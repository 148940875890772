import dayjs from 'dayjs'
import {
  ScheduleType,
  TransactionInstrumentType,
  TransactionUpdate,
} from 'utils/types/update'
import {
  CommonUpdatePayload,
  ContentsPayload,
  CreateDraftUpdatePayload,
  CreateUpdatePayload,
  EditUpdatePayload,
  PermissionsPayload,
  ScheduleUpdatePayload,
  TagsPayload,
} from 'utils/types/updatePayloads'

import differenceBy from 'lodash/differenceBy'
import { AssociationType } from 'utils/constants/investorManagement'
import { TransactionInstruments } from 'utils/constants/transactionInstruments'
import {
  TransactionFundType,
  TransactionTypes,
} from 'utils/constants/transactionTypes'
import { Nullable } from 'utils/types/common'
import { Portfolio } from 'utils/types/portfolios'
import {
  InvestorTransactionFormValues,
  TransactionFundTypes,
  TransactionTypes as TTransactionTypes,
} from 'utils/types/transactions'
import { CreateTransactionBaseFormValues } from 'utils/types/updateForm'
import { parsePercentage } from '../number'
import { EditUpdatePayloadParams } from './types'
import { getEditDraftPayload, getEditUpdateItemPayload } from './updates'

export const instrumentsPerField = {
  valuationCap: [
    TransactionInstruments.CONVERTIBLE_NOTE,
    TransactionInstruments.SAFE,
  ],
  discountRate: [
    TransactionInstruments.CONVERTIBLE_NOTE,
    TransactionInstruments.SAFE,
  ],
  interestRate: [
    TransactionInstruments.CONVERTIBLE_NOTE,
    TransactionInstruments.DEBT_CREDIT,
  ],
  interestCalculationBasis: [
    TransactionInstruments.CONVERTIBLE_NOTE,
    TransactionInstruments.DEBT_CREDIT,
  ],
  maturityDate: [
    TransactionInstruments.CONVERTIBLE_NOTE,
    TransactionInstruments.DEBT_CREDIT,
  ],
  purchasePricePerShare: [
    TransactionInstruments.EQUITY,
    TransactionInstruments.PREFERRED_EQUITY,
  ],
  sharesPurchased: [
    TransactionInstruments.EQUITY,
    TransactionInstruments.PREFERRED_EQUITY,
  ],
  preMoneyValuation: [
    TransactionInstruments.EQUITY,
    TransactionInstruments.PREFERRED_EQUITY,
  ],
  postMoneyValuation: [
    TransactionInstruments.EQUITY,
    TransactionInstruments.PREFERRED_EQUITY,
  ],

  annualManagementFee: [TransactionInstruments.FUND_INVESTMENT],
  carry: [TransactionInstruments.FUND_INVESTMENT],
  carryHurdleRate: [TransactionInstruments.FUND_INVESTMENT],
  dividend: [TransactionInstruments.PREFERRED_EQUITY],
  dividendCalculationBasis: [TransactionInstruments.PREFERRED_EQUITY],
  dividendType: [TransactionInstruments.PREFERRED_EQUITY],

  vestingCommencementDate: [TransactionInstruments.WARRANTS],
  expirationDate: [TransactionInstruments.WARRANTS],
  strikePrice: [TransactionInstruments.WARRANTS],
  numberOfShares: [TransactionInstruments.WARRANTS],
}

export type EditTransactionPayloadParams = Omit<
  EditUpdatePayloadParams,
  'schedule' | 'investor' | 'portfolio' | 'associationType'
> & {
  transactionValues: CreateTransactionBaseFormValues
  originalUpdate: TransactionUpdate
}

export type CreateTransactionUpdatePayload = Omit<
  CreateUpdatePayload<
    'transaction',
    BasicTransactionPayload &
      Partial<PortfolioAttributesPayload> &
      Partial<VehicleInvestorAttributesPayload> &
      Partial<InstrumentAttributesPayload>
  >,
  'loggingUpdateSchedule'
>

export type EditTransactionUpdatePayload = Omit<
  EditUpdatePayload<
    'transaction',
    BasicTransactionPayload &
      Partial<PortfolioAttributesPayload> &
      Partial<VehicleInvestorAttributesPayload> &
      Partial<InstrumentAttributesPayload>
  >,
  'loggingUpdateSchedule'
>

export type TransactionUpdatableAttributesPayload = {
  updatableAttributes: Partial<BasicTransactionPayload> &
    Partial<PortfolioAttributesPayload> &
    Partial<VehicleInvestorAttributesPayload> &
    Partial<InstrumentAttributesPayload>
}

export type EditDraftTransactionItemPayload = CommonUpdatePayload &
  PermissionsPayload &
  TagsPayload &
  ContentsPayload &
  ScheduleUpdatePayload &
  TransactionUpdatableAttributesPayload

type BasicTransactionPayloadParams = {
  title?: string
  description?: string
  date?: Nullable<Date>
  type?: Nullable<TTransactionTypes>
  amount?: Nullable<number>
  amountDistributed?: number
  amountCommitted?: number
  amountInvested?: number
  instrument?: TransactionInstrumentType
  transactionFundType?: Nullable<TransactionFundTypes>
  drawdownAgainstCommitment?: boolean
  subjectMatterId?: string
  holding?: Nullable<{
    id: string
  }>
}
type BasicTransactionPayload = {
  title: string
  text: string
  date: Nullable<Date>
  transactionType: string
  amountDistributed?: number
  amountCommitted?: number
  amountInvested?: number
  drawdownAgainstCommitment: boolean
  subjectMatterId: string
  associationType?: AssociationType
}

export const getBasicTransactionPayload = (
  values: BasicTransactionPayloadParams
): BasicTransactionPayload => {
  const payload: any = {
    title: values.title,
    text: values.description,
    date: values.date,
    transactionType: values.type?.toLowerCase(),
  }

  if (values.type === TransactionTypes.DISTRIBUTION) {
    payload.amountDistributed = values.amount?.toString()
  } else if (values.type === TransactionTypes.COMMITMENT) {
    payload.amountCommitted = values.amount?.toString()
  } else {
    payload.amountInvested = values.amount?.toString()

    if (
      values.instrument === TransactionInstruments.FUND_INVESTMENT ||
      values.transactionFundType === TransactionFundType.INVESTOR
    ) {
      payload.drawdownAgainstCommitment = values.drawdownAgainstCommitment
    } else {
      payload.drawdownAgainstCommitment = false
    }
  }

  if (values.transactionFundType === TransactionFundType.HOLDING) {
    payload.subjectMatterId = values.holding?.id
  }

  return payload
}

export type VehicleInvestorAttributesPayload = {
  portfolioVehicleInvestorTransactionAttributes:
    | {
        id: string
        investmentVehicleInvestorId: string
      }
    | {
        investmentVehicleInvestorId: string
        fundPortfolioInvestorAttributes?: {
          fundPortfolioId: string
          investorGroupId: string
        }
        id?: string
      }
}

const getVehicleInvestorAttributesPayload = (
  values: CreateTransactionBaseFormValues,
  _originalUpdate?: TransactionUpdate
): VehicleInvestorAttributesPayload => {
  return {
    portfolioVehicleInvestorTransactionAttributes: {
      investmentVehicleInvestorId:
        values.investmentVehicle?.investmentVehicleInvestor?.id ||
        values.portfolioVehicleInvestorTransaction
          ?.investmentVehicleInvestorId ||
        '',
      fundPortfolioInvestorAttributes: {
        fundPortfolioId: values.fundPortfolio?.id || '',
        investorGroupId: values.investor?.id || '',
      },
      id: values.portfolioVehicleInvestorTransaction?.id,
    },
  }
}

type InstrumentAttributesPayloadParams = {
  instrument?: TransactionInstrumentType
  valuationCap?: number
  maturityDate?: Date
  purchasePricePerShare?: number
  sharesPurchased?: number
  preMoneyValuation?: number
  postMoneyValuation?: number
  dividendType?: string
  vestingCommencementDate?: Date
  expirationDate?: Date
  strikePrice?: number
  numberOfShares?: number
  discountRate?: string
  interestRate?: string
  interestCalculationBasis?: string
  annualManagementFee?: string
  carry?: string
  carryHurdleRate?: string
  dividend?: string
  dividendCalculationBasis?: string
}

type InstrumentAttributesPayload = {
  instrumentAttributes: {
    instrumentType?: string
    valuationCap?: number
    discountRate?: string
    interestRate?: string
    interestCalculationBasis?: string
    maturityDate?: string
    purchasePricePerShare?: number
    sharesPurchased?: number
    preMoneyValuation?: number
    postMoneyValuation?: number
    annualManagementFee?: string
    carry?: string
    carryHurdleRate?: string
    dividend?: string
    dividendCalculationBasis?: string
    dividendType?: string
    vestingCommencementDate?: string
    expirationDate?: string
    strikePrice?: number
    numberOfShares?: number
  }
}

export const getInstrumentAttributesPayload = (
  values: InstrumentAttributesPayloadParams
): InstrumentAttributesPayload => {
  const payload: InstrumentAttributesPayload = {
    instrumentAttributes: {},
  }

  if (values.instrument)
    payload.instrumentAttributes.instrumentType =
      values.instrument.toLocaleLowerCase()

  if (
    instrumentsPerField.valuationCap.includes(values.instrument!) &&
    values.valuationCap !== undefined
  )
    payload.instrumentAttributes.valuationCap = values.valuationCap

  if (
    instrumentsPerField.discountRate.includes(values.instrument!) &&
    values.discountRate
  )
    payload.instrumentAttributes.discountRate = parsePercentage(
      values.discountRate
    )

  if (
    instrumentsPerField.interestRate.includes(values.instrument!) &&
    values.interestRate
  )
    payload.instrumentAttributes.interestRate = parsePercentage(
      values.interestRate
    )

  if (
    instrumentsPerField.interestCalculationBasis.includes(values.instrument!) &&
    values.interestCalculationBasis
  )
    payload.instrumentAttributes.interestCalculationBasis =
      values.interestCalculationBasis

  if (instrumentsPerField.maturityDate.includes(values.instrument!))
    payload.instrumentAttributes.maturityDate = dayjs(
      values.maturityDate
    )?.isValid()
      ? values.maturityDate?.toISOString()
      : ''

  if (
    instrumentsPerField.purchasePricePerShare.includes(values.instrument!) &&
    values.purchasePricePerShare !== undefined
  )
    payload.instrumentAttributes.purchasePricePerShare =
      values.purchasePricePerShare

  if (
    instrumentsPerField.sharesPurchased.includes(values.instrument!) &&
    values.sharesPurchased !== undefined
  )
    payload.instrumentAttributes.sharesPurchased = values.sharesPurchased

  if (
    instrumentsPerField.preMoneyValuation.includes(values.instrument!) &&
    values.preMoneyValuation !== undefined
  )
    payload.instrumentAttributes.preMoneyValuation = values.preMoneyValuation

  if (
    instrumentsPerField.postMoneyValuation.includes(values.instrument!) &&
    values.postMoneyValuation !== undefined
  )
    payload.instrumentAttributes.postMoneyValuation = values.postMoneyValuation

  if (
    instrumentsPerField.annualManagementFee.includes(values.instrument!) &&
    values.annualManagementFee !== undefined
  )
    payload.instrumentAttributes.annualManagementFee = parsePercentage(
      values.annualManagementFee
    )

  if (instrumentsPerField.carry.includes(values.instrument!) && values.carry)
    payload.instrumentAttributes.carry = parsePercentage(
      parsePercentage(values.carry)
    )

  if (
    instrumentsPerField.carryHurdleRate.includes(values.instrument!) &&
    values.carryHurdleRate
  )
    payload.instrumentAttributes.carryHurdleRate = parsePercentage(
      values.carryHurdleRate
    )

  if (
    instrumentsPerField.dividend.includes(values.instrument!) &&
    values.dividend
  )
    payload.instrumentAttributes.dividend = parsePercentage(values.dividend)

  if (
    instrumentsPerField.dividendCalculationBasis.includes(values.instrument!) &&
    values.dividendCalculationBasis
  )
    payload.instrumentAttributes.dividendCalculationBasis =
      values.dividendCalculationBasis

  if (
    instrumentsPerField.dividendType.includes(values.instrument!) &&
    values.dividendType
  )
    payload.instrumentAttributes.dividendType = values.dividendType

  if (instrumentsPerField.vestingCommencementDate.includes(values.instrument!))
    payload.instrumentAttributes.vestingCommencementDate =
      values.vestingCommencementDate?.toISOString() ?? ''

  if (instrumentsPerField.expirationDate.includes(values.instrument!))
    payload.instrumentAttributes.expirationDate =
      values.expirationDate?.toISOString() ?? ''

  if (
    instrumentsPerField.strikePrice.includes(values.instrument!) &&
    values.strikePrice !== undefined &&
    values.strikePrice !== null
  )
    payload.instrumentAttributes.strikePrice = values.strikePrice

  if (
    instrumentsPerField.numberOfShares.includes(values.instrument!) &&
    values.numberOfShares !== undefined &&
    values.numberOfShares !== null
  )
    payload.instrumentAttributes.numberOfShares = values.numberOfShares

  return payload
}

type ExistentPortfolioCompanyTransaction = {
  portfolioCompanyId: string
}

type ExistentPortfolioCompanyTransactionToRemove = {
  id: string
  _destroy: boolean
}

type NotExistentPortfolioCompanyTransaction = {
  portfoliableId: string
  holdingId: string
}

type PortfolioAttributesPayload = {
  portfolioCompanyTransactionsAttributes: (
    | ExistentPortfolioCompanyTransaction
    | ExistentPortfolioCompanyTransactionToRemove
  )[]
  investPortfolioCompaniesAttributes: NotExistentPortfolioCompanyTransaction[]
}

const getPortfoliosAttributesPayload = (
  values: CreateTransactionBaseFormValues | InvestorTransactionFormValues,
  initialPortfolios: Portfolio[] = []
): PortfolioAttributesPayload => {
  const transactionHoldingId = values.holding?.id

  const addedPortfolios = differenceBy(
    values.portfolios,
    initialPortfolios,
    (port) => port.id
  )

  const removedPortfolios = differenceBy(
    initialPortfolios,
    values?.portfolios || [],
    (port) => port.id
  )

  // These portfolios has been removed from the transaction, so the transaction must removed from all of them
  const removedPortfolioCompanyTransactionsAttributes: ExistentPortfolioCompanyTransactionToRemove[] =
    removedPortfolios
      .filter((portfolio) => portfolio.portfolioCompanyTransaction)
      .map((portfolio) => ({
        id: portfolio!.portfolioCompanyTransaction!.id,
        _destroy: true,
      }))

  const existentPortfolioCompanyTransactionsAttributes: ExistentPortfolioCompanyTransaction[] =
    []
  const notExistentPortfolioCompanyTransactions: NotExistentPortfolioCompanyTransaction[] =
    []

  addedPortfolios.forEach((portfolio) => {
    const portfolioCompany = transactionHoldingId
      ? portfolio.portfolioCompanies?.find(
          (portCompany) => portCompany.holdingId === transactionHoldingId
        )
      : null

    if (portfolioCompany) {
      // The company of the transaction already belongs to the portfolio, so the transaction must be linked to the existent portfolio company
      existentPortfolioCompanyTransactionsAttributes.push({
        portfolioCompanyId: portfolioCompany.id,
      })
    } else {
      // The company do not belong to these portfolios, so the company must be included in all of them
      notExistentPortfolioCompanyTransactions.push({
        portfoliableId: portfolio.id,
        holdingId: transactionHoldingId!,
      })
    }
  })

  return {
    portfolioCompanyTransactionsAttributes: [
      ...existentPortfolioCompanyTransactionsAttributes,
      ...removedPortfolioCompanyTransactionsAttributes,
    ],
    investPortfolioCompaniesAttributes: notExistentPortfolioCompanyTransactions,
  }
}

export const getCreateDraftTransactionPayload =
  (): CreateDraftUpdatePayload<'transaction'> => {
    return {
      transaction: {
        loggingUpdateAttributes: {
          draftHash: {},
        },
      },
    }
  }

export const getEditTransactionPayload = ({
  transactionValues,
  date,
  originalUpdate,
  ...params
}: EditTransactionPayloadParams): EditTransactionUpdatePayload => {
  let payload = {
    ...getEditUpdateItemPayload({
      ...params,
      date,
      schedule: {
        scheduleType: ScheduleType.SEND_NOW,
      },
    }),
    ...getBasicTransactionPayload(transactionValues),
  }

  if (transactionValues.transactionFundType === TransactionFundType.INVESTOR) {
    payload = {
      ...payload,
      ...getVehicleInvestorAttributesPayload(transactionValues, originalUpdate),
    }
  } else {
    payload = {
      ...payload,
      ...getPortfoliosAttributesPayload(
        transactionValues,
        originalUpdate.portfolios
      ),
    }

    if (transactionValues.type === TransactionTypes.INVESTMENT) {
      payload = {
        ...payload,
        ...getInstrumentAttributesPayload(transactionValues),
      }
    }
  }

  return {
    transaction: payload,
  }
}

const getDraftTransactionPayload = (
  {
    transactionValues,
    date,
    originalUpdate,
    ...params
  }: EditTransactionPayloadParams,
  suggestedUpdateId?: string
): EditDraftTransactionItemPayload => {
  const { updatableAttributes, ...rest } = getEditDraftPayload(
    {
      ...params,
      body: transactionValues.description,
      date,
      schedule: {
        scheduleType: ScheduleType.SEND_NOW,
      },
    },
    suggestedUpdateId
  )

  let payload = {
    ...rest,
    updatableAttributes: {
      ...getBasicTransactionPayload(transactionValues),
      ...updatableAttributes,
      transactionFundType: transactionValues.transactionFundType,
    },
  }

  if (transactionValues.transactionFundType === TransactionFundType.INVESTOR) {
    payload = {
      ...payload,
      updatableAttributes: {
        ...payload.updatableAttributes,
        ...getVehicleInvestorAttributesPayload(
          transactionValues,
          originalUpdate
        ),
        associationType: AssociationType.VEHICLE,
      },
    }
  } else {
    payload = {
      ...payload,
      updatableAttributes: {
        ...payload.updatableAttributes,
        ...getPortfoliosAttributesPayload(
          transactionValues,
          originalUpdate?.portfolios
        ),
      },
    }

    if (transactionValues.type === TransactionTypes.INVESTMENT) {
      payload = {
        ...payload,
        updatableAttributes: {
          ...payload.updatableAttributes,
          ...getInstrumentAttributesPayload(transactionValues),
        },
      }
    }
  }

  return payload
}

export const getEditDraftTransactionPayload = (
  params: EditTransactionPayloadParams,
  suggestedUpdateId?: string
): EditDraftTransactionItemPayload => {
  const payload = getDraftTransactionPayload(params, suggestedUpdateId)
  return payload
}
