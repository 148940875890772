import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Switch from 'components/Switch/Switch'
import { ImageSize } from 'utils/constants/iue'

import styles from './SectionImage.module.scss'

const SectionImage = ({
  sectionId,
  entryId,
  index,
  fileUrl,
  createAssetToEmail,
  readOnly,
  onRemoveEntryFromSection,
  onChangeImageSize,
  imageSize,
}) => {
  const [url, setUrl] = useState(fileUrl)

  if (readOnly) {
    return (
      <div
        className={classNames(styles.sectionImage, {
          [styles.smallImage]: imageSize === ImageSize.SMALL,
        })}
      >
        <img alt="imagePreview" src={url} />
      </div>
    )
  }

  const openFilePicker = () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = () => {
      const reader = new FileReader()
      const file = Object.values(input.files)[0]

      reader.onloadend = () => {
        setUrl(reader.result)
        createAssetToEmail(file, sectionId, index, imageSize)
      }
      reader.readAsDataURL(file)
    }
  }

  const onRemoveImage = (event) => {
    event.stopPropagation()
    onRemoveEntryFromSection(sectionId, entryId)
  }

  return (
    <div
      role="button"
      className={classNames(styles.sectionImage, styles.pointer, {
        [styles.smallImage]: imageSize === ImageSize.SMALL,
      })}
      onClick={openFilePicker}
      onKeyDown={openFilePicker}
      tabIndex={0}
    >
      <div className={styles.uploaderWrapper}>
        <div className={styles.overlay} />
        <div className={styles.imageUploader}>
          <FontAwesomeIcon
            className={styles.uploadIcon}
            icon={['far', 'pen']}
          />
        </div>
      </div>
      <div className={styles.switchContainer}>
        <Switch
          options={[
            {
              id: ImageSize.SMALL,
              label: 'Small',
            },
            {
              id: ImageSize.LARGE,
              label: 'Large',
            },
          ]}
          valueId={imageSize}
          onChange={(newSize) => onChangeImageSize(sectionId, entryId, newSize)}
          disableEffect
        />
      </div>
      <img alt="imagePreview" src={url} />
      <button
        className={styles.btnRemoveSection}
        type="button"
        onClick={onRemoveImage}
      >
        <FontAwesomeIcon icon={['fas', 'times-circle']} />
      </button>
    </div>
  )
}
SectionImage.propTypes = {
  sectionId: PropTypes.string.isRequired,
  entryId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  fileUrl: PropTypes.string.isRequired,
  createAssetToEmail: PropTypes.func,
  onRemoveEntryFromSection: PropTypes.func,
  readOnly: PropTypes.bool,
  onChangeImageSize: PropTypes.func,
  imageSize: PropTypes.string,
}

SectionImage.defaultProps = {
  readOnly: false,
  createAssetToEmail: () => {},
  onRemoveEntryFromSection: () => {},
  onChangeImageSize: () => {},
  imageSize: ImageSize.LARGE,
}

export default SectionImage
