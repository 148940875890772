import { BaseTag } from 'utils/types/update'
import axiosClient from './httpClient'

class TagService {
  static createTag = async (
    tagName: string,
    groupId?: string
  ): Promise<BaseTag> => {
    const params = {
      tag: {
        name: tagName,
        group_id: groupId,
      },
    }
    const response = await axiosClient().post(`/tags`, params)
    return response.data.entities.tags[response.data.result]
  }

  static getTags = (tagName?: string, idNotIn?: string[]) => {
    const params = {
      order_by: 'name',
      'q[name_cont]': tagName,
      'q[id_not_in]': idNotIn,
      // By default BE returns 25 per page so BE ask us to set it to a bigger number
      per_page: 5000,
    }
    return axiosClient().get(`/tags`, { params })
  }
}

export default TagService
