import { useIntl } from 'react-intl'
import {
  SharedUpdateIcon,
  UserAndGroupName,
} from 'components/Content/Content.styles'
import Tooltip from 'components/Tooltip'
import { IndexEmailContent, IndexFileContent } from 'utils/types/files'
import theme from 'utils/theme'

interface SharedIndicatorProps {
  content: IndexFileContent | IndexEmailContent
}

const SharedIndicator = ({ content }: SharedIndicatorProps) => {
  const intl = useIntl()
  const name = content.group?.name ?? content.user?.name

  return (
    <Tooltip
      backgroundColor={theme.colors.lightGray}
      id={`shared-indicator-${content.id}`}
      text={intl.formatMessage(
        { id: 'files.fileSharedBy' },
        {
          groupName: <UserAndGroupName>{name}</UserAndGroupName>,
        }
      )}
    >
      <SharedUpdateIcon icon={['far', 'share']} />
    </Tooltip>
  )
}

export default SharedIndicator
