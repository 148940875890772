import { Nullable } from 'utils/types/common'
import * as yup from 'yup'
import { AccountTypes, CreateInboundSteps } from 'utils/types/inbounds'

const accountTypeSchema = yup.object().shape({
  accountType: yup
    .string()
    .oneOf(Object.values([AccountTypes.GMAIL, AccountTypes.GOOGLE_GROUP]))
    .required(),
})

const emailAddressSchema = accountTypeSchema.shape({
  email: yup.string().email().required(),
  allowMyGroupToReceiveSuggestedUpdates: yup.boolean(),
})

export const getValidationSchema = (step: Nullable<CreateInboundSteps>) => {
  switch (step) {
    case CreateInboundSteps.ACCOUNT_TYPE:
      return accountTypeSchema
    case CreateInboundSteps.EMAIL_ADDRESS:
      return emailAddressSchema
    default:
      return emailAddressSchema
  }
}
