import styled from 'styled-components/macro'

import ToggleToStyle from 'components/Toggle'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const Toggle = styled(ToggleToStyle)`
  p {
    font-weight: 400;
  }
`
