import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ChartCriteriaType, ChartPeriods } from '../../types'

import * as Styles from './PeriodSelector.styles'

interface PeriodSelectorProps {
  activeCriteria: ChartCriteriaType
  onChangeCriteria: (criteria: ChartCriteriaType) => void
}

const PeriodSelector: React.FC<PeriodSelectorProps> = ({
  activeCriteria,
  onChangeCriteria,
}) => {
  return (
    <Styles.ChartCriteria>
      <Styles.CriteriaButton
        borderRadius="0.8rem 0 0 0.8rem"
        isActive={activeCriteria === ChartPeriods.QUARTERLY}
        onClick={() => onChangeCriteria(ChartPeriods.QUARTERLY)}
      >
        <FormattedMessage id="portfolioDetail.performanceMetrics.chartCriteria.quarter" />
      </Styles.CriteriaButton>
      <Styles.CriteriaButton
        isActive={activeCriteria === ChartPeriods.YEARLY}
        onClick={() => onChangeCriteria(ChartPeriods.YEARLY)}
      >
        <FormattedMessage id="portfolioDetail.performanceMetrics.chartCriteria.year" />
      </Styles.CriteriaButton>
      <Styles.CriteriaButton
        borderRadius="0 0.8rem 0.8rem 0"
        isActive={activeCriteria === ChartPeriods.ALL}
        onClick={() => onChangeCriteria(ChartPeriods.ALL)}
      >
        <FormattedMessage id="portfolioDetail.performanceMetrics.chartCriteria.all" />
      </Styles.CriteriaButton>
    </Styles.ChartCriteria>
  )
}

export default PeriodSelector
