import styled, { css } from 'styled-components'
import { Link as RouterLinkToStyle } from 'react-router-dom'

import { color } from 'utils/functions/colors'

const LinkStyles = css`
  font-weight: 700;
  border-bottom: 1px solid ${color('white')};
  cursor: pointer;
`

export const Link = styled.span`
  ${LinkStyles}
`

export const RouterLink = styled(RouterLinkToStyle)`
  ${LinkStyles}
`
