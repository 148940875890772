import { useCallback, useContext, useRef } from 'react'
import { useIntl } from 'react-intl'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'
import { useField, useFormikContext } from 'components/Form/hooks'
import {
  ExistentHoldingContext,
  ExistentHoldingContextType,
} from 'components/PortfolioHoldings/CreatePortfolioHolding/CreatePortfolioHolding'
import {
  AddHoldingForm,
  AddHoldingFormErrors,
  SuggestionErrors,
} from 'components/AddHoldingModal/types'

export interface FundPortfolioInputProps {
  index: number
}

export const useFundPortfolioInput = (index: number) => {
  const intl = useIntl()
  const repeatedFundNameErrorMessage = useRef(
    intl.formatMessage({ id: 'addHolding.errors.repeatedFundName' })
  )
  const requiredErrorMessage = useRef(
    intl.formatMessage({ id: 'general.requiredField' })
  )
  const {
    handleBlur,
    errors: formikErrors,
    status: formikStatus,
    setStatus,
  } = useFormikContext<AddHoldingForm>()
  const errors = formikErrors as unknown as AddHoldingFormErrors
  const status = formikStatus as SuggestionErrors
  const [fundField, fundMeta] = useField<string>(`funds.funds[${index}]`)
  const existentHoldingContext = useContext<ExistentHoldingContextType>(
    ExistentHoldingContext
  )
  const isRequiredError = errors.funds?.funds === requiredErrorMessage.current
  const shouldShowErrorMessage =
    fundMeta.error === repeatedFundNameErrorMessage.current || isRequiredError
  const shouldHideError = index !== 0 && isRequiredError
  const errorMessage = isRequiredError
    ? buildFormError(
        errors.funds!.funds as string,
        ErrorType.ERROR,
        fundMeta.touched
      )
    : buildFormError(fundMeta.error, ErrorType.ERROR, fundMeta.touched)

  const onChange = useCallback(
    (event) => {
      fundField.onChange(event)

      if (status?.fundsErrors) {
        status.fundsErrors[index] = []
        setStatus({
          ...status,
          fundsErrors: [...status.fundsErrors],
        })
      }
    },
    [fundField, status, index, setStatus]
  )

  return {
    intl,
    handleBlur,
    status,
    fundField,
    onChange,
    existentHoldingContext,
    errorMessage,
    shouldHideError,
    shouldShowErrorMessage,
  }
}
