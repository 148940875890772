import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CircularProgressbar as RCircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbarContainer } from './CircularProgressBar.styles'

const CircularProgressBar = ({ isActive, percentage }) => {
  const [percentageToShow, setPercentageToShow] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setPercentageToShow(percentage)
    }, 0)
  }, [percentage])

  return (
    <CircularProgressbarContainer isActive={isActive}>
      <RCircularProgressbar value={percentageToShow} text={`${percentage}%`} />
    </CircularProgressbarContainer>
  )
}

CircularProgressBar.propTypes = {
  isActive: PropTypes.bool,
  percentage: PropTypes.number.isRequired,
}

CircularProgressBar.defaultProps = {
  isActive: false,
}

export default CircularProgressBar
