import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  AccessType,
  CreateUpdateFormikStatus,
  CreateUpdateGlobalProps,
  ShareWithEntity,
  UpdateFormContext,
} from 'utils/types/updateForm'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { EMAIL_REGEX } from 'utils/functions/regex'
import { getCurrentGroupData, getUser } from 'selectors/auth'
import { getCurrentCompany } from 'selectors/company'
import { IndexInvestor } from 'utils/types/investors'
import { MODAL_FADE_OUT_DURATION_IN_MS } from 'components/Modal/Modal'
import { useField, useForm, useFormikContext } from 'components/Form/hooks'
import { UpdateRouteState } from 'utils/hooks/useShowUpdateRoutes'
import useUserData from 'utils/hooks/useUserData'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import useUpdateShareSettingsPermissions from 'utils/hooks/useUpdateShareSettingsPermissions'
import { useSearchScopedUsersAndGroups } from 'utils/hooks/useSearchScopedUsersAndGroups'
import { createNewUsers } from 'containers/UpdatesV2/utils'
import {
  ShareWithOption,
  ShareWithOptionType,
  TO_CREATE_PREFIX,
} from './components/ShareWithDropdown/useShareWithDropdown'

const mapEntitiesToOptions = (entities: ShareWithEntity[]) =>
  entities.map((entity) => {
    let type =
      entity.type === 'group'
        ? ShareWithOptionType.Group
        : ShareWithOptionType.User

    if (entity.type === 'user' && EMAIL_REGEX.test(entity.name)) {
      type = ShareWithOptionType.Email
    }

    return {
      type,
      id: entity.id,
      name: entity.name,
      imageUrl: entity.imageUrl,
    }
  })

export interface ShareSettingsModalProps {
  isModalOpen: boolean
  closeModal: () => void
}

export const useShareSettingsModal = ({
  closeModal,
}: ShareSettingsModalProps) => {
  const location = useLocation<UpdateRouteState>()
  const locationState = location.state || {}
  const { values, status, setStatus, setFieldValue, submitForm } =
    useFormikContext<CreateUpdateGlobalProps, CreateUpdateFormikStatus>()

  const {
    isShowMode,
    isEditMode,
    hasEditPermissions,
    updateType,
    submitDraft,
    update,
  } = useForm<UpdateFormContext>()

  const { isCurrentUserActingAsFounder, isFounderVerified } = useUserData()
  const founderUnverified = !isFounderVerified && isCurrentUserActingAsFounder

  const [yourGroupAccess, setYourGroupAccess] = useState<AccessType>()
  const [investorAccess, setInvestorAccess] = useState<AccessType>()
  const [communityAccess, setCommunityAccess] = useState<AccessType>()
  const [entitiesSelected, setEntitiesSelected] = useState<ShareWithOption[]>(
    []
  )
  const [isConfidential, setIsConfidential] = useState<boolean>()
  const [investor, setInvestor] = useState<IndexInvestor>()

  const { loadRecentOptions, loadOptionsBySearchValue } =
    useSearchScopedUsersAndGroups()
  const { updates } = usePlanFeatures()

  useEffect(() => {
    if (values.investor) {
      setInvestor(values.investor)
    }
  }, [values.investor])

  useEffect(() => {
    if (investor) {
      setInvestorAccess(values.permissions.investor || AccessType.CAN_VIEW)
    }
  }, [investor, values.permissions.investor])

  useEffect(() => {
    setYourGroupAccess(values.permissions.yourGroup)
  }, [values.permissions.yourGroup])

  useEffect(() => {
    setCommunityAccess(values.permissions.community)
  }, [values.permissions.community])

  useEffect(() => {
    setEntitiesSelected(mapEntitiesToOptions(values.permissions.sharedWith))
  }, [values.permissions.sharedWith])

  useEffect(() => {
    setIsConfidential(values.permissions.confidentialUpdate)
  }, [values.permissions.confidentialUpdate])

  const currentUser = useAppSelector(getUser)
  const updateOwner = values.owner
  const currentUserIsOwner = currentUser.id === values.owner?.id
  const disableEditGroupPermissions = hasEditPermissions && !currentUserIsOwner

  const currentCompany = useAppSelector(getCurrentCompany)
  const currentGroup = useAppSelector(getCurrentGroupData)

  const {
    isSharableOnCreate,
    isSharableOnShowAndEdit,
    isPrivatePortfolioDisclaimerVisible,
    showPublicPermissionOnCreateMode,
    showPublicPermissionOnShowAndEditMode,
    showReshareSettingsOptionOnCreateMode,
    showReshareSettingsOptionOnShowAndEdit,
  } = useUpdateShareSettingsPermissions({ updateType, update })

  const showReshareSettings =
    isShowMode || isEditMode
      ? showReshareSettingsOptionOnShowAndEdit()
      : showReshareSettingsOptionOnCreateMode()

  const showPublicOption =
    isShowMode || isEditMode
      ? showPublicPermissionOnShowAndEditMode()
      : showPublicPermissionOnCreateMode()

  const showDropdown =
    isShowMode || isEditMode ? isSharableOnShowAndEdit() : isSharableOnCreate()

  const [portfoliosField] = useField('portfolios')
  const [fundPortfoliosField] = useField('fundPortfolio')
  const [fundPortfolioField] = useField('portfolio')

  const hasAtLeastOnePortfolio =
    portfoliosField.value?.length > 0 ||
    !!fundPortfoliosField.value ||
    !!fundPortfolioField.value

  const hasPreLoadedInvestor =
    !!locationState.investorId || !!locationState.investor

  const isGroupOwned = hasAtLeastOnePortfolio || hasPreLoadedInvestor

  const createEntitiesSelectedSet = useCallback(() => {
    const sharedWithIds: Set<string> = new Set([currentUser.id])

    if (yourGroupAccess !== AccessType.NO_ACCESS && currentGroup) {
      sharedWithIds.add(currentGroup.id)
    }

    return sharedWithIds
  }, [currentUser.id, currentGroup, yourGroupAccess])

  const getOtherEntitiesSelectedIds = useCallback(
    (list: ShareWithOption, listIndex: number) => {
      const recipientsInList = new Set<string>(
        list.recipients?.map((recipient) => recipient.id)
      )
      const ids = createEntitiesSelectedSet()

      entitiesSelected.forEach((entity, index) => {
        if (entity.id === list.id) return

        ids.add(entity.id)

        if (entity.type === 'list') {
          entity.recipients?.forEach((recipient) => {
            const recipientIsBeingAddedByCurrentList =
              recipientsInList.has(recipient.id) && index > listIndex

            if (recipientIsBeingAddedByCurrentList) return

            ids.add(recipient.id)
          })
        }
      })

      return ids
    },
    [createEntitiesSelectedSet, entitiesSelected]
  )

  useEffect(() => {
    if (hasAtLeastOnePortfolio) {
      setYourGroupAccess(AccessType.CAN_EDIT)
    }
  }, [hasAtLeastOnePortfolio, setFieldValue, yourGroupAccess])

  const saveAndClose = () => {
    setFieldValue('permissions.confidentialUpdate', isConfidential)
    const sharedWith: ShareWithEntity[] = []
    const sharedWithIds: Set<string> = createEntitiesSelectedSet()

    entitiesSelected.forEach((entity) => {
      if (entity.type === 'list') {
        entity.recipients?.forEach((recipient) => {
          if (!sharedWithIds.has(recipient.id)) {
            sharedWithIds.add(recipient.id)
            sharedWith.push({
              id: recipient.id,
              name: recipient.name,
              imageUrl: recipient.imageUrl,
              type: recipient.type === 'group' ? 'group' : 'user',
            })
          }
        })
      } else if (!sharedWithIds.has(entity.id)) {
        sharedWithIds.add(entity.id)
        sharedWith.push({
          id: entity.id,
          name: entity.name,
          imageUrl: entity.imageUrl,
          type: entity.type === 'group' ? 'group' : 'user',
        })
      }
    })

    const listIdsSelected = entitiesSelected
      .filter((entity) => entity.type === ShareWithOptionType.List)
      .map((entity) => entity.id)

    setFieldValue('permissions.sharedWith', sharedWith)
    setFieldValue('permissions.yourGroup', yourGroupAccess)
    setFieldValue('permissions.community', communityAccess)
    setFieldValue('permissions.investor', investorAccess)
    setFieldValue('permissions.recentlyUsedLists', listIdsSelected)
    setStatus({ sharedWithModalCache: entitiesSelected })

    if (isShowMode) {
      submitForm()
    } else {
      submitDraft?.()
    }

    closeModal()
  }

  const cancel = () => {
    closeModal()
    setTimeout(() => {
      setYourGroupAccess(values.permissions.yourGroup)
      setCommunityAccess(values.permissions.community)
      setIsConfidential(values.permissions.confidentialUpdate)
      if (investor) {
        setInvestorAccess(values.permissions.investor)
      }
      setEntitiesSelected(
        status?.sharedWithModalCache ||
          mapEntitiesToOptions(values.permissions.sharedWith)
      )
    }, MODAL_FADE_OUT_DURATION_IN_MS)
  }

  const onRecipientRemove = (listId: string) => (recipientId: string) => {
    setEntitiesSelected((currEntitiesSelected) => {
      const listIndex = currEntitiesSelected.findIndex(
        (entity) => entity.id === listId
      )
      const list = currEntitiesSelected[listIndex]
      const recipients = list.recipients?.filter(
        (recipient) => recipient.id !== recipientId
      )
      const newEntitiesSelected = [...currEntitiesSelected]

      if (recipients?.length === 0) {
        newEntitiesSelected.splice(listIndex, 1)
        return newEntitiesSelected
      }

      newEntitiesSelected[listIndex] = {
        ...list,
        recipients,
      }

      return newEntitiesSelected
    })
  }

  const loadOptions = useCallback(
    async (searchValue: string): Promise<ShareWithOption[]> => {
      if (!searchValue) {
        return loadRecentOptions(currentGroup, currentCompany?.owner)
      }

      return loadOptionsBySearchValue(
        searchValue,
        currentGroup,
        currentCompany?.owner
      )
    },
    [
      currentGroup,
      currentCompany?.owner,
      loadRecentOptions,
      loadOptionsBySearchValue,
    ]
  )

  const onCloseDropdown = (selectedOptions: ShareWithOption[]) => {
    if (selectedOptions?.length > 0) {
      setEntitiesSelected((currGroupsSelected) => [
        ...currGroupsSelected,
        ...selectedOptions,
      ])
      createNewUsers({
        entities: selectedOptions,
        setSelectedEntities: setEntitiesSelected,
        prefix: TO_CREATE_PREFIX,
        toastErrorMessage: 'errors.addingUserToList',
      })
    }
  }

  const onYourGroupAccessChange = (access: AccessType) => {
    setYourGroupAccess(access)

    if (
      access === AccessType.NO_ACCESS &&
      communityAccess === AccessType.CAN_VIEW
    ) {
      setCommunityAccess(AccessType.NO_ACCESS)
    }
  }

  const onInvestorAccessChange = (access: AccessType) => {
    setInvestorAccess(access)
  }

  const onCommunityAccessChange = (access: AccessType) => {
    setCommunityAccess(access)

    if (
      access === AccessType.CAN_VIEW &&
      yourGroupAccess === AccessType.NO_ACCESS
    ) {
      setYourGroupAccess(AccessType.CAN_VIEW)
    }
  }

  const toggleIsConfidential = () => {
    setIsConfidential((currIsConfidential) => !currIsConfidential)
  }

  return {
    entitiesSelected,
    showDropdown,
    founderUnverified,
    onCloseDropdown,
    loadOptions,
    currentUser,
    updateOwner,
    currentUserIsOwner,
    isEditMode,
    isShowMode,
    currentGroup,
    yourGroupAccess,
    onYourGroupAccessChange,
    isGroupOwned,
    disableEditGroupPermissions,
    showPublicOption,
    communityAccess,
    onCommunityAccessChange,
    setEntitiesSelected,
    onRecipientRemove,
    getOtherEntitiesSelectedIds,
    showReshareSettings,
    toggleIsConfidential,
    isConfidential,
    saveAndClose,
    cancel,
    investor,
    investorAccess,
    updateType,
    onInvestorAccessChange,
    isOwnGroupSharingEnabled: updates.isOwnGroupSharingEnabled,
    isPrivatePortfolioDisclaimerVisible: isPrivatePortfolioDisclaimerVisible(),
  }
}
