import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const PREVIEW_DRAFT_PATH = '/preview-draft'

export const useIsPreviewingDraftUpdate = () => {
  const location = useLocation()
  const [isPreviewingDraft, setIsPreviewingDraft] = useState(
    location.pathname.includes(PREVIEW_DRAFT_PATH)
  )

  useEffect(() => {
    setIsPreviewingDraft(location.pathname.includes(PREVIEW_DRAFT_PATH))
  }, [location])

  return isPreviewingDraft
}
