import useShowInvestmentVehicle from 'containers/InvestorManagement/ShowInvestmentVehicle/useShowInvestmentVehicle'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { UpdateTypes } from 'utils/constants/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useShowUpdateRoute } from 'utils/hooks/useShowUpdateRoutes'
import { UpdateUrlSuffix } from 'utils/hooks/useShowUpdateRoutes/types'
import { TransactionItem } from 'utils/types/update'

interface useInvestmentVehicleProps {
  investmentVehicleId: string
  investorId?: string
}

const useInvestmentVehicleProfile = ({
  investmentVehicleId,
  investorId,
}: useInvestmentVehicleProps) => {
  const showUpdateRoute = useShowUpdateRoute()
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const {
    investmentVehicle,
    fundsPortfolioInvestor,
    isLoading,
    isLoadingFunds,
    onShowTransaction: onShowTransactionActingAsGP,
  } = useShowInvestmentVehicle(investmentVehicleId, investorId)

  const onShowTransaction = (transaction: TransactionItem) => {
    if (isInvestorGroup) {
      showUpdateRoute.fromInvestorGroupInvestmentVehicle({
        updateId: transaction.loggingUpdateId,
        updateType: UpdateTypes.TRANSACTION,
        investmentVehicle: investmentVehicle!,
        routeState: {
          isFromInvestor: true,
        },
      })
    } else {
      onShowTransactionActingAsGP(transaction)
    }
  }

  const onEditTransaction = (transaction: TransactionItem) => {
    showUpdateRoute.fromInvestmentVehicle({
      updateId: transaction.loggingUpdateId,
      updateType: UpdateTypes.TRANSACTION,
      investmentVehicle: investmentVehicle!,
      suffix: UpdateUrlSuffix.EDIT,
      investorId,
    })
  }

  return {
    investmentVehicle,
    fundsPortfolioInvestor,
    isLoading,
    isLoadingFunds,
    onShowTransaction,
    onEditTransaction,
  }
}

export default useInvestmentVehicleProfile
