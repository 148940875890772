import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  getUrl,
  setInitialConfiguration,
  setUrl,
} from 'reducers/breadcrumbSlice'
import { getCurrentGroupId, getCurrentGroupType } from 'selectors/auth'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'

const BreadcrumbInitialConfiguration = () => {
  const location = useLocation()
  const currentGroupType = useAppSelector(getCurrentGroupType)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const urlFromBreadcrumbState = useAppSelector(getUrl)
  const dispatch = useAppDispatch()

  const currentUrl = location.pathname

  useEffect(() => {
    if (urlFromBreadcrumbState.current !== currentUrl) {
      dispatch(
        setInitialConfiguration({
          url: location.pathname,
          groupType: currentGroupType!,
          currentGroupId: currentGroupId!,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.pathname) {
      dispatch(setUrl(location.pathname))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (currentGroupType && location.pathname !== '/') {
      dispatch(
        setInitialConfiguration({
          url: location.pathname,
          groupType: currentGroupType,
          currentGroupId: currentGroupId!,
        })
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroupType, location.pathname])
}

export default BreadcrumbInitialConfiguration
