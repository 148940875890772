import type { IntlShape } from 'react-intl'
import uniq from 'lodash/uniq'
import HoldingsService from 'api/HoldingsService'
import { ALREADY_TAKEN_ERROR } from 'utils/constants/errors'
import {
  AddHoldingBulkImportFormErrors,
  AddHoldingForm,
  AddHoldingFormErrors,
  BulkImportSuggestionErrors,
  CompanyDraftValidationResponse,
  DraftValidationResponse,
  FundDraftValidationResponse,
  HoldingType,
  SuggestionErrors,
} from './types'

/**
 * Response type for the backend error when the fund names are duplicated and the form was sent WITH the fund manager data
 */
interface DuplicatedFundNamesWithFundManagerError {
  errors: { name: string[] }
}

/**
 * Response type for the backend error when the fund names are duplicated and the form was sent WITHOUT the fund manager data
 */
interface DuplicatedFundNamesWithoutFundManagerError {
  error: string
  portfolioNames: string[]
}

type DuplicatedFundNamesError =
  | DuplicatedFundNamesWithFundManagerError
  | DuplicatedFundNamesWithoutFundManagerError

interface DuplicatedFundManagerWebsiteError {
  errors: string
  company: DuplicatedCompanyErrorData
}

interface DuplicatedCompanyWebsiteError {
  errors: WebsiteError
  company: DuplicatedCompanyErrorData
}

interface WebsiteError {
  website: string[]
}

export interface DuplicatedCompanyErrorData {
  id?: string
  name?: string
}

type BackendError =
  | DuplicatedFundNamesError
  | DuplicatedFundNamesWithoutFundManagerError
  | DuplicatedFundManagerWebsiteError
  | DuplicatedCompanyWebsiteError

const DUPLICATED_PORTFOLIO_NAMES_ERROR = 'Portfolio names already exist'

const isDuplicatedFundNamesWithFundManagerError = (
  backendError: BackendError
): backendError is DuplicatedFundNamesWithFundManagerError => {
  return (
    backendError as DuplicatedFundNamesWithFundManagerError
  ).errors?.name?.includes?.(ALREADY_TAKEN_ERROR)
}

const isDuplicatedFundNamesWithoutFundManagerError = (
  backendError: BackendError
): backendError is DuplicatedFundNamesWithoutFundManagerError =>
  (backendError as DuplicatedFundNamesWithoutFundManagerError).error ===
  DUPLICATED_PORTFOLIO_NAMES_ERROR

const isDuplicatedFundNamesError = (
  backendError: BackendError
): backendError is DuplicatedFundNamesError => {
  return (
    isDuplicatedFundNamesWithFundManagerError(backendError) ||
    isDuplicatedFundNamesWithoutFundManagerError(backendError)
  )
}

const isDuplicatedFundManagerWebsiteError = (
  backendError: BackendError
): backendError is DuplicatedFundManagerWebsiteError =>
  (backendError as DuplicatedFundManagerWebsiteError).errors?.includes?.(
    ALREADY_TAKEN_ERROR
  )

const isDuplicatedCompanyWebsiteError = (
  backendError: BackendError
): backendError is DuplicatedCompanyWebsiteError =>
  (backendError as DuplicatedCompanyWebsiteError).errors?.website?.includes?.(
    ALREADY_TAKEN_ERROR
  )

const buildSuggestedCompanyError = (
  companyErrorData: DuplicatedCompanyErrorData
): SuggestionErrors => {
  if (!companyErrorData.id || !companyErrorData.name) {
    return {
      companyErrors: { isHidden: true },
    }
  }

  return {
    companyErrors: {
      website: {
        id: companyErrorData.id,
        name: companyErrorData.name,
        url: `/companies/${companyErrorData.id}`,
      },
    },
  }
}

export const getSuggestionErrors = async (
  error: BackendError,
  values: AddHoldingForm
): Promise<SuggestionErrors | undefined> => {
  if (values.type === HoldingType.FUND) {
    if (isDuplicatedFundNamesError(error)) {
      let names: string[]
      if (isDuplicatedFundNamesWithFundManagerError(error)) {
        names = values.funds!.funds
      } else {
        names = uniq(error.portfolioNames) as string[]
      }

      const holdingsRequests = names.map(async (name) => {
        const fundHoldings = await HoldingsService.getFundHoldingsByName(name)
        return {
          name,
          fundHoldings,
        }
      })
      const holdingsResponses = await Promise.all(holdingsRequests)

      return {
        fundsErrors: values.funds!.funds.map((fund) => {
          if (names.includes(fund)) {
            const existentHoldingsInfo = holdingsResponses.find(
              (holdingResponse) => holdingResponse.name === fund
            )

            if (existentHoldingsInfo?.fundHoldings.length) {
              return existentHoldingsInfo.fundHoldings.map((fundHolding) => ({
                id: fundHolding.id,
                name: `${fund}${
                  fundHolding.group ? ` (${fundHolding.group.name})` : ''
                }`,
                url: `/funds/${fundHolding.id}`,
                holding: fundHolding,
              }))
            }
            return [
              {
                name: fund,
              },
            ]
          }

          return []
        }),
      }
    }

    if (isDuplicatedFundManagerWebsiteError(error)) {
      return buildSuggestedCompanyError(error.company)
    }
  }

  if (values.type === HoldingType.COMPANY) {
    if (isDuplicatedCompanyWebsiteError(error)) {
      return buildSuggestedCompanyError(error.company)
    }
  }

  return undefined
}

export const SUGGESTION_ERROR: string = 'SUGGESTION_ERROR'

export const getInitialErrorsFromSuggestionErrors = (
  suggestionErrors: SuggestionErrors
): AddHoldingFormErrors => {
  return {
    funds: {
      funds: (suggestionErrors.fundsErrors || []).map((fundErrors) =>
        fundErrors.length ? SUGGESTION_ERROR : undefined
      ),
      fundManager: {
        website:
          suggestionErrors.companyErrors?.website ||
          suggestionErrors.companyErrors?.isHidden
            ? SUGGESTION_ERROR
            : undefined,
      },
    },
    company: {
      website:
        suggestionErrors.companyErrors?.website ||
        suggestionErrors.companyErrors?.isHidden
          ? SUGGESTION_ERROR
          : undefined,
    },
  }
}

const fundManagerChanged = (
  previousValues: AddHoldingForm,
  values: AddHoldingForm
): boolean => {
  const nowIncludesFundManager =
    !previousValues.funds?.includeFundManager &&
    values.funds?.includeFundManager

  const fundManagerNameChanged =
    previousValues.funds?.fundManager?.name !== values.funds?.fundManager?.name

  const fundManagerWebsiteChanged =
    previousValues.funds?.fundManager?.website !==
    values.funds?.fundManager?.website

  const fundManagerPointOfContactChanged =
    previousValues.funds?.fundManager?.pointOfContact !==
    values.funds?.fundManager?.pointOfContact

  return (
    nowIncludesFundManager ||
    (!!values.funds?.includeFundManager &&
      (fundManagerNameChanged ||
        fundManagerWebsiteChanged ||
        fundManagerPointOfContactChanged))
  )
}

export const validateServerErrors = (
  errors: AddHoldingFormErrors,
  values: AddHoldingForm,
  previousValues: AddHoldingForm
) => {
  if (values.type === HoldingType.COMPANY) {
    if (
      errors?.company?.website &&
      values.company?.website !== previousValues.company?.website
    ) {
      // eslint-disable-next-line no-param-reassign
      delete errors.company.website
    }
  }

  if (values.type === HoldingType.FUND) {
    values.funds?.funds.forEach((fund, index) => {
      if (
        Array.isArray(errors?.funds?.funds) &&
        errors?.funds?.funds?.[index] &&
        (fund !== previousValues.funds?.funds[index] ||
          fundManagerChanged(previousValues, values))
      ) {
        // eslint-disable-next-line no-param-reassign
        errors.funds.funds[index] = undefined
      }
    })

    const changedWebsite =
      values.funds?.fundManager?.website !==
      previousValues.funds?.fundManager?.website
    const removedFundManager = !values.funds?.includeFundManager

    if (
      errors?.funds?.fundManager?.website &&
      (changedWebsite || removedFundManager)
    ) {
      // eslint-disable-next-line no-param-reassign
      delete errors.funds.fundManager.website
    }
  }
}

export const hasFundsErrors = (errors: AddHoldingFormErrors) => {
  const hasDuplicatedFundNames =
    Array.isArray(errors?.funds?.funds) && errors.funds.funds.some(Boolean)
  const hasntFilledAFundName = typeof errors?.funds?.funds === 'string'
  const hasFundManagerWebsiteError = !!errors?.funds?.fundManager?.website

  return (
    hasDuplicatedFundNames || hasntFilledAFundName || hasFundManagerWebsiteError
  )
}

export const hasServerErrors = (
  errors: AddHoldingFormErrors,
  values: AddHoldingForm
) => {
  return values.type === HoldingType.COMPANY
    ? !!errors?.company?.website
    : hasFundsErrors(errors)
}

export const hasBulkImportServerErrors = (
  errors: AddHoldingBulkImportFormErrors,
  values: AddHoldingForm[]
) => {
  return !!errors.holdings.filter((holdingErrors, index) =>
    hasServerErrors(holdingErrors, values[index])
  ).length
}

export const fillHoldingErrors = async (
  holding: AddHoldingForm,
  holdingDraft: DraftValidationResponse,
  index: number,
  errors: AddHoldingBulkImportFormErrors,
  status: BulkImportSuggestionErrors
) => {
  if (holding.type === HoldingType.COMPANY) {
    const companyDraft = holdingDraft as CompanyDraftValidationResponse

    if (companyDraft.company.errors.website?.includes(ALREADY_TAKEN_ERROR)) {
      const error: DuplicatedCompanyWebsiteError = {
        errors: { website: [ALREADY_TAKEN_ERROR] },
        company: {
          id: companyDraft.company.errors.companyId?.[0],
          name: companyDraft.company.errors.companyName?.[0],
        },
      }
      const suggestionErrors = await getSuggestionErrors(error, holding)

      if (suggestionErrors) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[index] =
          getInitialErrorsFromSuggestionErrors(suggestionErrors)
        // eslint-disable-next-line no-param-reassign
        status.holdings[index] = suggestionErrors
      }
    }
  }

  if (holding.type === HoldingType.FUND) {
    const fundDraft = holdingDraft as FundDraftValidationResponse

    if (fundDraft.fundManager.errors.website?.includes(ALREADY_TAKEN_ERROR)) {
      // Generates single form error from bulk import error
      const error: DuplicatedFundManagerWebsiteError = {
        errors: ALREADY_TAKEN_ERROR,
        company: {
          id: fundDraft.fundManager.errors.companyId?.[0],
          name: fundDraft.fundManager.errors.companyName?.[0],
        },
      }
      const suggestionErrors = await getSuggestionErrors(error, holding)

      if (suggestionErrors) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[index] =
          getInitialErrorsFromSuggestionErrors(suggestionErrors)
        // eslint-disable-next-line no-param-reassign
        status.holdings[index] = suggestionErrors
      }
    }

    const portfolioNamesWithErrors: string[] = []
    fundDraft.funds.forEach(async (fundNameDraft, fundIndex) => {
      if (fundNameDraft.errors.name?.includes(ALREADY_TAKEN_ERROR)) {
        const fund = holding.funds?.funds[fundIndex]!
        portfolioNamesWithErrors.push(fund)
      }
    })

    if (portfolioNamesWithErrors.length) {
      // Generates single form error from bulk import error
      const error: DuplicatedFundNamesError = {
        errors: {
          name: new Array(portfolioNamesWithErrors.length).fill(
            ALREADY_TAKEN_ERROR
          ),
        },
      }

      const suggestionErrors = await getSuggestionErrors(error, holding)

      if (suggestionErrors) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[index] =
          getInitialErrorsFromSuggestionErrors(suggestionErrors)
        // eslint-disable-next-line no-param-reassign
        status.holdings[index] = suggestionErrors
      }
    }
  }
}

export const getHoldingsErrorsFromDrafts = async (
  drafts: DraftValidationResponse[],
  holdings: AddHoldingForm[]
) => {
  const errors: AddHoldingBulkImportFormErrors = {
    holdings: holdings.map(() => ({
      funds: {
        funds: [],
      },
      company: {},
    })),
  }
  const status: BulkImportSuggestionErrors = {
    holdings: [],
  }

  const promises = drafts.map(async (holdingDraft) => {
    const holding = holdings[holdingDraft.index]
    await fillHoldingErrors(
      holding,
      holdingDraft,
      holdingDraft.index,
      errors,
      status
    )
  })
  await Promise.all(promises)

  return {
    errors,
    status,
  }
}

export const setRepeatedFundErrors = (
  funds: string[],
  errors: AddHoldingFormErrors,
  intl: IntlShape
) => {
  const repeatedIndexes: number[] = []

  funds.forEach((fund, index) => {
    if (fund) {
      for (let i = index + 1; i < funds.length; i++) {
        if (fund.trim() === funds[i].trim()) {
          repeatedIndexes.push(i)
        }
      }
    }
  })

  repeatedIndexes.forEach((index) => {
    const fundsErrors = errors.funds?.funds as string[]
    fundsErrors[index] = intl.formatMessage({
      id: 'addHolding.errors.repeatedFundName',
    })
  })
}

export const setRepeatedBulkErrors = (
  holdings: AddHoldingForm[],
  errors: AddHoldingBulkImportFormErrors,
  intl: IntlShape
) => {
  const repeatedFundNamesMap: Map<
    string,
    { holdingIndex: number; fundIndex: number }[]
  > = new Map()
  const repeatedWebsitesMap: Map<string, number[]> = new Map()

  holdings.forEach((holding, holdingIndex) => {
    if (holding.type === HoldingType.FUND) {
      holding.funds?.funds.forEach((fund, fundIndex) => {
        if (fund) {
          if (repeatedFundNamesMap.get(fund.trim())) {
            repeatedFundNamesMap.set(fund.trim(), [
              ...repeatedFundNamesMap.get(fund.trim())!,
              { holdingIndex, fundIndex },
            ])
          } else {
            repeatedFundNamesMap.set(fund.trim(), [])
          }
        }
      })
    } else if (holding.company?.website) {
      if (repeatedWebsitesMap.get(holding.company.website.trim())) {
        repeatedWebsitesMap.set(holding.company.website.trim(), [
          ...repeatedWebsitesMap.get(holding.company.website.trim())!,
          holdingIndex,
        ])
      } else {
        repeatedWebsitesMap.set(holding.company.website.trim(), [])
      }
    }
  })

  repeatedFundNamesMap.forEach((repeatedIndexes) => {
    repeatedIndexes.forEach(({ holdingIndex, fundIndex }) => {
      if (!errors.holdings[holdingIndex]) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[holdingIndex] = {
          funds: {
            funds: [],
          },
          company: {},
        }
      }

      const fundsErrors = errors.holdings[holdingIndex].funds.funds as string[]
      fundsErrors[fundIndex] = intl.formatMessage({
        id: 'addHolding.errors.repeatedFundName',
      })
    })
  })

  repeatedWebsitesMap.forEach((repeatedIndexes) => {
    repeatedIndexes.forEach((holdingIndex) => {
      if (!errors.holdings[holdingIndex]) {
        // eslint-disable-next-line no-param-reassign
        errors.holdings[holdingIndex] = {
          funds: {
            funds: [],
          },
          company: {},
        }
      }

      // eslint-disable-next-line no-param-reassign
      errors.holdings[holdingIndex].company.website = intl.formatMessage({
        id: 'addHolding.errors.repeatedWebsite',
      })
    })
  })
}
