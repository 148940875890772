import { useCallback, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'

import Toast from 'components/Toast'
import {
  getUniversityProgress,
  UniversityAction,
  UniversityActionType,
} from 'utils/functions/university'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupType } from 'selectors/auth'
import {
  setActionCompleted,
  setGroupActionSkipped,
  setUniversityProgress,
  setUserActionSkipped,
  seUniversityActionEnabled,
} from 'actions/university'
import {
  getUniversityActions,
  getUniversityIsLoading,
  getUniversityHasError,
  getPendingActions,
  getCompletedByMeActions,
  getCompletedByGroupActions,
  getUniversityIsActive,
  getUniversityIsEnabled,
} from 'selectors/university'
import usePortfolios from 'utils/hooks/usePortfolios'
import { PortfolioTypes } from 'utils/constants/portfolio'

export const useUniversity = ({ closeUniversity }) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const currentGroupType = useAppSelector(getCurrentGroupType)
  const loading = useAppSelector(getUniversityIsLoading)
  const hasError = useAppSelector(getUniversityHasError)
  const universityActive = useAppSelector(getUniversityIsActive)
  const universityEnabled = useAppSelector(getUniversityIsEnabled)
  const actions = useAppSelector(getUniversityActions)
  const pendingActions = useAppSelector(getPendingActions)
  const completedByMeActions = useAppSelector(getCompletedByMeActions)
  const completedByGroupActions = useAppSelector(getCompletedByGroupActions)
  const isFirstSetProgress = useRef(true)
  const { portfolios: userPortfolios } = usePortfolios()

  useEffect(() => {
    const hasInvestPortfolio = userPortfolios?.some(
      (portfolio) => portfolio.type === PortfolioTypes.INVEST
    )
    if (hasInvestPortfolio) {
      dispatch(
        seUniversityActionEnabled(UniversityAction.MONITOR_INVESTMENTS.id, true)
      )
    }
  }, [dispatch, userPortfolios])

  useEffect(() => {
    if (hasError) {
      Toast.display(intl.messages['univesity.fetchActionsError'], 'error')
    }
  }, [hasError, intl.messages])

  useEffect(() => {
    if (!loading) {
      const progress = getUniversityProgress({
        actions,
        groupType: currentGroupType,
      })
      dispatch(setUniversityProgress(progress, isFirstSetProgress.current))
      isFirstSetProgress.current = false
    }
  }, [loading, actions, currentGroupType, dispatch])

  const onStartAction = useCallback(
    (action) => {
      closeUniversity()
      action?.onStart?.()
      if (
        action.id === UniversityAction.EXPLORE_SETTINGS.id ||
        action.id === UniversityAction.NEED_HELP.id
      ) {
        dispatch(setActionCompleted(action.id))
      }
    },
    [closeUniversity, dispatch]
  )

  const onRedoAction = useCallback(
    (action) => {
      closeUniversity()
      action?.onStart?.()
    },
    [closeUniversity]
  )

  const onSkipAction = useCallback(
    (action) => {
      if (action.actionType === UniversityActionType.USER) {
        dispatch(setUserActionSkipped(action.id))
      } else {
        dispatch(setGroupActionSkipped(action.id))
      }
    },
    [dispatch]
  )

  return {
    universityActive: universityActive && universityEnabled,
    allActions: actions,
    pendingActions,
    completedByMeActions,
    completedByGroupActions,
    loading,
    onStartAction,
    onRedoAction,
    onSkipAction,
  }
}
