import styled from 'styled-components'
import Button from 'ui/Button'
import { breakpoint } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const AdditionInformationWrapper = styled.div`
  margin-top: 1.8rem;
`

export const AdditionInformation = styled.ul<{ padding?: string }>`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  display: grid;
  gap: 1.2rem;
  padding: ${(props) => props.padding || '0'};

  svg {
    color: ${color('lightGray')};
  }

  @media (min-width: ${breakpoint.xsm}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
  }

  @media (min-width: ${breakpoint.tabletLg}) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
`

export const EditCompanyButton = styled(Button)`
  margin-top: 2.6rem;
  width: 100%;
`
