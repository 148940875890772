/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl, FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import { isValidEmail } from 'utils/functions/validation'
import { getUser } from 'selectors/auth'
import Button from 'ui/Button'
import Modal from 'components/Modal'
import BulkAddInput from 'components/BulkAddInput'
import Toast from 'components/Toast'
import EmailService from 'api/EmailService'

import styles from './SendTestEmail.module.scss'

const MAX_TEST_RECIPIENTS = 5

const SendTestEmail = ({ emailUpdate }) => {
  const user = useAppSelector(getUser)
  const [emailList, setEmailList] = useState([])
  const [removeBorderRadius, setRemoveBorderRadius] = useState(false)
  const addInputRef = useRef(null)

  const intl = useIntl()

  const [openModal, setOpenModal] = useState(false)

  const sendTest = async () => {
    if (!emailList.length) {
      Toast.display(intl.messages['createEmail.requiredEmail'], 'error')
      return
    }

    if (emailList.length > MAX_TEST_RECIPIENTS) {
      Toast.display(
        intl.formatMessage(
          { id: 'createEmail.maxEmailsError' },
          { maxRecipients: MAX_TEST_RECIPIENTS }
        ),
        'error'
      )
      return
    }

    const emails = emailList.map((tag) => tag.name)

    try {
      await EmailService.sendTestEmail(emailUpdate.item.id, { emails })

      setOpenModal(false)
      setEmailList([])
      setRemoveBorderRadius(false)
      Toast.display(intl.messages['createEmail.testEmailSent'])
    } catch (err) {
      Toast.display(intl.messages['createEmail.testEmailError'], 'error')
    }
  }

  const onAddEmail = (email) => {
    setEmailList((currTagList) => {
      return [...currTagList, { name: email }]
    })
  }
  const onBulkAddEmail = (tagNames) => {
    setEmailList((currTagList) => {
      return [...currTagList, ...tagNames.map((tagName) => ({ name: tagName }))]
    })
  }

  const onRemoveEmail = (tagName) => {
    setEmailList((currTagList) => {
      return currTagList.filter((tag) => tag.name !== tagName.name)
    })
  }

  const addUserEmailAsTestEmail = () => {
    const isListEmailsEmpty = emailList === ''
    if (isListEmailsEmpty) {
      setEmailList({ name: user.email })
    } else {
      setEmailList((currTagList) => {
        return [...currTagList, { name: user.email }]
      })
    }
  }

  const doesEmailListContainUserEmail = emailList.find(
    (tag) => user.email === tag.name
  )

  const removeBorder = (entry) => {
    window.requestAnimationFrame(() => {
      const target = entry[0]?.target
      if (target.clientHeight > 30) {
        setRemoveBorderRadius(true)
      } else {
        setRemoveBorderRadius(false)
      }
    })
  }

  useLayoutEffect(() => {
    if (addInputRef?.current) {
      new ResizeObserver(removeBorder).observe(addInputRef?.current)
    }
  }, [addInputRef?.current])

  const validateEmail = (tagName, showError = false) => {
    if (!isValidEmail(tagName)) {
      if (showError) {
        Toast.display(intl.messages['general.invalidEmail'], 'error')
      }
      return false
    }
    return true
  }

  return (
    <div className={styles.sendTestWrapper}>
      <Button
        className={styles.btnTestEmail}
        secondary
        onClick={() => setOpenModal(true)}
      >
        <FontAwesomeIcon icon={['fal', 'envelope']} />
        <FormattedMessage id="createEmail.sendTest" />
      </Button>

      <Modal
        show={openModal}
        onHide={() => {
          setOpenModal(false)
          setEmailList([])
          setRemoveBorderRadius(false)
        }}
      >
        <Modal.Header onHide={() => setOpenModal(false)}>
          <Modal.Title>
            <h2>{intl.messages['createEmail.sendATest']}</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.modalBody}>
            <div className={styles.legend}>
              <span>
                <FormattedMessage id="createEmail.sendTestTo" />
              </span>
              {!doesEmailListContainUserEmail && (
                <button onClick={addUserEmailAsTestEmail} type="button">
                  <FormattedMessage id="createEmail.addMyself" />
                </button>
              )}
            </div>
            <BulkAddInput
              list={emailList}
              onAdd={onAddEmail}
              onBulkAdd={onBulkAddEmail}
              onRemove={onRemoveEmail}
              className={classNames(styles.tagsInput, {
                [styles.noBorderRadius]: removeBorderRadius,
              })}
              validationWhenAdding={validateEmail}
              ref={addInputRef}
              placeholder={intl.messages['createEmail.addEmailAddress']}
              allowBulkAdd
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={styles.modalFooter}>
            <Button
              secondary
              onClick={() => {
                setOpenModal(false)
                setEmailList([])
                setRemoveBorderRadius(false)
              }}
            >
              {intl.messages['createEmail.cancel']}
            </Button>
            <Button uppercase primary onClick={sendTest}>
              {intl.messages['createEmail.sendTest']}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

SendTestEmail.propTypes = {
  emailUpdate: PropTypes.shape({
    id: PropTypes.string,
    attachments: PropTypes.array,
    item: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default SendTestEmail
