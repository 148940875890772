import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import CWLoader from 'components/CWLoader'
import Form from 'components/Form'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import FileDragger from 'containers/InvestorManagement/components/FileDragger'
import Button from 'ui/Button'
import { ReactComponent as DataEntry } from 'assets/images/import_csv.svg'
import theme from 'utils/theme'
import SingleHoldingForm from '../SingleHoldingForm/SingleHoldingForm'
import { AddHoldingForm } from '../types'
import * as Styles from './SingleHoldingModal.styles'
import {
  useSingleHoldingModal,
  SingleHoldingModalProps,
} from './useSingleHoldingModal'

const SingleHoldingModal: React.FC<SingleHoldingModalProps> = ({
  onHideModal,
  parseCSV,
  processingFile,
  fileName,
  onCreateNewHoldings,
  name,
  fundManager,
}) => {
  const {
    intl,
    downloadTemplate,
    initialValues,
    validationSchema,
    validate,
    onSubmit,
    loading,
  } = useSingleHoldingModal({
    onHideModal,
    parseCSV,
    processingFile,
    fileName,
    onCreateNewHoldings,
    name,
    fundManager,
  })
  return (
    <Formik<AddHoldingForm>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      {({ submitForm, isValid }) => (
        <Form<AddHoldingForm> {...initialValues}>
          <Styles.ModalBody>
            <Styles.Container>
              <Styles.FormContainer>
                <SingleHoldingForm />
              </Styles.FormContainer>

              <Styles.FileDraggerWrapper>
                <FileDragger onUploadFile={parseCSV}>
                  <DataEntry width="12.2rem" />
                  <Styles.Instructions>
                    <FormattedMessage id="addHolding.dropCSV" />
                  </Styles.Instructions>

                  <Styles.BrowseButton
                    secondary
                    acceptFiles={['.csv']}
                    onUploadFile={(e) => parseCSV(e.target.files[0])}
                  >
                    <FormattedMessage id="addHolding.browseYourFile" />
                  </Styles.BrowseButton>

                  <Styles.HelpMessage onClick={downloadTemplate}>
                    <FormattedMessage id="addHolding.downloadBulkTemplate" />
                  </Styles.HelpMessage>
                </FileDragger>
              </Styles.FileDraggerWrapper>
              {processingFile && (
                <Styles.LoaderWrapper>
                  <Loader
                    loader={<CWLoader color={theme.colors.primaryBlue} />}
                    text={intl.formatMessage(
                      { id: 'bulkImportInvestorsModal.importingCSVFile' },
                      { fileName }
                    )}
                    legend={intl.formatMessage({
                      id: 'bulkImportInvestorsModal.importingCSVFileLegend',
                    })}
                  />
                </Styles.LoaderWrapper>
              )}
            </Styles.Container>
          </Styles.ModalBody>
          <Modal.Footer>
            <Styles.Footer>
              <Button onClick={onHideModal} secondary>
                {intl.messages['common.cancel']}
              </Button>

              <Button
                autoWidth
                primary
                blue
                onClick={submitForm}
                disabled={!isValid || processingFile || loading}
                isLoading={loading}
              >
                {intl.messages['general.add']}
              </Button>
            </Styles.Footer>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  )
}

export default SingleHoldingModal
