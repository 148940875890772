export enum UpdateEntityType {
  ALL_PORTFOLIOS = 'ALL_PORTFOLIOS',
  PORTFOLIO = 'PORTFOLIO',
  PORTFOLIO_COMPANIES = 'PORTFOLIO_COMPANIES',
  COMPANY = 'COMPANY',
  FUND = 'FUND',
  INVESTOR = 'INVESTOR',
  MY_UPDATES = 'MY_UPDATES',
  EMAILS = 'EMAILS',
}
