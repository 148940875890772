import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HoverColumn } from 'ui/Table/Row/Row.styles'
import IconButton from 'ui/IconButton'
import * as Styles from './FloatButtonColumn.styles'

const EditButtonColumn = ({ onClick, forceVisible }) => {
  return (
    <Styles.EditButtonColumnWrapper>
      <HoverColumn id="editButtonColumn" forceVisible={forceVisible}>
        <IconButton icon="pen" onClick={onClick} />
      </HoverColumn>
    </Styles.EditButtonColumnWrapper>
  )
}

EditButtonColumn.propTypes = {
  onClick: PropTypes.func.isRequired,
  forceVisible: PropTypes.bool,
}

export default EditButtonColumn
