import axiosClient from './httpClient'

export default class UniversityService {
  static normalizeResponse(response) {
    const {
      data: { result, entities },
    } = response

    return result.map((actionId) => ({
      ...entities.tutorials[actionId],
      isUserAction: !!entities.tutorials[actionId]?.group_id,
    }))
  }

  static getUniversityActions = async () => {
    const response = await axiosClient().get('/tutorials')
    return this.normalizeResponse(response)
  }

  static createGroupAction = async (actionId, skipped) => {
    return axiosClient().post('/tutorials', {
      tutorial: { name: actionId, skipped },
    })
  }

  static createUserAction = (actionId, skipped) => {
    return axiosClient().post('/tutorials', {
      tutorial: { name: actionId, skipped },
    })
  }

  static skipGroupAction = (actionId) => {
    return this.createGroupAction(actionId, true)
  }

  static skipUserAction = (actionId) => {
    return this.createUserAction(actionId, true)
  }
}
