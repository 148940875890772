import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQueryClient } from '@tanstack/react-query'

import { getCurrentGroupId } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { CompanyHoldingData } from 'utils/types/company'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { portfolioKeys } from 'utils/queries/portfolios'
import { maxSize } from 'utils/constants/breakpoint'
import Button from 'ui/Button'
import Card from 'components/Card'
import CreateFundPortfolioModal from 'components/CreateFundPortfolioModal'
import AddHoldingModal from 'components/AddHoldingModal/AddHoldingModal'

import FundsList from './FundsList/FundsList'
import * as Styles from './FundCompanyProfileFunds.styles'

interface CompanyProfileFundsProps {
  fundCompany: CompanyHoldingData
}

const FundCompanyProfileFunds: React.FC<CompanyProfileFundsProps> = ({
  fundCompany,
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [showModal, setShowModal] = useState<boolean>(false)
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const isFundCompanyOwner = fundCompany.groupId === currentGroupId

  const openModal = () => setShowModal(true)

  const onFundAdded = () => {
    queryClient.invalidateQueries(
      portfolioKeys.publicFundsByFundCompanyId(fundCompany.id)
    )
  }

  return (
    <>
      <Card isExpandable padding="1.4rem 2.4rem">
        <Card.Header>
          <Styles.HeaderContent>
            <Styles.NormalTitle>
              {intl.formatMessage({ id: 'profiles.fundsDeals' })}
            </Styles.NormalTitle>
            {!isMobile && (
              <Styles.ButtonsContainer>
                <Button onClick={openModal} link add>
                  <FormattedMessage id="profiles.addFund" />
                </Button>
              </Styles.ButtonsContainer>
            )}
          </Styles.HeaderContent>
        </Card.Header>
        <Styles.CardBody>
          <FundsList fundCompanyId={fundCompany.id} />
        </Styles.CardBody>
      </Card>

      {isFundCompanyOwner ? (
        <CreateFundPortfolioModal
          onHide={() => setShowModal(false)}
          isOpen={showModal}
          onFundPortfolioCreated={onFundAdded}
        />
      ) : (
        <AddHoldingModal
          onHideModal={() => setShowModal(false)}
          isModalOpen={showModal}
          onCreateNewHoldings={onFundAdded}
          fundManager={{
            name: fundCompany.group.name,
            website: fundCompany.website || undefined,
            pointOfContact: fundCompany.group.email || undefined,
          }}
        />
      )}
    </>
  )
}

export default FundCompanyProfileFunds
