import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const LinkStyles = styled.span`
  cursor: pointer;
  color: ${color('primaryBlue')};
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`
