import Toggle from 'components/Toggle'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Preview = styled.div<{
  withBackground: boolean
  isAnImage: boolean
}>`
  border-radius: 0.8rem;
  display: flex;
  font-size: 1.4rem;
  height: fit-content;
  justify-content: center;
  margin-bottom: 1.6rem;
  max-height: 21rem;
  min-height: 11rem;
  overflow: hidden;
  position: relative;

  ${({ withBackground }) =>
    withBackground &&
    css`
      background-color: ${color('veryDarkGray')};
    `}

  ${({ isAnImage }) =>
    isAnImage &&
    css`
      align-items: center;
    `}
    

  img {
    max-width: 100%;
  }

  .react-pdf__Document {
    button {
      display: none;
    }
  }
`

export const Actions = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-bottom: 2rem;
  svg {
    font-size: 1.6rem;
  }
`

export const GroupManagedToggle = styled(Toggle)`
  p {
    color: ${color('darkBlue')};
    font-weight: 700;
    font-size: 1.4rem;
  }
`
