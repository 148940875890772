import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  gap: 1.7rem;
  padding: 1.6rem 2.4rem;

  &:not(:last-child) {
    border-bottom: 1px solid ${color('chatBorder')};
  }

  &:hover {
    background-color: ${color('veryLightBlue', 0.4)};
    cursor: pointer;
  }
`

export const ColumnOne = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.4rem;
  color: ${color('darkGray')};
  min-width: 0;
`

export const ColumnTwo = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
`

const RowStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const ChannelName = styled.div`
  ${RowStyles}
  font-size: 1.6rem;
  line-height: 2.2rem;

  div {
    height: 100%;
  }
`

export const ChannelMessage = styled.div`
  ${RowStyles}
  font-size: 1.4rem;
  line-height: 1.7rem;
`

export const ArrowRight = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  color: ${color('darkBlue')};
  transform: rotate(-90deg);
  cursor: pointer;
`
