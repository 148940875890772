import Avatar from 'components/Avatar'
import CompanySocials from 'components/CompanySocials'
import { getInitials } from 'utils/functions/user'
import { Investor } from 'utils/types/investors'
import {
  BasicInformationWrapper,
  CompanyName,
  ImageWrapper,
} from '../CompanyHeader/HeaderBasicInformation.styles'

interface Props {
  investor: Investor
}

const HeaderBasicInformation = ({ investor }: Props) => {
  return (
    <BasicInformationWrapper>
      <ImageWrapper>
        <Avatar
          avatarStyle="avatarCircleLarge"
          image={investor?.logo?.url}
          initials={getInitials(investor.name)}
          grayBorder
        />
      </ImageWrapper>
      <CompanyName>{investor.name}</CompanyName>
      <CompanySocials centerLinks withTitle={false} companyInfo={investor} />
    </BasicInformationWrapper>
  )
}

export default HeaderBasicInformation
