import { Plan } from 'utils/types/signUp'
import { SubscriptionPlan } from 'utils/types/subscriptions'

export const getPlansById = (
  highlightPlans: boolean = false
): Record<SubscriptionPlan, Plan> => ({
  [SubscriptionPlan.LITE]: {
    id: SubscriptionPlan.LITE,
    subscriptionPlan: SubscriptionPlan.LITE,
    planOrder: 0,
    name: 'signup.plans.lite.name',
    description: 'signup.plans.lite.description',
    amount: {
      monthly: 50,
      annually: 40,
    },
    amountSubtext: 'signup.plans.perMonth',
    features: [
      { title: 'oneInvestPortfolio' },
      { title: 'upTo25Transactions' },
      { title: 'csvExports' },
    ],
    upgradeFeatures: [],
    cancelFeatures: [
      { title: 'onlineOnboarding' },
      { title: 'investmentPortfoliosCount', count: 1 },
      { title: 'pipelinesCount', count: 5 },
      { title: 'storageMB', count: 500 },
      { title: 'seats', count: 1 },
      { title: 'createAndShareUpdates' },
      { title: 'clockworkChat' },
      { title: 'emailAndChatSupport' },
    ],
    buttonText: 'signup.plans.selectPlan',
  },
  [SubscriptionPlan.INDIVIDUAL]: {
    id: SubscriptionPlan.INDIVIDUAL,
    subscriptionPlan: SubscriptionPlan.INDIVIDUAL,
    planOrder: 1,
    name: 'signup.plans.basic.name',
    description: 'signup.plans.basic.description',
    amount: {
      monthly: 165,
      annually: 150,
    },
    amountSubtext: 'signup.plans.perMonth',
    features: [
      { title: 'unlimitedPortfolios' },
      { title: 'unlimitedTransactions' },
      { title: 'trackMetrics' },
    ],
    upgradeFeatures: [
      { title: 'onlineOnboarding' },
      { title: 'unlimitedPortfolios' },
      { title: 'unlimitedPipelines' },
      { title: 'storageGB', count: 1 },
      { title: 'countSeats', count: 1 },
      { title: 'createAndShareUpdates' },
      { title: 'createAndTrackMetrics' },
      { title: 'clockworkChat' },
      { title: 'emailAndChatSupport' },
      { title: 'customEmailInboxes' },
      { title: 'thirdPartyAccess' },
    ],
    cancelFeatures: [
      { title: 'onlineOnboarding' },
      { title: 'yourInvestmentPortfolio' },
      { title: 'accessYourPipelines' },
      { title: 'storageGB', count: 1 },
      { title: 'seats', count: 1 },
      { title: 'createAndShareUpdates' },
      { title: 'createAndTrackMetrics' },
      { title: 'clockworkChat' },
      { title: 'emailAndChatSupport' },
      { title: 'thirdPartyAccess' },
    ],
    buttonText: 'signup.plans.selectPlan',
    highlighted: highlightPlans,
  },
  [SubscriptionPlan.PRO]: {
    id: SubscriptionPlan.PRO,
    subscriptionPlan: SubscriptionPlan.PRO,
    planOrder: 2,
    name: 'signup.plans.pro.name',
    description: 'signup.plans.pro.description',
    amount: {
      monthly: 585,
      annually: 550,
    },
    amountSubtext: 'signup.plans.perMonth',
    features: [
      { title: 'sharedInbox' },
      { title: 'addSeats' },
      { title: 'reportStakeholders' },
    ],
    upgradeFeatures: [
      { title: 'onlineOnboarding' },
      { title: 'unlimitedPortfolios' },
      { title: 'unlimitedPipelines' },
      { title: 'storageGB', count: 2 },
      { title: 'countSeats', count: 5 },
      { title: 'createAndShareUpdates' },
      { title: 'createAndTrackMetrics' },
      { title: 'clockworkChat' },
      { title: 'emailAndChatSupport' },
      { title: 'customEmailInboxes' },
      { title: 'thirdPartyAccess' },
    ],
    cancelFeatures: [
      { title: 'yourInvestmentPortfolios' },
      { title: 'accessYourPipelines' },
      { title: 'storageGB', count: 2 },
      { title: 'countSeats', count: 5 },
      { title: 'createAndShareUpdates' },
      { title: 'createAndTrackMetrics' },
      { title: 'clockworkChat' },
      { title: 'emailAndChatSupport' },
      { title: 'customEmailInboxes' },
      { title: 'thirdPartyAccess' },
    ],
    buttonText: 'signup.plans.selectPlan',
    featured: true,
  },
  [SubscriptionPlan.ORGANIZATION]: {
    id: SubscriptionPlan.ORGANIZATION,
    planOrder: 3,
    name: 'signup.plans.organization.name',
    description: 'signup.plans.organization.description',
    amount: {
      contact: true,
    },
    amountSubtext: 'signup.plans.customPricing',
    features: [
      { title: 'customReporting' },
      { title: 'teamSupport' },
      { title: 'suitYourNeeds' },
    ],
    upgradeFeatures: [],
    cancelFeatures: [],
    buttonText: 'signup.plans.getInTouch',
  },
})

export const getPlans = (_highlightPlans: boolean): Plan[] => {
  const plansById = getPlansById(false) // change to highlightPlans to re-enable plan highlight
  return [
    // plansById[SubscriptionPlan.LITE], // LITE plan is not available temporary
    plansById[SubscriptionPlan.INDIVIDUAL],
    plansById[SubscriptionPlan.PRO],
    plansById[SubscriptionPlan.ORGANIZATION],
  ]
}
