import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const DraftIconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: ${color('darkGray')};
  span {
    font-size: 1.4rem;
    padding-top: 0.3rem;
  }
`
