import { findDayChangeIndices } from 'containers/Files/helpers'
import { useIntl } from 'react-intl'
import { MixedContent } from 'utils/types/files'

interface FilesMemo {
  contents: MixedContent[]
  isLoading: boolean
  isGroupedByDate: boolean
}

const FAKE_ARR_LENGTH = 18

const useFilesMemo = ({ contents, isLoading, isGroupedByDate }: FilesMemo) => {
  const intl = useIntl()

  if (isLoading && isGroupedByDate) {
    return {
      dates: ['1', '2', '3'],
      groupedFilesByDate: {
        '1': [{}, {}, {}],
        '2': [{}, {}, {}, {}],
        '3': [{}, {}, {}, {}, {}],
      },
    }
  }

  if (isLoading && !isGroupedByDate) {
    const fakeArrForSkeleton = Array.from(
      { length: FAKE_ARR_LENGTH },
      () => ({})
    ) as MixedContent[]
    return { fakeArrForSkeleton }
  }

  if (contents.length === 0) {
    return { dates: [], groupedFilesByDate: {} }
  }

  const datesIndexes = findDayChangeIndices({ contents, intl, isLoading })
  const groupedFilesByDate: Record<string, MixedContent[]> = {}

  datesIndexes.forEach((_, index) => {
    const startIndex = datesIndexes[index]
    const endIndex =
      index + 1 < datesIndexes.length
        ? datesIndexes[index + 1]
        : contents.length
    const date = contents[startIndex].createdAt
    const contentsInDate = contents.slice(startIndex, endIndex)

    groupedFilesByDate[date.toLocaleString()] = contentsInDate
  })

  const dates = Object.keys(groupedFilesByDate)

  return {
    dates,
    groupedFilesByDate,
  }
}

export default useFilesMemo
