import { useEffect, useState } from 'react'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import { useGetUserGroup } from 'utils/hooks/queries/useUserGroupQuery'
import { AssistanceCardType } from 'utils/types/onboardingModal'
import { useHelpArticlesMutation } from './useHelpArticlesMutation'

export const useHelpArticles = () => {
  const { showHelpArticles } = usePlanFeatures()

  const [assistantCards, setAssistantCards] = useState({
    [AssistanceCardType.INVEST_PORTFOLIO]: false,
    [AssistanceCardType.PIPELINE]: false,
    [AssistanceCardType.HOLDING]: false,
    [AssistanceCardType.UPDATE]: false,
    [AssistanceCardType.UPLOAD_FILES]: false,
    [AssistanceCardType.CHAT]: false,
  })

  const { data: userGroup } = useGetUserGroup({
    enabled: showHelpArticles,
  })
  const helpArticlesMutation = useHelpArticlesMutation()

  useEffect(() => {
    if (userGroup) {
      setAssistantCards({
        [AssistanceCardType.INVEST_PORTFOLIO]:
          !userGroup.settings?.visibleFields?.investmentsTooltip,
        [AssistanceCardType.PIPELINE]:
          !userGroup.settings?.visibleFields?.trackTooltip,
        [AssistanceCardType.HOLDING]:
          !userGroup.settings?.visibleFields?.holdingsTooltip,
        [AssistanceCardType.UPDATE]:
          !userGroup.settings?.visibleFields?.updatesTooltip,
        [AssistanceCardType.UPLOAD_FILES]:
          !userGroup.settings?.visibleFields?.filesTooltip,
        [AssistanceCardType.CHAT]:
          !userGroup.settings?.visibleFields?.chatTooltip,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGroup])

  const onCloseAssistanceCard = (type: AssistanceCardType) => {
    setAssistantCards((prev) => ({
      ...prev,
      [type]: false,
    }))

    helpArticlesMutation.mutate({
      userGroupId: userGroup?.id!,
      helpArticle: type,
    })
  }

  const showAssistantCard = (type: AssistanceCardType) => {
    return showHelpArticles && assistantCards[type]
  }

  return {
    showAssistantCard,
    onCloseAssistanceCard,
  }
}
