import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  max-width: 100%;
`

export const OptionText = styled.div`
  flex: 1;
  max-width: 100%;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('darkBlue')};
`
