/* eslint-disable no-shadow */
import {
  Investor,
  InvestmentVehicle,
  IndexInvestor,
  IndexInvestmentVehicle,
  InvestorPayload,
  InvestmentVehiclePayload,
} from 'utils/types/investors'
import {
  InvestmentVehicleDraft,
  BulkInvestmentVehicleFormDraftValues,
} from 'utils/types/bulkImports/investmentVehicles'
import {
  BulkInvestorFormDraftValues,
  InvestorDraft,
} from 'utils/types/bulkImports/investors'
import { BulkPlainTypes } from 'utils/functions/bulk'
import { TransactionItem } from 'utils/types/update'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import { DraftValidation, SortDirectionType } from 'utils/types/common'
import convertJsonToFormData from 'utils/functions/convertJsonToFormData'

import {
  CapitalStatement,
  FundPortfolioInvestor,
  FundSummary,
} from 'utils/types/funds'
import { FundPortfolio, IndexFundPortfolio } from 'utils/types/portfolios'

import axiosClient from './httpClient'

export type OrderByProps =
  | 'name'
  | 'portfolios_total_committed_capital'
  | 'portfolios_total_capital_called'

export type PortfolioType = 'track' | 'invest' | 'fund'

export interface Filters {
  name?: string
  fundPortfolioId?: string[]
  fundPortfoliosIdNotIn?: string[]
  orderBy?: OrderByProps
  direction?: SortDirectionType
  page?: number
  investorId?: string[]
  investmentVehicleId?: string
  withInvestmentVehicle?: boolean
  portfolioType?: PortfolioType
  idNotIn?: string[]
  pageSize?: number
}

export enum TransactionFilter {
  INVESTMENT_VEHICLE = 'investment_vehicle_investor_investment_vehicle_id_eq',
  FUND_PORTFOLIO_INVESTOR = 'fund_portfolio_investor_id_eq',
}

enum FundPortfoliosFilter {
  INVESTMENT_VEHICLE_ID_EQ = 'q[portfolio_vehicle_investor_transactions_investment_vehicle_investor_investment_vehicle_id_eq]',
  INVESTOR_ID_IN = 'q[investor_group_id_in]',
  FUND_PORTFOLIO_ID_EQ = 'q[fund_portfolio_id_eq]',
}

interface FetchTransactionsProps {
  id: string
  filter: TransactionFilter
  page?: number
  pageSize?: number
  sortId: string
  sortDirection: SortDirectionType
}

export default class InvestorManagementService {
  static async fetchInvestors(
    filters: Filters
  ): Promise<{ results: IndexInvestor[]; total: number }> {
    const {
      data: { result, entities },
      headers,
    } = await axiosClient().get('/investor_groups', {
      params: {
        page: filters.page,
        order_by: filters.orderBy,
        direction: filters.direction,
        per_page: filters.pageSize,
        'q[fund_portfolios_id_in]': filters.fundPortfolioId,
        'q[name_cont]': filters.name,
        'q[investment_vehicles_id_not_null]': filters.withInvestmentVehicle,
        'q[id_not_in]': filters.idNotIn,
        'q[fund_portfolios_id_not_in]': filters.fundPortfoliosIdNotIn,
      },
    })

    return {
      results: result.map((investorId) =>
        ResponseNormalizer.normalizeIndexInvestor(investorId, entities)
      ),
      total: Number(headers.total),
    }
  }

  static async fetchInvestorsByName(names: string[]): Promise<IndexInvestor[]> {
    const {
      data: { result, entities },
    } = await axiosClient().get('/investor_groups', {
      params: {
        'q[name_matches_any]': names,
      },
    })

    return result.map((investorId) =>
      ResponseNormalizer.normalizeIndexInvestor(investorId, entities)
    )
  }

  static async fetchInvestor(investorId: string): Promise<Investor> {
    const {
      data: { result, entities },
    } = await axiosClient().get(`/investor_groups/${investorId}`)

    return ResponseNormalizer.normalizeInvestor(result, entities)
  }

  static fetchFundPortfoliosSummary = async (
    investorOnlyNumbers: boolean
  ): Promise<FundSummary> => {
    const url = `/fund_portfolios/aggregate_fund_summary`
    const response = await axiosClient().get(url, {
      params: { lp_only_numbers: investorOnlyNumbers },
    })

    return response.data.aggregateFundSummary
  }

  static fetchInvestorFundPortfolios = async (
    investorId: string
  ): Promise<IndexFundPortfolio[]> => {
    const {
      data: { result, entities },
    } = await axiosClient().get(
      `/investor_groups/${investorId}/fund_portfolios`
    )

    return result.map((portfolioId: string) =>
      ResponseNormalizer.normalizeIndexFundPortfolio(portfolioId, entities)
    )
  }

  static async createInvestor(
    investorData: InvestorPayload
  ): Promise<Investor> {
    const formData = convertJsonToFormData({ investor_group: investorData })

    const response = await axiosClient(true).post('/investor_groups', formData)

    return ResponseNormalizer.normalizeInvestor(
      response.data.result,
      response.data.entities
    )
  }

  static async editInvestor(
    investorId: string,
    investorData: Partial<InvestorPayload>,
    currentGroupId?: string
  ): Promise<Investor> {
    const formData = convertJsonToFormData({ investor_group: investorData })

    if (!investorData.founded_date) {
      formData.set('investor_group[founded_date]', '')
    }

    const response = await axiosClient(true, {
      'group-id': currentGroupId,
    }).patch(`/investor_groups/${investorId}`, formData)
    return ResponseNormalizer.normalizeInvestor(
      response.data.result,
      response.data.entities
    )
  }

  static async deleteInvestor(investorId: string): Promise<void> {
    return axiosClient().delete(`/investor_groups/${investorId}`)
  }

  static async sendInvestorInvites(
    investorId: string,
    userIds: string[]
  ): Promise<void> {
    return axiosClient().post(
      `/investor_groups/${investorId}/send_invitations`,
      {
        userIds,
      }
    )
  }

  static async fetchInvestmentVehicles(
    filters: Filters
  ): Promise<{ results: IndexInvestmentVehicle[]; total: number }> {
    const {
      data: { result, entities },
      headers,
    } = await axiosClient().get('/investment_vehicles', {
      params: {
        page: filters.page,
        order_by: filters.orderBy,
        direction: filters.direction,
        'q[fund_portfolios_id_in]': filters.fundPortfolioId,
        'q[name_cont]': filters.name,
        'q[investor_group_id_in]': filters.investorId,
      },
    })

    return {
      results: result.map((vehicleId: string) =>
        ResponseNormalizer.normalizeIndexInvestmentVehicle<IndexInvestmentVehicle>(
          vehicleId,
          entities
        )
      ),
      total: Number(headers.total),
    }
  }

  static async fetchInvestmentVehicle(
    investmentVehicleId: string
  ): Promise<InvestmentVehicle> {
    const {
      data: { result, entities },
    } = await axiosClient().get(`/investment_vehicles/${investmentVehicleId}`)

    return ResponseNormalizer.normalizeInvestmentVehicle(result, entities)
  }

  static async createInvestmentVehicle(
    payload: InvestmentVehiclePayload
  ): Promise<InvestmentVehicle> {
    const {
      data: { result, entities },
    } = await axiosClient().post('/investment_vehicles', payload)

    return ResponseNormalizer.normalizeInvestmentVehicle(result, entities)
  }

  static async editInvestmentVehicle(
    investmentVehicleId: string,
    payload: Partial<InvestmentVehiclePayload>
  ): Promise<InvestmentVehicle> {
    const {
      data: { result, entities },
    } = await axiosClient().patch(
      `/investment_vehicles/${investmentVehicleId}`,
      payload
    )

    return ResponseNormalizer.normalizeInvestmentVehicle(result, entities)
  }

  static async deleteInvestmentVehicle(
    investmentVehicleId: string
  ): Promise<void> {
    return axiosClient().delete(`/investment_vehicles/${investmentVehicleId}`)
  }

  static async fetchTransactions({
    id,
    filter,
    page,
    pageSize,
    sortId,
    sortDirection,
  }: FetchTransactionsProps): Promise<TransactionItem[]> {
    const queryParams = {
      page,
      per_page: pageSize,
      '[sorts]':
        sortId && sortDirection ? [`${sortId} ${sortDirection}`] : undefined,
    }

    const {
      data: { result, entities },
    } = await axiosClient().get(`/transactions?q[${filter}]=${id}`, {
      params: queryParams,
    })

    return result.map((transactionId) =>
      ResponseNormalizer.normalizeTransactionItem(transactionId, entities)
    )
  }

  static async fetchFundsPortfolioInvestors(
    filters: Filters
  ): Promise<FundPortfolioInvestor[]> {
    const {
      data: { result, entities },
    } = await axiosClient().get('/fund_portfolio_investors', {
      params: {
        page: filters.page,
        order_by: filters.orderBy,
        direction: filters.direction,
        [FundPortfoliosFilter.INVESTOR_ID_IN]: filters.investorId,
        [FundPortfoliosFilter.INVESTMENT_VEHICLE_ID_EQ]:
          filters.investmentVehicleId,
        [FundPortfoliosFilter.FUND_PORTFOLIO_ID_EQ]: filters.fundPortfolioId,
      },
    })

    return result.map((fundPorfolioInvestorId) =>
      ResponseNormalizer.normalizeFundPortfolioInvestor(
        fundPorfolioInvestorId,
        entities
      )
    )
  }

  static async fetchCapitalStatementFromFundPortfolioInvestor(
    fundPortfolioInvestorId: string
  ): Promise<CapitalStatement> {
    const { data } = await axiosClient().get(
      '/fund_portfolio_investors/capital_statement',
      {
        params: {
          'q[fund_portfolio_id_in]': fundPortfolioInvestorId,
        },
      }
    )
    return data.capitalStatement
  }

  static async fetchCapitalStatementForInvestor(): Promise<CapitalStatement> {
    const { data } = await axiosClient().get(
      '/fund_portfolio_investors/capital_statement'
    )
    return data.capitalStatement
  }

  static async fetchFundPortfolioInvestor(
    fundPortfolioInvestorId: string
  ): Promise<FundPortfolioInvestor> {
    const {
      data: { result, entities },
    } = await axiosClient().get(
      `/fund_portfolio_investors/${fundPortfolioInvestorId}`
    )

    return ResponseNormalizer.normalizeFundPortfolioInvestor(result, entities)
  }

  static async fetchFundPortfolioPreview(
    portfolioId: string,
    fundPortfolioInvestorId: string
  ): Promise<FundPortfolio> {
    const {
      data: { result, entities },
    } = await axiosClient().get(
      `/fund_portfolios/${portfolioId}/preview/${fundPortfolioInvestorId}`
    )

    return ResponseNormalizer.normalizePortfolio<FundPortfolio>(
      result,
      entities
    )
  }

  static async fetchCapitalStatementPreview(
    fundPortfolioId: string,
    fundPortfolioInvestorId: string
  ): Promise<CapitalStatement> {
    const { data } = await axiosClient().get(
      `/fund_portfolios/${fundPortfolioId}/preview_capital_statement/${fundPortfolioInvestorId}`,
      {
        params: {
          'q[fund_portfolio_id_in]': fundPortfolioInvestorId,
        },
      }
    )
    return data.capitalStatement
  }

  static async bulkCreateInvestors(investors: InvestorPayload[]) {
    const formData = convertJsonToFormData({
      investor_groups: investors,
    })

    await axiosClient(true).post('/investor_groups/bulk', formData)
  }

  static async validateBulkInvestorsImport(
    investors: InvestorDraft[]
  ): Promise<DraftValidation<BulkInvestorFormDraftValues>[]> {
    const response = await axiosClient().post(`/investor_groups/drafts`, {
      object_name: BulkPlainTypes.INVESTOR_GROUP,
      object_instances: investors,
    })

    return response.data
  }

  static async getBulkExport(isInvestors?: boolean): Promise<string> {
    const url = `${
      isInvestors ? 'investor_groups' : 'investment_vehicles'
    }/export`

    const { data } = await axiosClient().get(url)

    return data
  }

  static async bulkCreateInvestmentVehicles(
    vehicles: InvestmentVehiclePayload[]
  ) {
    await axiosClient().post('/investment_vehicles/bulk', {
      investment_vehicles: vehicles.map((vehicle) => vehicle.investmentVehicle),
    })
  }

  static async validateBulkInvestmentVehiclesImport(
    vehicles: InvestmentVehicleDraft[]
  ): Promise<DraftValidation<BulkInvestmentVehicleFormDraftValues>[]> {
    const response = await axiosClient().post(`/investment_vehicles/drafts`, {
      object_name: BulkPlainTypes.INVESTMENT_VEHICLE,
      object_instances: vehicles,
    })

    return response.data
  }
}
