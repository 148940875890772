import UpdateIconFactoryV2 from 'components/UpdateIconFactoryV2'
import dayjs from 'dayjs'
import { getUpdateItemTitle } from 'utils/functions/updates'
import { useFetchUpdateQuery } from 'utils/hooks/queries/useUpdateQuery'
import { UpdateType } from 'utils/types/common'
import { AttachmentPaths } from 'containers/Chat/types'
import { getHoldingImage } from 'utils/types/company'
import * as Styles from './HoldingAttachment.styles'

type Props = {
  updateId: string
  updateType: UpdateType
  link: string
  matchedPath: AttachmentPaths
}

const UpdateAttachmentBox = ({
  updateId,
  updateType,
  link,
  matchedPath,
}: Props) => {
  const { data: update } = useFetchUpdateQuery({
    updateId,
    updateType,
    preventRedirectOnPermissionsError: true,
    retry: false,
    staleTime: 500,
  })

  if (!update) return null

  const getIconProps = () => {
    switch (matchedPath) {
      case AttachmentPaths.COMPANY_UPDATE:
      case AttachmentPaths.FUND_UPDATE:
        return {
          logo: getHoldingImage(update.holding!),
          tooltip: update.holding!.name,
        }
      case AttachmentPaths.PORTFOLIO_UPDATE:
      case AttachmentPaths.INVESTMENTS_PORTFOLIO_UPDATE:
        return {
          portfolioType: update.portfolio?.type || update.fundPortfolio?.type,
          tooltip:
            update.portfolio?.name ||
            update.fundPortfolio?.name ||
            update.holding?.name,
          logo: update.holding ? getHoldingImage(update.holding) : undefined,
        }

      default:
        return {}
    }
  }

  return (
    <Styles.Container href={link} target="_blank">
      <UpdateIconFactoryV2 updateType={updateType} {...getIconProps()} />
      <div>
        <Styles.AttachmentTitle>
          {getUpdateItemTitle(update)}
        </Styles.AttachmentTitle>
        <Styles.Date>
          {dayjs(update.date).format('dddd DD MMMM - h:mmA')}
        </Styles.Date>
      </div>
    </Styles.Container>
  )
}

export default UpdateAttachmentBox
