import styled from 'styled-components'

export const FMVTooltipContainer = styled.div``

export const FMVTooltipRow = styled.div``

export const FMVTooltipLabel = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  color: white;
`
export const FMVTooltipValue = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  color: white;
`

export const Separator = styled.div`
  height: 0.1rem;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0.5rem 0;
`

export const FMVTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
`

export const FMVDescription = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  width: 19.2rem;
  a {
    font-weight: bold;
    text-decoration: underline;
  }
`
