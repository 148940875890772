import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { DataVisualizationColor } from 'utils/theme'
import { Nullable } from 'utils/types/common'
import { MetricSources } from 'utils/types/metrics'
import { useMetricsSandbox } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import { getChartBar, getChartLine } from './utils/charts'
import {
  CHART_MODE,
  ChartConfig,
  ChartMetric,
  ChartCriteriaType,
  ChartPeriods,
  DEFAULT_CHART_Y_AXIS_ID,
  CUSTOM_CHART_Y_AXIS_ID,
} from './types'
import { Config } from './MultiViewChart'
import useAxis from '../hooks/useAxis'

const TICKS_QUANTITY = 4

export interface InitialChartConfig {
  charts?: Record<string, ChartConfig>
  yAxis?: Record<string, YAxisConfig>
}

interface Props {
  initialConfig?: InitialChartConfig
  groupingDataCriteria: ChartCriteriaType
  chartMetrics: ChartMetric[]
  colors: Record<string, DataVisualizationColor>
}

export interface YAxisConfig {
  id: string
  placement: 'left' | 'right'
  domain: number[]
  ticks: number[]
  isVisible: boolean
}

const useChartConfig = ({
  chartMetrics,
  colors,
  groupingDataCriteria,
  initialConfig = {},
}: Props) => {
  const intl = useIntl()
  const { changeMetricVisibility, isMetricVisible, defaultChartMode } =
    useMetricsSandbox()

  const [yAxisConfig, setYAxisConfig] = useState<YAxisConfig[]>(
    Object.values(initialConfig.yAxis ?? {}) ?? []
  )

  const [chartConfig, setChartConfig] = useState<Record<string, ChartConfig>>(
    initialConfig.charts ?? {}
  )

  const [chartModeButtonValue, setChartModeButtonValue] =
    useState<Nullable<CHART_MODE>>(defaultChartMode)

  /*
    Get all the metrics from the Chart Metrics and filter them based on the groupingDataCriteria.
    This is because when the groupingDataCriteria is changed, the yAxis domain should be recalculated.
    Reason: we display the last value of the period (quarter or year) in the chart, if the user change it from quarterly to yearly,
    the last value of the year could be potentially too different from the values of its quarters, so the yAxis domain should be recalculated.
  */
  const metricsData = useMemo(
    () =>
      Array.from(
        new Set(
          chartMetrics
            .filter((chartMetric) => {
              if (groupingDataCriteria === ChartPeriods.QUARTERLY) {
                return chartMetric.quarter !== ''
              }
              if (groupingDataCriteria === ChartPeriods.YEARLY) {
                return chartMetric.quarter === '' && chartMetric.year !== ''
              }
              return true
            })
            .flatMap((chartMetric) =>
              chartMetric.metrics.map((metric) => metric)
            )
        )
      ),
    [chartMetrics, groupingDataCriteria]
  )

  const allVisibleValues = useMemo(() => {
    return metricsData
      .map((metric) =>
        isMetricVisible(metric.metricId) ? metric.value : undefined
      )
      .filter(Boolean) as number[]
  }, [isMetricVisible, metricsData])

  const visibleMetricsAssociatedToCustomYAxis = useMemo(() => {
    return metricsData.filter(
      (metric) =>
        isMetricVisible(metric.metricId) &&
        chartConfig[metric.metricId]?.yAxisId === CUSTOM_CHART_Y_AXIS_ID
    )
  }, [chartConfig, isMetricVisible, metricsData])

  const hiddenMetricsAssociatedToCustomYAxis = useMemo(() => {
    return metricsData.filter(
      (metric) =>
        !isMetricVisible(metric.metricId) &&
        chartConfig[metric.metricId]?.yAxisId === CUSTOM_CHART_Y_AXIS_ID
    )
  }, [chartConfig, isMetricVisible, metricsData])

  const { domain: yAxisDomain, ticks: yTicks } = useAxis(
    allVisibleValues,
    TICKS_QUANTITY
  )

  const memoizedVisibleCustomYAxisValues = useMemo(
    () => visibleMetricsAssociatedToCustomYAxis.map((metric) => metric.value),
    [visibleMetricsAssociatedToCustomYAxis]
  )

  const { domain: customYAxisDomain, ticks: customYTicks } = useAxis(
    memoizedVisibleCustomYAxisValues,
    TICKS_QUANTITY
  )

  const resetYAxis = () => {
    setYAxisConfig((prevYAxisConfig) =>
      prevYAxisConfig.filter((yAxis) => yAxis.id === DEFAULT_CHART_Y_AXIS_ID)
    )
  }

  const resetChartsYAxis = useCallback(() => {
    // set all charts the default yAxis
    setChartConfig((prevChartConfig) => {
      return Object.keys(prevChartConfig).reduce(
        (acc, metricId) => ({
          ...acc,
          [metricId]: {
            ...prevChartConfig[metricId],
            yAxisId: DEFAULT_CHART_Y_AXIS_ID,
          },
        }),
        {}
      )
    })

    // remove the custom yAxis
    resetYAxis()
  }, [])

  useEffect(() => {
    if (metricsData.length) {
      setChartConfig((prevChartMode) =>
        metricsData.reduce<Record<string, ChartConfig>>(
          (acc, metric) => ({
            ...acc,
            [metric.metricId]: {
              mode:
                prevChartMode[metric.metricId]?.mode ??
                initialConfig[metric.metricId]?.mode ??
                chartModeButtonValue,
              yAxisId:
                prevChartMode[metric.metricId]?.yAxisId ??
                DEFAULT_CHART_Y_AXIS_ID,
            },
          }),
          {}
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartModeButtonValue, metricsData])

  useEffect(() => {
    setYAxisConfig((prevYAxisConfig) =>
      prevYAxisConfig.map((yAxis) =>
        yAxis.id === DEFAULT_CHART_Y_AXIS_ID
          ? { ...yAxis, domain: yAxisDomain, ticks: yTicks, isVisible: true }
          : {
              ...yAxis,
              domain: customYAxisDomain,
              ticks: customYTicks,
              isVisible: memoizedVisibleCustomYAxisValues.length > 0,
            }
      )
    )
  }, [
    customYAxisDomain,
    customYTicks,
    memoizedVisibleCustomYAxisValues.length,
    yAxisDomain,
    yTicks,
  ])

  useEffect(() => {
    if (
      visibleMetricsAssociatedToCustomYAxis.length === 0 &&
      hiddenMetricsAssociatedToCustomYAxis.length === 0
    ) {
      resetYAxis()
    }
  }, [
    hiddenMetricsAssociatedToCustomYAxis.length,
    visibleMetricsAssociatedToCustomYAxis.length,
  ])

  const addCustomAxis = useCallback(() => {
    setYAxisConfig((prevYAxisConfig) => {
      if (
        prevYAxisConfig.find((yAxis) => yAxis.id === CUSTOM_CHART_Y_AXIS_ID)
      ) {
        return prevYAxisConfig
      }

      return [
        ...prevYAxisConfig,
        {
          id: CUSTOM_CHART_Y_AXIS_ID,
          placement: 'right',
          domain: customYAxisDomain,
          ticks: customYTicks,
          isVisible: true,
        },
      ]
    })
  }, [customYAxisDomain, customYTicks])

  const config: Config = useMemo(() => {
    const lineChartsConfig = {}
    const barChartsConfig = {}

    Object.keys(chartConfig).forEach((metricId) => {
      if (!isMetricVisible(metricId)) return

      const metric = metricsData.find((m) => m.metricId === metricId)

      if (
        chartConfig[metricId].mode === CHART_MODE.LINE ||
        chartConfig[metricId].mode === CHART_MODE.DOTTED_LINE
      ) {
        lineChartsConfig[metricId] = getChartLine({
          metricId,
          metricName: metric?.name ?? '',
          metricSource: metric?.metricSource ?? MetricSources.Custom,
          intl,
          colors,
          config: chartConfig[metricId],
        })
      }

      if (chartConfig[metricId].mode === CHART_MODE.BAR) {
        barChartsConfig[metricId] = getChartBar({
          metricId,
          metricName: metric?.name ?? '',
          metricSource: metric?.metricSource ?? MetricSources.Custom,
          intl,
          colors,
          config: chartConfig[metricId],
        })
      }
    })

    return {
      lineChartsConfig,
      barChartsConfig,
      colors,
    }
  }, [chartConfig, colors, isMetricVisible, metricsData, intl])

  const onChangeChartMode = useCallback(
    (metricId: string, mode: CHART_MODE) => {
      addCustomAxis()
      setChartConfig((prevVisibilityMode) => ({
        ...prevVisibilityMode,
        [metricId]: {
          mode,
          yAxisId: CUSTOM_CHART_Y_AXIS_ID,
        } as ChartConfig,
      }))
    },
    [addCustomAxis]
  )

  const onChangeAllChartsMode = useCallback(
    (mode: CHART_MODE) => {
      setChartConfig(
        Object.keys(chartConfig).reduce(
          (acc, metricId) => ({
            ...acc,
            [metricId]: {
              mode,
              yAxisId: chartConfig[metricId].yAxisId,
            },
          }),
          {}
        )
      )

      resetChartsYAxis()
    },
    [chartConfig, resetChartsYAxis]
  )

  const onChangeChartModeButtonValue = useCallback(
    (mode: CHART_MODE) => {
      setChartModeButtonValue(mode)
      setChartConfig((prevVisibilityMode) =>
        metricsData.reduce(
          (acc, metric) => ({
            ...acc,
            [metric.metricId]: {
              mode,
              yAxisId: prevVisibilityMode[metric.metricId].yAxisId,
            },
          }),
          { ...prevVisibilityMode }
        )
      )
    },
    [metricsData, setChartModeButtonValue, setChartConfig]
  )

  const isSomeMetricVisible = useMemo(
    () =>
      Object.keys(chartConfig).some((metricId) => isMetricVisible(metricId)),
    [chartConfig, isMetricVisible]
  )

  useEffect(() => {
    setChartConfig((prevChartConfig) => ({
      ...prevChartConfig,
      ...initialConfig.charts,
    }))
  }, [initialConfig])

  useEffect(() => {
    let chartModeButton: Nullable<CHART_MODE> = null

    const isLineModeActive = Object.values(chartConfig).every(
      ({ mode }) => mode === CHART_MODE.LINE || mode === CHART_MODE.DOTTED_LINE
    )

    const isBarModeActive = Object.values(chartConfig).every(
      ({ mode }) => mode === CHART_MODE.BAR
    )

    if (isLineModeActive) {
      chartModeButton = CHART_MODE.LINE
    }

    if (isBarModeActive) {
      chartModeButton = CHART_MODE.BAR
    }

    setChartModeButtonValue(chartModeButton)
  }, [chartConfig])

  return {
    chartConfig,
    chartModeButtonValue,
    colors,
    config,
    isSomeMetricVisible,
    yAxisConfig,
    yAxisDomain,
    yTicks,
    onChangeChartModeButtonValue,
    onChangeChartMode,
    onChangeAllChartsMode,
    onChangeVisibilityMode: changeMetricVisibility,
  }
}

export default useChartConfig
