import styled, { css } from 'styled-components'
import IconButton from 'ui/IconButton'
import { color } from 'utils/functions/colors'

const rowContainerMobileStyles = css`
  grid-template-columns: 2.8rem auto 2.8rem 2.8rem;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 0.8rem;
  grid-template-rows: auto;
  align-items: center;
  min-width: auto;
`

export const DragCol = styled.div`
  > svg {
    font-size: 1.4rem;
  }
  grid-column: 1 / 1;
  grid-template-rows: 1/2;
  padding-right: 1.7rem;
`

export const NameCol = styled.div`
  grid-column: 2 / 3;
`
export const EmployeeName = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
`

export const PositionCol = styled.div`
  grid-column: 2/3;
  grid-template-rows: 2/3;
  font-size: 1.4rem;
`
export const EmailCol = styled.div`
  grid-column: 2/3;
  grid-template-rows: 3/4;
  font-size: 1.4rem;
`
export const LinkedinCol = styled.div<{
  red: boolean
}>`
  grid-column: 2/3;
  grid-template-rows: 4/5;
  font-size: 2rem;

  ${(props) =>
    props.red &&
    css`
      color: ${color('red')};
    `}
`
export const FounderCol = styled.div`
  grid-column: 2/3;
  grid-template-rows: 5/6;
  font-size: 1.4rem;
`

export const EditIconButtonCol = styled.div`
  grid-column: 3/3;
  grid-row: 1 / 1;
  justify-self: end;
  align-self: flex-end;

  button {
    background-color: transparent;
  }

  > svg {
    font-size: 1.2rem;
  }
`

export const RemoveIconButtonCol = styled.div`
  grid-column: 4/4;
  grid-row: 1 / 1;
  place-self: flex-end end;

  button {
    background-color: transparent;
  }

  > svg {
    font-size: 1.4rem;
  }
`

export const RowContainer = styled.div<{
  isDragging: boolean
  showMobileView: boolean
}>`
  display: grid;
  grid-template-columns: auto 2fr 2fr 2fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 1.4rem;
  background-color: ${color('white')};

  ${(props) =>
    props.isDragging &&
    css`
      background-color: #f4f5fa;
      border: 0.1rem solid #e9e9e9;
    `}

  ${rowContainerMobileStyles}
    
  &:hover {
    background-color: #f4f5fa;
  }
`

export const Empty = styled.span`
  @media (max-width: 1439px) {
    display: none;
  }
`

export const Remove = styled(IconButton)`
  &:hover {
    background-color: transparent;
    background: transparent;

    > svg {
      color: var(--color-red-default);
    }
  }
`
