import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import CompanyService from 'api/CompanyService'
import Toast from 'components/Toast'

import Title from 'components/Title'
import Chip from 'components/Chip'
import Dropdown from 'ui/Dropdown'

import * as Styles from '../common/styles'

const CompanyIndustries = ({
  companyData,
  industriesOptions,
  investmentToolIndustries,
  onChange,
  isFundCompany,
}) => {
  const intl = useIntl()

  const addIndustry = async ({ id, name }) => {
    if (id) {
      try {
        const {
          data: { result, entities },
        } = await CompanyService.createCompanyIndustries(companyData.id, {
          industryId: id,
        })
        const newInvestmentToolIndustry =
          entities.investmentToolIndustries[result]

        const normalizedInvestmentToolIndustry = {
          ...newInvestmentToolIndustry,
          industryId: newInvestmentToolIndustry.industry,
        }

        onChange({
          investmentToolIndustries: [
            ...investmentToolIndustries,
            normalizedInvestmentToolIndustry,
          ],
        })
      } catch (errors) {
        Toast.display(
          intl.formatMessage(
            { id: 'errors.addItemError' },
            {
              name,
              item: intl.formatMessage({ id: 'general.industry' }),
            }
          ),
          'error'
        )
      }
    }
  }

  const removeIndustry = async (id) => {
    const industryToRemove = investmentToolIndustries.find(
      (toolInd) => toolInd.id === id
    )
    try {
      const newIndustries = investmentToolIndustries.filter(
        (toolInd) => toolInd.id !== id
      )

      await CompanyService.removeCompanyIndustry(id)

      onChange({
        investmentToolIndustries: newIndustries,
      })
    } catch (errors) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.removeItemError' },
          {
            name: industryToRemove?.name || '',
            item: intl.formatMessage({ id: 'general.industry' }),
          }
        ),
        'error'
      )
    }
  }

  return (
    <Styles.Section>
      <Title
        title={intl.formatMessage({ id: 'editCompany.fundTargetIndustires' })}
      />

      <Dropdown
        name="companyIndustry"
        onSelectOption={(_, __, selectedValue) => {
          addIndustry({ id: selectedValue.id })
        }}
        placeholder={`${intl.formatMessage({
          id: 'editCompany.addIndustryPlaceholder',
        })}...`}
        id="industries-add"
        type="select"
        loadOptions={(searchValue) => {
          return industriesOptions?.filter(
            (industry) =>
              industry.name.toLowerCase().indexOf(searchValue.toLowerCase()) >=
                0 &&
              !investmentToolIndustries.some(
                (toolInd) => toolInd.industryId === industry.id
              )
          )
        }}
        getOption={(industry) => ({
          ...industry,
          label: industry.name,
        })}
        clearOnSelect
        async
      />

      <Styles.ChipContainer>
        {investmentToolIndustries.map(({ name, id }) => (
          <Chip
            key={id}
            text={name}
            canDelete
            handleDelete={() => removeIndustry(id)}
            width="fit-content"
          />
        ))}
      </Styles.ChipContainer>
    </Styles.Section>
  )
}

CompanyIndustries.propTypes = {
  companyData: PropTypes.object,
  investmentToolIndustries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      industryId: PropTypes.string,
    })
  ),
  industriesOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func.isRequired,
  isFundCompany: PropTypes.bool,
}

CompanyIndustries.defaultProps = {
  companyData: null,
  industriesOptions: [],
  investmentToolIndustries: [],
  isFundCompany: false,
}

export default CompanyIndustries
