import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { ReactComponent as FeatureCheck } from 'assets/images/feature-check.svg'
import Tooltip from 'components/Tooltip'
import Button from 'ui/Button'
import { ORGANIZATION_BUTTON_ID } from 'utils/constants/signUp'
import { Plan } from 'utils/types/signUp'

import TooltipContent from 'containers/Chat/components/TooltipContent'
import { renderCurrency } from 'utils/functions/number'
import { getPriceWithPromotionCode } from 'utils/functions/subscriptions'
import {
  PromotionCode,
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import CouponCode from './CouponCode'
import * as Styles from './PlanColumn.styles'

interface PlanColumnProps {
  plan: Plan
  billingPeriod: SubscriptionBillingPeriod
  onPlanSelect: (plan: Plan) => void
  className?: string
  isSelected?: boolean
  isCurrentPlan?: boolean
  showTrialText?: boolean
  couponCodeAllowed?: boolean
  handlePromotionCodeAdded?: (code: PromotionCode) => void
}

const PlanColumn: React.FC<PlanColumnProps> = ({
  plan,
  billingPeriod,
  onPlanSelect,
  className,
  isCurrentPlan,
  isSelected,
  showTrialText = false,
  couponCodeAllowed,
  handlePromotionCodeAdded,
}) => {
  const intl = useIntl()
  const [promotionCode, setPromotionCode] = useState<PromotionCode | null>(null)

  const buttonText = useMemo(() => {
    if (isCurrentPlan) {
      return 'subscriptions.currentPlan'
    }

    if (showTrialText && plan.id !== SubscriptionPlan.ORGANIZATION) {
      return 'signup.plans.startTrial'
    }

    return plan.buttonText
  }, [isCurrentPlan, plan, showTrialText])

  const getPrice = () => {
    if (plan.amount.contact) {
      return <FormattedMessage id="signup.plans.contactUs" />
    }

    if (promotionCode) {
      if (billingPeriod === SubscriptionBillingPeriod.MONTHLY) {
        const amount = getPriceWithPromotionCode(
          plan.amount.monthly!,
          promotionCode
        )
        return (
          <>
            {renderCurrency(intl, amount)}
            <Styles.OldPrice>
              {renderCurrency(intl, plan.amount.monthly!)}
            </Styles.OldPrice>
          </>
        )
      }

      const amount = getPriceWithPromotionCode(
        plan.amount.annually! * 12,
        promotionCode
      )
      return (
        <>
          {renderCurrency(intl, amount / 12)}
          <Styles.OldPrice>
            {renderCurrency(intl, plan.amount.annually!)}
          </Styles.OldPrice>
        </>
      )
    }

    if (billingPeriod === SubscriptionBillingPeriod.MONTHLY) {
      return renderCurrency(intl, plan.amount.monthly!)
    }

    return renderCurrency(intl, plan.amount.annually!)
  }

  const getAnnualPrice = () => {
    if (promotionCode) {
      const amount = getPriceWithPromotionCode(
        plan.amount.annually! * 12,
        promotionCode
      )
      return renderCurrency(intl, amount)
    }

    return renderCurrency(intl, plan.amount.annually! * 12)
  }

  const getPriceSubText = () => {
    if (plan.amount.contact) {
      return <FormattedMessage id="signup.plans.customPricing" />
    }

    if (billingPeriod === SubscriptionBillingPeriod.MONTHLY) {
      return <FormattedMessage id="signup.plans.perMonth" />
    }

    return <FormattedMessage id="signup.plans.priceBilledAnnually" />
  }

  const isHighlighted = isSelected || plan.highlighted

  return (
    <Styles.PlanContainer isHighlighted={isHighlighted} className={className}>
      {plan.highlighted && (
        <Styles.PlanTag>
          <FormattedMessage id="signup.plans.mostPopular" />
        </Styles.PlanTag>
      )}
      <Styles.Info>
        <Styles.Name>
          <FormattedMessage id={plan.name} />
        </Styles.Name>
        <Styles.Description>
          <FormattedMessage id={plan.description} />
        </Styles.Description>

        <Styles.Price isContactAmount={plan.amount.contact}>
          <Styles.PriceBold>{getPrice()}</Styles.PriceBold>

          {!plan.amount.contact && <Styles.PriceSeparator />}

          {!plan.amount.contact &&
          billingPeriod === SubscriptionBillingPeriod.ANNUALLY ? (
            <Tooltip
              id={`${plan.name}-subtext-tooltip`}
              backgroundColor="transparent"
              place="top"
              topOffset={-85}
              leftOffset={45}
              text={
                <TooltipContent>
                  <FormattedMessage
                    id="signup.plans.upFrontPrice"
                    values={{
                      value: getAnnualPrice(),
                    }}
                  />
                </TooltipContent>
              }
            >
              <Styles.PriceSubtext>{getPriceSubText()}</Styles.PriceSubtext>
            </Tooltip>
          ) : (
            <Styles.PriceSubtext>{getPriceSubText()}</Styles.PriceSubtext>
          )}
        </Styles.Price>
      </Styles.Info>
      <Styles.FeatureContainer>
        {plan.features.map((feature) => {
          return (
            <Styles.Feature key={feature.title}>
              <Styles.FeatureCheckContainer>
                <FeatureCheck />
              </Styles.FeatureCheckContainer>
              {feature.hoverText ? (
                <Tooltip
                  id={`${plan.name}-${feature.title}-tooltip`}
                  place="top"
                  topOffset={-10}
                  backgroundColor="transparent"
                  text={
                    <TooltipContent>
                      <FormattedMessage id={feature.hoverText} />
                    </TooltipContent>
                  }
                >
                  <Styles.FeatureTitle>
                    <FormattedMessage
                      id={`signup.plans.features.${feature.title}`}
                      values={{
                        count: feature.count,
                      }}
                    />{' '}
                    *
                  </Styles.FeatureTitle>
                </Tooltip>
              ) : (
                <Styles.FeatureTitle>
                  <FormattedMessage
                    id={`signup.plans.features.${feature.title}`}
                    values={{
                      count: feature.count,
                    }}
                  />
                </Styles.FeatureTitle>
              )}
            </Styles.Feature>
          )
        })}
      </Styles.FeatureContainer>

      <Styles.ButtonContainer>
        <Button
          id={
            plan.id === SubscriptionPlan.ORGANIZATION
              ? ORGANIZATION_BUTTON_ID
              : `select-plan-btn-${plan.id}`
          }
          primary={!isCurrentPlan && isSelected}
          secondary={isCurrentPlan || !isSelected}
          disabled={isCurrentPlan}
          onClick={() => onPlanSelect(plan)}
        >
          <FormattedMessage id={buttonText} />
        </Button>
      </Styles.ButtonContainer>

      {couponCodeAllowed && plan.id !== SubscriptionPlan.ORGANIZATION && (
        <CouponCode
          handlePromotionCodeAdded={(promotionCodeSelected) => {
            setPromotionCode(promotionCodeSelected)
            handlePromotionCodeAdded?.(promotionCodeSelected)
            onPlanSelect(plan)
          }}
          handlePromotionCodeRemoved={() => setPromotionCode(null)}
        />
      )}
    </Styles.PlanContainer>
  )
}

export default PlanColumn
