import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

import FileIcon from 'components/FileIcon'
import { DropdownContainer } from 'components/UpdateContent/UpdateContent.styles'
import Button from 'ui/Button'
import OptionsDropdown from 'ui/OptionsDropdown'
import { displayFileSize, download } from 'utils/functions/files'
import { FileContent, isFileFormatImage } from 'utils/types/files'
import {
  FileIconWrapper,
  FileName,
  FileLabel,
  Content,
  DownloadsIndicator,
  Grid,
  CardImagePreview,
} from '../AttachmentCard.styles'
import { useAttachmentAnalytics } from '../../AttachmentSidebar/useAttachmentAnalytics'

interface AttachmentRowCardProps {
  attachment: FileContent
  onSelect: () => void
}

const AttachmentRowCard: React.FC<AttachmentRowCardProps> = ({
  attachment,
  onSelect,
}) => {
  const intl = useIntl()
  const { totalDownloads } = useAttachmentAnalytics(attachment)
  const { url, fileName, fileFormat } = attachment

  return (
    <Grid data-testid="attachments-row-card">
      <FileIconWrapper>
        {isFileFormatImage(fileFormat) ? (
          <CardImagePreview src={url} />
        ) : (
          <FileIcon type={fileFormat} src={url} useBackgroundImage small />
        )}
      </FileIconWrapper>

      <Content>
        <FileName title={fileName}>{fileName}</FileName>
        <FileLabel>
          <>
            {intl.formatMessage({ id: 'analytics.added' })}{' '}
            {dayjs(attachment.createdAt).format('DD MMM YYYY')}
          </>
        </FileLabel>
      </Content>

      <FileLabel>{displayFileSize(attachment.fileSize)}</FileLabel>

      <DownloadsIndicator>
        <FontAwesomeIcon icon={['fal', 'download']} />
        <span>{totalDownloads}</span>
      </DownloadsIndicator>

      <Button type="button" link onClick={onSelect}>
        {intl.formatMessage({ id: 'analytics.viewDetails' })}
      </Button>

      <DropdownContainer>
        <OptionsDropdown buttonType="square" buttonSize="3.2rem" topOffset={-5}>
          <OptionsDropdown.Item
            label={intl.messages['analytics.viewDetails']}
            icon={['fal', 'clipboard-list-check']}
            onSelectOption={onSelect}
          />
          <OptionsDropdown.Item
            label={intl.messages['analytics.downloadFile']}
            icon={['fal', 'download']}
            onSelectOption={() => download(fileName, url)}
          />
        </OptionsDropdown>
      </DropdownContainer>
    </Grid>
  )
}

export default AttachmentRowCard
