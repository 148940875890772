import { Investor } from 'utils/types/investors'
import {
  disableSuggestCreateInvestmentVehicleModal,
  getIsShowSuggestCreateInvestmentVehicleModalEnabled,
  getShowSuggestCreateInvestmentVehicleModal,
  getSuggestedInvestor,
  setShowSuggestCreateInvestmentVehicleModal,
} from 'features/investorManagementSlice'

import { useAppSelector, useAppDispatch } from './reduxToolkit'

export const useSuggestCreateInvestmentVehicleModal = () => {
  const dispatch = useAppDispatch()
  const showSuggestionModal = useAppSelector(
    getShowSuggestCreateInvestmentVehicleModal
  )

  const suggestedInvestor = useAppSelector(getSuggestedInvestor)

  const isSuggestionModalEnabled = useAppSelector(
    getIsShowSuggestCreateInvestmentVehicleModalEnabled
  )

  const disableSuggestionModal = () => {
    dispatch(disableSuggestCreateInvestmentVehicleModal())
  }

  const setSuggestionModalData = (investor: Investor) => {
    dispatch(
      setShowSuggestCreateInvestmentVehicleModal({ show: true, investor })
    )
  }

  const closeSuggestionModal = () => {
    dispatch(setShowSuggestCreateInvestmentVehicleModal({ show: false }))
  }

  return {
    showSuggestionModal,
    closeSuggestionModal,
    suggestedInvestor,
    isSuggestionModalEnabled,
    disableSuggestionModal,
    setSuggestionModalData,
  }
}
