import { isNavigationKey } from '@silevis/reactgrid'
import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { isEnterKeyCode } from 'utils/functions/keyboardEvents'

interface DatePickerProps {
  value?: Date
  handleChange?: (date: Date) => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  maxDate?: Date
}

interface IReactDatePicker {
  input: HTMLInputElement
}

type ForwardedRef = React.RefObject<IReactDatePicker>

const DatePicker = React.forwardRef(
  (props: DatePickerProps, ref: ForwardedRef) => {
    const { value, handleChange } = props
    const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
      value
    )

    const onChange = (date: Date) => {
      setSelectedDate(date)
    }

    const onSelectDate = () => {
      if (selectedDate && handleChange) {
        handleChange(selectedDate)
      }
    }

    const onKeyDown = (e: React.KeyboardEvent) => {
      if (isEnterKeyCode(e)) {
        onSelectDate()
      }

      if (isNavigationKey(e.keyCode)) {
        e.stopPropagation()
      }
    }

    return (
      <ReactDatePicker
        onChange={onChange}
        selected={selectedDate}
        onKeyDown={onKeyDown}
        onCalendarClose={onSelectDate}
        ref={ref}
        {...props}
      />
    )
  }
)

export default DatePicker
