import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`

export const PrimaryText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${color('darkBlue')};
`

export const SecondaryText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  color: ${color('lightGray')};
`

export const DropdownContainer = styled.div`
  .dropdown-list {
    margin: initial;
    padding-top: 0.2rem;
  }

  button {
    padding: 0.9rem 0.8rem 1rem 0.8rem;
    font-weight: 400;
  }
`
