import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { BubbleMenu as BubbleMenuToStyle } from '@tiptap/react'
import { color } from 'utils/functions/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const StyledBubbleMenu = styled(BubbleMenuToStyle)`
  background-color: ${color('softGray')};
  border-bottom: none;
  border-radius: 2rem;
  padding: 0.5rem 0.8rem;
  display: flex;
  gap: 0.4rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
`

const ActiveStyles = css`
  color: ${color('primaryBlue')};
  background-color: ${color('lightGray', 0.1)};
`

export const BubbleIcon = styled(FontAwesomeIcon)<{
  active?: boolean
  disabled?: boolean
}>`
  color: ${color('darkGray')};
  font-size: 1.6rem;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 0.4rem;

  &:hover {
    ${ActiveStyles}
  }

  ${({ active }) => active && ActiveStyles}

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${color('lightGray')};
      background-color: initial;
      pointer-events: none;
    `}
`

export const LinkInputContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 13rem;
  padding: 0.4rem;
  background-color: ${color('softGray')};
  display: flex;
  align-items: center;
  gap: 0.4rem;
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2);
`

export const LinkInput = styled.input`
  height: 3rem;
  width: 20rem;
  font-size: 1.4rem;
  padding-left: 0.8rem;
  border-radius: 0.8rem;
`
