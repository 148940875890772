import { useCallback, useEffect, useState } from 'react'
import {
  ShareWithOption,
  ShareWithOptionType,
  TO_CREATE_PREFIX,
} from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/useShareWithDropdown'
import {
  useCreateReshareMutation,
  useEditReshareMutation,
} from 'utils/hooks/queries/useUpdateQuery'
import { ReshareTokenSet } from 'utils/functions/updates'
import { Update } from 'utils/types/update'
import { useSearchScopedUsersAndGroups } from 'utils/hooks/useSearchScopedUsersAndGroups'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentCompany } from 'selectors/company'
import { getCurrentGroupData, getUser } from 'selectors/auth'
import { AccessType } from 'utils/types/updateForm'
import { getPermissionsFromServerUpdate } from 'utils/functions/api/updates'
import { createNewUsers } from 'containers/UpdatesV2/utils'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'

interface Props {
  reshareTokenSet?: ReshareTokenSet
  update: Update
}

const mapTokenEntitiesToOptions = (entities) =>
  entities.map((entity) => {
    const returnEntity: any = {
      canBeDeleted: false,
    }

    if (entity.groupableId) {
      returnEntity.type = ShareWithOptionType.Group
      returnEntity.name = entity.groupName
      returnEntity.imageUrl = entity.groupLogo.url
      returnEntity.id = entity.groupableId
      returnEntity.groupableId = entity.groupableId
      returnEntity.groupableType = entity.groupableType
    }

    if (entity.userId) {
      returnEntity.type = ShareWithOptionType.User
      returnEntity.name = entity.userName ?? entity.userEmail
      returnEntity.imageUrl = entity.userImage.url
      returnEntity.userId = entity.userId
      returnEntity.id = entity.userId
    }

    return returnEntity
  })

const getUniqueIdsFromEntities = (entities) => {
  const sharedGroupsSet = new Set<string>()
  const sharedUsersSet = new Set<string>()

  entities.forEach((e) => {
    if (e.type === ShareWithOptionType.List) {
      e.recipients?.forEach((r) => {
        if (r.type === ShareWithOptionType.Group) {
          sharedGroupsSet.add(r.id)
        } else if (r.type === ShareWithOptionType.User) {
          sharedUsersSet.add(r.id)
        } else if (r.type === ShareWithOptionType.Email) {
          sharedUsersSet.add(r.id)
        }
      })
    } else if (e.type === ShareWithOptionType.Group) {
      sharedGroupsSet.add(e.id)
    } else if (e.type === ShareWithOptionType.User) {
      sharedUsersSet.add(e.id)
    } else if (e.type === ShareWithOptionType.Email) {
      sharedUsersSet.add(e.id)
    }
  })

  return {
    sharedGroupsIds: Array.from(sharedGroupsSet),
    sharedUsersIds: Array.from(sharedUsersSet),
  }
}

interface UpdateEntitiesSelectedProps {
  entitiesSelected: ShareWithOption[]
  listId: string
  recipientId: string
}

const updateEntitiesSelected = ({
  entitiesSelected,
  listId,
  recipientId,
}: UpdateEntitiesSelectedProps) => {
  const listIndex = entitiesSelected.findIndex((e) => e.id === listId)
  const list = entitiesSelected[listIndex]
  const recipients = list.recipients?.filter(
    (recipient) => recipient.id !== recipientId
  )
  const newEntitiesSelected = [...entitiesSelected]

  if (recipients?.length === 0) {
    newEntitiesSelected.splice(listIndex, 1)
  } else {
    newEntitiesSelected[listIndex] = {
      ...list,
      recipients,
    }
  }

  return newEntitiesSelected
}

interface ProcessEntitiesSelectedProps {
  entitiesSelected: ShareWithOption[]
  list: ShareWithOption
  listIndex: number
  recipientsInList: Set<string>
  ids: Set<string>
}

const processEntitiesSelected = ({
  entitiesSelected,
  list,
  listIndex,
  recipientsInList,
  ids,
}: ProcessEntitiesSelectedProps) => {
  entitiesSelected.forEach((e, index) => {
    if (e.id === list.id) return

    ids.add(e.id)

    if (e.type === 'list') {
      e.recipients?.forEach((recipient) => {
        const recipientIsBeingAddedByCurrentList =
          recipientsInList.has(recipient.id) && index > listIndex

        if (recipientIsBeingAddedByCurrentList) return

        ids.add(recipient.id)
      })
    }
  })

  return ids
}

export const useReshareUpdate = ({ update, reshareTokenSet }: Props) => {
  const [tokenReshareEntitiesSelected, setTokenReshareEntitiesSelected] =
    useState<ShareWithOption[]>([])

  const [newReshareEntitiesSelected, setNewReshareEntitiesSelected] = useState<
    ShareWithOption[]
  >([])

  const [reshareMessage, setReshareMessage] = useState('')
  const { handleOnUpdateClick } = useOnClickRedirect()

  const { confidentialUpdate, yourGroup } =
    getPermissionsFromServerUpdate(update)

  const currentCompany = useAppSelector(getCurrentCompany)
  const currentGroup = useAppSelector(getCurrentGroupData)
  const currentUser = useAppSelector(getUser)

  const { loadRecentOptions, loadOptionsBySearchValue } =
    useSearchScopedUsersAndGroups()

  const redirectToShow = () => {
    return handleOnUpdateClick({
      update,
      isRedirectingAfterReshare: true,
    }).redirectFunction()
  }

  const loadOptions = useCallback(
    async (searchValue: string): Promise<ShareWithOption[]> => {
      if (!searchValue) {
        return loadRecentOptions(currentGroup, currentCompany?.owner)
      }

      return loadOptionsBySearchValue(
        searchValue,
        currentGroup,
        currentCompany?.owner
      )
    },
    [
      currentGroup,
      currentCompany?.owner,
      loadRecentOptions,
      loadOptionsBySearchValue,
    ]
  )

  const onCloseDropdown = (selectedOptions: ShareWithOption[]) => {
    if (selectedOptions?.length > 0) {
      const newSelectedOptions = selectedOptions.map((option) => ({
        ...option,
        canBeDeleted: true,
      }))

      if (reshareTokenSet) {
        setTokenReshareEntitiesSelected((prev) => [
          ...prev,
          ...newSelectedOptions,
        ])
      } else {
        setNewReshareEntitiesSelected((prev) => [
          ...prev,
          ...newSelectedOptions,
        ])
      }

      createNewUsers({
        entities: selectedOptions,
        setSelectedEntities: reshareTokenSet
          ? setTokenReshareEntitiesSelected
          : setNewReshareEntitiesSelected,
        prefix: TO_CREATE_PREFIX,
        toastErrorMessage: 'errors.addingUserToList',
      })
    }
  }

  const onRecipientRemove = (listId: string) => (recipientId: string) => {
    if (reshareTokenSet) {
      setTokenReshareEntitiesSelected((currEntitiesSelected) =>
        updateEntitiesSelected({
          entitiesSelected: currEntitiesSelected,
          listId,
          recipientId,
        })
      )
    } else {
      setNewReshareEntitiesSelected((currEntitiesSelected) =>
        updateEntitiesSelected({
          entitiesSelected: currEntitiesSelected,
          listId,
          recipientId,
        })
      )
    }
  }

  const createEntitiesSelectedSet = useCallback(() => {
    const sharedWithIds: Set<string> = new Set([currentUser.id])

    if (yourGroup !== AccessType.NO_ACCESS && currentGroup) {
      sharedWithIds.add(currentGroup.id)
    }

    return sharedWithIds
  }, [currentGroup, currentUser.id, yourGroup])

  const getOtherEntitiesSelectedIds = useCallback(
    (list: ShareWithOption, listIndex: number) => {
      const recipientsInList = new Set<string>(
        list.recipients?.map((recipient) => recipient.id)
      )
      const ids = createEntitiesSelectedSet()

      if (reshareTokenSet) {
        return processEntitiesSelected({
          entitiesSelected: tokenReshareEntitiesSelected,
          list,
          listIndex,
          recipientsInList,
          ids,
        })
      }

      return processEntitiesSelected({
        entitiesSelected: newReshareEntitiesSelected,
        list,
        listIndex,
        recipientsInList,
        ids,
      })
    },
    [
      createEntitiesSelectedSet,
      newReshareEntitiesSelected,
      reshareTokenSet,
      tokenReshareEntitiesSelected,
    ]
  )

  useEffect(() => {
    if (reshareTokenSet) {
      setReshareMessage(reshareTokenSet.message ?? '')
      setTokenReshareEntitiesSelected(
        mapTokenEntitiesToOptions(reshareTokenSet.loggingUpdateEntities)
      )
    }
  }, [reshareTokenSet])

  const {
    sharedGroupsIds: uniqueGroupIdsWhenCreating,
    sharedUsersIds: uniqueUserIdsWhenCreating,
  } = getUniqueIdsFromEntities(newReshareEntitiesSelected)

  const {
    sharedGroupsIds: uniqueGroupIdsWhenEditing,
    sharedUsersIds: uniqueUserIdsWhenEditing,
  } = getUniqueIdsFromEntities(tokenReshareEntitiesSelected)

  const { mutate: reshareUpdate, isLoading: isCreatingReshare } =
    useCreateReshareMutation({
      sharedGroupsIds: uniqueGroupIdsWhenCreating,
      sharedUsersIds: uniqueUserIdsWhenCreating,
      reshareMessage,
      updateId: update!.id,
      updateType: update!.updateType,
      redirectToShow,
    })

  const { mutate: editReshare, isLoading: isEditingReshare } =
    useEditReshareMutation({
      sharedGroupsIds: uniqueGroupIdsWhenEditing,
      sharedUsersIds: uniqueUserIdsWhenEditing,
      reshareTokenSet: reshareTokenSet!,
      reshareMessage,
      updateType: update!.updateType,
      updateId: update.id,
      // this redirect
      redirectToShow,
    })

  const onChangeReshareMessage = (msg: string) => setReshareMessage(msg)

  return {
    isConfidential: confidentialUpdate,
    reshareMessage,
    tokenReshareEntitiesSelected,
    newReshareEntitiesSelected,
    setTokenReshareEntitiesSelected,
    getOtherEntitiesSelectedIds,
    setNewReshareEntitiesSelected,
    onCloseDropdown,
    onChangeReshareMessage,
    reshareUpdate,
    editReshare,
    loadOptions,
    onRecipientRemove,
    isSubmitting: isEditingReshare || isCreatingReshare,
  }
}
