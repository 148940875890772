import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useField } from 'components/Form/hooks'
import { getCanScheduleUpdates } from 'selectors/auth'
import { getScheduleType } from 'utils/functions/updates'
import { ScheduleType } from 'utils/types/update'

const usePublishUpdate = () => {
  const canSchedule = useAppSelector(getCanScheduleUpdates)
  const [dateField] = useField<Date>('date')

  const scheduleType = canSchedule
    ? getScheduleType(dateField.value)
    : ScheduleType.SEND_NOW

  const isScheduled = scheduleType === ScheduleType.SCHEDULE

  return {
    isScheduled,
  }
}

export default usePublishUpdate
