/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { useForm } from 'react-hook-form'

import SettingsService from 'api/SettingsService'
import Title from 'components/Title'
import CompanyName from 'components/EditCompany/components/CompanyName'
import CompanyLocation from 'components/EditCompany/components/CompanyLocation'
import CompanyLegalStructure from 'components/EditCompany/components/CompanyLegalStructure'
import CompanyIndustries from 'components/EditCompany/components/CompanyIndustries'
import CompanyUrls from 'components/EditCompany/components/CompanyUrls'
import { CompanyDataValues } from 'components/EditCompany/components/CompanyLocation/CompanyLocation'
import ImagePicker from 'ui/ImagePicker'
import TeamMembers from 'components/EditCompany/components/TeamMembers'
import Input from 'ui/Input'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import CompanyService from 'api/CompanyService'
import CWLoader from 'components/CWLoader'
import {
  getCompanyFundId,
  getCurrentGroupCompaniesIds,
  getCurrentGroupsPrivateCompanies,
  isActingAsFundManager,
  isActingAsFounder,
} from 'selectors/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Toast from 'components/Toast'
import Drawer from 'components/Drawer'
import Collapsable from 'components/Collapsable'
import { getIsFounderVerified } from 'selectors/company'

import { Company } from 'utils/types/company'
import { getInitials } from 'utils/functions/user'
import { getUrlFieldsValidationsSchema } from 'utils/schemas/teamMembers'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip'

import ShowInSearch from 'containers/Profiles/components/ShowInSearch'
import useCompanyProfileDrawer from './useCompanyProfileDrawer'

import * as Styles from './CompanyProfileEditDrawer.styles'

const CompanyProfileEditDrawer = () => {
  const {
    initialData,
    companyInfo,
    isLoading,
    selectedCompany,
    locationEntities,
    setSelectedCompany,
    onChange,
    onMembersCountChange,
    handleChangeEmployees,
    onAddNewTeamMember,
    toggleHideTeamMember,
    onEditTeamMember,
    onDeleteTeamMember,
    onSaveImage,
    onSelectImage,
    refetchCompany,
  } = useCompanyProfileDrawer()

  const intl = useIntl()
  const history = useHistory<{
    companyId: string
  }>()

  const isFundCompanyProfile = window.location.href.includes('fund')

  const userCompanies = useAppSelector(getCurrentGroupCompaniesIds)
  const userPrivateCompanies = useAppSelector(getCurrentGroupsPrivateCompanies)
  const isUserActingAsFounder = useAppSelector(isActingAsFounder)
  const isUserActingAsFund = useAppSelector(isActingAsFundManager)
  const isFounderVerified = useAppSelector(getIsFounderVerified)

  const isFundCompany = companyInfo?.companyData?.fund
  const fundCompanyId = useAppSelector(getCompanyFundId)

  const groupHasCompany = userCompanies.length
  if (!groupHasCompany) {
    history.push('/settings/group_management/general')
  }

  const schema = getUrlFieldsValidationsSchema(intl.messages)
  const { errors, register, getValues } = useForm({
    validationSchema: schema,
    mode: 'onChange',
  })

  const dismissCompanyPageMessage = async () => {
    await SettingsService.dismissMessages('companyPage')
  }

  useEffect(() => {
    dismissCompanyPageMessage()
  }, [])

  useEffect(() => {
    if (isFundCompanyProfile) {
      setSelectedCompany(fundCompanyId)
    } else if (isUserActingAsFund && userPrivateCompanies[0]) {
      const id =
        history.location?.state?.companyId || userPrivateCompanies[0]?.id
      setSelectedCompany(id)
    } else if (userCompanies?.[0]) {
      const id = history.location?.state?.companyId || userCompanies?.[0]
      setSelectedCompany(id)
    }
  }, [])

  const onEditField = useMemo(
    () =>
      debounce(async ({ key, value }) => {
        try {
          await CompanyService.editCompany(
            selectedCompany,
            { [key]: value },
            undefined
          )
          refetchCompany()
        } catch (e) {
          Toast.display(
            intl.formatMessage(
              { id: 'errors.editItemError' },
              { name: '', item: key }
            ),
            'error'
          )
        }
      }, 800),
    [selectedCompany]
  )

  const onBlur = (key) => {
    const transformedValues = schema.cast(getValues())
    onEditField({ key, value: transformedValues?.[key] })
  }

  const onCloseDrawerHandler = () => {
    history.goBack()
  }

  return (
    <Drawer
      isOpen
      onCloseDrawer={onCloseDrawerHandler}
      headerTitle={intl.formatMessage({ id: 'companyProfile.editCompany' })}
    >
      <Drawer.Content contentPadding="0.6rem 2.3rem">
        {isLoading ? (
          <CWLoader />
        ) : (
          <Styles.Wrapper>
            {(isUserActingAsFund ? isFundCompany : isUserActingAsFounder) && (
              <ShowInSearch
                company={companyInfo?.companyData!}
                isFounderVerified={isFounderVerified}
                onChange={(companyData) =>
                  onChange({ ...companyInfo, companyData })
                }
                onEditField={onEditField}
              />
            )}

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              bodyCustomStyles={Styles.Section}
              id="companyProfile.generalInformation"
              initiallyExpanded
            >
              <ImagePicker
                onSaveImage={onSaveImage}
                showRemoveImageButton={false}
                onSelectImage={onSelectImage}
                imageUrl={companyInfo?.companyData?.primaryLogo?.url}
                changeImageText={intl.formatMessage({
                  id: 'companyProfile.changeLogo',
                })}
                initials={getInitials(companyInfo?.companyData?.name)}
                helpText={[
                  intl.formatMessage({ id: 'companyProfile.imageFormat' }),
                  intl.formatMessage({
                    id: 'companyProfile.imageFormatLegend',
                  }),
                ]}
                imageSize="4.8rem"
              />

              <CompanyName
                companyId={companyInfo?.companyData?.id}
                doingBusinessAs={companyInfo?.companyData?.doingBusinessAs}
                onChange={onChange}
                isFundCompany={isFundCompany}
              />

              <Title
                title={intl.formatMessage({ id: 'editCompany.companyFounded' })}
              />

              <SelectDatePicker
                id="founded-date"
                name="founded-date"
                initialValue={
                  companyInfo?.companyData?.founded
                    ? new Date(`${companyInfo?.companyData?.founded}T00:00:01`)
                    : null
                }
                handleChange={(value) => onEditField({ key: 'founded', value })}
                placeholder={intl.formatMessage({
                  id: 'editCompany.foundedPlaceholder',
                })}
              />

              <Title
                title={intl.formatMessage({ id: 'editCompany.website' })}
                subLabel={intl.formatMessage({ id: 'common.requiredField' })}
              />

              <Input
                id="website"
                value={companyInfo?.companyData?.website}
                onBlur={() => !errors.website && onBlur('website')}
                name="website"
                placeholder={intl.formatMessage({
                  id: 'editCompany.companyWebsitePlaceholder',
                })}
                error={errors.website}
                ref={register}
                prefix={
                  <ReadOnlyTooltip
                    place="top"
                    text={intl.formatMessage({
                      id: 'editCompany.unverifiedEditUrl',
                    })}
                    leftOffset={-130}
                    disabled={
                      isUserActingAsFund
                        ? companyInfo?.companyData?.verified
                        : isFounderVerified
                    }
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'link-simple']}
                      className="imageStyle"
                    />
                  </ReadOnlyTooltip>
                }
              />

              <Title
                title={intl.formatMessage({
                  id: 'editCompany.description',
                })}
              />
              <Input
                placeholder={intl.formatMessage({
                  id: 'editCompany.writeDescription',
                })}
                id="description"
                value={companyInfo?.companyData?.description}
                onChange={(event) => {
                  const { value } = event.target
                  const newCompanyData: Company = {
                    ...companyInfo?.companyData,
                    description: value,
                  } as Company
                  onEditField({ key: 'description', value })
                  onChange({ companyData: newCompanyData })
                }}
              />

              <CompanyLocation
                companyData={companyInfo?.companyData!}
                locations={locationEntities}
                onChange={(companyData: CompanyDataValues) =>
                  onChange({
                    ...companyInfo,
                    companyData: {
                      ...companyInfo.companyData,
                      ...companyData.companyData,
                    } as Company,
                  })
                }
              />
            </Collapsable>

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              id="editCompany.links"
            >
              <CompanyUrls
                iconStyle="imageStyle"
                companyData={companyInfo?.companyData}
                onEditField={onEditField}
                isFundCompany={isFundCompany}
              />
            </Collapsable>

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              bodyCustomStyles={Styles.Section}
              id="editCompany.team"
            >
              <Title
                title={intl.formatMessage({ id: 'editCompany.companyTeam' })}
              />

              <Input
                id="membersCount"
                name="membersCount"
                placeholder={intl.formatMessage({
                  id: 'editCompany.membersCountPlaceholder',
                })}
                type="number"
                onChange={onMembersCountChange}
                value={companyInfo?.companyData?.employeesNumber}
              />

              <TeamMembers
                companyData={companyInfo?.companyData}
                employees={companyInfo?.employees}
                handleChangeEmployeeOrder={handleChangeEmployees}
                onAddNewTeamMember={onAddNewTeamMember}
                onEditTeamMember={onEditTeamMember}
                onDeleteTeamMember={onDeleteTeamMember}
                toggleHideTeamMember={toggleHideTeamMember}
              />
            </Collapsable>

            <Collapsable
              titleCustomStyles={Styles.TitleStyles}
              bodyCustomStyles={Styles.Section}
              id="editCompany.otherInformation"
            >
              <CompanyLegalStructure
                companyData={companyInfo?.companyData}
                legalStructures={initialData?.legalStructures}
                countries={initialData?.countries}
                provinces={initialData?.provinces}
                onChange={onChange}
              />

              <CompanyIndustries
                companyData={companyInfo?.companyData}
                industriesOptions={initialData?.industries}
                investmentToolIndustries={companyInfo.investmentToolIndustries}
                onChange={onChange}
                isFundCompany={isFundCompany}
              />
            </Collapsable>
          </Styles.Wrapper>
        )}
      </Drawer.Content>
    </Drawer>
  )
}

export default CompanyProfileEditDrawer
