import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import CWLoader from 'components/CWLoader'
import Toast from 'components/Toast'
import useQueryString from 'utils/hooks/useQueryString'
import * as Styles from './SignInHandler.styles'

const SignInDesktopHandler = () => {
  const { handleRedirectCallback } = useAuth0()
  const history = useHistory()
  const code = useQueryString('code')
  const state = useQueryString('state')

  const handleRedirectUrl = async () => {
    try {
      await handleRedirectCallback(
        `/redirect/auth0/app?code=${code}&state=${encodeURIComponent(state!)}`
      )

      history.push('/redirect/auth0')
    } catch (e) {
      Toast.displayIntl('login.signInError', 'error')
      setTimeout(() => {
        history.replace('/sign-out')
      }, 2000)
    }
  }

  useEffect(() => {
    handleRedirectUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styles.Container>
      <CWLoader />
    </Styles.Container>
  )
}

export default SignInDesktopHandler
