import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  padding: 2.95rem 2.4rem;
  background-color: ${color('white')};
  border-radius: 0.8rem;
  box-shadow: 0px 0px 15px ${color('darkBlue', 0.1)};
  border: 1px solid ${color('veryLightGray')};
  max-width: calc(100% - 3.5rem - 3.5rem);

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0px;
  text-align: center;
  color: ${color('darkGray')};
  word-break: break-all;
`
