import dayjs from 'dayjs'
import { formatStringList } from 'utils/functions/utils'
import { ChangeSetItem, EventType, FieldValue } from '../types'
import { DefaultLogTextBuilder } from './DefaultLogTextBuilder'

export class InvestorCustomTextBuilder extends DefaultLogTextBuilder {
  private getSwitchedText([from, to]: ChangeSetItem): React.ReactNode {
    return this.formatMessage('logs.switchedFromTo', { from, to })
  }

  private getAddedIndustryText(text: string): React.ReactNode {
    return this.formatMessage('logs.addedIndustry', { text })
  }

  private getRemovedIndustryText(text: string): React.ReactNode {
    return this.formatMessage('logs.removedIndustry', { text })
  }

  private getSentInvitationText(text: string): React.ReactNode {
    return this.formatMessage('logs.sentInvitation', { text })
  }

  private getMemberJoinedText(text: string): React.ReactNode {
    return this.formatMessage('logs.memberJoined', { text })
  }

  private getRemovedMemberText(text: string): React.ReactNode {
    return this.formatMessage('logs.removedMember', { text })
  }

  private getEditedInvestorNameText([
    from,
    to,
  ]: ChangeSetItem): React.ReactNode {
    return this.formatMessage('logs.editedInvestorName', { from, to })
  }

  private getAddedFoundedDateText(date: string): React.ReactNode {
    return this.formatMessage('logs.addedFoundedDate', { date })
  }

  private getDeletedFoundedDateText(date: string): React.ReactNode {
    return this.formatMessage('logs.deletedFoundedDate', { date })
  }

  private getChangedFoundedDateText([
    from,
    to,
  ]: ChangeSetItem): React.ReactNode {
    return this.formatMessage('logs.changedFoundedDate', { from, to })
  }

  private getAddedWebsiteText(website: string): React.ReactNode {
    return this.formatMessage('logs.addedWebsite', { website })
  }

  private getDeletedWebsiteText(website: string): React.ReactNode {
    return this.formatMessage('logs.deletedWebsite', { website })
  }

  private getChangedWebsiteText([from, to]: ChangeSetItem): React.ReactNode {
    return this.formatMessage('logs.changedWebsite', { from, to })
  }

  private getAddedAngellistText(
    fieldName: string,
    to: string
  ): React.ReactNode {
    return this.formatMessage('logs.addedFieldAn', { fieldName, to })
  }

  private static formatDate(date: FieldValue) {
    return dayjs(date as string).format('DD MMM YYYY')
  }

  public getLogText(
    event: EventType,
    fieldName: string,
    changeSetItem: ChangeSetItem,
    text: string
  ): React.ReactNode {
    if (event === EventType.UPDATE) {
      if (fieldName === 'investorType') {
        return this.getSwitchedText(changeSetItem)
      }

      if (fieldName === 'name') {
        return this.getEditedInvestorNameText(changeSetItem)
      }
    }

    if (fieldName === 'foundedDate') {
      if (changeSetItem[0] === null) {
        return this.getAddedFoundedDateText(
          InvestorCustomTextBuilder.formatDate(changeSetItem[1])
        )
      }

      if (changeSetItem[1] === null) {
        return this.getDeletedFoundedDateText(
          InvestorCustomTextBuilder.formatDate(changeSetItem[0])
        )
      }

      return this.getChangedFoundedDateText([
        InvestorCustomTextBuilder.formatDate(changeSetItem[0]),
        InvestorCustomTextBuilder.formatDate(changeSetItem[1]),
      ])
    }

    if (fieldName === 'website') {
      if (changeSetItem[0] === null) {
        return this.getAddedWebsiteText(changeSetItem[1] as string)
      }

      if (changeSetItem[1] === null) {
        return this.getDeletedWebsiteText(changeSetItem[0] as string)
      }

      return this.getChangedWebsiteText(changeSetItem)
    }

    if (fieldName === 'angellistUrl') {
      if (changeSetItem[0] === null && changeSetItem[1] !== null) {
        return this.getAddedAngellistText(fieldName, changeSetItem[1] as string)
      }

      return super.getLogText(event, fieldName, changeSetItem, text)
    }

    if (event === EventType.ADD_INDUSTRY) {
      return this.getAddedIndustryText(text)
    }

    if (event === EventType.REMOVE_INDUSTRY) {
      return this.getRemovedIndustryText(text)
    }

    if (event === EventType.INVITE_MEMBERS) {
      return this.getSentInvitationText(
        formatStringList(changeSetItem[1] as string[], null, this.intl)
      )
    }

    if (event === EventType.ADD_MEMBER) {
      return this.getMemberJoinedText(text)
    }

    if (event === EventType.REMOVE_MEMBER) {
      return this.getRemovedMemberText(text)
    }

    return null
  }
}
