import {
  getCurrentInvestmentVehicle,
  getCurrentInvestor,
} from 'features/investorManagementSlice'

import { PortfolioTypes } from 'utils/constants/portfolio'
import { GroupTypes } from 'utils/constants/groups'
import { UpdateTypes } from 'utils/constants/updates'

import {
  getCurrentGroupCompaniesIds,
  getCurrentGroupId,
  getCurrentGroupData,
  isActingAsFounder,
  isActingAsFundManager,
} from './auth'

export const getCurrentCompany = (state) => state?.companies?.currentCompany

export const getFounderCompany = (state) => {
  const groupData = getCurrentGroupData(state)

  if (groupData?.type === GroupTypes.FOUNDER) {
    return groupData.companyData[0]
  }

  if (groupData?.type === GroupTypes.FUND_MANAGER) {
    return groupData.companyData.find((company) => company.fund)
  }

  return null
}

export const currentCompanyIsLoading = ({ companies }) => companies.loading

export const getOwner = ({ companies }) => companies.owner

export const getSignupCompany = ({ companies }) => companies.signupCompany

export const getCurrentCompanyOwner = ({ companies }) =>
  companies.currentCompany?.owner

export const getCurrentUpdates = ({ companies }) => companies.currentUpdates

export const getCurrentUpdatesButDraft = ({ companies }) => {
  const updates =
    companies.currentUpdates.updates?.filter(
      (update) => !update.isDraftUpdate
    ) || []

  return {
    updates,
    page: companies.currentUpdates.page,
  }
}

export const getCurrentUpdatesWithoutEmailsDraft = ({ companies }) => {
  const updates =
    companies.currentUpdates.updates?.filter((update) => {
      const isEmail = update.updateType === UpdateTypes.IUE

      if (!isEmail) {
        return update
      }

      return isEmail && !update.isDraftUpdate
    }) || []

  return {
    updates,
    page: companies.currentUpdates.page,
  }
}

export const getOnlyEmailUpdates = ({ companies }) => {
  const updates =
    companies.currentUpdates.updates?.filter(
      (update) => update.updateType === UpdateTypes.IUE && !update.isDraftUpdate
    ) || []

  return {
    updates,
    page: companies.currentUpdates.page,
  }
}

export const getCurrentCompanyProfileBeingCreated = ({ companies }) =>
  companies.currentCompanyProfile

export const getIsFounderVerified = (state) => {
  return isActingAsFounder(state)
    ? state?.companies?.currentCompany?.verified
    : true
}

export const isCompanyPrivate = (company) => (state) => {
  const currentGroupCompanies = getCurrentGroupCompaniesIds(state)
  const isFounder = isActingAsFounder(state)
  const isFundManager = isActingAsFundManager(state)

  return (
    (!isFounder || isFundManager) &&
    !company?.fund &&
    currentGroupCompanies.includes(company?.id)
  )
}

export const isCurrentCompanyPrivate = (state) => {
  const currentCompany = getCurrentCompany(state)
  return isCompanyPrivate(currentCompany)(state)
}

export const isCurrentCompanyFundProfile = (state) => {
  const currentCompany = getCurrentCompany(state)
  const currentGroupId = getCurrentGroupId(state)
  const isFundManager = isActingAsFundManager(state)
  const companyOwnerId = currentCompany.owner?.id ?? currentCompany.groupId
  const isFundProfile = companyOwnerId === currentGroupId && currentCompany.fund

  const isInInvestorManagement =
    !!getCurrentInvestor(state) || !!getCurrentInvestmentVehicle(state)
  return isFundManager && isFundProfile && !isInInvestorManagement
}

export const isFundCompanyOwnedByCurrentGroup = (company) => (state) => {
  const isFundManager = isActingAsFundManager(state)
  const currentGroupId = getCurrentGroupId(state)

  return (
    isFundManager &&
    company?.type === PortfolioTypes.FUND &&
    company?.group?.id === currentGroupId
  )
}

export const isDealCompanyOwnedByCurrentGroup = (company) => (state) => {
  const isFundManager = isActingAsFundManager(state)
  const currentGroupId = getCurrentGroupId(state)

  return (
    isFundManager &&
    company?.type === PortfolioTypes.DEAL &&
    company?.group?.id === currentGroupId
  )
}

export const isFundCurrentCompanyOwnedByCurrentGroup = (state) => {
  const currentCompany = getCurrentCompany(state)
  return isFundCompanyOwnedByCurrentGroup(currentCompany)(state)
}

export const isDealCurrentCompanyOwnedByCurrentGroup = (state) => {
  const currentCompany = getCurrentCompany(state)
  return isDealCompanyOwnedByCurrentGroup(currentCompany)(state)
}
