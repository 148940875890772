import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface LimitationState {
  hidePortfolioMessage?: boolean
  hideTransactionMessage?: boolean
}

const initialState: LimitationState = {}

export const limitationsSlice = createSlice({
  name: 'limitations',
  initialState,
  reducers: {
    hidePortfolioMessage: (state) => {
      state.hidePortfolioMessage = true
    },
    hideTransactionMessage: (state) => {
      state.hideTransactionMessage = true
    },
  },
})

export const { hidePortfolioMessage, hideTransactionMessage } =
  limitationsSlice.actions

export const getIsPortfolioMessageHidden = (state: RootState) =>
  !!state.limitations.hidePortfolioMessage

export const getIsTransactionMessageHidden = (state: RootState) =>
  !!state.limitations.hideTransactionMessage

export default limitationsSlice.reducer
