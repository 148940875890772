import React, { useId } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Checkbox from 'components/Checkbox'
import { AttachedEmailFileContent } from 'utils/types/files'
import Tooltip from 'components/Tooltip'
import FileIcon from 'components/FileIcon'
import ToastMessage from 'components/ToastMessage'
import { formatStringList } from 'utils/functions/utils'
import { getFileExtension } from 'utils/functions/files'
import * as Styles from './EmailContentRelatedFiles.styles'

interface EmailContentRelatedFilesProps {
  files: AttachedEmailFileContent[]
  onCheckboxChange: (checked: boolean) => void
  fromSuggestedUpdate?: boolean
  discardIntlKey?: string
  contentTitle?: string
}

const EmailContentRelatedFiles: React.FC<EmailContentRelatedFilesProps> = ({
  files,
  onCheckboxChange,
  fromSuggestedUpdate,
  discardIntlKey,
  contentTitle,
}) => {
  const intl = useIntl()
  const [checked, setChecked] = React.useState(false)
  const [hideDisclaimer, setHideDisclaimer] = React.useState(false)
  const randomId = useId()
  const usedFileNames = files.reduce((acc, s3Content) => {
    if (!s3Content.unreconciled) {
      acc.push(s3Content.fileName)
    }
    return acc
  }, [] as string[])

  const onChange = React.useCallback(() => {
    setChecked(!checked)
    onCheckboxChange(!checked)
  }, [checked, onCheckboxChange])

  return (
    <Styles.Container fromSuggestedUpdate={fromSuggestedUpdate}>
      <Checkbox
        id="deleteRelatedFiles"
        name="deleteRelatedFiles"
        onChange={onChange}
        mode="default"
        label={intl.formatMessage(
          {
            id: fromSuggestedUpdate
              ? `suggestedUpdates.discard.${discardIntlKey}.deleteAnyContent`
              : 'files.deleteModal.content.deleteRelatedFiles',
          },
          {
            count: files.length,
            tooltip: (text) => (
              <Tooltip
                id={'deleteRelatedFilesTooltip'.concat('-', randomId)}
                backgroundColor="transparent"
                clickable
                text={
                  <Styles.FilesTooltip>
                    {contentTitle && (
                      <>
                        <Styles.FileRow>
                          <Styles.EmailIcon icon={['far', 'envelope']} />
                          <Styles.FileName>{contentTitle}</Styles.FileName>
                        </Styles.FileRow>
                        <Styles.TitleSeparator />
                      </>
                    )}
                    {files.map((s3Content) => (
                      <Styles.FileRow
                        key={s3Content.id}
                        fileType={getFileExtension(s3Content.fileFormat)}
                      >
                        <FileIcon type={s3Content.fileFormat} />
                        <Styles.FileName>{s3Content.fileName}</Styles.FileName>
                      </Styles.FileRow>
                    ))}
                  </Styles.FilesTooltip>
                }
                place="bottom"
                widthAuto
              >
                <Styles.RelatedFilesText>{text}</Styles.RelatedFilesText>
              </Tooltip>
            ),
          }
        )}
        checked={checked}
      />
      {!hideDisclaimer && !!usedFileNames.length && (
        <ToastMessage>
          <Styles.DisclaimerMessageContainer>
            <div>
              <FormattedMessage
                id="files.deleteModal.content.filesCannotBeDeleted"
                values={{
                  files: <b>{formatStringList(usedFileNames, null, intl)}</b>,
                  contentOr: fromSuggestedUpdate ? (
                    <FormattedMessage id="files.deleteModal.content.contentOr" />
                  ) : (
                    ''
                  ),
                }}
              />
            </div>
            <Styles.CloseDisclaimerIcon onClick={() => setHideDisclaimer(true)}>
              <FontAwesomeIcon icon={['far', 'times']} />
            </Styles.CloseDisclaimerIcon>
          </Styles.DisclaimerMessageContainer>
        </ToastMessage>
      )}
    </Styles.Container>
  )
}

export default EmailContentRelatedFiles
