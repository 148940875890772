/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { InvestmentsNavigationOption } from 'containers/Investments/types'
import { RootState } from 'store'
import { GroupTypes } from 'utils/constants/groups'
import { Nullable } from 'utils/types/common'
import { GroupType } from 'utils/types/user'

export enum DefaultRoutes {
  UPDATES = 'updates',
  EMAILS = 'emails',
  METRICS = 'metrics',
  FILES = 'files',
  CHAT = 'chat',
}

export enum InvestorRoutes {
  INVESTMENTS = 'investments',
  INVESTOR_PROFILE = 'profile',
  HOLDINGS = 'fund-holdings',
}

export enum FundManagerRoutes {
  INVESTMENTS = 'investments',
  ORGANIZATION_PROFILE = 'group-profile',
  INVESTOR_MANAGEMENT = 'investor-management',
  INVESTOR_VEHICLES = 'investor-vehicles',
  DISCOVER = 'discover',
}

export enum FounderRoutes {
  dashboard = 'dashboard',
}

export const DEFAULT_ROUTES_ARRAY = Object.values(DefaultRoutes)
const INVESTOR_ROUTES_ARRAY = Object.values(InvestorRoutes)
const FUND_MANAGER_ROUTES_ARRAY = Object.values(FundManagerRoutes)
const FOUNDER_ROUTES_ARRAY = Object.values(FounderRoutes)

export type GroupTypesProps = Nullable<GroupType>

export type URL = {
  current: string
  previous: string
  all: string[]
}

export type Routes =
  | InvestorRoutes
  | FundManagerRoutes
  | FounderRoutes
  | DefaultRoutes

export type RoutesType = Nullable<Routes>

type InitialState = {
  url: URL
  currentBaseRoute: RoutesType
  showInvestorInitialLink: boolean
  currentGroupType: GroupTypesProps
  currentGroupId: Nullable<string>
  investmentNavigationOptionSelected: Nullable<InvestmentsNavigationOption>
  initialLinkRedirect: string
  hideNavigation: boolean
}

export interface SetRouteConfigurationPayload {
  route: Routes
  initialLinkRedirect: string
}

const getFirstPartOfUrl = (url: string) => {
  const urlParts = url.split('/')
  return urlParts[1]
}

const initialState: InitialState = {
  url: {
    current: '',
    previous: '',
    all: [],
  },
  currentBaseRoute: null,
  showInvestorInitialLink: true,
  currentGroupType: null,
  currentGroupId: null,
  investmentNavigationOptionSelected: null,
  initialLinkRedirect: '/',
  hideNavigation: false,
}

export const breadcrumbSlice = createSlice({
  name: 'urlState',
  initialState,
  reducers: {
    setUrl: (state, action: PayloadAction<string>) => {
      state.url.previous = state.url.current
      state.url.current = action.payload
      state.url.all.push(action.payload)
    },

    setHideNavigation: (state, action: PayloadAction<boolean>) => {
      state.hideNavigation = action.payload
    },

    setInitialConfiguration: (
      state,
      action: PayloadAction<{
        url: string
        groupType: GroupType
        currentGroupId: string
      }>
    ) => {
      const isSameGroup =
        state.currentGroupType === action.payload.groupType &&
        state.currentGroupId === action.payload.currentGroupId

      if (
        state.currentBaseRoute &&
        isSameGroup &&
        action.payload.url === state.url.current
      ) {
        return
      }

      state.currentGroupType = action.payload.groupType
      state.currentGroupId = action.payload.currentGroupId
      state.showInvestorInitialLink = true
      state.initialLinkRedirect = `/${getFirstPartOfUrl(action.payload.url)}`

      const { groupType } = action.payload

      const isFundManager = groupType === GroupTypes.FUND_MANAGER
      const isClient = groupType === GroupTypes.CLIENT
      const isInvestor = groupType === GroupTypes.INVESTOR_GROUP
      const isFounder = groupType === GroupTypes.FOUNDER

      const urlPath = getFirstPartOfUrl(action.payload.url)

      if (DEFAULT_ROUTES_ARRAY.includes(urlPath as DefaultRoutes)) {
        state.currentBaseRoute = urlPath as DefaultRoutes
        state.investmentNavigationOptionSelected = null
        return
      }

      if (isFundManager || isClient) {
        if (urlPath === FundManagerRoutes.INVESTMENTS) {
          state.currentBaseRoute = FundManagerRoutes.INVESTMENTS
          state.investmentNavigationOptionSelected = null
          return
        }

        if (urlPath === FundManagerRoutes.ORGANIZATION_PROFILE) {
          state.currentBaseRoute = FundManagerRoutes.ORGANIZATION_PROFILE
          state.investmentNavigationOptionSelected = null

          return
        }

        if (urlPath === FundManagerRoutes.INVESTOR_MANAGEMENT) {
          if (action.payload.url.includes('investors')) {
            state.currentBaseRoute = FundManagerRoutes.INVESTOR_MANAGEMENT
            state.investmentNavigationOptionSelected = null

            return
          }

          state.currentBaseRoute = FundManagerRoutes.INVESTOR_VEHICLES
          state.investmentNavigationOptionSelected = null

          return
        }

        if (urlPath === 'companies' || urlPath === 'funds') {
          state.currentBaseRoute = FundManagerRoutes.DISCOVER
          state.investmentNavigationOptionSelected = null

          return
        }
      }

      if (isInvestor) {
        if (urlPath === InvestorRoutes.INVESTMENTS) {
          state.currentBaseRoute = InvestorRoutes.INVESTMENTS
          state.investmentNavigationOptionSelected = null

          return
        }

        if (urlPath === InvestorRoutes.INVESTOR_PROFILE) {
          state.currentBaseRoute = InvestorRoutes.INVESTOR_PROFILE
          state.investmentNavigationOptionSelected = null

          return
        }

        if (urlPath === InvestorRoutes.HOLDINGS) {
          state.currentBaseRoute = InvestorRoutes.HOLDINGS
          state.investmentNavigationOptionSelected = null

          return
        }
      }

      if (isFounder) {
        if (urlPath === FounderRoutes.dashboard) {
          state.currentBaseRoute = FounderRoutes.dashboard
          state.investmentNavigationOptionSelected = null
        }
      }
    },

    setRouteConfiguration: (
      state,
      action: PayloadAction<SetRouteConfigurationPayload>
    ) => {
      const { route } = action.payload
      if (route === null) return

      state.initialLinkRedirect = action.payload.initialLinkRedirect
      state.showInvestorInitialLink = true

      if (DEFAULT_ROUTES_ARRAY.includes(route as DefaultRoutes)) {
        state.currentBaseRoute = route as DefaultRoutes
        return
      }

      if (
        state.currentGroupType === GroupTypes.FUND_MANAGER ||
        state.currentGroupType === GroupTypes.CLIENT
      ) {
        if (FUND_MANAGER_ROUTES_ARRAY.includes(route as FundManagerRoutes)) {
          state.currentBaseRoute = route as FundManagerRoutes
          return
        }
      }

      if (state.currentGroupType === GroupTypes.INVESTOR_GROUP) {
        if (INVESTOR_ROUTES_ARRAY.includes(route as InvestorRoutes)) {
          state.currentBaseRoute = route as InvestorRoutes
          return
        }
      }

      if (state.currentGroupType === GroupTypes.FOUNDER) {
        if (FOUNDER_ROUTES_ARRAY.includes(route as FounderRoutes)) {
          state.currentBaseRoute = route as FounderRoutes
        }
      }
    },

    setInitialLinkRedirect: (state, action: PayloadAction<string>) => {
      state.initialLinkRedirect = action.payload
    },

    setInvestmentNavigationOptionSelected: (
      state,
      action: PayloadAction<any>
    ) => {
      state.investmentNavigationOptionSelected = action.payload
    },

    setShowInvestorInitialLink: (state, action: PayloadAction<boolean>) => {
      state.showInvestorInitialLink = action.payload
    },
  },
})

export const {
  setUrl,
  setInitialLinkRedirect,
  setHideNavigation,
  setRouteConfiguration,
  setInitialConfiguration,
  setInvestmentNavigationOptionSelected,
  setShowInvestorInitialLink,
} = breadcrumbSlice.actions
export const getUrl = (state: RootState) => state.breadcrumbState.url

export const getInitialLinkRedirect = (state: RootState) =>
  state.breadcrumbState.initialLinkRedirect

export const getHideNavigation = (state: RootState) =>
  state.breadcrumbState.hideNavigation

export const getCurrentBaseRoute = (state: RootState) =>
  state.breadcrumbState.currentBaseRoute

export const getInvestmentNavigationOptionSelected = (state: RootState) =>
  state.breadcrumbState.investmentNavigationOptionSelected

export const getShowInvestorInitialLink = (state: RootState) =>
  state.breadcrumbState.showInvestorInitialLink

export default breadcrumbSlice.reducer
