import { useLocation } from 'react-router-dom'
import {
  getCompanyIdFromUrl,
  getFundPortfolioIdFromUrl,
  getInvestmentVehicleIdFromUrl,
  getInvestorIdFromUrl,
  getMetricIdFromUrl,
  getUpdateTypeAndIdFromUrl,
} from './BreadcrumbNavigation/helpers'

const useParseCurrentUrl = () => {
  const location = useLocation()
  const current = location.pathname

  const companyId = getCompanyIdFromUrl(current)
  const metricId = getMetricIdFromUrl(current)
  const fundId = getFundPortfolioIdFromUrl(current)
  const investorId = getInvestorIdFromUrl(current)
  const investmentVehicleId = getInvestmentVehicleIdFromUrl(current)
  const { updateId, updateType } = getUpdateTypeAndIdFromUrl(current)

  return {
    companyId,
    fundId,
    investorId,
    metricId,
    investmentVehicleId,
    updateId,
    updateType,
  }
}

export default useParseCurrentUrl
