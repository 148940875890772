import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const AttachmentInfo = styled.div`
  padding-left: 0.8rem;
  font-size: 1.4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 0;
  text-align: center;
  font-weight: 400;
  color: ${color('darkGray')};
`

export const StyledImageContainer = styled.div<{ size?: string }>`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.8rem;
  flex-direction: row;
  height: 4rem;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid ${color('veryLightGray')};
  transition: border-color 0.2s;

  &:hover {
    border: 0.1rem solid ${color('veryLightBlue')};
  }

  ${(props) =>
    props.size === 'large' &&
    css`
      height: 13.2rem;
      width: 13.2rem;
    `}

  svg {
    border-radius: 10rem;
    width: 1.8rem;
    height: 1.8rem;
  }

  @media ${maxSize.xsm} {
    max-width: 19rem;
  }
`

export const StyledImagePreview = styled.img`
  object-fit: cover;
  width: 4rem;
  height: 100%;
`
