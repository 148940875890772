import styled, { css } from 'styled-components'

import Input from 'ui/Input'
import { Prefix } from 'ui/Input/Input.styles'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const SEARCH_INPUT_WIDTH = 238

export const SearchInputContainer = styled.div`
  height: 5.2rem;
  margin: 0 1rem;

  @media ${maxSize.mobile} {
    margin: 2.2rem 2.4rem 0;
    height: initial;
  }
`

export const SearchInput = styled(Input)<{ showLeftArrow: boolean }>`
  position: relative;
  z-index: 1;

  ${Prefix} {
    left: 1.6rem;
    top: 1.2rem;
    display: none;
    cursor: pointer;
  }

  input {
    padding-left: 1.6rem;
  }

  @media ${size.lg} {
    :focus-within {
      position: fixed;
      width: ${SEARCH_INPUT_WIDTH}px;

      svg {
        color: ${color('primaryBlue')};
      }

      ::before {
        z-index: -1;
        content: '';
        position: absolute;
        width: calc(100% + 0.8rem);
        height: calc(100% + 0.8rem);
        background: ${color('white')};
        border: 0.1rem solid ${color('veryLightGray')};
        border-radius: 0.8rem;
        bottom: -0.4rem;
        left: -0.4rem;
        box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.1);
      }

      ${({ showLeftArrow }) =>
        showLeftArrow &&
        css`
          input {
            padding-left: 3.8rem;
          }

          ${Prefix} {
            display: block;
          }
        `}
    }

    input:hover:not(:focus) {
      border-color: transparent;
      box-shadow: 0px 0px 0px 0.2rem ${color('veryLightBlue')};
    }
  }
`
