/* eslint-disable no-loop-func */
/* eslint-disable no-param-reassign */
import { ImageSize } from 'utils/constants/iue'
import {
  convertHtmlToMJML,
  convertMJMLToHTML,
  getImageSize,
  getTextAlignType,
} from './mjml'
import { randomId } from './number'

export class IUEHelper {
  static updateEntryField = ({
    sectionsWithEntries,
    sectionId,
    entryId,
    entryIndex,
    fieldName,
    newValue,
  }) => {
    return sectionsWithEntries.map((sectionWithEntries) => {
      if (sectionWithEntries.id === sectionId) {
        return {
          ...sectionWithEntries,
          entries: sectionWithEntries.entries.map((entry, index) => {
            if (entry.id === entryId || index === entryIndex) {
              return {
                ...entry,
                [fieldName]: newValue,
              }
            }
            return entry
          }),
        }
      }
      return sectionWithEntries
    })
  }

  static updateSectionField = ({
    sectionsWithEntries,
    sectionId,
    fieldName,
    newValue,
  }) => {
    return sectionsWithEntries.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          [fieldName]: newValue,
        }
      }
      return section
    })
  }

  // This function gathers the entries content (mjml) to be sent to the API
  static gatherEntriesToUpdateBySectionId = (
    sectionId,
    newSectionsWithEntries
  ) => {
    let content = ''
    newSectionsWithEntries.forEach((section) => {
      if (section.id === sectionId) {
        section.entries.forEach((entry) => {
          content += convertHtmlToMJML({
            html: entry.content,
            type: entry.type,
            textAlign: entry.textAlign,
            imageSize: entry.imageSize,
            sectionColor: section.backgroundColor,
          })
        })
      }
    })
    return content
  }

  static serializeSectionsWithEntries = (reportSections) => {
    return reportSections.map((reportSection) => {
      if (reportSection.text) {
        reportSection.entries = []
        const sectionsArray = reportSection.text.match(
          /((<mj-text.*?>((.|\n)*?)<\/mj-text>)|(<mj-image((.|\n)*?) \/>))/g
        )
        sectionsArray.forEach((textEntry) => {
          const type = textEntry.includes('text') ? 'text' : 'image'
          let imageSize
          let textAlign
          if (type === 'image') {
            imageSize = getImageSize(textEntry)
          } else {
            textAlign = getTextAlignType(textEntry)
          }
          const serializeText = convertMJMLToHTML(textEntry, type)
          reportSection.entries.push({
            id: randomId(),
            type,
            content: serializeText,
            textAlign,
            imageSize,
          })
        })
      }
      return reportSection
    })
  }

  static filterDefaultSectionsByReportSections = (
    defaultSections,
    reportEntries
  ) => {
    const reportEntriesTitles = reportEntries?.map(
      (reportSection) => reportSection.title
    )
    const filteredEmailSections = defaultSections?.filter(
      (emailSection) => !reportEntriesTitles.includes(emailSection.title)
    )
    return filteredEmailSections
  }

  static getNewSections = (allSections, sectionsToRemove) =>
    allSections
      .filter((section) => !sectionsToRemove.includes(section.title))
      .map((section) => section.title)

  static addEntryToSection = ({
    sectionsWithEntries,
    sectionId,
    index,
    content = '',
    type,
    imageSize,
  }) => {
    return sectionsWithEntries.map((currSection) => {
      if (sectionId === currSection.id) {
        if (index !== undefined) {
          return {
            ...currSection,
            entries: currSection.entries.map((entry, entryIndex) => {
              if (entryIndex === index) {
                return {
                  id: entry.id,
                  type,
                  content: type === 'text' ? '<p></p>' : content,
                  imageSize: type === 'image' ? imageSize : undefined,
                }
              }
              return entry
            }),
          }
        }
        return {
          ...currSection,
          entries: [
            ...(currSection.entries || []),
            {
              id: randomId(),
              type,
              content: type === 'text' ? '<p></p>' : content,
              imageSize: type === 'image' ? ImageSize.LARGE : undefined,
            },
          ],
        }
      }
      return currSection
    })
  }
}

export const getNextSectionName = (sections, baseName) => {
  if (!sections.some((section) => section.title === baseName)) {
    return baseName
  }

  let sectionNumber = 2
  let sectionTitleCandidate = `${baseName} ${sectionNumber}`
  while (sections.some((section) => section.title === sectionTitleCandidate)) {
    sectionNumber++
    sectionTitleCandidate = `${baseName} ${sectionNumber}`
  }

  return sectionTitleCandidate
}
