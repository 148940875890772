import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const METRIC_VALUE_WIDTH = 85
export const METRIC_VALUE_HEIGHT = 50
export const METRIC_CHART_HEIGHT = 108

export const ValuesContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: end;
  overflow: hidden;
  margin: 0 2rem;

  @media ${maxSize.sm} {
    justify-content: center;
    margin: 0;
    overflow: visible;
  }
`

export const Values = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: ${METRIC_VALUE_HEIGHT}px;

  .ReactVirtualized__Grid {
    overflow: hidden !important;
  }

  transition: transform 0.5s ease-in-out;
  transform: translateX(-0%);
`

export const DataPointContainer = styled.div`
  border-right: 1px solid ${color('borderGray')};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: ${METRIC_VALUE_WIDTH}px;

  :last-child {
    border-right: none;
  }
`

export const DataPoint = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`

export const DataPointValue = styled.div`
  color: ${color('darkGray')};
  font-size: 14px;
  padding-top: 0.4rem;
`

export const DataPointDate = styled.div`
  font-size: 12px;
  color: ${color('lightGray')};
  width: max-content;
`

export const ArrowContainer = styled.div<{ isLeftArrow?: boolean }>`
  width: 20px;
  font-size: 1.6rem;
  display: flex;
  justify-content: ${({ isLeftArrow }) =>
    isLeftArrow ? 'flex-start' : 'flex-end'};
  align-items: center;
  padding-bottom: 0.5rem;
`

export const Arrow = styled(ArrowContainer)<{ hidden: boolean }>`
  cursor: pointer;

  ${({ hidden }) =>
    hidden &&
    css`
      visibility: hidden;
    `}
`

export const GrowthContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.3rem;

  span {
    font-size: 12px;
    padding-top: 0;
  }
`
