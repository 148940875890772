import type { IconName } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import { useIntl } from 'react-intl'
import IconButton from 'ui/IconButton'
import { assertUnreachable } from 'utils/functions/utils'
import theme from 'utils/theme'
import { FileUploadStatus } from 'utils/types/uploads'
import * as Styles from './FileUploadRow.styles'

interface FileUploadRowProps {
  fileName: string
  uploadState: FileUploadStatus
  timeLeft?: number
  isLast?: boolean
  hasActionButtons?: boolean
  onCancelUpload: () => void
  onRestartUpload: () => void
  onDeleteUpload: () => void
}

const FileUploadRow: React.FC<FileUploadRowProps> = ({
  fileName,
  uploadState,
  timeLeft,
  isLast,
  hasActionButtons,
  onCancelUpload,
  onRestartUpload,
  onDeleteUpload,
}) => {
  const intl = useIntl()

  const getIcon = (): { icon: IconName; color: string } => {
    switch (uploadState) {
      case FileUploadStatus.CANCELED:
        return { icon: 'trash-alt', color: theme.colors.red }
      case FileUploadStatus.UPLOADING:
        return { icon: 'stop-circle', color: theme.colors.black }
      case FileUploadStatus.ERROR:
        return { icon: 'exclamation-triangle', color: theme.colors.red }
      case FileUploadStatus.FINISHED:
        return { icon: 'check', color: theme.colors.green }
      default:
        return assertUnreachable(uploadState)
    }
  }

  const getStatus = () => {
    if (uploadState === FileUploadStatus.FINISHED)
      return intl.formatMessage({ id: 'files.finished' })

    if (uploadState === FileUploadStatus.ERROR)
      return intl.formatMessage({ id: 'files.error' })

    if (uploadState === FileUploadStatus.CANCELED)
      return intl.formatMessage({ id: 'files.canceled' })

    if (timeLeft === undefined || timeLeft === Infinity) return ''

    if (timeLeft! < 30)
      return intl.formatMessage({ id: 'files.secondsLeft' }, { count: 30 })

    if (timeLeft! < 60)
      return intl.formatMessage({ id: 'files.lessThanMinute' })

    const minutesLeft = Math.round(timeLeft! / 60)
    return intl.formatMessage(
      {
        id: 'files.lessThanNMinutes',
      },
      { count: minutesLeft }
    )
  }

  const onClickIcon = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    switch (uploadState) {
      case FileUploadStatus.CANCELED:
        onDeleteUpload()
        return
      case FileUploadStatus.UPLOADING:
        onCancelUpload()
        return
      case FileUploadStatus.ERROR:
        return
      case FileUploadStatus.FINISHED:
        return
      default:
        assertUnreachable(uploadState)
    }
  }

  const renderIcon = () => {
    const { icon, color } = getIcon()

    if (
      uploadState === FileUploadStatus.FINISHED ||
      uploadState === FileUploadStatus.ERROR
    ) {
      return <Styles.Icon icon={['far', icon]} color={color} />
    }

    return (
      <IconButton
        onClick={onClickIcon}
        border={false}
        iconFontSize="1.6rem"
        icon={icon}
        color={color}
      />
    )
  }

  return (
    <Styles.Container isLast={isLast} hasActionButtons={hasActionButtons}>
      <Styles.FileName title={fileName}>{fileName}</Styles.FileName>
      <Styles.Status>{getStatus()}</Styles.Status>
      <Styles.Buttons>
        {uploadState === FileUploadStatus.CANCELED && (
          <IconButton
            onClick={onRestartUpload}
            icon="redo"
            border={false}
            iconFontSize="1.6rem"
          />
        )}
        {renderIcon()}
      </Styles.Buttons>
    </Styles.Container>
  )
}

export default FileUploadRow
