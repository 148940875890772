import { useCallback, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import type { IntlShape } from 'react-intl'
import type { CellChange, Column, EventHandlers, Row } from '@silevis/reactgrid'
import * as yup from 'yup'
import {
  CompanyHolding,
  Holding,
  HoldingType,
  isFundHolding,
} from 'utils/types/company'
import { useEventListener } from 'utils/hooks/useEventListener'
import { StaticDropdownCell } from 'components/Spreadsheet/CellTemplates/CustomStaticDropdownCellTemplate'
import {
  CELL_ERROR,
  CustomCell,
  CustomTextCell,
  RowNumberCell,
} from 'components/Spreadsheet/types'
import { assertUnreachable } from 'utils/functions/utils'
import { WebsiteCell } from 'components/OnboardingModal/components/SetupYourPipelineStep/components/AddHoldingSpreadsheet/components/WebsiteCellTemplate/WebsiteCellTemplate'
import { BulkImportSuggestionErrors } from 'components/AddHoldingModal/types'
import {
  BaseColumn,
  DEFAULT_CELL_HEIGHT,
  DEFAULT_CELL_WIDTH,
  OptionColumn,
  RowNumberColumn,
  SpreadsheetBorders,
  TextColumn,
  getStaticDropdownCell,
} from 'components/Spreadsheet/utils'
import { HoldingNameCell } from './components/HoldingNameCellTemplate/HoldingNameCellTemplate'
import { useHoldingsSpreadsheetContext } from './HoldingsSpreadsheetContext'

type WebsiteColumn = BaseColumn & {
  type: 'website'
}

type HoldingNameColumn = BaseColumn & {
  type: 'holdingName'
}

export type ColumnType =
  | RowNumberColumn
  | WebsiteColumn
  | TextColumn
  | OptionColumn
  | HoldingNameColumn

type ColumnNames =
  | 'RowNumber'
  | 'Website'
  | 'Type'
  | 'Name'
  | 'LegalEntityName'
  | 'PointOfContact'
  | 'FundManagerName'

export const Columns: Record<ColumnNames, ColumnType> = {
  RowNumber: {
    index: 0,
    type: 'rowNumber',
    label: '#',
    width: 42,
  },
  Name: {
    index: 1,
    type: 'holdingName',
    label: 'spreadsheet.holdings.columns.name',
    placeholder: 'spreadsheet.holdings.columns.namePlaceholder',
  },
  Type: {
    index: 2,
    type: 'option',
    label: 'spreadsheet.holdings.columns.type',
    placeholder: 'spreadsheet.holdings.columns.typePlaceholder',
    width: 110,
    getOptions: (intl: IntlShape) => [
      {
        id: HoldingType.COMPANY,
        label: intl.formatMessage({
          id: 'addHolding.company',
        }),
        value: HoldingType.COMPANY,
      },
      {
        id: HoldingType.FUND,
        label: intl.formatMessage({
          id: 'addHolding.fund',
        }),
        value: HoldingType.FUND,
      },
    ],
  },
  LegalEntityName: {
    index: 3,
    type: 'text',
    label: 'spreadsheet.holdings.columns.legalEntityName',
    placeholder: 'spreadsheet.holdings.columns.legalEntityNamePlaceholder',
  },
  Website: {
    index: 4,
    type: 'website',
    label: 'spreadsheet.holdings.columns.website',
    placeholder: 'spreadsheet.holdings.columns.websitePlaceholder',
    width: 200,
  },
  PointOfContact: {
    index: 5,
    type: 'text',
    label: 'spreadsheet.holdings.columns.pointOfContact',
    placeholder: 'spreadsheet.holdings.columns.pointOfContactPlaceholder',
  },
  FundManagerName: {
    index: 6,
    type: 'text',
    label: 'spreadsheet.holdings.columns.fundManagerName',
    placeholder: 'spreadsheet.holdings.columns.fundManagerNamePlaceholder',
  },
}

const CommonColumns = [
  Columns.Website.index,
  Columns.Type.index,
  Columns.Name.index,
  Columns.PointOfContact.index,
]

// Available columns per holding type, besides the common ones
export const HoldingTypeColumns = {
  [HoldingType.COMPANY]: [Columns.LegalEntityName.index],
  [HoldingType.FUND]: [Columns.FundManagerName.index],
}

export type CellType =
  | CustomTextCell
  | WebsiteCell
  | StaticDropdownCell
  | RowNumberCell
  | HoldingNameCell
export type GridType = CellType[][]

const DEFAULT_EXTRA_ROWS = 0

const MANDATORY_FIELD_ERROR = 'spreadsheet.holdings.errors.mandatoryField'

const getEmptyRow = (intl: IntlShape, rowNumber: number): CellType[] => {
  return Object.values(Columns).map((column, index) => {
    switch (column.type) {
      case 'rowNumber':
        return {
          type: 'rowNumber',
          rowNumber,
          errors: new Set(),
          warnings: new Set(),
          rowIndex: rowNumber,
        }
      case 'website': {
        return {
          type: 'website',
          text: '',
          placeholder:
            column.placeholder &&
            intl.formatMessage({
              id: column.placeholder,
            }),
          validator: (value: string) => {
            const schema = yup.string().url()
            return schema.isValidSync(value)
          },
          errorMessage: 'general.invalidUrl',
          width: column.width,
          rowIndex: rowNumber,
          style: {
            border: {
              ...SpreadsheetBorders.NoLeftBorder,
              ...SpreadsheetBorders.NoRightBorder,
              ...SpreadsheetBorders.NoBottomBorder,
            },
          },
        }
      }
      case 'text': {
        const isPointOfContact = index === Columns.PointOfContact.index

        return {
          type: 'text',
          text: '',
          placeholder:
            column.placeholder &&
            intl.formatMessage({
              id: column.placeholder,
            }),
          ...(isPointOfContact && {
            validator: (value: string) => {
              const schema = yup.string().email()
              return schema.isValidSync(value)
            },
            errorMessage: 'general.invalidEmail',
          }),
          width: column.width,
          rowIndex: rowNumber,
          style: {
            border: {
              ...SpreadsheetBorders.NoLeftBorder,
              ...SpreadsheetBorders.NoRightBorder,
              ...SpreadsheetBorders.NoBottomBorder,
            },
          },
        }
      }
      case 'holdingName': {
        return {
          type: 'holdingName',
          text: '',
          placeholder:
            column.placeholder &&
            intl.formatMessage({
              id: column.placeholder,
            }),
          width: column.width,
          rowIndex: rowNumber,
          style: {
            border: {
              ...SpreadsheetBorders.NoLeftBorder,
              ...SpreadsheetBorders.NoRightBorder,
              ...SpreadsheetBorders.NoBottomBorder,
            },
          },
        }
      }
      case 'option': {
        const cell = getStaticDropdownCell({
          rowIndex: rowNumber,
          options: column.getOptions(intl),
        })

        return {
          ...cell,
          width: column.width,
          overrideWidth: `${column.width}px`,
          placeholder: column.placeholder,
        }
      }
      default:
        return assertUnreachable(column)
    }
  })
}

const getColumns = (currentGrid: GridType): Column[] => {
  return currentGrid[0].map((cell, index) => ({
    columnId: index,
    width: cell.width || DEFAULT_CELL_WIDTH,
  }))
}

const getRows = (currentGrid: GridType): Row<CellType>[] => {
  return currentGrid.map<Row<CellType>>((cells, idx) => {
    return {
      rowId: idx,
      cells: [...cells],
      height: DEFAULT_CELL_HEIGHT,
    }
  })
}

const createRow = (holdings: GridType, intl: IntlShape): CellType[] => {
  return getEmptyRow(intl, holdings.length)
}

const checkEnabledColumns = (row: CellType[]) => {
  const holdingTypeCell = row[Columns.Type.index] as StaticDropdownCell
  const holdingType = holdingTypeCell.option?.id
  const enabledColumns: number[] = [...CommonColumns]

  if (holdingType) {
    enabledColumns.push(...(HoldingTypeColumns[holdingType] || []))
  }

  row.forEach((cell, index) => {
    const customCell = cell as CustomCell

    if (index >= Columns.LegalEntityName.index) {
      const isEnabled = enabledColumns.includes(index)
      customCell.disabled = !isEnabled
    }
  })
}

const isHoldingNameCell = (cell: CellType): cell is HoldingNameCell => {
  return cell.type === 'holdingName'
}

const applyChangesToGrid = (
  changes: CellChange<CellType>[],
  currentHoldings: GridType,
  intl: IntlShape
): GridType => {
  const newHoldings = [...currentHoldings]
  const rowsChanged: Set<number> = new Set()

  changes.forEach((change) => {
    const rowIndex = change.rowId as number
    const columnIndex = change.columnId as number
    let nameChange: CellChange<HoldingNameCell> | undefined

    if (rowIndex === 0 && columnIndex === 0) {
      return
    }

    const row = newHoldings[rowIndex]

    if (!row) {
      newHoldings[rowIndex] = createRow(newHoldings, intl)
    } else {
      nameChange = changes.find(
        (changeToFind) =>
          changeToFind.columnId === Columns.Name.index &&
          changeToFind.rowId === rowIndex
      ) as CellChange<HoldingNameCell> | undefined
    }

    if (!rowsChanged.has(rowIndex)) {
      rowsChanged.add(rowIndex)
    }

    const cell = newHoldings[rowIndex][columnIndex]

    if (cell) {
      // When name changes it should change autoFilled to false on all other cells
      if (nameChange && !nameChange.newCell.holding) {
        row.forEach((cellToChange) => {
          const customCell = cellToChange as CustomCell
          customCell.autoFilled = false
        })
      }

      // Only apply change if the cell is not autoFilled
      if (!(cell as CustomCell).autoFilled) {
        newHoldings[rowIndex][columnIndex] = {
          ...change.newCell,
          rowIndex: cell.rowIndex,
        }
      }
    }
  })

  rowsChanged.forEach((rowIndex) => {
    const row = newHoldings[rowIndex]
    const holdingTypeCell = row[Columns.Type.index] as StaticDropdownCell
    const holdingType = holdingTypeCell.option?.id
    const enabledColumns: number[] = [...CommonColumns]

    if (holdingType) {
      enabledColumns.push(...(HoldingTypeColumns[holdingType] || []))
      checkEnabledColumns(row)
    }

    const websiteCell = row[Columns.Website.index] as WebsiteCell
    const pointOfContactCell = row[
      Columns.PointOfContact.index
    ] as CustomTextCell
    const fundManagerNameCell = row[
      Columns.FundManagerName.index
    ] as CustomTextCell

    if (
      !websiteCell.text &&
      !pointOfContactCell.text &&
      fundManagerNameCell.error === MANDATORY_FIELD_ERROR
    ) {
      fundManagerNameCell.error = undefined
    }

    row.forEach((cell, index) => {
      const customCell = cell as CustomCell
      const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell

      if (customCell.error && !customCell.disabled) {
        rowNumberCell.errors.add(index)
      } else {
        rowNumberCell.errors.delete(index)
      }

      if (isHoldingNameCell(cell)) {
        if (cell.holdingsToSuggest?.length) {
          rowNumberCell.warnings.add(index)
        } else {
          rowNumberCell.warnings.delete(index)
        }
      }
    })
  })

  return newHoldings
}

const getBaseGrid = (intl: IntlShape): GridType => {
  return [
    Object.values(Columns).map((column, index) => {
      if (index === Columns.RowNumber.index) {
        return {
          type: 'rowNumber',
          rowNumber: 0,
          rowIndex: 0,
          width: column.width,
          isHeader: true,
          errors: new Set(),
          warnings: new Set(),
        }
      }

      return {
        type: 'text',
        text: intl.formatMessage({
          id: column.label,
        }),
        nonEditable: true,
        width: column.width,
        rowIndex: 0,
        style: {
          border: {
            ...SpreadsheetBorders.NoLeftBorder,
            ...SpreadsheetBorders.NoTopBorder,
            ...SpreadsheetBorders.ThickBottomBorder,
            ...SpreadsheetBorders.NoRightBorder,
          },
        },
      }
    }),
    getEmptyRow(intl, 1),
  ]
}

const getFullGrid = (intl: IntlShape): GridType => {
  const grid = getBaseGrid(intl)

  for (let i = 0; i < DEFAULT_EXTRA_ROWS; i++) {
    grid.push(getEmptyRow(intl, grid.length))
  }

  return grid
}

const getGrid = (intl: IntlShape): GridType => {
  return getFullGrid(intl)
}

export const isEmptyRow = (row: CellType[]) => {
  const nameCell = row[Columns.Name.index] as HoldingNameCell
  const typeCell = row[Columns.Type.index] as StaticDropdownCell<HoldingType>
  const legalEntityNameCell = row[
    Columns.LegalEntityName.index
  ] as CustomTextCell
  const websiteCell = row[Columns.Website.index] as WebsiteCell
  const pointOfContactCell = row[Columns.PointOfContact.index] as CustomTextCell
  const fundManagerNameCell = row[
    Columns.FundManagerName.index
  ] as CustomTextCell

  return (
    !nameCell.text &&
    !typeCell.option?.value &&
    !legalEntityNameCell.text &&
    !websiteCell.text &&
    !pointOfContactCell.text &&
    !fundManagerNameCell.text
  )
}

export const validateRow = (row: CellType[]): void => {
  if (!isEmptyRow(row)) {
    const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell
    const nameCell = row[Columns.Name.index] as HoldingNameCell
    const typeCell = row[Columns.Type.index] as StaticDropdownCell<HoldingType>
    const legalEntityNameCell = row[
      Columns.LegalEntityName.index
    ] as CustomTextCell
    const websiteCell = row[Columns.Website.index] as WebsiteCell
    const pointOfContactCell = row[
      Columns.PointOfContact.index
    ] as CustomTextCell
    const fundManagerNameCell = row[
      Columns.FundManagerName.index
    ] as CustomTextCell

    if (!nameCell.text) {
      nameCell.error = CELL_ERROR
      nameCell.customError = {
        type: 'REQUIRED',
        error: MANDATORY_FIELD_ERROR,
      }
      rowNumberCell.errors.add(Columns.Name.index)
    }

    if (!typeCell.option?.value) {
      typeCell.error = MANDATORY_FIELD_ERROR
      rowNumberCell.errors.add(Columns.Type.index)
    } else if (typeCell.option?.value === HoldingType.COMPANY) {
      if (!legalEntityNameCell.text) {
        legalEntityNameCell.error = MANDATORY_FIELD_ERROR
        rowNumberCell.errors.add(Columns.LegalEntityName.index)
      }
    } else if (typeCell.option?.value === HoldingType.FUND) {
      const isTryingToAddFundManagerInfo =
        fundManagerNameCell.text || pointOfContactCell.text || websiteCell.text

      if (isTryingToAddFundManagerInfo) {
        if (!fundManagerNameCell.text) {
          fundManagerNameCell.error = MANDATORY_FIELD_ERROR
          rowNumberCell.errors.add(Columns.FundManagerName.index)
        }
      }
    }
  }
}

export enum HoldingsSpreadsheetEvents {
  HoldingsBulkImportDraftErrors = 'HoldingsBulkImportDraftErrors',
  HoldingSelectedFromDropdown = 'HoldingSelectedFromDropdown',
  CompanyHoldingSelectedFromWebsiteError = 'CompanyHoldingSelectedFromWebsiteError',
  SuggestHoldingsWithSameName = 'SuggestHoldingsWithSameName',
  ValidateGrid = 'ValidateGrid',
}

export interface AddHoldingsSpreadsheetProps {
  onGridChange?: (grid: GridType) => void
}

export const useAddHoldingsSpreadsheet = ({
  onGridChange,
}: AddHoldingsSpreadsheetProps) => {
  const intl = useIntl()
  const eventHandler = useRef<EventHandlers>()
  const { grid: contextGrid, setGrid: setContextGrid } =
    useHoldingsSpreadsheetContext()
  const [grid, setGrid] = useState<GridType>(contextGrid || getGrid(intl))
  const rows = useMemo(() => getRows(grid), [grid])
  const columns = useMemo(() => getColumns(grid), [grid])

  const handleChanges = useCallback(
    (changes: CellChange<CellType>[]) => {
      setGrid((currentGrid) => {
        const newMetrics = applyChangesToGrid(changes, currentGrid, intl)
        onGridChange?.(newMetrics)
        setContextGrid(newMetrics)
        return newMetrics
      })
    },
    [onGridChange, intl, setContextGrid]
  )

  const addRow = useCallback(() => {
    setGrid((currentHoldings) => {
      const newHoldings = [...currentHoldings, createRow(currentHoldings, intl)]

      for (let i = 1; i < newHoldings.length - 1; i++) {
        validateRow(newHoldings[i])
      }

      onGridChange?.(newHoldings)
      return newHoldings
    })
  }, [onGridChange, intl])

  const getEventHandler = useCallback((gridEventHandler) => {
    eventHandler.current = gridEventHandler
  }, [])

  useEventListener(
    HoldingsSpreadsheetEvents.HoldingSelectedFromDropdown,
    ({ holding }: { holding: Holding }) => {
      const newHoldingRows = [...grid]

      newHoldingRows.forEach((row) => {
        const holdingNameCell = row[Columns.Name.index] as HoldingNameCell

        if (holdingNameCell.holding?.id === holding.id) {
          const typeCell = row[Columns.Type.index] as StaticDropdownCell
          typeCell.option = typeCell.options.find(
            (option) => option.id === holding.holdingType
          )
          typeCell.autoFilled = true
          typeCell.error = undefined
          checkEnabledColumns(row)

          const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell
          rowNumberCell.errors = new Set()

          const pointOfContactCell = row[
            Columns.PointOfContact.index
          ] as CustomTextCell
          pointOfContactCell.text = ''
          pointOfContactCell.autoFilled = true
          pointOfContactCell.error = undefined

          const websiteCell = row[Columns.Website.index] as WebsiteCell
          websiteCell.autoFilled = true
          websiteCell.error = undefined
          websiteCell.customError = undefined

          if (isFundHolding(holding)) {
            const fundManagerNameCell = row[
              Columns.FundManagerName.index
            ] as CustomTextCell
            fundManagerNameCell.text = holding.group?.name || ''
            fundManagerNameCell.autoFilled = true
            fundManagerNameCell.error = undefined

            pointOfContactCell.text = holding.group?.email || ''

            websiteCell.text = holding.fundCompany?.website || ''
          } else {
            websiteCell.text = holding.website || ''

            const legalEntityNameCell = row[
              Columns.LegalEntityName.index
            ] as CustomTextCell
            legalEntityNameCell.text = holding.legalEntityName
            legalEntityNameCell.autoFilled = true
            legalEntityNameCell.error = undefined
          }
        }
      })

      setGrid(newHoldingRows)
      onGridChange?.(newHoldingRows)
    }
  )

  useEventListener(
    HoldingsSpreadsheetEvents.CompanyHoldingSelectedFromWebsiteError,
    ({ holding, rowIndex }: { holding: CompanyHolding; rowIndex: number }) => {
      const newHoldingRows = [...grid]
      const row = newHoldingRows[rowIndex]

      const typeCell = row[Columns.Type.index] as StaticDropdownCell
      typeCell.autoFilled = true
      typeCell.error = undefined

      if (holding.fund) {
        typeCell.option = typeCell.options.find(
          (option) => option.id === HoldingType.FUND
        )
        const fundManagerNameCell = row[
          Columns.FundManagerName.index
        ] as CustomTextCell
        fundManagerNameCell.text = holding.name || ''
        fundManagerNameCell.autoFilled = true
        fundManagerNameCell.error = undefined
      } else {
        typeCell.option = typeCell.options.find(
          (option) => option.id === HoldingType.COMPANY
        )
        const holdingNameCell = row[Columns.Name.index] as CustomTextCell
        holdingNameCell.text = holding.name
        holdingNameCell.error = undefined

        const legalEntityNameCell = row[
          Columns.LegalEntityName.index
        ] as CustomTextCell
        legalEntityNameCell.text = holding.legalEntityName
        legalEntityNameCell.autoFilled = true
        legalEntityNameCell.error = undefined
      }

      const pointOfContactCell = row[
        Columns.PointOfContact.index
      ] as CustomTextCell
      pointOfContactCell.text = ''
      pointOfContactCell.autoFilled = true
      pointOfContactCell.error = undefined

      const websiteCell = row[Columns.Website.index] as WebsiteCell
      websiteCell.autoFilled = true
      websiteCell.error = undefined

      const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell
      rowNumberCell.errors = new Set()

      checkEnabledColumns(row)
      setGrid(newHoldingRows)
      onGridChange?.(newHoldingRows)
    }
  )

  useEventListener(
    HoldingsSpreadsheetEvents.HoldingsBulkImportDraftErrors,
    ({
      errors,
      indexMap,
    }: {
      errors: BulkImportSuggestionErrors
      indexMap: Map<number, number>
    }) => {
      const newHoldingRows = [...grid]

      newHoldingRows.forEach((row, index) => {
        const mappedIndex = indexMap.get(index)

        if (mappedIndex !== undefined) {
          if (errors.holdings[mappedIndex].fundsErrors) {
            const holdingNameCell = row[Columns.Name.index] as HoldingNameCell
            holdingNameCell.customError = {
              type: 'DUPLICATE_NAME',
              error: errors.holdings[mappedIndex],
            }
            holdingNameCell.error = CELL_ERROR
            const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell
            rowNumberCell.errors.add(Columns.Name.index)
          } else if (errors.holdings[mappedIndex].companyErrors) {
            const websiteCell = row[Columns.Website.index] as WebsiteCell
            websiteCell.customError = {
              type: 'DUPLICATE_WEBSITE',
              error: errors.holdings[mappedIndex],
            }
            websiteCell.error = CELL_ERROR
            const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell
            rowNumberCell.errors.add(Columns.Website.index)
          }
        }
      })

      setGrid(newHoldingRows)
      onGridChange?.(newHoldingRows)
    }
  )

  useEventListener(
    HoldingsSpreadsheetEvents.SuggestHoldingsWithSameName,
    ({
      holdingsToSuggest,
      rowIndex,
    }: {
      holdingsToSuggest: Holding[]
      rowIndex: number
    }) => {
      const newHoldingRows = [...grid]
      const row = newHoldingRows[rowIndex]
      const nameCell = row[Columns.Name.index] as HoldingNameCell
      nameCell.holdingsToSuggest = holdingsToSuggest

      const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell
      rowNumberCell.warnings.add(Columns.Name.index)

      setGrid(newHoldingRows)
      onGridChange?.(newHoldingRows)
    }
  )

  useEventListener(HoldingsSpreadsheetEvents.ValidateGrid, () => {
    setGrid((currentHoldings) => {
      const newHoldings = [...currentHoldings]

      for (let i = 1; i < newHoldings.length; i++) {
        validateRow(newHoldings[i])
      }

      onGridChange?.(newHoldings)
      return newHoldings
    })
  })

  return {
    handleChanges,
    addRow,
    rows,
    columns,
    getEventHandler,
    eventHandler,
  }
}
