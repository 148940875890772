import styled from 'styled-components'

export const GridRow = styled.div<{ gap?: string }>`
  display: flex;
  justify-content: space-between;
  gap: ${(props) => props.gap || '2.8rem'};
  margin-bottom: 1.8rem;
`

export const GridCol = styled.div`
  width: 50%;
`
