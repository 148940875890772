import holdings from './addHoldingsSpreadsheet'
import metrics from './metricsSpreadsheet'

export default {
  optional: 'Optional',
  selectOption: 'Select option',
  addValue: 'Add value',
  rowContainsErrors: 'This row contains errors',
  transactions: {
    addMultipleTransactions: 'Add Multiple Transactions',
    modalDescription:
      'Add transactions manually or paste them from your spreadsheet',
    addTransactions: 'Add Transactions {count}',
    addTransactionTitle: 'Add transaction title',
    selectHolding: 'Select holding',
    addAmount: 'Add amount',
    maxDateError: 'Future dates are not allowed',
    maxPercentageError: 'Value must be less than 100%',
    invalidOption:
      'Invalid option. Make sure to choose a valid option from the menu.',
    invalidHolding:
      'This holding does not exist in our records. Please double-check the information and try again.',
    columns: {
      title: 'Title',
      holding: 'Holding',
      date: 'Date',
      transactionType: 'Transaction Type',
      transactionTypePlaceholder: 'Select type',
      instrument: 'Instrument',
      instrumentPlaceholder: 'Select instrument',
      amount: 'Amount',
      drawAgainstCommitment: 'Draw Against Commitment',
      description: 'Description',
      valuationCap: 'Valuation Cap',
      discountRate: 'Discount Rate',
      interestRate: 'Interest Rate',
      interestCalculationBasis: 'Interest Calculation Basis',
      maturityDate: 'Maturity Date',
      purchasePricePerShare: 'Purchase Price Per Share',
      sharesPurchased: 'Shares Purchased',
      preMoneyValuation: 'Pre Money Valuation',
      postMoneyValuation: 'Post Money Valuation',
      annualManagementFee: 'Annual Management Fee',
      carry: 'Carry',
      carryHurdleRate: 'Carry Hurdle Rate',
      dividend: 'Dividend',
      dividendCalculationBasis: 'Dividend Calculation Basis',
      dividendType: 'Dividend Type',
      vestingCommencementDate: 'Vesting Commencement Date',
      expirationDate: 'Expiration Date',
      strikePrice: 'Strike Price',
      numberOfShares: 'Number Of Shares',
    },
  },
  holdings,
  metrics,
}
