import { FormattedMessage } from 'react-intl'

import { DIRECTION, Direction } from 'components/PDFPreviewer/usePDFPreviewer'
import { useMediaQuery, Media } from 'utils/hooks/useMediaQuery'

import { Circle, Icon } from '../../PreviewContents.styles'
import * as Styles from './PDFControls.styles'

interface PDFControlsProps {
  navigate?: (direction: Direction) => void
  increaseScale?: () => void
  decreaseScale?: () => void
  pageNumber?: number
  pagesCount?: number
  scale?: number
}

const PDFControls = ({
  navigate,
  increaseScale,
  decreaseScale,
  pageNumber,
  pagesCount,
  scale,
}: PDFControlsProps) => {
  const { matches: isMobile } = useMediaQuery(Media.MOBILE)
  const isFirstPage = pageNumber === 1
  const isLastPage = pageNumber === pagesCount

  return (
    <Styles.Container>
      <Styles.HorizontalContent>
        <Styles.Page>
          <FormattedMessage id="general.page" />: {pageNumber}{' '}
          <FormattedMessage id="general.of" /> {pagesCount}
        </Styles.Page>
      </Styles.HorizontalContent>
      <Styles.HorizontalContent>
        <Circle
          onClick={() => navigate?.(DIRECTION.LEFT)}
          disabled={isFirstPage}
        >
          <Icon icon={['far', 'chevron-up']} />
        </Circle>
        <Circle
          onClick={() => navigate?.(DIRECTION.RIGHT)}
          disabled={isLastPage}
        >
          <Icon icon={['far', 'chevron-down']} />
        </Circle>
      </Styles.HorizontalContent>
      <Styles.Separator />
      <Styles.HorizontalContent>
        <Circle onClick={decreaseScale}>
          <Icon icon={['far', 'search-minus']} />
        </Circle>
        <Circle onClick={increaseScale}>
          <Icon icon={['far', 'search-plus']} />
        </Circle>
        <Styles.Percentage>
          {((scale ?? 0) * 100).toFixed(0)}%
        </Styles.Percentage>
      </Styles.HorizontalContent>
      {!isMobile && <Styles.Separator />}
    </Styles.Container>
  )
}

export default PDFControls
