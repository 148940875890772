import differenceWith from 'lodash/differenceWith'
import type { IntlShape, MessageFormatElement } from 'react-intl'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { EMAIL_REGEX } from 'utils/functions/regex'
import { Nullable } from 'utils/types/common'

export const formatStringList = (
  list: string[],
  maxItems: Nullable<number>,
  intl: IntlShape
): string => {
  const items = [...list]
  if (items.length === 1) {
    return items[0]
  }

  // return 'itemA and itemB'
  if (items.length === 2) {
    return items.join(` ${intl.formatMessage({ id: 'common.and' })} `)
  }

  // return 'itemA, itemB and itemC'
  if (items.length === maxItems || maxItems === null) {
    const lastItem = items.pop()
    return items
      .join(', ')
      .concat(` ${intl.formatMessage({ id: 'common.and' })} ${lastItem!}`)
  }

  // return 'itemA, itemB, itemC and N more'
  const itemsToShow = items.slice(0, maxItems - 1)
  return itemsToShow
    .join(', ')
    .concat(
      ` ${intl.formatMessage(
        { id: 'common.moreItems' },
        { count: list.length - itemsToShow.length }
      )}`
    )
}

export const generateKey = (value: string): string =>
  value.replaceAll(',', '').replaceAll(' ', '_').toLowerCase()

type DifferenceComparators<T, K> = [
  (entity: T) => string | number,
  (entity: K) => string | number
]

/**
 * Receives two arrays `oldEntities` and `newEntities` of potentially different type of objects, and returns:
 * - `added`: Those objects that are present in `newEntities` but not in `oldEntities`
 * - `removed`: thos objects that are present in `oldEntities` but not in `newEntities`
 *
 * By default, elements will be compared using the `id` attribute.
 * If elements of `oldEntities` and `newEntities` has different types, an optional `DifferenceComparators` param can be passed to obtain the corresponding comparator field for each array.
 */
export function getAddedAndRemovedEntities<
  T extends { id: string },
  K extends { id: string } = T
>(
  oldEntities: T[],
  newEntities: K[],
  comparators: DifferenceComparators<T, K> = [
    (entity: T) => entity.id,
    (entity: K) => entity.id,
  ]
): { added: K[]; removed: T[] } {
  const addedEntities = differenceWith(
    newEntities,
    oldEntities,
    (entityK, entityT) => comparators[1](entityK) === comparators[0](entityT)
  )

  const removedEntities = differenceWith(
    oldEntities,
    newEntities,
    (entityT, entityK) => comparators[0](entityT) === comparators[1](entityK)
  )

  return {
    added: addedEntities,
    removed: removedEntities,
  }
}

export const isValidEmail = (email: string) => EMAIL_REGEX.test(email)

export const assertUnreachable = (_x: never): never => {
  throw new Error("Didn't expect to get here")
}

export const getIntlPortfolioTypeKey = (type): string => {
  const translations = {
    [PortfolioTypes.TRACK]: 'portfolios.type.track',
    [PortfolioTypes.FUND]: 'portfolios.type.fund',
    [PortfolioTypes.INVEST]: 'portfolios.type.invest',
    [PortfolioTypes.DEAL]: 'portfolios.type.deal',
  }

  return translations[type] || type
}

export const toLower = (value: string | MessageFormatElement[]) =>
  value.toString().toLowerCase()

export const coordinatesInsideRect = (x: number, y: number, rect: DOMRect) => {
  return (
    x >= rect.left &&
    x <= rect.left + rect.width &&
    y >= rect.top &&
    y <= rect.top + rect.height
  )
}

export const capitalizeFirstLetter = (str: string): string => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.toLowerCase().slice(1))
    .join(' ')
}

export const hasAnyOf = <T>(arrayA: T[], arrayB: T[]): boolean =>
  arrayB.some((element) => arrayA.includes(element))

export const escapeSpecialChars = (str) => {
  return str.replace(/([ #;?%&,.+*~':"!^$[\]()=>|/@])/g, '\\$1')
}
