import { FormattedMessage } from 'react-intl'
import { PlanSelector } from 'containers/Signup/GetStarted'
import Separator from 'ui/Separator'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { PRICING_LINK } from 'utils/constants/links'
import useGetStarted from 'containers/Signup/GetStarted/hooks/useGetStarted'
import { ReactComponent as Earth } from 'assets/images/earth.svg'
import { useInvestorAccountSetup } from '../useInvestorAccountSetup'
import * as Styles from './SignupPlanSelector.styles'

const SignupPlanSelector = () => {
  const { onPlanSelected } = useInvestorAccountSetup()
  const { matches: carouselMode } = useMediaQuery('(max-width: 1280px)')
  const { matches: twoSlides } = useMediaQuery('(max-width: 1070px)')
  const { matches: oneSlide } = useMediaQuery('(max-width: 768px)')
  const { onClickFounderSetup } = useGetStarted()

  const getSlidesToShow = () => {
    if (oneSlide) return 1
    if (twoSlides) return 2

    return 3
  }

  return (
    <Styles.Container>
      <Styles.Title>
        <FormattedMessage id="signup.getStarted" />
      </Styles.Title>

      <Separator height="1.2rem" />

      <Styles.Subtitle>
        <FormattedMessage
          id="signup.plans.choosePlan"
          values={{
            a: (txt) => (
              <a href={PRICING_LINK} target="_blank" rel="noreferrer">
                <u>{txt}</u>
              </a>
            ),
          }}
        />
      </Styles.Subtitle>

      <Separator space={oneSlide ? '2.4rem' : '3.6rem'} />

      <PlanSelector
        onPlanSelect={onPlanSelected}
        carouselMode={carouselMode}
        slidesToShow={getSlidesToShow()}
        showTrialText
      />

      {!carouselMode && <Separator space="3.6rem" />}

      <Styles.Subtitle bold>
        <FormattedMessage id="signup.plans.pricesInUSD" />
      </Styles.Subtitle>

      <Separator space="2.4rem" />

      <Styles.NonClickableGroupCard>
        <Styles.GroupTypeImageCreate>
          <Earth />
        </Styles.GroupTypeImageCreate>
        <Styles.GroupTypeTitle>
          <FormattedMessage id="signup.areYouFounder" />
          <Styles.GroupTypeLegend>
            <FormattedMessage id="signup.founderDescriptionAlt" />
          </Styles.GroupTypeLegend>
          <Styles.CardLink onClick={onClickFounderSetup}>
            <FormattedMessage id="signup.getStartedNow" />
          </Styles.CardLink>
        </Styles.GroupTypeTitle>
      </Styles.NonClickableGroupCard>
    </Styles.Container>
  )
}

export default SignupPlanSelector
