import { FormattedMessage } from 'react-intl'

import ConfirmationModal from 'components/ConfirmationModal'

import { ModalBody } from './LeaveChannelModal.styles'
import { useLeaveChannelModal } from './useLeaveChannelModal'

interface LeaveChannelModalProps {
  show: boolean
  onHide: () => void
}

const LeaveChannelModal = ({ show, onHide }: LeaveChannelModalProps) => {
  const { onLeaveChannel } = useLeaveChannelModal(onHide)

  return (
    <ConfirmationModal
      dangerConfirm
      preventHideOnConfirm
      hasIcon={false}
      title={<FormattedMessage id="chat.leaveChat" />}
      onHide={onHide}
      isOpen={show}
      confirmMessage={<FormattedMessage id="chat.leaveConfirmation" />}
      ModalBody={
        <ModalBody>
          <FormattedMessage id="chat.areYouSureYouWantToLeave" />
        </ModalBody>
      }
      onConfirm={onLeaveChannel}
    />
  )
}

export default LeaveChannelModal
