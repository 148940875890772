import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import IconButton from 'ui/IconButton'
import Heading from 'components/Heading'
import crunchbaseLogo from 'assets/images/crunchbaselogo.svg'
import xTwitterLogo from 'assets/images/x-twitter.svg'

import {
  StyledTitleWrapper,
  SocialIcon,
  SocialButtons,
} from './CompanySocials.styles'

const CompanySocials = ({
  companyInfo,
  withTitle = true,
  centerLinks = false,
  ...props
}) => {
  const { linkedinUrl, crunchbaseUrl, twitterUrl, x } = companyInfo || {}

  if (!linkedinUrl && !crunchbaseUrl && !twitterUrl && !x) {
    return null
  }

  return (
    <StyledTitleWrapper {...props}>
      {withTitle && (
        <Heading level="h4">
          <FormattedMessage id="companyProfile.contactDetails" />
        </Heading>
      )}

      <SocialButtons centerLinks={centerLinks}>
        {linkedinUrl && (
          <IconButton
            iconFamily="fab"
            icon="linkedin-in"
            title={linkedinUrl}
            onClick={() => window.open(linkedinUrl)}
          />
        )}
        {(twitterUrl || x) && (
          <IconButton
            iconFamily="fab"
            customIcon={<SocialIcon src={xTwitterLogo} alt="X logo" />}
            title={twitterUrl}
            onClick={() => window.open(twitterUrl ?? x)}
          />
        )}
        {crunchbaseUrl && (
          <IconButton
            iconFamily="fab"
            customIcon={<SocialIcon src={crunchbaseLogo} alt="crunchbase" />}
            title={crunchbaseUrl}
            onClick={() => window.open(crunchbaseUrl)}
          />
        )}
      </SocialButtons>
    </StyledTitleWrapper>
  )
}

CompanySocials.propTypes = {
  companyInfo: PropTypes.object,
  withTitle: PropTypes.bool.isRequired,
  centerLinks: PropTypes.bool,
}

export default CompanySocials
