import {
  HoldingMessageLink,
  MessageLink,
  MessageAttachmentType,
  PortfolioMessageLink,
  StreamChatType,
  UpdateMessageLink,
} from 'containers/Chat/types'
import { useMemo } from 'react'
import type { Attachment } from 'stream-chat'
import { useChatContext, useMessageContext } from 'stream-chat-react'
import { getUpdateTypeFromUrl } from 'utils/functions/updates'
import { HoldingType } from 'utils/types/company'
import { isActingAsFounder } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  ParsedAttachmentLink,
  isLinkToApp,
  parseAttachmentLink,
} from 'containers/Chat/helpers'

export const useMessage = () => {
  const isFounderGroup = useAppSelector(isActingAsFounder)
  const { client } = useChatContext()
  const { message, initialMessage, threadList } =
    useMessageContext<StreamChatType>()
  const isDeletedForYou = useMemo(
    () => !!message.selfDeletedIds?.includes(client.userID || ''),
    [client.userID, message.selfDeletedIds]
  )
  const isDeleted = message.type === 'deleted' || isDeletedForYou

  const showMessageActions = useMemo(
    () =>
      !isDeleted &&
      !initialMessage &&
      message.status !== 'sending' &&
      message.status !== 'failed' &&
      message.type !== 'system' &&
      message.type !== 'ephemeral' &&
      message.type !== 'error',
    [isDeleted, initialMessage, message.status, message.type]
  )
  const showThreadInfo = useMemo(
    () => !threadList && !!message.reply_count,
    [message.reply_count, threadList]
  )
  const showReactionsList = useMemo(
    () => message.latest_reactions?.length !== 0 && message.text !== '',
    [message.latest_reactions, message.text]
  )
  const showEdited = useMemo(
    () => !isDeleted && message.edited,
    [isDeleted, message.edited]
  )
  const showAttachments = useMemo(
    () => !isDeleted && message.attachments?.length !== 0,
    [isDeleted, message.attachments?.length]
  )

  const parseHoldingAttachment = (
    acc: MessageLink[],
    parsedLinkData: ParsedAttachmentLink,
    attachment: Attachment<StreamChatType>
  ) => {
    if (parsedLinkData.companyId) {
      const companyAlreadyExists = acc.some(
        (accAttachment: HoldingMessageLink) =>
          accAttachment.holdingId === parsedLinkData.companyId
      )
      if (!companyAlreadyExists) {
        acc.push({
          attachmentType: MessageAttachmentType.HOLDING,
          link: attachment.title_link!,
          holdingId: parsedLinkData.companyId,
          holdingType: HoldingType.COMPANY,
          matchedPath: parsedLinkData.matchedPath,
        } as HoldingMessageLink)
      }
      return acc
    }

    const fundAlreadyExists = acc.some(
      (accAttachment: HoldingMessageLink) =>
        accAttachment.holdingId === parsedLinkData.fundId
    )
    if (!fundAlreadyExists) {
      acc.push({
        attachmentType: MessageAttachmentType.HOLDING,
        link: attachment.title_link!,
        holdingId: parsedLinkData.fundId!,
        holdingType: HoldingType.FUND,
        matchedPath: parsedLinkData.matchedPath,
      } as HoldingMessageLink)
    }

    return acc
  }

  const parsePortfolioAttachment = (
    acc: MessageLink[],
    parsedLinkData: ParsedAttachmentLink,
    attachment: Attachment<StreamChatType>
  ) => {
    const portfolioAlreadyExists = acc.some(
      (accAttachment: PortfolioMessageLink) =>
        accAttachment.portfolioId === parsedLinkData.portfolioId
    )

    if (!portfolioAlreadyExists) {
      acc.push({
        attachmentType: MessageAttachmentType.PORTFOLIO,
        link: attachment.title_link!,
        portfolioId: parsedLinkData.portfolioId,
        portfolioType: parsedLinkData.portfolioType,
        matchedPath: parsedLinkData.matchedPath,
      } as PortfolioMessageLink)
    }
    return acc
  }

  const parseUpdateAttachment = (
    acc: MessageLink[],
    parsedLinkData: ParsedAttachmentLink,
    attachment: Attachment<StreamChatType>
  ) => {
    const updateAlreadyExists = acc.some(
      (accAttachment: UpdateMessageLink) =>
        accAttachment.updateId === parsedLinkData.updateId
    )
    if (!updateAlreadyExists) {
      acc.push({
        attachmentType: MessageAttachmentType.UPDATE,
        link: attachment.title_link!,
        updateId: parsedLinkData.updateId,
        updateType: getUpdateTypeFromUrl(parsedLinkData.urlUpdateType),
        portfolioId: parsedLinkData.portfolioId,
        holdingId: parsedLinkData.companyId || parsedLinkData.fundId,
        matchedPath: parsedLinkData.matchedPath,
      } as UpdateMessageLink)
    }
    return acc
  }

  const parsedMessageLinks = useMemo(() => {
    return message.attachments?.reduce<MessageLink[]>((acc, attachment) => {
      const parsedLinkData = parseAttachmentLink(attachment.title_link!)
      if (!parsedLinkData) return acc

      if (
        parsedLinkData.attachmentType === MessageAttachmentType.HOLDING &&
        !isFounderGroup
      ) {
        return parseHoldingAttachment(acc, parsedLinkData, attachment)
      }

      if (
        parsedLinkData.attachmentType === MessageAttachmentType.PORTFOLIO &&
        !isFounderGroup
      ) {
        return parsePortfolioAttachment(acc, parsedLinkData, attachment)
      }

      return parseUpdateAttachment(acc, parsedLinkData, attachment)
    }, [])
  }, [message.attachments, isFounderGroup])

  const attachments = useMemo(
    () =>
      message.attachments?.filter(
        (attachment) => !isLinkToApp(attachment.title_link || '')
      ),
    [message.attachments]
  )

  return {
    isDeleted,
    isDeletedForYou,
    showMessageActions,
    showThreadInfo,
    showReactionsList,
    showEdited,
    showAttachments,
    parsedMessageLinks,
    attachments,
    isAThreadMessage: !!threadList,
  }
}
