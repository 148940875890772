import { useCallback } from 'react'
import { useMessageInputContext } from 'stream-chat-react'

export const useMessageInput = () => {
  const messageInput = useMessageInputContext()

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      messageInput.handleChange(event)
    },
    [messageInput]
  )

  return {
    onChange,
  }
}
