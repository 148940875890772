import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const InviteInvestorGroupUserFormContainer = styled.div`
  border: 1px solid ${color('veryLightGray')};
  border-radius: 8px;
  padding: 2.2rem 2.4rem;
  margin-bottom: 1.6rem;
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1.9rem;
  h1,
  svg {
    color: ${color('lightGray')};
    font-weight: 700;
    font-size: 1.4rem;
  }

  h1 {
    text-transform: uppercase;
    margin: 0;
  }
`

export const EmailList = styled.ul`
  padding: 0.8rem 0;
`
export const EmailItem = styled.li`
  display: inline-block;
  padding: 0 0.8rem 0.8rem 0;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
`
