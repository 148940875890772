import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  transition: min-width 0.2s;
`

export const Name = styled.div`
  margin-bottom: 2.4rem;
`

export const AddInputContainer = styled.div`
  @media (max-width: 1024px) {
    width: 100%;
  }

  div[class*='AddInputAutosuggest_inputWrapper'] {
    margin-bottom: 0rem;
  }
`

export const Label = styled.div`
  display: inline-block;
  color: ${color('darkGray')};
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0.8rem 0.8rem 0.8rem;
`

export const Companies = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 25rem;
  overflow-y: auto;
  margin-top: 1rem;

  @media ${maxSize.sm} {
    max-height: 3rem;
  }

  & > * {
    margin: 0 0.5rem 0.5rem 0;
  }
`

export const HoldingsDealsErrorMessage = styled.div`
  color: ${color('red')};
  font-size: 1.4rem;
  margin: 1.6rem 0 0 1.4rem;
`
