import { useCallback, useState, useContext } from 'react'
import { getIn } from 'formik'
import {
  ExistentHoldingContext,
  ExistentHoldingContextType,
} from 'components/PortfolioHoldings/CreatePortfolioHolding/CreatePortfolioHolding'
import { useField, useFormikContext } from 'components/Form/hooks'
import { AddHoldingForm, LogoImage, SuggestionErrors } from '../types'

export const useAddCompanyForm = () => {
  const {
    status: formikStatus,
    setStatus,
    setFieldValue,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<AddHoldingForm>()
  const status = formikStatus as SuggestionErrors
  const existentHoldingContext = useContext<ExistentHoldingContextType>(
    ExistentHoldingContext
  )
  const [file, setFile] = useState<Blob>()
  const [legalEntityNameField] = useField<string>('company.legalEntityName')
  const [nameField] = useField<string>('company.name')
  const [websiteField, websiteMeta] = useField<string>('company.website')
  const [pointOfContactField] = useField<string>('company.pointOfContact')
  const [logoField] = useField<LogoImage>('company.logo')
  const nameErrors = getIn(errors, 'company.name')

  const onSelectImage = useCallback((fileParam: Blob) => {
    setFile(fileParam)
  }, [])

  const onSaveImage = useCallback(() => {
    let url

    if (file) {
      url = window.URL.createObjectURL(file)
    }

    setFieldValue('company.logo', { file, url })
  }, [file, setFieldValue])

  const onDeleteImage = useCallback(() => {
    setFile(undefined)
    setFieldValue('company.logo', undefined)
  }, [setFieldValue])

  const onChangeWebsite = useCallback(
    (event) => {
      websiteField.onChange(event)

      if (status?.companyErrors?.website) {
        setStatus({
          ...status,
          companyErrors: {},
        })
      }
    },
    [websiteField, status, setStatus]
  )

  return {
    status,
    legalEntityNameField,
    nameField,
    websiteField,
    websiteMeta,
    pointOfContactField,
    logoField,
    handleBlur,
    existentHoldingContext,
    onSelectImage,
    onSaveImage,
    onDeleteImage,
    onChangeWebsite,
    nameErrors,
    touched,
  }
}
