import styled, { css } from 'styled-components'

import { ReactComponent as CWLogoSVG } from 'assets/images/logo-cw.svg'
import { ReactComponent as FormPreviewSVG } from 'assets/images/FormPreview.svg'

import { ReactComponent as CWMainBigLogoSVG } from 'assets/images/clockwork-logo.svg'

export const PreviewContainer = styled.div`
  box-shadow: 0px 0px 15px rgba(16, 21, 39, 0.1);
  background: #ffffff;
  border-radius: 8px;
  border: 0.1rem solid #e9e9e9;
  width: 35.5rem;
  height: 22.2rem;
  display: flex;
  overflow: hidden;
`
const PreviewCol = styled.div`
  height: 100%;
  overflow: hidden;
`
export const PreviewLeft = styled(PreviewCol)`
  width: 18.7rem;
  position: relative;

  ${(props) =>
    props.withCustomImage &&
    css`
      padding: 1.2rem 2.4rem;
      background: #101527;
    `}
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: background-color 0.2s;
  background-color: ${(props) => (props.color ? props.color : '#000000cc')};
`

export const PreviewRight = styled(PreviewCol)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.84rem 0;
`

export const CWLogo = styled(CWLogoSVG)`
  width: 6rem;
  margin-top: 1rem;
`

export const LogoImage = styled.img`
  margin-top: 1rem;
  max-height: 1rem;
  max-width: 9rem;
  width: auto;
`
export const CWMainBigLogo = styled(CWMainBigLogoSVG)`
  height: 100%;
  width: auto;
  left: 0;
  position: absolute;
  opacity: 0.4;
  left: -5rem;
`

export const BigLogoImage = styled.div`
  position: absolute;
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

export const FormPreviewContainer = styled.div`
  margin-top: 1rem;
`

export const FormPreview = styled(FormPreviewSVG)`
  width: 7.9rem;
  height: auto;
  ${(props) => css`
    rect[id='customizable'] {
      transition: fill 0.2s, stroke 0.2s;
      fill: ${props.color};
    }
    path[id='customizable'] {
      transition: fill 0.2s, stroke 0.2s;
      stroke: ${props.color};
    }
  `}
`
