/* eslint-disable react/prop-types */
import Tooltip from 'components/Tooltip'
import React from 'react'
import { useRandomId } from 'utils/hooks/useRandomId'
import { ReactChildren } from 'utils/types/common'
import { Cell, Container, Data, Title, Value } from './Grid.styles'

interface GridCellProps {
  label: string | React.ReactNode
  value: string | number | React.ReactNode
  tooltip?: string
  columnsQuantity?: number
  padding?: string
}

interface GridProps {
  columnsQuantity: number
  children?: ReactChildren
  gridId?: string
}

interface GridComposition {
  Cell: React.FC<GridCellProps>
}

const Grid: React.FC<GridProps> & GridComposition = ({
  children,
  columnsQuantity,
  gridId,
}) => {
  const countChildren = React.Children.toArray(children).filter(Boolean).length

  const dummyGridColumnsToFillRow =
    countChildren === 1 || countChildren % columnsQuantity === 0
      ? 0
      : columnsQuantity - (countChildren % columnsQuantity)

  // We will leave it commented for now, just in case we need it in the future
  // This is used to render the last row of the grid with the different number of columns
  // const elementsInLastRow = countChildren % columnsQuantity || columnsQuantity
  // const lastRowElements = React.Children.toArray(children)
  //   .filter(Boolean)
  //   .slice(-elementsInLastRow)

  return (
    <Container>
      <Data id={gridId} columns={columnsQuantity}>
        {countChildren > 0 &&
          React.Children.map(
            children,
            (child: React.ReactElement<GridCellProps>) => {
              // We will leave it commented for now, just in case we need it in the future
              // const isLastRow = index >= countChildren - elementsInLastRow
              // if (isLastRow) return null
              return (
                child &&
                React.cloneElement(child, {
                  columnsQuantity,
                })
              )
            }
          )}
        {dummyGridColumnsToFillRow >= 0 &&
          [...new Array(dummyGridColumnsToFillRow)].map(() => (
            <Cell columns={columnsQuantity!} />
          ))}
      </Data>
      {/* We will leave it commented for now, just in case we need it in the future */}
      {/* <Data id={`${gridId}last_column`} columns={lastRowElements.length}>
        {lastRowElements}
      </Data> */}
    </Container>
  )
}

const GridCell: React.FC<GridCellProps> = ({
  label,
  value,
  tooltip,
  columnsQuantity,
  padding,
}) => {
  const id = useRandomId()
  return (
    <Cell columns={columnsQuantity!} padding={padding}>
      {tooltip ? (
        <Tooltip
          id={`porfolio-table-tooltip_${id}`}
          text={tooltip}
          place="top"
          delayShow={500}
        >
          <Title>{label}</Title>
        </Tooltip>
      ) : (
        <Title>{label}</Title>
      )}

      <Value className="fs-exclude">{value}</Value>
    </Cell>
  )
}

Grid.Cell = GridCell

export default Grid
