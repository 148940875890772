import { getCurrentGroupData, isActingAsFounder } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { Group } from 'utils/types/user'
import { useCompanyQuery } from 'utils/hooks/queries/useCompanyQuery'
import { usePublicFundProfileQuery } from 'utils/hooks/queries/usePortfolioQuery'
import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import useParseCurrentUrl from '../useParseCurrentUrl'

const useEntityInformation = () => {
  const groupData = useAppSelector(getCurrentGroupData)
  const isFounder = useAppSelector(isActingAsFounder)

  const { companyId, fundId } = useParseCurrentUrl()

  const founderCompanyId = (groupData as Group)?.companyData?.[0]?.id
  const companyIdToUse = isFounder ? founderCompanyId : companyId
  const entityId = companyIdToUse

  const { data: companyInfo } = useCompanyQuery(entityId, {
    enabled: !!entityId,
  })

  const { publicFundProfile } = usePublicFundProfileQuery(fundId, {
    enabled: !!fundId,
  })

  const companyLogo = companyInfo?.primaryLogo?.url ?? companyInfo?.logoUrl
  const fundLogo = publicFundProfile?.fundCompany?.primaryLogo.url

  const logoToShow = fundLogo ?? companyLogo
  const entityToShow = publicFundProfile ?? companyInfo

  const isShowingCompany = !!companyInfo

  const getAvatar = () => {
    if (!logoToShow) return null

    if (logoToShow.indexOf('missing.png') >= 0) {
      return (
        <Avatar
          initials={getInitials(entityToShow?.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }
    return <Avatar image={logoToShow} avatarStyle="avatarCircleXS" />
  }

  return {
    companyLogo,
    fundLogo,
    companyInfo,
    companyId,
    publicFundProfile,
    isShowingCompany,
    entityToShow,
    getAvatar,
  }
}

export default useEntityInformation
