import styled from 'styled-components'

export const FinancialInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
`

export const Title = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.03em;
  min-width: max-content;
`
