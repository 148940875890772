import { Section } from 'containers/InvestorManagement/components'
import React from 'react'
import { FinancialInformation as IFinancialInformation } from 'utils/types/investors'
import FinancialInfoItem from './FinancialInfoItem'
import * as Styles from './FinancialInformation.styles'

interface FinancialInformationProps {
  financialInformation?: IFinancialInformation
  isDetailsScreen?: boolean
}

const FinancialInformation: React.FC<FinancialInformationProps> = ({
  financialInformation,
  isDetailsScreen,
}) => {
  return (
    <Section
      title="investorManagement.list.extended.financialInformation"
      width={isDetailsScreen ? '100%' : undefined}
      sectionClassName="financial-information"
    >
      <Styles.FinancialInfoBody
        isDetailsScreen={isDetailsScreen}
        className="financial-information-body"
      >
        <FinancialInfoItem
          isReadOnly
          titleId="investorManagement.list.extended.taxId"
          value={financialInformation?.taxId}
        />
        <FinancialInfoItem
          titleId="investorManagement.list.extended.bankName"
          value={financialInformation?.bankName}
        />
        <FinancialInfoItem
          isReadOnly
          titleId="investorManagement.list.extended.bankAccount"
          value={financialInformation?.bankAccount}
        />
        <FinancialInfoItem
          isReadOnly
          titleId="investorManagement.list.extended.routingNumber"
          value={financialInformation?.routingNumber}
        />
      </Styles.FinancialInfoBody>
    </Section>
  )
}

export default FinancialInformation
