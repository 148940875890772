import Chip from 'components/Chip'
import styled from 'styled-components'
import Button from 'ui/Button'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { DataVisualizationColor } from 'utils/theme'

export const Container = styled.div`
  display: flex;
  gap: 1.8rem;

  @media ${maxSize.md} {
    border: none;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
`

export const LineSeparator = styled.div`
  position: relative;
  margin: 1.6rem 0;
  width: calc(100% + 2.4rem + 2.4rem);
  height: 0.1rem;
  left: -2.4rem;
  background-color: ${color('veryLightGray')};

  @media ${maxSize.xss} {
    width: calc(100% + 1.6rem + 1.6rem);
    left: -1.6rem;
  }
`

export const List = styled.div`
  column-gap: 1.2rem;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: 0.4rem;

  @media ${maxSize.sm} {
    gap: 0.4rem;
  }
`

export const CustomChip = styled(Chip)`
  max-width: 100%;

  @media ${size.md} {
    max-width: 55rem;
  }

  @media ${size.tabletLg} {
    max-width: 70rem;
  }

  @media ${size.xl} {
    max-width: 100%;
  }
`

export const Clear = styled(Button)`
  align-self: center;
  height: 3.7rem;
  width: 6.5rem;

  @media ${maxSize.md} {
    width: 100%;
  }
`

export const MetricAvatar = styled.div<{
  isVisible: boolean
  color: DataVisualizationColor
}>`
  align-items: center;
  background-color: ${({ color: visualizationColor, isVisible }) =>
    isVisible
      ? color(visualizationColor, 1, 'dataVisualizationColors') ??
        color('white')
      : color('white')};
  border-radius: 50%;
  color: white;
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  height: 2.1rem;
  width: 2.1rem;
  min-height: 2.1rem;
  min-width: 2.1rem;

  svg {
    font-size: 1.4rem;
  }
`
