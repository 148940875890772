import React from 'react'
import { Extension } from '@tiptap/react'
import styled from 'styled-components/macro'
import RichTextArea from 'components/RichTextAreaV2'

interface UpdateTextProps {
  text?: string
  maxLines?: number
  openLinksOnClick?: boolean
  extensions?: Extension[]
  onCreate?: (element: Element) => void
}

export const UpdateTextContainer = styled.div<{ truncate?: boolean }>`
  margin-top: 1.8rem;
`

const UpdateText: React.FC<UpdateTextProps> = ({
  text,
  maxLines,
  openLinksOnClick = true,
  extensions,
  onCreate,
}) => {
  return (
    <UpdateTextContainer className="fs-exclude">
      <RichTextArea
        readOnly
        readOnlyValue={text}
        maxLines={maxLines}
        openLinksOnClick={openLinksOnClick}
        extensions={extensions}
        onCreate={onCreate}
      />
    </UpdateTextContainer>
  )
}

export default UpdateText
