import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import TagsInput from 'components/TagsInput'
import Title from 'components/Title'
import { isValidTag } from 'utils/functions/tags'
import UpdateCardBox from '../UpdateCardBox'
import { useUpdateTags } from './useUpdateTags'

const UpdateTags = ({ updateId, initialTags }) => {
  const intl = useIntl()

  const { onAddTag, onRemoveTag, tagsList } = useUpdateTags(
    updateId,
    initialTags
  )

  return (
    <UpdateCardBox>
      <UpdateCardBox.Header>
        <Title title={intl.messages['tags.tagsTitle']} icon={['far', 'tag']} />
      </UpdateCardBox.Header>
      <UpdateCardBox.Body>
        <TagsInput
          tagsList={tagsList}
          onAddTag={onAddTag}
          onRemoveTag={onRemoveTag}
          validationWhenAddingTag={(tag) => isValidTag(tag, intl)}
          maxTagWidth="34rem"
        />
      </UpdateCardBox.Body>
    </UpdateCardBox>
  )
}

UpdateTags.propTypes = {
  initialTags: PropTypes.array.isRequired,
  updateId: PropTypes.string,
}

UpdateTags.defaultProps = {
  updateId: '',
}

export default UpdateTags
