/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import EmailService from 'api/EmailService'
import Toast from 'components/Toast'
import EmailPreviewLoader from 'components/loaders/emailPreviewLoader'

import styles from './EmailIframePreview.module.scss'

const EmailIframePreview = ({ customClass, emailUpdate }) => {
  const intl = useIntl()

  const [html, setHtml] = useState(null)
  const [loading, setLoading] = useState(true)

  const getEmailPreview = async () => {
    try {
      const { data } = await EmailService.getEmailPreview(emailUpdate.item.id)

      setHtml(data)
    } catch (err) {
      Toast.display(intl.messages['createEmail.getPreview'], 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getEmailPreview()
  }, [])

  return loading ? (
    <EmailPreviewLoader />
  ) : (
    <div
      className={classNames(customClass, styles.iframe)}
      title="viewer"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

EmailIframePreview.propTypes = {
  customClass: PropTypes.string,
  emailUpdate: PropTypes.shape({
    item: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

EmailIframePreview.defaultProps = {
  customClass: '',
}

export default EmailIframePreview
