import React from 'react'
import PropTypes from 'prop-types'

import styles from './UpdateLayout.module.scss'

const UpdateBody = ({ children }) => (
  <div className={styles.bodyContainer}>{children}</div>
)

UpdateBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default UpdateBody
