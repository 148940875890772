import React from 'react'
import { FormattedMessage } from 'react-intl'

import { InboundStatus } from 'utils/types/inbounds'
import theme from 'utils/theme'
import CircleTimer from 'components/CircleTimer/CircleTimer'
import { TEST_CONNECTION_TIMEOUT_SECONDS } from 'containers/Inbounds/hooks/useTestConnection'

import GeneratedInboundEmail from '../../GeneratedInboundEmail/GeneratedInboundEmail'
import {
  ConnectStepContainer,
  Description,
  ErrorMessage,
  StatusIndicator,
  StatusIndicatorContainer,
  StatusWithTimerContainer,
} from '../CreateInbound.styles'

interface Props {
  inboundEmail: string
  status: InboundStatus
  isTestingConnection?: boolean
}

const ConnectStep: React.FC<Props> = ({
  inboundEmail,
  status,
  isTestingConnection,
}) => {
  return (
    <ConnectStepContainer>
      <Description>
        <FormattedMessage id="inbounds.connectStepDescription" />
      </Description>
      <Description>
        <FormattedMessage id="inbounds.connectStepCopyDescription" />
      </Description>
      <GeneratedInboundEmail email={inboundEmail} />
      <Description>
        <FormattedMessage id="inbounds.connectStepAfterAdding" />
      </Description>

      <StatusIndicatorContainer>
        <FormattedMessage id="inbounds.settings.status" />
        <StatusWithTimerContainer>
          <StatusIndicator
            status={isTestingConnection ? InboundStatus.TESTING : status}
          />
          {isTestingConnection && (
            <CircleTimer
              time={TEST_CONNECTION_TIMEOUT_SECONDS}
              color={theme.colors.mainBlue}
            />
          )}
        </StatusWithTimerContainer>
        {status === InboundStatus.NOT_CONNECTED && !isTestingConnection && (
          <ErrorMessage>
            <FormattedMessage id="inbounds.checkStatusFailed" />
          </ErrorMessage>
        )}
      </StatusIndicatorContainer>
    </ConnectStepContainer>
  )
}

export default ConnectStep
