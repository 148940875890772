import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useEffectSkipFirstRender from 'utils/hooks/useEffectSkipFirstRender'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import InfiniteScrollRef from './InfiniteScrollRef'

const InfiniteScroll = ({
  enabled,
  fetchNextPage,
  callback,
  offset,
  pageSize = 20,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const {
    onScreen,
    infiniteScrollEnabled,
    setInfiniteScrollEnabled,
    ref: endOfPageRef,
  } = useInfiniteScroll()

  useEffect(() => {
    setInfiniteScrollEnabled(enabled)
  }, [enabled, setInfiniteScrollEnabled])

  useEffect(() => {
    if (onScreen && !fetchNextPage) {
      setCurrentPage((page) => {
        return page + 1
      })
    } else if (onScreen) {
      fetchNextPage()
    }
  }, [onScreen, fetchNextPage])

  useEffectSkipFirstRender(() => {
    callback?.({ page: currentPage, pageSize })
  }, [currentPage])

  return (
    <InfiniteScrollRef
      ref={endOfPageRef}
      enabled={infiniteScrollEnabled}
      offset={offset}
    />
  )
}

InfiniteScroll.propTypes = {
  enabled: PropTypes.bool.isRequired,
  callback: PropTypes.func,
  pageSize: PropTypes.number,
  offset: PropTypes.string,
  fetchNextPage: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

InfiniteScroll.defaultProps = {
  pageSize: 20,
  offset: '50rem',
  fetchNextPage: null,
}

export default InfiniteScroll
