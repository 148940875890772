import type { EventComponentProps } from 'stream-chat-react'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import {
  SystemMessage,
  isJoinedChannelSystemMessage,
  isLeftChannelSystemMessage,
} from 'containers/Chat/types'

import { FormattedMessage, useIntl } from 'react-intl'
import { useMessageTimestamp } from '../MessageTimestamp/useMessageTimestamp'
import {
  MessageContainer,
  MessageDate,
  MessageMembers,
} from './SystemMessage.styles'
import { useSystemMessage } from './useSystemMessage'

const CustomSystemMessage = ({ message }: EventComponentProps) => {
  const intl = useIntl()
  const { renderJoinedChatMessage, renderLeftChatMessage } = useSystemMessage()

  const parsedSystemMessage = useMemo<string>(() => {
    try {
      const parsed: SystemMessage = JSON.parse(message.text!)
      if (isJoinedChannelSystemMessage(parsed)) {
        return renderJoinedChatMessage(parsed)
      }

      if (isLeftChannelSystemMessage(parsed)) {
        return renderLeftChatMessage(parsed)
      }

      return message.text!
    } catch (err) {
      return message.text!
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, message.text])

  const { isCreationDateToday, isJustNow, format } = useMessageTimestamp(
    message.created_at!,
    true
  )

  return (
    <MessageContainer>
      <MessageMembers
        dangerouslySetInnerHTML={{ __html: parsedSystemMessage }}
      />
      <MessageDate>
        {isJustNow ? (
          <FormattedMessage id="chat.justNow" />
        ) : (
          <>
            {isCreationDateToday && <FormattedMessage id="chat.today" />}
            {dayjs(message.created_at).format(format)}
          </>
        )}
      </MessageDate>
    </MessageContainer>
  )
}

export default CustomSystemMessage
