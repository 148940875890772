import { GroupTypes } from 'utils/constants/groups'
import { MANAGER_KEY, MEMBER_KEY, OWNER_KEY } from 'utils/functions/settings'
import { Nullable } from 'utils/types/common'
import { getCurrentCompany } from 'selectors/company'
import {
  AppGroup,
  Group,
  GroupType,
  User,
  InvestorGroup,
  Role,
} from 'utils/types/user'
import { RootState } from 'store'
import { SignUpUser } from 'utils/types/signUp'

export const getUser = <T extends User = User & { currentGroupRole: Role }>({
  auth: { user },
}): T => user

export const getAuth = ({ auth }) => auth

export const getUserId = ({ auth: { user } }): string => user?.id

export const getPrimaryEmail = ({ auth: { user } }): string => user?.email

export const getUserGroups = ({ auth: { groups } }): Record<string, AppGroup> =>
  groups || {}

export const getCurrentGroupId = (state): string =>
  state?.auth?.userSession?.currentGroupId

export const getPreviousGroupId = (state): string =>
  state?.auth?.userSession?.previousGroupId

export const getCurrentGroupType = (state): GroupType | undefined => {
  const userGroups = getUserGroups(state)
  const currentGroupId = getCurrentGroupId(state)

  return userGroups?.[currentGroupId]?.type
}

export const getCurrentGroupName = (state): string | undefined => {
  const userGroups = getUserGroups(state)
  const currentGroupId = getCurrentGroupId(state)
  return userGroups?.[currentGroupId]?.name
}

export const isFounderGroup = (state): boolean => {
  const userGroups = getUserGroups(state)
  const currentGroupId = getCurrentGroupId(state)
  return userGroups?.[currentGroupId]?.type === GroupTypes.FOUNDER
}

export const getCurrentGroupCompaniesIds = (state): string[] => {
  const currentGroupId = getCurrentGroupId(state)
  const userGroup = getUserGroups(state)?.[currentGroupId] as Group | undefined
  const companyDatas = userGroup?.companyData
  return (
    companyDatas?.map((companyData) =>
      typeof companyData === 'string' ? companyData : companyData.id
    ) || []
  )
}

export const groupHasCompany = (state) => {
  return !!getCurrentGroupCompaniesIds(state)?.length
}

export const isActingAsFounder = (state): boolean => {
  return (
    getUserGroups(state)?.[state.auth?.userSession?.currentGroupId]?.type ===
    GroupTypes.FOUNDER
  )
}

export const isActingAsFundManager = (state): boolean => {
  const userGroups = getUserGroups(state)
  const currentGroupId = getCurrentGroupId(state)
  return userGroups?.[currentGroupId]?.type === GroupTypes.FUND_MANAGER
}

export const isActingAsInvestorGroup = (state): boolean => {
  return (
    getUserGroups(state)?.[state.auth?.userSession?.currentGroupId]?.type ===
    GroupTypes.INVESTOR_GROUP
  )
}

export const isActingAsClient = (state): boolean => {
  return getCurrentGroupType(state) === GroupTypes.CLIENT
}

export const getCompanyFundId = (state): Nullable<string> => {
  const currentGroup =
    getUserGroups(state)?.[state.auth?.userSession?.currentGroupId]

  if (!(currentGroup as Group)?.companyData) {
    return null
  }

  const companyFund = (currentGroup as Group)?.companyData?.find(
    (companyData) => companyData.fund
  )

  return companyFund?.id || null
}

export const getCompanyFund = (state) => {
  const currentGroup =
    getUserGroups(state)?.[state.auth?.userSession?.currentGroupId]
  if (!(currentGroup as Group)?.companyData) {
    return null
  }

  const companyFund = (currentGroup as Group)?.companyData.find(
    (companyData) => companyData.fund
  )
  return companyFund || null
}

export const getIsCompanyFund = (company) => (state) => {
  const companyFundId = getCompanyFundId(state)

  if (!companyFundId) return false
  if (!company) return false

  return companyFundId === company?.id
}

export const getIsCurrentCompanyFund = (state) => {
  const currentCompany = getCurrentCompany(state)
  return getIsCompanyFund(currentCompany)(state)
}

export const getCurrentGroupData = (state): Nullable<AppGroup> => {
  const currentGroupId = getCurrentGroupId(state)
  return getUserGroups(state)?.[currentGroupId] ?? null
}

export const showTermsOfUse = (state) => {
  return state?.auth.showTermsOfUse
}

export const getRedirectTermsOfUse = (state) => {
  return state?.auth.redirectTermsOfUseTo
}

export const getUserSignupInfo = (state) => {
  return state?.auth.userSignup
}

export const getCompaniesOwnedByGroup = (state) => {
  const currentGroupId = getCurrentGroupId(state)
  return state?.auth?.groups[currentGroupId]?.companyData || []
}

export const getCurrentGroupsPrivateCompanies = (state) => {
  const currentGroupId = getCurrentGroupId(state)
  const userGroup = getUserGroups(state)?.[currentGroupId] as Group | undefined

  return (
    userGroup?.companyData?.filter((companyData) => !companyData.fund) || []
  )
}

export const getUserHasGroupMemberRole = ({ auth }) =>
  auth?.user?.currentGroupRole === MEMBER_KEY

export const getUserIsOwnerOrManager = ({ auth }) =>
  auth?.user?.currentGroupRole === OWNER_KEY ||
  auth?.user?.currentGroupRole === MANAGER_KEY

export const getUserIsOwner = ({ auth }) =>
  auth?.user?.currentGroupRole === OWNER_KEY

export const getGoogleToken = ({ auth }) => auth?.googleToken

export const getUserConfirmationToken = ({ auth }) => auth?.confirmationToken

export const getGoogleAccountType = ({ auth }) => auth?.accountType

export const getIsReadOnlyMode = (state) => {
  const userGroups = getUserGroups(state)
  const currentGroupId = getCurrentGroupId(state)
  const userGroup = userGroups?.[currentGroupId] as Group | undefined
  return userGroup?.readOnly
}

export const getIsSigningUp = (state) => state.auth.isSigningUp

export const getCustomLoginData = (state) => state.auth.customLoginData

export const getParentGroup = (state): Nullable<AppGroup> => {
  const currentGroup = getCurrentGroupData(state)

  if (!currentGroup) {
    return null
  }

  if (currentGroup.type === GroupTypes.INVESTOR_GROUP) {
    return (currentGroup as InvestorGroup).parentGroup
  }

  if (currentGroup.type === GroupTypes.FUND_MANAGER) {
    return currentGroup
  }

  return null
}

export const getCanScheduleUpdates = (state) => {
  const currentGroupType = getCurrentGroupType(state)

  if (
    currentGroupType === GroupTypes.CLIENT ||
    currentGroupType === GroupTypes.INVESTOR_GROUP
  ) {
    // Clients and investors can't schedule updates
    return false
  }

  if (currentGroupType === GroupTypes.FUND_MANAGER) {
    // Fund managers can schedule updates only in their own fund company
    return getIsCurrentCompanyFund(state)
  }

  // Founders can always schedule updates
  return true
}

export const isBetaUser = (state): boolean => {
  const currentGroupId = getCurrentGroupId(state)
  return state.auth?.betaGroups?.[currentGroupId]
}

export const getUserTempData = (state: RootState) =>
  (state.auth?.user as SignUpUser)?.tempData
