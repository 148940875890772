import styled from 'styled-components'
import theme from 'utils/theme'

export const FileDetailsDrawerWrapper = styled.div`
  padding: 3.2rem;
`

export const FileDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 1.4rem;
  margin-bottom: 3.2rem;
  row-gap: 1.2rem;
  border-bottom: 0.1rem solid ${theme.colors.veryLightGray};
  padding-bottom: 1.6rem;
`

export const FileUpdateDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 30rem;
  padding: 1.8rem 1.6rem;
  border: 0.1rem solid ${theme.colors.veryLightGray};
  border-radius: 0.8rem;
`

export const FileUpdateDetailsContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  justify-content: center;
`

export const FileUpdateDetailsFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 1.2rem;
`
