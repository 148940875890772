/* eslint-disable react/require-default-props,  react/destructuring-assignment */
import { WindowScroller } from 'react-virtualized'
import React, { useRef } from 'react'

import CWLoader from 'components/CWLoader'
import { useTableLayout } from 'components/ExpandableTable/hooks/useTableLayout'
import { LoaderContainer } from 'components/ExpandableTable/Table/Table.styles'
import useArrayState from 'utils/hooks/useArrayState'
import { useRandomId } from 'utils/hooks/useRandomId'
import { useEventListener } from 'utils/hooks/useEventListener'

import { TableContextProvider } from './TableContextProvider'
import Header from './Header'
import { DataType, TableProps } from './types'
import List from './List'
import { FixedWrapper, TableContainer } from './Table.styles'
import ScrollableContainer from './Row/ScrollableContainer'
import FakeScrollBar from './FakeScrollBar'
import { useHoverIndex } from './useHoverIndex'

export const COLLAPSE_ALL_ROWS = 'COLLAPSE_ALL_ROWS'

function Table<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType = any,
  ParentRowData extends DataType = any
>({
  rowHeight = 60,
  maxHeight = 500,
  overflow = 'auto',
  id,
  withHeader = true,
  ...rest
}: TableProps<RowDataType, ExpandedRowDataType, ParentRowData>) {
  const tableContainerId = useRef(
    `table-container-${
      rest.embeddedTable ? 'embedded' : 'main'
    }-${useRandomId()}`
  )

  const tableLayoutHelpers = useTableLayout({
    scrollElementId: rest.scrollElementId,
    rowHeight,
  })

  const { addHoverIndex, removeHoverIndex, getIsIndexHover } = useHoverIndex()

  const [
    expandedRowIds,
    {
      push: addExpandedRow,
      findAndRemove: removeExpandedRow,
      set: setExpandedRows,
    },
  ] = useArrayState<string>([])

  const isRowExpanded = (rowId: string) => expandedRowIds.includes(rowId)

  const toggleRow = (rowId: string) => {
    if (isRowExpanded(rowId)) {
      removeExpandedRow((someId) => someId === rowId)
    } else {
      addExpandedRow(rowId)
    }
  }

  const fixedColumns = rest.columns.filter((col) => col.fixed)

  const totalFixedWidth = fixedColumns.reduce(
    (total, cell) => total + (cell.minWidth ?? 0),
    60
  )

  const hasScrollableColumns = rest.columns.some(
    (col) => !col.fixed && !col.hidden
  )
  const hasFixedColumns = rest.columns.some((col) => col.fixed && !col.hidden)

  useEventListener(COLLAPSE_ALL_ROWS, async (tableId) => {
    if (id === tableId) {
      setExpandedRows([])
    }
  })

  return (
    <TableContextProvider<RowDataType, ExpandedRowDataType>
      {...rest}
      rowHeight={rowHeight}
      maxHeight={maxHeight}
      tableLayoutHelpers={tableLayoutHelpers}
      getIsIndexHover={getIsIndexHover}
      addHoverIndex={addHoverIndex}
      removeHoverIndex={removeHoverIndex}
      expandedRowIds={expandedRowIds}
      toggleRow={toggleRow}
      isRowExpanded={isRowExpanded}
      fixedList={!hasFixedColumns}
      tableContainerId={tableContainerId.current}
    >
      {!rest.embeddedTable && rest.loading ? (
        <LoaderContainer>
          <CWLoader text={rest.loadingDataLabel} />
        </LoaderContainer>
      ) : (
        <>
          {!rest.embeddedTable && (
            <WindowScroller
              onScroll={({ scrollTop }) => {
                tableLayoutHelpers.listRef.current?.scrollTo?.(scrollTop)
                tableLayoutHelpers.fixedListRef.current?.scrollTo?.(scrollTop)
              }}
              scrollElement={tableLayoutHelpers.scrollElement || undefined}
            >
              {() => <div />}
            </WindowScroller>
          )}
          <TableContainer
            id={tableContainerId.current}
            embeddedTable={rest.embeddedTable}
            className={rest.className}
          >
            {fixedColumns.length > 0 && (
              <TableContextProvider<RowDataType, ExpandedRowDataType>
                {...rest}
                columns={fixedColumns.map((col) => ({
                  ...col,
                  fixed: false,
                }))}
                rowHeight={rowHeight}
                maxHeight={maxHeight}
                tableLayoutHelpers={tableLayoutHelpers}
                getIsIndexHover={getIsIndexHover}
                addHoverIndex={addHoverIndex}
                removeHoverIndex={removeHoverIndex}
                expandedRowIds={expandedRowIds}
                toggleRow={toggleRow}
                isRowExpanded={isRowExpanded}
                fixedList
                tableContainerId={tableContainerId.current}
              >
                <FixedWrapper
                  id="fixed-table-container"
                  ref={
                    tableLayoutHelpers.fixedListContainerRef as React.RefObject<HTMLDivElement>
                  }
                  width={totalFixedWidth}
                  noScrollableColumns={!hasScrollableColumns}
                >
                  {withHeader && <Header />}
                  <List ref={tableLayoutHelpers.fixedListRef} />
                </FixedWrapper>
              </TableContextProvider>
            )}

            {hasScrollableColumns && (
              <ScrollableContainer isVisible={!rest.withFloatingScroll}>
                {withHeader && <Header />}
                <List ref={tableLayoutHelpers.listRef} />
              </ScrollableContainer>
            )}
          </TableContainer>
        </>
      )}
      {!rest.embeddedTable && rest.withFloatingScroll && <FakeScrollBar />}
    </TableContextProvider>
  )
}

export default Table
