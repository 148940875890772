import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'
import { MAC_DESKTOP_HEADER_HEIGHT } from 'utils/constants/electron'

export const SIDE_BAR_WIDTH = '25.8rem'
export const SIDE_BAR_HEIGHT = '7.2rem'

export const SideBarWrapper = styled.div<{ isOpen: boolean }>`
  height: calc(100vh - ${SIDE_BAR_HEIGHT} - ${MAC_DESKTOP_HEADER_HEIGHT});
  display: flex;
  z-index: 3;
  top: calc(${SIDE_BAR_HEIGHT} + ${MAC_DESKTOP_HEADER_HEIGHT});

  @media ${maxSize.mobile} {
    position: fixed;
    transform: ${(props) =>
      props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s;
    width: 100%;
  }
`

export const SideBarTitle = styled.p<{ padding?: string }>`
  color: ${color('veryLightBlue')};
  padding-left: 2.4rem;
  text-transform: uppercase;
  height: 2.5rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  ${(props) =>
    props.padding
      ? css`
          padding: ${props.padding};
        `
      : css`
          @media ${maxSize.md} {
            padding: 0 3.2rem;
          }
        `}
`

export const SidebarContainer = styled.aside`
  width: ${SIDE_BAR_WIDTH};
  background-color: ${color('darkBlue')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;

  @media ${maxSize.md} {
    height: calc(var(--viewHeight, 100vh) - ${SIDE_BAR_HEIGHT});
  }

  @media ${maxSize.xsm} {
    width: 100%;
  }
`

export const HelpContainer = styled.div`
  border-top: 0.1rem solid ${color('softDarkBlue')};
`

export const SidebarItems = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  overflow-x: hidden;
`

export const SidebarWrapper = styled.div`
  z-index: 7;

  @media ${maxSize.md} {
    z-index: 8;
  }
`

export const Divider = styled.div`
  border-top: 0.1rem solid ${color('gray')};
`
