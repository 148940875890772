import {
  SkeletonAvatarWithAnimation,
  SkeletonHorizontalSeparator,
  SkeletonItem,
  SkeletonItemWrapper,
  SkeletonVerticalSeparator,
} from 'components/Skeletons/Skeletons.styles'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import theme from 'utils/theme'
import Card from 'components/Card'
import {
  SkeletonAllMetricsCardWrapper,
  Wrapper,
} from './SkeletonAllMetricsCard.styles'

const SkeletonMetricsItem = () => (
  <SkeletonItemWrapper direction="column" gap="0.4rem" alignItems="flex-start">
    <SkeletonItem width="8rem" height="1.4rem" />
    <SkeletonItem width="6rem" height="1.4rem" />
    <SkeletonItem width="4rem" height="1.4rem" />
  </SkeletonItemWrapper>
)

export const SkeletonAllMetricsItem = () => {
  const { matches: isMobileXsm } = useMediaQuery(maxSize.xsm)
  const { matches: isMobileXss } = useMediaQuery(maxSize.xss)

  return (
    <SkeletonAllMetricsCardWrapper>
      <SkeletonItemWrapper
        direction="column"
        gap="1rem"
        alignItems="flex-start"
      >
        <SkeletonItem width="12rem" height="1.4rem" />
      </SkeletonItemWrapper>

      <SkeletonItemWrapper justifyContent="space-between">
        <SkeletonItemWrapper height="6rem" gap="2rem">
          <SkeletonMetricsItem />
          {!isMobileXss && (
            <>
              <SkeletonVerticalSeparator />
              <SkeletonMetricsItem />
            </>
          )}
          {!isMobileXsm && (
            <>
              <SkeletonVerticalSeparator />
              <SkeletonMetricsItem />
            </>
          )}
        </SkeletonItemWrapper>

        <SkeletonItemWrapper gap="1.5rem" marginLeft="4rem">
          <SkeletonAvatarWithAnimation height="2.4rem" width="2.4rem" />
          <SkeletonAvatarWithAnimation height="2.4rem" width="2.4rem" />
        </SkeletonItemWrapper>
      </SkeletonItemWrapper>
    </SkeletonAllMetricsCardWrapper>
  )
}

export const SkeletonAllMetricsCardContent = () => {
  return (
    <Wrapper>
      <SkeletonAllMetricsItem />
      <SkeletonHorizontalSeparator />
      <SkeletonAllMetricsItem />
      <SkeletonHorizontalSeparator />
      <SkeletonAllMetricsItem />
    </Wrapper>
  )
}

export const SkeletonAllMetricsCard = () => {
  return (
    <Card isExpandable padding="1.4rem 0" arrowColor={theme.colors.lightGray}>
      <Card.Header padding="1.4rem 2.4rem">
        <SkeletonItemWrapper>
          <SkeletonAvatarWithAnimation />
          <SkeletonItem width="12rem" height="1.8rem" />
        </SkeletonItemWrapper>
      </Card.Header>
      <Card.Body>
        <SkeletonAllMetricsCardContent />
      </Card.Body>
    </Card>
  )
}
