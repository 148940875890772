import {
  useState,
  useRef,
  useLayoutEffect,
  startTransition,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import { measureText } from 'utils/functions/strings'
import { useWindowResize } from 'utils/hooks/windowResize'

interface Props {
  description?: string
  rowsCount?: number
  precisionError?: number
}

export const useShowMoreLess = ({
  description,
  rowsCount = 2,
  precisionError = 16,
}: Props) => {
  const [isShowingMore, setIsShowingMore] = useState(false)
  const [width, setWidth] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)
  const result = useMemo(() => {
    if (description) {
      const descriptionWidth = measureText(description).width
      const availableSpace = width * rowsCount
      const charWidth = descriptionWidth / description.length
      const dotLength = 3
      const showMoreLength = 8
      const length =
        Math.ceil(availableSpace / charWidth) -
        showMoreLength -
        dotLength -
        precisionError

      const visibleText = `${description.substring(0, length)}...`
      const visibleTextSpacesCount = (visibleText.match(/\n/g) || []).length

      if (
        descriptionWidth > availableSpace ||
        visibleTextSpacesCount > rowsCount
      ) {
        return {
          description: visibleText,
          isShowMoreNeeded: true,
        }
      }
    }

    return { description, isShowMoreNeeded: false }
  }, [description, precisionError, rowsCount, width])

  const toggleShowingMore = useCallback(
    () => setIsShowingMore((currentShowMore) => !currentShowMore),
    []
  )

  const calculateContainerWidth = useCallback(() => {
    if (containerRef.current) {
      const computedStyle = window.getComputedStyle(containerRef.current)
      let currentWidth = containerRef.current.clientWidth
      currentWidth -=
        parseFloat(computedStyle.paddingLeft) +
        parseFloat(computedStyle.paddingRight)
      setWidth(currentWidth)
    } else {
      setWidth(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current?.clientWidth])

  useLayoutEffect(() => {
    startTransition(() => {
      calculateContainerWidth()
    })
  }, [calculateContainerWidth])

  useWindowResize(() => {
    startTransition(() => {
      calculateContainerWidth()
    })
  })

  useEffect(() => {
    startTransition(() => {
      calculateContainerWidth()
    })
  }, [calculateContainerWidth])

  return {
    containerRef,
    shortDescription: result.description,
    isShowMoreNeeded: result.isShowMoreNeeded,
    isShowingMore,
    toggleShowingMore,
    calculateContainerWidth,
  }
}
