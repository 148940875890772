import styled from 'styled-components/macro'
import Button from 'ui/Button'
import { color } from 'utils/functions/colors'

export const AddFundButton = styled(Button)`
  margin-bottom: 1.2rem;

  svg {
    font-size: 1.6rem;
  }
`

export const FundManagerInfoContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${color('veryLightGray')};
  margin-top: 1.6rem;
  padding: 1.6rem;
`

export const LogoTitle = styled.p`
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: ${color('darkGray')};
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  padding-left: 1.4rem;
`

export const FundManagerInfo = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 100%;
  max-width: 43.2rem;
`

export const SuggestionErrorContainer = styled.div`
  padding-top: 0.8rem;
  padding-left: 0.3rem;
`

export const DefaultError = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
`
