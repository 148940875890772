/* eslint-disable react-hooks/exhaustive-deps */
import ReactDOM from 'react-dom'
import { isEmpty } from 'lodash'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import debounce from 'lodash/debounce'

import InputLabel from 'ui/InputLabel'
import InputError from 'ui/InputError'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import InputIcon from 'ui/InputIcon'
import CWLoader from 'components/CWLoader'
import {
  ErrorIcon,
  StyledInput,
  InputIcon as StyledInputIcon,
} from 'ui/Input/Input.styles'
import {
  isDownCode,
  isEnterKeyCode,
  isEscCode,
  isUpCode,
} from 'utils/functions/keyboardEvents'
import {
  getFirstSubOption,
  getNextSubOption,
  getPreviousSubOption,
  getSelectedOptionGroupedOptionIndexes,
  scrollDropdownOptionIntoView,
} from 'utils/functions/dropdown'

import Avatar from 'components/Avatar'

import theme from 'utils/theme'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { randomId } from 'utils/functions/number'
import { mapToAndIgnoreNulls, removeNulls } from 'utils/functions/array'
import { getInitials } from 'utils/functions/user'
import { useEventListener } from 'utils/hooks/useEventListener'
import { coordinatesInsideRect } from 'utils/functions/utils'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'

import {
  NoStyledButton,
  DropdownOptionButton,
  NoStyleButton,
  ButtonLabel,
  CustomDiv,
  PortfolioName,
  PortfolioType,
} from './Dropdown.tsstyles'

import {
  InputContainer,
  InputWrapper,
  DropdownContent,
  DropdownOptionsList,
  DropdownOptionItem,
  DropdownOptionsTitle,
  SearchInputContainer,
  AddOptionButton,
  AddIcon,
  AddOptionLabel,
  DropdownOptionIcon,
  DropdownOptionImage,
  OptionRow,
  OptionLegend,
  DropdownButton,
  StyledIconsContainer,
  OptionsTitle,
  NoResults,
  DropdownContentListWrapper,
  AddOptionWrapper,
  AvatarWrapper,
  CounterLabel,
} from './Dropdown.styles'
import { getNumber, getPortfolioName } from './helpers'

const Dropdown = React.forwardRef(
  (
    {
      id,
      type,
      label,
      isOptional,
      optionalLabel,
      error,
      large,
      options,
      getOption,
      onSelectOption,
      value,
      loadOptions,
      async,
      onChange,
      withGroupOptions,
      maxListHeight,
      searchPlaceholder,
      onAddOption,
      addOptionEnabled,
      showIcon,
      icon,
      iconComponent,
      onPressEnter,
      clearOnSelect,
      clearOnPressEnter,
      hideCursor,
      confirmOnClickIcon,
      disabled,
      formatSelectedOption,
      iconFontSize,
      minWidth,
      padding,
      alignDropdownContent,
      growOptionItems,
      minContentWidth,
      hideGroupLabelIfEmpty,
      resetSearchOnFocus,
      capitalizeOptions,
      optionsTitle,
      formatSelectedOptionComponent,
      formatSelectedTextOption,
      background,
      backgroundFocus,
      highlightEnabled,
      showLoadingIndicator,
      dropdownOptionPadding,
      refetchOptionsOnDropdownOpen,
      highlightSelectedOption,
      arrowNavigationEnabled,
      skipInitialFetch,
      showNoResults,
      optionsListPadding,
      top,
      dropdownContentWidth,
      optionTitlePadding,
      preventAutocomplete,
      borderOnFocus,
      showAngleIcon,
      iconOnLeft,
      dropdownListWidth,
      dropdownListMargin,
      attachToDocument,
      transparent,
      onClose,
      customButton,
      alignLeft,
      topOffset,
      maxLength,
      optionWithDescription,
      simulateIconSpaceForSearchInput,
      withCounter,
      counterStyle,
      errorIconCustomPaddingRight,
      backgroundColorForSelected,
      onFocus,
      ...props
    },
    ref
  ) => {
    const intl = useIntl()
    const dropdownRef = useRef(null)
    const buttonRef = useRef(null)
    const dropdownContentRef = useRef(null)
    const dropdownContentWrapperRef = useRef(null)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const [selectedOption, setSelectedOption] = useState(value)
    const [currentOption, setCurrentOption] = useState(value)
    const [search, setSearch] = useState('')
    const [inputValue, setInputValue] = useState(type === 'input' ? value : '')
    const [dropdownOptions, setDropdownOptions] = useState(
      options.map(getOption)
    )
    const [loadingOptions, setLoadingOptions] = useState(false)
    const skipInitialFetchRef = useRef(skipInitialFetch)
    const preventAutocompleteRef = useRef(preventAutocomplete)
    const [randomKey, setRandomKey] = useState(
      preventAutocomplete ? randomId() : undefined
    )
    // Throw exceptions based on the props that are passed
    if (attachToDocument && dropdownContentWidth && !dropdownListWidth) {
      throw new Error(
        'Cannot use dropdownContentWidth and attachToDocument together, try using dropdownListWidth instead'
      )
    }

    useHighlightText(
      {
        elementId: 'dropdown-content',
        text: search,
        enabled: highlightEnabled,
      },
      [dropdownOptions, loadingOptions]
    )

    const [rawOptions, setRawOptions] = useState(options)

    const handleOnChange = useCallback(
      async (searchValue) => {
        setLoadingOptions(true)
        const asyncOptions = await loadOptions(searchValue)

        if (Array.isArray(asyncOptions)) {
          let newOptions
          if (withGroupOptions) {
            newOptions = asyncOptions.map((opt) => ({
              ...opt,
              subOptions: opt.subOptions.map(getOption),
            }))
          } else {
            newOptions = asyncOptions.map(getOption)
          }

          newOptions = removeNulls(newOptions)

          setRawOptions(asyncOptions)
          setDropdownOptions(newOptions)
        }
        setLoadingOptions(false)
      },
      [loadOptions]
    )

    useEffect(() => {
      if (refetchOptionsOnDropdownOpen && isDropdownOpen && async) {
        handleOnChange(search)
      }
    }, [isDropdownOpen])

    const debouncedHandleOnChange = useMemo(
      () => debounce(handleOnChange, 300),
      [handleOnChange]
    )

    const toggleDropdown = useCallback(() => {
      if (resetSearchOnFocus) {
        handleOnChange('')
      }

      setIsDropdownOpen((currState) => !currState)
    }, [])

    const onInputFocus = useCallback(() => {
      onFocus?.()
      toggleDropdown()
    }, [onFocus, toggleDropdown])

    useImperativeHandle(
      ref,
      () => ({
        clear: () => {
          setInputValue('')
          setSearch('')
        },
        close: () => {
          // this is a hack to fix the issue with the dropdown not closing when clicking on some elements from the dropdown list
          setTimeout(() => {
            setIsDropdownOpen(false)
          }, 0)
          onClose()
        },
        setValue: (text) => {
          setInputValue(text)
        },
        focus: () => {
          buttonRef.current.focus()
        },
        setLoadingOptions: (isLoading) => {
          setLoadingOptions(isLoading)
        },
      }),
      []
    )
    useEffect(() => {
      if (options) {
        setRawOptions(options)
        setDropdownOptions(mapToAndIgnoreNulls(options, getOption))
      }
    }, [options])

    useEffect(() => {
      if (typeof ref === 'function' && props.name) {
        ref({ name: props.name })
      }
    }, [props.name, ref])

    useOutsideClick(dropdownRef, (event) => {
      const clickedOutsideDropdownContent =
        !!dropdownContentWrapperRef.current &&
        !coordinatesInsideRect(
          event.clientX,
          event.clientY,
          dropdownContentWrapperRef.current.getBoundingClientRect()
        )

      if (!attachToDocument || clickedOutsideDropdownContent) {
        setIsDropdownOpen(false)
        onClose()

        if (!highlightSelectedOption) {
          setSelectedOption(null)
        }
      }
    })

    const getRawOption = (dropdownOption) => {
      let selectedRawOption
      if (withGroupOptions) {
        rawOptions.forEach((rawOption) => {
          rawOption.subOptions.forEach((rawSubOption) => {
            if (dropdownOption.id === getOption(rawSubOption)?.id) {
              selectedRawOption = rawSubOption
            }
          })
        })
      } else {
        selectedRawOption = rawOptions.find(
          (rawOption) => getOption(rawOption)?.id === dropdownOption.id
        )
      }
      return selectedRawOption
    }

    const onSelectDropdownOption = useCallback(
      (dropdownOption) => {
        if (highlightSelectedOption) {
          setSelectedOption(dropdownOption)
        }
        const selectedRawOption = getRawOption(dropdownOption)
        setCurrentOption(selectedRawOption)
        setSearch('')

        if (async) {
          handleOnChange('')
        }

        setSearch('')
        onSelectOption(props.name, dropdownOption.id, selectedRawOption)
      },
      [rawOptions, onSelectOption, props.name, getOption]
    )

    const renderIcon = (dropdownOption) => {
      if (!dropdownOption.icon) return null

      if (dropdownOption.showAvatar) {
        return (
          <AvatarWrapper>
            <Avatar
              avatarStyle="avatarCircleXS"
              image={dropdownOption.icon}
              initials={getInitials(dropdownOption.label)}
            />
          </AvatarWrapper>
        )
      }

      if (Array.isArray(dropdownOption.icon)) {
        return (
          <DropdownOptionIcon
            fixedWidth
            width="1.5rem"
            icon={dropdownOption.icon}
          />
        )
      }

      if (typeof dropdownOption.icon === 'string') {
        return <DropdownOptionImage src={dropdownOption.icon} />
      }

      return dropdownOption.icon
    }

    const highlightOption = (newSelectedOption) => {
      if (highlightSelectedOption) {
        setSelectedOption(newSelectedOption)
      }
    }

    useEffect(() => {
      if (type === 'select') {
        if (typeof value === 'object' && value !== null) {
          const option = getOption(value)
          setCurrentOption(option)
          setInputValue(option.label)
          highlightOption(option)
        } else if (value !== undefined && dropdownOptions.length) {
          const newSelected = dropdownOptions.find(
            (option) => option.id === value
          )
          setCurrentOption(newSelected)
          setInputValue(newSelected?.label ?? '')
          highlightOption(newSelected)
        }
      } else if (value !== null) {
        if (typeof value === 'object') {
          const opt = getOption(value)
          setInputValue(opt.label)
        } else {
          setInputValue(value)
        }
      }
    }, [dropdownOptions, value, getOption, type])

    useEffect(() => {
      if (async && !skipInitialFetchRef.current) {
        skipInitialFetchRef.current = false
        debouncedHandleOnChange('')
      }
    }, [async, debouncedHandleOnChange])

    useEventListener('wheel', (_, event) => {
      const scrolledOutsideDropdownContent =
        !!dropdownContentWrapperRef.current &&
        !coordinatesInsideRect(
          event.clientX,
          event.clientY,
          dropdownContentWrapperRef.current.getBoundingClientRect()
        )

      if (attachToDocument && scrolledOutsideDropdownContent) {
        setIsDropdownOpen(false)
        onClose()
      }
    })

    const renderOption = useCallback(
      (dropdownOption) => {
        if (dropdownOption.legend) {
          return (
            <OptionRow>
              <span>{dropdownOption.label}</span>
              <OptionLegend> {dropdownOption.legend}</OptionLegend>
            </OptionRow>
          )
        }

        if (formatSelectedOptionComponent) {
          return formatSelectedOptionComponent(
            dropdownOption,
            selectedOption?.id === dropdownOption?.id
          )
        }

        if (optionWithDescription) {
          return (
            <CustomDiv selected={selectedOption?.id === dropdownOption?.id}>
              <PortfolioName
                selected={selectedOption?.id === dropdownOption?.id}
              >
                {dropdownOption.label}
              </PortfolioName>
              <PortfolioType
                selected={selectedOption?.id === dropdownOption?.id}
              >
                {getPortfolioName(dropdownOption.type)}
              </PortfolioType>
            </CustomDiv>
          )
        }

        return dropdownOption.label
      },
      [selectedOption]
    )

    const handleSearch = useCallback(
      (event) => {
        const { value: searchValue } = event.target
        setSearch(searchValue)
        if (searchValue && !isDropdownOpen) {
          setIsDropdownOpen(true)
        }
        debouncedHandleOnChange(searchValue)
      },
      [debouncedHandleOnChange]
    )

    const clearSearch = useCallback(() => {
      if (search) {
        setSearch('')
        handleOnChange('')
      }
    }, [handleOnChange, search])

    const onClickAddOption = useCallback(() => {
      onAddOption(search)
      setInputValue('')
      setSearch('')
      setIsDropdownOpen(false)
      onClose()
    }, [onAddOption, search])

    const onChangeInputValue = useCallback(
      (event) => {
        if (!preventAutocompleteRef.current) {
          if (!isDropdownOpen) {
            setIsDropdownOpen(true)
          }
          if (async && type === 'input') {
            setLoadingOptions(true)
            handleSearch(event)
            setInputValue(event.target.value)
            onChange(event)
          } else if (type === 'select' && hideCursor) {
            event.preventDefault()
            event.stopPropagation()
          } else {
            setInputValue(event.target.value)
            onChange(event)
          }
        } else {
          preventAutocompleteRef.current = false
          setRandomKey(randomId())
        }
      },
      [async, handleSearch, hideCursor, onChange, type, isDropdownOpen]
    )

    const formatInputValue = (option) => {
      if (!clearOnSelect) {
        setInputValue(
          typeof formatSelectedOption === 'undefined'
            ? formatSelectedOptionComponent(option.label)
            : option.label
        )
      } else {
        setInputValue('')
      }
    }

    const handleOnConfirm = () => {
      if (onPressEnter) {
        onPressEnter(inputValue, rawOptions, selectedOption)

        setSelectedOption(null)
        if (resetSearchOnFocus) {
          handleOnChange('')
        }
        if (clearOnPressEnter) {
          setInputValue('')
          setSearch('')
          if (async) {
            handleOnChange('')
          }
          if (!resetSearchOnFocus) {
            setIsDropdownOpen(false)
            onClose()
          }
        }
      } else if (!isEmpty(selectedOption)) {
        const option = getOption(selectedOption)
        formatInputValue(option)
        onSelectOption(props.name, selectedOption.id, selectedOption)
        setCurrentOption(selectedOption)
        toggleDropdown()
        if (clearOnSelect) setSelectedOption(null)
      } else {
        setInputValue(search)
        setIsDropdownOpen(false)
        onClose()
        buttonRef.current.blur()
      }
    }

    const getNextSelectedOption = (event) => {
      if (withGroupOptions) {
        if (!selectedOption) {
          return getFirstSubOption(dropdownOptions)
        }
        const { groupIndex, optionIndex } =
          getSelectedOptionGroupedOptionIndexes(selectedOption, dropdownOptions)

        if (isDownCode(event)) {
          if (!isDropdownOpen) {
            setIsDropdownOpen(true)
            return highlightSelectedOption
              ? selectedOption
              : getFirstSubOption(dropdownOptions)
          }
          return getNextSubOption(groupIndex, optionIndex, dropdownOptions)
        }

        if (isUpCode(event)) {
          return getPreviousSubOption(groupIndex, optionIndex, dropdownOptions)
        }

        return null
      }

      const currentSelectedIndex = dropdownOptions.findIndex(
        (option) => option.id === selectedOption?.id
      )

      if (isDownCode(event)) {
        if (!isDropdownOpen) {
          setIsDropdownOpen(true)
          return highlightSelectedOption ? selectedOption : dropdownOptions?.[0]
        }
        if (currentSelectedIndex + 1 < dropdownOptions.length) {
          return dropdownOptions[currentSelectedIndex + 1]
        }
        return null
      }

      if (isUpCode(event)) {
        if (currentSelectedIndex > 0) {
          return dropdownOptions[currentSelectedIndex - 1]
        }
        return null
      }

      return null
    }

    const onKeyDown = useCallback(
      (event) => {
        if (isEscCode(event)) {
          event.preventDefault()
          event.stopPropagation()
          if (!highlightSelectedOption) {
            setSelectedOption(null)
          }
          setIsDropdownOpen(false)
          onClose()
        } else if (isEnterKeyCode(event)) {
          event.preventDefault()
          event.stopPropagation()
          handleOnConfirm()
        } else if (
          arrowNavigationEnabled &&
          (isDownCode(event) || isUpCode(event))
        ) {
          event.preventDefault()
          event.stopPropagation()
          const nextSelectedOption = getNextSelectedOption(event)

          if (!nextSelectedOption) return

          scrollDropdownOptionIntoView(nextSelectedOption, dropdownContentRef)
          setSelectedOption(getRawOption(nextSelectedOption))
        }
      },
      [
        handleOnConfirm,
        dropdownOptions,
        selectedOption,
        arrowNavigationEnabled,
        isDropdownOpen,
      ]
    )

    const displayArrow = () => {
      return (
        <StyledInputIcon
          data-testid="dropdown-icon"
          icon={
            icon ||
            (showAngleIcon
              ? ['fal', isDropdownOpen ? 'angle-up' : 'angle-down']
              : null)
          }
          fontSize={iconFontSize}
          disabled={disabled}
          onClick={(event) => {
            if (!disabled) {
              return confirmOnClickIcon
                ? handleOnConfirm(event, inputValue)
                : setIsDropdownOpen(true)
            }
            return null
          }}
        />
      )
    }

    const displayIcon = ({
      hasError,
      relativePositioning,
      shouldRenderArrow,
      shouldRenderIcon,
    }) => {
      return (
        (hasError || showIcon) && (
          <StyledIconsContainer
            className="dropdown-icons"
            disabled={disabled}
            mouseEventDisabled={!confirmOnClickIcon}
            relativePositioning={relativePositioning}
            large={large}
          >
            {hasError && (
              <ErrorIcon
                errorIconCustomPaddingRight={errorIconCustomPaddingRight}
                icon={['far', 'exclamation-triangle']}
              />
            )}
            {shouldRenderIcon && iconComponent}
            {shouldRenderArrow && displayArrow()}
          </StyledIconsContainer>
        )
      )
    }

    const renderDropdownOptions = () => {
      const hasOptions = withGroupOptions
        ? dropdownOptions.some((opt) => opt.subOptions?.length > 0)
        : dropdownOptions?.length > 0

      if (showLoadingIndicator && loadingOptions && !hasOptions) {
        return <CWLoader logoSize="4rem" />
      }

      if (!hasOptions && !loadingOptions) {
        if (!inputValue) return null
        return (
          <NoResults className="ignore-highlight">
            {intl.messages['general.noMatchesFound']}
          </NoResults>
        )
      }

      const handleSelectOption = (event, dropdownOption) => {
        formatInputValue(dropdownOption)

        onSelectDropdownOption(dropdownOption)
        if (type === 'input') {
          event.persist()
          onChange(event)
        }

        toggleDropdown()
      }

      const renderGroupLabel = (groupOption) => {
        if (hideGroupLabelIfEmpty && groupOption.subOptions?.length === 0) {
          return null
        }

        return (
          <DropdownOptionsTitle
            optionTitlePadding={optionTitlePadding}
            className="ignore-highlight"
          >
            {groupOption.group}
          </DropdownOptionsTitle>
        )
      }

      if (withGroupOptions) {
        return dropdownOptions?.map?.((groupOption) => {
          return (
            <Fragment key={groupOption.id}>
              {renderGroupLabel(groupOption)}

              {groupOption?.subOptions?.map((dropdownOption) => {
                return (
                  <DropdownOptionItem
                    id={`dropdownOption_${dropdownOption.id}`}
                    key={dropdownOption.id}
                    selected={selectedOption?.id === dropdownOption?.id}
                    growOptionItems={growOptionItems}
                  >
                    <DropdownOptionButton
                      id={dropdownOption.id}
                      data-testid={dropdownOption.id}
                      disabled={dropdownOption.disabled}
                      onClick={(event) =>
                        handleSelectOption(event, dropdownOption)
                      }
                      type="button"
                      role="button"
                      name={props.name}
                      value={dropdownOption.label}
                      capitalizeOptions={capitalizeOptions}
                      padding={dropdownOptionPadding}
                      aria-label={dropdownOption.label}
                      selected={selectedOption?.id === dropdownOption?.id}
                    >
                      {dropdownOption.icon
                        ? renderIcon(dropdownOption)
                        : renderIcon(groupOption)}
                      {renderOption(dropdownOption)}
                    </DropdownOptionButton>
                  </DropdownOptionItem>
                )
              })}
            </Fragment>
          )
        })
      }

      return (
        <>
          {!!optionsTitle && (
            <OptionsTitle className="ignore-highlight">
              {optionsTitle}
            </OptionsTitle>
          )}

          {dropdownOptions?.map?.((dropdownOption) => (
            <DropdownOptionItem
              key={dropdownOption.id}
              id={`dropdownOption_${dropdownOption.id}`}
              selected={selectedOption?.id === dropdownOption?.id}
              growOptionItems={growOptionItems}
            >
              <DropdownOptionButton
                key={dropdownOption.id}
                id={dropdownOption.id}
                data-testid={dropdownOption.id}
                type="button"
                disabled={dropdownOption.disabled}
                name={props.name}
                value={dropdownOption.label}
                onClick={(event) => handleSelectOption(event, dropdownOption)}
                withIcon={dropdownOption.icon}
                selected={selectedOption?.id === dropdownOption?.id}
                backgroundColorForSelected={backgroundColorForSelected}
                padding={dropdownOptionPadding}
                aria-label={dropdownOption.label}
              >
                {renderIcon(dropdownOption)}
                {renderOption(dropdownOption)}
              </DropdownOptionButton>
            </DropdownOptionItem>
          ))}
        </>
      )
    }

    const hasError = !!error.message

    let position = { left: 0, right: 0 }
    if (buttonRef.current) {
      position = buttonRef.current?.getBoundingClientRect()
    }

    const dropdownContainer = document.getElementById('dropdown-container')

    const renderDropdownContent = () => {
      const component = (
        <DropdownContent
          id="dropdown-content"
          ref={dropdownContentWrapperRef}
          data-testid="dropdown-content"
          large={large}
          label={label}
          alignDropdownContent={alignDropdownContent}
          minContentWidth={minContentWidth}
          top={top}
          dropdownContentWidth={dropdownContentWidth}
          attachToDocument={attachToDocument}
          position={position}
          alignLeft={alignLeft}
          topOffset={topOffset}
        >
          <DropdownContentListWrapper dropdownListWidth={dropdownListWidth}>
            {async && type === 'select' && (
              <SearchInputContainer>
                <InputIcon
                  forceShowIcon
                  icon={!search ? ['fal', 'search'] : ['far', 'times']}
                  placeholder={searchPlaceholder}
                  value={search}
                  handleChange={handleSearch}
                  tabIndex={0}
                  autoFocus
                  onKeyDown={onKeyDown}
                  onClickIcon={clearSearch}
                  maxLength={maxLength}
                  simulateIconSpace={simulateIconSpaceForSearchInput}
                />
              </SearchInputContainer>
            )}
            <DropdownOptionsList
              className="dropdown-list"
              key={dropdownOptions.length}
              maxListHeight={maxListHeight}
              ref={dropdownContentRef}
              margin={search && '0'}
              padding={search && '1rem 1rem 0rem 1rem'}
            >
              {renderDropdownOptions()}
            </DropdownOptionsList>
            {addOptionEnabled && search && (
              <AddOptionWrapper>
                <AddOptionButton
                  disabled={
                    withCounter
                      ? search.length > MAX_COUNTER_CHARACTERS
                      : undefined
                  }
                  type="button"
                  onClick={onClickAddOption}
                >
                  <AddIcon
                    color={theme.colors.primaryBlue}
                    icon={['fal', 'plus']}
                  />
                  <AddOptionLabel className="ignore-highlight">
                    {intl.formatMessage(
                      { id: 'general.addOption' },
                      { value: search }
                    )}
                  </AddOptionLabel>
                  {withCounter &&
                    getNumber(
                      search.length,
                      MAX_COUNTER_CHARACTERS,
                      counterStyle
                    )}
                </AddOptionButton>
              </AddOptionWrapper>
            )}
          </DropdownContentListWrapper>
        </DropdownContent>
      )

      if (isDropdownOpen) {
        if (attachToDocument) {
          return ReactDOM.createPortal(component, dropdownContainer)
        }
        return component
      }
      return null
    }

    const getInputContent = () => {
      if (customButton) {
        return (
          <NoStyledButton
            ref={buttonRef}
            type="button"
            onClick={toggleDropdown}
            onKeyDown={onKeyDown}
          >
            {customButton}
          </NoStyledButton>
        )
      }
      return formatSelectedOption ? (
        <DropdownButton
          ref={buttonRef}
          className="dropdown-button"
          type="button"
          aria-label="dropdown-button"
          onClick={toggleDropdown}
          minWidth={minWidth}
          padding={padding}
          background={background}
          backgroundFocus={backgroundFocus}
          onKeyDown={onKeyDown}
          borderOnFocus={borderOnFocus}
          showAngleIcon={showAngleIcon}
        >
          {iconOnLeft &&
            displayIcon({
              hasError: false,
              relativePositioning: true,
              shouldRenderArrow: false,
              shouldRenderIcon: showIcon,
            })}
          {formatSelectedOption(currentOption)}
          {displayIcon({
            hasError,
            relativePositioning: true,
            shouldRenderArrow: true,
            shouldRenderIcon: !iconOnLeft && showIcon,
          })}
        </DropdownButton>
      ) : (
        <>
          <StyledInput
            id={id}
            data-testid={`dropdown-input-${id}`}
            ref={buttonRef}
            key={randomKey}
            hasError={hasError}
            error={error}
            large={large}
            isFocused={isDropdownOpen}
            onFocus={onInputFocus}
            value={
              formatSelectedTextOption && currentOption
                ? formatSelectedTextOption(currentOption)
                : inputValue ?? ''
            }
            onChange={onChangeInputValue}
            onClick={() => setIsDropdownOpen(true)}
            icon={icon}
            onKeyDown={onKeyDown}
            disabled={disabled}
            aria-label="textbox"
            role="textbox"
            transparent={transparent}
            {...props}
          />
          {displayIcon({
            hasError,
            relativePositioning: false,
            shouldRenderArrow: !transparent,
            shouldRenderIcon: !iconOnLeft && showIcon,
          })}
          {type === 'text' &&
            withCounter &&
            getNumber(inputValue?.length, MAX_COUNTER_CHARACTERS, counterStyle)}
        </>
      )
    }

    return (
      <InputContainer ref={dropdownRef} disabled={disabled}>
        {label && (
          <InputLabel
            isOptional={isOptional}
            optionalLabel={optionalLabel}
            large={large}
          >
            {label}
          </InputLabel>
        )}

        <InputWrapper
          dropdownType={type}
          errorType={error.type || 'error'}
          hasError={hasError}
          large={large}
          hideCursor={hideCursor}
          disabled={disabled}
        >
          {getInputContent()}
        </InputWrapper>

        {hasError && (
          <InputError type={error.type || 'error'} large={large}>
            {error?.message}
          </InputError>
        )}

        {renderDropdownContent()}
      </InputContainer>
    )
  }
)

Dropdown.propTypes = {
  id: PropTypes.string,
  large: PropTypes.bool,
  withGroupOptions: PropTypes.bool,
  maxListHeight: PropTypes.string,
  isOptional: PropTypes.bool,
  optionalLabel: PropTypes.string,
  type: PropTypes.oneOf(['input', 'select']),
  error: PropTypes.shape({
    type: PropTypes.oneOf(['error', 'warning']),
    message: PropTypes.string,
  }),
  onChange: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  minWidth: PropTypes.string,
  padding: PropTypes.string,
  iconFontSize: PropTypes.string,
  options: PropTypes.array,
  addOptionEnabled: PropTypes.bool,
  onSelectOption: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  searchPlaceholder: PropTypes.string,
  alignDropdownContent: PropTypes.string,
  minContentWidth: PropTypes.string,
  name: PropTypes.string.isRequired,
  async: PropTypes.bool,
  loadOptions: PropTypes.func,
  getOption: PropTypes.func,
  onAddOption: PropTypes.func,
  showIcon: PropTypes.bool,
  icon: PropTypes.array,
  iconComponent: PropTypes.node,
  onPressEnter: PropTypes.func,
  formatSelectedOption: PropTypes.func,
  clearOnSelect: PropTypes.bool,
  clearOnPressEnter: PropTypes.bool,
  hideCursor: PropTypes.bool,
  confirmOnClickIcon: PropTypes.bool,
  growOptionItems: PropTypes.bool,
  disabled: PropTypes.bool,
  hideGroupLabelIfEmpty: PropTypes.bool,
  resetSearchOnFocus: PropTypes.bool,
  capitalizeOptions: PropTypes.bool,
  highlightEnabled: PropTypes.bool,
  refetchOptionsOnDropdownOpen: PropTypes.bool,
  optionsTitle: PropTypes.string,
  background: PropTypes.string,
  backgroundFocus: PropTypes.string,
  formatSelectedOptionComponent: PropTypes.func,
  showLoadingIndicator: PropTypes.bool,
  highlightSelectedOption: PropTypes.bool,
  arrowNavigationEnabled: PropTypes.bool,
  skipInitialFetch: PropTypes.bool,
  showNoResults: PropTypes.bool,
  dropdownOptionPadding: PropTypes.string,
  dropdownContentWidth: PropTypes.string,
  formatSelectedTextOption: PropTypes.func,
  onFocus: PropTypes.func,
  optionsListPadding: PropTypes.string,
  top: PropTypes.string,
  optionTitlePadding: PropTypes.string,
  preventAutocomplete: PropTypes.bool,
  attachToDocument: PropTypes.bool,
  showAngleIcon: PropTypes.bool,
  borderOnFocus: PropTypes.string,
  iconOnLeft: PropTypes.bool,
  dropdownListWidth: PropTypes.string,
  dropdownListMargin: PropTypes.string,
  transparent: PropTypes.bool,
  onClose: PropTypes.func,
  customButton: PropTypes.node,
  alignLeft: PropTypes.bool,
  topOffset: PropTypes.number,
  maxLength: PropTypes.number,
  optionWithDescription: PropTypes.bool,
  simulateIconSpaceForSearchInput: PropTypes.bool,
  withCounter: PropTypes.bool,
  counterStyle: PropTypes.object,
  backgroundColorForSelected: PropTypes.string,
  errorIconCustomPaddingRight: PropTypes.string,
}

Dropdown.defaultProps = {
  large: false,
  error: {},
  label: '',
  minWidth: '',
  padding: '',
  maxListHeight: '',
  iconFontSize: '',
  isOptional: false,
  withGroupOptions: false,
  optionalLabel: '(optional)',
  type: 'select',
  onChange: () => {},
  getOption: (option) => option,
  options: [],
  searchPlaceholder: '',
  placeholder: '',
  value: null,
  addOptionEnabled: false,
  async: false,
  loadOptions: () => [],
  onAddOption: () => {},
  showIcon: true,
  icon: null,
  iconComponent: null,
  formatSelectedOption: null,
  onPressEnter: null,
  hideGroupLabelIfEmpty: false,
  resetSearchOnFocus: false,
  clearOnSelect: false,
  clearOnPressEnter: false,
  hideCursor: true,
  growOptionItems: false,
  confirmOnClickIcon: false,
  refetchOptionsOnDropdownOpen: false,
  disabled: false,
  highlightEnabled: false,
  capitalizeOptions: true,
  alignDropdownContent: 'left',
  minContentWidth: 'left',
  optionsTitle: '',
  background: '',
  backgroundFocus: '',
  formatSelectedOptionComponent: undefined,
  showLoadingIndicator: false,
  dropdownOptionPadding: '',
  formatSelectedTextOption: null,
  highlightSelectedOption: true,
  arrowNavigationEnabled: true,
  skipInitialFetch: false,
  showNoResults: true,
  dropdownContentWidth: '',
  preventAutocomplete: false,
  showAngleIcon: true,
  attachToDocument: false,
  borderOnFocus: null,
  iconOnLeft: false,
  transparent: false,
  onClose: () => {},
  customButton: null,
  alignLeft: false,
  topOffset: 0,
  maxLength: undefined,
  optionWithDescription: false,
  simulateIconSpaceForSearchInput: false,
  withCounter: false,
  counterStyle: {},
}
export default Dropdown
