import React from 'react'
import { FormattedMessage } from 'react-intl'

const NoMatch = () => (
  <div>
    <FormattedMessage id="pageNotFound.legend" />
  </div>
)

export default NoMatch
