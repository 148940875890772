import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import FeedUpdate from 'components/FeedUpdate'
import IconLegend from 'components/IconLegend'
import { displayAttachments } from 'utils/functions/renderers/renderUpdatesHelper'
import { useVisibility } from 'utils/hooks/useVisibility'
import { FileContent } from 'utils/types/files'
import { IndexUpdate } from 'utils/types/update'
import Tooltip from 'components/Tooltip'
import UpdateText from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import {
  AttachmentsContainer,
  LinkButton,
  VisibilityContainer,
  ShowMoreLess,
} from '../UpdateFactory.styles'
import { useDefaultUpdateCard } from './useDefaultUpdateCard'
import Header from '../components/Header'
import ReshareIcons from '../components/ReshareIcons'

interface DefaultUpdateCardProps {
  update: IndexUpdate
  onPreviewFile: (file: FileContent) => void
  filesPreview: JSX.Element
  canEdit?: boolean
}

const DefaultUpdateCard: React.FC<DefaultUpdateCardProps> = ({
  update,
  onPreviewFile,
  filesPreview,
  canEdit,
}) => {
  const intl = useIntl()
  const { getUpdateVisibility } = useVisibility()
  const {
    onShow,
    onEdit,
    onReshare,
    isEditBlocked,
    userEditor,
    updateText,
    showMoreLess,
  } = useDefaultUpdateCard(update)

  return (
    <>
      <FeedUpdate onClick={onShow} isDraftUpdate={update.isDraftUpdate}>
        <>
          <Header update={update} />

          <FeedUpdate.Body>
            <div ref={showMoreLess.containerRef}>
              <UpdateText
                text={updateText}
                maxLines={showMoreLess.isShowingMore ? undefined : 2}
                openLinksOnClick={false}
                onCreate={showMoreLess.onEditorCreated}
              />
            </div>

            <ShowMoreLess
              isShowMoreNeeded={showMoreLess.isShowMoreNeeded}
              isShowingMore={showMoreLess.isShowingMore}
              toggleShowingMore={showMoreLess.toggleShowingMore}
            />

            <AttachmentsContainer>
              {displayAttachments(update.contents, 'small', onPreviewFile)}
            </AttachmentsContainer>
          </FeedUpdate.Body>

          <FeedUpdate.Footer>
            {!!update.contents.length && (
              <IconLegend
                icon={['fal', 'paperclip']}
                legend={update.contents.length?.toString()}
                title={intl.formatMessage({ id: 'updates.attachmentsNumber' })}
              />
            )}

            {!update.isDraftUpdate && (
              <VisibilityContainer>
                <IconLegend
                  icon={['fal', 'eye']}
                  legend={getUpdateVisibility(update)}
                  title={intl.formatMessage({ id: 'updates.visibility' })}
                />

                {update.confidential && (
                  <IconLegend
                    icon={['fal', 'user-shield']}
                    title={intl.formatMessage({ id: 'updates.confidential' })}
                    hasTooltip
                  />
                )}
              </VisibilityContainer>
            )}

            {canEdit && (
              <Tooltip
                text={
                  isEditBlocked
                    ? intl.formatMessage(
                        { id: 'updates.header.updateLocked' },
                        { userName: userEditor }
                      )
                    : ''
                }
                place="bottom"
              >
                <LinkButton onClick={onEdit} disabled={isEditBlocked}>
                  <FormattedMessage id="general.edit" />
                </LinkButton>
              </Tooltip>
            )}

            <ReshareIcons update={update} onClick={onReshare} />
          </FeedUpdate.Footer>
        </>
      </FeedUpdate>
      {filesPreview}
    </>
  )
}

export default DefaultUpdateCard
