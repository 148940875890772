import { FormattedMessage } from 'react-intl'
import type { DateSeparatorProps } from 'stream-chat-react'
import { Container, Separator, Text } from './DateSeparator.styles'

const DateSeparator = (props: DateSeparatorProps) => {
  const { unread } = props

  return unread ? (
    <Container>
      <Separator />
      <Text>
        <FormattedMessage id="chat.newMessages" />
      </Text>
      <Separator />
    </Container>
  ) : null
}

export default DateSeparator
