import { useMetricsContext } from 'containers/Metrics/MetricsContext'
import { useProfileContext } from 'containers/Profiles/ProfileContext'
import { useUpdatesFiltersContext } from 'containers/UpdatesView/UpdatesFiltersContext'
import { useCallback, useEffect, useRef, useState } from 'react'
import { setHideNavigation } from 'reducers/breadcrumbSlice'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'

const useProfilesActions = () => {
  const dispatch = useAppDispatch()
  const companyActions = document.getElementById('breadcrumb-actions-portal')

  const [mobileStyles, setMobileStyles] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const { matches: matchesMobileQuery } = useMediaQuery(Media.MAX_SM)

  const { onChange, search } = useProfileContext()
  const { onChangeSearch } = useUpdatesFiltersContext()
  const { onChange: onSearchMetrics } = useMetricsContext()

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeSearch(event)
      onChange(event)
      onSearchMetrics(event.target.value)
    },
    [onChangeSearch, onChange, onSearchMetrics]
  )

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setMobileStyles(true)
    }
  }, [])

  useEffect(() => {
    if (matchesMobileQuery) {
      setMobileStyles(true)
      return
    }
    setMobileStyles(false)
  }, [matchesMobileQuery])

  useEffect(() => {
    if (matchesMobileQuery) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
    return () => {}
  }, [handleClickOutside, matchesMobileQuery])

  useEffect(() => {
    if (matchesMobileQuery && !mobileStyles) {
      dispatch(setHideNavigation(true))
      return
    }

    dispatch(setHideNavigation(false))
  }, [dispatch, matchesMobileQuery, mobileStyles])

  const handleOnClickIcon = () => {
    if (!matchesMobileQuery) return
    setMobileStyles(false)
  }

  return {
    inputRef,
    mobileStyles,
    companyActions,
    search,
    matchesMobileQuery,
    onInputChange,
    handleOnClickIcon,
  }
}

export default useProfilesActions
