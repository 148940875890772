import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${color('white')};
  font-size: 1.2rem;
  border-radius: 0.6rem;
  padding: 0.8rem;
  box-shadow: 0 0 2rem 0 ${color('darkBlue', 0.15)};
  min-width: 10rem;
`

export const Title = styled.p`
  color: ${color('lightGray')};
  font-weight: bold;
  font-size: 1.2rem;
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const Item = styled.p`
  color: ${color('darkGray')};
  font-size: 1.4rem;
`
