import styled, { css } from 'styled-components'
import { HorizontalScroll, Scroll } from 'App.styles'
import { color } from 'utils/functions/colors'
import { size } from 'utils/constants/breakpoint'
import IconButton from 'ui/IconButton'

export const NAVIGATION_ITEM_WIDTH = '23rem'

const ExpandedListStyles = css`
  display: grid;
  gap: 0.8rem;
  max-height: 80vh;
  ${Scroll()}

  @media ${size.sm} {
    grid-template-columns: repeat(2, ${NAVIGATION_ITEM_WIDTH});
  }

  @media ${size.tabletLg} {
    grid-template-columns: repeat(3, ${NAVIGATION_ITEM_WIDTH});
  }

  @media ${size.desktopLg} {
    grid-template-columns: repeat(4, ${NAVIGATION_ITEM_WIDTH});
  }

  @media ${size.xxxl} {
    grid-template-columns: repeat(5, ${NAVIGATION_ITEM_WIDTH});
  }

  @media ${size.xxxXl} {
    grid-template-columns: repeat(6, ${NAVIGATION_ITEM_WIDTH});
  }
`

export const NavigationList = styled.div<{
  hasScroll: boolean
  isExpanded: boolean
}>`
  align-items: center;
  border-left: 0.1rem solid transparent;
  display: flex;
  gap: 0.8rem;
  padding-top: 0.8rem;

  ${HorizontalScroll(true)}

  &:hover {
    ${HorizontalScroll(false)}
  }

  ${({ hasScroll }) =>
    hasScroll &&
    css`
      &:hover {
        border-left: 0.1rem solid ${color('lightGray', 0.2)};
      }
    `}

  ${({ isExpanded }) => isExpanded && ExpandedListStyles}
`

export const PinnedButton = styled(IconButton)`
  margin-left: auto;
  cursor: pointer;
`
