import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import OptionsDropdown from 'ui/OptionsDropdown'
import { FileViewEnum } from 'components/FileViewSelector'
import FileIcon from 'components/FileIcon'
import {
  EmailContent as EmailContentType,
  IndexEmailContent,
  UserContentStatus,
} from 'utils/types/files'
import theme from 'utils/theme'
import { isUnreconciledFile } from 'utils/functions/files'
import {
  SkeletonAvatar,
  SkeletonItem,
  SkeletonThumbnailContainer,
  SkeletonThumbnailContentWrapper,
} from 'components/Skeletons/Skeletons.styles'

import UnreconciledIndicator from '../UnreconciledIndicator'
import SharedIndicator from '../SharedIndicator'
import {
  CheckIcon,
  ContentNameContainer,
  ContentSelectedContainer,
  ContentWrapper,
  FadeIn,
  ContentName,
  TooltipName,
  OptionsButton,
} from '../../Content.styles'
import { AttachmentWrapper } from './EmailContent.styles'

interface EmailContentProps {
  content?: IndexEmailContent
  isSelected?: boolean
  showDropdown?: boolean
  showUnreconciledIndicator?: boolean
  showSharedIndicator?: boolean
  isLoading: boolean
  size: FileViewEnum
  isMenuOpen?: boolean
  onCloseContentMenu?: () => void
  onRightClick?: () => void
  onClickFile?: (content: EmailContentType) => void
  onPreviewFile?: (file: EmailContentType) => void
  openEmailContentDetails?: (content: EmailContentType) => void
  onDeleteContent?: (content: EmailContentType) => void
  onPreviewContent?: (content: EmailContentType) => void
}

const EmailContent = ({
  content,
  isSelected,
  showDropdown = true,
  showUnreconciledIndicator = true,
  showSharedIndicator = true,
  size,
  isLoading,
  isMenuOpen,
  onClickFile,
  onCloseContentMenu,
  onRightClick,
  onPreviewFile,
  openEmailContentDetails,
  onDeleteContent,
  onPreviewContent,
}: EmailContentProps) => {
  const intl = useIntl()

  if (isLoading || !content) {
    return (
      <SkeletonThumbnailContainer>
        <SkeletonThumbnailContentWrapper size={size}>
          <SkeletonAvatar
            borderWidth="0.3rem"
            height={size === FileViewEnum.LARGE ? '6.8rem' : '4.8rem'}
            width={size === FileViewEnum.LARGE ? '6.8rem' : '4.8rem'}
          />
          <SkeletonItem width="10rem" height="1rem" />
        </SkeletonThumbnailContentWrapper>
      </SkeletonThumbnailContainer>
    )
  }

  const { title } = content.data

  const isUnreconciled =
    isUnreconciledFile(content?.userContentStatus) && showUnreconciledIndicator

  const isFileFromSharedUpdate =
    content?.userContentStatus === UserContentStatus.SHARED_UPDATE &&
    showSharedIndicator

  return (
    <FadeIn>
      <ContentSelectedContainer isSelected={isSelected}>
        <ContentWrapper
          isContentFromSharedUpdate={isFileFromSharedUpdate}
          isSelected={isSelected}
          size={size}
          onClick={() => onClickFile?.(content)}
          onDoubleClick={() => onPreviewFile?.(content)}
          onContextMenu={(e) => {
            e.preventDefault()
            onRightClick?.()
          }}
        >
          {isSelected && <CheckIcon icon={['fal', 'check']} />}

          {!!content.s3Contents?.length && (
            <AttachmentWrapper size={size}>
              <FontAwesomeIcon icon={['fal', 'paperclip']} />
            </AttachmentWrapper>
          )}

          <FileIcon
            large={size === FileViewEnum.LARGE}
            hasBorder
            isEmailContent
          />

          <ContentNameContainer large={size === FileViewEnum.LARGE}>
            <TooltipName
              place="bottom"
              id={content.id}
              text={title}
              wordBreakAll
            >
              <ContentName>{title}</ContentName>
            </TooltipName>
            {showUnreconciledIndicator && (
              <UnreconciledIndicator content={content} />
            )}
            {isFileFromSharedUpdate && <SharedIndicator content={content} />}
          </ContentNameContainer>

          {showDropdown && (
            <OptionsButton>
              <OptionsDropdown
                buttonType="square"
                buttonSize="3.2rem"
                dropdownContentMinWidth="18.8rem"
                alignLeft
                isOpen={isMenuOpen}
                onCloseDropdown={onCloseContentMenu}
              >
                {onPreviewContent && (
                  <OptionsDropdown.Item
                    label={intl.formatMessage({ id: 'general.preview' })}
                    icon={['fal', 'eye']}
                    onSelectOption={() => onPreviewContent?.(content)}
                  />
                )}
                <OptionsDropdown.Item
                  label={intl.formatMessage({
                    id: 'files.properties',
                  })}
                  icon={['fal', 'file-alt']}
                  onSelectOption={() => openEmailContentDetails?.(content)}
                />
                {isUnreconciled && onDeleteContent && (
                  <OptionsDropdown.Item
                    label={intl.formatMessage({
                      id: 'files.delete',
                    })}
                    onSelectOption={() => onDeleteContent?.(content)}
                    icon={['fal', 'trash-alt']}
                    color={theme.colors.red}
                  />
                )}
              </OptionsDropdown>
            </OptionsButton>
          )}
        </ContentWrapper>
      </ContentSelectedContainer>
    </FadeIn>
  )
}

export default EmailContent
