import { useCallback } from 'react'
import { useChatContext } from 'stream-chat-react'
import { StreamChatType } from '../types'
import { getPinById } from '../helpers'

/**
 * Custom hook to pin and unpin DM channels and topics
 * @returns {function} pinChannel
 * @returns {function} unpinChannel
 * @returns {function} togglePinChannel
 * @returns {function} togglePinTopic
 */
export const usePinChannels = () => {
  const { client } = useChatContext<StreamChatType>()

  const pinChannel = useCallback(
    async (channelId: string) => {
      const pinnedChannels = [
        ...(client.user?.pinnedChannels || []),
        {
          id: channelId,
          pinnedAt: new Date().toISOString(),
        },
      ]

      await client.partialUpdateUser({
        id: client.user!.id,
        set: {
          pinnedChannels,
        },
      })
    },
    [client]
  )

  const unpinChannel = useCallback(
    async (channelId: string) => {
      const pinnedChannels = client.user?.pinnedChannels || []
      const newPinnedChannels = pinnedChannels.filter(
        (channel) => channel.id !== channelId
      )

      if (pinnedChannels.length !== newPinnedChannels.length) {
        await client.partialUpdateUser({
          id: client.user!.id,
          set: {
            pinnedChannels: newPinnedChannels,
          },
        })
      }
    },
    [client]
  )

  const togglePinChannel = useCallback(
    async (channelId: string) => {
      const pinnedChannels = client.user?.pinnedChannels || []

      if (getPinById(channelId, pinnedChannels)) {
        unpinChannel(channelId)
      } else {
        pinChannel(channelId)
      }
    },
    [client, pinChannel, unpinChannel]
  )

  const togglePinTopic = useCallback(
    async (topicId: string) => {
      let pinnedTopics = client.user?.pinnedTopics || []

      if (getPinById(topicId, pinnedTopics)) {
        pinnedTopics = pinnedTopics.filter((pin) => pin.id !== topicId)
      } else {
        pinnedTopics = [
          ...pinnedTopics,
          {
            id: topicId,
            pinnedAt: new Date().toISOString(),
          },
        ]
      }

      await client.partialUpdateUser({
        id: client.user!.id,
        set: {
          pinnedTopics,
        },
      })
    },
    [client]
  )

  return {
    pinChannel,
    unpinChannel,
    togglePinChannel,
    togglePinTopic,
  }
}
