import { useIntl } from 'react-intl'

import * as Styles from './SetAsMainRow.styles'

const SetAsMainRow = ({
  isMain = false,
  deletable = true,
  text,
  setAsMain,
  removeItem,
}) => {
  const intl = useIntl()

  return (
    <Styles.Container>
      <Styles.Column>
        <Styles.Text>{text}</Styles.Text>
        {isMain ? (
          <Styles.MainTag>
            {intl.formatMessage({ id: 'common.main' })}
          </Styles.MainTag>
        ) : (
          <Styles.SetAsMainButton onClick={setAsMain}>
            {intl.formatMessage({ id: 'common.setAsMain' })}
          </Styles.SetAsMainButton>
        )}
      </Styles.Column>
      <Styles.Column>
        {deletable && (
          <Styles.Remove
            onClick={removeItem}
            icon="trash-alt"
            danger
            border={false}
            buttonSize="2rem"
            roundedButtonHover={false}
          />
        )}
      </Styles.Column>
    </Styles.Container>
  )
}

export default SetAsMainRow
