import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import EmailService from 'api/EmailService'
import Modal from 'components/Modal'
import Button from 'ui/Button'
import GroupsUsersAddInput from 'components/GroupsUsersAddInput'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import Toast from 'components/Toast'
import ListsSelectionBox from 'components/ListsSelectionBox'
import Separator from 'ui/Separator'

import styles from './ResendIUEModal.module.scss'

const removeItem = (itemId, state) => state?.filter?.((id) => id !== itemId)

const ResendIUEModal = ({ emailId, openModal, setOpenModal }) => {
  const intl = useIntl()
  const addInputRef = useRef(null)
  const [users, setUsers] = useState({})
  const [sharedUsers, setSharedUsers] = useState([])

  useEffect(() => {
    if (!openModal) {
      setUsers({})
    }
  }, [openModal])

  const handleBulkAddUsers = (newUsers) => {
    setUsers((state) => ({
      ...state,
      ...newUsers.reduce((res, user) => {
        res[user.id] = user
        return res
      }, {}),
    }))
    addInputRef.current?.clear()
  }

  const handleAddUser = (newUser) => {
    setUsers((state) => ({
      ...state,
      [newUser.id]: newUser,
    }))
    addInputRef.current?.clear()
  }

  const handleDeleteUser = (userId) => {
    setUsers((state) => Object.values(state).filter(({ id }) => id !== userId))
    setSharedUsers((state) =>
      state.filter((currUserId) => currUserId !== userId)
    )
  }

  const resendIUE = async () => {
    try {
      await EmailService.resendEmail(emailId, {
        userIds: Object.keys(users),
      })

      Toast.display(intl.messages['showEmail.resendEmailSuccess'])
      setOpenModal(false)
    } catch (err) {
      Toast.display(intl.messages['showEmail.resendEmailError'], 'error')
    }
  }

  const handleAddUserFromList = (user) => {
    if (!sharedUsers.includes(user.id)) {
      setUsers((state) => ({ ...state, [user.id]: user }))
      setSharedUsers((state) => [...state, user.id])
    }
  }

  const handleDelete = (itemId) => {
    setUsers((state) => {
      // eslint-disable-next-line no-param-reassign
      delete state[itemId]
      return { ...state }
    })
    setSharedUsers((state) => removeItem(itemId, state))
  }

  const handleEditItemList = ({ id, listId, listName }) => {
    setUsers((state) =>
      state.map((item) =>
        item.id === id ? { ...item, listId, listName } : item
      )
    )
  }

  return (
    <Modal
      show={openModal}
      onHide={() => {
        setOpenModal(false)
      }}
      className={styles.modalWrapper}
    >
      <Modal.Header onHide={() => setOpenModal(false)}>
        <Modal.Title>
          <div className={styles.title}>
            <h2>{intl.messages['showEmail.sendToAdditionalrecipients']}</h2>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>
        <p className={styles.modalSubtitle}>
          <FormattedMessage id="createEmail.resendRecipientTitle" />
        </p>

        <ListsSelectionBox
          items={Object.values(users)}
          handleAddUser={handleAddUserFromList}
          handleDelete={handleDelete}
          handleEditItemList={handleEditItemList}
          customLabel={intl.messages['createEmail.byRecipientsList']}
          customLinkMessage={intl.messages['createEmail.manageRecipientsLists']}
          onlyUsers
        />

        <Separator space="2.6rem" />

        <GroupsUsersAddInput
          ref={addInputRef}
          id="resend-recipients"
          handleBulkAddUsers={handleBulkAddUsers}
          items={Object.values(users)}
          handleAddUser={handleAddUser}
          onlyUsers
          clearOnSelect
          customLabel={intl.messages['createEmail.byEmail']}
          customPlaceholder={intl.messages['createEmail.addEmailsSeparated']}
          showOptionsTitle={false}
        />

        {Object.values(users).length > 0 && <Separator space="3.2rem" />}

        {Object.values(users).length > 0 ? (
          <p className={styles.sendEmailTo}>
            <FontAwesomeIcon icon={['far', 'envelope']} />
            <FormattedMessage id="createEmail.sendEmailToRecipient" />
          </p>
        ) : null}

        <BoxGroupsAndUsersChips
          items={Object.values(users)}
          handleDelete={handleDeleteUser}
        />
      </Modal.Body>

      <Modal.Footer className={styles.modalFooter}>
        <Button onClick={() => setOpenModal(false)}>
          {intl.messages['createEmail.cancel']}
        </Button>
        <Button
          primary
          onClick={resendIUE}
          disabled={!Object.values(users).length}
        >
          {intl.messages['createEmail.sendEmail']}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ResendIUEModal.propTypes = {
  emailId: PropTypes.string,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
}

ResendIUEModal.defaultProps = {
  emailId: '',
}
export default ResendIUEModal
