import React from 'react'
import PropTypes from 'prop-types'

import {
  ButtonContainer,
  ButtonStylesWrapper,
  EditButtonColumnContainer,
} from './FloatButtonColumn.styles'

const FloatColumnWrapper = ({ children, id }) => {
  return (
    <ButtonStylesWrapper>
      <EditButtonColumnContainer>
        <ButtonContainer id={`editButtonColumn_${id}`}>
          {children}
        </ButtonContainer>
      </EditButtonColumnContainer>
    </ButtonStylesWrapper>
  )
}

FloatColumnWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  id: PropTypes.string.isRequired,
}
export default FloatColumnWrapper
