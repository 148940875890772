export default function beamerInitialize(id, url = false, args = {}) {
  if (!window || process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production') {
    return
  }

  if (!id) {
    throw Error('Must provide "id". https://app.getbeamer.com/embed')
  }

  window.beamer_config = {
    product_id: id,
    ...args,
  }

  const timestamp = new Date().getTime().toString()
  const beamerURL =
    url || `https://app.getbeamer.com/js/beamer-embed.js?randomStr=${timestamp}`

  const body = document.getElementsByTagName('body')[0]
  const script = document.createElement('script')

  script.defer = 'defer'
  script.src = beamerURL
  body.appendChild(script)
}
