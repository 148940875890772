import { getHasChatFeature } from 'selectors/chat'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useChatNotifications } from './useChatNotifications'

const ChatNotifications = () => {
  useChatNotifications()

  return null
}

const Notifications = () => {
  const hasChatEnabled = useAppSelector(getHasChatFeature)

  if (hasChatEnabled) {
    return <ChatNotifications />
  }

  return null
}

export default Notifications
