import CompanyService from 'api/CompanyService'
import EmployeeService from 'api/EmployeeService'
import Toast from 'components/Toast'
import debounce from 'lodash/debounce'
import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useCompanyQuery } from 'utils/hooks/queries/useCompanyQuery'
import useInitialData from 'utils/hooks/useInitialData'
import { Nullable } from 'utils/types/common'
import { Company, Employee, Industry } from 'utils/types/company'
import { Location } from 'utils/types/locations'

export interface CompanyEmployee extends Employee {
  hidden?: boolean
}

const useCompanyProfileDrawer = () => {
  const [selectedCompany, setSelectedCompany] = useState<Nullable<string>>(null)
  const [image, setImage] = useState(null)

  const [companyInfo, setCompanyInfo] = useState<{
    companyData?: Company
    locations?: string[]
    investmentToolIndustries?: Industry[]
    companyIndustries?: Industry[]
    employees?: CompanyEmployee[]
  }>({})

  const [locationEntities, setLocationEntities] = useState<Location[]>([])

  const {
    data,
    isLoading: isCompanyLoading,
    isError: isCompanyError,
    error: companyError,
    refetch: refetchCompany,
  } = useCompanyQuery(selectedCompany ?? '', {
    enabled: !!selectedCompany,
  })

  const { loading, initialData } = useInitialData()

  const intl = useIntl()

  const editTeamMembersCount = useMemo(
    () =>
      debounce(async (value) => {
        try {
          await CompanyService.editCompany(
            selectedCompany,
            {
              employeesNumber: value,
            },
            undefined
          )
          refetchCompany()
        } catch (error) {
          Toast.display(
            intl.messages['editCompany.editTeamMembersCountError'],
            'error'
          )
        }
      }, 500),
    [selectedCompany, refetchCompany, intl.messages]
  )

  const onMembersCountChange = ({ target: { value } }) => {
    setCompanyInfo({
      ...companyInfo,
      companyData: {
        ...companyInfo.companyData,
        employeesNumber: value,
      } as Company,
    })
    editTeamMembersCount(value)
  }

  const onChange = ({
    companyData,
    locations,
    investmentToolIndustries,
    employees,
  }: {
    companyData?: Company
    locations?: string[]
    investmentToolIndustries?: Industry[]
    employees?: CompanyEmployee[]
  }) => {
    setCompanyInfo((state) => ({
      ...state,
      companyData:
        companyData?.[selectedCompany!] || companyData || state.companyData,
      locations: locations || state.locations,
      investmentToolIndustries:
        investmentToolIndustries || state.investmentToolIndustries,
      employees: employees || state.employees,
    }))
    refetchCompany()
  }

  const handleChangeEmployees = (employees) => {
    setCompanyInfo((state) => ({
      ...state,
      employees,
    }))
  }

  const onAddNewTeamMember = async (employeeData) => {
    try {
      const response = await CompanyService.createCompanyEmployee(
        selectedCompany,
        {
          employee: employeeData,
        }
      )
      const newEmployee = response.data.entities.employees[response.data.result]
      setCompanyInfo((state) => ({
        ...state,
        employees: [...(state.employees || []), newEmployee],
      }))
      refetchCompany()
      Toast.display(
        intl.formatMessage(
          {
            id: 'editCompany.addTeamMemberSuccess',
          },
          { name: `${employeeData.firstName} ${employeeData.lastName}` }
        )
      )
    } catch (err) {
      Toast.displayIntl(
        [
          'errors.creatingItemError',
          {
            error: intl.formatMessage({ id: 'general.employee' }),
          },
        ],
        'error'
      )
    }
  }

  const toggleHideTeamMember = (employeeId) => {
    setCompanyInfo((state) => ({
      ...state,
      employees: state.employees?.map((emp) => {
        if (emp.id === employeeId) {
          return {
            ...emp,
            hidden: !emp.hidden,
          }
        }
        return emp
      }),
    }))
  }

  const onEditTeamMember = async (employeeId, employeeData) => {
    try {
      const response = await EmployeeService.editEmployee(employeeId, {
        employee: employeeData,
      })
      const updatedEmployee =
        response.data.entities.employees[response.data.result]
      setCompanyInfo((state) => ({
        ...state,
        employees: state.employees?.map((emp) => {
          if (emp.id === updatedEmployee.id) {
            return updatedEmployee
          }
          return emp
        }),
      }))
      refetchCompany()
      Toast.display(
        intl.formatMessage(
          {
            id: 'editCompany.editTeamMemberSuccess',
          },
          { name: `${employeeData.firstName} ${employeeData.lastName}` }
        )
      )
    } catch (err) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.editItemError' },
          { item: intl.formatMessage({ id: 'general.employee' }) }
        ),
        'error'
      )
    }
  }

  const onDeleteTeamMember = (deletedEmployee) => {
    setCompanyInfo((state) => ({
      ...state,
      employees: state.employees?.filter((emp) => emp.id !== deletedEmployee),
    }))
    refetchCompany()
  }

  const onSaveImage = async () => {
    try {
      const bodyFormData = new FormData()
      bodyFormData.append('company_datum[primary_logo]', image!)
      const {
        data: {
          entities: { companyData },
          result,
        },
      } = await CompanyService.editCompany(selectedCompany, bodyFormData, true)
      setCompanyInfo((currCompanyInfo) => ({
        ...currCompanyInfo,
        companyData: { ...companyData?.[result] },
      }))
      refetchCompany()
    } catch (errors) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.editItemError' },
          {
            name: '',
            item: intl.formatMessage({ id: 'editCompany.companyLogo' }),
          }
        ),
        'error'
      )
    }
  }

  const onSelectImage = (file) => {
    setImage(file)
  }

  useEffect(() => {
    if (data) {
      const { employees, investmentToolIndustries, industries, ...company } =
        data

      setCompanyInfo({
        companyData: company,
        locations: company.locations ?? [],
        investmentToolIndustries: investmentToolIndustries ?? [],
        companyIndustries: industries ?? [],
        employees: employees ?? [],
      })

      setLocationEntities(data.locationEntities)
    }
  }, [data])

  return {
    initialData,
    companyInfo,
    isLoading: loading || isCompanyLoading,
    isCompanyError,
    companyError,
    selectedCompany,
    locationEntities,
    setSelectedCompany,
    onChange,
    onMembersCountChange,
    handleChangeEmployees,
    onAddNewTeamMember,
    toggleHideTeamMember,
    onEditTeamMember,
    onDeleteTeamMember,
    onSaveImage,
    onSelectImage,
    refetchCompany,
  }
}

export default useCompanyProfileDrawer
