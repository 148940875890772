import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import * as Styles from './UndoArchive.styles'

export const UndoArchive =
  (onUndoArchiveChannel: () => void) =>
  ({ hideToast }: { hideToast: () => void }) => {
    return (
      <Styles.UndoContainer>
        <Styles.Undo
          onClick={() => {
            onUndoArchiveChannel()
            hideToast()
          }}
        >
          <FormattedMessage id="general.undo" />
        </Styles.Undo>
        <FontAwesomeIcon icon={['fal', 'times']} onClick={hideToast} />
      </Styles.UndoContainer>
    )
  }
