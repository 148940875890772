import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { ReactChildren } from 'utils/types/common'

interface UploadsMenuAnimationWrapperProps {
  children: ReactChildren
  isVisible: boolean
}

const UploadsMenuAnimationWrapper: React.FC<
  UploadsMenuAnimationWrapperProps
> = ({
  isVisible,
  children,
}: {
  isVisible: boolean
  children: React.ReactNode
}) => {
  const animation = {
    y: '0%',
    opacity: 1,
    transition: { duration: 0.3, ease: 'easeIn' },
    transitionEnd: {
      y: 0,
    },
  }

  const exitAnimation = {
    y: '100%',
    opacity: 0,
    transition: { duration: 0.3, ease: 'easeIn' },
  }

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{
            y: '100%',
            opacity: 1,
          }}
          animate={animation}
          exit={exitAnimation}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default UploadsMenuAnimationWrapper
