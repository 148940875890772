/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import Checkbox from 'components/Checkbox'
import InputLabel from 'ui/InputLabel'

import styles from './ScheduleUpdateRadio.module.scss'

export const ScheduleType = {
  SEND_NOW: 'SEND_NOW',
  SCHEDULE: 'SCHEDULE',
}

export const ScheduleUpdateRadio = ({
  register,
  name,
  label,
  value,
  publishNowLabel,
  handleChange,
}) => {
  const intl = useIntl()

  return (
    <div>
      {label && <InputLabel marginLeft="2rem">{label}</InputLabel>}
      <div className={styles.container}>
        <Checkbox
          ref={register}
          name={name}
          className={classNames(styles.checkRadio, {
            [styles.unchecked]: value !== ScheduleType.SEND_NOW,
          })}
          id={ScheduleType.SEND_NOW}
          value={ScheduleType.SEND_NOW}
          mode="custom"
          type="radio"
          onChange={() => handleChange(ScheduleType.SEND_NOW)}
          checked={register ? undefined : value === ScheduleType.SEND_NOW}
        >
          <div className={styles.text}>
            {publishNowLabel || intl.messages['updates.publishNow']}
          </div>
        </Checkbox>
        <Checkbox
          id={ScheduleType.SCHEDULE}
          type="radio"
          ref={register}
          name={name}
          className={classNames(styles.checkRadio, {
            [styles.unchecked]: value !== ScheduleType.SCHEDULE,
          })}
          value={ScheduleType.SCHEDULE}
          mode="custom"
          onChange={() => handleChange(ScheduleType.SCHEDULE)}
          checked={register ? undefined : value === ScheduleType.SCHEDULE}
        >
          <div className={styles.text}>
            {intl.messages['updates.selectSpecificDate']}
          </div>
        </Checkbox>
      </div>
    </div>
  )
}

ScheduleUpdateRadio.propTypes = {
  register: PropTypes.func,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  publishNowLabel: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
}

ScheduleUpdateRadio.defaultProps = {
  register: null,
  handleChange: () => {},
  name: '',
  publishNowLabel: '',
  label: '',
}

export default React.memo(ScheduleUpdateRadio)
