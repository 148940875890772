/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import ReportSectionMainSummary from './ReportSectionMainSummary'
import ReportSectionValues from './ReportSectionValues'
import {
  ReportColumn,
  ReportSectionTitle,
  ReportSectionSubTitle,
} from './ShowReport.styles'

const ReportSection = ({
  periods,
  title,
  sectionRows,
  isMainSection,
  isMainSectionSummary,
}) => {
  if (isMainSectionSummary) {
    return (
      <ReportSectionMainSummary periods={periods} sectionRow={sectionRows[0]} />
    )
  }
  return (
    <>
      <ReportColumn labelColumn borderRight>
        <ReportSectionTitle isMainSection={isMainSection}>
          {title}
        </ReportSectionTitle>

        {sectionRows?.map?.((sectionRow) => {
          return (
            <ReportSectionSubTitle rowType={sectionRows.rowType}>
              {sectionRow.cells[0].value}
            </ReportSectionSubTitle>
          )
        })}
      </ReportColumn>

      {new Array(periods).fill().map((value, index) => {
        return (
          <ReportSectionValues
            key={index}
            sections={sectionRows}
            valueIndex={index}
            isLastColumn={index === periods - 1}
          />
        )
      })}
    </>
  )
}

ReportSection.propTypes = {
  periods: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  sectionRows: PropTypes.array.isRequired,
  isMainSection: PropTypes.bool.isRequired,
  isMainSectionSummary: PropTypes.bool.isRequired,
}

export default ReportSection
