import { useQuery } from '@tanstack/react-query'
import { setPortfolios } from 'actions/portfolios'
import PortfolioService from 'api/PortfolioService'
import Toast from 'components/Toast'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { portfolioKeys } from 'utils/queries/portfolios'

const usePortfolios = () => {
  const dispatch = useAppDispatch()

  const { data: portfolios, status } = useQuery(
    portfolioKeys.all(),
    async () => {
      const currentPortfolios = await PortfolioService.fetchPortfolios({
        withCompanies: true,
        perPage: 500,
      })
      return currentPortfolios
    },
    {
      onSuccess: (data) => {
        dispatch(setPortfolios(data))
      },
      onError: () => {
        Toast.displayIntl('portfolios.getPortfolioError', 'error')
      },
    }
  )

  return {
    portfolios,
    isLoadingPortfolios: status === 'loading',
  }
}

export default usePortfolios
