import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { Label } from 'ui/InputLabel/InputLabel.styles'

import { Legend } from '../../../Signup.styles'

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
`

export const BillingInfo = styled(Legend)`
  text-align: center;
  max-width: 36.1rem;
  margin-top: 0.8rem;
  margin-bottom: 3.2rem;
  line-height: 1.15;
`

export const PhoneAndInputContainer = styled(FormWrapper)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.6rem;
`

export const GridContainer = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    columns ? `repeat(${columns}, 1fr)` : '1fr'};
  gap: 2.4rem;

  @media ${maxSize.sm} {
    grid-template-columns: 1fr;
  }

  ${Label} {
    margin-left: 1.6rem;
    font-size: 1.4rem;
    font-weight: 700;
  }
`

export const LinkContainer = styled.div<{ forceMobile?: boolean }>`
  width: 100%;
  margin-top: ${(props) => (props.forceMobile ? 0 : '2.4rem')};
  text-align: left;
  margin-left: 0.2rem;
`

export const PostalAddressLink = styled.div`
  color: ${color('primaryDarkBlue')};
  font-size: 1.4rem;
  font-weight: 700;
  width: fit-content;
  border-bottom: 1px solid transparent;
  border-color: ${color('primaryDarkBlue')};

  &:hover {
    cursor: pointer;
  }
`
