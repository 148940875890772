export const QUERIES = {
  MFA_ENROLLMENT: 'MFA_ENROLLMENT',
  RECOVERY_CODES: 'recoveryCodes',
  DISABLE_MFA: 'disableMfa',
} as const

export const authKeys = {
  mfaEnrollment: () => [QUERIES.MFA_ENROLLMENT] as const,
  recoveryCodes: () => [QUERIES.RECOVERY_CODES] as const,
  disableMfa: () => [QUERIES.DISABLE_MFA] as const,
}
