/* eslint-disable jsx-a11y/interactive-supports-focus, consistent-return */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'

import AddToPortfolioButton from 'components/AddToPortfolioButton'
import Avatar from 'components/Avatar'
import CompanyStatus from 'components/CompanyStatus'
import Heading from 'components/Heading'
import { isEnterCode } from 'utils/functions/keyboardEvents'

import { gridItemAnimation } from 'utils/animations/grid'
import { getInitials } from 'utils/functions/user'
import {
  getHoldingImage,
  isDealHolding,
  isFundHolding,
} from 'utils/types/company'

import styles from './HoldingCard.module.scss'

const CompanyCard = ({
  holding,
  primaryLocations,
  onCardClick,
  showRemoveButton,
  onRemoveCompany,
  isFundPortfolio,
  hideAddToPortfolioButton,
}) => {
  const isFund = isFundHolding(holding)
  const isDeal = isDealHolding(holding)
  const hasFundManagerInfo = !!holding.group

  const handleKeyUp = (event) => {
    if (isEnterCode(event)) {
      onCardClick()
    }
  }

  const hasPrimaryLocation =
    !!primaryLocations[holding.primaryLocation] || holding.primaryLocation
  const formattedAddress = holding?.primaryLocation?.formattedAddress

  return (
    <motion.li key={holding.name} variants={gridItemAnimation}>
      <div
        className={`${styles.companyWrapper} holding-name`}
        onClick={onCardClick}
        onKeyUp={handleKeyUp}
        role="button"
      >
        {!hideAddToPortfolioButton && (
          <div className={styles.btnAddToPortfolio}>
            <AddToPortfolioButton
              holdingId={holding.id}
              holdingName={holding.name}
              holdingPortfolios={[
                ...(holding.holdingPortfolios ?? []),
                ...(holding.holdingFundPortfolios ?? []),
              ]}
              isFundHolding={isFund}
            />
          </div>
        )}
        {showRemoveButton && (
          <button
            onClick={(event) => {
              event.stopPropagation()
              onRemoveCompany(holding)
            }}
            className={styles.btnRemoveCompany}
            type="button"
          >
            <FontAwesomeIcon size="5x" icon={['fas', 'times']} />
          </button>
        )}

        <div className={styles.companyImageWrapper}>
          <Avatar
            image={getHoldingImage(holding)}
            initials={getInitials(holding.name)}
            avatarStyle="avatarCircleLarge"
          />
        </div>

        <div>
          <Heading
            className={styles.companyName}
            level="h4"
            title={holding.name}
          >
            {holding.name}
          </Heading>
          {hasPrimaryLocation && (
            <p className={styles.companyLocation} title={formattedAddress}>
              <FontAwesomeIcon icon={['fal', 'location-pin']} />
              {formattedAddress}
            </p>
          )}
        </div>

        {(isDeal || isFundPortfolio) && hasFundManagerInfo && (
          <div className={styles.fundManagerName}>
            <FontAwesomeIcon icon={['fal', 'user-tie']} />
            <p className={styles.fundCompanyName} title={holding.group.name}>
              {holding.group.name}
            </p>
          </div>
        )}
        <CompanyStatus
          company={holding}
          displayLastUpdate
          isFundPortfolio={isFundPortfolio}
          isDealPortfolio={isDeal}
        />
      </div>
    </motion.li>
  )
}

CompanyCard.defaultProps = {
  onCardClick: () => {},
  primaryLocations: {},
  showRemoveButton: false,
  onRemoveCompany: () => {},
  isFundPortfolio: false,
  hideAddToPortfolioButton: false,
}

CompanyCard.propTypes = {
  holding: PropTypes.object.isRequired,
  primaryLocations: PropTypes.object,
  onCardClick: PropTypes.func,
  showRemoveButton: PropTypes.bool,
  onRemoveCompany: PropTypes.func,
  isFundPortfolio: PropTypes.bool,
  hideAddToPortfolioButton: PropTypes.bool,
}

export default CompanyCard
