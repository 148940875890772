import React from 'react'
import {
  Channel,
  Window,
  MessageList,
  MessageInput as StreamMessageInput,
  Thread,
} from 'stream-chat-react'
import type { Channel as ChannelType } from 'stream-chat'
import 'stream-chat-react/dist/css/v2/index.css'

import { StreamChatType } from 'containers/Chat/types'
import { ChatListContainer } from 'containers/Chat/Chat.styles'

import { ChatPopupHeader } from 'containers/Chat/components/ChatPopupHeader'
import Message from '../../../Message'
import MessageInput from '../../../MessageInput'
import EmptyComponent from '../../../EmptyComponent'
import TypingIndicator from '../../../TypingIndicator'
import EmptyPlaceholder from '../../../ZeroStates/EmptyPlaceholder'
import EmptyStateIndicator from '../../../ZeroStates/EmptyStateIndicator'
import DateSeparator from '../../../DateSeparator'
import SystemMessage from '../../../SystemMessage'
import SuggestionItem from '../../../ChatSuggestions/SuggestionItem'
import ThreadHeader from '../ThreadHeader'
import ClosableThreadArea from '../ClosableThreadArea'
import * as Styles from './MaximizedChatPopup.styles'

interface MaximizedChatPopupProps {
  channel: ChannelType<StreamChatType>
}

const MaximizedChatPopup: React.FC<MaximizedChatPopupProps> = ({ channel }) => {
  return (
    <Styles.Container>
      <Channel
        channel={channel}
        Message={Message}
        Input={MessageInput}
        MessageListNotifications={EmptyComponent}
        TypingIndicator={TypingIndicator}
        ThreadHeader={ThreadHeader}
        ThreadStart={EmptyComponent}
        LoadingIndicator={EmptyComponent}
        EmptyPlaceholder={EmptyPlaceholder()}
        EmptyStateIndicator={EmptyStateIndicator}
        DateSeparator={DateSeparator}
        MessageSystem={SystemMessage}
        AutocompleteSuggestionItem={SuggestionItem}
      >
        <Window>
          <ChatPopupHeader channel={channel} />
          <ChatListContainer>
            <MessageList />
            <StreamMessageInput grow />
          </ChatListContainer>
        </Window>
        <ClosableThreadArea />
        <Thread Input={MessageInput} />
      </Channel>
    </Styles.Container>
  )
}

export default MaximizedChatPopup
