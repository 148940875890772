import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { breakpoint } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { UpdateTextContainer } from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'

const hoverStyles = css`
  box-shadow: 0 0 0 2px ${color('veryLightBlue')};
`

export const FeedUpdateCard = styled(motion.div)<{
  hoverState?: boolean
  isDraftUpdate?: boolean
}>`
  background-color: ${(props) =>
    props.isDraftUpdate ? color('softBlue', 0.9)(props) : 'white'};
  position: relative;
  border-radius: 1rem;
  box-shadow: 0 0 1.5rem rgba(16, 21, 39, 0.1);
  cursor: pointer;
  padding: 1.8rem 2.4rem;
  cursor: pointer;

  @media (max-width: ${breakpoint.sm}) {
    padding: 2.1rem 1.5rem;
  }
`

export const FeedUpdateCardBorder = styled.div<{
  hoverState?: boolean
  checkState?: boolean
  isDraftUpdate?: boolean
}>`
  position: relative;
  border-radius: 1rem;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 1px
    ${(props) =>
      props.isDraftUpdate ? color('veryLightGray')(props) : 'white'};

  &:hover {
    ${hoverStyles}
  }
  ${(props) => props.hoverState && hoverStyles};

  ${(props) =>
    props.isDraftUpdate &&
    css`
      background-color: ${color('whiteSmoke')};
      border-color: ${color('veryLightGray')};
    `}
`

export const FeedUpdateHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FeedUpdateDate = styled.div`
  color: ${color('lightGray')};
  font-size: 1.4rem;
  margin-left: 1rem;
`

export const FeedUpdateBody = styled.div`
  > div {
    height: fit-content;
  }

  ${UpdateTextContainer} {
    margin-top: 0.8rem;
  }
`

export const UpdateFooter = styled.div`
  color: #131313;
  display: flex;
  flex-direction: column;
  font-size: 1.6rem;
  font-weight: 400;
  margin-top: 1.35rem;
  row-gap: 1rem;

  @media (min-width: ${breakpoint.xsm}) {
    display: flex;
    flex-direction: row;
    gap: 1.8rem;

    > *:not(:first-child) {
      border-left: 0.1rem solid ${color('veryLightGray')};
      padding-left: 1.5rem;
    }
  }
`
