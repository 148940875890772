import React from 'react'
import { displayThousands } from 'utils/functions/number'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import CustomizedAxisTick from './CustomizedAxisTick'

interface CardSummaryChartProps<T> {
  chartData: T
  getColorFn: (type: string) => string
  withCustomizedAxisTick?: boolean
  ChartTooltip: React.FC
  showXYAxis?: boolean
}

const NAME_CONSTANT = 'name'

function CardSummaryChart<T extends any[]>({
  chartData,
  getColorFn,
  ChartTooltip,
  showXYAxis = true,
  withCustomizedAxisTick = true,
}: CardSummaryChartProps<T>) {
  return (
    <ResponsiveContainer width="100%" height={155}>
      <BarChart data={chartData}>
        <CartesianGrid vertical={false} />
        {showXYAxis && (
          <>
            <XAxis
              dataKey="name"
              axisLine={false}
              tickLine={false}
              tick={withCustomizedAxisTick ? CustomizedAxisTick : undefined}
              interval={0}
              height={40}
              fontSize="1.2rem"
              fill="#666"
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
              width={35}
              fontSize={12}
              tickCount={4}
              tickFormatter={displayThousands}
            />
          </>
        )}

        {showXYAxis && (
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            offset={-110}
            cursor={{ fill: 'transparent' }}
            content={<ChartTooltip />}
          />
        )}

        {Object.keys(chartData[Object.keys(chartData)[0]]).map((key) => {
          if (key === NAME_CONSTANT) return null
          return (
            <Bar
              key={key}
              dataKey={key}
              // The stack id of bar, when two bars have the same value axis and same stackId, then the two bars are stacked in order.
              stackId="unique"
              fill={getColorFn(key)}
              barSize={10}
            />
          )
        })}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default CardSummaryChart
