/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
import { useState, useEffect } from 'react'
import { findLastIndex, findIndex } from 'lodash'
import { useIntl } from 'react-intl'
import { useAppSelector, useAppDispatch } from 'utils/hooks/reduxToolkit'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { setCurrentUpdates } from 'actions/companies'
import Toast from 'components/Toast'

import {
  getCurrentUpdatesWithoutEmailsDraft,
  getOnlyEmailUpdates,
} from 'selectors/company'
import { DIRECTION } from 'utils/constants/updateNavigation'
import { parseUpdatesForNavigation } from 'utils/functions/updates'
import useEntityFromUrl, { EntityName } from 'utils/hooks/useEntityFromUrl'
import { UpdateRouteState } from 'utils/hooks/useShowUpdateRoutes'
import { NavigationUpdate } from 'utils/types/update'
import { isCompanyHolding } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import MixedUpdatesService from 'api/MixedUpdatesService'
import { UpdateEntityType } from 'components/UpdatesFeedV2/types'
import { isIndexUpdate } from 'utils/functions/suggestedUpdates'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import { DefaultRoutes, getCurrentBaseRoute } from 'reducers/breadcrumbSlice'

export const useUpdateNavigationHeader = () => {
  const [isLoading, setIsLoading] = useState(true)

  const baseRoute = useAppSelector(getCurrentBaseRoute)
  const isEmailOnlyNavigation = baseRoute === DefaultRoutes.EMAILS

  const currentUpdates = useAppSelector<{
    updates: NavigationUpdate[]
    page: number
  }>(
    isEmailOnlyNavigation
      ? getOnlyEmailUpdates
      : getCurrentUpdatesWithoutEmailsDraft
  )

  const [disableNextButton, setDisableNextButton] = useState(false)
  const [disablePreviousButton, setDisablePreviousButton] = useState(false)
  const location = useLocation<UpdateRouteState>()
  const locationState = location.state || {}

  const history = useHistory()
  const params = useParams<{ updateId?: string; emailId?: string }>()
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { handleUpdateNavigation } = useOnClickRedirect()

  const entity = useEntityFromUrl()

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const getUpdateIndex = (direction?: string) => {
    let currentIndex = 0
    if (direction === DIRECTION.NEXT) {
      currentIndex = findLastIndex(
        currentUpdates.updates,
        (update) => update.updateId === params.updateId
      )
    } else {
      currentIndex = findIndex(
        currentUpdates.updates,
        (update) => update.updateId === params.updateId
      )
    }
    return currentIndex
  }

  const getUpdatesByCompany = async () => {
    try {
      const updates = await MixedUpdatesService.fetchMixedUpdates({
        entityType:
          entity.name === EntityName.PORTFOLIOS
            ? UpdateEntityType.PORTFOLIO_COMPANIES
            : UpdateEntityType.COMPANY,
        entityId: entity.id,
        page: currentUpdates.page + 1,
      })

      return updates.filter(isIndexUpdate)
    } catch (error) {
      Toast.displayIntl(
        [
          'errors.fetchError',
          { item: intl.formatMessage({ id: 'general.update' }) },
        ],
        'error'
      )
      return []
    }
  }

  const onClickNavigationToUpdate = (direction: string) => {
    let updateIndex: Nullable<number> = null
    updateIndex = getUpdateIndex(direction)
    if (direction === DIRECTION.NEXT) {
      updateIndex += 1
      setDisablePreviousButton(false)
      if (updateIndex >= currentUpdates.updates.length - 1) {
        setDisableNextButton(true)
      } else {
        setDisableNextButton(false)
      }
    } else if (direction === DIRECTION.PREVIOUS) {
      setDisableNextButton(false)
      updateIndex -= 1
      if (updateIndex <= 0) {
        setDisablePreviousButton(true)
      } else {
        setDisablePreviousButton(false)
      }
    }
    const newUpdate = currentUpdates.updates[updateIndex]

    if (newUpdate) {
      handleUpdateNavigation({ update: newUpdate }).redirectFunction()
    }
  }

  useEffect(() => {
    const checkCurrentUpdate = async () => {
      const updateIndex = getUpdateIndex()

      if (updateIndex + 1 === currentUpdates?.updates?.length) {
        const newUpdates = await getUpdatesByCompany()
        if (newUpdates.length) {
          dispatch(
            setCurrentUpdates({
              page: currentUpdates.page + 1,
              updates: [
                ...currentUpdates.updates,
                ...parseUpdatesForNavigation(newUpdates),
              ],
            })
          )
        } else {
          setDisableNextButton(true)
        }
      }

      setDisablePreviousButton(!updateIndex)
      setDisableNextButton(updateIndex + 1 >= currentUpdates.updates.length)
    }

    checkCurrentUpdate()
  }, [])

  const onClickBackIcon = () => {
    if (locationState.canGoBack) history.goBack()

    if (locationState.investor) {
      return history.replace(
        `/investor-management/investors/${locationState.investor.id}/profile`
      )
    }

    if (locationState.investmentVehicle) {
      return history.replace(
        `/investor-management/vehicles/${locationState.investmentVehicle.id}/profile`
      )
    }

    if (locationState.portfolio) {
      return history.replace(`/portfolios/${locationState.portfolio.id}`)
    }

    if (locationState.company) {
      if (isCompanyHolding(locationState.company)) {
        return history.replace(`/companies/${locationState.company.id}`)
      }
      return history.replace(`/funds/${locationState.company.id}`)
    }

    return history.replace(`/updates`)
  }

  return {
    isLoading,
    disableNextButton,
    disablePreviousButton,

    onClickNavigationToUpdate,
    onClickBackIcon,
    locationState,
  }
}
