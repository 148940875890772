import { BaseSyntheticEvent, forwardRef, Ref } from 'react'
import { ReactionSelector as StreamReactionSelector } from 'stream-chat-react'
import { reactions } from './reactions'
import { Position, ReactionSelectorContainer } from './ReactionSelector.styles'

interface Props {
  isVisible?: boolean
  position: Position
  isRelative?: boolean
  handleReaction?: (
    reactionType: string,
    event: BaseSyntheticEvent<object, any, any>
  ) => Promise<void>
}

const ReactionSelector = forwardRef(
  (
    { position, isRelative = true, isVisible = true, handleReaction }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    return (
      <ReactionSelectorContainer position={position} isRelative={isRelative}>
        {isVisible && (
          <StreamReactionSelector
            reactionOptions={reactions}
            ref={ref}
            handleReaction={handleReaction}
          />
        )}
      </ReactionSelectorContainer>
    )
  }
)

export default ReactionSelector
