import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const InputLabel = styled.label<{
  large?: boolean
  marginBottom?: string
  marginLeft?: string
}>`
  color: ${color('darkGray')};
  display: block;
  font-size: ${(props) => (props.large ? '1.4rem' : '1.2rem')};
  font-weight: bold;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '1rem'};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0.8rem')};
`

export const Label = styled.p<{ marginLeft?: string }>`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  display: flex;
  align-items: center;
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '1rem')};
`

export const ReadOnlyWrapper = styled.div`
  @media ${maxSize.md} {
    max-width: 29.5rem;
  }
`
