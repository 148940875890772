import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import Modal from 'components/Modal'
import Button from 'ui/Button'
import { INVESTORS_PAGE_LINK } from 'utils/constants/links'
import { CREATE_NEW_GROUP_URL_PREFIX } from 'containers/Signup/context/CreateNewGroupContextProvider'

import Item from './components/Item'
import { Gif } from './UpgradeYourAccountModal.styles'
import gifImage from './assets/upgrade-your-account.gif'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const UpgradeYourAccountModal = ({ isOpen, onClose }: Props) => {
  const intl = useIntl()
  const history = useHistory()

  const handleLearnMore = () => {
    window.open(INVESTORS_PAGE_LINK, '_blank')
  }

  const handleUpgrade = () => {
    history.push(CREATE_NEW_GROUP_URL_PREFIX)
    onClose()
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header onHide={onClose} isSecondary backgroundColor="green">
        <Modal.Title isSecondary>
          {intl.formatMessage({ id: 'common.upgradeYourAccount' })}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body padding="3.2rem 0" widthOverflow>
        <Item
          textId="upgradeYourAccountModal.subtitle"
          noCheck
          textColor="strongBlack"
        />
        <Gif src={gifImage} alt="gif" />
        <Item textId="upgradeYourAccountModal.item1" />
        <Item textId="upgradeYourAccountModal.item2" />
        <Item textId="upgradeYourAccountModal.item3" />
        <Item textId="upgradeYourAccountModal.item4" />
        <Item textId="upgradeYourAccountModal.item5" />
        <Item textId="upgradeYourAccountModal.item6" noMarginBottom />
      </Modal.Body>

      <Modal.RowFooter>
        <Button secondary onClick={handleLearnMore}>
          {intl.formatMessage({ id: 'common.learnMore' })}
        </Button>
        <Button tertiary onClick={handleUpgrade}>
          {intl.formatMessage({ id: 'common.upgrade' })}
        </Button>
      </Modal.RowFooter>
    </Modal>
  )
}

export default UpgradeYourAccountModal
