import {
  SkeletonIcon,
  SkeletonItem,
  SkeletonItemWrapper,
} from '../Skeletons.styles'

const InvestPortfolioChartResumeSkeleton = () => {
  return (
    <SkeletonItemWrapper direction="column" marginLeft="3.5rem">
      <SkeletonItemWrapper>
        <SkeletonIcon width="1rem" height="1rem" />
        <SkeletonItem height="1.2rem" width="12rem" />
      </SkeletonItemWrapper>
      <SkeletonItemWrapper>
        <SkeletonIcon width="1rem" height="1rem" />
        <SkeletonItem height="1.2rem" width="12rem" />
      </SkeletonItemWrapper>
      <SkeletonItemWrapper>
        <SkeletonIcon width="1rem" height="1rem" />
        <SkeletonItem height="1.2rem" width="12rem" />
      </SkeletonItemWrapper>
    </SkeletonItemWrapper>
  )
}

export default InvestPortfolioChartResumeSkeleton
