import styled from 'styled-components/macro'
import { css } from 'styled-components'
import ButtonToStyle from 'ui/Button'
import FadeIn from 'components/FadeIn'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'
import { SIDE_BAR_WIDTH } from 'layouts/SideBarComponents/SideBar'

export const Container = styled(FadeIn)`
  position: fixed;
  z-index: 7;
  bottom: 2.4rem;
  width: calc(100% - 4.8rem - ${SIDE_BAR_WIDTH});
  left: calc(${SIDE_BAR_WIDTH} + 2.4rem);
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  background-color: ${color('cloud')};
  box-shadow: 0px 0px 15px 0px ${color('darkBlue', 0.1)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.6rem;

  @media ${maxSize.lg} {
    width: calc(100% - 4.8rem);
    left: 2.4rem;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const Button = styled(ButtonToStyle)<{ discard?: boolean }>`
  padding: 0.8rem 1.6rem;
  ${({ discard }) =>
    discard &&
    css`
      color: ${color('red')};
    `}
`

export const Selected = styled.div`
  display: flex;
  font-size: 1.4rem;
  gap: 0.6rem;
  color: ${color('darkGray')};
  align-items: center;
`

export const SelectedCount = styled.div`
  display: flex;
  padding: 0.3rem 0.8rem;
  align-items: center;
  border-radius: 1rem;
  background: ${color('primaryBlue')};
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.036rem;
  color: white;
`
