import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Slider from 'react-slick'

import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import ReactSlickWrapper from 'components/ReactSlickWrapper/ReactSlickWrapper'
import CWLoader from 'components/CWLoader'
import * as Styles from './FundsList.styles'
import {
  SLIDER_CONTAINER_ID,
  SLIDER_SETTINGS,
  useFundsList,
} from './useFundsList'

interface PublicFundsListProps {
  fundCompanyId: string
}

const FundsList: React.FC<PublicFundsListProps> = ({ fundCompanyId }) => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const { funds, isLoading, renderFund, checkArrowsVisibility } =
    useFundsList(fundCompanyId)

  if (isLoading) {
    return (
      <CWLoader
        text={intl.formatMessage({ id: 'profiles.loadingFunds' })}
        logoSize="4rem"
      />
    )
  }

  if (!funds.length) {
    return (
      <Styles.NoFundsContainer>
        <Styles.NoFundsText>
          <FormattedMessage id="profiles.noFunds" />
        </Styles.NoFundsText>
      </Styles.NoFundsContainer>
    )
  }

  if (isMobile) {
    return <>{funds.map((fund) => renderFund(fund))}</>
  }

  return (
    <Styles.SliderContainer id={SLIDER_CONTAINER_ID}>
      <ReactSlickWrapper>
        <Slider
          afterChange={checkArrowsVisibility}
          onReInit={checkArrowsVisibility}
          {...SLIDER_SETTINGS}
        >
          {funds.map((fund) => (
            <Styles.FundContainer key={fund.id}>
              {renderFund(fund)}
            </Styles.FundContainer>
          ))}
        </Slider>
      </ReactSlickWrapper>
    </Styles.SliderContainer>
  )
}

export default FundsList
