import { FormattedMessage } from 'react-intl'
import { Channel } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'

import Tooltip from 'components/Tooltip'
import TooltipContent from 'containers/Chat/components/TooltipContent'
import {
  useTypingIndicator,
  Dots,
} from 'containers/Chat/components/TypingIndicator'
import CountBadge from 'containers/Chat/components/CountBadge'
import { useChatMobileContext } from 'containers/Chat/components/ChatMobile'
import {
  StreamChatType,
  TopicChannel as TopicChannelType,
} from 'containers/Chat/types'
import {
  useChatTitle,
  useSetActiveChannel,
  useTypingListener,
} from 'containers/Chat/hooks'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import { closeChatPopupById } from 'features/chatSlice'

import TopicChannelMessage from '../TopicChannelMessage'
import {
  ChannelContainer,
  ChannelTitle,
  ChannelTitleContainer,
  ChannelUpdate,
  Message,
  MessageContainer,
  MessageTimestamp,
  MutedIndicator,
  NameAndMutedContainer,
  TypingIndicatorContainer,
  TypingText,
} from './TopicChannel.styles'

interface TopicChannelProps {
  channel: Channel<StreamChatType<TopicChannelType>>
}

const TopicChannel = ({ channel }: TopicChannelProps) => {
  const dispatch = useAppDispatch()
  const { channel: activeTopic } =
    useChatContext<StreamChatType<TopicChannelType>>()
  const setActiveChannel = useSetActiveChannel<TopicChannelType>()
  const chatTitle = useChatTitle(channel)
  const { onTopicChannelClick } = useChatMobileContext()
  const { matches: disableIsSelectedFeature } = useMediaQuery(maxSize.mobile)

  const channelData = channel.data
  const lastMessage = channel.lastMessage()
  const isSelected = !disableIsSelectedFeature && channel.id === activeTopic?.id

  const { typing } = useTypingListener<TopicChannelType>(channel)
  const typingText = useTypingIndicator(true, typing)

  const unreadCount = channel.countUnread()

  const handleChannelClick = () => {
    setActiveChannel(channel)
    dispatch(closeChatPopupById(channel.id!))
    onTopicChannelClick()
  }

  return (
    <ChannelContainer
      key={channel.id}
      isSelected={isSelected}
      onMouseDown={handleChannelClick}
    >
      {channelData?.updateId && (
        <ChannelUpdate isSelected={isSelected}>
          <Tooltip
            offset={-10}
            place="top"
            backgroundColor="transparent"
            delayShow={500}
            text={
              <TooltipContent>
                <FormattedMessage id="common.from" />:{' '}
                {channelData?.updateTitle}
              </TooltipContent>
            }
          >
            <FormattedMessage id="common.from" />: {channelData?.updateTitle}
          </Tooltip>
        </ChannelUpdate>
      )}
      <ChannelTitleContainer>
        <NameAndMutedContainer>
          <Tooltip
            offset={-10}
            place="top"
            backgroundColor="transparent"
            delayShow={500}
            text={<TooltipContent>{chatTitle}</TooltipContent>}
          >
            <ChannelTitle>{chatTitle}</ChannelTitle>
          </Tooltip>
          <MutedIndicator
            isSelected={isSelected}
            channel={channel as Channel<StreamChatType>}
          />
        </NameAndMutedContainer>
        {lastMessage && (
          <MessageTimestamp showToday={false} message={lastMessage} />
        )}
      </ChannelTitleContainer>
      {typingText ? (
        <TypingIndicatorContainer>
          <Dots color={isSelected ? 'white' : undefined} />
          <TypingText>{typingText}</TypingText>
        </TypingIndicatorContainer>
      ) : (
        <MessageContainer>
          <Message>
            <TopicChannelMessage
              message={lastMessage}
              isChannelSelected={isSelected}
            />
          </Message>
          <CountBadge unreadCount={unreadCount} perfectCircle />
        </MessageContainer>
      )}
    </ChannelContainer>
  )
}

export default TopicChannel
