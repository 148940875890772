import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { SideBarTitle } from '../SideBar/SideBar.styles'
import {
  BackIcon,
  SecondarySideBarContent,
  SecondarySidebarContainer,
  SecondarySidebarTitle,
  SideBarTitleContainer,
} from './SecondarySideBar.styles'

interface SecondarySideBarProps {
  title: string
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}

const SecondarySideBar = ({
  title,
  isOpen = false,
  onClose,
  children,
}: SecondarySideBarProps) => {
  const intl = useIntl()

  return (
    <SecondarySidebarContainer open={isOpen}>
      <SecondarySideBarContent open={isOpen}>
        {isOpen && (
          <>
            <SideBarTitleContainer>
              <SideBarTitle>{title}</SideBarTitle>
              <SecondarySidebarTitle>{title}</SecondarySidebarTitle>
              <BackIcon type="button" onClick={onClose}>
                <>
                  <FontAwesomeIcon icon={['fal', 'arrow-left']} />
                  {intl.formatMessage({ id: 'general.backText' })}
                </>
              </BackIcon>
            </SideBarTitleContainer>
            {children}
          </>
        )}
      </SecondarySideBarContent>
    </SecondarySidebarContainer>
  )
}

export default SecondarySideBar
