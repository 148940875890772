/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useUpdatesFilter } from 'utils/hooks/useUpdatesFilter'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { UpdateUrlSuffix } from 'utils/hooks/useShowUpdateRoutes/types'
import { IndexUpdate } from 'utils/types/update'
import { useUpdatesFiltersContext } from 'containers/UpdatesView/UpdatesFiltersContext'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'

export const usePortfolioUpdates = (isSearchByTagAllowed = true) => {
  const { setFilters } = useUpdatesFiltersContext()
  const { filterDataSet } = useUpdatesFilter(isSearchByTagAllowed)
  const intl = useIntl()
  const portfolio = useAppSelector(getCurrentPortfolio)
  const { matches: isMobile } = useMediaQuery(Media.MOBILE)

  const { handleOnUpdateClick } = useOnClickRedirect()

  const handleFiltersChange = useCallback(async (activeFilters) => {
    setFilters({
      types: activeFilters
        ?.filter((filter) => filter.dataSetName === 'type')
        .map((filter) => ({
          id: filter.name,
          label: filter.name,
          value: filter.value,
        }))
        .flat(),
      tags: activeFilters
        ?.filter((filter) => filter.dataSetName === 'tag')
        .map((filter) => ({
          id: filter.value,
          label: filter.value,
        }))
        .flat(),
      searchText: activeFilters
        ?.filter((filter) => filter.dataSetName === 'keyword')
        .map((filter) => filter.value)?.[0],
    })
  }, [])

  const onClickUpdate = (suffix?: UpdateUrlSuffix) => (update: IndexUpdate) => {
    return handleOnUpdateClick({
      update,
      updateSuffix: suffix,
    }).redirectFunction()
  }

  return {
    filterDataSet,
    portfolio,
    handleFiltersChange,
    onClickUpdate,
    intl,
    isMobile,
  }
}
