import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { MetricsMode } from 'utils/types/metrics'

import EditInvestorMetric from './EditInvestorMetric'
import EditFounderMetric from './EditFounderMetric'

interface Props {
  isOpen: boolean
  closeDrawer: () => void
}

const EditMetric = ({ closeDrawer, isOpen }: Props) => {
  const metricsMode = useMetricsMode()

  if (metricsMode === MetricsMode.FOUNDER) {
    return <EditFounderMetric isOpen={isOpen} closeDrawer={closeDrawer} />
  }

  return <EditInvestorMetric isOpen={isOpen} closeDrawer={closeDrawer} />
}

export default EditMetric
