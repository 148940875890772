/* eslint-disable react-hooks/exhaustive-deps */
import { resetCurrentCompany } from 'actions/companies'
import {
  getCurrentInvestmentVehicle,
  getCurrentInvestor,
  setCurrentInvestmentVehicle as setCurrentInvestmentVehicleAction,
  setCurrentInvestor as setCurrentInvestorAction,
} from 'features/investorManagementSlice'
import { useEffect } from 'react'
import { InvestmentVehicle, Investor } from 'utils/types/investors'
import { setCurrentPortfolio } from '../../actions/portfolios'
import { useAppDispatch, useAppSelector } from './reduxToolkit'

export const useCurrentInvestorManagementEntities = () => {
  const dispatch = useAppDispatch()

  const currentInvestor = useAppSelector(getCurrentInvestor)
  const currentInvestmentVehicle = useAppSelector(getCurrentInvestmentVehicle)

  const setCurrentInvestor = (investor: Investor) => {
    dispatch(setCurrentInvestorAction(investor))
  }
  const removeCurrentInvestor = () => {
    dispatch(setCurrentInvestorAction(null))
  }

  const setCurrentInvestmentVehicle = (
    investmentVehicle: InvestmentVehicle
  ) => {
    dispatch(setCurrentInvestmentVehicleAction(investmentVehicle))
  }
  const removeCurrentInvestmentVehicle = () => {
    dispatch(setCurrentInvestmentVehicleAction(null))
  }

  return {
    currentInvestor,
    currentInvestmentVehicle,
    setCurrentInvestor,
    removeCurrentInvestor,
    setCurrentInvestmentVehicle,
    removeCurrentInvestmentVehicle,
  }
}

export const useSetCurrentInvestor = (investor?: Investor) => {
  const dispatch = useAppDispatch()
  const { setCurrentInvestor, removeCurrentInvestor } =
    useCurrentInvestorManagementEntities()

  useEffect(() => {
    if (investor) {
      dispatch(resetCurrentCompany())
      dispatch(setCurrentPortfolio({}))
      setCurrentInvestor(investor)
    }
    return () => {
      removeCurrentInvestor()
    }
  }, [investor])
}

export const useSetCurrentInvestmentVehicle = (
  investmentVehicle?: InvestmentVehicle
) => {
  const dispatch = useAppDispatch()

  const { setCurrentInvestmentVehicle, removeCurrentInvestmentVehicle } =
    useCurrentInvestorManagementEntities()

  useEffect(() => {
    if (investmentVehicle) {
      dispatch(resetCurrentCompany())
      dispatch(setCurrentPortfolio({}))
      setCurrentInvestmentVehicle(investmentVehicle)
    }
    return () => {
      removeCurrentInvestmentVehicle()
    }
  }, [investmentVehicle])
}
