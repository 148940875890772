import { FieldArray, ErrorMessage } from 'formik'
import { useIntl } from 'react-intl'
import type { FieldArrayRenderProps } from 'formik'

import Chip from 'components/Chip'
import { useField, useFormikContext } from 'components/Form/hooks'
import Input from 'ui/Input'
import Dropdown from 'ui/Dropdown'
import { Optional } from 'ui/InputLabel/InputLabel.styles'
import { Holding, isCompanyHolding } from 'utils/types/company'
import theme from 'utils/theme'

import {
  AddInputContainer,
  Companies,
  Container,
  HoldingsDealsErrorMessage,
  Label,
  Name,
} from './CreatePortfolioBody.styles'

interface CreatePortfolioBodyProps {
  isFundManager: boolean
  loadHoldings: (searchValue: string) => Promise<Holding[]>
  checkIfPortfolioNameExists: (name: string) => Promise<boolean>
}

const CreatePortfolioBody = ({
  isFundManager,
  loadHoldings,
  checkIfPortfolioNameExists,
}: CreatePortfolioBodyProps) => {
  const { handleBlur } = useFormikContext()
  const [nameField] = useField('name')
  const [holdingsField] = useField('holdings')
  const intl = useIntl()

  const onSelectOption = (arrayHelpers: FieldArrayRenderProps) => {
    return (_, __, value) => {
      if (!holdingsField.value.find((holding) => holding.id === value.id)) {
        arrayHelpers.push(value)
      }
    }
  }

  return (
    <Container>
      <Name>
        <Input
          type="text"
          name="name"
          onChange={nameField.onChange}
          label={intl.formatMessage({ id: 'createPortfolio.portfolioName' })}
          placeholder={
            isFundManager
              ? intl.formatMessage({
                  id: 'createPortfolio.fundPortfolioNamePlaceholder',
                })
              : intl.formatMessage({
                  id: 'createPortfolio.portfolioNamePlaceholder',
                })
          }
          error={nameField.buildError()}
          onBlur={(e) => {
            nameField.onBlur(e)
            checkIfPortfolioNameExists(nameField.value)
          }}
          withCounter
          counterSubtractNumber={nameField.value.length}
          simulateIconSpace
          counterStyle={{
            right: '2rem',
            top: '1.2rem',
            color: theme.colors.darkGray,
            opacity: 0.5,
          }}
          extraPaddingRight={1}
        />
      </Name>

      <FieldArray name="holdings">
        {(arrayHelpers) => {
          return (
            <>
              <AddInputContainer>
                <Label>
                  {intl.formatMessage({ id: 'createPortfolio.addHoldings' })}
                  <Optional>
                    {intl.formatMessage({ id: 'createPortfolio.optional' })}
                  </Optional>
                </Label>

                <Dropdown
                  id="company-add"
                  name="holdings"
                  loadOptions={loadHoldings}
                  onSelectOption={onSelectOption(arrayHelpers)}
                  placeholder={intl.formatMessage({
                    id: 'createPortfolio.lookHoldings',
                  })}
                  onBlur={handleBlur}
                  async
                  type="input"
                  icon={['far', 'plus']}
                  iconFontSize="1.8rem"
                  getOption={(entity) => ({
                    label: entity.name,
                    id: entity.id,
                  })}
                  clearOnSelect
                />
              </AddInputContainer>
              {!!holdingsField.value?.length && (
                <Companies>
                  {holdingsField.value.map((holding, index) => (
                    <Chip
                      key={holding.id}
                      image={
                        isCompanyHolding(holding)
                          ? holding.primaryLogo?.url
                          : holding.group?.logo?.url
                      }
                      text={holding.name}
                      handleDelete={arrayHelpers.handleRemove(index)}
                    />
                  ))}
                </Companies>
              )}

              <ErrorMessage
                name="holdings"
                component={HoldingsDealsErrorMessage}
              />
            </>
          )
        }}
      </FieldArray>
    </Container>
  )
}

export default CreatePortfolioBody
