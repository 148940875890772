import { useChatContext } from 'stream-chat-react'

import {
  StreamChatType,
  TopicChannel as TopicChannelType,
} from 'containers/Chat/types'
import {
  useIsThreadOpen,
  useForceUpdateOnChatUserUpdated,
} from 'containers/Chat/hooks'
import { usePinChannels } from 'containers/Chat/hooks/usePinChannels'
import IconButton from 'ui/IconButton'
import theme from 'utils/theme'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { ChatBreakpointSize } from 'utils/constants/chat'
import { maxSize } from 'utils/constants/breakpoint'
import { buildTopicEntityFromChannel } from 'utils/types/chatTopicEntity'

import {
  Container as ChatHeaderContainer,
  LeftContainer as ChatHeaderLeftContainer,
} from '../ChatHeader/ChatHeader.styles'
import {
  ChannelList,
  Container,
  CreateChannel,
  IconsContainer,
  StyledLink,
} from './TopicSidebar.styles'
import TopicChannel from './components/TopicChannel'
import CreateChatModal from '../CreateChat'
import ChannelDropdown from '../ChannelDropdown'
import { useTopic } from './useTopic'
import { MobileNavigationArrow } from '../ChatMobile/ChatMobile.styles'
import { useChatMobileContext } from '../ChatMobile'

const TopicSidebar = () => {
  const { channel: activeTopic } =
    useChatContext<StreamChatType<TopicChannelType>>()
  const { isAChannel, channels, isPinned, topicUrl, createChatModal } =
    useTopic(activeTopic, true)
  const { togglePinTopic } = usePinChannels()
  const { matches: showChannelDropdown } = useMediaQuery(
    ChatBreakpointSize.tablet
  )
  const { matches: isMobile } = useMediaQuery(maxSize.mobile)
  const isThreadOpen = useIsThreadOpen()
  const { onGoToSidebar } = useChatMobileContext()

  useForceUpdateOnChatUserUpdated()

  if (!activeTopic || !isAChannel) {
    return null
  }

  return (
    <>
      <Container>
        <ChatHeaderContainer preventResponsive isThreadOpen={isThreadOpen}>
          <ChatHeaderLeftContainer>
            <MobileNavigationArrow
              onClick={onGoToSidebar}
              icon={['fal', 'chevron-down']}
            />
            <StyledLink to={topicUrl}>
              {activeTopic?.data?.topicName}
            </StyledLink>
          </ChatHeaderLeftContainer>
          <IconsContainer>
            {!isMobile && (
              <IconButton
                onClick={createChatModal.onShow}
                iconFamily="far"
                icon="plus"
                buttonType="square"
                buttonSize="3.2rem"
                color={theme.colors.black}
                border={false}
                borderOnFocus={false}
                primary
              />
            )}
            <IconButton
              onClick={() => togglePinTopic(activeTopic.data?.entityId!)}
              iconFamily={isPinned ? 'fas' : 'far'}
              icon="thumbtack"
              buttonType="square"
              buttonSize="3.2rem"
              color={isPinned ? theme.colors.primaryBlue : theme.colors.black}
              border={false}
              borderOnFocus={false}
              primary
            />
          </IconsContainer>
        </ChatHeaderContainer>
        {showChannelDropdown ? (
          <ChannelDropdown<TopicChannelType>
            channels={channels}
            Channel={TopicChannel}
          />
        ) : (
          <ChannelList>
            {channels.map((channel) => (
              <TopicChannel key={channel.id} channel={channel} />
            ))}
          </ChannelList>
        )}
        {isMobile && (
          <CreateChannel
            createChannelIntlId="chat.addConversation"
            onClick={createChatModal.onShow}
          />
        )}
      </Container>

      <CreateChatModal
        show={createChatModal.show}
        onHide={createChatModal.onHide}
        initialValues={{
          members: [],
          topic: buildTopicEntityFromChannel(activeTopic.data!),
        }}
      />
    </>
  )
}

export default TopicSidebar
