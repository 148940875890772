import React from 'react'
import { FormattedNumber } from 'react-intl'
import { Grid } from 'react-virtualized'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'

import { displayThousands, withDecimals } from 'utils/functions/number'
import GrowthIndicator from 'components/Dashboard/GrowthIndicator'
import GrowthValue from 'components/Dashboard/GrowthIndicator/GrowthValue'
import { DATE_FORMATS } from 'utils/functions/date'
import * as Styles from './MetricDataPoints.styles'
import {
  MetricDataPointsProps,
  useMetricDataPoints,
} from './useMetricDataPoints'

const MAX_DISPLAYABLE_VALUE = 10_000_000

const MetricDataPoints: React.FC<MetricDataPointsProps> = ({
  dataPoints,
  width,
}) => {
  const {
    gridRef,
    getListWidth,
    onScroll,
    scrollLeft,
    onSectionRendered,
    goLeft,
    goRight,
    firstValueVisible,
    lastValueVisible,
  } = useMetricDataPoints({ dataPoints, width })

  return (
    <Styles.ValuesContainer>
      <Styles.ArrowContainer isLeftArrow>
        <Styles.Arrow onClick={goLeft} hidden={firstValueVisible} isLeftArrow>
          <FontAwesomeIcon icon={['fal', 'chevron-left']} />
        </Styles.Arrow>
      </Styles.ArrowContainer>
      <Styles.Values>
        <Grid
          ref={gridRef}
          height={Styles.METRIC_VALUE_HEIGHT}
          columnCount={dataPoints.length}
          columnWidth={Styles.METRIC_VALUE_WIDTH}
          rowCount={1}
          rowHeight={Styles.METRIC_VALUE_HEIGHT}
          width={getListWidth()}
          onScroll={onScroll}
          scrollLeft={scrollLeft}
          onSectionRendered={onSectionRendered}
          cellRenderer={({ columnIndex, style, key }) => {
            const dataPoint = dataPoints[columnIndex]
            const lastDataPoint = dataPoints[columnIndex - 1]

            const difference =
              (dataPoint?.value ?? 0) - (lastDataPoint?.value ?? 0)

            return (
              <Styles.DataPointContainer
                key={key}
                className="fs-exclude"
                style={style}
              >
                <Styles.DataPoint>
                  <Styles.DataPointDate>
                    {dayjs(dataPoint.date).format(DATE_FORMATS.DDMM__YYYY)}
                  </Styles.DataPointDate>
                  <Styles.DataPointValue>
                    {dataPoint.value >= MAX_DISPLAYABLE_VALUE ? (
                      displayThousands(withDecimals(dataPoint.value))
                    ) : (
                      <FormattedNumber value={dataPoint.value} />
                    )}
                  </Styles.DataPointValue>
                  {!!lastDataPoint && (
                    <Styles.GrowthContainer>
                      <GrowthIndicator
                        direction={difference > 0 ? 'up' : 'down'}
                      />
                      <GrowthValue
                        growth={difference > 0}
                        value={displayThousands(withDecimals(difference))}
                      />
                    </Styles.GrowthContainer>
                  )}
                </Styles.DataPoint>
              </Styles.DataPointContainer>
            )
          }}
        />
      </Styles.Values>
      <Styles.ArrowContainer>
        <Styles.Arrow onClick={goRight} hidden={lastValueVisible}>
          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
        </Styles.Arrow>
      </Styles.ArrowContainer>
    </Styles.ValuesContainer>
  )
}

export default MetricDataPoints
