import CWLoader from 'components/CWLoader'
import { StreamChatType } from 'containers/Chat/types'
import type { Channel, MessageResponse } from 'stream-chat'
import { MessageSearchResult } from 'utils/hooks/chat/chatSearchQueries'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import ResultMessage from './ResultMessage/ResultMessage'
import {
  ChannelContainer,
  ChannelMessages,
  ChannelName,
  SearchMessageResultsContainer,
  ZeroStateContainer,
} from './SearchResults.styles'
import { getChannelAvatar, getChannelName } from './ChannelRow'

type Props = {
  messages: MessageSearchResult
  isLoading: boolean
  onSelectChannel: (channel: Channel<StreamChatType>) => void
  onSelectMessage: (
    message: MessageResponse<StreamChatType>,
    channel: Channel<StreamChatType>
  ) => void
  searchTexts: string[]
  plainSearch: string
}

const SearchMessagesResults = ({
  messages,
  onSelectChannel,
  onSelectMessage,
  isLoading,
  searchTexts,
  plainSearch,
}: Props) => {
  useHighlightText(
    {
      elementClass: '.highlight-message',
      text: searchTexts,
    },
    [searchTexts, isLoading, messages]
  )

  if (isLoading) {
    return <CWLoader />
  }

  if (messages.length === 0) {
    return (
      <ZeroStateContainer>
        <ZeroState
          textFontSize="1.6rem"
          titleFontSize="1.8rem"
          infoMarginTop="-0.7rem"
          type={ZeroStateType.CHAT_SEARCH_RESULTS}
          intlValues={{ search: plainSearch }}
        />
      </ZeroStateContainer>
    )
  }

  return (
    <SearchMessageResultsContainer>
      {messages.map(({ key: channel, values: messagesForChannel }) => {
        return (
          <ChannelMessages key={channel.id}>
            <ChannelContainer>
              {getChannelAvatar(channel)}
              <ChannelName onClick={() => onSelectChannel(channel)}>
                {getChannelName(channel)}
              </ChannelName>
            </ChannelContainer>
            {messagesForChannel.map((message) => (
              <ResultMessage
                key={message.id}
                message={message}
                onClick={() => onSelectMessage(message, channel)}
              />
            ))}
          </ChannelMessages>
        )
      })}
    </SearchMessageResultsContainer>
  )
}

export default SearchMessagesResults
