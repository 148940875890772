import { Helmet } from 'components/Helmet'
import Dashboard from 'components/Dashboard'
import { UpdatesFiltersProvider } from 'containers/UpdatesView/UpdatesFiltersContext'
import { InvestorGroupDashboardContainer } from './InvestorGroupDashboard.styles'
import CapitalStatement from './components/CapitalStatement'
import FundSummary from './components/FundSummary'
import Updates from './components/Updates'
import useInvestorGroupDashboard from './useInvestorGroupDashboard'

const InvestorGroupDashboard = () => {
  const { intl } = useInvestorGroupDashboard()

  return (
    <>
      <Helmet>
        <title>{`${intl.messages['metaTitles.dashboard']}`}</title>
      </Helmet>
      <InvestorGroupDashboardContainer>
        <Dashboard.Grid>
          <Dashboard.Row>
            <Dashboard.Item>
              <CapitalStatement />
            </Dashboard.Item>
          </Dashboard.Row>

          <Dashboard.Row>
            <Dashboard.Item>
              <FundSummary />
            </Dashboard.Item>
          </Dashboard.Row>

          <UpdatesFiltersProvider>
            <Updates />
          </UpdatesFiltersProvider>
        </Dashboard.Grid>
      </InvestorGroupDashboardContainer>
    </>
  )
}

export default InvestorGroupDashboard
