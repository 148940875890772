import { useIntl } from 'react-intl'
import Card from 'components/Card'

import { CompanyHoldingData } from 'utils/types/company'
import HeaderBasicInformation from './CompanyHeader/HeaderBasicInformation'
import HeaderAdditionalInformation from './CompanyHeader/HeaderAdditionalInformation'
import {
  HeaderInfoWrapper,
  SideCardWrapper,
  SideInformationWrapper,
} from './CompanyInformation.styles'
import CompanyDescription from './CompanyDescription/CompanyDescription'
import CompanyKeyPeople from './CompanyKeyMembers/CompanyKeyMembers'

interface CompanyInformationProps {
  companyInfo: CompanyHoldingData
}

const CompanyInformation = ({ companyInfo }: CompanyInformationProps) => {
  const intl = useIntl()

  return (
    <SideInformationWrapper>
      <HeaderInfoWrapper>
        <HeaderBasicInformation
          companyName={companyInfo.name}
          companyImage={companyInfo.primaryLogo.url}
          isVerified={companyInfo.verified}
          companyInfo={companyInfo}
        />
        <HeaderAdditionalInformation companyInfo={companyInfo} />
      </HeaderInfoWrapper>

      {companyInfo.description && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'companyProfile.description' })}
            </Card.Header>
            <Card.Body>
              <CompanyDescription content={companyInfo.description} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}

      {!!companyInfo.employees.length && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'investorManagement.keyPeople' })}
            </Card.Header>
            <Card.Body>
              <CompanyKeyPeople content={companyInfo.employees} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}
    </SideInformationWrapper>
  )
}

export default CompanyInformation
