export const ImageSize = {
  SMALL: 'small',
  LARGE: 'large',
}

export const TextAlign = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
}

export const HEADER_SECTION = 'HEADER'
