import styled from 'styled-components'
import theme from 'utils/theme'
import { hexToRGB } from 'utils/functions/colors'

const PulseIndicator = styled.div<{
  animationName?: string
}>`
  @keyframes ${(props) => props.animationName} {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 ${(props) => hexToRGB(props.color, 0.7)};
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 0.5rem ${(props) => hexToRGB(props.color, '0')};
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0);
    }
  }

  background: ${(props) => props.color};
  border-radius: 50%;
  box-shadow: 0 0 0 0 ${(props) => props.color};
  width: 0.8rem;
  height: 0.8rem;
  transform: scale(1);
  animation: ${(props) => props.animationName} 2s infinite;
`

PulseIndicator.defaultProps = {
  color: theme.colors.green,
  animationName: 'pulse',
}

export default PulseIndicator
