import Chip from 'components/Chip'
import { getImageComponent } from './Icons'
import { isOptionWithError } from '../useShareWithDropdown'
import * as Styles from '../ShareWithDropdown.styles'

export const SharedWithPill = ({ removeProps, ...props }) => {
  return (
    <Styles.PillWrapper
      withError={isOptionWithError(props.data)}
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
      }}
      onMouseDown={(event) => {
        event.stopPropagation()
        event.preventDefault()
      }}
      onTouchEnd={(event) => {
        event.stopPropagation()
        event.preventDefault()
      }}
    >
      <Chip
        canDelete
        isUnreachableEmail={isOptionWithError(props.data)}
        image={props.data.imageUrl}
        imageComponent={getImageComponent(
          props.data.type,
          props.data.name,
          props.data.imageUrl
        )}
        handleDelete={() => {
          removeProps.onClick()
        }}
        text={props.data.name}
      />
    </Styles.PillWrapper>
  )
}
