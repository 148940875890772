import { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Scroll = () =>
  css`
    overflow-y: auto;

    /* width & height */
    ::-webkit-scrollbar {
      width: 0.8rem;
      height: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 1rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${color('lightGray')};
      border-radius: 1rem;
      visibility: hidden;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  `

export const HorizontalScroll = (hideScrollbar: boolean) =>
  css`
    overflow-x: auto;

    /* height */
    ::-webkit-scrollbar {
      height: 0.8rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 1rem;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${hideScrollbar ? 'transparent' : color('lightGray')};
      border-radius: 1rem;
    }
  `
