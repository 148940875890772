import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

import {
  AuthHeaders,
  BillingAccount,
  GroupData,
  SetupAccount,
} from 'utils/types/signUp'
import { Group, GroupType, InvestorGroup, User } from 'utils/types/user'
import {
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import { GroupTypes } from 'utils/constants/groups'

interface Subscription {
  clientSecret?: string
  customerId?: string
  subscriptionId?: string
}

interface SignUpState {
  planId?: SubscriptionPlan
  billingPeriod?: SubscriptionBillingPeriod
  setupAccount?: SetupAccount
  groupName?: string
  billingAccount?: BillingAccount
  subscription?: Subscription
  authHeaders?: AuthHeaders
  groupType: GroupType
  user?: User
  groups?: Record<string, Group>
  investorGroups?: Record<string, InvestorGroup>
}

const initialState: SignUpState = {
  groupType: GroupTypes.CLIENT,
}

export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setPlanId: (state, action: PayloadAction<SubscriptionPlan>) => {
      state.planId = action.payload
    },
    setBillingPeriod: (
      state,
      action: PayloadAction<SubscriptionBillingPeriod>
    ) => {
      state.billingPeriod = action.payload
    },
    setSetupAccount: (state, action: PayloadAction<SetupAccount>) => {
      state.setupAccount = action.payload
    },
    setGroupData: (state, action: PayloadAction<GroupData>) => {
      state.groupName = action.payload.groupName
    },
    setBillingAccount: (state, action: PayloadAction<BillingAccount>) => {
      state.billingAccount = action.payload
    },
    setSubscription: (state, action: PayloadAction<Subscription>) => {
      state.subscription = action.payload
    },

    setGroupType: (state, action: PayloadAction<GroupType>) => {
      state.groupType = action.payload
    },

    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload
    },
    resetSignup: () => initialState,
  },
})

export const {
  setPlanId,
  setBillingPeriod,
  setSetupAccount,
  setGroupData,
  setBillingAccount,
  setSubscription,
  setGroupType,
  setUser,
  resetSignup,
} = signUpSlice.actions

export const getPlanId = (state: RootState): SubscriptionPlan | undefined =>
  state.signUp.planId

export const getBillingPeriod = (
  state: RootState
): SubscriptionBillingPeriod | undefined => state.signUp.billingPeriod

export const getSetupAccount = (state: RootState): SetupAccount | undefined =>
  state.signUp.setupAccount

export const getGroupData = (state: RootState): GroupData | undefined =>
  state.signUp.groupName
    ? {
        groupName: state.signUp.groupName,
      }
    : undefined

export const getBillingAccount = (
  state: RootState
): BillingAccount | undefined => state.signUp.billingAccount

export const getSubscription = (state: RootState): Subscription | undefined =>
  state.signUp.subscription

export const getGroupType = (state: RootState): GroupType | undefined =>
  state.signUp.groupType

export const getUser = (state: RootState): User | undefined => state.signUp.user

export default signUpSlice.reducer
