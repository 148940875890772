import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import { color } from 'utils/functions/colors'

export const SliderContainer = styled.div`
  padding: 0 1.6rem;
  height: 7rem;
`

export const FundContainer = styled.div`
  position: relative;
`

export const Fund = styled.div<{ isMobile: boolean }>`
  height: 7rem;
  margin: 0 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border: 1px ${color('veryLightGray')} solid;
  border-radius: 8px;
  padding: 1.6rem 2.4rem;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  :hover {
    padding: 1.5rem 2.3rem;
    border: 2px ${color('veryLightBlue')} solid;
    color: ${color('primaryBlue')};
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin-bottom: 1.6rem;
    `}
`

export const FundName = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const Arrow = styled.div`
  z-index: 3;

  &:before {
    display: none;
  }

  svg {
    width: 1.6rem !important;
    height: 1.6rem;
    color: black;
  }
`

export const NoFundsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  padding-bottom: 1.5rem;
`

export const NoFundsText = styled.div`
  font-size: 18px;
  color: ${color('lightGray')};
`

export const PrivateFundEye = styled.div`
  position: absolute;
  right: 2rem;
  bottom: 1rem;
`

export const EyeSlash = styled(FontAwesomeIcon)`
  font-size: 1.3rem;
  color: #000;
`
