import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color as colorFn } from 'utils/functions/colors'
import UpdateIconFactory from 'components/UpdateIconFactoryV2'
import { Color } from 'utils/theme'

export const UpdateIcon = styled(UpdateIconFactory)<{
  isSelected?: boolean
  isHover?: boolean
  color: Color
}>`
  background-color: white;
  color: ${(props) => colorFn(props.color)(props)};

  ${({ isHover, isSelected }) =>
    !isHover &&
    !isSelected &&
    css`
      border: 1px solid ${colorFn('veryLightGray')};
    `}
`

export const IconWrapper = styled.div`
  display: inline-flex;
  cursor: pointer;
  position: relative;
  margin-top: 0.7rem;
`
