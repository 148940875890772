import { ButtonWrapper } from 'components/Breadcrumb/UpdateActions/UpdateActions.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from 'utils/theme'
import { useIntl } from 'react-intl'
import TooltipActionWrapper from 'components/Breadcrumb/UpdateActions/Components/TooltipActionWrapper/TooltipActionWrapper'

interface Props {
  disabled?: boolean
  handleOnClick: () => void
}

const ReshareUpdateButton = ({ disabled, handleOnClick }: Props) => {
  const intl = useIntl()

  return (
    <TooltipActionWrapper
      id="reshare-update-action"
      text={intl.formatMessage({ id: 'updateActions.reshareUpdate' })}
    >
      <ButtonWrapper onClick={handleOnClick} disabled={disabled}>
        <FontAwesomeIcon
          color={theme.colors.darkBlue}
          icon={['fal', 'retweet']}
        />
      </ButtonWrapper>
    </TooltipActionWrapper>
  )
}

export default ReshareUpdateButton
