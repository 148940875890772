import React from 'react'
import { AccountingUpdate } from 'utils/types/update'
import { Integrations } from 'utils/constants/integrations'
import ShowQuickbooksReport from 'components/AccountingReports/ShowQuickbooksReport'
import ShowXeroReport from 'components/AccountingReports/ShowXeroReport'

interface ShowAccountingContentProps {
  update: AccountingUpdate
}
const ShowAccountingContent: React.FC<ShowAccountingContentProps> = ({
  update,
}) => {
  const accounting = update?.item
  const normalizedReportData = JSON.parse(accounting?.rawContent || '{}')

  const reportData =
    accounting?.integrationType === Integrations.XERO
      ? normalizedReportData?.reports?.[0]
      : normalizedReportData

  return update.item.integrationType === Integrations.XERO ? (
    <ShowXeroReport data={reportData} />
  ) : (
    <ShowQuickbooksReport
      reportType={accounting.reportType}
      data={reportData}
    />
  )
}

export default ShowAccountingContent
