import { UpdateTypes } from 'utils/constants/updates'
import { useFundPortfoliosByInvestorQuery } from 'utils/hooks/investorManagement/useFundPortfolioInvestorQuery'
import { useInvestorQuery } from 'utils/hooks/investorManagement/useInvestorsQuery'
import { useShowUpdateRoute } from 'utils/hooks/useShowUpdateRoutes/useShowUpdateRoute'
import { InvestorInvestmentVehicle } from 'utils/types/investors'
import { TransactionItem } from 'utils/types/update'
import { useSetCurrentInvestor } from 'utils/hooks/useCurrentInvestorManagementEntities'
import { UpdateUrlSuffix } from 'utils/hooks/useShowUpdateRoutes/types'

const useShowInvestor = (investorId: string) => {
  const showUpdateRoute = useShowUpdateRoute()

  const { data: investor, isLoading: isLoadingInvestor } =
    useInvestorQuery(investorId)

  useSetCurrentInvestor(investor)

  const { data: fundsPortfolioInvestor, isLoading: isLoadingFunds } =
    useFundPortfoliosByInvestorQuery(investorId)
  const vehicles: InvestorInvestmentVehicle[] =
    investor?.investmentVehicles || []

  const onShowTransaction = (transaction: TransactionItem) => {
    showUpdateRoute.fromInvestor({
      updateId: transaction.loggingUpdateId,
      updateType: UpdateTypes.TRANSACTION,
      investor: investor!,
    })
  }

  const onEditTransaction = (transaction: TransactionItem) => {
    showUpdateRoute.fromInvestor({
      updateId: transaction.loggingUpdateId,
      updateType: UpdateTypes.TRANSACTION,
      investor: investor!,
      suffix: UpdateUrlSuffix.EDIT,
    })
  }

  return {
    fundsPortfolioInvestor: fundsPortfolioInvestor || [],
    investor,
    isLoadingInvestor,
    isLoadingFunds,
    vehicles,
    onShowTransaction,
    onEditTransaction,
  }
}

export default useShowInvestor
