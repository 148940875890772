import React, { useRef } from 'react'
import { FieldArray, Formik } from 'formik'
import type { ArrayHelpers, FormikProps } from 'formik'
import FadeIn from 'components/FadeIn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import Input from 'ui/Input'
import UserService from 'api/UserService'

import { isValidEmail } from 'utils/functions/utils'
import { buildFormError } from 'utils/functions/forms'
import Chip from 'components/Chip'
import Separator from 'ui/Separator'
import {
  AddInvestorSendInvitesFormValues,
  InvestorGroupUser,
} from 'utils/types/investors'
import { getCreateInvestorInitialValues } from 'utils/constants/investorManagement'
import { getSendInvitesSchema } from 'utils/schemas/investorManagement'
import CustomTextArea from 'ui/TextArea/CustomTextArea'
import Button from 'ui/Button'
import { ErrorType } from 'utils/types/common'
import {
  ButtonsContainer,
  EmailItem,
  EmailList,
  Header,
  InviteInvestorGroupUserFormContainer,
} from './InviteInvestorGroupUserForm.styles'

interface InviteInvestorGroupUserFormProps {
  onAddUsers: (values: AddInvestorSendInvitesFormValues) => void
  onCancel: () => void
  currentUsers: InvestorGroupUser[]
}

const InviteInvestorGroupUserForm: React.FC<
  InviteInvestorGroupUserFormProps
> = ({ onAddUsers, onCancel, currentUsers }) => {
  const intl = useIntl()
  const inputEmailRef = useRef<HTMLInputElement>(null)

  const onAddEmail =
    (
      arrayHelpers: ArrayHelpers,
      formik: FormikProps<AddInvestorSendInvitesFormValues>
    ) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail = event.target.value || inputEmailRef.current?.value
      if (!newEmail) return

      const investorUserHasEmail = currentUsers.some(
        (user) => user.user.email === newEmail
      )

      const formUsersHasEmail = formik.values.users.some(
        (user) => user.email === newEmail
      )

      if (investorUserHasEmail || formUsersHasEmail) {
        formik.setFieldError(
          'users',
          intl.formatMessage({ id: 'investorManagement.duplicatedEmailError' })
        )

        return
      }

      if (isValidEmail(newEmail)) {
        const {
          data: { entities, result },
        } = await UserService.createUser({
          email: newEmail,
        })

        const newUser = entities?.users?.[result]
        arrayHelpers.push(newUser)
        inputEmailRef.current!.value = ''
      } else {
        formik.setFieldError(
          'users',
          intl.formatMessage({ id: 'investorManagement.emailFormatError' })
        )
      }
    }

  return (
    <FadeIn>
      <InviteInvestorGroupUserFormContainer>
        <Header>
          <FontAwesomeIcon icon={['far', 'envelope']} />
          <h1>
            {intl.formatMessage({ id: 'investorManagement.sendInvitation' })}
          </h1>
        </Header>

        <Formik<AddInvestorSendInvitesFormValues>
          initialValues={{
            ...getCreateInvestorInitialValues(intl).SEND_INVITATIONS,
            sendInvitation: true,
          }}
          onSubmit={onAddUsers}
          validationSchema={getSendInvitesSchema()}
        >
          {(formik) => {
            return (
              <>
                <FieldArray
                  name="users"
                  render={(arrayHelpers) => (
                    <>
                      <Input
                        ref={inputEmailRef}
                        name="emails"
                        onPressEnter={onAddEmail(arrayHelpers, formik)}
                        icon={['far', 'plus']}
                        iconFontSize="1.8rem"
                        label={intl.formatMessage({
                          id: 'investorManagement.editInvestor.emails',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'investorManagement.editInvestor.addEmail',
                        })}
                        error={buildFormError(
                          formik.errors.users as string,
                          ErrorType.ERROR,
                          true
                        )}
                      />

                      <EmailList>
                        {formik.values.users.map((user, index) => (
                          <EmailItem key={user.id}>
                            <Chip
                              text={user.name || user.email}
                              canDelete
                              handleDelete={arrayHelpers.handleRemove(index)}
                              maxWidth="22rem"
                              titleWithTooltip
                            />
                          </EmailItem>
                        ))}
                      </EmailList>
                    </>
                  )}
                />

                <Separator space="1rem" />

                <CustomTextArea
                  id="message"
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  label={intl.formatMessage({
                    id: 'investorManagement.invitationMessage',
                  })}
                  isOptional
                  placeholder={intl.formatMessage({
                    id: 'investorManagement.addInvitationMessage',
                  })}
                  minHeight="12rem"
                />
                <Separator />
                <ButtonsContainer>
                  <Button onClick={onCancel}>
                    {intl.formatMessage({ id: 'general.cancel' })}
                  </Button>
                  <Button
                    type="submit"
                    primary
                    onClick={formik.handleSubmit}
                    disabled={!formik.values.users.length}
                  >
                    {intl.formatMessage({ id: 'general.save' })}
                  </Button>
                </ButtonsContainer>
              </>
            )
          }}
        </Formik>
      </InviteInvestorGroupUserFormContainer>
    </FadeIn>
  )
}

export default InviteInvestorGroupUserForm
