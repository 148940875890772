import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Tag = styled.p`
  font-size: 1.4rem;
  color: #787e8c;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1.6rem;
`

export const PortfolioEntityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: none;
  padding: ${(props) => props.padding};
`

export const EntityName = styled.span`
  margin-left: 0.8rem;
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: bold;
  cursor: pointer;
`

export const EmailLogo = styled.div`
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 100%;
  background-color: ${color('veryLightBlue')};
  border: 0.1rem solid #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: white;
    font-size: 1.1rem;
  }
`

export const EntityType = styled.span`
  font-weight: 700;
  color: ${color('lightGray')} !important;
  ${(props) =>
    props.isSelected &&
    css`
      color: #e9e9e9;
    `};
`

export const EntityWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

export const EntityImage = styled(Image)`
  border-radius: 10rem;
  width: 2.1rem;
  height: 2.1rem;
`

export const EntityHandle = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${color('lightGray')} !important;
  flex-basis: 100%;
  margin-left: 2.9rem;
  height: 0;
  text-transform: none;
  ${(props) =>
    props.isSelected &&
    css`
      color: #e9e9e9;
    `}
`

const hoverState = css`
  ${EntityName}, ${EntityHandle} {
    color: ${color('primaryBlue')} !important;
  }
`

export const EntityRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: none;
  padding: ${(props) => props.padding};
  width: 100%;
  cursor: pointer;
  border-radius: 0.8rem;

  :hover {
    ${hoverState}
    background-color: ${color('primaryBlue', 0.1)};
  }
`

export const PortfolioEntityName = styled.span`
  margin-left: 1.35rem;
`
