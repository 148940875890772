import type { Channel, Event } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import { useAddTopicChannel } from 'containers/Chat/hooks'
import {
  ChannelType,
  StreamChatType,
  TopicChannel,
  isTopicChannel,
} from 'containers/Chat/types'
import { useEffect, useState } from 'react'
import { getUser } from 'selectors/auth'
import { getHasChatFeature } from 'selectors/chat'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { TopicEntity } from 'utils/types/chatTopicEntity'

export const useHasUnreadMessages = (
  topic?: TopicEntity,
  updateId?: string
) => {
  const hasChatFeature = useAppSelector(getHasChatFeature)

  const user = useAppSelector(getUser)
  const { client, channelsQueryState } =
    useChatContext<StreamChatType<TopicChannel>>()
  const [activeChannels, setActiveChannels] = useState<
    Channel<StreamChatType<TopicChannel>>[]
  >([])
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)

  useEffect(() => {
    if (hasChatFeature) {
      const newActiveChannels = Object.values(client.activeChannels).filter(
        (channel) =>
          [ChannelType.HOLDING, ChannelType.PORTFOLIO].includes(
            channel.type as ChannelType
          ) &&
          channel.state.members[user.id] &&
          isTopicChannel(channel.data) &&
          channel.data.entityId === topic?.id &&
          (updateId ? channel.data.updateId === updateId : true)
      )

      setActiveChannels(newActiveChannels)
      setHasUnreadMessages(
        newActiveChannels.some((channel) => channel.countUnread() > 0)
      )
    }
  }, [
    channelsQueryState.queryInProgress,
    client.activeChannels,
    topic?.id,
    updateId,
    user.id,
    hasChatFeature,
  ])

  useAddTopicChannel(setActiveChannels, topic?.id)

  useEffect(() => {
    if (!hasChatFeature) return () => {}

    const handleEvent = (event: Event<StreamChatType>) => {
      if (activeChannels.some((channel) => channel.id === event.channel_id)) {
        setHasUnreadMessages(true)
      }
    }

    client.on('message.new', handleEvent)

    return () => {
      client.off('message.new', handleEvent)
    }
  }, [activeChannels, client, hasChatFeature])

  return hasUnreadMessages
}
