import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from 'ui/Input'
import { color } from 'utils/functions/colors'

export const StyledInput = styled(Input)`
  padding: ${(props) => props.padding || '0'};
`

export const InputIcon = styled(FontAwesomeIcon)`
  min-width: 1.8rem;
  height: 1.8rem;
  color: ${color('darkBlue')};
`
