import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as CWLogo } from 'assets/images/logo-cw.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Brand,
  LogoContainer,
  NavBarContainer,
  SideBarButton,
} from './NavBar.styles'

const NavBar = ({ isSidebarOpen, toggleSideBar }) => {
  return (
    <NavBarContainer>
      <LogoContainer>
        <Brand href="/">
          <CWLogo />
        </Brand>
      </LogoContainer>
      <SideBarButton
        type="button"
        onClick={(event) => {
          event.preventDefault()
          toggleSideBar()
        }}
        isSidebarOpen={isSidebarOpen}
      >
        <FontAwesomeIcon icon={['fal', isSidebarOpen ? 'times' : 'bars']} />
      </SideBarButton>
    </NavBarContainer>
  )
}

NavBar.propTypes = {
  toggleSideBar: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
}

export default NavBar
