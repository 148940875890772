import { FormattedMessage } from 'react-intl'

import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupName } from 'selectors/auth'

import TopPrompt from 'components/TopPrompt'

import * as Styles from './UnverifiedWidget.styles'

const UnverifiedWidget = () => {
  const currentGroupName = useAppSelector(getCurrentGroupName)

  return (
    <TopPrompt
      icon={['fal', 'clock']}
      title={
        <Styles.Title>
          <FormattedMessage id="unverifiedWidget.title" />
        </Styles.Title>
      }
      legend={
        <Styles.Legend>
          <FormattedMessage
            values={{ groupName: currentGroupName }}
            id="unverifiedWidget.description"
          />
        </Styles.Legend>
      }
    />
  )
}

export default UnverifiedWidget
