import { useRef, useMemo } from 'react'
import { useIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { useEditor, Extension } from '@tiptap/react'
import { Editor } from '@tiptap/core'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Placeholder from '@tiptap/extension-placeholder'
import Italic from '@tiptap/extension-italic'
import Bold from '@tiptap/extension-bold'
import Underline from '@tiptap/extension-underline'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import HardBreak from '@tiptap/extension-hard-break'
import Link from '@tiptap/extension-link'
import History from '@tiptap/extension-history'

import InputLabel from 'ui/InputLabel'
import { getSanitizedHtml } from 'utils/functions/updates'

import BubbleMenu from './components/BubbleMenu/BubbleMenu'
import { EditorContent } from './RichTextArea.styles'

interface RichTextAreaProps {
  initialValue?: string
  label?: string
  isOptional?: boolean
  placeholder?: string
  onChange?: (value: string) => void
  isThin?: boolean
  readOnly?: boolean
  readOnlyValue?: string
  maxLines?: number
  disabled?: boolean
  error?: string
  openLinksOnClick?: boolean
  extensions?: Extension[]
  onCreate?: (element: Element) => void
}

const RichTextArea = ({
  initialValue,
  placeholder,
  readOnly = false,
  readOnlyValue,
  disabled = false,
  openLinksOnClick = false,
  error,
  onChange,
  extensions,
  label,
  isOptional,
  isThin,
  maxLines,
  onCreate,
}: RichTextAreaProps) => {
  const intl = useIntl()
  const initialValueRef = useRef(getSanitizedHtml(initialValue))
  const debouncedOnChange = useMemo(
    () =>
      debounce((editorUpdate: Editor) => {
        if (onChange) {
          onChange(editorUpdate.getHTML())
        }
      }, 500),
    [onChange]
  )

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph,
        Text,
        History,
        Italic,
        Bold,
        Underline,
        BulletList,
        OrderedList,
        ListItem,
        Placeholder.configure({ placeholder: placeholder ?? '' }),
        HardBreak.configure({ keepMarks: false }),
        Link.configure({
          openOnClick: openLinksOnClick,
          linkOnPaste: true,
          autolink: false,
          protocols: ['http', 'https'],
          HTMLAttributes:
            readOnly && !openLinksOnClick
              ? { style: 'pointer-events: none;' }
              : {},
        }),
        ...(extensions || []),
      ],
      content:
        readOnlyValue !== undefined
          ? getSanitizedHtml(readOnlyValue)
          : initialValueRef.current,
      editable: !readOnly && !disabled,
      onUpdate: ({ editor: editorUpdate }) => {
        debouncedOnChange(editorUpdate)
      },
      onCreate: ({ editor: createdEditor }) => {
        onCreate?.(createdEditor.options.element)
      },
    },
    [readOnlyValue]
  )

  return (
    <>
      {editor && <BubbleMenu editor={editor} />}
      {label && (
        <InputLabel
          isOptional={isOptional}
          optionalLabel={intl.formatMessage({ id: 'general.optional' })}
        >
          {label}
        </InputLabel>
      )}
      <EditorContent
        editor={editor}
        readOnly={readOnly}
        maxLines={maxLines}
        openLinksOnClick={openLinksOnClick}
        disabled={disabled}
        hasError={!!error}
        isThin={isThin}
      />
    </>
  )
}

export default RichTextArea
