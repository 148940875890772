export type BreakPointName =
  | 'xxs'
  | 'xss'
  | 'xsm'
  | 'sm'
  | 'md'
  | 'mdXl'
  | 'mobile'
  | 'lg'
  | 'tabletLg'
  | 'xl'
  | 'desktopLg'
  | 'xxl'
  | 'xxxl'
  | 'xxxXl'

export const breakpoint: Record<BreakPointName, string> = {
  xxs: '320px',
  // => @media (min-width: 320px) { ... }
  xss: '380px',
  // => @media (min-width: 380px) { ... }
  xsm: '468px',
  // => @media (min-width: 468px) { ... }
  sm: '640px',
  // => @media (min-width: 640px) { ... }
  md: '768px',
  // => @media (min-width: 768px) { ... }
  mdXl: '840px',
  // => @media (min-width: 768px) { ... }
  mobile: '1023px',
  // => @media (min-width: 1023px) { ... }
  lg: '1024px',
  // => @media (min-width: 1024px) { ... }
  tabletLg: '1150px',
  // => @media (min-width: 1150px) { ... }
  xl: '1280px',
  // => @media (min-width: 1280px) { ... }
  desktopLg: '1400px',
  // => @media (min-width: 1400px) { ... }
  xxl: '1536px',
  // => @media (min-width: 1536px) { ... }
  xxxl: '1720px',
  // => @media (min-width: 1720px) { ... }
  xxxXl: '1920px',
  // => @media (min-width: 1920px) { ... }
}

export const size = {
  xxs: `(width: ${breakpoint.xxs})`,
  xss: `(min-width: ${breakpoint.xss})`,
  xsm: `(min-width: ${breakpoint.xsm})`,
  sm: `(min-width: ${breakpoint.sm})`,
  md: `(min-width: ${breakpoint.md})`,
  mdXl: `(min-width: ${breakpoint.mdXl})`,
  lg: `(min-width: ${breakpoint.lg})`,
  tabletLg: `(min-width: ${breakpoint.tabletLg})`,
  xl: `(min-width: ${breakpoint.xl})`,
  desktopLg: `(min-width: ${breakpoint.desktopLg})`,
  xxl: `(min-width: ${breakpoint.xxl})`,
  xxxl: `(min-width: ${breakpoint.xxxl})`,
  xxxXl: `(min-width: ${breakpoint.xxxXl})`,
}

export const maxSize = {
  xxs: `(max-width: ${breakpoint.xxs})`,
  xss: `(max-width: ${breakpoint.xss})`,
  xsm: `(max-width: ${breakpoint.xsm})`,
  sm: `(max-width: ${breakpoint.sm})`,
  md: `(max-width: ${breakpoint.md})`,
  /**
   * Used to switch between mobile and desktop
   * (max-width: 1023px)
   */
  mobile: `(max-width: 1023px)`,
  mdXl: `(max-width: ${breakpoint.mdXl})`,
  lg: `(max-width: ${breakpoint.lg})`,
  tabletLg: `(max-width: ${breakpoint.tabletLg})`,
  xl: `(max-width: ${breakpoint.xl})`,
  desktopLg: `(max-width: ${breakpoint.desktopLg})`,
  xxl: `(max-width: ${breakpoint.xxl})`,
  xxxl: `(max-width: ${breakpoint.xxxl})`,
  xxxXl: `(max-width: ${breakpoint.xxxXl})`,
}
