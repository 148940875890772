import React, { lazy, useState } from 'react'

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import {
  isActingAsFounder,
  isActingAsFundManager,
  getUserIsOwnerOrManager,
  isActingAsInvestorGroup,
  getUserIsOwner,
  getCompaniesOwnedByGroup,
} from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import PaddedLayout from 'layouts/PaddedLayout'
import CompanyProfileEditDrawer from 'containers/Profiles/CompanyProfileEditDrawer'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import SidebarMetricsRoutes from './SidebarMetricsRoutes'

const UpdateAllMetricsModal = lazy(() =>
  import('containers/Metrics/UpdateAllMetricsModal/UpdateAllMetricsModal')
)
const GroupManagement = lazy(() => import('containers/GroupManagement'))

const UserProfileEdit = lazy(() => import('containers/UserProfileEdit'))
const GroupInfoEdit = lazy(() =>
  import('containers/GroupSettings/generalInformation')
)
const Storage = lazy(() => import('containers/GroupSettings/Storage'))
const Privacy = lazy(() => import('containers/GroupSettings/Privacy'))
const GroupMembers = lazy(() => import('containers/GroupSettings/GroupMembers'))
const InvestmentVehicleEdit = lazy(() =>
  import('containers/InvestorManagement/EditInvestmentVehicle')
)
const ListManagement = lazy(() => import('containers/ListManagement'))
const Notifications = lazy(() => import('containers/Notifications'))
const UpdateSettings = lazy(() => import('containers/UpdateSettings'))
const Integrations = lazy(() => import('containers/Integrations'))
const CustomLogin = lazy(() => import('containers/CustomLogin'))
const LoginAndSecurity = lazy(() => import('containers/LoginAndSecurity'))
const Subscriptions = lazy(() =>
  import('containers/GroupSettings/Subscriptions')
)
const Inbounds = lazy(() => import('containers/Inbounds'))
const FundManagerProfile = lazy(() =>
  import('containers/Profiles/FundManagerProfile')
)
const CompanyProfile = lazy(() => import('containers/Profiles/CompanyProfile'))

const SettingsRoutes = () => {
  const [isEditInvestmentVehicleOpen, setIsEditInvestmentVehicleOpen] =
    useState(true)

  const history = useHistory()
  const { path: subPath } = useRouteMatch()

  const onCloseEditInvestmentVehicleDrawer = () => {
    history.push(`${subPath}/group_management/investment-vehicles`)
  }

  const isFounder = useAppSelector(isActingAsFounder)
  const groupCompanies = useAppSelector(getCompaniesOwnedByGroup)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const userIsOwnerOrManager = useAppSelector(getUserIsOwnerOrManager)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const isOwner = useAppSelector(getUserIsOwner)
  const planFeatures = usePlanFeatures()

  const paddedRoutes = [
    {
      path: `${subPath}/group_management/general`,
      component: <GroupInfoEdit />,
    },
    {
      path: `${subPath}/group_management/users`,
      component: isInvestorGroup ? <GroupMembers /> : <GroupManagement />,
      hidden: !planFeatures.isGroupManagementEnabled,
    },
    {
      path: `${subPath}/group_management/investment-vehicles/:investmentVehicleId/edit`,
      component: (
        <InvestmentVehicleEdit
          isDrawerOpen={isEditInvestmentVehicleOpen}
          onCloseDrawer={onCloseEditInvestmentVehicleDrawer}
        />
      ),
    },
    {
      path: `${subPath}/group_management/lists`,
      component: <ListManagement />,
    },
    {
      path: `${subPath}/group_management/subscriptions`,
      component: isOwner ? (
        <Subscriptions />
      ) : (
        <Redirect to="/settings/group_management/general" />
      ),
    },
    {
      path: `${subPath}/group_management/integrations`,
      component:
        isFounder || isFundManager ? (
          <Integrations />
        ) : (
          <Redirect to="/settings/group_management/general" />
        ),
    },
    {
      path: [`${subPath}/group_management/inbounds`],
      component: isInvestorGroup ? <Redirect to="/" /> : <Inbounds />,
    },
    {
      path: `${subPath}/group_management/custom-login`,
      component:
        isFundManager && userIsOwnerOrManager ? (
          <CustomLogin />
        ) : (
          <Redirect to="/" />
        ),
    },
    {
      path: `${subPath}/group_management/storage`,
      component: <Storage />,
      hidden: isInvestorGroup,
    },

    {
      path: `${subPath}/account/:selected_menu/:id/confirmation`,
      component: <UserProfileEdit emailConfirmationRoute />,
    },
    {
      path: `${subPath}/account/general`,
      component: <UserProfileEdit />,
    },
    {
      path: `${subPath}/account/login-and-security`,
      component: <LoginAndSecurity />,
    },
    {
      path: `${subPath}/account/privacy`,
      component: <Privacy />,
    },
    {
      path: `${subPath}/account/notifications`,
      component: <Notifications />,
    },
    {
      path: `${subPath}/account/update-settings`,
      component: <UpdateSettings />,
    },
  ].filter(({ hidden }) => !hidden)

  const nonPaddedRoutes = [
    {
      path: `${subPath}/group_management/fund/metrics/update-all`,
      exact: true,
      component: <UpdateAllMetricsModal />,
    },
    {
      path: `${subPath}/group_management/profile/metrics/update-all`,
      exact: true,
      component: <UpdateAllMetricsModal />,
    },
    {
      path: `${subPath}/group_management/fund`,
      exact: false,
      component: isFundManager ? (
        <FundManagerProfile />
      ) : (
        <Redirect to="/settings/group_management/general" />
      ),
    },
    {
      path: `${subPath}/group_management/fund/metrics`,
      exact: false,
      component: isFundManager ? (
        <SidebarMetricsRoutes
          urlPrefix={`${subPath}/group_management/fund/metrics`}
        />
      ) : (
        <Redirect to="/settings/group_management/general" />
      ),
    },
    {
      path: `${subPath}/group_management/profile/metrics`,
      exact: false,
      component: (
        <SidebarMetricsRoutes
          urlPrefix={`${subPath}/group_management/profile/metrics`}
        />
      ),
    },
    {
      path: [
        `${subPath}/group_management/fund/edit`,
        `${subPath}/group_management/profile/edit`,
      ],
      exact: false,
      component: <CompanyProfileEditDrawer />,
    },
    {
      path: [
        `${subPath}/group_management/profile`,
        `${subPath}/group_management/profile/edit`,
      ],
      exact: false,
      component: isFounder ? (
        <CompanyProfile companyId={groupCompanies[0].id} />
      ) : (
        <Redirect to="/settings/group_management/general" />
      ),
    },
  ].filter(({ hidden }) => !hidden)

  return (
    <>
      {nonPaddedRoutes.map(({ path, component, exact = true }) => (
        <Route key={path} exact={exact} path={path}>
          {component}
        </Route>
      ))}
      <PaddedLayout>
        {paddedRoutes.map(({ path, component, exact = true }) => (
          <Route key={path} exact={exact} path={path}>
            {component}
          </Route>
        ))}
      </PaddedLayout>
    </>
  )
}

export default SettingsRoutes
