import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'

import { getPortfolioInvestorSettings } from 'selectors/portfolio'
import { setInvestorCalculation } from 'actions/portfolios'

export const useToggleInvestorCalculations = (portfolioId: string) => {
  const dispatch = useAppDispatch()
  const portfolioInvestorSettings = useAppSelector(
    getPortfolioInvestorSettings(portfolioId)
  )

  const toggleCalculations = useCallback(
    (isEnabled: boolean) => {
      dispatch(setInvestorCalculation(portfolioId, !isEnabled))
    },
    [dispatch, portfolioId]
  )

  return {
    isInvestorCalculationDisabled:
      !!portfolioInvestorSettings?.isInvestorCalculationDisabled,
    toggleCalculations,
  }
}
