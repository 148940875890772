import { useMemo } from 'react'
import { Channel, type ChannelPreviewUIComponentProps } from 'stream-chat-react'

import { StreamChatType } from 'containers/Chat/types'
import { getMaximizedChatId } from 'features/chatSlice'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import MaximizedChatPopup from './components/MaximizedChatPopup'
import { ChatPopupHeader } from '../ChatPopupHeader'
import EmptyComponent from '../EmptyComponent'
import { ClosedChatPopup, Wrapper } from './ChatPopup.styles'

interface Props extends ChannelPreviewUIComponentProps<StreamChatType> {}

const ChatPopup = ({ channel }: Props) => {
  const maximizedChatId = useAppSelector(getMaximizedChatId)
  const isMaximized = maximizedChatId === channel.id

  const maximizedPopup = useMemo(() => {
    return <MaximizedChatPopup channel={channel} />
  }, [channel])

  const minimizedPopup = useMemo(() => {
    return (
      <ClosedChatPopup>
        <Channel
          doMarkReadRequest={() => {}}
          channel={channel}
          LoadingIndicator={EmptyComponent}
        >
          <ChatPopupHeader channel={channel} isMaximized={false} />
        </Channel>
      </ClosedChatPopup>
    )
  }, [channel])

  return (
    <Wrapper isMaximized={isMaximized}>
      {isMaximized ? maximizedPopup : minimizedPopup}
    </Wrapper>
  )
}

export default ChatPopup
