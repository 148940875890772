import { Helmet } from 'components/Helmet'
import { useIntl } from 'react-intl'
import { StyledShowInvestorLoading } from 'containers/InvestorManagement/ShowInvestor/ShowInvestor.styles'
import useInvestorGroup from 'containers/InvestorManagement/hooks/useInvestorGroup'
import { UpdatesFiltersProvider } from 'containers/UpdatesView/UpdatesFiltersContext'
import Celeste from 'components/Celeste'
import CWLoader from 'components/CWLoader'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import Layout from 'containers/Profiles/components/Layout'
import InvestorMainContent from 'containers/Profiles/components/MainContent/InvestorMainContent'
import { INVESTOR_ALL_UPDATES } from 'routes/constant/investorManagementRoutes'
import InvestorInformation from 'containers/Profiles/components/SideInformation/InvestorInformation'
import HeaderContent from 'containers/Profiles/components/HeaderContent/HeaderContent'
import { ProfileProvider } from 'containers/Profiles/ProfileContext'
import { ProfileType } from 'containers/Profiles/types'
import useInvestorProfile from './useInvestorProfile'

const InvestorProfile = () => {
  const { id: investorGroupId } = useInvestorGroup()
  const intl = useIntl()
  const {
    fundsPortfolioInvestor,
    onShowFundTransaction,
    onShowInvestmentVehicleTransaction,
    onClickUpdate,
    onEditUpdate,
    isLoadingInvestor,
    isLoadingFunds,
    vehicles,
  } = useInvestorProfile({ investorGroupId })

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  if (isLoadingInvestor || isLoadingFunds) {
    return (
      <>
        <Helmet>
          <title>
            {intl.formatMessage({ id: 'metaTitles.investorProfile' })}
          </title>
        </Helmet>
        <StyledShowInvestorLoading>
          <CWLoader />
        </StyledShowInvestorLoading>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'metaTitles.investorProfile' })}
        </title>
      </Helmet>

      <UpdatesFiltersProvider>
        <ProfileProvider>
          <Layout>
            <Layout.Header type={ProfileType.INVESTOR}>
              <HeaderContent />
            </Layout.Header>

            <Layout.SideContent>
              <InvestorInformation investorId={investorGroupId} />
            </Layout.SideContent>

            <Layout.MainContent>
              <InvestorMainContent
                investorId={investorGroupId}
                vehicles={vehicles}
                isLoadingInvestor={isLoadingInvestor}
                isLoadingFunds={isLoadingFunds}
                fundsPortfolioInvestor={fundsPortfolioInvestor}
                onShowFundTransaction={onShowFundTransaction}
                onShowInvestmentVehicleTransaction={
                  onShowInvestmentVehicleTransaction
                }
                onEditUpdate={onEditUpdate}
                onShowUpdate={onClickUpdate}
              />
            </Layout.MainContent>

            {!isInvestorGroup && (
              <Celeste
                prefixUrl={INVESTOR_ALL_UPDATES.replace(
                  ':investorId',
                  investorGroupId
                )}
                showCreateTransactionOption
              />
            )}
          </Layout>
        </ProfileProvider>
      </UpdatesFiltersProvider>
    </>
  )
}

export default InvestorProfile
