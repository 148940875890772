import { InvestmentVehicleFilters } from 'utils/hooks/investorManagement/useInvestmentVehiclesQuery'

export const QUERIES = {
  INVESTMENT_VEHICLES: 'investment-vehicles',
  INVESTMENT_VEHICLE: 'investment-vehicle',
  FUND_PORTFOLIO_INVESTMENT_VEHICLES: 'fund-portfolio-investment-vehicles',
  FUNDS_PORTFOLIO_INVESTMENT_VEHICLE: 'funds-portfolio-investment-vehicle',
} as const

export const investmentVehiclesKeys = {
  all: () => [QUERIES.INVESTMENT_VEHICLES] as const,
  allWithFilters: (filters: InvestmentVehicleFilters) =>
    [QUERIES.INVESTMENT_VEHICLES, filters] as const,
  byId: (id: string) => [QUERIES.INVESTMENT_VEHICLE, id] as const,

  fundPortfolioInvestmentVehicles: (fundId: string) =>
    [QUERIES.FUND_PORTFOLIO_INVESTMENT_VEHICLES, fundId] as const,
  fundsPortfoliosByInvestmentVehicle: (vehicleId: string) =>
    [QUERIES.FUNDS_PORTFOLIO_INVESTMENT_VEHICLE, vehicleId] as const,
}
