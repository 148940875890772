import Card from 'components/Card'
import UpdatesFeed, { UpdateEntityType } from 'components/UpdatesFeedV2'
import { useIntl } from 'react-intl'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import { UpdateUrlSuffix } from 'utils/hooks/useShowUpdateRoutes/types'
import { IndexUpdate } from 'utils/types/update'
import { FilterOptionsWrapper, Title } from './CompanyUpdates.style'
import UpdatesFilterOptions from './UpdatesFilterOptions'
import useCompanyUpdates from './useCompanyUpdates'
import { useGetFilterOptions } from '../useGetFilterOptions'

interface Props {
  companyId: string
}

const CompanyUpdates: React.FC<Props> = ({ companyId }) => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(Media.MAX_MD)
  const { handleOnUpdateClick } = useOnClickRedirect()

  const {
    filters,
    filterDataSet,
    onChangeTypeFilters,
    onChangeTagFilters,
    onToggleDraftOnly,
    hasMixedUpdates,
  } = useCompanyUpdates(companyId)

  const onClickUpdate = (suffix?: UpdateUrlSuffix) => (update: IndexUpdate) => {
    return handleOnUpdateClick({
      update,
      updateSuffix: suffix,
    }).redirectFunction()
  }

  const onShowUpdate = (update: IndexUpdate) => {
    return handleOnUpdateClick({ update }).redirectFunction()
  }

  const filterOptionsToShow = useGetFilterOptions()

  return (
    <Card isExpandable padding="1.4rem 2.4rem">
      <Card.Header>
        <Title>{intl.formatMessage({ id: 'updates.updateFeed' })}</Title>
        <FilterOptionsWrapper>
          <UpdatesFilterOptions
            filters={filters}
            filterDataSet={filterDataSet}
            isMobile={isMobile}
            handleTypeFilters={onChangeTypeFilters}
            handleTagFilters={onChangeTagFilters}
            handleToggleDraftOnly={onToggleDraftOnly}
            hasUpdates={hasMixedUpdates}
            optionsToShow={filterOptionsToShow}
          />
        </FilterOptionsWrapper>
      </Card.Header>
      <Card.Body>
        <UpdatesFeed
          entityType={UpdateEntityType.COMPANY}
          entityId={companyId}
          onShowUpdate={onShowUpdate}
          onEditUpdate={onClickUpdate(UpdateUrlSuffix.EDIT)}
          onReshareUpdate={onClickUpdate(UpdateUrlSuffix.RESHARE)}
        />
      </Card.Body>
    </Card>
  )
}

export default CompanyUpdates
