import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Pagination = styled.div`
  font-size: 1.4rem;

  bottom: 3rem;
  display: flex;
  justify-content: space-between;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  width: 90%;
`

export const PaginationButton = styled.button<{ isRounded: boolean }>`
  border-radius: 50%;
  border: ${({ isRounded }) =>
    isRounded && `0.1rem solid ${color('veryLightGray')};`}
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.6rem;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`
