import { useQueryClient } from '@tanstack/react-query'
import SubscriptionService from 'api/SubscriptionService'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import { INVALID_DISCOUNT_CODE } from 'utils/constants/errors'
import { buildFormError } from 'utils/functions/forms'
import { renderCurrency } from 'utils/functions/number'
import { subscriptionsKeys } from 'utils/queries/subscriptions'
import { ErrorType } from 'utils/types/common'
import {
  ExistentSubscription,
  SubscriptionBillingPeriod,
  SubscriptionPlan,
  SubscriptionStatus,
} from 'utils/types/subscriptions'
import { useSignupContext } from 'containers/Signup/context/SignupContext'
import { useSubscriptionQuery } from '../../../../GroupSettings/Subscriptions/useSubscriptionQuery'
import { LineSeparator } from '../Payment/Payment.styles'
import * as Styles from './SubscriptionBill.styles'

const SubscriptionBill = () => {
  const intl = useIntl()
  const {
    investorSignup: { subscriptionId, billingAccount, onChangeBillingPeriod },
  } = useSignupContext()

  const [couponCode, setCouponCode] = useState<string>('')
  const [couponError, setCouponError] = useState<string>('')
  const [couponLoading, setCouponLoading] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { data: subscriptionData } = useSubscriptionQuery<ExistentSubscription>(
    {
      subscriptionId,
    }
  )

  const isDiscountApplied = !!subscriptionData?.coupon

  const handleApplyCoupon = async () => {
    try {
      if (couponCode) {
        setCouponError('')
        setCouponLoading(true)
        const newSubscriptionData = await SubscriptionService.applyCoupon(
          subscriptionId!,
          couponCode
        )
        queryClient.setQueryData(
          subscriptionsKeys.getSubscriptionData(subscriptionId!),
          newSubscriptionData
        )
      }
    } catch (err) {
      if (err.message.includes(INVALID_DISCOUNT_CODE)) {
        setCouponError(
          intl.formatMessage({ id: 'subscriptions.invalidPromotionCode' })
        )
      } else {
        setCouponError(
          intl.formatMessage({ id: 'subscriptions.promotionCodeError' })
        )
      }
    } finally {
      setCouponLoading(false)
    }
  }

  const removeCoupon = async () => {
    try {
      setCouponError('')
      setCouponLoading(true)
      const newSubscriptionData = await SubscriptionService.applyCoupon(
        subscriptionId!,
        null
      )
      queryClient.setQueryData(
        subscriptionsKeys.getSubscriptionData(subscriptionId!),
        newSubscriptionData
      )
      setCouponCode('')
    } catch (err) {
      setCouponError(
        intl.formatMessage({ id: 'subscriptions.promotionCodeRemoveError' })
      )
    } finally {
      setCouponLoading(false)
    }
  }

  const onCouponActionClick = () => {
    if (isDiscountApplied) {
      removeCoupon()
    } else {
      handleApplyCoupon()
    }
  }

  const planName = useMemo(() => {
    switch (subscriptionData?.plan) {
      case SubscriptionPlan.LITE:
        return intl.formatMessage({ id: 'subscriptions.plan.lite' })
      case SubscriptionPlan.INDIVIDUAL:
        return intl.formatMessage({ id: 'subscriptions.plan.basic' })
      case SubscriptionPlan.PRO:
      default:
        return intl.formatMessage({ id: 'subscriptions.plan.pro' })
    }
  }, [intl, subscriptionData?.plan])

  const trialMessage = useMemo(() => {
    if (subscriptionData?.status === SubscriptionStatus.TRIALING) {
      return (
        <Styles.Trial>
          (<FormattedMessage id="investorSignup.fourteenDayTrial" />)
        </Styles.Trial>
      )
    }

    return null
  }, [subscriptionData?.status])

  const renderDiscountEndDate = () => {
    if (subscriptionData?.coupon?.end) {
      return ` - ${intl.formatMessage(
        { id: 'subscriptions.validUntil' },
        { date: dayjs(subscriptionData.coupon.end).format('MMM DD, YYYY') }
      )}`
    }

    if (subscriptionData?.coupon?.duration === 'once') {
      return ` - ${intl.formatMessage(
        { id: 'subscriptions.validUntil' },
        { date: dayjs().add(1, 'months').format('MMM DD, YYYY') }
      )}`
    }

    return null
  }

  const billingPeriodLabels = useMemo(() => {
    if (
      billingAccount?.subscription.billingPeriod ===
      SubscriptionBillingPeriod.MONTHLY
    ) {
      return {
        priceLabel: intl.formatMessage({ id: 'subscriptions.perMonth' }),
        to: `${intl.formatMessage({
          id: 'subscriptions.monthlyTo',
        })} ${planName}`,
        billed: intl.formatMessage({ id: 'subscriptions.billedMonthly' }),
        switchTo: intl.formatMessage({ id: 'subscriptions.switchToAnnually' }),
      }
    }

    return {
      priceLabel: intl.formatMessage({ id: 'subscriptions.perYear' }),
      to: `${intl.formatMessage({
        id: 'subscriptions.annuallyTo',
      })} ${planName}`,
      billed: intl.formatMessage({ id: 'subscriptions.billedAnnually' }),
      switchTo: intl.formatMessage({ id: 'subscriptions.switchToMonthly' }),
    }
  }, [billingAccount?.subscription.billingPeriod, planName, intl])

  if (!subscriptionData) return null

  return (
    <Styles.BillContainer>
      <Styles.PlanName>
        <FormattedMessage id="subscriptions.subscribeTo" /> <b>{planName}</b>{' '}
        {trialMessage}
      </Styles.PlanName>
      <Styles.PlanPriceContainer>
        <Styles.PlanPrice>
          {renderCurrency(intl, subscriptionData.priceWithoutDiscount)}
        </Styles.PlanPrice>
        <Styles.PlanPriceLabel>
          {billingPeriodLabels.priceLabel}
        </Styles.PlanPriceLabel>
      </Styles.PlanPriceContainer>
      <Separator space="1.6rem" />
      <Styles.PlanRow>
        <span>{billingPeriodLabels.to}</span>
        <b>{renderCurrency(intl, subscriptionData.priceWithoutDiscount)}</b>
      </Styles.PlanRow>
      <Styles.PlanRowDescription>
        {billingPeriodLabels.billed} -{' '}
        <Styles.SwitchPeriodButton onClick={onChangeBillingPeriod}>
          {billingPeriodLabels.switchTo}
        </Styles.SwitchPeriodButton>
      </Styles.PlanRowDescription>

      <Separator space="1.6rem" />

      <Styles.CouponContainer>
        <Styles.Input
          value={couponCode}
          disabled={isDiscountApplied}
          onChange={(e) => setCouponCode(e.target.value)}
          error={buildFormError(couponError, ErrorType.ERROR, true)}
          placeholder={intl.formatMessage({
            id: 'subscriptions.addPromotionCode',
          })}
        />
        <Button
          secondary={!isDiscountApplied}
          type="button"
          onClick={onCouponActionClick}
          disabled={couponLoading}
          secondaryDanger={isDiscountApplied}
        >
          {isDiscountApplied ? (
            <FormattedMessage id="subscriptions.remove" />
          ) : (
            <FormattedMessage id="subscriptions.apply" />
          )}
        </Button>
      </Styles.CouponContainer>

      <Separator space="1.2rem" />

      <Styles.PlanRow>
        <span>
          <FormattedMessage id="subscriptions.subtotal" />
        </span>
        <b>{renderCurrency(intl, subscriptionData.priceWithoutDiscount)}</b>
      </Styles.PlanRow>

      {subscriptionData.coupon && (
        <>
          <Separator space="1.2rem" />
          <Styles.PlanRow>
            <span>
              <FormattedMessage
                id="subscriptions.discountValue"
                values={{
                  value: `${subscriptionData.discountPercent}%`,
                }}
              />
              {renderDiscountEndDate()}
            </span>
            <b>-{renderCurrency(intl, subscriptionData.discountAmount)}</b>
          </Styles.PlanRow>
        </>
      )}

      <LineSeparator margin="1.2rem" />

      <Styles.PlanRow>
        <b>
          <FormattedMessage id="subscriptions.total" />
        </b>
        <b>{renderCurrency(intl, subscriptionData.price)}</b>
      </Styles.PlanRow>
    </Styles.BillContainer>
  )
}

export default SubscriptionBill
