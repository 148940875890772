import { getGroupType } from 'features/createNewGroupSlice'
import AccountSetupLayout from 'layouts/AccountSetupLayout'
import ProgressBar from 'layouts/ProgressBar/ProgressBar'
import { useIntl } from 'react-intl'
import { Redirect, Route, Switch } from 'react-router-dom'
import Separator from 'ui/Separator'
import { GroupTypes } from 'utils/constants/groups'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import FounderGroupSetup from '../FounderGroupSetup'
import { BillingAccount, Payment, PaymentCheckStep } from '../GetStarted'
import CreateGroup from '../GetStarted/components/CreateGroup'
import SignupPlanSelector from '../InvestorAccountSetup/SignupPlanSelector'
import { useInvestorAccountSetup } from '../InvestorAccountSetup/useInvestorAccountSetup'
import * as Styles from './SignupWizard.styles'

enum SignupSteps {
  WELCOME = 'welcome',
  ADD_USER_INFO = 'add_user_info',
  ADD_BILLING_INFO = 'add_billing_info',
  ADD_PAYMENT_DETAILS = 'add_payment_details',
  SETUP_GROUP = 'SETUP_GROUP',
}

const CreateGroupWizard = () => {
  const intl = useIntl()
  const {
    urlPrefix,
    clientSecret,
    paymentError,
    onClickContinueToPayment,
    onPaymentSuccess,
    goBackToPayment,
    onCancel,
  } = useInvestorAccountSetup()

  const groupType = useAppSelector(getGroupType)

  const getCurrentStep = () => {
    const path = window.location.pathname

    if (path.includes('select-plan')) return SignupSteps.WELCOME
    if (path.includes('account-setup')) return SignupSteps.ADD_USER_INFO
    if (path.includes('group-setup')) return SignupSteps.SETUP_GROUP
    if (path.includes('founder-setup')) return SignupSteps.SETUP_GROUP
    if (path.includes('billing')) return SignupSteps.ADD_BILLING_INFO
    if (path.includes('payment')) return SignupSteps.ADD_PAYMENT_DETAILS
    return SignupSteps.WELCOME
  }

  const steps = [
    {
      id: SignupSteps.WELCOME,
      label: intl.formatMessage({
        id: 'signup.steps.welcome',
      }),
    },
    {
      id: SignupSteps.SETUP_GROUP,
      label: intl.formatMessage({
        id: 'signup.steps.setupYourGroup',
      }),
    },
    {
      id: SignupSteps.ADD_BILLING_INFO,
      label: intl.formatMessage({
        id: 'signup.steps.addBillingInfo',
      }),
      hidden: groupType === GroupTypes.FOUNDER,
    },
    {
      id: SignupSteps.ADD_PAYMENT_DETAILS,
      label: intl.formatMessage({
        id: 'signup.steps.addPaymentDetails',
      }),
      hidden: groupType === GroupTypes.FOUNDER,
    },
  ].filter((step) => !step.hidden)

  return (
    <Styles.Modal show onHide={onCancel} blurOverlay>
      <Styles.SignupWizardContainer>
        <AccountSetupLayout.StickyHeader>
          <AccountSetupLayout.Header onClose={onCancel} />
          <ProgressBar steps={steps} currentStep={getCurrentStep()} />
        </AccountSetupLayout.StickyHeader>

        <div>
          <Separator space="3.6rem" />
          <Switch>
            <Route path={`${urlPrefix}/select-plan`}>
              <SignupPlanSelector />
            </Route>

            <Route path={`${urlPrefix}/group-setup`}>
              <CreateGroup />
            </Route>

            <Route path={`${urlPrefix}/founder-setup`}>
              <FounderGroupSetup />
            </Route>

            <Route path={`${urlPrefix}/billing`}>
              <BillingAccount onSubmit={onClickContinueToPayment} />
            </Route>

            <Route path={`${urlPrefix}/payment`}>
              <Payment
                onPaymentSuccess={onPaymentSuccess}
                clientSecret={clientSecret!}
                paymentError={paymentError}
              />
            </Route>

            <Route path={`${urlPrefix}/payment-check`}>
              <PaymentCheckStep
                onPaymentSuccess={onPaymentSuccess}
                goBackToPayment={goBackToPayment}
              />
            </Route>

            <Redirect to={`${urlPrefix}/select-plan`} />
          </Switch>
        </div>
      </Styles.SignupWizardContainer>
    </Styles.Modal>
  )
}

export default CreateGroupWizard
