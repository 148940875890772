/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import ReportSection from '../ReportSection'
import {
  CompanyTitle,
  BodyContainer,
  Line,
  ReportTitle,
  ReportHeader,
  ReportTable,
  ReportColumn,
  TableHeader,
  ReportContent,
} from '../ShowReport.styles'

const ShowXeroReport = ({ data, border }) => {
  const header = data.rows?.find?.((row) => row.rowType === 'Header')
  const sections = data.rows?.filter?.((row) => row.rowType === 'Section')
  const periods = header?.cells?.length ?? 0 - 1

  return (
    <BodyContainer border={border}>
      <CompanyTitle>{data.reportTitles?.[1]}</CompanyTitle>
      <ReportHeader>
        <ReportTitle>{data.reportTitles?.[0]}</ReportTitle>
        <ReportTitle light>{data.reportTitles?.[2]}</ReportTitle>
      </ReportHeader>
      <Line />
      <ReportContent>
        <ReportTable periods={periods}>
          {header?.cells?.map((headerCell) => (
            <ReportColumn key={headerCell.value}>
              <TableHeader labelColumn={!headerCell.value}>
                {headerCell.value?.toUpperCase?.() ?? ''}
              </TableHeader>
            </ReportColumn>
          ))}
        </ReportTable>

        {sections
          .filter((section) => section.rows?.length > 0)
          .map((section, index) => {
            return (
              <ReportTable key={index} periods={periods}>
                <ReportSection
                  periods={periods}
                  title={section.title}
                  sectionRows={section.rows}
                  isMainSection={section.rows.length === 0}
                  isMainSectionSummary={
                    !section.title && section.rows.length === 1
                  }
                />
              </ReportTable>
            )
          })}
      </ReportContent>
    </BodyContainer>
  )
}

ShowXeroReport.propTypes = {
  border: PropTypes.bool,
  data: PropTypes.shape({
    reportTitles: PropTypes.arrayOf(PropTypes.string),
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        rows: PropTypes.arrayOf(
          PropTypes.shape({
            rowType: PropTypes.string,
            cells: PropTypes.arrayOf(
              PropTypes.shape({
                value: PropTypes.oneOfType([
                  PropTypes.string,
                  PropTypes.number,
                ]),
              })
            ),
          })
        ),
      })
    ),
  }),
}

ShowXeroReport.defaultProps = {
  border: true,
  data: {
    reportTitles: [],
    rows: [],
  },
}

export default ShowXeroReport
