import { decamelize } from 'humps'

export const decamelizeThatDoesntBreakFiles = (object) => {
  const isAParsableObject =
    !(object instanceof File) && !(object instanceof Date)

  if (object && isAParsableObject) {
    if (object instanceof Array) {
      return object.map((item) => decamelizeThatDoesntBreakFiles(item))
    }

    if (typeof object === 'object') {
      return Object.keys(object).reduce(
        (acc, next) => ({
          ...acc,
          [decamelize(next)]: decamelizeThatDoesntBreakFiles(object[next]),
        }),
        {}
      )
    }
  }

  return object
}
