export enum AccountingReportType {
  XERO_BALANCE_SHEET = 'XERO_BALANCE_SHEET',
  XERO_PROFIT_AND_LOSS = 'XERO_PROFIT_AND_LOSS',
  QUICKBOOKS_BALANCE_SHEET = 'QUICKBOOKS_BALANCE_SHEET',
  QUICKBOOKS_PROFIT_AND_LOSS = 'QUICKBOOKS_PROFIT_AND_LOSS',
}

export enum BalanceDateType {
  SCHEDULED_DATE = 'SCHEDULED_DATE',
  END_OF_LAST_MONTH = 'MONTH',
  END_OF_LAST_QUARTER = 'QUARTER',
  END_OF_LAST_FINANCIAL_YEAR = 'YEAR',
}

export enum ReportDatePeriodType {
  CURRENT_MONTH = 'CURRENT_MONTH',
  CURRENT_QUARTER = 'CURRENT_QUARTER',
  CURRENT_FINANCIAL_YEAR = 'CURRENT_FINANCIAL_YEAR',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  PREVIOUS_QUARTER = 'PREVIOUS_QUARTER',
  PREVIOUS_FINANCIAL_YEAR = 'PREVIOUS_FINANCIAL_YEAR',
}

export enum PeriodType {
  NONE = '0',
  PREVIOUS_1_PERIOD = '1',
  PREVIOUS_2_PERIOD = '2',
  PREVIOUS_3_PERIOD = '3',
}

export type BalanceSheetReportParams = {
  balanceDate: BalanceDateType
  periods: PeriodType
  paymentsOnly: boolean
  standardLayout: boolean
}
export type ProfitAndLossReportParams = {
  periods: PeriodType
  reportDatePeriod: ReportDatePeriodType
  paymentsOnly: boolean
  standardLayout: boolean
}

export type ReportParams = BalanceSheetReportParams | ProfitAndLossReportParams
