import { useEffect, useMemo } from 'react'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import SkeletonPortfoliosNavList from 'components/Skeletons/Portfolios/SkeletonPortfoliosNavList/SkeletonPortfoliosNavList'
import FadeIn from 'components/FadeIn'
import PaddedLayout from 'layouts/PaddedLayout'
import { ZeroStateContainer } from 'components/ZeroStateV2/ZeroState.styles'
import LAYOUT from 'utils/constants/zerostatelayout'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import ZeroStateLinks from 'utils/constants/zerostatelinks'
import { getAllFundsOption } from 'containers/Dashboard/InvestorGroupDashboard/constants'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { setCurrentPortfolio } from 'actions/portfolios'
import { useInvestmentsFiltersContext } from './InvestmentContext'
import InvestmentsPortfolioNavigation from './InvestmentsPortfolioNavigation/InvestmentsPortfolioNavigation'

export const Title = styled.h1`
  font-size: 2.4rem;
  font-family: 'Raleway';
`

interface LoaderWrapperInvestorGroupProps {
  children: React.ReactNode
  isPaddedLayout?: boolean
}

const LoaderWrapperInvestorGroup: React.FC<LoaderWrapperInvestorGroupProps> = ({
  children,
  isPaddedLayout = true,
}) => {
  const { normalizedNavigationOptions, isLoadingPortfolios } =
    useInvestmentsFiltersContext()

  const intl = useIntl()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const ALL_FUNDS_OPTION = getAllFundsOption(intl)
    dispatch(setCurrentPortfolio(ALL_FUNDS_OPTION))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showZeroState = useMemo(() => {
    return (
      normalizedNavigationOptions === null ||
      normalizedNavigationOptions?.length === 0
    )
  }, [normalizedNavigationOptions])

  if (isLoadingPortfolios) return <SkeletonPortfoliosNavList />

  if (showZeroState)
    return (
      <>
        <InvestmentsPortfolioNavigation />
        <PaddedLayout>
          <FadeIn>
            <ZeroStateContainer layout={LAYOUT.VERTICAL}>
              <ZeroState
                type={ZeroStateType.INVESTOR_FUNDS_DEALS}
                isChatWithSupportEnabled
                linkToGuide={ZeroStateLinks.ADD_PORTFOLIO}
                padding="10vh 0 0 0"
                layout={LAYOUT.VERTICAL}
                maxWidth="44rem"
              />
            </ZeroStateContainer>
          </FadeIn>
        </PaddedLayout>
      </>
    )

  return (
    <>
      {!isEmpty(normalizedNavigationOptions) && (
        <InvestmentsPortfolioNavigation />
      )}
      {isPaddedLayout ? <PaddedLayout>{children}</PaddedLayout> : children}
    </>
  )
}

export default LoaderWrapperInvestorGroup
