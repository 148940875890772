import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'ui/Button'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import { useRandomId } from 'utils/hooks/useRandomId'

import LAYOUT from 'utils/constants/zerostatelayout'
import { ReactComponent as MetricZeroStateLogo } from 'assets/images/zero-states/metrics.svg'
import { ReactComponent as PortfoliosZeroState } from 'assets/images/zero-states/portfolios.svg'
import { ReactComponent as UpdatesFeedZeroState } from 'assets/images/zero-states/updates.svg'
import { ReactComponent as HoldingsListZeroState } from 'assets/images/zero-states/holdings-list.svg'
import { ReactComponent as DashboardZeroState } from 'assets/images/zero-states/dashboard.svg'
import { ReactComponent as CompanyUpdatesFeedZeroState } from 'assets/images/zero-states/company-updates.svg'
import { ReactComponent as EmailsZeroState } from 'assets/images/zero-states/emails.svg'
import { ReactComponent as DraftEmailsZeroState } from 'assets/images/zero-states/draft-emails.svg'
import { ReactComponent as ScheduledEmailsZeroState } from 'assets/images/zero-states/scheduled-emails.svg'
import { ReactComponent as PendingRequestsZeroState } from 'assets/images/zero-states/pending-requests.svg'
import { ReactComponent as MetricsDataZeroState } from 'assets/images/zero-states/no-metric-data.svg'
import { ReactComponent as MilestonesZeroState } from 'assets/images/zero-states/no-milestones.svg'
import { ReactComponent as ListsZeroState } from 'assets/images/zero-states/no-lists.svg'
import { ReactComponent as IntegrationsZeroState } from 'assets/images/zero-states/no-integrations.svg'
import { ReactComponent as PortfolioHoldingsZeroState } from 'assets/images/zero-states/portfolio-holdings.svg'
import { ReactComponent as InvestorMembersZeroState } from 'assets/images/zero-states/investor-members-zero-state.svg'
import { ReactComponent as NoInvestors } from 'assets/images/zero-states/no-investors.svg'
import { ReactComponent as NoVehicles } from 'assets/images/zero-states/no-vehicles.svg'
import { ReactComponent as NoVehiclesOrInvestorsFound } from 'assets/images/zero-states/no-vehicles-investors-with-selected-filters.svg'
import { ReactComponent as NoFundHoldingsZeroState } from 'assets/images/zero-states/no-fund-holdings.svg'
import { ReactComponent as FundsZeroState } from 'assets/images/zero-states/funds-zero-state.svg'
import { ReactComponent as FundHoldingsZeroState } from 'assets/images/zero-states/fund-holdings-zero-state.svg'
import { ReactComponent as NoFairMarketValueHistoryZeroState } from 'assets/images/zero-states/no-fair-market-value-history.svg'
import { ReactComponent as FollowdHoldingsZeroState } from 'assets/images/zero-states/followed-holdings.svg'
import { ReactComponent as FilesNoUnreconciledZeroState } from 'assets/images/zero-states/files.svg'
import { ReactComponent as NoFilesFound } from 'assets/images/zero-states/no-files.svg'
import { ReactComponent as FilesZeroState } from 'assets/images/zero-states/files-zero-state.svg'
import { ReactComponent as NoChatsZeroState } from 'assets/images/zero-states/no-chats.svg'
import { ReactComponent as NoMessagesZeroState } from 'assets/images/zero-states/no-messages.svg'
import { ReactComponent as ChatZeroState } from 'assets/images/zero-states/chat.svg'
import { ReactComponent as SearchZeroState } from 'assets/images/zero-states/search.svg'
import { ReactComponent as PublishUpdateZeroState } from 'assets/images/zero-states/publish-update-zero-state.svg'
import { ReactComponent as InvestmentFiltersZeroState } from 'assets/images/zero-states/empty_filter_entity.svg'
import { ReactComponent as UpdatesTableZeroState } from 'assets/images/zero-states/updates_table_zero_state.svg'
import { ReactComponent as NoResults } from 'assets/images/zero-states/no-results.svg'
import { ReactComponent as SelectedMetrics } from 'assets/images/zero-states/selected-metrics.svg'
import { ReactComponent as NoSandboxedMetric } from 'assets/images/zero-states/no-sandboxed-metric.svg'

import {
  ZeroStateContainer,
  ZeroStateFigure,
  ZeroStateTitle,
  ZeroStateText,
  ZeroStateHelpLink,
  LinksContainer,
  HelpWrapper,
  LinkText,
  ZeroStateInfo,
  Delimiter,
  CallToActionLink,
  NeedHelp,
} from './ZeroState.styles'

export const ZeroStateType = {
  FOUNDER_METRICS: 'FOUNDER_METRICS',
  FOUNDER_METRICS_NO_TRACKING: 'FOUNDER_METRICS_NO_TRACKING',
  PORTFOLIO_METRICS: 'PORTFOLIO_METRICS',
  PORTFOLIO_HOLDINGS: 'PORTFOLIO_HOLDINGS',
  INVESTOR_FUND_HOLDINGS: 'INVESTOR_FUND_HOLDINGS',
  INVESTOR_FUNDS_DEALS: 'INVESTOR_FUNDS_DEALS',
  FUND_HOLDINGS: 'FUND_HOLDINGS',
  FUND_INVESTORS: 'FUND_INVESTORS',
  PORTFOLIOS: 'PORTFOLIOS',
  UPDATES_FEED: 'UPDATES_FEED',
  COMPANY_UPDATES_FEED: 'COMPANY_UPDATES_FEED',
  COMPANY_PROFILE_METRICS: 'COMPANY_PROFILE_METRICS',
  HOLDINGS: 'HOLDINGS',
  DASHBOARD: 'DASHBOARD',
  FUND_DASHBOARD: 'FUND_DASHBOARD',
  READONLY_DASHBOARD: 'READONLY_DASHBOARD',
  EMPTY_PORTFOLIO: 'EMPTY_PORTFOLIO',
  EMAILS: 'EMAILS',
  DRAFT_EMAILS: 'DRAFT_EMAILS',
  SCHEDULED_EMAILS: 'SCHEDULED_EMAILS',
  SCHEDULED_UPDATES: 'SCHEDULED_UPDATES',
  PENDING_REQUESTS: 'PENDING_REQUESTS',
  METRICS_DATA: 'METRICS_DATA',
  METRICS_UNARCHIVED_DATA: 'METRICS_UNARCHIVED_DATA',
  MILESTONES: 'MILESTONES',
  ACTIVE_MILESTONES: 'ACTIVE_MILESTONES',
  LISTS: 'LISTS',
  INTEGRATIONS: 'INTEGRATIONS',
  INVESTOR_MEMBERS: 'INVESTOR_MEMBERS',
  INVESTOR_FUNDS: 'INVESTOR_FUNDS',
  VEHICLE_FUNDS: 'VEHICLE_FUNDS',
  VEHICLES: 'VEHICLES',
  NO_VEHICLES: 'NO_VEHICLES',
  NO_INVESTORS: 'NO_INVESTORS',
  NO_INVESTORS_FOUND: 'NO_INVESTORS_FOUND',
  NO_VEHICLES_FOUND: 'NO_VEHICLES_FOUND',
  NO_HOLDINGS_FOUND: 'NO_HOLDINGS_FOUND',
  NO_HOLDINGS_RESULTS: 'NO_HOLDINGS_RESULTS',
  NO_FUND_HOLDING_RESULTS: 'NO_FUND_HOLDING_RESULTS',
  NO_FUND_HOLDINGS: 'NO_FUND_HOLDINGS',
  INVESTOR_FUND_LIST: 'INVESTOR_FUND_LIST',
  NO_INVESTOR_FUND_LIST_RESULTS: 'NO_INVESTOR_FUND_LIST_RESULTS',
  NO_PORTFOLIO_LIST_RESULTS: 'NO_PORTFOLIO_LIST_RESULTS',
  FUND_PORTFOLIOS: 'FUND_PORTFOLIOS',
  UPDATES: 'UPDATES',
  NO_FMV_HISTORY: 'NO_FMV_HISTORY',
  FOLLOWED_HOLDINGS: 'FOLLOWED_HOLDINGS',
  FILES: 'FILES',
  MODAL_FILES: 'MODAL_FILES',
  NO_FILES_FOUND: 'NO_FILES_FOUND',
  NO_UNRECONCILED_FOUND: 'NO_UNRECONCILED_FOUND',
  NO_FILES_MODAL_FOUND: 'NO_FILES_MODAL_FOUND',
  NO_FILES_MODAL_UNRECONCILED_FOUND: 'NO_FILES_MODAL_UNRECONCILED_FOUND',
  NO_LISTS_RESULTS: 'NO_LISTS_RESULTS',
  NO_CHATS: 'NO_CHATS',
  NO_MESSAGES: 'NO_MESSAGES',
  CHAT_SEARCH: 'CHAT_SEARCH',
  CHAT_SEARCH_RESULTS: 'CHAT_SEARCH_RESULTS',
  NO_INVESTMENTS: 'NO_INVESTMENTS',
  PUBLISH_UPDATE: 'PUBLISH_UPDATE',
  EMPTY_INVESTMENT_FILTER: 'EMPTY_INVESTMENT_FILTER',
  UPDATES_TABLE: 'UPDATES_TABLE',
  ALL_INVESTMENTS: 'ALL_INVESTMENTS',
  NO_SEARCH_RESULTS: 'NO_SEARCH_RESULTS',
  SELECTED_METRICS: 'SELECTED_METRICS',
  SANDBOXED_METRICS: 'SANDBOXED_METRICS',
  SANDBOXED_METRICS_FROM_INVESTMENTS: 'SANDBOXED_METRICS_FROM_INVESTMENTS',
}

const getImageForZeroStateType = (type) => {
  switch (type) {
    case ZeroStateType.FOUNDER_METRICS:
      return MetricZeroStateLogo
    case ZeroStateType.FOUNDER_METRICS_NO_TRACKING:
    case ZeroStateType.UPDATES_FEED:
      return UpdatesFeedZeroState
    case ZeroStateType.COMPANY_UPDATES_FEED:
      return CompanyUpdatesFeedZeroState
    case ZeroStateType.COMPANY_PROFILE_METRICS:
      return MetricZeroStateLogo
    case ZeroStateType.PORTFOLIOS:
      return PortfoliosZeroState
    case ZeroStateType.NO_INVESTMENTS:
      return PortfoliosZeroState
    case ZeroStateType.HOLDINGS:
      return HoldingsListZeroState
    case ZeroStateType.DASHBOARD:
    case ZeroStateType.FUND_DASHBOARD:
    case ZeroStateType.READONLY_DASHBOARD:
    case ZeroStateType.EMPTY_PORTFOLIO:
      return DashboardZeroState
    case ZeroStateType.PORTFOLIO_METRICS:
      return MetricZeroStateLogo
    case ZeroStateType.EMAILS:
      return EmailsZeroState
    case ZeroStateType.DRAFT_EMAILS:
      return DraftEmailsZeroState
    case ZeroStateType.SCHEDULED_EMAILS:
    case ZeroStateType.SCHEDULED_UPDATES:
      return ScheduledEmailsZeroState
    case ZeroStateType.PENDING_REQUESTS:
      return PendingRequestsZeroState
    case ZeroStateType.METRICS_DATA:
    case ZeroStateType.METRICS_UNARCHIVED_DATA:
      return MetricsDataZeroState
    case ZeroStateType.MILESTONES:
    case ZeroStateType.ACTIVE_MILESTONES:
      return MilestonesZeroState
    case ZeroStateType.LISTS:
      return ListsZeroState
    case ZeroStateType.INTEGRATIONS:
      return IntegrationsZeroState
    case ZeroStateType.PORTFOLIO_HOLDINGS:
    case ZeroStateType.FUND_HOLDINGS:
    case ZeroStateType.INVESTOR_FUND_HOLDINGS:
    case ZeroStateType.INVESTOR_FUNDS_DEALS:
    case ZeroStateType.INVESTOR_FUNDS:
    case ZeroStateType.VEHICLE_FUNDS:
    case ZeroStateType.ALL_INVESTMENTS:
      return PortfolioHoldingsZeroState
    case ZeroStateType.INVESTOR_MEMBERS:
    case ZeroStateType.FUND_INVESTORS:
      return InvestorMembersZeroState
    case ZeroStateType.FUND:
      return PortfolioHoldingsZeroState
    case ZeroStateType.NO_VEHICLES:
      return NoVehicles
    case ZeroStateType.NO_INVESTORS:
      return NoInvestors
    case ZeroStateType.NO_INVESTORS_FOUND:
    case ZeroStateType.NO_VEHICLES_FOUND:
    case ZeroStateType.NO_HOLDINGS_FOUND:
      return NoVehiclesOrInvestorsFound
    case ZeroStateType.NO_FUND_HOLDING_RESULTS:
    case ZeroStateType.NO_HOLDINGS_RESULTS:
    case ZeroStateType.NO_INVESTOR_FUND_LIST_RESULTS:
    case ZeroStateType.NO_PORTFOLIO_LIST_RESULTS:
    case ZeroStateType.NO_LISTS_RESULTS:
      return NoFundHoldingsZeroState
    case ZeroStateType.NO_FUND_HOLDINGS:
      return FundHoldingsZeroState
    case ZeroStateType.FUND_PORTFOLIOS:
    case ZeroStateType.UPDATES:
      return FundsZeroState
    case ZeroStateType.NO_FMV_HISTORY:
      return NoFairMarketValueHistoryZeroState
    case ZeroStateType.FOLLOWED_HOLDINGS:
      return FollowdHoldingsZeroState
    case ZeroStateType.NO_FILES_FOUND:
      return NoFilesFound
    case ZeroStateType.FILES:
      return FilesZeroState
    case ZeroStateType.NO_UNRECONCILED_FOUND:
      return FilesNoUnreconciledZeroState
    case ZeroStateType.NO_CHATS:
      return NoChatsZeroState
    case ZeroStateType.NO_MESSAGES:
      return NoMessagesZeroState
    case ZeroStateType.CHAT_SEARCH:
      return ChatZeroState
    case ZeroStateType.CHAT_SEARCH_RESULTS:
      return SearchZeroState
    case ZeroStateType.PUBLISH_UPDATE:
      return PublishUpdateZeroState
    case ZeroStateType.EMPTY_INVESTMENT_FILTER:
      return InvestmentFiltersZeroState
    case ZeroStateType.UPDATES_TABLE:
      return UpdatesTableZeroState
    case ZeroStateType.NO_SEARCH_RESULTS:
      return NoResults
    case ZeroStateType.SELECTED_METRICS:
      return SelectedMetrics
    case ZeroStateType.SANDBOXED_METRICS_FROM_INVESTMENTS:
    case ZeroStateType.SANDBOXED_METRICS:
      return NoSandboxedMetric
    default:
      return null
  }
}

export const ZeroState = ({
  className,
  type,
  layout,
  padding,
  maxWidth,
  onClickHelp,
  callToAction,
  callToActionText,
  isChatWithSupportEnabled,
  isNeedHelpEnabled,
  linkToGuide,
  onDismissMessage,
  isCallToActionPrimaryStyle,
  disableActionIfReadOnly,
  readOnlyTooltipText,
  readOnlyTooltipPlace,
  readOnlyTooltipMaxWidth,
  infoMarginTop,
  zeroStateLink,
  intlValues,
  readGuideLabel,
  hideText,
  hideTitle,
  textFontSize,
  titleFontSize,
  centerCallToActionButton,
  customButtonStyles,
  titleIcon,
}) => {
  const readOnlyTooltipId = useRandomId()
  if (!ZeroStateType[type]) {
    throw new Error(`Zero state type ${type} is not valid`)
  }
  const intl = useIntl()
  const Image = getImageForZeroStateType(type)

  const renderCallToAction = () => {
    if (!callToAction) {
      return null
    }

    if (disableActionIfReadOnly) {
      return (
        <ReadOnlyTooltip
          id={readOnlyTooltipId}
          text={readOnlyTooltipText}
          place={readOnlyTooltipPlace}
          maxWidth={readOnlyTooltipMaxWidth}
          topOffset={-10}
        >
          <Button
            primary={isCallToActionPrimaryStyle}
            onClick={callToAction}
            style={customButtonStyles}
          >
            {callToActionText}
          </Button>
        </ReadOnlyTooltip>
      )
    }

    if (zeroStateLink) {
      return (
        <CallToActionLink role="button" onClick={callToAction}>
          {callToActionText}
        </CallToActionLink>
      )
    }

    return (
      <Button
        primary={isCallToActionPrimaryStyle}
        onClick={callToAction}
        style={customButtonStyles}
      >
        {callToActionText}
      </Button>
    )
  }

  return (
    <ZeroStateContainer
      className={className}
      layout={layout}
      maxWidth={maxWidth}
      padding={padding}
    >
      {Image && (
        <ZeroStateFigure>
          <Image />
        </ZeroStateFigure>
      )}
      <ZeroStateInfo layout={layout} infoMarginTop={infoMarginTop}>
        {!hideTitle && (
          <ZeroStateTitle titleFontSize={titleFontSize}>
            <FormattedMessage
              id={`zeroState.${type?.toLowerCase()}.title`}
              values={intlValues}
            />

            {titleIcon && (
              <FontAwesomeIcon
                icon={titleIcon}
                style={{ marginLeft: '0.8rem' }}
              />
            )}
          </ZeroStateTitle>
        )}
        {!hideText && (
          <ZeroStateText textFontSize={textFontSize}>
            <FormattedMessage
              id={`zeroState.${type?.toLowerCase()}.text`}
              values={intlValues}
            />
          </ZeroStateText>
        )}
        {(isChatWithSupportEnabled || linkToGuide) && (
          <LinksContainer>
            {isNeedHelpEnabled && (
              <NeedHelp>
                <FormattedMessage id="zeroState.needHelp" />
              </NeedHelp>
            )}
            <HelpWrapper className="zero-state-help-wrapper" layout={layout}>
              {isChatWithSupportEnabled && (
                <LinkText
                  onClick={() => {
                    window.Intercom('show')
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'comment']} />
                  <FormattedMessage id="zeroState.chatWithSupport" />
                </LinkText>
              )}
              {linkToGuide && (
                <LinkText
                  href={linkToGuide}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={['far', 'book-alt']} />
                  {readGuideLabel || (
                    <FormattedMessage id="zeroState.readGuide" />
                  )}
                </LinkText>
              )}
              {onDismissMessage && (
                <>
                  <Delimiter className="delimeter" />
                  <LinkText onClick={onDismissMessage}>
                    <FontAwesomeIcon icon={['far', 'times']} />
                    <FormattedMessage id="zeroState.dismissMessage" />
                  </LinkText>
                </>
              )}
            </HelpWrapper>
          </LinksContainer>
        )}

        {centerCallToActionButton ? (
          <div>{renderCallToAction()}</div>
        ) : (
          renderCallToAction()
        )}
      </ZeroStateInfo>
    </ZeroStateContainer>
  )
}

ZeroState.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ZeroStateType)).isRequired,
  onClickHelp: PropTypes.func,
  layout: PropTypes.string,
  padding: PropTypes.string,
  maxWidth: PropTypes.string,
  callToAction: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  callToActionText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isChatWithSupportEnabled: PropTypes.bool,
  linkToGuide: PropTypes.string,
  onDismissMessage: PropTypes.func,
  isCallToActionPrimaryStyle: PropTypes.bool,
  disableActionIfReadOnly: PropTypes.bool,
  readOnlyTooltipText: PropTypes.string,
  readOnlyTooltipPlace: PropTypes.string,
  readOnlyTooltipMaxWidth: PropTypes.string,
  infoMarginTop: PropTypes.string,
  readGuideLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  zeroStateLink: PropTypes.bool,
  hideText: PropTypes.bool,
  isNeedHelpEnabled: PropTypes.bool,
  hideTitle: PropTypes.bool,
  intlValues: PropTypes.object,
  textFontSize: PropTypes.string,
  titleIcon: PropTypes.arrayOf(PropTypes.string),
  titleFontSize: PropTypes.string,
  customButtonStyles: PropTypes.object,
  centerCallToActionButton: PropTypes.bool,
}

ZeroState.defaultProps = {
  className: '',
  onClickHelp: null,
  callToAction: null,
  callToActionText: '',
  isChatWithSupportEnabled: false,
  isNeedHelpEnabled: true,
  linkToGuide: '',
  layout: LAYOUT.VERTICAL,
  padding: '',
  maxWidth: '',
  isCallToActionPrimaryStyle: false,
  disableActionIfReadOnly: false,
  zeroStateLink: false,
  hideText: false,
  hideTitle: false,
  readOnlyTooltipText: '',
  infoMarginTop: '',
  readOnlyTooltipPlace: 'bottom',
  readOnlyTooltipMaxWidth: '26.3rem',
  readGuideLabel: '',
  customButtonStyles: {},
  centerCallToActionButton: false,
  intlValues: undefined,
  onDismissMessage: undefined,
  textFontSize: undefined,
  titleFontSize: undefined,
  titleIcon: undefined,
}
