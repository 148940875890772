import * as light from './light'
import * as regular from './regular'
import * as solid from './solid'
import * as freeBrand from './free-brand'

const icons = [
  ...Object.values(light),
  ...Object.values(regular),
  ...Object.values(solid),
  ...Object.values(freeBrand),
]

export default icons
