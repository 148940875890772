import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UPDATES } from 'routes/constant/commonRoutes'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import { Update } from 'utils/types/update'

interface Props {
  update?: Update
  isCreatingNewUpdate: boolean
}

const FounderUpdatesBreadcrumb = ({ update, isCreatingNewUpdate }: Props) => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <NavigationWrapper>
      <>
        <InitialLink
          isActive={false}
          label={intl.formatMessage({ id: 'breadcrumb.updates' })}
          onClickHandler={() => history.push(UPDATES)}
        />

        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      </>

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default FounderUpdatesBreadcrumb
