import { useIntl } from 'react-intl'
import {
  PREVIEW_DRAFT_PATH,
  useIsPreviewingDraftUpdate,
} from 'utils/hooks/useIsPreviewingDraftUpdate'
import { useHistory } from 'react-router-dom'
import { Button } from './PreviewUpdateButton.styles'

const PreviewUpdateButton = () => {
  const intl = useIntl()
  const history = useHistory()

  const isPreviewingDraft = useIsPreviewingDraftUpdate()

  const previewText = isPreviewingDraft
    ? intl.formatMessage({ id: 'updates.previewOriginal' })
    : intl.formatMessage({ id: 'updates.previewDraft' })

  const handlePreview = () => {
    const locationPathname = history.location.pathname
    const newPathName = isPreviewingDraft
      ? locationPathname.replace(PREVIEW_DRAFT_PATH, '')
      : `${locationPathname}${PREVIEW_DRAFT_PATH}`
    history.replace({
      pathname: newPathName,
    })
  }

  return (
    <Button secondary icon={['far', 'eye']} onClick={handlePreview}>
      {previewText}
    </Button>
  )
}

export default PreviewUpdateButton
