import React from 'react'
import PropTypes from 'prop-types'

import { StyledHeading } from './Heading.styles'

const Heading = ({ className, level, children, margin }) => {
  return (
    <StyledHeading className={className} margin={margin} as={level}>
      {children}
    </StyledHeading>
  )
}

Heading.propTypes = {
  className: PropTypes.string,
  level: PropTypes.string,
  children: PropTypes.node.isRequired,
  margin: PropTypes.string,
}

Heading.defaultProps = {
  className: '',
  level: 'h1',
  margin: '',
}

export default Heading
