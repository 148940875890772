import { Formik } from 'formik'
import { useIntl } from 'react-intl'

import Button from 'ui/Button'
import List from 'components/List/List'
import Modal from 'components/Modal'
import TopicEntityDropdown from 'components/TopicEntityDropdown'
import { getChatFormSchema } from 'utils/schemas/chat'

import ChatMembersPicker from '../ChatMembersPicker/ChatMembersPicker'
import {
  ParticipantsContainer,
  ParticipantsZeroState,
  StyledModal,
  participantsContainerHeight,
  rowHeight,
  Body,
} from './CreateChatModal.styles'
import ChatMemberRow from './components/ChatMemberRow'
import { ChatForm, CreateChatProps, useCreateChat } from './useCreateChat'
import TitleInput from './components/TitleInput'

const CreateChatModal = ({
  show,
  onHide,
  initialValues,
  initialMessage,
  onChatCreated,
  preventNavigationOnCreate,
}: CreateChatProps) => {
  const intl = useIntl()

  const {
    formikRef,
    isTitleVisible,
    onMembersSelected,
    onMemberRemoved,
    onCreateChannel,
  } = useCreateChat({
    show,
    onHide,
    initialMessage,
    onChatCreated,
    preventNavigationOnCreate,
  })

  return (
    <StyledModal show={show} onHide={onHide}>
      <Formik<ChatForm>
        innerRef={formikRef}
        initialValues={
          initialValues || {
            members: [],
          }
        }
        validationSchema={getChatFormSchema(intl)}
        validateOnMount
        onSubmit={onCreateChannel}
      >
        {({ handleSubmit, values, isSubmitting, isValid }) => (
          <>
            <Modal.Header onHide={onHide}>
              <Modal.H2Title>
                {initialValues?.topic
                  ? intl.formatMessage(
                      { id: 'chat.newChatOn' },
                      {
                        topic: initialValues.topic.label,
                      }
                    )
                  : intl.formatMessage({ id: 'chat.newChat' })}
              </Modal.H2Title>
            </Modal.Header>
            <Body>
              {!!initialValues && (
                <TitleInput isTitleVisible={isTitleVisible(values)} />
              )}

              <ChatMembersPicker
                members={values.members}
                onMembersSelected={onMembersSelected}
              />

              <ParticipantsContainer
                hasScroll={
                  participantsContainerHeight <
                  rowHeight * values.members.length
                }
              >
                {values.members.length > 0 ? (
                  <List
                    elements={values.members.map((memberData, index) => (
                      <ChatMemberRow
                        key={memberData.id}
                        image={memberData.imageUrl}
                        name={memberData.name}
                        onRemoveMember={() => onMemberRemoved(index)}
                      />
                    ))}
                    keyExtractor={(member) => member.id}
                    striped={false}
                  />
                ) : (
                  <ParticipantsZeroState>
                    <h1>
                      {intl.formatMessage({
                        id: 'chat.noParticipantsZeroState',
                      })}
                    </h1>
                    <p>
                      {intl.formatMessage({
                        id: 'chat.noParticipantsZeroStateDescription',
                      })}
                    </p>
                  </ParticipantsZeroState>
                )}
              </ParticipantsContainer>

              {!initialValues && (
                <>
                  <TopicEntityDropdown
                    name="topic"
                    id="chat-topic"
                    label={intl.formatMessage({ id: 'chat.topic' })}
                  />
                  <TitleInput isTitleVisible={isTitleVisible(values)} />
                </>
              )}
            </Body>
            <Modal.RowFooter>
              <Button secondary onClick={onHide}>
                {intl.formatMessage({ id: 'general.cancel' })}
              </Button>
              <Button
                primary
                onClick={handleSubmit}
                disabled={isSubmitting || !isValid}
              >
                {intl.formatMessage({ id: 'common.create' })}
              </Button>
            </Modal.RowFooter>
          </>
        )}
      </Formik>
    </StyledModal>
  )
}

export default CreateChatModal
