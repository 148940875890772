import Celeste from 'components/Celeste'
import CWLoader from 'components/CWLoader'
import { StyledShowInvestorLoading } from 'containers/InvestorManagement/ShowInvestor/ShowInvestor.styles'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import Layout from 'containers/Profiles/components/Layout'
import InvestmentVehicleMainContent from 'containers/Profiles/components/MainContent/InvestmentVehicleMainContent'
import InvestmentVehicleInformation from 'containers/Profiles/components/SideInformation/InvestmentVehicleInformation'
import {
  INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES,
  VEHICLES_ALL_UPDATES,
} from 'routes/constant/investorManagementRoutes'
import HeaderContent from 'containers/Profiles/components/HeaderContent/HeaderContent'

import { ProfileType } from 'containers/Profiles/types'
import { ProfileProvider } from 'containers/Profiles/ProfileContext'
import useInvestmentVehicleProfile from './useInvestmentVehicleProfile'

const InvestmentVehicleProfile = () => {
  const { investmentVehicleId, investorId } = useParams<{
    investmentVehicleId: string
    investorId?: string
  }>()

  const isComingFromInvestorProfile = !!investorId

  const {
    investmentVehicle,
    fundsPortfolioInvestor,
    isLoading,
    isLoadingFunds,
    onShowTransaction,
    onEditTransaction,
  } = useInvestmentVehicleProfile({ investmentVehicleId, investorId })

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  if (isLoading || isLoadingFunds) {
    return (
      <StyledShowInvestorLoading>
        <CWLoader />
      </StyledShowInvestorLoading>
    )
  }

  return (
    <ProfileProvider>
      <Layout>
        <Layout.Header type={ProfileType.INVESTMENT_VEHICLE}>
          <HeaderContent />
        </Layout.Header>

        <Layout.SideContent>
          <InvestmentVehicleInformation
            investmentVehicle={investmentVehicle!}
          />
        </Layout.SideContent>

        <Layout.MainContent>
          <InvestmentVehicleMainContent
            investmentVehicleId={investmentVehicleId}
            isLoadingFunds={isLoadingFunds}
            fundsPortfolioInvestor={fundsPortfolioInvestor}
            onShowTransaction={onShowTransaction}
            onEditTransaction={onEditTransaction}
          />
        </Layout.MainContent>

        {!isInvestorGroup && (
          <Celeste
            prefixUrl={
              isComingFromInvestorProfile
                ? INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES.replace(
                    ':investorId',
                    investorId
                  ).replace(':investmentVehicleId', investmentVehicleId)
                : VEHICLES_ALL_UPDATES.replace(
                    ':investmentVehicleId',
                    investmentVehicleId
                  )
            }
            showCreateTransactionOption
          />
        )}
      </Layout>
    </ProfileProvider>
  )
}

export default InvestmentVehicleProfile
