import React from 'react'
import { FundSummary } from 'utils/types/funds'
import { FundPortfolio } from 'utils/types/portfolios'
import { useIntl, FormattedDate, FormattedNumber } from 'react-intl'
import Grid from 'components/Portfolios/Grid'
import PortfolioDashboardLoader from 'components/loaders/portfolioDashboardLoader'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { FundSummaryRowWrapper } from './FundSummaryRow.styles'
import Chart from './Chart'

enum DataType {
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  MULTIPLIER = 'MULTIPLIER',
  STRING = 'STRING',
  PERCENT = 'PERCENT',
}

interface Props {
  isLoading?: boolean
  data?: FundSummary
  chartData?: FundPortfolio[]
  investorCalculationEnabled?: boolean
}

interface InvestRowProps {
  fundSummaryData: FundSummary
  chartData?: FundPortfolio[]
  investorCalculationEnabled?: boolean
}

const InvestRow: React.FC<InvestRowProps> = ({
  fundSummaryData,
  chartData = [],
  investorCalculationEnabled,
}) => {
  const intl = useIntl()

  const getFieldValue = (
    value?: number | string,
    type: DataType = DataType.CURRENCY,
    countDecimals: number = 0
  ) => {
    if (!value && value !== 0) return null

    if (type === DataType.CURRENCY && typeof value === 'number')
      return (
        <FormattedNumber
          value={value}
          // eslint-disable-next-line react/style-prop-object
          style="currency"
          currency="USD"
          currencyDisplay="narrowSymbol"
          minimumFractionDigits={countDecimals}
          maximumFractionDigits={countDecimals}
        />
      )

    if (type === DataType.DATE)
      return <FormattedDate year="numeric" value={value} />

    if (type === DataType.MULTIPLIER) return `${Number(value).toFixed(2)}x`

    if (type === DataType.NUMBER)
      return (
        <FormattedNumber
          value={Number(value || 0)}
          minimumFractionDigits={0}
          maximumFractionDigits={2}
        />
      )

    if (type === DataType.PERCENT)
      return (
        <FormattedNumber
          value={Number(value) / 100 || 0}
          // eslint-disable-next-line react/style-prop-object
          style="percent"
          minimumFractionDigits={countDecimals}
          maximumFractionDigits={countDecimals}
        />
      )

    return String(value)
  }

  const FIELDS = [
    {
      label: intl.formatMessage({ id: 'portfolios.totalInvested' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalInvestedInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalInvested',
          }),
      value: getFieldValue(fundSummaryData.totalInvested),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.totalFairMarketValue' }),
      tooltip: intl.formatMessage({
        id: 'portfolioDetail.tooltips.totalFairMarketValue',
      }),
      value: getFieldValue(fundSummaryData.totalFairMarketValue),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.totalDistributed' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalDistributedInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalDistributed',
          }),
      value: getFieldValue(fundSummaryData.totalDistributed),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.totalValue' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalValueInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalValue',
          }),
      value: getFieldValue(fundSummaryData.totalValue),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.totalUnfundedCommitment' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalUnfundedCommitmentInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalUnfundedCommitment',
          }),
      value: getFieldValue(fundSummaryData.portfolioTotalUnfundedCommitment),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.totalHoldings' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalHoldingsInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalHoldings',
          }),
      value: getFieldValue(fundSummaryData.totalHoldings, DataType.STRING),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.totalInvestments' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalInvestmentsInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.totalInvestments',
          }),
      value: getFieldValue(fundSummaryData.totalInvestments, DataType.STRING),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.averageHoldingSize' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.averageHoldingSizeInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.averageHoldingSize',
          }),
      value: getFieldValue(fundSummaryData.averageInvestmentPerCompany),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.firstInvestment' }),
      tooltip: investorCalculationEnabled
        ? intl.formatMessage({
            id: 'portfolioDetail.tooltips.firstInvestmentInvestor',
          })
        : intl.formatMessage({
            id: 'portfolioDetail.tooltips.firstInvestment',
          }),
      value: getFieldValue(fundSummaryData.firstInvestmentDate, DataType.DATE),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.distributionToPaidIn' }),
      tooltip: intl.formatMessage({
        id: 'portfolioDetail.tooltips.distributionToPaidIn',
      }),
      value: getFieldValue(
        fundSummaryData.distributionToPaidIn,
        DataType.MULTIPLIER
      ),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.multipleOnInvestedCapital' }),
      tooltip: intl.formatMessage({
        id: 'portfolioDetail.tooltips.multipleOnInvestedCapital',
      }),
      value: getFieldValue(
        fundSummaryData.multipleOnInvestedCapital,
        DataType.MULTIPLIER
      ),
    },
    {
      label: intl.formatMessage({ id: 'portfolios.irr' }),
      tooltip: intl.formatMessage({
        id: 'portfolioDetail.tooltips.irr',
      }),
      value:
        fundSummaryData.irr ||
        fundSummaryData.irr === 0 ||
        fundSummaryData.irr === null
          ? getFieldValue(fundSummaryData.irr, DataType.PERCENT, 2)
          : '-',
    },
  ].filter((field) => field.value !== null && field.value !== undefined)

  const { matches: isMobileAndSmallDesktop } = useMediaQuery(Media.MAX_XL)

  return (
    <FundSummaryRowWrapper>
      <Grid
        columnsQuantity={
          isMobileAndSmallDesktop ? 2 : Math.min(FIELDS.length, 4)
        }
      >
        {FIELDS.map((field) => (
          <Grid.Cell
            label={field.label}
            tooltip={field.tooltip}
            value={field.value}
          />
        ))}
      </Grid>
      <Chart chartData={chartData} />
    </FundSummaryRowWrapper>
  )
}

const FundSummaryRow = ({
  isLoading,
  data,
  chartData,
  investorCalculationEnabled,
}: Props) => {
  if (isLoading) return <PortfolioDashboardLoader />

  return (
    <InvestRow
      fundSummaryData={data!}
      chartData={chartData}
      investorCalculationEnabled={investorCalculationEnabled}
    />
  )
}

export default FundSummaryRow
