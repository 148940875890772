import missingPNGPath from 'assets/images/missing.png'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

import { AvatarContainer, Circle, Initials } from './Avatar.styles'

interface AvatarProps {
  size: string
  minSize?: string
  imageUrl?: string
  initials?: string
  borderWidth?: number
  initialsSize?: string
  children?: React.ReactNode
}

const Avatar = ({
  imageUrl,
  initials,
  size,
  minSize,
  borderWidth = 1,
  children,
  initialsSize,
}: AvatarProps) => {
  const getImage = (url: string) => {
    return (
      <image
        width="100%"
        height="100%"
        xlinkHref={url}
        clipPath="url(#circle)"
      />
    )
  }

  const getAvatar = () => {
    if (imageUrl && !imageUrl.endsWith('missing.png')) {
      return getImage(imageUrl)
    }

    if (initials) {
      return (
        <>
          <Circle cx="50%" cy="50%" r={`calc(50% - ${borderWidth}px)`} />
          <Initials
            x="50%"
            y="50%"
            dominantBaseline="central"
            textAnchor="middle"
            initialsSize={initialsSize}
          >
            {initials}
          </Initials>
        </>
      )
    }

    return getImage(missingPNGPath)
  }

  return (
    <AvatarContainer size={size} minSize={minSize}>
      <defs>
        <clipPath id="circle">
          <circle
            fill="none"
            cx="50%"
            cy="50%"
            r={`calc(50% - ${borderWidth}px)`}
          />
        </clipPath>
      </defs>
      {children ? (
        <foreignObject width="100%" height="100%" clipPath="url(#circle)">
          {children}
        </foreignObject>
      ) : (
        getAvatar()
      )}
      <rect
        width={`calc(100% - ${borderWidth}px)`}
        height={`calc(100% - ${borderWidth}px)`}
        x={borderWidth / 2}
        y={borderWidth / 2}
        fill="none"
        stroke={color('veryLightGray')({ theme })}
        strokeWidth={borderWidth}
        rx="50%"
        ry="50%"
        dominantBaseline="central"
        textAnchor="middle"
      />
    </AvatarContainer>
  )
}

export default Avatar
