export default {
  newChat: 'New Chat',
  copyUpdateLink: 'Copy Update Link',
  duplicateUpdate: 'Duplicate Update',
  editUpdate: 'Edit Update',
  addMoreRecipients: 'Add More Recipients',
  reshareUpdate: 'Reshare Update',
  navigation: {
    previous: 'Previous Update',
    next: 'Next Update',
  },
}
