import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from 'components/Input'
import Modal from 'components/Modal'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const FormRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.8rem;
  height: 8.6rem;
  margin-bottom: 0.2rem;

  & > * {
    flex: 1;
  }
`

export const ModalWrapper = styled(Modal)`
  width: 74rem;
`
export const ModalFooter = styled(Modal.Footer)`
  border-top: 0.1rem solid #dfe6ed;
  width: calc(100% + 4.7rem);
  transform: translateX(-2.35rem);
  display: flex;
  justify-content: flex-end;
  padding: 2.4rem 3.2rem 0 3.2rem;
  button {
    margin-left: 0.8rem;
  }
`

export const CheckboxWrapper = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 2rem;
`

export const URLInput = styled(Input)`
  padding-left: 0.4rem;
`

export const InputIcon = styled(FontAwesomeIcon)`
  min-width: 1.8rem;
  height: 1.8rem;
  color: ${color('darkBlue')};
`
