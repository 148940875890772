import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import IconButton from 'ui/IconButton'
import { color } from 'utils/functions/colors'

export const LocationRowContainer = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  padding: 1.6rem;

  > div {
    display: flex;
    align-items: center;
  }

  > div:nth-child(2) {
    justify-self: end;
  }
`

export const EmailRowContainer = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 2fr 1fr;
  height: 100%;
  padding: 1.6rem;
  gap: 1rem;

  > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  > div:nth-child(2) {
    justify-self: end;
  }
`

const RowContent = styled.span`
  font-size: 1.4rem;
`

export const LocationRowContent = styled(RowContent)`
  display: flex;
`

export const EmailRowContent = styled(RowContent)`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const WarningIcon = styled(FontAwesomeIcon)`
  color: ${color('darkYellow')};
  margin-right: 1.6rem;
`

export const BoldLabel = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('black')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const MediumLabel = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('black')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const LightLabel = styled.span`
  font-size: 1.2rem;
  color: ${color('lightGray')};
  line-height: 1.4rem;
`

export const SetAsMainButton = styled.a`
  color: ${color('primaryBlue')};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
`

export const DeleteButton = styled(IconButton)`
  margin-left: 0.5rem;

  &:hover {
    background-color: transparent;
    background: transparent;

    > svg {
      color: var(--color-red-default);
    }
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
`

export const MainTag = styled.span`
  background-color: ${color('primaryBlue')};
  border-radius: 10rem;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  height: fit-content;
  padding: 0.3rem 0.8rem;
  text-transform: uppercase;
  width: 5rem;
`

export const InvestorUserRowContainer = styled.div`
  display: grid;
  padding: 1.5rem 1.5rem 1.5rem 2rem;
  gap: 0.8rem;
  align-items: flex-start;

  button {
    margin-top: -1rem;
  }
`

export const InvestorPendingUserRowContainer = styled(InvestorUserRowContainer)`
  grid-template-columns: 1fr 18.3rem 0.5fr 0.5fr;
`
export const InvestorConfirmedUserRowContainer = styled(
  InvestorUserRowContainer
)`
  grid-template-columns: 1fr 22.8rem 0.5fr;
`
export const InvestorUserMainCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`
