import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import ButtonToStyle from 'ui/Button'

export const Legend = styled.div`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
`

export const Link = styled(ButtonToStyle)`
  padding: 0 0 0.2rem 0.5rem;

  &&:hover {
    background-color: initial;
  }

  &&:focus:not(:active) {
    border-color: transparent;
  }
`
