import { useCallback, useState } from 'react'

export const useShowMore = <T>(maxSize: number, list?: T[]) => {
  const [isShowingMore, setIsShowingMore] = useState(false)

  const fullItems = list || []
  const isShowMoreButtonVisible = fullItems.length > maxSize + 1
  const items =
    isShowingMore || !isShowMoreButtonVisible
      ? fullItems
      : fullItems.slice(0, maxSize)
  const showMoreCount = fullItems.length - maxSize

  const toggleShowMore = useCallback(() => {
    setIsShowingMore((currentIsShowingMore) => !currentIsShowingMore)
  }, [])

  return {
    toggleShowMore,
    isShowingMore,
    items,
    isShowMoreButtonVisible,
    showMoreCount,
  }
}
