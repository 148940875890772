import { PlainUpdateType } from 'utils/types/common'
import { UpdateTypesOption } from 'utils/functions/normalizers/fileContentNormalizer'
import { FormatType } from './types'

export const setAllUpdateTypesToNotActive = (
  updateTypes: UpdateTypesOption[]
) => {
  return updateTypes.map((updateType) => ({
    ...updateType,
    active: false,
  }))
}

export const setAllFormatTypesToNotActive = (formatTypes: FormatType[]) => {
  return formatTypes.map((formatType) => ({
    ...formatType,
    active: false,
  }))
}

export const getUpdateTypes = (
  updateTypes: UpdateTypesOption[]
): PlainUpdateType[] => {
  const updateTypesToApply = updateTypes
    .filter((updateType) => updateType.active)
    .map((updateType) => updateType.type)

  return updateTypesToApply
}

export const getFormatTypes = (formatTypes: FormatType[]): string[] => {
  const formatTypesToApply = formatTypes
    .filter((formatType) => formatType.active)
    .map((formatType) => formatType.values)
    .flat()

  return formatTypesToApply
}
