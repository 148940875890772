import { useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { contentsKeys } from 'utils/queries/contents'
import { MixedContent } from 'utils/types/files'
import ContentService, { ContentFilters } from 'api/ContentService'
import useInfiniteScroll from '../useInfiniteScroll'

type UseContentInfiniteQueryProps = {
  filters: ContentFilters
  scrollRootElement?: React.RefObject<HTMLElement>
  queryOptions?: UseInfiniteQueryOptions<{
    contents: MixedContent[]
    page: number
  }>
  isInitiallyInfiniteScrollEnabled?: boolean
}

const PAGE_SIZE = 50

export const useContentsInfiniteQuery = ({
  filters,

  queryOptions = {},
  isInitiallyInfiniteScrollEnabled = true,
}: UseContentInfiniteQueryProps) => {
  const {
    ref: infiniteScrollRef,
    onScreen,
    setInfiniteScrollEnabled,
    infiniteScrollEnabled,
  } = useInfiniteScroll({
    initiallyEnabled: isInitiallyInfiniteScrollEnabled,
  })

  const query = useInfiniteQuery(
    contentsKeys.all(filters),
    async ({ pageParam = 1 }) => {
      const contents = await ContentService.fetchContents({
        ...filters,
        page: pageParam,
        pageSize: PAGE_SIZE,
      })

      if (contents.length === 0 || contents.length < PAGE_SIZE) {
        setInfiniteScrollEnabled(false)
      }

      return {
        contents,
        page: pageParam,
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.contents.length === 0) {
          return lastPage.page
        }

        return lastPage.page + 1
      },
      ...queryOptions,
    }
  )

  useEffect(() => {
    if (onScreen && !query.isFetching) {
      query.fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen])

  return {
    ...query,
    onScreen,
    infiniteScrollEnabled,
    setInfiniteScrollEnabled,
    infiniteScrollRef,
  }
}
