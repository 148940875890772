import { useCallback, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import type { FormikHelpers } from 'formik'
import cloneDeep from 'lodash/cloneDeep'
import CompanyService from 'api/CompanyService'
import HoldingsService from 'api/HoldingsService'
import Toast from 'components/Toast'
import HOLDINGS_TEMPLATE_URL from 'assets/holdings_template.csv'
import { download } from 'utils/functions/files'
import { getSingleSchema } from '../schemas'
import {
  AddHoldingForm,
  AddHoldingFormErrors,
  FundManager,
  HoldingType,
} from '../types'
import {
  getInitialErrorsFromSuggestionErrors,
  setRepeatedFundErrors,
  getSuggestionErrors,
  hasServerErrors,
  validateServerErrors,
} from '../errors'
import { getDefaultHoldingValues } from '../utils'

export interface SingleHoldingModalProps {
  onHideModal: () => void
  parseCSV: (file: File) => Promise<void>
  processingFile: boolean
  fileName: string
  onCreateNewHoldings?: (holdingIds: string[]) => void
  name?: string
  fundManager?: FundManager
}

export const useSingleHoldingModal = ({
  onHideModal,
  onCreateNewHoldings,
  name,
  fundManager,
}: SingleHoldingModalProps) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const lastValuesSentToServer = useRef<AddHoldingForm>(
    getDefaultHoldingValues()
  )
  const serverErrors = useRef<AddHoldingFormErrors>({
    company: {},
    funds: {
      funds: [],
    },
  })
  const initialName = useRef(name)
  const initialValues = useMemo(
    () => getDefaultHoldingValues(initialName.current, fundManager),
    [fundManager]
  )
  const validationSchema = useMemo(() => getSingleSchema(intl), [intl])

  const downloadTemplate = useCallback(() => {
    download('holdings.csv', HOLDINGS_TEMPLATE_URL)
  }, [])

  const validate = useCallback(
    (values: AddHoldingForm) => {
      const errors = cloneDeep(serverErrors.current)
      validateServerErrors(errors, values, lastValuesSentToServer.current)

      if (values.type === HoldingType.FUND) {
        setRepeatedFundErrors(values.funds!.funds, errors, intl)
      }

      return hasServerErrors(errors, values) ? errors : {}
    },
    [intl]
  )

  const onSubmit = useCallback(
    async (
      values: AddHoldingForm,
      formikHelpers: FormikHelpers<AddHoldingForm>
    ) => {
      setLoading(true)
      lastValuesSentToServer.current = cloneDeep(values)

      if (values.type === HoldingType.COMPANY) {
        try {
          const {
            data: { entities, result },
          } = await CompanyService.createPublicCompany(values.company!)

          Toast.displayIntl('addHolding.companyCreated', 'success')
          const newCompany = entities?.companyData?.[result]
          onCreateNewHoldings?.([newCompany.id])
          onHideModal()
        } catch (error) {
          const suggestionErrors = await getSuggestionErrors(error.data, values)

          if (suggestionErrors) {
            serverErrors.current =
              getInitialErrorsFromSuggestionErrors(suggestionErrors)
            formikHelpers.setStatus(suggestionErrors)
            formikHelpers.validateForm()
            return
          }

          Toast.displayIntl('addHolding.errors.genericCompany', 'error')
        } finally {
          setLoading(false)
        }
      }

      if (values.type === HoldingType.FUND) {
        try {
          const fundNames = values.funds!.funds.filter((fundName) => !!fundName)
          const newFunds = await HoldingsService.createFundPortfolioProfile({
            fund_portfolios: fundNames.map((fund) => ({
              name: fund,
            })),
            group:
              values.funds!.includeFundManager && values.funds!.fundManager
                ? {
                    name: values.funds!.fundManager.name,
                    email: values.funds!.fundManager.pointOfContact,
                    website: values.funds!.fundManager.website,
                    logo: values.funds!.fundManager.logo?.file,
                  }
                : undefined,
          })

          Toast.displayIntl(
            ['addHolding.fundsCreated', { count: newFunds.length }],
            'success'
          )
          onCreateNewHoldings?.(newFunds.map((fund) => fund.id))
          onHideModal()
        } catch (error) {
          const suggestionErrors = await getSuggestionErrors(error.data, values)

          if (suggestionErrors) {
            serverErrors.current =
              getInitialErrorsFromSuggestionErrors(suggestionErrors)
          }
          formikHelpers.setStatus(suggestionErrors)
          formikHelpers.validateForm()
        } finally {
          setLoading(false)
        }
      }
    },
    [onCreateNewHoldings, onHideModal]
  )

  return {
    intl,
    downloadTemplate,
    initialValues,
    validationSchema,
    validate,
    onSubmit,
    loading,
  }
}
