import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { getCurrentGroupData } from 'selectors/auth'
import { UpdateVisibility } from 'utils/constants/updates'
import { UpdateVisibilityType } from 'utils/types/common'
import {
  IndexUpdate,
  LoggingUpdateGroup,
  LoggingUpdateUser,
  ScheduledUpdate,
} from 'utils/types/update'

import { useAppSelector } from './reduxToolkit'

export const useVisibility = () => {
  const intl = useIntl()
  const visibilityMessageId = 'updates.visibilityOptions'
  const currentGroup = useAppSelector(getCurrentGroupData)

  const getVisibility = useCallback(
    (visibility: UpdateVisibilityType) => {
      switch (visibility) {
        case UpdateVisibility.ONLY_ME:
          return intl.formatMessage({
            id: `${visibilityMessageId}.onlyMe`,
          })
        case UpdateVisibility.PUBLIC:
          return intl.formatMessage({
            id: `${visibilityMessageId}.public`,
          })

        case UpdateVisibility.CUSTOM:
          return intl.formatMessage({
            id: `${visibilityMessageId}.multipleGroupsUsers`,
          })
        default:
          return ''
      }
    },
    [intl]
  )

  const getVisibilityWithGroupsAndUsers = useCallback(
    (
      visibility: UpdateVisibilityType,
      loggingUpdateGroups: LoggingUpdateGroup[],
      loggingUpdateUsers: LoggingUpdateUser[]
    ) => {
      const isOnlySharedWithMyGroup =
        visibility === UpdateVisibility.CUSTOM &&
        loggingUpdateUsers.length === 0 &&
        loggingUpdateGroups.length === 1 &&
        loggingUpdateGroups[0].groupableId === currentGroup?.id

      if (isOnlySharedWithMyGroup) {
        return currentGroup.name
      }

      return getVisibility(visibility)
    },
    [currentGroup?.id, currentGroup?.name, getVisibility]
  )

  const getUpdateVisibility = useCallback(
    (update: IndexUpdate) => {
      const { visibility, loggingUpdateGroups, loggingUpdateUsers } = update

      return getVisibilityWithGroupsAndUsers(
        visibility,
        loggingUpdateGroups,
        loggingUpdateUsers
      )
    },
    [getVisibilityWithGroupsAndUsers]
  )

  const getScheduledUpdateVisibility = useCallback(
    (scheduledUpdate: ScheduledUpdate) => {
      const { visibility, loggingUpdateGroups, loggingUpdateUsers } =
        scheduledUpdate

      return getVisibilityWithGroupsAndUsers(
        visibility,
        loggingUpdateGroups,
        loggingUpdateUsers
      )
    },
    [getVisibilityWithGroupsAndUsers]
  )

  return {
    getUpdateVisibility,
    getScheduledUpdateVisibility,
  }
}
