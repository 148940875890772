import { AddHoldingForm, FundManager, HoldingType } from './types'

export const getDefaultHoldingValues = (
  name?: string,
  fundManager?: FundManager
): AddHoldingForm => ({
  type: fundManager ? HoldingType.FUND : HoldingType.COMPANY,
  company: {
    legalEntityName: name || '',
    name: name || '',
    website: '',
    pointOfContact: '',
  },
  funds: {
    funds: [name || ''],
    includeFundManager: !!fundManager,
    fundManager: fundManager || {
      name: '',
      website: '',
      pointOfContact: '',
    },
  },
})
