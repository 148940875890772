/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { endOfDay } from 'date-fns'

import { useAppSelector, useAppDispatch } from 'utils/hooks/reduxToolkit'
import { useParams } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'

import { getEmailSubject } from 'selectors/email'
import { setEmailSubject } from 'actions/emails'
import EmailService from 'api/EmailService'
import CompanyService from 'api/CompanyService'
import Input from 'ui/Input'
import Toast from 'components/Toast'
import {
  getPrimaryEmail,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import { buildFormError } from 'utils/functions/forms'
import { UpdateVisibility } from 'utils/constants/updates'

import {
  ScheduleType,
  ScheduleUpdateRadio,
} from 'components/ScheduleUpdateRadio/ScheduleUpdateRadio'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import { calculateMinTime } from 'utils/functions/date'
import { useFormikContext } from 'components/Form/hooks'
import EmailIframePreview from 'components/EmailIframePreview'
import CWLoader from 'components/CWLoader'

import { useSetUpdatePermissions } from 'containers/Updates/SetUpdatePermissions/useSetUpdatePermissions'
import ReshareSettings from 'containers/Updates/ReshareSettings'

import Separator from 'ui/Separator'
import useEffectSkipFirstRender from 'utils/hooks/useEffectSkipFirstRender'
import UpdateService from 'api/UpdateService'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import styles from './SendEmailDraft.module.scss'

const fields = ['fromName', 'subject', 'replyTo']

const SendEmailDraft = ({
  recipients,
  refreshEmailIframe,
  isFounderVerified,
  emailIframeKey,
  emailUpdate,
}) => {
  const initializedForm = useRef(false)
  const [loading, setLoading] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [iueData, setIueData] = useState({})
  const [loggingUpdateSchedule, setLoggingUpdateSchedule] = useState({})
  const formik = useFormikContext()

  const dispatch = useAppDispatch()
  const userEmail = useAppSelector(getPrimaryEmail)
  const emailSubject = useAppSelector(getEmailSubject)
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const groupCompany = useGroupCompany()
  const intl = useIntl()

  const { companyId } = useParams()

  const { reshareSettings, onChangeReshareSettings } = useSetUpdatePermissions()

  useEffectSkipFirstRender(() => {
    if (emailUpdate) {
      EmailService.editEmail(emailUpdate.item.id, {
        report: {
          loggingUpdateAttributes: {
            id: emailUpdate.id,
            blocked: reshareSettings.isBlocked.toString(),
            visibility: UpdateVisibility.CUSTOM,
            confidential: reshareSettings.isConfidential.toString(),
            ownedByGroup: emailUpdate.ownedByGroup.toString(),
          },
        },
      })
    }
  }, [reshareSettings])

  const onBlur = async (name, value) => {
    let data
    let hasDeletedSchedule = false
    if (name === 'date') {
      data = {
        loggingUpdateSchedule: {
          scheduleDate: value.toISOString(),
        },
      }
    } else if (name === 'scheduleType') {
      if (value === ScheduleType.SCHEDULE) {
        const { date } = formik.values
        if (!date || !dayjs(date).isValid()) {
          return
        }
        data = {
          loggingUpdateSchedule: {
            scheduleDate: date.toISOString(),
          },
        }
      } else {
        if (!loggingUpdateSchedule?.id) {
          return
        }
        hasDeletedSchedule = true
        data = {
          loggingUpdateSchedule: {
            _destroy: true,
            id: loggingUpdateSchedule.id,
          },
        }
      }
    } else {
      data = { [name]: value || formik.values.name }
    }

    const body = {
      ...data,
      loggingUpdateAttributes: {
        id: emailUpdate.id,
        scheduled: false, // every time an email is edited, it should be unscheduled
      },
    }

    try {
      const {
        data: {
          entities: { iues, loggingUpdates },
          result,
        },
      } = await EmailService.editEmail(emailUpdate.item.id, body)

      const iue = iues[result]

      if (hasDeletedSchedule) {
        setLoggingUpdateSchedule(null)
      } else {
        const schedule = loggingUpdates[iue.loggingUpdate].loggingUpdateSchedule
        setLoggingUpdateSchedule(schedule)
      }
      if (name === 'scheduleType' || name === 'date') {
        formik.setFieldValue(name, value)
      } else {
        formik.setFieldValue(name, iue[name])
      }

      if (name === 'subject' && iueData?.subject !== iue?.subject) {
        refreshEmailIframe()
      }

      setIueData(iue)
    } catch (err) {
      Toast.display(
        intl.formatMessage({ id: 'createEmail.editFieldError' }, { name }),
        'error'
      )
    }
  }

  const initializeForm = async () => {
    try {
      const {
        data: {
          entities: { updates, loggingUpdateSchedules },
        },
      } = await EmailService.getEmail(emailUpdate.id)
      setLoading(false)

      const { fromName, subject, replyTo } = emailUpdate.item
      const loggingUpdateScheduleId =
        updates[emailUpdate.id].loggingUpdateSchedule

      const schedule = loggingUpdateSchedules[loggingUpdateScheduleId]

      setLoggingUpdateSchedule(schedule)
      formik.setValues({
        fromName,
        date: schedule ? new Date(schedule.scheduleDate) : undefined,
        subject,
        replyTo: replyTo || userEmail,
        scheduleType: schedule ? ScheduleType.SCHEDULE : ScheduleType.SEND_NOW,
      })

      if (!fromName) {
        formik.setFieldValue('fromName', `${groupCompany.name} Team`)
        onBlur('fromName', `${groupCompany.name} Team`)
      }

      if (!subject) {
        formik.setFieldValue(
          'subject',
          `${groupCompany.name} ${dayjs().format('MMMM')} Update`
        )
        onBlur(
          'subject',
          `${groupCompany.name} ${dayjs().format('MMMM')} Update`
        )
      }

      onBlur('replyTo', replyTo || userEmail)
      // getCompanyData()
    } catch (err) {
      Toast.display(intl.messages['createEmail.fetchError'], 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      initializeForm()
    }, 300)
  }, [])

  useEffect(() => {
    return () => {
      const { scheduleType } = formik.values
      if (scheduleType === ScheduleType.SEND_NOW) {
        formik.setFieldValue('date', null)
      }
    }
  }, [])

  if (!isFounderVerified) {
    return (
      <article className={styles.article}>
        <h3>
          <FontAwesomeIcon icon={['far', 'check-circle']} />
          <FormattedMessage id="createEmail.yourAccountIsBeingVerified" />
        </h3>
        <p>
          <FormattedMessage id="createEmail.yourAccountIsBeingVerifiedLegend" />
        </p>
      </article>
    )
  }

  const canSchedule = isFounder || isFundManager

  return (
    <div className={styles.sendStepContentWrapper}>
      <div className={styles.sendContainer}>
        {loading ? (
          <div className={styles.contentLoader}>
            <CWLoader
              text={intl.messages['createEmail.loadingRecipients']}
              column
            />
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.recipientsContainer}>
              <div className={styles.iconWrapper}>
                <FontAwesomeIcon icon={['far', 'paper-plane']} />
              </div>
              <div className={styles.column}>
                <p className={styles.recipientsCountText}>
                  {intl.messages['createEmail.sendEmailTo']}
                </p>
                <p className={styles.recipientsCountText}>
                  {`${recipients} ${
                    recipients === 1
                      ? intl.messages['createEmail.recipient']
                      : intl.messages['createEmail.recipients']
                  }`}
                </p>
              </div>
            </div>

            <div className={styles.divider} />

            {canSchedule && (
              <>
                <div className={styles.scheduleQuestion}>
                  {intl.messages['createEmail.scheduleQuestion']}
                </div>
                <ScheduleUpdateRadio
                  name="scheduleType"
                  value={formik.values.scheduleType}
                  handleChange={(value) => {
                    formik.setFieldValue('scheduleType', value)
                    onBlur('scheduleType', value)
                  }}
                  publishNowLabel={intl.messages['createEmail.sendNow']}
                />

                <div className={styles.datePickerContainer}>
                  <div
                    className={classNames({
                      [styles.hidden]:
                        formik.values.scheduleType === ScheduleType.SEND_NOW,
                    })}
                  >
                    <SelectDatePicker
                      label={intl.messages['updates.dateTime']}
                      onlyMonthYearPicker={false}
                      dateFormat="MM/dd/yyyy h:mm aa"
                      name="date"
                      initialValue={null}
                      showTimeSelect
                      error={formik.errors.date}
                      minDate={new Date()}
                      maxDate={null}
                      minTime={calculateMinTime(formik.values.date)}
                      maxTime={endOfDay(new Date())}
                      value={formik.values.date}
                      handleChange={(selectedDate) => {
                        formik.setFieldValue('date', selectedDate)
                        onBlur('date', selectedDate)
                      }}
                    />
                  </div>
                </div>
              </>
            )}

            {fields.map((name) => (
              <div className={styles.inputsContainer}>
                <Input
                  id={name}
                  onBlur={() =>
                    !formik.errors?.[name] && onBlur(name, formik.values[name])
                  }
                  name={name}
                  label={intl.messages[`createEmail.${name}`]}
                  placeholder={intl.messages[`createEmail.${name}Placeholder`]}
                  error={buildFormError(formik.errors?.[name], 'error', true)}
                  value={formik.values[name]}
                  onChange={formik.handleChange}
                />
              </div>
            ))}

            <Separator space="2.4rem" border />
            <Separator space="2.4rem" />
            <ReshareSettings
              reshareSettings={reshareSettings}
              onChangeReshareSettings={onChangeReshareSettings}
              noPadding
            />
          </div>
        )}
      </div>
      <div className={classNames(styles.emailBackground)}>
        <EmailIframePreview
          key={emailIframeKey}
          customClass={styles.iframe}
          emailUpdate={emailUpdate}
        />
      </div>
    </div>
  )
}

SendEmailDraft.propTypes = {
  recipients: PropTypes.number,
  refreshEmailIframe: PropTypes.func.isRequired,
  isFounderVerified: PropTypes.bool.isRequired,
  emailIframeKey: PropTypes.string.isRequired,
  emailUpdate: PropTypes.shape({
    id: PropTypes.string,
    item: PropTypes.shape({
      id: PropTypes.string,
      fromName: PropTypes.string,
      subject: PropTypes.string,
      replyTo: PropTypes.string,
    }),
    loggingUpdateSchedule: PropTypes.shape({
      id: PropTypes.string,
      scheduleDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
      ]),
    }),
    ownedByGroup: PropTypes.bool,
  }),
}

SendEmailDraft.defaultProps = {
  emailUpdate: null,
}
export default SendEmailDraft
