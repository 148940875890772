import React from 'react'
import { useIntl } from 'react-intl'

import IconButton from 'ui/IconButton'
import theme from 'utils/theme'
import { InvestorGroupUser } from 'utils/types/investors'
import GroupLogo from 'components/GroupLogo'
import Tooltip from 'components/Tooltip'
import avatar from 'assets/images/avatar.png'

import {
  InvestorUserMainCol,
  InvestorConfirmedUserRowContainer,
  InvestorPendingUserRowContainer,
  BoldLabel,
  MediumLabel,
  LightLabel,
} from '../Row.styles'

interface InvestorUserRowProps {
  investorGroupUser: InvestorGroupUser
  onDelete: (investorGroupUser: InvestorGroupUser) => void
  onResendInvite: (investorGroupUser: InvestorGroupUser) => void
}

const ConfirmedInvestorUserRow: React.FC<
  Omit<InvestorUserRowProps, 'onResendInvite'>
> = ({ investorGroupUser, onDelete }) => {
  return (
    <InvestorConfirmedUserRowContainer>
      <GroupLogo
        small
        name={investorGroupUser.user.name}
        imageUrl={investorGroupUser.user.image?.smallLogo?.url}
        forceInitials={false}
      />
      <InvestorUserMainCol>
        <BoldLabel>{investorGroupUser.user.name}</BoldLabel>
        <MediumLabel>
          <Tooltip
            id="members_email__list_item"
            text={investorGroupUser.user.email}
            place="bottom"
            leftOffset={10}
            widthAuto
            delayShow={500}
          >
            {investorGroupUser.user.email}
          </Tooltip>
        </MediumLabel>
        {/*  TODO: last seen */}
      </InvestorUserMainCol>

      <IconButton
        icon="trash-alt"
        color={theme.colors.red}
        onClick={() => onDelete(investorGroupUser)}
        border={false}
        buttonSize="3.7rem"
        iconFontSize="1.6rem"
        iconFamily="fal"
      />
    </InvestorConfirmedUserRowContainer>
  )
}

const UnconfirmedInvestorUserRow: React.FC<InvestorUserRowProps> = ({
  investorGroupUser,
  onDelete,
  onResendInvite,
}) => {
  const intl = useIntl()

  return (
    <InvestorPendingUserRowContainer>
      <GroupLogo small imageUrl={avatar} forceInitials={false} />
      <InvestorUserMainCol>
        <MediumLabel>
          <Tooltip
            id="members_email__list_item"
            text={investorGroupUser.user.email}
            place="bottom"
            leftOffset={10}
            widthAuto
            delayShow={500}
          >
            {investorGroupUser.user.email}
          </Tooltip>
        </MediumLabel>
        <LightLabel>
          {intl.formatMessage({
            id: 'investorManagement.editInvestor.awaitingSignup',
          })}
        </LightLabel>
      </InvestorUserMainCol>
      <IconButton
        icon="redo"
        color={theme.colors.darkBlue}
        onClick={() => onResendInvite(investorGroupUser)}
        border={false}
        buttonSize="3.7rem"
        iconFontSize="1.6rem"
      />
      <IconButton
        icon="trash-alt"
        color={theme.colors.red}
        onClick={() => onDelete(investorGroupUser)}
        border={false}
        buttonSize="3.7rem"
        iconFontSize="1.6rem"
        iconFamily="fal"
      />
    </InvestorPendingUserRowContainer>
  )
}

const InvestorUserRow: React.FC<InvestorUserRowProps> = ({
  investorGroupUser,
  onDelete,
  onResendInvite,
}) => {
  if (investorGroupUser.user.logged) {
    return (
      <ConfirmedInvestorUserRow
        investorGroupUser={investorGroupUser}
        onDelete={onDelete}
      />
    )
  }

  return (
    <UnconfirmedInvestorUserRow
      investorGroupUser={investorGroupUser}
      onDelete={onDelete}
      onResendInvite={onResendInvite}
    />
  )
}

export default InvestorUserRow
