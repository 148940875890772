import { useMemo } from 'react'
import MultiViewChart from 'components/Charts/MultiViewChart'
import useMetricsData from 'components/Charts/hooks/useMetricsData'
import FadeIn from 'components/FadeIn'
import {
  CHART_MODE,
  CUSTOM_CHART_Y_AXIS_ID,
  DEFAULT_CHART_Y_AXIS_ID,
} from 'components/Charts/MultiViewChart/types'
import { YAxisConfig } from 'components/Charts/MultiViewChart/useChartConfig'
import { isMoicMetric } from 'components/Charts/MultiViewChart/utils'
import { useMetricsSandbox } from '../SelectedMetrics/useMetricsSandbox'
import EmptyState from './components/EmptyState'

const MetricsSandbox: React.FC = () => {
  const {
    selectedMetrics,
    toggleMetricInSandbox,
    clearSandbox,
    getMetricColor,
  } = useMetricsSandbox()

  const metrics = useMemo(
    () => selectedMetrics.map((selected) => selected.metric),
    [selectedMetrics]
  )

  const { annualMetrics, quarterlyMetrics, dateMetrics } =
    useMetricsData(metrics)

  const chartMetrics = useMemo(
    () => [...annualMetrics, ...quarterlyMetrics, ...dateMetrics],
    [annualMetrics, dateMetrics, quarterlyMetrics]
  )

  const metricsColor = useMemo(() => {
    return selectedMetrics.reduce((acc, selected) => {
      acc[selected.metric.id] = getMetricColor(selected.metric)
      return acc
    }, {})
  }, [getMetricColor, selectedMetrics])

  const initialConfig = useMemo(() => {
    const charts = metrics.filter(isMoicMetric).reduce((acc, metric) => {
      acc[metric.id] = {
        mode: CHART_MODE.DOTTED_LINE,
        yAxisId: CUSTOM_CHART_Y_AXIS_ID,
      }

      return acc
    }, {})

    const yAxis: Record<string, YAxisConfig> = {
      [DEFAULT_CHART_Y_AXIS_ID]: {
        id: DEFAULT_CHART_Y_AXIS_ID,
        placement: 'left',
        domain: [],
        ticks: [],
        isVisible: true,
      },
    }

    // Add custom axis for MOIC
    if (Object.keys(charts).length) {
      yAxis[CUSTOM_CHART_Y_AXIS_ID] = {
        id: CUSTOM_CHART_Y_AXIS_ID,
        placement: 'right',
        domain: [],
        ticks: [],
        isVisible: true,
      }
    }

    return { charts, yAxis }
  }, [metrics])

  if (!chartMetrics.length)
    return (
      <FadeIn>
        <EmptyState />
      </FadeIn>
    )

  return (
    <FadeIn>
      <MultiViewChart
        hasLegend={false}
        hasMetricsList
        isLoading={false}
        isResizable
        initialConfig={initialConfig}
        chartMetrics={chartMetrics}
        originalMetrics={metrics}
        colors={metricsColor}
        onClearAll={clearSandbox}
        onRemoveMetric={toggleMetricInSandbox}
      />
    </FadeIn>
  )
}

export default MetricsSandbox
