import styled from 'styled-components'

export const EditButtonColumnContainer = styled.div`
  position: relative;
`

export const ButtonContainer = styled.div`
  z-index: 10;
  position: absolute;

  right: 1rem;
  bottom: -2rem;
`

export const EditButton = styled.button`
  width: 3.9rem;
  height: 3.9rem;
  padding: 1.1rem 1.1rem 1.1rem;
  box-shadow: 0.1rem 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  border: solid 0.1rem #e9e9e9;
  background-color: white;
  color: #787e8c;
  border-radius: 100%;
  &:focus {
    outline: none;
  }
`
