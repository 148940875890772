import { CardBody as CardBodyToStyle } from 'components/Card/Card.styles'
import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const ZeroStateContainer = styled.div`
  margin-top: 10.2rem;
`

export const CardBody = styled(CardBodyToStyle)<{
  isExpanded?: boolean
}>`
  margin-top: ${({ isExpanded }) => (isExpanded ? '-0.5rem' : '0')};
`
