import { Channel } from 'stream-chat'

import { StreamChatType } from 'containers/Chat/types'

import TooltipContent from '../TooltipContent'
import { MutedIcon, Tooltip } from './MutedIndicator.style'
import { useMutedIndicator } from './useMutedIndicator'

interface MutedIndicatorProps {
  className?: string
  channel?: Channel<StreamChatType>
}

const MutedIndicator = ({ className, channel }: MutedIndicatorProps) => {
  const { isChannelMuted, muteExpirationText } = useMutedIndicator(channel)

  return isChannelMuted ? (
    <Tooltip
      text={<TooltipContent>{muteExpirationText}</TooltipContent>}
      backgroundColor="transparent"
      place="right"
      offset={-5}
    >
      <MutedIcon className={className} icon={['far', 'bell-slash']} />
    </Tooltip>
  ) : null
}

export default MutedIndicator
