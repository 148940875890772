import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm, useFormikContext } from 'components/Form/hooks'
import { Update } from 'utils/types/update'
import PublishButton from 'containers/UpdatesV2/components/UpdateHeader/components/PublishButton'
import { UpdateType } from 'utils/types/common'
import ConfidentialUpdate from 'containers/UpdatesV2/components/UpdateHeader/components/ConfidentialUpdate'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'
import SaveDraftIcon from 'containers/UpdatesV2/components/UpdateHeader/components/SaveDraftIcon/SaveDraftIcon'
import { OfflineMask } from 'containers/UpdatesV2/components/UpdateHeader/UpdateHeader.styles'
import useManageModals from 'containers/UpdatesV2/components/UpdateHeader/useManageModals'
import ReshareUpdateButton from 'containers/UpdatesV2/components/UpdateHeader/components/ReshareButton/ReshareUpdateButton'
import theme from 'utils/theme'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder, isActingAsInvestorGroup } from 'selectors/auth'
import {
  CreateBasicFormValues,
  CreateUpdateGlobalProps,
  UpdateFormContext,
} from 'utils/types/updateForm'
import { UpdateTypes } from 'utils/constants/updates'
import CloseButton from './Components/CloseButton/CloseButton'
import {
  ActionsWrapper,
  Divider,
  EditActionsWrapper,
  MainWrapper,
} from './UpdateActions.styles'
import CopyLinkedAction from './CopyLinkedAction/CopyLinkedAction'
import EditUpdateAction from './EditUpdateAction/EditUpdateAction'
import NavigationUpdateAction from './NavigationUpdateAction/NavigationUpdateAction'
import ShareSettingsAction from './ShareSettingsAction/ShareSettingsAction'
import useUpdateActions from './useUpdateActions'
import UpdateModals from './Components/Modals/UpdateModals'
import NewChatAction from './NewChatAction/NewChatAction'
import { useBreadcrumbActionContext } from '../BreadcrumbActionPortal'
import TooltipActionWrapper from './Components/TooltipActionWrapper/TooltipActionWrapper'

interface UpdateActionsProps {
  update?: Update
  updateType: UpdateType
  hasDraftMode: boolean
  handleDelete?: () => void
  handleDiscardDraft?: () => void
  toggleReshareUpdateModal?: () => void
  handleChangeUpdateToToday?: (
    values: CreateBasicFormValues
  ) => Promise<unknown>
  handleShowCreateChatModal: (value: boolean) => void
}

const UpdateActions = ({
  update,
  updateType,
  hasDraftMode,
  handleDelete,
  handleDiscardDraft,
  toggleReshareUpdateModal,
  handleChangeUpdateToToday,
  handleShowCreateChatModal,
}: UpdateActionsProps) => {
  const history = useHistory()
  const intl = useIntl()
  const isInvestor = useAppSelector(isActingAsInvestorGroup)
  const isFounder = useAppSelector(isActingAsFounder)
  const { showActionsBelow } = useBreadcrumbActionContext()

  const {
    hasDraftUpdate,
    isEditMode,
    isShowMode,
    isUploadingFiles,
    hasDealsErrors,
    initialDraftStatus,
    isSavingDraft,
    isOnline,
    isEditingDraft,
    setIsPublishing,
  } = useForm<UpdateFormContext>()

  const { hasEditPermissions, canReshare } = useUpdatePermissions(update)

  const { isSubmitting, values, isValid, handleSubmit, dirty } =
    useFormikContext<CreateUpdateGlobalProps>()

  const { onHandleSubmit } = useUpdateActions({
    setIsPublishing,
    handleSubmit,
  })

  const {
    deleteModal,
    discardDraftModal,
    shouldShowShareWithInvestorRecommendation,
    showShareWithInvestorRecommendationModal,
    showChangePublishUpdateDate,
    showShareSettingsModal,
    handleOpenShareSettingsModal,
    toggleShareSettingsModal,
    onShareWith,
    switchShowMessageAgain,
    toggleDeleteConfirmationModal,
    toggleSubmitConfirmationModal,
    toggleDiscardDraftConfirmationModal,
    togglePublishUpdateDateModal,
    publishUpdate,
  } = useManageModals({
    handleSubmit,
  })

  const onChangeDraftToToday = async () => {
    await handleChangeUpdateToToday?.(values)
    handleSubmit()
  }

  const showReshareButton = !hasEditPermissions && canReshare && update
  const isCreateMode = !isEditMode && !isShowMode

  const hasChangesToPublish =
    isEditingDraft && (Object.keys(update?.draftHash || {}).length > 0 || dirty)

  const enablePublish =
    isValid && !isUploadingFiles && !hasDealsErrors && hasChangesToPublish

  const enablePublishButton = hasDraftMode
    ? enablePublish
    : enablePublish && isCreateMode

  const isAccountingUpdate = updateType === UpdateTypes.ACCOUNTING

  const enablePublishButtonForAccounting =
    isValid && !isUploadingFiles && !hasDealsErrors

  const showNewChatIcon = isShowMode && !isInvestor && !isFounder

  return (
    <MainWrapper showActionsBelowBreadcrumb={showActionsBelow}>
      {!isOnline && !isShowMode && <OfflineMask />}

      {!isShowMode && !isAccountingUpdate && (
        <>
          <SaveDraftIcon
            initialStatus={initialDraftStatus}
            isSubmitting={isSavingDraft}
            isOnline={isOnline}
          />

          <Divider
            color={
              showActionsBelow
                ? theme.colors.veryLightBlue
                : theme.colors.veryLightGray
            }
          />
        </>
      )}

      {isEditMode && <ConfidentialUpdate />}

      <ShareSettingsAction
        update={update!}
        hasEditPermissions={hasEditPermissions}
        isCreateMode={!isEditMode && !isShowMode}
        isEditMode={isEditMode}
        isShowMode={isShowMode}
        isEditingDraft={isEditingDraft}
        values={values}
        isSubmitting={isSubmitting}
        handleOpenShareSettingsModal={handleOpenShareSettingsModal}
        dividerColor={
          showActionsBelow
            ? theme.colors.veryLightBlue
            : theme.colors.veryLightGray
        }
      />

      <ActionsWrapper>
        {showNewChatIcon && (
          <TooltipActionWrapper
            id="new-chat-update-action"
            text={intl.formatMessage({ id: 'updateActions.newChat' })}
          >
            <NewChatAction
              handleShowCreateChatModal={handleShowCreateChatModal}
            />
          </TooltipActionWrapper>
        )}

        <CopyLinkedAction
          update={update}
          updateType={updateType}
          hasDraftUpdate={hasDraftUpdate!}
          isShowMode={isShowMode}
        />

        {showReshareButton && toggleReshareUpdateModal && (
          <ReshareUpdateButton handleOnClick={toggleReshareUpdateModal} />
        )}

        {isShowMode ? (
          <EditUpdateAction
            update={update}
            hasEditPermissions={hasEditPermissions}
            isEditMode={isEditMode}
            isEditingDraft={isEditingDraft}
            isSubmitting={isSubmitting}
          />
        ) : (
          <EditActionsWrapper>
            <CloseButton
              isSubmitting={isSubmitting}
              goBack={history.goBack}
              hasDraftMode={hasDraftMode}
              disabled={isSubmitting}
              label={intl.formatMessage({ id: 'buttons.cancel' })}
              toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
              toggleDiscardDraftConfirmationModal={
                toggleDiscardDraftConfirmationModal
              }
            />
            <PublishButton
              isSubmitting={isSubmitting}
              enabled={
                isAccountingUpdate
                  ? enablePublishButtonForAccounting
                  : enablePublishButton
              }
              handleSubmit={publishUpdate}
            />
          </EditActionsWrapper>
        )}
      </ActionsWrapper>

      {isShowMode && (
        <NavigationUpdateAction
          dividerColor={
            showActionsBelow
              ? theme.colors.veryLightBlue
              : theme.colors.veryLightGray
          }
        />
      )}

      <UpdateModals
        values={values}
        updateType={updateType}
        deleteModal={deleteModal}
        discardDraftModal={discardDraftModal}
        shouldShowShareWithInvestorRecommendation={
          shouldShowShareWithInvestorRecommendation
        }
        showShareWithInvestorRecommendationModal={
          showShareWithInvestorRecommendationModal
        }
        toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
        toggleDiscardDraftConfirmationModal={
          toggleDiscardDraftConfirmationModal
        }
        handleDelete={handleDelete}
        handleDiscardDraft={handleDiscardDraft}
        handleSubmit={onHandleSubmit}
        onShareWith={onShareWith}
        switchShowMessageAgain={switchShowMessageAgain}
        toggleSubmitConfirmationModal={toggleSubmitConfirmationModal}
        onChangeDraftToToday={onChangeDraftToToday}
        showChangePublishUpdateDate={showChangePublishUpdateDate}
        togglePublishUpdateDateModal={togglePublishUpdateDateModal}
        showShareSettingsModal={showShareSettingsModal}
        toggleShareSettingsModal={toggleShareSettingsModal}
      />
    </MainWrapper>
  )
}

export default UpdateActions
