import styled from 'styled-components'

const ButtonBase = styled.button`
  position: relative;
  display: inline-block;
  padding: 1rem 1.6rem;
  font-family: inherit;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.68rem;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border-radius: 0.8rem;
  appearance: none;
  text-decoration: none;
  text-align: center;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: default;
  }
  &:disabled svg {
    opacity: 0.5;
  }
`
export default ButtonBase
