import React, { useCallback, useMemo, useState } from 'react'

import { Plan } from 'utils/types/signUp'
import {
  PromotionCode,
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import { getPlanId, getPlanOrder } from 'utils/functions/subscriptions'
import PlanColumn from './components/PlanColumn'
import { getPlans, getPlansById } from './plans'
import PlanSlider from './components/PlanSlider'
import * as Styles from './PlanSelector.styles'

interface PlanSelectorProps {
  onPlanSelect: (plan: Plan, billingPeriod: SubscriptionBillingPeriod) => void
  handleInternalState?: boolean
  carouselMode?: boolean
  className?: string
  currentPlan?: SubscriptionPlan
  currentBillingPeriod?: SubscriptionBillingPeriod
  slidesToShow: number
  highlightPlans?: boolean
  hideLowerPlans?: boolean
  showTrialText?: boolean
  minPlanToShow?: SubscriptionPlan
  couponCodeAllowed?: boolean
  handlePromotionCodeAdded?: (
    code: PromotionCode,
    plan: SubscriptionPlan
  ) => void
}

const PlanSelector: React.FC<PlanSelectorProps> = ({
  onPlanSelect,
  handleInternalState,
  carouselMode = false,
  className,
  currentPlan,
  currentBillingPeriod,
  slidesToShow: slidesToShowProp,
  highlightPlans = true,
  hideLowerPlans = false,
  showTrialText = false,
  minPlanToShow,
  couponCodeAllowed,
  handlePromotionCodeAdded,
}) => {
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(() =>
    currentPlan ? getPlansById()[currentPlan] : undefined
  )

  const billingPeriod =
    currentBillingPeriod ?? SubscriptionBillingPeriod.ANNUALLY

  const onPlanClick = useCallback(
    (plan: Plan) => {
      onPlanSelect(plan, billingPeriod)

      if (handleInternalState) {
        setSelectedPlan(plan)
      }
    },
    [onPlanSelect, billingPeriod, handleInternalState]
  )

  const plans = useMemo(() => {
    return getPlans(highlightPlans).filter((plan) => {
      if (minPlanToShow) {
        return plan.planOrder >= getPlanOrder(minPlanToShow)
      }

      if (!hideLowerPlans || currentPlan === undefined) return true
      return plan.planOrder >= getPlanOrder(currentPlan)
    })
  }, [highlightPlans, hideLowerPlans, currentPlan, minPlanToShow])

  const slidesToShow = Math.min(slidesToShowProp, plans.length)

  return (
    <Styles.PlanSelectorContainer className={className}>
      {carouselMode ? (
        <Styles.PlansSlider slidesToShow={slidesToShow}>
          <PlanSlider slidesToShow={slidesToShow} countPlans={plans.length}>
            {plans.map((plan) => {
              return (
                <PlanColumn
                  key={plan.name}
                  plan={plan}
                  onPlanSelect={onPlanClick}
                  billingPeriod={billingPeriod}
                  isCurrentPlan={
                    currentPlan
                      ? getPlanId(currentPlan) === plan.id &&
                        currentBillingPeriod === billingPeriod
                      : false
                  }
                  isSelected={selectedPlan?.id === plan.id}
                  showTrialText={showTrialText}
                  couponCodeAllowed={couponCodeAllowed}
                  handlePromotionCodeAdded={(code) =>
                    handlePromotionCodeAdded?.(code, plan.subscriptionPlan!)
                  }
                />
              )
            })}
          </PlanSlider>
        </Styles.PlansSlider>
      ) : (
        <Styles.Plans countPlans={plans.length}>
          {plans.map((plan) => {
            return (
              <PlanColumn
                key={plan.name}
                plan={plan}
                onPlanSelect={onPlanClick}
                billingPeriod={billingPeriod}
                isCurrentPlan={
                  currentPlan ? getPlanId(currentPlan) === plan.id : false
                }
                showTrialText={showTrialText}
                couponCodeAllowed={couponCodeAllowed}
                handlePromotionCodeAdded={(code) =>
                  handlePromotionCodeAdded?.(code, plan.subscriptionPlan!)
                }
              />
            )
          })}
        </Styles.Plans>
      )}
    </Styles.PlanSelectorContainer>
  )
}

export default PlanSelector
