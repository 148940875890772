/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'

import { getAuth } from 'selectors/auth'
import { updateUserSession } from 'features/authSlice'
import { setOwner, setSignupCompany } from 'actions/companies'
import GroupService from 'api/GroupService'
import Toast from 'components/Toast'
import CompanyService from 'api/CompanyService'
import Button from 'ui/Button'
import { getSignupCompany } from 'selectors/company'
import { Legend } from 'containers/Signup/Signup.styles'
import { getRandomInt } from 'utils/functions/number'

import ClaimCompanyCard from './ClaimCompanyCard'
import {
  ClaimCompanyContainer,
  ButtonsContainer,
  ClaimTitle,
} from './ClaimCompany.styles'

const MIN_RANGE = 1000
const MAX_RANGE = 9999

const ClaimCompany = ({
  onClickYes,
  onClickNo,
  title,
  description,
  claimerGroup,
}) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const signupCompany = useAppSelector(getSignupCompany)
  const auth = useAppSelector(getAuth)
  const [loading, setLoading] = useState(false)

  const claimCompanyProfile = async () => {
    try {
      setLoading(true)
      const groupName = signupCompany?.name

      let group
      if (claimerGroup) {
        group = claimerGroup
      } else {
        group = await GroupService.createGroup(
          `${groupName}_${getRandomInt(MIN_RANGE, MAX_RANGE)}`
        )
      }
      const {
        data: { entities },
      } = await CompanyService.claimCompanyProfile(group.id, signupCompany?.id)
      const owner = Object.values(entities?.groups || {})?.[0]

      const authCopy = {
        userSession: { ...auth.userSession, currentGroupId: group.id },
        user: { ...auth.user },
      }
      authCopy.groups = {
        [group.id]: {
          ...group,
          companyData: [{ id: signupCompany?.id }],
        },
      }

      await dispatch(updateUserSession(authCopy))

      dispatch(setOwner(owner))
      onClickYes()
    } catch (error) {
      Toast.display(intl.messages['claimCompany.error'], 'error')
    } finally {
      setLoading(true)
    }
  }

  const onCancelClaim = () => {
    dispatch(setSignupCompany({}))
    onClickNo()
  }

  return (
    <ClaimCompanyContainer>
      <ClaimTitle>
        {title || <FormattedMessage id="claimCompany.title" />}
        {description ? <Legend>{description}</Legend> : null}
      </ClaimTitle>

      <ClaimCompanyCard {...signupCompany} />

      <ButtonsContainer>
        <Button
          onClick={claimCompanyProfile}
          primary
          blue
          uppercase
          disabled={loading}
        >
          <FormattedMessage id="claimCompany.yes" />
        </Button>
        <Button onClick={onCancelClaim} secondary uppercase disabled={loading}>
          <FormattedMessage id="claimCompany.no" />
        </Button>
      </ButtonsContainer>
    </ClaimCompanyContainer>
  )
}

ClaimCompany.propTypes = {
  onClickYes: PropTypes.func.isRequired,
  onClickNo: PropTypes.func.isRequired,
  showSignupMode: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  claimerGroup: PropTypes.object,
}

ClaimCompany.defaultProps = {
  showSignupMode: false,
  title: '',
  description: '',
  claimerGroup: null,
}

export default ClaimCompany
