import { useMutation } from '@tanstack/react-query'
import GroupService from 'api/GroupService'
import { useGroupTierQuery } from 'utils/hooks/queries/useGroupQuery'
import { ApiOnboardingSteps } from 'utils/types/api/tier'
import { OnboardingStep } from './useOnboardingModal'

export const useOnboardingStepMutation = () => {
  const { tier } = useGroupTierQuery()

  return useMutation((stepState: Record<OnboardingStep, boolean>) => {
    const stepsData: ApiOnboardingSteps = {
      welcome: 'true',
      track: stepState[OnboardingStep.SETUP_YOUR_PIPELINE] ? 'true' : 'false',
      files: stepState[OnboardingStep.ADD_YOUR_FILES] ? 'true' : 'false',
      inbound: stepState[OnboardingStep.SETUP_YOUR_INBOUND] ? 'true' : 'false',
    }
    return GroupService.updateTierOnboardingStep(tier.id, stepsData)
  })
}
