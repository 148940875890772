import { useMixedContent } from 'utils/hooks/contents/useMixedContent'
import FileIcon from 'components/FileIcon'

import {
  FileInfo,
  FileName,
  ListFile,
  DeleteButton,
} from './FileListPreview.styles'
import { FilePreviewProps } from '../FileGridPreview/FileGridPreview'

const FileListPreview: React.FC<FilePreviewProps> = ({
  content,
  canDelete,
  handleDelete,
  handleOnClick,
}) => {
  const { isEmailContent, contentName, fileFormat } = useMixedContent(content)

  return (
    <ListFile role="button" onClick={handleOnClick}>
      <FileInfo>
        <FileIcon
          className="file-icon"
          small
          type={fileFormat}
          isEmailContent={isEmailContent}
        />
        <FileName>{contentName}</FileName>
      </FileInfo>
      {canDelete && (
        <DeleteButton
          icon="trash"
          transparent
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            handleDelete()
          }}
        />
      )}
    </ListFile>
  )
}

export default FileListPreview
