export const listAnimation = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      delayChildren: 0,
      staggerDirection: 1,
    },
  },
  hidden: { opacity: 0 },
}

export const itemAnimation = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  hidden: { opacity: 0 },
}
