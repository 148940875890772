import styled from 'styled-components/macro'

export const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.8rem;
`

export const GridTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGray};
  display: flex;
  justify-content: space-between;
`

export const PipelineNameContainer = styled.div`
  max-width: 40rem;
  padding-bottom: 2rem;
`
