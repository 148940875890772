import dayjs from 'dayjs'
import type { IntlShape } from 'react-intl'
import {
  AccountingReportType,
  BalanceDateType,
  PeriodType,
  ReportDatePeriodType,
  ReportParams,
} from 'utils/constants/accounting'
import { Integrations } from 'utils/constants/integrations'
import { ScheduleRepetitionType } from 'utils/constants/scheduleRepetition'
import { PlainUpdateTypes } from 'utils/constants/updates'
import { AccountingFormValues } from 'utils/types/updateForm'
import { PlainUpdateType, ValueOf } from '../types/common'
import { assertUnreachable } from './utils'

export const getRandomValue = (min: number, max: number): number => {
  return Math.random() * (max - min) + min
}

export const getRandomBoolean = (): boolean => {
  return getRandomValue(1, 2) > 1.5
}

export const getPeriodsForDate = (
  date: Date,
  balanceDate: BalanceDateType,
  periods: number
): string[] => {
  const periodArray = new Array(periods).fill(undefined)

  switch (balanceDate) {
    case BalanceDateType.SCHEDULED_DATE:
      return periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index, 'day')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
    case BalanceDateType.END_OF_LAST_MONTH:
      return periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index - 1, 'month')
          .endOf('month')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
    case BalanceDateType.END_OF_LAST_QUARTER:
      return periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index - 1, 'quarter')
          .endOf('quarter')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
    case BalanceDateType.END_OF_LAST_FINANCIAL_YEAR:
      return periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index - 1, 'year')
          .endOf('year')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })

    default:
      return []
  }
}

export const getPeriodsFromReportDatePeriod = ({
  date,
  reportDatePeriod,
  periods,
}: {
  date: Date
  reportDatePeriod: ReportDatePeriodType
  periods: number
}) => {
  let periodArray: string[] = new Array(periods).fill(
    PeriodType.PREVIOUS_1_PERIOD
  )
  let fromDate
  let toDate
  switch (reportDatePeriod) {
    case ReportDatePeriodType.CURRENT_MONTH:
      fromDate = dayjs(date).startOf('month')
      toDate = dayjs(date).endOf('month')
      periodArray = periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index, 'month')
          .endOf('month')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
      break
    case ReportDatePeriodType.CURRENT_QUARTER:
      fromDate = dayjs(date).startOf('quarter')
      toDate = dayjs(date).endOf('quarter')
      periodArray = periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index, 'quarter')
          .endOf('quarter')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
      break
    case ReportDatePeriodType.CURRENT_FINANCIAL_YEAR:
      fromDate = dayjs(date).startOf('year')
      toDate = dayjs(date).endOf('year')
      periodArray = periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index, 'year')
          .endOf('year')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
      break
    case ReportDatePeriodType.PREVIOUS_MONTH:
      fromDate = dayjs(date).startOf('month').add(-1, 'month')
      toDate = dayjs(date).add(-1, 'month').endOf('month')
      periodArray = periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index - 1, 'month')
          .endOf('month')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
      break
    case ReportDatePeriodType.PREVIOUS_QUARTER:
      fromDate = dayjs(date).startOf('quarter').add(-1, 'quarter')
      toDate = dayjs(date).add(-1, 'quarter').endOf('month')
      periodArray = periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index - 1, 'quarter')
          .endOf('quarter')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
      break
    case ReportDatePeriodType.PREVIOUS_FINANCIAL_YEAR:
      fromDate = dayjs(date).startOf('year').add(-1, 'year')
      toDate = dayjs(date).add(-1, 'year').endOf('month')
      periodArray = periodArray.map((_, index) => {
        return dayjs(date)
          .add(-index - 1, 'year')
          .endOf('year')
          .format('DD MMM. YYYY')
          .toUpperCase()
      })
      break

    default:
      return assertUnreachable(reportDatePeriod)
  }
  return {
    fromDate,
    toDate,
    periodArray,
  }
}

export const getReportDate = (
  date: string,
  balanceDate: BalanceDateType
): string => {
  switch (balanceDate) {
    case BalanceDateType.SCHEDULED_DATE:
      return dayjs(date).format('DD MMM. YYYY').toUpperCase()

    case BalanceDateType.END_OF_LAST_MONTH:
      return dayjs(date).endOf('month').format('DD MMM. YYYY').toUpperCase()

    case BalanceDateType.END_OF_LAST_QUARTER:
      return dayjs(date).endOf('quarter').format('DD MMM. YYYY').toUpperCase()

    case BalanceDateType.END_OF_LAST_FINANCIAL_YEAR:
      return dayjs(date).endOf('year').format('DD MMM. YYYY').toUpperCase()

    default:
      return ''
  }
}

export const getReportParams = (
  reportType: AccountingReportType,
  values: AccountingFormValues
): ReportParams => {
  if (
    reportType === AccountingReportType.XERO_BALANCE_SHEET ||
    reportType === AccountingReportType.QUICKBOOKS_BALANCE_SHEET
  ) {
    return {
      balanceDate: values.balanceDate!,
      periods: values.periods!,
      paymentsOnly: values.paymentsOnly,
      standardLayout: values.standardLayout,
    }
  }

  if (
    reportType === AccountingReportType.XERO_PROFIT_AND_LOSS ||
    reportType === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS
  ) {
    return {
      periods: values.periods!,
      reportDatePeriod: values.reportDatePeriod!,
      paymentsOnly: values.paymentsOnly,
      standardLayout: values.standardLayout,
    }
  }

  throw assertUnreachable(reportType)
}

export const getEntityNameByReport = (
  reportName: AccountingReportType
): string => {
  const entityName = reportName.split('_')[0]
  switch (entityName) {
    case 'XERO':
      return 'xero_report'
    case 'QUICKBOOKS':
      return 'quickbooks_report'
    default:
      return ''
  }
}

export const getEntityUrlNameByReport = (
  reportName: AccountingReportType
): string => {
  const entityName = reportName.split('_')[0]
  switch (entityName) {
    case 'XERO':
      return 'xero_reports'
    case 'QUICKBOOKS':
      return 'quickbooks_reports'
    default:
      return ''
  }
}

export const getAccountingReportUrlByPlainUpdateType = (
  plainUpdateType: PlainUpdateType
): string => {
  switch (plainUpdateType) {
    case PlainUpdateTypes.XERO_REPORT:
      return 'xero_reports'
    case PlainUpdateTypes.QUICKBOOKS_REPORT:
      return 'quickbooks_reports'
    default:
      throw new Error('Not an accounting report')
  }
}

export const updateTypeIsAccountingReport = (type) =>
  ['xeroreports', 'quickbooksreports'].includes(type)

export const getAccountingReportType = (
  reportName: string,
  integration: ValueOf<typeof Integrations>
): AccountingReportType => {
  if (integration === Integrations.XERO) {
    if (reportName === 'balance_sheet') {
      return AccountingReportType.XERO_BALANCE_SHEET
    }
    return AccountingReportType.XERO_PROFIT_AND_LOSS
  }
  if (reportName === 'balance_sheet') {
    return AccountingReportType.QUICKBOOKS_BALANCE_SHEET
  }
  return AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS
}

export const getScheduleRepetitionDropdownOptions = (
  date: Date,
  intl: IntlShape
) => {
  const dayOfWeek = dayjs(date).format('dddd')
  const dateDo = dayjs(date).format('Do')
  const monthDate = dayjs(date).format('MMM. DD')

  return [
    {
      id: ScheduleRepetitionType.NO_REPETITION,
      label: intl.formatMessage({ id: 'general.doesNotRepeat' }),
    },
    {
      id: ScheduleRepetitionType.DAILY,
      label: intl.formatMessage({ id: 'general.daily' }, { dayOfWeek }),
    },
    {
      id: ScheduleRepetitionType.WEEKLY_ON_DAY,
      label: intl.formatMessage({ id: 'general.weeklyOnDay' }, { dayOfWeek }),
    },
    {
      id: ScheduleRepetitionType.WEEKLY_ON_DATE,
      label: intl.formatMessage({ id: 'general.weeklyOnDate' }, { dateDo }),
    },

    {
      id: ScheduleRepetitionType.MONTHLY_FIRST_DAY,
      label: intl.formatMessage(
        { id: 'general.monthlyFirstDay' },
        { dayOfWeek }
      ),
    },
    {
      id: ScheduleRepetitionType.MONTHLY_LAST_DAY,
      label: intl.formatMessage(
        { id: 'general.monthlyLastDay' },
        { dayOfWeek }
      ),
    },
    {
      id: ScheduleRepetitionType.ANUALLY_ON_DATE,
      label: intl.formatMessage(
        { id: 'general.anuallyOnDate' },
        { date: monthDate }
      ),
    },
  ]
}

export const getReportDatePeriodDropdownOptions = (
  date: Date,
  intl: IntlShape
) => {
  const parseDate = dayjs(date)
  const DATE_FORMAT = 'D MMM'
  const DATE_FORMAT_END = 'D MMM YYYY'
  return [
    {
      id: ReportDatePeriodType.CURRENT_MONTH,
      label: intl.formatMessage({
        id: 'general.currentMonth',
      }),
      legend: `${parseDate.startOf('month').format(DATE_FORMAT)} - ${parseDate
        .endOf('month')
        .format(DATE_FORMAT_END)}`,
    },
    {
      id: ReportDatePeriodType.CURRENT_QUARTER,
      label: intl.formatMessage({
        id: 'general.currentQuarter',
      }),
      legend: `${parseDate.startOf('quarter').format(DATE_FORMAT)} - ${parseDate
        .endOf('quarter')
        .format(DATE_FORMAT_END)}`,
    },
    {
      id: ReportDatePeriodType.CURRENT_FINANCIAL_YEAR,
      label: intl.formatMessage({
        id: 'general.currentFinancialYear',
      }),
      legend: `${parseDate.startOf('year').format(DATE_FORMAT)} - ${parseDate
        .endOf('year')
        .format(DATE_FORMAT_END)}`,
    },
    {
      id: ReportDatePeriodType.PREVIOUS_MONTH,
      label: intl.formatMessage({
        id: 'general.previousMonth',
      }),
      legend: `${parseDate
        .add(-1, 'month')
        .startOf('month')
        .format(DATE_FORMAT)} - ${parseDate
        .add(-1, 'month')
        .endOf('month')
        .format(DATE_FORMAT_END)}`,
    },
    {
      id: ReportDatePeriodType.PREVIOUS_QUARTER,
      label: intl.formatMessage({
        id: 'general.previousQuarter',
      }),
      legend: `${parseDate
        .add(-1, 'quarter')
        .startOf('quarter')
        .format(DATE_FORMAT)} - ${parseDate
        .add(-1, 'quarter')
        .endOf('quarter')
        .format(DATE_FORMAT_END)}`,
    },
    {
      id: ReportDatePeriodType.PREVIOUS_FINANCIAL_YEAR,
      label: intl.formatMessage({
        id: 'general.previousFinancialYear',
      }),
      legend: `${parseDate
        .add(-1, 'year')
        .startOf('year')
        .format(DATE_FORMAT)} - ${parseDate
        .add(-1, 'year')
        .endOf('year')
        .format(DATE_FORMAT_END)}`,
    },
  ]
}
