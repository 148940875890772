import * as yup from 'yup'
import { scheduleDateSchema } from './updates'

export const getSendEmailDraftSchema = (intl, dateRequired) =>
  yup.object().shape({
    replyTo: yup
      .string()
      .trim()
      .email(intl.formatMessage({ id: 'general.invalidEmail' }))
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    subject: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    fromName: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    scheduleType: dateRequired
      ? yup
          .string()
          .required(intl.formatMessage({ id: 'general.requiredField' }))
      : undefined,
    date: dateRequired ? scheduleDateSchema(intl) : undefined,
  })
