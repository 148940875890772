import React from 'react'
import type {
  SuggestionUser,
  SuggestionCommand,
  SuggestionItemProps,
} from 'stream-chat-react'

import Avatar from 'components/Avatar'
import { Emoji } from 'containers/Chat/types'
import { getInitials } from 'utils/functions/user'
import * as Styles from './SuggestionItem.styles'

type SuggestionItemType = Emoji | SuggestionUser | SuggestionCommand

const isEmoji = (output: SuggestionItemType): output is Emoji =>
  (output as Emoji).native != null

const isMention = (output: SuggestionItemType): output is SuggestionUser =>
  (output as SuggestionUser).id != null &&
  (output as SuggestionUser).native == null

const SuggestionItem = React.forwardRef(
  (props: SuggestionItemProps, ref: React.Ref<HTMLDivElement>) => {
    const { item, onClickHandler, onSelectHandler, selected } = props
    const shouldShow = isEmoji(item) || isMention(item)

    const selectItem = () => onSelectHandler(item)

    if (!shouldShow) return null

    return (
      <Styles.Container
        selected={selected}
        onClick={onClickHandler}
        onMouseEnter={selectItem}
        ref={ref}
        role="button"
        tabIndex={0}
      >
        {isMention(item) && (
          <>
            <Avatar
              image={item.image}
              initials={getInitials(item.name)}
              avatarStyle="avatarCircleXS"
            />
            <span>{item.name}</span>
          </>
        )}
        {isEmoji(item) && (
          <>
            <span>{item.native}</span>
            <span>:{item.id}:</span>
          </>
        )}
      </Styles.Container>
    )
  }
)

export default SuggestionItem
