import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import OptionsDropdownToStyle from 'ui/OptionsDropdown'
import {
  METRIC_CHART_HEIGHT,
  METRIC_VALUE_HEIGHT,
} from 'components/ProfileMetrics/components/MetricDataPoints/MetricDataPoints.styles'

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  min-width: 0;
`

export const HoldingName = styled.div`
  line-height: 1.1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

export const VerticalSeparator = styled.div<{ isMobile: boolean }>`
  background-color: ${color('veryLightGray')};
  height: 2rem;
  margin: ${({ isMobile }) => (isMobile ? '0 0.5rem' : '0 0.5rem 0rem 2rem')};
  width: 0.05rem;
`

export const OptionsDropdown = styled(OptionsDropdownToStyle)`
  svg {
    font-size: 1.6rem;
  }
`

export const DataPointsContainer = styled.div`
  margin: 3rem 0rem 2.8rem;
  height: ${METRIC_VALUE_HEIGHT}px;
  width: 100%;
`

export const ChartContainer = styled.div`
  display: flex;
  height: ${METRIC_CHART_HEIGHT}px;
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: 1.2rem;
`

export const EmptyState = styled.div`
  color: ${color('lightGray')};
  text-align: center;
  font-size: 1.4rem;
  height: 3.3rem;
`
