import { ChangeSetItem, EventType, LogTextBuilder } from '../types'

export class EmailLogTextBuilder extends LogTextBuilder {
  private getDeletedEmailLogText(email: string): React.ReactNode {
    return this.formatMessage('logs.deletedEmailAddress', { email })
  }

  private getAddedEmailLogText(email: string): React.ReactNode {
    return this.formatMessage('logs.addedEmailAddress', { email })
  }

  private getDeletedPointOfContactLogText(email: string): React.ReactNode {
    return this.formatMessage('logs.deletedPointOfContact', { email })
  }

  private getAddedPointOfContactLogText(email: string): React.ReactNode {
    return this.formatMessage('logs.addedPointOfContact', { email })
  }

  public getLogText(
    _event: EventType,
    _fieldName: string,
    changeSetItem: ChangeSetItem,
    text: 'organization' | 'individual'
  ): React.ReactNode {
    const emailWasAdded = changeSetItem[1] !== null
    const emailWasRemoved = changeSetItem[0] !== null

    if (emailWasAdded) {
      return text === 'individual'
        ? this.getAddedEmailLogText(changeSetItem[1] as string)
        : this.getAddedPointOfContactLogText(changeSetItem[1] as string)
    }

    if (emailWasRemoved) {
      return text === 'individual'
        ? this.getDeletedEmailLogText(changeSetItem[0] as string)
        : this.getDeletedPointOfContactLogText(changeSetItem[0] as string)
    }

    return null
  }
}
