import { useQueryClient } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactGrid } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import { CustomHoldingCellTemplate } from 'components/TransactionsSpeadsheetImportModal/components/CustomHoldingCellTemplate'
import { RowNumberCellTemplate } from 'components/Spreadsheet/CellTemplates/RowNumberCellTemplate/RowNumberCellTemplate'
import { CustomDateCellTemplate } from '../Spreadsheet/CellTemplates/CustomDateCellTemplate'
import { MetricCellTemplate } from '../Spreadsheet/CellTemplates/MetricCellTemplate'
import { CustomNumberCellTemplate } from '../Spreadsheet/CellTemplates/CustomNumberCellTemplate'
import * as Styles from '../Spreadsheet/Spreadsheet.styles'
import {
  UpdateMetricsGridProps,
  useUpdateMetricsGrid,
} from './useUpdateMetricsGrid'
import {
  MetricsSpreadsheetMode,
  getFetchCompanyFunction,
} from './MetricsSpreadsheetLogic'

const UpdateMetricsGrid = ({
  companyId,
  disableAddingColumns,
  isInDrawer,
  onPasteOutsideGrid,
  onGridChange,
  initialGrid,
  mode,
  spreadsheetBuilder,
  shouldDisplayDisclaimer,
}: UpdateMetricsGridProps) => {
  const queryClient = useQueryClient()
  const {
    handleChanges,
    addRow,
    addColumn,
    rows,
    columns,
    fetchMetrics,
    getEventHandler,
    eventHandler,
    fetchMetricsForCompany,
  } = useUpdateMetricsGrid({
    companyId,
    disableAddingColumns,
    isInDrawer,
    onPasteOutsideGrid,
    onGridChange,
    initialGrid,
    mode,
    spreadsheetBuilder,
  })

  return (
    <Styles.Container>
      <Styles.GridContainer disclaimer={shouldDisplayDisclaimer}>
        <Styles.StyledGrid isInDrawer={isInDrawer}>
          <ReactGrid
            // onCellsChanged expects only default cell types
            // @ts-ignore
            onCellsChanged={handleChanges}
            getEventHandler={getEventHandler}
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            enableRowSelection
            stickyTopRows={1}
            stickyLeftColumns={
              spreadsheetBuilder.getMode() ===
              MetricsSpreadsheetMode.MULTIPLE_HOLDINGS
                ? 2
                : 1 // The 2 doesn't work, but it's the correct value
            }
            customCellTemplates={{
              rowNumber: new RowNumberCellTemplate(),
              number: new CustomNumberCellTemplate(),
              date: new CustomDateCellTemplate(),
              dropdown: new MetricCellTemplate(
                spreadsheetBuilder.getMode(),
                fetchMetrics,
                fetchMetricsForCompany,
                eventHandler,
                companyId
              ),
              holdingDropdown: new CustomHoldingCellTemplate(
                getFetchCompanyFunction(queryClient),
                eventHandler
              ),
            }}
          />
        </Styles.StyledGrid>
        {!disableAddingColumns && (
          <Styles.VerticalPlusContainer onClick={addColumn}>
            <FontAwesomeIcon icon={['fal', 'plus']} />
          </Styles.VerticalPlusContainer>
        )}
      </Styles.GridContainer>
      <Styles.HorizontalPlusContainer
        hasRightMargin={!disableAddingColumns}
        onClick={addRow}
      >
        <FontAwesomeIcon icon={['fal', 'plus']} />
      </Styles.HorizontalPlusContainer>
    </Styles.Container>
  )
}

export default UpdateMetricsGrid
