import { GroupTypes } from 'utils/constants/groups'
import {
  Group,
  InvestorGroup,
  Session,
  User,
  UserSession,
} from 'utils/types/user'

export const getInitials = (text?: string) => {
  if (!text) return ''

  return text
    .split(' ')
    .map((word, index) => {
      if (!word || index > 1) return ''

      return word[0]
    })
    .join('')
    .toUpperCase()
}

export const getEmailProviderFromEmailAddress = (email: string) => {
  const emailProvider = email.split('@')[1]
  return emailProvider?.split('.')[0]
}

interface GetUserSessionParams<T extends User = User> {
  user: T
  userSession: UserSession
  groups: { [id: string]: Group }
  investorGroups: { [id: string]: Omit<InvestorGroup, 'type'> }
}

export const getUserSession = <T extends User = User>({
  user,
  userSession,
  groups,
  investorGroups,
}: GetUserSessionParams<T>): Session<T> => {
  const newInvestorGroups = { ...investorGroups } as {
    [id: string]: InvestorGroup
  }

  Object.values(newInvestorGroups || {}).forEach((investorGroup) => {
    newInvestorGroups[investorGroup.id].type = GroupTypes.INVESTOR_GROUP
  })

  const newGroups = { ...groups, ...newInvestorGroups }
  const groupId = Object.keys(newGroups)[0]

  const newUserSession = {
    ...userSession,
    currentGroupId: groupId,
    previousGroupId: undefined,
    groupId,
  }

  return {
    user,
    userSession: newUserSession,
    groups: newGroups,
  }
}

export const getUserSessionAuth0 = <T extends User = User>({
  user,
  userSession,
  groups,
  investorGroups,
}: GetUserSessionParams<T>): Session<T> => {
  const newInvestorGroups = { ...investorGroups } as {
    [id: string]: InvestorGroup
  }

  const newUserSession = {
    ...userSession,
    previousGroupId: undefined,
  }

  Object.values(newInvestorGroups || {}).forEach((investorGroup) => {
    newInvestorGroups[investorGroup.id].type = GroupTypes.INVESTOR_GROUP
  })

  const newGroups = { ...groups, ...newInvestorGroups }
  const groupId = Object.keys(newGroups)[0]

  if (!userSession.currentGroupId) {
    newUserSession.currentGroupId = groupId
  }

  if (!userSession.groupId) {
    newUserSession.groupId = groupId
  }

  return {
    user,
    userSession: newUserSession,
    groups: newGroups,
  }
}
