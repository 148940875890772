import dayjs from 'dayjs'

import UpdateIconFactory from 'components/UpdateIconFactory'
import { FormattedMessage, useIntl } from 'react-intl'
import { weekdays } from 'utils/functions/date'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIsPreviewingDraftUpdate } from 'utils/hooks/useIsPreviewingDraftUpdate'
import { Nullable } from 'utils/types/common'
import {
  Confidential,
  DateContainer,
  DraftLabel,
  Edited,
  Header,
  IconContainer,
  InfoContainer,
  Title,
  TitleContainer,
} from './UpdateHeader.styles'

interface UpdateHeaderProps {
  title?: string
  date?: Nullable<Date>
  updateType: string
  isConfidential: boolean
  isEdited: boolean
  isDraftUpdate: boolean
}

const UpdateHeader = ({
  title,
  date,
  updateType,
  isConfidential,
  isEdited,
  isDraftUpdate,
}: UpdateHeaderProps) => {
  const isDateValid = dayjs(date).isValid()
  const monthYear = dayjs(date).format('DD MMMM')
  const day = weekdays[dayjs(date).day()]
  const time = dayjs(date).format('hh:mmA')
  const intl = useIntl()
  const isPreviewingDraft = useIsPreviewingDraftUpdate()

  return (
    <Header>
      <IconContainer>
        <UpdateIconFactory updateType={updateType} showSimpleIcon />
      </IconContainer>

      <InfoContainer>
        <TitleContainer>
          <Title>{title}</Title>

          {isEdited && (
            <Edited>
              ({intl.formatMessage({ id: 'updates.common.edited' })})
            </Edited>
          )}

          {isDraftUpdate && isPreviewingDraft && (
            <DraftLabel>
              {intl.formatMessage({ id: 'updates.drafts.draftLabel' })}
            </DraftLabel>
          )}
        </TitleContainer>

        <DateContainer>
          {isDateValid && <p>{`${day} ${monthYear} - ${time}`}</p>}
        </DateContainer>

        {isConfidential && (
          <Confidential>
            <FontAwesomeIcon icon={['far', 'user-shield']} />
            <p>
              <FormattedMessage id="updates.confidentialUpdate" />
            </p>
          </Confidential>
        )}
      </InfoContainer>
    </Header>
  )
}

export default UpdateHeader
