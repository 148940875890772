import styled, { css } from 'styled-components'
import { Column } from 'ui/Table/types'
import { color } from 'utils/functions/colors'
import { breakpoint } from 'utils/constants/breakpoint'

export const Cell = styled.div<{
  column: Pick<Column, 'justify' | 'minWidth' | 'maxWidth' | 'flex'>
}>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.column.justify || 'flex-start'};
  min-width: ${(props) =>
    props.column.minWidth !== undefined && `${props.column.minWidth}px`};
  flex: ${(props) => props.column.flex !== undefined && props.column.flex};
  max-width: ${(props) =>
    props.column.maxWidth !== undefined && props.column.maxWidth};
`

export const FixedCellsWrapper = styled.div<{ height: number }>`
  height: 100%;
  position: sticky;
  left: 3.65rem;
  transform: translateX(-3.65rem);
  background: white;
  z-index: 6;
  border-bottom: 0.2rem solid #e9e9e9;
  background: white;
  z-index: 1;
  border-right: 0.3rem solid ${color('lightGray', 0.15)};
  height: ${(props) => props.height}px;
`

export const FixedCellsContainer = styled.div<{
  borderWidth?: string
  hideBorder?: boolean
  embeddedTable?: boolean
  rowHeight: number
}>`
  background: white;
  display: flex;
  height: ${(props) => props.rowHeight}px;
  gap: 0.5rem;
  padding-left: 3.3rem;

  ${(props) =>
    !props.hideBorder &&
    css`
      border-bottom: ${props.borderWidth || '0.1rem'} solid #e9e9e9}
    `}
`

export const ScrollableCellsContainer = styled.div<{
  borderWidth?: string
  hideBorder?: boolean
  embeddedTable?: boolean
  paddingRight?: string
  countColumns: number
}>`
  display: flex;
  gap: 0.8rem;
  flex: 1;
  justify-content: ${(props) =>
    props.countColumns === 1 ? 'flex-end' : 'space-between'};
  height: 100%;
  align-items: center;
  padding-left: ${(props) => (props.embeddedTable ? '3.6rem' : '3.3rem')};
  padding-right: ${(props) => props.paddingRight || '2.4rem'};

  @media (max-width: ${breakpoint.md}) {
    ${(props) =>
      !props.embeddedTable &&
      css`
        padding-left: 2.3rem;
        gap: 1rem;
      `}
  }

  ${(props) =>
    !props.hideBorder &&
    css`
    border-bottom: ${props.borderWidth || '0.1rem'} solid #e9e9e9}
    `}
`

export const HoverColumn = styled.div<{ forceVisible?: boolean }>`
  ${(props) =>
    !props.forceVisible &&
    css`
      opacity: 0;
    `}
`

export const ExpandArrowButton = styled.button`
  &:focus {
    outline: none;
  }
`

const hoverStyles = css<{ expandOnRowClick?: boolean }>`
  ${ScrollableCellsContainer}, ${FixedCellsContainer} {
    background-color: ${(props) =>
      props.expandOnRowClick ? '#e9ecf4' : color('veryLightBlue', 0.2)};
  }
`

export const RowContainer = styled.div.attrs(
  (props: {
    fixedList: boolean
    isHover: boolean
    expandOnRowClick: boolean
  }) => {
    if (props.isHover) {
      return {
        style: {
          backgroundColor: props.expandOnRowClick
            ? '#e9ecf4'
            : color('veryLightBlue', 0.2)(props),
        },
      }
    }
    return {}
  }
)<{
  rowHeight: number
  expandOnRowClick?: boolean
  rowExpanded?: boolean
  isHover?: boolean
  fixedList?: boolean
  fixedRowHeight?: number
  hasCustomRow?: boolean
  isExpandable?: boolean
}>`
  display: flex;
  justify-content: space-between;
  height: ${(props) => props.rowHeight}px;
  align-items: flex-start;
  cursor: pointer;

  ${(props) =>
    props.isHover &&
    css`
      ${HoverColumn} {
        opacity: 1;
      }
    `}

  ${(props) => props.rowExpanded && hoverStyles};

  ${(props) =>
    props.rowExpanded &&
    !props.fixedList &&
    css`
      margin-bottom: ${(props.fixedRowHeight ?? 0) - props.rowHeight}px;
    `}
  ${(props) =>
    !props.fixedList &&
    props.hasCustomRow &&
    css`
      margin-top: ${props.rowHeight - 3}px;
    `}
`

export const Expandable = styled.div<{
  visible?: boolean
  width: string | number
}>`
  height: auto;
  width: ${(props) =>
    typeof props.width === 'string' ? props.width : `${props.width}px`};
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
  border-bottom: 0.2rem solid #e9e9e9;
  background: white;
`
