import styled from 'styled-components'

export const PlanSliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SliderContainer = styled.div`
  display: flex;
  gap: 1.45rem;
  justify-content: space-between;
  align-items: center;
  width: -webkit-fill-available;
`

export const SliderScrollContainer = styled.div<{
  width: string
  countPlans: number
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.countPlans}, 1fr);
  gap: 3rem;
  overflow-x: auto;
  max-width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0.1rem 0;

  &::-webkit-scrollbar {
    display: none;
  }
`
