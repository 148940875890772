import React from 'react'
import { useIntl } from 'react-intl'
import { CustomNumberCell } from 'components/Spreadsheet/types'
import * as Styles from '../Template.styles'

interface NumberCellPlaceholderProps {
  cell: CustomNumberCell
}

const NumberCellPlaceholder: React.FC<NumberCellPlaceholderProps> = ({
  cell,
}) => {
  const intl = useIntl()

  return (
    <Styles.NumberCellPlaceholder centered={cell.centered}>
      {intl.formatMessage({
        id: cell.optional
          ? 'spreadsheet.optional'
          : cell.placeholderMsgId || 'metrics.update.addValue',
      })}
    </Styles.NumberCellPlaceholder>
  )
}

export default NumberCellPlaceholder
