import Grid from 'components/Portfolios/Grid'
import { SkeletonItem } from '../Skeletons.styles'

const DEFAULT_SKELETON_ITEM_WIDTH = '100%'

interface Props {
  columnsQuantity: number
  getSkeletonItemWidth?: (columns?: number) => string
  labelWidth?: string
  valueWidth?: string
  cellsQuantity?: number
}

const GridSkeleton = ({
  columnsQuantity,
  getSkeletonItemWidth,
  labelWidth,
  valueWidth,
  cellsQuantity = 8,
}: Props) => {
  return (
    <Grid gridId="gridSkeleton" columnsQuantity={columnsQuantity}>
      {new Array(cellsQuantity).fill(0).map((_, index) => (
        <Grid.Cell
          key={(index as number).toString()}
          padding="1.8rem"
          label={
            <SkeletonItem
              height="1.6rem"
              width={
                labelWidth ||
                getSkeletonItemWidth?.(3) ||
                DEFAULT_SKELETON_ITEM_WIDTH
              }
            />
          }
          value={
            <SkeletonItem
              height="1.6rem"
              width={
                valueWidth ||
                getSkeletonItemWidth?.() ||
                DEFAULT_SKELETON_ITEM_WIDTH
              }
              margin="0.5rem 0"
            />
          }
        />
      ))}
    </Grid>
  )
}

export default GridSkeleton
