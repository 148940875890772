import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useEventListener } from 'utils/hooks/useEventListener'

export const OPEN_THREAD_EVENT = 'OPEN_THREAD_EVENT'
export const CLOSE_THREAD_EVENT = 'CLOSE_THREAD_EVENT'

export const useIsThreadOpen = () => {
  const [isThreadOpen, setIsThreadOpen] = useState(false)
  const history = useHistory()

  useEventListener(OPEN_THREAD_EVENT, () => {
    setIsThreadOpen(true)
  })

  useEventListener(CLOSE_THREAD_EVENT, () => {
    setIsThreadOpen(false)
  })

  useEffect(() => {
    setIsThreadOpen(false)
  }, [history.location.pathname])

  return isThreadOpen
}
