import type { FormikTouched } from 'formik'

export const convertToFormikTouched = <T extends object>(
  object: T
): FormikTouched<T> => {
  const touched: FormikTouched<T> = {}

  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) {
      touched[key] = object[key].map((item) => {
        if (typeof item === 'object') {
          return convertToFormikTouched(item)
        }
        return true
      })
    } else if (typeof object[key] === 'object') {
      touched[key] = convertToFormikTouched(object[key])
    } else {
      touched[key] = true
    }
  })

  return touched
}
