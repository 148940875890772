import React from 'react'
import { useIntl } from 'react-intl'
import OptionsDropdown from 'ui/OptionsDropdown'
import { IndexFileContent, MixedContent } from 'utils/types/files'
import {
  isAttachedEmailFileContent,
  isIndexEmailContent,
  isUnreconciledFile,
} from 'utils/functions/files'
import theme from 'utils/theme'

interface FileActionsProps {
  id?: string
  file: MixedContent
  onDownloadFile?: (file: MixedContent) => void
  onPreviewFile?: (file: MixedContent) => void
  onDeleteFile?: (file: MixedContent) => void
  onClickGeneralInformation?: (file: MixedContent) => void
  isContentMenuOpen?: boolean
  onCloseContentMenu?: () => void
  onOpenContentMenu?: () => void
  contextMenuPosition?: { x: number; y: number }
  [x: string]: any
}

const FileActions: React.FC<FileActionsProps> = ({
  id,
  file,
  contextMenuPosition,
  isContentMenuOpen,
  onCloseContentMenu,
  onOpenContentMenu,
  onDownloadFile,
  onPreviewFile,
  onDeleteFile,
  onClickGeneralInformation,
  ...rest
}) => {
  const intl = useIntl()

  const isUnreconciled = isAttachedEmailFileContent(file)
    ? file.unreconciled
    : isUnreconciledFile((file as IndexFileContent).userContentStatus)

  const isEmailContent = isIndexEmailContent(file)

  return (
    <OptionsDropdown
      buttonType="square"
      buttonSize="3.2rem"
      dropdownContentMinWidth="18.8rem"
      isOpen={isContentMenuOpen}
      onCloseDropdown={onCloseContentMenu}
      onOpenDropdown={onOpenContentMenu}
      alignLeft
      hoverColumnId={id}
      overridePosition={contextMenuPosition}
      {...rest}
    >
      {onPreviewFile && (
        <OptionsDropdown.Item
          label={intl.formatMessage({ id: 'general.preview' })}
          icon={['fal', 'eye']}
          onSelectOption={() => onPreviewFile(file)}
        />
      )}
      {onClickGeneralInformation && (
        <OptionsDropdown.Item
          label={intl.formatMessage({
            id: 'files.properties',
          })}
          icon={['far', 'file-alt']}
          onSelectOption={() => onClickGeneralInformation(file)}
        />
      )}
      {!isEmailContent && (
        <OptionsDropdown.Item
          label={intl.formatMessage({ id: 'files.download' })}
          icon={['fal', 'download']}
          onSelectOption={() => onDownloadFile?.(file)}
        />
      )}
      {isUnreconciled && onDeleteFile && (
        <OptionsDropdown.Item
          label={intl.formatMessage({
            id: 'files.delete',
          })}
          onSelectOption={() => onDeleteFile?.(file)}
          icon={['fal', 'trash-alt']}
          color={theme.colors.red}
        />
      )}
    </OptionsDropdown>
  )
}

export default FileActions
