import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styles from './LogIcon.styles'

export type Color = (props: any) => any

interface LogIconProps {
  icon: [IconPrefix, IconName]
  color: Color
  small?: boolean
}

const LogIcon = ({ icon, color, small }: LogIconProps) => {
  return (
    <Styles.IconBase small={small} iconColor={color}>
      <FontAwesomeIcon color="white" icon={icon} />
    </Styles.IconBase>
  )
}

export default LogIcon
