import { AnalyticsGroupBy } from 'utils/constants/analytics'

export const QUERIES = {
  ANALYTICS: 'analytics',
  CONTENT_ANALYTICS: 'content_analytics',
} as const

export const analyticKeys = {
  fetchTotalAnalytics: (
    updateId: string,
    groupBy: AnalyticsGroupBy,
    startDate: Date,
    endDate: Date
  ) => [QUERIES.ANALYTICS, updateId, groupBy, startDate, endDate] as const,

  fetchAnalytics: (updateId: string) => [QUERIES.ANALYTICS, updateId] as const,

  contentAnalytics: (contentId: string) =>
    [QUERIES.CONTENT_ANALYTICS, contentId] as const,
}
