import { Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'

import ShowUpdateContent from 'containers/UpdatesV2/ShowUpdateContent'
import { UpdateTypes } from 'utils/constants/updates'
import UpdateLayout from 'components/UpdateLayout'
import Toast from 'components/Toast'
import EditUpdate from 'containers/Updates/EditUpdate/EditUpdate'
import ResendIUEModal from 'components/ResendIUEBox/components/ResendIUEModal'
import BreadcrumbActionPortal from 'components/Breadcrumb/BreadcrumbActionPortal'
import EditEmailActions from 'components/Breadcrumb/UpdateActions/EditEmailActions'
import { useEditEmail } from './useEditEmail'

const EditEmail = () => {
  const {
    update,
    register,
    handleSubmit,
    onSubmit,
    onCardExpanded,
    companyId,
    emailId,
    isCardExpanded,
    updatePermissions,
    items,
    setSharedGroups,
    setSharedUsers,
    setValue,
    setVisibility,
    sharedGroups,
    sharedUsers,
    visibility,
    tagsList,
    currentGroupName,
    redirectToShow,
    additionalRecipientsModal,
    toggleAdditionalRecipientsModal,
    isSubmitting,
    reshareSettings,
    onChangeReshareSettings,
    ownByGroup,
    setOwnByGroup,
  } = useEditEmail()

  const intl = useIntl()

  if (!update) {
    return null
  }

  if (!updatePermissions.hasEditPermissions) {
    Toast.display(
      intl.formatMessage(
        { id: 'errors.groupNoAccessEdit' },
        { groupName: currentGroupName, name: 'email' }
      ),
      'error'
    )
    return <Redirect to={`/companies/${companyId}/emails/${emailId}`} />
  }

  return (
    <>
      <BreadcrumbActionPortal>
        <EditEmailActions
          toggleAdditionalRecipientsModal={toggleAdditionalRecipientsModal}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit(onSubmit)}
          onCancel={redirectToShow}
        />
      </BreadcrumbActionPortal>

      <EditUpdate
        updateType={UpdateTypes.IUE}
        attachmentsMode="grid"
        handleSubmit={handleSubmit(onSubmit)}
        onCardExpanded={onCardExpanded}
        isCardExpanded={isCardExpanded}
        register={register}
        originalFiles={[]}
        updateLoading={!update}
        setValue={setValue}
        update={update}
        redirectToShow={redirectToShow}
        visibility={visibility}
        sharedGroups={sharedGroups}
        sharedUsers={sharedUsers}
        setVisibility={setVisibility}
        setSharedGroups={setSharedGroups}
        setSharedUsers={setSharedUsers}
        items={items}
        tagsList={tagsList}
        updateId={update.id}
        dropdownOptions={undefined}
        loader={null}
        isSubmitting={isSubmitting}
        reshareSettings={reshareSettings}
        onChangeReshareSettings={onChangeReshareSettings}
        ownByGroup={ownByGroup}
        setOwnByGroup={setOwnByGroup}
      >
        <UpdateLayout.Tab id="general">
          <ShowUpdateContent
            updateType={UpdateTypes.IUE}
            update={update}
            hideHeader
          />
        </UpdateLayout.Tab>
      </EditUpdate>
      <ResendIUEModal
        emailId={emailId}
        openModal={additionalRecipientsModal}
        setOpenModal={toggleAdditionalRecipientsModal}
      />
    </>
  )
}

export default EditEmail
