import { useState } from 'react'

export type HoverIndexProps = {
  getIsIndexHover: (index: number) => boolean
  addHoverIndex: (index: number) => void
  removeHoverIndex: (index: number) => void
}

export const useHoverIndex = (): HoverIndexProps => {
  const [hoverIndex, setHoverIndex] = useState<Record<string, boolean>>({})

  const addHoverIndex = (index: number) => {
    setHoverIndex((prev) => ({
      ...prev,
      [index]: true,
    }))
  }

  const removeHoverIndex = (index: number) => {
    setHoverIndex((prev) => {
      const newHoverIndex = { ...prev }
      delete newHoverIndex[index]
      return newHoverIndex
    })
  }

  const getIsIndexHover = (index: number) => {
    return !!hoverIndex[index]
  }

  return {
    addHoverIndex,
    removeHoverIndex,
    getIsIndexHover,
  }
}
