import type { IntlShape } from 'react-intl'
import { getDefaultOrganizationsDate } from 'utils/functions/date'
import { MixedContent } from 'utils/types/files'

export const findDayChangeIndices = ({
  contents,
  isLoading,
  intl,
}: {
  contents: MixedContent[]
  isLoading?: boolean
  intl: IntlShape
}): number[] => {
  const indices: number[] = []

  if (isLoading) {
    return [0, 3, 6, 9, 12, 15, 18, 21, 24]
  }

  let currentDate: string | null = null

  for (let i = 0; i < contents.length; i++) {
    const contentDate = getDefaultOrganizationsDate({
      date: contents[i].createdAt,
      intl,
    })

    if (currentDate !== contentDate) {
      indices.push(i)
      currentDate = contentDate
    }
  }

  return indices
}
