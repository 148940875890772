import CardToStyle from 'components/Card'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Card = styled(CardToStyle)`
  flex: 1;
`

export const LoaderWrapper = styled(Card)`
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const FetchingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
`

export const ZeroState = styled.div`
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  color: ${color('lightGray')};
`

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  height: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
`
