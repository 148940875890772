import React from 'react'
import PropTypes from 'prop-types'
import { Check, CheckContainer } from './CheckItem.styles'

const CheckItem = ({ checked }) => {
  return (
    <CheckContainer checked={checked}>
      <Check checked={checked} />
    </CheckContainer>
  )
}

CheckItem.propTypes = {
  checked: PropTypes.bool.isRequired,
}
export default CheckItem
