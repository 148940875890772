import { useEffect, useState } from 'react'
import {
  castLoggingUpdateGroup,
  castLoggingUpdateUser,
  getPermissionItems,
  getPermissionItemsFromReshareTokenSet,
  ONLY_ME,
  ReshareTokenSet,
} from 'utils/functions/updates'
import { PermissionItem, UpdateVisibilityType } from 'utils/types/common'
import { Update } from 'utils/types/update'

type SetUpdatePermissionsHookParams = {
  update?: Update
  isConfidentialUpdate?: boolean
  isBlockedUpdate?: boolean
  initialVisibility?: UpdateVisibilityType
  reshareTokenSet?: ReshareTokenSet
}

type ReshareSettings = {
  isConfidential: boolean
  isBlocked: boolean
}
type ReshareSettingKeys = keyof ReshareSettings

export const useSetUpdatePermissions = ({
  update,
  isConfidentialUpdate,
  isBlockedUpdate,
  initialVisibility,
  reshareTokenSet,
}: SetUpdatePermissionsHookParams = {}) => {
  const [items, setItems] = useState<PermissionItem[]>([])
  const [sharedGroups, setSharedGroups] = useState<string[]>([])
  const [sharedUsers, setSharedUsers] = useState<string[]>([])
  const [visibility, setVisibility] = useState<UpdateVisibilityType>(
    initialVisibility || ONLY_ME
  )
  const [reshareSettings, setReshareSettings] = useState<ReshareSettings>({
    isConfidential: !!isConfidentialUpdate,
    isBlocked: !!isBlockedUpdate,
  })
  const [ownByGroup, setOwnByGroup] = useState(false)

  const onChangeReshareSettings = (key: ReshareSettingKeys, value: boolean) => {
    setReshareSettings((prev) => ({
      ...prev,
      [key]: value,
    }))
  }

  useEffect(() => {
    if (isConfidentialUpdate !== undefined) {
      onChangeReshareSettings('isConfidential', isConfidentialUpdate)
    }
  }, [isConfidentialUpdate])

  useEffect(() => {
    if (isBlockedUpdate !== undefined) {
      onChangeReshareSettings('isBlocked', isBlockedUpdate)
    }
  }, [isBlockedUpdate])

  useEffect(() => {
    if (update) {
      setSharedGroups(
        update.loggingUpdateGroups
          .filter((loggingGroup) => loggingGroup.original)
          .map((loggingGroup) => loggingGroup.groupableId)
      )
      setSharedUsers(
        update.loggingUpdateUsers
          .filter((loggingGroup) => loggingGroup.original)
          .map((loggingUser) => loggingUser.userId)
      )
      setOwnByGroup(update.ownedByGroup)
      setItems(getPermissionItems(update))
    }
  }, [update])

  useEffect(() => {
    if (reshareTokenSet) {
      setSharedGroups(
        reshareTokenSet.loggingUpdateEntities
          .filter(castLoggingUpdateGroup)
          .map((loggingGroup) => loggingGroup.groupableId)
      )
      setSharedUsers(
        reshareTokenSet.loggingUpdateEntities
          .filter(castLoggingUpdateUser)
          .map((loggingUser) => loggingUser.userId)
      )

      setItems(getPermissionItemsFromReshareTokenSet(reshareTokenSet))
    }
  }, [reshareTokenSet])

  return {
    items,
    setItems,
    sharedGroups,
    setSharedGroups,
    sharedUsers,
    setSharedUsers,
    visibility,
    setVisibility,
    onChangeReshareSettings,
    reshareSettings,
    ownByGroup,
    setOwnByGroup,
  }
}
