import { useCallback, useEffect, useRef, useState } from 'react'
import InboundService from 'api/InboundService'
import { InboundStatus } from 'utils/types/inbounds'
import {
  useEditInboundMutation,
  useUpdateInboundCache,
} from './useInboundsQuery'

export const TEST_CONNECTION_TIMEOUT_SECONDS = 30
const TEST_CONNECTION_TIMEOUT_MS = TEST_CONNECTION_TIMEOUT_SECONDS * 1000
const POLLING_INTERVAL = 2000

export const useTestConnection = (
  inboundId: string,
  previousLastSuccessfulConnection?: string
) => {
  const [isTestingConnection, setIsTestingConnection] = useState(false)
  const receiveEmailTimeout = useRef<NodeJS.Timeout>()
  const pollingTimeout = useRef<NodeJS.Timeout>()
  const { updateInboundCache } = useUpdateInboundCache()
  const { mutateAsync: editAsync } = useEditInboundMutation(inboundId)

  const inboundStatusPolling = useCallback(
    async (onSuccess?: () => void) => {
      const inbound = await InboundService.getById(inboundId)
      const previousLastSuccessfulConnectionDate =
        previousLastSuccessfulConnection
          ? new Date(previousLastSuccessfulConnection)
          : undefined

      if (
        inbound.lastConnection &&
        new Date(inbound.lastConnection).getTime() !==
          previousLastSuccessfulConnectionDate?.getTime()
      ) {
        clearTimeout(receiveEmailTimeout.current!)
        clearTimeout(pollingTimeout.current!)
        updateInboundCache(inboundId, inbound)
        setIsTestingConnection(false)
        onSuccess?.()
      } else {
        pollingTimeout.current = setTimeout(
          () => inboundStatusPolling(onSuccess),
          POLLING_INTERVAL
        )
      }
    },
    [inboundId, updateInboundCache, previousLastSuccessfulConnection]
  )

  const testConnection = useCallback(
    async (onSuccess?: () => void, onFailure?: () => void) => {
      try {
        setIsTestingConnection(true)
        await InboundService.sendTestEmail(inboundId)
        inboundStatusPolling(onSuccess)

        receiveEmailTimeout.current = setTimeout(() => {
          clearTimeout(pollingTimeout.current!)
          updateInboundCache(inboundId, {
            connectionStatus: InboundStatus.NOT_CONNECTED,
          })
          editAsync({
            connectionStatus: InboundStatus.NOT_CONNECTED,
          })
          setIsTestingConnection(false)
          onFailure?.()
        }, TEST_CONNECTION_TIMEOUT_MS)
      } catch (error) {
        setIsTestingConnection(false)
      }
    },
    [inboundId, updateInboundCache, editAsync, inboundStatusPolling]
  )

  useEffect(() => {
    return () => {
      clearTimeout(pollingTimeout.current!)
      clearTimeout(receiveEmailTimeout.current!)
    }
  }, [])

  return {
    pollingTimeout: pollingTimeout.current,
    isTestingConnection,
    testConnection,
    inboundStatusPolling,
  }
}
