import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import Chip from 'components/Chip'

import Dropdown from 'ui/Dropdown'
import TagService from 'api/TagService'

import { getCurrentGroupName } from 'selectors/auth'

import { Tag } from 'utils/types/update'
import styles from './TagsInput.module.scss'

interface TagsInputProps {
  tagsList: Tag[]
  onAddTag: (tagName: string) => void
  onRemoveTag: (tag: Tag) => void
  validationWhenAddingTag: (tagName: string) => boolean
  className?: string
  placeholder?: string
  maxTagWidth?: string
}

const TagsInput = React.forwardRef(
  (
    {
      onAddTag,
      onRemoveTag: onRemoveTagProp,
      tagsList: tagsListProp,
      validationWhenAddingTag,
      className,
      placeholder,
      maxTagWidth,
    }: TagsInputProps,
    ref: (arg?: any) => {}
  ) => {
    const [tagsList, setTagsList] = useState<Tag[]>([])
    const dropdownRef = useRef<any>(null)
    const groupName = useAppSelector(getCurrentGroupName)

    const intl = useIntl()

    useEffect(() => {
      setTagsList(tagsListProp)
    }, [tagsListProp])

    const onSelectTag = (selectedTag) => {
      document.getElementById('tags-add')?.blur()
      dropdownRef.current?.close()
      const tagNameValue = selectedTag.name ?? selectedTag

      const doesTagExistInList = tagsList.find(
        (tag) =>
          !tag.destroy &&
          (tag.name?.trim() === selectedTag.name?.trim() ||
            tag.name?.trim() === selectedTag)
      )

      if (!doesTagExistInList) {
        if (!validationWhenAddingTag) {
          onAddTag(tagNameValue)
        } else {
          const isValidTag = validationWhenAddingTag(
            tagNameValue.replace(/\s/g, '')
          )

          if (isValidTag) {
            onAddTag(tagNameValue)
          }
        }
      }
    }

    const onRemoveTag = (tag) => {
      onRemoveTagProp(tag)
    }

    const displayTags = tagsList?.map((tag) =>
      tag.destroy ? null : (
        <li key={tag.name} style={{ maxWidth: maxTagWidth }}>
          <Chip
            text={tag.name}
            handleDelete={() => {
              onRemoveTag(tag)
            }}
            titleWithTooltip
          />
        </li>
      )
    )

    const searchTags = useCallback(
      async (value) => {
        const {
          data: { result, entities },
        } = await TagService.getTags(value)
        return [
          {
            id: 'group-options',
            icon: ['far', 'tag'],
            group: `${groupName} ${intl.messages['tags.tagsTitle']}`,
            subOptions: result.map((tagId) => {
              return entities?.tags?.[tagId]
            }),
          },
        ]
      },
      [groupName, intl.messages]
    )

    return (
      <div
        id="tagsWrapper"
        className={classNames(styles.tagListWrapper, className)}
      >
        <Dropdown
          ref={dropdownRef}
          id="tags-add"
          name="companies"
          async
          loadOptions={searchTags}
          getOption={(entity) => ({ label: entity.name, id: entity.id })}
          onSelectOption={(_, __, value) => {
            onSelectTag(value)
          }}
          placeholder={
            placeholder || intl.formatMessage({ id: 'tags.addTagPlaceholder' })
          }
          type="input"
          icon={['far', 'plus']}
          clearOnSelect
          addOptionEnabled
          withGroupOptions
          hideGroupLabelIfEmpty
          resetSearchOnFocus
          onAddOption={onSelectTag}
          capitalizeOptions={false}
          optionTitlePadding="0rem 0.8rem 1.1rem 0.8rem"
          iconFontSize="1.8rem"
          withCounter
          counterStyle={{ right: '2rem' }}
        />
        <ul
          ref={ref}
          id="tagsList"
          className={classNames(styles.tagList, 'fs-exclude')}
        >
          {displayTags}
        </ul>
      </div>
    )
  }
)

export default TagsInput
