import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import IconButton from 'ui/IconButton'

import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  clearUploads,
  getIsMenuExpanded,
  getIsMenuOpen,
  getIsUploadingFiles,
  showUploadsMenu,
  toggleExpandMenu,
  cancelUploads,
} from 'reducers/uploadsSlice'
import Separator from 'ui/Separator'
import { useUploadFile } from 'utils/hooks/useUploadFile'
import FileUploadRow from './components/FileUploadRow/FileUploadRow'
import UploadsMenuAnimationWrapper from './components/UploadsMenuAnimationWrapper'
import * as Styles from './UploadsMenu.styles'

interface UploadMenuProps {
  closeOnUnmount?: boolean
}

const UploadMenu: React.FC<UploadMenuProps> = ({ closeOnUnmount }) => {
  const isOpen = useAppSelector(getIsMenuOpen)
  const isExpanded = useAppSelector(getIsMenuExpanded)
  const isUploadingFiles = useAppSelector(getIsUploadingFiles)
  const dispatch = useAppDispatch()

  const { uploads, cancelUpload, restartUpload, deleteUpload } = useUploadFile()

  useEffect(() => {
    return () => {
      if (closeOnUnmount) {
        dispatch(showUploadsMenu(false))
        dispatch(clearUploads())
        dispatch(cancelUploads())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickMenuButton = (event: MouseEvent) => {
    event.preventDefault()
    if (isUploadingFiles) {
      dispatch(toggleExpandMenu())
    } else {
      dispatch(showUploadsMenu(false))
      dispatch(clearUploads())
    }
  }

  const getMenuIcon = () => {
    if (isUploadingFiles) {
      return isExpanded ? 'angle-down' : 'angle-up'
    }

    return 'times'
  }
  return (
    <Styles.PositionContainer>
      <UploadsMenuAnimationWrapper isVisible={isOpen}>
        <Styles.Container>
          <Styles.Header>
            <Styles.Title>
              <FormattedMessage id="files.uploadingFiles" />
            </Styles.Title>
            <IconButton
              icon={getMenuIcon()}
              onClick={onClickMenuButton}
              buttonType="square"
              border={false}
              buttonSize="3.2rem"
            />
          </Styles.Header>
          {isExpanded && <Separator space="1.6rem" />}
          <Styles.Expandable isExpanded={isExpanded}>
            <Styles.Content>
              {uploads.map((upload, index) => (
                <FileUploadRow
                  key={upload.id}
                  fileName={upload.file.name}
                  timeLeft={upload.uploadTimeLeft}
                  uploadState={upload.status}
                  isLast={index === uploads.length - 1}
                  onCancelUpload={() => cancelUpload(upload.id)}
                  onRestartUpload={() => restartUpload(upload.id)}
                  onDeleteUpload={() => deleteUpload(upload.id)}
                />
              ))}
            </Styles.Content>
          </Styles.Expandable>
        </Styles.Container>
      </UploadsMenuAnimationWrapper>
    </Styles.PositionContainer>
  )
}

export default UploadMenu
