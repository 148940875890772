import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'

export const PaddedStyledLayout = styled.div<{ customPadding?: string }>`
  padding: ${({ customPadding }) => customPadding || '3.2rem'};
  position: relative;

  &:empty {
    min-height: auto;
  }

  @media ${maxSize.mobile} {
    padding: 1.2rem 3rem 3rem;
  }

  @media ${maxSize.md} {
    padding: 1.2rem 2.4rem;
  }
`
