import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const CheckContainer = styled.div`
  border-color: #d0d2d7;
  border-width: 0.1rem;
  width: 2.8rem;
  min-width: 2.8rem;
  height: 2.8rem;
  border-radius: 100%;
  position: relative;
  ${(props) =>
    props.checked &&
    css`
      border-width: 0.2rem;
      border-color: ${color('green')};
    `}
`
export const Check = styled.span`
  position: absolute;
  left: 0.9rem;
  top: 0.3rem;

  &:after {
    content: '';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    height: 1.4rem;
    transform: rotate(45deg);
    width: 0.6rem;
    left: 0.9rem;
    top: 0.4rem;
    border-width: 0 0.2rem 0.2rem 0;
    border-color: ${color('green')};
  }
`
