import styled from 'styled-components'

import { color } from 'utils/functions/colors'

export const Container = styled.div`
  padding: 3.2rem 3.4rem;
  display: flex;
  align-items: center;
  border-radius: 0.8px;
  border: 1px solid ${color('veryLightGray')};
  color: ${color('lightGray')};
  background-color: ${color('white')};
  box-sizing: border-box;
  height: 11rem;
`

export const Text = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: baseline;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
`
