import GroupService from 'api/GroupService'
import SubscriptionService from 'api/SubscriptionService'
import UserService from 'api/UserService'
import Toast from 'components/Toast'
import { updateUser } from 'features/authSlice'
import {
  getBillingAccount,
  getBillingPeriod,
  getGroupData,
  getGroupType,
  getPlanId,
  getSubscription,
  resetSignup,
  setBillingAccount,
  setBillingPeriod,
  setGroupData,
  setGroupType,
  setPlanId,
  setSetupAccount,
  setSubscription,
} from 'features/signUpSlice'
import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getUser } from 'selectors/auth'
import { GroupTypes } from 'utils/constants/groups'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  BillingAccount,
  GroupData,
  Plan,
  SetupAccount,
} from 'utils/types/signUp'
import {
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import { BillingAccountFormValues } from '../GetStarted'
import { useGroupSetup } from '../GroupSetup/useGroupSetup'
import {
  SignupContext,
  SignupContextProps,
  getGroupTypeFromPlanId as getGroupTypeFromPlan,
} from './SignupContext'

export const SignupProvider: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [paymentError, setPaymentError] = useState<string>('')
  const [isUpdatingUserInfo, setIsUpdatingUserInformation] = useState(false)
  const { createGroup } = useGroupSetup()
  const [isLoadingChangeBillingPeriod, setIsLoadingChangeBillingPeriod] =
    useState(false)
  const planId = useAppSelector(getPlanId)
  const billingPeriod = useAppSelector(getBillingPeriod)
  const billingAccount = useAppSelector(getBillingAccount)
  const user = useAppSelector(getUser) // Defined for google sign-up
  const { clientSecret, subscriptionId } = useAppSelector(getSubscription) ?? {}
  const signupGroupType = useAppSelector(getGroupType)
  const groupData = useAppSelector(getGroupData)

  const onPlanSelected = useCallback(
    (selectedPlan: Plan, selectedBillingPeriod: SubscriptionBillingPeriod) => {
      if (selectedPlan.id !== SubscriptionPlan.ORGANIZATION) {
        const groupType = getGroupTypeFromPlan(selectedPlan.id)

        dispatch(setGroupType(groupType))
        dispatch(setPlanId(selectedPlan.id))
        dispatch(setBillingPeriod(selectedBillingPeriod))
        history.push('/welcome/account-setup')
      }
    },
    [dispatch, history]
  )

  const updateUserData = useCallback(
    async (values: SetupAccount) => {
      setIsUpdatingUserInformation(true)
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        name: `${values.firstName} ${values.lastName}`,
      }
      const updatedUser = await UserService.updateUser(user!.id, payload)
      dispatch(updateUser(updatedUser))
      setIsUpdatingUserInformation(false)
    },
    [dispatch, user]
  )

  const onSetupAccount = useCallback(
    async (values: SetupAccount) => {
      await dispatch(setSetupAccount(values))

      await updateUserData(values)
      if (signupGroupType === GroupTypes.FOUNDER) {
        history.push('/welcome/founder-setup')
      } else {
        history.push('/welcome/group-setup')
      }
    },
    [dispatch, history, signupGroupType, updateUserData]
  )

  const onCreateGroup = useCallback(
    async (values: GroupData) => {
      await dispatch(setGroupData(values))
      history.push('/welcome/billing')
    },
    [dispatch, history]
  )

  const onContinueForUserAddedFromGroupManagement = useCallback(
    async (values: SetupAccount) => {
      await updateUserData(values)
      history.push('/')
    },
    [history, updateUserData]
  )

  const createSubscription = useCallback(
    async (billingAccountValues: BillingAccount) => {
      const subscription = await SubscriptionService.createSubscription(
        billingAccountValues
      )

      dispatch(
        setSubscription({
          clientSecret: subscription.stripeClientSecret,
          customerId: subscription.stripeCustomerId,
          subscriptionId: subscription.stripeSubscriptionId,
        })
      )
    },
    [dispatch]
  )

  const onClickContinueToPayment = useCallback(
    async (values: BillingAccountFormValues) => {
      try {
        const accountValues: BillingAccount = {
          ...values,
          address: {
            ...values.address,
            country: values.country,
            address: values.address?.street,
            postalCode: values.address?.zip,
          },
          subscription: {
            plan: planId!,
            trial: values.trial!,
            billingPeriod: billingPeriod!,
          },
        }

        dispatch(setBillingAccount(accountValues))
        await createSubscription(accountValues)
        history.push('/welcome/payment')
      } catch {
        Toast.displayIntl('signup.payment.createPaymentIntentError', 'error')
      }
    },
    [billingPeriod, createSubscription, dispatch, planId, history]
  )

  const onPaymentSuccess = useCallback(
    async (paymentIntentId: string) => {
      SubscriptionService.confirmPayment(user!.id, paymentIntentId)

      await createGroup({
        onCreateNewGroup:
          getGroupTypeFromPlan(planId!) === GroupTypes.FUND_MANAGER
            ? GroupService.createFundManagerGroup
            : GroupService.createClientGroup,
        groupName: groupData?.groupName,
      })
    },
    [user, createGroup, planId, groupData?.groupName]
  )

  const goBackToPayment = useCallback(
    (stripeClientSecret: string, error: string) => {
      setPaymentError(error)
      dispatch(setSubscription({ clientSecret: stripeClientSecret }))
      history.replace('/welcome/payment')
    },
    [dispatch, history]
  )

  const onClickFounderSetup = useCallback(() => {
    dispatch(setGroupType(GroupTypes.FOUNDER))
    history.push('/welcome/account-setup')
  }, [dispatch, history])

  const onCancel = useCallback(() => {
    history.replace('/')
    dispatch(resetSignup())
  }, [dispatch, history])

  const onChangeBillingPeriod = useCallback(async () => {
    const accountValues: BillingAccount = {
      ...billingAccount!,
      subscription: {
        ...billingAccount?.subscription!,
        billingPeriod:
          billingAccount?.subscription.billingPeriod ===
          SubscriptionBillingPeriod.MONTHLY
            ? SubscriptionBillingPeriod.ANNUALLY
            : SubscriptionBillingPeriod.MONTHLY,
      },
    }

    setIsLoadingChangeBillingPeriod(true)
    dispatch(setBillingAccount(accountValues))
    await createSubscription(accountValues)
    setIsLoadingChangeBillingPeriod(false)
  }, [billingAccount, createSubscription, dispatch])

  const value: SignupContextProps = useMemo(
    () => ({
      urlPrefix: '/welcome',
      isUpdatingUserInfo,
      onCancel,
      onSetupAccount,
      onClickFounderSetup,
      investorSignup: {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
        isTrial: billingAccount?.subscription.trial,
        billingAccount,
        subscriptionId,
        clientSecret,
        paymentError,
        onPlanSelected,
        onClickContinueToPayment,
        onCreateGroup,
        onPaymentSuccess,
        goBackToPayment,
        onContinueForUserAddedFromGroupManagement,
        onChangeBillingPeriod,
        isLoadingChangeBillingPeriod,
      },
    }),
    [
      isUpdatingUserInfo,
      onCancel,
      user?.firstName,
      user?.lastName,
      user?.email,
      billingAccount,
      subscriptionId,
      clientSecret,
      paymentError,
      onPlanSelected,
      onSetupAccount,
      onClickContinueToPayment,
      onCreateGroup,
      onPaymentSuccess,
      goBackToPayment,
      onContinueForUserAddedFromGroupManagement,
      onChangeBillingPeriod,
      isLoadingChangeBillingPeriod,
      onClickFounderSetup,
    ]
  )

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  )
}
