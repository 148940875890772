import { CountryCode, Nullable } from './common'

export enum SubscriptionPlan {
  LITE = 'lite',
  INDIVIDUAL = 'basic',
  PRO = 'pro',
  ORGANIZATION = 'organization',
}

export enum SubscriptionBillingPeriod {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}

export enum TrialType {
  FREE_TRIAL = 'free_trial',
  REFUND_WINDOW_TRIAL = 'refund_window_trial',
}

export enum SubscriptionStatus {
  INCOMPLETE = 'incomplete',
  PAST_DUE = 'past_due',
  ACTIVE = 'active',
  CANCELED = 'canceled',
  PROCESSING = 'processing',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
}

export enum PaymentMethodType {
  CARD = 'card',
  CASH_APP = 'cashapp',
  LINK = 'link',
  BANK_ACCOUNT = 'us_bank_account',
}

export enum CouponType {
  AMOUNT_OFF = 'AMOUNT_OFF',
  PERCENT_OFF = 'PERCENT_OFF',
}

export interface Coupon {
  id: string
  type: CouponType
  name: Nullable<string>
  amount: number
  currency: Nullable<string>
  duration: 'forever' | 'once' | 'repeating'
  durationInMonths: Nullable<number>
  promotionCode: Nullable<string>
  end: Nullable<Date>
}

export interface Subscription {
  id: string
  plan: SubscriptionPlan
  billingPeriod: SubscriptionBillingPeriod
  nextBillingDate: Date
  status: SubscriptionStatus
  canceledAt: Date | null
  paymentMethodType: PaymentMethodType
  paymentMethodData: PaymentMethodData
  customer: Customer
  trialType: Nullable<TrialType>
  isDeleted: boolean
}
export interface DeletedSubscription extends Subscription {
  isDeleted: true
}
export interface ExistentSubscription extends Subscription {
  priceWithoutDiscount: number
  price: number
  discountAmount: number
  discountPercent: number
  coupon: Nullable<Coupon>
  isDeleted: false
}

export function isExistentSubscription(
  subscription: Subscription
): subscription is ExistentSubscription {
  return !subscription.isDeleted
}

export function isDeletedSubscription(
  subscription: Subscription
): subscription is DeletedSubscription {
  return subscription.isDeleted
}

export interface PaymentMethodData {}

export interface CardPaymentMethodData extends PaymentMethodData {
  brand: string
  country: string
  expMonth: number
  expYear: number
  last4: string
}

export interface BankAccountPaymentMethodData extends PaymentMethodData {
  bankName: string
  last4: string
  accountType: string
}
export interface LinkPaymentMethodData extends PaymentMethodData {
  email: string
}

export interface Customer {
  id: string
  name: string
  email: string
  phone: any
  address: CustomerAddress
}

export interface CustomerWithPaymentMethodData extends Customer {
  paymentMethod?: {
    id: string
    paymentMethodType: PaymentMethodType
    paymentMethodData: PaymentMethodData
  }
}

export interface CustomerAddress {
  address: Nullable<string>
  city: Nullable<string>
  state: Nullable<string>
  postalCode: Nullable<string>
  country: CountryCode
}

export enum SubscriptionChangeType {
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE',
  CANCEL = 'CANCEL',
}

export interface StripeInvoiceItem {
  id: string
  amount: number
  amountExcludingTax: number
  quantity: number
  currency: string
  description: string
}

export interface StripeInvoice {
  id: string
  amountDue: number
  periodEnd: number
  periodStart: number
  subscriptionProrationDate: number
  subtotal: number
  subtotalExcludingTax: number
  total: number
  totalExcludingTax: number

  lines: {
    data: StripeInvoiceItem[]
  }
}
export interface StripeInvoiceWithSubscription extends StripeInvoice {
  subscription: {
    id: string
    currentPeriodEnd: number
  }
}

export interface ChangePlanInvoiceData {
  invoice: StripeInvoiceWithSubscription
  coupon: Coupon | null
  nextBillingDateNewPrice: number
  nextBillingDateDiscountAmount: number
  nextBillingDateDiscountPercent: number
}

export interface PromotionCode {
  id: string
  active: boolean
  code: string
  coupon: {
    id: string
    amountOff: number
    percentOff: number
    valid: boolean
  }
  created: number
}

export interface CreateSubscriptionData {
  plan: SubscriptionPlan
  trial: boolean
  billingPeriod: SubscriptionBillingPeriod
  coupon?: string
}
