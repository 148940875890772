import styled from 'styled-components'
import { motion } from 'framer-motion'

import { color } from 'utils/functions/colors'

export const Li = styled(motion.li)`
  display: grid;
  align-items: center;
  padding: 1.6rem 3.2rem;
  grid-template-columns: auto 1fr auto auto;

  &:not(:last-child) {
    border-bottom: 1px solid ${color('veryLightGray')};
  }
`

export const AvatarContainer = styled.div`
  padding-right: 1.8rem;
`

export const Email = styled.span`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-right: 3.7rem;
  display: block;
  word-break: break-all;
`
