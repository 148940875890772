import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Card from 'components/Card'
import InfiniteScroll from 'components/InfiniteScrollRef/InfiniteScroll'
import SkeletonInvitationsCard from 'components/Skeletons/Metrics/SkeletonInvitationsCard/SkeletonInvitationsCard'
import {
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'
import { METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import { useAutoAnimate } from 'utils/hooks/useAutoAnimate'
import theme from 'utils/theme'
import { usePendingMetricsInvitationsQuery } from 'utils/hooks/metrics/useMetricsInvitationsQueries'
import InvitationRow from '../InvitationRow'
import { CardBody } from '../../Invitations.styles'

interface PendingInvitationsProps {
  setHasPendingInvitations: (hasPendingInvitations: boolean) => void
}

const PendingInvitations: React.FC<PendingInvitationsProps> = ({
  setHasPendingInvitations,
}) => {
  const [isPaginationEnabled, setIsPaginationEnabled] = useState(true)
  const [animationParent] = useAutoAnimate()
  const { isLoading, pendingInvitations, fetchNextPendingInvitationsPage } =
    usePendingMetricsInvitationsQuery(setIsPaginationEnabled)

  const invitations = useMemo(() => {
    if (isLoading) {
      return []
    }

    return pendingInvitations?.pages?.flatMap((page) => page.data) || []
  }, [isLoading, pendingInvitations?.pages])

  const hasPendingInvitations = useMemo(() => {
    if (isLoading) {
      return true
    }

    return invitations.length > 0
  }, [isLoading, invitations.length])

  useEffect(() => {
    setHasPendingInvitations(hasPendingInvitations)
  }, [hasPendingInvitations, setHasPendingInvitations])

  return isLoading || hasPendingInvitations ? (
    <Card
      isExpandable
      padding="0"
      arrowColor={isLoading ? theme.colors.lightGray : undefined}
    >
      <Card.Header padding="3rem 2.4rem">
        {isLoading ? (
          <SkeletonItemWrapper>
            <SkeletonItem width="12rem" height="1.8rem" />
          </SkeletonItemWrapper>
        ) : (
          <FormattedMessage id="metrics.invitations.pendingInvitations" />
        )}
      </Card.Header>
      <CardBody>
        {isLoading ? (
          <SkeletonInvitationsCard />
        ) : (
          <>
            <div ref={animationParent}>
              {invitations.map((invitation) => (
                <InvitationRow
                  key={invitation.id}
                  invitation={invitation}
                  isPendingInvitation
                />
              ))}
            </div>
            <InfiniteScroll
              enabled={isPaginationEnabled}
              fetchNextPage={fetchNextPendingInvitationsPage}
              pageSize={METRICS_PAGE_SIZE}
            />
          </>
        )}
      </CardBody>
    </Card>
  ) : null
}

export default PendingInvitations
