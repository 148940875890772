/* eslint-disable consistent-return, default-case */
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import {
  getCurrentGroupId,
  isActingAsFounder,
  getCurrentGroupType,
} from 'selectors/auth'
import { UpdateTypes } from 'utils/constants/updates'
import { UpdateType } from 'utils/types/common'
import { getHoldingUrl } from 'utils/types/company'
import {
  INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES,
  VEHICLES_ALL_UPDATES,
} from 'routes/constant/investorManagementRoutes'

import {
  BaseUpdateUrl,
  ShowUpdateFromInvestmentVehicleParams,
  ShowUpdateFromInvestorParams,
  ShowUpdateFromGroupProfile,
  ShowUpdateFromPortfolioParams,
  ShowUpdateFromDashboardParams,
  ShowUpdateFromCompanyProfileParams,
  ShowUpdateFromEmails,
  ShowUpdateParamsFromUpdates,
  ShowUpdateFromInvestorGroup,
  UpdateUrlSuffix,
  ShowTransactionFromCompanyProfileParams,
  ShowUpdateParams,
} from './types'

const BASE_ROUTE_STATE = {
  canGoBack: true,
}

/**
 * Hook with handlers to redirect to the show update view from different views:
 * - InvestmentVehicle
 * - Investor
 * - CompanyUpdates
 * - GroupProfile
 * - Portfolio
 * - Dashboard
 * - CompanyProfile
 * - InvestmentVehicle
 * - Emails
 * - Group Profile
 *
 * Each method will send in the route state the required values (`company`, `portfolio`, etc)  to correctly populate the header in `src/UpdateNavigationHeader` component
 *
 * @returns a set of handlers to redirect to the show update view
 *
 */
export const useShowUpdateRoute = () => {
  const history = useHistory()
  const groupId = useAppSelector(getCurrentGroupId)
  const isFounder = useAppSelector(isActingAsFounder)
  const currentGroupType = useAppSelector(getCurrentGroupType)
  const intl = useIntl()

  const getUpdateBaseUrl = (updateType: UpdateType): BaseUpdateUrl => {
    switch (updateType) {
      case UpdateTypes.NOTE:
        return BaseUpdateUrl.NOTES
      case UpdateTypes.DOCUMENT:
        return BaseUpdateUrl.DOCUMENTS
      case UpdateTypes.ANNOUNCEMENT:
        return BaseUpdateUrl.ANNOUNCEMENTS
      case UpdateTypes.TRANSACTION:
        return BaseUpdateUrl.TRANSACTIONS
      case UpdateTypes.IUE:
        return BaseUpdateUrl.EMAILS
      case UpdateTypes.ACCOUNTING:
        return BaseUpdateUrl.ACCOUNTING
    }
  }

  const getSuffix = (suffix?: UpdateUrlSuffix) => {
    if (suffix) {
      return `/${suffix}`
    }

    return ''
  }

  const showUpdateFromInvestmentVehicle = ({
    updateId,
    updateType,
    investmentVehicle,
    investorId,
    routeState = {},
    replace,
    suffix,
  }: ShowUpdateFromInvestmentVehicleParams) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push
    const urlSuffix = getSuffix(suffix)

    // Investor Management --> Investors --> Investor Profile --> Click on vehicle on side info
    // --> Click on update
    const isComingFromInvestorProfile = !!investorId

    const urlToRedirect = isComingFromInvestorProfile
      ? INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES.replace(
          ':investorId',
          investorId
        ).replace(':investmentVehicleId', investmentVehicle.id)
      : VEHICLES_ALL_UPDATES.replace(
          ':investmentVehicleId',
          investmentVehicle.id
        )

    historyFunc(`${urlToRedirect}/${updateBaseUrl}/${updateId}${urlSuffix}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
      investmentVehicle,
    })
  }

  const showLPUpdateFromInvestmentVehicle = ({
    updateId,
    updateType,
    investmentVehicle,
    routeState = {},
    replace,
  }: ShowUpdateFromInvestmentVehicleParams) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push

    historyFunc(
      `/investment-vehicles/${investmentVehicle.id}/updates/${updateBaseUrl}/${updateId}`,
      {
        ...BASE_ROUTE_STATE,
        ...routeState,
        investmentVehicle,
      }
    )
  }

  const showUpdateFromInvestor = ({
    updateId,
    updateType,
    investor,
    routeState = {},
    replace,
    suffix,
  }: ShowUpdateFromInvestorParams) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push
    const urlSuffix = getSuffix(suffix)

    historyFunc(
      `/investor-management/investors/${investor.id}/profile/all-updates/${updateBaseUrl}/${updateId}${urlSuffix}`,
      {
        ...BASE_ROUTE_STATE,
        ...routeState,
        investor,
      }
    )
  }

  const showUpdateFromCompanyUpdates = ({
    updateId,
    updateType,
    routeState = {},
    replace,
    suffix,
    company,
    portfolio,
    isFromUpdates,
  }: ShowUpdateParamsFromUpdates) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push
    const urlSuffix = getSuffix(suffix)

    if (suffix === UpdateUrlSuffix.CREATE) {
      return historyFunc(`/updates/${updateBaseUrl}${urlSuffix}/${updateId}`, {
        ...BASE_ROUTE_STATE,
        ...routeState,
        company,
        portfolio,
        isFromUpdates,
      })
    }

    historyFunc(`/updates/${updateBaseUrl}/${updateId}${urlSuffix}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
      company,
      portfolio,
      isFromUpdates,
    })
  }

  const showUpdateFromPortfolio = ({
    updateId,
    updateType,
    portfolio,
    portfolioId,
    company,
    routeState = {},
    replace,
    suffix,
    fundInvestor,
    showNavigation = true,
  }: ShowUpdateFromPortfolioParams) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push
    const urlSuffix = getSuffix(suffix)

    historyFunc(
      `/portfolios/${
        portfolio?.id ?? portfolioId
      }/${updateBaseUrl}/${updateId}${urlSuffix}`,
      {
        ...BASE_ROUTE_STATE,
        ...routeState,
        portfolio,
        groupId,
        company,
        fundInvestor,
        showNavigation,
      }
    )
  }

  const showUpdateFromDashboard = ({
    updateId,
    updateType,
    company,
    routeState = {},
    replace,
  }: ShowUpdateFromDashboardParams) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push

    historyFunc(`/updates/${updateBaseUrl}/${updateId}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
      company,
    })
  }

  const showUpdateFromInvestorGroup = ({
    updateId,
    updateType,
    company,
    routeState = {},
    replace,
  }: ShowUpdateFromInvestorGroup) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push

    historyFunc(
      `/fund-holdings/${company.id}/updates/${updateBaseUrl}/${updateId}`,
      {
        ...BASE_ROUTE_STATE,
        ...routeState,
        company,
      }
    )
  }

  const showUpdateFromInvestorProfile = ({
    updateId,
    updateType,
    routeState = {},
    replace,
  }: ShowUpdateParams) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push

    historyFunc(`/profile/${updateBaseUrl}/${updateId}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
    })
  }

  const showUpdateFromInvestorGroupFund = ({
    updateId,
    updateType,
    portfolio,
    company,
    routeState = {},
    replace,
    portfolioId,
  }: ShowUpdateFromPortfolioParams) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push

    historyFunc(
      `/funds/${portfolio?.id || portfolioId}/${updateBaseUrl}/${updateId}`,
      {
        ...BASE_ROUTE_STATE,
        ...routeState,
        portfolio,
        company,
      }
    )
  }

  const showUpdateFromCompanyProfile = ({
    updateId,
    updateType,
    company,
    routeState = {},
    replace,
    suffix,
    isFromUpdates,
    showNavigation = true,
  }: ShowUpdateFromCompanyProfileParams) => {
    let baseUrl = ''
    if (isFounder) {
      baseUrl = '/updates'
    } else {
      baseUrl = getHoldingUrl(company, currentGroupType, true)
    }

    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push
    const urlSuffix = getSuffix(suffix)

    historyFunc(`${baseUrl}/${updateBaseUrl}/${updateId}${urlSuffix}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
      company,
      isFromUpdates,
      showNavigation,
    })
  }

  const showUpdateFromFundHoldingsCompanyProfile = ({
    updateId,
    updateType,
    company,
    routeState = {},
    replace,
    suffix,
  }: ShowUpdateFromCompanyProfileParams) => {
    const baseUrl = getHoldingUrl(company, currentGroupType, true)
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push
    const urlSuffix = getSuffix(suffix)

    historyFunc(
      `${baseUrl}/all-updates/${updateBaseUrl}/${updateId}${urlSuffix}`,
      {
        ...BASE_ROUTE_STATE,
        ...routeState,
        company,
      }
    )
  }

  const showTransactionFromFundHoldingsCompanyProfile = ({
    updateId,
    fundId,
    routeState = {},
    replace,
  }: ShowTransactionFromCompanyProfileParams) => {
    const baseUrl = `/fund-holdings/funds/${fundId}`
    const updateBaseUrl = BaseUpdateUrl.TRANSACTIONS
    const historyFunc = replace ? history.replace : history.push

    historyFunc(`${baseUrl}/${updateBaseUrl}/${updateId}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
    })
  }

  const showUpdateFromEmails = ({
    updateId,
    routeState = {},
    replace,
    isDraft = false,
    edit = false,
  }: ShowUpdateFromEmails) => {
    const historyFunc = replace ? history.replace : history.push

    let endUrl = ''
    if (isDraft) {
      endUrl += '/draft'
    } else if (edit) {
      endUrl += '/edit'
    }

    historyFunc(`/emails/${updateId}${endUrl}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
      customHeader: intl.formatMessage({ id: 'general.emails' }),
      redirectUrl: '/emails',
    })
  }

  const showUpdateFromGroupProfile = ({
    updateId,
    updateType,
    routeState = {},
    company,
    replace,
    suffix,
  }: ShowUpdateFromGroupProfile) => {
    const updateBaseUrl = getUpdateBaseUrl(updateType)
    const historyFunc = replace ? history.replace : history.push
    const urlSuffix = getSuffix(suffix)
    historyFunc(`/group-profile/${updateBaseUrl}/${updateId}${urlSuffix}`, {
      ...BASE_ROUTE_STATE,
      ...routeState,
      customHeader: intl.formatMessage({ id: 'sidebar.groupProfile' }),
      redirectUrl: '/group-profile',
      company,
    })
  }

  return {
    fromInvestorGroup: showUpdateFromInvestorGroup,
    fromInvestorGroupFund: showUpdateFromInvestorGroupFund,
    fromInvestorGroupInvestmentVehicle: showLPUpdateFromInvestmentVehicle,
    fromInvestmentVehicle: showUpdateFromInvestmentVehicle,
    fromInvestor: showUpdateFromInvestor,
    fromInvestorProfile: showUpdateFromInvestorProfile,
    fromCompanyUpdates: showUpdateFromCompanyUpdates,
    fromPortfolio: showUpdateFromPortfolio,
    fromDashboard: showUpdateFromDashboard,
    fromCompanyProfile: showUpdateFromCompanyProfile,
    fromGroupProfile: showUpdateFromGroupProfile,
    fromFundHoldingsCompanyProfile: showUpdateFromFundHoldingsCompanyProfile,
    transactionFromFundHoldingsCompanyProfile:
      showTransactionFromFundHoldingsCompanyProfile,
    fromEmails: showUpdateFromEmails,
  }
}
