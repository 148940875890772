import type {
  QueryObserverOptions,
  UseQueryOptions,
} from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'

import Toast from 'components/Toast'
import UpdateService from 'api/UpdateService'
import CompanyService from 'api/CompanyService'
import { setCurrentCompany } from 'actions/companies'
import { setCurrentPortfolio } from 'actions/portfolios'
import { ScheduledUpdate } from 'utils/types/update'

import { companyKeys } from '../../queries/companies'
import { useAppDispatch } from '../reduxToolkit'

export const useCompanyQuery = (companyId: string, queryOptions = {}) => {
  const dispatch = useAppDispatch()

  return useQuery(
    companyKeys.byId(companyId),
    async () => {
      try {
        const company = await CompanyService.fetchCompany(companyId)
        dispatch(setCurrentCompany(company))
        dispatch(setCurrentPortfolio({}))
        return company
      } catch (error) {
        Toast.displayIntl('holdings.errors.errorWhenFetchingHolding', 'error')
        return undefined
      }
    },
    queryOptions
  )
}

type QueryOptions = Omit<
  UseQueryOptions<ScheduledUpdate[]> | QueryObserverOptions<ScheduledUpdate[]>,
  'queryKey' | 'queryFn' | 'initialData'
>

export const useUpdatesByMonthAndCompany = (
  companyId: string,
  date: Date,
  queryOptions?: QueryOptions
) => {
  return useQuery(
    companyKeys.updatesByMonthAndCompany(companyId, dayjs(date).format('MMM')),
    async () => {
      try {
        const from = dayjs(date).startOf('month').toISOString()
        const to = dayjs(date).endOf('month').toISOString()
        const monthUpdates = await UpdateService.getScheduledUpdatesByMonth(
          from,
          to,
          companyId
        )
        return monthUpdates
      } catch (error) {
        Toast.displayIntl('companyUpdates.errorFetchingUpdates', 'error')
        return []
      }
    },
    { ...queryOptions }
  )
}
