import React from 'react'

import { usePortfolioQuery } from 'utils/hooks/queries/usePortfolioQuery'
import {
  buildPortfolioTopicEntity,
  getPortfolioTopicEntityType,
} from 'utils/types/chatTopicEntity'

import { useQuerySimilarChannel } from 'containers/Chat/hooks/useQuerySimilarChannel'
import { ChannelType } from 'containers/Chat/types'
import { useChatMembers } from 'utils/hooks/useChatMembers'
import { PortfolioType } from 'utils/types/portfolios'
import CreateNewChatSuggestionBox from './CreateNewChatSuggestionBox'
import ExistentChatSuggestion from './ExistentChatSuggestion'

interface CreateNewPortfolioChatSuggestionProps {
  portfolioId: string
  portfolioType: PortfolioType
}

const CreateNewPortfolioChatSuggestion: React.FC<
  CreateNewPortfolioChatSuggestionProps
> = ({ portfolioId, portfolioType }) => {
  const { data: portfolio } = usePortfolioQuery(portfolioId, portfolioType, {
    staleTime: 500,
  })

  const { members } = useChatMembers()
  const { data: similarChannel, isLoading: isLoadingSimilarChannel } =
    useQuerySimilarChannel({
      entityId: portfolioId,
      entityType: getPortfolioTopicEntityType(portfolioType),
      channelType: ChannelType.PORTFOLIO,
      memberIds: members.map((member) => member.user_id!),
    })

  if (isLoadingSimilarChannel) {
    return null
  }

  if (similarChannel) {
    return (
      <ExistentChatSuggestion
        topicEntity={
          portfolio ? buildPortfolioTopicEntity(portfolio) : undefined
        }
        existentChannel={similarChannel!}
        textIntlKey="chat.alreadyExistentChatPortfolio"
      />
    )
  }

  return (
    <CreateNewChatSuggestionBox
      topicEntity={portfolio ? buildPortfolioTopicEntity(portfolio) : undefined}
      textIntlKey="chat.startNewDiscussionPortfolio"
    />
  )
}

export default CreateNewPortfolioChatSuggestion
