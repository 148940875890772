import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { isEnterKeyCode } from 'utils/functions/keyboardEvents'

import styles from './Accordion.module.scss'

const animation = {
  maxHeight: 'fit-content',
  opacity: 1,
  transition: { duration: 0.3 },
}

const exitAnimation = {
  opacity: 0,
  transition: { duration: 0.3 },
}

const Accordion = ({ children, onAccordionChange, className, ...rest }) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)

  const displayChildren = React.Children.map(
    children,
    (child) =>
      child &&
      React.cloneElement(child, {
        isAccordionExpanded,
        setIsAccordionExpanded,
        onAccordionChange,
        ...rest,
      })
  )

  return (
    <div className={classNames(styles.container, className)}>
      {displayChildren}
    </div>
  )
}

Accordion.Header = ({
  children,
  isAccordionExpanded,
  setIsAccordionExpanded,
  onAccordionChange,
  className,
  ...rest
}) => {
  const expandCollapseAccordion = () => {
    setIsAccordionExpanded(!isAccordionExpanded)
    onAccordionChange(!isAccordionExpanded)
  }

  return (
    <div
      onKeyDown={(event) => {
        if (isEnterKeyCode(event)) expandCollapseAccordion()
      }}
      tabIndex="0"
      role="button"
      className={`${styles.header} ${className} ${
        isAccordionExpanded ? styles.removeBottomCornerRadius : ''
      }`}
      onClick={expandCollapseAccordion}
      {...rest}
    >
      {children}
    </div>
  )
}

Accordion.Body = ({ children, isAccordionExpanded, backgroundColor }) =>
  isAccordionExpanded && (
    <motion.div
      initial={exitAnimation}
      animate={animation}
      exit={exitAnimation}
      className={styles.body}
      style={{ backgroundColor }}
    >
      {children}
    </motion.div>
  )

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  onAccordionChange: PropTypes.func,
  className: PropTypes.string,
}

Accordion.Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isAccordionExpanded: PropTypes.bool,
  onAccordionChange: PropTypes.func,
  setIsAccordionExpanded: PropTypes.func,
}

Accordion.Body.propTypes = {
  children: PropTypes.node,
  isAccordionExpanded: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

Accordion.defaultProps = {
  onAccordionChange: () => {},
}

Accordion.Header.defaultProps = {
  className: '',
  isAccordionExpanded: false,
  onAccordionChange: () => {},
  setIsAccordionExpanded: () => {},
}

Accordion.Body.defaultProps = {
  children: null,
  isAccordionExpanded: false,
  backgroundColor: '',
}

export default Accordion
