import styled from 'styled-components'
import CModal from 'components/Modal'

export const Modal = styled(CModal)`
  width: 91vw;
  border-radius: 0.8rem;
  overflow: hidden;
`

export const SignupWizardContainer = styled.div`
  background: white;
  padding: 0 2.4rem;
  overflow-y: auto;
  height: 100vh;
`
