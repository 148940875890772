import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div<{ sticky: boolean }>`
  font-size: 1.4rem;
  color: white;
  display: flex;
  align-items: center;
  padding: 1.4rem 2.7rem;
  gap: 1.2rem;
  cursor: pointer;
  position: sticky;
  bottom: 0rem;
  background-color: ${color('darkBlue')};

  &:hover {
    background-color: #1b2341;
  }

  ${({ sticky }) =>
    sticky &&
    css`
      box-shadow: 0 -0.1rem 0 0 ${color('gray')};
      padding-bottom: 1.8rem;
    `}
`

export const PlusContainer = styled.div`
  height: 3.4rem;
  width: 3.4rem;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`
