import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import useEffectSkipFirstRender from 'utils/hooks/useEffectSkipFirstRender'
import { SwitchContainer, SwitchOption } from './Switch.styles'

const Switch = ({
  options,
  valueId,
  onChange,
  fontSize,
  disableEffect,
  width,
  selectedColor,
  unSelectedColor,
}) => {
  const onSelectOption = (event, option) => {
    event.preventDefault()
    event.stopPropagation()
    onChange(option.id)
  }

  useEffect(() => {
    if (valueId && !disableEffect) {
      const selectedOption =
        options.find((option) => option.id === valueId) || options[0]
      onChange(selectedOption.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueId, disableEffect])

  return (
    <SwitchContainer width={width}>
      {options.map((option) => (
        <SwitchOption
          key={option.id}
          data-testid={`switch_option_${option.id}`}
          onClick={(event) => onSelectOption(event, option)}
          selected={option.id === valueId}
          fontSize={fontSize}
          unSelectedColor={unSelectedColor}
          selectedColor={selectedColor}
        >
          {option.label}
        </SwitchOption>
      ))}
    </SwitchContainer>
  )
}

Switch.propTypes = {
  options: PropTypes.array.isRequired,
  valueId: PropTypes.string,
  width: PropTypes.string,
  selectedColor: PropTypes.string,
  unSelectedColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  fontSize: PropTypes.string,
  disableEffect: PropTypes.bool,
}

Switch.defaultProps = {
  valueId: null,
  disableEffect: false,
}
export default Switch
