import { FormattedMessage, useIntl } from 'react-intl'

import Heading from 'ui/Heading'
import Input from 'ui/Input'
import Separator from 'ui/Separator'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'
import { SetupAccount as SetupAccountFormValues } from 'utils/types/signUp'

import { ContinueButton, HeaderContainer, Legend } from '../../../Signup.styles'
import { SetupAccountContainer } from '../GetStarted.styles'
import { ButtonsContainer, Column, Form, Row } from './SetupAccount.styles'
import useSetupAccount from './useSetupAccount'

interface SetupAccountProps {
  onClickContinue: (data: SetupAccountFormValues) => void
  userAddedFromGroupManagement?: boolean
  isUpdatingUserInfo?: boolean
}

const SetupAccount = ({
  onClickContinue,
  userAddedFromGroupManagement = false,
  isUpdatingUserInfo = false,
}: SetupAccountProps) => {
  const { formik } = useSetupAccount({
    onClickContinue,
  })
  const intl = useIntl()

  return (
    <SetupAccountContainer withTopPadding={userAddedFromGroupManagement}>
      <HeaderContainer>
        <Heading level="h1">
          <FormattedMessage id="signup.setup.title" />
        </Heading>
        <Legend>
          <FormattedMessage id="signup.setup.subtitle" />
        </Legend>
      </HeaderContainer>

      <Form onSubmit={formik.handleSubmit} autoComplete="off">
        <Row>
          <Column>
            <Input
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              id="firstName"
              onChange={formik.handleChange}
              name="firstName"
              label={intl.formatMessage({ id: 'investorSignup.firstName' })}
              placeholder={intl.formatMessage({
                id: 'investorSignup.addYourFirstName',
              })}
              error={buildFormError(
                formik.errors.firstName,
                ErrorType.ERROR,
                formik.touched.firstName
              )}
            />
          </Column>
          <Column>
            <Input
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              id="lastName"
              onChange={formik.handleChange}
              name="lastName"
              label={intl.formatMessage({ id: 'investorSignup.lastName' })}
              placeholder={intl.formatMessage({
                id: 'investorSignup.addYourLastName',
              })}
              error={buildFormError(
                formik.errors.lastName,
                ErrorType.ERROR,
                formik.touched.lastName
              )}
            />
          </Column>
        </Row>

        <Separator />

        <ButtonsContainer>
          <ContinueButton
            type="submit"
            primary
            disabled={!formik.isValid || isUpdatingUserInfo}
            isLoading={isUpdatingUserInfo}
          >
            <FormattedMessage
              id={
                userAddedFromGroupManagement
                  ? 'general.continue'
                  : 'general.saveAndGoNext'
              }
            />
          </ContinueButton>
        </ButtonsContainer>
      </Form>
    </SetupAccountContainer>
  )
}

export default SetupAccount
