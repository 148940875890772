import { Elements } from '@stripe/react-stripe-js'
import type { StripeElementsOptions } from '@stripe/stripe-js'
import PaymentForm from 'components/PaymentForm'
import { useSignupContext } from 'containers/Signup/context/SignupContext'
import { FormattedMessage } from 'react-intl'
import Heading from 'ui/Heading'
import { lgStripeAppearance } from 'utils/constants/stripe'
import CWLoader from 'components/CWLoader'
import Separator from 'ui/Separator'
import { PaymentContainer } from '../GetStarted.styles'
import { LineSeparator } from './Payment.styles'
import { stripePromise } from './usePayment'

import SubscriptionBill from '../SubscriptionBill/SubscriptionBill'

interface Props {
  onPaymentSuccess: (paymentIntentId: string) => void
  clientSecret: string
  paymentError?: string
}

const Payment = ({ onPaymentSuccess, clientSecret, paymentError }: Props) => {
  const options: StripeElementsOptions = {
    clientSecret,
    appearance: lgStripeAppearance,
  }
  const {
    investorSignup: { isTrial, isLoadingChangeBillingPeriod, billingAccount },
  } = useSignupContext()

  return (
    <PaymentContainer>
      <Heading level="h1">
        <FormattedMessage id="signup.payment.title" />
      </Heading>

      {isLoadingChangeBillingPeriod ? (
        <>
          <Separator />
          <CWLoader />
        </>
      ) : (
        <>
          <SubscriptionBill />
          <LineSeparator />
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              isTrial={!!isTrial}
              billingPeriod={billingAccount?.subscription.billingPeriod!}
              onPaymentSuccess={onPaymentSuccess}
              paymentError={paymentError}
            />
          </Elements>
        </>
      )}
    </PaymentContainer>
  )
}

export default Payment
