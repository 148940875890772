import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const NoChatsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  padding-bottom: 3rem;
`

export const NoChatsText = styled.div`
  font-size: 18px;
  color: ${color('lightGray')};
`
