import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const SkeletonPortfoliosNavListWrapper = styled.div`
  background-color: ${color('white')};
  border-bottom: ${color('lightGray', 0.2)} 0.1rem solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 1.2rem 1.2rem 2.4rem;
  position: sticky;
  top: 0;
  z-index: 3;
  flex-shrink: 0;
  gap: 1.6rem;
  overflow: hidden;
`
