/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedNumber } from 'react-intl'

import {
  ReportColumn,
  ReportSectionTitle,
  ReportCell,
  ReportSummaryValue,
  ReportItemsContainer,
} from './ShowReport.styles'

const ReportSectionMainSummary = ({ sectionRow, periods }) => {
  const [titleCell, ...valueCells] = sectionRow.cells
  return (
    <>
      <ReportColumn labelColumn borderRight>
        <ReportSectionTitle isMainSection>{titleCell.value}</ReportSectionTitle>
      </ReportColumn>

      {new Array(periods).fill().map((value, index) => {
        return (
          <ReportColumn key={index} borderRight={index !== periods - 1}>
            <ReportItemsContainer>
              <ReportCell bold>
                <ReportSummaryValue align="end">
                  <FormattedNumber
                    value={valueCells?.[index]?.value || 0}
                    maximumFractionDigits={2}
                  />
                </ReportSummaryValue>
              </ReportCell>
            </ReportItemsContainer>
          </ReportColumn>
        )
      })}
    </>
  )
}

ReportSectionMainSummary.propTypes = {
  sectionRow: PropTypes.object.isRequired,
  periods: PropTypes.number.isRequired,
}

export default ReportSectionMainSummary
