import { forwardRef } from 'react'
import { useInvestmentsFiltersContext } from 'containers/Investments/InvestmentContext'
import {
  ALL_INVESTMENT_OPTION_ID,
  ALL_FOLLOWED_HOLDINGS_OPTION_ID,
  InvestmentsNavigationOption,
} from 'containers/Investments/types'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { setInitialLinkRedirect } from 'reducers/breadcrumbSlice'

import NavigationItem from '../NavigationItem'
import AddPortfolioButton from '../AddPortfolioButton'

import * as Styles from './NavigationList.styles'

interface NavigationListProps {
  options: InvestmentsNavigationOption[]
  hasScroll: boolean
  hoveredPortfolioId: string
  isBig: boolean
  hasCreatePortfolio: boolean
  onScroll: () => void
  onItemClick: (option: InvestmentsNavigationOption) => void
  onMouseEnter: (id: string) => void
  onMouseLeave: () => void
  onAddButtonClick: () => void
}

const NavigationList = forwardRef<HTMLDivElement, NavigationListProps>(
  (
    {
      hasScroll,
      options,
      hoveredPortfolioId,
      isBig,
      hasCreatePortfolio = true,
      onScroll,
      onItemClick,
      onMouseEnter,
      onMouseLeave,
      onAddButtonClick,
    },
    navigationRef
  ) => {
    const dispatch = useAppDispatch()

    const { togglePinPortfolio, isMakingPinnedReq } =
      useInvestmentsFiltersContext()

    return (
      <Styles.NavigationList
        ref={navigationRef}
        hasScroll={hasScroll}
        onScroll={onScroll}
        isExpanded={isBig}
      >
        {options?.map((option) => (
          <NavigationItem
            key={option.id}
            option={option}
            colorizeHoverIcon={
              option.id === ALL_INVESTMENT_OPTION_ID ||
              option.id === ALL_FOLLOWED_HOLDINGS_OPTION_ID
            }
            isHovered={hoveredPortfolioId === option.id}
            isDisabled={isMakingPinnedReq}
            iconHasBackground={
              option.id !== ALL_INVESTMENT_OPTION_ID &&
              option.id !== ALL_FOLLOWED_HOLDINGS_OPTION_ID
            }
            onMouseEnter={() => onMouseEnter(option.id)}
            onMouseLeave={onMouseLeave}
            onClick={() => {
              onItemClick(option)
              dispatch(setInitialLinkRedirect(option.url))
            }}
            onTogglePin={() =>
              togglePinPortfolio({ option, value: !option.pinned })
            }
          />
        ))}

        {hasCreatePortfolio && (
          <AddPortfolioButton
            isBig={isBig}
            iconHasBackground={false}
            onClick={onAddButtonClick}
          />
        )}
      </Styles.NavigationList>
    )
  }
)

export default NavigationList
