import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { Channel, useChatContext } from 'stream-chat-react'
// TODO: remove lib styles and use custom styles
import 'stream-chat-react/dist/css/v2/index.css'
import { PanelGroup } from 'react-resizable-panels'

import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { useEventListener } from 'utils/hooks/useEventListener'
import { AssistanceCardType } from 'utils/types/onboardingModal'
import { AssistanceCardLarge } from 'components/OnboardingModal/components/Assistance'
import { Helmet } from 'components/Helmet'

import ChatSidebar from './components/ChatSidebar'
import TopicSidebar from './components/TopicSidebar'
import ThreadHeader from './components/ThreadHeader/ThreadHeader'
import Message from './components/Message'
import MessageInput from './components/MessageInput'
import TypingIndicator from './components/TypingIndicator'
import EmptyComponent from './components/EmptyComponent'
import DateSeparator from './components/DateSeparator'
import SystemMessage from './components/SystemMessage'
import SuggestionItem from './components/ChatSuggestions/SuggestionItem'
import EmptyPlaceholder from './components/ZeroStates/EmptyPlaceholder'
import EmptyStateIndicator from './components/ZeroStates/EmptyStateIndicator'
import ArchivedChannelsSidebar from './components/ArchivedChannelsSidebar/ArchivedChannelsSidebar'
import ChatMobile, { ChatMobileContextProvider } from './components/ChatMobile'
import * as Styles from './Chat.styles'
import ChatSearch from './components/ChatSearch'
import {
  EMPTY_ARCHIVE_LIST_EVENT,
  SHOW_ARCHIVED_SIDEBAR_EVENT,
} from './components/ArchivedChannelsSidebar/useArchivedChannelsSidebar'
import { NEW_CHAT_CREATED_EVENT } from './components/CreateChat/useCreateChat'
import { StreamChatType } from './types'
import { useArchiveChannels } from './hooks/useArchiveChannels'
import ChatErrorHandler from './components/ChatErrorHandler'
import { ChatSidebarContextProvider } from './hooks'
import { ChatSearchContextProvider } from './hooks/useChatSearch'
import ChatChannelContent from './ChatChannelContent'

const Chat: React.FC = () => {
  const intl = useIntl()
  const chatContainerRef = React.useRef<HTMLDivElement>(null)
  const { channel, setActiveChannel } = useChatContext<StreamChatType>()
  const { channelId } = useParams<{ channelId?: string }>()
  const { isChannelArchived } = useArchiveChannels()

  const isArchived = isChannelArchived(channelId || channel?.id)
  const [searchView, setSearchView] = useState(false)
  const [showArchiveSidebar, setShowArchiveSidebar] = useState(isArchived)
  const { matches: isMobile } = useMediaQuery(maxSize.mobile)

  useEffect(() => {
    if (isArchived) {
      setShowArchiveSidebar(true)
    }
  }, [isArchived])

  useEventListener(EMPTY_ARCHIVE_LIST_EVENT, () => {
    setShowArchiveSidebar(false)
  })

  useEventListener(NEW_CHAT_CREATED_EVENT, () => {
    setShowArchiveSidebar(false)
  })

  useEventListener(SHOW_ARCHIVED_SIDEBAR_EVENT, () => {
    setShowArchiveSidebar(true)
  })

  useEffect(() => {
    return () => {
      setActiveChannel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSearchView = useCallback(() => {
    setSearchView(true)
  }, [])

  const hideSearchView = useCallback(() => {
    setSearchView(false)
  }, [])

  const showArchiveSidebarFn = useCallback(() => {
    setShowArchiveSidebar(true)
  }, [])

  const hideArchiveSidebar = useCallback(() => {
    setShowArchiveSidebar(false)
  }, [])

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'metaTitles.chat' })}</title>
      </Helmet>
      <ChatSearchContextProvider>
        <ChatSidebarContextProvider
          showSearchView={showSearchView}
          hideSearchView={hideSearchView}
          showArchiveSidebar={showArchiveSidebarFn}
          hideArchiveSidebar={hideArchiveSidebar}
          isArchiveSidebarActive={showArchiveSidebar}
        >
          <ChatMobileContextProvider>
            {isMobile ? (
              <ChatMobile isSearchViewVisible={searchView} />
            ) : (
              <PanelGroup direction="horizontal">
                <Styles.Container ref={chatContainerRef}>
                  <ChatSidebar chatContainerRef={chatContainerRef} />

                  <Styles.ChatLayout>
                    {!searchView && <TopicSidebar />}
                    {showArchiveSidebar && <ArchivedChannelsSidebar />}
                    <Styles.VisibleContainer visible={searchView}>
                      <ChatSearch hideSearchView={hideSearchView} />
                    </Styles.VisibleContainer>

                    <Styles.VisibleContainer visible={!searchView}>
                      <Channel
                        Message={Message}
                        Input={MessageInput}
                        MessageListNotifications={EmptyComponent}
                        TypingIndicator={TypingIndicator}
                        ThreadHeader={ThreadHeader}
                        ThreadStart={EmptyComponent}
                        LoadingIndicator={EmptyComponent}
                        EmptyPlaceholder={<EmptyPlaceholder />}
                        EmptyStateIndicator={EmptyStateIndicator}
                        DateSeparator={DateSeparator}
                        MessageSystem={SystemMessage}
                        AutocompleteSuggestionItem={SuggestionItem}
                      >
                        <ChatChannelContent />
                      </Channel>
                    </Styles.VisibleContainer>
                  </Styles.ChatLayout>

                  <AssistanceCardLarge type={AssistanceCardType.CHAT} />
                </Styles.Container>
              </PanelGroup>
            )}
          </ChatMobileContextProvider>
        </ChatSidebarContextProvider>
      </ChatSearchContextProvider>
    </>
  )
}

export default () => (
  <ChatErrorHandler centerVertically>
    <Chat />
  </ChatErrorHandler>
)
