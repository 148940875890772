import { useId } from 'react'
import sortBy from 'lodash/sortBy'
import differenceBy from 'lodash/differenceBy'
import { Groups } from './GroupTooltips'

interface Props {
  groups: Groups[]
  unsortedGroups: Groups[]
  direction?: string
  maxGroupsToDisplay?: number
}

const useGroupTooltips = ({
  groups,
  direction,
  unsortedGroups,
  maxGroupsToDisplay,
}: Props) => {
  const id = useId()

  const allGroups =
    direction === 'left'
      ? sortBy(groups, ['name'])
      : sortBy(groups, ['name']).reverse()

  const tooltipGroups = sortBy(groups, ['name'])

  let groupIndexOffset = 0
  let groupsToDisplay = [...unsortedGroups, ...allGroups]
  let hiddenGroups: Groups[] = []

  if (
    maxGroupsToDisplay !== null &&
    groupsToDisplay.length > Number(maxGroupsToDisplay) + 1
  ) {
    if (direction === 'right') {
      groupIndexOffset = 1
      groupsToDisplay = groupsToDisplay
        .reverse()
        .splice(0, maxGroupsToDisplay)
        .reverse()
      hiddenGroups = differenceBy(
        allGroups,
        groupsToDisplay,
        (group) => group.id
      ).reverse()
    } else {
      groupIndexOffset = 0
      groupsToDisplay = groupsToDisplay.slice(0, maxGroupsToDisplay)
      hiddenGroups = differenceBy(
        allGroups,
        groupsToDisplay,
        (group) => group.id
      )
    }
  }

  const showCountGroupTooltips = hiddenGroups.length > 0

  const tooltipId = `${tooltipGroups.map((gr) => gr.id).join('_')}_${id}`

  return {
    allGroups,
    groupIndexOffset,
    groupsToDisplay,
    hiddenGroups,
    showCountGroupTooltips,
    tooltipId,
    tooltipGroups,
  }
}

export default useGroupTooltips
