import React from 'react'
import Avatar from 'components/Avatar/Avatar'
import IconButton from 'ui/IconButton'
import { getInitials } from 'utils/functions/user'
import {
  RowContainer,
  AvatarContainer,
  NameContainer,
  RemoveContainer,
} from '../CreateChatModal.styles'

type Props = {
  onRemoveMember?: () => void
  image?: string
  name: string
}

const ChatMemberRow: React.FC<Props> = ({ image, name, onRemoveMember }) => {
  return (
    <RowContainer>
      <AvatarContainer>
        <Avatar
          image={image}
          avatarStyle="avatarCircleXS"
          initials={getInitials(name)}
        />
      </AvatarContainer>
      <NameContainer>{name}</NameContainer>
      {!!onRemoveMember && (
        <RemoveContainer>
          <IconButton icon="times" border={false} onClick={onRemoveMember} />
        </RemoveContainer>
      )}
    </RowContainer>
  )
}

export default ChatMemberRow
