import styled from 'styled-components'

export const RecipientsSummaryContainer = styled.ul`
  display: grid;
  gap: 1.4rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  list-style: none;
  margin-bottom: 3rem;
`

export const RecipientSummaryItem = styled.li`
  align-items: center;
  border-radius: 0.6rem;
  border: solid 0.1rem #e9e9e9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.8rem 0;
`

export const RecipientsSummaryTitle = styled.div`
  display: flex;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.8rem;

  > svg {
    margin-right: 0.5rem;
  }
`

export const RecipientsSummarySubtitle = styled.p`
  color: #787e8c;
  font-size: 1.2rem;
`
