import dayjs from 'dayjs'
import { useField } from 'formik'
import { InputLabel, Label, ReadOnlyWrapper } from './ReadOnlyField.styles'

interface ReadOnlyFieldProps {
  name: string
  label: string
  isDate?: boolean
  value?: string
  marginBottom?: string
  icon?: React.ReactNode
  marginLeft?: string
}

const ReadOnlyField = ({
  name,
  label,
  isDate,
  value,
  icon,
  marginBottom,
  marginLeft,
}: ReadOnlyFieldProps) => {
  const [field] = useField(name)
  const valueField = isDate
    ? dayjs(field.value).format('DD MMM YYYY')
    : field.value

  return (
    <ReadOnlyWrapper>
      <InputLabel marginBottom={marginBottom} marginLeft={marginLeft}>
        {label}
      </InputLabel>
      <Label marginLeft={marginLeft}>
        {icon && icon} {value || valueField}
      </Label>
    </ReadOnlyWrapper>
  )
}

export default ReadOnlyField
