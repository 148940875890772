import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useFilePicker } from 'utils/hooks/files/useFilePicker'
import { ReactFCWithChildren } from 'utils/types/common'
import { DropContainer, DropFileMessage } from './FileDropper.styles'

export interface FileDropperProps {
  onDropFiles: ({
    acceptedFiles,
    rejectedFiles,
  }: {
    acceptedFiles: File[]
    rejectedFiles: File[]
  }) => void
  className?: string
  defaultZIndex?: number
}

const FileDropper: ReactFCWithChildren<FileDropperProps> = ({
  onDropFiles,
  className,
  children,
  defaultZIndex,
}) => {
  const [showDropper, setShowDropper] = useState(false)

  const {
    dropFilesContainerRef,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onSelectFiles,
    isDraggingFile,
  } = useFilePicker({
    onFilesSelected: onDropFiles,
    onStartDraggingFile: () => {
      setShowDropper(true)
    },
    onStopDraggingFile: () => {
      setTimeout(() => {
        setShowDropper(false)
      })
    },
  })

  return (
    <DropContainer
      ref={dropFilesContainerRef}
      onDrop={onSelectFiles}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      isDraggingFile={isDraggingFile}
      showDropper={showDropper}
      className={className}
      defaultZIndex={defaultZIndex}
    >
      {children || (
        <DropFileMessage isDraggingFile={isDraggingFile}>
          <FormattedMessage id="files.dropFiles" />
        </DropFileMessage>
      )}
    </DropContainer>
  )
}

export default FileDropper
