import styled from 'styled-components'

import PDFPreviewerToStyle from 'components/PDFPreviewer'
import { maxSize } from 'utils/constants/breakpoint'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  z-index: 2;

  div[class*='react-pdf__message'] {
    height: 100%;
  }

  div[class*='react-pdf__Document'] {
    max-width: 80vw;
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${maxSize.sm} {
      min-width: initial;
      max-width: 37rem;
    }
  }

  div[class='react-pdf__Page'] {
    width: fit-content;
    max-width: 100%;
    overflow: auto;

    div[class*='react-pdf__Page__annotations'] {
      display: none;
    }

    @media ${maxSize.md} {
      margin: 4rem 0;
    }

    @media ${maxSize.xsm} {
      border-left-width: 1rem;
      border-right-width: 1rem;
      max-height: 55rem;
    }
  }

  @media ${maxSize.md} {
    width: calc(100% - 2 * 4rem);
  }
`

export const PDFPreviewer = styled(PDFPreviewerToStyle)`
  height: 100%;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: text;
`
