export default {
  newPortfolio: 'New Portfolio',
  allInvestments: 'All Investments',
  allFollowedHoldings: 'All Followed Holdings',
  aggregateInvestmentSummary: 'Aggregate Investment Summary',
  hide0CarryingValuesHoldings: 'Hide $0 Carrying Value Holdings',
  aggregateInvestmentHoldingTable: {
    title: 'Aggregate Investment Holdings',
    holdings: 'Holdings',
    firstInvestmentDate: 'First Investment Date',
    totalInvestments: 'Total Investments',
    unfundedCommitment: 'Unfunded Commitment',
    amountInvested: 'Amount Invested',
    fairMarketValue: 'Carrying Value',
    lastCarryingValueUpdate: 'Last Carrying Value Update',
    amountDistributed: 'Amount Distributed',
    totalValue: 'Total Value',
    multipleOnInvestedCapital: 'Multiple on Invested Capital',
    irr: 'IRR',
    percentageOfInvested: 'Percentage of Invested',
    exportTransactions: 'Export Transactions',
    exportHoldingsFMVs: 'Export Holdings Carrying Values',
  },
  errors: {
    portfolioSummary: 'An error occurred when fetching the portfolio summary',
    updatesError: 'An error occurred when fetching the updates',
  },
  filters: {
    filterInvestments: 'Filter Investments',
    allInvestments: 'All Investments',
    clearAll: 'Clear All',
    appliedFilters: 'Applied Filters',
    sectorsAndIndustries: 'Sectors & Industries',
    searchForSectorsAndIndustries: 'Search for sectors and industries',
    portfolios: 'Portfolios',
    holdings: 'Holdings',
    portfolioHoldings: 'Portfolio Holdings',
    search: 'Search...',
    groupTags: 'Group Tags',
    saveAsNewPortfolio: 'Save as New Portfolio',
    saveTheSelectedFilterCriteria: 'Save the selected filter criteria',
    addFilterNewName: 'Add Filter New Name',
    saveFilter: 'Save Filter',
    renameFilter: 'Rename Filter',
    editFilter: 'Edit Filter',
    deleteFilter: 'Delete Filter',
    saveAsNew: 'Save as New',
    replace: 'Replace',
    addFilterName: 'Add Filter Name',
    filterCreatedSuccessfully: 'Filter created successfully',
    filterCreatedError: 'An error occurred when creating the filter',
    filterEditedSuccessfully: 'Filter edited successfully',
    filterEditedError: 'An error occurred when editing the filter',
    filterDeletedSuccessfully: 'Filter deleted successfully',
    filterDeletedError: 'An error occurred when deleting the filter',
    filterNameAlreadyExists: 'You already have one “{filterName}”.',
    areYouSureYouWantToReplaceIt:
      'Are you sure you want to replace it or save it as a new filter?',
    areYouSureYouWantToDeleteIt: 'Are you sure you want to delete this filter?',
    selectedHolding: 'Selected Holding ({holdingCount})',
    includeTransactions: 'Include Transactions',
    includeTransactionsTooltip:
      'Turning off this setting will remove all transactions and empty the portfolio, leaving only the holdings list. Please note that this action cannot be undone, and you will need to manually add transactions again to restore the portfolio data.',
    portfolioTypes: {
      pipelines: 'Pipelines',
      investPortfolios: 'Invest Portfolios',
      funds: 'Funds',
      deals: 'Deals',
    },
    filterTypes: {
      savedFilters: 'Saved Filters',
      unsavedFilter: 'Unsaved filter',
    },
  },
  allFollowingHoldings: {
    removeCompanyTitle: 'Remove holding from portfolio',
    removeCompanyWarning:
      'Are you sure you want to remove {holdingName} from this pipeline?',
    confirmRemove: 'Remove',
    cancelRemove: 'Cancel',
    removeHoldingSuccess: '{holdingName} was removed from {portfolioName}',
    removeHoldingError:
      'An error occurred when trying to remove the holding, try again later.',
  },
}
