import React from 'react'
import { ReactChildren } from 'utils/types/common'
import { StyledScrollableContainer } from '../Table.styles'
import { useTableContext } from '../TableContextProvider'

interface ScrollableContainerProps {
  children?: ReactChildren
  isVisible?: boolean
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({
  children,
  isVisible,
}) => {
  const { tableLayoutHelpers } = useTableContext()
  const {
    embeddedTable,
    scrollableContainerMarginBottom,
    scrollableContainerPaddingBottom,
  } = useTableContext()

  return (
    <StyledScrollableContainer
      id={`scrollable-table-container${embeddedTable ? '-embedded' : ''}`}
      ref={
        tableLayoutHelpers.listContainerRef as React.RefObject<HTMLDivElement>
      }
      scrollEnabled
      embeddedTable={embeddedTable}
      isVisible={isVisible}
      scrollableContainerMarginBottom={scrollableContainerMarginBottom}
      scrollableContainerPaddingBottom={scrollableContainerPaddingBottom}
    >
      {children}
    </StyledScrollableContainer>
  )
}

export default ScrollableContainer
