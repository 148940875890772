import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'

export const DiffContainer = styled.div<{
  showInsertsOnly?: boolean
  showDeletesOnly?: boolean
}>`
  flex: 1;

  ins {
    background: ${color('diffGreen', 0.25)};
    text-decoration: auto;
  }

  del {
    background: ${color('red', 0.2)};
    text-decoration: auto;
  }

  ${({ showInsertsOnly }) =>
    showInsertsOnly &&
    css`
      del {
        display: none;
      }
    `}

  ${({ showDeletesOnly }) =>
    showDeletesOnly &&
    css`
      ins {
        display: none;
      }
    `}
`

export const Separator = styled.div`
  width: 1px;
  background: ${color('borderGray')};
  margin: 0 2rem;
`

export const EmptyMessage = styled.div`
  font-style: italic;
  color: ${color('darkGray', 0.5)};
  margin-top: 1.4rem;
`

export const TrashIcon = styled(FontAwesomeIcon)`
  margin-left: 0.5rem;
`

export const Date = styled.div`
  color: ${color('lightGray')};
  font-size: 1.2rem;
`
