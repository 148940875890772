import { useEffect } from 'react'
import { useField, useFormikContext } from 'components/Form/hooks'
import { EditFounderMetricFormType } from './useEditFounderMetric'

interface Props {
  currentGroups: any
}

const useEditFounderMetricForm = ({ currentGroups }: Props) => {
  const { setFieldValue, handleBlur, handleChange, errors } =
    useFormikContext<EditFounderMetricFormType>()
  const [nameField] = useField<string>('name')
  const [frequencyField] = useField<string>('frequency')
  const [shareGroupsField] = useField<any[]>('sharedGroups')

  useEffect(() => {
    if (!currentGroups) return

    setFieldValue('sharedGroups', currentGroups)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroups])

  return {
    nameField,
    frequencyField,
    shareGroupsField,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
  }
}

export default useEditFounderMetricForm
