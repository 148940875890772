import { getCurrentPortfolio } from 'selectors/portfolio'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'
import { useCapitalStatementForInvestorQuery } from 'utils/hooks/investorManagement/useFundPortfolioInvestorQuery'
import Card from 'components/Card'
import FadeIn from 'components/FadeIn'
import GridSkeleton from 'components/Skeletons/components/GridSkeleton'
import { ALL_FUNDS } from '../../constants'
import CapitalStatementRow from './CapitalStatementRow'
import { useFetchCapitalStatement } from './useCapitalStatement'

interface CapitalStatementProps {
  investorsTotalUnfundedCommitment?: number
  totalCapitalCalled?: number
  totalCommittedCapital?: number
  totalDistributions?: number
  proRataHoldingValue?: number
  isFundPortfolioProfile?: boolean
}

const CapitalStatementContent: React.FC<CapitalStatementProps> = ({
  investorsTotalUnfundedCommitment,
  totalCapitalCalled,
  totalCommittedCapital,
  totalDistributions,
  proRataHoldingValue,
  isFundPortfolioProfile,
}) => {
  const intl = useIntl()

  const hasSharedFields = [
    investorsTotalUnfundedCommitment,
    totalCapitalCalled,
    totalCommittedCapital,
    totalDistributions,
    proRataHoldingValue,
  ].some((field) => field !== undefined && field !== null)

  if (!hasSharedFields) return null

  return (
    <FadeIn>
      <Card isExpandable padding="1.4rem 2.4rem">
        <Card.Header>
          {intl.formatMessage({ id: 'fundProfile.capitalStatement' })}
        </Card.Header>
        <Card.Body>
          <CapitalStatementRow
            isFundPortfolioProfile={isFundPortfolioProfile}
            data={{
              investorsTotalUnfundedCommitment,
              totalCapitalCalled,
              totalCommittedCapital,
              totalDistributions,
              proRataHoldingValue,
            }}
          />
        </Card.Body>
      </Card>
    </FadeIn>
  )
}

const CSAllFunds = () => {
  const { data, isLoading } = useCapitalStatementForInvestorQuery()
  const intl = useIntl()

  if (isLoading)
    return (
      <Card isExpandable padding="1.4rem 2.4rem">
        <Card.Header>
          {intl.formatMessage({ id: 'fundProfile.capitalStatement' })}
        </Card.Header>
        <Card.Body>
          <GridSkeleton
            columnsQuantity={4}
            cellsQuantity={4}
            labelWidth="15rem"
            valueWidth="12.4rem"
          />
        </Card.Body>
      </Card>
    )

  return (
    <CapitalStatementContent
      investorsTotalUnfundedCommitment={data?.unfundedCommitment}
      totalCapitalCalled={data?.capitalCalled}
      totalCommittedCapital={data?.committedCapital}
      totalDistributions={data?.distributions}
      proRataHoldingValue={data?.proRataHoldingValue}
    />
  )
}

const CSSpecificPortfolio = ({
  id,
  isFundPortfolioProfile,
  previewInvestorId,
}: {
  id: string
  isFundPortfolioProfile?: boolean
  previewInvestorId?: string
}) => {
  const {
    data: {
      investorsTotalUnfundedCommitment,
      totalCapitalCalled,
      totalCommittedCapital,
      totalDistributions,
      proRataHoldingValue,
    },
  } = useFetchCapitalStatement({ id, previewInvestorId })

  return (
    <CapitalStatementContent
      investorsTotalUnfundedCommitment={investorsTotalUnfundedCommitment}
      totalCapitalCalled={totalCapitalCalled}
      totalCommittedCapital={totalCommittedCapital}
      totalDistributions={totalDistributions}
      proRataHoldingValue={proRataHoldingValue}
      isFundPortfolioProfile={isFundPortfolioProfile}
    />
  )
}

const CapitalStatement = ({
  isFundPortfolioProfile,
  previewInvestorId,
}: {
  isFundPortfolioProfile?: boolean
  previewInvestorId?: string
}) => {
  const currentPortfolio = useAppSelector(getCurrentPortfolio)

  const isAllFundsSelected = currentPortfolio.id === ALL_FUNDS

  return isAllFundsSelected ? (
    <CSAllFunds />
  ) : (
    <CSSpecificPortfolio
      id={currentPortfolio.id}
      isFundPortfolioProfile={isFundPortfolioProfile}
      previewInvestorId={previewInvestorId}
    />
  )
}

export default CapitalStatement
