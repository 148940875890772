import { useIntl } from 'react-intl'
import { RowNumberCellContainer } from '../Template.styles'
import RowErrorIcon, {
  RowErrorType,
  RowErrorTypeToColor,
} from './components/RowErrorIcon'

interface Props {
  type: RowErrorType
  right?: string
}

const RowNumberCellError = ({ type, right }: Props) => {
  const intl = useIntl()

  return (
    <RowNumberCellContainer color={RowErrorTypeToColor[type]}>
      <RowErrorIcon
        type={type}
        message={
          type === RowErrorType.ERROR
            ? intl.formatMessage({ id: 'spreadsheet.rowContainsErrors' })
            : undefined
        }
        right={right}
      />
    </RowNumberCellContainer>
  )
}

export default RowNumberCellError
