import Types from './types'

export const setCurrentPortfolio = (portfolio) => ({
  type: Types.SET_CURRENT_PORTFOLIO,
  payload: portfolio,
})

export const setPortfolios = (portfolios) => ({
  type: Types.SET_PORTFOLIOS,
  payload: portfolios,
})

export const setInvestorCalculation = (
  portfolioId: string,
  isEnabled: boolean
) => ({
  type: Types.SET_INVESTOR_CALCULATION,
  payload: { portfolioId, isEnabled },
})
