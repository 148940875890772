import React from 'react'
import { useIntl } from 'react-intl'

import FadeIn from 'components/FadeIn/FadeIn'
import theme from 'utils/theme'

import {
  MainTag,
  LocationRowContainer,
  SetAsMainButton,
  DeleteButton,
  WarningIcon,
  LocationRowContent,
} from './Row.styles'

interface LocationRowProps {
  formattedAddress: string
  main: boolean
  setAsMain: () => void
  onDelete: () => void
  canDelete?: boolean
  isPopulated?: boolean
}
const LocationRow: React.FC<LocationRowProps> = ({
  formattedAddress,
  main,
  setAsMain,
  onDelete,
  isPopulated,
  canDelete = true,
}) => {
  const intl = useIntl()
  return (
    <FadeIn>
      <LocationRowContainer>
        <LocationRowContent>
          {isPopulated && (
            <WarningIcon icon={['far', 'exclamation-triangle']} />
          )}
          {formattedAddress}
        </LocationRowContent>

        <div>
          {canDelete && (
            <DeleteButton
              icon="trash-alt"
              color={theme.colors.red}
              onClick={onDelete}
              border={false}
              buttonSize="2rem"
            />
          )}
        </div>

        {main ? (
          <MainTag>{intl.formatMessage({ id: 'common.main' })}</MainTag>
        ) : (
          <SetAsMainButton onClick={setAsMain}>
            {intl.formatMessage({ id: 'common.setAsMain' })}
          </SetAsMainButton>
        )}
      </LocationRowContainer>
    </FadeIn>
  )
}

export default LocationRow
