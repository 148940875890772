import { useIntl } from 'react-intl'

import InputLabel from 'ui/InputLabel'
import { useField } from 'components/Form/hooks'
import { getCountryNameByCode } from 'utils/functions/countries'
import { CountryCode } from 'utils/types/common'

import CountryDropdown from '../CountryDropdown/CountryDropdown'
import { useCountryInput } from './useCountryInput'
import {
  ChevronDown,
  Container,
  InputContainer,
  SelectedOption,
} from './CountryInput.styles'

interface CountryInputProps {
  large?: boolean
  intlLabel?: string
  isOptional?: boolean
  optionalIntlLabel?: string
  name: string
}

const CountryInput = ({
  large,
  intlLabel,
  isOptional,
  optionalIntlLabel,
  name,
}: CountryInputProps) => {
  const intl = useIntl()
  const [field, , helper] = useField<CountryCode>(name)
  const {
    countryDropdownRef,
    containerRef,
    showDropdown,
    selectOption,
    toggleDropdown,
    getPosition,
  } = useCountryInput({
    onChange: (code: CountryCode) => helper.setValue(code),
    name,
  })

  const label = intlLabel ? intl.formatMessage({ id: intlLabel }) : undefined
  const optionalLabel = optionalIntlLabel
    ? intl.formatMessage({ id: optionalIntlLabel })
    : undefined

  return (
    <Container ref={containerRef}>
      <InputContainer>
        {label && (
          <InputLabel
            large={large}
            marginLeft={large ? '1.6rem' : ''}
            isOptional={isOptional}
            optionalLabel={optionalLabel}
          >
            {label}
          </InputLabel>
        )}
        <SelectedOption large={large} tabIndex={0} onMouseDown={toggleDropdown}>
          {getCountryNameByCode(field.value)}
          <ChevronDown icon={['far', 'chevron-down']} />
        </SelectedOption>
      </InputContainer>
      <CountryDropdown
        countryDropdownRef={countryDropdownRef}
        showDropdown={showDropdown}
        selectOption={selectOption}
        position={getPosition()}
      />
    </Container>
  )
}

export default CountryInput
