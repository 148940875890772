import { useCallback } from 'react'
import { useField, useFormikContext } from 'formik'
import { useIntl } from 'react-intl'
import { IndexFundPortfolio } from 'utils/types/portfolios'
import PortfolioService from 'api/PortfolioService'
import { PortfolioTypes } from 'utils/constants/portfolio'

export const useSingleFundPortfolioSelector = (portfolioKey: string) => {
  const intl = useIntl()
  const [field, meta, helpers] = useField(portfolioKey)
  const formik = useFormikContext()
  const messages = {
    label: intl.formatMessage({ id: 'updates.includeIn' }),
    placeholder: intl.formatMessage({ id: 'updates.selectFund' }),
  }

  const loadPortfolios = useCallback(async (inputSearch: string) => {
    return PortfolioService.fetchPortfolios({
      portfolioType: [PortfolioTypes.FUND, PortfolioTypes.DEAL],
      name: inputSearch,
      perPage: 5000,
    })
  }, [])

  const getOption = (portfolio: IndexFundPortfolio) => {
    return {
      label: portfolio.name,
      id: portfolio.id,
      type: portfolio.type,
    }
  }

  return {
    field,
    helpers,
    meta,
    messages,
    formik,
    loadPortfolios,
    getOption,
  }
}
