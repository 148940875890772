import React, { useState, useCallback } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { updateServiceWorker } from 'utils/serviceWorker'
import Logo from 'components/Navbar/Logo'
import Button from 'components/Button'

import styles from './ErrorBoundary.module.scss'

const ErrorFallback = ({ error, componentStack, onReloadPage }) => {
  const [eventIdState, setEventIdState] = useState('')

  React.useEffect(() => {
    const eventId = Sentry.captureException(error)
    setEventIdState(eventId)
  }, [error])

  return (
    <div className={styles.container}>
      <Logo size="large" />
      <h1>
        <FormattedMessage id="errorBoundary.title" />
      </h1>
      <h3>
        <FormattedMessage id="errorBoundary.legend" />
      </h3>
      <details>
        <summary>
          <FormattedMessage id="errorBoundary.details" />
        </summary>
        <div className={styles.errorContainer}>
          <div>{error.toString()}</div>
          {componentStack}
        </div>
      </details>
      <div className={styles.btnContainer}>
        <Button className={styles.reload} type="button" onClick={onReloadPage}>
          <FormattedMessage id="errorBoundary.reloadPage" />
        </Button>
        <Button
          className={styles.reportFeedback}
          type="button"
          onClick={() => {
            Sentry.showReportDialog({ eventId: eventIdState })
          }}
        >
          <FormattedMessage id="errorBoundary.reportFeedback" />
        </Button>
      </div>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.string.isRequired,
  componentStack: PropTypes.string.isRequired,
  onReloadPage: PropTypes.func.isRequired,
}

const ErrorBoundary = ({ children }) => {
  const history = useHistory()
  const serviceWorker = useAppSelector((state) => state.serviceWorker)

  const reloadPage = useCallback(() => {
    history.replace('/sign-out')
    updateServiceWorker(serviceWorker)
  }, [history, serviceWorker])

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorFallback
          onReloadPage={reloadPage}
          resetError={resetError}
          error={error}
          componentStack={componentStack}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ErrorBoundary
