import { useRef, useState } from 'react'
import useOnScreen from './useOnScreen'

interface UseInfiniteScrollProps {
  rootElement?: React.RefObject<HTMLElement>
  initiallyEnabled?: boolean
}
const useInfiniteScroll = ({
  rootElement,
  initiallyEnabled = true,
}: UseInfiniteScrollProps = {}) => {
  const [infiniteScrollEnabled, setInfiniteScrollEnabled] =
    useState(initiallyEnabled)

  const ref = useRef<HTMLDivElement>(null)
  const { observe, onScreen } = useOnScreen(ref, '0px', rootElement)

  return {
    ref: observe,
    onScreen: infiniteScrollEnabled && onScreen,
    setInfiniteScrollEnabled,
    infiniteScrollEnabled,
  }
}

export default useInfiniteScroll
