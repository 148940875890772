import {
  PortfolioTypes,
  MixedPortfoliosType,
  getPortfolioApiUrl,
  getPortfolioParams,
} from 'utils/constants/portfolio'
import { TransactionFundType } from 'utils/constants/transactionTypes'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import { getPortfolioTypeFromApiType } from 'utils/types/api/updates'
import { SortDirectionType, ValueOf } from 'utils/types/common'
import { Company, FundProfile } from 'utils/types/company'
import {
  IndexFundPortfolioInvestor,
  SharePortfolioSettings,
} from 'utils/types/funds'
import {
  EditPorfolioPayload,
  FairMarketValue,
  FundPortfolioInvestors,
  IndexPortfolio,
  Portfolio,
  PortfolioCompany,
  PortfolioMetricsStatus,
  PortfolioType,
  ShareSettingsPayload,
  FollowedHoldingsTable,
  MixedPortfolio,
  UserPortfolioSettings,
} from 'utils/types/portfolios'
import { MixedUpdate, TransactionItem } from 'utils/types/update'

import axiosClient from './httpClient'

export enum OrderByProps {
  NAME = 'name',
  UPDATED_AT = 'updated_at',
  TYPE = 'type',
}

export enum TransactionOwner {
  VEHICLE = 'vehicle',
  COMPANY = 'company',
}

interface ExportCSVParams {
  portfolioId?: string
  portfolioType: PortfolioType | MixedPortfoliosType
  transactionFundType?: ValueOf<typeof TransactionFundType>
}

enum FundPortfoliosFilter {
  PORTFOLIO_OR_COMPANIES = 'q[company_data_name_or_name_cont]',
  FUND_PORTFOLIOS_WITH_AT_LEAST_ONE_VEHICLE = 'q[fund_portfolio_investors_portfolio_vehicle_investor_transactions_id_null]',
  ID_NOT_IN = 'q[id_not_in]',
  ID_IN = 'q[id_in]',
}

const BELONG_TO_PORTFOLIO_OR_COMPANIES_IN_PORTFOLIO_FILTER =
  'q[belonging_to_portfolio_or_companies_in_portfolio]'

export interface FileCounts {
  totalFiles: number
  totalPdfs: number
  totalImages: number
  totalDocuments: number
  totalSpreadsheets: number
  totalPresentations: number
  totalAudios: number
  totalEmailContents: number
  totalVideos: number
  totalCompressedFiles: number
  totalOtherTypes: number
}

export type FileSummaryChartData = Array<{ name: string } & FileCounts>

export interface UpdateCounts {
  totalAnnouncements: number
  totalCreatedUpdates: number
  totalDocuments: number
  totalDraftUpdates: number
  totalEmails: number
  totalNotes: number
  totalSuggestedUpdates: number
  totalUpdates: number
}

export interface DealSummaryChartCounts {
  totalInvested: number
  totalValue: number
}

export type UpdateSummaryChartData = Array<
  { name: string } & Pick<
    UpdateCounts,
    'totalAnnouncements' | 'totalDocuments' | 'totalEmails' | 'totalNotes'
  >
>

export type DealSummaryChartData = Array<
  { name: string } & Pick<
    DealSummaryChartCounts,
    'totalInvested' | 'totalValue'
  >
>

export interface FilesSummary extends FileCounts {
  chart: FileCounts[]
}

export interface UpdatesSummary extends UpdateCounts {
  chart: UpdateCounts[]
  mixedUpdates: MixedUpdate[]
}

export interface PortfoliosFilters {
  name?: string
  orderBy?: OrderByProps
  direction?: SortDirectionType
  portfolioType?: PortfolioType | PortfolioType[]
  nameInFundAndCompanies?: string
  isInvestmentVehicleNull?: boolean
  page?: number
  idNotIn?: string[]
  withCompanies?: boolean
  perPage?: number
  idIn?: string[]
}

class PortfolioService {
  static addCompanyToPortoflio = (companyId) => {
    return axiosClient().post(`/portfolio_companies?company_id=${companyId}`)
  }

  static getPortfoliosByName = async (name) => {
    const {
      data: { mixedPortfolios },
    } = await axiosClient().get(`/mixed_portfolios?q[name_eq]=${name}`)

    return mixedPortfolios.map(
      (mixedPortfolio) => mixedPortfolio.portfolio
    ) as Portfolio<PortfolioCompany>[]
  }

  static fetchPortfolios = async (
    filters?: PortfoliosFilters
  ): Promise<IndexPortfolio[]> => {
    const params = {
      page: filters?.page,
      'q[name_cont]': filters?.name,
      '[sorts]':
        filters?.orderBy && filters.direction
          ? [`${filters.orderBy} ${filters.direction}`]
          : undefined,
      'q[with_companies]': filters?.withCompanies,
      [FundPortfoliosFilter.FUND_PORTFOLIOS_WITH_AT_LEAST_ONE_VEHICLE]:
        filters?.isInvestmentVehicleNull,
      [FundPortfoliosFilter.PORTFOLIO_OR_COMPANIES]:
        filters?.nameInFundAndCompanies,
      [FundPortfoliosFilter.ID_NOT_IN]: filters?.idNotIn,
      per_page: filters?.perPage,
      [FundPortfoliosFilter.ID_IN]: filters?.idIn,
    }

    if (Array.isArray(filters?.portfolioType)) {
      params['q[type_in]'] = filters?.portfolioType
    } else if (filters?.portfolioType) {
      params['q[type_eq]'] = filters?.portfolioType
    }

    const {
      data: { mixedPortfolios },
    } = await axiosClient().get(`/mixed_portfolios`, {
      params,
    })

    const value = mixedPortfolios.map((mixedPortfolio) => {
      return ResponseNormalizer.normalizeIndexPortfolio(
        mixedPortfolio.portfolio
      )
    })

    return value
  }

  static createPortfolio = async ({ type, ...params }): Promise<Portfolio> => {
    const url = getPortfolioApiUrl(type)
    const portfolioParams = getPortfolioParams(type, params)
    const response = await axiosClient().post(url, portfolioParams)

    return ResponseNormalizer.normalizePortfolio(
      response.data.result,
      response.data.entities
    )
  }

  static migratePortfolio = async (portfolio) => {
    const urls = {
      [PortfolioTypes.TRACK]: `/track_portfolios/${portfolio.id}/migrate_to_invest_portfolio`,
      [PortfolioTypes.INVEST]: `/invest_portfolios/${portfolio.id}/migrate_to_fund_portfolio`,
    }
    const response = await axiosClient().patch(urls[portfolio.type])

    return ResponseNormalizer.normalizePortfolio(
      response.data.result,
      response.data.entities
    )
  }

  static updateFundPortfolios = (fundId, newPortfolios, deletedPortfolios) => {
    return axiosClient().patch(
      `/fund_portfolio_profiles/${fundId}/update_group_scoped`,
      {
        fund_portfolio: {
          holdingPortfolioCompaniesAttributes: [
            ...newPortfolios.map((portfolio) => ({
              portfoliableId: portfolio.id,
              type: portfolio.type,
            })),
            ...deletedPortfolios.map((portfolio) => ({
              id: portfolio.portfolioCompanyId,
              _destroy: true,
            })),
          ],
        },
      }
    )
  }

  static async getPublicFunds(
    fundCompanyId: string,
    text?: string
  ): Promise<FundProfile[]> {
    const params = {
      'q[belonging_to_fund_company_id_eq]': fundCompanyId,
    }

    if (text) {
      params['q[name_cont]'] = text
    }

    const response = await axiosClient().get('/fund_portfolio_profiles', {
      params,
    })

    return response.data.result.map((fundId) =>
      ResponseNormalizer.normalizePublicFundProfile(
        fundId,
        response.data.entities
      )
    )
  }

  static async getPublicFundProfile(portfolioId: string): Promise<FundProfile> {
    const response = await axiosClient().get(
      `/fund_portfolio_profiles/${portfolioId}`
    )

    return ResponseNormalizer.normalizePublicFundProfile(
      response.data.result,
      response.data.entities
    )
  }

  static async getPortfolio<T extends Portfolio = Portfolio>(
    portfolioId: string,
    portfolioType: PortfolioType,
    isInvestorCalculations?: boolean
  ): Promise<T> {
    let url = `/${getPortfolioApiUrl(portfolioType)}/${portfolioId}`
    if (
      portfolioType === PortfolioTypes.FUND ||
      portfolioType === PortfolioTypes.DEAL
    ) {
      url += `/show_companies`
    }
    const params = {
      ...(isInvestorCalculations && {
        params: { fund_holdings_calculation: true },
      }),
    }

    const response = await axiosClient().get(url, params)
    return ResponseNormalizer.normalizePortfolio<T>(
      response.data.result,
      response.data.entities
    )
  }

  static getFundPortfolioInvestors = async (
    portfolioId: string
  ): Promise<FundPortfolioInvestors> => {
    const url = `/fund_portfolios/${portfolioId}/show_investors`

    const response = await axiosClient().get(url)
    return ResponseNormalizer.normalizeFundPortfolioInvestors(
      portfolioId,
      response.data.entities
    )
  }

  static fetchFundPortfolioInvestors = async (
    portfolioId: string
  ): Promise<IndexFundPortfolioInvestor[]> => {
    const url = `/fund_portfolio_investors`

    const response = await axiosClient().get(url, {
      params: { 'q[fund_portfolio_id_eq]': portfolioId },
    })

    return response.data.result.map((fundPortfolioInvestorId) =>
      ResponseNormalizer.normalizeIndexFundPortfolioInvestor(
        fundPortfolioInvestorId,
        response.data.entities
      )
    )
  }

  static editTrackPortfolio = (portfolioId, portfolio) => {
    return axiosClient().patch(
      `/${getPortfolioApiUrl(PortfolioTypes.TRACK)}/${portfolioId}`,
      {
        portfolio,
      }
    )
  }

  static addCompanyToPortfolio = async (
    portfolio: Portfolio,
    company: Company
  ): Promise<Portfolio> => {
    const {
      data: { result, entities },
    } = await axiosClient().patch(
      `/${getPortfolioApiUrl(portfolio.type)}/${portfolio.id}`,
      {
        portfolio: {
          portfolioCompaniesAttributes: [
            {
              holdingId: company.id,
            },
          ],
        },
      }
    )

    return ResponseNormalizer.normalizePortfolio(result, entities)
  }

  static addHoldingsToPortfolio = async (
    portfolio: IndexPortfolio,
    holdingIds: string[]
  ): Promise<Portfolio> => {
    const {
      data: { result, entities },
    } = await axiosClient().patch(
      `/${getPortfolioApiUrl(portfolio.type)}/${portfolio.id}`,
      {
        portfolio: {
          portfolioCompaniesAttributes: holdingIds.map((holdingId) => ({
            holdingId,
          })),
        },
      }
    )

    return ResponseNormalizer.normalizePortfolio(result, entities)
  }

  static editInvestPortfolio = async (portfolioId, data) => {
    return axiosClient().patch(
      `/${getPortfolioApiUrl(PortfolioTypes.INVEST)}/${portfolioId}`,
      {
        portfolio: data,
      }
    )
  }

  static editFundPortfolio = async (
    portfolioId: string,
    data: EditPorfolioPayload
  ): Promise<Portfolio> => {
    const {
      data: { result, entities },
    } = await axiosClient().patch(
      `/${getPortfolioApiUrl(PortfolioTypes.FUND)}/${portfolioId}`,
      {
        portfolio: data,
      }
    )
    return ResponseNormalizer.normalizePortfolio(result, entities)
  }

  static removeCompanyFromPortfolio = async (
    portfolio: Portfolio,
    portfolioCompany: PortfolioCompany
  ): Promise<Portfolio> => {
    const payload = {
      portfolio: {
        portfolioCompaniesAttributes: [
          {
            id: portfolioCompany.id,
            _destroy: true,
            holdingId: portfolioCompany.holding?.id,
          },
        ],
      },
    }

    const {
      data: { result, entities },
    } = await axiosClient().patch(
      `/${getPortfolioApiUrl(portfolio.type)}/${portfolio.id}`,
      payload
    )

    return ResponseNormalizer.normalizePortfolio(result, entities)
  }

  static removePortfolio = (portfolioId, portfolioType) => {
    return axiosClient().delete(
      `/${getPortfolioApiUrl(portfolioType)}/${portfolioId}`
    )
  }

  static exportCSV = async (params: ExportCSVParams) => {
    const ownerMap = {
      [TransactionFundType.HOLDING]: TransactionOwner.COMPANY,
      [TransactionFundType.INVESTOR]: TransactionOwner.VEHICLE,
    }

    const action = params.transactionFundType
      ? `export?type=${ownerMap[params.transactionFundType]}`
      : 'export'

    const url = params.portfolioId
      ? `/${getPortfolioApiUrl(params.portfolioType)}/${
          params.portfolioId
        }/${action}`
      : `/${getPortfolioApiUrl(params.portfolioType)}/${action}`

    return axiosClient().get(url)
  }

  static exportFairMarketValuesCSV = async (portfolioId?: string) => {
    const url = portfolioId
      ? `/mixed_portfolios/${portfolioId}/fair_market_values/export`
      : `/fair_market_values/export`

    return axiosClient().get(url)
  }

  static deleteFairMarketValue = async (fmvId) => {
    await axiosClient().delete(`/fair_market_values/${fmvId}`)
  }

  static getFairMarketValues = async (
    portfolioCompanyId: string,
    page: number,
    perPage: number
  ): Promise<FairMarketValue[]> => {
    const response = await axiosClient().get(
      `/invest_portfolio_companies/${portfolioCompanyId}/fair_market_values`,
      { params: { page, per_page: perPage } }
    )
    return response?.data?.result.map(
      (fmvId: string) => response?.data?.entities?.fairMarketValues?.[fmvId]
    )
  }

  static getPortfolioLastsFairMarketValues = async (portfolioId) => {
    const response = await axiosClient().get(
      `/mixed_portfolios/${portfolioId}/last_fair_market_values`
    )
    return response?.data?.investPortfolioCompanies
  }

  static addFairMarketValue = async (
    portfolioCompanyId,
    date,
    value,
    description
  ) => {
    const response = await axiosClient().post(
      `/invest_portfolio_companies/${portfolioCompanyId}/fair_market_values`,
      {
        fairMarketValue: { date, value, description },
      }
    )
    return response?.data?.entities?.fairMarketValues?.[response?.data?.result]
  }

  static editFairMarketValue = async (fmvId, date, value, description) => {
    const response = await axiosClient().patch(`/fair_market_values/${fmvId}`, {
      fairMarketValue: { date, value, description },
    })

    return response?.data?.entities?.fairMarketValues?.[response?.data?.result]
  }

  static getUserPortfolios = async (): Promise<MixedPortfolio[]> => {
    const response = await axiosClient().get(
      '/mixed_portfolios/user_mixed_portfolios'
    )
    const { mixedPortfolios } = response.data

    return (
      mixedPortfolios?.map((item) => ({
        ...item.portfolio,
        type: getPortfolioTypeFromApiType(item.portfolio.type),
      })) ?? []
    )
  }

  static getTransactionsByCompany = async ({
    portfolioCompanyId,
    page,
    pageSize,
    orderBy,
    direction,
  }: {
    portfolioCompanyId: string
    page: number
    pageSize: number
    orderBy?: OrderByProps
    direction?: SortDirectionType
  }): Promise<TransactionItem[]> => {
    const params = {
      page,
      per_page: pageSize,
      '[sorts]': orderBy && direction ? [`${orderBy} ${direction}`] : undefined,
    }

    const response = await axiosClient().get(
      `/transactions/in_portfolio_company?portfolio_company_id=${portfolioCompanyId}`,
      { params }
    )
    return response.data.result.map((transactionId) =>
      ResponseNormalizer.normalizeTransactionItem(
        transactionId,
        response.data.entities
      )
    )
  }

  static getTransactionsByCompanies = async ({
    companiesIds,
    page,
    pageSize,
    orderBy,
    direction,
  }: {
    companiesIds: string[]
    page: number
    pageSize: number
    orderBy?: OrderByProps
    direction?: SortDirectionType
  }): Promise<TransactionItem[]> => {
    const query = companiesIds
      .map((id) => `portfolio_companies_id[]=${id}`)
      .join('&')
    const params = {
      page,
      per_page: pageSize,
      '[sorts]': orderBy && direction ? [`${orderBy} ${direction}`] : undefined,
    }

    const response = await axiosClient().get(
      `/transactions/in_portfolio_companies?${query}`,
      { params }
    )

    return response.data.result.map((transactionId) =>
      ResponseNormalizer.normalizeTransactionItem(
        transactionId,
        response.data.entities
      )
    )
  }

  static setUserPortfolioSettings = async (portfolioId, setting) => {
    return axiosClient().patch(`/user_portfolios/${portfolioId}`, {
      setting,
    })
  }

  static getUserPortfolioSettings = async (
    portfolioId
  ): Promise<UserPortfolioSettings> => {
    const response = await axiosClient().get(`/user_portfolios/${portfolioId}`)
    return response.data.entities.userPortfolios[
      response.data.result
    ] as UserPortfolioSettings
  }

  static addInvestorToFundPortfolio = async (portfolioId, investorGroupId) => {
    const response = await axiosClient().post(
      `/fund_portfolios/${portfolioId}/fund_portfolio_investors`,
      {
        fundPortfolioInvestor: {
          investorGroupId,
        },
      }
    )
    return response?.data?.entities?.fairMarketValues?.[response?.data?.result]
  }

  static deleteInvestorFromFundPortfolio = async (
    investorId: string,
    currentGroupId: string
  ) => {
    await axiosClient(false, {
      'group-id': currentGroupId,
    }).delete(`/fund_portfolio_investors/${investorId}`)
  }

  static fetchPortfolioShareSettings = async (): Promise<
    SharePortfolioSettings[]
  > => {
    const response = await axiosClient().get(`/share_portfolio_settings`)
    return response.data.result.map(
      (sharePorfolioId) =>
        response.data.entities.sharePortfolioSettings[
          sharePorfolioId
        ] as SharePortfolioSettings
    )
  }

  static fetchPortfolioShareSetting = async (
    id: string
  ): Promise<SharePortfolioSettings> => {
    const response = await axiosClient().get(`/share_portfolio_settings/${id}`)
    return response.data.entities.sharePortfolioSettings[
      response.data.result
    ] as SharePortfolioSettings
  }

  static createShareSettingTemplate = async (
    payload: ShareSettingsPayload
  ): Promise<SharePortfolioSettings> => {
    const response = await axiosClient().post(`/share_portfolio_settings`, {
      sharePortfolioSetting: {
        name: payload.name,
        visibleFields: payload.sharePortfolioSettings,
      },
    })
    return response.data.entities.sharePortfolioSettings[
      response.data.result
    ] as SharePortfolioSettings
  }

  static updateShareSetting = async (
    shareSettingId: string,
    payload: ShareSettingsPayload
  ): Promise<SharePortfolioSettings> => {
    const response = await axiosClient().patch(
      `/share_portfolio_settings/${shareSettingId}`,
      {
        sharePortfolioSetting: {
          name: payload.name,
          visibleFields: payload.sharePortfolioSettings,
        },
      }
    )

    return response.data.entities.sharePortfolioSettings[
      response.data.result
    ] as SharePortfolioSettings
  }

  static getAllInvestmentsSummary = async () => {
    return axiosClient().get('mixed_portfolios/aggregate_portfolio_summary')
  }

  static getFilteredSummary = async ({
    holdingsIds,
  }: {
    holdingsIds: string[]
  }) => {
    return axiosClient().post('/portfolio_companies/aggregate_summary', {
      ids: holdingsIds,
    })
  }

  static getFilteredHoldings = async ({
    holdingsIds,
  }: {
    holdingsIds: string[]
  }): Promise<PortfolioCompany[]> => {
    const response = await axiosClient().post(
      '/portfolio_companies/aggregate_holdings',
      {
        ids: holdingsIds,
      }
    )
    const holdings =
      Object.keys(response.data.holdings || {}).map((key) =>
        ResponseNormalizer.normalizePortfolioCompany(key, {
          portfolioCompanies: response.data.holdings,
        })
      ) ?? []

    return holdings
  }

  static fetchAggregateHoldings = async (): Promise<PortfolioCompany[]> => {
    const response = await axiosClient().get(
      `/mixed_portfolios/holdings_aggregate`
    )

    const holdings =
      Object.keys(response.data.holdings || {}).map((key) =>
        ResponseNormalizer.normalizePortfolioCompany(key, {
          portfolioCompanies: response.data.holdings,
        })
      ) ?? []

    return holdings
  }

  static getPortfolioMetricsStatus = async (
    portfolioId: string
  ): Promise<PortfolioMetricsStatus> => {
    const response = await axiosClient().get(
      `/mixed_portfolios/${portfolioId}/portfolio_metrics_status`
    )

    return ResponseNormalizer.normalizePortfolioMetricsStatus(
      response.data.result,
      response.data.entities
    )
  }

  static getHoldingMetricsStatus = async (): Promise<{
    [portfolioId: string]: PortfolioMetricsStatus
  }> => {
    const response = await axiosClient().get(`/mixed_portfolios/metrics_states`)

    const processedHoldingMetricsStatusObject: {
      [portfolioId: string]: PortfolioMetricsStatus
    } = Object.fromEntries(
      response.data.result.map((portfolioId) => [
        portfolioId,
        ResponseNormalizer.normalizePortfolioMetricsStatus(
          portfolioId,
          response.data.entities
        ).action,
      ])
    )

    return processedHoldingMetricsStatusObject
  }

  static getFilesSummary = async (portfolioId: string) => {
    const response = await axiosClient().get<{
      filesSummary: FilesSummary
    }>(
      `/contents/summary?${BELONG_TO_PORTFOLIO_OR_COMPANIES_IN_PORTFOLIO_FILTER}[]=${portfolioId}`
    )
    return response.data
  }

  static getPortfolioHoldings = async (portfolioId: string) => {
    const url = `/contents/grouped_by_entity?${BELONG_TO_PORTFOLIO_OR_COMPANIES_IN_PORTFOLIO_FILTER}[]=${portfolioId}`

    const response = await axiosClient().get(url)
    return Object.keys(response.data.filesGroupedByEntity).map((id) =>
      ResponseNormalizer.normalizePortfolioHolding(
        id,
        response.data.filesGroupedByEntity
      )
    )
  }

  static getUpdatesSummary = async (portfolioId: string) => {
    const response = await axiosClient().get<UpdatesSummary>(
      `/mixed_updates/summary?${BELONG_TO_PORTFOLIO_OR_COMPANIES_IN_PORTFOLIO_FILTER}[]=${portfolioId}`
    )
    return response.data
  }

  static getFollowedHoldingsSummary = async () => {
    const response = await axiosClient().get<{
      followedHoldingsSummary: FollowedHoldingsTable
    }>(`/holdings/followed_holdings_summary`)
    return response.data
  }
}

export default PortfolioService
