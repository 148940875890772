import { MessageStyles } from 'containers/Chat/components/Message'
import SentInformation from 'containers/Chat/components/SentInformation'
import type { MessageResponse } from 'stream-chat'
import { getInitials } from 'utils/functions/user'
import { Container } from './ResultMessage.styles'

type Props = {
  message: MessageResponse
  onClick: React.MouseEventHandler
}

const ResultMessage = ({ message, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <MessageStyles.Avatar
        image={message.user?.image as string}
        initials={getInitials(message.user?.name)}
        avatarStyle="avatarForUpdateFeed"
      />

      <MessageStyles.MessageContainer>
        <SentInformation message={message} />
        <MessageStyles.TheirMessage className="highlight-message">
          {message.text}
        </MessageStyles.TheirMessage>
      </MessageStyles.MessageContainer>
    </Container>
  )
}

export default ResultMessage
