import { useRouteMatch, Route, Switch, Redirect } from 'react-router-dom'

import EditEmail from 'containers/EditEmail'
import ShowEmail from 'containers/Updates/ShowEmail'
import EmailList from 'components/EmailList'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsClient } from 'selectors/auth'

import ReshareUpdate from 'containers/Updates/ReshareUpdate/ReshareUpdate'
import CreateEmail from 'containers/CreateEmail'
import PaddedLayout from 'layouts/PaddedLayout'

const EmailsRoutes = () => {
  const { path } = useRouteMatch()
  const isClient = useAppSelector(isActingAsClient)

  return (
    <Switch>
      <Route exact path={`${path}/:updateId`}>
        {path === '/emails' ? (
          <PaddedLayout>
            <ShowEmail />
          </PaddedLayout>
        ) : (
          <ShowEmail />
        )}
      </Route>
      <Route exact path={`${path}/:updateId/reshare`}>
        <ReshareUpdate />
      </Route>
      <Route exact path={`${path}/:updateId/edit-reshare`}>
        <ReshareUpdate editMode />
      </Route>
      {!isClient && (
        <>
          <Route exact path={path}>
            <PaddedLayout>
              <EmailList />
            </PaddedLayout>
          </Route>
          <Route exact path={`${path}/:updateId/edit`}>
            {path === '/emails' ? (
              <PaddedLayout>
                <EditEmail />
              </PaddedLayout>
            ) : (
              <EditEmail />
            )}
          </Route>
          <Route exact path={`${path}/:updateId/draft`}>
            <CreateEmail />
          </Route>
        </>
      )}
      <Redirect to="/" />
    </Switch>
  )
}
export default EmailsRoutes
