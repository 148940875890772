import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'ui/Button'
import { maxSize } from 'utils/constants/breakpoint'
import RunOutOfStorageMessage from 'components/RunOutOfStorageMessage'

export const Content = styled.div`
  padding: 1rem 0.8rem;
`

export const Row = styled.div`
  display: grid;
  column-gap: 2.8rem;
  grid-template-columns: 1fr 1fr;

  @media ${maxSize.md} {
    grid-template-columns: 1fr;
  }
`

export const MediaContainer = styled.div`
  width: 100%;
  margin-top: 1.8rem;
`

export const DateContainer = styled.div`
  color: var(--color-gray-700);
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
  }

  p {
    font-size: 1.4rem;
  }
`

export const InputTitleContainer = styled.div`
  @media ${maxSize.lg} {
    width: 100%;
  }
`

export const InputDateContainer = styled.div`
  margin-top: 0;
  width: 100%;

  @media ${maxSize.md} {
    margin-top: 2rem;
  }
`

export const RepetitionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.8rem;
  margin-top: 2.5rem;

  @media ${maxSize.md} {
    display: block;
  }

  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`

export const BodyContainer = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  position: relative;
`

export const ExpandArrow = styled(FontAwesomeIcon).attrs({
  icon: ['far', 'arrows-h'],
})`
  cursor: pointer;
  font-size: 1.8rem;
  margin-left: 1.5rem;

  @media ${maxSize.lg} {
    display: none;
  }
`

export const DatePicker = styled.div`
  width: 44%;

  @media ${maxSize.md} {
    width: 100%;
  }
`

export const ReportSettingsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.8rem;
  margin: 2.5rem 0rem;

  button[class*='Button_btn'] {
    color: black;
    font-size: 1.4rem;
    width: auto;
    width: 22rem;
    height: 3.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #979797;
    padding: 1rem;
    svg {
      width: 1.5rem;
    }
  }

  @media ${maxSize.md} {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`

export const CheckboxLegend = styled.p`
  color: #787e8c;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-left: 2.6rem;

  a {
    text-decoration: underline;
    font-weight: 700;
    margin-left: 0.5rem;
  }
`

export const RepetitionContainer = styled.div`
  margin-top: 1.8rem;

  @media ${maxSize.md} {
    margin-left: 0;
    margin-top: 1.5rem;
    width: 100%;
  }
`

export const ReportSettingsTitle = styled.div`
  font-size: 16px;
  margin: 3.8rem 1.8rem 2.5rem 0.1rem;
  color: #000000;
`

export const XeroIcon = styled.div`
  margin-right: 0rem;

  div[class*='UpdateIconFactory_xeroContainer'],
  div[class*='UpdateIconFactory_quickbooksContainer'] {
    position: relative;
    margin-left: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 2.2rem;
    }
  }
`

export const QuickbooksIcon = styled.div`
  margin-right: 0rem;

  div[class*='UpdateIconFactory_quickbooksContainer'] {
    position: relative;
    margin-left: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 2.2rem;
    }
  }
`

export const DropdownContainer = styled.div`
  @media ${maxSize.md} {
    width: 100%;
  }
`

export const PreviewReportButton = styled(Button)`
  margin-top: 2.1rem;
  width: 22rem;
`

export const EditToastMessageWrapper = styled.div`
  margin-bottom: 1.8rem;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RunOutOfStorageMessageStyled = styled(RunOutOfStorageMessage)`
  margin: 0rem 0.8rem 0.8rem 0.8rem;
`
