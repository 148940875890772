export enum AssistanceCardType {
  PIPELINE = 'pipeline',
  HOLDING = 'holding',
  UPDATES = 'updates',
  UPDATE = 'update',
  CREATE_UPDATE = 'createUpdate',
  INBOUND = 'inbound',
  SUGGESTED_UPDATE = 'suggestedUpdate',
  UPLOAD_FILES = 'uploadFiles',
  CHAT = 'chat',
  INVEST_PORTFOLIO = 'investPortfolio',
}
