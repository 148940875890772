import React from 'react'
import { FormattedMessage } from 'react-intl'
import type { Compatible, Uncertain } from '@silevis/reactgrid'
import { CompanyHolding, isCompanyHolding } from 'utils/types/company'
import CellErrorTooltip from 'components/Spreadsheet/CellTemplates/components/CellErrorTooltip/CellErrorTooltip'
import * as Styles from './WebsiteCellTemplate.styles'
import { WebsiteCell } from './WebsiteCellTemplate'
import HoldingProfileSuggestionButton from './HoldingProfileSuggestionButton'

interface WebsiteCellDisplayValueProps {
  cell: Compatible<WebsiteCell>
  onCellChanged: (cell: Compatible<WebsiteCell>, commit: boolean) => void
  getCompatibleCell: (
    uncertainCell: Uncertain<WebsiteCell>
  ) => Compatible<WebsiteCell>
}

const WebsiteCellDisplayValue: React.FC<WebsiteCellDisplayValueProps> = ({
  cell,
  onCellChanged,
  getCompatibleCell,
}) => {
  const onOptionClick = (holding: CompanyHolding) => {
    onCellChanged(getCompatibleCell({ ...cell, holding }), true)
  }

  const getCellError = () => {
    if (cell.customError?.type === 'INVALID_URL') {
      return (
        <div>
          <FormattedMessage id={cell.customError.error} />
        </div>
      )
    }

    const companyErrors = cell.customError?.error.companyErrors
    if (cell.customError?.type === 'DUPLICATE_WEBSITE' && companyErrors) {
      companyErrors.isHidden = false

      if (companyErrors.isHidden) {
        return (
          <FormattedMessage id="spreadsheet.holdings.errors.websiteInUseHidden" />
        )
      }

      if (!companyErrors.website?.holding) {
        return null
      }

      if (isCompanyHolding(companyErrors.website.holding)) {
        const { holding } = companyErrors.website

        return (
          <div>
            <FormattedMessage
              id={`spreadsheet.holdings.errors.${
                holding.fund ? 'websiteInUseFundManager' : 'websiteInUse'
              }`}
            />
            <Styles.HoldingProfileSuggestion.Container>
              <HoldingProfileSuggestionButton
                key={companyErrors.website.id}
                profileData={companyErrors.website}
                onClick={() => onOptionClick(holding)}
              />
            </Styles.HoldingProfileSuggestion.Container>
          </div>
        )
      }
    }

    return null
  }

  return (
    <CellErrorTooltip
      content={cell.customError ? getCellError() : null}
      cellWidth={cell.width}
    >
      <Styles.WebsiteCellValue
        hasError={!!cell.customError}
        autoFilled={!!cell.autoFilled}
        isPlaceholder={!cell.text}
      >
        {cell.text || cell.placeholder}
      </Styles.WebsiteCellValue>
    </CellErrorTooltip>
  )
}

export default WebsiteCellDisplayValue
