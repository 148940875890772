import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import EmailPreview from 'components/EmailPreview'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ShowTransactionContent from 'components/ShowTransactionContent'
import UpdateHeader from 'containers/Updates/UpdateHeader/UpdateHeader'
import { UpdateTypes } from 'utils/constants/updates'
import { isActingAsFundManager } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  UpdateBasicFormValues,
  UpdateWithItem,
  castUpdate,
} from 'utils/functions/updates'
import { UpdateType } from 'utils/types/common'
import { TransactionUpdate, Update } from 'utils/types/update'
import Chip from 'components/Chip'
import { getPortfolioIcon } from 'utils/functions/renderers/renderPortfolioHelper'
import { PortfolioTypes } from 'utils/constants/portfolio'
import Attachments from './Attachments'
import EditToastMessage from './EditToastMessage'
import {
  Content,
  EmailPreviewWrapper,
  Row,
  UpdateHistoryContainer,
  Spacer,
  FlexColumnContainer,
  ColumnTitle,
} from './ShowUpdateContent.styles'
import UpdateText from './UpdateText'
import useShowUpdateContent from './useShowUpdateContent'
import {
  IconsTypes,
  getImageComponent,
} from '../components/ShareSettingsModal/components/ShareWithDropdown/components/Icons'

interface ShowUpdateContentProps {
  update: Update | UpdateWithItem
  updateType: UpdateType
  children?: React.ReactNode
  updateTitle?: string
  hideHeader?: boolean
  editMode?: boolean
  uniqueUserNames?: string[]
}

const ShowUpdateContent: React.FC<ShowUpdateContentProps> = ({
  update,
  updateType,
  children = null,
  updateTitle = '',
  hideHeader = false,
  editMode = false,
  uniqueUserNames,
}) => {
  const history = useHistory()
  const intl = useIntl()
  const isFundManager = useAppSelector(isActingAsFundManager)

  const { updateValues, contents, createdAtDate, formatEntitiesList } =
    useShowUpdateContent({
      update,
      updateType,
      updateTitle,
    })

  const isTransactionUpdate = useMemo(
    () => updateType === UpdateTypes.TRANSACTION,
    [updateType]
  )

  const isNoteUpdate = useMemo(
    () => updateType === UpdateTypes.NOTE,
    [updateType]
  )

  const isDocumentUpdate = useMemo(
    () => updateType === UpdateTypes.DOCUMENT,
    [updateType]
  )

  const isIUEUpdate = useMemo(
    () => updateType === UpdateTypes.IUE,
    [updateType]
  )

  const renderText = useMemo(
    () =>
      (!isDocumentUpdate && !isTransactionUpdate && !isIUEUpdate) ||
      isDocumentUpdate,

    [isDocumentUpdate, isIUEUpdate, isTransactionUpdate]
  )

  const hasInvestor = useMemo(
    () => !!(updateValues as UpdateBasicFormValues).investor,
    [updateValues]
  )

  const hasFundPortfolio = useMemo(
    () => !!(updateValues as UpdateBasicFormValues).portfolio,
    [updateValues]
  )

  const showInvestorAndFund = useMemo(
    () =>
      (isNoteUpdate || isDocumentUpdate) && (hasInvestor || hasFundPortfolio),
    [isNoteUpdate, isDocumentUpdate, hasInvestor, hasFundPortfolio]
  )

  // const renderInvestorAndFund = useMemo(
  //   () =>
  //     updateType === UpdateTypes.DOCUMENT || updateType === UpdateTypes.NOTE,
  //   [updateType]
  // )

  const handleFundPortfolioClick = () => {
    if (isFundManager) {
      if (
        (updateValues as UpdateBasicFormValues).portfolio?.type ===
        PortfolioTypes.FUND
      ) {
        return history.push(
          `/investments/fund/${
            (updateValues as UpdateBasicFormValues).portfolio?.id
          }/portfolio`
        )
      }

      if (
        (updateValues as UpdateBasicFormValues).portfolio?.type ===
        PortfolioTypes.DEAL
      ) {
        return history.push(
          `/investments/deal/${
            (updateValues as UpdateBasicFormValues).portfolio?.id
          }/portfolio`
        )
      }
    }

    return history.push(
      `/funds/${(updateValues as UpdateBasicFormValues).portfolio?.id}`
    )
  }

  const handleInvestorClick = () => {
    if (isFundManager) {
      return history.push(
        `/investor-management/investors/${
          (updateValues as UpdateBasicFormValues).investor?.id
        }/profile`
      )
    }
    return history.push('/profile')
  }

  return (
    <Content>
      {editMode && <EditToastMessage update={update} createMode={false} />}

      {!!uniqueUserNames?.length && (
        <UpdateHistoryContainer>
          <FontAwesomeIcon icon={['far', 'retweet']} />
          <span>{formatEntitiesList(uniqueUserNames)}</span>
        </UpdateHistoryContainer>
      )}

      {!hideHeader && (
        <Row>
          {updateType && (
            <UpdateHeader
              title={updateValues.title}
              date={createdAtDate}
              updateType={updateType}
              isConfidential={update.confidential}
              isEdited={update.edited && !update.isDraftUpdate}
              isDraftUpdate={update.isDraftUpdate}
            />
          )}
        </Row>
      )}

      {isTransactionUpdate && <Spacer margin="3.2rem" />}

      {castUpdate<TransactionUpdate>(update, UpdateTypes.TRANSACTION) && (
        <ShowTransactionContent update={update} />
      )}

      {showInvestorAndFund && (
        <Row justify="flex-start" gap="2rem" marginTop="2.4rem">
          {hasInvestor && (
            <FlexColumnContainer>
              <ColumnTitle>
                {intl.formatMessage({
                  id: 'updates.investor',
                })}
              </ColumnTitle>
              <Chip
                key={(updateValues as UpdateBasicFormValues).investor?.id}
                maxWidth="15rem"
                text={(updateValues as UpdateBasicFormValues).investor?.name}
                imageComponent={getImageComponent(
                  IconsTypes.avatar,
                  (updateValues as UpdateBasicFormValues).investor?.name,
                  (updateValues as UpdateBasicFormValues).investor?.logo?.url
                )}
                canDelete={false}
                onClick={handleInvestorClick}
              />
            </FlexColumnContainer>
          )}

          {hasFundPortfolio && (
            <FlexColumnContainer>
              <ColumnTitle>
                {intl.formatMessage({
                  id: 'updates.includedIn',
                })}
              </ColumnTitle>
              <Chip
                key={(updateValues as UpdateBasicFormValues).portfolio?.id}
                maxWidth="15rem"
                text={(updateValues as UpdateBasicFormValues).portfolio?.name}
                imageComponent={getPortfolioIcon(
                  (updateValues as UpdateBasicFormValues).portfolio?.type!,
                  false
                )}
                canDelete={false}
                onClick={handleFundPortfolioClick}
              />
            </FlexColumnContainer>
          )}
        </Row>
      )}

      {renderText && (
        <UpdateText text={(updateValues as UpdateBasicFormValues).body} />
      )}

      {updateType !== UpdateTypes.IUE && (
        <Row>{contents && <Attachments update={update} />}</Row>
      )}

      <Row>{children}</Row>

      {updateType === UpdateTypes.IUE && (
        <>
          <EmailPreviewWrapper>
            <EmailPreview emailId={update.item.id} readOnly />
          </EmailPreviewWrapper>
          <Row>{contents && <Attachments update={update} />}</Row>
        </>
      )}
    </Content>
  )
}

export default ShowUpdateContent
