import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const NoPreviewAvailable = styled.div`
  background-color: ${color('veryLightBlue', 0.2)};
  border-radius: 0.8rem;
  color: ${color('lightGray')};
  height: fit-content;
  overflow: hidden;
  padding: 3.4rem 5rem;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
`
