import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const DropFiles = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${color('darkBlue')};
  gap: 1.2rem;

  &:focus {
    outline: none;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const FileUploaderContainer = styled.div<{
  disabled?: boolean
  error: boolean
  isThin: boolean
}>`
  border-radius: 0.5rem;
  border: 0.15rem dashed rgba(120, 126, 140, 0.5);
  cursor: pointer;
  display: table;
  height: inherit;
  padding: 2.4rem;
  width: 100%;
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}

  ${(props) =>
    props.error &&
    css`
      border-color: ${color('red')};
    `}

    ${({ isThin }) =>
    isThin &&
    css`
      padding: 1.6rem 0;

      ${DropFiles} {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;

        ${ButtonContainer} {
          margin: initial;
          margin-left: 0.8rem;
        }
      }
    `}
`

export const FileErrorText = styled.p`
  color: ${color('red')};
  font-size: 1.2rem;
  margin-top: 1.4rem;
  &:empty {
    display: none;
  }
`

export const FileUploaderInput = styled.input`
  display: none;
  width: 100%;
  height: 100%;
`

export const DropFilesText = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkBlue')};
`

export const MaxFileText = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${color('darkGray')};
`
