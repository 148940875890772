import React, { MouseEventHandler } from 'react'
import { useIntl } from 'react-intl'

import * as Styles from './AddPortfolioButton.styles'
import DisableCreatePortfolioTooltip from '../../DisableCreatePortfolioTooltip'

interface AddPortfolioButtonProps {
  isBig?: boolean
  [x: string]: any
  onClick: MouseEventHandler<HTMLDivElement> & ((isOpen: boolean) => void)
}

const AddPortfolioButton: React.FC<AddPortfolioButtonProps> = ({
  isBig,
  ...props
}) => {
  const intl = useIntl()

  const tooltipOffset = isBig
    ? {
        leftOffset: 530,
        topOffset: -18,
      }
    : {
        leftOffset: 180,
      }

  return (
    <Styles.AddPortfolioButtonContainer isBig={isBig}>
      <DisableCreatePortfolioTooltip
        id={`portfolio-limit-${isBig ? 'big' : 'small'}`}
        isBigCreatePortfolioButton={isBig}
        {...tooltipOffset}
      >
        <Styles.AddPortfolioButton
          {...props}
          isBig={isBig}
          option={{
            selectedIcon: ['far', 'plus'],
            nonSelectedIcon: ['far', 'plus'],
            label: intl.formatMessage({ id: 'investments.newPortfolio' }),
          }}
        />
      </DisableCreatePortfolioTooltip>
    </Styles.AddPortfolioButtonContainer>
  )
}

export default AddPortfolioButton
