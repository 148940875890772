import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

interface SideBarState {
  mainSideBarOpen: boolean
}

const initialState: SideBarState = {
  mainSideBarOpen: true,
}

export const sideBarSlice = createSlice({
  name: 'sideBar',
  initialState,
  reducers: {
    switchMainSidebarOpen: (state, action: PayloadAction<boolean>) => {
      state.mainSideBarOpen = action.payload
    },
  },
})

export const { switchMainSidebarOpen } = sideBarSlice.actions

export const getSideBarOpenState = (state: RootState) =>
  state.sideBar.mainSideBarOpen

export default sideBarSlice.reducer
