/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'

import { setEmailSubject } from 'actions/emails'

import styles from './Wizard.module.scss'

const Wizard = ({ children, activeStep: activeStepProp, onSelectStep }) => {
  const [activeStep, setActiveStep] = useState(activeStepProp || '')
  const dispatch = useAppDispatch()

  const handleStepClick = async (name) => {
    const shouldGoToNextStep = await onSelectStep(name)
    if (shouldGoToNextStep) setActiveStep(name)
    return shouldGoToNextStep
  }

  const displayChildren = React.Children.map(
    children,
    (child) =>
      child && React.cloneElement(child, { handleStepClick, activeStep })
  )

  useEffect(() => {
    setActiveStep(activeStepProp)
  }, [activeStepProp])

  useEffect(() => {
    return () => {
      dispatch(setEmailSubject(null))
    }
  }, [])

  return (
    <div className={styles.container}>
      {displayChildren}
      <div className={styles.progressContainer}>
        <div className={styles.progressWrapper}>
          <span className={styles.progressBar} />
        </div>
      </div>
    </div>
  )
}

const Step = ({
  activeStep,
  children,
  handleStepClick: handleStepClickProp,
  name,
  id,
  callback,
}) => {
  const history = useHistory()
  const {
    location: { pathname },
  } = history

  const locationState = history.location?.state

  const handleStepClick = async () => {
    const shouldGoToNextStep = await handleStepClickProp(id || name)

    callback?.()

    if (shouldGoToNextStep !== false) {
      history.push(`${pathname}?step=${id || name}`, {
        ...locationState,
      })
    }
  }

  const stepContainerClass = `
    ${styles.stepContainer} 
    ${activeStep === (id || name) ? styles.activeStepClass : ''}
    `

  return (
    <button
      type="button"
      onClick={handleStepClick}
      className={stepContainerClass}
    >
      <div className={styles.stepIconWrapper}>{children}</div>
      <span className={styles.stepName}>{name}</span>
    </button>
  )
}

Step.defaultProps = {
  activeStep: '',
  name: '',
  id: '',
  handleStepClick: () => {},
}

Step.propTypes = {
  id: PropTypes.string,
  activeStep: PropTypes.string,
  children: PropTypes.node.isRequired,
  handleStepClick: PropTypes.func,
  name: PropTypes.string,
  callback: PropTypes.func,
}

Wizard.Step = Step

const Content = ({ id, activeStep, children }) => (
  <div
    className={classNames(styles.content, {
      [styles.activeContent]: id === activeStep,
    })}
  >
    {React.Children.map(
      children,
      (child) => child && React.cloneElement(child, { activeStep })
    )}
  </div>
)

Content.propTypes = {
  id: PropTypes.string.isRequired,
  activeStep: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

Wizard.Content = Content

Wizard.defaultProps = {
  activeStep: '',
  onSelectStep: () => {},
}

Wizard.propTypes = {
  activeStep: PropTypes.string,
  onSelectStep: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Wizard
