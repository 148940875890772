import { useEffect } from 'react'
import { uniqBy } from 'lodash'
import type { Channel, Event } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'

import { getUser } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { StreamChatType } from '../types'

export const useRemoveChannelMemberListener = <
  StreamChatGenerics extends StreamChatType
>(
  setChannels: React.Dispatch<
    React.SetStateAction<Array<Channel<StreamChatGenerics>>>
  >,
  onRemoveChannelMember?: (channels: Array<Channel<StreamChatGenerics>>) => void
) => {
  const { client } = useChatContext<StreamChatGenerics>()
  const user = useAppSelector(getUser)

  useEffect(() => {
    const handleEvent = (event: Event<StreamChatGenerics>) => {
      setChannels((channels) => {
        const isCurrentUserRemoved = event.member?.user_id === user?.id

        if (isCurrentUserRemoved) {
          const newChannels = channels.filter(
            (channel) => channel.cid !== event.cid
          )
          onRemoveChannelMember?.(newChannels)
          return newChannels
        }

        const updateChannelMembers = channels.find(
          (channel) => channel.cid === event.cid
        )

        if (updateChannelMembers) {
          const channel = client.channel(event.channel_type!, event.channel_id)
          return uniqBy([channel, ...channels], 'cid')
        }

        return channels
      })
    }

    client.on('member.removed', handleEvent)

    return () => {
      client.off('member.removed', handleEvent)
    }
  }, [client, onRemoveChannelMember, setChannels, user?.id])
}
