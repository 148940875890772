import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { useSelectUpdatesContext } from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import { isSuggestedUpdate } from 'utils/functions/suggestedUpdates'

import {
  ActionsContainer,
  Button,
  Container,
  Selected,
  SelectedCount,
} from './SuggestedUpdatesSelected.styles'
import DiscardSuggestedUpdateModal from '../SuggestedUpdateFeedCard/components/DiscardModal'

const SuggestedUpdatesSelected = () => {
  const { selectedCards, unSelectAllUpdates } = useSelectUpdatesContext()
  const selectedCount = Object.keys(selectedCards).length
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false)
  const suggestedUpdates =
    Object.values(selectedCards).filter(isSuggestedUpdate) || []

  const onDiscardSelected = () => {
    setIsDiscardModalOpen(true)
  }

  const onHideModal = () => {
    setIsDiscardModalOpen(false)
    unSelectAllUpdates()
  }

  return selectedCount > 0 ? (
    <>
      {!isDiscardModalOpen && (
        <Container duration={0.2}>
          <Selected>
            <SelectedCount>{selectedCount}</SelectedCount>
            <FormattedMessage id="general.selected" />
          </Selected>
          <ActionsContainer>
            <Button onClick={unSelectAllUpdates}>
              <FormattedMessage id="general.cancel" />
            </Button>
            <Button discard onClick={onDiscardSelected}>
              <FormattedMessage id="suggestedUpdates.discardSelected" />
            </Button>
          </ActionsContainer>
        </Container>
      )}
      <DiscardSuggestedUpdateModal
        isOpen={isDiscardModalOpen}
        onHide={onHideModal}
        suggestedUpdates={suggestedUpdates}
      />
    </>
  ) : null
}

export default SuggestedUpdatesSelected
