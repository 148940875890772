import { useCallback, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import type { FormikProps } from 'formik'
import { yupToFormErrors } from 'formik'

import Toast from 'components/Toast'
import { useUpdatesFeedContext } from 'components/UpdatesFeedV2/UpdateFeedContext'
import EmailContentService from 'api/EmailContentService'
import {
  DiscardSuggestedUpdateForm,
  DiscardedReasonCodes,
} from 'utils/types/suggestedUpdate'
import { getDiscardSuggestedUpdateSchema } from 'utils/schemas/suggestedUpdate'

interface Props {
  onDiscardSuccess?: () => void
  isDiscardingMultipleUpdates?: boolean
}

export const useDiscardForm = ({
  onDiscardSuccess,
  isDiscardingMultipleUpdates = false,
}: Props) => {
  const intl = useIntl()
  const formikRef = useRef<FormikProps<DiscardSuggestedUpdateForm>>(null)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { onDiscardSuggestedUpdateSuccess } = useUpdatesFeedContext()
  const intlDiscardKey = isDiscardingMultipleUpdates
    ? 'multiplesSuggested'
    : 'singleSuggested'

  const discardSuggestedUpdateReasons = useMemo(() => {
    const textPath = 'suggestedUpdates.discardModal.reasons'
    const partialOptions = [
      {
        label: `${textPath}.notRelevant`,
        id: DiscardedReasonCodes.NotRelevant,
      },
      {
        label: `${textPath}.notInterested`,
        id: DiscardedReasonCodes.NotInterested,
      },
      { label: `${textPath}.repetitive`, id: DiscardedReasonCodes.Repeated },
      {
        label: `${textPath}.dontTrust`,
        id: DiscardedReasonCodes.UntrustedSource,
      },
      { label: `${textPath}.other`, id: DiscardedReasonCodes.Other },
    ]

    return partialOptions.map((option) => ({
      ...option,
      label: intl.formatMessage({ id: option.label }),
    }))
  }, [intl])

  const discardSuggestedUpdate = useCallback(
    async (values: DiscardSuggestedUpdateForm) => {
      try {
        setIsLoading(true)
        await Promise.all(
          values.suggestedUpdateIds.map((id) => {
            return EmailContentService.discardContent({
              id,
              emailContent: {
                discardReasonCode: values.reason!,
              },
              deleteEmailContent: values.discardContents,
            })
          })
        )
        onDiscardSuggestedUpdateSuccess?.(values.suggestedUpdateIds)
        onDiscardSuccess?.()
        Toast.displayIntl(
          `suggestedUpdates.discard.${intlDiscardKey}.success`,
          'success'
        )
      } catch {
        Toast.displayIntl(
          `suggestedUpdates.discard.${intlDiscardKey}.error`,
          'error'
        )
      } finally {
        setIsLoading(false)
      }
    },
    [intlDiscardKey, onDiscardSuccess, onDiscardSuggestedUpdateSuccess]
  )

  const onSubmit = useCallback(() => {
    formikRef.current?.submitForm()
  }, [])

  const validateForm = useCallback(
    async (values: DiscardSuggestedUpdateForm) => {
      try {
        const schema = getDiscardSuggestedUpdateSchema()
        await schema.validate(values, { abortEarly: false })

        setIsFormValid(true)
        return {}
      } catch (error) {
        setIsFormValid(false)
        return yupToFormErrors(error)
      }
    },
    []
  )

  return {
    discardSuggestedUpdateReasons,
    formikRef,
    isFormValid,
    isLoading,
    intlDiscardKey,
    discardSuggestedUpdate,
    onSubmit,
    validateForm,
  }
}

export type DiscardFormController = ReturnType<typeof useDiscardForm>
