import { MixedContent } from 'utils/types/files'
import {
  isIndexFileContent as isIndexFileContentFn,
  isIndexEmailContent as isIndexEmailContentFn,
} from 'utils/functions/files'
import Tooltip from 'components/Tooltip'

import { usePreviewContents } from './usePreviewContents'
import * as Styles from './PreviewContents.styles'
import PreviewFile from './components/PreviewFile'
import PreviewContent from './components/PreviewEmailContent'

interface PreviewContentsProps {
  contents: MixedContent[]
  selectedContent?: MixedContent
  onClosePreview: () => void
  isOpen: boolean
  isLoading?: boolean
}

const PreviewContents = ({
  contents,
  selectedContent,
  isOpen,
  isLoading,
  onClosePreview,
}: PreviewContentsProps) => {
  const {
    nextContent,
    previousContent,
    onDownloadFile,
    handleKeyDown,
    onFilePreviewContainerClick,
    currentContent,
    containerRef,
    contentPreviewContainer,
    handleContentPreviewContainerRef,
    typeChecker,
    isFirstContent,
    isLastContent,
  } = usePreviewContents({
    contents,
    selectedContent,
    onClosePreview,
  })
  const isIndexFileContent = isIndexFileContentFn(currentContent)
  const isIndexEmailContent = isIndexEmailContentFn(currentContent)

  const isNavigationEnabled = contents.length > 1
  const fileName = isIndexFileContent ? currentContent.fileName : ''
  const emailContentName = isIndexEmailContent
    ? currentContent.linkedEmailAccount?.email
    : ''
  const contentName = fileName || emailContentName

  if (!isOpen || !currentContent) {
    return null
  }

  return (
    <Styles.FadeIn duration={0.2}>
      <Styles.Container
        ref={containerRef}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <Styles.Header>
          <Styles.HorizontalContent>
            <Styles.Icon
              icon={['far', 'angle-left']}
              onClick={onClosePreview}
            />
            <Styles.FileIcon
              type={isIndexFileContent ? currentContent.fileFormat : undefined}
              src={isIndexFileContent ? currentContent.url : undefined}
              showImagePreview={false}
              small
              isAnImage={typeChecker.isAnImage}
              isEmailContent={isIndexEmailContent}
            />
            <Styles.FileName isAPDFFile={typeChecker.isAPdf}>
              <Tooltip
                id={contentName}
                text={contentName}
                place="bottom"
                overridePosition={({ top }) => ({
                  top,
                  left: 110,
                })}
                wordBreakAll
              >
                {contentName}
              </Tooltip>
            </Styles.FileName>
          </Styles.HorizontalContent>
          <Styles.HorizontalContent>
            {isIndexFileContent && (
              <Styles.Circle
                onClick={() => onDownloadFile(currentContent)}
                withMargin
              >
                <Styles.Icon icon={['far', 'download']} />
              </Styles.Circle>
            )}
            <Styles.Icon icon={['far', 'times']} onClick={onClosePreview} />
          </Styles.HorizontalContent>
        </Styles.Header>
        <Styles.Content>
          {isNavigationEnabled && (
            <Styles.Circle onClick={previousContent} disabled={isFirstContent}>
              <Styles.Angle icon={['far', 'chevron-left']} />
            </Styles.Circle>
          )}
          <Styles.PreviewContainer
            ref={handleContentPreviewContainerRef}
            onClick={onFilePreviewContainerClick}
          >
            {isIndexFileContent && (
              <PreviewFile
                file={currentContent}
                onDownloadFile={onDownloadFile}
              />
            )}
            {isIndexEmailContent && (
              <PreviewContent
                isLoading={isLoading}
                emailContent={currentContent}
                previewContainer={contentPreviewContainer}
              />
            )}
          </Styles.PreviewContainer>
          {isNavigationEnabled && (
            <Styles.Circle onClick={nextContent} disabled={isLastContent}>
              <Styles.Angle icon={['far', 'chevron-right']} />
            </Styles.Circle>
          )}
        </Styles.Content>
      </Styles.Container>
    </Styles.FadeIn>
  )
}

export default PreviewContents
