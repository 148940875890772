import { AssistanceCardType } from 'utils/types/onboardingModal'

export default {
  gettingStarted: 'GETTING STARTED',
  welcome: 'Welcome to Clockwork Universe, {userName}',
  welcomeSubtitle:
    "Let's begin by setting up and incorporating the essential information into the product, allowing you to maximize its potential.",
  trialMessage:
    'To better use your 14 days trial, these tasks will help you get started',
  completeTasks: 'Complete these tasks to help you get started',
  letsStart: "Let's start",
  setupYourPipeline: 'Setup your pipeline',
  setupYourPipelineDescription:
    'Kick off your pipeline arrangement by adding all your holdings.',
  addYourFiles: 'Add your files',
  addYourFilesDescription:
    'Upload your files and begin the process of organizing them into updates.',
  setupYourInbound: 'Setup your inbound',
  setupYourInboundDescription:
    'Learn about your Clockwork group email and start to receive suggested updates.',
  assistanceQuestion:
    'Do you need assistance in understanding certain concepts and how to use the product?',
  help: {
    [AssistanceCardType.PIPELINE]: 'What is a pipeline?',
    [AssistanceCardType.HOLDING]: 'What is a holding?',
    [AssistanceCardType.UPDATES]: 'What are updates?',
    [AssistanceCardType.UPDATE]: 'What is an update?',
    [AssistanceCardType.CREATE_UPDATE]: 'How to create an update?',
    [AssistanceCardType.INBOUND]: 'What is an inbound?',
    [AssistanceCardType.SUGGESTED_UPDATE]: 'What is a suggested update?',
    [AssistanceCardType.UPLOAD_FILES]: 'How to upload files?',
    [AssistanceCardType.CHAT]: 'Who can I chat with?',
    [AssistanceCardType.INVEST_PORTFOLIO]: 'What is an invest portfolio?',
  },
  onboardingBarText:
    'Ready to unleash the full potential of Clockwork Universe? Complete these 3 initial steps and start maximizing your collaboration and decision making. 💫🚀',
  dropYourFilesHere:
    'Drop files to upload them to Clockwork Universe or <span>browse your files</span>',
  inbound: {
    redirectEmails:
      'Redirect emails from your personal mailbox to the following email address:',
    test: 'Send a test email to the email address above',
    connectStepInfo:
      'Every email sent to this email address will be analyzed and transformed into suggested updates.',
    receivedEmailSuccessfully:
      'Your email has been received, and your first suggested update is ready for approval. ',
    nextStepDescription:
      "After clicking 'Finish,' proceed to the Updates screen to categorize the suggested update.",
  },
  helpDesc: {
    [AssistanceCardType.PIPELINE]:
      "Pipeline portfolios allow you to track holdings under a specific stage or criteria. It's a good way to follow holdings and not miss any updates.",
    [AssistanceCardType.HOLDING]:
      'Holdings in the platform are spaces that can be added to a portfolio. They work as a data rooms where groups can add updates and metrics.',
    [AssistanceCardType.UPDATES]:
      'An update is any form of communication that can be sent through the platform. In-app updates include Announcements, Documents, Notes and Transactions. ',
    [AssistanceCardType.UPDATE]:
      'An update is any form of communication that can be sent through the platform. In-app updates include Announcements, Documents, Notes and Transactions. ',
    [AssistanceCardType.UPLOAD_FILES]:
      "You have two options for uploading files: use the 'Browse files' option from the “Celeste” button or simply drag and drop the files onto this screen.",
    [AssistanceCardType.CHAT]:
      'The Universe chat feature enables you to quickly message other users on the platform and create conversations around specific topics.',
    [AssistanceCardType.INVEST_PORTFOLIO]:
      'Invest Portfolios let you group and track holdings and provide a summary with key calculations based on transactions and Carrying Values.',
  },
  learnMore: 'Learn More',
}
