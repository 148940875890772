import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'

export const TableWrapper = styled.div`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${(props) =>
    props.embbeddedTable &&
    css`
      padding-left: 3.6rem;
      padding-bottom: 1rem;

      div[id*='expandableRow']:last-child {
        border-bottom: none;
      }
    `};

  label[class^='Checkbox_checkboxContainer'] {
    margin-bottom: 0%;
  }
`

export const TableHeader = styled.div`
  z-index: 1;
  color: #787e8c;
  display: flex;
  align-items: center;
  min-height: ${(props) => props.headerHeight || '3.5rem'};
  font-weight: bold;
  top: 0;
  padding: 0.2rem 1.5rem;
  border-bottom: 0.2rem solid #e9e9e9;
  font-weight: 800;
  font-size: 1.1rem;
  ${(props) =>
    props.embeddedTable &&
    css`
      padding: 1rem 1.5rem;
      border-bottom: 0.2rem solid white;
    `}
`

export const TableBody = styled.div`
  position: relative;
  font-size: 1.3rem;
`

export const NoResultsWrapper = styled.div``

export const NoResultsLabel = styled.div`
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 12rem;
`

export const ExpandIcon = styled(FontAwesomeIcon)`
  font-size: 2.3rem;
  color: ${color('darkBlue')};
  cursor: pointer;
`

export const LoaderContainer = styled.div`
  height: 11.8rem;
`
