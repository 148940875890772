import { type AuthorizationParams, useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { scope } from 'utils/constants/auth0'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuthentication } from 'utils/hooks/auth'
import { useCustomLogin } from 'utils/hooks/useCustomLogin'
import useQueryString from 'utils/hooks/useQueryString'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCustomApiEnv, getDevEnvironment } from 'features/preferenceSlice'
import { getApiUrl } from 'api/httpClient'
import { isDesktopApp, openUrl } from 'utils/functions/electron'
import { getWebAppUrl } from 'utils/functions/env'
import DesktopSignIn from '../DesktopSignIn'

type Props = {
  isSignup?: boolean
}
const SignInAuth0 = ({ isSignup }: Props) => {
  const history = useHistory()
  const email = useQueryString('email')

  const { loginWithRedirect } = useAuth0()
  const { isAuthenticated } = useAuthentication()
  const { isCustomLogin, color, loginImage, loginLogo, isLoadingCustomLogin } =
    useCustomLogin()
  const devEnvironment = useAppSelector(getDevEnvironment)
  const apiEnvironment = useAppSelector(getCustomApiEnv)

  const location = useLocation<{ previewMode }>()
  const handleLogin = async (authorizationParams?: AuthorizationParams) => {
    const defaultApiUrl = getApiUrl(
      process.env.REACT_APP_API_URL,
      apiEnvironment
    )

    await loginWithRedirect({
      authorizationParams: {
        scope,
        prompt: 'login',
        environment: devEnvironment,
        apiUrl: defaultApiUrl,
        login_hint: email || undefined,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        screen_hint: isSignup ? 'signup' : undefined,
        'ext-is-custom-login': isCustomLogin ? 'true' : 'false',
        'ext-color': color?.replace('#', ''), // Parameters cannot include # symbol, must be added in the template
        'ext-logo': loginLogo?.url?.includes('missing.png')
          ? undefined
          : loginLogo?.url,
        'ext-background-logo': loginImage?.url?.includes('missing.png')
          ? undefined
          : loginImage?.url,
        'ext-intercom-app-id': process.env.REACT_APP_INTERCOM_APP_ID,
        redirect_uri: isDesktopApp()
          ? `${getWebAppUrl()}/desktop-redirect`
          : undefined,
        ...authorizationParams,
      },
      openUrl: isDesktopApp() ? (url) => openUrl(url) : undefined,
    })
  }

  useEffect(() => {
    if (isCustomLogin && isLoadingCustomLogin) return

    if (isAuthenticated && !location.state?.previewMode) {
      history.replace('/')
    } else if (!isDesktopApp()) {
      handleLogin()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isCustomLogin, isLoadingCustomLogin])

  return isDesktopApp() ? <DesktopSignIn handleLogin={handleLogin} /> : null
}

export default SignInAuth0
