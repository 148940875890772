import React, { useId } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import {
  setInitialLinkRedirect,
  setShowInvestorInitialLink,
} from 'reducers/breadcrumbSlice'
import { useIntl } from 'react-intl'
import GroupLogo from 'components/GroupLogo'
import Tooltip from 'components/Tooltip/Tooltip'
import { InvestorGroup } from 'utils/types/user'
import * as Styles from './AccountManaged.styles'

interface AccountManagedProps {
  group: InvestorGroup
}

const AccountManaged: React.FC<AccountManagedProps> = ({ group }) => {
  const intl = useIntl()
  const history = useHistory()
  const id = useId()
  const dispatch = useAppDispatch()

  const handleGroupClick = () => {
    const urlToRedirect = `/fund-holdings/companies/${group.parentGroup.fundCompanyId}/updates`

    dispatch(setShowInvestorInitialLink(false))
    dispatch(setInitialLinkRedirect(urlToRedirect))

    history.push(urlToRedirect)
  }

  return (
    <>
      <Styles.Container>
        <Styles.Title>
          {intl.formatMessage({ id: 'sidebar.accountManagedBy' })}:
        </Styles.Title>
        <Styles.GroupLink onClick={handleGroupClick}>
          <Styles.LogoContainer>
            <GroupLogo
              imageUrl={group.parentGroup.logo.smallLogo.url}
              name={group.parentGroup.name}
              forceInitials={false}
              small
            />
          </Styles.LogoContainer>
          <Tooltip
            id={id}
            text={group.parentGroup.name}
            widthAuto
            delayShow={500}
            place="top"
          >
            <Styles.Name>{group.parentGroup.name}</Styles.Name>
          </Tooltip>
        </Styles.GroupLink>
      </Styles.Container>
      <Styles.Line />
    </>
  )
}

export default AccountManaged
