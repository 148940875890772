import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import CompanyService from 'api/CompanyService'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'

import Title from 'components/Title'
import Toast from 'components/Toast'
import Dropdown from 'ui/Dropdown'
import Input from 'ui/Input'

import { isAmerica, USA } from 'utils/constants/countries'

import * as Styles from '../common/styles'

const filterArrayByName = (arrayToFilter, name) =>
  Object.values(arrayToFilter).filter(({ name: value }) => name === value)?.[0]
    ?.id

const CompanyLegalStructure = ({
  companyData,
  legalStructures,
  countries,
  provinces,
  onChange,
}) => {
  const [legalEntityName, debouncedLegalEntityName, setLegalEntityName] =
    useDebouncedState(companyData?.legalEntityName, 500)

  const intl = useIntl()

  const getIdByName = (type, value) => {
    switch (type) {
      case 'legalEntityName':
        return value
      case 'legalStructure':
        return filterArrayByName(legalStructures, value)
      case 'legalCountry':
        return filterArrayByName(countries, value)
      case 'legalProvince':
        return filterArrayByName(provinces, value)
      default:
        return null
    }
  }

  const getBody = (key, value) => {
    const data = getIdByName(key, value)
    if (!data) return null

    const rest =
      key === 'legalCountry' &&
      countries.find((country) => country.id === companyData?.legalCountry)
        ?.name !== value
        ? { legalProvinceId: null }
        : {}

    if (key === 'legalEntityName') {
      return { [key]: value, ...rest }
    }

    return {
      [`${key}Id`]: data,
      ...rest,
    }
  }

  const updateLegal = async ({ key, value }) => {
    const body = value ? getBody(key, value) : { [key]: '' }
    if (body) {
      try {
        const {
          data: {
            entities: { companyData: data },
          },
        } = await CompanyService.editCompany(companyData.id, body)
        onChange({ companyData: data })
      } catch (errors) {
        Toast.display(
          intl.formatMessage(
            { id: 'errors.addItemError' },
            {
              name: value || '',
              item: intl.formatMessage({ id: 'general.legalStructure' }),
            }
          ),
          'error'
        )
      }
    }
  }

  const selectedCountryIsAmerica = isAmerica(
    countries.find((country) => country.id === companyData?.legalCountry)
  )

  useEffect(() => {
    if (debouncedLegalEntityName !== companyData?.legalEntityName) {
      updateLegal({ key: 'legalEntityName', value: debouncedLegalEntityName })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyData?.legalEntityName, debouncedLegalEntityName])

  return (
    <Styles.Section>
      <Title title={intl.formatMessage({ id: 'editCompany.legalStructure' })} />

      <Styles.Item>
        <Input
          placeholder={intl.formatMessage({
            id: 'editCompany.legalEntityNamePlaceholder',
          })}
          label={intl.formatMessage({
            id: 'additionalInformation.legalEntityName',
          })}
          id="legalEntityName"
          value={legalEntityName}
          onChange={(event) => {
            setLegalEntityName(event.target.value)
          }}
        />
      </Styles.Item>
      <Styles.Item>
        <Dropdown
          type="select"
          async
          placeholder={intl.formatMessage({
            id: 'editCompany.legalStructurePlaceholder',
          })}
          label={intl.formatMessage({
            id: 'additionalInformation.legalStructure',
          })}
          loadOptions={(searchValue) =>
            legalStructures
              ?.filter(
                (option) =>
                  option.name
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0
              )
              .map((option) => ({
                ...option,
                label: option.name,
              }))
              .sort((a, b) => (a.name < b.name ? -1 : 1))
          }
          getOption={(structure) => ({
            ...structure,
            label: structure.name,
          })}
          value={legalStructures.find((legalStructure) => {
            return (
              legalStructure.id === companyData?.legalStructure ||
              legalStructure.id === companyData?.legalStructure?.id
            )
          })}
          onSelectOption={(_, name, value) =>
            updateLegal({ key: 'legalStructure', value: value.name })
          }
        />
      </Styles.Item>

      <Styles.Item>
        <Dropdown
          type="select"
          async
          placeholder={intl.formatMessage({
            id: 'editCompany.legalCountryPlaceholder',
          })}
          label={intl.formatMessage({
            id: 'additionalInformation.legalCountry',
          })}
          loadOptions={(searchValue) =>
            countries
              ?.filter(
                (option) =>
                  option.name
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0
              )
              .map((option) => ({
                ...option,
                label: option.name,
              }))
          }
          getOption={(country) => ({
            ...country,
            label: country.name,
          })}
          value={countries.find(
            (country) => country.id === companyData?.legalCountry
          )}
          onSelectOption={(_, name, value) =>
            updateLegal({ key: 'legalCountry', value: value.name })
          }
        />
      </Styles.Item>

      <Styles.Item>
        <Dropdown
          type="select"
          async
          placeholder={intl.formatMessage({
            id: 'editCompany.legalStatePlaceholder',
          })}
          label={intl.formatMessage({ id: 'additionalInformation.legalState' })}
          loadOptions={(searchValue) =>
            provinces
              ?.filter(
                (option) =>
                  option.name
                    .toLowerCase()
                    .indexOf(searchValue.toLowerCase()) >= 0
              )
              .map((option) => ({
                ...option,
                label: option.name,
              }))
          }
          getOption={(state) => ({
            ...state,
            label: state.name,
          })}
          value={
            selectedCountryIsAmerica
              ? provinces.find(
                  (province) => province.id === companyData?.legalProvince
                )
              : { name: '' }
          }
          onSelectOption={(_, name, value) =>
            updateLegal({ key: 'legalProvince', value: value.name })
          }
          disabled={!selectedCountryIsAmerica}
        />
      </Styles.Item>
    </Styles.Section>
  )
}

CompanyLegalStructure.propTypes = {
  companyData: PropTypes.object,
  legalStructures: PropTypes.object,
  countries: PropTypes.object,
  provinces: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

CompanyLegalStructure.defaultProps = {
  companyData: null,
  legalStructures: {},
  countries: {},
  provinces: {},
}

export default CompanyLegalStructure
