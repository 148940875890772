import type { UseQueryOptions } from '@tanstack/react-query'
import type { Country } from 'react-phone-number-input'
import { ReshareSettingsType } from 'containers/Updates/ReshareSettings/ReshareSettings'
import React from 'react'
import { EventLogTypes } from 'utils/constants/eventLogs'
import { SortDirection } from 'utils/constants/sortDirection'
import {
  PlainUpdateTypes,
  UpdateTypes,
  UpdateVisibility,
} from 'utils/constants/updates'
import { UpdateAttachment } from './transactions'
import { Tag } from './update'

export type Nullable<T> = T | null

export type ReactFCWithChildren<T = {}> = React.FC<
  T & {
    children?: React.ReactNode
  }
>

export type ValueOf<T> = T[keyof T]

export type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>

export type Modify<T, R> = Omit<T, keyof R> & R

export enum ErrorType {
  ERROR = 'error',
  WARNING = 'warning',
  REQUIRED = 'required',
  MESSAGE = 'message',
}

export type IError = {
  type: ErrorType
  message: string
}

export interface Logo {
  url: string
}

export interface Image {
  largeLogo: Logo
  mediumLogo: Logo
  smallLogo: Logo
  url: string
}

export type PlainUpdateType = ValueOf<typeof PlainUpdateTypes>

export type UpdateVisibilityType = ValueOf<typeof UpdateVisibility>

export type UpdateType = ValueOf<typeof UpdateTypes>
export type SuggestedUpdateType = UpdateType | 'suggested'

export type EventLogType = ValueOf<typeof EventLogTypes>

export type DropdownOption = {
  id: string
  label: string
  icon: string[]
  onSelectOption: () => void
}

export type SetupUpdateRequestDataParams = {
  title?: string
  body?: string
  date?: Date
  visibility: string
  sharedGroups?: string[]
  sharedUsers?: string[]
  removedGroups?: string[]
  removedUsers?: string[]
  ownByGroup: boolean
  uploads?: UpdateAttachment[]
  entity: string
  updateName?: string
  isEditingUpdate: boolean
  tags: (Tag & {
    alreadyExists?: boolean
    destroy?: boolean
  })[]
  removeTags?: boolean
  schedule?: {
    id?: string
    scheduleType?: string
    repetition?: string
    date?: Date
    destroy?: boolean
  }
  reportParams?: any
  repetition?: string
  reportType?: string
  reshareSettings: ReshareSettingsType
  isFounder?: boolean
}

export enum PermissionItemType {
  GROUP = 'group',
  USER = 'user',
  EMAIL = 'email',
}

export type PermissionItem = {
  type: PermissionItemType
  name: string
  id: string
  logo?: Image
  canDelete?: boolean
}

export interface UpdatableEntity {
  id: string
  createdAt: Date
  updatedAt: Date
  name: string
  primaryLogo: Image
}

export type UpdatePermissions = {
  hasEditPermissions: boolean
  isEditable: boolean
  isContentEditable: boolean
  contentHasFullPermissions: boolean
  isPermissionsEditable: boolean
  canEditReshare: boolean
  canReshare: boolean
  groupOwnedDisabled: boolean
  canDowngradePermissions: boolean
  setAsDraftVisible: boolean
  canDeleteUpdate: boolean
}

export type SortDirectionType = ValueOf<typeof SortDirection>

export type MakeOptional<Type, Key extends keyof Type> = Omit<Type, Key> &
  Partial<Pick<Type, Key>>

export interface DraftValidation<T> {
  errors: {
    [key in keyof T]?: string[]
  }
  valid: boolean
  object: T
}

export type PropertyMapper<T, U> = { [key in keyof T]?: keyof U }

export interface CustomUseQueryOptions<T>
  extends Omit<UseQueryOptions<T>, 'initialData'> {}

export type ReactChildren = React.ReactNode | React.ReactNode[]
export type CountryCode = Country

export interface ElementSize {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}
