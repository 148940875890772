import { FC } from 'react'
import { Redirect } from 'react-router-dom'
import { hasEditPermissions } from 'utils/functions/update-permissions'
import { UpdateType } from 'utils/types/common'
import { Update } from 'utils/types/update'
import Toast from 'components/Toast'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupName } from 'selectors/auth'
import CreateWrapper, { CreateWrapperProps } from '../CreateWrapper'

interface Props extends CreateWrapperProps {
  isLoading: boolean
  children: JSX.Element
  title: string
  update?: Update
  updateType?: UpdateType
}

const EditWrapper: FC<Props> = ({
  isLoading,
  children,
  title,
  update,
  updateType,
}) => {
  const currentGroupName = useAppSelector(getCurrentGroupName)

  if (!isLoading && update && !hasEditPermissions(update)) {
    Toast.displayIntl(
      [
        'errors.groupNoAccessEdit',
        {
          name: updateType,
          groupName: currentGroupName,
        },
      ],
      'error'
    )
    return <Redirect to="/updates" />
  }

  return (
    <CreateWrapper title={title} isLoading={isLoading}>
      {children}
    </CreateWrapper>
  )
}

export default EditWrapper
