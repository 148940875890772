import { createContext, useContext, useMemo } from 'react'
import { Channel } from 'stream-chat'
import {
  MessageSearchResult,
  useSearchChannels,
  useSearchMessages,
  useSearchParticipants,
} from 'utils/hooks/chat/chatSearchQueries'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'
import { ReactFCWithChildren } from 'utils/types/common'
import { StreamChatType } from '../types'

type ChatSearchContextType = {
  search: string
  parsedSearchTexts: string[]
  debouncedSearch: string
  setSearch: (search: string) => void
  messagesResults: MessageSearchResult
  isLoadingMessages: boolean
  channelResults: Channel<StreamChatType>[]
  isLoadingChannels: boolean
  participantResults: Channel<StreamChatType>[]
  isLoadingParticipants: boolean
}

const ChatSearchContext = createContext<ChatSearchContextType>(
  {} as ChatSearchContextType
)

export const useChatSearchContext = () => useContext(ChatSearchContext)

export const ChatSearchContextProvider: ReactFCWithChildren = ({
  children,
}) => {
  const [search, debouncedSearch, setSearch] = useDebouncedState('')

  const parsedSearchTexts = useMemo(() => {
    return debouncedSearch.split(/ ?[,] ?| /)
  }, [debouncedSearch])

  const {
    data: messagesResults = [] as MessageSearchResult,
    isLoading: isLoadingMessages,
  } = useSearchMessages(debouncedSearch)

  const { data: channelResults = [], isLoading: isLoadingChannels } =
    useSearchChannels(debouncedSearch)

  const { data: participantResults = [], isLoading: isLoadingParticipants } =
    useSearchParticipants(parsedSearchTexts)

  const value = useMemo(
    () => ({
      search,
      parsedSearchTexts,
      debouncedSearch,
      setSearch,
      messagesResults,
      isLoadingMessages,
      channelResults,
      isLoadingChannels,
      participantResults,
      isLoadingParticipants,
    }),
    [
      search,
      parsedSearchTexts,
      debouncedSearch,
      setSearch,
      messagesResults,
      isLoadingMessages,
      channelResults,
      isLoadingChannels,
      participantResults,
      isLoadingParticipants,
    ]
  )

  return (
    <ChatSearchContext.Provider value={value}>
      {children}
    </ChatSearchContext.Provider>
  )
}
