import { useField } from 'formik'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isActingAsInvestorGroup } from 'selectors/auth'

import type { DropdownRef } from 'ui/Dropdown'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { usePortfoliosAndHoldingsQuery } from 'utils/hooks/queries/usePortfoliosAndHoldingsQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { TopicEntity } from 'utils/types/chatTopicEntity'

type Props = {
  name: string
  queryPortfolios?: boolean
  queryHoldings?: boolean
  portfoliosQuery?: PortfolioTypes[]
}

export const useTopicEntityDropdown = ({
  name,
  queryPortfolios = true,
  queryHoldings = true,
  portfoliosQuery,
}: Props) => {
  const dropdownRef = useRef<DropdownRef>(null)
  const [field, , helper] = useField<TopicEntity | undefined>(name)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const [isDropdownClosed, setIsDropdownClosed] = useState(true)

  const {
    data: topicEntities,
    refetchData,
    isFetching,
  } = usePortfoliosAndHoldingsQuery({
    queryHoldings: queryHoldings && !isInvestorGroup,
    queryPortfolios,
    portfoliosQuery,
  })

  const onSelectOption = useCallback(
    (_, __, value) => {
      helper.setValue(value)
    },
    [helper]
  )

  const clearSelection = useCallback(() => {
    dropdownRef.current?.clear()
    helper.setValue(undefined)
  }, [helper])

  const onCloseDropdown = useCallback(() => {
    setIsDropdownClosed(true)
  }, [])

  const onOpenDropdown = useCallback(() => {
    setIsDropdownClosed(false)
  }, [])

  useEffect(() => {
    if (!isFetching) {
      dropdownRef.current?.setLoadingOptions(isFetching)
    }
  }, [isFetching])

  return {
    dropdownRef,
    topicEntities,
    hasValue: !!field.value,
    value: isDropdownClosed ? field.value : undefined,
    isFetching,
    clearSelection,
    onSelectOption,
    refetchData,
    onCloseDropdown,
    onOpenDropdown,
  }
}
