import { DraftHashEditor, Update } from 'utils/types/update'
import UpdateVisibility from 'containers/UpdatesV2/components/UpdateHeader/components/UpdateVisibility'
import { CreateUpdateGlobalProps } from 'utils/types/updateForm'
import ShareSettingsButton from 'containers/UpdatesV2/components/UpdateHeader/components/ShareSettingsButton'
import { useIsPreviewingDraftUpdate } from 'utils/hooks/useIsPreviewingDraftUpdate'
import { Divider, ShareActionsWrapper } from '../UpdateActions.styles'
import useShareSettingsAction from './useShareSettingsAction'

interface ShareSettingsActionProps {
  hasEditPermissions: boolean
  isCreateMode: boolean
  update: Update
  values: CreateUpdateGlobalProps
  isSubmitting: boolean
  isEditMode: boolean
  isShowMode: boolean
  isEditingDraft: boolean
  dividerColor: string
  handleOpenShareSettingsModal: () => void
}

const ShareSettingsAction = ({
  hasEditPermissions,
  isCreateMode,
  values,
  update,
  isSubmitting,
  isEditMode,
  isShowMode,
  isEditingDraft,
  dividerColor,
  handleOpenShareSettingsModal,
}: ShareSettingsActionProps) => {
  const isPreviewingDraft = useIsPreviewingDraftUpdate()

  const {
    isShareSettingsEditable,
    disableSettingsOnTransaction,
    defaultGroupsPermissions,
    groupsAndUsersPermissions,
  } = useShareSettingsAction({
    hasEditPermissions,
    isCreateMode,
    update,
    values,
    isEditMode,
    isShowMode,
  })

  const isDraftUpdate = update?.isDraftUpdate

  const disableShareSettingsButton =
    disableSettingsOnTransaction ||
    isSubmitting ||
    (isDraftUpdate && !isPreviewingDraft && !isEditingDraft)

  if (!isShareSettingsEditable) return null

  return (
    <>
      <ShareActionsWrapper>
        <UpdateVisibility
          defaultGroupsPermissions={defaultGroupsPermissions}
          groupsAndUsersPermissions={groupsAndUsersPermissions}
          disabled={isDraftUpdate && !isPreviewingDraft && !isEditingDraft}
        />

        <ShareSettingsButton
          handleOpenShareSettingsModal={handleOpenShareSettingsModal}
          disabled={disableShareSettingsButton}
          editionLocked={update?.draftHash as DraftHashEditor}
        />
      </ShareActionsWrapper>

      <Divider color={dividerColor} />
    </>
  )
}

export default ShareSettingsAction
