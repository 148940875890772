/* eslint-disable no-nested-ternary, consistent-return, no-else-return */
import React, { useCallback, useRef, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { useIntl } from 'react-intl'
import Proptypes from 'prop-types'
import uniq from 'lodash/uniq'
import differenceWith from 'lodash/differenceWith'

import UserService from 'api/UserService'
import GroupService from 'api/GroupService'
import Toast from 'components/Toast'
import { EMAIL_REGEX, HANDLE_REGEX } from 'utils/functions/regex'
import { isValidEmail } from 'utils/functions/utils'
import Dropdown from 'ui/Dropdown/Dropdown'
import { getCurrentGroupData, isActingAsFounder } from 'selectors/auth'
import { getCurrentCompany } from 'selectors/company'
import { searchMatchesGroup } from 'utils/functions/updates'
import { EntityType, GroupTypes } from 'utils/constants/groups'

import { Tag } from './GroupsUsersAddInput.styles'
import DropdownEntity from './DropdownEntity'

const INITIAL_PAGE = 0
const PAGE_SIZE = 25

const GroupUsersAddInput = ({
  handleAddGroup,
  handleAddUser,
  handleBulkAddUsers,
  handleBulkAddGroups,
  items,
  onlyUsers,
  showLabel,
  className,
  id: idProp,
  clearOnSelect,
  allowBulkAddUsers,
  maxBulkEmails,
  onAddMoreEmailsThanAllowed,
  customLabel,
  showOptionsTitle,
  customPlaceholder,
  dataTestId,
  ...rest
}) => {
  const intl = useIntl()
  const addEntityRef = useRef(null)
  const [error, setError] = useState(null)
  const [showResultsTitle, setShowResultsTitle] = useState(true)
  const isFounder = useAppSelector(isActingAsFounder)
  const currentCompany = useAppSelector(getCurrentCompany)
  const currentGroup = useAppSelector(getCurrentGroupData)

  const isItemAlreadyAddedToList = (value) =>
    !!items.find(({ email, handle }) => value === handle || value === email)

  const groupOwner = isFounder ? currentGroup : currentCompany?.owner

  const searchUsersAndGroupsByHandle = async (handle) => {
    const normalizedSearch = handle.replace('@', '')
    try {
      if (normalizedSearch.length) {
        return await GroupService.getGroupByHandle(normalizedSearch)
      }
      return null
    } catch (err) {
      return UserService.getUserByHandle(normalizedSearch)
    }
  }

  const fetchMostRecentGroupsAndUsers = useCallback(
    async (searchValue) => {
      try {
        let keyword = searchValue
        if (HANDLE_REGEX.test(searchValue)) {
          keyword = keyword.substr(1)
        }

        const groups = await GroupService.getGroupsAndUsers(
          INITIAL_PAGE,
          PAGE_SIZE,
          {
            keyword,
          }
        )
        const notIncludedInResults = (id) =>
          !groups.some((group) => group.id === id)

        if (
          groupOwner &&
          searchMatchesGroup(groupOwner, searchValue) &&
          notIncludedInResults(groupOwner.id)
        ) {
          groups.push(groupOwner)
        }

        if (HANDLE_REGEX.test(searchValue)) {
          const groupOrUser = await searchUsersAndGroupsByHandle(searchValue)

          if (
            groupOrUser &&
            notIncludedInResults(groupOrUser.id) &&
            (!groupOrUser.type ||
              groupOrUser.type === GroupTypes.INVESTOR_GROUP ||
              groupOrUser.type === GroupTypes.CLIENT ||
              groupOrUser.type === GroupTypes.FUND_MANAGER ||
              groupOrUser.id === groupOwner.id)
          ) {
            groups.push(groupOrUser)
          }
        } else if (EMAIL_REGEX.test(searchValue)) {
          const user = await UserService.getUserByEmail(searchValue)
          if (user && notIncludedInResults(user.id)) {
            groups.push(user)
          }
        }
        return groups
      } catch (err) {
        return []
      }
    },
    [groupOwner]
  )

  const fetchGroups = useCallback(async (searchValue) => {
    try {
      const groups = await GroupService.getClientGroups(0, 3, {
        keyword: searchValue,
      })

      return groups
    } catch {
      return []
    }
  }, [])

  const fetchUsers = useCallback(async (searchValue, pageSize = 3) => {
    try {
      const groups = await GroupService.getRecentUsers(0, pageSize, {
        keyword: searchValue,
      })
      return groups
    } catch {
      return []
    }
  }, [])

  const fetchData = useCallback(
    async (searchValue) => {
      if (onlyUsers) {
        return fetchUsers(searchValue, PAGE_SIZE)
      }

      if (!searchValue) {
        const [groups, users] = await Promise.all([fetchGroups(), fetchUsers()])
        setShowResultsTitle(true)

        if (groupOwner && !groups.some((group) => group.id === groupOwner.id)) {
          return [...groups, groupOwner, ...users]
        } else {
          return [...groups, ...users]
        }
      }
      setShowResultsTitle(false)
      const result = await fetchMostRecentGroupsAndUsers(searchValue)

      return result
    },
    [
      fetchGroups,
      fetchMostRecentGroupsAndUsers,
      fetchUsers,
      groupOwner,
      onlyUsers,
    ]
  )

  const clearDropdown = () => {
    addEntityRef.current?.clear()
    addEntityRef.current?.close()
  }

  const addUserByEmail = async (email) => {
    if (isValidEmail(email)) {
      try {
        if (isItemAlreadyAddedToList(email)) {
          return Promise.resolve()
        }
        const [user] = await UserService.bulkCreateUser({ emails: [email] })
        handleAddUser(user, { ...rest })
      } catch (err) {
        Toast.display(intl.messages['errors.addingUserToList'], 'error')
        return Promise.resolve()
      }
      addEntityRef.current?.clear()
      addEntityRef.current?.close()
    } else {
      addEntityRef.current?.close()
      addEntityRef.current?.setValue(email)
    }
  }

  const bulkAddUserByEmail = async (emails) => {
    const emailsToAdd =
      maxBulkEmails !== null ? emails.slice(0, maxBulkEmails) : emails

    if (maxBulkEmails !== null && emails.length > maxBulkEmails) {
      onAddMoreEmailsThanAllowed(emails)
    }

    const validEmails = emailsToAdd.filter(isValidEmail)

    if (validEmails.length) {
      const alreadyAddedEmails = validEmails.filter(isItemAlreadyAddedToList)
      alreadyAddedEmails.forEach((email) => {
        setError(
          intl.formatMessage(
            { id: 'updates.userAlreadyAdded' },
            { name: email }
          )
        )
      })

      const newEmails = validEmails.filter(
        (email) => !isItemAlreadyAddedToList(email)
      )
      try {
        const groups = await GroupService.getGroupsByEmails(newEmails)
        const userEmails = differenceWith(
          newEmails,
          groups,
          (email, group) => group.email === email
        )
        const newUsers = await UserService.bulkCreateUser({
          emails: userEmails,
        })
        handleBulkAddUsers(newUsers, { ...rest })
        handleBulkAddGroups(groups)
      } catch (err) {
        Toast.display(intl.messages['errors.addingUserToList'], 'error')
      }
    }
    if (validEmails.length < emailsToAdd.length) {
      const invalidEmails = emailsToAdd.filter((email) => !isValidEmail(email))
      addEntityRef.current?.setValue(invalidEmails.join(', '))
      setError(
        intl.formatMessage({
          id: onlyUsers
            ? 'updates.invalidUserFormat'
            : 'updates.invalidGroupUserFormat',
        })
      )
      addEntityRef.current?.close()
    } else {
      addEntityRef.current?.clear()
      addEntityRef.current?.close()
    }
  }

  const getEntityOption = (entity) => ({
    value: {
      id: entity.id,
      type: entity.type === 'User' || !entity.type ? 'User' : 'Group',
      name: entity.name,
      email: entity.email,
      handle: entity.handle,
      logo: entity.logo?.url,
      unreachableEmailAddress: entity.unreachableEmailAddress,
    },
    label: entity.name || entity.email,
    id: entity.id,
  })

  const addGroupOrUser = async (rawOption) => {
    const { value: entity } = getEntityOption(rawOption)
    setError(null)
    if (entity.type === EntityType.USER) {
      handleAddUser(rawOption)
    } else if (
      entity.type !== GroupTypes.FOUNDER ||
      entity.id === groupOwner.id
    ) {
      handleAddGroup(rawOption)
    }
    setTimeout(() => {
      clearDropdown()
    }, 100)
  }

  const handlePressEnter = (inputValue, dropdownOptions, selectedOption) => {
    if (selectedOption) {
      addGroupOrUser(selectedOption)
    } else if (
      dropdownOptions.length === 1 &&
      searchMatchesGroup(dropdownOptions[0], inputValue)
    ) {
      addGroupOrUser(dropdownOptions?.[0])
    } else if (!HANDLE_REGEX.test(inputValue)) {
      const emails = uniq(
        inputValue
          ?.split(/ ?[,;] ?| /)
          .map((value) => value.trim())
          .filter((email) => !!email)
      )
      if (
        (emails.length === 1 || !allowBulkAddUsers) &&
        EMAIL_REGEX.test(emails[0])
      ) {
        addUserByEmail(emails[0])
      } else if (emails.length > 1) {
        bulkAddUserByEmail(emails)
      }
    }
  }

  return (
    <>
      {showLabel && !customLabel && (
        <Tag>
          {onlyUsers
            ? intl.messages['updates.addUser']
            : intl.messages['updates.addGroupsOrUser']}
        </Tag>
      )}
      {customLabel && <Tag>{customLabel}</Tag>}
      <Dropdown
        id={`group-user-add-${idProp}`}
        data-testid={dataTestId}
        ref={addEntityRef}
        name="groupUserInput"
        className={className}
        type="input"
        showIcon
        highlightEnabled
        placeholder={
          customPlaceholder ||
          (onlyUsers
            ? intl.messages['updates.lookForUsers']
            : intl.messages['updates.lookForGroupsOrUsers'])
        }
        error={error ? { message: error } : {}}
        icon={['far', 'plus']}
        iconFontSize="1.8rem"
        onPressEnter={handlePressEnter}
        onSelectOption={(name, id, rawOption) => addGroupOrUser(rawOption)}
        clearOnSelect={clearOnSelect}
        clearOnPressEnter={false}
        hideCursor={false}
        loadOptions={fetchData}
        async
        showLoadingIndicator
        maxListHeight={onlyUsers ? '30rem' : '45rem'}
        dropdownOptionPadding="0"
        getOption={getEntityOption}
        optionsTitle={
          showOptionsTitle && showResultsTitle
            ? onlyUsers
              ? intl.messages['updates.recentlyUsedUsers']
              : intl.messages['updates.recentlyUsedGroupsOrUsers']
            : null
        }
        confirmOnClickIcon
        handleOnConfirm={(_, inputValue) => {
          addGroupOrUser({ inputValue })
        }}
        formatSelectedOptionComponent={(option, isSelected) => (
          <DropdownEntity entity={option.value} isSelected={isSelected} />
        )}
        highlightSelectedOption={false}
        arrowNavigationEnabled
      />
    </>
  )
}

GroupUsersAddInput.propTypes = {
  className: Proptypes.string,
  handleAddGroup: Proptypes.func,
  handleAddUser: Proptypes.func.isRequired,
  handleBulkAddUsers: Proptypes.func,
  handleBulkAddGroups: Proptypes.func,
  onAddMoreEmailsThanAllowed: Proptypes.func,
  id: Proptypes.string.isRequired,
  items: Proptypes.array,
  onlyUsers: Proptypes.bool,
  showLabel: Proptypes.bool,
  clearOnSelect: Proptypes.bool,
  allowBulkAddUsers: Proptypes.bool,
  maxBulkEmails: Proptypes.number,
  customLabel: Proptypes.string,
  showOptionsTitle: Proptypes.bool,
  customPlaceholder: Proptypes.string,
  dataTestId: Proptypes.string,
}

GroupUsersAddInput.defaultProps = {
  className: '',
  handleAddGroup: () => {},
  handleBulkAddUsers: () => {},
  handleBulkAddGroups: () => {},
  onAddMoreEmailsThanAllowed: () => {},
  items: [],
  onlyUsers: false,
  showLabel: true,
  clearOnSelect: true,
  allowBulkAddUsers: true,
  maxBulkEmails: null,
  customLabel: '',
  showOptionsTitle: true,
  dataTestId: '',
  customPlaceholder: '',
}

export default GroupUsersAddInput
