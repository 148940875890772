import CWLoader from 'components/CWLoader'
import React, { useEffect } from 'react'
import { ReactChildren } from 'utils/types/common'

import FadeIn from 'components/FadeIn'
import { SkeletonItem } from 'components/Skeletons/Skeletons.styles'
import DrawerFooter, { DrawerFooterProps } from './DrawerFooter'
import DrawerHeader from './DrawerHeader'
import { DrawerHeaderProps } from './DrawerHeader/DrawerHeader'
import {
  Container,
  customFadeInKeyframes,
  DrawerContent,
  DrawerContentProps,
  LoadingWrapper,
  Sticky,
  StyledDrawer,
} from './Drawer.styles'

export const DEFAULT_DRAWER_WIDTH = '37.7rem'

interface DrawerProps {
  children: ReactChildren
  isLoading?: boolean
  skeleton?: React.ReactNode
  headerTitle?: string
  showHeader?: boolean
  onCloseDrawer: () => void
  isOpen: boolean
  width?: string
  onAnimationComplete?: () => void
  [x: string]: any
}

interface DrawerComposition {
  Header: React.FC<DrawerHeaderProps>
  Content: React.FC<DrawerContentProps>
  Footer: React.FC<DrawerFooterProps>
  Sticky: React.FC
}

const Drawer: React.FC<DrawerProps> & DrawerComposition = ({
  children,
  isOpen,
  skeleton,
  headerTitle,
  showHeader = true,
  onCloseDrawer,
  width = DEFAULT_DRAWER_WIDTH,
  isLoading = false,
  onAnimationComplete,
}) => {
  useEffect(() => {
    const mainLayout = document.getElementById('mainLayout')

    if (isOpen) {
      mainLayout?.classList.add('drawerOpen')
    }

    return () => mainLayout?.classList.remove('drawerOpen')
  }, [isOpen])

  const clonedChildren = React.Children.map(
    children,
    (child: React.ReactElement) => {
      if (React.isValidElement(child) && typeof child.type !== 'string') {
        if (child.type === DrawerContent) {
          return React.cloneElement<any>(child, {
            id: 'drawer-content',
          })
        }
      }
      return child
    }
  )

  return (
    <Container
      id="drawer-container"
      animate={{ width: isOpen ? width : '0rem' }}
      onAnimationComplete={onAnimationComplete}
    >
      <StyledDrawer>
        <FadeIn duration={0.6} customKeyframes={customFadeInKeyframes}>
          {showHeader && (
            <Drawer.Header
              title={
                headerTitle ?? <SkeletonItem height="1.2rem" width="9rem" />
              }
              onCloseDrawer={onCloseDrawer}
            />
          )}
          {isOpen &&
            (isLoading
              ? skeleton || (
                  <LoadingWrapper>
                    <CWLoader />
                  </LoadingWrapper>
                )
              : clonedChildren)}
        </FadeIn>
      </StyledDrawer>
    </Container>
  )
}

Drawer.Header = DrawerHeader
Drawer.Content = DrawerContent
Drawer.Footer = DrawerFooter
Drawer.Sticky = Sticky

export default Drawer
