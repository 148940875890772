import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CWLoader from 'components/CWLoader'
import { useIntl } from 'react-intl'
import InputIcon from 'ui/InputIcon'
import { FullCellTooltip } from 'components/Spreadsheet/CellTemplates/Template.styles'
import { useRandomId } from 'utils/hooks/useRandomId'
import theme from 'utils/theme'
import * as Styles from './MetricsSpreadsheetDropdown.styles'
import {
  MetricsSpreadsheetDropdownProps,
  useMetricsSpreadsheetDropdown,
} from './useMetricsSpreadsheetDropdown'

const MetricsSpreadsheetDropdown = ({
  cell,
  isInEditMode,
  initialMetrics,
  loadMetrics,
  onCellChanged,
  getCompatibleCell,
  eventHandler,
}: MetricsSpreadsheetDropdownProps) => {
  const id = useRandomId()
  const intl = useIntl()
  const {
    selectedMetric,
    isOpen,
    toCreate,
    fetchingMetric,
    dropdownRef,
    inputHeight,
    optionsRef,
    stopPropagation,
    onKeyDown,
    search,
    onSearchChange,
    onInputKeyDown,
    onClickAddOption,
    loadingMetrics,
    metrics,
    highlightedMetric,
    onSelectOption,
    onArrowClick,
    forceAutoFocus,
  } = useMetricsSpreadsheetDropdown({
    cell,
    isInEditMode,
    initialMetrics,
    loadMetrics,
    onCellChanged,
    getCompatibleCell,
    eventHandler,
  })

  return (
    <Styles.Container isInvalid={!!cell.error}>
      <FullCellTooltip
        id={id}
        text={
          cell.error
            ? intl.formatMessage({
                id: cell.error,
              })
            : null
        }
        place="bottom"
        backgroundColor={theme.colors.red}
        maxWidth={cell.width ? `${cell.width}px` : undefined}
        offset={2}
      >
        <Styles.SelectedOption
          hasMetric={!!selectedMetric}
          isOpen={isOpen}
          ref={dropdownRef}
        >
          <Styles.MetricName empty={!selectedMetric?.name}>
            {selectedMetric?.name ||
              intl.formatMessage({ id: 'metrics.update.selectMetric' })}
            {fetchingMetric && (
              <Styles.Spinner icon={['fas', 'spinner-third']} />
            )}
            {toCreate && (
              <Styles.Badge>
                {intl.formatMessage({ id: 'spreadsheet.metrics.new' })}
              </Styles.Badge>
            )}
          </Styles.MetricName>
          <Styles.IconContainer onPointerDown={onArrowClick}>
            <FontAwesomeIcon
              size="sm"
              icon={['fal', isOpen ? 'angle-up' : 'angle-down']}
            />
          </Styles.IconContainer>
        </Styles.SelectedOption>
      </FullCellTooltip>
      {isOpen && (
        <Styles.DropdownContent
          onPointerDown={stopPropagation}
          onKeyDown={onKeyDown}
          top={inputHeight}
        >
          <>
            <InputIcon
              forceShowIcon
              name="search"
              icon={['far', 'search']}
              placeholder={intl.messages['general.search']}
              value={search}
              handleChange={onSearchChange}
              autoFocus
              onKeyDown={onInputKeyDown}
              onBlur={forceAutoFocus}
            />
            {search && (
              <Styles.AddOptionButton
                type="button"
                onPointerDown={onClickAddOption}
              >
                <Styles.AddIcon icon={['fal', 'plus']} />
                <Styles.AddOptionLabel>
                  {intl.formatMessage(
                    { id: 'general.addOption' },
                    { value: search }
                  )}
                </Styles.AddOptionLabel>
              </Styles.AddOptionButton>
            )}
            {loadingMetrics ? (
              <CWLoader logoSize="4rem" />
            ) : (
              <Styles.Options ref={optionsRef}>
                {metrics.map((option) => (
                  <Styles.Option
                    key={option.id}
                    id={`dropdownOption_${option.id}`}
                    selected={option.id === selectedMetric?.id}
                    highlighted={option.id === highlightedMetric?.id}
                    onTouchStart={stopPropagation}
                    onMouseDown={() => {
                      onSelectOption(option)
                    }}
                  >
                    {option.name}
                  </Styles.Option>
                ))}
              </Styles.Options>
            )}
            {!loadingMetrics && !metrics.length && (
              <Styles.NoResults className="ignore-highlight">
                {intl.formatMessage({ id: 'general.noMatchesFound' })}
              </Styles.NoResults>
            )}
          </>
        </Styles.DropdownContent>
      )}
    </Styles.Container>
  )
}

export default MetricsSpreadsheetDropdown
