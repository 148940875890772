import React from 'react'
import { useHistory } from 'react-router-dom'
import PreviewContents from 'components/PreviewContents'
import { PlainUpdateTypes, UpdateTypes } from 'utils/constants/updates'
import { usePreviewContents } from 'utils/hooks/files/usePreviewContents'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'
import {
  DraftHashAttributes,
  EmailIndexUpdate,
  IndexUpdate,
  TransactionUpdatableAttributes,
} from 'utils/types/update'
import AccountingCard from './Cards/AccountingCard'
import DefaultUpdateCard from './Cards/DefaultUpdateCard'
import EmailCard from './Cards/EmailCard'
import TransactionCard from './Cards/TransactionCard'

interface UpdateFactoryProps {
  update: IndexUpdate
}

const normalizeUpdate = (update: IndexUpdate) => {
  if (update.isDraftUpdate) {
    if (update.type === PlainUpdateTypes.TRANSACTION) {
      const transactionDraftHash =
        update.draftHash as DraftHashAttributes<TransactionUpdatableAttributes>
      const normalizedTransaction = {
        ...update,
        contents: transactionDraftHash.contents,
        item: {
          ...update.item,
          amountInvested:
            transactionDraftHash.updatableAttributes.amountInvested,
          amountDistributed:
            transactionDraftHash.updatableAttributes.amountDistributed,
          amountCommitted:
            transactionDraftHash.updatableAttributes.amountCommitted,
        },
      }

      return normalizedTransaction
    }

    const updateDraftHash = update.draftHash as DraftHashAttributes
    const normalizedUpdate: IndexUpdate = {
      ...update,
      contents: updateDraftHash.contents,
    }

    return normalizedUpdate
  }

  return update
}

const UpdateFactory: React.FC<UpdateFactoryProps> = ({
  update: updateProp,
}) => {
  const update = React.useMemo(() => normalizeUpdate(updateProp), [updateProp])
  const history = useHistory()
  const { selectedContent, onClosePreview, onPreviewContent } =
    usePreviewContents()
  const updatePermissions = useUpdatePermissions(update)
  const isComingFromUpdatesView = history.location.pathname.includes('/updates')

  const renderPreviewFiles = () => {
    return (
      <PreviewContents
        contents={update.contents}
        selectedContent={selectedContent}
        isOpen={!!selectedContent}
        onClosePreview={onClosePreview}
      />
    )
  }

  switch (update.updateType) {
    case UpdateTypes.DOCUMENT:
    case UpdateTypes.ANNOUNCEMENT:
    case UpdateTypes.NOTE:
      return (
        <DefaultUpdateCard
          update={update}
          onPreviewFile={onPreviewContent}
          filesPreview={renderPreviewFiles()}
          canEdit={updatePermissions.hasEditPermissions}
        />
      )
    case UpdateTypes.IUE:
      return (
        <EmailCard
          update={update as EmailIndexUpdate}
          onPreviewFile={onPreviewContent}
          filesPreview={renderPreviewFiles()}
          canEdit={updatePermissions.hasEditPermissions}
          showDotsOptions={!isComingFromUpdatesView}
        />
      )
    case UpdateTypes.TRANSACTION:
      return (
        <TransactionCard
          update={update}
          onPreviewFile={onPreviewContent}
          filesPreview={renderPreviewFiles()}
          canEdit={updatePermissions.hasEditPermissions}
        />
      )
    case UpdateTypes.ACCOUNTING:
      return (
        <AccountingCard
          update={update}
          canEdit={updatePermissions.hasEditPermissions}
        />
      )
    default:
      return null
  }
}

export default UpdateFactory
