import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import Chip from 'components/Chip'
import { Scroll } from 'App.styles'

interface TagsTooltipContentProps {
  closed?: boolean
  position?: { left: number; top: number }
  isMobile?: boolean
}

export const TagsList = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
  flex-grow: 1;
  min-width: 0;
  margin-right: 4rem;
`

export const TagsTooltipContent = styled.div.attrs<TagsTooltipContentProps>(
  (props) => {
    if (props.closed) {
      return {
        style: {
          opacity: 0,
          visibility: 'hidden',
        },
      }
    }
    return {
      style: {
        left: `${(props.position?.left ?? 0) + (props.isMobile ? -100 : 0)}px`,
        top: `${props.position?.top ?? 0}px`,
        transform: 'translateY(4rem)',
        opacity: 1,
        visibility: 'visible',
      },
    }
  }
)<TagsTooltipContentProps>`
  position: absolute;
`
export const Tag = styled(Chip)<{
  transparent?: boolean
  isFromTooltip?: boolean
}>`
  color: ${color('white')};
  background-color: ${color('white', 0.4)};
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  ${(props) =>
    props.transparent &&
    css`
      border: 1px solid ${color('white')};
      background-color: transparent;
    `}

  ${(props) =>
    props.isFromTooltip &&
    css`
      color: ${color('darkBlue')};
      background-color: ${color('lightGray', 0.1)};
      width: max-content;
      height: fit-content;

      p {
        max-width: 39rem;
        white-space: normal;
        word-break: break-word;
      }
    `}

  svg {
    font-size: 1.5rem;
  }
`

export const TooltipTagContainer = styled.div`
  background-color: ${color('white')};
  border-radius: 0.8rem;
  box-shadow: 0px 0px 15px rgba(16, 21, 39, 0.1);
  padding: 1.2rem 1.6rem;
  padding-right: 0.5rem;
`

export const TooltipTagWrapper = styled.div`
  ${Scroll}
  display: flex;
  max-height: 30rem;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.4rem;
  padding-right: 1.6rem;
`
