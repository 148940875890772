import { Group, User } from 'utils/types/user'
import { downloadBlob } from './files'

export const CHECKBOX_INDETERMINATE = 'indeterminate'

export const setGroupsUsersToListAssociated = (users, groups, list) => {
  const listUsers = list.listUsers.map((id) => ({
    ...users[id].user,
    listId: list.id,
    listName: list.name,
  }))

  const listGroups = list.listGroups.map((id) => ({
    ...groups[id].groupable,
    listId: list.id,
    listName: list.name,
  }))

  return { listUsers, listGroups }
}

export const belongsToAnotherCheckedList = (itemId, lists, groups, users) => {
  const belongLists = lists.filter(({ listGroups, listUsers, checked }) => {
    if (checked) {
      const belongsToGroup = listGroups.some(
        (id) => groups[id].groupable.id === itemId
      )

      const belongsToUser = listUsers.some((id) => users[id].user.id === itemId)

      return belongsToGroup || belongsToUser
    }

    return null
  })

  return {
    belongsToAnotherList: belongLists.length,
    newListId: belongLists[0]?.id,
  }
}

export const filterList = (list, searchInput) => {
  if (!searchInput) {
    return false
  }

  const searchContainsString = (string) =>
    string?.toLowerCase().includes(searchInput.toLowerCase()) ?? false

  const searchContainsObject = (object) =>
    searchContainsString(object.email) ||
    searchContainsString(object.name) ||
    searchContainsString(object.handle)

  return (
    !searchContainsString(list.name) &&
    !list.users.some(searchContainsObject) &&
    !list.groups.some(searchContainsObject)
  )
}

export const copyListRecipientsToClipboard = (
  users: User[],
  groups: Group[]
) => {
  const groupsTxt = groups.map((group) => group.email)
  const usersTxt = users.map((user) => user.email)
  const txt = groupsTxt.concat(usersTxt).join(' ')

  navigator.clipboard.writeText(txt)
}

export const exportListRecipientsToCsv = (
  listName: string,
  users: User[],
  groups: Group[]
) => {
  const groupEmails = groups.map((group: Group) => group.email)
  const userEmails = users.map((user: User) => user.email)
  const emails = groupEmails.concat(userEmails).join('\n')
  const blob = new Blob([emails], { type: 'text/csv' })

  downloadBlob(`${listName}.csv`, blob)
}
