import theme from 'utils/theme'
import { FileViewEnum } from 'components/FileViewSelector'
import {
  SkeletonAvatar,
  SkeletonItem,
  SkeletonThumbnailContainer,
  SkeletonThumbnailContentWrapper,
} from '../../Skeletons.styles'

interface Props {
  gridSize: FileViewEnum
  orderByDate: boolean
  amountOfItems: number
}

const FetchingMoreSkeleton = ({
  gridSize,
  orderByDate,
  amountOfItems,
}: Props) => {
  const array = Array.from(Array(amountOfItems).keys())

  const renderSkeleton = () => {
    const commonSkeleton = (
      <SkeletonThumbnailContainer>
        <SkeletonThumbnailContentWrapper size={gridSize}>
          <SkeletonAvatar
            borderWidth="0.3rem"
            height={gridSize === FileViewEnum.LARGE ? '6.8rem' : '4.8rem'}
            width={gridSize === FileViewEnum.LARGE ? '6.8rem' : '4.8rem'}
          />
          <SkeletonItem width="10rem" height="1rem" />
        </SkeletonThumbnailContentWrapper>
      </SkeletonThumbnailContainer>
    )

    const renderThumbnailContainers = () => array.map((_item) => commonSkeleton)

    if (orderByDate) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '3.2rem' }}
        >
          <SkeletonItem
            skeletonShine={theme.colors.filesGridShine}
            width="4rem"
            height="1rem"
          />
          <div style={{ display: 'flex', gap: '1.6rem' }}>
            {renderThumbnailContainers()}
          </div>
        </div>
      )
    }

    return (
      <div style={{ display: 'flex', gap: '1.6rem' }}>
        {renderThumbnailContainers()}
      </div>
    )
  }

  return renderSkeleton()
}

export default FetchingMoreSkeleton
