import type { IntlShape } from 'react-intl'
import * as yup from 'yup'

export const getBillingAccountSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    email: yup
      .string()
      .trim()
      .email(intl.formatMessage({ id: 'login.invalidEmail' }))
      .required(intl.formatMessage({ id: 'login.requiredEmail' })),
    phone: yup
      .string()
      .trim()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    country: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    hasPostalAddress: yup
      .boolean()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    address: yup.object().when('hasPostalAddress', {
      is: true,
      then: yup.object().shape({
        street: yup
          .string()
          .required(intl.formatMessage({ id: 'general.requiredField' })),
        city: yup
          .string()
          .required(intl.formatMessage({ id: 'general.requiredField' })),
        state: yup
          .string()
          .required(intl.formatMessage({ id: 'general.requiredField' })),
        zip: yup
          .string()
          .trim()
          .required(intl.formatMessage({ id: 'general.requiredField' })),
      }),
    }),
  })
