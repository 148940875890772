import { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { setRedirectUrl, getRedirectUrl } from 'features/redirectSlice'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'

const RedirectUrlRoute = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const redirectUrl = useAppSelector(getRedirectUrl)

  useEffect(() => {
    if (redirectUrl) {
      history.replace(redirectUrl)
      dispatch(setRedirectUrl(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUrl])

  return <Redirect to={redirectUrl!} />
}

export default RedirectUrlRoute
