/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react'
import {
  PortfolioCompanyTypes,
  PortfolioTypes,
} from 'utils/constants/portfolio'
import { PlainSubjectMatters } from 'utils/constants/updates'
import { SubjectMatterType } from 'api/UpdateService'
import { UpdatableEntity, ValueOf, MakeOptional, Nullable } from './common'
import { CompanyHolding } from './company'
import {
  FundPortfolioInvestor,
  ShareFundSettings,
  SharePortfolioSettings,
} from './funds'
import { AppGroup, Group } from './user'

export type PortfolioType = ValueOf<typeof PortfolioTypes>
export type TypeOfPortfolios = ValueOf<typeof PortfolioCompanyTypes>

export interface PortfolioCompany {
  id: string
  createdAt: Date
  updatedAt: Date
  distributionToPaidIn: number
  firstInvestmentDate: string
  lastCarryingValueUpdate: string
  multipleOnInvestedCapital: number
  totalAmountDistributed: number
  totalAmountInvested: number
  percentageOfInvested: number
  unfundedCommitment: number
  totalTransactions: number
  totalValue: number
  irr: number
  holding: CompanyHolding
  type: TypeOfPortfolios | PortfolioTypes | SubjectMatterType
  name: string
  added: boolean
  totalFairMarketValue?: number
  holdingId?: string
  portfolioCompanyIds?: string[]
  logo?: string
  holdingType?: string
}

export interface FairMarketValue {
  id: string
  createdAt: string
  updatedAt: string
  date: Date
  description: string
  portfolioCompanyId: string
  value: number | string
}

export interface FmvWithGrowth extends FairMarketValue {
  growth: Nullable<boolean>
  growthPercentage: Nullable<number>
  growthValue?: Nullable<number>
}

export interface PortfolioCompanyWithFmv extends PortfolioCompany {
  fairMarketValues: FairMarketValue[]
  totalFairMarketValue?: number
}
export type PortfolioCompanyWithOptionalFmv = MakeOptional<
  PortfolioCompanyWithFmv,
  'fairMarketValues'
>

export interface PortfolioCompanyTransaction {
  id: string
  portfolioCompanyId: string
  investPortfolioCompany: PortfolioCompany
}

export interface PortfolioHoldingsColumn {
  id: string
  status: boolean
  label: string | React.ReactNode
}

export type UpdatesTypeValues =
  | 'announcement'
  | 'note'
  | 'document'
  | 'report'
  | 'transaction'
  | ['xero_report', 'quickbooks_report']

export interface FilterOptions {
  id: string
  label: string
}

export interface UpdatesTypesFilterOptions extends FilterOptions {
  value: UpdatesTypeValues
}

export interface UpdatesTagsFilterOptions extends FilterOptions {}

export interface PortfolioHoldingsVisibleColumns {
  totalTransactions: boolean
  totalAmountInvested: boolean
  totalAmountDistributed: boolean
  firstInvestmentDate: boolean
  multipleOnInvestedCapital: boolean
  percentageOfInvested: boolean
  totalFairMarketValue: boolean
  lastCarryingValueUpdate: boolean
  totalValue: boolean
  irr: boolean
  unfundedCommitment: boolean
  hideNullCarryingValueHoldings: boolean
}

export interface UserPortfolioSettings {
  id: string
  settings: {
    visibleFields: PortfolioHoldingsVisibleColumns
    metrics: {
      selectedMetrics: string[]
    }
  }
}

export interface IndexPortfolio<T extends PortfolioCompany = PortfolioCompany>
  extends UpdatableEntity {
  averageInvestmentPerCompany: number
  averageInvestmentSize: number
  companiesCount: number
  createdAt: Date
  distributionToPaidIn: 0
  multipleOnInvestedCapital: 0
  portfolioCompanies: T[]
  pinned: boolean
  // investorPortfolioCompanies is used when previewing a portfolio
  investorPortfolioCompanies?: T[]
  portfolioCompanyTransaction?: PortfolioCompanyTransaction
  totalHoldings: number
  totalInvested: number
  totalFairMarketValue: number
  totalDistributed: number
  portfolioTotalUnfundedCommitment: number
  totalTransactions: number
  irr: number
  totalValue: number
  type: PortfolioType
  updatedAt: Date
  firstInvestmentDate: string
  investorSummary?: {
    averageInvestmentPerCompany: number
    distributionToPaidIn: number
    irr: number
    multipleOnInvestedCapital: number
    portfolioTotalUnfundedCommitment: number
    totalDistributed: number
    totalFairMarketValue: number
    totalHoldings: number
    totalInvested: number
    totalInvestments: number
    totalValue: number
  }
}

export interface IndexFundPortfolio extends IndexPortfolio {
  globalShareSettings: boolean
  investorsTotalUnfundedCommitment: number
  totalCapitalCalled: number
  totalCommittedCapital: number
  totalDistributions: number
  totalHoldingValue: number
  totalInvestments: number
  totalInvestors: number
  hidden: boolean
  sharePortfolioSetting?: SharePortfolioSettings
  group?: AppGroup
}

export interface FundPortfolioInvestors {
  fundPortfolio: IndexFundPortfolio
  investors: FundPortfolioInvestor[]
}

export interface Portfolio<T extends PortfolioCompany = PortfolioCompany>
  extends IndexPortfolio<T> {
  userPortfolio: UserPortfolioSettings
  hidden: boolean
}

export interface FundPortfolio<T extends PortfolioCompany = PortfolioCompany>
  extends Portfolio<T> {
  fundPortfolioInvestorId?: string
  investorsTotalUnfundedCommitment: number
  totalCapitalCalled: number
  totalCommittedCapital: number
  totalDistributions: number
  totalHoldingValue: number
  totalInvestments: number
  totalInvestors: number
  globalShareSettings: boolean
  sharePortfolioSetting: SharePortfolioSettings
  hidden: boolean
  group: Group
}

export interface ChartColors {
  [entityId: string]: string
}

export interface EditPorfolioPayload {
  name?: string
  globalShareSettings?: boolean
  sharePortfolioSettingsAttributes?: ShareFundSettings
  hidden?: boolean
  pinned?: boolean
  type?: string
}

export interface ShareSettingsPayload {
  name?: string
  sharePortfolioSettings: ShareFundSettings
}

export type OptionalPortfolioSharedFields =
  | 'averageInvestmentPerCompany'
  | 'averageInvestmentSize'
  | 'distributionToPaidIn'
  | 'multipleOnInvestedCapital'
  | 'portfolioCompanies'
  | 'portfolioCompanyTransaction'
  | 'totalHoldings'
  | 'totalInvested'
  | 'totalFairMarketValue'
  | 'totalDistributed'
  | 'portfolioTotalUnfundedCommitment'
  | 'totalTransactions'
  | 'irr'
  | 'totalValue'
  | 'firstInvestmentDate'

export type OptionalPortfolioCompanySharedFields =
  | 'distributionToPaidIn'
  | 'firstInvestmentDate'
  | 'multipleOnInvestedCapital'
  | 'totalAmountDistributed'
  | 'totalAmountInvested'
  | 'percentageOfInvested'
  | 'unfundedCommitment'
  | 'totalTransactions'
  | 'totalValue'
  | 'fairMarketValues'

export type PortfolioCompanyWithSharedSettingsApplied = MakeOptional<
  PortfolioCompanyWithFmv,
  OptionalPortfolioCompanySharedFields
>

export type PortfolioWithSharedSettingsApplied = MakeOptional<
  Portfolio,
  OptionalPortfolioSharedFields
>

export enum PortfolioMetricsActions {
  DONE = 'Done',
  PENDING = 'Pending',
}

export type PortfolioMetricsStatus = {
  action: PortfolioMetricsActions
  lastUpdatedAt: Date
}

export type PortfolioHolding = {
  id: string
  logo: string
  name: string
  totalAudios: number
  totalCompressedFiles: number
  totalDocuments: number
  totalEmailContents: number
  totalImages: number
  totalOtherTypes: number
  totalPdfs: number
  totalPresentations: number
  totalSpreadsheets: number
  totalVideos: number
  type: ValueOf<typeof PlainSubjectMatters>
  totalFiles: number
}

export type FollowedHoldingsTable = {
  followedHoldings: number
  activeHoldings: number
  industryCount: number
  industryConcentration: string[]
  pieChart: {
    [key: string]: number
  }
  averageSize: number
  averageAge: string
  foundingRange: string
  geographicDiversity: number
  websitePresence: number
  socialMediaPresence: number
  mainLocation: string[]
}

export interface MixedPortfolio {
  updatedAt: Date
  createdAt: Date
  id: string
  name: string
  type: PortfolioTypes
  companiesCount: number
  group: AppGroup
}
