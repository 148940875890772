import { FC } from 'react'
import { Helmet } from 'components/Helmet'
import CWLoader from 'components/CWLoader'
import { Wrapper } from './ShowWrapper.styles'

interface Props {
  isLoading: boolean
  children: JSX.Element
  title: string
}

const ShowWrapper: FC<Props> = ({ isLoading, children, title }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isLoading ? (
        <Wrapper>
          <CWLoader />
        </Wrapper>
      ) : (
        children
      )}
    </>
  )
}

export default ShowWrapper
