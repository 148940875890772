/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useRef } from 'react'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'
import { fetchUniversityActions } from 'actions/university'
import {
  getUniversityInitialLoading,
  getUniversityIsActive,
  getUniversityIsEnabled,
} from 'selectors/university'
import { usePlanFeatures } from './usePlanFeatures'

export const useFetchUniversity = () => {
  const universityActive = useAppSelector(getUniversityIsActive)
  const planFeatures = usePlanFeatures()
  const universityEnabled = useAppSelector(getUniversityIsEnabled)
  const initialLoading = useAppSelector(getUniversityInitialLoading)

  const currentGroupId = useAppSelector(getCurrentGroupId)

  const firstFetch = useRef(true)
  const dispatch = useAppDispatch()

  const fetchUniversity = useCallback(() => {
    if (currentGroupId && (universityActive || firstFetch.current)) {
      dispatch(fetchUniversityActions(firstFetch.current))
      firstFetch.current = false
    }
  }, [dispatch, universityActive, currentGroupId])

  return {
    universityActive:
      planFeatures.isUniversityEnabled &&
      universityEnabled &&
      universityActive &&
      !initialLoading,
    fetchUniversity,
  }
}
