import { FormattedMessage } from 'react-intl'
import type { StreamMessage } from 'stream-chat-react'

import { StreamChatType } from 'containers/Chat/types'
import { HorizontalContainer, Sender } from './SentInformation.styles'
import MessageTimestamp from '../MessageTimestamp'

type Props = {
  userName?: string
  isMyMessage?: boolean
  message: StreamMessage<StreamChatType>
}

const SentInformation: React.FC<Props> = ({ message, isMyMessage }) => {
  return (
    <HorizontalContainer>
      <Sender>
        {isMyMessage ? <FormattedMessage id="chat.you" /> : message.user?.name}
      </Sender>
      <MessageTimestamp message={message} />
    </HorizontalContainer>
  )
}

export default SentInformation
