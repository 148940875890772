import styled from 'styled-components'
import { motion } from 'framer-motion'

import { color } from 'utils/functions/colors'

export const ZeroState = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 6.6rem;
  color: ${color('lightGray')};
  font-size: 1.8rem;
  line-height: 2.2rem;
  font-weight: 400;
`

export const Ul = styled(motion.ul)`
  margin-top: 2.4rem;
  background-color: white;
  border-radius: 0.8rem;
  border: 1px solid ${color('veryLightGray')};
`

export const LoaderWrapper = styled.div`
  margin-top: 2rem;
`
