import styled from 'styled-components/macro'
import { css } from 'styled-components/'
import { Scroll } from 'App.styles'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const Container = styled.div`
  z-index: 3;
  position: absolute;
  left: 2.5rem;
  top: 8.8rem;
  width: calc(100% - 12rem);
`

export const ChannelName = styled.div<{
  isOpen: boolean
}>`
  font-size: 1.8rem;
  line-height: 2.2rem;
  position: relative;
  display: flex;
  gap: 1.1rem;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  max-width: calc(100% - 15rem);

  span {
    max-width: 100%;

    a {
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      background: ${color('white')};
    }
  }

  &:hover {
    color: ${color('primaryBlue')};
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      color: ${color('primaryBlue')};
    `}
`

export const ChannelList = styled.div`
  ${Scroll}
  overflow-y: overlay;
  position: relative;
  left: -1rem;
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
  width: 37.2rem;
  max-height: 40.6rem;
  background: white;
  border: 0.1rem solid ${color('veryLightGray')};
  box-shadow: 0rem 0rem 0.8rem ${color('darkBlue', 0.05)};
  border-radius: 0.8rem;

  @media ${maxSize.xsm} {
    width: calc(100vw - 9.2rem);
  }
`
