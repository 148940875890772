import { useCallback, useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import LogoUrl from 'assets/images/logo-cw.svg'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser, getUserGroups } from 'selectors/auth'

import {
  WhiteBackground,
  LowerRightLogo,
  TopLeftLogo,
  StyledClockworkLogo,
  WelcomeText,
} from './WelcomePages.styles'

const WelcomePage = () => {
  const animation = useAnimation()
  const welcomeAnimation = useAnimation()
  const history = useHistory()
  const currentUserGroups = useAppSelector(getUserGroups)
  const user = useAppSelector(getUser)

  const sequence = useCallback(async () => {
    // Animate fade in clockwork logo
    await animation.start({
      opacity: 1,
      scale: 1,
      transition: { duration: 1.2, ease: 'easeInOut' },
    })
    // Animate translate clockwork logo to top
    await animation.start({
      y: '-23vh',
      scale: 0.8,
      transition: {
        duration: 1.5,
        times: [0, 0.2, 0.5, 0.8, 1],
        ease: 'easeInOut',
      },
    })
    // Animate fade out of the white background
    await welcomeAnimation.start({
      opacity: 0,
      transition: { duration: 1, ease: 'easeOut', delay: 3 },
    })

    const hasGroups = Object.keys(currentUserGroups || {}).length > 0
    const hasNoName = user.firstName === null && user.lastName === null

    if (hasGroups && hasNoName) {
      history.replace('/welcome/invite')
    } else {
      history.replace('/welcome/select-plan')
    }
  }, [animation, history, welcomeAnimation, user, currentUserGroups])

  useEffect(() => {
    sequence()
  }, [sequence])

  return (
    <WhiteBackground animate={welcomeAnimation}>
      <div>
        <StyledClockworkLogo
          src={LogoUrl}
          animate={animation}
          initial={{ opacity: 0, scale: 1 }}
        />
        <WelcomeText
          animate={{ opacity: 1, scale: 1 }}
          initial={{ opacity: 0, scale: 0.1 }}
          transition={{ duration: 1.5, ease: 'easeInOut', delay: 2.3 }}
        >
          <FormattedMessage id="investorSignup.welcome" />
        </WelcomeText>
      </div>
      <LowerRightLogo>
        <motion.svg
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          xmlns="http://www.w3.org/2000/svg"
          width="440"
          height="652"
          viewBox="0 0 440 652"
          fill="none"
        >
          <g opacity="0.1">
            <path
              d="M317.71 768.586C233.5 728.424 168.429 654.839 139.748 565.073C126.43 562.557 112.697 561.176 98.6531 561.176C80.1394 561.176 62.1731 563.492 45 567.789C78.6979 701.834 175.333 810.927 301.811 862.202C312.213 836.759 318 808.936 318 779.763C318 776.012 317.889 772.282 317.71 768.586Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              d="M464.225 585.818C442.829 585.818 422.692 580.4 405.125 570.872C401.609 568.966 398.193 566.904 394.901 564.675C361.919 542.428 340.243 504.744 340.243 462.011C340.243 419.278 361.919 381.605 394.901 359.347C398.195 357.129 401.609 355.056 405.125 353.15C419.69 345.248 436.021 340.255 453.377 338.75C453.287 336.509 453.198 334.259 453.198 331.995C453.198 331.025 453.253 330.078 453.276 329.108C451.636 327.748 450.039 326.344 448.443 324.94C379.542 332.764 326 391.112 326 462.011C326 538.247 387.88 600.05 464.225 600.05C516.897 600.05 562.661 570.625 586 527.346C581.546 525.584 577.15 523.724 572.864 521.662C551.755 559.892 511.037 585.818 464.225 585.818Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <motion.path
              style={{ originX: '500px', originY: '500px' }}
              animate={{ rotate: -360 }}
              transition={{
                ease: 'linear',
                duration: 12,
                repeat: Infinity,
                delay: 0.9,
              }}
              d="M344 290.059C344 273.534 330.564 260.15 314 260.15C297.436 260.15 284 273.534 284 290.059C284 306.561 297.436 319.956 314 319.956C330.564 319.956 344 306.561 344 290.059Z"
              stroke="#101527"
            />
            <path
              d="M572.875 521.217C504.123 488.336 456.12 419.236 453.568 338.669C436.238 340.17 419.931 345.154 405.388 353.04C401.877 354.942 398.467 357.011 395.18 359.225C362.257 381.438 340.604 419.036 340.604 461.684C340.604 504.332 362.259 541.94 395.18 564.144C398.468 566.369 401.877 568.427 405.388 570.329C422.931 579.84 443.036 585.245 464.4 585.245C511.144 585.245 551.8 559.371 572.875 521.217Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <motion.path
              style={{ originX: '500px', originY: '500px' }}
              animate={{ rotate: 360 }}
              transition={{
                ease: 'linear',
                duration: 24,
                repeat: Infinity,
                delay: 0.9,
              }}
              d="M119.008 346.01C119.008 333.129 108.543 322.695 95.6602 322.695C82.7546 322.695 72.3008 333.129 72.3008 346.01C72.3008 358.879 82.7546 369.313 95.6602 369.313C108.543 369.313 119.008 358.879 119.008 346.01Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              d="M872.584 325.846C854.721 272.633 826.796 224.073 791.013 182.402C788.821 221.213 775.932 257.14 755.218 287.303C785.96 338.4 803.667 398.225 803.667 462.188C803.667 471.225 803.211 480.151 802.51 488.999C788.832 664.097 642.516 801.93 463.925 801.93C438.471 801.93 413.705 799.037 389.855 793.728C388.375 825.404 381.808 855.711 371 883.936C400.94 890.525 432.003 894.098 463.925 894.098C702.007 894.098 895 701.094 895 463.023C895 415.041 887.054 368.953 872.584 325.846ZM831.615 604.23C818.727 604.23 808.287 593.779 808.287 580.902C808.287 568.025 818.727 557.574 831.615 557.574C844.503 557.574 854.943 568.025 854.943 580.902C854.943 593.779 844.503 604.23 831.615 604.23Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              d="M168.393 572.583C159.191 569.501 149.712 566.999 140 565.163C168.526 654.851 233.245 728.377 317 768.505C316.511 758.581 315.289 748.846 313.499 739.313C247.124 703.313 195.174 644.105 168.393 572.583Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              d="M147.461 462.034C147.461 317.533 244.937 195.844 377.791 158.708C378.304 149.822 379.396 141.104 381 132.563C232.852 169.208 123 302.787 123 462.034C123 471.497 123.49 480.828 124.247 490.081C132.623 490.836 140.877 491.96 149.031 493.395C148.006 483.075 147.461 472.621 147.461 462.034Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              d="M755.531 288.06C750.542 295.323 745.098 302.243 739.252 308.783C764.873 354.346 779.55 406.872 779.55 462.845C779.55 478.853 778.324 494.57 776.021 509.932C753.271 661.626 622.289 777.958 464.105 777.958C438.583 777.958 413.819 774.866 390.057 769.148C390.201 772.907 390.346 776.667 390.346 780.46C390.346 785.077 390.201 789.649 390 794.198C413.863 799.504 438.638 802.395 464.103 802.395C642.777 802.395 789.158 664.64 802.843 489.642C803.544 480.799 804 471.878 804 462.845C804 398.919 786.287 339.13 755.531 288.06Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              d="M751.764 142.097C738.786 130.453 725.049 119.644 710.723 109.624C713.886 116.151 716.66 122.891 718.788 129.942C722.676 142.765 724.804 156.366 724.804 170.445C724.804 191.607 719.969 211.605 711.534 229.586C707.892 237.349 703.591 244.722 698.646 251.627C673.234 287.114 631.713 310.336 584.679 310.336C536.842 310.336 494.651 286.358 469.373 249.849C453.754 227.284 444.552 199.949 444.552 170.445C444.552 162.515 445.377 154.787 446.636 147.214C448.073 138.716 450.256 130.498 453.153 122.591C467.503 83.3012 498.918 52.3071 538.522 38.4936C514.168 34.246 489.17 31.8887 463.603 31.8887C452.395 31.8887 441.321 32.4562 430.338 33.2894C405.839 60.7361 388.617 94.7441 381.576 132.334C379.972 140.874 378.88 149.594 378.367 158.478C378.156 162.449 378 166.429 378 170.445C378 232 405.027 287.214 447.851 325.026C449.444 326.427 451.037 327.829 452.674 329.185C456.517 332.377 460.506 335.4 464.584 338.316C489.651 356.21 518.849 368.653 550.51 373.903V373.914C557.461 375.061 564.513 375.871 571.678 376.317C575.978 376.594 580.3 376.773 584.679 376.773C645.985 376.773 701.041 350.093 738.885 307.768C744.733 301.229 750.181 294.311 755.172 287.049C775.904 256.911 788.804 221.013 791 182.233C778.792 168.031 765.713 154.597 751.764 142.097Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              opacity="0.6"
              d="M751.764 142.097C738.786 130.453 725.049 119.644 710.723 109.624C713.886 116.151 716.66 122.891 718.788 129.942C722.676 142.765 724.804 156.366 724.804 170.445C724.804 191.607 719.969 211.605 711.534 229.586C707.892 237.349 703.591 244.722 698.646 251.627C673.234 287.114 631.713 310.336 584.679 310.336C536.842 310.336 494.651 286.358 469.373 249.849C453.754 227.284 444.552 199.949 444.552 170.445C444.552 162.515 445.377 154.787 446.636 147.214C448.073 138.716 450.256 130.498 453.153 122.591C467.503 83.3012 498.918 52.3071 538.522 38.4936C514.168 34.246 489.17 31.8887 463.603 31.8887C452.395 31.8887 441.321 32.4562 430.338 33.2894C405.839 60.7361 388.617 94.7441 381.576 132.334C379.972 140.874 378.88 149.594 378.367 158.478C378.156 162.449 378 166.429 378 170.445C378 232 405.027 287.214 447.851 325.026C449.444 326.427 451.037 327.829 452.674 329.185C456.517 332.377 460.506 335.4 464.584 338.316C489.651 356.21 518.849 368.653 550.51 373.903V373.914C557.461 375.061 564.513 375.871 571.678 376.317C575.978 376.594 580.3 376.773 584.679 376.773C645.985 376.773 701.041 350.093 738.885 307.768C744.733 301.229 750.181 294.311 755.172 287.049C775.904 256.911 788.804 221.013 791 182.233C778.792 168.031 765.713 154.597 751.764 142.097Z"
              fill="#101527"
            />
            <path
              d="M390.707 767.622C385.393 629.135 283.439 515.475 150.275 491.966C142.104 490.527 133.831 489.4 125.437 488.642C116.786 487.873 108.045 487.415 99.1819 487.415C76.756 487.415 54.9646 490.036 34 494.809C35.8861 519.379 39.7498 543.391 45.5759 566.634C62.7339 562.328 80.6845 560.01 99.1819 560.01C113.214 560.01 126.933 561.394 140.24 563.914C149.997 565.754 159.519 568.263 168.763 571.352C243.232 596.257 299.841 659.984 314.532 738.501C316.328 748.058 317.557 757.817 318.049 767.766C318.228 771.469 318.338 775.205 318.338 778.964C318.338 808.186 312.556 836.056 302.163 861.541C324.479 870.621 347.731 877.869 371.742 883.134C382.581 854.851 389.167 824.481 390.653 792.74C390.853 788.179 391 783.594 391 778.966C390.996 775.161 390.851 771.392 390.707 767.622Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              opacity="0.4"
              d="M390.707 767.622C385.393 629.135 283.439 515.475 150.275 491.966C142.104 490.527 133.831 489.4 125.437 488.642C116.786 487.873 108.045 487.415 99.1819 487.415C76.756 487.415 54.9646 490.036 34 494.809C35.8861 519.379 39.7498 543.391 45.5759 566.634C62.7339 562.328 80.6845 560.01 99.1819 560.01C113.214 560.01 126.933 561.394 140.24 563.914C149.997 565.754 159.519 568.263 168.763 571.352C243.232 596.257 299.841 659.984 314.532 738.501C316.328 748.058 317.557 757.817 318.049 767.766C318.228 771.469 318.338 775.205 318.338 778.964C318.338 808.186 312.556 836.056 302.163 861.541C324.479 870.621 347.732 877.869 371.742 883.134C382.581 854.851 389.167 824.481 390.653 792.74C390.853 788.179 391 783.594 391 778.966C390.996 775.161 390.851 771.392 390.707 767.622Z"
              fill="#101527"
            />
            <path
              d="M168.165 572.124C195.038 643.64 247.168 702.842 313.77 738.838C299.096 660.527 242.551 596.963 168.165 572.124Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
            <path
              d="M788.886 134.261C705.914 52.5095 592.144 1.85199 466.477 1.18372C465.651 1.18372 464.839 1.12866 464.013 1.12866C208.652 1.12866 1.6416 207.645 1.6416 462.408C1.6416 476.534 2.37808 490.496 3.62647 504.3C5.85683 529.14 10.0054 553.423 16.0267 576.961C52.3328 718.659 154.417 834.025 287.889 888.933C310.491 898.243 334.007 905.752 358.261 911.404C392.238 919.347 427.606 923.674 464.013 923.674C719.383 923.674 926.384 717.157 926.384 462.406C926.384 333.992 873.752 217.891 788.886 134.261ZM464.013 893.252C432.032 893.252 400.912 889.681 370.916 883.095C346.93 877.844 323.704 870.614 301.416 861.559C175.191 810.333 78.7488 701.339 45.1184 567.417C39.297 544.235 35.4395 520.285 33.5555 495.78C32.7087 484.755 32.1397 473.643 32.1397 462.408C32.1397 235.646 207.773 49.9409 430.717 32.955C441.711 32.1216 452.796 31.5539 464.013 31.5539C489.603 31.5539 514.626 33.9119 539 38.1607C602.257 49.2175 660.764 73.9682 711.354 109.311C725.694 119.333 739.443 130.147 752.433 141.792C766.394 154.295 779.485 167.733 791.706 181.937C827.556 223.587 855.532 272.12 873.428 325.304C887.924 368.387 895.886 414.452 895.886 462.408C895.886 700.35 702.536 893.252 464.013 893.252Z"
              stroke="#101527"
              strokeWidth="1.6975"
            />
          </g>
        </motion.svg>
      </LowerRightLogo>
      <TopLeftLogo>
        <motion.svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 418 344"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" x="-271" y="-343" width="689" height="687" />
            </defs>
            <g className="st0">
              <path
                className="st1"
                d="M-178.3,21.1c-0.6-6.9-0.9-13.8-0.9-20.8c0-118.2,81.7-217.4,192-244.6c5.2-27.9,18.1-53.2,36.3-73.5
			C-116.7-305.2-247.2-167.4-247.2,0.9c0,8.3,0.4,16.6,1.1,24.8c15.6-3.5,31.7-5.5,48.4-5.5C-191.2,20.2-184.7,20.5-178.3,21.1z
			 M-217.8-85.6c0-9.6,7.8-17.3,17.4-17.3c9.6,0,17.4,7.7,17.4,17.3c0,9.6-7.8,17.3-17.4,17.3C-210.1-68.3-217.8-76-217.8-85.6z"
              />
              <path
                className="st1"
                d="M73.7,92.1c-15.9,0-30.8-4-43.9-11.1c-2.6-1.4-5.1-2.9-7.6-4.6C-2.3,59.9-18.4,31.9-18.4,0.1
			s16.1-59.7,40.6-76.2c2.4-1.6,5-3.2,7.6-4.6c10.8-5.9,22.9-9.6,35.8-10.7c-0.1-1.7-0.1-3.3-0.1-5c0-0.7,0-1.4,0.1-2.1
			c-1.2-1-2.4-2.1-3.6-3.1C10.8-95.9-29-52.5-29,0.1C-29,56.8,17,102.7,73.7,102.7c39.1,0,73.1-21.9,90.4-54
			c-3.3-1.3-6.6-2.7-9.8-4.2C138.7,72.8,108.4,92.1,73.7,92.1z"
              />
              <path
                className="st1"
                d="M154.3,44.1c-51-24.4-86.7-75.7-88.6-135.6c-12.9,1.1-25,4.8-35.8,10.7c-2.6,1.4-5.1,2.9-7.6,4.6
			C-2-59.7-18.1-31.8-18.1-0.1S-2,59.5,22.4,76c2.4,1.7,5,3.2,7.6,4.6c13,7.1,28,11.1,43.8,11.1C108.5,91.7,138.7,72.4,154.3,44.1z"
              />
              <path
                className="st1"
                d="M376.9-101c-13.3-39.5-34-75.6-60.6-106.5c-1.6,28.8-11.2,55.5-26.6,77.9c22.8,38,36,82.4,36,129.9
			c0,6.7-0.3,13.3-0.9,19.9C314.7,150.2,206,252.6,73.4,252.6c-18.9,0-37.3-2.1-55-6.1c-1.1,23.5-6,46-14,67
			c22.2,4.9,45.3,7.5,69,7.5c176.8,0,320.1-143.3,320.1-320.2C393.5-34.7,387.6-69,376.9-101z"
              />
              <path
                className="st1"
                d="M-161.5,0.2c0-107.3,72.4-197.7,171-225.3c0.4-6.6,1.2-13.1,2.4-19.4c-110,27.2-191.6,126.4-191.6,244.7
			c0,7,0.4,14,0.9,20.8c6.2,0.6,12.3,1.4,18.4,2.5C-161.1,15.8-161.5,8-161.5,0.2z"
              />
              <path
                className="st1"
                d="M289.9-129.1c-3.7,5.4-7.7,10.5-12.1,15.4c19,33.8,29.9,72.9,29.9,114.4c0,11.9-0.9,23.6-2.6,35
			C288.3,148.4,191,234.8,73.6,234.8c-18.9,0-37.3-2.3-55-6.5c0.1,2.8,0.2,5.6,0.2,8.4c0,3.4-0.1,6.8-0.3,10.2
			c17.7,3.9,36.1,6.1,55,6.1c132.7,0,241.3-102.3,251.5-232.3c0.5-6.6,0.9-13.2,0.9-19.9C325.9-46.7,312.8-91.1,289.9-129.1z"
              />
              <path
                className="st1"
                d="M19.1,227.1C15.1,124.3-60.6,39.8-159.4,22.4c-6.1-1.1-12.2-1.9-18.4-2.5c-6.4-0.6-12.9-0.9-19.5-0.9
			c-16.6,0-32.8,1.9-48.4,5.5c1.4,18.2,4.3,36.1,8.6,53.3c12.7-3.2,26.1-4.9,39.8-4.9c10.4,0,20.6,1,30.5,2.9
			c7.2,1.4,14.3,3.2,21.2,5.5c55.3,18.5,97.3,65.8,108.2,124.1c1.3,7.1,2.2,14.3,2.6,21.7c0.1,2.8,0.2,5.5,0.2,8.3
			c0,21.7-4.3,42.4-12,61.3c16.6,6.7,33.8,12.1,51.7,16c8-21,12.9-43.6,14-67.1c0.1-3.4,0.3-6.8,0.3-10.2
			C19.3,232.7,19.2,229.9,19.1,227.1z"
              />
              <path
                className="st2"
                d="M19.1,227.1C15.1,124.3-60.6,39.8-159.4,22.4c-6.1-1.1-12.2-1.9-18.4-2.5c-6.4-0.6-12.9-0.9-19.5-0.9
			c-16.6,0-32.8,1.9-48.4,5.5c1.4,18.2,4.3,36.1,8.6,53.3c12.7-3.2,26.1-4.9,39.8-4.9c10.4,0,20.6,1,30.5,2.9
			c7.2,1.4,14.3,3.2,21.2,5.5c55.3,18.5,97.3,65.8,108.2,124.1c1.3,7.1,2.2,14.3,2.6,21.7c0.1,2.8,0.2,5.5,0.2,8.3
			c0,21.7-4.3,42.4-12,61.3c16.6,6.7,33.8,12.1,51.7,16c8-21,12.9-43.6,14-67.1c0.1-3.4,0.3-6.8,0.3-10.2
			C19.3,232.7,19.2,229.9,19.1,227.1z"
              />
              <path
                className="st1"
                d="M163.8,48.2c-17.3,32.1-51.3,53.9-90.3,53.9C16.9,102.1-29,56.3-29-0.2c0-52.6,39.7-95.8,90.8-101.6
			C30-129.9,9.9-170.9,9.9-216.6c0-3,0.1-5.9,0.3-8.9C-88.6-197.9-161-107.5-161-0.2c0,7.9,0.4,15.6,1.2,23.3
			C-61.1,40.5,14.5,124.7,18.5,227.3c17.7,4.2,36.1,6.5,55.1,6.5c117.6,0,215-86.4,231.9-199.1c-24.2,15.5-53,24.5-83.9,24.5
			C201.1,59.3,181.7,55.3,163.8,48.2z M-60.1-127.6c0-12.3,10-22.3,22.4-22.3c12.4,0,22.4,10,22.4,22.3c0,12.3-10,22.3-22.4,22.3
			C-50-105.3-60.1-115.3-60.1-127.6z"
              />
              <path
                className="st1"
                d="M314.7-243.3c-61.6-60.7-146.1-98.3-239.4-98.8c-0.6,0-1.2,0-1.8,0c-189.6,0-343.3,153.4-343.3,342.6
			c0,10.5,0.5,20.9,1.5,31.1c1.7,18.4,4.7,36.5,9.2,54c27,105.2,102.7,190.9,201.8,231.7c16.8,6.9,34.2,12.5,52.2,16.7
			c25.2,5.9,51.5,9.1,78.5,9.1c189.6,0,343.3-153.4,343.3-342.6C416.8-94.9,377.7-181.2,314.7-243.3z M73.5,320.4
			c-23.7,0-46.8-2.7-69.1-7.5c-17.8-3.9-35.1-9.3-51.6-16c-93.7-38-165.3-119-190.3-218.5c-4.3-17.2-7.2-35-8.6-53.2
			c-0.6-8.2-1.1-16.4-1.1-24.8c0-168.4,130.4-306.3,295.9-319c8.2-0.6,16.4-1,24.7-1c19,0,37.6,1.8,55.7,4.9
			c47,8.2,90.4,26.6,128,52.8c10.6,7.4,20.9,15.5,30.5,24.1c10.4,9.3,20.1,19.3,29.2,29.8c26.6,30.9,47.4,67,60.7,106.5
			c10.8,32,16.7,66.2,16.7,101.8C394.2,177.2,250.6,320.4,73.5,320.4z"
              />
              <g className="st3">
                <path
                  className="st4"
                  d="M153.5,45.4C102.5,21,66.9-30.4,65-90.3c-0.1-1.7-0.1-3.3-0.1-5c0-0.7,0-1.4,0.1-2.1
				c2.9,2.4,5.8,4.6,8.8,6.8c18.6,13.3,40.3,22.6,63.8,26.5c5.2,0.9,10.4,1.5,15.7,1.8c3.2,0.2,6.4,0.3,9.7,0.3
				c45.5,0,86.4-19.8,114.5-51.3c19,33.9,29.9,72.9,29.9,114.5c0,11.9-0.9,23.6-2.6,35c-24.2,15.5-53,24.5-83.9,24.5
				c-20.3,0-39.8-3.9-57.6-11C159.9,48.3,156.7,46.9,153.5,45.4z"
                />
              </g>
            </g>
          </g>
          <motion.ellipse
            style={{ originX: '55px', originY: '-30px' }}
            animate={{ rotate: -360 }}
            transition={{
              ease: 'linear',
              duration: 24,
              repeat: Infinity,
              delay: 0.9,
            }}
            id="big-circle"
            className="st5"
            cx="346.4"
            cy="88.4"
            rx="17.3"
            ry="17.3"
          />
          <motion.path
            style={{ originX: '55px', originY: '-30px' }}
            animate={{ rotate: 360 }}
            transition={{
              ease: 'linear',
              duration: 32,
              repeat: Infinity,
              delay: 0.9,
            }}
            id="small-circle"
            className="st5"
            d="M177,158.5c-12.4,0-22.4-10-22.4-22.3s10-22.3,22.4-22.3c12.4,0,22.4,10,22.4,22.3
	S189.4,158.5,177,158.5z"
          />
        </motion.svg>
      </TopLeftLogo>
    </WhiteBackground>
  )
}

export default WelcomePage
