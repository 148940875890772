import type { FormikProps } from 'formik'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import useQueryString from 'utils/hooks/useQueryString'
import { ALREADY_TAKEN_ERROR } from 'utils/constants/errors'
import { Nullable } from 'utils/types/common'
import {
  InboundForm,
  AccountTypes,
  CreateInboundSteps,
  InboundStatus,
  Inbound,
} from 'utils/types/inbounds'
import Toast from 'components/Toast'
import { useTestConnection } from 'containers/Inbounds/hooks/useTestConnection'

import { getValidationSchema } from './schemas'
import { useCreateInboundMutation } from '../../hooks'

export const initialInboundForm: InboundForm = {
  accountType: AccountTypes.GOOGLE_GROUP,
  email: '',
  allowMyGroupToReceiveSuggestedUpdates: false,
}

interface Props {
  isOpen: boolean
  onCloseDrawer: () => void
}

export const useCreateInbound = ({ isOpen, onCloseDrawer }: Props) => {
  const intl = useIntl()
  const formikRef = useRef<FormikProps<InboundForm>>(null)
  const history = useHistory()
  const currentStep = useQueryString(
    'step'
  ) as unknown as Nullable<CreateInboundSteps>
  const createInboundMutation = useCreateInboundMutation()
  const [inbound, setInbound] = useState<Inbound>()
  const isInboundCreated = useMemo(() => !!inbound, [inbound])
  const [status, setStatus] = useState(InboundStatus.AWAITING_CONNECTION)
  const { testConnection, isTestingConnection } = useTestConnection(
    inbound?.id!
  )

  const validationSchema = useMemo(
    () => getValidationSchema(currentStep),
    [currentStep]
  )

  const setCurrentStep = useCallback(
    (step: CreateInboundSteps) => {
      return history.push(`${history.location.pathname}?step=${step}`)
    },
    [history]
  )

  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case CreateInboundSteps.ACCOUNT_TYPE:
        setCurrentStep(CreateInboundSteps.EMAIL_ADDRESS)
        break
      case CreateInboundSteps.EMAIL_ADDRESS:
        setCurrentStep(CreateInboundSteps.CONNECT)
        break
      default:
        setCurrentStep(CreateInboundSteps.ACCOUNT_TYPE)
        break
    }
  }, [currentStep, setCurrentStep])

  const isStepValid = useCallback(
    (step: CreateInboundSteps) => {
      try {
        const schema = getValidationSchema(step)
        schema.validateSync(formikRef.current?.values)

        return true
      } catch {
        return false
      }
    },
    [formikRef]
  )

  const changeStep = useCallback(
    (nextStep: CreateInboundSteps) => {
      if (!isInboundCreated) {
        if (nextStep === CreateInboundSteps.ACCOUNT_TYPE) {
          setCurrentStep(nextStep)
          return true
        }

        if (
          nextStep === CreateInboundSteps.EMAIL_ADDRESS &&
          isStepValid(CreateInboundSteps.ACCOUNT_TYPE)
        ) {
          setCurrentStep(nextStep)
          return true
        }

        if (
          nextStep === CreateInboundSteps.CONNECT &&
          isStepValid(CreateInboundSteps.EMAIL_ADDRESS)
        ) {
          setCurrentStep(nextStep)
          return true
        }
      }

      return false
    },
    [isStepValid, setCurrentStep, isInboundCreated]
  )

  const getFooterActionText = useCallback(() => {
    switch (currentStep) {
      case CreateInboundSteps.CONNECT: {
        if (status !== InboundStatus.ACTIVE) {
          return intl.formatMessage({ id: 'inbounds.checkStatus' })
        }

        return intl.formatMessage({ id: 'general.done' })
      }
      case CreateInboundSteps.ACCOUNT_TYPE:
      case CreateInboundSteps.EMAIL_ADDRESS:
      default:
        return intl.formatMessage({ id: 'general.next' })
    }
  }, [currentStep, status, intl])

  const getFooterSecondaryActionText = useCallback(() => {
    switch (currentStep) {
      case CreateInboundSteps.CONNECT:
        return intl.formatMessage({ id: 'inbounds.doThisLater' })
      case CreateInboundSteps.ACCOUNT_TYPE:
      case CreateInboundSteps.EMAIL_ADDRESS:
      default:
        return intl.formatMessage({ id: 'general.cancel' })
    }
  }, [currentStep, intl])

  const handleOnSubmit = useCallback(
    async (values: InboundForm) => {
      try {
        const createdInbound = await createInboundMutation.mutateAsync({
          email: values.email,
          groupManaged: values.allowMyGroupToReceiveSuggestedUpdates,
        })

        setInbound(createdInbound)
        goToNextStep()
      } catch (error) {
        if (error?.message?.includes?.(ALREADY_TAKEN_ERROR)) {
          Toast.displayIntl('inbounds.createInbound.alreadyTakenError', 'error')
        } else {
          Toast.displayIntl('inbounds.createInbound.error', 'error')
        }
      }
    },
    [createInboundMutation, goToNextStep]
  )

  const onClose = useCallback(() => {
    const success = status === InboundStatus.ACTIVE
    const configuredEmail = inbound?.inboundEmail
    onCloseDrawer()
    setInbound(undefined)
    setStatus(InboundStatus.AWAITING_CONNECTION)

    if (success) {
      Toast.displayIntl([
        'inbounds.createInbound.success',
        { email: configuredEmail },
      ])
    }
  }, [inbound, status, onCloseDrawer])

  const onActionButtonClick = useCallback(async () => {
    if (currentStep === CreateInboundSteps.CONNECT) {
      if (status !== InboundStatus.ACTIVE) {
        testConnection(
          () => {
            setStatus(InboundStatus.ACTIVE)
          },
          () => {
            setStatus(InboundStatus.NOT_CONNECTED)
          }
        )
      } else {
        onClose()
      }
    } else if (currentStep === CreateInboundSteps.EMAIL_ADDRESS) {
      await formikRef.current?.submitForm()
    } else {
      goToNextStep()
    }
  }, [currentStep, goToNextStep, testConnection, onClose, status])

  useEffect(() => {
    if (isOpen) {
      setCurrentStep(CreateInboundSteps.ACCOUNT_TYPE)
    } else {
      history.push(history.location.pathname)
    }
  }, [history, isOpen, setCurrentStep])

  useEffect(() => {
    formikRef.current?.validateForm()
  }, [validationSchema])

  return {
    stepsHandlers: {
      goToNextStep,
      changeStep,
      currentStep,
    },
    validationSchema,
    formikRef,
    inboundEmail: inbound?.inboundEmail,
    status,
    isLoading: createInboundMutation.isLoading,
    isTestingConnection,
    getFooterActionText,
    getFooterSecondaryActionText,
    handleOnSubmit,
    onActionButtonClick,
    onClose,
  }
}
