import { GroupTypes } from 'utils/constants/groups'
import { GroupType } from 'utils/types/user'
import { getCurrentGroupType, getIsReadOnlyMode } from './auth'

export const groupTypeHasChatFeature = (groupType: GroupType) => {
  return (
    groupType !== GroupTypes.INVESTOR_GROUP && groupType !== GroupTypes.FOUNDER
  )
}

export const getHasChatFeature = (state) => {
  const currentGroupType = getCurrentGroupType(state)
  const isReadOnly = getIsReadOnlyMode(state)
  return (
    currentGroupType && !isReadOnly && groupTypeHasChatFeature(currentGroupType)
  )
}
