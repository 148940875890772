import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useChatSearchContext } from 'containers/Chat/hooks/useChatSearch'
import { useChatSidebarContext } from 'containers/Chat/hooks'
import { useResizeObserver } from 'utils/hooks/useResizeObserver'

import * as Styles from './ChatSearchInput.styles'

const ACCEPTABLE_WIDTH_THRESHOLD = 1
const CHAT_SEARCH_CONTAINER_ID = 'search-container'

interface Props {
  className?: string
}

const ChatSearchInput: React.FC = ({ className }: Props) => {
  const intl = useIntl()
  const { search, setSearch } = useChatSearchContext()
  const { showSearchView, hideArchiveSidebar } = useChatSidebarContext()
  const { width: searchContainerWidth } = useResizeObserver(
    CHAT_SEARCH_CONTAINER_ID
  )

  const onSearchButtonClick = useCallback(() => {
    showSearchView()
    hideArchiveSidebar()
  }, [hideArchiveSidebar, showSearchView])

  const blurActiveElement = useCallback(() => {
    if (
      document.activeElement &&
      'blur' in document.activeElement &&
      typeof document.activeElement?.blur === 'function'
    ) {
      document.activeElement!.blur()
    }
  }, [])

  return (
    <Styles.SearchInputContainer
      id={CHAT_SEARCH_CONTAINER_ID}
      className={className}
    >
      <Styles.SearchInput
        prefix={
          <FontAwesomeIcon
            onClick={blurActiveElement}
            icon={['far', 'arrow-left']}
          />
        }
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        icon={search ? ['far', 'times'] : ['far', 'search']}
        iconFontSize="1.6rem"
        onClickIcon={() => {
          setSearch('')
        }}
        placeholder={intl.formatMessage({ id: 'general.searchEllipsis' })}
        onFocus={onSearchButtonClick}
        showLeftArrow={
          searchContainerWidth <
          Styles.SEARCH_INPUT_WIDTH - ACCEPTABLE_WIDTH_THRESHOLD
        }
      />
    </Styles.SearchInputContainer>
  )
}

export default ChatSearchInput
