import React from 'react'
import { useIntl } from 'react-intl'
import { useChannelStateContext } from 'stream-chat-react'

import Toast from 'components/Toast'
import StackedAvatarList from 'components/StackedAvatarList/StackedAvatarList'
import { StreamChatType } from 'containers/Chat/types'
import IconButton from 'ui/IconButton'
import { MinimizeIcon } from 'ui/Icons'
import OptionsDropdown from 'ui/OptionsDropdown'
import theme from 'utils/theme'
import AddParticipantsModal from '../AddParticipantsModal'
import LeaveModal from '../LeaveChannelModal'
import MuteChatModal from '../MuteChatModal'
import MutedIndicator from '../MutedIndicator'
import ViewParticipants from '../ViewParticipants'
import YouLabel from '../YouLabel/YouLabel'
import { UndoArchive } from '../UndoArchive'
import { MobileNavigationArrow } from '../ChatMobile/ChatMobile.styles'
import * as Styles from './ChatHeader.styles'
import ChatHeaderMembersTooltip from './ChatHeaderMembersTooltip'
import { useChatHeader } from './useChatHeader'

const MAX_AVATARS = 6

const ChatHeader: React.FC = () => {
  const intl = useIntl()
  const { channel } = useChannelStateContext<StreamChatType>()

  const {
    members,
    isChannelModerator,
    chatTitle,
    isUserPersonalChat,
    addParticipantsModal,
    participantsModal,
    muteModal,
    leaveModal,
    membersForAvatars,
    isChannelMuted,
    isPinned,
    isATopicChannel,
    handleMute,
    togglePinChannel,
    openChatAsPopup,
    isArchived,
    toggleArchiveChannel,
    onUndoArchiveChannel,
    onChannelGoBack,
    hideMembers,
    isChatPopupEnabled,
    renderIconsOnDropdown,
  } = useChatHeader(channel)

  const onToggleArchiveChannel = () => {
    toggleArchiveChannel(channel.id!)
    if (!isArchived) {
      Toast.displayIntl(
        'chat.conversationArchived',
        undefined,
        5000,
        UndoArchive(onUndoArchiveChannel)
      )
    }
  }

  const renderMinimizeIcon = () => {
    return (
      <IconButton
        onClick={openChatAsPopup}
        customIcon={<MinimizeIcon />}
        buttonType="square"
        buttonSize="3.2rem"
        color={theme.colors.black}
        border={false}
        borderOnFocus={false}
        primary
      />
    )
  }

  return (
    <>
      <Styles.Container>
        <Styles.LeftContainer>
          <MobileNavigationArrow
            onClick={() => onChannelGoBack(channel)}
            icon={['fal', 'chevron-down']}
          />
          <Styles.Names isATopicChannel={isATopicChannel}>
            {chatTitle}
            {isUserPersonalChat && <YouLabel />}
          </Styles.Names>
          <MutedIndicator />
        </Styles.LeftContainer>
        <Styles.RightContainer>
          {membersForAvatars.length > 0 && !hideMembers && (
            <StackedAvatarList
              avatars={membersForAvatars}
              maxAvatars={MAX_AVATARS}
              withTooltip
              tooltipContent={
                <ChatHeaderMembersTooltip avatars={membersForAvatars} />
              }
            />
          )}
          {!isArchived && !isATopicChannel && (
            <IconButton
              onClick={togglePinChannel}
              iconFamily={isPinned ? 'fas' : 'far'}
              icon="thumbtack"
              buttonType="square"
              buttonSize="3.2rem"
              color={isPinned ? theme.colors.primaryBlue : theme.colors.black}
              border={false}
              borderOnFocus={false}
              primary
            />
          )}
          {isChatPopupEnabled && !renderIconsOnDropdown && renderMinimizeIcon()}
          <OptionsDropdown
            buttonType="square"
            buttonSize="3.2rem"
            icon={['far', 'ellipsis-v-alt']}
          >
            {isChatPopupEnabled && renderIconsOnDropdown && (
              <Styles.OptionsDropdownItem
                label={intl.formatMessage({ id: 'chat.popupChatWindow' })}
                component={<MinimizeIcon />}
                onSelectOption={openChatAsPopup}
              />
            )}

            {!isArchived && isChannelModerator && (
              <OptionsDropdown.Item
                label={intl.formatMessage({ id: 'chat.addParticipants' })}
                icon={['far', 'user-plus']}
                onSelectOption={addParticipantsModal.onShow}
              />
            )}
            {!isArchived && (
              <OptionsDropdown.Item
                label={intl.formatMessage({ id: 'chat.chatDetails' })}
                icon={['far', 'file-alt']}
                onSelectOption={participantsModal.onShow}
              />
            )}
            {!isArchived &&
              (isChannelMuted ? (
                <OptionsDropdown.Item
                  label={intl.formatMessage({ id: 'chat.unmute' })}
                  icon={['far', 'bell']}
                  onSelectOption={handleMute}
                />
              ) : (
                <OptionsDropdown.Item
                  label={intl.formatMessage({ id: 'chat.mute' })}
                  icon={['far', 'bell-slash']}
                  onSelectOption={handleMute}
                />
              ))}
            {isATopicChannel && (
              <OptionsDropdown.Item
                label={intl.formatMessage({
                  id: isArchived ? 'chat.unarchive' : 'chat.archive',
                })}
                icon={['far', isArchived ? 'box-open' : 'archive']}
                onSelectOption={onToggleArchiveChannel}
              />
            )}

            {isATopicChannel && (
              <OptionsDropdown.Item
                label={intl.formatMessage({ id: 'chat.leave' })}
                icon={['far', 'arrow-from-left']}
                onSelectOption={leaveModal.onShow}
              />
            )}
          </OptionsDropdown>
        </Styles.RightContainer>
      </Styles.Container>
      <AddParticipantsModal
        show={addParticipantsModal.show}
        onHide={addParticipantsModal.onHide}
        currentMemberIds={members.map(({ user }) => user!.id)}
      />
      <ViewParticipants
        isOpen={participantsModal.show}
        onClose={participantsModal.onHide}
        members={membersForAvatars}
      />
      <MuteChatModal show={muteModal.show} onHide={muteModal.onHide} />
      <LeaveModal show={leaveModal.show} onHide={leaveModal.onHide} />
    </>
  )
}

export default ChatHeader
