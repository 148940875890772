import { Route } from 'react-router-dom'
import ProtectedRoute from 'components/ProtectedRoute'
import { SHOW_NEW_INVESTMENTS_FEATURE } from 'utils/constants/features'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import InvestmentsRoutes from './InvestmentsRoutes'
import SidebarMetricsRoutes from './SidebarMetricsRoutes'
import {
  INVESTMENTS,
  INVESTMENTS_METRICS_ID,
} from './constant/investmentsRoutes'

const InvestmentsFeatureRoutes = () => {
  const planFeatures = usePlanFeatures()

  return SHOW_NEW_INVESTMENTS_FEATURE ? (
    <ProtectedRoute path={INVESTMENTS}>
      <InvestmentsRoutes />
      {planFeatures.areMetricsEnabled && (
        <Route path={INVESTMENTS_METRICS_ID}>
          <SidebarMetricsRoutes urlPrefix="/investments/metrics" />
        </Route>
      )}
    </ProtectedRoute>
  ) : null
}

export default InvestmentsFeatureRoutes
