import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledInvestmentVehicles = styled.div``

export const NoVehiclesForInvestorFunds = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  color: ${color('lightGray')};
  text-align: center;
  padding: 2rem 0 6rem 0;
`
