import React from 'react'
import { FormattedMessage } from 'react-intl'
import Modal from 'components/Modal'
import Button from 'ui/Button'
import { DATE_FORMATS, formattedDate } from 'utils/functions/date'

import { Actions, Body } from './ConfirmationDateModal.styles'

interface ConfirmationDateModalProps {
  isOpen: boolean
  date: Date
  togglePublishUpdateDateModal: () => void
  publishOnSelectedDate: () => void
  changeToToday: () => Promise<void>
}

const ConfirmationDateModal: React.FC<ConfirmationDateModalProps> = ({
  isOpen,
  date,
  togglePublishUpdateDateModal,
  publishOnSelectedDate,
  changeToToday,
}) => {
  const onPublishTodayHandler = () => {
    changeToToday()
    togglePublishUpdateDateModal()
  }

  const onPublishOnSelectedDateHandler = () => {
    publishOnSelectedDate()
    togglePublishUpdateDateModal()
  }

  return (
    <Modal
      show={isOpen}
      onHide={togglePublishUpdateDateModal}
      hideOnOutsideClick
    >
      <Modal.Header onHide={togglePublishUpdateDateModal}>
        <Modal.Title>
          <FormattedMessage id="updates.publishUpdate" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Body>
          <FormattedMessage
            id="updates.publishedDate"
            values={{
              publishDate: formattedDate(date, DATE_FORMATS.DDMM_YYYY),
            }}
          />
          <br />
          <FormattedMessage id="updates.changeUpdateDate" />
        </Body>
      </Modal.Body>

      <Modal.Footer>
        <Actions>
          <Button
            onClick={onPublishOnSelectedDateHandler}
            type="button"
            secondary
          >
            <FormattedMessage id="updates.publishSelectedDate" />
          </Button>
          <Button primary type="button" onClick={onPublishTodayHandler}>
            <FormattedMessage id="updates.publishToday" />
          </Button>
        </Actions>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationDateModal
