import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const TableContainer = styled.div<{ embeddedTable?: boolean }>`
  display: flex;
`

export const FixedWrapper = styled.div<{
  width: number
  noScrollableColumns: boolean
}>`
  width: ${(props) => props.width}px;
  z-index: 2;
  border-right: 0.2rem solid #e9e9e9;
  box-shadow: 2px 0px 1px rgba(120, 126, 140, 0.1);

  ${(props) =>
    props.noScrollableColumns &&
    css`
      width: 100%;
      border-right: 0;
      box-shadow: none;
    `}
`

export const StyledScrollableContainer = styled.div<{
  scrollEnabled?: boolean
  embeddedTable?: boolean
  isVisible?: boolean
  scrollableContainerPaddingBottom?: string
  scrollableContainerMarginBottom?: string
}>`
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  flex: 1;

  ${(props) =>
    props.embeddedTable
      ? css`
          width: 100%;
          margin-bottom: 1rem;
        `
      : css`
          padding-bottom: ${props.scrollableContainerPaddingBottom || '1rem'};
          margin-bottom: ${props.scrollableContainerMarginBottom || '1.2rem'};
        `}

  ${({ isVisible }) =>
    isVisible
      ? css`
          &::-webkit-scrollbar {
            width: 0.8rem;
            height: 0.8rem;
          }
        `
      : css`
          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }
        `}

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${color('lightGray')};
    height: 0.6rem;
    width: 5rem;
    border-radius: 0.7rem;
  }
  scrollbar-width: 10rem;
`
