import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMessageContext } from 'stream-chat-react'
import { useQueryClient } from '@tanstack/react-query'
import { StreamChatType } from 'containers/Chat/types'
import { useFetchUpdateQuery } from 'utils/hooks/queries/useUpdateQuery'
import { UpdateType } from 'utils/types/common'
import SuggestedMessageBox from '../SuggestedMessageBox'
import { BoldText, LightText } from './NoUpdateAccess.styles'

type Props = {
  updateId: string
  updateType: UpdateType
}

const RequestUpdateAccess: React.FC<Props> = ({ updateId, updateType }) => {
  const [visible, setVisible] = useState<boolean>(true)
  const queryClient = useQueryClient()

  const { error } = useFetchUpdateQuery({
    updateId,
    updateType,
    preventRedirectOnPermissionsError: true,
    retry: false,
  })

  const { isMyMessage } = useMessageContext<StreamChatType>()

  const permissionsError = error && error?.status === 403

  useEffect(() => {
    if (!visible) {
      queryClient.setQueryData(
        ['update-access-message-visible', updateId],
        false
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  useEffect(() => {
    const cachedVisible = queryClient.getQueryData<boolean>([
      'update-access-message-visible',
      updateId,
    ])

    if (cachedVisible !== undefined) {
      setVisible(cachedVisible)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!permissionsError || isMyMessage() || !visible) {
    return null
  }

  return (
    <SuggestedMessageBox
      text={
        <FormattedMessage
          id="chat.noUpdateAccess"
          values={{
            bold: (txt) => <BoldText>{txt}</BoldText>,
            light: (txt) => <LightText>{txt}</LightText>,
          }}
        />
      }
      onClose={() => setVisible(false)}
    />
  )
}

export default RequestUpdateAccess
