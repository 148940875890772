import { ComponentType, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useChatContext } from 'stream-chat-react'
import { Channel as StreamChannel } from 'stream-chat'

import Tooltip from 'components/Tooltip'
import { ChannelData, StreamChatType } from 'containers/Chat/types'
import { useChatTitle } from 'containers/Chat/hooks/useChatTitle'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import { ChannelList, ChannelName, Container } from './ChannelDropdown.styles'
import TooltipContent from '../TooltipContent'

interface ChannelDropdownProps<ChannelType extends ChannelData> {
  className?: string
  channels: StreamChannel<StreamChatType<ChannelType>>[]
  Channel: ComponentType<{
    channel: StreamChannel<StreamChatType<ChannelType>>
  }>
}

const ChannelDropdown = <ChannelType extends ChannelData>({
  className,
  channels,
  Channel,
}: ChannelDropdownProps<ChannelType>) => {
  const { channel } = useChatContext<StreamChatType<ChannelType>>()
  const channelTitle = useChatTitle(channel)
  const [areChannelsVisible, setAreChannelsVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const toggleAreChannelsVisible = () => {
    setAreChannelsVisible((current) => !current)
  }

  useOutsideClick(ref, () => setAreChannelsVisible(false))

  return (
    <Container className={className}>
      <ChannelName
        ref={ref}
        onClick={toggleAreChannelsVisible}
        isOpen={areChannelsVisible}
      >
        <Tooltip
          backgroundColor="transparent"
          place="top"
          offset={-10}
          text={<TooltipContent>{channelTitle}</TooltipContent>}
        >
          {channelTitle}
        </Tooltip>
        <FontAwesomeIcon icon={['far', 'chevron-down']} />
      </ChannelName>
      {areChannelsVisible && (
        <ChannelList>
          {channels.map((currentChannel) => {
            return <Channel key={currentChannel.id} channel={currentChannel} />
          })}
        </ChannelList>
      )}
    </Container>
  )
}

export default ChannelDropdown
