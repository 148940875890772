import { useEffect, useRef, useState } from 'react'

const useCloseButton = () => {
  const [showOptions, setShowOptions] = useState(false)

  const optionsRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setShowOptions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return {
    showOptions,
    setShowOptions,
    optionsRef,
  }
}

export default useCloseButton
