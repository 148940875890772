import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PaymentElement } from '@stripe/react-stripe-js'
import dayjs from 'dayjs'

import CWLoader from 'components/CWLoader/CWLoader'
import { SubscriptionBillingPeriod } from 'utils/types/subscriptions'
import { ContinueButton } from 'containers/Signup/Signup.styles'
import { TRIAL_DAYS } from 'utils/constants/subscriptions'
import {
  Container,
  ImmediateBilling,
  Link,
  PoweredBy,
  Support,
  ErrorMessage,
} from './PaymentForm.styles'
import { usePaymentForm } from './usePaymentForm'

interface Props {
  onPaymentSuccess: (paymentIntentId: string) => void
  paymentError?: string
  isTrial: boolean
  billingPeriod: SubscriptionBillingPeriod
}

const PaymentForm = ({
  onPaymentSuccess,
  paymentError: paymentErrorProp,
  isTrial: _isTrial,
  billingPeriod,
}: Props) => {
  const {
    handleSubmit,
    onPaymentElementReady,
    onPaymentElementChange,
    paymentElementLoading,
    formIsComplete,
    isLoading,
    paymentError,
  } = usePaymentForm(onPaymentSuccess, paymentErrorProp)

  return (
    <>
      {paymentError && <ErrorMessage>{paymentError}</ErrorMessage>}
      <Container>
        {paymentElementLoading && <CWLoader />}

        <PaymentElement
          className="payment-element"
          onChange={onPaymentElementChange}
          onLoaderStart={onPaymentElementReady}
        />

        <ContinueButton
          id="signup-payment-continue-button"
          primary
          onClick={handleSubmit}
          disabled={!formIsComplete || isLoading}
          isLoading={isLoading}
        >
          <FormattedMessage id="general.finish" />
        </ContinueButton>

        <PoweredBy>
          <FontAwesomeIcon icon={['far', 'lock']} />
          <FormattedMessage
            id="signup.payment.poweredBy"
            values={{
              link: (
                <Link href="https://stripe.com/es-us/privacy" target="_blank">
                  <FormattedMessage id="signup.payment.stripe" />
                </Link>
              ),
            }}
          />
        </PoweredBy>
        <ImmediateBilling>
          <FormattedMessage
            id={
              billingPeriod === SubscriptionBillingPeriod.MONTHLY
                ? 'signup.payment.monthlyBilling'
                : 'signup.payment.annualBilling'
            }
            values={{
              date: (
                <b>{dayjs().add(TRIAL_DAYS, 'days').format('DD MMMM YYYY')}</b>
              ),
              help: (
                <Support onClick={() => window.Intercom('show')}>
                  <FormattedMessage id="signup.payment.help" />
                </Support>
              ),
            }}
          />
        </ImmediateBilling>
      </Container>
    </>
  )
}

export default PaymentForm
