import styled from 'styled-components'
import { motion } from 'framer-motion'
import { color } from 'utils/functions/colors'

export const ReshareHistoryList = styled(motion.ul)`
  margin-top: 1.5rem;
  padding-top: 2.4rem;
  margin-left: 0.8rem;
  border-top: ${color('veryLightGray')} 0.1rem solid;
`
export const ReshareHistoryListItem = styled(motion.li)`
  margin-bottom: 1.6rem;
`

export const ReshareRowContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const ReshareRowTitleContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  font-size: 1.4rem;
  color: ${color('darkGray')};
`

export const MessageTitle = styled.div`
  display: flex;
  gap: 0.4rem;
  max-width: 85%;
`

export const ReshareRowDate = styled.p`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0.4rem 0;
`

export const ReshareRowContent = styled.div`
  width: 100%;
`

export const ReshareMessage = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('lightGray')};
`

export const EditReshareContainer = styled.div`
  position: relative;
  button {
    position: absolute;
    left: 0.2rem;
    top: -1.2rem;
  }
`

export const ReshareAvatarIcon = styled.div`
  width: 2.8rem;
  height: 2.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 100%;
  background-color: ${color('veryLightBlue')};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: white;
    font-size: 1.3rem;
    width: 2.8rem;
  }
`

export const ReshareWithDiv = styled.div`
  font-size: 1.4rem;
  font-weight: normal;
  color: ${color('darkGray')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 85%;
`

export const Names = styled.span`
  font-weight: 700;
`

export const CountReshare = styled.span`
  font-weight: 700;
  color: ${color('primaryBlue')};
`
