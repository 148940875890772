import { FormattedMessage } from 'react-intl'
import googleLogoPath from 'assets/images/google-logo.png'

import { GoogleButton } from './GoogleLoginButton.styles'

interface Props {
  onClick: () => void
  className?: string
  isLoading?: boolean
}

const GoogleLoginButton = ({ isLoading, className, onClick }: Props) => {
  return (
    <GoogleButton
      type="button"
      className={className}
      onClick={onClick}
      disabled={isLoading}
    >
      <img src={googleLogoPath} alt="Google logo" />
      <span>
        <FormattedMessage id="login.googleSignIn" />
      </span>
    </GoogleButton>
  )
}

export default GoogleLoginButton
