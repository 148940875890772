import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import Button from 'ui/Button'
import Modal from 'components/Modal'
import { ToastLabel } from 'components/ToastMessage/ToastMessage.styles'

export const Description = styled.span`
  color: ${color('darkGray')};
  font-size: 14px;
`

export const GridContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.7rem;
`

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  button {
    text-transform: capitalize;
  }
`

export const AddMultipleDatesButton = styled(Button)`
  color: ${color('darkBlue')};
  align-self: end;
`

export const Footer = styled(Modal.Footer)`
  padding: 2.4rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`

export const Disclaimer = styled.div`
  margin-top: 1rem;

  ${ToastLabel} {
    flex: 1;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    gap: 0.6rem;
  }
`

export const DismissIcon = styled.div`
  cursor: pointer;

  svg {
    color: ${color('darkBlue')};
    font-size: 2rem;
    margin: 0;
  }
`
