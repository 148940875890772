import styled from 'styled-components'

export const ByDateFilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
`

export const ByNameFilesContainer = styled.div<{
  isModalBrowseFileView: boolean
}>`
  padding: ${({ isModalBrowseFileView }) =>
    isModalBrowseFileView ? '2.5rem' : '0'};
  display: flex;
  gap: 2.5rem;
  flex-wrap: wrap;
`
