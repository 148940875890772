import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import { ReactComponent as HeroSection } from 'assets/images/cancel-feedback-hero.svg'
import AccountSetupLayout from 'layouts/AccountSetupLayout'
import Button from 'ui/Button'
import IntercomService from 'api/IntercomService'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getSubscriptionData } from 'selectors/subscriptions'
import * as Styles from './CancelSubscriptionFeedback.styles'

export enum ReasonForLeaving {
  NOT_WHAT_LOOKING_FOR = 'NOT_WHAT_LOOKING_FOR',
  NOT_EASY_TO_USE = 'NOT_EASY_TO_USE',
  TOO_EXPENSIVE = 'TOO_EXPENSIVE',
  OTHER = 'OTHER',
}

const CancelSubscriptionFeedback: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const { matches: isMobile } = useMediaQuery(Media.maxWidth(500))
  const heroSectionWidth = isMobile ? '90vw' : '49.8rem'
  const [isLoading, setIsLoading] = useState(false)
  const [improvements, setImprovements] = useState('')
  const [reasonForLeaving, setReasonForLeaving] = useState<ReasonForLeaving>()
  const subscriptionData = useAppSelector(getSubscriptionData)
  const sendFeedback = async () => {
    try {
      setIsLoading(true)

      if (reasonForLeaving) {
        const message = `
        Reason for leaving: ${reasonForLeaving.replaceAll('_', ' ')}
        
        Feedback and improvements:
        
        ${improvements || 'N/A'}
        `
        await IntercomService.sendMessageToAdmin(message)
      }
    } finally {
      history.replace('/settings/group_management/subscriptions')
    }
  }

  return (
    <AccountSetupLayout>
      <AccountSetupLayout.Header />
      <div>
        <Styles.FormContainer>
          <HeroSection width={heroSectionWidth} height="33.9rem" />
          <Styles.Title>
            <FormattedMessage id="subscriptions.cancelFeedback.title" />
          </Styles.Title>
          <Styles.Description>
            <FormattedMessage
              id="subscriptions.cancelFeedback.readOnlyDisclaimer"
              values={{
                expirationDate: dayjs(
                  subscriptionData?.stripeNextBillingDate
                ).format('MMMM DD, YYYY '),
              }}
            />
          </Styles.Description>
          <Styles.Description>
            <FormattedMessage id="subscriptions.cancelFeedback.answerSomeQuestions" />
          </Styles.Description>

          <Styles.Question>
            <FormattedMessage id="subscriptions.cancelFeedback.reasonForLeaving" />
          </Styles.Question>
          <Styles.RadioButtons>
            <Styles.StyledRadioButton
              name="reasonForLeaving"
              label=""
              value={reasonForLeaving || ''}
              uncheckedWithOpacity={false}
              onChange={(value: ReasonForLeaving) => {
                setReasonForLeaving(value)
              }}
              options={[
                {
                  id: ReasonForLeaving.NOT_WHAT_LOOKING_FOR,
                  label: intl.formatMessage({
                    id: 'subscriptions.cancelFeedback.reasonsForLeaving.notWhatLookingFor',
                  }),
                  value: ReasonForLeaving.NOT_WHAT_LOOKING_FOR,
                },
                {
                  id: ReasonForLeaving.NOT_EASY_TO_USE,
                  label: intl.formatMessage({
                    id: 'subscriptions.cancelFeedback.reasonsForLeaving.notEasyToUse',
                  }),
                  value: ReasonForLeaving.NOT_EASY_TO_USE,
                },
                {
                  id: ReasonForLeaving.TOO_EXPENSIVE,
                  label: intl.formatMessage({
                    id: 'subscriptions.cancelFeedback.reasonsForLeaving.tooExpensive',
                  }),
                  value: ReasonForLeaving.TOO_EXPENSIVE,
                },
                {
                  id: ReasonForLeaving.OTHER,
                  label: intl.formatMessage({
                    id: 'subscriptions.cancelFeedback.reasonsForLeaving.other',
                  }),
                  value: ReasonForLeaving.OTHER,
                },
              ]}
              direction="column"
              marginLeft="0"
            />
          </Styles.RadioButtons>

          <Styles.Question>
            <FormattedMessage id="subscriptions.cancelFeedback.improvements" />
          </Styles.Question>
          <Styles.StyledTextArea
            value={improvements}
            onChange={(event) => setImprovements(event.target.value)}
            rows={4}
            placeholder={intl.formatMessage({
              id: 'subscriptions.cancelFeedback.improvementsPlaceholder',
            })}
          />

          <Styles.LoginButtonContainer>
            <Button
              autoWidth
              primary
              blue
              onClick={sendFeedback}
              isLoading={isLoading}
            >
              <FormattedMessage id="subscriptions.cancelFeedback.backToUniverse" />
            </Button>
          </Styles.LoginButtonContainer>
        </Styles.FormContainer>
      </div>
    </AccountSetupLayout>
  )
}

export default CancelSubscriptionFeedback
