import styled from 'styled-components/macro'
import { createGlobalStyle, css } from 'styled-components'

import { color } from 'utils/functions/colors'
import TooltipToStyle from 'components/Tooltip'
import { UpdateTextContainer } from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import { AttachmentsContainer as AttachmentsContainerToStyle } from 'components/UpdateFactory'
import ButtonToStyle from 'ui/Button'

const hoverStyles = css`
  box-shadow: inset 0 0 0 0.2rem ${color('veryLightBlue')};
`

export const Card = styled.div<{
  isDiscardMode: boolean
  hoverState?: boolean
}>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.4rem 2.4rem 1.2rem;
  gap: 0.8rem;
  background-color: ${color('softGray')};
  box-shadow: inset 0 0 0px 0.1rem ${color('veryLightGray')},
    0px 0px 1.5rem ${color('darkBlue', 0.1)};
  border-radius: 0.8rem;
  width: 100%;
  min-width: 0;
  max-width: 90rem;
  transition: box-shadow 0.2s;

  &:hover {
    ${hoverStyles}
  }
  ${({ hoverState }) => hoverState && hoverStyles};

  ${({ isDiscardMode }) =>
    isDiscardMode &&
    css`
      padding-top: 2rem;
    `}
`

const FontStyles = css`
  color: ${color('darkGray')};
  font-weight: 400;
`

export const Title = styled.h4`
  ${FontStyles}
  font-size: 1.6rem;
  line-height: 2.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TitleTooltipStyles = createGlobalStyle`
  div.suggested-update-tooltip { 
    max-width: 66vw;
  }
`

export const DescriptionContainer = styled.div`
  position: relative;
  width: 100%;

  ${UpdateTextContainer} {
    margin-top: 0rem;
  }
`

export const Tooltip = styled(TooltipToStyle)`
  width: 100%;

  a {
    display: inline-flex;
    max-width: 100%;
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 0.4rem;
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
`

export const Button = styled(ButtonToStyle)<{
  link?: boolean
}>`
  padding: 0.8rem 1.2rem;
  border-color: ${color('lightGray', 0.2)};

  ${({ link }) =>
    link &&
    css`
      border-color: transparent;
      color: ${color('darkGray')};
    `}
`

export const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const AttachmentsContainer = styled(AttachmentsContainerToStyle)`
  width: 100%;
`
