import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  CardContainer,
  IconsContainer,
} from './CollapsedPermissionsTagsCard.styles'

const CollapsedPermissionsTagsCard = ({
  onCardExpanded,
  showLockIcon,
  showShareIcon,
}) => {
  return (
    <CardContainer>
      <FontAwesomeIcon
        onClick={onCardExpanded}
        icon={['far', 'chevron-left']}
      />
      <IconsContainer>
        {showLockIcon && <FontAwesomeIcon icon={['far', 'lock-alt']} />}
        <FontAwesomeIcon icon={['far', 'tag']} />
        {showShareIcon && <FontAwesomeIcon icon={['far', 'share-square']} />}
      </IconsContainer>
    </CardContainer>
  )
}

CollapsedPermissionsTagsCard.propTypes = {
  onCardExpanded: PropTypes.func.isRequired,
  showLockIcon: PropTypes.bool,
  showShareIcon: PropTypes.bool,
}

CollapsedPermissionsTagsCard.defaultProps = {
  showShareIcon: true,
  showLockIcon: true,
}

export default CollapsedPermissionsTagsCard
