import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CopyContainer = styled.div`
  cursor: pointer;
  background-color: ${color('lightGray', 0.15)};
  border-radius: 0.8rem;
  color: ${color('primaryBlue')};
  font-size: 1.4rem;
  padding: 1rem 1.6rem;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: flex;
  }

  span {
    width: 100%;
  }
`

export const CopyButton = styled.button`
  svg {
    color: black;
  }
  &:focus {
    outline: none;
  }
`

export const CopyTooltipContainer = styled.div`
  background-color: ${color('primaryBlue')};
  border-radius: 0.8rem;
  padding: 0.8rem;
`

export const UpdateLink = styled.span`
  word-break: break-all;
  margin-right: 0.5rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
