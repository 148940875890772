import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg'
import { isFileFormatImage, MixedContent } from 'utils/types/files'
import { isIndexFileContent } from 'utils/functions/files'
import { useMixedContent } from 'utils/hooks/contents/useMixedContent'
import CWLoader from 'components/CWLoader'

import {
  IconWrapper,
  CloseIconWrapper,
  GridFile,
  PreviewImage,
  ImageContainer,
  PreviewItem,
  FileIcon,
} from './FileGridPreview..styles'

export interface FilePreviewProps {
  content: MixedContent
  canDelete?: boolean
  isLoading?: boolean
  handleOnClick: () => void
  handleDelete: () => void
}
const FileGridPreview: React.FC<FilePreviewProps> = ({
  content,
  canDelete,
  isLoading,
  handleDelete,
  handleOnClick,
}) => {
  const isFileContent = isIndexFileContent(content)
  const { isEmailContent, contentName, fileFormat } = useMixedContent(content)

  if (isLoading) {
    return (
      <GridFile
        onClick={handleOnClick}
        role="button"
        tabIndex={0}
        isLoading={isLoading}
      >
        <PreviewItem>
          <div>
            <CWLoader />
          </div>
          <span className="loading">{contentName}</span>
        </PreviewItem>
      </GridFile>
    )
  }

  const renderPreviewItem = () => {
    return isFileContent && isFileFormatImage(content.fileFormat) ? (
      <PreviewImage>
        <ImageContainer style={{ backgroundImage: `url(${content.url})` }} />
        <div>
          <span>{contentName}</span>
        </div>
      </PreviewImage>
    ) : (
      <PreviewItem>
        <div>
          <FileIcon
            type={fileFormat}
            isEmailContent={isEmailContent}
            hasBorder
          />
        </div>
        <span>{contentName}</span>
      </PreviewItem>
    )
  }

  return (
    <GridFile onClick={handleOnClick} role="button" tabIndex={0}>
      <IconWrapper>
        {canDelete && (
          <CloseIconWrapper>
            <CloseIcon
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                handleDelete()
              }}
            />
          </CloseIconWrapper>
        )}
      </IconWrapper>
      {renderPreviewItem()}
    </GridFile>
  )
}

export default FileGridPreview
