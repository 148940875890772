import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'
import { ReactChildren } from 'utils/types/common'
import { DEFAULT_CELL_HEIGHT } from 'components/Spreadsheet/utils'
import { ARROW_WIDTH, CELL_PADDING } from '../../useUpdateMetricsGrid'

const AVATAR_SIZE = 24

export const Container = styled.div<{ isInvalid?: boolean }>`
  width: 100%;
  height: 100%;

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      height: 100%;
      border: 1px solid ${color('red')};
    `}
`

export const DropdownContent = styled.div<{ top: number; width?: string }>`
  position: absolute;
  top: ${({ top }) => top || DEFAULT_CELL_HEIGHT}px;
  left: -2px;
  background-color: white;
  width: ${({ width }) => width || '22rem'};
  border: 1px solid ${color('veryLightGray')};
  min-height: 2rem;
  padding: 1rem;
  border-radius: 0.8rem;
  box-shadow: 0 0.4rem 1rem 0 rgba(55, 56, 60, 0.16);
`

export const IconContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  width: ${ARROW_WIDTH}px;
  height: ${ARROW_WIDTH}px;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.2rem;

  :hover {
    background-color: ${color('lightGray')};
    color: white;
  }
`

export const Options = styled.ul`
  overflow: auto;
  max-height: 20rem;
`

export const Option = styled.li<{ selected: boolean; highlighted: boolean }>`
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.8rem;
  font-weight: 700;
  color: ${color('darkBlue')};

  :hover {
    ${({ selected }) =>
      !selected &&
      css`
        background: ${color('veryLightBlue', 0.2)};
        color: ${color('primaryBlue')};
      `}
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${color('veryLightBlue', 0.2)};
      color: ${color('primaryBlue')};
    `}

  ${({ highlighted }) =>
    highlighted &&
    css`
      background-color: ${color('primaryBlue')};
      color: white;
    `}
`

export const SelectedOption = styled.span<{
  isOpen: boolean
  hasMetric: boolean
}>`
  display: flex;
  flex: 1;
  align-items: center;
  padding: ${CELL_PADDING / 2}px;
  white-space: normal;
  height: 100%;
  gap: 0.4rem;

  ${({ hasMetric }) =>
    !hasMetric &&
    css`
      color: ${color('darkGray', 0.5)};
    `}
`

export const Name = styled.div<{
  empty?: boolean
  centered?: boolean
  truncated?: boolean
}>`
  color: ${color('darkGray')};
  line-height: 1.9rem;
  width: calc(100% - ${ARROW_WIDTH + AVATAR_SIZE}px);

  ${({ empty }) =>
    empty &&
    css`
      color: ${color('lightGray', 0.5)};
    `}

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

    ${({ truncated }) =>
    truncated &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

  svg {
    margin-left: 0.5rem;
    color: ${color('lightGray')};
  }
`

export const MetricName = styled.div<{
  empty?: boolean
  children?: ReactChildren
  isInvalid?: boolean
}>`
  display: flex;
  align-items: center;
  color: ${color('darkBlue')};
  line-height: 1.9rem;
  width: calc(100% - ${ARROW_WIDTH}px);

  ${({ empty }) =>
    empty &&
    css`
      color: ${color('lightGray', 0.5)};
    `}

  svg {
    margin-left: 0.5rem;
    color: ${color('lightGray')};
  }

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      color: ${color('red')};

      svg {
        color: ${color('red')};
      }
    `}
`

export const AddOptionButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 800;
  width: calc(100% - 1rem);
  padding: 0.7rem 1rem;
  margin: 0.5rem;
  border-radius: 0.3rem;

  &:hover {
    background: ${color('veryLightBlue', 0.2)};
    color: ${color('darkBlue')};
  }
`

export const AddIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
  margin-left: 1.2rem;
  font-size: 2rem;
`

export const AddOptionLabel = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NoResults = styled.p<{ children: ReactChildren }>`
  color: ${color('lightGray')};
  font-weight: 700;
  padding: 0.7rem 0;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
`

export const Spinner = styled(FontAwesomeIcon)`
  animation: infinite-spinning 2s infinite ease-in-out;

  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`

export const Badge = styled.div`
  display: inline-flex;
  background-color: ${color('green')};
  color: white;
  height: 1.6rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 700;
  padding: 0 0.5rem;
  margin-left: 0.4rem;
  line-height: 1.7;
`
