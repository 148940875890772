import { FormattedMessage, useIntl } from 'react-intl'

import { OnboardingStep } from 'components/OnboardingModal/useOnboardingModal'
import Button from 'ui/Button'
import {
  FooterButtonsContainer,
  Body,
  Footer,
} from '../../OnboardingModal.styles'
import {
  GeneratedInboundEmail,
  InputWrapper,
  Label,
  StepContainer,
  StepNumber,
} from './SetupYourInboundStep.styles'
import { useSetupYourInboundStep } from './useSetupYourInboundStep'
import TestInboundConnection from './TestInboundConnection'

interface SetupYourInboundStepProps {
  goBack: () => void
  hideModal: () => void
  completeStep: () => void
  completedSteps: Record<OnboardingStep, boolean>
}

const SetupYourInboundStep = ({
  goBack,
  hideModal,
  completeStep,
  completedSteps,
}: SetupYourInboundStepProps) => {
  const intl = useIntl()

  const { isStepCompleted, areAllStepsCompleted, inbound } =
    useSetupYourInboundStep(completedSteps)

  return (
    <>
      <Body>
        <StepContainer>
          <StepNumber>1</StepNumber>
          <InputWrapper>
            <Label>
              <FormattedMessage id="onboardingModal.inbound.redirectEmails" />
            </Label>
            <GeneratedInboundEmail
              email={inbound?.inboundEmail ?? ''}
              connectStepInfo={intl.formatMessage({
                id: 'onboardingModal.inbound.connectStepInfo',
              })}
              showInfoIcon
            />
          </InputWrapper>
        </StepContainer>
        <StepContainer>
          <StepNumber>2</StepNumber>
          <InputWrapper>
            <Label>
              <FormattedMessage id="onboardingModal.inbound.test" />
            </Label>
          </InputWrapper>
        </StepContainer>

        {!!inbound && (
          <TestInboundConnection
            isStepCompleted={isStepCompleted}
            inbound={inbound!}
            completeStep={completeStep}
          />
        )}
      </Body>

      <Footer>
        <Button onClick={goBack}>
          <FormattedMessage id="general.backText" />
        </Button>
        <FooterButtonsContainer>
          {!isStepCompleted && (
            <Button onClick={hideModal}>
              <FormattedMessage id="general.skipStep" />
            </Button>
          )}
          <Button onClick={hideModal} primary disabled={!areAllStepsCompleted}>
            <FormattedMessage id="general.finish" />
          </Button>
        </FooterButtonsContainer>
      </Footer>
    </>
  )
}

export default SetupYourInboundStep
