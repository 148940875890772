import styled from 'styled-components'
import Modal from 'components/Modal'
import { PrimaryText } from '../DiscardForm'

export const Body = styled(Modal.Body)`
  width: 43.2rem;

  && {
    padding: 0 0 1.2rem 0;
  }

  ${PrimaryText} {
    font-size: 1.8rem;
  }
`
