import { ChangeSetItem, EventType, LogTextBuilder } from '../types'

export class FinancialLogTextBuilder extends LogTextBuilder {
  private getRemovedLogWithoutText(fieldName: string): React.ReactNode {
    return this.formatMessage('logs.removedLogWithoutText', { fieldName })
  }

  private getAddedLogWithoutText(fieldName: string): React.ReactNode {
    return this.formatMessage('logs.addedLogWithoutText', { fieldName })
  }

  private getChangedLogWithoutText(fieldName: string): React.ReactNode {
    return this.formatMessage('logs.changedLogWithoutText', { fieldName })
  }

  public getLogText(
    event: EventType,
    fieldName: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    if (event === EventType.CREATE) {
      this.getAddedLogWithoutText(fieldName)
    }

    if (event === EventType.UPDATE) {
      if (from === null) {
        if (to === null) return null
        return this.getAddedLogWithoutText(fieldName)
      }

      if (to === null) {
        return this.getRemovedLogWithoutText(fieldName)
      }

      return this.getChangedLogWithoutText(fieldName)
    }

    return null
  }
}
