/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
import {
  needContrast,
  getContrastColor,
} from 'components/ColorPicker/ColorPicker'
import { ImageSize, TextAlign } from 'utils/constants/iue'
import missingPNGPath from 'assets/images/missing.png'
import { randomId } from './number'

export const TEXT = 'text'
export const IMAGE = 'image'

export const isEmptyText = (text) => {
  return (
    text
      .replace(/<p>/gm, '')
      .replace(/<\/p>/gm, '')
      .replace(/<br>/gm, '')
      .trim() === ''
  )
}

export const getHeaderStylesForMJML = ({ type, imageSize }) => {
  if (type === 'image') {
    return imageSize === ImageSize.SMALL ? 'width="300px"' : ''
  }
  return 'font-size="16px" color="#808080" align="center"'
}

export const getEntryStylesForMJML = ({
  type,
  imageSize,
  textAlign,
  sectionColor,
}) => {
  if (type === 'text') {
    let styles = 'font-weight="300"'
    if (textAlign) {
      styles += ` align="${textAlign}"`
    }
    if (sectionColor && needContrast(sectionColor)) {
      styles += ` color="${getContrastColor(sectionColor)}"`
    }
    return styles
  }
  return imageSize === ImageSize.SMALL ? 'width="300px"' : ''
}

export const convertHtmlToMJML = ({
  html,
  type,
  textAlign,
  imageSize,
  sectionColor,
}) => {
  switch (type) {
    case 'image':
      return `<mj-image src="${html.fileUrl}" ${getEntryStylesForMJML({
        type,
        imageSize,
      })} />`
    default:
      return `<mj-${type} ${getEntryStylesForMJML({
        type,
        textAlign,
        imageSize,
        sectionColor,
      })}>${html}</mj-${type}>`
  }
}

const removeMJMLTextTags = (text) =>
  text.replace(/<mj-text((.|\n)*?)>|<\/mj-text>/g, '')

const replaceMJMLImageTags = (text) => text.replace(/<mj-image/g, '<img')

const getImageSourceUrl = (mjml) => {
  const regex = /.*src=("|')(.*?)("|')/g
  const match = regex.exec(mjml)
  if (match?.[2]) {
    return match?.[2]
  }
  return missingPNGPath
}

export const convertMJMLToHTML = (mjml, type, toHtmlTags) => {
  switch (type) {
    case 'image':
      if (toHtmlTags) {
        return replaceMJMLImageTags(mjml)
      }

      return {
        id: randomId(),
        type: 'img',
        fileUrl: getImageSourceUrl(mjml),
        imageSize: mjml.includes('width=') ? ImageSize.SMALL : ImageSize.LARGE,
      }
    default:
      return removeMJMLTextTags(mjml)
  }
}

export const getTextAlignType = (entryContent) => {
  if (entryContent?.includes?.('align="center"')) {
    return TextAlign.CENTER
  }
  if (entryContent?.includes?.('align="left"')) {
    return TextAlign.LEFT
  }
  if (entryContent?.includes?.('align="right"')) {
    return TextAlign.RIGHT
  }
  if (entryContent?.includes?.('align="justify"')) {
    return TextAlign.JUSTIFY
  }
  return TextAlign.LEFT
}

export const getImageSize = (entryContent) => {
  return entryContent.includes('width="') ? ImageSize.SMALL : ImageSize.LARGE
}

export const setupMJMLEmail = (content) => {
  let sections = []
  content.forEach(({ mjml }) => {
    sections = [...sections, mjml]
  })
  return sections.join('')
}

export const setupMJMLEmailPreview = (content, toHtmlTags = true) => {
  const text = content
    ? content.match(/(<mj-text((.|\n)*?)<\/mj-text>)|(<mj-image(.*?) \/>)/g)
    : []

  let result = []
  text &&
    text.forEach((item) => {
      const type = item.includes(TEXT) ? TEXT : IMAGE

      result = [...result, convertMJMLToHTML(item, type, toHtmlTags)]
    })

  return result
}

export const reorderElementsFromTo = (source, destination, array) => {
  let result = []

  if (source > destination) {
    result = array.slice(0, destination)
    result = [...result, array[source]]
    result = [...result, ...array.slice(destination, source)]
    result = [...result, ...array.slice(source + 1, array.length)]
  } else if (source < destination) {
    result = array.slice(0, source)
    result = [...result, ...array.slice(source + 1, destination + 1)]
    result = [...result, array[source]]
    result = [...result, ...array.slice(destination + 1, array.length)]
  }

  return result
}
