import React, { useCallback, useState } from 'react'

import Dropdown from 'ui/Dropdown'
import { color } from 'utils/functions/colors'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styled from './SortBy.styles'

export interface SortCriteria {
  id: string
  field?: string
  label: React.ReactElement | React.ReactElement[] | string
  direction: string
}

export interface CurrentSort {
  orderBy: string
  direction: string
}

interface SortByProps {
  containerPadding?: string
  initialSortBy?: SortCriteria | string
  dropdownListWidth?: string
  dropdownListMargin?: string
  currentSort?: CurrentSort
  sortCriteria: SortCriteria[]
  onChange: (field: string, direction: string) => void
  [x: string]: any // rest
  mobileEnabled?: boolean
  className?: string
}

interface LoadStateProps {
  sortCriteria: SortCriteria[]
  currentSort?: CurrentSort
  initialSortBy?: SortCriteria | string
}
const loadState = ({
  sortCriteria,
  currentSort,
  initialSortBy,
}: LoadStateProps) => {
  if (currentSort) {
    return {
      id: `${currentSort.orderBy}_${currentSort.direction}`,
      field: currentSort.orderBy,
      direction: currentSort.direction,
      label: sortCriteria.find(
        (criterion) =>
          criterion.field === currentSort.orderBy &&
          criterion.direction === currentSort.direction
      )?.label,
    }
  }

  if (initialSortBy) {
    return initialSortBy
  }

  return sortCriteria[0]
}

const SortBy: React.FC<SortByProps> = ({
  containerPadding,
  initialSortBy = '',
  sortCriteria,
  dropdownListWidth,
  dropdownListMargin,
  currentSort,
  onChange,
  mobileEnabled,
  className,
  ...rest
}) => {
  const { matches: isMobile } = useMediaQuery(Media.MAX_MD)
  const [sortBy, setSortBy] = useState(() =>
    loadState({
      sortCriteria,
      currentSort,
      initialSortBy,
    })
  )

  const onSelectCriterion = useCallback(
    (criterion) => {
      setSortBy(criterion)
      onChange(criterion.field, criterion.direction)
    },
    [onChange]
  )

  const showMobileVersion = mobileEnabled && isMobile

  return (
    <Styled.SortContainer
      padding={containerPadding}
      showMobileVersion={showMobileVersion}
      className={className}
    >
      <Dropdown
        backgroundFocus={color('veryLightBlue', 0.4)}
        borderOnFocus="1px solid transparent"
        id="sort-dropdown"
        options={sortCriteria}
        getOption={(option) => option}
        value={sortBy}
        onSelectOption={(_, __, selectedOption) => {
          onSelectCriterion(selectedOption)
        }}
        type="select"
        transparent
        iconFontSize="2.3rem"
        minWidth="auto"
        formatSelectedOption={(selectedOption) =>
          !showMobileVersion ? (
            <Styled.FormattedSelectedOption className="selected-option">
              {selectedOption.label}
            </Styled.FormattedSelectedOption>
          ) : null
        }
        iconComponent={
          <FontAwesomeIcon icon={['fal', 'arrow-down-wide-short']} />
        }
        showAngleIcon={!showMobileVersion}
        minContentWidth="13rem"
        iconOnLeft
        dropdownListWidth={dropdownListWidth}
        dropdownListMargin={dropdownListMargin || '0'}
        alignDropdownContent="right"
        {...rest}
      />
    </Styled.SortContainer>
  )
}

export default SortBy
