import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import PortfolioService from 'api/PortfolioService'
import { FundProfile } from 'utils/types/company'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import PortfolioIcon from 'components/PortfolioIcon'
import Tooltip from 'components/Tooltip'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import { useProfileContext } from 'containers/Profiles/ProfileContext'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { portfolioKeys } from 'utils/queries/portfolios'
import theme from 'utils/theme'
import * as Styles from './FundsList.styles'

export const SLIDER_CONTAINER_ID = 'public-funds-list'

export const useFundsList = (fundCompanyId: string) => {
  const { handleOnHoldingClick } = useOnClickRedirect()
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const { debouncedSearch } = useProfileContext()
  const intl = useIntl()

  const fetchPublicFunds = useCallback(() => {
    return PortfolioService.getPublicFunds(fundCompanyId, debouncedSearch)
  }, [fundCompanyId, debouncedSearch])

  const { data, isFetching, isLoading } = useQuery(
    portfolioKeys.publicFundsByFundCompanyId(fundCompanyId, debouncedSearch),
    () => {
      return fetchPublicFunds()
    }
  )
  const funds = data || []

  useHighlightText(
    {
      elementClass: '.fund-name',
      text: debouncedSearch,
    },
    [debouncedSearch, isLoading, data]
  )

  const checkArrowsVisibility = useCallback(() => {
    const publicFundsList = document.getElementById(SLIDER_CONTAINER_ID)
    const activeSlidesArray =
      publicFundsList?.getElementsByClassName('slick-active')

    if (publicFundsList && activeSlidesArray) {
      const firstActive = Number(
        activeSlidesArray[0]?.getAttribute('data-index')
      )
      const lastActive = Number(
        activeSlidesArray[activeSlidesArray.length - 1]?.getAttribute(
          'data-index'
        )
      )

      const showPrevArrow = firstActive !== 0
      const showNextArrow = lastActive !== funds.length - 1

      if (!showPrevArrow) {
        publicFundsList
          .getElementsByClassName('slick-prev')[0]
          ?.setAttribute('style', 'display: none')
      } else {
        publicFundsList
          .getElementsByClassName('slick-prev')[0]
          ?.removeAttribute('style')
      }

      if (!showNextArrow) {
        publicFundsList
          .getElementsByClassName('slick-next')[0]
          ?.setAttribute('style', 'display: none')
      } else {
        publicFundsList
          .getElementsByClassName('slick-next')[0]
          ?.removeAttribute('style')
      }
    }
  }, [funds.length])

  const renderFund = useCallback(
    (fund: FundProfile) => {
      const isAPrivateFund = fund.hidden

      return (
        <Styles.Fund
          key={fund.id}
          isMobile={isMobile}
          onClick={() => handleOnHoldingClick(fund)}
        >
          <PortfolioIcon
            type={fund.type}
            backgroundColor={
              isAPrivateFund ? theme.colors.lightGray : undefined
            }
          />
          <Styles.FundName className="fs-exclude fund-name">
            <Tooltip
              id={`metric-name-${fund.id}`}
              place="bottom"
              text={fund.name}
              delayShow={500}
            >
              {fund.name}
            </Tooltip>
          </Styles.FundName>
          {isAPrivateFund && (
            <Styles.PrivateFundEye>
              <Tooltip
                id={`private-fund-${fund.id}`}
                place="left"
                text={intl.formatMessage({
                  id: 'profiles.privateFundTooltip',
                })}
              >
                <Styles.EyeSlash icon={['far', 'eye-slash']} />
              </Tooltip>
            </Styles.PrivateFundEye>
          )}
        </Styles.Fund>
      )
    },
    [isMobile, intl, handleOnHoldingClick]
  )

  return {
    funds,
    isFetching,
    isLoading,
    checkArrowsVisibility,
    renderFund,
  }
}

export const SLIDER_SETTINGS = {
  infinite: false,
  draggable: false,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: (
    <Styles.Arrow>
      <FontAwesomeIcon icon={['far', 'chevron-right']} />
    </Styles.Arrow>
  ),
  prevArrow: (
    <Styles.Arrow>
      <FontAwesomeIcon icon={['far', 'chevron-left']} />
    </Styles.Arrow>
  ),
  responsive: [
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1220,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1149,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 530,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
