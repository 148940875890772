import type {
  UR,
  LiteralStringForUnion,
  Event,
  ChannelResponse,
} from 'stream-chat'
import {
  TopicEntityType,
  isHoldingTopic,
  isPortfolioTopic,
} from 'utils/types/chatTopicEntity'
import { UpdateType } from 'utils/types/common'
import { HoldingType } from 'utils/types/company'
import { PortfolioType } from 'utils/types/portfolios'

export type Pin = {
  id: string
  pinnedAt: string
}

export type ArchivedChannel = {
  id: string
  archivedAt: string
}

export type AttachmentType = UR
export type CommandType = LiteralStringForUnion
export type EventType = UR
export type MessageType = {
  selfDeletedIds?: string[]
  edited?: boolean
  createNewChatSuggestionDismissed?: boolean
}
export type ReactionType = UR
export type UserType = {
  image?: string
  pinnedChannels?: Pin[]
  pinnedTopics?: Pin[]
  archivedChannels?: ArchivedChannel[]
}

export enum ChannelType {
  DIRECT_MESSAGE = 'messaging',
  HOLDING = 'holding',
  UPDATE = 'update',
  PORTFOLIO = 'portfolio',
  CHATBOT = 'cwuniverse-chatbot-channel',
}

export const CHATBOT_USER_ID = 'cwuniverse-chatbot'

export type StreamChatType<ChannelType = ChannelData> = {
  attachmentType: AttachmentType
  channelType: ChannelType
  commandType: CommandType
  eventType: EventType
  messageType: MessageType
  reactionType: ReactionType
  userType: UserType
}

export type EventStreamChatType = Event<StreamChatType> & {
  channel: ChannelResponse<StreamChatType> & {
    type?: ChannelType
    topicName?: string
    updateTitle?: string
    entityType?: TopicEntityType
    muteStatus?: () => {
      muted: boolean
    }
    data?: {
      topicName?: string
      name?: string
    }
  }
}

export enum SystemMessageType {
  JOINED_CHAT = 'JOINED_CHAT',
  LEFT_CHAT = 'LEFT_CHAT',
}

export interface SystemMessage {
  type: SystemMessageType
}

export interface JoinedChannelSystemMessage extends SystemMessage {
  type: SystemMessageType.JOINED_CHAT
  members: { id: string; name: string }[]
}

export interface LeftChannelSystemMessage extends SystemMessage {
  type: SystemMessageType.LEFT_CHAT
  member: { id: string; name: string }
}

export const isJoinedChannelSystemMessage = (
  message: SystemMessage
): message is JoinedChannelSystemMessage =>
  message.type === SystemMessageType.JOINED_CHAT

export const isLeftChannelSystemMessage = (
  message: SystemMessage
): message is LeftChannelSystemMessage =>
  message.type === SystemMessageType.LEFT_CHAT

export type Emoji = {
  id: string
  native: string
  name: string
}

export enum ChannelRole {
  CHANNEL_MODERATOR = 'channel_moderator',
  CHANNEL_MEMBER = 'channel_member',
}

export const DEFAULT_DM_ROLE = ChannelRole.CHANNEL_MEMBER

export type ChannelData = {
  member_count?: number
  hidden?: boolean
}

export type TopicChannel = ChannelData & {
  topicName: string
  name?: string
  entityId: string
  entityType: TopicEntityType
  updateId?: string
  updateTitle?: string
  updateType?: UpdateType
}

type HoldingChannel = TopicChannel & {
  imageUrl: string
  entityType:
    | TopicEntityType.COMPANY_HOLDING
    | TopicEntityType.FUND_HOLDING
    | TopicEntityType.ORGANIZATION
}

type PortfolioChannel = TopicChannel & {
  entityType:
    | TopicEntityType.FUND_PORTFOLIO
    | TopicEntityType.INVEST_PORTFOLIO
    | TopicEntityType.TRACK_PORTFOLIO
}

export const isTopicChannel = (
  entity?: ChannelData
): entity is TopicChannel => {
  return !!(entity as TopicChannel)?.entityType
}

export const isHoldingChannel = (
  entity: TopicChannel
): entity is HoldingChannel => {
  return isHoldingTopic(entity.entityType)
}

export const isPortfolioChannel = (
  entity: TopicChannel
): entity is PortfolioChannel => {
  return isPortfolioTopic(entity.entityType)
}

export enum AttachmentPaths {
  FUND = '/funds/:fundId',
  COMPANY = '/companies/:companyId',
  PORTFOLIO = '/portfolios/:portfolioType/:portfolioId/',
  FUND_PORTFOLIO_TAB_PORTFOLIO = '/portfolios/:portfolioType/:portfolioId/portfolio',
  FUND_PORTFOLIO_TAB_INVESTORS = '/portfolios/:portfolioType/:portfolioId/investors',
  INVESTMENTS_PORTFOLIO = '/investments/:portfolioType/:portfolioId/',
  INVESTMENTS_FUND_PORTFOLIO_TAB_PORTFOLIO = '/investments/:portfolioType/:portfolioId/portfolio',
  INVESTMENTS_FUND_PORTFOLIO_TAB_INVESTORS = '/investments/:portfolioType/:portfolioId/investors',
  INVESTMENTS_PORTFOLIO_UPDATE = '/investments/:portfolioId/:updateType/:updateId',
  UPDATE = '/updates/:updateType/:updateId',
  COMPANY_UPDATE = '/companies/:companyId/:updateType/:updateId',
  FUND_UPDATE = '/funds/:fundId/:updateType/:updateId',
  PORTFOLIO_UPDATE = '/portfolios/:portfolioId/:updateType/:updateId',
}

export enum MessageAttachmentType {
  HOLDING = 'holding',
  PORTFOLIO = 'portfolio',
  UPDATE = 'update',
}
export interface MessageLink {
  link: string
  matchedPath: AttachmentPaths
  attachmentType: MessageAttachmentType
}

export interface HoldingMessageLink extends MessageLink {
  holdingId: string
  holdingType: HoldingType
}
export interface UpdateMessageLink extends MessageLink {
  updateId: string
  updateType: UpdateType
}
export interface HoldingUpdateMessageLink extends UpdateMessageLink {
  updateId: string
  updateType: UpdateType
  logo?: string
  holdingId: string
}
export interface PortfolioUpdateMessageLink extends UpdateMessageLink {
  updateId: string
  updateType: UpdateType
  portfolioId: string
}
export interface PortfolioMessageLink extends MessageLink {
  portfolioId: string
  portfolioType: PortfolioType
}

export const isHoldingMessageLink = (
  attachment: MessageLink
): attachment is HoldingMessageLink =>
  attachment.attachmentType === MessageAttachmentType.HOLDING

export const isPortfolioMessageLink = (
  attachment: MessageLink
): attachment is PortfolioMessageLink =>
  attachment.attachmentType === MessageAttachmentType.PORTFOLIO

export const isUpdateMessageLink = (
  attachment: MessageLink
): attachment is UpdateMessageLink =>
  attachment.attachmentType === MessageAttachmentType.UPDATE

export const isPortfolioUpdateMessageLink = (
  attachment: MessageLink
): attachment is PortfolioUpdateMessageLink =>
  isUpdateMessageLink(attachment) &&
  !!(attachment as PortfolioUpdateMessageLink).portfolioId

export const isHoldingUpdateMessageLink = (
  attachment: MessageLink
): attachment is HoldingUpdateMessageLink =>
  isUpdateMessageLink(attachment) &&
  !!(attachment as HoldingUpdateMessageLink).holdingId
