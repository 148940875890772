import styled, { css } from 'styled-components'

import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 1.2rem;
  background-color: ${color('softRed')};
  border: 1px solid ${color('red')};
  color: ${color('red')};
  border-radius: 0.8rem;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0px;
`

const LinkStyles = css`
  font-weight: 700;
  border-bottom: 1px solid ${color('red')};
  cursor: pointer;
`

export const DecoratedText = styled.span`
  ${LinkStyles}
`
