import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { IndexPortfolio, PortfolioCompany } from 'utils/types/portfolios'
import { useFilteredHoldingsIdsQuery } from 'utils/hooks/queries/useInvestmentsFiltersQuery'
import useInitialData from 'utils/hooks/useInitialData'
import useTagsQuery from 'utils/hooks/useTagsQuery'
import { Industry, Sector } from 'utils/types/company'
import { Filter } from 'utils/functions/normalizers/investmentFilterNormalizer'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import useCustomFilterView from '../../CustomFilterView/useCustomFilterView'
import {
  entityHasChanged,
  holdingsFilterApplied,
  industryFilterApplied,
  normalizePortfolio,
  portfolioFilterApplied,
  sectorFilterApplied,
  tagsFilterApplied,
  updateFiltersForHolding,
  updateFiltersForPortfolio,
  updateFiltersForTag,
  updateFiltersObject,
  updateIndustriesToShow,
  updateSectorsAndIndustriesFiltersWhenSelectingIndustry,
  updateSectorsAndIndustriesFiltersWhenSelectingSector,
} from '../helpers'
import {
  GroupTag,
  Holding,
  InvestmentsFilters,
  InvestmentsFiltersObject,
  NormalizedPortfolio,
} from '../types'

interface Props {
  portfolios?: IndexPortfolio<PortfolioCompany>[]
  createdFilters?: Filter[]
}

const findFilter = (id: string, filters: Filter[]) => {
  return filters?.find((filter) => filter.id === id)
}

const useEditInvestmentsFilter = ({ portfolios, createdFilters }: Props) => {
  const { type, id: filterId } = useParams<{ type: string; id: string }>()
  const isShowingFilterAlreadyCreated = type === 'filters'

  const [isEditFilterDrawerOpen, setIsEditFilterDrawerOpen] = useState(false)
  const [isOriginalFilterChanged, setIsOriginalFilterChanged] = useState(false)

  const [originalFilter, setOriginalFilter] = useState<InvestmentsFilters>({
    portfolios: [],
    groupTags: [],
    holdings: [],
    sectors: [],
    industries: [],
  })

  const [filtersForFilterAlreadyCreated, setFiltersForFilterAlreadyCreated] =
    useState<InvestmentsFilters>({
      portfolios: [],
      groupTags: [],
      holdings: [],
      sectors: [],
      industries: [],
    })

  const [industriesToShow, setIndustriesToShow] = useState<Industry[]>([])

  const [filtersObject, setFiltersObject] = useState<InvestmentsFiltersObject>(
    {}
  )

  const { initialData } = useInitialData()

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const { data: tags } = useTagsQuery({
    enabled: !isInvestorGroup, // investors have no tags
  })

  const hasPortfolioFiltersAppliedOnCreatedFilter = useMemo(
    () => portfolioFilterApplied(filtersForFilterAlreadyCreated),
    [filtersForFilterAlreadyCreated]
  )

  const hasTagsFiltersAppliedOnCreatedFilter = useMemo(
    () => tagsFilterApplied(filtersForFilterAlreadyCreated),
    [filtersForFilterAlreadyCreated]
  )

  const hasHoldingsFiltersAppliedOnCreatedFilter = useMemo(
    () => holdingsFilterApplied(filtersForFilterAlreadyCreated),
    [filtersForFilterAlreadyCreated]
  )

  const hasSectorFiltersAppliedOnCreatedFilter = useMemo(
    () => sectorFilterApplied(filtersForFilterAlreadyCreated),
    [filtersForFilterAlreadyCreated]
  )

  const hasIndustryFiltersAppliedOnCreatedFilter = useMemo(
    () => industryFilterApplied(filtersForFilterAlreadyCreated),
    [filtersForFilterAlreadyCreated]
  )

  const hasFiltersAppliedOnAlreadyCreatedFilter = useMemo(
    () =>
      hasPortfolioFiltersAppliedOnCreatedFilter ||
      hasTagsFiltersAppliedOnCreatedFilter ||
      hasHoldingsFiltersAppliedOnCreatedFilter ||
      hasSectorFiltersAppliedOnCreatedFilter ||
      hasIndustryFiltersAppliedOnCreatedFilter,
    [
      hasPortfolioFiltersAppliedOnCreatedFilter,
      hasTagsFiltersAppliedOnCreatedFilter,
      hasHoldingsFiltersAppliedOnCreatedFilter,
      hasSectorFiltersAppliedOnCreatedFilter,
      hasIndustryFiltersAppliedOnCreatedFilter,
    ]
  )

  const onOpenEditFilterDrawer = useCallback(() => {
    setIsEditFilterDrawerOpen(true)
  }, [])

  const onCloseEditFilterDrawer = useCallback(
    () => setIsEditFilterDrawerOpen(false),
    []
  )

  const { data: portfolioHoldingsIds } = useFilteredHoldingsIdsQuery({
    filtersObject,
    hasFiltersApplied: hasFiltersAppliedOnAlreadyCreatedFilter,
  })

  const { data, columns, columnsOption, holdings, isLoading, toggleColumn } =
    useCustomFilterView({
      portfolioHoldingsIds,
    })

  // CHECK PORTFOLIOS
  useEffect(() => {
    if (portfolios?.length === 0) return

    // filters for a filter entity already created and selected in the navigation
    if (portfolios && isShowingFilterAlreadyCreated && createdFilters) {
      let normalizedPortfolios = portfolios.map(normalizePortfolio)
      const selectedFilter = findFilter(filterId, createdFilters)
      const portfoliosFromSelectedFilter = selectedFilter?.portfolios || []

      normalizedPortfolios = normalizedPortfolios.map((portfolio) => {
        const foundPortfolio = portfoliosFromSelectedFilter?.find(
          (createdPortfolio) => createdPortfolio.id === portfolio.id
        )
        if (foundPortfolio) {
          return {
            ...portfolio,
            isSelected: true,
          }
        }
        return portfolio
      })

      setFiltersForFilterAlreadyCreated((prevFilters) => ({
        ...prevFilters,
        portfolios: normalizedPortfolios,
      }))
      setOriginalFilter((prevFilters) => ({
        ...prevFilters,
        portfolios: normalizedPortfolios,
      }))
    }
  }, [createdFilters, filterId, isShowingFilterAlreadyCreated, portfolios])

  // CHECK TAGS
  useEffect(() => {
    // filters for a filter entity already created and selected in the navigation
    if (tags && isShowingFilterAlreadyCreated && createdFilters) {
      let normalizedTags = tags
      const selectedFilter = findFilter(filterId, createdFilters)
      const tagsFromSelectedFilter = selectedFilter?.tags || []

      normalizedTags = (tags as GroupTag[]).map((tag) => {
        const foundTag = tagsFromSelectedFilter?.find(
          (createdTag) => createdTag.id === tag.id
        )
        if (foundTag) {
          return {
            ...tag,
            isSelected: true,
          }
        }
        return tag
      })

      setFiltersForFilterAlreadyCreated((prevFilters) => ({
        ...prevFilters,
        groupTags: normalizedTags as GroupTag[],
      }))

      setOriginalFilter((prevFilters) => ({
        ...prevFilters,
        groupTags: normalizedTags as GroupTag[],
      }))
    }
  }, [createdFilters, filterId, isShowingFilterAlreadyCreated, tags])

  // CHECK HOLDINGS
  useEffect(() => {
    // filters for a filter entity already created and selected in the navigation
    if (isShowingFilterAlreadyCreated && createdFilters) {
      const selectedFilter = findFilter(filterId, createdFilters)
      const holdingsFromSelectedFilter = selectedFilter?.holdings || []
      const normalizedHoldings = holdingsFromSelectedFilter.map((holding) => ({
        id: holding.id,
        label: holding.name,
        logo: holding.primaryLogo?.url,
      }))

      setFiltersForFilterAlreadyCreated((prevFilters) => ({
        ...prevFilters,
        holdings: normalizedHoldings,
      }))

      setOriginalFilter((prevFilters) => ({
        ...prevFilters,
        holdings: normalizedHoldings,
      }))
    }
  }, [createdFilters, filterId, isShowingFilterAlreadyCreated])

  // CHECK SECTORS
  useEffect(() => {
    // filters for a filter entity already created and selected in the navigation
    if (isShowingFilterAlreadyCreated && createdFilters) {
      const selectedFilter = findFilter(filterId, createdFilters)
      const sectorsFromSelectedFilter = selectedFilter?.sectors || []
      const normalizedSectors = sectorsFromSelectedFilter.map((sector) => ({
        id: sector.id,
        name: sector.name,
      }))

      setFiltersForFilterAlreadyCreated((prevFilters) => ({
        ...prevFilters,
        sectors: normalizedSectors,
      }))

      setOriginalFilter((prevFilters) => ({
        ...prevFilters,
        sectors: normalizedSectors,
      }))
    }
  }, [createdFilters, filterId, isShowingFilterAlreadyCreated])

  // CHECK INDUSTRIES
  useEffect(() => {
    // filters for a filter entity already created and selected in the navigation
    if (isShowingFilterAlreadyCreated && createdFilters) {
      const selectedFilter = findFilter(filterId, createdFilters)
      const industriesFromSelectedFilter = selectedFilter?.industries || []
      const normalizedIndustries = industriesFromSelectedFilter.map(
        (industry) => ({
          id: industry.id,
          name: industry.name,
          sectorId: industry.sectorId,
        })
      )
      setFiltersForFilterAlreadyCreated((prevFilters) => ({
        ...prevFilters,
        industries: normalizedIndustries,
      }))

      setOriginalFilter((prevFilters) => ({
        ...prevFilters,
        industries: normalizedIndustries,
      }))
    }
  }, [createdFilters, filterId, isShowingFilterAlreadyCreated])

  useEffect(() => {
    if (!createdFilters || !filterId) return

    setIsOriginalFilterChanged(
      entityHasChanged(originalFilter, filtersForFilterAlreadyCreated)
    )
  }, [createdFilters, filterId, filtersForFilterAlreadyCreated, originalFilter])

  const onAddPortfolioForAlreadyCreatedFilter = (
    portfolio: NormalizedPortfolio
  ) => {
    setFiltersForFilterAlreadyCreated((prevFilters) =>
      updateFiltersForPortfolio({
        prevFilters,
        portfolio,
        isSelected: true,
      })
    )
  }

  const onRemovePortfolioForAlreadyCreatedFilter = (
    portfolio: NormalizedPortfolio
  ) => {
    setFiltersForFilterAlreadyCreated((prevFilters) =>
      updateFiltersForPortfolio({ prevFilters, portfolio, isSelected: false })
    )
  }

  const onRemoveTagForAlreadyCreatedFilter = (tag: GroupTag) => {
    setFiltersForFilterAlreadyCreated((prevFilters) =>
      updateFiltersForTag({ prevFilters, tag, isSelected: false })
    )
  }

  const onToggleTagForAlreadyCreatedFilter = (tag: GroupTag) => {
    setFiltersForFilterAlreadyCreated((prevFilters) => {
      const foundTag = prevFilters.groupTags.find(
        (prevTag) => prevTag.id === tag.id
      )

      if (foundTag) {
        const isSelected = !foundTag.isSelected
        return updateFiltersForTag({ prevFilters, tag, isSelected })
      }

      return prevFilters
    })
  }

  const onAddHoldingForAlreadyCreatedFilter = (company: Holding) => {
    setFiltersForFilterAlreadyCreated((prevFilters) =>
      updateFiltersForHolding({ prevFilters, company, isAdding: true })
    )
  }

  const onRemoveHoldingForAlreadyCreatedFilter = (company: Holding) => {
    setFiltersForFilterAlreadyCreated((prevFilters) =>
      updateFiltersForHolding({ prevFilters, company, isAdding: false })
    )
  }

  const getIndustries = (currentSectorId: string) =>
    initialData?.industries.filter(
      (industry) => industry.sectorId === currentSectorId
    ) || []

  const onSelectSectorForAlreadyCreatedFilter = (newSector: Sector) => {
    updateSectorsAndIndustriesFiltersWhenSelectingSector({
      newSector,
      filters: filtersForFilterAlreadyCreated,
      setFiltersFunction: setFiltersForFilterAlreadyCreated,
      getIndustries,
    })
  }

  const getAllIndustriesForSectorSelected = (
    sectorId: string,
    industries: Industry[]
  ): boolean => {
    const industriesByCurrentSector = getIndustries(sectorId).map(
      (industry) => industry
    )

    return industriesByCurrentSector.every((industry) =>
      industries.map((ind) => ind.id).includes(industry.id)
    )
  }

  const onSelectIndustryForAlreadyCreatedFilter = ({ industry, sector }) => {
    updateSectorsAndIndustriesFiltersWhenSelectingIndustry({
      industry,
      sector,
      filters: filtersForFilterAlreadyCreated,
      setFiltersFunction: setFiltersForFilterAlreadyCreated,
      getAllIndustriesForSectorSelected,
    })
  }

  const onClearAllForAlreadyCreatedFilter = () => {
    setFiltersForFilterAlreadyCreated({
      portfolios: portfolios?.map(normalizePortfolio) || [],
      groupTags: isEmpty(tags) ? [] : (tags as GroupTag[]),
      holdings: [],
      sectors: [],
      industries: [],
    })
  }

  useEffect(() => {
    updateIndustriesToShow({
      filters: filtersForFilterAlreadyCreated,
      setIndustriesToShowFunction: setIndustriesToShow,
    })
  }, [filtersForFilterAlreadyCreated])

  useEffect(() => {
    const selectedFiltersObject = updateFiltersObject({
      industries: filtersForFilterAlreadyCreated.industries,
      sectors: filtersForFilterAlreadyCreated.sectors,
      portfolios: filtersForFilterAlreadyCreated.portfolios,
      holdings: filtersForFilterAlreadyCreated.holdings,
      groupTags: filtersForFilterAlreadyCreated.groupTags,
    })

    setFiltersObject(selectedFiltersObject)
  }, [
    filtersForFilterAlreadyCreated.groupTags,
    filtersForFilterAlreadyCreated.holdings,
    filtersForFilterAlreadyCreated.industries,
    filtersForFilterAlreadyCreated.portfolios,
    filtersForFilterAlreadyCreated.sectors,
  ])

  return {
    filtersForFilterAlreadyCreated,
    portfolioHoldingsIdsForCreatedFilter: portfolioHoldingsIds,
    isShowingFilterAlreadyCreated,
    isOriginalFilterChanged,

    filteredDataForAlreadyCreatedFilter: {
      data,
      columns,
      columnsOption,
      holdings,
      isLoading,
      toggleColumn,
    },

    hasFiltersAppliedOnAlreadyCreatedFilter,
    hasPortfolioFiltersAppliedOnCreatedFilter,
    hasTagsFiltersAppliedOnCreatedFilter,
    hasHoldingsFiltersAppliedOnCreatedFilter,
    hasSectorFiltersAppliedOnCreatedFilter,
    hasIndustryFiltersAppliedOnCreatedFilter,

    initialData,
    industriesToShowForAlreadyCreatedFilter: industriesToShow,
    filtersObjectForAlreadyCreatedFilter: filtersObject,
    getIndustries,

    onSelectSectorForAlreadyCreatedFilter,
    onSelectIndustryForAlreadyCreatedFilter,
    onAddPortfolioForAlreadyCreatedFilter,
    onRemovePortfolioForAlreadyCreatedFilter,
    onRemoveTagForAlreadyCreatedFilter,
    onToggleTagForAlreadyCreatedFilter,
    onAddHoldingForAlreadyCreatedFilter,
    onRemoveHoldingForAlreadyCreatedFilter,
    onClearAllForAlreadyCreatedFilter,

    isEditFilterDrawerOpen,
    onOpenEditFilterDrawer,
    onCloseEditFilterDrawer,
  }
}

export default useEditInvestmentsFilter
