import type { IntlShape } from 'react-intl'
import { getDefaultOrganizationsDate } from 'utils/functions/date'
import { EntityName } from 'utils/hooks/useEntityFromUrl'
import { TopicEntity, TopicEntityType } from 'utils/types/chatTopicEntity'
import { IndexUpdate, MixedUpdate, SuggestedUpdate } from 'utils/types/update'
import { getUpdateDate } from 'utils/functions/updates'
import { ArrayMap, getArrayMap } from './array'

export function isSuggestedUpdate(
  entity: MixedUpdate
): entity is SuggestedUpdate {
  return !!(entity as SuggestedUpdate).data
}

export function isIndexUpdate(entity: MixedUpdate): entity is IndexUpdate {
  return !isSuggestedUpdate(entity)
}

export const groupMixedUpdatesByDate = (
  mixedUpdates: MixedUpdate[],
  intl: IntlShape
): ArrayMap<string, MixedUpdate> => {
  return getArrayMap(mixedUpdates, (mixedUpdate) => {
    const date = getUpdateDate(mixedUpdate)

    return getDefaultOrganizationsDate({
      date,
      intl,
    })
  })
}

export const mapUpdateFromTypeToEntityName = (
  updateFrom?: TopicEntity
): EntityName => {
  switch (updateFrom?.entityType) {
    case TopicEntityType.COMPANY_HOLDING:
    case TopicEntityType.ORGANIZATION:
      return EntityName.COMPANIES
    case TopicEntityType.FUND_HOLDING:
      return EntityName.FUNDS
    case TopicEntityType.FUND_PORTFOLIO:
    case TopicEntityType.INVEST_PORTFOLIO:
    case TopicEntityType.TRACK_PORTFOLIO:
    case TopicEntityType.DEAL_PORTFOLIO:
      return EntityName.PORTFOLIOS
    default:
      return EntityName.NONE
  }
}
