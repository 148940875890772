import { useEffect, useRef } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import AuthService from 'api/AuthService'
import { updateUserSession, setIsSigningUp, signOut } from 'features/authSlice'
import useQueryString from 'utils/hooks/useQueryString'
import Toast from 'components/Toast'
import { setSignupCompany } from 'actions/companies'
import { getUserSession } from 'utils/functions/user'
import { GroupSetupStep } from 'containers/Signup/GroupTypeSelector'
import { useGroupSetup } from 'containers/Signup/GroupSetup/useGroupSetup'
import GroupService from 'api/GroupService'
import { GroupTypes } from 'utils/constants/groups'
import { SignUpUser } from 'utils/types/signUp'

const AccountSetupProxy = () => {
  const intl = useIntl()
  const token = useQueryString('confirmation_token')
  const signUpExecuted = useRef(false)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { createGroup } = useGroupSetup()

  const setupUserByToken = async () => {
    const response = await AuthService.getUserAccountByToken(token)
    const {
      data: {
        entities: { groups, users, investorGroups },
      },
      headers,
    } = response

    const userId = Object.keys(users)[0]
    const user = users[userId]
    const userSession = {
      accessToken: headers['access-token']!,
      uid: headers.uid!,
      client: headers.client!,
    }

    return getUserSession<SignUpUser>({
      user,
      groups,
      userSession,
      investorGroups,
    })
  }

  const getUserAccountByToken = async () => {
    try {
      await dispatch(setSignupCompany({}))
      const session = await setupUserByToken()
      await dispatch(setIsSigningUp(true))

      const userHasGroups = Object.values(session.groups).length > 0
      const hasCompletedSignUpPaymentStep =
        session.user.tempData?.paymentIntentId

      // If has groups, user has been added from group management to an existent group
      if (userHasGroups && !hasCompletedSignUpPaymentStep) {
        if (session.user.firstName) {
          // User has already filled his name and password
          await dispatch(setIsSigningUp(false))
          await dispatch(updateUserSession(session))
          return history.replace('/welcome')
        }

        return history.replace('/signup/invite', {
          session,
        })
      }

      // User is performing a Founder or Investor sign up
      await dispatch(updateUserSession(session))

      if (session.user.tempData!.groupType === GroupTypes.FOUNDER) {
        return history.replace(GroupSetupStep.CREATE_FOUNDER)
      }

      return createGroup({
        onCreateNewGroup:
          session.user.tempData!.groupType === GroupTypes.FUND_MANAGER
            ? GroupService.createFundManagerGroup
            : GroupService.createClientGroup,
      })
    } catch (error) {
      if (error?.message === intl.messages['general.tosNotAccepted']) {
        return history.replace('/terms-of-use')
      }
      await dispatch(signOut())
      history.replace('/sign-in')
      return Toast.display(intl.messages['general.invalidToken'], 'error')
    }
  }

  useEffect(() => {
    if (!signUpExecuted.current) {
      signUpExecuted.current = true
      ;(async () => {
        await dispatch(signOut())
        getUserAccountByToken()
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!token) {
    return <Redirect to="/sign-in" />
  }

  return null
}

export default AccountSetupProxy
