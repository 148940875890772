import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

interface Props {
  className?: string
}

interface EmailContentAvatarIconProps {
  small?: boolean
}

export const EmailAvatarIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="20"
      height="20"
      rx="10"
      fill={color('veryLightBlue')({ theme })}
    />
    <path
      d="M14.9688 6.375H6.03125C5.4617 6.375 5 6.8367 5 7.40625V13.5938C5 14.1633 5.4617 14.625 6.03125 14.625H14.9688C15.5383 14.625 16 14.1633 16 13.5938V7.40625C16 6.8367 15.5383 6.375 14.9688 6.375ZM14.9688 7.40625V8.28292C14.487 8.6752 13.719 9.28519 12.0772 10.5708C11.7154 10.8554 10.9987 11.5391 10.5 11.5312C10.0014 11.5392 9.28444 10.8553 8.92277 10.5708C7.28121 9.28538 6.51304 8.67527 6.03125 8.28292V7.40625H14.9688ZM6.03125 13.5938V9.60621C6.52354 9.99832 7.22168 10.5486 8.28578 11.3818C8.75536 11.7514 9.57772 12.5674 10.5 12.5625C11.4177 12.5674 12.2297 11.7633 12.714 11.382C13.7781 10.5487 14.4764 9.99836 14.9688 9.60623V13.5938H6.03125Z"
      fill="white"
    />
    <rect
      x="0.5"
      y="0.5"
      width="20"
      height="20"
      rx="10"
      stroke={color('veryLightGray')({ theme })}
    />
  </svg>
)

export const EmailContentAvatarIcon = ({
  small,
}: EmailContentAvatarIconProps) => (
  <svg
    width={small ? '16' : '25'}
    height={small ? '15' : '24'}
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.875 0.5H1.125C0.503672 0.5 0 1.00367 0 1.625V8.375C0 8.99633 0.503672 9.5 1.125 9.5H10.875C11.4963 9.5 12 8.99633 12 8.375V1.625C12 1.00367 11.4963 0.5 10.875 0.5ZM10.875 1.625V2.58137C10.3495 3.00931 9.51169 3.67475 7.72062 5.07723C7.32591 5.3877 6.54403 6.1336 6 6.12491C5.45606 6.1337 4.67393 5.38759 4.27938 5.07723C2.48859 3.67496 1.65059 3.00938 1.125 2.58137V1.625H10.875ZM1.125 8.375V4.02495C1.66205 4.45271 2.42365 5.05297 3.58448 5.96197C4.09676 6.36521 4.99388 7.25539 6 7.24998C7.00118 7.25539 7.88693 6.37813 8.41531 5.96216C9.57612 5.05318 10.3379 4.45276 10.875 4.02498V8.375H1.125Z"
      fill="#44C2AF"
    />
  </svg>
)
