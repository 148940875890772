import { useId } from 'react'
import { useIntl } from 'react-intl'

import Tooltip from 'components/Tooltip'
import theme from 'utils/theme'

import * as Styles from './RunOutOfStorageTooltip.styles'

interface RunOutOfStorageTooltipProps {
  hasExceededStorage: boolean
  children: JSX.Element
  topOffset?: number
  leftOffset?: number
  place?: 'bottom' | 'right'
  backgroundColor?: string
}

const RunOutOfStorageTooltip = ({
  hasExceededStorage,
  children,
  topOffset,
  leftOffset,
  place = 'bottom',
  backgroundColor = theme.colors.red,
}: RunOutOfStorageTooltipProps) => {
  const intl = useIntl()
  const id = useId()

  if (!hasExceededStorage) {
    return children
  }

  return (
    <Tooltip
      id={id}
      text={intl.formatMessage(
        {
          id: 'runOutOfStorage.message',
        },
        {
          link: (message) => (
            <Styles.Link onClick={() => window.Intercom('showNewMessage')}>
              {message}
            </Styles.Link>
          ),
        }
      )}
      place={place}
      topOffset={topOffset}
      leftOffset={leftOffset}
      backgroundColor={backgroundColor}
      tooltipStyle={{
        width: '26rem',
        lineHeight: '1.23',
      }}
      clickable
      forceOverridePosition
      delayHide={500}
    >
      {children}
    </Tooltip>
  )
}

export default RunOutOfStorageTooltip
