import styled from 'styled-components/macro'
import { css } from 'styled-components'

import { color } from 'utils/functions/colors'
import { Sparkle } from 'ui/Icons'

const GradientColor = css`
  background: linear-gradient(
    331.87deg,
    ${color('celesteDark')} 13.06%,
    ${color('celesteLight')} 104.97%
  );
`

export const StyledCelesteIcon = styled.div`
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  border: 0.4rem solid white;
  display: flex;

  ${GradientColor}
`

export const InfiniteSparkleRotation = styled(Sparkle)`
  animation: infinite-spinning 13s linear infinite;

  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`
