import { useChannelActionContext } from 'stream-chat-react'

import { dispatchEvent } from 'utils/hooks/useEventListener'
import { CLOSE_THREAD_EVENT } from 'containers/Chat/hooks/useIsThreadOpen'
import { useIsThreadOpen } from 'containers/Chat/hooks'
import { Container } from './ClosableThreadArea.styles'

const ClosableThreadArea = () => {
  const isThreadOpen = useIsThreadOpen()
  const { closeThread } = useChannelActionContext()

  const onCloseThread = () => {
    dispatchEvent(CLOSE_THREAD_EVENT)
    closeThread()
  }

  if (isThreadOpen) {
    return <Container onClick={onCloseThread} />
  }

  return null
}

export default ClosableThreadArea
