import React from 'react'
import UpdateTitle from 'components/FeedUpdate/FeedUpdateTitle'
import { ReactFCWithChildren } from 'utils/types/common'
import CardLabel from 'components/UpdatesFeedV2/components/CardLabel'
import Header from './Header'
import { FeedUpdateTitleProps } from './FeedUpdateTitle/FeedUpdateTitle'

import {
  FeedUpdateBody,
  FeedUpdateCard,
  FeedUpdateCardBorder,
  FeedUpdateDate,
  UpdateFooter,
} from './FeedUpdate.styles'

interface FeedUpdateProps {
  dataTestId?: string
  onClick?: (event: React.MouseEvent) => void
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
  hoverState?: boolean
  checkState?: boolean
  isDraftUpdate?: boolean
  isScheduled?: boolean
  children?: React.ReactNode
}

interface FeedUpdateComposition {
  Header: ReactFCWithChildren
  Body: ReactFCWithChildren
  Date: ReactFCWithChildren
  Title: React.FC<FeedUpdateTitleProps & React.HTMLAttributes<HTMLDivElement>>
  Footer: ReactFCWithChildren
}

const FeedUpdateComponent: React.FC<FeedUpdateProps> = ({
  dataTestId,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  hoverState,
  checkState,
  isDraftUpdate,
  isScheduled,
}) => {
  const renderCardLabel = () => {
    if (isScheduled) {
      return <CardLabel labelType="scheduled" />
    }

    if (isDraftUpdate) {
      return <CardLabel labelType="draft" />
    }

    return null
  }

  return (
    <FeedUpdateCardBorder
      data-testid={dataTestId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      hoverState={hoverState}
      checkState={checkState}
      isDraftUpdate={isDraftUpdate}
    >
      {renderCardLabel()}
      <FeedUpdateCard onClick={onClick} isDraftUpdate={isDraftUpdate}>
        {children}
      </FeedUpdateCard>
    </FeedUpdateCardBorder>
  )
}

const FeedUpdate: React.FC<FeedUpdateProps> & FeedUpdateComposition =
  Object.assign(FeedUpdateComponent, {
    Body: FeedUpdateBody,
    Header,
    Footer: UpdateFooter,
    Date: FeedUpdateDate,
    Title: UpdateTitle,
  })

export default FeedUpdate
