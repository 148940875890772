import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import Tooltip from 'components/Tooltip'
import FadeInToStyle from 'components/FadeIn'
import { FileViewEnum } from 'components/FileViewSelector'

export const ContentSelectedContainer = styled.div<{ isSelected?: boolean }>`
  transition: box-shadow 0.2s ease-in-out, transform 0.2s;

  box-shadow: ${(props) =>
    props.isSelected
      ? `0 0 0 0.3rem ${color('primaryBlue')(props)}`
      : `0 0 0 0.1rem ${color('veryLightGray')(props)}`};
  border-radius: 0.8rem;
`
export const ContentWrapper = styled.div<{
  isSelected?: boolean
  isContentFromSharedUpdate?: boolean
  size: FileViewEnum
  showHoverState?: boolean
}>`
  background-color: ${(props) =>
    color(props.isContentFromSharedUpdate ? 'lightWhite' : 'white')(props)};
  align-items: center;
  cursor: pointer;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  height: 13.2rem;
  justify-content: space-between;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 13.2rem;
  box-shadow: 0px 0px 15px rgba(16, 21, 39, 0.1);
  &:hover {
    ${({ showHoverState }) =>
      showHoverState &&
      css`
        box-shadow: 0 0 0 0.2rem var(--color-button-back-outline);
      `}
  }

  ${({ size }) =>
    size === FileViewEnum.LARGE &&
    css`
      height: 21rem;
      width: 21rem;
    `}
`

export const ContentNameContainer = styled.div<{ large?: boolean }>`
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: ${color('darkGray')};
  text-align: center;
  padding: 0.8rem 0.8rem;
  width: 100%;
  grid-template-columns: 1fr auto;
  display: grid;
  align-items: center;

  ${({ large }) =>
    large &&
    css`
      padding: 0.8rem 0.8rem 1.2rem 0.8rem;
    `}
`

export const ContentName = styled.div`
  color: ${color('darkGray')};
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const OptionsButton = styled.div`
  position: absolute;
  background-color: inherit;
  border-radius: 0.8rem;
  top: 0.4rem;
  right: 0.4rem;
  visibility: hidden;

  ${ContentWrapper}:hover & {
    visibility: visible;
  }
`
export const CheckIcon = styled(FontAwesomeIcon)`
  background-color: ${color('primaryBlue')};
  border-radius: 100%;
  border: 0.2rem solid ${color('primaryBlue')};
  color: ${color('white')};
  height: 1.6rem;
  min-width: 1.6rem;
  position: absolute;
  right: -1rem;
  top: -1rem;
`

export const AlreadyAddedIcon = styled(CheckIcon)`
  right: 1rem;
  top: 1rem;
  background-color: ${color('lightGray')};
  color: ${color('white')};
  border: 0.2rem solid ${color('lightGray')};
  cursor: not-allowed;
`

export const AlreadySelectedOverlay = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => (props.isVisible ? '100%' : '0')};
  height: ${(props) => (props.isVisible ? '100%' : '0')};
  border-radius: 0.8rem;
  background: linear-gradient(
    180deg,
    rgba(201, 207, 228, 0.7) 0%,
    rgba(201, 207, 228, 0) 100%
  );
  opacity: 0.5;
  cursor: not-allowed;
`

export const FadeIn = styled(FadeInToStyle)`
  width: fit-content;
`

export const UnreconciledIndicator = styled.div`
  background-color: ${color('purple')};
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  flex: 1;
  margin-left: 0.4rem;
`

export const NotUsedContent = styled.span`
  opacity: 0.65;
  font-size: 1.2rem;
`

export const UserAndGroupName = styled.span`
  font-weight: 700;
`

export const SharedUpdateIcon = styled(FontAwesomeIcon)`
  color: ${color('darkGray')};
  opacity: 0.5;
`

export const TooltipName = styled(Tooltip)`
  min-width: 0;
`
