import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useDebouncedState } from 'utils/hooks/useDebouncedState'
import {
  FiltersDataSet,
  StatusFilters,
} from 'utils/hooks/useUpdatesProfilesFilter'
import { ReactFCWithChildren } from 'utils/types/common'

interface UpdatesFiltersContextProps {
  search: string
  filters: FiltersDataSet
  onChangeSearch: (event: React.ChangeEvent) => void
  setFilters: (newFilters: FiltersDataSet) => void
  onClearSearchText: () => void
}

export const UpdatesFiltersContext = createContext<UpdatesFiltersContextProps>({
  search: '',
  onChangeSearch: () => {},
  filters: {
    types: [],
    tags: [],
    searchText: '',
  },
  setFilters: () => {},
  onClearSearchText: () => {},
})

export const UpdatesFiltersProvider: ReactFCWithChildren<{}> = ({
  children,
}) => {
  const [filters, setFilters] = useState<FiltersDataSet>({
    searchText: '',
    types: [],
    [StatusFilters.PUBLISHED]: true,
    [StatusFilters.SUGGESTED]: true,
    [StatusFilters.DRAFT]: true,
  })
  const [search, debouncedSearch, setSearch] = useDebouncedState('')

  const onChangeSearch = useCallback(
    (event) => {
      setSearch(event.target.value)
    },
    [setSearch]
  )

  const onClearSearchText = useCallback(() => {
    setSearch('')
  }, [setSearch])

  useEffect(() => {
    setFilters((prevFilters) => {
      if (debouncedSearch === prevFilters.searchText) {
        return prevFilters
      }

      return {
        ...prevFilters,
        searchText: debouncedSearch,
      }
    })
  }, [debouncedSearch])

  const setFiltersWithSearch = useCallback(
    (newFilters: FiltersDataSet) => {
      setFilters(newFilters)
      setSearch(newFilters.searchText || '')
    },
    [setFilters, setSearch]
  )

  const value = useMemo(
    () => ({
      search,
      onChangeSearch,
      filters,
      setFilters: setFiltersWithSearch,
      onClearSearchText,
    }),
    [filters, onChangeSearch, onClearSearchText, search, setFiltersWithSearch]
  )

  return (
    <UpdatesFiltersContext.Provider value={value}>
      {children}
    </UpdatesFiltersContext.Provider>
  )
}

export const useUpdatesFiltersContext = () => useContext(UpdatesFiltersContext)
