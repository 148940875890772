import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useResharesWithMeOrMyGroup } from 'utils/hooks/reshares'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'
import { IndexUpdate } from 'utils/types/update'
import { getUpdateItemTitle } from 'utils/functions/updates'
import Tooltip from 'components/Tooltip'
import FeedUpdate from 'components/FeedUpdate'
import { useUpdatesFeedContext } from 'components/UpdatesFeedV2/UpdateFeedContext'
import ResharedGroups from 'containers/UpdatesV2/ReshareHistoryV2/ResharedGroups'
import {
  ResharedByWrapper,
  UntitledUpdate,
  UpdateTitleContainer,
} from '../UpdateFactory.styles'

interface HeaderProps {
  update: IndexUpdate
}

const Header: React.FC<HeaderProps> = ({ update }) => {
  const resharesWithMeOrMyGroup = useResharesWithMeOrMyGroup(update)
  const updatePermissions = useUpdatePermissions(update)
  const { reshareDisabled } = useUpdatesFeedContext()
  const title = getUpdateItemTitle(update)

  return (
    <FeedUpdate.Header>
      <FeedUpdate.Title className="fs-exclude">
        {!updatePermissions.hasEditPermissions &&
          !reshareDisabled &&
          resharesWithMeOrMyGroup.length > 0 && (
            <ResharedByWrapper>
              <ResharedGroups reshares={resharesWithMeOrMyGroup} />
            </ResharedByWrapper>
          )}
        <Tooltip
          id={`update-title-${update.id}`}
          place="top"
          text={title}
          delayShow={500}
          widthAuto
        >
          <UpdateTitleContainer isDraftUpdate={update.isDraftUpdate}>
            {title ? (
              <span>{title}</span>
            ) : (
              <UntitledUpdate>
                <FormattedMessage
                  id="updates.drafts.untitled"
                  values={{
                    updateType: (
                      <FormattedMessage
                        id={`updates.updateType.${update.updateType}`}
                      />
                    ),
                  }}
                />
              </UntitledUpdate>
            )}
          </UpdateTitleContainer>
        </Tooltip>
      </FeedUpdate.Title>
    </FeedUpdate.Header>
  )
}

export default Header
