import styled, { css } from 'styled-components'
import FeedUpdate from 'components/FeedUpdate'
import { TransactionTypes } from 'utils/constants/transactionTypes'
import { color } from 'utils/functions/colors'
import Chip from 'components/Chip'
import ShowMoreLessToStyle from 'components/ShowMoreLess'

export const AttachmentsContainer = styled.div`
  --grid-layout-gap: 1rem;
  --grid-column-count: 3;
  --grid-item--min-width: 22rem;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
  grid-template-rows: 4rem;

  margin-top: 1rem;
  padding-bottom: 1rem;

  &:empty {
    display: none;
  }
`

export const UpdateText = styled.div`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  line-height: 1.7rem;

  margin-top: 0.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  word-break: break-word;
`

export const AccountingUpdateBody = styled(FeedUpdate.Body)`
  overflow: visible;
`

export const AccountingReport = styled.div`
  max-width: 40rem;
  min-width: 18.7rem;
  height: 4.4rem;
  margin-top: 1.2rem;
  min-height: 4.4rem;
  border-radius: 0.8rem;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--color-white);
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: ${color('darkGray')};
  gap: 0.9rem;

  padding-left: 1.2rem;
  padding-right: 0.9rem;

  img {
    width: 2.6rem;
    height: 2.6rem;
  }
`

export const Draft = styled.span`
  height: 2rem;
  background-color: ${color('primaryDarkBlue', 0.4)};
  color: white;
  border-radius: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  margin-left: 0.8rem;
  padding: 0.2rem 0.8rem;
`

export const ScheduledEmail = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  color: ${color('lightGray')};
  margin-top: 0.5rem;
`

export const TitleWrapper = styled.span<{ draft?: boolean }>`
  display: flex;
  gap: 0.8rem;

  ${({ draft }) =>
    draft &&
    css`
      padding-top: 0.8rem;
    `};
`

export const TransactionType = styled.p`
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-style: italic;
  color: ${color('darkGray')};
  font-weight: 500;
`

export const AmountInvested = styled.p<{ transactionType?: string }>`
  font-size: 2.4rem;
  font-weight: normal;
  font-size: 3.2rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.8rem;
  margin-top: 1.2rem;
  display: flex;
  align-items: flex-end;

  color: ${(props) =>
    props.transactionType === TransactionTypes.COMMITMENT
      ? color('lightGray')(props)
      : color('darkBlue')(props)};

  > span {
    color: ${color('darkGray')};
    font-size: 1.4rem;
    margin-left: 0.5rem;
    font-weight: normal;
    margin-left: 1.2rem;
    height: 2.2rem;

    &::before {
      content: ' - ';
      margin-right: 0.5rem;
    }
  }
`

export const Separator = styled.div`
  height: 1.7rem;
  width: 0.1rem;
  background-color: ${color('veryLightGray')};
  margin-right: 0.5rem;
`

export const LinkButton = styled.button<{ disabled?: boolean }>`
  font-size: 1.4rem;
  font-weight: 700;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    `};

  &:focus {
    outline: none;
  }

  :hover {
    text-decoration: underline;
  }
`

export const IconWrapper = styled.div`
  display: flex;
`

export const PortfolioChip = styled(Chip)`
  padding: 0;
  height: auto;
  padding-right: 0.5rem;
`

export const ChipIcon = styled.button`
  background-color: ${color('primaryBlue')};
  border-radius: 2.4rem;
  color: ${color('white')};
  font-size: 1.1rem;
  margin-right: 0.6rem;
  height: 2.1rem;
  width: 2.1rem;
`

export const Portfolios = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  margin-top: 1.2rem;
`

export const ResharedByWrapper = styled.div`
  span {
    margin-bottom: 1.4rem;
  }
`

export const VisibilityContainer = styled.div`
  display: flex;
  gap: 1.8rem;
`

export const UpdateTitleContainer = styled.div<{ isDraftUpdate?: boolean }>`
  color: ${color('darkGray')};
  ${(props) =>
    props.isDraftUpdate &&
    css`
      padding-top: 0.2rem;
    `}
`
export const UntitledUpdate = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 1.6rem;
  color: ${color('lightGray')};
  text-transform: none;
`

export const DraftDotsMenuContainer = styled.div`
  height: 3.2rem;
`

export const ShowMoreLess = styled(ShowMoreLessToStyle)`
  display: block;
  padding-top: 0.6rem;
  padding-left: initial;
`
