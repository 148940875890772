import { useRouteMatch } from 'react-router-dom'
import BeamerHelmet from 'components/BeamerHelmet'
import Footer from 'components/Footer'
import OnboardingModal from 'components/OnboardingModal'
import UnverifiedWidget from 'components/UnverifiedWidget'
import Notifications from 'components/Notifications'
import { ChatPopupLayout } from 'containers/Chat'
import SideBar from 'layouts/SideBarComponents/SideBar'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import {
  FooterContainer,
  LayoutContainer,
  MainLayoutContainer,
} from './MainLayout.styles'
import useMainLayout from './useMainLayout'

interface MainLayoutProps {
  children: React.ReactNode
  isFounderVerified?: boolean
  position?: string
}

const MainLayout = ({
  children,
  isFounderVerified = true,
  position,
}: MainLayoutProps) => {
  const { showOnboardingModal } = usePlanFeatures()
  const matchesChatView = useRouteMatch('/chat')

  const { hasChatFeature, currentGroupId, mainLayoutRef } = useMainLayout()

  return (
    <BeamerHelmet>
      <LayoutContainer>
        {isFounderVerified ? null : <UnverifiedWidget />}
        <SideBar />
        <MainLayoutContainer
          id="mainLayout"
          ref={mainLayoutRef}
          key={currentGroupId}
          position={position}
        >
          {showOnboardingModal && <OnboardingModal />}

          {children}

          {!matchesChatView && (
            <FooterContainer>
              <Footer />
            </FooterContainer>
          )}
        </MainLayoutContainer>
        {hasChatFeature && <ChatPopupLayout />}
        <Notifications />
      </LayoutContainer>
    </BeamerHelmet>
  )
}

export default MainLayout
