import styled from 'styled-components/macro'
import OriginalCheckbox from 'components/Checkbox'
import Modal from 'components/Modal'
import { color } from 'utils/functions/colors'

export const StyledModal = styled(Modal)`
  max-width: 47rem;
`

export const Title = styled.div`
  font-size: 1.8rem;
  padding-bottom: 2.4rem;
`

export const Checkbox = styled(OriginalCheckbox)`
  margin-left: 0rem;
  margin-bottom: 1.6rem;

  :last-child {
    margin-bottom: 0;
  }
`

export const Label = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkGray')};
`

export const Description = styled(Label)`
  font-weight: 400;
  padding-top: 0.6rem;
`
