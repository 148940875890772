import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  padding: 2.4rem;
  font-size: 1.2rem;
`

export const DaysLeft = styled.div`
  color: white;
  padding-bottom: 0.9rem;
`
export const Indicator = styled.div`
  border-radius: 0.4rem;
  background: white;
`

export const Progress = styled.div<{ max: number; value: number }>`
  background: ${color('green')};
  height: 0.4rem;
  border-radius: 0.4rem;
  width: ${(props) => (props.value * 100) / props.max}%;
`
