import GroupService from 'api/GroupService'
import { ShareWithOptionType } from 'containers/UpdatesV2/components/ShareSettingsModal'
import { ShareWithOption } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/useShareWithDropdown'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { getCurrentGroupData, getUserId } from 'selectors/auth'
import { getCurrentCompany } from 'selectors/company'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useDebouncedCallback } from 'utils/hooks/useDebouncedCallback'
import { useSearchScopedUsersAndGroups } from 'utils/hooks/useSearchScopedUsersAndGroups'
import { GroupTypes } from 'utils/constants/groups'
import InvestorManagementService from 'api/InvestorManagementService'

export type ChannelMember = ShareWithOption

export interface GroupOption {
  label: string
  options: ChannelMember[]
}

export const useChatMembersPicker = (hiddenMemberIds: string[] = []) => {
  const intl = useIntl()

  const currentCompany = useAppSelector(getCurrentCompany)
  const currentGroup = useAppSelector(getCurrentGroupData)
  const currentUserId = useAppSelector(getUserId)

  const { loadRecentOptions, loadOptionsBySearchValue } =
    useSearchScopedUsersAndGroups({
      shouldIncludeLists: false,
      shouldIncludeGroups: false,
      shouldIncludePendingUsers: false,
    })

  const getGroupUsers = useCallback(async () => {
    if (currentGroup!.type === GroupTypes.INVESTOR_GROUP) {
      const investor = await InvestorManagementService.fetchInvestor(
        currentGroup!.id
      )
      return investor.investorGroupUsers
    }
    return GroupService.getGroupUsers()
  }, [currentGroup])

  const loadOptions = useCallback(
    async (searchValue: string): Promise<GroupOption[]> => {
      if (!searchValue) {
        const groupUsers = await getGroupUsers()

        const groupChatMembers = groupUsers
          .map((groupUser) => ({
            id: groupUser.user.id,
            name: groupUser.user.name,
            type: ShareWithOptionType.User,
            imageUrl: groupUser.user.image.url,
            subtitle: groupUser.user.email,
          }))
          .filter(
            (user) =>
              user.id !== currentUserId &&
              !!user.name &&
              !hiddenMemberIds.includes(user.id)
          )

        const recentOptions = await loadRecentOptions(
          currentGroup,
          currentCompany?.owner,
          15
        )

        return [
          {
            label: intl.formatMessage({ id: 'chat.groupMembers' }),
            options: groupChatMembers,
          },
          {
            label: intl.formatMessage({ id: 'chat.recentUsers' }),
            options: recentOptions.filter(
              (member) => !hiddenMemberIds.includes(member.id)
            ),
          },
        ]
      }

      const results = await loadOptionsBySearchValue(
        searchValue,
        currentGroup,
        currentCompany?.owner
      )

      return [
        {
          label: intl.formatMessage({ id: 'chat.recentUsers' }),
          options: results,
        },
      ]
    },
    [
      currentCompany?.owner,
      currentGroup,
      currentUserId,
      getGroupUsers,
      hiddenMemberIds,
      intl,
      loadOptionsBySearchValue,
      loadRecentOptions,
    ]
  )

  const debouncedLoadOptions = useDebouncedCallback(
    (searchValue: string, callback: (options: GroupOption[]) => void) => {
      loadOptions(searchValue).then(callback)
    },
    [loadOptions],
    300
  )

  return {
    loadOptions: debouncedLoadOptions,
  }
}
