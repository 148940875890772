import { FormattedMessage } from 'react-intl'

import { ReactComponent as AlmostDoneImage } from 'assets/images/almost-done.svg'
import Heading from 'ui/Heading'
import useAlmostThereForm from '../hooks/useAlmostThere'

import {
  AlmostThereContainer,
  InfoIcon,
  ButtonLink,
  AlmostThereLegend,
  AlmostThereLegendContainer,
} from './GetStarted.styles'

const AlmostThere = () => {
  const { onResendEmail, userEmail } = useAlmostThereForm()

  return (
    <AlmostThereContainer>
      <AlmostDoneImage />

      <Heading margin="1.6rem 0 0.8rem 0" level="h1">
        <FormattedMessage id="investorSignup.almostDone" />
      </Heading>

      <Heading margin="0 0 2.8rem 0" level="h2">
        <FormattedMessage
          id="investorSignup.almostDoneLegend"
          values={{
            email: userEmail,
          }}
        />
      </Heading>

      <AlmostThereLegendContainer>
        <InfoIcon icon={['far', 'info-circle']} />
        <AlmostThereLegend>
          <FormattedMessage id="investorSignup.almostDoneHelp1" />
          <br />
          <FormattedMessage
            id="investorSignup.almostDoneHelp2"
            values={{
              link: (
                <ButtonLink type="button" onClick={onResendEmail}>
                  <FormattedMessage id="investorSignup.clickHere" />
                </ButtonLink>
              ),
            }}
          />
        </AlmostThereLegend>
      </AlmostThereLegendContainer>
    </AlmostThereContainer>
  )
}

export default AlmostThere
