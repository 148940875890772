import styled from 'styled-components'
import ImagePicker from 'ui/ImagePicker'
import { BackgroundImage, ImageBorder } from 'ui/ImagePicker/ImagePicker.styles'
import InputLabel from 'ui/InputLabel'
import Button from 'ui/Button'
import { color } from 'utils/functions/colors'
import HeadingToStyle from 'ui/Heading'
import { maxSize } from 'utils/constants/breakpoint'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem;
  width: 100%;
  gap: 0.8rem;
`

export const Legend = styled.p<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  font-size: 1.4rem;
  color: ${color('darkGray')};
  line-height: 1;

  a {
    color: ${color('mainBlue')};
    text-decoration: underline;
    font-weight: 600;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > button {
    width: max-content;
    align-self: center;
  }

  > div {
    width: 100%;
  }

  a {
    color: ${color('mainBlue')};
    text-decoration: underline;
    font-weight: 700;
  }
`

export const StyledSeparator = styled.p`
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${color('darkGray')};
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  width: 100%;

  span {
    display: inline-block;
    width: max-content;
    white-space: nowrap;
    padding: 0 1.6rem;
  }

  hr {
    width: 100%;
    background-color: ${color('veryLightBlue')};
    height: 0rem;
  }
`

export const GoogleLoginWrapper = styled.div`
  width: fit-content;
  margin-top: 3.2rem;
`

export const Grid = styled.div<{ large?: boolean }>`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ large }) =>
    large ? 'repeat(2, 32.7rem);' : 'repeat(2, 1fr);'}
  
  grid-gap: 2.4rem 3.2rem;

  @media ${maxSize.md} {
    grid-template-columns: 1fr;
  }
`

export const Spacer = styled.div<{ margin: string }>`
  height: ${(props) => props.margin || '2.4rem'};
`

export const ImagePickerLabel = styled(InputLabel)`
  width: 9.7rem;
  text-align: center;
  font-size: 1.2rem;
`

export const StyledImagePicker = styled(ImagePicker)`
  grid-template-columns: 115px 1fr;
  width: 11.3rem;
  height: 11.3rem;

  ${ImageBorder} {
    background-color: ${color('lightGray', 0.1)};
    padding: 0;
    border: none;
    width: 11.3rem;
    height: 11.3rem;
  }

  ${BackgroundImage} {
    height: 100%;
    width: 100%;
    transition: 0.1s border;
    border: 0.1rem solid transparent;
    &:hover {
      border: 0.1rem solid #c9cfe4;
    }
  }
`

export const GroupSetupForm = styled(Form)`
  padding: 0 2.5rem;

  & > button {
    margin-top: 4rem;
  }
`

export const GroupSetupContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  > div > div {
    grid-template-columns: 115px 1fr;
  }
`

export const FounderGroupSetupContent = styled.div`
  max-width: 68.6rem;
`

export const GroupNameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.1rem;

  @media (max-width: 1024px) {
    margin-top: 2.4rem;
  }

  @media (min-width: 1025px) {
    max-width: 55.7rem;
  }
`

export const ButtonWrapper = styled.div`
  > button {
    margin-bottom: 3.2rem;
  }
`

export const ContinueButton = styled(Button)`
  font-size: 1.6rem;
  font-weight: 400;
  padding-right: 2.75rem;
  padding-left: 2.75rem;
  width: initial;
`

export const CheckboxContainer = styled.div`
  margin-top: 1.2rem;
`

export const FounderGroupSetupHeading = styled(HeadingToStyle)`
  margin-bottom: 4.8rem;
`

export const Heading = styled(HeadingToStyle)`
  text-align: center;
  gap: 0.8rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
`

export const HorizontalContainer = styled.div`
  display: flex;
  gap: 1.6rem;
`

export const CaptchaContainer = styled.div`
  margin: 3.2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
`
