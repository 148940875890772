import React, { useState } from 'react'
import theme from 'utils/theme'
import IconButton from 'ui/IconButton'
import OptionsDropdown from 'ui/OptionsDropdown'
import { FormattedMessage } from 'react-intl'

import * as Styles from './FileViewSelector.styles'

export enum FileViewEnum {
  SMALL = 'small-grid',
  LARGE = 'large-grid',
  LIST = 'list',
}

interface FileViewSelectorProps {
  selectedView: FileViewEnum
  hasListMode?: boolean
  onChange: (value: FileViewEnum) => void
}

const FileViewSelector: React.FC<FileViewSelectorProps> = ({
  selectedView,
  hasListMode = false,
  onChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onExpandOptions = () => {
    setIsExpanded((prev) => !prev)
  }

  const onSelectOption = (option: FileViewEnum) => {
    onChange(option)
    setIsExpanded(false)
  }

  return (
    <Styles.ViewSelector>
      {hasListMode && (
        <IconButton
          data-testid="list-button"
          selected={selectedView === FileViewEnum.LIST}
          onClick={() => onChange(FileViewEnum.LIST)}
          icon="list-ul"
          iconFamily="fal"
          border={false}
          roundSquare
          buttonSize="3rem"
        />
      )}

      <div>
        <OptionsDropdown
          icon={['fal', 'grip-horizontal']}
          buttonType="square"
          buttonSize="3.2rem"
          dropdownContentMinWidth="15rem"
          onClick={onExpandOptions}
          isActive={
            hasListMode ? selectedView !== FileViewEnum.LIST : isExpanded
          }
        >
          <OptionsDropdown.Item
            label={<FormattedMessage id="files.gridViewSelector.medium" />}
            onSelectOption={() => onSelectOption(FileViewEnum.SMALL)}
            backgroundColor={
              selectedView === FileViewEnum.SMALL
                ? theme.colors.primaryBlue
                : undefined
            }
            color={
              selectedView === FileViewEnum.SMALL
                ? theme.colors.white
                : undefined
            }
          />
          <OptionsDropdown.Item
            label={<FormattedMessage id="files.gridViewSelector.large" />}
            onSelectOption={() => onSelectOption(FileViewEnum.LARGE)}
            backgroundColor={
              selectedView === FileViewEnum.LARGE
                ? theme.colors.primaryBlue
                : undefined
            }
            color={
              selectedView === FileViewEnum.LARGE
                ? theme.colors.white
                : undefined
            }
          />
        </OptionsDropdown>
      </div>
    </Styles.ViewSelector>
  )
}

export default FileViewSelector
