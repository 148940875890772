import styled from 'styled-components/macro'

export const TableScrollWrapper = styled.div<{ minWidth?: string }>`
  @media (max-width: 768px) {
    overflow-x: auto;

    .table-container {
      min-width: ${(props) => props.minWidth};
    }
  }
`
