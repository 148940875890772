import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { ChatBreakpointSize } from 'utils/constants/chat'
import { maxSize } from 'utils/constants/breakpoint'
import OptionsDropdown from 'ui/OptionsDropdown'

export const chatHeaderHeight = '7.2rem'

export const EllipsisStyles = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Container = styled.div<{
  preventResponsive?: boolean
  height?: string
  hasNewMessages?: boolean
  isMinimized?: boolean
  isThreadOpen?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${(props) => props.height || chatHeaderHeight};
  border-bottom: 0.1rem solid ${color('veryLightGray')};
  font-size: 1.8rem;
  padding: 0 2rem;
  gap: 0.5rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  span {
    ${EllipsisStyles}
  }

  @media ${ChatBreakpointSize.tablet} {
    ${({ isThreadOpen }) =>
      isThreadOpen &&
      css`
        display: none;
      `}

    ${({ preventResponsive }) =>
      !preventResponsive &&
      css`
        min-height: 5.6rem;
      `}
  }

  ${(props) =>
    props.hasNewMessages &&
    css`
      background-color: ${color('superSoftGreen')};
    `}
`

export const LeftContainer = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  max-width: 60vw;
  min-width: 0;
  gap: ${(props) => props.gap || 0};
`

export const RightContainer = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${(props) => props.gap || '1rem'};

  span {
    z-index: 4;
  }
`

export const Names = styled.div<{ isATopicChannel: boolean }>`
  font-size: 1.4rem;
  font-family: Lato;
  font-weight: 700;

  ${EllipsisStyles}

  @media ${ChatBreakpointSize.tablet} {
    font-size: 1.8rem;
    font-weight: 400;

    ${({ isATopicChannel }) =>
      isATopicChannel &&
      css`
        display: none;
      `}
  }

  @media ${maxSize.mobile} {
    font-size: 1.8rem;
    font-weight: normal;
  }
`

export const TooltipUserName = styled.div`
  display: flex;
  align-items: center;
`

export const OptionsDropdownItem = styled(OptionsDropdown.Item)`
  :hover svg path {
    fill: ${color('primaryBlue')};
  }
`
