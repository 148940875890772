import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  getIsCurrentCompanyFund,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import {
  getCurrentCompany,
  isDealCurrentCompanyOwnedByCurrentGroup,
  isFundCurrentCompanyOwnedByCurrentGroup,
} from 'selectors/company'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useAvailableUpdateTypes } from 'utils/hooks/useAvailableUpdateTypes'
import { useCurrentInvestorManagementEntities } from 'utils/hooks/useCurrentInvestorManagementEntities'
import { useQueryData } from 'utils/hooks/useQueryData'
import { companyKeys } from 'utils/queries/companies'
import { CompanyHoldingData } from 'utils/types/company'
import { Portfolio } from 'utils/types/portfolios'
import {
  UpdateCreatedFrom,
  CreateUpdateRouteState,
} from 'utils/types/updateForm'

interface UseCeleste {
  isOpen: boolean
  isTransactionVisible: boolean
  isAccountingVisible: boolean
  isAnnouncementVisible: boolean
  isNoteVisible: boolean
  isDocumentVisible: boolean
  isIUEVisible: boolean
  isMetricVisible: boolean
  company?: CompanyHoldingData
  isAnimationEnabled: boolean
  handleOnClick: () => void
  getCreateUpdateRouteState: () => CreateUpdateRouteState
}

interface Props {
  disabled?: boolean
  showCreatePortfolioOption?: boolean
  showCreateTransactionOption?: boolean
}

export const useCeleste = ({
  disabled,
  showCreatePortfolioOption = false,
  showCreateTransactionOption = false,
}: Props): UseCeleste => {
  const location = useLocation()
  const { companyId } = useParams<{ companyId: string }>()
  const [isOpen, setIsOpen] = useState(false)
  const [isAnimationEnabled, setIsAnimationEnabled] = useState(false)

  const isComingFromInvestorsTab =
    location.pathname.includes('investors') &&
    location.pathname.includes('investments')

  const currentPortfolio: Portfolio | undefined =
    useAppSelector(getCurrentPortfolio)
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const currentCompany = useAppSelector(getCurrentCompany)
  const isCurrentCompanyFund = useAppSelector(getIsCurrentCompanyFund)
  const planFeatures = usePlanFeatures()
  const fundOwnByCurrentGroup = useAppSelector(
    isFundCurrentCompanyOwnedByCurrentGroup
  )
  const dealOwnByCurrentGroup = useAppSelector(
    isDealCurrentCompanyOwnedByCurrentGroup
  )

  const { getAvailableUpdateTypesByCompany } = useAvailableUpdateTypes()
  const availableUpdates = getAvailableUpdateTypesByCompany(currentCompany)

  const company = useQueryData<CompanyHoldingData>(companyKeys.byId(companyId))

  const { currentInvestmentVehicle, currentInvestor } =
    useCurrentInvestorManagementEntities()

  const isOnInvestorManagementProfile =
    !!currentInvestor || !!currentInvestmentVehicle
  const isFundPortfolio =
    isFundManager && currentPortfolio?.type === PortfolioTypes.FUND

  const isDealPortfolio =
    isFundManager && currentPortfolio?.type === PortfolioTypes.DEAL

  const isAccountingVisible =
    availableUpdates.accounting && !showCreatePortfolioOption

  const isIUEVisible = availableUpdates.report && !showCreatePortfolioOption

  const isTransactionVisible =
    (availableUpdates.transaction &&
      currentPortfolio?.type !== PortfolioTypes.TRACK &&
      !showCreatePortfolioOption) ||
    showCreateTransactionOption

  const isNoteVisible = availableUpdates.note && !showCreatePortfolioOption

  const isDocumentVisible =
    availableUpdates.document && !showCreatePortfolioOption

  const isAnnouncementVisible =
    ((!isOnInvestorManagementProfile && availableUpdates.announcement) ||
      isFundPortfolio ||
      isDealPortfolio ||
      fundOwnByCurrentGroup ||
      dealOwnByCurrentGroup) &&
    !showCreatePortfolioOption

  const handleOnClick = () => {
    if (!disabled) {
      setIsAnimationEnabled(true)
      setIsOpen((state) => !state)
    }
  }

  const isMetricVisible =
    !!planFeatures.areMetricsEnabled &&
    !showCreatePortfolioOption &&
    !isOnInvestorManagementProfile &&
    (isFounder ||
      isCurrentCompanyFund ||
      (!!company && !company.isPrivateProfile))

  const getCreateUpdateRouteState = (): CreateUpdateRouteState => {
    if (isCurrentCompanyFund) {
      return { updateCreatedFrom: UpdateCreatedFrom.ORGANIZATION_PROFILE }
    }

    if (currentInvestmentVehicle) {
      return {
        updateCreatedFrom: UpdateCreatedFrom.INVESTMENT_VEHICLE,
        investmentVehicle: currentInvestmentVehicle,
      }
    }

    if (currentInvestor) {
      return {
        updateCreatedFrom: UpdateCreatedFrom.INVESTOR,
        investor: currentInvestor,
        showInvestorAndFundSelector: true,
      }
    }

    if (currentPortfolio?.id) {
      const state = {
        updateCreatedFrom: UpdateCreatedFrom.PORTFOLIO,
        portfolio: currentPortfolio,
      }

      if (
        (currentPortfolio.type === PortfolioTypes.FUND ||
          currentPortfolio.type === PortfolioTypes.DEAL) &&
        isComingFromInvestorsTab
      ) {
        return {
          ...state,
          showInvestorAndFundSelector: true,
        }
      }

      return {
        ...state,
      }
    }

    if (currentCompany) {
      return {
        updateCreatedFrom: UpdateCreatedFrom.HOLDING,
        holding: currentCompany,
      }
    }

    return {
      updateCreatedFrom: UpdateCreatedFrom.UPDATES,
    }
  }

  return {
    isOpen,
    isTransactionVisible,
    isAccountingVisible,
    isAnnouncementVisible,
    isIUEVisible,
    isMetricVisible,
    company,
    isAnimationEnabled,
    handleOnClick,
    isNoteVisible,
    isDocumentVisible,
    getCreateUpdateRouteState,
  }
}
