import axiosClient from './httpClient'

class EmployeeService {
  static editEmployee = (id, body) => {
    return axiosClient().patch(`/employees/${id}`, body)
  }

  static removeEmployee = (id, groupId) => {
    return axiosClient(
      false,
      groupId ? { 'group-id': groupId } : undefined
    ).delete(`/employees/${id}`)
  }
}

export default EmployeeService
