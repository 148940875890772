import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ModalBody = styled.div<{ maxWidth?: string }>`
  max-width: ${(props) => props.maxWidth || 'auto'};
`

export const ModalBodyPrimaryText = styled.p`
  color: ${color('darkBlue')};
  font-size: 1.8rem;
  margin-bottom: 1.4rem;
  line-height: normal;
`

export const ModalBodySecondaryText = styled.span`
  font-size: 1.4rem;
  color: ${color('gray')};
  font-size: 1.4rem;
  line-height: normal;
`
