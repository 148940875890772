import React, { useEffect, useState } from 'react'
import { useMessageContext } from 'stream-chat-react'

import { usePublicFundProfileQuery } from 'utils/hooks/queries/usePortfolioQuery'
import { HoldingType } from 'utils/types/company'
import { ChatHoldingTopic } from './HoldingAttachment'
import HoldingAttachmentBox from './HoldingAttachmentBox'

interface FundProfileAttachmentProps {
  fundProfileId: string
  link: string
}

const FundProfileAttachment: React.FC<FundProfileAttachmentProps> = ({
  fundProfileId,
  link,
}) => {
  const [holding, setHolding] = useState<ChatHoldingTopic>()
  const { message } = useMessageContext()
  const { publicFundProfile, fundCompanyData } = usePublicFundProfileQuery(
    fundProfileId,
    { staleTime: 500 }
  )

  useEffect(() => {
    if (publicFundProfile && fundCompanyData) {
      const fetchedHolding: ChatHoldingTopic = {
        id: publicFundProfile.id,
        name: publicFundProfile.name,
        image: fundCompanyData.primaryLogo.mediumLogo.url,
        link,
        type: HoldingType.FUND,
      }
      setHolding(fetchedHolding)
    }
  }, [publicFundProfile, fundCompanyData, link, message.id])

  return holding ? <HoldingAttachmentBox holding={holding} /> : null
}

export default FundProfileAttachment
