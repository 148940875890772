import { Scroll } from 'App.styles'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { AssistanceCardType } from 'utils/types/onboardingModal'
import { maxSize } from 'utils/constants/breakpoint'

export const Container = styled.div`
  ${Scroll}
  overflow-x: auto;
  max-width: 100%;
  padding-top: 0.2rem;
  padding-bottom: 1rem;
`

export const AssistanceContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  width: fit-content;
`

export const CardContainer = styled.a<{
  backgroundSrc: string
  type?: AssistanceCardType
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 15rem;
  height: 11rem;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  background-image: url(${({ backgroundSrc }) => backgroundSrc});

  ${({ type }) =>
    type === AssistanceCardType.SUGGESTED_UPDATE &&
    css`
      background-size: cover;
    `}

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 0.2rem ${color('veryLightBlue40')};
  }
`

export const AbsoluteCardWrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  left: 30rem;
  border-radius: 1.4rem;
  background: white;
  z-index: 4;

  @media ${maxSize.lg} {
    left: 2rem;
    max-width: calc(100vw - 4rem);
  }
`

export const CardRowContainer = styled.div`
  display: flex;
  padding: 0.7rem;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.8rem 0 rgba(16, 21, 39, 0.05);
`

export const CardImage = styled.div<{
  backgroundSrc: string
}>`
  width: 15rem;
  height: 13.6rem;
  background-image: url(${({ backgroundSrc }) => backgroundSrc});
  background-size: cover;
  border-radius: 0.8rem 0 0 0.8rem;
`

export const ContentWrapper = styled.div`
  display: flex;
  background-color: #f8f7fe;
  border-radius: 0 0.8rem 0.8rem 0;
  color: ${color('darkGray')};
  flex: 1;
`

export const Content = styled.div`
  padding: 0.85rem 0 0.85rem 1.6rem;
  max-width: min(23.1rem, 100%);
  width: 23.1rem;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.6rem;
    margin-bottom: 0.6rem;
  }
  p {
    font-size: 1.2rem;
  }

  a {
    height: 2.6rem;
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0 1.2rem;
    font-size: 1.2rem;
    letter-spacing: 0.036rem;
  }

  @media ${maxSize.xsm} {
    width: auto;
  }
`

export const Question = styled.div`
  padding: 0.8rem 1.6rem 1.2rem 1.6rem;
  color: ${color('darkGray')};
  font-size: 1.4rem;
  background-color: white;
  border-top: 0.1rem solid ${color('veryLightGray')};
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
`

export const Separator = styled.div`
  flex: 1;
  height: 1rem;
  min-height: 1rem;
`
