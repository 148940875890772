import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import * as Styles from './ShowMoreLess.styles'

interface ShowMoreLessProps {
  className?: string
  isShowMoreNeeded: boolean
  isShowingMore: boolean
  toggleShowingMore: () => void
}

const ShowMoreLess = ({
  isShowMoreNeeded,
  isShowingMore,
  className,
  toggleShowingMore,
}: ShowMoreLessProps) => {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation()
      toggleShowingMore()
    },
    [toggleShowingMore]
  )

  if (!isShowMoreNeeded) {
    return null
  }

  return (
    <Styles.ShowMoreLess className={className} onClick={onClick}>
      <FormattedMessage
        id={isShowingMore ? 'general.showLess' : 'general.readMore'}
      />
    </Styles.ShowMoreLess>
  )
}

export default ShowMoreLess
