import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import Tooltip from 'components/Tooltip'
import { DraftEditor } from 'utils/types/update'
import { ButtonWrapper } from 'components/Breadcrumb/UpdateActions/UpdateActions.styles'
import theme from 'utils/theme'
import TooltipActionWrapper from 'components/Breadcrumb/UpdateActions/Components/TooltipActionWrapper/TooltipActionWrapper'

type EditionLocked = {
  userEditing: DraftEditor
}

interface Props {
  disabled?: boolean
  editionLocked?: EditionLocked
  redirectToEditView: () => void
}

const EditUpdateButton = ({
  disabled,
  editionLocked,
  redirectToEditView,
}: Props) => {
  const intl = useIntl()
  const isEditionLocked = !!editionLocked?.userEditing

  if (isEditionLocked) {
    const blockedByText = intl.formatMessage(
      { id: 'updates.header.updateLocked' },
      {
        userName: editionLocked?.userEditing.name,
      }
    )

    return (
      <Tooltip
        id={`UpdateLockedBy${editionLocked?.userEditing.name}`}
        text={blockedByText}
        place="bottom"
        widthAuto
        delayShow={500}
      >
        <ButtonWrapper transparent disabled onClick={redirectToEditView}>
          <FontAwesomeIcon
            color={theme.colors.darkBlue}
            icon={['fal', 'pencil']}
          />
        </ButtonWrapper>
      </Tooltip>
    )
  }

  return (
    <TooltipActionWrapper
      id="edit-update-action"
      text={intl.formatMessage({ id: 'updateActions.editUpdate' })}
    >
      <ButtonWrapper
        transparent
        disabled={disabled}
        onClick={redirectToEditView}
      >
        <FontAwesomeIcon
          color={theme.colors.darkBlue}
          icon={['fal', 'pencil']}
        />
      </ButtonWrapper>
    </TooltipActionWrapper>
  )
}

export default EditUpdateButton
