import { useCallback, useEffect, useState } from 'react'
import { useChatContext } from 'stream-chat-react'

import { setUnreadMessagesCount } from 'features/chatSlice'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { setDesktopBadge } from 'utils/functions/electron'
import { StreamChatType } from '../types'

export const useUnreadMessages = () => {
  const { client } = useChatContext<StreamChatType>()
  const [totalUnreadMessages, setTotal] = useState(0)
  const [totalUnreadChannels, setTotalUnreadChannels] = useState(0)
  const dispatch = useAppDispatch()

  const setTotalUnreadMessages = useCallback(
    (total: number) => {
      setTotal(total)
      dispatch(setUnreadMessagesCount(total))

      if (total > 0) {
        setDesktopBadge(total)
      } else {
        setDesktopBadge()
      }
    },
    [dispatch]
  )

  const setInitialCounters = useCallback(async () => {
    const user = client?.user

    if (user) {
      const channels = await client.queryChannels(
        {
          members: { $in: [user.id] },
        },
        {},
        { watch: false, state: false }
      )

      let currentTotalUnreadMessages = 0
      let currentTotalUnreadChannels = 0

      channels.forEach((channel) => {
        const unreadCount = channel.countUnread()
        if (unreadCount > 0 && !channel.muteStatus().muted) {
          currentTotalUnreadMessages += unreadCount
          currentTotalUnreadChannels++
        }
      })

      setTotalUnreadMessages(currentTotalUnreadMessages)
      setTotalUnreadChannels(currentTotalUnreadChannels)
    }
  }, [client, setTotalUnreadMessages])

  useEffect(() => {
    if (!client) return () => {}

    const handleEvent = (event) => {
      if (event.total_unread_count !== undefined) {
        setTotalUnreadMessages(event.total_unread_count)
      }

      if (event.unread_channels !== undefined) {
        setTotalUnreadChannels(event.unread_channels)
      }
    }

    client.on(handleEvent)

    return () => {
      client.off(handleEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client])

  useEffect(() => {
    if (client) {
      setInitialCounters()
    }
  }, [client, setInitialCounters])

  return {
    totalUnreadMessages,
    totalUnreadChannels,
  }
}
