import { useRouteMatch, Route, Switch } from 'react-router-dom'

import SidebarMetricsRoutes from 'routes/SidebarMetricsRoutes'
import Metrics from 'containers/Metrics/Metrics'
import UpdateAllMetricsModal from 'containers/Metrics/UpdateAllMetricsModal/UpdateAllMetricsModal'
import { MetricsContextProvider } from 'containers/Metrics/MetricsContext'

const MetricsRoutes = () => {
  const routeMatch = useRouteMatch()

  return (
    <MetricsContextProvider>
      <Metrics />

      <Switch>
        <Route exact path={`${routeMatch.path}/update-all/:optionalCompanyId?`}>
          <UpdateAllMetricsModal />
        </Route>

        <Route path={[`${routeMatch.path}/:id`]}>
          <SidebarMetricsRoutes urlPrefix={routeMatch.url} />
        </Route>
      </Switch>
    </MetricsContextProvider>
  )
}

export default MetricsRoutes
