import { DraftCompany, DraftFund, DraftHoldingType } from 'api/HoldingsService'
import { AddHoldingForm, HoldingType } from '../types'

export const mapToBulkImportBackendRequest = (
  holding: AddHoldingForm,
  index: number,
  isBulkImport: boolean = false
): DraftCompany | DraftFund => {
  if (holding.type === HoldingType.COMPANY) {
    return {
      index,
      holding_type: DraftHoldingType.COMPANY,
      legal_entity_name: holding.company!.legalEntityName,
      name: holding.company!.name,
      website: holding.company!.website,
      point_of_contact: holding.company!.pointOfContact,
      ...(isBulkImport && { primary_logo: holding.company!.logo?.file }),
    }
  }

  return {
    index,
    holding_type: DraftHoldingType.FUND,
    funds: holding.funds!.funds,
    include_fund_manager: holding.funds!.includeFundManager,
    fund_manager_name: holding.funds!.fundManager?.name,
    fund_manager_website: holding.funds!.fundManager?.website,
    fund_manager_point_of_contact: holding.funds!.fundManager?.pointOfContact,
    ...(isBulkImport && { logo: holding.funds!.fundManager?.logo?.file }),
  }
}
