import dayjs from 'dayjs'
import DOMPurify from 'dompurify'
import { chain } from 'lodash'
import uniqWith from 'lodash/uniqWith'
import type { IntlShape } from 'react-intl'

import { EmailRecipientStatus } from 'utils/constants/emailRecipientStatus'
import { ScheduleRepetitionType } from 'utils/constants/scheduleRepetition'
import { SortDirection } from 'utils/constants/sortDirection'
import { TransactionInstruments } from 'utils/constants/transactionInstruments'
import { TransactionTypes } from 'utils/constants/transactionTypes'
import { getTransactionInitialFormValues } from 'utils/functions/transactions'

import {
  PlainUpdateTypes,
  UpdateInvestorGroupUrl,
  UpdateTypes,
  UpdateUrl,
  UpdateVisibility,
} from 'utils/constants/updates'
import {
  DropdownOption,
  Nullable,
  PermissionItem,
  SetupUpdateRequestDataParams,
  UpdatePermissions,
  UpdateType as TSUpdateType,
  UpdateType,
  UpdateVisibilityType,
  PlainUpdateType,
} from 'utils/types/common'
import { LoggingUpdateContent } from 'utils/types/files'
import { UpdateAttachment } from 'utils/types/transactions'
import { EntityName } from 'utils/hooks/useEntityFromUrl'
import {
  AccountingItem,
  AnnouncementItem,
  DocumentItem,
  DraftHashAttributes,
  IndexUpdate,
  IUEReportItem,
  LoggingUpdateEntity,
  LoggingUpdateGroup,
  LoggingUpdateReshare,
  LoggingUpdateUser,
  NavigationUpdate,
  NoteItem,
  ScheduledUpdate,
  ScheduleType,
  ScheduleType as ScheduleTypeEnum,
  TransactionItem,
  Update,
  NoteUpdate,
  DocumentUpdate,
  AnnouncementUpdate,
  TransactionUpdate,
  isDraftHashAttributes,
  MixedUpdate,
  EmailUpdate,
} from 'utils/types/update'
import theme from 'utils/theme'
import {
  AccessType,
  CreateBasicFormValues,
  CreateTransactionFormValues,
  CreateUpdateFormValues,
} from 'utils/types/updateForm'
import { GroupTypes } from 'utils/constants/groups'
import {
  FundPortfolio,
  IndexFundPortfolio,
  Portfolio,
  PortfolioCompany,
} from 'utils/types/portfolios'
import { AppGroup, User } from 'utils/types/user'
import { IndexInvestor } from 'utils/types/investors'
import {
  getEditDraftPermissions,
  getPermissionsFromDraftHash,
  getPermissionsFromServerUpdate,
} from './api/updates'

import { orderArrayByObjectPropertyAndDirection } from './array'
import { HANDLE_REGEX } from './regex'
import { isUpdateExpired } from './update-permissions'
import { setXeroReportParams } from './xeroReports'
import { getAddedAndRemovedEntities } from './utils'

export const ONLY_ME = 'only_me'
export const CUSTOM = 'custom'
export const PUBLIC = 'everyone'
export const CUSTOM_GROUP = 'custom_group'

export const getUpdateType = (type) => type?.split('::')[1]?.toLowerCase()

export const capitalizedUpdateType = ({
  updateType,
}: {
  updateType?: string
}) => {
  if (!updateType) return ''
  return updateType.charAt(0).toUpperCase() + updateType.slice(1)
}

export const stringToHTML = (str) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(str, 'text/html')
  return doc.body
}

export function castLoggingUpdateGroup(
  entity: LoggingUpdateEntity
): entity is LoggingUpdateGroup {
  return !!(entity as LoggingUpdateGroup).groupableId
}
export function castLoggingUpdateUser(
  entity: LoggingUpdateEntity
): entity is LoggingUpdateUser {
  return !!(entity as LoggingUpdateUser).userId
}

export const removeAlreadyAdded = (allItems, alreadyAddedItems) =>
  allItems.filter((id) => !alreadyAddedItems.some((item) => item.id === id))

export const getRemovedLoggingUpdateEntityIds = (
  loggingUpdateEntities: LoggingUpdateEntity[],
  newItems: string[],
  newVisibility: UpdateVisibilityType
): string[] => {
  if (newVisibility !== UpdateVisibility.CUSTOM) {
    return loggingUpdateEntities.map((loggingEntity) => loggingEntity.id)
  }

  return loggingUpdateEntities
    .filter((entity) => {
      if (castLoggingUpdateGroup(entity)) {
        return !newItems.some((itemId) => itemId === entity.groupableId)
      }

      return !newItems.some(
        (itemId) => itemId === (entity as LoggingUpdateUser).userId
      )
    })
    .map((entity) => entity.id)
}

export const setPermissionsFormData = ({
  formData,
  updateType,
  visibility,
  sharedGroups,
  sharedUsers,
  ownByGroup,
  removedGroups,
  removedUsers,
}) => {
  const updateBaseName = `${updateType}[logging_update_attributes]`
  formData.append(`${updateBaseName}[visibility]`, visibility)

  if (visibility === CUSTOM) {
    sharedGroups.forEach((id) => {
      formData.append(
        `${updateBaseName}[logging_update_groups_attributes][][groupable_id]`,
        id
      )
      formData.append(
        `${updateBaseName}[logging_update_groups_attributes][][original]`,
        true
      )
    })

    sharedUsers.forEach((id) => {
      formData.append(
        `${updateBaseName}[logging_update_users_attributes][][user_id]`,
        id
      )
      formData.append(
        `${updateBaseName}[logging_update_users_attributes][][original]`,
        true
      )
    })

    removedGroups?.forEach((id) => {
      formData.append(
        `${updateBaseName}[logging_update_groups_attributes][][id]`,
        id
      )
      formData.append(
        `${updateBaseName}[logging_update_groups_attributes][][_destroy]`,
        true
      )
    })
    removedUsers?.forEach((id) => {
      formData.append(
        `${updateBaseName}[logging_update_users_attributes][][id]`,
        id
      )
      formData.append(
        `${updateBaseName}[logging_update_users_attributes][][_destroy]`,
        true
      )
    })
  }

  if (visibility === CUSTOM || visibility === PUBLIC) {
    formData.append(`${updateBaseName}[owned_by_group]`, ownByGroup)
  }

  if (visibility === ONLY_ME) {
    formData.append(`${updateBaseName}[owned_by_group]`, 'false')
  }
}

export const setupUpdateRequestData = ({
  title,
  body,
  date = new Date(),
  visibility,
  sharedGroups = [],
  sharedUsers = [],
  ownByGroup,
  uploads = [],
  entity,
  updateName = 'logging_update_attributes',
  isEditingUpdate,
  tags,
  removeTags = false,
  schedule,
  reportParams,
  repetition,
  reportType,
  removedGroups,
  reshareSettings,
  isFounder,
}: SetupUpdateRequestDataParams) => {
  const formdata = new FormData()
  const updateBaseName =
    updateName === 'update' ? 'update' : `${entity}[${updateName}]`

  let formDataDate =
    !isFounder &&
    dayjs(date).isToday() &&
    schedule?.scheduleType !== ScheduleType.SCHEDULE
      ? new Date()
      : date

  if (!isFounder && dayjs(date).isBefore(dayjs().startOf('day'))) {
    formDataDate = dayjs(date).hour(12).minute(0).toDate()
  }

  formdata.append(
    `${updateBaseName}[confidential]`,
    String(!!reshareSettings.isConfidential)
  )
  formdata.append(
    `${updateBaseName}[blocked]`,
    String(!!reshareSettings.isBlocked)
  )

  if (entity === 'note' || entity === 'document' || entity === 'announcement') {
    const formattedDate = formDataDate ? formDataDate.toISOString() : Date()
    formdata.append(`${entity}[date]`, formattedDate)
    if (isEditingUpdate) {
      formdata.append(`${updateName}[date]`, formattedDate)
    } else {
      formdata.append(
        `${entity}[logging_update_attributes][date]`,
        formattedDate
      )
    }
  }

  if (
    entity !== 'email' &&
    entity !== 'xero_report' &&
    entity !== 'quickbooks_report'
  ) {
    formdata.append(`${entity}[title]`, title!)
    if (body) {
      formdata.append(`${entity}[text]`, body)
    }
  }

  if (entity === 'xero_report' || entity === 'quickbooks_report') {
    formdata.append(`${entity}[title]`, title!)
    setXeroReportParams(formdata, reportType, reportParams, {
      entity,
      date,
    })

    if (
      schedule?.scheduleType &&
      schedule?.scheduleType !== ScheduleType.SEND_NOW &&
      repetition
    ) {
      formdata.append(`logging_update_schedule[repetition]`, repetition)
    }
  }

  if (schedule?.scheduleType === ScheduleType.SEND_NOW) {
    formdata.append(`${updateName}[date]`, new Date().toISOString())
  }

  if (entity === 'xero_report' || entity === 'quickbooks_report') {
    if (formDataDate) {
      formdata.append(`${entity}[date]`, formDataDate.toISOString())
      formdata.append(
        `${updateBaseName}[date]`,
        schedule?.scheduleType === ScheduleType.SEND_NOW
          ? new Date().toISOString()
          : formDataDate.toISOString()
      )
    } else if (
      schedule &&
      (schedule.scheduleType === ScheduleType.SEND_NOW || schedule.destroy)
    ) {
      formdata.append(`${entity}[date]`, new Date().toISOString())
      formdata.append(`${updateBaseName}[date]`, new Date().toISOString())
    } else if (schedule && schedule.scheduleType === ScheduleType.SCHEDULE) {
      formdata.append(`${entity}[date]`, schedule.date!.toISOString())
      formdata.append(`${updateBaseName}[date]`, schedule.date!.toISOString())
    }
  }

  if (schedule && schedule.destroy) {
    formdata.append(`logging_update_schedule[_destroy]`, 'true')
    formdata.append(`logging_update_schedule[id]`, schedule.id!)
  } else if (schedule && schedule.scheduleType === ScheduleType.SCHEDULE) {
    formdata.append(
      `logging_update_schedule[schedule_date]`,
      schedule.date!.toISOString()
    )
  }

  formdata.append(`${updateBaseName}[visibility]`, visibility)

  if (visibility === CUSTOM) {
    sharedGroups.forEach((id) => {
      formdata.append(
        `${updateBaseName}[logging_update_groups_attributes][][groupable_id]`,
        id
      )
      formdata.append(
        `${updateBaseName}[logging_update_groups_attributes][][original]`,
        'true'
      )
      formdata.append(
        `${updateBaseName}[logging_update_groups_attributes][][id]`,
        ''
      )
    })

    sharedUsers.forEach((id) => {
      formdata.append(
        `${updateBaseName}[logging_update_users_attributes][][user_id]`,
        id
      )
      formdata.append(
        `${updateBaseName}[logging_update_users_attributes][][original]`,
        'true'
      )
    })

    removedGroups?.forEach((id) => {
      formdata.append(
        `${updateBaseName}[logging_update_groups_attributes][][id]`,
        id
      )
      formdata.append(
        `${updateBaseName}[logging_update_groups_attributes][][_destroy]`,
        'true'
      )
    })
  }

  if (visibility === CUSTOM || visibility === PUBLIC) {
    formdata.append(`${updateBaseName}[owned_by_group]`, String(!!ownByGroup))
  }

  uploads.forEach((file: any) => {
    if (!file.destroy) {
      if (file?.isNewUpload && isEditingUpdate) {
        formdata.append(`update[attachments_attributes][][id]`, '')
        formdata.append(`update[attachments_attributes][][file]`, file)
      } else if (file?.isNewUpload && !isEditingUpdate) {
        formdata.append(`update[attachments_attributes][][id]`, '')
        formdata.append(
          `${entity}[logging_update_attributes][attachments_attributes][][id]`,
          ''
        )
        formdata.append(
          `${entity}[logging_update_attributes][attachments_attributes][][file]`,
          file
        )
      }
    }

    if (!file?.isNewUpload && file.destroy) {
      formdata.append(`update[attachments_attributes][][id]`, file.id)
      formdata.append(`update[attachments_attributes][][_destroy]`, 'true')
    }
  })

  if (!removeTags) {
    tags.forEach((tag) => {
      if (!isEditingUpdate) {
        if (tag.alreadyExists) {
          formdata.append(
            `${entity}[logging_update_attributes][taggings_attributes][][tag_id]`,
            tag.id
          )
        } else {
          formdata.append(
            `${entity}[logging_update_attributes][taggings_attributes][][tag_id]`,
            ''
          )
          formdata.append(
            `${entity}[logging_update_attributes][taggings_attributes][][tag_attributes][name]`,
            tag.name
          )
        }
      } else if (isEditingUpdate) {
        if (!tag.taggingId) {
          if (tag.alreadyExists) {
            formdata.append(`update[taggings_attributes][][tag_id]`, tag.id)
          } else {
            formdata.append(`update[taggings_attributes][][tag_id]`, '')
            formdata.append(
              `update[taggings_attributes][][tag_attributes][name]`,
              tag.name
            )
          }
        }
      }
    })
  } else {
    formdata.delete('update[logging_update_groups_attributes][][groupable_id]')
    formdata.delete('update[logging_update_users_attributes][][user_id]')
    tags.forEach((tag) => {
      if (tag.destroy && tag.taggingId) {
        formdata.append(`update[taggings_attributes][][id]`, tag.taggingId)
        formdata.append(`update[taggings_attributes][][_destroy]`, 'true')
      }
    })
  }

  return formdata
}

const updateBaseData = (
  {
    title,
    body,
    visibility,
    sharedGroups = [],
    sharedUsers = [],
    removedGroups = [],
    removedUsers = [],
    ownByGroup,
    uploads = [],
    entity,
    isEditingUpdate,
    tags,
    removeTags = false,
    schedule,
    reshareSettings,
  }: SetupUpdateRequestDataParams,
  loggingUpdateId: string,
  isEditable = true
) => {
  const formdata = new FormData()
  const entityPrefix = entity === 'email' ? 'report' : entity
  const loggingUpdateAttributesPrefix = `${entityPrefix}[logging_update_attributes]`
  const updateWithCommonData =
    entity !== 'email' &&
    entity !== 'xero_report' &&
    entity !== 'quickbooks_report'

  formdata.append(
    `${loggingUpdateAttributesPrefix}[confidential]`,
    String(!!reshareSettings.isConfidential)
  )

  formdata.append(
    `${loggingUpdateAttributesPrefix}[blocked]`,
    String(!!reshareSettings.isBlocked)
  )

  if (updateWithCommonData) {
    formdata.append(`${entityPrefix}[title]`, title!)
    if (isEditable) {
      formdata.append(`${entityPrefix}[text]`, body!)
    }
  }

  if (schedule?.scheduleType === ScheduleType.SEND_NOW) {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[date]`,
      new Date().toISOString()
    )
  }

  if (schedule && schedule.destroy) {
    const date = new Date().toISOString()
    formdata.append('logging_update_schedule[_destroy]', 'true')
    formdata.append('logging_update_schedule[id]', schedule.id!)
    formdata.append(`${loggingUpdateAttributesPrefix}[date]`, date)
    formdata.append(`${entityPrefix}[date]`, date)
  } else if (schedule && schedule.scheduleType === ScheduleType.SCHEDULE) {
    formdata.append(
      'logging_update_schedule[schedule_date]',
      schedule.date!.toISOString()
    )
  }

  formdata.append(`${loggingUpdateAttributesPrefix}[visibility]`, visibility)

  sharedGroups.forEach((id) => {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_groups_attributes][][groupable_id]`,
      id
    )
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_groups_attributes][][original]`,
      'true'
    )
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_groups_attributes][][id]`,
      ''
    )
  })

  sharedUsers.forEach((id) => {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_users_attributes][][user_id]`,
      id
    )
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_users_attributes][][original]`,
      'true'
    )
  })

  removedGroups?.forEach((id) => {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_groups_attributes][][id]`,
      id
    )
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_groups_attributes][][_destroy]`,
      'true'
    )
  })
  removedUsers?.forEach((id) => {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_users_attributes][][id]`,
      id
    )
    formdata.append(
      `${loggingUpdateAttributesPrefix}[logging_update_users_attributes][][_destroy]`,
      'true'
    )
  })

  if (visibility === CUSTOM || visibility === PUBLIC) {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[owned_by_group]`,
      String(ownByGroup)
    )
  }

  if (visibility === ONLY_ME) {
    formdata.append(`${loggingUpdateAttributesPrefix}[owned_by_group]`, 'false')
  }

  uploads.forEach((file: any) => {
    if (!file.destroy) {
      if (file?.isNewUpload && isEditingUpdate) {
        formdata.append(
          `${loggingUpdateAttributesPrefix}[attachments_attributes][][id]`,
          ''
        )
        formdata.append(
          `${loggingUpdateAttributesPrefix}[attachments_attributes][][file]`,
          file
        )
      } else if (file?.isNewUpload && !isEditingUpdate) {
        formdata.append(`${entityPrefix}[attachments_attributes][][id]`, '')
        formdata.append(
          `${loggingUpdateAttributesPrefix}[attachments_attributes][][id]`,
          ''
        )
        formdata.append(
          `${loggingUpdateAttributesPrefix}[attachments_attributes][][file]`,
          file
        )
      }
    }

    if (!file?.isNewUpload && file.destroy) {
      formdata.append(
        `${loggingUpdateAttributesPrefix}[attachments_attributes][][id]`,
        file.id
      )
      formdata.append(
        `${loggingUpdateAttributesPrefix}[attachments_attributes][][_destroy]`,
        'true'
      )
    }
  })

  if (removeTags) {
    formdata.delete(
      `${entityPrefix}[logging_update_groups_attributes][][groupable_id]`
    )
    formdata.delete(
      `${entityPrefix}[logging_update_users_attributes][][user_id]`
    )
    tags.forEach((tag) => {
      if (tag.destroy && tag.taggingId) {
        formdata.append(
          `${entityPrefix}[taggings_attributes][][id]`,
          tag.taggingId
        )
        formdata.append(
          `${entityPrefix}[taggings_attributes][][destroy]`,
          'true'
        )
      }
    })
  } else {
    tags.forEach((tag) => {
      if (isEditingUpdate) {
        if (!tag.taggingId) {
          if (tag.alreadyExists) {
            formdata.append(
              `${loggingUpdateAttributesPrefix}[taggings_attributes][][tag_id]`,
              tag.id
            )
          } else {
            formdata.append(
              `${loggingUpdateAttributesPrefix}[taggings_attributes][][tag_id]`,
              ''
            )
            formdata.append(
              `${loggingUpdateAttributesPrefix}[taggings_attributes][][tag_attributes][name]`,
              tag.name
            )
          }
        }
      }
    })
  }

  formdata.append(`${loggingUpdateAttributesPrefix}[id]`, loggingUpdateId)

  return formdata
}

const setFormDataDate = (formData, entity, date, isFounder) => {
  let formDataDate = date
  if (!isFounder && dayjs(date).isBefore(dayjs().startOf('day'))) {
    formDataDate = dayjs(date).hour(12).minute(0).toDate()
  }
  if (date) {
    formData.append(`${entity}[date]`, formDataDate.toISOString())
    formData.append(
      `${entity}[logging_update_attributes][date]`,
      formDataDate.toISOString()
    )
  }
}

export const setupUpdateAnnouncementRequestData = (
  announcementProps: SetupUpdateRequestDataParams,
  loggingUpdateId: string
) => {
  const { entity, date, isFounder } = announcementProps
  const formData = updateBaseData(announcementProps, loggingUpdateId)
  setFormDataDate(formData, entity, date, isFounder)

  return formData
}

export const setupUpdateDocumentRequestData = (
  documentProps: SetupUpdateRequestDataParams,
  loggingUpdateId: string,
  isEditable: boolean
) => {
  const { entity, date, isFounder } = documentProps
  const formData = updateBaseData(documentProps, loggingUpdateId, isEditable)
  setFormDataDate(formData, entity, date, isFounder)

  return formData
}

export const setupUpdateAccountingRequestData = (
  accountingProps: SetupUpdateRequestDataParams,
  loggingUpdateId: string,
  updatePermissions: UpdatePermissions
) => {
  const {
    date,
    entity,
    isFounder,
    title,
    repetition,
    reportParams,
    reportType,
    schedule,
    updateName = 'logging_update_attributes',
  } = accountingProps
  const formdata = updateBaseData(accountingProps, loggingUpdateId)
  const updateBaseName =
    updateName === 'update' ? 'update' : `${entity}[${updateName}]`

  let formDataDate =
    date &&
    !isFounder &&
    dayjs(date).isToday() &&
    schedule?.scheduleType !== ScheduleType.SCHEDULE
      ? new Date()
      : date

  if (date && !isFounder && dayjs(date).isBefore(dayjs().startOf('day'))) {
    formDataDate = dayjs(date).hour(12).minute(0).toDate()
  }

  if (updatePermissions.isContentEditable) {
    formdata.append(`${entity}[title]`, title!)
    setXeroReportParams(formdata, reportType, reportParams, {
      entity,
      date,
    })

    if (
      schedule?.scheduleType &&
      schedule?.scheduleType !== ScheduleType.SEND_NOW &&
      repetition
    ) {
      formdata.append(`logging_update_schedule[repetition]`, repetition)
    }

    if (formDataDate) {
      formdata.append(`${entity}[date]`, new Date(formDataDate).toISOString())
      formdata.append(
        `${updateBaseName}[date]`,
        schedule?.scheduleType === ScheduleType.SEND_NOW
          ? new Date().toISOString()
          : new Date(formDataDate).toISOString()
      )
    }
  } else if (
    schedule &&
    (schedule.scheduleType === ScheduleType.SEND_NOW || schedule.destroy)
  ) {
    formdata.append(`${entity}[date]`, new Date().toISOString())
    formdata.append(`${updateBaseName}[date]`, new Date().toISOString())
  } else if (schedule && schedule.scheduleType === ScheduleType.SCHEDULE) {
    formdata.append(`${entity}[date]`, schedule.date!.toISOString())
    formdata.append(`${updateBaseName}[date]`, schedule.date!.toISOString())
  }

  return formdata
}

export const setupUpdateEmailRequestData = (
  emailProps: SetupUpdateRequestDataParams,
  loggingUpdateId: string
) => {
  const { date, schedule } = emailProps
  const formdata = updateBaseData(emailProps, loggingUpdateId)
  const loggingUpdateAttributesPrefix = `report[logging_update_attributes]`
  let formDataDate =
    dayjs(date).isToday() && schedule?.scheduleType !== ScheduleType.SCHEDULE
      ? new Date()
      : date

  if (dayjs(date).isBefore(dayjs().startOf('day'))) {
    formDataDate = dayjs(date).hour(12).minute(0).toDate()
  }

  if (formDataDate) {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[date]`,
      schedule?.scheduleType === ScheduleType.SEND_NOW
        ? new Date().toISOString()
        : formDataDate.toISOString()
    )
  } else if (
    schedule &&
    (schedule.scheduleType === ScheduleType.SEND_NOW || schedule.destroy)
  ) {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[date]`,
      new Date().toISOString()
    )
  } else if (schedule && schedule.scheduleType === ScheduleType.SCHEDULE) {
    formdata.append(
      `${loggingUpdateAttributesPrefix}[date]`,
      schedule.date!.toISOString()
    )
  }

  return formdata
}

export const setupUpdateNoteRequestData = (
  noteProps: SetupUpdateRequestDataParams,
  loggingUpdateId: string
) => {
  const { entity, date, isFounder } = noteProps
  const formData = updateBaseData(noteProps, loggingUpdateId)
  setFormDataDate(formData, entity, date, isFounder)

  return formData
}

// TODO: Remove this function when we remove the old update feed
export const groupUpdatesByDate = (updatesParam, dateField = 'date') => {
  const today: Update[] = []
  const yesterday: Update[] = []
  const byMonths: { [month: string]: Update[] } = {}

  let monthOrder = 0
  let currentMonth = ''

  if (Array.isArray(updatesParam)) {
    updatesParam.forEach((update) => {
      if (update) {
        // if the draft was never published, it is shown in the date it was edited
        const date =
          update.isDraftUpdate && !update.published
            ? update.updatedAt
            : update[dateField]

        if (dayjs(date).format('DD-MM-YYYY') === dayjs().format('DD-MM-YYYY')) {
          today.push(update)
        } else if (
          dayjs().subtract(1, 'day').format('DD-MM-YYYY') ===
          dayjs(date).format('DD-MM-YYYY')
        ) {
          yesterday.push(update)
        } else {
          const month = dayjs(date).format('MMMM YYYY')
          if (month !== currentMonth) {
            currentMonth = month
            monthOrder += 1
            byMonths[monthOrder] = [update]
          } else {
            byMonths[monthOrder].push(update)
          }
        }
      }
    })
  }

  return {
    today,
    yesterday,
    byMonths,
  }
}

const DELETE_UPDATE_THRESHOLD_IN_DAYS = 2
export const isUpdateOlderThanTwoDays = (createdAt) => {
  const now = dayjs()
  const updateDate = dayjs(createdAt)
  return now.diff(updateDate, 'day') >= DELETE_UPDATE_THRESHOLD_IN_DAYS
}

export const isUpdateEditable = ({ update, userId, currentGroupId }) => {
  if (update.visibility === ONLY_ME) {
    return true
  }
  const updateGroupType = update.group?.type

  if (update.ownedByGroup) {
    const updateGroupId = update.group?.id ?? update.groupId ?? update.group
    if (
      (updateGroupType === GroupTypes.CLIENT ||
        updateGroupType === GroupTypes.FUND_MANAGER) &&
      updateGroupId === currentGroupId
    ) {
      return true
    }
  }

  if (!update.ownedByGroup) {
    const updateUserId = update.user?.id ?? update.user
    if (
      (updateGroupType === GroupTypes.CLIENT ||
        updateGroupType === GroupTypes.FUND_MANAGER) &&
      updateUserId === userId
    ) {
      return true
    }
  }

  if (
    updateGroupType === GroupTypes.FUND_MANAGER &&
    getUpdateType(update.type) === 'transaction'
  ) {
    return true
  }

  return (
    update.loggingUpdateSchedule || !isUpdateOlderThanTwoDays(update.createdAt)
  )
}

export const getBaseUpdateRoute = (update, type) => {
  let updateRoute = type
  const isUpdateEmail = updateRoute === 'iue' || updateRoute === 'report'
  const isDraftEmail = update.sentAt === null

  if (isUpdateEmail && isDraftEmail) {
    updateRoute = 'draft'
  } else if (isUpdateEmail && !isDraftEmail) {
    updateRoute = 'email'
  }

  return updateRoute
}

export const getAttachmentsInUpdate = (allAttachments, update) =>
  allAttachments.filter((attachment) => {
    return update.contents.some((updateAttachment) => {
      const updateAttachmentId =
        typeof updateAttachment === 'string'
          ? updateAttachment
          : updateAttachment.id
      return updateAttachmentId === attachment.id
    })
  })

export const parseUpdatesForNavigation = (
  updates: IndexUpdate[]
): NavigationUpdate[] => {
  return updates.map<NavigationUpdate>((update) => {
    const isEmail = update.updateType === UpdateTypes.IUE
    const isDraftEmail = isEmail && (update as EmailUpdate).item.sentAt === null
    return {
      updateId: update.id,
      updateType: update.updateType,
      holding: update.holding,
      isDraftUpdate: isEmail ? isDraftEmail : update.isDraftUpdate,
    }
  })
}

export const getScheduleType = (date: Date): ScheduleTypeEnum =>
  dayjs(date).isAfter(new Date())
    ? ScheduleTypeEnum.SCHEDULE
    : ScheduleTypeEnum.SEND_NOW

export const isUpdateScheduled = (scheduleType, date) => {
  if (!date) {
    return false
  }

  return (
    scheduleType === ScheduleType.SCHEDULE &&
    date.setSeconds(0) > new Date().setSeconds(0)
  )
}

export const setLogColors = (logs) => {
  let colors = [
    { color: '#ff94a0', used: false },
    { color: '#fbd28a', used: false },
    { color: '#c1dba2', used: false },
    { color: '#ffcaa4', used: false },
    { color: '#82edda', used: false },
    { color: '#bac7f8', used: false },
  ]

  const resetUnusedColors = () => {
    colors = colors.map((color) => ({
      ...color,
      used: false,
    }))
  }

  const pickUnusedColor = () => {
    const unused = colors.find((color) => !color.used)
    if (!unused) {
      resetUnusedColors()
      colors[0].used = true
      return colors[0].color
    }
    unused.used = true
    return unused.color
  }
  const colorPerUser = {}

  return logs.map((log) => {
    const userId = log.user?.id

    if (colorPerUser[userId]) {
      return {
        ...log,
        color: colorPerUser[userId],
      }
    }

    const unusedColor = pickUnusedColor()
    colorPerUser[userId] = unusedColor
    return {
      ...log,
      color: unusedColor,
    }
  })
}

export const getScheduleRepetition = (
  update: ScheduledUpdate,
  intl: IntlShape
) => {
  const { date } = update
  const dayOfWeek = dayjs(date).format('dddd')
  const dateDo = dayjs(date).format('Do')
  const monthDate = dayjs(date).format('MMM. DD')

  switch (update.repetition) {
    case ScheduleRepetitionType.NO_REPETITION:
      return intl.formatMessage({ id: 'general.doesNotRepeat' })
    case ScheduleRepetitionType.DAILY:
      return intl.formatMessage({ id: 'general.daily' }, { dayOfWeek })
    case ScheduleRepetitionType.WEEKLY_ON_DAY:
      return intl.formatMessage({ id: 'general.weeklyOnDay' }, { dayOfWeek })
    case ScheduleRepetitionType.WEEKLY_ON_DATE:
      return intl.formatMessage({ id: 'general.weeklyOnDate' }, { dateDo })
    case ScheduleRepetitionType.MONTHLY_FIRST_DAY:
      return intl.formatMessage(
        { id: 'general.monthlyFirstDay' },
        { dayOfWeek }
      )
    case ScheduleRepetitionType.MONTHLY_LAST_DAY:
      return intl.formatMessage({ id: 'general.monthlyLastDay' }, { dayOfWeek })
    case ScheduleRepetitionType.ANUALLY_ON_DATE:
      return intl.formatMessage({ id: 'general.annuallyOnDate' }, { monthDate })
    default:
      return ''
  }
}

export const getColorForEmailStatus = (status) => {
  switch (status) {
    case EmailRecipientStatus.SENT:
      return theme.colors.orange
    case EmailRecipientStatus.DELIVERED:
      return theme.colors.green
    case EmailRecipientStatus.OPENED:
      return theme.colors.brightBlue
    case EmailRecipientStatus.ERROR:
      return theme.colors.red
    case EmailRecipientStatus.QUEUED:
      return theme.colors.skyBlue
    case EmailRecipientStatus.SPAM:
      return theme.colors.lightGray
    case EmailRecipientStatus.CLICKED:
      return theme.colors.purple
    default:
      return theme.colors.green
  }
}

export const getOrderForStatus = (status) => {
  switch (status) {
    case EmailRecipientStatus.QUEUED:
      return 1
    case EmailRecipientStatus.SENT:
      return 2
    case EmailRecipientStatus.DELIVERED:
      return 3
    case EmailRecipientStatus.OPENED:
      return 4
    case EmailRecipientStatus.ERROR:
      return 5
    default:
      return 1
  }
}

export const getTransactionTypes = (intl) => {
  return [
    {
      id: TransactionTypes.COMMITMENT,
      label: intl.formatMessage({ id: 'transactions.commitment' }),
    },
    {
      id: TransactionTypes.INVESTMENT,
      label: intl.formatMessage({ id: 'transactions.investment' }),
    },
    {
      id: TransactionTypes.DISTRIBUTION,
      label: intl.formatMessage({ id: 'transactions.distribution' }),
    },
  ]
}

export const getTransactionInstruments = (intl) => {
  return [
    {
      id: TransactionInstruments.CONVERTIBLE_NOTE,
      label: intl.formatMessage({ id: 'transactions.convertibleNote' }),
    },
    {
      id: TransactionInstruments.DEBT_CREDIT,
      label: intl.formatMessage({ id: 'transactions.debtCredit' }),
    },
    {
      id: TransactionInstruments.EQUITY,
      label: intl.formatMessage({ id: 'transactions.equity' }),
    },
    {
      id: TransactionInstruments.FUND_INVESTMENT,
      label: intl.formatMessage({ id: 'transactions.fundInvestment' }),
    },
    {
      id: TransactionInstruments.PREFERRED_EQUITY,
      label: intl.formatMessage({ id: 'transactions.preferredEquity' }),
    },
    {
      id: TransactionInstruments.SAFE,
      label: intl.formatMessage({ id: 'transactions.safe' }),
    },
    {
      id: TransactionInstruments.WARRANTS,
      label: intl.formatMessage({ id: 'transactions.warrants' }),
    },
    {
      id: TransactionInstruments.OTHER,
      label: intl.formatMessage({ id: 'transactions.other' }),
    },
  ]
}

export const getEmailUpdateDropdownOptions = ({
  currentGroupIsOwnerOfEmail,
  intl,
  canEdit,
  onSelectDuplicateEmail,
  onSelectAdditionalRecipients,
  onSelectEdit,
}) => {
  const options: DropdownOption[] = []
  if (currentGroupIsOwnerOfEmail) {
    options.push(
      {
        id: 'copy',
        label: intl.messages['showEmail.makeACopy'],
        icon: ['far', 'clone'],
        onSelectOption: onSelectDuplicateEmail,
      },
      {
        id: 'sendAdditionalRecipients',
        label: intl.messages['showEmail.sendToAdditionalrecipients'],
        icon: ['far', 'envelope'],
        onSelectOption: onSelectAdditionalRecipients,
      }
    )
  }

  if (canEdit) {
    options.unshift({
      id: 'edit',
      label: intl.messages['general.edit'],
      icon: ['far', 'pen'],
      onSelectOption: onSelectEdit,
    })
  }

  return options
}

export const searchMatchesGroup = (group, searchValue = '') => {
  let normalizedSearch = searchValue.toLowerCase()

  if (HANDLE_REGEX.test(searchValue)) {
    normalizedSearch = normalizedSearch.replace('@', '')
  }
  return (
    group?.name?.toLowerCase().includes(normalizedSearch) ||
    group?.handle?.toLowerCase().includes(normalizedSearch)
  )
}

export const getIconByUpdateType = (type) => {
  switch (type) {
    case 'note':
      return 'sticky-note'
    case 'announcement':
      return 'megaphone'
    case 'document':
      return 'file'
    case 'transaction':
      return 'money-check-alt'
    case 'accounting':
      return 'file-invoice-dollar'
    case 'iue':
    case 'report':
      return 'envelope'
    default:
      return 'sticky-note'
  }
}

export const getIconByPlainUpdateType = (type: PlainUpdateType) => {
  switch (type) {
    case 'Logging::Note':
      return 'sticky-note'
    case 'Logging::Announcement':
      return 'megaphone'
    case 'Logging::Document':
      return 'file'
    case 'Logging::Transaction':
      return 'money-check-alt'
    case 'Logging::QuickbooksReport':
    case 'Logging::XeroReport':
      return 'file-invoice-dollar'
    case 'Reporting::IUE::Report':
    case 'Reporting::Report':
      return 'envelope'
    default:
      return 'sticky-note'
  }
}

interface GetLabelByPlainUpdateTypeProps {
  type: PlainUpdateType
  intl: IntlShape
}

export const getLabelByPlainUpdateType = ({
  type,
  intl,
}: GetLabelByPlainUpdateTypeProps) => {
  switch (type) {
    case PlainUpdateTypes.NOTE:
      return intl.formatMessage({ id: 'general.updatesTypes.note' })
    case PlainUpdateTypes.ANNOUNCEMENT:
      return intl.formatMessage({ id: 'general.updatesTypes.announcement' })
    case PlainUpdateTypes.DOCUMENT:
      return intl.formatMessage({ id: 'general.updatesTypes.document' })
    case PlainUpdateTypes.TRANSACTION:
      return intl.formatMessage({ id: 'general.updatesTypes.transaction' })
    case PlainUpdateTypes.QUICKBOOKS_REPORT:
    case PlainUpdateTypes.XERO_REPORT:
      return intl.formatMessage({ id: 'general.updatesTypes.accounting' })
    case PlainUpdateTypes.REPORTING:
    case PlainUpdateTypes.IUE:
      return intl.formatMessage({ id: 'general.updatesTypes.email' })
    default:
      return intl.formatMessage({ id: 'general.updatesTypes.note' })
  }
}

export const getUpdateUrlType = (updateType: UpdateType): string => {
  switch (updateType) {
    case UpdateTypes.NOTE:
      return 'notes'
    case UpdateTypes.DOCUMENT:
      return 'documents'
    case UpdateTypes.ANNOUNCEMENT:
      return 'announcements'
    case UpdateTypes.TRANSACTION:
      return 'transactions'
    case UpdateTypes.IUE:
      return 'emails'
    case UpdateTypes.ACCOUNTING:
      return 'accounting'
    default:
      return ''
  }
}

const getCreateDraftBaseUrl = (
  useSubjectUrl: boolean,
  subject: EntityName,
  subjectId: string
) => {
  if (useSubjectUrl) {
    return `${subject}/${subjectId}`
  }

  return `/updates`
}

export const getCreateDraftUpdateUrl = (
  updateType: UpdateType,
  draftUpdateId: string,
  useSubjectUrl: boolean,
  subject: EntityName,
  subjectId: string
) => {
  const baseUrl = getCreateDraftBaseUrl(useSubjectUrl, subject, subjectId)
  const updateTypePath = getUpdateUrlType(updateType)
  return `${baseUrl}/${updateTypePath}/new/${draftUpdateId}`
}

export const getColorByPlainUpdateType = (type: PlainUpdateType) => {
  switch (type) {
    case 'Logging::Note':
      return theme.colors.yellow
    case 'Logging::Announcement':
      return theme.colors.red
    case 'Logging::Document':
      return theme.colors.brightBlue
    case 'Logging::Transaction':
      return theme.colors.orange
    case 'Logging::QuickbooksReport':
    case 'Logging::XeroReport':
      return theme.colors.purple
    case 'Reporting::IUE::Report':
    case 'Reporting::Report':
      return theme.colors.green
    default:
      return theme.colors.yellow
  }
}

export const getUpdateBaseUrl = (
  update: IndexUpdate,
  isComingFromUpdatesView: boolean,
  entity?: { id: string; name: string }
): string => {
  const baseUrl = isComingFromUpdatesView
    ? 'updates'
    : `${entity?.name}/${entity?.id}`

  switch (update.updateType) {
    case UpdateTypes.NOTE:
      return `/${baseUrl}/notes/${update.id}`
    case UpdateTypes.DOCUMENT:
      return `/${baseUrl}/documents/${update.id}`
    case UpdateTypes.ANNOUNCEMENT:
      return `/${baseUrl}/announcements/${update.id}`
    case UpdateTypes.TRANSACTION:
      return `/${baseUrl}/transactions/${update.id}`
    case UpdateTypes.IUE:
      return `/${baseUrl}/emails/${update.id}`
    case UpdateTypes.ACCOUNTING:
      return `/${baseUrl}/accounting/${update.id}`
    default:
      return ''
  }
}

export const getUpdateReshareUrl = (
  update: IndexUpdate,
  isComingFromUpdatesView: boolean,
  entity?: { id: string; name: EntityName }
): string => {
  const baseUrl = getUpdateBaseUrl(update, isComingFromUpdatesView, entity)
  return `${baseUrl}/reshare`
}

export const getUpdateTypeFromUrl = (pathname): UpdateType => {
  if (pathname.includes('document')) {
    return UpdateTypes.DOCUMENT
  }
  if (pathname.includes('note')) {
    return UpdateTypes.NOTE
  }
  if (pathname.includes('transaction')) {
    return UpdateTypes.TRANSACTION
  }
  if (pathname.includes('accounting')) {
    return UpdateTypes.ACCOUNTING
  }
  if (pathname.includes('announcement')) {
    return UpdateTypes.ANNOUNCEMENT
  }
  if (pathname.includes('email')) {
    return UpdateTypes.IUE
  }
  return UpdateTypes.NOTE
}

export const getUpdateItemTitle = (update?: IndexUpdate): string => {
  if (!update) return ''

  if (update.isDraftUpdate && isDraftHashAttributes(update.draftHash)) {
    return update.draftHash.updatableAttributes.title ?? ''
  }

  switch (update.updateType) {
    case UpdateTypes.NOTE:
      return (update.item as NoteItem).title ?? ''
    case UpdateTypes.DOCUMENT:
      return (update.item as DocumentItem).title ?? ''
    case UpdateTypes.ANNOUNCEMENT:
      return (update.item as AnnouncementItem).title ?? ''
    case UpdateTypes.TRANSACTION:
      return (update.item as TransactionItem).title ?? ''
    case UpdateTypes.IUE:
      return (update.item as IUEReportItem).subject ?? ''
    case UpdateTypes.ACCOUNTING:
      return (update.item as AccountingItem).title ?? ''
    default:
      return ''
  }
}

export const getUpdateItemText = (update: IndexUpdate): string => {
  if (update.isDraftUpdate && isDraftHashAttributes(update.draftHash)) {
    return update.draftHash.updatableAttributes.text ?? ''
  }

  switch (update.updateType) {
    case UpdateTypes.NOTE:
      return (update.item as NoteItem).text
    case UpdateTypes.DOCUMENT:
      return (update.item as DocumentItem).text
    case UpdateTypes.ANNOUNCEMENT:
      return (update.item as AnnouncementItem).text
    case UpdateTypes.TRANSACTION:
      return (update.item as TransactionItem).text
    case UpdateTypes.IUE:
      return (update.item as IUEReportItem).subject
    case UpdateTypes.ACCOUNTING:
    default:
      return ''
  }
}

export const getSanitizedHtml = (html?: string): string => {
  if (!html) return ''
  return DOMPurify.sanitize(html)
}

export const removeHtmlTags = (html?: string): string => {
  if (!html) return ''
  return DOMPurify.sanitize(html, { ALLOWED_TAGS: [] })
}

export const getSanitizedBodyIfNotExpired = (
  body: string,
  expirationDate?: string
) => {
  if (expirationDate && !isUpdateExpired(expirationDate)) {
    return getSanitizedHtml(body)
  }

  return body
}

export const getSubjectPath = (
  subject: EntityName,
  subjectId: string
): string => {
  if (subject === EntityName.FUNDS) {
    return `${EntityName.PORTFOLIOS}/${subjectId}`
  }

  return `${subject}/${subjectId}`
}
export function castUpdate<T extends Update>(
  update: Update,
  updateType: TSUpdateType
): update is T {
  return update.updateType === updateType
}

export const getResharesWithMeOrMyGroup = (
  update: IndexUpdate,
  currentGroupId: string,
  userId: string
): LoggingUpdateReshare[] => {
  const resharedWithMyGroup = (update?.loggingUpdateGroups ?? [])
    .filter((group) => group.groupableId === currentGroupId)
    .map((loggingGroup) => loggingGroup.loggingReshares)
    .flat()

  const resharedWithMyUser = (update?.loggingUpdateUsers ?? [])
    .filter((user) => user.userId === userId)
    .map((loggingUser) => loggingUser.loggingReshares)
    .flat()

  return orderArrayByObjectPropertyAndDirection(
    [...resharedWithMyGroup, ...resharedWithMyUser],
    'createdAt',
    SortDirection.ASC
  )
}

export const getUpdateReshares = (
  update: IndexUpdate
): LoggingUpdateReshare[] => {
  const resharedWithMyGroup = update.loggingUpdateGroups
    .map((loggingGroup) => loggingGroup.loggingReshares)
    .flat()

  const resharedWithMyUser = update.loggingUpdateUsers
    .map((loggingUser) => loggingUser.loggingReshares)
    .flat()

  const reshares = uniqWith(
    [...resharedWithMyGroup, ...resharedWithMyUser],
    (reshareA, reshareB) => {
      return reshareA.setToken === reshareB.setToken
    }
  )

  return orderArrayByObjectPropertyAndDirection(
    reshares,
    'createdAt',
    SortDirection.ASC
  )
}

export const getPermissionItems = (
  update: Update,
  showResharedPermissions: boolean = false
): PermissionItem[] => {
  const filterOriginal = (loggingEntity: LoggingUpdateEntity) => {
    return showResharedPermissions
      ? !loggingEntity.original
      : loggingEntity.original
  }

  return [
    ...update.loggingUpdateGroups.filter(filterOriginal).map(
      (loggingGroup) =>
        ({
          type: 'group',
          id: loggingGroup.groupableId,
          name: loggingGroup.groupName,
          logo: loggingGroup.groupLogo,
        } as PermissionItem)
    ),
    ...update.loggingUpdateUsers.filter(filterOriginal).map(
      (loggingUser) =>
        ({
          type: !loggingUser.userName ? 'email' : 'user',
          id: loggingUser.userId,
          name: loggingUser.userName ?? loggingUser.userEmail,
          logo: loggingUser.userImage,
        } as PermissionItem)
    ),
  ]
}

export const getPermissionItemsFromReshareTokenSet = (
  tokenSet: ReshareTokenSet
): PermissionItem[] => {
  return tokenSet.loggingUpdateEntities.map((loggingUpdateEntity) => {
    if (castLoggingUpdateGroup(loggingUpdateEntity)) {
      return {
        type: 'group',
        id: loggingUpdateEntity.groupableId,
        name: loggingUpdateEntity.groupName,
        logo: loggingUpdateEntity.groupLogo,
      } as PermissionItem
    }

    const loggingUpdateser = loggingUpdateEntity as LoggingUpdateUser
    return {
      type: !loggingUpdateser.userName ? 'email' : 'user',
      id: loggingUpdateser.userId,
      name: loggingUpdateser.userName ?? loggingUpdateser.userEmail,
      logo: loggingUpdateser.userImage,
    } as PermissionItem
  })
}

export type ReshareTokenSet = {
  token: string
  resharerUserId: string
  resharerGroupId: string
  message: string | undefined
  loggingUpdateEntities: LoggingUpdateEntity[]
  reshares: LoggingUpdateReshare[]
  createdAt: Date
}

export const getResharesByToken = (update: IndexUpdate): ReshareTokenSet[] => {
  const loggingUpdateEntities: LoggingUpdateEntity[] = [
    ...(update.loggingUpdateGroups ?? []),
    ...(update.loggingUpdateUsers ?? []),
  ]

  const reshares = loggingUpdateEntities
    .map((loggingEntity) => loggingEntity.loggingReshares)
    .flat()

  const resharesByToken: ReshareTokenSet[] = chain(reshares)
    .groupBy((reshare) => reshare.setToken)
    .mapValues((resharesForToken) => {
      const { userId, setToken, message, groupId, createdAt } =
        resharesForToken[0]

      return {
        resharerUserId: userId,
        resharerGroupId: groupId,
        token: setToken,
        loggingUpdateEntities: loggingUpdateEntities.filter((loggingGroup) =>
          loggingGroup.loggingReshares.some((rsh) => rsh.setToken === setToken)
        ),
        message,
        reshares: orderArrayByObjectPropertyAndDirection(
          resharesForToken,
          'createdAt',
          SortDirection.ASC
        ) as LoggingUpdateReshare[],
        createdAt,
      }
    })
    .values()
    .value()

  return orderArrayByObjectPropertyAndDirection(
    resharesByToken,
    'createdAt',
    SortDirection.ASC
  )
}

export const userHasResharedUpdate = (
  update: IndexUpdate,
  userId: string
): boolean => {
  const userHasResharesWithGroups = update.loggingUpdateGroups.some(
    (loggingUpdateGroup) =>
      loggingUpdateGroup.loggingReshares.some(
        (reshare) => reshare.userId === userId
      )
  )

  const userHasResharesWithUsers = update.loggingUpdateUsers.some(
    (loggingUpdateUser) =>
      loggingUpdateUser.loggingReshares.some(
        (reshare) => reshare.userId === userId
      )
  )

  return userHasResharesWithGroups || userHasResharesWithUsers
}

export const getIsUpdateScheduled = (update?: Update): boolean => {
  return update?.loggingUpdateSchedule?.scheduleDate
    ? new Date(update?.loggingUpdateSchedule?.scheduleDate) > new Date()
    : false
}

const getInvestorGroupBaseUrl = (update: LoggingUpdateContent) => {
  return `/${
    UpdateInvestorGroupUrl[update.subjectMatterInfo?.type as string]
  }/${update.subjectMatterInfo?.id}/updates`
}

export const getUpdateUrlFromSubjectMatter = (
  update: LoggingUpdateContent,
  isActingAsAnInvestorGroup?: boolean
) => {
  let baseUrl = ''

  if (isActingAsAnInvestorGroup) {
    baseUrl = getInvestorGroupBaseUrl(update)
  } else {
    baseUrl =
      UpdateUrl[update.subjectMatterInfo?.type as string] === 'updates'
        ? '/updates'
        : `/${UpdateUrl[update.subjectMatterInfo?.type as string]}/${
            update.subjectMatterInfo?.id
          }`
  }
  return `${baseUrl}/${getUpdateUrlType(update.type)}/${update.id}`
}

export const getDefaultFormValues = (
  updateType: UpdateType,
  groupOwned?: boolean,
  owner?: User,
  investor?: Nullable<IndexInvestor>
): CreateUpdateFormValues => {
  return {
    owner,
    permissions: {
      yourGroup:
        groupOwned || investor?.id ? AccessType.CAN_EDIT : AccessType.NO_ACCESS,
      community: AccessType.NO_ACCESS,
      sharedWith: [],
      confidentialUpdate: false,
      investor: investor?.id ? AccessType.CAN_VIEW : undefined,
    },
    files: [],
    tags: [],
    updateType,
  }
}

export type UpdateWithItem =
  | NoteUpdate
  | DocumentUpdate
  | AnnouncementUpdate
  | TransactionUpdate

export type UpdateBasicFormValues = Pick<
  CreateUpdateFormValues,
  'permissions'
> &
  CreateBasicFormValues & {
    updateId?: string
  }

export type TransactionFormValues = Pick<
  CreateUpdateFormValues,
  'permissions'
> &
  CreateTransactionFormValues & {
    updateId?: string
  }

export type UpdateFormValue = UpdateBasicFormValues | TransactionFormValues

export const getUpdatePortfolio = (
  update: IndexUpdate
): Nullable<Portfolio | IndexFundPortfolio> => {
  if (update.portfolio?.id) return update.portfolio
  if (update.fundPortfolio?.id) return update.fundPortfolio
  return null
}

export const getBasicUpdateFormValues = ({
  update,
  getPublishedValues,
}: {
  update: UpdateWithItem
  getPublishedValues?: boolean
}): UpdateBasicFormValues => {
  let updateFormValues: UpdateBasicFormValues

  if (update?.isDraftUpdate && !getPublishedValues) {
    const draftHash = update.draftHash as DraftHashAttributes
    const {
      title = '',
      text: body = '',
      date,
      associationType,
    } = draftHash.updatableAttributes

    updateFormValues = {
      updateType: update.updateType,
      title,
      body,
      date: new Date(date),
      files: draftHash.contents,
      tags:
        draftHash.tags?.map((tag) => ({
          ...tag,
          alreadyExists: true,
        })) ?? [],
      permissions: getPermissionsFromDraftHash(
        draftHash,
        update.group?.id,
        draftHash.investor?.id
      ),
      owner: update.user,
      investor: draftHash.investor,
      portfolio: draftHash.fundPortfolio as FundPortfolio<PortfolioCompany>,
      associationType,
    }
  } else {
    const {
      title = '',
      text: body = '',
      date = new Date(),
      associationType,
    } = update.item
    const files = update.contents
    const tags = update.tags.map((tag) => ({
      ...tag,
      alreadyExists: true,
    }))

    updateFormValues = {
      title,
      body,
      date,
      tags,
      files,
      associationType,
      permissions: getPermissionsFromServerUpdate(update),
      updateType: UpdateTypes.NOTE,
      owner: update.user,
      investor: update.item.investor,
      portfolio: update.fundPortfolio as FundPortfolio,
    }
  }

  return updateFormValues
}

export const getUpdateFormValues = ({
  update,
  getPublishedValues,
}: {
  update: UpdateWithItem
  getPublishedValues?: boolean
}): UpdateFormValue => {
  if (update.updateType === UpdateTypes.TRANSACTION) {
    return getTransactionInitialFormValues(
      update as TransactionUpdate,
      getPublishedValues
    )
  }

  return getBasicUpdateFormValues({ update, getPublishedValues })
}

const getEditedFiles = (
  newFiles: UpdateAttachment[],
  updateFiles: UpdateAttachment[]
) => {
  const uploads: UpdateAttachment[] = []

  newFiles.forEach((file) => {
    const isUpdateFile = updateFiles?.find((content) => content.id === file.id)
    if (!isUpdateFile || (file.destroy && isUpdateFile)) {
      uploads.push(file)
    }
  })
  updateFiles.forEach((content) => {
    if (!newFiles.find((file) => file.id === content.id)) {
      uploads.push({
        ...content,
        destroy: true,
      })
    }
  })

  return uploads
}

export const getDraftEditedFields = (
  values: CreateBasicFormValues,
  currentGroup: Nullable<AppGroup>,
  update?: Update
) => {
  const { files: newFiles, permissions: newPermissions, tags } = values
  let uploads: UpdateAttachment[] = []
  const permissions = getEditDraftPermissions(
    newPermissions,
    currentGroup,
    update
  )

  if (update?.published) {
    uploads = getEditedFiles(newFiles, update.contents)
  } else {
    uploads = newFiles.filter((file) => !file.destroy)
  }

  const { added: addedTags, removed: removedTags } = getAddedAndRemovedEntities(
    update?.tags ?? [],
    tags
  )

  return {
    uploads,
    permissions,
    addedTags,
    removedTags,
  }
}

export const isScheduled = (update: ScheduledUpdate) => {
  const { scheduleDates } = update

  return scheduleDates?.some((scheduledDate) =>
    dayjs(scheduledDate).isAfter(new Date())
  )
}

/*
  This is a helper to get the correct place the update has to be in the Update Feed.
  This fix the issue we have when an update has a future date but it was created in the past.
  In that case, in the update feed the update is displayed first (cause the Update feed display the newest update first)
  But actually that update should be displayed when it was created rather than when it was scheduled to.
*/
export const getUpdateDate = (update: MixedUpdate) =>
  update.draft && !update.scheduled ? update.createdAt : update.date
