import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CloseIconWrapper = styled.div`
  svg {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
`
export const GridFile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  border-radius: 0.8rem;
  background-color: var(--color-white);
  border: 0.05rem solid ${color('veryLightGray')};
  box-shadow: 0 0 1.5rem 0 #1015271a;
  height: 4rem;
`

export const ItemName = styled.span`
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${color('darkGray')};
  max-width: 17rem;
`

export const PreviewItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  gap: 1rem;
`

export const PreviewImage = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const ImageContainer = styled.div`
  background-size: cover;
  background-position: center;
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;

  height: 3.8rem;
  width: 3.8rem;
  margin-left: -1rem;
`
