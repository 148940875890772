import { css } from 'styled-components'
import { DEFAULT_DRAWER_WIDTH } from 'components/Drawer/Drawer'
import { color } from 'utils/functions/colors'
import { ChatBreakpointSize } from 'utils/constants/chat'

export const StreamMobileThread = css`
  z-index: 4;
  min-width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
`

export const StreamStylesOverrides = css`
  .str-chat__list {
    outline: none;
    background-color: transparent;
  }

  * {
    font-family: 'Lato', sans-serif !important;
  }

  .str-chat__list .str-chat__message-list-scroll .str-chat__li {
    padding-inline: 0 !important;
  }

  .str-chat__list .str-chat__parent-message-li {
    border: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .str-chat__main-panel-inner {
    flex: 1;
  }

  .str-chat__list .str-chat__message-list-scroll .str-chat__ul {
    margin-top: 2.2rem;
  }

  .str-chat__list--thread {
    height: 100% !important;
  }

  .str-chat__thread-container {
    box-shadow: 0px 0px 1.5rem 0px ${color('darkBlue', 0.1)};
    min-width: ${DEFAULT_DRAWER_WIDTH};
    max-width: ${DEFAULT_DRAWER_WIDTH};

    .str-chat__li--top {
      margin: initial;
      padding: initial;
    }

    .str-chat__reverse-infinite-scroll {
      padding: initial;

      .str-chat__li {
        margin: 0;
      }
    }

    @media ${ChatBreakpointSize.tablet} {
      ${StreamMobileThread}
    }
  }
`

export const StreamChatChannel = css`
  .str-chat-channel {
    position: unset;

    .str-chat__container {
      display: grid;
      grid-template-columns: 1fr auto;

      .str-chat__main-panel {
        max-height: 100%;
        overflow: hidden;
      }
    }
  }
`
