import { useEffect } from 'react'

import { isLeftCode, isRightCode } from 'utils/functions/keyboardEvents'
import { DIRECTION } from 'utils/constants/updateNavigation'
import { ValueOf } from 'utils/types/common'

import {
  BtnNavigation,
  NavigationArrowWrapper,
} from './UpdateNavigation.styles'

interface Props {
  disablePreviousButton: boolean
  disableNextButton: boolean
  arrowNavigationDisabled?: boolean
  onClickNavigationToUpdate: (direction: ValueOf<typeof DIRECTION>) => void
}

const UpdateNavigation = ({
  onClickNavigationToUpdate,
  disablePreviousButton = false,
  disableNextButton = false,
  arrowNavigationDisabled = false,
}: Props) => {
  const navigateWithKeys = (event) => {
    if (arrowNavigationDisabled) return

    if (isRightCode(event)) {
      onClickNavigationToUpdate(DIRECTION.NEXT)
    } else if (isLeftCode(event)) {
      onClickNavigationToUpdate(DIRECTION.PREVIOUS)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', navigateWithKeys)
    return () => {
      window.removeEventListener('keydown', navigateWithKeys)
    }
  })

  const buttonProps = {
    border: false,
    iconSize: '3.5rem',
    iconFontSize: '1.6rem',
  }

  return (
    <NavigationArrowWrapper>
      <BtnNavigation
        icon="chevron-left"
        disabled={disablePreviousButton}
        onClick={() => {
          onClickNavigationToUpdate(DIRECTION.PREVIOUS)
        }}
        {...buttonProps}
      />
      <BtnNavigation
        icon="chevron-right"
        disabled={disableNextButton}
        onClick={() => {
          onClickNavigationToUpdate(DIRECTION.NEXT)
        }}
        {...buttonProps}
      />
    </NavigationArrowWrapper>
  )
}

export default UpdateNavigation
