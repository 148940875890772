import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { URL } from 'reducers/breadcrumbSlice'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { PROFILE, PROFILE_EDIT } from 'routes/constant/InvestorGroupRoutes'
import {
  INVESTOR_PROFILE,
  INVESTOR_PROFILE_EDIT,
} from 'routes/constant/investorManagementRoutes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ArrowWrapper, LinkWrapper, NavigationItem } from '../Breadcrumb.styles'
import useInvestorInformation from '../BreadcrumbNavigation/useInvestorInformation'

interface InvestorLinkProps {
  showRightArrow: boolean
  url: URL
  handleInvestorId: (id: string) => void
}

const InvestorLink = ({
  showRightArrow,
  url,
  handleInvestorId,
}: InvestorLinkProps) => {
  const investorGroup = useAppSelector(isActingAsInvestorGroup)
  const history = useHistory()
  const location = useLocation<{ investorId?: string }>()
  const investorId = location.state?.investorId

  const { investor, getInvestorAvatar } = useInvestorInformation({
    investorIdFromUrlState: investorId,
    fetchInvestor: true,
  })

  useEffect(() => {
    if (investor) {
      handleInvestorId(investor.id)
    }
  }, [investor, handleInvestorId])

  if (!investor) {
    return null
  }

  const activeTabs = [
    PROFILE,
    PROFILE_EDIT,
    INVESTOR_PROFILE.replace(':investorId', investor.id),
    INVESTOR_PROFILE_EDIT.replace(':investorId', investor.id),
  ]

  const isInvestorActive = activeTabs.some((tab) => tab === url.current)

  return (
    <NavigationItem>
      {getInvestorAvatar()}
      <LinkWrapper
        isActive={isInvestorActive}
        onClick={() => {
          if (isInvestorActive) return

          if (investorGroup) {
            history.push(PROFILE)
            return
          }

          history.push(INVESTOR_PROFILE.replace(':investorId', investor.id))
        }}
      >
        {investor.name}
      </LinkWrapper>

      {showRightArrow && (
        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      )}
    </NavigationItem>
  )
}

export default InvestorLink
