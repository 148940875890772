import styled, { css } from 'styled-components'
import { Panel } from 'react-resizable-panels'

import { color } from 'utils/functions/colors'
import { ChatBreakpointSize } from 'utils/constants/chat'
import { chatHeaderHeight } from './components/ChatHeader'
import {
  StreamChatChannel,
  StreamStylesOverrides,
} from './ChatStreamStylesOverrides'

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex: 1;

  // TODO: remove after stream-chat styles are removed
  // START REMOVE
  ${StreamStylesOverrides}
  ${StreamChatChannel} // END REMOVE
`

export const ChatLayout = styled(Panel)`
  display: flex;
  flex: 1;
  position: relative;

  @media ${ChatBreakpointSize.tablet} {
    flex-direction: column;
  }
`

export const ChatListContainer = styled.div`
  flex: 1;
  --str-chat__font-family: 'Lato', sans-serif;
  --str-chat__message-list-background-color: ${color('cloud')};
  background: ${color('cloud')};
  display: flex;
  flex-direction: column;
  height: calc(100% - ${chatHeaderHeight});
`

export const VisibleContainer = styled.div<{ visible?: boolean }>`
  width: 100%;
  min-height: calc(100% - ${chatHeaderHeight});
  min-width: 0;
  flex: 1;

  ${(props) =>
    !props.visible &&
    css`
      display: none;
    `}
`
