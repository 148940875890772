import type { InfiniteData } from '@tanstack/react-query'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import ContentService, { ContentFilters } from 'api/ContentService'
import { contentsKeys } from 'utils/queries/contents'
import { FileContent, MixedContent } from 'utils/types/files'
import { groupKeys } from 'utils/queries/groups'
import Toast from 'components/Toast'
import { isIndexFileContent, isIndexEmailContent } from 'utils/functions/files'
import EmailContentService from 'api/EmailContentService'

export interface DeleteContentMutationParams {
  content: MixedContent
  deleteRelatedFiles?: boolean
}

/**
 * Mutation to delete a content (file)
 *
 * @param filters - Filters for the current content query
 * @returns An instance of useMutation
 *
 */
export const useDeleteContentMutation = (filters: ContentFilters) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (params: DeleteContentMutationParams) => {
      if (isIndexFileContent(params.content)) {
        await ContentService.deleteContent(params.content.id)
      } else {
        await EmailContentService.deleteContent(
          params.content.id,
          params.deleteRelatedFiles
        )
      }

      return params
    },
    {
      onSuccess: (params: DeleteContentMutationParams) => {
        const previousContents = queryClient.getQueryData<
          InfiniteData<{
            contents: FileContent[]
            page: number
          }>
        >(contentsKeys.all(filters))
        let idsToFilter = [params.content.id]

        if (isIndexEmailContent(params.content) && params.deleteRelatedFiles) {
          idsToFilter = idsToFilter.concat(
            params.content.s3Contents.reduce<string[]>((acc, s3Content) => {
              if (s3Content.unreconciled) {
                acc.push(s3Content.id)
              }

              return acc
            }, [])
          )
        }

        const pagesWithoutDeletedFile = previousContents?.pages.map((page) => {
          return {
            ...page,
            contents: page.contents.reduce<FileContent[]>((acc, content) => {
              if (!idsToFilter.includes(content.id)) {
                if (isIndexEmailContent(content)) {
                  // eslint-disable-next-line no-param-reassign
                  content.s3Contents = content.s3Contents.filter(
                    (s3Content) => !idsToFilter.includes(s3Content.id)
                  )
                }

                acc.push(content)
              }

              return acc
            }, []),
          }
        })

        queryClient.setQueryData(contentsKeys.all(filters), {
          pages: pagesWithoutDeletedFile,
          pageParams: previousContents?.pageParams,
        })
        queryClient.invalidateQueries(groupKeys.storageUsage())

        Toast.displayIntl('files.fileDeletedSuccessfully')
      },
      onError: () => {
        Toast.displayIntl('files.errorDeletingFile', 'error')
      },
    }
  )
}

/**
 * Mutation to edit a file content
 *
 * @param contentId - The id of the content to edit
 * @param fileContent - The new content of the file
 * @returns An instance of useMutation
 *
 */
export const useEditFileContentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      contentId,
      fileContent,
    }: {
      contentId: string
      fileContent: Partial<FileContent>
    }) => {
      return ContentService.editFileContent(contentId, fileContent)
    },
    {
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries(contentsKeys.fileContentById(id))
      },
      onError: () => {
        Toast.displayIntl('files.errorChangingGroupOwned', 'error')
      },
    }
  )
}
