import React from 'react'

import Input from 'ui/Input'
import type { InputProps } from 'ui/Input'

interface TextAreaProps extends InputProps {
  rows?: number
}

const TextArea = React.forwardRef<HTMLInputElement, TextAreaProps>(
  ({ ...props }, ref) => {
    return (
      <Input
        ref={ref}
        {...props}
        as="textarea"
        style={{ height: 'auto', whiteSpace: 'break-spaces' }}
      />
    )
  }
)

export default TextArea
