import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'
import { motion } from 'framer-motion'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import DragAndDropApi from 'utils/functions/dragAndDropApi'

import EmployeeService from 'api/EmployeeService'
import Toast from 'components/Toast'
import Button from 'ui/Button'
import { getCurrentGroupId } from 'selectors/auth'
import { itemAnimation, listAnimation } from 'utils/animations/list'
import EmployeesList from 'components/EmployeesList'

import {
  TeamMembersHeader,
  TeamMembersLabel,
  TeamMembersList,
  TeamMembersTableWrapper,
} from '../../../EmployeesList/EmployeesList.styles'

const TeamMembers = ({
  companyData,
  employees,
  handleChangeEmployeeOrder,
  onAddNewTeamMember,
  onEditTeamMember,
  onDeleteTeamMember,
  toggleHideTeamMember,
}) => {
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const [employeeModal, setEmployeeModal] = useState({ show: false })

  const intl = useIntl()

  const handleDelete = (currentGroup) => async (employee) => {
    try {
      await EmployeeService.removeEmployee(employee.id, currentGroup)
      onDeleteTeamMember(employee)
    } catch (error) {
      Toast.display(intl.messages['editCompany.errorDeleteEmployee'], 'error')
    }
  }

  const handleClickDelete = (employee) => {
    toggleHideTeamMember(employee.id)
    const handleDeleteFunc = handleDelete(currentGroupId)
    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'editCompany.keyMemberDeleted' },
        { name: `${employee.firstName} ${employee.lastName}` }
      ),
      action: () => toggleHideTeamMember(employee.id),
      afterClose: () => handleDeleteFunc(employee),
    })
  }

  const visibleEmployees = useMemo(
    () =>
      employees
        .filter((emp) => !emp.hidden)
        .sort((empA, empB) => empA.position - empB.position),
    [employees]
  )

  const reorderEmployees = async (sourceIndex, destinationIndex) => {
    const reorderedEmployee = visibleEmployees[sourceIndex]

    EmployeeService.editEmployee(reorderedEmployee.id, {
      employee: { position: destinationIndex + 1 },
    })

    const sortedEmployees = DragAndDropApi.reorder(
      visibleEmployees,
      sourceIndex,
      destinationIndex
    )
    handleChangeEmployeeOrder(
      sortedEmployees.map((emp, index) => ({ ...emp, position: index + 1 })),
      reorderedEmployee,
      destinationIndex
    )
  }

  return (
    <EmployeesList
      buttonProps={{
        add: true,
        link: true,
      }}
      employees={visibleEmployees}
      onReorderEmployees={reorderEmployees}
      onDeleteEmployee={handleClickDelete}
      onAddNewTeamMember={onAddNewTeamMember}
      onEditTeamMember={onEditTeamMember}
      companyId={companyData.id}
    />
  )
}

TeamMembers.propTypes = {
  companyData: PropTypes.object,
  employees: PropTypes.array,
  handleChangeEmployeeOrder: PropTypes.func.isRequired,
  onAddNewTeamMember: PropTypes.func,
  onEditTeamMember: PropTypes.func,
  onDeleteTeamMember: PropTypes.func,
  toggleHideTeamMember: PropTypes.func,
}

TeamMembers.defaultProps = {
  companyData: null,
  employees: [],
  onAddNewTeamMember: () => {},
  onEditTeamMember: () => {},
  onDeleteTeamMember: () => {},
  toggleHideTeamMember: () => {},
}

export default TeamMembers
