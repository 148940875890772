import { ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { PortfolioTypes } from 'utils/constants/portfolio'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

export const INVESTMENT_FILTER_TYPE = 'InvestmentFilter'
export const INVESTMENT_SUMMARY_TYPE = 'InvestmentSummary'
export const UNSAVED_FILTER_TYPE = 'UnsavedFilter'
export const All_FOLLOWED_HOLDINGS_TYPE = 'AllFollowedHoldings'

export const FILTER_TYPE_NOT_FOUND = 'no_filters_found'

export const ALL_INVESTMENT_OPTION_ID = 'all_investment_option'
export const UNSAVED_FILTER_OPTION_ID = 'unsaved_filter_option'
export const ALL_FOLLOWED_HOLDINGS_OPTION_ID = 'all_followed_holdings_option'

export type InvestmentNavigationOptionType =
  | PortfolioTypes
  | typeof INVESTMENT_FILTER_TYPE
  | typeof INVESTMENT_SUMMARY_TYPE
  | typeof UNSAVED_FILTER_TYPE
  | typeof All_FOLLOWED_HOLDINGS_TYPE

export interface InvestmentsNavigationOption {
  id: string
  label: ReactElement | string
  showOption: boolean
  nonSelectedIcon: IconProp
  selectedIcon: IconProp
  isSelected: boolean
  type: InvestmentNavigationOptionType
  url: string
  isPinnable: boolean
  pinned: boolean
}

export const allInvestmentOption: InvestmentsNavigationOption = {
  id: ALL_INVESTMENT_OPTION_ID,
  label: <FormattedMessage id="investments.filters.allInvestments" />,
  showOption: true,
  nonSelectedIcon: ['far', 'briefcase'],
  selectedIcon: ['fas', 'check'],
  isSelected: false,
  type: INVESTMENT_SUMMARY_TYPE,
  url: '/investments',
  isPinnable: false,
  pinned: false,
}

export const allFollowedHoldings: InvestmentsNavigationOption = {
  id: ALL_FOLLOWED_HOLDINGS_OPTION_ID,
  label: 'All Followed Holdings',
  showOption: true,
  nonSelectedIcon: ['far', 'binoculars'],
  selectedIcon: ['fas', 'check'],
  isSelected: false,
  type: All_FOLLOWED_HOLDINGS_TYPE,
  url: '/investments/all-followed-holdings',
  isPinnable: false,
  pinned: false,
}

export const unSavedFilterOption: InvestmentsNavigationOption = {
  id: UNSAVED_FILTER_OPTION_ID,
  label: (
    <FormattedMessage id="investments.filters.filterTypes.unsavedFilter" />
  ),
  showOption: true,
  nonSelectedIcon: ['far', 'filter'],
  selectedIcon: ['fas', 'check'],
  isSelected: false,
  url: '/investments/filters',
  type: UNSAVED_FILTER_TYPE,
  isPinnable: false,
  pinned: false,
}

export const portfolioTypesMap = {
  [PortfolioTypes.TRACK]: 'investments.filters.portfolioTypes.pipelines',
  [PortfolioTypes.INVEST]:
    'investments.filters.portfolioTypes.investPortfolios',
  [PortfolioTypes.FUND]: 'investments.filters.portfolioTypes.funds',
  [PortfolioTypes.DEAL]: 'investments.filters.portfolioTypes.deals',
}

export type FilterTypeReturn =
  | typeof INVESTMENT_FILTER_TYPE
  | typeof FILTER_TYPE_NOT_FOUND

export type EntityId =
  | PortfolioTypes
  | typeof INVESTMENT_FILTER_TYPE
  | typeof UNSAVED_FILTER_TYPE
  | typeof FILTER_TYPE_NOT_FOUND
  | typeof All_FOLLOWED_HOLDINGS_TYPE

export interface BaseOption {
  id: EntityId
  label: ReactElement | string
  showOption: boolean
  status?: boolean
  isPinnable?: boolean
}

const PORTFOLIO_TYPE_FILTER_OPTIONS: BaseOption[] = Object.values(
  PortfolioTypes
).map((type) => ({
  id: type,
  label: <FormattedMessage id={portfolioTypesMap[type]} />,
  status: true,
  showOption: true,
}))

const FILTER_TYPE_FILTER_OPTION: BaseOption = {
  id: INVESTMENT_FILTER_TYPE,
  label: <FormattedMessage id="investments.filters.filterTypes.savedFilters" />,
  status: true,
  showOption: true,
}

export const ALL_ENTITIES_FILTER_OPTION: BaseOption[] = [
  ...PORTFOLIO_TYPE_FILTER_OPTIONS,
  FILTER_TYPE_FILTER_OPTION,
]
