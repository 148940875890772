import styled from 'styled-components/macro'
import { ZeroState as ZeroStateToStyle } from 'components/ZeroStateV2'
import { Container as ContainerToStyle } from '../ZeroStates.styles'

export const Container = styled(ContainerToStyle)`
  justify-content: center;
  height: 100%;
`

export const ZeroState = styled(ZeroStateToStyle)<{
  type?: any
  hideText?: boolean
  hideTitle?: boolean
}>`
  padding: 0;
  margin: 0;
`
