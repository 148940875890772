import { useRef, useState } from 'react'

interface Props {
  minHeight?: number
  maxHeight?: number
}

let initialHeight = 0
let initialMouseY = 0

export const useResizableComponent = ({ minHeight, maxHeight }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(300)

  const handleResize = (event: MouseEvent) => {
    const newHeight = initialHeight + (event.clientY - initialMouseY)
    if (
      (minHeight && newHeight < minHeight) ||
      (maxHeight && newHeight > maxHeight)
    ) {
      return
    }

    setHeight(newHeight)
  }

  const handleMouseUp = () => {
    window.removeEventListener('mousemove', handleResize)
    window.removeEventListener('mouseup', handleMouseUp)
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    if (ref.current) {
      initialHeight = ref.current.getBoundingClientRect().height
      initialMouseY = event.clientY

      window.addEventListener('mousemove', handleResize)
      window.addEventListener('mouseup', handleMouseUp)
    }
  }

  return {
    height,
    ref,
    handleMouseDown,
  }
}
