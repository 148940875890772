import { useCallback, useMemo } from 'react'

import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { SuggestedUpdateTypes } from 'utils/constants/updates'
import { SuggestedUpdateForm } from 'utils/types/suggestedUpdate'
import { getCurrentGroupData } from 'selectors/auth'
import { CreateUpdateSectionsMapper } from './createUpdateRules'

export const useCurrentGroupRules = () => {
  const currentGroup = useAppSelector(getCurrentGroupData)

  const currentGroupRules = useMemo(
    () => (currentGroup ? CreateUpdateSectionsMapper[currentGroup.type] : {}),
    [currentGroup]
  )

  const isUpdateFromRequired = useCallback(
    (values: SuggestedUpdateForm) => {
      const currentGroupAndTypeRules =
        values.type && values.type !== SuggestedUpdateTypes.SUGGESTED
          ? currentGroupRules[values.type] ?? {}
          : {}

      return !!(
        currentGroupAndTypeRules.areHoldingsVisible ||
        currentGroupAndTypeRules.isOwnOrganizationVisible ||
        currentGroupAndTypeRules.selectablePortfolios?.length
      )
    },
    [currentGroupRules]
  )

  return {
    currentGroupRules,
    currentGroup,
    isUpdateFromRequired,
  }
}
