import React, { Children, isValidElement, useEffect } from 'react'
import { Helmet as HelmetLib } from 'react-helmet-async'

import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUnreadMessagesCount } from 'features/chatSlice'

interface HelmetProps {
  children: React.ReactNode
}

export const Helmet = ({ children }: HelmetProps) => {
  const unreadMessagesCount = useAppSelector(getUnreadMessagesCount)

  const renderTitle = () => {
    const titleTag = Children.toArray(children).find(
      (child: any) => child.type === 'title'
    )

    if (isValidElement(titleTag)) {
      const title = titleTag.props.children
      const unreadMessagesTitle = `(${unreadMessagesCount})`

      if (typeof title === 'string' && unreadMessagesCount > 0) {
        return (
          <>
            <title>
              {unreadMessagesTitle} {title}
            </title>
            {Children.toArray(children).filter(
              (child: any) => child.type !== 'title'
            )}
          </>
        )
      }
    }

    return children
  }

  useEffect(() => {
    const favicon = document.getElementById('favicon')!
    if (unreadMessagesCount > 0) {
      favicon.setAttribute('href', '/favicon-red-dot.ico')
    } else {
      favicon.setAttribute('href', '/favicon.ico')
    }
  }, [unreadMessagesCount])

  return <HelmetLib>{renderTitle()}</HelmetLib>
}
