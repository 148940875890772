import { Dispatch, SetStateAction } from 'react'
import type { Country } from 'react-phone-number-input'
import labels from 'react-phone-number-input/locale/en.json'

import { useCountryInput } from 'ui/CountryInput'

import {
  ChevronDown,
  CountryDropdown,
  EmptyCircle,
  Flag,
  FlagContainer,
} from './CountrySelector.styles'

interface Props {
  value?: Country
  name: string
  onChange: Dispatch<SetStateAction<Country | undefined>>
}

const CountrySelector = ({ value, name, onChange }: Props) => {
  const {
    countryDropdownRef,
    showDropdown,
    selectOption,
    toggleDropdown,
    large,
    hasError,
  } = useCountryInput({ onChange, name })

  return (
    <>
      <FlagContainer large={large} onMouseDown={toggleDropdown}>
        {value ? (
          <Flag
            large={large}
            alt={labels[value] || labels.ZZ}
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value}.svg`}
          />
        ) : (
          <EmptyCircle large={large} />
        )}
        <ChevronDown icon={['far', 'chevron-down']} large={large} />
      </FlagContainer>

      <CountryDropdown
        countryDropdownRef={countryDropdownRef}
        showDropdown={showDropdown}
        selectOption={selectOption}
        showCountryCallingCode
        hasError={hasError}
      />
    </>
  )
}

export default CountrySelector
