import { useAuth0 } from '@auth0/auth0-react'
import { setAuth0AccessToken, setAuth0IdToken } from 'features/authSlice'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthentication } from 'utils/hooks/auth'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'

const RefreshAuth0Tokens = ({ children }) => {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const { isAuthenticated } = useAuthentication()
  const dispatch = useAppDispatch()
  const [refreshingTokens, setRefreshingTokens] = useState(isAuthenticated)
  const history = useHistory()

  useEffect(() => {
    const refreshTokens = async () => {
      try {
        const accessToken = await getAccessTokenSilently()
        const claims = await getIdTokenClaims()
        await dispatch(setAuth0IdToken(claims?.__raw!))
        await dispatch(setAuth0AccessToken(accessToken))
      } catch (e) {
        history.replace('/sign-out')
      }
      setRefreshingTokens(false)
    }

    if (isAuthenticated) {
      refreshTokens()
    } else {
      setRefreshingTokens(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (refreshingTokens) return null

  return children
}

export default RefreshAuth0Tokens
