import React from 'react'
import { useIntl } from 'react-intl'
import type { Compatible } from '@silevis/reactgrid'
import { CustomNumberCell } from 'components/Spreadsheet/types'
import { useRandomId } from 'utils/hooks/useRandomId'
import theme from 'utils/theme'
import * as Styles from '../Template.styles'

interface NumberCellDisplayValueProps {
  cell: Compatible<CustomNumberCell>
}

const NumberCellDisplayValue: React.FC<NumberCellDisplayValueProps> = ({
  cell,
}) => {
  const id = useRandomId()
  const intl = useIntl()

  return (
    <Styles.FullCellTooltip
      id={id}
      text={cell.error ? intl.formatMessage({ id: cell.error }) : null}
      place="bottom"
      backgroundColor={theme.colors.red}
      offset={2}
    >
      <Styles.NumberCellValue hasError={!!cell.error} centered={cell.centered}>
        {cell.text}
        {cell.isPercentage && '%'}
      </Styles.NumberCellValue>
    </Styles.FullCellTooltip>
  )
}

export default NumberCellDisplayValue
