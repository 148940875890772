import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import className from 'classnames'

import Card from 'components/Card'
import Tabs from 'components/Tabs/Tabs'

import styles from './UpdateLayout.module.scss'

const UpdateTabs = ({ children, card, tabs }) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(true)

  const tabsToDisplay = tabs || [
    {
      id: 'logs',
      title: intl.messages['logs.title'],
      icon: ['fal', 'clipboard-list-check'],
    },
  ]

  const tabComponent = (
    <Tabs items={tabsToDisplay}>
      {(params) => children({ ...params, setLoading })}
    </Tabs>
  )

  if (card) {
    return (
      <Card
        className={className(styles.updateTabs, { [styles.hidden]: loading })}
      >
        <Card.Body>{tabComponent}</Card.Body>
      </Card>
    )
  }

  return tabComponent
}

UpdateTabs.propTypes = {
  children: PropTypes.func.isRequired,
  card: PropTypes.bool,
  tabs: PropTypes.array,
}

UpdateTabs.defaultProps = {
  card: true,
  tabs: null,
}

export default UpdateTabs
