import { ReactNode } from 'react'
import { Nullable, UpdateType } from 'utils/types/common'
import { color } from 'utils/functions/colors'
import { UpdateVisibility } from 'utils/constants/updates'
import { LogsBuilder } from './LogsBuilder'
import { EventType, Log, LogTextBuilder } from '../types'
import { UpdateLogTextBuilder } from '../text-builders/UpdateLogTextBuilder'
import LogIcon from '../../LogIcon'
import UpdateDescriptionLog from '../../UpdateDescriptionLog/UpdateDescriptionLog'

export type UpdateLogChangeSetFields = {
  title: string
  text: string
  date: string
  visibility: string
  groupableId: string
  userId: string
  ownedByGroup: string
  file: string
  scheduleDate: string
  repetition: string

  amountCommitted: number
  amountInvested: number
  amountDistributed: number
  transactionType: string
  instrumentType: string
  portfolioName: string
  valuationCap: string
  discountRate: string
  discountRatePlaceholder: string
  interestCalculationBasis: string
  maturityDate: string
  purchasePricePerShare: string
  sharesPurchased: string
  preMoneyValuation: string
  postMoneyValuation: string
  annualManagementFee: string
  carry: string
  carryHurdleRate: string
  dividend: string
  dividendCalculationBasis: string
  dividendType: string
  cumulative: string
  noncumulative: string
  warrants: string
  vestingCommencementDate: string
  expirationDate: string
  strikePrice: string
  numberOfShares: string
  contentId: string
}

export interface UpdateLogParams {
  updateTitle: string
}

export class UpdateLogsBuilder extends LogsBuilder<UpdateLogChangeSetFields> {
  private entityName: string

  private updateLogTextBuilder: UpdateLogTextBuilder

  public updateType: UpdateType

  public updateTitle: string

  constructor(
    log: Log<UpdateLogChangeSetFields>,
    entityName: string,
    updateType: UpdateType,
    params?: UpdateLogParams
  ) {
    super(log)
    this.entityName = entityName
    this.updateTitle = params?.updateTitle || ''
    this.updateType = updateType
    this.updateLogTextBuilder = new UpdateLogTextBuilder(
      this.entityName,
      updateType,
      true
    )
  }

  protected getTextBuilderForField(): Nullable<LogTextBuilder> {
    if (
      [
        'title',
        'date',
        'text',
        'visibility',
        'groupableId',
        'userId',
        'ownedByGroup',
        'file',
        'tagId',
        'scheduleDate',
        'repetition',
        'amountCommitted',
        'amountDistributed',
        'amountInvested',
        'transactionType',
        'instrumentType',
        'portfolioName',

        'valuationCap',
        'discountRate',
        'discountRatePlaceholder',
        'interestCalculationBasis',
        'maturityDate',
        'purchasePricePerShare',
        'sharesPurchased',
        'preMoneyValuation',
        'postMoneyValuation',
        'annualManagementFee',
        'carry',
        'carryHurdleRate',
        'dividend',
        'dividendCalculationBasis',
        'dividendType',
        'cumulative',
        'noncumulative',
        'warrants',
        'vestingCommencementDate',
        'expirationDate',
        'strikePrice',
        'numberOfShares',
        'contentId',
      ].includes(this.log.fieldName)
    ) {
      return this.updateLogTextBuilder
    }
    return null
  }

  public static getShareIcon(): React.ReactNode {
    return <LogIcon color={color('green')} icon={['far', 'share']} />
  }

  public static getUnshareIcon(): React.ReactNode {
    return <LogIcon color={color('red')} icon={['far', 'share']} />
  }

  public static getAddedAttachmentIcon(): React.ReactNode {
    return <LogIcon color={color('green')} icon={['far', 'paperclip']} />
  }

  public static getRemovedAttachmentIcon(): React.ReactNode {
    return <LogIcon color={color('red')} icon={['far', 'paperclip']} />
  }

  public static getAddedTagIcon(): React.ReactNode {
    return <LogIcon color={color('green')} icon={['far', 'tag']} small />
  }

  public static getRemovedTagIcon(): React.ReactNode {
    return <LogIcon color={color('red')} icon={['far', 'tag']} small />
  }

  public static getScheduleUpdateIcon(): React.ReactNode {
    return <LogIcon color={color('yellow')} icon={['far', 'calendar-day']} />
  }

  public static getLockIcon(): React.ReactNode {
    return (
      <LogIcon color={color('brightBlue')} icon={['far', 'user-lock']} small />
    )
  }

  public static getUnlockIcon(): React.ReactNode {
    return <LogIcon color={color('red')} icon={['far', 'user-lock']} small />
  }

  public static getPublicUpdateIcon(): React.ReactNode {
    return <LogIcon color={color('brightBlue')} icon={['far', 'users']} small />
  }

  public static getOnlyYouIcon(): React.ReactNode {
    return <LogIcon color={color('brightBlue')} icon={['far', 'user']} small />
  }

  public getOperationIcon(): ReactNode {
    switch (this.log.event) {
      case EventType.ADD_GROUP:
      case EventType.ADD_USER:
        return UpdateLogsBuilder.getShareIcon()
      case EventType.REMOVE_GROUP:
      case EventType.REMOVE_USER:
        return UpdateLogsBuilder.getUnshareIcon()
      case EventType.ADD_ATTACHMENT:
        return UpdateLogsBuilder.getAddedAttachmentIcon()
      case EventType.REMOVE_ATTACHMENT:
        return UpdateLogsBuilder.getRemovedAttachmentIcon()
      case EventType.ADD_TAG:
        return UpdateLogsBuilder.getAddedTagIcon()
      case EventType.REMOVE_TAG:
        return UpdateLogsBuilder.getRemovedTagIcon()
      case EventType.ADD_SCHEDULE:
        return UpdateLogsBuilder.getScheduleUpdateIcon()
      case EventType.ADD_TO_PORTFOLIO:
        return LogsBuilder.getUpdateIcon()
      default:
        if (this.log.event === EventType.UPDATE) {
          if (this.log.fieldName === 'visibility') {
            if (this.log.change[1] === UpdateVisibility.PUBLIC)
              return UpdateLogsBuilder.getPublicUpdateIcon()
            if (this.log.change[1] === UpdateVisibility.ONLY_ME)
              return UpdateLogsBuilder.getOnlyYouIcon()
          }
        }

        if (this.log.fieldName === 'ownedByGroup') {
          if (!this.log.change[0] && this.log.change[1]) {
            return UpdateLogsBuilder.getLockIcon()
          }
          return UpdateLogsBuilder.getUnlockIcon()
        }
        if (this.log.fieldName === 'scheduleDate') {
          return UpdateLogsBuilder.getScheduleUpdateIcon()
        }

        if (this.log.fieldName === 'text') {
          return LogsBuilder.getUpdateIcon()
        }

        return super.getOperationIcon()
    }
  }

  public getText(): ReactNode {
    if (this.log.fieldName === 'text') {
      return this.getUpdateDescriptionLog()
    }

    return super.getText()
  }

  private getUpdateDescriptionLog(): ReactNode {
    return <UpdateDescriptionLog logBuilder={this} />
  }
}
