import React from 'react'

import { useCompanyQuery } from 'utils/hooks/queries/useCompanyQuery'
import {
  buildHoldingTopicEntity,
  TopicEntityType,
} from 'utils/types/chatTopicEntity'

import { useChatMembers } from 'utils/hooks/useChatMembers'
import { useQuerySimilarChannel } from 'containers/Chat/hooks/useQuerySimilarChannel'
import { ChannelType } from 'containers/Chat/types'
import CreateNewChatSuggestionBox from './CreateNewChatSuggestionBox'
import ExistentChatSuggestion from './ExistentChatSuggestion'

interface CreateNewCompanyChatSuggestionProps {
  companyId: string
}

const CreateNewCompanyChatSuggestion: React.FC<
  CreateNewCompanyChatSuggestionProps
> = ({ companyId }) => {
  const { data: company } = useCompanyQuery(companyId, { staleTime: 500 })
  const { members } = useChatMembers()
  const { data: similarChannel, isLoading: isLoadingSimilarChannel } =
    useQuerySimilarChannel({
      entityId: companyId,
      entityType: TopicEntityType.COMPANY_HOLDING,
      channelType: ChannelType.HOLDING,
      memberIds: members.map((member) => member.user_id!),
    })

  if (isLoadingSimilarChannel) {
    return null
  }

  if (similarChannel) {
    return (
      <ExistentChatSuggestion
        topicEntity={company ? buildHoldingTopicEntity(company) : undefined}
        existentChannel={similarChannel!}
      />
    )
  }

  return (
    <CreateNewChatSuggestionBox
      topicEntity={company ? buildHoldingTopicEntity(company) : undefined}
    />
  )
}

export default CreateNewCompanyChatSuggestion
