import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Button from 'ui/Button'
import {
  CompanyTransactionsZeroStateContainer,
  CompanyTransactionsZeroStateTitle,
} from './CompanyTransactionsZeroState.styles'

const CompanyTransactionsZeroState = ({ onAddTransaction }) => {
  return (
    <CompanyTransactionsZeroStateContainer>
      <CompanyTransactionsZeroStateTitle>
        <FormattedMessage id="portfolioDetail.noTransactions" />
      </CompanyTransactionsZeroStateTitle>
      {onAddTransaction && (
        <Button onClick={onAddTransaction}>
          <FormattedMessage id="portfolioDetail.addTransaction" />
        </Button>
      )}
    </CompanyTransactionsZeroStateContainer>
  )
}

CompanyTransactionsZeroState.propTypes = {
  onAddTransaction: PropTypes.func,
}

CompanyTransactionsZeroState.defaultProps = {}

export default CompanyTransactionsZeroState
