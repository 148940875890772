import { useIntl } from 'react-intl'
import { useFormikContext, useFieldName } from 'components/Form/hooks'
import type { ArrayHelpers } from 'formik'
import Title from 'components/Title'
import Toggle from 'components/Toggle'
import FadeIn from 'components/FadeIn/FadeIn'
import Separator from 'ui/Separator'
import CustomTextArea from 'ui/TextArea/CustomTextArea'
import theme from 'utils/theme'
import { EMAIL_REGEX } from 'utils/functions/regex'
import { AddInvestorSendInvitesFormValues } from 'utils/types/investors'
import UserService from 'api/UserService'
import { User } from 'utils/types/user'
import EmailsList from '../components/Emails/EmailsList'
import { SelectorWrapper, StandardWrapper } from './InvestorForm.styles'

interface Props {
  onSendInvitation?: () => void
}

const AddInvestorSendInvitesForm = ({ onSendInvitation }: Props) => {
  const intl = useIntl()
  const {
    setFieldValue,
    setFieldError,
    values,
    handleChange,
    errors,
    getFieldName,
  } = useFormikContext<AddInvestorSendInvitesFormValues>()
  const usersFieldName = useFieldName('users')

  const handleAddEmail = async (
    arrayHelpers: ArrayHelpers,
    newEmail: string
  ) => {
    let fieldError = ''
    if (values.users.some((user) => user.email === newEmail)) {
      fieldError = intl.formatMessage({
        id: 'investorManagement.duplicatedEmailError',
      })
    } else if (!EMAIL_REGEX.test(newEmail)) {
      fieldError = intl.formatMessage({
        id: 'investorManagement.emailFormatError',
      })
    } else {
      const {
        data: { entities, result },
      } = await UserService.createUser({ email: newEmail })
      const user = entities?.users?.[result] as User
      arrayHelpers.push(user)
    }
    setFieldError('users', fieldError)
  }
  return (
    <StandardWrapper marginTop="3rem">
      <Toggle
        id="sendInvitation"
        name={getFieldName('sendInvitation')}
        legend={intl.formatMessage({
          id: 'investorManagement.sendInvitationToJoin',
        })}
        status={values.sendInvitation}
        blue
        onChangeStatus={(status) => {
          setFieldValue('sendInvitation', status)
          if (status) {
            onSendInvitation?.()
          }
        }}
      />

      {values.sendInvitation && (
        <FadeIn>
          <Separator space="2.4rem" />
          <Title
            title={intl.formatMessage({
              id: 'investorManagement.inviteGroupMembers',
            })}
            color={theme.colors.lightGray}
          />

          <Separator />

          <SelectorWrapper>
            <EmailsList<User>
              name={usersFieldName}
              label=""
              handleAddEmail={handleAddEmail}
              error={errors?.users as string}
              items={values.users}
              getRowLabel={(user) => user.name || user.email}
              mainAndSetAsMainActive={false}
              onDelete={(user) => {
                setFieldValue('removedUsers', [
                  ...(values.removedUsers ?? []),
                  user,
                ])
              }}
            />
          </SelectorWrapper>

          <Separator space="2.4rem" />
          <Title
            title={intl.formatMessage({
              id: 'investorManagement.invitationMessage',
            })}
            subLabel={intl.formatMessage({ id: 'general.optional' })}
            color={theme.colors.lightGray}
          />
          <Separator />

          <SelectorWrapper>
            <CustomTextArea
              id="message"
              name="message"
              value={values.message}
              onChange={handleChange}
              placeholder={intl.formatMessage({
                id: 'investorManagement.addInvitationMessage',
              })}
              minHeight="12rem"
              isOptional
            />
          </SelectorWrapper>
        </FadeIn>
      )}
    </StandardWrapper>
  )
}

export default AddInvestorSendInvitesForm
