import styled, { css } from 'styled-components'
import { size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

interface IconLegendWrapperProps {
  bold: boolean
  link: boolean
  disabled?: boolean
}

export const IconLegendWrapper = styled.div<IconLegendWrapperProps>`
  align-items: center;
  color: var(--color-gray-600);
  display: flex;
  font-size: 1.6rem;
  color: ${color('lightGray')};
  white-space: nowrap;

  > span {
    font-size: 1.4rem;
    line-height: 1.7rem;
    margin-left: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 10rem;

    @media ${size.md} {
      max-width: 35rem;
    }

    @media ${size.lg} {
      max-width: 31rem;
    }

    @media ${size.tabletLg} {
      max-width: 10rem;
    }

    @media ${size.xl} {
      max-width: 23rem;
    }

    @media ${size.desktopLg} {
      max-width: 34rem;
    }

    @media ${size.xxl} {
      max-width: 42rem;
    }
  }

  > svg {
    color: ${color('lightGray')};
  }

  ${(props) =>
    props.bold &&
    css`
      color: ${color('darkBlue')};
      > span,
      svg {
        color: ${color('darkBlue')};
        font-weight: 700;
      }
    `};

  ${(props) =>
    props.link &&
    !props.disabled &&
    css`
      &:hover {
        > span,
        svg {
          color: ${color('primaryBlue')};
          text-decoration: underline;
        }
      }
    `}
`
