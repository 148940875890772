import { FormattedMessage } from 'react-intl'
import { assertUnreachable } from 'utils/functions/utils'
import { Color } from 'utils/theme'
import { Label } from './CardLabel.styles'

type LabelType = 'draft' | 'suggested' | 'scheduled'

interface CardLabelProps {
  labelType: LabelType
}

const getLabelProps = (
  labelType: LabelType
): {
  textId: string
  color: Color
} => {
  switch (labelType) {
    case 'draft':
      return {
        textId: 'updates.draft',
        color: 'draftGray',
      }
    case 'scheduled':
      return {
        textId: 'updates.scheduledUpdate',
        color: 'draftGray',
      }
    case 'suggested':
      return {
        textId: 'updates.suggested',
        color: 'purple',
      }
    default:
      return assertUnreachable(labelType)
  }
}

const CardLabel = ({ labelType }: CardLabelProps) => {
  const { textId, color } = getLabelProps(labelType)
  return (
    <Label color={color}>
      <FormattedMessage id={textId} />
    </Label>
  )
}

export default CardLabel
