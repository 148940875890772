/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import TagService from 'api/TagService'
import Toast from 'components/Toast'

export default (fetchTags = true) => {
  const [tags, setTags] = useState([])
  const intl = useIntl()

  useEffect(() => {
    ;(async () => {
      if (fetchTags) {
        try {
          const {
            data: { entities },
          } = await TagService.getTags()

          if (entities.tags) {
            setTags(Object.values(entities.tags))
          }
        } catch (error) {
          Toast.display(intl.messages['tags.getTagsByGroupError'], 'error')
        }
      }
    })()
  }, [intl.messages])

  return tags
}
