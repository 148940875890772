import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import CWLoader from 'components/CWLoader'
import AccountSetupLayout from 'layouts/AccountSetupLayout'
import { getDesktopProtocol, getWebAppUrl } from 'utils/functions/env'
import Separator from 'ui/Separator'
import useQueryString from 'utils/hooks/useQueryString'
import * as Styles from './DesktopRedirect.styles'

const DesktopRedirect = () => {
  const code = useQueryString('code')
  const state = useQueryString('state')
  const isAlreadyRedirectedRef = useRef(false)

  const openInBrowser = () => {
    window.location.href = getWebAppUrl()
  }

  useEffect(() => {
    if (code && state && !isAlreadyRedirectedRef.current) {
      isAlreadyRedirectedRef.current = true
      const url = `${getDesktopProtocol()}://desktop-redirect?code=${code}&state=${encodeURIComponent(
        state!
      )}`

      window.location.href = url
    }
  }, [code, state])

  return (
    <Styles.Container>
      <AccountSetupLayout.Header />
      <Separator height="19.6rem" />

      <CWLoader svgSize={70} />

      <Styles.Launching>
        <FormattedMessage id="login.launchingClockwork" />
      </Styles.Launching>
      <Styles.Line>
        <FormattedMessage
          id="login.clickOpenClockwork"
          values={{
            b: (txt) => <b>{txt}</b>,
          }}
        />
      </Styles.Line>
      <Styles.Line2>
        <FormattedMessage
          id="login.notWorking"
          values={{
            link: (txt) => (
              <Styles.Link link onClick={openInBrowser}>
                {txt}
              </Styles.Link>
            ),
          }}
        />
      </Styles.Line2>
    </Styles.Container>
  )
}

export default DesktopRedirect
