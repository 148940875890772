import { IntlShape } from 'react-intl/src/types'
import { PortfolioTypes } from 'utils/constants/portfolio'

export const ALL_FUNDS = 'ALL_FUNDS'

export const getAllFundsOption = (intl: IntlShape) => ({
  label: intl.formatMessage({
    id: 'general.allFunds',
  }),
  name: intl.formatMessage({
    id: 'general.allFunds',
  }),
  id: ALL_FUNDS,
  type: PortfolioTypes.FUND,
})
