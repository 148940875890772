import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './GrowthIndicator.module.scss'

const GrowthIndicator = ({ direction, bold, padding }) => {
  return (
    <span
      className={classNames(styles.growthIndicator, {
        [styles.up]: direction === 'up',
        [styles.down]: direction === 'down',
        [styles.bold]: bold,
        [styles.padding]: padding,
      })}
      style={{ padding }}
    />
  )
}

GrowthIndicator.propTypes = {
  direction: PropTypes.oneOf(['up', 'down']).isRequired,
  bold: PropTypes.bool,
  padding: PropTypes.string,
}
GrowthIndicator.defaultProps = {
  bold: false,
  padding: '',
}

export default GrowthIndicator
