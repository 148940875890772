/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { CustomUseQueryOptions } from 'utils/types/common'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import InvestorManagementService, {
  Filters,
} from 'api/InvestorManagementService'
import Toast from 'components/Toast'
import {
  IndexInvestmentVehicle,
  InvestmentVehicle,
  InvestmentVehiclePayload,
} from 'utils/types/investors'
import { investmentVehiclesKeys } from 'utils/queries/investmentVehicles'
import useInfiniteScroll from '../useInfiniteScroll'

export type InvestmentVehicleFilters = Omit<Filters, 'page'>

/**
 * Infinite query to fetch investment vehicles by page
 *
 * @param filters - Filters to send to the API. Check `InvestmentVehicleFilters` type.
 * @returns An instance of react-query
 *
 */
export const useInvestmentVehiclesInfiniteQuery = (
  filters: InvestmentVehicleFilters,
  queryOptions: UseInfiniteQueryOptions<{
    investmentVehicles: InvestmentVehicle[]
    page: number
  }> = {}
) => {
  const {
    ref: infiniteScrollRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()
  const [total, setTotal] = useState<number>()

  const query = useInfiniteQuery(
    investmentVehiclesKeys.allWithFilters(filters),
    async ({ pageParam = 1 }) => {
      try {
        const { results: investmentVehicles, total: totalVehicles } =
          await InvestorManagementService.fetchInvestmentVehicles({
            ...filters,
            page: pageParam,
          })

        if (investmentVehicles.length === 0) {
          setInfiniteScrollEnabled(false)
        }
        setTotal(totalVehicles)

        return {
          investmentVehicles,
          page: pageParam,
        }
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchInvestmentVehicles',
          'error'
        )
        return {
          investmentVehicles: [],
          page: 1,
        }
      }
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1
      },
      ...queryOptions,
    }
  )

  useEffect(() => {
    if (onScreen) {
      query.fetchNextPage()
    }
  }, [onScreen])

  return {
    ...query,
    total,
    infiniteScrollRef,
  }
}

/**
 * Query to fetch investment vehicles by page
 *
 * @param filters - Filters to send to the API. Check `InvestmentVehicleFilters` type.
 * @returns An instance of react-query
 *
 */
export const useInvestmentVehiclesQuery = (
  filters: InvestmentVehicleFilters,
  queryOptions = {}
) => {
  return useQuery(
    investmentVehiclesKeys.allWithFilters(filters),
    async () => {
      try {
        return await InvestorManagementService.fetchInvestmentVehicles({
          page: 1,
        })
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchInvestmentVehicles',
          'error'
        )
        throw err
      }
    },
    { ...queryOptions, staleTime: 500 }
  )
}

/**
 * Query to fetch investment vehicles by id
 *
 * @param investmentVehicleId - The id of the investment vehicle
 * @returns An instance of react-query
 *
 */
export const useInvestmentVehicleQuery = (
  investmentVehicleId: string,
  queryOptions: CustomUseQueryOptions<InvestmentVehicle> = {}
) => {
  return useQuery(
    investmentVehiclesKeys.byId(investmentVehicleId),
    async () => {
      try {
        return await InvestorManagementService.fetchInvestmentVehicle(
          investmentVehicleId
        )
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchInvestmentVehicle',
          'error'
        )
        throw err
      }
    },
    { ...queryOptions, staleTime: 500 }
  )
}

/**
 * Mutation to create an investment vehicle
 *
 * @returns An instance of useMutation
 *
 */
export const useCreateInvestmentVehicleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: InvestmentVehiclePayload) => {
      return InvestorManagementService.createInvestmentVehicle(payload)
    },
    {
      onSuccess: (newVehicle) => {
        queryClient.invalidateQueries(investmentVehiclesKeys.all())
        queryClient.setQueryData(
          investmentVehiclesKeys.byId(newVehicle.id),
          newVehicle
        )
      },
    }
  )
}

/**
 * Mutation to update an investment vehicle
 *
 * @param investmentVehicleId - The id of the investment vehicle
 * @returns An instance of useMutation
 *
 */
export const useEditInvestmentVehicleMutation = (
  investmentVehicleId: string
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: InvestmentVehiclePayload) => {
      return InvestorManagementService.editInvestmentVehicle(
        investmentVehicleId,
        payload
      )
    },
    {
      onSuccess: (newInvestmentVehicle) => {
        queryClient.refetchQueries(investmentVehiclesKeys.all())
        queryClient.setQueryData(
          investmentVehiclesKeys.byId(newInvestmentVehicle.id),
          newInvestmentVehicle
        )
      },
    }
  )
}

export const useDeleteInvestmentVehiclesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(InvestorManagementService.deleteInvestmentVehicle, {
    onSuccess: () =>
      queryClient.invalidateQueries(investmentVehiclesKeys.all()),
  })
}

/**
 * Query to fetch all the investment vehicles that has transactions on a fund portfolio.
 * If the request is made by an Investor Group, the results will be scoped to his transactions.
 *
 * @param fundPortfolioId - The id of the fund portfolio
 * @returns An instance of react-query
 *
 */
export const useFundPortfolioInvestmentVehiclesQuery = (
  fundPortfolioId: string,
  queryOptions: CustomUseQueryOptions<IndexInvestmentVehicle[]> = {}
) => {
  return useQuery(
    investmentVehiclesKeys.fundPortfolioInvestmentVehicles(fundPortfolioId),
    async () => {
      try {
        const { results } =
          await InvestorManagementService.fetchInvestmentVehicles({
            fundPortfolioId: [fundPortfolioId],
            page: 1,
          })
        return results
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchInvestmentVehicles',
          'error'
        )
        return null
      }
    },
    queryOptions
  )
}
