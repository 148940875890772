import React from 'react'
import '@formatjs/intl-numberformat/locale-data/en'
import '@formatjs/intl-numberformat/polyfill'
import App from 'App'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import isoWeek from 'dayjs/plugin/isoWeek'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import { createRoot } from 'react-dom/client'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { store } from 'store'
import { updateServiceWorker } from './actions/serviceWorker'
import { register } from './registerServiceWorker'
import reportWebVitals from './reportWebVitals'

dayjs.extend(advancedFormat)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(quarterOfYear)
dayjs.extend(isoWeek)

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

register({
  onUpdate: (registration) => {
    store.dispatch(updateServiceWorker(registration))
  },
})
reportWebVitals()
