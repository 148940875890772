import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const ShowInChart = styled.div<{
  disabled: boolean
}>`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  color: ${color('darkGray')};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

export const ShowInChartLabel = styled.span<{
  isMetricVisibleInChart: boolean
}>`
  color: ${({ isMetricVisibleInChart }) =>
    isMetricVisibleInChart ? undefined : color('lightGray')};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
