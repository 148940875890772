import { FormattedMessage, useIntl } from 'react-intl'
import InputLabel from 'ui/InputLabel'
import { formatPercentage } from 'utils/functions/number'
import {
  getDividendTypeLabel,
  instrumentFieldIsDefined,
  instrumentHasData,
} from 'utils/functions/transactions'
import { CreateTransactionFormValues } from 'utils/types/updateForm'
import {
  Content,
  Row,
  TransactionContent,
} from '../ShowTransactionContent.styles'
import EquityInstrumentContent from './EquityInstrumentContent'

const PreferredEquityInstrumentContent = ({
  transaction,
}: {
  transaction: CreateTransactionFormValues
}) => {
  const intl = useIntl()

  if (
    !instrumentFieldIsDefined(transaction.description) &&
    !instrumentHasData(transaction.instrument!, transaction)
  ) {
    return null
  }

  return (
    <>
      <EquityInstrumentContent transaction={transaction} />
      <TransactionContent>
        {instrumentFieldIsDefined(transaction?.dividend) && (
          <Row>
            <InputLabel marginLeft="1.4rem">
              <FormattedMessage id="transactions.dividend" />
            </InputLabel>
            <Content capitalize>
              {formatPercentage(transaction!.dividend)}
            </Content>
          </Row>
        )}

        {instrumentFieldIsDefined(transaction?.dividendCalculationBasis) && (
          <Row>
            <InputLabel marginLeft="1.4rem">
              <FormattedMessage id="transactions.dividendCalculationBasis" />
            </InputLabel>
            <Content capitalize>
              {transaction?.dividendCalculationBasis}
            </Content>
          </Row>
        )}

        {instrumentFieldIsDefined(transaction?.dividendType) && (
          <Row>
            <InputLabel marginLeft="1.4rem">
              <FormattedMessage id="transactions.dividendType" />
            </InputLabel>
            <Content>
              {getDividendTypeLabel(transaction!.dividendType!, intl)}
            </Content>
          </Row>
        )}
      </TransactionContent>
    </>
  )
}

export default PreferredEquityInstrumentContent
