import React from 'react'
import { chunk } from 'lodash'
import type { AxisDomain } from 'recharts/types/util/types'

import {
  AreaChart as REAreaChart,
  Area,
  YAxis,
  XAxis,
  Tooltip as RETooltip,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts'
import { displayThousands } from 'utils/functions/number'
import { Nullable, ReactChildren } from 'utils/types/common'
import ChartTooltip from '../ChartTooltip'
import {
  AreaChartWrapper,
  AxisItem,
  ChartContainer,
  CustomAxis,
} from './AreaChart.styles'

export type LegendItem = {
  x: string
  y: number
}
interface AreaChartProps {
  data: LegendItem[]
  fill?: string
  stroke?: string
  maxLegendItems: Nullable<number>
  lines?: {
    id: string
    value: number
  }[]
  showAxis?: boolean
  customAxis?: boolean
  height?: number
  children?: ReactChildren
  axisItemWidth?: string
  formatTooltipX?: Nullable<(values: LegendItem) => React.ReactNode>
  formatTooltipY?: Nullable<(values: LegendItem) => React.ReactNode>
  tooltipWidth?: string
  tooltipHeight?: string
  allowYDecimals?: boolean
  xAxisPadding?: {
    left: number
    right: number
  }
  yDomain?: AxisDomain
  dot?: { stroke: string; fill: string }
  connectNulls?: boolean
}

const AreaChart: React.FC<AreaChartProps> = ({
  data,
  fill = '#e8e5fc',
  stroke = '#c2baf6',
  maxLegendItems = Infinity,
  lines,
  showAxis = true,
  height = 85,
  children,
  axisItemWidth,
  customAxis = true,
  formatTooltipX = null,
  formatTooltipY = null,
  tooltipWidth = '',
  tooltipHeight = 'fit-content',
  allowYDecimals = true,
  xAxisPadding = {},
  yDomain,
  ...props
}) => {
  let chunkLegends = false
  let legendItems: LegendItem[] | LegendItem[][] = data

  const chunkLength = Math.ceil(data.length / (maxLegendItems ?? 1))

  if (maxLegendItems !== null && data.length > maxLegendItems) {
    chunkLegends = true
    legendItems = chunk(data, chunkLength).filter(
      (chk) => chk.length === chunkLength
    )
  }

  return (
    <ChartContainer data-testid="area-chart">
      <AreaChartWrapper>
        <ResponsiveContainer width="100%" height={height}>
          <REAreaChart
            data={data}
            margin={{ top: 15, right: 0, bottom: 0, left: 0 }}
          >
            {children}
            {showAxis && (
              <YAxis
                dataKey="y"
                tickFormatter={displayThousands}
                tickLine={false}
                axisLine={false}
                tickCount={20}
                allowDecimals={allowYDecimals}
                domain={yDomain}
              />
            )}
            {showAxis && !customAxis && (
              <XAxis
                dataKey="x"
                tickLine={false}
                axisLine={false}
                padding={xAxisPadding}
              />
            )}
            <RETooltip
              offset={0}
              allowEscapeViewBox={{ x: true, y: true }}
              cursor={false}
              wrapperStyle={{
                outline: 'none',
              }}
              content={
                <ChartTooltip
                  formatX={formatTooltipX}
                  formatY={formatTooltipY}
                  width={tooltipWidth}
                  height={tooltipHeight}
                />
              }
            />
            <CartesianGrid stroke="#f5f5f5" vertical={false} />
            <Area
              type="monotone"
              dataKey="y"
              stroke={stroke}
              strokeWidth={2}
              fill={fill}
              fillOpacity={1}
              {...props}
            />
            {lines?.map(({ id, value }) => (
              <ReferenceLine
                key={`line_${id}`}
                y={value}
                stroke="#000"
                strokeDasharray="6 3"
                ifOverflow="extendDomain"
              />
            ))}
          </REAreaChart>
        </ResponsiveContainer>
      </AreaChartWrapper>
      {showAxis && customAxis && (
        <CustomAxis
          chunkLegends={chunkLegends}
          countItems={data.length}
          chunks={legendItems}
        >
          {legendItems.map((dataPointChunk) => {
            const dataPoint = chunkLegends
              ? dataPointChunk?.[0]
              : dataPointChunk
            return (
              <AxisItem axisItemWidth={axisItemWidth}>{dataPoint.x}</AxisItem>
            )
          })}
        </CustomAxis>
      )}
    </ChartContainer>
  )
}
export default React.memo(AreaChart)
