import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'

import Drawer from 'components/Drawer'
import AttachmentPreview from 'components/AttachmentPreview'
import PreviewContents from 'components/PreviewContents'
import Tooltip from 'components/Tooltip'
import IconButton from 'ui/IconButton'
import { usePreviewContents } from 'utils/hooks/files/usePreviewContents'
import UpdateText from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import { useTipTapShowMoreLess } from 'components/ShowMoreLess'

import { useEmailContentDetails } from './useEmailContentDetails'
import {
  ActionsSeparator,
  ActionsWrapper,
  AttachmentsContainer,
  Container,
  DetailsContainer,
  DetailsItem,
  DrawerContent,
  Email,
  SectionTitle,
  ShowMoreLessButton,
  TextContainer,
  UnreconciledPill,
  ShowMoreLess,
} from './EmailContentDetails.styles'
import GroupManaged from '../components/GroupManaged'
import Updates from '../FileDetails/Updates'

const EmailContentDetails = () => {
  const {
    emailContent,
    isLoading,
    contentsHandler,
    receiversHandler,
    onCloseDrawer,
  } = useEmailContentDetails()
  const {
    containerRef,
    isShowMoreNeeded,
    isShowingMore,
    toggleShowingMore,
    onEditorCreated,
  } = useTipTapShowMoreLess({
    updateText: emailContent?.data.text || '',
  })
  const { onClosePreview, onPreviewContent, selectedContent } =
    usePreviewContents()

  const creationDate = emailContent
    ? dayjs(emailContent.createdAt).format('D MMM. YYYY')
    : ''

  return (
    <Drawer
      isOpen
      isLoading={isLoading}
      onCloseDrawer={onCloseDrawer}
      headerTitle={emailContent?.data.title || 'Email Subject'}
    >
      <DrawerContent contentPadding="1.6rem 2.4rem">
        {emailContent && (
          <Container>
            {emailContent.unreconciled && <UnreconciledPill />}
            <TextContainer>
              <div ref={containerRef}>
                <UpdateText
                  key={emailContent.id}
                  text={emailContent.data.text}
                  maxLines={isShowingMore ? undefined : 12}
                  openLinksOnClick={false}
                  onCreate={onEditorCreated}
                />
              </div>
              <ShowMoreLess
                isShowMoreNeeded={isShowMoreNeeded}
                isShowingMore={isShowingMore}
                toggleShowingMore={toggleShowingMore}
              />
            </TextContainer>
            <ActionsWrapper>
              <IconButton
                onClick={() => onPreviewContent(emailContent)}
                iconFamily="fal"
                icon="eye"
              />
              <ActionsSeparator />
              <GroupManaged content={emailContent} />
            </ActionsWrapper>
            {!!contentsHandler.items.length && (
              <>
                <SectionTitle>
                  <FormattedMessage id="files.attachments" />
                </SectionTitle>
                <AttachmentsContainer>
                  {contentsHandler.items.map((attachment) => (
                    <AttachmentPreview
                      key={attachment.id}
                      content={attachment}
                      size="small"
                    />
                  ))}
                  {contentsHandler.isShowMoreButtonVisible && (
                    <ShowMoreLessButton
                      onClick={contentsHandler.toggleShowMore}
                    >
                      {contentsHandler.isShowingMore ? (
                        <FormattedMessage id="files.details.showLess" />
                      ) : (
                        <FormattedMessage
                          id="files.details.showMore"
                          values={{ count: contentsHandler.showMoreCount }}
                        />
                      )}
                    </ShowMoreLessButton>
                  )}
                </AttachmentsContainer>
              </>
            )}
            <SectionTitle>
              <FormattedMessage id="files.emailDetails" />
            </SectionTitle>
            <DetailsContainer>
              <>
                <DetailsItem>
                  <FormattedMessage id="files.connectedAccount" />
                </DetailsItem>
                <DetailsItem dark>
                  {emailContent.linkedEmailAccount?.email}
                </DetailsItem>
              </>
              <>
                <DetailsItem>
                  <FormattedMessage id="files.from" />
                </DetailsItem>
                <DetailsItem dark>{emailContent.data.sender}</DetailsItem>
              </>
              <>
                <DetailsItem>
                  <FormattedMessage id="files.to" />
                </DetailsItem>
                <DetailsItem dark>
                  {receiversHandler.items.map((receiver, index) => (
                    <Tooltip place="bottom" text={receiver} offset={2}>
                      <Email>
                        {receiver}
                        {index !== emailContent.data.receivers.length - 1 &&
                          ','}
                      </Email>
                    </Tooltip>
                  ))}

                  <ShowMoreLess
                    isShowMoreNeeded={receiversHandler.isShowMoreButtonVisible}
                    isShowingMore={receiversHandler.isShowingMore}
                    toggleShowingMore={receiversHandler.toggleShowMore}
                  />
                </DetailsItem>
              </>
              <>
                <DetailsItem>
                  <FormattedMessage id="files.date" />
                </DetailsItem>
                <DetailsItem dark>{creationDate}</DetailsItem>
              </>
            </DetailsContainer>
            {!emailContent?.unreconciled && (
              <Updates
                updates={emailContent?.loggingUpdates}
                group={emailContent?.group}
              />
            )}
          </Container>
        )}
      </DrawerContent>
      {!!selectedContent && (
        <PreviewContents
          isLoading={isLoading}
          contents={[selectedContent]}
          selectedContent={selectedContent}
          isOpen={!!selectedContent}
          onClosePreview={onClosePreview}
        />
      )}
    </Drawer>
  )
}

export default EmailContentDetails
