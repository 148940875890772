import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledTitleWrapper = styled.div`
  margin-top: 1.8rem;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 790px) {
    width: 100%;
  }

  > h4 {
    color: ${color('lightGray')};
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 1.8rem;
    text-transform: uppercase;
  }
`

export const SocialButtons = styled.div<{ centerLinks?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.centerLinks ? 'center' : 'start')};

  button {
    margin-right: 0.8rem;
  }
`

export const SocialIcon = styled.img`
  width: 1.6rem;
`
