import { useEffect } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import { getIsFounderVerified } from 'selectors/company'
import { useField, useFormikContext } from 'components/Form/hooks'
import { CreateFounderMetricFormType } from './useCreateFounderMetric'

interface Props {
  currentGroups: any[]
}

const useCreateFounderMetricForm = ({ currentGroups }: Props) => {
  const { setFieldValue, handleBlur } =
    useFormikContext<CreateFounderMetricFormType>()
  const [nameField] = useField<string>('name')
  const [frequencyField] = useField<string>('frequency')
  const [shareGroupsField] = useField<string>('sharedGroups')

  const isCurrentUserActingAsFounder = useAppSelector(isActingAsFounder)
  const isFounderVerified = useAppSelector(getIsFounderVerified)

  useEffect(() => {
    if (!currentGroups) return

    setFieldValue('sharedGroups', currentGroups)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroups])

  return {
    nameField,
    frequencyField,
    shareGroupsField,

    isFounderVerified,
    isCurrentUserActingAsFounder,

    setFieldValue,
    handleBlur,
  }
}

export default useCreateFounderMetricForm
