import { lazy } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import ProtectedRoute from 'components/ProtectedRoute'
import { CreateNewGroupProvider } from 'containers/Signup/context/CreateNewGroupContextProvider'
import CreateGroupWizard from 'containers/Signup/SignupWizard/CreateGroupWizard'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsClient } from 'selectors/auth'
import CompanyProfileEditDrawer from 'containers/Profiles/CompanyProfileEditDrawer'
import { fundManagerAndClientPaths } from 'components/Breadcrumb/BreadcrumbNavigation/constants/routes'
import useFundManagerBreadcrumb from 'components/Breadcrumb/BreadcrumbNavigation/FundManager/hooks/useFundManagerBreadcrumb'
import useShowBreadcrumb from 'components/Breadcrumb/BreadcrumbNavigation/hooks/useShowBreadcrumb'
import { getCurrentBaseRoute } from 'reducers/breadcrumbSlice'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import UpdateAllMetricsModal from 'containers/Metrics/UpdateAllMetricsModal/UpdateAllMetricsModal'
import SidebarMetricsRoutes from './SidebarMetricsRoutes'
import InvestorManagementRoutes from './InvestorManagementRoutes'
import UpdatesRoutes from './UpdatesRoutes'
import {
  COMPANIES,
  FUND_MANAGER_PROFILE_ROUTES,
  FUNDS,
  FUNDS_ALL_UPDATES,
  FUNDS_ROUTES,
  GROUP_PROFILE,
  GROUP_PROFILE_ALL_UPDATES,
  GROUP_PROFILE_METRICS,
  GROUP_PROFILE_METRICS_ID,
  GROUP_PROFILE_METRICS_UPDATE_ALL,
  GROUP_PROFILE_SELECTED_TAB_EDIT,
  INVESTOR_MANAGEMENT,
} from './constant/fundManagerRoutes'
import InvestmentsFeatureRoutes from './InvestmentsFeatureRoutes'
import CommonRoutes from './commonRoutes/CommonRoutes'
import CompaniesRoutes from './CompaniesRoutes'
import { CREATE_GROUP } from './constant/commonRoutes'

const FundManagerProfile = lazy(
  () => import('containers/Profiles/FundManagerProfile')
)

const FundHoldingProfile = lazy(
  () => import('containers/Profiles/FundHoldingProfile')
)

const FundManagerAndClientRoutes = () => {
  const path = useLocation().pathname
  const baseRoute = useAppSelector(getCurrentBaseRoute)
  const isClient = useAppSelector(isActingAsClient)

  const { showBreadcrumb } = useShowBreadcrumb({
    paths: fundManagerAndClientPaths,
  })

  const indexInvestmentsTabs = [
    'portfolio',
    'investors',
    'metrics',
    'updates',
    'files',
    'chat',
  ]

  const isOnIndexTab =
    indexInvestmentsTabs.some((tab) => path.endsWith(tab)) &&
    path.includes('investments')

  const { navigationComponentFunc } = useFundManagerBreadcrumb({ baseRoute })

  return (
    <Breadcrumb
      showBreadcrumb={showBreadcrumb && !isOnIndexTab}
      baseRoute={baseRoute}
      navigationComponentFunc={navigationComponentFunc}
    >
      <InvestmentsFeatureRoutes />

      <ProtectedRoute path={GROUP_PROFILE}>
        <Switch>
          <Route exact path={FUND_MANAGER_PROFILE_ROUTES}>
            <FundManagerProfile />
          </Route>
        </Switch>

        <Switch>
          <Route path={GROUP_PROFILE_ALL_UPDATES}>
            <UpdatesRoutes />
          </Route>
        </Switch>

        <Switch>
          <Route path={GROUP_PROFILE_SELECTED_TAB_EDIT}>
            <CompanyProfileEditDrawer />
          </Route>
          <Route exact path={GROUP_PROFILE_METRICS_UPDATE_ALL}>
            <UpdateAllMetricsModal />
          </Route>
          <Route path={GROUP_PROFILE_METRICS_ID}>
            <SidebarMetricsRoutes urlPrefix={GROUP_PROFILE_METRICS} />
          </Route>
        </Switch>
      </ProtectedRoute>

      {!isClient && (
        <ProtectedRoute path={INVESTOR_MANAGEMENT}>
          <InvestorManagementRoutes />
        </ProtectedRoute>
      )}

      <ProtectedRoute path={COMPANIES}>
        <CompaniesRoutes />
      </ProtectedRoute>

      <ProtectedRoute path={FUNDS}>
        <Switch>
          <Route exact path={FUNDS_ROUTES}>
            <FundHoldingProfile />
          </Route>
        </Switch>

        <Switch>
          <Route path={FUNDS_ALL_UPDATES}>
            <UpdatesRoutes />
          </Route>
        </Switch>
      </ProtectedRoute>

      <Route path={CREATE_GROUP}>
        <CreateNewGroupProvider>
          <CreateGroupWizard />
        </CreateNewGroupProvider>
      </Route>

      <CommonRoutes />
    </Breadcrumb>
  )
}

export default FundManagerAndClientRoutes
