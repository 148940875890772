import { useIntl } from 'react-intl'
import { Item, Title, TooltipContainer, TooltipContent } from './styles'

const enum DealDataKey {
  TOTAL_INVESTED = 'Total Invested',
  TOTAL_VALUE = 'Total Value',
}

type Payload = {
  name: DealDataKey
  totalInvested: number
  totalValue: number
}

export interface PortfolioChartTooltipProps {
  payload: {
    payload: Payload
    dataKey: string
    color: string
  }[]
  active?: boolean
}

const DealPortfolioBarTooltip = ({
  active,
  payload,
}: PortfolioChartTooltipProps) => {
  const intl = useIntl()

  if (active && payload?.length) {
    const { payload: dealsData } = payload[0]

    const amount =
      dealsData.name === DealDataKey.TOTAL_INVESTED
        ? dealsData.totalInvested
        : dealsData.totalValue

    return (
      <TooltipContainer>
        <Title>{dealsData.name}</Title>
        <TooltipContent>
          <Item>
            {intl.formatNumber(amount, {
              style: 'currency',
              currency: 'USD',
            })}
          </Item>
        </TooltipContent>
      </TooltipContainer>
    )
  }

  return null
}

export default DealPortfolioBarTooltip
