import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import theme from 'utils/theme'
import {
  ArrowDown,
  ArrowsWrapper,
  ArrowUp,
  Divider,
} from '../UpdateActions.styles'
import useNavigationUpdateAction from './useNavigationUpdateAction'
import TooltipActionWrapper from '../Components/TooltipActionWrapper/TooltipActionWrapper'

export const DIRECTION = {
  NEXT: 'NEXT',
  PREVIOUS: 'PREVIOUS',
} as const

interface Props {
  dividerColor: string
}

const NavigationUpdateAction = ({ dividerColor }: Props) => {
  const intl = useIntl()
  const {
    displayNavigation,
    disableNextButton,
    disablePreviousButton,
    onClickNavigationToUpdate,
  } = useNavigationUpdateAction()

  if (!displayNavigation) return null

  return (
    <>
      <Divider color={dividerColor} />

      <ArrowsWrapper>
        <TooltipActionWrapper
          id="navigation-update-action-previous"
          text={intl.formatMessage({ id: 'updateActions.navigation.previous' })}
        >
          <ArrowDown
            disabled={disableNextButton}
            onClick={() => {
              onClickNavigationToUpdate(DIRECTION.NEXT)
            }}
            transparent
          >
            <FontAwesomeIcon
              color={theme.colors.darkBlue}
              icon={['fal', 'arrow-left']}
            />
          </ArrowDown>
        </TooltipActionWrapper>

        <TooltipActionWrapper
          id="navigation-update-action-next"
          text={intl.formatMessage({ id: 'updateActions.navigation.next' })}
        >
          <ArrowUp
            disabled={disablePreviousButton}
            onClick={() => {
              onClickNavigationToUpdate(DIRECTION.PREVIOUS)
            }}
            transparent
          >
            <FontAwesomeIcon
              color={theme.colors.darkBlue}
              icon={['fal', 'arrow-left']}
            />
          </ArrowUp>
        </TooltipActionWrapper>
      </ArrowsWrapper>
    </>
  )
}

export default NavigationUpdateAction
