import ProtectedRoute from 'components/ProtectedRoute'
import { lazy } from 'react'
import {
  CHAT,
  CHAT_CHANNEL,
  FILES,
  SETTINGS,
} from 'routes/constant/commonRoutes'
import FilesRoutes from 'routes/FilesRoutes'
import SettingsRoutes from 'routes/SettingsRoutes'
import { getHasChatFeature } from 'selectors/chat'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

const Chat = lazy(() => import('containers/Chat'))

const InvestorCommonRoutes = () => {
  const hasChatFeature = useAppSelector(getHasChatFeature)
  return (
    <>
      <ProtectedRoute path={SETTINGS}>
        <SettingsRoutes />
      </ProtectedRoute>

      <ProtectedRoute path={FILES}>
        <FilesRoutes />
      </ProtectedRoute>

      {hasChatFeature && (
        <ProtectedRoute exact path={[CHAT, CHAT_CHANNEL]}>
          <Chat />
        </ProtectedRoute>
      )}
    </>
  )
}

export default InvestorCommonRoutes
