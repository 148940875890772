import { RefObject } from 'react'
import { useIntl } from 'react-intl'

import { CountryCode } from 'utils/types/common'
import SearchInput from 'components/SearchInput'

import { Position } from '../CountryInput/useCountryInput'
import { useCountryDropdown } from './useCountryDropdown'
import {
  CountryCallingCode,
  CountryDropdownContainer,
  Option,
  Container,
} from './CountryDropdown.styles'

interface CountryDropdownProps {
  className?: string
  countryDropdownRef: RefObject<HTMLDivElement>
  showDropdown: boolean
  selectOption: (country: CountryCode) => void
  position?: Position
  showCountryCallingCode?: boolean
}

const CountryDropdown = ({
  className,
  countryDropdownRef,
  showDropdown,
  position = 'bottom',
  showCountryCallingCode = false,
  selectOption,
}: CountryDropdownProps) => {
  const intl = useIntl()
  const { search, filteredCountries, handleSearch } = useCountryDropdown()

  return (
    <Container
      className={className}
      ref={countryDropdownRef}
      showDropdown={showDropdown}
      position={position}
    >
      <SearchInput
        value={search}
        placeholder={intl.formatMessage({
          id: 'general.search',
        })}
        handleChange={handleSearch}
      />
      <CountryDropdownContainer>
        {filteredCountries.map((country) => (
          <Option key={country.code} onClick={() => selectOption(country.code)}>
            {country.name}
            {showCountryCallingCode && (
              <CountryCallingCode>
                {` (+${country.callingCode})`}
              </CountryCallingCode>
            )}
          </Option>
        ))}
      </CountryDropdownContainer>
    </Container>
  )
}

export default CountryDropdown
