/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign, consistent-return */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'
import {
  needContrast,
  getContrastColor,
} from 'components/ColorPicker/ColorPicker'

import { getCurrentCompany } from 'selectors/company'
import { isEmptyText, TEXT } from 'utils/functions/mjml'
import { randomId } from 'utils/functions/number'
import { getNextSectionName, IUEHelper } from 'utils/functions/iue'
import { getCurrentGroupId } from 'selectors/auth'
import DragAndDropApi from 'utils/functions/dragAndDropApi'
import EmailService from 'api/EmailService'
import { DEBOUNCE_TIME } from 'utils/constants/debounce'
import { HEADER_SECTION } from 'utils/constants/iue'
import ConfirmationModal from 'components/ConfirmationModal'
import Toast from 'components/Toast'
import EmailStructure from './components/EmailStructure'
import SelectSections from './components/SelectSections'
import EmailAttachments from './components/EmailAttachments'

import styles from './BuildEmailSection.module.scss'

const EMAIL_SECTIONS = 'emailSections'
const EMAIL_STRUCTURE = 'emailStructure'

const DEBOUNCE_REMOVE_CUSTOM_SECTION_TIME = 10000

const BuildEmailSection = ({ emailUpdate }) => {
  const [addedReportId, setAddedReportId] = useState('')
  const [sections, setSections] = useState([])

  const [sectionsWithEntries, setSectionsWithEntries] = useState([])
  const [modal, setModal] = useState({
    title: '',
    body: '',
    isOpen: false,
    onConfirmCallback: () => {},
  })

  const [isLoadingDefaultSections, setIsLoadingDefaultSections] =
    useState(false)
  const companyInfo = useAppSelector(getCurrentCompany)

  const currentCompany = useAppSelector(getCurrentCompany)

  const groupId = useAppSelector(getCurrentGroupId)
  const intl = useIntl()
  const customSectionsToRemove = useRef([])
  const currentSections = useRef([])

  const editReportSection = useCallback(
    debounce(async (sectionId, sectionEdited) => {
      await EmailService.editReportSection(sectionId, sectionEdited).catch(
        () => {
          Toast.display(
            intl.messages['createEmail.editReportSectionError'],
            'error'
          )
        }
      )
    }, DEBOUNCE_TIME),
    []
  )

  const createDefaultHeader = async () => {
    const subject = `${companyInfo.name} ${dayjs().format('MMMM')} Update`
    return EmailService.createDefaultHeader(
      emailUpdate.item.id,
      subject,
      companyInfo.logoUrl
    )
  }

  const createNewReportEntry = async (title, position) => {
    try {
      if (title === HEADER_SECTION) {
        const {
          data: {
            result,
            entities: { reportEntries },
          },
        } = await createDefaultHeader()
        setAddedReportId({
          entryTitle: title,
          reportEntryId: result,
          text: reportEntries[result]?.text,
        })
      } else {
        const {
          data: { result },
        } = await EmailService.createReportEntry(emailUpdate.item.id, {
          text: '',
          position,
          title,
          showTitle: true,
        })
        setAddedReportId({ entryTitle: title, reportEntryId: result })
      }
    } catch (error) {
      Toast.display(
        intl.messages['createEmail.createNewReportSectionError'],
        'error'
      )
    }
  }

  const changeEntryPosition = async (entry, position) => {
    await EmailService.changeEntryPosition({
      position,
      reportEntryId: entry.id,
    }).catch(() => {
      Toast.display(
        intl.messages['createEmail.changeSectionPositionError'],
        'error'
      )
    })
  }

  const deleteSectionBySectionId = async (sectionId) => {
    await EmailService.deleteReportSection(sectionId).catch(() => {
      Toast.display(intl.messages['createEmail.deleteSectionError'], 'error')
    })
  }

  const moveFromSectionToEmailStructrue = (source, destination) => {
    const { newSource, newDestination } = DragAndDropApi.move({
      source,
      destination,
      sourceList: sections,
      destinationList: sectionsWithEntries,
    })
    setSections(newSource)
    setSectionsWithEntries(newDestination)
    createNewReportEntry(sections[source.index]?.title, destination.index + 1)
  }

  const reOrderSections = (source, destination) => {
    const oldSectionWithEntry = sectionsWithEntries[source.index]
    const newItems = DragAndDropApi.reorder(
      sectionsWithEntries,
      source.index,
      destination.index
    )
    setSectionsWithEntries(newItems)
    changeEntryPosition(oldSectionWithEntry, destination.index + 1)
  }

  const removeSection = async ({
    newSectionsWithEntries,
    sectionToRemoveFromReport,
    sectionId,
    insertAtIndex,
    isRemovingHeader,
  }) => {
    setSectionsWithEntries(newSectionsWithEntries)
    if (insertAtIndex === undefined) {
      setSections([...sections, sectionToRemoveFromReport])
    } else {
      setSections((current) => {
        const newSections = [...current]
        newSections.splice(insertAtIndex, 0, sectionToRemoveFromReport)
        return newSections
      })
    }
    await deleteSectionBySectionId(sectionId)
    if (isRemovingHeader) {
      await EmailService.editCustomSections([
        HEADER_SECTION,
        ...sections.map((section) => section.title),
      ])
    }
  }

  const handleRemoveSection = (sectionId, insertAtIndex) => {
    let sectionToRemoveFromReport
    const newSectionsWithEntries = sectionsWithEntries.filter((section) => {
      const isSection = section.id === sectionId
      if (isSection) {
        sectionToRemoveFromReport = section
      }
      return !isSection
    })

    const isRemovingHeader = sectionToRemoveFromReport.title === HEADER_SECTION

    if (sectionToRemoveFromReport.entries?.length) {
      setModal({
        title: intl.messages['createEmail.deleteEntryTitle'],
        body: intl.messages['createEmail.deleteEntryBody'],
        isOpen: true,
        onConfirmCallback: () => {
          removeSection({
            newSectionsWithEntries,
            sectionId,
            sectionToRemoveFromReport,
            insertAtIndex: isRemovingHeader ? 0 : insertAtIndex,
            isRemovingHeader,
          })
        },
      })
    } else {
      removeSection({
        newSectionsWithEntries,
        sectionId,
        sectionToRemoveFromReport,
        insertAtIndex: isRemovingHeader ? 0 : insertAtIndex,
        isRemovingHeader,
      })
    }
  }

  const getDefaultSectionsByGroupId = async () => {
    setIsLoadingDefaultSections(true)
    try {
      const {
        data: { entities },
      } = await EmailService.getDefaultSectionsByGroupId()

      if (entities?.groups[groupId]) {
        return entities?.groups[groupId].iueSections
      }
    } catch (error) {
      Toast.display(
        intl.messages['createEmail.getDefaultSectionsError'],
        'error'
      )
    } finally {
      setIsLoadingDefaultSections(false)
    }
  }

  const getReportEntries = async () => {
    try {
      const {
        data: { entities },
      } = await EmailService.getReportEntries(emailUpdate.item.id)

      if (entities.reportEntries) {
        return Object.values(entities.reportEntries)
      }
    } catch (error) {
      Toast.display(
        intl.messages['createEmail.getReportSectionsError'],
        'error'
      )
    }
  }

  useEffect(() => {
    currentSections.current = sections
  }, [sections])

  useEffect(() => {
    const setupEmailSections = async () => {
      const defaultEmailSections = await getDefaultSectionsByGroupId()
      const reportEntries = await getReportEntries()
      const serializedDefaultEmailSections = (defaultEmailSections || []).map(
        (section) => ({
          title: section,
          showTitle: section !== HEADER_SECTION, // Show in gray only header section by default
          id: randomId(),
        })
      )

      if (reportEntries) {
        const filteredEmailSections =
          IUEHelper.filterDefaultSectionsByReportSections(
            serializedDefaultEmailSections,
            reportEntries
          )
        setSections(filteredEmailSections)
        setSectionsWithEntries(
          IUEHelper.serializeSectionsWithEntries(reportEntries)
        )
      } else {
        setSections(serializedDefaultEmailSections)
      }
    }

    setupEmailSections()

    return () => {
      if (customSectionsToRemove.current.length) {
        EmailService.editCustomSections(
          IUEHelper.getNewSections(
            currentSections.current,
            customSectionsToRemove.current
          )
        )
      }
    }
  }, [])

  useEffect(() => {
    const newSectionsWithEntries = sectionsWithEntries.map(
      (sectionWithEntry) => {
        const { entryTitle, reportEntryId, text } = addedReportId
        if (sectionWithEntry.title === entryTitle) {
          sectionWithEntry.id = reportEntryId
          sectionWithEntry.text = text
        }
        return sectionWithEntry
      }
    )
    setSectionsWithEntries(
      IUEHelper.serializeSectionsWithEntries(newSectionsWithEntries)
    )
  }, [addedReportId])

  const onAddEntryToSection = (
    sectionId,
    type,
    content = '',
    index,
    imageSize
  ) => {
    const newEmailStructureSections = IUEHelper.addEntryToSection({
      sectionsWithEntries,
      sectionId,
      index,
      content,
      type,
      imageSize,
    })

    if (type === 'image') {
      const totalHtml = IUEHelper.gatherEntriesToUpdateBySectionId(
        sectionId,
        newEmailStructureSections
      )
      editReportSection(sectionId, { text: totalHtml })
    }
    setSectionsWithEntries(newEmailStructureSections)
  }

  const createAssetToEmail = async (file, sectionId, index, imageSize) => {
    const formData = new FormData()
    formData.append('report_asset[asset]', file)
    try {
      const response = await EmailService.createAssetToEmail(
        emailUpdate.item.id,
        formData
      )

      const { assetUrl, fileSize } = Object.values(
        response.data.entities?.reportAssets
      )[0]
      onAddEntryToSection(
        sectionId,
        'image',
        {
          size: fileSize,
          fileUrl: assetUrl,
        },
        index,
        imageSize
      )
    } catch (error) {
      Toast.display(error.message, 'error')
    }
  }

  const reOrderSectionEntry = (source, destination, type) => {
    const parentSectionId = type.split('emailStructureSubSection-')[1]
    const sectionEntryMap = sectionsWithEntries.reduce((acc, section) => {
      acc[section.id] = section.entries
      return acc
    }, {})
    const entriesForCorrespondingSection = sectionEntryMap[parentSectionId]
    const reorderedEntriesInSection = DragAndDropApi.reorder(
      entriesForCorrespondingSection,
      source.index,
      destination.index
    )
    let newSectionsWithEntries = [...sectionsWithEntries]

    newSectionsWithEntries = newSectionsWithEntries.map((section) => {
      if (section.id === parentSectionId) {
        section.entries = reorderedEntriesInSection
      }
      return section
    })
    const totalHtml = IUEHelper.gatherEntriesToUpdateBySectionId(
      parentSectionId,
      newSectionsWithEntries
    )

    editReportSection(parentSectionId, { text: totalHtml })
    setSectionsWithEntries(newSectionsWithEntries)
  }

  const onDragEnd = ({ source, destination, type }) => {
    if (!destination) {
      return
    }

    const isDroppingFromSectionToStructure =
      source.droppableId === EMAIL_SECTIONS &&
      destination.droppableId === EMAIL_STRUCTURE
    const isDroppingToStructureFromStructure =
      source.droppableId === EMAIL_STRUCTURE &&
      destination.droppableId === EMAIL_STRUCTURE
    const isMovingEmailSectionEntry = type.includes('emailStructureSubSection')

    const isDroppingFromStructureToSection =
      destination.droppableId === EMAIL_SECTIONS &&
      source.droppableId === EMAIL_STRUCTURE

    if (isDroppingFromSectionToStructure) {
      moveFromSectionToEmailStructrue(source, destination)
    } else if (isDroppingFromStructureToSection) {
      const section = sectionsWithEntries[source.index]
      const isRemovingHeader = section.title === HEADER_SECTION
      handleRemoveSection(
        section.id,
        isRemovingHeader ? 0 : destination.index,
        isRemovingHeader
      )
    }

    if (isDroppingToStructureFromStructure) {
      reOrderSections(source, destination)
    } else if (isMovingEmailSectionEntry) {
      reOrderSectionEntry(source, destination, type)
    }
  }

  const onChangeTextAlign = (sectionId, entryId, textAlignValue) => {
    setSectionsWithEntries((currentSectionWithEntries) => {
      const newSectionsWithEntries = IUEHelper.updateEntryField({
        sectionsWithEntries: currentSectionWithEntries,
        sectionId,
        entryId,
        fieldName: 'textAlign',
        newValue: textAlignValue,
      })
      const totalHtml = IUEHelper.gatherEntriesToUpdateBySectionId(
        sectionId,
        newSectionsWithEntries
      )
      editReportSection(sectionId, { text: totalHtml })
      return newSectionsWithEntries
    })
  }

  const onChangeEntrySection = debounce((html, type, sectionId, entryId) => {
    setSectionsWithEntries((currentSectionWithEntries) => {
      const newSectionsWithEntries = IUEHelper.updateEntryField({
        sectionsWithEntries: currentSectionWithEntries,
        sectionId,
        entryId,
        fieldName: 'content',
        newValue: html,
      })

      const totalHtml = IUEHelper.gatherEntriesToUpdateBySectionId(
        sectionId,
        newSectionsWithEntries
      )

      editReportSection(sectionId, { text: totalHtml })

      return IUEHelper.updateSectionField({
        sectionsWithEntries: newSectionsWithEntries,
        sectionId,
        fieldName: 'text',
        newValue: totalHtml,
      })
    })
  }, DEBOUNCE_TIME)

  const removeEntry = ({ newSectionsWithEntries, sectionId, newEntries }) => {
    newSectionsWithEntries.forEach((sectionWithEntries) => {
      if (sectionWithEntries.id === sectionId) {
        sectionWithEntries.entries = newEntries
      }
    })
    const totalHtml = IUEHelper.gatherEntriesToUpdateBySectionId(
      sectionId,
      newSectionsWithEntries
    )
    editReportSection(sectionId, { text: totalHtml })
    setSectionsWithEntries(newSectionsWithEntries)
  }

  const onRemoveEntryFromSection = (sectionId, entryId) => {
    let entryToRemove = {}
    let newEntries = []
    const newSectionsWithEntries = sectionsWithEntries.map(
      (sectionWithEntries) => {
        if (sectionWithEntries.id === sectionId) {
          newEntries = sectionWithEntries.entries.filter((entry) => {
            const isEntryToRemove = entryId === entry.id
            if (isEntryToRemove) {
              entryToRemove = entry
            }
            return !isEntryToRemove
          })
        }
        return sectionWithEntries
      }
    )

    const isContentEmpty =
      entryToRemove.type === TEXT ? isEmptyText(entryToRemove.content) : false
    if (!isContentEmpty) {
      setModal({
        title: intl.formatMessage(
          { id: 'createEmail.deleteTextOrImageTitle' },
          { name: entryToRemove.type || 'image' }
        ),
        body: intl.formatMessage(
          { id: 'createEmail.deleteTextOrImageBody' },
          { name: entryToRemove.type || 'image' }
        ),
        isOpen: true,
        onConfirmCallback: () => {
          removeEntry({ newSectionsWithEntries, newEntries, sectionId })
        },
      })
    } else {
      removeEntry({ newSectionsWithEntries, newEntries, sectionId })
    }
  }

  const onAddNewSection = () => {
    setSections([
      ...sections,
      {
        isDraftMode: true,
        title: getNextSectionName(
          [...sections, ...sectionsWithEntries],
          'New section'
        ),
        id: randomId(),
      },
    ])
  }

  const handleChangeSectionName = (newName, sectionId) => {
    const newSectionsWithEntries = IUEHelper.updateSectionField({
      sectionsWithEntries,
      sectionId,
      fieldName: 'title',
      newValue: newName,
    })
    setSectionsWithEntries(newSectionsWithEntries)
    if (newName !== '') {
      editReportSection(sectionId, { title: newName })
    }
  }

  const onRemoveSectionTitle = (sectionId) => {
    setModal({
      title: intl.formatMessage({ id: 'createEmail.deleteTitle' }),
      body: intl.formatMessage({ id: 'createEmail.deleteTitleText' }),
      isOpen: true,
      onConfirmCallback: () => {
        handleChangeSectionName(null, sectionId)
      },
    })
  }

  const onChangeShowTitle = (sectionId, showTitle) => {
    const newSectionsWithEntries = IUEHelper.updateSectionField({
      sectionsWithEntries,
      sectionId,
      fieldName: 'showTitle',
      newValue: showTitle,
    })

    setSectionsWithEntries(newSectionsWithEntries)
    if (showTitle !== '') {
      editReportSection(sectionId, { showTitle })
    }
  }

  const onChangeImageSize = (sectionId, entryId, imageSize) => {
    const newSectionsWithEntries = IUEHelper.updateEntryField({
      sectionsWithEntries,
      sectionId,
      entryId,
      fieldName: 'imageSize',
      newValue: imageSize,
    })

    const totalHtml = IUEHelper.gatherEntriesToUpdateBySectionId(
      sectionId,
      newSectionsWithEntries,
      'image'
    )
    editReportSection(sectionId, { text: totalHtml })

    setSectionsWithEntries(newSectionsWithEntries)
  }

  const handleRemoveCustomSection = debounce(async () => {
    try {
      const {
        data: { entities },
      } = await EmailService.editCustomSections(
        IUEHelper.getNewSections(sections, customSectionsToRemove.current)
      )

      const newEmailSections = entities.groups[groupId]?.iueSections.map(
        (iueSection) => ({
          title: iueSection,
          id: randomId(),
        })
      )

      if (sectionsWithEntries) {
        const filteredEmailSections =
          IUEHelper.filterDefaultSectionsByReportSections(
            newEmailSections,
            sectionsWithEntries
          )
        setSections(filteredEmailSections)
      }
    } catch (error) {
      Toast.display(
        intl.messages['createEmail.onRemovingCustomSectionError'],
        'error'
      )
    }
  }, DEBOUNCE_REMOVE_CUSTOM_SECTION_TIME)

  const onSelectColor = (section, color) => {
    const newSectionsWithEntries = IUEHelper.updateSectionField({
      sectionsWithEntries,
      sectionId: section.id,
      fieldName: 'backgroundColor',
      newValue: color,
    })
    setSectionsWithEntries(newSectionsWithEntries)
    const totalHtml = IUEHelper.gatherEntriesToUpdateBySectionId(
      section.id,
      newSectionsWithEntries
    )

    editReportSection(section.id, {
      backgroundColor: color,
      textColor: needContrast(color) ? getContrastColor(color) : null,
      text: totalHtml,
    })
  }

  const onAddCustomSectionToRemove = (sectionName) => {
    customSectionsToRemove.current.push(sectionName)
  }

  const onRemoveCustomSectionToRemove = (sectionName) => {
    customSectionsToRemove.current = customSectionsToRemove.current.filter(
      (section) => section !== sectionName
    )
  }

  const handleChangeDraftSectionName = (sectionId, event) => {
    const newName = event.target.value
    setSections((oldSections) =>
      IUEHelper.updateSectionField({
        sectionId,
        sectionsWithEntries: oldSections,
        fieldName: 'title',
        newValue: newName,
      })
    )
  }

  const doesSectionAlreadyExist = (sectionName) =>
    sections.filter((section) => section.title === sectionName)

  const onSaveNewDraftSection = async (sectionName) => {
    const doesSectionExist = doesSectionAlreadyExist(sectionName).length > 1

    if (doesSectionExist) {
      Toast.display(intl.messages['createEmail.sectionNameExists'], 'warning')
    } else if (sectionName.trim() === '') {
      Toast.display(intl.messages['createEmail.sectionCantBeEmpty'], 'warning')
    } else {
      try {
        await EmailService.editCustomSections(
          sections.map((section) => section.title)
        )
        setSections((oldSections) => {
          return oldSections.map((section) => {
            if (section.title === sectionName) {
              return {
                id: section.id,
                title: section.title,
                showTitle: true,
              }
            }
            return section
          })
        })
      } catch (error) {
        Toast.display(intl.messages['createEmail.onSaveDraftError'], 'error')
      }
    }
  }

  const onDiscardNewDraftSection = async (emailSectionId) => {
    try {
      setSections((oldSections) =>
        oldSections.filter((section) => section.id !== emailSectionId)
      )
    } catch (error) {
      Toast.display(intl.message['createEmail.onDiscardError'], 'error')
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.leftColumn}>
        <div className={styles.sticky}>
          <SelectSections
            onAddNewSection={onAddNewSection}
            emailSections={sections}
            isLoading={isLoadingDefaultSections}
            handleRemoveSection={handleRemoveCustomSection}
            onAddCustomSectionToRemove={onAddCustomSectionToRemove}
            onRemoveCustomSectionToRemove={onRemoveCustomSectionToRemove}
            handleChangeDraftSectionName={handleChangeDraftSectionName}
            onSaveNewDraftSection={onSaveNewDraftSection}
            onDiscardNewDraftSection={onDiscardNewDraftSection}
          />
          <div className={styles.attachmentsWrapper}>
            <EmailAttachments emailUpdate={emailUpdate} />
          </div>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <EmailStructure
          emailStructureSections={sectionsWithEntries}
          handleRemoveSection={handleRemoveSection}
          onAddEntryToSection={onAddEntryToSection}
          onRemoveEntryFromSection={onRemoveEntryFromSection}
          onChangeEntrySection={onChangeEntrySection}
          createAssetToEmail={createAssetToEmail}
          handleChangeSectionName={handleChangeSectionName}
          onSelectColor={onSelectColor}
          onRemoveSectionTitle={onRemoveSectionTitle}
          onChangeImageSize={onChangeImageSize}
          onChangeShowTitle={onChangeShowTitle}
          onChangeTextAlign={onChangeTextAlign}
        />
      </div>
      <ConfirmationModal
        title={modal.title}
        body={modal.body}
        onHide={() => setModal({ ...modal, isOpen: false })}
        onConfirm={modal.onConfirmCallback}
        isOpen={modal.isOpen}
      />
    </DragDropContext>
  )
}

BuildEmailSection.propTypes = {
  emailUpdate: PropTypes.shape({
    id: PropTypes.string,
    attachments: PropTypes.array,
    item: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default BuildEmailSection
