import Toast from 'components/Toast'
import { useParams, useHistory } from 'react-router-dom'
import {
  useEditInvestmentVehicleMutation,
  useInvestmentVehicleQuery,
} from 'utils/hooks/investorManagement/useInvestmentVehiclesQuery'
import { InvestmentVehicleFormValues } from 'utils/types/investors'
import { getInvestmentVehiclePayload } from 'utils/functions/investorManagement'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'

export const useEditInvestmentVehicle = (onCloseDrawer: () => void) => {
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const { investmentVehicleId } = useParams<{ investmentVehicleId: string }>()
  const history = useHistory()
  const { data: investmentVehicle, isLoading } =
    useInvestmentVehicleQuery(investmentVehicleId)

  const editInvestmentVehicleMutation =
    useEditInvestmentVehicleMutation(investmentVehicleId)

  const editInvestmentVehicle = (values: InvestmentVehicleFormValues) => {
    const payload = getInvestmentVehiclePayload(values, investmentVehicle)
    editInvestmentVehicleMutation.mutate(payload, {
      onSuccess: (newInvestor) => {
        Toast.displayIntl([
          'investorManagement.success.editInvestmentVehicle',
          { investmentVehicleName: newInvestor.name },
        ])
        onCloseDrawer()
      },
      onError: (_error) =>
        Toast.displayIntl(
          'investorManagement.errors.editInvestmentVehicle',
          'error'
        ),
    })
  }

  const onCreateNewInvestor = (investorName: string) => {
    history.replace('/investor-management/investors/create', {
      initialInvestorName: investorName,
    })
  }

  return {
    investmentVehicle,
    isLoading,
    isInvestorGroup,
    editInvestmentVehicle,
    onCreateNewInvestor,
  }
}
