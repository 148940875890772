export const isEnterCode = (event) => event.code === 'Enter'
export const isDownCode = (event) => event.keyCode === 40
export const isUpCode = (event) => event.keyCode === 38
export const isLeftCode = (event) => event.keyCode === 37
export const isRightCode = (event) => event.keyCode === 39
export const isEscCode = (event) => event.keyCode === 27
export const isEnterKeyCode = (event) => event.keyCode === 13
export const isBackspaceKeyCode = (event) => event.keyCode === 8
export const isSpaceKeyCode = (event) => event.keyCode === 32

export const isEnterKey = (event) => event.key === 'Enter'
export const isSpaceKey = (event) => event.key === ' '
