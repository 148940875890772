import { useCallback } from 'react'
import { useChatContext } from 'stream-chat-react'

import {
  ArchivedChannel,
  StreamChatType,
  TopicChannel,
} from 'containers/Chat/types'
import { dispatchEvent } from 'utils/hooks/useEventListener'

export const ARCHIVE_CHANNEL_EVENT = 'ARCHIVE_CHANNEL_EVENT'
export const UNARCHIVE_CHANNEL_EVENT = 'UNARCHIVE_CHANNEL_EVENT'

export const checkIsArchived = (
  channelId?: string,
  archivedChannels?: ArchivedChannel[]
) =>
  !!archivedChannels?.find(
    (archivedChannel) => archivedChannel.id === channelId
  )

export const useArchiveChannels = () => {
  const { client, channel: activeChannel } =
    useChatContext<StreamChatType<TopicChannel>>()

  const archiveChannel = useCallback(
    async (channelId: string) => {
      const archivedChannels = [
        ...(client.user?.archivedChannels || []),
        {
          id: channelId,
          archivedAt: new Date().toISOString(),
        },
      ]

      await client.partialUpdateUser({
        id: client.user!.id,
        set: {
          archivedChannels,
        },
      })

      activeChannel?.mute()
    },
    [activeChannel, client]
  )

  const unarchiveChannel = useCallback(
    async (channelId: string) => {
      const archivedChannels = client.user?.archivedChannels || []
      const newArchivedChannels = archivedChannels.filter(
        (channel) => channel.id !== channelId
      )

      activeChannel?.unmute()

      if (archivedChannels.length !== newArchivedChannels.length) {
        await client.partialUpdateUser({
          id: client.user!.id,
          set: {
            archivedChannels: newArchivedChannels,
          },
        })
      }
    },
    [activeChannel, client]
  )

  const toggleArchiveChannel = useCallback(
    async (channelId: string) => {
      let archiving = false
      const archivedChannels = client.user?.archivedChannels || []

      if (archivedChannels.find((channel) => channel.id === channelId)) {
        unarchiveChannel(channelId)
      } else {
        archiving = true
        archiveChannel(channelId)
      }

      dispatchEvent(
        archiving ? ARCHIVE_CHANNEL_EVENT : UNARCHIVE_CHANNEL_EVENT,
        {
          channelId,
        }
      )
    },
    [client, archiveChannel, unarchiveChannel]
  )

  const isChannelArchived = useCallback(
    (channelId?: string) => {
      return checkIsArchived(channelId, client.user?.archivedChannels)
    },
    [client.user?.archivedChannels]
  )

  return {
    toggleArchiveChannel,
    archiveChannel,
    unarchiveChannel,
    isChannelArchived,
  }
}
