import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { Color } from 'utils/theme'

export const Text = styled.p<{ textColor?: Color }>`
  font-size: 1.8rem;
  line-height: 2rem;
  color: ${({ textColor }) => color(textColor || 'darkGray')};
`

export const Gif = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 4.5rem;
  padding: 0;
`
