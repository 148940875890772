import { useQuery } from '@tanstack/react-query'
import { useChatContext } from 'stream-chat-react'
import { chatKeys } from 'utils/queries/chat'
import { TopicEntityType } from 'utils/types/chatTopicEntity'
import { ChannelType, StreamChatType, TopicChannel } from '../types'

export const useQuerySimilarChannel = ({
  entityId,
  entityType,
  channelType,
  memberIds,
  updateId,
  enabled,
}: {
  entityId: string
  entityType: TopicEntityType
  channelType: ChannelType
  memberIds: string[]
  updateId?: string
  enabled?: boolean
}) => {
  const { client } = useChatContext<StreamChatType<TopicChannel>>()

  return useQuery(
    chatKeys.similarChannel(
      entityId,
      entityType,
      channelType,
      memberIds,
      updateId
    ),
    async () => {
      const channels = await client.queryChannels(
        {
          entityId,
          entityType,
          updateId,
          type: channelType,
          members: { $eq: [...memberIds, client.userID!] },
        },
        { created_at: -1 },
        {
          limit: 1,
          offset: 0,
        }
      )
      return channels.at(0) ?? null
    },
    { enabled, staleTime: 500 }
  )
}
