import CompanyService from 'api/CompanyService'
import Toast from 'components/Toast'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { ALREADY_TAKEN_ERROR } from 'utils/constants/errors'
import {
  addTagToList,
  getTagsFromTaggings,
  removeTagFromList,
} from 'utils/functions/tags'
import { useDynamicWidth } from 'utils/hooks/useDynamicWidth'
import useTagsByGroup from 'utils/hooks/useTagsByGroup'
import { Tag } from 'utils/types/update'

interface Props {
  holdingId: string
  tags?: Tag[]
  isFundHolding: boolean
}

const dynamicWidthConfig = {
  rowsCount: 1,
  baseWidth: 33,
  showMoreWidth: 60,
  paddingGap: 13,
}

const useTags = ({ tags, holdingId, isFundHolding }: Props) => {
  const [tagsList, setTagsList] = useState(tags || [])
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const isShowingProfileFromSignup =
    window.location.href.includes('/sign-up-wizard')
  const groupTags = useTagsByGroup(
    !isShowingProfileFromSignup && !isInvestorGroup
  )
  const totalTags = tagsList.length

  const { containerRef, maxItemsCount: numberOfTagsToShow } = useDynamicWidth(
    tagsList,
    false,
    dynamicWidthConfig
  )

  useEffect(() => {
    setTagsList(tags || [])
  }, [tags])

  const onAddTag = async (tagName, resultsFromSearch: Tag[]) => {
    const groupTagsWithResultsFromSearch = [...groupTags, ...resultsFromSearch]
    const newTagsList = addTagToList(
      tagName,
      tagsList,
      groupTagsWithResultsFromSearch
    )
    setTagsList(newTagsList)
    const tag = newTagsList.filter((currTag) => tagName === currTag.name)[0]

    try {
      const {
        data: { entities },
      } = await CompanyService.updateTagsByCompany(
        holdingId,
        tag,
        isFundHolding
      )
      const tagsResponse = getTagsFromTaggings(Object.values(entities.taggings))
      const tagResponse = tagsResponse.find(
        (currTag) => currTag.name === tag.name
      )

      setTagsList(
        newTagsList.map((currTag) => {
          if (!currTag.taggingId && currTag.name === tagName) {
            return {
              ...currTag,
              id: tagResponse.id,
              taggingId: tagResponse.taggingId,
            }
          }
          return currTag
        })
      )
    } catch (error) {
      if (!error.message?.includes?.(ALREADY_TAKEN_ERROR)) {
        Toast.displayIntl('tags.errorAddingTag', 'error')
      }
    }
  }

  const onRemoveTag = async (tag: Tag) => {
    const newTagsList = removeTagFromList(tag, tagsList)
    setTagsList(newTagsList.filter((currTag) => !currTag.destroy))
    const tagToRemove = { ...tag, destroy: true }
    await CompanyService.updateTagsByCompany(
      holdingId,
      tagToRemove,
      isFundHolding
    )?.catch(() => {
      Toast.displayIntl('tags.errorRemovingTag', 'error')
    })
  }

  return {
    onRemoveTag,
    onAddTag,
    tagsList,
    numberOfTagsToShow,
    totalTags,
    containerRef,
  }
}

export default useTags
