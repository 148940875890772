import Types from './types'

export const updateServiceWorker = (registration) => ({
  type: Types.SW_UPDATE_INSTALLED,
  payload: registration,
})

export const activateServiceWorker = () => ({
  type: Types.SW_UPDATE_ACTIVATED,
})
