import Avatar from 'components/Avatar'
import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const SearchMessageResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.9rem;
`
export const ChannelMessages = styled.div``
export const Channel = styled.div``
export const ChannelRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.9rem;
`

export const ChannelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
  margin: 0 0 1.1rem 7rem;

  @media ${maxSize.xsm} {
    margin-left: 2rem;
  }
`

export const ChannelName = styled.a`
  font-size: 1.6rem;
  color: ${color('gray')};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  .markjs-highlight-text {
    color: ${color('primaryBlue')};
    border-radius: 0;
  }
`

export const AvatarContainer = styled.div`
  position: relative;
`
export const StyledAvatar = styled(Avatar)`
  padding: 0;
`

export const ParticipantRowContainer = styled.div<{ isLast?: boolean }>`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0 0 3.1rem 7rem;
  ${(props) =>
    !props.isLast &&
    css`
      border-bottom: 1px solid ${color('chatBorder')};
    `}

  @media ${maxSize.xsm} {
    padding-left: 2rem;
  }
`

export const ZeroStateContainer = styled.div`
  display: flex;
  height: 100%;
`
