import { ReactNode, cloneElement } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DownloadLink from 'components/DownloadLink'

import styles from './PDFPreviewer.module.scss'
import { DIRECTION, PDF_DOCUMENT_ID, usePDFPreviewer } from './usePDFPreviewer'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
  file: string
  name?: string
  className?: string
  autoScale?: boolean
  loadingComponent?: ReactNode
  errorComponent?: ReactNode
  controlsComponent?: JSX.Element
  displayControls: boolean
  onFileDownloaded?: () => void
}

const PDFPreviewer = ({
  file,
  className,
  autoScale,
  loadingComponent,
  errorComponent,
  controlsComponent,
  name = '',
  displayControls,
  onFileDownloaded = () => {},
}: Props) => {
  const pdfControls = usePDFPreviewer({ autoScale })
  const {
    onDocumentLoadSuccess,
    navigate,
    calculatePageScale,
    increaseScale,
    decreaseScale,
    pageNumber,
    pagesCount,
    scale,
    pageRef,
  } = pdfControls

  return (
    <div id={PDF_DOCUMENT_ID} className={className}>
      <Document
        className={styles.pdfPreviewer}
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={loadingComponent}
        error={errorComponent}
      >
        <Page
          ref={pageRef}
          pageNumber={pageNumber}
          scale={scale}
          onLoadSuccess={calculatePageScale}
          loading=""
        />

        {displayControls && controlsComponent ? (
          cloneElement(controlsComponent, {
            ...pdfControls,
          })
        ) : (
          <>
            <div className={styles.pageControls}>
              <button
                disabled={pageNumber === 1}
                onClick={() => navigate(DIRECTION.LEFT)}
                type="button"
              >
                ‹
              </button>
              <span>{`${pageNumber} of ${pagesCount}`}</span>
              <button
                disabled={pageNumber === pagesCount}
                onClick={() => navigate(DIRECTION.RIGHT)}
                type="button"
              >
                ›
              </button>
            </div>

            <div className={styles.zoomContainer}>
              <button type="button" onClick={increaseScale}>
                <FontAwesomeIcon size="2x" icon={['far', 'search-plus']} />
              </button>
              <button type="button" onClick={decreaseScale}>
                <FontAwesomeIcon size="2x" icon={['far', 'search-minus']} />
              </button>
            </div>

            <DownloadLink
              className={styles.downloadLink}
              url={file}
              name={name}
              onFileDownloaded={onFileDownloaded}
            />
          </>
        )}
      </Document>
    </div>
  )
}

export default PDFPreviewer
