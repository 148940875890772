import React, { useState } from 'react'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import PendingInvitations from './components/PendingInvitations'
import InvitationsHistory from './components/InvitationsHistory'
import * as Styles from './Invitations.styles'

interface InvitationsProps {}

const Invitations: React.FC<InvitationsProps> = () => {
  const [hasPendingInvitations, setHasPendingInvitations] = useState(true)
  const [hasInvitationsHistory, setHasInvitationsHistory] = useState(true)

  if (!hasPendingInvitations && !hasInvitationsHistory) {
    return (
      <Styles.ZeroStateContainer>
        <ZeroState
          type={ZeroStateType.PENDING_REQUESTS}
          textFontSize="1.4rem"
          infoMarginTop="1.6rem"
        />
      </Styles.ZeroStateContainer>
    )
  }

  return (
    <Styles.Container>
      <PendingInvitations setHasPendingInvitations={setHasPendingInvitations} />
      <InvitationsHistory setHasInvitationsHistory={setHasInvitationsHistory} />
    </Styles.Container>
  )
}

export default Invitations
