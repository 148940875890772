import styled from 'styled-components'

export const GroupManagedWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const TooltipContent = styled.div`
  white-space: pre-wrap;
`
