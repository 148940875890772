import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const RawReportContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
    text-decoration: auto;

    &:hover {
      text-decoration: underline;
    }
  }
`

export const Code = styled.pre`
  margin: 0.8rem 0.2rem 0 0.1rem;
  padding: 1.6rem 1.2rem;
  border-radius: 0.8rem;
  background-color: ${color('softBlue', 0.9)};
  font-size: 1.4rem;
  max-height: 70vh;
  overflow: auto;
`
