import styled, { css } from 'styled-components'
import ButtonToStyle from 'ui/Button'
import { color } from 'utils/functions/colors'

export const Button = styled(ButtonToStyle)`
  display: flex;
  height: fit-content;
  align-items: center;
  width: fit-content;
  line-height: 1;
  padding: 0.8rem 1.2rem;
  border: ${(props) =>
    props.border
      ? `0.1rem solid ${color('veryLightBlue')}`
      : '0.1rem solid transparent'};
  svg {
    font-size: 1.4rem;
  }
`

export const ButtonsWrapper = styled.div<{
  showEmailActionsBelow: boolean
}>`
  display: flex;
  align-items: center;
  gap: 0.6rem;

  ${(props) =>
    props.showEmailActionsBelow &&
    css`
      justify-content: end;
      border-bottom: 0.1rem solid ${color('veryLightBlue')};
      padding: 1rem 3rem;
    `}
`
