import GroupLogoComponent from 'components/GroupLogo/GroupLogo'
import PortfolioIconComponent from 'components/PortfolioIcon'
import styled, { css } from 'styled-components'
import IconButton from 'ui/IconButton'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { EllipsisStyles } from '../ChatHeader/ChatHeader.styles'

export const ClosePopupIconButton = styled(IconButton)`
  background: ${color('veryLightBlue40')};
`

export const ChatTitleContainer = styled.div`
  ${EllipsisStyles}
`

export const Names = styled.div<{ isATopicChannel: boolean }>`
  font-size: 1.4rem;
  font-weight: 700;
  text-align: left;

  @media ${maxSize.tabletLg} {
    ${({ isATopicChannel }) =>
      isATopicChannel &&
      css`
        display: none;
      `}
  }
`

export const CountMembers = styled.div`
  font-size: 1.2rem;
  color: ${color('lightGray')};
  text-align: left;
`

export const PortfolioIcon = styled(PortfolioIconComponent)`
  min-width: 2.8rem;
  min-height: 2.8rem;
`

export const GroupLogo = styled(GroupLogoComponent)`
  min-width: 2.8rem;
  min-height: 2.8rem;
`

export const IconContainer = styled.div`
  width: 2.8rem;
`
