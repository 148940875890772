import React from 'react'
import { FormattedMessage } from 'react-intl'

import {
  SideBarItemContainer,
  SidebarItemIcon,
  SidebarItemTitle,
  ItemWrapper,
  ItemTitle,
} from '../SideBarItem/SideBarItem.styles'

const NewFeaturesButton = () => {
  React.useEffect(() => {
    setTimeout(() => {
      window?.Beamer?.init()
    }, 1000)
  }, [])

  return (
    <SideBarItemContainer id="beamerButton" as="button">
      <ItemWrapper>
        <ItemTitle>
          <SidebarItemIcon icon={['fal', 'sparkles']} />
          <SidebarItemTitle>
            <FormattedMessage id="sidebar.newFeatures" />
          </SidebarItemTitle>
        </ItemTitle>
      </ItemWrapper>
    </SideBarItemContainer>
  )
}

export default NewFeaturesButton
