import { useIntl } from 'react-intl'
import { Color } from 'utils/theme'
import { CheckIcon, ItemWrapper } from './Item.styles'
import { Text } from '../../UpgradeYourAccountModal.styles'

interface Props {
  textId: string
  noMarginBottom?: boolean
  noCheck?: boolean
  textColor?: Color
}

const Item = ({ textId, noMarginBottom, noCheck, textColor }: Props) => {
  const intl = useIntl()
  return (
    <ItemWrapper noMarginBottom={noMarginBottom}>
      {!noCheck && <CheckIcon icon={['far', 'check']} />}
      <Text textColor={textColor}>{intl.formatMessage({ id: textId })}</Text>
    </ItemWrapper>
  )
}

export default Item
