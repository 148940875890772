import { LinkWrapper, NavigationItem } from '../Breadcrumb.styles'

interface Props {
  isActive: boolean
  label: string
  entityLogo: React.ReactNode
  onClickHandler: () => void
}

const EntityLink = ({ isActive, label, onClickHandler, entityLogo }: Props) => {
  return (
    <NavigationItem>
      {entityLogo}
      <LinkWrapper isActive={isActive} onClick={onClickHandler}>
        {label}
      </LinkWrapper>
    </NavigationItem>
  )
}

export default EntityLink
