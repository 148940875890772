import { useEffect, useRef } from 'react'
import * as typeformEmbed from '@typeform/embed'

import { useHistory } from 'react-router-dom'

const Typeform = () => {
  const typeformRef = useRef(null)
  const history = useHistory()

  useEffect(() => {
    if (typeformRef.current) {
      typeformEmbed.makeWidget(
        typeformRef.current,
        process.env.REACT_APP_TYPEFORM_URL,
        {
          opacity: 0,
          hideScrollbars: true,
          onSubmit: () => {
            history.replace('/sign-out')

            window.location.href = 'https://www.clockwork.app/'
          },
        }
      )
    }
  }, [history, typeformRef])

  return (
    <div>
      <div
        ref={typeformRef}
        style={{ width: '100%', maxHeight: '100vh', height: '100vh' }}
      />
    </div>
  )
}

export default Typeform
