/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'

import { setCurrentSelectedDrafts } from 'actions/emails'
import UpdateIconFactory from 'components/UpdateIconFactory'
import { getCurrentSelectedDrafts } from 'selectors/email'

import {
  TimelineItemDot,
  TimelineItemLine,
  TimelineListItem,
  TimelineItemContent,
} from './TimelineItem.styles'

const item = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const MARGIN_BOTTOM_ITEMS_IN_PX = 36

const TimelineItem = ({
  children,
  withPortfolio,
  updateType,
  withCompany,
  update,
  lineMargin,
  icon,
  selectionMode,
  left,
  padding,
  hideDottedLine,
}) => {
  const dispatch = useAppDispatch()
  const selectedDrafts = useAppSelector(getCurrentSelectedDrafts)
  const [hoverState, setHoverState] = useState(false)
  const [checkState, setCheckState] = useState(false)
  const timelineContentRef = useRef(null)
  const [lineItemHeight, setLineItemHeight] = useState(0)

  const timelineHeight = timelineContentRef?.current?.clientHeight
  useEffect(() => {
    setLineItemHeight(timelineContentRef?.current?.clientHeight)
  }, [timelineHeight])

  const onClickIcon = (draftBeingClicked) => {
    const newSelectedDrafts = [...selectedDrafts]

    if (checkState) {
      dispatch(
        setCurrentSelectedDrafts(
          newSelectedDrafts.filter(
            (draftItem) => draftItem.id !== draftBeingClicked.id
          )
        )
      )
    } else {
      newSelectedDrafts.push(draftBeingClicked)
      dispatch(setCurrentSelectedDrafts(newSelectedDrafts))
    }
  }

  useEffect(() => {
    if (selectedDrafts.find((draft) => draft?.id === update?.id)) {
      setCheckState(true)
    } else {
      setCheckState(false)
    }
  }, [selectedDrafts])

  const isDraftEmail =
    (updateType === 'report' || updateType === 'iue') && update.sentAt === null

  return (
    <TimelineListItem
      as={motion.li}
      variants={item}
      hideDottedLine={hideDottedLine}
    >
      {!hideDottedLine && (
        <TimelineItemLine
          height={`${lineItemHeight + lineMargin}px`}
          left={left}
        />
      )}
      <TimelineItemDot customIcon={!!icon}>
        {icon || (
          <UpdateIconFactory
            withCompany={withCompany}
            withPortfolio={withPortfolio}
            updateType={updateType}
            setHoverState={setHoverState}
            update={update}
            onClickIcon={onClickIcon}
            hoverState={isDraftEmail && hoverState}
            checkState={isDraftEmail && checkState}
            selectionMode={selectionMode}
            updateFeedIcon
            isDraftUpdate={update.isDraftUpdate}
          />
        )}
      </TimelineItemDot>
      <TimelineItemContent padding={padding} ref={timelineContentRef}>
        {React.Children.map(children, (child) => {
          return (
            child &&
            React.cloneElement(child, {
              onMouseEnter: () => setHoverState(true),
              onMouseLeave: () => setHoverState(false),
              hoverState,
              checkState,
            })
          )
        })}
      </TimelineItemContent>
    </TimelineListItem>
  )
}

TimelineItem.propTypes = {
  children: PropTypes.node.isRequired,
  lineMargin: PropTypes.number,
  withPortfolio: PropTypes.object,
  updateType: PropTypes.string,
  withCompany: PropTypes.object,
  update: PropTypes.object,
  icon: PropTypes.element,
  selectionMode: PropTypes.bool,
  left: PropTypes.string,
  padding: PropTypes.string,
  hideDottedLine: PropTypes.bool,
}

TimelineItem.defaultProps = {
  left: '',
  lineMargin: MARGIN_BOTTOM_ITEMS_IN_PX,
  icon: null,
  withPortfolio: null,
  updateType: null,
  update: null,
  withCompany: {},
  selectionMode: false,
  padding: '',
  hideDottedLine: false,
}

export default TimelineItem
