import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Tabs.module.scss'

const TabContent = ({ id, activeTabId, children, className = undefined }) => {
  return (
    <div
      className={classNames(
        styles.hiddenTab,
        {
          [styles.visibleTab]: id === activeTabId,
        },
        className
      )}
    >
      {children}
    </div>
  )
}

TabContent.propTypes = {
  id: PropTypes.string.isRequired,
  activeTabId: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

TabContent.defaultProps = {
  activeTabId: null,
}

export default TabContent
