import Tooltip from 'components/Tooltip'
import React, { useMemo } from 'react'
import { getInitials } from 'utils/functions/user'
import StackedAvatarListTooltipContent from './components/StackedAvatarListTooltipContent'
import {
  Container,
  AvatarContainer,
  Avatar,
  RemainingAvatarsCount,
} from './StackedAvatarList.styles'

export interface AvatarInfo {
  id: string
  name: string
  image?: string
}

interface StackedAvatarListProps {
  avatars: AvatarInfo[]
  maxAvatars?: number
  withTooltip?: boolean
  tooltipContent?: React.ReactNode
  avatarSize?: string
}

const StackedAvatarList: React.FC<StackedAvatarListProps> = ({
  avatars,
  maxAvatars,
  withTooltip,
  tooltipContent,
  avatarSize,
}) => {
  const tooltipId = useMemo(
    () => avatars.map((avatar) => avatar.id).join('_'),
    [avatars]
  )
  const remaining = useMemo(
    () => (maxAvatars ? avatars.length - maxAvatars : 0),
    [avatars, maxAvatars]
  )
  const avatarsToRender = useMemo(
    () => avatars.slice(0, maxAvatars),
    [avatars, maxAvatars]
  )

  const renderAvatars = () => (
    <Container>
      {avatarsToRender.map((avatar) => (
        <AvatarContainer key={avatar.id}>
          <Avatar
            image={avatar.image}
            initials={getInitials(avatar.name)}
            avatarStyle="avatarCircleXS"
            avatarSize={avatarSize}
          />
        </AvatarContainer>
      ))}
      {remaining > 0 && (
        <RemainingAvatarsCount>+{remaining}</RemainingAvatarsCount>
      )}
    </Container>
  )

  return withTooltip ? (
    <Tooltip
      id={`avatars_${tooltipId}`}
      text={
        tooltipContent || <StackedAvatarListTooltipContent avatars={avatars} />
      }
      place="bottom"
      backgroundColor="transparent"
      delayShow={500}
    >
      {renderAvatars()}
    </Tooltip>
  ) : (
    renderAvatars()
  )
}

export default StackedAvatarList
