import dayjs from 'dayjs'
import { format } from 'date-fns'
import type { IntlShape } from 'react-intl'

export const weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const getGreetingTime = () => {
  const date = dayjs()

  const SPLIT_AFTERNOON = 12
  const SPLIT_EVENING = 17
  const currentHour = parseFloat(date.format('HH'))

  if (currentHour >= SPLIT_AFTERNOON && currentHour <= SPLIT_EVENING) {
    return 'afternoon'
  }
  if (currentHour >= SPLIT_EVENING) {
    return 'evening'
  }
  return 'morning'
}

export const calculateMinTime = (date) => {
  const isToday = dayjs(date).isSame(dayjs(), 'day')
  if (isToday) {
    return new Date()
  }
  return dayjs().startOf('day').toDate()
}

export const DATE_FORMATS = {
  DDMMYYYY: 'DD MMM YYYY', // 16 Jan 2023
  DDMM_YYYY: 'DD MMM, YYYY', // 16 Jan, 2023
  DDMM__YYYY: 'DD MMM. YYYY', // 16 Jan. 2023
  HHMMAA: 'hh:mm aa', // 12:00 AM
  LLL_D_YYYY_HHMMAA: 'LLL. d, yyyy - hh:mm aa', // Jan. 16, 2023 - 12:00 AM
  LLL_D_YYYY: 'LLL. d, yyyy', // Jan. 16, 2023
  DDMM: 'DD MMM', // 16 Jan
  YYYY: 'YYYY', // 2023
}

export const formattedDate = (date, dateFormat = DATE_FORMATS.DDMMYYYY) =>
  dayjs(date).format(dateFormat)

export const getDateIgnoringTimezone = (date) => {
  const newDate = new Date(date)
  const userTimezoneOffset = newDate.getTimezoneOffset() * 60 * 1000

  return new Date(newDate.getTime() + userTimezoneOffset)
}

export const dateHelpers = {
  isToday: (date: Date) => dayjs(date).isToday(),

  isYesterday: (date: Date) => dayjs(date).isYesterday(),

  isCurrentMonth: (date: Date) => dayjs(date).isSame(dayjs(), 'month'),

  isCurrentYear: (date: Date) => dayjs(date).isSame(dayjs(), 'year'),

  isLastMonth: (date: Date) =>
    dayjs(date).isSame(dayjs().subtract(1, 'month'), 'month'),

  isLastYear: (date: Date) =>
    dayjs(date).isSame(dayjs().subtract(1, 'year'), 'year'),

  isLessThanTwoYearsAgo: (date: Date) =>
    dayjs(date).isAfter(dayjs().subtract(2, 'year').startOf('year'), 'year'),
}

export const areSameDates = (date1?: string, date2?: string) => {
  if (!date1 || !date2) {
    return false
  }

  return dayjs(date1).isSame(date2, 'second')
}

export const formatDateLongFormat = (
  date,
  isRelative?: boolean,
  intl?: IntlShape,
  dateFormat: string = DATE_FORMATS.LLL_D_YYYY_HHMMAA,
  relativeDateFormat: string = DATE_FORMATS.HHMMAA
) => {
  if (date && isRelative && intl) {
    if (dateHelpers.isToday(date)) {
      if (relativeDateFormat === DATE_FORMATS.DDMMYYYY) {
        return intl.formatMessage({ id: 'general.today' })
      }

      return `${intl.formatMessage({ id: 'general.today' })} - ${format(
        new Date(date),
        relativeDateFormat
      )}`
    }

    if (dateHelpers.isYesterday(date)) {
      if (relativeDateFormat === DATE_FORMATS.DDMMYYYY) {
        return intl.formatMessage({ id: 'general.yesterday' })
      }

      return `${intl.formatMessage({ id: 'general.yesterday' })} - ${format(
        new Date(date),
        relativeDateFormat
      )}`
    }
  }

  if (date) {
    const dateToFormat = new Date(date)
    return format(dateToFormat, dateFormat)
  }

  return '-'
}

export const getDefaultOrganizationsDate = ({
  date,
  intl,
}: {
  date: Date
  intl: IntlShape
}) => {
  if (dateHelpers.isToday(date)) {
    return intl.formatMessage({ id: 'general.today' })
  }

  if (dateHelpers.isYesterday(date)) {
    return intl.formatMessage({ id: 'general.yesterday' })
  }

  if (dateHelpers.isCurrentMonth(date) || dateHelpers.isLastMonth(date)) {
    if (dateHelpers.isCurrentYear(date)) {
      return dayjs(date).format('MMMM D')
    }

    return dayjs(date).format('MMMM D, YYYY')
  }

  if (dateHelpers.isLessThanTwoYearsAgo(date)) {
    if (dateHelpers.isCurrentYear(date)) {
      return dayjs(date).format('MMMM')
    }
    return dayjs(date).format('MMMM, YYYY')
  }

  return dayjs(date).format('YYYY')
}

export const getQuarterDates = (date: Date) => {
  const year = date.getFullYear()
  const quarter = Math.floor((date.getMonth() + 3) / 3)

  const firstMonthOfQuarter = (quarter - 1) * 3
  const lastMonthOfQuarter = firstMonthOfQuarter + 2
  const firstDayOfQuarter = new Date(year, firstMonthOfQuarter, 1)
  const lastDayOfQuarter = new Date(year, lastMonthOfQuarter + 1, 0)

  return {
    firstDayOfQuarter,
    lastDayOfQuarter,
  }
}

export const getYearDates = (date: Date) => {
  const year = date.getFullYear()

  const firstDayOfYear = new Date(year, 0, 1)
  const lastDayOfYear = new Date(year, 11, 31)

  return {
    firstDayOfYear,
    lastDayOfYear,
  }
}
