import Card from 'components/Card'
import theme from 'utils/theme'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import {
  SkeletonAvatar,
  SkeletonAvatarWithAnimation,
  SkeletonItem,
  SkeletonItemWrapper,
  SkeletonHorizontalSeparator,
} from 'components/Skeletons/Skeletons.styles'
import Separator from 'ui/Separator'
import * as Styles from './SkeletonSelectedMetricsCard.styles'

const SkeletonSelectedMetricsCard = () => {
  const { matches: isMobile } = useMediaQuery(maxSize.md)
  const xAxisItemsToShow = isMobile ? 3 : 6

  return (
    <Card padding="1.4rem 1.6rem" arrowColor={theme.colors.lightGray}>
      <Card.Header padding="1.4rem 0">
        <SkeletonItemWrapper>
          <SkeletonAvatarWithAnimation height="2.6rem" width="2.6rem" />
          <SkeletonItem width="13rem" height="1.8rem" />
        </SkeletonItemWrapper>
      </Card.Header>
      <Styles.CardBody>
        <SkeletonItemWrapper
          justifyContent="space-between"
          alignItems={isMobile ? 'flex-start' : 'center'}
          direction={isMobile ? 'column' : 'row'}
        >
          <SkeletonItem width={isMobile ? '100%' : '13.1rem'} height="1.4rem" />
          <SkeletonItemWrapper gap="0.8rem">
            <SkeletonAvatar height="2.2rem" width="2.2rem" />
            <SkeletonItem width="10rem" height="1.4rem" />
          </SkeletonItemWrapper>
        </SkeletonItemWrapper>
        <Separator height="1.6rem" />
        <SkeletonItemWrapper alignItems="flex-end" gap="1.77rem">
          <SkeletonItem width="2.3rem" height="1.2rem" />
          <SkeletonHorizontalSeparator />
        </SkeletonItemWrapper>
        <Separator height="1.3rem" />
        <SkeletonItemWrapper alignItems="flex-end" gap="1.77rem">
          <SkeletonItem width="2.3rem" height="1.2rem" />
          <SkeletonHorizontalSeparator />
        </SkeletonItemWrapper>
        <Separator height="1.3rem" />
        <SkeletonItemWrapper alignItems="flex-end" gap="1.77rem">
          <SkeletonItem width="2.3rem" height="1.2rem" />
          <SkeletonHorizontalSeparator />
        </SkeletonItemWrapper>
        <Separator height="0.8rem" />
        <SkeletonItemWrapper justifyContent="space-around">
          {[...Array(xAxisItemsToShow)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SkeletonItem key={index} width="2.3rem" height="1.2rem" />
          ))}
        </SkeletonItemWrapper>
      </Styles.CardBody>
    </Card>
  )
}

export default SkeletonSelectedMetricsCard
