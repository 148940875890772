import { createContext, useContext } from 'react'
import { IndexUpdate } from 'utils/types/update'
import { UpdateEntityType } from './types'

export type UpdatesFeedContextType = {
  onShowUpdate: (update: IndexUpdate) => void
  onEditUpdate?: (update: IndexUpdate) => void
  onReshareUpdate?: (update: IndexUpdate) => void
  onDeleteUpdate?: (update: IndexUpdate) => void
  onDiscardSuggestedUpdateSuccess?: (updateIds: string[]) => void
  reshareDisabled?: boolean
  showEntityFeedIcons?: boolean
  entityType: UpdateEntityType
  entityId?: string | string[]
}

export const UpdatesFeedContext = createContext<UpdatesFeedContextType>({
  onShowUpdate: () => {},
  showEntityFeedIcons: false,
  entityType: UpdateEntityType.MY_UPDATES,
})

export const useUpdatesFeedContext = () => {
  return useContext(UpdatesFeedContext)
}
