import React, { useId } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import AsyncSelect from 'react-select/async'

import Tooltip from 'components/Tooltip'
import InputLabel from 'ui/InputLabel'
import { Option } from './components/Option'
import * as Styles from './ShareWithDropdown.styles'
import {
  REACT_SELECT_STYLES,
  ShareWithDropdownProps,
  useShareWithDropdown,
} from './useShareWithDropdown'
import { Input } from './components/Input'
import { Menu } from './components/Menu'
import { NoOptionsMessage } from './components/NoOptionsMessage'
import { DropdownIndicator } from './components/DropdownIndicator'
import { SharedWithPill } from './components/SharedWithPill'
import { Loader } from './components/Loader'

const ShareWithDropdown: React.FC<ShareWithDropdownProps> = ({
  selectedUsers,
  label,
  maxMenuHeight,
  placeholder,
  isDisabled,
  loadOptions,
  onClose,
}) => {
  const intl = useIntl()
  const id = useId()

  const {
    selectRef,
    currentSelectedOptions,
    debouncedloadOptions,
    menuIsOpen,
    handleKeyDown,
    onOptionSelected,
    onMenuClose,
    onInputChange,
    onFocus,
  } = useShareWithDropdown({
    selectedUsers,
    loadOptions,
    onClose,
  })

  const selectContent = (
    <AsyncSelect
      ref={selectRef}
      placeholder={
        placeholder ||
        intl.formatMessage({
          id: 'updates.addListsGroupsUsers',
        })
      }
      closeMenuOnSelect={false}
      onInputChange={onInputChange}
      menuIsOpen={menuIsOpen}
      isMulti
      loadOptions={debouncedloadOptions}
      defaultOptions
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      isDisabled={isDisabled}
      components={{
        Input,
        Menu,
        NoOptionsMessage,
        DropdownIndicator,
        Option: Option(selectedUsers),
        MultiValue: SharedWithPill,
        LoadingMessage: Loader,
        IndicatorSeparator: null,
        LoadingIndicator: null,
      }}
      hideSelectedOptions={false}
      onMenuClose={onMenuClose}
      onChange={onOptionSelected}
      value={currentSelectedOptions}
      styles={REACT_SELECT_STYLES}
      classNamePrefix="react-select"
      isClearable={false}
      maxMenuHeight={maxMenuHeight || 405}
      onKeyDown={handleKeyDown}
      onFocus={onFocus}
    />
  )

  const renderContent = () => {
    if (isDisabled) {
      return (
        <Tooltip
          id={id}
          text={<FormattedMessage id="updates.unverifiedUserMessage" />}
          place="bottom"
          delayShow={500}
          event="mouseover mouseenter"
          eventOff="mouseleave mouseout scroll mousewheel blur"
          maxWidth="31rem"
          margin="-2rem 0 0 0"
        >
          {selectContent}
        </Tooltip>
      )
    }

    return selectContent
  }

  return (
    <Styles.Container data-testid="share-with-dropdown">
      {label && <InputLabel>{label}</InputLabel>}
      {renderContent()}
    </Styles.Container>
  )
}

export default ShareWithDropdown
