import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const InputIconContainer = styled.div`
  position: relative;
  width: 100%;
`
export const Input = styled.input`
  background-color: ${color('lightGray', 0.1)};
  font-size: 1.4rem;
  height: 3.8rem;
  padding: 0 1.5rem;
  width: 100%;
  border-radius: 0.8rem;
  padding-right: 3rem;
  transition: all 0.2s;
  &::placeholder {
    color: ${color('darkGray', 0.5)};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 0 0 0.1rem ${color('veryLightBlue')};
  }

  /* Secondary Styles */
  ${(props) =>
    props.secondary
      ? css`
          background: ${color('lightGray', 0.1)};
        `
      : ''}

  ${(props) =>
    props.whiteBackgroundOnFocus
      ? css`
          &:focus {
            background: ${color('white')};
            border: 0.1rem solid ${color('primaryBlue')};
          }
        `
      : ''}
`

export const IconButton = styled.button`
  position: absolute;
  right: 0.1rem;
  top: 0.1rem;
  height: 100%;
  width: 3rem;

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    font-size: 1.8rem;
    color: ${color('primaryBlue')};
    transform: translate(-1.1rem, -50%);
  }
`
