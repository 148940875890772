import { Dispatch, SetStateAction, useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Filter } from 'utils/functions/normalizers/investmentFilterNormalizer'
import {
  useAllFiltersQuery,
  useDeleteFilterMutation,
} from 'utils/hooks/queries/useInvestmentsFiltersQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { useHistory, useParams } from 'react-router-dom'
import { checkItemInLocalStorage, normalizeFilters } from './helpers'
import {
  ALL_ENTITIES_FILTER_OPTION,
  BaseOption,
  FILTER_TYPE_NOT_FOUND,
  FilterTypeReturn,
  INVESTMENT_FILTER_TYPE,
} from './types'

interface Props {
  setDisplayBigPortfolioNavigation: Dispatch<SetStateAction<boolean>>
  setPortfolioTypeFilterOptions: Dispatch<SetStateAction<BaseOption[]>>
}

const useFilterInvestments = ({
  setDisplayBigPortfolioNavigation,
  setPortfolioTypeFilterOptions,
}: Props) => {
  const history = useHistory()
  const { id: filterId } = useParams<{ id: string }>()

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const {
    data: createdFilters,
    isLoading: isLoadingFilters,
    refetch: refetchingFilters,
    isRefetching: isRefetchingFilters,
  } = useAllFiltersQuery({
    enabled: !isInvestorGroup, // TODO LP PROFILE: remove this when the filters are implemented for the investor group
  })

  const { mutate: handleDeleteFilter } = useDeleteFilterMutation()

  const normalizedFilters = useMemo(() => {
    const filterOptions = isEmpty(createdFilters)
      ? []
      : (createdFilters as Filter[])?.map((filter) => normalizeFilters(filter))
    return filterOptions
  }, [createdFilters])

  const onInvestmentFilterCreated = async (createdFilter: Filter) => {
    setDisplayBigPortfolioNavigation(false)
    setPortfolioTypeFilterOptions(ALL_ENTITIES_FILTER_OPTION)

    await refetchingFilters()

    const newNormalizedFilter = normalizeFilters(createdFilter)
    history.push(newNormalizedFilter.url)
  }

  const onFilterDeleted = () => {
    history.push('/investments')
    handleDeleteFilter(filterId)
  }

  const hasUnsavedFilter = checkItemInLocalStorage('unsavedFilter')

  const filterNavigationOptions = useMemo(() => {
    if (!normalizedFilters) return []

    return normalizedFilters
  }, [normalizedFilters])

  const filterType: FilterTypeReturn = useMemo(() => {
    if (isEmpty(createdFilters)) return FILTER_TYPE_NOT_FOUND
    return INVESTMENT_FILTER_TYPE
  }, [createdFilters])

  return {
    isLoadingFilters,
    normalizedFilters,
    createdFilters: createdFilters as Filter[],
    filterNavigationOptions,
    hasUnsavedFilter,
    filterType,
    onFilterDeleted,
    onInvestmentFilterCreated,
    isRefetchingFilters,
  }
}

export default useFilterInvestments
