import { useEffect, useState, useMemo, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useAppSelector, useAppDispatch } from 'utils/hooks/reduxToolkit'
import {
  getCurrentGroupType,
  isActingAsInvestorGroup,
  getCurrentGroupData,
} from 'selectors/auth'
import { getHasChatFeature } from 'selectors/chat'
import { useMediaQuery, Media } from 'utils/hooks/useMediaQuery'
import { getSidebarOptions } from 'utils/functions/sideBar'
import UniversityButton from 'containers/University/UniversityButton'
import UniversitySidebar from 'containers/University/UniversitySidebar'
import { useFetchUniversity } from 'utils/hooks/useFetchUniversity'
import { setUniversityEnabled } from 'actions/university'
import { GroupTypes } from 'utils/constants/groups'
import { InvestorGroup } from 'utils/types/user'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import { size } from 'utils/constants/breakpoint'
import { switchMainSidebarOpen } from 'features/sideBarSlice'
import {
  SetRouteConfigurationPayload,
  getCurrentBaseRoute,
  setRouteConfiguration,
} from 'reducers/breadcrumbSlice'

import SideBarItem from '../SideBarItem'
import GroupSelector from '../GroupSelector'
import IntercomSidebarButton from '../IntercomSidebarButton/IntercomSidebarButton'
import UserSettings from '../UserSettings/UserSettings'
import NavBar from '../NavBar/NavBar'
import SecondarySideBarSettings from '../SecondarySideBar/SecondarySideBarSettings'
import SecondarySideBarGroupSelector from '../GroupSelector/components/SecondarySideBarGroupSelector'
import {
  SideBarWrapper,
  SidebarContainer,
  HelpContainer,
  SidebarItems,
  SidebarWrapper,
  Divider,
} from './SideBar.styles'
import NewFeaturesButton from '../NewFeaturesButton'
import AccountManaged from '../AccountManaged'
import UpgradeYourAccountButton from '../UpgradeYourAccountButton'
import UpgradeYourAccountModal from '../UpgradeYourAccountModal'

const Sidebar = () => {
  const intl = useIntl()
  const currentGroupType = useAppSelector(getCurrentGroupType)
  const baseRoute = useAppSelector(getCurrentBaseRoute)
  const currentGroup = useAppSelector(getCurrentGroupData)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const [showGroupSelector, setShowGroupSelector] = useState(false)
  const [primarySidebarOpen, setPrimarySidebarOpen] = useState(false)
  const [isUniversityOpen, setIsUniversityOpen] = useState(false)
  const [showUpgradeYourAccount, setShowUpgradeYourAccount] = useState(false)
  const [isGroupsSectionOpen, setIsGroupsSectionOpen] = useState(false)
  const secondarySidebarOpenMatch = useRouteMatch('/settings')
  const { matches: isDesktop } = useMediaQuery(size.lg)
  const { matches: isMobile } = useMediaQuery(Media.MAX_MD)
  const { universityActive, fetchUniversity } = useFetchUniversity()
  const hasChatFeature = useAppSelector(getHasChatFeature)
  const planFeatures = usePlanFeatures()
  const dispatch = useAppDispatch()
  const toggleSidebar = useCallback(() => {
    setPrimarySidebarOpen((open) => {
      dispatch(switchMainSidebarOpen(!open))
      return !open
    })
  }, [dispatch])

  const handleDispatchUserRoutes = useCallback(
    ({ route, initialLinkRedirect }: SetRouteConfigurationPayload) => {
      dispatch(
        setRouteConfiguration({
          route,
          initialLinkRedirect,
        })
      )
    },
    [dispatch]
  )

  useEffect(() => {
    if (isMobile) {
      dispatch(setUniversityEnabled(false))
    } else {
      dispatch(setUniversityEnabled(currentGroupType === GroupTypes.CLIENT))
    }
  }, [currentGroupType, dispatch, isMobile])

  useEffect(() => {
    if (!primarySidebarOpen) {
      setShowGroupSelector(false)
    }
  }, [primarySidebarOpen])

  useEffect(() => {
    fetchUniversity()
  }, [fetchUniversity])

  const sidebarOptions = useMemo(() => {
    return getSidebarOptions(
      currentGroupType!,
      intl,
      !!hasChatFeature,
      planFeatures,
      baseRoute,
      handleDispatchUserRoutes
    )
  }, [
    currentGroupType,
    intl,
    hasChatFeature,
    planFeatures,
    baseRoute,
    handleDispatchUserRoutes,
  ])

  const onToggleGroupSectionHandler = useCallback((isOpen) => {
    setIsGroupsSectionOpen(isOpen)
  }, [])

  return (
    <>
      <SidebarWrapper>
        <NavBar
          isSidebarOpen={primarySidebarOpen}
          toggleSideBar={toggleSidebar}
        />
        <SideBarWrapper isOpen={primarySidebarOpen}>
          <SidebarContainer>
            <SidebarItems>
              <div>
                <GroupSelector
                  isDesktop={isDesktop}
                  onShowSideBarGroupSelector={() => setShowGroupSelector(true)}
                  onToggleGroupSection={onToggleGroupSectionHandler}
                />
                {isInvestorGroup && (
                  <AccountManaged group={currentGroup as InvestorGroup} />
                )}
                {!isGroupsSectionOpen &&
                  sidebarOptions.map((sidebarOption) => (
                    <SideBarItem
                      key={sidebarOption.url}
                      item={sidebarOption}
                      primarySidebarOpen={primarySidebarOpen}
                      toggleSideBar={toggleSidebar}
                    />
                  ))}
              </div>
              {!isGroupsSectionOpen && (
                <div>
                  <HelpContainer>
                    {isInvestorGroup && (
                      <>
                        <UpgradeYourAccountButton
                          handleOpenModal={() =>
                            setShowUpgradeYourAccount((currState) => !currState)
                          }
                        />
                        <Divider />
                      </>
                    )}
                    <IntercomSidebarButton toggleSidebar={toggleSidebar} />
                    {!isMobile && <NewFeaturesButton />}
                  </HelpContainer>
                </div>
              )}
            </SidebarItems>
            {!isGroupsSectionOpen && (
              <div>
                {universityActive && (
                  <UniversityButton
                    handleOpenUniversity={() =>
                      setIsUniversityOpen((currState) => !currState)
                    }
                    isUniversityOpen={isUniversityOpen}
                  />
                )}
                <UserSettings />
              </div>
            )}
          </SidebarContainer>

          <SecondarySideBarSettings
            isOpen={!!secondarySidebarOpenMatch}
            primarySidebarOpen={primarySidebarOpen}
            togglePrimarySidebar={toggleSidebar}
          />

          <SecondarySideBarGroupSelector
            isOpen={showGroupSelector}
            onClose={() => setShowGroupSelector(false)}
            toggleSidebar={toggleSidebar}
          />
        </SideBarWrapper>
      </SidebarWrapper>
      {universityActive && (
        <UniversitySidebar
          isUniversityOpen={isUniversityOpen}
          closeUniversity={() => setIsUniversityOpen(false)}
        />
      )}
      {showUpgradeYourAccount && (
        <UpgradeYourAccountModal
          isOpen={showUpgradeYourAccount}
          onClose={() => setShowUpgradeYourAccount(false)}
        />
      )}
    </>
  )
}

export default Sidebar
