import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import ConfirmationModal from 'components/ConfirmationModal'
import { SuggestedUpdate } from 'utils/types/update'

import { Body } from './DiscardSuggestedUpdateModal.styles'
import DiscardForm, { useDiscardForm } from '../DiscardForm'

interface DiscardSuggestedUpdateModalProps {
  onHide: () => void
  isOpen: boolean
  suggestedUpdates: SuggestedUpdate[]
}

const DiscardSuggestedUpdateModal = ({
  onHide,
  isOpen,
  suggestedUpdates,
}: DiscardSuggestedUpdateModalProps) => {
  const intl = useIntl()
  const discardFormController = useDiscardForm({
    onDiscardSuccess: onHide,
    isDiscardingMultipleUpdates: suggestedUpdates.length > 1,
  })
  const { onSubmit, formikRef, isFormValid, isLoading } = discardFormController

  const onHideModal = useCallback(() => {
    if (!formikRef.current?.isSubmitting) {
      onHide()
    }
  }, [formikRef, onHide])

  return (
    <ConfirmationModal
      dangerConfirm
      confirmMessage={intl.formatMessage({ id: 'general.discard' })}
      title={intl.formatMessage({ id: 'suggestedUpdates.discardModal.title' })}
      body={
        <Body>
          <DiscardForm
            suggestedUpdates={suggestedUpdates}
            discardFormController={discardFormController}
          />
        </Body>
      }
      onHide={onHideModal}
      onConfirm={onSubmit}
      isOpen={isOpen}
      hasIcon={false}
      confirmDisabled={!isFormValid || isLoading}
      isLoading={isLoading}
      preventHideOnConfirm
    />
  )
}

export default DiscardSuggestedUpdateModal
