import React from 'react'

import { AccessType } from 'utils/types/updateForm'
import Dropdown from 'ui/Dropdown'
import * as Styles from './ShareWithEntities.styles'

const NO_ACCESS = {
  id: AccessType.NO_ACCESS,
  label: 'No access',
}

const CAN_EDIT = {
  id: AccessType.CAN_EDIT,
  label: 'Can edit',
}

const CAN_VIEW = {
  id: AccessType.CAN_VIEW,
  label: 'Can view',
}

const GROUP_ACCESS_OPTIONS = [NO_ACCESS, CAN_EDIT, CAN_VIEW]
const COMMUNITY_ACCESS_OPTIONS = [NO_ACCESS, CAN_VIEW]

interface AccessDropdownProps {
  access: AccessType
  onAccessChange: (access: AccessType) => void
  disabled?: boolean
  isPublic?: boolean
  isInvestorTransaction?: boolean
}

const AccessDropdown: React.FC<AccessDropdownProps> = ({
  access,
  onAccessChange,
  isPublic,
  isInvestorTransaction,
  disabled,
}) => {
  const dropdownName = `${isPublic ? 'cwuniverse' : 'group'}-access-dropdown`
  const ACCESS_OPTIONS =
    isPublic || isInvestorTransaction
      ? COMMUNITY_ACCESS_OPTIONS
      : GROUP_ACCESS_OPTIONS

  return (
    <Styles.DropdownContainer disabled={!!disabled}>
      <Dropdown
        id={dropdownName}
        name={dropdownName}
        data-testid={dropdownName}
        backgroundColor="white"
        borderOnFocus="1px solid transparent"
        padding="0.8rem"
        options={ACCESS_OPTIONS}
        getOption={(option) => option}
        value={access}
        onSelectOption={(_, __, selectedOption) => {
          onAccessChange(selectedOption.id)
        }}
        type="select"
        disabled={disabled}
        iconFontSize="2.3rem"
        minWidth="10rem"
        showAngleIcon
        minContentWidth="10rem"
        iconOnLeft
        attachToDocument
        dropdownListWidth="10rem"
        dropdownContentWidth="10rem"
      />
    </Styles.DropdownContainer>
  )
}

export default AccessDropdown
