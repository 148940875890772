import styled from 'styled-components'
import { motion } from 'framer-motion'
import { color } from 'utils/functions/colors'

export const ReshareHistoryList = styled(motion.ul)`
  margin-top: 2.4rem;
`
export const ReshareHistoryListItem = styled(motion.li)`
  margin-bottom: 1.6rem;
`

export const ReshareRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.8rem;
  padding-left: 0.8rem;
`

export const ReshareRowTitleContainer = styled.div`
  display: flex;
  color: ${color('darkBlue')};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 17px;
  gap: 0.4rem;
`
export const MessageTitle = styled.div`
  max-width: 94%;
`

export const ReshareRowDate = styled.p`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0.4rem 0;
`

export const ReshareRowContent = styled.div`
  width: 100%;
`

export const ReshareMessage = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  color: ${color('lightGray')};
`

export const GroupLogoContainer = styled.div`
  width: 2.1rem;
`
export const EditReshareContainer = styled.div`
  position: relative;
  button {
    position: absolute;
    left: 0.2rem;
    top: -1.2rem;
  }
`

export const ReshareAvatarIcon = styled.div`
  width: 2.1rem;
  height: 2.1rem;
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 100%;
  background-color: ${color('lightGray')};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: white;
    font-size: 1.2rem;
  }
`
