import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from 'components/AvatarSVG'
import { OnboardingStep } from 'components/OnboardingModal/useOnboardingModal'
import { useIntl } from 'react-intl'

import {
  Container,
  IconContainer,
  StepContainer,
  StepTitle,
} from './StepsSelector.styles'

interface StepSelectorProps {
  currentStep: OnboardingStep
  completedSteps: Record<OnboardingStep, boolean>
  jumpToStep: (step: OnboardingStep) => void
}

const StepsSelector = ({
  currentStep,
  completedSteps,
  jumpToStep,
}: StepSelectorProps) => {
  const intl = useIntl()

  const renderStep = (step: OnboardingStep, icon: IconProp, text: string) => {
    const isSelected = currentStep === step
    const isCompleted = completedSteps[step]

    return (
      <StepContainer selected={isSelected} onClick={() => jumpToStep(step)}>
        <Avatar size="3.6rem" borderWidth={isSelected || isCompleted ? 0 : 1}>
          <IconContainer selected={isSelected} completed={isCompleted}>
            {isCompleted ? (
              <FontAwesomeIcon icon={['far', 'check']} />
            ) : (
              <FontAwesomeIcon icon={icon} />
            )}
          </IconContainer>
        </Avatar>
        <StepTitle completed={isCompleted} selected={isSelected}>
          {text}
        </StepTitle>
      </StepContainer>
    )
  }

  return (
    <Container>
      {renderStep(
        OnboardingStep.SETUP_YOUR_PIPELINE,
        ['far', 'briefcase'],
        intl.formatMessage({ id: 'onboardingModal.setupYourPipeline' })
      )}
      {renderStep(
        OnboardingStep.ADD_YOUR_FILES,
        ['far', 'folder'],
        intl.formatMessage({ id: 'onboardingModal.addYourFiles' })
      )}
      {renderStep(
        OnboardingStep.SETUP_YOUR_INBOUND,
        ['far', 'cog'],
        intl.formatMessage({ id: 'onboardingModal.setupYourInbound' })
      )}
    </Container>
  )
}

export default StepsSelector
