import React, { Dispatch, SetStateAction } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useIntl } from 'react-intl'

import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import { getSideBarOpenState } from 'features/sideBarSlice'
import { randomId } from 'utils/functions/number'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useCurrentInvestorManagementEntities } from 'utils/hooks/useCurrentInvestorManagementEntities'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import DisableCreateTransactionTooltip from 'components/DisableCreateTransactionTooltip'

import {
  CelesteButton,
  CelesteButtonContainer,
  CelesteButtonContent,
  CelesteMessage,
  DegradedAnimation,
  HelpMessage,
  OptionsContainer,
  OptionsGroup,
  StyledPlusIcon,
  StyledSparkleIcon,
  Title,
} from './Celeste.styles'
import CelesteActionItem, {
  CelesteActionItemProps,
  CelesteActionType,
} from './components/CelesteActionItem/CelesteActionItem'
import CelesteIcon from './components/CelesteIcon'
import { useCeleste } from './useCeleste'

interface CelesteProps {
  prefixUrl?: string
  isInvestorTransaction?: boolean
  groupsItems?: CelesteActionItemProps[][]
  disabled?: boolean
  onCreatePortfolio?: Dispatch<SetStateAction<boolean>>
  showCreatePortfolioOption?: boolean
  showCreateTransactionOption?: boolean
  readOnlyIntlId?: string
}

const startAnimationState = { opacity: 0.2, bottom: '8.2rem' }
const endAnimationState = { opacity: 1, bottom: '9.2rem' }

const Celeste: React.FC<CelesteProps> = ({
  prefixUrl,
  isInvestorTransaction,
  groupsItems,
  disabled,
  showCreatePortfolioOption,
  showCreateTransactionOption,
  onCreatePortfolio,
  readOnlyIntlId,
}) => {
  const metricsRoute = useMetricsRoute()

  const metricsRouteWithoutSlash = metricsRoute.slice(1)
  const isSideBarOpen = useAppSelector(getSideBarOpenState)
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const shouldShowCeleste = !isMobile || !isSideBarOpen
  const { currentInvestor, currentInvestmentVehicle } =
    useCurrentInvestorManagementEntities()
  const intl = useIntl()
  const {
    isOpen,
    isTransactionVisible,
    isAccountingVisible,
    isAnnouncementVisible,
    isNoteVisible,
    isDocumentVisible,
    isIUEVisible,
    isMetricVisible,
    company,
    isAnimationEnabled,
    handleOnClick,
    getCreateUpdateRouteState,
  } = useCeleste({
    disabled,
    showCreatePortfolioOption,
    showCreateTransactionOption,
  })

  return (
    <>
      {shouldShowCeleste && (
        <AnimatePresence>
          {isOpen && (
            <OptionsContainer
              initial={startAnimationState}
              animate={endAnimationState}
              exit={startAnimationState}
              transition={{ duration: 0.2, ease: 'easeIn' }}
            >
              <CelesteMessage>
                <CelesteIcon />
                <Title>{intl.formatMessage({ id: 'celeste.welcome' })}</Title>
                <HelpMessage>
                  {intl.formatMessage({ id: 'celeste.help' })}
                </HelpMessage>
              </CelesteMessage>

              {groupsItems?.length ? (
                <>
                  {groupsItems.map((group) => (
                    <OptionsGroup key={randomId()} onClick={handleOnClick}>
                      {group.map((action) => (
                        <CelesteActionItem
                          key={action.name}
                          name={action.name}
                          actionType={action.actionType}
                          url={action.url}
                          prefixUrl={prefixUrl}
                          routeState={action.routeState}
                          onClick={action.onClick}
                          disabled={action.disabled}
                        />
                      ))}
                    </OptionsGroup>
                  ))}
                </>
              ) : (
                <OptionsGroup onClick={handleOnClick}>
                  {isAccountingVisible && (
                    <CelesteActionItem
                      name={intl.formatMessage({
                        id: 'updates.createTitles.accounting',
                      })}
                      actionType={CelesteActionType.CREATE_ACCOUNTING_REPORT}
                      url="accounting/new"
                      prefixUrl={prefixUrl}
                      routeState={getCreateUpdateRouteState()}
                    />
                  )}
                  {isDocumentVisible && (
                    <CelesteActionItem
                      name={intl.formatMessage({
                        id: 'updates.createTitles.document',
                      })}
                      actionType={CelesteActionType.CREATE_DOCUMENT}
                      url="documents/new"
                      prefixUrl={prefixUrl}
                      routeState={getCreateUpdateRouteState()}
                    />
                  )}
                  {isNoteVisible && (
                    <CelesteActionItem
                      name={intl.formatMessage({
                        id: 'updates.createTitles.note',
                      })}
                      actionType={CelesteActionType.CREATE_NOTE}
                      url="notes/new"
                      prefixUrl={prefixUrl}
                      routeState={getCreateUpdateRouteState()}
                    />
                  )}

                  {isAnnouncementVisible && (
                    <CelesteActionItem
                      name={intl.formatMessage({
                        id: 'updates.createTitles.announcement',
                      })}
                      actionType={CelesteActionType.CREATE_ANNOUNCEMENT}
                      url="announcements/new"
                      prefixUrl={prefixUrl}
                      routeState={getCreateUpdateRouteState()}
                    />
                  )}
                  {isIUEVisible && (
                    <CelesteActionItem
                      name={intl.formatMessage({
                        id: 'updates.createTitles.email',
                      })}
                      actionType={CelesteActionType.CREATE_EMAIL}
                    />
                  )}
                  {isTransactionVisible && (
                    <DisableCreateTransactionTooltip
                      id="create-transaction-celeste-action"
                      leftOffset={28}
                      topOffset={-10}
                    >
                      <CelesteActionItem
                        name={intl.formatMessage({
                          id: 'updates.createTitles.transaction',
                        })}
                        actionType={CelesteActionType.CREATE_TRANSACTION}
                        url="transactions/new"
                        prefixUrl={prefixUrl}
                        routeState={{
                          investorTransaction:
                            !!currentInvestor ||
                            !!currentInvestmentVehicle ||
                            isInvestorTransaction,
                          investorId:
                            currentInvestor?.id ??
                            currentInvestmentVehicle?.investorGroup?.id,
                          investmentVehicleId: currentInvestmentVehicle?.id,
                          ...getCreateUpdateRouteState(),
                        }}
                      />
                    </DisableCreateTransactionTooltip>
                  )}

                  {isMetricVisible && (
                    <CelesteActionItem
                      name={intl.formatMessage({
                        id: 'metrics.addCompanyMetric',
                      })}
                      actionType={CelesteActionType.ADD_METRIC}
                      url="new"
                      prefixUrl={metricsRouteWithoutSlash}
                      routeState={{ companyToAdd: company }}
                      isMetricsOption
                    />
                  )}

                  {showCreatePortfolioOption && onCreatePortfolio && (
                    <CelesteActionItem
                      name={intl.formatMessage({
                        id: 'portfolios.newPortfolio',
                      })}
                      onClick={() => onCreatePortfolio(true)}
                      actionType={CelesteActionType.CREATE_PORTFOLIO}
                      url="new"
                    />
                  )}
                </OptionsGroup>
              )}
            </OptionsContainer>
          )}
        </AnimatePresence>
      )}
      <CelesteButtonContainer isSideBarOpen={isSideBarOpen}>
        <ReadOnlyTooltip
          id="celesteReadOnlyTooltip"
          text={intl.formatMessage({
            id: readOnlyIntlId || 'readOnlyMode.addUpdates',
          })}
          disabled={undefined}
          place="left"
        >
          <CelesteButton
            isOpen={isOpen}
            isAnimationEnabled={isAnimationEnabled}
            onClick={handleOnClick}
            isDisabled={disabled}
          >
            <CelesteButtonContent
              disabled={disabled}
              isOpen={isOpen}
              isAnimationEnabled={isAnimationEnabled}
            />
            <StyledSparkleIcon
              isOpen={isOpen}
              isAnimationEnabled={isAnimationEnabled}
            />
            <StyledPlusIcon
              isOpen={isOpen}
              isAnimationEnabled={isAnimationEnabled}
              icon={['fal', 'plus']}
            />
          </CelesteButton>
          <DegradedAnimation
            isOpen={isOpen}
            isAnimationEnabled={isAnimationEnabled}
          />
        </ReadOnlyTooltip>
      </CelesteButtonContainer>
    </>
  )
}

export default Celeste
