import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const SortContainer = styled.div<{
  padding?: string
  showMobileVersion?: boolean
}>`
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding || '1.3rem 2rem 1rem 2rem'};

  .dropdown-button {
    gap: 0.8rem;
    height: 3.8rem;
    background-color: transparent;

    svg {
      color: ${color('darkBlue')};
    }

    ${(props) =>
      props.showMobileVersion &&
      css`
        font-size: 1.8rem;
        height: 4rem;
        width: 4rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
  }

  .dropdown-list {
    margin-top: 0;
  }

  &:hover {
    .selected-option,
    .dropdown-icons {
      color: ${color('primaryBlue')};
      * {
        color: ${color('primaryBlue')};
      }
    }
  }
`

export const FormattedSelectedOption = styled.div`
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.7rem;
`

export const SortByLabel = styled.span`
  color: ${color('lightGray')};
  display: inline-block;
  margin-right: 5px;
`
