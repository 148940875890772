import { useHistory, useParams } from 'react-router-dom'
import Drawer from 'components/Drawer'
import FileDetailsSkeleton from 'components/Skeletons/Files/Drawer/FileDetailsSkeleton'
import Preview from './Preview'
import Details from './Details'
import Updates from './Updates'
import useFileDetails from './useFileDetails'

interface FileDetailsProps {
  fileId?: string
  isOpen?: boolean
  onClose?: () => void
}

const FileDetails: React.FC<FileDetailsProps> = ({
  fileId,
  isOpen = true,
  onClose,
}) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, contentData, handlers, aboutEntities } = useFileDetails(
    fileId || id
  )

  const onCloseDrawer = () => {
    if (onClose) {
      onClose()
    } else {
      history.replace('/files')
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      isLoading={isLoading}
      skeleton={<FileDetailsSkeleton />}
      headerTitle={contentData?.fileName}
      onCloseDrawer={onCloseDrawer}
    >
      {contentData && (
        <Drawer.Content contentPadding="1.7rem 2.4rem">
          <Preview
            file={contentData}
            onDownloadFile={handlers.onDownloadFile}
          />
          <Details file={contentData} aboutEntities={aboutEntities} />
          {!contentData?.unreconciled && (
            <Updates
              updates={contentData?.loggingUpdates}
              group={contentData?.group}
            />
          )}
        </Drawer.Content>
      )}
    </Drawer>
  )
}

export default FileDetails
