import AnalyticsService from 'api/AnalyticsService'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { AnalyticsGroupBy } from 'utils/constants/analytics'
import { analyticKeys } from 'utils/queries/analytics'

export const useAnalyticsQuery = (updateId: string) => {
  return useQuery(analyticKeys.fetchAnalytics(updateId), () => {
    return AnalyticsService.fetchAnalytics({
      updateId,
    })
  })
}

type TotalAnalyticsParams = {
  updateId: string
  groupBy: AnalyticsGroupBy
  startDate: Date
  endDate: Date
}

export const useTotalAnalyticsQuery = ({
  updateId,
  groupBy,
  startDate,
  endDate,
}: TotalAnalyticsParams) => {
  const [maxValue, setMaxValue] = useState<number>()

  const queryData = useQuery(
    analyticKeys.fetchTotalAnalytics(updateId, groupBy, startDate, endDate),
    async () => {
      const data = await AnalyticsService.getTotalAnalyticsForUpdate({
        updateId,
        groupBy,
        startDate,
        endDate,
      })
      const max = data.pop()
      if (max) {
        setMaxValue(max[1])
      }
      return data
    }
  )

  return { ...queryData, maxValue }
}

export const useAttachmentAnalyticsQuery = (fileContentId: string) => {
  return useQuery(analyticKeys.contentAnalytics(fileContentId), () => {
    return AnalyticsService.fetchAttachmentAnalytics(fileContentId)
  })
}
