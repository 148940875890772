import React from 'react'

import styles from './DraggableCircles.module.scss'

const BULLETS_ROWS_QTY = 4

const DraggableCircles = () => (
  <div className={styles.draggableWrapper}>
    {Array(BULLETS_ROWS_QTY)
      .fill()
      .map(Number.call, Number)
      .map((bulletNumber) => (
        <div key={bulletNumber} className={styles.draggableBullets}>
          <div className={styles.bullet} />
          <div className={styles.bullet} />
        </div>
      ))}
  </div>
)

export default DraggableCircles
