import { PortfolioType } from 'api/InvestorManagementService'
import Drawer from 'components/Drawer'
import Logs from 'containers/Logs/Logs'
import { PortfolioLogChangeSetFields } from 'containers/Logs/models/logs-builders/PortfolioLogsBuilder'
import { LogType } from 'containers/Logs/models/types'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

const PortfolioLogs = () => {
  const { id, type } = useParams<{ id: string; type: PortfolioType }>()

  const history = useHistory()
  const intl = useIntl()

  return (
    <Drawer
      isOpen
      onCloseDrawer={() => history.goBack()}
      headerTitle={intl.formatMessage({ id: 'logs.portfolios.title' })}
    >
      <Drawer.Content>
        <Logs<PortfolioLogChangeSetFields>
          entityId={id}
          logType={`${type}_portfolios` as LogType}
        />
      </Drawer.Content>
    </Drawer>
  )
}

export default PortfolioLogs
