export const generateUpdatePaths = (basePath: string) => [
  `${basePath}/:updateType/:updateId`,
  `${basePath}/:updateType/:updateId/edit`,
  `${basePath}/:updateType/:updateId/preview-draft`,
  `${basePath}/:updateType/new`,
  `${basePath}/:updateType/new/:updateId`,
]

export const generateProfileUniquePaths = (basePath: string) => [
  `${basePath}`,
  `${basePath}/edit`,

  `${basePath}/:anyTab`,
  `${basePath}/:anyTab/edit`,
  `${basePath}/:anyTab/:anyOtherRouteOrId`,
  `${basePath}/:anyTab/:anyOtherRouteOrId/:anyOtherRouteAfterId`,
]

export const generateProfilePaths = (basePath: string) => [
  ...generateProfileUniquePaths(basePath),
  ...generateUpdatePaths(`${basePath}/all-updates`),
]

export const generatePaths = (basePaths: string[]) =>
  basePaths.flatMap(generateProfilePaths)
