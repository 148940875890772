/**
 * Taken from https://javascript.plainenglish.io/content-diff-view-in-vanilla-javascript-105a00abd7ce
 * Which was converted to JS from CoffeeScript from https://github.com/tnwinc/htmldiff.js/blob/master/src/htmldiff.coffee
 * Slightly modified to fix/ignore eslint warnings
 */

/* eslint-disable no-constant-condition */
/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
/* eslint-disable no-multi-assign */

const is_end_of_tag = (char) => char === '>'

const is_start_of_tag = (char) => char === '<'

const is_whitespace = (char) => /^\s+$/.test(char)

const is_tag = (token) => /^\s*<[^>]+>\s*$/.test(token)

const isnt_tag = (token) => !is_tag(token)

class Match {
  constructor(start_in_before1, start_in_after1, length1) {
    this.start_in_before = start_in_before1
    this.start_in_after = start_in_after1
    this.length = length1
    this.end_in_before = this.start_in_before + this.length - 1
    this.end_in_after = this.start_in_after + this.length - 1
  }
}

const html_to_tokens = (html) => {
  let char
  let current_word
  let i
  let len
  let mode
  mode = 'char'
  current_word = ''
  const words = []

  for (i = 0, len = html.length; i < len; i++) {
    char = html[i]
    switch (mode) {
      case 'tag':
        if (is_end_of_tag(char)) {
          current_word += '>'
          words.push(current_word)
          current_word = ''
          if (is_whitespace(char)) {
            mode = 'whitespace'
          } else {
            mode = 'char'
          }
        } else {
          current_word += char
        }
        break
      case 'char':
        if (is_start_of_tag(char)) {
          if (current_word) {
            words.push(current_word)
          }
          current_word = '<'
          mode = 'tag'
        } else if (/\s/.test(char)) {
          if (current_word) {
            words.push(current_word)
          }
          current_word = char
          mode = 'whitespace'
        } else if (/[\w#@]+/i.test(char)) {
          current_word += char
        } else {
          if (current_word) {
            words.push(current_word)
          }
          current_word = char
        }
        break
      case 'whitespace':
        if (is_start_of_tag(char)) {
          if (current_word) {
            words.push(current_word)
          }
          current_word = '<'
          mode = 'tag'
        } else if (is_whitespace(char)) {
          current_word += char
        } else {
          if (current_word) {
            words.push(current_word)
          }
          current_word = char
          mode = 'char'
        }
        break
      default:
        throw new Error(`Unknown mode ${mode}`)
    }
  }

  if (current_word) {
    words.push(current_word)
  }

  return words
}

const find_match = (
  before_tokens,
  after_tokens,
  index_of_before_locations_in_after_tokens,
  start_in_before,
  end_in_before,
  start_in_after,
  end_in_after
) => {
  let best_match_in_after
  let best_match_in_before
  let best_match_length
  let i
  let index_in_after
  let index_in_before
  let j
  let len
  let locations_in_after
  let looking_for
  let match
  let match_length_at
  let new_match_length
  let new_match_length_at
  let ref
  let ref1
  best_match_in_before = start_in_before
  best_match_in_after = start_in_after
  best_match_length = 0
  match_length_at = {}
  for (
    index_in_before = i = ref = start_in_before, ref1 = end_in_before;
    ref <= ref1 ? i < ref1 : i > ref1;
    index_in_before = ref <= ref1 ? ++i : --i
  ) {
    new_match_length_at = {}
    looking_for = before_tokens[index_in_before]
    locations_in_after = index_of_before_locations_in_after_tokens[looking_for]
    for (j = 0, len = locations_in_after.length; j < len; j++) {
      index_in_after = locations_in_after[j]
      if (index_in_after < start_in_after) {
        continue
      }
      if (index_in_after >= end_in_after) {
        break
      }
      if (match_length_at[index_in_after - 1] == null) {
        match_length_at[index_in_after - 1] = 0
      }
      new_match_length = match_length_at[index_in_after - 1] + 1
      new_match_length_at[index_in_after] = new_match_length
      if (new_match_length > best_match_length) {
        best_match_in_before = index_in_before - new_match_length + 1
        best_match_in_after = index_in_after - new_match_length + 1
        best_match_length = new_match_length
      }
    }
    match_length_at = new_match_length_at
  }
  if (best_match_length !== 0) {
    match = new Match(
      best_match_in_before,
      best_match_in_after,
      best_match_length
    )
  }
  return match
}
const recursively_find_matching_blocks = (
  before_tokens,
  after_tokens,
  index_of_before_locations_in_after_tokens,
  start_in_before,
  end_in_before,
  start_in_after,
  end_in_after,
  matching_blocks
) => {
  const match = find_match(
    before_tokens,
    after_tokens,
    index_of_before_locations_in_after_tokens,
    start_in_before,
    end_in_before,
    start_in_after,
    end_in_after
  )
  if (match != null) {
    if (
      start_in_before < match.start_in_before &&
      start_in_after < match.start_in_after
    ) {
      recursively_find_matching_blocks(
        before_tokens,
        after_tokens,
        index_of_before_locations_in_after_tokens,
        start_in_before,
        match.start_in_before,
        start_in_after,
        match.start_in_after,
        matching_blocks
      )
    }
    matching_blocks.push(match)
    if (
      match.end_in_before <= end_in_before &&
      match.end_in_after <= end_in_after
    ) {
      recursively_find_matching_blocks(
        before_tokens,
        after_tokens,
        index_of_before_locations_in_after_tokens,
        match.end_in_before + 1,
        end_in_before,
        match.end_in_after + 1,
        end_in_after,
        matching_blocks
      )
    }
  }
  return matching_blocks
}
const create_index = (p) => {
  let i
  let idx
  let len
  let token
  if (p.find_these == null) {
    throw new Error('params must have find_these key')
  }
  if (p.in_these == null) {
    throw new Error('params must have in_these key')
  }
  const index = {}
  const ref = p.find_these
  for (i = 0, len = ref.length; i < len; i++) {
    token = ref[i]
    index[token] = []
    idx = p.in_these.indexOf(token)
    while (idx !== -1) {
      index[token].push(idx)
      idx = p.in_these.indexOf(token, idx + 1)
    }
  }
  return index
}

const find_matching_blocks = (before_tokens, after_tokens) => {
  const matching_blocks = []
  const index_of_before_locations_in_after_tokens = create_index({
    find_these: before_tokens,
    in_these: after_tokens,
  })
  return recursively_find_matching_blocks(
    before_tokens,
    after_tokens,
    index_of_before_locations_in_after_tokens,
    0,
    before_tokens.length,
    0,
    after_tokens.length,
    matching_blocks
  )
}

const calculate_operations = (before_tokens, after_tokens) => {
  let action_up_to_match_positions
  let i
  let index
  let j
  let last_op
  let len
  let len1
  let match
  let match_starts_at_current_position_in_after
  let match_starts_at_current_position_in_before
  let op
  let position_in_after
  let position_in_before
  if (before_tokens == null) {
    throw new Error('before_tokens?')
  }
  if (after_tokens == null) {
    throw new Error('after_tokens?')
  }
  position_in_before = position_in_after = 0
  const operations = []
  const action_map = {
    'false,false': 'replace',
    'true,false': 'insert',
    'false,true': 'delete',
    'true,true': 'none',
  }
  const matches = find_matching_blocks(before_tokens, after_tokens)
  matches.push(new Match(before_tokens.length, after_tokens.length, 0))
  for (index = i = 0, len = matches.length; i < len; index = ++i) {
    match = matches[index]
    match_starts_at_current_position_in_before =
      position_in_before === match.start_in_before
    match_starts_at_current_position_in_after =
      position_in_after === match.start_in_after
    action_up_to_match_positions =
      action_map[
        [
          match_starts_at_current_position_in_before,
          match_starts_at_current_position_in_after,
        ].toString()
      ]
    if (action_up_to_match_positions !== 'none') {
      operations.push({
        action: action_up_to_match_positions,
        start_in_before: position_in_before,
        end_in_before:
          action_up_to_match_positions !== 'insert'
            ? match.start_in_before - 1
            : undefined,
        start_in_after: position_in_after,
        end_in_after:
          action_up_to_match_positions !== 'delete'
            ? match.start_in_after - 1
            : undefined,
      })
    }
    if (match.length !== 0) {
      operations.push({
        action: 'equal',
        start_in_before: match.start_in_before,
        end_in_before: match.end_in_before,
        start_in_after: match.start_in_after,
        end_in_after: match.end_in_after,
      })
    }
    position_in_before = match.end_in_before + 1
    position_in_after = match.end_in_after + 1
  }
  const post_processed = []
  last_op = {
    action: 'none',
  }

  const is_single_whitespace = (operation) => {
    if (operation.action !== 'equal') {
      return false
    }
    if (operation.end_in_before - operation.start_in_before !== 0) {
      return false
    }
    return /^\s$/.test(
      before_tokens.slice(
        operation.start_in_before,
        +operation.end_in_before + 1 || 9e9
      )
    )
  }

  for (j = 0, len1 = operations.length; j < len1; j++) {
    op = operations[j]
    if (
      (is_single_whitespace(op) && last_op.action === 'replace') ||
      (op.action === 'replace' && last_op.action === 'replace')
    ) {
      last_op.end_in_before = op.end_in_before
      last_op.end_in_after = op.end_in_after
    } else {
      post_processed.push(op)
      last_op = op
    }
  }
  return post_processed
}
const consecutive_where = (start, content, predicate) => {
  let answer
  let i
  let index
  let last_matching_index
  let len
  let token
  content = content.slice(start, +content.length + 1 || 9e9)
  last_matching_index = undefined
  for (index = i = 0, len = content.length; i < len; index = ++i) {
    token = content[index]
    answer = predicate(token)
    if (answer === true) {
      last_matching_index = index
    }
    if (answer === false) {
      break
    }
  }
  if (last_matching_index != null) {
    return content.slice(0, +last_matching_index + 1 || 9e9)
  }
  return []
}
const wrap = (tag, content) => {
  let non_tags
  let position
  let rendering
  let tags
  rendering = ''
  position = 0
  const { length } = content
  while (true) {
    if (position >= length) {
      break
    }
    non_tags = consecutive_where(position, content, isnt_tag)
    position += non_tags.length
    if (non_tags.length !== 0) {
      rendering += `<${tag}>${non_tags.join('')}</${tag}>`
    }
    if (position >= length) {
      break
    }
    tags = consecutive_where(position, content, is_tag)
    position += tags.length
    rendering += tags.join('')
  }
  return rendering
}
const op_map = {
  equal(op, before_tokens, after_tokens) {
    return before_tokens
      .slice(op.start_in_before, +op.end_in_before + 1 || 9e9)
      .join('')
  },
  insert(op, before_tokens, after_tokens) {
    const val = after_tokens.slice(
      op.start_in_after,
      +op.end_in_after + 1 || 9e9
    )
    return wrap('ins', val)
  },
  delete(op, before_tokens, after_tokens) {
    const val = before_tokens.slice(
      op.start_in_before,
      +op.end_in_before + 1 || 9e9
    )
    return wrap('del', val)
  },
}

op_map.replace = (op, before_tokens, after_tokens) =>
  op_map.insert(op, before_tokens, after_tokens) +
  op_map.delete(op, before_tokens, after_tokens)

const render_operations = (before_tokens, after_tokens, operations) => {
  let i
  let len
  let op
  let rendering
  rendering = ''
  for (i = 0, len = operations.length; i < len; i++) {
    op = operations[i]
    rendering += op_map[op.action](op, before_tokens, after_tokens)
  }
  return rendering
}

const diff = (before, after) => {
  if (before === after) {
    return before
  }
  before = html_to_tokens(before)
  after = html_to_tokens(after)
  const ops = calculate_operations(before, after)
  return render_operations(before, after, ops)
}

diff.html_to_tokens = html_to_tokens
diff.find_matching_blocks = find_matching_blocks
find_matching_blocks.find_match = find_match
find_matching_blocks.create_index = create_index
diff.calculate_operations = calculate_operations
diff.render_operations = render_operations

export default diff
