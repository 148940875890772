import { UpdateType } from 'utils/types/common'
import { Holding } from 'utils/types/company'
import { FundPortfolioInvestor } from 'utils/types/funds'
import {
  IndexInvestmentVehicle,
  InvestmentVehicle,
  Investor,
} from 'utils/types/investors'
import { Portfolio } from 'utils/types/portfolios'

export interface UpdateRouteState {
  canGoBack?: boolean
  showNavigation?: boolean
  portfolio?: Portfolio
  currentPortfolioGroupId?: string
  company?: Holding
  holding?: Holding
  investmentVehicle?: InvestmentVehicle
  investorTransaction?: boolean
  investor?: Investor
  fundInvestor?: FundPortfolioInvestor
  investorId?: string
  customHeader?: React.ReactNode
  redirectUrl?: string
  isFromFiles?: boolean
  isFromInvestor?: boolean
  isFromUpdates?: boolean
  showInvestorAndFundSelector?: boolean
}

export enum BaseUpdateUrl {
  NOTES = 'notes',
  DOCUMENTS = 'documents',
  ANNOUNCEMENTS = 'announcements',
  TRANSACTIONS = 'transactions',
  EMAILS = 'emails',
  ACCOUNTING = 'accounting',
}

export enum UpdateUrlSuffix {
  EDIT = 'edit',
  RESHARE = 'reshare',
  CREATE = 'new',
}

export type ShowUpdateParams = {
  updateId: string
  updateType: UpdateType
  routeState?: any
  replace?: boolean
}

export type ShowUpdateParamsFromUpdates = ShowUpdateParams & {
  suffix?: UpdateUrlSuffix
  company?: Holding
  portfolio?: Portfolio
  isFromUpdates?: boolean
}

export type ShowUpdateFromInvestmentVehicleParams = ShowUpdateParams & {
  investmentVehicle: IndexInvestmentVehicle
  investorId?: string
  suffix?: UpdateUrlSuffix
}
export type ShowUpdateFromInvestorParams = ShowUpdateParams & {
  investor: Investor
  suffix?: UpdateUrlSuffix
}

export type ShowUpdateFromGroupProfile = ShowUpdateParams & {
  company: Holding
  suffix?: UpdateUrlSuffix
}

export type ShowUpdateFromEmails = Omit<ShowUpdateParams, 'updateType'> & {
  isDraft?: boolean
  edit?: boolean
}

export type ShowUpdateFromPortfolioParams = ShowUpdateParams & {
  portfolio?: Portfolio
  company?: Holding
  suffix?: UpdateUrlSuffix
  portfolioId?: string
  fundInvestor?: FundPortfolioInvestor
  showNavigation?: boolean
}

export type ShowUpdateFromDashboardParams = ShowUpdateParams & {
  company?: Holding
}

export type ShowUpdateFromInvestorGroup = ShowUpdateParams & {
  company: Holding
}

export type ShowUpdateFromCompanyProfileParams = ShowUpdateParams & {
  company: Holding
  suffix?: UpdateUrlSuffix
  isFromUpdates?: boolean
  showNavigation?: boolean
}

export type ShowTransactionFromCompanyProfileParams = ShowUpdateParams & {
  fundId: string
}

export type ShowUpdateWithCustomHeaderParams = ShowUpdateParams & {
  customHeader: React.ReactNode
  redirectUrl: string
}
