export default {
  chat: {
    createdDirectMessage:
      '{userName} created a direct message with you, {members}',
    createdTopicChannel: '{userName} created a #{topicName} ({about})',
    pendingMessages:
      'You have {count, plural, one {1 new message} other {# new messages}} {in}',
    inADirectMessage: 'in a direct message with {members}',
    inATopicChannel: 'in #{topicName} ({about})',
    mentionedYou: '{userName} mentioned you {in}',
    newMessage: 'You have 1 new message {in}',
    addedToDirectMessage:
      '{userName} added you to a direct message with you, {members}',
    addedToTopicChannel: '{userName} added you to #{topicName} ({about})',
    aConversationAbout: 'in a conversation about {about}',
    createdTopicChannelInAConversationAbout:
      '{userName} created a conversation about {about}',
    addedToTopicChannelInAConversationAbout:
      '{userName} added you to a conversation about {about}',
  },
}
