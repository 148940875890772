import React from 'react'
import { FormattedMessage } from 'react-intl'

import { usePortfolioQuery } from 'utils/hooks/queries/usePortfolioQuery'
import { PortfolioType } from 'utils/types/portfolios'
import PortfolioIcon from 'components/PortfolioIcon'
import * as Styles from './HoldingAttachment.styles'

interface PortfolioAttachmentProps {
  portfolioId: string
  portfolioType: PortfolioType
  link: string
}

const PortfolioAttachment: React.FC<PortfolioAttachmentProps> = ({
  portfolioId,
  portfolioType,
  link,
}) => {
  const { data: portfolio } = usePortfolioQuery(portfolioId, portfolioType)

  if (!portfolio) return null

  return (
    <Styles.Container href={link} target="_blank">
      {!!portfolio && (
        <>
          <PortfolioIcon type={portfolio.type} />
          <div>
            <Styles.AttachmentTitle>{portfolio.name}</Styles.AttachmentTitle>
            <div>
              <FormattedMessage
                id={`chat.portfolios.${portfolio.type.toLowerCase()}`}
              />
            </div>
          </div>
        </>
      )}
    </Styles.Container>
  )
}

export default PortfolioAttachment
