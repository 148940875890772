import type {
  Compatible,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid'
import { DateCellTemplate, getCellProperty } from '@silevis/reactgrid'
import dayjs from 'dayjs'
import DatePicker, { DateFormatter } from './components/SpreadsheetDatePicker'
import { CellContainer, DisabledCell } from './Template.styles'
import { CustomDateCell } from '../types'

export class CustomDateCellTemplate extends DateCellTemplate {
  // eslint-disable-next-line class-methods-use-this
  getCompatibleCell(
    uncertainCell: Uncertain<CustomDateCell>
  ): Compatible<CustomDateCell> {
    const date =
      uncertainCell.date && getCellProperty(uncertainCell, 'date', 'object')
    const value = date ? date.getTime() : NaN
    return {
      ...uncertainCell,
      date,
      value,
      text: '',
      rowIndex: uncertainCell.rowIndex || 0,
    }
  }

  update(
    cell: Compatible<CustomDateCell>,
    cellToMerge: UncertainCompatible<CustomDateCell>
  ): Compatible<CustomDateCell> {
    let date: Date | undefined
    const { value, type } = cellToMerge
    const isPastingFromExcelOrGSheets = type !== 'date'

    try {
      if (isPastingFromExcelOrGSheets) {
        date = new Date(cellToMerge.text)
        date = Number.isNaN(date.getTime()) ? undefined : date
      } else if (!Number.isNaN(value)) {
        date = new Date(value)
      }
    } catch (error) {
      date = undefined
    }

    let error = ''
    if (cell.maxDate && date) {
      const now = new Date()
      date.setHours(now.getHours(), 0, 0, 0)
      cell.maxDate.setHours(now.getHours(), 0, 0, 0)
      const isFutureDate = !!dayjs(date).isAfter(cell.maxDate, 'day')
      error = isFutureDate ? cell.maxDateErrorMsgId || '' : ''
    }

    return this.getCompatibleCell({
      ...cell,
      error,
      date,
    })
  }

  onChange(
    date: Date,
    cell: Compatible<CustomDateCell>,
    onCellChanged: (cell: Compatible<CustomDateCell>, commit: boolean) => void
  ) {
    date.setHours(12, 0, 0, 0)
    onCellChanged(
      this.getCompatibleCell({ ...cell, date, update: true } as CustomDateCell),
      true
    )
  }

  static onPointerDown(isInEditMode: boolean) {
    return (event) => {
      if (isInEditMode) {
        event.stopPropagation()
      }
    }
  }

  render(
    cell: Compatible<CustomDateCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CustomDateCell>, commit: boolean) => void
  ) {
    if (cell.disabled) {
      return <DisabledCell centered={cell.centered}>-</DisabledCell>
    }

    if (!isInEditMode) {
      return (
        <DateFormatter
          date={cell.date ?? null}
          error={cell.error}
          isOptional={cell.optional}
          centered={cell.centered}
          onClear={() =>
            onCellChanged(
              { text: '', type: 'date', value: NaN, rowIndex: cell.rowIndex },
              true
            )
          }
        />
      )
    }

    return (
      <CellContainer
        onPointerDown={CustomDateCellTemplate.onPointerDown(isInEditMode)}
      >
        <DatePicker
          ref={(datePicker) => {
            if (datePicker?.input && isInEditMode) {
              datePicker.input.focus?.()
            }
          }}
          handleChange={(date) => this.onChange(date, cell, onCellChanged)}
          value={cell.date}
          maxDate={cell.maxDate}
        />
      </CellContainer>
    )
  }
}
