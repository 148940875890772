export enum TierClassType {
  LITE = 'Tiers::LiteTier',
  INDIVIDUAL = 'Tiers::BasicTier',
  PRO = 'Tiers::ProTier',
  ORGANIZATION = 'Tiers::OrganizationTier',
}

export type ApiOnboardingSteps = {
  welcome: 'true' | 'false'
  track: 'true' | 'false'
  files: 'true' | 'false'
  inbound: 'true' | 'false'
}

export interface TierApi {
  groupId: string
  id: string
  maxInvestPortfolios: number
  maxStorage: number
  maxTrackPortfolios: number
  maxTransactions: number
  maxUsers: number
  classType: TierClassType
  investPortfolios: number
  storage: number
  trackPortfolios: number
  transactions: number
  users: number
  onboarding: { onboarding: ApiOnboardingSteps }
}
