import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CellWithTooltip = styled.div`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  color: ${color('darkGray')};
`

export const TooltipTitle = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
`

export const TooltipParagraph = styled.p`
  font-size: 1.2rem;

  > a {
    font-weight: 700;
    text-decoration: underline;
  }
`
