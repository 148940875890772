export const getMockedBalanceSheet = () => {
  return {
    Header: {
      Time: '2021-02-24T09:52:57-08:00',
      ReportName: 'BalanceSheet',
      ReportBasis: 'Accrual',
      StartPeriod: '2018-01-01',
      EndPeriod: '2021-02-28',
      SummarizeColumnsBy: 'Year',
      Currency: 'USD',
      Option: [
        {
          Name: 'AccountingStandard',
          Value: 'GAAP',
        },
        {
          Name: 'NoReportData',
          Value: 'false',
        },
      ],
    },
    Columns: {
      Column: [
        {
          ColTitle: '',
          ColType: 'Account',
          MetaData: [
            {
              Name: 'ColKey',
              Value: 'account',
            },
          ],
        },
        {
          ColTitle: 'Jan - Dec 2018',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2018-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2018-12-31',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Dec 2018',
            },
          ],
        },
        {
          ColTitle: 'Jan - Dec 2019',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2019-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2019-12-31',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Dec 2019',
            },
          ],
        },
        {
          ColTitle: 'Jan - Dec 2020',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2020-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2020-12-31',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Dec 2020',
            },
          ],
        },
        {
          ColTitle: 'Jan - Feb, 2021',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2021-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2021-02-28',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Feb, 2021',
            },
          ],
        },
      ],
    },
    Rows: {
      Row: [
        {
          Header: {
            ColData: [
              {
                value: 'ASSETS',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
            ],
          },
          Rows: {
            Row: [
              {
                Header: {
                  ColData: [
                    {
                      value: 'Current Assets',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Bank Accounts',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Checking',
                                id: '35',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '4321.40',
                              },
                              {
                                value: '1201.00',
                              },
                            ],
                            type: 'Data',
                          },
                          {
                            ColData: [
                              {
                                value: 'Savings',
                                id: '36',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '800.00',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Bank Accounts',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '4321.40',
                          },
                          {
                            value: '2001.00',
                          },
                        ],
                      },
                      type: 'Section',
                      group: 'BankAccounts',
                    },
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Accounts Receivable',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Accounts Receivable (A/R)',
                                id: '84',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '2154.00',
                              },
                              {
                                value: '5281.52',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Accounts Receivable',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '2154.00',
                          },
                          {
                            value: '5281.52',
                          },
                        ],
                      },
                      type: 'Section',
                      group: 'AR',
                    },
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Other Current Assets',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Inventory Asset',
                                id: '81',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '596.25',
                              },
                            ],
                            type: 'Data',
                          },
                          {
                            ColData: [
                              {
                                value: 'Undeposited Funds',
                                id: '4',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '687.15',
                              },
                              {
                                value: '2062.52',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Other Current Assets',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '687.15',
                          },
                          {
                            value: '2658.77',
                          },
                        ],
                      },
                      type: 'Section',
                      group: 'OtherCurrentAssets',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Current Assets',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '7162.55',
                    },
                    {
                      value: '9941.29',
                    },
                  ],
                },
                type: 'Section',
                group: 'CurrentAssets',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Fixed Assets',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Truck',
                            id: '37',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Original Cost',
                                id: '38',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '13495.00',
                              },
                              {
                                value: '13495.00',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Truck',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '13495.00',
                          },
                          {
                            value: '13495.00',
                          },
                        ],
                      },
                      type: 'Section',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Fixed Assets',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '13495.00',
                    },
                    {
                      value: '13495.00',
                    },
                  ],
                },
                type: 'Section',
                group: 'FixedAssets',
              },
            ],
          },
          Summary: {
            ColData: [
              {
                value: 'TOTAL ASSETS',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '20657.55',
              },
              {
                value: '23436.29',
              },
            ],
          },
          type: 'Section',
          group: 'TotalAssets',
        },
        {
          Header: {
            ColData: [
              {
                value: 'LIABILITIES AND EQUITY',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
            ],
          },
          Rows: {
            Row: [
              {
                Header: {
                  ColData: [
                    {
                      value: 'Liabilities',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Current Liabilities',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            Header: {
                              ColData: [
                                {
                                  value: 'Accounts Payable',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                              ],
                            },
                            Rows: {
                              Row: [
                                {
                                  ColData: [
                                    {
                                      value: 'Accounts Payable (A/P)',
                                      id: '33',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '1897.39',
                                    },
                                    {
                                      value: '1602.67',
                                    },
                                  ],
                                  type: 'Data',
                                },
                              ],
                            },
                            Summary: {
                              ColData: [
                                {
                                  value: 'Total Accounts Payable',
                                },
                                {
                                  value: '0.00',
                                },
                                {
                                  value: '0.00',
                                },
                                {
                                  value: '1897.39',
                                },
                                {
                                  value: '1602.67',
                                },
                              ],
                            },
                            type: 'Section',
                            group: 'AP',
                          },
                          {
                            Header: {
                              ColData: [
                                {
                                  value: 'Credit Cards',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                              ],
                            },
                            Rows: {
                              Row: [
                                {
                                  ColData: [
                                    {
                                      value: 'Mastercard',
                                      id: '41',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '223.08',
                                    },
                                    {
                                      value: '157.72',
                                    },
                                  ],
                                  type: 'Data',
                                },
                              ],
                            },
                            Summary: {
                              ColData: [
                                {
                                  value: 'Total Credit Cards',
                                },
                                {
                                  value: '0.00',
                                },
                                {
                                  value: '0.00',
                                },
                                {
                                  value: '223.08',
                                },
                                {
                                  value: '157.72',
                                },
                              ],
                            },
                            type: 'Section',
                            group: 'CreditCards',
                          },
                          {
                            Header: {
                              ColData: [
                                {
                                  value: 'Other Current Liabilities',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                                {
                                  value: '',
                                },
                              ],
                            },
                            Rows: {
                              Row: [
                                {
                                  ColData: [
                                    {
                                      value: 'Arizona Dept. of Revenue Payable',
                                      id: '89',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '0.00',
                                    },
                                    {
                                      value: '0.00',
                                    },
                                  ],
                                  type: 'Data',
                                },
                                {
                                  ColData: [
                                    {
                                      value: 'Board of Equalization Payable',
                                      id: '90',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '46.40',
                                    },
                                    {
                                      value: '370.94',
                                    },
                                  ],
                                  type: 'Data',
                                },
                                {
                                  ColData: [
                                    {
                                      value: 'Loan Payable',
                                      id: '43',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '',
                                    },
                                    {
                                      value: '4000.00',
                                    },
                                  ],
                                  type: 'Data',
                                },
                              ],
                            },
                            Summary: {
                              ColData: [
                                {
                                  value: 'Total Other Current Liabilities',
                                },
                                {
                                  value: '0.00',
                                },
                                {
                                  value: '0.00',
                                },
                                {
                                  value: '46.40',
                                },
                                {
                                  value: '4370.94',
                                },
                              ],
                            },
                            type: 'Section',
                            group: 'OtherCurrentLiabilities',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Current Liabilities',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '2166.87',
                          },
                          {
                            value: '6131.33',
                          },
                        ],
                      },
                      type: 'Section',
                      group: 'CurrentLiabilities',
                    },
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Long-Term Liabilities',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Notes Payable',
                                id: '44',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '25000.00',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Long-Term Liabilities',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '25000.00',
                          },
                        ],
                      },
                      type: 'Section',
                      group: 'LongTermLiabilities',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Liabilities',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '2166.87',
                    },
                    {
                      value: '31131.33',
                    },
                  ],
                },
                type: 'Section',
                group: 'Liabilities',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Equity',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      ColData: [
                        {
                          value: 'Opening Balance Equity',
                          id: '34',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '18495.00',
                        },
                        {
                          value: '-9337.50',
                        },
                      ],
                      type: 'Data',
                    },
                    {
                      ColData: [
                        {
                          value: 'Retained Earnings',
                          id: '2',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '-4.32',
                        },
                      ],
                      type: 'Data',
                    },
                    {
                      ColData: [
                        {
                          value: 'Net Income',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '-4.32',
                        },
                        {
                          value: '1646.78',
                        },
                      ],
                      type: 'Data',
                      group: 'NetIncome',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Equity',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '18490.68',
                    },
                    {
                      value: '-7695.04',
                    },
                  ],
                },
                type: 'Section',
                group: 'Equity',
              },
            ],
          },
          Summary: {
            ColData: [
              {
                value: 'TOTAL LIABILITIES AND EQUITY',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '20657.55',
              },
              {
                value: '23436.29',
              },
            ],
          },
          type: 'Section',
          group: 'TotalLiabilitiesAndEquity',
        },
      ],
    },
  }
}

export const getMockedProfitAndLossDetail = () => {
  return {
    Header: {
      Time: '2021-02-23T14:26:26-08:00',
      ReportName: 'ProfitAndLoss',
      ReportBasis: 'Accrual',
      StartPeriod: '2018-01-01',
      EndPeriod: '2021-02-28',
      SummarizeColumnsBy: 'Year',
      Currency: 'USD',
      Option: [
        {
          Name: 'AccountingStandard',
          Value: 'GAAP',
        },
        {
          Name: 'NoReportData',
          Value: 'false',
        },
      ],
    },
    Columns: {
      Column: [
        {
          ColTitle: '',
          ColType: 'Account',
          MetaData: [
            {
              Name: 'ColKey',
              Value: 'account',
            },
          ],
        },
        {
          ColTitle: 'Jan - Dec 2018',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2018-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2018-12-31',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Dec 2018',
            },
          ],
        },
        {
          ColTitle: 'Jan - Dec 2019',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2019-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2019-12-31',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Dec 2019',
            },
          ],
        },
        {
          ColTitle: 'Jan - Dec 2020',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2020-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2020-12-31',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Dec 2020',
            },
          ],
        },
        {
          ColTitle: 'Jan - Feb, 2021',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'StartDate',
              Value: '2021-01-01',
            },
            {
              Name: 'EndDate',
              Value: '2021-02-28',
            },
            {
              Name: 'ColKey',
              Value: 'Jan - Feb, 2021',
            },
          ],
        },
        {
          ColTitle: 'Total',
          ColType: 'Money',
          MetaData: [
            {
              Name: 'ColKey',
              Value: 'total',
            },
          ],
        },
      ],
    },
    Rows: {
      Row: [
        {
          Header: {
            ColData: [
              {
                value: 'Income',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
            ],
          },
          Rows: {
            Row: [
              {
                ColData: [
                  {
                    value: 'Design income',
                    id: '82',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '975.00',
                  },
                  {
                    value: '1275.00',
                  },
                  {
                    value: '2250.00',
                  },
                ],
                type: 'Data',
              },
              {
                ColData: [
                  {
                    value: 'Discounts given',
                    id: '86',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '-89.50',
                  },
                  {
                    value: '-89.50',
                  },
                ],
                type: 'Data',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Landscaping Services',
                      id: '45',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '835.00',
                    },
                    {
                      value: '642.50',
                    },
                    {
                      value: '1477.50',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Job Materials',
                            id: '46',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '0.00',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Fountains and Garden Lighting',
                                id: '48',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '1020.00',
                              },
                              {
                                value: '1226.50',
                              },
                              {
                                value: '2246.50',
                              },
                            ],
                            type: 'Data',
                          },
                          {
                            ColData: [
                              {
                                value: 'Plants and Soil',
                                id: '49',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '256.89',
                              },
                              {
                                value: '2095.08',
                              },
                              {
                                value: '2351.97',
                              },
                            ],
                            type: 'Data',
                          },
                          {
                            ColData: [
                              {
                                value: 'Sprinklers and Drip Systems',
                                id: '50',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '108.00',
                              },
                              {
                                value: '30.00',
                              },
                              {
                                value: '138.00',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Job Materials',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '1384.89',
                          },
                          {
                            value: '3351.58',
                          },
                          {
                            value: '4736.47',
                          },
                        ],
                      },
                      type: 'Section',
                    },
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Labor',
                            id: '51',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '0.00',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Installation',
                                id: '52',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '250.00',
                              },
                              {
                                value: '250.00',
                              },
                            ],
                            type: 'Data',
                          },
                          {
                            ColData: [
                              {
                                value: 'Maintenance and Repair',
                                id: '53',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '50.00',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '50.00',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Labor',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '50.00',
                          },
                          {
                            value: '250.00',
                          },
                          {
                            value: '300.00',
                          },
                        ],
                      },
                      type: 'Section',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Landscaping Services',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '2269.89',
                    },
                    {
                      value: '4244.08',
                    },
                    {
                      value: '6513.97',
                    },
                  ],
                },
                type: 'Section',
              },
              {
                ColData: [
                  {
                    value: 'Pest Control Services',
                    id: '54',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '40.00',
                  },
                  {
                    value: '70.00',
                  },
                  {
                    value: '110.00',
                  },
                ],
                type: 'Data',
              },
              {
                ColData: [
                  {
                    value: 'Sales of Product Income',
                    id: '79',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '912.75',
                  },
                  {
                    value: '912.75',
                  },
                ],
                type: 'Data',
              },
              {
                ColData: [
                  {
                    value: 'Services',
                    id: '1',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '503.55',
                  },
                  {
                    value: '503.55',
                  },
                ],
                type: 'Data',
              },
            ],
          },
          Summary: {
            ColData: [
              {
                value: 'Total Income',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '3284.89',
              },
              {
                value: '6915.88',
              },
              {
                value: '10200.77',
              },
            ],
          },
          type: 'Section',
          group: 'Income',
        },
        {
          Header: {
            ColData: [
              {
                value: 'Cost of Goods Sold',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
            ],
          },
          Rows: {
            Row: [
              {
                ColData: [
                  {
                    value: 'Cost of Goods Sold',
                    id: '80',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '405.00',
                  },
                  {
                    value: '405.00',
                  },
                ],
                type: 'Data',
              },
            ],
          },
          Summary: {
            ColData: [
              {
                value: 'Total Cost of Goods Sold',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '405.00',
              },
              {
                value: '405.00',
              },
            ],
          },
          type: 'Section',
          group: 'COGS',
        },
        {
          Summary: {
            ColData: [
              {
                value: 'Gross Profit',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '3284.89',
              },
              {
                value: '6510.88',
              },
              {
                value: '9795.77',
              },
            ],
          },
          type: 'Section',
          group: 'GrossProfit',
        },
        {
          Header: {
            ColData: [
              {
                value: 'Expenses',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
            ],
          },
          Rows: {
            Row: [
              {
                ColData: [
                  {
                    value: 'Advertising',
                    id: '7',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '74.86',
                  },
                  {
                    value: '74.86',
                  },
                ],
                type: 'Data',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Automobile',
                      id: '55',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '113.96',
                    },
                    {
                      value: '113.96',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      ColData: [
                        {
                          value: 'Fuel',
                          id: '56',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '181.56',
                        },
                        {
                          value: '167.85',
                        },
                        {
                          value: '349.41',
                        },
                      ],
                      type: 'Data',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Automobile',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '181.56',
                    },
                    {
                      value: '281.81',
                    },
                    {
                      value: '463.37',
                    },
                  ],
                },
                type: 'Section',
              },
              {
                ColData: [
                  {
                    value: 'Equipment Rental',
                    id: '29',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '112.00',
                  },
                  {
                    value: '112.00',
                  },
                ],
                type: 'Data',
              },
              {
                ColData: [
                  {
                    value: 'Insurance',
                    id: '11',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '241.23',
                  },
                  {
                    value: '241.23',
                  },
                ],
                type: 'Data',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Job Expenses',
                      id: '58',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '108.09',
                    },
                    {
                      value: '46.98',
                    },
                    {
                      value: '155.07',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      Header: {
                        ColData: [
                          {
                            value: 'Job Materials',
                            id: '63',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '',
                          },
                          {
                            value: '0.00',
                          },
                        ],
                      },
                      Rows: {
                        Row: [
                          {
                            ColData: [
                              {
                                value: 'Decks and Patios',
                                id: '64',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '234.04',
                              },
                              {
                                value: '234.04',
                              },
                            ],
                            type: 'Data',
                          },
                          {
                            ColData: [
                              {
                                value: 'Plants and Soil',
                                id: '66',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '247.17',
                              },
                              {
                                value: '105.95',
                              },
                              {
                                value: '353.12',
                              },
                            ],
                            type: 'Data',
                          },
                          {
                            ColData: [
                              {
                                value: 'Sprinklers and Drip Systems',
                                id: '67',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '',
                              },
                              {
                                value: '215.66',
                              },
                              {
                                value: '215.66',
                              },
                            ],
                            type: 'Data',
                          },
                        ],
                      },
                      Summary: {
                        ColData: [
                          {
                            value: 'Total Job Materials',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '0.00',
                          },
                          {
                            value: '247.17',
                          },
                          {
                            value: '555.65',
                          },
                          {
                            value: '802.82',
                          },
                        ],
                      },
                      type: 'Section',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Job Expenses',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '355.26',
                    },
                    {
                      value: '602.63',
                    },
                    {
                      value: '957.89',
                    },
                  ],
                },
                type: 'Section',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Legal & Professional Fees',
                      id: '12',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '75.00',
                    },
                    {
                      value: '75.00',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      ColData: [
                        {
                          value: 'Accounting',
                          id: '69',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '250.00',
                        },
                        {
                          value: '390.00',
                        },
                        {
                          value: '640.00',
                        },
                      ],
                      type: 'Data',
                    },
                    {
                      ColData: [
                        {
                          value: 'Bookkeeper',
                          id: '70',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '55.00',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '55.00',
                        },
                      ],
                      type: 'Data',
                    },
                    {
                      ColData: [
                        {
                          value: 'Lawyer',
                          id: '71',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '300.00',
                        },
                        {
                          value: '100.00',
                        },
                        {
                          value: '400.00',
                        },
                      ],
                      type: 'Data',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Legal & Professional Fees',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '605.00',
                    },
                    {
                      value: '565.00',
                    },
                    {
                      value: '1170.00',
                    },
                  ],
                },
                type: 'Section',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Maintenance and Repair',
                      id: '72',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '185.00',
                    },
                    {
                      value: '185.00',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      ColData: [
                        {
                          value: 'Equipment Repairs',
                          id: '75',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '755.00',
                        },
                        {
                          value: '755.00',
                        },
                      ],
                      type: 'Data',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Maintenance and Repair',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '940.00',
                    },
                    {
                      value: '940.00',
                    },
                  ],
                },
                type: 'Section',
              },
              {
                ColData: [
                  {
                    value: 'Meals and Entertainment',
                    id: '13',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '28.49',
                  },
                  {
                    value: '28.49',
                  },
                ],
                type: 'Data',
              },
              {
                ColData: [
                  {
                    value: 'Office Expenses',
                    id: '15',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '18.08',
                  },
                  {
                    value: '18.08',
                  },
                ],
                type: 'Data',
              },
              {
                ColData: [
                  {
                    value: 'Rent or Lease',
                    id: '17',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '900.00',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '900.00',
                  },
                ],
                type: 'Data',
              },
              {
                Header: {
                  ColData: [
                    {
                      value: 'Utilities',
                      id: '24',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '',
                    },
                    {
                      value: '0.00',
                    },
                  ],
                },
                Rows: {
                  Row: [
                    {
                      ColData: [
                        {
                          value: 'Gas and Electric',
                          id: '76',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '200.53',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '200.53',
                        },
                      ],
                      type: 'Data',
                    },
                    {
                      ColData: [
                        {
                          value: 'Telephone',
                          id: '77',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '130.86',
                        },
                        {
                          value: '',
                        },
                        {
                          value: '130.86',
                        },
                      ],
                      type: 'Data',
                    },
                  ],
                },
                Summary: {
                  ColData: [
                    {
                      value: 'Total Utilities',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '331.39',
                    },
                    {
                      value: '0.00',
                    },
                    {
                      value: '331.39',
                    },
                  ],
                },
                type: 'Section',
              },
            ],
          },
          Summary: {
            ColData: [
              {
                value: 'Total Expenses',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '2373.21',
              },
              {
                value: '2864.10',
              },
              {
                value: '5237.31',
              },
            ],
          },
          type: 'Section',
          group: 'Expenses',
        },
        {
          Summary: {
            ColData: [
              {
                value: 'Net Operating Income',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '911.68',
              },
              {
                value: '3646.78',
              },
              {
                value: '4558.46',
              },
            ],
          },
          type: 'Section',
          group: 'NetOperatingIncome',
        },
        {
          Header: {
            ColData: [
              {
                value: 'Other Expenses',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
              {
                value: '',
              },
            ],
          },
          Rows: {
            Row: [
              {
                ColData: [
                  {
                    value: 'Miscellaneous',
                    id: '14',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '',
                  },
                  {
                    value: '916.00',
                  },
                  {
                    value: '2000.00',
                  },
                  {
                    value: '2916.00',
                  },
                ],
                type: 'Data',
              },
            ],
          },
          Summary: {
            ColData: [
              {
                value: 'Total Other Expenses',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '916.00',
              },
              {
                value: '2000.00',
              },
              {
                value: '2916.00',
              },
            ],
          },
          type: 'Section',
          group: 'OtherExpenses',
        },
        {
          Summary: {
            ColData: [
              {
                value: 'Net Other Income',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '-916.00',
              },
              {
                value: '-2000.00',
              },
              {
                value: '-2916.00',
              },
            ],
          },
          type: 'Section',
          group: 'NetOtherIncome',
        },
        {
          Summary: {
            ColData: [
              {
                value: 'Net Income',
              },
              {
                value: '0.00',
              },
              {
                value: '0.00',
              },
              {
                value: '-4.32',
              },
              {
                value: '1646.78',
              },
              {
                value: '1642.46',
              },
            ],
          },
          type: 'Section',
          group: 'NetIncome',
        },
      ],
    },
  }
}
