import styled, { css } from 'styled-components'

import { Scroll } from 'App.styles'
import { color } from 'utils/functions/colors'
import {
  Position,
  DEFAULT_DROPDOWN_HEIGHT,
} from '../CountryInput/useCountryInput'

export const Container = styled.div<{
  showDropdown: boolean
  position: Position
}>`
  position: absolute;
  background-color: white;
  border-radius: 0.8rem;
  border: 1px solid ${color('veryLightGray')};
  padding: 0.8rem;
  z-index: 1;
  display: ${({ showDropdown }) => (showDropdown ? 'block' : 'none')};
  transition: all 0.2s ease-in-out;
  max-height: ${DEFAULT_DROPDOWN_HEIGHT}px;
  width: 100%;

  div input {
    margin-bottom: 0.4rem;
    padding-left: 1.6rem;
  }

  ${({ position }) => {
    switch (position) {
      case 'top':
        return css`
          bottom: calc(100% + 0.5rem);
        `
      case 'bottom':
      default:
        return css`
          top: calc(100% + 0.5rem);
        `
    }
  }}
`

export const CountryDropdownContainer = styled.ul`
  ${Scroll}
  max-height: calc(${DEFAULT_DROPDOWN_HEIGHT}px - 5rem - 0.8rem);
`

export const Option = styled.li`
  padding: 1rem 0.8rem;
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;

  &:hover {
    cursor: pointer;
    border-radius: 0.8rem;
    background: ${color('veryLightBlue', 0.2)};
    color: ${color('primaryBlue')};

    span {
      color: ${color('primaryBlue')};
    }
  }
`

export const CountryCallingCode = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: ${color('lightGray')};
`
