import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 55.7rem;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;

  .payment-element {
    width: 100%;
  }
`

export const PoweredBy = styled.div`
  color: ${color('lightGray')};
  display: flex;
  gap: 0.4rem;
  font-size: 1.4rem;
`

export const Link = styled.a`
  font-weight: 700;
  cursor: pointer;
`

export const ImmediateBilling = styled.div`
  color: ${color('darkGray')};
  max-width: 56rem;
  font-size: 1.4rem;
  text-align: center;
`

export const Support = styled.span`
  font-weight: 700;
  cursor: pointer;
`

export const ErrorMessage = styled.div<{ margin?: string }>`
  color: ${color('red')};
  font-size: 1.4rem;
  margin: ${(props) => props.margin || '0 0 2.4rem 0'};
  text-align: center;
`
