import { useIntl } from 'react-intl'
import { getFrequencyNames, getMetricNames } from 'utils/functions/metrics'

import Dropdown from 'ui/Dropdown'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import InputLabel from 'ui/InputLabel'

import Separator from 'ui/Separator'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'

import ListsSelectionBox from 'components/ListsSelectionBox'
import DropdownEntity from 'components/GroupsUsersAddInput/DropdownEntity'

import {
  AutoSuggestPortfolioCompanies,
  OptionalSpan,
  DropdownContainer,
  ListsSelectionBoxWrapper,
} from '../CreateMetric.styles'
import useCreateFounderMetricForm from './useCreateFounderMetricForm'

interface Props {
  onSelectGroup: (option: any) => void
  currentGroups: any[]
  handleRemoveGroup: (id: string) => void
  loadGroups: (groups: any) => void
  groupSearchText: string

  itemLists: any[]
  handleAddItemList: (item: any) => void
  handleDeleteItemList: (id: string) => void
  handleEditItemList: (item: any) => void

  getSubOptionById: (id: string, options: any[]) => any
}

const CreateFounderMetricForm = ({
  onSelectGroup,
  currentGroups,
  handleRemoveGroup,
  loadGroups,
  groupSearchText,

  itemLists,
  handleAddItemList,
  handleDeleteItemList,
  handleEditItemList,

  getSubOptionById,
}: Props) => {
  const intl = useIntl()

  const {
    nameField,
    frequencyField,
    shareGroupsField,

    isFounderVerified,
    isCurrentUserActingAsFounder,

    setFieldValue,
    handleBlur,
  } = useCreateFounderMetricForm({ currentGroups })

  return (
    <>
      <Dropdown
        id={nameField.name}
        name={nameField.name}
        error={nameField.buildError()}
        onBlur={handleBlur}
        label={intl.formatMessage({ id: 'metrics.metricName' })}
        options={getMetricNames(intl)}
        placeholder={intl.formatMessage({ id: 'metrics.titlePlaceholder' })}
        getOption={(option) => option}
        value={nameField.value}
        type="text"
        withGroupOptions
        onChange={nameField.onChange}
        onSelectOption={(_, value) => {
          const subOption = getSubOptionById(value, getMetricNames(intl))
          setFieldValue(nameField.name, subOption?.label)
        }}
        showIcon={false}
        showAngleIcon={false}
        withCounter
        simulateIconSpace
        counterStyle={{ right: '2rem', top: '1.2rem' }}
        errorIconCustomPaddingRight="2.5rem"
      />

      <Separator space="1.8rem" />

      <Dropdown
        id={frequencyField.name}
        name={frequencyField.name}
        label={intl.formatMessage({ id: 'metrics.frequency' })}
        placeholder={intl.formatMessage({
          id: 'metrics.frequencyPlaceholder',
        })}
        onSelectOption={(_, value) => {
          setFieldValue(frequencyField.name, value)
        }}
        showBorder
        options={getFrequencyNames(intl)}
        error={frequencyField.buildError()}
        value={frequencyField.value}
      />
      <ReadOnlyTooltip
        place="left"
        text={intl.formatMessage({ id: 'updates.readOnlyMetrics' })}
        disabled={!isFounderVerified && isCurrentUserActingAsFounder}
      >
        <AutoSuggestPortfolioCompanies>
          <InputLabel>
            {intl.formatMessage({ id: 'metrics.shareWith' })}
            <OptionalSpan>
              {intl.formatMessage({ id: 'general.optional' })}
            </OptionalSpan>
          </InputLabel>

          <ListsSelectionBoxWrapper>
            <ListsSelectionBox
              showLabel={false}
              items={itemLists}
              handleAddGroup={handleAddItemList}
              handleAddUser={() => {}}
              handleDelete={handleDeleteItemList}
              handleEditItemList={handleEditItemList}
              onlyGroups
            />
          </ListsSelectionBoxWrapper>
          <DropdownContainer>
            <Dropdown
              id={shareGroupsField.name}
              name={shareGroupsField.name}
              onSelectOption={onSelectGroup}
              placeholder={intl.formatMessage({
                id: 'metrics.addGroups',
              })}
              loadOptions={loadGroups}
              async
              type="input"
              icon={['far', 'plus']}
              iconFontSize="1.8rem"
              clearOnSelect
              clearOnPressEnter={false}
              disabled={!isFounderVerified && isCurrentUserActingAsFounder}
              confirmOnClickIcon
              optionsTitle={
                !groupSearchText
                  ? intl.formatMessage({
                      id: 'metrics.recentlyUsedGroups',
                    })
                  : null
              }
              error={shareGroupsField.buildError()}
              highlightEnabled
              maxListHeight="40rem"
              dropdownOptionPadding="0"
              showLoadingIndicator
              getOption={(entity) => {
                if (!entity) return null

                return {
                  value: {
                    type: entity.type,
                    name: entity.name,
                    handle: entity.handle,
                    logo: entity.logo?.url,
                  },
                  name: entity.name,
                  id: entity.id,
                }
              }}
              formatSelectedOptionComponent={(option) => (
                <DropdownEntity
                  entity={option.value}
                  isSelected={option.value}
                />
              )}
              highlightSelectedOption={false}
            />
          </DropdownContainer>

          {!!currentGroups?.length && (
            <BoxGroupsAndUsersChips
              items={currentGroups?.map((item) => ({
                ...item,
                fromMetric: true,
              }))}
              handleDelete={handleRemoveGroup}
            />
          )}
        </AutoSuggestPortfolioCompanies>
        <Separator height="8rem" />
      </ReadOnlyTooltip>
    </>
  )
}

export default CreateFounderMetricForm
