import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { GroupTypes } from 'utils/constants/groups'
import EMAIL_PROVIDERS_LIST from 'utils/constants/emailProviders'
import { getEmailProviderFromEmailAddress } from 'utils/functions/user'
import { GroupType } from 'utils/types/user'
import { SignUpUser } from 'utils/types/signUp'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { HIDE_CLAIM_COMPANY, SHOW_CLAIM_COMPANY } from 'utils/constants/events'
import CompanyService from 'api/CompanyService'
import { getUser } from 'selectors/auth'
import { setIsSigningUp } from 'features/authSlice'
import { setSignupCompany } from 'actions/companies'
import { useSignupContext } from '../context/SignupContext'

export enum GroupSetupStep {
  CREATE_FOUNDER = '/group-setup/create-group/founder',
}

export const getGroupSetupStepUrl = (groupType: GroupType) => {
  switch (groupType) {
    case GroupTypes.FOUNDER:
      return GroupSetupStep.CREATE_FOUNDER
    default:
      throw new Error('Not implemented')
  }
}

export const useGroupTypeSelector = () => {
  const history = useHistory()
  const { urlPrefix } = useSignupContext()
  const [showClaimCompany, setShowClaimCompany] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useDispatch()
  const currentUser = useSelector(getUser<SignUpUser>)

  const collectCompanyInformationByUser = useCallback(async () => {
    try {
      if (currentUser?.tempData?.groupType === GroupTypes.FOUNDER) {
        const emailProvider = getEmailProviderFromEmailAddress(
          currentUser.email
        )
        const isCompanyEmail = !EMAIL_PROVIDERS_LIST.includes(emailProvider)

        if (!isCompanyEmail) {
          setIsLoading(false)
          return
        }

        const result = await CompanyService.searchPublicProfileByUserId(
          currentUser.id
        )

        if (result.companyFound) {
          dispatchEvent(SHOW_CLAIM_COMPANY)
          await dispatch(setSignupCompany(result.company))
          setShowClaimCompany(true)
        }
      }

      setIsLoading(false)
    } catch (err) {
      setIsLoading(false)
    }
  }, [currentUser.email, currentUser.id, currentUser?.tempData, dispatch])

  const onCancelClaimCompany = useCallback(() => {
    dispatchEvent(HIDE_CLAIM_COMPANY)
    setShowClaimCompany(false)
  }, [])

  const onConfirmClaimCompany = useCallback(() => {
    dispatch(setIsSigningUp(false))
    history.push('/welcome')
  }, [dispatch, history])

  useEffect(() => {
    collectCompanyInformationByUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    urlPrefix,
    isLoading,
    showClaimCompany,
    onCancelClaimCompany,
    onConfirmClaimCompany,
  }
}
