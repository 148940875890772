import { Scroll } from 'App.styles'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const GroupsWrapper = styled.div<{
  groupsLength: number
  isMobile: boolean
}>`
  width: ${({ groupsLength, isMobile }) =>
    `min(calc(${groupsLength > 1 ? '1.7rem' : '2.1rem'} * ${groupsLength}), ${
      isMobile ? '7.5rem' : '12rem'
    })`};
`

const fontStyles = css`
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 700;
`

export const TooltipWrapper = styled.div`
  ${Scroll}
  padding-right: 1rem;
  max-height: 33rem;

  span {
    padding: initial;
  }

  span.group-name {
    ${fontStyles}
    color: ${color('gray')};
    margin-left: 0.45rem;
  }

  .globe-container svg {
    height: 1.15rem;
    width: 1.35rem;
  }
`

export const TooltipTitle = styled.div`
  ${fontStyles}
  text-transform: uppercase;
  color: ${color('lightGray')};
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  justify-content: flex-start;

  svg {
    font-size: 1.5rem;
  }
`
