import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import NavigationItem from '../NavigationItem'
import { NAVIGATION_ITEM_WIDTH } from '../NavigationList/NavigationList.styles'

export const AddPortfolioButton = styled(NavigationItem).attrs((props) => ({
  ...props,
}))<{
  isBig?: boolean
  onClick: (isOpen: boolean) => void
}>`
  border: 0.1rem dashed ${color('lightGray', 0.2)};
  cursor: pointer;
  height: 4.2rem;

  ${({ isBig }) =>
    isBig
      ? css`
          display: flex;
          justify-content: center;
          width: 100%;
          min-width: 100%;
          max-width: 100%;
        `
      : css`
          width: ${NAVIGATION_ITEM_WIDTH};
        `}
`

export const AddPortfolioButtonContainer = styled.div<{ isBig?: boolean }>`
  ${({ isBig }) =>
    isBig &&
    css`
      display: flex;
      justify-content: center;
      margin-top: 0.8rem;
      max-width: none;
      margin-right: 0rem;
      grid-column: 1/-1;
      width: 100%;
    `}
`
