import * as FullStory from '@fullstory/browser'
import { getGroupTypeName } from 'utils/functions/groups'
import { AppGroup, User } from 'utils/types/user'
import { PROD_ENV, STAGING_ENV } from '../functions/env'

const FULLSTORY_ENABLED = PROD_ENV || STAGING_ENV

export const initialize = () => {
  if (FULLSTORY_ENABLED) {
    FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORGANIZATION_ID! })
  }
}

export const shutdown = () => {
  try {
    if (FullStory.isInitialized()) {
      FullStory.shutdown()
    }
  } catch {
    // continue regardless of error
  }
}

export const isInitialized = () => {
  return FullStory.isInitialized()
}

export const identifyUser = (user: User, group: AppGroup) => {
  if (FULLSTORY_ENABLED) {
    FullStory.identify(user.id, {
      displayName: user.name,
      email: user.email,
      'Group Id': group.id,
      'Group Name': group.name,
      'Group Type': getGroupTypeName(group.type),
    })
  }
}
