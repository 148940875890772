import { MouseEventHandler } from 'react'
import IconButton from 'ui/IconButton'
import { ArrowContainer } from './SliderArrow.styles'

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>
  direction: 'right' | 'left'
  visible: boolean
}

const SliderArrow: React.FC<Props> = ({ onClick, direction, visible }) => {
  return (
    <ArrowContainer visible={visible} direction={direction}>
      <IconButton
        icon={`chevron-${direction}`}
        onClick={(event) => {
          event.preventDefault()
          event.stopPropagation()
          onClick?.(event)
        }}
      />
    </ArrowContainer>
  )
}

export default SliderArrow
