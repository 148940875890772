/* eslint-disable react/no-danger, react/style-prop-object, react/style-prop-object */
import classNames from 'classnames'
import DOMPurify from 'dompurify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import type { IntlShape } from 'react-intl/src/types'

import EditButtonColumn from 'components/ExpandableTable/FloatButtonColumn'

import { TransactionItem } from 'utils/types/update'
import { FundPortfolioInvestor } from 'utils/types/funds'
import { TransactionTypes } from 'utils/constants/transactionTypes'
import { PortfolioCompanyWithFmv } from 'utils/types/portfolios'
import { TransactionsVisibleColumns } from 'utils/types/transactions'
import { formattedDate } from 'utils/functions/date'

import { Column } from 'ui/Table/types'

import {
  getNormalizedTransactionInstrument,
  getTransactionTypeLabels,
} from 'utils/functions/transactions'

type CompanyType = FundPortfolioInvestor & PortfolioCompanyWithFmv

const defaultVisibleColumns: TransactionsVisibleColumns = {
  title: true,
  date: true,
  investmentVehicle: false,
  transactionType: true,
  amount: true,
  instrument: true,
  description: true,
  attachableCount: true,
  editTransaction: true,
}

export const getCompanyTransactionsColumns = ({
  visibleTransactionsColumns,
  styles,
  intl,
  onEditTransaction,
  isMobile,
  isEditable = true,
}: {
  visibleTransactionsColumns?: Partial<TransactionsVisibleColumns>
  styles: any
  intl: IntlShape
  onEditTransaction: (
    transaction: TransactionItem,
    portfolioCompany: CompanyType
  ) => void
  isMobile?: boolean
  isSmallDesktop?: boolean
  isEditable?: boolean
}): Column<TransactionItem & { amount: number }>[] => {
  const visibleColumns = Object.assign(
    defaultVisibleColumns,
    visibleTransactionsColumns
  )
  return [
    {
      id: 'title',
      sortKey: 'title',
      label: <FormattedMessage id="portfolioDetail.transactionName" />,
      flex: 1.5,
      minWidth: 150,
      hidden: !visibleColumns.title,
      cellRenderer: ({ rowData }) => (
        <span
          className={classNames(styles.cell, styles.textCell, 'fs-exclude')}
        >
          {rowData.title}
        </span>
      ),
    },
    {
      id: 'date',
      sortKey: 'date',
      label: <FormattedMessage id="portfolioDetail.transactionDate" />,
      flex: 1,
      minWidth: 100,
      hidden: !visibleColumns.date,
      cellRenderer: ({ rowData }) => {
        return (
          <span className={classNames(styles.cell, 'fs-exclude')}>
            {formattedDate(rowData.date)}
          </span>
        )
      },
    },
    {
      id: 'investmentVehicle',
      sortKey: 'investmentVehicle',
      label: <FormattedMessage id="portfolioDetail.investmentVehicle" />,
      flex: 1,
      minWidth: 210,
      hidden: !visibleColumns.investmentVehicle,
      cellRenderer: ({ rowData }) => {
        return (
          <div className={classNames(styles.cell, 'fs-exclude')}>
            {rowData.investmentVehicle ? rowData.investmentVehicle.name : '-'}
          </div>
        )
      },
    },
    {
      id: 'transactionType',
      sortKey: 'transaction_type',
      label: <FormattedMessage id="portfolioDetail.type" />,
      flex: 1,
      minWidth: 100,
      hidden: !visibleColumns.transactionType,
      cellRenderer: ({ rowData }) => (
        <div
          className={classNames(styles.cell, 'fs-exclude', {
            [styles.boldCell]:
              rowData.transactionType === TransactionTypes.DISTRIBUTION ||
              rowData.transactionType === TransactionTypes.INVESTMENT,
          })}
        >
          {getTransactionTypeLabels(rowData.transactionType, intl).typeLabel}
        </div>
      ),
    },
    {
      id: 'amount',
      flex: 1,
      sortDisabled: true, // TODO: transaction entity has different fields name for amount (amountCommitted/amountInvested). It'll be unified in #TAS-12904
      minWidth: 120,
      label: <FormattedMessage id="portfolioDetail.amount" />,
      hidden: !visibleColumns.amount,
      cellRenderer: ({ rowData }) => {
        const amount =
          rowData.amount || rowData.amountCommitted || rowData.amountInvested
        return (
          <div
            className={classNames(styles.cell, 'fs-exclude', {
              [styles.boldCell]:
                rowData.transactionType === TransactionTypes.DISTRIBUTION ||
                rowData.transactionType === TransactionTypes.INVESTMENT,
            })}
          >
            {amount ? (
              <FormattedNumber
                value={amount}
                style="currency"
                currency="USD"
                currencyDisplay="narrowSymbol"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            ) : (
              '-'
            )}
          </div>
        )
      },
    },

    {
      id: 'instrument',
      sortKey: 'instrumentType',
      label: <FormattedMessage id="portfolioDetail.instrument" />,
      flex: 1,
      minWidth: 110,
      hidden: !visibleColumns.instrument,
      cellRenderer: ({ rowData }) => {
        return (
          <div className={styles.cell}>
            {rowData.instrumentType
              ? getNormalizedTransactionInstrument(rowData.instrumentType, intl)
              : '-'}
          </div>
        )
      },
    },
    {
      id: 'text',
      sortKey: 'text',
      label: <FormattedMessage id="portfolioDetail.description" />,
      flex: 3.2,
      minWidth: 200,
      hidden: !visibleColumns.description,
      cellRenderer: ({ rowData }) => {
        return rowData.text ? (
          <div
            className={classNames(styles.cell, styles.textCell)}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(rowData.text),
            }}
          />
        ) : (
          <div className={styles.cell}>-</div>
        )
      },
    },
    {
      id: 'attachableCount',
      sortKey: 'attachableCount',
      justify: 'center',
      label: '',
      flex: 0.5,
      minWidth: 30,
      hidden: !visibleColumns.attachableCount,
      cellRenderer: ({ rowData }) => {
        if (!rowData.attachableCount) return null
        return (
          <div
            className={classNames(
              styles.cell,
              styles.noWrapCell,
              styles.countAttachments
            )}
          >
            <FontAwesomeIcon icon={['far', 'paperclip']} />
            {rowData.attachableCount || 0}
          </div>
        )
      },
    },
    {
      id: 'edit-transaction',
      sortDisabled: true,
      flex: 0.4,
      minWidth: 40,
      style: {
        position: 'sticky',
        right: '.5rem',
      },
      hidden: !visibleColumns.editTransaction,
      cellRenderer: ({
        rowData: transaction,
        parentRowData: portfolioCompany,
      }) => {
        return (
          isEditable && (
            <EditButtonColumn
              forceVisible={isMobile}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                onEditTransaction(transaction, portfolioCompany as CompanyType)
              }}
            />
          )
        )
      },
    },
  ]
}
