export const ESCAPE_CHARACTERS_REGEX = /[.*+?^${}()|[\]\\]/g

export const LINKEDIN_URL =
  /(?:https?:)?\/\/(?:[\w]+\.)?linkedin\.com\/(.*)\/?/g

export const TWITTER_URL = /(?:https?:)?\/\/(?:[\w]+\.)?twitter\.com\/(.*)\/?/g
export const X_URL = /(?:https?:)?\/\/(?:[\w]+\.)?x\.com\/(.*)\/?/g
export const X_TWITTER_URL =
  /(?:https?:)?\/\/(?:[\w]+\.)?(?:x|twitter)\.com\/(.*)\/?/g

export const CRUNCHBASE_URL =
  /(?:https?:)?\/\/(?:[\w]+\.)?crunchbase\.com\/organization\/(.*)\/?/g

export const ANGELLIST_URL =
  /(?:https?:)?\/\/(?:[\w]+\.)?angel\.co\/company\/(.*)\/?/g

export const escapeRegexCharacters = (str) =>
  str.replace(ESCAPE_CHARACTERS_REGEX, '\\$&')

export const EMAIL_REGEX = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i

export const HANDLE_REGEX = /^@[-a-z0-9_]+$/i

export const CW_EMAIL_REGEX = /(.*)@cwuniverse.app$/g

export const PASSWORD_REGEX =
  /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*\W)(?=.*\d))).{8,}$/

export const ALPHANUMERICAL_REGEX = /^\w+$/i

export const CHECK_FOR_BODY_TAGS = /<body( [^<>]+)?>(.+?)<\/body>/gi

export const MOBILE_USER_AGENT_REGEX =
  /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i

export const TABLET_USER_AGENT_REGEX =
  /ipad|tablet|kindle|playbook|silk|android|rim tablet/i

export const BROWSER_REGEX = /(Chrome|Safari|Firefox|Edge)\/([\d.]+)/

export const OS_REGEX = /(Windows NT|Mac OS X|Linux|Android|iOS) ([\d._]+)/
