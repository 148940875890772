import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

interface Props {
  paths: string[]
}
const useShowBreadcrumb = ({ paths }: Props) => {
  const breadcrumbPaths = useMemo(() => [...paths], [paths])

  const match = !!useRouteMatch({ path: breadcrumbPaths, exact: true })

  return { showBreadcrumb: match }
}

export default useShowBreadcrumb
