import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import styles from './SignupLink.module.scss'

const SignupLink = ({ onClick, color }) => (
  <p className={styles.legend}>
    <FormattedMessage id="login.newToUniverse" />
    <button
      type="button"
      onClick={onClick}
      className={styles.link}
      style={{ color }}
    >
      <FormattedMessage id="login.signup" />
    </button>
  </p>
)

SignupLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
}

SignupLink.defaultProps = {
  color: '',
}

export default SignupLink
