/* eslint-disable react-hooks/exhaustive-deps */
import { useQuery } from '@tanstack/react-query'
import { shallowEqual } from 'react-redux'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { useAuth0 } from '@auth0/auth0-react'
import AuthService from 'api/AuthService'
import { authKeys } from 'utils/queries/auth'
import { Nullable } from 'utils/types/common'
import { User, UserSession } from 'utils/types/user'

export const useAuthentication = <T extends User = User>() => {
  return useAppSelector(
    ({
      auth: { user, userSession },
    }): {
      isAuthenticated: boolean
      user: T
      userSession: Nullable<UserSession>
    } => {
      return {
        isAuthenticated: userSession !== null,
        // TODO: remove as T
        user: user as T,
        userSession,
      }
    },
    shallowEqual
  )
}

export const use2FASettings = () => {
  const {
    refetch,
    data: fetchedData,
    isLoading,
    isFetching,
  } = useQuery(authKeys.mfaEnrollment(), async () =>
    AuthService.getMfaEnrollments()
  )

  const totp = fetchedData?.find((authenticator) =>
    authenticator.id.startsWith('totp')
  )

  const createdAt = totp?.enrolled_at ? new Date(totp.enrolled_at) : undefined
  const lastTimeUsed = totp?.last_auth ? new Date(totp.last_auth) : undefined
  const isEnabled = totp?.status === 'confirmed'
  return { isEnabled, createdAt, lastTimeUsed, isLoading, isFetching, refetch }
}

export const useIsGoogleUser = () => {
  const { user } = useAuth0()
  return user?.sub?.includes('google')
}
