import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip'
import { isPieChartDataShared } from 'utils/functions/portfolios'
import {
  SkeletonIcon,
  SkeletonItemWrapper,
  SkeletonItem,
} from 'components/Skeletons/Skeletons.styles'
import InvestPortfolioChartResumeSkeleton from 'components/Skeletons/Portfolio/InvestPortfolioChartResumeSkeleton'
import portfolioRowStyles from './PortfolioRow.module.scss'

const InvestPortfolioChartResume = ({
  chartData,
  companies,
  colorsForCompanies,
  showHeader,
  sharePortfolioSetting,
  isLoading,
}) => {
  const intl = useIntl()
  const location = useLocation()

  const { hasTotalValueShared, hasTotalAmountInvestedShared } =
    isPieChartDataShared(chartData, sharePortfolioSetting)

  if (isLoading) {
    return <InvestPortfolioChartResumeSkeleton />
  }

  if (!hasTotalValueShared && !hasTotalAmountInvestedShared) {
    return null
  }
  const rowsInfo = chartData.map(
    ({ id, holding, name, totalValuePercentage, holdingId }, index) => {
      return {
        name: holding?.name ?? name,
        percentage: totalValuePercentage,
        color: colorsForCompanies?.[holding?.id ?? id ?? holdingId ?? 'other'],
      }
    }
  )

  return (
    <div className={portfolioRowStyles.resultContainer}>
      {showHeader && (
        <p className={portfolioRowStyles.totalHeader}>
          {intl.formatMessage(
            { id: 'portfolios.totalCompanies' },
            { length: companies.length }
          )}
        </p>
      )}
      {rowsInfo.map(({ name, color }) => (
        <Tooltip
          id={`portfolio_holding_${name}`}
          text={name}
          place="top"
          delayShow={1000}
          widthAuto
        >
          <p key={name} className={portfolioRowStyles.percentageRow}>
            <span
              className={portfolioRowStyles.legendIndicator}
              style={{ backgroundColor: color }}
            />

            <span
              className={classNames(
                portfolioRowStyles.legendName,
                'fs-exclude'
              )}
            >
              {name}
            </span>
          </p>
        </Tooltip>
      ))}
    </div>
  )
}

InvestPortfolioChartResume.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      totalAmountInvested: PropTypes.number,
      totalValue: PropTypes.number,
      percentage: PropTypes.number,
      image: PropTypes.string,
    })
  ).isRequired,
  companies: PropTypes.array.isRequired,
  colorsForCompanies: PropTypes.object,
  showHeader: PropTypes.bool,
  sharePortfolioSetting: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
}

InvestPortfolioChartResume.defaultProps = {
  colorsForCompanies: {},
  showHeader: false,
}

export default InvestPortfolioChartResume
