import CWLoader from 'components/CWLoader'
import InfiniteScrollRef from 'components/InfiniteScrollRef'
import {
  SelectUpdatesProvider,
  SelectUpdatesProviderProps,
} from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectUpdatesProvider'
import { motion } from 'framer-motion'
import orderBy from 'lodash/orderBy'
import React, { RefObject, useMemo } from 'react'

import {
  FetchingWrapper,
  ScrollWrapper,
  ZeroState,
} from 'components/UpdatesFeedV2/UpdatesFeed.styles'
import { useIntl } from 'react-intl'
import { list } from 'utils/animations/feed'
import { groupMixedUpdatesByDate } from 'utils/functions/suggestedUpdates'
import { getUpdateDate } from 'utils/functions/updates'
import { ReactFCWithChildren } from 'utils/types/common'
import { MixedUpdate } from 'utils/types/update'
import UpdatesForDate from '../UpdatesForDate/UpdatesForDate'

type Props = {
  isLoading: boolean
  isFetching: boolean
  showZeroState: boolean
  updates: MixedUpdate[]
  infiniteScrollRef: (node: HTMLDivElement) => void
  customZeroState?: React.ReactElement
  loadingText?: string
  scrollRef: RefObject<HTMLDivElement>
}

const FeedList: React.FC<Props> & {
  SelectUpdatesProvider: ReactFCWithChildren<SelectUpdatesProviderProps>
} = ({
  isLoading,
  isFetching,
  showZeroState,
  updates,
  infiniteScrollRef,
  scrollRef,
  customZeroState,
}) => {
  const intl = useIntl()

  const updatesByDate = useMemo(
    () =>
      groupMixedUpdatesByDate(
        orderBy(updates, [getUpdateDate], ['desc']),
        intl
      ),
    [updates, intl]
  )

  const renderListUpdates = () => {
    if (isLoading) {
      return (
        <motion.ul initial="hidden" animate="visible" variants={list}>
          {[1, 2, 3].map((key) => (
            <UpdatesForDate key={key} isLoading />
          ))}
        </motion.ul>
      )
    }

    if (showZeroState) {
      return (
        customZeroState || (
          <ZeroState className="feed-list-zero-state">
            <p>{intl.formatMessage({ id: 'general.noUpdates' })}</p>
          </ZeroState>
        )
      )
    }

    return (
      <>
        <motion.ul initial="hidden" animate="visible" variants={list}>
          {updatesByDate.map(({ key, values }) => (
            <UpdatesForDate
              key={key}
              label={key}
              mixedUpdates={values}
              isLoading={isLoading}
            />
          ))}
        </motion.ul>

        <FetchingWrapper>
          {isFetching && (
            <CWLoader
              text={intl.formatMessage({ id: 'general.loadingMoreUpdates' })}
            />
          )}
        </FetchingWrapper>
      </>
    )
  }

  return (
    <ScrollWrapper ref={scrollRef}>
      {renderListUpdates()}
      <InfiniteScrollRef ref={infiniteScrollRef} />
    </ScrollWrapper>
  )
}

FeedList.SelectUpdatesProvider = SelectUpdatesProvider

export default FeedList
