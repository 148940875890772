import { useAuth0 } from '@auth0/auth0-react'
import Toast from 'components/Toast'
import { useSignIn } from 'containers/Auth/SignIn/useSignIn'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const ConfirmEmail = () => {
  const { signInAuth0 } = useSignIn()
  const history = useHistory()

  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  const loginUser = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const claims = await getIdTokenClaims()

      const { shouldContinueSignup } = await signInAuth0({
        idToken: claims!.__raw,
        accessToken,
      })
      if (shouldContinueSignup) {
        history.push('/welcome')
      } else {
        history.push('/')
      }
    } catch (err) {
      history.replace('/sign-in')
      Toast.display(err.message, 'error')
    }
  }

  useEffect(() => {
    loginUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

export default ConfirmEmail
