import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import { EMAILS } from 'routes/constant/commonRoutes'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Update } from 'utils/types/update'

interface Props {
  update?: Update
  isCreatingNewUpdate: boolean
}

const EmailsBreadcrumb = ({ update, isCreatingNewUpdate }: Props) => {
  const intl = useIntl()
  const history = useHistory()

  if (!update) return null

  return (
    <NavigationWrapper>
      <>
        <InitialLink
          isActive={false}
          label={intl.formatMessage({ id: 'breadcrumb.emails' })}
          onClickHandler={() => history.push(EMAILS)}
        />

        {update && (
          <ArrowWrapper>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </ArrowWrapper>
        )}
      </>

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default EmailsBreadcrumb
