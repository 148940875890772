/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'

export const ExpandableRowState = React.createContext({})

const reducer = (state, action) => {
  const setValue = (key) => ({
    ...state,
    [action.payload.rowIndex]: {
      ...state[action.payload.rowIndex],
      [key]: action.payload.value,
    },
  })

  switch (action.type) {
    case 'setInitialRowState':
      return {
        ...state,
        [action.payload.rowIndex]: action.payload.data,
      }
    case 'setSortBy':
      return setValue('sortBy')
    case 'setSortDirection':
      return setValue('sortDirection')
    case 'setPage':
      return setValue('page')
    case 'setExpandableData':
      return setValue('expandableData')
    case 'pushExpandableData':
      return {
        ...state,
        [action.payload.rowIndex]: {
          ...state[action.payload.rowIndex],
          expandableData: [
            ...state[action.payload.rowIndex].expandableData,
            ...action.payload.value,
          ],
        },
      }
    case 'setIsLoadingExpandableRow':
      return setValue('isLoadingExpandableRow')
    case 'setIsExpanded':
      return setValue('isExpanded')
    default:
      throw new Error(`Invalid action ${action.type}`)
  }
}

export const useExpandableRowState = (rowIndex, initialState) => {
  const [state, dispatch] = useContext(ExpandableRowState)

  useEffect(() => {
    if (!state[rowIndex]) {
      dispatch({
        type: 'setInitialRowState',
        payload: { rowIndex, data: initialState },
      })
    }
  }, [])

  const rowState = state[rowIndex]

  return {
    ...(rowState ?? {}),
    dispatch: (action) =>
      dispatch({ ...action, payload: { value: action.payload, rowIndex } }),
  }
}

export const ExpandableRowStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {})

  return (
    <ExpandableRowState.Provider value={[state, dispatch]}>
      {children}
    </ExpandableRowState.Provider>
  )
}

ExpandableRowStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
