import { useCallback } from 'react'
import { FounderRoutes, RoutesType } from 'reducers/breadcrumbSlice'
import FounderDashboardBreadcrumb from '../FounderDashboardBreadcrumb'
import useBreadcrumbNavigation from '../../useBreadcrumbNavigation'

const founderBreadcrumb = {
  dashboard: FounderDashboardBreadcrumb,
}

interface Props {
  baseRoute: RoutesType
}

const useFounderBreadcrumb = ({ baseRoute }: Props) => {
  const { update, isCreatingNewUpdate, entityToShow, url, getAvatar } =
    useBreadcrumbNavigation()

  const navigationComponentFunc = useCallback(() => {
    switch (baseRoute) {
      case FounderRoutes.dashboard:
        return founderBreadcrumb.dashboard({
          entityToShow,
          isCreatingNewUpdate,
          update,
          url,
          getAvatar,
        })
      default:
        return null
    }
  }, [baseRoute, entityToShow, getAvatar, isCreatingNewUpdate, update, url])

  return {
    navigationComponentFunc,
  }
}

export default useFounderBreadcrumb
