import type { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import {
  StyledHiddenValue,
  VisibleValues,
  Values,
  IconWrapper,
} from './HiddenValue.styles'

interface HiddenValueProps {
  value: string
  isDisplayedByDefault?: boolean
  visibleQuantity?: number
  iconSize?: SizeProp
}

const maskValue = (value: string, visibleQuantity, maskSymbol = '●') => {
  if (value.length <= visibleQuantity) return value.replace(/./g, maskSymbol)

  return (
    value.slice(0, -visibleQuantity).replace(/./g, maskSymbol) +
    value.slice(-visibleQuantity)
  )
}

const getValues = (value: string, visibleQuantity: number) => {
  return maskValue(value, visibleQuantity)
}

export const HiddenValue: React.FC<HiddenValueProps> = ({
  value,
  visibleQuantity = 4,
  isDisplayedByDefault = false,
  iconSize,
}) => {
  const [isVisible, setIsVisible] = useState(isDisplayedByDefault)
  const valuesToDisplay = getValues(value, visibleQuantity)

  const onVisibilityClickHandler = () => {
    setIsVisible((visible) => !visible)
  }

  return valuesToDisplay ? (
    <StyledHiddenValue className="fs-exclude">
      <Values>
        <VisibleValues>
          {isVisible ? value : getValues(value, visibleQuantity)}
        </VisibleValues>
      </Values>
      <IconWrapper>
        <FontAwesomeIcon
          size={iconSize}
          icon={['far', isVisible ? 'eye-slash' : 'eye']}
          onClick={onVisibilityClickHandler}
        />
      </IconWrapper>
    </StyledHiddenValue>
  ) : null
}

export default React.memo(HiddenValue)
