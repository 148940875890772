import { useCallback, useMemo } from 'react'
import type { Channel } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'

import { useArchiveChannels } from 'containers/Chat/hooks/useArchiveChannels'
import {
  ChannelType,
  isTopicChannel,
  StreamChatType,
  TopicChannel,
} from 'containers/Chat/types'
import { getUser } from 'selectors/auth'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { TopicEntity } from 'utils/types/chatTopicEntity'

import { maximizeChatPopupById } from 'features/chatSlice'

export const useChatWidget = (topic?: TopicEntity, updateId?: string) => {
  const user = useAppSelector(getUser)
  const dispatch = useAppDispatch()

  const { isChannelArchived } = useArchiveChannels()

  const { setActiveChannel } = useChatContext()

  const filters = useMemo(
    () => ({
      type: {
        $in: [ChannelType.HOLDING, ChannelType.PORTFOLIO],
      },
      members: { $in: [user.id] },
      entityId: topic?.id,
      updateId,
    }),
    [topic?.id, updateId, user.id]
  )

  const renderChannels = useCallback(
    (
      channels: Channel<StreamChatType<TopicChannel>>[],
      channelPreview: (
        item: Channel<StreamChatType<TopicChannel>>
      ) => React.ReactNode
    ) => {
      const filteredChannels = channels.filter(
        (channel) =>
          isTopicChannel(channel.data) &&
          channel.data.entityId === topic?.id &&
          !isChannelArchived(channel.id)
      )

      return filteredChannels.map(channelPreview)
    },
    [isChannelArchived, topic?.id]
  )

  const openChatAsPopup = useCallback(
    (channel: Channel<StreamChatType>) => {
      dispatch(maximizeChatPopupById(channel.id!))
      setActiveChannel(undefined)
    },
    [dispatch, setActiveChannel]
  )

  return {
    filters,
    renderChannels,
    openChatAsPopup,
  }
}

export type UseChatWidgetType = ReturnType<typeof useChatWidget>
