import React from 'react'
import { useChannelStateContext } from 'stream-chat-react'

import { StreamChatType, isTopicChannel } from 'containers/Chat/types'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { UpdateType } from 'utils/types/common'
import { HoldingType } from 'utils/types/company'
import { PortfolioType } from 'utils/types/portfolios'
import CreateNewCompanyChatSuggestion from './CreateNewCompanyChatSuggestion'
import CreateNewFundProfileChatSuggestion from './CreateNewFundProfileChatSuggestion'
import CreateNewPortfolioChatSuggestion from './CreateNewPortfolioChatSuggestion'
import CreateNewUpdateChatSuggestion from './CreateNewUpdateChatSuggestion'

interface CreateNewChatSuggestionProps {
  entityId?: string
  updateId?: string
  type: HoldingType | UpdateType | PortfolioType
}

const CreateNewChatSuggestion: React.FC<CreateNewChatSuggestionProps> = ({
  entityId,
  updateId,
  type,
}) => {
  const { channel } = useChannelStateContext<StreamChatType>()

  if (isTopicChannel(channel.data) && channel.data.entityId === entityId) {
    return null
  }

  if (type === HoldingType.COMPANY) {
    return <CreateNewCompanyChatSuggestion companyId={entityId!} />
  }

  if (type === HoldingType.FUND) {
    return <CreateNewFundProfileChatSuggestion fundProfileId={entityId!} />
  }

  if (
    [PortfolioTypes.FUND, PortfolioTypes.INVEST, PortfolioTypes.TRACK].includes(
      type as PortfolioType
    )
  ) {
    return (
      <CreateNewPortfolioChatSuggestion
        portfolioId={entityId!}
        portfolioType={type as PortfolioType}
      />
    )
  }

  return (
    <CreateNewUpdateChatSuggestion
      updateId={updateId!}
      updateType={type as UpdateType}
    />
  )
}

export default CreateNewChatSuggestion
