import type { FormikProps } from 'formik'
import { ScheduleType } from 'utils/types/update'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import { getSendEmailDraftSchema } from 'utils/schemas/email'
import type { IntlShape } from 'react-intl'
import {
  useCreateEmailMutation,
  useEmailsQuery,
} from 'utils/hooks/queries/useEmailsQuery'
import { useParams } from 'react-router-dom'
import { useMemo, useRef } from 'react'
import { CreateEmailFormValues } from 'utils/types/emailForm'
import { Nullable } from 'utils/types/common'

interface Props {
  intl: IntlShape
}

const useCreateEmail = ({ intl }: Props) => {
  const { updateId } = useParams<{ updateId: string }>()
  const { data: emailUpdate, isLoading } = useEmailsQuery(updateId)
  const isFounder = useAppSelector(isActingAsFounder)
  const schema = getSendEmailDraftSchema(intl, isFounder)

  const formikRef = useRef<FormikProps<CreateEmailFormValues>>(null)

  const emailValues = useMemo<Nullable<CreateEmailFormValues>>(() => {
    if (isLoading || !emailUpdate) return null

    return {
      fromName: '',
      subject: '',
      replyTo: '',
      scheduleType: ScheduleType.SEND_NOW,
      date: new Date(),
      files: emailUpdate?.contents || [],
    }
  }, [emailUpdate, isLoading])

  const { mutate: createEmail } = useCreateEmailMutation({
    updateId: emailUpdate?.item.id!,
    loggingUpdateId: emailUpdate?.id!,
    formikRef,
  })

  return { emailValues, schema, formikRef, emailUpdate, createEmail }
}

export default useCreateEmail
