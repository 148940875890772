import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styles from './TotalAnalyticsCard.styles'

const TotalAnalyticsCard = ({ icon, value, label }) => {
  return (
    <Styles.Container>
      <Styles.Title>
        <FontAwesomeIcon icon={['fal', icon]} />
        <Styles.Value className="fs-exclude">{value}</Styles.Value>
      </Styles.Title>
      <Styles.Label>{label}</Styles.Label>
    </Styles.Container>
  )
}

TotalAnalyticsCard.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

export default TotalAnalyticsCard
