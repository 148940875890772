import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from 'utils/theme'
import { useRandomId } from 'utils/hooks/useRandomId'
import { StyledTooltip } from './RowErrorIcon.styles'

export enum RowErrorType {
  ERROR = 'error',
  WARNING = 'warning',
}

export const RowErrorTypeToColor = {
  [RowErrorType.ERROR]: theme.colors.red,
  [RowErrorType.WARNING]: theme.colors.yellow,
}

interface RowErrorIconProps {
  type: RowErrorType
  message?: string
  right?: string
}

const RowErrorIcon = ({ type, message, right }: RowErrorIconProps) => {
  const id = useRandomId()

  return (
    <StyledTooltip
      id={id}
      text={message}
      place="bottom"
      backgroundColor={RowErrorTypeToColor[type]}
      right={right}
    >
      <FontAwesomeIcon
        icon={['far', 'exclamation-triangle']}
        color={RowErrorTypeToColor[type]}
      />
    </StyledTooltip>
  )
}

export default RowErrorIcon
