import { useId } from 'react'
import { useIntl } from 'react-intl'

import Dropdown from 'ui/Dropdown'
import { UpdateType } from 'utils/types/common'
import { SuggestedUpdate } from 'utils/types/update'
import { SuggestedUpdateFields } from 'utils/types/suggestedUpdate'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GroupLogo from 'components/GroupLogo'
import Switch from 'components/Switch/Switch'
import Tooltip from 'components/Tooltip'
import TopicEntityDropdown from 'components/TopicEntityDropdown'
import { TransactionFundType } from 'utils/constants/transactionTypes'
import { UpdateTypes } from 'utils/constants/updates'
import { Investor } from 'utils/types/investors'
import { TooltipContent } from '../../../../../../containers/Chat/components/ChatSidebar/components/ChannelPreview/ChannelPreview.styles'
import {
  Container,
  OptionContainer,
  OptionIcon,
  OptionText,
  OptionTextContainer,
  UpdateFromDropdownWrapper,
  UpdateTypeDropdownWrapper,
} from './SuggestedUpdateForm.styles'
import { useSuggestedUpdateForm } from './hooks/useSuggestedUpdateForm'

interface Props {
  suggestedUpdate: SuggestedUpdate
}

const SuggestedUpdateForm = ({ suggestedUpdate }: Props) => {
  const id = useId()
  const {
    updateTypeField,
    isSelectFromAvailable,
    isTransactionFundTypeAvailable,
    dropdownUpdateFromRef,
    onUpdateTypeChange,
    onTransactionTypeChange,
    selectedTransactionFundType,
    investorField,
    loadInvestors,
    selectableUpdateTypes,
    selectablePortfolios,
    areHoldingsSelectable,
  } = useSuggestedUpdateForm(suggestedUpdate)
  const intl = useIntl()

  const renderTransactionTypeSelector = () => {
    return (
      <Switch
        options={[
          {
            id: TransactionFundType.HOLDING,
            label: (
              <Tooltip
                id={`holding_transaction_type_${id}`}
                place="bottom"
                backgroundColor="transparent"
                text={
                  <TooltipContent>
                    {intl.formatMessage({
                      id: 'suggestedUpdates.transactionTypeTooltip.holding',
                    })}
                  </TooltipContent>
                }
              >
                <FontAwesomeIcon icon={['far', 'building']} />
              </Tooltip>
            ),
          },
          {
            id: TransactionFundType.INVESTOR,
            label: (
              <Tooltip
                id={`investor_transaction_type_${id}`}
                place="bottom"
                backgroundColor="transparent"
                text={
                  <TooltipContent>
                    {intl.formatMessage({
                      id: 'suggestedUpdates.transactionTypeTooltip.investor',
                    })}
                  </TooltipContent>
                }
              >
                <FontAwesomeIcon icon={['far', 'user-tie']} />
              </Tooltip>
            ),
          },
        ]}
        valueId={selectedTransactionFundType}
        onChange={onTransactionTypeChange}
        width="8.3rem"
        fontSize="1.6rem"
        unSelectedColor="transparent"
      />
    )
  }

  const renderInvestorOption = (option: {
    id: string
    logo: string
    label: string
  }) => {
    return (
      <OptionContainer>
        <GroupLogo small imageUrl={option.logo} />
        <OptionTextContainer>
          <OptionText>{option.label}</OptionText>
        </OptionTextContainer>
      </OptionContainer>
    )
  }

  const renderTopicEntityDropdown = (placeholderIntlKey: string) => {
    return (
      <UpdateFromDropdownWrapper>
        <TopicEntityDropdown
          ref={dropdownUpdateFromRef}
          name={SuggestedUpdateFields.updateFrom}
          id="suggested-update-from"
          label=""
          placeholder={intl.formatMessage({
            id: placeholderIntlKey,
          })}
          queryPortfolios={!!selectablePortfolios?.length}
          queryHoldings={areHoldingsSelectable}
          clearEnabled={false}
          portfoliosQuery={selectablePortfolios}
        />
      </UpdateFromDropdownWrapper>
    )
  }

  const renderUpdateEntitySelector = () => {
    const [investorInputField, , investorFieldHelpers] = investorField
    if (updateTypeField.value === UpdateTypes.TRANSACTION) {
      if (!selectedTransactionFundType) return null
      if (selectedTransactionFundType === TransactionFundType.INVESTOR) {
        return (
          <UpdateFromDropdownWrapper>
            <Dropdown
              id="investor"
              name="investor"
              async
              highlightEnabled
              highlightSelectedOption={false}
              type="input"
              placeholder={intl.formatMessage({
                id: 'suggestedUpdates.selectInvestorPlaceholder',
              })}
              onSelectOption={(_, __, value) =>
                investorFieldHelpers.setValue(value)
              }
              loadOptions={loadInvestors}
              showBorder
              getOption={(option: Investor) => ({
                id: option.id,
                label: option.name,
                logo: option.logo?.smallLogo.url,
              })}
              addNewOptionEnabled={false}
              arrowNavigationEnabled
              value={investorInputField.value}
              formatSelectedOptionComponent={renderInvestorOption}
            />
          </UpdateFromDropdownWrapper>
        )
      }

      return renderTopicEntityDropdown(
        'suggestedUpdates.selectHoldingPlaceholder'
      )
    }

    if (isSelectFromAvailable) {
      return renderTopicEntityDropdown(
        'suggestedUpdates.selectUpdateFromPlaceholder'
      )
    }

    return null
  }

  return (
    <Container>
      <UpdateTypeDropdownWrapper>
        <Dropdown
          id="suggested-update-type"
          name="suggested-update-type"
          placeholder={intl.formatMessage({
            id: 'suggestedUpdates.selectUpdateType.placeholder',
          })}
          options={selectableUpdateTypes}
          onSelectOption={(_, updateType: UpdateType) => {
            onUpdateTypeChange(updateType)
          }}
          value={updateTypeField.value}
          formatSelectedOptionComponent={(option) => (
            <OptionContainer>
              <OptionIcon updateType={option.id as UpdateType} />
              {option.label}
            </OptionContainer>
          )}
          margin="initial"
        />
      </UpdateTypeDropdownWrapper>
      {isTransactionFundTypeAvailable && renderTransactionTypeSelector()}
      {renderUpdateEntitySelector()}
    </Container>
  )
}

export default SuggestedUpdateForm
