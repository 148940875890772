import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  width: 100%;
  background-color: ${color('veryLightBlue', 0.2)};
  border-bottom: 0.1rem solid ${color('borderGray')};
  padding: 1.6rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
    font-size: 1.6rem;
  }
`

export const FileName = styled.span`
  font-weight: bold;
  font-size: 1.4rem;
  color: ${color('darkBlue')};
`

export const ValuesImportedMessage = styled.span`
  font-size: 1.4rem;
  color: ${color('lightGray')};
`

export const Separator = styled.span`
  padding: 0 1rem;
`
