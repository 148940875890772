import React from 'react'
import { ProfileData } from 'components/AddHoldingModal/types'
import Button from 'ui/Button/Button'
import Avatar from 'components/Avatar'
import { getHoldingImage, isFundHolding } from 'utils/types/company'
import { getInitials } from 'utils/functions/user'
import * as Styles from './HoldingNameCellTemplate.styles'

interface FundProfileSuggestionButtonProps {
  profileData: ProfileData
  onClick: () => void
}

const FundProfileSuggestionButton: React.FC<
  FundProfileSuggestionButtonProps
> = ({ profileData, onClick }) => {
  const fundHolding = profileData.holding!

  if (!isFundHolding(fundHolding)) {
    return null
  }

  return (
    <Button secondary onClick={onClick}>
      <Styles.ProfileSuggestions.Content>
        <Avatar
          image={getHoldingImage(fundHolding)}
          initials={getInitials(fundHolding.name)}
          avatarStyle="avatarCircleXSM"
        />
        <Styles.ProfileSuggestions.FundInfo>
          <div>{fundHolding.name}</div>
          {fundHolding.group && (
            <Styles.ProfileSuggestions.ManagerName>
              {fundHolding.group.name}
            </Styles.ProfileSuggestions.ManagerName>
          )}
        </Styles.ProfileSuggestions.FundInfo>
      </Styles.ProfileSuggestions.Content>
    </Button>
  )
}

export default FundProfileSuggestionButton
