import { useUpdateNavigationHeader } from 'containers/UpdatesV2/components/UpdateHeader/useUpdateNavigationHeader'
import useQueryString from 'utils/hooks/useQueryString'

const useNavigationUpdateAction = () => {
  const throughSharedLink = useQueryString('shared') === 'true'
  const displayNavigation = !throughSharedLink

  const {
    disablePreviousButton,
    disableNextButton,
    onClickNavigationToUpdate,
  } = useUpdateNavigationHeader()

  return {
    displayNavigation,
    disableNextButton,
    disablePreviousButton,
    onClickNavigationToUpdate,
  }
}

export default useNavigationUpdateAction
