import styled, { createGlobalStyle } from 'styled-components'
import ButtonToStyle from 'ui/Button'
import { maxSize } from 'utils/constants/breakpoint'

export const ListStyles = createGlobalStyle`
  li[class*='row'] {
    min-width: initial
  }
`

export const Button = styled(ButtonToStyle)`
  @media ${maxSize.xsm} {
    width: fit-content;
  }
`
