import compact from 'lodash/compact'

import { getIsReadOnlyMode, getCurrentGroupData, getUser } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { useAuthentication } from 'utils/hooks/auth'

export const useBeamer = () => {
  const initialMatch = true
  const { matches: isMobile } = useMediaQuery(Media.MAX_MD, initialMatch)
  const { isAuthenticated } = useAuthentication()

  const user = useAppSelector(getUser)
  const group = useAppSelector(getCurrentGroupData)
  const isReadOnly = useAppSelector(getIsReadOnlyMode)
  const isDevelopmentMode =
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  const filters = compact([
    group?.type,
    isReadOnly ? 'Read-only-client' : '',
    isDevelopmentMode ? 'Development' : '',
  ]).join(';')

  const args = {
    selector: 'beamerButton',
    button: false,
    user_firstname: user?.firstName,
    user_email: user?.email,
    user_lastname: user?.lastName,
    filter: filters,
  }

  return {
    isBeamerEnabled: !isMobile && isAuthenticated,
    args,
  }
}
