import { useCallback, useEffect } from 'react'
import { useChatContext } from 'stream-chat-react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'

import {
  ChannelType,
  EventStreamChatType,
  StreamChatType,
} from 'containers/Chat/types'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useNotifications } from 'utils/hooks/useNotifications'
import { getUser } from 'selectors/auth'
import { areSameDates } from 'utils/functions/date'
import {
  getAddedToDirectMessageText,
  getAddedToTopicChannelText,
  getChannelFromEvent,
  getChatTitleFromEvent,
  getCreatedDirectMessageText,
  getCreatedTopicChannelText,
  getInText,
  getMentionedYouText,
  getNewMessageText,
  getPendingMessagesText,
} from './chatHelper'

export const useChatNotifications = () => {
  const intl = useIntl()
  const currentUser = useAppSelector(getUser)
  const { client } = useChatContext<StreamChatType>('useChatNotifications')
  const history = useHistory()
  const location = useLocation()
  const { showNotification } = useNotifications()

  const onNotificationClick = useCallback(
    (chatId?: string) => {
      if (chatId) {
        history.push(`/chat/${chatId}`)
      }
    },
    [history]
  )

  const notifyAboutUnreadChats = useCallback(async () => {
    const channels = await client.queryChannels(
      {
        members: { $in: [currentUser.id] },
      },
      {},
      { watch: false, state: false }
    )

    channels.forEach((channel) => {
      const unreadCount = channel.countUnread()
      if (unreadCount > 0 && !channel.muteStatus().muted) {
        const inText = getInText(intl, channel, currentUser.id)
        const body = getPendingMessagesText(intl, unreadCount, inText)

        showNotification(body, () => onNotificationClick(channel.id))
      }
    })
  }, [client, currentUser.id, intl, onNotificationClick, showNotification])

  useEffect(() => {
    const notifyAddedToAChat = (event: EventStreamChatType) => {
      const channelOwner = event.channel.created_by
      const ownerName = channelOwner?.name

      if (channelOwner?.id !== currentUser.id) {
        const channel = getChannelFromEvent(event)
        if (channel) {
          const title = getChatTitleFromEvent(
            intl,
            channel,
            event.channel_type as ChannelType,
            currentUser.id
          )
          const topicTitle =
            !channel.name && !channel.data?.name ? undefined : title

          let body = getCreatedDirectMessageText(intl, title, ownerName)

          if (areSameDates(channel.created_at, event.created_at)) {
            if (event.channel_type !== ChannelType.DIRECT_MESSAGE) {
              body = getCreatedTopicChannelText(
                intl,
                topicTitle,
                channel.updateTitle ?? channel.topicName,
                ownerName
              )
            }
          } else if (event.channel_type === ChannelType.DIRECT_MESSAGE) {
            body = getAddedToDirectMessageText(intl, title, ownerName)
          } else {
            body = getAddedToTopicChannelText(
              intl,
              topicTitle,
              channel.updateTitle ?? channel.topicName,
              ownerName
            )
          }

          showNotification(body, () => onNotificationClick(channel.id))
        }
      }
    }

    client?.on('notification.added_to_channel', notifyAddedToAChat)

    return () => {
      client?.off('notification.added_to_channel', notifyAddedToAChat)
    }
  }, [client, currentUser.id, intl, onNotificationClick, showNotification])

  useEffect(() => {
    const notifyNewMessage = (event: EventStreamChatType) => {
      const sender = event.message?.user
      const channel =
        getChannelFromEvent(event) ||
        client?.channel(event.channel_type!, event.channel_id!)

      const isMuted = channel.muteStatus?.()?.muted ?? false
      const notify =
        !isMuted &&
        sender?.id !== currentUser.id &&
        !location.pathname.includes('chat')

      if (notify) {
        const inText = getInText(intl, channel, currentUser.id)

        let body = getNewMessageText(intl, inText)
        const hasBeenMentioned = event.message?.mentioned_users?.some(
          (u) => u.id === currentUser?.id
        )

        if (hasBeenMentioned) {
          body = getMentionedYouText(intl, inText, sender?.name)
        }

        showNotification(body, () => onNotificationClick(channel.id))
      }
    }

    client?.on('notification.message_new', notifyNewMessage)
    client?.on('message.new', notifyNewMessage)

    return () => {
      client?.off('notification.message_new', notifyNewMessage)
      client?.off('message.new', notifyNewMessage)
    }
  }, [
    client,
    currentUser.id,
    intl,
    location,
    onNotificationClick,
    showNotification,
  ])

  useEffect(() => {
    if (client) {
      notifyAboutUnreadChats()
    }
  }, [client, notifyAboutUnreadChats])

  return {}
}
