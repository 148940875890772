import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import Card from 'components/Card'

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 1.7rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: capitalize;
  color: ${color('darkBlue')};
`

export const NormalTitle = styled(Title)`
  text-transform: none;
`

export const CardBody = styled(Card.Body)<{ isExpanded?: boolean }>`
  margin: ${({ isExpanded }) => (isExpanded ? ' 1.7rem 0 1.4rem' : 0)};
`
