import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { ChipContainer, Title } from 'components/Chip/Chip.styles'
import { Scroll } from 'App.styles'

export const Container = styled.div`
  .react-select__value-container {
    cursor: text;
    padding: 0.4rem 0.8rem;

    ${Scroll()}
    overflow-x: hidden;
    max-height: 10rem;
    margin-right: 1rem;
  }
`

export const InputWrapper = styled.div<{ hasLeftPadding: boolean }>`
  font-size: 1.4rem;
  cursor: text;

  ${({ hasLeftPadding }) =>
    hasLeftPadding &&
    css`
      padding-left: 0.6rem;
    `}
`

export const OptionsTitle = styled.p`
  color: ${color('lightGray')};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 1.9rem 0 0.6rem 1.6rem;
`

export const OptionLeft = styled.div`
  display: flex;
  font-weight: 700;
`

export const OptionData = styled.div`
  padding-top: 0.1rem;
  padding-left: 0.8rem;
  max-width: 32rem;
`

export const OptionType = styled.span`
  color: ${color('lightGray')};
`

export const OptionSubtitle = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  color: ${color('lightGray')};
`

const hoverState = css`
  background-color: ${color('veryLightBlue', 0.2)};
  border-radius: 8px;

  ${OptionLeft}, ${OptionSubtitle} {
    color: ${color('primaryBlue')};
  }
`

export const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const OptionContainer = styled.div<{
  disabled: boolean
  isEmail: boolean
  isFocused: boolean
}>`
  height: ${({ isEmail }) => (isEmail ? '3.6rem' : 'fit-content')};
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 2rem;
  cursor: pointer;

  :hover {
    ${hoverState}
  }

  ${({ isFocused }) => isFocused && hoverState}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const ListRecipientsContainer = styled.div`
  height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const PlusSign = styled.div`
  font-size: 1.6rem;
  padding-right: 1.2rem;
  cursor: pointer;
`

export const PillWrapper = styled.div<{ withError: boolean }>`
  ${ChipContainer} {
    padding-left: 0rem;
    height: 2.3rem;

    ${({ withError }) =>
      withError &&
      css`
        background-color: ${color('red', 0.15)};
        border-width: 1px;

        ${Title} {
          color: ${color('gray')};
        }
      `}
  }
`

export const NoResults = styled.p`
  color: ${color('lightGray')};
  font-weight: 700;
  padding: 0.7rem 0;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
`
