import { useState, useRef, useCallback } from 'react'

const MAX_TEXT_LENGTH = 229

interface Props {
  updateText: string
}

export const useTipTapShowMoreLess = ({ updateText }: Props) => {
  const [isShowingMore, setIsShowingMore] = useState(false)
  const [isShowMoreNeeded, setIsShowMoreNeeded] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  const toggleShowingMore = useCallback(
    () => setIsShowingMore((currentShowMore) => !currentShowMore),
    []
  )

  const onEditorCreated = useCallback(
    (editorContent: Element) => {
      const editor = editorContent.firstChild as HTMLElement
      setIsShowMoreNeeded(
        editor.offsetHeight < editor.scrollHeight ||
          updateText?.length > MAX_TEXT_LENGTH
      )
    },
    [updateText?.length]
  )

  return {
    containerRef,
    isShowMoreNeeded,
    isShowingMore,
    toggleShowingMore,
    onEditorCreated,
  }
}
