import { motion } from 'framer-motion'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { ToastType } from './ToastMessage'

export const Toast = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.2rem 1.4rem;
  border-radius: 0.8rem;
  width: ${(props) => props.width};
  border: solid 0.1rem
    ${(props) =>
      props.type === ToastType.ALERT
        ? color('primaryBlue')(props)
        : color('red', 0.9)(props)};

  background-color: ${(props) =>
    props.type === ToastType.ALERT
      ? color('softBlue', 0.9)(props)
      : color('softRed', 0.9)(props)};

  svg {
    color: ${(props) =>
      props.type === ToastType.ALERT
        ? color('primaryBlue')(props)
        : color('red', 0.9)(props)};
    font-size: 1.5rem;
    margin-right: 0.625rem;
    margin-top: 0.2rem;
  }
`
export const ToastLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${({ iconPosition }) => iconPosition};
  color: ${(props) =>
    props.type === ToastType.ALERT
      ? color('primaryBlue')(props)
      : color('red', 0.9)(props)};

  font-size: 1.4rem;
  line-height: 1.7rem;

  a:hover {
    text-decoration: underline;
  }

  b {
    padding: 0 0.1rem;
  }
`
