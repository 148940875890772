import dayjs from 'dayjs'
import {
  UpdateEditStatus,
  UpdateTypes,
  UpdateVisibility,
  PlainSubjectMatters,
} from 'utils/constants/updates'
import { UpdateVisibilityType } from 'utils/types/common'
import { IndexUpdate } from '../types/update'

export const hasEditPermissions = (update: IndexUpdate): boolean => {
  return update.updatableStatus !== UpdateEditStatus.ONLY_RESHARE_ALLOWED
}

export const isUpdateContentEditable = (update: IndexUpdate): boolean => {
  return [
    UpdateEditStatus.FULL,
    UpdateEditStatus.FULL_OWNED_BY_GROUP_DISABLED_NO_DRAFT,
    UpdateEditStatus.TEMPORARY,
    UpdateEditStatus.TEMPORARY_OWNED_BY_GROUP_DISABLED,
  ].includes(update.updatableStatus)
}

export const updateContentHasFullPermissions = (
  update: IndexUpdate
): boolean => {
  return [
    UpdateEditStatus.FULL,
    UpdateEditStatus.FULL_OWNED_BY_GROUP_DISABLED_NO_DRAFT,
  ].includes(update.updatableStatus)
}

export const isUpdatePermissionsEditable = (update: IndexUpdate): boolean => {
  return (
    update.updatableStatus !== UpdateEditStatus.ONLY_RESHARE_ALLOWED &&
    update.updatableStatus !== UpdateEditStatus.NONE
  )
}

export const isUpdateEditable = (update: IndexUpdate): boolean => {
  return isUpdateContentEditable(update) || isUpdatePermissionsEditable(update)
}

export const canDowngradePermissions = (update: IndexUpdate): boolean => {
  return (
    update.updatableStatus !== UpdateEditStatus.NONE &&
    update.updatableStatus !== UpdateEditStatus.EXPIRED &&
    update.updatableStatus !==
      UpdateEditStatus.EXPIRED_OWNED_BY_GROUP_DISABLED &&
    hasEditPermissions(update)
  )
}

export const canEditReshare = (
  update: IndexUpdate,
  userId: string
): boolean => {
  const userHasResharesWithGroups = update.loggingUpdateGroups.some(
    (loggingUpdateGroup) =>
      loggingUpdateGroup.loggingReshares.some(
        (reshare) => reshare.userId === userId
      )
  )

  const userHasResharesWithUsers = update.loggingUpdateUsers.some(
    (loggingUpdateUser) =>
      loggingUpdateUser.loggingReshares.some(
        (reshare) => reshare.userId === userId
      )
  )

  return userHasResharesWithGroups || userHasResharesWithUsers
}

export const groupOwnedDisabled = (update: IndexUpdate): boolean => {
  return [
    UpdateEditStatus.EXPIRED_OWNED_BY_GROUP_DISABLED,
    UpdateEditStatus.TEMPORARY_OWNED_BY_GROUP_DISABLED,
    UpdateEditStatus.FULL_OWNED_BY_GROUP_DISABLED_NO_DRAFT,
    UpdateEditStatus.NONE,
    UpdateEditStatus.ONLY_RESHARE_ALLOWED,
  ].includes(update.updatableStatus)
}

export const setAsDraftVisible = (update: IndexUpdate): boolean => {
  return (
    update.updatableStatus !==
      UpdateEditStatus.FULL_OWNED_BY_GROUP_DISABLED_NO_DRAFT &&
    update.updatableStatus !==
      UpdateEditStatus.TEMPORARY_OWNED_BY_GROUP_DISABLED
  )
}

export const getUpdateHoursLeftToEdit = (expirationDate: string): number => {
  return dayjs(expirationDate).diff(dayjs(), 'hours')
}

export const isUpdateExpired = (expirationDate: string): boolean => {
  return getUpdateHoursLeftToEdit(expirationDate) < 0
}

export const canReshareUpdate = (
  update: IndexUpdate,
  isFundManager?: boolean,
  isClient?: boolean,
  currentUserId?: string,
  isPrivateCompany?: boolean,
  hasEditPermissionsValue?: boolean
): boolean => {
  const isFundPortfolioUpdate =
    update.item.subjectMatterType === PlainSubjectMatters.FUND_PORTFOLIO
  const isPublicFundPortfolio = !update.fundPortfolio?.hidden
  const isUpdateAllowed =
    update.updatableStatus === UpdateEditStatus.ONLY_RESHARE_ALLOWED
  const isTransactionUpdate = update.updateType === UpdateTypes.TRANSACTION
  const { isPortfolioUpdate } = update
  const isBlocked = update.blocked
  const updateIsFromCurrentUser = update && update.userId === currentUserId

  if (updateIsFromCurrentUser || isPrivateCompany || hasEditPermissionsValue) {
    return false
  }

  if (
    (isFundManager || isClient) &&
    isFundPortfolioUpdate &&
    isPublicFundPortfolio
  ) {
    return true
  }

  if ((isPortfolioUpdate && !isTransactionUpdate) || isBlocked) {
    return false
  }

  return isUpdateAllowed
}

export const isUpdateSharedOutsideGroup = (
  visibility: UpdateVisibilityType,
  sharedGroupIds: string[],
  currentGroupId: string
): boolean => {
  return (
    visibility === UpdateVisibility.PUBLIC ||
    sharedGroupIds.some((groupId) => groupId !== currentGroupId)
  )
}
