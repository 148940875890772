import React from 'react'
import PropTypes from 'prop-types'

import ReportSectionMainSummary from '../ReportSectionMainSummary'

import {
  ReportColumn,
  ReportSectionTitle,
  ReportSectionSubTitle,
  ReportItemsContainer,
  ReportSummary,
  ReportRowValues,
} from '../ShowReport.styles'

const ReportSection = ({
  periods,
  title,
  sectionRows,
  isMainSection,
  isMainSectionSummary,
  sectionSummary,
  shouldRemoveLastColumn,
}) => {
  if (isMainSectionSummary) {
    return (
      <ReportSectionMainSummary periods={periods} sectionRow={sectionRows[0]} />
    )
  }

  const slice = (arr, start = 0) =>
    arr?.slice?.(start, arr.length - shouldRemoveLastColumn) ?? []

  return (
    <ReportColumn labelColumn>
      <ReportSectionTitle isMainSection={isMainSection}>
        {title}
      </ReportSectionTitle>

      {sectionRows?.map?.((sectionRow, index) => {
        if (sectionRow.ColData) {
          return (
            <ReportRowValues periods={periods}>
              {slice(sectionRow.ColData).map((colDataValue) => (
                <ReportSectionSubTitle level={sectionRow.Header ? 2 : 1}>
                  {colDataValue.value}
                </ReportSectionSubTitle>
              ))}
            </ReportRowValues>
          )
        }

        return (
          <ReportItemsContainer>
            <ReportRowValues periods={periods}>
              {slice(sectionRow?.Header?.ColData).map?.((colDataValue) => (
                <ReportSectionSubTitle rowType={sectionRows.rowType}>
                  {colDataValue?.value}
                </ReportSectionSubTitle>
              ))}
            </ReportRowValues>
            {sectionRow.Rows?.Row.map((section) => {
              return (
                <>
                  {section.Header && (
                    <ReportRowValues periods={periods}>
                      {slice(section.Header?.ColData).map((colData) => (
                        <ReportSectionSubTitle level={2}>
                          {colData.value}
                        </ReportSectionSubTitle>
                      ))}
                    </ReportRowValues>
                  )}
                  <ReportRowValues periods={periods}>
                    {slice(section.ColData).map(
                      (colDataValue, colDataValueIndex) => (
                        <ReportSectionSubTitle
                          level={colDataValueIndex === 0 ? 2 : 1}
                        >
                          {colDataValue.value}
                        </ReportSectionSubTitle>
                      )
                    )}
                  </ReportRowValues>

                  {section.Rows?.Row.map((currSection) => {
                    if (currSection.type === 'Section') {
                      return (
                        <div>
                          <ReportRowValues periods={periods}>
                            {currSection.Header?.ColData?.map(
                              (headerColData) => (
                                <ReportSectionSubTitle level={2}>
                                  {headerColData.value}
                                </ReportSectionSubTitle>
                              )
                            )}
                          </ReportRowValues>

                          {currSection.Rows.Row.map((nestedSection) => {
                            return (
                              <ReportRowValues periods={periods}>
                                <ReportSectionSubTitle
                                  level={index === 0 ? 3 : 1}
                                >
                                  {nestedSection.ColData[0].value}
                                </ReportSectionSubTitle>

                                {slice(nestedSection.ColData, 1).map(
                                  (nestedSectionValue) => (
                                    <ReportSectionSubTitle>
                                      {nestedSectionValue?.value}
                                    </ReportSectionSubTitle>
                                  )
                                )}
                              </ReportRowValues>
                            )
                          })}

                          <ReportSummary marginLeft="0">
                            <ReportRowValues periods={periods}>
                              <ReportSectionSubTitle level={2}>
                                <p>{currSection.Summary?.ColData?.[0].value}</p>
                              </ReportSectionSubTitle>
                              {slice(currSection.Summary?.ColData, 1).map(
                                (nestedSectionValue) => (
                                  <ReportSectionSubTitle>
                                    {nestedSectionValue?.value}
                                  </ReportSectionSubTitle>
                                )
                              )}
                            </ReportRowValues>
                          </ReportSummary>
                        </div>
                      )
                    }
                    return (
                      <ReportRowValues periods={periods}>
                        {slice(currSection.ColData).map(
                          (colDataValue, colDataValueIndex) => (
                            <ReportSectionSubTitle
                              level={colDataValueIndex === 0 ? 3 : 1}
                            >
                              {colDataValue.value}
                            </ReportSectionSubTitle>
                          )
                        )}
                      </ReportRowValues>
                    )
                  })}
                  {section.Summary && (
                    <ReportSummary marginLeft="0">
                      <ReportRowValues periods={periods}>
                        <ReportSectionSubTitle level={3}>
                          <p>{section.Summary?.ColData?.[0].value}</p>
                        </ReportSectionSubTitle>
                        {slice(section.Summary?.ColData, 1).map(
                          (colDataValue) => (
                            <ReportSectionSubTitle>
                              {colDataValue.value}
                            </ReportSectionSubTitle>
                          )
                        )}
                      </ReportRowValues>
                    </ReportSummary>
                  )}
                </>
              )
            })}
            <ReportSummary marginLeft="0">
              <ReportRowValues periods={periods}>
                <ReportSectionSubTitle level={2}>
                  <p>{sectionRow.Summary?.ColData?.[0].value}</p>
                </ReportSectionSubTitle>
                {slice(sectionRow.Summary?.ColData, 1).map((colDataValue) => (
                  <ReportSectionSubTitle>
                    {colDataValue.value}
                  </ReportSectionSubTitle>
                ))}
              </ReportRowValues>
            </ReportSummary>
          </ReportItemsContainer>
        )
      })}
      <ReportSummary marginLeft="0">
        <ReportRowValues periods={periods}>
          <ReportSectionSubTitle>
            <p>{sectionSummary?.ColData?.[0].value}</p>
          </ReportSectionSubTitle>
          {slice(sectionSummary?.ColData, 1).map((colDataValue) => (
            <ReportSectionSubTitle>{colDataValue.value}</ReportSectionSubTitle>
          ))}
        </ReportRowValues>
      </ReportSummary>
    </ReportColumn>
  )
}

ReportSection.propTypes = {
  periods: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  sectionRows: PropTypes.array.isRequired,
  isMainSection: PropTypes.bool.isRequired,
  isMainSectionSummary: PropTypes.bool.isRequired,
  sectionSummary: PropTypes.object,
  shouldRemoveLastColumn: PropTypes.bool,
}

ReportSection.defaultProps = {
  sectionSummary: {},
  shouldRemoveLastColumn: false,
}

export default ReportSection
