import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const DropdownContainer = styled.div`
  margin-bottom: 2.4rem;
`
export const IconContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: ${color('primaryBlue')};
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 50%;
  justify-content: center;
  padding: 1.1rem;
  height: 1.8rem;
  width: 1.8rem;
  margin-right: 0.5rem;
  pointer-events: none !important;

  && svg path {
    color: ${color('white')};
  }
`

export const SelectedOptionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const SelectPlaceholder = styled.div`
  color: ${color('darkGray', 0.5)};
  font-weight: 400;
`
