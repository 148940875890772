interface SparkleIconProps {
  className?: string
  width?: string
  height?: string
}

const SparkleIcon = ({ className, width, height }: SparkleIconProps) => {
  return (
    <svg
      className={className}
      width={(width && `calc(${width} - 0.5px)`) || '40'}
      height={(height && `calc(${height} - 0.5px)`) || '40'}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <filter
          id="filter0_d_515_922"
          x="0"
          y="0"
          width={(width && `calc(${width} - 0.5px)`) || '40'}
          height={(height && `calc(${height} - 0.5px)`) || '40'}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_515_922"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_515_922"
            result="shape"
          />
        </filter>
      </defs>
      <g
        filter="url(#filter0_d_515_922)"
        transform="matrix(1, 0, 0, 1, 0.000032, 2.000012)"
        origin="0.5 0.502969"
      >
        <path
          d="M19.9758 25.999C20.4035 26.0043 20.6729 25.793 20.8102 25.3175C21.2274 23.8332 21.6552 22.3489 22.0565 20.8592C22.1674 20.4208 22.4315 20.1831 22.8645 20.0616C24.3538 19.6548 25.8378 19.2375 27.3218 18.8202C28.2513 18.5825 28.209 17.3834 27.2954 17.1721C25.8484 16.7654 24.4066 16.3534 22.9543 15.9677C22.4632 15.8357 22.1886 15.561 22.0565 15.0645C21.671 13.6118 21.2591 12.1697 20.8524 10.7224C20.6887 10.1519 20.2715 9.89302 19.7698 10.0409C19.4213 10.1413 19.2628 10.416 19.1678 10.7382C18.7558 12.2225 18.3333 13.7069 17.9267 15.1965C17.8316 15.5874 17.5782 15.8198 17.1979 15.9255C15.7034 16.3375 14.2088 16.7548 12.7142 17.1774C12.0013 17.3781 11.7636 18.1124 12.2706 18.5931C13.7758 19.3062 15.5185 19.5439 17.1134 20.0616C17.557 20.1831 17.8158 20.4419 17.9373 20.8856C18.3175 22.2855 18.7136 23.6853 19.1044 25.0798C19.2047 25.5394 19.4318 26.0254 19.9758 25.999Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default SparkleIcon
