import { EventType } from '../types'
import { BackendEvent } from './BackendEventsMapper'
import { UpdateEventsMapper } from './UpdateEventMapper'
import { UpdateLogChangeSetFields } from '../logs-builders/UpdateLogsBuilder'

export class AccountingUpdateEventsMapper extends UpdateEventsMapper {
  getLogsFromEvent(event: BackendEvent<UpdateLogChangeSetFields>) {
    if (event.event === EventType.ADD_SCHEDULE && event.changeset.repetition) {
      return [
        {
          ...super.buildLog(event, 'scheduleDate'),
          customValue: event.changeset.repetition?.[1],
        },
      ]
    }

    return super.getLogsFromEvent(event)
  }
}
