import React from 'react'
import { Formik } from 'formik'
import type { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import Button from 'ui/Button'
import Drawer from 'components/Drawer'
import FadeIn from 'components/FadeIn/FadeIn'
import { getSendInvitesSchema } from 'utils/schemas/investorManagement'
import { AddInvestorSendInvitesFormValues } from 'utils/types/investors'
import AddInvestorSendInvitesForm from '../../InvestorForm/AddInvestorSendInvitesForm'

interface SendInvitesStepProps {
  initialValues: AddInvestorSendInvitesFormValues
  onSubmit: (values: AddInvestorSendInvitesFormValues) => void
  onCancel: () => void
  isLoading: boolean
}

const SendInvitesStep: React.ForwardRefRenderFunction<
  FormikProps<AddInvestorSendInvitesFormValues>,
  SendInvitesStepProps
> = ({ initialValues, onSubmit, onCancel, isLoading }, ref) => {
  const intl = useIntl()

  return (
    <FadeIn>
      <Formik<AddInvestorSendInvitesFormValues>
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={getSendInvitesSchema()}
      >
        {(formik) => (
          <>
            <AddInvestorSendInvitesForm />
            <Drawer.Footer>
              <Button onClick={onCancel}>
                {intl.formatMessage({ id: 'general.cancel' })}
              </Button>
              <Button
                type="submit"
                primary
                onClick={formik.handleSubmit}
                disabled={
                  formik.isSubmitting ||
                  isLoading ||
                  (formik.values.sendInvitation && !formik.values.users.length)
                }
                isLoading={isLoading}
              >
                {formik.values.sendInvitation
                  ? intl.formatMessage({
                      id: 'investorManagement.addAndSendInvitation',
                    })
                  : intl.formatMessage({
                      id: 'investorManagement.add',
                    })}
              </Button>
            </Drawer.Footer>
          </>
        )}
      </Formik>
    </FadeIn>
  )
}

export default React.forwardRef(SendInvitesStep)
