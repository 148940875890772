import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const SideBarItemContainer = styled.button<{
  $isSelected?: boolean
  isEmbeddedItem?: boolean
  backgroundColor?: string
}>`
  height: 4.9rem;
  width: 100%;
  padding: 0 2.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 0.1s;
  background-color: ${(props) =>
    props.$isSelected ? color('primaryBlue')(props) : 'transparent'};

  justify-content: space-between;

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  ${(props) =>
    props.isEmbeddedItem &&
    css`
      padding-left: 5.5rem;
    `}

  &:hover {
    background-color: ${(props) =>
      props.$isSelected ? color('primaryBlue')(props) : '#1b2341'};
  }

  &:focus {
    outline: ${color('white')};
  }

  @media (max-width: 720px) {
    padding: 0 3.2rem;
    height: 5.5rem;
  }

  div.beamer_icon.active {
    width: 2.2rem;
    height: 1.8rem;
    border-radius: 1rem;
    right: 2.1rem;
    top: 1.6rem;
    transform: none;
  }
`

const ItemIcon = css<{ $gray?: boolean }>`
  width: 2rem;
  font-size: 1.6rem;
  margin-right: 1.2rem;
  color: ${(props) =>
    props.$gray ? color('lightGray')(props) : color('white')(props)};
`

export const SidebarItemIcon = styled(FontAwesomeIcon)`
  && {
    ${ItemIcon}
  }
`
export const ItemWrapper = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justify || 'space-between'};
  width: 100%;
`

export const StyledIcon = styled.div`
  ${ItemIcon}
`

export const SidebarItemTitle = styled.p<{ $gray?: boolean }>`
  font-size: 1.4rem;
  font-weight: 400;
  white-space: nowrap;
  color: ${(props) =>
    props.$gray ? color('lightGray')(props) : color('white')(props)};
`

export const MsgBubble = styled.span`
  width: 2rem;
  height: 2rem;
  background: red;
  margin-left: auto;
  border-radius: 100%;
  text-align: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: bold;
  padding-top: 0.2rem;
  padding-right: 0.1rem;
`

export const Expandable = styled.div<{ isExpanded: boolean }>`
  max-height: 0;
  transition: max-height 0.2s;
  overflow: hidden;
  will-change: max-height;
  ${(props) =>
    props.isExpanded &&
    css`
      max-height: 20rem;
    `}
`

export const AngleIcon = styled(FontAwesomeIcon)<{ isExpanded: boolean }>`
  color: white;

  transition: transform 0.2s;
  ${(props) =>
    props.isExpanded &&
    css`
      transform: rotate(180deg);
    `}
`

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
