/* eslint-disable no-case-declarations */
import dayjs from 'dayjs'
import {
  AccountingReportType,
  BalanceDateType,
  ReportDatePeriodType,
  PeriodType,
} from 'utils/constants/accounting'

import { Integrations } from 'utils/constants/integrations'
import { assertUnreachable } from './utils'
import {
  getRandomValue,
  getPeriodsForDate,
  getPeriodsFromReportDatePeriod,
} from './accounting'

export const getDateFromTimeFrame = (timeFrame, date) => {
  switch (timeFrame) {
    case BalanceDateType.END_OF_LAST_MONTH:
      const currDate = new Date()
      const lastDayLastMonth = new Date(
        currDate.getFullYear(),
        currDate.getMonth(),
        0
      )
      return dayjs(lastDayLastMonth).format('YYYY-MM-DD')
    case BalanceDateType.END_OF_LAST_QUARTER:
      const quarterAdjustment = (dayjs().month() % 3) + 1
      const lastQuarterEndDate = dayjs()
        .subtract(quarterAdjustment, 'month')
        .endOf('month')
      const endOfLastQuarter = lastQuarterEndDate.format('YYYY-MM-DD')
      return endOfLastQuarter
    case BalanceDateType.END_OF_LAST_FINANCIAL_YEAR:
      return dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
    default:
      return dayjs(date).format('YYYY-MM-DD')
  }
}

export const getTimeFrameFromReportDatePeriod = (
  reportDatePeriod: ReportDatePeriodType
) => {
  switch (reportDatePeriod) {
    case ReportDatePeriodType.CURRENT_MONTH:
    case ReportDatePeriodType.PREVIOUS_MONTH:
      return 'MONTH'
    case ReportDatePeriodType.CURRENT_QUARTER:
    case ReportDatePeriodType.PREVIOUS_QUARTER:
      return 'QUARTER'
    case ReportDatePeriodType.CURRENT_FINANCIAL_YEAR:
    case ReportDatePeriodType.PREVIOUS_FINANCIAL_YEAR:
      return 'YEAR'
    default:
      return assertUnreachable(reportDatePeriod)
  }
}

export const getReportDatePeriodFromTimeFrame = (
  timeFrame: string
): ReportDatePeriodType => {
  switch (timeFrame) {
    case 'MONTH':
      return ReportDatePeriodType.CURRENT_MONTH
    case 'QUARTER':
      return ReportDatePeriodType.CURRENT_QUARTER
    case 'YEAR':
      return ReportDatePeriodType.PREVIOUS_FINANCIAL_YEAR
    default:
      return assertUnreachable(timeFrame as never)
  }
}

export const getBalanceDateFromTimeFrame = (
  balanceDate: BalanceDateType | ReportDatePeriodType
): BalanceDateType => {
  switch (balanceDate) {
    case 'SCHEDULED_DATE':
      return BalanceDateType.SCHEDULED_DATE
    case 'PREVIOUS_MONTH':
      return BalanceDateType.END_OF_LAST_MONTH
    case 'PREVIOUS_QUARTER':
      return BalanceDateType.END_OF_LAST_QUARTER
    case 'PREVIOUS_FINANCIAL_YEAR':
      return BalanceDateType.END_OF_LAST_FINANCIAL_YEAR
    default:
      return balanceDate as BalanceDateType
  }
}

export const getTimeFrameFromBalanceDate = (balanceDate: BalanceDateType) => {
  switch (balanceDate) {
    case BalanceDateType.SCHEDULED_DATE:
      return 'SCHEDULED_DATE'
    case BalanceDateType.END_OF_LAST_MONTH:
      return 'PREVIOUS_MONTH'
    case BalanceDateType.END_OF_LAST_QUARTER:
      return 'PREVIOUS_QUARTER'
    case BalanceDateType.END_OF_LAST_FINANCIAL_YEAR:
      return 'PREVIOUS_FINANCIAL_YEAR'
    default:
      return balanceDate
  }
}

export const setXeroReportParams = (
  formdata,
  reportType,
  reportParams,
  { entity, date }
) => {
  formdata.append(
    `${entity}[report_data][periods]`,
    parseInt(reportParams.periods, 10)
  )
  formdata.append(
    `${entity}[report_data][paymentsOnly]`,
    reportParams.paymentsOnly
  )

  if (
    reportType === AccountingReportType.XERO_BALANCE_SHEET ||
    reportType === AccountingReportType.QUICKBOOKS_BALANCE_SHEET
  ) {
    const dateFromTimeFrame = getDateFromTimeFrame(
      reportParams.balanceDate,
      date
    )
    formdata.append(`${entity}[report_type]`, 'BalanceSheet')
    formdata.append(`${entity}[report_data][date]`, dateFromTimeFrame)

    if (reportType.includes('XERO')) {
      formdata.append(
        `${entity}[report_data][timeFrame]`,
        reportParams.balanceDate
      )
      formdata.append(
        `${entity}[report_data][standardLayout]`,
        reportParams.standardLayout
      )
    } else {
      formdata.append(
        `${entity}[report_data][timeFrame]`,
        getTimeFrameFromBalanceDate(reportParams.balanceDate)
      )
    }
  } else if (
    reportType === AccountingReportType.XERO_PROFIT_AND_LOSS ||
    reportType === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS
  ) {
    formdata.append(`${entity}[report_type]`, 'ProfitAndLoss')
    formdata.append(
      `${entity}[report_data][timeFrame]`,
      getTimeFrameFromReportDatePeriod(reportParams.reportDatePeriod)
    )
    formdata.append(
      `${entity}[report_data][reportDatePeriod]`,
      reportParams.reportDatePeriod
    )
    if (reportType.includes('XERO')) {
      formdata.append(
        `${entity}[report_data][standardLayout]`,
        reportParams.standardLayout
      )
    }
  }
}

export const getReportTypeFromBackendType = (reportType, integrationType) => {
  if (integrationType === Integrations.XERO) {
    switch (reportType) {
      case 'profit_and_loss':
        return AccountingReportType.XERO_PROFIT_AND_LOSS
      case 'balance_sheet':
        return AccountingReportType.XERO_BALANCE_SHEET
      default:
        return ''
    }
  }
  switch (reportType) {
    case 'profit_and_loss':
      return AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS
    case 'balance_sheet':
      return AccountingReportType.QUICKBOOKS_BALANCE_SHEET
    default:
      return ''
  }
}

export const getReportName = (update) => {
  const switchStatement =
    update?.xeroReport?.reportType || update?.quickbooksReport?.reportType
  switch (switchStatement) {
    case 'profit_and_loss':
      return 'Profit & Loss'
    case 'balance_sheet':
      return 'Balance Sheet'
    default:
      return ''
  }
}

const toBoolean = (value) =>
  typeof value === 'string' ? value === 'true' : value

export const getFormValuesForReportData = (reportType, reportData) => {
  switch (reportType) {
    case AccountingReportType.XERO_BALANCE_SHEET:
    case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
      return {
        periods: `${reportData?.periods}`,
        balanceDate: reportData?.timeFrame,
        paymentsOnly: toBoolean(reportData?.paymentsOnly),
        standardLayout: toBoolean(reportData?.standardLayout),
      }
    case AccountingReportType.XERO_PROFIT_AND_LOSS:
    case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
      return {
        periods: `${reportData?.periods}`,
        reportDatePeriod: reportData?.reportDatePeriod,
        paymentsOnly: toBoolean(reportData?.paymentsOnly),
        standardLayout: toBoolean(reportData?.standardLayout),
      }
    default:
      return {}
  }
}

export const getMockedBalanceSheet = ({
  periods = 2,
  balanceDate = BalanceDateType.END_OF_LAST_MONTH,
  date = new Date(),
}) => {
  return {
    reportID: 'BalanceSheet',
    reportName: 'Balance Sheet',
    reportType: 'BalanceSheet',
    reportTitles: [
      'Balance Sheet',
      'Demo Company (Global)',
      `As at ${dayjs(date).format('DD MMMM YYYY')}`,
    ],
    reportDate: dayjs(date).format('DD MMMM YYYY'),
    rows: [
      {
        rowType: 'Header',
        cells: [
          {
            value: '',
          },
          ...getPeriodsForDate(date, balanceDate, periods).map((dateStr) => ({
            value: dateStr,
          })),
        ],
      },

      {
        rowType: 'Section',
        title: 'Assets',
        rows: [],
      },

      {
        rowType: 'Section',
        title: 'Bank',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Business Bank Account',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Bank',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },

      {
        rowType: 'Section',
        title: 'Current Assets',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Accounts Receivable',
                attributes: [
                  {
                    id: 'account',
                    value: '9af45928-6ad1-462d-b4c6-46d8f58255be',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: '9af45928-6ad1-462d-b4c6-46d8f58255be',
                  },
                ],
              })),
            ],
          },

          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Current Assets',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },

      {
        rowType: 'Section',
        title: 'Fixed Assets',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Computer Equipment',
                attributes: [
                  {
                    id: 'account',
                    value: '37e5d4fc-fd6e-489c-83da-fdb8cb058d48',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: '37e5d4fc-fd6e-489c-83da-fdb8cb058d48',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Office Equipment',
                attributes: [
                  {
                    id: 'account',
                    value: '0289f44f-bdad-48e8-ab5a-502e9d949bd7',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: '0289f44f-bdad-48e8-ab5a-502e9d949bd7',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Fixed Assets',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
      {
        rowType: 'Section',
        title: '',
        rows: [
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Assets',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
      {
        rowType: 'Section',
        title: 'Liabilities',
        rows: [],
      },
      {
        rowType: 'Section',
        title: 'Current Liabilities',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Accounts Payable',
                attributes: [
                  {
                    id: 'account',
                    value: 'c0f7668c-1c46-47bf-8d65-62f8ba2da059',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: 'c0f7668c-1c46-47bf-8d65-62f8ba2da059',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Historical Adjustment',
                attributes: [
                  {
                    id: 'account',
                    value: '44ee8a46-0b5f-46ca-b2e3-fbdae39fd13c',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: '44ee8a46-0b5f-46ca-b2e3-fbdae39fd13c',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Sales Tax',
                attributes: [
                  {
                    id: 'account',
                    value: 'f0bb2f96-7530-416e-b8f0-bcec05e5ff04',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: 'f0bb2f96-7530-416e-b8f0-bcec05e5ff04',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Unpaid Expense Claims',
                attributes: [
                  {
                    id: 'account',
                    value: 'bab792be-f547-43b3-b81b-289bd52f1dcf',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: 'bab792be-f547-43b3-b81b-289bd52f1dcf',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Current Liabilities',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
      {
        rowType: 'Section',
        title: '',
        rows: [
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Liabilities',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },

      {
        rowType: 'Section',
        title: '',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Net Assets',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
      {
        rowType: 'Section',
        title: 'Equity',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Current Year Earnings',
                attributes: [
                  {
                    id: 'account',
                    value: 'abababab-abab-abab-abab-abababababab',
                  },
                ],
              },

              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: 'abababab-abab-abab-abab-abababababab',
                  },
                  {
                    id: 'fromDate',
                    value: '1/1/2021',
                  },
                  {
                    id: 'toDate',
                    value: '1/31/2021',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Retained Earnings',
                attributes: [
                  {
                    id: 'account',
                    value: 'd2e8fca5-83cc-475d-9efb-03fe0dc57fd4',
                  },
                ],
              },
              ...new Array(periods).fill(undefined).map(() => ({
                value: getRandomValue(800, 10000),
                attributes: [
                  {
                    id: 'account',
                    value: 'd2e8fca5-83cc-475d-9efb-03fe0dc57fd4',
                  },
                  {
                    id: 'fromDate',
                    value: '',
                  },
                  {
                    id: 'toDate',
                    value: '1/31/2021',
                  },
                ],
              })),
            ],
          },
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Equity',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
    ],
    updatedDateUTC: '2021-01-08T14:57:09.334Z',
    fields: [],
  }
}

export const getMockedProfitAndLoss = ({
  periods = Number(PeriodType.PREVIOUS_2_PERIOD),
  reportDatePeriod = ReportDatePeriodType.CURRENT_MONTH,
  date = new Date(),
}) => {
  const { toDate, fromDate, periodArray } = getPeriodsFromReportDatePeriod({
    date,
    reportDatePeriod,
    periods,
  })
  return {
    reportID: 'ProfitAndLoss',
    reportName: 'Profit and Loss',
    reportType: 'ProfitAndLoss',
    reportTitles: [
      'Profit & Loss',
      'Demo Company (Global)',
      `${fromDate.format('DD MMM. YYYY')} to ${toDate.format('DD MMM. YYYY')}`,
    ],
    reportDate: dayjs(date).format('DD MMM. YYYY'),
    rows: [
      {
        rowType: 'Header',
        cells: [
          {
            value: '',
          },
          ...(periodArray.map((dateStr) => ({
            value: dateStr,
          })) || []),
        ],
      },
      {
        rowType: 'Section',
        title: 'Income',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Sales',
                attributes: [
                  {
                    id: 'account',
                    value: 'd1ebb97b-d207-4ccb-9ab6-8a466a8b4d39',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Income',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
      {
        rowType: 'Section',
        title: '',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Gross Profit',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
      {
        rowType: 'Section',
        title: 'Less Operating Expenses',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Advertising',
                attributes: [
                  {
                    id: 'account',
                    value: 'eb01b8c7-f9b0-49ef-86f4-ed89b4ac33d5',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Bank Fees',
                attributes: [
                  {
                    id: 'account',
                    value: '269479fb-cc8e-4e5f-b0b6-cc4eccf045e0',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Cleaning',
                attributes: [
                  {
                    id: 'account',
                    value: '30ad3fd7-43b8-4eeb-96bc-ed2b7c945f3c',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Consulting & Accounting',
                attributes: [
                  {
                    id: 'account',
                    value: 'd2429b4f-ad8b-45e5-815a-13f5ecb0d5c1',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Entertainment',
                attributes: [
                  {
                    id: 'account',
                    value: '6a7d9c59-1d5a-4474-8c61-3da1f980db13',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'General Expenses',
                attributes: [
                  {
                    id: 'account',
                    value: '4281c446-efb4-445d-b32d-c441a4ef5678',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Light, Power, Heating',
                attributes: [
                  {
                    id: 'account',
                    value: 'c61d3100-d95f-4ce4-ba75-c03cc767b825',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Motor Vehicle Expenses',
                attributes: [
                  {
                    id: 'account',
                    value: '470f3b54-9cbe-420a-8d13-22cb17fd9d40',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Office Expenses',
                attributes: [
                  {
                    id: 'account',
                    value: '79fc6227-e183-409a-ba39-77028e0ceeff',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Printing & Stationery',
                attributes: [
                  {
                    id: 'account',
                    value: 'a8c9ca73-91ef-4163-a4f6-7d892201c087',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Rent',
                attributes: [
                  {
                    id: 'account',
                    value: 'd76027eb-0256-4b69-9f72-4751312e2749',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Repairs and Maintenance',
                attributes: [
                  {
                    id: 'account',
                    value: '5098e313-2775-40c3-9bb4-6b1f86d89a47',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Telephone & Internet',
                attributes: [
                  {
                    id: 'account',
                    value: '3b4fee92-7ed1-4d63-b00e-8fdf13927e05',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Travel - National',
                attributes: [
                  {
                    id: 'account',
                    value: 'f5211403-43fb-49aa-82de-9fc1f6bc3e8c',
                  },
                ],
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
          {
            rowType: 'SummaryRow',
            cells: [
              {
                value: 'Total Operating Expenses',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
      {
        rowType: 'Section',
        title: '',
        rows: [
          {
            rowType: 'Row',
            cells: [
              {
                value: 'Net Profit',
              },
              ...new Array(periods)
                .fill(undefined)
                .map(() => ({ value: getRandomValue(800, 10000) })),
            ],
          },
        ],
      },
    ],
    updatedDateUTC: '2021-02-09T18:34:47.511Z',
    fields: [],
  }
}
