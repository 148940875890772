/* eslint-disable react/style-prop-object */
import React from 'react'
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl'

import FeedUpdate from 'components/FeedUpdate'
import IconLegend from 'components/IconLegend'
import UISeparator from 'ui/Separator'
import { displayAttachments } from 'utils/functions/renderers/renderUpdatesHelper'
import {
  getNormalizedTransactionInstrument,
  getTransactionTypeLabels,
} from 'utils/functions/transactions'
import { useVisibility } from 'utils/hooks/useVisibility'
import { FileContent } from 'utils/types/files'
import { IndexUpdate, TransactionItem } from 'utils/types/update'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import UpdateText from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import {
  AmountInvested,
  AttachmentsContainer,
  ChipIcon,
  LinkButton,
  PortfolioChip,
  Portfolios,
  TransactionType,
  VisibilityContainer,
  ShowMoreLess,
} from '../UpdateFactory.styles'
import { useDefaultUpdateCard } from './useDefaultUpdateCard'
import Header from '../components/Header'
import ReshareIcons from '../components/ReshareIcons'

interface TransactionCardProps {
  update: IndexUpdate
  onPreviewFile: (file: FileContent) => void
  filesPreview: JSX.Element
  canEdit?: boolean
}

const TransactionCard: React.FC<TransactionCardProps> = ({
  update,
  onPreviewFile,
  filesPreview,
  canEdit,
}) => {
  const intl = useIntl()
  const { getUpdateVisibility } = useVisibility()
  const { onShow, onEdit, onReshare, updateText, showMoreLess } =
    useDefaultUpdateCard(update)
  const transactionItem = update.item as TransactionItem
  const { typeLabel: normalizedTransactionType } = getTransactionTypeLabels(
    transactionItem.transactionType,
    intl
  )

  const normalizedInstrument = getNormalizedTransactionInstrument(
    transactionItem.instrumentType || '',
    intl
  )

  const chipBreakpoints: { [key: string]: string } = {
    sm: '35rem',
    tabletLg: '10rem',
    xl: '35rem',
  }

  return (
    <>
      <FeedUpdate onClick={onShow} isDraftUpdate={update.isDraftUpdate}>
        <Header update={update} />

        <FeedUpdate.Body>
          <div>
            <AmountInvested transactionType={transactionItem.transactionType}>
              <FormattedNumber
                value={
                  transactionItem.amountInvested ||
                  transactionItem.amountDistributed ||
                  transactionItem.amountCommitted ||
                  0
                }
                style="currency"
                currency="USD"
                currencyDisplay="narrowSymbol"
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </AmountInvested>
            <TransactionType>
              {normalizedTransactionType}
              {normalizedInstrument ? ` - ${normalizedInstrument}` : ''}
            </TransactionType>

            <Portfolios>
              {transactionItem.portfolioNamesAndTypes.map((portfolioInfo) => (
                <PortfolioChip
                  key={portfolioInfo.name}
                  imageComponent={
                    <ChipIcon>
                      {getPortfolioIconByType(portfolioInfo.type)}
                    </ChipIcon>
                  }
                  text={portfolioInfo.name}
                  canDelete={false}
                  breakpoints={chipBreakpoints}
                  maxWidth="15rem"
                />
              ))}
            </Portfolios>

            {updateText && (
              <>
                <UISeparator space="1.2rem" />

                <div ref={showMoreLess.containerRef}>
                  <UpdateText
                    text={updateText}
                    maxLines={showMoreLess.isShowingMore ? undefined : 2}
                    openLinksOnClick={false}
                    onCreate={showMoreLess.onEditorCreated}
                  />
                </div>

                <ShowMoreLess
                  isShowMoreNeeded={showMoreLess.isShowMoreNeeded}
                  isShowingMore={showMoreLess.isShowingMore}
                  toggleShowingMore={showMoreLess.toggleShowingMore}
                />
              </>
            )}
          </div>

          <AttachmentsContainer>
            {displayAttachments(update.contents, 'small', onPreviewFile)}
          </AttachmentsContainer>
        </FeedUpdate.Body>

        <FeedUpdate.Footer>
          {!!update.contents?.length && (
            <IconLegend
              icon={['fal', 'paperclip']}
              legend={update.contents?.length?.toString()}
              title={intl.formatMessage({
                id: 'updates.attachmentsNumber',
              })}
            />
          )}

          {!update.isDraftUpdate && (
            <VisibilityContainer>
              <IconLegend
                icon={['fal', 'eye']}
                legend={getUpdateVisibility(update)}
                title={intl.formatMessage({ id: 'updates.visibility' })}
              />
              {update.confidential && (
                <IconLegend
                  icon={['fal', 'user-shield']}
                  title={intl.formatMessage({ id: 'updates.confidential' })}
                  hasTooltip
                />
              )}
            </VisibilityContainer>
          )}

          {canEdit && (
            <LinkButton onClick={onEdit}>
              <FormattedMessage id="general.edit" />
            </LinkButton>
          )}

          <ReshareIcons update={update} onClick={onReshare} />
        </FeedUpdate.Footer>
      </FeedUpdate>
      {filesPreview}
    </>
  )
}

export default TransactionCard
