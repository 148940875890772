import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { Scroll } from 'App.styles'

export const Wrapper = styled.div`
  max-height: 15rem;
  overflow-x: hidden;
  ${Scroll}
`

export const DescriptionContent = styled.p`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  line-height: 2rem;
  margin-right: 0.8rem;
`
