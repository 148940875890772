import { useIntl } from 'react-intl'
import { FieldArray } from 'formik'

import Separator from 'ui/Separator'
import Input from 'ui/Input'
import { buildFormError } from 'utils/functions/forms'
import Title from 'components/Title'
import Dropdown from 'ui/Dropdown'
import {
  AddInvestorOtherInfoFormValues,
  InvestorType,
} from 'utils/types/investors'
import useInitialData from 'utils/hooks/useInitialData'
import { isAmerica } from 'utils/constants/countries'
import { useForm, useFormikContext } from 'components/Form/hooks'
import { ErrorType } from 'utils/types/common'
import Chip from 'components/Chip'

import {
  DisplayWrapper,
  IndustryItem,
  IndustryList,
  SelectorWrapper,
} from './InvestorForm.styles'
import { AddInvestorOtherInfoFormConfig } from './types'
import FinancialInformationForm from './FinancialInformationForm'

interface Props {
  investorType?: InvestorType
}

const AddInvestorOtherInfoForm = ({ investorType }: Props) => {
  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    getFieldName,
  } = useFormikContext<AddInvestorOtherInfoFormValues>()

  const intl = useIntl()
  const { initialData } = useInitialData()

  const { legalEntityColumnsNumber } = useForm<AddInvestorOtherInfoFormConfig>()

  const filterOptions = (options, searchValue) =>
    options
      ?.filter(
        (option) =>
          option.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      )
      .map((option) => ({
        ...option,
        label: option.name,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1)) ?? []

  const selectedCountryIsAmerica = isAmerica(values.legalCountry)

  const getIndustrieOptions = (industry) =>
    values.industries.some((ind) => ind.name === industry.name)
      ? null
      : {
          ...industry,
          label: industry.name,
        }

  return (
    <div>
      <Title
        title={intl.formatMessage({
          id: 'investorManagement.legalStructure',
        })}
      />
      <Separator />

      <DisplayWrapper
        showInGrid={false}
        columnsNumber={legalEntityColumnsNumber ?? 3}
      >
        <SelectorWrapper>
          <Input
            name={getFieldName('legalEntityName')}
            type="text"
            label={intl.formatMessage({
              id: 'investorManagement.legalEntityName',
            })}
            placeholder={intl.formatMessage({
              id: 'investorManagement.addLegalEntityName',
            })}
            value={values.legalEntityName}
            onChange={handleChange}
            error={buildFormError(
              errors?.legalEntityName,
              ErrorType.ERROR,
              touched?.legalEntityName
            )}
            onBlur={handleBlur}
          />

          <Separator />
        </SelectorWrapper>

        <SelectorWrapper>
          <Dropdown
            name={getFieldName('legalStructure')}
            type="select"
            async
            label={intl.formatMessage({
              id: 'investorManagement.legalStructure',
            })}
            placeholder={intl.formatMessage({
              id: 'investorManagement.addLegalStructure',
            })}
            loadOptions={(searchValue) =>
              filterOptions(initialData?.legalStructures, searchValue)
            }
            getOption={(structure) => ({
              ...structure,
              label: structure.name,
            })}
            value={values.legalStructure}
            onSelectOption={(name, _, value) => setFieldValue(name, value)}
            preventAutocomplete
          />

          <Separator />
        </SelectorWrapper>

        <SelectorWrapper>
          <Dropdown
            name={getFieldName('legalCountry')}
            type="select"
            async
            label={intl.formatMessage({
              id: 'investorManagement.legalCountry',
            })}
            placeholder={intl.formatMessage({
              id: 'investorManagement.addLegalCountry',
            })}
            loadOptions={(searchValue) =>
              filterOptions(initialData?.countries, searchValue)
            }
            getOption={(country) => ({
              ...country,
              label: country.name,
            })}
            value={values.legalCountry}
            onSelectOption={(name, _, value) => setFieldValue(name, value)}
            preventAutocomplete
          />

          <Separator />
        </SelectorWrapper>

        <SelectorWrapper>
          <Dropdown
            name={getFieldName('legalState')}
            type="select"
            async
            label={intl.formatMessage({
              id: 'investorManagement.legalState',
            })}
            placeholder={intl.formatMessage({
              id: 'investorManagement.addLegalState',
            })}
            loadOptions={(searchValue) =>
              filterOptions(initialData?.provinces, searchValue)
            }
            getOption={(state) => ({
              ...state,
              label: state.name,
            })}
            value={values.legalState}
            onSelectOption={(name, _, value) => setFieldValue(name, value)}
            disabled={!selectedCountryIsAmerica}
            preventAutocomplete
          />

          <Separator />
        </SelectorWrapper>
      </DisplayWrapper>

      <Title
        title={intl.formatMessage({
          id: 'investorManagement.financialInfo',
        })}
      />
      <Separator />

      <FinancialInformationForm investorType={investorType} />

      <Title
        title={intl.formatMessage({
          id: 'investorManagement.targetIndustries',
        })}
      />
      <Separator />

      <FieldArray
        name={getFieldName('industries')}
        render={(arrayHelpers) => (
          <DisplayWrapper showInGrid={false}>
            <SelectorWrapper>
              <Dropdown
                id="industries"
                name={getFieldName('groupUserInput')}
                type="input"
                showIcon
                highlightEnabled
                placeholder={intl.formatMessage({
                  id: 'investorManagement.addIndustry',
                })}
                onPressEnter={(_, __, selectedOption) => {
                  if (!selectedOption) return
                  arrayHelpers.push(selectedOption)
                }}
                onSelectOption={(_, __, rawOption) =>
                  arrayHelpers.push(rawOption)
                }
                clearOnSelect
                clearOnPressEnter={false}
                hideCursor={false}
                loadOptions={(searchValue) =>
                  filterOptions(initialData?.industries, searchValue).filter(
                    (opt) =>
                      !values.industries.some(
                        (industry) => industry.id === opt.id
                      )
                  )
                }
                getOption={getIndustrieOptions}
                async
                showLoadingIndicator
                highlightSelectedOption={false}
                arrowNavigationEnabled
              />
              <IndustryList>
                {values.industries.map((industry, index) => (
                  <IndustryItem key={industry.id}>
                    <Chip
                      canDelete
                      handleDelete={arrayHelpers.handleRemove(index)}
                      text={industry.name}
                    />
                  </IndustryItem>
                ))}
              </IndustryList>
            </SelectorWrapper>
          </DisplayWrapper>
        )}
      />
    </div>
  )
}

export default AddInvestorOtherInfoForm
