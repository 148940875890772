import { PropsWithChildren } from 'react'
import { Redirect, Route, type RouteProps, useLocation } from 'react-router-dom'

import { useAuthentication } from 'utils/hooks/auth'

type ProtectedRouteProps = PropsWithChildren & RouteProps

const ProtectedRoute = ({ children, ...rest }: ProtectedRouteProps) => {
  const location = useLocation()
  const { isAuthenticated } = useAuthentication()

  return isAuthenticated ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Redirect to={{ pathname: '/sign-in', state: { from: location } }} />
  )
}

export default ProtectedRoute
