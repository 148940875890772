import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const MessageActions = styled.div`
  visibility: hidden;
  position: absolute;
  right: 1rem;
  top: -2rem;

  display: flex;
  cursor: pointer;
  background-color: white;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
`

export const MessageAction = styled.div<{ isDelete?: boolean }>`
  min-height: 3.2rem;
  min-width: 3.2rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;

  :hover {
    background-color: ${(props) =>
      props.isDelete
        ? color('softRed', 0.9)(props)
        : color('veryLightBlue', 0.4)(props)};

    ${({ isDelete }) =>
      !isDelete &&
      css`
        svg {
          color: ${color('primaryBlue')};
        }
      `}
`
