import React from 'react'

import { usePublicFundProfileQuery } from 'utils/hooks/queries/usePortfolioQuery'
import {
  TopicEntityType,
  buildHoldingTopicEntityFromFundProfile,
} from 'utils/types/chatTopicEntity'

import { useQuerySimilarChannel } from 'containers/Chat/hooks/useQuerySimilarChannel'
import { ChannelType } from 'containers/Chat/types'
import { useChatMembers } from 'utils/hooks/useChatMembers'
import CreateNewChatSuggestionBox from './CreateNewChatSuggestionBox'
import ExistentChatSuggestion from './ExistentChatSuggestion'

interface CreateNewFundProfileChatSuggestionProps {
  fundProfileId: string
}

const CreateNewFundProfileChatSuggestion: React.FC<
  CreateNewFundProfileChatSuggestionProps
> = ({ fundProfileId }) => {
  const { publicFundProfile } = usePublicFundProfileQuery(fundProfileId, {
    staleTime: 500,
  })

  const { members } = useChatMembers()
  const { data: similarChannel, isLoading: isLoadingSimilarChannel } =
    useQuerySimilarChannel({
      entityId: fundProfileId,
      entityType: TopicEntityType.FUND_HOLDING,
      channelType: ChannelType.HOLDING,
      memberIds: members.map((member) => member.user_id!),
    })

  if (isLoadingSimilarChannel) {
    return null
  }

  if (similarChannel) {
    return (
      <ExistentChatSuggestion
        topicEntity={
          publicFundProfile
            ? buildHoldingTopicEntityFromFundProfile(publicFundProfile)
            : undefined
        }
        existentChannel={similarChannel!}
      />
    )
  }

  return (
    <CreateNewChatSuggestionBox
      topicEntity={
        publicFundProfile
          ? buildHoldingTopicEntityFromFundProfile(publicFundProfile)
          : undefined
      }
    />
  )
}

export default CreateNewFundProfileChatSuggestion
