import React from 'react'
import { Formik, FieldArray } from 'formik'
import type { FormikErrors } from 'formik'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'ui/Button'
import NavMenu from 'components/NavMenu'
import Form from 'components/Form'
import BulkImport from 'components/BulkImport'
import FileBanner from 'components/FileBanner'
import SingleHoldingForm from '../SingleHoldingForm/SingleHoldingForm'
import {
  AddHoldingBulkImportForm,
  AddHoldingFormErrors,
  HoldingType,
} from '../types'
import * as Styles from './ImportHoldingsModal.styles'
import {
  useImportHoldingsModal,
  ImportHoldingsProps,
} from './useImportHoldingsModal'

const ImportHoldings: React.FC<ImportHoldingsProps> = ({
  onHideModal,
  onCreateNewHoldings,
  fileName,
  holdingsFromCSV,
  initialErrors,
  initialStatus,
}) => {
  const {
    initialValues,
    initialTouched,
    validationSchema,
    validate,
    onSubmit,
    csvHoldingsCount,
    currentHolding,
    setCurrentHolding,
    onAddHolding,
    onDeleteHolding,
    isHoldingValid,
    isSubmitting,
  } = useImportHoldingsModal({
    onHideModal,
    onCreateNewHoldings,
    fileName,
    holdingsFromCSV,
    initialErrors,
    initialStatus,
  })

  return (
    <BulkImport.Container>
      <Formik<AddHoldingBulkImportForm>
        initialValues={initialValues}
        initialErrors={
          initialErrors as any as FormikErrors<AddHoldingBulkImportForm>
        }
        initialStatus={initialStatus}
        initialTouched={initialTouched}
        validateOnMount
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validate={validate}
      >
        {({
          values,
          handleSubmit,
          errors,
          touched,
          validateForm,
          setStatus,
          isValid,
        }) => (
          <>
            <Styles.ModalBody>
              <FileBanner fileName={fileName}>
                <FormattedMessage
                  id="addHolding.countImported"
                  values={{ count: csvHoldingsCount }}
                />
              </FileBanner>
              <NavMenu>
                <FieldArray name="holdings">
                  {(arrayHelpers) => {
                    const currentHoldingIndex = values.holdings?.findIndex?.(
                      (holding) => holding.id === currentHolding?.id
                    )

                    return (
                      <>
                        <NavMenu.AsideMenu>
                          <NavMenu.AsideItem
                            key="addHolding"
                            onClick={() => onAddHolding(arrayHelpers)}
                          >
                            <Styles.AddHoldingButton>
                              <FontAwesomeIcon icon={['far', 'plus']} />
                              <BulkImport.NameContainer>
                                <FormattedMessage id="addHolding.addHolding" />
                              </BulkImport.NameContainer>
                            </Styles.AddHoldingButton>
                          </NavMenu.AsideItem>
                          {values.holdings.map((holding, index) => {
                            const isActive = holding.id === currentHolding?.id
                            const name =
                              holding.type === HoldingType.COMPANY
                                ? holding.company?.name
                                : holding.funds?.funds.find((fund) => !!fund)

                            return (
                              <NavMenu.AsideItem
                                key={holding.id}
                                active={isActive}
                                onClick={() => setCurrentHolding(holding)}
                              >
                                <BulkImport.NameContainer
                                  italic={!name}
                                  isActive={isActive}
                                >
                                  {name || (
                                    <FormattedMessage id="addHolding.completeTheForm" />
                                  )}
                                </BulkImport.NameContainer>
                                <BulkImport.IconsContainer>
                                  {!isHoldingValid(
                                    holding,
                                    errors.holdings?.[
                                      index
                                    ] as any as AddHoldingFormErrors,
                                    touched.holdings?.[index]
                                  ) && (
                                    <FontAwesomeIcon
                                      icon={['far', 'exclamation-triangle']}
                                    />
                                  )}

                                  <FontAwesomeIcon
                                    icon={['far', 'angle-right']}
                                  />
                                </BulkImport.IconsContainer>
                              </NavMenu.AsideItem>
                            )
                          })}
                        </NavMenu.AsideMenu>

                        {currentHoldingIndex >= 0 ? (
                          <Form prefix={`holdings.${currentHoldingIndex}`}>
                            <BulkImport.NavMenuContent>
                              <SingleHoldingForm />
                              <Styles.DeleteButton
                                danger
                                onClick={() => {
                                  onDeleteHolding(
                                    values,
                                    arrayHelpers,
                                    validateForm,
                                    setStatus
                                  )
                                }}
                              >
                                <FormattedMessage id="common.delete" />
                              </Styles.DeleteButton>
                            </BulkImport.NavMenuContent>
                          </Form>
                        ) : (
                          <Styles.EmptyListPlaceholder />
                        )}
                      </>
                    )
                  }}
                </FieldArray>
              </NavMenu>
            </Styles.ModalBody>
            <BulkImport.Footer>
              <Button onClick={onHideModal} secondary>
                <FormattedMessage id="general.cancel" />
              </Button>
              <Button
                autoWidth
                primary
                blue
                onClick={handleSubmit}
                isLoading={isSubmitting}
                disabled={isSubmitting || !isValid}
              >
                <FormattedMessage id="general.add" />
              </Button>
            </BulkImport.Footer>
          </>
        )}
      </Formik>
    </BulkImport.Container>
  )
}

export default ImportHoldings
