import { FormattedMessage, useIntl } from 'react-intl'

import Modal from 'components/Modal'
import Button from 'ui/Button'
import RadioButton from 'ui/RadioButton/RadioButton'

import { Body, StyledModal } from './MuteChatModal.styles'
import { MuteOptions, useMuteChatModal } from './useMuteChatModal'

interface MuteChatModalProps {
  show: boolean
  onHide: () => void
}

const MuteChatModal = ({ show, onHide }: MuteChatModalProps) => {
  const intl = useIntl()
  const { muteOption, setMuteOption, muteChannel } = useMuteChatModal(onHide)

  return (
    <StyledModal show={show} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.H2Title>
          <FormattedMessage id="chat.muteNotifications" />
        </Modal.H2Title>
      </Modal.Header>
      <Body>
        <RadioButton
          name="mute-chat-options"
          onChange={(value: MuteOptions) => setMuteOption(value)}
          direction="column"
          uncheckedWithOpacity={false}
          options={[
            {
              label: `8 ${intl.formatMessage({ id: 'general.hours' })}`,
              value: MuteOptions.MUTE_8_HOURS,
            },
            {
              label: `1 ${intl.formatMessage({ id: 'general.week' })}`,
              value: MuteOptions.MUTE_1_WEEK,
            },
            {
              label: intl.formatMessage({ id: 'general.always' }),
              value: MuteOptions.ALWAYS,
            },
          ]}
          value={muteOption}
        />
      </Body>
      <Modal.RowFooter>
        <Button secondary onClick={onHide}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button primary onClick={muteChannel}>
          <FormattedMessage id="general.ok" />
        </Button>
      </Modal.RowFooter>
    </StyledModal>
  )
}

export default MuteChatModal
