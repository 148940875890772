export const DefaultDocument = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="file-alt 1" clipPath="url(#clip0_7690_533748)">
      <path
        id="Vector"
        d="M8.75 6.77808V7.43433C8.75 7.58901 8.62344 7.71558 8.46875 7.71558H4.53125C4.37656 7.71558 4.25 7.58901 4.25 7.43433V6.77808C4.25 6.62339 4.37656 6.49683 4.53125 6.49683H8.46875C8.62344 6.49683 8.75 6.62339 8.75 6.77808ZM8.46875 8.46558H4.53125C4.37656 8.46558 4.25 8.59214 4.25 8.74683V9.40308C4.25 9.55776 4.37656 9.68433 4.53125 9.68433H8.46875C8.62344 9.68433 8.75 9.55776 8.75 9.40308V8.74683C8.75 8.59214 8.62344 8.46558 8.46875 8.46558ZM11 4.05698V11.8406C11 12.4617 10.4961 12.9656 9.875 12.9656H3.125C2.50391 12.9656 2 12.4617 2 11.8406V2.09058C2 1.46948 2.50391 0.965576 3.125 0.965576H7.90859C8.20625 0.965576 8.49219 1.08511 8.70312 1.29604L10.6695 3.26245C10.8805 3.47105 11 3.75933 11 4.05698ZM8 2.18198V3.96558H9.78359L8 2.18198ZM9.875 11.8406V5.09058H7.4375C7.12578 5.09058 6.875 4.83979 6.875 4.52808V2.09058H3.125V11.8406H9.875Z"
        fill="#313748"
      />
    </g>
    <defs>
      <clipPath id="clip0_7690_533748">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.965576)"
        />
      </clipPath>
    </defs>
  </svg>
)
