import { PortfolioTypes } from 'utils/constants/portfolio'
import { UpdateTypes } from 'utils/constants/updates'
import { assertUnreachable } from 'utils/functions/utils'
import { ValueOf } from '../common'

const PORTFOLIOS_PREFIX = 'Portfolios::'

export enum PortfolioApiTypes {
  TRACK = 'TrackPortfolio',
  INVEST = 'InvestPortfolio',
  FUND = 'FundPortfolio',
  DEAL = 'DealPortfolio',
}

export const getPortfolioTypeFromApiType = (apiType: PortfolioApiTypes) => {
  const portfolioType = apiType.replace(
    PORTFOLIOS_PREFIX,
    ''
  ) as PortfolioApiTypes

  switch (portfolioType) {
    case PortfolioApiTypes.TRACK:
      return PortfolioTypes.TRACK
    case PortfolioApiTypes.INVEST:
      return PortfolioTypes.INVEST
    case PortfolioApiTypes.FUND:
      return PortfolioTypes.FUND
    case PortfolioApiTypes.DEAL:
      return PortfolioTypes.DEAL
    default:
      return assertUnreachable(portfolioType)
  }
}

export type UpdateTypeApi = ValueOf<typeof UpdateTypes>
export type SuggestedUpdateTypeApi = UpdateTypeApi | 'suggested'
