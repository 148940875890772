import React from 'react'
import { useField } from 'components/Form/hooks'
import { useIntl } from 'react-intl'
import { Nullable } from 'utils/types/common'
import { DatePicker } from 'containers/UpdatesV2/components/commons'
import { IconPosition } from 'components/TimeSelect/types'

import { FormElementProps } from './types'

const DateInput: React.FC<
  FormElementProps & {
    label?: Nullable<string>
    maxDate?: Nullable<Date>
    placeholder?: string
    isOptional?: boolean
    initialValue?: Nullable<Date>
  }
> = ({
  label = undefined,
  placeholder = undefined,
  maxDate = undefined,
  isOptional,
  initialValue = null,
  disabled,
  name = 'date',
}) => {
  const [, , helpers] = useField(name)
  const intl = useIntl()

  return (
    <DatePicker
      id={name}
      name={name}
      iconPosition={IconPosition.RIGHT}
      handleChange={(value) => helpers.setValue(value, true)}
      label={
        label !== undefined
          ? label
          : intl.formatMessage({ id: 'transactions.investmentDate' })
      }
      placeholder={
        placeholder !== undefined
          ? placeholder
          : intl.formatMessage({
              id: 'transactions.transactionDatePlaceholder',
            })
      }
      onlyMonthYearPicker={false}
      dateFormat="MM/dd/yyyy"
      maxDate={maxDate}
      isPastDateAllowed
      disabled={disabled}
      optional={isOptional}
      initialValue={initialValue}
    />
  )
}

export default DateInput
