import { useMemo } from 'react'
import { useForm } from './useForm'

export const useFieldName = (field: string) => {
  const { prefix } = useForm()
  const name = useMemo(
    () => (prefix ? `${prefix}.${field}` : field),
    [prefix, field]
  )

  return name
}
