import { useIntl } from 'react-intl'
import { useField } from 'components/Form/hooks'
import Input from 'ui/Input'
import theme from 'utils/theme'
import { MAX_CHAT_TITLE_LENGTH } from 'utils/schemas/chat'

interface TitleInputProps {
  isTitleVisible: boolean
}

const TitleInput = ({ isTitleVisible }: TitleInputProps) => {
  const intl = useIntl()
  const [titleField] = useField('title')

  if (!isTitleVisible) {
    return null
  }

  return (
    <Input
      name="title"
      label={intl.formatMessage({ id: 'general.title' })}
      optionalLabel={intl.formatMessage({ id: 'general.optional' })}
      isOptional
      value={titleField.value}
      onChange={titleField.onChange}
      onBlur={titleField.onBlur}
      error={titleField.buildError()}
      placeholder={intl.formatMessage({
        id: 'chat.titlePlaceholder',
      })}
      withCounter
      customMaxCounterCharacters={MAX_CHAT_TITLE_LENGTH}
      counterSubtractNumber={titleField.value?.length ?? 0}
      simulateIconSpace
      counterStyle={{
        right: '1.2rem',
        top: '1.2rem',
        color: theme.colors.darkGray,
        opacity: 0.5,
      }}
      extraPaddingRight={1.5}
    />
  )
}

export default TitleInput
