import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from 'components/Modal'
import ToastMessage from 'components/ToastMessage'
import { ToastType } from 'components/ToastMessage/ToastMessage'
import UpdateMetricsGrid from 'components/UpdateMetricsGrid/UpdateMetricsGrid'
import Button from 'ui/Button'
import { useUpdateAllMetrics } from '../UpdateAllMetrics/useUpdateAllMetrics'
import * as Styles from './UpdateAllMetricsModal.styles'

const MODAL_FADE_OUT_DURATION = 500

const UpdateAllMetricsModal = () => {
  const history = useHistory()
  const location = useLocation<{ isBulkCreate?: string }>()
  const [isModalOpen, setIsModalOpen] = useState(true)
  const {
    loading,
    onGridChange,
    uploadValuesAndClose,
    errorMessage,
    companyId,
    mode,
    gridRef,
    spreadsheetLogic,
    shouldDisplayDisclaimer,
    dismissDisclaimer,
  } = useUpdateAllMetrics()

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    setTimeout(() => {
      history.goBack()
    }, MODAL_FADE_OUT_DURATION)
  }, [history])

  const copyKeys = useMemo(
    () =>
      location.state?.isBulkCreate
        ? {
            title: 'metrics.create.addMetricsTitle',
            save: 'metrics.create.ok',
          }
        : {
            title: 'metrics.update.updateAllMetrics',
            save: 'metrics.create.ok',
          },
    [location.state?.isBulkCreate]
  )

  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header onHide={closeModal}>
        <Modal.Title>
          <FormattedMessage id={copyKeys.title} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Styles.Description>
          <FormattedMessage id="metrics.update.updateCompanyMetricsDescription" />
        </Styles.Description>
        <Styles.GridContainer>
          <UpdateMetricsGrid
            companyId={companyId}
            initialGrid={gridRef.current}
            onGridChange={onGridChange}
            mode={mode}
            spreadsheetBuilder={spreadsheetLogic.current.getBuilder()}
            shouldDisplayDisclaimer={shouldDisplayDisclaimer}
          />
        </Styles.GridContainer>
        {shouldDisplayDisclaimer && (
          <Styles.Disclaimer>
            <ToastMessage>
              <FormattedMessage id="metrics.update.groupSharingDisclaimer" />
              <Styles.DismissIcon onClick={dismissDisclaimer}>
                <FontAwesomeIcon icon={['fal', 'times']} />
              </Styles.DismissIcon>
            </ToastMessage>
          </Styles.Disclaimer>
        )}
      </Modal.Body>

      <Styles.Footer>
        {!!errorMessage && (
          <div>
            <ToastMessage
              type={ToastType.ERROR}
              icon={['fal', 'exclamation-triangle']}
            >
              {errorMessage}
            </ToastMessage>
          </div>
        )}
        <Styles.ButtonsContainer>
          <Button secondary type="button" onClick={closeModal}>
            <FormattedMessage id="general.cancel" />
          </Button>

          <Button
            primary
            type="button"
            disabled={loading || !!errorMessage}
            isLoading={loading}
            onClick={uploadValuesAndClose(closeModal)}
          >
            <FormattedMessage id={copyKeys.save} />
          </Button>
        </Styles.ButtonsContainer>
      </Styles.Footer>
    </Modal>
  )
}

export default UpdateAllMetricsModal
