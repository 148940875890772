import React, { useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from '@tanstack/react-query'

import { UpdateEntityParams } from 'api/MixedUpdatesService'
import { mixedUpdatesKeys } from 'utils/queries/mixedUpdates'
import useReshareUpdateModal from 'containers/UpdatesV2/components/ReshareUpdateModal/useReshareUpdateModal'
import ReshareUpdateModal from 'containers/UpdatesV2/components/ReshareUpdateModal/ReshareUpdateModal'
import { useUpdatesFiltersContext } from 'containers/UpdatesView/UpdatesFiltersContext'
import { useMixedUpdatesInfiniteQuery } from 'utils/hooks/queries/useMixedUpdatesQuery'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { PlainUpdateTypes } from 'utils/constants/updates'
import { InvestmentsFiltersObject } from 'containers/Investments/InvestmentsFilters/types'
import { IndexUpdate, MixedUpdate, Update } from 'utils/types/update'
import FeedList from './components/FeedList'
import SuggestedUpdatesSelected from './components/SuggestedUpdatesSelected'
import { UpdatesFeedContext, UpdatesFeedContextType } from './UpdateFeedContext'
import { UpdateEntityType } from './types'

interface UpdatesFeedProps extends UpdateEntityParams {
  onShowUpdate: (update: IndexUpdate) => void
  onEditUpdate?: (update: IndexUpdate) => void
  onReshareUpdate?: (update: IndexUpdate) => void
  handleSetHasMixedUpdates?: (hasUpdatesToShow: boolean) => void
  showEntityFeedIcons?: boolean
  includeTransactions?: boolean
  customZeroState?: React.ReactElement
  onZeroStateChange?: (showZeroState: boolean) => void
  isPortfolioFeed?: boolean
  isAllInvestmentsOption?: boolean
  investmentsFilterObject?: InvestmentsFiltersObject
  isShowingUpdatesForFilters?: boolean
  updatesForInvestorTab?: boolean
  setIsUpdatesLoading?: (isLoading: boolean) => void
}

const UpdatesFeed: React.FC<UpdatesFeedProps> = ({
  entityType,
  entityId,
  investmentsFilterObject,
  isShowingUpdatesForFilters,
  onShowUpdate,
  onEditUpdate,
  onReshareUpdate,
  handleSetHasMixedUpdates,
  showEntityFeedIcons,
  customZeroState,
  onZeroStateChange,
  updatesForInvestorTab = false,
  isAllInvestmentsOption = false,
  isPortfolioFeed = false,
  includeTransactions = true,
  setIsUpdatesLoading,
}) => {
  const [updateSelected, setUpdateSelected] = React.useState<Update>()
  const { filters } = useUpdatesFiltersContext()
  const queryClient = useQueryClient()
  const removeTransactionUpdates = isPortfolioFeed && !includeTransactions
  const filtersForQuery = useMemo(
    () => ({
      ...filters,
      typesNotIncluded: removeTransactionUpdates
        ? [PlainUpdateTypes.TRANSACTION]
        : [],
    }),
    [filters, removeTransactionUpdates]
  )
  const onlyTransactionUpdates =
    isPortfolioFeed &&
    includeTransactions &&
    !isAllInvestmentsOption &&
    !updatesForInvestorTab

  const {
    mixedUpdates,
    refetch,
    isLoading,
    isFetching,
    infiniteScrollRef,
    scrollRef,
  } = useMixedUpdatesInfiniteQuery({
    entityType,
    entityId,
    updatesForInvestorTab,
    filters: filtersForQuery,
    investmentsFilterObject,
    isShowingUpdatesForFilters,
    onlyTransactionUpdates,
    queryOptions: {
      enabled:
        entityType === UpdateEntityType.PORTFOLIO_COMPANIES || // TODO: workaround to fix the custom filter view feed. Check for a better solution
        entityType === UpdateEntityType.MY_UPDATES ||
        !!(entityId && entityId?.length > 0),
    },
  })
  const showZeroState = !isLoading && mixedUpdates.length === 0 && !isFetching

  useEffect(() => {
    onZeroStateChange?.(showZeroState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showZeroState])

  useEffect(() => {
    setIsUpdatesLoading?.(isLoading)
  }, [isLoading, setIsUpdatesLoading])

  const { isReshareModalOpen, handleCloseReshareModal, toggleReshareModal } =
    useReshareUpdateModal()

  const onClickReshareUpdate = useCallback(
    (update: Update) => {
      const newUpdate = update
      setUpdateSelected(newUpdate)
      if (newUpdate.type === PlainUpdateTypes.IUE && onReshareUpdate) {
        onReshareUpdate(newUpdate)
      } else {
        toggleReshareModal()
      }
    },
    [onReshareUpdate, toggleReshareModal]
  )

  const removeSuggestedUpdateFromFeed = useCallback(
    (updateIds: string[]) => {
      try {
        queryClient.setQueryData<{
          pages: {
            data: MixedUpdate[]
            page: number
          }[]
        }>(
          mixedUpdatesKeys.all(
            entityType,
            entityId || '',
            filtersForQuery,
            investmentsFilterObject
          ),
          (oldMixedUpdates) => {
            const newPages = oldMixedUpdates?.pages.map((page) => {
              return {
                data: page.data.filter(
                  (update) => !updateIds.includes(update.id)
                ),
                page: page.page,
              }
            })

            return {
              ...oldMixedUpdates,
              pages: newPages || [],
            }
          }
        )
      } catch {
        refetch()
      }
    },
    [
      queryClient,
      entityType,
      entityId,
      filtersForQuery,
      investmentsFilterObject,
      refetch,
    ]
  )

  useEffect(() => {
    handleSetHasMixedUpdates?.(!!mixedUpdates.length)
  }, [handleSetHasMixedUpdates, mixedUpdates])

  const contextValue = useMemo<UpdatesFeedContextType>(
    () => ({
      onShowUpdate,
      onEditUpdate,
      onReshareUpdate: onClickReshareUpdate,
      onDiscardSuggestedUpdateSuccess: removeSuggestedUpdateFromFeed,
      showEntityFeedIcons,
      entityType,
      entityId,
    }),
    [
      onShowUpdate,
      onEditUpdate,
      onClickReshareUpdate,
      removeSuggestedUpdateFromFeed,
      showEntityFeedIcons,
      entityType,
      entityId,
    ]
  )

  useHighlightText(
    {
      elementClass: '.update-name',
      text: filters.searchText!,
      delay: 300,
      enabled: true,
    },
    [filters.searchText, isLoading, mixedUpdates]
  )

  return (
    <UpdatesFeedContext.Provider value={contextValue}>
      <FeedList
        isLoading={isLoading}
        isFetching={isFetching}
        showZeroState={showZeroState}
        updates={mixedUpdates}
        infiniteScrollRef={infiniteScrollRef}
        customZeroState={customZeroState}
        scrollRef={scrollRef}
      />
      {isReshareModalOpen && updateSelected && (
        <ReshareUpdateModal
          update={updateSelected}
          isModalOpen={isReshareModalOpen}
          closeModal={() => {
            handleCloseReshareModal()
            setUpdateSelected(undefined)
          }}
          updateTitle={updateSelected.item.title}
        />
      )}
      <SuggestedUpdatesSelected />
    </UpdatesFeedContext.Provider>
  )
}

export default UpdatesFeed
