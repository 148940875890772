import { useMemo } from 'react'

import { getPortfolioTypeFromTopicEntityType } from 'containers/Chat/helpers'
import {
  StreamChatType,
  isHoldingChannel,
  isPortfolioChannel,
  isTopicChannel,
} from 'containers/Chat/types'
import type { ChannelMemberResponse } from 'stream-chat'
import { Channel } from 'stream-chat'

import { Avatar } from 'components/StackedAvatarList/StackedAvatarList.styles'
import { getInitials } from 'utils/functions/user'

import {
  Tooltip,
  TooltipContent,
} from '../ChatSidebar/components/ChannelPreview/ChannelPreview.styles'
import * as PopupStyles from './ChatPopupHeader.styles'

type Props = {
  channel: Channel<StreamChatType>
  members: ChannelMemberResponse<StreamChatType>[]
  tooltipKey: string
}

const ChatPopupHeaderIcon = ({ tooltipKey, channel, members }: Props) => {
  const tooltipProps = useMemo(
    () => ({
      place: 'bottom',
      topOffset: -20,
      leftOffset: 50,
      backgroundColor: 'transparent',
      delayShow: 500,
      inline: true,
    }),
    []
  )

  if (isTopicChannel(channel.data)) {
    if (isHoldingChannel(channel.data)) {
      return (
        <Tooltip
          key={`chat_popup_holding_${tooltipKey}`}
          id={`chat_popup_holding_${tooltipKey}`}
          text={<TooltipContent>{channel.data.topicName}</TooltipContent>}
          {...tooltipProps}
          leftOffset={60}
          topOffset={30}
        >
          <PopupStyles.GroupLogo
            name={channel.data.topicName}
            imageUrl={channel.data.imageUrl}
          />
        </Tooltip>
      )
    }

    if (isPortfolioChannel(channel.data)) {
      return (
        <Tooltip
          key={`chat_popup_portfolio_${tooltipKey}`}
          id={`chat_popup_portfolio_${tooltipKey}`}
          text={<TooltipContent>{channel.data.topicName}</TooltipContent>}
          {...tooltipProps}
          leftOffset={25}
        >
          <PopupStyles.PortfolioIcon
            type={getPortfolioTypeFromTopicEntityType(channel.data.entityType)}
          />
        </Tooltip>
      )
    }
  }

  return (
    <Avatar
      image={members[0]?.user?.image}
      initials={getInitials(members[0]?.user?.name)}
      avatarStyle="avatarCircleLogs"
      avatarSize="2.8rem"
    />
  )
}

export default ChatPopupHeaderIcon
