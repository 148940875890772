import {
  useCapitalStatementFundPortfolioInvestorIdQuery,
  useCapitalStatementPreviewQuery,
} from 'utils/hooks/investorManagement/useFundPortfolioInvestorQuery'

export const useFetchCapitalStatement = ({
  id,
  previewInvestorId,
}: {
  id: string
  previewInvestorId?: string
}) => {
  const { data, isLoading } =
    useCapitalStatementFundPortfolioInvestorIdQuery(id)

  const { data: previewData, isFetching: isFetchingCapitalStatement } =
    useCapitalStatementPreviewQuery(id, previewInvestorId as string, {
      enabled: !!previewInvestorId,
    })

  const dataToUse = previewInvestorId ? previewData : data

  const investorsTotalUnfundedCommitment = dataToUse?.unfundedCommitment
  const totalCapitalCalled = dataToUse?.capitalCalled
  const totalCommittedCapital = dataToUse?.committedCapital
  const totalDistributions = dataToUse?.distributions
  const proRataHoldingValue = dataToUse?.proRataHoldingValue

  return {
    data: {
      investorsTotalUnfundedCommitment,
      totalCapitalCalled,
      totalCommittedCapital,
      totalDistributions,
      proRataHoldingValue,
    },
    isLoading: isLoading || isFetchingCapitalStatement,
  }
}
