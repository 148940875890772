/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

const initialState = {
  updated: false,
  registration: null,
}

const SWUpdateInstalled = (draftState, payload) => {
  draftState.updated = true
  draftState.registration = payload
  return draftState
}

const SWActivated = () => ({
  ...initialState,
})

const handlers = {
  [Types.SW_UPDATE_INSTALLED]: SWUpdateInstalled,
  [Types.SW_UPDATE_ACTIVATED]: SWActivated,
}

const serviceWorkerReducer = createReducer(initialState, handlers)

export default serviceWorkerReducer
