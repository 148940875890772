import Card from 'components/Card'
import UpdatesFeed, { UpdateEntityType } from 'components/UpdatesFeedV2'
import updatesFeedStyles from 'components/UpdatesFeed/UpdatesFeed.module.scss'
import FundsTable from 'containers/InvestorManagement/ShowInvestor/FundsTable'
import { useIntl } from 'react-intl'
import { FundPortfolioInvestor } from 'utils/types/funds'
import { IndexUpdate, TransactionItem } from 'utils/types/update'
import Tabs from 'components/Tabs'
import {
  MainContentCardWrapper,
  NoUpdatesToShow,
} from './CompanyMainContent.styles'
import useProfileUpdates from '../../useProfileUpdates'

interface Props {
  investmentVehicleId: string
  isLoadingFunds: boolean
  fundsPortfolioInvestor: FundPortfolioInvestor[]
  onShowTransaction: (transaction: TransactionItem) => void
  onEditTransaction: (transaction: TransactionItem) => void
}

const InvestmentVehicleMainContent = ({
  investmentVehicleId,
  isLoadingFunds,
  onShowTransaction,
  onEditTransaction,
  fundsPortfolioInvestor,
}: Props) => {
  const intl = useIntl()
  const { reshareUpdate } = useProfileUpdates({
    entityType: UpdateEntityType.INVESTOR,
    entityId: investmentVehicleId,
  })

  const onClickUpdate = (update: IndexUpdate) => {
    onShowTransaction({
      loggingUpdateId: update.id,
    } as TransactionItem)
  }

  const onEditUpdate = (update: IndexUpdate) => {
    onEditTransaction({
      loggingUpdateId: update.id,
    } as TransactionItem)
  }

  return (
    <Tabs
      tabOffset={24}
      items={[
        {
          id: 'UPDATES',
          title: intl.formatMessage({
            id: 'portfolioDetail.updates',
          }),
        },
        {
          id: 'FUNDS',
          title: intl.formatMessage({
            id: 'portfolioDetail.fundsAndDeals',
          }),
        },
      ].filter(Boolean)}
      withBorder
    >
      <Tabs.TabContent id="UPDATES">
        <MainContentCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'updates.updateFeed' })}
            </Card.Header>
            <Card.Body>
              {fundsPortfolioInvestor.length ? (
                <div className={updatesFeedStyles.updatesFeedContainer}>
                  <UpdatesFeed
                    entityType={UpdateEntityType.INVESTOR}
                    entityId={investmentVehicleId}
                    onReshareUpdate={reshareUpdate}
                    onShowUpdate={onClickUpdate}
                    onEditUpdate={onEditUpdate}
                  />
                </div>
              ) : (
                <NoUpdatesToShow>
                  {intl.formatMessage({
                    id: 'investorManagement.profiles.noUpdates',
                  })}
                </NoUpdatesToShow>
              )}
            </Card.Body>
          </Card>
        </MainContentCardWrapper>
      </Tabs.TabContent>

      <Tabs.TabContent id="FUNDS">
        <MainContentCardWrapper>
          <FundsTable
            isLoading={isLoadingFunds}
            funds={fundsPortfolioInvestor}
            onShowTransaction={onShowTransaction}
            investorProfile={false}
          />
        </MainContentCardWrapper>
      </Tabs.TabContent>
    </Tabs>
  )
}

export default InvestmentVehicleMainContent
