import { FIELD_NAMES } from 'containers/UpdatesV2/Accounting/constants/constants'
import { useIntl } from 'react-intl'
import { StyledCheckbox } from './PaymentsOnly.styles'

interface PaymentsOnlyProps {
  disabled: boolean
}

const PaymentsOnly = ({ disabled }: PaymentsOnlyProps) => {
  const intl = useIntl()

  return (
    <StyledCheckbox
      id={FIELD_NAMES.paymentsOnly}
      name={FIELD_NAMES.paymentsOnly}
      label={intl.formatMessage({ id: 'general.paymentsOnly' })}
      disabled={disabled}
    />
  )
}

export default PaymentsOnly
