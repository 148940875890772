import PortfolioService from 'api/PortfolioService'
import { PortfolioTypes } from 'utils/constants/portfolio'

export const getUpdateTypeAndIdFromUrl = (url: string) => {
  const urlSegments = url.split('/')
  const updateTypes = [
    'accounting',
    'notes',
    'documents',
    'transactions',
    'announcements',
    'emails',
  ]

  let updateType = ''
  let updateId = ''

  for (let i = 0; i < urlSegments.length; i++) {
    if (updateTypes.includes(urlSegments[i])) {
      updateType = urlSegments[i].slice(0, -1)
      updateId =
        urlSegments[i + 1] === 'new' ? urlSegments[i + 2] : urlSegments[i + 1]
      break
    }
  }

  return {
    updateType,
    updateId,
  }
}

export enum EntityBreadcrumbTypes {
  TRACK = 'track',
  INVEST = 'invest',
  FUND = 'fund',
  DEAL = 'deal',
  FILTERS = 'filters',
}

export const getCompanyIdFromUrl = (url: string) => {
  const urlSegments = url.split('/')
  let companyId = ''

  for (let i = 0; i < urlSegments.length; i++) {
    if (urlSegments[i] === 'companies') {
      companyId = urlSegments[i + 1]
      break
    }
  }

  return companyId
}

export const getInvestorIdFromUrl = (url: string) => {
  const urlSegments = url.split('/')
  let investorId = ''

  for (let i = 0; i < urlSegments.length; i++) {
    if (urlSegments[i] === 'investors') {
      investorId = urlSegments[i + 1]
      break
    }
  }

  return investorId
}

export const getMetricIdFromUrl = (url: string) => {
  const urlSegments = url.split('/')
  let metricId = ''

  for (let i = 0; i < urlSegments.length; i++) {
    if (urlSegments[i] === 'metrics') {
      metricId = urlSegments[i + 1]
      break
    }
  }

  return metricId
}

export const getInvestmentVehicleIdFromUrl = (url: string) => {
  const urlSegments = url.split('/')
  let investmentVehicleId = ''

  for (let i = 0; i < urlSegments.length; i++) {
    if (
      urlSegments[i] === 'vehicles' ||
      urlSegments[i] === 'investment-vehicles'
    ) {
      investmentVehicleId = urlSegments[i + 1]
      break
    }
  }

  return investmentVehicleId
}

export const getFundPortfolioIdFromUrl = (url: string) => {
  const urlSegments = url.split('/')
  let portfolioId = ''

  for (let i = 0; i < urlSegments.length; i++) {
    if (urlSegments[i] === 'funds') {
      portfolioId = urlSegments[i + 1]
      break
    }
  }

  return portfolioId
}

export const fetchPortfolio = async (
  portfolioId: string,
  portfolioType: PortfolioTypes
) => {
  return PortfolioService.getPortfolio(portfolioId, portfolioType)
}

export const returnPortfolioIdFromUrl = (
  url: string,
  type: EntityBreadcrumbTypes
) => {
  const urlSegments = url.split('/')

  let portfolioId = ''

  for (let i = 0; i < urlSegments.length; i++) {
    if (urlSegments[i] === type) {
      portfolioId = urlSegments[i + 1]
      break
    }
  }

  return portfolioId
}
