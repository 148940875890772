import React from 'react'
import {
  isIndexUpdate,
  isSuggestedUpdate,
} from 'utils/functions/suggestedUpdates'
import { MixedUpdate } from 'utils/types/update'
import IndexUpdateFeedCard from './IndexUpdateFeedCard'
import SuggestedUpdateFeedCard from '../SuggestedUpdateFeedCard/SuggestedUpdateFeedCard'

type Props = {
  mixedUpdate: MixedUpdate
}

const FeedCard: React.FC<Props> = ({ mixedUpdate }) => {
  if (isSuggestedUpdate(mixedUpdate)) {
    return <SuggestedUpdateFeedCard suggestedUpdate={mixedUpdate} />
  }

  if (isIndexUpdate(mixedUpdate)) {
    return <IndexUpdateFeedCard indexUpdate={mixedUpdate} />
  }

  return null
}

export default FeedCard
