import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

const sharedStyles = css`
  max-width: 25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const MetricOption = styled.div`
  padding: 0.8rem 0.8rem 0.8rem 2rem;
  border-radius: 0.8rem;
  cursor: pointer;

  &:hover {
    background: ${color('veryLightBlue', 0.2)};
  }
`

export const MetricOptionContainer = styled.div`
  margin-left: 0.8rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
`

export const MetricOptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`

export const MetricName = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkBlue')};
  ${sharedStyles}
`

export const HoldingName = styled.div`
  font-size: 1.2rem;
  color: ${color('lightGray')};
  text-transform: capitalize;
  ${sharedStyles}
`
