import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InvestmentsNavigationOption } from 'containers/Investments/types'
import { ReactChildren } from 'utils/types/common'
import theme from 'utils/theme'

import * as Styles from './NavigationItem.styles'
import { PinButton } from './NavigationItem.styles'

interface NavigationItemProps {
  iconHasBackground: boolean
  isDisabled: boolean
  isHovered?: boolean
  option: InvestmentsNavigationOption
  [x: string]: any
  children?: ReactChildren
  onClick: () => void
  onTogglePin?: (event: MouseEvent) => void
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  children,
  iconHasBackground,
  isDisabled,
  isHovered,
  option,
  onClick,
  onTogglePin,
  ...rest
}) => {
  const {
    isSelected,
    selectedIcon,
    nonSelectedIcon,
    label,
    isPinnable,
    pinned,
    type,
  } = option

  return (
    <Styles.NavigationItem
      isItemSelected={isSelected}
      onClick={!rest.disabled ? onClick : undefined}
      {...rest}
    >
      <Styles.IconWrapper
        isItemSelected={isSelected}
        hasBackground={iconHasBackground}
        optionType={type}
      >
        <FontAwesomeIcon
          color={isSelected ? theme.colors.primaryBlue : theme.colors.darkGray}
          size="sm"
          icon={isSelected ? selectedIcon : nonSelectedIcon}
        />
      </Styles.IconWrapper>
      <Styles.ItemName optionType={type}>{label}</Styles.ItemName>

      {isPinnable && (
        <PinButton
          onClick={(e) => {
            e.stopPropagation()
            onTogglePin?.(e)
          }}
          iconFamily={pinned && !isHovered ? 'fas' : 'far'}
          disabled={isDisabled}
          icon="thumbtack"
          buttonType="square"
          buttonSize="1.2rem"
          border={false}
          borderOnFocus={false}
          iconSize="xs"
          primary
          isPinned={pinned}
          isHovered={isHovered}
        />
      )}
    </Styles.NavigationItem>
  )
}

export default NavigationItem
