import { Company, Industry, Sector } from 'utils/types/company'
import { Portfolio } from 'utils/types/portfolios'
import { Tag } from 'utils/types/update'

export interface Filter {
  id: string
  name: string
  type: string
  sectors: Sector[]
  industries: Industry[]
  portfolios: Portfolio[]
  holdings: Company[]
  tags: Tag[]
}

export type InvestmentsFiltersObject = {
  id: string
  name: string
  classType: string
  holdings: string[]
  industries: string[]
  portfoliables: string[]
  sectors: string[]
  tags: string[]
}

export interface ApiFilterEntity {
  investmentFilters: { [id: string]: InvestmentsFiltersObject }
  holdings: { [id: string]: { holding: Company } }
  industries: { [id: string]: Industry }
  portfoliables: { [id: string]: { portfolio: Portfolio } }
  sectors: { [id: string]: Sector }
  tags: { [id: string]: Tag }
}

export class InvestmentFilterNormalizer {
  static populateFilterData = (
    entities: ApiFilterEntity,
    filter: InvestmentsFiltersObject
  ): Filter => {
    const holdingsData = filter.holdings.map(
      (holdingId) => entities.holdings[holdingId].holding
    )
    const industriesData = filter.industries.map(
      (industryId) => entities.industries[industryId]
    )
    const portfoliablesData = filter.portfoliables.map(
      (portfoliableId) => entities.portfoliables[portfoliableId].portfolio
    )
    const sectorsData = filter.sectors.map(
      (sectorId) => entities.sectors[sectorId]
    )
    const tagsData = filter.tags.map((tagId) => entities.tags[tagId])

    return {
      id: filter.id,
      name: filter.name,
      type: filter.classType,
      holdings: holdingsData,
      industries: industriesData,
      portfolios: portfoliablesData,
      sectors: sectorsData,
      tags: tagsData,
    }
  }
}
