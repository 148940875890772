import { UpdateEntityParams } from 'api/MixedUpdatesService'
import { useUpdatesFiltersContext } from 'containers/UpdatesView/UpdatesFiltersContext'
import { useMixedUpdatesQuery } from 'utils/hooks/queries/useMixedUpdatesQuery'
import { useHistory } from 'react-router-dom'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { getUpdateReshareUrl } from 'utils/functions/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useUpdatesProfilesFilter } from 'utils/hooks/useUpdatesProfilesFilter'
import { IndexUpdate } from 'utils/types/update'
import { EntityName } from 'utils/hooks/useEntityFromUrl'

const useProfileUpdates = ({ entityType, entityId }: UpdateEntityParams) => {
  const { filters, setFilters } = useUpdatesFiltersContext()
  const shouldDisplayUpdates = !useAppSelector(isActingAsInvestorGroup)
  const {
    filterDataSet,
    onChangeTypeFilters,
    onChangeTagFilters,
    onToggleDraftOnly,
  } = useUpdatesProfilesFilter({
    isSearchByTagAllowed: shouldDisplayUpdates,
    filters,
    setFilters,
  })

  const portfolio = useAppSelector(getCurrentPortfolio)
  const history = useHistory()

  const reshareUpdate = (update: IndexUpdate) => {
    history.push(
      getUpdateReshareUrl(update, false, {
        id: update.isPortfolioUpdate ? portfolio.id : update.holding?.id,
        name: update.isPortfolioUpdate
          ? EntityName.PORTFOLIOS
          : EntityName.COMPANIES,
      })
    )
  }

  const { data } = useMixedUpdatesQuery({
    entityType,
    entityId,
    filters,
  })

  const hasMixedUpdates = !!data?.pages?.some((page) => page.data.length > 0)

  return {
    portfolio,
    hasMixedUpdates,
    filterDataSet,
    filters,
    onChangeTypeFilters,
    onChangeTagFilters,
    onToggleDraftOnly,
    reshareUpdate,
  }
}

export default useProfileUpdates
