import { useMemo } from 'react'
import { useField } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'

import CWLoader from 'components/CWLoader'
import InfiniteScrollRef from 'components/InfiniteScrollRef'
import Modal from 'components/Modal'
import RunOutOfStorageTooltip from 'components/RunOutOfStorageTooltip'
import SortBy, { SortCriteria } from 'components/SortBy'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import FilesMemo from 'containers/Files/components/FilesMemo'
import FileViewSelector from 'components/FileViewSelector'
import Button from 'ui/Button'
import { SortDirection } from 'utils/constants'
import { FileContent, OrderByProps } from 'utils/types/files'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import { UpdateAttachment } from 'utils/types/transactions'
import useModalBrowseFiles from './useModalBrowseFiles'

import {
  ContentWrapper,
  FilterContainer,
  FooterWrapper,
  FormButtonsWrapper,
  LoaderContainer,
  ModalBody,
  ScrollWrapper,
  SearchFilesInput,
  SortDirectionLabel,
  UnreconciledOrderWrapper,
  FileFilterToggle,
  BrowseButton,
} from './ModalBrowseFiles.styles'

const filesSortCriteria: SortCriteria[] = [
  {
    id: `${OrderByProps.NAME}_${SortDirection.ASC}`,
    field: OrderByProps.NAME,
    direction: SortDirection.ASC,
    label: (
      <span>
        <FormattedMessage id="files.orderBy.name" />
        <SortDirectionLabel>(A-Z)</SortDirectionLabel>
      </span>
    ),
  },
  {
    id: `${OrderByProps.NAME}_${SortDirection.DESC}`,
    field: OrderByProps.NAME,
    direction: SortDirection.DESC,
    label: (
      <span>
        <FormattedMessage id="files.orderBy.name" />
        <SortDirectionLabel>(Z-A)</SortDirectionLabel>
      </span>
    ),
  },
  {
    id: `${OrderByProps.ADDED_DATE}_${SortDirection.ASC}`,
    field: OrderByProps.ADDED_DATE,
    direction: SortDirection.ASC,
    label: <FormattedMessage id="files.orderBy.firstDate" />,
  },
  {
    id: `${OrderByProps.ADDED_DATE}_${SortDirection.DESC}`,
    field: OrderByProps.ADDED_DATE,
    direction: SortDirection.DESC,
    label: <FormattedMessage id="files.orderBy.lastDate" />,
  },
]

interface Props {
  closeModal: () => void
  isModalOpen: boolean
  allowMultipleFiles?: boolean
  onlyFileContents?: boolean
  openFileDialog: () => void
  onSelectFiles: (files: FileContent[]) => void
}

const ModalBrowseFiles = ({
  isModalOpen,
  closeModal,
  openFileDialog,
  onSelectFiles,
  allowMultipleFiles = false,
  onlyFileContents = false,
}: Props) => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(maxSize.md)
  const [field] = useField<UpdateAttachment[]>('files')
  const filesAlreadyAdded = useMemo(
    () =>
      field?.value
        ?.filter(
          (singleFile) => !('destroy' in singleFile) || !singleFile.destroy
        )
        .map((singleFile) => singleFile.id),
    [field]
  )

  const {
    contents,
    isFetching,
    isLoading,
    infiniteScrollRef,
    scrollRef,
    selectedFiles,
    hasExceededStorage,
    search,
    debouncedSearch,
    handlers,
    sorting,
    filters,
    selectedGridSize,
  } = useModalBrowseFiles(
    isModalOpen,
    onSelectFiles,
    closeModal,
    filesSortCriteria,
    !!allowMultipleFiles,
    !!onlyFileContents
  )

  const renderZeroState = () => {
    if (debouncedSearch || filters.onlyUnreconciled) {
      return (
        <ContentWrapper>
          <ZeroState
            type={
              filters.onlyUnreconciled
                ? ZeroStateType.NO_FILES_MODAL_UNRECONCILED_FOUND
                : ZeroStateType.NO_FILES_MODAL_FOUND
            }
            infoMarginTop="0"
            maxWidth="50rem"
            textFontSize="1.6rem"
            titleFontSize="1.8rem"
            callToAction={
              !debouncedSearch ? handlers.onClearFilters : undefined
            }
            callToActionText={intl.formatMessage({
              id: 'zeroState.no_files_found.clear',
            })}
            zeroStateLink
            intlValues={{ search: debouncedSearch }}
          />
        </ContentWrapper>
      )
    }

    return (
      <ContentWrapper>
        <ZeroState
          type={ZeroStateType.MODAL_FILES}
          infoMarginTop="0"
          maxWidth="50rem"
          textFontSize="1.6rem"
          titleFontSize="1.8rem"
        />
      </ContentWrapper>
    )
  }

  const renderModalBody = () => {
    if (isLoading)
      return (
        <ContentWrapper>
          <CWLoader
            text={intl.formatMessage({ id: 'files.loadingFiles' })}
            logoSize="50px"
          />
        </ContentWrapper>
      )

    if (!contents.length) return renderZeroState()

    return (
      <>
        <FilesMemo
          isLoading={isLoading}
          filters={filters}
          contents={contents}
          selectedFiles={selectedFiles}
          showDropdown={false}
          showSharedIndicator={false}
          gridSize={selectedGridSize}
          onClickFile={handlers.onClickFile}
          onClickGeneralInformation={handlers.onClickFile}
          filesAlreadyAdded={filesAlreadyAdded}
          isModalBrowseFileView
        />

        <LoaderContainer>
          {isFetching && (
            <CWLoader
              text={intl.formatMessage({ id: 'files.loadingFiles' })}
              logoSize="50px"
            />
          )}
        </LoaderContainer>
      </>
    )
  }

  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <>
        <Modal.Header onHide={closeModal}>
          <Modal.Title>
            <FormattedMessage id="general.browseFiles" />
          </Modal.Title>
        </Modal.Header>

        <FilterContainer>
          <SearchFilesInput
            icon={['far', 'search']}
            iconFontSize="1.5rem"
            name="contentName"
            onChange={handlers.onChangeSearchHandler}
            value={search}
            placeholder={intl.formatMessage({
              id: 'files.search',
            })}
          />
          <UnreconciledOrderWrapper>
            <FileFilterToggle
              id="unreconciled"
              name="unreconciled"
              legend={intl.formatMessage({
                id: 'files.unreconciledFiles',
              })}
              status={!!filters.onlyUnreconciled}
              blue
              onChangeStatus={() => {
                handlers.switchUnreconciledFilter()
                scrollRef.current?.scrollTo({ top: 0 })
              }}
            />
            <SortBy
              initialSortBy={sorting.initialCriteria}
              containerPadding="0"
              sortCriteria={sorting.sortCriteria}
              onChange={(orderBy, direction) => {
                sorting.onChangeSortBy(orderBy, direction)
                scrollRef.current?.scrollTo({ top: 0 })
              }}
              padding="0.5rem 1.6rem"
              background="transparent"
              dropdownListWidth="15.3rem"
            />
          </UnreconciledOrderWrapper>
          <FileViewSelector
            selectedView={selectedGridSize}
            onChange={handlers.handleGridSizeChange}
          />
        </FilterContainer>

        <ModalBody>
          <ScrollWrapper ref={scrollRef}>
            <>
              {renderModalBody()}
              <InfiniteScrollRef ref={infiniteScrollRef} />
            </>
          </ScrollWrapper>
        </ModalBody>

        <Modal.Footer>
          <FooterWrapper>
            <RunOutOfStorageTooltip
              hasExceededStorage={hasExceededStorage}
              place="right"
            >
              <BrowseButton
                type="button"
                secondary
                onClick={openFileDialog}
                disabled={hasExceededStorage}
              >
                <FormattedMessage
                  id={isMobile ? 'general.browse' : 'general.browseMyComputer'}
                />
              </BrowseButton>
            </RunOutOfStorageTooltip>
            <FormButtonsWrapper>
              <Button type="button" onClick={handlers.handleCancel}>
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button
                primary
                type="button"
                disabled={selectedFiles.length === 0}
                onClick={handlers.handleConfirm}
              >
                <FormattedMessage id="general.addFiles" />
                {selectedFiles.length > 0 && ` (${selectedFiles.length})`}
              </Button>
            </FormButtonsWrapper>
          </FooterWrapper>
        </Modal.Footer>
      </>
    </Modal>
  )
}

export default ModalBrowseFiles
