import React from 'react'
import PropTypes from 'prop-types'

import styles from './UpdateLayout.module.scss'

const UpdateContainer = ({ children }) => {
  return <div className={styles.updateContainer}>{children}</div>
}

UpdateContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default UpdateContainer
