/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'

import UpdateService from 'api/UpdateService'
import {
  removeAlreadyAdded,
  setupUpdateEmailRequestData,
  getEmailUpdateDropdownOptions,
  getRemovedLoggingUpdateEntityIds,
} from 'utils/functions/updates'
import {
  getUserId,
  getCurrentGroupId,
  getCurrentGroupName,
  isActingAsFounder,
} from 'selectors/auth'
import Toast from 'components/Toast'

import useDuplicateEmail from 'utils/hooks/useDuplicateEmail'
import { useShowEmail } from 'containers/Updates/ShowEmail/useShowEmail'
import { useSetUpdatePermissions } from 'containers/Updates/SetUpdatePermissions/useSetUpdatePermissions'
import { SetupUpdateRequestDataParams } from 'utils/types/common'
import { getCurrentCompany } from 'selectors/company'

export const useEditEmail = () => {
  const { id: companyId } = useAppSelector(getCurrentCompany)

  const { update, updatePermissions, tagsList } = useShowEmail()

  const emailId = update?.item?.id
  const history = useHistory()
  const intl = useIntl()

  const {
    items,
    sharedGroups,
    setSharedGroups,
    sharedUsers,
    setSharedUsers,
    visibility,
    setVisibility,
    reshareSettings,
    onChangeReshareSettings,
    ownByGroup,
    setOwnByGroup,
  } = useSetUpdatePermissions({
    update,
    isConfidentialUpdate: update?.confidential,
    isBlockedUpdate: update?.blocked,
  })

  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {},
  })

  const [isCardExpanded, setIsCardExpanded] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const userId = useAppSelector(getUserId)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const currentGroupName = useAppSelector(getCurrentGroupName)
  const isUserActingAsFounder = useAppSelector(isActingAsFounder)
  const { duplicateEmail } = useDuplicateEmail(emailId)
  const [additionalRecipientsModal, setAdditionalRecipientsModal] =
    useState(false)
  const currentGroupIsOwnerOfEmail = currentGroupId === update?.groupId

  useEffect(() => {
    if (update) {
      setVisibility(update.visibility)
    }
  }, [currentGroupId, update, userId])

  const redirectToShow = () => history.goBack()

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)

      const data: SetupUpdateRequestDataParams = {
        visibility,
        sharedGroups: removeAlreadyAdded(sharedGroups, items),
        sharedUsers: removeAlreadyAdded(sharedUsers, items),
        ownByGroup,
        removedGroups: getRemovedLoggingUpdateEntityIds(
          update!.loggingUpdateGroups,
          sharedGroups,
          visibility
        ),
        removedUsers: getRemovedLoggingUpdateEntityIds(
          update!.loggingUpdateUsers,
          sharedUsers,
          visibility
        ),
        entity: 'email',
        updateName: 'update',
        tags: tagsList ?? [],
        isEditingUpdate: true,
        reshareSettings,
      }

      const formData = setupUpdateEmailRequestData(data, update!.id)

      await UpdateService.editEmail(update!.item.id, formData)

      Toast.display(
        intl.formatMessage({ id: 'success.updatingItem' }, { name: 'email' })
      )

      redirectToShow()
    } catch (error) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.updatingItemError' },
          { item: 'email' }
        ),
        'error'
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const onCardExpanded = () => {
    setIsCardExpanded(!isCardExpanded)
  }

  const toggleAdditionalRecipientsModal = useCallback(() => {
    setAdditionalRecipientsModal((state) => !state)
  }, [])

  const dropdownOptions = useMemo(
    () =>
      getEmailUpdateDropdownOptions({
        currentGroupIsOwnerOfEmail,
        intl,
        canEdit: false,
        onSelectDuplicateEmail: duplicateEmail,
        onSelectAdditionalRecipients: toggleAdditionalRecipientsModal,
        onSelectEdit: () => {},
      }),
    [
      duplicateEmail,
      intl,
      isUserActingAsFounder,
      toggleAdditionalRecipientsModal,
    ]
  )

  return {
    update,
    register,
    handleSubmit,
    onSubmit,
    onCardExpanded,
    companyId,
    emailId,
    isCardExpanded,
    updatePermissions,
    items,
    setSharedGroups,
    setSharedUsers,
    setValue,
    setVisibility,
    sharedGroups,
    sharedUsers,
    visibility,
    tagsList,
    currentGroupName,
    redirectToShow,
    additionalRecipientsModal,
    toggleAdditionalRecipientsModal,
    dropdownOptions,
    isSubmitting,
    reshareSettings,
    onChangeReshareSettings,
    ownByGroup,
    setOwnByGroup,
  }
}
