import { useQuery } from '@tanstack/react-query'
import InvestorManagementService, {
  Filters,
} from 'api/InvestorManagementService'

import Toast from 'components/Toast'
import { investmentVehiclesKeys } from 'utils/queries/investmentVehicles'

/**
 * Query to fetch all the fund portfolios where a specific investment vehicle has transactions
 *
 * @param investmentVehicleId - The id of the investment vehicle
 * @param filters - Custom filters to send to the API
 * @returns An instance of react-query
 *
 */
export const useFundPortfolioInvestmentVehicleQuery = (
  investmentVehicleId: string,
  filters?: Omit<Filters, 'page'>
) => {
  return useQuery(
    investmentVehiclesKeys.fundsPortfoliosByInvestmentVehicle(
      investmentVehicleId
    ),
    () => {
      try {
        return InvestorManagementService.fetchFundsPortfolioInvestors({
          investmentVehicleId,
          ...filters,
          page: 1,
        })
      } catch (err) {
        Toast.displayIntl(
          'investorManagement.errors.fetchFundPortfolios',
          'error'
        )
        return null
      }
    }
  )
}
