import styled from 'styled-components/macro'
import Tooltip from 'components/Tooltip'
import { DEFAULT_CELL_WIDTH } from 'components/Spreadsheet/utils'

export const FullCellTooltip = styled(Tooltip)`
  height: 100%;
  width: 100%;
`

export const ContentWrapper = styled.div<{ cellWidth?: number }>`
  min-width: ${({ cellWidth }) => cellWidth || DEFAULT_CELL_WIDTH}px;
  width: fit-content;
`
