import React from 'react'
import PropTypes from 'prop-types'

import theme from 'utils/theme'
import { accentColorIsDefaultColor } from 'utils/functions/groups'

import {
  PreviewContainer,
  PreviewLeft,
  PreviewRight,
  CWLogo,
  LogoImage,
  BigLogoImage,
  FormPreviewContainer,
  FormPreview,
  Overlay,
  CWMainBigLogo,
} from './CustomLoginPreview.styles'

export const accentColors = [
  { labelIntlKey: 'customLogin.default', code: theme.colors.primaryBlue },
  { labelIntlKey: 'customLogin.purple', code: theme.colors.purple },
  { labelIntlKey: 'customLogin.green', code: theme.colors.green },
  { labelIntlKey: 'customLogin.red', code: theme.colors.red },
  { labelIntlKey: 'customLogin.yellow', code: theme.colors.yellow },
]

const CustomLoginPreview = ({ bigImageUrl, mainLogoImageUrl, accentColor }) => {
  return (
    <PreviewContainer>
      <PreviewLeft>
        {bigImageUrl ? (
          <BigLogoImage url={bigImageUrl} />
        ) : (
          <>
            {!accentColorIsDefaultColor(accentColor) ? (
              <>
                <Overlay color={accentColor} />
                <Overlay darkOverlay />
              </>
            ) : (
              <Overlay color="#101527" />
            )}
            <CWMainBigLogo />
          </>
        )}
      </PreviewLeft>

      <PreviewRight>
        {mainLogoImageUrl ? <LogoImage src={mainLogoImageUrl} /> : <CWLogo />}
        <FormPreviewContainer>
          <FormPreview color={accentColor} />
        </FormPreviewContainer>
      </PreviewRight>
    </PreviewContainer>
  )
}

CustomLoginPreview.propTypes = {
  bigImageUrl: PropTypes.string,
  mainLogoImageUrl: PropTypes.string,
  accentColor: PropTypes.string,
}

CustomLoginPreview.defaultProps = {
  bigImageUrl: '',
  mainLogoImageUrl: '',
  accentColor: accentColors[0].code,
}
export default CustomLoginPreview
