import { ValueOf } from 'utils/types/common'
import { GroupTypes } from 'utils/constants/groups'
import { UpdateTypes } from 'utils/constants/updates'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { CreateUpdateFromRules } from 'utils/types/suggestedUpdate'

type UpdateTypesRules = {
  [key in ValueOf<typeof UpdateTypes>]?: CreateUpdateFromRules
}

type CreateUpdateRules = {
  [key in ValueOf<typeof GroupTypes>]: UpdateTypesRules
}

const allVisibleRules: CreateUpdateFromRules = {
  isOwnOrganizationVisible: true,
  areHoldingsVisible: true,
  selectablePortfolios: [
    PortfolioTypes.FUND,
    PortfolioTypes.INVEST,
    PortfolioTypes.TRACK,
    PortfolioTypes.DEAL,
  ],
}

const clientRules: CreateUpdateFromRules = {
  areHoldingsVisible: true,
  selectablePortfolios: [PortfolioTypes.INVEST, PortfolioTypes.TRACK],
}

export const CreateUpdateSectionsMapper: CreateUpdateRules = {
  [GroupTypes.INVESTOR_GROUP]: {},
  [GroupTypes.FUND_MANAGER]: {
    // accounting: {}, // TODO it isn't supported yet
    announcement: {
      isOwnOrganizationVisible: true,
      selectablePortfolios: [PortfolioTypes.FUND, PortfolioTypes.DEAL],
    },
    document: allVisibleRules,
    note: allVisibleRules,
    transaction: {
      isOwnOrganizationVisible: true,
      areHoldingsVisible: true,
      isTransactionTypeVisible: true,
      selectablePortfolios: [],
    },
    // report: {}, // TODO it isn't supported yet
  },
  [GroupTypes.FOUNDER]: {
    // accounting: {}, // TODO it isn't supported yet
    announcement: {},
    document: {},
    note: {},
    // report: {}, // TODO it isn't supported yet
  },
  [GroupTypes.CLIENT]: {
    document: clientRules,
    note: clientRules,
    transaction: {
      areHoldingsVisible: true,
      isTransactionTypeVisible: false,
      selectablePortfolios: [],
    },
  },
}
