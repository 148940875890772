import styled, { css } from 'styled-components'
import type { GroupStyle } from 'stream-chat-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'
import { ChatBreakpointSize } from 'utils/constants/chat'
import AvatarToStyle from 'components/Avatar'

import { MessageActions } from './components/MessageActions/MessageActions.styles'

const PaddingGap = '1.2rem'
const PaddingMapper: Record<GroupStyle, string> = {
  '': `${PaddingGap} 3.8rem 2.9rem 3.8rem`,
  top: `${PaddingGap} 3.8rem 0.6rem 3.8rem`,
  middle: `0.6rem 3.8rem 0.6rem 3.8rem`,
  bottom: `0.6rem 3.8rem 2.9rem 3.8rem`,
  single: `${PaddingGap} 3.8rem 2.9rem 3.8rem`,
}

const MobilePaddingMapper: Record<GroupStyle, string> = {
  '': `${PaddingGap} 2.4rem 2.4rem`,
  top: `${PaddingGap} 2.4rem 0.6rem`,
  middle: `0.6rem 2.4rem`,
  bottom: `0.6rem 2.4rem 1.2rem`,
  single: `${PaddingGap} 2.4rem 1.2rem`,
}

const AvatarStyles = css`
  width: 3.4rem;
  height: 3.4rem;
`

export const DummyAvatar = styled.div`
  ${AvatarStyles}
  display: flex;
  align-items: center;
  visibility: hidden;
  margin-left: -0.3rem;
  margin-right: 0.3rem;
`

export const Avatar = styled(AvatarToStyle)`
  ${AvatarStyles}
`

export const MessageContainer = styled.div<{
  isMyMessage?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  max-width: 70%;
  align-items: ${({ isMyMessage }) =>
    isMyMessage ? 'flex-end' : 'flex-start'};

  @media ${maxSize.xl} {
    max-width: 89%;
  }

  @media ${maxSize.tabletLg} {
    max-width: 80%;
  }

  @media ${maxSize.sm} {
    max-width: 100%;
  }
`

interface ContainerProps {
  isMyMessage?: boolean
  initialMessage?: boolean
  isAThreadMessage?: boolean
  groupStyle?: GroupStyle
}

export const ContainerAttributes = {
  groupStyleAttr: 'data-groupStyle',
  isAThreadMessageAttr: 'data-isAThreadMessage',
  initialMessageAttr: 'data-initialMessage',
}

export const Container = styled.div.attrs<ContainerProps>(
  ({ isAThreadMessage, groupStyle, initialMessage }) => ({
    [ContainerAttributes.isAThreadMessageAttr]: !!isAThreadMessage,
    [ContainerAttributes.groupStyleAttr]: groupStyle,
    [ContainerAttributes.initialMessageAttr]: !!initialMessage,
  })
)<ContainerProps>`
  position: relative;
  display: flex;
  gap: 1.3rem;
  padding: ${({ groupStyle }) => PaddingMapper[groupStyle || '']};
  justify-content: ${({ isMyMessage }) =>
    isMyMessage ? 'flex-end' : 'flex-start'};

  ${({ isAThreadMessage, groupStyle }) =>
    isAThreadMessage &&
    css`
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      ${groupStyle === 'bottom' && `padding-bottom: ${PaddingGap};`}

      ${MessageContainer} {
        max-width: calc(100% - 3.4rem - 1.3rem);
      }

      @media ${ChatBreakpointSize.tablet} {
        padding-left: 3.8rem;
        padding-right: 3.8rem;
      }
    `}

  ${({ initialMessage }) =>
    initialMessage &&
    css`
      padding-top: 2.4rem;

      ${MessageContainer} {
        max-width: 100%;
      }
    `}

  :hover {
    background-color: ${color('chatMessageHover')};

    ${DummyAvatar} {
      visibility: visible;
    }

    ${MessageActions} {
      visibility: visible;
    }
  }

  @media ${maxSize.mobile} {
    padding: ${({ groupStyle }) => MobilePaddingMapper[groupStyle || '']};

    ${({ initialMessage, isMyMessage }) =>
      (!initialMessage || !isMyMessage) &&
      css`
        ${MessageContainer} {
          max-width: calc(100% - 2.4rem - 2.4rem);
        }
      `}

    ${({ initialMessage }) =>
      initialMessage &&
      css`
        padding-bottom: 2.4rem;
      `}
  }
`

export const TheirMessage = styled.div<{
  deleted?: boolean
}>`
  padding: 1rem 1.4rem;
  background: ${color('chatMessage')};
  border-radius: 0rem 0.8rem 0.8rem 0.8rem;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('gray')};
  white-space: break-spaces;
  width: fit-content;
  max-width: 100%;
  word-break: break-word;
  text-align: left;

  ${({ deleted }) =>
    deleted &&
    css`
      color: ${color('lightGray')};
      border: 0.1rem solid ${color('veryLightGray')};
    `}

  .str-chat__message-mention {
    color: ${color('primaryBlue')};
    font-weight: 700;
  }

  .str-chat__message-attachment-card--url,
  .str-chat__message-url-link {
    color: ${color('primaryBlue')};
    cursor: pointer;
    font-weight: 700;

    :hover {
      text-decoration: underline;
    }
  }

  .str-chat__message-attachment-card {
    width: 100%;
  }

  .str-chat__message-attachment--card {
    margin-top: 1rem;
  }

  .str-chat__message-attachment-card--content {
    padding: 1rem 0 0 0 !important;
  }

  .str-chat__message-attachment-card--title,
  .str-chat__message-attachment-card--text,
  .str-chat__message-attachment-card--url {
    font-size: 1.2rem !important;
    padding-bottom: 0.2rem;
  }

  p {
    display: inline;
  }

  .markjs-highlight-text {
    color: ${color('primaryBlue')};
    border-radius: 0;
  }
`

export const MyMessageContainer = styled.div`
  max-width: 100%;
`

export const MyMessage = styled(TheirMessage)`
  background: ${color('primaryBlue')};
  border-radius: 0.8rem 0rem 0.8rem 0.8rem;
  color: white;

  ${({ deleted }) =>
    deleted &&
    css`
      background-color: white;
      color: ${color('lightGray')};
    `}

  .str-chat__message-mention {
    color: white;
  }

  .str-chat__message-attachment-card--url,
  .str-chat__message-attachment-card--title,
  .str-chat__message-attachment-card--text,
  .str-chat__message-url-link {
    color: ${color('cloud')};
  }
`

export const ThreadSeparator = styled.div`
  margin: 0 3.8rem;
  border-bottom: 0.1rem solid ${color('veryLightGray')};

  @media ${maxSize.mobile} {
    margin: 0 2.4rem;
  }
`

export const DeletedMessageIcon = styled(FontAwesomeIcon)`
  color: ${color('darkGray', 0.5)};
  padding-right: 0.8rem;
  font-size: 1.6rem;
`

export const WhiteEditedLabel = styled.span`
  color: ${color('white', 0.5)};
`

export const GrayEditedLabel = styled.span`
  color: ${color('gray', 0.5)};
`
