import { useEffect, useState } from 'react'
import { getUser } from 'selectors/auth'
import { getHasChatFeature } from 'selectors/chat'
import { StreamChat } from 'stream-chat'
import { Chat } from 'stream-chat-react'

import {
  getHasAnErrorConnectingTheUser,
  setHasAnErrorConnectingTheUser,
} from 'features/chatSlice'
import { getStreamChatApiKey } from 'utils/functions/env'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { useChatSetup } from 'utils/hooks/useChatSetup'
import { Nullable } from 'utils/types/common'

const ChatSetup = ({ children }) => {
  const [chatClient, setChatClient] = useState<Nullable<StreamChat>>(null)
  const [isConnected, setIsConnected] = useState(false)
  const { connect, disconnect } = useChatSetup()

  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const hasChatFeature = useAppSelector(getHasChatFeature)
  const chatSetupError = useAppSelector(getHasAnErrorConnectingTheUser)

  useEffect(() => {
    const client = new StreamChat(getStreamChatApiKey())

    const setupChat = async () => {
      try {
        setChatClient(client)
        await connect(client)
        setIsConnected(true)
        dispatch(setHasAnErrorConnectingTheUser(false))
      } catch (err) {
        dispatch(setHasAnErrorConnectingTheUser(true))
      }
    }

    if (hasChatFeature && user?.id) {
      setupChat()
      return () => {
        disconnect(client)
        setIsConnected(false)
      }
    }

    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, hasChatFeature])

  if (!isConnected || chatSetupError || !user?.id || !hasChatFeature) {
    return children
  }

  return <Chat client={chatClient!}>{children}</Chat>
}

export default ChatSetup
