import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const UniversityButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    height: 6rem;
  }
`

export const Text = styled.span`
  margin-left: 0.8rem;
  color: ${color('white')};
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: left;
`

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const PulseWrapper = styled.div`
  margin-left: 1.2rem;
`
