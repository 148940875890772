import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import GroupService from 'api/GroupService'
import {
  getCurrentGroupId,
  getUserId,
  isActingAsInvestorGroup,
} from 'selectors/auth'
import { groupKeys } from 'utils/queries/groups'
import { UserGroup } from 'utils/types/user'
import { useAppSelector } from '../reduxToolkit'
import { useObserveQuery } from '../useObserveQuery'

interface Props {
  enabled?: boolean
  includeSelectedMetrics?: boolean
  onSuccess?: (data: UserGroup) => void
}

export const useGetUserGroup = ({
  enabled,
  includeSelectedMetrics,
  onSuccess,
}: Props = {}) => {
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const currentUserId = useAppSelector(getUserId)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  return useQuery({
    queryKey: groupKeys.userGroup(
      currentUserId,
      currentGroupId,
      includeSelectedMetrics
    ),
    queryFn: async () => {
      return GroupService.getUserGroup({
        userId: currentUserId,
        groupId: currentGroupId,
        isInvestorGroup,
        includeSelectedMetrics,
      })
    },
    enabled,
    onSuccess,
  })
}

export const useObserveUserGroup = ({ includeSelectedMetrics }: Props = {}) => {
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const currentUserId = useAppSelector(getUserId)

  return useObserveQuery<UserGroup>(
    groupKeys.userGroup(currentUserId, currentGroupId, includeSelectedMetrics)
  )
}

export interface updateUserGroupPayload {
  setting: {
    [key: string]: any
  }
}

/**
 * Mutation to update a user group
 *
 * @returns An instance of useMutation
 *
 */
export const useUpdateUserGroup = (userGroupId: string) => {
  const queryClient = useQueryClient()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const currentUserId = useAppSelector(getUserId)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  return useMutation(
    (payload: updateUserGroupPayload) => {
      return GroupService.updateGroupUser(userGroupId, payload, isInvestorGroup)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          groupKeys.userGroup(currentUserId, currentGroupId)
        )
      },
    }
  )
}
