import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const Body = styled.div<{ columnsConfig?: string }>`
  display: grid;
  grid-template-columns: ${({ columnsConfig }) => columnsConfig || '2fr 1fr'};
  gap: 4.6rem;
  align-items: center;

  @media ${maxSize.xl} {
    gap: 3.2rem;
  }

  @media ${maxSize.lg} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const Container = styled.div`
  flex: 1;
  width: 100%;

  div[class*='Card_cardWrapper'] {
    padding: 0.8rem;
  }
`

export const Data = styled.div<{ columns: number }>`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
`

export const Cell = styled.div<{
  columns: number
  padding?: string
}>`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: ${({ padding }) => padding || '1.1rem'};
  height: 100%;
  cursor: default;

  border-right: 0.1rem solid var(--color-gray-200);
  border-bottom: 0.1rem solid var(--color-gray-200);

  &:nth-child(${({ columns }) => columns}n) {
    /* Every nth child element: last column */
    border-right: none;
  }

  &:nth-last-child(-n + ${({ columns }) => columns}) {
    /* Last n child elements: last row */
    border-bottom: none;
  }
`

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${color('lightGray')};
`

export const Value = styled.div`
  font-size: 1.8rem;
  font-weight: 400;
  padding-top: 0.4rem;
  color: #4a4a68;
`
