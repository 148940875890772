import styled, { css } from 'styled-components'

import IconButton from 'ui/IconButton'

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 4.8rem;
  margin-top: -7rem;
  margin-bottom: 2.5rem;
`

export const UpdateOptionsDropdownWrapper = styled.div``

export const MenuIconButton = styled(IconButton)`
  @media (max-width: 1040px) {
    display: none;
  }
`
export const ExpandArrowIconButton = styled(MenuIconButton)`
  margin: 0 0.3rem;
`

export const Collapsable = styled.div<{ hidden?: boolean }>`
  ${(props) =>
    props.hidden &&
    css`
      display: none;
    `}
`
