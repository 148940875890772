import styled, { css } from 'styled-components'
import Card from 'components/Card'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const EmailListContainer = styled.div`
  div[class*='cardWrapper'] {
    position: sticky;
    top: 1rem;
  }
`

export const EmailTimeline = styled(Card)<{
  transparent?: boolean
  isLoading?: boolean
}>`
  margin-left: 1.5%;
  width: 70%;
  background-color: ${(props) => props.transparent && 'transparent'};
  ${(props) =>
    props.isLoading &&
    css`
      min-height: 50rem;
    `}

  @media ${maxSize.tabletLg} {
    margin-left: 0;
    width: 100%;
    margin-top: 2%;
  }
`

export const ColumnsLayout = styled.div`
  display: flex;

  @media ${maxSize.tabletLg} {
    flex-direction: column;
  }
`

export const ComposeEmailWrapper = styled.div`
  margin-bottom: 3.2rem;
  display: flex;
  justify-content: space-between;
`
export const LoaderWrapper = styled.div`
  margin-top: 10rem;
`

export const DeleteConfirmationText = styled.p`
  color: ${color('darkBlue')};
  font-size: 1.8rem;
`
