import styled, { css } from 'styled-components'
import PulseIndicatorToStyle from 'components/PulseIndicator'

export const Container = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-right: 3.7rem;
  align-items: center;
`

export const Label = styled.span<{ color: string }>`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: ${({ color }) => color};
`
export const PulseIndicator = styled(PulseIndicatorToStyle)<{
  disableAnimation: boolean
}>`
  width: 1rem;
  min-width: 1rem;
  height: 1rem;

  ${({ disableAnimation }) =>
    disableAnimation &&
    css`
      animation: none;
    `}
`
