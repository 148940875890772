import React, { ReactNode, useCallback } from 'react'
import { CheckboxCheckMark } from 'ui/Icons'
import { isEnterKey, isSpaceKey } from 'utils/functions/keyboardEvents'
import { useMediaQuery, Media } from 'utils/hooks/useMediaQuery'
import * as Styles from './Checkbox.styles'

interface CheckboxProps {
  label: ReactNode
  checked?: boolean
  onChange?: (checked: any) => void
  useMobileStyles?: boolean
  id?: string
  name?: string
  disabled?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onChange,
  useMobileStyles = false,
  disabled,
  ...props
}) => {
  const { matches: matchesMobileQuery } = useMediaQuery(Media.MAX_MD)
  const isMobile = useMobileStyles && matchesMobileQuery

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.checked)
    },
    [onChange]
  )

  const handleKeyDown: React.KeyboardEventHandler<HTMLSpanElement> =
    useCallback(
      (event) => {
        if (isEnterKey(event) || isSpaceKey(event)) {
          event.preventDefault()
          onChange?.(!checked)
        }
      },
      [onChange, checked]
    )

  return (
    <Styles.Container disabled={disabled}>
      <Styles.HiddenCheckboxInput
        type="checkbox"
        {...props}
        onChange={handleChange}
      />
      <Styles.Checkbox
        checked={checked}
        isMobile={isMobile}
        tabIndex={0}
        onKeyDown={handleKeyDown}
      >
        {!!checked && (
          <CheckboxCheckMark
            width={isMobile ? 10 : 8}
            height={isMobile ? 8 : 6}
          />
        )}
      </Styles.Checkbox>
      <div>{label}</div>
    </Styles.Container>
  )
}

export default Checkbox
