import { getUser } from 'selectors/auth'
import EmailContentService from 'api/EmailContentService'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { EmailContentDetails } from 'utils/types/files'

export const useGroupManaged = (content: EmailContentDetails) => {
  const currentUser = useAppSelector(getUser)
  const canModifyGroupManaged =
    currentUser?.email === content.user?.email && content.unreconciled
  const onChangeGroupManaged = (status: boolean) => {
    if (canModifyGroupManaged) {
      EmailContentService.changeGroupManaged(content.id, status)
    }
  }

  return {
    onChangeGroupManaged,
    canModifyGroupManaged,
  }
}
