import get from 'lodash/get'
import { Color, DataVisualizationColor, Theme } from 'utils/theme'

/* eslint-disable no-bitwise */
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex?.slice(1, 3), 16)
  const g = parseInt(hex?.slice(3, 5), 16)
  const b = parseInt(hex?.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  }
  return `rgb(${r}, ${g}, ${b})`
}

export const color =
  (
    path: Color | DataVisualizationColor,
    opacity?: number,
    colorsProvider: keyof Theme = 'colors'
  ) =>
  (props) => {
    const theme = props.theme as Theme
    const themeColor = get(theme[colorsProvider], path)

    if (!themeColor) {
      throw new Error(`Color ${path} not found on theme`)
    }

    if (opacity !== undefined) {
      return hexToRGB(themeColor, opacity)
    }

    return themeColor
  }

export const seededRandomColor = (seed) => {
  function LCG(s) {
    return (Math.imul(741103597, s) >>> 0) / 2 ** 32
  }

  const symbols = '0123456789ABCDEF'

  let colorHex = '#'

  for (let i = 0; i < 6; i++) {
    colorHex += symbols[Math.round(LCG(seed + i) * 16)]
  }

  return colorHex
}
