import { FileContentNormalizer } from 'utils/functions/normalizers/fileContentNormalizer'
import {
  EmailContentDetailsApi,
  FileContentDetailsApi,
  LoggingUpdateApi,
} from 'utils/types/api/files'
import { UserApi } from 'utils/types/api/user'
import { EmailContentDetails } from 'utils/types/files'
import { Inbound } from 'utils/types/inbounds'
import { DiscardSuggestedUpdatePayload } from 'utils/types/suggestedUpdate'
import axiosClient from './httpClient'
import { ApiEntity, ApiShowResponse } from './types'

class EmailContentService {
  static async discardContent(
    values: DiscardSuggestedUpdatePayload
  ): Promise<void> {
    await axiosClient().patch(`/email_contents/${values.id}/discard`, values)
  }

  static async getContentById(id: string): Promise<EmailContentDetails> {
    const { data } = await axiosClient().get<
      ApiShowResponse<{
        emailContents: ApiEntity<EmailContentDetailsApi>
        users: ApiEntity<UserApi>
        s3Contents: ApiEntity<FileContentDetailsApi>
        loggingUpdates: ApiEntity<LoggingUpdateApi>
        linkedEmailAccounts: ApiEntity<Inbound>
      }>
    >(`email_contents/${id}`)

    return FileContentNormalizer.normalizeEmailContentDetails(
      data.result,
      data.entities
    )
  }

  static async changeGroupManaged(
    id: string,
    isGroupManaged: boolean
  ): Promise<void> {
    await axiosClient().patch(`/email_contents/${id}`, {
      emailContent: {
        groupManaged: isGroupManaged,
      },
    })
  }

  static async deleteContent(
    contentId: string,
    deleteRelatedFiles?: boolean
  ): Promise<string> {
    await axiosClient().delete(`/email_contents/${contentId}`, {
      data: {
        delete_s3_contents: deleteRelatedFiles,
      },
    })
    return contentId
  }
}

export default EmailContentService
