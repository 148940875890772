import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const DrawerFooter = styled.div`
  position: fixed;
  bottom: 0;
  right: 2rem;
  width: 37.7rem;
  height: 8.5rem;
  margin: 0 -2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2.4rem;
  border-top: 1px solid ${color('veryLightGray')};
  gap: 0.8rem;
  background: white;
  z-index: 2;
`
