import styled from 'styled-components'
import Pagination from 'components/Charts/MultiViewChart/components/Pagination'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const ChartPagination = styled(Pagination)`
  .actions {
    bottom: 0.5rem;
    left: 2.5rem;
    width: 94%;

    @media ${maxSize.md} {
      left: 1rem;
      width: 95%;
    }
  }
`

export const TooltipWrapper = styled.div`
  align-items: center;
  border: 0.1rem solid ${color('veryLightGray')};
  background-color: ${color('white')};
  border-radius: 0.8rem;
  box-shadow: 0px 0px 15px 0px #1015271a;
  display: flex;
  flex-direction: column;
  padding: 1rem;
`

export const TooltipValue = styled.span`
  color: ${color('gray')};
  font-size: 1.4rem;
  font-weight: 700;
`

export const TooltipDate = styled.span`
  color: ${color('gray')};
  font-size: 1.2rem;
  font-weight: 400;
`
