import { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import GroupTooltips from 'components/GroupTooltips'
import theme from 'utils/theme'
import { size } from 'utils/constants/breakpoint'
import { Logo } from 'utils/types/common'
import { ShareWithEntity } from 'utils/types/updateForm'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

import {
  GroupsWrapper,
  TooltipTitle,
  TooltipWrapper,
} from './UpdateVisibility.styles'

export interface HeaderShareWithEntity extends Omit<ShareWithEntity, 'type'> {
  logoComponent?: React.ReactNode
  logo?: Logo
  description?: string
}

interface Props {
  defaultGroupsPermissions: HeaderShareWithEntity[]
  groupsAndUsersPermissions: HeaderShareWithEntity[]
  disabled?: boolean
}

const UpdateVisibility = ({
  groupsAndUsersPermissions,
  defaultGroupsPermissions,
  disabled,
}: Props) => {
  const groupsLength =
    groupsAndUsersPermissions.length + defaultGroupsPermissions.length
  const { matches: isDesktop } = useMediaQuery(size.md)
  const { matches: isVisible } = useMediaQuery(size.xsm)

  if (!isVisible) {
    return null
  }

  const tooltipContent = (children: ReactNode) => (
    <div>
      <TooltipTitle>
        <FontAwesomeIcon icon={['far', 'eye']} />
        <FormattedMessage id="updates.header.updateVisibility" />
      </TooltipTitle>
      <TooltipWrapper>{children}</TooltipWrapper>
    </div>
  )

  return (
    <GroupsWrapper groupsLength={groupsLength} isMobile={!isDesktop}>
      <GroupTooltips
        direction="left"
        small
        isUpdateAction
        maxGroupsToDisplay={isDesktop ? 6 : 3}
        groups={groupsAndUsersPermissions}
        unsortedGroups={defaultGroupsPermissions}
        disabled={disabled}
        tooltipConfig={{
          backgroundColor: theme.colors.white,
          content: tooltipContent,
          clickable: true,
          delayHide: 300,
          parentTooltipStyles: {
            border: `1px solid ${theme.colors.veryLightGray}`,
            padding: '1.2rem 0.6rem 0.5rem 1.6rem',
            borderRadius: '0.8rem',
          },
        }}
      />
    </GroupsWrapper>
  )
}

export default UpdateVisibility
