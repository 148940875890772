import React from 'react'
import PropTypes from 'prop-types'
import { HintContainer, HintIcon, HintText } from './Hint.styles'

const Hint = ({ children }) => {
  return (
    <HintContainer>
      <HintIcon />
      <HintText>{children}</HintText>
    </HintContainer>
  )
}

Hint.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Hint
