import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import { size } from 'utils/constants/breakpoint'

export const Container = styled.div`
  border-radius: 0.8rem;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2.4rem;
  width: 100%;
  background-color: var(--color-white);
  min-height: 5.5rem;
  padding: 1.8rem 2.4rem;

  @media ${size.md} {
    padding-left: 2.8rem;
    padding-right: 3rem;
    min-height: 7rem;
  }
`
export const BackIcon = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  margin-right: 0.8rem;
  cursor: pointer;
  color: ${color('darkBlue')};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  margin-left: -1.5rem;
  gap: 0.8rem;

  button {
    position: inherit;
    top: 0;

    &:focus {
      outline: none;
    }
  }

  @media ${size.sm} {
    margin-left: 0;
  }
`
