export default {
  the: 'the',
  accountingReport: 'accounting report',
  investor: 'investor profile',
  investmentVehicle: 'investment vehicle',
  hasUpdatedEntity: 'has updated the {entity}',
  hasCreatedEntity: 'has created the {entity}',
  hasCreatedInvestmentVehicle: 'has created <bold>{name}</bold>',
  hasRemovedField: 'has removed the {fieldName}',
  changedLogFromTo:
    'has changed {fieldName} from <bold><quotes>{from}</quotes></bold> to <bold><quotes>{to}</quotes></bold>',
  updateEditedLogFromTo:
    'has edited {updateType} {fieldName} from <bold><quotes>{from}</quotes></bold> to <bold><quotes>{to}</quotes></bold>',
  updateAddedLogFromTo:
    'has added a {updateType} {fieldName} of <bold><quotes>{to}</quotes></bold>',
  updateChangedLogFromTo:
    'has changed {updateType} {fieldName} from <bold><quotes>{from}</quotes></bold> to <bold><quotes>{to}</quotes></bold>',
  removedField: 'has removed {fieldName} <bold><quotes>{from}</quotes></bold>',
  addedLogWithoutText: 'has added a {fieldName}',
  removedLogWithoutText: 'has removed {fieldName}',
  changedLogWithoutText: 'has changed {fieldName}',
  editedLogWithoutText: 'has edited {fieldName}',
  addedField: 'has added a {fieldName} <bold><quotes>{to}</quotes></bold>',
  addedUpdateField:
    'has added a {updateType} {fieldName} of <bold><quotes>{to}</quotes></bold>',
  addedFieldAn: 'has added an {fieldName} <bold><quotes>{to}</quotes></bold>',
  addedAddress: 'has added a new address <bold>"{text}"</bold>',
  deletedAddress: 'has deleted address <bold>"{text}"</bold>',
  setAsMainAddress: 'has set as main address <bold>"{text}"</bold>',
  deletedEmailAddress: 'has deleted an email address <bold>{email}</bold>',
  addedEmailAddress: 'has added an email address <bold>{email}</bold>',
  deletedPointOfContact: 'has deleted a point of contact <bold>{email}</bold>',
  addedPointOfContact: 'has added a new point of contact <bold>{email}</bold>',
  switchedFromTo: 'has switched from {from} to {to}',
  addedIndustry: 'has added a target industry <bold>{text}</bold>',
  removedIndustry: 'has removed a target industry <bold>{text}</bold>',
  removedMember: 'has removed member {text}',
  memberJoined: 'has joined <bold>{text}</bold>',
  sentInvitation: 'has sent invitation to <bold>{text}</bold>',
  editedInvestorName:
    'has edited <bold>{from}</bold> name to <bold>{to}</bold>',
  addedFoundedDate: 'has added a founded date <bold>{date}</bold>',
  deletedFoundedDate: 'has deleted the founded date <bold>{date}</bold>',
  changedFoundedDate:
    'has changed the founded date from <bold>{from}</bold> to <bold>{to}</bold>',
  addedWebsite: 'has added a new website <bold>{website}</bold>',
  deletedWebsite: 'has deleted the website <bold>{website}</bold>',
  changedWebsite:
    'has changed the website url from <bold>{from}</bold> to <bold>{to}</bold>',
  setAsPublicUpdate: 'set as a public update',
  setAsOnlyMe: '<bold>(you)</bold> set this update to be visible by only you',
  sharedUpdateWith: 'shared the update with <bold>{name}</bold>',
  sharedUpdateWithYouOrYourGroup: 'shared the update with you / your group',
  unsharedUpdateWith: 'removed <bold>{name}</bold> from the update',
  setOwnedByGroup: 'gave all group members permissions over this update',
  unsetOwnedByGroup: 'removed all group members permissions over this update',
  addedAttachment: 'has added attachment "{fileName}"',
  deletedAttachment: 'deleted attachment "{fileName}"',
  addedTag: 'has added tag "{tagName}"',
  removedTag: 'has removed tag "{tagName}"',
  addedScheduleDate: 'has scheduled the {updateType} to be shared on {date}',
  changedTransactionDate:
    'has changed the {transactionType} date from "{from}" to "{to}"',
  addedTransactionToPortfolio:
    'has added transaction to <bold>{portfolioName}</bold>',
  removedTransactionToPortfolio:
    'has removed transaction from <bold>{portfolioName}</bold>',
  addedScheduleDateWithRepetition:
    'has scheduled the {updateType} to be shared on {date}, and to repeat it {repetition}',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  annually: 'annually',
  commitment: 'Commitment',
  investment: 'Investment',
  distribution: 'Distribution',
  convertible_note: 'Convertible Note',
  debt_credit: 'Debt / Credit',
  equity: 'Equity',
  preferred_equity: 'Preferred Equity',
  fund_investment: 'Fund Investment',
  safe: 'SAFE',
  other: 'Other',
  updateDescription: {
    log: 'has {link}',
    editedAdditionalMessage: 'edited the additional message',
    editedTransactionDescription: "edited the transaction's description",
    editedDescription: 'edited the description',
    additionalMessageModalTitle: 'Additional Message Log',
    descriptionModalTitle: 'Description Log',
    lastDesctiption: 'Last Description',
    current: 'Current',
    noPreviousMessage: 'No previous message',
    messageDeleted: 'Message deleted',
  },

  fields: {
    name: 'name',
    email: 'email',
    phone: 'phone number',
    foundedDate: 'founded date',
    bankName: 'bank name',
    bankAccount: 'bank account',
    routingNumber: 'routing number',
    taxId: 'EIN / Tax ID',
    legalStructureName: 'legal structure',
    legalEntityName: 'legal entity name',
    legalProvinceName: 'legal state',
    legalCountryName: 'legal country',
    website: 'website',
    crunchbaseUrl: 'crunchbase profile',
    angellistUrl: 'angellist profile',
    linkedinUrl: 'linkedin profile',
    twitterUrl: 'X profile',
    industryId: 'twitter profile',
    title: 'title',
    text: 'additional message',
    date: 'date',
    amountCommitted: 'the amount committed',
    amountInvested: 'the amount invested',
    amountDistributed: 'the amount distributed',
    transactionType: 'type',
    instrumentType: 'instrument',
    valuationCap: 'valuation cap',
    discountRate: 'discount rate',
    discountRatePlaceholder: 'add discount rate',
    interestCalculationBasis: 'interest calculation basis',
    maturityDate: 'maturity date',
    purchasePricePerShare: 'purchase price per share',
    sharesPurchased: 'shares purchased',
    preMoneyValuation: 'pre-money valuation',
    postMoneyValuation: 'post-money valuation',
    drawdownAgainstCommitment: 'drawdown against commitment',
    annualManagementFee: 'annual management fee',
    carry: 'carry',
    carryHurdleRate: 'carry hurdle rate',
    dividend: 'dividend',
    dividendCalculationBasis: 'dividend calculation basis',
    dividendType: 'dividend type',
    cumulative: 'cumulative',
    noncumulative: 'non-cumulative',
    warrants: 'warrants',
    vestingCommencementDate: 'vesting commencement date',
    expirationDate: 'expiration date',
    strikePrice: 'strike price',
    numberOfShares: 'number of shares',
    scheduleDate: 'schedule date',
    repetition: 'repetition',
  },
  portfolios: {
    title: 'Portfolio Log',
    names: {
      pipeline: 'pipeline',
      pipelinePortfolio: 'pipeline portfolio',
      investPortfolio: 'invest portfolio',
      fundPortfolio: 'fund portfolio',
      dealPortfolio: 'deal portfolio',
    },
    created: 'created {type}',
    renamed: 'renamed {type} from "{from}" to "{to}"',
    addedCompany: 'added <bold>{company}</bold> to portfolio',
    removedCompany: 'removed <bold>{company}</bold> from portfolio',
    addedTransaction:
      'added a transaction (<bold>{transaction}</bold>) to this portfolio',
    removedTransaction:
      'removed a transaction (<bold>{transaction}</bold>) from this portfolio',
    addedFMV:
      'added carrying value <bold>{value}</bold> of <bold>{company}</bold> on this portfolio',
    editedFMV:
      'edited carrying value from <bold>{from}</bold> to <bold>{to}</bold> of <bold>{company}</bold> on this portfolio',
    removedFMV:
      'removed carrying value <bold>{value}</bold> of <bold>{company}</bold> from this portfolio',
    addedInvestor: 'added <bold>{investor}</bold> to portfolio',
    removedInvestor: 'removed <bold>{investor}</bold> from portfolio',
  },
}
