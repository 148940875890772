import { Channel } from 'stream-chat'
import { type IntlShape } from 'react-intl'

import { getChatTitle } from 'containers/Chat/helpers'
import {
  ChannelData,
  ChannelType,
  EventStreamChatType,
  isTopicChannel,
  StreamChatType,
} from 'containers/Chat/types'

type EventChannel =
  | EventStreamChatType['channel']
  | Channel<StreamChatType<ChannelData>>

const isEventStreamChannel = (
  channel: EventChannel
): channel is EventStreamChatType['channel'] => {
  return !(channel as Channel).state
}

const hasTitle = (channel: EventChannel, isDirectMessage: boolean) => {
  const hasTopicName = isEventStreamChannel(channel)
    ? channel.topicName || channel.name || channel.updateTitle
    : channel.data?.name

  return isDirectMessage || hasTopicName
}

const getChannelAbout = (channel: EventChannel) => {
  if (isEventStreamChannel(channel)) {
    return channel.updateTitle || channel.topicName
  }

  return isTopicChannel(channel.data)
    ? channel.data?.updateTitle || channel.data?.topicName
    : undefined
}

const getChannelUpdateTitle = (channel: EventChannel) => {
  if (isEventStreamChannel(channel)) {
    return channel.updateTitle
  }

  return isTopicChannel(channel.data) ? channel.data?.updateTitle : undefined
}

export const getChannelFromEvent = (event: EventStreamChatType) => {
  if (event.channel) {
    return {
      ...event.channel,
      type: event.channel_type as ChannelType,
    }
  }

  return undefined
}

export const getChatTitleFromEvent = (
  intl: IntlShape,
  channel: EventChannel,
  channelType: ChannelType,
  userId?: string
) => {
  const isDirectMessage = channelType === ChannelType.DIRECT_MESSAGE

  if (hasTitle(channel, isDirectMessage)) {
    const newChannel = isEventStreamChannel(channel)
      ? {
          ...channel,
          data: {
            name: channel?.name,
            topicName: channel?.topicName,
            entityType: channel?.entityType,
            updateTitle: channel?.updateTitle,
          },
          state: {
            members: channel?.members,
          },
        }
      : channel

    return getChatTitle(intl, newChannel as Channel<StreamChatType>, userId)
  }

  return undefined
}

export const getInText = (
  intl: IntlShape,
  channel: EventChannel,
  currentUserId?: string
) => {
  const isDirectMessage = channel?.type === ChannelType.DIRECT_MESSAGE
  const channelType = channel?.type as ChannelType
  const hasChannelTitle = hasTitle(channel, isDirectMessage)
  const defaultTitle = isEventStreamChannel(channel)
    ? getChatTitleFromEvent(intl, channel, channelType, currentUserId)
    : getChatTitle(intl, channel, currentUserId)
  const title = hasChannelTitle ? defaultTitle : undefined

  const about = getChannelAbout(channel)

  if (channelType === ChannelType.DIRECT_MESSAGE) {
    return intl.formatMessage(
      { id: 'pushNotifications.chat.inADirectMessage' },
      { members: title }
    )
  }

  if (title) {
    const topicTitle = !title ? getChannelUpdateTitle(channel) : title

    return intl.formatMessage(
      { id: 'pushNotifications.chat.inATopicChannel' },
      { topicName: topicTitle, about }
    )
  }

  return intl.formatMessage(
    { id: 'pushNotifications.chat.aConversationAbout' },
    { about }
  )
}

export const getCreatedDirectMessageText = (
  intl: IntlShape,
  members?: string,
  userName?: string
) => {
  return intl.formatMessage(
    {
      id: 'pushNotifications.chat.createdDirectMessage',
    },
    {
      userName: userName ?? '',
      members,
    }
  )
}

export const getCreatedTopicChannelText = (
  intl: IntlShape,
  topicName?: string,
  about?: string,
  userName?: string
) => {
  if (topicName) {
    return intl.formatMessage(
      {
        id: 'pushNotifications.chat.createdTopicChannel',
      },
      {
        userName: userName ?? '',
        topicName,
        about,
      }
    )
  }

  return intl.formatMessage(
    { id: 'pushNotifications.chat.createdTopicChannelInAConversationAbout' },
    {
      userName: userName ?? '',
      about,
    }
  )
}

export const getPendingMessagesText = (
  intl: IntlShape,
  count: number,
  inText: string
) => {
  return intl.formatMessage(
    {
      id: 'pushNotifications.chat.pendingMessages',
    },
    {
      count,
      in: inText,
    }
  )
}

export const getNewMessageText = (intl: IntlShape, inText: string) => {
  return intl.formatMessage(
    {
      id: 'pushNotifications.chat.newMessage',
    },
    {
      in: inText,
    }
  )
}

export const getMentionedYouText = (
  intl: IntlShape,
  inText: string,
  userName?: string
) => {
  return intl.formatMessage(
    {
      id: 'pushNotifications.chat.mentionedYou',
    },
    {
      in: inText,
      userName: userName ?? '',
    }
  )
}

export const getAddedToDirectMessageText = (
  intl: IntlShape,
  members?: string,
  userName?: string
) => {
  return intl.formatMessage(
    {
      id: 'pushNotifications.chat.addedToDirectMessage',
    },
    {
      userName: userName ?? '',
      members,
    }
  )
}

export const getAddedToTopicChannelText = (
  intl: IntlShape,
  topicName?: string,
  about?: string,
  userName?: string
) => {
  if (topicName) {
    return intl.formatMessage(
      {
        id: 'pushNotifications.chat.addedToTopicChannel',
      },
      {
        topicName,
        about,
        userName: userName ?? '',
      }
    )
  }

  return intl.formatMessage(
    { id: 'pushNotifications.chat.addedToTopicChannelInAConversationAbout' },
    {
      userName: userName ?? '',
      about,
    }
  )
}
