import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledHiddenValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  height: 1.6rem;
  min-width: max-content;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.6rem;
  svg {
    cursor: pointer;
    user-select: none;
    width: 1.75rem;
  }
`

export const Values = styled.div`
  align-items: center;
  display: flex;
  height: 1.7rem;
`

export const VisibleValues = styled.div`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
`
