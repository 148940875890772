import { CWLogo } from './Logo.styles'

export enum LOGO_SIZE {
  LARGE = 'large',
}

interface Props {
  size?: LOGO_SIZE
  withAnimation?: boolean
}

const Logo = ({ size, withAnimation }: Props) => (
  <CWLogo withAnimation={withAnimation} size={size} />
)

export default Logo
