import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  width: 52rem;
`

export const Body = styled.p`
  color: ${color('darkBlue')};
  font-size: 1.8rem;
`
