const CustomizedAxisTick = ({ x, y, payload }) => {
  const [month, year] = payload.value.split(' ')
  const monthWithDot = month.endsWith('.') ? month : `${month}.`
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="middle"
        fill="#666"
        transform="rotate(0)"
        fontSize="1.2rem"
      >
        {monthWithDot}
      </text>
      <text
        x={0}
        y={10}
        dy={15}
        textAnchor="middle"
        fill="#666"
        transform="rotate(0)"
        fontSize="1.2rem"
      >
        {year}
      </text>
    </g>
  )
}

export default CustomizedAxisTick
