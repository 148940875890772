import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const AddMetricContainer = styled.div`
  height: 100%;
  button[class*='Dropdown_dropdownOptionButton'] {
    text-transform: none;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;

  span[class^='Checkbox_checkmarkCustom'] {
    margin-right: 1rem;
  }

  span div[class*='tooltip'] {
    max-width: 31.3rem;
  }
`

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  gap: 1rem;
`

export const AutoSuggestPortfolioCompanies = styled.div<{ disabled?: boolean }>`
  margin-top: 1.8rem;
  color: #214b7d;
  font-size: 1.4rem;

  ${(props) =>
    props.disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ''}

  > p {
    margin-bottom: 1rem;
  }

  div[class*='Chip_container'] {
    width: auto;
  }

  ul[class^='BoxGroupsAndUsersChips'] {
    margin: 0;
  }

  div[class^='Chip_container'] {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
`
export const Label = styled.div<{ marginBottom?: string }>`
  color: #214b7d;
  font-size: 1.4rem;
  margin-bottom: ${(props) => props.marginBottom || '1.5rem'};
`

export const CheckboxText = styled.div`
  color: #383838;
  font-size: 1.5rem;
`

export const Separator = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin || 0};
`

export const OptionalSpan = styled.span`
  font-style: italic;
  color: #787e8c;
  font-size: 1.2rem;
  margin-left: 0.2rem;
`

export const MessageArea = styled.textarea`
  width: 100%;
  height: 9.4rem;
  border: dashed 0.1rem #333333;
  border-radius: 0.4rem;
  margin-bottom: 2.4rem;
  padding: 1rem;
  font-size: 1.4rem;
`

export const Emphasis = styled.em`
  font-size: 1.3rem;
  padding-top: 0.3rem;
  display: block;
  color: ${color('lightGray')};
`

export const ToggleWrapper = styled.div`
  display: flex;
`
export const Legend = styled.label`
  display: inline-block;
  margin-left: 0.8rem;
  color: ${color('darkGray')};
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0 0.8 rem 0.8 rem 0.8 rem;
`

export const ListsSelectionBoxWrapper = styled.div`
  padding: 0 1rem;
  margin-bottom: 1.8rem;
`

export const DropdownContainer = styled.div`
  button {
    flex-direction: column;
    align-items: flex-start;
  }
`
