import { useRef, useEffect, RefObject } from 'react'

const createScrollStopListener = (
  elementRef: RefObject<HTMLDivElement>,
  callback: () => void,
  timeout: number
) => {
  let removed = false
  let handle: NodeJS.Timeout | null = null
  const onScroll = () => {
    if (handle) {
      clearTimeout(handle)
    }
    handle = setTimeout(callback, timeout) // default 200 ms
  }
  elementRef.current?.addEventListener('scroll', onScroll)
  return () => {
    if (removed) {
      return
    }
    removed = true
    if (handle) {
      clearTimeout(handle)
    }
    elementRef.current?.removeEventListener('scroll', onScroll)
  }
}

const useScrollStopListener = (callback: () => void, timeout = 200) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const callbackRef = useRef<() => void>()
  callbackRef.current = callback

  useEffect(() => {
    const destroyListener = createScrollStopListener(
      containerRef,
      () => {
        if (callbackRef.current) {
          callbackRef.current()
        }
      },
      timeout
    )
    return () => destroyListener()
  }, [timeout])

  return containerRef
}

export default useScrollStopListener
