import { FC } from 'react'
import { Helmet } from 'components/Helmet'
import CWLoader from 'components/CWLoader'
import { LoaderWrapper } from './CreateWrapper.styles'

export interface CreateWrapperProps {
  isLoading: boolean
  children: JSX.Element
  title: string
}

const CreateWrapper: FC<CreateWrapperProps> = ({
  isLoading,
  children,
  title,
}) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {isLoading ? (
        <LoaderWrapper>
          <CWLoader />
        </LoaderWrapper>
      ) : (
        children
      )}
    </>
  )
}

export default CreateWrapper
