import { useEffect } from 'react'
import type { Channel, Event } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import uniqBy from 'lodash/uniqBy'

import { StreamChatType, SystemMessageType } from '../types'

type MoveChannelUpParams<StreamChatGenerics extends StreamChatType> = {
  channels: Array<Channel<StreamChatGenerics>>
  cid: string
  activeChannel?: Channel<StreamChatGenerics>
}

export const moveChannelUp = <StreamChatGenerics extends StreamChatType>({
  activeChannel,
  channels,
  cid,
}: MoveChannelUpParams<StreamChatGenerics>) => {
  // get index of channel to move up
  const channelIndex = channels.findIndex((channel) => channel.cid === cid)

  if (!activeChannel && channelIndex <= 0) return channels

  // get channel to move up
  const channel = activeChannel || channels[channelIndex]

  return uniqBy([channel, ...channels], 'cid')
}

/**
 * Custom hook to listen to new messages and move the channel to the top of the list.
 * Note: It ignores the system message when the current user leaves the channel.
 * @param setChannels Setter for the channels state
 * @returns void
 */
export const useMoveChannelUpOnNewMessage = <
  StreamChatGenerics extends StreamChatType
>(
  setChannels: React.Dispatch<
    React.SetStateAction<Array<Channel<StreamChatGenerics>>>
  >
) => {
  const { client } = useChatContext<StreamChatGenerics>()

  useEffect(() => {
    const handleEvent = (event: Event<StreamChatGenerics>) => {
      const isOwnLeftChannelSystemMessage =
        event.message?.type === 'system' &&
        event.message?.text?.includes(SystemMessageType.LEFT_CHAT) &&
        client.userID &&
        event.message?.text?.includes(client.userID)

      if (!isOwnLeftChannelSystemMessage) {
        setChannels((channels) => {
          return moveChannelUp({ channels, cid: event.cid || '' })
        })
      }
    }

    client.on('message.new', handleEvent)

    return () => {
      client.off('message.new', handleEvent)
    }
  }, [client, setChannels])
}
