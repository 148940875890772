import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import { BackgroundImage, ImageBorder } from 'ui/ImagePicker/ImagePicker.styles'

export const LogoTitle = styled.p`
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: ${color('darkGray')};
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
  padding-left: 1.4rem;
`

export const CompanyMandatoryInfo = styled.div`
  display: flex;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  width: 100%;
`

export const InvitationInfoContainer = styled.div`
  color: ${color('lightGray')};
  margin: 0 1.4rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  max-width: 50rem;

  svg {
    font-size: 1.5rem;
  }
`

export const InvitationInfoText = styled.p`
  font-size: 1.2rem;
`

export const ImagePickerContainer = styled.div`
  ${BackgroundImage}, ${ImageBorder} {
    width: 11.3rem;
    height: 11.3rem;
  }
`

export const SuggestionErrorContainer = styled.div`
  padding-top: 0.8rem;
  padding-left: 0.3rem;
`

export const DefaultError = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
`
