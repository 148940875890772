import styled, { css } from 'styled-components'
import { ReactComponent as Logo } from 'assets/images/clockwork-logo.svg'
import { LOGO_SIZE } from './Logo'

export const CWLogo = styled(Logo)<{
  withAnimation?: boolean
  size?: LOGO_SIZE
}>`
  #inner,
  #outer {
    display: none;
  }

  ${({ size }) =>
    size === LOGO_SIZE.LARGE &&
    css`
      position: absolute;
      left: -23rem;
      top: 50%;
      width: 158rem;
      transform: translateY(-50%);
      opacity: 0.4;

      @media (min-height: 1200px) {
        width: 180rem;
      }

      @media (min-width: 2250px) {
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @media (max-height: 1060px) {
        width: 153rem;
      }

      @media (max-height: 950px) {
        width: 135rem;
      }

      @media (max-height: 830px) {
        width: 124rem;
        left: -17rem;
      }
    `}

  ${({ withAnimation }) =>
    withAnimation &&
    css`
      #inner,
      #outer {
        display: block;
        animation-name: spin-cw-circles;
        animation-duration: 12000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        transform-origin: center;
      }

      #inner {
        animation-duration: 60000ms;
        animation-direction: reverse;
      }

      @keyframes spin-cw-circles {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
    `}
`
