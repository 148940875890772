import { FIELD_NAMES } from 'containers/UpdatesV2/Accounting/constants/constants'
import { useField } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import { AccountingReportType } from 'utils/constants/accounting'
import { getBalanceDateDropdownOptions } from 'utils/functions/renderers/renderUpdatesHelper'
import { getReportDatePeriodDropdownOptions } from 'utils/functions/accounting'
import { ScheduleType } from 'utils/types/update'
import { Dropdown } from '../commons'
import {
  DropdownContainer,
  ReportSettingsRow,
} from '../ReportSettings/ReportSettings.styles'
import PaymentsOnly from './components/PaymentsOnly'
import Periods from './components/Periods'
import StandardLayout from './components/StandardLayout'
import ReadOnlyField from '../commons/ReadOnlyField'

interface ReportFiltersProps {
  isEditMode?: boolean
  reportParamsDisabled?: boolean
  scheduleType: ScheduleType
}

const ReportFilters: React.FC<ReportFiltersProps> = ({
  isEditMode,
  reportParamsDisabled,
  scheduleType,
}) => {
  const intl = useIntl()
  const [date] = useField<Date>(FIELD_NAMES.date)
  const [balanceDateField] = useField(FIELD_NAMES.balanceDate)
  const [reportDatePeriodField] = useField(FIELD_NAMES.reportDatePeriod)
  const [reportTypeField] = useField<AccountingReportType>(
    FIELD_NAMES.reportType
  )
  const reportType = reportTypeField.value
  const shouldBeDisabled = !!reportParamsDisabled || !!isEditMode

  if (reportType)
    return (
      <div>
        <ReportSettingsRow>
          <DropdownContainer>
            {(reportType === AccountingReportType.XERO_PROFIT_AND_LOSS ||
              reportType === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS) &&
              (isEditMode ? (
                <ReadOnlyField
                  name={FIELD_NAMES.reportDatePeriod}
                  value={
                    getReportDatePeriodDropdownOptions(date.value, intl).find(
                      (el) => el.id === reportDatePeriodField.value
                    )?.label as string
                  }
                  label={intl.formatMessage({
                    id: 'general.selectPeriod',
                  })}
                  marginLeft="0"
                />
              ) : (
                <Dropdown
                  id={FIELD_NAMES.reportDatePeriod}
                  name={FIELD_NAMES.reportDatePeriod}
                  label={intl.formatMessage({ id: 'general.selectPeriod' })}
                  placeholder={intl.formatMessage({
                    id: 'general.selectPeriod',
                  })}
                  options={getReportDatePeriodDropdownOptions(date.value, intl)}
                  disabled={shouldBeDisabled}
                />
              ))}
            {(reportType === AccountingReportType.XERO_BALANCE_SHEET ||
              reportType === AccountingReportType.QUICKBOOKS_BALANCE_SHEET) &&
              (isEditMode ? (
                <ReadOnlyField
                  name={FIELD_NAMES.balanceDate}
                  label={intl.formatMessage({
                    id: 'general.selectBalanceDate',
                  })}
                  value={
                    getBalanceDateDropdownOptions(scheduleType, intl).find(
                      (el) => el.id === balanceDateField.value
                    )?.label
                  }
                />
              ) : (
                <Dropdown
                  name={FIELD_NAMES.balanceDate}
                  label={intl.formatMessage({ id: 'general.balanceDate' })}
                  placeholder={intl.formatMessage({
                    id: 'general.selectBalanceDate',
                  })}
                  options={getBalanceDateDropdownOptions(scheduleType, intl)}
                  disabled={shouldBeDisabled}
                />
              ))}
          </DropdownContainer>
          <Periods disabled={shouldBeDisabled} isEditMode={isEditMode} />
        </ReportSettingsRow>
        <PaymentsOnly disabled={shouldBeDisabled} />
        <StandardLayout reportType={reportType} disabled={shouldBeDisabled} />
      </div>
    )

  return null
}

export default ReportFilters
