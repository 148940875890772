import { BaseSyntheticEvent } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMessageContext } from 'stream-chat-react'

import StackedAvatarList from 'components/StackedAvatarList/StackedAvatarList'
import { OPEN_THREAD_EVENT } from 'containers/Chat/hooks/useIsThreadOpen'
import { dispatchEvent } from 'utils/hooks/useEventListener'

import * as Styles from './ThreadRepliesInfo.styles'

const ThreadRepliesInfo = () => {
  const { message, handleOpenThread } = useMessageContext()

  const onOpenThread = (event: BaseSyntheticEvent) => {
    dispatchEvent(OPEN_THREAD_EVENT)
    handleOpenThread(event)
  }

  return (
    <Styles.ThreadInfo onClick={onOpenThread}>
      <StackedAvatarList
        avatars={(message.thread_participants || []).map((participant) => ({
          id: participant.id,
          name: participant.name!,
          image: participant.image,
        }))}
      />
      <Styles.ThreadInfoText>
        <FormattedMessage
          id="chat.messageReplies"
          values={{
            count: message.reply_count,
          }}
        />
      </Styles.ThreadInfoText>
    </Styles.ThreadInfo>
  )
}

export default ThreadRepliesInfo
