import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { Legend } from '../../../Signup.styles'

export const BillTo = styled(Legend)`
  font-size: 1.8rem;
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
`

export const BillToInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LineSeparator = styled.hr<{ margin?: string }>`
  width: 100%;
  border-color: ${color('veryLightGray')};
  margin: ${(props) => props.margin || '2.4rem'} 0;
`
