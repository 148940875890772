import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import type { IntlShape } from 'react-intl'
import * as yup from 'yup'

export const getInvestorMetricsSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      ),
    companiesOrPortfolios: yup
      .array()
      .min(1, 'Enter at least one company or portfolio'),
    frequency: yup.string().nullable(),
    requestDataFromCompanies: yup.boolean(),
    message: yup.string().max(200),
  })

export const getFounderMetricsSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      ),
    sharedGroups: yup.array(),
    frequency: yup.string(),
    value: yup.string().nullable(),
    date: yup.date().nullable(),
  })

export const getSetNewValueMetricSchema = () =>
  yup.object().shape({
    metricValue: yup.string().required('This is a required field'),
    date: yup.date().required(),
  })

export const getEditValueMetricSchema = () =>
  yup.object().shape({
    date: yup.date().required(),
  })

export const getSetMilestoneSchema = () =>
  yup.object().shape({
    value: yup.string().required('This is a required field'),
    date: yup.date().required('This is a required field'),
    notifyValueReached: yup.boolean(),
    notifyPercentageReached: yup.boolean(),
    shared: yup.boolean(),
  })

export const getEditInvestorMetricSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      ),
    frequency: yup.string().required(),
  })

export const getEditFounderMetricSchema = (intl: IntlShape) =>
  yup.object().shape({
    name: yup
      .string()
      .required()
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      ),
    frequency: yup.string().required(),
    sharedGroups: yup.array(),
  })

export const getApproveMetricSchema = () =>
  yup.object().shape({
    frequency: yup.string().required(),
    value: yup.string(),
    date: yup.date(),
  })

export const requestValidationSchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  action: yup.string(),
  frequency: yup.string().when('action', {
    is: 'CREATE_METRIC',
    then: yup.string().required(),
    otherwise: yup.string().nullable(),
  }),
  metricToAdd: yup.object().when('action', {
    is: 'CREATE_METRIC',
    then: yup.object().nullable(),
    otherwise: yup
      .object()
      .required('Add to metric is a required field')
      .nullable(),
  }),
  historicalData: yup.boolean(),
})

export const requestsList = yup.object().shape({
  requests: yup.array().of(requestValidationSchema),
})

export const getBulkImportMetricValuesSchema = (intl) =>
  yup.object().shape({
    metricValues: yup.array().of(
      yup.object().shape({
        dataPointValue: yup
          .string()
          .required(intl.messages['general.required']),
        dataPointDate: yup
          .date()
          .max(new Date())
          .required(intl.messages['general.required']),
      })
    ),
  })
