import { ScheduleRepetitionType } from 'utils/constants/scheduleRepetition'
import { UpdateTypes } from 'utils/constants/updates'
import { getDefaultFormValues } from 'utils/functions/updates'
import { useCreateAccountingMutation } from 'utils/hooks/queries/useUpdateQuery'
import useSettings from 'utils/hooks/useSettings'
import { AccountingFormValues } from 'utils/types/updateForm'
import { ReportDatePeriodType } from '../../../../utils/constants/accounting'
import { FIELD_NAMES } from '../constants/constants'

const useCreateAccounting = () => {
  const { mutate: createAccountingUpdate } = useCreateAccountingMutation()
  const { settings } = useSettings()

  const defaultValues: AccountingFormValues = {
    [FIELD_NAMES.title]: '',
    [FIELD_NAMES.date]: new Date(),
    [FIELD_NAMES.repetition]: ScheduleRepetitionType.NO_REPETITION,
    [FIELD_NAMES.periods]: undefined,
    [FIELD_NAMES.reportType]: undefined,
    [FIELD_NAMES.balanceDate]: undefined,
    [FIELD_NAMES.reportDatePeriod]: ReportDatePeriodType.CURRENT_MONTH,
    [FIELD_NAMES.standardLayout]: false,
    [FIELD_NAMES.paymentsOnly]: false,
    ...getDefaultFormValues(
      UpdateTypes.ACCOUNTING,
      settings?.updatesPermissions?.groupOwned
    ),
  }

  return {
    defaultValues,
    createAccountingUpdate,
  }
}

export default useCreateAccounting
