import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GroupLogo from 'components/GroupLogo'
import { GroupName, Row } from 'components/GroupTooltips/GroupTooltips.styles'
import Tooltip from 'components/Tooltip'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import IconButton from 'ui/IconButton'
import {
  castLoggingUpdateGroup,
  ReshareTokenSet,
} from 'utils/functions/updates'
import { LoggingUpdateEntity, LoggingUpdateUser } from 'utils/types/update'

import {
  ReshareRowContainer,
  ReshareRowContent,
  ReshareRowTitleContainer,
  EditReshareContainer,
  ReshareAvatarIcon,
  ReshareRowDate,
  ReshareMessage,
  MessageTitle,
  ReshareWithDiv,
  Names,
  CountReshare,
} from './ReshareHistory.styles'

interface ReshareHistoryRowProps {
  reshareTokenSet: ReshareTokenSet
  onEditReshare?: (tokenSet: ReshareTokenSet) => void
  isEditable: boolean
}
const ReshareHistoryRow: React.FC<ReshareHistoryRowProps> = ({
  reshareTokenSet,
  isEditable,
  onEditReshare = undefined,
}) => {
  const intl = useIntl()
  const [reshare] = reshareTokenSet.reshares
  const { message } = reshareTokenSet

  const getMessageLogo = () => {
    if (isEditable) {
      return (
        <ReshareAvatarIcon>
          <FontAwesomeIcon icon={['far', 'retweet']} />
        </ReshareAvatarIcon>
      )
    }

    return <GroupLogo forceInitials name={reshare.userName} imageUrl="" />
  }

  const getLoggingUpdateEntityData = (entity: LoggingUpdateEntity) => {
    if (castLoggingUpdateGroup(entity)) {
      return {
        id: entity.groupableId,
        name: entity.groupName,
        logo: entity.groupLogo,
      }
    }

    const userEntity = entity as LoggingUpdateUser

    return {
      id: userEntity.userId,
      name: userEntity.userName || userEntity.userEmail,
      logo: userEntity.userImage,
    }
  }

  const formatEntitiesList = (entities) => {
    if (entities.length === 1) {
      return entities[0].name
    }

    if (entities.length <= 4) {
      const lastEntity = entities.pop()
      return `${entities
        .map((entity) => entity.name)
        .join(', ')} ${intl.formatMessage({ id: 'updates.and' })} ${
        lastEntity.name
      }`
    }

    const [entity1, entity2, entity3] = entities
    return `${entity1.name}, ${entity2.name}, ${entity3.name}`
  }

  const getMessageTitle = () => {
    if (isEditable) {
      const entities = uniqBy(
        reshareTokenSet.loggingUpdateEntities.map(getLoggingUpdateEntityData),
        (entityValue) => entityValue.id
      )

      if (entities.length === 0) {
        return reshare.userName
      }

      const formattedEntities = formatEntitiesList(entities)

      if (entities.length <= 4) {
        return intl.formatMessage(
          { id: 'updates.resharedWith' },
          { names: <Names>{formattedEntities}</Names> }
        )
      }

      return (
        <>
          <ReshareWithDiv>
            {intl.formatMessage(
              { id: 'updates.resharedWith' },
              { names: <Names>{formattedEntities}</Names> }
            )}
          </ReshareWithDiv>
          <Tooltip
            id={`resharesTooltip_${reshareTokenSet.token}`}
            place="bottom"
            text={
              <span>
                {entities.slice(3).map((entity) => (
                  <Row key={entity.id}>
                    <GroupLogo
                      imageUrl={entity.logo.smallLogo.url}
                      name={entity.name}
                      small
                      forceInitials={false}
                    />
                    <GroupName>{entity.name}</GroupName>
                  </Row>
                ))}
              </span>
            }
          >
            <CountReshare>
              {intl.formatMessage(
                { id: 'updates.moreReshares' },
                { count: entities.length - 3 }
              )}
            </CountReshare>
          </Tooltip>
        </>
      )
    }

    return reshare.userName
  }

  return (
    <ReshareRowContainer>
      {getMessageLogo()}
      <ReshareRowContent>
        <ReshareRowTitleContainer>
          <MessageTitle>{getMessageTitle()}</MessageTitle>
          {isEditable && (
            <EditReshareContainer>
              <IconButton
                icon="pen"
                border={false}
                onClick={() => onEditReshare!(reshareTokenSet)}
              />
            </EditReshareContainer>
          )}
        </ReshareRowTitleContainer>
        <ReshareRowDate>
          {dayjs(reshare.createdAt).format('dddd DD MMMM - hh:mmA')}
        </ReshareRowDate>{' '}
        <ReshareMessage>{message}</ReshareMessage>
      </ReshareRowContent>
    </ReshareRowContainer>
  )
}

export default ReshareHistoryRow
