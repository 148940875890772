import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const PaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
`

export const PaginatorContainer = styled.button`
  background-color: white;
  border-radius: 0.4rem;
  border: solid 0.1rem #e9e9e9;
  border-radius: 8px;
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0.5rem;
  padding: 1rem;

  ${(props) =>
    props.embeddedPaginator &&
    css`
      display: inline-block;
      width: calc(100% - 6.4rem);
    `}

  svg {
    font-size: 1.6rem;
    margin-right: 1rem;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${color('veryLightBlue', 0.2)};
    border: 1px solid ${color('veryLightBlue')};
    color: ${color('primaryBlue')};
  }
`
