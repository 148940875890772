import CWLoader from 'components/CWLoader'
import theme from 'utils/theme'

import { Container } from './Loading.styles'

const Loading = () => {
  return (
    <Container>
      <CWLoader color={theme.colors.white} />
    </Container>
  )
}

export default Loading
