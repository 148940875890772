import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import Chip from 'components/Chip'
import { isActingAsFundManager } from 'selectors/auth'
import { InvestorInvestmentVehicle } from 'utils/types/investors'
import { VehiclesContainer } from './InvestmentVehicles.styles'

interface Props {
  investmentVehicles: InvestorInvestmentVehicle[]
  investorId: string
}

const InvestmentVehicles = ({ investmentVehicles, investorId }: Props) => {
  const history = useHistory()
  const isFundManager = useAppSelector(isActingAsFundManager)

  const redirectToVehicleProfile = (vehicleId: string) => {
    const investmentVehicleUrl = isFundManager
      ? `/investor-management/investors/${investorId}/profile/vehicles/${vehicleId}`
      : `/investment-vehicles/${vehicleId}`
    history.push(investmentVehicleUrl)
  }

  return (
    <VehiclesContainer>
      {investmentVehicles.map((vehicle) => (
        <Chip
          canDelete={false}
          className="portfolio"
          key={vehicle.id}
          maxWidth="min-content"
          text={vehicle.name}
          isSmall
          onClick={() => redirectToVehicleProfile(vehicle.id)}
          withCursorPointer
          titleWithTooltip
          // This is a hack to make the chip take the full width of the container when the text is too long
          width={vehicle.name.length > 35 ? '100%' : undefined}
        />
      ))}
    </VehiclesContainer>
  )
}

export default InvestmentVehicles
