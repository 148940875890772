import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'
import { flatMap } from 'lodash'

import {
  DiscardSuggestedUpdateFields,
  DiscardSuggestedUpdateForm,
} from 'utils/types/suggestedUpdate'
import { SuggestedUpdate } from 'utils/types/update'
import Dropdown from 'ui/Dropdown'
import EmailContentRelatedFiles from 'containers/Files/components/DeleteFileModal/components/EmailContentRelatedFiles/EmailContentRelatedFiles'

import { DiscardFormController } from './useDiscardForm'
import {
  Container,
  DropdownContainer,
  PrimaryText,
  SecondaryText,
} from './DiscardForm.styles'

interface DiscardFormProps {
  suggestedUpdates: SuggestedUpdate[]
  discardFormController: DiscardFormController
  className?: string
}

export const DISCARD_REASON_CONTAINER_ID = 'discard-reason-container'

const DiscardForm = ({
  suggestedUpdates,
  discardFormController,
  className,
}: DiscardFormProps) => {
  const intl = useIntl()
  const {
    formikRef,
    discardSuggestedUpdate,
    validateForm,
    discardSuggestedUpdateReasons,
    intlDiscardKey,
  } = discardFormController
  const suggestedUpdatesContents = flatMap(
    suggestedUpdates.map((s) => s.contents)
  )
  const contentTitle =
    suggestedUpdates.length === 1 ? suggestedUpdates[0].data.title : undefined

  return (
    <Formik<DiscardSuggestedUpdateForm>
      innerRef={formikRef}
      initialValues={{
        suggestedUpdateIds: suggestedUpdates.map((s) => s.id),
        discardContents: false,
      }}
      onSubmit={discardSuggestedUpdate}
      validateOnChange
      validate={validateForm}
    >
      {(formik) => (
        <Container className={className}>
          <PrimaryText>
            <FormattedMessage
              id={`suggestedUpdates.discard.${intlDiscardKey}.question`}
            />
          </PrimaryText>
          <DropdownContainer id={DISCARD_REASON_CONTAINER_ID}>
            <Dropdown
              id="discard-reason"
              placeholder={intl.formatMessage({ id: 'general.select' })}
              options={discardSuggestedUpdateReasons}
              onSelectOption={(_, selectedOptionId) => {
                formik.setFieldValue(
                  DiscardSuggestedUpdateFields.Reason,
                  selectedOptionId
                )
              }}
            />
          </DropdownContainer>
          {suggestedUpdatesContents.length > 0 && (
            <EmailContentRelatedFiles
              fromSuggestedUpdate
              contentTitle={contentTitle}
              discardIntlKey={intlDiscardKey}
              files={suggestedUpdatesContents}
              onCheckboxChange={(value: boolean) => {
                formik.setFieldValue(
                  DiscardSuggestedUpdateFields.DiscardContents,
                  value
                )
              }}
            />
          )}
          <SecondaryText>
            <FormattedMessage
              id={`suggestedUpdates.discard.${intlDiscardKey}.description`}
            />
          </SecondaryText>
        </Container>
      )}
    </Formik>
  )
}

export default DiscardForm
