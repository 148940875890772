import Checkbox from 'components/Checkbox'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ReshareSettingCheckbox = styled(Checkbox)`
  .checkbox-legend {
    color: ${color('darkBlue')};
    font-weight: 700;
  }
`
export const ReshareSettingCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  svg {
    font-size: 1.4rem;
  }
`
export const ReshareSettingText = styled.p`
  color: ${color('darkGray')};
  line-height: 1.7rem;
  font-size: 1.4rem;
  padding-left: 2.5rem;
  margin: 0;
`
