import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { AccountingReportType } from 'utils/constants/accounting'
import {
  getMockedBalanceSheet,
  getMockedProfitAndLoss,
} from 'utils/functions/xeroReports'
import {
  getMockedBalanceSheet as getQBMockedBalanceSheet,
  getMockedProfitAndLossDetail,
} from 'utils/functions/quickbooksReports'
import { ScheduleType } from 'components/ScheduleUpdateRadio/ScheduleUpdateRadio'
import ShowXeroReport from 'components/AccountingReports/ShowXeroReport'
import ShowQuickbooksReport from 'components/AccountingReports/ShowQuickbooksReport'

import { Modal, Header } from './ReportPreviewModal.styles'

const ReportPreviewModal = ({
  isOpen,
  setIsOpen,
  periods,
  date,
  reportType,
  scheduleType,
  reportParams,
}) => {
  const intl = useIntl()

  const onHide = () => {
    setIsOpen(false)
  }

  const periodsToCompare = Number(periods) + 1

  const mockedData = useMemo(() => {
    switch (reportType) {
      case AccountingReportType.XERO_BALANCE_SHEET:
        return getMockedBalanceSheet({
          periods: periodsToCompare,
          balanceDate: reportParams.balanceDate,
          date,
          scheduleType,
        })
      case AccountingReportType.XERO_PROFIT_AND_LOSS:
        return getMockedProfitAndLoss({
          periods: periodsToCompare,
          reportDatePeriod: reportParams.reportDatePeriod,
          date,
        })
      case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
        return getQBMockedBalanceSheet({
          balanceDate: reportParams.balanceDate,
          date,
          scheduleType,
        })
      case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
        return getMockedProfitAndLossDetail({
          balanceDate: reportParams.balanceDate,
          date,
          scheduleType,
        })

      default:
        return {}
    }
  }, [
    date,
    periodsToCompare,
    reportParams.balanceDate,
    reportParams.reportDatePeriod,
    reportType,
    scheduleType,
  ])

  const getModalPreview = () => {
    switch (reportType) {
      case AccountingReportType.XERO_BALANCE_SHEET:
      case AccountingReportType.XERO_PROFIT_AND_LOSS:
        return (
          <ShowXeroReport
            data={mockedData}
            date={date}
            scheduleType={scheduleType}
            border={false}
            reportType={reportType}
          />
        )
      case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
      case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
        return (
          <ShowQuickbooksReport
            data={mockedData}
            date={date}
            scheduleType={scheduleType}
            border={false}
            reportType={reportType}
          />
        )

      default:
        return null
    }
  }

  const getReportTitle = () => {
    switch (reportType) {
      case AccountingReportType.XERO_BALANCE_SHEET:
      case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
        return intl.messages['updates.balanceSheetPreview']
      case AccountingReportType.XERO_PROFIT_AND_LOSS:
      case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
        return intl.messages['updates.profitAndLossPreview']

      default:
        return null
    }
  }

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.Title>
          <Header>{getReportTitle()}</Header>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{getModalPreview()}</Modal.Body>
    </Modal>
  )
}

ReportPreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func.isRequired,
  periods: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  date: PropTypes.instanceOf(Date),
  reportType: PropTypes.string.isRequired,
  scheduleType: PropTypes.string,
  reportParams: PropTypes.object,
}

ReportPreviewModal.defaultProps = {
  isOpen: false,
  periods: 1,
  date: new Date(),
  scheduleType: ScheduleType.SEND_NOW,
  reportParams: {},
}
export default ReportPreviewModal
