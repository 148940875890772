import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { UpdateTypes } from 'utils/constants/updates'
import { UpdateType } from 'utils/types/common'
import { UpdateLogsBuilder } from '../models/logs-builders/UpdateLogsBuilder'
import DescriptionDiffModal from './components/DescriptionDiffModal/DescriptionDiffModal'
import * as Styles from './UpdateDescriptionLog.styles'

export const getTextFieldLogMessageId = (updateType: UpdateType) => {
  switch (updateType) {
    case UpdateTypes.TRANSACTION:
      return 'logs.updateDescription.editedTransactionDescription'
    case UpdateTypes.DOCUMENT:
      return 'logs.updateDescription.editedAdditionalMessage'
    default:
      return 'logs.updateDescription.editedDescription'
  }
}

interface UpdateDescriptionLogProps {
  logBuilder: UpdateLogsBuilder
}

const UpdateDescriptionLog: React.FC<UpdateDescriptionLogProps> = ({
  logBuilder,
}) => {
  const intl = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const getLinkElement = () => (
    <Styles.LinkStyles onClick={() => setIsModalOpen(true)}>
      {intl.formatMessage({
        id: getTextFieldLogMessageId(logBuilder.updateType),
      })}
    </Styles.LinkStyles>
  )

  return (
    <>
      {intl.formatMessage(
        { id: 'logs.updateDescription.log' },
        {
          link: getLinkElement(),
        }
      )}
      <DescriptionDiffModal
        logBuilder={logBuilder}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default UpdateDescriptionLog
