import { Scroll } from 'App.styles'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import ShowMoreLessToStyle from 'components/ShowMoreLess'
import { UpdateTextContainer } from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'

export const ScrollContainer = styled.div`
  ${Scroll}
  max-height: 100%;
  width: min(76rem, calc(100% - 3.5rem - 3.5rem));
  padding: 0 1rem;
`

export const Container = styled.div`
  width: 100%;
  background-color: ${color('white')};
  border-radius: 0.8rem;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`

export const Subject = styled.div`
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${color('darkGray')};
  text-transform: capitalize;
`

export const DetailsContainer = styled.div`
  display: flex;
  gap: 0.2rem;
  flex-direction: column;
`

export const ShowMoreLess = styled(ShowMoreLessToStyle)`
  font-size: 1.2rem;
`

export const Details = styled.div`
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${color('lightGray')};

  span:not(${ShowMoreLess}) {
    font-weight: 400;
  }
`

export const TextContainer = styled.div`
  ${UpdateTextContainer} {
    margin-top: 0;
  }
`

export const AttachmentsContainer = styled.div`
  display: flex;
  gap: 0.9rem;
  flex-wrap: wrap;
`
