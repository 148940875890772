import { useIntl } from 'react-intl'
import { AllFundsChartDataWithPercentages } from 'utils/functions/portfolios'
import { ChartColors } from 'utils/types/portfolios'

import {
  TooltipContainer,
  Title,
  TooltipContent,
  Percentage,
  Company,
  Name,
} from './FundPortfoliosPieChartTooltip.styles'

const DECIMAL_PRECISION = 1

interface Payload {
  dataKey: 'totalCommittedCapital' | 'totalCapitalCalled'
  payload: AllFundsChartDataWithPercentages
}

interface Props {
  payload?: Payload[]
  colorsForPortfolios: ChartColors
  active?: boolean
}

const FundPortfoliosPieChartTooltip = ({
  active,
  payload,
  colorsForPortfolios,
}: Props) => {
  const intl = useIntl()
  if (active && payload?.length) {
    const { payload: fundPortfolio, dataKey } = payload[0]
    const percentage = fundPortfolio?.[`${dataKey}Percentage`]

    const keyTranslation = `fundDashboard.${dataKey}`

    const title = intl.formatMessage({ id: keyTranslation })

    return (
      <TooltipContainer key={fundPortfolio?.id}>
        <Title>{title}</Title>
        <TooltipContent>
          <Percentage color={colorsForPortfolios[fundPortfolio?.id]}>
            {percentage?.toFixed(DECIMAL_PRECISION)}%
          </Percentage>
          <Company>
            <Name>{fundPortfolio?.name}</Name>
          </Company>
        </TooltipContent>
      </TooltipContainer>
    )
  }
  return null
}

export default FundPortfoliosPieChartTooltip
