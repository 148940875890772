import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Wrapper = styled.div`
  max-height: 30rem;
`

export const FinancialInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const FinancialInfo = styled.div`
  :not(:last-child) {
    border-bottom: 0.1rem solid ${color('veryLightGray')};
  }

  padding-bottom: 1rem;
`

export const VisibleContent = styled.span`
  font-size: 1.4rem;
  color: ${color('darkGray')};
`

export const Title = styled.div`
  color: ${color('lightGray')};
  font-weight: 700;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
  margin-bottom: 0.4rem;
`
