import { useMemo } from 'react'
import { useInfiniteQuery, useMutation } from '@tanstack/react-query'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'

import Toast from 'components/Toast'
import EmailService from 'api/EmailService'
import { emailUpdateKeys } from 'utils/queries/emailUpdates'
import { EmailIndexUpdate } from '../../types/update'

const PAGE_SIZE = 25

export interface UseEmailUpdatesInfiniteQuery {
  companyId: string
  draftEmails: boolean
  queryOptions?: UseInfiniteQueryOptions<{
    data: EmailIndexUpdate[]
    totalResults: number
    page: number
  }>
  setScrollEnabled: (value: boolean) => void
}

export const useEmailUpdatesInfiniteQuery = ({
  companyId,
  draftEmails,
  queryOptions = {},
  setScrollEnabled,
}: UseEmailUpdatesInfiniteQuery) => {
  const query = useInfiniteQuery(
    emailUpdateKeys.all(draftEmails),
    async ({ pageParam = 1 }) => {
      const response = await EmailService.getEmails(
        companyId,
        draftEmails,
        pageParam,
        PAGE_SIZE
      )

      if (response.results.length < PAGE_SIZE) {
        setScrollEnabled(false)
      }

      return {
        data: response.results,
        totalResults: response.total,
        page: pageParam,
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data.length === 0) return lastPage.page
        return (lastPage?.page ?? 0) + 1
      },
      ...queryOptions,
    }
  )

  const emails = useMemo(
    () => query.data?.pages.flatMap((page) => page.data) ?? [],
    [query.data]
  )

  const totalResults = query.data?.pages?.[0].totalResults ?? 0

  return {
    ...query,
    totalResults,
    emails,
  }
}

export const useRemoveEmailDraftsMutation = (groupCompanyId: string) => {
  return useMutation(
    (updates: EmailIndexUpdate[]) => {
      const selectedDraftsIds = updates.map((email) => email.item.id)
      return EmailService.deleteDrafts(groupCompanyId, selectedDraftsIds)
    },
    {
      onError: () => {
        Toast.displayIntl('emailList.errorDeletingDrafts', 'error')
      },
    }
  )
}
