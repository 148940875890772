import theme from 'utils/theme'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonWrapper } from '../UpdateActions.styles'
import TooltipActionWrapper from '../Components/TooltipActionWrapper/TooltipActionWrapper'

interface Props {
  handleDuplicateUpdate: (value: boolean) => void
}

const DuplicateUpdateAction = ({ handleDuplicateUpdate }: Props) => {
  const intl = useIntl()

  return (
    <TooltipActionWrapper
      id="duplicate-email-update-action"
      text={intl.formatMessage({ id: 'updateActions.duplicateUpdate' })}
    >
      <ButtonWrapper transparent onClick={handleDuplicateUpdate}>
        <FontAwesomeIcon
          color={theme.colors.darkBlue}
          icon={['fal', 'clone']}
        />
      </ButtonWrapper>
    </TooltipActionWrapper>
  )
}

export default DuplicateUpdateAction
