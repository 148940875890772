import Checkbox from 'components/Checkbox'
import { FIELD_NAMES } from 'containers/UpdatesV2/Accounting/constants/constants'
import { AccountingReportType } from 'utils/constants/accounting'
import { useIntl } from 'react-intl'
import { FOUNDER_UPDATES } from 'utils/constants/links'
import { CheckboxLegend } from '../../ReportFilters.styles'

interface StandardLayoutProps {
  reportType: AccountingReportType
  disabled: boolean
}
const StandardLayout = ({ reportType, disabled }: StandardLayoutProps) => {
  const intl = useIntl()

  if (
    reportType === AccountingReportType.XERO_PROFIT_AND_LOSS ||
    reportType === AccountingReportType.XERO_BALANCE_SHEET
  )
    return (
      <div>
        <Checkbox
          id={FIELD_NAMES.standardLayout}
          name={FIELD_NAMES.standardLayout}
          label={intl.formatMessage({ id: 'general.standardLayout' })}
          disabled={disabled}
        />
        <CheckboxLegend>
          {intl.formatMessage({ id: 'general.standardLayoutDesc' })}
          <a href={FOUNDER_UPDATES} target="_blank" rel="noopener noreferrer">
            {intl.formatMessage({ id: 'general.moreInfo' })}
          </a>
        </CheckboxLegend>
      </div>
    )

  return null
}

export default StandardLayout
