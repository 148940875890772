import React from 'react'
import { ComposedChart as REComposedChart, ResponsiveContainer } from 'recharts'
import FadeIn from 'components/FadeIn'
import { useResizableComponent } from 'utils/hooks/useResizableComponent'
import { ReactComponent as Traveller } from 'assets/images/horizontal-traveller.svg'
import { Nullable } from 'utils/types/common'

import * as Styles from './ComposedChart.styles'

interface ChartPoint {
  date: Nullable<Date>
  quarter: string
  year: string
  [x: string]: number | string | Date | null
}

interface ComposedChartProps {
  data?: ChartPoint[]
  children: React.ReactNode
  isResizable?: boolean
}

const ComposedChart: React.FC<ComposedChartProps> = ({
  data,
  children,
  isResizable = false,
}) => {
  const { height, ref, handleMouseDown } = useResizableComponent({
    minHeight: 300,
    maxHeight: 530,
  })

  return (
    <FadeIn>
      <Styles.ComposedChart ref={ref}>
        <ResponsiveContainer width="100%" height={height}>
          <REComposedChart
            data={data}
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            {children}
          </REComposedChart>
        </ResponsiveContainer>
      </Styles.ComposedChart>
      {isResizable && (
        <Styles.Traveller onMouseDown={handleMouseDown}>
          <Traveller />
        </Styles.Traveller>
      )}
    </FadeIn>
  )
}

export default ComposedChart
