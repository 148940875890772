import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { Color } from 'utils/theme'

export const ModalHeader = styled.div<{
  isSecondary?: boolean
  backgroundColor?: Color
}>`
  align-items: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? color(backgroundColor) : color('white')};
  color: ${({ isSecondary }) =>
    isSecondary ? color('white') : color('darkBlue')};
  border-bottom: 0.1rem solid var(--color-light-gray-secondary);
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  display: flex;
  justify-content: space-between;
  padding: 2rem 2.4rem;
  height: 5.8rem;
  font-size: 1.8rem;

  > svg {
    cursor: pointer;
    font-size: 2rem;
  }
`

export const CloseButton = styled.div<{ isSecondary?: boolean }>`
  align-items: center;
  background-color: ${({ isSecondary }) =>
    isSecondary ? 'transparent' : 'rgba(201, 207, 228, 0.4)'};
  border-radius: 50%;
  color: color('darkBlue');
  cursor: pointer;
  display: flex;
  font-size: 0.4rem;
  justify-content: center;
  height: 3.2rem;
  width: 3.2rem;
  min-height: 3.2rem;
  min-width: 3.2rem;
`

export const ModalTitle = styled.h1<{ isSecondary?: boolean }>`
  color: ${({ isSecondary }) =>
    isSecondary ? color('white') : color('darkBlue')};
  font-family: 'Lato';
  font-size: ${({ isSecondary }) => (isSecondary ? '1.8rem' : '1.4rem')};
  font-style: normal;
  font-weight: ${({ isSecondary }) => (isSecondary ? '400' : '700')};
  margin: 0;
  text-transform: ${({ isSecondary }) => (!isSecondary ? 'uppercase' : 'none')};
  user-select: none;
`

export const ModalBody = styled.div<{
  padding?: string
  withOverflow?: boolean
}>`
  padding: ${({ padding }) => padding || '2.4rem'};
  ${(props) =>
    props.withOverflow &&
    css`
      overflow-x: auto;
    `}
`

export const ModalFooter = styled.div`
  display: flex;
  padding: 2.4rem 3.2rem;
  border-top: 1px solid ${color('borderGray')};
`

export const RowFooter = styled(ModalFooter)`
  border-top: 0.1rem solid ${color('borderGray')};
  justify-content: flex-end;
  gap: 0.8rem;

  @media ${maxSize.xsm} {
    padding: 1.2rem;
  }
`
