import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import Toast from 'components/Toast'
import useQueryString from 'utils/hooks/useQueryString'
import { scope } from 'utils/constants/auth0'
import { setAuth0AccessToken, setAuth0IdToken } from 'features/authSlice'
import CWLoader from 'components/CWLoader'
import { useSignIn } from '../SignIn/useSignIn'
import * as Styles from './SignInHandler.styles'

const SignInHandler = () => {
  const { user, getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const { signInAuth0 } = useSignIn()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const error = useQueryString('error')
  const errorDescription = useQueryString('error_description')

  const [idToken, setIdToken] = useState<string>('')
  const [accessToken, setAccessToken] = useState<string>('')
  useEffect(() => {
    if (error) return

    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope,
        },
      })
      setAccessToken(token)
    }
    fetchAccessToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (error) return

    const fetchIdToken = async () => {
      const claims = await getIdTokenClaims()
      if (claims) {
        setIdToken(claims.__raw)
      }
    }
    fetchIdToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, accessToken])

  const onSignIn = async () => {
    try {
      if (!user?.email_verified) {
        dispatch(setAuth0IdToken(idToken))
        dispatch(setAuth0AccessToken(accessToken))
        history.replace('/signup/almost-done')
        return
      }

      const { shouldContinueSignup } = await signInAuth0({
        idToken,
        accessToken,
      })

      if (shouldContinueSignup) {
        history.push('/welcome')
      } else {
        history.push('/')
      }
    } catch (e) {
      Toast.displayIntl(e.message, 'error')
    }
  }

  useEffect(() => {
    if (user && accessToken && idToken) {
      onSignIn()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, accessToken, idToken])

  useEffect(() => {
    if (error && errorDescription) {
      Toast.displayIntl(errorDescription, 'error')
      history.push('/sign-in')
    }
  }, [error, errorDescription, history])

  return (
    <Styles.Container>
      <CWLoader />
    </Styles.Container>
  )
}

export default SignInHandler
