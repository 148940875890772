export const DefaultImage = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="image 6">
      <path
        id="Vector"
        d="M9.96875 1.84058H1.03125C0.461699 1.84058 0 2.30228 0 2.87183V9.05933C0 9.62888 0.461699 10.0906 1.03125 10.0906H9.96875C10.5383 10.0906 11 9.62888 11 9.05933V2.87183C11 2.30228 10.5383 1.84058 9.96875 1.84058ZM9.83984 9.05933H1.16016C1.12597 9.05933 1.09318 9.04574 1.06901 9.02157C1.04483 8.9974 1.03125 8.96461 1.03125 8.93042V3.00073C1.03125 2.96654 1.04483 2.93376 1.06901 2.90958C1.09318 2.88541 1.12597 2.87183 1.16016 2.87183H9.83984C9.87403 2.87183 9.90682 2.88541 9.93099 2.90958C9.95517 2.93376 9.96875 2.96654 9.96875 3.00073V8.93042C9.96875 8.96461 9.95517 8.9974 9.93099 9.02157C9.90682 9.04574 9.87403 9.05933 9.83984 9.05933ZM2.75 3.7312C2.27539 3.7312 1.89062 4.11596 1.89062 4.59058C1.89062 5.06519 2.27539 5.44995 2.75 5.44995C3.22461 5.44995 3.60938 5.06519 3.60938 4.59058C3.60938 4.11596 3.22461 3.7312 2.75 3.7312ZM2.0625 8.02808H8.9375V6.30933L7.05729 4.42912C6.95662 4.32845 6.79338 4.32845 6.69268 4.42912L4.125 6.99683L3.27604 6.14787C3.17537 6.04719 3.01213 6.04719 2.91143 6.14787L2.0625 6.99683V8.02808Z"
        fill="#313748"
      />
    </g>
  </svg>
)
