import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import GroupService from 'api/GroupService'
import SubscriptionService from 'api/SubscriptionService'
import Toast from 'components/Toast'
import {
  getBillingAccount,
  getBillingPeriod,
  getIsTrial,
  getPlanId,
  getSubscription,
  resetCreateNewGroup,
  setBillingAccount,
  setBillingPeriod,
  setGroupType,
  setPlanId,
  setSubscription,
} from 'features/createNewGroupSlice'
import { getUser } from 'selectors/auth'
import { GroupTypes } from 'utils/constants/groups'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { BillingAccount, GroupData, Plan } from 'utils/types/signUp'
import {
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import { BillingAccountFormValues } from '../GetStarted'
import { useGroupSetup } from '../GroupSetup/useGroupSetup'
import {
  SignupContext,
  SignupContextProps,
  getGroupTypeFromPlanId,
} from './SignupContext'

export const CREATE_NEW_GROUP_URL_PREFIX = '/create-group'

export const CreateNewGroupProvider: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { createGroup } = useGroupSetup()
  const [groupName, setGroupName] = useState<string>('')
  const [paymentError, setPaymentError] = useState<string>('')
  const [isUpdatingUserInfo, _setIsUpdatingUserInfo] = useState(false)
  const [isLoadingChangeBillingPeriod, setIsLoadingChangeBillingPeriod] =
    useState(false)
  const planId = useAppSelector(getPlanId)
  const isTrial = useAppSelector(getIsTrial)
  const billingPeriod = useAppSelector(getBillingPeriod)
  const billingAccount = useAppSelector(getBillingAccount)
  const user = useAppSelector(getUser)
  const { clientSecret, subscriptionId } = useAppSelector(getSubscription) ?? {}

  const onPlanSelected = useCallback(
    (selectedPlan: Plan, selectedBillingPeriod: SubscriptionBillingPeriod) => {
      if (selectedPlan.id !== SubscriptionPlan.ORGANIZATION) {
        dispatch(setGroupType(getGroupTypeFromPlanId(selectedPlan.id)))
        dispatch(setPlanId(selectedPlan.id))
        dispatch(setBillingPeriod(selectedBillingPeriod))
        history.push(`${CREATE_NEW_GROUP_URL_PREFIX}/group-setup`)
      }
    },
    [history, dispatch]
  )

  const onCreateGroup = useCallback(
    async (values: GroupData) => {
      setGroupName(values.groupName)
      history.push(`${CREATE_NEW_GROUP_URL_PREFIX}/billing`)
    },
    [history]
  )

  const createSubscription = useCallback(
    async (billingAccountValues: BillingAccount) => {
      const subscription = await SubscriptionService.createSubscription(
        billingAccountValues
      )

      dispatch(
        setSubscription({
          clientSecret: subscription.stripeClientSecret,
          customerId: subscription.stripeCustomerId,
          subscriptionId: subscription.stripeSubscriptionId,
        })
      )
    },
    [dispatch]
  )

  const onClickContinueToPayment = useCallback(
    async (values: BillingAccountFormValues) => {
      try {
        const accountValues: BillingAccount = {
          ...values,
          address: {
            ...values.address,
            country: values.country,
            address: values.address?.street,
            postalCode: values.address?.zip,
          },
          subscription: {
            plan: planId!,
            trial: isTrial!,
            billingPeriod: billingPeriod!,
          },
        }

        dispatch(setBillingAccount(accountValues))
        await createSubscription(accountValues)
        history.replace(`${CREATE_NEW_GROUP_URL_PREFIX}/payment`)
      } catch {
        Toast.displayIntl('signup.payment.createPaymentIntentError', 'error')
      }
    },
    [billingPeriod, createSubscription, dispatch, isTrial, planId, history]
  )

  const onPaymentSuccess = useCallback(
    async (successfulPaymentIntentId: string) => {
      SubscriptionService.confirmPayment(user.id, successfulPaymentIntentId)
      createGroup({
        onCreateNewGroup:
          getGroupTypeFromPlanId(planId!) === GroupTypes.FUND_MANAGER
            ? GroupService.createFundManagerGroup
            : GroupService.createClientGroup,
        groupName,
      })
    },
    [user.id, createGroup, planId, groupName]
  )

  const goBackToPayment = useCallback(
    (stripeClientSecret: string, error: string) => {
      setPaymentError(error)
      dispatch(setSubscription({ clientSecret: stripeClientSecret }))
      history.replace(`${CREATE_NEW_GROUP_URL_PREFIX}/payment`)
    },
    [history, dispatch]
  )

  const onClickFounderSetup = useCallback(() => {
    dispatch(setGroupType(GroupTypes.FOUNDER))
    history.push(`${CREATE_NEW_GROUP_URL_PREFIX}/founder-setup`)
  }, [dispatch, history])

  const onCancel = useCallback(() => {
    history.replace('/')
    dispatch(resetCreateNewGroup())
  }, [dispatch, history])

  const onChangeBillingPeriod = useCallback(async () => {
    const accountValues: BillingAccount = {
      ...billingAccount!,
      subscription: {
        plan: planId!,
        trial: isTrial!,
        billingPeriod:
          billingAccount?.subscription.billingPeriod ===
          SubscriptionBillingPeriod.MONTHLY
            ? SubscriptionBillingPeriod.ANNUALLY
            : SubscriptionBillingPeriod.MONTHLY,
      },
    }
    setIsLoadingChangeBillingPeriod(true)
    dispatch(setBillingAccount(accountValues))
    await createSubscription(accountValues)
    setIsLoadingChangeBillingPeriod(false)
  }, [billingAccount, createSubscription, dispatch, isTrial, planId])

  const value: SignupContextProps = useMemo(
    () => ({
      urlPrefix: CREATE_NEW_GROUP_URL_PREFIX,
      isCreatingNewGroup: true,
      isUpdatingUserInfo,
      onSetupAccount: () => Promise.resolve(),
      onCancel,
      onClickFounderSetup,
      investorSignup: {
        name: user.name,
        email: user.email,
        isTrial,
        billingAccount,
        planId,
        subscriptionId,
        clientSecret,
        paymentError,
        onPlanSelected,
        onCreateGroup,
        onClickContinueToPayment,
        onPaymentSuccess,
        goBackToPayment,
        onContinueForUserAddedFromGroupManagement: async () => {},
        onChangeBillingPeriod,
        isLoadingChangeBillingPeriod,
      },
    }),
    [
      isUpdatingUserInfo,
      onCancel,
      user.name,
      user.email,
      isTrial,
      billingAccount,
      planId,
      subscriptionId,
      clientSecret,
      paymentError,
      onPlanSelected,
      onCreateGroup,
      onClickContinueToPayment,
      onPaymentSuccess,
      goBackToPayment,
      onChangeBillingPeriod,
      isLoadingChangeBillingPeriod,
      onClickFounderSetup,
    ]
  )

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  )
}
