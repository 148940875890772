import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Channel } from 'stream-chat'
import CreateChatModal from 'containers/Chat/components/CreateChat'
import { StreamChatType, TopicChannel } from 'containers/Chat/types'
import { TopicEntity } from 'utils/types/chatTopicEntity'
import { Update } from 'utils/types/update'
import SuggestedMessageBox from '../SuggestedMessageBox'
import { useCreateNewChatSuggestion } from './useCreateNewChatSuggestion'

type Props = {
  topicEntity?: TopicEntity
  existentChannel: Channel<StreamChatType<TopicChannel>>
  update?: Update
  textIntlKey?: string
}

const ExistentChatSuggestion: React.FC<Props> = ({
  topicEntity,
  update,
  existentChannel,
  textIntlKey,
}) => {
  const {
    onClose,
    onCreateNewChat,
    onGoToChannel,
    shouldHide,
    showCreateChatModal,
    onHideCreateChatModal,
    members,
    messageText,
    dismissSuggestion,
  } = useCreateNewChatSuggestion(topicEntity)

  if (shouldHide) {
    return null
  }

  return (
    <>
      <SuggestedMessageBox
        text={
          <FormattedMessage
            id={textIntlKey || 'chat.alreadyExistentChatProfile'}
          />
        }
        onConfirm={() => onGoToChannel(existentChannel)}
        onCancel={onCreateNewChat}
        onClose={onClose}
        confirmLabelIntlKey="chat.goToExisting"
        cancelLabelIntlKey="chat.createNewChat"
      />
      <CreateChatModal
        show={showCreateChatModal}
        onHide={onHideCreateChatModal}
        initialValues={{
          members,
          topic: topicEntity,
          update,
        }}
        initialMessage={messageText}
        onChatCreated={dismissSuggestion}
      />
    </>
  )
}

export default ExistentChatSuggestion
