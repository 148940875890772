import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const StyledRow = styled.div`
  display: grid;
  gap: 5rem;

  .investor-socials {
    margin-top: 0;

    button {
      height: 38px;
      width: 38px;
    }

    h4 {
      color: ${color('lightGray')};
      font-size: 1.4rem;
      line-height: 16.8px;
      text-transform: uppercase;
    }
  }

  @media ${maxSize.lg} {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
  }
`
