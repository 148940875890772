import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import {
  StyledImageContainer,
  StyledImagePreview,
  AttachmentInfo,
} from './ImagePreview.styles'

const ImagePreview = ({ src, title, onClick, ...rest }) => {
  const { updateId } = useParams<{ updateId?: string }>()

  return (
    <StyledImageContainer
      size={updateId && 'large'}
      className="fs-exclude"
      onClick={onClick}
    >
      <StyledImagePreview src={src} alt="attachment" {...rest} />
      <AttachmentInfo>{title}</AttachmentInfo>
    </StyledImageContainer>
  )
}

ImagePreview.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ImagePreview
