import styled, { css } from 'styled-components'
import { breakpoint, maxSize } from 'utils/constants/breakpoint'

export const BodyContainer = styled.div`
  display: grid;
  padding: 3.6rem 2.2rem;
  width: 100%;
  @media ${maxSize.lg} {
  }
  ${(props) =>
    props.border &&
    css`
      border-radius: 8px;
      border: solid 1px #d6d6d8;
      margin: 1.8rem 0;
    `}
`

export const CompanyTitle = styled.div`
  font-size: 2.8rem;
  font-weight: 500;
  color: #101527;
`

export const ReportHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${(props) => props.marginTop || '3.2rem'};
`

export const ReportDate = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  text-align: right;
  color: #787e8c;
`

export const ReportTitle = styled.div`
  font-size: 2.2rem;
  font-weight: 500;
  color: #3f5569;

  ${(props) =>
    props.light &&
    css`
      font-size: 1.6rem;
      color: #787e8c;
      text-transform: uppercase;
    `}
`

export const Line = styled.div`
  height: 0.1rem;
  margin: 1.8rem 0;
  border: solid 0.1rem #d6d7d8;
`

export const ReportTable = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(${(props) => props.periods}, 1fr);
`

export const ReportCell = styled.div`
  display: flex;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
`

export const ReportColumn = styled.div`
  min-width: 9rem;
  padding-right: 0.5rem;
  ${(props) =>
    props.borderRight &&
    css`
      border-right: 0.1rem solid #d6d7d8;
    `}
`

export const ReportSectionTitle = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin: ${(props) =>
    props.isMainSection ? '1.7rem 0 3rem 0' : ' 0 0 .8rem 0'};
  color: ${(props) => (props.isMainSection ? '#101527' : '#3f5569')};
  height: 1.9rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ReportSectionSubTitle = styled.div`
  font-size: 1.6rem;
  height: 2.8rem;
  margin-left: 1.5rem;
  font-weight: ${(props) =>
    props.rowType === 'SummaryRow' ? 'bold' : 'normal'};
  color: ${(props) => (props.rowType === 'SummaryRow' ? '#1c4373' : '#3f5569')};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) =>
    props.level === 2 &&
    css`
      margin-left: 40px;
    `}
  ${(props) =>
    props.level === 3 &&
    css`
      margin-left: 60px;
    `}
  ${(props) =>
    props.level === 4 &&
    css`
      margin-left: 60px;
      font-size: 14px;
    `}
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}

    ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `};
`

export const ReportValue = styled.div`
  font-size: 1.6rem;
  color: #3f5569;
  text-align: ${(props) => props.align || 'left'};
  width: 100%;
  padding-right: 1rem;
  height: 2.8rem;
  ${(props) =>
    props.isSummary &&
    css`
      padding-bottom: 1.5rem;
    `}
`

export const ReportSummaryValue = styled.div`
  font-size: 1.6rem;
  color: #3f5569;
  text-align: ${(props) => props.align || 'left'};
  width: 100%;
  padding-right: 1rem;
  margin: 1.7rem 0 2rem 0;
  ${(props) =>
    props.isSummary &&
    css`
      padding-bottom: 1.5rem;
    `}
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ReportValueTotals = styled(ReportValue)`
  padding: 0.5rem 2rem 0.5rem 0;
`

export const ReportItemsContainer = styled.div`
  margin-top: '2rem';
`

export const TableHeader = styled.div`
  font-size: 1.4rem;
  color: #787e8c;
  height: 1.7rem;
  margin: 1.7rem 0;
  text-align: center;
  padding: 0 1rem;

  ${(props) =>
    props.right &&
    css`
      text-align: right;
    `}
`

export const ReportContent = styled.div`
  overflow: auto;
  position: relative;
`

export const ReportSummary = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: ${(props) => props.marginLeft || '4rem'};
  margin-bottom: 1.5rem;
  p,
  span {
    font-size: 1.6rem;
    color: #3f5569;
    font-weight: bold;
  }
`

export const ReportInlineValue = styled.span`
  color: #3f5569;
  font-size: 1.6rem;
`

export const ReportRowValues = styled.div`
  display: grid;
  grid-template-columns: 29rem repeat(${(props) => props.periods}, 9rem);
  width: 99%;

  ${(props) =>
    props.separatorRow &&
    css`
      height: 100%;
      position: absolute;
      left: 1px;
    `}
`

export const BorderCell = styled.div`
  border-right: solid 1px #d6d6d8;
`
