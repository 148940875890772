import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import { Toast } from 'components/ToastMessage/ToastMessage.styles'
import { FileExtension } from 'utils/types/files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div<{
  fromSuggestedUpdate?: boolean
}>`
  ${Toast} {
    max-width: fit-content;
    margin-top: 1.2rem;
    padding: 1.2rem;
    margin-bottom: ${({ fromSuggestedUpdate }) =>
      fromSuggestedUpdate ? '-0.8rem' : 0};
  }
`

export const RelatedFilesText = styled.span`
  font-weight: 700;
  text-decoration: underline;
  color: ${color('primaryBlue')};
`

export const FileRow = styled.div<{ fileType?: FileExtension }>`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  white-space: nowrap;

  svg {
    font-size: ${({ fileType }) =>
      fileType === FileExtension.IMAGE ? '1.2rem' : '2rem'};
  }

  div {
    width: 2rem;
    height: 2rem;
  }
`

export const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FilesTooltip = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  color: ${color('darkGray')};
  font-weight: 700;
  background-color: ${color('white')};
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
  padding: 1.2rem 1.6rem;
  gap: 1.2rem;
  margin-top: -1.5rem;
  width: 16.2rem;
`

export const DisclaimerMessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const CloseDisclaimerIcon = styled.div`
  margin-left: 1.5rem;
  cursor: pointer;
`

export const TitleSeparator = styled.hr`
  color: ${color('veryLightGray')};
  margin-top: -0.2rem;
`

export const EmailIcon = styled(FontAwesomeIcon)`
  width: 2rem;
  color: ${color('lightGray')};

  && {
    font-size: 1.7rem;
  }
`
