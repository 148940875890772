import styled from 'styled-components'

export const PhoneInputContainer = styled.div<{
  large?: boolean
}>`
  display: flex;
  flex-direction: column;
  position: relative;

  * {
    font-family: 'Lato', sans-serif;
  }

  input {
    max-width: 40rem;
    padding-left: ${({ large }) => (large ? 8.3 : 6.1)}rem;
  }
`
