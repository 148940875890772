import { MailboxType } from 'components/EmailList/useEmailList'
import { useSelectUpdatesContext } from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import Button from 'ui/Button'
import { EmailIndexUpdate } from 'utils/types/update'
import { ButtonsContainer } from './SelectionButtons.styles'

type Props = {
  updates: EmailIndexUpdate[]
  currentMailboxType: MailboxType
  onOpenDeleteDraftsConfirmationModal: (
    selectedUpdates: EmailIndexUpdate[],
    callback: () => void
  ) => void
  isDeletingDrafts?: boolean
}

const SelectionButtons = ({
  updates,
  currentMailboxType,
  onOpenDeleteDraftsConfirmationModal,
  isDeletingDrafts,
}: Props) => {
  const { selectedCards, selectAllUpdates, unSelectAllUpdates } =
    useSelectUpdatesContext()

  useEffect(() => {
    unSelectAllUpdates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMailboxType])

  const handleDeleteDrafts = async (selectedUpdates: EmailIndexUpdate[]) => {
    onOpenDeleteDraftsConfirmationModal(selectedUpdates, unSelectAllUpdates)
  }
  const selectedUpdates = Object.values(selectedCards) as EmailIndexUpdate[]
  const selectionMode =
    (currentMailboxType === MailboxType.DRAFT ||
      currentMailboxType === MailboxType.SCHEDULE) &&
    selectedUpdates.length > 0

  const areAllDraftsSelected = updates.length === selectedUpdates.length

  if (selectionMode) {
    return (
      <ButtonsContainer>
        <Button
          secondary
          onClick={() =>
            areAllDraftsSelected
              ? unSelectAllUpdates()
              : selectAllUpdates(updates)
          }
          disabled={isDeletingDrafts}
          uppercase
        >
          {areAllDraftsSelected ? (
            <FormattedMessage id="emailList.deselectAllDrafts" />
          ) : (
            <FormattedMessage id="emailList.selectAllDrafts" />
          )}
        </Button>
        <Button
          danger
          uppercase
          onClick={() => handleDeleteDrafts(selectedUpdates)}
          disabled={isDeletingDrafts}
        >
          <FormattedMessage
            id="emailList.deleteDrafts"
            values={{
              count: selectedUpdates.length,
            }}
          />
        </Button>
      </ButtonsContainer>
    )
  }
  return null
}

export default SelectionButtons
