import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { motion } from 'framer-motion'

export const WhiteBackground = styled(motion.header)`
  height: 100vh;
  background: ${color('cloud')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LowerRightLogo = styled.div`
  > svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`

export const TopLeftLogo = styled.div`
  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
  }

  .st0 {
    opacity: 0.1;
    clip-path: url(#SVGID_2_);
  }
  .st1 {
    fill: none;
    stroke: #101527;
    stroke-width: 1.6975;
  }
  .st2 {
    opacity: 0.4;
    fill: #101527;
    enable-background: new;
  }
  .st3 {
    opacity: 0.4;
  }
  .st4 {
    opacity: 0.4;
    fill: #101527;
    stroke: #101527;
    stroke-width: 1.6975;
    enable-background: new;
  }
  .st5 {
    opacity: 0.1;
    fill: none;
    stroke: #101527;
    stroke-width: 1.6975;
  }
`

export const StyledClockworkLogo = styled(motion.img)`
  width: 35rem;
  margin-top: -5vh;
`

export const WelcomeText = styled(motion.h1)`
  font-size: 3.2rem;
  font-weight: 400;
  color: ${color('black')};
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%) !important;
`
