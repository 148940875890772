import { useFormik } from 'formik'
import { useEffect, useRef } from 'react'
import type { ReCAPTCHA } from 'react-google-recaptcha'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { getGroupType } from 'features/signUpSlice'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUserInformationSchema } from 'utils/schemas/investorSignup'
import { SetupAccount } from 'utils/types/signUp'
import { getUser } from 'selectors/auth'

interface SetupAccountProps {
  onClickContinue: (data: SetupAccount) => void
}

const useSetupAccount = ({ onClickContinue }: SetupAccountProps) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const intl = useIntl()
  const groupType = useAppSelector(getGroupType)!
  const user = useAppSelector(getUser)

  const formik = useFormik<SetupAccount>({
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
    },
    validationSchema: getUserInformationSchema(intl),
    validateOnBlur: true,
    onSubmit: (values) => {
      onClickContinue({
        firstName: values.firstName,
        lastName: values.lastName,
      })
    },
  })

  useEffect(() => {
    document.getElementById('firstName')?.focus()
  }, [])

  return {
    history,
    dispatch,
    formik,
    intl,
    recaptchaRef,
    groupType,
  }
}

export default useSetupAccount
