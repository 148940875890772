import React from 'react'
import { useIntl, FormattedNumber } from 'react-intl'

import CapitalStatementGrid from 'components/Portfolios/Grid'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'

interface Data {
  investorsTotalUnfundedCommitment?: number
  totalCapitalCalled?: number
  totalCommittedCapital?: number
  totalDistributions?: number
  proRataHoldingValue?: number
}

interface CapitalStatementRowProps {
  data: Data
  isFundPortfolioProfile?: boolean
}

const getFieldValue = (value?: number) => {
  if (!value && value !== 0) return null

  return (
    <FormattedNumber
      value={value}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      currency="USD"
      currencyDisplay="narrowSymbol"
      minimumFractionDigits={0}
      maximumFractionDigits={0}
    />
  )
}

const CapitalStatementRow: React.FC<CapitalStatementRowProps> = ({
  data,
  isFundPortfolioProfile,
}) => {
  const intl = useIntl()
  const { matches: isMobileAndSmallDesktop } = useMediaQuery(Media.MAX_XL)

  const FIELDS = [
    {
      label: intl.formatMessage({ id: 'fundDashboard.commitedCapital' }),
      tooltip: intl.formatMessage({
        id: 'fundDashboard.tooltips.commitedCapital',
      }),
      value: getFieldValue(data.totalCommittedCapital),
    },
    {
      label: intl.formatMessage({ id: 'fundDashboard.capitalCalled' }),
      tooltip: intl.formatMessage({
        id: 'fundDashboard.tooltips.capitalCalled',
      }),
      value: getFieldValue(data.totalCapitalCalled),
    },
    {
      label: intl.formatMessage({ id: 'fundDashboard.unfundedCommitment' }),
      tooltip: intl.formatMessage({
        id: 'fundDashboard.tooltips.unfundedCommitment',
      }),
      value: getFieldValue(data.investorsTotalUnfundedCommitment),
    },
    {
      label: intl.formatMessage({ id: 'fundDashboard.distributions' }),
      tooltip: intl.formatMessage({
        id: 'fundDashboard.tooltips.distributions',
      }),
      value: getFieldValue(data.totalDistributions),
    },
    {
      label: intl.formatMessage({ id: 'fundDashboard.proRataHoldingValue' }),
      tooltip: intl.formatMessage({
        id: 'fundDashboard.tooltips.proRataHoldingValue',
      }),
      value: getFieldValue(data.proRataHoldingValue),
    },
  ].filter((field) => field.value)

  let columnsQuantity = FIELDS.length
  if (isFundPortfolioProfile || isMobileAndSmallDesktop) {
    columnsQuantity = 2
  }

  return (
    <CapitalStatementGrid columnsQuantity={columnsQuantity}>
      {FIELDS.map((field) => (
        <CapitalStatementGrid.Cell
          label={field.label}
          tooltip={field.tooltip}
          value={field.value}
        />
      ))}
    </CapitalStatementGrid>
  )
}

export default CapitalStatementRow
