import { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import type { Channel, MessageResponse } from 'stream-chat'

import Separator from 'ui/Separator'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import { StreamChatType } from 'containers/Chat/types'
import { useSetActiveChannel } from 'containers/Chat/hooks'

import { useChatSearchContext } from '../../hooks/useChatSearch'
import SearchMessagesResults from './components/SearchMessagesResults'
import SearchChannelsResults from './components/SearchChannelsResults'
import * as Styles from './ChatSearch.styles'

interface Props {
  hideSearchView: () => void
}

const ChatSearch = ({ hideSearchView }: Props) => {
  const intl = useIntl()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const searchHandle = useChatSearchContext()
  const setActiveChannel = useSetActiveChannel()

  const {
    search,
    parsedSearchTexts,
    messagesResults,
    isLoadingMessages,
    channelResults,
    isLoadingChannels,
    participantResults,
    isLoadingParticipants,
  } = searchHandle

  const onSelectChannel = (channel: Channel<StreamChatType>) => {
    hideSearchView()
    setActiveChannel(channel)
  }

  const onSelectMessage = async (
    message: MessageResponse<StreamChatType>,
    channel: Channel<StreamChatType>
  ) => {
    hideSearchView()
    setActiveChannel(channel, message.id)
  }

  const countResults = useMemo(() => {
    const messages = messagesResults.map((res) => res.values).flat().length
    const channels = channelResults.length
    const participants = participantResults.length

    return {
      messages,
      channels,
      participants,
      total: messages + channels + participants,
    }
  }, [channelResults, messagesResults, participantResults])

  useEffect(() => {
    const resultsPerIndex = [
      countResults.messages,
      countResults.channels,
      countResults.participants,
    ]

    if (resultsPerIndex[activeTabIndex] === 0) {
      const nextIndex = resultsPerIndex.findIndex((res) => res > 0)
      if (nextIndex > -1) {
        setActiveTabIndex(nextIndex)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countResults])

  const isLoading =
    isLoadingMessages || isLoadingChannels || isLoadingParticipants

  return (
    <Styles.ChatSearchContainer>
      {search ? (
        <Styles.SearchContent>
          {countResults.total === 0 && !isLoading ? (
            <Styles.ZeroStateContainer>
              <ZeroState
                textFontSize="1.6rem"
                titleFontSize="1.8rem"
                infoMarginTop="-0.7rem"
                type={ZeroStateType.CHAT_SEARCH_RESULTS}
                intlValues={{ search }}
              />
            </Styles.ZeroStateContainer>
          ) : (
            <>
              <Styles.SearchTitle>
                <FormattedMessage
                  id="chat.search.searchResults"
                  values={{ search }}
                />
              </Styles.SearchTitle>

              <Styles.Tabs
                items={[
                  {
                    id: 'MESSAGES',
                    title: intl.formatMessage(
                      { id: 'chat.search.messages' },
                      {
                        count: countResults.messages,
                      }
                    ),
                  },
                  {
                    id: 'CHATS',
                    title: intl.formatMessage(
                      { id: 'chat.search.chats' },
                      { count: countResults.channels }
                    ),
                  },
                  {
                    id: 'PARTICIPANTS',
                    title: intl.formatMessage(
                      { id: 'chat.search.participants' },
                      { count: countResults.participants }
                    ),
                  },
                ]}
                withBorder
                onClickTab={setActiveTabIndex}
                initialIndex={activeTabIndex}
                fullHeight
              >
                <Separator space="1.1rem" />

                <Styles.TabContent id="MESSAGES">
                  <SearchMessagesResults
                    messages={messagesResults}
                    isLoading={isLoadingMessages}
                    onSelectChannel={onSelectChannel}
                    onSelectMessage={onSelectMessage}
                    searchTexts={parsedSearchTexts}
                    plainSearch={search}
                  />
                </Styles.TabContent>
                <Styles.TabContent id="CHATS">
                  <SearchChannelsResults
                    channels={channelResults}
                    isLoading={isLoadingChannels}
                    onSelectChannel={onSelectChannel}
                    searchTexts={parsedSearchTexts}
                    plainSearch={search}
                  />
                </Styles.TabContent>

                <Styles.TabContent id="PARTICIPANTS">
                  <SearchChannelsResults
                    channels={participantResults}
                    isLoading={isLoadingParticipants}
                    onSelectChannel={onSelectChannel}
                    searchTexts={parsedSearchTexts}
                    plainSearch={search}
                  />
                </Styles.TabContent>
              </Styles.Tabs>
            </>
          )}
        </Styles.SearchContent>
      ) : (
        <Styles.ZeroStateContainer>
          <ZeroState
            type={ZeroStateType.CHAT_SEARCH}
            titleFontSize="1.8rem"
            infoMarginTop="0.1rem"
            hideText
          />
        </Styles.ZeroStateContainer>
      )}
    </Styles.ChatSearchContainer>
  )
}

export default ChatSearch
