import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color, hexToRGB } from 'utils/functions/colors'

import ButtonBase from './ButtonBase.styles'

export const StyledButton = styled(ButtonBase)`
  /* Default styles */
  color: ${color('darkBlue')};
  background-color: ${color('white')};
  border: 0.1rem solid ${color('veryLightBlue')};

  @media (max-width: 468px) {
    width: 100%;
  }

  &:hover:not(:disabled) {
    background-color: ${color('veryLightBlue', 0.4)};
  }

  &:focus {
  }
  &:active {
    box-shadow: inset 0.2rem 0.2rem 0.4rem 0 ${hexToRGB('#787E8C', 0.25)};
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${(props) =>
    props.isActive &&
    css`
      background: red !important;
    `}

  /* Primary styles */
  ${(props) =>
    props.primary &&
    css`
      color: ${color('white')};
      background-color: ${color('primaryBlue')};
      border: 0.1rem solid ${color('primaryBlue')};
      &:hover:not(:disabled) {
        background-color: ${color('primaryDarkBlue')};
        border-color: ${color('primaryDarkBlue')};
      }

      svg {
        margin-right: 0.9rem;
      }
    `}

  /* Success styles */
  ${(props) =>
    props.success &&
    css`
      color: ${color('white')};
      background-color: ${color('green')};
      border: 0.1rem solid ${color('green')};
      &:hover:not(:disabled) {
        background-color: ${color('darkGreen')};
        border-color: ${color('darkGreen')};
      }
    `}

  /* Danger styles */
  ${(props) =>
    props.danger &&
    css`
      color: ${color('white')};
      background-color: ${color('red')};
      border: 0.1rem solid ${color('red')};
      &:hover:not(:disabled) {
        background-color: ${color('darkRed')};
        border-color: ${color('darkRed')};
      }
    `}
  /* Danger styles */
  ${(props) =>
    props.secondaryDanger &&
    css`
      color: ${color('red')};
      background-color: ${color('white')};
    `}

    ${(props) =>
    props.transparent &&
    css`
      background-color: transparent;
    `}

  /* Warning styles */
  ${(props) =>
    props.warning &&
    css`
      color: ${color('white')};
      background-color: ${color('yellow')};
      border: 0.1rem solid ${color('yellow')};
      &:hover:not(:disabled) {
        background-color: ${color('darkYellow')};
        border-color: ${color('darkYellow')};
      }
    `}

  /* Uppercase styles */
  ${(props) =>
    props.uppercase &&
    css`
      text-transform: uppercase;
      font-size: 1.6rem;
      letter-spacing: 0.04em;
    `}

    /* Uppercase styles */
  ${(props) =>
    props.large &&
    css`
      font-size: 2.4rem;
      letter-spacing: 0.04em;
      padding: 1.6rem 2.4rem;
      font-weight: 400;
      height: 6.4rem;
    `}

    /* Active styles */
    ${(props) =>
    props.active &&
    css`
      background-color: ${color('veryLightBlue', 0.4)} !important;
      border: 1px solid ${color('primaryBlue')} !important;
      color: ${color('primaryBlue')} !important;
    `}

    /* Link styles */
    ${(props) =>
    props.link &&
    css`
      background-color: transparent;
      color: ${color('primaryBlue')};
      font-size: 1.4rem;
      font-weight: 700;
      border: none;
      border: 0.1rem solid transparent; // avoid changing the size when adding a border on focusing

      &:active {
        box-shadow: inset 4px 4px 4px rgba(120, 126, 140, 0.25);
      }

      &:disabled {
        color: ${color('lightGray')};
      }

      &:focus:not(:active) {
        color: ${color('darkBlue')};
        background-color: transparent;
        border: 1px solid ${color('darkBlue')};
      }

      &:hover:not(:disabled) {
        background-color: ${color('veryLightBlue', 0.4)};
        color: ${color('primaryBlue')};
      }
    `}


  /* Add icon styles */
  ${(props) =>
    (props.add || props.icon) &&
    !props.primary &&
    css`
      color: ${color('darkBlue')};
      > svg {
        margin-right: 0.9rem;
      }
    `}

    /* Link Button styles */
    ${(props) =>
    props.btnLink &&
    css`
      color: ${color('darkBlue')};
      font-size: 1.4rem;
      font-weight: 700;
      border: none;
      box-shadow: none;

      &:focus {
        box-shadow: inset 4px 4px 4px rgba(120, 126, 140, 0.25);
      }

      &:hover:not(:disabled) {
        background: ${color('veryLightBlue', '0.4')};
        color: ${color('primaryBlue')};
      }
    `}

    /* Secondary */
    ${(props) =>
    props.secondary &&
    css`
      color: ${color('darkBlue')};
      border: 0.1rem solid ${color('veryLightBlue')};
      font-weight: 700;
    `}

    /* Tertiary */
    ${(props) =>
    props.tertiary &&
    css`
      color: ${color('white')};
      border: 0.1rem solid ${color('green')};
      font-weight: 700;
      background-color: ${color('green')};
      &:hover:not(:disabled) {
        background-color: ${color('darkGreen')};
        border-color: ${color('darkGreen')};
      }
    `}
`

export const StyledSpinner = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
  animation: infinite-spinning 2s infinite ease-in-out;

  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`
