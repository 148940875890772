import styled from 'styled-components'

export const TooltipContainer = styled.div`
  width: ${(props) => props.width || '6.2rem'};
  height: ${(props) => props.height || '4.4rem'};
  background: #194590;
  border-radius: 0.8rem;
  padding: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-4rem);
`

export const YValue = styled.p`
  font-size: 1.4rem;
  line-height: 1.68rem;
  font-weight: 700;
  color: white;
  text-align: center;
`
export const XValue = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
  color: white;
`
