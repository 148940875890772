import Types from './types'

export const setEmailSubject = (emailSubject) => ({
  type: Types.SET_EMAIL_SUBJECT,
  payload: emailSubject,
})

export const setCurrentSelectedDrafts = (selectedDrafts) => ({
  type: Types.SET_CURRENT_SELECTED_DRAFTS,
  payload: selectedDrafts,
})
