import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { LARGE_SCREEN_BREAKPOINT_WIDTH } from 'utils/constants/breakpoints'

export const StyledSection = styled.section<{ width?: string }>`
  color: ${color('darkGray')};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  gap: 1.6rem;
  ${({ width }) => width && `width: ${width};`};

  &:empty {
    display: none;
  }

  h4 {
    color: ${color('lightGray')};
    font-size: 1.4rem;
    line-height: 16.8px;
    text-transform: uppercase;
  }
`

export const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4rem;

  @media (max-width: ${LARGE_SCREEN_BREAKPOINT_WIDTH}px) {
    flex-direction: column;
    gap: 1.2rem;
  }
`
