import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { InvestmentVehicleFormValues, Investor } from 'utils/types/investors'
import type { IntlShape } from 'react-intl'
import Toast from 'components/Toast'
import { ALREADY_TAKEN_ERROR } from 'utils/constants/errors'
import { useCreateInvestmentVehicleMutation } from 'utils/hooks/investorManagement/useInvestmentVehiclesQuery'
import { getInvestmentVehiclePayload } from '../../../utils/functions/investorManagement'

export type InvestmentVehicleErrors = {
  [key in keyof InvestmentVehicleFormValues]?: string
}

const useCreateInvestmentVehicle = (
  onCloseDrawer: () => void,
  intl: IntlShape
) => {
  const createInvestmentVehicleMutation = useCreateInvestmentVehicleMutation()
  const location = useLocation<{ investor?: Investor }>()
  const investor = location.state?.investor
  const history = useHistory()
  const [customError, setCustomError] = useState<InvestmentVehicleErrors>({
    name: '',
  })

  const createInvestmentVehicle = async (
    values: InvestmentVehicleFormValues
  ) => {
    const payload = getInvestmentVehiclePayload(values)

    createInvestmentVehicleMutation.mutate(payload, {
      onSuccess(newVehicle) {
        Toast.displayIntl([
          'investorManagement.success.createdInvestmentVehicle',
          { vehicleName: newVehicle.name },
        ])
        onCloseDrawer()
      },
      onError: (error: any) => {
        const errBody = JSON.parse(error.message)

        if (errBody.name.includes(ALREADY_TAKEN_ERROR)) {
          setCustomError((prev) => {
            return {
              ...prev,
              name: intl.formatMessage({
                id: 'investorManagement.vehicleErrors.vehicleNameTaken',
              }),
            }
          })
          return
        }

        Toast.displayIntl(
          'investorManagement.errors.createInvestmentVehicle',
          'error'
        )
      },
    })
  }

  const onCancel = () => {
    onCloseDrawer()
  }

  const onCreateNewInvestor = (investorName: string) => {
    history.replace('/investor-management/investors/create', {
      initialInvestorName: investorName,
    })
  }

  const initialData = {
    name: investor?.name ?? '',
    investor: investor ?? null,
    shareInvestorData: true,
    locations: [],
  }

  return {
    createInvestmentVehicle,
    onCancel,
    onCreateNewInvestor,
    initialData,
    customError,
  }
}

export default useCreateInvestmentVehicle
