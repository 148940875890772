import { useMemo } from 'react'
import { format as formatter } from 'date-fns'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nullable } from 'utils/types/common'
import theme from 'utils/theme'
import { useRandomId } from 'utils/hooks/useRandomId'
import { FullCellTooltip } from 'components/Spreadsheet/CellTemplates/Template.styles'
import {
  CloseIconContainer,
  DateFormatterWrapper,
  Label,
  StyledFontAwesomeIcon,
} from './DateFormatter.styles'

interface Props {
  date: Nullable<Date>
  format?: string
  isOptional?: boolean
  error?: string
  centered?: boolean
  onClear?: () => void
}

export const DateFormatter = ({
  date,
  format,
  isOptional,
  error,
  centered,
  onClear,
}: Props) => {
  const id = useRandomId()
  const intl = useIntl()
  const messageId = useMemo(
    () => (isOptional ? 'spreadsheet.optional' : 'metrics.update.selectDate'),
    [isOptional]
  )

  return (
    <FullCellTooltip
      id={id}
      text={error ? intl.formatMessage({ id: error }) : null}
      place="bottom"
      backgroundColor={theme.colors.red}
      offset={2}
    >
      <DateFormatterWrapper isFutureDate={!!error} centered={centered}>
        <StyledFontAwesomeIcon icon={['fal', 'calendar-day']} />
        <Label hasDate={!!date}>
          {date
            ? formatter(date, format ?? 'MMM d, uuuu')
            : intl.formatMessage({ id: messageId })}
        </Label>
        {!!date && (
          <CloseIconContainer onClick={onClear} centered={centered}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </CloseIconContainer>
        )}
      </DateFormatterWrapper>
    </FullCellTooltip>
  )
}
