import styled from 'styled-components/macro'
import { css } from 'styled-components'
import CWAvatar from 'components/Avatar'
import { color } from 'utils/functions/colors'
import ButtonToStyle from 'ui/Button'
import { maxSize } from 'utils/constants/breakpoint'

export const RowContainer = styled.div<{
  isPendingInvitation?: boolean
}>`
  font-size: 1.4rem;
  padding: 1.8rem;
  width: 100%;
  color: ${color('darkGray')};

  & + & {
    border-top: 1px solid ${color('veryLightGray')};
  }

  @media ${maxSize.lg} {
    ${({ isPendingInvitation }) =>
      isPendingInvitation &&
      css`
        padding: 1.6rem 1.8rem;
      `};
  }

  @media ${maxSize.sm} {
    ${({ isPendingInvitation }) =>
      !isPendingInvitation &&
      css`
        padding-bottom: 1.4rem;
      `}
  }
`

export const RowContent = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const Avatar = styled(CWAvatar)`
  width: 3.6rem;
  height: 3.6rem;
`

export const MessageContainer = styled.div`
  padding-top: 0.3rem;
`

export const GroupName = styled.span`
  font-weight: 700;
`

export const RequestDate = styled.div`
  font-size: 1.2rem;
  color: ${color('lightGray')};
  padding-top: 0.3rem;
`

export const RightContainer = styled.div<{
  largeMargin?: boolean
  isInvitationHistoryStatus?: boolean
}>`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-left: auto;

  ${({ largeMargin }) =>
    largeMargin &&
    css`
      margin-right: 3.4rem;
    `}

  ${({ isInvitationHistoryStatus }) =>
    isInvitationHistoryStatus &&
    css`
      align-self: flex-start;
    `}

  @media ${maxSize.lg} {
    margin-top: ${({ isInvitationHistoryStatus }) =>
      isInvitationHistoryStatus ? 0 : '1.6rem;'};
  }

  @media ${maxSize.xss} {
    ${({ isInvitationHistoryStatus }) =>
      isInvitationHistoryStatus &&
      css`
        margin-top: 0.8rem;
      `}

    margin-right: initial;
    flex-wrap: wrap;
  }

  @media ${maxSize.xxs} {
    ${({ isInvitationHistoryStatus }) =>
      !isInvitationHistoryStatus &&
      css`
        flex-direction: column;
      `}
  }
`

export const Status = styled.div`
  font-weight: 700;
  color: ${color('lightGray')};
  flex: 1;
  text-align: right;
`

export const Button = styled(ButtonToStyle)`
  height: 3.7rem;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;

  svg {
    font-size: 1.8rem;
  }

  @media ${maxSize.xsm} {
    width: initial;
  }

  @media ${maxSize.xxs} {
    width: 100%;
  }
`
