import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from 'components/Avatar'

import { PortfolioTypes } from 'utils/constants/portfolio'
import { getInitials } from 'utils/functions/user'

import { StyledPortfolioIcon } from './PortfolioIcon.styles'

const portfoliosIconConfig = {
  [`${PortfolioTypes.TRACK}`]: <FontAwesomeIcon icon={['far', 'binoculars']} />,
  [`${PortfolioTypes.INVEST}`]: (
    <FontAwesomeIcon icon={['far', 'dollar-sign']} />
  ),
  [`${PortfolioTypes.FUND}`]: <FontAwesomeIcon icon={['far', 'sack-dollar']} />,
  [`${PortfolioTypes.DEAL}`]: (
    <FontAwesomeIcon icon={['far', 'badge-dollar']} />
  ),
}

const PortfolioIcon = ({
  type,
  isActive,
  noBackground,
  updateFeedIcon,
  investorGroup,
  backgroundColor,
  className = undefined,
}) => {
  const portfolioLogo = investorGroup?.logo?.url

  return updateFeedIcon ? (
    <Avatar
      image={portfolioLogo}
      initials={getInitials(investorGroup?.name)}
      grayBorder
      avatarStyle="avatarForUpdateFeed"
    />
  ) : (
    <StyledPortfolioIcon
      isActive={isActive}
      noBackground={noBackground}
      backgroundColor={backgroundColor}
      className={className}
    >
      {portfoliosIconConfig[type]}
    </StyledPortfolioIcon>
  )
}

PortfolioIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(PortfolioTypes)).isRequired,
  isActive: PropTypes.bool,
  noBackground: PropTypes.bool,
  updateFeedIcon: PropTypes.bool,
  investorGroup: PropTypes.object,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
}

PortfolioIcon.defaultProps = {
  isActive: true,
  noBackground: false,
  backgroundColor: undefined,
}

export default PortfolioIcon
