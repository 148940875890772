import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import type { StripeElementsOptions } from '@stripe/stripe-js'

import { lgStripeAppearance } from 'utils/constants/stripe'
import useQueryString from 'utils/hooks/useQueryString'
import { stripePromise } from '../Payment/usePayment'
import PaymentChecker from './PaymentChecker'
import * as Styles from './PaymentCheckerStep.styles'

interface PaymentCheckStepProps {
  onPaymentSuccess: (paymentIntentId: string) => void
  goBackToPayment: (clientSecret: string, error: string) => void
}

const PaymentCheckStep: React.FC<PaymentCheckStepProps> = ({
  onPaymentSuccess,
  goBackToPayment,
}) => {
  const paymentIntentClientSecret = useQueryString(
    'payment_intent_client_secret'
  )
  const setupIntentClientSecret = useQueryString('setup_intent_client_secret')
  const clientSecret = (paymentIntentClientSecret || setupIntentClientSecret)!

  const options: StripeElementsOptions = {
    clientSecret,
    appearance: lgStripeAppearance,
  }

  return (
    <Styles.Container>
      <Elements options={options} stripe={stripePromise}>
        <PaymentChecker
          clientSecret={clientSecret}
          isPaymentIntent={!!paymentIntentClientSecret}
          onPaymentSuccess={onPaymentSuccess}
          goBackToPayment={goBackToPayment}
        />
      </Elements>
    </Styles.Container>
  )
}

export default PaymentCheckStep
