import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Contents = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const ShowMore = styled.li`
  align-items: center;
  background: ${color('softBlue', 0.9)};
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  color: ${color('lightGray')};
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: center;

  &:hover {
    background-color: ${color('veryLightBlue', 0.2)};
    color: ${color('primaryBlue')};
  }
`
