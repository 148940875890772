import { SET_BADGE } from 'utils/constants/electron'

let ipcRenderer

if (window.require) {
  ipcRenderer = window.require('electron').ipcRenderer
}

export const isDesktopApp = () => {
  if (
    typeof window !== 'undefined' &&
    typeof window.process === 'object' &&
    (window.process as any).type === 'renderer'
  ) {
    return true
  }

  return false
}

export const setDesktopBadge = (value?: number) => {
  ipcRenderer?.send(SET_BADGE, { value: value ?? 0 })
}

export const isMacApp = () => {
  return window.process?.platform === 'darwin'
}

export const sendNotification = (
  id: string,
  title: string,
  body: string,
  onClick: () => void
) => {
  ipcRenderer?.send('SEND_NOTIFICATION', {
    id,
    title,
    body,
  })
  ipcRenderer?.once(`NOTIFICATION_CLICKED_${id}`, onClick)
}

export const openUrl = (url: string) => {
  ipcRenderer?.send('OPEN_URL', { url })
}
