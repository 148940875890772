import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { TextCellValue } from 'components/Spreadsheet/CellTemplates/Template.styles'
import { color } from 'utils/functions/colors'
import Button from 'ui/Button/Button'

export const WebsiteCellValue = styled(TextCellValue)<{
  hasError?: boolean
  autoFilled?: boolean
  isPlaceholder?: boolean
}>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.8rem;
  color: ${color('darkGray')};

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${color('red')};
    `}

  ${({ autoFilled }) =>
    autoFilled &&
    css`
      background-color: ${color('lightGray', 0.1)};
      color: ${color('lightGray')};
      border-left: 1px solid ${color('white')};
    `}

    ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${color('lightGray', 0.8)};
    `}
`

export const HoldingProfileSuggestion = {
  Container: styled.div`
    padding-top: 0.8rem;
  `,
  Content: styled.div`
    display: flex;
    align-items: center;
    gap: 0.8rem;
  `,
  ManagerName: styled.div`
    font-weight: 400;
    text-align: left;
    color: ${color('lightGray')};
  `,
  Button: styled(Button)`
    width: 100%;
    padding: 0.6rem;
  `,
}
