/* eslint-disable max-classes-per-file */
import { UpdateTypes } from 'utils/constants/updates'
import { Log } from '../types'

import {
  UpdateLogChangeSetFields,
  UpdateLogParams,
  UpdateLogsBuilder,
} from './UpdateLogsBuilder'

export class AccountingLogsBuilder extends UpdateLogsBuilder {
  constructor(log: Log<UpdateLogChangeSetFields>) {
    super(
      log,
      UpdateLogsBuilder.intl.formatMessage({ id: 'logs.accountingReport' }),
      UpdateTypes.ACCOUNTING
    )
  }
}

export class NoteLogsBuilder extends UpdateLogsBuilder {
  constructor(log: Log<UpdateLogChangeSetFields>, params: UpdateLogParams) {
    super(
      log,
      UpdateLogsBuilder.intl.formatMessage({ id: 'logs.note' }),
      UpdateTypes.NOTE,
      params
    )
  }
}

export class DocumentLogsBuilder extends UpdateLogsBuilder {
  constructor(log: Log<UpdateLogChangeSetFields>, params: UpdateLogParams) {
    super(
      log,
      UpdateLogsBuilder.intl.formatMessage({ id: 'logs.document' }),
      UpdateTypes.DOCUMENT,
      params
    )
  }
}

export class AnnouncementLogsBuilder extends UpdateLogsBuilder {
  constructor(log: Log<UpdateLogChangeSetFields>, params: UpdateLogParams) {
    super(
      log,
      UpdateLogsBuilder.intl.formatMessage({ id: 'logs.announcement' }),
      UpdateTypes.ANNOUNCEMENT,
      params
    )
  }
}

export class TransactionLogsBuilder extends UpdateLogsBuilder {
  constructor(log: Log<UpdateLogChangeSetFields>, params: UpdateLogParams) {
    super(
      log,
      UpdateLogsBuilder.intl.formatMessage({ id: 'logs.transaction' }),
      UpdateTypes.TRANSACTION,
      params
    )
  }
}
