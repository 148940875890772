import axiosClient from './httpClient'

class IntercomService {
  static async receiveMessageFromAdmin(message: string): Promise<string> {
    const { data } = await axiosClient().post<{
      conversationId: string
    }>(`intercom/message_admin_to_user`, { message })

    return data.conversationId
  }

  static async sendMessageToAdmin(message: string): Promise<string> {
    const { data } = await axiosClient().post<{
      conversationId: string
    }>(`intercom/message_user_to_admin`, { message })

    return data.conversationId
  }
}

export default IntercomService
