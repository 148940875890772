import { FormattedMessage } from 'react-intl'

import { ReactComponent as CWLogo } from 'assets/images/cw-mini-logo.svg'
import { ReactComponent as GoogleGroup } from 'assets/images/google-group.svg'
import OptionsDropdown from 'ui/OptionsDropdown'
import { gridItemAnimation } from 'utils/animations/grid'
import theme from 'utils/theme'
import { Inbound } from 'utils/types/inbounds'
import Tooltip from 'components/Tooltip'

import StatusIndicator from '../../StatusIndicator'
import { AvatarContainer, Email, Li } from './InboundRow.styles'

interface InboundRowProps {
  inbound: Inbound
  openDeleteModal: () => void
  openSettings: () => void
  isDefaultInbound: boolean
  canEdit: boolean
}

const InboundRow = ({
  inbound,
  openDeleteModal,
  openSettings,
  isDefaultInbound,
  canEdit,
}: InboundRowProps) => {
  if (isDefaultInbound) {
    return (
      <Li variants={gridItemAnimation}>
        <AvatarContainer>
          <CWLogo />
        </AvatarContainer>
        <Email>{inbound.inboundEmail}</Email>
      </Li>
    )
  }

  return (
    <Li variants={gridItemAnimation}>
      <AvatarContainer>
        <GoogleGroup />
      </AvatarContainer>
      <Email>
        {canEdit ? (
          inbound.email
        ) : (
          <Tooltip
            id={`inbound_tooltip_${inbound.id}`}
            text={<FormattedMessage id="inbounds.inboundTooltip" />}
            place="bottom"
          >
            {inbound.email}
          </Tooltip>
        )}
      </Email>
      <StatusIndicator status={inbound.connectionStatus} />
      {canEdit && (
        <div>
          <OptionsDropdown
            buttonType="square"
            buttonSize="3.2rem"
            attachToDocument={false}
            dropdownContentMinWidth="13rem"
          >
            <OptionsDropdown.Item
              label={<FormattedMessage id="general.settings" />}
              icon={['far', 'cog']}
              onSelectOption={openSettings}
            />
            <OptionsDropdown.Item
              label={<FormattedMessage id="general.delete" />}
              icon={['far', 'trash-alt']}
              onSelectOption={openDeleteModal}
              color={theme.colors.red}
            />
          </OptionsDropdown>
        </div>
      )}
    </Li>
  )
}

export default InboundRow
