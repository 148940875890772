import { ChannelType } from 'containers/Chat/types'
import { TopicEntityType } from 'utils/types/chatTopicEntity'

export const QUERIES = {
  CHANNEL: 'channel',
  CHANNELS: 'channels',
  CHANNEL_MEMBERS: 'channel_members',
  SEARCH_MESSAGES: 'search_messages',
  SEARCH_CHANNELS: 'search_channels',
  SEARCH_PARTICIPANTS: 'search_participants',
  SIMILAR_CHANNELS: 'similar_channels',
} as const

export const chatKeys = {
  getChannelsByIds: (channelIds: string[]) =>
    [QUERIES.CHANNELS, ...channelIds] as const,
  getChannelById: (channelId: string) => [QUERIES.CHANNEL, channelId] as const,
  getChannelMembers: (channelId: string) =>
    [QUERIES.CHANNEL_MEMBERS, channelId] as const,
  searchMessages: (text: string, userId: string) =>
    [QUERIES.SEARCH_MESSAGES, text, userId] as const,
  searchChannels: (text: string, userId: string) =>
    [QUERIES.SEARCH_CHANNELS, text, userId] as const,
  searchParticipants: (text: string[], userId: string) =>
    [QUERIES.SEARCH_PARTICIPANTS, userId, ...text] as const,
  similarChannel: (
    entityId: string,
    entityType: TopicEntityType,
    channelType: ChannelType,
    members: string[],
    updateId?: string
  ) => [
    QUERIES.SIMILAR_CHANNELS,
    entityId,
    entityType,
    channelType,
    members,
    updateId,
  ],
}
