import { getCurrentGroupId } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { ONLY_ME } from 'utils/functions/updates'
import { Update } from 'utils/types/update'
import { useHistory, useLocation } from 'react-router-dom'
import useEntityFromUrl from 'utils/hooks/useEntityFromUrl'
import {
  PREVIEW_DRAFT_PATH,
  useIsPreviewingDraftUpdate,
} from 'utils/hooks/useIsPreviewingDraftUpdate'

interface UseEditUpdateActionProps {
  update?: Update
  isEditMode: boolean
  hasEditPermissions: boolean
}

const useEditUpdateAction = ({
  update,
  isEditMode,
  hasEditPermissions,
}: UseEditUpdateActionProps) => {
  const location = useLocation<{ canGoBack: boolean }>()
  const history = useHistory()
  const isPreviewingDraft = useIsPreviewingDraftUpdate()

  const currentGroupId = useAppSelector(getCurrentGroupId)
  const entity = useEntityFromUrl()

  const canGoBack = location.state?.canGoBack
  const wasUpdateCreatedInCurrentGroup = update?.groupId === currentGroupId
  const isClickingFromUpdatesView = location.pathname.includes('/updates')

  const isUserAbleToEdit =
    update?.visibility === ONLY_ME ? wasUpdateCreatedInCurrentGroup : true

  const isEditable = isUserAbleToEdit && !isEditMode && hasEditPermissions

  const redirectToEditView = () => {
    const { pathname } = history.location

    const locationState = {
      ...location.state,
      showNavigation: false,
      canGoBack,
      companyName: document.getElementById('company-name')?.innerHTML,
      currentPortfolio: !isClickingFromUpdatesView ? entity : undefined,
    }

    if (isPreviewingDraft) {
      const newPathName = pathname.replace(PREVIEW_DRAFT_PATH, '/edit')

      return history.push(newPathName, {
        ...locationState,
      })
    }

    return history.push(`${pathname}/edit`, {
      ...locationState,
    })
  }

  return {
    isEditable,
    isPreviewingDraft,
    redirectToEditView,
  }
}

export default useEditUpdateAction
