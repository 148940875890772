import { DATE_FORMATS, formattedDate } from 'utils/functions/date'
import theme from 'utils/theme'

interface CustomTickProps {
  x: number
  y: number
  payload: {
    value: string
  }
}

const CustomTick = ({ x, y, payload }: CustomTickProps) => {
  const dayMonth = formattedDate(payload.value, DATE_FORMATS.DDMM)
  const year = formattedDate(payload.value, DATE_FORMATS.YYYY)

  return (
    <g>
      <g>
        <text x={x} y={y + 10} fill={theme.colors.lightGray} fontSize="1.2rem">
          {dayMonth}.
        </text>
        <text x={x} y={y + 25} fill={theme.colors.lightGray} fontSize="1.2rem">
          {year}
        </text>
      </g>
    </g>
  )
}

export default CustomTick
