import { FormattedMessage } from 'react-intl'

import Checkbox from 'components/Checkbox'
import GroupTooltips from 'components/GroupTooltips'

import { ShareWithOption } from '../useShareWithDropdown'
import * as Styles from '../ShareWithDropdown.styles'
import { getImageComponent } from './Icons'

const getUserType = (type: string) => {
  switch (type) {
    case 'group':
      return 'updates.group'
    case 'list':
      return 'updates.list'
    case 'user':
      return 'updates.user'
    default:
      return ''
  }
}

export const Option =
  (prevSelectedOptions?: ShareWithOption[]) =>
  ({ data, innerProps, isSelected, isFocused, innerRef }) => {
    const wasAlreadyAdded = prevSelectedOptions?.some(
      (option) => option.id === data.id
    )

    return (
      <Styles.OptionContainer
        ref={innerRef}
        disabled={wasAlreadyAdded}
        isEmail={data.type === 'email'}
        isFocused={isFocused}
        {...innerProps}
      >
        <Styles.OptionLeft>
          <Checkbox
            id={`option-checkbox-${data.id}`}
            name={`option-checkbox-${data.id}`}
            checked={isSelected}
          />
          <div>{getImageComponent(data.type, data.name, data.imageUrl)}</div>

          <Styles.OptionData>
            <Styles.Name>{data.name}</Styles.Name>
            {data.type !== 'email' && (
              <Styles.OptionType>
                {' '}
                · <FormattedMessage id={getUserType(data.type)} />
              </Styles.OptionType>
            )}
            {!!data.subtitle && (
              <Styles.OptionSubtitle>{data.subtitle}</Styles.OptionSubtitle>
            )}
          </Styles.OptionData>
        </Styles.OptionLeft>
        <div>
          {wasAlreadyAdded && <FormattedMessage id="updates.addedAlready" />}
          {data.recipients?.length > 0 && (
            <Styles.ListRecipientsContainer>
              <GroupTooltips
                groups={data.recipients.map((option) => ({
                  id: option.id,
                  name: option.name,
                  logo: {
                    url: option.imageUrl,
                  },
                }))}
                small
                maxGroupsToDisplay={10}
              />
            </Styles.ListRecipientsContainer>
          )}
        </div>
      </Styles.OptionContainer>
    )
  }
