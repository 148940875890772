/* eslint-disable consistent-return */
import CompanyService from 'api/CompanyService'
import Toast from 'components/Toast'
import { setCurrentCompany, setCurrentCompanyLoading } from 'actions/companies'
import { getTagsFromTaggings } from 'utils/functions/tags'
import { setCurrentPortfolio } from 'actions/portfolios'
import companyLogoPlaceholder from 'assets/images/missing.png'
import { CLIENT_GROUP_TYPE, FOUNDER_TEAM_TYPE } from './groups'
import ResponseNormalizer from './responseNormalizer'

export const getLegalStructureById = (legalStructures, legalStructureId) =>
  Object.values(legalStructures).filter(
    (legalStructure) => legalStructure.id === legalStructureId
  )[0]

export const getCompanyInfo = async (
  dispatch,
  companyId,
  history,
  currentGroupId
) => {
  try {
    dispatch(setCurrentCompanyLoading(true))

    const getCompanyResponse = await CompanyService.getCompanyById(companyId)
    if (getCompanyResponse?.data) {
      const { entities } = getCompanyResponse.data
      const companyData = entities?.companyData[companyId]

      const owner = entities?.groups[companyData.groupId]
      const isPrivateProfile =
        !!currentGroupId && owner?.id === currentGroupId && !companyData.fund

      const companyInfoObj = {
        id: companyData.id,
        name: companyData.name,
        website: companyData.website,
        founded: companyData.founded,
        description: companyData.description,
        logoUrl: companyData.primaryLogo?.url,
        employees: Object.values(entities?.employees),
        employeesNumber: companyData.employeesNumber,
        industries: Object.values(entities?.industries),
        primaryLocation: Object.values(entities?.primaryLocations)?.[0],
        legalStructure: getLegalStructureById(
          entities.legalStructures,
          entities?.companyData[companyId].legalStructure
        ),
        alreadyInUserPortfolio: companyData.alreadyInUserPortfolio,
        tags: getTagsFromTaggings(Object.values(entities.taggings)),
        owner,
        fund: companyData.fund,
        companiesInPortfolios: companyData.companiesInPortfolios,
        angellistUrl: companyData.angellistUrl,
        linkedinUrl: companyData.linkedinUrl,
        crunchbaseUrl: companyData.crunchbaseUrl,
        verified: companyData.verified,
        qualityAssured: companyData.qualityAssured,
        isPrivateProfile,
      }

      const companyHolding = ResponseNormalizer.normalizeHolding(companyInfoObj)
      dispatch(setCurrentCompany(companyHolding))

      dispatch(setCurrentPortfolio({}))
      dispatch(setCurrentCompanyLoading(false))

      return companyHolding
    }
    history.replace('/dashboard/updates')
  } catch {
    Toast.display(
      'An error occurred when trying to get the company information, try again later',
      'error'
    )
  }
}

export const getCompanyImage = (companyDatum) => {
  const companyImage = companyDatum?.primaryLogo?.smallLogo?.url
  return !companyImage ? companyLogoPlaceholder : companyImage
}
