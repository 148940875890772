import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const SharedGroups = styled.div``

const updateActionsStyles = css`
  justify-content: flex-end;
  margin-left: -0.4rem;
  align-items: center;
`

export const TooltipsWrapper = styled.div<{
  countGroups?: number
  isUpdateAction?: boolean
}>`
  display: flex;
  position: relative;
  height: 1rem;

  ${(props) =>
    props.isUpdateAction
      ? updateActionsStyles
      : css`
          width: calc(${props.countGroups} * 1.6rem);
          justify-content: flex-end;
        `}
`

export const Label = styled.span<{ italic?: boolean; upperCase?: string }>`
  color: #787e8c;
  text-transform: ${(props) => props.upperCase && 'uppercase'};
  font-size: 1.2rem;

  ${(props) =>
    props.italic
      ? css`
          font-style: italic;
          font-weight: 700;
        `
      : ''}
`

export const GroupItem = styled.div<{
  direction?: string
  length: number
  disabled?: boolean
  small?: boolean
  index: number
}>`
  position: absolute;
  ${(props) =>
    props.direction === 'right'
      ? css`
          right: calc(
            ${(currProps: { index: number }) => currProps.index}*1.6rem
          );
          z-index: ${props.length - props.index};
        `
      : css`
          left: calc(
            ${(currProps: { index: number }) => currProps.index}*1.6rem
          );
          z-index: ${props.index};
        `} background: white;
  border-radius: 100%;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};

  ${(props) =>
    props.small
      ? css`
          bottom: -0.6rem;
        `
      : css`
          bottom: -0.9rem;
        `}
`

export const CountGroups = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.1rem;
  height: 2.1rem;
  background-color: transparent;
  color: ${color('lightGray')};
  font-weight: bold;
  font-size: 1rem;
  border-radius: 2.5rem;
  line-height: 1;
`

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }
`

export const GroupName = styled.span`
  margin-left: 1rem;
  color: white;
  display: flex;
  gap: 0.5rem;
`

export const GroupDescription = styled.span`
  color: ${color('lightGray')};
  font-weight: 400;
`
