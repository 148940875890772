import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { Container, Text } from './PreviewError.styles'

const PreviewError = () => {
  return (
    <Container>
      <Text>
        <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
        <FormattedMessage id="files.errorToLoad" />
      </Text>
    </Container>
  )
}

export default PreviewError
