import type { Compatible, UncertainCompatible } from '@silevis/reactgrid'
import { NumberCellTemplate } from '@silevis/reactgrid'
import React from 'react'
import { parseNumber, parsePercentageNumber } from 'utils/functions/number'
import { CustomNumberCell } from '../types'
import { DisabledCell, NumberCellInputContainer } from './Template.styles'
import NumberCellPlaceholder from './components/NumberCellPlaceholder'
import NumberCellDisplayValue from './components/NumberCellDisplayValue'

export class CustomNumberCellTemplate extends NumberCellTemplate {
  update(
    cell: Compatible<CustomNumberCell>,
    cellToMerge: UncertainCompatible<CustomNumberCell>
  ): Compatible<CustomNumberCell> {
    // eslint-disable-next-line no-param-reassign
    cell.error = ''
    const newValue = cell.isPercentage
      ? parsePercentageNumber(cellToMerge.text)
      : parseNumber(cellToMerge.text)

    if (cell.max && !Number.isNaN(newValue)) {
      // eslint-disable-next-line no-param-reassign
      cell.error = newValue >= cell.max ? cell.maxErrorMsgId || '' : ''
    }

    return {
      ...this.getCompatibleCell({
        ...cell,
        value: newValue,
      }),
      rowIndex: cell.rowIndex,
    }
  }

  render(
    cell: Compatible<CustomNumberCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CustomNumberCell>, commit: boolean) => void
  ): React.ReactNode {
    if (cell.disabled) {
      return <DisabledCell centered={cell.centered}>-</DisabledCell>
    }

    if (!isInEditMode) {
      return !Number.isNaN(cell.value) ? (
        <NumberCellDisplayValue cell={cell} />
      ) : (
        <NumberCellPlaceholder cell={cell} />
      )
    }

    return (
      <NumberCellInputContainer centered={cell.centered}>
        {super.render(cell, isInEditMode, onCellChanged)}
      </NumberCellInputContainer>
    )
  }
}
