import styled, { css } from 'styled-components'
import Button from 'ui/Button'
import { color } from 'utils/functions/colors'

export const ActionTitle = styled.h1`
  text-align: initial;
  font-size: 1.8rem;
  margin-left: 1.2rem;
  line-height: 2.2rem;
  color: ${(props) => (props.isActive ? color('primaryBlue')(props) : '')};

  ${(props) =>
    props.completed &&
    css`
      color: ${color('green')(props)};
    `}
`

const activeActionContainerStyles = css`
  padding: 1.4rem 1.8rem 0.3rem 2.1rem;
  border: 0.2rem solid #c9cfe4;
  ${(props) =>
    !props.completed &&
    css`
      ${ActionTitle} {
        color: ${color('primaryBlue')};
      }
    `}
`
export const ActionContainer = styled.div`
  padding: 1.5rem 1.9rem 0.4rem 2.2rem;
  margin: 1.6rem 0;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  box-sizing: border-box;

  ${(props) =>
    props.isActive
      ? activeActionContainerStyles
      : css`
          &:hover {
            ${activeActionContainerStyles}
          }
        `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`

export const ActiontitleContainer = styled.div`
  display: flex;
  margin-bottom: 0.7rem;
  justify-content: space-between;
  align-items: center;
`

export const TitleRow = styled.div`
  display: flex;
  align-items: center;
`

export const ActionText = styled.p`
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin: 0.8rem 0;
  color: ${color('lightGray')};
  text-align: initial;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  button {
    margin-right: 0.8rem;
  }
`

export const ActionButton = styled(Button)`
  padding-left: 0.4rem;
  color: ${color('lightGray')};
  svg {
    margin-right: 0.8rem;
  }
  &:hover:not(:disabled) {
    background-color: transparent;
  }
`

export const ToggleActionButton = styled.button`
  &:focus {
    outline: none;
  }
  svg {
    font-size: 1.4rem;
  }
`

export const CardWrapper = styled.div`
  display: block;
  cursor: pointer;
  width: 100%;
  &:focus {
    outline: none;
  }
`
