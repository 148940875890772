import ReadOnlyTooltip from 'components/ReadOnlyTooltip'
import React, { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { PRICING_LINK } from 'utils/constants/links'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'

const StyledReadOnlyTooltip = styled(ReadOnlyTooltip)<{
  isBigCreatePortfolioButton?: boolean
}>`
  width: 100%;
`
type Props = PropsWithChildren & {
  id: string
  leftOffset?: number
  topOffset?: number
  isBigCreatePortfolioButton?: boolean
}

const DisableCreatePortfolioTooltip: React.FC<Props> = ({
  children,
  id,
  leftOffset,
  topOffset,
  isBigCreatePortfolioButton,
}) => {
  const intl = useIntl()
  const planFeatures = usePlanFeatures()

  return (
    <StyledReadOnlyTooltip
      id={id}
      key={isBigCreatePortfolioButton ? 'big-tooltip-key' : 'small-tooltip-key'}
      isBigCreatePortfolioButton={isBigCreatePortfolioButton}
      text={intl.formatMessage(
        { id: 'subscriptions.limitations.portfoliosLimit' },
        {
          SubscriptionsLink: (txt) => (
            <Link to="/settings/group_management/subscriptions">{txt}</Link>
          ),
          MoreInfoLink: (txt) => (
            <a href={PRICING_LINK} target="blank" rel="noreferrer">
              {txt}
            </a>
          ),
        }
      )}
      disabled={!planFeatures.hasPortfoliosAvailable}
      place="right"
      clickable
      delayHide={500}
      maxWidth="45rem"
      forceOverridePosition
      leftOffset={leftOffset}
      topOffset={topOffset}
    >
      {children}
    </StyledReadOnlyTooltip>
  )
}

export default DisableCreatePortfolioTooltip
