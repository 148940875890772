import styled, { css } from 'styled-components'
import Button from 'ui/Button'
import { color } from 'utils/functions/colors'

export const FilterOptionsWrapper = styled.div`
  margin-left: auto;
`

export const tagsStyles = () => css`
  flex-direction: inherit;
  flex-wrap: wrap;
  gap: 0.8rem;
  cursor: pointer;
`

export const titleStyles = (hasActiveFilters?: boolean) => css`
  font-weight: ${hasActiveFilters ? 700 : 400};
  color: ${hasActiveFilters ? color('primaryBlue') : color('darkBlue')};
  text-transform: capitalize;
`

export const FilterButton = styled(Button)`
  color: ${color('darkBlue')};
  font-weight: bold;
  font-size: 1.4rem;

  ${(props) =>
    props.link &&
    css`
      &:focus:not(:active) {
        color: ${color('primaryBlue')};
        background-color: ${color('veryLightBlue', 0.4)};
        border: 0.1rem solid ${color('primaryBlue')};
      }
    `}
`

export const FilterTitle = styled.p`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${color('lightGray')};
  padding: 1rem 0 1rem 0.8rem;
`

export const Tag = styled.div<{ status: boolean }>`
  width: fit-content;
  border-radius: 0.8rem;
  padding: 0.6rem 1.2rem;
  border: 1px solid ${color('veryLightGray')};
  text-transform: capitalize;
  max-width: 17rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) =>
    props.status &&
    css`
      background-color: ${color('primaryBlue')};
      color: white;
      border: 1px solid ${color('primaryBlue')};
    `}
`

export const Title = styled.h2`
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: capitalize;
  color: ${color('darkBlue')};
`
