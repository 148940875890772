import { useIntl } from 'react-intl'
import {
  isAttachedEmailFileContent,
  isIndexEmailContent,
  isIndexFileContent,
  isUnreconciledFile,
} from 'utils/functions/files'
import theme from 'utils/theme'
import {
  AttachedEmailFileContent,
  IndexEmailContent,
  IndexFileContent,
} from 'utils/types/files'
import Tooltip from 'components/Tooltip'
import {
  NotUsedContent,
  UserAndGroupName,
  UnreconciledIndicator as StyledUnreconciledIndicator,
} from 'components/Content/Content.styles'
import { useId, useMemo } from 'react'

interface UnreconciledIndicatorProps {
  content: IndexFileContent | IndexEmailContent | AttachedEmailFileContent
}

const UnreconciledIndicator = ({ content }: UnreconciledIndicatorProps) => {
  const id = useId()
  const isUnreconciled = useMemo(() => {
    if (isAttachedEmailFileContent(content)) {
      return content.unreconciled
    }

    if (isIndexFileContent(content) || isIndexEmailContent(content)) {
      return isUnreconciledFile(content.userContentStatus)
    }

    return false
  }, [content])

  const intl = useIntl()

  if (!isUnreconciled) {
    return null
  }

  return (
    <Tooltip
      id={`unreconciled-${content.id}-${id}`}
      text={
        isAttachedEmailFileContent(content)
          ? intl.formatMessage(
              {
                id: 'files.unreconciledFileWithoutOwner',
              },
              {
                notUsedFile: (
                  <NotUsedContent>
                    {intl.formatMessage({ id: 'files.notUsedFile' })}
                  </NotUsedContent>
                ),
              }
            )
          : intl.formatMessage(
              { id: 'files.unreconciledFile' },
              {
                notUsedFile: (
                  <NotUsedContent>
                    {intl.formatMessage({ id: 'files.notUsedFile' })}
                  </NotUsedContent>
                ),
                userName: (
                  <UserAndGroupName>{content.user?.name}</UserAndGroupName>
                ),
              }
            )
      }
      backgroundColor={theme.colors.purple}
    >
      <StyledUnreconciledIndicator />
    </Tooltip>
  )
}

export default UnreconciledIndicator
