const ZeroStateLinks = {
  ADD_PORTFOLIO_DATA_GUIDE:
    'https://support.cwuniverse.app/en/articles/4979707-create-and-manage-track-portfolios',
  ADD_PORTFOLIO:
    'https://support.cwuniverse.app/en/collections/2568799-investor-resources#how-to-work-with-portfolios',
  ADD_FIRST_COMPANY:
    'https://support.cwuniverse.app/en/articles/4753843-exploring-the-company-index',
  ADD_FIRST_PORTFOLIO:
    'https://support.cwuniverse.app/en/articles/4979707-create-and-manage-track-portfolios',
  ADD_FIRST_METRIC: '',
  COMPANY_UPDATES_FEED:
    'https://support.cwuniverse.app/en/collections/2568773-founder-resources#in-app-updates',
  FOUNDER_METRICS:
    'https://support.cwuniverse.app/en/articles/5407461-adding-metrics-and-managing-your-dashboard',
  EMAILS:
    'https://support.cwuniverse.app/en/articles/4486079-create-and-send-investor-updates',
  FUNDS_GUIDE: '', // TODO: Add funds guide URL,
}

export default ZeroStateLinks
