import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ProcessingDataLoading } from 'components/ProcessingDataLoading/ProcessingDataLoading.styles'
import * as Styles from './GeneratingMetrics.styles'

const GeneratingMetrics: React.FC = () => {
  return (
    <Styles.GeneratingMetrics>
      <Styles.ProcessingData>
        <Styles.ProcessingDataTitle>
          <ProcessingDataLoading />
          <FormattedMessage id="portfolioDetail.performanceMetrics.processingNewData" />
        </Styles.ProcessingDataTitle>
        <Styles.ProcessingDataSubTitle>
          <FormattedMessage id="portfolioDetail.performanceMetrics.takeAFewSeconds" />
        </Styles.ProcessingDataSubTitle>
      </Styles.ProcessingData>
    </Styles.GeneratingMetrics>
  )
}

export default GeneratingMetrics
