import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import {
  keyCodes,
  type Compatible,
  type Uncertain,
  isAlphaNumericKey,
  isNavigationKey,
} from '@silevis/reactgrid'
import { useQuery } from '@tanstack/react-query'
import debounce from 'lodash/debounce'
import Avatar from 'components/Avatar'
import CWLoader from 'components/CWLoader'
import HoldingsService from 'api/HoldingsService'
import { Holding, getHoldingImage } from 'utils/types/company'
import { getInitials } from 'utils/functions/user'
import Tooltip from 'components/Tooltip'
import theme from 'utils/theme'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { holdingsKeys } from 'utils/queries/holdings'
import CellErrorTooltip from 'components/Spreadsheet/CellTemplates/components/CellErrorTooltip/CellErrorTooltip'
import FundProfileSuggestionButton from './FundProfileSuggestionButton'
import { HoldingNameCell } from './HoldingNameCellTemplate'
import * as Styles from './HoldingNameCellTemplate.styles'
import HoldingProfileSuggestionButton from '../WebsiteCellTemplate/HoldingProfileSuggestionButton'

const INITIAL_PAGE = 0
const COMPANIES_PER_PAGE = 20

const useHoldingsQuery = (searchTerm: string) => {
  return useQuery(
    holdingsKeys.holdingsSpreadsheet(searchTerm),
    async () => {
      const { holdings } = await HoldingsService.getHoldings({
        page: INITIAL_PAGE,
        companiesPerPage: COMPANIES_PER_PAGE,
        filters: {
          name: searchTerm,
        },
      })

      return holdings
    },
    {
      enabled: !!searchTerm,
      retry: false,
    }
  )
}

interface HoldingNameCellProps {
  cell: Compatible<HoldingNameCell>
  isInEditMode: boolean
  onCellChanged: (cell: Compatible<HoldingNameCell>, commit: boolean) => void
  getCompatibleCell: (
    uncertainCell: Uncertain<HoldingNameCell>
  ) => Compatible<HoldingNameCell>
}

const HoldingNameCellRender: React.FC<HoldingNameCellProps> = ({
  cell,
  isInEditMode,
  onCellChanged,
  getCompatibleCell,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = debounce(setSearchTerm, 500)
  const wasEscKeyPressed = useRef(false)

  const { data: holdings, isFetching } = useHoldingsQuery(searchTerm)
  const hasFetched = useRef(false)
  const shouldShowDropdown = useMemo(
    () =>
      !!(holdings?.length || isFetching || hasFetched.current) && !!searchTerm,
    [holdings?.length, isFetching, searchTerm]
  )
  const shouldShowNoResults = useMemo(
    () =>
      !isFetching && hasFetched.current && !holdings?.length && !!searchTerm,
    [holdings?.length, isFetching, searchTerm]
  )
  const shouldShowResults = useMemo(
    () => !isFetching && hasFetched.current && !!holdings?.length,
    [holdings?.length, isFetching]
  )

  useEffect(() => {
    if (hasFetched.current) return

    if (isFetching) {
      hasFetched.current = true
    }
  }, [isFetching])

  const handleInputChange = (event) => {
    debouncedSearchTerm(event.target.value)
  }

  const onOptionClick = (holding: Holding) => {
    onCellChanged(
      getCompatibleCell({ ...cell, text: holding.name, holding }),
      true
    )
  }

  if (!isInEditMode) {
    const cellText = cell.text || cell.placeholder || ''

    const getCellError = () => {
      if (
        cell.customError?.type === 'MAX_LENGTH' ||
        cell.customError?.type === 'REQUIRED'
      ) {
        return (
          <FormattedMessage
            id={cell.customError?.error}
            values={{
              limit: MAX_COUNTER_CHARACTERS,
            }}
          />
        )
      }

      const profileSuggestion = cell.customError?.error.fundsErrors?.[0]
      if (cell.customError?.type === 'DUPLICATE_NAME' && profileSuggestion) {
        const nonHiddenProfiles = profileSuggestion.filter(
          (profileData) => !!profileData.holding
        )

        if (nonHiddenProfiles.length === 0) {
          return (
            <FormattedMessage id="spreadsheet.holdings.errors.fundNameInUseHidden" />
          )
        }

        return (
          <div>
            <FormattedMessage
              id={`spreadsheet.holdings.errors.${
                nonHiddenProfiles.length > 1
                  ? 'fundNameInUsePlural'
                  : 'fundNameInUse'
              }`}
            />
            <Styles.ProfileSuggestions.Container>
              {nonHiddenProfiles.map((profileData) =>
                profileData.holding ? (
                  <FundProfileSuggestionButton
                    key={profileData.id}
                    profileData={profileData}
                    onClick={() => onOptionClick(profileData.holding!)}
                  />
                ) : null
              )}
            </Styles.ProfileSuggestions.Container>
          </div>
        )
      }

      if (cell.holdingsToSuggest?.length) {
        return (
          <div>
            <FormattedMessage
              id={
                cell.holdingsToSuggest.length > 1
                  ? 'spreadsheet.holdings.existingHoldingsSuggestion'
                  : 'spreadsheet.holdings.existingHoldingSuggestion'
              }
            />
            <Styles.ProfileSuggestions.Container>
              {cell.holdingsToSuggest.map((holding) => (
                <HoldingProfileSuggestionButton
                  key={holding.id}
                  profileData={{
                    name: holding.name,
                    holding,
                  }}
                  onClick={() => onOptionClick(holding)}
                />
              ))}
            </Styles.ProfileSuggestions.Container>
          </div>
        )
      }

      return null
    }

    return (
      <CellErrorTooltip
        content={
          cell.customError || cell.holdingsToSuggest?.length
            ? getCellError()
            : null
        }
      >
        <Styles.CellValue
          isPlaceholder={!cell.text}
          hasError={!!cell.customError}
          hasSuggestion={!!cell.holdingsToSuggest?.length}
        >
          {cellText}
        </Styles.CellValue>
      </CellErrorTooltip>
    )
  }

  return (
    <Styles.CellWrapper>
      <input
        ref={(input) => {
          if (input) {
            input.focus()
            input.setSelectionRange(input.value.length, input.value.length)
          }
        }}
        defaultValue={cell.text}
        onChange={(e) => {
          handleInputChange(e)
          onCellChanged(
            getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            false
          )
        }}
        onBlur={(e) => {
          onCellChanged(
            getCompatibleCell({ ...cell, text: e.currentTarget.value }),
            !wasEscKeyPressed.current
          )
          wasEscKeyPressed.current = false
        }}
        onCopy={(e) => e.stopPropagation()}
        onCut={(e) => e.stopPropagation()}
        onPaste={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(e) => {
          if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
            e.stopPropagation()
          if (e.keyCode === keyCodes.ESCAPE) wasEscKeyPressed.current = true
        }}
      />
      {shouldShowDropdown && (
        <Styles.DropdownWrapper onPointerDown={(e) => e.stopPropagation()}>
          {shouldShowResults &&
            holdings?.map((holding) => (
              <Styles.DropdownOption
                key={holding.id}
                onPointerDown={() => onOptionClick(holding)}
              >
                <Avatar
                  image={getHoldingImage(holding)}
                  initials={getInitials(holding.name)}
                  avatarStyle="avatarCircleXSM"
                />
                <Tooltip
                  id={`holding-name-cell-${holding.id}`}
                  text={
                    holding.name.length > 20 ? (
                      <Styles.TooltipHoldingName>
                        {holding.name}
                      </Styles.TooltipHoldingName>
                    ) : null
                  }
                  place="right"
                  backgroundColor={theme.colors.white}
                  offset={2}
                  class="white-tooltip"
                >
                  <span>{holding.name}</span>
                </Tooltip>
              </Styles.DropdownOption>
            ))}
          {isFetching && <CWLoader logoSize="4rem" />}
          {shouldShowNoResults && (
            <Styles.NoResultsContainer>
              <FormattedMessage id="spreadsheet.holdings.noResults" />
            </Styles.NoResultsContainer>
          )}
        </Styles.DropdownWrapper>
      )}
    </Styles.CellWrapper>
  )
}

export default HoldingNameCellRender
