import styled, { css } from 'styled-components'
import { breakpoint, maxSize } from 'utils/constants/breakpoint'

const displayMobile = (maxWidth: string) => css`
  @media ${maxWidth} {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'content'
      'side';
  }
`

export const UpdateLayout = styled.div<{
  isAccounting?: boolean
  isInvestor: boolean
}>`
  display: grid;
  transition: grid-template-columns 0.2s;
  gap: 2.4rem;

  ${(props) =>
    props.isAccounting ? displayMobile(maxSize.md) : displayMobile(maxSize.xl)}

  ${(props) =>
    props.isAccounting &&
    css`
      @media (min-width: ${breakpoint.md}) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'header header'
          'content content'
          'side side';
      }
    `}

  @media (min-width: ${breakpoint.xl}) {
    grid-template-columns: 1fr 1fr 40.3rem;
    gap: 3.2rem;
    grid-template-areas:
      'header header header'
      'content content ${(props) => (props.isInvestor ? 'content' : 'side')}'
      'content content ${(props) => (props.isInvestor ? 'content' : 'side')}';
  }
`

export const UpdateLayoutHeader = styled.div`
  grid-area: header;
`

export const UpdateLayoutContent = styled.div`
  min-width: 0;
  grid-area: content;
`

export const UpdateLayoutSide = styled.div`
  grid-area: side;
`
