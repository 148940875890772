import styled from 'styled-components'

export const Legend = styled.div`
  max-width: 21rem;
  text-wrap: balance;
  user-select: none;
`

export const Title = styled.div`
  max-width: 26rem;
  user-select: none;
`
