import { useHistory } from 'react-router-dom'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  DASHBOARD_METRICS,
  DASHBOARD_METRICS_ID,
  DASHBOARD_METRICS_ID_ADD_VALUE,
  DASHBOARD_METRICS_ID_EDIT,
  DASHBOARD_METRICS_ID_SET_MILESTONE,
  DASHBOARD_METRICS_UPDATE_ALL,
  DASHBOARD_UPDATES,
  DASHBOARD_UPDATES_EDIT,
} from 'routes/constant/founderRoutes'
import useParseCurrentUrl from 'components/Breadcrumb/useParseCurrentUrl'
import {
  ArrowWrapper,
  LinkWrapper,
  NavigationItem,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import { Update } from 'utils/types/update'
import { CompanyHoldingData, FundProfile } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'

interface Props {
  update?: Update
  entityToShow?: FundProfile | CompanyHoldingData
  url: URL
  isCreatingNewUpdate: boolean
  getAvatar: () => Nullable<JSX.Element>
}

const FounderDashboardBreadcrumb = ({
  entityToShow,
  getAvatar,
  isCreatingNewUpdate,
  update,
  url,
}: Props) => {
  const history = useHistory()
  const { metricId } = useParseCurrentUrl()

  const initialTabActive = [
    DASHBOARD_UPDATES,
    DASHBOARD_UPDATES_EDIT,
    DASHBOARD_METRICS,
    DASHBOARD_METRICS_ID.replace(':metricId', metricId),
    DASHBOARD_METRICS_ID_ADD_VALUE.replace(':metricId', metricId),
    DASHBOARD_METRICS_ID_SET_MILESTONE.replace(':metricId', metricId),
    DASHBOARD_METRICS_ID_EDIT.replace(':metricId', metricId),
    DASHBOARD_METRICS_UPDATE_ALL,
  ]

  const isActive = initialTabActive.some((tab) => tab === url.current)

  return (
    <NavigationWrapper>
      {entityToShow && (
        <NavigationItem>
          {getAvatar()}
          <LinkWrapper
            isActive={isActive}
            onClick={() => history.push(DASHBOARD_UPDATES)}
          >
            {entityToShow.name}
          </LinkWrapper>

          {(update || isCreatingNewUpdate) && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </NavigationItem>
      )}

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default FounderDashboardBreadcrumb
