import React from 'react'
import { useIntl } from 'react-intl'
import Modal from 'components/Modal'
import ImportHoldingsModal from './ImportHoldingsModal/ImportHoldingsModal'
import SingleHoldingModal from './SingleHoldingModal/SingleHoldingModal'
import { useAddHoldingModal, AddHoldingModalProps } from './useAddHoldingModal'

const AddHoldingModal: React.FC<AddHoldingModalProps> = ({
  onHideModal,
  isModalOpen,
  onCreateNewHoldings,
  name,
  fundManager,
}) => {
  const intl = useIntl()
  const {
    type,
    closeModal,
    parseCSV,
    processingFile,
    fileName,
    holdingsFromCSV,
    initialBulkErrors,
    initialBulkStatus,
  } = useAddHoldingModal({
    onHideModal,
    isModalOpen,
    onCreateNewHoldings,
    name,
  })

  return (
    <Modal show={isModalOpen} onHide={closeModal} hideOnOutsideClick={false}>
      <Modal.Header onHide={closeModal}>
        <Modal.Title>
          {intl.formatMessage({ id: 'addHolding.addHolding' })}
        </Modal.Title>
      </Modal.Header>
      {type === 'single' && (
        <SingleHoldingModal
          onHideModal={closeModal}
          parseCSV={parseCSV}
          processingFile={processingFile}
          fileName={fileName}
          onCreateNewHoldings={onCreateNewHoldings}
          name={name}
          fundManager={fundManager}
        />
      )}
      {type === 'bulk' && (
        <ImportHoldingsModal
          onHideModal={closeModal}
          onCreateNewHoldings={onCreateNewHoldings}
          fileName={fileName}
          holdingsFromCSV={holdingsFromCSV}
          initialErrors={initialBulkErrors.current!}
          initialStatus={initialBulkStatus.current!}
        />
      )}
    </Modal>
  )
}

export default AddHoldingModal
