import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import dayjs from 'dayjs'

import DraftDotsMenu from 'components/DraftDotsMenu'
import FeedUpdate from 'components/FeedUpdate'
import IconLegend from 'components/IconLegend'
import Tooltip from 'components/Tooltip'
import { getCurrentCompany } from 'selectors/company'
import { displayAttachments } from 'utils/functions/renderers/renderUpdatesHelper'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useVisibility } from 'utils/hooks/useVisibility'
import { FileContent } from 'utils/types/files'
import { EmailIndexUpdate } from 'utils/types/update'
import {
  AttachmentsContainer,
  ScheduledEmail,
  LinkButton,
  TitleWrapper,
  UpdateText,
  VisibilityContainer,
  DraftDotsMenuContainer,
} from '../UpdateFactory.styles'
import { useDefaultUpdateCard } from './useDefaultUpdateCard'

import { useSelectUpdatesContext } from '../../UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import ReshareIcons from '../components/ReshareIcons'

const isFromEmailGroupCreator = (sentToCount) => typeof sentToCount === 'number'

interface EmailCardProps {
  update: EmailIndexUpdate
  onPreviewFile: (file: FileContent) => void
  filesPreview: JSX.Element
  canEdit?: boolean
  showDotsOptions?: boolean
}

const EmailCard: React.FC<EmailCardProps> = ({
  update,
  onPreviewFile,
  filesPreview,
  canEdit,
  showDotsOptions = true,
}) => {
  const intl = useIntl()
  const company = useAppSelector(getCurrentCompany)
  const { getUpdateVisibility } = useVisibility()
  const { onShow, onEdit, onReshare, onDelete } = useDefaultUpdateCard(update)
  const { handleHoverCard, handleUnHoverCard, isCardSelected, isCardHover } =
    useSelectUpdatesContext()

  const emailData = update.item

  if (!emailData) {
    return null
  }

  const onMouseProps = {
    onMouseEnter: () => handleHoverCard(update),
    onMouseLeave: () => handleUnHoverCard(),
    hoverState: isCardHover(update),
    checkState: isCardSelected(update),
  }

  const subject =
    emailData?.subject || `${company?.name} ${dayjs().format('MMMM')} Update `

  const isScheduled = !!update.loggingUpdateSchedule && update.scheduled
  const isDraft = update.draft && !isScheduled

  return (
    <>
      <FeedUpdate
        dataTestId="email-feed-card"
        {...onMouseProps}
        isDraftUpdate={isDraft}
        isScheduled={isScheduled}
        onClick={onShow}
      >
        <FeedUpdate.Header>
          <FeedUpdate.Title
            draft={isDraft}
            hoverState={onMouseProps.hoverState || onMouseProps.checkState}
            titleMaxWidth="94%"
          >
            <TitleWrapper draft={isDraft}>
              <Tooltip
                id={`update-title-${update.id}`}
                place="top"
                text={subject}
                delayShow={500}
                widthAuto
                tooltipStyle={{ maxWidth: 500 }}
                ellipsis
              >
                <span>{subject}</span>
              </Tooltip>
            </TitleWrapper>
            {!!isScheduled && (
              <ScheduledEmail>
                {intl.formatMessage(
                  { id: 'updates.scheduled' },
                  {
                    date: dayjs(
                      update.loggingUpdateSchedule?.scheduleDate
                    ).format('MM/DD/YYYY, HH:mm A'),
                  }
                )}
              </ScheduledEmail>
            )}
          </FeedUpdate.Title>

          <DraftDotsMenuContainer>
            {isDraft && onMouseProps.hoverState && showDotsOptions && (
              <DraftDotsMenu onDeleteIUEDraft={onDelete} update={update} />
            )}
          </DraftDotsMenuContainer>
        </FeedUpdate.Header>
        <FeedUpdate.Body>
          <UpdateText className="fs-exclude">
            {emailData.entriesRawText}
          </UpdateText>

          <AttachmentsContainer>
            {displayAttachments(update.contents, 'small', onPreviewFile)}
          </AttachmentsContainer>
        </FeedUpdate.Body>

        <FeedUpdate.Footer>
          {!!update.contents?.length && (
            <IconLegend
              icon={['fal', 'paperclip']}
              legend={update.contents?.length}
              title={intl.formatMessage({
                id: 'updates.attachmentsNumber',
              })}
            />
          )}
          {!isDraft && isFromEmailGroupCreator(emailData?.sentToCount) && (
            <IconLegend
              icon={['fal', 'paper-plane']}
              legend={emailData.sentToCount?.toString()}
              title={intl.formatMessage({ id: 'updates.sentRecipients' })}
            />
          )}

          {(update.confidential || !isDraft) && (
            <VisibilityContainer>
              {!isDraft && (
                <IconLegend
                  icon={['fal', 'eye']}
                  legend={
                    isDraft
                      ? intl.formatMessage({ id: 'updates.only_meText' })
                      : getUpdateVisibility(update)
                  }
                  title={intl.formatMessage({ id: 'updates.visibility' })}
                />
              )}
              {update.confidential && (
                <IconLegend
                  icon={['fal', 'user-shield']}
                  title={intl.formatMessage({ id: 'updates.confidential' })}
                  hasTooltip
                />
              )}
            </VisibilityContainer>
          )}

          {canEdit && (
            <LinkButton onClick={onEdit}>
              <LinkButton onClick={onEdit}>
                <FormattedMessage id="general.edit" />
              </LinkButton>
            </LinkButton>
          )}

          <ReshareIcons update={update} onClick={onReshare} />
        </FeedUpdate.Footer>
      </FeedUpdate>
      {filesPreview}
    </>
  )
}

export default EmailCard
