import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { StyledItem, StyledToolTip } from './Item.styles'

type ItemType =
  | 'email'
  | 'label'
  | 'link'
  | 'darkLink'
  | 'text'
  | 'tooltip'
  | 'email-tooltip'

interface ItemProps {
  icon: IconProp
  value: string
  type: ItemType
  href?: string
  maxLines?: number
  title?: string
  isDark?: boolean
  id?: string
  topOffset?: number
  leftOffset?: number
}

const getValue = (type, value, href, id, topOffset, leftOffset) => {
  switch (type) {
    case 'email':
      return <a href={`mailto: ${value}`}>{value}</a>
    case 'label':
      return <FormattedMessage id={value} />
    case 'link':
      return (
        <a href={href} target="_blank" rel="noreferrer">
          {value}
        </a>
      )
    case 'darkLink':
      return (
        <StyledToolTip
          place="bottom"
          id={id}
          text={value}
          delayShow={800}
          topOffset={topOffset || 70}
          leftOffset={leftOffset}
          widthAuto
        >
          <a className="darkLink" href={href} target="_blank" rel="noreferrer">
            {value}
          </a>
        </StyledToolTip>
      )
    case 'text':
      return <p>{value}</p>
    case 'tooltip':
      return (
        <StyledToolTip
          place="bottom"
          id="tooltip__list_item"
          text={value}
          delayShow={500}
        >
          <p className="tooltip">{value}</p>
        </StyledToolTip>
      )
    case 'email-tooltip':
      return (
        <StyledToolTip
          place="bottom"
          id="email__list_item"
          text={value}
          delayShow={500}
        >
          <a className="mailItem" href={`mailto: ${value}`}>
            {value}
          </a>
        </StyledToolTip>
      )
    default:
      return value
  }
}

const Item: React.FC<ItemProps> = ({
  icon,
  value,
  type,
  href,
  maxLines,
  title,
  isDark,
  id,
  topOffset,
  leftOffset,
}) => {
  return value ? (
    <StyledItem
      className="fs-exclude"
      isDark={isDark}
      maxLines={maxLines}
      title={title}
    >
      <FontAwesomeIcon icon={icon} width="1.6rem" />
      {getValue(type, value, href, id, topOffset, leftOffset)}
    </StyledItem>
  ) : null
}

export default Item
