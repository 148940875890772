import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { isEnterKeyCode } from 'utils/functions/keyboardEvents'

import styles from './Tabs.module.scss'

const TabItem = ({ title, icon, isActive, onClick, showTitles }) => {
  return (
    <div
      className={classNames(styles.tabItem, {
        [styles.activeIndicator]: isActive,
      })}
    >
      <div
        className={classNames(styles.title, { [styles.titleActive]: isActive })}
        role="button"
        tabIndex="0"
        onKeyDown={(event) => {
          if (isEnterKeyCode(event)) onClick(event)
        }}
        onClick={onClick}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        <span className={classNames({ [styles.titleSpan]: !!icon })}>
          {showTitles && title}
        </span>
      </div>
      <div className={styles.indicator} />
    </div>
  )
}

TabItem.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.array,
  showTitles: PropTypes.bool,
}

TabItem.defaultProps = {
  icon: null,
  showTitles: true,
}

export default TabItem
