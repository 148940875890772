import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const EditMessageActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8rem;
  button {
    height: 2.9rem;
    padding: 0rem 1.2rem;
  }
`

export const EditInputWrapper = styled.div`
  width: 100%;
  textarea {
    width: 100%;
    min-height: 6rem;
    background-color: transparent;
    font-size: 1.4rem;
    &:focus {
      outline: none;
    }
  }
`
export const EditMessageContainer = styled.div`
  width: 100%;
  border: 1px solid ${color('primaryBlue')};
  border-radius: 8px;
  padding: 1rem;
  background: white;
  position: relative;
`

export const EmojiPickerContainer = styled.div<{ threadList?: boolean }>`
  z-index: 3;
  position: absolute;
  bottom: 4.5rem;
  ${(props) =>
    props.threadList
      ? css`
          left: -3.2rem;
        `
      : css`
          right: 1rem;
        `};
`
