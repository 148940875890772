import { FormattedMessage } from 'react-intl'
import { SortCriteria } from 'components/SortBy'
import { OrderByProps } from 'utils/types/files'
import { SortDirection } from 'utils/constants'

import {
  DefaultImage,
  DefaultPdf,
  DefaultDocument,
  DefaultSpreadSheet,
  DefaultPresentation,
  DefaultAudio,
  DefaultVideo,
  DefaultCompressed,
  DefaultEmail,
  DefaultOthers,
} from 'ui/Icons'

import * as Styles from 'containers/Files/Files.styles'
import { FormatType, FormatTypeWithoutValues } from './types'

export const DEFAULT_FILES_SORT_CRITERIA: SortCriteria[] = [
  {
    id: `${OrderByProps.NAME}_${SortDirection.ASC}`,
    field: OrderByProps.NAME,
    direction: SortDirection.ASC,
    label: (
      <span>
        <FormattedMessage id="files.orderBy.name" />
        <Styles.SortDirectionLabel>(A-Z)</Styles.SortDirectionLabel>
      </span>
    ),
  },
  {
    id: `${OrderByProps.NAME}_${SortDirection.DESC}`,
    field: OrderByProps.NAME,
    direction: SortDirection.DESC,
    label: (
      <span>
        <FormattedMessage id="files.orderBy.name" />
        <Styles.SortDirectionLabel>(Z-A)</Styles.SortDirectionLabel>
      </span>
    ),
  },
  {
    id: `${OrderByProps.ADDED_DATE}_${SortDirection.DESC}`,
    field: OrderByProps.ADDED_DATE,
    direction: SortDirection.DESC,
    label: <FormattedMessage id="files.orderBy.firstDate" />,
  },
  {
    id: `${OrderByProps.ADDED_DATE}_${SortDirection.ASC}`,
    field: OrderByProps.ADDED_DATE,
    direction: SortDirection.ASC,
    label: <FormattedMessage id="files.orderBy.lastDate" />,
  },
  {
    id: `${OrderByProps.SIZE}_${SortDirection.DESC}`,
    field: OrderByProps.SIZE,
    direction: SortDirection.DESC,
    label: <FormattedMessage id="files.orderBy.largestFileFirst" />,
  },
  {
    id: `${OrderByProps.SIZE}_${SortDirection.ASC}`,
    field: OrderByProps.SIZE,
    direction: SortDirection.ASC,
    label: <FormattedMessage id="files.orderBy.smallestFileFirst" />,
  },
]

export const ALL_FORMAT_TYPES: string[] = [
  'jpg',
  'png',
  'gif',
  'tiff',
  'svg',
  'pdf',
  'doc',
  'odt',
  'rtf',
  'txt',
  'xls',
  'csv',
  'ods',
  'ppt',
  'odp',
  'mp3',
  'wav',
  'mp4',
  'avi',
  'mov',
  'wmv',
  'zip',
  'rar',
]

export const DEFAULT_FORMAT_TYPES: FormatType[] = [
  {
    id: 'all_images',
    nonSelectedIcon: <DefaultImage />,
    selectedIcon: ['fas', 'check'],
    label: <FormattedMessage id="files.fileExtensionLabels.images" />,
    values: ['jpg', 'png', 'gif', 'tiff', 'svg'],
    active: false,
  },
  {
    id: 'all_pdf',
    nonSelectedIcon: <DefaultPdf />,
    selectedIcon: ['fas', 'check'],
    label: <FormattedMessage id="files.fileExtensionLabels.pdf" />,
    values: ['pdf'],
    active: false,
  },
  {
    id: 'all_documents',
    nonSelectedIcon: <DefaultDocument />,
    selectedIcon: ['fas', 'check'],
    label: <FormattedMessage id="files.fileExtensionLabels.documents" />,
    values: ['doc', 'odt', 'rtf', 'txt'],
    active: false,
  },
  {
    id: 'all_spreadsheets',
    nonSelectedIcon: <DefaultSpreadSheet />,
    selectedIcon: ['fas', 'check'],
    label: <FormattedMessage id="files.fileExtensionLabels.spreadsheets" />,
    values: ['xls', 'csv', 'ods'],
    active: false,
  },
  {
    id: 'all_presentations',
    nonSelectedIcon: <DefaultPresentation />,
    selectedIcon: ['fas', 'check'],
    label: <FormattedMessage id="files.fileExtensionLabels.presentations" />,
    values: ['ppt', 'odp'],
    active: false,
  },
  {
    id: 'all_audios',
    nonSelectedIcon: <DefaultAudio />,
    selectedIcon: ['fas', 'check'],
    label: <FormattedMessage id="files.fileExtensionLabels.audios" />,
    values: ['mp3', 'wav'],
    active: false,
  },
  {
    id: 'all_videos',
    nonSelectedIcon: <DefaultVideo />,
    selectedIcon: ['fas', 'check'],
    label: <FormattedMessage id="files.fileExtensionLabels.videos" />,
    values: ['mp4', 'avi', 'mov', 'wmv'],
    active: false,
  },
  {
    id: 'all_compressed',
    nonSelectedIcon: <DefaultCompressed />,
    selectedIcon: ['fas', 'check'],
    label: 'Compressed',
    values: ['zip', 'rar'],
    active: false,
  },
]

export const DEFAULT_EMAIL_FORMAT_TYPES: FormatTypeWithoutValues = {
  id: 'all_emails',
  nonSelectedIcon: <DefaultEmail />,
  selectedIcon: ['fas', 'check'],
  label: <FormattedMessage id="files.fileExtensionLabels.emails" />,
  active: false,
}

export const DEFAULT_OTHERS_FORMAT_TYPES: FormatTypeWithoutValues = {
  id: 'all_others',
  nonSelectedIcon: <DefaultOthers />,
  selectedIcon: ['fas', 'check'],
  label: <FormattedMessage id="files.fileExtensionLabels.others" />,
  active: false,
}
