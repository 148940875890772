import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import CircularProgressBar from 'components/CircularProgressBar'
import { SideBarItemContainer } from 'layouts/SideBarComponents/SideBarItem/SideBarItem.styles'
import PulseIndicator from 'components/PulseIndicator'
import {
  getUniversityProgress,
  getIsUniversityCompleted,
} from 'selectors/university'
import {
  UniversityButtonContainer,
  ButtonContent,
  Text,
  PulseWrapper,
} from './UniversityButton.styles'

const UniversityButton = ({ handleOpenUniversity, isUniversityOpen }) => {
  const intl = useIntl()

  const isUniversityCompleted = useAppSelector(getIsUniversityCompleted)
  const universityProgress = useAppSelector(getUniversityProgress)

  return (
    <UniversityButtonContainer>
      <SideBarItemContainer
        as="button"
        onClick={handleOpenUniversity}
        $isSelected={isUniversityOpen}
      >
        <ButtonContent>
          <CircularProgressBar
            isActive={isUniversityOpen}
            percentage={universityProgress}
          />
          <Text>
            {isUniversityCompleted
              ? intl.messages['university.progressCompleted']
              : intl.messages['university.progress']}
          </Text>
        </ButtonContent>
        {!universityProgress && (
          <PulseWrapper>
            <PulseIndicator />
          </PulseWrapper>
        )}
      </SideBarItemContainer>
    </UniversityButtonContainer>
  )
}

UniversityButton.propTypes = {
  handleOpenUniversity: PropTypes.func.isRequired,
  isUniversityOpen: PropTypes.bool.isRequired,
}

export default UniversityButton
