import type { IntlShape } from 'react-intl'
import {
  AccountingReportType,
  PeriodType,
  BalanceDateType,
  ReportDatePeriodType,
} from 'utils/constants/accounting'
import { Integrations } from 'utils/constants/integrations'
import { TransactionInstruments } from 'utils/constants/transactionInstruments'
import {
  CalculationBasisTypes,
  DividendTypes,
  TransactionTypes,
} from 'utils/constants/transactionTypes'
import { ScheduleRepetitionType } from 'utils/constants/scheduleRepetition'
import { LogType } from 'containers/Logs/models/types'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { AssociationType } from 'utils/constants/investorManagement'

import {
  Image,
  Nullable,
  PlainUpdateType,
  SuggestedUpdateType,
  UpdateType,
  UpdateVisibilityType,
  ValueOf,
} from './common'
import { Company, Holding } from './company'
import { IndexFundPortfolio, Portfolio, PortfolioCompany } from './portfolios'

import { Group, User } from './user'
import { PlainSubjectMatters, UpdateEditStatus } from '../constants/updates'
import { IndexInvestmentVehicle, IndexInvestor } from './investors'
import { TransactionFundTypes, UpdateAttachment } from './transactions'
import { AttachedEmailFileContent, FileContent, MixedContent } from './files'

export interface LoggingUpdateReshare {
  id: string
  createdAt: Date
  updatedAt: Date
  message?: string
  userId: string
  userName: string
  groupId: string
  groupName: string
  setToken: string
  sharedWithId: string
  sharedWithName: string
}

export interface LoggingUpdateEntity {
  id: string
  createdAt: Date
  updatedAt: Date
  loggingReshares: LoggingUpdateReshare[]
  original: boolean
}

export interface LoggingUpdateGroup extends LoggingUpdateEntity {
  groupableId: string
  groupName: string
  groupLogo: Image
}

export interface LoggingUpdateUser extends LoggingUpdateEntity {
  userId: string
  userName?: string
  userEmail: string
  userImage: Image
}

export interface BaseTag {
  id: string
  name: string
  groupId: string
}

export interface Tag extends BaseTag {
  taggingId: string
  destroy?: boolean
  alreadyExists?: boolean
}

export interface ScheduledUpdate {
  id: string
  createdAt: Date
  title: string
  updateType: UpdateType
  visibility: UpdateVisibilityType
  date: Date
  scheduleDates: Date[]
  repetition?: ScheduleRepetitionType
  loggingUpdateGroups: LoggingUpdateGroup[]
  loggingUpdateUsers: LoggingUpdateUser[]
  draftHash?: DraftHash
  isDraftUpdate: boolean
  isEditBlocked: boolean
}

export interface LoggingUpdateSchedule {
  id: string
  scheduleDate: Date
  repetition?: ScheduleRepetitionType
}

export interface BasicUpdatableAttributes {
  text?: string
  title?: string
  date: Date
  investor?: IndexInvestor
  associationType?: ValueOf<typeof AssociationType>
}

export interface TransactionUpdatableAttributes
  extends BasicUpdatableAttributes {
  amount?: number
  amountCommitted?: number
  amountDistributed?: number
  amountInvested?: number
  userId: string
  drawdownAgainstCommitment?: boolean
  transactionType?: TransactionType
  instrumentType?: TransactionInstrumentType
  instrument?: TransactionInstrument
  transactionFundType?: TransactionFundTypes
  portfolioVehicleInvestorTransactionAttributes?: PortfolioVehicleInvestorTransaction
}

export type DraftEditor = {
  id: string
  name: string
}

export type DraftHashEditor = {
  userEditing: DraftEditor
}

export type DraftHashAttributes<
  T extends BasicUpdatableAttributes = BasicUpdatableAttributes
> = {
  blocked: boolean
  confidential: boolean
  contents: MixedContent[]
  date: Date
  loggingUpdateGroups: LoggingUpdateGroup[]
  loggingUpdateUsers: LoggingUpdateUser[]
  ownedByGroup: boolean
  tags: BaseTag[]
  updatableAttributes: T
  visibility: UpdateVisibilityType
  investmentVehicle?: IndexInvestmentVehicle
  investor?: IndexInvestor
  holding?: Holding
  fundPortfolio?: IndexFundPortfolio
  portfolios?: Portfolio[]
}

export const isDraftHashAttributes = (
  draftHash: DraftHash
): draftHash is DraftHashAttributes => {
  return (draftHash as DraftHashAttributes).updatableAttributes !== undefined
}

type EmptyObject = {
  [K in any]: never
}

export type DraftHashData<
  T extends BasicUpdatableAttributes = BasicUpdatableAttributes
> = {
  draftHash?: DraftHash<T>
  isDraftUpdate: boolean
  isEditBlocked: boolean
}

export type DraftHash<
  T extends BasicUpdatableAttributes = BasicUpdatableAttributes
> = DraftHashAttributes<T> | DraftHashEditor | EmptyObject

export interface MixedUpdate {
  id: string
  createdAt: Date
  updatedAt: Date
  date: Date
  contents: MixedContent[]
  draft: boolean
  scheduled: boolean
}

export interface IndexUpdate extends MixedUpdate {
  loggingUpdateGroups: LoggingUpdateGroup[]
  loggingUpdateUsers: LoggingUpdateUser[]
  loggingUpdateSchedule?: LoggingUpdateSchedule
  visibility: UpdateVisibilityType
  type: PlainUpdateType
  updateType: UpdateType
  attachableCount: number
  tags: Tag[]
  item: UpdateItem
  userId: string
  ownedByGroup: boolean
  isPortfolioUpdate: boolean
  fundPortfolio?: IndexFundPortfolio
  taggings: {
    id: string
    tag: BaseTag
  }[]
  /**
   * @deprecated The `companyDatum` should not be used anymore. Use `holding` instead.
   */
  companyDatum?: Company
  holding?: Holding
  holdingDraft?: Holding
  portfolio?: Portfolio
  updatableStatus: UpdateEditStatusType
  expirationDate?: string
  confidential: boolean
  blocked: boolean
  draftHash: DraftHash
  isDraftUpdate: boolean
  isEditBlocked: boolean
  groupId: string
  published: boolean
}

export type SuggestedUpdateData = {
  id: string
  text: string
  type: SuggestedUpdateType
  title: string
  inbound: string
  sender: string
  receivers: string[]
  holdingId?: string
}

export interface SuggestedUpdate extends MixedUpdate {
  data: SuggestedUpdateData
  contents: AttachedEmailFileContent[]
}

export interface Update extends IndexUpdate {
  blocked: boolean
  date: Date
  group: Group
  groupId: string
  loggingUpdateGroups: LoggingUpdateGroup[]
  loggingUpdateUsers: LoggingUpdateUser[]
  portfolios?: Portfolio[]
  investorGroup?: string
  investor?: IndexInvestor

  ownedByGroup: boolean
  resharesCount: number
  totalViews: number
  type: PlainUpdateType
  uniqueViews: number
  user: User
  userId: string
  userName: string
  eventLogType: LogType
  attachments: UpdateAttachment[]
  edited: boolean
}

export interface UpdateItem {
  id: string
  createdAt: Date
  updatedAt: Date
  subjectMatterType?: ValueOf<typeof PlainSubjectMatters>
  investor?: IndexInvestor
  fundPortfolioId?: string
  title: string
  text: string
  loggingUpdate: {
    draftHash?: DraftHash
  }
  transactionFundType?: TransactionFundTypes
  associationType?: ValueOf<typeof AssociationType>
}

// TODO: Move text, title and date to common interface and make them nullable (for drafts)
export interface DocumentItem extends UpdateItem {
  text: string
  title: string
  date: Date
}

export interface NoteItem extends UpdateItem {
  text: string
  title: string
  date: Date
}

export interface AnnouncementItem extends UpdateItem {
  text: string
  title: string
  date: Date
}

export interface ReportRecipient {
  id: string
  createdAt: Date
  updatedAt: Date
  recipientId: string
  recipientType: 'Group' | 'User'
}

export interface IUEReportItem extends UpdateItem {
  businessCompanyDatumId: string
  clientId: string
  entriesRawText: string
  fromName: string
  replyTo: string
  reportRecipients: ReportRecipient[]
  sentAt: Date
  sentToCount: number
  subject: string
  teamId: string
  url: string
  userId: string
}

export type TransactionInstrumentType = ValueOf<typeof TransactionInstruments>
export type TransactionType = ValueOf<typeof TransactionTypes>
export type CalculationBasis = ValueOf<typeof CalculationBasisTypes>
export type DividendType = ValueOf<typeof DividendTypes>
export type UpdateEditStatusType = ValueOf<typeof UpdateEditStatus>
export interface PortfolioVehicleInvestorTransaction {
  portfolioVehicleInvestorTransactionId: string
  fundPortfolioInvestorId: string
  investmentVehicleInvestorId: string
  fundPortfolioId: string
  id?: string
}
export interface TransactionInstrument {
  valuationCap?: number
  discountRate?: number
  interestRate?: number
  interestCalculationBasis?: CalculationBasis
  maturityDate?: Date
  purchasePricePerShare?: number
  sharesPurchased?: number
  preMoneyValuation?: number
  postMoneyValuation?: number
  annualManagementFee?: number
  carry?: number
  carryHurdleRate?: number
  dividend?: number
  dividendCalculationBasis?: CalculationBasis
  dividendType?: DividendType
  vestingCommencementDate?: Date
  expirationDate?: Date
  strikePrice?: number
  numberOfShares?: number
}
export interface TransactionItem extends UpdateItem {
  amountDistributed?: number
  amountCommitted?: number
  amountInvested?: number
  amount: number
  attachableCount: number
  date: Date
  text: string
  title: string
  userId: string
  drawdownAgainstCommitment: boolean
  transactionType: TransactionType
  instrumentType?: TransactionInstrumentType
  instrument?: TransactionInstrument
  portfolioNamesAndTypes: {
    name: string
    type: PortfolioTypes
  }[]
  investmentVehicle?: IndexInvestmentVehicle
  investor?: IndexInvestor
  transactionFundType: TransactionFundTypes
  portfolioVehicleInvestorTransaction?: PortfolioVehicleInvestorTransaction
  loggingUpdateId: string
  holdingId?: string
}

export type IntegrationType = ValueOf<typeof Integrations>

export interface AccountingItem extends UpdateItem {
  integrationType: IntegrationType
  reportData: {
    date: string
    paymentsOnly: boolean
    periods: PeriodType
    standardLayout: boolean
    timeFrame: string
    balanceDate: BalanceDateType
    reportDatePeriod: ReportDatePeriodType
  }
  reportType: AccountingReportType
  title: string
  rawContent: any
  repetition: ScheduleRepetitionType
}

export interface DocumentUpdate extends Update {
  item: DocumentItem
}

export interface EmailUpdate extends Update {
  draft: boolean
  scheduled: boolean
  item: IUEReportItem
  contents: FileContent[]
}

export interface EmailIndexUpdate extends IndexUpdate {
  draft: boolean
  scheduled: boolean
  item: IUEReportItem
}

export interface NoteUpdate extends Update {
  item: NoteItem
}

export interface AnnouncementUpdate extends Update {
  item: AnnouncementItem
}

type TransactionPortfolio = Portfolio & {
  portfolioCompany: PortfolioCompany
}

export interface TransactionUpdate extends Update {
  item: TransactionItem
  portfolios: TransactionPortfolio[]
  fundPortfolio?: IndexFundPortfolio
}

export interface AccountingUpdate extends Update {
  item: AccountingItem
}

export interface NavigationUpdate {
  updateId: string
  updateType: UpdateType
  holding?: Holding
  isDraftUpdate?: boolean
}

export interface UpdatesReport {
  thisMonth: number
  lastMonth: number
  growth: Nullable<boolean>
}

export interface TotalUpdatesReport {
  totalUpdates: Nullable<UpdatesReport>
  announcements: Nullable<UpdatesReport>
  documents: Nullable<UpdatesReport>
  notes: Nullable<UpdatesReport>
  reports: Nullable<UpdatesReport>
  transactions: Nullable<UpdatesReport>
  accountingReports: Nullable<UpdatesReport>
}

export enum ScheduleType {
  SEND_NOW = 'SEND_NOW',
  SCHEDULE = 'SCHEDULE',
}

export enum SubjectType {
  COMPANY = 'company',
  PORTFOLIO = 'portfolio',
}

export type BasicUpdate = NoteUpdate | DocumentUpdate | AnnouncementUpdate
export type DraftableUpdate = BasicUpdate | TransactionUpdate

export interface UpdateInfoItem {
  lastUpdateDate: string
  totalUpdates: number
  totalNotes: number
  totalDocuments: number
  totalAnnouncements: number
  totalEmails: number
  createdByGroup: number
  sharedWithGroup: number
  logo: Nullable<Image> | string
  type: string
  name: string
}

export interface UpdateInfoItemWithId extends UpdateInfoItem {
  id: string
}

export interface UpdatesTableInfo {
  updatesGroupedByEntity: {
    [key: string]: UpdateInfoItem
  }
}

export interface VisibleUpdateTableColumns {
  updatesTabLastUpdateDate: boolean
  updatesTabTotalUpdates: boolean
  updatesTabTotalNotes: boolean
  updatesTabTotalDocuments: boolean
  updatesTabTotalAnnouncements: boolean
  updatesTabTotalEmails: boolean
  updatesTabCreatedByGroup: boolean
  updatesTabSharedWithGroup: boolean

  updatesTabLastUpdateDateOrder: number
  updatesTabTotalUpdatesOrder: number
  updatesTabTotalNotesOrder: number
  updatesTabTotalDocumentsOrder: number
  updatesTabTotalAnnouncementsOrder: number
  updatesTabTotalEmailsOrder: number
  updatesTabCreatedByGroupOrder: number
  updatesTabSharedWithGroupOrder: number
}

export type ColumnOptionsId = keyof VisibleUpdateTableColumns

export interface ColumnOption {
  id: ColumnOptionsId
  label: string
  status: boolean
}

export const initialColumnsOrderState = ({ intl }: { intl: IntlShape }) => [
  {
    id: 'updatesTabLastUpdateDate',
    label: intl.formatMessage({
      id: 'updates.tableColumns.lastUpdateDate',
    }),
    status: true,
    order: {
      index: 1,
      name: 'updatesTabLastUpdateDateOrder',
    },
  },
  {
    id: 'updatesTabTotalUpdates',
    label: intl.formatMessage({
      id: 'updates.tableColumns.totalNumberOfUpdates',
    }),
    status: true,
    order: {
      index: 2,
      name: 'updatesTabTotalUpdatesOrder',
    },
  },
  {
    id: 'updatesTabTotalNotes',
    label: intl.formatMessage({
      id: 'updates.tableColumns.totalNumberOfNotes',
    }),
    status: true,
    order: {
      index: 3,
      name: 'updatesTabTotalNotesOrder',
    },
  },
  {
    id: 'updatesTabTotalDocuments',
    label: intl.formatMessage({
      id: 'updates.tableColumns.totalNumberOfDocuments',
    }),
    status: true,
    order: {
      index: 4,
      name: 'updatesTabTotalDocumentsOrder',
    },
  },
  {
    id: 'updatesTabTotalAnnouncements',
    label: intl.formatMessage({
      id: 'updates.tableColumns.totalNumberOfAnnouncements',
    }),
    status: true,
    order: {
      index: 5,
      name: 'updatesTabTotalAnnouncementsOrder',
    },
  },
  {
    id: 'updatesTabTotalEmails',
    label: intl.formatMessage({
      id: 'updates.tableColumns.totalNumberOfEmails',
    }),
    status: true,
    order: {
      index: 6,
      name: 'updatesTabTotalEmailsOrder',
    },
  },
  {
    id: 'updatesTabCreatedByGroup',
    label: intl.formatMessage({
      id: 'updates.tableColumns.numberCreatedByGroup',
    }),
    status: true,
    order: {
      index: 7,
      name: 'updatesTabCreatedByGroupOrder',
    },
  },
  {
    id: 'updatesTabSharedWithGroup',
    label: intl.formatMessage({
      id: 'updates.tableColumns.numberSharedWithGroup',
    }),
    status: true,
    order: {
      index: 8,
      name: 'updatesTabSharedWithGroupOrder',
    },
  },
]
