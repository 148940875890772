import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useInvestmentsFiltersContext } from 'containers/Investments/InvestmentContext'
import { BaseOption, EntityId } from 'containers/Investments/types'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'

import Toggle from 'components/Toggle'
import OptionsDropdown from 'ui/OptionsDropdown'
import IconButton from 'ui/IconButton'
import theme from 'utils/theme'

import * as Styles from './NavigationActions.styles'

interface NavigationActionsProps {
  visiblePortfolioTypeFilterOptions: BaseOption[]
  openFilterDrawer: () => void
}

const NavigationActions: React.FC<NavigationActionsProps> = ({
  visiblePortfolioTypeFilterOptions,
  openFilterDrawer,
}) => {
  const {
    displayBigPortfolioNavigation,
    showPortfolioTypeFilterButton,
    hasPortfolioTypesFiltersApplied,
    fetchedPortfolios,
    hasFiltersAppliedOnInProcessFilter,
    filterInProcessCount,
    resetPortfolioTypeFilter,
    toggleEntityTypeFilter,
    setDisplayBigPortfolioNavigation,
  } = useInvestmentsFiltersContext()

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  // TODO LP PROFILE: remove this if when the filters are implemented for the investor group
  if (isInvestorGroup) {
    return (
      <Styles.ActionsIconWrapper>
        <Styles.ActionsWrapper>
          <FontAwesomeIcon
            onClick={() =>
              setDisplayBigPortfolioNavigation(!displayBigPortfolioNavigation)
            }
            size="2x"
            icon={[
              'far',
              displayBigPortfolioNavigation ? 'chevron-up' : 'chevron-down',
            ]}
            style={{ cursor: 'pointer' }}
          />
        </Styles.ActionsWrapper>
      </Styles.ActionsIconWrapper>
    )
  }

  return (
    <Styles.PortfolioBigNavigationActions>
      <Styles.ActionsWrapper>
        <Styles.ActionsIconWrapper>
          <FontAwesomeIcon
            onClick={() =>
              setDisplayBigPortfolioNavigation(!displayBigPortfolioNavigation)
            }
            size="2x"
            icon={[
              'fal',
              displayBigPortfolioNavigation ? 'chevron-up' : 'chevron-down',
            ]}
            style={{ cursor: 'pointer' }}
          />
        </Styles.ActionsIconWrapper>

        {showPortfolioTypeFilterButton && (
          <Styles.HideAndShowIconWrapper>
            {hasPortfolioTypesFiltersApplied && (
              <Styles.ClearFiltersBadge>
                <FontAwesomeIcon
                  onClick={resetPortfolioTypeFilter}
                  icon={['far', 'times']}
                  style={{ cursor: 'pointer' }}
                  color={theme.colors.primaryBlue}
                />
              </Styles.ClearFiltersBadge>
            )}
            <OptionsDropdown
              buttonSize="3rem"
              buttonType="square"
              icon={['fal', 'eye']}
              attachToDocument={false}
              onClick={() => {}}
              isActive={hasPortfolioTypesFiltersApplied}
            >
              {visiblePortfolioTypeFilterOptions.map((option) => (
                <OptionsDropdown.Item
                  key={option.id}
                  label={option.label}
                  closeOnSelectOption={false}
                  component={
                    <Toggle
                      id={option.id}
                      status={option.status!}
                      blue
                      name={option.id}
                      disableAnimationOnMount
                      color={theme.colors.green}
                    />
                  }
                  onSelectOption={() =>
                    toggleEntityTypeFilter(option.id as EntityId)
                  }
                />
              ))}
            </OptionsDropdown>
          </Styles.HideAndShowIconWrapper>
        )}

        {!!fetchedPortfolios?.length && (
          <Styles.HideAndShowIconWrapper>
            {hasFiltersAppliedOnInProcessFilter && (
              <Styles.FiltersCountBadge>
                {filterInProcessCount}
              </Styles.FiltersCountBadge>
            )}
            <IconButton
              onClick={openFilterDrawer}
              border={false}
              iconFamily="fal"
              icon="filter"
              buttonSize="3rem"
              buttonType="square"
              color={
                hasFiltersAppliedOnInProcessFilter
                  ? theme.colors.primaryBlue
                  : theme.colors.darkBlue
              }
              isActive={hasFiltersAppliedOnInProcessFilter}
              activeStyles={Styles.activeMenuButtonStyles()}
            />
          </Styles.HideAndShowIconWrapper>
        )}
      </Styles.ActionsWrapper>
    </Styles.PortfolioBigNavigationActions>
  )
}

export default NavigationActions
