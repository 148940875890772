import styled, { css } from 'styled-components'

export const TableHeader = styled.div<{ embeddedTable?: boolean }>`
  z-index: 1;
  color: #787e8c;
  display: flex;
  align-items: center;
  height: 4.5rem;
  font-weight: bold;
  top: 0;

  font-weight: 800;
  font-size: 1.1rem;

  ${(props) =>
    props.embeddedTable &&
    css`
      padding: 1rem 1.5rem;
      border-bottom: 0.2rem solid white;
    `}
`

export const FixedHeaderCellContainer = styled.div<{
  borderWidth?: string
  hideBorder?: boolean
  embeddedTable?: boolean
}>`
  position: sticky;
  left: 3.65rem;
  background: white;
  display: flex;
  height: 100%;
  gap: 0.5rem;
  border-right: 0.2rem solid #e9e9e9;
  padding-left: ${(props) => (props.embeddedTable ? '7.1rem' : '3.3rem')};
  transform: translateX(-3.65rem);
  ${(props) =>
    !props.hideBorder &&
    css`
    border-bottom: ${props.borderWidth || '0.1rem'} solid #e9e9e9}
    `}
`
