import styled from 'styled-components/macro'
import { css } from 'styled-components'
import FileDropperToStyle from 'containers/Files/FileDropper'
import { color } from 'utils/functions/colors'
import { Scroll } from 'App.styles'

export const Container = styled.div``

export const FileDropper = styled(FileDropperToStyle)<{
  hasUploads: boolean
}>`
  position: relative;
  height: 39.6rem;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.2rem dashed ${color('veryLightBlue')};
  transform: initial;
  justify-content: center;

  ${({ hasUploads }) =>
    hasUploads &&
    css`
      height: 12.4rem;
      transition: none;
      transition-delay: 0s;
    `}
`

export const DropFileMessage = styled.div`
  padding: 1.6rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkGray')};
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  box-shadow: 0rem 0rem 0.8rem 0rem ${color('darkBlue', 0.05)};
`

export const BrowseYourFiles = styled.span`
  border-bottom: 0.1rem solid ${color('darkGray')};
  cursor: pointer;
`

export const FileUploaderInput = styled.input`
  display: none;
`

export const UploadsContainer = styled.div`
  ${Scroll}
  display: flex;
  flex-direction: column;
  max-height: 21rem;
`

export const UploadingFiles = styled.div`
  color: ${color('darkGray')};
  font-size: 1.8rem;
  margin: 2.4rem 0 1.6rem 0;
`
