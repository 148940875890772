import { AuthHeaders } from 'utils/types/signUp'
import { Group, InvestorGroup, User, UserSession } from 'utils/types/user'
import axiosClient, { buildSessionHeaders } from './httpClient'

interface BodyType {
  idToken?: string
  confirmationToken?: string
  email?: string
}

type UpdateTermsOfUse = {
  userSession?: UserSession
  authHeaders?: AuthHeaders
} & (
  | {
      isGoogleSignIn: true
      userCredential: string
    }
  | {
      isGoogleSignIn: false
      userCredential: { token?: string; email?: string }
    }
)

class AuthService {
  static getUser = (id) => {
    return axiosClient().get(`/users/${id}`)
  }

  static getMyData = async (): Promise<{
    user: User
    groups: Record<string, Group>
    investorGroups: Record<string, InvestorGroup>
  }> => {
    const response = await axiosClient().get(`/users/me`)
    const user = response.data.entities.users[response.data.result]
    const { groups, investorGroups } = response.data.entities
    return { user, groups, investorGroups }
  }

  static resetPassword = (userData) => {
    return axiosClient().post(
      `auth/password?email=${userData.email.replace('+', '%2B')}&redirect_url=${
        window.location.origin
      }/password-change`
    )
  }

  static getChangePasswordTicket = async (): Promise<string> => {
    const response = await axiosClient().get<{ ticket: string }>(
      '/users/password_change_ticket'
    )
    return response.data.ticket
  }

  static getMFAUrl = async (): Promise<string> => {
    const response = await axiosClient().get('/two_factor_settings/new')
    return response.data.ticketUrl
  }

  static getRecoveryCode = async (): Promise<string> => {
    const response = await axiosClient().get(
      '/two_factor_settings/recovery_code'
    )
    return response.data.recoveryCode
  }

  static getMfaEnrollments = async (): Promise<
    {
      id: string
      status: string
      enrolled_at: Date
      last_auth: Date
      type: string
      auth_method: string
    }[]
  > => {
    const response = await axiosClient().get('/two_factor_settings/enrollments')
    if (response.data?.length > 0) {
      return response.data
    }

    return []
  }

  static sendSignUpConfirmationEmail = async () => {
    return axiosClient().post(`users/send_confirmation`)
  }

  static updateTermsOfUse = ({
    isGoogleSignIn,
    userCredential,
    userSession,
    authHeaders,
  }: UpdateTermsOfUse) => {
    const body: BodyType = {}
    const headers = authHeaders || buildSessionHeaders(userSession)

    if (isGoogleSignIn) {
      body.idToken = userCredential
    } else if (userCredential.token) {
      body.confirmationToken = userCredential.token
    } else if (userCredential.email) {
      body.email = userCredential.email
    }
    return axiosClient(null, headers).post('/users/accept_terms_of_use', body)
  }

  static getUserAccountByToken = (confirmationToken) => {
    return axiosClient().post('/auth/confirmation_token', {
      confirmationToken,
    })
  }
}

export default AuthService
