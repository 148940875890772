import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Badge = styled.div<{ perfectCircle?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${color('green')};
  border-radius: 1rem;
  padding: 0 0.3rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.03em;
  color: white;

  ${({ perfectCircle }) =>
    perfectCircle
      ? css`
          min-width: 1.8rem;
          min-height: 1.8rem;
        `
      : css`
          padding: 0.3rem 0.8rem;
        `}
`
