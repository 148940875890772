import { Tier } from 'utils/types/tier'
import { NO_LIMIT } from 'utils/constants/tier'
import { SubscriptionPlan } from 'utils/types/subscriptions'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { useAppSelector } from './reduxToolkit'
import { useGroupTierQuery } from './queries/useGroupQuery'

const getUsersLimit = (tier: Tier) => {
  if (tier.maxUsers === NO_LIMIT) {
    return {
      availableUsers: NO_LIMIT,
      usedUsers: 1,
      hasUsersAvailable: true,
      maxUsers: NO_LIMIT,
    }
  }

  return {
    availableUsers: tier.maxUsers - tier.users,
    usedUsers: tier.users,
    hasUsersAvailable: tier.users < tier.maxUsers,
    maxUsers: tier.maxUsers,
  }
}

const getPipelinesLimit = (tier: Tier) => {
  if (tier.maxTrackPortfolios === NO_LIMIT) {
    return {
      availablePipelines: NO_LIMIT,
      usedPipelines: tier.trackPortfolios,
      hasPipelinesAvailable: true,
      maxPipelines: NO_LIMIT,
    }
  }

  return {
    availablePipelines: tier.maxTrackPortfolios - tier.trackPortfolios,
    hasPipelinesAvailable: tier.maxTrackPortfolios > tier.trackPortfolios,
    usedPipelines: tier.trackPortfolios,
    maxPipelines: tier.maxTrackPortfolios,
  }
}

const getPortfolioTransactionsLimit = (tier: Tier, hasAPortfolio?: boolean) => {
  if (hasAPortfolio && tier.maxTransactions !== NO_LIMIT) {
    return {
      availableTransactions: tier.maxTransactions - tier.transactions,
      usedTransactions: tier.transactions,
      hasTransactionsAvailable: tier.maxTransactions > tier.transactions,
      maxTransactions: tier.maxTransactions,
    }
  }

  return {
    availableTransactions: NO_LIMIT,
    usedTransactions: tier.transactions,
    hasTransactionsAvailable: true,
    maxTransactions: NO_LIMIT,
  }
}

const getInvestPortfoliosLimit = (tier: Tier) => {
  if (tier.maxInvestPortfolios === NO_LIMIT) {
    return {
      availableInvestPortfolios: NO_LIMIT,
      usedInvestPortfolios: tier.investPortfolios,
      hasInvestPortfoliosAvailable: true,
      maxInvestPortfolios: NO_LIMIT,
    }
  }

  return {
    availableInvestPortfolios: tier.maxInvestPortfolios - tier.investPortfolios,
    usedInvestPortfolios: tier.investPortfolios,
    hasInvestPortfoliosAvailable:
      tier.maxInvestPortfolios > tier.investPortfolios,
    maxInvestPortfolios: tier.maxInvestPortfolios,
  }
}

const getStorageLimit = (tier: Tier) => {
  return {
    availableSpaceInMb: tier.maxStorageInMb - tier.storageInMb,
    maxStorageInMb: tier.maxStorageInMb,
    totalUsedInMb: tier.storageInMb,
  }
}

export type PlanFeatures = ReturnType<typeof usePlanFeaturesUntyped>

const usePlanFeaturesUntyped = () => {
  const currentPortfolio = useAppSelector(getCurrentPortfolio)
  const { tier } = useGroupTierQuery()

  const groupPlan = tier.plan

  const usersLimit = getUsersLimit(tier)
  const pipelinesLimit = getPipelinesLimit(tier)
  const portfolioTransactionsLimit = getPortfolioTransactionsLimit(
    tier,
    !!currentPortfolio
  )
  const investPortfoliosLimit = getInvestPortfoliosLimit(tier)
  const storageLimit = getStorageLimit(tier)

  return {
    groupPlan,
    showPortfolioLimitationMessage: groupPlan === SubscriptionPlan.LITE,
    showTransactionLimitationMessage: groupPlan === SubscriptionPlan.LITE,
    showHelpArticles: groupPlan === SubscriptionPlan.LITE,
    showOnboardingModal: groupPlan === SubscriptionPlan.LITE,
    areMetricsEnabled: groupPlan !== SubscriptionPlan.LITE,
    arePerformanceMetricsEnabled: groupPlan !== SubscriptionPlan.LITE,
    hasOnlyDefaultInbound: groupPlan === SubscriptionPlan.LITE,
    isIndividualWithOnlyOneMaxUser:
      groupPlan === SubscriptionPlan.INDIVIDUAL && tier.maxUsers === 1,
    isGroupManagementEnabled: tier.maxUsers > 1,
    isUsersTableVisible: tier.maxUsers > 1,
    updates: {
      isOwnGroupSharingEnabled: tier.maxUsers > 1,
    },
    isUniversityEnabled: groupPlan !== SubscriptionPlan.LITE,
    ...usersLimit,
    ...pipelinesLimit,
    ...portfolioTransactionsLimit,
    ...investPortfoliosLimit,
    hasPortfoliosAvailable:
      pipelinesLimit.hasPipelinesAvailable ||
      investPortfoliosLimit.hasInvestPortfoliosAvailable,
    ...storageLimit,
  }
}

export const usePlanFeatures = usePlanFeaturesUntyped as () => PlanFeatures
