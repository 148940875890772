import React, { lazy } from 'react'
import {
  Route,
  Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import {
  getCurrentGroupType,
  isActingAsClient,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import { getPortfolioRoute } from 'utils/functions/portfolios'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import { SHOW_NEW_INVESTMENTS_FEATURE } from 'utils/constants/features'
import PaddedLayout from 'layouts/PaddedLayout'
import { getCurrentPortfolio } from 'selectors/portfolio'
import PortfolioLogs from 'components/PortfolioLogs/PortfolioLogs'
import SidebarMetricsRoutes from './SidebarMetricsRoutes'
import AccountingRoutes from './AccountingRoutes'

const Portfolios = lazy(() => import('containers/Portfolios'))
const PortfolioDetail = lazy(() => import('containers/PortfolioDetail'))
const NotesRoutes = lazy(() => import('./NotesRoutes'))
const AnnouncementsRoutes = lazy(() => import('./AnnouncementsRoutes'))
const DocumentsRoutes = lazy(() => import('./DocumentsRoutes'))
const TransactionsRoutes = lazy(() => import('./TransactionsRoutes'))
const EmailsRoutes = lazy(() => import('./EmailsRoutes'))
const FundProfile = lazy(() => import('containers/FundProfile'))
const PreviewFundProfile = lazy(() => import('containers/PreviewFundProfile'))

const PortfolioRoutes = () => {
  const isFounder = useAppSelector(isActingAsFounder)
  const currentPortfolio = useAppSelector(getCurrentPortfolio)
  const isClient = useAppSelector(isActingAsClient)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const planFeatures = usePlanFeatures()
  const history = useHistory()
  const location = useLocation()
  const locationsState = location.state || {}

  const { path: subPath } = useRouteMatch()

  if (isFounder) {
    return <Redirect from={subPath} to="/dashboard/updates" />
  }

  if (SHOW_NEW_INVESTMENTS_FEATURE && (isClient || isFundManager)) {
    const url = history.location.pathname.replace('/portfolios', '/investments')
    const editedSubpath = subPath.replace('/portfolios', '/investments')

    return (
      <Redirect
        from={editedSubpath}
        to={{ pathname: url, state: locationsState }}
      />
    )
  }

  const routes = [
    {
      path: `${subPath}/:portfolioId/notes`,
      key: 'notes',
      component: <NotesRoutes />,
    },
    {
      path: `${subPath}/:portfolioId/announcements`,
      key: 'announcements',
      component: <AnnouncementsRoutes />,
    },
    {
      path: `${subPath}/:portfolioId/documents`,
      key: 'documents',
      component: <DocumentsRoutes />,
    },
    {
      path: `${subPath}/:portfolioId/transactions`,
      key: 'transactions',
      component: <TransactionsRoutes />,
    },
    {
      path: `${subPath}/:portfolioId/accounting`,
      key: 'accounting',
      component: <AccountingRoutes />,
    },
    {
      path: `${subPath}/:portfolioId/emails`,
      key: 'emails',
      component: <EmailsRoutes />,
    },
    {
      path: [`${subPath}/:type/:fundId/portfolio/preview`],
      key: 'preview',
      exact: true,
      component: <PreviewFundProfile />,
    },
    {
      path: [
        `${subPath}/:type/:id`,
        `${subPath}/:type/:id/investors`,
        `${subPath}/:type/:id/portfolio`,
        `${subPath}/:type/:id/portfolio/metrics/:metricId`,
        `${subPath}/:type/:id/portfolio/metrics/:metricId/:something`,
        `${subPath}/:type/:id/metrics/:metricId`,
        `${subPath}/:type/:id/metrics/:metricId/:something`,
        `${subPath}/:type/:id/logs`,
        `${subPath}/:type/:id/:tab/logs`,
        `${subPath}/:type/:id/files`,
        `${subPath}/:type/:id/files/:id`,
      ],
      key: 'portfolioId',
      exact: true,
      component: <PortfolioDetail />,
    },
    {
      path: subPath,
      key: subPath,
      exact: true,
      component: <Portfolios />,
    },
    {
      path: [`${subPath}/:type/:id/logs`, `${subPath}/:type/:id/:tab/logs`],
      key: 'logs',
      component: <PortfolioLogs />,
    },
  ]

  return (
    <PaddedLayout>
      {routes.map(({ path, component, key, exact }) => (
        <Route key={key} exact={exact} path={path}>
          {component}
        </Route>
      ))}
      {planFeatures.areMetricsEnabled && (
        <>
          <Route path={`${subPath}/:type/:id/metrics/:metricId`}>
            <SidebarMetricsRoutes urlPrefix="/portfolios/:type/:id/metrics" />
          </Route>
          <Route path={`${subPath}/:type/:id/portfolio/metrics/:metricId`}>
            <SidebarMetricsRoutes urlPrefix="/portfolios/:type/:id/portfolio/metrics" />
          </Route>
        </>
      )}
    </PaddedLayout>
  )
}

export default PortfolioRoutes
