import { useCallback, useMemo, useState } from 'react'
import { useChatContext } from 'stream-chat-react'
import { Channel } from 'stream-chat'

import { getPinById } from 'containers/Chat/helpers'
import { useArchiveChannels } from 'containers/Chat/hooks/useArchiveChannels'
import { useChatTitle } from 'containers/Chat/hooks/useChatTitle'
import { useForceUpdateOnChatUserUpdated } from 'containers/Chat/hooks/useForceUpdateOnChatUserUpdated'
import { usePinChannels } from 'containers/Chat/hooks/usePinChannels'
import { useIsThreadOpen, useSetActiveChannel } from 'containers/Chat/hooks'
import { StreamChatType, isTopicChannel } from 'containers/Chat/types'
import { useChatMembers } from 'utils/hooks/useChatMembers'
import { useIsChannelMuted } from 'utils/hooks/useIsChannelMuted'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize, size } from 'utils/constants/breakpoint'
import { maximizeChatPopupById } from 'features/chatSlice'
import { useChatMobileContext } from '../ChatMobile'

export const useChatHeader = (channel: Channel<StreamChatType>) => {
  const { client } = useChatContext<StreamChatType>()
  const {
    isChannelModerator,
    members,
    membersNames,
    isUserPersonalChat,
    isDirectMessage,
  } = useChatMembers(channel)
  const setActiveChannel = useSetActiveChannel()
  const { onGoToSidebar, onChannelGoBack } = useChatMobileContext()

  const isThreadOpen = useIsThreadOpen()
  const { matches: isChatPopupEnabled } = useMediaQuery(size.xsm)
  const { matches: renderIconsOnDropdownOnSpecificScreen } = useMediaQuery(
    '(min-width: 1440px) and (max-width: 1440px)'
  )
  const { matches: renderIconsOnDropdown } = useMediaQuery(maxSize.xss)
  const hideMembers = renderIconsOnDropdownOnSpecificScreen && isThreadOpen

  const chatTitle = useChatTitle(channel)
  const { muted } = useIsChannelMuted(channel)
  const { toggleArchiveChannel, isChannelArchived, unarchiveChannel } =
    useArchiveChannels()
  const { togglePinChannel } = usePinChannels()
  const [showAddParticipantsModal, setShowAddParticipantsModal] =
    useState(false)

  const [showParticipants, setShowParticipants] = useState(false)
  const [showMuteModal, setShowMuteModal] = useState(false)
  const [showLeaveModal, setShowLeaveModal] = useState(false)

  const dispatch = useAppDispatch()

  const isPinned = useMemo(
    () => !!getPinById(channel.id!, client.user?.pinnedChannels || []),
    [channel.id, client.user?.pinnedChannels]
  )

  const isATopicChannel = useMemo(
    () => isTopicChannel(channel?.data),
    [channel?.data]
  )

  const isArchived = useMemo(
    () => isChannelArchived(channel.id),
    [channel.id, isChannelArchived]
  )

  const membersForAvatars = useMemo(
    () =>
      members.map(({ user }) => ({
        id: user!.id,
        name: user!.name!,
        image: user!.image,
      })),
    [members]
  )

  const handleMute = useCallback(() => {
    if (muted) {
      channel.unmute()
    } else {
      setShowMuteModal(true)
    }
  }, [channel, muted])

  const onUndoArchiveChannel = useCallback(async () => {
    await unarchiveChannel(channel.id!)
    setActiveChannel(channel)
  }, [channel, setActiveChannel, unarchiveChannel])

  const openChatAsPopup = useCallback(() => {
    dispatch(maximizeChatPopupById(channel.id!))
    setActiveChannel(undefined)
    onGoToSidebar()
  }, [dispatch, channel, setActiveChannel, onGoToSidebar])

  useForceUpdateOnChatUserUpdated()

  return {
    channel,
    members,
    isChannelModerator,
    chatTitle,
    isUserPersonalChat,
    isDirectMessage,
    membersNames,
    participantsModal: {
      show: showParticipants,
      onShow: () => setShowParticipants(true),
      onHide: () => setShowParticipants(false),
    },
    addParticipantsModal: {
      show: showAddParticipantsModal,
      onShow: () => setShowAddParticipantsModal(true),
      onHide: () => setShowAddParticipantsModal(false),
    },
    muteModal: {
      show: showMuteModal,
      onHide: () => setShowMuteModal(false),
    },
    leaveModal: {
      show: showLeaveModal,
      onShow: useCallback(() => setShowLeaveModal(true), []),
      onHide: useCallback(() => setShowLeaveModal(false), []),
    },
    membersForAvatars,
    handleMute,
    isChannelMuted: muted,
    isPinned,
    togglePinChannel: () => togglePinChannel(channel.id!),
    isATopicChannel,
    isArchived,
    toggleArchiveChannel,
    onUndoArchiveChannel,
    openChatAsPopup,
    unarchiveChannel,
    onChannelGoBack,
    isChatPopupEnabled,
    hideMembers,
    renderIconsOnDropdown:
      renderIconsOnDropdown || renderIconsOnDropdownOnSpecificScreen,
  }
}
