import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CWLoader from 'components/CWLoader'
import { useIntl } from 'react-intl'
import InputIcon from 'ui/InputIcon'
import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import { Holding, getHoldingImage } from 'utils/types/company'
import { useRandomId } from 'utils/hooks/useRandomId'
import theme from 'utils/theme'
import { FullCellTooltip } from 'components/Spreadsheet/CellTemplates/Template.styles'
import * as Styles from '../../../UpdateMetricsGrid/components/MetricsSpreadsheetDropdown/MetricsSpreadsheetDropdown.styles'
import { OptionWrapper } from './SpreadsheetHoldingsDropdown.styles'
import {
  SpreadsheetHoldingsDropdownProps,
  useSpreadsheetHoldingsDropdown,
} from './useSpreadsheetHoldingsDropdown'

const SpreadsheetHoldingsDropdown = ({
  cell,
  isInEditMode,
  initialHoldings,
  loadHoldings,
  onCellChanged,
  getCompatibleCell,
  eventHandler,
}: SpreadsheetHoldingsDropdownProps) => {
  const id = useRandomId()
  const intl = useIntl()
  const {
    selectedHolding,
    isOpen,
    fetchingHolding,
    isInvalid,
    dropdownRef,
    inputHeight,
    optionsRef,
    stopPropagation,
    onKeyDown,
    search,
    onSearchChange,
    onInputKeyDown,
    loadingHoldings,
    holdings,
    highlightedHoldings,
    onSelectOption,
    onArrowClick,
    forceAutoFocus,
  } = useSpreadsheetHoldingsDropdown({
    cell,
    isInEditMode,
    initialHoldings,
    loadHoldings,
    onCellChanged,
    getCompatibleCell,
    eventHandler,
  })

  return (
    <Styles.Container isInvalid={isInvalid}>
      <FullCellTooltip
        id={id}
        text={
          isInvalid
            ? intl.formatMessage({
                id: cell.error,
              })
            : null
        }
        place="bottom"
        backgroundColor={theme.colors.red}
        maxWidth={cell.width ? `${cell.width}px` : undefined}
        offset={2}
      >
        <Styles.SelectedOption
          hasMetric={!!selectedHolding}
          isOpen={isOpen}
          ref={dropdownRef}
        >
          {!!selectedHolding && (
            <Avatar
              avatarStyle="avatarCircleXS"
              image={getHoldingImage(selectedHolding as Holding, 'small')}
              initials={getInitials(selectedHolding.name)}
            />
          )}
          <Styles.Name empty={!selectedHolding?.name} truncated>
            {selectedHolding?.name ||
              intl.formatMessage({
                id:
                  cell.placeholderId ||
                  'spreadsheet.transactions.selectHolding',
              })}
            {fetchingHolding && (
              <Styles.Spinner icon={['fas', 'spinner-third']} />
            )}
          </Styles.Name>
          <Styles.IconContainer onPointerDown={onArrowClick}>
            <FontAwesomeIcon
              size="sm"
              icon={['fal', isOpen ? 'angle-up' : 'angle-down']}
            />
          </Styles.IconContainer>
        </Styles.SelectedOption>
      </FullCellTooltip>

      {isOpen && (
        <Styles.DropdownContent
          onPointerDown={stopPropagation}
          onKeyDown={onKeyDown}
          top={inputHeight}
        >
          <>
            <InputIcon
              forceShowIcon
              name="search"
              icon={['far', 'search']}
              placeholder={intl.messages['general.search']}
              value={search}
              handleChange={onSearchChange}
              autoFocus
              onKeyDown={onInputKeyDown}
              onBlur={forceAutoFocus}
            />
            {loadingHoldings ? (
              <CWLoader logoSize="4rem" />
            ) : (
              <Styles.Options ref={optionsRef}>
                {holdings.map((option: Holding) => (
                  <Styles.Option
                    key={option.id}
                    id={`dropdownOption_${option.id}`}
                    selected={option.id === selectedHolding?.id}
                    highlighted={option.id === highlightedHoldings?.id}
                    onTouchStart={stopPropagation}
                    onMouseDown={() => {
                      onSelectOption(option)
                    }}
                  >
                    <OptionWrapper>
                      <Avatar
                        avatarStyle="avatarCircleXS"
                        image={getHoldingImage(option, 'small')}
                        initials={getInitials(option.name)}
                      />
                      {option.name}
                    </OptionWrapper>
                  </Styles.Option>
                ))}
              </Styles.Options>
            )}
            {!loadingHoldings && !holdings.length && (
              <Styles.NoResults className="ignore-highlight">
                {intl.formatMessage({ id: 'general.noMatchesFound' })}
              </Styles.NoResults>
            )}
          </>
        </Styles.DropdownContent>
      )}
    </Styles.Container>
  )
}

export default SpreadsheetHoldingsDropdown
