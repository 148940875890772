import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const DatePickerWrapper = styled.div<{
  disabled?: boolean
}>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;

      .react-datepicker {
        display: none;
      }
    `}

  @media ${maxSize.md} {
    max-width: 29.5rem;
  }
`

export const InputLabel = styled.label<{
  filled?: boolean
  large?: boolean
}>`
  color: ${color('darkGray')};
  display: block;
  font-size: ${(props) => (props.large ? '1.4rem' : '1.2rem')};
  font-weight: bold;
  margin-bottom: 0.8rem;
  margin-left: 0.8rem;

  ${(props) =>
    props.filled
      ? css`
          color: var(--color-gray-600);
          font-family: 'Lato', sans-serif;
          font-size: 1.4rem;
          font-weight: 700;
          margin-bottom: 0.5rem;
        `
      : ''}
`

export const OptionalField = styled.span`
  color: #787e8c;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  margin-left: 0.5rem;
`

export const DatePickerContainer = styled.div<{
  iconLeft: boolean
  filled?: boolean
  withError?: boolean
  isFocused?: boolean
  disabled?: boolean
}>`
  border-bottom: 0.1rem solid var(--color-gray-400);
  border-radius: 0.8rem;
  border: 0.1rem solid transparent;
  box-sizing: border-box;
  color: ${color('darkGray')};
  display: flex;
  font-size: 1.4rem;
  height: 3.8rem;
  outline: none;
  padding-right: 0.6rem;
  padding: 1rem 1.6rem;
  text-align: left;
  transition: 0.2s background-color, 0.1s border;
  width: 100%;

  ${(props) =>
    props.iconLeft
      ? css`
          flex-direction: row-reverse;

          svg {
            margin-right: 0.6rem;
          }
        `
      : ''}

  ${(props) =>
    props.isFocused
      ? css`
          background-color: ${color('white')(props)};
          border: 0.1rem solid
            ${props.withError
              ? color('red')(props)
              : color('primaryBlue')(props)};
        `
      : css`
          background-color: ${props.withError
            ? color('red', 0.15)(props)
            : color('lightGray', 0.1)(props)};

          &:hover {
            border: 0.1rem solid
              ${props.disabled ? 'transparent' : color('veryLightBlue')};
            border-radius: 0.8rem;
          }
        `}


  ${(props) =>
    props.filled
      ? css`
          border-radius: 0.8rem;
          width: 100%;
          background: var(--color-account-setup-input-background);
          font-family: 'Lato', sans-serif;
          font-size: 1.6rem;
          font-weight: 400;
          padding: 1rem 1.5rem;
          color: var(--color-gray-400);
          border: none;
          height: 3.8rem;

          &:focus-within {
            outline: none;
            box-shadow: none;
            border: solid 0.1rem #e9e9e9;
          }

          .icon {
            margin-top: 0;
          }
        `
      : ''}


  /* Start Override react-datepicker styles */
  .react-datepicker__month-container {
    .react-datepicker__header {
      padding-bottom: 0.8rem;
      width: 18rem;
      font-size: 1.6rem;
      color: var(--color-gray-700);
    }
  }

  .react-datepicker__month-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .react-datepicker__navigation--previous {
    border-right-color: var(--color-gray-700);
  }

  .react-datepicker__navigation--next {
    border-left-color: var(--color-gray-700);
  }

  .react-datepicker__month {
    font-size: 1.2rem;
    color: var(--color-gray-600);
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    font-size: 1.4rem;
    font-family: 'Lato';
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    margin: 0.3rem;
    font-weight: 400;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    font-size: 1rem;
  }

  .react-datepicker__day-names {
    margin-top: 0.9rem;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 1.4rem;
    color: var(--color-gray-600);
  }

  .react-datepicker__input-container {
    input {
      border: none;
      font-family: Lato, sans-serif;
      width: 100%;
      color: var(--color-gray-600);
      background: transparent;

      &:focus {
        outline: none;
      }

      &:disabled {
        color: var(--color-gray-500);
        cursor: not-allowed;
      }
    }
  }

  .iconClass {
    min-width: 1.8rem;
    height: 1.8rem;
    margin-top: 0.3rem;
  }

  .react-datepicker-popper {
    z-index: 9999;
  }

  .react-datepicker__time-list-item {
    font-size: 1.2rem;
  }
  /* End Override react-datepicker styles */
`

export const IconContainer = styled.div`
  > svg {
    min-width: 1.6rem;
    height: 1.6rem;
    padding-bottom: 0.2rem;
  }
`

export const Label = styled.p`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  margin-left: 1rem;
`
