import type { Appearance } from '@stripe/stripe-js'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

export const baseStripeAppearance: Appearance = {
  theme: 'stripe',
  variables: {
    colorText: color('darkGray')({ theme }),
    borderRadius: '8px',
    fontFamily: 'Lato, sans-serif',
    colorBackground: '#F2F3F4',
    colorTextPlaceholder: color('darkGray', 0.5)({ theme }),
    colorIcon: color('darkBlue')({ theme }),
    colorIconTabSelected: color('primaryBlue')({ theme }),
    colorTextSecondary: color('lightGray')({ theme }),
    colorPrimary: color('primaryBlue')({ theme }),
  },
  rules: {
    '.TabLabel': {
      color: 'black',
    },
    '.TabLabel--selected': {
      color: '#194590',
    },
    '.Label': {
      marginLeft: '14px',
      fontWeight: '700',
      marginBottom: '8px',
      textTransform: 'capitalize',
      fontSize: '12px',
    },
    '.Input': {
      border: 'unset',
      color: color('gray')({ theme }),
    },
    '.Input:focus': {
      boxShadow: `0px 0px 0px 1px ${color('primaryBlue')({ theme })}`,
    },
    '.Error': {
      fontSize: '12px',
    },
  },
}

export const lgStripeAppearance: Appearance = {
  ...baseStripeAppearance,
  variables: {
    ...baseStripeAppearance.variables,
    spacingGridRow: '16px',
    fontSizeSm: '12px',
    fontSizeBase: '14px',
    fontSize3Xs: '12px',
    fontSizeXs: '12px',
    spacingTab: '8px',
    spacingGridColumn: '32px',
  },
  rules: {
    ...baseStripeAppearance.rules,
    '.Input': {
      ...baseStripeAppearance.rules?.['.Input'],
      padding: '10px 16px',
      lineHeight: '18px',
    },
    '.Tab': {
      padding: '18px 16px',
      backgroundColor: 'white',
      fontSize: '14px',
      color: color('darkBlue')({ theme }),
      fontWeight: '700',
    },
    '.Tab--selected': {
      color: color('primaryBlue')({ theme }),
      boxShadow: `0px 0px 0px 1px ${color('primaryBlue')({ theme })}`,
    },
    '.Tab--selected:hover': {
      outline: 'none',
      boxShadow: `0px 0px 0px 1px ${color('primaryBlue')({ theme })}`,
      color: color('primaryBlue')({ theme }),
    },
    '.Tab--selected:focus': {
      outline: 'none',
      boxShadow: `0px 0px 0px 1px ${color('primaryBlue')({ theme })}`,
    },
    '.TabLabel': {
      paddingTop: '5px',
    },
    '.Block': {
      padding: '16px',
      backgroundColor: 'white',
    },
  },
}
