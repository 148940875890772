import React from 'react'

import {
  ButtonContainer,
  EditButtonColumnContainer,
} from './FloatButtonColumn.styles'

interface FloatColumnWrapperProps {
  children: React.ReactElement
  id: string
}

const FloatColumnWrapper: React.FC<FloatColumnWrapperProps> = ({
  children,
  id,
}) => {
  return (
    <EditButtonColumnContainer>
      <ButtonContainer id={`editButtonColumn_${id}`}>
        {children}
      </ButtonContainer>
    </EditButtonColumnContainer>
  )
}

export default FloatColumnWrapper
