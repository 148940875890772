import React from 'react'
import { ProfileData } from 'components/AddHoldingModal/types'
import Avatar from 'components/Avatar'
import { getHoldingImage } from 'utils/types/company'
import { getInitials } from 'utils/functions/user'
import * as Styles from './WebsiteCellTemplate.styles'

interface HoldingProfileSuggestionButtonProps {
  profileData: ProfileData
  onClick: () => void
}

const HoldingProfileSuggestionButton: React.FC<
  HoldingProfileSuggestionButtonProps
> = ({ profileData, onClick }) => {
  return (
    <Styles.HoldingProfileSuggestion.Button secondary onClick={onClick}>
      <Styles.HoldingProfileSuggestion.Content>
        <Avatar
          image={getHoldingImage(profileData.holding!)}
          initials={getInitials(profileData.holding!.name)}
          avatarStyle="avatarCircleXSM"
        />
        <div>{profileData.holding!.name}</div>
      </Styles.HoldingProfileSuggestion.Content>
    </Styles.HoldingProfileSuggestion.Button>
  )
}

export default HoldingProfileSuggestionButton
