import { useEffect } from 'react'
import { useChatContext } from 'stream-chat-react'
import { useForceUpdate } from 'utils/hooks/useForceUpdate'
import { StreamChatType } from '../types'

export const useForceUpdateOnChatUserUpdated = () => {
  const forceUpdate = useForceUpdate()
  const { client } = useChatContext<StreamChatType>()

  useEffect(() => {
    client.on('user.updated', forceUpdate)

    return () => {
      client.off('user.updated', forceUpdate)
    }
  }, [client, forceUpdate])
}
