/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Mark from 'mark.js'

interface Props {
  elementId?: string
  elementClass?: string
  enabled?: boolean
  text: string | string[]
  options?: Mark.MarkOptions
  delay?: number
}

export const useHighlightText = (
  {
    elementId,
    elementClass,
    text,
    options = {},
    enabled = true,
    delay = 0,
  }: Props,
  dependencies: React.DependencyList = []
) => {
  const [matches, setMatches] = useState(0)

  useEffect(() => {
    // THIS TIMER IS NEED TO WAIT FOR ANIMATIONS AND API CALL.
    // IF NOT, NOT ALL MATCHES ARE HIGHLIGHTED
    const timer = setTimeout(() => {
      let context
      if (elementId) context = document.getElementById(elementId)
      if (elementClass) context = document.querySelectorAll(elementClass)

      const hasContext = Array.isArray(context) ? context.length > 0 : context

      if (enabled && hasContext) {
        const markJs = new Mark(context)

        if (text) {
          markJs.unmark()
          markJs.mark(text, {
            ...options,
            className: 'markjs-highlight-text',
            done: setMatches,
            exclude: ['.ignore-highlight'],
          })
        } else if (!text) {
          markJs.unmark()
        }
      }
    }, delay)

    return () => clearTimeout(timer)
  }, [text, enabled, ...dependencies])

  return { matches }
}
