import DraftUpdateService from 'api/DraftUpdateService'
import ListService from 'api/ListService'
import UpdateService from 'api/UpdateService'
import { UpdateTypes } from 'utils/constants/updates'
import {
  getCreateAnnouncementPayload,
  getEditAnnouncementPayload,
  getEditDraftAnnouncementPayload,
} from 'utils/functions/api/announcements'
import {
  getCreateDocumentPayload,
  getEditDocumentPayload,
  getEditDraftDocumentPayload,
} from 'utils/functions/api/documents'
import { round } from 'utils/functions/number'
import {
  getCreateNotePayload,
  getEditDraftNotePayload,
  getEditNotePayload,
} from 'utils/functions/api/notes'
import {
  EditDraftUpdatePayloadParams,
  EditUpdatePayloadParams,
} from 'utils/functions/api/types'
import { updatesKeys } from 'utils/queries/udpates'
import {
  getCreateDraftTransactionPayload,
  getEditDraftTransactionPayload,
} from 'utils/functions/api/transactions'
import {
  EditDraftUpdatePayload,
  EditUpdatePayload,
} from 'utils/types/updatePayloads'
import { AccessType } from 'utils/types/updateForm'
import { TransactionUpdate, Update } from 'utils/types/update'
import { EntityFromUrlType } from '../useEntityFromUrl'

/**
 * Object helper for editing the NOTE, ANNOUNCEMENTS, AND DOCUMENTS permissions in the VIEW SCREEN
 * In the VIEW SCREEN, the user can edit the permissions directly for the update without converting the update into a draft
 */

export const editQueryHelpers = {
  note: {
    key: updatesKeys.editNote(),

    payload: (values: EditUpdatePayloadParams) =>
      getEditNotePayload({ ...values }),

    service: async (updateId: string, payload: EditUpdatePayload<'note'>) =>
      UpdateService.editNote(updateId, payload),
  },
  announcement: {
    key: updatesKeys.editAnnouncement(),

    payload: (values: EditUpdatePayloadParams) =>
      getEditAnnouncementPayload({ ...values }),

    service: async (
      updateId: string,
      payload: EditUpdatePayload<'announcement'>
    ) => UpdateService.editAnnouncement(updateId, payload),
  },

  document: {
    key: updatesKeys.editDocument(),

    payload: (values: EditUpdatePayloadParams) =>
      getEditDocumentPayload({ ...values }),

    service: async (updateId: string, payload: EditUpdatePayload<'document'>) =>
      UpdateService.editDocument(updateId, payload),
  },
}

/**
 * Object helper for creating DRAFT UPDATES
 */
export const createDraftQueryHelpers = {
  note: {
    key: updatesKeys.createDraftNote(),

    service: (entity: EntityFromUrlType) => {
      const draftUpdatePayload = getCreateNotePayload()

      return DraftUpdateService.createDraftUpdate<'note'>({
        subject: entity.name,
        subjectId: entity.id,
        draftUpdate: draftUpdatePayload,
        updateType: UpdateTypes.NOTE,
      })
    },
  },

  announcement: {
    key: updatesKeys.createAnnouncement(),

    service: async (entity: EntityFromUrlType) => {
      const draftAnnouncementPayload = getCreateAnnouncementPayload()
      return DraftUpdateService.createDraftUpdate({
        subject: entity.name,
        subjectId: entity.id,
        draftUpdate: draftAnnouncementPayload,
        updateType: UpdateTypes.ANNOUNCEMENT,
      })
    },
  },

  document: {
    key: updatesKeys.createDocument(),

    service: async (entity: EntityFromUrlType) => {
      const draftDocumentPayload = getCreateDocumentPayload()
      return DraftUpdateService.createDraftUpdate({
        subject: entity.name,
        subjectId: entity.id,
        draftUpdate: draftDocumentPayload,
        updateType: UpdateTypes.DOCUMENT,
      })
    },
  },

  transaction: {
    key: updatesKeys.createTransaction(),

    service: async (entity: EntityFromUrlType) => {
      const draftTransactionPayload = getCreateDraftTransactionPayload()
      return DraftUpdateService.createDraftUpdate({
        subject: entity.name,
        subjectId: entity.id,
        draftUpdate: draftTransactionPayload,
        updateType: UpdateTypes.TRANSACTION,
      })
    },
  },
}

/**
 * Object helper for editing DRAFTS UPDATES
 */
export const editDraftQueryHelpers = {
  note: {
    key: updatesKeys.editDraftNote(),

    payload: (
      values: EditDraftUpdatePayloadParams,
      originalUpdate?: Update
    ): EditDraftUpdatePayload<'note'> => {
      let sharedGroups =
        values.investor && values.permissions?.investor === AccessType.CAN_VIEW
          ? [...values.sharedGroups, values.investor.id]
          : values.sharedGroups

      if (originalUpdate && originalUpdate.loggingUpdateGroups.length > 0) {
        sharedGroups = sharedGroups.filter(
          (groupId: string) =>
            !originalUpdate.loggingUpdateGroups.find(
              (group) => group.groupableId === groupId
            )
        )
      }

      return {
        note: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftNotePayload({ ...values, sharedGroups }),
          },
        },
      }
    },

    service: (
      updateId: string,
      draftUpdatePayload: EditDraftUpdatePayload<'note'>
    ) => {
      return DraftUpdateService.editDraftUpdate<'note'>({
        updateId,
        draftUpdate: draftUpdatePayload,
        updateType: UpdateTypes.NOTE,
      })
    },
  },

  announcement: {
    key: updatesKeys.editAnnouncement(),

    payload: (
      values: EditDraftUpdatePayloadParams
    ): EditDraftUpdatePayload<'announcement'> => {
      return {
        announcement: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftAnnouncementPayload(values),
          },
        },
      }
    },

    service: async (
      updateId: string,
      draftUpdatePayload: EditDraftUpdatePayload<'announcement'>
    ) => {
      return DraftUpdateService.editDraftUpdate<'announcement'>({
        updateId,
        draftUpdate: draftUpdatePayload,
        updateType: UpdateTypes.ANNOUNCEMENT,
      })
    },
  },

  document: {
    key: updatesKeys.editDocument(),

    payload: (
      values: EditDraftUpdatePayloadParams,
      originalUpdate?: Update
    ): EditDraftUpdatePayload<'document'> => {
      let sharedGroups =
        values.investor && values.permissions?.investor === AccessType.CAN_VIEW
          ? [...values.sharedGroups, values.investor.id]
          : values.sharedGroups

      if (originalUpdate && originalUpdate.loggingUpdateGroups.length > 0) {
        sharedGroups = sharedGroups.filter(
          (groupId: string) =>
            !originalUpdate.loggingUpdateGroups.find(
              (group) => group.groupableId === groupId
            )
        )
      }

      return {
        document: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftDocumentPayload({ ...values, sharedGroups }),
          },
        },
      }
    },

    service: async (
      updateId: string,
      draftUpdatePayload: EditDraftUpdatePayload<'document'>
    ) => {
      return DraftUpdateService.editDraftUpdate<'document'>({
        updateId,
        draftUpdate: draftUpdatePayload,
        updateType: UpdateTypes.DOCUMENT,
      })
    },
  },

  transaction: {
    key: updatesKeys.editDraftTransaction(),

    payload: (
      values: any,
      originalUpdate: TransactionUpdate
    ): EditDraftUpdatePayload<'transaction'> => {
      let sharedGroups =
        values.investor && values.permissions.investor === AccessType.CAN_VIEW
          ? [...values.sharedGroups, values.investor.id]
          : values.sharedGroups

      if (originalUpdate && originalUpdate.loggingUpdateGroups.length > 0) {
        sharedGroups = sharedGroups.filter(
          (groupId: string) =>
            !originalUpdate.loggingUpdateGroups.find(
              (group) => group.groupableId === groupId
            )
        )
      }

      return {
        transaction: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftTransactionPayload({
              updateId: values.updateId,
              title: values.title,
              body: values.description,
              date: values.date!,
              uploads: values.files,
              tags: [],
              shouldBehaveAsFounder: false,
              originalUpdate,
              transactionValues: values,
              reshareSettings: {
                isConfidential: values.reshareSettings.isConfidential,
                isBlocked: values.reshareSettings.isBlocked,
              },
              ...values,
              sharedGroups,
            }),
          },
        },
      }
    },

    service: (
      updateId: string,
      draftUpdatePayload: EditDraftUpdatePayload<'transaction'>
    ) => {
      return DraftUpdateService.editDraftUpdate<'transaction'>({
        updateId,
        draftUpdate: draftUpdatePayload,
        updateType: UpdateTypes.TRANSACTION,
      })
    },
  },
}

/**
 * Called every time there is a list id in the permissions
 * to set the last used when we fetch the share permissions
 */
export const updateRecentlyUsedLists = async (recentlyUsedLists: number[]) => {
  return Promise.all(
    recentlyUsedLists?.map(async (listId) => {
      return ListService.setListRecentlyUsed(listId, new Date())
    })
  )
}

export const getFmvListWithGrowth = (fmvList) => {
  return fmvList?.map((fmvValue, index) => {
    const previousFmv = index === 0 ? null : fmvList[index - 1]

    if (!previousFmv || previousFmv?.value === fmvValue?.value) {
      return {
        ...fmvValue,
        growth: null,
        growthPercentage: null,
      }
    }

    const difference = (fmvValue?.value ?? 0) - (previousFmv?.value ?? 0)

    return {
      ...fmvValue,
      growth: difference > 0,
      growthPercentage: round(difference, 1),
      growthValue: Math.abs(difference),
    }
  })
}
