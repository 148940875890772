import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
`
export const ColorPickerButton = styled.div`
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  border: solid 1px #bababa;
  background-color: ${(props) => props.color || '#f7f7f7'};
  transition: all 0.2s;
  &:hover {
    transform: scale(1.15);
    box-shadow: 0 0.4rem 0.1rem 0 rgba(16, 21, 39, 0.16);
  }

  ${(props) =>
    props.selected &&
    css`
      border: solid 1px #1c4373;
      transform: scale(1.15);
    `}

  ${(props) =>
    props.open &&
    css`
      box-shadow: 0 0.4rem 0.1rem 0 rgba(16, 21, 39, 0.16);
    `}
`

export const OptionsContainer = styled.ul`
  position: absolute;
  top: -5rem;
  right: -1.2rem;
  height: 4.7rem;
  padding: 11px 16px 11px 15px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(16, 21, 39, 0.15);
  background-color: var(--white);
  cursor: initial;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  background: white;

  &:focus {
    outline: none;
  }
`

export const OptionsItem = styled.li`
  margin-right: 0.6rem;
`

export const CloseButton = styled.button`
  svg {
    color: #222222;
    font-size: 1.6rem;
    margin-left: 1rem;
  }
`
