import { useCallback, useState } from 'react'
import { useField, useForm } from 'components/Form/hooks'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCanScheduleUpdates } from 'selectors/auth'
import { UpdateFormContext } from 'utils/types/updateForm'

const useDatePicker = (name: string) => {
  const [isNewTimeSelected, setIsNewTimeSelected] = useState(false)
  const canScheduleUpdate = useAppSelector(getCanScheduleUpdates)

  const { isEditMode } = useForm<UpdateFormContext>()
  const [field, , dateHelpers] = useField(name)

  const onChangeDate = useCallback(
    (date: Date) => {
      dateHelpers.setValue(date)
      setIsNewTimeSelected(true)
    },
    [dateHelpers]
  )

  return {
    field,
    isNewTimeSelected,
    onChangeDate,
    canScheduleUpdate,
    isEditMode,
  }
}

export default useDatePicker
