import { useCallback, useMemo, useState } from 'react'

const useDateSelected = () => {
  const [yearDateActive, setYearDateActive] = useState({
    isStartDayActive: false,
    isEndDayActive: false,
  })

  const [quarterDateActive, setQuarterDateActive] = useState({
    isStartDayActive: false,
    isEndDayActive: false,
  })

  const handleYearDateActive = useCallback((isStart: boolean) => {
    setYearDateActive((prev) => ({
      ...prev,
      isStartDayActive: isStart,
      isEndDayActive: !isStart,
    }))
  }, [])

  const handleQuarterDateActive = useCallback((isStart: boolean) => {
    setQuarterDateActive((prev) => ({
      ...prev,
      isStartDayActive: isStart,
      isEndDayActive: !isStart,
    }))
  }, [])

  const handleResetDateActive = useCallback(() => {
    setYearDateActive({
      isStartDayActive: false,
      isEndDayActive: false,
    })

    setQuarterDateActive({
      isStartDayActive: false,
      isEndDayActive: false,
    })
  }, [])

  const isQuarterDateActive = useMemo(
    () =>
      quarterDateActive.isStartDayActive || quarterDateActive.isEndDayActive,
    [quarterDateActive.isEndDayActive, quarterDateActive.isStartDayActive]
  )

  const isYearDateActive = useMemo(
    () => yearDateActive.isStartDayActive || yearDateActive.isEndDayActive,
    [yearDateActive.isEndDayActive, yearDateActive.isStartDayActive]
  )

  const isAnyDateActive = useMemo(
    () => isQuarterDateActive || isYearDateActive,
    [isQuarterDateActive, isYearDateActive]
  )

  return {
    yearDateActive,
    quarterDateActive,
    isAnyDateActive,
    handleYearDateActive,
    handleQuarterDateActive,
    handleResetDateActive,
  }
}

export default useDateSelected
