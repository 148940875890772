import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { AnimatePresence, motion } from 'framer-motion'

import CheckItem from 'components/CheckItem/CheckItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'components/Tooltip'

import {
  ActionContainer,
  ActionTitle,
  ActionText,
  ButtonsContainer,
  ActiontitleContainer,
  ActionButton,
  TitleRow,
  ToggleActionButton,
  CardWrapper,
} from './UniversityAction.styles'

const UniversityAction = ({
  actionId,
  title,
  text,
  guideUrl,
  handleStart,
  skipEnabled,
  handleSkip,
  completed,
  redoLabel,
  isEnabled,
  disabledText,
  isActiveAction,
  setActiveAction,
  runTourEnabled,
}) => {
  const intl = useIntl()

  const Wrapper = isEnabled
    ? ({ children: tootltipChildren }) => (
        <CardWrapper onClick={setActiveAction}>{tootltipChildren}</CardWrapper>
      )
    : ({ children: tootltipChildren }) => (
        <Tooltip
          id={`universtityActionTooltip_${actionId}`}
          place="right"
          leftOffset={80}
          text={disabledText}
          event="mouseover mouseenter"
          eventOff="mouseleave mouseout scroll mousewheel blur"
        >
          <div data-iscapture="true">{tootltipChildren}</div>
        </Tooltip>
      )

  return (
    <Wrapper>
      <ActionContainer
        disabled={!isEnabled}
        isActive={isActiveAction}
        completed={completed}
      >
        <ActiontitleContainer>
          <TitleRow>
            <CheckItem checked={completed} />
            <ActionTitle completed={completed} isActive={isActiveAction}>
              {title}
            </ActionTitle>
          </TitleRow>
          <ToggleActionButton>
            <FontAwesomeIcon
              icon={['far', isActiveAction ? 'chevron-up' : 'chevron-down']}
            />
          </ToggleActionButton>
        </ActiontitleContainer>
        <AnimatePresence>
          {isActiveAction && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ActionText>{text}</ActionText>
              {completed && runTourEnabled && (
                <ButtonsContainer>
                  <ActionButton link onClick={handleStart}>
                    <FontAwesomeIcon icon={['far', 'pennant']} />
                    {redoLabel || intl.messages['university.redo']}
                  </ActionButton>
                </ButtonsContainer>
              )}
            </motion.div>
          )}
        </AnimatePresence>
        <ButtonsContainer>
          {!completed && runTourEnabled && (
            <ActionButton link onClick={handleStart}>
              <FontAwesomeIcon icon={['far', 'pennant']} />
              {completed
                ? redoLabel || intl.messages['university.redo']
                : intl.messages['university.start']}
            </ActionButton>
          )}

          {guideUrl && (
            <ActionButton
              link
              onClick={(event) => {
                event.stopPropagation()
                window.open(guideUrl, '_blank').focus()
              }}
            >
              <FontAwesomeIcon icon={['far', 'book-alt']} />
              {intl.messages['university.readGuide']}
            </ActionButton>
          )}

          {skipEnabled && (
            <ActionButton link onClick={handleSkip}>
              <FontAwesomeIcon icon={['far', 'check']} />
              {intl.messages['university.skip']}
            </ActionButton>
          )}
        </ButtonsContainer>
      </ActionContainer>
    </Wrapper>
  )
}

UniversityAction.propTypes = {
  title: PropTypes.string.isRequired,
  actionId: PropTypes.string.isRequired,
  guideUrl: PropTypes.string,
  handleStart: PropTypes.func.isRequired,
  skipEnabled: PropTypes.bool,
  completed: PropTypes.bool,
  isEnabled: PropTypes.bool,
  runTourEnabled: PropTypes.bool,
  handleSkip: PropTypes.func,
  redoLabel: PropTypes.string,
  disabledText: PropTypes.string,
  text: PropTypes.string.isRequired,
  isActiveAction: PropTypes.bool.isRequired,
  setActiveAction: PropTypes.func.isRequired,
}

UniversityAction.defaultProps = {
  skipEnabled: false,
  completed: false,
  isEnabled: true,
  handleSkip: () => {},
  redoLabel: null,
  disabledText: null,
  guideUrl: '',
  runTourEnabled: true,
}
export default React.memo(UniversityAction)
