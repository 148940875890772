import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'

import Title from 'components/Title'
import Separator from 'ui/Separator'
import UpdateCardBox from '../UpdateCardBox'
import {
  ReshareSettingCheckbox,
  ReshareSettingCheckboxContainer,
  ReshareSettingText,
} from './ReshareSettings.styles'

export type ReshareSettingsType = {
  isConfidential: boolean
  isBlocked: boolean
}

export type ChangeReshareSettingsFunc = (
  key: keyof ReshareSettingsType,
  value: boolean
) => void

interface ReshareSettingsProps {
  reshareSettings: ReshareSettingsType
  onChangeReshareSettings: ChangeReshareSettingsFunc
  noPadding?: boolean
}

const ReshareSettings: React.FC<ReshareSettingsProps> = ({
  reshareSettings,
  onChangeReshareSettings,
  noPadding = false,
}) => {
  const intl = useIntl()

  return (
    <UpdateCardBox noPadding={noPadding}>
      <UpdateCardBox.Header>
        <Title
          title={intl.formatMessage({ id: 'updates.reshareSettings' })}
          icon={['far', 'retweet']}
        />
      </UpdateCardBox.Header>
      <UpdateCardBox.Body>
        <ReshareSettingCheckboxContainer>
          <ReshareSettingCheckbox
            id="isConfidential"
            name="isConfidential"
            label={intl.formatMessage({ id: 'updates.confidentialUpdate' })}
            onChange={(event) =>
              onChangeReshareSettings('isConfidential', event.target.checked)
            }
            checked={reshareSettings.isConfidential}
          />
          <FontAwesomeIcon icon={['far', 'user-shield']} />
        </ReshareSettingCheckboxContainer>
        <Separator space="0.3rem" />
        <ReshareSettingText>
          {intl.formatMessage({ id: 'updates.confidentialUpdateDesc' })}
        </ReshareSettingText>

        <Separator space="1.6rem" />

        {/* <ReshareSettingCheckboxContainer>
          <ReshareSettingCheckbox
            id="isblocked"
            name="isblocked"
            label={intl.formatMessage({ id: 'updates.blockedUpdate' })}
            onChange={(event) =>
              onChangeReshareSettings('isBlocked', event.target.checked)
            }
            checked={reshareSettings.isBlocked}
          />
          <FontAwesomeIcon icon={['far', 'ban']} />
        </ReshareSettingCheckboxContainer>
        <Separator space="0.3rem" />
        <ReshareSettingText>
          {intl.formatMessage({ id: 'updates.blockedUpdateDesc' })}
        </ReshareSettingText> */}
      </UpdateCardBox.Body>
    </UpdateCardBox>
  )
}

ReshareSettings.defaultProps = {
  noPadding: false,
}

export default ReshareSettings
