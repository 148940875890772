import { CSSProperties } from 'react'
import { FormattedMessage } from 'react-intl'
import { PortfolioTypes } from 'utils/constants/portfolio'
import theme from 'utils/theme'
import { CounterLabel } from './Dropdown.styles'

export const getPortfolioName = (portfolioType: PortfolioTypes) => {
  switch (portfolioType) {
    case PortfolioTypes.INVEST:
      return <FormattedMessage id="dashboard.dropdownOptions.investPortfolio" />
    case PortfolioTypes.FUND:
      return <FormattedMessage id="dashboard.dropdownOptions.fundPortfolio" />
    case PortfolioTypes.TRACK:
      return <FormattedMessage id="dashboard.dropdownOptions.pipeline" />

    default:
      return new Error('Wrong portfolio type')
  }
}

export const getNumber = (
  number: number,
  maxLength: number,
  counterStyle: CSSProperties
) => {
  const isNegative = maxLength - number < 0
  const remainingChars = maxLength - number

  return (
    <CounterLabel
      style={counterStyle}
      color={isNegative ? theme.colors.red : theme.colors.lightGray}
    >
      {Number.isNaN(remainingChars) ? maxLength : remainingChars}
    </CounterLabel>
  )
}
