import { useEffect, useState } from 'react'

export const useMetricLists = ({
  currentGroups,
  handleRemoveGroup,
  setCurrentGroups,
  handleAddGroup,
}) => {
  const [itemLists, setItemLists] = useState([])

  useEffect(() => {
    const updatedItems = currentGroups
      ? currentGroups?.map((item) => ({
          ...item,
          entityId: item.id,
        }))
      : []
    setItemLists(updatedItems)
  }, [currentGroups])

  const handleDeleteItemList = (itemId) => {
    const newItems = itemLists.filter(({ id }) => id !== itemId)
    setItemLists(newItems)
    handleRemoveGroup(itemId)
  }

  const handleEditItemList = ({ id: groupId, listId, listName }) => {
    setItemLists((prevItems) => {
      const newItems = prevItems.map((item) =>
        item.id === groupId ? { ...item, listId, listName } : item
      )
      setCurrentGroups(newItems)
      return newItems
    })
  }

  const handleAddItemList = (item) => {
    const itemIsAlreadyAddedToList = itemLists?.find(
      (currItem) => currItem.id === item.id
    )
    if (!itemIsAlreadyAddedToList) {
      setItemLists((currItems) => [
        ...currItems,
        { ...item, entityId: item.id },
      ])
      handleAddGroup(null, item.id, item)
    }
  }

  return {
    itemLists,
    handleDeleteItemList,
    handleEditItemList,
    handleAddItemList,
  }
}
