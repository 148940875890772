import { flatMap, map, uniq } from 'lodash'
import UserService from 'api/UserService'
import { ReshareTokenSet } from 'utils/functions/updates'
import Toast from 'components/Toast'
import { User } from 'utils/types/user'
import { UpdateType } from 'utils/types/common'
import { ShareWithOption } from './components/ShareSettingsModal/components/ShareWithDropdown/useShareWithDropdown'

export const getUniqueUserNames = (
  resharesByToken: ReshareTokenSet[]
): string[] => {
  const names = flatMap(resharesByToken, (reshareTokenSet) => {
    const { reshares } = reshareTokenSet
    const userNames = map(reshares, (reshare: any) => {
      const { userName } = reshare
      return userName
    })
    return userNames
  })

  const uniqueUserNames = uniq(names)
  return uniqueUserNames
}

interface CreateNewUsersProps {
  entities: ShareWithOption[]
  setSelectedEntities: React.Dispatch<React.SetStateAction<ShareWithOption[]>>
  prefix: string
  toastErrorMessage: string
}

export const createNewUsers = async ({
  entities,
  setSelectedEntities,
  prefix,
  toastErrorMessage,
}: CreateNewUsersProps) => {
  try {
    const usersToCreate = entities.filter((entity) =>
      entity.id.toString().startsWith(prefix)
    )

    if (usersToCreate.length > 0) {
      const newUsers: User[] = await UserService.bulkCreateUser({
        emails: usersToCreate.map((user) => user.name),
      })

      setSelectedEntities((currGroupsSelected) =>
        currGroupsSelected.map((group) => {
          const userWithSameEmail = newUsers.find(
            (user) => user.email === group.name
          )

          if (userWithSameEmail) {
            return {
              ...group,
              id: userWithSameEmail.id,
            }
          }

          return group
        })
      )
    }
  } catch {
    Toast.displayIntl(toastErrorMessage, 'error')
    setSelectedEntities((currGroupsSelected) =>
      currGroupsSelected.map((group) => {
        if (group.id.toString().startsWith(prefix)) {
          return {
            ...group,
            failedCreating: true,
          }
        }

        return group
      })
    )
  }
}

export const handleEditUpdateError = (updateType: UpdateType) => {
  return Toast.displayIntl(
    ['errors.updatingItemError', { item: updateType }],
    'error'
  )
}
