import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const addToPortfolioCustomStyle = () => css`
  color: ${color('white')};
  border-color: ${color('white', 0.4)};
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 1.3rem;
  grid-area: 1/1/1/3;
  height: fit-content;
  padding: 1.6rem 3.2rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 3;
  align-items: center;

  @media ${size.md} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${size.tabletLg} {
    align-self: flex-end;
    flex-direction: row;
    grid-area: main;
    justify-content: space-between;
    padding: 1.6rem 3.2rem 1.6rem 0;
    position: relative;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
  width: 100%;
  min-width: 0;
`

export const TagIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  cursor: pointer;
`

export const TagButton = styled.div`
  display: flex;
  align-items: center;
  gap: 1.3rem;
  width: fit-content;
`

export const TagList = styled.div`
  display: flex;
  gap: 1.3rem;
`

export const PlaceHolderDiv = styled.div``

export const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: 14%;
`
