import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useChannelStateContext, useChatContext } from 'stream-chat-react'

import Modal from 'components/Modal'
import Toast from 'components/Toast'
import {
  JoinedChannelSystemMessage,
  SystemMessageType,
  StreamChatType,
  isTopicChannel,
  TopicChannel,
} from 'containers/Chat/types'
import { useSetActiveChannel } from 'containers/Chat/hooks'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUserId } from 'selectors/auth'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import useArrayState from 'utils/hooks/useArrayState'
import ChatService from 'api/ChatService'
import {
  findChannelWithSameMembers,
  getChannelType,
} from 'containers/Chat/helpers'
import ChatMembersPicker from '../ChatMembersPicker'
import { ChannelMember } from '../ChatMembersPicker/useChatMembersPicker'
import {
  StyledModal,
  RadioButton,
  RowFooter,
} from './AddParticipantsModal.styles'

type Props = {
  show: boolean
  onHide: () => void
  currentMemberIds?: string[]
}

const AddParticipantsModal = ({
  show,
  onHide,
  currentMemberIds = [],
}: Props) => {
  const intl = useIntl()
  const [members, membersHandler] = useArrayState<ChannelMember>([])
  const [includeChatHistory, setIncludeChatHistory] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const currentUserId = useAppSelector(getUserId)
  const { client } = useChatContext<StreamChatType>()
  const setActiveChannel = useSetActiveChannel()
  const { channel } = useChannelStateContext()

  useEffect(() => {
    if (!show) {
      membersHandler.set([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const onMembersSelected = useCallback(
    (selectedOptions?: ChannelMember[]) => {
      membersHandler.set(selectedOptions || [])
    },
    [membersHandler]
  )

  const onAddMembers = async () => {
    try {
      setIsSubmitting(true)

      const membersIds = members
        .map((member) => member.id)
        .concat(Object.keys(channel.state.members))
        .filter((id) => id !== currentUserId)

      const channelType = getChannelType(channel.data as TopicChannel)

      const existingChannel = await findChannelWithSameMembers({
        membersIds,
        userId: currentUserId,
        client,
        entityId: isTopicChannel(channel.data)
          ? channel.data.entityId
          : undefined,
        updateId: channel.data?.updateId as string,
        channelType,
      })

      if (existingChannel) {
        setActiveChannel(existingChannel)
      } else {
        const message: JoinedChannelSystemMessage = {
          type: SystemMessageType.JOINED_CHAT,
          members: members.map((member) => ({
            name: member.name,
            id: member.id,
          })),
        }

        await ChatService.addMembersToChannel(
          channel.id!,
          members.map((member) => member.id),
          JSON.stringify(message),
          !includeChatHistory
        )
      }

      onHide()
    } catch (err) {
      Toast.displayIntl('chat.addParticipantsError', 'error')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <StyledModal show={show} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.H2Title>
          <FormattedMessage id="chat.addParticipants" />
        </Modal.H2Title>
      </Modal.Header>
      <Modal.Body>
        <ChatMembersPicker
          members={members}
          onMembersSelected={onMembersSelected}
          hiddenMemberIds={currentMemberIds}
        />
        <Separator />
        <RadioButton
          name="include-chat-history"
          onChange={(value: boolean) => setIncludeChatHistory(value)}
          direction="column"
          uncheckedWithOpacity={false}
          options={[
            {
              label: intl.formatMessage({ id: 'chat.dontIncludeChatHistory' }),
              value: false,
            },
            {
              label: intl.formatMessage({ id: 'chat.includeChatHistory' }),
              value: true,
            },
          ]}
          value={includeChatHistory}
        />
      </Modal.Body>
      <RowFooter>
        <Button secondary onClick={onHide}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button
          primary
          disabled={members.length === 0 || isSubmitting}
          onClick={onAddMembers}
        >
          <FormattedMessage id="general.add" />
        </Button>
      </RowFooter>
    </StyledModal>
  )
}

export default AddParticipantsModal
