import { useCallback, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'

import { isEscCode } from 'utils/functions/keyboardEvents'
import {
  ACCOUNT_SETTINGS,
  getCurrentSettingRoute,
  GROUP_MANAGEMENT_SETTINGS,
} from 'utils/functions/settings'
import {
  accountSettingsOptions,
  groupSettingsOptions,
} from 'utils/functions/sideBar'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'

import SubscriptionTrialIndicator from 'components/SubscriptionTrialIndicator'
import { useSubscriptionQuery } from 'containers/GroupSettings/Subscriptions/useSubscriptionQuery'
import { getLastLocation } from 'features/routesSlice'
import { getUserGroups } from 'selectors/auth'
import { getSubscriptionData } from 'selectors/subscriptions'
import { GroupTypes } from 'utils/constants/groups'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { SubscriptionStatus, TrialType } from 'utils/types/subscriptions'
import SideBarItem from '../SideBarItem/SideBarItem'
import SecondarySideBar from './SecondarySideBar'

interface SecondarySideBarSettingsProps {
  isOpen: boolean
  primarySidebarOpen: boolean
  togglePrimarySidebar: () => void
}

const SecondarySideBarSettings = ({
  isOpen,
  primarySidebarOpen,
  togglePrimarySidebar,
}: SecondarySideBarSettingsProps) => {
  const appState = useAppSelector((state) => state)
  const location = useLocation()
  const { pathname } = location
  const history = useHistory()
  const { matches: isTablet } = useMediaQuery(maxSize.mobile)
  const intl = useIntl()
  const userGroups = useAppSelector(getUserGroups)
  const planFeatures = usePlanFeatures()
  const groups = Object.values(userGroups || {})
  const userHasOnlyInvestorGroups = groups.every(
    (group) => group.type === GroupTypes.INVESTOR_GROUP
  )
  const lastLocation = useAppSelector(getLastLocation)
  const subscriptionData = useAppSelector(getSubscriptionData)

  const { data: subscription } = useSubscriptionQuery({
    queryOptions: {
      enabled: !!subscriptionData?.stripeSubscriptionId,
    },
  })

  const isTrial =
    subscription?.status === SubscriptionStatus.TRIALING &&
    subscription.trialType === TrialType.FREE_TRIAL

  const closeGroupSelectorOnEscKeyPressed = useCallback(
    (event) => {
      if (isEscCode(event)) {
        history.goBack()
      }
    },
    [history]
  )

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keyup', closeGroupSelectorOnEscKeyPressed)
    }
    return () => {
      window.removeEventListener('keyup', closeGroupSelectorOnEscKeyPressed)
    }
  }, [isOpen, closeGroupSelectorOnEscKeyPressed])

  const sidebarOptions = useMemo(() => {
    const currentRoute = getCurrentSettingRoute(pathname)
    switch (currentRoute) {
      case ACCOUNT_SETTINGS:
        return accountSettingsOptions(
          appState,
          intl,
          userHasOnlyInvestorGroups,
          planFeatures
        )
      case GROUP_MANAGEMENT_SETTINGS:
        return groupSettingsOptions(appState, intl, planFeatures)
      default:
        return []
    }
  }, [pathname, appState, intl, userHasOnlyInvestorGroups, planFeatures])

  const currentRoute = getCurrentSettingRoute(pathname)

  const sidebarTitle = useMemo(() => {
    switch (currentRoute) {
      case ACCOUNT_SETTINGS:
        return intl.formatMessage({ id: 'sidebar.userSettings' })
      case GROUP_MANAGEMENT_SETTINGS:
        return intl.formatMessage({ id: 'sidebar.groupSettings' })
      default:
        return ''
    }
  }, [currentRoute, intl])

  const onClose = useCallback(() => {
    if (isTablet && lastLocation.pathname) {
      history.push(lastLocation.pathname)
    }
  }, [history, isTablet, lastLocation.pathname])

  return (
    <SecondarySideBar title={sidebarTitle} isOpen={isOpen} onClose={onClose}>
      {sidebarOptions.map((sidebarOption) => (
        <SideBarItem
          key={sidebarOption.url}
          item={sidebarOption}
          isSelected={pathname.includes(sidebarOption.url as string)}
          primarySidebarOpen={primarySidebarOpen}
          toggleSideBar={togglePrimarySidebar}
        />
      ))}
      {currentRoute === GROUP_MANAGEMENT_SETTINGS && isTrial && (
        <SubscriptionTrialIndicator
          trialEndDate={subscriptionData!.stripeNextBillingDate!}
        />
      )}
    </SecondarySideBar>
  )
}

export default SecondarySideBarSettings
