import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: absolute;
  left: 0;

  svg {
    width: 8.1rem;
    height: 8.1rem;
  }
`
