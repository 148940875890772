import { Inbound, InboundStatus } from 'utils/types/inbounds'
import axiosClient from './httpClient'
import { ApiCreateResponse, ApiEntity, ApiIndexResponse } from './types'

export interface CreateInboundPayload {
  email: string
  groupManaged: boolean
}

export interface EditInboundPayload {
  groupManaged: boolean
  connectionStatus: InboundStatus
}

const RESOURCE_PATH = '/linked_email_accounts'
type AxiosInboundResponse = ApiCreateResponse<Inbound, 'linkedEmailAccounts'>

class InboundService {
  static normalizeInbound(inboundData: any): Inbound {
    return { ...inboundData, isDefaultInbound: !inboundData.email }
  }

  static async create(payload: CreateInboundPayload): Promise<Inbound> {
    const { data } = await axiosClient().post<AxiosInboundResponse>(
      RESOURCE_PATH,
      payload
    )

    const inbound = data.entities.linkedEmailAccounts[data.result]

    return InboundService.normalizeInbound(inbound)
  }

  static async destroy(inboundId: string): Promise<void> {
    await axiosClient().delete(`${RESOURCE_PATH}/${inboundId}`)
  }

  static count = 0

  static async getById(inboundId: string): Promise<Inbound> {
    const { data } = await axiosClient().get<AxiosInboundResponse>(
      `${RESOURCE_PATH}/${inboundId}`
    )

    const inboundData = data.entities.linkedEmailAccounts[data.result]
    return InboundService.normalizeInbound(inboundData)
  }

  static async edit(
    inboundId: string,
    inbound: Partial<EditInboundPayload>
  ): Promise<Inbound> {
    const { data } = await axiosClient().patch<AxiosInboundResponse>(
      `${RESOURCE_PATH}/${inboundId}`,
      inbound
    )

    const inboundData = data.entities.linkedEmailAccounts[data.result]
    return InboundService.normalizeInbound(inboundData)
  }

  static async getAll(): Promise<Inbound[]> {
    const { data } = await axiosClient().get<
      ApiIndexResponse<{
        linkedEmailAccounts: ApiEntity<Inbound>
      }>
    >(RESOURCE_PATH)

    return data.result
      .map((inboundId) => {
        const inboundData = data.entities.linkedEmailAccounts[inboundId]
        return InboundService.normalizeInbound(inboundData)
      })
      .sort((a) => (a.isDefaultInbound ? -1 : 1))
  }

  static async sendTestEmail(inboundId: string): Promise<void> {
    await axiosClient().post<AxiosInboundResponse>(
      `${RESOURCE_PATH}/${inboundId}/test_connection`
    )
  }
}

export default InboundService
