import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactComponent as CloudCheck } from 'assets/images/cloud-check.svg'
import { ReactComponent as CloudUp } from 'assets/images/cloud-up.svg'
import { ReactComponent as NotConnection } from 'assets/images/no_connection.svg'
import FadeIn from 'components/FadeIn'
import { DraftIconContainer } from './SaveDraftIcon.styles'

const SAVED_STATE_TIMEOUT = 30000

export enum DraftStatus {
  UNSAVED = 'UNSAVED',
  IDLE = 'IDLE',
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  OFFLINE = 'OFFLINE',
}

type Props = {
  isSubmitting: boolean
  isOnline: boolean
  initialStatus?: DraftStatus
}

const SaveDraftIcon: React.FC<Props> = ({
  isSubmitting,
  isOnline,
  initialStatus = DraftStatus.UNSAVED,
}) => {
  const [draftStatus, setDraftStatus] = useState(initialStatus)
  const timeout = useRef<NodeJS.Timeout>()

  useEffect(() => {
    if (!isOnline) {
      setDraftStatus(DraftStatus.OFFLINE)
    }

    if (isOnline && !isSubmitting) {
      setDraftStatus(DraftStatus.IDLE)
    }

    if (isSubmitting) {
      setDraftStatus(DraftStatus.SAVING)
    } else if (draftStatus === DraftStatus.SAVING) {
      setDraftStatus(DraftStatus.SAVED)

      if (timeout.current) clearTimeout(timeout.current)

      timeout.current = setTimeout(() => {
        setDraftStatus(DraftStatus.IDLE)
      }, SAVED_STATE_TIMEOUT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting, isOnline])

  switch (draftStatus) {
    case DraftStatus.IDLE:
      return (
        <FadeIn>
          <DraftIconContainer>
            <CloudCheck />
          </DraftIconContainer>
        </FadeIn>
      )

    case DraftStatus.SAVING:
      return (
        <FadeIn>
          <DraftIconContainer>
            <span>
              <FormattedMessage id="updates.drafts.saving" />
            </span>
            <CloudUp />
          </DraftIconContainer>
        </FadeIn>
      )

    case DraftStatus.SAVED:
      return (
        <FadeIn>
          <DraftIconContainer>
            <span>
              <FormattedMessage id="updates.drafts.saved" />
            </span>
            <CloudCheck />
          </DraftIconContainer>
        </FadeIn>
      )

    case DraftStatus.OFFLINE:
      return (
        <FadeIn>
          <DraftIconContainer>
            <span>
              <FormattedMessage id="updates.drafts.noConnection" />
            </span>
            <NotConnection />
          </DraftIconContainer>
        </FadeIn>
      )

    default:
      return null
  }
}

export default SaveDraftIcon
