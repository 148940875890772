import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const DrawerHeader = styled.header`
  align-items: center;
  border-bottom: 0.1rem solid ${color('borderGray')};
  display: flex;
  font-size: 1.4rem;
  height: 5.8rem;
  justify-content: space-between;
  padding: 0 2.4rem;

  > svg {
    font-size: 1.4rem;
    margin-right: 0.8rem;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;
  }
`

export const BtnClose = styled.button`
  align-items: center;
  background-color: ${color('veryLightBlue', 0.4)};
  border-radius: 50%;
  color: ${color('darkBlue')};
  cursor: pointer;
  display: flex;
  height: 3.2rem;
  justify-content: center;
  width: 3.2rem;

  &:hover {
    outline: none;
    color: ${color('primaryBlue')};
  }
`

export const Title = styled.h2<{
  trunc?: boolean
}>`
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;

  ${(props) =>
    props.trunc &&
    css`
      max-width: 27rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

export const IconTitleWrapper = styled.div`
  align-items: baseline;
  display: flex;
  gap: 0.8rem;
  user-select: none;
`
