import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Header = styled.header`
  display: flex;
  max-width: 100%;
  gap: 1.4rem;
`
export const IconContainer = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  position: relative;
  & > div {
    width: 3.6rem;
    height: 3.6rem;
    position: relative;
    margin-left: 0;
  }

  div[class*='UpdateIconFactory_xeroContainer'],
  div[class*='UpdateIconFactory_quickbooksContainer'] {
    padding-top: 0;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  max-width: 100%;
  min-width: 0;
`

export const Title = styled.p`
  font-size: 1.4rem;
  color: ${color('darkBlue')};
  text-transform: uppercase;
  font-weight: bold;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`

export const DateContainer = styled.div`
  color: ${color('lightGray')};
  display: flex;
  align-items: center;

  p {
    font-size: 1.4rem;
  }
`

export const Confidential = styled.span`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  color: ${color('lightGray')};
  svg {
    font-size: 1.3rem;
  }
  p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
`

export const Edited = styled.span`
  font-style: italic;
  font-weight: 400;
  color: ${color('lightGray')};
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-transform: none;
  color: ${color('lightGray')};
  align-self: flex-start;
`

export const DraftLabel = styled.span`
  background-color: ${color('primaryDarkBlue', 0.4)};
  border-radius: 1rem;
  color: ${color('white')};
  font-family: 'Lato';
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.03em;
  line-height: 1.4rem;
  padding: 0.3rem 0.8rem;
  text-transform: uppercase;
  height: fit-content;
  align-self: flex-start;
`
