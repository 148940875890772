import type { Cell, NumberCell, DateCell, TextCell } from '@silevis/reactgrid'

export const CELL_ERROR = 'CELL_ERROR'

export interface BaseCell extends Cell {
  width?: number
  rowIndex: number
}

export interface CustomCell extends BaseCell {
  disabled?: boolean
  autoFilled?: boolean
  optional?: boolean
  error?: string
  centered?: boolean
}

export type CustomNumberCell = NumberCell &
  CustomCell & {
    isPercentage?: boolean
    max?: number
    maxErrorMsgId?: string
    placeholderMsgId?: string
  }

export type CustomDateCell = DateCell &
  CustomCell & {
    maxDate?: Date
    maxDateErrorMsgId?: string
  }

export type CustomTextCell = TextCell & CustomCell

export type RowNumberCell = BaseCell & {
  type: 'rowNumber'
  rowNumber: number
  errors: Set<number>
  warnings: Set<number>
  isHeader?: boolean
}
