import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import * as Styles from './TopPrompt.styles'

interface TopPromptProps {
  icon: IconProp
  title: React.ReactNode
  legend: React.ReactNode
  children?: React.ReactNode
}

const TopPrompt = ({ icon, title, legend, children }: TopPromptProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleWidget = () => {
    setIsOpen((isCurrentlyOpen) => !isCurrentlyOpen)
  }

  return (
    <Styles.StyledWidget onClick={toggleWidget} isOpen={isOpen}>
      <Styles.StyledHeader isOpen={isOpen}>
        <Styles.Wrapper>
          <Styles.StyledIcon icon={icon} />
          <Styles.StyledTitle>{title}</Styles.StyledTitle>
        </Styles.Wrapper>
        <Styles.Wrapper>
          {children}
          <FontAwesomeIcon icon={['far', 'angle-down']} />
        </Styles.Wrapper>
      </Styles.StyledHeader>
      {isOpen && <Styles.StyledLegend>{legend}</Styles.StyledLegend>}
    </Styles.StyledWidget>
  )
}

export default TopPrompt
