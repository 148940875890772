import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from 'utils/theme'
import Tooltip from 'components/Tooltip'

import * as Styles from './ShareWithEntities.styles'
import {
  getImageComponent,
  IconsTypes,
} from '../ShareWithDropdown/components/Icons'

export const UnreachableEmailTooltip = ({ name }) => (
  <Tooltip
    id={`unreachableEmail_${name}`}
    text={<FormattedMessage id="updates.unreachableEmail" />}
    arrowColor="transparent"
    place="right"
  >
    <FontAwesomeIcon
      icon={['far', 'exclamation-triangle']}
      color={theme.colors.yellow}
    />
  </Tooltip>
)

interface ShareWithEntityProps {
  name: string
  imageUrl?: string
  isOwner?: boolean
  showYouLabel?: boolean
  onRemove?: () => void
  unreachable?: boolean
  showCanViewStatus?: boolean
}

const ShareWithEntity: React.FC<ShareWithEntityProps> = ({
  name,
  imageUrl,
  isOwner,
  showYouLabel,
  onRemove,
  unreachable,
  showCanViewStatus = true,
}) => {
  return (
    <Styles.Container data-testid="shared-with-entity">
      <Styles.NameContainer>
        <div>{getImageComponent(IconsTypes.avatar, name, imageUrl)}</div>
        <div>
          {name}
          {showYouLabel && (
            <Styles.UserDetailText>
              (<FormattedMessage id="updates.you" />)
            </Styles.UserDetailText>
          )}
        </div>
      </Styles.NameContainer>
      {isOwner && (
        <Styles.SecondaryText>
          <FormattedMessage id="updates.owner" />
        </Styles.SecondaryText>
      )}
      {!isOwner && (
        <Styles.RightSideContainer>
          {unreachable && <UnreachableEmailTooltip name={name} />}
          {showCanViewStatus && (
            <Styles.SecondaryText>
              <FormattedMessage id="updates.canView" />
            </Styles.SecondaryText>
          )}
          {!!onRemove && (
            <Styles.RemoveIconContainer>
              <FontAwesomeIcon
                icon={['far', 'times']}
                color={theme.colors.darkBlue}
                onClick={onRemove}
                data-testid="remove-recipient"
              />
            </Styles.RemoveIconContainer>
          )}
        </Styles.RightSideContainer>
      )}
    </Styles.Container>
  )
}

export default ShareWithEntity
