import React, { useEffect, useCallback, useRef, useState, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { isEscCode } from 'utils/functions/keyboardEvents'
import { InvestorGroup } from 'utils/types/user'
import Briefing from '../../Briefing'
import { SideBarTitle } from '../../SideBar/SideBar.styles'
import {
  GroupListContainer,
  GroupSelectorContainer,
} from '../GroupSelector.styles'
import { useGroupSelector } from '../useGroupSelector'
import CreateNewGroupButton from './CreateNewGroupButton/CreateNewGroupButton'

interface ExpandableGroupSelectorProps {
  onToggleGroupSection: (isExpanded: boolean) => void
}
const ExpandableGroupSelector: React.FC<ExpandableGroupSelectorProps> = ({
  onToggleGroupSection,
}) => {
  const groupContainerRef = useRef<HTMLDivElement>(null)
  const groupListContainerRef = useRef<HTMLDivElement>(null)
  const [overflow, setOverflow] = useState('hidden')
  const timeoutRef = useRef<any>(null)
  const intl = useIntl()
  const [isExpanded, setIsExpanded] = useState(false)

  const { currentGroup, previousGroup, groups, onSelectGroup } =
    useGroupSelector()

  const filteredGroups = useMemo(
    () => (groups.length > 1 ? groups : []),
    [groups]
  )

  const toggleGroupSelector = useCallback(() => {
    onToggleGroupSection(!isExpanded)
    setIsExpanded((state) => !state)
  }, [isExpanded, onToggleGroupSection])

  const onExpand = useCallback(() => {
    if (isExpanded) {
      groupContainerRef.current!.scrollTop = 0
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef.current)
      }
      setOverflow('hidden')
    } else {
      timeoutRef.current = setTimeout(() => {
        setOverflow('auto')
      }, 400)
    }
    toggleGroupSelector()
  }, [isExpanded, toggleGroupSelector])

  const handleGroupSelect = useCallback(
    (group) => {
      if (isExpanded) {
        groupContainerRef.current!.scrollTop = 0
        if (timeoutRef?.current) {
          clearTimeout(timeoutRef.current)
        }
        setOverflow('hidden')
      }
      onSelectGroup(group)
      toggleGroupSelector()
    },
    [isExpanded, onSelectGroup, toggleGroupSelector]
  )

  const closeGroupSelectorOnEscKeyPressed = useCallback(
    (event) => {
      if (isEscCode(event)) {
        onExpand()
      }
    },
    [onExpand]
  )

  useEffect(() => {
    if (isExpanded) {
      window.addEventListener('keyup', closeGroupSelectorOnEscKeyPressed)
    }
    return () => {
      window.removeEventListener('keyup', closeGroupSelectorOnEscKeyPressed)
    }
  }, [isExpanded, closeGroupSelectorOnEscKeyPressed])

  if (!currentGroup) return null
  return (
    <GroupSelectorContainer
      ref={groupContainerRef}
      isExpanded={isExpanded}
      style={{ overflow }}
    >
      <Briefing
        id="group-settings"
        isMainBriefing
        type="group"
        name={currentGroup!.name}
        logoUrl={currentGroup!.logo?.mediumLogo.url}
        handle={currentGroup!.handle}
        isExpanded={isExpanded}
        onClick={onExpand}
        groupManager={(currentGroup as InvestorGroup)?.parentGroup?.name}
        isButtonEnabled
        clickable
      />
      <GroupListContainer ref={groupListContainerRef}>
        {previousGroup && (
          <>
            <SideBarTitle>
              {intl.formatMessage({ id: 'sidebar.recent' })}
            </SideBarTitle>
            <Briefing
              type="group"
              name={previousGroup!.name}
              logoUrl={previousGroup!.logo?.mediumLogo.url}
              handle={previousGroup!.handle}
              groupManager={(previousGroup as InvestorGroup)?.parentGroup?.name}
              isMainBriefing={false}
              isExpanded={isExpanded}
              onClick={() => handleGroupSelect(previousGroup?.id)}
            />
            <SideBarTitle>
              {intl.formatMessage({ id: 'sidebar.allGroups' })}
            </SideBarTitle>
          </>
        )}
        {filteredGroups.map((group) => (
          <Briefing
            key={group.id}
            type="group"
            name={group.name}
            logoUrl={group.logo?.mediumLogo.url}
            handle={group.handle}
            groupManager={(group as InvestorGroup)?.parentGroup?.name}
            isMainBriefing={false}
            isExpanded={isExpanded}
            onClick={() => handleGroupSelect(group.id)}
            isButtonEnabled={groups.length > 1}
            clickable={groups.length > 1}
            isActive={group.id === currentGroup?.id}
          />
        ))}
        <CreateNewGroupButton
          containerRef={groupListContainerRef}
          groupsLength={filteredGroups.length}
          toggleGroupSelector={toggleGroupSelector}
        />
      </GroupListContainer>
    </GroupSelectorContainer>
  )
}

export default React.memo(ExpandableGroupSelector)
