import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import ButtonToStyle from 'ui/Button'
import Card from 'components/Card'

export const Container = styled.div`
  font-size: 14px;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 2rem;
  min-width: 0;
  text-transform: none;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const UpdateAllButton = styled(ButtonToStyle)`
  svg {
    font-size: 1.6rem;
  }
`

export const LoaderContainer = styled.div`
  padding-bottom: 3rem;
`

export const ZeroStateContainer = styled.div`
  margin-top: 7.8rem;
`

export const NoMetricsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  padding-bottom: 3rem;
`

export const NoMetricsText = styled.div`
  font-size: 18px;
  color: ${color('lightGray')};
`

export const CardHeader = styled(Card.Header)`
  min-width: 0;
`

export const Title = styled.h2<{
  hasClickOnName?: boolean
  isPortfolio?: boolean
}>`
  display: flex;
  gap: 0.6rem;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${color('darkBlue')};
  min-width: 0;

  ${({ hasClickOnName }) =>
    hasClickOnName &&
    css`
      &:hover > span {
        cursor: pointer;
        text-decoration: underline;
      }
    `}

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
