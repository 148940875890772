import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { isActingAsFounder } from 'selectors/auth'
import { getIsFounderVerified } from 'selectors/company'

const useUserData = () => {
  const isCurrentUserActingAsFounder = useAppSelector(isActingAsFounder)
  const isFounderVerified = useAppSelector(getIsFounderVerified)
  return {
    isCurrentUserActingAsFounder,
    isFounderVerified,
  }
}

export default useUserData
