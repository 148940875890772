import { lazy } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import useInvestorBreadcrumb from 'components/Breadcrumb/BreadcrumbNavigation/InvestorGroup/hooks/useInvestorBreadcrumb'
import { investorPaths } from 'components/Breadcrumb/BreadcrumbNavigation/constants/routes'
import useShowBreadcrumb from 'components/Breadcrumb/BreadcrumbNavigation/hooks/useShowBreadcrumb'
import ProtectedRoute from 'components/ProtectedRoute'
import InvestorGroupDashboard from 'containers/Dashboard/InvestorGroupDashboard'
import HoldingIndex from 'containers/HoldingIndex'
import { InvestmentProvider } from 'containers/Investments/InvestmentContext'
import LoaderWrapperInvestorGroup from 'containers/Investments/LoaderWrapperInvestorGroup'
import EditInvestor from 'containers/InvestorManagement/EditInvestor'
import CompanyProfileRouteWrapper from 'containers/Profiles/CompanyProfile/CompanyProfileRouteWrapper'
import InvestmentVehicleProfile from 'containers/Profiles/InvestmentVehicleProfile'
import InvestorProfile from 'containers/Profiles/InvestorProfile'
import CreateGroupWizard from 'containers/Signup/SignupWizard/CreateGroupWizard'
import { CreateNewGroupProvider } from 'containers/Signup/context/CreateNewGroupContextProvider'
import PaddedLayout from 'layouts/PaddedLayout'
import { getCurrentBaseRoute } from 'reducers/breadcrumbSlice'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import InvestorUpdatesRoutesRedirect from './InvestorUpdatesRoutesRedirect'
import UpdatesRoutes from './UpdatesRoutes'
import InvestorCommonRoutes from './commonRoutes/InvestorCommonRoutes'

const InvestmentVehicleEdit = lazy(
  () => import('containers/InvestorManagement/EditInvestmentVehicle')
)
const FundProfile = lazy(() => import('containers/FundProfile'))
const FundHoldingProfile = lazy(
  () => import('containers/Profiles/FundHoldingProfile')
)

const InvestorGroupRoutes = () => {
  const baseRoute = useAppSelector(getCurrentBaseRoute)
  const history = useHistory()

  const onCloseDrawer = () => {
    history.goBack()
  }

  const { showBreadcrumb } = useShowBreadcrumb({
    paths: investorPaths,
  })
  const { navigationComponentFunc } = useInvestorBreadcrumb({
    baseRoute,
  })

  return (
    <Breadcrumb
      showBreadcrumb={showBreadcrumb}
      baseRoute={baseRoute}
      navigationComponentFunc={navigationComponentFunc}
    >
      <Switch>
        <Route path="/create-group">
          <CreateNewGroupProvider>
            <CreateGroupWizard />
          </CreateNewGroupProvider>
        </Route>

        <ProtectedRoute exact path={['/funds', '/funds/:fundId', '/filters']}>
          <InvestmentProvider>
            <LoaderWrapperInvestorGroup isPaddedLayout={false}>
              <FundProfile />
            </LoaderWrapperInvestorGroup>
          </InvestmentProvider>
        </ProtectedRoute>

        <ProtectedRoute exact path={['/investments']}>
          <InvestmentProvider>
            <LoaderWrapperInvestorGroup>
              <InvestorGroupDashboard />
            </LoaderWrapperInvestorGroup>
          </InvestmentProvider>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={['/investments/funds/:fundId', '/investments/filters']}
        >
          <InvestmentProvider>
            <LoaderWrapperInvestorGroup isPaddedLayout={false}>
              <FundProfile />
            </LoaderWrapperInvestorGroup>
          </InvestmentProvider>
        </ProtectedRoute>

        <ProtectedRoute exact path={['/profile', '/profile/edit']}>
          <InvestorProfile />
        </ProtectedRoute>

        <ProtectedRoute path="/profile">
          <UpdatesRoutes />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={[
            '/investment-vehicles/:investmentVehicleId',
            '/investment-vehicles/:investmentVehicleId/edit',
          ]}
        >
          <InvestmentVehicleProfile />
        </ProtectedRoute>

        <ProtectedRoute path="/investment-vehicles/:investmentVehicleId/updates">
          <UpdatesRoutes />
        </ProtectedRoute>

        <ProtectedRoute exact path="/fund-holdings">
          <PaddedLayout>
            <HoldingIndex />
          </PaddedLayout>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={[
            `/fund-holdings/companies/:companyId/:somePath`,
            `/investments/holdings/companies/:companyId`,
            `/investments/funds/:fundId/companies/:companyId`,
            `/fund-holdings/companies/:companyId/metrics/:id`,
            `/fund-holdings/companies/:companyId/metrics/:id/:somePath`,
            `/fund-holdings/companies/:companyId/metrics/:id/:somePath/:someOtherPath`,
          ]}
        >
          <CompanyProfileRouteWrapper prefix="fund-holdings/companies" />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={[
            `/investments/holdings/funds/:fundId`,
            '/funds/:fundId/fund-holding/:fundHoldingId',
            '/fund-holdings/funds/:fundId/:somePath',
          ]}
        >
          <FundHoldingProfile />
        </ProtectedRoute>

        <ProtectedRoute
          path={[
            '/investments/fund-holdings/updates',

            '/investments/holdings/companies/:companyId',
            '/investments/holdings/funds/:fundId',

            '/investments/funds/:fundId',

            '/fund-holdings/funds/:fundId/updates',
            '/fund-holdings/companies/:companyId/updates',
          ]}
        >
          <UpdatesRoutes />
        </ProtectedRoute>

        <ProtectedRoute
          path={[
            '/updates/:updateType/:updateId',
            '/:holdingType/:holdingId/:updateType/:updateId',
          ]}
        >
          <InvestorUpdatesRoutesRedirect />
        </ProtectedRoute>

        <InvestorCommonRoutes />

        <Redirect to="/investments" />
      </Switch>

      <Switch>
        <ProtectedRoute exact path="/profile/edit">
          <EditInvestor isDrawerOpen onCloseDrawer={onCloseDrawer} />
        </ProtectedRoute>

        <ProtectedRoute path="/investment-vehicles/:investmentVehicleId/edit">
          <InvestmentVehicleEdit isDrawerOpen onCloseDrawer={onCloseDrawer} />
        </ProtectedRoute>
      </Switch>
    </Breadcrumb>
  )
}

export default InvestorGroupRoutes
