const serviceWorkerTypes = {
  SW_UPDATE_INSTALLED: 'SW_UPDATE_INSTALLED',
  SW_UPDATE_ACTIVATED: 'SW_UPDATE_ACTIVATED',
}

const sideBarTypes = {
  SWITCH_MAIN_SIDEBAR_SIZE: 'SWITCH_MAIN_SIDEBAR_SIZE',
  SWITCH_MAIN_SIDEBAR_OPEN: 'SWITCH_MAIN_SIDEBAR_OPEN',
}

const layoutTypes = {
  SET_FOOTER_VERTICAL: 'SET_FOOTER_VERTICAL',
}

const listManagementTypes = {
  ADD_ENTITY: 'ADD_ENTITY',
  BULK_ADD_ENTITY: 'BULK_ADD_ENTITY',
  REMOVE_ENTITY: 'REMOVE_ENTITY',
  ADD_LIST: 'ADD_LIST',
  REMOVE_LIST: 'REMOVE_LIST',
  SET_LISTS: 'SET_LISTS',
  SET_LIST_NAME: 'SET_LIST_NAME',
  FILTER_LISTS: 'FILTER_LISTS',
}

const companiesTypes = {
  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',
  SET_CURRENT_COMPANY_LOADING: 'SET_CURRENT_COMPANY_LOADING',
  SET_SIGNUP_COMPANY: 'SET_SIGNUP_COMPANY',
  SET_OWNER: 'SET_OWNER',
  RESET_CURRENT_COMPANY: 'RESET_CURRENT_COMPANY',
  SET_LEGAL_COMPANY: 'SET_LEGAL_COMPANY',
  SET_CURRENT_UPDATES: 'SET_CURRENT_UPDATES',
  SET_COMPANY_PROFILE: 'SET_COMPANY_PROFILE',
  SET_SHOW_COMPANY_NAVIGATION_HEADER: 'SET_SHOW_COMPANY_NAVIGATION_HEADER',
}

const emailTypes = {
  SET_EMAIL_SUBJECT: 'SET_EMAIL_SUBJECT',
  SET_CURRENT_SELECTED_DRAFTS: 'SET_CURRENT_SELECTED_DRAFTS',
}

const preferencesTypes = {
  SET_PREFERENCE: 'SET_PREFERENCE',
}

const portfolioTypes = {
  SET_CURRENT_PORTFOLIO: 'SET_CURRENT_PORTFOLIO',
  SET_PORTFOLIOS: 'SET_PORTFOLIOS',
  SET_INVESTOR_CALCULATION: 'SET_INVESTOR_CALCULATION',
}

const universityTypes = {
  FETCH_UNIVERSITY_ACTIONS: 'FETCH_UNIVERSITY_ACTIONS',
  SET_ACTION_COMPLETED: 'SET_ACTION_COMPLETED',
  SET_USER_ACTION_SKIPPED: 'SET_USER_ACTION_SKIPPED',
  SET_GROUP_ACTION_SKIPPED: 'SET_GROUP_ACTION_SKIPPED',
  SET_UNIVERSITY_PROGRESS: 'SET_UNIVERSITY_PROGRESS',
  SET_UNIVERSITY_ENABLED: 'SET_UNIVERSITY_ENABLED',
  SET_UNIVERSITY_ACTION_ENABLED: 'SET_UNIVERSITY_ACTION_ENABLED',
}

const metricsTypes = {
  SET_UPDATE_ALL_METRICS_DISMISSED: 'SET_UPDATE_ALL_METRICS_DISMISSED',
}

const investorManagementTypes = {
  SET_SHOW_SUGGEST_CREATE_INVESTMENT_VEHICLE_MODAL:
    'SET_SHOW_SUGGEST_CREATE_INVESTMENT_VEHICLE_MODAL',
  DISABLE_SUGGEST_CREATE_INVESTMENT_VEHICLE_MODAL:
    'DISABLE_SUGGEST_CREATE_INVESTMENT_VEHICLE_MODAL',
  SET_CURRENT_INVESTOR: 'SET_CURRENT_INVESTOR',
  SET_CURRENT_INVESTMENT_VEHICLE: 'SET_CURRENT_INVESTMENT_VEHICLE',
}

export default {
  ...serviceWorkerTypes,
  ...sideBarTypes,
  ...listManagementTypes,
  ...companiesTypes,
  ...preferencesTypes,
  ...portfolioTypes,
  ...emailTypes,
  ...layoutTypes,
  ...universityTypes,
  ...metricsTypes,
  ...investorManagementTypes,
}
