import { FormattedMessage } from 'react-intl'

import Button from 'ui/Button'
import IconButton from 'ui/IconButton'
import {
  CHAT_LINK,
  FILES_PAGE_LINK,
  GLOSSARY_LINK,
  UPDATES_HELP_LINK,
} from 'utils/constants/links'
import { assertUnreachable } from 'utils/functions/utils'
import { AssistanceCardType } from 'utils/types/onboardingModal'

import chat from 'assets/images/onboarding-chat.png'
import helpChat from 'assets/images/helpAssistance/chat.png'
import createUpdate from 'assets/images/onboarding-create-update.png'
import holding from 'assets/images/onboarding-holding.png'
import helpHolding from 'assets/images/helpAssistance/holding.png'
import inbound from 'assets/images/onboarding-inbound.png'
import investPortfolio from 'assets/images/onboarding-invest-portfolio.png'
import helpInvestPortfolio from 'assets/images/helpAssistance/invest-portfolio.png'
import pipeline from 'assets/images/onboarding-pipeline.png'
import helpPipeline from 'assets/images/helpAssistance/pipeline-portfolio.png'
import suggestedUpdate from 'assets/images/onboarding-suggested-update.png'
import update from 'assets/images/onboarding-update.png'
import helpUpdate from 'assets/images/helpAssistance/update.png'
import uploadFiles from 'assets/images/onboarding-upload-files.png'
import helpFiles from 'assets/images/helpAssistance/files.png'

import { useHelpArticles } from 'components/OnboardingModal/useHelpArticles'
import {
  AbsoluteCardWrapper,
  AssistanceContainer,
  CardContainer,
  CardImage,
  CardRowContainer,
  Container,
  Content,
  ContentWrapper,
  Question,
  Separator,
} from './Assistance.styles'

export const getFeatureHelpData = (type: AssistanceCardType) => {
  switch (type) {
    case AssistanceCardType.PIPELINE:
      return {
        imageSrc: pipeline,
        link: GLOSSARY_LINK,
        largeImageSrc: helpPipeline,
      }
    case AssistanceCardType.HOLDING:
      return {
        imageSrc: holding,
        link: GLOSSARY_LINK,
        largeImageSrc: helpHolding,
      }

    case AssistanceCardType.UPDATES:
      return {
        imageSrc: update,
        link: GLOSSARY_LINK,
        largeImageSrc: helpUpdate,
      }
    case AssistanceCardType.UPDATE:
      return {
        imageSrc: update,
        link: GLOSSARY_LINK,
        largeImageSrc: helpUpdate,
      }
    case AssistanceCardType.CREATE_UPDATE:
      return { imageSrc: createUpdate, link: UPDATES_HELP_LINK }
    case AssistanceCardType.INBOUND:
      return { imageSrc: inbound, link: GLOSSARY_LINK }
    case AssistanceCardType.SUGGESTED_UPDATE:
      return { imageSrc: suggestedUpdate, link: GLOSSARY_LINK }
    case AssistanceCardType.UPLOAD_FILES:
      return {
        imageSrc: uploadFiles,
        link: FILES_PAGE_LINK,
        largeImageSrc: helpFiles,
      }
    case AssistanceCardType.CHAT:
      return { imageSrc: chat, link: CHAT_LINK, largeImageSrc: helpChat }
    case AssistanceCardType.INVEST_PORTFOLIO:
      return {
        imageSrc: investPortfolio,
        link: GLOSSARY_LINK,
        largeImageSrc: helpInvestPortfolio,
      }

    default:
      return assertUnreachable(type)
  }
}

export const AssistanceCard = ({ type }: { type: AssistanceCardType }) => {
  const { imageSrc, link } = getFeatureHelpData(type)

  return (
    <CardContainer
      key={type}
      type={type}
      backgroundSrc={imageSrc}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Question>
        <FormattedMessage id={`onboardingModal.help.${type}`} />
      </Question>
    </CardContainer>
  )
}

export const AssistanceCardLarge = ({ type }: { type: AssistanceCardType }) => {
  const { largeImageSrc, link, imageSrc } = getFeatureHelpData(type)
  const { showAssistantCard, onCloseAssistanceCard } = useHelpArticles()

  if (!showAssistantCard(type)) return null

  return (
    <AbsoluteCardWrapper>
      <CardRowContainer>
        <CardImage key={type} backgroundSrc={largeImageSrc || imageSrc} />
        <ContentWrapper>
          <Content>
            <h1>
              <FormattedMessage id={`onboardingModal.help.${type}`} />
            </h1>
            <p>
              <FormattedMessage id={`onboardingModal.helpDesc.${type}`} />
            </p>
            <Separator />
            <Button
              secondary
              as="a"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="onboardingModal.learnMore" />
            </Button>
          </Content>
          <IconButton
            icon="times"
            onClick={() => onCloseAssistanceCard(type)}
            border={false}
            buttonType="square"
            buttonSize="3rem"
            minSize="3rem"
            iconFontSize="1.6rem"
          />
        </ContentWrapper>
      </CardRowContainer>
    </AbsoluteCardWrapper>
  )
}

const Assistance = () => {
  return (
    <Container>
      <AssistanceContainer>
        {Object.values(AssistanceCardType)
          .filter((t) => t !== AssistanceCardType.UPDATE)
          .map((type) => (
            <AssistanceCard key={type} type={type} />
          ))}
      </AssistanceContainer>
    </Container>
  )
}

export default Assistance
