import Switch from 'components/Switch'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useFormikContext } from 'components/Form/hooks'

import AddCompanyForm from '../AddCompanyForm/AddCompanyForm'
import AddFundPortfoliosForm from '../AddFundPortfoliosForm/AddFundPortfoliosForm'
import { AddHoldingForm, HoldingType } from '../types'
import * as Styles from './SingleHoldingForm.styles'

const SingleHoldingForm: React.FC = () => {
  const intl = useIntl()
  const { values, setFieldValue } = useFormikContext<AddHoldingForm>()

  return (
    <Styles.Container>
      <Styles.FormTitle>
        <FormattedMessage id="addHolding.completeForm" />
      </Styles.FormTitle>
      <Styles.SwitchContainer>
        <Switch
          valueId={values.type}
          onChange={(value) => setFieldValue('type', value)}
          options={[
            {
              id: HoldingType.COMPANY,
              label: intl.formatMessage({
                id: 'addHolding.company',
              }),
            },
            {
              id: HoldingType.FUND,
              label: intl.formatMessage({
                id: 'addHolding.fund',
              }),
            },
          ]}
        />
      </Styles.SwitchContainer>
      {values.type === HoldingType.COMPANY && <AddCompanyForm />}
      {values.type === HoldingType.FUND && <AddFundPortfoliosForm />}
    </Styles.Container>
  )
}

export default SingleHoldingForm
