export const SIGN_IN = '/sign-in'
export const SIGN_IN_HANDLE = '/sign-in/:handle'
export const SIGN_OUT = '/sign-out'
export const ENTER_TWO_FACTOR_CODE = '/enter-two-factor-code'

export const ACCESS_REQUEST = '/access-request'

export const PASSWORD_RESET = '/password-reset'
export const PASSWORD_CHANGE = '/password-change'

export const REDIRECT_AUTH0 = '/redirect/auth0'
export const REDIRECT_AUTH0_APP = '/redirect/auth0/app'
export const REDIRECT_DESKTOP = '/desktop-redirect'

export const EMAIL_CONFIRMATION = '/email-confirmation'

const autRoutes = [
  SIGN_IN,
  SIGN_IN_HANDLE,
  SIGN_OUT,
  ACCESS_REQUEST,
  PASSWORD_RESET,
  ENTER_TWO_FACTOR_CODE,
  PASSWORD_CHANGE,
  REDIRECT_AUTH0,
  REDIRECT_DESKTOP,
  REDIRECT_AUTH0_APP,
  EMAIL_CONFIRMATION,
]

export default autRoutes
