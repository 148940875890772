import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Nullable } from 'utils/types/common'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import usePortfolioInvestments from './usePortfolioInvestments'
import useFilterInvestments from './useFilterInvestments'
import {
  ALL_ENTITIES_FILTER_OPTION,
  ALL_FOLLOWED_HOLDINGS_OPTION_ID,
  ALL_INVESTMENT_OPTION_ID,
  BaseOption,
  EntityId,
  InvestmentsNavigationOption,
  UNSAVED_FILTER_OPTION_ID,
} from './types'
import {
  getDefaultNavigationOptions,
  getSelectedPortfolioIndex,
  getVisibleNavigationOptions,
  shouldAllFollowingHoldingsOptionBeVisible,
  shouldAllInvestmentOptionBeVisible,
} from './helpers'

interface Props {
  setCreatePortfolioModalOpen: Dispatch<SetStateAction<boolean>>
}

const useInvestments = ({ setCreatePortfolioModalOpen }: Props) => {
  const [selectedPortfolioIndex, setSelectedPortfolioIndex] =
    useState<Nullable<number>>(0)

  const [displayBigPortfolioNavigation, setDisplayBigPortfolioNavigation] =
    useState(false)

  const [portfolioTypeFilterOptions, setPortfolioTypeFilterOptions] = useState<
    BaseOption[]
  >(ALL_ENTITIES_FILTER_OPTION)

  const [showPortfolioTypeFilterButton, setShowPortfolioTypeFilterButton] =
    useState(false)

  const [navigationOptions, setNavigationOptions] =
    useState<Nullable<InvestmentsNavigationOption[]>>(null)

  const history = useHistory()
  const { id: portfolioId, fundId } = useParams<{
    id?: string
    fundId?: string
  }>()
  const entityId = portfolioId || fundId

  const filtersEnabled = !useAppSelector(isActingAsInvestorGroup) // TODO LP PROFILE: remove this when the filters are implemented for the investor group

  const hasPortfolioTypesFiltersApplied = useMemo<boolean>(() => {
    return portfolioTypeFilterOptions.some(
      (option) => option.showOption && !option.status
    )
  }, [portfolioTypeFilterOptions])

  const isAllInvestmentOptionInNavigation = useMemo<boolean>(() => {
    if (navigationOptions === null) return false

    return navigationOptions?.some(
      (option) => option.id === ALL_INVESTMENT_OPTION_ID
    )
  }, [navigationOptions])

  const {
    fetchedPortfolios,
    isLoadingPortfolios,
    portfolioTypes,
    portfolioNavigationOptions,
    isMakingPinnedReq,
    hasOneTrackPortfolio,
    changePortfolioName,
    onPortfolioCreated,
    onPortfolioDeleted,
    togglePinPortfolio,
  } = usePortfolioInvestments({
    setCreatePortfolioModalOpen,
    setDisplayBigPortfolioNavigation,
    setPortfolioTypeFilterOptions,
  })

  const {
    createdFilters,
    isLoadingFilters,
    normalizedFilters,
    filterType,
    filterNavigationOptions,
    hasUnsavedFilter,
    isRefetchingFilters,
    onInvestmentFilterCreated,
    onFilterDeleted,
  } = useFilterInvestments({
    setDisplayBigPortfolioNavigation,
    setPortfolioTypeFilterOptions,
  })

  const isShowingAllInvestmentsOption =
    history.location.pathname === '/investments'
  const isShowingAllFollowedHoldingsOption =
    history.location.pathname === '/investments/all-followed-holdings'
  const isCreatingFilter = history.location.pathname === '/investments/filters'
  const isShowingUnsavedFiltersOption = hasUnsavedFilter && isCreatingFilter

  // So far we have 3 different cases:
  // 1. We are showing the "All investments" option.
  // 2. We are showing the "Unsaved filters" option.
  // 3. We are showing the selected option (portfolio or filter entity).
  useEffect(() => {
    if (isLoadingPortfolios || (filtersEnabled && isLoadingFilters)) {
      return
    }

    let selectedIndex = 0

    const isAllInvestmentOptionVisible = shouldAllInvestmentOptionBeVisible(
      portfolioNavigationOptions
    )

    const isAllFollowedHoldingsOptionVisible =
      shouldAllFollowingHoldingsOptionBeVisible(portfolioNavigationOptions)

    const options = getDefaultNavigationOptions({
      portfolioNavigationOptions,
      filterNavigationOptions,
      hasUnsavedFilter,
      isAllInvestmentOptionVisible,
      isAllFollowedHoldingsOptionVisible,
    })

    if (options.length === 0) {
      setNavigationOptions(options)
      return
    }

    if (isShowingAllFollowedHoldingsOption) {
      const newNavigationOptions = getVisibleNavigationOptions({
        options,
        portfolioTypeFilterOptions,
        isShowingAllFollowedHoldingsOption,
      })

      selectedIndex = getSelectedPortfolioIndex(
        newNavigationOptions,
        ALL_FOLLOWED_HOLDINGS_OPTION_ID
      )

      setNavigationOptions(newNavigationOptions)
      setSelectedPortfolioIndex(selectedIndex)
      return
    }

    if (!isAllInvestmentOptionVisible && isShowingAllInvestmentsOption) {
      history.push(options[0].url)
      return
    }

    if (isShowingAllInvestmentsOption) {
      const newNavigationOptions = getVisibleNavigationOptions({
        options,
        portfolioTypeFilterOptions,
        isAllFollowedHoldingsOptionVisible,
        isAllInvestmentOptionVisible,
        isShowingAllFollowedHoldingsOption,
        isShowingAllInvestmentsOption,
        isShowingUnsavedFiltersOption,
      })

      selectedIndex = getSelectedPortfolioIndex(
        newNavigationOptions,
        ALL_INVESTMENT_OPTION_ID
      )

      setNavigationOptions(newNavigationOptions)
      setSelectedPortfolioIndex(selectedIndex)
      return
    }

    if (isShowingUnsavedFiltersOption) {
      const newNavigationOptions = getVisibleNavigationOptions({
        options,
        portfolioTypeFilterOptions,
        isShowingUnsavedFiltersOption,
      })

      selectedIndex = getSelectedPortfolioIndex(
        newNavigationOptions,
        UNSAVED_FILTER_OPTION_ID
      )

      setNavigationOptions(newNavigationOptions)
      setSelectedPortfolioIndex(selectedIndex)
      return
    }

    const newNavigationOptions = getVisibleNavigationOptions({
      options,
      portfolioTypeFilterOptions,
      isAllInvestmentOptionVisible: shouldAllInvestmentOptionBeVisible(
        portfolioNavigationOptions
      ),
      isAllFollowedHoldingsOptionVisible:
        shouldAllFollowingHoldingsOptionBeVisible(portfolioNavigationOptions),
      hasUnsavedFilter,
      entityId,
    })

    selectedIndex = getSelectedPortfolioIndex(newNavigationOptions, entityId!)

    setNavigationOptions(newNavigationOptions)
    setSelectedPortfolioIndex(selectedIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    entityId,
    filtersEnabled,
    hasUnsavedFilter,
    history,
    isCreatingFilter,
    isLoadingFilters,
    isLoadingPortfolios,
    isShowingAllFollowedHoldingsOption,
    isShowingAllInvestmentsOption,
    isShowingUnsavedFiltersOption,
    portfolioNavigationOptions,
    portfolioTypeFilterOptions,
  ])

  // This useEffect is used to create the toggle options for the portfolio type filter
  // (options inside the eye icon in the navigation bar)
  useEffect(() => {
    if (!portfolioTypes || !filterType) return

    const allTypes: EntityId[] = [...portfolioTypes, filterType]

    setPortfolioTypeFilterOptions((prev) => {
      return prev.map((option) => ({
        ...option,
        showOption: allTypes.includes(option.id),
      }))
    })
  }, [filterType, portfolioTypes])

  // This useEffect is in charge of showing or hiding the portfolio type filter button
  // where the toggle options are displayed
  useEffect(() => {
    const portfolioTypesCount = portfolioTypes?.length || 0
    const filterTypesCount = normalizedFilters?.length || 0
    const showFilterButton = portfolioTypesCount > 0 || filterTypesCount > 0

    if (!showFilterButton) {
      setShowPortfolioTypeFilterButton(false)
      return
    }

    setShowPortfolioTypeFilterButton(true)
  }, [isAllInvestmentOptionInNavigation, normalizedFilters, portfolioTypes])

  const selectOption = useCallback(
    (option: InvestmentsNavigationOption) => {
      history.push(option.url)
    },

    [history]
  )

  const resetPortfolioTypeFilter = useCallback(() => {
    const allTypes: EntityId[] = [...portfolioTypes, filterType]

    const newPortfolioTypeFilterOptions = portfolioTypeFilterOptions.map(
      (option) => ({
        ...option,
        showOption: allTypes.includes(option.id),
        status: true,
      })
    )

    setPortfolioTypeFilterOptions(newPortfolioTypeFilterOptions)
  }, [filterType, portfolioTypeFilterOptions, portfolioTypes])

  const toggleEntityTypeFilter = useCallback(
    (id: EntityId) => {
      const newPortfolioTypeFilterOptions = portfolioTypeFilterOptions.map(
        (option) => {
          if (option.id === id) {
            return {
              ...option,
              status: !option.status,
            }
          }

          return option
        }
      )

      setPortfolioTypeFilterOptions(newPortfolioTypeFilterOptions)
    },
    [portfolioTypeFilterOptions]
  )

  return {
    isLoadingPortfolios,
    isLoadingFilters,
    normalizedNavigationOptions: navigationOptions,
    fetchedPortfolios,
    isAllInvestmentOptionInNavigation: true,
    displayBigPortfolioNavigation,
    selectedPortfolioIndex,
    isMakingPinnedReq,
    portfolioTypeFilterOptions,
    showPortfolioTypeFilterButton,
    hasPortfolioTypesFiltersApplied,
    createdFilters,
    isCreatingFilter,
    isRefetchingFilters,
    hasOneTrackPortfolio,
    resetPortfolioTypeFilter,
    toggleEntityTypeFilter,
    setDisplayBigPortfolioNavigation,
    selectOption,
    changePortfolioName,
    togglePinPortfolio,
    onPortfolioCreated,
    onInvestmentFilterCreated,
    onPortfolioDeleted,
    onFilterDeleted,
    setNavigationOptions,
  }
}

export default useInvestments
