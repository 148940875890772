import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { color } from 'utils/functions/colors'
import { breakpoint, maxSize } from 'utils/constants/breakpoint'
import { Sparkle } from 'ui/Icons'
import { Scroll } from 'App.styles'

interface AnimatedElement {
  isAnimationEnabled: boolean
  isOpen: boolean
}

const Position = css`
  position: fixed;
  z-index: 99;
`

const CelestePaddingTop = '3.8rem'
const CelesteMessageHeight = '14.5rem'
const CelesteMessagePaddingBottom = '3.5rem'
const HalfActionHeight = '2.6rem'

const Animation = css`
  animation-duration: 1s;
  animation-fill-mode: forwards;
`

const DisabledGradientColor = css`
  background: linear-gradient(
    331.87deg,
    ${color('celesteDark', 0.5)} 13.06%,
    ${color('celesteLight', 0.5)} 104.97%
  );
`

const GradientColor = css`
  background: linear-gradient(
    331.87deg,
    ${color('celesteDark')} 13.06%,
    ${color('celesteLight')} 104.97%
  );
`

const CenteredItems = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CelesteSize = css`
  width: calc(5.6rem - 0.5px);
  height: calc(5.6rem - 0.5px);
  border-radius: 50%;
`

export const CelesteButtonContainer = styled.div<{
  isSideBarOpen: boolean
}>`
  bottom: 2.4rem;
  right: 4.2rem;

  ${Position}

  ${(props) =>
    props.isSideBarOpen &&
    css`
      @media (max-width: ${breakpoint.sm}) {
        display: none;
      }
    `}

  @media ${maxSize.md} {
    right: 2.4rem;
  }
`

export const CelesteButton = styled.button<
  AnimatedElement & { isDisabled?: boolean }
>`
  box-shadow: 0px 0px 20px ${color('darkBlue', 0.25)};
  background-color: white;

  ${CelesteSize}
  ${Animation}
  ${CenteredItems}

  animation-duration: 0.5s;

  ${({ isOpen }) =>
    css`
      animation-name: ${isOpen ? 'open-celeste-button' : ''};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}

  &:focus {
    outline: none;
  }

  &:hover {
    ${({ isOpen, isDisabled }) =>
      !isOpen &&
      !isDisabled &&
      css`
        transition: 0.5s;
        filter: brightness(1.25);
      `}
  }

  @keyframes open-celeste-button {
    from {
      background: white;
    }

    to {
      ${GradientColor}
    }
  }
`

export const CelesteButtonContent = styled.div<
  AnimatedElement & { disabled?: boolean }
>`
  width: calc(4.5rem - 0.5px);
  height: calc(4.5rem - 0.5px);
  border-radius: 50%;

  ${Animation}
  ${({ disabled }) => (disabled ? DisabledGradientColor : GradientColor)}
  ${CenteredItems}

  
  ${({ isOpen, isAnimationEnabled }) =>
    isAnimationEnabled &&
    css`
      animation-name: ${isOpen
        ? 'open-celeste-button-content'
        : 'close-celeste-button-content'};
    `}

  @keyframes open-celeste-button-content {
    to {
      background-color: white;
      background-image: none;
    }
  }

  @keyframes close-celeste-button-content {
    from {
      opacity: 0.2;
      ${GradientColor}
    }

    to {
      opacity: 1;
      z-index: 1;
    }
  }
`

export const OptionsContainer = styled(motion.div)`
  ${Position}
  right: 2.4rem;
  bottom: 9.2rem;
  max-height: calc(var(--viewHeight, 100vh) - 11rem);
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: ${CelestePaddingTop} 1.4rem 1.1rem 1.4rem;
  border-radius: 8px;
  width: 32rem;
  filter: drop-shadow(0px 0px 15px ${color('darkBlue', 0.15)});
  background: linear-gradient(
    180deg,
    ${color('primaryBlue')}
      calc(
        ${CelestePaddingTop} + ${CelesteMessageHeight} +
          ${CelesteMessagePaddingBottom} + ${HalfActionHeight}
      ),
    white 0,
    white 100%
  );

  @media (max-width: ${breakpoint.md}) {
    max-height: calc(var(--viewHeight, 100vh) - 18rem);
  }
`

export const OptionsGroup = styled.div`
  width: 100%;
  ${Scroll()}
  overflow-x: hidden;
  padding: 1.1rem 1.4rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const CelesteMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-align: center;
  white-space: break-spaces;
  height: ${CelesteMessageHeight};
  margin-bottom: ${CelesteMessagePaddingBottom};
`

export const Title = styled.span`
  font-size: 1.8rem;
`

export const HelpMessage = styled.span`
  font-size: 1.4rem;
`

const Icons = css`
  position: absolute;
  z-index: 3;
  ${Animation}
`

export const StyledPlusIcon = styled(FontAwesomeIcon)<AnimatedElement>`
  ${Icons}
  opacity: 0;
  z-index: 4;
  color: ${color('primaryBlue')};
  margin: auto;
  min-width: 2rem;
  height: 2rem;

  ${({ isOpen, isAnimationEnabled }) =>
    isAnimationEnabled &&
    css`
      animation-name: ${isOpen ? 'open-plus' : 'close-plus'};
    `}

  @keyframes open-plus {
    from {
      transform: rotate(0deg);
      opacity: 0.1;
    }

    to {
      transform: rotate(45deg);
      opacity: 1;
    }
  }

  @keyframes close-plus {
    from {
      transform: rotate(45deg);
      opacity: 1;
    }

    to {
      transform: rotate(0deg);
      opacity: 0;
    }
  }
`

export const StyledSparkleIcon = styled(Sparkle)<AnimatedElement>`
  ${Icons}
  transform: rotate(0deg);
  opacity: 1;

  ${({ isOpen, isAnimationEnabled }) =>
    isAnimationEnabled &&
    css`
      animation-name: ${isOpen ? 'open-sparkle' : 'close-sparkle'};
    `}

  @keyframes open-sparkle {
    from {
      transform: rotate(0deg);
      opacity: 1;
    }

    to {
      transform: rotate(35deg);
      opacity: 0;
    }
  }

  @keyframes close-sparkle {
    from {
      transform: rotate(35deg);
      opacity: 0;
    }

    to {
      transform: rotate(0deg);
      opacity: 1;
    }
  }
`

export const DegradedAnimation = styled.div<AnimatedElement>`
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: -1;

  ${GradientColor}
  ${CelesteSize}
  ${Animation}

  ${({ isOpen, isAnimationEnabled }) =>
    isAnimationEnabled &&
    css`
      animation-name: ${isOpen ? 'open-degraded' : 'close-degraded'};
      animation-duration: ${isOpen ? '1s' : '0.5s'};
    `}

  @keyframes open-degraded {
    from {
      opacity: 0.7;
      z-index: 2;
    }

    to {
      opacity: 0;
      z-index: -1;
      background-color: white;
    }
  }

  @keyframes close-degraded {
    from {
      opacity: 0.5;
      ${GradientColor}
      z-index: 2;
    }

    to {
      opacity: 0;
      background-color: white;
      background-image: none;
    }
  }
`
