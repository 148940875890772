import React from 'react'
import ColHeader from '../ColHeader'
import { ScrollableCellsContainer } from '../Row/Row.styles'

import { useTableContext } from '../TableContextProvider'
import { Column, DataType } from '../types'
import { TableHeader } from './Header.styles'

const Header = () => {
  const {
    columns,
    embeddedTable,
    scrollableColumnsPaddingRight,
    rowContextMenu,
  } = useTableContext()
  const nonFixedCols = columns.filter((col) => !col.fixed && !col.hidden)

  if (rowContextMenu) {
    nonFixedCols.push({
      id: 'context-menu-dropdown',
      sortDisabled: true,
      minWidth: 45,
    } as Column<DataType>)
  }
  const renderCols = (cols: Column<DataType>[]) =>
    cols.map((col) => <ColHeader key={col.id} column={col} />)

  return (
    <TableHeader>
      <ScrollableCellsContainer
        borderWidth=".2rem"
        embeddedTable={embeddedTable}
        countColumns={nonFixedCols.length}
        paddingRight={scrollableColumnsPaddingRight}
      >
        {renderCols(nonFixedCols)}
      </ScrollableCellsContainer>
    </TableHeader>
  )
}

export default React.memo(Header)
