/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Badge from 'components/Badge'

import {
  BadgeContainer,
  LinkedinURL,
  TeamMemberContainer,
  TeamMemberEmail,
  TeamMemberName,
  TeamMemberTitle,
  Row,
  LinkedinIcon,
  EmailIcon,
} from './CompanyTeamMember.styles'

const CompanyTeamMember = ({ employee, isCompanyProfile = false }) => {
  return (
    <TeamMemberContainer isCompanyProfile={isCompanyProfile}>
      <TeamMemberName className="fs-exclude" level="h3">
        {`${employee?.firstName} ${employee?.lastName}`}
      </TeamMemberName>

      <TeamMemberTitle className="fs-exclude" title={employee.title}>
        {employee.title || 'N/A'}
      </TeamMemberTitle>

      <Row>
        {employee?.email && (
          <TeamMemberEmail
            className="fs-exclude"
            title={employee.email}
            rel="noopener noreferrer"
            target="_blank"
            href={`mailto:${employee.email}`}
          >
            <EmailIcon icon={['far', 'envelope']} />
          </TeamMemberEmail>
        )}

        {employee?.linkedinUrl && (
          <LinkedinURL
            className="fs-exclude"
            target="_blank"
            rel="noopener noreferrer"
            href={employee.linkedinUrl}
          >
            <LinkedinIcon
              icon={['fab', 'linkedin-in']}
              title={employee.linkedinUrl}
            />
          </LinkedinURL>
        )}
        {employee.founder && (
          <BadgeContainer withMargin={employee?.email || employee.linkedinUrl}>
            <Badge>
              <FormattedMessage id="general.founder" />
            </Badge>
          </BadgeContainer>
        )}
      </Row>
    </TeamMemberContainer>
  )
}

CompanyTeamMember.defaultProps = {
  employee: {},
}

CompanyTeamMember.propTypes = {
  employee: PropTypes.object,
  isCompanyProfile: PropTypes.bool,
}

export default CompanyTeamMember
