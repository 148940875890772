interface Props {
  className?: string
}

export const FileLockIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="17"
    height="15"
    viewBox="0 0 17 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33 4.91C9.12 4.91 8.92 4.91 8.72 4.91C8.16 4.91 7.61 4.92 7.05 4.9C6.56 4.89 6.23 4.54 6.23 4.05C6.22 3.38 6.23 2.71 6.23 2.04C6.23 1.98 6.23 1.92 6.23 1.83C6.15 1.83 6.09 1.83 6.03 1.83C4.66 1.83 3.29 1.83 1.92 1.83C1.49 1.83 1.31 2.01 1.31 2.43C1.31 5.89 1.31 9.36 1.31 12.82C1.31 13.25 1.47 13.41 1.9 13.41C3.97 13.41 6.05 13.41 8.12 13.41C8.38 13.41 8.62 13.47 8.77 13.71C9.05 14.17 8.76 14.69 8.21 14.72C8.18 14.72 8.15 14.72 8.12 14.72C6.03 14.72 3.94 14.72 1.86 14.72C1.04 14.72 0.45 14.34 0.14 13.58C0.05 13.37 0.01 13.12 0.01 12.89C0 9.38 0 5.86 0 2.34C0 1.44 0.55 0.75 1.42 0.54C1.57 0.51 1.72 0.5 1.87 0.5C3.48 0.5 5.09 0.5 6.7 0.5C7.27 0.5 7.75 0.69 8.15 1.1C8.78 1.74 9.41 2.37 10.05 3C10.46 3.4 10.68 3.88 10.65 4.46C10.64 4.73 10.65 5.01 10.62 5.28C10.58 5.6 10.32 5.8 10 5.82C9.71 5.83 9.43 5.62 9.36 5.31C9.35 5.19 9.35 5.06 9.33 4.91Z"
      fill="inherit"
    />
    <path
      d="M15.09 9.35008C15.3 9.43008 15.49 9.49008 15.66 9.59008C15.88 9.72008 15.98 9.94008 15.99 10.1801C16 10.7101 16 11.2401 16 11.7701C16 12.4701 16.01 13.1701 15.99 13.8601C15.98 14.3601 15.7 14.6501 15.2 14.7101C15.14 14.7201 15.08 14.7201 15.03 14.7201C13.6 14.7201 12.16 14.7201 10.73 14.7201C10.39 14.7201 10.1 14.6301 9.93 14.3201C9.85 14.1601 9.79 13.9701 9.79 13.8001C9.78 12.6201 9.78 11.4501 9.79 10.2701C9.79 9.77008 10.08 9.49008 10.67 9.39008C10.67 9.33008 10.67 9.27008 10.67 9.21008C10.68 8.75008 10.66 8.29008 10.7 7.83008C10.81 6.50008 11.97 5.74008 13.11 5.87008C13.99 5.97008 14.63 6.42008 14.94 7.26008C15.04 7.53008 15.07 7.83008 15.09 8.12008C15.11 8.49008 15.08 8.86008 15.08 9.24008C15.09 9.29008 15.09 9.34008 15.09 9.35008ZM13.79 9.39008C13.79 8.90008 13.81 8.42008 13.79 7.94008C13.77 7.48008 13.38 7.16008 12.91 7.16008C12.43 7.16008 12.06 7.48008 12.03 7.94008C12 8.40008 12.02 8.86008 12.01 9.32008C12.01 9.34008 12.02 9.36008 12.02 9.39008C12.6 9.39008 13.19 9.39008 13.79 9.39008Z"
      fill="inherit"
    />
  </svg>
)
