import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const AnalyticRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

AnalyticRow.Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`

AnalyticRow.IconContainer = styled.div`
  position: relative;
  align-self: flex-start;
`

AnalyticRow.Icon = styled.div`
  border-radius: 1.8rem;
  border: 0.1rem solid
    ${(props) => (props.isReshare ? color('purple')(props) : '#c9cfe4')};
  background: #ffffff;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) =>
      props.isReshare ? color('purple')(props) : color('lightGray')(props)};

    font-size: 1.6rem;
  }
`

AnalyticRow.Line = styled.span`
  height: ${(props) => props.height}px;
  position: absolute;
  top: 3.8rem;
  width: 0;
  border-right: 0.2rem dashed ${color('veryLightGray')};
  left: 1.7rem;
`

AnalyticRow.Date = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  line-height: 1.4rem;
`

AnalyticRow.Text = styled.p`
  color: ${color('darkGray')};
  font-style: italic;
  font-size: 1.4rem;
`
