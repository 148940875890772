import { BackendEvent } from 'containers/Logs/models/backend-events-mapper/BackendEventsMapper'
import { LogType } from 'containers/Logs/models/types'
import { useEffect } from 'react'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import LogService, { LOG_PAGE_SIZE } from '../../../api/LogService'

const useInfiniteLogsQuery = <T>(
  entityId: string,
  logType: LogType,
  queryOptions: UseInfiniteQueryOptions<{
    logs: BackendEvent<T>[]
    page: number
  }> = {}
) => {
  const {
    ref: infiniteScrollRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()

  const query = useInfiniteQuery(
    ['logs', entityId, logType],
    async ({ pageParam = 1 }) => {
      try {
        const logs = await LogService.fetchLogs<T>(entityId, logType, pageParam)

        if (logs.length < LOG_PAGE_SIZE) {
          setInfiniteScrollEnabled(false)
        }

        return {
          logs,
          page: pageParam,
        }
      } catch (error) {
        return {
          logs: [],
          page: 1,
        }
      }
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1
      },
      ...queryOptions,
    }
  )

  useEffect(() => {
    if (onScreen && !query.isFetching) {
      query.fetchNextPage()
    }
  }, [onScreen, query])

  return {
    ...query,
    infiniteScrollRef,
  }
}

export default useInfiniteLogsQuery
