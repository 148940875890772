import React, { ReactNode } from 'react'
import { ReshareTokenSet } from 'utils/functions/updates'
import ReshareHistory from './ReshareHistory'

interface ReshareHistoryWrapperProps {
  children: ReactNode
  resharesByToken: ReshareTokenSet[]
  handleSetSelectedReshareTokenSet: (tokenSet: ReshareTokenSet) => void
}

const ReshareHistoryWrapper: React.FC<ReshareHistoryWrapperProps> = ({
  children,
  resharesByToken,
  handleSetSelectedReshareTokenSet,
}) => {
  return (
    <>
      {children}

      {!!resharesByToken.length && (
        <ReshareHistory
          handleSetSelectedReshareTokenSet={handleSetSelectedReshareTokenSet}
          resharesByToken={resharesByToken}
        />
      )}
    </>
  )
}

export default ReshareHistoryWrapper
