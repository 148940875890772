import React, { useCallback } from 'react'
import { useFormikContext } from 'formik'
import Form from 'components/Form'
import Tags from 'components/Tags'
import { LogType } from 'containers/Logs/models/types'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { UpdateTypes } from 'utils/constants/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'
import { ReactChildren, UpdateType } from 'utils/types/common'
import { Update } from 'utils/types/update'
import UpdateActions from 'components/Breadcrumb/UpdateActions/UpdateActions'
import BreadcrumbActionPortal from 'components/Breadcrumb/BreadcrumbActionPortal'
import {
  CreateBasicFormValues,
  UpdateFormContext,
} from 'utils/types/updateForm'
import UpdateFormWrapper from '../UpdateFormWrapper'
import { DraftStatus } from '../UpdateHeader/components/SaveDraftIcon/SaveDraftIcon'
import {
  UpdateLayout,
  UpdateLayoutContent,
  UpdateLayoutSide,
} from './UpdateForm.styles'

interface UpdateFormProps {
  children: ReactChildren
  updateType: UpdateType
  isEditMode?: boolean
  isShowMode?: boolean
  updateId?: string
  disabled?: boolean
  isSavingDraft?: boolean
  logType?: LogType
  update?: Update
  accountingRawData?: any
  hasDealsErrors?: boolean
  handleDelete?: () => void
  handleDiscardDraft?: () => void
  handleChangeUpdateToToday?: (
    values: CreateBasicFormValues
  ) => Promise<unknown>
  isEditingDraft?: boolean
  isPublishing?: boolean
  setIsPublishing?: (isPublishing: boolean) => void
  isOnline?: boolean
  toggleReshareModal?: () => void
  showInvestorAndFundSelector?: boolean
}

const UpdateForm: React.FC<UpdateFormProps> = ({
  updateType,
  updateId,
  isEditMode = false,
  isShowMode = false,
  disabled = false,
  isSavingDraft = false,
  isOnline = true,
  hasDealsErrors,
  children,
  logType,
  update,
  accountingRawData,
  handleDelete,
  handleDiscardDraft,
  handleChangeUpdateToToday,
  toggleReshareModal,
  isEditingDraft,
  isPublishing = false,
  setIsPublishing = () => {},
  showInvestorAndFundSelector,
}) => {
  const [showCreateChatModal, setShowCreateChatModal] = React.useState(false)
  const [isUploadingFiles, setIsUploadingFiles] = React.useState(false)
  const { hasEditPermissions } = useUpdatePermissions(update)
  const isInvestor = useAppSelector(isActingAsInvestorGroup)

  const handleShowCreateChatModal = useCallback((value: boolean) => {
    setShowCreateChatModal(value)
  }, [])

  const getDraftStatus = () => {
    if (!isOnline) {
      return DraftStatus.OFFLINE
    }

    if (updateId) {
      return DraftStatus.IDLE
    }

    return DraftStatus.UNSAVED
  }

  const { validateForm } = useFormikContext()

  const hasDraftMode =
    updateType === UpdateTypes.NOTE ||
    updateType === UpdateTypes.DOCUMENT ||
    updateType === UpdateTypes.ANNOUNCEMENT ||
    updateType === UpdateTypes.TRANSACTION

  return (
    <Form<UpdateFormContext>
      isEditMode={isEditMode}
      updateType={updateType}
      disabled={disabled}
      updateId={updateId}
      isShowMode={isShowMode}
      isUploadingFiles={isUploadingFiles}
      setIsUploadingFiles={setIsUploadingFiles}
      hasEditPermissions={hasEditPermissions}
      isSavingDraft={isSavingDraft}
      isEditingDraft={!!isEditingDraft}
      hasDraftUpdate={hasDraftMode && update?.isDraftUpdate}
      submitDraft={() => {
        setTimeout(() => {
          validateForm()
        })
      }}
      initialDraftStatus={getDraftStatus()}
      isPublishing={isPublishing}
      setIsPublishing={setIsPublishing}
      update={update}
      hasDraftMode={hasDraftMode}
      isOnline={isOnline}
      showInvestorAndFundSelector={showInvestorAndFundSelector}
      hasDealsErrors={hasDealsErrors}
    >
      <UpdateLayout
        isInvestor={isInvestor}
        isAccounting={updateType === UpdateTypes.ACCOUNTING}
      >
        <BreadcrumbActionPortal>
          <UpdateActions
            update={update}
            updateType={updateType}
            handleDelete={handleDelete}
            handleDiscardDraft={handleDiscardDraft}
            toggleReshareUpdateModal={toggleReshareModal}
            hasDraftMode={hasDraftMode}
            handleChangeUpdateToToday={handleChangeUpdateToToday}
            handleShowCreateChatModal={handleShowCreateChatModal}
          />
        </BreadcrumbActionPortal>

        <UpdateLayoutContent>
          <UpdateFormWrapper
            hasEditPermissions={hasEditPermissions}
            update={update}
            updateType={updateType}
            logType={logType}
            accountingRawData={accountingRawData}
            showCreateChatModal={showCreateChatModal}
            handleShowCreateChatModal={handleShowCreateChatModal}
          >
            {children}
          </UpdateFormWrapper>
        </UpdateLayoutContent>

        {!isInvestor && (
          <UpdateLayoutSide>
            <Tags />
          </UpdateLayoutSide>
        )}
      </UpdateLayout>
    </Form>
  )
}

export default UpdateForm
