import React, { useRef, useState } from 'react'

import Paginator from 'components/ExpandableTable/Paginator/Paginator'
import { ZeroStateContainer } from 'components/ExpandableTable/ExpandableRow/ExpandableRow.styles'
import CWLoader from 'components/CWLoader'
import { DataType } from '../types'
import Table from '../Table'

import {
  Expandable,
  RowContainer,
  ScrollableCellsContainer,
} from './Row.styles'
import useRow from './useRow'
import MemoizedRow from './MemoizedRow'

type RowProps<RowDataType extends DataType> = {
  data: RowDataType[]
  index: number
  style: any
}

function Row<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType
>({ data, index, style }: RowProps<RowDataType>) {
  const {
    rowRef,
    tableContext,
    rowData,
    showZeroState,
    currentSort,
    rowExpanded,
    expandedDataToShow,
    expandedRowLoading,
    expandedRowPageLoading,
    hasMorePages,
    onLoadMoreRows,
    customRow,
    onClickRow,
    onDoubleClickRow,
    cells,
    onChangeSortBy,
  } = useRow<RowDataType, ExpandedRowDataType>(data, index)
  const clickTimeout = useRef<any>(null)

  const rowHeight = tableContext.tableLayoutHelpers.getSize(index)

  const hasCustomRow = tableContext.fixedList && customRow

  const renderCustomRow = () => {
    if (hasCustomRow) {
      return (
        <Expandable id="custom-row" visible width={tableContext.tableWidth}>
          {customRow?.component}
        </Expandable>
      )
    }
    return null
  }

  const renderExpandedData = () => {
    if (showZeroState) {
      return (
        <ZeroStateContainer>
          {tableContext.renderExpandableRowZeroState({
            rowIndex: index,
            rowData,
          })}
        </ZeroStateContainer>
      )
    }

    return (
      <>
        {expandedDataToShow.length > 0 && (
          <Table<ExpandedRowDataType, RowDataType>
            data={expandedDataToShow as ExpandedRowDataType[]}
            initialSortDirection={currentSort.sortDirection}
            initialSortId={currentSort.sortId}
            loading={expandedRowLoading && !expandedRowPageLoading}
            onChangeSortBy={onChangeSortBy}
            rowHeight={tableContext.expandedRowHeight}
            keyPath={tableContext.expandedKeyPath}
            columns={tableContext.expandedColumns}
            embeddedTable
            parentRowData={rowData}
            parentRowIndex={index}
            loadingDataLabel={tableContext.loadingExpandedDataLabel}
            onClickExpandedRow={tableContext.onClickExpandedRow}
            onDoubleClickExpandedRow={tableContext.onDoubleClickExpandedRow}
            rowContextMenu={tableContext.expandedRowContextMenu}
            onRightClickRow={tableContext.onRightClickExpandedRow}
          />
        )}
        {expandedRowPageLoading && (
          <CWLoader text={tableContext.loadingExpandedDataLabel} />
        )}
        {!expandedRowLoading && !expandedRowPageLoading && hasMorePages && (
          <Paginator
            embeddedPaginator
            onLoadMoreRows={onLoadMoreRows}
            label={tableContext.paginationLabel}
          />
        )}
      </>
    )
  }

  const handleClicks = () => {
    if (clickTimeout.current !== null) {
      clearTimeout(clickTimeout.current)
      clickTimeout.current = null
      onDoubleClickRow()
    } else {
      clickTimeout.current = setTimeout(() => {
        clearTimeout(clickTimeout.current)
        clickTimeout.current = null
        onClickRow()
      }, 300)
    }
  }

  const [rowContextMenuState, setRowContextMenuState] = useState<{
    open: boolean
    manuallyOpen: boolean
    position?: { x: number; y: number }
  }>({
    open: false,
    manuallyOpen: false,
  })

  return (
    <div
      style={{
        ...style,
        height: rowExpanded || hasCustomRow ? 'auto' : tableContext.rowHeight,
      }}
      id="row-wrapper"
      ref={rowRef}
    >
      {renderCustomRow()}

      {(!customRow || !customRow.replaceRow) && (
        <RowContainer
          rowHeight={tableContext.rowHeight}
          expandOnRowClick={tableContext.expandOnRowClick}
          rowExpanded={rowExpanded}
          onClick={handleClicks}
          onContextMenu={(e) => {
            if (tableContext.onRightClickRow) {
              tableContext.onRightClickRow(rowData, index, e)
              return
            }

            if (tableContext.rowContextMenu) {
              e.preventDefault()

              setRowContextMenuState({
                open: true,
                manuallyOpen: false,
                position: {
                  x: e.clientX,
                  y: e.clientY,
                },
              })

              tableContext.addHoverIndex(index)
            }
          }}
          onMouseEnter={() => tableContext.addHoverIndex(index)}
          onMouseLeave={() => {
            if (
              !tableContext.rowContextMenu ||
              (!rowContextMenuState.open && !rowContextMenuState.manuallyOpen)
            ) {
              tableContext.removeHoverIndex(index)

              setRowContextMenuState({ open: false, manuallyOpen: false })
            }
          }}
          isHover={tableContext.getIsIndexHover(index)}
          fixedList={tableContext.fixedList}
          hasCustomRow={!!customRow}
          fixedRowHeight={rowHeight}
          isExpandable={tableContext.isExpandable}
        >
          <ScrollableCellsContainer
            id={`cell-container-${
              tableContext.fixedList ? 'fixed' : 'scrollable'
            }`}
            hideBorder={tableContext.embeddedTable}
            embeddedTable={tableContext.embeddedTable}
            countColumns={cells.length}
          >
            <MemoizedRow
              cells={cells}
              rowData={rowData}
              rowIndex={index}
              embeddedTable={!!tableContext.embeddedTable}
              parentRowData={tableContext.parentRowData}
              parentRowIndex={tableContext.parentRowIndex}
              isFixedList={
                tableContext.fixedList && !tableContext.embeddedTable
              }
              rowContextMenu={tableContext.rowContextMenu}
              rowContextMenuOpen={rowContextMenuState.open}
              rowContextMenuPosition={rowContextMenuState.position}
              onCloseRowContextMenu={() => {
                setRowContextMenuState({ open: false, manuallyOpen: false })
                tableContext.removeHoverIndex(index)
              }}
              onOpenRowContextMenu={() => {
                setRowContextMenuState({ open: false, manuallyOpen: true })
                tableContext.addHoverIndex(index)
              }}
            />
          </ScrollableCellsContainer>
        </RowContainer>
      )}

      {tableContext.fixedList && rowExpanded && (
        <Expandable
          id="expanded-table"
          visible={rowExpanded}
          width={tableContext.tableWidth}
        >
          {renderExpandedData()}
        </Expandable>
      )}
    </div>
  )
}

export default React.memo(Row)
