import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'

import UpdateIconFactory from 'components/UpdateIconFactory'
import { getCurrentCompany } from 'selectors/company'
import useCreateEmail from 'utils/hooks/useCreateEmail'
import {
  ActionContainer,
  ButtonTitle,
  Container,
  StyledUpdateIconFactory,
} from './CelesteActionItem.styles'

const withSlash = (url: string) => (url.startsWith('/') ? url : `/${url}`)
const withoutSlash = (url: string) => (url.startsWith('/') ? url.slice(1) : url)

export enum CelesteActionType {
  CREATE_ACCOUNTING_REPORT = 'create_accounting_report',
  CREATE_NOTE = 'create_note',
  CREATE_DOCUMENT = 'create_document',
  CREATE_TRANSACTION = 'create_transaction',
  CREATE_ANNOUNCEMENT = 'create_announcement',
  CREATE_EMAIL = 'create_email',
  ADD_METRIC = 'add_metric',
  UPDATE_METRICS = 'update_metrics',
  EXPORT_ALL_METRICS = 'export_all_metrics',
  UPLOAD_FILE = 'upload_file',
  CREATE_PORTFOLIO = 'create_portfolio',
}

export interface CelesteActionItemProps {
  name: string
  actionType: CelesteActionType
  url?: string
  prefixUrl?: string
  routeState?: any
  onClick?: () => void
  isMetricsOption?: boolean
  disabled?: boolean
}

const CelesteActionItem: React.FC<CelesteActionItemProps> = ({
  name,
  actionType,
  url,
  prefixUrl,
  routeState,
  onClick,
  isMetricsOption = false,
  disabled = false,
}) => {
  const history = useHistory()
  const { id: companyId } = useAppSelector(getCurrentCompany)
  const { onCreateEmail } = useCreateEmail(companyId)

  const onUpdateClick = () => {
    if (onClick) {
      onClick()
      return
    }

    if (actionType === CelesteActionType.CREATE_EMAIL) {
      onCreateEmail()
    } else if (url) {
      history.push(
        prefixUrl
          ? withSlash(`${prefixUrl}/${withoutSlash(url)}`)
          : withSlash(url),
        {
          canGoBack: true,
          ...routeState,
        }
      )
    }
  }
  return (
    <Container
      disabled={disabled}
      isMetricsOption={isMetricsOption}
      onClick={onUpdateClick}
    >
      <ActionContainer>
        <StyledUpdateIconFactory>
          <UpdateIconFactory updateType={actionType} />
        </StyledUpdateIconFactory>
        <ButtonTitle>{name}</ButtonTitle>
      </ActionContainer>
      <FontAwesomeIcon icon={['far', 'chevron-right']} />
    </Container>
  )
}

export default CelesteActionItem
