import styled from 'styled-components'
import Card from 'components/Card'
import { Scroll } from 'App.styles'

export const CardHeader = styled(Card.Header)`
  text-transform: capitalize;
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CardBody = styled(Card.Body)`
  margin-top: initial;

  .str-chat {
    margin-top: 0.8rem;
  }

  .str-chat__channel-list-messenger-react__main {
    ${Scroll}
    max-height: 31rem;
    overflow-y: overlay;
    overflow: auto;
    padding: initial;
  }

  .str-chat.messaging {
    --str-chat__font-family: 'Lato', sans-serif;

    border-right: initial;
  }
`
