import { useIntl } from 'react-intl'

import {
  JoinedChannelSystemMessage,
  LeftChannelSystemMessage,
} from 'containers/Chat/types'
import { formatStringList } from 'utils/functions/utils'

export const useSystemMessage = () => {
  const intl = useIntl()

  const renderJoinedChatMessage = (
    systemMessage: JoinedChannelSystemMessage,
    plainText: boolean = false
  ) => {
    const invitationMessageMembers = formatStringList(
      systemMessage.members.map((member) =>
        plainText ? member.name : `<b>${member.name}</b>`
      ),
      3,
      intl
    )

    return `${invitationMessageMembers} ${intl.formatMessage({
      id:
        systemMessage.members.length === 1
          ? 'chat.hasJoinedTheChat'
          : 'chat.haveJoinedTheChat',
    })}`
  }

  const renderLeftChatMessage = (
    systemMessage: LeftChannelSystemMessage,
    plainText: boolean = false
  ) => {
    const memberName = plainText
      ? systemMessage.member.name
      : `<b>${systemMessage.member.name}</b>`

    return `${memberName} ${intl.formatMessage({
      id: 'chat.hasLeftTheChat',
    })}`
  }

  return {
    renderJoinedChatMessage,
    renderLeftChatMessage,
  }
}
