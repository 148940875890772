import React from 'react'

import FileIcon from 'components/FileIcon'
import { FileExtension, MixedContent } from 'utils/types/files'
import {
  getFileExtension,
  isIndexEmailContent,
  isIndexFileContent,
} from 'utils/functions/files'
import { useMixedContent } from 'utils/hooks/contents/useMixedContent'

import {
  StyledAttachmentPreview,
  AttachmentInfo,
  IconWrapper,
  PaperclipIcon,
} from './AttachmentPreview.styles'

export type AttachmentPreviewSize = 'large' | 'small'

interface AttachmentPreviewProps {
  content: MixedContent
  size?: AttachmentPreviewSize
  dummy?: boolean
  showPaperclipOnEmails?: boolean
  onClick?: (event: React.MouseEvent) => void
}

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({
  content,
  size = 'small',
  dummy,
  showPaperclipOnEmails,
  onClick,
}) => {
  const isFileContent = isIndexFileContent(content)
  const { isEmailContent, contentName, fileFormat } = useMixedContent(content)
  const fileType = getFileExtension(fileFormat)

  return (
    <StyledAttachmentPreview
      data-url={isFileContent ? content.url : ''}
      data-testid={`attachment-preview-${content.id}`}
      size={size}
      dummy={dummy}
      onClick={onClick}
      hasPreview={FileExtension.IMAGE === fileType}
    >
      <IconWrapper>
        <FileIcon
          type={fileFormat}
          isEmailContent={isEmailContent}
          src={isFileContent ? content.url : undefined}
        />
      </IconWrapper>
      <AttachmentInfo>{contentName}</AttachmentInfo>
      {isIndexEmailContent(content) &&
        showPaperclipOnEmails &&
        content.s3Contents?.length > 0 && (
          <PaperclipIcon icon={['far', 'paperclip']} />
        )}
    </StyledAttachmentPreview>
  )
}

export default AttachmentPreview
