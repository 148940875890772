import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useEmailContentQuery } from 'utils/hooks/contents/useContentQuery'
import { useShowMore } from './useShowMore'

const MAX_ATTACHMENTS = 3
const MAX_RECEIVERS = 3

export type ListHandler<T> = ReturnType<typeof useShowMore<T>>

export const useEmailContentDetails = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { isLoading, data: emailContent } = useEmailContentQuery(id)

  const contentsHandler = useShowMore(MAX_ATTACHMENTS, emailContent?.s3Contents)
  const receiversHandler = useShowMore(
    MAX_RECEIVERS,
    emailContent?.data.receivers
  )

  const onCloseDrawer = useCallback(() => {
    history.replace('/files')
  }, [history])

  return {
    onCloseDrawer,
    contentsHandler,
    receiversHandler,
    isLoading,
    emailContent,
  }
}
