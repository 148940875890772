export default {
  new: 'NEW',
  selectFrom: 'Select from',
  errors: {
    youHaveErrors:
      'You have errors on the table. Please correct red fields to continue.',
    metricRequired: 'You must add the name of a new or existing metric.',
    holdingRequired: 'You must select which holding the metric belongs to.',
    invalidHolding:
      'This holding does not exist in our records. Please double-check the information and try again.',
  },
}
