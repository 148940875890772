import { UpdateType } from 'utils/types/common'

export const QUERIES = {
  ALL_UPDATES: 'all_updates',
  UPDATE: 'update',
  DELETE_UPDATE: 'delete_update',
  DISCARD_DRAFT_UPDATE: 'discard_draft_update',
  EDIT_RESHARED_UPDATE: 'edit_reshared_update',
  CREATE_RESHARE_UPDATE: 'create_reshare_update',

  // Draft Update Queries
  CREATE_DRAFT_NOTE_UPDATE: 'create_draft_note_update',
  CREATE_DRAFT_ANNOUNCEMENT_UPDATE: 'create_draft_announcement_update',
  CREATE_DRAFT_DOCUMENT_UPDATE: 'create_draft_document_update',
  CREATE_DRAFT_TRANSACTION_UPDATE: 'create_draft_transaction_update',
  CREATE_DRAFT_ACCOUNTING_UPDATE: 'create_draft_accounting_update',

  EDIT_DRAFT_NOTE_UPDATE: 'edit_draft_note_update',
  EDIT_DRAFT_ANNOUNCEMENT_UPDATE: 'edit_draft_announcement_update',
  EDIT_DRAFT_DOCUMENT_UPDATE: 'edit_draft_document_update',
  EDIT_DRAFT_TRANSACTION_UPDATE: 'edit_draft_transaction_update',
  EDIT_DRAFT_ACCOUNTING_UPDATE: 'edit_draft_accounting_update',

  // Updates table on updates tab
  UPDATE_TABLE_INFO: 'update_table_info',

  // TODO: the following shouldn't be used
  CREATE_NOTE_UPDATE: 'create_note_update',
  CREATE_DOCUMENT_UPDATE: 'create_document_update',
  CREATE_ANNOUNCEMENT_UPDATE: 'create_announcement_update',
  CREATE_TRANSACTION_UPDATE: 'create_transaction_update',
  CREATE_ACCOUNTING_UPDATE: 'create_accounting_update',

  EDIT_NOTE_UPDATE: 'edit_note_update',
  EDIT_ANNOUNCEMENT_UPDATE: 'edit_announcement_update',
  EDIT_DOCUMENT_UPDATE: 'edit_document_update',
  EDIT_TRANSACTION_UPDATE: 'edit_transaction_update',
  CREATE_UPDATE: 'create_update',
  CREATE_ACCOUNTING: 'create_accounting',
  EDIT_ACCOUNTING: 'edit_accounting',
} as const

export const updatesKeys = {
  allUpdates: () => [QUERIES.ALL_UPDATES] as const,
  createReshareUpdate: () => [QUERIES.CREATE_RESHARE_UPDATE] as const,
  editResharedUpdate: () => [QUERIES.EDIT_RESHARED_UPDATE] as const,
  byId: (id: string) => [QUERIES.UPDATE, id] as const,
  deleteUpdateByIdAndType: (id: string, type: UpdateType) =>
    [QUERIES.DELETE_UPDATE, id, type] as const,
  discardDraftUpdateByIdAndType: (id: string, type: UpdateType) =>
    [QUERIES.DISCARD_DRAFT_UPDATE, id, type] as const,

  createNote: () => [QUERIES.CREATE_NOTE_UPDATE] as const,
  createAnnouncement: () => [QUERIES.CREATE_ANNOUNCEMENT_UPDATE] as const,
  createDocument: () => [QUERIES.CREATE_DOCUMENT_UPDATE] as const,
  createUpdate: () => [QUERIES.CREATE_UPDATE] as const,
  updateNote: () => [QUERIES.EDIT_NOTE_UPDATE] as const,
  editAccounting: () => [QUERIES.EDIT_ACCOUNTING] as const,
  updateDocument: () => [QUERIES.EDIT_DOCUMENT_UPDATE] as const,
  createTransaction: () => [QUERIES.CREATE_TRANSACTION_UPDATE] as const,
  createAccounting: () => [QUERIES.CREATE_ACCOUNTING_UPDATE] as const,

  editNote: () => [QUERIES.EDIT_NOTE_UPDATE] as const,
  editAnnouncement: () => [QUERIES.EDIT_ANNOUNCEMENT_UPDATE] as const,
  editDocument: () => [QUERIES.EDIT_DOCUMENT_UPDATE] as const,
  editTransaction: () => [QUERIES.EDIT_TRANSACTION_UPDATE] as const,

  // Draft Updates
  createDraftNote: () => [QUERIES.CREATE_DRAFT_NOTE_UPDATE] as const,
  createDraftAnnouncement: () =>
    [QUERIES.CREATE_DRAFT_ANNOUNCEMENT_UPDATE] as const,
  createDraftDocument: () => [QUERIES.CREATE_DRAFT_DOCUMENT_UPDATE] as const,
  createDraftTransaction: () =>
    [QUERIES.CREATE_DRAFT_TRANSACTION_UPDATE] as const,
  createDraftAccounting: () =>
    [QUERIES.CREATE_DRAFT_ACCOUNTING_UPDATE] as const,

  editDraftNote: () => [QUERIES.EDIT_DRAFT_NOTE_UPDATE] as const,
  editDraftAnnouncement: () =>
    [QUERIES.EDIT_DRAFT_ANNOUNCEMENT_UPDATE] as const,
  editDraftDocument: () => [QUERIES.EDIT_DRAFT_DOCUMENT_UPDATE] as const,
  editDraftTransaction: () => [QUERIES.EDIT_DRAFT_TRANSACTION_UPDATE] as const,
  editDraftAccounting: () => [QUERIES.EDIT_DRAFT_ACCOUNTING_UPDATE] as const,

  updateTableInfo: () => [QUERIES.UPDATE_TABLE_INFO] as const,
}
