import {
  InvestmentsFilters,
  InvestmentsFiltersObject,
} from 'containers/Investments/InvestmentsFilters/types'

export const QUERIES = {
  INVESTMENTS: 'investments',
  FILTERS: 'filters',
  FILTERED_HOLDINGS_IDS: 'filteredHoldingsIds',
  FILTERED_SUMMARY: 'filteredSummary',
  FILTERED_HOLDINGS: 'filteredHoldings',
} as const

export const investmentsKeys = {
  allWithFilters: (filters: InvestmentsFilters) =>
    [QUERIES.INVESTMENTS, filters] as const,
  filteredSummary: (holdingsIds: string[]) =>
    [QUERIES.FILTERED_SUMMARY, holdingsIds] as const,
  filteredHoldings: (holdingsIds: string[]) =>
    [QUERIES.FILTERED_HOLDINGS, holdingsIds] as const,
  filteredHoldingsIds: (filterObject: InvestmentsFiltersObject) =>
    [QUERIES.FILTERED_HOLDINGS_IDS, filterObject] as const,
  filters: () => [QUERIES.FILTERS] as const,
  filterById: (id: string) => [QUERIES.FILTERS, id] as const,
}
