import { InvestmentVehicleLogChangeSetFields } from '../logs-builders/InvestmentVehicleLogsBuilder'
import { EventType, Log } from '../types'
import { BackendEvent, BackendEventsMapper } from './BackendEventsMapper'

export class InvestmentVehicleEventsMapper extends BackendEventsMapper<InvestmentVehicleLogChangeSetFields> {
  getLogsFromEvent(event: BackendEvent<InvestmentVehicleLogChangeSetFields>) {
    if (
      event.event === EventType.CREATE &&
      event.changeset[this.mainAttribute]
    ) {
      const creationLog: Log<InvestmentVehicleLogChangeSetFields>[] = [
        {
          ...this.buildLog(event, this.mainAttribute),
          change: [null, null],
          text: event.changeset[this.mainAttribute][1] as string,
        },
      ]
      return creationLog
    }

    return super.getLogsFromEvent(event)
  }
}
