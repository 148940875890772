import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    border-bottom: 0.1rem solid #dedede;
    line-height: 0.1em;
    text-align: center;
    width: 17%;
  }

  span {
    color: #000000;
    font-size: 1.4rem;
    padding: 0 0.8rem;
  }
`
