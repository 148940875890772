import { useIntl } from 'react-intl'
import Drawer from 'components/Drawer/Drawer'
import Dropdown from 'ui/Dropdown'
import { useFormikContext } from 'components/Form/hooks'
import { AddToExistingMetricFormValues } from './useAddToExistingMetric'

interface Props {
  loadMetrics: (metricName: string) => void
}

const AddToExistingMetricForm = ({ loadMetrics }: Props) => {
  const intl = useIntl()
  const { setFieldValue } = useFormikContext<AddToExistingMetricFormValues>()

  return (
    <Drawer.Content contentPadding="2.5rem 2.4rem">
      <Dropdown
        loadOptions={loadMetrics}
        onSelectOption={(_, __, value) => {
          setFieldValue('id', value.id)
        }}
        label={intl.formatMessage({ id: 'metrics.selectMetric' })}
        placeholder={intl.formatMessage({ id: 'general.select' })}
        async
        type="input"
        getOption={(entity) => ({ label: entity.label, id: entity.id })}
      />
    </Drawer.Content>
  )
}

export default AddToExistingMetricForm
