import React from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import { downloadJson } from 'utils/functions/files'

import { RawReportContainer, Code } from './RawReport.styles'

const RawReport = ({ data, reportName }) => {
  return (
    <RawReportContainer>
      <Button
        icon={['far', 'cloud-download']}
        link
        strong
        onClick={() => {
          downloadJson(data, `${reportName}.json`)
        }}
      >
        Download JSON
      </Button>
      <Code>{JSON.stringify(data, null, 2)}</Code>
    </RawReportContainer>
  )
}

RawReport.propTypes = {
  data: PropTypes.object,
  reportName: PropTypes.string,
}

RawReport.defaultProps = {
  data: {},
  reportName: '',
}

export default RawReport
