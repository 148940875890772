import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'

import { HEADER_SECTION } from 'utils/constants/iue'
import Accordion from 'components/Accordion'
import Heading from 'components/Heading'
import Button from 'components/Button'
import ColorPicker from 'components/ColorPicker/ColorPicker'
import Checkbox from 'components/Checkbox'
import DraggableCircles from './components/DraggableCircles'

import styles from './SectionAccordion.module.scss'

const TIMEOUT_UNTIL_REMOVE_SECTION = 10000

const UndoRemoveSection = ({ onClick }) => (
  <div className={styles.undoRemoveSectionWrapper}>
    <Button onClick={onClick} className={styles.btnUndoAction} link>
      <FormattedMessage id="createEmail.undoAction" />
    </Button>
  </div>
)

const EmailSectionAccordion = ({
  children,
  draggableIcon,
  emailSectionId,
  handleChangeSectionName,
  handleRemoveSection,
  isDraftMode,
  name,
  onAddCustomSectionToRemove,
  onDiscardNewDraftSection,
  onRemoveCustomSectionToRemove,
  onSaveNewDraftSection,
  showUndoAction,
  onSelectColor,
  color,
  isSelectSections,
  onChangeShowTitle,
  showTitle,
  ...rest
}) => {
  const intl = useIntl()
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
  const [titleInputWidth, setTitleInputWidth] = useState(false)
  const [editTitleFocus, setEditTitleFocus] = useState(false)
  const [undoActionTimerFn, setUndoActionTimerFn] = useState(null)
  const isHeaderSection = name === HEADER_SECTION
  const titleInputRef = useRef(null)
  const TITLE_INPUT_MARGIN_RIGHT = 2

  const handleAccordionChange = (isExpanded) =>
    setIsAccordionExpanded(isExpanded)

  const displayDraggableIcon = () => draggableIcon || <DraggableCircles />

  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.style.width = `${
        titleInputRef.current.value.length + TITLE_INPUT_MARGIN_RIGHT
      }ch`
    }
  }, [titleInputRef])

  useEffect(() => {
    if (isDraftMode) {
      const sectionsBody = document.getElementById('sectionsBody')
      sectionsBody.scrollTop = sectionsBody.scrollHeight
    }
  }, [isDraftMode])

  const onChangeSectionTitle = (event) => {
    if (event.target.value !== '') {
      setTitleInputWidth(
        `${event.target.value.length + TITLE_INPUT_MARGIN_RIGHT}ch`
      )
    } else {
      setTitleInputWidth('1ch')
    }
    handleChangeSectionName(emailSectionId, event)
  }

  const undoRemoveSection = () => {
    if (undoActionTimerFn) {
      clearTimeout(undoActionTimerFn)
      onRemoveCustomSectionToRemove(name)
      setUndoActionTimerFn(null)
    }
  }

  const onRemoveSection = (event) => {
    event.stopPropagation()
    if (showUndoAction) {
      const timer = setTimeout(() => {
        handleRemoveSection(name)
      }, TIMEOUT_UNTIL_REMOVE_SECTION)
      onAddCustomSectionToRemove(name)
      setUndoActionTimerFn(timer)
    } else {
      handleRemoveSection(name)
    }
  }

  const onClickEditTitle = (event) => {
    event.preventDefault()
    event.stopPropagation()
    titleInputRef.current.focus()
  }

  return (
    <Accordion onAccordionChange={handleAccordionChange} {...rest}>
      <Accordion.Header
        className={classNames(styles.accordionHeader, {
          [styles.highlightBackground]: isDraftMode,
        })}
      >
        {undoActionTimerFn && <UndoRemoveSection onClick={undoRemoveSection} />}
        <div className={styles.headingDisplay}>
          <div className={styles.headerTitleContainer}>
            <div className={styles.leftSideContainer}>
              {isDraftMode ? null : displayDraggableIcon()}
              <Heading
                level="h3"
                className={classNames(styles.sectionHeader, {
                  [styles.emailHeaderAccordion]: !showTitle,
                })}
              >
                {handleChangeSectionName && showTitle ? (
                  <input
                    ref={titleInputRef}
                    maxLength="35"
                    className={classNames(styles.sectionName, {
                      [styles.editMode]: isDraftMode,
                    })}
                    value={name}
                    type="text"
                    style={{ width: titleInputWidth }}
                    onChange={onChangeSectionTitle}
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                    onFocus={() => setEditTitleFocus(true)}
                    onBlur={() => setEditTitleFocus(false)}
                  />
                ) : (
                  <span className={styles.sectionName}>{name}</span>
                )}
                {!isSelectSections && showTitle && !editTitleFocus && (
                  <button
                    type="button"
                    className={classNames(styles.editTitle, {
                      [styles.editTitleMargin]: !name,
                    })}
                    onClick={onClickEditTitle}
                  >
                    {intl.messages['createEmail.editTitle']}
                  </button>
                )}
              </Heading>
            </div>
          </div>

          <div className={styles.rightSideWrapper}>
            {!isSelectSections && (
              <>
                <Checkbox
                  id={`showTitle_${emailSectionId}`}
                  name={`showTitle_${emailSectionId}`}
                  label={intl.messages['createEmail.showTitle']}
                  onChange={(event) =>
                    onChangeShowTitle(emailSectionId, event.target.checked)
                  }
                  checked={showTitle}
                />
                <ColorPicker color={color} onChange={onSelectColor} />
              </>
            )}

            <div className={styles.rightSideContainer}>
              {handleRemoveSection &&
                !isDraftMode &&
                !(isSelectSections && isHeaderSection) && (
                  <button
                    className={styles.btnRemoveSection}
                    type="button"
                    onClick={onRemoveSection}
                  >
                    <FontAwesomeIcon icon={['fas', 'times-circle']} />
                  </button>
                )}

              {isDraftMode && (
                <>
                  <Button
                    onClick={() => {
                      onDiscardNewDraftSection(emailSectionId)
                    }}
                    className={styles.linkDiscard}
                    link
                  >
                    <FormattedMessage id="createEmail.discardDraftSection" />
                  </Button>
                  <Button
                    onClick={() => {
                      onSaveNewDraftSection(name)
                    }}
                    className={styles.linkSave}
                    link
                  >
                    <FormattedMessage id="createEmail.saveDraftSection" />
                  </Button>
                </>
              )}

              {children && (
                <div className={styles.toggle}>
                  <FontAwesomeIcon
                    className={isAccordionExpanded ? styles.rotateAngleUp : ''}
                    icon={['fas', 'angle-down']}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Accordion.Header>
      {children && (
        <Accordion.Body backgroundColor={color}>{children}</Accordion.Body>
      )}
    </Accordion>
  )
}

EmailSectionAccordion.defaultProps = {
  handleRemoveSection: null,
  children: null,
  draggableIcon: null,
  handleChangeSectionName: null,
  showUndoAction: false,
  isDraftMode: false,
  onSaveNewDraftSection: null,
  onAddCustomSectionToRemove: null,
  onRemoveCustomSectionToRemove: null,
  onDiscardNewDraftSection: null,
  emailSectionId: null,
  onSelectColor: () => {},
  onChangeShowTitle: () => {},
  showTitle: () => {},
  color: null,
  isSelectSections: false,
}

EmailSectionAccordion.propTypes = {
  name: PropTypes.string.isRequired,
  handleRemoveSection: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  children: PropTypes.node,
  draggableIcon: PropTypes.node,
  handleChangeSectionName: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  showUndoAction: PropTypes.bool,
  onAddCustomSectionToRemove: PropTypes.func,
  onRemoveCustomSectionToRemove: PropTypes.func,
  emailSectionId: PropTypes.string,
  isDraftMode: PropTypes.bool,
  onDiscardNewDraftSection: PropTypes.func,
  onSaveNewDraftSection: PropTypes.func,
  onSelectColor: PropTypes.func,
  color: PropTypes.string,
  isSelectSections: PropTypes.bool,
  onChangeShowTitle: PropTypes.func,
  showTitle: PropTypes.func,
}

UndoRemoveSection.propTypes = {
  onClick: PropTypes.func.isRequired,
}

EmailSectionAccordion.displayName = 'EmailSectionAccordion'

export default EmailSectionAccordion
