import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ShowMoreLess = styled.div`
  color: ${color('gray')};
  font-size: 1.4rem;
  cursor: pointer;
  font-weight: 700;
  padding-left: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`
