import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Heading from 'components/Heading'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const TeamMemberContainer = styled.div<{
  isCompanyProfile: boolean
}>`
  display: grid;
  gap: 0.4rem;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 1.6rem;
  width: ${(props) => (props.isCompanyProfile ? 'auto' : '21.5rem')};
  margin-right: ${(props) => (props.isCompanyProfile ? '.8rem' : '1.6rem')};
  margin-bottom: ${(props) => (props.isCompanyProfile ? '0.8rem' : '0')};
`

export const TeamMemberName = styled(Heading)`
  font-size: 1.4rem;
  font-weight: 700;
`
export const TeamMemberTitle = styled.p`
  color: ${color('lightGray')};
  font-size: 1.4rem;
`

export const BadgeContainer = styled.div<{ withMargin?: boolean }>`
  ${(props) =>
    props.withMargin &&
    css`
      margin-left: 0.8rem;
    `}
`

export const LinkedinURL = styled.a``

export const TeamMemberEmail = styled.a``

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
`

export const EmailIcon = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
`
export const LinkedinIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
`
