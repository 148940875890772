import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const ZeroStateContainer = styled.div`
  margin-top: 2.4rem;
`
