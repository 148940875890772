/* eslint-disable */
import { decamelizeThatDoesntBreakFiles } from './convertKeys'

function mergeObjects(object1, object2) {
  return [object1, object2].reduce(function (carry, objectToMerge) {
    Object.keys(objectToMerge).forEach(function (objectKey) {
      carry[objectKey] = objectToMerge[objectKey]
    })
    return carry
  }, {})
}

function isArray(val) {
  return {}.toString.call(val) === '[object Array]'
}

function isJsonObject(val) {
  return (
    !isArray(val) &&
    typeof val === 'object' &&
    !!val &&
    !(val instanceof Blob) &&
    !(val instanceof Date)
  )
}

function isAppendFunctionPresent(formData) {
  return typeof formData.append === 'function'
}

function isGlobalFormDataPresent() {
  return typeof FormData === 'function'
}

function getDefaultFormData() {
  if (isGlobalFormDataPresent()) {
    return new FormData()
  }

  return undefined
}

function convertRecursively(jsonObject, options, formData, parentKey) {
  let index = 0

  for (var key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
      var propName = parentKey || key
      var value = options.mapping(jsonObject[key])

      if (parentKey && isJsonObject(jsonObject)) {
        propName = parentKey + '[' + key + ']'
      }

      if (parentKey && isArray(jsonObject)) {
        // This library has been modified to support lists params without the index inside the [ ].

        // if (isArray(value) || options.showLeafArrayIndexes ) {
        //     propName = parentKey + '[' + index + ']';
        // } else {
        //     propName = parentKey + '[]';
        //   }

        propName = parentKey + '[]'
      }

      if (isArray(value) || isJsonObject(value)) {
        convertRecursively(value, options, formData, propName)
      } else if (value instanceof FileList) {
        for (var j = 0; j < value.length; j++) {
          // formData.append(propName + '[' + j + ']', value.item(j));
          formData.append(propName + '[]', value.item(j))
        }
      } else if (value instanceof Blob) {
        formData.append(propName, value, value.name)
      } else if (value instanceof Date) {
        formData.append(propName, value.toISOString())
      } else if (
        ((value === null && options.includeNullValues) || value !== null) &&
        value !== undefined
      ) {
        formData.append(propName, value)
      }
    }
    index++
  }
  return formData
}

function convert(jsonObject, options) {
  if (options && options.initialFormData) {
    if (!isAppendFunctionPresent(options.initialFormData)) {
      throw 'initialFormData must have an append function.'
    }
  } else if (!isGlobalFormDataPresent()) {
    throw 'This environment does not have global form data. options.initialFormData must be specified.'
  }

  const defaultOptions = {
    initialFormData: getDefaultFormData(),
    showLeafArrayIndexes: true,
    includeNullValues: false,
    mapping: (value) => value,
  }

  const mergedOptions = mergeObjects(defaultOptions, options || {})
  const parsedObject = decamelizeThatDoesntBreakFiles(jsonObject)

  return convertRecursively(
    parsedObject,
    mergedOptions,
    mergedOptions.initialFormData
  )
}

export default convert
