/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'

import useEntityFromUrl from 'utils/hooks/useEntityFromUrl'
import { Update } from 'utils/types/update'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'

import { download } from 'utils/functions/files'
import {
  useDeleteUpdateMutation,
  useFetchUpdateQuery,
} from 'utils/hooks/queries/useUpdateQuery'
import {
  getUpdateBaseUrl,
  getUpdateTypeFromUrl,
  ReshareTokenSet,
} from 'utils/functions/updates'

import {
  useResharesByToken,
  useResharesWithMeOrMyGroup,
} from 'utils/hooks/reshares'
import UpdateService from 'api/UpdateService'
import Toast from 'components/Toast'
import { useIntl } from 'react-intl'

export function useShowUpdate<T extends Update>() {
  const [attachmentZipUrl, setAttachmentZipUrl] = useState<string>('')
  const [isGeneratingAttachments, setIsGeneratingAttachments] = useState(false)
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation<{ canGoBack: boolean }>()
  const { updateId } = useParams<{ updateId: string }>()
  const entity = useEntityFromUrl()
  const updateType = getUpdateTypeFromUrl(location.pathname)

  const canGoBack = location.state?.canGoBack

  const { data: update, isLoading } = useFetchUpdateQuery<T>({
    updateId,
    updateType,
  })

  const getAttachmentZipUrl = async () => {
    if (!updateId) {
      return
    }
    try {
      setIsGeneratingAttachments(true)
      const url = await UpdateService.fetchAttachmentZipUrl(updateId)
      setAttachmentZipUrl(url)
      download('', url)
    } catch (err) {
      Toast.display(
        intl.formatMessage({ id: `updates.downloadAllNotGeneratedYet` }),
        'error'
      )
    } finally {
      setIsGeneratingAttachments(false)
    }
  }

  const updatePermissions = useUpdatePermissions(update)
  const resharesByToken = useResharesByToken(update)
  const resharesWithMeOrMyGroup = useResharesWithMeOrMyGroup(update)

  const isClickingFromUpdatesView = location.pathname.includes('/updates')

  const redirectToEditView = () => {
    const locationState = {
      ...location.state,
      showNavigation: false,
      canGoBack,
      companyName: document.getElementById('company-name')?.innerHTML,
      currentPortfolio: !isClickingFromUpdatesView ? entity : undefined,
    }

    const { pathname } = history.location

    history.push(`${pathname}/edit`, {
      ...locationState,
    })
  }

  const redirectToReshareView = () => {
    const locationState = {
      canGoBack,
      companyName: document.getElementById('company-name')?.innerHTML,
      currentPortfolio: !isClickingFromUpdatesView ? entity : undefined,
    }

    const baseUrl = getUpdateBaseUrl(update!, isClickingFromUpdatesView, entity)
    history.push(`${baseUrl}/reshare`, {
      ...locationState,
    })
  }

  const redirectToEditReshareView = (tokenSet: ReshareTokenSet) => {
    const locationState = {
      canGoBack,
      companyName: document.getElementById('company-name')?.innerHTML,
      currentPortfolio: !isClickingFromUpdatesView ? entity : undefined,
      reshareTokenSet: tokenSet,
    }

    const baseUrl = getUpdateBaseUrl(update!, isClickingFromUpdatesView, entity)
    history.push(`${baseUrl}/edit-reshare`, {
      ...locationState,
    })
  }

  const deleteMutation = useDeleteUpdateMutation(
    update?.item.id!,
    update?.updateType!,
    update?.type!
  )

  return {
    attachmentZipUrl,
    isGeneratingAttachments,
    isLoading,
    resharesByToken,
    resharesWithMeOrMyGroup,
    tagsList: update?.tags,
    update,
    updateId,
    updatePermissions,
    deleteUpdate: deleteMutation.mutate,
    redirectToEditReshareView,
    redirectToEditView,
    redirectToReshareView,
    getAttachmentZipUrl,
  }
}
