import React from 'react'
import ContentLoader from 'react-content-loader'

const EditPermissionsLoader = () => (
  <ContentLoader
    speed={1}
    height={200}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
  >
    <rect x="0" y="20" rx="4" ry="4" width="100%" height="60" />
    <rect x="0" y="90" rx="4" ry="4" width="100%" height="60" />
    <rect x="0" y="160" rx="4" ry="4" width="100%" height="60" />
  </ContentLoader>
)

export default EditPermissionsLoader
