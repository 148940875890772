import InvestorSelector from 'components/TransactionUpdateContent/UpdateFormElements/InvestorSelector'
import { SingleFundPortfolioSelector } from 'components/TransactionUpdateContent/UpdateFormElements/SingleFundPortfolioSelector'
import { GridCol, GridRow } from './MultipleInvestorFundSelector.styles'

const MultipleInvestorFundSelector = () => {
  return (
    <GridRow>
      <GridCol>
        <InvestorSelector />
      </GridCol>
      <GridCol>
        <SingleFundPortfolioSelector portfolioKey="portfolio" />
      </GridCol>
    </GridRow>
  )
}

export default MultipleInvestorFundSelector
