export const INVESTOR_MANAGEMENT = '/investor-management'

export const INVESTORS = `${INVESTOR_MANAGEMENT}/investors`
export const INVESTORS_CREATE = `${INVESTORS}/create`

export const VEHICLES = `${INVESTOR_MANAGEMENT}/vehicles`
export const VEHICLES_CREATE = `${VEHICLES}/create`

export const SINGLE_INVESTOR_PREFIX = `${INVESTORS}/:investorId`
export const SINGLE_VEHICLE_PREFIX = `${VEHICLES}/:investmentVehicleId`

export const INVESTOR_PROFILE = `${SINGLE_INVESTOR_PREFIX}/profile`
export const INVESTOR_PROFILE_EDIT = `${SINGLE_INVESTOR_PREFIX}/profile/edit`
export const INVESTORS_EDIT = `${SINGLE_INVESTOR_PREFIX}/edit`
export const INVESTORS_LOGS = `${SINGLE_INVESTOR_PREFIX}/logs`

export const INVESTMENT_VEHICLE_PROFILE = `${SINGLE_VEHICLE_PREFIX}/profile`
export const INVESTMENT_VEHICLE_PROFILE_EDIT = `${SINGLE_VEHICLE_PREFIX}/profile/edit`
export const VEHICLES_EDIT = `${SINGLE_VEHICLE_PREFIX}/edit`
export const VEHICLES_LOGS = `${SINGLE_VEHICLE_PREFIX}/logs`

export const INVESTOR_MANAGEMENT_INDEX_ROUTES = [
  INVESTOR_MANAGEMENT,
  INVESTORS,
  VEHICLES,
  INVESTORS_CREATE,
  VEHICLES_CREATE,
  INVESTORS_EDIT,
  INVESTORS_LOGS,
  VEHICLES_EDIT,
  VEHICLES_LOGS,
]

export const INVESTOR_ALL_UPDATES = `${INVESTOR_PROFILE}/all-updates`
export const VEHICLES_ALL_UPDATES = `${INVESTMENT_VEHICLE_PROFILE}/all-updates`

export const INVESTOR_PROFILE_VEHICLE_PROFILE = `${INVESTOR_PROFILE}/vehicles/:investmentVehicleId`
export const INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES = `${INVESTOR_PROFILE_VEHICLE_PROFILE}/all-updates`

export const INVESTOR_PROFILE_ROUTES = [INVESTOR_PROFILE, INVESTOR_PROFILE_EDIT]

export const VEHICLE_PROFILE_ROUTES = [
  INVESTMENT_VEHICLE_PROFILE,
  INVESTMENT_VEHICLE_PROFILE_EDIT,
]

const investorManagementRoutes = [
  INVESTOR_ALL_UPDATES,
  VEHICLES_ALL_UPDATES,
  INVESTOR_PROFILE_VEHICLE_PROFILE,
  INVESTOR_PROFILE_VEHICLE_PROFILE_ALL_UPDATES,
  ...INVESTOR_MANAGEMENT_INDEX_ROUTES,
  ...INVESTOR_PROFILE_ROUTES,
  ...VEHICLE_PROFILE_ROUTES,
]

export default investorManagementRoutes
