import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useLocation } from 'react-router-dom'

import { isActingAsFounder, isActingAsFundManager } from 'selectors/auth'

const useShouldBehaveAsFounder = (): boolean => {
  const location = useLocation()
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isComingFromCompanies = location.pathname.includes('companies')
  return Boolean(isFounder || (isFundManager && !isComingFromCompanies))
}

export default useShouldBehaveAsFounder
