import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const StyledAttachmentPreview = styled.div<{
  size?: 'large' | 'small'
  dummy?: boolean
  hasPreview?: boolean
}>`
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.8rem;
  height: 4rem;
  overflow: hidden;
  cursor: pointer;
  border: 0.1rem solid ${color('veryLightGray')};
  transition: border-color 0.2s;

  &:hover {
    border: 0.1rem solid ${color('veryLightBlue')};
  }

  ${(props) =>
    props.size === 'large' &&
    css`
      height: 13.2rem;
      width: 13.2rem;
    `}

  ${(props) =>
    props.dummy &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${color('softBlue', 0.9)(props)};
      span {
        color: ${color('darkGray')(props)};
        font-size: 1.2rem;
        font-weight: normal;
      }
    `}

  ${(props) =>
    props.hasPreview &&
    css`
      gap: 0.8rem;
    `}

    @media ${maxSize.xsm} {
    max-width: 19rem;
  }
`

export const PaperclipIcon = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  color: ${color('lightGray')};
  padding-right: 0.8rem;
`

export const AttachmentInfo = styled.div`
  flex: 1;
  padding-right: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 0;
  color: ${color('darkGray')};
`

export const IconWrapper = styled.div`
  width: 4rem;
  flex-shrink: 0;

  svg {
    font-size: 2rem;
  }
`
