import type { IntlShape } from 'react-intl'
import * as yup from 'yup'

export const MAX_CHAT_TITLE_LENGTH = 80

export const getChatFormSchema = (intl: IntlShape) => {
  return yup.object().shape({
    members: yup.array().of(yup.object()).min(1),
    topic: yup.object(),
    title: yup
      .string()
      .max(
        MAX_CHAT_TITLE_LENGTH,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_CHAT_TITLE_LENGTH }
        )
      ),
  })
}
