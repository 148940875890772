import { useIntl } from 'react-intl'
import { useChatContext, useTypingContext } from 'stream-chat-react'
import type { Event } from 'stream-chat'

import { formatStringList } from 'utils/functions/utils'
import { ChannelData, StreamChatType } from 'containers/Chat/types'

export const useTypingIndicator = <ChannelType extends ChannelData>(
  plainText: boolean = false,
  typingProp?: Record<string, Event<StreamChatType<ChannelType>>>
) => {
  const intl = useIntl()
  const { client } = useChatContext()
  const { typing: typingContext } = useTypingContext()

  const typing = typingProp || typingContext

  if (!client || !typing) {
    return null
  }

  const users = Object.values(typing).filter(
    ({ user }) => user && user.id !== client.user?.id
  )

  if (!users.length) {
    return null
  }

  let typingString: React.ReactNode = intl.formatMessage({
    id: 'chat.severalPeopleAreTyping',
  })

  if (users.length <= 3) {
    const userNames = formatStringList(
      users.map(({ user }) => user?.name || ''),
      3,
      intl
    )
    typingString = intl.formatMessage(
      {
        id: users.length === 1 ? 'chat.isTyping' : 'chat.areTyping',
      },
      {
        names: plainText ? userNames : <b>{userNames}</b>,
      }
    )
  }

  return typingString
}
