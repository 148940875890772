import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import PhoneInputLib from 'react-phone-number-input'
import type { Country } from 'react-phone-number-input'

import { useField } from 'components/Form/hooks'
import Input from 'ui/Input'
import type { InputProps } from 'ui/Input'

import CountrySelector from './components/CountrySelector'
import { PhoneInputContainer } from './PhoneInput.styles'

interface PhoneInputProps extends InputProps {
  name: string
  intlLabel?: string
  intlPlaceholder?: string
  className?: string
}

type ContextType = {
  large: boolean
}

export const PhoneInputContext = React.createContext<ContextType>(
  {} as ContextType
)

const PhoneInput = (props: PhoneInputProps) => {
  const {
    className,
    name,
    label,
    placeholder,
    intlLabel,
    intlPlaceholder,
    ...inputProps
  } = props
  const intl = useIntl()
  const [field, , helper] = useField(name)
  const [currentCountry, setCurrentCountry] = useState<Country | undefined>(
    'US'
  )

  const usPlaceholder = intlPlaceholder
    ? intl.formatMessage({ id: intlPlaceholder })
    : placeholder

  const hasPlaceholder = currentCountry === 'US'

  const contextProps = useMemo(
    () => ({
      large: inputProps.large!!,
    }),
    [inputProps]
  )

  return (
    <PhoneInputContext.Provider value={contextProps}>
      <PhoneInputContainer className={className} large={inputProps.large}>
        <PhoneInputLib
          defaultCountry="US"
          value={field.value}
          onBlur={() => helper.setTouched(true, true)}
          onChange={(newValue) => helper.setValue(newValue)}
          countryCallingCodeEditable
          inputComponent={Input}
          numberInputProps={{
            label: intlLabel ? intl.formatMessage({ id: intlLabel }) : label,
            placeholder: hasPlaceholder ? usPlaceholder : undefined,
            error: field.buildError(),
            icon: field.value ? ['far', 'times'] : undefined,
            iconFontSize: '1.6rem',
            onClickIcon: () => helper.setValue(''),
            ...inputProps,
          }}
          countrySelectProps={{
            name,
          }}
          countrySelectComponent={CountrySelector}
          onCountryChange={(country?: Country) => {
            setCurrentCountry(country)
          }}
          focusInputOnCountrySelection
        />
      </PhoneInputContainer>
    </PhoneInputContext.Provider>
  )
}

export default PhoneInput
