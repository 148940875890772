import { useState } from 'react'
import { EmailContent, FileContent } from 'components/Content'
import { isIndexEmailContent, isIndexFileContent } from 'utils/functions/files'
import { useIntl } from 'react-intl'
import { getDefaultOrganizationsDate } from 'utils/functions/date'
import {
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'
import theme from 'utils/theme'
import { RowProps } from '../FilesMemo/FilesMemo'
import { GridRow, RowDate } from './FileGridRow.styles'

interface FileGridRowProps extends RowProps {
  date?: string
  isLoading: boolean
}

const FileGridRow: React.FC<FileGridRowProps> = ({
  date,
  contents,
  selectedFiles,
  showDropdown,
  showUnreconciledIndicator,
  showSharedIndicator,
  filesAlreadyAdded,
  gridSize,
  isLoading,
  onPreviewFile,
  onDownloadFile,
  onDeleteFile,
  onClickFile,
  onClickGeneralInformation,
  openEmailContentDetails,
}) => {
  const intl = useIntl()
  const [fileId, setFileId] = useState<string | null>(null)

  const items = contents.map((file) => {
    if (isIndexFileContent(file)) {
      return (
        <FileContent
          isLoading={isLoading}
          key={file.id}
          file={file}
          onRightClick={() => setFileId(file.id)}
          isMenuOpen={fileId === file.id}
          onCloseContentMenu={() => setFileId(null)}
          isSelected={!!selectedFiles?.find((f) => f.id === file.id)}
          onDownloadFile={onDownloadFile}
          onPreviewFile={onPreviewFile}
          onClickFile={onClickGeneralInformation}
          onDeleteFile={onDeleteFile}
          onClickGeneralInformation={onClickGeneralInformation}
          showDropdown={showDropdown}
          showUnreconciledIndicator={showUnreconciledIndicator}
          showSharedIndicator={showSharedIndicator}
          size={gridSize}
          filesAlreadyAdded={filesAlreadyAdded}
        />
      )
    }

    if (isIndexEmailContent(file)) {
      return (
        <EmailContent
          isLoading={isLoading}
          onClickFile={openEmailContentDetails}
          onPreviewFile={onClickFile}
          onRightClick={() => setFileId(file.id)}
          isMenuOpen={fileId === file.id}
          onCloseContentMenu={() => setFileId(null)}
          key={file.id}
          content={file}
          isSelected={!!selectedFiles?.find((f) => f.id === file.id)}
          size={gridSize}
          showDropdown={showDropdown}
          showUnreconciledIndicator={showUnreconciledIndicator}
          showSharedIndicator={showSharedIndicator}
          openEmailContentDetails={openEmailContentDetails}
          onDeleteContent={onDeleteFile}
          onPreviewContent={onPreviewFile}
        />
      )
    }

    return null
  })

  if (date) {
    return (
      <div>
        <RowDate>
          {isLoading ? (
            <SkeletonItemWrapper>
              <SkeletonItem
                skeletonShine={theme.colors.filesGridShine}
                width="4rem"
                height="1rem"
              />
            </SkeletonItemWrapper>
          ) : (
            getDefaultOrganizationsDate({ date: new Date(date), intl })
          )}
        </RowDate>
        <GridRow gridSize={gridSize}>{items}</GridRow>
      </div>
    )
  }

  if (items.length)
    return (
      <>
        {items}
        <div />
      </>
    )

  return null
}

export default FileGridRow
