import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { UseQueryOptions } from '@tanstack/react-query'
import CompanyService from 'api/CompanyService'
import InvestmentsFiltersService from 'api/InvestmentsFilterService'
import PortfolioService from 'api/PortfolioService'
import Toast from 'components/Toast'
import { EditFilterFormValues } from 'containers/Investments/CustomFilterView/Modals/EditFilterModal/useEditFilterModal'
import { RenameFilterFormValues } from 'containers/Investments/CustomFilterView/Modals/RenameFilterModal/RenameFilterModal'
import { getNumKeys } from 'containers/Investments/InvestmentsFilters/helpers'
import { InvestmentsFiltersObject } from 'containers/Investments/InvestmentsFilters/types'
import { FormStep, FormSteps } from 'containers/Investments/useHandleModals'
import { useHistory } from 'react-router-dom'
import { formattedDate } from 'utils/functions/date'
import { Filter } from 'utils/functions/normalizers/investmentFilterNormalizer'
import { investmentsKeys } from 'utils/queries/investments'

const FILTER_NAME_ALREADY_EXISTS_ERROR_CODE = 422

interface FilteredHoldingsIdsQueryProps {
  filtersObject: InvestmentsFiltersObject
  hasFiltersApplied: boolean
}

export const useFilteredHoldingsIdsQuery = ({
  filtersObject,
  hasFiltersApplied,
}: FilteredHoldingsIdsQueryProps) => {
  return useQuery(
    investmentsKeys.filteredHoldingsIds(filtersObject),
    async () => {
      const numKeys = getNumKeys(filtersObject)

      if (numKeys === 0) {
        return []
      }

      return CompanyService.getFilteredPortfolioHoldingsIds(filtersObject)
    },
    {
      enabled: hasFiltersApplied,
    }
  )
}

interface FilteredHoldings {
  holdingsIds: string[]
}

export const useFilteredSummary = ({ holdingsIds }: FilteredHoldings) => {
  return useQuery(
    investmentsKeys.filteredSummary(holdingsIds),
    async () => {
      const {
        data: { aggregateSummary, portfoliosData },
      } = await PortfolioService.getFilteredSummary({
        holdingsIds,
      })

      let portfoliosDataArray: any[] = []
      let lastPortfolioUpdate: string = ''

      if (portfoliosData) {
        portfoliosDataArray = Object.keys(portfoliosData).map((key) => {
          return {
            id: key,
            ...portfoliosData[key],
          }
        })

        lastPortfolioUpdate = portfoliosDataArray.reduce((acc, curr) => {
          return acc.lastUpdatedAt > curr.lastUpdatedAt ? acc : curr
        }).lastUpdatedAt
      }

      return {
        aggregateSummary,
        portfoliosData: portfoliosDataArray,
        lastPortfolioUpdate: formattedDate(lastPortfolioUpdate),
      }
    },
    {
      enabled: !!holdingsIds,
    }
  )
}

export const useFilteredHoldings = ({ holdingsIds }: FilteredHoldings) => {
  return useQuery(
    investmentsKeys.filteredHoldings(holdingsIds),
    async () => {
      return PortfolioService.getFilteredHoldings({
        holdingsIds,
      })
    },
    {
      enabled: !!holdingsIds,
    }
  )
}

type QueryOptions = Omit<
  UseQueryOptions,
  'queryKey' | 'queryFn' | 'initialData'
>

export const useAllFiltersQuery = (queryOptions: QueryOptions = {}) => {
  return useQuery(
    investmentsKeys.filters(),
    async () => {
      return InvestmentsFiltersService.getFilters()
    },
    queryOptions
  )
}

export const useGetFilterByIdQuery = (id: string, enabled?: boolean) => {
  return useQuery(
    investmentsKeys.filterById(id),
    () => {
      return InvestmentsFiltersService.getFilterById(id)
    },
    {
      enabled: enabled ?? !!id,
    }
  )
}

interface CreateNewFilterProps {
  handleSetEditFormStep: (step: FormStep) => void
  onInvestmentFilterCreated: (filter: Filter) => void
  onClearAll: ({ shouldRedirect = true }: { shouldRedirect?: boolean }) => void
  onCloseFilterDrawer: () => void
  filtersObject: InvestmentsFiltersObject
  filterName: string
}

export const useCreateNewFilterMutation = ({
  handleSetEditFormStep,
  onInvestmentFilterCreated,
  onClearAll,
  onCloseFilterDrawer,
  filterName,
  filtersObject,
}: CreateNewFilterProps) => {
  return useMutation(
    async () => {
      const newFilter = await InvestmentsFiltersService.createNewFilter({
        name: filterName,
        filtersObject,
      })

      onInvestmentFilterCreated(newFilter)
    },
    {
      onSuccess: () => {
        onCloseFilterDrawer()
        handleSetEditFormStep(FormSteps.DISCARD_FORM)
        onClearAll({
          shouldRedirect: false,
        })

        Toast.displayIntl(
          'investments.filters.filterCreatedSuccessfully',
          'success'
        )
      },

      onError: (err: { status: number }) => {
        if (err.status === FILTER_NAME_ALREADY_EXISTS_ERROR_CODE) {
          handleSetEditFormStep(FormSteps.SECOND_STEP)
          return
        }

        Toast.displayIntl('investments.filters.filterCreatedError', 'error')
      },
    }
  )
}

interface EditNewFilterProps {
  filterId: string
  handleSetEditFormStep: (step: FormStep) => void
  onCloseFilterDrawer: () => void
  onClearAllForFilterInProcess: ({
    shouldRedirect,
  }: {
    shouldRedirect?: boolean
  }) => void
  filtersObject: InvestmentsFiltersObject
  isEditingFromCreatingFilterDrawer: boolean
}

export const useEditNewFilterMutation = ({
  filterId,
  filtersObject,
  isEditingFromCreatingFilterDrawer,
  handleSetEditFormStep,
  onCloseFilterDrawer,
  onClearAllForFilterInProcess,
}: EditNewFilterProps) => {
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation(
    (values: EditFilterFormValues) => {
      return InvestmentsFiltersService.editFilterById({
        id: filterId,
        name: values.filterName,
        filtersObject,
      })
    },
    {
      onSuccess: () => {
        handleSetEditFormStep(FormSteps.DISCARD_FORM)
        onCloseFilterDrawer()
        queryClient.invalidateQueries(investmentsKeys.filters())

        if (isEditingFromCreatingFilterDrawer) {
          onClearAllForFilterInProcess({
            shouldRedirect: false,
          })
          history.push(`/investments/filters/${filterId}`)
        }

        Toast.displayIntl(
          'investments.filters.filterEditedSuccessfully',
          'success'
        )
      },

      onError: () => {
        Toast.displayIntl('investments.filters.filterEditedError', 'error')
      },
    }
  )
}

export const useRenameFilterMutation = ({
  filterId,
  handleShowRenameFilterModal,
  showSuccessToast = true,
}: {
  filterId: string
  handleShowRenameFilterModal?: (state: boolean) => void
  showSuccessToast?: boolean
}) => {
  const queryClient = useQueryClient()

  return useMutation(
    (values: RenameFilterFormValues) => {
      return InvestmentsFiltersService.editFilterById({
        id: filterId,
        name: values.filterName,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(investmentsKeys.filters())
        queryClient.invalidateQueries(investmentsKeys.filterById(filterId))
        handleShowRenameFilterModal?.(false)

        if (showSuccessToast) {
          Toast.displayIntl(
            'investments.filters.filterEditedSuccessfully',
            'success'
          )
        }
      },
      onError: () => {
        Toast.displayIntl('investments.filters.filterEditedError', 'error')
      },
    }
  )
}

export const useDeleteFilterMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    async (id: string) => {
      await InvestmentsFiltersService.deleteFilterById(id)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(investmentsKeys.filters())

        Toast.displayIntl(
          'investments.filters.filterDeletedSuccessfully',
          'success'
        )
      },
      onError: () => {
        Toast.displayIntl('investments.filters.filterDeletedError', 'error')
      },
    }
  )
}
