import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import PortfolioIconComponent from 'components/PortfolioIcon'
import { Color } from 'utils/theme'

export const EntitiesChips = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;

  .small {
    height: 2.3rem;

    p {
      font-size: 1.2rem;
    }
  }
`

export const ShowMoreChip = styled.div`
  align-items: center;
  border-radius: 1.1rem;
  border: 0.1rem solid ${color('veryLightGray')};
  color: ${color('gray')};
  display: flex;
  font-size: 1.4rem;
  font-weight: 400;
  height: 2.3rem;
  padding: 0.3rem 0.7rem;
  width: max-content;

  &:hover {
    border: 0.2rem solid ${color('veryLightBlue')};
    color: ${color('primaryBlue')};
    cursor: pointer;
    padding: 0.2rem 0.6rem;
  }
`

const TooltipContentContainer = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  padding: 0.6rem 0.8rem;
  background: white;
  box-shadow: 0px 0px 1.5rem 0px rgba(16, 21, 39, 0.1);
`
export const GridContainer = styled(TooltipContentContainer)`
  width: 18rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem 0.5rem;

  .small {
    height: 2.3rem;
    padding: 0.2rem;
  }
`
export const ListContainer = styled(TooltipContentContainer)`
  max-height: 19rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  width: 17.1rem; ;
`

export const AvatarContainer = styled.div`
  width: 2.1rem;
  height: 2.1rem;
  img {
    width: 100%;
    height: 100%;
  }
`
export const ListItem = styled.div<{
  backgroundColor?: Color
  borderRadius?: string
  fontWeight?: string
  padding?: string
  gap?: string
}>`
  display: flex;
  align-items: center;
  font-size: 1.2rem;

  ${({ fontWeight }) =>
    fontWeight ? `font-weight: ${fontWeight};` : 'font-weight: 700;'}
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ gap }) => (gap ? `gap: ${gap};` : 'gap: 0.4rem;')}
`

export const Name = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 15rem;
  overflow: hidden;
`

export const PortfolioIcon = styled(PortfolioIconComponent)`
  min-height: 2.1rem;
  min-width: 2.1rem;
  svg {
    font-size: 1.2rem;
  }
`
