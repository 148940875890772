import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './UpdateLayout.module.scss'

const UpdateContent = ({ children, fullContent, isCardExpanded }) => (
  <div
    className={classNames({
      [styles.contentContainer]: fullContent,
      [styles.contentContainerEdit]: !fullContent,
      [styles.expandCard]: isCardExpanded,
    })}
  >
    {children}
  </div>
)

UpdateContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  fullContent: PropTypes.bool,
  isCardExpanded: PropTypes.bool.isRequired,
}
UpdateContent.defaultProps = {
  fullContent: false,
}

export default UpdateContent
