import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { AccountingUpdate } from 'utils/types/update'
import { AccountingFormValues } from 'utils/types/updateForm'
import { UpdateTypes } from 'utils/constants/updates'
import {
  useDeleteUpdateMutation,
  useFetchUpdateQuery,
  useUpdateAccountingMutation,
} from 'utils/hooks/queries/useUpdateQuery'
import { Nullable } from 'utils/types/common'
import { PeriodType, BalanceDateType } from 'utils/constants/accounting'
import { getBalanceDateFromTimeFrame } from 'utils/functions/xeroReports'
import { getEditAccountingSchema } from 'utils/schemas/updates'
import { getPermissionsFromServerUpdate } from 'utils/functions/api/updates'
import { FIELD_NAMES } from '../constants/constants'

const useEditAccounting = () => {
  const intl = useIntl()
  const validationSchema = getEditAccountingSchema(intl)
  const { updateId } = useParams<{
    updateId: string
  }>()

  const { data: update, isLoading } = useFetchUpdateQuery<AccountingUpdate>({
    updateId,
    updateType: UpdateTypes.ACCOUNTING,
  })

  const accountingValues = useMemo((): Nullable<AccountingFormValues> => {
    if (isLoading || !update) return null

    const { title, reportType, reportData, repetition } = update.item
    const {
      paymentsOnly,
      periods,
      standardLayout,
      reportDatePeriod,
      timeFrame,
    } = reportData
    const files = update.contents
    const tags = update.tags.map((tag) => ({
      ...tag,
      alreadyExists: true,
    }))

    return {
      [FIELD_NAMES.title]: title,
      [FIELD_NAMES.date]: update.date,
      [FIELD_NAMES.periods]: String(periods) as PeriodType,
      [FIELD_NAMES.reportType]: reportType,
      [FIELD_NAMES.repetition]: repetition,
      [FIELD_NAMES.balanceDate]: getBalanceDateFromTimeFrame(
        timeFrame as BalanceDateType
      ),
      [FIELD_NAMES.reportDatePeriod]: reportDatePeriod,
      [FIELD_NAMES.standardLayout]: standardLayout,
      [FIELD_NAMES.paymentsOnly]: paymentsOnly,
      files,
      permissions: getPermissionsFromServerUpdate(update),
      updateType: UpdateTypes.ACCOUNTING,
      tags,
      owner: update.user,
    }
  }, [update, isLoading])

  const { mutate: editMutation } = useUpdateAccountingMutation({ updateId })

  const { mutate: deleteMutation } = useDeleteUpdateMutation(
    update?.item.id!,
    update?.updateType!,
    update?.type!
  )

  return {
    accountingValues,
    isLoading,
    validationSchema,
    updateId,
    update,
    editMutation,
    deleteMutation,
  }
}

export default useEditAccounting
