import React from 'react'
import ContentLoader from 'react-content-loader'

const EmailPreviewLoader = () => (
  <ContentLoader
    speed={1}
    height="100vh"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
  >
    <rect ry="0" rx="0" x="0" y="0" width="100%" height="100vh" />
  </ContentLoader>
)

export default EmailPreviewLoader
