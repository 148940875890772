import React, { useCallback, useEffect, useRef, useState } from 'react'

import { getFileExtension, isIndexFileContent } from 'utils/functions/files'
import { FileExtension, MixedContent } from 'utils/types/files'
import { useDownloadFile } from 'utils/hooks/files/useDownloadFile'
import { Nullable } from 'utils/types/common'

interface Props {
  contents: MixedContent[]
  selectedContent?: MixedContent
  onClosePreview: () => void
}

export const usePreviewContents = ({
  contents,
  selectedContent,
  onClosePreview,
}: Props) => {
  const typeChecker = {
    isAnImage: false,
    isAPdf: false,
  }
  const [currentContentIndex, setCurrentContentIndex] = useState<number>(
    contents.findIndex((content) => content.id === selectedContent?.id)
  )
  const currentContent = contents[currentContentIndex]
  const containerRef = useRef<HTMLDivElement>(null)
  const { onDownloadFile } = useDownloadFile()
  const [contentPreviewContainer, setContentPreviewContainer] =
    useState<Nullable<HTMLDivElement>>(null)
  const handleContentPreviewContainerRef = useCallback(
    (node: Nullable<HTMLDivElement>) => {
      setContentPreviewContainer(node)
    },
    []
  )

  const isFirstContent = currentContentIndex === 0
  const isLastContent = currentContentIndex === contents.length - 1

  const nextContent = useCallback(() => {
    if (currentContentIndex < contents.length - 1) {
      setCurrentContentIndex((index) => index + 1)
    }
  }, [contents, currentContentIndex])

  const previousContent = useCallback(() => {
    if (currentContentIndex > 0) {
      setCurrentContentIndex((index) => index - 1)
    }
  }, [currentContentIndex])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        nextContent()
      } else if (event.key === 'ArrowLeft') {
        previousContent()
      } else if (event.key === 'Escape') {
        onClosePreview()
      }
    },
    [nextContent, onClosePreview, previousContent]
  )

  const onFilePreviewContainerClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (contentPreviewContainer === event.target) {
        onClosePreview()
      }
    },
    [onClosePreview, contentPreviewContainer]
  )

  if (isIndexFileContent(currentContent)) {
    const fileType = getFileExtension(currentContent?.fileFormat)
    typeChecker.isAnImage = fileType === FileExtension.IMAGE
    typeChecker.isAPdf = fileType === FileExtension.PDF
  }

  useEffect(() => {
    setCurrentContentIndex(
      contents.findIndex((content) => content.id === selectedContent?.id)
    )
  }, [contents, selectedContent])

  useEffect(() => {
    containerRef.current?.focus()
  }, [])

  return {
    currentContent,
    containerRef,
    contentPreviewContainer,
    handleContentPreviewContainerRef,
    typeChecker,
    isFirstContent,
    isLastContent,
    nextContent,
    previousContent,
    onDownloadFile,
    handleKeyDown,
    onFilePreviewContainerClick,
  }
}
