import { useMemo } from 'react'
import { Nullable } from 'utils/types/common'
import { VisibleFundHoldingsColumns } from 'utils/functions/renderers/renderFundHoldingsHelper'
import { useGetUserGroup } from '../queries/useUserGroupQuery'
import {
  useFilteredHoldings,
  useFilteredSummary,
} from '../queries/useInvestmentsFiltersQuery'
import {
  AggregateFundSummary,
  PortfoliosData,
} from './useFetchInvestmentsSummary'

export type FilteredSummaryData = {
  summaryData: {
    aggregateSummary: AggregateFundSummary
    portfoliosData: PortfoliosData[]
    lastPortfolioUpdate: string
  }
  aggregateInvestmentHoldings: any
}

interface Props {
  portfolioHoldingsIds: string[]
}

export const useFetchInvestmentsFilteredSummary = ({
  portfolioHoldingsIds,
}: Props) => {
  const { data: summaryData, isLoading: isLoadingSummary } = useFilteredSummary(
    {
      holdingsIds: portfolioHoldingsIds,
    }
  )

  const { data: aggregateInvestmentHoldings, isLoading: isLoadingHolding } =
    useFilteredHoldings({
      holdingsIds: portfolioHoldingsIds,
    })

  const { data: userGroupSettings, isLoading: isLoadingAvailableColumns } =
    useGetUserGroup()

  const availableColumns = userGroupSettings?.settings
    ?.visibleFields as VisibleFundHoldingsColumns
  const userGroupId = userGroupSettings?.id as string

  const data = useMemo<Nullable<FilteredSummaryData>>(() => {
    if (!summaryData || !aggregateInvestmentHoldings) return null

    return {
      summaryData,
      aggregateInvestmentHoldings,
    }
  }, [summaryData, aggregateInvestmentHoldings])

  return {
    data,
    availableColumns,
    isLoading:
      isLoadingSummary || isLoadingHolding || isLoadingAvailableColumns,
    userGroupId,
  }
}
