import { useHistory } from 'react-router-dom'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EntityLink from 'components/Breadcrumb/Components/EntityLink'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import {
  FUND_HOLDINGS_FUNDS_UPDATES,
  FUND_HOLDINGS_COMPANIES_UPDATES,
  FUND_HOLDINGS_FUNDS_INVESTMENTS,
  FUND_HOLDINGS_COMPANIES_INVESTMENTS,
} from 'routes/constant/InvestorGroupRoutes'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import { Update } from 'utils/types/update'
import { CompanyHoldingData, FundProfile } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'

interface Props {
  showInitialLink: any
  update?: Update
  entityToShow?: FundProfile | CompanyHoldingData
  url: URL
  isShowingCompany: boolean
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
  getAvatar: () => Nullable<JSX.Element>
}

const FundHoldingsBreadcrumb = ({
  entityToShow,
  getAvatar,
  isCreatingNewUpdate,
  isShowingCompany,
  initialLinkRedirect,
  update,
  url,
  showInitialLink,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  if (!entityToShow) {
    return null
  }

  const activeTabs = [
    FUND_HOLDINGS_COMPANIES_UPDATES.replace(':companyId', entityToShow.id),
    FUND_HOLDINGS_COMPANIES_INVESTMENTS.replace(':companyId', entityToShow.id),
    FUND_HOLDINGS_FUNDS_UPDATES.replace(':fundId', entityToShow.id),
    FUND_HOLDINGS_FUNDS_INVESTMENTS.replace(':fundId', entityToShow.id),
  ]

  return (
    <NavigationWrapper>
      {showInitialLink && (
        <>
          <InitialLink
            isActive={false}
            label={intl.formatMessage({ id: 'breadcrumb.holdings' })}
            onClickHandler={() => history.push(initialLinkRedirect)}
          />

          <ArrowWrapper>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </ArrowWrapper>
        </>
      )}

      {entityToShow && (
        <>
          <EntityLink
            isActive={activeTabs.some((tab) => tab === url.current)}
            label={entityToShow.name}
            entityLogo={getAvatar()}
            onClickHandler={() => {
              const entityUrl = isShowingCompany
                ? FUND_HOLDINGS_COMPANIES_UPDATES.replace(
                    ':companyId',
                    entityToShow.id
                  )
                : FUND_HOLDINGS_FUNDS_UPDATES.replace(
                    ':fundId',
                    entityToShow.id
                  )

              if (url.current !== entityUrl) {
                history.push(entityUrl)
              }
            }}
          />

          {update && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </>
      )}

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default FundHoldingsBreadcrumb
