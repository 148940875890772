import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CTA = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  margin-top: 1.8rem;
`

export const CheckboxWrapper = styled.div`
  margin-left: 1.5rem;
`
