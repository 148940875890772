import * as yup from 'yup'
import type { IntlShape } from 'react-intl'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'

export const getAboutCompanySchema = (intl: IntlShape) =>
  yup.object().shape({
    companyName: yup
      .string()
      .required(intl.formatMessage({ id: 'aboutCompany.requiredField' }))
      .nullable()
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      )
      .nullable(),
    legalEntityName: yup
      .string()
      .required(intl.formatMessage({ id: 'aboutCompany.requiredField' }))
      .nullable(),
    website: yup
      .string()
      .url(intl.formatMessage({ id: 'general.invalidUrl' }))
      .nullable(),
    role: yup.string(),
    isFounder: yup.boolean(),
  })
