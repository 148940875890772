import { useCallback, useEffect, useRef, useState } from 'react'
import { useResizeObserver } from 'utils/hooks/useResizeObserver'

interface PageType {
  originalWidth: number
  originalHeight: number
}

interface Props {
  autoScale?: boolean
}

export const DIRECTION = {
  LEFT: 'left',
  RIGHT: 'right',
} as const

export type Direction = typeof DIRECTION[keyof typeof DIRECTION]

export const PDF_DOCUMENT_ID = 'pdf-document'

export const usePDFPreviewer = ({ autoScale }: Props) => {
  const [pagesCount, setPagesCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1)
  const pageRef = useRef<{ state: { page: PageType } }>(null)

  const calculatePageScale = useCallback(
    (page: PageType) => {
      if (autoScale) {
        const parentDiv = document.querySelector(`#${PDF_DOCUMENT_ID}`)

        if (parentDiv) {
          const computedStyle = getComputedStyle(parentDiv)
          const paddingLeft = parseFloat(computedStyle.paddingLeft)
          const paddingRight = parseFloat(computedStyle.paddingRight)
          const width = parseFloat(computedStyle.width)
          const height = parseFloat(computedStyle.height)

          const pageWidthScale =
            (width - paddingLeft - paddingRight) / page.originalWidth

          const pageHeightScale = height / page.originalHeight

          const pageScale = Number(
            Math.min(pageWidthScale, pageHeightScale).toFixed(1)
          )

          if (scale !== pageScale && pageScale <= 1) {
            setScale(pageScale)
          }
        }
      }
    },
    [autoScale, scale]
  )

  const navigate = useCallback(
    (direction: Direction) => {
      if (direction === DIRECTION.RIGHT && pageNumber < pagesCount) {
        setPageNumber((currPageNumber) => currPageNumber + 1)
      } else if (direction === DIRECTION.LEFT && pageNumber > 1) {
        setPageNumber((currPageNumber) => currPageNumber - 1)
      }
    },
    [pageNumber, pagesCount]
  )

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setPagesCount(numPages)
  }, [])

  const increaseScale = useCallback(() => {
    setScale((currScale) => currScale + 0.1)
  }, [])

  const decreaseScale = useCallback(() => {
    setScale((currentScale) => {
      if (Number(currentScale.toFixed(1)) > 0.1) {
        return currentScale - 0.1
      }

      return currentScale
    })
  }, [])

  const screenSize = useResizeObserver('')

  useEffect(() => {
    if (pageRef.current?.state?.page) {
      calculatePageScale(pageRef.current?.state?.page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize])

  return {
    calculatePageScale,
    navigate,
    onDocumentLoadSuccess,
    increaseScale,
    decreaseScale,
    pageNumber,
    pagesCount,
    scale,
    pageRef,
  }
}
