import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './UpdateLayout.module.scss'

const UpdateCard = ({ children }) => (
  <div className={classNames(styles.updateCardContainer)}>{children}</div>
)

UpdateCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default UpdateCard
