import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import type { IntlShape } from 'react-intl/src/types'
import ExpandArrow from 'ui/Table/Row/ExpandArrow'
import { Column } from 'ui/Table/types'
import { displayCurrency, displayNumber } from 'utils/functions/number'
import theme from 'utils/theme'
import { InvestmentVehicle } from 'utils/types/investors'
import { IconContainer, InvestorNameCell } from './renderFundHoldingsHelper'

interface VehicleColumns {
  intl: IntlShape
  isMobile: boolean
  styles: any
}

export const getVehicleColumns = ({
  intl,
  isMobile,
  styles,
}: VehicleColumns): Column<InvestmentVehicle>[] => [
  {
    id: 'expand',
    sortDisabled: true,
    fixed: !isMobile,
    minWidth: 0,
    cellRenderer: ExpandArrow,
  },
  {
    id: 'name',
    label: intl.formatMessage({
      id: 'investorManagement.investmentVehicle.name',
    }),
    hidden: false,
    fixed: !isMobile,
    minWidth: 215,
    flex: 4,
    sortKey: 'name',
    cellRenderer: ({ rowData }) => {
      return (
        <InvestorNameCell className="fs-exclude">
          <IconContainer>
            <FontAwesomeIcon
              color={theme.colors.primaryBlue}
              size="sm"
              icon={['far', 'hand-holding-usd']}
            />
          </IconContainer>
          <span className="investment-vehicle-name">{rowData.name}</span>
        </InvestorNameCell>
      )
    },
  },
  {
    id: 'committedCapital',
    label: intl.formatMessage({
      id: 'investorManagement.investmentVehicle.committedCapital',
    }),
    flex: 1,
    minWidth: 85,
    sortKey: 'totalCommitment',
    cellRenderer: ({ rowData }) => {
      return (
        <span className={classNames(styles?.cell, 'fs-exclude')}>
          {displayCurrency(intl, rowData.totalCommitment)}
        </span>
      )
    },
  },
  {
    id: 'capitalCalled',
    label: intl.formatMessage({
      id: 'investorManagement.investmentVehicle.capitalCalled',
    }),
    flex: 1,
    minWidth: 85,
    sortKey: 'totalCapital',
    cellRenderer: ({ rowData }) => {
      return (
        <span className={classNames(styles?.cell, 'fs-exclude')}>
          {displayCurrency(intl, rowData.totalCapital)}
        </span>
      )
    },
  },
  {
    id: 'unfundedCommitment',
    label: intl.formatMessage({
      id: 'investorManagement.investmentVehicle.unfundedCommitment',
    }),
    flex: 1,
    minWidth: 85,
    sortKey: 'unfundedCommitment',
    cellRenderer: ({ rowData }) => {
      return (
        <span className={classNames(styles?.cell, 'fs-exclude')}>
          {displayCurrency(intl, rowData.unfundedCommitment)}
        </span>
      )
    },
  },
  {
    id: 'distributions',
    label: intl.formatMessage({
      id: 'investorManagement.investmentVehicle.amountDistributed',
    }),
    flex: 0.9,
    minWidth: 85,
    sortKey: 'distributions',
    cellRenderer: ({ rowData }) => {
      return (
        <span className={classNames(styles?.cell, 'fs-exclude')}>
          {displayCurrency(intl, rowData.distributions)}
        </span>
      )
    },
  },
  {
    id: 'totalInvestment',
    label: intl.formatMessage({
      id: 'investorManagement.investmentVehicle.totalInvestment',
    }),
    flex: 1,
    minWidth: 85,
    sortKey: 'totalInvestment',
    cellRenderer: ({ rowData }) => {
      return (
        <span className={classNames(styles?.cell, 'fs-exclude')}>
          {displayNumber(rowData.totalInvestment)}
        </span>
      )
    },
  },
]
