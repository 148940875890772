import styled from 'styled-components'
import { MAC_DESKTOP_HEADER_HEIGHT } from 'utils/constants/electron'
import { color } from 'utils/functions/colors'

export const LoginContainer = styled.div`
  display: flex;
  min-height: calc(100vh - ${MAC_DESKTOP_HEADER_HEIGHT});
`

export const LeftSideBackground = styled.div`
  position: relative;
  background-color: ${color('darkBlue')};
  display: none;
  flex: 1;
  overflow: hidden;

  @media (min-width: 76rem) {
    display: block;
  }
`

export const LeftSideLogoContainer = styled.div`
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 50%;
`

export const RightSideBackground = styled.div`
  position: relative;
  background-color: ${color('darkBlue')};
  flex: 1;

  @media (min-width: 76rem) {
    background: white;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
