import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'

export const DateFormatterWrapper = styled.div<{
  isFutureDate: boolean
  centered?: boolean
}>`
  display: flex;
  align-items: center;
  padding-left: 0.8rem;
  flex: 1;
  height: 100%;
  color: ${color('lightGray')};

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
      padding-left: 0;
    `}

  ${({ isFutureDate }) =>
    isFutureDate &&
    css`
      border: 1px solid ${color('red')};
    `}
`

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  margin-right: 5px;
`

export const Label = styled.span<{ hasDate: boolean }>`
  font-weight: 400;
  font-size: 1.4rem;
  color: ${({ hasDate }) =>
    hasDate ? color('darkGray') : color('lightGray', 0.5)};
`

export const CloseIconContainer = styled.div<{ centered?: boolean }>`
  position: absolute;
  left: 11.5rem;
  cursor: pointer;

  :hover {
    color: ${color('primaryBlue')};
  }

  ${({ centered }) =>
    centered &&
    css`
      left: 14rem;
    `}
`
