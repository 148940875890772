import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const ThreadInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkGray')};

  :hover {
    cursor: pointer;
  }
`

export const ThreadInfoText = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkGray')};

  :hover {
    text-decoration: underline;
  }
`
