/* eslint-disable no-shadow */
import styled from 'styled-components'

export enum Direction {
  Horizontal,
  Vertical,
}

export const Separator = styled.div<{
  space?: string
  border?: boolean
  direction?: Direction
  height?: string
  separation?: string
}>`
  height: ${(props) => {
    if (props.height) {
      return props.height
    }

    return props.direction === Direction.Vertical
      ? '100%;'
      : props.space || '1.8rem;'
  }};
  border-bottom: ${(props) =>
    props.direction === Direction.Vertical
      ? '0'
      : props.border && '.1rem solid #E9E9E9;'};
  border-right: ${(props) =>
    props.direction === Direction.Vertical
      ? '1px solid #E9E9E9;'
      : props.border && '.1rem solid #E9E9E9;'};
  width: ${(props) => props.direction === Direction.Vertical && '1px;'};

  ${(props) => {
    if (props.separation) {
      return props.direction === Direction.Vertical
        ? `margin: 0 ${props.separation} 0 ${props.separation}`
        : `${props.separation} 0 ${props.separation} 0`
    }
    return ''
  }};
`
