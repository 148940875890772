import { useCallback } from 'react'

import { Inbound } from 'utils/types/inbounds'
import Toast from 'components/Toast'
import { useDeleteInboundMutation } from './useInboundsQuery'

interface Props {
  onDeleteSuccess: () => void
  inbound?: Inbound
}

export const useDeleteInbound = ({ onDeleteSuccess, inbound }: Props) => {
  const { mutateAsync, isLoading } = useDeleteInboundMutation()

  const onDelete = useCallback(async () => {
    try {
      if (inbound) {
        await mutateAsync(inbound.id)
        onDeleteSuccess()
        Toast.displayIntl(['inbounds.delete.success', { email: inbound.email }])
      }
    } catch {
      Toast.displayIntl('inbounds.delete.error', 'error')
    }
  }, [inbound, onDeleteSuccess, mutateAsync])

  return { onDelete, isLoading }
}
