import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import CWLoader from 'components/CWLoader'
import { listAnimation } from 'utils/animations/list'
import { Inbound } from 'utils/types/inbounds'
import { getUser } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { Roles } from 'utils/constants/groups'
import DeleteInboundModal from '../DeleteInboundModal'
import InboundRow from './InboundRow'
import { useInboundsQuery } from '../../hooks'
import InboundSettings from '../InboundSettings'
import { LoaderWrapper, Ul, ZeroState } from './InboundsList.styles'

const InboundsList = () => {
  const intl = useIntl()
  const [inboundToDelete, setInboundToDelete] = useState<Inbound>()
  const [inboundIdToShow, setInboundIdToShow] = useState<string>()
  const currentUser = useAppSelector(getUser)
  const { data: inbounds, isLoading } = useInboundsQuery()
  const hasInbounds = !!inbounds?.length
  const isGroupAdmin =
    currentUser.currentGroupRole === Roles.MANAGER ||
    currentUser.currentGroupRole === Roles.OWNER

  const openDeleteModal = (inbound: Inbound) => {
    setInboundToDelete(inbound)
  }

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CWLoader text={intl.formatMessage({ id: 'inbounds.loading' })} />
      </LoaderWrapper>
    )
  }

  if (!hasInbounds) {
    return (
      <ZeroState>
        <FormattedMessage id="inbounds.zeroState" />
      </ZeroState>
    )
  }

  return (
    <>
      <Ul initial="hidden" animate="visible" variants={listAnimation}>
        {inbounds.map((inbound) => (
          <InboundRow
            key={inbound.email}
            inbound={inbound}
            openDeleteModal={() => openDeleteModal(inbound)}
            openSettings={() => setInboundIdToShow(inbound.id)}
            isDefaultInbound={inbound.isDefaultInbound}
            canEdit={inbound.userId === currentUser?.id || isGroupAdmin}
          />
        ))}
      </Ul>
      {inboundToDelete && (
        <DeleteInboundModal
          show={!!inboundToDelete}
          onHide={() => setInboundToDelete(undefined)}
          inbound={inboundToDelete}
        />
      )}
      <InboundSettings
        inboundId={inboundIdToShow!}
        isOpen={!!inboundIdToShow}
        onClose={() => setInboundIdToShow(undefined)}
      />
    </>
  )
}

export default InboundsList
