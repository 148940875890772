import { memo } from 'react'
import FetchingMoreSkeleton from 'components/Skeletons/Files/Thumbnails/FetchingMoreSkeleton'
import { MixedContent } from 'utils/types/files'
import { ContentFilters } from 'api/ContentService'
import { FileViewEnum } from 'components/FileViewSelector'
import FileGridRow from '../FileGridRow/FileGridRow'
import useFilesMemo from './useFilesMemo'

import { ByDateFilesContainer, ByNameFilesContainer } from './Files.styles'

export interface RowProps {
  contents: MixedContent[]
  selectedFiles?: MixedContent[]
  showDropdown?: boolean
  showUnreconciledIndicator?: boolean
  showSharedIndicator?: boolean
  filesAlreadyAdded?: string[]
  isModalBrowseFileView?: boolean
  gridSize: FileViewEnum
  onPreviewFile?: (file: MixedContent) => void
  onDownloadFile?: (file: MixedContent) => void
  onDeleteFile?: (file: MixedContent) => void
  onClickFile: (file: MixedContent) => void
  onClickGeneralInformation?: (content: MixedContent) => void
  openEmailContentDetails?: (content: MixedContent) => void
}

interface GridProps {
  filters: Partial<ContentFilters>
}

interface FilesGridProps extends GridProps, RowProps {
  isGroupedByDate?: boolean
  isLoading: boolean
  renderLoadMoreSkeleton?: boolean
}

const areEquals = (
  { filters: oldFilters, contents, selectedFiles, gridSize }: FilesGridProps,
  {
    filters: newFilters,
    contents: newContents,
    selectedFiles: newSelectedFiles,
    gridSize: newGridSize,
  }: FilesGridProps
) => {
  const oldSelectedFilesIds = selectedFiles?.map((file) => file.id)
  const newSelectedFilesIds = newSelectedFiles?.map((file) => file.id)

  return (
    oldFilters.direction === newFilters.direction &&
    oldFilters.orderBy === newFilters.orderBy &&
    oldFilters.page === newFilters.page &&
    oldFilters.name === newFilters.name &&
    oldFilters.onlyUnreconciled === newFilters.onlyUnreconciled &&
    oldFilters.showPublicFiles === newFilters.showPublicFiles &&
    oldFilters.uploadModal === newFilters.uploadModal &&
    contents.length === newContents.length &&
    oldSelectedFilesIds === newSelectedFilesIds &&
    gridSize === newGridSize
  )
}

const FilesGrid = ({
  filters: _filters,
  contents,
  showDropdown,
  showUnreconciledIndicator,
  showSharedIndicator,
  selectedFiles,
  gridSize,
  filesAlreadyAdded,
  isLoading,
  renderLoadMoreSkeleton,
  isGroupedByDate = false,
  isModalBrowseFileView = false,
  onClickFile,
  onPreviewFile,
  onDownloadFile,
  onDeleteFile,
  onClickGeneralInformation,
  openEmailContentDetails,
}: FilesGridProps) => {
  const { dates, groupedFilesByDate, fakeArrForSkeleton } = useFilesMemo({
    contents,
    isLoading,
    isGroupedByDate,
  })

  if (isGroupedByDate) {
    return (
      <ByDateFilesContainer>
        {dates?.map((date) => (
          <FileGridRow
            date={date}
            contents={groupedFilesByDate[date]}
            isLoading={isLoading}
            showDropdown={showDropdown}
            showUnreconciledIndicator={showUnreconciledIndicator}
            showSharedIndicator={showSharedIndicator}
            selectedFiles={selectedFiles}
            gridSize={gridSize}
            filesAlreadyAdded={filesAlreadyAdded}
            onClickFile={onClickFile}
            onPreviewFile={onPreviewFile}
            onDownloadFile={onDownloadFile}
            onDeleteFile={onDeleteFile}
            onClickGeneralInformation={onClickGeneralInformation}
            openEmailContentDetails={openEmailContentDetails}
          />
        ))}

        {renderLoadMoreSkeleton && (
          <FetchingMoreSkeleton
            gridSize={gridSize}
            orderByDate={isGroupedByDate}
            amountOfItems={7}
          />
        )}
      </ByDateFilesContainer>
    )
  }

  return (
    <>
      <ByNameFilesContainer isModalBrowseFileView={isModalBrowseFileView}>
        <FileGridRow
          contents={isLoading ? fakeArrForSkeleton! : contents}
          isLoading={isLoading}
          showDropdown={showDropdown}
          showUnreconciledIndicator={showUnreconciledIndicator}
          showSharedIndicator={showSharedIndicator}
          selectedFiles={selectedFiles}
          gridSize={gridSize}
          filesAlreadyAdded={filesAlreadyAdded}
          onClickFile={onClickFile}
          onPreviewFile={onPreviewFile}
          onDownloadFile={onDownloadFile}
          onDeleteFile={onDeleteFile}
          onClickGeneralInformation={onClickGeneralInformation}
          openEmailContentDetails={openEmailContentDetails}
        />
      </ByNameFilesContainer>

      {renderLoadMoreSkeleton && (
        <FetchingMoreSkeleton
          gridSize={gridSize}
          orderByDate={isGroupedByDate}
          amountOfItems={7}
        />
      )}
    </>
  )
}

export default memo(FilesGrid, areEquals)
