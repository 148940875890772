import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Title from 'components/Title'
import { GridType } from 'utils/constants/gridType'
import GridSelector from 'components/GridSelector'
import List from 'components/List'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { gridAnimation } from 'utils/animations/grid'
import { TitleContainer } from 'containers/Analytics/Analytics.styles'
import { FileContent } from 'utils/types/files'
import Separator from 'ui/Separator'

import AttachmentSidebar from '../AttachmentSidebar/AttachmentSidebar'
import AttachmentRowCard from '../AttachmentCard/AttachmentRowCard'
import AttachmentGridCard from '../AttachmentCard/AttachmentGridCard'
import { AttachmentsGrid } from './AttachmentsAnalytics.styles'

interface AttachmentsAnalyticsProps {
  attachments?: FileContent[]
}
const AttachmentsAnalytics: React.FC<AttachmentsAnalyticsProps> = ({
  attachments = [],
}) => {
  const [gridType, setGridType] = useState(GridType.GRID)
  const [sidebar, setSidebar] = useState<{
    isOpen: boolean
    attachment?: FileContent
  }>({ isOpen: false })
  const { matches: smallDesktop } = useMediaQuery('(max-width: 1439px)')
  const intl = useIntl()

  return (
    <div data-testid="attachments-analytics">
      <TitleContainer>
        <Title
          title={intl.formatMessage(
            { id: 'analytics.attachedFiles' },
            { countAttachments: attachments.length }
          )}
          icon={['far', 'paperclip']}
        />
        {!smallDesktop ? (
          <GridSelector
            data-testid="grid-selector"
            initialGridType={gridType}
            onChange={setGridType}
            buttonSize="3.2rem"
          />
        ) : (
          <Separator space="5rem" />
        )}
      </TitleContainer>

      {gridType === GridType.GRID || smallDesktop ? (
        <AttachmentsGrid
          data-testid="attachments-grid"
          initial="hidden"
          animate="visible"
          variants={gridAnimation}
        >
          {attachments.map((attachment) => (
            <AttachmentGridCard
              key={attachment.id}
              attachment={attachment}
              onSelect={() => setSidebar({ isOpen: true, attachment })}
              isSelected={attachment.id === sidebar.attachment?.id}
            />
          ))}
        </AttachmentsGrid>
      ) : (
        <div data-testid="attachments-table">
          <List
            overflow="visible"
            removeMaxHeight
            rowHeightAuto
            striped={false}
            keyExtractor={(index) => attachments[index].id}
            elements={attachments.map((attachment) => (
              <AttachmentRowCard
                key={attachment.id}
                attachment={attachment}
                onSelect={() => setSidebar({ isOpen: true, attachment })}
              />
            ))}
          />
        </div>
      )}
      {sidebar.isOpen && (
        <AttachmentSidebar
          isOpen={sidebar.isOpen}
          closeDrawer={() => setSidebar({ isOpen: false })}
          attachment={sidebar.attachment!}
        />
      )}
    </div>
  )
}

export default AttachmentsAnalytics
