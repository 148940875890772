import React from 'react'
import PropTypes from 'prop-types'

import { ReactComponent as CWLoaderSVG } from 'assets/images/CWLoader_dark.svg'

import { Container, Content, Text } from './CWLoader.styles'

const CWLoader = ({
  text,
  darkText,
  column,
  logoSize,
  color,
  marginTop,
  svgSize,
}) => {
  const svgProps = svgSize
    ? {
        width: svgSize,
        height: svgSize,
      }
    : {}

  return (
    <Container marginTop={marginTop}>
      <Content logoSize={logoSize} column={column} color={color}>
        <CWLoaderSVG {...svgProps} />
        {text && (
          <Text darkText={darkText} column={column}>
            {text}
          </Text>
        )}
      </Content>
    </Container>
  )
}

CWLoader.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  darkText: PropTypes.bool,
  column: PropTypes.bool,
  logoSize: PropTypes.string,
  svgSize: PropTypes.number,
  color: PropTypes.string,
  marginTop: PropTypes.string,
}

CWLoader.defaultProps = {
  text: '',
  darkText: false,
  column: false,
  logoSize: '',
  color: '',
  marginTop: '',
}
export default CWLoader
