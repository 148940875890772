import styled from 'styled-components'
import IconButton from 'ui/IconButton'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  background: var(--color-white);
  position: relative;
  width: 100%;

  h2[class*='subheader'] {
    color: var(--color-gray-900);
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 3.2rem;
    margin-bottom: 2.4rem;
  }
`

export const GroupSetupLayout = styled.div`
  min-height: 67vh;
`

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`

export const CenterBox = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  max-width: 55rem;
  margin: auto;
`

export const Paragraph = styled.p`
  font-size: 1.4rem;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.08rem;
  color: var(--color-gray-600);
`

export const BtnLogOut = styled.button`
  position: absolute;
  top: 3rem;
  right: 9rem;
  color: var(--color-gray-800);
  font-size: 3rem;
  cursor: pointer;
  z-index: 1;
`

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  margin: 0 -2.4rem;
`

export const Header = styled.div<{ justify?: string }>`
  padding: 1.2rem 2.4rem;
  width: 100%;
  background: white;
  display: flex;
  justify-content: ${(props) => props.justify || 'center'};
  border-bottom: 0.1rem solid ${color('veryLightGray')};
  align-items: center;
  box-shadow: 0rem 0rem 0.8rem 0rem rgba(16, 21, 39, 0.05);
  position: sticky;
  top: 0;
  z-index: 2;
`

export const ClockworkLogoStyles = styled.div`
  fill: black;
`

export const CloseIcon = styled(IconButton)`
  background-color: ${color('veryLightBlue', 0.4)};
`
