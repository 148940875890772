import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

interface ProgressProps {
  totalSteps: number
  currentStepIndex: number
}

interface ProgressLabelProps {
  isActive: boolean
}

export const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 0.4rem;
  position: relative;
  background-color: ${color('lightGray', 0.2)};
  margin-bottom: 1rem;
`

export const ProgressFill = styled.div<ProgressProps>`
  background-color: ${color('primaryBlue')};
  height: 100%;
  width: ${({ currentStepIndex, totalSteps }) =>
    `${(100 / totalSteps) * (currentStepIndex + 1)}%`};
  transition: width 0.2s;
`

export const ProgressLabelWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;

  @media ${maxSize.md} {
    display: none;
  }
`
export const ProgressLabel = styled.span<ProgressLabelProps>`
  color: ${({ isActive }) =>
    isActive ? color('primaryBlue') : color('lightGray')};
  font-size: 1.4rem;
  top: 0;
  white-space: nowrap;
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`
