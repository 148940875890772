import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Separator.module.scss'

const Separator = ({ children, className }) => (
  <div className={classNames(styles.separator, className)}>
    <p />
    <span>{children}</span>
    <p />
  </div>
)

Separator.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Separator.defaultProps = {
  className: '',
}

const isSeparatorEqual = (props, nextProps) =>
  props.children.props.id === nextProps.children.props.id

export default React.memo(Separator, isSeparatorEqual)
