import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div<{ iconSize?: string }>`
  display: flex;
  text-align: center;
  align-items: center;
  svg {
    font-size: ${(props) => props.iconSize};
  }
`

export const Icon = styled(FontAwesomeIcon)`
  height: 1.5rem;
  min-width: 1.5rem;
  margin-right: 1rem;
  color: ${color('gray700')};
`

export const Text = styled.p<{ uppercase: boolean; fontSize?: string }>`
  text-transform: ${(props) => props.uppercase && 'uppercase'};
  margin: auto;
  color: ${(props) => props.color || color('darkGray')(props)};
  font-size: ${(props) => props.fontSize || '1.6rem'};
  margin-left: 0;
`
