import { useQuery, useQueryClient } from '@tanstack/react-query'

import ContentService from 'api/ContentService'
import EmailContentService from 'api/EmailContentService'
import { contentsKeys } from 'utils/queries/contents'
import { holdingsKeys } from 'utils/queries/holdings'
import { portfolioKeys } from 'utils/queries/portfolios'

export const useFileContentQuery = (fileContentId: string) => {
  const queryClient = useQueryClient()
  return useQuery(
    contentsKeys.fileContentById(fileContentId),
    async () => {
      return ContentService.getFileContentById(fileContentId)
    },
    {
      onSuccess: (data) => {
        const ids = data.loggingUpdates.map(
          (loggingUpdate) => loggingUpdate.subjectMatterInfo?.id as string
        )
        queryClient.invalidateQueries(
          holdingsKeys.all({
            idIn: ids,
          })
        )
        queryClient.invalidateQueries(
          portfolioKeys.all({
            idIn: ids,
          })
        )
      },
      enabled: !!fileContentId,
    }
  )
}

export const useEmailContentQuery = (
  emailContentId: string,
  queryOptions = {}
) => {
  const queryClient = useQueryClient()

  return useQuery(
    contentsKeys.emailContentById(emailContentId),
    async () => {
      return EmailContentService.getContentById(emailContentId)
    },
    {
      onSuccess: (data) => {
        const ids = data.loggingUpdates.map(
          (loggingUpdate) => loggingUpdate.subjectMatterInfo?.id as string
        )
        queryClient.invalidateQueries(
          holdingsKeys.all({
            idIn: ids,
          })
        )
        queryClient.invalidateQueries(
          portfolioKeys.all({
            idIn: ids,
          })
        )
      },
      ...queryOptions,
    }
  )
}
