import styled from 'styled-components/macro'
import { maxSize } from 'utils/constants/breakpoint'

export const MultipleCardContainer = styled.div`
  display: grid;
  gap: 2.4rem;
  grid-template-columns: 6fr 2fr;

  @media ${maxSize.md} {
    display: flex;
    flex-direction: column;
  }
`

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
