export const QUERIES = {
  GET_SUBSCRIPTION_DATA: 'get_subscription_data',
  GET_CUSTOMER_DATA: 'get_customer_data',
} as const

export const subscriptionsKeys = {
  getSubscriptionData: (subscriptionId: string) =>
    [QUERIES.GET_SUBSCRIPTION_DATA, subscriptionId] as const,
  getCustomerData: (customerId: string) =>
    [QUERIES.GET_CUSTOMER_DATA, customerId] as const,
}
