export const updateServiceWorker = (serviceWorker) => {
  const registrationWaiting = serviceWorker?.registration?.waiting
  if (registrationWaiting) {
    registrationWaiting.postMessage({ type: 'SKIP_WAITING' })
    registrationWaiting.addEventListener('statechange', (e) => {
      if (e.target.state === 'activated') {
        window.location.reload(true)
      }
    })
  } else {
    window.location.reload(true)
  }
}
