import type { CellTemplate, Compatible, Uncertain } from '@silevis/reactgrid'
import React from 'react'
import { RowNumberCell } from '../../types'
import { RowNumberCellContainer } from '../Template.styles'
import RowNumberCellError from './RowNumberCellError'
import { RowErrorType } from './components/RowErrorIcon'

export class RowNumberCellTemplate implements CellTemplate<RowNumberCell> {
  // eslint-disable-next-line class-methods-use-this
  getCompatibleCell(
    uncertainCell: Uncertain<RowNumberCell>
  ): Compatible<RowNumberCell> {
    const { rowNumber: uncertainRowNumber } = uncertainCell
    const rowNumber = uncertainRowNumber || 0

    return {
      ...uncertainCell,
      rowNumber,
      rowIndex: uncertainCell.rowIndex || 0,
      errors: uncertainCell.errors || new Set(),
      warnings: uncertainCell.warnings || new Set(),
      value: rowNumber,
      text: `${rowNumber}`,
    }
  }

  render(
    cell: Compatible<RowNumberCell>,
    _isInEditMode: boolean,
    _onCellChanged: (cell: Compatible<RowNumberCell>, commit: boolean) => void
  ): React.ReactNode {
    if (cell.errors.size || cell.warnings.size) {
      return (
        <RowNumberCellError
          type={cell.errors.size ? RowErrorType.ERROR : RowErrorType.WARNING}
          right="1.2rem"
        />
      )
    }

    return (
      <RowNumberCellContainer>
        {cell.isHeader ? '#' : cell.rowNumber}
      </RowNumberCellContainer>
    )
  }
}
