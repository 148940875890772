import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { GroupTypes } from 'utils/constants/groups'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import {
  getCurrentGroupData,
  getCurrentGroupType,
  getUser,
} from 'selectors/auth'

import UserService from 'api/UserService'
import Toast from 'components/Toast'
import {
  ItemTitle,
  ItemWrapper,
  MsgBubble,
  SideBarItemContainer,
  SidebarItemIcon,
  SidebarItemTitle,
} from '../SideBarItem/SideBarItem.styles'

type Props = {
  toggleSidebar: () => void
}

const IntercomSidebarButton = ({ toggleSidebar }: Props) => {
  const [msgCount, setMsgCount] = useState(0)
  const user = useAppSelector(getUser)
  const currentGroup = useAppSelector(getCurrentGroupData)
  const currentGroupType = useAppSelector(getCurrentGroupType)
  const intl = useIntl()

  const setupIntercom = useCallback(async () => {
    try {
      const {
        data: { intercomKey },
      } = await UserService.getIntercomIndentityHash(user.id)

      if (user && currentGroup) {
        window.Intercom('shutdown')
        window.Intercom('boot', {
          email: user.email,
          name: user.name,
          user_id: user.id,
          hide_default_launcher: true,
          user_hash: intercomKey,
          company: {
            company_id: currentGroup.id,
            name: currentGroup.name,
            plan: currentGroupType === GroupTypes.CLIENT ? 'Client' : 'Founder',
          },
        })
      } else {
        window.Intercom('shutdown')
      }

      window.Intercom('onUnreadCountChange', (count) => {
        setMsgCount(count)
        if (count > 0) {
          window.Intercom('showMessages')
        }
      })
    } catch (error) {
      Toast.display(error.message, 'error')
    }
  }, [currentGroup, currentGroupType, user])

  useEffect(() => {
    if (user) {
      setupIntercom()
    }
  }, [currentGroup, setupIntercom, user])

  return (
    <SideBarItemContainer
      id="intercom-custom-launcher"
      onClick={toggleSidebar}
      as="button"
    >
      <ItemWrapper>
        <ItemTitle>
          <SidebarItemIcon icon={['fal', 'question-circle']} />
          <SidebarItemTitle>
            {intl.formatMessage({ id: 'sidebar.needHelp' })}
          </SidebarItemTitle>
        </ItemTitle>
      </ItemWrapper>
      {!!msgCount && <MsgBubble>{msgCount}</MsgBubble>}
    </SideBarItemContainer>
  )
}
IntercomSidebarButton.defaultProps = {}

export default IntercomSidebarButton
