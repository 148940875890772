import { useCallback, useState } from 'react'
import { SortDirection } from 'utils/constants/sortDirection'
import { SortDirectionType } from 'utils/types/common'

export type SortByCallback = ({
  sortId,
  sortDirection,
  sortBy,
}: {
  sortId: string
  sortDirection: SortDirectionType
  sortBy?: any
}) => void

export interface UseTableSortingParams {
  initialSortId: string
  initialSortDirection: SortDirectionType
  onSort: SortByCallback
}

export interface UseTableSortingState {
  sortBy: string
  sortDirection: 'asc' | 'desc'
  onChangeSortBy: (sortId: any, getSortBy: any) => void
}
export const useTableSorting = ({
  initialSortId,
  initialSortDirection,
  onSort,
}: UseTableSortingParams): UseTableSortingState => {
  const [sortBy, setSortBy] = useState(initialSortId)
  const [sortDirection, setSortDirection] = useState(
    initialSortDirection || SortDirection.ASC
  )

  const onChangeSortBy = useCallback(
    (sortId: string, getSortBy?: any) => {
      let newSortDirection
      if (sortId === sortBy) {
        newSortDirection =
          sortDirection === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC
      } else {
        newSortDirection = SortDirection.ASC
      }

      setSortBy(sortId)
      setSortDirection(newSortDirection)
      onSort({
        sortId,
        sortBy: getSortBy,
        sortDirection: newSortDirection,
      })
    },
    [onSort, sortBy, sortDirection]
  )

  return {
    sortBy,
    sortDirection,
    onChangeSortBy,
  }
}
