import { FormattedMessage } from 'react-intl'
import { Button } from '../../UpdateHeader.styles'
import usePublishUpdate from './usePublishUpdate'

interface PublishButtonProps {
  isSubmitting: boolean
  enabled: boolean
  handleSubmit: () => void
}

const testId = 'publish-button'

const PublishButton = ({
  isSubmitting,
  enabled,
  handleSubmit,
}: PublishButtonProps) => {
  const { isScheduled } = usePublishUpdate()

  return (
    <Button
      primary
      disabled={isSubmitting || !enabled}
      isLoading={isSubmitting}
      onClick={handleSubmit}
      data-testid={testId}
    >
      <FormattedMessage
        id={isScheduled ? 'buttons.schedule' : 'buttons.publish'}
      />
    </Button>
  )
}

export default PublishButton
