import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`

export const SkeletonAllMetricsCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 2.4rem;

  @media ${maxSize.sm} {
    gap: 2rem;
    flex-direction: column;
  }
`
