import { DrawerTitle } from 'components/Drawer/Drawer.styles'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const FiltersDrawerTitle = styled(DrawerTitle)`
  padding-left: 1rem;
`

export const Actions = styled.div`
  display: flex;
  justify-content: end;
  gap: 0.8rem;
`

export const DrawerContent = styled.div``

export const Tag = styled.li<{ selected?: boolean }>`
  background-color: ${color('white')};
  border-radius: 0.8rem;
  border: 1px solid ${color('veryLightBlue')};
  color: ${color('darkBlue')};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  height: fit-content;
  list-style-type: none;
  padding: 0.6rem 1.2rem;
  white-space: nowrap;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${color('primaryBlue')};
      color: ${color('white')};
    `}
`

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`

export const CheckboxWrapper = styled.div`
  margin-bottom: 1rem;
`

export const Industries = styled.div<{ show?: boolean }>`
  padding-left: 1.6rem;
  display: ${(props) => (props.show ? 'block' : 'none')};
`

export const Industry = styled.div`
  margin-bottom: 1rem;
`

export const AppliedFilterTitle = css`
  color: ${color('primaryBlue')};
`
