import { FormattedMessage } from 'react-intl'
import { ZeroStateType } from 'components/ZeroStateV2'
import { useChatTitle } from 'containers/Chat/hooks/useChatTitle'
import { StreamChatType } from 'containers/Chat/types'
import { useChannelStateContext } from 'stream-chat-react'
import { Text, Title } from '../ZeroStates.styles'
import { Container, ZeroState } from './EmptyStateIndicator.styles'

const EmptyStateIndicator = () => {
  const { channel } = useChannelStateContext<StreamChatType>()
  const chatTitle = useChatTitle(channel)

  return (
    <Container>
      <ZeroState type={ZeroStateType.NO_MESSAGES} hideText hideTitle />
      <Title>
        <FormattedMessage
          id="chat.chatWith"
          values={{
            names: chatTitle,
          }}
        />
      </Title>
      <Text>
        <FormattedMessage id="chat.noMessagesYet" />
      </Text>
    </Container>
  )
}

export default EmptyStateIndicator
