import classNames from 'classnames'
import { PaddedStyledLayout } from './PaddedLayout.styles'

interface Props {
  className?: string
  children: React.ReactNode
  customPadding?: string
}

const PaddedLayout = ({ className = '', customPadding, children }: Props) => (
  <PaddedStyledLayout
    id="padded-layout"
    customPadding={customPadding}
    className={classNames(className)}
  >
    {children}
  </PaddedStyledLayout>
)

export default PaddedLayout
