import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledNav = styled.div`
  display: grid;
  grid-template-columns: 23.6rem 2fr;
`

export const StyledAside = styled.aside`
  grid-column: '1';
  ul {
    max-height: 68vh;
    overflow: auto;
  }
`

export const StyledAsideItem = styled.li`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${color('darkGray')};
  border-bottom: 0.1rem solid #dee6ee;

  svg {
    font-size: 1.1em;
  }

  svg[data-icon='triangle-exclamation'] {
    color: ${color('red')};
  }

  button {
    font-weight: 700;
    width: 100%;
    padding: 1.3rem 2.4rem;
    display: flex;
    justify-content: space-between;
    text-align: left;
    &:focus {
      outline: none;
    }
    ${(props) =>
      props.active &&
      css`
        outline: none;
        color: white;
        background: ${color('primaryBlue')};

        svg {
          fill: white;
        }
      `}

    &:hover {
      ${(props) =>
        !props.active &&
        css`
          background: ${color('veryLightBlue', 0.2)};
          color: ${color('primaryBlue')};
        `}
    }
  }
`

export const StyledNavContent = styled.section`
  grid-column-start: 2;
  grid-column-end: 4;
  min-height: 40rem;
  border-left: 0.1rem solid #dee6ee;
  width: 60rem;
`
