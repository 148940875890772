import { matchPath } from 'react-router-dom'
import type {
  Channel,
  ChannelOptions,
  ChannelSort,
  StreamChat,
} from 'stream-chat'
import type { IntlShape } from 'react-intl'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { PlainSubjectMatters, UpdateTypeForUrl } from 'utils/constants/updates'
import { getPortfolioTypeFromUrlType } from 'utils/functions/portfolios'
import { assertUnreachable, formatStringList } from 'utils/functions/utils'
import { SHOW_NEW_INVESTMENTS_FEATURE } from 'utils/constants/features'
import {
  TopicEntityType,
  isCompanyHoldingTopic,
  isHoldingTopic,
  isPortfolioTopic,
} from 'utils/types/chatTopicEntity'
import { Nullable } from 'utils/types/common'
import { PortfolioType } from 'utils/types/portfolios'
import {
  AttachmentPaths,
  ChannelData,
  ChannelType,
  MessageAttachmentType,
  Pin,
  StreamChatType,
  TopicChannel,
  isTopicChannel,
} from './types'

export const getPinById = (pinId: string, pins: Pin[]) =>
  pins.find((pin) => pin.id === pinId)

export const getPinId = (channel: Channel<StreamChatType>) =>
  isTopicChannel(channel.data) ? channel.data.entityId : channel.id

export const sortChannelsByPinned = (
  channels: Channel<StreamChatType>[],
  pinnedChannels: Pin[]
) => {
  const sortedPinnedChannels: {
    channel: Channel<StreamChatType>
    pinnedAt: string
  }[] = []
  const unpinnedChannels: Channel<StreamChatType>[] = []

  channels.forEach((channel) => {
    const pinId = getPinId(channel)
    const pin = getPinById(pinId!, pinnedChannels)

    if (pin) {
      // insert sorted into sortedPinnedChannels by pin.pinnedAt
      const index = sortedPinnedChannels.findIndex(
        (sortedChannel) =>
          new Date(sortedChannel.pinnedAt!).getTime() <
          new Date(pin.pinnedAt).getTime()
      )

      if (index === -1) {
        sortedPinnedChannels.push({ channel, pinnedAt: pin.pinnedAt })
      } else {
        sortedPinnedChannels.splice(index, 0, {
          channel,
          pinnedAt: pin.pinnedAt,
        })
      }
    } else {
      unpinnedChannels.push(channel)
    }
  })

  return [
    ...sortedPinnedChannels.map((pin) => pin.channel),
    ...unpinnedChannels,
  ]
}

export const getTopicTitle = (
  channel?: Channel<StreamChatType>,
  userId?: string
) => {
  if (!channel) return ''
  const channelMembers = Object.values(channel.state.members)
  return isTopicChannel(channel.data)
    ? channel.data.topicName
    : channelMembers
        .filter(
          (member) =>
            channelMembers.length === 1 || !userId || member.user?.id !== userId
        )
        .map((member) => member.user?.name || '')
        .join(', ')
}

export const getChatTitle = <ChannelType extends ChannelData>(
  intl: IntlShape,
  channel?: Channel<StreamChatType<ChannelType>>,
  userId?: string
) => {
  if (!channel) return ''
  const channelMembers = Object.values(channel.state.members)

  return isTopicChannel(channel.data) && channel.data.name
    ? channel.data.name
    : formatStringList(
        channelMembers
          .filter(
            (member) =>
              channelMembers.length === 1 ||
              !userId ||
              member.user?.id !== userId
          )
          .map((member) => member.user?.name || ''),
        null,
        intl
      )
}

export const isLinkToApp = (link: string) => {
  const { hostname } = window.location

  if (hostname.includes('localhost')) {
    return link.includes(process.env.REACT_APP_APP_DOMAIN!)
  }

  return link.includes(hostname)
}

export type ParsedAttachmentLink = {
  attachmentType: MessageAttachmentType
  matchedPath: AttachmentPaths
  companyId?: string
  fundId?: string
  portfolioId?: string
  portfolioType?: PortfolioType
  updateId?: string
  urlUpdateType?: UpdateTypeForUrl
}

const getMessageAttachmentType = (path: AttachmentPaths) => {
  switch (path) {
    case AttachmentPaths.COMPANY:
    case AttachmentPaths.FUND:
      return MessageAttachmentType.HOLDING
    case AttachmentPaths.UPDATE:
    case AttachmentPaths.COMPANY_UPDATE:
    case AttachmentPaths.FUND_UPDATE:
    case AttachmentPaths.PORTFOLIO_UPDATE:
    case AttachmentPaths.INVESTMENTS_PORTFOLIO_UPDATE:
      return MessageAttachmentType.UPDATE
    case AttachmentPaths.PORTFOLIO:
    case AttachmentPaths.FUND_PORTFOLIO_TAB_INVESTORS:
    case AttachmentPaths.FUND_PORTFOLIO_TAB_PORTFOLIO:
    case AttachmentPaths.INVESTMENTS_PORTFOLIO:
    case AttachmentPaths.INVESTMENTS_FUND_PORTFOLIO_TAB_INVESTORS:
    case AttachmentPaths.INVESTMENTS_FUND_PORTFOLIO_TAB_PORTFOLIO:
      return MessageAttachmentType.PORTFOLIO

    default:
      throw assertUnreachable(path)
  }
}

export const parseAttachmentLink = (
  link: string
): Nullable<ParsedAttachmentLink> => {
  if (isLinkToApp(link)) {
    const url = new URL(link)
    const matchesUpdateUrl = matchPath<{
      updateId: string
      companyId?: string
      fundId?: string
      portfolioId?: string
      portfolioType?: string
      updateType?: UpdateTypeForUrl
    }>(url.pathname, {
      path: [
        AttachmentPaths.FUND,
        AttachmentPaths.COMPANY,
        AttachmentPaths.UPDATE,
        AttachmentPaths.PORTFOLIO,
        AttachmentPaths.FUND_PORTFOLIO_TAB_PORTFOLIO,
        AttachmentPaths.FUND_PORTFOLIO_TAB_INVESTORS,
        AttachmentPaths.COMPANY_UPDATE,
        AttachmentPaths.FUND_UPDATE,
        AttachmentPaths.PORTFOLIO_UPDATE,
        AttachmentPaths.INVESTMENTS_PORTFOLIO,
        AttachmentPaths.INVESTMENTS_FUND_PORTFOLIO_TAB_PORTFOLIO,
        AttachmentPaths.INVESTMENTS_FUND_PORTFOLIO_TAB_INVESTORS,
        AttachmentPaths.INVESTMENTS_PORTFOLIO_UPDATE,
      ],
      exact: true,
    })

    const companyId = matchesUpdateUrl?.params.companyId
    const portfolioId = matchesUpdateUrl?.params.portfolioId
    const portfolioType = matchesUpdateUrl?.params.portfolioType
    const fundId = matchesUpdateUrl?.params.fundId
    const updateId = matchesUpdateUrl?.params.updateId

    const attachmentPath = matchesUpdateUrl?.path as AttachmentPaths

    return matchesUpdateUrl
      ? {
          attachmentType: getMessageAttachmentType(attachmentPath),
          matchedPath: attachmentPath,
          updateId,
          companyId,
          portfolioId,
          portfolioType: portfolioType
            ? getPortfolioTypeFromUrlType(portfolioType)
            : undefined,
          fundId,
          urlUpdateType: matchesUpdateUrl?.params.updateType,
        }
      : null
  }

  return null
}

export const getTopicUrl = (
  channel: Channel<StreamChatType<TopicChannel>>,
  isMyOrganization: boolean
) => {
  const { entityType, entityId } = channel.data!
  const baseRoute = SHOW_NEW_INVESTMENTS_FEATURE
    ? '/investments'
    : '/portfolios'

  switch (entityType) {
    case TopicEntityType.ORGANIZATION:
      if (isMyOrganization) {
        return `/group-profile/updates`
      }
      return `/companies/${entityId}`
    case TopicEntityType.COMPANY_HOLDING:
      return `/companies/${entityId}`
    case TopicEntityType.FUND_HOLDING:
      return `/funds/${entityId}`
    case TopicEntityType.INVEST_PORTFOLIO:
      return `${baseRoute}/invest/${entityId}/portfolio`
    case TopicEntityType.FUND_PORTFOLIO:
      return `${baseRoute}/fund/${entityId}/portfolio`
    case TopicEntityType.DEAL_PORTFOLIO:
      return `${baseRoute}/deal/${entityId}/portfolio`
    case TopicEntityType.TRACK_PORTFOLIO:
      return `${baseRoute}/track/${entityId}/portfolio`
    default:
      throw assertUnreachable(entityType)
  }
}

export const getChannelTypeFromTopicEntityType = (
  entityType: TopicEntityType
) => {
  if (isPortfolioTopic(entityType)) {
    return ChannelType.PORTFOLIO
  }

  if (isHoldingTopic(entityType)) {
    return ChannelType.HOLDING
  }

  return ChannelType.DIRECT_MESSAGE
}
export const getSubjectMatterFromTopicEntityType = (
  entityType: TopicEntityType
) => {
  if (isPortfolioTopic(entityType)) {
    if (entityType === TopicEntityType.INVEST_PORTFOLIO) {
      return PlainSubjectMatters.INVEST_PORTFOLIO
    }
    if (entityType === TopicEntityType.TRACK_PORTFOLIO) {
      return PlainSubjectMatters.TRACK_PORTFOLIO
    }
    return PlainSubjectMatters.FUND_PORTFOLIO
  }

  if (isHoldingTopic(entityType)) {
    if (isCompanyHoldingTopic(entityType)) {
      return PlainSubjectMatters.COMPANY
    }
    return PlainSubjectMatters.FUND_PORTFOLIO
  }

  throw assertUnreachable(entityType as never)
}

export const getPortfolioTypeFromTopicEntityType = (
  entityType: TopicEntityType
): PortfolioType => {
  switch (entityType) {
    case TopicEntityType.INVEST_PORTFOLIO:
      return PortfolioTypes.INVEST
    case TopicEntityType.FUND_PORTFOLIO:
      return PortfolioTypes.FUND
    case TopicEntityType.TRACK_PORTFOLIO:
      return PortfolioTypes.TRACK
    case TopicEntityType.DEAL_PORTFOLIO:
      return PortfolioTypes.DEAL
    default:
      throw assertUnreachable(entityType as never)
  }
}

export const findChannelWithSameMembers = async ({
  membersIds,
  client,
  entityId,
  updateId,
  channelType = ChannelType.DIRECT_MESSAGE,
  name,
}: {
  membersIds: string[]
  userId: string
  client: StreamChat<StreamChatType>
  entityId?: string
  updateId?: string
  channelType?: ChannelType
  name?: string
}) => {
  const filters = {
    entityId,
    updateId,
    type: channelType,
    members: { $eq: [...membersIds, client.userID!].sort() },
    name: undefined as string | undefined,
  }

  if (channelType && channelType !== ChannelType.DIRECT_MESSAGE) {
    filters.name = name
  }

  const extraFilters: [ChannelSort, ChannelOptions] = [
    { created_at: -1 },
    {
      limit: 1,
      offset: 0,
    },
  ]

  const visibleChannels = await client.queryChannels(
    {
      ...filters,
      hidden: false,
    },
    ...extraFilters
  )

  if (visibleChannels?.length) {
    return visibleChannels[0]
  }

  const hiddenChannels = await client.queryChannels(
    {
      ...filters,
      hidden: true,
    },
    ...extraFilters
  )

  if (hiddenChannels?.length) {
    return hiddenChannels[0]
  }

  return null
}

export const getChannelType = (channelData?: TopicChannel) => {
  if (!channelData?.entityType) {
    return ChannelType.DIRECT_MESSAGE
  }

  if (isPortfolioTopic(channelData?.entityType)) {
    return ChannelType.PORTFOLIO
  }

  if (isHoldingTopic(channelData?.entityType)) {
    return ChannelType.HOLDING
  }

  return ChannelType.DIRECT_MESSAGE
}
