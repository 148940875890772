import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import type { AscDesc } from 'stream-chat'
import { ChannelList, InfiniteScroll } from 'stream-chat-react'

import Card from 'components/Card'
import { StreamChatType, TopicChannel } from 'containers/Chat/types'
import Button from 'ui/Button'
import { TopicEntity } from 'utils/types/chatTopicEntity'

import CreateChatModal from '../../CreateChat'
import EmptyComponent from '../../EmptyComponent'
import ChatLabel from '../components/ChatLabel'
import NoChats from '../components/NoChats'
import WidgetErrorHandler from '../WidgetErrorHandler'
import PreviewChannel from '../components/PreviewChannel'
import { useChatWidget } from '../useChatWidget'
import { CardBody, CardHeader, HeaderContent } from './ProfileChatWidget.styles'

interface ProfileChatWidgetProps {
  topic?: TopicEntity
}

const options = { state: true, presence: true, limit: 10 }
const sort = { last_message_at: -1 as AscDesc }

const ProfileChatWidget = ({ topic }: ProfileChatWidgetProps) => {
  const [showCreateChatModal, setShowCreateChatModal] = useState(false)

  const { filters, renderChannels, openChatAsPopup } = useChatWidget(topic)

  return (
    <>
      <Card isExpandable padding="1.4rem 0">
        <CardHeader padding="0 2.4rem">
          <HeaderContent>
            <ChatLabel topic={topic} />
            <Button onClick={() => setShowCreateChatModal(true)} link add>
              <FormattedMessage id="chat.newChat" />
            </Button>
          </HeaderContent>
        </CardHeader>
        <CardBody>
          <ChannelList<StreamChatType<TopicChannel>>
            renderChannels={renderChannels}
            filters={filters}
            sort={sort}
            options={options}
            EmptyStateIndicator={NoChats}
            LoadingIndicator={EmptyComponent}
            Preview={PreviewChannel}
            Paginator={InfiniteScroll}
          />
        </CardBody>
      </Card>
      {topic && (
        <CreateChatModal
          preventNavigationOnCreate
          show={showCreateChatModal}
          onHide={() => setShowCreateChatModal(false)}
          onChatCreated={openChatAsPopup}
          initialValues={{
            members: [],
            topic,
          }}
        />
      )}
    </>
  )
}

export default ({ topic }: ProfileChatWidgetProps) => (
  <WidgetErrorHandler>
    <ProfileChatWidget topic={topic} />
  </WidgetErrorHandler>
)
