import React from 'react'
import type { Compatible, UncertainCompatible } from '@silevis/reactgrid'
import { TextCellTemplate } from '@silevis/reactgrid'
import { CustomTextCell } from '../types'
import { DisabledCell } from './Template.styles'
import TextCellDisplayValue from './components/TextCellDisplayValue'

export class CustomTextCellTemplate extends TextCellTemplate {
  update(
    cell: Compatible<CustomTextCell>,
    cellToMerge: UncertainCompatible<CustomTextCell>
  ): Compatible<CustomTextCell> {
    // eslint-disable-next-line no-param-reassign
    cell.error = ''

    if (cell.validator) {
      const isValid = cell.validator(cellToMerge.text)

      if (!isValid) {
        // eslint-disable-next-line no-param-reassign
        cell.error = cell.errorMessage || ''
      }
    }

    return {
      ...this.getCompatibleCell({
        ...cell,
        text: cellToMerge.text,
        placeholder: cellToMerge.placeholder || cell.placeholder,
      }),
      rowIndex: cell.rowIndex,
    }
  }

  render(
    cell: Compatible<CustomTextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CustomTextCell>, commit: boolean) => void
  ): React.ReactNode {
    if (cell.disabled) {
      return <DisabledCell centered={cell.centered}>-</DisabledCell>
    }

    if (!isInEditMode || cell.autoFilled) {
      return <TextCellDisplayValue cell={cell} />
    }

    return super.render(cell, isInEditMode, onCellChanged)
  }
}
