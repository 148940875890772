import { Nullable } from 'utils/types/common'
import { AccountMergeAttempt, SecondaryEmail, User } from 'utils/types/user'
import axiosClient from './httpClient'

class UserService {
  static getUserByEmail = async (email) => {
    const {
      data: { entities, result },
    } = await axiosClient().get(`/users?email=${encodeURIComponent(email)}`)
    return entities?.users?.[result]
  }

  static getUserById = async (id: string) => {
    const {
      data: { entities, result },
    } = await axiosClient().get(`/users?id=${id}`)
    return entities?.users?.[result]
  }

  static getUserByHandle = async (handle) => {
    const {
      data: { entities, result },
    } = await axiosClient().get(`/users?handle=${handle}`)
    return entities?.users?.[result]
  }

  static createUser = (body) => {
    return axiosClient().post(`/users`, body)
  }

  static bulkCreateUser = async (body): Promise<User[]> => {
    const response = await axiosClient().post('/users/bulk_create', body)
    return response.data?.result?.map(
      (userId) => response.data?.entities?.users?.[userId]
    )
  }

  static updateUser = async (
    id: string,
    body: any,
    formData = false,
    authHeaders: any = {}
  ): Promise<User> => {
    const {
      data: {
        entities: { users },
        result,
      },
    } = await axiosClient(formData, authHeaders).patch(`/users/${id}`, body)
    return users[result]
  }

  static addSecondaryEmail = (email) => {
    return axiosClient().post('/secondary_emails', {
      secondaryEmail: {
        email,
      },
    })
  }

  static getSecondaryEmails = async (): Promise<SecondaryEmail[]> => {
    const {
      data: {
        entities: { secondaryEmails = {} },
      },
    } = await axiosClient().get('/secondary_emails')

    return Object.values(secondaryEmails)
  }

  static setPrimaryEmail = (emailId) => {
    return axiosClient().patch(`/secondary_emails/${emailId}/promote`)
  }

  static removeSecondaryEmail = (emailId) => {
    return axiosClient().delete(`/secondary_emails/${emailId}`)
  }

  static confirmSecondaryEmail = async (
    emailId,
    confirmationToken
  ): Promise<SecondaryEmail> => {
    const {
      data: {
        entities: { secondaryEmails },
        result,
      },
    } = await axiosClient().patch(`/secondary_emails/${emailId}/confirm`, {
      confirmationToken,
    })

    return secondaryEmails[result]
  }

  static getMergeAttempts = async (): Promise<AccountMergeAttempt[]> => {
    const {
      data: {
        entities: { mergeAttempts },
        result,
      },
    } = await axiosClient().get('/merge_attempts')

    return result.map((mergeId) => mergeAttempts[mergeId])
  }

  static removeMergeAttempt = (mergeAttemptId) => {
    return axiosClient().delete(`/merge_attempts/${mergeAttemptId}`)
  }

  static mergeAccounts = async (emailToMerge): Promise<AccountMergeAttempt> => {
    const {
      data: {
        entities: { mergeAttempts },
        result,
      },
    } = await axiosClient().post('/merge_attempts', {
      userToMergeEmail: emailToMerge,
    })
    return mergeAttempts[result]
  }

  static confirmMergeAccounts = (mergeAttemptId, confirmationToken) => {
    return axiosClient().patch(`/merge_attempts/${mergeAttemptId}/confirm`, {
      confirmationToken,
    })
  }

  static disconnectGoogleAccount = ({
    password,
    passwordConfirmation,
    userId,
    otpAttempt = '',
  }) => {
    return axiosClient().patch(`/users/${userId}/unlink_oauth`, {
      user: {
        password,
        passwordConfirmation,
      },
      ...(otpAttempt && { otpAttempt }),
    })
  }

  static changePassword = (
    userId,
    currentPassword,
    newPassword,
    newPasswordConfirmation,
    otpAttempt = ''
  ) => {
    return axiosClient().patch(`/users/${userId}`, {
      currentPassword,
      password: newPassword,
      passwordConfirmation: newPasswordConfirmation,
      ...(otpAttempt && { otpAttempt }),
    })
  }

  static getIntercomIndentityHash = (userId) => {
    return axiosClient().get(`/users/${userId}/intercom`)
  }

  static async getOrCreateUser(email: string): Promise<User> {
    const getUser = async (): Promise<Nullable<User>> => {
      const response: any = await axiosClient().get('/users', {
        params: { email },
      })
      const userId = response.body?.data?.result
      return response.data?.entities?.users?.[userId] ?? null
    }

    let user = await getUser()
    if (!user) {
      const {
        data: { entities, result },
      } = await UserService.createUser({
        email,
      })
      user = entities?.users?.[result] as User
    }

    return user
  }

  static async sendFreeTrialEmail(userId: string): Promise<void> {
    return axiosClient().post(`/users/${userId}/send_free_trial_email`)
  }
}

export default UserService
