import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import ButtonToStyle from 'ui/Button'

export const OptionsWrapper = styled.div`
  position: relative;
`

export const ButtonLabelWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`

export const Button = styled(ButtonToStyle)`
  position: relative;
  display: flex;
  height: fit-content;
  align-items: center;
  line-height: 1;
  padding: 0.6rem 1.2rem;
  border: 0.1rem solid transparent;
  z-index: 5555;

  svg {
    font-size: 1rem;
  }

  &:hover,
  &:active,
  &:focus {
    border: 0.1rem solid ${color('veryLightBlue')};
    outline: none;
  }
`

export const ArrowDownOptionsWrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  border: 0.1rem solid transparent;
  background-color: transparent;
  padding: 0.4rem;
  border-radius: 0.3rem;

  &:hover,
  &:active,
  &:focus {
    border: 0.1rem solid ${color('veryLightBlue')};
    outline: none;
  }
`

export const OptionsContainer = styled.div<{ showOptions: boolean }>`
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  padding: 0.8rem;
  width: 16rem;
  z-index: 9999;
  background-color: ${color('white')};
  top: 100%;
  right: 0;
  display: ${({ showOptions }) => (showOptions ? 'flex' : 'none')};
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
`

export const Option = styled.div<{ fontColor: string }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${({ fontColor }) => fontColor};

  > svg {
    font-size: 1.4rem;
    padding: 0.8rem;
  }
`
