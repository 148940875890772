import React, { useCallback, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'

import { setLastLocation } from 'features/routesSlice'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { UpdateRouteState } from 'utils/hooks/useShowUpdateRoutes'
import { useEventListener } from 'utils/hooks/useEventListener'
import { CLOSE_GROUP_SETTINGS_EVENT } from 'utils/constants/events'
import {
  BriefingContainer,
  ButtonBriefing,
  Expandable,
  GroupManager,
  Handle,
  IconButton,
  Name,
  TextSection,
} from './Briefing.styles'

interface BriefingProps {
  name: string
  logoUrl?: string
  handle: string
  type: 'group' | 'user'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  groupManager?: string
  isButtonEnabled?: boolean
  isMainBriefing?: boolean
  isExpanded?: boolean
  expandableNode?: React.ReactNode
  hideSettings?: boolean
  clickable?: boolean
  isActive?: boolean
  id?: string | number
}

const Briefing: React.FC<BriefingProps> = ({
  name,
  logoUrl,
  handle,
  onClick,
  isMainBriefing,
  isButtonEnabled,
  groupManager,
  isExpanded,
  type = 'group',
  expandableNode = null,
  hideSettings,
  clickable,
  isActive,
  id = -1,
}) => {
  const history = useHistory()
  const location = useLocation<UpdateRouteState>()
  const [locationState, setLocationState] = useState(location.state)
  const route = type === 'group' ? 'group_management' : 'account'
  const isSecondarySidebarActive = useRouteMatch(`/settings/${route}`)
  const [lastLocationState, setLastLocationState] = useState('/')
  const dispatch = useAppDispatch()

  const onGearClick = useCallback(() => {
    if (
      location.pathname.includes('/settings') &&
      !location.pathname.includes(`/settings/${route}`)
    ) {
      history.replace(`/settings/${route}/general`)
    } else if (isSecondarySidebarActive) {
      history.push({ pathname: lastLocationState, state: locationState })
    } else {
      setLastLocationState(location.pathname)
      setLocationState(location.state)
      dispatch(
        setLastLocation({
          state: location.state as string,
          pathname: location.pathname,
        })
      )
      history.push(`/settings/${route}/general`)
    }
  }, [
    location.pathname,
    location.state,
    route,
    isSecondarySidebarActive,
    history,
    lastLocationState,
    dispatch,
    locationState,
  ])

  const closeGroupSettings = useCallback(() => {
    if (isMainBriefing && type === 'group' && isSecondarySidebarActive) {
      history.push({ pathname: lastLocationState, state: locationState })
    }
  }, [
    history,
    isMainBriefing,
    isSecondarySidebarActive,
    lastLocationState,
    locationState,
    type,
  ])

  useEventListener(CLOSE_GROUP_SETTINGS_EVENT, closeGroupSettings)

  const getAvatar = () => {
    if (!logoUrl || logoUrl.indexOf('missing.png') >= 0) {
      return <Avatar initials={getInitials(name)} />
    }
    return <Avatar image={logoUrl} />
  }

  const getSubItem = () => {
    if (groupManager && !isMainBriefing) {
      return <GroupManager title={groupManager}>{groupManager}</GroupManager>
    }

    return <Handle>@{handle}</Handle>
  }

  return (
    <BriefingContainer isMainBriefing={isMainBriefing}>
      {expandableNode}
      <ButtonBriefing
        isMainBriefing={isMainBriefing}
        onClick={onClick}
        type={clickable ? 'button' : undefined}
        as={clickable ? 'button' : 'div'}
        isActive={isActive}
      >
        {getAvatar()}

        {isMainBriefing && isButtonEnabled && (
          <Expandable isExpanded={isExpanded}>
            <FontAwesomeIcon
              icon={['fal', 'angle-down']}
              data-testid="group-selector"
            />
          </Expandable>
        )}
        <TextSection>
          <Name>{name}</Name>
          {getSubItem()}
        </TextSection>
      </ButtonBriefing>
      {isMainBriefing && !hideSettings && (
        <IconButton
          id={`${id}`}
          type="button"
          active={!!isSecondarySidebarActive}
          onClick={onGearClick}
        >
          <FontAwesomeIcon icon={['fal', 'cog']} />
        </IconButton>
      )}
    </BriefingContainer>
  )
}

export default Briefing
