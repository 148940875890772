import { useEffect, useState } from 'react'
import { areSameDates } from 'utils/functions/date'

const DEFAULT_OPACITY = 1
const GRAY_OUT_OPACITY = 0.4

const CustomBar = ({ activeBar, ...props }) => {
  const [isHovered, setIsHovered] = useState(false)
  const opacity = isHovered ? DEFAULT_OPACITY : GRAY_OUT_OPACITY

  useEffect(() => {
    if (activeBar) {
      setIsHovered(areSameDates(activeBar.payload.date, props.payload.date))
    }
  }, [activeBar, props.payload.date, props.payload.quarter, props.payload.year])

  if (Number.isNaN(props.height)) return null

  return (
    <rect
      {...props}
      key={`${props.name}_${props.value}`}
      height={props.height < 0 ? 0 : props.height}
      opacity={activeBar ? opacity : DEFAULT_OPACITY}
      rx={props.radius}
    />
  )
}

export default CustomBar
