import theme from 'utils/theme'
import {
  SkeletonAvatar,
  SkeletonItem,
} from 'components/Skeletons/Skeletons.styles'
import {
  LogContentWrapper,
  LogListWrapper,
  LogWrapper,
  Wrapper,
} from './PortfolioLogsSkeleton.styles'

const PortfolioLogsSkeleton = () => {
  return (
    <Wrapper>
      <SkeletonItem height="1.2rem" width="10rem" />

      <LogListWrapper>
        {[0, 1, 2].map((item) => (
          <LogWrapper key={item} style={{ display: 'flex' }}>
            <SkeletonAvatar
              backgroundColor={theme.colors.defaultSkeletonShine}
              width="3.8rem"
              height="3.8rem"
              marginRight="1.2rem"
            />
            <LogContentWrapper>
              <SkeletonItem height="1.2rem" width="22rem" />
              <SkeletonItem height="1.2rem" width="7rem" />
            </LogContentWrapper>
          </LogWrapper>
        ))}
      </LogListWrapper>
    </Wrapper>
  )
}

export default PortfolioLogsSkeleton
