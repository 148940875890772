import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const MainContentCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: auto;
  @media ${maxSize.tabletLg} {
    gap: 2.4rem;
  }
`

export const NoUpdatesToShow = styled.p`
  color: ${color('lightGray')};
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
  padding: 2rem 0 6rem 0;
`
