import { size } from './breakpoint'

export const MAXIMIZED_CHAT_WIDTH = 401
export const MINIMIZED_CHAT_WIDTH = 300
export const GAP_BETWEEN_CHAT_POPUPS = 12
export const CHAT_LAYOUT_POSITION = 124
export const CHAT_LAYOUT_MOBILE_POSITION = 106
export const CHAT_LAYOUT_PADDING_LEFT = 16
export const CHAT_POPUP_HEADER_HEIGHT = 52

export const ChatBreakpoint = {
  desktop: 1439,
}

export const ChatBreakpointSize = {
  tablet: `${size.lg} and (max-width: ${ChatBreakpoint.desktop}px)`,
}
