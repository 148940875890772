/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'

import { orderArrayByObjectPropertyAndDirection } from 'utils/functions/array'
import useTaggingsByUpdateId from 'utils/hooks/useTaggingsByUpdateId'
import { getCurrentGroupId } from 'selectors/auth'

import EmailService from 'api/EmailService'
import Toast from 'components/Toast'
import useDuplicateEmail from 'utils/hooks/useDuplicateEmail'
import { useShowUpdate } from 'containers/Updates/ShowUpdate/useShowUpdate'
import { EmailUpdate } from 'utils/types/update'

type RecipienstData = {
  data: any
  countDelivered: number
  countOpened: number
  openRate: number
}

const PAGE_SIZE = 500

export const useShowEmail = () => {
  const {
    update,
    isLoading,
    updatePermissions,
    redirectToEditView,
    redirectToReshareView,
    redirectToEditReshareView,
    deleteUpdate,
    resharesByToken,
    resharesWithMeOrMyGroup,
  } = useShowUpdate<EmailUpdate>()

  const [recipientsData, setRecipientsData] = useState<RecipienstData>({
    data: [],
    countDelivered: 0,
    countOpened: 0,
    openRate: 0,
  })

  const [recipientsDataLoading, setRecipientsDataLoading] = useState(true)
  const intl = useIntl()
  const [additionalRecipientsModal, setAdditionalRecipientsModal] =
    useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [hasFetchedRecipients, setHasFetchedRecipients] = useState(false)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const { tagsList } = useTaggingsByUpdateId(update?.id)

  const { duplicateEmail } = useDuplicateEmail(update?.item?.id)

  const currentGroupIsOwnerOfEmail = currentGroupId === update?.groupId

  const getRecipientsData = async () => {
    try {
      if (!update) {
        return
      }
      const data = await EmailService.getEmailRecipientStatus(
        update.item.id,
        PAGE_SIZE
      )
      setRecipientsDataLoading(false)
      setRecipientsData(data)
      setHasFetchedRecipients(true)
    } catch (error) {
      Toast.display(intl.messages['showEmail.errorRecipients'], 'error')
    }
  }

  useEffect(() => {
    if (
      update &&
      currentGroupIsOwnerOfEmail &&
      activeTabIndex === 1 &&
      !hasFetchedRecipients
    ) {
      getRecipientsData()
    }
  }, [activeTabIndex, update, currentGroupIsOwnerOfEmail, hasFetchedRecipients])

  const onChangeSortBy = ({ sortId, sortDirection }) => {
    setRecipientsData((previousRecipientsData) => {
      const sortedData = orderArrayByObjectPropertyAndDirection(
        previousRecipientsData.data,
        sortId,
        sortDirection
      )

      return {
        ...previousRecipientsData,
        data: sortedData,
      }
    })
  }

  const toggleAdditionalRecipientsModal = useCallback(() => {
    setAdditionalRecipientsModal((state) => !state)
  }, [])

  const tabs = useMemo(() => {
    const tabOptions = [
      {
        id: 'general',
        title: intl.formatMessage({ id: 'updates.general' }),
        icon: ['far', 'newspaper'],
      },
    ]
    if (currentGroupIsOwnerOfEmail) {
      tabOptions.push({
        id: 'recipients',
        title: intl.formatMessage({ id: 'updates.recipients' }),
        icon: ['far', 'users'],
      })
    }

    if (updatePermissions.hasEditPermissions) {
      tabOptions.push({
        id: 'analytics',
        title: intl.formatMessage({ id: 'updates.analytics' }),
        icon: ['far', 'chart-line'],
      })
    }
    return tabOptions
  }, [updatePermissions.hasEditPermissions, currentGroupIsOwnerOfEmail, intl])

  return {
    update,
    isLoading,
    deleteUpdate,
    redirectToReshareView,
    redirectToEditReshareView,
    resharesByToken,
    resharesWithMeOrMyGroup,
    recipientsData,
    recipientsDataLoading,
    tagsList,
    updatePermissions,
    onChangeSortBy,
    redirectToEditView,
    additionalRecipientsModal,
    toggleAdditionalRecipientsModal,
    duplicateEmail,
    tabs,
    currentGroupIsOwnerOfEmail,
    setActiveTabIndex,
  }
}
