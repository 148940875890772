import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.2rem;
`

export const Title = styled.h1`
  font-size: 2.4rem;
  line-height: 2.9rem;
  font-weight: 400;
  color: ${color('darkBlue')};
`

export const SubTitle = styled.span`
  font-size: 1.4rem;
  line-height: 1.68rem;
  font-weight: 400;
  color: ${color('darkBlue')};
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const Separator = styled.hr`
  width: calc(100% + 3.2rem + 3.2rem);
  margin: 2.4rem 0 0 -3.2rem;
  border-top: 1px solid ${color('veryLightBlue')};

  @media ${maxSize.lg} {
    width: calc(100% + 2.4rem + 2.4rem);
    margin: 2.4rem 0 0 -2.4rem;
  }
`
