import { useState } from 'react'

const useBulkImportMetricValuesModalHandlers = () => {
  const [metricBulkImportModal, setMetricBulkImportModal] = useState({
    show: false,
    metric: null,
  })

  const onImportCsv = (metricData) =>
    setMetricBulkImportModal({
      show: true,
      metric: metricData,
    })

  const onHideImportCsvModal = () =>
    setMetricBulkImportModal((prev) => ({ ...prev, show: false }))

  return {
    metricBulkImportModal,
    onImportCsv,
    onHideImportCsvModal,
  }
}

export default useBulkImportMetricValuesModalHandlers
