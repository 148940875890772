import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Checkbox from 'components/Checkbox'
import Input from 'ui/Input'
import ImagePicker from 'ui/ImagePicker'
import FundPortfolioInput from './components/FundPortfolioInput/FundPortfolioInput'
import { ImagePickerContainer } from '../AddCompanyForm/AddCompanyForm.styles'
import ProfileSuggestion from '../ProfileSuggestion/ProfileSuggestion'
import * as Styles from './AddFundPortfoliosForm.styles'
import { useAddFundPortfoliosForm } from './useFundPortfoliosForm'

const AddFundPortfoliosForm: React.FC = () => {
  const intl = useIntl()
  const {
    values,
    status,
    handleBlur,
    includeFundManagerField,
    fundManagerNameField,
    fundManagerWebsiteField,
    fundManagerPointOfContactField,
    fundManagerLogoField,
    shouldShowWebsiteErrorMessage,
    onSelectImage,
    onSaveImage,
    onDeleteImage,
    onChangeWebsite,
    onAddFund,
  } = useAddFundPortfoliosForm()

  const renderSuggestionError = () => {
    if (status?.companyErrors?.website)
      return (
        <Styles.SuggestionErrorContainer>
          <ProfileSuggestion
            type="website"
            profiles={[status.companyErrors.website]}
          />
        </Styles.SuggestionErrorContainer>
      )

    if (status?.companyErrors?.isHidden)
      return (
        <Styles.SuggestionErrorContainer>
          <Styles.DefaultError>
            {intl.formatMessage({
              id: 'addHolding.errors.websiteUsed',
            })}
          </Styles.DefaultError>
        </Styles.SuggestionErrorContainer>
      )

    return null
  }

  return (
    <>
      {values.funds?.funds.map((_, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`funds.${index}`}>
            <FundPortfolioInput index={index} />
          </div>
        )
      })}
      <Styles.AddFundButton link icon={['far', 'plus']} onClick={onAddFund}>
        <FormattedMessage id="addHolding.addFund" />
      </Styles.AddFundButton>
      <Checkbox
        id={includeFundManagerField.name}
        name={includeFundManagerField.name}
        onChange={includeFundManagerField.onChange}
        mode="default"
        label={intl.formatMessage({
          id: 'addHolding.includeFundManager',
        })}
        checked={includeFundManagerField.value}
      />
      {includeFundManagerField.value && (
        <Styles.FundManagerInfoContainer>
          <Styles.FundManagerInfo>
            <div>
              <Styles.LogoTitle>
                <FormattedMessage id="addHolding.logo" />
              </Styles.LogoTitle>
              <ImagePickerContainer>
                <ImagePicker
                  imageUrl={fundManagerLogoField.value?.url}
                  onSelectImage={onSelectImage}
                  onSaveImage={onSaveImage}
                  helpHidden
                  showSelectImageButton={false}
                  deleteImageEnabled
                  showRemoveImageButton={false}
                  onDeleteImage={onDeleteImage}
                  gray
                />
              </ImagePickerContainer>
            </div>
            <Styles.InputsWrapper>
              <Input
                id={fundManagerNameField.name}
                name={fundManagerNameField.name}
                value={fundManagerNameField.value}
                label={intl.formatMessage({ id: 'addHolding.fundManagerName' })}
                placeholder={intl.formatMessage({
                  id: 'addHolding.fundManagerName',
                })}
                onChange={fundManagerNameField.onChange}
                error={fundManagerNameField.buildError()}
                onBlur={handleBlur}
              />
              <div>
                <Input
                  id={fundManagerWebsiteField.name}
                  name={fundManagerWebsiteField.name}
                  value={fundManagerWebsiteField.value}
                  label={intl.formatMessage({ id: 'addHolding.website' })}
                  placeholder={intl.formatMessage({
                    id: 'addHolding.website',
                  })}
                  onChange={onChangeWebsite}
                  error={fundManagerWebsiteField.buildError()}
                  showErrorMessage={shouldShowWebsiteErrorMessage}
                  onBlur={handleBlur}
                  isOptional
                />
                {renderSuggestionError()}
              </div>
              <Input
                id={fundManagerPointOfContactField.name}
                name={fundManagerPointOfContactField.name}
                value={fundManagerPointOfContactField.value}
                label={intl.formatMessage({ id: 'addHolding.pointOfContact' })}
                placeholder={intl.formatMessage({
                  id: 'addHolding.pointOfContact',
                })}
                onChange={fundManagerPointOfContactField.onChange}
                error={fundManagerPointOfContactField.buildError()}
                onBlur={handleBlur}
                isOptional
              />
            </Styles.InputsWrapper>
          </Styles.FundManagerInfo>
        </Styles.FundManagerInfoContainer>
      )}
    </>
  )
}

export default AddFundPortfoliosForm
