import React from 'react'
import { ReactChildren } from 'utils/types/common'
import styled, { keyframes } from 'styled-components'
import type { Keyframes } from 'styled-components'

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const FadeInContainer = styled.div<{
  duration?: number
  customKeyframes?: Keyframes
}>`
  animation: ${(props) => `${props.duration || 0.5}s`}
    ${(props) => props.customKeyframes || fadeIn} ease-out;
`

const FadeIn: React.FC<{
  className?: string
  duration?: number
  customKeyframes?: Keyframes
  children: ReactChildren
}> = ({ children, className, duration, customKeyframes }) => {
  return (
    <FadeInContainer
      className={className}
      duration={duration}
      customKeyframes={customKeyframes}
    >
      {children}
    </FadeInContainer>
  )
}

export default FadeIn
