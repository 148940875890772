import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

const Container = styled.div`
  background-color: ${color('purple')};
  border-radius: 1rem;
  color: ${color('white')};
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.03em;
  padding: 0.3rem 0.8rem;
  position: absolute;
  text-transform: uppercase;
  z-index: 2;
  line-height: 1.4rem;
  right: 1.5rem;
  bottom: 1.2rem;
`

interface Props {
  className?: string
}

const UnreconciledPill = ({ className }: Props) => {
  return (
    <Container className={className}>
      <FormattedMessage id="files.details.unreconciled" />
    </Container>
  )
}

export default UnreconciledPill
