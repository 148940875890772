import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import {
  isDesktopApp,
  isMacApp,
  sendNotification,
} from 'utils/functions/electron'
import { randomId } from 'utils/functions/number'

import useSettings from './useSettings'

export const useNotifications = () => {
  const intl = useIntl()
  const { settings } = useSettings()

  const showNotification = useCallback(
    (body: string, onClick: () => void) => {
      const hasNotificationsEnabled = isDesktopApp()
        ? settings?.pushNotifications?.desktop
        : settings?.pushNotifications?.web

      if (hasNotificationsEnabled) {
        const title = intl.formatMessage({ id: 'general.clockworkUniverse' })

        if (isDesktopApp()) {
          sendNotification(randomId(), title, body, onClick)
        } else {
          const notify = () => {
            const notification = new Notification(title, {
              body,
              icon: isMacApp() ? undefined : '/logo192.png',
            })
            notification.onclick = () => {
              onClick()
              window.focus()
              notification.close()
            }
          }

          if (Notification.permission === 'granted') {
            notify()
          } else {
            Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                notify()
              }
            })
          }
        }
      }
    },
    [
      intl,
      settings?.pushNotifications?.desktop,
      settings?.pushNotifications?.web,
    ]
  )

  return {
    showNotification,
  }
}
