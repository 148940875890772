import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { color } from 'utils/functions/colors'

export const StyledWidget = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 5.2rem;
  background: ${color('lightGray')};
  color: ${color('white')};
  display: flex;
  height: fit-content;
  padding: 1rem 1.6rem;
  z-index: 10;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  cursor: pointer;
`

export const StyledIcon = styled(FontAwesomeIcon)`
  align-self: baseline;
`

export const StyledHeader = styled.header<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;

  svg {
    font-size: 1.4rem;
  }

  svg:last-child {
    transition: all 0.2s ease-in-out;
    font-size: 1.8rem;
    opacity: 0.6;
    ${(props) =>
      props.isOpen &&
      css`
        transform: rotate(180deg);
      `}
  }
`

export const StyledTitle = styled.p`
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 1rem 0 0.4rem;
`

export const StyledLegend = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 0.87rem;
  line-height: 1.4rem;
`

export const Wrapper = styled.div`
  display: flex;
  align-self: flex-start;
`
