import React, { useCallback, useEffect } from 'react'
import Input from 'ui/Input'
import { InvestmentVehicleFormValues } from 'utils/types/investors'
import { buildFormError } from 'utils/functions/forms'
import { useIntl } from 'react-intl'
import Separator from 'ui/Separator'
import Checkbox from 'components/Checkbox'
import Title from 'components/Title'
import Dropdown from 'ui/Dropdown'
import FadeIn from 'components/FadeIn/FadeIn'
import InvestorManagementService from 'api/InvestorManagementService'
import { ErrorType } from 'utils/types/common'
import { useFormikContext } from 'components/Form/hooks'
import { InvestorTypes } from 'utils/constants/investorManagement'
import {
  FormContainer,
  FormContent,
} from '../CreateInvestmentVehicle/CreateInvestmentVehicle.styles'
import LocationsList from '../components/LocationsList'
import { InvestmentVehicleErrors } from '../CreateInvestmentVehicle/useCreateInvestmentVehicle'
import FinancialInformationForm from '../InvestorForm/FinancialInformationForm'

interface InvestmentVehicleFormProps {
  onCreateNewInvestor?: (name: string) => void
  isInvestorGroupsSelectorVisible?: boolean
  className?: string
  customError?: InvestmentVehicleErrors
}

const InvestmentVehicleForm: React.FC<InvestmentVehicleFormProps> = ({
  onCreateNewInvestor,
  isInvestorGroupsSelectorVisible = true,
  className = '',
  customError,
}) => {
  const intl = useIntl()

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    getFieldName,
    setFieldError,
  } = useFormikContext<InvestmentVehicleFormValues>()

  const getInvestorCustomErrors = useCallback(() => {
    if (!customError) return

    if (customError.name) {
      setFieldError('name', customError.name)
    }
  }, [customError, setFieldError])

  useEffect(() => {
    getInvestorCustomErrors()
  }, [getInvestorCustomErrors])

  const loadInvestors = useCallback(async (inputSearch) => {
    const { results } = await InvestorManagementService.fetchInvestors({
      page: 1,
      name: inputSearch,
    })
    return results
  }, [])

  return (
    <FormContainer className={className}>
      <FormContent autoComplete="off">
        <Title
          title={intl.formatMessage({
            id: 'investorManagement.vehicleName',
          })}
          subLabel={intl.formatMessage({
            id: 'common.requiredField',
          })}
        />

        <Separator />

        <Input
          name="name"
          placeholder={intl.formatMessage({ id: 'investorManagement.addName' })}
          value={values.name}
          onChange={handleChange}
          error={buildFormError(errors?.name, ErrorType.ERROR, true)}
          onBlur={handleBlur}
        />

        <Separator />

        {isInvestorGroupsSelectorVisible && (
          <>
            <Title
              title={intl.formatMessage({
                id: 'investorManagement.investmentVehicleInvestor',
              })}
              subLabel={intl.formatMessage({
                id: 'common.requiredField',
              })}
            />

            <Separator />

            <Dropdown
              id={getFieldName('investor-selector')}
              name="investor"
              loadOptions={loadInvestors}
              onSelectOption={(_, __, value) => {
                setFieldValue('investor', value)
              }}
              placeholder={intl.formatMessage({
                id: 'investorManagement.selectInvestor',
              })}
              error={
                values.investor?.isPopulated
                  ? buildFormError(
                      intl.formatMessage({
                        id: 'investorManagement.investorWarning',
                      }),
                      ErrorType.WARNING,
                      true
                    )
                  : buildFormError(
                      errors?.investor,
                      ErrorType.ERROR,
                      touched?.investor
                    )
              }
              async
              type="select"
              getOption={(entity) => ({ label: entity.name, id: entity.id })}
              clearOnSelect
              value={values.investor}
              addOptionEnabled={!!onCreateNewInvestor}
              onAddOption={onCreateNewInvestor}
            />
            <Separator />
          </>
        )}

        <Checkbox
          id={getFieldName('shareInvestorData')}
          name={getFieldName('shareInvestorData')}
          label={intl.formatMessage({
            id: 'investorManagement.shareInvestorData',
          })}
          checked={values.shareInvestorData}
          onChange={handleChange}
        />

        {!values.shareInvestorData && (
          <FadeIn>
            <Separator />

            <Title
              title={intl.formatMessage({
                id: 'investorManagement.phone',
              })}
            />

            <Separator />

            <Input
              name="phone"
              type="text"
              placeholder={intl.formatMessage({
                id: 'investorManagement.addPhone',
              })}
              value={values.phone}
              onChange={handleChange}
              error={buildFormError(
                errors?.phone,
                ErrorType.ERROR,
                touched?.phone
              )}
              onBlur={handleBlur}
            />

            <Separator />

            <LocationsList />

            <Separator />

            <Title
              title={intl.formatMessage({
                id: 'investorManagement.financialInfo',
              })}
            />

            <Separator />

            <FinancialInformationForm
              investorType={InvestorTypes.ORGANIZATION}
            />
          </FadeIn>
        )}
      </FormContent>
    </FormContainer>
  )
}

export default InvestmentVehicleForm
