import { color } from 'utils/functions/colors'
import styled, { css } from 'styled-components'
import type { FlattenInterpolation, ThemeProps } from 'styled-components'
import IconButton from 'ui/IconButton'

export const BtnAddToPortfolio = styled(IconButton)<{
  hasPortfolios?: boolean
  btnCustomStyles?: FlattenInterpolation<ThemeProps<any>>
}>`
  align-items: center;
  border-radius: 100%;
  border: 0.1rem solid;
  border-color: ${color('veryLightGray')};
  display: flex;
  font-size: 14px;
  height: 3.8rem;
  justify-content: center;
  width: 3.8rem;
  color: ${color('black')};

  &:hover {
    box-shadow: 0px 0px 15px rgba(16, 21, 39, 0.1);
  }

  ${(props) =>
    props.hasPortfolios &&
    css`
      background-color: ${color('primaryBlue')};
      color: ${color('white')};
      border: none;

      &:hover {
        background-color: ${color('primaryBlue')};
        svg {
          color: ${color('white')};
        }
      }
    `}

  ${(props) => props.btnCustomStyles}
`

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const TooltipPortfolioItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${color('white')};
  margin-bottom: 0.5rem;
  white-space: nowrap;
`

export const TooltipPortfolioItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: ${color('white')};
  margin-right: 1rem;
  width: 2rem;
`

export const TooltipPortfolioItemName = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.6rem;
  color: ${color('white')};
`
