import React, { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Modal from 'components/Modal'
import Avatar from 'components/Avatar'
import Button from 'ui/Button'
import { getInitials } from 'utils/functions/user'
import LogIcon from 'containers/Logs/LogIcon'
import { color } from 'utils/functions/colors'
import { UpdateType } from 'utils/types/common'
import { UpdateTypes } from 'utils/constants/updates'
import Tooltip from 'components/Tooltip'
import { UpdateLogsBuilder } from '../../../models/logs-builders/UpdateLogsBuilder'
import * as Styles from './DescriptionDiffModal.styles'
import { DescriptionDiff } from '../DescriptionDiff/DescriptionDiff'
import { getTextFieldLogMessageId } from '../../UpdateDescriptionLog'

const getModalTitleId = (updateType: UpdateType) => {
  switch (updateType) {
    case UpdateTypes.DOCUMENT:
      return 'logs.updateDescription.additionalMessageModalTitle'
    default:
      return 'logs.updateDescription.descriptionModalTitle'
  }
}

interface DiffModalProps {
  isModalOpen: boolean
  closeModal: () => void
  logBuilder: UpdateLogsBuilder
}

const DiffModal: React.FC<DiffModalProps> = ({
  logBuilder,
  isModalOpen,
  closeModal,
}) => {
  const intl = useIntl()

  const logoUrl = logBuilder.getLogoUrl()
  const shouldDisplayInitials = useRef(
    !logoUrl || logoUrl?.includes?.('missing.png')
  )
  const owner = useRef(logBuilder.getOwner())

  return (
    <Modal show={isModalOpen} onHide={closeModal} hideOnOutsideClick={false}>
      <Styles.Header>
        <Modal.Header onHide={closeModal}>
          <Modal.Title>
            {intl.formatMessage({
              id: getModalTitleId(logBuilder.updateType),
            })}
          </Modal.Title>
        </Modal.Header>
      </Styles.Header>
      <Modal.Body>
        <div>
          <Styles.AvatarContainer>
            <Avatar
              image={logoUrl}
              avatarStyle="avatarCircleLogs"
              initials={
                shouldDisplayInitials.current && getInitials(owner.current)
              }
            />
            <LogIcon color={color('brightBlue')} icon={['far', 'pen']} />
          </Styles.AvatarContainer>
          <Styles.TitleAndMessage>
            <Tooltip
              place="bottom"
              id="update-title-tooltip"
              text={logBuilder.updateTitle}
              delayShow={500}
              topOffset={7}
              widthAuto
            >
              <Styles.Title>{logBuilder.updateTitle}</Styles.Title>
            </Tooltip>
            <div>
              <b>{`${owner.current} `}</b>
              {intl.formatMessage(
                { id: 'logs.updateDescription.log' },
                {
                  link: (
                    <b>
                      {intl.formatMessage({
                        id: getTextFieldLogMessageId(logBuilder.updateType),
                      })}
                    </b>
                  ),
                }
              )}
            </div>
          </Styles.TitleAndMessage>
        </div>
        <Styles.Wrapper>
          <DescriptionDiff
            beforeHtml={logBuilder.log.change[0] as string | undefined}
            afterHtml={logBuilder.log.change[1] as string | undefined}
            afterDate={logBuilder.log.createdAt}
          />
        </Styles.Wrapper>
      </Modal.Body>
      <Modal.Footer>
        <Styles.Footer>
          <Button primary type="button" onClick={closeModal}>
            <FormattedMessage id="general.ok" />
          </Button>
        </Styles.Footer>
      </Modal.Footer>
    </Modal>
  )
}

export default DiffModal
