import dayjs from 'dayjs'
import { useIntl } from 'react-intl'

import AreaChart from 'components/Charts/AreaChart'
import IconButton from 'ui/IconButton'
import {
  AnalyticsChartData,
  AnalyticsDisplayBy,
} from 'utils/constants/analytics'
import Separator from 'ui/Separator'
import Dropdown from 'ui/Dropdown'
import {
  GroupByWrapper,
  TitleContainer,
} from 'containers/Analytics/Analytics.styles'
import Title from 'components/Title'
import {
  AnalyticsChartContainer,
  ChartLegend,
  ChartNavigatorContainer,
  DummyButton,
} from './AnalyticsChart.styles'

import { useAnalyticsChart } from './useAnalyticsChart'

interface AnalyticsChartProps {
  updateId: string
  updateCreationDate: Date
}
const AnalyticsChart: React.FC<AnalyticsChartProps> = ({
  updateId,
  updateCreationDate,
}) => {
  const {
    chartData,
    chartParams,
    onSelectDisplayType,
    onChangePage,
    isNextPageDisabled,
    isPreviousPageDisabled,
    maxValue,
  } = useAnalyticsChart(updateId, updateCreationDate)
  const intl = useIntl()

  const getLegend = (): string => {
    const startDate = dayjs(chartParams.startDate)

    if (chartParams.displayType === AnalyticsDisplayBy.DAY) {
      if (startDate.isToday()) {
        return intl.formatMessage({ id: 'analytics.today' })
      }
      if (startDate.isYesterday()) {
        return intl.formatMessage({ id: 'analytics.yesterday' })
      }
      return startDate.format('ddd DD YYYY')
    }

    if (chartParams.displayType === AnalyticsDisplayBy.MONTH) {
      return startDate.format('YYYY')
    }

    if (chartParams.displayType === AnalyticsDisplayBy.WEEK) {
      return `${startDate.format('DD MMM YYYY')} - ${dayjs(
        chartParams.endDate
      ).format('DD MMM YYYY')}`
    }

    return ''
  }

  const formatTooltipX = (chartDataPoint: AnalyticsChartData): string => {
    if (chartParams.displayType === AnalyticsDisplayBy.DAY) {
      return dayjs(chartDataPoint.plainX).format('DD MMM YYYY, hh:mm A')
    }
    if (chartParams.displayType === AnalyticsDisplayBy.WEEK) {
      return dayjs(chartDataPoint.plainX).format('dddd DD MMM YYYY')
    }

    return dayjs(chartDataPoint.plainX).format('MMM YYYY')
  }

  const groupByOptions = [
    {
      id: AnalyticsDisplayBy.DAY,
      label: 'By Day',
    },
    {
      id: AnalyticsDisplayBy.WEEK,
      label: 'By Week',
    },
    {
      id: AnalyticsDisplayBy.MONTH,
      label: 'By Month',
    },
  ]

  if (!chartData.length) {
    return null
  }

  return (
    <>
      <TitleContainer>
        <Title title="Update Views" icon={['far', 'eye']} />
        <GroupByWrapper>
          <Dropdown
            name="displayAnalytics"
            id="display-analytics"
            options={groupByOptions}
            getOption={(option) => option}
            onSelectOption={(_, __, selectedOption) =>
              onSelectDisplayType(selectedOption.id)
            }
            type="select"
            iconFontSize="2.3rem"
            minWidth="3.7rem"
            value={chartParams.displayType}
          />
        </GroupByWrapper>
      </TitleContainer>
      <Separator space="1rem" />
      <AnalyticsChartContainer data-testid="analytics-chart">
        <AreaChart
          data={chartData}
          maxLegendItems={12}
          stroke="#4E74DA"
          fill="url(#gradientFill)"
          height={234}
          dot={{ stroke: '#4E74DA', fill: 'white' }}
          axisItemWidth="3rem"
          customAxis={false}
          formatTooltipX={formatTooltipX}
          tooltipWidth="auto"
          tooltipHeight="auto"
          allowYDecimals={false}
          xAxisPadding={{ left: 30, right: 30 }}
          connectNulls
          yDomain={maxValue !== undefined ? [0, () => maxValue] : undefined}
        >
          <defs>
            <linearGradient id="gradientFill" x1="0" y1="0" x2="0" y2="1">
              <stop offset="53.65%" stopColor="#4E74DA" stopOpacity={0.1} />
              <stop offset="100%" stopColor="#BCCAF0" stopOpacity={0} />
            </linearGradient>
          </defs>
        </AreaChart>

        <ChartNavigatorContainer>
          {isPreviousPageDisabled ? (
            <DummyButton />
          ) : (
            <IconButton
              icon="chevron-left"
              aria-label="previous-page"
              onClick={onChangePage(-1)}
            />
          )}
          <ChartLegend>{getLegend()}</ChartLegend>
          {isNextPageDisabled ? (
            <DummyButton />
          ) : (
            <IconButton icon="chevron-right" onClick={onChangePage(1)} />
          )}
        </ChartNavigatorContainer>
      </AnalyticsChartContainer>
      <Separator space="3rem" />
    </>
  )
}

export default AnalyticsChart
