import { useRef, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import type {
  MutationFunction,
  MutationKey,
  UseMutationOptions,
} from '@tanstack/react-query'

interface DebounceOptions<TData, TError, TVariables, TContext>
  extends UseMutationOptions<TData, TError, TVariables, TContext> {
  wait?: number
}

type Timer = ReturnType<typeof setTimeout> | undefined

export const useDebouncedMutation = <
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationKey: MutationKey,
  mutationFn?: MutationFunction<TData, TVariables>,
  options?: UseMutationOptions<TData, TError, TVariables, TContext>
) => {
  const mutation = useMutation(mutationKey, mutationFn, options)
  const [isDebouncing, setIsDebouncing] = useState(false)
  const timerRef = useRef<Timer>(undefined)

  const debouncedMutate = (
    variables: TVariables,
    {
      wait,
      ...moreOptions
    }: DebounceOptions<TData, TError, TVariables, TContext> = {
      wait: 300,
    }
  ) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    setIsDebouncing(true)
    timerRef.current = setTimeout(() => {
      mutation.mutate(variables, moreOptions)
      setIsDebouncing(false)
    }, wait)
  }

  return { isDebouncing, debouncedMutate, ...mutation }
}
