import React from 'react'
import { AccessType } from 'utils/types/updateForm'
import AccessDropdown from './AccessDropdown'
import {
  getImageComponent,
  IconsTypes,
} from '../ShareWithDropdown/components/Icons'
import * as Styles from './ShareWithEntities.styles'

interface CurrentInvestorProps {
  name: string
  imageUrl?: string
  access: AccessType
  onAccessChange: (access: AccessType) => void
}

const CurrentInvestor: React.FC<CurrentInvestorProps> = ({
  name,
  imageUrl,
  access,
  onAccessChange,
}) => {
  const renderDropdown = () => (
    <AccessDropdown
      isInvestorTransaction
      access={access}
      onAccessChange={onAccessChange}
    />
  )

  return (
    <Styles.Container data-testid="current-investor">
      <Styles.NameContainer>
        {getImageComponent(IconsTypes.avatar, name, imageUrl)}
        <div>{name}</div>
      </Styles.NameContainer>
      {renderDropdown()}
    </Styles.Container>
  )
}

export default CurrentInvestor
