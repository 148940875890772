export const gridAnimation = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0,
      staggerDirection: 1,
    },
  },
  hidden: {
    opacity: 0,
  },
}

export const gridItemAnimation = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 50, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 50 },
    },
  },
}
