import {
  SkeletonAvatarWithAnimation,
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'

const SkeletonSelectMetrics = () => {
  return (
    <SkeletonItemWrapper
      gap="0.8rem"
      justifyContent="flex-start"
      height="3.6rem"
      marginLeft="1.8rem"
    >
      <SkeletonItem width="1.8rem" height="1.8rem" borderRadius="none" />
      <SkeletonAvatarWithAnimation
        width="2.1rem"
        height="2.1rem"
        marginLeft="0.8rem"
      />
      <SkeletonItemWrapper
        direction="column"
        gap="0.3rem"
        alignItems="flex-start"
      >
        <SkeletonItem width="12rem" height="1.7rem" />
        <SkeletonItem width="12rem" height="1.4rem" />
      </SkeletonItemWrapper>
    </SkeletonItemWrapper>
  )
}

export default SkeletonSelectMetrics
