import { useMemo } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import {
  ReshareTokenSet,
  getResharesByToken,
  getResharesWithMeOrMyGroup,
} from 'utils/functions/updates'
import { LoggingUpdateReshare, IndexUpdate } from 'utils/types/update'
import { getUserId, getCurrentGroupId } from 'selectors/auth'

export const useResharesByToken = (update?: IndexUpdate) =>
  useMemo<ReshareTokenSet[]>(() => {
    if (!update) {
      return []
    }
    return getResharesByToken(update)
  }, [update])

export const useResharesWithMeOrMyGroup = (update?: IndexUpdate) => {
  const userId = useAppSelector(getUserId)
  const currentGroupId = useAppSelector(getCurrentGroupId)

  return useMemo<LoggingUpdateReshare[]>(() => {
    if (!update) {
      return []
    }
    return getResharesWithMeOrMyGroup(update, currentGroupId, userId)
  }, [currentGroupId, update, userId])
}
