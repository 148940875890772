import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from './IconButton.styles'

const IconButton = React.forwardRef(
  (
    {
      icon,
      iconFamily,
      buttonType,
      border,
      customIcon,
      color,
      isActive,
      activeStyles,
      iconBackground,
      borderOnFocus,
      iconSize,
      direction,
      hoverOpacity,
      roundedButtonHover,
      ...rest
    },
    ref
  ) => {
    return (
      <Button
        ref={ref}
        isActive={isActive}
        activeStyles={activeStyles}
        color={color}
        buttonType={buttonType}
        border={border}
        background={iconBackground}
        borderOnFocus={borderOnFocus}
        role="button"
        direction={direction}
        hoverOpacity={hoverOpacity}
        roundedButtonHover={roundedButtonHover}
        {...rest}
      >
        {customIcon || (
          <FontAwesomeIcon size={iconSize} icon={[iconFamily, icon]} />
        )}
      </Button>
    )
  }
)

IconButton.propTypes = {
  iconFamily: PropTypes.string,
  icon: PropTypes.string,
  customIcon: PropTypes.element,
  buttonType: PropTypes.oneOf(['circle', 'square']),
  border: PropTypes.bool,
  color: PropTypes.string,
  isActive: PropTypes.bool,
  danger: PropTypes.bool,
  iconBackground: PropTypes.string,
  borderOnFocus: PropTypes.bool,
  activeStyles: PropTypes.object,
  iconSize: PropTypes.string,
  direction: PropTypes.string,
  hoverOpacity: PropTypes.string,
  roundedButtonHover: PropTypes.bool,
}

IconButton.defaultProps = {
  customIcon: null,
  iconFamily: 'fal',
  buttonType: 'circle',
  border: true,
  color: '',
  isActive: false,
  danger: false,
  iconBackground: null,
  borderOnFocus: true,
  hoverOpacity: '0.4',
  roundedButtonHover: true,
}
export default IconButton
