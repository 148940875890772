import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmptyText } from 'utils/functions/mjml'

import RichTextEditor from 'components/RichTextEditor'
import InputLabel from 'ui/InputLabel'

import {
  TextAreaWrapper,
  TextAreaContainer,
  FieldErrorMessage,
  InvisibleInput,
} from './RichTextArea.styles'

const RichTextArea = React.forwardRef(
  (
    {
      error,
      name,
      setValue,
      value,
      backgroundFocus,
      label,
      isOptional,
      optionalLabel,
      isThin,
      ...rest
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const onChange = (currentValue) => {
      if (!isEmptyText(currentValue)) {
        setValue(name, currentValue, { shouldValidate: true })
      } else {
        setValue(name, '', { shouldValidate: true })
      }
    }

    const hasError = !!error?.message

    return (
      <TextAreaWrapper disabled={rest.disabled}>
        {label && (
          <InputLabel isOptional={isOptional} optionalLabel={optionalLabel}>
            {label}
          </InputLabel>
        )}
        <TextAreaContainer
          hasError={hasError}
          disabled={rest.disabled}
          isFocused={isFocused}
          isThin={isThin}
        >
          <InvisibleInput name={name} ref={ref} />
          <RichTextEditor
            text={value}
            onChange={onChange}
            onFocus={(event) => {
              setIsFocused(true)
              rest?.onFocus?.(event)
            }}
            onBlur={(event) => {
              setIsFocused(false)
              rest?.onBlur?.(event)
            }}
            {...rest}
          />
        </TextAreaContainer>
        <FieldErrorMessage>{error?.message}</FieldErrorMessage>
      </TextAreaWrapper>
    )
  }
)

RichTextArea.defaultProps = {
  error: null,
  backgroundFocus: false,
  value: '',
  label: '',
  isOptional: false,
  optionalLabel: '(optional)',
  isThin: false,
}

RichTextArea.propTypes = {
  error: PropTypes.object,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  backgroundFocus: PropTypes.bool,
  label: PropTypes.string,
  isOptional: PropTypes.bool,
  isThin: PropTypes.bool,
  optionalLabel: PropTypes.string,
}

export default RichTextArea
