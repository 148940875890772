import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import theme from 'utils/theme'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import * as Styles from './MetricsZeroState.styles'

interface MetricsZeroStateProps {
  isAllMetricsTabSelected?: boolean
}

const MetricsZeroState = ({
  isAllMetricsTabSelected = false,
}: MetricsZeroStateProps) => {
  const intl = useIntl()
  const history = useHistory()
  const isFounder = useAppSelector(isActingAsFounder)

  const onAddMetricClickHandler = useCallback(() => {
    history.push('/metrics/all/update-all', {
      isBulkCreate: true,
    })
  }, [history])

  const readOnlyConfig = isFounder
    ? {}
    : {
        disableActionIfReadOnly: true,
        readOnlyTooltipText: intl.formatMessage({
          id: 'readOnlyMode.addMetrics',
        }),
      }

  const allMetricsTabProperties = {
    type: ZeroStateType.FOUNDER_METRICS,
    callToAction: onAddMetricClickHandler,
    callToActionText: (
      <Styles.ActionTextContainer>
        <FontAwesomeIcon icon={['fal', 'plus']} />
        {intl.formatMessage({ id: 'metrics.create.addMetrics' })}
      </Styles.ActionTextContainer>
    ),
  }

  return (
    <ZeroState
      maxWidth="50rem"
      {...(isAllMetricsTabSelected
        ? allMetricsTabProperties
        : {
            type: ZeroStateType.SELECTED_METRICS,
          })}
      customButtonStyles={{
        backgroundColor: 'transparent',
        color: `${theme.colors.darkBlue}`,
        marginTop: '1.4rem',
        width: 'fit-content',
        padding: '0 0.9rem',
        height: '3.2rem',
      }}
      infoMarginTop="-0.4rem"
      centerCallToActionButton
      {...readOnlyConfig}
    />
  )
}

export default MetricsZeroState
