import { useLayoutEffect, useState, useRef } from 'react'
import debounce from 'lodash/debounce'
import { ElementSize, Nullable } from 'utils/types/common'

const MAX_RECURSION = 20

export function useResizeObserver(domElementId?: string): ElementSize {
  const resizeObserver = useRef<ResizeObserver>()
  const domElement = useRef<Nullable<HTMLElement>>(null)
  const [state, setState] = useState<ElementSize>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  })

  const getDomElement = (id?: string) => {
    return id ? document.getElementById(id) : document.body
  }

  const getElementByIdAsync = (id?: string): Promise<Nullable<HTMLElement>> =>
    new Promise((resolve) => {
      const getElement = (iterationNumber: number) => {
        const element = getDomElement(id)
        if (element) {
          resolve(element)
        } else if (iterationNumber < MAX_RECURSION) {
          setTimeout(() => {
            requestAnimationFrame(() => getElement(iterationNumber + 1))
          }, 100)
        } else {
          resolve(null)
        }
      }
      getElement(0)
    })

  const firstRender = useRef(true)

  const observeElement = async () => {
    const setDebounced = debounce((value) => {
      setState(value)
    }, 100)

    domElement.current = await getElementByIdAsync(domElementId)
    if (domElement.current) {
      resizeObserver.current = new ResizeObserver(([observerdDomElement]) => {
        window.requestAnimationFrame(() => {
          if (firstRender.current) {
            setState(observerdDomElement.contentRect)
            firstRender.current = false
          } else {
            setDebounced(observerdDomElement.contentRect)
          }
        })
      })

      resizeObserver.current.observe(domElement.current)
    }
    return () => {}
  }

  useLayoutEffect(() => {
    observeElement()
    return () => {
      resizeObserver.current?.unobserve(domElement.current!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domElementId])

  return state
}
