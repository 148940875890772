import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { randomId } from 'utils/functions/number'

import {
  ColorPickerButton,
  Container,
  OptionsContainer,
  OptionsItem,
  CloseButton,
} from './ColorPicker.styles'

export const COLORS = [
  { code: '#ffffff', contrast: false },
  { code: '#f7f7f7', contrast: false },
  { code: '#fee4e7', contrast: false },
  { code: '#fef4d9', contrast: false },
  { code: '#eef7e3', contrast: false },
  { code: '#dfeefe', contrast: false },
  { code: '#111111', contrast: true },
]

export const needContrast = (color) => {
  return COLORS.find(
    (colorData) => colorData.code === color && colorData.contrast
  )
}

export const getContrastColor = () => '#ffffff'

const ColorPicker = ({ onChange, color }) => {
  const colorPickerId = useRef(randomId())

  const [open, setOpen] = useState(false)
  const [selectedColor, setSelectedColor] = useState(color)

  useEffect(() => {
    setSelectedColor(COLORS.find((colorData) => colorData.code === color)?.code)
  }, [color])

  const togglePicker = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      if (!open) {
        window.dispatchEvent(new Event('color_picker_opened'))
      }
      setOpen((opn) => !opn)
    },
    [open]
  )

  const onClickOutside = (event) => {
    if (event.target.id !== colorPickerId.current) {
      setOpen(false)
    }
  }

  const close = () => {
    setOpen(false)
  }
  useEffect(() => {
    window.addEventListener('click', onClickOutside)
    window.addEventListener('color_picker_opened', close)

    return () => {
      window.removeEventListener('click', onClickOutside)
      window.removeEventListener('color_picker_opened', close)
    }
  }, [])

  const onSelectColor = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const newColor = event.target.id
    setSelectedColor(newColor)
    onChange(newColor)
  }

  return (
    <Container>
      <ColorPickerButton
        onClick={togglePicker}
        color={selectedColor}
        open={open}
      />
      {open && (
        <OptionsContainer
          id={colorPickerId.current}
          onClick={(event) => event.stopPropagation()}
        >
          {COLORS.map((colorData) => (
            <OptionsItem>
              <ColorPickerButton
                id={colorData.code}
                color={colorData.code}
                selected={selectedColor === colorData.code}
                onClick={onSelectColor}
              />
            </OptionsItem>
          ))}
          <CloseButton onClick={close}>
            <FontAwesomeIcon icon={['fal', 'times']} />
          </CloseButton>
        </OptionsContainer>
      )}
    </Container>
  )
}

ColorPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  color: PropTypes.string,
}

ColorPicker.defaultProps = {
  color: null,
}
export default ColorPicker
