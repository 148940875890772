import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const AvatarContainer = styled.svg<{
  size: string
  minSize?: string
}>`
  min-width: ${({ minSize }) => minSize || 'initial'};
  min-height: ${({ minSize }) => minSize || 'initial'};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export const Circle = styled.circle`
  fill: ${color('lightGray')};
`

export const Initials = styled.text<{ initialsSize?: string }>`
  fill: white;
  font-size: ${({ initialsSize }) => initialsSize || '0.9rem'};
`
