export const FIELD_NAMES = {
  updateId: 'updateId',
  title: 'title',
  date: 'date',
  repetition: 'repetition',
  reportType: 'reportType',
  reportParams: 'reportParams',
  periods: 'periods',
  balanceDate: 'balanceDate',
  standardLayout: 'standardLayout',
  paymentsOnly: 'paymentsOnly',
  reportDatePeriod: 'reportDatePeriod',
  files: 'files',
  owner: 'owner',
} as const
