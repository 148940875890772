import type { IntlShape } from 'react-intl'
import { Roles } from 'utils/constants/groups'
import { Role, UserGroup } from 'utils/types/user'

export const GROUP_MANAGEMENT_SETTINGS = 'group_management'
export const ACCOUNT_SETTINGS = 'account'
export const LIST_MANAGEMENT_SETTINGS = 'list_management'

export const getCurrentSettingRoute = (pathname) =>
  pathname.includes(ACCOUNT_SETTINGS)
    ? ACCOUNT_SETTINGS
    : GROUP_MANAGEMENT_SETTINGS

export const getCurrentSettingOption = (pathname) => {
  const path = pathname.split('/')
  const size = path.length
  return path[size - 1]
}

export const OWNER_KEY = 'owner'
export const OWNER_LABEL = 'Owner'
export const MANAGER_KEY = 'manager'
export const MANAGER_ROL = 'Manager'
export const MEMBER_KEY = 'member'
export const MEMBER_ROL = 'Member'

export const getRolesOptions = (intl) => [
  {
    id: Roles.OWNER,
    name: OWNER_LABEL,
    value: Roles.OWNER,
    legend: intl ? intl.messages['groupManagement.ownerLegend'] : null,
  },
  {
    id: Roles.MANAGER,
    name: MANAGER_ROL,
    value: Roles.MANAGER,
    legend: intl ? intl.messages['groupManagement.managerLegend'] : null,
  },
  {
    id: Roles.MEMBER,
    name: MEMBER_ROL,
    value: Roles.MEMBER,
    legend: intl ? intl.messages['groupManagement.memberLegend'] : null,
  },
]

export const isOwner = (role) => role === OWNER_KEY

export const isManager = (role) => role === MANAGER_KEY

export const isMember = (role) => role === MEMBER_KEY

export const canEditRole = (currentUserRole, otherUserRole) => {
  if (isOwner(currentUserRole)) {
    return true
  }
  return !isMember(currentUserRole) && !isOwner(otherUserRole)
}

export const getAvailableRoles = (
  currentUserRole: Role,
  groupMember: UserGroup,
  intl: IntlShape
) => {
  if (
    (currentUserRole === Roles.MANAGER && groupMember.role !== Roles.OWNER) ||
    !groupMember.user.logged
  ) {
    // If the current user is not the owner, or the user has not confirmed the invitation, remove the Owner option
    return [...getRolesOptions(intl)].slice(1)
  }
  return getRolesOptions(intl)
}
