import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ActionTextContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  &:hover {
    color: ${color('primaryBlue')};
  }
  svg {
    font-size: 1.8rem;
  }
`
