import React from 'react'
import PropTypes from 'prop-types'

import { displayThousands } from 'utils/functions/number'
import { TooltipContainer, XValue, YValue } from './ChartToooltip.styles'

const ChartTooltip = ({ active, payload, formatX, formatY, width, height }) => {
  if (active && payload?.length) {
    const chartValues = payload[0]?.payload

    return (
      <TooltipContainer width={width} height={height}>
        <YValue>
          {formatY ? formatY(chartValues) : displayThousands(chartValues.y)}
        </YValue>
        <XValue>{formatX ? formatX(chartValues) : chartValues.x}</XValue>
      </TooltipContainer>
    )
  }
  return null
}

ChartTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  formatX: PropTypes.func,
  formatY: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
}

ChartTooltip.defaultProps = {
  active: false,
  payload: [],
  formatX: null,
  formatY: null,
  width: '',
  height: '',
}

export default ChartTooltip
