import { Nullable } from 'utils/types/common'
import { ChangeSetItem, EventType, LogTextBuilder } from '../types'

export class DefaultLogTextBuilder extends LogTextBuilder {
  private entityName: string

  private useQuotes: boolean

  constructor(entityName: string, useQuotes = false) {
    super()
    this.entityName = entityName
    this.useQuotes = useQuotes
  }

  private getCreatedLogText(): React.ReactNode {
    return this.formatMessage('logs.hasCreatedEntity', {
      entity: this.entityName,
    })
  }

  private getAddedFieldLog(
    fieldName: string,
    [, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.addedField',
      {
        fieldName,
        to,
      },
      this.useQuotes
    )
  }

  private getChangedLogFromTo(
    fieldName: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.changedLogFromTo',
      {
        fieldName,
        from,
        to,
      },
      this.useQuotes
    )
  }

  private getRemovedLogWithFieldText(
    fieldName: string,
    [from]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage(
      'logs.removedField',
      {
        fieldName,
        from,
      },
      this.useQuotes
    )
  }

  public getLogText(
    event: EventType,
    fieldName: string,
    changeSetItem: ChangeSetItem,
    _text: Nullable<string>
  ): React.ReactNode {
    if (event === EventType.CREATE) {
      return this.getCreatedLogText()
    }

    if (event === EventType.UPDATE) {
      if (changeSetItem[0] === null) {
        if (changeSetItem[1] === null) return null
        return this.getAddedFieldLog(fieldName, changeSetItem)
      }

      if (changeSetItem[1] === null) {
        return this.getRemovedLogWithFieldText(fieldName, changeSetItem)
      }
    }

    return this.getChangedLogFromTo(fieldName, changeSetItem)
  }
}
