import GroupService, {
  ENTITY_TYPE_EQUAL_FILTER,
  GROUP_ID_NOT_IN_FILTER,
  SCOPEABLE_ID_NOT_IN,
} from 'api/GroupService'
import ListService from 'api/ListService'
import UserService from 'api/UserService'
import { ShareWithOptionType } from 'containers/UpdatesV2/components/ShareSettingsModal'
import { ShareWithOption } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/useShareWithDropdown'
import { EntityType, GroupTypes } from 'utils/constants/groups'
import { HANDLE_REGEX, EMAIL_REGEX } from 'utils/functions/regex'
import { searchMatchesGroup } from 'utils/functions/updates'
import { Nullable } from 'utils/types/common'
import { Group, AppGroup, User } from 'utils/types/user'
import { getUserId } from 'selectors/auth'
import { useAppSelector } from './reduxToolkit'

const INITIAL_PAGE = 0
const RECENT_ENTITY_PAGE_SIZE = 3
const SEARCH_PAGE_SIZE = 25

const isUser = (entity: any): entity is User => entity.type === EntityType.USER

const getOptionFromUser = (user: User): ShareWithOption => ({
  id: user.id,
  name: user.name || user.email,
  imageUrl: user.image.smallLogo.url,
  type: user.name ? ShareWithOptionType.User : ShareWithOptionType.Email,
  subtitle: user.name ? user.email : undefined,
  unreachableEmail: user.unreachableEmailAddress,
})

const getOptionFromGroup = (group: Group): ShareWithOption => ({
  id: group.id,
  name: group.name,
  imageUrl: group.logo.smallLogo.url,
  type: ShareWithOptionType.Group,
  subtitle: `@${group.handle}`,
})

const sortListsByIdOrder = (listArray, listIds) => {
  return listIds
    .map((listId) => listArray.find((list) => +list.id === listId))
    .filter((list) => list !== undefined)
}

const includedInResults = (id: string, groupsAndUsers: ShareWithOption[]) =>
  !!groupsAndUsers.find((entity) => entity.id === id)

const getLists = async (search?: string) => {
  const {
    data: {
      entities: { lists: listsResponse = {}, listUsers = {}, listGroups = {} },
      result,
    },
  } = await ListService.getLists({
    include_users: true,
    per_page: search && SEARCH_PAGE_SIZE,
    ordered_by_last_used: search ? undefined : true,
  })

  const lists: ShareWithOption[] = Object.values(listsResponse).map(
    (list: any) => {
      const recipients: ShareWithOption[] = [
        ...list.listGroups.map((listGroupId: string) => ({
          id: listGroups[listGroupId].groupable.id,
          name: listGroups[listGroupId].groupable.name,
          type: ShareWithOptionType.Group,
          imageUrl: listGroups[listGroupId].groupable.logo.smallLogo.url,
        })),
        ...list.listUsers.map((listUserId: string) => ({
          id: listUsers[listUserId].user.id,
          name:
            listUsers[listUserId].user.name || listUsers[listUserId].user.email,
          type: listUsers[listUserId].user.name
            ? ShareWithOptionType.User
            : ShareWithOptionType.Email,
          imageUrl: listUsers[listUserId].user.image.smallLogo.url,
          unreachableEmail: listUsers[listUserId].user.unreachableEmailAddress,
        })),
      ]

      return {
        id: list.id,
        name: list.name,
        type: ShareWithOptionType.List,
        subtitle: `${recipients.length} recipients`,
        recipients,
      }
    }
  )

  return search
    ? lists.filter((list) =>
        list.name.toLowerCase().includes(search.toLowerCase())
      )
    : sortListsByIdOrder(lists, result)
}

export const useSearchScopedUsersAndGroups = ({
  shouldIncludeLists = true,
  shouldIncludeGroups = true,
  shouldIncludePendingUsers = true,
}: {
  shouldIncludeLists?: boolean
  shouldIncludeGroups?: boolean
  shouldIncludePendingUsers?: boolean
} = {}) => {
  const currentUserId = useAppSelector(getUserId)
  const loadRecentOptions = async (
    groupOwner: Nullable<AppGroup>,
    currentCompanyOwner: Nullable<AppGroup>,
    countItems: number = RECENT_ENTITY_PAGE_SIZE
  ): Promise<ShareWithOption[]> => {
    try {
      const lists = shouldIncludeLists ? await getLists() : []

      const usersNotIn: string[] = groupOwner
        ? [groupOwner.id, currentUserId]
        : [currentUserId]

      const usersResponse = await GroupService.getGroupsAndUsers(
        INITIAL_PAGE,
        countItems,
        {
          [ENTITY_TYPE_EQUAL_FILTER]: EntityType.USER,
          [SCOPEABLE_ID_NOT_IN]: usersNotIn,
        }
      )
      const groupsResponse = shouldIncludeGroups
        ? await GroupService.getGroupsAndUsers(INITIAL_PAGE, countItems, {
            [ENTITY_TYPE_EQUAL_FILTER]: EntityType.GROUP,
            [GROUP_ID_NOT_IN_FILTER]: usersNotIn,
          })
        : []
      const groupsAndUsers: ShareWithOption[] = [
        ...usersResponse.filter(
          (user) => shouldIncludePendingUsers || !!user.name
        ),
        ...groupsResponse,
      ].map((entity: Group | User) =>
        isUser(entity) ? getOptionFromUser(entity) : getOptionFromGroup(entity)
      )

      if (
        currentCompanyOwner &&
        currentCompanyOwner.id !== groupOwner?.id &&
        !includedInResults(currentCompanyOwner.id, groupsAndUsers)
      ) {
        groupsAndUsers.push({
          id: currentCompanyOwner.id,
          imageUrl: currentCompanyOwner?.logo?.url,
          name: currentCompanyOwner.name,
          subtitle: currentCompanyOwner.email,
          type: ShareWithOptionType.Group,
        })
      }

      return [...lists, ...groupsAndUsers]
    } catch (error) {
      return []
    }
  }

  const searchUsersAndGroupsByHandle = async (handleWithoutAt: string) => {
    try {
      if (handleWithoutAt.length) {
        return await GroupService.getGroupByHandle(handleWithoutAt)
      }
      return null
    } catch (err) {
      return UserService.getUserByHandle(handleWithoutAt)
    }
  }

  const getOption = (entity: Group | User) =>
    isUser(entity) ? getOptionFromUser(entity) : getOptionFromGroup(entity)

  const loadOptionsBySearchValue = async (
    searchValue: string,
    groupOwner: Nullable<AppGroup>,
    currentCompanyOwner: Nullable<AppGroup>
  ): Promise<ShareWithOption[]> => {
    try {
      let keyword = searchValue
      if (HANDLE_REGEX.test(searchValue)) {
        keyword = keyword.substring(1)
      }

      const lists = shouldIncludeLists ? await getLists(keyword) : []

      const groupsAndUsersResponse = await GroupService.getGroupsAndUsers(
        INITIAL_PAGE,
        SEARCH_PAGE_SIZE,
        {
          keyword,
          [SCOPEABLE_ID_NOT_IN]: [currentUserId],
          [ENTITY_TYPE_EQUAL_FILTER]: !shouldIncludeGroups
            ? EntityType.USER
            : undefined,
        }
      )
      let groupsAndUsers: ShareWithOption[] = groupsAndUsersResponse.map(
        (entity: Group | User) => getOption(entity)
      )

      groupsAndUsers = groupOwner
        ? groupsAndUsers.filter((entity) => entity.id !== groupOwner.id)
        : groupsAndUsers

      if (
        currentCompanyOwner &&
        currentCompanyOwner.id !== groupOwner?.id &&
        searchMatchesGroup(currentCompanyOwner, searchValue) &&
        !includedInResults(currentCompanyOwner.id, groupsAndUsers)
      ) {
        groupsAndUsers.push({
          id: currentCompanyOwner.id,
          imageUrl: currentCompanyOwner?.logo?.url,
          name: currentCompanyOwner.name,
          subtitle: currentCompanyOwner.email,
          type: ShareWithOptionType.Group,
        })
      }

      if (HANDLE_REGEX.test(searchValue)) {
        const groupOrUser = await searchUsersAndGroupsByHandle(keyword)

        if (
          groupOrUser &&
          groupOrUser.id !== groupOwner?.id &&
          !includedInResults(groupOrUser.id, groupsAndUsers) &&
          groupOrUser.type !== GroupTypes.FOUNDER
        ) {
          groupsAndUsers.unshift(getOption(groupOrUser))
        }
      } else if (EMAIL_REGEX.test(searchValue)) {
        const user = await UserService.getUserByEmail(searchValue)

        if (user && !includedInResults(user.id, groupsAndUsers)) {
          user.type = EntityType.USER
          groupsAndUsers.unshift(getOption(user))
        }
      }

      return [...lists, ...groupsAndUsers]
    } catch (error) {
      return []
    }
  }

  return {
    loadRecentOptions,
    loadOptionsBySearchValue,
  }
}
