import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { motion } from 'framer-motion'

export const Container = styled.div``
export const DateLabel = styled(motion.h2)`
  color: ${color('lightGray')};
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0.8rem 0;
`

export const List = styled.ul``

export const ListItem = styled(motion.li)`
  margin: 2rem 2rem 2rem 0;
  display: flex;
  flex: 1;
  gap: 1.6rem;
  position: relative;
`

export const FeedCard = styled.div`
  width: 100%;
  min-width: 0;
`

export const LineDash = styled.div`
  border-left: 1px dashed #e9e9e9;
  position: absolute;
  left: 1.8rem;
  height: 100%;
  height: calc(100% + 2rem);
  top: 0.7rem;
`
