import React, { ReactNode } from 'react'
import Separator from 'ui/Separator'
import { ReshareTokenSet } from 'utils/functions/updates'
import { LoggingUpdateReshare } from 'utils/types/update'
import ResharedGroups from './ResharedGroups'
import ReshareHistory from './ReshareHistory'

interface ReshareHistoryWrapperProps {
  children: ReactNode
  resharesByToken: ReshareTokenSet[]
  resharesWithMeOrMyGroup: LoggingUpdateReshare[]
  hasEditPermissions?: boolean
}

const ReshareHistoryWrapper: React.FC<ReshareHistoryWrapperProps> = ({
  children,
  resharesByToken,
  resharesWithMeOrMyGroup,
  hasEditPermissions,
}) => {
  return (
    <>
      {!hasEditPermissions && (
        <ResharedGroups reshares={resharesWithMeOrMyGroup} />
      )}

      {!hasEditPermissions && resharesWithMeOrMyGroup.length > 0 && (
        <Separator space="2em" />
      )}

      {children}

      {resharesByToken && <ReshareHistory resharesByToken={resharesByToken} />}
    </>
  )
}

ReshareHistoryWrapper.defaultProps = {
  hasEditPermissions: false,
}

export default ReshareHistoryWrapper
