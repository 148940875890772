import { useSelectUpdatesContext } from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import { MixedUpdate } from 'utils/types/update'

export const useClickableIcon = (update: MixedUpdate, enabled: boolean) => {
  const {
    selectedCards,
    handleToggleCard,
    currentHoverCardId,
    handleHoverCard,
    handleUnHoverCard,
  } = useSelectUpdatesContext()

  const onMouseOver = () => {
    if (enabled) handleHoverCard(update)
  }
  const onMouseLeave = () => {
    if (enabled) handleUnHoverCard()
  }

  const onClickIcon = () => {
    if (enabled) {
      handleToggleCard?.(update)
    }
  }

  if (!enabled) {
    return {
      isHover: false,
      isSelected: false,
      onMouseOver,
      onMouseLeave,
      onClickIcon,
    }
  }
  return {
    isHover:
      update.id === currentHoverCardId || Object.keys(selectedCards).length > 0,
    isSelected: !!selectedCards[update.id],
    onMouseOver,
    onMouseLeave,
    onClickIcon,
  }
}
