import {
  InfiniteSparkleRotation,
  StyledCelesteIcon,
} from './CelesteIcon.styles'

const CelesteIcon = () => {
  return (
    <StyledCelesteIcon>
      <InfiniteSparkleRotation width="3.55rem" height="3.55rem" />
    </StyledCelesteIcon>
  )
}

export default CelesteIcon
