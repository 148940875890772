import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactGrid } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import { CustomStaticDropdownCellTemplate } from 'components/Spreadsheet/CellTemplates/CustomStaticDropdownCellTemplate'
import { RowNumberCellTemplate } from 'components/Spreadsheet/CellTemplates/RowNumberCellTemplate/RowNumberCellTemplate'
import { CustomTextCellTemplate } from 'components/Spreadsheet/CellTemplates/CustomTextCellTemplate'
import * as Styles from 'components/Spreadsheet/Spreadsheet.styles'
import { WebsiteCellTemplate } from 'components/OnboardingModal/components/SetupYourPipelineStep/components/AddHoldingSpreadsheet/components/WebsiteCellTemplate/WebsiteCellTemplate'
import {
  AddHoldingsSpreadsheetProps,
  useAddHoldingsSpreadsheet,
} from './useAddHoldingsSpreadsheet'
import { HoldingNameCellTemplate } from './components/HoldingNameCellTemplate/HoldingNameCellTemplate'

const AddHoldingsSpreadsheet = ({
  onGridChange,
}: AddHoldingsSpreadsheetProps) => {
  const {
    handleChanges,
    addRow,
    rows,
    columns,
    getEventHandler,
    eventHandler,
  } = useAddHoldingsSpreadsheet({
    onGridChange,
  })

  return (
    <Styles.Container>
      <Styles.GridContainer>
        <Styles.StyledGrid>
          <ReactGrid
            // onCellsChanged expects only default cell types
            // @ts-ignore
            onCellsChanged={handleChanges}
            getEventHandler={getEventHandler}
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            enableRowSelection
            stickyTopRows={1}
            customCellTemplates={{
              rowNumber: new RowNumberCellTemplate(),
              website: new WebsiteCellTemplate(),
              text: new CustomTextCellTemplate(),
              staticDropdown: new CustomStaticDropdownCellTemplate(
                eventHandler
              ),
              holdingName: new HoldingNameCellTemplate(),
            }}
          />
        </Styles.StyledGrid>
      </Styles.GridContainer>
      <Styles.HorizontalPlusContainer hasRightMargin onClick={addRow}>
        <FontAwesomeIcon icon={['fal', 'plus']} />
      </Styles.HorizontalPlusContainer>
    </Styles.Container>
  )
}

export default AddHoldingsSpreadsheet
