import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import styles from './ForgotPasswordLink.module.scss'

const Legend = ({ color }) => (
  <Link to="/password-reset" className={styles.link} style={{ color }}>
    <FormattedMessage id="login.forgotPassword" />
  </Link>
)

Legend.propTypes = {
  color: PropTypes.string,
}

Legend.defaultProps = {
  color: '',
}

export default Legend
