import { ReactNode, cloneElement } from 'react'
import { ReactFCWithChildren } from 'utils/types/common'

import { useContextMenu } from './useContextMenu'
import { Menu } from './ContextMenu.styles'

interface Props {
  className?: string
  items?: ReactNode[]
  enabled?: boolean
}

const ContextMenu: ReactFCWithChildren<Props> = ({
  children,
  className,
  items,
  enabled = true,
}) => {
  const {
    contextMenuRef,
    menuRef,
    clicked,
    points,
    setClicked,
    hideContextMenu,
    setPoints,
  } = useContextMenu()

  return (
    <div
      ref={contextMenuRef}
      className={className}
      onContextMenu={(e) => {
        if (enabled) {
          e.preventDefault()
          setClicked(true)
          const width = menuRef.current?.offsetWidth || 0

          if (e.pageX + width > window.innerWidth) {
            setPoints({
              x: e.pageX - width,
              y: e.pageY,
            })
          } else {
            setPoints({
              x: e.pageX,
              y: e.pageY,
            })
          }
        }
      }}
    >
      {children}

      <Menu ref={menuRef} visible={clicked} top={points.y} left={points.x}>
        {items?.map((item) =>
          cloneElement(
            item as React.ReactElement<{
              hide: () => void
            }>,
            {
              hide: hideContextMenu,
            }
          )
        )}
      </Menu>
    </div>
  )
}
export default ContextMenu
