/* eslint-disable no-param-reassign */

import { ActionType } from 'redux-promise-middleware'

import Types from 'actions/types'
import createReducer from 'reducers/createReducer'
import { UniversityAction } from 'utils/functions/university'

const { Fulfilled, Pending, Rejected } = ActionType

const initialState = {
  actions: Object.values(UniversityAction),
  loading: true,
  initialLoading: true,
  isActive: true,
  isEnabled: false,
  progress: 0,
  isCompleted: false,
}

const fetchUniversityActionsFulfilled = (draftState, data) => {
  draftState.actions.forEach((universityAction) => {
    const backendAction = data.find(
      (backAction) => backAction.name === universityAction.id
    )

    if (backendAction) {
      universityAction.completed = true
      universityAction.skip = false
      universityAction.isUserAction = backendAction.isUserAction
      universityAction.userId = backendAction.userId
    } else {
      universityAction.completed = false
      universityAction.completedByMe = false
      universityAction.skip = false
    }
  })

  draftState.loading = false
  draftState.initialLoading = false
  return draftState
}

const fetchUniversityActionsRejected = (draftState) => {
  draftState.loading = false
  draftState.error = true
  return draftState
}

const fetchUniversityActionsPending = (draftState) => {
  draftState.loading = true
  draftState.error = false
  return draftState
}

const setActionCompleted = (draftState, payload) => {
  const actionToComplete = draftState.actions.find(
    (univAction) => univAction.id === payload.actionId
  )
  actionToComplete.completed = true
  actionToComplete.completedByMe = true
  return draftState
}

const setUserActionSkiped = (draftState, payload) => {
  const actionToSkip = draftState.actions.find(
    (univAction) => univAction.id === payload.actionId
  )
  actionToSkip.skip = true
  actionToSkip.completed = true
  actionToSkip.isUserAction = true
  actionToSkip.completedByMe = true
  return draftState
}

const setGroupActionSkiped = (draftState, payload) => {
  const actionToSkip = draftState.actions.find(
    (univAction) => univAction.id === payload.actionId
  )
  actionToSkip.skip = true
  actionToSkip.completed = true
  actionToSkip.isUserAction = false
  actionToSkip.completedByMe = true
  return draftState
}

const setUniversityProgress = (draftState, payload) => {
  draftState.progress = payload.progress
  draftState.isCompleted = payload.progress === 100
  if (payload.isFirstSetProgress && draftState.isCompleted) {
    draftState.isActive = false
  } else {
    draftState.isActive = true
  }
  return draftState
}

const setUniversityEnabled = (draftState, payload) => {
  draftState.isEnabled = payload.isEnabled

  return draftState
}

const setUniversityActionEnabled = (draftState, payload) => {
  const actionToUpdate = draftState.actions.find(
    (action) => action.id === payload.actionId
  )
  if (actionToUpdate) {
    actionToUpdate.isDisabled = !payload.isEnabled
  }

  return draftState
}

const handlers = {
  [`${Types.FETCH_UNIVERSITY_ACTIONS}_${Fulfilled}`]:
    fetchUniversityActionsFulfilled,
  [`${Types.FETCH_UNIVERSITY_ACTIONS}_${Pending}`]:
    fetchUniversityActionsPending,
  [`${Types.FETCH_UNIVERSITY_ACTIONS}_${Rejected}`]:
    fetchUniversityActionsRejected,
  [`${Types.SET_ACTION_COMPLETED}_${Pending}`]: setActionCompleted,
  [`${Types.SET_USER_ACTION_SKIPPED}_${Pending}`]: setUserActionSkiped,
  [`${Types.SET_GROUP_ACTION_SKIPPED}_${Pending}`]: setGroupActionSkiped,
  [Types.SET_UNIVERSITY_PROGRESS]: setUniversityProgress,
  [Types.SET_UNIVERSITY_ENABLED]: setUniversityEnabled,
  [Types.SET_UNIVERSITY_ACTION_ENABLED]: setUniversityActionEnabled,
}

const authReducer = createReducer(initialState, handlers)

export default authReducer
