import styled, { css } from 'styled-components'

import { maxSize, size } from 'utils/constants/breakpoint'

import PlanColumn from './components/PlanColumn'

export const Container = styled.div<{ modalMode?: boolean }>`
  padding: 10rem 0 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${size.lg} {
    @media ${maxSize.xl} {
      padding-top: 5.2rem;
    }
  }

  @media ${size.xl} {
    padding-top: 8rem;
  }
`

export const Plans = styled.div<{
  countPlans: number
}>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.countPlans}, 1fr);
  justify-content: center;
  gap: 3rem;
`

const getSliderStyles = ({ slidesToShow }: { slidesToShow: number }) => {
  switch (slidesToShow) {
    case 1:
      return css`
        width: 100%;
        max-width: 42rem;
      `
    case 2:
      return css`
        width: 70rem;
      `
    case 3:
      return css`
        width: 102rem;
      `
    default:
      return css`
        width: 100%;
      `
  }
}
export const PlansSlider = styled.div<{ slidesToShow: number }>`
  gap: 1.6rem;
  ${getSliderStyles};
`

export const PlanColumnSlide = styled(PlanColumn)`
  width: 30rem;
`

export const PlanSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
`
