import styled, { css } from 'styled-components'
import { Scroll } from 'App.styles'
import Modal from 'components/Modal/Modal'
import { color } from 'utils/functions/colors'

export const participantsContainerHeight = 25.5
export const rowHeight = 5.9

export const StyledModal = styled(Modal)`
  width: 56.3rem;
`

export const Body = styled(Modal.Body)`
  gap: 1.6rem;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
`

export const ParticipantsContainer = styled.div<{
  hasScroll?: boolean
}>`
  ${Scroll}
  border: 1px solid ${color('veryLightGray')};
  width: 100%;
  height: ${participantsContainerHeight}rem;
  border-radius: 8px;
  overflow: overlay;

  ul {
    border: initial;
    border-radius: initial;

    ${(props) =>
      !props.hasScroll &&
      css`
         {
          li:last-child {
            border-bottom: 0.1rem solid ${color('veryLightGray')};
          }
        }
      `}
  }
`

export const ParticipantsZeroState = styled.div`
  color: ${color('lightGray')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2.6rem;
  text-align: center;
  height: 100%;

  h1 {
    font-size: 1.8rem;
    padding-bottom: 0.8rem;
  }

  p {
    font-size: 1.4rem;
  }
`

export const RowContainer = styled.div`
  display: flex;
  gap: 0.7rem;
  margin: 0 1.8rem;
  align-items: center;
  height: 100%;
`

export const AvatarContainer = styled.div`
  width: 1.9rem;
`

export const NameContainer = styled.div`
  flex: 1;
  color: ${color('darkGray')};
`

export const RemoveContainer = styled.div`
  width: 3rem;
`
