import styled from 'styled-components'
import Modal from 'components/Modal'
import { color } from 'utils/functions/colors'

export const ModalBody = styled(Modal.Body)`
  padding: 2.4rem;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: ${color('darkBlue')};
`
