export const DefaultVideo = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="file-video 1" clipPath="url(#clip0_7690_533774)">
      <path
        id="Vector"
        d="M10.6705 3.26107L8.70451 1.29508C8.49353 1.08411 8.20738 0.965578 7.90902 0.965576H3.125C2.50367 0.965576 2 1.46925 2 2.09058V11.8406C2 12.4619 2.50367 12.9656 3.125 12.9656H9.875C10.4963 12.9656 11 12.4619 11 11.8406V4.05656C11 3.75819 10.8815 3.47205 10.6705 3.26107ZM9.78402 3.96558H8V2.18156L9.78402 3.96558ZM3.125 11.8406V2.09058H6.875V4.52808C6.875 4.83874 7.12684 5.09058 7.4375 5.09058H9.875V11.8406H3.125ZM8.48485 6.88816L7.25 8.12278V7.24683C7.25 6.98794 7.04014 6.77808 6.78125 6.77808H4.34375C4.08486 6.77808 3.875 6.98794 3.875 7.24683V9.68433C3.875 9.94322 4.08486 10.1531 4.34375 10.1531H6.78125C7.04014 10.1531 7.25 9.94322 7.25 9.68433V8.80837L8.48485 10.0429C8.71963 10.2777 9.125 10.1128 9.125 9.77775V7.15333C9.125 6.81792 8.71932 6.65367 8.48485 6.88816Z"
        fill="#313748"
      />
    </g>
    <defs>
      <clipPath id="clip0_7690_533774">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.965576)"
        />
      </clipPath>
    </defs>
  </svg>
)
