import { SIDE_BAR_WIDTH } from 'layouts/SideBarComponents/SideBar'
import styled from 'styled-components/macro'
import { maxSize, size } from 'utils/constants/breakpoint'
import {
  CHAT_LAYOUT_MOBILE_POSITION,
  CHAT_LAYOUT_PADDING_LEFT,
  CHAT_LAYOUT_POSITION,
} from 'utils/constants/chat'

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: ${CHAT_LAYOUT_POSITION}px;
  width: calc(
    100% - ${CHAT_LAYOUT_POSITION}px - ${SIDE_BAR_WIDTH} -
      ${CHAT_LAYOUT_PADDING_LEFT}px
  );
  z-index: 5;
  pointer-events: none;

  .str-chat__load-more-button {
    display: none;
  }

  display: none;
  gap: 1.2rem;
  justify-content: flex-end;
  align-items: flex-end;

  @media ${size.xsm} {
    display: flex;
  }

  @media ${maxSize.lg} {
    width: calc(100% - ${CHAT_LAYOUT_POSITION}px);
  }

  @media ${maxSize.md} {
    right: ${CHAT_LAYOUT_MOBILE_POSITION}px;
  }
`
