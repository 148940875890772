/* eslint-disable consistent-return */
import { useCallback, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import GroupService, {
  GROUP_ID_NOT_IN_FILTER,
  ENTITY_TYPE_NOT_EQUAL_FILTER,
  ENTITY_TYPE_NOT_IN_FILTER,
} from 'api/GroupService'
import Toast from 'components/Toast'
import { HANDLE_REGEX } from 'utils/functions/regex'
import { EntityType, GroupTypes } from 'utils/constants/groups'
import { getCurrentGroupId } from 'selectors/auth'
import { useMetricLists } from './useMetricLists'

const INITIAL_PAGE = 1
const PAGE_SIZE = 25

const useMetricGroups = (initialData) => {
  const [initialGroups, setInitialGroups] = useState(initialData || [])
  const [clear, setClear] = useState(false)
  const [currentGroups, setCurrentGroups] = useState(initialData || [])
  const [removedGroups, setRemovedGroups] = useState([])
  const [addedGroups, setAddedGroups] = useState([])
  const [search, setSearch] = useState('')
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const intl = useIntl()

  const searchUsersAndGroupsByHandle = async (handle) => {
    const normalizedSearch = handle.replace('@', '')
    try {
      if (normalizedSearch.length) {
        return await GroupService.getGroupByHandle(normalizedSearch)
      }
      return null
    } catch (err) {
      return null
    }
  }

  const loadGroups = useCallback(
    async (inputSearch) => {
      try {
        const currentGroupsIds = currentGroups
          ? [...(currentGroups?.map((item) => item?.id) ?? [])]
          : []

        const groups = await GroupService.getClientGroups(
          INITIAL_PAGE,
          PAGE_SIZE,
          {
            keyword: inputSearch.replace(/^@/, ''),
            [GROUP_ID_NOT_IN_FILTER]: currentGroupsIds,
            [ENTITY_TYPE_NOT_IN_FILTER]: [
              EntityType.USER,
              EntityType.INVESTOR_GROUP,
            ],
          }
        )
        setSearch(inputSearch)

        const notIncludedInResults = (id) =>
          !groups.some((group) => group.id === id)

        if (HANDLE_REGEX.test(inputSearch)) {
          const group = await searchUsersAndGroupsByHandle(inputSearch)

          if (
            group &&
            group.type === GroupTypes.CLIENT &&
            notIncludedInResults(group.id)
          ) {
            groups.push(group)
          }
        }
        return groups.filter(
          (group) =>
            group.type !== GroupTypes.FOUNDER && group.id !== currentGroupId
        )
      } catch (error) {
        Toast.display(intl.messages['metrics.errorFetchingGroups'], 'error')
      }
    },
    [currentGroupId, currentGroups, intl.messages]
  )

  useEffect(() => {
    if (clear) {
      setClear(false)
    }
  }, [clear])

  const setGroups = (groups) => {
    setCurrentGroups(groups)
    setInitialGroups(groups)
  }

  const reset = () => {
    setGroups(initialData)
  }

  useEffect(() => {
    if (initialData) {
      setGroups(initialData)
    }
  }, [initialData])

  const lookupGroupByHandle = useCallback(
    async (handle) => {
      try {
        const normalizeName = handle.replace('@', '')

        const group = await GroupService.getGroupByHandle(normalizeName)

        if (!group || group.type === GroupTypes.FOUNDER) {
          return { doesGroupExist: false }
        }
        if (
          group &&
          !currentGroups?.find((currGroup) => currGroup.id === group?.id)
        ) {
          setClear(true)

          setCurrentGroups((currState) => {
            return currState ? [...currState, group] : [group]
          })
          setAddedGroups((currState) => {
            return currState ? [...currState, group] : [group]
          })
          return { doesGroupExist: true }
        }
        return { doesGroupExist: true }
      } catch (err) {
        return { doesGroupExist: false }
      }
    },
    [currentGroups]
  )

  const handleAddGroup = async (dropdownName, groupId, element) => {
    if (element.type !== EntityType.INVESTOR_GROUP) {
      if (element.name?.indexOf('@') >= 0) {
        lookupGroupByHandle(element.name)
      }
      if (!element.id) {
        return
      }
      if (!currentGroups?.find((currGroup) => currGroup.id === element.id)) {
        setCurrentGroups((currState) => {
          return currState ? [...currState, element] : [element]
        })
        setAddedGroups((currAdded) => [...currAdded, element])
        setRemovedGroups((currState) =>
          currState.filter((group) => group.id !== element.id)
        )
      }
    }
  }

  const handleAddGroupByHandle = (handle) => {
    if (handle?.indexOf('@') >= 0) {
      return lookupGroupByHandle(handle)
    }
    return { doesGroupExist: false }
  }

  const handleRemoveGroup = useCallback(
    (id) => {
      setCurrentGroups((currState) => {
        return currState.filter((item) => {
          if (item.id === id) {
            setRemovedGroups((currDeleted) => [...currDeleted, item])
            return false
          }
          return true
        })
      })

      setAddedGroups((currState) =>
        currState.filter((group) => group.id !== id)
      )
    },
    [setCurrentGroups]
  )

  const initialGroupIds = initialGroups?.map(({ id }) => id) ?? []
  const addedGroupIds = addedGroups?.map(({ id }) => id) ?? []
  const removedGroupIds = removedGroups?.map(({ id }) => id) ?? []

  const groupIdsToAdd = []
  const groupIdsToRemove = []
  addedGroupIds.forEach((addedGroupId) => {
    if (initialGroupIds.includes(addedGroupId)) {
      return
    }

    if (removedGroupIds.includes(addedGroupId)) {
      return
    }

    groupIdsToAdd.push(addedGroupId)
  })
  removedGroupIds.forEach((removedGroupId) => {
    if (addedGroupIds.includes(removedGroupId)) {
      return
    }
    if (initialGroupIds.includes(removedGroupId)) {
      groupIdsToRemove.push(removedGroupId)
    }
  })

  const {
    itemLists,
    handleDeleteItemList,
    handleEditItemList,
    handleAddItemList,
  } = useMetricLists({
    currentGroups,
    handleRemoveGroup,
    setCurrentGroups,
    handleAddGroup,
  })

  return {
    currentGroups,
    setCurrentGroups,
    removedGroups,
    addedGroups,
    loadGroups,
    handleAddGroup,
    handleAddGroupByHandle,
    setGroups,
    handleRemoveGroup,
    groupIdsToAdd,
    groupIdsToRemove,
    clear,
    searchText: search,
    reset,

    itemLists,
    handleDeleteItemList,
    handleEditItemList,
    handleAddItemList,
  }
}

export default useMetricGroups
