import { useCallback, useRef, useState } from 'react'
import humps from 'humps'
import Toast from 'components/Toast'
import {
  parseCSVToJson,
  parseArray,
  parseBoolean,
} from 'utils/functions/csvParser'
import { randomId } from 'utils/functions/number'
import HoldingsService from 'api/HoldingsService'
import {
  AddHoldingBulkImportFormErrors,
  AddHoldingFormId,
  FundManager,
  HoldingType,
  SuggestionErrors,
} from './types'
import { getHoldingsErrorsFromDrafts } from './errors'
import { mapToBulkImportBackendRequest } from './ImportHoldingsModal/utils'
import { getDefaultHoldingValues } from './utils'

interface CSVData {
  holdingType: HoldingType
  name: string
  legalEntityName: string
  website: string
  pointOfContact: string
  includeFundManager: boolean
  fundManagerName: string
  fundManagerWebsite: string
  fundManagerPointOfContact: string
  funds: string[]
}

const MODAL_FADE_OUT_DURATION = 500

type HoldingModalType = 'single' | 'bulk'

export interface AddHoldingModalProps {
  isModalOpen: boolean
  onHideModal: () => void
  onCreateNewHoldings?: (holdingIds: string[]) => void
  name?: string
  fundManager?: FundManager
}

export const useAddHoldingModal = ({ onHideModal }: AddHoldingModalProps) => {
  const [type, setType] = useState<HoldingModalType>('single')
  const [fileName, setFileName] = useState('')
  const [processingFile, setProcessingFile] = useState(false)
  const [holdingsFromCSV, setHoldingsFromCSV] = useState<AddHoldingFormId[]>([])
  const initialBulkErrors = useRef<AddHoldingBulkImportFormErrors>()
  const initialBulkStatus = useRef<{
    holdings: SuggestionErrors[]
  }>()

  const closeModal = useCallback(() => {
    onHideModal()
    setTimeout(() => {
      setType('single')
      setFileName('')
    }, MODAL_FADE_OUT_DURATION)
  }, [onHideModal])

  const parseCSV = useCallback(async (file: File) => {
    if (file.type === 'text/csv') {
      try {
        setProcessingFile(true)
        setFileName(file.name)
        const { data } = await parseCSVToJson<CSVData>(file, {
          transformHeader: humps.camelize,
          transform: (value, header) => {
            if (header === 'holdingType') {
              return value.toLowerCase() === 'fund'
                ? HoldingType.FUND
                : HoldingType.COMPANY
            }

            if (header === 'funds') {
              return parseArray(value)
            }

            if (header === 'includeFundManager') {
              return parseBoolean(value)
            }

            return value
          },
        })

        const holdings: AddHoldingFormId[] = data.map((csvHolding) => ({
          ...getDefaultHoldingValues(),
          id: randomId(),
          type: csvHolding.holdingType,
          ...(csvHolding.holdingType === HoldingType.COMPANY && {
            company: {
              legalEntityName: csvHolding.legalEntityName,
              name: csvHolding.name,
              website: csvHolding.website,
              pointOfContact: csvHolding.pointOfContact,
            },
          }),
          ...(csvHolding.holdingType === HoldingType.FUND && {
            funds: {
              funds: csvHolding.funds,
              includeFundManager: csvHolding.includeFundManager,
              ...(csvHolding.includeFundManager && {
                fundManager: {
                  name: csvHolding.fundManagerName,
                  website: csvHolding.fundManagerWebsite,
                  pointOfContact: csvHolding.fundManagerPointOfContact,
                },
              }),
            },
          }),
        }))

        const validatedHoldings = await HoldingsService.validateBulkImport(
          holdings.map((holding, index) =>
            mapToBulkImportBackendRequest(holding, index)
          )
        )

        const { errors, status } = await getHoldingsErrorsFromDrafts(
          validatedHoldings,
          holdings
        )

        setHoldingsFromCSV(holdings)
        initialBulkErrors.current = errors
        initialBulkStatus.current = status
        setType('bulk')
      } catch (error) {
        Toast.displayIntl(
          [
            'addHolding.errors.readingCSV',
            {
              error: error.message,
            },
          ],
          'error'
        )
      }

      setProcessingFile(false)
    } else {
      Toast.displayIntl('bulkImportModal.fileTypeError', 'error')
    }
  }, [])

  return {
    type,
    closeModal,
    parseCSV,
    processingFile,
    fileName,
    holdingsFromCSV,
    initialBulkErrors,
    initialBulkStatus,
  }
}
