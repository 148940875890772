/* eslint-disable react/button-has-type */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Button.module.scss'

const Button = ({
  autoWidth,
  back,
  blue,
  children,
  className,
  disabled,
  full,
  large,
  link,
  next,
  primary,
  rounded,
  secondary,
  strong,
  type,
  xlarge,
  icon,
  danger,
  ...rest
}) => (
  <button
    className={classNames(
      styles.btn,
      {
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.link]: link,
        [styles.blue]: blue,
        [styles.full]: full,
        [styles.large]: large,
        [styles.xlarge]: xlarge,
        [styles.autoWidth]: autoWidth,
        [styles.disabled]: disabled,
        [styles.rounded]: rounded,
        [styles.strong]: strong,
        [styles.back]: back,
        [styles.btnBack]: back,
        [styles.next]: next,
        [styles.navigation]: back || next,
        [styles.danger]: danger,
      },
      className
    )}
    type={type}
    disabled={disabled}
    {...rest}
  >
    {back ? (
      <FontAwesomeIcon
        className={styles.angleLeft}
        icon={['fas', 'angle-left']}
      />
    ) : null}
    {icon ? <FontAwesomeIcon className={styles.angleLeft} icon={icon} /> : null}
    {children}
    {next ? (
      <FontAwesomeIcon
        className={styles.angleRight}
        icon={['fas', 'angle-right']}
      />
    ) : null}
  </button>
)

Button.defaultProps = {
  autoWidth: false,
  blue: false,
  className: '',
  full: false,
  large: false,
  link: false,
  primary: false,
  secondary: false,
  type: 'button',
  disabled: false,
  xlarge: false,
  rounded: false,
  strong: false,
  back: false,
  next: false,
  danger: false,
  icon: null,
}

Button.propTypes = {
  autoWidth: PropTypes.bool,
  blue: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  full: PropTypes.bool,
  large: PropTypes.bool,
  link: PropTypes.bool,
  disabled: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  xlarge: PropTypes.bool,
  rounded: PropTypes.bool,
  strong: PropTypes.bool,
  back: PropTypes.bool,
  next: PropTypes.bool,
  danger: PropTypes.bool,
  icon: PropTypes.array,
}

export default Button
