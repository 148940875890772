import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  color: ${color('darkGray')};
  margin-bottom: 2rem;
  padding-left: 0.5rem;
  font-size: 1.4rem;
  overflow-wrap: anywhere;
`

export const Date = styled.div`
  color: ${color('lightGray')};
  margin-top: 0.4rem;
  font-size: 1.2rem;
`
