/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

import { FormattedNumber } from 'react-intl'
import {
  ReportColumn,
  ReportSectionTitle,
  ReportCell,
  ReportValue,
  ReportItemsContainer,
} from './ShowReport.styles'

const ReportSectionValues = ({ sections, valueIndex, isLastColumn }) => {
  return (
    <ReportColumn borderRight={!isLastColumn}>
      <ReportSectionTitle />
      {sections.map((section, index) => {
        const [, ...valueCells] = section.cells
        return (
          <ReportItemsContainer key={index}>
            <ReportCell bold={section.rowType === 'SummaryRow'}>
              <ReportValue align="end">
                <FormattedNumber
                  value={valueCells?.[valueIndex]?.value || 0}
                  maximumFractionDigits={2}
                />
              </ReportValue>
            </ReportCell>
          </ReportItemsContainer>
        )
      })}
    </ReportColumn>
  )
}

ReportSectionValues.propTypes = {
  sections: PropTypes.array.isRequired,
  valueIndex: PropTypes.number.isRequired,
  isLastColumn: PropTypes.bool.isRequired,
}

export default ReportSectionValues
