import {
  TransactionInstrument,
  TransactionInstrumentType,
} from 'utils/types/update'
import { TransactionInstruments } from './transactionInstruments'

export const TransactionTypes = {
  COMMITMENT: 'commitment',
  DISTRIBUTION: 'distribution',
  INVESTMENT: 'investment',
} as const

export const CalculationBasisTypes = {
  N_A: 'N/A',
  ACTUAL_365: 'Actual/365',
  '30_360': '30/360',
} as const

export const DividendTypes = {
  N_A: 'N/A',
  CUMULATIVE: 'Cumulative',
  NON_CUMULATIVE: 'Noncumulative',
} as const

export const TransactionFundType = {
  HOLDING: 'Holding',
  INVESTOR: 'Investor',
} as const

export const TransactionFieldsPerInstrument: {
  [instrumentType: TransactionInstrumentType]: (keyof TransactionInstrument)[]
} = {
  [TransactionInstruments.CONVERTIBLE_NOTE]: [
    'valuationCap',
    'discountRate',
    'interestRate',
    'interestCalculationBasis',
    'maturityDate',
  ],
  [TransactionInstruments.DEBT_CREDIT]: [
    'interestRate',
    'interestCalculationBasis',
    'maturityDate',
  ],
  [TransactionInstruments.EQUITY]: [
    'purchasePricePerShare',
    'sharesPurchased',
    'preMoneyValuation',
    'postMoneyValuation',
  ],
  [TransactionInstruments.FUND_INVESTMENT]: [
    'annualManagementFee',
    'carry',
    'carryHurdleRate',
  ],
  [TransactionInstruments.PREFERRED_EQUITY]: [
    'purchasePricePerShare',
    'sharesPurchased',
    'preMoneyValuation',
    'postMoneyValuation',
    'dividend',
    'dividendCalculationBasis',
    'dividendType',
  ],
  [TransactionInstruments.SAFE]: ['valuationCap', 'discountRate'],
  [TransactionInstruments.WARRANTS]: [
    'vestingCommencementDate',
    'expirationDate',
    'strikePrice',
    'numberOfShares',
  ],
  [TransactionInstruments.Other]: [],
}
