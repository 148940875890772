import { enUs } from '../messages/en-US'

const enUSLanguage = {
  messages: {
    ...enUs,
  },
  locale: 'en-US',
}

export default enUSLanguage
