import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import Drawer from 'components/Drawer'
import {
  Card,
  CardText,
  Description,
  CardIcon,
  CardTitle,
  CardDescription,
} from './ApproveMetric.styles'

const ApproveMetric = ({ closeDrawer, isOpen }) => {
  const intl = useIntl()
  const history = useHistory()
  const { linkedMetricId } = useParams()
  const { state } = useLocation()
  const metricsRoute = useMetricsRoute()

  const redirectToAddGroupToExsistingMetric = () => {
    history.replace(`${metricsRoute}/${linkedMetricId}/add-group-to-metric`, {
      linkedMetric: state?.linkedMetric,
    })
  }

  const redirectToCreateNewMetric = () => {
    history.push(`${metricsRoute}/new`, {
      linkedMetric: state?.linkedMetric,
      isOrganizationMetric: true,
    })
  }

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={intl.formatMessage(
        { id: 'metrics.approveMetricGroup' },
        { groupName: 'Investor' }
      )}
      onCloseDrawer={closeDrawer}
    >
      <Drawer.Content contentPadding="2rem 2.4rem">
        <Description>
          <FormattedMessage id="metrics.selectSharedData" />
        </Description>

        <Card onClick={redirectToCreateNewMetric}>
          <CardIcon>
            <FontAwesomeIcon icon={['far', 'plus']} />
          </CardIcon>
          <CardText>
            <CardTitle>
              <FormattedMessage id="metrics.createNewMetric" />
            </CardTitle>
            <CardDescription>
              <FormattedMessage id="metrics.createNewMetricDesc" />
            </CardDescription>
          </CardText>
        </Card>

        <Card onClick={redirectToAddGroupToExsistingMetric}>
          <CardIcon>
            <FontAwesomeIcon icon={['far', 'share']} />
          </CardIcon>
          <CardText>
            <CardTitle>
              <FormattedMessage id="metrics.addGroupToExistingMetric" />
            </CardTitle>
            <CardDescription>
              <FormattedMessage id="metrics.addGroupToExistingMetricDesc" />
            </CardDescription>
          </CardText>
        </Card>
      </Drawer.Content>
    </Drawer>
  )
}

ApproveMetric.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default ApproveMetric
