import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const EditButtonColumnContainer = styled.div`
  position: relative;
`

export const ButtonStylesWrapper = styled.div`
  div[class*='OptionsDropdown_dots'] {
    width: 3.9rem;
    height: 3.9rem;
    box-shadow: 0.1rem 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
    border: solid 0.1rem #194590;
    color: #194590;
    background-color: #eff2f7;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const HoverButtonContainer = styled.div`
  display: none;
`
export const ButtonContainer = styled.div`
  z-index: 10;
  display: none;
  position: absolute;

  right: -1rem;
  bottom: -2rem;
`

export const EditButton = styled.button`
  width: 3.9rem;
  height: 3.9rem;
  padding: 1.1rem 1.1rem 1.1rem;
  box-shadow: 0.1rem 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  border: solid 0.1rem #e9e9e9;
  background-color: white;
  color: #787e8c;
  border-radius: 100%;
  svg {
    font-size: 1.6rem;
  }
  &:focus {
    outline: none;
  }
`

export const EditButtonColumnWrapper = styled.div`
  button {
    border: 1px solid ${color('veryLightGray')};
    background: ${color('white')};
    color: ${color('darkBlue')};
    &:hover {
      svg {
        color: ${color('black')};
      }
    }
  }
`
