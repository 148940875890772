import { useCallback, useMemo, useState } from 'react'
import { useChatContext, useMessageContext } from 'stream-chat-react'
import {
  CHATBOT_USER_ID,
  StreamChatType,
  TopicChannel,
} from 'containers/Chat/types'
import { useChatMembers } from 'utils/hooks/useChatMembers'
import { ChannelMember } from 'containers/Chat/components/ChatMembersPicker/useChatMembersPicker'
import { useSetActiveChannel } from 'containers/Chat/hooks'
import { ShareWithOptionType } from 'containers/UpdatesV2/components/ShareSettingsModal'
import { TopicEntity } from 'utils/types/chatTopicEntity'
import { Channel } from 'stream-chat'
import { useArchiveChannels } from 'containers/Chat/hooks/useArchiveChannels'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { SHOW_ARCHIVED_SIDEBAR_EVENT } from 'containers/Chat/components/ArchivedChannelsSidebar/useArchivedChannelsSidebar'

export function useCreateNewChatSuggestion(entity?: TopicEntity) {
  const { client } = useChatContext<StreamChatType<TopicChannel>>()
  const setActiveChannel = useSetActiveChannel<TopicChannel>()

  const { message } = useMessageContext<StreamChatType>()
  const { isChannelArchived } = useArchiveChannels()
  const { members: streamMembers } = useChatMembers()
  const members: ChannelMember[] = useMemo(() => {
    return streamMembers.reduce<ChannelMember[]>((acc, member) => {
      if (member.user?.id !== CHATBOT_USER_ID) {
        acc.push({
          id: member.user!.id,
          name: member.user!.name!,
          imageUrl: member.user!.image,
          type: ShareWithOptionType.User,
        })
      }
      return acc
    }, [])
  }, [streamMembers])
  const [closed, setClosed] = useState<boolean>(false)
  const [showCreateChatModal, setShowCreateChatModal] = useState<boolean>(false)

  const dismissSuggestion = useCallback(() => {
    client.partialUpdateMessage(message.id, {
      set: {
        createNewChatSuggestionDismissed: true,
      },
    })
  }, [client, message])

  const onClose = useCallback(() => {
    setClosed(true)
    dismissSuggestion()
  }, [dismissSuggestion])

  const onCreateNewChat = useCallback(() => {
    setShowCreateChatModal(true)
  }, [])

  const onHideCreateChatModal = useCallback(() => {
    setShowCreateChatModal(false)
  }, [])

  const onGoToChannel = useCallback(
    async (channel: Channel<StreamChatType<TopicChannel>>) => {
      await channel.watch()
      if (isChannelArchived(channel.id)) {
        dispatchEvent(SHOW_ARCHIVED_SIDEBAR_EVENT)
      }

      if (message?.text) {
        await channel.sendMessage({
          text: message.text,
          createNewChatSuggestionDismissed: true,
        })
      }
      setActiveChannel(channel)
      dismissSuggestion()
    },
    [dismissSuggestion, message.text, setActiveChannel, isChannelArchived]
  )

  const shouldHide = useMemo(
    () => !entity || closed || message.createNewChatSuggestionDismissed,
    [closed, entity, message.createNewChatSuggestionDismissed]
  )

  return {
    onClose,
    onCreateNewChat,
    onGoToChannel,
    shouldHide,
    showCreateChatModal,
    onHideCreateChatModal,
    members,
    messageText: message.text,
    dismissSuggestion,
  }
}
