import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl, type IntlShape } from 'react-intl'
import * as yup from 'yup'
import uniq from 'lodash/uniq'

import Toast from 'components/Toast'
import HoldingsService from 'api/HoldingsService'
import { AddHoldingFormId } from 'components/AddHoldingModal/types'
import { StaticDropdownCell } from 'components/Spreadsheet/CellTemplates/CustomStaticDropdownCellTemplate'
import { HoldingType } from 'utils/types/company'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { CustomTextCell, RowNumberCell } from 'components/Spreadsheet/types'
import { WebsiteCell } from 'components/OnboardingModal/components/SetupYourPipelineStep/components/AddHoldingSpreadsheet/components/WebsiteCellTemplate/WebsiteCellTemplate'
import { mapToBulkImportBackendRequest } from 'components/AddHoldingModal/ImportHoldingsModal/utils'
import {
  getHoldingsErrorsFromDrafts,
  hasBulkImportServerErrors,
} from 'components/AddHoldingModal/errors'
import { IndexPortfolio } from 'utils/types/portfolios'
import { useFirstPipeline } from 'components/OnboardingModal/hooks/useFirstPipeline'
import { useChangePortfolioNameMutation } from 'utils/hooks/queries/usePortfolioQuery'
import HOLDINGS_SPREADSHEET_TEMPLATE_URL from 'assets/holdings_spreadsheet_template.csv'
import { download } from 'utils/functions/files'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'
import PortfolioService from 'api/PortfolioService'
import { HoldingNameCell } from './components/AddHoldingSpreadsheet/components/HoldingNameCellTemplate/HoldingNameCellTemplate'
import { useHoldingsSpreadsheetContext } from './components/AddHoldingSpreadsheet/HoldingsSpreadsheetContext'
import {
  CellType,
  Columns,
  GridType,
  HoldingsSpreadsheetEvents,
  isEmptyRow,
  validateRow,
} from './components/AddHoldingSpreadsheet/useAddHoldingsSpreadsheet'

const rowHasValidHoldingToCreate = (row: CellType[]) => {
  const rowNumberCell = row[Columns.RowNumber.index] as RowNumberCell
  const typeCell = row[Columns.Type.index] as StaticDropdownCell<HoldingType>

  return typeCell.option?.value && !rowNumberCell.errors.size
}

const rowHasAutoFilledHolding = (row: CellType[]) => {
  const nameCell = row[Columns.Name.index] as HoldingNameCell
  return !!nameCell.holding
}

const getPipelineNameSchema = (intl: IntlShape) =>
  yup
    .string()
    .required(intl.formatMessage({ id: 'general.requiredField' }))
    .max(
      MAX_COUNTER_CHARACTERS,
      intl.formatMessage(
        { id: 'general.youHaveExceededCharacterLimit' },
        { limit: MAX_COUNTER_CHARACTERS }
      )
    )

const getHoldingToCreate = (
  row: CellType[],
  index: number
): AddHoldingFormId => {
  const typeCell = row[Columns.Type.index] as StaticDropdownCell<HoldingType>
  const nameCell = row[Columns.Name.index] as CustomTextCell
  const legalEntityNameCell = row[
    Columns.LegalEntityName.index
  ] as CustomTextCell
  const pointOfContactCell = row[Columns.PointOfContact.index] as CustomTextCell
  const websiteCell = row[Columns.Website.index] as WebsiteCell
  const fundManagerNameCell = row[
    Columns.FundManagerName.index
  ] as CustomTextCell
  const id = `holdingId-${index}`

  if (typeCell.option!.value === HoldingType.COMPANY) {
    return {
      id,
      type: typeCell.option!.value,
      company: {
        legalEntityName: legalEntityNameCell.text,
        name: nameCell.text,
        website: websiteCell.text,
        pointOfContact: pointOfContactCell.text,
      },
    }
  }

  return {
    id,
    type: typeCell.option!.value,
    funds: {
      funds: [nameCell.text],
      includeFundManager: !!fundManagerNameCell.text,
      ...(!!fundManagerNameCell.text && {
        fundManager: {
          name: fundManagerNameCell.text,
          website: websiteCell.text,
          pointOfContact: pointOfContactCell.text,
        },
      }),
    },
  }
}

const createNewHoldings = async (
  holdingsToCreate: AddHoldingFormId[],
  holdingIdsToAddToPortfolio: string[],
  toCreateIndexMap: Map<number, number>
): Promise<boolean> => {
  const validatedHoldings = await HoldingsService.validateBulkImport(
    holdingsToCreate.map((holding, index) =>
      mapToBulkImportBackendRequest(holding, index)
    )
  )

  const { errors, status } = await getHoldingsErrorsFromDrafts(
    validatedHoldings,
    holdingsToCreate
  )

  if (hasBulkImportServerErrors(errors, holdingsToCreate)) {
    const holdingIdsToFetch: string[] = []
    status.holdings.forEach((suggestionError) => {
      const holdingId = suggestionError.companyErrors?.website?.id

      if (holdingId) {
        holdingIdsToFetch.push(holdingId)
      }
    })

    const fetchedDuplicatedHoldings = await HoldingsService.getHoldings({
      page: 1,
      companiesPerPage: holdingIdsToFetch.length,
      filters: {
        idIn: holdingIdsToFetch,
      },
    })

    status.holdings.forEach((suggestionError) => {
      const holdingId = suggestionError.companyErrors?.website?.id

      if (suggestionError.companyErrors?.website && holdingId) {
        const duplicatedHolding = fetchedDuplicatedHoldings.holdings.find(
          (holding) => holding.id === holdingId
        )

        // eslint-disable-next-line no-param-reassign
        suggestionError.companyErrors.website.holding = duplicatedHolding
      }
    })

    dispatchEvent(HoldingsSpreadsheetEvents.HoldingsBulkImportDraftErrors, {
      errors: status,
      indexMap: toCreateIndexMap,
    })

    return false
  }

  const holdingsBulkPayload = holdingsToCreate.map((holding, index) =>
    mapToBulkImportBackendRequest(holding, index)
  )
  const createdHoldingsIds = await HoldingsService.bulkCreateHoldings(
    holdingsBulkPayload
  )
  holdingIdsToAddToPortfolio.push(...createdHoldingsIds)

  return true
}

const addHoldingsToPortfolio = async (
  pipeline: IndexPortfolio,
  holdingIdsToAddToPortfolio: string[]
) => {
  const holdingIdsWithoutDuplicates = uniq(
    holdingIdsToAddToPortfolio.filter(
      (holdingId) =>
        !pipeline!.portfolioCompanies.find(
          (company) => company.id === holdingId
        )
    )
  )
  await PortfolioService.addHoldingsToPortfolio(
    pipeline!,
    holdingIdsWithoutDuplicates
  )
}

export interface SetupYourPipelineStepProps {
  completeStep: () => void
  goToNextStep: () => void
  preFetchedPipeline?: IndexPortfolio
}

export const useSetupYourPipelineStep = ({
  completeStep,
  goToNextStep,
  preFetchedPipeline,
}: SetupYourPipelineStepProps) => {
  const intl = useIntl()
  const { grid: contextGrid, setGrid: setContextGrid } =
    useHoldingsSpreadsheetContext()
  const { mutateAsync: changePortfolioNameMutation } =
    useChangePortfolioNameMutation()
  const { firstPipeline } = useFirstPipeline()
  const prevPortfolio = useRef<IndexPortfolio | undefined>()
  const pipeline: IndexPortfolio | undefined = useMemo(() => {
    if (prevPortfolio.current) {
      return prevPortfolio.current
    }

    const portfolioToReturn: IndexPortfolio | undefined =
      firstPipeline || preFetchedPipeline
    prevPortfolio.current = portfolioToReturn

    return portfolioToReturn
  }, [preFetchedPipeline, firstPipeline])
  const [loading, setLoading] = useState(false)
  const [newPipelineName, setNewPipelineName] = useState(pipeline?.name || '')
  const [newPipelineNameError, setNewPipelineNameError] = useState('')
  const newPipelineFormError = buildFormError(
    newPipelineNameError,
    ErrorType.ERROR,
    true
  )
  const pipelineNameSchema = useMemo(() => getPipelineNameSchema(intl), [intl])
  const [hasRowsWithErrors, setHasRowsWithErrors] = useState(false)
  const gridRef = useRef<GridType | undefined>(contextGrid)
  const shouldDisableContinue = useMemo(
    () => !pipeline || hasRowsWithErrors,
    [pipeline, hasRowsWithErrors]
  )

  useEffect(() => {
    if (pipeline) {
      setNewPipelineName(
        (prevPipelineName) => prevPipelineName || pipeline.name
      )
    }
  }, [pipeline])

  const hasInvalidRows = useCallback(() => {
    const grid = gridRef.current

    if (grid) {
      for (let i = 1; i < grid.length; i++) {
        const { errors } = grid[i][Columns.RowNumber.index] as RowNumberCell

        if (errors.size) {
          return true
        }
      }
    }

    return false
  }, [])

  const isGridValid = useCallback(async () => {
    const grid = gridRef.current

    if (!grid) {
      return false
    }

    for (let i = 1; i < grid.length; i++) {
      const row = grid[i]
      validateRow(row)
    }

    const hasErrors = hasInvalidRows()

    if (hasErrors) {
      setHasRowsWithErrors(true)
      dispatchEvent(HoldingsSpreadsheetEvents.ValidateGrid)
    }

    return !hasErrors
  }, [hasInvalidRows])

  const onGridChange = useCallback(
    (grid: GridType) => {
      gridRef.current = grid
      setHasRowsWithErrors(hasInvalidRows())
    },
    [hasInvalidRows]
  )

  const onPipelineNameInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPipelineName(event.target.value)
    },
    []
  )

  const onPipelineNameInputBlur = useCallback(() => {
    try {
      pipelineNameSchema.validateSync(newPipelineName)
      setNewPipelineNameError('')
    } catch (error) {
      setNewPipelineNameError(error.message)
    }
  }, [pipelineNameSchema, newPipelineName])

  const changePipelineName = useCallback(async () => {
    try {
      if (newPipelineName !== pipeline!.name) {
        await changePortfolioNameMutation({
          portfolio: pipeline!,
          value: newPipelineName,
        })
      }
    } catch (err) {
      // Ignore error when changing name
    }
  }, [pipeline, newPipelineName, changePortfolioNameMutation])

  const downloadTemplate = useCallback(() => {
    download('holdings_template.csv', HOLDINGS_SPREADSHEET_TEMPLATE_URL)
  }, [])

  const createAndAddHoldingsToPortfolio = useCallback(async () => {
    try {
      setLoading(true)
      const grid = gridRef.current
      const holdingIdsToAddToPortfolio: string[] = []
      const gridIsValid = await isGridValid()

      if (gridIsValid) {
        await changePipelineName()
        const holdingsToCreate: AddHoldingFormId[] = []
        const toCreateIndexMap = new Map<number, number>()

        grid!.forEach((row, index) => {
          if (index > 0 && !isEmptyRow(row)) {
            if (rowHasAutoFilledHolding(row)) {
              const nameCell = row[Columns.Name.index] as HoldingNameCell
              holdingIdsToAddToPortfolio.push(nameCell.holding!.id)
            } else if (rowHasValidHoldingToCreate(row)) {
              toCreateIndexMap.set(index, holdingsToCreate.length)
              holdingsToCreate.push(getHoldingToCreate(row, index))
            }
          }
        })

        let createdHoldingSuccessfully = true

        if (holdingsToCreate.length) {
          createdHoldingSuccessfully = await createNewHoldings(
            holdingsToCreate,
            holdingIdsToAddToPortfolio,
            toCreateIndexMap
          )
        }

        if (createdHoldingSuccessfully) {
          if (holdingIdsToAddToPortfolio.length) {
            await addHoldingsToPortfolio(pipeline!, holdingIdsToAddToPortfolio)
          }

          Toast.displayIntl('addHolding.bulkImportSuccess', 'success')
          completeStep()
          goToNextStep()
          setContextGrid(undefined)
        }
      }
    } catch (err) {
      Toast.displayIntl('spreadsheet.holdings.errors.genericError', 'error')
    } finally {
      setLoading(false)
    }
  }, [
    pipeline,
    changePipelineName,
    isGridValid,
    completeStep,
    goToNextStep,
    setContextGrid,
  ])

  return {
    pipeline,
    newPipelineName,
    onPipelineNameInputChange,
    onPipelineNameInputBlur,
    newPipelineFormError,
    downloadTemplate,
    onGridChange,
    createAndAddHoldingsToPortfolio,
    loading,
    shouldDisableContinue,
  }
}
