import { useMutation } from '@tanstack/react-query'
import GroupService from 'api/GroupService'
import { assertUnreachable } from 'utils/functions/utils'
import { AssistanceCardType } from 'utils/types/onboardingModal'

export type ApiHelpArticle =
  | 'investmentsTooltip'
  | 'updatesTooltip'
  | 'holdingsTooltip'
  | 'filesTooltip'
  | 'chatTooltip'
  | 'trackTooltip'

const getApiHelpArticle = (type: AssistanceCardType): ApiHelpArticle => {
  switch (type) {
    case AssistanceCardType.INVEST_PORTFOLIO:
      return 'investmentsTooltip'
    case AssistanceCardType.PIPELINE:
      return 'trackTooltip'
    case AssistanceCardType.HOLDING:
      return 'holdingsTooltip'
    case AssistanceCardType.UPDATE:
      return 'updatesTooltip'
    case AssistanceCardType.UPLOAD_FILES:
      return 'filesTooltip'
    case AssistanceCardType.CHAT:
      return 'chatTooltip'
    default:
      return assertUnreachable(type as never)
  }
}

export const useHelpArticlesMutation = () => {
  return useMutation(
    ({
      userGroupId,
      helpArticle,
    }: {
      userGroupId: string
      helpArticle: AssistanceCardType
    }) => {
      const apiHelpArticle = getApiHelpArticle(helpArticle)
      return GroupService.updateGroupUser(
        userGroupId,
        {
          setting: {
            visible_fields: {
              [apiHelpArticle]: true,
            },
          },
        },
        false
      )
    }
  )
}
