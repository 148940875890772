import { FormattedMessage } from 'react-intl'

import Modal from 'components/Modal'
import Button from 'ui/Button'
import { Inbound } from 'utils/types/inbounds'

import { Description, Question, StyledModal } from './DeleteInboundModal.styles'
import { useDeleteInbound } from '../../hooks'

interface Props {
  show: boolean
  onHide: () => void
  inbound: Inbound
}

const DeleteInboundModal = ({ show, onHide, inbound }: Props) => {
  const { onDelete, isLoading } = useDeleteInbound({
    inbound,
    onDeleteSuccess: onHide,
  })

  return (
    <StyledModal show={show} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.Title>
          <FormattedMessage id="inbounds.delete.title" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Question>
          <FormattedMessage
            id="inbounds.delete.areYouSure"
            values={{
              email: inbound.email,
            }}
          />
        </Question>
        <Description>
          <FormattedMessage id="inbounds.delete.description" />
        </Description>
      </Modal.Body>

      <Modal.RowFooter>
        <Button
          onClick={onHide}
          secondary
          data-testid="cancel-delete-inbound-button"
        >
          <FormattedMessage id="common.cancel" />
        </Button>

        <Button
          onClick={onDelete}
          danger
          isLoading={isLoading}
          data-testid="delete-inbound-button"
        >
          <FormattedMessage id="general.delete" />
        </Button>
      </Modal.RowFooter>
    </StyledModal>
  )
}

export default DeleteInboundModal
