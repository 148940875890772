import axiosClient from './httpClient'

class SettingsService {
  static getSettings = async () => {
    const {
      data: {
        entities: { users },
        result: userId,
      },
    } = await axiosClient().get('/settings')

    return users[userId]?.settings
  }

  static dismissMessages = async (messageKey) => {
    await axiosClient().patch('/settings', {
      setting: { dismissMessages: { [messageKey]: true } },
    })
  }

  static updateSettings = async (settings) => {
    const {
      data: {
        entities: { users },
        result: userId,
      },
    } = await axiosClient().patch('/settings', { ...settings })

    return users[userId]?.settings
  }
}

export default SettingsService
