import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import UnreadMessages from 'containers/Chat/components/UnreadMessages'
import {
  DefaultRoutes,
  FounderRoutes,
  FundManagerRoutes,
  InvestorRoutes,
  Routes,
  SetRouteConfigurationPayload,
} from 'reducers/breadcrumbSlice'
import type { IntlShape } from 'react-intl'
import { Nullable } from 'utils/types/common'
import {
  getUserIsOwner,
  isActingAsClient,
  isActingAsFundManager,
  isActingAsInvestorGroup,
} from 'selectors/auth'
import { SHOW_NEW_INVESTMENTS_FEATURE } from 'utils/constants/features'
import { PlanFeatures } from 'utils/hooks/usePlanFeatures'
import { SubscriptionPlan } from 'utils/types/subscriptions'
import { GroupType } from 'utils/types/user'
import { GroupTypes } from '../constants/groups'

export interface SidebarSection {
  url?: string
  icon?: [IconPrefix, IconName] | JSX.Element
  text: string
  isSelected?: boolean
  subSections?: SidebarSection[]
  isLogout?: boolean
  hidden?: boolean
  rightComponent?: React.ReactNode
  breadcrumbSetter?: () => void
}

const addChatFeature = (
  defaultOptions: SidebarSection[],
  baseRoute: Nullable<Routes>,
  intl: IntlShape,
  handleDispatchUserRoutes: (props: SetRouteConfigurationPayload) => void
) => {
  defaultOptions.push({
    url: '/chat',
    icon: ['far', 'comment'],
    text: intl.formatMessage({ id: 'sidebar.chat' }),
    isSelected: baseRoute === DefaultRoutes.CHAT,
    rightComponent: <UnreadMessages />,
    breadcrumbSetter: () =>
      handleDispatchUserRoutes({
        route: DefaultRoutes.CHAT,
        initialLinkRedirect: '/chat',
      }),
  })
}

export const getSidebarOptions = (
  groupType: GroupType,
  intl: IntlShape,
  hasChatFeature: boolean,
  planFeatures: PlanFeatures,
  baseRoute: Nullable<Routes>,
  handleDispatchUserRoutes: (props: SetRouteConfigurationPayload) => void
): SidebarSection[] => {
  const investmentsFeatureEnabled = SHOW_NEW_INVESTMENTS_FEATURE

  const investmentRoute: SidebarSection[] = [
    {
      url: '/investments',
      icon:
        planFeatures.groupPlan === SubscriptionPlan.LITE
          ? ['fal', 'tachometer-alt-average']
          : ['fal', 'briefcase'],
      text:
        planFeatures.groupPlan === SubscriptionPlan.LITE
          ? intl.formatMessage({ id: 'sidebar.dashboard' })
          : intl.formatMessage({ id: 'sidebar.investments' }),
      isSelected:
        baseRoute === FundManagerRoutes.INVESTMENTS ||
        baseRoute === InvestorRoutes.INVESTMENTS ||
        baseRoute === FounderRoutes.dashboard,

      breadcrumbSetter: () =>
        handleDispatchUserRoutes({
          route: FundManagerRoutes.INVESTMENTS,
          initialLinkRedirect: '/investments',
        }),
    },
  ]

  if (groupType === GroupTypes.FUND_MANAGER) {
    const defaultOptions: SidebarSection[] = [
      {
        url: '/dashboard/updates',
        icon: ['fal', 'tachometer-alt-average'],
        text: intl.formatMessage({ id: 'sidebar.dashboard' }),
        isSelected: baseRoute === FundManagerRoutes.INVESTMENTS,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: FundManagerRoutes.INVESTMENTS,
            initialLinkRedirect: '/dashboard/updates',
          }),
      },
      {
        url: '/group-profile/updates',
        icon: ['fal', 'id-card'],
        text: intl.formatMessage({ id: 'sidebar.organizationProfile' }),
        isSelected: baseRoute === FundManagerRoutes.ORGANIZATION_PROFILE,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: FundManagerRoutes.ORGANIZATION_PROFILE,
            initialLinkRedirect: '/group-profile/updates',
          }),
      },
      {
        url: `/investor-management`,
        icon: ['fal', 'comments-alt-dollar'],
        text: intl.formatMessage({ id: 'sidebar.investorRelations' }),
        subSections: [
          {
            url: '/investor-management',
            text: intl.formatMessage({ id: 'sidebar.investorManagement' }),
            isSelected:
              baseRoute === FundManagerRoutes.INVESTOR_MANAGEMENT ||
              baseRoute === FundManagerRoutes.INVESTOR_VEHICLES,
            breadcrumbSetter: () =>
              handleDispatchUserRoutes({
                route: FundManagerRoutes.INVESTOR_MANAGEMENT,
                initialLinkRedirect: '/investor-management/investors',
              }),
          },
          {
            url: `/emails`,
            text: intl.formatMessage({ id: 'sidebar.emails' }),
            isSelected: baseRoute === DefaultRoutes.EMAILS,
            breadcrumbSetter: () =>
              handleDispatchUserRoutes({
                route: DefaultRoutes.EMAILS,
                initialLinkRedirect: '/emails',
              }),
          },
          {
            url: '/updates',
            text: intl.formatMessage({ id: 'sidebar.updates' }),
            isSelected: baseRoute === DefaultRoutes.UPDATES,
            breadcrumbSetter: () =>
              handleDispatchUserRoutes({
                route: DefaultRoutes.UPDATES,
                initialLinkRedirect: '/updates',
              }),
          },
        ],
        breadcrumbSetter: () => {
          const useDefaultRoutes =
            baseRoute === DefaultRoutes.EMAILS ||
            baseRoute === DefaultRoutes.UPDATES

          return handleDispatchUserRoutes({
            route: useDefaultRoutes
              ? baseRoute
              : FundManagerRoutes.INVESTOR_MANAGEMENT,
            initialLinkRedirect: '/investor-management/investors',
          })
        },
      },
      {
        url: '/companies',
        icon: ['fal', 'telescope'],
        text: intl.formatMessage({ id: 'sidebar.discover' }),
        isSelected: baseRoute === FundManagerRoutes.DISCOVER,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: FundManagerRoutes.DISCOVER,
            initialLinkRedirect: '/companies',
          }),
      },
      {
        url: '/metrics/selected',
        icon: ['far', 'analytics'],
        text: intl.formatMessage({ id: 'sidebar.metrics' }),
        isSelected: baseRoute === DefaultRoutes.METRICS,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: DefaultRoutes.METRICS,
            initialLinkRedirect: '/metrics/selected',
          }),
      },
      {
        url: '/files',
        icon: ['fal', 'folder'],
        text: intl.formatMessage({ id: 'sidebar.files' }),
        isSelected: baseRoute === DefaultRoutes.FILES,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: DefaultRoutes.FILES,
            initialLinkRedirect: '/files',
          }),
      },
    ]

    if (hasChatFeature) {
      addChatFeature(defaultOptions, baseRoute, intl, handleDispatchUserRoutes)
    }

    if (investmentsFeatureEnabled) {
      const newOptions = [
        ...investmentRoute,
        ...defaultOptions.filter(
          (option) => option.url !== '/dashboard/updates'
        ),
      ]

      return newOptions.filter((option) => option.url !== '/portfolios')
    }

    return defaultOptions
  }

  if (groupType === GroupTypes.CLIENT) {
    const defaultOptions: SidebarSection[] = [
      {
        url: '/dashboard/updates',
        icon: ['fal', 'tachometer-alt-average'],
        text: intl.formatMessage({ id: 'sidebar.dashboard' }),
        isSelected: baseRoute === FundManagerRoutes.INVESTMENTS,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: FundManagerRoutes.INVESTMENTS,
            initialLinkRedirect: '/dashboard/updates',
          }),
      },
      {
        url: '/updates',
        icon: ['fal', 'comments-alt-dollar'],
        text: 'Updates',
        isSelected: baseRoute === DefaultRoutes.UPDATES,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: DefaultRoutes.UPDATES,
            initialLinkRedirect: '/updates',
          }),
      },
      {
        url: '/companies',
        icon: ['fal', 'telescope'],
        text: intl.formatMessage({ id: 'sidebar.discover' }),
        isSelected: baseRoute === FundManagerRoutes.DISCOVER,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: FundManagerRoutes.DISCOVER,
            initialLinkRedirect: '/companies',
          }),
      },
      {
        url: '/metrics/selected',
        icon: ['fal', 'analytics'],
        text: intl.formatMessage({ id: 'sidebar.metrics' }),
        isSelected: baseRoute === DefaultRoutes.METRICS,
        hidden: !planFeatures.areMetricsEnabled,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: DefaultRoutes.METRICS,
            initialLinkRedirect: '/metrics/selected',
          }),
      },
      {
        url: '/files',
        icon: ['fal', 'folder'],
        text: intl.formatMessage({ id: 'sidebar.files' }),
        isSelected: baseRoute === DefaultRoutes.FILES,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: DefaultRoutes.FILES,
            initialLinkRedirect: '/files',
          }),
      },
    ]

    if (hasChatFeature) {
      addChatFeature(defaultOptions, baseRoute, intl, handleDispatchUserRoutes)
    }

    if (investmentsFeatureEnabled) {
      const newOptions = [
        ...investmentRoute,
        ...defaultOptions.filter(
          (option) => option.url !== '/dashboard/updates' && !option.hidden
        ),
      ]

      return newOptions.filter((option) => option.url !== '/portfolios')
    }

    return defaultOptions
  }

  if (groupType === GroupTypes.INVESTOR_GROUP) {
    return [
      {
        url: `/investments`,
        icon: ['fal', 'tachometer-alt-average'],
        text: intl.formatMessage({ id: 'sidebar.dashboard' }),
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: InvestorRoutes.INVESTMENTS,
            initialLinkRedirect: '/investments',
          }),
        isSelected: baseRoute === InvestorRoutes.INVESTMENTS,
      },
      {
        url: `/profile`,
        icon: ['fal', 'id-card'],
        text: intl.formatMessage({ id: 'sidebar.investorProfile' }),
        isSelected: baseRoute === InvestorRoutes.INVESTOR_PROFILE,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: InvestorRoutes.INVESTOR_PROFILE,
            initialLinkRedirect: '/profile',
          }),
      },
      {
        url: `/fund-holdings`,
        icon: ['fal', 'building'],
        text: intl.formatMessage({ id: 'sidebar.holdings' }),
        isSelected: baseRoute === InvestorRoutes.HOLDINGS,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: InvestorRoutes.HOLDINGS,
            initialLinkRedirect: '/fund-holdings',
          }),
      },
      {
        url: `/files`,
        icon: ['fal', 'folder'],
        text: intl.formatMessage({ id: 'sidebar.fundFiles' }),
        isSelected: baseRoute === DefaultRoutes.FILES,
        breadcrumbSetter: () =>
          handleDispatchUserRoutes({
            route: DefaultRoutes.FILES,
            initialLinkRedirect: '/files',
          }),
      },
    ]
  }

  return [
    {
      url: `/dashboard/updates`,
      icon: ['fal', 'tachometer-alt-average'],
      text: 'Dashboard',
      isSelected: baseRoute === FounderRoutes.dashboard,
      breadcrumbSetter: () =>
        handleDispatchUserRoutes({
          route: FounderRoutes.dashboard,
          initialLinkRedirect: '/dashboard',
        }),
    },
    {
      url: '/updates',
      icon: ['fal', 'comments-alt-dollar'],
      text: 'Updates',
      isSelected: baseRoute === DefaultRoutes.UPDATES,
      breadcrumbSetter: () =>
        handleDispatchUserRoutes({
          route: DefaultRoutes.UPDATES,
          initialLinkRedirect: '/updates',
        }),
    },
    {
      url: `/emails`,
      icon: ['fal', 'envelope'],
      text: 'Emails',
      isSelected: baseRoute === DefaultRoutes.EMAILS,
      breadcrumbSetter: () =>
        handleDispatchUserRoutes({
          route: DefaultRoutes.EMAILS,
          initialLinkRedirect: '/emails',
        }),
    },
    {
      url: '/metrics/all',
      icon: ['fal', 'analytics'],
      text: 'Metrics',
      isSelected: baseRoute === DefaultRoutes.METRICS,
      breadcrumbSetter: () =>
        handleDispatchUserRoutes({
          route: DefaultRoutes.METRICS,
          initialLinkRedirect: '/metrics/all',
        }),
    },
    {
      url: '/files',
      icon: ['fal', 'folder'],
      text: intl.formatMessage({ id: 'sidebar.files' }),
      isSelected: baseRoute === DefaultRoutes.FILES,
      breadcrumbSetter: () =>
        handleDispatchUserRoutes({
          route: DefaultRoutes.FILES,
          initialLinkRedirect: '/files',
        }),
    },
  ]
}

export const groupSettingsOptions = (
  appState,
  intl: IntlShape,
  planFeatures: PlanFeatures
): SidebarSection[] => {
  const isFundManager = isActingAsFundManager(appState)
  const isClient = isActingAsClient(appState)
  const isInvestorGroup = isActingAsInvestorGroup(appState)
  const isOwner = getUserIsOwner(appState)

  return [
    {
      text: intl.formatMessage({ id: 'sidebar.generalInformation' }),
      url: 'general',
      icon: ['fal', 'info-square'],
      hidden: isInvestorGroup,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.groupManagement' }),
      url: 'users',
      icon: ['fal', 'users'],
      hidden: isInvestorGroup || !planFeatures.isGroupManagementEnabled,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.listManagement' }),
      url: 'lists',
      icon: ['fal', 'address-book'],
      hidden: isInvestorGroup,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.subscriptionsAndBilling' }),
      url: 'subscriptions',
      icon: ['fal', 'file-invoice-dollar'],
      hidden: !isOwner || (!isClient && !isFundManager),
    },
    {
      text: intl.formatMessage({ id: 'sidebar.storage' }),
      url: '/settings/group_management/storage',
      icon: ['fal', 'database'],
      hidden: isInvestorGroup,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.integrations' }),
      url: 'integrations',
      icon: ['fal', 'plug'],
      hidden: isClient || isInvestorGroup,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.inbounds' }),
      url: 'inbounds',
      icon: ['fal', 'sign-in'],
      hidden: isInvestorGroup,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.customLogin' }),
      url: 'custom-login',
      icon: ['fal', 'palette'],
      hidden: !isFundManager || !isOwner || isInvestorGroup,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.generalInformation' }),
      url: '/settings/group_management/general',
      icon: ['fal', 'info-square'],
      hidden: !isInvestorGroup,
    },
    {
      text: intl.formatMessage({ id: 'sidebar.groupMembers' }),
      url: '/settings/group_management/users',
      icon: ['fal', 'users'],
      hidden: !isInvestorGroup,
    },
  ]
    .filter(({ hidden }) => !hidden)
    .map(({ hidden, ...rest }) => rest as SidebarSection)
}

export const accountSettingsOptions = (
  appState,
  intl,
  userHasOnlyInvestorGroups: boolean,
  planFeatures: PlanFeatures
): SidebarSection[] => {
  const isInvestorGroup = isActingAsInvestorGroup(appState)

  const options: SidebarSection[] = [
    {
      text: intl.formatMessage({ id: 'sidebar.userInformation' }),
      url: 'general',
      icon: ['fal', 'id-card'],
    },
    {
      text: intl.formatMessage({ id: 'sidebar.loginAndSecurity' }),
      url: 'login-and-security',
      icon: ['fal', 'lock'],
    },
    {
      text: intl.messages['sidebar.privacy'],
      url: 'privacy',
      icon: ['fal', 'lock'],
    },
  ]

  if (!userHasOnlyInvestorGroups) {
    options.push({
      text: intl.formatMessage({ id: 'sidebar.notifications' }),
      url: 'notifications',
      icon: ['fal', 'bell'],
      hidden: isInvestorGroup,
    })
    if (planFeatures.maxUsers > 1) {
      options.push({
        text: intl.formatMessage({ id: 'sidebar.updateSettings' }),
        url: 'update-settings',
        icon: ['fal', 'file-lock'],
      })
    }
  }

  options.push({
    text: intl.formatMessage({ id: 'sidebar.logOut' }),
    url: '/sign-out',
    icon: ['fal', 'power-off'],
    isLogout: true,
  })

  return options
}
