import React from 'react'
import { StyledRow } from './Row.styles'

interface RowProps {
  children: React.ReactNode
  className?: string
}

const Row: React.FC<RowProps> = ({ children, className }) => {
  return <StyledRow className={className}>{children}</StyledRow>
}

export default Row
