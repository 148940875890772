import { Helmet } from 'components/Helmet'
import { Formik } from 'formik'
import { useIntl } from 'react-intl'
import {
  CreateEmailFormValues,
  UpdateEmailContext,
} from 'utils/types/emailForm'
import Form from 'components/Form/Form'
import CreateEmailForm from './CreateEmailForm'
import useCreateEmail from './useCreateEmail'

const CreateEmail = () => {
  const intl = useIntl()
  const { emailValues, schema, formikRef, emailUpdate, createEmail } =
    useCreateEmail({
      intl,
    })

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'metaTitles.createEmail' })}</title>
      </Helmet>
      {emailValues && (
        <Formik<CreateEmailFormValues>
          initialValues={emailValues}
          validationSchema={schema}
          onSubmit={(values) => createEmail(values)}
          innerRef={formikRef}
        >
          <Form<UpdateEmailContext> emailUpdate={emailUpdate!}>
            <CreateEmailForm />
          </Form>
        </Formik>
      )}
    </>
  )
}

export default CreateEmail
