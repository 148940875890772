import React from 'react'
import { FormattedMessage } from 'react-intl'
import Heading from 'ui/Heading'
import { StyledBody, StyledSection } from './Section.styles'

interface SectionProps {
  children: React.ReactNode
  className?: string
  sectionClassName?: string
  title?: string
  titleValues?: Record<string, React.ReactNode>
  socialHeader?: React.ReactNode
  width?: string
}

const Section: React.FC<SectionProps> = ({
  children,
  className,
  sectionClassName,
  title,
  titleValues,
  socialHeader,
  width,
}) => {
  return (
    <StyledSection width={width} className={sectionClassName}>
      {socialHeader}
      {title && (
        <Heading level="h4">
          <FormattedMessage id={title} values={titleValues} />
        </Heading>
      )}
      {children && <StyledBody className={className}>{children}</StyledBody>}
    </StyledSection>
  )
}

export default Section
