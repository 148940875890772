import type { AscDesc, Channel } from 'stream-chat'
import { ChannelList, InfiniteScroll } from 'stream-chat-react'

import { StreamChatType, TopicChannel } from 'containers/Chat/types'
import { Update } from 'utils/types/update'
import { TopicEntity } from 'utils/types/chatTopicEntity'
import { getUpdateItemTitle } from 'utils/functions/updates'

import EmptyComponent from '../../EmptyComponent'
import CreateChatModal from '../../CreateChat'
import PreviewChannel from '../components/PreviewChannel'
import NoChats from '../components/NoChats'
import { UseChatWidgetType } from '../useChatWidget'
import { Container } from './UpdateChatWidget.styles'

const options = { state: true, presence: true, limit: 10 }
const sort = { last_message_at: -1 as AscDesc }

interface UpdateChatWidgetProps extends UseChatWidgetType {
  topic?: TopicEntity
  update?: Update
  showCreateChatModal: boolean
  onHideCreateChatModal: () => void
  openChatAsPopup: (channel: Channel<StreamChatType>) => void
}

const UpdateChatWidget = ({
  renderChannels,
  filters,
  showCreateChatModal,
  onHideCreateChatModal,
  topic,
  update,
  openChatAsPopup,
}: UpdateChatWidgetProps) => {
  return (
    <Container>
      <ChannelList<StreamChatType<TopicChannel>>
        renderChannels={renderChannels}
        filters={filters}
        sort={sort}
        options={options}
        EmptyStateIndicator={NoChats}
        LoadingIndicator={EmptyComponent}
        Preview={PreviewChannel}
        Paginator={InfiniteScroll}
      />
      {topic && (
        <CreateChatModal
          preventNavigationOnCreate
          show={showCreateChatModal}
          onHide={onHideCreateChatModal}
          onChatCreated={openChatAsPopup}
          initialValues={{
            members: [],
            topic,
            update,
            title: getUpdateItemTitle(update),
          }}
        />
      )}
    </Container>
  )
}

export default UpdateChatWidget
