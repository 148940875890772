import React from 'react'
import { FormattedMessage } from 'react-intl'
import { sortBy } from 'lodash'
import { motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { listAnimation } from 'utils/animations/list'
import { gridItemAnimation } from 'utils/animations/grid'
import { formattedDate } from 'utils/functions/date'
import { ContentGroupType, LoggingUpdateContent } from 'utils/types/files'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import UpdateIconFactory from 'components/UpdateIconFactory'
import Tooltip from 'components/Tooltip'
import Contents from './Contents'

import * as Styles from './Updates.styles'

interface UpdatesProps {
  updates: LoggingUpdateContent[]
  group?: ContentGroupType
}

interface UpdateProps {
  update: LoggingUpdateContent
  group?: ContentGroupType
}

const Update: React.FC<UpdateProps> = ({ update, group }) => {
  const sortedContents = sortBy(update.contents, ['fileName'])
  const { handleOnUpdateClick } = useOnClickRedirect()

  const handleOnClick = () => {
    return handleOnUpdateClick({ update }).redirectFunction()
  }

  return (
    <motion.li key={update.id} variants={gridItemAnimation}>
      <Styles.UpdateCard shadow>
        <Styles.Header>
          <Styles.UpdateLogo>
            <UpdateIconFactory
              updateType={update.type}
              withGroup={group}
              showSimpleIcon
            />
          </Styles.UpdateLogo>
          <div>
            <Tooltip
              id={`file_details_update_${update.title}`}
              text={update.title}
              place="bottom"
              delayShow={1000}
            >
              <Styles.UpdateTitle>{update.title}</Styles.UpdateTitle>
            </Tooltip>
            <Styles.UpdateDate>
              {formattedDate(update.createdAt)}
            </Styles.UpdateDate>
          </div>
        </Styles.Header>
        <Styles.ContentsWrapper>
          <Styles.Contents>
            <Contents contents={sortedContents} />
          </Styles.Contents>
          <button type="button" onClick={handleOnClick} className="update-link">
            <FontAwesomeIcon
              icon={['far', 'chevron-right']}
              className="chevron"
              data-testid="update-link"
            />
          </button>
        </Styles.ContentsWrapper>
      </Styles.UpdateCard>
    </motion.li>
  )
}

const Updates: React.FC<UpdatesProps> = ({ updates, group }) => {
  return (
    <>
      <Styles.Title>
        <FormattedMessage id="files.details.includeInUpdateTitle" />
      </Styles.Title>
      <motion.ul initial="hidden" animate="visible" variants={listAnimation}>
        <Styles.Updates>
          {updates.map((update) => (
            <Update update={update} group={group} />
          ))}
        </Styles.Updates>
      </motion.ul>
    </>
  )
}

export default Updates
