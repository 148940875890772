import { ApiEnv } from 'features/preferenceSlice'

export const DEV_ENV = process.env.REACT_APP_CUSTOM_NODE_ENV === ApiEnv.DEVELOP

export const PROD_ENV =
  process.env.REACT_APP_CUSTOM_NODE_ENV === ApiEnv.PRODUCTION

export const STAGING_ENV =
  process.env.REACT_APP_CUSTOM_NODE_ENV === ApiEnv.STAGING

export const getGoogleApiKey = (): string =>
  process.env.REACT_APP_GOOGLE_API_KEY!

export const getGoogleReCaptchaKey = (): string =>
  process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY!

export const getDevEnvironment = (): string =>
  process.env.REACT_APP_DEV_ENVIRONMENT || ''

export const getEnvironment = (): ApiEnv => {
  switch (process.env.REACT_APP_CUSTOM_NODE_ENV) {
    case 'development':
      return ApiEnv.DEVELOP
    case 'testing':
      return ApiEnv.TESTING
    case 'staging':
      return ApiEnv.STAGING
    case 'production':
      return ApiEnv.PRODUCTION

    default:
      throw new Error('Invalid REACT_APP_CUSTOM_NODE_ENV environment variable')
  }
}

export const getStripePublicKey = (): string =>
  process.env.REACT_APP_STRIPE_PUBLIC_KEY!

export const getStreamChatApiKey = (): string =>
  process.env.REACT_APP_STREAM_CHAT_API_KEY!

export const getDesktopProtocol = (): string =>
  process.env.REACT_APP_DESKTOP_PROTOCOL!

export const getWebAppUrl = (): string => process.env.REACT_APP_WEB_APP_URL!
