import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import * as Styles from './SuggestedMessageBox.styles'

type SuggestedMessageBoxProps = {
  text: string | React.ReactNode
  onConfirm?: () => void
  onCancel?: () => void
  onClose?: () => void
  confirmLabelIntlKey?: string
  cancelLabelIntlKey?: string
}

const SuggestedMessageBox: React.FC<SuggestedMessageBoxProps> = ({
  text,
  onConfirm,
  onCancel,
  onClose = onCancel,
  confirmLabelIntlKey = 'common.yes',
  cancelLabelIntlKey = 'common.no',
}) => (
  <Styles.Container>
    <Styles.Visibility>
      <FontAwesomeIcon icon={['far', 'eye']} />
      <FormattedMessage id="chat.messageOnlyVisibleToYou" />
    </Styles.Visibility>
    <div>
      {text}
      {onConfirm && (
        <Styles.ButtonsContainer>
          <Styles.ThinButton primary onClick={onConfirm}>
            <FormattedMessage id={confirmLabelIntlKey} />
          </Styles.ThinButton>
          <Styles.ThinButton onClick={onCancel}>
            <FormattedMessage id={cancelLabelIntlKey} />
          </Styles.ThinButton>
        </Styles.ButtonsContainer>
      )}
      <Styles.CloseIcon icon={['far', 'times']} onClick={onClose} />
    </div>
  </Styles.Container>
)

export default SuggestedMessageBox
