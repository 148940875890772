import styled, { css } from 'styled-components'
import Modal from 'components/Modal'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import Chip from 'components/Chip'

export const Portfolios = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.6rem;
  overflow-y: auto;
  max-height: 20rem;

  & > * {
    margin: 0 0.5rem 0.5rem 0;
  }
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 0.8rem;
`

export const AddInputContainer = styled.div`
  @media ${maxSize.lg} {
    width: 100%;
  }

  div[class*='AddInputAutosuggest_inputWrapper'] {
    margin-bottom: 0rem;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  div[class*='PortfolioIcon_portfolioIcon'] {
    margin-right: 1.5rem;

    > svg {
      fill: var(--color-white);
    }
  }
`

export const AddPortfolioModal = styled(Modal)`
  max-width: 50rem;

  @media ${size.md} {
    width: 50rem;
  }
`

export const CustomChip = styled(Chip)`
  padding: 0 1rem 0 0;
`

const chipIconStyles = css`
  background-color: ${color('primaryBlue')};
  border-radius: 2.4rem;
  color: ${color('white')};
  font-size: 1.1rem;
  margin-right: 0.6rem;
  height: 2.1rem;
  width: 2.1rem;
`

export const ChipIconAsButton = styled.button`
  ${chipIconStyles}
`

export const ChipIconAsDiv = styled.div`
  ${chipIconStyles}

  display: flex;
  align-items: center;
  justify-content: center;
`
