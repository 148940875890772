import CompanyTeamMember from 'components/CompanyTeamMember'
import { Employee } from 'utils/types/company'
import { Wrapper } from './CompanyKeyMembers.styles'

interface Props {
  content: Employee[]
}

const CompanyKeyPeople = ({ content }: Props) => {
  return (
    <Wrapper>
      {content.map((keyMember) => (
        <CompanyTeamMember
          key={keyMember.firstName}
          isCompanyProfile
          employee={keyMember}
        />
      ))}
    </Wrapper>
  )
}

export default CompanyKeyPeople
