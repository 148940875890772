import { FormattedMessage } from 'react-intl'
import type { StreamMessage } from 'stream-chat-react'
import {
  MessageTimestamp as Timestamp,
  useMessageContext,
} from 'stream-chat-react'
import { StreamChatType } from 'containers/Chat/types'
import { Container } from './MessageTimestamp.styles'
import { useMessageTimestamp } from './useMessageTimestamp'

interface Props {
  showOnlyTime?: boolean
  showToday?: boolean
  message?: StreamMessage<StreamChatType>
  className?: string
}

const MessageTimestamp = ({
  showOnlyTime,
  message: messageProp,
  showToday = true,
  className,
}: Props) => {
  const { message: messageContext } = useMessageContext<StreamChatType>()
  const message = messageProp || messageContext

  const { created_at } = message

  const { isCreationDateToday, isJustNow, format } = useMessageTimestamp(
    created_at!,
    !!showOnlyTime
  )

  const isTodayLabelVisible = isCreationDateToday && showToday && !showOnlyTime

  return (
    <Container className={className}>
      {isJustNow && !showOnlyTime ? (
        <FormattedMessage id="chat.justNow" />
      ) : (
        <>
          {isTodayLabelVisible && <FormattedMessage id="chat.today" />}
          <Timestamp format={format} message={message} />
        </>
      )}
    </Container>
  )
}

export default MessageTimestamp
