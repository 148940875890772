import { useMemo } from 'react'
import { useChatContext, useChannelStateContext } from 'stream-chat-react'
import type { Channel } from 'stream-chat'
import orderBy from 'lodash/orderBy'

import {
  ChannelRole,
  ChannelType,
  isTopicChannel,
  StreamChatType,
} from 'containers/Chat/types'

export const useChatMembers = (channel?: Channel<StreamChatType>) => {
  const { client } = useChatContext()
  const { channel: contextChannel } = useChannelStateContext<StreamChatType>()

  const channelToUse = channel || contextChannel

  const isDirectMessage = channelToUse.type === ChannelType.DIRECT_MESSAGE

  const isUserPersonalChat = useMemo(() => {
    const name = isTopicChannel(channelToUse.data) ? channelToUse.data.name : ''

    const stateMembers = Object.values(channelToUse.state.members)

    return (
      stateMembers.length === 1 &&
      stateMembers[0].user?.id === client.userID &&
      !name
    )
  }, [channelToUse.data, channelToUse.state.members, client.userID])

  const members = useMemo(() => {
    const stateMembers = Object.values(channelToUse.state.members)

    const membersArray = isUserPersonalChat
      ? stateMembers
      : stateMembers.filter(({ user }) => user?.id !== client.userID)

    return orderBy(
      membersArray,
      [(member) => member.user?.name?.toLowerCase()],
      'asc'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelToUse.data?.members?.length, client.userID, isUserPersonalChat])

  const membersNames = useMemo(
    () => members.map((member) => member.user?.name).join(', '),
    [members]
  )

  const userChannelRole = useMemo(
    () =>
      channelToUse.state.members[client.userID!]?.channel_role! as ChannelRole,
    [channelToUse.state.members, client.userID]
  )

  return {
    userChannelRole,
    isChannelModerator: userChannelRole === ChannelRole.CHANNEL_MODERATOR,
    isChannelMember: userChannelRole === ChannelRole.CHANNEL_MEMBER,
    members,
    membersNames,
    isUserPersonalChat,
    isDirectMessage,
  }
}
