export const QUERIES = {
  HOLDINGS_AND_PORTFOLIOS: 'holdings_and_portfolios',
  HOLDINGS_AND_PORTFOLIOS_BY_ID: 'holdings_and_portfolios_by_id',
} as const

export const holdingsAndPortfolios = {
  all: (holdings: boolean, portfolios: boolean, search: string) =>
    [QUERIES.HOLDINGS_AND_PORTFOLIOS, holdings, portfolios, search] as const,
  byId: (id: string) => [QUERIES.HOLDINGS_AND_PORTFOLIOS_BY_ID, id] as const,
}
