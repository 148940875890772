import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/Button'
import LoginField from '../LoginField'
import Separator from '../Separator'
import ForgotPasswordLink from '../ForgotPasswordLink'
import SignupLink from '../SignupLink'

import styles from './Form.module.scss'

const Form = ({ children, onSubmit, className }) => {
  return (
    <form
      onSubmit={onSubmit}
      className={classNames(styles.loginFormContent, className)}
    >
      {children}
    </form>
  )
}

Form.Field = LoginField
Form.Button = Button
Form.Separator = Separator
Form.ForgotPasswordLink = ForgotPasswordLink
Form.SignupLink = SignupLink

Form.defaultProps = {
  className: '',
  onSubmit: () => {},
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  className: PropTypes.string,
}

export default Form
