import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ApiEntity } from 'api/types'
import { parseUpdateType } from 'utils/constants/updates'
import {
  FileContentApi,
  IndexFileContentApi,
  FileContentDetailsApi,
  LoggingUpdateApi,
  IndexEmailContentApi,
  EmailContentDetailsApi,
  EmailContentApi,
} from 'utils/types/api/files'
import { GroupApi } from 'utils/types/api/groups'
import { UserApi } from 'utils/types/api/user'
import {
  FileContent,
  IndexFileContent,
  FileContentDetails,
  FileExtension,
  MixedContent,
  IndexEmailContent,
  ContentUserType,
  EmailContentDetails,
  EmailContent,
  AttachedEmailFileContent,
} from 'utils/types/files'
import { Inbound } from 'utils/types/inbounds'
import { PlainUpdateType } from 'utils/types/common'
import { Holding, Sector } from 'utils/types/company'
import { Tag } from 'utils/types/update'
import { assertUnreachable } from '../utils'
import { getColorByPlainUpdateType, getIconByPlainUpdateType } from '../updates'

export type S3ContentsEntities = {
  s3Contents: ApiEntity<FileContentApi>
}

export type ContentsEntities = {
  contents: ApiEntity<FileContentApi>
}

type GenericContentsEntities = S3ContentsEntities | ContentsEntities

export interface UpdateTypesOption {
  id: string
  type: PlainUpdateType
  active: boolean
  icon: IconProp
  color: string
}

function isS3ContentEntity(
  genericContentEntity: GenericContentsEntities
): genericContentEntity is S3ContentsEntities {
  return !!(genericContentEntity as S3ContentsEntities).s3Contents
}

function isContentEntity(
  genericContentEntity: GenericContentsEntities
): genericContentEntity is ContentsEntities {
  return !!(genericContentEntity as ContentsEntities).contents
}

export class FileContentNormalizer {
  static populateFileContent(
    content: FileContentApi
  ): FileContent | AttachedEmailFileContent {
    return {
      ...content,
      fileFormat: (content.fileFormat?.toLowerCase() ??
        FileExtension.UNKNOWN) as FileExtension,
    }
  }

  static populateEmailContent(content: EmailContentApi): EmailContent {
    return {
      ...content,
      s3Contents:
        (content.s3Contents?.map(
          this.populateFileContent
        ) as AttachedEmailFileContent[]) ?? [],
    }
  }

  private static populateLoggingUpdate(
    loggingUpdatesIds: string[],
    loggingUpdates: ApiEntity<LoggingUpdateApi>
  ) {
    return loggingUpdatesIds.map((updateId) => {
      const update = loggingUpdates[updateId]
      return {
        ...update,
        contents: [
          ...update.s3Contents.map(this.populateFileContent),
          ...update.emailContents.map(this.populateEmailContent),
        ],
        type: parseUpdateType(update.type),
        subjectMatterType: update.subjectMatterInfo.type,
        subjectMatterId: update.subjectMatterInfo.id,
      }
    })
  }

  private static populateUser(user?: UserApi): ContentUserType | undefined {
    return user
      ? {
          id: user.id,
          name: user.name,
          image: user.image,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
        }
      : undefined
  }

  static normalizeFileContent<
    T extends GenericContentsEntities = S3ContentsEntities
  >(id: string, entities: T): FileContent {
    if (isS3ContentEntity(entities)) {
      return this.populateFileContent(entities.s3Contents[id])
    }

    if (isContentEntity(entities)) {
      return this.populateFileContent(entities.contents[id])
    }

    return assertUnreachable(entities)
  }

  static normalizeIndexFileContentFromEntities(
    id: string,
    entities: {
      s3Contents: ApiEntity<IndexFileContentApi>
      users?: ApiEntity<UserApi>
      groups?: ApiEntity<GroupApi>
    }
  ): IndexFileContent {
    const indexFileContentApi = entities.s3Contents[id]
    const fileContent = this.normalizeFileContent(id, entities)
    let user: UserApi | undefined
    let group: GroupApi | undefined

    if (indexFileContentApi.user) {
      user = entities.users?.[indexFileContentApi.user]
    }
    if (indexFileContentApi.group) {
      group = entities.groups?.[indexFileContentApi.group]
    }

    return {
      ...fileContent,
      userContentStatus: indexFileContentApi.userContentStatus,
      user: this.populateUser(user),
      group: group
        ? {
            id: group.id,
            name: group.name,
            logo: group.logo,
          }
        : undefined,
    }
  }

  static normalizeMixedFileContent(data: {
    s3Content?: IndexFileContentApi
    user?: UserApi
    group?: GroupApi
    emailContent?: IndexEmailContentApi
    holdings?: { holding: Holding }[]
    sectors?: Sector[]
    tags?: Tag[]
  }): MixedContent {
    if (data.s3Content) {
      const parsedHoldings = data.holdings?.map((holding) => holding.holding)

      return FileContentNormalizer.normalizeIndexFileContent({
        s3Content: data.s3Content,
        user: data.user,
        group: data.group,
        holdings: parsedHoldings,
        sectors: data.sectors,
        tags: data.tags,
      })
    }

    return FileContentNormalizer.normalizeEmailContent({
      emailContent: data.emailContent!,
      user: data.user!,
      group: data.group,
    })
  }

  static normalizeEmailContent(data: {
    emailContent: IndexEmailContentApi
    user: UserApi
    group?: GroupApi
  }): IndexEmailContent {
    return {
      ...data.emailContent,
      user: this.populateUser(data.user)!,
      group: data.group,
      s3Contents: data.emailContent.s3Contents.map((s3Content) => {
        return {
          ...s3Content,
          fileFormat: s3Content.fileFormat?.toLowerCase() as FileExtension,
        }
      }),
    }
  }

  static normalizeEmailContents(
    emailContents: ApiEntity<EmailContentApi>,
    emailContentIds?: string[]
  ): EmailContent[] {
    return (
      emailContentIds?.map((id) => ({
        ...emailContents[id],
        s3Contents: [],
      })) ?? []
    )
  }

  static normalizeIndexFileContent(data: {
    s3Content: IndexFileContentApi
    user?: UserApi
    group?: GroupApi
    holdings?: Holding[]
    sectors?: Sector[]
    tags?: Tag[]
  }): IndexFileContent {
    const fileContent = this.populateFileContent(data.s3Content)

    return {
      ...fileContent,
      userContentStatus: data.s3Content.userContentStatus,
      user: data.user
        ? {
            id: data.user.id,
            name: data.user.name,
            image: data.user.image,
            firstName: data.user.firstName,
            lastName: data.user.lastName,
            email: data.user.email,
          }
        : undefined,
      group: data.group
        ? {
            id: data.group.id,
            name: data.group.name,
            logo: data.group.logo,
          }
        : undefined,
      holdings: data.holdings ?? [],
      sectors: data.sectors ?? [],
      tags: data.tags ?? [],
    }
  }

  static normalizeFileContentDetails(
    id: string,
    entities: {
      s3Contents: ApiEntity<FileContentDetailsApi>
      loggingUpdates: ApiEntity<LoggingUpdateApi>
      users: ApiEntity<UserApi>
      groups: ApiEntity<GroupApi>
    }
  ): FileContentDetails {
    const fileContentDetails = entities.s3Contents[id]
    const indexFileContent = this.normalizeIndexFileContentFromEntities(
      id,
      entities
    )

    return {
      ...indexFileContent,
      unreconciled: fileContentDetails.unreconciled,
      loggingUpdates: this.populateLoggingUpdate(
        fileContentDetails.loggingUpdates,
        entities.loggingUpdates
      ),
    }
  }

  static normalizeEmailContentDetails(
    id: string,
    entities: {
      emailContents: ApiEntity<EmailContentDetailsApi>
      users: ApiEntity<UserApi>
      s3Contents: ApiEntity<FileContentDetailsApi>
      loggingUpdates: ApiEntity<LoggingUpdateApi>
      linkedEmailAccounts: ApiEntity<Inbound>
    }
  ): EmailContentDetails {
    const emailContentDetails = entities.emailContents[id]
    const s3ContentIds = emailContentDetails.s3Contents
    const linkedEmailAccountId = emailContentDetails.linkedEmailAccount

    return {
      ...emailContentDetails,
      user: entities.users
        ? this.populateUser(entities.users[emailContentDetails.user])!
        : undefined,
      linkedEmailAccount: entities.linkedEmailAccounts[linkedEmailAccountId],
      loggingUpdates: this.populateLoggingUpdate(
        emailContentDetails.loggingUpdates,
        entities.loggingUpdates
      ),
      s3Contents: s3ContentIds.map((s3ContentId) => {
        const s3Content = entities.s3Contents[s3ContentId]

        return {
          ...s3Content,
          fileFormat: s3Content.fileFormat?.toLowerCase() as FileExtension,
        }
      }),
    }
  }

  static normalizeUpdateTypesOptions(
    updatableTypes: PlainUpdateType[]
  ): UpdateTypesOption[] {
    const sortedUpdatableTypes = updatableTypes.sort((a, b) =>
      a.split('::')[1].localeCompare(b.split('::')[1])
    )

    return sortedUpdatableTypes.map((type) => ({
      id: type,
      type,
      icon: ['far', getIconByPlainUpdateType(type)],
      active: false,
      color: getColorByPlainUpdateType(type),
    }))
  }
}
