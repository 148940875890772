import React from 'react'
import { getInitials } from 'utils/functions/user'
import { color } from 'utils/functions/colors'

import styled, { css } from 'styled-components'

interface GroupLogoProps {
  imageUrl?: string
  name?: string
  forceInitials?: boolean
  small?: boolean
}

export const GroupLogoContainer = styled.div<
  GroupLogoProps & { initials?: boolean }
>`
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 100%;
  border: solid 0.1rem #e9e9e9;
  background-color: white;
  background: url(${(props) => props.imageUrl});
  background-size: contain;

  ${(props) =>
    props.small
      ? css`
          width: 2.1rem;
          height: 2.1rem;
          min-width: 2.1rem;
          min-height: 2.1rem;
        `
      : ''}
  ${(props) =>
    props.initials &&
    css`
      background-color: ${color('lightGray')};
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        padding-top: 0.1rem;
        color: white;
        font-weight: 600;
        font-size: ${props.small ? '1rem' : '1.3rem'};
      }
    `}
`

const GroupLogo: React.FC<GroupLogoProps> = ({
  imageUrl = '',
  forceInitials = false,
  ...props
}) => {
  if (
    !forceInitials &&
    imageUrl &&
    (!imageUrl?.includes?.('missing') || !props.name)
  ) {
    return <GroupLogoContainer {...props} imageUrl={imageUrl} />
  }
  return (
    <GroupLogoContainer {...props} initials>
      <span>{getInitials(props.name)}</span>
    </GroupLogoContainer>
  )
}

export default GroupLogo
