import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'

export const Content = styled.div`
  padding: 1rem 0.8rem;
`

export const Row = styled.div`
  display: grid;
  column-gap: 2.8rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1.8rem;

  @media ${maxSize.md} {
    grid-template-columns: 1fr;
  }
`
