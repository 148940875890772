import React from 'react'

import { Container } from './FormSeparator.styles'

const FormSeparator: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <Container>
    <p />
    <span>{children}</span>
    <p />
  </Container>
)

export default FormSeparator
