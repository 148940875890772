import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import type { FormikProps } from 'formik'
import { Dispatch, RefObject, SetStateAction } from 'react'
import type { IntlShape } from 'react-intl'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'

import Toast from 'components/Toast'

import { Portfolio } from 'utils/types/portfolios'
import DraftUpdateService from 'api/DraftUpdateService'
import { getCurrentGroupData, isActingAsClient } from 'selectors/auth'
import { getDraftEditedFields, getSanitizedHtml } from 'utils/functions/updates'
import { QUERIES } from 'utils/queries/mixedUpdates'
import { updatesKeys } from 'utils/queries/udpates'
import { UpdateType } from 'utils/types/common'
import { UpdateTypes } from 'utils/constants/updates'
import { useRefetchCurrentGroupTier } from 'utils/hooks/queries/useGroupQuery'

import {
  DraftableUpdate,
  ScheduleType,
  TransactionType,
  Update,
} from 'utils/types/update'
import {
  CreateTransactionFormValues,
  CreateUpdateGlobalProps,
} from 'utils/types/updateForm'
import { Investor } from 'utils/types/investors'
import { useAppSelector } from '../reduxToolkit'
import useEntityFromUrl, { EntityFromUrlType } from '../useEntityFromUrl'
import useShouldBehaveAsFounder from '../useShouldBehaveAsAFounder'
import { createDraftQueryHelpers, editDraftQueryHelpers } from './helpers'

export const useCreateDraftUpdateMutation = (
  updateType: UpdateType,
  entity: EntityFromUrlType
) => {
  const currentGroupClient = useAppSelector(isActingAsClient)
  const createDraftQueryHelper = createDraftQueryHelpers[updateType]
  const { refetchCurrentGroupTier } = useRefetchCurrentGroupTier()

  return useMutation(
    createDraftQueryHelper.key,
    async () => {
      return createDraftQueryHelper.service(entity, {
        isActingAsClient: currentGroupClient,
      })
    },
    {
      onError: (_err) => {
        // TODO: handle error with toast
      },
      onSuccess: () => {
        if (updateType === UpdateTypes.TRANSACTION) {
          refetchCurrentGroupTier()
        }
      },
      retry: 3,
    }
  )
}

export const useEditDraftUpdateMutation = (updateType: UpdateType) => {
  const shouldBehaveAsFounder = useShouldBehaveAsFounder()
  const currentGroup = useAppSelector(getCurrentGroupData)
  const editDraftQueryHelper = editDraftQueryHelpers[updateType]
  const queryClient = useQueryClient()

  return useMutation(
    editDraftQueryHelper.key,
    ({
      updateItemId,
      values,
      update,
    }: {
      updateItemId: string
      values: CreateUpdateGlobalProps
      update?: Update
    }) => {
      const { title, body, date, ...rest } = values
      const isScheduled =
        shouldBehaveAsFounder && dayjs(date).isAfter(new Date())

      const { uploads, permissions, addedTags, removedTags } =
        getDraftEditedFields(values, currentGroup, update)

      const payload = editDraftQueryHelper.payload(
        {
          title,
          body: getSanitizedHtml(body) ?? '',
          date,
          addedTags,
          removedTags,
          reshareSettings: {
            isConfidential: permissions.confidentialUpdate,
            isBlocked: false,
          },
          schedule: {
            scheduleType: isScheduled
              ? ScheduleType.SCHEDULE
              : ScheduleType.SEND_NOW,
            loggingUpdateScheduleId: update?.loggingUpdateSchedule?.id,
          },
          shouldBehaveAsFounder,
          ...rest,
          ...permissions,
          uploads,
        },
        update
      )

      return editDraftQueryHelper.service(updateItemId, payload)
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([QUERIES.MIXED_UPDATES])
      },
    }
  )
}

interface PublishDraftUpdateMutationProps {
  updateType: UpdateType
  formikRef: RefObject<FormikProps<CreateTransactionFormValues>>
  setIsPublishing: Dispatch<SetStateAction<boolean>>
  handleError:
    | ((
        type: TransactionType,
        error: Error,
        values: CreateTransactionFormValues,
        intl: IntlShape,
        formikRef: RefObject<FormikProps<CreateTransactionFormValues>>
      ) => void)
    | ((type: UpdateType) => void)
}

export const usePublishDraftUpdateMutation = ({
  updateType,
  formikRef,
  handleError,
  setIsPublishing,
}: PublishDraftUpdateMutationProps) => {
  const intl = useIntl()
  const history = useHistory()
  const queryClient = useQueryClient()

  const { state } = useLocation<{
    portfolio?: Portfolio
    investor?: Investor
  }>()

  return useMutation(
    async ({
      updateItemId,
    }: {
      updateItemId: string
      values: CreateTransactionFormValues
    }) => {
      return DraftUpdateService.publishDraftUpdate(updateItemId, updateType)
    },
    {
      onSuccess: ({ loggingUpdateId }) => {
        queryClient.removeQueries([QUERIES.MIXED_UPDATES])
        if (loggingUpdateId) {
          queryClient.invalidateQueries(updatesKeys.byId(loggingUpdateId))
        }

        if (state?.portfolio) {
          const basePortfolioUrl = `/investments/${state.portfolio.type.toLocaleLowerCase()}/${
            state.portfolio.id
          }`

          const targetPageTab =
            updateType !== UpdateTypes.TRANSACTION ? 'updates' : 'portfolio'

          history.push(`${basePortfolioUrl}/${targetPageTab}`)

          return
        }

        if (state?.investor) {
          history.push(
            `/investor-management/investors/${state.investor.id}/profile`
          )
          return
        }

        history.goBack()
      },
      onError: (err: Error, rest) => {
        if (rest.values.updateType === UpdateTypes.TRANSACTION) {
          ;(
            handleError as (
              type: TransactionType,
              error: Error,
              values: CreateTransactionFormValues,
              intl: IntlShape,
              formikRef: RefObject<FormikProps<CreateTransactionFormValues>>
            ) => void
          )(rest.values.type!, err, rest.values, intl, formikRef)

          formikRef.current?.setSubmitting(false)
          setIsPublishing(false)
        } else {
          ;(handleError as (type: UpdateType) => void)(rest.values.updateType)
        }
      },
    }
  )
}

export const useDiscardDraftUpdateMutation = (
  draftableUpdate?: DraftableUpdate
) => {
  const entity = useEntityFromUrl()
  const history = useHistory()
  const location = useLocation<{ canGoBack?: boolean }>()
  const canGoBack = location.state?.canGoBack
  const queryClient = useQueryClient()

  const redirectToList = () => {
    if (location.pathname.includes('/updates')) {
      history.replace(`/updates`)
    } else {
      const redirectUrl = entity.type
        ? `/${entity.name}/${entity.type.toLowerCase()}/${entity.id}/portfolio`
        : `/${entity.name}/${entity.id}/updates`
      history.replace(redirectUrl, {
        canGoBack,
        showNavigation: true,
      })
    }
  }

  return useMutation(
    updatesKeys.discardDraftUpdateByIdAndType(
      draftableUpdate?.item?.id!,
      draftableUpdate?.updateType!
    ),
    async () => {
      await DraftUpdateService.discardDraftUpdate(
        draftableUpdate?.item?.id!,
        draftableUpdate?.updateType!
      )
    },
    {
      onSuccess: () => {
        Toast.displayIntl('updates.drafts.draftDiscardedSuccess', 'success')
        queryClient.invalidateQueries(updatesKeys.byId(draftableUpdate?.id!))
        redirectToList()
      },
      onError: () => {
        Toast.displayIntl('updates.drafts.draftDiscardedError', 'error')
      },
    }
  )
}
