import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { FundPortfolio } from 'utils/types/portfolios'
import {
  AllFundsChartDataWithPercentages,
  getAllFundsPortfolioChartData,
  getAllFundsPortfolioIndividualChartData,
  getColorsForFunds,
} from 'utils/functions/portfolios'
import FundPortfoliosPieChart from './FundPortfoliosPieChart'
import InvestPortfolioChartResume from './InvestPortfolioChartResume'
import { ChartWrapper, ResultContainer } from './Chart.styles'

interface Props {
  chartData: FundPortfolio[]
  isIndividualSummary?: boolean
}

const Chart = ({ chartData, isIndividualSummary = true }: Props) => {
  const intl = useIntl()

  const fundSummaryData = useMemo<AllFundsChartDataWithPercentages[]>(() => {
    return getAllFundsPortfolioChartData(chartData, intl)
  }, [chartData, intl])

  const individualSummaryData = useMemo<
    AllFundsChartDataWithPercentages[]
  >(() => {
    return getAllFundsPortfolioIndividualChartData(chartData, intl)
  }, [chartData, intl])

  const groupedChartData = isIndividualSummary
    ? individualSummaryData
    : fundSummaryData

  const colors = getColorsForFunds(groupedChartData)

  return (
    <ChartWrapper>
      <FundPortfoliosPieChart
        chartData={groupedChartData}
        colorsForPortfolios={colors}
      />
      {groupedChartData.length && (
        <ResultContainer>
          <InvestPortfolioChartResume
            chartData={groupedChartData}
            colorsForCompanies={colors}
          />
        </ResultContainer>
      )}
    </ChartWrapper>
  )
}

export default Chart
