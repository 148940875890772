import { useState } from 'react'
import { SortCriteria } from 'components/SortBy/SortBy'

const useSort = (sortCriteria) => {
  const [initialCriteria] = useState<SortCriteria>({
    id: sortCriteria[2].id,
    label: sortCriteria[2].label,
    direction: sortCriteria[2].direction,
  })

  const [currentSort, setCurrentSort] = useState({
    orderBy: sortCriteria[2].field,
    direction: sortCriteria[2].direction,
  })

  const onChangeSortBy = (orderBy, direction) => {
    setCurrentSort({
      orderBy,
      direction,
    })
  }

  return {
    sortCriteria,
    initialCriteria,
    onChangeSortBy,
    currentSort,
  }
}

export default useSort
