import styled, { css } from 'styled-components'
import { LegendItem } from './AreaChart'

export const AreaChartWrapper = styled.div`
  .recharts-wrapper {
    margin-left: -4.7rem;
    height: 10.5rem;
    width: calc(100% + 5rem) !important;
  }
  .recharts-surface {
    overflow: visible;
    width: 100%;
  }

  .recharts-cartesian-axis-tick-value {
    font-size: 1.2rem;
  }
`

export const ChartContainer = styled.div`
  /* height: 14rem; */
`

type CustomAxisProps = {
  chunkLegends: boolean
  chunks: LegendItem[] | LegendItem[][]
  countItems: number
}
const calculateChunkColumns = ({ chunks, countItems }: CustomAxisProps) => {
  return chunks
    .map((chunk, index) => {
      const isLast = index === chunks.length - 1

      if (isLast && countItems % chunks.length === 0) {
        return `${chunk.length - 1}fr`
      }

      return `${chunk.length}fr`
    })
    .join(' ')
}

export const CustomAxis = styled.div<CustomAxisProps>`
  margin: 0.7rem 0.6rem 0.7rem 2.3rem;
  overflow: visible;
  display: grid;

  ${(props) =>
    props.chunkLegends
      ? css`
          grid-template-columns: ${calculateChunkColumns(props)};
          transform: translateX(-1.5rem);
        `
      : css`
          display: flex;
          justify-content: ${props.countItems === 1
            ? 'center'
            : 'space-between'};
        `}
`

export const AxisItem = styled.span<{ axisItemWidth?: string }>`
  line-height: 0.92;
  color: #4a4a68;
  word-break: break-word;
  font-size: 1.15rem;
  width: ${(props) => props.axisItemWidth || '4.2rem'};
  text-align: center;
`
