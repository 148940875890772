import React from 'react'
import ContentLoader from 'react-content-loader'

const PortfolioDashboardLoader = (props) => (
  <ContentLoader
    speed={2}
    width={633}
    height={170}
    viewBox="0 0 100% 170"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ width: '100%' }}
    {...props}
  >
    <rect x="4" y="35" rx="5" ry="5" width="100%" height="22" />
    <rect x="4" y="70" rx="5" ry="5" width="100%" height="22" />
    <rect x="4" y="105" rx="5" ry="5" width="400" height="22" />
  </ContentLoader>
)

export default PortfolioDashboardLoader
