import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'

export const Content = styled.div`
  padding: 1rem 0.8rem;
`

export const Row = styled.div`
  display: grid;
  column-gap: 2.8rem;
  grid-template-columns: 1fr 1fr;

  @media ${maxSize.md} {
    grid-template-columns: 1fr;
  }
`

export const RepetitionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.8rem;
  margin-top: 2.5rem;

  @media ${maxSize.md} {
    display: block;
  }
`

export const RepetitionContainer = styled.div`
  margin-top: 1.8rem;

  @media ${maxSize.md} {
    margin-left: 0;
    margin-top: 1.5rem;
    width: 100%;
  }
`
