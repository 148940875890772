/* eslint-disable class-methods-use-this */
import { Nullable } from 'utils/types/common'
import { ChangeSetItem, EventType, LogTextBuilder } from '../types'

export class AddressLogTextBuilder extends LogTextBuilder {
  private getDeletedAddressLogText(text: Nullable<string>): React.ReactNode {
    return this.intl.formatMessage(
      { id: 'logs.deletedAddress' },
      { text, bold: (msg) => <b>{msg}</b> }
    )
  }

  private getAddedAddressLogText(text: Nullable<string>): React.ReactNode {
    return this.intl.formatMessage(
      { id: 'logs.addedAddress' },
      { text, bold: (msg) => <b>{msg}</b> }
    )
  }

  private getSetMainAddressLogText(text: Nullable<string>): React.ReactNode {
    return this.intl.formatMessage(
      { id: 'logs.setAsMainAddress' },
      { text, bold: (msg) => <b>{msg}</b> }
    )
  }

  public getLogText(
    event: EventType,
    fieldName: string,
    changeSetItem: ChangeSetItem,
    text: Nullable<string>
  ): React.ReactNode {
    if (event === EventType.ADD_LOCATION) {
      if (fieldName !== 'position') {
        return this.getAddedAddressLogText(text)
      }

      if (changeSetItem[1] === 1) {
        return this.getSetMainAddressLogText(text)
      }
    }

    if (event === EventType.REMOVE_LOCATION && fieldName !== 'position')
      return this.getDeletedAddressLogText(text)

    if (
      event === EventType.UPDATE_LOCATION &&
      fieldName === 'position' &&
      changeSetItem[1] === 1
    )
      return this.getSetMainAddressLogText(text)

    return null
  }
}
