import { accentColors } from 'containers/CustomLogin/CustomLoginPreview/CustomLoginPreview'
import { GroupNames, GroupTypes } from 'utils/constants/groups'
import { GroupType } from 'utils/types/user'
import { assertUnreachable } from './utils'

export const isGroup = (type) =>
  type === GroupTypes.FOUNDER ||
  type === GroupTypes.CLIENT ||
  type === GroupTypes.INVESTOR_GROUP ||
  type === 'group'

export const accentColorIsDefaultColor = (color) => {
  return color === accentColors[0].code
}

export const getGroupTypeName = (groupType: GroupType) => {
  switch (groupType) {
    case GroupTypes.CLIENT:
      return GroupNames.CLIENT
    case GroupTypes.FOUNDER:
      return GroupNames.FOUNDER
    case GroupTypes.FUND_MANAGER:
      return GroupNames.FUND_MANAGER
    case GroupTypes.INVESTOR_GROUP:
      return GroupNames.INVESTOR_GROUP
    default:
      return assertUnreachable(groupType)
  }
}

export const getGroupTypeFromName = (groupName: GroupNames) => {
  switch (groupName) {
    case GroupNames.CLIENT:
      return GroupTypes.CLIENT
    case GroupNames.FOUNDER:
      return GroupTypes.FOUNDER
    case GroupNames.FUND_MANAGER:
      return GroupTypes.FUND_MANAGER
    case GroupNames.INVESTOR_GROUP:
      return GroupTypes.INVESTOR_GROUP
    default:
      return assertUnreachable(groupName)
  }
}
