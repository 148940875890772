import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import Toast from 'components/Toast'
import TagService from 'api/TagService'
import { Tag } from 'utils/types/update'

const useTags = () => {
  const intl = useIntl()

  const { data: tags, status } = useQuery<Tag[]>(['tags'], async () => {
    try {
      const {
        data: { result, entities },
      } = await TagService.getTags()
      return result.map((tagId) => entities?.tags?.[tagId])
    } catch (error) {
      Toast.display(intl.messages['errors.fetchTagsError'], 'error')
      throw error
    }
  })

  return {
    tags,
    loading: status === 'loading',
  }
}

export default useTags
