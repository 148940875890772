import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Scroll } from 'App.styles'
import { color } from 'utils/functions/colors'
import { ChatBreakpointSize } from 'utils/constants/chat'
import { maxSize } from 'utils/constants/breakpoint'
import {
  Container as ChatHeaderContainer,
  chatHeaderHeight,
} from '../ChatHeader/ChatHeader.styles'
import CreateChannelToStyle from '../CreateChannel'

export const Container = styled.div`
  background-color: white;
  min-width: 28rem;
  width: 28rem;
  height: 100%;
  border-right: 0.1rem solid ${color('veryLightGray')};

  @media ${ChatBreakpointSize.tablet} {
    width: 100%;
  }

  @media ${maxSize.mobile} {
    width: 100%;
    height: initial;
    border-right: none;
    padding-bottom: 2rem;

    ${ChatHeaderContainer} {
      height: 6.2rem;
      min-height: initial;
      padding: 2.4rem 2rem 1.6rem 2.5rem;
    }
  }
`

export const ChannelList = styled.div`
  ${Scroll}
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - ${chatHeaderHeight});

  .str-chat__container {
    display: initial !important;
  }

  @media ${maxSize.mobile} {
    max-height: initial;
  }
`

export const StyledLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;

  &:hover {
    text-decoration: underline;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  gap: 0.9rem;
`

export const CreateChannel = styled(CreateChannelToStyle)`
  padding-left: 2.4rem;
`
