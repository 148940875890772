import * as yup from 'yup'

import { emptyToNull } from 'utils/functions/schemas'
import { ScheduleType } from 'components/ScheduleUpdateRadio/ScheduleUpdateRadio'
import {
  AccountingReportType,
  BalanceDateType,
  PeriodType,
  ReportDatePeriodType,
} from 'utils/constants/accounting'
import { IntlShape } from 'react-intl/src/types'
import { FIELD_NAMES as ACCOUNTING_FIELD_NAMES } from 'containers/UpdatesV2/Accounting/constants/constants'
import { ScheduleRepetitionType } from 'utils/constants/scheduleRepetition'
import { MAX_TITLE_COUNTER_CHARACTERS } from 'utils/constants/updates'
import { UpdateAttachment } from 'utils/types/transactions'

const GENERAL_REQUIRED_FIELD_ERROR_KEY = 'general.requiredField'

const checkContentEditableSchema = (schema, intl: IntlShape) => {
  return schema.when('$isContentEditable', {
    is: true,
    then: schema.required(
      intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })
    ),
    otherwise: schema,
  })
}

export const scheduleDateSchema = (intl: IntlShape) =>
  yup.date().when('scheduleType', {
    is: ScheduleType.SEND_NOW,
    then: yup.date().nullable(),
    otherwise: checkContentEditableSchema(yup.date(), intl).nullable(),
  })

export const repetitionSchema = (intl: IntlShape) =>
  yup.string().when('scheduleType', {
    is: ScheduleType.SCHEDULE,
    then: yup
      .string()
      .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
    otherwise: yup.string().nullable(),
  })

export const getAnnouncementSchema = (
  intl: IntlShape,
  dateRequired: boolean = false
) =>
  yup.object().shape({
    title: checkContentEditableSchema(
      yup
        .string()
        .required(intl.formatMessage({ id: 'general.requiredField' }))
        .max(
          MAX_TITLE_COUNTER_CHARACTERS,
          intl.formatMessage(
            { id: 'general.youHaveExceededCharacterLimit' },
            { limit: MAX_TITLE_COUNTER_CHARACTERS }
          )
        ),
      intl
    ),
    body: yup.string().nullable().transform(emptyToNull),
    scheduleType: yup.string().nullable(),
    date: dateRequired ? scheduleDateSchema(intl) : yup.mixed().nullable(),
  })

export const getNoteSchema = (intl: IntlShape) =>
  yup.object().shape({
    title: checkContentEditableSchema(
      yup
        .string()
        .required(intl.formatMessage({ id: 'general.requiredField' }))
        .max(
          MAX_TITLE_COUNTER_CHARACTERS,
          intl.formatMessage(
            { id: 'general.youHaveExceededCharacterLimit' },
            { limit: MAX_TITLE_COUNTER_CHARACTERS }
          )
        ),
      intl
    ),
    body: yup.string().nullable().transform(emptyToNull),
    scheduleType: yup.string().nullable(),
    date: scheduleDateSchema(intl),
    investor: yup.object().nullable(),
    portfolio: yup.object().when(['investor'], {
      is: (investor) => {
        return !!investor
      },
      then: yup
        .object()
        .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
      otherwise: yup.object().nullable(),
    }),
  })

export const getDocumentSchema = (intl: IntlShape, dateRequired?: boolean) =>
  yup.object().shape({
    title: yup
      .string()
      .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY }))
      .max(
        MAX_TITLE_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_TITLE_COUNTER_CHARACTERS }
        )
      ),
    body: yup.string().nullable().transform(emptyToNull),
    scheduleType: yup.string(),
    date: dateRequired ? scheduleDateSchema(intl) : yup.mixed().nullable(),
    files: yup
      .array()
      .test(
        'at-least-one-valid-file',
        intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY }),
        (value) => {
          if (!value) return false
          return value.some((file: UpdateAttachment) => !file.destroy)
        }
      )
      .min(1, intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
    investor: yup.object().nullable(),
    portfolio: yup.object().when(['investor'], {
      is: (investor) => {
        return !!investor
      },
      then: yup
        .object()
        .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
      otherwise: yup.object().nullable(),
    }),
  })

export const getEditAccountingSchema = (intl: IntlShape) =>
  yup.object().shape({
    title: yup
      .string()
      .required()
      .max(
        MAX_TITLE_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_TITLE_COUNTER_CHARACTERS }
        )
      ),
  })

export const getAccountingSchema = (intl: IntlShape) =>
  yup.object().shape({
    [ACCOUNTING_FIELD_NAMES.title]: yup
      .string()
      .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY }))
      .max(
        MAX_TITLE_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_TITLE_COUNTER_CHARACTERS }
        )
      ),
    scheduleType: yup.string(),
    [ACCOUNTING_FIELD_NAMES.date]: yup
      .date()
      .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
    [ACCOUNTING_FIELD_NAMES.repetition]: yup
      .string<ScheduleRepetitionType>()
      .oneOf<ScheduleRepetitionType>(Object.values(ScheduleRepetitionType))
      .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
    [ACCOUNTING_FIELD_NAMES.reportType]: yup
      .string<AccountingReportType>()
      .oneOf<AccountingReportType>(Object.values(AccountingReportType))
      .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
    [ACCOUNTING_FIELD_NAMES.balanceDate]: yup
      .mixed()
      .when(ACCOUNTING_FIELD_NAMES.reportType, {
        is: (reportTypeValue) =>
          reportTypeValue === AccountingReportType.QUICKBOOKS_BALANCE_SHEET ||
          reportTypeValue === AccountingReportType.XERO_BALANCE_SHEET,
        then: yup
          .string<BalanceDateType>()
          .oneOf<BalanceDateType>(Object.values(BalanceDateType))
          .required(
            intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })
          ),
        otherwise: yup.string().nullable().notRequired(),
      }),
    [ACCOUNTING_FIELD_NAMES.reportDatePeriod]: yup
      .mixed()
      .when(ACCOUNTING_FIELD_NAMES.reportType, {
        is: (reportTypeValue) =>
          reportTypeValue === AccountingReportType.XERO_PROFIT_AND_LOSS ||
          reportTypeValue === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS,
        then: yup
          .string<ReportDatePeriodType>()
          .oneOf<ReportDatePeriodType>(Object.values(ReportDatePeriodType))
          .required(
            intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })
          ),
        otherwise: yup.string().nullable().notRequired(),
      }),
    [ACCOUNTING_FIELD_NAMES.periods]: yup
      .string<PeriodType>()
      .oneOf<PeriodType>(Object.values(PeriodType))
      .required(intl.formatMessage({ id: GENERAL_REQUIRED_FIELD_ERROR_KEY })),
    [ACCOUNTING_FIELD_NAMES.paymentsOnly]: yup.boolean(),
    [ACCOUNTING_FIELD_NAMES.standardLayout]: yup.boolean(),
  })
