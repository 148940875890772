import Big from 'big.js'
import { Nullable } from 'utils/types/common'
import {
  PromotionCode,
  SubscriptionBillingPeriod,
  SubscriptionChangeType,
  SubscriptionPlan,
} from 'utils/types/subscriptions'

export const getPlanChangeType = (
  currentPlan: SubscriptionPlan,
  newPlan: SubscriptionPlan
): Nullable<SubscriptionChangeType> => {
  if (currentPlan === newPlan) return null

  if (currentPlan === SubscriptionPlan.LITE) {
    return SubscriptionChangeType.UPGRADE
  }

  if (currentPlan === SubscriptionPlan.INDIVIDUAL) {
    if (newPlan === SubscriptionPlan.PRO) {
      return SubscriptionChangeType.UPGRADE
    }
  }

  return SubscriptionChangeType.DOWNGRADE
}

export const getPlanId = (plan: SubscriptionPlan): SubscriptionPlan => {
  switch (plan) {
    case SubscriptionPlan.LITE:
      return SubscriptionPlan.LITE
    case SubscriptionPlan.INDIVIDUAL:
      return SubscriptionPlan.INDIVIDUAL
    case SubscriptionPlan.PRO:
      return SubscriptionPlan.PRO
    default:
      return SubscriptionPlan.ORGANIZATION
  }
}

export const getPlanOrder = (plan: SubscriptionPlan): number => {
  switch (plan) {
    case SubscriptionPlan.LITE:
      return 0
    case SubscriptionPlan.INDIVIDUAL:
      return 1
    case SubscriptionPlan.PRO:
      return 2
    default:
      return 3
  }
}

export const getPriceWithPromotionCode = (
  amount: number,
  promotionCode: PromotionCode
) => {
  if (promotionCode.coupon.amountOff !== null) {
    const amountOff = Big(promotionCode.coupon.amountOff).div(100)
    return Big(amount).minus(amountOff).toNumber()
  }

  if (promotionCode.coupon.percentOff !== null) {
    return Big(amount)
      .times(1 - promotionCode.coupon.percentOff / 100)
      .toNumber()
  }

  return amount
}

export const isBillingPeriod = (
  billingPeriod: Nullable<string>
): billingPeriod is SubscriptionBillingPeriod => {
  return [
    SubscriptionBillingPeriod.MONTHLY,
    SubscriptionBillingPeriod.ANNUALLY,
  ].includes(billingPeriod as SubscriptionBillingPeriod)
}

export const isPlanId = (
  planId: Nullable<string>
): planId is SubscriptionPlan => {
  return [
    SubscriptionPlan.LITE,
    SubscriptionPlan.INDIVIDUAL,
    SubscriptionPlan.PRO,
  ].includes(planId as SubscriptionPlan)
}
