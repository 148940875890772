import React, { useId } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from 'utils/theme'
import Tooltip from 'components/Tooltip'
import { AccessType } from 'utils/types/updateForm'
import AccessDropdown from './AccessDropdown'
import * as Styles from './ShareWithEntities.styles'

interface ClockworkCommunityProps {
  access: AccessType
  onAccessChange: (access: AccessType) => void
  disabled: boolean
}

const ClockworkCommunity: React.FC<ClockworkCommunityProps> = ({
  access,
  onAccessChange,
  disabled,
}) => {
  const id = useId()

  const renderLabel = () => {
    if (disabled) {
      return (
        <Tooltip
          id={id}
          text={<FormattedMessage id="updates.unverifiedUserMessage" />}
          place="bottom"
          delayShow={500}
          event="mouseover mouseenter"
          eventOff="mouseleave mouseout scroll mousewheel blur"
          maxWidth="31rem"
          margin="-1rem 0 0 4rem"
        >
          <Styles.NameContainer>
            <Styles.GlobeIcon>
              <FontAwesomeIcon
                icon={['far', 'globe']}
                color={theme.colors.darkGray}
              />
            </Styles.GlobeIcon>
            <span>
              <FormattedMessage id="updates.clockworkUniverseCommunity" />
            </span>
          </Styles.NameContainer>
        </Tooltip>
      )
    }

    return (
      <Styles.NameContainer>
        <Styles.GlobeIcon>
          <FontAwesomeIcon
            icon={['far', 'globe']}
            color={theme.colors.darkGray}
          />
        </Styles.GlobeIcon>
        <span>
          <FormattedMessage id="updates.clockworkUniverseCommunity" />
        </span>
      </Styles.NameContainer>
    )
  }

  return (
    <Styles.Container disabled={disabled} data-testid="clockwork-community">
      {renderLabel()}
      <AccessDropdown
        access={access}
        isPublic
        onAccessChange={onAccessChange}
        disabled={disabled}
      />
    </Styles.Container>
  )
}

export default ClockworkCommunity
