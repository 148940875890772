/**
 * React Slick goes crazy and grows the width of the slider infinitely inside a flex or grid container (in some cases)
 * https://github.com/akiran/react-slick/issues/1887#issuecomment-719195394
 */

import React from 'react'
import styled from 'styled-components/macro'

const RelativeContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .slick-track {
    margin: 0;
  }
`

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

interface ReactSlickWrapperProps {
  children: React.ReactNode
}

const ReactSlickWrapper: React.FC<ReactSlickWrapperProps> = ({ children }) => {
  return (
    <RelativeContainer>
      <AbsoluteContainer>{children}</AbsoluteContainer>
    </RelativeContainer>
  )
}

export default ReactSlickWrapper
