import {
  ProgressBarWrapper,
  ProgressContainer,
  ProgressFill,
  ProgressLabel,
  ProgressLabelWrapper,
} from './ProgressBar.styles'

const ProgressBar = ({
  currentStep,
  steps,
}: {
  steps: { id: string; label: string }[]
  currentStep: string
}) => {
  const currentStepIndex = steps.findIndex((step) => step.id === currentStep)

  return (
    <ProgressContainer>
      <ProgressBarWrapper>
        <ProgressFill
          currentStepIndex={currentStepIndex}
          totalSteps={steps.length}
        />
      </ProgressBarWrapper>
      <ProgressLabelWrapper>
        {steps.map((step, index) => (
          <ProgressLabel key={step.id} isActive={index <= currentStepIndex}>
            {step.label}
          </ProgressLabel>
        ))}
      </ProgressLabelWrapper>
    </ProgressContainer>
  )
}

export default ProgressBar
