import { SubscriptionPlan } from './subscriptions'

export enum TierRequestStatus {
  IDLE = 'idle',
  SUCCESS = 'success',
  NOT_FOUND = 'not-found',
  ERROR = 'error',
}

export type OnboardingSteps = {
  welcome: boolean
  track: boolean
  files: boolean
  inbound: boolean
}

export interface Tier {
  groupId: string
  id: string
  maxInvestPortfolios: number
  maxStorageInMb: number
  maxTrackPortfolios: number
  maxTransactions: number
  maxUsers: number
  plan?: SubscriptionPlan
  investPortfolios: number
  storageInMb: number
  trackPortfolios: number
  transactions: number
  users: number
  onboarding: OnboardingSteps
}
