import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { FormikProps } from 'formik'
import { RefObject, useRef } from 'react'
import type { IntlShape } from 'react-intl'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'

import Toast from 'components/Toast'

import DraftUpdateService from 'api/DraftUpdateService'
import UpdateService from 'api/UpdateService'
import dayjs from 'dayjs'
import {
  getCurrentGroupData,
  getCurrentGroupName,
  isActingAsFounder,
} from 'selectors/auth'
import { OUT_OF_RANGE } from 'utils/constants/errors'
import {
  UpdateTypeForUrl,
  UpdateTypes,
  UpdateVisibility,
} from 'utils/constants/updates'
import {
  getEntityNameByReport,
  getEntityUrlNameByReport,
  getReportParams,
} from 'utils/functions/accounting'
import {
  CreateAccountingPayloadParams,
  EditAccountingPayloadParams,
  getAccountingReportTypeKey,
  getCreateAccountingPayload,
  getEditAccountingPayload,
} from 'utils/functions/api/accounting'
import { getEditTransactionPayload } from 'utils/functions/api/transactions'
import {
  getCreatePermissions,
  getEditPermissions,
} from 'utils/functions/api/updates'
import { checkUpdatePermissionError } from 'utils/functions/permissions'
import { refetchPortfolio } from 'utils/functions/portfolios'
import {
  ReshareTokenSet,
  castLoggingUpdateGroup,
  castLoggingUpdateUser,
  getRemovedLoggingUpdateEntityIds,
  getSanitizedBodyIfNotExpired,
  getScheduleType,
} from 'utils/functions/updates'
import { useRefetchCurrentGroupTier } from 'utils/hooks/queries/useGroupQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { QUERIES } from 'utils/queries/mixedUpdates'
import { updatesKeys } from 'utils/queries/udpates'
import { PlainUpdateType, UpdateType } from 'utils/types/common'
import {
  DefaultRoutes,
  FundManagerRoutes,
  getCurrentBaseRoute,
  getInitialLinkRedirect,
  getInvestmentNavigationOptionSelected,
} from 'reducers/breadcrumbSlice'
import { UPDATES } from 'routes/constant/commonRoutes'
import {
  AccountingUpdate,
  DraftableUpdate,
  ScheduleType,
  Tag,
  TransactionType,
  TransactionUpdate,
  Update,
} from 'utils/types/update'
import {
  AccountingFormValues,
  CreateBasicFormValues,
  CreateTransactionFormValues,
} from 'utils/types/updateForm'
import useEntityFromUrl from '../useEntityFromUrl'
import useSettings from '../useSettings'
import useShouldBehaveAsFounder from '../useShouldBehaveAsAFounder'
import { editQueryHelpers, updateRecentlyUsedLists } from './helpers'
import { useEditDraftUpdateMutation } from './useDraftUpdateQuery'

interface FetchProps {
  updateId: string
  updateType?: string
  preventRedirectOnPermissionsError?: boolean
  retry?: boolean
  staleTime?: number
  enabled?: boolean
}

export const useFetchUpdateQuery = <T extends Update>({
  updateId,
  updateType,
  preventRedirectOnPermissionsError,
  retry = true,
  staleTime,
  enabled,
}: FetchProps) => {
  const currentGroupName = useAppSelector(getCurrentGroupName)
  return useQuery<T, { status: number }>(
    updatesKeys.byId(updateId),
    async () => {
      return UpdateService.fetchUpdate<T>(updateId!)
    },
    {
      enabled,
      retry,
      staleTime,
      onError: preventRedirectOnPermissionsError
        ? undefined
        : (error) => {
            if (checkUpdatePermissionError(error)) {
              Toast.displayIntl(
                [
                  'errors.groupNoAccessShow',
                  {
                    name: updateType,
                    groupName: currentGroupName,
                  },
                ],
                'error'
              )
            } else {
              Toast.displayIntl([`updates.fetchErrors.${updateType}`], 'error')
            }
          },
    }
  )
}

/**
 * Mutation for deleting NOTES, ANNOUNCEMENTS, DOCUMENTS, and TRANSACTIONS
 */
export const useDeleteUpdateMutation = (
  itemId: string,
  updateType: UpdateType,
  plainUpdateType: PlainUpdateType
) => {
  const history = useHistory()
  const baseRoute = useAppSelector(getCurrentBaseRoute)
  const redirectLink = useAppSelector(getInitialLinkRedirect)
  const navigationOptionSelected = useAppSelector(
    getInvestmentNavigationOptionSelected
  )
  const queryClient = useQueryClient()
  const { refetchCurrentGroupTier } = useRefetchCurrentGroupTier()

  const redirectToList = () => {
    if (baseRoute === DefaultRoutes.UPDATES) {
      history.push(UPDATES)
      return
    }

    if (
      baseRoute === FundManagerRoutes.INVESTMENTS &&
      navigationOptionSelected
    ) {
      history.push(navigationOptionSelected.url)
      return
    }

    history.push(redirectLink)
  }

  return useMutation(
    updatesKeys.deleteUpdateByIdAndType(itemId, updateType),
    async () => {
      return UpdateService.deleteUpdate(itemId, updateType, plainUpdateType)
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([QUERIES.MIXED_UPDATES])
        refetchCurrentGroupTier()
        Toast.displayIntl(
          [`updates.deleteMessageSuccess.${updateType}`],
          'success'
        )
        redirectToList()
      },
      onError: (error: { message: string[] }) => {
        if (error.message.includes(OUT_OF_RANGE)) {
          Toast.displayIntl(
            ['updates.transactionEditErrorUnfundedCommitment'],
            'error',
            5000
          )
        } else {
          Toast.displayIntl(
            [`updates.deleteMessageError.${updateType}`],
            'error'
          )
        }
      },
    }
  )
}

/**
 * Mutation for discarding draft NOTES, ANNOUNCEMENTS and DOCUMENTS
 */
export const useDiscardDraftUpdateMutation = (
  draftableUpdate?: DraftableUpdate
) => {
  const entity = useEntityFromUrl()
  const history = useHistory()
  const queryClient = useQueryClient()
  const location = useLocation<{ canGoBack?: boolean }>()
  const canGoBack = location.state?.canGoBack

  const redirectToList = () => {
    if (location.pathname.includes('/updates')) {
      history.replace(`/updates`)
    } else {
      const redirectUrl = entity.type
        ? `/${entity.name}/${entity.type.toLowerCase()}/${entity.id}/portfolio`
        : `/${entity.name}/${entity.id}/updates`
      history.replace(redirectUrl, {
        canGoBack,
        showNavigation: true,
      })
    }
  }

  return useMutation(
    updatesKeys.discardDraftUpdateByIdAndType(
      draftableUpdate?.item.id!,
      draftableUpdate?.updateType!
    ),
    async () => {
      if (draftableUpdate?.isDraftUpdate) {
        await DraftUpdateService.discardDraftUpdate(
          draftableUpdate?.item.id!,
          draftableUpdate?.updateType!
        )
      }
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([QUERIES.MIXED_UPDATES])
        Toast.displayIntl('updates.drafts.draftDiscardedSuccess', 'success')
        queryClient.invalidateQueries(updatesKeys.byId(draftableUpdate?.id!))
        redirectToList()
      },
      onError: () => {
        Toast.displayIntl('updates.drafts.draftDiscardedError', 'error')
      },
    }
  )
}

export const useCreateAccountingMutation = () => {
  const intl = useIntl()
  const history = useHistory()
  const { name: entityToCreateUpdateUpon, id } = useEntityFromUrl()
  const shouldBehaveAsFounder = useShouldBehaveAsFounder()
  const currentGroup = useAppSelector(getCurrentGroupData)
  const queryClient = useQueryClient()

  const handleError = (error: Error) => {
    if (error?.message.indexOf('not_verified') >= 0) {
      Toast.display(intl.messages['general.accountNotVerified'], 'error')
    } else {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.creatingItemError' },
          { item: 'accounting' }
        ),
        'error'
      )
    }
  }

  const handleOnSuccess = (values: AccountingFormValues) => {
    const scheduleType = getScheduleType(values.date)

    Toast.displayIntl(
      scheduleType === ScheduleType.SCHEDULE
        ? ['success.schedulingItem', { name: 'accounting' }]
        : ['success.creatingItem', { name: 'accounting' }],
      'success'
    )
    history.goBack()
  }

  return useMutation(
    updatesKeys.createAccounting(),
    async (values: AccountingFormValues) => {
      const scheduleType = getScheduleType(values.date)
      const { permissions } = values

      const accountingReportType = getAccountingReportTypeKey(
        values.reportType!
      )

      const data: CreateAccountingPayloadParams<any> = {
        accountingReportType,
        title: values.title,
        date: values.date,
        uploads: values.files,
        tags: values.tags as Tag[],
        shouldBehaveAsFounder,
        reportParams: getReportParams(values.reportType!, values),
        reportType: values.reportType!,
        reshareSettings: {
          isConfidential: permissions.confidentialUpdate,
          isBlocked: false,
        },
        schedule: {
          scheduleType: ScheduleType.SEND_NOW,
        },
        ...getCreatePermissions({
          permissions,
          currentGroup,
        }),
      }

      if (shouldBehaveAsFounder && dayjs(values.date).isAfter(new Date())) {
        data.schedule = {
          scheduleType,
          date: values.date,
          repetition: values.repetition,
        }
      }

      const payload = getCreateAccountingPayload(data)

      await UpdateService.createAccounting(
        entityToCreateUpdateUpon,
        id,
        getEntityNameByReport(values.reportType!),
        payload
      )

      if (values.permissions.recentlyUsedLists) {
        updateRecentlyUsedLists(values.permissions.recentlyUsedLists)
      }
    },
    {
      onSuccess: (_, values) => {
        queryClient.removeQueries([QUERIES.MIXED_UPDATES])
        handleOnSuccess(values)
      },
      onError: handleError,
    }
  )
}

export const useUpdateAccountingMutation = ({
  updateId,
}: {
  updateId: string
}) => {
  const history = useHistory()
  const isFounder = useAppSelector(isActingAsFounder)
  const shouldBehaveAsFounder = useShouldBehaveAsFounder()
  const currentGroup = useAppSelector(getCurrentGroupData)

  const entity = useEntityFromUrl()

  const location = useLocation<{ canGoBack?: boolean }>()
  const canGoBack = location.state?.canGoBack
  const queryClient = useQueryClient()

  const redirectToShow = () => {
    if (location.pathname.includes('/updates')) {
      history.replace(`/updates/accounting/${updateId}`)
    } else {
      history.replace(`/${entity.name}/${entity.id}/accounting/${updateId}`, {
        canGoBack,
        showNavigation: true,
      })
    }
  }

  return useMutation(
    updatesKeys.editAccounting(),
    async ({
      values,
      update,
    }: {
      values: AccountingFormValues
      update: AccountingUpdate
    }) => {
      const scheduleType = getScheduleType(values.date)
      const accountingReportType = getAccountingReportTypeKey(
        values.reportType!
      )
      const data: EditAccountingPayloadParams<any> = {
        updateId: update.id,
        tags: values.tags as Tag[],
        accountingReportType,
        title: values.title,
        date: values.date,
        uploads: values.files,
        shouldBehaveAsFounder,
        reportParams: getReportParams(values.reportType!, values),
        reportType: values.reportType!,
        reshareSettings: {
          isConfidential: values.permissions.confidentialUpdate,
          isBlocked: update.blocked,
        },
        ...getEditPermissions({
          permissions: values.permissions,
          currentGroup,
          oldUpdate: update,
        }),
        removedGroups: [],
        removedUsers: [],
        schedule: {
          scheduleType: ScheduleType.SCHEDULE,
        },
      }

      if (isFounder && dayjs(values.date).isAfter(new Date())) {
        data.schedule = {
          scheduleType,
          date: values.date,
          repetition: values.repetition,
        }
      }
      const payload = getEditAccountingPayload(data)

      await UpdateService.editAccounting(
        update.item.id,
        getEntityUrlNameByReport(values.reportType!),
        payload
      )

      if (values.permissions.recentlyUsedLists) {
        updateRecentlyUsedLists(values.permissions.recentlyUsedLists)
      }
    },
    {
      onSuccess: async () => {
        Toast.displayIntl(
          ['success.updatingItem', { name: 'accounting' }],
          'success'
        )
        queryClient.invalidateQueries(updatesKeys.byId(updateId))
        redirectToShow()
      },
      onError: (_err) => {
        Toast.displayIntl(
          ['errors.updatingItemError', { item: 'accounting' }],
          'error'
        )
      },
    }
  )
}
/**
 * Mutation for updating NOTES, ANNOUNCEMENTS, AND DOCUMENTS
 * NOT --> Does not update transactions
 * FOR TRANSACTIONS --> useUpdateTransactionMutation
 */

interface EditUpdateMutationProps {
  updateType: UpdateType
  updateId: string
  isShowMode: boolean
  updateTypeForUrl?: UpdateTypeForUrl
}

export const useEditUpdateMutation = <T extends Update>({
  updateType,
  updateId,
  isShowMode,
  updateTypeForUrl,
}: EditUpdateMutationProps) => {
  const shouldBehaveAsFounder = useShouldBehaveAsFounder()
  const entity = useEntityFromUrl()
  const currentGroup = useAppSelector(getCurrentGroupData)
  const editDraftMutation = useEditDraftUpdateMutation(updateType)

  const location = useLocation<{ canGoBack?: boolean }>()
  const history = useHistory()
  const canGoBack = location.state?.canGoBack
  const queryClient = useQueryClient()
  const redirectToShow = () => {
    if (location.pathname.includes('/updates')) {
      history.replace(`/updates/${updateTypeForUrl}/${updateId}`)
    } else {
      history.replace(
        `/${entity.name}/${entity.id}/${updateTypeForUrl}/${updateId}`,
        {
          canGoBack,
          showNavigation: true,
        }
      )
    }
  }
  const editQueryHelper = editQueryHelpers[updateType]

  return useMutation(
    editQueryHelper.key,
    async ({
      values,
      update,
      isPreviewingDraft,
    }: {
      values: CreateBasicFormValues
      update: T
      isPreviewingDraft?: boolean
    }) => {
      if (isPreviewingDraft) {
        await editDraftMutation.mutateAsync({
          updateItemId: update.item.id,
          values,
          update,
        })
      } else {
        const { title, body, date, files, permissions } = values
        const isScheduled =
          shouldBehaveAsFounder && dayjs(date).isAfter(new Date())

        const isDocument = updateType === UpdateTypes.DOCUMENT
        const isNote = updateType === UpdateTypes.NOTE
        const hasInvestor = !!values.investor
        const hasPortfolio = !!values.portfolio

        const allowEditInvestorPermissionsOnShow =
          isShowMode && (isNote || isDocument) && hasInvestor && hasPortfolio

        const payload = editQueryHelper.payload({
          updateId,
          title,
          body: body
            ? getSanitizedBodyIfNotExpired(body, update!.expirationDate)!
            : '',
          date,
          schedule: {
            loggingUpdateScheduleId: update!.loggingUpdateSchedule?.id,
            scheduleType: isScheduled
              ? ScheduleType.SCHEDULE
              : ScheduleType.SEND_NOW,
          },
          reshareSettings: {
            isConfidential: permissions.confidentialUpdate,
            isBlocked: update!.blocked,
          },
          ...getEditPermissions({
            permissions,
            currentGroup,
            oldUpdate: update,
            allowEditInvestorPermissionsOnShow,
          }),
          uploads: files,
          shouldBehaveAsFounder,
        })

        await editQueryHelper.service(update!.item.id, payload)

        if (permissions.recentlyUsedLists) {
          updateRecentlyUsedLists(permissions.recentlyUsedLists)
        }
      }
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(updatesKeys.byId(updateId))

        if (!isShowMode) {
          Toast.displayIntl(
            ['success.updatingItem', { name: updateType }],
            'success'
          )
          redirectToShow()
        }
      },
      onError: (_err) => {
        Toast.displayIntl(
          ['errors.updatingItemError', { item: updateType }],
          'error'
        )
      },
    }
  )
}

/**
 * Mutation for updating TRANSACTIONS
 * NOT --> Does not update notes, announcements, and documents
 * FOR NOTES, ANN OR DOC --> useEditUpdateMutation
 */

interface EditUpdateTransactionProps {
  update: TransactionUpdate
  updateId: string
  isShowMode: boolean
  handleEditTransactionError: (
    transactionType: TransactionType,
    error: Error,
    values: CreateTransactionFormValues,
    intl: IntlShape,
    formikRef: RefObject<FormikProps<CreateTransactionFormValues>>
  ) => any
  portfolioId?: string
}

export const useUpdateTransactionMutation = ({
  update,
  updateId,
  isShowMode,
  handleEditTransactionError,
  portfolioId,
}: EditUpdateTransactionProps) => {
  const entity = useEntityFromUrl()
  const location = useLocation<{ canGoBack?: boolean }>()
  const history = useHistory()
  const canGoBack = location.state?.canGoBack
  const { mutation } = useSettings()
  const queryClient = useQueryClient()
  const isAddingUpdateFromPortfolio = location.pathname.includes('portfolios')
  const isAddingUpdateUpdates = location.pathname.includes('updates')
  const currentGroup = useAppSelector(getCurrentGroupData)
  const intl = useIntl()
  const formikRef = useRef<FormikProps<CreateTransactionFormValues>>(null)
  const editDraftMutation = useEditDraftUpdateMutation(UpdateTypes.TRANSACTION)

  const redirectToShow = () => {
    if (location.pathname.includes('/updates')) {
      history.replace(`/updates/transactions/${updateId}`)
    } else {
      history.replace(`/${entity.name}/${entity.id}/transactions/${updateId}`, {
        canGoBack,
        ...location.state,
      })
    }
  }

  return useMutation(
    updatesKeys.editTransaction(),
    async (
      values: CreateTransactionFormValues,
      isPreviewingDraft?: boolean
    ) => {
      if (isPreviewingDraft) {
        await editDraftMutation.mutateAsync({
          updateItemId: update.item.id,
          values,
          update,
        })
      } else {
        const payload = getEditTransactionPayload({
          updateId,
          title: values.title || '',
          body: values.description || '',
          date: values.date!,
          uploads: values.files,
          tags: [],
          shouldBehaveAsFounder: false,
          originalUpdate: update!,
          transactionValues: values,
          reshareSettings: {
            isConfidential: values.permissions.confidentialUpdate,
            isBlocked: update.blocked,
          },
          ...getEditPermissions({
            permissions: values.permissions,
            currentGroup,
            oldUpdate: update,
            values,
          }),
        })

        if (values.notShowShareWithInvestorModal) {
          mutation.debouncedMutate({
            notShowAgainModal: {
              shareWithInvestor: false,
            },
          })
        }

        await UpdateService.editTransaction(update!.item.id, payload)

        if (values.permissions.recentlyUsedLists) {
          updateRecentlyUsedLists(values.permissions.recentlyUsedLists)
        }
      }
    },

    {
      onSuccess: async () => {
        queryClient.invalidateQueries(updatesKeys.byId(updateId))

        if (
          (isAddingUpdateFromPortfolio || isAddingUpdateUpdates) &&
          !isShowMode
        ) {
          Toast.displayIntl(
            ['success.updatingItem', { name: 'transaction' }],
            'success'
          )
          refetchPortfolio({ portfolioId, queryClient })
          history.goBack()
          return
        }

        redirectToShow()
      },
      onError: (err: Error, values) => {
        handleEditTransactionError(values.type!, err, values, intl, formikRef)
      },
    }
  )
}

interface EditReshareMutationProps {
  sharedUsersIds: string[]
  sharedGroupsIds: string[]
  reshareTokenSet: ReshareTokenSet
  reshareMessage: string
  updateType: UpdateType
  updateId: string
  redirectToShow: () => void
}

export const useEditReshareMutation = ({
  sharedUsersIds,
  sharedGroupsIds,
  reshareTokenSet,
  reshareMessage,
  updateType,
  updateId,
  redirectToShow,
}: EditReshareMutationProps) => {
  const queryClient = useQueryClient()

  return useMutation(
    updatesKeys.editResharedUpdate(),
    async () => {
      const removedGroups = getRemovedLoggingUpdateEntityIds(
        reshareTokenSet!.loggingUpdateEntities.filter(castLoggingUpdateGroup),
        sharedGroupsIds,
        UpdateVisibility.CUSTOM
      )

      const removedUsers = getRemovedLoggingUpdateEntityIds(
        reshareTokenSet!.loggingUpdateEntities.filter(castLoggingUpdateUser),
        sharedUsersIds,
        UpdateVisibility.CUSTOM
      )

      await UpdateService.editReshare(
        reshareTokenSet!.token,
        reshareMessage,
        sharedGroupsIds,
        sharedUsersIds,
        removedGroups,
        removedUsers
      )
    },
    {
      onSuccess: async () => {
        Toast.displayIntl(['updates.reshareSuccess', { updateType }], 'success')
        queryClient.invalidateQueries(updatesKeys.byId(updateId))
        redirectToShow()
      },
      onError: () => {
        Toast.displayIntl(['updates.errorEditingReshareUpdate'], 'error')
      },
    }
  )
}

interface CreateReshareMutationProps {
  sharedUsersIds: string[]
  sharedGroupsIds: string[]
  reshareMessage: string
  updateId: string
  updateType: UpdateType
  redirectToShow: () => void
}

export const useCreateReshareMutation = ({
  sharedUsersIds,
  sharedGroupsIds,
  reshareMessage,
  updateId,
  updateType,
  redirectToShow,
}: CreateReshareMutationProps) => {
  const queryClient = useQueryClient()

  return useMutation(
    updatesKeys.createReshareUpdate(),
    async () => {
      if (!updateId) return

      await UpdateService.reshareUpdate(
        updateId,
        sharedUsersIds,
        sharedGroupsIds,
        reshareMessage
      )
    },
    {
      onSuccess: async () => {
        Toast.displayIntl(['updates.reshareSuccess', { updateType }], 'success')
        queryClient.invalidateQueries(updatesKeys.byId(updateId))
        redirectToShow()
      },
      onError: () => {
        Toast.displayIntl(['updates.errorCreatingReshareUpdate'], 'error')
      },
    }
  )
}
