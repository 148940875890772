import React from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupData } from 'selectors/auth'
import SideBarGroupSelector from './components/SideBarGroupSelector'
import ExpandableGroupSelector from './components/ExpandableGroupSelector'

interface GroupSelectorProps {
  isDesktop?: boolean
  onToggleGroupSection: (isExpanded: boolean) => void
  onShowSideBarGroupSelector: (groupId: string) => void
}

const GroupSelector: React.FC<GroupSelectorProps> = ({
  isDesktop,
  onShowSideBarGroupSelector,
  onToggleGroupSection,
}) => {
  const currentGroup = useAppSelector(getCurrentGroupData)

  if (!currentGroup) return null

  if (isDesktop) {
    return (
      <ExpandableGroupSelector onToggleGroupSection={onToggleGroupSection} />
    )
  }

  return (
    <SideBarGroupSelector
      onShowSideBarGroupSelector={onShowSideBarGroupSelector}
    />
  )
}

GroupSelector.defaultProps = {
  isDesktop: false,
}

export default GroupSelector
