import React, { useState } from 'react'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'

import { ReactComponent as EmptyPieChart } from 'assets/images/pie-chart-empty.svg'
import {
  getChartColor,
  AllFundsChartDataWithPercentages,
} from 'utils/functions/portfolios'
import { ChartColors } from 'utils/types/portfolios'
import PortfolioChartTooltip from './FundPortfoliosPieChartTooltip'
import { PieChartHoverContainer } from './FundPortfoliosPieChart.styles'

interface FundPortfoliosPieChartProps {
  chartData: AllFundsChartDataWithPercentages[]
  colorsForPortfolios: ChartColors
}

const hasChartdata = (chartData: AllFundsChartDataWithPercentages[]) =>
  chartData.some(
    (data) =>
      (data.totalCommittedCapital && data.totalCommittedCapital !== 0) ||
      (data.totalCapitalCalled && data.totalCapitalCalled !== 0)
  )

const FundPortfoliosPieChart: React.FC<FundPortfoliosPieChartProps> = ({
  chartData,
  colorsForPortfolios,
}) => {
  const [hover, setHover] = useState(false)

  if (!hasChartdata(chartData)) {
    return <EmptyPieChart />
  }

  const outsidePie = 'totalCommittedCapital'
  const insidePie = 'totalCapitalCalled'

  return (
    <PieChartHoverContainer hover={hover}>
      <PieChart width={170} height={170}>
        <Pie
          id={outsidePie}
          data={chartData}
          dataKey={outsidePie}
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={52}
          outerRadius={81}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {chartData.map((entry, index) => {
            return (
              <Cell
                key={entry.id}
                fill={colorsForPortfolios[entry.id] || getChartColor(index)}
              />
            )
          })}
        </Pie>

        <Pie
          id={insidePie}
          data={chartData}
          dataKey={insidePie}
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={20}
          outerRadius={50}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          {chartData.map((entry, index) => {
            return (
              <Cell
                key={entry.id}
                fill={colorsForPortfolios[entry.id] || getChartColor(index)}
              />
            )
          })}
        </Pie>

        <Tooltip
          allowEscapeViewBox={{ x: true, y: true }}
          offset={-110}
          content={
            <PortfolioChartTooltip colorsForPortfolios={colorsForPortfolios} />
          }
        />
      </PieChart>
    </PieChartHoverContainer>
  )
}

export default FundPortfoliosPieChart
