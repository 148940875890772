import { create } from 'zustand'
import { Nullable } from './types/common'

export const useAuth0Store = create<{
  auth0Domain: Nullable<string>
  setAuth0Domain: (value: Nullable<string>) => void
}>((set) => ({
  auth0Domain: null,
  setAuth0Domain: (value: Nullable<string>) =>
    set(() => ({ auth0Domain: value })),
}))
