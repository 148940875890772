import { Channel, useChatContext } from 'stream-chat-react'
import { useParams } from 'react-router-dom'
import { useCallback, useLayoutEffect, useState } from 'react'

import { StreamChatType } from 'containers/Chat/types'
import Separator from 'ui/Separator'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import CWLoader from 'components/CWLoader'

import {
  useChatSidebarContext,
  useForceUpdateOnChatUserUpdated,
} from '../../hooks'
import { ChatSidebarContent } from '../ChatSidebar'
import ChatSearch from '../ChatSearch'
import ThreadHeader from '../ThreadHeader/ThreadHeader'
import Message from '../Message'
import MessageInput from '../MessageInput'
import TypingIndicator from '../TypingIndicator'
import EmptyComponent from '../EmptyComponent'
import DateSeparator from '../DateSeparator'
import SystemMessage from '../SystemMessage'
import SuggestionItem from '../ChatSuggestions/SuggestionItem'
import EmptyPlaceholder from '../ZeroStates/EmptyPlaceholder'
import EmptyStateIndicator from '../ZeroStates/EmptyStateIndicator'
import ArchivedChannelsSidebar from '../ArchivedChannelsSidebar'
import ChatSearchInput from '../ChatSidebar/components/ChatSearchInput'
import TopicSidebar from '../TopicSidebar'
import ChatChannelContent from '../../ChatChannelContent'
import { useChatSearchContext } from '../../hooks/useChatSearch'
import { CHAT_MOBILE_STEPS, useChatMobileContext } from './useChatMobileContext'
import * as ChatStyles from '../../Chat.styles'
import * as Styles from './ChatMobile.styles'

interface Props {
  isSearchViewVisible: boolean
}

const ChatMobile = ({ isSearchViewVisible }: Props) => {
  const { matches: isMobile } = useMediaQuery(maxSize.mobile)
  const { hideSearchView } = useChatSidebarContext()
  const { search } = useChatSearchContext()
  const { channel: activeChannel } = useChatContext()
  const isSearchVisible = isSearchViewVisible && !!search?.length
  const { step, openChannel } = useChatMobileContext()

  const showArchivedSidebar =
    step === CHAT_MOBILE_STEPS.ARCHIVED && !activeChannel
  const showTopicSidebar = step === CHAT_MOBILE_STEPS.TOPIC_SIDEBAR
  const isShowingATopicChannel =
    (CHAT_MOBILE_STEPS.ARCHIVED === step && activeChannel) ||
    step === CHAT_MOBILE_STEPS.TOPIC_CHANNEL

  const [isLoading, setIsLoading] = useState(false)
  const { client } = useChatContext<StreamChatType>()
  const { channelId } = useParams<{ channelId?: string }>()

  const setInitialStep = useCallback(async () => {
    try {
      if (channelId) {
        setIsLoading(true)
        const [channel] = await client.queryChannels({ id: channelId })

        if (channel) {
          openChannel(channel)
        }
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useForceUpdateOnChatUserUpdated()

  useLayoutEffect(() => {
    setInitialStep()
  }, [setInitialStep])

  if (!isMobile) return null

  if (isLoading) return <CWLoader />

  return (
    <Styles.MobileLayout>
      {(step === CHAT_MOBILE_STEPS.SIDEBAR ||
        showArchivedSidebar ||
        step === CHAT_MOBILE_STEPS.TOPIC_SIDEBAR) && <ChatSearchInput />}

      <ChatStyles.VisibleContainer visible={isSearchVisible}>
        <Separator height="2rem" />
        <ChatSearch hideSearchView={hideSearchView} />
      </ChatStyles.VisibleContainer>

      <Styles.VisibleContainer
        isVisible={CHAT_MOBILE_STEPS.SIDEBAR === step && !isSearchVisible}
      >
        <ChatSidebarContent isMobile />
      </Styles.VisibleContainer>

      <Styles.VisibleContainer
        isVisible={showArchivedSidebar && !isSearchVisible}
      >
        <ArchivedChannelsSidebar preventSelectArchivedChannelOnQuery />
      </Styles.VisibleContainer>

      <Styles.VisibleContainer isVisible={showTopicSidebar && !isSearchVisible}>
        <TopicSidebar />
      </Styles.VisibleContainer>

      {(isShowingATopicChannel ||
        CHAT_MOBILE_STEPS.DIRECT_MESSAGE === step) && (
        <ChatStyles.VisibleContainer visible={!isSearchVisible}>
          <Channel
            Message={Message}
            Input={MessageInput}
            MessageListNotifications={EmptyComponent}
            TypingIndicator={TypingIndicator}
            ThreadHeader={ThreadHeader}
            ThreadStart={EmptyComponent}
            LoadingIndicator={EmptyComponent}
            EmptyPlaceholder={<EmptyPlaceholder />}
            EmptyStateIndicator={EmptyStateIndicator}
            DateSeparator={DateSeparator}
            MessageSystem={SystemMessage}
            AutocompleteSuggestionItem={SuggestionItem}
          >
            <ChatChannelContent />
          </Channel>
        </ChatStyles.VisibleContainer>
      )}
    </Styles.MobileLayout>
  )
}

export default ChatMobile
