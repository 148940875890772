import { TRIAL_DAYS, getTrialDaysLeft } from 'utils/constants/subscriptions'
import { FormattedMessage } from 'react-intl'
import * as Styles from './SubscriptionTrialIndicator.styles'

type Props = {
  trialEndDate: string
}

const SubscriptionTrialIndicator = ({ trialEndDate }: Props) => {
  const daysLeft = getTrialDaysLeft(trialEndDate)

  return (
    <Styles.Container>
      <Styles.DaysLeft>
        <FormattedMessage
          id="subscriptions.trialEndsIndicator"
          values={{
            bold: (msg) => <b>{msg}</b>,
            countDays: daysLeft,
          }}
        />
      </Styles.DaysLeft>
      <Styles.Indicator>
        <Styles.Progress max={TRIAL_DAYS} value={daysLeft} />
      </Styles.Indicator>
    </Styles.Container>
  )
}

export default SubscriptionTrialIndicator
