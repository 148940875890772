import { BaseSyntheticEvent, useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMessageContext } from 'stream-chat-react'
import type { ReactionsListProps, EmojiContextValue } from 'stream-chat-react'
import type { ReactionEmoji } from 'stream-chat-react/dist/components/Channel/emojiData'

import { getUser } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import useOutsideClick from 'utils/hooks/useOutsideClick'

import { reactions } from '../ReactionSelector/reactions'

type SharedReactionListProps = 'own_reactions' | 'reaction_counts' | 'reactions'

type UseProcessReactionsParams = Pick<
  ReactionsListProps,
  SharedReactionListProps
> &
  Pick<EmojiContextValue, 'emojiConfig'>

export const useProcessReactions = (params: UseProcessReactionsParams) => {
  const intl = useIntl()
  const currentUser = useAppSelector(getUser)
  const [showDetailedReactions, setShowDetailedReactions] = useState(false)
  const {
    own_reactions: propOwnReactions,
    reaction_counts: propReactionCounts,
    reactions: propReactions,
  } = params
  const {
    message,
    handleReaction: defaultHandleReaction,
    reactionSelectorRef,
  } = useMessageContext()

  const reactionCounts = propReactionCounts || message.reaction_counts || {}
  const reactionOptions = reactions
  const latestReactions = useMemo(
    () => propReactions || message.latest_reactions || [],
    [message.latest_reactions, propReactions]
  )
  const ownReactions = useMemo(
    () => propOwnReactions || message?.own_reactions || [],
    [message?.own_reactions, propOwnReactions]
  )

  const iHaveReactedWithReaction = useCallback(
    (reactionType: string) =>
      ownReactions.find((reaction) => reaction.type === reactionType),
    [ownReactions]
  )

  const getEmojiByReactionType = useCallback(
    (type: string): ReactionEmoji | undefined =>
      reactionOptions.find((option: ReactionEmoji) => option.id === type),
    [reactionOptions]
  )

  const openDetailedReactions = useCallback(() => {
    setShowDetailedReactions(true)
  }, [])

  const handleReaction = useCallback(
    async (
      reactionType: string,
      event: BaseSyntheticEvent<object, any, any>
    ) => {
      setShowDetailedReactions(false)
      await defaultHandleReaction(reactionType, event)
    },
    [defaultHandleReaction]
  )

  useOutsideClick(reactionSelectorRef, () => {
    setShowDetailedReactions(false)
  })

  const latestReactionTypes = useMemo(
    () =>
      latestReactions.reduce<string[]>((reactionTypes, { type }) => {
        if (reactionTypes.indexOf(type) === -1) {
          reactionTypes.push(type)
        }
        return reactionTypes
      }, []),
    [latestReactions]
  )

  const aggregatedUserNamesByType = useMemo(
    () =>
      latestReactions.reduce<Record<string, Array<string>>>(
        (typeMap, { type, user }) => {
          const types = typeMap[type] || []
          types.push(user?.name || user!.id)

          return { ...typeMap, [type]: types }
        },
        {}
      ),
    [latestReactions]
  )

  const getUsersPerReactionType = useCallback(
    (type: string | undefined) =>
      latestReactions
        .map((reaction) => {
          if (type && reaction.type === type) {
            if (reaction.user?.id === currentUser?.id) {
              return intl.formatMessage({
                id: 'general.you',
              })
            }

            return reaction.user?.name || reaction.user?.id || ''
          }
          return ''
        })
        .filter(Boolean),
    [latestReactions, currentUser?.id, intl]
  )

  return {
    aggregatedUserNamesByType,
    latestReactionTypes,
    reactionCounts,
    showDetailedReactions,
    reactionSelectorRef,
    handleReaction,
    openDetailedReactions,
    getEmojiByReactionType,
    iHaveReactedWithReaction,
    getUsersPerReactionType,
  }
}
