import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'
import FileIconToStyle from 'components/FileIcon'
import FadeInToStyle from 'components/FadeIn'

const Padding = '3.2rem'
const CircleHeight = 4
const HeaderHeight = `calc(${Padding} + ${Padding} + ${CircleHeight}rem)`

export const FadeIn = styled(FadeInToStyle)`
  position: fixed;
  width: 100vw;
  height: calc(var(--viewHeight, 100vh));
  left: 0;
  top: 0;
  z-index: 100;
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    178.85deg,
    ${color('darkBlue')} 2.21%,
    ${color('darkBlue', 0.8)} 27.57%,
    ${color('darkBlue', 0.7)} 51.06%
  );
  padding: 0 ${Padding};
  display: flex;
  flex-direction: column;
  user-select: none;

  @media ${maxSize.md} {
    padding: 0 2.4rem;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.2rem 0;
  z-index: 2;
  height: ${HeaderHeight};
`

export const FileIcon = styled(FileIconToStyle)<{
  isAnImage: boolean
  isEmailContent: boolean
}>`
  margin-left: 3.6rem;

  div {
    ${({ isAnImage }) =>
      isAnImage &&
      css`
        background-color: ${color('primaryBlue')};
      `};

    ${({ isEmailContent }) =>
      isEmailContent &&
      css`
        background-color: ${color('white')};
      `}

    svg {
      font-size: 1.6rem;
    }
  }
`

const HeaderWidthWithoutActions = 'calc(100vw - (2.4rem * 2) - 9rem - 8rem)'
const PDFControlsWidth = '51rem'

export const FileName = styled.span<{
  isAPDFFile: boolean
}>`
  font-size: 1.6rem;
  font-weight: 700;
  color: ${color('white')};
  margin-left: 0.8rem;
  line-height: 1.9rem;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${HeaderWidthWithoutActions};
  user-select: text;

  ${({ isAPDFFile }) =>
    isAPDFFile &&
    css`
      max-width: calc(${HeaderWidthWithoutActions} - ${PDFControlsWidth});

      @media ${maxSize.md} {
        max-width: ${HeaderWidthWithoutActions};
      }
    `}
`

export const Icon = styled(FontAwesomeIcon)`
  font-size: 1.8rem;
  color: ${color('white')};
  cursor: pointer;
`

export const HorizontalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Circle = styled.div<{ withMargin?: boolean; disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${color('white')};
  border: 1px solid ${color('veryLightGray')};
  min-width: calc(4.05rem - 0.5px);
  min-height: calc(4.05rem - 0.5px);
  margin-right: ${({ withMargin }) => (withMargin ? '3.6rem' : 0)};
  cursor: pointer;
  z-index: 3;

  &:hover {
    filter: brightness(0.9);
  }

  ${Icon} {
    color: ${color('darkBlue')};
    font-size: 1.4rem;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      filter: brightness(0.5);
    `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(100% - ${HeaderHeight});
  padding-bottom: 3.2rem;
`

export const Angle = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  color: ${color('darkBlue')};
  padding: 0.2rem;
`

export const PreviewContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
