import Modal from 'components/Modal'
import styled from 'styled-components'
import RadioButtonToStyle from 'ui/RadioButton/RadioButton'

export const StyledModal = styled(Modal)`
  width: 56.3rem;
`

export const RowFooter = styled(Modal.RowFooter)`
  padding: 2.4rem;
`

export const RadioButton = styled(RadioButtonToStyle)`
  label {
    margin: 0;
    margin-left: -0.2rem;
    padding-left: 0.2rem;

    &:nth-child(2) {
      margin: -0.8rem 0 -0.8rem -0.2rem;
    }
  }
`
