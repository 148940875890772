import { useRouteMatch, Route, Switch } from 'react-router-dom'
import ShowAccounting from 'containers/UpdatesV2/Accounting/ShowAccounting'
import CreateAccounting from 'containers/UpdatesV2/Accounting/CreateAccounting'
import EditAccounting from 'containers/UpdatesV2/Accounting/EditAccounting'

const AccountingRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <CreateAccounting />
      </Route>
      <Route exact path={`${path}/:updateId`}>
        <ShowAccounting />
      </Route>
      <Route exact path={`${path}/:updateId/edit`}>
        <EditAccounting />
      </Route>
    </Switch>
  )
}

export default AccountingRoutes
