import { useEffect, useLayoutEffect, useRef } from 'react'
import { getCurrentGroupId } from 'selectors/auth'
import { getHasChatFeature } from 'selectors/chat'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useResizeObserver } from 'utils/hooks/useResizeObserver'
import useSettings from 'utils/hooks/useSettings'
import * as FullStory from 'utils/integrations/fullStory'

const useMainLayout = () => {
  const hasChatFeature = useAppSelector(getHasChatFeature)
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const { settings } = useSettings()
  const screenSize = useResizeObserver()

  const mainLayoutRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const viewHeight = window.innerHeight
    document.documentElement.style.setProperty(
      '--viewHeight',
      `${viewHeight}px`
    )
  }, [screenSize])

  useEffect(() => {
    if (settings?.privacy?.sharingUsageData) {
      FullStory.initialize()
    } else {
      FullStory.shutdown()
    }
  }, [settings])

  useLayoutEffect(() => {
    const mainLayoutWidth = mainLayoutRef.current?.offsetWidth

    document.documentElement.style.setProperty(
      '--mainLayoutWidth',
      `${mainLayoutWidth}px`
    )
  }, [screenSize])

  return {
    hasChatFeature,
    currentGroupId,
    mainLayoutRef,
  }
}

export default useMainLayout
