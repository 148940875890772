import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { color } from 'utils/functions/colors'
import RadioButtonToStyle from 'ui/RadioButton'

import StatusIndicatorToStyle from '../StatusIndicator'

export const WizardHeader = styled.div`
  padding: 2.4rem 3.4rem;
`

export const Numbers = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
`

export const Description = styled.span<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('darkGray')};
  display: block;
`

export const ErrorMessage = styled(Description)`
  font-size: 1.2rem;
  color: ${color('red')};
`

export const RadioLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const RadioLabel = styled.label<{ bold?: boolean }>`
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: ${color('darkGray')};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
`

export const RadioButton = styled(RadioButtonToStyle)`
  padding-top: initial;

  > label {
    align-items: flex-start;
  }
`

const StepContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
`

export const TypeOfAccountStepContainer = styled.div`
  ${StepContainerStyles}
  margin-top: 0.3rem;
`

export const EmailStepContainer = styled.div`
  ${StepContainerStyles}
  margin-top: 1.9rem;
`

export const InputContainer = styled.div`
  ${StepContainerStyles}
  gap: 0.8rem;
`

export const ConnectStepContainer = styled.div`
  ${StepContainerStyles}
  margin-top: 1.9rem;
`

export const InfoContainer = styled.div`
  display: flex;
  column-gap: 0.625rem;
  color: ${color('lightGray')};
  font-size: 1.2rem;
  line-height: 1.44rem;
  font-weight: 400;
  flex-wrap: wrap;

  svg {
    font-size: 1.6rem;
  }
`

export const ClickHere = styled.a`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const GeneratedEmailContainer = styled.div`
  display: flex;
  padding: 1rem 1.6rem;
  gap: 0.8rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${color('veryLightGray')};
  border-radius: 0.8rem;
  margin-bottom: 0.8rem;
`

export const GeneratedEmail = styled.span`
  font-size: 1.4rem;
  line-height: 1.68rem;
  color: ${color('primaryBlue')};
  font-weight: 700;
  border-bottom: 1px solid ${color('primaryBlue')};
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const CloneIcon = styled(FontAwesomeIcon)`
  color: ${color('darkBlue')};
  font-size: 1.6rem;
  cursor: pointer;
`

export const StatusIndicatorContainer = styled.div`
  border-top: 1px solid ${color('borderGray')};
  padding: 2.7rem 2.6rem;
  width: calc(100% + 2.6rem + 2.6rem);
  margin-left: -2.6rem;
  display: flex;
  flex-direction: column;

  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.03em;
  color: ${color('darkGray')};
`

export const StatusIndicator = styled(StatusIndicatorToStyle)`
  span {
    font-size: 1.2rem;
  }

  margin-right: 1.6rem;
`

export const StatusWithTimerContainer = styled.div`
  display: flex;
  min-height: 3rem;
`
