export default {
  exploreOpportunities: 'Explore Opportunities',
  loadingOpportunities: 'Loading Opportunities...',
  loadingMoreHoldings: 'Loading more holdings...',
  loadingHoldings: 'Loading holdings...',
  addNew: 'Add new',
  addHolding: 'Add holding',
  noResultsTitle: 'No holdings found',
  noResultsLegend: "Sorry, we couldn't find any holding matching your search",
  addHoldingTooltip: 'Can’t find the profile you’re looking for?',
  addHoldingToCW: 'Add Holding to CWUniverse.',
  holdings: 'Holdings',
  location: 'Location',
  sectors: 'Sectors',
  industries: 'Industries',
  lastUpdate: 'Last Update',
  errors: {
    errorWhenFetchingHolding:
      'An error occurred when trying to get the company information, try again later',
  },
  zeroState: {
    title: 'Welcome to our opportunities index',
    text: 'Search for investment opportunities in Clockwork Universe. If you are searching for a holding that does not yet exist in the system, we invite you to add it by clicking on "Add New".',
  },
}
