import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Input from 'ui/Input'
import Button from 'ui/Button'
import { PromotionCode } from 'utils/types/subscriptions'
import SubscriptionService from 'api/SubscriptionService'
import * as Styles from './CouponCode.styles'

type Props = {
  handlePromotionCodeAdded: (code: PromotionCode) => void
  handlePromotionCodeRemoved: () => void
}

const CouponCode = ({
  handlePromotionCodeAdded,
  handlePromotionCodeRemoved,
}: Props) => {
  const intl = useIntl()

  const [couponCode, setCouponCode] = useState<{
    showInput: boolean
    isLoading?: boolean
    isValid: boolean
    code: string
    promotionCode: PromotionCode | null
  }>({
    isLoading: false,
    showInput: false,
    isValid: true,
    code: '',
    promotionCode: null,
  })

  const validateCouponCode = async () => {
    try {
      setCouponCode((prev) => ({
        ...prev,
        isLoading: true,
      }))
      const promotionCode = await SubscriptionService.validateCoupon(
        couponCode.code
      )
      setCouponCode((prev) => ({
        ...prev,
        isValid: true,
        promotionCode,
        isLoading: false,
      }))
      handlePromotionCodeAdded(promotionCode)
    } catch (err) {
      setCouponCode((prev) => ({
        ...prev,
        isValid: false,
        isLoading: false,
      }))
    }
  }

  const removeCouponCode = () => {
    setCouponCode((prev) => ({
      ...prev,
      isValid: true,
      promotionCode: null,
      code: '',
      isLoading: false,
    }))
    handlePromotionCodeRemoved()
  }

  return (
    <>
      <Styles.CouponCodeContainer>
        {!couponCode.showInput ? (
          <Button
            link
            add
            onClick={() =>
              setCouponCode((prev) => ({
                ...prev,
                showInput: true,
              }))
            }
          >
            {intl.formatMessage({
              id: 'signup.plans.addPromoCodeCaps',
            })}
          </Button>
        ) : (
          <>
            <Input
              placeholder={intl.formatMessage({
                id: 'signup.plans.addPromoCode',
              })}
              value={couponCode.code}
              onChange={(e) =>
                setCouponCode((prev) => ({
                  ...prev,
                  code: e.target.value,
                }))
              }
              disabled={!!couponCode.promotionCode}
            />
            <Button
              secondaryDanger={!!couponCode.promotionCode}
              disabled={!couponCode.code || couponCode.isLoading}
              onClick={
                couponCode.promotionCode ? removeCouponCode : validateCouponCode
              }
            >
              {couponCode.promotionCode ? (
                <FormattedMessage id="common.remove" />
              ) : (
                <FormattedMessage id="common.apply" />
              )}
            </Button>
          </>
        )}
      </Styles.CouponCodeContainer>
      {!couponCode.isValid && (
        <Styles.CouponError>
          <FormattedMessage id="subscriptions.invalidPromotionCode" />
        </Styles.CouponError>
      )}
    </>
  )
}

export default CouponCode
