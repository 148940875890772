import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { Button } from '../UpdateHeader/UpdateHeader.styles'

export const ReportSettingsTitle = styled.div`
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;
  margin: 3.8rem 1.8rem 2.5rem 0.1rem;
  text-transform: uppercase;
`

export const ReportSettingsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2.8rem;
  margin: 2.5rem 0rem;

  button[class*='Button_btn'] {
    color: black;
    font-size: 1.4rem;
    width: auto;
    width: 22rem;
    height: 3.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #979797;
    padding: 1rem;
    svg {
      width: 1.5rem;
    }
  }

  @media ${maxSize.md} {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
`

export const DropdownContainer = styled.div`
  @media ${maxSize.md} {
    width: 100%;
  }
`

export const PreviewReportButton = styled(Button)`
  margin-top: 2.1rem;
  width: 22rem;
`
