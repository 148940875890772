import type { IntlShape } from 'react-intl'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import * as yup from 'yup'
import { HoldingType } from './types'

export const getSingleSchema = (intl: IntlShape) => {
  return yup.object().shape({
    type: yup
      .string()
      .oneOf(Object.values([HoldingType.COMPANY, HoldingType.FUND]))
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    company: yup.object().when('type', {
      is: HoldingType.COMPANY,
      then: yup.object({
        legalEntityName: yup
          .string()
          .min(2, intl.formatMessage({ id: 'general.min2Chars' }))
          .required(intl.formatMessage({ id: 'general.requiredField' })),
        name: yup
          .string()
          .min(2, intl.formatMessage({ id: 'general.min2Chars' }))
          .max(
            MAX_COUNTER_CHARACTERS,
            intl.formatMessage(
              { id: 'general.youHaveExceededCharacterLimit' },
              { limit: MAX_COUNTER_CHARACTERS }
            )
          )
          .required(intl.formatMessage({ id: 'general.requiredField' })),
        website: yup
          .string()
          .url(intl.formatMessage({ id: 'general.invalidUrl' })),
        pointOfContact: yup
          .string()
          .email(intl.formatMessage({ id: 'general.invalidEmail' })),
        logo: yup.mixed(),
      }),
    }),
    funds: yup.object().when('type', {
      is: HoldingType.FUND,
      then: yup.object({
        funds: yup
          .array()
          .of(yup.string())
          .required()
          .test(
            'funds',
            intl.formatMessage({ id: 'general.requiredField' }),
            (funds) => !!funds?.length && funds.some((fund) => !!fund)
          ),
        includeFundManager: yup.boolean().required(),
        fundManager: yup.object().when('includeFundManager', {
          is: true,
          then: yup
            .object({
              name: yup
                .string()
                .min(2, intl.formatMessage({ id: 'general.min2Chars' }))
                .required(intl.formatMessage({ id: 'general.requiredField' })),
              website: yup
                .string()
                .url(intl.formatMessage({ id: 'general.invalidUrl' })),
              pointOfContact: yup
                .string()
                .email(intl.formatMessage({ id: 'general.invalidEmail' })),
            })
            .required(),
        }),
      }),
    }),
  })
}

export const getArraySchema = (intl: IntlShape) => {
  return yup.object().shape({
    holdings: yup.array().of(getSingleSchema(intl)),
  })
}
