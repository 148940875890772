import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Wrapper = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightBlue')};
  color: ${color('darkBlue')};
  font-size: 1.8rem;
  margin-bottom: 0.4rem;
  margin-top: 1.9rem;
  padding: 1.6rem;

  .showInDiscover {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`
