import React from 'react'
import { useSelector } from 'react-redux'
import { itemAnimation, listAnimation } from 'utils/animations/list'
import { ReshareTokenSet } from 'utils/functions/updates'
import { getCurrentGroupId, getUserId } from 'selectors/auth'
import ReshareHistoryRow from './ReshareHistoryRow'
import {
  ReshareHistoryList,
  ReshareHistoryListItem,
} from './ReshareHistory.styles'

interface ReshareHistoryProps {
  resharesByToken: ReshareTokenSet[]
  handleSetSelectedReshareTokenSet: (tokenSet: ReshareTokenSet) => void
}

const ReshareHistory: React.FC<ReshareHistoryProps> = ({
  resharesByToken,
  handleSetSelectedReshareTokenSet,
}) => {
  const currentUserId = useSelector(getUserId)
  const currentGroupId = useSelector(getCurrentGroupId)

  return (
    <ReshareHistoryList
      initial="hidden"
      animate="visible"
      variants={listAnimation}
    >
      {resharesByToken.map((reshareTokenSet) => (
        <ReshareHistoryListItem
          key={reshareTokenSet.token}
          variants={itemAnimation}
        >
          <ReshareHistoryRow
            reshareTokenSet={reshareTokenSet}
            isEditable={
              reshareTokenSet.resharerGroupId === currentGroupId &&
              reshareTokenSet.resharerUserId === currentUserId
            }
            onEditReshare={() =>
              handleSetSelectedReshareTokenSet(reshareTokenSet)
            }
          />
        </ReshareHistoryListItem>
      ))}
    </ReshareHistoryList>
  )
}

export default ReshareHistory
