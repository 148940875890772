import { useAppSelector } from 'utils/hooks/reduxToolkit'

import {
  getCompanyFund,
  getCurrentGroupData,
  isActingAsFundManager,
  isActingAsFounder,
} from 'selectors/auth'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import { Nullable } from 'utils/types/common'
import { CompanyHolding } from 'utils/types/company'
import { Group } from 'utils/types/user'

const useGroupCompany = (): Nullable<CompanyHolding> => {
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isFounderGroup = useAppSelector(isActingAsFounder)
  const companyFund = useAppSelector(getCompanyFund)
  const currentGroup = useAppSelector(getCurrentGroupData)

  if (isFundManager) {
    return ResponseNormalizer.normalizeHolding(companyFund)! as CompanyHolding
  }

  if (isFounderGroup) {
    return ResponseNormalizer.normalizeHolding(
      (currentGroup as Group)?.companyData?.[0]!
    ) as CompanyHolding
  }

  return null
}

export default useGroupCompany
