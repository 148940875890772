import { useIntl } from 'react-intl'
import { ButtonWrapper } from 'components/Breadcrumb/UpdateActions/UpdateActions.styles'

import { DraftEditor } from 'utils/types/update'
import Tooltip from 'components/Tooltip'

type EditionLocked = {
  userEditing: DraftEditor
}

interface Props {
  disabled: boolean
  editionLocked: EditionLocked
  handleOpenShareSettingsModal: () => void
}

const ShareSettingsButton = ({
  disabled,
  editionLocked,
  handleOpenShareSettingsModal,
}: Props) => {
  const isEditionLocked = !!editionLocked?.userEditing
  const intl = useIntl()

  if (isEditionLocked) {
    const blockedByText = intl.formatMessage(
      { id: 'updates.header.updateLocked' },
      {
        userName: editionLocked?.userEditing.name,
      }
    )

    return (
      <Tooltip
        id={`shareSettingsUpdateLockedBy${editionLocked?.userEditing.name}`}
        text={blockedByText}
        place="bottom"
        widthAuto
        delayShow={500}
      >
        <ButtonWrapper
          transparent
          disabled
          onClick={handleOpenShareSettingsModal}
        >
          {intl.formatMessage({
            id: 'breadcrumb.share',
          })}
        </ButtonWrapper>
      </Tooltip>
    )
  }

  return (
    <ButtonWrapper
      transparent
      disabled={disabled}
      onClick={handleOpenShareSettingsModal}
      data-testid="share-settings-button"
    >
      {intl.formatMessage({
        id: 'breadcrumb.share',
      })}
    </ButtonWrapper>
  )
}

export default ShareSettingsButton
