import React from 'react'
import { get } from 'lodash'
import { VariableSizeList } from 'react-window'
import Row from '../Row'

import { useTableContext } from '../TableContextProvider'
import { Expandable } from '../Row/Row.styles'

const List = React.forwardRef((_, ref) => {
  const {
    tableLayoutHelpers,
    innerScroll,
    data,
    rowHeight,
    overflow,
    keyPath,
    embeddedTable,
    customRows,
    tableWidth,
  } = useTableContext()

  const customRow = customRows?.find((cr) => cr.index === 0 && cr.showCustomRow)

  if (!data.length && customRow) {
    return (
      <Expandable id="custom-row" visible width={tableWidth}>
        {customRow?.component}
      </Expandable>
    )
  }
  return (
    <VariableSizeList
      ref={ref}
      height={
        innerScroll || embeddedTable
          ? data.length * rowHeight + 15
          : window.innerHeight
      }
      overscanCount={20}
      width="100%"
      itemCount={data.length}
      itemSize={tableLayoutHelpers.getSize}
      itemData={data}
      style={
        !innerScroll
          ? { height: '100% !important', overflow: 'visible' }
          : { overflow }
      }
      itemKey={(index, rowData) => {
        return get(rowData[index], keyPath)
      }}
    >
      {Row}
    </VariableSizeList>
  )
})

export default List
