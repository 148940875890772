import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, useIntl } from 'react-intl'
import CellErrorTooltip from 'components/Spreadsheet/CellTemplates/components/CellErrorTooltip/CellErrorTooltip'
import * as DefaultStyles from '../../../../UpdateMetricsGrid/components/MetricsSpreadsheetDropdown/MetricsSpreadsheetDropdown.styles'
import { INVALID_OPTION_ID } from '../../CustomStaticDropdownCellTemplate'
import {
  StaticSpreadsheetDropdownProps,
  useStaticSpreadsheetDropdown,
} from './useStaticSpreadsheetDropdown'
import * as Styles from './StaticSpreadsheetDropdown.styles'

const StaticSpreadsheetDropdown = ({
  cell,
  options,
  isInEditMode,
  onCellChanged,
  getCompatibleCell,
  eventHandler,
}: StaticSpreadsheetDropdownProps) => {
  const intl = useIntl()
  const {
    selectedOption,
    isOpen,
    dropdownRef,
    inputHeight,
    optionsRef,
    placeholderId,
    stopPropagation,
    onKeyDown,
    highlightedOption,
    onSelectOption,
    onArrowClick,
  } = useStaticSpreadsheetDropdown({
    cell,
    options,
    isInEditMode,
    onCellChanged,
    getCompatibleCell,
    eventHandler,
  })

  const isInvalid = selectedOption?.id === INVALID_OPTION_ID || !!cell.error

  return (
    <Styles.Container isInvalid={isInvalid} isAutoFilled={cell.autoFilled}>
      <CellErrorTooltip
        content={
          isInvalid && cell.error ? <FormattedMessage id={cell.error} /> : null
        }
        cellWidth={cell.width}
        color={cell.overrideTooltipBackground}
        clickable={false}
      >
        <DefaultStyles.SelectedOption
          hasMetric={!!selectedOption}
          isOpen={isOpen}
          ref={dropdownRef}
        >
          <DefaultStyles.Name
            empty={!selectedOption?.label}
            centered={cell.centered}
          >
            {selectedOption?.label || intl.formatMessage({ id: placeholderId })}
          </DefaultStyles.Name>
          <DefaultStyles.IconContainer onPointerDown={onArrowClick}>
            <FontAwesomeIcon
              size="lg"
              icon={['fal', isOpen ? 'angle-up' : 'angle-down']}
            />
          </DefaultStyles.IconContainer>
        </DefaultStyles.SelectedOption>
      </CellErrorTooltip>

      {isOpen && (
        <DefaultStyles.DropdownContent
          onPointerDown={stopPropagation}
          onKeyDown={onKeyDown}
          top={inputHeight}
          width={cell.overrideWidth}
        >
          <DefaultStyles.Options ref={optionsRef}>
            {options.map((option) => (
              <DefaultStyles.Option
                key={option.id}
                id={`dropdownOption_${option.id}`}
                selected={option.id === selectedOption?.id}
                highlighted={option.id === highlightedOption?.id}
                onTouchStart={stopPropagation}
                onMouseDown={() => {
                  onSelectOption(option)
                }}
              >
                {option.label}
              </DefaultStyles.Option>
            ))}
          </DefaultStyles.Options>
        </DefaultStyles.DropdownContent>
      )}
    </Styles.Container>
  )
}

export default StaticSpreadsheetDropdown
