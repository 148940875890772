import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import {
  COMPANIES_CHAT,
  COMPANIES_CHAT_EDIT,
  COMPANIES_METRICS,
  COMPANIES_METRICS_EDIT,
  COMPANIES_UPDATES,
  COMPANIES_UPDATES_EDIT,
} from 'routes/constant/companiesRoutes'
import { INVESTMENTS } from 'routes/constant/investmentsRoutes'
import { Update } from 'utils/types/update'
import { CompanyHoldingData, FundProfile } from 'utils/types/company'

import {
  FUNDS_CHAT,
  FUNDS_CHAT_EDIT,
  FUNDS_METRICS,
  FUNDS_METRICS_EDIT,
  FUNDS_UPDATES,
  FUNDS_UPDATES_EDIT,
} from 'routes/constant/fundManagerRoutes'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import EntityLink from 'components/Breadcrumb/Components/EntityLink'
import useInvestmentsBreadcrumb from '../useInvestmentsBreadcrumb'

interface Props {
  update?: Update
  entityToShow?: FundProfile | CompanyHoldingData
  url: URL
  isShowingCompany: boolean
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
  investmentOptionSelected: any
  getAvatar: () => Nullable<JSX.Element>
}

const InvestmentsBreadcrumb = ({
  update,
  entityToShow,
  url,
  isShowingCompany,
  initialLinkRedirect,
  isCreatingNewUpdate,
  investmentOptionSelected,
  getAvatar,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  const { displayNavigationOptionBreadcrumbItem } = useInvestmentsBreadcrumb({
    initialLinkRedirect,
    url,
    investmentOptionSelected,
  })

  const companiesActiveTabs = [
    COMPANIES_UPDATES.replace(':companyId', entityToShow?.id!),
    COMPANIES_METRICS.replace(':companyId', entityToShow?.id!),
    COMPANIES_CHAT.replace(':companyId', entityToShow?.id!),
    COMPANIES_UPDATES_EDIT.replace(':companyId', entityToShow?.id!),
    COMPANIES_METRICS_EDIT.replace(':companyId', entityToShow?.id!),
    COMPANIES_CHAT_EDIT.replace(':companyId', entityToShow?.id!),
  ]

  const fundsActiveTabs = [
    FUNDS_UPDATES.replace(':fundId', entityToShow?.id!),
    FUNDS_METRICS.replace(':fundId', entityToShow?.id!),
    FUNDS_CHAT.replace(':fundId', entityToShow?.id!),
    FUNDS_UPDATES_EDIT.replace(':fundId', entityToShow?.id!),
    FUNDS_METRICS_EDIT.replace(':fundId', entityToShow?.id!),
    FUNDS_CHAT_EDIT.replace(':fundId', entityToShow?.id!),
  ]

  return (
    <NavigationWrapper>
      <InitialLink
        isActive={false}
        label={intl.formatMessage({ id: 'breadcrumb.investments' })}
        onClickHandler={() => history.push(INVESTMENTS)}
      />

      <ArrowWrapper>
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </ArrowWrapper>

      {displayNavigationOptionBreadcrumbItem()}

      {entityToShow && (
        <>
          <EntityLink
            isActive={
              isShowingCompany
                ? companiesActiveTabs.some((tab) => url.current.includes(tab))
                : fundsActiveTabs.some((tab) => url.current.includes(tab))
            }
            label={entityToShow.name}
            entityLogo={getAvatar()}
            onClickHandler={() => {
              const entityUrl = isShowingCompany
                ? COMPANIES_UPDATES.replace(':companyId', entityToShow.id)
                : FUNDS_UPDATES.replace(':fundId', entityToShow.id)

              if (url.current !== entityUrl) {
                history.push(entityUrl)
              }
            }}
          />

          {(update || isCreatingNewUpdate) && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </>
      )}

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default InvestmentsBreadcrumb
