export default {
  tabs: {
    selectedMetrics: 'Selected Metrics',
    allMetrics: 'All Metrics',
    invitations: 'Invitations',
  },
  invitations: {
    pendingInvitations: 'Pending Invitations',
    invitationsHistory: 'Invitations History',
    requestingAccess:
      '<strong>{groupName}</strong> is requesting access to <strong>{metricName}</strong>{message}',
    founderSharingMetric:
      '<strong>{groupName}</strong> is sharing its <strong>{metricName}</strong> data with your group',
    requestSent: 'Request Sent: {date}',
    received: 'Received: {date}',
    approve: 'Approve',
    denyAccess: 'Deny Access',
    newMetric: 'New Metric',
    addToExisting: 'Add to Existing',
    accessDenied: 'Access Denied',
    groupAddedTo: 'Group added to {metricName}',
    addedTo: 'Added to {metricName}',
  },
  allMetrics: {
    name: 'Name',
    portfolioHolding: 'Portfolio/Holding',
    source: 'Source',
    previousValue: 'Previous Value',
    currentValue: 'Latest Value',
    latestValueDate: 'Latest Value Date',
    previousValueDate: 'Previous Value Date',
  },
  toasts: {
    successCreatingMetric: '{name} has been created successfully.',
    successEditingMetric: '{name} has been updated successfully.',
    metricDeleted: '{metricName} has been deleted.',
    successArchivingDataPoint: 'Metric value archived.',
    successArchivingDataPoints: '{count} metric values were archived.',
    errorArchivingDataPoint:
      'An error occurred archiving the data point, try again later',
    successUnarchivingDataPoint: 'Metric value unarchived.',
    successUnarchivingDataPoints: '{count} metric values unarchived.',
    errorUnarchivingDataPoint:
      'An error occurred unarchiving the data point, try again later',
    successNewMetricValue: 'A new metric value has been added successfully.',
    errorMetricCompanyNotVerified:
      'The company must be verified to share metrics',
  },
  updateMetrics: 'Update Metrics',
  addHoldingMetric: 'Add Holding Metric',
  addOrganizationMetric: 'Add Organization Metric',
  metricsSelect: {
    placeholder: 'Add to selected metrics',
    noMetrics: 'You have no matches.',
  },
  noMetricValues: 'No metric values yet.',
  visibleOnChart: 'Visible on Chart',
  sandbox: {
    hide: 'Hide',
    show: 'Show',
    clear: 'Clear',
    dotChart: 'Set as Dotted Line',
    barChart: 'View as Bar Chart',
    lineChart: 'View as Line Chart',
  },
  showInSandboxDisabled:
    'You have selected the maximum amount of datasets (8). Remove one to add a new dataset.',
}
