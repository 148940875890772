/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components'

export const NoResultsContainer = styled.div`
  padding: 7rem;

  ${(props) =>
    props.margin
      ? css`
          margin: ${props.margin};
        `
      : ''}

  div[class*='noResultsFoundContainer'] {
    top: 58%;
  }
`

export const LogsContainer = styled.div`
  ul[class*='Timeline_timeline'] {
    li:last-child {
      div[class*='logItemContainer'] {
        padding-bottom: 0;
      }
    }
  }

  span[class*='TimelineItem_timelineItemLine'] {
    left: 1.5rem;
  }

  #timeline-item {
    left: 1.4rem;
  }
`

export const StyledLogAvatar = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  position: relative;
  border-radius: 100%;
  font-size: 1.6rem;
  color: white;
  padding-top: 0.6rem;

  ${(props) =>
    props.backgroundColor
      ? css`
          background-color: ${props.backgroundColor};
        `
      : ''}
`

export const LogIcon = styled.div`
  width: 2.2rem;
  height: 2.2rem;
  position: absolute;
  bottom: -0.8rem;
  right: -0.8rem;
  font-size: 1.2rem;

  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid white;
  background-color: #4772e1;

  svg {
    color: white;
  }

  ${(props) =>
    props.type === 'create'
      ? css`
          background-color: #3cc3af;
        `
      : props.type === 'update' || props.type === props.blueIcon
      ? css`
          background-color: #4772e1;
        `
      : props.type === 'delete' || props.type === props.redIcon
      ? css`
          background-color: #e75767;
        `
      : ''}
`

export const LogZeroStateContainer = styled.div`
  position: relative;
  margin-top: 12rem;
  margin-bottom: 8rem;
`
