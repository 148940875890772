import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'
import { UpdateTypesOption } from 'utils/functions/normalizers/fileContentNormalizer'
import useSort from 'utils/hooks/useSort'
import { ContentFilters } from 'api/ContentService'
import { usePopulateUpdateTypesQuery } from '../useContents'
import { FormatType, FormatTypeWithoutValues } from './types'
import {
  // DEFAULT_EMAIL_FORMAT_TYPES,
  DEFAULT_FILES_SORT_CRITERIA,
  DEFAULT_FORMAT_TYPES,
  DEFAULT_OTHERS_FORMAT_TYPES,
} from './constants'
import {
  getFormatTypes,
  getUpdateTypes,
  setAllFormatTypesToNotActive,
  setAllUpdateTypesToNotActive,
} from './utils'

export interface AddedOrSharedEntity {
  id: string
  name: string
  logo: string
  email: string
}

const useContentFilter = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const [updateTypes, setUpdateTypes] = useState<UpdateTypesOption[]>([])
  const [formatTypes, setFormatTypes] =
    useState<FormatType[]>(DEFAULT_FORMAT_TYPES)

  // const [emailFormatType, setEmailFormatType] =
  //   useState<FormatTypeWithoutValues>(DEFAULT_EMAIL_FORMAT_TYPES)
  const [otherFormatType, setOtherFormatType] =
    useState<FormatTypeWithoutValues>(DEFAULT_OTHERS_FORMAT_TYPES)

  const [search, debouncedSearch, setSearch] = useDebouncedState('', 500)
  const [shouldFetchUpdateTypes, setShouldFetchUpdateTypes] = useState(false)
  const [addedByUsers, setAddedByUsers] = useState<AddedOrSharedEntity[]>([])
  const [sharedByGroups, setSharedByGroups] = useState<AddedOrSharedEntity[]>(
    []
  )

  const { sortCriteria, initialCriteria, onChangeSortBy, currentSort } =
    useSort(DEFAULT_FILES_SORT_CRITERIA)

  const { data: updatableTypes, isLoading: isLoadingUpdateTypeOptions } =
    usePopulateUpdateTypesQuery(shouldFetchUpdateTypes)

  const [filters, setFilters] = useState<ContentFilters>({
    name: debouncedSearch,
    orderBy: currentSort.orderBy,
    direction: currentSort.direction,
    uploadModal: false,
    onlyUnreconciled: false,
    showPublicFiles: false,
    showEmailContent: false,
    onlyFileContents: false,
    userIds: [],
    groupIds: [],
    loggingUpdateType: [],
    fileFormat: [],
  })

  const fetchUpdateTypesOptions = useCallback(async () => {
    setShouldFetchUpdateTypes(true)
  }, [])

  const isFilterApplied = useMemo(() => {
    return (
      filters.onlyUnreconciled ||
      filters.showPublicFiles ||
      filters.showEmailContent ||
      filters.onlyFileContents ||
      !!otherFormatType.active ||
      !!filters.loggingUpdateType?.length ||
      !!filters.userIds?.length ||
      !!filters.groupIds?.length ||
      !!filters.fileFormat?.length
    )
  }, [
    filters.onlyUnreconciled,
    filters.showPublicFiles,
    filters.showEmailContent,
    filters.onlyFileContents,
    filters.loggingUpdateType?.length,
    filters.userIds?.length,
    filters.groupIds?.length,
    filters.fileFormat?.length,
    otherFormatType.active,
  ])

  const filtersCount = useMemo<number>(() => {
    const filtersToCheck = [
      +filters.onlyUnreconciled,
      +filters.showPublicFiles,
      +otherFormatType.active,
      filters.loggingUpdateType.length,
      filters.userIds.length,
      filters.groupIds.length,
      formatTypes.reduce((acc, curr) => acc + +curr.active, 0),
    ]

    return filtersToCheck.reduce((acc, curr) => acc + curr, 0)
  }, [
    filters.onlyUnreconciled,
    filters.showPublicFiles,
    filters.loggingUpdateType.length,
    filters.userIds.length,
    filters.groupIds.length,
    otherFormatType.active,
    formatTypes,
  ])

  const switchUnreconciledFilter = () => {
    setFilters((prev) => ({
      ...prev,
      onlyUnreconciled: !prev.onlyUnreconciled,
    }))
  }

  // const switchEmailContentFilter = () => {
  //   setEmailFormatType((prev) => ({
  //     ...prev,
  //     active: !prev.active,
  //   }))

  //   setFilters((prev) => ({
  //     ...prev,
  //     showEmailContent: !prev.showEmailContent,
  //   }))
  // }

  const switchOtherContentFilter = () => {
    setOtherFormatType((prev) => ({
      ...prev,
      active: !prev.active,
    }))

    setFilters((prev) => ({
      ...prev,
      onlyFileContents: !prev.onlyFileContents,
    }))
  }

  const switchPublicFilesFilter = () => {
    setFilters((prev) => ({
      ...prev,
      showPublicFiles: !prev.showPublicFiles,
    }))
  }

  const onChangeSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const onClearFilters = ({
    isCleaningFromDrawer,
  }: {
    isCleaningFromDrawer: boolean
  }) => {
    const newUpdatesTypeState = setAllUpdateTypesToNotActive(updateTypes)
    const newFormatFileState = setAllFormatTypesToNotActive(formatTypes)

    setFilters({
      name: '',
      orderBy: currentSort.orderBy,
      direction: currentSort.direction,
      onlyUnreconciled: false,
      showPublicFiles: false,
      showEmailContent: false,
      onlyFileContents: false,
      userIds: [],
      groupIds: [],
      loggingUpdateType: [],
      fileFormat: [],
    })

    setUpdateTypes(newUpdatesTypeState)
    setFormatTypes(newFormatFileState)
    setOtherFormatType(DEFAULT_OTHERS_FORMAT_TYPES)
    setSearch('')
    setAddedByUsers([])
    setSharedByGroups([])

    if (isCleaningFromDrawer) {
      setIsDrawerOpen(false)
    }
  }

  const handleOpenDrawer = () => setIsDrawerOpen(!isDrawerOpen)

  const switchTypeFilter = (id: string) => {
    const updatedFilters = updateTypes.map((updateType) => {
      if (updateType.id === id) {
        return {
          ...updateType,
          active: !updateType.active,
        }
      }
      return updateType
    })

    setUpdateTypes(updatedFilters)

    setFilters((prev) => ({
      ...prev,
      loggingUpdateType: getUpdateTypes(updatedFilters),
    }))
  }

  const addUserIds = (user: AddedOrSharedEntity) => {
    if (addedByUsers.some((addedUser) => addedUser.id === user.id)) return

    setFilters((prev) => ({
      ...prev,
      userIds: [...prev.userIds, user.id],
    }))

    setAddedByUsers((prev) => [...prev, user])
  }

  const addGroupIds = (group: AddedOrSharedEntity) => {
    if (sharedByGroups.some((addedGroup) => addedGroup.id === group.id)) return

    setFilters((prev) => ({
      ...prev,
      groupIds: [...prev.groupIds, group.id],
    }))

    setSharedByGroups((prev) => [...prev, group])
  }

  const removeUserIds = (id: string) => {
    setFilters((prev) => ({
      ...prev,
      userIds: prev.userIds.filter((userId) => userId !== id),
    }))

    setAddedByUsers((prev) => prev.filter((user) => user.id !== id))
  }

  const removeGroupIds = (id: string) => {
    setFilters((prev) => ({
      ...prev,
      groupIds: prev.groupIds.filter((groupId) => groupId !== id),
    }))

    setSharedByGroups((prev) => prev.filter((group) => group.id !== id))
  }

  const switchFormatFilter = (id: string) => {
    const formatFilters = formatTypes.map((formatType) => {
      if (formatType.id === id) {
        return {
          ...formatType,
          active: !formatType.active,
        }
      }
      return formatType
    })

    setFormatTypes(formatFilters)

    setFilters((prev) => ({
      ...prev,
      fileFormat: getFormatTypes(formatFilters),
    }))
  }

  const isUnreconciledFilterApplied = useMemo(() => {
    return filters.onlyUnreconciled
  }, [filters.onlyUnreconciled])

  const isUnreconciledFilterDisabled = useMemo(() => {
    return (
      filters.showPublicFiles ||
      updateTypes.some((updateType) => updateType.active)
    )
  }, [filters.showPublicFiles, updateTypes])

  useEffect(() => {
    if (!updatableTypes) return

    setUpdateTypes(updatableTypes)
  }, [updatableTypes])

  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      name: debouncedSearch,
      direction: currentSort.direction,
      orderBy: currentSort.orderBy,
    }))
  }, [currentSort.direction, currentSort.orderBy, debouncedSearch])

  return {
    isDrawerOpen,
    addedByUsers,
    sharedByGroups,

    filters,
    filtersCount,
    updateTypes,
    formatTypes,
    isLoadingUpdateTypeOptions,

    // emailFormatType,
    otherFormatType,

    isFilterApplied,
    isUnreconciledFilterApplied,
    isUnreconciledFilterDisabled,

    search,
    debouncedSearch,
    initialCriteria,
    sortCriteria,
    currentSort,

    onChangeSortBy,
    handlers: {
      switchUnreconciledFilter,
      switchPublicFilesFilter,
      addUserIds,
      addGroupIds,
      removeUserIds,
      removeGroupIds,
      // switchEmailContentFilter,
      switchOtherContentFilter,
      onChangeSearchHandler,
      onClearFilters,
      switchTypeFilter,
      switchFormatFilter,
      fetchUpdateTypesOptions,
      handleOpenDrawer,
    },
  }
}

export default useContentFilter
