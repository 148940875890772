import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import ContentService from 'api/ContentService'
import {
  addUploads,
  FileToUpload,
  getUploads,
  removeUpload,
  restartUpload as restartUploadAction,
  setUploadCompleted,
  setUploadProgress,
  setUploadStatus,
  showUploadsMenu,
} from 'reducers/uploadsSlice'
import { randomId } from 'utils/functions/number'
import { Nullable } from 'utils/types/common'
import { FileContent } from 'utils/types/files'
import { FileUploadStatus, Upload } from 'utils/types/uploads'
import { groupKeys } from 'utils/queries/groups'
import { useRefetchCurrentGroupTier } from 'utils/hooks/queries/useGroupQuery'

import { useAppDispatch, useAppSelector } from './reduxToolkit'
import { dispatchEvent, useEventListener } from './useEventListener'

const FILE_UPLOADED_EVENT = 'FILE_UPLOADED'
const GLOBAL_FILE_UPLOADED_EVENT = 'GLOBAL_FILE_UPLOADED_EVENT'

const DEFAULT_FILE_UPLOAD_CONTEXT_ID = 'ALL_UPLOADS'

export const useFileUploadedEventListener = (
  fileUploadContextId: string = DEFAULT_FILE_UPLOAD_CONTEXT_ID,
  callback: (fileContent: FileContent) => void
) => {
  return useEventListener([FILE_UPLOADED_EVENT, fileUploadContextId], callback)
}

export const useGlobalFileUploadedEventListener = (
  callback: (fileContent: FileContent) => void
) => {
  return useEventListener(GLOBAL_FILE_UPLOADED_EVENT, callback)
}

export const useUploadFile = () => {
  const dispatch = useAppDispatch()
  const uploads = useAppSelector(getUploads)
  const { refetchCurrentGroupTier } = useRefetchCurrentGroupTier()

  const hasUploadsInProgress = useCallback(
    (fileUploadContextId: string = DEFAULT_FILE_UPLOAD_CONTEXT_ID) => {
      return uploads.some(
        (file: Upload) =>
          file.fileUploadContextId === fileUploadContextId &&
          file.status === FileUploadStatus.UPLOADING
      )
    },
    [uploads]
  )

  const queryClient = useQueryClient()

  const uploadFile = useCallback(
    async (fileData: FileToUpload): Promise<Nullable<FileContent>> => {
      try {
        const fileContent = await ContentService.createContent(
          fileData.file,
          fileData.abortController,
          (progressEvent) =>
            dispatch(
              setUploadProgress({
                fileId: fileData.id,
                uploadProgress: progressEvent.loaded,
                uploadTotal: progressEvent.total ?? fileData.file.size,
              })
            )
        )

        dispatch(
          setUploadCompleted({
            fileId: fileData.id,
            fileContent,
          })
        )

        queryClient.invalidateQueries(groupKeys.storageUsage())
        refetchCurrentGroupTier()

        return fileContent
      } catch (err) {
        const newStatus = fileData.abortController.signal.aborted
          ? FileUploadStatus.CANCELED
          : FileUploadStatus.ERROR

        dispatch(
          setUploadStatus({
            fileId: fileData.id,
            status: newStatus,
            error: err?.message,
          })
        )

        return null
      }
    },
    [dispatch, queryClient, refetchCurrentGroupTier]
  )

  const uploadFiles = useCallback(
    async (files: File[], fileUploadContextId: string) => {
      const filesToUpload: FileToUpload[] = files.map((file) => ({
        id: randomId(),
        file,
        abortController: new AbortController(),
        fileUploadContextId,
      }))

      dispatch(addUploads(filesToUpload))
      dispatch(showUploadsMenu(true))

      return Promise.all(
        filesToUpload.map(async (fileToUpload) => {
          const fileContent = await uploadFile(fileToUpload)
          if (fileContent !== null) {
            dispatchEvent(
              [FILE_UPLOADED_EVENT, fileUploadContextId],
              fileContent
            )
            dispatchEvent(GLOBAL_FILE_UPLOADED_EVENT, fileContent)
          }
          return fileContent
        })
      )
    },
    [dispatch, uploadFile]
  )

  const cancelUpload = useCallback(
    (fileId: string) => {
      const file = uploads.find((upload) => upload.id === fileId)
      if (!file) return
      file.abortController.abort()
    },
    [uploads]
  )

  const restartUpload = useCallback(
    async (fileId: string) => {
      const upload = uploads.find((upl) => upl.id === fileId)
      if (!upload) return

      const newUpload = {
        id: upload.id,
        file: upload.file,
        abortController: new AbortController(),
        fileUploadContextId: upload.fileUploadContextId,
      }
      dispatch(restartUploadAction(newUpload))
      const fileContent = await uploadFile(newUpload)

      if (fileContent !== null) {
        dispatchEvent(
          [FILE_UPLOADED_EVENT, newUpload.fileUploadContextId],
          fileContent
        )
      }
    },
    [dispatch, uploadFile, uploads]
  )

  const deleteUpload = useCallback(
    async (fileId: string) => {
      dispatch(removeUpload({ fileId }))
    },
    [dispatch]
  )

  return {
    uploads,
    uploadFiles,
    cancelUpload,
    restartUpload,
    deleteUpload,
    hasUploadsInProgress,
  }
}
