import Card from 'components/Card'
import styled, { css } from 'styled-components'
import ButtonToStyle from 'ui/Button'
import { color } from 'utils/functions/colors'

export const StyledCard = styled(Card)`
  position: relative;
`

const ButtonPosition = css`
  position: absolute;
  right: 2rem;
  top: 1rem;
  z-index: 10;
`

export const ButtonContainer = styled.div`
  ${ButtonPosition}
`

export const Button = styled(ButtonToStyle)`
  ${ButtonPosition}
  width: auto;
  cursor: pointer;
  border: none;
  font-size: 1.4rem;
`

export const LinkedCopiedContainer = styled.div<{ copied: boolean }>`
  background-color: ${color('primaryBlue')};
  border-radius: 0.8rem;
  padding: 0.8rem;
  position: absolute;
  color: white;
  font-size: 1.2rem;
  right: 2rem;
  top: -2.5rem;

  display: ${(props) => (props.copied ? 'block' : 'none')};
`
