import { useCallback, useState } from 'react'

export const FormSteps = {
  DISCARD_FORM: 'DISCARD_FORM',
  FIRST_STEP: 'FIRST_STEP',
  SECOND_STEP: 'SECOND_STEP',
} as const

export type FormStep = typeof FormSteps[keyof typeof FormSteps]

const useHandleModals = () => {
  const [editFilterFormStep, setEditFilterFormStep] = useState<FormStep>(
    FormSteps.DISCARD_FORM
  )
  const [showDeleteFilterModal, setShowDeleteFilterModal] = useState(false)
  const [showRenameFilterModal, setShowRenameFilterModal] = useState(false)
  const [showSaveAsNewPortfolioModal, setShowSaveAsNewPortfolioModal] =
    useState(false)

  const [createPortfolioModalOpen, setCreatePortfolioModalOpen] =
    useState(false)

  const handleSetEditFormStep = useCallback(
    (step: FormStep) => setEditFilterFormStep(step),
    []
  )

  const handleShowDeleteFilterModal = useCallback(
    (state: boolean) => setShowDeleteFilterModal(state),
    []
  )

  const handleShowRenameFilterModal = useCallback(
    (state: boolean) => setShowRenameFilterModal(state),
    []
  )

  const handleShowSaveAsNewPortfolioModal = useCallback(
    (state: boolean) => setShowSaveAsNewPortfolioModal(state),
    []
  )

  return {
    editFilterFormStep,
    showDeleteFilterModal,
    showRenameFilterModal,
    showSaveAsNewPortfolioModal,
    createPortfolioModalOpen,
    setCreatePortfolioModalOpen,
    handleShowRenameFilterModal,
    handleSetEditFormStep,
    handleShowDeleteFilterModal,
    handleShowSaveAsNewPortfolioModal,
  }
}

export default useHandleModals
