export const DefaultPresentation = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clipPath="url(#clip0_7690_533761)">
      <path
        id="Vector"
        d="M0 6.96138C0 5.36335 0 3.76532 0 2.16729C0 1.92484 0.0358398 1.69294 0.177091 1.48633C0.392129 1.1701 0.683064 0.982467 1.07098 0.974034C1.42305 0.967709 1.77301 0.969817 2.12509 0.969817C3.35207 0.969817 4.57695 0.976142 5.80394 0.965601C6.19817 0.963493 6.5123 1.09631 6.78848 1.3767C7.38299 1.98387 7.98595 2.58472 8.59101 3.18134C8.86718 3.4533 9 3.76321 9 4.15745C8.99157 6.69997 8.99368 9.24459 8.99789 11.7871C8.99789 12.2467 8.81026 12.5903 8.42024 12.8265C8.23893 12.9361 8.03865 12.9635 7.83205 12.9635C5.60787 12.9635 3.3837 12.9614 1.16163 12.9656C0.539705 12.9677 0.113844 12.5334 0.0295151 12.0717C0.0105411 11.9663 0.00421644 11.8546 0.00421644 11.747C0 10.1511 0 8.55731 0 6.96138ZM1.12157 2.08928C1.12157 5.34227 1.12157 8.58893 1.12157 11.8398C3.37526 11.8398 5.62052 11.8398 7.86999 11.8398C7.86999 9.58612 7.86999 7.34086 7.86999 5.08507C7.8215 5.08507 7.78145 5.08507 7.73928 5.08507C6.99086 5.08507 6.24245 5.08928 5.49403 5.08085C5.36964 5.07874 5.23472 5.04501 5.1272 4.98387C4.93324 4.87424 4.87632 4.68029 4.87632 4.46314C4.88053 3.71894 4.87843 2.97474 4.87843 2.22843C4.87843 2.18415 4.87843 2.13988 4.87843 2.09139C3.61982 2.08928 2.37386 2.08928 1.12157 2.08928ZM6.00211 3.95928C6.59663 3.95928 7.1806 3.95928 7.72874 3.95928C7.15741 3.38795 6.57554 2.80397 6.00211 2.22843C6.00211 2.78921 6.00211 3.37108 6.00211 3.95928Z"
        fill="black"
      />
      <path
        id="Vector_2"
        d="M3.93556 9.32668C3.93556 9.5122 3.93556 9.68297 3.93556 9.85374C3.93556 10.0329 3.93767 10.2121 3.93556 10.3913C3.93345 10.6064 3.82804 10.7097 3.61722 10.7097C3.45278 10.7097 3.28623 10.7118 3.12179 10.7097C2.90042 10.7076 2.82031 10.6296 2.82031 10.4103C2.82031 9.10953 2.82031 7.81087 2.82031 6.5101C2.82031 6.29927 2.8941 6.21916 3.10281 6.21705C3.67203 6.21495 4.24125 6.21284 4.81047 6.22338C5.59684 6.23814 6.13865 6.89168 6.17239 7.59372C6.18503 7.85514 6.19558 8.11867 6.10703 8.36533C5.97843 8.72373 5.77182 9.0252 5.38813 9.16435C5.03184 9.29295 4.66501 9.32879 4.28974 9.32457C4.17801 9.32668 4.06206 9.32668 3.93556 9.32668ZM3.94189 8.39485C4.14428 8.39485 4.34034 8.39906 4.53641 8.39485C4.75355 8.39063 4.90956 8.27889 5.00021 8.08705C5.06979 7.93736 5.08665 7.77503 5.05081 7.6148C5.00443 7.41031 4.8948 7.23532 4.67976 7.20159C4.43943 7.16364 4.19276 7.17419 3.94399 7.16154C3.94189 7.58318 3.94189 7.98164 3.94189 8.39485Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_7690_533761">
        <rect
          width="8.99789"
          height="12"
          fill="white"
          transform="translate(0 0.965576)"
        />
      </clipPath>
    </defs>
  </svg>
)
