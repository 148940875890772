import { useEffect } from 'react'
import type { Event } from 'stream-chat'
import { Channel } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import { StreamChatType, TopicChannel } from '../types'

export const useMessageEditedListener = (
  setChannels: React.Dispatch<
    React.SetStateAction<Array<Channel<StreamChatType<TopicChannel>>>>
  >
) => {
  const { client } = useChatContext<StreamChatType<TopicChannel>>(
    'useMessageEditedListener'
  )

  useEffect(() => {
    const handleEvent = (event: Event<StreamChatType<TopicChannel>>) => {
      setChannels((channels) => {
        const channel = client.channel(event.channel_type!, event.channel_id!)

        return channels.map((ch) => {
          if (ch.id === event.channel_id) {
            return channel
          }
          return ch
        })
      })
    }

    client.on('message.updated', handleEvent)

    return () => {
      client.off('message.updated', handleEvent)
    }
  }, [client, setChannels])
}
