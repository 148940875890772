import styled from 'styled-components/macro'
import Modal from 'components/Modal'
import BulkImport from 'components/BulkImport'
import { color } from 'utils/functions/colors'

export const ModalBody = styled(Modal.Body)`
  min-width: 64.8rem;
  min-height: 51.4rem;
  padding: 0;
  margin: 0;
`

export const AddHoldingButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.9rem;
`

export const DeleteButton = styled(BulkImport.DeleteButton)`
  margin-top: 1.6rem;
`

export const EmptyListPlaceholder = styled.div`
  min-width: 60rem;
  min-height: 58.8rem;
  border-left: 0.1rem solid ${color('borderGray')};
`
