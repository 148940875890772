import { Route } from 'react-router-dom'
import AuthLayout from 'layouts/AuthLayout'

import SignInAuth0 from 'containers/Auth/SignInAuth0'
import EmailConfirmation from 'containers/Auth/EmailConfirmation'
import SignInHandler from 'containers/Auth/SignInHandler'
import Typeform from 'components/Typeform'
import Signout from 'containers/Signout/Signout'
import SignInDesktopHandler from 'containers/Auth/SignInHandler/SignInDesktopHandler'
import DesktopRedirect from 'containers/Auth/DesktopRedirect'

import {
  REDIRECT_AUTH0,
  SIGN_IN,
  SIGN_IN_HANDLE,
  ACCESS_REQUEST,
  REDIRECT_DESKTOP,
  REDIRECT_AUTH0_APP,
  EMAIL_CONFIRMATION,
  SIGN_OUT,
} from './constant/authRoutes'

const AuthRoutes = () => {
  const routes = [
    {
      path: [SIGN_IN, SIGN_IN_HANDLE],
      exact: true,
      key: 'sign-in',
      component: <SignInAuth0 />,
    },
    {
      path: REDIRECT_AUTH0,
      exact: true,
      key: 'redirect-auth0',
      component: <SignInHandler />,
    },
    {
      path: REDIRECT_DESKTOP,
      exact: true,
      key: 'desktop-redirect',
      component: <DesktopRedirect />,
    },
    {
      path: REDIRECT_AUTH0_APP,
      exact: true,
      key: 'redirect-auth0-app',
      component: <SignInDesktopHandler />,
    },
    {
      path: SIGN_OUT,
      key: 'sign-out',
      component: <Signout />,
    },
    {
      path: EMAIL_CONFIRMATION,
      key: 'email-confirmation',
      component: <EmailConfirmation />,
    },
    {
      path: ACCESS_REQUEST,
      key: 'access-request',
      component: (
        <AuthLayout>
          <Typeform />
        </AuthLayout>
      ),
    },
  ]

  return (
    <>
      {routes.map((route) => (
        <Route key={route.key} exact={route.exact} path={route.path}>
          {route.component}
        </Route>
      ))}
    </>
  )
}
export default AuthRoutes
