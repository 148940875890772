import BaseAvatar from 'components/Avatar'
import { SubjectMatterInfo } from 'utils/types/files'

import { SubjectMatterType } from 'api/UpdateService'
import { isActingAsFounder, isActingAsInvestorGroup } from 'selectors/auth'
import { PortfolioTypes } from 'utils/constants/portfolio'
import {
  PlainSubjectMatters,
  PortfolioEntityForUrl,
} from 'utils/constants/updates'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import { getInitials } from 'utils/functions/user'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { ValueOf } from 'utils/types/common'

const getImage = (entity: SubjectMatterInfo): string => {
  return entity?.logo?.mediumLogo?.url
}

const useDetails = () => {
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const isFounder = useAppSelector(isActingAsFounder)

  const isPortfolioEntity = (entity: SubjectMatterInfo) => {
    if (
      entity.type === SubjectMatterType.PORTFOLIO ||
      entity.type === SubjectMatterType.FUND_PORTFOLIO ||
      entity.type === SubjectMatterType.INVEST_PORTFOLIO ||
      entity.type === SubjectMatterType.TRACK_PORTFOLIO
    ) {
      return true
    }
    return false
  }

  const getEntityUrl = (entity: SubjectMatterInfo) => {
    if (
      entity.type === SubjectMatterType.COMPANY ||
      entity.type === SubjectMatterType.FUND_PORTFOLIO
    ) {
      if (isFounder) {
        return `/dashboard/updates`
      }

      // HOLDINGS
      if (!isInvestorGroup) {
        if (entity.type === SubjectMatterType.FUND_PORTFOLIO) {
          return `/funds/${entity.id}/updates`
        }
        return `/companies/${entity.id}/updates`
      }

      if (entity.type === SubjectMatterType.FUND_PORTFOLIO) {
        if (isInvestorGroup) {
          return `/fund-holdings/funds/${entity.id}/updates`
        }
        return `/funds/${entity.id}/updates`
      }
      return `/fund-holdings/companies/${entity.id}/updates`
    }

    if (entity.type === SubjectMatterType.INVESTOR) {
      // INVESTORS
      if (isInvestorGroup) {
        return `/profile/updates`
      }
      return `/investor-management/investors/${entity.id}/profile`
    }

    if (isPortfolioEntity(entity)) {
      // PORTFOLIOS
      if (isInvestorGroup) {
        return `/funds/${entity.id}/updates`
      }
      if (entity.type === SubjectMatterType.PORTFOLIO) {
        return `/portfolios/${PortfolioEntityForUrl[entity.type]}/${entity.id}`
      }

      return `/portfolios/${PortfolioEntityForUrl[entity.type]}/${
        entity.id
      }/portfolio`
    }

    return ''
  }

  const renderPortfolioIconByType = (
    type: ValueOf<typeof PlainSubjectMatters>
  ) => {
    if (type === SubjectMatterType.FUND_PORTFOLIO) {
      return getPortfolioIconByType(PortfolioTypes.FUND, 'xs')
    }
    if (type === SubjectMatterType.INVEST_PORTFOLIO) {
      return getPortfolioIconByType(PortfolioTypes.INVEST, 'xs')
    }
    return getPortfolioIconByType(PortfolioTypes.TRACK, 'xs')
  }

  const renderLogo = (entity: SubjectMatterInfo) => {
    if (isPortfolioEntity(entity) && !entity.logo)
      return renderPortfolioIconByType(entity.type)

    return (
      <BaseAvatar
        avatarStyle="avatarCircleXS"
        grayBorder={false}
        image={getImage(entity)}
        initials={getInitials(entity.name)}
      />
    )
  }
  return { getEntityUrl, renderLogo }
}

export default useDetails
