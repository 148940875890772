import { FormattedMessage } from 'react-intl'
import Tooltip from 'components/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IconName } from '@fortawesome/fontawesome-svg-core'
import theme from 'utils/theme'
import { CellWithTooltip } from './styles'

interface Props {
  cellNameId: string
  tooltipText: string | React.ReactNode
  iconName: IconName
  tooltipId: string
}

const IconCell = ({ cellNameId, tooltipText, tooltipId, iconName }: Props) => {
  return (
    <CellWithTooltip>
      <FormattedMessage id={cellNameId} />
      <Tooltip
        id={tooltipId}
        place="top"
        maxWidth="22rem"
        delayShow={500}
        backgroundColor={theme.colors.white}
        color={theme.colors.gray}
        boxShadow="0px 0px 15px 0px rgba(16, 21, 39, 0.10)"
        clickable
        text={tooltipText}
      >
        <FontAwesomeIcon icon={['far', iconName]} size="1x" />
      </Tooltip>
    </CellWithTooltip>
  )
}

export default IconCell
