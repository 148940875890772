import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { ErrorIcon, IconsContainer, StyledInput } from 'ui/Input/Input.styles'
import Button from 'ui/Button'

export const InputContainer = styled.div`
  position: relative;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const InputWrapper = styled.div`
  position: relative;
  border: 0.1rem solid transparent;

  ${StyledInput} {
    ${(props) =>
      props.dropdownType === 'select' &&
      css`
        cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
        caret-color: transparent;

        &:disabled {
          cursor: not-allowed;
        }
      `}
    ${(props) =>
      !props.hideCursor &&
      css`
        caret-color: ${color('darkGray')};
      `}
        &:focus::placeholder {
      color: ${color('darkGray', 0.5)};
    }

    ${(props) =>
      props.disabled
        ? css`
            border: none;
            outline: none;
            &:hover {
              border: none;
              outline: none;
            }
            opacity: 0.5;
          `
        : ''}
  }

  ${ErrorIcon} {
    color: ${(props) =>
      props.errorType === 'error' || props.errorType === 'required'
        ? color('red')
        : color('darkYellow')};
  }
`

export const DropdownContent = styled.div`
  position: absolute;

  top: ${(props) => {
    if (props.large) {
      return props.top || '9.8rem'
    }

    if (props.label) {
      return props.top || '6.7rem'
    }

    return '4.1rem'
  }};

  ${(props) =>
    props.alignDropdownContent === 'left'
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};
  ${(props) =>
    props.minContentWidth &&
    css`
      min-width: ${props.minContentWidth};
    `}
  padding-bottom: 2rem;
  list-style: none;
  z-index: 9998;

  ${(props) =>
    props.attachToDocument &&
    css`
      left: ${props.position?.left ?? 0}px;
      top: ${(props.position?.top ?? 0) + (props.topOffset ?? 0)}px;
      width: ${props.position?.width ?? 100 + 30}px;
      transform: ${(() => {
        if (props.alignLeft) {
          return `translateY(4rem)`
        }
        const width = props.position?.width ?? 0
        return `translate(calc(-100% + ${width}px), 4rem)`
      })()};
    `}
  width: ${(props) => props.dropdownContentWidth || '100%'};
`

export const DropdownContentListWrapper = styled.div`
  background: ${color('white')};
  box-shadow: 0 0.4rem 1rem 0 rgba(55, 56, 60, 0.16);
  border-radius: 0.6rem;
  padding-top: 0.5rem;
  width: ${(props) => props.dropdownListWidth || 'auto'};
`

export const DropdownOptionsList = styled.ul`
  max-height: ${(props) => props.maxListHeight || '20rem'};
  overflow: auto;
  padding: ${(props) => props.padding || '0.4rem 0.8rem'};
  margin: ${(props) => props.margin || '0.9rem 0 0 0'};

  &:empty {
    padding: 0;
  }
`

export const OptionLegend = styled.span`
  color: ${color('darkGray')};
  font-weight: 600;
`

export const DropdownOptionIcon = styled(FontAwesomeIcon)`
  margin-right: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1.5rem;
`

export const AvatarWrapper = styled.div`
  margin-right: 1.5rem;
`

export const DropdownOptionImage = styled.img`
  border-radius: 100%;
  width: 1.8rem;
  height: 1.8rem;
  margin-right: 1.5rem;
`

export const DropdownOptionItem = styled.li`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${color('darkBlue')};
  ${(props) =>
    props.growOptionItems &&
    css`
      width: max-content;
    `}
  min-width: 100%;
  margin-bottom: 0.3rem;
`

export const DropdownOptionsTitle = styled.p`
  font-size: 1.2rem;
  text-transform: uppercase;
  padding: ${(props) => props.optionTitlePadding || '1.1rem 0.8rem'};
  color: ${color('lightGray')};
`

export const SearchInputContainer = styled.div`
  padding: 1rem 1rem 0 1rem;
`

export const AddOptionWrapper = styled.div`
  padding-bottom: 0.1rem;
`

export const AddOptionButton = styled.button`
  display: flex !important;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 800;
  width: calc(100% - 1rem);
  padding: 1rem 5rem 1rem 1rem;

  margin: 0.5rem;
  border-radius: 0.3rem;

  &:hover {
    background: ${color('veryLightBlue', 0.2)};
    color: ${color('darkBlue')};
  }

  &:disabled {
    cursor: not-allowed;

    > span,
    svg {
      color: ${color('darkGray')};
      opacity: 0.5;
    }
  }
`

export const AddIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
  margin-left: 1.2rem;
  font-size: 2rem;
`

export const AddOptionLabel = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${color('primaryBlue')};
`

export const CounterLabel = styled.div`
  position: absolute;
  font-size: 1.4rem;
  color: ${(props) => props.color || color('darkGray')};
`

export const OptionRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const DropdownButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 0.1rem solid transparent;
  border-radius: 0.8rem;
  min-width: ${(props) => props.minWidth || '19.6rem'};
  text-align: left;
  padding: ${(props) => props.padding || '1rem 1.6rem'};

  background-color: ${(props) => props.background};

  ${({ iconComponentPosition, showAngleIcon }) =>
    showAngleIcon &&
    css`
      gap: 1.1rem;
    `}

  &:hover:not(:disabled),
  &:active,
  &:focus {
    box-shadow: none;
    background-color: ${(props) => props.backgroundFocus || 'transparent'};
  }

  &:hover {
    border: ${({ borderOnFocus }) =>
      borderOnFocus || `0.1rem solid ${color('veryLightBlue')};`};
    color: ${({ onHover }) => onHover?.color || 'initial'};
  }

  &:focus {
    background-color: ${color('white')};
    border: 0.1rem solid ${color('primaryBlue')};
  }
`

export const StyledIconsContainer = styled(IconsContainer)`
  ${(props) =>
    props.relativePositioning &&
    css`
      position: relative;
      right: 0;
    `};
`

export const OptionsTitle = styled.p`
  color: ${color('lightGray')};
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin: 1rem 0 1rem 0.5rem;
`

export const NoResults = styled.p`
  color: ${color('lightGray')};
  font-weight: 700;
  padding: 0.7rem 0;
  text-align: center;
  font-size: 1.2rem;
  letter-spacing: 0.03em;
`
