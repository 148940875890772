import { FinancialInformation } from 'utils/types/investors'
import { FinancialInfoWrapper } from './FinancialInformation.styles'
import FinancialItem from './FinancialItem'

interface Props {
  financialInformation: FinancialInformation
}

const FinancialInfo = ({ financialInformation }: Props) => {
  return (
    <FinancialInfoWrapper>
      <FinancialItem
        isReadOnly
        titleId="investorManagement.list.extended.taxId"
        value={financialInformation?.taxId}
      />
      <FinancialItem
        titleId="investorManagement.list.extended.bankName"
        value={financialInformation?.bankName}
      />
      <FinancialItem
        isReadOnly
        titleId="investorManagement.list.extended.bankAccount"
        value={financialInformation?.bankAccount}
      />
      <FinancialItem
        isReadOnly
        titleId="investorManagement.list.extended.routingNumber"
        value={financialInformation?.routingNumber}
      />
    </FinancialInfoWrapper>
  )
}

export default FinancialInfo
