import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Card from 'components/Card'

import EmailPreview from 'components/EmailPreview'

import styles from './EmailStructure.module.scss'

const EmailStructure = ({
  createAssetToEmail,
  emailStructureSections = [],
  handleRemoveSection,
  onAddEntryToSection,
  onChangeEntrySection,
  onRemoveEntryFromSection,
  handleChangeSectionName,
  onRemoveSectionTitle,
  onSelectColor,
  onChangeImageSize,
  onChangeShowTitle,
  onChangeTextAlign,
}) => {
  const onChangeSection = ({ value, type = null, sectionId, entryId }) => {
    onChangeEntrySection(value, type, sectionId, entryId)
  }

  return (
    <Card>
      <Card.Header>
        <FormattedMessage id="createEmail.email" />
      </Card.Header>
      <Card.Body className={styles.emailStructureCardBody}>
        <EmailPreview
          emailStructureSections={emailStructureSections}
          customClass={styles.previewCustom}
          onChangeSectionName={handleChangeSectionName}
          onChangeSection={onChangeSection}
          createAssetToEmail={createAssetToEmail}
          onAddEntryToSection={onAddEntryToSection}
          handleRemoveSection={handleRemoveSection}
          onRemoveEntryFromSection={onRemoveEntryFromSection}
          onRemoveSectionTitle={onRemoveSectionTitle}
          onSelectColor={onSelectColor}
          onChangeImageSize={onChangeImageSize}
          onChangeShowTitle={onChangeShowTitle}
          onChangeTextAlign={onChangeTextAlign}
        />
      </Card.Body>
    </Card>
  )
}

EmailStructure.defaultProps = {
  emailStructureSections: [],
  handleChangeSectionName: null,
}

EmailStructure.propTypes = {
  createAssetToEmail: PropTypes.func.isRequired,
  onRemoveSectionTitle: PropTypes.func.isRequired,
  emailStructureSections: PropTypes.array,
  handleChangeSectionName: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]),
  handleRemoveSection: PropTypes.func.isRequired,
  onAddEntryToSection: PropTypes.func.isRequired,
  onChangeEntrySection: PropTypes.func.isRequired,
  onRemoveEntryFromSection: PropTypes.func.isRequired,
  onChangeImageSize: PropTypes.func.isRequired,
  onSelectColor: PropTypes.func.isRequired,
  onChangeShowTitle: PropTypes.func.isRequired,
  onChangeTextAlign: PropTypes.func.isRequired,
}

export default EmailStructure
