import AddToPortfolioButton from 'components/AddToPortfolioButton'
import {
  ActionsWrapper,
  addToPortfolioCustomStyle,
  ButtonsWrapper,
  PlaceHolderDiv,
} from 'containers/Profiles/CompanyProfile/CompanyProfile.styles'
import { isActingAsFounder, isActingAsInvestorGroup } from 'selectors/auth'
import ProfilesActions from 'components/Breadcrumb/ProfilesActions/ProfilesActions'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { CompanyHoldingData, FundProfile } from 'utils/types/company'
import Tags from '../Tags'

interface HeaderContentProps {
  companyInfo?: CompanyHoldingData
  fundHoldingInfo?: FundProfile
}

const HeaderContent = ({
  companyInfo,
  fundHoldingInfo,
}: HeaderContentProps) => {
  const isFounder = useAppSelector(isActingAsFounder)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const isSearchByTagAllowed = !isInvestorGroup

  const tagsList = companyInfo?.tags || fundHoldingInfo?.tags
  const holdingName = companyInfo?.name || fundHoldingInfo?.name
  const holdingId = companyInfo?.id || fundHoldingInfo?.id

  return (
    <ActionsWrapper>
      {companyInfo || fundHoldingInfo ? (
        <ButtonsWrapper>
          {!isFounder && !isInvestorGroup && (
            <AddToPortfolioButton
              holdingName={holdingName!}
              holdingId={holdingId!}
              forceFetch
              btnCustomStyles={addToPortfolioCustomStyle()}
              isFundHolding={!!fundHoldingInfo}
            />
          )}

          {isSearchByTagAllowed && (
            <Tags
              listOfTags={tagsList}
              holdingId={holdingId!}
              isFundHolding={!!fundHoldingInfo}
            />
          )}
        </ButtonsWrapper>
      ) : (
        <PlaceHolderDiv />
      )}

      <ProfilesActions />
    </ActionsWrapper>
  )
}

export default HeaderContent
