import { useHistory } from 'react-router-dom'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ArrowWrapper,
  LinkWrapper,
  NavigationItem,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import useParseCurrentUrl from 'components/Breadcrumb/useParseCurrentUrl'
import {
  FUNDS_CHAT,
  FUNDS_UPDATES,
  GROUP_PROFILE_CHAT,
  GROUP_PROFILE_CHAT_EDIT,
  GROUP_PROFILE_INVESTMENTS,
  GROUP_PROFILE_INVESTMENTS_EDIT,
  GROUP_PROFILE_METRICS,
  GROUP_PROFILE_METRICS_EDIT,
  GROUP_PROFILE_METRICS_ID_ADD_VALUE,
  GROUP_PROFILE_METRICS_ID_EDIT,
  GROUP_PROFILE_METRICS_ID_SET_MILESTONE,
  GROUP_PROFILE_METRICS_METRIC_ID,
  GROUP_PROFILE_UPDATES,
  GROUP_PROFILE_UPDATES_EDIT,
} from 'routes/constant/fundManagerRoutes'
import { Update } from 'utils/types/update'
import { CompanyHoldingData, FundProfile } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'

interface Props {
  update?: Update
  entityToShow?: FundProfile | CompanyHoldingData
  url: URL
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
  getAvatar: () => Nullable<JSX.Element>
  getFundCompanyAvatar: () => Nullable<JSX.Element>
  fundCompany: any
}

const OrganizationProfileBreadcrumb = ({
  update,
  entityToShow,
  url,
  initialLinkRedirect,
  fundCompany,
  isCreatingNewUpdate,
  getAvatar,
  getFundCompanyAvatar,
}: Props) => {
  const history = useHistory()
  const { metricId } = useParseCurrentUrl()

  const initialTabActive = [
    GROUP_PROFILE_UPDATES,
    GROUP_PROFILE_UPDATES_EDIT,
    GROUP_PROFILE_INVESTMENTS,
    GROUP_PROFILE_INVESTMENTS_EDIT,
    GROUP_PROFILE_METRICS,
    GROUP_PROFILE_METRICS_METRIC_ID.replace(':metricId', metricId),
    GROUP_PROFILE_METRICS_ID_ADD_VALUE.replace(':metricId', metricId),
    GROUP_PROFILE_METRICS_ID_SET_MILESTONE.replace(':metricId', metricId),
    GROUP_PROFILE_METRICS_ID_EDIT.replace(':metricId', metricId),
    GROUP_PROFILE_METRICS_EDIT,
    GROUP_PROFILE_CHAT,
    GROUP_PROFILE_CHAT_EDIT,
  ]

  const isActive = initialTabActive.some((tab) => tab === url.current)

  const entityActiveTabs = [
    FUNDS_UPDATES.replace(':fundId', entityToShow?.id!),
    FUNDS_CHAT.replace(':fundId', entityToShow?.id!),
  ]

  const isEntityActive = entityActiveTabs.some((tab) => tab === url.current)

  return (
    <NavigationWrapper>
      {fundCompany && (
        <NavigationItem>
          {getFundCompanyAvatar()}
          <LinkWrapper
            isActive={isActive}
            onClick={() => {
              history.push(initialLinkRedirect)
            }}
          >
            {fundCompany.name}
          </LinkWrapper>

          {(update || isCreatingNewUpdate || entityToShow) && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </NavigationItem>
      )}

      {entityToShow && (
        <NavigationItem>
          {getAvatar()}
          <LinkWrapper
            isActive={isEntityActive}
            onClick={() => {
              if (isEntityActive) return
              history.push(FUNDS_UPDATES.replace(':fundId', entityToShow.id))
            }}
          >
            {entityToShow.name}
          </LinkWrapper>

          {(update || isCreatingNewUpdate) && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </NavigationItem>
      )}

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default OrganizationProfileBreadcrumb
