import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import CompanyService from 'api/CompanyService'
import Title from 'components/Title'
import List from 'components/List'
import SetAsMainRow from 'components/List/SetAsMainRow'
import Toast from 'components/Toast'

import LocationInput, { Location as InputLocation } from 'ui/LocationInput'
import { Company } from 'utils/types/company'
import { Location } from 'utils/types/locations'
import InputError from 'ui/InputError'
import GoogleApiService from 'api/GoogleApiService'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import { ErrorType } from 'utils/types/common'

import * as Styles from '../common/styles'

export interface CompanyDataValues {
  companyData?: Partial<Company>
  locations: Location[]
}

interface CompanyLocationProps {
  companyData: Company
  locations: Location[]
  onChange: (data: CompanyDataValues) => void
}

const CompanyLocation: React.FC<CompanyLocationProps> = ({
  companyData,
  locations,
  onChange,
}) => {
  const intl = useIntl()
  const [locationError, setLocationError] = useState('')

  const formatLocations = (entities): Location[] => {
    return Object.values(entities.locations).map((loc: any) =>
      ResponseNormalizer.normalizeLocation(loc, entities)
    )
  }

  const editLocation = async (locationId: string, position: number) => {
    try {
      const newLocations = await CompanyService.editCompanyLocation(
        locationId,
        {
          position,
        }
      )

      onChange({
        companyData: {
          [companyData.id]: {
            ...companyData,
            locations: newLocations.map((loc) => loc.id),
          },
        },
        locations: newLocations,
      })
    } catch (errors) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.editItemError' },
          { name: '', item: intl.formatMessage({ id: 'general.mainLocation' }) }
        ),
        'error'
      )
    }
  }

  const removeLocation = async (locationId: string) => {
    try {
      await CompanyService.removeCompanyLocation(locationId)
      const newLocations = locations.filter((loc) => loc.id !== locationId)
      onChange({
        companyData: {
          [companyData.id]: {
            ...companyData,
            locations: newLocations.map((loc) => loc.id),
          },
        },
        locations: newLocations,
      })
    } catch (errors) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.removeItemError' },
          { name: '', item: intl.formatMessage({ id: 'general.location' }) }
        ),
        'error'
      )
    }
  }

  const createLocation = async (location: InputLocation) => {
    if (
      locations.some((loc) => loc.place?.googlePlaceId === location.place_id)
    ) {
      setLocationError(
        intl.formatMessage(
          { id: 'errors.locationAlreadyAdded' },
          { location: location.description }
        )
      )
      return
    }
    setLocationError('')
    const placeData = await GoogleApiService.getPlaceData(location.place_id)
    try {
      const {
        data: { entities },
      } = await CompanyService.createCompanyLocation(companyData.id, {
        location: {
          position: locations.length + 1,
          placeAttributes: {
            googlePlaceId: location.place_id,
            formattedAddress: location.description,
            latitude: placeData.geometry.location.lat(),
            longitude: placeData.geometry.location.lng(),
            addressComponentsAttributes: placeData.address_components,
            types: placeData.types,
          },
        },
      })

      const newLocations = [...locations, ...formatLocations(entities)]
      onChange({
        companyData: {
          ...companyData,
          ...entities.companyData[companyData.id],
        },
        locations: newLocations,
      })
    } catch (errors) {
      Toast.display(
        intl.formatMessage(
          { id: 'errors.creatingItemError' },
          { item: intl.formatMessage({ id: 'general.location' }) }
        ),
        'error'
      )
    }
  }

  return (
    <Styles.Item>
      <Title title={intl.formatMessage({ id: 'editCompany.locations' })} />
      <LocationInput
        placeholder={intl.formatMessage({
          id: 'editCompany.addLocationPlaceholder',
        })}
        loadingMessage={intl.formatMessage({
          id: 'editCompany.loadingMessage',
        })}
        onChange={(loc) => createLocation(loc.value)}
      />
      <InputError
        type={ErrorType.ERROR}
        hasError={!!locationError}
        displayOnError
      >
        {locationError}
      </InputError>
      <List
        elements={locations.map((location, index) => {
          return (
            <SetAsMainRow
              key={location.id}
              text={location.place?.formattedAddress ?? ''}
              isMain={!index}
              setAsMain={() => editLocation(location.id, 1)}
              removeItem={() => removeLocation(location.id)}
            />
          )
        })}
        striped={false}
        rowHeightAuto
        rowWidthAuto
      />
    </Styles.Item>
  )
}

export default CompanyLocation
