export enum AnalyticAccessType {
  DIRECT_SHARE = 'direct_share',
  PUBLIC_SHARE = 'public_share',
  RESHARE = 'reshare',
}

export enum AnalyticsGroupBy {
  DAY = 'day',
  HOUR = 'hour',
  MONTH = 'month',
}

export enum AnalyticsDisplayBy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum QuarterDayTimeFrame {
  FRAME_0_6 = 0,
  FRAME_6_12 = 1,
  FRAME_12_18 = 2,
  FRAME_18_24 = 3,
}

export type AnalyticsChartParams = {
  displayType: AnalyticsDisplayBy
  startDate: Date
  endDate: Date
  page: number
}

export type AnalyticsChartData = {
  x: string
  y: number
  plainX: string
}
