import FadeIn from 'components/FadeIn'
import Tooltip from 'components/Tooltip'
import React from 'react'
import { useIntl } from 'react-intl'
import { useRandomId } from 'utils/hooks/useRandomId'
import theme from 'utils/theme'
import {
  DeleteButton,
  EmailRowContainer,
  EmailRowContent,
  MainTag,
  SetAsMainButton,
} from '../Row.styles'

interface EmailRowProps {
  email: string
  onDelete: () => void
  mainAndSetAsMainActive?: boolean
  setAsMain?: () => void
  main?: boolean
}

const EmailRow: React.FC<EmailRowProps> = ({
  email,
  main,
  mainAndSetAsMainActive = true,
  onDelete,
  setAsMain,
}) => {
  const tooltipId = useRandomId()
  const intl = useIntl()

  return (
    <FadeIn>
      <EmailRowContainer>
        <EmailRowContent>
          <Tooltip
            id={tooltipId}
            text={email}
            place="left"
            widthAuto
            delayShow={500}
          >
            {email}
          </Tooltip>
        </EmailRowContent>

        <div>
          <DeleteButton
            icon="trash-alt"
            color={theme.colors.red}
            onClick={onDelete}
            border={false}
            buttonSize="2rem"
          />
        </div>

        {mainAndSetAsMainActive &&
          (main ? (
            <MainTag>{intl.formatMessage({ id: 'common.main' })}</MainTag>
          ) : (
            <SetAsMainButton onClick={setAsMain}>
              {intl.formatMessage({ id: 'common.setAsMain' })}
            </SetAsMainButton>
          ))}
      </EmailRowContainer>
    </FadeIn>
  )
}

export default EmailRow
