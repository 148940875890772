import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Badge from 'components/Badge'
import { FormattedMessage } from 'react-intl'
import IconButton from 'ui/IconButton'
import {
  EmployeeName,
  RowContainer,
  DragCol,
  EditIconButtonCol,
  RemoveIconButtonCol,
  Icon,
  Empty,
  NameCol,
  PositionCol,
  LinkedinCol,
  EmailCol,
  FounderCol,
  Remove,
} from './TeamMemberRow.styles'

const TeamMemberRow = ({
  employee,
  onEditEmployee,
  onDeleteEmployee,
  isDragging,
}) => {
  return (
    <RowContainer isDragging={isDragging}>
      <DragCol>
        <FontAwesomeIcon icon={['fal', 'bars']} />
      </DragCol>

      <NameCol>
        <EmployeeName>
          {employee.firstName} {employee.lastName}
        </EmployeeName>
      </NameCol>

      <PositionCol>{employee.title}</PositionCol>

      <EmailCol>{employee.email || <Empty>-</Empty>}</EmailCol>

      {employee.linkedinUrl && (
        <LinkedinCol>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={employee.linkedinUrl}
          >
            <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
          </a>
        </LinkedinCol>
      )}

      <FounderCol>
        {employee.founder && (
          <Badge>
            <FormattedMessage id="general.founder" />
          </Badge>
        )}
      </FounderCol>

      <EditIconButtonCol>
        <IconButton
          icon="pen"
          border={false}
          roundedButtonHover={false}
          buttonSize="2rem"
          onClick={() => onEditEmployee(employee)}
        />
      </EditIconButtonCol>

      <RemoveIconButtonCol>
        <Remove
          icon="trash-alt"
          danger
          border={false}
          roundedButtonHover={false}
          buttonSize="2rem"
          onClick={() => onDeleteEmployee(employee)}
        />
      </RemoveIconButtonCol>
    </RowContainer>
  )
}

TeamMemberRow.propTypes = {
  employee: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string,
    title: PropTypes.string,
    linkedinUrl: PropTypes.string,
    founder: PropTypes.bool.isRequired,
  }).isRequired,
  onEditEmployee: PropTypes.func.isRequired,
  onDeleteEmployee: PropTypes.func.isRequired,
  isDragging: PropTypes.bool,
}

TeamMemberRow.defaultProps = {
  isDragging: false,
}

export default TeamMemberRow
