import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import MessageTimestampToStyle from '../../../MessageTimestamp'
import MutedIndicatorToStyle from '../../../MutedIndicator'

export const MessageTimestamp = styled(MessageTimestampToStyle)`
  display: flex;

  time {
    width: max-content;
  }
`

export const ChannelContainer = styled.div<{
  isSelected?: boolean
}>`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  padding: 1.2rem 2.4rem;
  color: ${color('darkGray')};
  min-width: 0;
  width: 100%;

  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: ${color('primaryBlue')};
          color: white;

          ${MessageTimestamp} {
            color: white;
          }
        `
      : css`
          :hover {
            background-color: ${color('veryLightBlue', 0.2)};
            cursor: pointer;
          }
        `}
`

export const ChannelTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.4rem;
  white-space: nowrap;

  span:first-child {
    min-width: 0;
  }
`

const EllipsisStyles = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ChannelTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2.2rem;
  ${EllipsisStyles}
`

export const Message = styled.div`
  font-size: 1.4rem;
  line-height: 1.7rem;
  height: 1.7rem;
  min-width: 0;
  ${EllipsisStyles}
`

export const MessageContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
`

export const ChannelUpdate = styled.div<{ isSelected: boolean }>`
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: ${(props) => color(props.isSelected ? 'veryLightGray' : 'lightGray')};
  ${EllipsisStyles}
`

export const MutedIndicator = styled(MutedIndicatorToStyle)<{
  isSelected: boolean
}>`
  margin-left: 0.3rem;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: white;
    `}
`

export const NameAndMutedContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const TypingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
`

export const TypingText = styled(Message)`
  padding-left: 1rem;
`
