import styled from 'styled-components/macro'
import { size } from 'utils/constants/breakpoint'

export const FundSummaryRowWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;

  @media ${size.xsm} {
    align-items: flex-start;
    flex-direction: row;
  }
`
