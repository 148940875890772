import { motion } from 'framer-motion'
import { css } from 'styled-components/'
import styled from 'styled-components/macro'
import { maxSize, size } from 'utils/constants/breakpoint'
import {
  MAXIMIZED_CHAT_WIDTH,
  MINIMIZED_CHAT_WIDTH,
} from 'utils/constants/chat'
import { color } from 'utils/functions/colors'
import { ClosePopupIconButton } from '../ChatPopupHeader/ChatPopupHeader.styles'

export const Wrapper = styled(motion.div)<{ isMaximized: boolean }>`
  pointer-events: auto;
  min-width: ${MINIMIZED_CHAT_WIDTH}px;
  max-width: ${MINIMIZED_CHAT_WIDTH}px;

  @media ${maxSize.sm} {
    ${({ isMaximized }) =>
      isMaximized &&
      css`
        min-width: 34rem;
        max-width: 34rem;
      `}
  }

  @media ${size.sm} {
    ${({ isMaximized }) =>
      isMaximized &&
      css`
        min-width: ${MAXIMIZED_CHAT_WIDTH}px;
        max-width: ${MAXIMIZED_CHAT_WIDTH}px;
      `}
  }
`

export const PopupBorderStyles = css`
  border-top: 0.1rem solid ${color('veryLightGray')};
  border-left: 0.1rem solid ${color('veryLightGray')};
  border-right: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem 0.8rem 0rem 0rem;
  overflow: hidden;
`

export const ClosedChatPopup = styled.div`
  ${PopupBorderStyles}
  background-color: white;

  &:hover {
    background-color: ${color('veryLightBlue40')};
    border-color: ${color('veryLightBlue')};

    ${ClosePopupIconButton} {
      transition: none;
      background-color: ${color('veryLightBlue')};
    }
  }

  .str-chat {
    background-color: inherit;
  }
`
