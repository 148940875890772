import { useCallback } from 'react'
import { uniqBy } from 'lodash'
import {
  getChannel,
  useChatContext,
  useNotificationAddedToChannelListener,
} from 'stream-chat-react'
import type { Channel, Event } from 'stream-chat'

import { StreamChatType, TopicChannel, isTopicChannel } from '../types'

export const useAddTopicChannel = (
  setChannels: React.Dispatch<
    React.SetStateAction<Channel<StreamChatType<TopicChannel>>[]>
  >,
  topicId?: string
) => {
  const { client } = useChatContext<StreamChatType<TopicChannel>>()

  const onAddChannelToTopic = useCallback(
    async (_, event: Event) => {
      if (event.channel_type && event.channel_id) {
        const channel = await getChannel(
          client,
          event.channel_type,
          event.channel_id
        )
        const belongsToTheActiveTopic =
          isTopicChannel(channel.data) && channel.data.entityId === topicId

        if (belongsToTheActiveTopic) {
          setChannels((currentChannels) =>
            uniqBy([channel, ...currentChannels], 'cid')
          )
        }
      }
    },
    [client, topicId, setChannels]
  )

  useNotificationAddedToChannelListener(setChannels, onAddChannelToTopic)
}
