import { Filter } from 'utils/functions/normalizers/investmentFilterNormalizer'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { IndexPortfolio } from 'utils/types/portfolios'
import {
  ALL_INVESTMENT_OPTION_ID,
  ALL_FOLLOWED_HOLDINGS_OPTION_ID,
  BaseOption,
  InvestmentsNavigationOption,
  UNSAVED_FILTER_OPTION_ID,
  allInvestmentOption,
  unSavedFilterOption,
  allFollowedHoldings,
} from './types'

export const getPortfolioIcon = (type: PortfolioTypes): IconProp => {
  if (type === PortfolioTypes.INVEST) return ['fal', 'dollar-sign']
  if (type === PortfolioTypes.FUND) return ['fal', 'sack-dollar']
  if (type === PortfolioTypes.DEAL) return ['fal', 'badge-dollar']
  return ['fal', 'binoculars']
}

export const normalizePortfolio = (
  portfolio: IndexPortfolio,
  isInvestorGroup: boolean
): InvestmentsNavigationOption => ({
  id: portfolio.id,
  label: portfolio.name,
  showOption: true,
  nonSelectedIcon: getPortfolioIcon(portfolio.type),
  selectedIcon: ['fas', 'check'],
  isSelected: false,
  type: portfolio.type,
  url: isInvestorGroup
    ? `/investments/funds/${portfolio.id}`
    : `/investments/${portfolio.type.toLowerCase()}/${portfolio.id}/portfolio`,
  isPinnable: !isInvestorGroup, // the BE doesnt support pinning portfolios for investor groups yet
  pinned: portfolio.pinned,
})

export const normalizeFilters = (
  filter: Filter
): InvestmentsNavigationOption => ({
  id: filter.id,
  label: filter.name,
  nonSelectedIcon: ['far', 'filter'],
  selectedIcon: ['fas', 'check'],
  isSelected: false,
  type: 'InvestmentFilter',
  showOption: true,
  url: `/investments/filters/${filter.id}`,
  isPinnable: false,
  pinned: false,
})

export const checkItemInLocalStorage = (key: string) => {
  if (typeof localStorage !== 'undefined') {
    const item = localStorage.getItem(key)

    if (item !== null) {
      return true
    }
  }

  return false
}

export const shouldAllInvestmentOptionBeVisible = (
  portfolios: InvestmentsNavigationOption[]
): boolean =>
  portfolios.filter(
    (portfolio) =>
      portfolio.type !== PortfolioTypes.TRACK && portfolio.type !== null
  ).length > 1

export const shouldAllFollowingHoldingsOptionBeVisible = (
  portfolios: InvestmentsNavigationOption[]
): boolean =>
  portfolios.filter(
    (portfolio) =>
      portfolio.type === PortfolioTypes.TRACK && portfolio.type !== null
  ).length > 1

export const getVisibleOptionFromToggleFilters = ({
  option,
  portfolioTypeFilterOptions,
}: {
  option: InvestmentsNavigationOption
  portfolioTypeFilterOptions: BaseOption[]
}) => {
  return portfolioTypeFilterOptions.some(
    (filterOption) => filterOption.id === option.type && filterOption.status
  )
}

interface GetDefaultNavigationOptions {
  portfolioNavigationOptions: InvestmentsNavigationOption[]
  filterNavigationOptions: InvestmentsNavigationOption[]
  hasUnsavedFilter: boolean
  isAllInvestmentOptionVisible: boolean
  isAllFollowedHoldingsOptionVisible: boolean
}

export const getDefaultNavigationOptions = ({
  portfolioNavigationOptions,
  filterNavigationOptions,
  hasUnsavedFilter,
  isAllInvestmentOptionVisible,
  isAllFollowedHoldingsOptionVisible,
}: GetDefaultNavigationOptions): InvestmentsNavigationOption[] => {
  let options: InvestmentsNavigationOption[] = [
    ...portfolioNavigationOptions,
    ...filterNavigationOptions,
  ]

  if (isAllFollowedHoldingsOptionVisible) {
    options.unshift(allFollowedHoldings)
  }

  if (isAllInvestmentOptionVisible) {
    options.unshift(allInvestmentOption)
  }

  if (hasUnsavedFilter) {
    options = [...options, unSavedFilterOption]
  }

  return options
}

interface GetVisibleNavigationOptions {
  options: InvestmentsNavigationOption[]
  portfolioTypeFilterOptions: BaseOption[]
  isAllInvestmentOptionVisible?: boolean
  isAllFollowedHoldingsOptionVisible?: boolean
  hasUnsavedFilter?: boolean
  isShowingAllInvestmentsOption?: boolean
  isShowingUnsavedFiltersOption?: boolean
  isShowingAllFollowedHoldingsOption?: boolean
  entityId?: string
}

// So far we have 3 different cases:
// 1. We are showing the "All investments" option.
// 2. We are showing the "Unsaved filters" option.
// 3. We are showing the selected option (portfolio or filter entity).
export const getVisibleNavigationOptions = ({
  options,
  portfolioTypeFilterOptions,
  isShowingAllInvestmentsOption = false,
  isShowingUnsavedFiltersOption = false,
  isShowingAllFollowedHoldingsOption = false,
  hasUnsavedFilter = false,
  isAllInvestmentOptionVisible = false,
  isAllFollowedHoldingsOptionVisible = false,
  entityId,
}: GetVisibleNavigationOptions): InvestmentsNavigationOption[] => {
  if (isShowingAllFollowedHoldingsOption) {
    return options.map((option) => {
      if (option.id === ALL_FOLLOWED_HOLDINGS_OPTION_ID) {
        return {
          ...option,
          isSelected: true,
        }
      }

      if (option.id === ALL_INVESTMENT_OPTION_ID) {
        return option
      }

      return {
        ...option,
        showOption: getVisibleOptionFromToggleFilters({
          option,
          portfolioTypeFilterOptions,
        }),
        isSelected: false,
      }
    })
  }

  if (isShowingAllInvestmentsOption) {
    return options.map((option) => {
      if (option.id === ALL_INVESTMENT_OPTION_ID) {
        return {
          ...option,
          isSelected: true,
        }
      }

      if (option.id === ALL_FOLLOWED_HOLDINGS_OPTION_ID) {
        return option
      }

      return {
        ...option,
        showOption: getVisibleOptionFromToggleFilters({
          option,
          portfolioTypeFilterOptions,
        }),
        isSelected: false,
      }
    })
  }

  if (isShowingUnsavedFiltersOption) {
    return options.map((option) => {
      if (option.id === UNSAVED_FILTER_OPTION_ID) {
        return {
          ...option,
          isSelected: true,
        }
      }

      return {
        ...option,
        showOption: getVisibleOptionFromToggleFilters({
          option,
          portfolioTypeFilterOptions,
        }),
        isSelected: false,
      }
    })
  }

  return options.map((option) => {
    if (option.id === ALL_FOLLOWED_HOLDINGS_OPTION_ID) {
      return {
        ...option,
        showOption: isAllFollowedHoldingsOptionVisible,
        isSelected: false,
      }
    }

    if (option.id === ALL_INVESTMENT_OPTION_ID) {
      return {
        ...option,
        showOption: isAllInvestmentOptionVisible,
        isSelected: false,
      }
    }

    if (option.id === UNSAVED_FILTER_OPTION_ID) {
      return {
        ...option,
        showOption: hasUnsavedFilter,
        isSelected: false,
      }
    }

    return {
      ...option,
      isSelected: entityId === option.id,
      showOption: getVisibleOptionFromToggleFilters({
        option,
        portfolioTypeFilterOptions,
      }),
    }
  })
}

export const getSelectedPortfolioIndex = (
  options: InvestmentsNavigationOption[],
  entityId: string
) => {
  return options.findIndex((option) => option.id === entityId)
}
