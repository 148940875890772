import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'

interface ProfileContextProps {
  search: string
  debouncedSearch: string
  onChange: (event: React.ChangeEvent) => void
}

interface HeaderProviderProps {
  children: ReactElement | ReactElement[]
}

export const ProfileContext = createContext({} as ProfileContextProps)

export const ProfileProvider: FC<HeaderProviderProps> = ({ children }) => {
  const [search, debouncedSearch, setSearch] = useDebouncedState('')

  const onChange = useCallback(
    (event) => {
      setSearch(event.target.value)
    },
    [setSearch]
  )

  const value = useMemo(
    () => ({ search, debouncedSearch, onChange }),
    [debouncedSearch, onChange, search]
  )

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export const useProfileContext = () => useContext(ProfileContext)
