import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useParams } from 'react-router-dom'
import { getCurrentGroupId } from 'selectors/auth'

const useInvestorGroup = () => {
  const { investorId } = useParams<{ investorId: string }>()
  const groupId = useAppSelector(getCurrentGroupId)

  return {
    id: investorId || groupId,
  }
}

export default useInvestorGroup
