import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const TextAreaWrapper = styled.div`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`
export const TextAreaContainer = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid transparent;
  color: ${color('darkGray')};
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  min-height: ${({ isThin }) => (isThin ? '7.6rem' : '20rem')};
  padding: 0 0.5rem;
  resize: none;
  width: 100%;
  background: ${color('lightGray', 0.1)};
  transition: 0.2s background-color, 0.1s border;

  div[class*='DraftEditorPlaceholder'] {
    font-style: normal;
    color: #4a4a68;
    font-family: 'Lato';
    font-weight: 400;
    opacity: 0.6;
  }

  div[class^='RichTextEditor__editor'] {
    font-size: 1.4rem;
  }
  div[class*='public-DraftEditor-content'] {
    min-height: ${({ isThin }) => (isThin ? '7.6rem' : '17rem')};
    width: 100%;
  }

  div[id^='rich-text-editor-body'] {
    width: 100%;
  }

  .DraftEditor-editorContainer {
    transition: 0.2s background-color;
    background: ${color('lightGray', 0.1)};
    span[data-text='true'] {
      color: ${color('darkGray')};
    }
  }

  &:disabled {
    color: var(--color-gray-500);
    background-color: var(--color-gray-100);
    cursor: not-allowed;
  }

  &::placeholder {
    font-size: 1.4rem;
    color: ${color('darkGray', 0.5)};
  }

  ${(props) =>
    props.isFocused
      ? css`
          background-color: ${color('white')(props)};

          border: 0.1rem solid
            ${
              props.hasError ? color('red')(props) : color('primaryBlue')(props)
            };

          .DraftEditor-editorContainer {
            background-color: ${color('white')(props)};};
          }
        `
      : css`
          &:hover {
            border: 0.1rem solid ${color('veryLightBlue')};
            border-radius: 0.8rem;
          }
          background-color: ${
            props.hasError
              ? color('red', 0.15)(props)
              : color('lightGray', 0.1)(props)
          };

          .DraftEditor-editorContainer {
            background-color: ${
              props.hasError
                ? color('red', 0.15)(props)
                : color('lightGray', 0.1)(props)
            }
        `}

  ${(props) =>
    props.disabled &&
    css`
      div[class^='RichTextEditor__editor'] {
        cursor: not-allowed;

        span {
          color: var(--color-gray-500);
        }
      }
    `}
`

export const FieldErrorMessage = styled.span`
  color: ${color('red')};
  font-size: 1.4rem;
`

export const InvisibleInput = styled.input`
  display: none;
`
