import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Avatar from 'components/Avatar'
import CompanySocials from 'components/CompanySocials'
import CompanyStatus from 'components/CompanyStatus'
import { getInitials } from 'utils/functions/user'
import { FundManagerData } from 'utils/hooks/investorGroups/useFundManagerData'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { Nullable } from 'utils/types/common'
import { CompanyHoldingData, getCompanyHoldingUrl } from 'utils/types/company'
import { getCurrentGroupType } from 'selectors/auth'
import Tooltip from 'components/Tooltip'
import { useIntl } from 'react-intl'
import {
  BasicInformationWrapper,
  CompanyName,
  FundManagerName,
  ImageWrapper,
} from './HeaderBasicInformation.styles'

interface Props {
  companyInfo?: CompanyHoldingData
  fundManagerData?: Nullable<FundManagerData>
  companyName: string
  companyImage?: string
  isVerified: boolean
}

const HeaderBasicInformation = ({
  companyInfo,
  fundManagerData,
  companyName,
  companyImage,
  isVerified,
}: Props) => {
  const isFundProfile = !!fundManagerData
  const intl = useIntl()

  const currentGroupType = useAppSelector(getCurrentGroupType)
  return (
    <BasicInformationWrapper>
      <ImageWrapper>
        <Avatar
          image={companyImage}
          initials={getInitials(companyName)}
          avatarStyle="avatarCircleLarge"
        />
      </ImageWrapper>

      <CompanyName isVerified={isVerified}>
        {companyName}
        {isVerified && (
          <Tooltip
            place="right"
            id="update-title-tooltip"
            text={
              <>
                <p>
                  {intl.formatMessage({
                    id: 'companyProfile.verifiedProfile1',
                  })}
                </p>
                <p>
                  {intl.formatMessage({
                    id: 'companyProfile.verifiedProfile2',
                  })}
                </p>
              </>
            }
            delayShow={500}
            widthAuto
          >
            <FontAwesomeIcon icon={['fas', 'check-circle']} />
          </Tooltip>
        )}
      </CompanyName>

      {isFundProfile && (
        <FundManagerName
          to={getCompanyHoldingUrl(fundManagerData.id!, currentGroupType)}
        >
          {fundManagerData.name}
        </FundManagerName>
      )}

      <CompanySocials
        centerLinks
        withTitle={false}
        companyInfo={isFundProfile ? fundManagerData : companyInfo}
      />

      {!isFundProfile && companyInfo && (
        <CompanyStatus
          statusMargin="1.8rem 0 0 0"
          company={companyInfo}
          tooltipPlace="right"
        />
      )}
    </BasicInformationWrapper>
  )
}

export default HeaderBasicInformation
