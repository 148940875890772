import { useChatContext } from 'stream-chat-react'

const C = <T,>({ Component, ...rest }: { Component: React.FC } & T) => {
  const { client } = useChatContext()

  if (!client) return null

  return <Component {...rest} />
}

const ChatWrapper =
  (Component: React.FC) =>
  <T,>(props: T) => {
    return <C Component={Component} {...props} />
  }

export default ChatWrapper
