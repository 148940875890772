import React from 'react'
import { FormattedMessage } from 'react-intl'

import Card from 'components/Card'
import FileUploader from 'components/FileUploader'
import { EmailUpdate } from 'utils/types/update'
import UpdateAttachmentsPreview from 'components/UpdateAttachmentsPreview'
import UploadsMenu from 'components/UploadsMenu'
import useEmailAttachments from './useEmailAttachments'
import { RunOutOfStorageMessageStyled } from './EmailAttachments.styles'

interface EmailAttachmentProps {
  emailUpdate: EmailUpdate
}
const EmailAttachments: React.FC<EmailAttachmentProps> = ({ emailUpdate }) => {
  const {
    attachments,
    handleFileUploads,
    handleSelectExistentFiles,
    handleRemoveFile,
    isAssociatingAttachments,
  } = useEmailAttachments(emailUpdate, emailUpdate.contents)

  return (
    <Card>
      <Card.Header>
        <FormattedMessage id="createEmail.addAttachments" />
      </Card.Header>
      <Card.Body>
        <RunOutOfStorageMessageStyled />
        <FileUploader
          fileHandler={handleFileUploads}
          onSelectExistentFiles={handleSelectExistentFiles}
          allowMultipleFiles
          onlyFileContents
          isEmail
        />

        <UpdateAttachmentsPreview
          contents={attachments}
          mode="grid"
          canDelete
          handleRemoveFile={handleRemoveFile}
          isAssociatingAttachments={isAssociatingAttachments}
        />
        <UploadsMenu />
      </Card.Body>
    </Card>
  )
}

export default EmailAttachments
