import { useEffect } from 'react'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import Toast from 'components/Toast'
import PortfolioService, { PortfoliosFilters } from 'api/PortfolioService'
import { portfolioKeys } from 'utils/queries/portfolios'
import { IndexPortfolio } from 'utils/types/portfolios'
import { setPortfolios } from 'actions/portfolios'
import useInfiniteScroll from './useInfiniteScroll'
import { useAppDispatch } from './reduxToolkit'

const usePortfoliosQuery = (
  filters: PortfoliosFilters,
  queryOptions: UseInfiniteQueryOptions<{
    portfolios: IndexPortfolio[]
    page: number
  }> = {}
) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const {
    ref: infiniteScrollRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()

  const query = useInfiniteQuery(
    portfolioKeys.fundPortfolioHoldings(filters),
    async ({ pageParam = 1 }) => {
      try {
        const portfolios = await PortfolioService.fetchPortfolios({
          ...filters,
          page: pageParam,
        })

        if (portfolios.length === 0) {
          setInfiniteScrollEnabled(false)
        }

        dispatch(setPortfolios(portfolios))

        return {
          portfolios,
          page: pageParam,
        }
      } catch (error) {
        Toast.display(
          intl.messages['investorManagement.fundHoldings.somethingWentWrong'],
          'error'
        )

        return {
          portfolios: [],
          page: 1,
        }
      }
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1
      },
      refetchOnMount: 'always',
      ...queryOptions,
    }
  )

  useEffect(() => {
    if (onScreen) {
      query.fetchNextPage()
    }
  }, [onScreen, query])

  return {
    ...query,
    infiniteScrollRef,
  }
}

export default usePortfoliosQuery
