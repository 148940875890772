import Toast from 'components/Toast'
import { ChangeEvent, DragEvent, useState } from 'react'
import { useIntl } from 'react-intl'

export const useBulkImportFileUpload = (parseCSV: (file: File) => void) => {
  const intl = useIntl()
  const [uploadFile, setUploadFile] = useState<File>()
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const [isImportingFile, setIsImportingFile] = useState(false)
  const [dragTargets, setDragTargets] = useState<HTMLElement[]>([])

  const onUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setUploadFile(event.target.files[0])
      parseCSV(event.target.files[0])
    }
  }

  const getDataTransferItems = ({ dataTransfer, target }) => {
    let dataTransferItemsList = []
    if (dataTransfer) {
      if (dataTransfer?.files?.length) {
        dataTransferItemsList = dataTransfer.files
      } else if (dataTransfer?.items?.length) {
        dataTransferItemsList = dataTransfer.items
      }
    } else if (target?.files) {
      dataTransferItemsList = target.files
    }

    return Array.prototype.slice.call(dataTransferItemsList)
  }

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()

    const fileList = getDataTransferItems(event)

    if (fileList[0]?.type !== 'text/csv') {
      Toast.display(intl.messages['bulkImportModal.fileTypeError'], 'error')
    } else {
      setUploadFile(fileList[0])
      parseCSV(fileList[0])
    }
    setIsDraggingOver(false)
  }

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.persist()
    event.preventDefault()
    setIsDraggingOver(true)
    return false
  }

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDraggingOver(false)
  }

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.persist()
    event.preventDefault()

    if (dragTargets.indexOf(event?.target as HTMLElement) === -1) {
      setDragTargets((state) => [...state, event.target as HTMLElement])
    }
  }

  return {
    uploadFile,
    isDraggingOver,
    isImportingFile,
    setIsImportingFile,
    onUploadFile,
    onDrop,
    onDragOver,
    onDragLeave,
    onDragEnter,
  }
}
