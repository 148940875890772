import { SuggestedUpdatesNormalizer } from 'utils/functions/suggestedUpdatesNormalizer'
import { UpdateEntityType } from 'components/UpdatesFeedV2'
import { InvestmentsFiltersObject } from 'containers/Investments/InvestmentsFilters/types'
import { UpdateInfoItemWithId, UpdatesTableInfo } from 'utils/types/update'
import axiosClient from './httpClient'
import { CommonParams, SubjectMatterType } from './UpdateService'
import { assertUnreachable } from '../utils/functions/utils'
import { getCompaniesParams } from './CompanyService'

export interface UpdateEntityParams {
  entityType: UpdateEntityType
  entityId?: string | string[]
}
export interface MixedUpdatesParams extends UpdateEntityParams, CommonParams {
  page: number
  pageSize?: number
  userWithDraftUpdates?: string
  published?: boolean
  suggested?: boolean
  draft?: boolean
  typesNotIncluded?: string[]
  updatesForInvestorTab?: boolean
}

enum MixedUpdatesQueryParams {
  SUBJECT_MATTER_TYPE_EQ = 'q[logging_update_subject_matter_type_eq]',
  SUBJECT_MATTER_ID_IN = 'q[logging_update_subject_matter_id_in]',
  SUBJECT_MATTER_ID_EQ = 'q[logging_update_subject_matter_id_eq]',
  EMAIL_CONTENTS_BY_HOLDING_ID_EQ = 'q[email_content_holding_id_eq]',
  BELONGING_TO_PORTFOLIOS = 'q[belonging_to_portfolio_or_companies_in_portfolio]',
  UPDATES_ON_INVESTOR_TAB = 'q[belonging_to_investor_groups_in_portfolio]',
  BELONGING_TO_INVESTOR = 'q[belonging_to_investor_group_or_investment_vehicle]',
  DRAFT_NOT_TRUE = 'q[logging_update_draft_not_true]',
  SCHEDULED_NOT_TRUE = 'q[logging_update_scheduled_not_true]',
  UPDATE_TYPE_IN = 'q[logging_update_updatable_type_in]',
  UPDATE_TYPE_NOT_IN = 'q[updatable_type_not_in]',
  // *** notice the p instead of q
  UPDATE_TYPE_IN_ON_FILTERS = 'p[logging_update_updatable_type_in]',
  TEXT_ON_FILTERS = 'text',
  // *** --- end
  TEXT = 'text',
  TAGS = 'tags_name',
  MY_UPDATES = 'q[only_editable]',
  DRAFT_UPDATES = 'q[user_editing_eq]',
  PUBLISHED_UPDATES = 'q[user_editing_not_eq]',
  SUGGESTED_UPDATES = 'q[email_content_id_not_null]',

  // *** notice the c instead of q
  PUBLISHED = 'c[published]',
  SUGGESTED = 'c[suggested]',
  DRAFT = 'c[draft]',

  // FOR POST REQUEST (/all)
  MY_UPDATES_ON_POST = 'only_editable',
  DRAFT_UPDATES_ON_POST = 'user_editing_eq',
  SCHEDULED_NOT_TRUE_ON_POST = 'logging_update_scheduled_not_true',
  USER_WITH_DRAFT_UPDATES = 'q[user_editing_eq]',
  MATCHES = 'q[m]',
}

export default class MixedUpdatesService {
  private static getEntityQueryParam(
    entityType: UpdateEntityType,
    updatesForInvestorTab: boolean,
    entityId?: string | string[]
  ) {
    const isArrayOfEntities = Array.isArray(entityId)
    const subjectMatterIdFilter = isArrayOfEntities
      ? MixedUpdatesQueryParams.SUBJECT_MATTER_ID_IN
      : MixedUpdatesQueryParams.SUBJECT_MATTER_ID_EQ
    const suggestedUpdatesFilter = !isArrayOfEntities
      ? {
          [MixedUpdatesQueryParams.EMAIL_CONTENTS_BY_HOLDING_ID_EQ]: entityId,
          [MixedUpdatesQueryParams.MATCHES]: 'or',
        }
      : undefined

    switch (entityType) {
      case UpdateEntityType.MY_UPDATES:
        return {
          [MixedUpdatesQueryParams.MY_UPDATES]: true,
        }
      case UpdateEntityType.COMPANY:
        return {
          [subjectMatterIdFilter]: entityId,
          ...suggestedUpdatesFilter,
        }

      case UpdateEntityType.FUND:
        return {
          [subjectMatterIdFilter]: entityId,
          ...suggestedUpdatesFilter,
        }

      case UpdateEntityType.PORTFOLIO:
        return {
          [subjectMatterIdFilter]: entityId,
          [MixedUpdatesQueryParams.SUBJECT_MATTER_TYPE_EQ]:
            SubjectMatterType.PORTFOLIO,
        }

      case UpdateEntityType.PORTFOLIO_COMPANIES:
      case UpdateEntityType.ALL_PORTFOLIOS:
        if (updatesForInvestorTab) {
          return {
            [MixedUpdatesQueryParams.UPDATES_ON_INVESTOR_TAB]: entityId,
          }
        }
        return {
          [MixedUpdatesQueryParams.BELONGING_TO_PORTFOLIOS]: entityId,
        }

      case UpdateEntityType.INVESTOR:
        return {
          [MixedUpdatesQueryParams.BELONGING_TO_INVESTOR]: entityId,
        }
      case UpdateEntityType.EMAILS:
        return {}

      default:
        return assertUnreachable(entityType)
    }
  }

  private static getFiltersQueryParams = (params: MixedUpdatesParams) => {
    if (params.draft && params.published && params.suggested) {
      return {}
    }

    return {
      [MixedUpdatesQueryParams.DRAFT]: params.draft,
      [MixedUpdatesQueryParams.PUBLISHED]: params.published,
      [MixedUpdatesQueryParams.SUGGESTED]: params.suggested,
    }
  }

  static fetchMixedUpdates = async (params: MixedUpdatesParams) => {
    const filteringByInvestor = !!params?.updatesForInvestorTab
    const queryParams = {
      ...this.getEntityQueryParam(
        params.entityType,
        filteringByInvestor,
        params.entityId
      ),
      page: params.page,
      per_page: params.pageSize,
      [MixedUpdatesQueryParams.TAGS]: params.tags,
      [MixedUpdatesQueryParams.UPDATE_TYPE_IN_ON_FILTERS]: params.types,
      [MixedUpdatesQueryParams.UPDATE_TYPE_NOT_IN]: params.typesNotIncluded,
      [MixedUpdatesQueryParams.TEXT]: params.text,
      ...this.getFiltersQueryParams(params),
    }
    const response = await axiosClient().get('/mixed_updates', {
      params: queryParams,
    })

    return SuggestedUpdatesNormalizer.normalizeMixedUpdates(
      response.data.entities
    )
  }

  // this service returns the cached updates by hitting the /mixed_updates/grouped_by_entity endpoint previously
  static fetchMixedUpdatesForEntity = async (
    params: Pick<MixedUpdatesParams, 'entityId' | 'page' | 'pageSize'>
  ) => {
    const response = await axiosClient().get('/mixed_updates', {
      params: {
        entity_id: params.entityId,
        page: params.page,
        per_page: params.pageSize,
      },
    })

    return SuggestedUpdatesNormalizer.normalizeMixedUpdates(
      response.data.entities
    )
  }

  static fetchMixedUpdatesForInvestmentsFilters = async ({
    params,
    investmentsFilterObject,
  }: {
    params: MixedUpdatesParams
    investmentsFilterObject: InvestmentsFiltersObject
  }) => {
    let queryParams = getCompaniesParams(investmentsFilterObject)

    queryParams = {
      page: params.page,
      ...queryParams,
      ...this.getEntityQueryParam(params.entityType, false, params.entityId),
      [MixedUpdatesQueryParams.UPDATE_TYPE_IN_ON_FILTERS]: params.types,
      [MixedUpdatesQueryParams.TEXT_ON_FILTERS]: params.text,
      [MixedUpdatesQueryParams.TAGS]: params.tags,
    }
    const response = await axiosClient().get('/mixed_updates', {
      params: queryParams,
    })

    return SuggestedUpdatesNormalizer.normalizeMixedUpdates(
      response.data.entities
    )
  }

  static fetchAllPortfolioMixedUpdates = async (params: MixedUpdatesParams) => {
    const filteringByDraft = params?.userWithDraftUpdates ? 1 : undefined

    const body = {
      q: {
        belonging_to_portfolio_or_companies_in_portfolio: params.entityId,
        logging_update_updatable_type_in: params.types?.length
          ? params.types
          : undefined,
        [MixedUpdatesQueryParams.DRAFT_UPDATES_ON_POST]:
          params.userWithDraftUpdates,
        [MixedUpdatesQueryParams.MY_UPDATES_ON_POST]: filteringByDraft,
        [MixedUpdatesQueryParams.SCHEDULED_NOT_TRUE_ON_POST]: filteringByDraft,
      },
      [MixedUpdatesQueryParams.TEXT]: params.text,
      [MixedUpdatesQueryParams.TAGS]: params.tags?.length
        ? params.tags
        : undefined,
    }

    const response = await axiosClient().post(
      `/mixed_updates/all?page=${params.page}`,
      body
    )
    return SuggestedUpdatesNormalizer.normalizeMixedUpdates(
      response.data.entities
    )
  }

  static fetchUpdatesInfo = async ({
    entityId,
  }: {
    entityId: string
  }): Promise<UpdateInfoItemWithId[]> => {
    const response = await axiosClient().get<UpdatesTableInfo>(
      '/mixed_updates/grouped_by_entity',
      {
        params: {
          [MixedUpdatesQueryParams.BELONGING_TO_PORTFOLIOS]: entityId,
        },
      }
    )

    const updatesInfoArray = Object.entries(
      response.data.updatesGroupedByEntity
    ).map(([id, updateInfo]) => ({ id, ...updateInfo }))

    return updatesInfoArray
  }
}
