import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CreateChannel = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  font-size: 1.4rem;
  color: ${color('gray')};
  background: ${color('white')};
  cursor: pointer;
  padding: 1.1rem 0px 0px 3.2rem;

  svg {
    font-size: 1.8rem;
  }

  &:hover {
    color: ${color('primaryBlue')};
  }
`
