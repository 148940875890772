import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styles from './CreateChannel.styles'

interface CreateChannelProps {
  createChannelIntlId?: string
  className?: string
  onClick: () => void
}

const CreateChannel = ({
  createChannelIntlId,
  className,
  onClick,
}: CreateChannelProps) => {
  if (!createChannelIntlId) {
    return null
  }

  return (
    <Styles.CreateChannel className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fal', 'plus']} />
      <FormattedMessage id={createChannelIntlId} />
    </Styles.CreateChannel>
  )
}

export default CreateChannel
