import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import dayjs from 'dayjs'
import htmlDiff from 'utils/functions/htmlDiff'
import UpdateText from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import { Ins } from 'components/RichTextAreaV2/extensions/InsExtension'
import { Del } from 'components/RichTextAreaV2/extensions/DelExtension'
import * as Styles from './DescriptionDiff.styles'

/**
 * Trims `<br>` tags at the end of the docuemnt that cause unnecessary spaces
 *
 * @param parsedHtml - Document object to remove the tags from
 *
 */
const trimEndNewLineTags = (parsedHtml: Document) => {
  const pNodes = parsedHtml.querySelector('body')?.childNodes || []

  for (let i = pNodes.length - 1; i >= 0; i--) {
    const pNode = pNodes[i]
    const children = Array.from(pNode.childNodes) as HTMLElement[]

    if (children.length === 1 && children[0].tagName === 'BR') {
      pNode.remove()
    } else {
      break
    }
  }
}

/**
 * Removes tags that contain only `<ins>` or `<del>` tags that will be hidden (display: none)
 * This is to avoid having empty `<li>` elements or unnecessary spaces (`<p>` elements)
 *
 * @param html - HTML to parse and remove the tags from
 * @returns HTML string without the tags
 *
 */
const removeTagsWithOnlyHiddenElements = (
  html: string,
  tagName: 'DEL' | 'INS'
): string => {
  const parser = new DOMParser()
  const parsedHtml = parser.parseFromString(html, 'text/html')

  parsedHtml.querySelectorAll('*').forEach((node: HTMLElement) => {
    const children = Array.from(node.children)

    if (children.length === 1 && children[0].tagName === tagName) {
      children[0].remove()

      if (!node.innerText) {
        node.remove()
      }
    }
  })

  trimEndNewLineTags(parsedHtml)
  return parsedHtml.documentElement.innerHTML
}

const getCleanedBeforeAndAfterHtml = (
  beforeHtml?: string,
  afterHtml?: string
): { cleanBeforeHtml?: string; cleanAfterHtml?: string } => {
  if (!beforeHtml) {
    return {
      cleanAfterHtml: afterHtml,
    }
  }

  if (!afterHtml) {
    return {
      cleanBeforeHtml: beforeHtml,
    }
  }

  const output = htmlDiff(beforeHtml, afterHtml)
  const cleanBeforeHtml = removeTagsWithOnlyHiddenElements(output, 'INS')
  const cleanAfterHtml = removeTagsWithOnlyHiddenElements(output, 'DEL')

  return {
    cleanBeforeHtml,
    cleanAfterHtml,
  }
}

const editorExtensions = [Ins, Del]

interface DescriptionDiffProps {
  beforeHtml?: string
  afterHtml?: string
  afterDate: string
}

export const DescriptionDiff = ({
  beforeHtml,
  afterHtml,
  afterDate,
}: DescriptionDiffProps) => {
  const intl = useIntl()
  const { cleanBeforeHtml, cleanAfterHtml } = useMemo(
    () => getCleanedBeforeAndAfterHtml(beforeHtml, afterHtml),
    [beforeHtml, afterHtml]
  )

  return (
    <>
      <Styles.DiffContainer showDeletesOnly>
        <Styles.Date>
          <span>
            {intl.formatMessage({
              id: 'logs.updateDescription.lastDesctiption',
            })}
          </span>
        </Styles.Date>
        {cleanBeforeHtml ? (
          <UpdateText
            text={removeTagsWithOnlyHiddenElements(cleanBeforeHtml, 'INS')}
            extensions={editorExtensions}
          />
        ) : (
          <Styles.EmptyMessage>
            <FormattedMessage id="logs.updateDescription.noPreviousMessage" />
          </Styles.EmptyMessage>
        )}
      </Styles.DiffContainer>
      <Styles.Separator />
      <Styles.DiffContainer showInsertsOnly>
        <Styles.Date>
          <span>{`${intl.formatMessage({
            id: 'logs.updateDescription.current',
          })} - `}</span>
          {`${dayjs(afterDate).format('D MMM. YYYY - h:mm A')} `}
        </Styles.Date>
        {cleanAfterHtml ? (
          <UpdateText
            text={removeTagsWithOnlyHiddenElements(cleanAfterHtml, 'DEL')}
            extensions={editorExtensions}
          />
        ) : (
          <Styles.EmptyMessage>
            <FormattedMessage id="logs.updateDescription.messageDeleted" />
            <Styles.TrashIcon icon={['far', 'trash-alt']} />
          </Styles.EmptyMessage>
        )}
      </Styles.DiffContainer>
    </>
  )
}
