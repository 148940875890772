import { useState, useRef, useCallback, useEffect } from 'react'
import { MAX_UPLOAD_SIZE_ALLOWED } from 'utils/constants/files'
import { isIeOrEdge } from 'utils/functions/browser'
import { hasProperSize, hasProperType } from 'utils/functions/files'

interface UseFilePickerProps {
  disabled?: boolean
  maxFileSize?: number
  accept?: string
  onFilesSelected: (files: {
    acceptedFiles: File[]
    rejectedFiles: File[]
  }) => void
  onStartDraggingFile?: (event: DragEvent) => void
  onStopDraggingFile?: (event: DragEvent) => void
}

export const useFilePicker = ({
  disabled,
  maxFileSize = MAX_UPLOAD_SIZE_ALLOWED,
  accept,
  onFilesSelected,
  onStartDraggingFile,
  onStopDraggingFile,
}: UseFilePickerProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const dropFilesContainerRef = useRef<HTMLDivElement>(null)
  const [isDraggingFile, setIsDraggingFile] = useState(false)

  const openFilePicker = () => {
    if (disabled) return

    const openDialog = () => {
      if (!disabled) {
        if (fileInputRef.current) {
          fileInputRef.current.value = ''
          fileInputRef.current.click()
        }
      }
    }

    if (isIeOrEdge()) {
      setTimeout(openDialog, 0)
    } else {
      openDialog()
    }
  }

  const getDataTransferItems = ({ dataTransfer, target }) => {
    let dataTransferItemsList = []
    if (dataTransfer) {
      if (dataTransfer?.files?.length) {
        dataTransferItemsList = dataTransfer.files
      } else if (dataTransfer?.items?.length) {
        dataTransferItemsList = dataTransfer.items
      }
    } else if (target?.files) {
      dataTransferItemsList = target.files
    }

    return Array.prototype.slice.call(dataTransferItemsList)
  }

  const onSelectFiles = (event) => {
    event.preventDefault()
    setIsDraggingFile(false)
    onStopDraggingFile?.(event)
    if (!disabled) {
      const fileList: File[] = getDataTransferItems(event)
      const acceptedFiles: File[] = []
      const rejectedFiles: File[] = []

      fileList.forEach((file) => {
        if (hasProperSize(file, maxFileSize) && hasProperType(file, accept)) {
          acceptedFiles.push(file)
        } else {
          rejectedFiles.push(file)
        }
      })

      onFilesSelected({ acceptedFiles, rejectedFiles })
    }
  }

  const onDocumentDragOver = useCallback(
    (event: DragEvent) => {
      onStartDraggingFile?.(event)
      event.preventDefault()
    },
    [onStartDraggingFile]
  )

  const onDocumentDragLeave = useCallback(
    (event: DragEvent) => {
      if (!document.contains(event.target as Node)) {
        onStopDraggingFile?.(event)
      }
      event.preventDefault()
    },
    [onStopDraggingFile]
  )

  const onDocumentDrop = useCallback(
    (event: DragEvent) => {
      onStopDraggingFile?.(event)
      if (dropFilesContainerRef?.current?.contains(event.target as Node)) {
        return
      }

      event.preventDefault()
    },
    [onStopDraggingFile]
  )

  const onDragOver = (event) => {
    event.preventDefault()
    event.stopPropagation()

    return false
  }

  const onDragEnter = (event) => {
    event.preventDefault()
    setIsDraggingFile(true)
  }

  const onDragLeave = (event) => {
    event.preventDefault()
    setIsDraggingFile(false)
    onStopDraggingFile?.(event)
  }

  useEffect(() => {
    document.addEventListener<'dragover'>('dragover', onDocumentDragOver)
    document.addEventListener('dragleave', onDocumentDragLeave)
    document.addEventListener<'drop'>('drop', onDocumentDrop)
    return () => {
      document.removeEventListener<'dragover'>('dragover', onDocumentDragOver)
      document.removeEventListener('dragleave', onDocumentDragLeave)
      document.removeEventListener<'drop'>('drop', onDocumentDrop)
    }
  }, [onDocumentDragOver, onDocumentDrop, onDocumentDragLeave])

  return {
    fileInputRef,
    dropFilesContainerRef,
    openFilePicker,
    onSelectFiles,
    onDragOver,
    onDragEnter,
    onDragLeave,
    isDraggingFile,
  }
}
