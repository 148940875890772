import styled from 'styled-components'
import Modal from 'components/Modal/Modal'
import { size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const ModalBody = styled(Modal.Body)`
  padding: 2.4rem;

  @media ${size.sm} {
    width: 56rem;
  }
`

export const ConfidentialWarning = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin: auto;
  margin-bottom: 2.4rem;
  padding: 1.2rem;
  gap: 0.6rem;
  border-radius: 0.8rem;
  border: ${color('primaryBlue')} 0.1rem solid;
  color: ${color('primaryBlue')};
  font-size: 1.4rem;
  background-color: ${color('softBlue', 0.9)};
`
