import * as Papa from 'papaparse'

interface Config<T> {
  header: boolean
  delimiter: string
  newline: string
  quoteChar: string
  escapeChar: string
  transformHeader: (header: string, index: number) => any
  dynamicTyping: boolean
  preview: number
  encoding: string
  worker: boolean
  comments: boolean
  step: (results: T[], parser) => void
  complete: (result: ParseResult<T>, file: File) => void
  error: (error, file: File) => void
  download: boolean
  downloadRequestHeaders: {}
  downloadRequestBody: {}
  skipEmptyLines: boolean
  chunk: (results: T[], parser) => void
  chunkSize: any
  fastMode: '' | boolean
  beforeFirstChunk: any
  withCredentials: string
  transform: (value: string, header: string) => any
  delimitersToGuess: string[]
}

interface ParseResult<T> {
  data: T[]
  errors: any[]
  meta: {
    delimiter: string
    linebreak: string
    aborted: boolean
    truncated: boolean
    cursor: number
  }
}

export const parseCSVToJson = async <T>(
  file: File,
  config?: Partial<Config<T>>
) => {
  return new Promise<ParseResult<T>>((resolve) => {
    const defaultConfig: Partial<Config<T>> = {
      complete: (result: ParseResult<T>) => {
        resolve(result)
      },
      header: true,
      ...config,
    }

    Papa.parse(file, defaultConfig)
  })
}

export const parseArray = (array: string, delimiter: string = ';') => {
  return array.split(delimiter).map((item) => item.trim())
}

export const parseBoolean = (value: string) => {
  return ['true', 'yes'].includes(value.trim().toLowerCase())
}
