import React from 'react'
import PropTypes from 'prop-types'

import { CellContainer } from './Cell.styles'

const Cell = ({
  component,
  onClick,
  flex,
  align,
  wrapText,
  fontWeight,
  padding,
}) => {
  return (
    <CellContainer
      flex={flex}
      align={align}
      wrapText={wrapText}
      fontWeight={fontWeight}
      padding={padding}
      onClick={onClick}
    >
      {component}
    </CellContainer>
  )
}

Cell.propTypes = {
  component: PropTypes.node,
  onClick: PropTypes.func,

  flex: PropTypes.number,
  align: PropTypes.string,
  wrapText: PropTypes.bool,
  fontWeight: PropTypes.string,
  padding: PropTypes.string,
}

Cell.defaultProps = {
  component: null,
  flex: 1,
  align: '',
  wrapText: '',
  fontWeight: '',
  padding: '',
}

Cell.defaultProps = {
  onClick: () => {},
}

export default React.memo(Cell)
