import {
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'
import { SkeletonPortfoliosNavListWrapper } from './SkeletonPortfoliosNavList.styles'

const SkeletonPortfoliosNavList = () => {
  return (
    <SkeletonPortfoliosNavListWrapper>
      <SkeletonItemWrapper>
        {[1, 2, 3].map((_, index) => (
          <SkeletonItem
            width="23rem"
            minWidth="23rem"
            height="4.2rem"
            key={Number(index).toString()}
          />
        ))}
      </SkeletonItemWrapper>
      <SkeletonItemWrapper gap="2rem">
        <SkeletonItem width="0.2rem" height="4rem" />
        {[1, 2, 3].map((_, index) => (
          <SkeletonItem
            width="2rem"
            height="2rem"
            key={Number(index).toString()}
            borderRadius="0.5rem"
          />
        ))}
      </SkeletonItemWrapper>
    </SkeletonPortfoliosNavListWrapper>
  )
}

export default SkeletonPortfoliosNavList
