import type { FormikProps } from 'formik'
import { useCallback, useEffect, useRef } from 'react'
import type { Channel } from 'stream-chat'

import Toast from 'components/Toast'
import { useSetActiveChannel } from 'containers/Chat/hooks'
import {
  ChannelType,
  StreamChatType,
  TopicChannel,
  isTopicChannel,
} from 'containers/Chat/types'
import { getUserId } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { TopicEntity, isPortfolioTopic } from 'utils/types/chatTopicEntity'

import ChatService from 'api/ChatService'
import {
  findChannelWithSameMembers,
  getChannelType,
} from 'containers/Chat/helpers'
import { useChatContext } from 'stream-chat-react'
import { getUpdateItemTitle } from 'utils/functions/updates'
import { getPortfolioTypeFromTopic } from 'utils/hooks/queries/usePortfoliosAndHoldingsQuery'
import { Update } from 'utils/types/update'
import { ChannelMember } from '../ChatMembersPicker/useChatMembersPicker'

export const NEW_CHAT_CREATED_EVENT = 'NEW_CHAT_CREATED_EVENT'

export interface ChatForm {
  members: ChannelMember[]
  topic?: TopicEntity
  update?: Update
  title?: string
}

export type CreateChatProps = {
  show: boolean
  onHide: () => void
  initialValues?: ChatForm
  initialMessage?: string
  onChatCreated?: (channel: Channel<StreamChatType>) => void
  preventNavigationOnCreate?: boolean
}

export const useCreateChat = ({
  show,
  onHide,
  initialMessage,
  onChatCreated,
  preventNavigationOnCreate,
}: CreateChatProps) => {
  const formikRef = useRef<FormikProps<ChatForm>>(null)
  const currentUserId = useAppSelector(getUserId)
  const setActiveChannel = useSetActiveChannel(preventNavigationOnCreate)

  const { client } = useChatContext<StreamChatType>()
  useEffect(() => {
    if (!show) {
      formikRef.current?.resetForm()
    }
  }, [show])

  const setMembersField = useCallback((members?: ChannelMember[]) => {
    formikRef.current?.setFieldValue('members', members || [])
  }, [])

  const onMembersSelected = useCallback(
    (selectedOptions?: ChannelMember[]) => {
      setMembersField(selectedOptions)
    },
    [setMembersField]
  )

  const onMemberRemoved = useCallback(
    (index: number) => {
      setMembersField(
        formikRef.current?.values.members.filter((_, i) => i !== index)
      )
    },
    [setMembersField]
  )

  const isTitleVisible = useCallback((values: ChatForm) => {
    return !!values.topic
  }, [])

  const getChannelData = useCallback(
    (values: ChatForm): TopicChannel | undefined => {
      const { title, topic, update } = values

      if (topic) {
        const logo = isPortfolioTopic(topic.entityType)
          ? { portfolioType: getPortfolioTypeFromTopic(topic.entityType) }
          : { imageUrl: topic.image }

        return {
          name: title,
          topicName: topic.label,
          entityId: topic.id,
          entityType: topic.entityType,
          updateId: update?.id,
          updateType: update?.updateType,
          updateTitle: update ? getUpdateItemTitle(update) : undefined,
          ...logo,
        }
      }

      return undefined
    },
    []
  )

  const onCreateChannel = useCallback(
    async (values: ChatForm) => {
      try {
        const { members } = values
        const channelData = getChannelData(values)
        const channelType = getChannelType(channelData)

        const membersIds = members
          .map((member) => member.id)
          .filter((memberId) => memberId !== currentUserId)

        let channel = await findChannelWithSameMembers({
          membersIds,
          userId: currentUserId,
          client,
          entityId: isTopicChannel(channelData)
            ? channelData.entityId
            : undefined,
          updateId: channelData?.updateId,
          channelType,
          name: channelData?.name,
        })

        if (!channel) {
          let channelId: string
          const membersPayload = [currentUserId, ...membersIds]
          if (channelType === ChannelType.DIRECT_MESSAGE) {
            channelId = await ChatService.createDmChannel(membersPayload)
          } else {
            channelId = await ChatService.createChannel(
              channelData!.entityType,
              channelData!.entityId,
              membersPayload,
              channelData!.name,
              channelData!.updateId
            )
          }

          ;[channel] = await client.queryChannels({ id: channelId })
        }
        if (channel.data?.hidden) {
          await channel.show()
        }

        await channel.watch()
        if (initialMessage) {
          await channel.sendMessage({
            text: initialMessage,
            createNewChatSuggestionDismissed: true,
          })
        }

        dispatchEvent(NEW_CHAT_CREATED_EVENT)
        setActiveChannel(channel)
        onHide()
        onChatCreated?.(channel)
        setTimeout(() => {
          setMembersField([])
        }, 300)
      } catch (err) {
        Toast.displayIntl('chat.errorCreatingChat', 'error')
      }
    },
    [
      getChannelData,
      currentUserId,
      client,
      initialMessage,
      setActiveChannel,
      onHide,
      onChatCreated,
      setMembersField,
    ]
  )

  return {
    formikRef,
    onCreateChannel,
    onMembersSelected,
    onMemberRemoved,
    isTitleVisible,
  }
}
