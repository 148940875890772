import styled, { css } from 'styled-components'

export const GroupBriefingContainer = styled.div`
  border-color: #757575;
  border-top-width: 0;
  width: 100%;
`

export const GroupBriefingListWrapper = styled.div`
  overflow-y: auto;
`

export const GroupSelectorContainer = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 8.4rem;
  justify-content: flex-start;
  min-width: 25.7rem;
  transition: height 0.3s ease;
  ${(props) =>
    props.isExpanded &&
    css`
      height: 100vh;
      max-height: calc(100vh - 7.3rem);
    `}

  @media (max-width: 768px) {
    height: 10.9rem;
  }
`

export const SideBarGroupSelectorContainer = styled.div``

export const GroupTypeSwitch = styled.div`
  padding: 0.5rem 1.5rem 2rem 1.5rem;
`

export const GroupListContainer = styled.div`
  overflow-y: auto;
`
