import { useHistory, useLocation } from 'react-router-dom'
import { UpdateTypes } from 'utils/constants/updates'
import ReshareUpdateButton from 'containers/UpdatesV2/components/UpdateHeader/components/ReshareButton/ReshareUpdateButton'
import { Update } from 'utils/types/update'
import theme from 'utils/theme'
import CopyLinkedAction from './CopyLinkedAction/CopyLinkedAction'
import DuplicateUpdateAction from './DuplicateUpdateAction/DuplicateUpdateAction'
import EditUpdateAction from './EditUpdateAction/EditUpdateAction'
import MoreRecipientsAction from './MoreRecipientsAction/MoreRecipientsAction'
import { MainWrapper } from './UpdateActions.styles'
import { useBreadcrumbActionContext } from '../BreadcrumbActionPortal'
import NavigationUpdateAction from './NavigationUpdateAction/NavigationUpdateAction'

interface Props {
  update?: Update
  hasEditPermissions: boolean
  canReshare: boolean
  toggleAdditionalRecipientsModal: () => void
  duplicateEmail: () => void
}

const ShowEmailActions = ({
  update,
  hasEditPermissions,
  canReshare,
  toggleAdditionalRecipientsModal,
  duplicateEmail,
}: Props) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { showActionsBelow } = useBreadcrumbActionContext()
  const showReshareButton = !hasEditPermissions && canReshare && update

  return (
    <MainWrapper gap="0.6rem" showActionsBelowBreadcrumb={showActionsBelow}>
      <MoreRecipientsAction
        handleAddMoreRecipients={toggleAdditionalRecipientsModal}
      />

      <CopyLinkedAction
        isShowMode
        update={update}
        updateType={UpdateTypes.IUE}
        hasDraftUpdate={false}
      />

      {showReshareButton && (
        <ReshareUpdateButton
          handleOnClick={() => history.push(`${pathname}/reshare`)}
        />
      )}

      <DuplicateUpdateAction handleDuplicateUpdate={duplicateEmail} />

      <EditUpdateAction
        update={update}
        hasEditPermissions={hasEditPermissions}
        isEditMode={false}
        isEditingDraft={false}
        isSubmitting={false}
      />

      <NavigationUpdateAction
        dividerColor={
          showActionsBelow
            ? theme.colors.veryLightBlue
            : theme.colors.veryLightGray
        }
      />
    </MainWrapper>
  )
}

export default ShowEmailActions
