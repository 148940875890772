import { isActingAsInvestorGroup } from 'selectors/auth'
import { usePortfoliosAndHoldingsQuery } from 'utils/hooks/queries/usePortfoliosAndHoldingsQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { SuggestedUpdate } from 'utils/types/update'

interface Props {
  suggestedUpdate: SuggestedUpdate
}

export const useSuggestedHolding = ({ suggestedUpdate }: Props) => {
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const { data: topicEntities, isFetching } = usePortfoliosAndHoldingsQuery({
    queryHoldings: !isInvestorGroup,
    queryPortfolios: false,
    singleEntityId: suggestedUpdate.data.holdingId,
    isRequestEnabled: !!suggestedUpdate.data.holdingId,
  })

  return {
    entity: suggestedUpdate.data.holdingId
      ? topicEntities?.find(
          (entity) => entity.id === suggestedUpdate.data.holdingId
        )
      : undefined,
    isFetching: suggestedUpdate.data.holdingId ? isFetching : false,
  }
}
