import { FC } from 'react'
import Dropdown from 'ui/Dropdown'
import { color } from 'utils/functions/colors'
import { buildFormError } from 'utils/functions/forms'
import theme from 'utils/theme'
import { ErrorType } from 'utils/types/common'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import {
  DropdownContainer,
  IconContainer,
  SelectPlaceholder,
  SelectedOptionContainer,
} from './SingleFundPortfolioSelector.styles'
import { useSingleFundPortfolioSelector } from './useSingleFundPortfolioSelector'

interface Props {
  portfolioKey: string
}

export const RenderCustomOption: FC<{
  type: PortfolioTypes
  label: string
}> = ({ type, label }) => {
  return (
    <SelectedOptionContainer>
      <IconContainer>
        {getPortfolioIconByType(type, 'sm', theme.colors.primaryBlue)}
      </IconContainer>
      {label}
    </SelectedOptionContainer>
  )
}

export const SingleFundPortfolioSelector: FC<Props> = ({ portfolioKey }) => {
  const { field, messages, meta, helpers, formik, loadPortfolios, getOption } =
    useSingleFundPortfolioSelector(portfolioKey)

  return (
    <DropdownContainer>
      <Dropdown
        {...field}
        placeholder={messages.placeholder}
        type="select"
        label={messages?.label}
        onSelectOption={(_, __, option) => {
          helpers.setValue(option)
          setTimeout(() => {
            formik.handleBlur({ target: { name: field.name } })
          })
        }}
        loadOptions={loadPortfolios}
        getOption={getOption}
        value={field.value}
        borderBold
        showBorder
        async
        addNewOptionEnabled={false}
        arrowNavigationEnabled
        highlightSelectedOption={false}
        clearOnSelect
        confirmOnClickIcon
        clearOnPressEnter
        error={buildFormError(meta.error, ErrorType.ERROR, meta.touched)}
        minWidth="100%"
        padding="0.5rem 1rem"
        background={theme.colors.softGray}
        backgroundFocus={color('softGray')}
        formatSelectedOptionComponent={(option) => {
          return (
            <RenderCustomOption
              type={option.type}
              label={String(option.label)}
            />
          )
        }}
        formatSelectedOption={(option) => {
          if (option && option?.type) {
            return (
              <RenderCustomOption type={option.type} label={option.label} />
            )
          }
          return <SelectPlaceholder>{messages.placeholder}</SelectPlaceholder>
        }}
      />
    </DropdownContainer>
  )
}
