import { OnboardingStep } from 'components/OnboardingModal/useOnboardingModal'
import { useInboundsQuery } from 'containers/Inbounds/hooks'

export const useSetupYourInboundStep = (
  completedSteps: Record<OnboardingStep, boolean>
) => {
  const isStepCompleted = completedSteps[OnboardingStep.SETUP_YOUR_INBOUND]
  const areAllStepsCompleted = Object.values(completedSteps).every(Boolean)
  const { data: inbounds } = useInboundsQuery()
  const inbound = inbounds?.[0]

  return {
    inbound,
    isStepCompleted,
    areAllStepsCompleted,
  }
}
