/* eslint-disable func-names */

function ToastFactory() {}

ToastFactory.prototype.getType = function () {
  return this.type
}
ToastFactory.prototype.getIcon = function () {
  return this.icon
}

ToastFactory.createFactory = function (type = 'success') {
  const constructor = type

  if (typeof ToastFactory[constructor] !== 'function') {
    throw new Error(`The constructor ${constructor} does not exist`)
  }

  if (typeof ToastFactory[constructor].prototype?.getType !== 'function') {
    ToastFactory[constructor].prototype = new ToastFactory()
  }
  return new ToastFactory[constructor]()
}

ToastFactory.success = function () {
  this.type = 'success'
  this.icon = 'check-circle'
}

ToastFactory.error = function () {
  this.type = 'error'
  this.icon = 'exclamation-triangle'
}

ToastFactory.warning = function () {
  this.type = 'warning'
  this.icon = 'exclamation-triangle'
}

ToastFactory.info = function () {
  this.type = 'info'
  this.icon = 'info-circle'
}

ToastFactory.action = function () {
  this.type = 'action'
  this.icon = 'info-circle'
}

export default ToastFactory
