import { FormattedDate } from 'react-intl'
import OptionsDropdown from 'ui/OptionsDropdown'
import * as Styles from './DateRange.styles'

interface Props {
  startDate: string
  endDate: string
  yearDateActive: {
    isStartDayActive: boolean
    isEndDayActive: boolean
  }
  handleYearDateActive: (isStart: boolean) => void
  handleResetDateActive: () => void
  updateRangeOnYearlyDayChange: ({
    date,
    isStart,
  }: {
    date: Date
    isStart: boolean
  }) => void
  getArrayOfYearsBasedOnDate: (useStartDate: boolean) => string[]
}

const YearRange = ({
  startDate,
  endDate,
  yearDateActive,
  handleYearDateActive,
  handleResetDateActive,
  updateRangeOnYearlyDayChange,
  getArrayOfYearsBasedOnDate,
}: Props) => {
  return (
    <Styles.DropdownsWrapper>
      <OptionsDropdown
        dropdownContentMinWidth="5rem"
        margin="1.2rem 0 0 0"
        contentRight="-3rem"
        onClick={() => handleYearDateActive(true)}
        onCloseDropdown={handleResetDateActive}
        attachToDocument={false}
        isScrollable
        title={
          <Styles.DateWrapper isActive={yearDateActive.isStartDayActive}>
            <FormattedDate
              year="numeric"
              month={undefined}
              day={undefined}
              value={startDate}
            />
          </Styles.DateWrapper>
        }
      >
        {getArrayOfYearsBasedOnDate(false).map((year) => (
          <OptionsDropdown.Item
            key={year}
            label={year.toString()}
            onSelectOption={() =>
              updateRangeOnYearlyDayChange({
                date: new Date(+year, 0, 1),
                isStart: true,
              })
            }
            isSelected={year === new Date(startDate).getFullYear().toString()}
          />
        ))}
      </OptionsDropdown>

      <span>-</span>

      <OptionsDropdown
        dropdownContentMinWidth="5rem"
        margin="1.2rem 0 0 0"
        contentRight="-3rem"
        onClick={() => handleYearDateActive(false)}
        onCloseDropdown={handleResetDateActive}
        attachToDocument={false}
        isScrollable
        title={
          <Styles.DateWrapper isActive={yearDateActive.isEndDayActive}>
            <FormattedDate
              year="numeric"
              month={undefined}
              day={undefined}
              value={endDate}
            />
          </Styles.DateWrapper>
        }
      >
        {getArrayOfYearsBasedOnDate(true).map((year) => (
          <OptionsDropdown.Item
            key={year}
            label={year.toString()}
            onSelectOption={() =>
              updateRangeOnYearlyDayChange({
                date: new Date(+year, 11, 31),
                isStart: false,
              })
            }
            isSelected={year === new Date(endDate).getFullYear().toString()!!}
          />
        ))}
      </OptionsDropdown>
    </Styles.DropdownsWrapper>
  )
}

export default YearRange
