import { Optional } from 'utils/types/common'
import {
  CreateDraftUpdatePayload,
  EditDraftUpdateItemPayload,
  EditUpdateItemPayload,
} from 'utils/types/updatePayloads'
import { EditDraftUpdatePayloadParams, EditUpdatePayloadParams } from './types'
import { getEditDraftPayload, getEditUpdateItemPayload } from './updates'

export const getCreateDocumentPayload =
  (): CreateDraftUpdatePayload<'document'> => {
    return {
      document: {
        loggingUpdateAttributes: {
          draftHash: {},
        },
      },
    }
  }

export const getEditDocumentPayload = (
  params: Optional<EditUpdatePayloadParams, 'body'>
): EditUpdateItemPayload => {
  return getEditUpdateItemPayload(params)
}

export const getEditDraftDocumentPayload = (
  params: EditDraftUpdatePayloadParams,
  suggestedUpdateId?: string
): EditDraftUpdateItemPayload => {
  return getEditDraftPayload(params, suggestedUpdateId)
}
