import styled from 'styled-components/macro'
import Modal from 'components/Modal'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const StyledModal = styled(Modal)`
  width: 98.5rem;
  min-height: 72.4rem;
`

export const Header = styled(Modal.Header)`
  background-color: #174086;
  border-bottom: 1px solid ${color('primaryDarkBlue')};
  height: 6.1rem;

  > div {
    background-color: #143b80;
    color: white;
  }
`

export const Title = styled.span`
  color: white;
  font-size: 1.4rem;
  font-weight: 700;
`

export const SecondHeader = styled.div`
  min-height: 12.2rem;
  background-color: ${color('primaryBlue')};
  padding: 2.4rem 3.2rem 3.2rem;
  position: relative;
  overflow: hidden;

  @media ${maxSize.md} {
    padding: 2.4rem 1.6rem 2.4rem;
  }
`

export const Body = styled(Modal.Body)`
  padding: 3.2rem 3.2rem 2.4rem 3.2rem;
  flex: 1;

  @media ${maxSize.md} {
    padding: 2.4rem 1.6rem;
  }
`

export const Footer = styled(Modal.Footer)`
  @media ${maxSize.md} {
    padding: 2.4rem 1.6rem;
    gap: 2rem;
  }
`

export const FooterButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.8rem;
  width: 100%;
`
