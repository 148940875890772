import { motion } from 'framer-motion'
import styled from 'styled-components'

export const AttachmentsGrid = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;

  @media (max-width: 1439px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`
