import styled from 'styled-components/macro'
import { css } from 'styled-components'

import { color } from 'utils/functions/colors'

export const UniversityWrapper = styled.div`
  position: relative;
`

export const UniversityContainer = styled.aside`
  height: 100vh;
  width: 38.7rem;
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  box-shadow: 0px 0px 15px rgba(16, 21, 39, 0.1);
  transition: transform 0.2s;
  overflow-y: auto;

  ${(props) =>
    !props.isOpen &&
    css`
      transform: translateX(-100%);
    `}
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
    padding: 1.58rem 1.66rem;

    &:focus {
      outline: none;
    }

    svg {
      font-size: 1.8rem;
    }
  }
`

export const Title = styled.div`
  padding: 2.5rem 5.8rem 1.6rem 3.2rem;

  h1 {
    font-weight: normal;
    font-size: 2.4rem;
    line-height: 29px;
    margin: 0 0 0.8rem 0;
  }

  p {
    color: ${color('lightGray')};
    font-size: 1.4rem;
    line-height: 17px;
  }
`

export const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  height: 3.2rem;
  margin: 1.6rem 0;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  padding: 0 3.2rem;
`

export const LoaderContainer = styled.div`
  height: 50vh;
`
