import { SetStateAction, useCallback } from 'react'
import type { Channel } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import { useQueryClient } from '@tanstack/react-query'

import { useMoveChannelUpOnNewMessage } from 'containers/Chat/hooks/useMoveChannelUpOnNewMessage'
import { useEventListener, dispatchEvent } from 'utils/hooks/useEventListener'
import {
  useRemoveChannelMemberListener,
  useSetActiveChannel,
} from 'containers/Chat/hooks'
import {
  ArchivedChannel,
  StreamChatType,
  TopicChannel,
} from 'containers/Chat/types'
import { UNARCHIVE_CHANNEL_EVENT } from 'containers/Chat/hooks/useArchiveChannels'
import {
  archivedChanelsKey,
  useArchivedChannelsQuery,
} from './useArchivedChannelsQuery'

export const EMPTY_ARCHIVE_LIST_EVENT = 'EMPTY_ARCHIVE_LIST_EVENT'
export const SHOW_ARCHIVED_SIDEBAR_EVENT = 'SHOW_ARCHIVED_SIDEBAR_EVENT'

export const filterArchivedChannels = (
  channels: Channel<StreamChatType<TopicChannel>>[],
  archivedChannels: ArchivedChannel[]
) =>
  channels.filter((channel) => {
    const archivedChannel = archivedChannels.find(
      (archivedCh) => archivedCh.id === channel.id
    )
    return !archivedChannel
  })

export const useArchivedChannelsSidebar = (
  preventSelectArchivedChannelOnQuery = false
) => {
  const { client } = useChatContext<StreamChatType<TopicChannel>>()
  const queryClient = useQueryClient()
  const { data: channels = [], isLoading } = useArchivedChannelsQuery(
    preventSelectArchivedChannelOnQuery
  )
  const setActiveChannel = useSetActiveChannel<TopicChannel>()

  const updateChannelsCacheCallback = useCallback(
    (callback: SetStateAction<Channel<StreamChatType<TopicChannel>>[]>) => {
      const newChannels =
        typeof callback === 'function' ? callback(channels) : callback
      queryClient.setQueryData(archivedChanelsKey, newChannels)
    },
    [channels, queryClient]
  )

  useMoveChannelUpOnNewMessage(updateChannelsCacheCallback)

  const setFirstAvailableChannelAsActive = useCallback(
    (newChannels: Channel<StreamChatType<TopicChannel>>[]) => {
      if (newChannels.length > 0) {
        setActiveChannel(newChannels[0])
      } else {
        const activeChannels = filterArchivedChannels(
          Object.values(client.activeChannels),
          client.user?.archivedChannels || []
        )
        setActiveChannel(activeChannels[0])
        dispatchEvent(EMPTY_ARCHIVE_LIST_EVENT)
      }
    },
    [client.activeChannels, client.user?.archivedChannels, setActiveChannel]
  )

  useEventListener(UNARCHIVE_CHANNEL_EVENT, ({ channelId }) => {
    const newChannels = (channels || []).filter(
      (currentChannel) => currentChannel.id !== channelId
    )

    setFirstAvailableChannelAsActive(newChannels)
    queryClient.setQueryData(archivedChanelsKey, newChannels)
  })

  const onChannelRemoved = useCallback(
    async (newChannels: Channel<StreamChatType<TopicChannel>>[]) => {
      setFirstAvailableChannelAsActive(newChannels)
    },
    [setFirstAvailableChannelAsActive]
  )

  useRemoveChannelMemberListener(updateChannelsCacheCallback, onChannelRemoved)

  return {
    isLoading,
    channels,
  }
}
