import { PortfolioSettings, PortfolioState } from 'reducers/portfolios'
import { Nullable } from 'utils/types/common'

export const getCurrentPortfolio = ({ portfolios }) =>
  portfolios.currentPortfolio

export const getPortfolios = ({ portfolios }) => portfolios.portfolios

export const getPortfolioInvestorSettings =
  (portfolioId: string) =>
  (state: { portfolios: PortfolioState }): Nullable<PortfolioSettings> => {
    const { portfolios } = state
    const { settings: portfolioInvestorSettings } = portfolios

    return portfolioInvestorSettings?.[portfolioId] || null
  }
