import { Wrapper } from 'components/TransactionsBulkImportModal/BulkImportModal.styles'
import { useBulkImportFileUpload } from 'utils/hooks/useBulkImportFileUpload'

interface Props {
  onUploadFile: (file: File) => void
  children: React.ReactNode
}

const FileDragger = ({ onUploadFile, children }: Props) => {
  const { isDraggingOver, onDrop, onDragOver, onDragLeave, onDragEnter } =
    useBulkImportFileUpload(onUploadFile)

  return (
    <Wrapper
      onDrop={onDrop}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      isDraggingOver={isDraggingOver}
    >
      {children}
    </Wrapper>
  )
}

export default FileDragger
