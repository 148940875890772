import { FormattedMessage } from 'react-intl'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { MailboxType } from 'components/EmailList/useEmailList'
import {
  Icon,
  CheckboxLegend,
  InboxNavigationContainer,
  OptionButton,
} from './InboxNavigation.styles'

type Props = {
  currentMailboxType: MailboxType
  handleMailboxTypeChange: (type: MailboxType) => void
  totalEmails: number
  totalDrafts: number
  totalSchedules: number
}

const InboxNavigation = ({
  currentMailboxType,
  totalEmails,
  totalDrafts,
  totalSchedules,
  handleMailboxTypeChange,
}: Props) => {
  const getIconType = (mailboxType: MailboxType): IconProp => {
    if (mailboxType === MailboxType.SENT) {
      return ['far', 'inbox-out']
    }

    if (mailboxType === MailboxType.DRAFT) {
      return ['far', 'file']
    }

    return ['far', 'calendar-day']
  }

  const displayMailboxTypeOptionLegend = (mailboxType: MailboxType) => (
    <div>
      <Icon
        icon={getIconType(mailboxType)}
        active={mailboxType === currentMailboxType}
      />
      <CheckboxLegend>
        <FormattedMessage id={`emailList.${mailboxType}`} />
      </CheckboxLegend>
    </div>
  )

  const displayMailboxTypeOption = (emailType: MailboxType, total: number) => (
    <OptionButton
      type="button"
      primary={emailType === currentMailboxType}
      secondary={emailType !== currentMailboxType}
      onClick={() => handleMailboxTypeChange(emailType)}
      selected={emailType === currentMailboxType}
    >
      {displayMailboxTypeOptionLegend(emailType)}
      {total > 0 && <span>{total}</span>}
    </OptionButton>
  )

  return (
    <InboxNavigationContainer>
      <nav>
        {displayMailboxTypeOption(MailboxType.SENT, totalEmails)}
        {displayMailboxTypeOption(MailboxType.DRAFT, totalDrafts)}
        {displayMailboxTypeOption(MailboxType.SCHEDULE, totalSchedules)}
      </nav>
    </InboxNavigationContainer>
  )
}

export default InboxNavigation
