import { Formik } from 'formik'
import { useIntl } from 'react-intl'
import { UpdateTypes } from 'utils/constants/updates'
import { getUpdateItemTitle } from 'utils/functions/updates'

import { AccountingFormValues } from 'utils/types/updateForm'
import { AccountingForm, UpdateForm } from '../../components'
import EditWrapper from '../../components/commons/EditWrapper/EditWrapper'
import useEditAccounting from './useEditAccounting'

const EditAccounting = () => {
  const {
    accountingValues,
    isLoading,
    validationSchema,
    updateId,
    update,
    editMutation,
    deleteMutation,
  } = useEditAccounting()
  const intl = useIntl()

  return (
    <EditWrapper
      title={intl.formatMessage(
        { id: 'metaTitles.editUpdateTitle' },
        {
          title: getUpdateItemTitle(update),
          entityName: intl.formatMessage({
            id: 'metaTitles.update.accounting',
          }),
        }
      )}
      isLoading={isLoading}
      update={update}
      updateType={UpdateTypes.ACCOUNTING}
    >
      <Formik<AccountingFormValues>
        initialValues={accountingValues as AccountingFormValues}
        validationSchema={validationSchema}
        onSubmit={(values) => editMutation({ values, update: update! })}
        enableReinitialize
        validateOnMount
      >
        <UpdateForm
          handleDelete={deleteMutation}
          isEditMode
          updateType={UpdateTypes.ACCOUNTING}
          updateId={updateId}
          update={update}
        >
          <AccountingForm />
        </UpdateForm>
      </Formik>
    </EditWrapper>
  )
}

export default EditAccounting
