import React from 'react'
import PropTypes from 'prop-types'

import styles from './CompanyLogo.module.scss'

const CompanyLogo = ({ srcImage }) => (
  <div
    className={styles.logo}
    style={{
      backgroundImage: `url(${srcImage})`,
    }}
  />
)

CompanyLogo.propTypes = {
  srcImage: PropTypes.string.isRequired,
}

CompanyLogo.displayName = 'CompanyLogo'

export default React.memo(CompanyLogo)
