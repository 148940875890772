import styled from 'styled-components/macro'
import DiscardFormToStyle, { DISCARD_REASON_CONTAINER_ID } from '../DiscardForm'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const DiscardForm = styled(DiscardFormToStyle)`
  gap: 2rem;

  #${DISCARD_REASON_CONTAINER_ID} {
    max-width: 32.7rem;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  gap: 0.4rem;
`
