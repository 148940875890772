import React from 'react'
import ContentLoader from 'react-content-loader'

const EditUpdateContentLoader = () => (
  <ContentLoader
    speed={1}
    height={422}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
  >
    <rect x="0" y="10" rx="4" ry="4" width="100%" height="24" />
    <rect x="0" y="60" rx="4" ry="4" width="100%" height="150" />
    <rect x="0" y="230" rx="4" ry="4" width="100%" height="65" />
    <rect x="0" y="320" rx="4" ry="4" width="125" height="125" />
    <rect x="135" y="320" rx="4" ry="4" width="125" height="125" />
    <rect x="270" y="320" rx="4" ry="4" width="125" height="125" />
  </ContentLoader>
)

export default EditUpdateContentLoader
