import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode, useCallback } from 'react'
import { Container } from './ContextMenuItem.styles'

interface ContextMenuItemProps {
  onClick: (event) => void
  icon: ReactNode | IconProp
  label: string | ReactNode
  hide?: () => void
  iconColor?: string
}

const ContextMenuItem = ({
  onClick,
  hide,
  icon,
  label,
  iconColor,
}: ContextMenuItemProps) => {
  const renderIcon = () => {
    if (Array.isArray(icon)) {
      return <FontAwesomeIcon icon={icon} color={iconColor} />
    }

    return icon
  }

  const handleOnClick = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      onClick(event)
      hide?.()
    },
    [onClick, hide]
  )

  return (
    <Container onClick={handleOnClick}>
      <>
        {renderIcon()}
        {label}
      </>
    </Container>
  )
}

export default ContextMenuItem
