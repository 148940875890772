import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import uniqBy from 'lodash/uniqBy'
import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getUserId } from 'selectors/auth'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { LoggingUpdateReshare } from 'utils/types/update'

const ResharedText = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('lightGray')};
  text-transform: none;
  display: block;
`
const ReshareIcon = styled(FontAwesomeIcon).attrs({ icon: ['far', 'retweet'] })`
  color: ${color('lightGray')};
  margin-right: 0.4rem;
`
interface ResharedGroupsProsp {
  reshares: LoggingUpdateReshare[]
  showResharedWith?: boolean
}

const ResharedGroups: React.FC<ResharedGroupsProsp> = ({
  reshares,
  showResharedWith,
}) => {
  const userId = useSelector(getUserId)

  const resharesToShow: LoggingUpdateReshare[] = uniqBy(
    [...reshares],
    (reshare) => {
      if (showResharedWith) {
        return reshare.sharedWithId
      }
      return reshare.groupId
    }
  ).filter((reshare: LoggingUpdateReshare) => reshare.userId !== userId)

  const intl = useIntl()

  const getSharedEntityName = (reshare: LoggingUpdateReshare) => {
    if (showResharedWith) {
      return reshare.sharedWithName
    }

    return reshare.groupName
  }

  if (!resharesToShow.length) {
    return null
  }

  if (resharesToShow.length === 1) {
    return (
      <ResharedText>
        <ReshareIcon />
        {intl
          .formatMessage({
            id: showResharedWith
              ? 'updates.resharedWith'
              : 'updates.resharedBy',
          })
          .concat(' ')
          .concat(getSharedEntityName(resharesToShow[0]))}
      </ResharedText>
    )
  }

  const lastReshare = resharesToShow.pop()
  return (
    <ResharedText>
      <ReshareIcon />
      {intl
        .formatMessage({
          id: showResharedWith ? 'updates.resharedWith' : 'updates.resharedBy',
        })
        .concat(' ')
        .concat(resharesToShow.map(getSharedEntityName).join(', '))
        .concat(' ')
        .concat(intl.formatMessage({ id: 'updates.and' }))
        .concat(' ')
        .concat(lastReshare!.groupName)}
    </ResharedText>
  )
}

ResharedGroups.defaultProps = {
  showResharedWith: false,
}

export default ResharedGroups
