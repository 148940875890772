import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react'
import { measureText } from 'utils/functions/strings'
import { useWindowResize } from 'utils/hooks/windowResize'

interface Config {
  rowsCount: number
  baseWidth: number
  paddingGap: number
  showMoreWidth: number
}

export const useDynamicWidth = (
  items: { name: string }[],
  showAllItems: boolean,
  config: Config
) => {
  const [, startTransition] = useTransition()
  const [width, setWidth] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    startTransition(() => {
      setWidth(containerRef.current?.clientWidth || 0)
    })
  }, [])

  useWindowResize(() => {
    startTransition(() => {
      setWidth(containerRef.current?.clientWidth || 0)
    })
  })

  useEffect(() => {
    startTransition(() => {
      setWidth(containerRef.current?.clientWidth || 0)
    })
  }, [containerRef.current?.clientWidth])

  const getItemWidth = useCallback(
    (name: string) => {
      const itemWidth =
        measureText(name).width + config.baseWidth + config.paddingGap

      return itemWidth > width ? width : itemWidth
    },
    [width, config]
  )

  const maxItemsCount = useMemo(() => {
    if (!showAllItems) {
      let currentRow = 1
      let availableWidth = width

      const initializeNextRow = (isLastElement: boolean) => {
        currentRow += 1
        availableWidth = width

        if (currentRow === config.rowsCount && !isLastElement) {
          availableWidth -= config.showMoreWidth
        }
      }

      const addItemToCurrentRow = (
        itemWidth: number,
        result: number,
        isLastElement: boolean
      ) => {
        const newAvailableWidth = availableWidth - itemWidth
        let newResult = result

        if (newAvailableWidth > 0) {
          availableWidth = newAvailableWidth
          newResult += 1
        } else if (newAvailableWidth === 0) {
          newResult += 1
          initializeNextRow(isLastElement)
        }
        return [newAvailableWidth, newResult]
      }

      const itemCount = items.reduce((currentResult, item, index) => {
        const isLastElement = index === items.length - 1

        if (currentRow <= config.rowsCount) {
          const itemWidth = getItemWidth(item.name)
          const [newAvailableWidth, newResult] = addItemToCurrentRow(
            itemWidth,
            currentResult,
            isLastElement
          )

          if (newAvailableWidth < 0) {
            initializeNextRow(isLastElement)

            if (currentRow <= config.rowsCount) {
              const [, lastResult] = addItemToCurrentRow(
                itemWidth,
                currentResult,
                isLastElement
              )

              return lastResult
            }
          }

          return newResult
        }

        return currentResult
      }, 0)

      return itemCount
    }

    return items.length
  }, [
    showAllItems,
    items,
    width,
    config.rowsCount,
    config.showMoreWidth,
    getItemWidth,
  ])

  return {
    containerRef,
    maxItemsCount,
  }
}
