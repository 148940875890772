import styled, { css } from 'styled-components'

import { FileLockIcon } from './FileLockIcon'
import SparkleIcon from './SparkleIcon'

const CommonIconStyles = css`
  margin: auto;
  fill: var(--fill-icon-color, #fff);
`

export const FileLock = styled(FileLockIcon)`
  ${CommonIconStyles}
`

export const Sparkle = styled(SparkleIcon)`
  ${CommonIconStyles}
`
