import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { UpdateTextContainer } from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'

export const TransactionContent = styled.div`
  display: grid;
  column-gap: 2.5rem;
  grid-template-columns: 1fr 1fr;
`

export const Row = styled.div<{ gridColumn?: string }>`
  ${(props) =>
    props.gridColumn &&
    css`
      grid-column: ${props.gridColumn};
    `}
`

interface Props {
  capitalize?: boolean
  isBold?: boolean
  cursor?: boolean
  width?: string
  display?: string
  gap?: string
}

export const Content = styled.div.attrs({
  className: 'fs-exclude',
})<Props>`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  margin-bottom: 1.8rem;
  line-height: 1.68rem;
  padding-left: 1.4rem;
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'normal')};
  font-weight: ${(props) => (props.isBold ? 'bold' : 'normal')};
  cursor: ${(props) => (props.cursor ? 'pointer' : 'auto')};
  width: ${(props) => props.width || 'auto'};
  display: ${(props) => props.display || 'block'};
  flex-wrap: wrap;
  gap: ${(props) => props.gap || '0'};

  ${UpdateTextContainer} {
    margin-top: 0;
  }
`

export const EmptyDiv = styled.div``
