/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import UpdateService from 'api/UpdateService'
import { getTagsFromTaggings } from 'utils/functions/tags'
import Toast from 'components/Toast'

const useTaggingsByUpdateId = (updateId) => {
  const [tagsList, setTagsList] = useState()
  const intl = useIntl()
  const [loggingUpdateGroups, setLoggingUpdateGroups] = useState()

  useEffect(() => {
    ;(async () => {
      try {
        if (updateId) {
          const {
            data: {
              entities: {
                taggings,
                loggingUpdateGroups: loggingUpdateGroupsResponse,
              },
            },
          } = await UpdateService.getUpdate(updateId)

          setTagsList(getTagsFromTaggings(Object.values(taggings)))
          setLoggingUpdateGroups(loggingUpdateGroupsResponse)
        }
      } catch (error) {
        Toast.display(intl.messages['updates.undefinedUpdate'], 'error')
      }
    })()
  }, [intl.messages, updateId])

  return { tagsList, setTagsList, loggingUpdateGroups }
}

export default useTaggingsByUpdateId
