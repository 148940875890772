import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import Tooltip from 'components/Tooltip'
import { InboundStatus } from 'utils/types/inbounds'
import { color } from 'utils/functions/colors'
import theme, { Color } from 'utils/theme'
import { assertUnreachable } from 'utils/functions/utils'

import { Container, Label, PulseIndicator } from './StatusIndicator.styles'

interface Props {
  status: InboundStatus
  className?: string
}

const StatusIndicator = ({ status, className }: Props) => {
  const intl = useIntl()

  const getColor = (colorName: Color) => color(colorName)({ theme })

  const statusMapper = useMemo(() => {
    switch (status) {
      case InboundStatus.ACTIVE:
        return {
          color: getColor('darkGreen'),
          label: intl.formatMessage({ id: 'inbounds.status.active' }),
        }
      case InboundStatus.NOT_CONNECTED:
        return {
          color: getColor('red'),
          label: intl.formatMessage({ id: 'inbounds.status.notConnected' }),
        }
      case InboundStatus.AWAITING_CONNECTION:
        return {
          color: getColor('purple'),
          label: intl.formatMessage({ id: 'inbounds.status.pending' }),
          tooltip: intl.formatMessage({ id: 'inbounds.status.pendingTooltip' }),
        }
      case InboundStatus.TESTING:
        return {
          color: getColor('lightGray'),
          label: intl.formatMessage({
            id: 'inbounds.status.testingConnection',
          }),
        }
      default:
        return assertUnreachable(status)
    }
  }, [intl, status])

  return (
    <Tooltip text={statusMapper.tooltip} place="bottom" maxWidth="23rem">
      <Container className={className}>
        <PulseIndicator
          color={statusMapper.color}
          disableAnimation={status === InboundStatus.NOT_CONNECTED}
          animationName={status}
        />
        <Label color={statusMapper.color}>{statusMapper.label}</Label>
      </Container>
    </Tooltip>
  )
}

export default StatusIndicator
