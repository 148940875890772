export const TransactionInstruments = {
  CONVERTIBLE_NOTE: 'convertible_note',
  DEBT_CREDIT: 'debt_credit',
  EQUITY: 'equity',
  FUND_INVESTMENT: 'fund_investment',
  PREFERRED_EQUITY: 'preferred_equity',
  SAFE: 'safe',
  WARRANTS: 'warrants',
  OTHER: 'other',
}
