import styled from 'styled-components'

export const CheckboxLegend = styled.p`
  color: #787e8c;
  font-size: 1.2rem;
  line-height: 1.4rem;
  margin-left: 2.6rem;

  a {
    text-decoration: underline;
    font-weight: 700;
    margin-left: 0.5rem;
  }
`
