import Header from './components/Header'
import InboundsList from './components/InboundsList'
import { Container } from './Inbounds.styles'

const Inbounds = () => {
  return (
    <Container>
      <Header />
      <InboundsList />
    </Container>
  )
}

export default Inbounds
