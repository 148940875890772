const FOUNDER_TEAM_TYPE = 'FounderTeam'
const CLIENT_GROUP_TYPE = 'Client'
const FUND_MANAGER_GROUP_TYPE = 'FundManager'
const INVESTOR_GROUP_TYPE = 'Investors::InvestorGroup'

export const GroupTypes = {
  FOUNDER: FOUNDER_TEAM_TYPE,
  CLIENT: CLIENT_GROUP_TYPE,
  FUND_MANAGER: FUND_MANAGER_GROUP_TYPE,
  INVESTOR_GROUP: INVESTOR_GROUP_TYPE,
} as const

export enum GroupNames {
  FOUNDER = 'Founder',
  CLIENT = 'Client',
  FUND_MANAGER = 'GP',
  INVESTOR_GROUP = 'LP',
}

export const Roles = {
  MANAGER: 'manager',
  MEMBER: 'member',
  OWNER: 'owner',
} as const

export enum EntityType {
  USER = 'User',
  GROUP = 'Group',
  COMPANY = 'Company',
  INVESTOR_GROUP = 'Investors::InvestorGroup',
}

export const BYTES_IN_GB = 1_073_741_824
export const BYTES_IN_MB = 1_048_576
