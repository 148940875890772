import { UpdateEntityType } from 'components/UpdatesFeedV2'
import { InvestmentsFiltersObject } from 'containers/Investments/InvestmentsFilters/types'
import { FiltersDataSet } from 'utils/hooks/useUpdatesProfilesFilter'

export const QUERIES = {
  MIXED_UPDATES: 'mixed_updates',
} as const

export const mixedUpdatesKeys = {
  all: (
    entityType: UpdateEntityType,
    entityId: string | string[],
    filters?: FiltersDataSet,
    investmentsFilterObject?: InvestmentsFiltersObject
  ) => {
    const key: any[] = [QUERIES.MIXED_UPDATES, entityType, entityId]
    if (filters) key.push(filters)
    if (investmentsFilterObject) key.push(investmentsFilterObject)
    return key
  },
}
