import { useState } from 'react'
import { useChannelStateContext } from 'stream-chat-react'
import {} from 'date-fns'

import Toast from 'components/Toast'

export enum MuteOptions {
  MUTE_8_HOURS = 'MUTE_8_HOURS',
  MUTE_1_WEEK = 'MUTE_1_WEEK',
  ALWAYS = 'ALWAYS',
}

export const useMuteChatModal = (onMute: () => void) => {
  const [muteOption, setMuteOption] = useState<MuteOptions>(MuteOptions.ALWAYS)
  const { channel: activeChannel } = useChannelStateContext()

  const getExpiration = () => {
    switch (muteOption) {
      case MuteOptions.MUTE_8_HOURS:
        return 8 * 60 * 60 * 1000
      case MuteOptions.MUTE_1_WEEK:
        return 7 * 24 * 60 * 60 * 1000
      default:
        return undefined
    }
  }

  const muteChannel = async () => {
    try {
      if (activeChannel) {
        const expiration = getExpiration()

        await activeChannel.mute({ expiration })
        onMute()
      }
    } catch {
      Toast.displayIntl('chat.muteChannelError', 'error')
    }
  }

  return { muteOption, setMuteOption, muteChannel }
}
