import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const VisibilityContainer = styled.div`
  margin-top: -1rem;
  div[class^='BoxGroupsAndUsersChips_chipsWrapper__'] {
    border: none;
    padding: 0;
    min-height: 0;
  }
`

export const TextContainer = styled.div`
  svg {
    color: ${color('lightGray')};
    margin-left: 0.8rem;
  }
`
