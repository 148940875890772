import { FileContent } from 'utils/types/files'

import Loading from '../Loading'
import PDFControls from '../PDFControls'
import PreviewError from '../PreviewError'
import * as Styles from './PDFPreview.styles'

interface PDFPreviewProps {
  file: FileContent
  displayControls?: boolean
  errorComponent?: React.ReactElement
}

const PDFPreview = ({
  file,
  errorComponent,
  displayControls = true,
}: PDFPreviewProps) => {
  return (
    <Styles.Container>
      <Styles.PDFPreviewer
        file={file.url}
        autoScale
        loadingComponent={<Loading />}
        controlsComponent={<PDFControls />}
        errorComponent={errorComponent || <PreviewError />}
        displayControls={displayControls}
      />
    </Styles.Container>
  )
}

export default PDFPreview
