/* eslint-disable react/no-array-index-key */
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`

export const Dot = styled.button<{ active?: boolean }>`
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 100%;
  transition: background-color 0.2s ease-in-out;
  background-color: ${(props) =>
    props.active ? color('primaryBlue')(props) : color('veryLightBlue')(props)};
  &:focus {
    outline: none;
  }
`
type Props = {
  currentPage: number
  countPages: number
  onClickPage: (index: number) => void
}

const PageDotIndicator = ({ currentPage, countPages, onClickPage }: Props) => {
  return (
    <DotsContainer>
      {[...new Array(countPages)].map((_, index) => (
        <Dot
          key={index}
          onClick={() => onClickPage(index)}
          active={currentPage === index}
        />
      ))}
    </DotsContainer>
  )
}

export default PageDotIndicator
