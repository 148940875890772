import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  INVESTMENT_VEHICLE_PROFILE,
  INVESTMENT_VEHICLE_PROFILE_EDIT,
} from 'routes/constant/investorManagementRoutes'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import InvestmentVehicleLink from 'components/Breadcrumb/Components/InvestmentVehicleLink'
import { Update } from 'utils/types/update'
import { URL } from 'reducers/breadcrumbSlice'

interface Props {
  update?: Update
  url: URL
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
}

const InvestmentVehiclesBreadcrumb = ({
  update,
  url,
  initialLinkRedirect,
  isCreatingNewUpdate,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <NavigationWrapper>
      <>
        <InitialLink
          isActive={false}
          label={intl.formatMessage({ id: 'breadcrumb.investorManagement' })}
          onClickHandler={() => history.push(initialLinkRedirect)}
        />

        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      </>

      <InvestmentVehicleLink
        url={url}
        showRightArrow={!!update || isCreatingNewUpdate}
        activeTabs={[
          INVESTMENT_VEHICLE_PROFILE,
          INVESTMENT_VEHICLE_PROFILE_EDIT,
        ]}
      />

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default InvestmentVehiclesBreadcrumb
