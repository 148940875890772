import styled from 'styled-components'
import { maxSize, size } from 'utils/constants/breakpoint'

export const Container = styled.div`
  @media ${maxSize.lg} and (min-width: 769px) {
    position: relative;
    width: calc(100% + 1.2rem);
    left: -0.6rem;

    @media ${size.lg} {
      width: calc(100% + 1.6rem);
      left: -0.8rem;
    }
  }

  @media ${maxSize.mobile} {
    padding-top: 1.2rem;
  }
`
