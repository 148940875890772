import {
  SkeletonAvatarWithAnimation,
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'
import { SkeletonUpdateCardWrapper } from './SkeletonUpdateCard.styles'

const SkeletonUpdateCard = () => {
  return (
    <>
      <SkeletonAvatarWithAnimation marginLeft />
      <SkeletonUpdateCardWrapper>
        <SkeletonItemWrapper
          direction="column"
          gap="1rem"
          alignItems="flex-start"
        >
          <SkeletonItem width="12rem" height="1.8rem" />
          <SkeletonItem width="100%" height="1.4rem" />
          <SkeletonItem width="100%" height="1.4rem" />
        </SkeletonItemWrapper>
      </SkeletonUpdateCardWrapper>
    </>
  )
}

export default SkeletonUpdateCard
