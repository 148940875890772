import { getUpdateIcon } from 'components/UpdateIconFactoryV2'
import { useClickableIcon } from 'components/UpdatesFeedV2/components/FeedIcon/useClickableIcon'
import { useSelectUpdatesContext } from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import { SuggestedUpdateTypes } from 'utils/constants/updates'
import { SuggestedUpdate } from 'utils/types/update'

import { IconWrapper, UpdateIcon } from './SuggestedUpdateIcon.styles'

interface Props {
  suggestedUpdate: SuggestedUpdate
}

const SuggestedUpdateIcon = ({ suggestedUpdate }: Props) => {
  const selectCtx = useSelectUpdatesContext()
  const isClickable = selectCtx.enabledForSuggestedUpdates
  const { isSelected, onMouseOver, onMouseLeave, onClickIcon } =
    useClickableIcon(suggestedUpdate, isClickable)
  const { currentHoverCardId, isSelectingSuggestedUpdates } =
    useSelectUpdatesContext()
  const isHover = currentHoverCardId === suggestedUpdate.id

  const icon = getUpdateIcon(SuggestedUpdateTypes.SUGGESTED)

  return (
    <IconWrapper>
      <UpdateIcon
        isSelected={isSelected}
        isHover={isHover}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClickIcon}
        updateType={SuggestedUpdateTypes.SUGGESTED}
        color={icon.color}
        clickable
        showIconOnlyOnHover={isSelectingSuggestedUpdates}
      />
    </IconWrapper>
  )
}

export default SuggestedUpdateIcon
