import {
  PaymentMethodType,
  SubscriptionChangeType,
  SubscriptionPlan,
} from 'utils/types/subscriptions'

export default {
  title: 'Subscription',
  plan: {
    lite: 'Lite Plan',
    basic: 'Individual Plan',
    pro: 'Pro Plan',
    organization: 'Organization Plan',
  },
  readOnly: 'Read-only',
  nextBillingDate: 'Your next billing date will be {date}',
  validUntilDate: 'Your current subscription is valid until {date}.',
  expired: 'Your subscription has expired on {date}.',
  postExpirationAccess:
    'Post-expiration, access will be limited to a read-only mode.',
  readOnlyAccess: 'Access is limited to a read-only mode.',
  price: '{amount} per month {cardBrand} ****{last4}',
  perMonth: 'per month',
  perYear: 'per year',
  expires: 'Expires',
  paymentMethod: 'Payment method',
  canceled: 'canceled',
  daysLeftOnTrial: '{countDays} days left on your free trial',
  removePaymentOption:
    'To remove your payment option, cancel your subscription.',
  billingInfo: 'Billing info',
  billingInfoDescription:
    'A billing account is used to store your payment information for your Clockwork Universe group subscription.',
  billingAccountName: 'Billing Account Name',
  email: 'Email',
  country: 'Country',
  phone: 'Phone',
  manageGroupMembers: 'Manage group members {countMembers}',
  updatePaymentMethod: 'Update payment method',
  cancelSubscription: 'Cancel Subscription',
  bankAccountEnding: 'Account ending in {last4}',
  amountPerPeriod: {
    monthly: '{amount} per month',
    annually: '{amount} per year',
  },
  cardPaymentMethodPlan: {
    visa: 'Visa **** {last4}',
    mastercard: 'Master Card **** {last4}',
    amex: 'American Express **** {last4}',
    jcb: 'JCB **** {last4}',
    unionpay: 'Union Pay **** {last4}',
    discover: 'Discover **** {last4}',
    diners: 'Diners Club **** {last4}',
  },
  subscriptionWith: {
    visa: 'Subscription with Visa ****{last4}',
    mastercard: 'Subscription with Master Card ****{last4}',
    amex: 'Subscription with American Express ****{last4}',
    jcb: 'Subscription with JCB ****{last4}',
    unionpay: 'Subscription with Union Pay ****{last4}',
    discover: 'Subscription with Discover ****{last4}',
    diners: 'Subscription with Diners Club ****{last4}',
    cashapp: 'Subscription with Cash App Pay',
    bankAccount: 'Subscription with {bankName} ending in {last4}',
  },
  cardBrandNames: {
    visa: 'Visa',
    mastercard: 'Master Card',
    amex: 'American Express',
    jcb: 'JCB',
    unionpay: 'Union Pay',
    discover: 'Discover',
    diners: 'Diners Club',
  },
  cashApp: 'Cash App Pay',
  otherPaymentMethodPlan: {
    [PaymentMethodType.LINK]: 'Link',
    [PaymentMethodType.CASH_APP]: 'Cash App Pay',
    [PaymentMethodType.BANK_ACCOUNT]: '{bankName} **** {last4}',
  },
  change: 'Change',
  addPaymentModal: {
    title: 'Change payment method',
    cancel: 'Cancel',
    replace: 'Replace',
  },
  switchPlan: 'Switch Plan',
  switchTo: 'Switch to {plan} {billingPeriod}',
  switchToConfirmation:
    'Are you sure you want to switch to {plan} {billingPeriod}?',
  switchToConfirmationChargeNow:
    'You’ll be now charged {amountNow}, and starting on {date}, {amountDate}',
  switchToConfirmationChargeThen:
    'You’ll be charged starting on {date}, {amountDate}',
  switchToConfirmationDiscountEndDate:
    'Remember you have an active coupon for {percent}% ({amount}), and will be valid until {date}',
  switchToConfirmationDiscount:
    'Remember you have an active coupon for {percent}% ({amount}).',
  paymentMethodSaved: 'Your payment method has been saved.',
  paymentMethodProcessing:
    "Processing payment details. We'll update you when processing is complete.",
  paymentMethodError:
    'Failed to process payment details. Please try another payment method.',
  confirmSwitchPlan:
    'Are you sure you want to switch to {plan} {billingPeriod}?',
  annually: 'Annually',
  monthly: 'Monthly',
  confirmSwitchPlanDesc:
    'Starting on {date}, you will be billed a fee of {count}',
  confirmSwitchPlanLogin:
    "Once you confirm, your account will be upgraded and you'll have to log in again.",
  subscriptionChangeModal: {
    title: {
      [SubscriptionChangeType.UPGRADE]: {
        [SubscriptionPlan.LITE]: 'Switch to Lite Plan',
        [SubscriptionPlan.INDIVIDUAL]: 'Switch to Individual Plan',
        [SubscriptionPlan.PRO]: 'Switch to Pro Plan',
      },
      [SubscriptionChangeType.DOWNGRADE]: {
        [SubscriptionPlan.LITE]: 'Switch to Lite Plan',
        [SubscriptionPlan.INDIVIDUAL]: 'Switch to Individual Plan',
        [SubscriptionPlan.PRO]: 'Switch to Pro Plan',
      },
      [SubscriptionChangeType.CANCEL]: 'Cancel Subscription',
    },
    confirm: {
      [SubscriptionChangeType.UPGRADE]: 'Confirm Upgrade',
      [SubscriptionChangeType.DOWNGRADE]: 'Downgrade Plan',
      [SubscriptionChangeType.CANCEL]: 'Confirm Cancelation',
    },
    cancel: {
      [SubscriptionChangeType.UPGRADE]: 'Cancel',
      [SubscriptionChangeType.DOWNGRADE]: 'Stick with {plan}',
      [SubscriptionChangeType.CANCEL]: 'Stick with {plan}',
    },
    subtitle: {
      [SubscriptionChangeType.UPGRADE]: {
        [SubscriptionPlan.LITE]:
          'Take your plan to the next level with Clockwork Universe Lite',
        [SubscriptionPlan.INDIVIDUAL]:
          'Take your plan to the next level with Clockwork Universe Individual',

        [SubscriptionPlan.PRO]:
          'Take your plan to the next level with Clockwork Universe Pro',
      },
      [SubscriptionChangeType.DOWNGRADE]: 'You will miss all this...',
      [SubscriptionChangeType.CANCEL]: 'You will miss all this...',
    },
    common: {
      onlineOnboarding: 'Access to the seamless Online Onboarding experience',
      unlimitedPortfolios:
        'A streamlined solution for managing unlimited Invest Portfolios',
      yourInvestmentPortfolio:
        'A streamlined solution for managing your Investment Portfolio',
      yourInvestmentPortfolios:
        'A streamlined solution for managing your Invest Portfolios',
      unlimitedPipelines: 'Access to unlimited Pipelines ',
      countSeats: `Utilization of {count, plural, 
          one {1 Seat} 
          other {# Seats}} for comprehensive team collaboration`,
      investmentPortfoliosCount: `A streamlined solution for managing your 
          {count, plural, 
          one {Investment Portfolio} 
          other {# Investment Portfolios}}`,
      pipelinesCount:
        'Continued access to your {count, plural, one {Pipeline} other {# Pipelines}}',
      accessYourPipelines: 'Continued access to your Pipelines',
      accessUnlimitedPipelines:
        'Continued access to your {count, plural, one {Pipeline} other {# Pipelines}}',
      storageGB:
        'Complete control over your {count} GB of valuable stored data',
      storageMB:
        'Complete control over your {count} MB of valuable stored data',
      seats: `Utilization 
          {count, plural, 
          one {of your Seat} 
          other {of # Seats for comprehensive team collaboration}}`,
      createAndShareUpdates: 'Access to create and share updates effortlessly',
      createAndTrackMetrics:
        'Capability to create and track metrics for insightful analysis',
      clockworkChat: 'Engage in productive conversations with Clockwork Chat',
      emailAndChatSupport: 'Email and chat support',
      customEmailInboxes: 'Access to your custom email inboxes',
      thirdPartyAccess: 'Third Party Access',
    },
    [SubscriptionChangeType.DOWNGRADE]: {
      onlineOnboarding: '{common}',
      investmentPortfoliosCount:
        'Access for managing your {count, plural, one {Investment Portfolio} other {# Investment Portfolios}}. All your Investment portfolios data will be added to one.',
      pipelinesCount: '{common}. All your pipeline data will be added to one.',
      storageGB: '{common}',
      seats: '{common}',
      createAndShareUpdates: '{common}',
      clockworkChat: '{common}',
      customEmailInboxes: '{common}',
    },
    [SubscriptionChangeType.UPGRADE]: {
      onlineOnboarding: '{common}',
      investmentPortfoliosCount: '{common}',
      pipelinesCount: 'Access to 5 Pipelines',
      storageGB: '{common}',
      seats: '{common}',
      createAndShareUpdates: '{common}',
      createAndTrackMetrics: '{common}',
      clockworkChat: '{common}',
      emailAndChatSupport: '{common}',
      customEmailInboxes: '{common}',
    },
    upgradePlan: 'Upgrade Plan',
    upgradePlanSuitsYou: 'Upgrade to the plan that suits you best',
    thanksForYourInterest:
      'Hi, thank you for your interest in our organization plan. A representative from our team will get in touch soon.',
  },
  cancelFeedback: {
    title: 'We’re sorry to see you go!',
    readOnlyDisclaimer:
      "After you go back into the platform, please be aware that your access to the application will remain active until the end of your current paid period. Subsequent to this period, your access will shift to read-only. In read-only mode you'll retain access to your existing content but you'll just be able to view it. Any modifications won't be possible; however, you can still review your information.",
    answerSomeQuestions:
      "We'd appreciate it if you could take a few minutes to provide us with valuable feedback.",
    improvements: 'What could we do to improve?',
    backToUniverse: 'Go back to Universe',
    improvementsPlaceholder: 'Please type here...',
    reasonForLeaving: 'What’s your reason for leaving?',
    reasonsForLeaving: {
      notWhatLookingFor: 'I didn’t find what I was looking for',
      notEasyToUse: 'I didn’t find the product easy to use',
      tooExpensive: 'It was too expensive for me',
      other: 'Other',
    },
  },
  errorCancelSubscription:
    'An error occurred when trying to cancel the subscription, try again later.',
  errorDowngradeSubscription:
    'An error occurred when trying to downgrade the subscription, try again later.',
  errorUpgradeSubscription:
    'An error occurred when trying to upgrade the subscription, try again later.',
  trialEndsIndicator: '<bold>{countDays} days</bold> left on your trial',
  discount: 'Discount',
  subscribeTo: 'Subscribe to',
  monthlyTo: 'Monthly to',
  annuallyTo: 'Annually to',
  billedMonthly: 'Monthly',
  billedAnnually: 'Annually',
  switchToMonthly: 'Switch to monthly',
  switchToAnnually: 'Switch to annually',
  subtotal: 'Subtotal',
  total: 'Total',
  addPromotionCode: 'Add promotion code',
  apply: 'Apply',
  remove: 'Remove',
  invalidPromotionCode: 'Invalid promotion code. Please try again.',
  promotionCodeError:
    'An error occurred applying the discount code. Try again later',
  promotionCodeRemoveError:
    'An error occurred removing the discount code. Try again later',
  discountValue: 'Discount ({value})',
  validUntil: 'Valid until {date}',
  upgradePlan: 'Upgrade Plan',
  currentPlan: 'Current Plan',
  limitations: {
    upgradeYourPlan: 'Upgrade your plan',
    portfolios:
      'Your plan is limited up to {pipelineCount, plural, one {1 pipeline} other {# pipelines}} and {investPortfolioCount, plural, one {1 invest portfolio} other {# invests portfolios}}.\n{upgradeYourPlan} to create more portfolios. More information {here}.',
    transactions:
      'Your plan is limited up to {transactionCount, plural, one {1 transaction} other {# transactions}} for this portfolio. {upgradeYourPlan} to create more transactions. More information {here}.',
    portfoliosLimit:
      'You have reached the max. amount of portfolios your group can create. <SubscriptionsLink>Upgrade your plan</SubscriptionsLink> to create a new portfolio. <MoreInfoLink>More information here.</MoreInfoLink>',
    pipelinesLimit: 'You have reached the max. amount of pipelines.',
    investPortfoliosLimit:
      'You have reached the max. amount of invest portfolios.',
    transactionsLimit:
      'You have reached the max. amount of transactions your group can create. <SubscriptionsLink>Upgrade your plan</SubscriptionsLink> to add more transactions. <MoreInfoLink>More information here.</MoreInfoLink>',
    transactionsBulkLimit:
      "You are about to reach the portfolio's transaction limit. You can only add up to {availableTransactions}, and you've already added {transactionsCount}. Please remove from this list some to have {availableTransactions} or fewer.",
  },
  legacyClient: 'Legacy - Client',
  legacyFund: 'Legacy - Fund',
  groupUsing: 'Your group is using:',
  groupUsage:
    '{space} of storage, {seats} seats, {investPortfolios} Invest Portfolios, {pipelines} Pipelines, {transactions} transactions',
  addBillingAndPayment: 'Add Billing Information & Payment Method',
  billingDescription:
    'A billing account is used to store your payment information for your Clockwork Universe group subscription.',
  upgradePlanSuits: 'Upgrade to the plan that suits you best',
  upgradePlanSuccess: 'Your plan has been upgraded successfully.',
  errorCreatingCustomer:
    'An error occurred updating the billing information. Try again later',
  errorCreatingPaymentMethod:
    'An error occurred setting up the payment method. Try again later',
  addBillingAccountAndPaymentMethod: 'Add Billing & Payment Method',
}
