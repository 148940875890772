import { useMemo } from 'react'
import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import { useCompanyQuery } from 'utils/hooks/queries/useCompanyQuery'

interface UseOrganizationProfileBreadcrumbProps {
  companyFundId: string
}

const useOrganizationProfileBreadcrumb = ({
  companyFundId = '',
}: UseOrganizationProfileBreadcrumbProps) => {
  const { data: fundCompany } = useCompanyQuery(companyFundId, {
    enabled: !!companyFundId?.length,
  })

  const fundCompanyLogo = useMemo(
    () => fundCompany?.primaryLogo?.url ?? fundCompany?.logoUrl,
    [fundCompany?.logoUrl, fundCompany?.primaryLogo?.url]
  )

  const getFundCompanyAvatar = () => {
    if (!fundCompanyLogo) return null

    if (fundCompanyLogo.indexOf('missing.png') >= 0) {
      return (
        <Avatar
          initials={getInitials(fundCompany?.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }
    return <Avatar image={fundCompanyLogo} avatarStyle="avatarCircleXS" />
  }

  return {
    fundCompany,
    getFundCompanyAvatar,
  }
}

export default useOrganizationProfileBreadcrumb
