import { ReactNode } from 'react'
import { color } from 'utils/functions/colors'
import { Nullable } from 'utils/types/common'
import { InvestorType } from 'utils/types/investors'
import LogIcon from '../../LogIcon'
import { AddressLogTextBuilder } from '../text-builders/AddressLogTextBuilder'
import { DefaultLogTextBuilder } from '../text-builders/DefaultLogTextBuilder'
import { EmailLogTextBuilder } from '../text-builders/EmailLogTextBuilder'
import { FinancialLogTextBuilder } from '../text-builders/FinancialLogTextBuilder'
import { InvestorCustomTextBuilder } from '../text-builders/InvestorCustomTextBuilder'
import { EventType, LogTextBuilder } from '../types'
import { LogsBuilder } from './LogsBuilder'

export type InvestorLogChangeSetFields = {
  name: string
  routingNumber: string
  bankAccount: string
  bankName: string
  taxId: string
  userEmails: string[]
  geographyPlacesId: string
  phone: string
  position: string
  investorType: InvestorType
  industryId: string
  angellistUrl: string
  crunchbaseUrl: string
  linkedinUrl: string
  twitterUrl: string
  foundedDate: string
  website: string
  legalEntityName: string
  legalStructureName: string
  legalCountryName: string
  legalProvinceName: string
  userId: string
  [EventType.ADD_MEMBER]: string
  [EventType.INVITE_MEMBERS]: string
}

export class InvestorLogsBuilder extends LogsBuilder<InvestorLogChangeSetFields> {
  private defaultLogTextBuilder: LogTextBuilder = new DefaultLogTextBuilder(
    LogsBuilder.intl.formatMessage({ id: 'logs.investor' })
  )

  private defaultLogTextWithQuotesBuilder: LogTextBuilder =
    new DefaultLogTextBuilder(
      LogsBuilder.intl.formatMessage({ id: 'logs.investor' }),
      true
    )

  private addressLogTextBuilder: LogTextBuilder = new AddressLogTextBuilder()

  private emailLogTextBuilder: LogTextBuilder = new EmailLogTextBuilder()

  private financialLogTextBuilder: LogTextBuilder =
    new FinancialLogTextBuilder()

  private investorCustomTextBuilder: LogTextBuilder =
    new InvestorCustomTextBuilder(
      LogsBuilder.intl.formatMessage({ id: 'logs.investor' })
    )

  private isFinancialLogField() {
    const fields: (keyof InvestorLogChangeSetFields)[] = [
      'routingNumber',
      'bankAccount',
      'bankName',
      'taxId',
    ]
    return fields.includes(this.log.fieldName)
  }

  private isAddressLogField() {
    const fields: (keyof InvestorLogChangeSetFields)[] = [
      'geographyPlacesId',
      'position',
    ]
    return fields.includes(this.log.fieldName)
  }

  private isEmailLogField() {
    const fields: (keyof InvestorLogChangeSetFields)[] = ['userEmails']
    return fields.includes(this.log.fieldName)
  }

  private isDefaultLogField() {
    const fields: (keyof InvestorLogChangeSetFields)[] = ['phone']
    return fields.includes(this.log.fieldName)
  }

  private isDefaultLogWithQuotesField() {
    const fields: (keyof InvestorLogChangeSetFields)[] = [
      'crunchbaseUrl',
      'linkedinUrl',
      'twitterUrl',
      'legalEntityName',
      'legalStructureName',
      'legalCountryName',
      'legalProvinceName',
    ]
    return fields.includes(this.log.fieldName)
  }

  private isInvestorCustomLogField() {
    const fields: (keyof InvestorLogChangeSetFields)[] = [
      'investorType',
      'industryId',
      'foundedDate',
      'website',
      'angellistUrl',
      'userId',
      'name',
      EventType.ADD_MEMBER,
      EventType.INVITE_MEMBERS,
    ]
    return fields.includes(this.log.fieldName)
  }

  protected getTextBuilderForField(): Nullable<LogTextBuilder> {
    if (this.log.event === EventType.CREATE) {
      return this.defaultLogTextBuilder
    }

    if (this.isFinancialLogField()) {
      return this.financialLogTextBuilder
    }

    if (this.isAddressLogField()) {
      return this.addressLogTextBuilder
    }

    if (this.isEmailLogField()) {
      return this.emailLogTextBuilder
    }

    if (this.isDefaultLogField()) {
      return this.defaultLogTextBuilder
    }

    if (this.isDefaultLogWithQuotesField()) {
      return this.defaultLogTextWithQuotesBuilder
    }

    if (this.isInvestorCustomLogField()) {
      return this.investorCustomTextBuilder
    }

    return null
  }

  public static getShareIcon(): React.ReactNode {
    return <LogIcon color={color('green')} icon={['far', 'share']} />
  }

  public getOperationIcon(): ReactNode {
    switch (this.log.event) {
      case EventType.ADD_LOCATION:
        if (this.log.fieldName === 'position' && this.log.change[1] === 1)
          return LogsBuilder.getUpdateIcon()

        return LogsBuilder.getCreateIcon()
      case EventType.ADD_INDUSTRY:
      case EventType.INVITE_MEMBERS:
        return LogsBuilder.getCreateIcon()
      case EventType.UPDATE_LOCATION:
        return LogsBuilder.getUpdateIcon()
      case EventType.REMOVE_LOCATION:
      case EventType.REMOVE_INDUSTRY:
      case EventType.REMOVE_MEMBER:
        return LogsBuilder.getDestroyIcon()
      case EventType.ADD_MEMBER:
        return InvestorLogsBuilder.getShareIcon()
      default:
        return super.getOperationIcon()
    }
  }
}
