import {
  getChannelTypeFromTopicEntityType,
  getSubjectMatterFromTopicEntityType,
} from 'containers/Chat/helpers'
import { TopicEntityType } from 'utils/types/chatTopicEntity'
import { ChannelType } from '../containers/Chat/types'
import axiosClient from './httpClient'

class ChatService {
  static getUserToken = async (): Promise<string> => {
    const response = await axiosClient().get('/chats')

    return response.data.streamChatToken
  }

  static createChannel = async (
    entityType: TopicEntityType,
    entityId: string,
    membersIds: string[],
    name?: string,
    updateId?: string
  ): Promise<string> => {
    const response = await axiosClient().post('/channels', {
      channel: {
        name,
        channelType: getChannelTypeFromTopicEntityType(entityType),
        entityType,
        channelizableType: getSubjectMatterFromTopicEntityType(entityType),
        channelizableId: entityId,
        membersIds,
        updateId,
      },
    })

    return response.data.result
  }

  static createDmChannel = async (membersIds: string[]): Promise<string> => {
    const response = await axiosClient().post('/channels', {
      channel: { channelType: ChannelType.DIRECT_MESSAGE, membersIds },
    })
    return response.data.result
  }

  static addMembersToChannel = async (
    channelId: string,
    membersIds: string[],
    initialMessage: string,
    hideHistory: boolean
  ): Promise<void> => {
    await axiosClient().patch(`/channels/${channelId}/add_members`, {
      channel: {
        membersIds,
        initialMessage,
        hideHistory,
      },
    })
  }

  static removeMembersFromChannel = async (
    channelId: string,
    membersIds: string[]
  ): Promise<void> => {
    await axiosClient().patch(`/channels/${channelId}/remove_members`, {
      channel: {
        membersIds,
      },
    })
  }
}

export default ChatService
