import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const MessageContainer = styled.div`
  color: ${color('lightGray')};
  margin: 2.4rem;
  text-align: center;
`

export const MessageMembers = styled.div`
  display: block;
  font-size: 1.4rem;
  line-height: 1.7rem;
`

export const MessageDate = styled.div`
  font-size: 1.2rem;
  margin-top: 0.4rem;
`
