import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence } from 'framer-motion'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'

import { getServiceWorker } from 'selectors/serviceWorker'
import ToastFactory from './model/ToastFactory'
import * as Styles from './Toast.styles'

const initialToastState = {
  message: '',
  type: 'success',
  isVisible: false,
  timeout: 3000,
  children: () => {},
}

const Toast = ({ children }) => {
  const timeoutId = useRef(null)
  const intl = useIntl()
  const serviceWorker = useAppSelector(getServiceWorker)
  const variants = {
    visible: {
      top: serviceWorker.updated ? '10rem' : '2rem',
      transition: { duration: 0.5 },
    },
    hidden: {
      top: '-7rem',
      transition: { duration: 0.5 },
    },
  }

  const [toast, setToast] = useState({
    ...initialToastState,
  })

  const toastPortal = document.getElementById('toast')

  Toast.display = (message, type, timeout, toastChildren, afterClose) => {
    if (message) {
      if (toast.afterClose) {
        toast.afterClose()
      }

      setToast({
        message,
        type,
        isVisible: true,
        timeout: timeout || toast.timeout,
        children: toastChildren,
        afterClose,
      })
    }
  }

  Toast.displayIntl = (message, ...rest) => {
    let intlKey
    let intlValues
    if (Array.isArray(message)) {
      ;[intlKey, intlValues] = message
    } else {
      intlKey = message
    }
    Toast.display(intl.formatMessage({ id: intlKey }, intlValues), ...rest)
  }

  Toast.displayAction = ({
    message,
    type = 'action',
    timeout = 5000,
    action,
    afterClose,
    label,
  }) => {
    if (typeof message === 'string' && message) {
      if (toast.afterClose) {
        toast.afterClose()
      }
      setToast({
        message,
        type,
        isVisible: true,
        timeout: timeout || toast.timeout,
        // eslint-disable-next-line react/prop-types
        children: ({ hideToast }) => (
          <>
            <Toast.ActionButton
              label={label || intl.formatMessage({ id: 'general.undo' })}
              onClick={() => {
                hideToast()
                if (action) {
                  action()
                }
              }}
            />
            <Styles.CloseToast icon={['fal', 'times']} onClick={hideToast} />
          </>
        ),
        afterClose,
      })
    }
  }

  const hideToast = useCallback(() => {
    setToast({
      ...initialToastState,
    })
    if (toast.afterClose) {
      toast.afterClose()
    }
  }, [toast])

  const hideToastWithoutCallback = useCallback(() => {
    setToast({
      ...initialToastState,
    })
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
  }, [])

  useEffect(() => {
    if (toast.isVisible) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
      timeoutId.current = setTimeout(() => {
        hideToast()
      }, toast.timeout)
    }
  }, [hideToast, toast])

  const ToastImplementation = ToastFactory.createFactory(toast.type)

  return (
    <>
      {ReactDom.createPortal(
        <AnimatePresence>
          {toast.isVisible && (
            <Styles.Wrapper
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
              type={ToastImplementation.getType()}
            >
              <Styles.Content>
                <FontAwesomeIcon
                  icon={['fal', ToastImplementation.getIcon()]}
                />
                <span>{toast.message}</span>
                {(typeof toast?.children === 'function' &&
                  toast.children({ hideToast: hideToastWithoutCallback })) ||
                  toast.children}
              </Styles.Content>
              {!toast?.children && (
                <Styles.CloseToast
                  icon={['fal', 'times']}
                  onClick={hideToast}
                />
              )}
            </Styles.Wrapper>
          )}
        </AnimatePresence>,
        toastPortal
      )}
      {children}
    </>
  )
}

Toast.defaultProps = {
  children: null,
}

Toast.propTypes = {
  children: PropTypes.node,
}

Toast.ActionButton = ({ label, onClick }) => {
  return (
    <Styles.UndoButton>
      <button type="button" onClick={onClick}>
        {label}
      </button>
    </Styles.UndoButton>
  )
}

Toast.ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Toast
