const fundProfile = {
  fundSummary: 'Fund Summary',
  capitalStatement: 'Capital Statement',
  totalCommitment: 'Total Commitment',
  investedCapital: 'Invested Capital',
  unfundedCommitment: 'Unfunded Commitment',
  distributions: 'Distributions',
  proRataHoldingValue: 'Pro-Rata Holding Value',
  founded: 'Founded',
  noHoldings: 'No holdings yet. Check out later.',
}

export default fundProfile
