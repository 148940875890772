export const GROUP_PROFILE = '/group-profile'

export const GROUP_PROFILE_UPDATES = `${GROUP_PROFILE}/updates`
export const GROUP_PROFILE_UPDATES_EDIT = `${GROUP_PROFILE}/updates/edit`
export const GROUP_PROFILE_INVESTMENTS = `${GROUP_PROFILE}/investments`
export const GROUP_PROFILE_INVESTMENTS_EDIT = `${GROUP_PROFILE}/investments/edit`

export const GROUP_PROFILE_METRICS = `${GROUP_PROFILE}/metrics`
export const GROUP_PROFILE_METRICS_EDIT = `${GROUP_PROFILE_METRICS}/edit`

export const GROUP_PROFILE_METRICS_METRIC_ID = `${GROUP_PROFILE_METRICS}/:metricId`
export const GROUP_PROFILE_METRICS_ID = `${GROUP_PROFILE_METRICS}/:id`
export const GROUP_PROFILE_METRICS_UPDATE_ALL = `${GROUP_PROFILE_METRICS}/update-all`
export const GROUP_PROFILE_METRICS_ID_ADD_VALUE = `${GROUP_PROFILE_METRICS_METRIC_ID}/add-value`
export const GROUP_PROFILE_METRICS_ID_SET_MILESTONE = `${GROUP_PROFILE_METRICS_METRIC_ID}/set-milestone`
export const GROUP_PROFILE_METRICS_ID_EDIT = `${GROUP_PROFILE_METRICS_METRIC_ID}/edit`

export const GROUP_PROFILE_CHAT = `${GROUP_PROFILE}/chat`
export const GROUP_PROFILE_CHAT_EDIT = `${GROUP_PROFILE_CHAT}/edit`

export const GROUP_PROFILE_ALL_UPDATES = `${GROUP_PROFILE}/all-updates`
export const GROUP_PROFILE_SELECTED_TAB_EDIT = `${GROUP_PROFILE}/:selectedTab/edit`

export const INVESTOR_MANAGEMENT = '/investor-management'

export const COMPANIES = '/companies'
export const FUNDS = '/funds'

export const FUND_PROFILE_PREFIX = `${FUNDS}/:fundId`

export const FUNDS_UPDATES = `${FUND_PROFILE_PREFIX}/updates`
export const FUNDS_UPDATES_EDIT = `${FUND_PROFILE_PREFIX}/updates/edit`
export const FUNDS_METRICS = `${FUND_PROFILE_PREFIX}/metrics`
export const FUNDS_METRICS_EDIT = `${FUND_PROFILE_PREFIX}/metrics/edit`
export const FUNDS_CHAT = `${FUND_PROFILE_PREFIX}/chat`
export const FUNDS_CHAT_EDIT = `${FUND_PROFILE_PREFIX}/chat/edit`

export const FUNDS_ALL_UPDATES = `${FUND_PROFILE_PREFIX}/all-updates`

export const INDIVIDUAL_FUND_ROUTES = [
  GROUP_PROFILE_ALL_UPDATES,
  GROUP_PROFILE_SELECTED_TAB_EDIT,
  GROUP_PROFILE_METRICS_UPDATE_ALL,
  GROUP_PROFILE_METRICS_ID,
  INVESTOR_MANAGEMENT,
  COMPANIES,
  FUNDS,
]

export const FUNDS_ROUTES = [
  FUNDS_UPDATES,
  FUNDS_UPDATES_EDIT,
  FUNDS_METRICS,
  FUNDS_METRICS_EDIT,
  FUNDS_CHAT,
  FUNDS_CHAT_EDIT,
]

export const FUND_MANAGER_PROFILE_ROUTES = [
  GROUP_PROFILE,
  GROUP_PROFILE_UPDATES,
  GROUP_PROFILE_UPDATES_EDIT,
  GROUP_PROFILE_INVESTMENTS,
  GROUP_PROFILE_INVESTMENTS_EDIT,
  GROUP_PROFILE_METRICS,
  GROUP_PROFILE_METRICS_METRIC_ID,
  GROUP_PROFILE_METRICS_ID_ADD_VALUE,
  GROUP_PROFILE_METRICS_ID_SET_MILESTONE,
  GROUP_PROFILE_METRICS_ID_EDIT,
  GROUP_PROFILE_METRICS_EDIT,
  GROUP_PROFILE_CHAT,
  GROUP_PROFILE_CHAT_EDIT,
]

const fundManagerRoutes = [
  ...INDIVIDUAL_FUND_ROUTES,
  ...FUND_MANAGER_PROFILE_ROUTES,
  ...FUNDS_ROUTES,
]

export default fundManagerRoutes
