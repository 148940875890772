import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Title from 'components/Title'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import TeamMemberForm from 'components/EmployeesList/TeamMemberForm'
import TeamMemberRow from 'components/EmployeesList/TeamMemberRow/TeamMemberRow'
import { listAnimation } from 'utils/animations/list'
import { Employee } from 'utils/types/company'
import {
  TeamMembersHeader,
  TeamMembersList,
  TeamMembersTableWrapper,
  ZeroStateWrapper,
  AddPeople,
} from './EmployeesList.styles'

interface EmployeesListProps {
  label?: string
  buttonProps: {}
  employees: Employee[]
  onReorderEmployees: (sourceIndex: number, destinationIndex: number) => void
  onDeleteEmployee: (employee: Employee) => void
  onAddNewTeamMember: (employee: Employee) => void
  onEditTeamMember: (employeeId: string, newEmployee: Employee) => void
  companyId?: string
}

const EmployeesList: React.FC<EmployeesListProps> = ({
  buttonProps,
  onReorderEmployees,
  onDeleteEmployee,
  employees,
  onAddNewTeamMember,
  onEditTeamMember,
  companyId,
}) => {
  const intl = useIntl()

  const [employeeModal, setEmployeeModal] = useState<{
    show: boolean
    edit: boolean
    employee?: Employee
  }>({
    show: false,
    edit: false,
  })

  const onDragEnd = async ({ source, destination }) => {
    if (
      typeof source?.index === 'number' &&
      typeof destination?.index === 'number'
    ) {
      onReorderEmployees(source.index, destination.index)
    }
  }

  return (
    <>
      <TeamMembersTableWrapper>
        <TeamMembersHeader>
          <Title title={intl.formatMessage({ id: 'editCompany.keyPeople' })} />
          <Button
            type="button"
            link
            onClick={() => setEmployeeModal({ show: true, edit: false })}
            {...buttonProps}
          >
            {intl.messages['editCompany.addMember']}
          </Button>
        </TeamMembersHeader>

        <Separator height="0.8rem" />

        {!employees?.length ? (
          <ZeroStateWrapper>
            <p>
              {intl.formatMessage({ id: 'investorManagement.noKeyPeople' })}
            </p>
            <AddPeople
              onClick={() => setEmployeeModal({ show: true, edit: false })}
            >
              {intl.formatMessage({ id: 'investorManagement.addPeople' })}
            </AddPeople>
          </ZeroStateWrapper>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="employee-list">
              {(provided) => (
                <TeamMembersList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  initial="hidden"
                  animate="visible"
                  variants={listAnimation}
                >
                  {employees.map((employee, index) => (
                    <Draggable
                      key={employee.id}
                      draggableId={employee.id}
                      index={index}
                    >
                      {(draggableProvided, draggableSnapshot) => (
                        <li
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                        >
                          <TeamMemberRow
                            {...draggableSnapshot}
                            employee={employee}
                            onEditEmployee={(emp) =>
                              setEmployeeModal({
                                show: true,
                                edit: true,
                                employee: emp,
                              })
                            }
                            onDeleteEmployee={onDeleteEmployee}
                          />
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TeamMembersList>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </TeamMembersTableWrapper>

      <TeamMemberForm
        show={employeeModal.show}
        edit={employeeModal.edit}
        employee={employeeModal.employee}
        onHide={() =>
          setEmployeeModal((prev) => ({ show: false, edit: prev.edit }))
        }
        companyId={companyId}
        onAddNewTeamMember={onAddNewTeamMember}
        onEditTeamMember={onEditTeamMember}
      />
    </>
  )
}

export default EmployeesList
