import UniversityService from 'api/UniversityService'
import Types from './types'

export const fetchUniversityActions = () => ({
  type: Types.FETCH_UNIVERSITY_ACTIONS,
  payload: UniversityService.getUniversityActions(),
})

export const setActionCompleted = (actionId) => ({
  type: Types.SET_ACTION_COMPLETED,
  payload: {
    promise: UniversityService.createUserAction(actionId),
    data: { actionId },
  },
})

export const setUserActionSkipped = (actionId) => ({
  type: Types.SET_USER_ACTION_SKIPPED,
  payload: {
    promise: UniversityService.skipUserAction(actionId),
    data: { actionId },
  },
})

export const setGroupActionSkipped = (actionId) => ({
  type: Types.SET_GROUP_ACTION_SKIPPED,
  payload: {
    promise: UniversityService.skipGroupAction(actionId),
    data: { actionId },
  },
})

export const setUniversityProgress = (progress, isFirstSetProgress) => ({
  type: Types.SET_UNIVERSITY_PROGRESS,
  payload: {
    progress,
    isFirstSetProgress,
  },
})

export const setUniversityEnabled = (isEnabled) => ({
  type: Types.SET_UNIVERSITY_ENABLED,
  payload: {
    isEnabled,
  },
})

export const seUniversityActionEnabled = (actionId, isEnabled) => ({
  type: Types.SET_UNIVERSITY_ACTION_ENABLED,
  payload: {
    actionId,
    isEnabled,
  },
})
