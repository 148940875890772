import { useQuery } from '@tanstack/react-query'
import sortBy from 'lodash/sortBy'
import InitialDataService from 'api/InitialDataService'
import { Country, Industry, LegalStructure, Sector } from 'utils/types/company'

const sortByName = (list: any[]) => sortBy(list, 'name')

const getLegalStructures = (legalStructures: LegalStructure[]) => {
  const naLegalStructure = legalStructures.find(
    (legalStructure) => legalStructure.name === 'N/A'
  )
  const legalStructuresWithoutNa = legalStructures.filter(
    (legalStructure) => legalStructure.name !== 'N/A'
  )
  const sortedLegalStructures = sortByName(legalStructuresWithoutNa)
  sortedLegalStructures.unshift(naLegalStructure)

  return sortedLegalStructures
}

export interface Data {
  countries: Country[]
  provinces: any
  legalStructures: LegalStructure[]
  industries: Industry[]
  sectors: Sector[]
}

interface InitialData {
  initialData?: Data
  loading: boolean
}

const useInitialData = (): InitialData => {
  const { data, status } = useQuery(['initialData'], async () => {
    const {
      data: { entities },
    } = await InitialDataService.getInitialData()

    const countries = sortByName(Object.values(entities.countries) as Country[])
    const provinces = sortByName(Object.values(entities.provinces) as any)
    const legalStructures = getLegalStructures(
      Object.values(entities.legalStructures) as LegalStructure[]
    )
    const industries = sortByName(
      Object.values(entities.industries) as Industry[]
    )
    const sectors = sortByName(Object.values(entities.sectors) as Sector[])

    return {
      countries,
      provinces,
      legalStructures,
      industries,
      sectors,
    }
  })

  return {
    initialData: data,
    loading: status === 'loading',
  }
}

export default useInitialData
