import Drawer from 'components/Drawer'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { CLOSE_THREAD_EVENT } from 'containers/Chat/hooks/useIsThreadOpen'
import { useIntl } from 'react-intl'

const ThreadHeader = ({ closeThread }) => {
  const intl = useIntl()

  const onCloseDrawer = () => {
    dispatchEvent(CLOSE_THREAD_EVENT)
    closeThread()
  }

  return (
    <Drawer.Header
      onCloseDrawer={onCloseDrawer}
      title={intl.formatMessage({ id: 'chat.thread' })}
    />
  )
}

export default ThreadHeader
