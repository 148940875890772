import styled from 'styled-components'

import RunOutOfStorageMessage from 'components/RunOutOfStorageMessage'

export const ImagePreview = styled.img`
  border-radius: 0.8rem;
  height: 10rem;
  object-fit: cover;
  width: 10rem;
`

export const AttachmentPreview = styled.div`
  align-items: center;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--color-gray-300);
  box-shadow: 0 2 40 0 rgba(0, 0, 0, 0.1);
  display: flex;
  height: 10rem;
  justify-content: center;
  width: 10rem;
`

export const RunOutOfStorageMessageStyled = styled(RunOutOfStorageMessage)`
  margin-bottom: 0.8rem;
`
