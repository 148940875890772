import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { Scroll } from 'App.styles'

const DISCLAIMER_HEIGHT = '7.6rem'

export const Container = styled.div`
  width: fit-content;
  overflow: hidden;
`

export const GridContainer = styled.div<{ disclaimer?: boolean }>`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  max-height: calc(
    61vh - ${({ disclaimer }) => (disclaimer ? DISCLAIMER_HEIGHT : '0rem')}
  );
`

export const VerticalPlusContainer = styled.div`
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  padding: 0.2rem;
  border: 0.1rem solid white;
  cursor: pointer;

  :hover {
    border: 0.1rem solid ${color('veryLightBlue')};
    border-radius: 0.8rem;
  }
`

export const HorizontalPlusContainer = styled(VerticalPlusContainer)<{
  hasRightMargin: boolean
}>`
  margin: 0.6rem 0;
  margin-right: ${({ hasRightMargin }) => (hasRightMargin ? 3 : 0)}rem;
  padding: 0;
`

export const StyledGrid = styled.div<{ isInDrawer?: boolean }>`
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 0.5rem;

  ${Scroll()}
  ::-webkit-scrollbar {
    height: 0.8rem;
  }

  ${({ isInDrawer }) =>
    !isInDrawer &&
    css`
      overflow-x: auto;
      padding-right: 0.3rem;
      margin-right: 0.2rem;
      border-bottom: 2px solid ${color('veryLightGray')};
      border-right: 2px solid ${color('veryLightGray')};
    `}

  .reactgrid-content {
    font-size: 1.4rem;
  }

  .rg-celleditor {
    padding: initial;
  }

  .rg-celleditor input[name='search'] {
    height: 3.8rem;
    width: 100%;
    padding: 1rem 1.6rem;
    font-size: 1.4rem;
    background-color: ${color('lightGray', 0.1)};
    margin-bottom: 1rem;
  }

  .reactgrid-content {
    .rg-pane-shadow {
      box-shadow: none;
    }

    .rg-pane.rg-pane-top {
      z-index: 1;
    }
  }

  .rg-cell.rg-number-cell,
  .rg-cell.rg-date-cell,
  .rg-cell.rg-holdingDropdown-cell,
  .rg-cell.rg-staticDropdown-cell,
  .rg-cell.rg-booleanDropdown-cell,
  .rg-cell.rg-rowNumber-cell,
  .rg-cell.rg-text-cell,
  .rg-cell.rg-holdingName-cell,
  .rg-cell.rg-website-cell {
    padding: 0;
  }

  .rg-celleditor.rg-text-celleditor input {
    padding: 0 0.8rem;
  }

  .rg-text-cell.rg-cell-nonEditable {
    color: ${color('lightGray')};
    font-size: 1.2rem;
    font-weight: 700;
  }

  .reactgrid-content .rg-pane .rg-cell.rg-text-cell.placeholder {
    font-size: 1.4rem;
    color: ${color('lightGray', 0.8)};
  }

  .rg-celleditor.rg-website-celleditor input {
    padding: 0 0.8rem;
  }
`
