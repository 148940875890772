import { useCallback, useRef, useState, useMemo } from 'react'
import type {
  FormikTouched,
  FieldArrayRenderProps,
  FormikErrors,
  FormikHelpers,
} from 'formik'
import { useIntl } from 'react-intl'
import cloneDeep from 'lodash/cloneDeep'
import { convertToFormikTouched } from 'utils/forms/formik'
import { randomId } from 'utils/functions/number'
import Toast from 'components/Toast'
import HoldingsService from 'api/HoldingsService'
import { getArraySchema } from '../schemas'
import {
  AddHoldingBulkImportForm,
  AddHoldingBulkImportFormErrors,
  AddHoldingFormErrors,
  AddHoldingFormId,
  HoldingType,
  SuggestionErrors,
} from '../types'
import {
  getHoldingsErrorsFromDrafts,
  setRepeatedBulkErrors,
  hasBulkImportServerErrors,
  validateServerErrors,
  hasFundsErrors,
} from '../errors'
import { mapToBulkImportBackendRequest } from './utils'
import { getDefaultHoldingValues } from '../utils'

export interface ImportHoldingsProps {
  onHideModal: () => void
  onCreateNewHoldings?: (holdingIds: string[]) => void
  fileName: string
  holdingsFromCSV: AddHoldingFormId[]
  initialErrors: AddHoldingBulkImportFormErrors
  initialStatus: { holdings: SuggestionErrors[] }
}

export const useImportHoldingsModal = ({
  onHideModal,
  onCreateNewHoldings,
  holdingsFromCSV,
  initialErrors,
  initialStatus,
}: ImportHoldingsProps) => {
  const intl = useIntl()
  const initialValues = useMemo(
    () => ({ holdings: holdingsFromCSV }),
    [holdingsFromCSV]
  )
  const initialTouched = useMemo(
    () =>
      convertToFormikTouched<AddHoldingBulkImportForm>({
        holdings: holdingsFromCSV,
      }),
    [holdingsFromCSV]
  )
  const validationSchema = useMemo(() => getArraySchema(intl), [intl])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const csvHoldingsCount = useRef(holdingsFromCSV.length)
  const lastHoldingsSentToServer = useRef(holdingsFromCSV)
  const currentStatus = useRef(initialStatus)
  const serverErrors = useRef<AddHoldingBulkImportFormErrors>(initialErrors)
  const [currentHolding, setCurrentHolding] = useState<AddHoldingFormId>(
    holdingsFromCSV[0]
  )

  const isHoldingValid = useCallback(
    (
      holding: AddHoldingFormId,
      errors?: AddHoldingFormErrors,
      touched?: FormikTouched<AddHoldingFormId>
    ) => {
      if (holding.type === HoldingType.FUND) {
        return !(errors && hasFundsErrors(errors) && touched?.funds)
      }

      return !(Object.keys(errors?.company || {}).length && touched?.company)
    },
    []
  )

  const onAddHolding = useCallback((arrayHelpers: FieldArrayRenderProps) => {
    const newHolding = {
      id: randomId(),
      ...getDefaultHoldingValues(),
    }
    arrayHelpers.push(newHolding)
    setCurrentHolding(newHolding)
    currentStatus.current.holdings.push({})
    serverErrors.current.holdings.push({
      company: {},
      funds: {
        funds: [],
      },
    })
  }, [])

  const deleteHoldingErrors = useCallback((holdingIndex: number) => {
    serverErrors.current = {
      holdings: serverErrors.current.holdings.filter(
        (_, index) => index !== holdingIndex
      ),
    }
  }, [])

  const onDeleteHolding = useCallback(
    (
      values: AddHoldingBulkImportForm,
      arrayHelpers: FieldArrayRenderProps,
      validateForm: (
        values?: AddHoldingBulkImportForm
      ) => Promise<FormikErrors<AddHoldingBulkImportForm>>,
      setStatus: (status?: any) => void
    ) => {
      const currentHoldingIndex = values.holdings?.findIndex?.(
        (holding) => holding.id === currentHolding?.id
      )
      const newIndex = currentHoldingIndex === 0 ? 1 : currentHoldingIndex - 1
      const newSelectedHolding = values.holdings[newIndex]

      deleteHoldingErrors(currentHoldingIndex)
      lastHoldingsSentToServer.current =
        lastHoldingsSentToServer.current.filter(
          (_, index) => index !== currentHoldingIndex
        )
      currentStatus.current = {
        holdings: currentStatus.current.holdings.filter(
          (_, index) => index !== currentHoldingIndex
        ),
      }
      setStatus(currentStatus.current)

      setCurrentHolding(newSelectedHolding)
      arrayHelpers.remove(currentHoldingIndex)
      validateForm()
    },
    [currentHolding?.id, deleteHoldingErrors]
  )

  const validate = useCallback(
    (values: AddHoldingBulkImportForm) => {
      const errors = cloneDeep(serverErrors.current)

      values.holdings.forEach((holding, index) => {
        if (
          lastHoldingsSentToServer.current[index] &&
          errors.holdings?.[index]
        ) {
          validateServerErrors(
            errors.holdings?.[index],
            holding,
            lastHoldingsSentToServer.current[index]
          )
        }
      })

      setRepeatedBulkErrors(values.holdings, errors, intl)

      return hasBulkImportServerErrors(errors, values.holdings) ? errors : {}
    },
    [lastHoldingsSentToServer, serverErrors, intl]
  )

  const onSubmit = useCallback(
    async (
      values: AddHoldingBulkImportForm,
      formikHelpers: FormikHelpers<AddHoldingBulkImportForm>
    ) => {
      try {
        setIsSubmitting(true)
        lastHoldingsSentToServer.current = cloneDeep(values.holdings)

        const holdingsValidationPayload = values.holdings.map(
          (holding, index) => mapToBulkImportBackendRequest(holding, index)
        )

        const validatedHoldings = await HoldingsService.validateBulkImport(
          holdingsValidationPayload
        )

        const { errors, status } = await getHoldingsErrorsFromDrafts(
          validatedHoldings,
          values.holdings
        )

        if (hasBulkImportServerErrors(errors, values.holdings)) {
          serverErrors.current = errors
          formikHelpers.setStatus(status)
          formikHelpers.validateForm()
        } else {
          const holdingsBulkPayload = values.holdings.map((holding, index) =>
            mapToBulkImportBackendRequest(holding, index, true)
          )
          const holdingsIds = await HoldingsService.bulkCreateHoldings(
            holdingsBulkPayload
          )
          Toast.displayIntl('addHolding.bulkImportSuccess', 'success')
          onCreateNewHoldings?.(holdingsIds)
          onHideModal()
        }
      } catch (error) {
        Toast.displayIntl('addHolding.bulkImportError', 'error')
      } finally {
        setIsSubmitting(false)
      }
    },
    [onCreateNewHoldings, onHideModal]
  )

  return {
    initialValues,
    initialTouched,
    validationSchema,
    validate,
    onSubmit,
    csvHoldingsCount: csvHoldingsCount.current,
    currentHolding,
    setCurrentHolding,
    onAddHolding,
    onDeleteHolding,
    isHoldingValid,
    isSubmitting,
  }
}
