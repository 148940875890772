import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'

import Table from 'ui/Table'
import { getFundPortfolioInvestorColumns } from 'utils/functions/renderers/renderFundPortfolioInvestorHelper'
import { getCompanyTransactionsColumns } from 'utils/functions/renderers/renderTransactionHelper'

import Toast from 'components/Toast'
import Card from 'components/Card'

import styles from 'containers/InvestorManagement/ShowInvestor/ShowInvestor.module.scss'
import InvestorManagementService, {
  TransactionFilter,
} from 'api/InvestorManagementService'

import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import CompanyTransactionsZeroState from 'components/PortfolioHoldings/CompanyTransactionsZeroState'
import { TransactionItem } from 'utils/types/update'
import { maxSize } from 'utils/constants/breakpoint'
import { FundPortfolioInvestor } from 'utils/types/funds'
import { orderArrayByObjectPropertyAndDirection } from 'utils/functions/array'
import { SortDirection } from 'utils/constants'
import { SortDirectionType } from 'utils/types/common'
import { SortByCallback } from 'ui/Table/types'
import { TransactionsVisibleColumns } from 'utils/types/transactions'
import { NoFunds, StyledFunds, TableMarginWrapper } from './FundsTable.styles'

const PAGE_SIZE = 25

interface FundsTableProps {
  isLoading: boolean
  funds: FundPortfolioInvestor[]
  onShowTransaction: (transaction: TransactionItem) => void
  investorProfile?: boolean
}

const FundsTable: React.FC<FundsTableProps> = ({
  isLoading,
  funds,
  onShowTransaction,
  investorProfile = true,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const [sortBy, setSortBy] = useState<{
    sortId: string
    sortDirection: SortDirectionType
  }>({
    sortId: 'investorManagement.investmentVehicle.name',
    sortDirection: SortDirection.ASC,
  })
  const getTransactionsByFund = async (
    rowData: FundPortfolioInvestor,
    page: number,
    pageSize: number,
    sortId: string,
    sortDirection: SortDirectionType
  ) => {
    try {
      const transactions = await InvestorManagementService.fetchTransactions({
        id: rowData.id,
        filter: TransactionFilter.FUND_PORTFOLIO_INVESTOR,
        page,
        pageSize,
        sortId,
        sortDirection,
      })

      return transactions
    } catch (error) {
      Toast.displayIntl(
        'investorManagement.funds.errorFetchingTransactions',
        'error'
      )
      return []
    }
  }

  const onSortByColumn: SortByCallback = ({ sortId, sortDirection }) => {
    setSortBy({
      sortId,
      sortDirection,
    })
  }

  const sortedFunds = useMemo<FundPortfolioInvestor[]>(() => {
    return orderArrayByObjectPropertyAndDirection(
      funds,
      sortBy.sortId,
      sortBy.sortDirection
    )
  }, [funds, sortBy])

  const visibleColumns = [
    'expand',
    'name',
    'committedCapital',
    'capitalCalled',
    'unfundedCommitment',
    'distributions',
    'totalInvestment',
  ]

  const visibleTransactionsColumns: TransactionsVisibleColumns = {
    title: true,
    date: true,
    investmentVehicle: true,
    transactionType: true,
    amount: true,
    instrument: false,
    description: true,
    attachableCount: true,
    editTransaction: false,
  }

  const handleInvestorClick = useCallback(
    (id: string) => {
      history.push(`/investor-management/investors/${id}/profile`)
    },
    [history]
  )

  return (
    <StyledFunds>
      <Card isExpandable padding="1.4rem 3.2rem">
        <Card.Header>
          <FormattedMessage id="investorManagement.funds.title" />
        </Card.Header>
        <Card.Body>
          {funds.length ? (
            <TableMarginWrapper>
              <Table<
                FundPortfolioInvestor,
                TransactionItem,
                FundPortfolioInvestor
              >
                data={sortedFunds}
                loading={isLoading}
                rowHeight={45}
                keyPath="id"
                columns={getFundPortfolioInvestorColumns({
                  intl,
                  styles,
                  isMobile,
                  visibleColumns,
                  withAvatarAndLink: false,
                  handleInvestorClick,
                })}
                isExpandable
                internalTableInitialSortId="date"
                internalTableInitialSortDirection={SortDirection.DESC}
                getExpandableData={getTransactionsByFund}
                expandedRowHeight={41}
                expandedKeyPath="id"
                expandedColumns={getCompanyTransactionsColumns({
                  intl,
                  styles,
                  onEditTransaction: () => {},
                  isEditable: false,
                  visibleTransactionsColumns,
                })}
                loadingExpandedDataLabel={intl.formatMessage({
                  id: 'investorManagement.funds.loadingTransactions',
                })}
                externalEmbeddedPagination
                embeddedPageSize={PAGE_SIZE}
                paginationLabel={intl.formatMessage({
                  id: 'investorManagement.funds.loadMoreTransactions',
                })}
                expandOnRowClick
                renderExpandableRowZeroState={() => (
                  <CompanyTransactionsZeroState />
                )}
                onClickExpandedRow={onShowTransaction}
                onChangeSortBy={onSortByColumn}
              />
            </TableMarginWrapper>
          ) : (
            <NoFunds>
              {investorProfile
                ? intl.formatMessage({
                    id: 'investorManagement.funds.notFundsForInvestors',
                  })
                : intl.formatMessage({
                    id: 'investorManagement.funds.notFundsForVehicles',
                  })}
            </NoFunds>
          )}
        </Card.Body>
      </Card>
    </StyledFunds>
  )
}

export default FundsTable
