import * as Sentry from '@sentry/react'
import { BYTES_IN_MB, GroupTypes } from 'utils/constants/groups'
import {
  ApiOnboardingSteps,
  TierApi,
  TierClassType,
} from 'utils/types/api/tier'
import { GroupCompany } from 'utils/types/company'
import { AuthHeaders } from 'utils/types/signUp'
import { SubscriptionPlan } from 'utils/types/subscriptions'
import { OnboardingSteps, Tier } from 'utils/types/tier'
import { Group, InvestorGroup, User, UserGroup } from 'utils/types/user'
import { SelectedMetricSettingsPayload } from 'utils/types/api/user'
import { NO_LIMIT } from 'utils/constants/tier'
import axiosClient from './httpClient'
import { ApiEntity, ApiShowResponse } from './types'
import UserService from './UserService'

export const GROUP_TYPE_NOT_EQUAL_FILTER =
  'q[scopeable_of_Group_type_type_or_scopeable_of_User_type_provider_not_eq]'
export const ENTITY_TYPE_NOT_EQUAL_FILTER = 'q[scopeable_type_not_eq]'
export const ENTITY_TYPE_NOT_IN_FILTER = 'q[scopeable_type_not_in]'
export const ENTITY_TYPE_EQUAL_FILTER = 'q[scopeable_type_eq]'
export const GROUP_ID_NOT_IN_FILTER = 'q[scopeable_of_Group_type_id_not_in]'
export const SCOPEABLE_ID_NOT_IN = 'q[scopeable_id_not_in]'
export const KEYWORD_FILTER = 'q[name_or_email_or_handle_cont]'
export const GROUP_EMAIL_IN = 'q[investor_group_email_or_group_email_in][]'

const USER_ID_NOT_IN_FILTER = 'q[user_id_not_in]'
const NAME_CONTAINS_FILTER = 'q[user_name_cont]'

const PER_PAGE = 5000

class GroupService {
  static normalizeScopedUsers = (entities): (Group | User)[] => {
    if (!entities.scopedUsersGroups) {
      return []
    }
    return Object.values(entities.scopedUsersGroups).map(
      (scopedUsersGroup: any) => {
        return {
          type: scopedUsersGroup.scopeableType,
          ...entities.scopeables[scopedUsersGroup.scopeable],
        }
      }
    )
  }

  static normalizeTierPlan = (
    tierClassType: TierClassType
  ): SubscriptionPlan => {
    switch (tierClassType) {
      case TierClassType.LITE:
        return SubscriptionPlan.LITE
      case TierClassType.INDIVIDUAL:
        return SubscriptionPlan.INDIVIDUAL
      case TierClassType.PRO:
        return SubscriptionPlan.PRO
      default:
        return SubscriptionPlan.ORGANIZATION
    }
  }

  static normalizeOnboardingSteps = (
    apiOnboardingStep: ApiOnboardingSteps
  ): OnboardingSteps => {
    return {
      welcome: apiOnboardingStep.welcome === 'true',
      track: apiOnboardingStep.track === 'true',
      files: apiOnboardingStep.files === 'true',
      inbound: apiOnboardingStep.inbound === 'true',
    }
  }

  static normalizeMax = (value: number): number => {
    if (value === -1) return NO_LIMIT

    return value
  }

  static normalizeTier = (tierApi: TierApi): Tier => {
    return {
      ...tierApi,
      plan: this.normalizeTierPlan(tierApi.classType),
      storageInMb: tierApi.storage / BYTES_IN_MB,
      onboarding: this.normalizeOnboardingSteps(tierApi.onboarding.onboarding),
      maxStorageInMb: this.normalizeMax(tierApi.maxStorage),
      maxInvestPortfolios: this.normalizeMax(tierApi.maxInvestPortfolios),
      maxTrackPortfolios: this.normalizeMax(tierApi.maxTrackPortfolios),
      maxTransactions: this.normalizeMax(tierApi.maxTransactions),
      maxUsers: this.normalizeMax(tierApi.maxUsers),
    }
  }

  static getGroupTier = async (authHeaders?: AuthHeaders): Promise<Tier> => {
    const response = await axiosClient(false, authHeaders).get<
      ApiShowResponse<{ tiers: ApiEntity<TierApi> }>
    >(`/tiers/group_tier`)

    const tierApi = response.data.entities?.tiers?.[response.data.result]

    return GroupService.normalizeTier(tierApi)
  }

  static updateTierOnboardingStep = async (
    tierId: string,
    stepsData: ApiOnboardingSteps
  ): Promise<Tier> => {
    const response = await axiosClient(false).patch(`/tiers/${tierId}`, {
      tier: {
        onboarding: stepsData,
      },
    })
    const tierApi = response.data.entities?.tiers?.[response.data.result]
    return GroupService.normalizeTier(tierApi)
  }

  static fetchUserGroups = async (): Promise<Group[]> => {
    const response = await axiosClient().get('/scoped_users_groups', {
      params: { only_user_groups: true },
    })
    const { entities } = response.data
    return GroupService.normalizeScopedUsers(entities) as Group[]
  }

  static getClientGroups = async (
    page,
    pageSize,
    { keyword = '', ...params }
  ) => {
    const {
      data: { entities },
    } = await axiosClient().get(
      `/scoped_users_groups?page=${page}&per_page=${pageSize}`,
      {
        params: {
          [KEYWORD_FILTER]: keyword,
          [GROUP_TYPE_NOT_EQUAL_FILTER]: GroupTypes.FOUNDER,
          [ENTITY_TYPE_NOT_EQUAL_FILTER]: 'User',

          ...params,
        },
      }
    )
    return GroupService.normalizeScopedUsers(entities)
  }

  static getGroupsByEmails = async (emails: string[]) => {
    const requestBody = {
      q: {
        investor_group_email_or_group_email_in: emails,
      },
    }
    const {
      data: { entities },
    } = await axiosClient().post(`/scoped_users_groups`, requestBody)

    return GroupService.normalizeScopedUsers(entities) as Group[]
  }

  static getRecentUsers = async (
    page,
    pageSize,
    { keyword = '', ...params }
  ) => {
    const {
      data: { entities },
    } = await axiosClient().get(
      `/scoped_users_groups?page=${page}&per_page=${pageSize}`,
      {
        params: {
          [ENTITY_TYPE_EQUAL_FILTER]: 'User',
          [KEYWORD_FILTER]: keyword,
          ...params,
        },
      }
    )

    return GroupService.normalizeScopedUsers(entities)
  }

  static getGroupsAndUsers = async (
    page,
    pageSize,
    { keyword = '', ...params }
  ) => {
    const {
      data: { entities },
    } = await axiosClient().get(
      `/scoped_users_groups?page=${page}&per_page=${pageSize}`,
      {
        params: {
          [KEYWORD_FILTER]: keyword,
          ...params,
        },
      }
    )
    return GroupService.normalizeScopedUsers(entities)
  }

  static createGroup = async (groupName, image) => {
    const formData = new FormData()
    formData.append('group[name]', groupName)
    if (image) {
      formData.append('group[logo]', image)
    }
    const response = await axiosClient(true, {}, {}, true).post(
      '/groups/create_founder',
      formData
    )
    const groupId = response.data.result
    return response.data.entities?.groups[groupId] as Group
  }

  static createClientGroup = async (userId, groupName, image) => {
    const bodyFormData = new FormData()
    if (image) {
      bodyFormData.append('group[logo]', image)
    }
    bodyFormData.append('group[name]', groupName)

    const response = await axiosClient(true).post(
      `/users/${userId}/clients`,
      bodyFormData
    )
    return response.data.entities?.clients?.[response.data.result] as Group
  }

  static createFundManagerGroup = async (userId, groupName, image) => {
    const bodyFormData = new FormData()
    if (image) {
      bodyFormData.append('group[logo]', image)
    }
    bodyFormData.append('group[name]', groupName)

    const response = await axiosClient(true).post(
      `/users/${userId}/fund_managers`,
      bodyFormData
    )
    return response.data.entities?.fundManagers?.[response.data.result] as Group
  }

  static getGroupByHandle = async (handle) => {
    const { data } = await axiosClient().get(`/mixed_groups/${handle}`)
    const group = data?.mixedGroups

    return group
  }

  static fetchGroup = async (groupId: string): Promise<Group<GroupCompany>> => {
    const {
      data: { entities, result },
    } = await axiosClient().get(`/groups/${groupId}`)
    const group = entities?.groups?.[result]

    return {
      ...group,
      companyData: group.companyData?.map(
        (companyId) => entities.companyData?.[companyId]
      ),
    } as Group
  }

  static getCustomLoginData = (handle) => {
    return axiosClient().get(`/groups/${handle}/show_minimal`)
  }

  static getCurrentGroup = async (): Promise<{
    groups: Group[]
    userGroups: UserGroup[]
  }> => {
    const response = await axiosClient().get(`/group`)

    const {
      entities: { groups, userGroups },
    } = response.data

    return {
      groups,
      userGroups,
    }
  }

  static updateGroup = (body, formData = false) => {
    return axiosClient(formData).patch(`/group`, body)
  }

  static getCurrentInvestorGroup = async (
    investorGroupId: string
  ): Promise<{
    investorGroups: InvestorGroup[]
    investorGroupUsers: User[]
  }> => {
    const response = await axiosClient().get(
      `/investor_groups/${investorGroupId}`
    )

    const {
      entities: { investorGroups, investorGroupUsers },
    } = response.data

    return {
      investorGroups,
      investorGroupUsers,
    }
  }

  static setGroupImage = (image) => {
    const formData = new FormData()
    formData.set('group[logo]', image)
    return this.updateGroup(formData, true)
  }

  static getGroupUsers = async (searchTerm?: string, userIds?: string[]) => {
    const {
      data: {
        result,
        entities: { userGroups, users },
      },
    } = await axiosClient().get('/user_groups', {
      params: {
        [USER_ID_NOT_IN_FILTER]: userIds,
        [NAME_CONTAINS_FILTER]: searchTerm,
        page: 1,
        per_page: PER_PAGE,
      },
    })

    return result.map((userGroupId) => {
      const userGroup = userGroups[userGroupId]
      const user = users[userGroup.user]
      return {
        ...userGroup,
        user,
      }
    })
  }

  static getCurrentUsersGroup = async (userGroupId) => {
    const response = await axiosClient().get(`/user_groups/${userGroupId}`)
    return response.data.entities?.userGroups?.[response.data.result]
  }

  static getUserGroup = async ({
    userId,
    groupId,
    isInvestorGroup,
    includeSelectedMetrics,
  }: {
    userId: string
    groupId: string
    isInvestorGroup?: boolean
    includeSelectedMetrics?: boolean
  }): Promise<UserGroup> => {
    let response

    if (isInvestorGroup) {
      response = await axiosClient().get(
        `/investor_group_users?q[user_id_eq]=${userId}&q[investor_group_id_eq]=${groupId}`
      )
      return response.data.entities?.investorGroupUsers?.[response.data.result]
    }

    let url = `/user_groups?q[user_id_eq]=${userId}&q[group_id_eq]=${groupId}`

    if (includeSelectedMetrics) {
      url += '&selected_metric_ids=true'
    }

    response = await axiosClient().get(url)

    try {
      if (!response.data.entities?.userGroups?.[response.data.result]) {
        Sentry.captureEvent({
          message: 'User group not found',
          level: 'info',
          extra: {
            userId,
            groupId,
            userGroupsIds: Object.keys(
              response.data.entities?.userGroups || {}
            ),
            result: response?.data?.result,
          },
          tags: {
            bug: 'userGroupNotFound',
          },
        })
      }
    } catch (error) {
      // continue regardless of error
    }

    return response.data.entities?.userGroups?.[response.data.result]
  }

  static updateGroupUser = (
    userGroupId: string,
    data: SelectedMetricSettingsPayload | any,
    isInvestorGroup: boolean
  ) => {
    if (isInvestorGroup) {
      return axiosClient().patch(`/investor_group_users/${userGroupId}`, data)
    }

    return axiosClient().patch(`/user_groups/${userGroupId}`, data)
  }

  static getGroupUser = async (email) => {
    const response: any = await axiosClient().get('/users', {
      params: { email },
    })
    const userId = response.body?.data?.result
    return response.data?.entities?.users?.[userId] ?? null
  }

  static createGroupUser = async (email, role): Promise<UserGroup> => {
    let user = await this.getGroupUser(email)
    if (!user) {
      ;[user] = await UserService.bulkCreateUser({
        emails: [email],
      })
    }

    const {
      data: {
        result,
        entities: { userGroups },
      },
    } = await axiosClient().post('/user_groups/', {
      user_id: user.id,
      role,
    })

    return { ...userGroups[result], user }
  }

  static deleteUserGroupMember = (userGroupId, userToTransferId) => {
    return axiosClient().delete(`/user_groups/${userGroupId}`, {
      data: { userToTransferId },
    })
  }

  static resendInvite = (userGroupId) => {
    return axiosClient().patch(
      `/user_groups/${userGroupId}/resend_email_added_to_group`
    )
  }

  static getGroupStorage = async () => {
    const {
      data: { result: groupId, entities },
    } = await axiosClient().get<
      ApiShowResponse<{
        groups: { [id: string]: Group }
        users: {
          [id: string]: User
        }
      }>
    >('/group/storage')

    return {
      group: entities.groups[groupId],
      users: Object.values(entities.users),
    }
  }
}

export default GroupService
