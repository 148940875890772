import {
  DashboardGrid,
  DashboardItem,
  DashboardItemContent,
  DashboardRow,
} from './Dashboard.styles'
import DashboardItemTitle from './DashboardItemTitle/DashboardItemTitle'

const Dashboard = {
  Grid: DashboardGrid,
  Row: DashboardRow,
  Item: DashboardItem,
  ItemTitle: DashboardItemTitle,
  ItemContent: DashboardItemContent,
}

export default Dashboard
