import { LogType } from 'containers/Logs/models/types'
import { PortfolioApiTypes } from 'utils/types/api/updates'
import { Nullable } from 'utils/types/common'

export const PlainUpdateTypes = {
  QUICKBOOKS_REPORT: 'Logging::QuickbooksReport',
  XERO_REPORT: 'Logging::XeroReport',
  NOTE: 'Logging::Note',
  DOCUMENT: 'Logging::Document',
  TRANSACTION: 'Logging::Transaction',
  ANNOUNCEMENT: 'Logging::Announcement',
  IUE: 'Reporting::Report',
  REPORTING: 'Reporting::IUE::Report',
} as const

export const PlainSubjectMatters = {
  COMPANY: 'Business::CompanyDatum',
  FUND_PORTFOLIO: 'Portfolios::FundPortfolio',
  DEAL_PORTFOLIO: 'Portfolios::DealPortfolio',
  PORTFOLIO: 'Portfolios::Portfolio',
  INVEST_PORTFOLIO: 'Portfolios::InvestPortfolio',
  TRACK_PORTFOLIO: 'Portfolios::TrackPortfolio',
  INVESTOR: 'Investors::InvestorGroup',
} as const

export const PortfoliosTypeMap = {
  [PlainSubjectMatters.FUND_PORTFOLIO]: PortfolioApiTypes.FUND,
  [PlainSubjectMatters.DEAL_PORTFOLIO]: PortfolioApiTypes.DEAL,
  [PlainSubjectMatters.INVEST_PORTFOLIO]: PortfolioApiTypes.INVEST,
  [PlainSubjectMatters.TRACK_PORTFOLIO]: PortfolioApiTypes.TRACK,
} as const

export const PortfolioEntityForUrl = {
  [PlainSubjectMatters.FUND_PORTFOLIO]: 'fund',
  [PlainSubjectMatters.PORTFOLIO]: 'portfolio',
  [PlainSubjectMatters.INVEST_PORTFOLIO]: 'invest',
  [PlainSubjectMatters.TRACK_PORTFOLIO]: 'track',
} as const

export const UpdateUrl = {
  [PlainSubjectMatters.COMPANY]: 'companies',
  [PlainSubjectMatters.INVESTOR]: 'updates',
  [PlainSubjectMatters.FUND_PORTFOLIO]: 'portfolios',
  [PlainSubjectMatters.PORTFOLIO]: 'portfolios',
  [PlainSubjectMatters.INVEST_PORTFOLIO]: 'portfolios',
  [PlainSubjectMatters.TRACK_PORTFOLIO]: 'portfolios',
} as const

export const UpdateInvestorGroupUrl = {
  [PlainSubjectMatters.COMPANY]: 'fund-holdings/companies',
  [PlainSubjectMatters.FUND_PORTFOLIO]: 'funds',
} as const

export const UpdateTypes = {
  ACCOUNTING: 'accounting',
  NOTE: 'note',
  DOCUMENT: 'document',
  TRANSACTION: 'transaction',
  ANNOUNCEMENT: 'announcement',
  IUE: 'report',
} as const

export const SuggestedUpdateTypes = {
  ...UpdateTypes,
  SUGGESTED: 'suggested',
} as const

export enum UpdateTypeForUrl {
  ACCOUNTING = 'accounting',
  NOTE = 'notes',
  DOCUMENT = 'documents',
  TRANSACTION = 'transactions',
  ANNOUNCEMENT = 'announcements',
  IUE = 'emails',
}

export const enum UpdateTabId {
  GENERAL = 'general',
  CHAT = 'chat',
  RAW_DATA = 'rawData',
  ANALYTICS = 'analytics',
  LOGS = 'logs',
}

export const UpdateVisibility = {
  ONLY_ME: 'only_me',
  CUSTOM: 'custom',
  PUBLIC: 'everyone',
  CUSTOM_GROUP: 'custom_group',
} as const

export const MAX_TITLE_COUNTER_CHARACTERS = 190

export const UpdateEditStatus = {
  /**
   * The user has no edit rights (Either because it reached expiration date or just because it never had them).
   */
  NONE: 'none',

  /**
   * The user can edit the update without limitations (It does not expire).
   */
  FULL: 'full',

  /**
   * The user can edit everything but the own by group settings (It does not expire).
   */
  FULL_OWNED_BY_GROUP_DISABLED_NO_DRAFT: 'full_no_checkbox_no_draft',

  /**
   * The user can edit everything including the own by group settings until the expiration date.
   */
  TEMPORARY: 'temporary_including_checkbox',

  /**
   * The user can edit everything but the own by group settings until the expiration date.
   */
  TEMPORARY_OWNED_BY_GROUP_DISABLED: 'temporary_no_checkbox',

  /**
   * The user can only add recipients and make public but nothing else not even change the own by group settings since expiration date has been reached.
   */
  EXPIRED_OWNED_BY_GROUP_DISABLED:
    'expired_make_public_add_recipients_not_checkbox',

  /**
   * The user can only add recipients and make public and change the own by group settings but nothing else since expiration date has been reached.
   */
  EXPIRED: 'expired_make_public_add_recipients_set_checkbox',

  /**
   * The user has no edit permissions over the update, but he can reshare it.
   */
  ONLY_RESHARE_ALLOWED: 'only_reshare_allowed',
}

export const isSharableOutsideGroupDeprecated = (
  hasFundPortfolioAssociated,
  comingFromUpdates,
  comingFromCompanies,
  type
) => {
  if (comingFromUpdates || comingFromCompanies) return true

  const isDocumentOrNote =
    [UpdateTypes.DOCUMENT, UpdateTypes.NOTE].indexOf(type) !== -1

  if (hasFundPortfolioAssociated) return !isDocumentOrNote

  return true
}

export const parseUpdateType = (plainUpdateType) => {
  switch (plainUpdateType) {
    case PlainUpdateTypes.NOTE:
      return UpdateTypes.NOTE
    case PlainUpdateTypes.DOCUMENT:
      return UpdateTypes.DOCUMENT
    case PlainUpdateTypes.ANNOUNCEMENT:
      return UpdateTypes.ANNOUNCEMENT
    case PlainUpdateTypes.TRANSACTION:
      return UpdateTypes.TRANSACTION
    case PlainUpdateTypes.IUE:
    case PlainUpdateTypes.REPORTING:
      return UpdateTypes.IUE
    case PlainUpdateTypes.QUICKBOOKS_REPORT:
    case PlainUpdateTypes.XERO_REPORT:
      return UpdateTypes.ACCOUNTING
    default:
      throw new Error(`Unexpected update type ${plainUpdateType}`)
  }
}

export const parseEventLogType = (plainUpdateType): Nullable<LogType> => {
  switch (plainUpdateType) {
    case PlainUpdateTypes.NOTE:
      return LogType.NOTES

    case PlainUpdateTypes.DOCUMENT:
      return LogType.DOCUMENTS

    case PlainUpdateTypes.ANNOUNCEMENT:
      return LogType.ANNOUNCEMENTS

    case PlainUpdateTypes.TRANSACTION:
      return LogType.TRANSACTIONS

    case PlainUpdateTypes.QUICKBOOKS_REPORT:
      return LogType.QUICKBOOKS_REPORTS

    case PlainUpdateTypes.XERO_REPORT:
      return LogType.XERO_REPORTS

    default:
      return null
  }
}
