import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Tabs from 'components/Tabs'
import Card from 'components/Card'
import UpdateContent from './UpdateContent'
import styles from './UpdateLayout.module.scss'

const UpdateTabContent = ({
  children,
  tabs,
  fullContent,
  isCardExpanded,
  onClickTab,
  analyticsEnabled,
  showTitles,
}) => {
  return (
    <UpdateContent fullContent={fullContent} isCardExpanded={isCardExpanded}>
      <div className={styles.updateTabs}>
        <Card padding="1rem 2.1rem 2.4rem">
          <Tabs
            withBorder
            tabOffset={21}
            items={tabs.filter((tab) => {
              return tab.id !== 'analytics' || analyticsEnabled
            })}
            onClickTab={onClickTab}
            showTitles={showTitles}
          >
            {children}
          </Tabs>
        </Card>
      </div>
    </UpdateContent>
  )
}

UpdateTabContent.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]).isRequired,
  fullContent: PropTypes.bool,
  isCardExpanded: PropTypes.bool.isRequired,
  onClickTab: PropTypes.func,
  analyticsEnabled: PropTypes.bool,
  showTitles: PropTypes.bool,
}

UpdateTabContent.defaultProps = {
  tabs: [
    {
      id: 'general',
      title: <FormattedMessage id="updates.general" />,
      icon: ['far', 'newspaper'],
    },
    {
      id: 'analytics',
      title: <FormattedMessage id="updates.analytics" />,
      icon: ['far', 'chart-line'],
    },
    {
      id: 'logs',
      title: <FormattedMessage id="logs.title" />,
      icon: ['far', 'clipboard-list-check'],
    },
  ],
  onClickTab: () => {},
  fullContent: false,
  analyticsEnabled: false,
  showTitles: true,
}

export default UpdateTabContent
