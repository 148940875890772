/* eslint-disable class-methods-use-this */
import React from 'react'
import {
  type Compatible,
  type UncertainCompatible,
  type CellTemplate,
  type Uncertain,
} from '@silevis/reactgrid'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { CELL_ERROR, CustomCell } from 'components/Spreadsheet/types'
import { Holding } from 'utils/types/company'
import { SuggestionErrors } from 'components/AddHoldingModal/types'
import {
  getCompatibleTextCell,
  handleKeyDown,
} from 'components/Spreadsheet/utils'
import HoldingsService from 'api/HoldingsService'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import HoldingNameCellRender from './HoldingNameCellRender'
import { HoldingsSpreadsheetEvents } from '../../useAddHoldingsSpreadsheet'

type CustomError =
  | {
      type: 'REQUIRED'
      error: string
    }
  | {
      type: 'MAX_LENGTH'
      error: string
    }
  | {
      type: 'DUPLICATE_NAME'
      error: SuggestionErrors
    }

export interface HoldingNameCell extends CustomCell {
  type: 'holdingName'
  text: string
  placeholder?: string
  holding?: Holding
  customError?: CustomError
  holdingsToSuggest?: Holding[]
}

export class HoldingNameCellTemplate implements CellTemplate<HoldingNameCell> {
  getCompatibleCell(
    uncertainCell: Uncertain<HoldingNameCell>
  ): Compatible<HoldingNameCell> {
    return { ...getCompatibleTextCell(uncertainCell), type: 'holdingName' }
  }

  update(
    cell: Compatible<HoldingNameCell>,
    cellToMerge: UncertainCompatible<HoldingNameCell>
  ): Compatible<HoldingNameCell> {
    const holding =
      cellToMerge.holding?.name === cellToMerge.text
        ? cellToMerge.holding
        : undefined
    let customError: CustomError | undefined

    if (holding) {
      // Wait for cell text to change
      setTimeout(() => {
        dispatchEvent(HoldingsSpreadsheetEvents.HoldingSelectedFromDropdown, {
          holding,
        })
      }, 0)
    } else if (cellToMerge.text.length > MAX_COUNTER_CHARACTERS) {
      customError = {
        type: 'MAX_LENGTH',
        error: 'general.youHaveExceededCharacterLimit',
      }
    } else if (cellToMerge.text) {
      HoldingsService.getHoldings({
        page: 1,
        filters: {
          name: cellToMerge.text,
        },
        companiesPerPage: 10,
      }).then((response) => {
        const { holdings } = response
        const holdingsToSuggest = holdings.filter(
          (fetchedHolding) =>
            fetchedHolding.name.toLowerCase() === cellToMerge.text.toLowerCase()
        )

        if (holdingsToSuggest.length) {
          dispatchEvent(HoldingsSpreadsheetEvents.SuggestHoldingsWithSameName, {
            holdingsToSuggest,
            rowIndex: cell.rowIndex,
          })
        }
      })
    }

    return this.getCompatibleCell({
      ...cell,
      holding,
      customError,
      error: customError ? CELL_ERROR : '',
      holdingsToSuggest: [],
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder || cell.placeholder,
    })
  }

  handleKeyDown(
    cell: Compatible<HoldingNameCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<HoldingNameCell>; enableEditMode: boolean } {
    const { cell: cellResult, enableEditMode } = handleKeyDown(
      cell,
      keyCode,
      ctrl,
      shift,
      alt,
      this.getCompatibleCell
    )

    return {
      cell: {
        ...cellResult,
        type: 'holdingName',
      },
      enableEditMode,
    }
  }

  render(
    cell: Compatible<HoldingNameCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<HoldingNameCell>, commit: boolean) => void
  ): React.ReactNode {
    return (
      <HoldingNameCellRender
        cell={cell}
        isInEditMode={isInEditMode}
        onCellChanged={onCellChanged}
        getCompatibleCell={this.getCompatibleCell}
      />
    )
  }
}
