import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

interface StyledInvestorDetailsProps {
  isAvatarVisible?: boolean
}

export const StyledInvestorDetails = styled.div<StyledInvestorDetailsProps>`
  flex-direction: column;
  ${(props) =>
    props.isAvatarVisible &&
    `
    display: grid;
    grid-template-columns: 1fr 10fr;
  `}

  .description-socials {
    display: inline-flex;
  }

  .vehicles-financial {
    display: inline-flex;

    @media ${maxSize.xl} {
      flex-flow: wrap;
      gap: 2.4rem;
    }
  }

  gap: 2.4rem;
`
export const Information = styled.div<{ isDetailsScreen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  ${({ isDetailsScreen }) =>
    !isDetailsScreen &&
    css`
      padding-top: 1.1rem;
    `}
`
export const Description = styled.div``

export const NoInfoMessage = styled.span`
  color: ${color('lightGray')};
  font-size: 1.4rem;
  font-style: italic;
`
