import { useState, useCallback, useMemo, useReducer } from 'react'
import { SelectUpdatesContextType } from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import { MixedUpdate } from 'utils/types/update'
import { isSuggestedUpdate } from 'utils/functions/suggestedUpdates'

import { Action, selectableCardsReducer } from './useSelectUpdatesReducer'

const useSelectUpdates = (
  enabled: boolean,
  enabledForSuggestedUpdates: boolean
): SelectUpdatesContextType => {
  const [selectedCards, dispatch] = useReducer(selectableCardsReducer, {})

  const [currentHoverCardId, setCurrentHoverCardId] = useState<string>()

  const handleSelectCard = useCallback((update: MixedUpdate) => {
    dispatch({
      type: Action.SELECT_CARD,
      payload: update,
    })
  }, [])

  const handleUnSelectCard = useCallback((update: MixedUpdate) => {
    dispatch({
      type: Action.UNSELECT_CARD,
      payload: update,
    })
  }, [])

  const handleToggleCard = useCallback((update: MixedUpdate) => {
    dispatch({
      type: Action.TOGGLE_CARD,
      payload: update,
    })
  }, [])

  const handleHoverCard = useCallback((update: MixedUpdate) => {
    setCurrentHoverCardId(update.id)
  }, [])

  const handleUnHoverCard = useCallback(() => {
    setCurrentHoverCardId(undefined)
  }, [])

  const isCardSelected = useCallback(
    (update: MixedUpdate) => {
      return !!selectedCards[update.id]
    },
    [selectedCards]
  )
  const isCardHover = useCallback(
    (update: MixedUpdate) => {
      return update.id === currentHoverCardId
    },
    [currentHoverCardId]
  )

  const selectAllUpdates = useCallback((updates: MixedUpdate[]) => {
    dispatch({
      type: Action.SELECT_ALL,
      payload: updates,
    })
  }, [])

  const unSelectAllUpdates = useCallback(() => {
    dispatch({
      type: Action.UNSELECT_ALL,
    })
  }, [])

  const isSelectingSuggestedUpdates = useMemo(() => {
    return (
      enabledForSuggestedUpdates &&
      Object.values(selectedCards).some(isSuggestedUpdate)
    )
  }, [enabledForSuggestedUpdates, selectedCards])

  return useMemo(
    () => ({
      selectedCards,
      currentHoverCardId,
      handleToggleCard,
      handleSelectCard,
      handleUnSelectCard,
      selectAllUpdates,
      unSelectAllUpdates,
      handleHoverCard,
      handleUnHoverCard,
      isCardSelected,
      isCardHover,
      enabled,
      enabledForSuggestedUpdates,
      isSelectingSuggestedUpdates,
    }),
    [
      currentHoverCardId,
      handleHoverCard,
      handleSelectCard,
      handleToggleCard,
      handleUnHoverCard,
      handleUnSelectCard,
      selectAllUpdates,
      unSelectAllUpdates,
      selectedCards,
      isCardSelected,
      isCardHover,
      enabled,
      enabledForSuggestedUpdates,
      isSelectingSuggestedUpdates,
    ]
  )
}

export default useSelectUpdates
