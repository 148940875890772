import styled from 'styled-components/macro'
import Card from 'components/Card'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const InvestorGroupDashboardContainer = styled.div`
  div[class*='Card_cardWrapper']:not([class*='Card_fitContent']) {
    height: 100%;
  }

  div[class^='InvestPortfolioTable_tableContainer'] {
    > div {
      padding: 0;
    }
  }

  header[class*='CompanyProfileHeader_header'] {
    z-index: unset;
  }
`
export const Header = styled.header`
  margin-bottom: 2.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${maxSize.md} {
    flex-direction: column;
    align-items: flex-start;
    margin: 2rem;

    div[class*='container'],
    div[class*='Control'] {
      width: 100%;
      margin-top: 1rem;
    }

    div[class*='option'] {
      width: 100%;
    }
  }
`
export const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const PortfolioWrapper = styled.div`
  margin: 0;
  div[class*='Card_cardWrapper'] {
    padding-bottom: 3.1rem;
  }
`

export const ChartWrapper = styled.div`
  margin-top: 0rem;
`

export const CardWrapper = styled(Card)`
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const FormattedSelectedOption = styled.div`
  font-size: 1.4rem;
  width: 15rem;
  margin-top: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${color('gray')};
`
