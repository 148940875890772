import Modal from 'components/Modal'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledModal = styled(Modal)`
  max-height: 95vh;
`
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 61.1rem;

  h3 {
    font-size: 1.8rem;
    color: #444444;
    font-weight: 400;
    margin-top: 2.5rem;
  }

  p.instructions-two {
    font-size: 1.4rem;
    font-weight: 500;
    color: ${color('darkGray')};
    margin-top: 0.9rem;
    width: 48rem;
    text-align: center;
    @media (max-width: 768px) {
      width: auto;
    }
  }
`
export const Wrapper = styled.div<{ isDraggingOver?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.1rem dashed #787e8c;
  padding: 8rem 10rem 11rem 10rem;
  margin: 2.4rem;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  ${(props) =>
    props.isDraggingOver &&
    css`
      background: var(--color-blue-light);
    `}
`

export const HelpMessage = styled.p`
  font-weight: bold;
  font-size: 1.4rem;
  font-weight: 500;
  color: #787e8c;
  margin-top: 0.9rem;

  > svg {
    margin-right: 0.5rem;
  }

  a {
    color: #787e8c;
  }
`

export const BulkImportModalWrapper = styled.div`
  div[class*='overlay'] {
    height: 100%;
    border-radius: 0.8rem;
  }
`

export const ErrorMessage = styled.p`
  font-size: 1.4rem;
  color: ${color('red')};
  max-width: 46rem;
  margin-top: -1.2rem;
  margin-bottom: 1.2rem;
  text-align: center;
`
