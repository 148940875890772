import { useCallback } from 'react'
import { InvestorRoutes, RoutesType } from 'reducers/breadcrumbSlice'
import InvestorDashboardBreadcrumb from '../InvestorDashboardBreadcrumb'
import InvestorProfileBreadcrumb from '../InvestorProfileBreadcrumb'
import FundHoldingsBreadcrumb from '../FundHoldingsBreadcrumb'
import useBreadcrumbNavigation from '../../useBreadcrumbNavigation'
import useShowInvestorInitialLink from '../useShowInvestorInitialLink'

const investorBreadcrumb = {
  dashboard: InvestorDashboardBreadcrumb,
  investorProfile: InvestorProfileBreadcrumb,
  holdings: FundHoldingsBreadcrumb,
}

interface Props {
  baseRoute: RoutesType
}

const useInvestorBreadcrumb = ({ baseRoute }: Props) => {
  const {
    entityToShow,
    isCreatingNewUpdate,
    isShowingCompany,
    initialLinkRedirect,
    update,
    url,
    investmentOptionSelected,
    isManipulatingUpdate,
    getAvatar,
  } = useBreadcrumbNavigation()

  const { showInitialLink } = useShowInvestorInitialLink()

  const navigationComponentFunc = useCallback(() => {
    switch (baseRoute) {
      case InvestorRoutes.INVESTMENTS:
        return investorBreadcrumb.dashboard({
          entityToShow,
          getAvatar,
          isCreatingNewUpdate,
          isShowingCompany,
          initialLinkRedirect,
          update,
          showInitialLink,
          investmentOptionSelected,
          isManipulatingUpdate,
          url,
        })
      case InvestorRoutes.INVESTOR_PROFILE:
        return investorBreadcrumb.investorProfile({
          isCreatingNewUpdate,
          update,
          url,
          entityToShow,
          isShowingCompany,
          initialLinkRedirect,
          showInitialLink,
          getAvatar,
        })
      case InvestorRoutes.HOLDINGS:
        return investorBreadcrumb.holdings({
          entityToShow,
          getAvatar,
          isCreatingNewUpdate,
          isShowingCompany,
          initialLinkRedirect,
          showInitialLink,
          update,
          url,
        })
      default:
        return null
    }
  }, [
    baseRoute,
    entityToShow,
    isManipulatingUpdate,
    isCreatingNewUpdate,
    isShowingCompany,
    initialLinkRedirect,
    update,
    url,
    investmentOptionSelected,
    showInitialLink,
    getAvatar,
  ])

  return {
    navigationComponentFunc,
  }
}

export default useInvestorBreadcrumb
