import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from 'utils/theme'
import Tooltip from 'components/Tooltip'
import { ShareWithOption } from '../ShareWithDropdown/useShareWithDropdown'
import {
  getImageComponent,
  IconsTypes,
} from '../ShareWithDropdown/components/Icons'
import { UnreachableEmailTooltip } from './ShareWithEntity'
import * as Styles from './ShareWithEntities.styles'

interface ListProps {
  name: string
  recipients: ShareWithOption[]
  onRemove: () => void
  onRecipientRemove: (recipientId: string) => void
  selectedIds: Set<string>
}

const List: React.FC<ListProps> = ({
  name,
  recipients,
  onRemove,
  onRecipientRemove,
  selectedIds,
}) => {
  const [isRecipientListOpen, setIsRecipientListOpen] = React.useState(false)

  const handleRecipientListToggle = () => {
    setIsRecipientListOpen(
      (currIsRecipientListOpen) => !currIsRecipientListOpen
    )
  }

  return (
    <>
      <Styles.Container data-testid="shared-with-list">
        <Styles.NameContainer>
          {recipients.length > 0 && (
            <Styles.ListIcon onClick={handleRecipientListToggle}>
              <FontAwesomeIcon
                icon={[
                  'far',
                  isRecipientListOpen ? 'chevron-down' : 'chevron-right',
                ]}
                color={theme.colors.darkGray}
                data-testid="shared-with-list-toggle"
              />
            </Styles.ListIcon>
          )}
          <div>{getImageComponent(IconsTypes.list)}</div>
          <div>{name}</div>
        </Styles.NameContainer>

        <Styles.RightSideContainer>
          <Styles.SecondaryText>
            <FormattedMessage id="updates.canView" />
          </Styles.SecondaryText>
          <Styles.RemoveIconContainer>
            <FontAwesomeIcon
              icon={['far', 'times']}
              color={theme.colors.darkBlue}
              onClick={onRemove}
              data-testid="shared-with-list-remove-all"
            />
          </Styles.RemoveIconContainer>
        </Styles.RightSideContainer>
      </Styles.Container>
      {isRecipientListOpen &&
        recipients.map((recipient) => {
          const alreadyAdded = selectedIds.has(recipient.id)

          return (
            <Styles.RecipientContainer
              key={recipient.name}
              alreadyAdded={alreadyAdded}
              data-testid="shared-with-list-recipient"
            >
              <Styles.NameContainer>
                {getImageComponent(
                  recipient.type,
                  recipient.name,
                  recipient.imageUrl
                )}

                <div>{recipient.name}</div>
              </Styles.NameContainer>
              <Styles.RightSideContainer>
                {alreadyAdded && (
                  <Tooltip
                    id={`alreadyAdded_${recipient.id}`}
                    text={
                      <FormattedMessage id="updates.alreadyAddedRecipient" />
                    }
                    arrowColor="transparent"
                    place="right"
                  >
                    <FontAwesomeIcon
                      icon={['far', 'exclamation-triangle']}
                      color={theme.colors.yellow}
                    />
                  </Tooltip>
                )}
                {!alreadyAdded && recipient.unreachableEmail && (
                  <UnreachableEmailTooltip name={name} />
                )}
                <Styles.RemoveIconContainer>
                  <FontAwesomeIcon
                    icon={['far', 'times']}
                    color={theme.colors.darkBlue}
                    onClick={() => onRecipientRemove(recipient.id)}
                    data-testid="shared-with-list-remove-recipient"
                  />
                </Styles.RemoveIconContainer>
              </Styles.RightSideContainer>
            </Styles.RecipientContainer>
          )
        })}
    </>
  )
}

export default List
