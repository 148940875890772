import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 7rem;
  margin-top: ${(props) => props.marginTop};

  .inner,
  .outer {
    animation-name: spin-cw-circles;
    animation-duration: 1200ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transform-origin: center;
  }

  .inner {
    animation-duration: 3000ms;
    animation-direction: reverse;
  }

  @keyframes spin-cw-circles {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  svg {
    width: ${(props) => props.logoSize};
    path {
      fill: ${(props) => props.color};
    }
  }
`
export const Text = styled.div`
  color: ${(props) =>
    props.darkText ? color('darkBlue')(props) : color('lightGray')(props)};
  font-weight: ${(props) => props.darkText && 'bold'};
  font-size: 1.4rem;
  ${(props) =>
    props.column
      ? css`
          margin-top: 1.4rem;
        `
      : css`
          margin-left: 0.9rem;
        `}
`
