import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { PDFObject } from 'react-pdfobject'
import UpdateAttachmentsPreview from 'components/UpdateAttachmentsPreview'
import { useShowUpdate } from 'containers/Updates/ShowUpdate/useShowUpdate'
import { UpdateTypes } from 'utils/constants/updates'

import {
  displayFileSize,
  isIndexFileContent,
  isPreviewAvailable,
} from 'utils/functions/files'
import { useDownloadFile } from 'utils/hooks/files/useDownloadFile'
import { FileExtension } from 'utils/types/files'
import { Update } from 'utils/types/update'
import {
  UpdateWithItem,
  getUpdateFormValues,
  getUpdateItemText,
  getUpdateItemTitle,
} from 'utils/functions/updates'
import { useIsPreviewingDraftUpdate } from 'utils/hooks/useIsPreviewingDraftUpdate'
import {
  DownloadAllButton,
  PDFWrapper,
  StyledIconButton,
} from './ShowUpdateContent.styles'

interface Props {
  update: Update
}

const Attachments = ({ update }: Props) => {
  const { getAttachmentZipUrl, isGeneratingAttachments } = useShowUpdate()
  const { onDownloadFile } = useDownloadFile()
  const isPreviewingDraft = useIsPreviewingDraftUpdate()

  const file = update.contents?.[0]

  const updateValues = update?.item
    ? getUpdateFormValues({
        update: update as UpdateWithItem,
        getPublishedValues: !isPreviewingDraft,
      })
    : {
        date: update.date,
        title: getUpdateItemTitle(update),
        body: getUpdateItemText(update),
        files: update.contents,
        updateId: update.id,
        updateType: update.type,
        tags: update.tags,
      }

  const isFileContent = isIndexFileContent(file)
  const isDocumentType = update.updateType === UpdateTypes.DOCUMENT
  const { contents } = update

  const showPdfPreview =
    isFileContent &&
    update.updateType === UpdateTypes.DOCUMENT &&
    file?.fileFormat === FileExtension.PDF &&
    isPreviewAvailable(file)

  const attachmentsSize = useMemo(() => {
    return contents.reduce((totalSize, attachment) => {
      if (isIndexFileContent(attachment)) {
        return totalSize + attachment.fileSize
      }

      return totalSize
    }, 0)
  }, [contents])

  return (
    <>
      {showPdfPreview ? (
        <PDFWrapper>
          <StyledIconButton
            id="download-button"
            icon="download"
            iconFamily="fas"
            onClick={() => onDownloadFile(file)}
          />
          <PDFObject url={file.previewUrl || file.url} assumptionMode />
        </PDFWrapper>
      ) : (
        <UpdateAttachmentsPreview
          isDocumentType={isDocumentType}
          contents={updateValues.files}
          mode={isDocumentType ? 'list' : 'grid'}
          canDelete={false}
        />
      )}

      {update.contents.length > 1 && (
        <DownloadAllButton
          icon={['fal', 'download']}
          secondary
          rounded
          isLoading={isGeneratingAttachments}
          onClick={getAttachmentZipUrl}
        >
          <FormattedMessage
            id="updates.downloadAll"
            values={{ filesSize: displayFileSize(attachmentsSize) }}
          />
        </DownloadAllButton>
      )}
    </>
  )
}

export default Attachments
