import React from 'react'
import { useIntl } from 'react-intl'
import type { Compatible } from '@silevis/reactgrid'
import { CustomTextCell } from 'components/Spreadsheet/types'
import CellErrorTooltip from './CellErrorTooltip/CellErrorTooltip'
import * as Styles from '../Template.styles'

interface TextCellDisplayValueProps {
  cell: Compatible<CustomTextCell>
}

const TextCellDisplayValue: React.FC<TextCellDisplayValueProps> = ({
  cell,
}) => {
  const intl = useIntl()

  return (
    <CellErrorTooltip
      content={cell.error ? intl.formatMessage({ id: cell.error }) : null}
    >
      <Styles.TextCellValue
        hasError={!!cell.error}
        autoFilled={!!cell.autoFilled}
        isPlaceholder={!cell.text}
        isHeaderCell={!cell.nonEditable}
      >
        {cell.text || cell.placeholder}
      </Styles.TextCellValue>
    </CellErrorTooltip>
  )
}

export default TextCellDisplayValue
