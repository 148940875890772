import styled from 'styled-components'

export const StyledShowInvestor = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  .table-header-label {
    display: inline-flex;
    flex-basis: min-content;

    &-container {
      display: flex;

      svg {
        margin: auto;
        margin-left: 1rem;
      }
    }
  }
`

export const StyledShowInvestorBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 0 3.2rem;
`

export const StyledShowInvestorLoading = styled.div`
  margin-top: 30rem;
`
