/* eslint-disable react-hooks/exhaustive-deps, react/no-danger */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import EmailService from 'api/EmailService'
import Toast from 'components/Toast'
import EmailPreviewLoader from 'components/loaders/emailPreviewLoader'
import SectionPreview from './SectionPreview'

import styles from './EmailPreview.module.scss'

const EmailPreview = ({
  emailId,
  onChangeSection,
  onChangeSectionName,
  createAssetToEmail,
  readOnly,
  emailStructureSections,
  onAddEntryToSection,
  handleRemoveSection,
  onRemoveEntryFromSection,
  onSelectColor,
  onRemoveSectionTitle,
  onChangeImageSize,
  onChangeShowTitle,
  onChangeTextAlign,
}) => {
  const intl = useIntl()
  const [reportEntries, setReportEntries] = useState([])

  const [loading, setLoading] = useState(false)

  const getEmailPreview = useCallback(async () => {
    if (!emailId) {
      return
    }
    setLoading(true)
    try {
      const response = await EmailService.getReportEntries(emailId)

      setReportEntries(
        Object.values(response?.data?.entities?.reportEntries ?? {})
      )
    } catch (err) {
      Toast.display(intl.messages['createEmail.getPreview'], 'error')
    } finally {
      setLoading(false)
    }
  }, [emailId, intl.messages, readOnly])

  useEffect(() => {
    if (!emailStructureSections) {
      getEmailPreview()
    }
  }, [getEmailPreview])

  const emailSections = emailStructureSections || reportEntries

  return loading ? (
    <EmailPreviewLoader />
  ) : (
    <div
      className={classNames(styles.emailPreviewWrapper, {
        [styles.emailPreviewFrame]: !readOnly,
      })}
    >
      <div className={styles.emailPreview}>
        {readOnly ? (
          reportEntries.map((section, index) => (
            <SectionPreview
              key={section.id}
              section={section}
              onChangeSectionName={onChangeSectionName}
              onChangeSection={onChangeSection}
              createAssetToEmail={createAssetToEmail}
              onAddEntryToSection={onAddEntryToSection}
              readOnly
              index={index}
            />
          ))
        ) : (
          <Droppable droppableId="emailStructure">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={classNames(styles.dropContainer, {
                  [styles.draggingOver]: snapshot.isDraggingOver,
                })}
              >
                {emailSections.map((section, index) => (
                  <Draggable
                    draggableId={`section_preview_${section.id}`}
                    index={index}
                    key={`section_preview_${section.id}`}
                  >
                    {(draggableProvided) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                      >
                        <SectionPreview
                          section={section}
                          index={index}
                          onChangeSectionName={onChangeSectionName}
                          onChangeSection={onChangeSection}
                          createAssetToEmail={createAssetToEmail}
                          onAddEntryToSection={onAddEntryToSection}
                          handleRemoveSection={handleRemoveSection}
                          onRemoveEntryFromSection={onRemoveEntryFromSection}
                          onRemoveSectionTitle={onRemoveSectionTitle}
                          onSelectColor={(color) =>
                            onSelectColor(section, color)
                          }
                          onChangeImageSize={onChangeImageSize}
                          onChangeShowTitle={onChangeShowTitle}
                          onChangeTextAlign={onChangeTextAlign}
                        />

                        {draggableProvided.placeholder}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
    </div>
  )
}

EmailPreview.propTypes = {
  emailId: PropTypes.string,
  onChangeSection: PropTypes.func,
  onChangeSectionName: PropTypes.func,
  handleRemoveSection: PropTypes.func,
  createAssetToEmail: PropTypes.func,
  onAddEntryToSection: PropTypes.func,
  onRemoveEntryFromSection: PropTypes.func,
  onRemoveSectionTitle: PropTypes.func,
  onChangeImageSize: PropTypes.func,
  readOnly: PropTypes.bool,
  emailStructureSections: PropTypes.array,
  onSelectColor: PropTypes.func,
  onChangeShowTitle: PropTypes.func,
  onChangeTextAlign: PropTypes.func,
}

EmailPreview.defaultProps = {
  emailId: '',
  readOnly: false,
  emailStructureSections: null,
  createAssetToEmail: () => {},
  onAddEntryToSection: () => {},
  handleRemoveSection: () => {},
  onChangeSectionName: () => {},
  onChangeSection: () => {},
  onRemoveEntryFromSection: () => {},
  onRemoveSectionTitle: () => {},
  onChangeImageSize: () => {},
  onChangeShowTitle: () => {},
  onChangeTextAlign: () => {},
  onSelectColor: () => {},
}

export default EmailPreview
