import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { IconsContainer, StyledInput } from 'ui/Input/Input.styles'

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;

  font-size: 1.4rem;
  height: 5rem;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;
`

export const GlobeIcon = styled.div`
  svg {
    width: 2rem;
    height: 1.7rem;
  }
`

export const SecondaryText = styled.span`
  color: ${color('lightGray')};
  white-space: nowrap;
`

export const UserDetailText = styled.span`
  font-size: 1.2rem;
  color: ${color('lightGray')};
  padding-left: 0.3rem;
`

export const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`

export const RemoveIconContainer = styled.div`
  cursor: pointer;
  user-select: none;
  opacity: 1;
`

export const DropdownContainer = styled.div<{ disabled: boolean }>`
  ${StyledInput} {
    padding: 0.8rem;
    width: 10rem;
    color: ${color('gray')};
    font-weight: 700;
  }

  ${IconsContainer} {
    right: 0.9rem;

    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
      `}
  }
`

export const ListIcon = styled.div`
  cursor: pointer;

  svg {
    height: 1.4rem;
    width: 1.4rem;
  }
`

export const RecipientContainer = styled(Container)<{ alreadyAdded: boolean }>`
  height: 4.3rem;
  margin-left: 3.4rem;

  ${({ alreadyAdded }) =>
    alreadyAdded &&
    css`
      opacity: 0.5;
    `}
`
