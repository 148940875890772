import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { Nullable } from 'utils/types/common'

interface Location {
  pathname: Nullable<string>
  state: Nullable<string>
}

interface RoutesState {
  location: Location
}

const initialState: RoutesState = {
  location: {
    pathname: null,
    state: null,
  },
}

export const routesSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setLastLocation: (state, action: PayloadAction<Location>) => {
      const { pathname, state: actionState } = action.payload
      state.location = { pathname, state: actionState }
      return state
    },
  },
})

export const { setLastLocation } = routesSlice.actions

export const getLastLocation = (state: RootState) => state.routes.location

export default routesSlice.reducer
