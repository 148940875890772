import { useCallback, useState } from 'react'

import { MixedContent } from 'utils/types/files'
import { useEditFileContentMutation } from '../contents/useContentsQuery'

export const usePreviewContents = (onPreviewContentCallback?: () => void) => {
  const [selectedContent, setSelectedContent] = useState<MixedContent>()

  const onClosePreview = useCallback(() => {
    setSelectedContent(undefined)
  }, [])

  const onPreviewContent = useCallback(
    (content: MixedContent) => {
      onPreviewContentCallback?.()
      setSelectedContent(content)
    },
    [onPreviewContentCallback]
  )

  const onGroupManagedStatusChange = useEditFileContentMutation()

  const onGroupManagedStatusChangeHandler = (
    contentId: string,
    isGroupManaged: boolean
  ) => {
    onGroupManagedStatusChange.mutateAsync({
      contentId,
      fileContent: {
        groupManaged: isGroupManaged,
      },
    })
  }

  return {
    selectedContent,
    onPreviewContent,
    onClosePreview,
    onGroupManagedStatusChangeHandler,
  }
}
