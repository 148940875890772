import { AllFundsChartDataWithPercentages } from 'utils/functions/portfolios'
import Tooltip from 'components/Tooltip'
import { LegendIndicator, PercentageRow } from './Chart.styles'

interface RowsInfo {
  name: string
  color: string
}

interface Colors {
  [key: string]: string
}

interface Props {
  chartData: AllFundsChartDataWithPercentages[]
  colorsForCompanies: Colors
}

const InvestPortfolioChartResume = ({
  chartData,
  colorsForCompanies,
}: Props) => {
  const rowsInfo: RowsInfo[] = chartData.map(({ id, name }) => ({
    name,
    color: colorsForCompanies?.[id ?? 'other'],
  }))

  return (
    <div>
      {rowsInfo.map(({ name, color }) => (
        <PercentageRow key={name}>
          <LegendIndicator backgroundColor={color} />
          <Tooltip
            id={`invest_portfolios_portfolio_${name}`}
            text={name}
            place="top"
            delayShow={1000}
            widthAuto
          >
            {name}
          </Tooltip>
        </PercentageRow>
      ))}
    </div>
  )
}

export default InvestPortfolioChartResume
