import { FormattedMessage } from 'react-intl'
import HiddenValue from 'ui/HiddenValue'
import {
  FinancialInfo,
  Title,
  VisibleContent,
} from './FinancialInformation.styles'

interface Props {
  isReadOnly?: boolean
  titleId: string
  value?: string
}

const FinancialItem = ({ isReadOnly, titleId, value }: Props) => {
  return value ? (
    <FinancialInfo>
      <Title>
        <FormattedMessage id={titleId} />
      </Title>
      {isReadOnly && <HiddenValue iconSize="2x" value={value} />}
      {!isReadOnly && <VisibleContent>{value}</VisibleContent>}
    </FinancialInfo>
  ) : null
}

export default FinancialItem
