import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { CREATE_NEW_GROUP_URL_PREFIX } from 'containers/Signup/context/CreateNewGroupContextProvider'
import { resetCreateNewGroup } from 'features/createNewGroupSlice'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { useWindowResize } from 'utils/hooks/windowResize'
import * as Styles from './CreateNewGroupButton.styles'

const APROX_GROUP_HEIGHT = 72

const getContainerHeight = (groupsLength: number) => {
  return groupsLength * APROX_GROUP_HEIGHT + APROX_GROUP_HEIGHT
}

interface CreateNewGroupButtonProps {
  containerRef?: React.RefObject<HTMLDivElement>
  groupsLength?: number
  toggleGroupSelector?: () => void
}

const CreateNewGroupButton: React.FC<CreateNewGroupButtonProps> = ({
  containerRef,
  groupsLength,
  toggleGroupSelector,
}) => {
  const [sticky, setSticky] = useState<boolean>(
    containerRef?.current?.scrollHeight && groupsLength
      ? getContainerHeight(groupsLength) > containerRef.current.scrollHeight
      : false
  )
  const history = useHistory()
  const dispatch = useAppDispatch()

  const onClick = () => {
    dispatch(resetCreateNewGroup())
    history.push(CREATE_NEW_GROUP_URL_PREFIX)
    toggleGroupSelector?.()
  }

  const checkForOverflow = useCallback(() => {
    if (containerRef?.current) {
      const { scrollHeight, offsetHeight } = containerRef.current

      if (offsetHeight) {
        if (offsetHeight < scrollHeight) {
          setSticky(true)
        } else {
          setSticky(false)
        }
      }
    }
  }, [containerRef])

  useLayoutEffect(() => {
    checkForOverflow()
  }, [
    containerRef?.current?.scrollHeight,
    containerRef?.current?.offsetHeight,
    checkForOverflow,
  ])

  useWindowResize(checkForOverflow)

  return (
    <Styles.Container onClick={onClick} sticky={sticky}>
      <Styles.PlusContainer>
        <FontAwesomeIcon icon={['far', 'plus']} />
      </Styles.PlusContainer>
      <FormattedMessage id="createNewGroup.createNewGroup" />
    </Styles.Container>
  )
}

export default CreateNewGroupButton
