import { HorizontalScroll } from 'App.styles'
import React from 'react'
import styled from 'styled-components'
import { useEventListener } from 'utils/hooks/useEventListener'
import { useTableContext } from './TableContextProvider'

const FakeScrollContent = styled.div<{ width?: number }>`
  width: ${(props) => props.width}px;
`

const FakeScrollBarWrapper = styled.div<{ leftOffset?: number }>`
  position: sticky;
  bottom: 1rem;
  left: 100%;
  width: 100%;
  height: 1.6rem;
  z-index: 4;
  background: white;
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 1rem 0 0 white;
`

const FakeScrollBarContainer = styled.div<{ leftOffset?: number }>`
  ${HorizontalScroll(false)}
  width: calc(100% - ${(props) => props.leftOffset}px);
  height: 1.6rem;
  overflow: auto;
`

const FakeScrollBar = () => {
  const { tableLayoutHelpers } = useTableContext()

  const scrollableRowContainer: HTMLElement | null = document.getElementById(
    'cell-container-scrollable'
  )

  const fixedRowContainer: HTMLElement | null = document.getElementById(
    'cell-container-fixed'
  )

  useEventListener(
    'scroll',
    (_, event) => {
      tableLayoutHelpers.listContainerRef.current?.scroll({
        left: event.target.scrollLeft,
      })
    },
    tableLayoutHelpers.fakeScrollRef.current
  )

  useEventListener(
    'scroll',
    (_, event) => {
      tableLayoutHelpers.fakeScrollRef.current?.scroll({
        left: event.target.scrollLeft,
      })
    },
    tableLayoutHelpers.listContainerRef.current
  )

  const scrollTableWidth =
    scrollableRowContainer?.getBoundingClientRect().width ??
    fixedRowContainer?.getBoundingClientRect().width ??
    0

  const leftOffset =
    tableLayoutHelpers.fixedListContainerRef.current?.getBoundingClientRect()
      .width ?? 0

  return (
    <FakeScrollBarWrapper>
      <FakeScrollBarContainer
        ref={
          tableLayoutHelpers.fakeScrollRef as React.RefObject<HTMLDivElement>
        }
        leftOffset={leftOffset}
      >
        <FakeScrollContent width={scrollTableWidth} />
      </FakeScrollBarContainer>
    </FakeScrollBarWrapper>
  )
}

export default FakeScrollBar
