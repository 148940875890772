import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ConfirmationHeader = styled.div`
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;

  svg {
    margin-right: 0.8rem;
    color: ${color('red')};
    width: 1.6rem;
    height: 1.6rem;
  }
`
