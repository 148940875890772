import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const BarContainer = styled.div`
  width: 100%;
  height: 4.8rem;
  background: ${color('primaryBlue')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2rem 1rem;
  color: white;
  font-size: 1.4rem;

  button {
    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  @media ${maxSize.md} {
    height: initial;
    padding: 0.8rem 1rem;
  }
`
