import styled, { css } from 'styled-components'
import Drawer from 'components/Drawer'
import ShowMoreLessToStyle from 'components/ShowMoreLess'
import { color } from 'utils/functions/colors'
import { UpdateTextContainer } from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import UnreconciledPillToStyle from '../components/UnreconciledPill'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
`

export const UnreconciledPill = styled(UnreconciledPillToStyle)`
  && {
    position: relative;
    bottom: initial;
    right: initial;
    width: fit-content;
  }
`

export const TextContainer = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('gray')};
  white-space: break-spaces;
  word-break: break-word;

  ${UpdateTextContainer} {
    margin-top: 0;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
`

export const ActionsSeparator = styled.div`
  border-left: 0.1rem solid ${color('veryLightGray')};
  height: 2rem;
  width: 0;
`

export const SectionTitle = styled.span`
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${color('gray')};
`

export const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-bottom: 0.5rem;
`

export const ShowMoreLessButton = styled.button`
  height: 4rem;
  width: 100%;
  background-color: ${color('lightGray', 0.1)};
  border-radius: 0.8rem;
  outline: none;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  color: ${color('lightGray')};

  &:hover {
    box-shadow: inset 0 0 0 0.2rem ${color('veryLightBlue')};
  }

  &:focus {
    outline: none;
  }
`

export const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.8rem;
  margin-bottom: 4rem;
`

export const DetailsItem = styled.div<{ dark?: boolean }>`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('lightGray')};
  min-width: 0;
  overflow-wrap: anywhere;

  ${({ dark }) =>
    dark &&
    css`
      color: ${color('darkBlue')};
    `}
`

export const Email = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const headerHeight = '5.8rem'

export const DrawerContent = styled(Drawer.Content)`
  height: calc(100vh - ${headerHeight});

  &:empty {
    display: block;
  }
`

export const ShowMoreLess = styled(ShowMoreLessToStyle)`
  padding-left: initial;
`
