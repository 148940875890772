import EmployeesList from 'components/EmployeesList'
import { FieldArray } from 'formik'
import type { ArrayHelpers } from 'formik'

import { Employee } from 'utils/types/company'
import { useIntl } from 'react-intl'
import { randomId } from 'utils/functions/number'
import DragAndDropApi from 'utils/functions/dragAndDropApi'
import { useFormikContext } from 'components/Form/hooks'
import { TeamMembersFormValues } from 'utils/types/investors'

const TeamMembersList = () => {
  const { values, setFieldValue, getFieldName } =
    useFormikContext<TeamMembersFormValues>()
  const intl = useIntl()
  const employeesFieldName = getFieldName('employees')

  const reorderEmployees = (sourceIndex: number, destinationIndex: number) => {
    const sortedEmployees = DragAndDropApi.reorder(
      values.employees,
      sourceIndex,
      destinationIndex
    )

    setFieldValue('employees', sortedEmployees)
  }

  const onDeleteEmployee =
    (arrayHelpers: ArrayHelpers) => (employee: Employee) => {
      const employeeIndex = values.employees.findIndex(
        (emp) => emp.id === employee.id
      )
      if (employeeIndex > -1) {
        arrayHelpers.remove(employeeIndex)
      }
    }

  const onAddEmployee =
    (arrayHelpers: ArrayHelpers) => (employee: Employee) => {
      arrayHelpers.push({ ...employee, id: randomId() })
    }

  const onEditEmployee =
    (arrayHelpers: ArrayHelpers) =>
    (employeeId: string, newEmployee: Employee) => {
      const employeeIndex = values.employees.findIndex(
        (emp) => emp.id === employeeId
      )
      if (employeeIndex > -1) {
        arrayHelpers.replace(employeeIndex, newEmployee)
      }
    }

  return (
    <FieldArray
      name={employeesFieldName}
      render={(arrayHelpers) => (
        <EmployeesList
          label={intl.formatMessage({ id: 'investorManagement.keyPeople' })}
          buttonProps={{
            add: true,
            link: true,
          }}
          employees={values.employees}
          onReorderEmployees={reorderEmployees}
          onDeleteEmployee={onDeleteEmployee(arrayHelpers)}
          onAddNewTeamMember={onAddEmployee(arrayHelpers)}
          onEditTeamMember={onEditEmployee(arrayHelpers)}
        />
      )}
    />
  )
}

export default TeamMembersList
