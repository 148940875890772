import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { getIsReadOnlyMode, isActingAsClient } from 'selectors/auth'
import CWLoader from 'components/CWLoader'
import { isUniversityActionEnabled } from 'utils/functions/university'
import UniversityAction from './UniversityAction'
import {
  UniversityWrapper,
  UniversityContainer,
  Header,
  Title,
  Label,
  Content,
  LoaderContainer,
} from './UniversitySidebar.styles'
import { useUniversity } from './useUniversity'

const UniversitySidebar = ({ isUniversityOpen, closeUniversity }) => {
  const [activeAction, setActiveAction] = useState()
  const isReadOnlyMode = useAppSelector(getIsReadOnlyMode)
  const intl = useIntl()
  const {
    allActions,
    pendingActions,
    completedByMeActions,
    completedByGroupActions,
    loading,
    onStartAction,
    onRedoAction,
    onSkipAction,
  } = useUniversity({
    isUniversityOpen,
    closeUniversity,
  })

  const isClient = useAppSelector(isActingAsClient)
  if (loading) {
    return (
      <UniversityWrapper>
        <UniversityContainer isOpen={isUniversityOpen}>
          <Header>
            <button type="button" onClick={closeUniversity}>
              <FontAwesomeIcon icon={['far', 'times']} />
            </button>
          </Header>
          <LoaderContainer>
            <CWLoader />
          </LoaderContainer>
        </UniversityContainer>
      </UniversityWrapper>
    )
  }

  const onSetActiveAction = (action) => {
    if (action.id === activeAction) {
      setActiveAction(null)
    } else {
      setActiveAction(action.id)
    }
  }

  return (
    <UniversityWrapper>
      <UniversityContainer isOpen={isUniversityOpen}>
        <Header>
          <button type="button" onClick={closeUniversity}>
            <FontAwesomeIcon icon={['far', 'times']} />
          </button>
          <Title>
            <h1>{intl.messages['university.title']}</h1>
            <p>{intl.messages['university.subTitle']}</p>
          </Title>
        </Header>

        <Content>
          {pendingActions.map((action) => (
            <UniversityAction
              key={action.id}
              actionId={action.id}
              isActiveAction={action.id === activeAction}
              setActiveAction={() => onSetActiveAction(action)}
              text={intl.messages[`university.actionDescriptions.${action.id}`]}
              title={intl.messages[`university.actionTitles.${action.id}`]}
              handleStart={() => onStartAction(action)}
              skipEnabled={action.isOptional}
              handleSkip={() => onSkipAction(action)}
              isEnabled={isUniversityActionEnabled(
                action,
                allActions,
                isReadOnlyMode
              )}
              disabledText={
                isReadOnlyMode
                  ? intl.messages['readOnlyMode.universityDisabled']
                  : action.disabledText
              }
              guideUrl={action.guideUrl}
              runTourEnabled={!isClient}
            />
          ))}

          {completedByMeActions.length > 0 && (
            <Label>{intl.messages['university.completedByYou']}</Label>
          )}
          {completedByMeActions.map((action) => (
            <UniversityAction
              key={action.id}
              actionId={action.id}
              isActiveAction={action.id === activeAction}
              setActiveAction={() => onSetActiveAction(action)}
              text={intl.messages[`university.actionDescriptions.${action.id}`]}
              title={intl.messages[`university.actionTitles.${action.id}`]}
              handleStart={() => onRedoAction(action)}
              completed
              runTourEnabled={!isClient}
            />
          ))}

          {completedByGroupActions.length > 0 && (
            <Label>{intl.messages['university.completedByGroup']}</Label>
          )}
          {completedByGroupActions.map((action) => (
            <UniversityAction
              key={action.id}
              actionId={action.id}
              isActiveAction={action.id === activeAction}
              setActiveAction={() => onSetActiveAction(action)}
              title={intl.messages[`university.actionTitles.${action.id}`]}
              text={intl.messages[`university.actionDescriptions.${action.id}`]}
              handleStart={() => onRedoAction(action)}
              completed
              redoLabel={intl.messages['university.tryItMyself']}
              runTourEnabled={!isClient}
            />
          ))}
        </Content>
      </UniversityContainer>
    </UniversityWrapper>
  )
}

UniversitySidebar.propTypes = {
  isUniversityOpen: PropTypes.bool.isRequired,
  closeUniversity: PropTypes.func.isRequired,
}

export default UniversitySidebar
