import {
  type Compatible,
  type Uncertain,
  getCharFromKeyCode,
  isAlphaNumericKey,
  keyCodes,
  getCellProperty,
  isNavigationKey,
} from '@silevis/reactgrid'
import { type IntlShape } from 'react-intl'
import {
  Option,
  StaticDropdownCell,
} from 'components/Spreadsheet/CellTemplates/CustomStaticDropdownCellTemplate'
import {
  CustomCell,
  CustomDateCell,
  CustomNumberCell,
  CustomTextCell,
} from './types'

/**
 * Cell (and row) height for cells that have only one line.
 */
export const DEFAULT_CELL_HEIGHT = 33

/**
 * Default cell width, although it changes a lot depending on the cell type.
 */
export const DEFAULT_CELL_WIDTH = 180

export type BaseColumn = {
  index: number
  type: string
  label: string
  placeholder?: string
  width?: number
}

export type RowNumberColumn = BaseColumn & {
  type: 'rowNumber'
}

export type TextColumn = BaseColumn & {
  type: 'text'
}

export type NumberColumn = BaseColumn & {
  type: 'number'
}

export type PercentageColumn = BaseColumn & {
  type: 'percentage'
}

export type DateColumn = BaseColumn & {
  type: 'date'
}

export type OptionColumn = BaseColumn & {
  type: 'option'
  getOptions: (intl: IntlShape) => Option[]
}

export type BooleanColumn = BaseColumn & {
  type: 'boolean'
  getOptions: (intl: IntlShape) => Option[]
}

export const SpreadsheetBorders = {
  NoRightBorder: {
    right: {
      width: '0',
    },
  },

  NoLeftBorder: {
    left: {
      width: '0',
    },
  },

  NoTopBorder: {
    top: {
      width: '0',
    },
  },

  NoBottomBorder: {
    bottom: {
      width: '0',
    },
  },

  ThickRightBorder: {
    right: {
      width: '2px',
    },
  },

  ThickBottomBorder: {
    bottom: {
      width: '2px',
    },
  },
}

export const getNumberCell = ({
  rowIndex,
  optional,
}: {
  rowIndex: number
  optional?: boolean
}): CustomNumberCell => ({
  type: 'number',
  value: NaN,
  optional,
  rowIndex,
  style: {
    border: {
      ...SpreadsheetBorders.NoLeftBorder,
      ...SpreadsheetBorders.NoRightBorder,
      ...SpreadsheetBorders.NoTopBorder,
    },
  },
})

export const getDateCell = ({
  rowIndex,
  date,
  optional,
}: {
  rowIndex: number
  date?: Date
  optional?: boolean
}): CustomDateCell => ({
  type: 'date',
  date,
  optional,
  rowIndex,
  style: {
    border: {
      ...SpreadsheetBorders.NoLeftBorder,
      ...SpreadsheetBorders.NoRightBorder,
      ...SpreadsheetBorders.NoTopBorder,
    },
  },
})

export const getStaticDropdownCell = ({
  rowIndex,
  options,
  optional,
}: {
  rowIndex: number
  options: Option[]
  optional?: boolean
}): StaticDropdownCell => ({
  type: 'staticDropdown',
  options,
  optional,
  rowIndex,
  style: {
    border: {
      ...SpreadsheetBorders.NoLeftBorder,
      ...SpreadsheetBorders.NoRightBorder,
      ...SpreadsheetBorders.NoTopBorder,
      ...SpreadsheetBorders.NoBottomBorder,
    },
  },
})

export const getNumberCellValue = <GridType,>(
  grid: GridType,
  rowId: number,
  columnId: number
) => {
  const cell = grid[rowId][columnId] as CustomNumberCell
  return cell.value !== undefined && !Number.isNaN(cell.value) && !cell.disabled
    ? cell.value.toString()
    : undefined
}

export const getDateCellValue = <GridType,>(
  grid: GridType,
  rowId: number,
  columnId: number
) => {
  const cell = grid[rowId][columnId] as CustomDateCell
  return cell.date && !cell.disabled ? cell.date.toString() : undefined
}

export const getTextCellValue = <GridType,>(
  grid: GridType,
  rowId: number,
  columnId: number
) => {
  const cell = grid[rowId][columnId] as CustomTextCell
  return cell.text && !cell.disabled ? cell.text : undefined
}

export const getOptionCellValue = <GridType,>(
  grid: GridType,
  rowId: number,
  columnId: number
) => {
  const cell = grid[rowId][columnId] as StaticDropdownCell

  return cell.error || cell.disabled ? undefined : cell.option?.value
}

type CellWithText = CustomCell & {
  text: string
  placeholder?: string
  validator?: (text: string) => boolean
  errorMessage?: string
}

/*
 * Default getCompatibleTextCell for text based cells
 */
export const getCompatibleTextCell = (
  uncertainCell: Uncertain<CellWithText>
): Compatible<CellWithText> => {
  const text = getCellProperty(uncertainCell, 'text', 'string')
  let placeholder: string | undefined
  try {
    placeholder = getCellProperty(uncertainCell, 'placeholder', 'string')
  } catch {
    placeholder = ''
  }
  const value = parseFloat(text)
  return {
    ...uncertainCell,
    text,
    value,
    placeholder,
    rowIndex: uncertainCell.rowIndex || 0,
  }
}

/*
 * Default handleKeyDown for text based cells
 */
export const handleKeyDown = (
  cell: Compatible<CellWithText>,
  keyCode: number,
  ctrl: boolean,
  shift: boolean,
  alt: boolean,
  getCompatibleCell: (cell: Uncertain<CellWithText>) => Compatible<CellWithText>
): { cell: Compatible<CellWithText>; enableEditMode: boolean } => {
  const char = getCharFromKeyCode(keyCode, shift)

  if (
    !ctrl &&
    !alt &&
    isAlphaNumericKey(keyCode) &&
    !(shift && keyCode === keyCodes.SPACE)
  ) {
    return {
      cell: getCompatibleCell({
        ...cell,
        text: shift ? char : char.toLowerCase(),
      }),
      enableEditMode: true,
    }
  }

  return {
    cell,
    enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
  }
}

/*
 * Default render for text based cells
 */
export const defaultTextCellRender = (
  cell: Compatible<CellWithText>,
  isInEditMode: boolean,
  onCellChanged: (cell: Compatible<CellWithText>, commit: boolean) => void,
  getCompatibleCell: (cell: Uncertain<CellWithText>) => Compatible<CellWithText>
): React.ReactNode => {
  if (!isInEditMode) {
    return cell.text || cell.placeholder || ''
  }

  return (
    <input
      ref={(input) => {
        if (input) {
          input.focus()
          input.setSelectionRange(input.value.length, input.value.length)
        }
      }}
      defaultValue={cell.text}
      onChange={(e) =>
        onCellChanged(
          getCompatibleCell({ ...cell, text: e.currentTarget.value }),
          false
        )
      }
      onBlur={(e) =>
        onCellChanged(
          getCompatibleCell({ ...cell, text: e.currentTarget.value }),
          (e as any).view?.event?.keyCode !== keyCodes.ESCAPE
        )
      }
      onCopy={(e) => e.stopPropagation()}
      onCut={(e) => e.stopPropagation()}
      onPaste={(e) => e.stopPropagation()}
      onPointerDown={(e) => e.stopPropagation()}
      placeholder={cell.placeholder}
      onKeyDown={(e) => {
        if (isAlphaNumericKey(e.keyCode) || isNavigationKey(e.keyCode))
          e.stopPropagation()
      }}
    />
  )
}
