import React from 'react'

import { HoldingType } from 'utils/types/company'
import CompanyAttachment from './CompanyAttachment'
import FundProfileAttachment from './FundProfileAttachment'

export interface ChatHoldingTopic {
  id: string
  name: string
  image: string
  link: string
  type: HoldingType
}

interface HoldingAttachmentProps {
  holdingId: string
  link: string
  type: HoldingType
}

const HoldingAttachment: React.FC<HoldingAttachmentProps> = ({
  holdingId,
  link,
  type,
}) => {
  if (type === HoldingType.COMPANY) {
    return <CompanyAttachment companyId={holdingId} link={link} />
  }

  return <FundProfileAttachment fundProfileId={holdingId} link={link} />
}

export default HoldingAttachment
