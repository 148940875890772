import { useLocation, useParams } from 'react-router-dom'
import { getUpdateTypeFromUrl } from 'utils/functions/updates'
import { useFetchUpdateQuery } from 'utils/hooks/queries/useUpdateQuery'
import { useIntl } from 'react-intl'
import { Update } from 'utils/types/update'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'

import { getCurrentCompany } from 'selectors/company'
import { getCurrentPortfolio } from 'selectors/portfolio'
import {
  useResharesByToken,
  useResharesWithMeOrMyGroup,
} from 'utils/hooks/reshares'

const useShowUpdate = <T extends Update>() => {
  const intl = useIntl()
  const location = useLocation<{ canGoBack: boolean }>()
  const { updateId } = useParams<{ updateId: string }>()
  const updateType = getUpdateTypeFromUrl(location.pathname)

  const currentPortfolio = useAppSelector(getCurrentPortfolio)
  const currentCompany = useAppSelector(getCurrentCompany)

  const entityName =
    currentCompany.name ||
    currentPortfolio?.name ||
    intl.formatMessage({ id: 'metaTitles.portfolio' })

  const { data: update, isLoading } = useFetchUpdateQuery<T>({
    updateId,
    updateType,
  })

  const resharesByToken = useResharesByToken(update)
  const resharesWithMeOrMyGroup = useResharesWithMeOrMyGroup(update)

  const updatePermissions = useUpdatePermissions(update)

  return {
    updateType,
    isLoading,
    resharesByToken,
    resharesWithMeOrMyGroup,
    update,
    updateId,
    updatePermissions,
    entityName,
  }
}

export default useShowUpdate
