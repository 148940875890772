import styled, { css } from 'styled-components'
import Card from 'components/Card'

const UpdateCardBox = styled(Card)<{ shrink?: boolean; noPadding?: boolean }>`
  padding: ${(props) => !props.noPadding && '2.4rem 3.2rem 3.2rem'};
  margin-bottom: 1.8rem;
  height: fit-content;
  gap: 1rem;

  ${(props) =>
    props.shrink &&
    css`
      display: flex;
      padding: 2rem;
      justify-content: center;
    `}
`

export default UpdateCardBox
