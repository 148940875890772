export const useMessageTimestamp = (
  createdAtDate: string | Date,
  showOnlyTime: boolean
) => {
  const createdAt =
    createdAtDate instanceof Date ? createdAtDate : new Date(createdAtDate!)

  const isCreationDateToday =
    createdAt.toDateString() === new Date().toDateString()
  const format = isCreationDateToday || showOnlyTime ? 'h:mma' : 'dddd h:mma'

  const secondsSinceSentMessage = createdAt
    ? (Date.now() - createdAt.getTime()) / 1000
    : 0
  const isJustNow = secondsSinceSentMessage < 10

  return {
    isCreationDateToday,
    isJustNow,
    format,
  }
}
