import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import OptionsDropdown from 'ui/OptionsDropdown'
import { DraftDotsMenuContainer } from './DraftDotsMenu.styles'

const DraftDotsMenu = ({ update, onDeleteIUEDraft }) => {
  const history = useHistory()
  const intl = useIntl()

  return (
    <DraftDotsMenuContainer>
      <OptionsDropdown
        buttonType="square"
        buttonSize="3.2rem"
        dropdownContentMinWidth="15rem"
      >
        <OptionsDropdown.Item
          label={<FormattedMessage id="emailList.editDraft" />}
          icon={['far', 'pen']}
          onSelectOption={() => {
            const path = history.location?.pathname
            history.push(`${path}/${update.id}/draft`)
          }}
        />
        <OptionsDropdown.Item
          label={intl.messages['emailList.deleteDraft']}
          icon={['far', 'trash-alt']}
          color="#f94863"
          onSelectOption={onDeleteIUEDraft}
        />
      </OptionsDropdown>
    </DraftDotsMenuContainer>
  )
}

DraftDotsMenu.propTypes = {
  update: PropTypes.object.isRequired,
  onDeleteIUEDraft: PropTypes.func.isRequired,
}

export default DraftDotsMenu
