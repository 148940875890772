import { FormattedMessage, useIntl } from 'react-intl'
import Toggle from 'components/Toggle'
import Tooltip from 'components/Tooltip'
import { EmailContentDetails } from 'utils/types/files'

import { GroupManagedWrapper, TooltipContent } from './GroupManaged.styles'
import { useGroupManaged } from './useGroupManaged'

interface GroupManagedProps {
  content: EmailContentDetails
}

const GroupManaged = ({ content }: GroupManagedProps) => {
  const intl = useIntl()
  const { onChangeGroupManaged, canModifyGroupManaged } =
    useGroupManaged(content)

  if (!content.unreconciled) {
    return null
  }

  return (
    <GroupManagedWrapper>
      <Tooltip
        place="bottom"
        offset={4}
        text={
          <TooltipContent>
            <FormattedMessage id="files.groupManagedTooltip" />
          </TooltipContent>
        }
      >
        <Toggle
          blue
          id="group-managed"
          name="group-managed"
          status={content.groupManaged}
          onChangeStatus={onChangeGroupManaged}
          legend={intl.formatMessage({
            id: 'files.groupManaged',
          })}
          disableAnimationOnMount
          disabled={!canModifyGroupManaged}
        />
      </Tooltip>
    </GroupManagedWrapper>
  )
}

export default GroupManaged
