import { useEffect, useMemo, useState } from 'react'
import { useInboundsQuery } from 'containers/Inbounds/hooks'
import { getFirstUncompletedOnboardingStep } from 'utils/functions/tier'
import { useGroupTierQuery } from 'utils/hooks/queries/useGroupQuery'
import useQueryString from 'utils/hooks/useQueryString'
import { useOnboardingStepMutation } from './useOnboardingStepMutation'
import { useFirstPipeline } from './hooks/useFirstPipeline'

export enum OnboardingStep {
  SETUP_YOUR_PIPELINE = 1,
  ADD_YOUR_FILES = 2,
  SETUP_YOUR_INBOUND = 3,
}

export const useOnboardingModal = () => {
  const showOnboarding = useQueryString('onboarding')
  const [show, setShow] = useState(!!showOnboarding)
  const { tier, isLoading } = useGroupTierQuery()
  const [currentStep, setStep] = useState<OnboardingStep>()
  const [completedSteps, setCompletedSteps] = useState<
    Record<OnboardingStep, boolean>
  >({
    [OnboardingStep.SETUP_YOUR_PIPELINE]: true,
    [OnboardingStep.ADD_YOUR_FILES]: false,
    [OnboardingStep.SETUP_YOUR_INBOUND]: false,
  })
  const onboardingStepMutation = useOnboardingStepMutation()

  useEffect(() => {
    if (tier?.onboarding) {
      const stepState = {
        [OnboardingStep.SETUP_YOUR_PIPELINE]: tier.onboarding.track,
        [OnboardingStep.ADD_YOUR_FILES]: tier.onboarding.files,
        [OnboardingStep.SETUP_YOUR_INBOUND]: tier.onboarding.inbound,
      }
      setCompletedSteps(stepState)
      setStep(getFirstUncompletedOnboardingStep(tier.onboarding))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tier?.id])

  // Prefetch inbound data
  useInboundsQuery()
  const { firstPipeline } = useFirstPipeline()

  const onHide = () => setShow(false)
  const showModal = () => setShow(true)

  const goBack = () => {
    if (!currentStep || currentStep === OnboardingStep.SETUP_YOUR_PIPELINE) {
      setStep(undefined)
    } else {
      setStep(currentStep - 1)
    }
  }

  const jumpToStep = (step: OnboardingStep) => {
    onboardingStepMutation.mutate(completedSteps)
    setStep(step)
  }

  const completeStep = (step: OnboardingStep) => {
    setCompletedSteps((prev) => {
      const newCompletedSteps = {
        ...prev,
        [step]: true,
      }
      onboardingStepMutation.mutate(newCompletedSteps)
      return newCompletedSteps
    })
  }

  const showOnboardingModal = useMemo(() => {
    return !isLoading && Object.values(completedSteps).some((step) => !step)
  }, [completedSteps, isLoading])

  return {
    show: show && !isLoading,
    showModal,
    currentStep,
    completedSteps,
    completeStep,
    onHide,
    jumpToStep,
    goBack,
    showOnboardingModal,
    firstPipeline,
  }
}
