import React, { ReactNode } from 'react'

import Tooltip, { PlacesType } from 'components/Tooltip'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isCompanyPrivate } from 'selectors/company'
import { isCompanyHolding, CompanyHolding } from 'utils/types/company'
import {
  Legend,
  PendingValidationBadge,
  PrivateCompanyBadge,
  TooltipWrapper,
} from './CompanyStatus.styles'

interface CompanyStatusProps {
  company: CompanyHolding
  displayLastUpdate?: boolean
  tooltipPlace?: PlacesType
  isFundPortfolio?: boolean
  isDealPortfolio?: boolean
  statusMargin?: string
}

const CompanyStatus: React.FC<CompanyStatusProps> = ({
  company,
  displayLastUpdate = false,
  isFundPortfolio,
  tooltipPlace,
  isDealPortfolio,
  ...rest
}) => {
  let qualityAssuredValue

  if (isCompanyHolding(company)) {
    const { qualityAssured } = company

    qualityAssuredValue = qualityAssured
  }

  const intl = useIntl()
  const isPrivate = useAppSelector(isCompanyPrivate(company))

  let label: ReactNode
  let tooltipText: string = ''

  if (!qualityAssuredValue && !isFundPortfolio && !isDealPortfolio) {
    tooltipText = intl.formatMessage({
      id: 'companyList.pendingValidationTooltip',
    })
    label = (
      <PendingValidationBadge>
        <FormattedMessage id="companyList.pendingValidation" />
      </PendingValidationBadge>
    )
  }

  if (isPrivate) {
    tooltipText = intl.formatMessage({
      id: 'companyList.privateCompanyTooltip',
    })
    label = (
      <PrivateCompanyBadge>
        <FormattedMessage id="companyList.privateCompany" />
      </PrivateCompanyBadge>
    )
  }

  return label ? (
    <Legend {...rest}>
      <TooltipWrapper>
        <Tooltip
          id={company.id}
          text={tooltipText}
          place={tooltipPlace || 'bottom'}
          delayShow={500}
          event="mouseover mouseenter"
          eventOff="mouseleave mouseout scroll mousewheel blur"
        >
          {label}
        </Tooltip>
      </TooltipWrapper>
    </Legend>
  ) : null
}

export default CompanyStatus
