import styled from 'styled-components'
import IconButton from 'ui/IconButton'
import Button from 'ui/Button'

import { color } from 'utils/functions/colors'

export const Row = styled.div<{
  justify?: string
  align?: string
  gap?: string
  marginTop?: string
}>`
  display: flex;
  margin-top: ${(props) => props.marginTop || '0'};
  justify-content: ${(props) => props.justify || 'space-between'};
  align-items: ${(props) => props.align || 'flex-start'};
  flex-wrap: wrap;
  gap: ${(props) => props.gap || '0'};

  h4 {
    margin-top: 2rem;
  }

  div[class^='FileGridPreview_imgContainer_'] {
    height: 12.8rem;
    border-radius: 0.8rem;
  }
`

export const Spacer = styled.div<{ margin: string }>`
  height: ${(props) => props.margin || '3.2rem'};
`

export const DownloadAllButton = styled(Button)`
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 2rem;
  text-transform: capitalize;

  svg {
    font-size: 1.5rem;
    margin-right: 9px;
  }
`

export const Content = styled.div`
  padding: 1rem 0.8rem;

  #pdfobject {
    width: 100%;
    height: 60vh;
    margin-top: 3rem;
  }
`

export const PDFWrapper = styled.div`
  width: 100%;
  position: relative;
`
export const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 9.6rem;
  top: 3.9rem;
  background: #323639;
  color: white;
  border: none;
  width: 3.7rem;
  height: 3.7rem;
  z-index: 99;
  cursor: pointer;
  svg {
    font-size: 1.6rem;
  }

  &:hover {
    background: #46484b;
    svg {
      color: white;
    }
  }

  @media screen and (min-resolution: 51dpi) and (max-resolution: 67dpi) {
    right: 14.6rem;
    top: 4.7rem;
    width: 5rem;
    height: 5rem;
  }

  @media screen and (min-resolution: 68dpi) and (max-resolution: 75dpi) {
    right: 12.6rem;
    top: 4.2rem;
    width: 5rem;
    height: 5rem;
  }

  @media screen and (min-resolution: 76dpi) and (max-resolution: 80dpi) {
    right: 11.6rem;
    top: 4.2rem;
    width: 4.7rem;
    height: 4.7rem;
  }

  @media screen and (min-resolution: 81dpi) and (max-resolution: 90dpi) {
    right: 10.6rem;
    top: 4.1rem;
    width: 4rem;
    height: 4rem;
  }

  @media screen and (min-resolution: 101dpi) and (max-resolution: 110dpi) {
    right: 8.6rem;
    top: 3.7rem;
  }

  @media screen and (min-resolution: 111dpi) and (max-resolution: 125dpi) {
    right: 7.6rem;
    top: 3.7rem;
    width: 3rem;
    height: 3rem;

    svg {
      font-size: 1rem;
    }
  }

  @media screen and (min-resolution: 125dpi) and (max-resolution: 150dpi) {
    right: 6.3rem;
    top: 3.4rem;
    width: 3rem;
    height: 3rem;

    svg {
      font-size: 1rem;
    }
  }

  display: none;
`

export const EmailPreviewWrapper = styled.div`
  border: 0.1rem solid #e9e9e9;
  border-radius: 0.8rem;
  width: 100%;
  overflow: hidden;
  margin-top: 2rem;

  a {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;

    span {
      color: -webkit-link;
      cursor: pointer;
      text-decoration: underline;
    }
  }
`

export const UpdateHistoryContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  color: ${color('lightGray')};
  margin-bottom: 2.4rem;
  font-size: 1.4rem;

  > span {
    font-weight: 700;
  }
`

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const ColumnTitle = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  color: ${color('darkGray')};
`
