import styled from 'styled-components/macro'
import AvatarUnstyled from 'components/Avatar'
import { color } from 'utils/functions/colors'

const OVERLAP = '-0.6rem'

export const Container = styled.div`
  display: flex;
`

export const AvatarContainer = styled.div`
  margin-left: ${OVERLAP};
  position: relative;

  :first-child {
    margin-left: 0;
  }
`

export const Avatar = styled(AvatarUnstyled)<{ avatarSize?: string }>`
  height: ${(props) => props.avatarSize || '2.1rem'};
  width: ${(props) => props.avatarSize || '2.1rem'};
`

export const RemainingAvatarsCount = styled.div`
  margin-left: ${OVERLAP};
  height: 2.1rem;
  min-width: 2.1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.5rem;
  background-color: ${color('primaryBlue')};
  color: ${color('white')};
  font-size: 1rem;
  font-weight: 700;
  padding-right: 0.6rem;
  padding-left: 0.4rem;
`

export const AvatarRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-size: 1.2rem;
  font-weight: 700;
  padding-bottom: 0.8rem;

  :last-child {
    padding-bottom: 0;
  }
`
