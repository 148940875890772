export default {
  move({ source, destination, sourceList, destinationList }) {
    const newSource = Array.from(sourceList)
    const newDestination = Array.from(destinationList)
    const [removed] = newSource.splice(source.index, 1)

    const newEmailStructureSection = {
      ...removed,
      entries: [],
    }

    newDestination.splice(destination.index, 0, newEmailStructureSection)

    return { newSource, newDestination }
  },
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  },
}
