/* eslint-disable react-hooks/exhaustive-deps, no-param-reassign */
import { useRef, useState, useEffect, useCallback } from 'react'

export default function useOnScreen(ref, rootMargin = '0px', rootElement) {
  const [onScreen, setOnScreen] = useState(false)
  const observer = useRef<IntersectionObserver>()
  const isObservingRef = useRef(false)

  const observe = useCallback(
    (node: HTMLDivElement) => {
      if (!ref.current && !isObservingRef.current) {
        ref.current = node

        if (!rootElement || rootElement.current !== null) {
          observer.current = new IntersectionObserver(
            ([entry]) => {
              setOnScreen(entry.isIntersecting)
            },
            {
              rootMargin,
              root: rootElement?.current,
            }
          )
          if (ref.current) {
            isObservingRef.current = true
            observer.current.observe(ref.current)
          }
        }
      }
    },
    [ref.current]
  )

  useEffect(() => {
    return () => {
      if (ref.current) {
        observer.current?.unobserve(ref.current)
        isObservingRef.current = false
        ref.current = undefined
      }
    }
  }, [])

  return { observe, onScreen }
}
