/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { FormattedMessage, FormattedNumber } from 'react-intl'

import {
  FMVTooltipValue,
  FMVTooltipContainer,
  FMVTooltipLabel,
  FMVTooltipRow,
  Separator,
} from './FMVHistoryTooltip.styles'

const FMVHistoryTooltip = ({
  currentFmv,
  currentFmvDate,
  lastFmv,
  lastFmvDate,
}) => {
  return (
    <FMVTooltipContainer>
      <FMVTooltipRow border>
        <FMVTooltipLabel>
          <FormattedMessage id="portfolioDetail.currentFmv" />
        </FMVTooltipLabel>
        <FMVTooltipValue>
          <FormattedNumber
            value={currentFmv || 0}
            style="currency"
            currency="USD"
            currencyDisplay="narrowSymbol"
            minimumFractionDigits={0}
            maximumFractionDigits={0}
          />{' '}
          {dayjs(currentFmvDate).format('(MMM DD, YYYY)')}
        </FMVTooltipValue>
      </FMVTooltipRow>
      <Separator />
      <FMVTooltipRow>
        <FMVTooltipLabel>
          <FormattedMessage id="portfolioDetail.lastFmv" />
        </FMVTooltipLabel>
        <FMVTooltipValue>
          {lastFmv ? (
            <FormattedNumber
              value={lastFmv}
              style="currency"
              currency="USD"
              currencyDisplay="narrowSymbol"
              minimumFractionDigits={0}
              maximumFractionDigits={0}
            />
          ) : (
            'N/A'
          )}{' '}
          {lastFmvDate ? dayjs(lastFmvDate).format('(MMM DD, YYYY)') : null}
        </FMVTooltipValue>
      </FMVTooltipRow>
    </FMVTooltipContainer>
  )
}

FMVHistoryTooltip.propTypes = {
  currentFmv: PropTypes.number.isRequired,
  currentFmvDate: PropTypes.string.isRequired,
  lastFmv: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  lastFmvDate: PropTypes.string,
}

FMVHistoryTooltip.defaultProps = {
  lastFmv: null,
  lastFmvDate: null,
}

export default FMVHistoryTooltip
