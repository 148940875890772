import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'

import { BillingAccount } from 'utils/types/signUp'
import { GroupType } from 'utils/types/user'
import {
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import { GroupTypes } from 'utils/constants/groups'

interface Subscription {
  clientSecret?: string
  customerId?: string
  subscriptionId?: string
}

interface CreateNewGroupState {
  planId?: SubscriptionPlan
  billingPeriod?: SubscriptionBillingPeriod
  billingAccount?: BillingAccount
  subscription?: Subscription
  groupType: GroupType
  isTrial?: boolean
}

const initialState: CreateNewGroupState = {
  groupType: GroupTypes.CLIENT,
  isTrial: true,
}

export const createNewGroupSlice = createSlice({
  name: 'createNewGroup',
  initialState,
  reducers: {
    setPlanId: (state, action: PayloadAction<SubscriptionPlan>) => {
      state.planId = action.payload
    },
    setBillingPeriod: (
      state,
      action: PayloadAction<SubscriptionBillingPeriod>
    ) => {
      state.billingPeriod = action.payload
    },
    setIsTrial: (state, action: PayloadAction<boolean>) => {
      state.isTrial = action.payload
    },
    setBillingAccount: (state, action: PayloadAction<BillingAccount>) => {
      state.billingAccount = action.payload
    },
    setSubscription: (state, action: PayloadAction<Subscription>) => {
      state.subscription = action.payload
    },
    setGroupType: (state, action: PayloadAction<GroupType>) => {
      state.groupType = action.payload
    },
    resetCreateNewGroup: () => initialState,
  },
})

export const {
  setPlanId,
  setBillingPeriod,
  setBillingAccount,
  setSubscription,
  setGroupType,
  resetCreateNewGroup,
} = createNewGroupSlice.actions

export const getPlanId = (state: RootState): SubscriptionPlan | undefined =>
  state.createNewGroup.planId

export const getBillingPeriod = (
  state: RootState
): SubscriptionBillingPeriod | undefined => state.createNewGroup.billingPeriod

export const getIsTrial = (state: RootState): boolean | undefined =>
  state.createNewGroup.isTrial

export const getBillingAccount = (
  state: RootState
): BillingAccount | undefined => state.createNewGroup.billingAccount

export const getSubscription = (state: RootState): Subscription | undefined =>
  state.createNewGroup.subscription

export const getGroupType = (state: RootState): GroupType | undefined =>
  state.createNewGroup.groupType

export default createNewGroupSlice.reducer
