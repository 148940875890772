import { createContext, useContext, useMemo } from 'react'
import { ReactFCWithChildren } from 'utils/types/common'

type ChatSidebarContextType = {
  showSearchView: () => void
  hideSearchView: () => void
  showArchiveSidebar: () => void
  hideArchiveSidebar: () => void
  isArchiveSidebarActive: boolean
}

const ChatSidebarContext = createContext<ChatSidebarContextType>(
  {} as ChatSidebarContextType
)

export const useChatSidebarContext = () => useContext(ChatSidebarContext)

export const ChatSidebarContextProvider: ReactFCWithChildren<
  ChatSidebarContextType
> = ({
  showSearchView,
  showArchiveSidebar,
  hideArchiveSidebar,
  hideSearchView,
  isArchiveSidebarActive,
  children,
}) => {
  const sidebarChatContextValue = useMemo(
    () => ({
      hideArchiveSidebar,
      hideSearchView,
      showArchiveSidebar,
      showSearchView,
      isArchiveSidebarActive,
    }),
    [
      hideArchiveSidebar,
      hideSearchView,
      showArchiveSidebar,
      showSearchView,
      isArchiveSidebarActive,
    ]
  )

  return (
    <ChatSidebarContext.Provider value={sidebarChatContextValue}>
      {children}
    </ChatSidebarContext.Provider>
  )
}
