import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { INVESTMENT_VEHICLES } from 'routes/constant/InvestorGroupRoutes'
import { Nullable } from 'utils/types/common'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  FundManagerRoutes,
  getCurrentBaseRoute,
  InvestorRoutes,
  URL,
} from 'reducers/breadcrumbSlice'
import {
  INVESTMENT_VEHICLE_PROFILE,
  INVESTOR_PROFILE_VEHICLE_PROFILE,
} from 'routes/constant/investorManagementRoutes'
import { ArrowWrapper, LinkWrapper, NavigationItem } from '../Breadcrumb.styles'
import useVehicleInformation from '../BreadcrumbNavigation/useVehicleInformation'

interface InvestmentVehicleLinkProps {
  url: URL
  showRightArrow: boolean
  activeTabs: string[]
  investorId?: Nullable<string>
  handleIsLoadedInvestmentVehicle?: () => void
}

const InvestmentVehicleLink = ({
  showRightArrow,
  url,
  investorId,
  activeTabs,
  handleIsLoadedInvestmentVehicle,
}: InvestmentVehicleLinkProps) => {
  const history = useHistory()
  const baseRoute = useAppSelector(getCurrentBaseRoute)

  const { getVehicleAvatar, investmentVehicle } = useVehicleInformation({
    fetchInvestmentVehicle: true,
  })

  useEffect(() => {
    if (investmentVehicle) {
      handleIsLoadedInvestmentVehicle?.()
    }
  }, [investmentVehicle, handleIsLoadedInvestmentVehicle])

  if (!investmentVehicle) {
    return null
  }

  const vehicleActiveTabs = activeTabs.map((tab) =>
    tab
      .replace(':investmentVehicleId', investmentVehicle.id)
      .replace(':investorId', investorId!)
  )

  const isVehicleActive = vehicleActiveTabs.some((tab) => tab === url.current)

  return (
    <NavigationItem>
      {getVehicleAvatar()}
      <LinkWrapper
        isActive={isVehicleActive}
        onClick={() => {
          if (isVehicleActive) return

          if (baseRoute === FundManagerRoutes.INVESTOR_MANAGEMENT) {
            history.push(
              INVESTOR_PROFILE_VEHICLE_PROFILE.replace(
                ':investorId',
                investorId!
              ).replace(':investmentVehicleId', investmentVehicle.id)
            )

            return
          }

          if (baseRoute === InvestorRoutes.INVESTOR_PROFILE) {
            history.push(
              INVESTMENT_VEHICLES.replace(
                ':investmentVehicleId',
                investmentVehicle.id
              )
            )

            return
          }

          history.push(
            INVESTMENT_VEHICLE_PROFILE.replace(
              ':investmentVehicleId',
              investmentVehicle.id
            )
          )
        }}
      >
        {investmentVehicle.name}
      </LinkWrapper>

      {showRightArrow && (
        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      )}
    </NavigationItem>
  )
}

export default InvestmentVehicleLink
