import { forwardRef, useImperativeHandle } from 'react'
import { useIntl } from 'react-intl'

import GroupLogo from 'components/GroupLogo'
import Dropdown from 'ui/Dropdown'
import type { DropdownRef } from 'ui/Dropdown'
import { TopicEntity, TopicEntityType } from 'utils/types/chatTopicEntity'

import { assertUnreachable } from 'utils/functions/utils'
import { getPortfolioTypeFromTopic } from 'utils/hooks/queries/usePortfoliosAndHoldingsQuery'
import { PortfolioTypes } from 'utils/constants/portfolio'
import {
  ClearSelectionIcon,
  OptionContainer,
  OptionDescription,
  OptionText,
  OptionTextContainer,
  PortfolioIcon,
} from './TopicEntityDropdown.styles'
import { useTopicEntityDropdown } from './useTopicEntityDropdown'

interface Props {
  name: string
  id: string
  label?: string
  placeholder?: string
  queryPortfolios?: boolean
  portfoliosQuery?: PortfolioTypes[]
  queryHoldings?: boolean
  clearEnabled?: boolean
}

const TopicEntityDropdown = forwardRef<DropdownRef, Props>(
  (
    {
      name,
      id,
      label,
      placeholder,
      queryPortfolios,
      queryHoldings,
      clearEnabled = true,
      portfoliosQuery,
    },
    ref
  ) => {
    const intl = useIntl()

    const {
      dropdownRef,
      topicEntities,
      hasValue,
      clearSelection,
      refetchData,
      onSelectOption,
      onCloseDropdown,
      onOpenDropdown,
      value,
    } = useTopicEntityDropdown({
      name,
      queryPortfolios,
      queryHoldings,
      portfoliosQuery,
    })

    useImperativeHandle(ref, () => dropdownRef.current!)

    const getTopicEntityLogo = (entity: TopicEntity) => {
      switch (entity.entityType) {
        case TopicEntityType.COMPANY_HOLDING:
        case TopicEntityType.FUND_HOLDING:
        case TopicEntityType.ORGANIZATION:
          return <GroupLogo small imageUrl={entity.image} />
        case TopicEntityType.INVEST_PORTFOLIO:
        case TopicEntityType.FUND_PORTFOLIO:
        case TopicEntityType.DEAL_PORTFOLIO:
        case TopicEntityType.TRACK_PORTFOLIO:
          return (
            <PortfolioIcon
              type={getPortfolioTypeFromTopic(entity.entityType)}
              isActive={false}
            />
          )
        default:
          throw assertUnreachable(entity.entityType)
      }
    }

    const getTopicEntityDescription = (topicEntity: TopicEntity) => {
      switch (topicEntity.entityType) {
        case TopicEntityType.COMPANY_HOLDING:
          if (topicEntity.company?.type === PortfolioTypes.DEAL) {
            return intl.formatMessage({
              id: 'general.deal',
            })
          }

          return intl.formatMessage({
            id: 'general.company',
          })
        case TopicEntityType.FUND_HOLDING:
          return intl.formatMessage({
            id: 'general.fund',
          })
        case TopicEntityType.INVEST_PORTFOLIO:
        case TopicEntityType.FUND_PORTFOLIO:
        case TopicEntityType.DEAL_PORTFOLIO:
        case TopicEntityType.TRACK_PORTFOLIO:
          return intl.formatMessage({
            id: `holdingsAndPortfoliosDropdown.portfolioTypes.${getPortfolioTypeFromTopic(
              topicEntity.entityType
            )?.toLocaleLowerCase()}`,
          })
        case TopicEntityType.ORGANIZATION:
          return intl.formatMessage({
            id: 'general.organization',
          })
        default:
          throw assertUnreachable(topicEntity.entityType)
      }
    }

    const renderOption = (entity: TopicEntity) => {
      return (
        <OptionContainer>
          {getTopicEntityLogo(entity)}
          <OptionTextContainer>
            <OptionText>{entity.label}</OptionText>
            <OptionDescription>
              {getTopicEntityDescription(entity)}
            </OptionDescription>
          </OptionTextContainer>
        </OptionContainer>
      )
    }

    return (
      <Dropdown
        name={name}
        id={id}
        label={label}
        placeholder={
          placeholder ||
          intl.formatMessage({
            id: 'chat.topicPlaceholder',
          })
        }
        isOptional
        optionalLabel={intl.formatMessage({ id: 'general.optional' })}
        async
        highlightEnabled
        highlightSelectedOption={false}
        onChange={() => {
          dropdownRef.current?.setLoadingOptions(true)
        }}
        showLoadingIndicator
        iconComponent={
          clearEnabled &&
          hasValue && (
            <ClearSelectionIcon
              onClickCapture={clearSelection}
              icon={['far', 'times']}
            />
          )
        }
        showAngleIcon
        type="input"
        ref={dropdownRef}
        onSelectOption={onSelectOption}
        options={topicEntities}
        loadOptions={refetchData}
        formatSelectedOptionComponent={renderOption}
        value={value}
        onClose={onCloseDropdown}
        onFocus={onOpenDropdown}
      />
    )
  }
)

export default TopicEntityDropdown
