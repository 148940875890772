import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GroupLogo from 'components/GroupLogo'
import { GroupName, Row } from 'components/GroupTooltips/GroupTooltips.styles'
import Tooltip from 'components/Tooltip'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import IconButton from 'ui/IconButton'
import {
  castLoggingUpdateGroup,
  ReshareTokenSet,
} from 'utils/functions/updates'
import { LoggingUpdateEntity, LoggingUpdateUser } from 'utils/types/update'

import {
  GroupLogoContainer,
  ReshareRowContainer,
  ReshareRowContent,
  ReshareRowTitleContainer,
  EditReshareContainer,
  ReshareAvatarIcon,
  ReshareRowDate,
  ReshareMessage,
  MessageTitle,
} from './ReshareHistory.styles'

interface ReshareHistoryRowProps {
  reshareTokenSet: ReshareTokenSet
  onEditReshare?: (tokenSet: ReshareTokenSet) => void
  isEditable: boolean
}
const ReshareHistoryRow: React.FC<ReshareHistoryRowProps> = ({
  reshareTokenSet,
  isEditable,
  onEditReshare,
}) => {
  const intl = useIntl()
  const [reshare] = reshareTokenSet.reshares
  const { message } = reshareTokenSet

  const getMessageLogo = () => {
    if (isEditable) {
      return (
        <ReshareAvatarIcon>
          <FontAwesomeIcon icon={['far', 'retweet']} />
        </ReshareAvatarIcon>
      )
    }

    return <GroupLogo small forceInitials name={reshare.userName} imageUrl="" />
  }

  const getLoggingUpdateEntityData = (entity: LoggingUpdateEntity) => {
    if (castLoggingUpdateGroup(entity)) {
      return {
        id: entity.groupableId,
        name: entity.groupName,
        logo: entity.groupLogo,
      }
    }

    const userEntity = entity as LoggingUpdateUser

    return {
      id: userEntity.userId,
      name: userEntity.userName || userEntity.userEmail,
      logo: userEntity.userImage,
    }
  }

  const getMessageTitle = () => {
    if (isEditable) {
      const entities = uniqBy(
        reshareTokenSet.loggingUpdateEntities.map(getLoggingUpdateEntityData),
        (entityValue) => entityValue.id
      )
      if (entities.length === 1) {
        return intl
          .formatMessage({
            id: 'updates.resharedWith',
          })
          .concat(' ')
          .concat(entities[0].name)
      }

      if (entities.length <= 4) {
        const lastEntity = entities.pop()!

        return intl
          .formatMessage({
            id: 'updates.resharedWith',
          })
          .concat(' ')
          .concat(entities.map((entity) => entity.name).join(', '))
          .concat(' ')
          .concat(intl.formatMessage({ id: 'updates.and' }))
          .concat(' ')
          .concat(lastEntity.name)
      }

      const [entity1, entity2, entity3, ...rest] = entities

      return (
        <>
          {intl
            .formatMessage({
              id: 'updates.resharedWith',
            })
            .concat(' ')
            .concat(
              [entity1, entity2, entity3]
                .map((entity) => entity.name)
                .join(', ')
            )
            .concat(', ')}
          <Tooltip
            id={`resharesTooltip_${reshareTokenSet.token}`}
            place="bottom"
            text={
              <span>
                {rest.map((entity) => (
                  <Row key={entity.id}>
                    <GroupLogo
                      imageUrl={entity.logo.smallLogo.url}
                      name={entity.name}
                      small
                      forceInitials={false}
                    />
                    <GroupName>{entity?.name}</GroupName>
                  </Row>
                ))}
              </span>
            }
          >
            {intl.formatMessage(
              { id: 'updates.moreReshares' },
              { count: rest.length }
            )}
          </Tooltip>
        </>
      )
    }

    return reshare.userName
  }

  return (
    <ReshareRowContainer>
      <GroupLogoContainer>{getMessageLogo()}</GroupLogoContainer>
      <ReshareRowContent>
        <ReshareRowTitleContainer>
          <MessageTitle>{getMessageTitle()}</MessageTitle>
          {isEditable && (
            <EditReshareContainer>
              <IconButton
                icon="pen"
                border={false}
                onClick={() => onEditReshare!(reshareTokenSet)}
              />
            </EditReshareContainer>
          )}
        </ReshareRowTitleContainer>
        <ReshareRowDate>
          {dayjs(reshare.createdAt).format('dddd DD MMMM - hh:mmA')}
        </ReshareRowDate>{' '}
        <ReshareMessage>{message}</ReshareMessage>
      </ReshareRowContent>
    </ReshareRowContainer>
  )
}

ReshareHistoryRow.defaultProps = {
  onEditReshare: undefined,
}
export default ReshareHistoryRow
