import React, { SyntheticEvent, useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { Channel } from 'stream-chat'
import type { ChannelPreviewUIComponentProps } from 'stream-chat-react'
import { useChatContext } from 'stream-chat-react'

import {
  getPinById,
  getPinId,
  getPortfolioTypeFromTopicEntityType,
  getTopicTitle,
} from 'containers/Chat/helpers'
import { useSetActiveChannel, useTypingListener } from 'containers/Chat/hooks'
import {
  Dots,
  useTypingIndicator,
} from 'containers/Chat/components/TypingIndicator'
import { checkIsArchived } from 'containers/Chat/hooks/useArchiveChannels'
import {
  ArchivedChannel,
  ChannelType,
  StreamChatType,
  isHoldingChannel,
  isPortfolioChannel,
  isTopicChannel,
} from 'containers/Chat/types'
import { useChatMobileContext } from 'containers/Chat/components/ChatMobile'
import ContextMenu, { ContextMenuItem } from 'ui/ContextMenu'
import { getInitials } from 'utils/functions/user'
import { useChatMembers } from 'utils/hooks/useChatMembers'
import { useIsChannelMuted } from 'utils/hooks/useIsChannelMuted'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { useTopicUnreadMessagesCount } from 'containers/Chat/hooks/useTopicUnreadMessagesCount'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { closeChatPopupById } from 'features/chatSlice'

import { usePinChannels } from 'containers/Chat/hooks/usePinChannels'
import YouLabel from 'containers/Chat/components/YouLabel'
import * as Styles from './ChannelPreview.styles'
import chatSidebarStyles from '../../ChatSidebar.module.scss'

const checkIsSelected = (
  channel: Channel<StreamChatType>,
  activeChannel: Channel<StreamChatType>,
  archivedChannels: ArchivedChannel[]
) => {
  if (checkIsArchived(activeChannel.id, archivedChannels)) {
    return false
  }

  if (isTopicChannel(activeChannel.data) && isTopicChannel(channel.data)) {
    return activeChannel.data.entityId === channel.data.entityId
  }

  return channel?.id === activeChannel?.id
}

type ChannelPreviewProps = ChannelPreviewUIComponentProps<StreamChatType> & {
  hideSearchView: () => void
}

const ChannelPreview: React.FC<ChannelPreviewProps> = ({
  channel,
  hideSearchView,
}) => {
  const { channel: activeChannel, client } = useChatContext<StreamChatType>()

  const setActiveChannel = useSetActiveChannel()
  const chatTitle = getTopicTitle(channel, client.userID)
  const channelData = channel.data
  const isSelected =
    !!activeChannel &&
    checkIsSelected(channel, activeChannel, client.user?.archivedChannels || [])
  const { members, isUserPersonalChat } = useChatMembers(channel)
  const { muted } = useIsChannelMuted(channel)
  const unreadMessagesCount = useTopicUnreadMessagesCount(channel)
  const { onSelectChannelFromSidebar } = useChatMobileContext()
  const { togglePinChannel, togglePinTopic } = usePinChannels()
  const { matches: isContextMenuEnabled } = useMediaQuery(maxSize.xxs)

  const dispatch = useAppDispatch()

  const isPinned = useMemo(
    () =>
      !!getPinById(
        getPinId(channel)!,
        (client.user?.pinnedChannels || []).concat(
          client.user?.pinnedTopics || []
        )
      ),
    [channel, client.user?.pinnedChannels, client.user?.pinnedTopics]
  )

  const isDirectMessage = channel.type === ChannelType.DIRECT_MESSAGE

  const hasUnreadMessages = unreadMessagesCount > 0

  const { typing } = useTypingListener(channel, true)
  const hasTypingText = !!useTypingIndicator(true, typing)

  const handleClick = useCallback(() => {
    setActiveChannel(channel)
    dispatch(closeChatPopupById(channel.id!))
    hideSearchView()
    onSelectChannelFromSidebar(channel)
  }, [
    setActiveChannel,
    channel,
    dispatch,
    hideSearchView,
    onSelectChannelFromSidebar,
  ])

  const renderIcon = useCallback(() => {
    if (hasTypingText) {
      return (
        <Styles.DotsContainer isATopicChannel={!isDirectMessage}>
          <Dots dotHeight={0.4} dotSpace={0.5} animationName="smallDots" />
        </Styles.DotsContainer>
      )
    }

    if (isTopicChannel(channelData)) {
      if (isHoldingChannel(channelData)) {
        return (
          <Styles.GroupLogo
            small
            name={channelData.topicName}
            imageUrl={channelData.imageUrl}
          />
        )
      }

      if (isPortfolioChannel(channelData)) {
        return (
          <Styles.PortfolioIcon
            type={getPortfolioTypeFromTopicEntityType(channelData.entityType)}
          />
        )
      }
    }

    return (
      <Styles.Avatar
        image={members[0]?.user?.image}
        initials={getInitials(members[0]?.user?.name)}
        avatarStyle="avatarCircleXS"
      />
    )
  }, [hasTypingText, channelData, members, isDirectMessage])

  const hideChannel = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault()
      event.stopPropagation()

      await channel.hide()
      const nextActiveChannel = Object.values(client.activeChannels).find(
        (c) => c.id !== channel.id && !c.data?.hidden
      )

      if (nextActiveChannel) {
        setActiveChannel(nextActiveChannel)
      }
    },
    [channel, client.activeChannels, setActiveChannel]
  )

  const handlePin = useCallback(async () => {
    if (isTopicChannel(channelData)) {
      await togglePinTopic(channelData.entityId)
    } else {
      await togglePinChannel(channel.id!)
    }
  }, [channel.id, channelData, togglePinChannel, togglePinTopic])

  const getContextMenuItems = useCallback(() => {
    const contextMenuItems = [
      <ContextMenuItem
        onClick={handlePin}
        icon={isPinned ? ['fas', 'thumbtack'] : ['far', 'thumbtack']}
        label={
          isPinned ? (
            <FormattedMessage id="chat.unpinChat" />
          ) : (
            <FormattedMessage id="chat.pinChat" />
          )
        }
      />,
    ]

    if (isDirectMessage) {
      contextMenuItems.push(
        <ContextMenuItem
          onClick={hideChannel}
          icon={['fal', 'times']}
          label={<FormattedMessage id="general.close" />}
        />
      )
    }

    return contextMenuItems
  }, [handlePin, hideChannel, isDirectMessage, isPinned])

  return (
    <ContextMenu items={getContextMenuItems()} enabled={isContextMenuEnabled}>
      <Styles.Container
        onClick={handleClick}
        selected={isSelected}
        className={chatSidebarStyles.channelPreviewContainer}
      >
        <Styles.Tooltip
          id={`chat_${channel.id}`}
          text={
            <Styles.TooltipContent>
              {chatTitle} {isUserPersonalChat && <YouLabel />}
            </Styles.TooltipContent>
          }
          place="top"
          offset={-8}
          backgroundColor="transparent"
          delayShow={500}
          inline
        >
          <Styles.Row>
            <Styles.AvatarContainer
              selected={isSelected}
              areTypingInATopicChannel={hasTypingText && !isDirectMessage}
            >
              {renderIcon()}

              {isPinned && (
                <Styles.PinContainer>
                  <FontAwesomeIcon icon={['fas', 'thumbtack']} />
                </Styles.PinContainer>
              )}

              {members.length > 1 && isDirectMessage && (
                <Styles.MembersCountBadge>
                  {members.length}
                </Styles.MembersCountBadge>
              )}

              {isDirectMessage && muted && (
                <Styles.MuteIconContainer>
                  <FontAwesomeIcon icon={['far', 'bell-slash']} />
                </Styles.MuteIconContainer>
              )}
            </Styles.AvatarContainer>

            <Styles.Members
              selected={isSelected}
              className={
                hasUnreadMessages ? chatSidebarStyles.members : undefined
              }
            >
              {chatTitle} {isUserPersonalChat && <YouLabel />}
            </Styles.Members>
          </Styles.Row>
        </Styles.Tooltip>

        {hasUnreadMessages && (
          <Styles.NewMessagesCountBadge>
            {unreadMessagesCount}
          </Styles.NewMessagesCountBadge>
        )}
        {isDirectMessage && (
          <Styles.HideIcon onClick={hideChannel} icon={['fal', 'times']} />
        )}
      </Styles.Container>
    </ContextMenu>
  )
}

export default ChannelPreview
