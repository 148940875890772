import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Wizard from 'components/Wizard'
import AddEmailRecipients from 'components/AddEmailRecipients'
import BuildEmailSection from 'components/BuildEmailSection'
import SendEmailDraft from 'components/SendEmailDraft'
import SendTestEmail from 'components/SendTestEmail'
import Button from 'ui/Button'

import useCreateEmailForm from './useCreateEmailForm'
import {
  EmailNavigationWrapper,
  NavigationWizardWrapper,
  PreviewerWrapper,
  ButtonsContainer,
  ButtonsRightContainer,
  CreateEmailContainer,
  NextLabel,
  ContentWrapper,
} from './CreateEmail.styles'

const CreateEmailForm = () => {
  const {
    emailUpdate,
    errors,
    recipients,
    isFounderVerified,
    emailIframeKey,
    step,
    buildStep,
    recipientsStep,
    sendStep,
    displayBtnText,
    handleClickNext,
    refreshEmailIframe,
    handleClickPrevious,
    invalidateEmailQuery,
  } = useCreateEmailForm()

  return (
    <PreviewerWrapper>
      <EmailNavigationWrapper>
        <NavigationWizardWrapper>
          <Wizard activeStep={step}>
            <Wizard.Step name={buildStep}>
              <FontAwesomeIcon icon={['far', 'pen']} />
            </Wizard.Step>
            <Wizard.Step name={recipientsStep} callback={invalidateEmailQuery}>
              <FontAwesomeIcon icon={['fal', 'users']} />
            </Wizard.Step>
            <Wizard.Step name={sendStep} callback={invalidateEmailQuery}>
              <FontAwesomeIcon icon={['fal', 'paper-plane']} />
            </Wizard.Step>
          </Wizard>
        </NavigationWizardWrapper>
        <ButtonsContainer>
          {emailUpdate && <SendTestEmail emailUpdate={emailUpdate} />}

          <ButtonsRightContainer>
            {step !== buildStep && (
              <Button secondary uppercase onClick={handleClickPrevious}>
                <FormattedMessage id="createEmail.back" />
              </Button>
            )}

            <Button
              primary
              uppercase
              onClick={handleClickNext}
              center
              disabled={
                isFounderVerified &&
                step === sendStep &&
                (!recipients || Object.values(errors).length)
              }
            >
              {step !== sendStep ? (
                <>
                  <NextLabel>
                    <FormattedMessage id="createEmail.next" />
                  </NextLabel>
                  <FontAwesomeIcon icon="chevron-right" />
                </>
              ) : (
                displayBtnText()
              )}
            </Button>
          </ButtonsRightContainer>
        </ButtonsContainer>
      </EmailNavigationWrapper>

      <ContentWrapper>
        <CreateEmailContainer minHeightNone={step === sendStep}>
          {step === buildStep && emailUpdate && (
            <BuildEmailSection emailUpdate={emailUpdate} />
          )}
          {step === recipientsStep && emailUpdate && (
            <AddEmailRecipients emailUpdate={emailUpdate} />
          )}
          {step === sendStep && emailUpdate && (
            <SendEmailDraft
              recipients={recipients}
              refreshEmailIframe={refreshEmailIframe}
              isFounderVerified={isFounderVerified}
              emailIframeKey={emailIframeKey}
              emailUpdate={emailUpdate}
            />
          )}
        </CreateEmailContainer>
      </ContentWrapper>
    </PreviewerWrapper>
  )
}

export default CreateEmailForm
