import { MouseEventHandler } from 'react'
import type { ChannelPreviewUIComponentProps } from 'stream-chat-react'

import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { size } from 'utils/constants/breakpoint'
import { maximizeChatPopupById } from 'features/chatSlice'
import { StreamChatType, TopicChannel } from 'containers/Chat/types'
import {
  MessageTimestamp,
  TopicChannelMessage,
} from 'containers/Chat/components/TopicSidebar'
import CountBadge from 'containers/Chat/components/CountBadge'
import { useTypingIndicator } from 'containers/Chat/components/TypingIndicator'
import {
  useTypingListener,
  useChatTitle,
  useSetActiveChannel,
} from 'containers/Chat/hooks'
import {
  ArrowRight,
  ChannelMessage,
  ChannelName,
  ColumnOne,
  ColumnTwo,
  Container,
} from './PreviewChannel.styles'

type ChannelPreviewProps = ChannelPreviewUIComponentProps<
  StreamChatType<TopicChannel>
>

const PreviewChannel = ({ channel }: ChannelPreviewProps) => {
  const message = channel.lastMessage()
  const unreadCount = channel.countUnread()
  const channelTitle = useChatTitle(channel)
  const { matches: isChatPopupEnabled } = useMediaQuery(size.xsm)
  const dispatch = useAppDispatch()
  const setActiveChannel = useSetActiveChannel<TopicChannel>()

  const { typing } = useTypingListener<TopicChannel>(channel)
  const typingText = useTypingIndicator(true, typing)

  const onClick = () => {
    if (isChatPopupEnabled) {
      dispatch(maximizeChatPopupById(channel.id!))
    } else {
      setActiveChannel(channel)
    }
  }

  const goToChatView: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    setActiveChannel(channel)
  }

  return (
    <Container onClick={onClick}>
      <ColumnOne>
        <ChannelName>
          <span>{channelTitle}</span>
          {message && <MessageTimestamp showToday={false} message={message} />}
        </ChannelName>
        <ChannelMessage>
          <span>{typingText ?? <TopicChannelMessage message={message} />}</span>
          <CountBadge unreadCount={unreadCount} />
        </ChannelMessage>
      </ColumnOne>
      <ColumnTwo onClick={goToChatView}>
        <ArrowRight icon={['far', 'chevron-down']} />
      </ColumnTwo>
    </Container>
  )
}

export default PreviewChannel
