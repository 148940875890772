import styled, { css } from 'styled-components'

export const DashboardGrid = styled.div`
  width: 100%;

  @media (max-width: 1400px) {
    display: block;
  }
`

interface Props {
  small?: boolean
  verticalDashboard?: boolean
  height?: string
}

export const DashboardItem = styled.div<Props>`
  flex: 1;
  max-width: 100%;
  min-width: 0;
  margin-bottom: 2.4rem;

  ${(props) =>
    props.small &&
    css`
      @media (min-width: 1025px) {
        max-width: 29.6rem;
      }
    `}

  &:empty {
    display: none;
  }

  height: ${(props) => (props.verticalDashboard ? 'auto' : props.height)};

  @media (min-width: 1025px) {
    &:not(:last-child) {
      margin-right: 2.4rem;
    }
  }
  @media (max-width: 1024px) {
    margin-bottom: 2.4rem;
  }
`

export const DashboardItemContent = styled.div`
  margin: 0rem 2.1rem 1.2rem;
`

export const DashboardRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`

export const PortfolioName = styled.div`
  width: 32rem;
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.2rem;
  display: flex;
  align-items: center;
  padding: 1.6rem 2.4rem;
  svg {
    margin-right: 1rem;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    padding: 2.5rem 0 1rem 0;
    width: auto;
    justify-content: flex-start;
  }
`
