import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Avatar from 'components/Avatar/Avatar'
import Tooltip from 'components/Tooltip/Tooltip'
import { InvestorRowActionsProps } from 'containers/PortfolioDetail/PortfolioFundDetail/Investors/InvestorsTable/InvestorRowActions'
import type { IntlShape } from 'react-intl/src/types'
import ExpandArrow from 'ui/Table/Row/ExpandArrow'
import { Column } from 'ui/Table/types'
import { displayCurrency, displayNumber } from 'utils/functions/number'
import { getInitials } from 'utils/functions/user'
import theme from 'utils/theme'
import { FundPortfolioInvestor } from 'utils/types/funds'
import { PortfolioTypes } from 'utils/constants/portfolio'
import {
  IconContainer,
  Investor,
  InvestorName,
  InvestorNameCell,
} from './renderFundHoldingsHelper'

interface FundPorfolioInvestorColumnsProps {
  intl: IntlShape
  isMobile: boolean
  styles: any
  isProrataVisible?: boolean
  RenderActions?: React.FC<InvestorRowActionsProps>
  renderActionsHandlers?: Record<string, (string) => void>
  visibleColumns: string[]
  withAvatarAndLink?: boolean
  handleInvestorClick: (id: string) => void
}

export const getFundPortfolioInvestorColumns = ({
  intl,
  isMobile,
  styles,
  visibleColumns,
  withAvatarAndLink = false,
  handleInvestorClick,
}: FundPorfolioInvestorColumnsProps): Column<FundPortfolioInvestor>[] =>
  [
    {
      id: 'expand',
      sortDisabled: true,
      fixed: !isMobile,
      minWidth: 0,
      cellRenderer: ExpandArrow,
    },
    {
      id: 'name',
      label: intl.formatMessage({
        id: 'investorManagement.investmentVehicle.name',
      }),
      hidden: false,
      fixed: !isMobile,
      minWidth: 215,
      flex: 4,
      sortKey: 'fundPortfolioName',
      cellRenderer: ({ rowData }) => {
        const isFund = rowData.fundPortfolioType === PortfolioTypes.FUND
        return withAvatarAndLink ? (
          <InvestorNameCell className="fs-exclude">
            <Avatar
              image={rowData?.investorGroupLogo?.url}
              initials={getInitials(rowData.fundPortfolioName)}
              grayBorder
              avatarStyle="avatarCircleHoldingLogo"
            />
            <Investor
              onClick={() => handleInvestorClick(rowData.investorGroupId)}
            >
              <Tooltip
                id={`investor-name-${rowData.fundPortfolioName}`}
                place="bottom"
                text={rowData.fundPortfolioName}
                delayShow={500}
              >
                <InvestorName>{rowData.fundPortfolioName}</InvestorName>
              </Tooltip>
            </Investor>
          </InvestorNameCell>
        ) : (
          <span className={classNames(styles?.cell, 'fs-exclude')}>
            <InvestorNameCell className="fs-exclude">
              <IconContainer>
                <FontAwesomeIcon
                  color={theme.colors.primaryBlue}
                  size="sm"
                  icon={['far', isFund ? 'sack-dollar' : 'badge-dollar']}
                />
              </IconContainer>
              {rowData.fundPortfolioName}
            </InvestorNameCell>
          </span>
        )
      },
    },
    {
      id: 'committedCapital',
      label: intl.formatMessage({
        id: 'investorManagement.investmentVehicle.committedCapital',
      }),
      flex: 1,
      minWidth: 85,
      sortKey: 'investorCommittedCapital',
      cellRenderer: ({ rowData }) => {
        return (
          <span className={classNames(styles?.cell, 'fs-exclude')}>
            {displayCurrency(intl, rowData.investorCommittedCapital)}
          </span>
        )
      },
    },
    {
      id: 'capitalCalled',
      label: intl.formatMessage({
        id: 'investorManagement.investmentVehicle.capitalCalled',
      }),
      flex: 1,
      minWidth: 85,
      sortKey: 'investorCapitalCalled',
      cellRenderer: ({ rowData }) => {
        return (
          <span className={classNames(styles?.cell, 'fs-exclude')}>
            {displayCurrency(intl, rowData.investorCapitalCalled)}
          </span>
        )
      },
    },
    {
      id: 'unfundedCommitment',
      label: intl.formatMessage({
        id: 'investorManagement.investmentVehicle.unfundedCommitment',
      }),
      flex: 1,
      minWidth: 85,
      sortKey: 'investorUnfundedCommitment',
      cellRenderer: ({ rowData }) => {
        return (
          <span className={classNames(styles?.cell, 'fs-exclude')}>
            {displayCurrency(intl, rowData.investorUnfundedCommitment)}
          </span>
        )
      },
    },
    {
      id: 'distributions',
      label: intl.formatMessage({
        id: 'investorManagement.investmentVehicle.amountDistributed',
      }),
      flex: 0.9,
      minWidth: 85,
      sortKey: 'investorDistributions',
      cellRenderer: ({ rowData }) => {
        return (
          <span className={classNames(styles?.cell, 'fs-exclude')}>
            {displayCurrency(intl, rowData.investorDistributions)}
          </span>
        )
      },
    },
    {
      id: 'totalInvestment',
      label: intl.formatMessage({
        id: 'investorManagement.investmentVehicle.totalInvestment',
      }),
      flex: 1,
      minWidth: 85,
      sortKey: 'investorTotalInvestments',
      cellRenderer: ({ rowData }) => {
        return (
          <span className={classNames(styles?.cell, 'fs-exclude')}>
            {displayNumber(rowData.investorTotalInvestments)}
          </span>
        )
      },
    },
    {
      id: 'prorataHoldingValue',
      label: intl.formatMessage({
        id: 'investorManagement.investmentVehicle.prorataHoldingValue',
      }),
      flex: 1,
      minWidth: 85,
      sortKey: 'investorProRataHoldingValue',
      cellRenderer: ({ rowData }) => {
        return (
          <span className={classNames(styles?.cell, 'fs-exclude')}>
            {displayCurrency(intl, rowData.investorProRataHoldingValue)}
          </span>
        )
      },
    },
  ].filter(({ id }) => visibleColumns.indexOf(id) !== -1)
