import React from 'react'
import CWLoader from 'components/CWLoader'
import Table from 'components/ExpandableTable'
import { FileContent } from 'utils/types/files'
import { TableScrollWrapper } from 'components/ExpandableTable/TableScrollWrapper/TableScrollWrapper'
import Separator from 'ui/Separator'
import TotalAnalyticsCard from './components/TotalAnalyticsCard'
import {
  AnalyticsContainer,
  LoadingWrapper,
  TotalCardsContainer,
} from './Analytics.styles'
import { useAnalytics } from './useAnalytics'
import AnalyticExpandedRow from './components/AnalyticExpandedRow/AnalyticExpandedRow'
import AttachmentsAnalytics from './components/AttachmentsAnalytics'
import AnalyticsChart from './components/AnalyticsChart'

const PAGE_SIZE = 24

interface AnalyticsProps {
  updateId: string
  updateCreationDate: Date
  totalViews: number
  uniqueViews: number
  attachments?: FileContent[]
}

const Analytics: React.FC<AnalyticsProps> = ({
  updateId,
  updateCreationDate,
  totalViews,
  uniqueViews,
  attachments = [],
}) => {
  const {
    tableData,
    tableColumns,
    setSort,
    isLoading,
    loadUserAnalytics,
    intl,
  } = useAnalytics(updateId)

  if (isLoading) {
    return (
      <LoadingWrapper>
        <CWLoader
          text={intl.formatMessage({ id: 'analytics.loadingAnalytics' })}
        />
      </LoadingWrapper>
    )
  }

  return (
    <AnalyticsContainer>
      <TotalCardsContainer>
        <TotalAnalyticsCard
          icon="star"
          value={uniqueViews}
          label={intl.formatMessage({ id: 'analytics.uniqueViews' })}
        />
        <TotalAnalyticsCard
          icon="eye"
          value={totalViews}
          label={intl.formatMessage({ id: 'analytics.totalViews' })}
        />
      </TotalCardsContainer>

      <Separator space="3.4rem" />

      {totalViews > 0 && (
        <AnalyticsChart
          updateId={updateId}
          updateCreationDate={updateCreationDate}
        />
      )}

      <TableScrollWrapper minWidth="55rem">
        <Table
          keyPath="id"
          data={tableData}
          columns={tableColumns}
          onChangeSortBy={({ sortId, sortDirection }) => {
            setSort({
              field: sortId,
              direction: sortDirection,
            })
          }}
          showExpandableColumn
          isRowExpandable={() => true}
          getExpandableData={loadUserAnalytics}
          showLoadingIndicatorOnExpandableRows
          expandedRowsColumns={[
            {
              align: 'left',
              flex: 1,
              cellRenderer: AnalyticExpandedRow,
            },
          ]}
          expandOnRowClick
          paginateExpandedRows
          expandedLoadingIndicatorText={intl.formatMessage({
            id: 'analytics.loadingAnalytics',
          })}
          pageSize={PAGE_SIZE}
          asyncExpandedPagination
          paginationLabel={intl.formatMessage({
            id: 'analytics.loadMoreAnalytics',
          })}
          rowHeight={41}
          expandedRowHeight={68}
          noResultsLabel={intl.formatMessage({
            id: 'analytics.noResultsLabel',
          })}
          noResultsLegend={intl.formatMessage({
            id: 'analytics.noResultsLegend',
          })}
          getExpandedRowHeight={() => {
            return 'auto'
          }}
        />
      </TableScrollWrapper>

      <Separator space="4.1rem" />

      {attachments.length > 0 && (
        <AttachmentsAnalytics attachments={attachments} />
      )}
    </AnalyticsContainer>
  )
}

export default Analytics
