import styled, { css } from 'styled-components'

export const CircularProgressbarContainer = styled.div`
  width: 5.3rem;
  .CircularProgressbar {
    .CircularProgressbar-trail {
      stroke: #4a4a68;
      stroke-width: 0.7rem;
      transition: stroke 0.2s;
    }

    .CircularProgressbar-path {
      stroke: #fafcfe;
      stroke-width: 1rem;
    }

    .CircularProgressbar-text {
      fill: #ffffff;
      font-size: 2.8rem;
      dominant-baseline: central;
    }

    ${(props) =>
      props.isActive &&
      css`
        .CircularProgressbar-trail {
          stroke: #112f63;
        }
      `}
  }
`
