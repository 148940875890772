import { useAttachmentAnalyticsQuery } from 'containers/Analytics/useAnalyticsQuery'
import { FileContent } from 'utils/types/files'
import { useDownloadFile } from 'utils/hooks/files/useDownloadFile'

export const useAttachmentAnalytics = (attachment: FileContent) => {
  const { data: attachmentAnalytics = [], isLoading } =
    useAttachmentAnalyticsQuery(attachment.id)

  const { onDownloadFile } = useDownloadFile()

  const handleDownloadFile = () => {
    onDownloadFile(attachment)
  }

  const totalDownloads = attachmentAnalytics.reduce(
    (acc, { downloadCount }) => acc + downloadCount,
    0
  )
  return {
    attachmentAnalytics,
    totalDownloads,
    isLoading,
    handleDownloadFile,
  }
}
