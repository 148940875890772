import React from 'react'
import { Item } from '..'

interface ContactInformationProps {
  address: string
  phone: string
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  address,
  phone,
}) => {
  return (
    <>
      <Item icon={['far', 'phone-alt']} value={phone} type="text" />
      <Item icon={['far', 'map-marker-alt']} value={address} type="text" />
    </>
  )
}

export default ContactInformation
