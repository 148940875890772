import { components } from 'react-select'

import styled from 'styled-components'

const GroupContainer = styled.div`
  padding: 0;
  .group {
    padding: 0;
  }
`

export const Group = (props) => (
  <GroupContainer>
    <components.Group {...props} className="group" />
  </GroupContainer>
)
