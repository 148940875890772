import React from 'react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { InputProps } from 'ui/Input'
import { StyledInput, InputIcon } from './URLInput.styles'

interface URLInputProps extends InputProps {
  icon?: IconProp
  customIcon?: React.ReactNode
  padding?: string
}

const URLInput: React.FC<URLInputProps> = ({ icon, customIcon, ...props }) => {
  const iconProps: any = {}
  if (icon) {
    iconProps.prefix = <InputIcon icon={icon} />
  } else if (customIcon) {
    iconProps.icon = customIcon
  }
  return <StyledInput {...props} {...iconProps} />
}

export default URLInput
