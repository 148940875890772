import { FormattedMessage, useIntl } from 'react-intl'
import Button from 'ui/Button'
import Input from 'ui/Input'
import theme from 'utils/theme'
import CWLoader from 'components/CWLoader'
import {
  Body,
  Footer,
  FooterButtonsContainer,
} from '../../OnboardingModal.styles'
import AddHoldingsSpreadsheet from './components/AddHoldingSpreadsheet/AddHoldingsSpreadsheet'
import * as Styles from './SetupYourPipeline.styles'
import {
  useSetupYourPipelineStep,
  SetupYourPipelineStepProps,
} from './useSetupYourPipelineStep'

const SetupYourPipelineStep = ({
  completeStep,
  goToNextStep,
  preFetchedPipeline,
}: SetupYourPipelineStepProps) => {
  const intl = useIntl()
  const {
    pipeline,
    newPipelineName,
    onPipelineNameInputChange,
    onPipelineNameInputBlur,
    newPipelineFormError,
    downloadTemplate,
    onGridChange,
    createAndAddHoldingsToPortfolio,
    loading,
    shouldDisableContinue,
  } = useSetupYourPipelineStep({
    completeStep,
    goToNextStep,
    preFetchedPipeline,
  })

  return (
    <>
      <Body>
        {!pipeline && <CWLoader />}
        {pipeline && (
          <>
            <Styles.PipelineNameContainer>
              <Input
                id="pipelineName"
                name="pipelineName"
                label={intl.formatMessage({
                  id: 'spreadsheet.holdings.pipelineName',
                })}
                placeholder={intl.formatMessage({
                  id: 'spreadsheet.holdings.pipelinePlaceholder',
                })}
                value={newPipelineName}
                onChange={onPipelineNameInputChange}
                onBlur={onPipelineNameInputBlur}
                error={newPipelineFormError}
                withCounter
                counterSubtractNumber={newPipelineName.length}
                simulateIconSpace
                counterStyle={{
                  right: '2rem',
                  top: '1.2rem',
                  color: theme.colors.darkGray,
                  opacity: 0.5,
                }}
              />
            </Styles.PipelineNameContainer>
            <Styles.GridTitle>
              <FormattedMessage id="spreadsheet.holdings.description" />
              <Button
                onClick={downloadTemplate}
                link
                icon={['far', 'download']}
              >
                <FormattedMessage id="spreadsheet.holdings.downloadTemplate" />
              </Button>
            </Styles.GridTitle>
            <Styles.GridContainer>
              <AddHoldingsSpreadsheet onGridChange={onGridChange} />
            </Styles.GridContainer>
          </>
        )}
      </Body>

      <Footer>
        <FooterButtonsContainer>
          <Button onClick={goToNextStep}>
            <FormattedMessage id="general.skipStep" />
          </Button>
          <Button
            primary
            isDisabled={shouldDisableContinue}
            isLoading={loading}
            onClick={createAndAddHoldingsToPortfolio}
          >
            <FormattedMessage id="general.continue" />
          </Button>
        </FooterButtonsContainer>
      </Footer>
    </>
  )
}

export default SetupYourPipelineStep
