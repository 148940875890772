import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`
export const Title = styled.div`
  font-size: 3.2rem;
  text-align: center;
`

export const Subtitle = styled.div<{ bold?: boolean }>`
  font-size: 1.4rem;
  text-align: center;
  color: ${color('darkGray')};

  a:hover {
    color: ${color('primaryBlue')};
  }

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
`

export const PlanNote = styled(Subtitle)`
  @media ${maxSize.md} {
    max-width: 51.6rem;
  }

  @media ${maxSize.sm} {
    max-width: 38.8rem;
  }

  @media ${maxSize.xsm} {
    max-width: 21.6rem;
  }
`

export const CardLink = styled.a`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;

  :hover {
    color: ${color('primaryBlue')};
  }
`
export const GroupTypeImageCreate = styled.div`
  svg {
    height: 6rem;
  }
`

export const GroupTypeLegend = styled.p`
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: start;
  color: ${color('darkGray')};
`

export const GroupTypeTitle = styled.h2`
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: ${color('darkBlue')};
  margin-bottom: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
`

export const GroupTypeCardStyles = css`
  display: flex;
  gap: 2.4rem;
  flex-direction: row;
  align-items: center;
  box-shadow: #e9e9e9 0 0 0 0.1rem;
  box-sizing: border-box;
  border-radius: 0.8rem;
  flex: 1;
  width: 100%;
  max-width: 88.1rem;

  @media ${maxSize.md} {
    padding: 2.4rem;
  }

  @media ${maxSize.xsm} {
    padding: 1.2rem;
  }
`

export const NonClickableGroupCard = styled.div`
  ${GroupTypeCardStyles}
  padding: 1.6rem 4rem;
  width: 90%;
  max-width: 58.8rem;
`
