import { FormattedMessage } from 'react-intl'

import CWLoader from 'components/CWLoader'
import Checkbox from 'components/Checkbox'
import ClaimCompany from 'components/ClaimCompany'
import Input from 'ui/Input'
import InputLabel from 'ui/InputLabel'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'
import {
  CheckboxContainer,
  ContinueButton,
  FounderGroupSetupContent,
  Grid,
  GroupSetupForm,
  Heading,
  HorizontalContainer,
  ImagePickerLabel,
  Legend,
  Spacer,
  StyledImagePicker,
} from '../Signup.styles'
import { useFounderGroupSetup } from './useFounderGroupSetup'

const FounderGroupSetup = () => {
  const {
    formik,
    intl,
    imageUrl,
    onSelectImage,
    onCancelImage,
    isLoading,
    onSaveImage,
    showClaimCompany,
    onConfirmClaimCompany,
    onCancelClaimCompany,
    createdGroup,
  } = useFounderGroupSetup()
  if (isLoading) {
    return <CWLoader />
  }

  if (showClaimCompany) {
    return (
      <ClaimCompany
        title={intl.formatMessage({ id: 'aboutCompany.claimGroup' })}
        description={intl.formatMessage({
          id: 'aboutCompany.claimGroupDescription',
        })}
        onClickNo={onCancelClaimCompany}
        onClickYes={onConfirmClaimCompany}
        claimerGroup={createdGroup}
        showSignupMode
      />
    )
  }

  return (
    <div>
      <Heading level="h1">
        <FormattedMessage id="createNewGroup.createYourGroup" />
        <Legend>
          <FormattedMessage id="createNewGroup.legend" />
        </Legend>
      </Heading>
      <GroupSetupForm onSubmit={formik.handleSubmit}>
        <FounderGroupSetupContent>
          <HorizontalContainer>
            <div>
              <ImagePickerLabel>
                <FormattedMessage id="investorSignup.companyPhoto" />
              </ImagePickerLabel>
              <StyledImagePicker
                imageUrl={imageUrl}
                onSelectImage={onSelectImage}
                onSaveImage={onSaveImage}
                changeImageText={<FormattedMessage id="signup.setupGroup" />}
                helpHidden
                showSelectImageButton={false}
                deleteImageEnabled
                showRemoveImageButton={false}
                onDeleteImage={onCancelImage}
              />
            </div>
            <Grid>
              <Input
                name="legalEntityName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.legalEntityName}
                label={intl.formatMessage({
                  id: 'aboutCompany.legalEntityName',
                })}
                placeholder={intl.formatMessage({
                  id: 'aboutCompany.legalEntityNamePlaceholder',
                })}
                error={buildFormError(
                  formik.errors.legalEntityName,
                  ErrorType.ERROR,
                  !!formik.touched.legalEntityName
                )}
              />

              <Input
                name="companyName"
                onChange={formik.handleChange}
                value={formik.values.companyName}
                onBlur={formik.handleBlur}
                label={intl.formatMessage({ id: 'aboutCompany.companyName' })}
                placeholder={intl.formatMessage({
                  id: 'aboutCompany.companyNamePlaceholder',
                })}
                error={buildFormError(
                  formik.errors.companyName,
                  ErrorType.ERROR,
                  !!formik.touched.companyName
                )}
              />

              <Input
                name="website"
                onChange={formik.handleChange}
                isOptional
                value={formik.values.website}
                label={intl.formatMessage({
                  id: 'aboutCompany.companyWebsite',
                })}
                placeholder={intl.formatMessage({
                  id: 'aboutCompany.companyWebsitePlaceholder',
                })}
              />
            </Grid>
          </HorizontalContainer>

          <Spacer margin="4rem" />

          <Grid large>
            <Input
              name="role"
              onChange={formik.handleChange}
              value={formik.values.role}
              isOptional
              label={intl.formatMessage({ id: 'aboutCompany.yourRole' })}
              placeholder={intl.formatMessage({
                id: 'aboutCompany.yourRolePlaceholder',
              })}
            />
            <div>
              <InputLabel
                marginLeft="0"
                isOptional
                optionalLabel={intl.formatMessage({
                  id: 'general.optional',
                })}
              >
                <FormattedMessage id="aboutCompany.areYouFounder" />
              </InputLabel>
              <CheckboxContainer>
                <Checkbox
                  id="is-founder"
                  name="isFounder"
                  label={intl.formatMessage({ id: 'aboutCompany.yesFounder' })}
                  onChange={formik.handleChange}
                />
              </CheckboxContainer>
            </div>
          </Grid>
        </FounderGroupSetupContent>

        <Spacer margin="0.8rem" />

        <ContinueButton
          type="submit"
          primary
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          isLoading={formik.isSubmitting}
        >
          <FormattedMessage id="general.finish" />
        </ContinueButton>
      </GroupSetupForm>
    </div>
  )
}

export default FounderGroupSetup
