import { useQuery } from '@tanstack/react-query'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { MixedContent } from 'utils/types/files'
import ContentService, { ContentFilters } from 'api/ContentService'
import { useContentsInfiniteQuery } from './useContentsInfiniteQuery'
import { useDeleteContentMutation } from './useContentsQuery'

type UseContentProps = {
  queryOptions?: UseInfiniteQueryOptions<{
    contents: MixedContent[]
    page: number
  }>
  isInitiallyInfiniteScrollEnabled?: boolean
  filters: ContentFilters
}

export const useContents = ({
  queryOptions = {},
  isInitiallyInfiniteScrollEnabled = false,
  filters,
}: UseContentProps) => {
  const query = useContentsInfiniteQuery({
    filters,

    queryOptions,
    isInitiallyInfiniteScrollEnabled,
  })
  const deleteContent = useDeleteContentMutation(filters).mutateAsync

  return {
    ...query,
    contents: query.data?.pages.map((data) => data.contents).flat() ?? [],
    deleteContent,
  }
}

export const usePopulateUpdateTypesQuery = (shouldFetch: boolean) => {
  const query = useQuery(
    ['populateUpdatesTypes'],
    () => {
      return ContentService.updateTypesFilterOptions()
    },
    {
      enabled: shouldFetch,
    }
  )

  return query
}
