import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import TooltipToStyle from 'components/Tooltip'
import { color } from 'utils/functions/colors'

export const MutedIcon = styled(FontAwesomeIcon)`
  color: ${color('lightGray')};
  font-size: 1.3rem;
  margin-left: 1rem;
`

export const Tooltip = styled(TooltipToStyle)`
  a {
    display: flex;
  }
`
