import React, { useLayoutEffect, useState } from 'react'

import { useWindowResize } from 'utils/hooks/windowResize'
import { useForceUpdateOnChatUserUpdated } from 'containers/Chat/hooks/useForceUpdateOnChatUserUpdated'

import * as Styles from './ChatSidebar.styles'
import { ChatSidebarContent } from './ChatSidebarContent'
import ChatSearchInput from './components/ChatSearchInput'

type ChatSidebarProps = {
  chatContainerRef?: React.RefObject<HTMLDivElement>
}

const ChatSidebar: React.FC<ChatSidebarProps> = ({ chatContainerRef }) => {
  const [sidebarPercentageSize, setSidebarPercentageSize] = useState<
    | {
        minSize: number
        maxSize: number
      }
    | undefined
  >(undefined)

  useForceUpdateOnChatUserUpdated()

  useWindowResize(() => {
    if (chatContainerRef?.current) {
      setSidebarPercentageSize({
        minSize:
          (Styles.MIN_SIDEBAR_WIDTH * 100) /
          chatContainerRef.current.offsetWidth,
        maxSize:
          (Styles.SIDEBAR_WIDTH * 100) / chatContainerRef.current.offsetWidth,
      })
    }
  })

  useLayoutEffect(() => {
    if (chatContainerRef?.current && !sidebarPercentageSize) {
      setSidebarPercentageSize({
        minSize:
          (Styles.MIN_SIDEBAR_WIDTH * 100) /
          chatContainerRef.current.offsetWidth,
        maxSize:
          (Styles.SIDEBAR_WIDTH * 100) / chatContainerRef.current.offsetWidth,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatContainerRef, chatContainerRef?.current?.offsetWidth])

  return (
    <>
      <Styles.Container
        maxSize={sidebarPercentageSize?.maxSize}
        minSize={sidebarPercentageSize?.minSize}
      >
        <ChatSearchInput />

        <ChatSidebarContent />
      </Styles.Container>

      <Styles.StyledPanelResizeHandle style={{ cursor: 'col-resize' }} />
    </>
  )
}

export default ChatSidebar
