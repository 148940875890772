import {
  Investor,
  InvestorFormValues,
  InvestorGroupUser,
  InvestmentVehicleFormValues,
  InvestmentVehicle,
  InvestorGroupUserPayload,
  BulkInvestmentVehicleFormValues,
  FinancialInformation,
  GeneralInformation,
  ContactDetails,
} from 'utils/types/investors'
import { BulkInvestmentVehicleFormDraftValues } from 'utils/types/bulkImports/investmentVehicles'
import {
  BulkInvestorFormDraftValues,
  BulkInvestorFormValues,
} from 'utils/types/bulkImports/investors'
import humps from 'humps'
import { getAddedAndRemovedEntities } from 'utils/functions/utils'
import { Industry, Employee } from 'utils/types/company'
import { User } from 'utils/types/user'
import { DraftValidation, PropertyMapper } from 'utils/types/common'
import { InvestorTypes } from 'utils/constants/investorManagement'
import { getInvestorLocations, getLocationsPayload } from './locations'
import { randomId } from './number'

export const getEditInvestorInitialValues = (
  investor: Investor
): InvestorFormValues => {
  const hasImageProfile = !investor.logo?.url?.includes('missing.png')

  return {
    type: investor.investorType,
    logo: {
      image: investor.logo,
      imageUrl: hasImageProfile ? investor.logo!.url : '',
      cropperUrl: '',
      hasRemovedImage: false,
    },
    name: investor.name,
    users: [],
    website: investor.website,
    foundedDate: investor.foundedDate,
    phone: investor.phone,
    description: investor.description || '',
    crunchbaseUrl: investor.crunchbaseUrl,
    angellistUrl: investor.angellistUrl,
    linkedinUrl: investor.linkedinUrl,
    twitterUrl: investor.twitterUrl,
    teamSize: investor.employeesNumber,
    employees: investor.employees,
    emails: investor.emails,
    locations: getInvestorLocations(investor.locations),
    legalEntityName: investor.legalEntityName,
    legalStructure: investor.legalStructure,
    legalCountry: investor.legalCountry,
    legalState: investor.legalProvince,
    einTaxId: investor.financialInformation?.taxId,
    bankName: investor.financialInformation?.bankName,
    bankAccountNumber: investor.financialInformation?.bankAccount,
    routingNumber: investor.financialInformation?.routingNumber,
    industries: investor.generalInformation.investmentToolIndustries ?? [],
    sendInvitation: false,
  }
}

export const getEditInvestmentVehicleInitialValues = (
  investmentVehicle: InvestmentVehicle
): InvestmentVehicleFormValues => {
  const shareInvestorData =
    investmentVehicle.investmentVehicleInvestor.shareData

  return {
    name: investmentVehicle.name,
    investor: investmentVehicle.investorGroup,
    shareInvestorData,

    phone: shareInvestorData ? '' : investmentVehicle.phone,
    einTaxId: shareInvestorData
      ? ''
      : investmentVehicle.financialInformation?.taxId,
    bankName: shareInvestorData
      ? ''
      : investmentVehicle.financialInformation?.bankName,
    bankAccountNumber: shareInvestorData
      ? ''
      : investmentVehicle.financialInformation?.bankAccount,
    routingNumber: shareInvestorData
      ? ''
      : investmentVehicle.financialInformation?.routingNumber,
    locations: getInvestorLocations(investmentVehicle.locations),
  }
}

export const getDeleteInvestorGroupUsersPayload = (
  investorGroupUsers: InvestorGroupUser[]
): {
  investorGroupUsersAttributes: InvestorGroupUserPayload[]
} => ({
  investorGroupUsersAttributes: investorGroupUsers.map((investorGroupUser) => ({
    id: investorGroupUser.id,
    _destroy: true,
  })),
})

export const getAddInvestorGroupUsersPayload = (
  users: User[],
  message?: string
): {
  invitationMessage?: string
  investorGroupUsersAttributes: InvestorGroupUserPayload[]
} => ({
  invitationMessage: message,
  investorGroupUsersAttributes: users.map((user) => ({
    id: null,
    userId: user.id,
  })),
})

const getLogo = (investorData: InvestorFormValues) => {
  const { logo } = investorData

  if (logo.hasRemovedImage) {
    return {
      remove_logo: true,
    }
  }

  if (logo?.image instanceof File) {
    return { logo: logo.image }
  }

  return undefined
}

interface GetInvestorPayloadProps {
  investorData: InvestorFormValues
  oldInvestor?: Investor
  isBulkImport?: boolean
}

export const getInvestorPayload = ({
  investorData,
  oldInvestor,
  isBulkImport,
}: GetInvestorPayloadProps) => {
  const isAnOrganizationInvestor =
    investorData.type === InvestorTypes.ORGANIZATION

  const hasFinancialAttributes =
    investorData.einTaxId ||
    investorData.bankName ||
    investorData.bankAccountNumber ||
    investorData.routingNumber

  const { removed: removedEmployees } = getAddedAndRemovedEntities<Employee>(
    oldInvestor?.employees ?? [],
    isAnOrganizationInvestor ? investorData.employees : []
  )

  const { added: addedIndustries, removed: removedIndustries } =
    getAddedAndRemovedEntities<Industry>(
      oldInvestor?.generalInformation.investmentToolIndustries ?? [],
      investorData.industries
    )

  const { added: addedUsers } = getAddedAndRemovedEntities<
    InvestorGroupUser,
    User
  >(oldInvestor?.investorGroupUsers ?? [], investorData.users, [
    (investorUserGroup) => investorUserGroup.user.id,
    (user) => user.id,
  ])

  const { investorGroupUsersAttributes: addedUsersPayload } =
    getAddInvestorGroupUsersPayload(addedUsers)

  const isNewEmployee = (employee: Employee): boolean =>
    !oldInvestor?.employees.some((emp) => emp.id === employee.id)

  const payload = humps.decamelizeKeys({
    name: investorData.name,
    investorType: investorData.type,
    website: investorData.website,
    phone: investorData.phone,
    description: investorData.description,
    foundedDate: investorData.foundedDate,
    crunchbaseUrl: investorData.crunchbaseUrl,
    angellistUrl: investorData.angellistUrl,
    linkedinUrl: investorData.linkedinUrl,
    twitterUrl: investorData.twitterUrl,
    employeesNumber: isAnOrganizationInvestor ? investorData.teamSize : null,
    legalEntityName: investorData.legalEntityName,
    legalStructureId: investorData.legalStructure?.id,
    legalCountryId: investorData.legalCountry?.id,
    legalProvinceId: investorData.legalState?.id,
    userEmails: investorData.emails?.length ? investorData.emails : [''],
    investorGroupUsersAttributes: investorData.sendInvitation
      ? addedUsersPayload
      : [''],
    invitationMessage: investorData.message,
    investmentToolIndustriesAttributes: [
      ...addedIndustries.map((industry) => ({
        id: null,
        industryId: industry.id,
      })),
      ...removedIndustries.map((industry) => ({
        id: industry.id,
        _destroy: true,
      })),
    ],
    financialAttributes:
      hasFinancialAttributes || oldInvestor?.financialInformation?.id
        ? {
            id: oldInvestor?.financialInformation?.id,
            taxId: investorData.einTaxId,
            bankName: investorData.bankName,
            bankAccount: investorData.bankAccountNumber,
            routingNumber: investorData.routingNumber,
          }
        : undefined,
    employeesAttributes: [
      ...investorData.employees.map((employee, index) => ({
        id: isNewEmployee(employee) ? null : employee.id,
        position: index + 1,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        title: employee.title,
        founder: employee.founder,
        linkedinUrl: employee.linkedinUrl,
      })),
      ...removedEmployees.map((employee) => ({
        id: employee.id,
        _destroy: true,
      })),
    ],
    locationsAttributes: getLocationsPayload(
      oldInvestor?.locations ?? [],
      investorData.locations
    ),
  })

  if (isBulkImport) {
    payload.inviteMembers = !!investorData?.sendInvitation
  }

  return {
    ...payload,

    ...getLogo(investorData),
  }
}

export const getInvestmentVehiclePayload = (
  investmentVehicleData: InvestmentVehicleFormValues,
  oldInvestmentVehicle?: InvestmentVehicle
) => {
  return {
    investmentVehicle: {
      name: investmentVehicleData.name,
      phone: !investmentVehicleData.shareInvestorData
        ? investmentVehicleData.phone
        : undefined,
      investmentVehicleInvestorAttributes: {
        id: oldInvestmentVehicle?.investmentVehicleInvestor?.id,
        investorGroupId: investmentVehicleData.investor?.id,
        shareData: investmentVehicleData.shareInvestorData,
      },
      financialAttributes: !investmentVehicleData.shareInvestorData
        ? {
            taxId: investmentVehicleData.einTaxId,
            bankName: investmentVehicleData.bankName,
            bankAccount: investmentVehicleData.bankAccountNumber,
            routingNumber: investmentVehicleData.routingNumber,
          }
        : undefined,
      locationsAttributes: investmentVehicleData.shareInvestorData
        ? undefined
        : getLocationsPayload(
            oldInvestmentVehicle?.locations ?? [],
            investmentVehicleData.locations
          ),
    },
  }
}

type ValidatedErrors<T> = {
  [key in keyof T]?: string
}

type ValidatedInvestorsErrors = ValidatedErrors<InvestorFormValues>
type ValidatedInvestmentVehiclesErrors =
  ValidatedErrors<InvestmentVehicleFormValues>

export const getBulkImportInvestorsData = (
  validatedInvestors: DraftValidation<BulkInvestorFormDraftValues>[],
  defaultMessage: string
) => {
  return validatedInvestors.reduce<{
    investors: BulkInvestorFormValues[]
    errors: ValidatedInvestorsErrors[]
  }>(
    (result, draftValidation) => {
      const { errors: errorsToParse, object: investor } = draftValidation
      const propertyMapper: PropertyMapper<
        BulkInvestorFormDraftValues,
        BulkInvestorFormValues
      > = {
        investorType: 'type',
        employeesNumber: 'teamSize',
        invitationMessage: 'message',
        userEmails: 'users',
      }

      const errors = Object.keys(
        errorsToParse
      ).reduce<ValidatedInvestorsErrors>((currentErrors, key) => {
        const property = propertyMapper[key] ?? key

        if (key !== 'email') {
          return {
            ...currentErrors,
            [property]: errorsToParse[key]?.join(', '),
          }
        }

        return currentErrors
      }, {})

      const parsedInvestor: BulkInvestorFormValues = {
        id: randomId(),
        type: investor.investorType ?? 'organization',
        logo: investor?.logo ?? {
          cropperUrl: '',
          hasRemovedImage: false,
          imageUrl: '',
        },
        name: investor.name ?? '',
        emails: investor.emails ?? [],
        website: investor.website ?? '',
        foundedDate: investor.foundedDate,
        phone: investor.phone ?? '',
        description: investor.description ?? '',
        locations: investor.locations ?? [],

        crunchbaseUrl: investor.crunchbaseUrl ?? '',
        angellistUrl: investor.angellistUrl ?? '',
        linkedinUrl: investor.linkedinUrl ?? '',
        twitterUrl: investor.twitterUrl ?? '',
        teamSize: Number(investor.employeesNumber) ?? 0,
        employees: investor.employees ?? [],
        legalEntityName: investor.legalEntityName ?? '',
        legalStructure: investor.legalStructure,
        legalCountry: investor.legalCountry,
        legalState: investor.legalState,
        einTaxId: investor.einTaxId ?? '',
        bankName: investor.bankName ?? '',
        bankAccountNumber: investor.bankAccountNumber ?? '',
        routingNumber: investor.routingNumber ?? '',
        industries: investor.industries ?? [],

        sendInvitation: false,
        users: [],
        message: investor.invitationMessage ?? defaultMessage,
      }

      return {
        investors: [...result.investors, parsedInvestor],
        errors: [...result.errors, errors],
      }
    },
    {
      investors: [],
      errors: [],
    }
  )
}

export const getBulkImportInvestmentVehicleData = (
  validatedInvestmentVehicles: DraftValidation<BulkInvestmentVehicleFormDraftValues>[]
) => {
  return validatedInvestmentVehicles.reduce<{
    investmentVehicles: BulkInvestmentVehicleFormValues[]
    errors: ValidatedInvestmentVehiclesErrors[]
  }>(
    (result, draftValidation) => {
      const { errors: errorsToParse, object: vehicle } = draftValidation
      const errors = Object.keys(
        errorsToParse
      ).reduce<ValidatedInvestmentVehiclesErrors>((currentErrors, key) => {
        return { ...currentErrors, [key]: errorsToParse[key]?.join(', ') }
      }, {})

      const parsedInvestmentVehicle: BulkInvestmentVehicleFormValues = {
        id: randomId(),
        name: vehicle.name ?? '',
        phone: vehicle.phone ?? '',
        investor: vehicle.investor
          ? { ...vehicle.investor, isPopulated: true }
          : null,
        locations: vehicle.locations,
        shareInvestorData: vehicle.shareInvestorData,
        einTaxId: vehicle.einTaxId ?? '',
        bankName: vehicle.bankName ?? '',
        bankAccountNumber: vehicle.bankAccountNumber ?? '',
        routingNumber: vehicle.routingNumber ?? '',
      }

      return {
        investmentVehicles: [
          ...result.investmentVehicles,
          parsedInvestmentVehicle,
        ],
        errors: [...result.errors, errors],
      }
    },
    { investmentVehicles: [], errors: [] }
  )
}

export const financialInformation = (financialInfo: FinancialInformation) => {
  return (
    !!financialInfo?.taxId ||
    !!financialInfo?.bankName ||
    !!financialInfo?.bankAccount ||
    !!financialInfo?.routingNumber
  )
}

export const generalInformation = (
  generalInfo: GeneralInformation,
  investor: Investor
) => {
  return (
    !!generalInfo?.address ||
    !!generalInfo?.investmentToolIndustries.length ||
    !!generalInfo?.legalName ||
    !!generalInfo?.phone ||
    !!generalInfo?.teamSize ||
    !!generalInfo?.website ||
    !!investor?.foundedDate ||
    !!investor?.userEmails?.length
  )
}

export const socialsInformation = (contactDetails: ContactDetails) =>
  Object.values(contactDetails).some((social) => social !== null)

export const extendedInformation = (
  investor: Investor,
  isDetailsScreen: boolean
) => {
  return (
    investor?.description ||
    investor?.investmentVehicles?.length > 0 ||
    investor?.employees?.length > 0 ||
    financialInformation(investor.financialInformation!) ||
    (socialsInformation(investor.contactDetails) && !isDetailsScreen)
  )
}
