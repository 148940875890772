import {
  DiscardedReasonCodes,
  DiscardSuggestedUpdateFields,
} from 'utils/types/suggestedUpdate'
import { UpdateTypes } from 'utils/constants/updates'
import * as yup from 'yup'
import { TransactionFundType } from 'utils/constants/transactionTypes'

export const getSuggestedUpdateSchema = (isUpdateFromRequired: boolean) => {
  const updateFrom = {
    updateFrom: yup.object().when(['type', 'transactionFundType'], {
      is: (type, transactionFundType) => {
        return (
          type !== UpdateTypes.TRANSACTION ||
          (type === UpdateTypes.TRANSACTION &&
            transactionFundType === TransactionFundType.HOLDING)
        )
      },
      then: yup.object().required(),
      otherwise: yup.object(),
    }),

    investor: yup.object().when(['type', 'transactionFundType'], {
      is: (type, transactionFundType) => {
        return (
          type === UpdateTypes.TRANSACTION &&
          transactionFundType === TransactionFundType.INVESTOR
        )
      },
      then: yup.object().required(),
      otherwise: yup.object(),
    }),
  }

  return yup.object().shape({
    type: yup.string().oneOf(Object.values(UpdateTypes)).required(),
    transactionFundType: yup.string().when('type', {
      is: UpdateTypes.TRANSACTION,
      then: yup.string().oneOf(Object.values(TransactionFundType)).required(),
      otherwise: yup.string(),
    }),
    ...(isUpdateFromRequired && updateFrom),
  })
}

export const getDiscardSuggestedUpdateSchema = () => {
  return yup.object().shape({
    [DiscardSuggestedUpdateFields.Reason]: yup
      .string()
      .required()
      .oneOf(Object.values(DiscardedReasonCodes)),
    [DiscardSuggestedUpdateFields.DiscardContents]: yup.boolean(),
    [DiscardSuggestedUpdateFields.SuggestedUpdateIds]: yup.string().required(),
  })
}
