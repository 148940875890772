import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'
import { Scroll } from 'App.styles'

export const DropdownContentTopOffsetPx = 37

export const Dropdown = styled.div<{ alignSelf?: string }>`
  position: relative;
  align-self: flex-start;

  ${(props) =>
    props.alignSelf &&
    css`
      align-self: ${props.alignSelf};
    `}
`
export const Title = styled.button`
  &:focus {
    outline: none;
  }
`

interface Position {
  top?: number
  left?: number
  width?: string
}

interface DropdownContentProps {
  padding?: string
  margin?: string
  contentRight?: number
  dropdownContentMinWidth?: string
  isScrollable?: boolean
  attachToDocument?: boolean
  position?: Position
  alignLeft?: boolean
  alignSelf?: string
  closed: boolean
}

export const DropdownContent = styled.div.attrs<DropdownContentProps>(
  (props) => {
    if (props.closed) {
      if (props.attachToDocument) {
        return {
          style: {
            visibility: 'hidden',
            minWidth: 0,
            width: 0,
            position: 'fixed',
          },
        }
      }

      return {
        style: { display: 'none' },
      }
    }

    if (props.attachToDocument) {
      return {
        style: {
          left: `${props.position?.left ?? 0}px`,
          top: `${props.position?.top ?? 0}px`,
          transform: `${(() => {
            if (
              props.alignLeft &&
              props.position!.left! + 220 < window.innerWidth
            ) {
              return 'none'
            }
            const width = props.position?.width ?? 0
            return `translateX(calc(-100% + ${width}px))`
          })()}`,
        },
      }
    }

    if (props.alignLeft) {
      return {
        style: {
          left: 0,
        },
      }
    }

    if (props.margin || props.contentRight) {
      return {
        style: {
          margin: props.margin ?? '',
          right: props.contentRight ?? 0,
        },
      }
    }

    return {
      style: {
        right: 0,
      },
    }
  }
)<DropdownContentProps>`
  position: absolute;
  min-width: ${(props) => props.dropdownContentMinWidth || '22rem'};
  border-radius: 0.8rem;
  background: white;
  opacity: 1;
  z-index: 99999;
  box-shadow: 0 0 1.5rem rgba(16, 21, 39, 0.1);
  border: 1px solid ${color('veryLightGray')};
  padding: ${({ padding }) => padding || '0.8rem'};

  ${(props) =>
    props.isScrollable &&
    css`
      max-height: 20rem;
      ${Scroll()}
    `}
`

const baseDropdownItemStyles = () => css`
  height: 3.6rem;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.7rem 1.2rem 0.7rem;
  font-size: 1.4rem;
  width: 100%;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
`

export const DropdownItem = styled.button<{
  isDisabled: boolean
  isSelected: boolean
  margin: string
}>`
  ${baseDropdownItemStyles}
  cursor: pointer;
  font-weight: bold;
  border-radius: 0.8rem;
  color: ${color('darkBlue')};

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${color('primaryBlue')};
      color: ${color('white')};

      &:hover {
        background-color: ${color('primaryBlue')} !important;
        color: ${color('white')} !important;
      }
    `}

  &:focus {
    outline: none;
  }
  ${(props) =>
    props.isDisabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;

          &:hover {
            text-decoration: none;
          }
        `
      : css`
          &:hover {
            background-color: ${color('veryLightBlue', 0.2)};
            color: ${color('primaryBlue')};
          }
        `}

  svg {
    width: 2rem !important;
  }
`

export const Label = styled.span`
  margin-left: 1rem;
  white-space: nowrap;
`

export const NoClickableLabel = styled.span`
  ${baseDropdownItemStyles}
  color: #787e8c;
  border-top: 0.1rem solid #e9e9e9;
  font-weight: 400;
`

export const ItemIcon = styled(FontAwesomeIcon)``
