import { useEffect, useRef, useState } from 'react'

import { FileContent } from 'utils/types/files'

export const useImagePreview = (file: FileContent) => {
  const [isLoading, setIsLoading] = useState(true)
  const lastLoadedFileUrl = useRef(file.url)
  const [hasError, setHasError] = useState(false)

  const onLoad = () => {
    setHasError(false)
    setIsLoading(false)
    lastLoadedFileUrl.current = file.url
  }

  const onError = () => {
    onLoad()
    setHasError(true)
  }

  useEffect(() => {
    if (lastLoadedFileUrl.current !== file.url) {
      setIsLoading(true)
    }
  }, [file])

  return {
    isLoading,
    hasError,
    onLoad,
    onError,
  }
}
