import { ReactFCWithChildren } from 'utils/types/common'
import { SelectUpdatesContext } from './SelectableCardsContext'
import useSelectUpdates from './useSelectUpdates'

export type SelectUpdatesProviderProps = {
  enabled?: boolean
  enabledForSuggestedUpdates?: boolean
}

export const SelectUpdatesProvider: ReactFCWithChildren<
  SelectUpdatesProviderProps
> = ({ enabled = true, children, enabledForSuggestedUpdates = false }) => {
  const selectableCardsContext = useSelectUpdates(
    enabled,
    enabledForSuggestedUpdates
  )

  return (
    <SelectUpdatesContext.Provider value={selectableCardsContext}>
      {children}
    </SelectUpdatesContext.Provider>
  )
}
