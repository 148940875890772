/* eslint-disable react-hooks/exhaustive-deps, no-nested-ternary */
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { setShowCompanyNavigationHeader } from 'actions/companies'
import EmailService from 'api/EmailService'
import Toast from 'components/Toast'
import { useScrollToTop } from 'utils/hooks/useScrollToTop'
import useCreateEmail from 'utils/hooks/useCreateEmail'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import { useShowUpdateRoute } from 'utils/hooks/useShowUpdateRoutes'
import { EmailIndexUpdate } from '../../utils/types/update'
import {
  ScrollEnabled,
  useEmailsQuery,
  useRemoveEmailDrafts,
} from './useEmailsQuery'
import { DeleteConfirmationText } from './EmailList.styles'

export enum MailboxType {
  SENT = 'sent',
  DRAFT = 'draft',
  SCHEDULE = 'schedule',
}

export const useEmailList = () => {
  const location = useLocation<{ mailboxType?: MailboxType }>()
  const [currentMailboxType, setCurrentMailboxType] = useState<MailboxType>(
    location.state?.mailboxType || MailboxType.SENT
  )

  const scrollRef = useRef<HTMLDivElement>(null)

  useScrollToTop([currentMailboxType])

  const dispatch = useAppDispatch()
  const groupCompany = useGroupCompany()
  const intl = useIntl()
  const showUpdateRoute = useShowUpdateRoute()

  const {
    removeDraftsFromCache,
    restoreDraftsToCache,
    onDeleteDrafts,
    isDeletingDrafts,
  } = useRemoveEmailDrafts(groupCompany!.id)

  const [scrollEnabled, setScrollEnabled] = useState<ScrollEnabled>({
    emails: true,
    drafts: true,
    scheduled: false,
  })
  const { ref: infiniteScrollRef, onScreen } = useInfiniteScroll()

  const {
    feedProps,
    totalEmails,
    totalDrafts,
    totalSchedules,
    initialLoading,
  } = useEmailsQuery({
    companyId: groupCompany!.id,
    mailboxType: currentMailboxType,
    scrollEnabled,
    setScrollEnabled,
  })

  useEffect(() => {
    if (onScreen && feedProps.scrollEnabled) {
      feedProps.fetchNextPage?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen])

  useEffect(() => {
    dispatch(setShowCompanyNavigationHeader(true))
  }, [])

  const { loading: createEmailLoading, onCreateEmail } = useCreateEmail(
    groupCompany!.id
  )

  const getDeleteDraftsText = (isOneDraft: boolean) => {
    const text = isOneDraft
      ? intl.formatMessage({ id: 'emailList.deleteDraftConfirmationBody' })
      : intl.formatMessage({
          id: 'emailList.deleteMultipleDraftsConfirmationBody',
        })

    return <DeleteConfirmationText>{text}</DeleteConfirmationText>
  }

  const [modal, setModal] = useState({
    title: intl.formatMessage({ id: 'emailList.deleteDraftConfirmationTitle' }),
    body: getDeleteDraftsText(true),
    isOpen: false,
    onConfirmCallback: () => {},
  })

  const handleMailboxTypeChange = (type: MailboxType) => {
    setCurrentMailboxType(type)
  }

  const onShowEmail = (emailUpdate: EmailIndexUpdate) => {
    showUpdateRoute.fromEmails({
      updateId: emailUpdate.id,
      isDraft: emailUpdate.draft || !!emailUpdate.loggingUpdateSchedule?.id,
    })
  }

  const onEditEmail = (emailUpdate: EmailIndexUpdate) => {
    showUpdateRoute.fromEmails({
      updateId: emailUpdate.id,
      isDraft: emailUpdate.draft || !!emailUpdate.loggingUpdateSchedule?.id,
      edit: true,
    })
  }

  const onDeleteIUEDraft = async (emailUpdate: EmailIndexUpdate) => {
    try {
      await EmailService.deleteIUEDRaft(emailUpdate.item.id)
      feedProps.refetch()
    } catch (error) {
      Toast.displayIntl('emailList.deleteDraftError', 'error')
    }
  }

  const openConfirmationModal = (emailUpdate: EmailIndexUpdate) => {
    setModal({
      ...modal,
      body: getDeleteDraftsText(true),
      isOpen: true,
      onConfirmCallback: () => onDeleteIUEDraft(emailUpdate),
    })
  }

  const openDeleteMultipleDraftsModal = (
    selectedUpdates: EmailIndexUpdate[],
    callback: () => void
  ) => {
    setModal({
      ...modal,
      body: getDeleteDraftsText(selectedUpdates.length === 1),
      isOpen: true,
      onConfirmCallback: () => {
        removeDraftsFromCache(selectedUpdates)

        Toast.displayAction({
          message: intl.formatMessage(
            {
              id: 'emailList.draftsDeleted',
            },
            {
              count: selectedUpdates.length,
              plural: selectedUpdates.length > 1 ? 's' : '',
            }
          ),
          label: intl.formatMessage({ id: 'general.undoRemove' }),
          action: restoreDraftsToCache,
          afterClose: () => {
            onDeleteDrafts(selectedUpdates)
          },
        })

        callback()
      },
    })
  }

  return {
    feedProps,
    totalEmails,
    totalDrafts,
    totalSchedules,
    infiniteScrollRef,
    scrollRef,

    isDeletingDrafts,

    createEmailLoading,
    onCreateEmail,
    handleMailboxTypeChange,
    onShowEmail,
    onEditEmail,
    openConfirmationModal,
    openDeleteMultipleDraftsModal,
    currentMailboxType,
    setModal,
    modal,
    scrollEnabled,
    showZeroState:
      !totalEmails && !totalDrafts && !totalSchedules && !initialLoading,
    initialLoading,
  }
}
