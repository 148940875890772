import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useChatContext } from 'stream-chat-react'

import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getHasAnErrorConnectingTheUser } from 'features/chatSlice'
import { ReactComponent as ConnectionError } from 'assets/images/connection-error.svg'

import { Container, Subtitle, Title, Button } from './ChatErrorHandler.styles'

interface Props {
  children: JSX.Element
  smallIcon?: boolean
  padding?: string
  centerVertically?: boolean
}

const ChatErrorHandler = ({
  children,
  smallIcon,
  padding = '',
  centerVertically = false,
}: Props) => {
  const { client } = useChatContext()
  const hasAnErrorConnectingTheUser = useAppSelector(
    getHasAnErrorConnectingTheUser
  )

  const reloadPage = () => {
    window.location.reload()
  }

  if (hasAnErrorConnectingTheUser) {
    return (
      <Container padding={padding} centerVertically={centerVertically}>
        {!smallIcon && <ConnectionError />}
        <Title>
          {smallIcon && (
            <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
          )}
          <FormattedMessage id="chat.chatConnectionError" />
        </Title>
        <Subtitle>
          <FormattedMessage id="chat.chatConnectionErrorDescription" />
        </Subtitle>

        <Button onClick={reloadPage}>
          <FormattedMessage id="general.reloadPage" />
        </Button>
      </Container>
    )
  }

  if (!client) {
    return null
  }

  return children
}

export default ChatErrorHandler
