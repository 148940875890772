import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DataType } from 'ui/Table/types'
import { useTableContext } from '../TableContextProvider'
import { ExpandArrowButton } from './Row.styles'

export default function ExpandArrow<RowDataType extends DataType>({
  rowData,
}: {
  rowData: RowDataType
}) {
  const { isRowExpanded, getRowId, toggleRow, isExpandable, expandOnRowClick } =
    useTableContext()
  const rowId = getRowId(rowData)
  const isExpanded = isRowExpanded(rowId)

  if (!isExpandable && !expandOnRowClick) return null

  return (
    <ExpandArrowButton type="button" onClick={() => toggleRow(rowId, rowData)}>
      <FontAwesomeIcon
        size="3x"
        icon={['far', isExpanded ? 'angle-down' : 'angle-right']}
      />
    </ExpandArrowButton>
  )
}
