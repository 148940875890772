import { FormattedMessage } from 'react-intl'
import { useUnreadMessages } from 'containers/Chat/hooks/useUnreadMessages'
import { Container, BetaTag, UnreadContainer } from './UnreadMessages.styles'

const UnreadMessages = () => {
  const { totalUnreadMessages } = useUnreadMessages()

  return (
    <Container>
      <BetaTag>
        <FormattedMessage id="general.beta" />
      </BetaTag>
      {!!totalUnreadMessages && (
        <UnreadContainer>{totalUnreadMessages}</UnreadContainer>
      )}
    </Container>
  )
}

export default UnreadMessages
