import { useState, useCallback, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import type { FormikProps } from 'formik'

import { getIsReadOnlyMode, isActingAsFundManager } from 'selectors/auth'
import PortfolioService from 'api/PortfolioService'
import { getCreatePortfolioSchema } from 'utils/schemas/portfolios'
import { PortfolioTypes } from 'utils/constants/portfolio'
import Toast from 'components/Toast'
import { Portfolio, PortfolioType } from 'utils/types/portfolios'
import { Holding } from 'utils/types/company'
import { useRefetchCurrentGroupTier } from 'utils/hooks/queries/useGroupQuery'
import HoldingsService from 'api/HoldingsService'
import { Nullable } from 'utils/types/common'

const INITIAL_PAGE = 0
const PAGE_SIZE = 20

export type CreatePortfolioFormValues = {
  name: string
  type?: Nullable<PortfolioType>
  holdings: Holding[]
}
const useCreatePortfolio = (
  onHide: () => void,
  onPortfolioCreated: (portfolio: Portfolio) => void,
  initialPortfolioType?: PortfolioType
) => {
  const intl = useIntl()
  const formikRef = useRef<FormikProps<CreatePortfolioFormValues>>(null)
  const { refetchCurrentGroupTier } = useRefetchCurrentGroupTier()

  const [loading, setLoading] = useState(false)
  const isReadOnlyGroup = useAppSelector(getIsReadOnlyMode)

  const initialValues: CreatePortfolioFormValues = {
    type: isReadOnlyGroup ? PortfolioTypes.TRACK : initialPortfolioType,
    name: '',
    holdings: [],
  }
  const validationSchema = getCreatePortfolioSchema(intl)
  const isFundManager = useAppSelector(isActingAsFundManager)

  const loadHoldings = useCallback(
    async (searchValue: string): Promise<Holding[]> => {
      const currentHoldings = formikRef.current?.values?.holdings ?? []
      const { holdings } = await HoldingsService.getHoldings({
        filters: {
          name: searchValue || '',
          idNotIn: currentHoldings.map(({ id }) => id),
        },
        page: INITIAL_PAGE,
        companiesPerPage: PAGE_SIZE,
      })

      return holdings
    },
    []
  )

  const onCancel = () => {
    onHide()
    setTimeout(() => {
      formikRef.current?.resetForm()
    }, 1000)
  }

  const checkIfPortfolioNameExists = async (name: string) => {
    try {
      if (!name) {
        return false
      }
      const portfolios = await PortfolioService.getPortfoliosByName(name)

      const portfolioWithNameAlreadyExists = portfolios?.length > 0

      if (portfolioWithNameAlreadyExists) {
        formikRef.current?.setFieldError(
          'name',
          intl.formatMessage({ id: 'createPortfolio.portfolioAlreadyExists' })
        )
        return true
      }
      return false
    } catch (error) {
      Toast.display('createPortfolio.errorGettingPortfolioByName', 'error')
      return false
    }
  }

  const createPortfolio = async (data: CreatePortfolioFormValues) => {
    const portfolioExists = await checkIfPortfolioNameExists(data.name)

    if (!portfolioExists) {
      try {
        setLoading(true)

        const portfolio = await PortfolioService.createPortfolio({
          type: data.type,
          name: data.name,
          portfolioCompaniesAttributes:
            data.holdings?.map((company) => ({
              holdingId: company.id,
            })) ?? [],
        })
        onPortfolioCreated(portfolio)
        refetchCurrentGroupTier()
        formikRef.current?.resetForm()
        onHide()
      } catch (error) {
        Toast.display(
          intl.messages['createPortfolio.createPortfolioError'],
          'error'
        )
      } finally {
        setLoading(false)
      }
    }
  }

  return {
    formikRef,
    validationSchema,
    initialValues,
    createPortfolio,
    onCancel,
    intl,
    loadHoldings,
    loading,
    checkIfPortfolioNameExists,
    isFundManager,
  }
}

export default useCreatePortfolio
