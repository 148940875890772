import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import LAYOUT from '../../utils/constants/zerostatelayout'

const verticalHelperWrapper = () =>
  css`
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0;
    max-width: 300px;
    margin: auto;
  `

const horizontalHelperWrapper = () =>
  css`
    display: grid;
    gap: 1.7rem;
    grid-template-columns: auto auto auto auto;

    a {
      :last-child {
        grid-column: auto;
      }
    }

    @media (max-width: 1025px) {
      grid-template-columns: auto auto;

      a {
        :last-child {
          grid-column: 1/3;
        }
      }
    }
  `
export const ZeroStateContainer = styled.div<{
  layout: LAYOUT
  maxWidth?: string
  padding?: string
}>`
  ${({ maxWidth }) =>
    maxWidth &&
    css`
      max-width: ${maxWidth};
    `};

  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `};

  @media (max-width: 1025px) {
    margin: auto;
    padding: 3rem 0;
    max-width: 33rem;
  }

  @media (min-width: 1026px) {
    ${({ layout }) => {
      switch (layout) {
        case LAYOUT.VERTICAL:
          return `
            display: flex;
            /* Vertical */
            flex-direction: column;
            margin: auto;
           
            `
        default:
          return `
            display: grid;
            /* Horizontal */
            grid-template-columns: auto auto;
            column-gap: 4.3rem;
            `
      }
    }}
  }
`

export const ZeroStateFigure = styled.figure`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ZeroStateInfo = styled.div<{
  layout: LAYOUT
  infoMarginTop: string
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: ${(props) =>
    props.layout === LAYOUT.VERTICAL ? 'center' : 'start'};
  margin-top: ${(props) => props.infoMarginTop || '2.2rem'};
`

export const ZeroStateTitle = styled.h1<{
  titleFontSize?: string
}>`
  font-size: ${({ titleFontSize }) => titleFontSize || '1.8rem'};
  color: ${color('darkBlue')};
  margin: 1.5rem 0 0.9rem 0;
  font-weight: 400;
`

export const ZeroStateText = styled.p<{
  marginBottom: string
  textFontSize?: string
}>`
  font-size: ${(props) => props.textFontSize || '1.4rem'};
  color: #787e8c;
  margin-bottom: ${(props) => props.marginBottom || '1rem'};
  font-weight: 400;
  line-height: 1.7rem;
`

export const LinksContainer = styled.div`
  margin-top: 1rem;
`

export const NeedHelp = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: center;
  color: ${color('lightGray')};
  text-transform: uppercase;
  margin-bottom: 1.25rem;
`

export const HelpWrapper = styled.div<{ layout: LAYOUT }>`
  ${({ layout }) => {
    switch (layout) {
      case LAYOUT.VERTICAL:
        return verticalHelperWrapper

      default:
        return horizontalHelperWrapper
    }
  }}
`

export const LinkText = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('lightGray')};
  cursor: pointer;
  text-decoration: none;

  > svg {
    margin-right: 0.9rem;
  }
`

export const ZeroStateHelpLink = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  color: ${color('lightGray')};
  font-weight: 400;
  margin-bottom: 2rem;
  svg {
    margin-right: 1rem;
  }
`

export const Delimiter = styled.span`
  @media (max-width: 1025px) {
    display: none;
  }

  @media (min-width: 1026px) {
    display: block;
    background: ${color('veryLightGray')};
    width: 0.1rem;
    height: 3.3rem;
  }
`

export const CallToActionLink = styled.span`
  display: flex;
  justify-content: center;
  margin: auto;
  color: ${color('primaryBlue')};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  text-decoration: underline;
`
