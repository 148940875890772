import { useEffect, useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { isEscCode } from 'utils/functions/keyboardEvents'
import SecondarySideBar from '../../SecondarySideBar/SecondarySideBar'
import { useGroupSelector } from '../useGroupSelector'
import Briefing from '../../Briefing'
import { SideBarTitle } from '../../SideBar/SideBar.styles'
import { GroupListContainer } from '../GroupSelector.styles'
import CreateNewGroupButton from './CreateNewGroupButton/CreateNewGroupButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  toggleSidebar: () => void
}

const SecondarySideBarGroupSelector = ({
  isOpen,
  onClose,
  toggleSidebar,
}: Props) => {
  const { groups, currentGroup, previousGroup, onSelectGroup } =
    useGroupSelector()
  const filteredGroups = useMemo(
    () => (groups.length > 1 ? groups : []),
    [groups]
  )

  const intl = useIntl()
  const handleSelectGroup = (group) => {
    onSelectGroup(group.id)
    toggleSidebar()
  }

  const closeGroupSelectorOnEscKeyPressed = useCallback(
    (event) => {
      if (isEscCode(event)) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keyup', closeGroupSelectorOnEscKeyPressed)
    }
    return () => {
      window.removeEventListener('keyup', closeGroupSelectorOnEscKeyPressed)
    }
  }, [isOpen, closeGroupSelectorOnEscKeyPressed])

  return (
    <SecondarySideBar
      title={intl.formatMessage({ id: 'sidebar.groups' })}
      isOpen={isOpen}
      onClose={onClose}
    >
      {previousGroup && (
        <>
          <SideBarTitle padding="0 2.4rem">
            {intl.formatMessage({ id: 'sidebar.recent' })}
          </SideBarTitle>
          <Briefing
            type="group"
            name={previousGroup.name}
            logoUrl={previousGroup.logo?.mediumLogo.url}
            handle={previousGroup.handle}
            isMainBriefing={false}
            onClick={() => handleSelectGroup(previousGroup?.id)}
          />
          <SideBarTitle padding="0 2.4rem">
            {intl.formatMessage({ id: 'sidebar.allGroups' })}
          </SideBarTitle>
        </>
      )}
      <CreateNewGroupButton toggleGroupSelector={onClose} />
      <GroupListContainer>
        {filteredGroups.map((group) => (
          <Briefing
            key={group.id}
            type="group"
            name={group.name}
            logoUrl={group.logo?.mediumLogo.url}
            handle={group.handle}
            isMainBriefing={false}
            isButtonEnabled={false}
            onClick={() => handleSelectGroup(group)}
            hideSettings
            isActive={group?.id === currentGroup?.id}
          />
        ))}
      </GroupListContainer>
    </SecondarySideBar>
  )
}

export default SecondarySideBarGroupSelector
