import ToastMessage from 'components/ToastMessage'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { UpdateVisibility } from 'utils/constants/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import { Update } from 'utils/types/update'
import { getUpdateHoursLeftToEdit } from 'utils/functions/update-permissions'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'
import { EditToastMessageWrapper } from './EditToastMessage.styles'

interface EditToastMessageProps {
  createMode: boolean
  updateSharedOutsideGroup?: boolean
  update: Update
}

const EditToastMessage: React.FC<EditToastMessageProps> = ({
  update,
  createMode,
  updateSharedOutsideGroup,
}) => {
  const isFounder = useAppSelector(isActingAsFounder)
  const updatePermissions = useUpdatePermissions(update)

  if (
    (createMode && !updateSharedOutsideGroup) ||
    updatePermissions.contentHasFullPermissions ||
    !isFounder
  ) {
    return null
  }

  const getMessage = () => {
    if (createMode) {
      return (
        <FormattedMessage
          id="updates.createUpdateToastMessage"
          values={{ bold: (msg) => <b>{msg}</b> }}
        />
      )
    }

    if (!updatePermissions.isContentEditable) {
      if (
        update!.visibility === UpdateVisibility.PUBLIC &&
        !update!.ownedByGroup
      ) {
        return (
          <FormattedMessage id="updates.editUpdateToastMessageGroupManage" />
        )
      }

      return <FormattedMessage id="updates.editUpdateToastMessageExpired" />
    }

    const hoursLeft = getUpdateHoursLeftToEdit(update!.expirationDate!)
    if (update!.visibility === UpdateVisibility.PUBLIC) {
      if (update!.ownedByGroup) {
        return (
          <FormattedMessage
            id="updates.editUpdateHoursToastMessage"
            values={{
              countHours: Math.ceil(hoursLeft),
              bold: (msg) => <b>{msg}</b>,
            }}
          />
        )
      }

      return (
        <FormattedMessage
          id="updates.editUpdateHoursToastMessageGroupManage"
          values={{
            countHours: Math.ceil(hoursLeft),
            bold: (msg) => <b>{msg}</b>,
          }}
        />
      )
    }

    return (
      <FormattedMessage
        id="updates.editUpdateHoursContentToastMessage"
        values={{
          countHours: Math.ceil(hoursLeft),
          bold: (msg) => <b>{msg}</b>,
        }}
      />
    )
  }

  return (
    <EditToastMessageWrapper>
      <ToastMessage>{getMessage()}</ToastMessage>
    </EditToastMessageWrapper>
  )
}

export default EditToastMessage
