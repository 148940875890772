import { FormattedMessage } from 'react-intl'
import { UpdateAttachment } from 'utils/types/transactions'
import { usePreviewContents } from 'utils/hooks/files/usePreviewContents'
import PreviewContents from 'components/PreviewContents'
import { useForm } from 'components/Form/hooks'
import { UpdateFormContext } from 'utils/types/updateForm'

import FileGridListPreview from './components/FileGridListPreview'
import FileGridPreview from './components/FileGridPreview'
import FileListPreview from './components/FileListPreview'
import {
  AttachmentsGrid,
  Title,
  TitleWrapper,
} from './AttachmentsPreview..styles'

export type GridMode = 'grid' | 'list' | 'grid-list'

interface AttachmentsPreviewProps {
  contents: UpdateAttachment[]
  canDelete?: boolean
  handleRemoveFile?: (file: UpdateAttachment) => void
  mode: GridMode
  isDocumentType?: boolean
  isTransactionType?: boolean
  isAssociatingAttachments?: boolean
}

const AttachmentsPreview: React.FC<AttachmentsPreviewProps> = ({
  contents = [],
  handleRemoveFile,
  mode = 'grid',
  canDelete = true,
  isDocumentType,
  isTransactionType,
  isAssociatingAttachments,
}) => {
  const contentsToRender = contents.filter(
    (content) => content && !content.destroy
  )
  const { selectedContent, onClosePreview, onPreviewContent } =
    usePreviewContents()
  const { isShowMode } = useForm<UpdateFormContext>()
  const hasAttachedFiles = !!contentsToRender.length

  const title = () => {
    if (hasAttachedFiles && isShowMode && !isDocumentType) {
      return (
        <TitleWrapper isTransactionType={!!isTransactionType}>
          <Title>
            <FormattedMessage
              id="updates.transaction.files.title"
              values={{ filesCount: contentsToRender.length }}
            />
          </Title>
        </TitleWrapper>
      )
    }

    return null
  }

  const renderPreviewFiles = () => {
    if (selectedContent) {
      return (
        <PreviewContents
          contents={contents}
          selectedContent={selectedContent}
          isOpen={!!selectedContent}
          onClosePreview={onClosePreview}
        />
      )
    }

    return null
  }

  if (mode === 'grid') {
    return (
      <>
        {title()}
        <AttachmentsGrid mode="grid">
          {contentsToRender.map((content) => (
            <FileGridPreview
              key={content.id}
              content={content}
              canDelete={canDelete}
              handleDelete={() => handleRemoveFile?.(content)}
              handleOnClick={() => onPreviewContent(content)}
              isLoading={isAssociatingAttachments}
            />
          ))}
        </AttachmentsGrid>
        {renderPreviewFiles()}
      </>
    )
  }

  if (mode === 'grid-list') {
    return (
      <>
        {title()}
        <AttachmentsGrid mode="grid-list">
          {contentsToRender.map((content) => (
            <FileGridListPreview
              key={content.id}
              content={content}
              canDelete={canDelete}
              handleDelete={() => handleRemoveFile?.(content)}
              handleOnClick={() => onPreviewContent(content)}
            />
          ))}
        </AttachmentsGrid>
        {renderPreviewFiles()}
      </>
    )
  }

  return (
    <>
      {title()}
      <AttachmentsGrid mode="list">
        {contentsToRender.map((content) => (
          <FileListPreview
            key={content.id}
            content={content}
            canDelete={canDelete}
            handleDelete={() => handleRemoveFile?.(content)}
            handleOnClick={() => onPreviewContent(content)}
          />
        ))}
      </AttachmentsGrid>
      {renderPreviewFiles()}
    </>
  )
}

export default AttachmentsPreview
