import styled from 'styled-components/macro'
import Modal from 'components/Modal'
import BtnUploadFile from 'components/BtnUploadFile'
import { HelpMessage as HelpMessageBase } from 'components/TransactionsBulkImportModal/BulkImportModal.styles'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const ModalBody = styled(Modal.Body)`
  min-width: 60rem;
  padding-bottom: 0;
  padding-top: 0;
  padding-right: 0;

  @media ${maxSize.md} {
    min-width: auto;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`

export const LoaderWrapper = styled.div`
  div[class*='Loader_overlay'] {
    width: 100%;
    height: calc(100% - 5.8rem - 8.7rem);
    top: 5.8rem;
  }
`

export const FormContainer = styled.div`
  border-right: 1px solid ${color('borderGray')};
  padding-right: 2.4rem;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  min-height: 53.3rem;

  @media ${maxSize.md} {
    width: 100%;
  }
`

export const FileDraggerWrapper = styled.div`
  width: 30rem;

  div:first-child {
    padding: 0;
    height: calc(100% - 4.8rem);
  }

  svg {
    margin-top: 4.3rem;
  }

  @media ${maxSize.md} {
    display: none;
  }
`

export const Instructions = styled.div`
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-align: center;
  padding: 1.7rem 3.2rem 1.2rem;
`

export const BrowseButton = styled(BtnUploadFile)`
  flex: 1;
  cursor: pointer;

  label {
    padding: 1rem 1.6rem;
    margin: 0;
  }
`

export const HelpMessage = styled(HelpMessageBase)`
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 4.3rem;
  cursor: pointer;
  text-decoration: underline;
`

export const Footer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 0.8rem;
`
