import AnalyticsService from 'api/AnalyticsService'
import { PAGE_SIZE } from 'containers/HoldingIndex/useCompanyIndex'
import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { SortDirection } from 'utils/constants/sortDirection'
import { getTableColumns } from 'utils/functions/analytics'
import { orderArrayByObjectPropertyAndDirection } from 'utils/functions/array'
import { useAnalyticsQuery } from './useAnalyticsQuery'

export const useAnalytics = (updateId: string) => {
  const [sort, setSort] = useState({
    field: 'name',
    direction: SortDirection.ASC,
  })

  const { data: analytics = [], isLoading: isLoadingAnalytics } =
    useAnalyticsQuery(updateId)

  const intl = useIntl()

  const tableData = useMemo(() => {
    if (sort.field) {
      const res = orderArrayByObjectPropertyAndDirection(
        analytics,
        sort.field,
        sort.direction
      )
      return res
    }
    return analytics
  }, [analytics, sort])

  const tableColumns = useMemo(getTableColumns, [])

  const loadUserAnalytics = async (user, page) => {
    return AnalyticsService.fetchAnalyticsByUser({
      updateId,
      userId: user.id,
      page,
      pageSize: PAGE_SIZE,
    })
  }

  return {
    tableData,
    tableColumns,
    sort,
    setSort,
    loadUserAnalytics,
    isLoading: isLoadingAnalytics,
    intl,
  }
}
