import type { StreamMessage } from 'stream-chat-react'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getUser } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { StreamChatType } from '../types'

export const useMessageText = (
  message?: StreamMessage<StreamChatType>
): string => {
  const intl = useIntl()
  const user = useAppSelector(getUser)

  const softDeleted = useMemo(
    () => message?.selfDeletedIds?.some((id) => id === user?.id),
    [message, user]
  )

  if (!message) return ''

  const isMyMessage = message.user_id === user.id

  if (message.type === 'deleted') {
    if (isMyMessage) {
      return intl.formatMessage({ id: 'chat.youDeletedThisMessage' })
    }
    return intl.formatMessage({ id: 'chat.thisMessageWasDeleted' })
  }

  if (softDeleted) {
    return intl.formatMessage({ id: 'chat.youDeletedThisMessageOnlyForYou' })
  }

  return message.text ?? ''
}
