import ButtonToStyle from 'ui/Button'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const MainWrapper = styled.div<{
  showActionsBelowBreadcrumb: boolean
  gap?: string
}>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap || '1.6rem'};

  ${(props) =>
    props.showActionsBelowBreadcrumb &&
    css`
      justify-content: end;
      border-bottom: 0.1rem solid ${color('veryLightBlue')};
      padding: 1rem 3rem;
    `}
`

export const ArrowsWrapper = styled.div`
  display: flex;
  gap: 0.4rem;
`

export const ShareActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.3rem;

  > span {
    padding: 0.8rem;
    color: ${color('darkBlue')};
    cursor: pointer;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled(ButtonToStyle)`
  padding: 0.8rem;
  cursor: pointer;
  border: none;
  width: auto;

  > svg {
    font-size: 1.4rem;
  }

  &:hover {
    svg {
      color: ${color('primaryBlue')};
    }
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`

export const ArrowDown = styled(ButtonWrapper)`
  transform: rotate(-90deg);
`

export const ArrowUp = styled(ButtonWrapper)`
  transform: rotate(90deg);
`

export const Divider = styled.div<{ color: string }>`
  height: 2.4rem;
  width: 0.05rem;
  background-color: ${(props) => props.color};
`

export const LinkedCopiedContainer = styled.div<{ copied: boolean }>`
  background-color: ${color('primaryBlue')};
  width: 17.5rem;
  color: ${color('white')};
  font-weight: 700;
  border-radius: 0.8rem;
  padding: 0.8rem;
  position: absolute;
  font-size: 1.2rem;
  right: -5rem;
  top: 3.8rem;
  display: ${(props) => (props.copied ? 'block' : 'none')};
  border: 1px solid ${color('veryLightGray')};
  box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.1);
`

export const GlobeContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color('veryLightBlue')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Globe = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  color: ${color('white')};
  height: 1.65rem;
  width: 1.45rem;
`

export const EditActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`
