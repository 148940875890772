import React, { useRef, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Auth0Provider } from '@auth0/auth0-react'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { library } from '@fortawesome/fontawesome-svg-core'

import Toast from 'components/Toast'
import ErrorBoundary from 'components/ErrorBoundary'
import MacHeader from 'components/MacHeader'
import icons from 'assets/icons'
import { store } from 'store'
import history from 'utils/history'
import { isDesktopApp, isMacApp } from 'utils/functions/electron'
import DevEnvironmentSelector from 'components/DevEnvironmentSelector'
import BreadcrumbInitialConfiguration from 'components/Breadcrumb/Trackers/BreadcrumbInitialConfiguration'

import PrefetchQueries from 'containers/PrefetchQueries'
import theme from 'utils/theme'
import { HelmetProvider } from 'react-helmet-async'
import { scope } from 'utils/constants/auth0'
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper'
import AppRoutes from './routes/AppRoutes'
import IntlProvider from './languageProvider'
import packageJson from '../package.json'

import './reset.css'
import './App.css'
import { PROD_ENV } from './utils/functions/env'
import { useAuth0Store } from './utils/auth0-store'

library.add(...icons)

const integrations = [
  new Integrations.BrowserTracing({
    tracingOrigins: [
      'testing.api.cwuniverse.app',
      'develop.api.cwuniverse.app',
      'staging.api.cwuniverse.app',
      'api.cwuniverse.app',
    ],
    beforeNavigate: (context) => {
      return {
        ...context,
        name: window.location.pathname.replace(/[a-f0-9-]{36}/g, ':id'),
      }
    },
  }),
]

if (PROD_ENV) {
  integrations.push(
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    })
  )
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_CUSTOM_NODE_ENV,
  release: `v${packageJson.version}`,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations,
  tracesSampler: () => {
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'production') {
      return 0.5
    }
    if (process.env.REACT_APP_CUSTOM_NODE_ENV === 'testing') {
      return 0.01
    }
    return 0.1
  },
})

const App = () => {
  const auth0Domain = useAuth0Store((state) => state.auth0Domain)
  const setAuth0Domain = useAuth0Store((state) => state.setAuth0Domain)

  const queryClient = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
        },
      },
    })
  )

  useEffect(() => {
    if (!auth0Domain) {
      const fetchAuth0Domain = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/auth0_domain`
        )
        const data = await response.json()
        setAuth0Domain(data.auth0_domain)
      }

      fetchAuth0Domain()
    }
  }, [auth0Domain, setAuth0Domain])

  if (!auth0Domain) {
    return null
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope,
        redirect_uri: `${window.location.origin}/redirect/auth0`,
      }}
      useRefreshTokensFallback
      cacheLocation={isDesktopApp() ? 'localstorage' : undefined}
    >
      <Provider store={store}>
        <HelmetProvider>
          <IntlProvider>
            <Router history={history}>
              <ErrorBoundary>
                <Toast>
                  <ServiceWorkerWrapper>
                    <ThemeProvider theme={theme}>
                      <PrefetchQueries queryClient={queryClient.current}>
                        <QueryClientProvider
                          client={queryClient.current}
                          contextSharing
                        >
                          <BreadcrumbInitialConfiguration />
                          <ReactQueryDevtools initialIsOpen={false} />
                          {isMacApp() && <MacHeader />}
                          <AppRoutes />
                        </QueryClientProvider>
                      </PrefetchQueries>
                    </ThemeProvider>
                  </ServiceWorkerWrapper>
                </Toast>
              </ErrorBoundary>
            </Router>
          </IntlProvider>
        </HelmetProvider>
      </Provider>
    </Auth0Provider>
  )
}

export default App
