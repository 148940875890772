import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import { type ToastType } from 'components/Toast'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

export const Wrapper = styled(motion.div)<{ type: ToastType }>`
  border-radius: 0.8rem;
  color: ${color('white')({ theme })};
  right: 4rem;
  padding: 1.6rem;
  position: absolute;
  top: 4rem;
  max-width: 60rem;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  background: ${({ type }) => {
    switch (type) {
      case 'success':
        return color('green')({ theme })
      case 'error':
        return color('red')({ theme })
      case 'warning':
        return 'var(--color-yellow-default)'
      case 'info':
      case 'action':
      default:
        return color('primaryBlue')({ theme })
    }
  }};

  ${({ type }) =>
    type === 'error' &&
    css`
      gap: 2.6rem;
    `}
`

export const Content = styled.div`
  align-items: center;
  white-space: nowrap;
  display: flex;
  font-size: 1.4rem;
  height: 100%;

  > svg {
    font-size: 1.6rem;
  }

  > span {
    margin-left: 1.2rem;
    white-space: break-spaces;
    max-width: 41.3rem;
    line-height: normal;
  }
`

export const UndoButton = styled.div`
  flex: 1;
  white-space: nowrap;
  text-align: end;
  padding: 0 2rem 0 2.4rem;

  button {
    font-weight: bold;
    border-bottom: 1px solid ${color('white')({ theme })};
  }
`

export const CloseToast = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${color('white')({ theme })};
  width: 1.6rem;

  && {
    font-size: 1.8rem;
  }
`
