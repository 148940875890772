import { useInvestorQuery } from 'utils/hooks/investorManagement/useInvestorsQuery'

const useInvestorDetails = (id) => {
  const { data: investor, isLoading } = useInvestorQuery(id)

  return {
    investor,
    isLoading,
  }
}

export default useInvestorDetails
