import React from 'react'
import { useRandomId } from 'utils/hooks/useRandomId'
import theme from 'utils/theme'
import * as Styles from './CellErrorTooltip.styles'

export enum CellErrorTooltipColor {
  WHITE = 'white',
  RED = 'red',
}

interface CellErrorTooltipProps {
  content: React.ReactNode
  children: React.ReactNode
  clickable?: boolean
  color?: CellErrorTooltipColor
  cellWidth?: number
}

const CellErrorTooltip: React.FC<CellErrorTooltipProps> = ({
  content,
  children,
  clickable = true,
  color = CellErrorTooltipColor.WHITE,
  cellWidth,
}) => {
  const id = useRandomId()

  return (
    <Styles.FullCellTooltip
      id={id}
      text={
        content && (
          <Styles.ContentWrapper
            cellWidth={cellWidth}
            onPointerDown={(e) => e.stopPropagation()}
          >
            {content}
          </Styles.ContentWrapper>
        )
      }
      place="bottom"
      clickable={clickable}
      offset={1}
      parentTooltipStyles={{
        width: 'fit-content',
        backgroundColor:
          color === CellErrorTooltipColor.RED
            ? theme.colors.red
            : theme.colors.white,
        color:
          color === CellErrorTooltipColor.RED
            ? theme.colors.white
            : theme.colors.darkGray,
        border: `0.1rem solid ${theme.colors.veryLightGray}`,
        boxShadow: '0px 0px 15px 0px rgba(16, 21, 39, 0.1)',
      }}
    >
      {children}
    </Styles.FullCellTooltip>
  )
}

export default CellErrorTooltip
