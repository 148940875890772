import { useIntl } from 'react-intl'
import { Formik } from 'formik'

import Drawer from 'components/Drawer'
import Wizard from 'components/Wizard'
import FadeIn from 'components/FadeIn'
import Button from 'ui/Button'
import {
  CreateInboundSteps,
  InboundForm,
  InboundStatus,
} from 'utils/types/inbounds'

import { initialInboundForm, useCreateInbound } from './useCreateInbound'
import { Numbers, WizardHeader } from './CreateInbound.styles'
import TypeOfAccountStep from './steps/TypeOfAccountStep'
import EmailAddressStep from './steps/EmailAddressStep'
import ConnectStep from './steps/ConnectStep'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const CreateInbound = ({ isOpen, onClose }: Props) => {
  const intl = useIntl()
  const {
    stepsHandlers,
    validationSchema,
    formikRef,
    inboundEmail,
    status,
    isLoading,
    isTestingConnection,
    getFooterActionText,
    getFooterSecondaryActionText,
    handleOnSubmit,
    onActionButtonClick,
    onClose: onCloseDrawer,
  } = useCreateInbound({ onCloseDrawer: onClose, isOpen })

  return (
    <Drawer
      isOpen={isOpen}
      onCloseDrawer={onCloseDrawer}
      headerTitle={intl.formatMessage({ id: 'inbounds.newInbound' })}
    >
      <Formik<InboundForm>
        innerRef={formikRef}
        onSubmit={handleOnSubmit}
        initialValues={initialInboundForm}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <>
            <Drawer.Content>
              <WizardHeader>
                <Wizard
                  activeStep={stepsHandlers.currentStep}
                  onSelectStep={stepsHandlers.changeStep}
                >
                  <Wizard.Step
                    id={CreateInboundSteps.ACCOUNT_TYPE}
                    name={intl.formatMessage({
                      id: 'inbounds.accountType',
                    })}
                  >
                    <Numbers>1</Numbers>
                  </Wizard.Step>

                  <Wizard.Step
                    id={CreateInboundSteps.EMAIL_ADDRESS}
                    name={intl.formatMessage({
                      id: 'inbounds.emailAddress',
                    })}
                  >
                    <Numbers>2</Numbers>
                  </Wizard.Step>

                  <Wizard.Step
                    id={CreateInboundSteps.CONNECT}
                    name={intl.formatMessage({
                      id: 'inbounds.connect',
                    })}
                  >
                    <Numbers>3</Numbers>
                  </Wizard.Step>
                </Wizard>
              </WizardHeader>

              <Wizard.Content
                id={CreateInboundSteps.ACCOUNT_TYPE}
                activeStep={stepsHandlers.currentStep!}
              >
                <TypeOfAccountStep />
              </Wizard.Content>

              <Wizard.Content
                id={CreateInboundSteps.EMAIL_ADDRESS}
                activeStep={stepsHandlers.currentStep!}
              >
                <EmailAddressStep goToNextStep={stepsHandlers.goToNextStep} />
              </Wizard.Content>

              <Wizard.Content
                id={CreateInboundSteps.CONNECT}
                activeStep={stepsHandlers.currentStep!}
              >
                <ConnectStep
                  status={status}
                  isTestingConnection={isTestingConnection}
                  inboundEmail={inboundEmail!}
                />
              </Wizard.Content>
            </Drawer.Content>
            <FadeIn>
              <Drawer.Footer>
                {status !== InboundStatus.ACTIVE && (
                  <Button onClick={onCloseDrawer}>
                    {getFooterSecondaryActionText()}
                  </Button>
                )}
                <Button
                  type="submit"
                  primary
                  disabled={!formik.isValid || isLoading || isTestingConnection}
                  onClick={onActionButtonClick}
                  data-testid="create-inbound-button"
                  isLoading={isLoading || isTestingConnection}
                >
                  {getFooterActionText()}
                </Button>
              </Drawer.Footer>
            </FadeIn>
          </>
        )}
      </Formik>
    </Drawer>
  )
}

export default CreateInbound
