import styled from 'styled-components'

export const Container = styled.div`
  margin-top: -1.5rem;

  .str-chat.messaging {
    border-right: initial;
  }

  .str-chat * {
    font-family: 'Lato', sans-serif;
  }
`
