import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import {
  RecipientsSummaryContainer,
  RecipientSummaryItem,
  RecipientsSummaryTitle,
  RecipientsSummarySubtitle,
} from './RecipientsSummary.styles'

const RecipientsSummary = ({ data }) => {
  return (
    <RecipientsSummaryContainer>
      <RecipientSummaryItem>
        <RecipientsSummaryTitle>
          <FontAwesomeIcon icon={['far', 'paper-plane']} />{' '}
          {data?.countDelivered}
        </RecipientsSummaryTitle>

        <RecipientsSummarySubtitle>
          <FormattedMessage id="showEmail.totalDelivered" />
        </RecipientsSummarySubtitle>
      </RecipientSummaryItem>

      <RecipientSummaryItem>
        <RecipientsSummaryTitle>
          <FontAwesomeIcon icon={['far', 'eye']} /> {data?.openRate}%
        </RecipientsSummaryTitle>

        <RecipientsSummarySubtitle>
          <FormattedMessage id="showEmail.openRate" />
        </RecipientsSummarySubtitle>
      </RecipientSummaryItem>
    </RecipientsSummaryContainer>
  )
}

RecipientsSummary.propTypes = {
  data: PropTypes.shape({
    countDelivered: PropTypes.number,
    openRate: PropTypes.number,
  }),
}

RecipientsSummary.defaultProps = {
  data: {},
}

export default RecipientsSummary
