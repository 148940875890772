import { Nullable } from 'utils/types/common'

export default class GoogleApiService {
  static async getPlaceData(
    placeId: string
  ): Promise<google.maps.GeocoderResult> {
    const geocoder = new google.maps.Geocoder()
    const { results } = await geocoder.geocode({ placeId })

    if (results.length > 0) {
      return results[0]
    }
    throw new Error('No results found')
  }

  static async getPlaceDataAutocomplete(
    address: string
  ): Promise<Nullable<google.maps.places.AutocompletePrediction[]>> {
    try {
      const autocomplete = new google.maps.places.AutocompleteService()
      const places = await autocomplete.getPlacePredictions({
        input: address,
      })

      return places?.predictions || []
    } catch {
      return null
    }
  }
}
