import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CouponCodeContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  padding-top: 1.4rem;
  margin-top: 2.5rem;
  border-top: 1px solid #e9e9e9;
`

export const CouponError = styled.div`
  color: ${color('red')};
  padding-top: 1rem;
  font-size: 1.3rem;
`
