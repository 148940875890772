import React from 'react'
import PropTypes from 'prop-types'

import { IntlProvider as ReactIntlProvider } from 'react-intl'
import flatten from 'flat'

import enUSLanguage from './entries/en-US'

const AppLocale = {
  en: enUSLanguage,
}

export const getIntlConfig = () => {
  const locale = 'en-US'
  const currentAppLocale =
    locale in AppLocale ? AppLocale[locale] : AppLocale.en

  return {
    locale,
    messages: flatten(currentAppLocale.messages),
  }
}

const IntlProvider = ({ children }) => {
  const { messages, locale } = getIntlConfig()
  return (
    <ReactIntlProvider locale={locale} messages={messages}>
      {children}
    </ReactIntlProvider>
  )
}

IntlProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
}

export default IntlProvider
