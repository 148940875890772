import DefaultModal from 'components/Modal'
import styled from 'styled-components'

export const Modal = styled(DefaultModal)`
  width: 100%;
  max-width: 70rem;
  max-height: 98vh;
  @media (max-width: 720px) {
    max-height: 100vh;
  }

  div[class*='modalBody'] {
    overflow: auto;
  }
`

export const Header = styled.div`
  font-family: Raleway;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.8px;
`
