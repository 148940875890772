import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { maxSize, size } from 'utils/constants/breakpoint'
import { ProfileType } from '../types'

export const StyledLayout = styled.div`
  @media ${size.tabletLg} {
    display: grid;

    grid-template-columns: 38.3rem 1fr;
    grid-template-areas:
      'header header header'
      'sidebar main main'
      'sidebar main main';
  }
`

export const Header = styled.header<{ type: ProfileType }>`
  grid-area: header;
  height: 33rem;
  position: relative;
  overflow: hidden;

  @media ${size.lg} {
    height: 19rem;
    display: grid;
    grid-template-columns: 38.3rem 1fr;
    grid-template-areas: 'sidebar main main';
  }

  ${(props) => {
    switch (props.type) {
      case ProfileType.COMPANY:
        return css`
          background-color: ${color('gray')};
        `
      case ProfileType.FUND_PORTFOLIO:
        return css`
          background-color: ${color('blueWhale')};
        `
      case ProfileType.FUND_COMPANY:
        return css`
          background-color: ${color('bigStone')};
        `
      case ProfileType.INVESTMENT_VEHICLE:
        return css`
          background-color: ${color('revolver')};
        `

      case ProfileType.INVESTOR:
        return css`
          background-color: ${color('portGore')};
        `

      default:
        return css`
          background-color: ${color('gray')};
        `
    }
  }}
`

export const MainContent = styled.div`
  grid-area: main;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 3.2rem 3.2rem 0 0;

  @media ${maxSize.tabletLg} {
    padding: 2.4rem 3.2rem;
    gap: 2.4rem;
  }
`

export const SideContent = styled.div`
  grid-area: sidebar;
  height: auto;
  padding: 0 3.2rem;
`

export const LogoWrapper = styled.div`
  position: absolute;
  opacity: 0.3;

  > svg {
    position: relative;
    height: 71rem;
    width: 122rem;
    top: -47rem;
    left: -31rem;

    @media ${size.md} {
      left: -18rem;
      margin-top: 3rem;
      height: 61rem;
      width: 85rem;
    }

    @media ${size.lg} {
      left: -15rem;
      margin-top: -2rem;
      height: 69rem;
      width: 88rem;
    }
  }
`
