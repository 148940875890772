import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import {
  EmojiPicker,
  useMessageInputContext,
  ChatAutoComplete,
  MessageInputContextProvider,
} from 'stream-chat-react'

import { useMessageInput } from './useMessageInput'
import {
  ActionsContainer,
  AutoCompleteWrapper,
  Container,
  EmojiButton,
  SendButton,
} from './MessageInput.styles'

const MessageInput = () => {
  const intl = useIntl()
  const messageInputContext = useMessageInputContext()
  const messageInputContextWithOverrides = {
    ...messageInputContext,
    maxRows: 3,
  }
  const {
    handleSubmit,
    openEmojiPicker,
    closeEmojiPicker,
    text,
    emojiPickerIsOpen,
  } = messageInputContext
  const { onChange } = useMessageInput()

  return (
    <Container>
      <AutoCompleteWrapper>
        <MessageInputContextProvider value={messageInputContextWithOverrides}>
          <ChatAutoComplete
            onChange={onChange}
            placeholder={intl.formatMessage({
              id: 'chat.inputPlaceholder',
            })}
          />
        </MessageInputContextProvider>
        <ActionsContainer>
          <EmojiButton
            onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
          >
            <FontAwesomeIcon icon={['far', 'smile']} />
          </EmojiButton>
          <SendButton onClick={handleSubmit} disabled={!text}>
            <FontAwesomeIcon icon={['far', 'paper-plane']} />
          </SendButton>
        </ActionsContainer>
      </AutoCompleteWrapper>
      <EmojiPicker />
    </Container>
  )
}

export default MessageInput
