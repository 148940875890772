import styled from 'styled-components'
import InputToStyle from 'ui/Input'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  position: relative;
  display: flex;
  max-width: 43rem;
`

export const MenuContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  z-index: 1;
  top: 4.1rem;
  max-height: 26rem;
  padding: 0.8rem;
  overflow-y: auto;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  background: ${color('white')};
  width: 100%;

  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

export const Input = styled(InputToStyle)`
  width: 100%;
`

export const ZeroState = styled.div`
  color: ${color('lightGray')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.4rem;
  padding: 0.2rem 0;
  margin-top: 0.5rem;
  margin-left: 0.8rem;
  height: 23rem;
`
