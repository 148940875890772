import { getSubjectPath, getUpdateUrlType } from 'utils/functions/updates'
import { EntityName } from 'utils/hooks/useEntityFromUrl'
import { UpdateItemApi } from 'utils/types/api/commons'
import { LoggingUpdateApi } from 'utils/types/api/files'
import { UpdateType } from 'utils/types/common'
import {
  CreateDraftUpdatePayload,
  EditDraftUpdatePayload,
  UpdateTypeKey,
} from 'utils/types/updatePayloads'
import axiosClient from './httpClient'
import { ApiEntity, ApiShowResponse } from './types'

class DraftUpdateService {
  static async createDraftUpdate<T extends UpdateTypeKey>({
    subject,
    subjectId,
    draftUpdate,
    updateType,
  }: {
    subject: EntityName
    subjectId: string
    draftUpdate: CreateDraftUpdatePayload<T>
    updateType: UpdateType
  }): Promise<{ draftUpdateItemId: string; draftUpdateId: string }> {
    const subjectPath = getSubjectPath(subject, subjectId)
    const updatePath = getUpdateUrlType(updateType)
    const url = `/${subjectPath}/${updatePath}/create_as_draft`

    const response = await axiosClient().post<
      ApiShowResponse<{
        notes?: ApiEntity<UpdateItemApi>
        documents?: ApiEntity<UpdateItemApi>
        announcements?: ApiEntity<UpdateItemApi>
        transactions?: ApiEntity<UpdateItemApi>
        loggingUpdates: ApiEntity<LoggingUpdateApi>
      }>
    >(url, draftUpdate)

    const updateItemEntities =
      response.data.entities.notes ??
      response.data.entities.announcements ??
      response.data.entities.documents ??
      response.data.entities.transactions

    const updateItem = updateItemEntities![response.data.result]
    return {
      draftUpdateItemId: updateItem.id,
      draftUpdateId: updateItem.loggingUpdate,
    }
  }

  static async editDraftUpdate<T extends UpdateTypeKey>({
    updateId,
    draftUpdate,
    updateType,
  }: {
    updateId: string
    draftUpdate: EditDraftUpdatePayload<T>
    updateType: UpdateType
  }) {
    const updatePath = `${getUpdateUrlType(updateType)}/${updateId}`
    const url = `/${updatePath}/update_as_draft`

    return axiosClient().patch<
      ApiShowResponse<{
        notes?: ApiEntity<UpdateItemApi>
        documents?: ApiEntity<UpdateItemApi>
        announcement?: ApiEntity<UpdateItemApi>
        loggingUpdates: ApiEntity<LoggingUpdateApi>
      }>
    >(url, draftUpdate)
  }

  static async publishDraftUpdate(updateId: string, updateType: UpdateType) {
    const url = `/${getUpdateUrlType(updateType)}/${updateId}/publish_draft`
    const response = await axiosClient().patch(url)

    return {
      loggingUpdateId: Object.keys(
        response.data.entities.loggingUpdates || response.data.entities.updates
      )[0],
    }
  }

  static async discardDraftUpdate(updateId: string, updateType: UpdateType) {
    const url = `/${getUpdateUrlType(updateType)}/${updateId}/discard_draft`
    return axiosClient().patch(url)
  }
}

export default DraftUpdateService
