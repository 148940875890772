import { Badge } from './CountBadge.styles'

interface CountBadgeProps {
  unreadCount: number
  perfectCircle?: boolean
}

const CountBadge = ({ unreadCount, perfectCircle }: CountBadgeProps) => {
  return unreadCount > 0 ? (
    <Badge perfectCircle={perfectCircle}>{unreadCount}</Badge>
  ) : null
}

export default CountBadge
