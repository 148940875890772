import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { Panel, PanelResizeHandle } from 'react-resizable-panels'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const SIDEBAR_WIDTH = 258
export const MIN_SIDEBAR_WIDTH = 100

export const Container = styled(Panel)`
  position: relative;
  background-color: ${color('white')};
  padding-top: 2rem;
  overflow: auto;
  min-width: ${MIN_SIDEBAR_WIDTH}px;
  max-width: ${SIDEBAR_WIDTH}px;
  width: ${SIDEBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  overflow-y: auto;
  max-height: 100%;
  flex: 1;
  scroll-behavior: smooth;
`

export const ResizeHandle = styled.div`
  width: 0.1rem;
`

export const StyledPanelResizeHandle = styled(PanelResizeHandle)`
  z-index: 1;
  width: 0.1rem;
`

export const ArchivedButtonContainer = styled.div<{
  archiveSidebarActive: boolean
}>`
  padding: 0.7rem 1.2rem 0.7rem 1.3rem;
  margin: -0.4rem 0 0.1rem;
  display: flex;
  align-items: center;
  gap: 0.9rem;
  color: ${color('gray')};
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;

  ${({ archiveSidebarActive }) =>
    archiveSidebarActive
      ? css`
          color: ${color('white')};
          background: ${color('primaryBlue')};
        `
      : css`
          :hover {
            color: ${color('primaryBlue')};
            cursor: pointer;
          }
        `}

  @media ${maxSize.mobile} {
    padding: 0.7rem 0 0.7rem 2.7rem;
    margin: 1.3rem 0 0;
  }
`

export const ArchivedIcon = styled.div`
  display: flex;
  width: 2.2rem;
  height: 2.2rem;
  padding: 1.1rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.8rem;
  box-shadow: inset 0 0 0 0.1rem ${color('veryLightGray', 20)};
  font-size: 1.2rem;
`

export const ArchivedLabel = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
