import { Redirect, useParams, useRouteMatch } from 'react-router-dom'

const InvestorUpdatesRoutesRedirect = () => {
  const matchesUpdatesRoutes = useRouteMatch('/updates')

  const { updateId, updateType, holdingType, holdingId } = useParams<{
    updateId: string
    updateType: string
    holdingId?: string
    holdingType?: string
  }>()

  if (matchesUpdatesRoutes) {
    return <Redirect to={`/profile/${updateType}/${updateId}`} />
  }
  return (
    <Redirect
      to={`/fund-holdings/${holdingType}/${holdingId}/${updateType}/${updateId}`}
    />
  )
}

export default InvestorUpdatesRoutesRedirect
