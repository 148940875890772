import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Channel } from 'stream-chat'
import { useChatContext, Channel as ChannelComponent } from 'stream-chat-react'

import {
  StreamChatType,
  TopicChannel as TopicChannelType,
} from 'containers/Chat/types'
import TooltipContent from 'containers/Chat/components/TooltipContent'
import Tooltip from 'components/Tooltip'
import {
  ChannelContainer,
  ChannelTitle,
  ChannelTitleContainer,
  ChannelUpdate,
  Message,
  MessageTimestamp,
} from 'containers/Chat/components/TopicSidebar/components/TopicChannel/TopicChannel.styles'
import TopicChannelMessage from 'containers/Chat/components/TopicSidebar/components/TopicChannelMessage'
import { useChatTitle, useSetActiveChannel } from 'containers/Chat/hooks'

interface TopicChannelProps {
  channel: Channel<StreamChatType<TopicChannelType>>
}

const ArchivedChannel = ({ channel }: TopicChannelProps) => {
  const intl = useIntl()
  const { channel: activeTopic } =
    useChatContext<StreamChatType<TopicChannelType>>()
  const setActiveChannel = useSetActiveChannel<TopicChannelType>()
  const channelData = channel.data
  const lastMessage = channel.lastMessage()
  const isSelected = channel.id === activeTopic?.id
  const channelTitle = useChatTitle(channel)

  const fromText = useMemo(
    () =>
      `${intl.formatMessage({ id: 'common.from' })}: ${channelData?.topicName}${
        channelData?.updateTitle ? ` - ${channelData?.updateTitle}` : ''
      }`,
    [channelData?.topicName, channelData?.updateTitle, intl]
  )

  const handleChannelClick = () => {
    setActiveChannel(channel)
  }

  return (
    <ChannelContainer
      key={channel.id}
      isSelected={isSelected}
      onMouseDown={handleChannelClick}
    >
      <ChannelUpdate isSelected={isSelected}>
        <Tooltip
          offset={-10}
          place="top"
          backgroundColor="transparent"
          delayShow={500}
          text={<TooltipContent>{fromText}</TooltipContent>}
        >
          {fromText}
        </Tooltip>
      </ChannelUpdate>
      <ChannelTitleContainer>
        <Tooltip
          offset={-10}
          place="top"
          backgroundColor="transparent"
          delayShow={500}
          text={<TooltipContent>{channelTitle}</TooltipContent>}
        >
          <ChannelTitle>{channelTitle}</ChannelTitle>
        </Tooltip>
        {lastMessage && (
          <MessageTimestamp showToday={false} message={lastMessage} />
        )}
      </ChannelTitleContainer>
      <Message>
        <TopicChannelMessage message={lastMessage} />
      </Message>
    </ChannelContainer>
  )
}

export default ({ channel }: TopicChannelProps) => (
  <ChannelComponent channel={channel}>
    <ArchivedChannel channel={channel} />
  </ChannelComponent>
)
