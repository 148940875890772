import { Cell, Pie, PieChart } from 'recharts'
import theme from 'utils/theme'

interface RadiusType {
  innerRadius: number
  outerRadius: number
}

interface SkeletonPieChartProps {
  outerPieRadius: RadiusType
  innerPieRadius?: RadiusType
  width: number
  height: number
}

const SkeletonPieChart = ({
  outerPieRadius,
  innerPieRadius,
  width,
  height,
}: SkeletonPieChartProps) => {
  return (
    <PieChart width={width} height={height}>
      <Pie
        id="value"
        data={[
          {
            name: 'Skeleton',
            value: 1,
          },
        ]}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        innerRadius={outerPieRadius.innerRadius}
        outerRadius={outerPieRadius.outerRadius}
      >
        <Cell key="skeleton" fill={theme.colors.defaultSkeletonShine} />
      </Pie>
      {innerPieRadius && (
        <Pie
          id="value"
          data={[
            {
              name: 'Skeleton',
              value: 1,
            },
          ]}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={innerPieRadius.innerRadius}
          outerRadius={innerPieRadius.outerRadius}
        >
          <Cell key="skeleton" fill={theme.colors.defaultSkeletonShine} />
        </Pie>
      )}
    </PieChart>
  )
}

export default SkeletonPieChart
