import styled from 'styled-components'
import { color as colorFn } from 'utils/functions/colors'

const DEFAULT_DOT_HEIGHT = 0.8
const DEFAULT_DOTS_SPACE = 0.8
const MIDDLE_DOT_POSITION = 1000.3

interface Props {
  color?: string
  animationName?: string
  dotHeight?: number
  dotSpace?: number
}

const getColor = ({ color }: Props) => {
  return color ?? '#667085'
}

const getAnimationName = ({ color, animationName }: Props) => {
  if (animationName) {
    return animationName
  }

  return color ? `dot-typing-${color}` : 'dot-typing'
}

const getDotHeight = ({ dotHeight }: Props) => dotHeight ?? DEFAULT_DOT_HEIGHT

const getInitialPosition = ({ dotSpace }: Props) =>
  dotSpace
    ? MIDDLE_DOT_POSITION - dotSpace
    : MIDDLE_DOT_POSITION - DEFAULT_DOTS_SPACE

const getMiddlePosition = () => MIDDLE_DOT_POSITION

const getFinalPosition = ({ dotSpace }: Props) =>
  dotSpace
    ? MIDDLE_DOT_POSITION + dotSpace
    : MIDDLE_DOT_POSITION + DEFAULT_DOTS_SPACE

export const Dots = styled.div<Props>`
  position: relative;
  left: -999.9rem;
  min-width: 0.4rem;
  min-height: 0.4rem;
  max-width: 0.4rem;
  max-height: 0.4rem;
  border-radius: 0.5rem;
  background-color: ${getColor};
  color: ${getColor};
  box-shadow: ${getInitialPosition}rem 0 0 0 ${getColor},
    ${getMiddlePosition}rem 0 0 0 ${getColor},
    ${getFinalPosition} 0 0 0 ${getColor};
  animation: ${getAnimationName} 1.5s infinite linear;
  margin: 0.2rem 1rem 0.2rem 0;

  @keyframes ${getAnimationName} {
    0% {
      box-shadow: ${getInitialPosition}rem 0 0 0 ${getColor},
        ${getMiddlePosition}rem 0 0 0 ${getColor},
        ${getFinalPosition}rem 0 0 0 ${getColor};
    }
    16.667% {
      box-shadow: ${getInitialPosition}rem -${getDotHeight}rem 0 0 #98a2b3,
        ${getMiddlePosition}rem 0 0 0 ${getColor},
        ${getFinalPosition}rem 0 0 0 ${getColor};
    }
    33.333% {
      box-shadow: ${getInitialPosition}rem 0 0 0 ${getColor},
        ${getMiddlePosition}rem 0 0 0 ${getColor},
        ${getFinalPosition}rem 0 0 0 ${getColor};
    }
    50% {
      box-shadow: ${getInitialPosition}rem 0 0 0 ${getColor},
        ${getMiddlePosition}rem -${getDotHeight}rem 0 0 #98a2b3,
        ${getFinalPosition}rem 0 0 0 ${getColor};
    }
    66.667% {
      box-shadow: ${getInitialPosition}rem 0 0 0 ${getColor},
        ${getMiddlePosition}rem 0 0 0 ${getColor},
        ${getFinalPosition}rem 0 0 0 ${getColor};
    }
    83.333% {
      box-shadow: ${getInitialPosition}rem 0 0 0 ${getColor},
        ${getMiddlePosition}rem 0 0 0 ${getColor},
        ${getFinalPosition}rem -${getDotHeight}rem 0 0 #98a2b3;
    }
    100% {
      box-shadow: ${getInitialPosition}rem 0 0 0 ${getColor},
        ${getMiddlePosition}rem 0 0 0 ${getColor},
        ${getFinalPosition}rem 0 0 0 ${getColor};
    }
  }
`

export const Text = styled.div`
  font-size: 1.2rem;
  color: ${colorFn('lightGray')};
  padding-left: 1rem;
`

export const Container = styled.div`
  position: absolute;
  bottom: -2rem;
  padding-left: 3rem;
  z-index: 1;

  display: flex;
  align-items: center;
`
