/* eslint-disable react/jsx-no-useless-fragment */
import { lazy } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import PortfolioLogs from 'components/PortfolioLogs/PortfolioLogs'
import PaddedLayout from 'layouts/PaddedLayout/PaddedLayout'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import AccountingRoutes from './AccountingRoutes'
import SidebarMetricsRoutes from './SidebarMetricsRoutes'

const Investments = lazy(() => import('containers/Investments'))
const PreviewFundProfile = lazy(() => import('containers/PreviewFundProfile'))
const RouteContentSelector = lazy(
  () => import('containers/Investments/RouteContentSelector')
)
const NotesRoutes = lazy(() => import('./NotesRoutes'))
const AnnouncementsRoutes = lazy(() => import('./AnnouncementsRoutes'))
const DocumentsRoutes = lazy(() => import('./DocumentsRoutes'))
const TransactionsRoutes = lazy(() => import('./TransactionsRoutes'))
const EmailsRoutes = lazy(() => import('./EmailsRoutes'))

const InvestmentsRoutes = () => {
  const { path: subPath } = useRouteMatch()
  const planFeatures = usePlanFeatures()

  const routes = [
    {
      path: `${subPath}/:portfolioId/notes`,
      key: 'notes',
      component: (
        <PaddedLayout>
          <NotesRoutes />
        </PaddedLayout>
      ),
    },
    {
      path: `${subPath}/:portfolioId/announcements`,
      key: 'announcements',
      component: (
        <PaddedLayout>
          <AnnouncementsRoutes />
        </PaddedLayout>
      ),
    },
    {
      path: `${subPath}/:portfolioId/documents`,
      key: 'documents',
      component: (
        <PaddedLayout>
          <DocumentsRoutes />
        </PaddedLayout>
      ),
    },
    {
      path: `${subPath}/:portfolioId/transactions`,
      key: 'transactions',
      component: (
        <PaddedLayout>
          <TransactionsRoutes />
        </PaddedLayout>
      ),
    },
    {
      path: `${subPath}/:portfolioId/accounting`,
      key: 'accounting',
      component: (
        <PaddedLayout>
          <AccountingRoutes />
        </PaddedLayout>
      ),
    },
    {
      path: `${subPath}/:portfolioId/emails`,
      key: 'emails',
      component: (
        <PaddedLayout>
          <EmailsRoutes />
        </PaddedLayout>
      ),
    },
    {
      path: [
        `${subPath}`,
        `${subPath}/investments`,
        `${subPath}/all-followed-holdings`,
        `${subPath}/filters`,
        `${subPath}/:type/:id`,
        `${subPath}/:type/:id/updates`,
        `${subPath}/:type/:id/chat`,
        `${subPath}/:type/:id/investors`,
        `${subPath}/:type/:id/portfolio`,
        `${subPath}/:type/:id/metrics`,
        `${subPath}/:type/:id/portfolio/metrics/:metricId`,
        `${subPath}/:type/:id/portfolio/metrics/:metricId/:something`,
        `${subPath}/:type/:id/files`,
        `${subPath}/:type/:id/files/:id`,
        `${subPath}/:type/:id/metrics/:metricId`,
        `${subPath}/:type/:id/metrics/:metricId/:something`,
        `${subPath}/:type/:id/logs`,
        `${subPath}/:type/:id/:tab/logs`,
      ],
      key: 'portfolioId',
      exact: true,
      component: (
        <Investments>
          <RouteContentSelector />
        </Investments>
      ),
    },
    {
      path: [`${subPath}/:type/:fundId/portfolio/preview`],
      key: 'preview',
      exact: true,
      component: (
        <PaddedLayout>
          <PreviewFundProfile />
        </PaddedLayout>
      ),
    },
    {
      path: [`${subPath}/:type/:id/logs`, `${subPath}/:type/:id/:tab/logs`],
      key: 'logs',
      component: (
        <PaddedLayout>
          <PortfolioLogs />
        </PaddedLayout>
      ),
    },
  ]

  const allRoutes = routes.map(({ path, component, key, exact }) => (
    <Route key={key} exact={exact} path={path}>
      {component}
    </Route>
  ))

  return (
    <>
      {[
        ...allRoutes,
        <>
          {planFeatures.areMetricsEnabled && (
            <>
              <Route path={`${subPath}/:type/:id/metrics/:metricId`}>
                <SidebarMetricsRoutes urlPrefix="/investments/:type/:id/metrics" />
              </Route>
              <Route path={`${subPath}/:type/:id/portfolio/metrics/:metricId`}>
                <SidebarMetricsRoutes urlPrefix="/investments/:type/:id/portfolio/metrics" />
              </Route>
            </>
          )}
        </>,
      ]}
    </>
  )
}

export default InvestmentsRoutes
