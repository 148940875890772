import { useField as useFieldFormik } from 'formik'
import type { FieldInputProps, FieldMetaProps, FieldHelperProps } from 'formik'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType, IError } from 'utils/types/common'
import { useFieldName } from './useFieldName'

type FieldInputPropsWithBuildError<T> = FieldInputProps<T> & {
  buildError: () => IError
}

export const useField = <T = any>(
  fieldName: string
): [
  FieldInputPropsWithBuildError<T>,
  FieldMetaProps<T>,
  FieldHelperProps<T>
] => {
  const prefixedFieldName = useFieldName(fieldName)

  const [field, meta, helpers] = useFieldFormik(prefixedFieldName)
  const newField = field as FieldInputPropsWithBuildError<T>
  newField.buildError = () =>
    buildFormError(meta.error, ErrorType.ERROR, meta.touched)

  return [newField, meta, helpers]
}
