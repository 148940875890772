import Card from 'components/Card'
import { Icon } from 'components/UpdateIconFactoryV2/UpdateIconFactory.styles'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CardHeader = styled(Card.Header)`
  height: 2rem;
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const UpdatesEmptyState = styled.div`
  align-items: center;
  background-color: ${color('white')};
  color: ${color('darkBlue')};
  display: flex;
  font-size: 1.8rem;
  height: 10rem;
  justify-content: center;
`

export const StyledIcon = styled(Icon)`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
