import styled, { css } from 'styled-components'
import type {
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'
import { color } from 'utils/functions/colors'

export const HeaderContainer = styled.button<{
  isOpen: boolean
}>`
  margin-bottom: ${(props) => (props.isOpen ? '1rem' : '0')};
  display: flex;
  gap: 1rem;
  padding: 1.6rem 0 0.5rem 0;
  width: 100%;

  &:disabled > div {
    cursor: 'not-allowed';
    color: ${color('gray', 0.5)};
  }
`

export const BodyContainer = styled.div<{
  isOpen: boolean
  preventReRender?: boolean
  bodyPadding?: string
  customStyles?: FlattenSimpleInterpolation
}>`
  display: flex;
  flex-direction: column;
  padding: ${({ bodyPadding }) => bodyPadding || '.2rem'};

  ${(props) => props.customStyles}
  ${(props) =>
    props.preventReRender &&
    !props.isOpen &&
    css`
      display: none;
    `}
`

export const TitleContainer = styled.div<{
  titleCustomStyles?: FlattenInterpolation<ThemeProps<any>>
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 0.6rem;
  color: ${color('gray')};
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.7rem;
  width: 100%;

  ${(props) => props.titleCustomStyles}
`

export const IconWrapper = styled.div`
  width: 1.6rem;
  font-size: 1.4rem;
`
export const Wrapper = styled.div<{ wrapperPadding?: string }>`
  margin-bottom: 1rem;
  padding: ${({ wrapperPadding }) => wrapperPadding || '0'};
  & > button:focus {
    outline: none;
  }
`
