import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Label = styled.span<{
  uppercase?: boolean
  large?: boolean
  marginLeft?: string
}>`
  display: inline-block;
  color: ${color('darkGray')};
  font-size: ${(props) => (props.large ? '1.4rem' : '1.2rem')};
  font-weight: bold;
  margin: 0 0.8rem 0.8rem 1.4rem;
  margin-left: ${(props) => props.marginLeft};
  text-transform: ${(props) => props.uppercase && 'uppercase'};
`

export const Optional = styled.span<{
  large?: boolean
}>`
  font-style: italic;
  font-weight: 400;
  color: #787e8c;
  font-size: ${(props) => (props.large ? '1.4rem' : '1.2rem')};
  margin-left: 0.2rem;
  text-transform: none;
`
