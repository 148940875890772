import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const GeneratingMetrics = styled.div`
  align-items: center;
  background-color: ${color('softBlue', 0.9)};
  border-radius: 0.8rem;
  display: flex;
  height: 40rem;
  justify-content: center;
  width: 100%;
`

export const ProcessingData = styled.div`
  align-items: center;
  background-color: ${color('white')};
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  height: 7.7rem;
  justify-content: center;
  padding: 1rem 1.2rem;
  width: 26rem;
`

export const ProcessingDataTitle = styled.h1`
  color: ${color('purple')};
  display: flex;
  font-size: 1.4rem;
  gap: 0.6rem;
  margin-bottom: 0.4rem;
`

export const ProcessingDataSubTitle = styled.h2`
  color: ${color('lightGray')};
  font-size: 1.2rem;
`
