/* eslint-disable consistent-return */
export const isIeOrEdge = (userAgent = window.navigator.userAgent) => {
  return (
    userAgent.indexOf('MSIE') !== -1 ||
    userAgent.indexOf('Trident/') !== -1 ||
    userAgent.indexOf('Edge/') !== -1
  )
}

export const promptBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event
      e.preventDefault()
      if (e) {
        e.returnValue = ''
      }
      return ''
    }
  }
}

export const isValidHttpUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}
