import { useMemo } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  getUserId,
  isActingAsClient,
  isActingAsFundManager,
  isActingAsInvestorGroup,
} from 'selectors/auth'
import { isCurrentCompanyPrivate } from 'selectors/company'

import { UpdatePermissions } from 'utils/types/common'
import { IndexUpdate } from 'utils/types/update'
import {
  isUpdateContentEditable,
  isUpdatePermissionsEditable,
  hasEditPermissions,
  isUpdateEditable,
  canEditReshare,
  groupOwnedDisabled,
  canDowngradePermissions,
  canReshareUpdate,
  setAsDraftVisible,
  updateContentHasFullPermissions,
} from 'utils/functions/update-permissions'

export const useUpdatePermissions = (
  update?: IndexUpdate
): UpdatePermissions => {
  const userId = useAppSelector(getUserId)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const isPrivateCompany = useAppSelector(isCurrentCompanyPrivate)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isClient = useAppSelector(isActingAsClient)
  const currentUserId = useAppSelector(getUserId)

  return useMemo(() => {
    if (!update) {
      return {
        hasEditPermissions: false,
        isEditable: false,
        isContentEditable: false,
        contentHasFullPermissions: false,
        isPermissionsEditable: false,
        canEditReshare: false,
        canReshare: false,
        groupOwnedDisabled: true,
        canDowngradePermissions: false,
        setAsDraftVisible: true,
        canDeleteUpdate: false,
      }
    }

    const hasEditPermissionsValue =
      !isInvestorGroup && hasEditPermissions(update)

    return {
      hasEditPermissions: hasEditPermissionsValue,
      isEditable: isUpdateEditable(update),
      isContentEditable: isUpdateContentEditable(update),
      contentHasFullPermissions: updateContentHasFullPermissions(update),
      isPermissionsEditable: isUpdatePermissionsEditable(update),
      canReshare: canReshareUpdate(
        update,
        isFundManager,
        isClient,
        currentUserId,
        isPrivateCompany,
        hasEditPermissionsValue
      ),
      canEditReshare: canEditReshare(update, userId),
      groupOwnedDisabled: groupOwnedDisabled(update),
      canDowngradePermissions: canDowngradePermissions(update),
      setAsDraftVisible: setAsDraftVisible(update),
      canDeleteUpdate: isUpdateContentEditable(update),
    }
  }, [
    currentUserId,
    isClient,
    isFundManager,
    isInvestorGroup,
    isPrivateCompany,
    update,
    userId,
  ])
}
