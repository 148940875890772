import TabContent from 'components/Tabs/TabContent'
import UpdateContent from './UpdateContent'
import UpdateBody from './UpdateBody'
import UpdateContainer from './UpdateContainer'
import UpdateButtons from './UpdateButtons'
import UpdateCard from './UpdateCard'
import UpdateTabs from './UpdateTabs'
import CollapsableCard from './CollapsableCard'
import UpdateTabContent from './UpdateTabContent'

const UpdateLayout = {
  Content: UpdateContent,
  TabContent: UpdateTabContent,
  Tab: TabContent,
  Body: UpdateBody,
  Container: UpdateContainer,
  Buttons: UpdateButtons,
  Permissions: UpdateCard,
  Tags: UpdateCard,
  Reshare: UpdateCard,
  Tabs: UpdateTabs,
  ShareUpdate: UpdateCard,
  CollapsableCard,
}

export default UpdateLayout
