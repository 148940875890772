import React, { useState } from 'react'
import { Helmet } from 'components/Helmet'
import { FormattedMessage, useIntl } from 'react-intl'

import { useAppSelector } from 'utils/hooks/reduxToolkit'
import ShowUpdatePermissions from 'components/ShowUpdatePermissions'
import UpdateNavigationHeader from 'components/UpdateNavigationHeader'
import UpdateLayout from 'components/UpdateLayout'
import CollapsedPermissionsTagsCard from 'components/CollapsedPermissionsTagsCard/CollapsedPermissionsTagsCard'
import EditUpdateContentLoader from 'components/loaders/editUpdateContentLoader'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { getCurrentCompany } from 'selectors/company'
import Analytics from 'containers/Analytics'
import { getUpdateItemTitle, ReshareTokenSet } from 'utils/functions/updates'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import { LoggingUpdateReshare, Update } from 'utils/types/update'
import { UpdatePermissions, UpdateType } from 'utils/types/common'
import type { TabItem } from 'components/Tabs'
import OptionsDropdown from 'ui/OptionsDropdown'
import ConfirmationModal from 'components/ConfirmationModal'
import DeleteModalBody from 'containers/DeleteModal'
import theme from 'utils/theme'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'
import { isIndexFileContent } from 'utils/functions/files'
import ConfirmReshareModal from 'components/UpdatesFeed/ConfirmReshareModal'
import { LogType } from 'containers/Logs/models/types'
import Logs from 'containers/Logs/Logs'
import ShowUpdateContent from 'containers/UpdatesV2/ShowUpdateContent'
import { UpdateTypes } from 'utils/constants/updates'

import { isActingAsInvestorGroup } from 'selectors/auth'
import UpdateTags from '../UpdateTags/UpdateTags'
import ShareUpdate from '../ShareUpdate/ShareUpdate'
import {
  Collapsable,
  ExpandArrowIconButton,
  MenuIconButton,
  OptionsContainer,
  UpdateOptionsDropdownWrapper,
} from './ShowUpdate.styles'
import ReshareHistoryWrapper from '../ReshareHistory/ReshareHistoryWrapper'

export interface DropdownItem {
  id: string
  label: string | typeof FormattedMessage
  icon?: string[]
  onSelectOption: () => void
  color?: string
}

interface ShowUpdateProps {
  children?: React.ReactNode
  update?: Update
  updateType: UpdateType
  updatePermissions: UpdatePermissions
  redirectToEditView: () => void
  redirectToReshareView: () => void
  onDeleteUpdate: () => void
  eventLogType?: LogType
  tabs?: TabItem[]
  loader?: React.ReactNode
  updateLoading?: boolean
  arrowNavigationDisabled?: boolean
  onClickTab?: (index: number) => void
  resharesByToken: ReshareTokenSet[]
  resharesWithMeOrMyGroup: LoggingUpdateReshare[]
  dropdownOptions?: DropdownItem[]
}

const ShowUpdate: React.FC<ShowUpdateProps> = ({
  children,
  update,
  updateType,
  updatePermissions,
  redirectToEditView,
  redirectToReshareView,
  onDeleteUpdate,
  eventLogType,
  tabs,
  loader,
  updateLoading,
  arrowNavigationDisabled,
  onClickTab,
  resharesByToken,
  resharesWithMeOrMyGroup,
  dropdownOptions: dropdownOptionsProps,
}) => {
  const intl = useIntl()
  const currentPortfolio = useAppSelector(getCurrentPortfolio)
  const currentCompany = useAppSelector(getCurrentCompany)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isCardExpanded, setIsCardExpanded] = useState(false)
  const [reshareModal, setReshareModal] = useState(false)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const { matches: isMobile } = useMediaQuery(Media.MOBILE)

  const toggleDeleteConfirmationModal = () => {
    setDeleteModal((open) => !open)
  }

  const onCardExpanded = () => {
    setIsCardExpanded((expanded) => !expanded)
  }

  const companyName = currentCompany.name

  const showDeleteUpdateModal = () => {
    setDeleteModal(true)
  }

  const renderIconButtons = () => {
    if (!update) {
      return null
    }

    let dropdownOptions: DropdownItem[] = []

    if (dropdownOptionsProps) {
      dropdownOptions = dropdownOptionsProps
    } else if (updatePermissions.canDeleteUpdate) {
      dropdownOptions = [
        {
          id: 'delete-update',
          label: intl.formatMessage({ id: 'general.delete' }),
          icon: ['far', 'trash-alt'],
          onSelectOption: showDeleteUpdateModal,
          color: theme.colors.red,
        },
      ]

      if (isMobile && updatePermissions.hasEditPermissions) {
        dropdownOptions = [
          {
            id: 'edit-update',
            label: intl.formatMessage({ id: 'general.edit' }),
            icon: ['far', 'pen'],
            onSelectOption: redirectToEditView,
            color: theme.colors.darkBlue,
          },
          {
            id: 'delete-update',
            label: intl.formatMessage({ id: 'general.delete' }),
            icon: ['far', 'trash-alt'],
            onSelectOption: showDeleteUpdateModal,
            color: theme.colors.red,
          },
        ]
      }
    }

    return (
      <OptionsContainer key="dropdown">
        {updatePermissions.hasEditPermissions &&
          updateType !== UpdateTypes.IUE && (
            <ReadOnlyTooltip
              text={intl.formatMessage({ id: 'updates.editExpiredTooltip' })}
              place="bottom"
              maxWidth="18.4rem"
              leftOffset={75}
              topOffset={10}
              disabled={!updatePermissions.isEditable}
            >
              <MenuIconButton
                icon="pen"
                title={intl.formatMessage({ id: 'general.edit' })}
                onClick={redirectToEditView}
                border={false}
              />
            </ReadOnlyTooltip>
          )}

        {!isCardExpanded && updateType !== UpdateTypes.IUE && (
          <ExpandArrowIconButton
            onClick={onCardExpanded}
            title={intl.formatMessage({ id: 'general.expand' })}
            icon="arrows-h"
            border={false}
          />
        )}

        {dropdownOptions?.length > 0 && (
          <UpdateOptionsDropdownWrapper>
            <OptionsDropdown buttonType="square" buttonSize="3.2rem">
              {dropdownOptions.map((option) => (
                <OptionsDropdown.Item
                  key={option.id}
                  label={option.label}
                  icon={option.icon}
                  onSelectOption={option.onSelectOption}
                  color={option.color}
                />
              ))}
            </OptionsDropdown>
          </UpdateOptionsDropdownWrapper>
        )}
      </OptionsContainer>
    )
  }

  const renderChildren = ({ activeTabId }): React.ReactNode => {
    if (updateLoading || !update) {
      return loader
    }

    if (children) {
      return [renderIconButtons(), children]
    }

    return [
      renderIconButtons(),
      <UpdateLayout.Tab id="general" key="general">
        <ReshareHistoryWrapper
          resharesByToken={resharesByToken}
          resharesWithMeOrMyGroup={resharesWithMeOrMyGroup}
          hasEditPermissions={updatePermissions.hasEditPermissions}
        >
          <ShowUpdateContent update={update} updateType={updateType} />
        </ReshareHistoryWrapper>
      </UpdateLayout.Tab>,
      updatePermissions.hasEditPermissions ? (
        <UpdateLayout.Tab id="analytics" key="analytics">
          {activeTabId === 'analytics' && (
            <Analytics
              updateId={update?.id}
              updateCreationDate={update.createdAt}
              totalViews={update.totalViews}
              uniqueViews={update.uniqueViews}
              attachments={update.contents.filter(isIndexFileContent)} // TODO review this filter
            />
          )}
        </UpdateLayout.Tab>
      ) : null,
      <UpdateLayout.Tab id="logs" key="logs">
        {!!eventLogType && (
          <Logs
            entityId={update?.item?.id}
            logType={eventLogType}
            factoryParams={{ updateTitle: getUpdateItemTitle(update) }}
          />
        )}
      </UpdateLayout.Tab>,
    ].filter((tab) => !!tab)
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            { id: 'metaTitles.updateTitle' },
            {
              title: getUpdateItemTitle(update),
              entityName:
                companyName ||
                currentPortfolio?.name ||
                intl.messages['metaTitles.portfolio'],
            }
          )}
        </title>
      </Helmet>
      <UpdateLayout.Container>
        <UpdateLayout.Body>
          {updateType !== UpdateTypes.IUE && (
            <UpdateNavigationHeader
              arrowNavigationDisabled={arrowNavigationDisabled}
            />
          )}
          <UpdateLayout.TabContent
            isCardExpanded={isCardExpanded}
            fullContent={!updatePermissions.hasEditPermissions}
            tabs={tabs}
            showTitles={!isMobile}
            onClickTab={onClickTab}
            analyticsEnabled={updatePermissions.hasEditPermissions}
          >
            {renderChildren}
          </UpdateLayout.TabContent>

          <UpdateLayout.CollapsableCard>
            <Collapsable hidden={!isCardExpanded}>
              <CollapsedPermissionsTagsCard
                onCardExpanded={onCardExpanded}
                showLockIcon={updatePermissions.hasEditPermissions}
              />
            </Collapsable>
            <Collapsable hidden={isCardExpanded}>
              <>
                {updatePermissions.hasEditPermissions && (
                  <UpdateLayout.Permissions>
                    <ShowUpdatePermissions update={update} />
                  </UpdateLayout.Permissions>
                )}

                {!isInvestorGroup && (
                  <UpdateLayout.Tags>
                    <UpdateTags
                      updateId={update?.id}
                      initialTags={update?.tags ?? []}
                    />
                  </UpdateLayout.Tags>
                )}

                {updateType !== UpdateTypes.IUE && (
                  <UpdateLayout.ShareUpdate>
                    <ShareUpdate />
                  </UpdateLayout.ShareUpdate>
                )}
              </>
            </Collapsable>
          </UpdateLayout.CollapsableCard>
        </UpdateLayout.Body>
      </UpdateLayout.Container>
      <ConfirmationModal
        title={intl.formatMessage({
          id: `updates.deleteTitles.${updateType}`,
        })}
        body={
          <DeleteModalBody
            text={intl.formatMessage({
              id: `updates.deleteMessages.${updateType}`,
            })}
          />
        }
        onHide={toggleDeleteConfirmationModal}
        onConfirm={onDeleteUpdate}
        isOpen={deleteModal}
      />
      <ConfirmReshareModal
        isOpen={reshareModal}
        onHide={() => setReshareModal(false)}
        onConfirm={redirectToReshareView}
      />
    </>
  )
}

ShowUpdate.defaultProps = {
  update: undefined,
  tabs: undefined,
  loader: <EditUpdateContentLoader />,
  updateLoading: false,
  arrowNavigationDisabled: false,
  children: undefined,
  eventLogType: undefined,
  onClickTab: () => {},
  dropdownOptions: undefined,
}

export default ShowUpdate
