import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 3rem;
`

export const LogListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.1rem;
`

export const LogWrapper = styled.div`
  display: flex;
`

export const LogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
`
