import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import {
  TooltipContainer,
  Title,
  TooltipContent,
  Percentage,
  Company,
  Image,
  Name,
} from './PortfolioChartTooltip.styles'

const DECIMAL_PRECISION = 1

const PortfolioChartTooltip = ({
  active,
  payload,
  colorsForCompanies,
  showChartLogoImage,
}) => {
  const intl = useIntl()
  if (active && payload?.length) {
    const { payload: company, dataKey } = payload[0]

    const percentage = company?.[`${dataKey}Percentage`]

    return (
      <TooltipContainer key={company.companyDatum?.id ?? company?.id}>
        <Title>{intl.messages[`portfolios.${dataKey}`]}</Title>
        <TooltipContent>
          <Percentage
            color={
              colorsForCompanies[company?.id] ??
              colorsForCompanies[company?.companyDatum?.id] ??
              colorsForCompanies[company?.holding?.id]
            }
          >
            {percentage?.toFixed(DECIMAL_PRECISION)}%
          </Percentage>
          <Company className="fs-exclude">
            {showChartLogoImage && (
              <Image
                src={
                  company?.companyDatum?.primaryLogo?.smallLogo?.url ??
                  company?.holding?.primaryLogo?.smallLogo?.url ??
                  company.image
                }
              />
            )}
            {showChartLogoImage ? (
              <Name>
                {company.companyDatum?.name ??
                  company.holding?.name ??
                  company.name}
              </Name>
            ) : (
              <Name>{company?.name}</Name>
            )}
          </Company>
        </TooltipContent>
      </TooltipContainer>
    )
  }
  return null
}

PortfolioChartTooltip.propTypes = {
  payload: PropTypes.array,
  colorsForCompanies: PropTypes.object,
  active: PropTypes.bool,
  showChartLogoImage: PropTypes.bool,
}

PortfolioChartTooltip.defaultProps = {
  active: false,
  payload: [],
  colorsForCompanies: {},
}

export default PortfolioChartTooltip
