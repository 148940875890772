import styled from 'styled-components/macro'
import { css } from 'styled-components'
import Modal from 'components/Modal'
import { color } from 'utils/functions/colors'
import { size } from 'utils/constants/breakpoint'
import { Container as GroupContainer } from './components/ShareWithEntities/ShareWithEntities.styles'

export const ModalBody = styled(Modal.Body)`
  padding: 2.4rem;

  @media ${size.sm} {
    width: 56rem;
  }
`

export const GroupsContainer = styled.div<{ hasTopMargin: boolean }>`
  max-height: 30rem;
  overflow-y: auto;

  border: 1px solid ${color('veryLightGray')};
  border-radius: 8px;

  ${({ hasTopMargin }) =>
    hasTopMargin &&
    css`
      margin-top: 1.6rem;
    `}

  ${GroupContainer} {
    border-bottom: 1px solid ${color('veryLightGray')};

    &:last-child {
      border-bottom: none;
    }
  }
`

export const TitleContainer = styled.div`
  padding-top: 2.4rem;
  padding-bottom: 1.6rem;
`

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  font-weight: 700;

  svg {
    font-size: 1.3rem;
  }
`

export const ReshareSettingsDescription = styled.div`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  padding-left: 2.5rem;
  padding-top: 0.3rem;
`
