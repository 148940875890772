import { useCallback, useEffect, useRef, useState } from 'react'
import isNil from 'lodash/isNil'

import theme from 'utils/theme'
import { hexToRGB } from 'utils/functions/colors'
import { useDebouncedCallback } from 'utils/hooks/useDebouncedCallback'
import { isValidEmail } from 'utils/functions/validation'
import { isEnterCode, isEscCode } from 'utils/functions/keyboardEvents'

export enum ShareWithOptionType {
  Group = 'group',
  List = 'list',
  User = 'user',
  Email = 'email',
}

export interface ShareWithOption {
  id: string
  name: string
  type: ShareWithOptionType
  imageUrl?: string
  subtitle?: string
  recipients?: ShareWithOption[]
  unreachableEmail?: boolean
  failedCreating?: boolean
  canBeDeleted?: boolean
}

export const WITH_ERROR_PREFIX = 'withError'
export const TO_CREATE_PREFIX = 'toCreate'

export const isOptionWithError = (option: ShareWithOption) =>
  option.id.toString().startsWith(WITH_ERROR_PREFIX)

export const REACT_SELECT_STYLES = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: state.menuIsOpen
      ? theme.colors.white
      : hexToRGB(theme.colors.lightGray, 0.1),
    borderRadius: '8px',
    borderColor: state.menuIsOpen ? theme.colors.primaryBlue : 'transparent',
    '&:hover': {
      ...baseStyles['&:hover'],
      borderColor: state.menuIsOpen
        ? theme.colors.primaryBlue
        : theme.colors.veryLightBlue,
    },
    boxShadow: undefined,
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    fontSize: '1.4rem',
    paddingLeft: '0.6rem',
    color: hexToRGB(theme.colors.darkGray, 0.6),
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    gap: '0.8rem',
  }),
}

export interface ReactSelect {
  select: {
    select: {
      handleInputChange: (event: any) => void
      focusedOptionRef: HTMLDivElement
      props: {
        isLoading: boolean
        options: ShareWithOption[]
      }
    }
  }
  state: { inputValue: string }
}

export interface ShareWithDropdownProps {
  label?: string
  selectedUsers?: ShareWithOption[]
  loadOptions: (inputValue: string) => Promise<ShareWithOption[]>
  onClose: (selectedOptions: ShareWithOption[]) => void
  maxMenuHeight?: number
  placeholder?: string
  isDisabled?: boolean
}

export const useShareWithDropdown = ({
  loadOptions,
  onClose,
}: ShareWithDropdownProps) => {
  const selectRef = useRef<ReactSelect>(null)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [currentSelectedOptions, setCurrentOptions] = useState<
    ShareWithOption[]
  >([])

  const onInputChange = (_, { action }) => {
    if (action === 'menu-close') {
      setMenuIsOpen(false)
    }
  }

  const onFocus = () => {
    setMenuIsOpen(true)
  }

  useEffect(() => {
    if (!menuIsOpen) {
      // @ts-ignore
      selectRef.current?.blur()
    }
  }, [menuIsOpen])

  const loadOptionsWrapper = useCallback(
    (searchValue: string, callback: (options: ShareWithOption[]) => void) => {
      loadOptions(searchValue).then(callback)
    },
    [loadOptions]
  )

  const debouncedloadOptions = useDebouncedCallback(
    loadOptionsWrapper,
    [loadOptionsWrapper],
    300
  )

  const createEmail = useCallback((inputValue: string) => {
    const email: ShareWithOption = isValidEmail(inputValue)
      ? {
          id: `${TO_CREATE_PREFIX}-${inputValue}`,
          type: ShareWithOptionType.Email,
          name: inputValue,
        }
      : {
          id: `${WITH_ERROR_PREFIX}-${inputValue}`,
          type: ShareWithOptionType.User,
          name: inputValue,
        }

    return email
  }, [])

  const handleKeyDown = useCallback(
    (event) => {
      const keysThatGenerateAPill = [',', 'Tab', ';']

      if (isEnterCode(event) || isEscCode(event)) {
        event.stopPropagation()
        event.preventDefault()
        setMenuIsOpen(false)
        // @ts-ignore
        selectRef.current?.blur()

        const optionAlreadySelected = !isNil(
          selectRef.current?.select.select.focusedOptionRef?.getAttribute(
            'disabled'
          )
        )

        if (optionAlreadySelected) {
          event.preventDefault()
          return
        }
      }

      if (keysThatGenerateAPill.includes(event.key)) {
        const inputValue: string = event.target.value
        const modifiedEvent = event
        const isLoading = selectRef.current?.select.select.props.isLoading
        const hasOptions =
          !!selectRef.current?.select.select.props.options.length

        if (!inputValue) {
          modifiedEvent.preventDefault()
          return
        }

        if (isLoading || !hasOptions) {
          const email = createEmail(inputValue)
          setCurrentOptions((prevOptions) =>
            prevOptions ? [...prevOptions, email] : [email]
          )

          modifiedEvent.target.value = ''
          modifiedEvent.currentTarget = event.target
          selectRef.current?.select.select.handleInputChange(modifiedEvent)
          modifiedEvent.preventDefault()
        } else {
          modifiedEvent.key = 'Enter'
        }
      }
    },
    [createEmail]
  )

  const onOptionSelected = useCallback(
    (selectedOptions?: ShareWithOption[]) => {
      setCurrentOptions(selectedOptions || [])
    },
    []
  )

  const onMenuClose = useCallback(() => {
    const email = createEmail(selectRef.current?.state?.inputValue || '')
    const userWroteSomething = email.name.length > 0
    const hasSelectedOptions = currentSelectedOptions.length > 0
    const currentOptionHasError = isOptionWithError(email)
    if (userWroteSomething) {
      setCurrentOptions((prevOptions) =>
        prevOptions ? [...prevOptions, email] : [email]
      )
    }

    if (currentOptionHasError && (!hasSelectedOptions || userWroteSomething)) {
      return
    }

    if (!currentSelectedOptions.some(isOptionWithError)) {
      onClose(
        userWroteSomething && !currentOptionHasError
          ? [...currentSelectedOptions, email]
          : currentSelectedOptions
      )
      setCurrentOptions([])
    }
  }, [createEmail, currentSelectedOptions, onClose])

  return {
    selectRef,
    currentSelectedOptions,
    debouncedloadOptions,
    menuIsOpen,
    handleKeyDown,
    onOptionSelected,
    onMenuClose,
    onInputChange,
    onFocus,
  }
}
