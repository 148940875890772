import { useCallback, useState } from 'react'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'

import { FileViewEnum } from 'components/FileViewSelector'
import { useShowMoreLess } from 'components/ShowMoreLess'
import FilesMemo from 'containers/Files/components/FilesMemo'
import { useDownloadFile } from 'utils/hooks/files/useDownloadFile'
import { EmailContent } from 'utils/types/files'
import { Nullable } from 'utils/types/common'
import {
  AttachmentsContainer,
  Container,
  Details,
  DetailsContainer,
  ScrollContainer,
  Subject,
  TextContainer,
  ShowMoreLess,
} from './PreviewEmailContent.styles'
import EmailFrame from './EmailFrame'

interface PreviewContentProps {
  emailContent: EmailContent
  isLoading?: boolean
  previewContainer: Nullable<HTMLDivElement>
}

const PreviewContent = ({
  emailContent,
  isLoading,
  previewContainer,
}: PreviewContentProps) => {
  const [detailsContainer, setDetailsContainer] =
    useState<Nullable<HTMLDivElement>>(null)
  const { data } = emailContent
  const receivers = data.receivers.join(', ')
  const date = dayjs(emailContent.createdAt).format('dddd D MMMM')
  const { onDownloadFile } = useDownloadFile()
  const {
    containerRef,
    isShowMoreNeeded,
    shortDescription,
    isShowingMore,
    toggleShowingMore,
  } = useShowMoreLess({
    description: receivers,
    precisionError: 0,
  })

  const handleDetailsContainerRef = useCallback(
    (node: Nullable<HTMLDivElement>) => {
      setDetailsContainer(node)
    },
    []
  )

  return (
    <ScrollContainer>
      <Container ref={containerRef}>
        <DetailsContainer ref={handleDetailsContainerRef}>
          <Subject>{data.title}</Subject>
          <Details>
            <FormattedMessage id="files.date" />
            <span>{date}</span>
          </Details>
          <Details>
            <FormattedMessage id="files.from" />
            <span>{data.sender}</span>
          </Details>
          <Details>
            <FormattedMessage id="files.to" />
            <span>{isShowingMore ? receivers : shortDescription}</span>
            <ShowMoreLess
              isShowMoreNeeded={isShowMoreNeeded}
              isShowingMore={isShowingMore}
              toggleShowingMore={toggleShowingMore}
            />
          </Details>
        </DetailsContainer>
        <TextContainer>
          <EmailFrame
            emailContent={emailContent}
            container={previewContainer}
            detailsContainer={detailsContainer}
          />
        </TextContainer>
        {!!emailContent.s3Contents.length && (
          <AttachmentsContainer>
            <FilesMemo
              isLoading={isLoading as boolean}
              contents={emailContent.s3Contents}
              filters={{}}
              gridSize={FileViewEnum.SMALL}
              onClickFile={() => {}}
              onDownloadFile={onDownloadFile}
            />
          </AttachmentsContainer>
        )}
      </Container>
    </ScrollContainer>
  )
}

export default PreviewContent
