import { BackendEvent } from 'containers/Logs/models/backend-events-mapper/BackendEventsMapper'
import { LogType } from 'containers/Logs/models/types'
import axiosClient from './httpClient'

export const LOG_PAGE_SIZE = 25

class LogService {
  static async fetchLogs<T>(
    itemId: string,
    logType: LogType,
    page: number,
    perPage: number = LOG_PAGE_SIZE
  ): Promise<BackendEvent<T>[]> {
    const {
      data: {
        entities: { versions = {} },
        result,
      },
    } = await axiosClient().get(
      `/${LogService.getBackendEntityName(
        logType
      )}/${itemId}/paper_trail_events?page=${page}&per_page=${perPage}`
    )

    const backendEvents = result.map((eventId) => {
      return versions[eventId]
    })

    return backendEvents
  }

  static getBackendEntityName(logType: LogType): string {
    switch (logType) {
      case LogType.PIPELINE_PORTFOLIOS:
      case LogType.INVEST_PORTFOLIOS:
        return 'portfolios'
      case LogType.DEAL_PORTFOLIOS:
        return 'fund_portfolios'
      default:
        return logType
    }
  }
}

export default LogService
