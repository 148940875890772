import {
  generatePaths,
  generateProfilePaths,
  generateUpdatePaths,
} from 'components/Breadcrumb/helpers/helpers'

const defaultPaths = [
  ...generateUpdatePaths('/updates'),
  '/emails/:emailId',
  '/emails/:emailId/edit',
]

export const fundManagerAndClientPaths = [
  ...defaultPaths,

  ...generateUpdatePaths('/investments/:investmentId'),
  ...generateProfilePaths('/group-profile'),
  ...generateProfilePaths('/investor-management/investors/:investorId/profile'),
  ...generateProfilePaths('/investor-management/vehicles/:vehicleId/profile'),

  ...generateUpdatePaths(
    '/investor-management/investors/:investorId/profile/vehicles/:vehicleId/all-updates'
  ),

  ...generatePaths(['/companies/:companyId', '/funds/:fundId']),
  '/investor-management/investors/:investorId/profile/vehicles/:vehicleId',
]

export const founderPaths = [
  ...defaultPaths,
  ...generateProfilePaths('/dashboard'),
  ...generateUpdatePaths('/companies/:companyId/all-updates'),
  ...generateUpdatePaths('/companies/:companyId'),
]

export const investorPaths = [
  ...defaultPaths,
  '/investments/fund-holdings/updates/:updateType/:updateId',
  '/investments/funds/:fundId/:updateType/:updateId',
  '/profile',
  '/profile/edit',
  '/profile/:updateType/:updateId',
  '/investment-vehicles/:vehicleId',
  '/investment-vehicles/:vehicleId/edit',
  '/investment-vehicles/:vehicleId/updates/:updateType/:transactionId',
  '/fund-holdings/companies/:companyId/updates',
  '/fund-holdings/companies/:companyId/investments',
  '/fund-holdings/funds/:fundId/updates',
  '/fund-holdings/funds/:fundId/investments',
  ...generateUpdatePaths('/fund-holdings/companies/:companyId/updates'),
  ...generateUpdatePaths('/fund-holdings/funds/:fundId/updates'),
]
