import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem 3.2rem 1rem 3.2rem;
`

export const Title = styled.h1`
  font-size: 2.4rem;
  color: #131313;
  margin: 1.67rem 0;
`

export const InputContainer = styled.div`
  width: 25.5rem;
`
