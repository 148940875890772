import theme from 'utils/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonWrapper } from '../UpdateActions.styles'

interface Props {
  handleShowCreateChatModal: (value: boolean) => void
}

const NewChatAction = ({ handleShowCreateChatModal }: Props) => {
  return (
    <ButtonWrapper transparent onClick={() => handleShowCreateChatModal(true)}>
      <FontAwesomeIcon
        color={theme.colors.darkBlue}
        icon={['far', 'comment']}
      />
    </ButtonWrapper>
  )
}

export default NewChatAction
