export const ALREADY_TAKEN_ERROR = 'has already been taken'

export const DRAWDOWN_EXCEEDS_COMMITMENT_ERROR =
  'investment overpasses unfunded commitment'

export const OUT_OF_RANGE = 'out of range'

export const INVALID_OTP_CODE_ERROR = 'Otp attempt code required'

export const TERMS_OF_USE_NOT_ACCEPTED = 'Terms of use not accepted'

export const INVALID_DISCOUNT_CODE = 'No such coupon'

export const CONSENT_REQUIRED = 'Consent required'

export const EMAIL_NOT_VERIFIED = 'email not verified'
