import { Mark, mergeAttributes } from '@tiptap/core'

export interface DelOptions {
  HTMLAttributes: Record<string, any>
}

export const Del = Mark.create<DelOptions>({
  name: 'del',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'del',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'del',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },
})
