import React from 'react'
import { useIntl } from 'react-intl'
import { ProfileData } from '../types'
import * as Styles from './ProfileSuggestion.styles'

interface ProfileSuggestionProps {
  type: 'fund' | 'website'
  profiles: ProfileData[]
  addHoldingsToPortfolio?: (holdingIds: string[]) => void
}

const ProfileSuggestion: React.FC<ProfileSuggestionProps> = ({
  type,
  profiles,
  addHoldingsToPortfolio,
}) => {
  const intl = useIntl()
  if (!profiles[0]?.url) {
    return (
      <Styles.UsedNameText>
        {intl.formatMessage({ id: 'addHolding.errors.nameUsed' })}
      </Styles.UsedNameText>
    )
  }
  return addHoldingsToPortfolio ? (
    <Styles.UsedNameText>
      {intl.formatMessage(
        {
          id:
            type === 'fund'
              ? 'addHolding.errors.fundAlreadyVisibleAddCompany'
              : 'addHolding.errors.companyAlreadyVisibleAddCompany',
        },
        {
          link: profiles.map((profileData, profileIndex) => (
            <>
              <Styles.PortfolioNameLink
                onClick={() => addHoldingsToPortfolio?.([profileData.id!])}
              >
                {profileData.name}
              </Styles.PortfolioNameLink>
              {profileIndex !== profiles.length - 1 &&
                intl.formatMessage({
                  id: 'addHolding.errors.or',
                })}
            </>
          )),
        }
      )}
    </Styles.UsedNameText>
  ) : (
    <Styles.UsedNameText>
      {`${intl.formatMessage({
        id:
          type === 'fund'
            ? 'addHolding.errors.nameUsed'
            : 'addHolding.errors.websiteUsed',
      })} ${intl.formatMessage({
        id: 'addHolding.errors.wouldYouLikeToVisit',
      })} `}

      {profiles.map((profileData, profileIndex) => (
        <>
          <Styles.PortfolioNameLink href={profileData.url} target="_blank">
            {profileData.name}
          </Styles.PortfolioNameLink>
          {profileIndex !== profiles.length - 1 &&
            intl.formatMessage({
              id: 'addHolding.errors.or',
            })}
        </>
      ))}

      {` ${intl.formatMessage({
        id: 'addHolding.errors.profile',
      })}`}
    </Styles.UsedNameText>
  )
}

export default ProfileSuggestion
