import { Container, Dots, Text } from './TypingIndicator.styles'
import { useTypingIndicator } from './useTypingIndicator'

const TypingIndicator = () => {
  const typingText = useTypingIndicator()

  if (!typingText) {
    return null
  }

  return (
    <Container>
      <Dots />
      <Text>{typingText}</Text>
    </Container>
  )
}

export default TypingIndicator
