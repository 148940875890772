import styled, { css } from 'styled-components'

export const CellContainer = styled.div`
  flex: ${(props) => (props.flex !== undefined ? props.flex : 1)};
  min-width: 0;
  text-align: ${(props) => props.align || 'center'};
  ${(props) =>
    props.wrapText
      ? css`
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding: 0 0.6rem;
        `
      : css`
          padding: 0.6rem;
        `};
  font-weight: ${(props) => props.fontWeight || 'normal'};
  padding: ${(props) => (props.flex === 0 ? 0 : props.padding)};
`
