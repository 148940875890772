import HoldingsService, { HoldingsFilters } from 'api/HoldingsService'
import Toast from 'components/Toast'
import { useEffect } from 'react'
import { useInfiniteQuery } from '@tanstack/react-query'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'

import { holdingsKeys } from 'utils/queries/holdings'
import { Holding } from 'utils/types/company'

interface Props {
  textSearch?: string
  currentFilters: HoldingsFilters
  tagsNameValue?: string[]
  companiesPerPage: number
}

export const useAllCompanies = ({
  textSearch = '',
  currentFilters,
  companiesPerPage,
}: Props) => {
  const {
    ref: endOfPageRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()

  const getHoldings = async ({
    page,
    filters,
  }): Promise<{ holdings: Holding[]; currentPage: number; total: number }> => {
    try {
      const { holdings, total } = await HoldingsService.getHoldings({
        page,
        filters,
        companiesPerPage,
      })
      if (holdings.length < companiesPerPage) {
        setInfiniteScrollEnabled(false)
      } else {
        setInfiniteScrollEnabled(true)
      }
      return { holdings, total, currentPage: page }
    } catch (error) {
      Toast.display(error.message, 'error')
      return { holdings: [], total: 0, currentPage: page }
    }
  }

  const query = useInfiniteQuery(
    holdingsKeys.all(currentFilters, textSearch),
    ({ pageParam = 1 }) => {
      return getHoldings({
        page: pageParam,
        filters: { ...currentFilters, name: textSearch },
      })
    },
    {
      getNextPageParam: (lastPage) => (lastPage?.currentPage ?? 0) + 1,
    }
  )
  useEffect(() => {
    if (onScreen) {
      query.fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen])

  return { ...query, endOfPageRef }
}
