import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useImperativeHandle,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  CardWrapper,
  CardBody,
  CardHeader,
  HeaderWrapper,
  IconWrapper,
  TitleContainer,
} from './Card.styles'

interface CardProps {
  id?: string
  children: ReactNode
  className?: string
  largePadding?: boolean
  shadow?: boolean
  fitContent?: boolean
  noPadding?: boolean
  fullHeight?: boolean
  isExpandable?: boolean
  padding?: string
  onChangeExpanded?: (isExpanded: boolean) => void
  initiallyExpanded?: boolean
  arrowColor?: string
}

interface CardBodyProps {
  children: ReactNode
  className?: string
  isExpanded?: boolean
  bodyMargin?: string
}

interface CardHeaderProps {
  children: ReactNode
  className?: string
  isExpandable?: boolean
  isExpanded?: boolean
  setIsExpanded?: Dispatch<SetStateAction<boolean>>
  padding?: string
  margin?: string
  arrowColor?: string
}

interface CardComposition {
  Body: typeof Body
  Header: React.FC<CardHeaderProps>
}

type CardType = React.ForwardRefExoticComponent<
  CardProps & React.RefAttributes<CardType>
> &
  CardComposition & { expandCard: () => void }

interface ImperativeCardApi {
  expandCard: () => void
}

type RefType = HTMLDivElement | ImperativeCardApi

const CardComponent = React.forwardRef<RefType, CardProps>(
  (
    {
      id = '',
      children,
      className = '',
      largePadding = false,
      shadow = false,
      fitContent = false,
      noPadding = false,
      fullHeight = false,
      isExpandable = false,
      padding = '',
      onChangeExpanded = undefined,
      initiallyExpanded = true,
      arrowColor,
    },
    ref
  ) => {
    const [isExpanded, setIsExpanded] = React.useState(initiallyExpanded)

    useImperativeHandle(ref, () => ({
      expandCard: () => setIsExpanded(true),
    }))

    const handleSetExpanded = (expanded: boolean) => {
      onChangeExpanded?.(expanded)
      setIsExpanded(expanded)
    }

    return (
      <CardWrapper
        id={id}
        ref={ref as React.Ref<HTMLDivElement>}
        largePadding={largePadding}
        shadow={shadow}
        fitContent={fitContent}
        noPadding={noPadding}
        fullHeight={fullHeight}
        className={className}
        padding={padding}
      >
        {React.Children.map(
          children,
          (child) =>
            React.isValidElement(child) &&
            // TODO: set the right type
            React.cloneElement<any>(child, {
              isExpandable,
              isExpanded,
              setIsExpanded: handleSetExpanded,
              arrowColor,
            })
        )}
      </CardWrapper>
    )
  }
)

const Body = React.forwardRef<HTMLDivElement, CardBodyProps>(
  ({ children, className = '', isExpanded = false, ...rest }, ref) => (
    <CardBody isExpanded={isExpanded} ref={ref} className={className} {...rest}>
      {children}
    </CardBody>
  )
)

const Header: React.FC<CardHeaderProps> = ({
  children,
  className = '',
  isExpandable = false,
  isExpanded = false,
  setIsExpanded = () => {},
  padding = '',
  margin = '',
  arrowColor,
}) => {
  return (
    <HeaderWrapper padding={padding} margin={margin}>
      {isExpandable ? (
        <IconWrapper isExpanded={isExpanded}>
          <FontAwesomeIcon
            onClick={() => {
              setIsExpanded?.((currState) => !currState)
            }}
            icon={['fal', 'chevron-down']}
            color={arrowColor}
          />
        </IconWrapper>
      ) : null}
      <CardHeader className={className}>
        <TitleContainer>{children}</TitleContainer>
      </CardHeader>
    </HeaderWrapper>
  )
}

const Card = CardComponent as CardType
Card.Body = Body
Card.Header = Header

export default Card
