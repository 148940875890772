export default {
  filters: {
    sidebar: {
      filter: 'Filter',
      filters: 'Filters',
      clearAll: 'Clear All',
      type: 'Type',
      updateType: 'Update Type',
      updateTags: 'Update Tags',
      updateStatus: 'Update Status',
    },
    filterBy: 'Filter By',
    all: {
      title: 'All',
    },
    draft: {
      title: 'Draft',
      draftsOnly: 'Drafts Only',
    },
    published: {
      title: 'Published',
      publishedOnly: 'Published Only',
    },
    suggested: {
      title: 'Suggested',
      suggestedOnly: 'Suggested Only',
    },
    updateTypes: {
      title: 'Update Type',
      options: {
        announcement: 'Announcement',
        accountingReport: 'Accounting Report',
        document: 'Document',
        email: 'Email',
        note: 'Note',
        transaction: 'Transaction',
      },
    },
    tags: {
      title: 'Update Tags',
    },
  },
  createTitles: {
    note: 'Create Note',
    document: 'Create Document',
    announcement: 'Create Announcement',
    accounting: 'Create Accounting',
    transaction: 'Create Transaction',
    email: 'Create Email',
    file: 'File Upload',
  },
  note: {
    title: {
      label: 'Title',
      placeholder: 'What’s the note about?',
    },
    date: {
      label: 'Publish date',
    },
    body: {
      label: 'Description',
      placeholder: 'Write your rationale here ...',
    },
  },

  announcement: {
    title: {
      label: 'Title',
      placeholder: 'What’s the announcement about?',
    },
    date: {
      label: 'Publish date',
    },
    body: {
      label: 'Description',
      placeholder: 'Write your rationale here ...',
    },
  },

  document: {
    title: {
      label: 'Title',
      placeholder: 'What’s the document about?',
    },
    date: {
      label: 'Publish date',
    },
    additionalMessage: {
      label: 'Additional message',
      placeholder: 'Write your message here ...',
    },
  },

  header: {
    titles: {
      updates: 'Updates',
      organization: 'Organization Profile',
    },
    shareSettings: 'Share Settings',
    editUpdate: 'Edit Update',
    editDraft: 'Edit Draft',
    reshareUpdate: 'Reshare Update',
    confidentialUpdate: 'Confidential Update',
    owner: '(Owner)',
    public: '(Public)',
    updateVisibility: 'Update Visibility',
    updateLocked: 'This update is currently locked for editing by {userName}.',
  },
  transaction: {
    title: {
      label: 'Title',
      placeholder: 'Give the transaction a title',
    },

    transactionType: {
      label: 'Transaction Type',
      placeholder: 'e.g Investment',
    },

    files: {
      title: 'Attached files ({filesCount})',
    },
  },
  confirmationModal: {
    title: 'SHARE {updateType}',
    description:
      "Are you sure you don't want to share this {updateType} with {investorName}?",
    descriptionTwo:
      'This {updateType} will only be visible to your group. Remember, you can also edit the share settings after publishing.',
    confirmMessage: 'Yes, publish it',
    shareWith: 'Share with...',
    showThisMessageAgain: "Don't show this message again",
  },
  includedIn: 'Included in',
  investors: 'Investor',
  shareSettings: 'Share Settings',
  previewOriginal: 'Preview Original',
  previewDraft: 'Preview Draft',
  addListsGroupsUsers: 'Add lists, groups or users',
  confidentialUpdateDesc:
    'Tag it as confidential, so recipients know this is privileged information.',
  clockworkUniverseCommunity: 'Clockwork Universe Community',
  unverifiedUserMessage:
    'Your account is being verified. You can’t publish public updates until your account is verified.',
  recentlyUsedGroupsListsAndUsers: 'Recently used groups, lists & users',
  addedAlready: 'Added already',
  group: 'Group',
  list: 'List',
  user: 'User',
  canView: 'Can view',
  alreadyAddedRecipient:
    "This recipient was already added. Don't worry we will ignore the duplicate and share the update once.",
  groupOwnedUpdate:
    '{updateType} created or added to a portfolio are always group owned.',
  errorAddingUser: 'User could not be added, please remove it and try again.',
  errorEditingReshareUpdate:
    'There was an error when trying to edit the reshare settings, please try again later.',
  errorCreatingReshareUpdate:
    'There was an error when trying to reshare the update, please try again later.',
  reshareSuccess: 'The {updateType} has been reshared successfully.',
  you: 'you',
  yourGroup: 'your group',
  owner: 'Owner',
  unreachableEmail: 'Unreachable email',
  general: 'General',
  copyLink: 'Copy Link',
  scheduledUpdate: 'Scheduled',
  common: {
    publishDate: 'Publish Date',
    edited: 'Edited',
  },
  draft: 'Draft',
  suggested: 'Suggested',
  updateType: {
    accounting: 'accounting',
    note: 'note',
    document: 'document',
    transaction: 'transaction',
    announcement: 'announcement',
    report: 'email',
  },
  drafts: {
    draftLabel: 'draft',
    scheduledDraft: 'DRAFT · SCHEDULED',
    saving: 'Saving...',
    saved: 'Draft saved!',
    noConnection: 'No connection',
    untitled: 'Untitled {updateType}',
    errorCreatingDraft:
      'An error occurred when trying to create the draft, try again later',
    discardDraftTitle: 'Discard Draft',
    discardDraftMessage: 'Are you sure you want to discard this draft?',
    discardDraftDescription: 'This action can not be undone.',
    draftDiscardedSuccess: 'Draft discarded successfully',
    draftDiscardedError:
      'An error occurred discarding the draft, try again later.',
  },
  visibilityOptions: {
    onlyMe: 'Only Me',
    public: 'Public',
    multipleGroupsUsers: 'Multiple Groups/Users',
  },
  preview: {
    locked: 'This update is currently locked for editing by {userName}.',
  },
  scheduled: 'scheduled',
  publishUpdate: 'Publish update',
  publishedDate: 'This update will be published on {publishDate}',
  changeUpdateDate: 'Do you want to set the publishing date to today?',
  publishToday: 'Yes, publish it today',
  publishSelectedDate: 'No, publish it on selected date',
  investor: 'Investor',
  reshareModal: {
    message: 'Message',
    messagePlaceholder: 'Write a message here...',
    title: 'Reshare "{updateTitle}"',
    selectListsGroupsUsers: 'Select lists, groups or users',
    reshareUpdate: 'Reshare Update',
  },
  exampleUrl: 'https://www.example.com',
  privatePortfolioDisclaimer: `<bold>Private portfolio update</bold> - This update is private and can only be viewed or edited by you. This is because it belongs to a portfolio accessible only to yourself.`,
  privatePortfolioGroupDisclaimer: `<bold>Private portfolio update</bold> - This update is private and can only be viewed or edited by you or your group. This is because it belongs to a portfolio accessible to your group only.`,

  tableColumns: {
    portfolioHolding: 'Portfolio/Holding',
    loadingUpdates: 'Loading Updates',
    loadMoreUpdates: 'Load More Updates',
    noUpdates: 'No Updates.',
    lastUpdateDate: 'Last Update Date',
    totalNumberOfUpdates: 'Total # of Updates',
    totalNumberOfNotes: 'Total # of Notes',
    totalNumberOfDocuments: 'Total # of Documents',
    totalNumberOfAnnouncements: 'Total # of Announcements',
    totalNumberOfEmails: 'Total # of Emails',
    numberCreatedByGroup: '# Updates created by your group',
    numberSharedWithGroup: '# Updates shared with your group',
  },

  nestedTableColumns: {
    title: 'Title',
    date: 'Date',
    Type: 'Type',
    tags: 'Tags',
    description: 'Description',
  },
}
