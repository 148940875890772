import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import IconButtonToStyle from 'ui/IconButton'
import { Body as BodyToStyle } from '../../OnboardingModal.styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
`

export const Title = styled.span`
  font-size: 2.4rem;
  margin-top: 0.4rem;
`

export const Subtitle = styled.span`
  font-size: 1.4rem;
`

export const LogoContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 51.9rem;
  height: 51.9rem;
  top: -21.7rem;
  right: -15.4rem;
  opacity: 0.2;
`

export const Label = styled.span`
  font-size: 1.6rem;
  color: ${color('darkGray')};
`

export const ActionsContainer = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  color: ${color('darkGray')};
`

export const Action = styled.div`
  padding: 1.5rem 1.6rem 1.5rem 2.4rem;
  display: flex;
  gap: 1.4rem;
  align-items: center;

  & + & {
    border-top: 0.1rem solid ${color('veryLightGray')};
  }
`

export const IconContainer = styled.div`
  background-color: ${color('darkBlue')};
  height: 100%;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const ActionTitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`

export const ActionTitle = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
`

export const ActionSubtitle = styled.span`
  font-size: 1.2rem;
`

export const IconButton = styled(IconButtonToStyle)`
  rotate: -90deg;
`

export const Body = styled(BodyToStyle)`
  padding-bottom: 0.1rem;
`
