/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { WindowScroller } from 'react-virtualized'
import { VariableSizeList as List } from 'react-window'

import Checkbox from 'components/Checkbox'
import CWLoader from 'components/CWLoader'
import { randomId } from 'utils/functions/number'
import NoResultsFound from 'components/NoResultsFound'
import ColHeader from '../ColHeader/ColHeader'
import ExpandableRow from '../ExpandableRow/ExpandableRow'
import Paginator from '../Paginator/Paginator'

import {
  NoResultsLabel,
  NoResultsWrapper,
  TableBody,
  TableHeader,
  TableWrapper,
  ExpandIcon,
  LoaderContainer,
} from './Table.styles'
import { ExpandableRowContext } from '../hooks/useExpandableRow'
import { ExpandableRowStateProvider } from '../hooks/useExpandableRowState'
import { useTableLayout } from '../hooks/useTableLayout'
import { useTableSorting } from '../hooks/useTableSorting'

const PAGE_SIZE = 20

const Table = ({
  keyPath,
  data,
  columns,
  rowHeight,
  expandedRowHeight,
  noResultsLabel,
  noResultsLegend,
  wrapText,
  loading,
  isRowExpandable,
  expandRowIcon,
  closeRowIcon,
  showExpandableColumn,
  getExpandableData,
  expandedRowsColumns = columns,
  hideExpandIconHeaderCell,
  hideHeader,
  tableHeight,
  width,
  headerHeight,
  initialSortId,
  sortDirection: initialSortDirection,
  onChangeSortBy: onChangeSortByProp,
  onChangeExpandedRowSortBy,
  customRows,
  showExpandableHeaderColumns,
  showExpandableRowZeroState,
  renderExpandableRowZeroState,
  onExpandRow,
  onCollapseRow,
  onClickExpandedRow,
  onClickRow,
  paginateExpandedRows,
  asyncExpandedPagination,
  expandedLoadingIndicatorText,
  paginationLabel,
  pageSize,
  paginationEnabled,
  onLoadMoreRows,
  expandOnRowClick,
  selectableRows,
  onSelectRow,
  scrollElementId,
  showLoadingIndicatorOnExpandableRows,
  innerScroll,
  maxHeight,
  overflow,
  getExpandedRowHeight,
}) => {
  const { listRef, scrollElement, setSize, getSize } = useTableLayout({
    scrollElementId,
    rowHeight,
  })

  const { sortBy, sortDirection, onChangeSortBy } = useTableSorting({
    initialSortId: initialSortId || columns[0].id,
    initialSortDirection,
    onSort: onChangeSortByProp,
  })

  const getCustomRow = (rowIndex) => {
    return customRows.find(
      ({ index, showCustomRow }) => showCustomRow && index === rowIndex
    )
  }

  const renderHeader = (forceHeaderWithoutData = false) => {
    if ((!hideHeader && forceHeaderWithoutData) || data.length > 0 || loading) {
      return (
        <TableHeader headerHeight={headerHeight}>
          {showExpandableColumn && !hideExpandIconHeaderCell && (
            <ColHeader key="expand" column={{ flex: 0.1, component: null }} />
          )}
          {columns.map((column, index) => {
            if (!column.hidden) {
              return (
                <Fragment key={column.sortBy || randomId()}>
                  {selectableRows && index === 0 && (
                    <Checkbox
                      onChange={(event) => {
                        onSelectRow('selectAll', event?.target?.checked)
                      }}
                      type="checkbox"
                    />
                  )}

                  <ColHeader
                    key={index}
                    column={column}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    onSort={() => onChangeSortBy(column.sortKey, column.sortBy)}
                  />
                </Fragment>
              )
            }
            return null
          })}
        </TableHeader>
      )
    }
    return null
  }

  const renderNoResults = () => {
    if (loading) {
      return null
    }

    if (data.length === 0) {
      const customRow = getCustomRow(0)
      if (customRow?.showCustomRow) {
        return (
          <TableWrapper
            tableHeight={tableHeight}
            rowHeight={rowHeight}
            countRows={data.length}
            width={width}
          >
            {renderHeader(true)}

            <TableBody>{customRow?.component}</TableBody>
          </TableWrapper>
        )
      }

      return (
        <NoResultsWrapper>
          <NoResultsLabel>
            <NoResultsFound.Title>{noResultsLabel}</NoResultsFound.Title>
            <NoResultsFound.Legend>{noResultsLegend}</NoResultsFound.Legend>
          </NoResultsLabel>
        </NoResultsWrapper>
      )
    }

    return null
  }

  if (!scrollElement) {
    return null
  }

  return (
    <div className="table-container" data-testid="expandable-table">
      <TableWrapper
        tableHeight={tableHeight}
        rowHeight={rowHeight}
        countRows={data.length}
        width={width}
      >
        {renderHeader()}

        {loading ? (
          <LoaderContainer>
            <CWLoader />
          </LoaderContainer>
        ) : (
          <TableBody id="table-body">
            {!innerScroll && (
              <WindowScroller
                onScroll={({ scrollTop }) => {
                  listRef.current?.scrollTo?.(scrollTop)
                }}
                scrollElement={scrollElement}
              >
                {() => <div />}
              </WindowScroller>
            )}

            <ExpandableRowContext.Provider
              value={{
                setSize,
                keyPath,
                columns,
                selectableRows,
                onSelectRow,
                expandedRowsColumns,
                rowHeight,
                expandedRowHeight,
                wrapText,
                showExpandableColumn,
                showExpandableHeaderColumns,
                expandRowIcon,
                closeRowIcon,
                isRowExpandable,
                getExpandableData,
                showExpandableRowZeroState,
                renderExpandableRowZeroState,
                onExpandRow,
                onCollapseRow,
                onChangeExpandedRowSortBy,
                onClickRow,
                onClickExpandedRow,
                paginateExpandedRows,
                paginationLabel,
                asyncExpandedPagination,
                expandedLoadingIndicatorText,
                pageSize,
                expandOnRowClick,
                getCustomRow,
                showLoadingIndicatorOnExpandableRows,
                getExpandedRowHeight,
              }}
            >
              <ExpandableRowStateProvider>
                <List
                  ref={listRef}
                  height={
                    innerScroll
                      ? Math.min(data.length * rowHeight + 3, maxHeight)
                      : window.innerHeight
                  }
                  overscanCount={20}
                  width="100%"
                  itemCount={data.length}
                  itemSize={getSize}
                  itemData={data}
                  style={
                    !innerScroll
                      ? { height: '100% !important', overflow: 'visible' }
                      : { overflow }
                  }
                  itemKey={(index, rowData) => {
                    return get(rowData[index], keyPath)
                  }}
                >
                  {ExpandableRow}
                </List>
              </ExpandableRowStateProvider>
            </ExpandableRowContext.Provider>

            {paginationEnabled && data.length > 0 && (
              <Paginator
                onLoadMoreRows={onLoadMoreRows}
                label={paginationLabel}
              />
            )}
            {renderNoResults()}
          </TableBody>
        )}
      </TableWrapper>
    </div>
  )
}

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array.isRequired,
  rowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeSortBy: PropTypes.func,
  onChangeExpandedRowSortBy: PropTypes.func,
  initialSortId: PropTypes.string,
  noResultsLabel: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  noResultsLegend: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  loading: PropTypes.bool,
  wrapText: PropTypes.bool,
  expandRowIcon: PropTypes.node,
  closeRowIcon: PropTypes.node,
  isRowExpandable: PropTypes.func,
  showExpandableColumn: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  getExpandableData: PropTypes.func,
  expandedRowsColumns: PropTypes.array,
  hideExpandIconHeaderCell: PropTypes.bool,
  sortDirection: PropTypes.string,
  keyPath: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
  tableHeight: PropTypes.string,
  width: PropTypes.string,
  headerHeight: PropTypes.string,
  customRows: PropTypes.arrayOf(
    PropTypes.shape({
      showCustomRow: PropTypes.bool,
      hideOriginalRow: PropTypes.bool,
      index: PropTypes.number,
      component: PropTypes.element.isRequired,
    })
  ),
  showExpandableRowZeroState: PropTypes.bool,
  renderExpandableRowZeroState: PropTypes.func,
  onClickExpandedRow: PropTypes.func,
  onClickRow: PropTypes.func,
  onExpandRow: PropTypes.func,
  onCollapseRow: PropTypes.func,
  showExpandableHeaderColumns: PropTypes.bool,
  expandedRowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paginateExpandedRows: PropTypes.bool,
  paginationLabel: PropTypes.string,
  pageSize: PropTypes.number,
  paginationEnabled: PropTypes.bool,
  onLoadMoreRows: PropTypes.func,
  expandOnRowClick: PropTypes.bool,
  scrollElementId: PropTypes.string,
  selectableRows: PropTypes.bool,
  showLoadingIndicatorOnExpandableRows: PropTypes.bool,
  onSelectRow: PropTypes.func,
  getExpandedRowHeight: PropTypes.func,
  innerScroll: PropTypes.bool,
  maxHeight: PropTypes.number,
  overflow: PropTypes.string,
  asyncExpandedPagination: PropTypes.bool,
  expandedLoadingIndicatorText: PropTypes.string,
}

Table.defaultProps = {
  scrollElementId: 'mainLayout',
  expandRowIcon: <ExpandIcon icon={['fal', 'angle-down']} />,
  closeRowIcon: <ExpandIcon icon={['fal', 'angle-up']} />,
  data: [],
  isRowExpandable: () => false,
  showExpandableColumn: false,
  rowHeight: 60,
  onChangeSortBy: () => {},
  onChangeExpandedRowSortBy: () => {},
  initialSortId: null,
  loading: false,
  wrapText: false,
  getExpandableData: () => {},
  expandedRowsColumns: [],
  hideExpandIconHeaderCell: false,
  sortDirection: null,
  hideHeader: false,
  tableHeight: null,
  width: null,
  headerHeight: null,
  customRows: [],
  showExpandableRowZeroState: false,
  renderExpandableRowZeroState: null,
  onExpandRow: () => {},
  onCollapseRow: () => {},
  onClickExpandedRow: null,
  onClickRow: null,
  showExpandableHeaderColumns: false,
  expandedRowHeight: null,
  getExpandedRowHeight: null,
  paginateExpandedRows: false,
  paginationLabel: '',
  pageSize: PAGE_SIZE,
  noResultsLabel: '',
  noResultsLegend: '',
  paginationEnabled: false,
  onLoadMoreRows: () => {},
  expandOnRowClick: false,
  selectableRows: false,
  onSelectRow: () => {},
  showLoadingIndicatorOnExpandableRows: false,
  innerScroll: false,
  maxHeight: 500,
  overflow: 'auto',
  asyncExpandedPagination: false,
  expandedLoadingIndicatorText: 'Loading...',
}

export default Table
