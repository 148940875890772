import styled, { keyframes } from 'styled-components'
import { color } from 'utils/functions/colors'

const load = keyframes`
 0% { -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%; }
  16.67% { -webkit-mask-position: 0% 100%, 50% 0%, 100% 0%; }
  33.33% { -webkit-mask-position: 0% 100%, 50% 100%, 100% 0%; }
  50% { -webkit-mask-position: 0% 100%, 50% 100%, 100% 100%; }
  66.67% { -webkit-mask-position: 0% 0%, 50% 100%, 100% 100%; }
  83.33% { -webkit-mask-position: 0% 0%, 50% 0%, 100% 100%; }
  100% { -webkit-mask-position: 0% 0%, 50% 0%, 100% 0%; }
`

export const ProcessingDataLoading = styled.div`
  width: 1.8rem;
  height: 1.2rem;
  aspect-ratio: 6/3;
  --_g: no-repeat radial-gradient(#000 68%, #0000 71%);
  -webkit-mask: var(--_g), var(--_g), var(--_g);
  -webkit-mask-size: 25% 40%;
  background: ${color('purple')};
  animation: ${load} 2s infinite;
`
