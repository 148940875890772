/* eslint-disable no-nested-ternary */
import React from 'react'

import Drawer from 'components/Drawer'
import { displayFileSize } from 'utils/functions/files'
import dayjs from 'dayjs'
import FileIcon from 'components/FileIcon'
import { FormattedMessage, useIntl } from 'react-intl'

import IconButton from 'ui/IconButton'
import List from 'components/List'
import CWLoader from 'components/CWLoader'
import { NoResultsLabel } from 'components/ExpandableTable/Table/Table.styles'
import NoResultsFound from 'components/NoResultsFound'
import { FileContent } from 'utils/types/files'
import Separator from 'ui/Separator'
import AttachmentAnalyticRow from './AttachmentAnalyticRow'
import { useAttachmentAnalytics } from './useAttachmentAnalytics'
import {
  FileIconWrapper,
  FileLabel,
  FileName,
} from '../AttachmentCard/AttachmentCard.styles'
import {
  FileDataWrapper,
  FileGeneralDataWrapper,
  FileNameContainer,
  Content,
} from './AttachmentSidebar.styles'

interface AttachmentSidebarProps {
  isOpen: boolean
  closeDrawer: () => void
  attachment: FileContent
}
const AttachmentSidebar: React.FC<AttachmentSidebarProps> = ({
  isOpen,
  closeDrawer,
  attachment,
}) => {
  const intl = useIntl()

  const { attachmentAnalytics, isLoading, handleDownloadFile, totalDownloads } =
    useAttachmentAnalytics(attachment)

  return (
    <div data-testid="attachment-sidebar">
      <Drawer
        isOpen={isOpen}
        onCloseDrawer={closeDrawer}
        headerTitle={intl.formatMessage({
          id: 'analytics.fileDetails',
        })}
      >
        <Drawer.Content contentPadding="2.5rem 2.4rem">
          <FileGeneralDataWrapper>
            <FileDataWrapper>
              <FileIconWrapper>
                <FileIcon
                  type={attachment.fileFormat}
                  src={attachment.url}
                  useBackgroundImage
                  small
                />
              </FileIconWrapper>
              <Content>
                <FileNameContainer>
                  <FileName title={attachment.fileName} maxWidth="20rem">
                    {attachment.fileName}
                  </FileName>
                  <FileLabel>
                    ({displayFileSize(attachment.fileSize)})
                  </FileLabel>
                </FileNameContainer>
                <FileLabel>
                  <>
                    {intl.messages['analytics.added']}{' '}
                    {dayjs(attachment.createdAt).format('DD MMM YYYY')}
                  </>
                </FileLabel>
                <FileLabel>
                  {intl.formatMessage(
                    { id: 'analytics.totalDownloads' },
                    { count: totalDownloads }
                  )}
                </FileLabel>
              </Content>
            </FileDataWrapper>
            <IconButton
              icon="download"
              iconFamily="fal"
              onClick={handleDownloadFile}
              aria-label="Download file"
            />
          </FileGeneralDataWrapper>

          <Separator space="3.2rem" />

          {isLoading ? (
            <CWLoader
              logoSize="3rem"
              text={<FormattedMessage id="analytics.loadingAnalytics" />}
            />
          ) : attachmentAnalytics.length === 0 ? (
            <NoResultsLabel>
              <NoResultsFound.Title>
                <FormattedMessage id="analytics.attachmentAnalyticZeroStateTitle" />
              </NoResultsFound.Title>
              <NoResultsFound.Legend>
                <FormattedMessage id="analytics.attachmentAnalyticZeroStateDescription" />
              </NoResultsFound.Legend>
            </NoResultsLabel>
          ) : (
            <div data-testid="attachments-table">
              <List
                overflow="visible"
                removeMaxHeight
                rowHeightAuto
                rowWidthAuto
                striped={false}
                keyExtractor={(index) => attachmentAnalytics[index].id}
                elements={attachmentAnalytics.map((analytic) => (
                  <AttachmentAnalyticRow attachmentAnalytic={analytic} />
                ))}
              />
            </div>
          )}
        </Drawer.Content>
      </Drawer>
    </div>
  )
}

export default AttachmentSidebar
