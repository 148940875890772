import { DescriptionContent, Wrapper } from './CompanyDescription.styles'

interface Props {
  content: string
}

const CompanyDescription = ({ content }: Props) => {
  return (
    <Wrapper>
      <DescriptionContent className="fs-exclude">{content}</DescriptionContent>
    </Wrapper>
  )
}

export default CompanyDescription
