import { ContentFilters } from 'api/ContentService'

export const QUERIES = {
  FILE_CONTENT: 'file-content',
  EMAIL_CONTENT: 'email-content',
  CONTENTS: 'contents',
} as const

export const contentsKeys = {
  all: (filters: ContentFilters) => [QUERIES.CONTENTS, filters] as const,
  fileContentById: (id: string) => [QUERIES.FILE_CONTENT, id] as const,
  emailContentById: (id: string) => [QUERIES.EMAIL_CONTENT, id] as const,
}
