import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledHeading = styled.h1`
  font-size: 3.2rem;
  color: ${color('black')};
  line-height: 3.8rem;
  font-weight: 400;


  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${(props) =>
    props.as === 'h2' &&
    css`
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 2.88rem;
      color: ${color('darkGray')};
    `}}
`
