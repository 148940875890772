import styled, { css } from 'styled-components'

interface Props {
  color?: string
  textColor?: string
  opacity?: number
  withBorder?: boolean
}

const StatusTag = styled.div<Props>`
  padding: 0.4rem 1.1rem;
  background: ${(props) => props.color || 'transparent'};
  border-radius: 1.5rem;

  ${(props) =>
    props.withBorder &&
    css`
      border: 0.1rem solid ${props.textColor};
    `}

  display: inline-block;
  color: ${(props) => props.textColor || 'white'};
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: ${(props) => props.opacity || 1};
`

export default StatusTag
