import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StyledButton, StyledSpinner } from './Button.styles'

const Button = ({ children, ...rest }) => {
  return (
    <StyledButton
      type="button"
      disabled={rest.isDisabled || rest.isLoading}
      {...rest}
    >
      {rest.isLoading && (
        <StyledSpinner
          data-testid="button-spinner"
          icon={['fas', 'spinner-third']}
        />
      )}
      {rest.add && !rest.isLoading && (
        <FontAwesomeIcon icon={['far', 'plus']} />
      )}
      {rest.icon && !rest.isLoading && <FontAwesomeIcon icon={rest.icon} />}
      {children}
    </StyledButton>
  )
}

Button.defaultProps = {
  isLoading: false,
  children: null,
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default Button
