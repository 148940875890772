import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'

import UserService from 'api/UserService'
import Toast from 'components/Toast'
import { useSignIn } from 'containers/Auth/SignIn/useSignIn'
import {
  addNewUserGroup,
  setCurrentGroupId,
  setIsSigningUp,
} from 'features/authSlice'
import { useHistory } from 'react-router-dom'
import { getUser } from 'selectors/auth'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { QUERIES } from 'utils/queries/subscriptions'
import { Group } from 'utils/types/user'

const truncate = (name: string) => {
  return name.length > MAX_COUNTER_CHARACTERS
    ? name.substring(0, MAX_COUNTER_CHARACTERS)
    : name
}

interface Props {
  onCreateNewGroup: (
    userId: string,
    groupName: string,
    image?: File
  ) => Promise<Group>

  groupName?: string
}

export const useGroupSetup = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { loadUserTier } = useSignIn()
  const isFreeTrialEmailSent = useRef(false)
  const history = useHistory()
  const user = useAppSelector(getUser)

  const sendFreeTrialEmail = useCallback(() => {
    if (!isFreeTrialEmailSent.current && user?.id) {
      isFreeTrialEmailSent.current = true
      UserService.sendFreeTrialEmail(user.id)
    }
  }, [user?.id])

  const createGroup = useCallback(
    async ({ onCreateNewGroup, groupName }: Props) => {
      sendFreeTrialEmail()
      const userName = truncate(user.name!)
      const groupNameToUse = groupName || userName

      try {
        const newGroup = await onCreateNewGroup(user.id, groupNameToUse)

        await loadUserTier(newGroup.id)
        await dispatch(addNewUserGroup(newGroup))
        await dispatch(setCurrentGroupId(newGroup.id))
        dispatch(setIsSigningUp(false))
        await queryClient.removeQueries([QUERIES.GET_SUBSCRIPTION_DATA])
        history.push('/')
      } catch (error) {
        const errorMsg = JSON.parse(error?.message)
        if (errorMsg?.errors?.name) {
          const random2Digits = Math.floor(Math.random() * 100)
          await createGroup({
            onCreateNewGroup,
            groupName: `${groupNameToUse} ${random2Digits}`,
          })
        } else {
          Toast.displayIntl('investorSignup.createGroupError', 'error')
        }
      }
    },
    [
      dispatch,
      history,
      loadUserTier,
      queryClient,
      sendFreeTrialEmail,
      user?.id,
      user?.name,
    ]
  )

  return {
    createGroup,
  }
}
