/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import { AccountingReportType } from 'utils/constants/accounting'
import { generateKey } from 'utils/functions/utils'
import QBReportSection from './QBReportSection'
import {
  BodyContainer,
  Line,
  ReportTitle,
  ReportHeader,
  ReportContent,
  TableHeader,
  ReportRowValues,
  BorderCell,
} from '../ShowReport.styles'

const ShowReport = ({ data, border, reportType }) => {
  const header = data.Rows?.Row[0]?.Header
  const sections = data.Rows?.Row
  const shouldRemoveLastColumn =
    reportType === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS
  const periods = (header?.ColData?.length ?? 0) - shouldRemoveLastColumn - 1

  const slice = (arr, start = 0) =>
    arr?.slice?.(start, arr.length - shouldRemoveLastColumn) ?? []

  const getReportName = () => {
    if (reportType === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS) {
      return 'Profit & Loss'
    }
    return 'Balance Sheet'
  }

  return (
    <BodyContainer border={border}>
      <ReportHeader marginTop="0">
        <ReportTitle>{getReportName()}</ReportTitle>
        <ReportTitle light>
          {dayjs(data.Header?.Time).format('DD MMMM YYYY')}
        </ReportTitle>
      </ReportHeader>
      <Line />
      <ReportContent>
        <ReportRowValues separatorRow periods={periods}>
          <BorderCell />
          {new Array(periods > 1 ? periods - 1 : 0).fill().map((_, index) => (
            <BorderCell key={index} />
          ))}
        </ReportRowValues>

        <ReportRowValues periods={periods}>
          {slice(data.Columns?.Column).map?.((headerCell) => (
            <TableHeader
              labelColumn={!headerCell.ColTitle}
              key={generateKey(`${headerCell.ColTitle} ${headerCell.ColType}`)}
            >
              {headerCell.ColTitle?.toUpperCase?.() ?? ''}
            </TableHeader>
          ))}
        </ReportRowValues>
        {sections?.map((section) => {
          return (
            <QBReportSection
              periods={periods}
              title={
                section.Header?.ColData[0]?.value ??
                section.Summary?.ColData[0]?.value
              }
              sectionRows={section.Rows?.Row ?? []}
              isMainSection={section.Rows?.Row?.length === 0 ?? true}
              sectionSummary={section.Summary}
              shouldRemoveLastColumn={shouldRemoveLastColumn}
              isMainSectionSummary={false}
              key={generateKey(`${section.group} ${section.type}`)}
            />
          )
        })}
      </ReportContent>
    </BodyContainer>
  )
}

ShowReport.propTypes = {
  reportType: PropTypes.string.isRequired,
  border: PropTypes.bool,
  data: PropTypes.shape({
    Header: PropTypes.object,
    Columns: PropTypes.object,
    Rows: PropTypes.shape({
      Row: PropTypes.arrayOf(
        PropTypes.shape({
          Header: PropTypes.object,
        })
      ),
    }),
  }),
}

ShowReport.defaultProps = {
  border: true,
  data: {
    Rows: {},
    Columns: {},
    Header: {},
  },
}

export default ShowReport
