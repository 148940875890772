import { useState, useRef, useCallback, useContext } from 'react'

import { useField } from 'components/Form/hooks'
import { PhoneInputContext } from 'ui/PhoneInput/PhoneInput'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import { CountryCode } from 'utils/types/common'

export type Position = 'top' | 'bottom'
export const DEFAULT_DROPDOWN_HEIGHT = 300

interface Props {
  onChange: (country: CountryCode) => void
  dropdownHeight?: number
  name: string
}

export const useCountryInput = ({ onChange, dropdownHeight, name }: Props) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [, fieldMeta] = useField(name)
  const countryDropdownRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const phoneInputContext = useContext(PhoneInputContext)

  const getPosition = useCallback((): Position => {
    if (containerRef.current && countryDropdownRef.current) {
      const inputRect = containerRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight
      const height = dropdownHeight || DEFAULT_DROPDOWN_HEIGHT

      if (inputRect.bottom + height > windowHeight) {
        return 'top'
      }
    }

    return 'bottom'
  }, [dropdownHeight])

  const selectOption = useCallback(
    (country: CountryCode) => {
      onChange(country)
      setShowDropdown(false)
    },
    [onChange]
  )

  const toggleDropdown = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setShowDropdown((prev) => !prev)
    },
    []
  )

  useOutsideClick(countryDropdownRef, () => setShowDropdown(false))

  return {
    countryDropdownRef,
    containerRef,
    showDropdown,
    selectOption,
    toggleDropdown,
    getPosition,
    large: phoneInputContext.large,
    hasError: !!(fieldMeta.touched && fieldMeta.error),
  }
}
