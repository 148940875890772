import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const RowContainer = styled.div`
  display: flex;
  min-height: ${(props) => (props.mainRow ? '6rem' : '4.5rem')};
  background-color: ${(props) => (props.mainRow ? 'white' : '#f5f5fa')};

  ${(props) =>
    props.mainRow &&
    css`
      border-bottom: 0.1rem solid #f0f0f0;
    `}
  ${(props) =>
    props.wrapText &&
    css`
      height: ${`${props.height}px` || (props.mainRow ? '6rem' : '4.5rem')};
    `}

  ${(props) => {
    if (props.getExpandedRowHeight) {
      return css`
        height: ${props.getExpandedRowHeight()};
        min-height: ${props.height}px;
      `
    }
    if (props.height !== undefined) {
      return css`
        height: ${props.height}px;
        min-height: ${props.height}px;
      `
    }
    return ''
  }}
    

  padding: 0 1.5rem;
  align-items: center;

  ${(props) =>
    props.isExpanded &&
    !props.nestedRow &&
    css`
      background-color: #ebedf6;
    `}

  ${(props) =>
    props.nestedRow &&
    css`
      background-color: ##ebedf6;
    `}

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
    `}

  div[id*='editButtonColumn'] > div > button {
    background: white;
    border: 1px solid ${color('veryLightGray')};

    svg {
      color: ${color('darkBlue')};
    }
  }

  &:hover {
    ${(props) =>
      props.clickable &&
      css`
        background-color: ${props.mainRow && props.isExpanded
          ? '#ebedf6'
          : '#f5f5fa'};
      `}
    div[id*='editButtonColumn'] {
      display: block;
    }
  }
`
