import { useQueryClient } from '@tanstack/react-query'
import { yupToFormErrors } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import DraftUpdateService from 'api/DraftUpdateService'
import { isActingAsFounder } from 'selectors/auth'
import { SuggestedUpdateTypes, UpdateVisibility } from 'utils/constants/updates'
import { getCreateSuggestedUpdatePayload } from 'utils/functions/api/suggestedUpdates'
import { mapUpdateFromTypeToEntityName } from 'utils/functions/suggestedUpdates'
import { getCreateDraftUpdateUrl } from 'utils/functions/updates'
import { EntityName } from 'utils/hooks/useEntityFromUrl'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import { QUERIES } from 'utils/queries/mixedUpdates'
import { SuggestedUpdate } from 'utils/types/update'
import { useSelectUpdatesContext } from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import { getSuggestedUpdateSchema } from 'utils/schemas/suggestedUpdate'
import { SuggestedUpdateForm } from 'utils/types/suggestedUpdate'

import { TopicEntity } from 'utils/types/chatTopicEntity'
import { Investor } from 'utils/types/investors'
import { useCurrentGroupRules } from './components/SuggestedUpdateForm'
import { useSuggestedHolding } from './components/SuggestedUpdateForm/hooks/useSuggestedHolding'

export const useSuggestedUpdateFeedCard = (
  suggestedUpdate: SuggestedUpdate
) => {
  const intl = useIntl()
  const [isDiscardMode, setIsDiscardMode] = useState(false)
  const isFounder = useAppSelector(isActingAsFounder)
  const company = useGroupCompany()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { entity: suggestedHolding } = useSuggestedHolding({
    suggestedUpdate,
  })
  const { handleHoverCard, handleUnHoverCard, isCardSelected, isCardHover } =
    useSelectUpdatesContext()

  const onMouseProps = useMemo(
    () => ({
      onMouseEnter: () => handleHoverCard(suggestedUpdate),
      onMouseLeave: () => handleUnHoverCard(),
      hoverState: isCardHover(suggestedUpdate),
      checkState: isCardSelected(suggestedUpdate),
    }),
    [
      handleHoverCard,
      handleUnHoverCard,
      isCardHover,
      isCardSelected,
      suggestedUpdate,
    ]
  )

  const enterDiscardMode = useCallback(() => setIsDiscardMode(true), [])
  const cancelDiscardMode = useCallback(() => setIsDiscardMode(false), [])

  const { isUpdateFromRequired } = useCurrentGroupRules()

  const validateSuggestedUpdate = useCallback(
    async (values: SuggestedUpdateForm) => {
      try {
        const isRequired = isUpdateFromRequired(values)
        const schema = getSuggestedUpdateSchema(isRequired)

        await schema.validate(values, { abortEarly: false })
        return {}
      } catch (error) {
        return yupToFormErrors(error)
      }
    },
    [isUpdateFromRequired]
  )

  const getCreateTooltipText = useCallback(
    (values: SuggestedUpdateForm) => {
      const { type, updateFrom, investor } = values

      if (type === SuggestedUpdateTypes.SUGGESTED) {
        return intl.formatMessage({
          id: 'suggestedUpdates.identifyUpdateTypeTooltip',
        })
      }

      if (isUpdateFromRequired(values) && !updateFrom && !investor) {
        return intl.formatMessage({
          id: 'suggestedUpdates.selectUpdateSourceTooltip',
        })
      }

      return ''
    },
    [intl, isUpdateFromRequired]
  )

  const getSubject = useCallback(
    (values: { updateFrom?: TopicEntity; investor?: Investor }) => {
      if (isFounder) {
        return {
          subject: EntityName.COMPANIES,
          subjectId: company?.id ?? '',
        }
      }

      if (values.investor) {
        return {
          subject: EntityName.INVESTORS,
          subjectId: values.investor.id,
        }
      }

      return {
        subject: mapUpdateFromTypeToEntityName(values.updateFrom),
        subjectId: values.updateFrom?.id ?? '',
      }
    },
    [company, isFounder]
  )

  const onSubmit = useCallback(
    async (values: SuggestedUpdateForm) => {
      const { subject, subjectId } = getSubject(values)
      const updateType = values.type
      if (updateType !== SuggestedUpdateTypes.SUGGESTED) {
        const draftUpdate = getCreateSuggestedUpdatePayload(
          updateType,
          {
            ownByGroup: true,
            removedGroups: [],
            removedUsers: [],
            reshareSettings: {
              isBlocked: false,
              isConfidential: false,
            },
            sharedGroups: [],
            sharedUsers: [],
            shouldBehaveAsFounder: isFounder,
            visibility: UpdateVisibility.ONLY_ME,

            title: values.title,
            body: '',
            date: new Date(),
            uploads: values.contents,
            transactionValues: {
              transactionFundType: values.transactionFundType,
              investor: values.investor,
              description: '',
            },
          },
          values.suggestedUpdateId
        )

        const { draftUpdateId } = await DraftUpdateService.createDraftUpdate({
          subject,
          subjectId,
          draftUpdate,
          updateType,
        })
        queryClient.removeQueries([QUERIES.MIXED_UPDATES])

        const path = getCreateDraftUpdateUrl(
          updateType,
          draftUpdateId,
          !values.investor && values.updateFrom?.id !== company?.id,
          subject,
          subjectId
        )
        history.push(path)
      }
    },
    [getSubject, isFounder, queryClient, company?.id, history]
  )

  return {
    isDiscardMode,
    onMouseProps,
    suggestedHolding,
    isUpdateFromRequired,
    validateSuggestedUpdate,
    enterDiscardMode,
    cancelDiscardMode,
    getCreateTooltipText,
    onSubmit,
  }
}
