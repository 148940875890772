import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import { useStorageUsage } from 'components/StorageUsage/useStorageUsage'

import * as Styles from './RunOutOfStorageMessage.styles'

interface Props {
  className?: string
}

const RunOutOfStorageMessage = ({ className }: Props) => {
  const { hasExceededStorage } = useStorageUsage()

  if (!hasExceededStorage) {
    return null
  }

  return (
    <Styles.Container className={className}>
      <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
      <div>
        <FormattedMessage
          id="runOutOfStorage.message"
          values={{
            link: (message) => (
              <Styles.DecoratedText
                onClick={() => window.Intercom('showNewMessage')}
              >
                {message}
              </Styles.DecoratedText>
            ),
          }}
        />
      </div>
    </Styles.Container>
  )
}

export default RunOutOfStorageMessage
