import { PortfoliosFilters } from 'api/PortfolioService'

export const QUERIES = {
  PORTFOLIO: 'portfolio',
  ALL_INVESTMENTS: 'all-investments',
  PORTFOLIO_FMVS: 'portfolio-fair-market-values',
  PORTFOLIOS: 'portfolios',
  PORTFOLIO_STATUS: 'portfolio-status',
  HOLDING_METRICS_STATUS: 'holding-metrics-status',

  FUND_PORTFOLIO_HOLDINGS: 'fund-portfolio-holdings',
  FUND_PORTFOLIO_INVESTOR: 'fund-portfolio-investor',
  FUND_PORTFOLIO_INVESTORS: 'fund-portfolio-investors',
  FUND_PORTFOLIO_INVESTORS_INDEX: 'fund-portfolio-investors-index',
  FUND_PORTFOLIO_PREVIEW: 'fund-portfolio-preview',
  FUND_PORTFOLIO_AGGREGATED_HOLDINGS_SUMMARY:
    'fund-portfolio-aggregated-holdings-summary',

  FUNDS_PORTFOLIO_INVESTOR: 'funds-portfolio-investor',
  FUNDS_PORTFOLIO_SUMMARY_BY_INVESTOR: 'fund-portfolio-summary-by-investor',

  LAST_MONTH_UPDATES_BY_INVESTOR: 'last-month-updates-by-investor',
  SHARE_PORTFOLIO_SETTINGS: 'share_portfolio_settings',
  FETCH_SHARE_PORTFOLIO_SETTING_BY_ID: 'fetch_share_portfolio_setting_by_id',

  CAPITAL_STATEMENT: 'capital-statement',
  CAPITAL_STATEMENT_FUND_PORTFOLIO_INVESTOR:
    'capital-statement-fund-portfolio-investor',
  CAPITAL_STATEMENT_PREVIEW: 'capital-statement-preview',
  PORTFOLIO_FAIR_MARKET_VALUES: 'portfolio-fair-market-values',
  PUBLIC_FUND_PROFILE: 'public-fund-profile',
  PUBLIC_FUND_COMPANY_FUNDS: 'public_fund_company_funds',

  FILES_SUMMARY: 'files-summary',
  PORTFOLIO_HOLDINGS: 'portfolio-holdings',
  UPDATES_SUMMARY: 'updates-summary',
  FOLLOWED_HOLDINGS_SUMMARY: 'followed-holdings-summary',
} as const

export const portfolioKeys = {
  all: (filters?: PortfoliosFilters) => [QUERIES.PORTFOLIOS, filters] as const,
  allInvestments: () => [QUERIES.ALL_INVESTMENTS] as const,
  byId: (
    id: string,
    isHoldingCalculationEnabled?: boolean,
    portfolioUrlType?: string
  ) =>
    [
      QUERIES.PORTFOLIO,
      id,
      !!isHoldingCalculationEnabled,
      portfolioUrlType,
    ] as const,

  fairMarketValuesByPortfolioId: (id: string) =>
    [QUERIES.PORTFOLIO_FMVS, id] as const,

  fundPortfolioHoldings: (filters: PortfoliosFilters) =>
    [QUERIES.FUND_PORTFOLIO_HOLDINGS, filters] as const,
  fundPortfolioByInvestorId: (investorId: string) =>
    [QUERIES.FUND_PORTFOLIO_INVESTOR, investorId] as const,
  allFundPortfoliosByInvestorId: (investorId: string) =>
    [QUERIES.FUNDS_PORTFOLIO_INVESTOR, investorId] as const,
  fundPortfolioInvestorsByFundId: (id: string) =>
    [QUERIES.FUND_PORTFOLIO_INVESTORS, id] as const,
  fundPortfolioInvestorsIndexByFundId: (id: string) =>
    [QUERIES.FUND_PORTFOLIO_INVESTORS_INDEX, id] as const,

  fundPortfolioSummaryByInvestor: (
    investorId: string,
    fundScopedNumbers: boolean
  ) =>
    [
      QUERIES.FUNDS_PORTFOLIO_SUMMARY_BY_INVESTOR,
      investorId,
      fundScopedNumbers,
    ] as const,

  lastMonthUpdatesByInvestor: (investorId: string) =>
    [QUERIES.LAST_MONTH_UPDATES_BY_INVESTOR, investorId] as const,

  sharePortfolioSettings: () => [QUERIES.SHARE_PORTFOLIO_SETTINGS] as const,
  sharePortfolioSettingById: (shareSettId: string) =>
    [QUERIES.FETCH_SHARE_PORTFOLIO_SETTING_BY_ID, shareSettId] as const,

  capitalStatementForInvestor: () => [QUERIES.CAPITAL_STATEMENT] as const,
  capitalStatementForFundPortfolioByInvestorId: (investorId: string) =>
    [QUERIES.CAPITAL_STATEMENT_FUND_PORTFOLIO_INVESTOR, investorId] as const,
  capitalStatementPreviewByFundIdAndInvestorId: (
    id: string,
    investorId: string
  ) => [QUERIES.CAPITAL_STATEMENT_PREVIEW, id, investorId] as const,

  fundPortfolioPreviewByFundIdAndInvestorId: (id: string, investorId: string) =>
    [QUERIES.FUND_PORTFOLIO_PREVIEW, id, investorId] as const,

  publicFundProfileById: (fundId: string) =>
    [QUERIES.PUBLIC_FUND_PROFILE, fundId] as const,

  publicFundsByFundCompanyId: (fundCompanyId: string, searchText?: string) =>
    [
      QUERIES.PUBLIC_FUND_COMPANY_FUNDS,
      fundCompanyId,
      searchText ?? '',
    ] as const,
  fundAggregatedHoldingSummary: (groupId: string) =>
    [QUERIES.FUND_PORTFOLIO_AGGREGATED_HOLDINGS_SUMMARY, groupId] as const,
  portfolioStatus: (portfolioId: string) =>
    [QUERIES.PORTFOLIO_STATUS, portfolioId] as const,
  holdingsMetricsStatus: (assetIds: string[]) =>
    [QUERIES.HOLDING_METRICS_STATUS, assetIds.join(':')] as const,
  filesSummary: (portfolioId: string) =>
    [QUERIES.FILES_SUMMARY, portfolioId] as const,
  updatesSummary: (portfolioId: string) =>
    [QUERIES.UPDATES_SUMMARY, portfolioId] as const,
  portfolioHoldings: (portfolioId: string) =>
    [QUERIES.PORTFOLIO_HOLDINGS, portfolioId] as const,
  followedHoldingsSummary: () => [QUERIES.FOLLOWED_HOLDINGS_SUMMARY] as const,
}
