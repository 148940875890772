import React, { useCallback, useState } from 'react'

export type UseArrayStateType<T> = [
  T[],
  {
    push: (...newValues: T[]) => void
    remove: (index: number) => void
    find: (predicate: (item: T) => boolean) => T | undefined
    findAndRemove: (predicate: (item: T) => boolean) => void
    set: React.Dispatch<React.SetStateAction<T[]>>
  }
]

function useArrayState<T>(initialState: T[]): UseArrayStateType<T> {
  const [state, setState] = useState<T[]>(initialState)

  const push = useCallback((newValue: T) => {
    setState((currentState) => [...currentState, newValue])
  }, [])

  const remove = useCallback((index: number) => {
    setState((currentState) => {
      const newState = [...currentState]
      newState.splice(index, 1)
      return newState
    })
  }, [])

  const find = useCallback(
    (predicate: (item: T) => boolean) => {
      return state.find(predicate)
    },
    [state]
  )

  const findAndRemove = useCallback((predicate: (item: T) => boolean) => {
    setState((currentState) => {
      const index = currentState.findIndex(predicate)
      const newState = [...currentState]
      newState.splice(index, 1)
      return newState
    })
  }, [])

  return [state, { push, remove, find, findAndRemove, set: setState }]
}

export default useArrayState
