import { ErrorType, IError } from 'utils/types/common'

export const buildFormError = (
  error: string = '',
  type: ErrorType = ErrorType.ERROR,
  touched?: boolean
): IError => {
  let message = ''

  // if (typeof touched === 'boolean') {
  message = touched ? error : ''
  // }

  return {
    type,
    message,
  }
}
