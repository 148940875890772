import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'

export const ArrowContainer = styled.div<{
  visible: boolean
  direction: 'right' | 'left'
}>`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  pointer-events: ${(props) => (props.visible ? 'all' : 'none')};
  button {
    background-color: white;
    z-index: 2;
  }

  @media ${maxSize.xsm} {
    ${(props) =>
      props.direction === 'left' &&
      css`
        margin-left: 0.4rem;
        width: 0;
      `}
    ${(props) =>
      props.direction === 'right' &&
      css`
        margin-right: 0.4rem;
        width: 0;
        button {
          transform: translateX(-4rem);
        }
      `}
  }
`
