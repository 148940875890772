import { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
  onAddTagFromTagInput,
  onRemoveTagFromTagInput,
} from 'utils/functions/tags'
import useTags from 'utils/hooks/useTags'

export const useUpdateTags = (updateId, initialTags) => {
  const [tagsList, setTagsList] = useState([])
  const { tags: groupTags } = useTags()

  useEffect(() => {
    setTagsList(initialTags)
  }, [initialTags])

  const intl = useIntl()

  const onAddTag = (tagName) => {
    onAddTagFromTagInput({
      tagName,
      tagsList,
      groupTags,
      setTagsList,
      updateId,
      intl,
    })
  }

  const onRemoveTag = (tag) => {
    onRemoveTagFromTagInput({ tag, tagsList, setTagsList, updateId, intl })
  }

  return {
    onAddTag,
    onRemoveTag,
    tagsList,
    setTagsList,
  }
}
