import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CompanyLogo from 'components/CompanyProfileHeader/components/CompanyLogo'
import {
  CompanyInfo,
  TitleWrapper,
  Title,
  CompanyInfoGrid,
  CompanyInfoItem,
  Link,
  CompanyCardContainer,
  CompanyLogoWrapper,
} from './ClaimCompany.styles'

const ClaimCompanyCard = ({
  founded,
  industries,
  legalStructure,
  logoUrl,
  name,
  primaryLocation,
  website,
}) => {
  const intl = useIntl()

  const displayMainLocation = () => {
    if (primaryLocation) {
      return primaryLocation?.place?.formattedAddress
    }
    return intl.messages['companyProfile.noLocation']
  }

  const displayIndustries = () => {
    if (industries?.length) {
      return industries.map((industry) => industry.name).join(', ')
    }
    return intl.messages['companyProfile.noIndustries']
  }

  return (
    <CompanyCardContainer>
      <CompanyLogoWrapper>
        <CompanyLogo srcImage={logoUrl} />
      </CompanyLogoWrapper>
      <CompanyInfo>
        <TitleWrapper>
          <Title>{name}</Title>
        </TitleWrapper>
        <CompanyInfoGrid>
          <CompanyInfoItem tooltip={website}>
            <FontAwesomeIcon icon={['far', 'link']} />
            {website ? (
              <Link target="_blank" rel="noopener noreferrer" href={website}>
                {website}
              </Link>
            ) : (
              intl.messages['companyProfile.noWebsite']
            )}
          </CompanyInfoItem>

          <CompanyInfoItem tooltip={displayIndustries()}>
            <FontAwesomeIcon icon={['fas', 'th']} />
            {displayIndustries()}
          </CompanyInfoItem>

          <CompanyInfoItem tooltip={displayMainLocation()}>
            <FontAwesomeIcon icon={['far', 'map-marker-alt']} />
            {displayMainLocation()}
          </CompanyInfoItem>

          <CompanyInfoItem>
            <FontAwesomeIcon icon={['far', 'list-ul']} />
            {legalStructure?.name || (
              <FormattedMessage id="companyProfile.notApply" />
            )}
          </CompanyInfoItem>
          <CompanyInfoItem>
            <FontAwesomeIcon icon={['far', 'birthday-cake']} />
            <FormattedMessage id="companyProfile.founded" />{' '}
            {founded ? (
              dayjs(founded).format('MMMM, YYYY')
            ) : (
              <FormattedMessage id="companyProfile.unknown" />
            )}
          </CompanyInfoItem>

          <CompanyInfoItem>
            <FontAwesomeIcon icon={['far', 'users']} />
            <FormattedMessage id="companyProfile.notApply" />
          </CompanyInfoItem>
        </CompanyInfoGrid>
      </CompanyInfo>
    </CompanyCardContainer>
  )
}

ClaimCompanyCard.propTypes = {
  name: PropTypes.string,
  website: PropTypes.string,
  founded: PropTypes.string,
  logoUrl: PropTypes.string,
  primaryLocation: PropTypes.object,
  industries: PropTypes.array,
  legalStructure: PropTypes.object,
}

ClaimCompanyCard.defaultProps = {
  name: '',
  website: '',
  founded: '',
  logoUrl: '',
  primaryLocation: {},
  industries: [],
  legalStructure: {},
}

export default ClaimCompanyCard
