/* eslint-disable react-hooks/exhaustive-deps */
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'

import {
  setCurrentPortfolio as setCurrentPortfolioAction,
  setPortfolios,
} from 'actions/portfolios'
import usePortfolios from 'utils/hooks/usePortfolios'
import { getAllFundsOption } from './constants'

const useInvestorGroupDashboard = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const { portfolios } = usePortfolios()
  const ALL_FUNDS_OPTION = getAllFundsOption(intl)

  useEffect(() => {
    if (portfolios) {
      dispatch(setCurrentPortfolioAction(ALL_FUNDS_OPTION))
      dispatch(setPortfolios(portfolios))
    }
  }, [portfolios])

  return {
    intl,
  }
}

export default useInvestorGroupDashboard
