import { useCallback, useMemo } from 'react'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

interface QuarterAndYearAxisProps {
  x: number
  y: number
  index: number
  visibleTicksCount: number
  width: number
  payload: { value: string }
  className: string
  tickFormatter: (value: string) => string
  metrics: {
    quarter: number
    year: number
  }[]
  brushRange: {
    startIndex: number
    endIndex: number
  }
}

const Q2 = 2
const SECOND_QUARTER = '2'
const LAST_QUARTER = '4'
const TICKS_PER_YEAR = 4

// Magic numbers are used to adjust the position of the texts
// The numbers were set based on the design
const QuarterAndYearAxis = ({
  x,
  y,
  index,
  visibleTicksCount,
  width,
  payload,
  className,
  tickFormatter,
  metrics,
  brushRange,
}: QuarterAndYearAxisProps) => {
  const { value } = payload
  const isLast = index === visibleTicksCount - 1
  const isSecondQuarter = value === SECOND_QUARTER
  const isLastQuarter = value === LAST_QUARTER
  const emptySpace = width / visibleTicksCount
  const newX = x - 12 + emptySpace / 2
  const newY = y + 31

  const getYearWhenTheBrushRangeIsAtEndOfTheData = useCallback(
    (yearsCount: number, endYear: number) => {
      const allYearsBetweenDateRange = Array.from(
        { length: yearsCount },
        (_, i) => endYear - i
      ).reverse()

      return allYearsBetweenDateRange[Math.floor(index / TICKS_PER_YEAR)]
    },
    [index]
  )

  const getYearWhenTheBrushRangeIsAtStartOfTheData = useCallback(
    (yearsCount: number, startYear: number) => {
      const allYearsBetweenDateRange = Array.from(
        { length: yearsCount },
        (_, i) => startYear + i
      )

      return allYearsBetweenDateRange[Math.floor(index / TICKS_PER_YEAR)]
    },
    [index]
  )

  const getYearnWhenTheBrushRangeIsBetweenData = useCallback(
    (initialStartYear: number) => {
      const startQuarter = metrics[brushRange.startIndex].quarter
      const endQuarter = metrics[brushRange.endIndex].quarter
      let yearsCount = Math.ceil(visibleTicksCount / TICKS_PER_YEAR)

      // When edge quarters don't display the year, we need to adjust the years count
      if (startQuarter > Q2 && endQuarter < Q2) {
        yearsCount -= 1
      }
      // When edge quarters display the year, we need to adjust the years count
      else if (startQuarter <= Q2 && endQuarter >= Q2) {
        yearsCount += 1
      }

      const startQuarterDoesNotDisplayYear = startQuarter > Q2
      // If the start quarter does not display the year, we start the year count from the next year
      const startYear = startQuarterDoesNotDisplayYear
        ? initialStartYear + 1
        : initialStartYear

      const allYearsBetweenDateRange = Array.from(
        { length: yearsCount },
        (_, i) => startYear + i
      )

      return allYearsBetweenDateRange[Math.floor(index / TICKS_PER_YEAR)]
    },
    [
      brushRange.endIndex,
      brushRange.startIndex,
      index,
      metrics,
      visibleTicksCount,
    ]
  )

  const year = useMemo(() => {
    const currentYear = ''

    // We will display the year only for the second quarter of the year
    if (isSecondQuarter) {
      const range = brushRange ?? {
        startIndex: 0,
        endIndex: metrics.length - 1,
      }
      const startYear = Number(metrics[range.startIndex].year)
      const endYear = Number(metrics[range.endIndex].year)

      // When the brush range is at the start or end of the data, we need to calculate the year differently
      const yearsCount = Math.ceil(visibleTicksCount / TICKS_PER_YEAR - 0.5)

      if (range.endIndex === metrics.length - 1) {
        return getYearWhenTheBrushRangeIsAtEndOfTheData(yearsCount, endYear)
      }

      if (range.startIndex === 0) {
        return getYearWhenTheBrushRangeIsAtStartOfTheData(yearsCount, startYear)
      }

      return getYearnWhenTheBrushRangeIsBetweenData(startYear)
    }

    return currentYear
  }, [
    isSecondQuarter,
    brushRange,
    metrics,
    visibleTicksCount,
    getYearnWhenTheBrushRangeIsBetweenData,
    getYearWhenTheBrushRangeIsAtEndOfTheData,
    getYearWhenTheBrushRangeIsAtStartOfTheData,
  ])

  return (
    <g
      className={className}
      fill={color('lightGray')({ theme })}
      fontSize={theme.fontSizes.small}
    >
      <text x={x - 10} y={y + 11}>
        {tickFormatter(value)}
      </text>
      {isSecondQuarter && (
        <text x={newX - 3} y={newY} fontWeight="bold">
          {year}
        </text>
      )}
      {!isLast && isLastQuarter && (
        <rect
          x={newX + 9}
          y={newY - 10}
          width={2}
          height={11}
          fill={color('veryLightGray')({ theme })}
        />
      )}
    </g>
  )
}

export default QuarterAndYearAxis
