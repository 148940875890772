import { PortfolioTypes } from 'utils/constants/portfolio'
import { assertUnreachable } from 'utils/functions/utils'
import { isHoldingChannel, TopicChannel } from 'containers/Chat/types'
import { IndexPortfolio } from './portfolios'
import {
  Company,
  FundProfile,
  Holding,
  getHoldingImage,
  isCompanyHolding,
} from './company'

export enum TopicEntityType {
  ORGANIZATION = 'organization',
  COMPANY_HOLDING = 'company_holding',
  FUND_HOLDING = 'fund_holding',
  INVEST_PORTFOLIO = 'invest_portfolio',
  FUND_PORTFOLIO = 'fund_portfolio',
  DEAL_PORTFOLIO = 'deal_portfolio',
  TRACK_PORTFOLIO = 'track_portfolio',
}

export type TopicEntity = {
  id: string
  entityType: TopicEntityType
  label: string
  image?: string
  company?: Company
}

export const isPortfolioTopic = (topicEntityType?: TopicEntityType) => {
  if (!topicEntityType) return false
  return [
    TopicEntityType.FUND_PORTFOLIO,
    TopicEntityType.DEAL_PORTFOLIO,
    TopicEntityType.INVEST_PORTFOLIO,
    TopicEntityType.TRACK_PORTFOLIO,
  ].includes(topicEntityType)
}

export const isHoldingTopic = (topicEntityType?: TopicEntityType) => {
  if (!topicEntityType) return false
  return [
    TopicEntityType.COMPANY_HOLDING,
    TopicEntityType.FUND_HOLDING,
    TopicEntityType.ORGANIZATION,
  ].includes(topicEntityType)
}
export const isCompanyHoldingTopic = (topicEntityType?: TopicEntityType) => {
  if (!topicEntityType) return false
  return [
    TopicEntityType.COMPANY_HOLDING,
    TopicEntityType.ORGANIZATION,
  ].includes(topicEntityType)
}
export const isFundHoldingTopic = (topicEntityType?: TopicEntityType) => {
  if (!topicEntityType) return false
  return topicEntityType === TopicEntityType.FUND_HOLDING
}

export const getPortfolioTopicEntityType = (
  portfolioType: PortfolioTypes
): TopicEntityType => {
  switch (portfolioType) {
    case PortfolioTypes.DEAL:
      return TopicEntityType.DEAL_PORTFOLIO
    case PortfolioTypes.FUND:
      return TopicEntityType.FUND_PORTFOLIO
    case PortfolioTypes.INVEST:
      return TopicEntityType.INVEST_PORTFOLIO
    case PortfolioTypes.TRACK:
      return TopicEntityType.TRACK_PORTFOLIO
    default:
      throw assertUnreachable(portfolioType)
  }
}

export const buildPortfolioTopicEntity = (
  portfolio: IndexPortfolio
): TopicEntity => {
  return {
    id: portfolio.id,
    entityType: getPortfolioTopicEntityType(portfolio.type),
    label: portfolio.name,
  }
}

const getHoldingTopicType = (holding: Holding): TopicEntityType => {
  if (isCompanyHolding(holding)) {
    return holding.fund
      ? TopicEntityType.ORGANIZATION
      : TopicEntityType.COMPANY_HOLDING
  }

  return TopicEntityType.FUND_HOLDING
}

export const buildHoldingTopicEntity = (holding: Holding): TopicEntity => {
  return {
    id: holding.id,
    label: holding.name,
    entityType: getHoldingTopicType(holding),
    image: getHoldingImage(holding),
    company: isCompanyHolding(holding) ? holding : undefined,
  }
}

export const buildHoldingTopicEntityFromCompany = (
  company: Company
): TopicEntity => {
  return {
    id: company.id,
    label: company.name,
    entityType: company.fund
      ? TopicEntityType.ORGANIZATION
      : TopicEntityType.COMPANY_HOLDING,
    image: company.primaryLogo.smallLogo.url,
    company,
  }
}

export const buildHoldingTopicEntityFromFundProfile = (
  fundProfile: FundProfile
): TopicEntity => {
  return {
    id: fundProfile.id,
    label: fundProfile.name,
    entityType: TopicEntityType.FUND_HOLDING,
    image: fundProfile.fundCompany?.primaryLogo.smallLogo.url,
  }
}

export const buildTopicEntityFromChannel = (
  channel: TopicChannel
): TopicEntity => {
  const topic: TopicEntity = {
    id: channel.entityId,
    label: channel.topicName,
    entityType: channel.entityType,
  }

  if (isHoldingChannel(channel)) {
    topic.image = channel.imageUrl
  }

  return topic
}
