import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const Date = styled.div`
  color: ${color('lightGray')};
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  font-size: 1.4rem;
  font-weight: 700;
`
