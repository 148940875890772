import { iconWrapperDefault } from 'containers/Investments/InvestmentsPortfolioNavigation/components/NavigationItem/NavigationItem.styles'
import { InvestmentNavigationOptionType } from 'containers/Investments/types'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const BreadcrumbWrapper = styled.div<{ hideNavigation: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 2.4rem;
  background-color: ${color('white')};
  height: 5.9rem;

  width: ${(props) => (props.hideNavigation ? '100%' : 'auto')};

  > div:first-of-type {
    display: ${(props) => (props.hideNavigation ? 'none' : 'flex')};
  }
`

export const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1.3rem;
  gap: 0.6rem;
`

export const PortfolioIconWrapper = styled.div`
  align-items: center;
  background-color: ${color('primaryBlue')};
  border-radius: 50%;
  display: flex;
  height: 2.1rem;
  width: 2.1rem;
  justify-content: center;
  padding: 0.5rem;

  svg {
    color: ${color('white')};
  }
`

export const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
`

export const LinkWrapper = styled.span<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? color('darkBlue') : color('lightGray')};
  text-decoration: none;
  cursor: ${(props) => (props.isActive ? 'default' : 'pointer')};
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: ${color('lightGray')};
    font-size: 1rem;
  }
`

export const IconWrapper = styled.div<{
  optionType: InvestmentNavigationOptionType
}>`
  ${({ optionType }) => iconWrapperDefault(optionType)};
`
