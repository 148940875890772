import { useCallback } from 'react'
import { store } from 'store'
import { getIsCompanyFund, isActingAsFounder } from 'selectors/auth'
import {
  isCompanyPrivate,
  isFundCompanyOwnedByCurrentGroup,
} from 'selectors/company'
import { UpdateTypes } from 'utils/constants/updates'
import { Company } from 'utils/types/company'

import { useAppSelector } from './reduxToolkit'
import useAccountingIntegrationStatus from './useAccountingIntegrationStatus'

export const useAvailableUpdateTypes = () => {
  const isFounder = useAppSelector(isActingAsFounder)

  const { isConnected: isAccountingIntegrationConnected } =
    useAccountingIntegrationStatus()

  const getAvailableUpdateTypesByCompany = useCallback(
    (company: Company) => {
      const state = store.getState()
      const isPrivateCompany = isCompanyPrivate(company)(state)
      const fundCompanyOwnedByCurrentGroup =
        isFundCompanyOwnedByCurrentGroup(company)(state)
      const isCurrentCompanyFund = getIsCompanyFund(company)(state)

      const isFounderUpdatesVisible =
        isCurrentCompanyFund || (isFounder && !isPrivateCompany)

      const isAccountingVisible =
        isAccountingIntegrationConnected && isFounderUpdatesVisible

      const isIUEVisible = isFounderUpdatesVisible

      const isTransactionVisible = !isFounder

      const isAnnouncementVisible =
        isFounderUpdatesVisible || fundCompanyOwnedByCurrentGroup

      return {
        [UpdateTypes.IUE]: isIUEVisible,
        [UpdateTypes.ACCOUNTING]: isAccountingVisible,
        [UpdateTypes.TRANSACTION]: isTransactionVisible,
        [UpdateTypes.NOTE]: true,
        [UpdateTypes.DOCUMENT]: true,
        [UpdateTypes.ANNOUNCEMENT]: isAnnouncementVisible,
      }
    },
    [isAccountingIntegrationConnected, isFounder]
  )

  return {
    getAvailableUpdateTypesByCompany,
  }
}
