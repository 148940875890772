import React from 'react'
import { useIntl } from 'react-intl'

import Separator from 'ui/Separator'
import Input from 'ui/Input'
import URLInput from 'ui/URLInput'
import CustomTextArea from 'ui/TextArea/CustomTextArea'
import ImagePicker from 'ui/ImagePicker'
import Switch from 'components/Switch'
import Title from 'components/Title'
import { buildFormError } from 'utils/functions/forms'
import { InvestorTypes } from 'utils/constants/investorManagement'
import DateInput from 'components/TransactionUpdateContent/UpdateFormElements/DateInput'
import { ErrorType } from 'utils/types/common'
import { getInitials } from 'utils/functions/user'
import avatarPlaceholderImg from 'assets/images/avatar-placeholder.png'

import {
  FormContainer,
  SelectorWrapper,
  DisplayWrapper,
  ImageAndNameWrapper,
} from './InvestorForm.styles'
import EmailsList from '../components/Emails/EmailsList'
import LocationsList from '../components/LocationsList'
import { useAddInvestorForm } from './useAddInvestorForm'

interface AddInvestorFormProps {
  onAddEmail?: (newEmail: string) => void
}

const AddInvestorForm: React.FC<AddInvestorFormProps> = ({ onAddEmail }) => {
  const intl = useIntl()

  const {
    isGridDisplay,
    values,
    errors,
    touched,
    descriptionHeight,
    isFundManager,
    onSaveImage,
    onDeleteImage,
    setFieldValue,
    getFieldName,
    handleChange,
    handleBlur,
    handleAddEmail,
  } = useAddInvestorForm(onAddEmail)

  const renderPhoneInput = () => {
    return (
      <SelectorWrapper>
        <Title
          title={intl.formatMessage({
            id: 'investorManagement.phone',
          })}
        />
        <Separator />
        <Input
          name={getFieldName('phone')}
          type="text"
          placeholder={intl.formatMessage({
            id: 'investorManagement.addPhone',
          })}
          value={values.phone}
          onChange={handleChange}
          error={buildFormError(errors?.phone, ErrorType.ERROR, touched?.phone)}
          onBlur={handleBlur}
        />
      </SelectorWrapper>
    )
  }

  return (
    <FormContainer>
      {isFundManager && (
        <>
          <SelectorWrapper>
            <Switch
              valueId={values.type}
              onChange={(value) => setFieldValue('type', value)}
              options={[
                {
                  id: InvestorTypes.INDIVIDUAL,
                  label: intl.formatMessage({
                    id: 'investorManagement.individual',
                  }),
                },
                {
                  id: InvestorTypes.ORGANIZATION,
                  label: intl.formatMessage({
                    id: 'investorManagement.organization',
                  }),
                },
              ]}
            />
          </SelectorWrapper>
          <Separator height="1.6rem" />
        </>
      )}

      <SelectorWrapper>
        <Title
          title={intl.formatMessage({
            id:
              values.type === InvestorTypes.INDIVIDUAL
                ? 'investorManagement.name'
                : 'investorManagement.organizationName',
          })}
          subLabel={intl.formatMessage({
            id: 'common.requiredField',
          })}
        />

        <Separator />
        <ImageAndNameWrapper>
          <ImagePicker
            placeHolderImage={avatarPlaceholderImg}
            imageUrl={values.logo.imageUrl}
            onSaveImage={onSaveImage}
            initials={getInitials(values.name)}
            cropperUrl={values.logo?.cropperUrl}
            onHideModal={() =>
              setFieldValue('logo.cropperUrl', values.logo?.image?.url ?? '')
            }
            setHasRemovedImage={(hasBeenRemoved) =>
              setFieldValue('logo.hasRemovedImage', hasBeenRemoved)
            }
            onDeleteImage={onDeleteImage}
            helpHidden
            showSelectImageButton={false}
            deleteImageEnabled
            gray
            imageSize="4.7rem"
          />

          <Input
            name={getFieldName('name')}
            placeholder={intl.formatMessage({
              id:
                values.type === InvestorTypes.INDIVIDUAL
                  ? 'investorManagement.addFullName'
                  : 'investorManagement.addOrganizationName',
            })}
            value={values.name}
            onChange={handleChange}
            error={buildFormError(
              errors?.name,
              ErrorType.ERROR,
              values.name.length > 0 ? true : touched?.name
            )}
            onBlur={handleBlur}
          />
        </ImageAndNameWrapper>
      </SelectorWrapper>

      <Separator height="1.6rem" />

      <SelectorWrapper>
        <Title
          title={intl.formatMessage({
            id: 'investorManagement.website',
          })}
        />
        <Separator />
        <URLInput
          name={getFieldName('website')}
          type="text"
          placeholder={intl.formatMessage({
            id: 'investorManagement.addWebsite',
          })}
          value={values.website}
          onChange={handleChange}
          error={buildFormError(
            errors?.website,
            ErrorType.ERROR,
            touched?.website
          )}
          onBlur={handleBlur}
          icon={['fal', 'link-simple']}
        />
      </SelectorWrapper>

      <Separator height="1.6rem" />

      <SelectorWrapper>
        <Title
          title={intl.formatMessage({
            id:
              values.type === InvestorTypes.INDIVIDUAL
                ? 'investorManagement.email'
                : 'investorManagement.pointOfContact',
          })}
        />
        <Separator />
        <EmailsList<string>
          name={getFieldName('emails')}
          label={null}
          placeholder={intl.formatMessage({
            id:
              values.type === InvestorTypes.INDIVIDUAL
                ? 'investorManagement.addEmail'
                : 'investorManagement.addPointOfContact',
          })}
          handleAddEmail={handleAddEmail}
          error={errors?.emails as string}
          items={values.emails}
          getRowLabel={(item) => item}
        />
      </SelectorWrapper>

      <Separator height="1.6rem" />

      <DisplayWrapper showInGrid={isGridDisplay}>
        {values.type === InvestorTypes.ORGANIZATION && (
          <>
            <SelectorWrapper>
              <Title
                title={intl.formatMessage({
                  id: 'investorManagement.foundedDate',
                })}
              />
              <Separator />
              <DateInput
                name={getFieldName('foundedDate')}
                label={null}
                placeholder={intl.formatMessage({
                  id: 'investorManagement.selectFoundedDate',
                })}
                initialValue={null}
              />
            </SelectorWrapper>
            <Separator height="1.6rem" />
          </>
        )}
      </DisplayWrapper>

      <DisplayWrapper showInGrid={isGridDisplay}>
        {renderPhoneInput()}
      </DisplayWrapper>

      <Separator height="1.6rem" />

      <Title
        title={intl.formatMessage({
          id: 'investorManagement.description',
        })}
      />
      <Separator />
      <CustomTextArea
        name={getFieldName('description')}
        id="description"
        placeholder={intl.formatMessage({
          id: 'investorManagement.writeYourDescriptionHere',
        })}
        value={values.description}
        onChange={handleChange}
        minHeight={descriptionHeight}
      />

      <Separator height="1.6rem" />

      <SelectorWrapper>
        <LocationsList />
      </SelectorWrapper>
    </FormContainer>
  )
}

export default AddInvestorForm
