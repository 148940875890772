import { useState } from 'react'
import { useQueryChannels } from 'utils/hooks/chat/chatSearchQueries'

import ChatPopup from '../ChatPopup'
import { Wrapper } from './ChatPopupLayout.styles'
import { useChatPopupLayout } from './useChatPopupLayout'
import ChatWrapper from '../ChatWrapper'

const ChatPopupLayout = () => {
  const { channelIds } = useChatPopupLayout()
  const [channelsToRender, setChannelsToRender] = useState<any[]>([])
  useQueryChannels(channelIds, setChannelsToRender)

  return (
    <Wrapper>
      {channelsToRender?.map((channel) => (
        <ChatPopup key={channel.id} channel={channel} />
      ))}
    </Wrapper>
  )
}

export default ChatWrapper(ChatPopupLayout)
