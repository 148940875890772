import type { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PortfolioIcon from 'components/PortfolioIcon'
import Tooltip from 'components/Tooltip'
import React, { useId } from 'react'
import { UpdateTypes } from 'utils/constants/updates'
import { Color } from 'utils/theme'
import { PortfolioType } from 'utils/types/portfolios'
import { SuggestedUpdateType } from 'utils/types/common'
import { FloatingIcon, Icon, IconWrapper } from './UpdateIconFactory.styles'

type Props = {
  updateType: SuggestedUpdateType
  isDraft?: boolean
  clickable?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isSelected?: boolean
  isHover?: boolean
  logo?: string
  portfolioType?: PortfolioType
  tooltip?: string
  onClickLogo?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  isScheduled?: boolean
  showIconOnlyOnHover?: boolean
}

export const getUpdateIcon = (
  updateType: SuggestedUpdateType,
  isDraft?: boolean
) => {
  const UpdatesIconMapper: {
    [key: string]: { icon: IconName; color: Color }
  } = {
    [UpdateTypes.NOTE]: {
      icon: 'sticky-note',
      color: isDraft ? 'lightYellow' : 'yellow',
    },
    [UpdateTypes.DOCUMENT]: {
      icon: 'file',
      color: isDraft ? 'lightBlue' : 'brightBlue',
    },
    [UpdateTypes.ANNOUNCEMENT]: {
      icon: 'megaphone',
      color: isDraft ? 'lightRed' : 'red',
    },
    [UpdateTypes.ACCOUNTING]: {
      icon: 'file-invoice-dollar',
      color: isDraft ? 'lightPurple' : 'purple',
    },
    [UpdateTypes.TRANSACTION]: {
      icon: 'money-check-alt',
      color: isDraft ? 'lightOrange' : 'orange',
    },
    [UpdateTypes.IUE]: {
      icon: 'envelope',
      color: isDraft ? 'draftGray' : 'green',
    },
    suggested: {
      icon: 'question-circle',
      color: 'suggestedGray',
    },
  }

  return UpdatesIconMapper[updateType]
}

const UpdateIconFactory: React.FC<Props> = ({
  updateType,
  isDraft,
  clickable,
  onClick,
  onMouseOver,
  onMouseLeave,
  isSelected,
  isHover,
  logo,
  portfolioType,
  tooltip,
  onClickLogo,
  className,
  isScheduled,
  showIconOnlyOnHover,
}) => {
  const id = useId()
  const { icon, color } = getUpdateIcon(updateType, isDraft)
  const scheduledColor: Color = isDraft ? 'lightDraftGray' : 'draftGray'
  const iconColor = isScheduled ? scheduledColor : color

  if (portfolioType) {
    return (
      <Tooltip id={`portfolio_${id}`} text={tooltip} place="bottom">
        <IconWrapper data-testid="portfolio-update-icon" onClick={onClickLogo}>
          <PortfolioIcon type={portfolioType} />
          <FloatingIcon color={iconColor}>
            <FontAwesomeIcon icon={['far', icon]} />
          </FloatingIcon>
        </IconWrapper>
      </Tooltip>
    )
  }

  if (logo) {
    return (
      <Tooltip id={`logo_${id}`} text={tooltip} place="bottom">
        <IconWrapper
          data-testid="logo-update-icon"
          onClick={onClickLogo}
          logo={logo}
        >
          <FloatingIcon color={iconColor}>
            <FontAwesomeIcon icon={['far', icon]} />
          </FloatingIcon>
        </IconWrapper>
      </Tooltip>
    )
  }

  return (
    <Tooltip id={`icon_${id}`} text={tooltip} place="bottom">
      <Icon
        data-testid="update-icon"
        color={iconColor}
        as={clickable ? 'button' : 'div'}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        hoverState={isHover}
        clickable={clickable}
        selectedState={clickable && isSelected}
        className={className}
        showIconOnlyOnHover={showIconOnlyOnHover}
      >
        <FontAwesomeIcon
          icon={['far', clickable && (isHover || isSelected) ? 'check' : icon]}
        />
      </Icon>
    </Tooltip>
  )
}

export default UpdateIconFactory
