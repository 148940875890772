import { AccountingReportType } from 'utils/constants/accounting'

export const getReportTypeLabel = (reportType: AccountingReportType) => {
  switch (reportType) {
    case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
    case AccountingReportType.XERO_BALANCE_SHEET:
      return 'Balance Sheet'
    case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
    case AccountingReportType.XERO_PROFIT_AND_LOSS:
      return 'Profit and Loss'
    default:
      return ''
  }
}
