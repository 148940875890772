import React, { createContext, useMemo } from 'react'
import AddHoldingModal from 'components/AddHoldingModal/AddHoldingModal'
import { Nullable } from 'utils/types/common'
import { CreatePortfolioHoldingHandler } from './useCreatePortfolioHolding'

export type ExistentHoldingContextType = Nullable<{
  addHoldingsToPortfolio: (holdingIds: string[]) => void
}>

export const ExistentHoldingContext =
  createContext<ExistentHoldingContextType>(null)

interface CreatePortfolioHoldingProps {
  createPortfolioHoldingHandler: CreatePortfolioHoldingHandler
  addHoldingsToPortfolio: (holdingIds: string[]) => void
}

const CreatePortfolioHolding: React.FC<CreatePortfolioHoldingProps> = ({
  createPortfolioHoldingHandler,
  addHoldingsToPortfolio,
}) => {
  const existentHoldingContext = useMemo(
    () => ({
      addHoldingsToPortfolio,
    }),
    [addHoldingsToPortfolio]
  )

  return (
    <ExistentHoldingContext.Provider value={existentHoldingContext}>
      <AddHoldingModal
        onHideModal={createPortfolioHoldingHandler.closeCreateHoldingModal}
        isModalOpen={createPortfolioHoldingHandler.isOpen}
        onCreateNewHoldings={addHoldingsToPortfolio}
        name={createPortfolioHoldingHandler.holdingName}
      />
    </ExistentHoldingContext.Provider>
  )
}

export default CreatePortfolioHolding
