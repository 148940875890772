import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Container,
  FileName,
  ValuesImportedMessage,
  Separator,
} from './FileBanner.styles'

interface Props {
  children: React.ReactNode
  fileName?: string
  className?: string
}

const FileBanner: React.FC<Props> = ({
  children,
  fileName,
  className = '',
}: Props) => {
  return (
    <Container className={className}>
      <FontAwesomeIcon icon={['fal', 'gauge']} />
      {fileName && <FileName>{fileName}</FileName>}
      <ValuesImportedMessage>
        <Separator>·</Separator> {children}
      </ValuesImportedMessage>
    </Container>
  )
}

export default FileBanner
