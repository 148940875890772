import styled from 'styled-components'
import ButtonToStyle from 'ui/Button'

export const Button = styled(ButtonToStyle)`
  width: auto;
  cursor: pointer;
  position: absolute;
  right: 2rem;
  top: 1rem;
  z-index: 10;
  border: none;
  font-size: 1.4rem;
`
