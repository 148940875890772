import React from 'react'

import { item } from 'utils/animations/feed'
import { MixedUpdate } from 'utils/types/update'

import { SkeletonItem } from 'components/Skeletons/Skeletons.styles'
import SkeletonUpdateCard from 'components/Skeletons/Updates/SkeletonUpdateCard/SkeletonUpdateCard'
import FeedCard from '../FeedCard/FeedCard'
import * as Styles from './UpdatesForDate.styles'

type UpdatesForDateProps = {
  label?: string
  mixedUpdates?: MixedUpdate[]
  isLoading: boolean
}

const UpdatesForDate: React.FC<UpdatesForDateProps> = ({
  label,
  mixedUpdates,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <Styles.Container>
        <SkeletonItem width="8rem" height="1rem" />
        <Styles.List>
          <Styles.ListItem>
            <SkeletonUpdateCard />
          </Styles.ListItem>
        </Styles.List>
      </Styles.Container>
    )
  }

  if (!mixedUpdates?.length) {
    return null
  }

  return (
    <Styles.Container>
      <Styles.DateLabel key={label} variants={item}>
        {label}
      </Styles.DateLabel>
      <Styles.List>
        {mixedUpdates.map((mixedUpdate, index) => (
          <Styles.ListItem key={mixedUpdate.id} variants={item}>
            {index < mixedUpdates.length - 1 && <Styles.LineDash />}
            <FeedCard mixedUpdate={mixedUpdate} />
          </Styles.ListItem>
        ))}
      </Styles.List>
    </Styles.Container>
  )
}

export default UpdatesForDate
