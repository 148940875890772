import { useMemo } from 'react'
import { Channel } from 'stream-chat'
import { useTopic } from '../components/TopicSidebar/useTopic'
import { ChannelType, StreamChatType, TopicChannel } from '../types'

export const useTopicUnreadMessagesCount = (
  channel: Channel<StreamChatType>
) => {
  const { channels: topicChannels } = useTopic(
    channel as Channel<StreamChatType<TopicChannel>>
  )

  const isDirectMessage = channel.type === ChannelType.DIRECT_MESSAGE

  const unreadMessagesCount = useMemo(() => {
    if (isDirectMessage) return channel.countUnread()

    return topicChannels.reduce(
      (res, currChannel) => res + currChannel.countUnread(),
      0
    )
  }, [channel, topicChannels, isDirectMessage])

  return unreadMessagesCount
}
