/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { ReactChildren } from 'utils/types/common'

export interface TabRouteContentProps {
  path: string
  children?: ReactChildren
  setActiveTab: () => void
}

const TabRouteContent: React.FC<TabRouteContentProps> = ({
  path,
  children,
  setActiveTab,
}) => {
  const matches = useRouteMatch(path)

  useEffect(() => {
    if (matches) {
      setActiveTab()
    }
  }, [matches])

  return <Route path={path}>{children}</Route>
}

export default TabRouteContent
