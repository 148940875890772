import styled from 'styled-components'

export const TitleWrapper = styled.div`
  height: 3.2rem;
  margin-bottom: 0.8rem;
  display: flex;
  align-items: center;
`

export const ShowMetricMilestoneWrapper = styled.div`
  margin: 0 2.6rem 2.4rem 2.6rem;
`

export const MilestoneWrapper = styled.div`
  margin-bottom: 0.8rem;
`

export const MilestoneZeroStateWrapper = styled.div`
  margin: 0 2.6rem 2.4rem 2.6rem;
`
