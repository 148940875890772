import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Tooltip from 'components/Tooltip'
import {
  ClickHere,
  CloneIcon,
  GeneratedEmail,
  GeneratedEmailContainer,
  InfoContainer,
} from '../CreateInbound/CreateInbound.styles'

interface GeneratedInboundEmailProps {
  email: string
  className?: string
  connectStepInfo?: string
  showInfoIcon?: boolean
}

const GeneratedInboundEmail: React.FC<GeneratedInboundEmailProps> = ({
  email,
  className,
  connectStepInfo,
  showInfoIcon = false,
}) => {
  const [isEmailCopied, setIsEmailCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email)
    setIsEmailCopied(true)
  }

  return (
    <div className={className}>
      <GeneratedEmailContainer>
        <GeneratedEmail onClick={copyToClipboard}>{email}</GeneratedEmail>
        <Tooltip
          id="link-copied"
          text={<FormattedMessage id="inbounds.linkCopied" />}
          place="top"
          isOpen={isEmailCopied}
        >
          <CloneIcon
            onMouseLeave={() => setIsEmailCopied(false)}
            onClick={copyToClipboard}
            icon={['far', 'clone']}
          />
        </Tooltip>
      </GeneratedEmailContainer>
      <InfoContainer>
        {showInfoIcon && <FontAwesomeIcon icon={['far', 'info-circle']} />}
        {connectStepInfo || (
          <FormattedMessage
            id="inbounds.connectStepInfo"
            values={{
              link: (msg) => <ClickHere>{msg}</ClickHere>,
            }}
          />
        )}
      </InfoContainer>
    </div>
  )
}

export default GeneratedInboundEmail
