import React, {
  useEffect,
  useCallback,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import type { Channel } from 'stream-chat'
import { useChatContext, useMessageNewListener } from 'stream-chat-react'

import {
  ChannelType,
  isTopicChannel,
  StreamChatType,
  TopicChannel,
} from 'containers/Chat/types'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useForceUpdate } from 'utils/hooks/useForceUpdate'
import { useEventListener } from 'utils/hooks/useEventListener'
import { getUser, isActingAsFundManager } from 'selectors/auth'
import {
  useRemoveChannelMemberListener,
  useAddTopicChannel,
  useSetActiveChannel,
} from 'containers/Chat/hooks'
import { getPinById, getTopicUrl } from 'containers/Chat/helpers'
import { useMessageRemovedListener } from 'containers/Chat/hooks/useMessageRemovedListener'
import { useMessageEditedListener } from 'containers/Chat/hooks/useMessageEditedListener'
import {
  ARCHIVE_CHANNEL_EVENT,
  useArchiveChannels,
} from 'containers/Chat/hooks/useArchiveChannels'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import { TopicEntityType } from 'utils/types/chatTopicEntity'

export const useTopic = (
  topic?: Channel<StreamChatType<TopicChannel>>,
  preventSubscribeArchiveChannelEvent?: boolean
) => {
  const user = useAppSelector(getUser)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const groupCompany = useGroupCompany()
  const { isChannelArchived } = useArchiveChannels()
  const [showCreateChatModal, setShowCreateChatModal] = useState(false)

  const { client, channelsQueryState } =
    useChatContext<StreamChatType<TopicChannel>>()
  const setActiveChannel = useSetActiveChannel<TopicChannel>()

  const [channels, setChannels] = React.useState<
    Channel<StreamChatType<TopicChannel>>[]
  >([])
  const isArchived = isChannelArchived(topic?.id)
  const isAChannel =
    !isArchived &&
    topic &&
    topic.type !== ChannelType.DIRECT_MESSAGE &&
    topic.type !== ChannelType.CHATBOT
  const isPinned = useMemo(
    () =>
      topic &&
      !!getPinById(topic.data?.entityId!, client.user?.pinnedTopics || []),
    [topic, client.user?.pinnedTopics]
  )
  const forceUpdate = useForceUpdate()

  const onMessagesChanged = (
    callback: SetStateAction<Channel<StreamChatType<TopicChannel>>[]>
  ) => {
    setChannels(callback)
    forceUpdate()
  }

  const onRemoveChannelMember = useCallback(
    (newChannels: Channel<StreamChatType<TopicChannel>>[]) => {
      const nextActiveChannel = newChannels.find(
        (channel) =>
          channel.data?.entityId === topic?.data?.entityId &&
          channel.state.members[user.id]
      )

      if (nextActiveChannel) {
        setActiveChannel(nextActiveChannel)
      } else {
        const activeChannels = Object.values(client.activeChannels).filter(
          (channel) =>
            channel.cid !== topic?.cid && channel.state.members[user.id]
        )
        setActiveChannel(activeChannels[0])
      }
    },
    [
      topic?.cid,
      topic?.data?.entityId,
      client.activeChannels,
      setActiveChannel,
      user.id,
    ]
  )

  useMessageNewListener(onMessagesChanged, false, false)
  useMessageRemovedListener(onMessagesChanged)
  useMessageEditedListener(onMessagesChanged)
  useAddTopicChannel(setChannels, topic?.data?.entityId)
  useRemoveChannelMemberListener(setChannels, onRemoveChannelMember)

  useEffect(() => {
    const newActiveChannels = Object.values(client.activeChannels).filter(
      (channel) => {
        const isTopic = isTopicChannel(channel.data)
        const isEntity = channel?.data?.entityId === topic?.data?.entityId
        const notArchived = !isChannelArchived(channel.id)

        return isTopic && isEntity && notArchived
      }
    )

    setChannels(newActiveChannels)
  }, [
    channelsQueryState.queryInProgress,
    topic?.data?.entityId,
    client.activeChannels,
    isChannelArchived,
  ])

  if (preventSubscribeArchiveChannelEvent) {
    // TODO Refactor useTopic hook
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEventListener(ARCHIVE_CHANNEL_EVENT, ({ channelId }) => {
      setChannels((currentChannels) => {
        const newChannels = currentChannels.filter(
          (currentChannel) => currentChannel.id !== channelId
        )
        setActiveChannel(newChannels[0])
        return newChannels
      })
    })
  }

  const topicUrl = useMemo(() => {
    if (!topic || !isTopicChannel(topic.data)) return ''

    const isMyOrganization =
      isFundManager &&
      topic.data?.entityType === TopicEntityType.ORGANIZATION &&
      topic.data?.entityId === groupCompany?.id

    return getTopicUrl(topic, isMyOrganization)
  }, [topic, groupCompany?.id, isFundManager])

  return {
    isAChannel,
    channels: channels.filter((channel) => !!channel.state.members[user.id]),
    isPinned,
    topicUrl,
    createChatModal: {
      show: showCreateChatModal,
      onHide: () => setShowCreateChatModal(false),
      onShow: () => setShowCreateChatModal(true),
    },
  }
}
