import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import Modal from 'components/Modal'
import Button from 'ui/Button'
import { color } from 'utils/functions/colors'
import { Spacer } from 'containers/Signup/Signup.styles'

const StyledModal = styled(Modal)`
  width: 46rem;
`
const StyledModalFooter = styled(Modal.Footer)`
  border-top: 0.1rem solid #dfe6ed;
  justify-content: flex-end;
  gap: 0.8rem;
`

const ReshareConfirmation = styled.p`
  font-size: 1.8rem;
  line-height: 2.2rem;
`

const ReshareConfirmationDesc = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: ${color('darkGray')};
`

const ConfirmReshareModal = ({ isOpen, onHide, onConfirm }) => {
  const intl = useIntl()
  return (
    <StyledModal show={isOpen} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.Title>
          <h2>{intl.messages['updates.reshareConfidentialUpdate']}</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ReshareConfirmation>
          {intl.messages['updates.reshareConfidentialUpdateConfirmation']}
        </ReshareConfirmation>
        <Spacer margin=".8rem" />
        <ReshareConfirmationDesc>
          {intl.messages['updates.reshareConfidentialUpdateDesc']}
        </ReshareConfirmationDesc>
      </Modal.Body>

      <StyledModalFooter>
        <Button onClick={onHide}>{intl.messages['general.cancel']}</Button>
        <Button autoWidth primary blue onClick={onConfirm}>
          {intl.messages['updates.confirmReshare']}
        </Button>
      </StyledModalFooter>
    </StyledModal>
  )
}

ConfirmReshareModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}
export default ConfirmReshareModal
