import React, { useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { isEnterCode } from 'utils/functions/keyboardEvents'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import Input from 'ui/Input'
import InputIcon from 'ui/InputIcon'

import styles from './AddInput.module.scss'

const AddInput = React.forwardRef(
  ({ error, name, handleAdd, placeholder, ...rest }, ref) => {
    const intl = useIntl()
    const [value, setValue] = useState('')
    const [errorState, setErrorState] = useState({
      error: false,
      type: '',
      message: '',
    })

    const add = useCallback(() => {
      if (errorState.error) return

      if (value) {
        handleAdd(value)
        setValue('')
      }
    }, [errorState.error, handleAdd, value])

    const clickEvent = useCallback(
      (event) => {
        if (isEnterCode(event) && value) {
          add()
          event.preventDefault()
        }
      },
      [add, value]
    )

    useEffect(() => {
      const input = document.getElementById('add-input')
      input.addEventListener('keydown', clickEvent)
      return () => {
        input.removeEventListener('keydown', clickEvent)
      }
    }, [clickEvent])

    useEffect(() => {
      if (value.length > MAX_COUNTER_CHARACTERS) {
        setErrorState({
          error: true,
          message: intl.formatMessage(
            { id: 'general.youHaveExceededCharacterLimit' },
            { limit: MAX_COUNTER_CHARACTERS }
          ),
          type: 'error',
        })
      } else {
        setErrorState({
          error: false,
          message: '',
          type: '',
        })
      }
    }, [error, intl, value.length])

    return (
      <div className={styles.inputWrapper}>
        <Input
          id="add-input"
          value={value}
          ref={ref}
          name={name}
          placeholder={placeholder}
          icon={['far', 'plus']}
          onChange={({ target: { value: data } }) => setValue(data)}
          error={errorState}
          iconFontSize="1.8rem"
          onPressEnter={add}
          {...rest}
        />
      </div>
    )
  }
)

AddInput.displayName = 'AddInput'

AddInput.defaultProps = {
  error: null,
  handleAdd: () => {},
  placeholder: '',
}

AddInput.propTypes = {
  error: PropTypes.object,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleAdd: PropTypes.func,
}

export default AddInput
