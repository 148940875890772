import React from 'react'
import { FormattedMessage } from 'react-intl'

import CreateChatModal from 'containers/Chat/components/CreateChat/CreateChatModal'
import { TopicEntity } from 'utils/types/chatTopicEntity'
import { Update } from 'utils/types/update'
import SuggestedMessageBox from '../SuggestedMessageBox'
import { useCreateNewChatSuggestion } from './useCreateNewChatSuggestion'

interface CreateNewChatSuggestionBoxProps {
  topicEntity?: TopicEntity
  update?: Update
  textIntlKey?: string
}

const CreateNewChatSuggestionBox: React.FC<CreateNewChatSuggestionBoxProps> = ({
  topicEntity,
  update,
  textIntlKey,
}) => {
  const {
    onClose,
    onCreateNewChat,
    shouldHide,
    showCreateChatModal,
    onHideCreateChatModal,
    members,
    messageText,
    dismissSuggestion,
  } = useCreateNewChatSuggestion(topicEntity)

  if (shouldHide) {
    return null
  }

  return (
    <>
      <SuggestedMessageBox
        text={
          <FormattedMessage
            id={textIntlKey || 'chat.startNewDiscussion'}
            values={{ topic: topicEntity!.label }}
          />
        }
        onConfirm={onCreateNewChat}
        onCancel={onClose}
        confirmLabelIntlKey="chat.createNewDiscussion"
        cancelLabelIntlKey="chat.leaveItHere"
      />

      <CreateChatModal
        show={showCreateChatModal}
        onHide={onHideCreateChatModal}
        initialValues={{
          members,
          topic: topicEntity,
          update,
        }}
        initialMessage={messageText}
        onChatCreated={dismissSuggestion}
      />
    </>
  )
}

export default CreateNewChatSuggestionBox
