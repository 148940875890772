/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-use-before-define */

import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'

import { ReactComponent as LinkedinIcon } from 'assets/images/linkedin-in-brands.svg'
import CompanyService from 'api/CompanyService'
import EmployeeService from 'api/EmployeeService'
import { getTeamMemberFormSchema } from 'utils/schemas/teamMembers'
import Toast from 'components/Toast'
import Modal from 'components/Modal'
import Input from 'ui/Input'
import Button from 'ui/Button'
import URLInput from 'ui/URLInput'
import { buildFormError } from 'utils/functions/forms'
import Checkbox from 'components/Checkbox'

import {
  CheckboxWrapper,
  FormRow,
  ModalFooter,
  ModalWrapper,
  InputIcon,
} from './TeamMemberForm.styles'

const TeamMemberForm = ({
  show,
  onHide,
  employee,
  edit,
  companyId,
  onAddNewTeamMember,
  onEditTeamMember,
}) => {
  const intl = useIntl()

  const formik = useFormik({
    validationSchema: getTeamMemberFormSchema(intl.messages),
    initialValues: edit
      ? employee
      : {
          firstName: '',
          lastName: '',
          email: '',
          title: '',
          linkedinUrl: '',
          founder: false,
        },
    onSubmit: (val) => onSubmit(val),
  })

  useEffect(() => {
    if (employee) {
      formik.setValues(employee)
    }
  }, [employee])

  const closeModal = () => {
    onHide()
    setTimeout(() => {
      formik.resetForm()
    }, 0)
  }
  const onSubmit = async (employeeData) => {
    if (edit) {
      await onEditTeamMember(employee.id, employeeData)
    } else {
      await onAddNewTeamMember(employeeData)
    }
    closeModal()
  }

  return (
    <ModalWrapper show={show} onHide={closeModal}>
      <Modal.Header>
        {edit ? (
          <FormattedMessage id="editCompany.editMembers" />
        ) : (
          <FormattedMessage id="editCompany.addNewMembers" />
        )}
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.submitForm}>
          <FormRow>
            <Input
              name="firstName"
              label={intl.formatMessage({ id: 'editCompany.firstName' })}
              subLabel={intl.formatMessage({
                id: 'common.requiredField',
              })}
              placeholder={intl.formatMessage({
                id: 'editCompany.firstNamePlaceholder',
              })}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={buildFormError(
                formik.errors.firstName,
                'error',
                formik.touched.firstName
              )}
              inputCustomStyles={{ marginRight: '0.4rem' }}
            />
            <Input
              name="lastName"
              label={intl.formatMessage({ id: 'editCompany.lastName' })}
              subLabel={intl.formatMessage({
                id: 'common.requiredField',
              })}
              placeholder={intl.formatMessage({
                id: 'editCompany.lastNamePlaceholder',
              })}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={buildFormError(
                formik.errors.lastName,
                'error',
                formik.touched.lastName
              )}
              inputCustomStyles={{ marginRight: '0.4rem' }}
            />
            <Input
              name="title"
              label={intl.formatMessage({ id: 'editCompany.title' })}
              placeholder={intl.formatMessage({
                id: 'editCompany.titlePlaceholder',
              })}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={buildFormError(
                formik.errors.title,
                'error',
                formik.touched.title
              )}
            />
          </FormRow>
          <FormRow>
            <Input
              name="email"
              type="email"
              label={intl.formatMessage({ id: 'editCompany.email' })}
              placeholder={intl.formatMessage({
                id: 'editCompany.emailPlaceholder',
              })}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={buildFormError(
                formik.errors.email,
                'error',
                formik.touched.email
              )}
            />

            <URLInput
              name="linkedinUrl"
              label={intl.formatMessage({ id: 'editCompany.linkedin' })}
              placeholder={intl.formatMessage({
                id: 'editCompany.companyLinkedinPlaceholder',
              })}
              value={formik.values.linkedinUrl}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={buildFormError(
                formik.errors.linkedinUrl,
                'error',
                formik.touched.linkedinUrl
              )}
              icon={['fab', 'linkedin-in']}
            />
          </FormRow>

          <CheckboxWrapper>
            <Checkbox
              name="founder"
              id="founder"
              label={intl.formatMessage({ id: 'editCompany.founder' })}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              checked={formik.values.founder}
            />
          </CheckboxWrapper>
        </form>
        <ModalFooter>
          <Button onClick={closeModal} type="button">
            <FormattedMessage id="companyProfile.cancel" />
          </Button>
          <Button primary type="button" onClick={formik.submitForm}>
            <FormattedMessage
              id={edit ? 'common.save' : 'companyProfile.addMember'}
            />
          </Button>
        </ModalFooter>
      </Modal.Body>
    </ModalWrapper>
  )
}

TeamMemberForm.propTypes = {
  show: PropTypes.bool,
  employee: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  edit: PropTypes.bool.isRequired,
  companyId: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onAddNewTeamMember: PropTypes.func,
  onEditTeamMember: PropTypes.func,
}

TeamMemberForm.defaultProps = {
  show: false,
  employee: null,
  companyId: '',
  onAddNewTeamMember: () => {},
  onEditTeamMember: () => {},
}

export default TeamMemberForm
