import { motion } from 'framer-motion'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const TeamMembersList = styled(motion.ul)`
  border: 0.1rem solid #e9e9e9;
  border-radius: 0.8rem;
  overflow: hidden;

  width: 100%;
  overflow: auto;

  li:not(:last-child) > div {
    border-bottom: 0.1rem solid #e9e9e9;
  }
`

export const TeamMembersTableWrapper = styled.div``
export const TeamMembersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    height: 3.7rem;
    color: ${color('primaryDarkBlue')};
  }
`

export const ZeroStateWrapper = styled.div`
  padding: 2.4rem;
  border: 1px solid ${color('veryLightGray')};
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  p {
    font-weight: 400;
    font-size: 16px;
    color: ${color('lightGray')};
  }
  button {
    text-decoration: underline;
  }
`

export const AddPeople = styled.a`
  color: ${color('primaryBlue')};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
`
