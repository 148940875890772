import React from 'react'
import { Label, Optional } from './InputLabel.styles'

interface Props {
  className?: string
  children: React.ReactNode
  isOptional?: boolean
  uppercase?: boolean
  large?: boolean
  optionalLabel?: string
  marginLeft?: string
  customStyles?: React.CSSProperties
}

const InputLabel = ({
  className = '',
  children,
  isOptional = false,
  optionalLabel = '',
  customStyles,
  large = false,
  marginLeft = '',
  uppercase = false,
}: Props) => {
  return (
    <Label
      className={className}
      large={large}
      marginLeft={marginLeft}
      uppercase={uppercase}
      style={customStyles}
    >
      {children}
      {isOptional && <Optional large={large}>{optionalLabel}</Optional>}
    </Label>
  )
}

export default InputLabel
