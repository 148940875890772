import { Industry, Sector } from 'utils/types/company'
import { IndexPortfolio, PortfolioCompany } from 'utils/types/portfolios'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import theme from 'utils/theme'
import {
  Holding,
  InvestmentsFilters,
  InvestmentsFiltersObject,
  NormalizedPortfolio,
} from './types'

export const portfolioFilterApplied = (
  filters: InvestmentsFilters
): boolean => {
  return filters.portfolios.some((portfolio) => portfolio.isSelected)
}
export const tagsFilterApplied = (filters: InvestmentsFilters): boolean => {
  return filters.groupTags.some((groupTag) => groupTag.isSelected)
}
export const holdingsFilterApplied = (filters: InvestmentsFilters): boolean => {
  return filters.holdings.length > 0
}
export const sectorFilterApplied = (filters: InvestmentsFilters): boolean => {
  return filters.sectors.length > 0
}
export const industryFilterApplied = (filters: InvestmentsFilters): boolean => {
  return filters.industries.length > 0
}

export const normalizePortfolio = (
  portfolio: IndexPortfolio<PortfolioCompany>
): NormalizedPortfolio => ({
  label: portfolio.name,
  type: portfolio.type,
  id: portfolio.id,
  icon: getPortfolioIconByType(portfolio.type, 'sm', theme.colors.white),
  isSelected: false,
})

export const updateFiltersForPortfolio = ({
  prevFilters,
  portfolio,
  isSelected,
}: {
  prevFilters: InvestmentsFilters
  portfolio: { id: string; isSelected: boolean }
  isSelected: boolean
}) => ({
  ...prevFilters,
  portfolios: prevFilters.portfolios.map((prevPortfolio) =>
    prevPortfolio.id === portfolio.id
      ? { ...prevPortfolio, isSelected }
      : prevPortfolio
  ),
})

export const updateFiltersForTag = ({
  prevFilters,
  tag,
  isSelected,
}: {
  prevFilters: InvestmentsFilters
  tag: { id: string; isSelected: boolean }
  isSelected: boolean
}) => ({
  ...prevFilters,
  groupTags: prevFilters.groupTags.map((prevTag) =>
    prevTag.id === tag.id ? { ...prevTag, isSelected } : prevTag
  ),
})

export const updateFiltersForHolding = ({
  prevFilters,
  company,
  isAdding,
}: {
  prevFilters: InvestmentsFilters
  company: Holding
  isAdding: boolean
}) => {
  const foundCompany = prevFilters.holdings.find(
    (holding) => holding.id === company.id
  )

  if (!foundCompany && isAdding) {
    return {
      ...prevFilters,
      holdings: [...prevFilters.holdings, company],
    }
  }

  if (foundCompany && !isAdding) {
    return {
      ...prevFilters,
      holdings: prevFilters.holdings.filter(
        (holding) => holding.id !== company.id
      ),
    }
  }

  return prevFilters
}

export const updateSectorsAndIndustriesFiltersWhenSelectingSector = ({
  newSector,
  filters,
  setFiltersFunction,
  getIndustries,
}: {
  newSector: Sector
  filters: InvestmentsFilters
  setFiltersFunction: React.Dispatch<React.SetStateAction<InvestmentsFilters>>
  getIndustries: (sectorId: string) => Industry[]
}) => {
  const valueIsAlreadySelected = filters.sectors.find(
    (sector) => sector.id === newSector.id
  )
  const listOfSectors = valueIsAlreadySelected
    ? filters.sectors.filter((sector) => sector.id !== newSector.id)
    : [...(filters.sectors || []), newSector]

  const industriesOfSelectedSector = getIndustries(newSector.id).map(
    (industry) => industry
  )

  const listOfIndustries = valueIsAlreadySelected
    ? filters.industries.filter(
        (industry) =>
          !industriesOfSelectedSector.map((ind) => ind.id).includes(industry.id)
      )
    : [
        ...(filters.industries || []),
        ...getIndustries(newSector.id).map((industry) => industry),
      ]

  setFiltersFunction((prevFilters) => ({
    ...prevFilters,
    sectors: listOfSectors,
    industries: listOfIndustries.filter(
      (industry, index, self) =>
        index === self.findIndex((ind) => ind.id === industry.id)
    ),
  }))
}

export const updateSectorsAndIndustriesFiltersWhenSelectingIndustry = ({
  industry,
  sector,
  filters,
  setFiltersFunction,
  getAllIndustriesForSectorSelected,
}: {
  industry: Industry
  sector: Sector
  filters: InvestmentsFilters
  setFiltersFunction: React.Dispatch<React.SetStateAction<InvestmentsFilters>>
  getAllIndustriesForSectorSelected: (
    sectorId: string,
    industries: Industry[]
  ) => boolean
}) => {
  const valueIsAlreadySelected = filters.industries.find(
    (ind) => ind.id === industry.id
  )
  const newListOfIndustries = valueIsAlreadySelected
    ? filters.industries.filter((ind) => ind.id !== industry.id)
    : [...(filters.industries || []), industry]

  const allIndustriesOfSectorAreSelected = getAllIndustriesForSectorSelected(
    sector.id,
    newListOfIndustries
  )

  const newListOfSectors = allIndustriesOfSectorAreSelected
    ? [...(filters.sectors || []), sector]
    : filters.sectors.filter((sec) => sec.id !== sector.id)

  setFiltersFunction((prevFilters) => ({
    ...prevFilters,
    sectors: newListOfSectors,
    industries: newListOfIndustries,
  }))
}

export const updateIndustriesToShow = ({
  filters,
  setIndustriesToShowFunction,
}: {
  filters: InvestmentsFilters
  setIndustriesToShowFunction: React.Dispatch<React.SetStateAction<Industry[]>>
}) => {
  const sectorsInFilters = filters.sectors.map((sector) => sector)
  const industriesInFilters = filters.industries.map((industry) => industry)

  const industriesArr: Industry[] = []

  industriesInFilters.forEach((industry) => {
    const sectorOfIndustryIsInFilters = sectorsInFilters?.find(
      (sector) => sector.id === industry.sectorId
    )

    if (!sectorOfIndustryIsInFilters) {
      industriesArr.push(industry)
    }
  })

  setIndustriesToShowFunction(industriesArr)
}

export const updateFiltersObject = ({
  industries,
  sectors,
  portfolios,
  holdings,
  groupTags,
}: InvestmentsFilters): InvestmentsFiltersObject => {
  const selectedPortfolios = portfolios.filter(
    (portfolio) => portfolio.isSelected
  )
  const selectedGroupTags = groupTags.filter((groupTag) => groupTag.isSelected)

  return {
    industries: industries.map((industry) => industry.id),
    sectors: sectors.map((sector) => sector.id),
    portfolios: selectedPortfolios.map((portfolio) => portfolio.id),
    holdings: holdings.map((holding) => holding.id),
    groupTags: selectedGroupTags.map((groupTag) => groupTag.id),
  }
}

export const entityHasChanged = (
  prevFilters: InvestmentsFilters,
  newFilters: InvestmentsFilters
): boolean => {
  const prevFiltersObject = updateFiltersObject(prevFilters)
  const newFiltersObject = updateFiltersObject(newFilters)

  const prevFiltersObjectKeys = Object.keys(prevFiltersObject)
  const newFiltersObjectKeys = Object.keys(newFiltersObject)

  if (prevFiltersObjectKeys.length !== newFiltersObjectKeys.length) {
    return true
  }

  return prevFiltersObjectKeys.some((key) => {
    const prevFiltersObjectKey: string[] = prevFiltersObject[key]
    const newFiltersObjectKey: string[] = newFiltersObject[key]

    return (
      prevFiltersObjectKey.length !== newFiltersObjectKey.length ||
      prevFiltersObjectKey.some((value) => !newFiltersObjectKey.includes(value))
    )
  })
}

export const getNumKeys = (filtersObject: InvestmentsFiltersObject) => {
  return Object.keys(filtersObject).reduce((acc, key) => {
    if (filtersObject[key] && filtersObject[key].length > 0) {
      return acc + 1
    }
    return acc
  }, 0)
}
