import { useState } from 'react'
import { useIntl } from 'react-intl'

import Button from 'ui/Button/Button'
import { Nullable } from 'utils/types/common'
import { FileContent } from 'utils/types/files'
import { MAX_UPLOAD_SIZE_ALLOWED } from 'utils/constants/files'

import { useFilePicker } from '../../utils/hooks/files/useFilePicker'
import ModalBrowseFiles from './components/ModalBrowseFiles'
import {
  ButtonContainer,
  DropFiles,
  DropFilesText,
  FileErrorText,
  FileUploaderContainer,
  FileUploaderInput,
  MaxFileText,
} from './FileUploader.styles'

interface Props {
  accept?: string
  allowMultipleFiles: boolean
  disableClick?: boolean
  maxFileSize?: number
  fileHandler: ({
    acceptedFiles,
    rejectedFiles,
  }: {
    acceptedFiles: File[]
    rejectedFiles: File[]
  }) => void
  disabled?: boolean
  fileError?: Nullable<string>
  isThin?: boolean
  onlyFileContents?: boolean
  onSelectExistentFiles: (files: FileContent[]) => void
  isEmail?: boolean
}

const FileUploader = ({
  accept = '',
  allowMultipleFiles = true,
  disableClick,
  maxFileSize = MAX_UPLOAD_SIZE_ALLOWED,
  fileHandler,
  disabled,
  fileError,
  isThin,
  onSelectExistentFiles,
  onlyFileContents = false,
  isEmail = false,
}: Props) => {
  const {
    fileInputRef,
    dropFilesContainerRef,
    openFilePicker,
    onSelectFiles,
    onDragOver,
    onDragEnter,
    onDragLeave,
  } = useFilePicker({
    disabled,
    maxFileSize,
    accept,
    onFilesSelected: fileHandler,
  })

  const intl = useIntl()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const onClick = (event) => {
    if (disableClick) {
      return
    }

    event.stopPropagation()
    setIsModalOpen(true)
  }

  const handleSelectFiles = (event) => {
    setIsModalOpen(false)
    onSelectFiles(event)
  }

  return (
    <>
      <FileUploaderContainer
        ref={dropFilesContainerRef}
        role="button"
        tabIndex={0}
        onDrop={handleSelectFiles}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onClick={onClick}
        onKeyDown={onClick}
        disabled={disabled}
        error={!!fileError}
        isThin={!!isThin}
      >
        <DropFiles>
          <DropFilesText>
            {intl.formatMessage({ id: 'general.dropFiles' })}
          </DropFilesText>
          <ButtonContainer>
            <Button
              type="button"
              disabled={disabled}
              secondary
              onClick={(event) => event.preventDefault()}
            >
              {intl.formatMessage({ id: 'general.browseFiles' })}
            </Button>
          </ButtonContainer>
          <MaxFileText>
            {intl.formatMessage(
              { id: 'general.maxFile' },
              { size: isEmail ? '10' : '50' }
            )}
          </MaxFileText>
        </DropFiles>
        <FileUploaderInput
          ref={fileInputRef}
          type="file"
          multiple={allowMultipleFiles}
          onChange={handleSelectFiles}
          autoComplete="off"
          accept={accept}
          disabled={disabled}
        />
      </FileUploaderContainer>
      <FileErrorText>{fileError}</FileErrorText>
      <ModalBrowseFiles
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        openFileDialog={openFilePicker}
        onSelectFiles={onSelectExistentFiles}
        allowMultipleFiles={allowMultipleFiles}
        onlyFileContents={onlyFileContents}
      />
    </>
  )
}

export default FileUploader
