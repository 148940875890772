import { endOfDay } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import FileUploader from 'components/FileUploader'
import RichTextArea from 'components/RichTextArea'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import Toast from 'components/Toast'
import { calculateMinTime } from 'utils/functions/date'

import { ScheduleType } from 'components/ScheduleUpdateRadio/ScheduleUpdateRadio'

import Checkbox from 'components/Checkbox'
import Dropdown from 'ui/Dropdown'
import Input from 'ui/Input'
import { FOUNDER_UPDATES } from 'utils/constants/links'
import {
  getReportDatePeriodDropdownOptions,
  getScheduleRepetitionDropdownOptions,
} from 'utils/functions/accounting'

import UploadsMenu from 'components/UploadsMenu'
import {
  getAccountingReportDropdownOptions,
  getBalanceDateDropdownOptions,
  getPeriodsToCompareDropdownOptions,
} from 'utils/functions/renderers/renderUpdatesHelper'
import { useRandomId } from 'utils/hooks/useRandomId'
import {
  useFileUploadedEventListener,
  useUploadFile,
} from 'utils/hooks/useUploadFile'
import { FileContent } from 'utils/types/files'
import { UpdateAttachment } from 'utils/types/transactions'
import { Update } from 'utils/types/update'
import { UpdateTypes } from 'utils/constants/updates'
import { AccountingReportType } from 'utils/constants/accounting'
import { Connections } from 'api/IntegrationService'
import AttachmentsPreview from '../UpdateAttachmentsPreview/AttachmentsPreview'
import EditToastMessage from './EditToastMessage'

import {
  BodyContainer,
  CheckboxLegend,
  Content,
  DropdownContainer,
  InputDateContainer,
  InputTitleContainer,
  MediaContainer,
  PreviewReportButton,
  RepetitionContainer,
  RepetitionWrapper,
  ReportSettingsRow,
  ReportSettingsTitle,
  Row,
  RunOutOfStorageMessageStyled,
} from './UpdateContent.styles'

interface Values {
  title: string
  body: string
  date: Date
  scheduleType: string
  repetition?: string
  reportType?: AccountingReportType
}

interface Props {
  update?: Update
  errors: any
  type: string
  onFilesChange: (result: UpdateAttachment[]) => void
  mode: 'grid' | 'list'
  dateRequired?: boolean
  isDocument?: boolean
  allowMultipleFiles: boolean
  fileError?: string
  originalFiles: UpdateAttachment[]
  disabled?: boolean
  pastDatesAllowed?: boolean
  showTimeSelect?: boolean
  setValue: any
  watch: any
  values: Values
  isCardExpanded: boolean
  onClickPreviewReport: () => void
  maxDate: Date
  showRepetition?: boolean
  reportParamsDisabled?: boolean
  integrationStatus: Connections
  createMode: boolean
  updateSharedOutsideGroup?: boolean
  setIsUploadingFiles: (value: boolean) => void
}

const UpdateContent = React.forwardRef(
  (
    {
      errors,
      update,
      type,
      onFilesChange,
      dateRequired,
      isDocument,
      allowMultipleFiles,
      fileError,
      originalFiles,
      disabled,
      setValue,
      values,
      pastDatesAllowed,
      watch,
      maxDate,
      showTimeSelect,
      onClickPreviewReport,
      showRepetition,
      reportParamsDisabled,
      integrationStatus,
      createMode,
      updateSharedOutsideGroup,
      setIsUploadingFiles,
    }: Props,
    ref: (arg?: any) => {}
  ) => {
    const intl = useIntl()
    const fileUploadContextId = useRandomId()
    const [files, setFiles] = useState<UpdateAttachment[]>([])
    const isAccounting = type === 'accounting'
    const date = watch('date')
    const scheduleType = watch('scheduleType')
    const location = useLocation()
    const isEditingUpdate = location.pathname.includes('edit')
    const [isNewTimeSelected, setIsNewTimeSelected] = useState(false)

    useEffect(() => {
      if (originalFiles) {
        setFiles(originalFiles)
      }
    }, [originalFiles])

    const handleAddUpdateAttachment = (file: FileContent) => {
      const newFile = {
        ...file,
        isNewUpload: true,
      }

      setFiles((state) => {
        if (!state.some((stateFile) => stateFile.id === newFile.id)) {
          const result: UpdateAttachment[] = [...state, newFile]
          onFilesChange(result)
          return result
        }

        return state
      })
    }

    const { uploadFiles, hasUploadsInProgress } = useUploadFile()

    const isUploadingFiles = hasUploadsInProgress(fileUploadContextId)

    useEffect(() => {
      setIsUploadingFiles(isUploadingFiles)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUploadingFiles])

    const handleFileUploads = async ({ acceptedFiles, rejectedFiles }) => {
      if (rejectedFiles.length) {
        Toast.displayIntl('errors.fileUpload', 'error')
      } else {
        uploadFiles(acceptedFiles, fileUploadContextId)
      }
    }

    useFileUploadedEventListener(
      fileUploadContextId,
      (fileContent: FileContent) => {
        handleAddUpdateAttachment(fileContent)
      }
    )

    const onSelectExistentFiles = (fileContents: FileContent[]) => {
      fileContents.forEach((file) => {
        handleAddUpdateAttachment(file)
      })
    }

    const handleRemoveUpdateAttachment = (file: UpdateAttachment) => {
      let newFiles: UpdateAttachment[] = []
      if (file.isNewUpload) {
        newFiles = files.filter((stateFile) => stateFile.id !== file.id)
      } else {
        newFiles = files.map((stateFile) => {
          if (stateFile.id === file.id) {
            return {
              ...stateFile,
              destroy: true,
            }
          }
          return stateFile
        })
      }

      setFiles(newFiles)
      onFilesChange(newFiles)
    }

    const onChangeScheduleDate = useCallback(
      (scheduleDate) => {
        if (scheduleDate.setSeconds(0) > new Date().setSeconds(0)) {
          setValue('scheduleType', ScheduleType.SCHEDULE, {
            shouldValidate: true,
          })
        } else {
          setValue('scheduleType', ScheduleType.SEND_NOW, {
            shouldValidate: true,
          })
        }

        setValue('date', scheduleDate, { shouldValidate: true })
        setIsNewTimeSelected(true)
      },
      [setValue]
    )

    const onChange = useCallback(
      (name, value) => {
        setValue(name, value, { shouldValidate: true })
      },
      [setValue]
    )

    const text = () => (
      <BodyContainer>
        <RichTextArea
          label={
            isDocument
              ? intl.formatMessage({ id: 'updates.aditionalMessage' })
              : intl.formatMessage({ id: 'updates.description' })
          }
          placeholder={intl.formatMessage({ id: 'updates.bodyPlaceholder' })}
          setValue={setValue}
          id="body"
          name="body"
          value={values?.body}
          error={errors?.body}
          ref={ref}
          disabled={disabled}
          isOptional
        />
      </BodyContainer>
    )

    const notDeletedFiles = files.filter((file) => !file.destroy)
    const showFileUploader = allowMultipleFiles || notDeletedFiles.length !== 1
    const uploader = () => (
      <MediaContainer>
        {showFileUploader && (
          <FileUploader
            fileHandler={handleFileUploads}
            onSelectExistentFiles={onSelectExistentFiles}
            allowMultipleFiles={allowMultipleFiles}
            disabled={!!disabled}
            fileError={fileError || null}
          />
        )}

        <AttachmentsPreview
          contents={files}
          handleRemoveFile={handleRemoveUpdateAttachment}
          mode={update?.updateType === UpdateTypes.DOCUMENT ? 'list' : 'grid'}
        />
        <UploadsMenu closeOnUnmount />
      </MediaContainer>
    )

    const datePicker = () => {
      const pastDatesValidationProps = {
        minDate: new Date(),
        minTime: calculateMinTime(date),
        maxTime: endOfDay(new Date()),
      }

      return (
        <SelectDatePicker
          id="date"
          handleChange={onChangeScheduleDate}
          label={(showTimeSelect
            ? intl.formatMessage({ id: 'updates.publishDate' })
            : intl.formatMessage({ id: 'updates.date' })
          ).toString()}
          placeholder={
            showTimeSelect
              ? intl.formatMessage({ id: 'updates.selectDateTime' })
              : intl.formatMessage({ id: 'updates.selectSpecificDate' })
          }
          onlyMonthYearPicker={false}
          dateFormat={
            (showTimeSelect && isEditingUpdate) ||
            (showTimeSelect && isNewTimeSelected && !isEditingUpdate)
              ? 'MMM dd yyyy - h:mm a'
              : 'MMM dd yyyy'
          }
          disabled={disabled || (isAccounting && isEditingUpdate)}
          showTimeSelect={showTimeSelect}
          maxDate={maxDate}
          name="date"
          error={errors.date}
          register={ref}
          setValue={setValue}
          initialValue={null}
          value={date}
          {...(!pastDatesAllowed ? pastDatesValidationProps : {})}
        />
      )
    }

    const repetition = () => {
      return (
        <RepetitionContainer>
          <Dropdown
            name="repetition"
            ref={ref}
            disabled={isAccounting && isEditingUpdate}
            label={intl.formatMessage({ id: 'general.repetition' })}
            placeholder={intl.formatMessage({ id: 'general.selectRepetition' })}
            onSelectOption={onChange}
            options={getScheduleRepetitionDropdownOptions(date, intl)}
            error={errors.repetition}
            value={values?.repetition}
          />
        </RepetitionContainer>
      )
    }

    const reportFilters = () => {
      const reportType = watch('reportType')

      const { paymentsOnly, standardLayout, balanceDate, periods } =
        watch('reportParams') || {}

      if (
        reportType === AccountingReportType.XERO_BALANCE_SHEET ||
        reportType === AccountingReportType.QUICKBOOKS_BALANCE_SHEET
      ) {
        return (
          <div>
            <ReportSettingsRow>
              <DropdownContainer>
                <Dropdown
                  name="reportParams.balanceDate"
                  ref={ref}
                  label={intl.formatMessage({ id: 'general.balanceDate' })}
                  placeholder={intl.formatMessage({
                    id: 'general.selectBalanceDate',
                  })}
                  onSelectOption={onChange}
                  options={getBalanceDateDropdownOptions(scheduleType, intl)}
                  error={errors.reportParams?.balanceDate}
                  value={balanceDate}
                  disabled={reportParamsDisabled}
                />
              </DropdownContainer>
              <DropdownContainer>
                <Dropdown
                  name="reportParams.periods"
                  ref={ref}
                  label={intl.formatMessage({ id: 'general.comparePeriods' })}
                  placeholder={intl.formatMessage({
                    id: 'general.selectComparePeriods',
                  })}
                  onSelectOption={onChange}
                  options={getPeriodsToCompareDropdownOptions(intl)}
                  error={errors.reportParams?.periods}
                  value={periods}
                  disabled={reportParamsDisabled}
                />
              </DropdownContainer>
            </ReportSettingsRow>
            <Checkbox
              id="reportParams.paymentsOnly"
              register={ref}
              name="reportParams.paymentsOnly"
              label={intl.formatMessage({ id: 'general.paymentsOnly' })}
              onChange={(event) =>
                setValue(event.target.name, event.target.checked)
              }
              checked={paymentsOnly}
              disabled={reportParamsDisabled || isEditingUpdate}
            />
            {reportType === AccountingReportType.XERO_BALANCE_SHEET && (
              <>
                <Checkbox
                  id="reportParams.standardLayout"
                  register={ref}
                  name="reportParams.standardLayout"
                  label={intl.formatMessage({ id: 'general.standardLayout' })}
                  onChange={(event) =>
                    setValue(event.target.name, event.target.checked)
                  }
                  checked={standardLayout}
                  disabled={reportParamsDisabled || isEditingUpdate}
                />
                <CheckboxLegend>
                  {intl.formatMessage({ id: 'general.standardLayoutDesc' })}
                  <a
                    href={FOUNDER_UPDATES}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'general.moreInfo' })}
                  </a>
                </CheckboxLegend>
              </>
            )}
          </div>
        )
      }

      if (
        reportType === AccountingReportType.XERO_PROFIT_AND_LOSS ||
        reportType === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS
      ) {
        return (
          <div>
            <ReportSettingsRow>
              <DropdownContainer>
                <Dropdown
                  name="reportParams.reportDatePeriod"
                  ref={ref}
                  label={intl.formatMessage({ id: 'general.selectPeriod' })}
                  placeholder={intl.formatMessage({
                    id: 'general.selectPeriod',
                  })}
                  onSelectOption={onChange}
                  options={getReportDatePeriodDropdownOptions(date, intl)}
                  error={errors.reportParams?.reportDatePeriod}
                  value={values?.['reportParams.reportDatePeriod']}
                  disabled={reportParamsDisabled || isEditingUpdate}
                />
              </DropdownContainer>
              <DropdownContainer>
                <Dropdown
                  name="reportParams.periods"
                  ref={ref}
                  label={intl.formatMessage({ id: 'general.comparePeriods' })}
                  placeholder={intl.formatMessage({
                    id: 'general.selectComparePeriods',
                  })}
                  onSelectOption={onChange}
                  options={getPeriodsToCompareDropdownOptions(intl)}
                  error={errors.reportParams?.periods}
                  value={values?.['reportParams.periods']}
                  disabled={reportParamsDisabled || isEditingUpdate}
                />
              </DropdownContainer>
            </ReportSettingsRow>
            <Checkbox
              id="reportParams.paymentsOnly"
              register={ref}
              name="reportParams.paymentsOnly"
              label={intl.formatMessage({ id: 'general.paymentsOnly' })}
              onChange={(event) =>
                setValue(event.target.name, event.target.checked)
              }
              checked={paymentsOnly}
              disabled={reportParamsDisabled || isEditingUpdate}
            />
            {reportType === AccountingReportType.XERO_PROFIT_AND_LOSS && (
              <>
                <Checkbox
                  id="reportParams.standardLayout"
                  register={ref}
                  name="reportParams.standardLayout"
                  label={intl.formatMessage({ id: 'general.standardLayout' })}
                  onChange={(event) =>
                    setValue(event.target.name, event.target.checked)
                  }
                  checked={standardLayout}
                  disabled={reportParamsDisabled || isEditingUpdate}
                />
                <CheckboxLegend>
                  {intl.formatMessage({ id: 'general.standardLayoutDesc' })}
                  <a
                    href={FOUNDER_UPDATES}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {intl.formatMessage({ id: 'general.moreInfo' })}
                  </a>
                </CheckboxLegend>
              </>
            )}
          </div>
        )
      }
      return null
    }

    const reportSettings = () => {
      const reportType = watch('reportType')
      return (
        <div>
          <ReportSettingsTitle>
            {intl.formatMessage({ id: 'general.reportSettings' })}
          </ReportSettingsTitle>
          <ReportSettingsRow>
            <DropdownContainer>
              <Dropdown
                name="reportType"
                ref={ref}
                label={intl.formatMessage({ id: 'general.accountingReport' })}
                placeholder={intl.formatMessage({
                  id: 'general.selectAccountingReport',
                })}
                onSelectOption={onChange}
                options={getAccountingReportDropdownOptions(integrationStatus)}
                error={errors.reportType}
                value={values?.reportType}
                disabled={reportParamsDisabled || isEditingUpdate}
              />
            </DropdownContainer>
            {!reportParamsDisabled && (
              <PreviewReportButton
                secondary
                onClick={onClickPreviewReport}
                disabled={!reportType}
              >
                {intl.formatMessage({ id: 'general.previewReport' })}
              </PreviewReportButton>
            )}
          </ReportSettingsRow>
          {reportFilters()}
        </div>
      )
    }

    const renderContent = () => {
      if (isDocument) {
        return (
          <>
            {uploader()}
            {text()}
          </>
        )
      }

      if (isAccounting) {
        return <>{reportSettings()}</>
      }

      return (
        <>
          {text()}
          {uploader()}
        </>
      )
    }

    return (
      <>
        {!isAccounting && <RunOutOfStorageMessageStyled />}
        <Content>
          <EditToastMessage
            update={update}
            createMode={createMode}
            updateSharedOutsideGroup={updateSharedOutsideGroup}
          />
          <Row>
            <InputTitleContainer>
              <Input
                id="update-title"
                name="title"
                ref={ref}
                label={intl.formatMessage({ id: 'updates.title' })}
                placeholder={intl.formatMessage({
                  id: `updates.${type}TitlePlaceholder`,
                })}
                error={errors.title}
                disabled={disabled}
              />
            </InputTitleContainer>

            <InputDateContainer>
              {dateRequired && datePicker()}
            </InputDateContainer>
          </Row>
          <RepetitionWrapper hidden={!isAccounting}>
            {showRepetition && repetition()}
          </RepetitionWrapper>
          {renderContent()}
        </Content>
      </>
    )
  }
)

UpdateContent.displayName = 'UpdateContent'

export default UpdateContent
