import {
  ImageSkeleton,
  SkeletonIcon,
  SkeletonItem,
  SkeletonItemWrapper,
} from '../../Skeletons.styles'
import {
  FileDetailsDrawerWrapper,
  FileDetailsWrapper,
  FileUpdateDetailsContentWrapper,
  FileUpdateDetailsFooterWrapper,
  FileUpdateDetailsWrapper,
} from '../FilesSkeleton.styles'

const FileDetailsSkeleton = () => {
  return (
    <FileDetailsDrawerWrapper>
      <ImageSkeleton />

      <div style={{ display: 'flex', gap: '0.8rem', marginBottom: '2rem' }}>
        <SkeletonIcon />
        <SkeletonIcon />
      </div>

      <SkeletonItemWrapper>
        <SkeletonItem height="1.2rem" width="9rem" />
      </SkeletonItemWrapper>

      <FileDetailsWrapper>
        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="6rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="6rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="4rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="8rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="7rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="3rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="6rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="6rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonItem height="0.8rem" width="4rem" />
        </SkeletonItemWrapper>

        <SkeletonItemWrapper>
          <SkeletonIcon width="2.1rem" height="2.1rem" />
          <SkeletonItem height="0.8rem" width="10rem" />
        </SkeletonItemWrapper>
      </FileDetailsWrapper>

      <FileUpdateDetailsWrapper>
        <div
          style={{
            display: 'flex',
            gap: '0.8rem',
          }}
        >
          <SkeletonIcon width="3.6rem" height="3.6rem" />

          <FileUpdateDetailsContentWrapper>
            <SkeletonItemWrapper>
              <SkeletonItem height="1.2rem" width="10rem" />
            </SkeletonItemWrapper>

            <SkeletonItemWrapper>
              <SkeletonItem height="0.8rem" width="6rem" />
            </SkeletonItemWrapper>
          </FileUpdateDetailsContentWrapper>
        </div>

        <FileUpdateDetailsFooterWrapper>
          <SkeletonItemWrapper>
            <SkeletonItem height="1.2rem" width="26.5rem" />
          </SkeletonItemWrapper>

          <SkeletonItemWrapper>
            <SkeletonItem height="1.2rem" width="14rem" />
          </SkeletonItemWrapper>
        </FileUpdateDetailsFooterWrapper>
      </FileUpdateDetailsWrapper>
    </FileDetailsDrawerWrapper>
  )
}

export default FileDetailsSkeleton
