import styled from 'styled-components/macro'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const Title = styled.span`
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: ${color('gray')};
  margin-top: -1rem;
`

export const Text = styled.span`
  white-space: break-spaces;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-align: center;
  color: ${color('lightGray')};
  margin-top: 0.8rem;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  @media ${maxSize.xsm} {
    padding: 0 2rem;
  }
`
