import React from 'react'
import UpdateNavigation from 'components/UpdateNavigation'
import DiscardOrSave from 'components/DiscardOrSave'
import { useUpdateNavigationHeader } from 'containers/UpdatesV2/components/UpdateHeader/useUpdateNavigationHeader'

import { Container, ButtonContainer } from './UpdateNavigationHeader.styles'

interface UpdateNavigationHeaderProps {
  onSave?: (event: React.FormEvent<HTMLFormElement>) => void
  onCancel?: (event: React.MouseEvent<HTMLFormElement>) => void
  onDelete?: (event: React.MouseEvent<HTMLFormElement>) => void
  editMode?: boolean
  deleteEnabled?: boolean
  confirmDisabled?: boolean
  isSubmitting?: boolean
  arrowNavigationDisabled?: boolean
  confirmLabel?: string
  cancelLabel?: string
}

const UpdateNavigationHeader: React.FC<UpdateNavigationHeaderProps> = ({
  deleteEnabled,
  confirmDisabled,
  editMode,
  onCancel,
  onDelete,
  onSave,
  isSubmitting,
  arrowNavigationDisabled,
  confirmLabel,
  cancelLabel,
}) => {
  const {
    isLoading,
    disableNextButton,
    disablePreviousButton,
    locationState,
    onClickNavigationToUpdate,
  } = useUpdateNavigationHeader()

  return (
    <Container>
      {!isLoading && (
        <ButtonContainer>
          {editMode && (
            <DiscardOrSave
              onCancel={onCancel}
              onSave={onSave}
              onDelete={onDelete}
              deleteEnabled={deleteEnabled}
              confirmDisabled={confirmDisabled}
              isSubmitting={isSubmitting}
              confirmLabel={confirmLabel}
              cancelLabel={cancelLabel}
            />
          )}

          {locationState.showNavigation && (
            <UpdateNavigation
              onClickNavigationToUpdate={onClickNavigationToUpdate}
              disableNextButton={disableNextButton}
              disablePreviousButton={disablePreviousButton}
              arrowNavigationDisabled={arrowNavigationDisabled}
            />
          )}
        </ButtonContainer>
      )}
    </Container>
  )
}

export default UpdateNavigationHeader
