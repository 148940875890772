import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ChevronDown = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
`

export const SelectedOption = styled.div<{ large?: boolean }>`
  border: 0.1rem solid transparent;
  border-radius: 0.8rem;
  background-color: ${color('lightGray', 0.1)};
  padding: 1rem 1.6rem;
  font-size: 1.4rem;
  cursor: pointer;
  outline: none;
  color: ${color('darkGray')};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props) =>
    props.large &&
    css`
      padding: 1.6rem 2.4rem;
      font-size: 2.4rem;
    `}

  :focus {
    border-color: ${color('primaryBlue')};
    background-color: white;

    ${ChevronDown} {
      color: ${color('primaryBlue')};
    }
  }

  :hover:not(:focus) {
    border-color: ${color('veryLightBlue')};

    ${ChevronDown} {
      color: ${color('primaryBlue')};
    }
  }
`
