import styled from 'styled-components'
import { size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 4fr;
  }
`

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h2`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${color('darkBlue')};
  text-transform: capitalize;
`

export const LastUpdatedDateContainer = styled.div`
  color: ${color('lightGray')};
  font-weight: 400;
  font-size: 1.4rem;
  text-transform: none;
  display: none;

  @media ${size.xsm} {
    display: block;
  }
`

export const LastUpdateDateValue = styled.span`
  margin-left: 0.4rem;
`
