import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useFilteredHoldingsIdsQuery } from 'utils/hooks/queries/useInvestmentsFiltersQuery'
import useCustomFilterView from 'containers/Investments/CustomFilterView/useCustomFilterView'
import useInitialData from 'utils/hooks/useInitialData'
import useTagsQuery from 'utils/hooks/useTagsQuery'
import { Industry, Sector } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import { IndexPortfolio, PortfolioCompany } from 'utils/types/portfolios'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import {
  GroupTag,
  Holding,
  InvestmentsFilters,
  InvestmentsFiltersObject,
  NormalizedPortfolio,
} from '../types'
import {
  holdingsFilterApplied,
  industryFilterApplied,
  normalizePortfolio,
  portfolioFilterApplied,
  sectorFilterApplied,
  tagsFilterApplied,
  updateFiltersForHolding,
  updateFiltersForPortfolio,
  updateFiltersForTag,
  updateFiltersObject,
  updateIndustriesToShow,
  updateSectorsAndIndustriesFiltersWhenSelectingIndustry,
  updateSectorsAndIndustriesFiltersWhenSelectingSector,
} from '../helpers'

interface Props {
  portfolios?: IndexPortfolio<PortfolioCompany>[]
  isRefetchingFilters: boolean
}

const useCreateInvestmentsFilter = ({
  portfolios,
  isRefetchingFilters,
}: Props) => {
  const [isCreateFilterDrawerOpen, setIsCreateFilterDrawerOpen] =
    useState(false)
  useState<Nullable<string>>(null)

  const history = useHistory()
  const pathName = history.location.pathname
  const [urlToRedirect, setUrlToRedirect] = useState<Nullable<string>>(null)

  const { initialData } = useInitialData()

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const { data: tags = [] } = useTagsQuery({
    enabled: !isInvestorGroup, // investors have no tags
  })

  const [filtersForFilterInProcess, setFiltersForFilterInProcess] =
    useState<InvestmentsFilters>({
      portfolios: [],
      groupTags: [],
      holdings: [],
      sectors: [],
      industries: [],
    })

  const [industriesToShow, setIndustriesToShow] = useState<Industry[]>([])
  const [filtersObject, setFiltersObject] = useState<InvestmentsFiltersObject>(
    {}
  )

  const hasPortfolioFiltersAppliedOnInProcessFilter = useMemo(
    () => portfolioFilterApplied(filtersForFilterInProcess),
    [filtersForFilterInProcess]
  )

  const hasTagsFiltersAppliedOnInProcessFilter = useMemo(
    () => tagsFilterApplied(filtersForFilterInProcess),
    [filtersForFilterInProcess]
  )

  const hasHoldingsFiltersAppliedOnInProcessFilter = useMemo(
    () => holdingsFilterApplied(filtersForFilterInProcess),
    [filtersForFilterInProcess]
  )

  const hasSectorFiltersAppliedOnInProcessFilter = useMemo(
    () => sectorFilterApplied(filtersForFilterInProcess),
    [filtersForFilterInProcess]
  )

  const hasIndustryFiltersAppliedOnInProcessFilter = useMemo(
    () => industryFilterApplied(filtersForFilterInProcess),
    [filtersForFilterInProcess]
  )

  const hasFiltersAppliedOnInProcessFilter = useMemo(
    () =>
      hasPortfolioFiltersAppliedOnInProcessFilter ||
      hasTagsFiltersAppliedOnInProcessFilter ||
      hasHoldingsFiltersAppliedOnInProcessFilter ||
      hasSectorFiltersAppliedOnInProcessFilter ||
      hasIndustryFiltersAppliedOnInProcessFilter,
    [
      hasPortfolioFiltersAppliedOnInProcessFilter,
      hasTagsFiltersAppliedOnInProcessFilter,
      hasHoldingsFiltersAppliedOnInProcessFilter,
      hasSectorFiltersAppliedOnInProcessFilter,
      hasIndustryFiltersAppliedOnInProcessFilter,
    ]
  )

  const filterInProcessCount = useMemo(() => {
    const filtersToCheck = [
      filtersForFilterInProcess.portfolios.filter((p) => p.isSelected).length,
      filtersForFilterInProcess.sectors.length,
      industriesToShow.length,
      filtersForFilterInProcess.holdings.length,
      filtersForFilterInProcess.groupTags.filter((t) => t.isSelected).length,
    ]

    return filtersToCheck.reduce((acc, curr) => acc + curr, 0)
  }, [
    filtersForFilterInProcess.portfolios,
    filtersForFilterInProcess.sectors.length,
    industriesToShow.length,
    filtersForFilterInProcess.holdings.length,
    filtersForFilterInProcess.groupTags,
  ])

  const onOpenCreateFilterDrawer = useCallback(() => {
    if (hasFiltersAppliedOnInProcessFilter) {
      history.push('/investments/filters')
    }
    setIsCreateFilterDrawerOpen(true)
  }, [hasFiltersAppliedOnInProcessFilter, history])

  const onCloseCreateFilterDrawer = useCallback(
    () => setIsCreateFilterDrawerOpen(false),
    []
  )

  const { data: portfolioHoldingsIds } = useFilteredHoldingsIdsQuery({
    filtersObject,
    hasFiltersApplied: hasFiltersAppliedOnInProcessFilter,
  })

  const { data, columns, columnsOption, holdings, isLoading, toggleColumn } =
    useCustomFilterView({
      portfolioHoldingsIds,
    })

  const onAddPortfolioForFilterInProcess = (portfolio: NormalizedPortfolio) => {
    setFiltersForFilterInProcess((prevFilters) =>
      updateFiltersForPortfolio({
        prevFilters,
        portfolio,
        isSelected: true,
      })
    )
  }

  const onRemovePortfolioForFilterInProcess = (
    portfolio: NormalizedPortfolio
  ) => {
    setFiltersForFilterInProcess((prevFilters) =>
      updateFiltersForPortfolio({ prevFilters, portfolio, isSelected: false })
    )
  }

  const onRemoveTagForFilterInProcess = (tag: GroupTag) => {
    setFiltersForFilterInProcess((prevFilters) =>
      updateFiltersForTag({ prevFilters, tag, isSelected: false })
    )
  }

  const onToggleTagForFilterInProcess = (tag: GroupTag) => {
    setFiltersForFilterInProcess((prevFilters) => {
      const foundTag = prevFilters.groupTags.find(
        (prevTag) => prevTag.id === tag.id
      )

      if (foundTag) {
        const isSelected = !foundTag.isSelected
        return updateFiltersForTag({ prevFilters, tag, isSelected })
      }

      return prevFilters
    })
  }

  const onAddHoldingForFilterInProcess = (company: Holding) => {
    setFiltersForFilterInProcess((prevFilters) =>
      updateFiltersForHolding({ prevFilters, company, isAdding: true })
    )
  }

  const onRemoveHoldingForFilterInProcess = (company: Holding) => {
    setFiltersForFilterInProcess((prevFilters) =>
      updateFiltersForHolding({ prevFilters, company, isAdding: false })
    )
  }

  const getIndustries = (currentSectorId: string) =>
    initialData?.industries.filter(
      (industry) => industry.sectorId === currentSectorId
    ) || []

  const onSelectSectorForFilterInProcess = (newSector: Sector) => {
    updateSectorsAndIndustriesFiltersWhenSelectingSector({
      newSector,
      filters: filtersForFilterInProcess,
      setFiltersFunction: setFiltersForFilterInProcess,
      getIndustries,
    })
  }

  const getAllIndustriesForSectorSelected = (
    sectorId: string,
    industries: Industry[]
  ): boolean => {
    const industriesByCurrentSector = getIndustries(sectorId).map(
      (industry) => industry
    )

    return industriesByCurrentSector.every((industry) =>
      industries.map((ind) => ind.id).includes(industry.id)
    )
  }

  const onSelectIndustryForFilterInProcess = ({ industry, sector }) => {
    updateSectorsAndIndustriesFiltersWhenSelectingIndustry({
      industry,
      sector,
      filters: filtersForFilterInProcess,
      setFiltersFunction: setFiltersForFilterInProcess,
      getAllIndustriesForSectorSelected,
    })
  }

  const onClearAllForFilterInProcess = ({
    shouldRedirect = true,
  }: {
    shouldRedirect?: boolean
  }) => {
    setFiltersForFilterInProcess({
      portfolios: portfolios?.map(normalizePortfolio) || [],
      groupTags: isEmpty(tags) ? [] : (tags as GroupTag[]),
      holdings: [],
      sectors: [],
      industries: [],
    })

    localStorage.removeItem('unsavedFilter')
    if (shouldRedirect) history.push(urlToRedirect || '/investments')
  }

  const manuallyCleanFilters =
    !hasFiltersAppliedOnInProcessFilter && pathName === '/investments/filters'

  useEffect(() => {
    if (manuallyCleanFilters && !isRefetchingFilters) {
      history.push(urlToRedirect || '/investments')
    }
  }, [history, isRefetchingFilters, manuallyCleanFilters, urlToRedirect])

  useEffect(() => {
    if (portfolios) {
      setFiltersForFilterInProcess((prevFilters) => ({
        ...prevFilters,
        portfolios: portfolios.map(normalizePortfolio),
      }))
    }
  }, [portfolios])

  useEffect(() => {
    if (!isEmpty(tags)) {
      setFiltersForFilterInProcess((prevFilters) => ({
        ...prevFilters,
        groupTags: tags as GroupTag[],
      }))
    }
  }, [tags])

  useEffect(() => {
    updateIndustriesToShow({
      filters: filtersForFilterInProcess,
      setIndustriesToShowFunction: setIndustriesToShow,
    })
  }, [filtersForFilterInProcess])

  useEffect(() => {
    const selectedFiltersObject = updateFiltersObject({
      industries: filtersForFilterInProcess.industries,
      sectors: filtersForFilterInProcess.sectors,
      portfolios: filtersForFilterInProcess.portfolios,
      holdings: filtersForFilterInProcess.holdings,
      groupTags: filtersForFilterInProcess.groupTags,
    })

    setFiltersObject(selectedFiltersObject)
  }, [
    filtersForFilterInProcess.groupTags,
    filtersForFilterInProcess.holdings,
    filtersForFilterInProcess.industries,
    filtersForFilterInProcess.portfolios,
    filtersForFilterInProcess.sectors,
  ])

  useEffect(() => {
    if (hasFiltersAppliedOnInProcessFilter) {
      localStorage.setItem('unsavedFilter', JSON.stringify(filtersObject))
    } else {
      localStorage.removeItem('unsavedFilter')
    }
  }, [filtersObject, hasFiltersAppliedOnInProcessFilter])

  useEffect(() => {
    if (hasFiltersAppliedOnInProcessFilter) {
      history.push('/investments/filters')
    }
  }, [hasFiltersAppliedOnInProcessFilter, history])

  useEffect(() => {
    if (pathName === '/investments/filters') return
    setUrlToRedirect(pathName)
  }, [pathName])

  return {
    filtersForFilterInProcess,
    portfolioHoldingsIdsForFilterInProcess: portfolioHoldingsIds,
    filterInProcessCount,

    filteredDataForFilterInProcess: {
      data,
      columns,
      columnsOption,
      holdings,
      isLoading,
      toggleColumn,
    },

    hasFiltersAppliedOnInProcessFilter,
    hasPortfolioFiltersAppliedOnInProcessFilter,
    hasTagsFiltersAppliedOnInProcessFilter,
    hasHoldingsFiltersAppliedOnInProcessFilter,
    hasSectorFiltersAppliedOnInProcessFilter,
    hasIndustryFiltersAppliedOnInProcessFilter,

    industriesToShowForFilterInProcess: industriesToShow,
    filtersObjectForFilterInProcess: filtersObject,

    onSelectSectorForFilterInProcess,
    onSelectIndustryForFilterInProcess,
    onAddPortfolioForFilterInProcess,
    onRemovePortfolioForFilterInProcess,
    onRemoveTagForFilterInProcess,
    onToggleTagForFilterInProcess,
    onAddHoldingForFilterInProcess,
    onRemoveHoldingForFilterInProcess,
    onClearAllForFilterInProcess,

    isCreateFilterDrawerOpen,
    onOpenCreateFilterDrawer,
    onCloseCreateFilterDrawer,
  }
}

export default useCreateInvestmentsFilter
