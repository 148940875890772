import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'components/Modal'
import ShareWithDropdown from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/ShareWithDropdown'
import ShareWithEntity from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithEntities/ShareWithEntity'
import { GroupsContainer } from 'containers/UpdatesV2/components/ShareSettingsModal/ShareSettingsModal.styles'
import { ShareWithOptionType } from 'containers/UpdatesV2/components/ShareSettingsModal'
import List from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithEntities/List'
import { ReshareTokenSet } from 'utils/functions/updates'
import { Update } from 'utils/types/update'
import Button from 'ui/Button/Button'
import Separator from 'ui/Separator'
import TextArea from 'ui/TextArea/TextArea'
import { useReshareUpdate } from './useReshareUpdate'
import { ConfidentialWarning, ModalBody } from './ReshareUpdate.styles'

interface ReshareUpdateModalProps {
  isModalOpen: boolean
  updateTitle?: string
  closeModal: () => void
  reshareTokenSet?: ReshareTokenSet
  update: Update
}

const ReshareUpdateModal = ({
  isModalOpen,
  updateTitle,
  reshareTokenSet,
  update,
  closeModal,
}: ReshareUpdateModalProps) => {
  const intl = useIntl()
  const isEditingPreviousReshare = !!reshareTokenSet

  const {
    tokenReshareEntitiesSelected,
    newReshareEntitiesSelected,
    reshareMessage,
    isSubmitting,
    isConfidential,
    getOtherEntitiesSelectedIds,
    onRecipientRemove,
    onCloseDropdown,
    loadOptions,
    setNewReshareEntitiesSelected,
    setTokenReshareEntitiesSelected,
    onChangeReshareMessage,
    editReshare,
    reshareUpdate,
  } = useReshareUpdate({
    update,
    reshareTokenSet,
  })

  const entitiesToMapThrough = isEditingPreviousReshare
    ? tokenReshareEntitiesSelected
    : newReshareEntitiesSelected

  const showEntitiesContainer = entitiesToMapThrough.length > 0
  const disabledReshareButton = entitiesToMapThrough.length === 0

  return (
    <Modal show={isModalOpen} onHide={closeModal} hideOnOutsideClick={false}>
      <Modal.Header onHide={closeModal}>
        <Modal.Title>
          {isEditingPreviousReshare
            ? intl.formatMessage({ id: 'updates.editReshare' })
            : intl.formatMessage(
                { id: 'updates.reshareModal.title' },
                { updateTitle }
              )}
        </Modal.Title>
      </Modal.Header>

      <ModalBody>
        {isConfidential && !isEditingPreviousReshare && (
          <ConfidentialWarning>
            <FontAwesomeIcon icon={['far', 'user-shield']} />
            {intl.formatMessage({ id: 'updates.confidentialWarning' })}
          </ConfidentialWarning>
        )}

        <ShareWithDropdown
          label={intl.formatMessage({ id: 'updates.reshareWith' })}
          selectedUsers={
            isEditingPreviousReshare
              ? tokenReshareEntitiesSelected
              : newReshareEntitiesSelected
          }
          loadOptions={loadOptions}
          onClose={onCloseDropdown}
          maxMenuHeight={320}
          placeholder={intl.formatMessage({
            id: 'updates.reshareModal.selectListsGroupsUsers',
          })}
        />

        {showEntitiesContainer && (
          <GroupsContainer hasTopMargin>
            {entitiesToMapThrough.map((option, index) => {
              const onRemove = () => {
                setTokenReshareEntitiesSelected((currGroupsSelected) =>
                  currGroupsSelected.filter((group) => group.id !== option.id)
                )

                setNewReshareEntitiesSelected((currGroupsSelected) =>
                  currGroupsSelected.filter((group) => group.id !== option.id)
                )
              }

              if (option.type === ShareWithOptionType.List) {
                return (
                  <List
                    key={option.id}
                    name={option.name}
                    recipients={option.recipients!}
                    onRemove={onRemove}
                    onRecipientRemove={onRecipientRemove(option.id)}
                    selectedIds={getOtherEntitiesSelectedIds(option, index)}
                  />
                )
              }

              return (
                <ShareWithEntity
                  key={option.id}
                  name={option.name}
                  imageUrl={option.imageUrl}
                  onRemove={option.canBeDeleted ? onRemove : undefined}
                  showCanViewStatus={false}
                  unreachable={option.unreachableEmail}
                />
              )
            })}
          </GroupsContainer>
        )}

        <Separator space="2.4rem" />

        <TextArea
          label={intl.formatMessage({ id: 'updates.reshareModal.message' })}
          placeholder={intl.formatMessage({
            id: 'updates.reshareModal.messagePlaceholder',
          })}
          value={reshareMessage}
          onChange={(event) => onChangeReshareMessage(event.target.value)}
          isOptional
          rows={isEditingPreviousReshare ? undefined : 1}
          placeholderColor="darkGray"
        />
      </ModalBody>

      <Modal.RowFooter>
        <Button onClick={closeModal} secondary>
          {intl.formatMessage({ id: 'common.cancel' })}
        </Button>

        <Button
          primary
          blue
          isDisabled={disabledReshareButton || isSubmitting}
          onClick={() => {
            if (isEditingPreviousReshare) {
              editReshare()
            } else {
              reshareUpdate()
            }

            closeModal()
          }}
        >
          {intl.formatMessage({ id: 'updates.reshareModal.reshareUpdate' })}
        </Button>
      </Modal.RowFooter>
    </Modal>
  )
}

export default ReshareUpdateModal
