import { useCallback } from 'react'
import { FundManagerRoutes, RoutesType } from 'reducers/breadcrumbSlice'
import DiscoverBreadcrumb from '../DiscoverBreadcrumb'
import InvestmentsBreadcrumb from '../InvestmentsBreadcrumb'
import InvestmentVehiclesBreadcrumb from '../InvestmentVehiclesBreadcrumb'
import InvestorManagementBreadcrumb from '../InvestorManagementBreadcrumb'
import OrganizationProfileBreadcrumb from '../OrganizationProfileBreadcrumb'
import useBreadcrumbNavigation from '../../useBreadcrumbNavigation'

const fundManagerBreadcrumb = {
  investments: InvestmentsBreadcrumb,
  organizationProfile: OrganizationProfileBreadcrumb,
  investorManagement: InvestorManagementBreadcrumb,
  investorVehicles: InvestmentVehiclesBreadcrumb,
  discover: DiscoverBreadcrumb,
}

interface Props {
  baseRoute: RoutesType
}

const useFundManagerBreadcrumb = ({ baseRoute }: Props) => {
  const {
    update,
    entityToShow,
    url,
    isShowingCompany,
    initialLinkRedirect,
    isCreatingNewUpdate,
    investmentOptionSelected,
    fundCompany,
    getFundCompanyAvatar,
    getAvatar,
  } = useBreadcrumbNavigation()

  const navigationComponentFunc = useCallback(() => {
    switch (baseRoute) {
      case FundManagerRoutes.INVESTMENTS:
        return fundManagerBreadcrumb.investments({
          update,
          entityToShow,
          url,
          isShowingCompany,
          initialLinkRedirect,
          isCreatingNewUpdate,
          investmentOptionSelected,
          getAvatar,
        })
      case FundManagerRoutes.ORGANIZATION_PROFILE:
        return fundManagerBreadcrumb.organizationProfile({
          update,
          entityToShow,
          url,
          initialLinkRedirect,
          isCreatingNewUpdate,
          fundCompany,
          getFundCompanyAvatar,
          getAvatar,
        })
      case FundManagerRoutes.INVESTOR_MANAGEMENT:
        return fundManagerBreadcrumb.investorManagement({
          update,
          url,
          initialLinkRedirect,
          isCreatingNewUpdate,
        })
      case FundManagerRoutes.INVESTOR_VEHICLES:
        return fundManagerBreadcrumb.investorVehicles({
          update,
          url,
          initialLinkRedirect,
          isCreatingNewUpdate,
        })
      case FundManagerRoutes.DISCOVER:
        return fundManagerBreadcrumb.discover({
          update,
          entityToShow,
          url,
          initialLinkRedirect,
          isCreatingNewUpdate,
          isShowingCompany,
          getAvatar,
        })
      default:
        return null
    }
  }, [
    baseRoute,
    entityToShow,
    fundCompany,
    initialLinkRedirect,
    investmentOptionSelected,
    isCreatingNewUpdate,
    isShowingCompany,
    update,
    url,
    getAvatar,
    getFundCompanyAvatar,
  ])

  return {
    navigationComponentFunc,
  }
}

export default useFundManagerBreadcrumb
