import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Card from 'components/Card'
import InfiniteScroll from 'components/InfiniteScrollRef/InfiniteScroll'
import SkeletonInvitationsCard from 'components/Skeletons/Metrics/SkeletonInvitationsCard/SkeletonInvitationsCard'
import {
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'
import { useAutoAnimate } from 'utils/hooks/useAutoAnimate'
import { METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import theme from 'utils/theme'
import { useMetricsInvitationsHistoryQuery } from 'utils/hooks/metrics/useMetricsInvitationsQueries'
import InvitationRow from '../InvitationRow'
import { CardBody } from '../../Invitations.styles'

interface InvitationsHistoryProps {
  setHasInvitationsHistory: (hasInvitationsHistory: boolean) => void
}

const InvitationsHistory: React.FC<InvitationsHistoryProps> = ({
  setHasInvitationsHistory,
}) => {
  const [isPaginationEnabled, setIsPaginationEnabled] = useState(true)
  const [animationParent] = useAutoAnimate()
  const { isLoading, pendingInvitations, fetchNextInvitationsHistoryPage } =
    useMetricsInvitationsHistoryQuery(setIsPaginationEnabled)

  const invitations = useMemo(() => {
    if (isLoading) {
      return []
    }

    return pendingInvitations?.pages?.flatMap((page) => page.data) || []
  }, [isLoading, pendingInvitations?.pages])

  const hasInvitationsHistory = useMemo(() => {
    if (isLoading) {
      return true
    }

    return invitations.length > 0
  }, [isLoading, invitations])

  useEffect(() => {
    setHasInvitationsHistory(hasInvitationsHistory)
  }, [hasInvitationsHistory, setHasInvitationsHistory])

  return isLoading || hasInvitationsHistory ? (
    <Card
      isExpandable
      padding="0"
      initiallyExpanded={false}
      arrowColor={isLoading ? theme.colors.lightGray : undefined}
    >
      <Card.Header padding="3rem 2.4rem">
        {isLoading ? (
          <SkeletonItemWrapper>
            <SkeletonItem width="12rem" height="1.8rem" />
          </SkeletonItemWrapper>
        ) : (
          <FormattedMessage id="metrics.invitations.invitationsHistory" />
        )}
      </Card.Header>
      <CardBody>
        {isLoading ? (
          <SkeletonInvitationsCard />
        ) : (
          <>
            <div ref={animationParent}>
              {invitations.map((invitation) => (
                <InvitationRow key={invitation.id} invitation={invitation} />
              ))}
            </div>
            <InfiniteScroll
              enabled={isPaginationEnabled}
              fetchNextPage={fetchNextInvitationsHistoryPage}
              pageSize={METRICS_PAGE_SIZE}
            />
          </>
        )}
      </CardBody>
    </Card>
  ) : null
}

export default InvitationsHistory
