import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const SwitchContainer = styled.div`
  width: ${(props) => props.width};
  display: flex;
`

export const SwitchOption = styled.button`
  height: 3.7rem;
  width: 100%;
  padding: 0.4rem 0.6rem;
  border: 1px solid
    ${(props) =>
      props.selected
        ? props.selectedColor || color('primaryBlue')(props)
        : color('veryLightBlue')(props)};

  font-size: ${(props) => props.fontSize || '1.2rem'};
  background-color: ${(props) =>
    props.selected
      ? props.selectedColor || color('primaryBlue')(props)
      : props.unSelectedColor || 'white'};
  color: ${(props) => (props.selected ? 'white' : color('darkBlue')(props))};
  border-radius: 8px;
  font-weight: 600;

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  outline: none;
  &:focus {
    outline: none;
  }
`
