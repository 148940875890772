import styled from 'styled-components/macro'
import { CHAT_POPUP_HEADER_HEIGHT } from 'utils/constants/chat'

export const CLOSABLE_THREAD_AREA_WIDTH = '2.3rem'

export const Container = styled.div`
  position: absolute;
  width: ${CLOSABLE_THREAD_AREA_WIDTH};
  height: calc(100% - ${CHAT_POPUP_HEADER_HEIGHT}px);
  top: ${CHAT_POPUP_HEADER_HEIGHT}px;
  cursor: pointer;
`
