/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Heading from 'components/Heading'
import {
  isDownCode,
  isEnterKeyCode,
  isEscCode,
  isUpCode,
} from 'utils/functions/keyboardEvents'
import Input from 'ui/Input'

import styles from './SearchBy.module.scss'

const SearchBy = ({
  dataSet,
  onFiltersChange,
  title,
  placeholder,
  activeFiltersProp,
  keywordSearchEnabled,
  nameSearchEnabled,
}) => {
  const [options, setOptions] = useState([])
  const [activeFilters, setActiveFilters] = useState(activeFiltersProp)
  const [currentDataSet, setCurrentDataSet] = useState('')
  const [cursor, setCursor] = useState(-1)
  const [filteredOptions, setFilteredOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)
  const inputRef = useRef(null)
  const firstRender = useRef(true)
  const intl = useIntl()

  useEffect(() => {
    setOptions(Object.keys(dataSet))
    setFilteredOptions(Object.keys(dataSet))
  }, [dataSet])

  const handleFocus = () => {
    setShowSuggestions(true)
  }

  const handleBlur = () => {
    setShowSuggestions(false)
    setCurrentDataSet('')
    setFilteredOptions(options)
  }

  const setInitialState = () => {
    setCursor(-1)
    setInputValue('')
    inputRef.current.blur()
  }

  const handleEnterOrClickAction = () => {
    const currentOption = filteredOptions[cursor]
    const optionsForDataSet = dataSet[currentOption]
    const userClickOnDataSet = !!dataSet[currentOption]

    if (userClickOnDataSet) {
      // Filter dataset data (remove the ones in active filters) before showing options from dataset
      const filteredOptionsFromDataSetByActiveFilters =
        optionsForDataSet.filter(
          (option) =>
            !activeFilters
              .map((activeFilter) => activeFilter.value)
              .includes(option.value)
        )

      setCurrentDataSet(currentOption)
      setFilteredOptions(filteredOptionsFromDataSetByActiveFilters)
      setInputValue('')
      setCursor(0)
    } else {
      const isNoOptionSelected = cursor === -1
      const isUserTyping = inputValue !== ''

      if (isNoOptionSelected && keywordSearchEnabled) {
        if (!inputValue) {
          return
        }
        setActiveFilters([
          ...activeFilters.filter(
            (activeFilter) => activeFilter.dataSetName !== 'keyword'
          ),
          {
            name: `keyword: ${inputValue}`,
            value: inputValue,
            dataSetName: 'keyword',
          },
        ])
      } else if (isUserTyping && !currentOption && nameSearchEnabled) {
        // If user is typing and there is no suggestion matching
        setActiveFilters([
          ...activeFilters.filter(
            (activeFilter) => activeFilter.dataSetName !== 'name'
          ),
          {
            name: `name: ${inputValue}`,
            value: inputValue,
            dataSetName: 'name',
          },
        ])
      } else {
        const isPortfolioNameFilterActive = activeFilters.find(
          (filter) => filter.dataSetName === 'portfolio'
        )
        if (
          isPortfolioNameFilterActive &&
          currentOption.dataSetName === 'portfolio'
        ) {
          const newActiveFilters = activeFilters.filter(
            (filter) => filter.dataSetName !== 'portfolio'
          )
          setActiveFilters([...newActiveFilters, currentOption])
        } else if (currentOption) {
          setActiveFilters([...activeFilters, currentOption])
        }
      }
      setInitialState()
    }
  }

  const handleKeyDown = (event) => {
    const isMovingDown = isDownCode(event) && cursor < filteredOptions.length
    const isMovingUp = isUpCode(event) && cursor > 0

    if (isMovingDown) {
      setCursor((cursorParam) => cursorParam + 1)
    } else if (isMovingUp) {
      setCursor((cursorParam) => cursorParam - 1)
    }
    if (isEnterKeyCode(event)) {
      handleEnterOrClickAction()
    }
    if (isEscCode(event)) {
      setInitialState()
    }
  }

  const handleInputChange = (event) => {
    const newInputValue = event.target.value
    setInputValue(newInputValue)
  }

  useEffect(() => {
    let newFilteredOPtions

    if (!currentDataSet) {
      newFilteredOPtions = options.filter(
        (option) => option.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      )
    } else {
      newFilteredOPtions = dataSet[currentDataSet].filter(
        (option) =>
          option.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
      )
    }
    setFilteredOptions(
      newFilteredOPtions.filter((option) => !activeFilters.includes(option))
    )
  }, [inputValue, options])

  useEffect(() => {
    if (!firstRender.current) {
      const newOptionsToShow = options.filter((option) => {
        return !activeFilters.includes(option) ? option : null
      })
      setFilteredOptions(newOptionsToShow.map((option) => option))
      onFiltersChange(activeFilters)
    } else {
      firstRender.current = false
    }
  }, [activeFilters])

  const displaySuggestions = () => {
    const areThereFilterOptions = !!filteredOptions.length
    if (!areThereFilterOptions) {
      return false
    }
    return showSuggestions
  }

  const removeFilter = (filterToRemove) => {
    const newActiveFilters = activeFilters.filter(
      (activeFilter) => activeFilter !== filterToRemove
    )

    setActiveFilters(newActiveFilters)
  }

  const clearSearch = () => setActiveFilters([])

  useEffect(() => {
    // Check if we have to scroll down or up in case user is moving with arrows keys
    const selectedOption = document.querySelectorAll(
      'li[class*="activeOption"'
    )[0]
    const suggestionsContainer = document.querySelectorAll(
      'div[class*="searchBySuggestionsContainer"'
    )[0]

    if (selectedOption && suggestionsContainer) {
      const selectedOptionSize = selectedOption.getBoundingClientRect()
      const suggestionsContainerSize =
        suggestionsContainer.getBoundingClientRect()

      const scrollFromTopSoFar = suggestionsContainer.scrollTop

      if (suggestionsContainerSize.top > selectedOptionSize.top) {
        suggestionsContainer.scrollTop =
          scrollFromTopSoFar -
          suggestionsContainerSize.top +
          selectedOptionSize.top
      } else if (suggestionsContainerSize.bottom < selectedOptionSize.bottom) {
        suggestionsContainer.scrollTop =
          scrollFromTopSoFar -
          suggestionsContainerSize.bottom +
          selectedOptionSize.bottom
      }
    }
  }, [cursor])

  const areThereActiveFilters = !!activeFilters.length

  const handleMouseMove = (index) => {
    setCursor(index)
  }

  const handleMouseClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    handleEnterOrClickAction()
  }

  return (
    <div>
      <div className={styles.searchByHeadingContainer}>
        {title && (
          <Heading className={styles.searchByTitle} level="h3">
            {title}
          </Heading>
        )}
        {areThereActiveFilters && (
          <button
            type="button"
            className={styles.clearSearchClass}
            onClick={clearSearch}
          >
            <FormattedMessage id="companyList.clearFilters" />
          </button>
        )}
      </div>
      <div className={styles.searchByWrapper}>
        <Input
          id="search"
          icon={['far', 'search']}
          onBlur={handleBlur}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          iconFontSize="1.6rem"
          placeholder={placeholder || intl.messages['companyList.search']}
          ref={inputRef}
          value={inputValue}
          onPressEnter={handleEnterOrClickAction}
        />

        {displaySuggestions() && (
          <div className={styles.searchBySuggestionsContainer}>
            <ul>
              {filteredOptions.map((option, index) => (
                <li
                  className={cursor === index ? styles.activeOption : ''}
                  onMouseMove={() => handleMouseMove(index)}
                  onMouseDown={handleMouseClick}
                  key={option.name || option}
                >
                  {option?.name || option}
                </li>
              ))}
            </ul>
          </div>
        )}

        <ul className={styles.activeFiltersList}>
          {activeFilters.map((activeFilter) => {
            return (
              <li key={activeFilter.name}>
                <span className={styles.queryContainer}>
                  <span className={styles.queryType}>
                    {activeFilter?.name?.split(':')[0]?.length >= 15
                      ? activeFilter?.name?.split(':')[0].substring(0, 9)
                      : activeFilter?.name?.split(':')[0]}
                    {': '}
                  </span>
                  <span className={styles.queryValue}>
                    {activeFilter?.name?.split(':')[1]}
                  </span>
                </span>
                <button
                  type="button"
                  className={styles.timesWrapper}
                  onClick={() => {
                    removeFilter(activeFilter)
                  }}
                >
                  <FontAwesomeIcon icon={['far', 'times']} />
                </button>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

SearchBy.defaultProps = {
  onFiltersChange: () => {},
  title: '',
  placeholder: '',
  activeFiltersProp: [],
  keywordSearchEnabled: true,
  nameSearchEnabled: true,
}

SearchBy.propTypes = {
  dataSet: PropTypes.object.isRequired,
  onFiltersChange: PropTypes.func,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  activeFiltersProp: PropTypes.array,
  keywordSearchEnabled: PropTypes.bool,
  nameSearchEnabled: PropTypes.bool,
}

export default SearchBy
