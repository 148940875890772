import humps from 'humps'
import axios from 'axios'
import type { AxiosRequestTransformer } from 'axios'

import { BillingAccountFormValues } from 'containers/Signup/GetStarted/components/BillingAccount'
import { AuthHeaders, BillingAccount } from 'utils/types/signUp'
import { Nullable } from 'utils/types/common'
import {
  ChangePlanInvoiceData,
  CreateSubscriptionData,
  Customer,
  PromotionCode,
  Subscription,
  SubscriptionBillingPeriod,
  SubscriptionPlan,
  SubscriptionStatus,
} from 'utils/types/subscriptions'
import axiosClient from './httpClient'

const keysToIgnoreDecamelize = ['postalCode', 'billingPeriod', 'prorationDate']

const getCustomAxiosClient = (authHeaders?: AuthHeaders) => {
  return axiosClient(false, authHeaders, {
    transformRequest: [
      (data) =>
        humps.decamelizeKeys(data, (key, convert) =>
          keysToIgnoreDecamelize.includes(key) ? key : convert(key)
        ),
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
  })
}

class SubscriptionService {
  static async createSubscription(billingAccount: BillingAccount) {
    const result = await getCustomAxiosClient().post<
      any,
      {
        data: {
          stripeClientSecret: string
          stripeCustomerId: string
          stripeSubscriptionId: string
        }
      }
    >('/stripe/subscriptions/create_for_user', {
      stripeData: billingAccount,
    })

    return result.data
  }

  static async createCustomer(
    billingAccount: BillingAccountFormValues
  ): Promise<string> {
    const response = await getCustomAxiosClient().post<
      any,
      { data: { stripeCustomerId: string } }
    >('/stripe/customers', {
      stripe_params: {
        name: billingAccount.name,
        email: billingAccount.email,
        phone: billingAccount.phone,
        address: billingAccount.hasPostalAddress
          ? {
              address: billingAccount.address?.street ?? '',
              city: billingAccount.address?.city ?? '',
              state: billingAccount.address?.state ?? '',
              postalCode: billingAccount.address?.zip ?? '',
              country: billingAccount.country,
            }
          : {
              address: '',
              city: '',
              state: '',
              postalCode: '',
              country: billingAccount.country,
            },
      },
    })

    return response.data.stripeCustomerId
  }

  static async createCustomerSubscription(
    customerId: string,
    subscriptionData: CreateSubscriptionData
  ): Promise<{
    stripeNextBillingDate: string
    stripeStatus: SubscriptionStatus
    stripeSubscriptionId: string
  }> {
    const response = await getCustomAxiosClient().post<
      any,
      {
        data: {
          stripeNextBillingDate: string
          stripeStatus: SubscriptionStatus
          stripeSubscriptionId: string
        }
      }
    >('/stripe/subscriptions/create_with_customer', {
      stripe_data: subscriptionData,
      customer_id: customerId,
    })

    return response.data
  }

  static async reactivateSubscription(
    subscriptionId: string,
    reactivateSubscriptionData?: {
      plan: SubscriptionPlan
      billingPeriod: SubscriptionBillingPeriod
      prorationDate?: number
    }
  ): Promise<{
    stripeNextBillingDate: string
    stripeStatus: SubscriptionStatus
    stripeSubscriptionId: string
  }> {
    const response = await getCustomAxiosClient().patch<
      any,
      {
        data: {
          stripeNextBillingDate: string
          stripeStatus: SubscriptionStatus
          stripeSubscriptionId: string
        }
      }
    >(`/stripe/subscriptions/${subscriptionId}/reactivate`, {
      stripe_params: reactivateSubscriptionData,
    })

    return response.data
  }

  static async getCustomerData(customerId: string) {
    const response = await getCustomAxiosClient().get<any, { data: Customer }>(
      `/stripe/customers/${customerId}`
    )

    return response.data
  }

  static confirmPayment(userId: string, paymentIntentId: string) {
    axiosClient().patch(`/users/${userId}/payment_intent_id`, {
      paymentIntentId,
    })
  }

  static async getSubscriptionData<T extends Subscription = Subscription>(
    subscriptionId: string,
    authHeaders?: AuthHeaders
  ): Promise<T> {
    const response = await axiosClient(false, authHeaders).get<T>(
      `/stripe/subscriptions/${subscriptionId}`
    )

    return response.data
  }

  static async updateCustomer(
    customerId: string,
    customerData: BillingAccountFormValues
  ): Promise<void> {
    return getCustomAxiosClient().patch(`/stripe/customers/${customerId}`, {
      stripeParams: {
        name: customerData.name,
        email: customerData.email,
        phone: customerData.phone,
        address: customerData.hasPostalAddress
          ? {
              address: customerData.address?.street ?? '',
              city: customerData.address?.city ?? '',
              state: customerData.address?.state ?? '',
              postalCode: customerData.address?.zip ?? '',
              country: customerData.country,
            }
          : {
              address: '',
              city: '',
              state: '',
              postalCode: '',
              country: customerData.country,
            },
      },
    })
  }

  static async setupNewPaymentMethod(customerId: string): Promise<{
    setupIntentId: string
    clientSecret: string
  }> {
    const response = await axiosClient().patch(
      `/stripe/customers/${customerId}/add_payment_method`
    )

    return response.data
  }

  static async cancelSubscription(subscriptionId: string): Promise<{
    id: string
    plan: SubscriptionPlan
    status: SubscriptionStatus
    nextBillingDate: Date
  }> {
    const response = await axiosClient().delete(
      `/stripe/subscriptions/${subscriptionId}`
    )
    return response.data
  }

  static updateSubscriptionPlan(
    subscriptionId: string,
    newSubscriptionPlan: SubscriptionPlan,
    newBillingPeriod: SubscriptionBillingPeriod,
    prorationDate: number
  ): Promise<void> {
    return getCustomAxiosClient().patch(
      `/stripe/subscriptions/${subscriptionId}/update_plan`,
      {
        stripeParams: {
          plan: newSubscriptionPlan,
          billingPeriod: newBillingPeriod,
          prorationDate,
        },
      }
    )
  }

  static async getSubscriptionChangePlanPreview(
    subscriptionId: string,
    newSubscriptionPlan: SubscriptionPlan,
    newBillingPeriod: SubscriptionBillingPeriod
  ): Promise<ChangePlanInvoiceData> {
    const response = await axiosClient().get<ChangePlanInvoiceData>(
      `/stripe/subscriptions/${subscriptionId}/preview_change_plan`,
      {
        params: {
          plan: newSubscriptionPlan,
          billingPeriod: newBillingPeriod,
        },
      }
    )
    return response.data
  }

  static async applyCoupon(
    subscriptionId: string,
    coupon: Nullable<string>
  ): Promise<Subscription> {
    const response = await axiosClient().patch<Subscription>(
      `/stripe/subscriptions/${subscriptionId}/apply_coupon`,
      {
        stripeParams: {
          coupon,
        },
      }
    )
    return response.data
  }

  static async validateCoupon(coupon: string): Promise<PromotionCode> {
    const response = await axiosClient().get<PromotionCode>(
      `/stripe/subscriptions/validate_coupon/${coupon}`
    )
    return response.data
  }
}

export default SubscriptionService
