import { Channel } from 'stream-chat'
import { useSetActiveChannel } from 'containers/Chat/hooks'
import { StreamChatType } from 'containers/Chat/types'
import {
  getMaximizedChatId,
  closeChatPopupById,
  maximizeChatPopupById,
  minimizeChatPopup,
} from 'features/chatSlice'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { OPEN_CHAT_POPUP_EVENT } from 'utils/constants/events'
import { dispatchEvent } from 'utils/hooks/useEventListener'

export const useChatPopupHeader = (channel: Channel<StreamChatType>) => {
  const dispatch = useAppDispatch()
  const setActiveChannel = useSetActiveChannel()
  const maximizedChatId = useAppSelector(getMaximizedChatId)

  const unreadCount = channel.countUnread()

  const onMaximizeChannel = () => {
    dispatch(maximizeChatPopupById(channel.id!))
  }

  const onCloseChannel = () => {
    dispatch(closeChatPopupById(channel.id!))
  }

  const onMinimizeChannel = () => {
    dispatch(minimizeChatPopup())
  }

  const onOpenChat = (event: React.MouseEvent) => {
    event.stopPropagation()
    setActiveChannel(channel)
    onCloseChannel()
    dispatchEvent(OPEN_CHAT_POPUP_EVENT, channel)
  }

  return {
    onMaximizeChannel,
    onCloseChannel,
    onMinimizeChannel,
    onOpenChat,
    unreadCount,
    maximizedChatId,
  }
}
