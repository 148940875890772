import PortfolioIcon from 'components/PortfolioIcon'
import Tooltip from 'components/Tooltip'
import { getPortfolioTypeFromTopicEntityType } from 'containers/Chat/helpers'
import {
  StreamChatType,
  isHoldingChannel,
  isPortfolioChannel,
  isTopicChannel,
} from 'containers/Chat/types'
import React from 'react'
import { Channel } from 'stream-chat'
import { getInitials } from 'utils/functions/user'
import { TooltipContent } from '../../ChatSidebar/components/ChannelPreview/ChannelPreview.styles'
import {
  AvatarContainer,
  ChannelName,
  ParticipantRowContainer,
  StyledAvatar,
} from './SearchResults.styles'

type Props = {
  channel: Channel<StreamChatType>
  onSelectChannel: (channel: Channel<StreamChatType>) => void
  isLast?: boolean
}

export const getChannelName = (channel: Channel<StreamChatType>) => {
  const members = Object.values(channel.state.members)
  const membersNames = members.map((member) => member.user?.name).join(', ')

  if (isTopicChannel(channel.data)) {
    return channel.data.name || membersNames
  }

  return membersNames
}

export const getChannelAvatar = (channel: Channel<StreamChatType>) => {
  if (isTopicChannel(channel.data)) {
    if (isHoldingChannel(channel.data)) {
      return (
        <AvatarContainer>
          <Tooltip
            text={<TooltipContent>{channel.data.topicName}</TooltipContent>}
            backgroundColor="transparent"
            place="right"
            showDelay={500}
            topOffset={20}
          >
            <StyledAvatar
              image={channel.data.imageUrl}
              initials={getInitials(channel.data.topicName)}
              avatarStyle="avatarForUpdateFeed"
            />
          </Tooltip>
        </AvatarContainer>
      )
    }

    if (isPortfolioChannel(channel.data)) {
      return (
        <Tooltip
          text={<TooltipContent>{channel.data.topicName}</TooltipContent>}
          backgroundColor="transparent"
          place="right"
          showDelay={500}
          topOffset={20}
        >
          <PortfolioIcon
            type={getPortfolioTypeFromTopicEntityType(channel.data.entityType)}
          />
        </Tooltip>
      )
    }
  }

  const members = Object.values(channel.state.members)
  return (
    <AvatarContainer>
      <StyledAvatar
        image={members[0]?.user?.image}
        initials={getInitials(members[0]?.user?.name)}
        avatarStyle="avatarForUpdateFeed"
      />
    </AvatarContainer>
  )
}

const ChannelRow: React.FC<Props> = ({ channel, onSelectChannel, isLast }) => {
  return (
    <ParticipantRowContainer isLast={isLast}>
      {getChannelAvatar(channel)}
      <ChannelName
        className="highlight-channel-name"
        onClick={() => onSelectChannel(channel)}
      >
        {getChannelName(channel)}
      </ChannelName>
    </ParticipantRowContainer>
  )
}

export default ChannelRow
