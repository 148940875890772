import Card from 'components/Card'
import styled from 'styled-components/macro'
import Heading from 'ui/Heading'
import { color } from 'utils/functions/colors'

export const ClaimCompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
`

export const ClaimTitle = styled(Heading).attrs({
  level: 'h1',
})`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const CompanyCardContainer = styled(Card).attrs({
  shadow: true,
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.1rem solid ${color('veryLightGray')};
  box-shadow: 0rem 0rem 1.5rem rgba(16, 21, 39, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 8.5rem);
  column-gap: 0.8rem;
`

export const CompanyInfo = styled.div`
  width: 100%;
  margin-left: 2.5rem;

  @media (max-width: 1410px) {
    margin-left: 0rem;
    margin-top: 2rem;
  }
  @media (max-width: 1410px) {
    margin-top: 0rem;
    margin-left: 1rem;
  }
  @media (max-width: 790px) {
    margin-top: 0rem;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.8rem;
`

export const Title = styled(Heading)`
  margin-top: 0;
  font-size: 2.4rem;
  line-height: normal;
`

export const CompanyInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.6rem;
  justify-items: stretch;

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`

export const CompanyInfoItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: ${color('darkBlue')};

  svg {
    margin-right: 1.6rem;
  }
`

export const Link = styled.a`
  color: var(--color-blue-primary);
  font-weight: bold;
  text-decoration: underline;
`

export const CompanyLogoWrapper = styled.div`
  padding: 0.8rem;
  border-radius: 100%;
  border: 0.1rem solid ${color('veryLightGray')};
  div[class*='logo'] {
    height: 10.8rem;
    width: 10.8rem;
    border: 0.1rem solid ${color('veryLightGray')};
  }
`
