import { FormattedMessage } from 'react-intl'

import styles from './PortfolioRow.module.scss'
import { HeaderContainer, SubHeader, Title } from './PortfoliosRowHeader.styles'

interface Props {
  titleId: string
}

const PortfoliosRowHeader = ({ titleId }: Props) => {
  return (
    <HeaderContainer>
      <SubHeader className={styles.subHeader}>
        <Title className={styles.portfolioIcon}>
          <FormattedMessage id={titleId} />
        </Title>
      </SubHeader>
    </HeaderContainer>
  )
}

export default PortfoliosRowHeader
