import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const AnalyticsContainer = styled.div`
  padding: 1rem;
`

export const TotalCardsContainer = styled.div`
  display: flex;
  gap: 1.4rem;
`

export const LoadingWrapper = styled.div`
  height: 20rem;
`

export const AnalyticNameColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.4rem;
`
export const AnalyticAvatar = styled.div``

export const AnalyticName = styled.span`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkGray')};
`
export const AnalyticIcon = styled(FontAwesomeIcon)`
  color: ${color('darkGray')};
`

export const LastActivityLabel = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 1.4rem;
  color: ${color('darkGray')};
`

export const ActivityDate = styled.span`
  font-weight: normal;
  font-size: 1.4rem;
  color: ${color('darkGray')};
`

export const GroupByWrapper = styled.div`
  width: 12rem;
`
export const AnalyticsChartContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LastActivityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ReshareIconContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;
  flex: 1;
  svg {
    transform: translateX(1rem);
    margin-left: 2rem;
    color: ${color('purple')};
    font-size: 1.6rem;
  }
  &:after {
    content: '';
    height: 4rem;
    width: 0.4rem;
    right: -2.1rem;
    top: -1.2rem;
    background: ${color('purple')};
    position: absolute;
  }
`
