import { Optional } from 'utils/types/common'
import {
  CreateDraftUpdatePayload,
  EditDraftUpdateItemPayload,
  EditUpdateItemPayload,
} from 'utils/types/updatePayloads'
import { EditDraftUpdatePayloadParams, EditUpdatePayloadParams } from './types'
import { getEditDraftPayload, getEditUpdateItemPayload } from './updates'

export const getCreateAnnouncementPayload =
  (): CreateDraftUpdatePayload<'announcement'> => {
    return {
      announcement: {
        loggingUpdateAttributes: {
          draftHash: {},
        },
      },
    }
  }

export const getEditAnnouncementPayload = (
  params: Optional<EditUpdatePayloadParams, 'body'>
): EditUpdateItemPayload => {
  return getEditUpdateItemPayload(params)
}

export const getEditDraftAnnouncementPayload = (
  params: EditDraftUpdatePayloadParams,
  suggestedUpdateId?: string
): EditDraftUpdateItemPayload => {
  return getEditDraftPayload(params, suggestedUpdateId)
}
