import React, { useContext } from 'react'

export interface IFormContext {
  prefix?: string
}

type Context<T extends IFormContext> = T

export const FormContext = React.createContext<Context<any>>({})

export const useForm = <T extends IFormContext = IFormContext>() => {
  return useContext<Context<T>>(FormContext)
}
