import React, { useRef } from 'react'

import Avatar from 'components/Avatar'
import Timeline from 'components/Timeline'
import dayjs from 'dayjs'
import { getInitials } from 'utils/functions/user'
import * as Styles from './LogRow.styles'
import { LogsBuilderFactory } from './models/logs-builders/LogsBuilderFactory'
import { Log, LogType } from './models/types'
import { ChangeSetFieldsType } from './models/logs-builders/LogsBuilder'

interface LogRowProps<T extends ChangeSetFieldsType> {
  log: Log<T>
  logType: LogType
  factoryParams?: any
}

const LogRow = <T extends ChangeSetFieldsType>({
  log,
  logType,
  factoryParams,
}: LogRowProps<T>) => {
  const logBuilder = useRef(
    LogsBuilderFactory.createLogsBuilder(log, logType, factoryParams)
  )
  const logoUrl = logBuilder.current?.getLogoUrl?.()
  const shouldDisplayInitials = useRef(
    !logoUrl || logoUrl?.includes?.('missing.png')
  )
  const logText = useRef(logBuilder.current.getText())

  const logTextNodes = useRef(
    React.Children.toArray(logText.current).filter(Boolean)
  )

  if (!logTextNodes.current.length) return null

  return (
    <Timeline.Item
      key={log.id}
      icon={
        <>
          <Avatar
            image={logoUrl}
            avatarStyle="avatarCircleLogs"
            initials={
              shouldDisplayInitials.current &&
              getInitials(logBuilder.current.getOwner())
            }
          />
          {logBuilder.current.getOperationIcon()}
        </>
      }
      updateType="log"
      padding="0 0 0 1.7rem"
      hideDottedLine
    >
      <Styles.Container>
        <b>{logBuilder.current.getOwner()}</b> {logTextNodes.current}
        <Styles.Date>
          {dayjs(logBuilder.current.getDate()).format('h:mm A')}
        </Styles.Date>
      </Styles.Container>
    </Timeline.Item>
  )
}

export default LogRow
