import styled from 'styled-components'
import { color as colorFn } from 'utils/functions/colors'
import { Color } from 'utils/theme'

export const Label = styled.div<{ color: Color }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.8rem;
  position: absolute;
  left: 1.8rem;
  top: -0.2rem;
  border-radius: 0rem 0rem 1rem 1rem;
  background: ${({ color }) => colorFn(color)};

  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.03em;
  color: white;

  text-transform: uppercase;
  z-index: 1;
`
