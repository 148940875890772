import { createContext, useContext } from 'react'
import { GroupTypes } from 'utils/constants/groups'
import {
  BillingAccount,
  GroupData,
  Plan,
  SetupAccount,
} from 'utils/types/signUp'
import {
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import { BillingAccountFormValues } from '../GetStarted'

export interface SignupContextProps {
  urlPrefix: string
  isCreatingNewGroup?: boolean
  isUpdatingUserInfo: boolean
  onCancel: () => void
  onSetupAccount: (values: SetupAccount) => Promise<void>
  onClickFounderSetup: () => void
  investorSignup: {
    name?: string
    email?: string
    isTrial?: boolean
    billingAccount?: BillingAccount
    planId?: SubscriptionPlan
    subscriptionId?: string
    clientSecret?: string
    paymentError: string
    onPlanSelected: (
      selectedPlan: Plan,
      selectedBillingPeriod: SubscriptionBillingPeriod
    ) => void

    onClickContinueToPayment: (
      values: BillingAccountFormValues
    ) => Promise<void>
    onCreateGroup: (groupData: GroupData) => Promise<void>
    onPaymentSuccess: (paymentIntentId: string) => Promise<void>
    goBackToPayment: (stripeClientSecret: string, error: string) => void
    onContinueForUserAddedFromGroupManagement: (
      values: SetupAccount
    ) => Promise<void> | undefined
    onChangeBillingPeriod: () => Promise<void>
    isLoadingChangeBillingPeriod: boolean
  }
}

export const SignupContext = createContext({} as SignupContextProps)

export const useSignupContext = () => useContext(SignupContext)

export const getGroupTypeFromPlanId = (selectedPlanId: SubscriptionPlan) => {
  if (selectedPlanId === SubscriptionPlan.PRO) {
    return GroupTypes.FUND_MANAGER
  }

  return GroupTypes.CLIENT
}
