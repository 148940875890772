import { useCallback } from 'react'
import { ChannelList, useChatContext } from 'stream-chat-react'
import type { AscDesc, Channel, ChannelFilters } from 'stream-chat'
import { useParams } from 'react-router-dom'

import { sortChannelsByPinned } from 'containers/Chat/helpers'
import {
  ChannelType,
  isTopicChannel,
  StreamChatType,
} from 'containers/Chat/types'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser } from 'selectors/auth'

import {
  useArchiveChannels,
  useChatSidebarContext,
} from 'containers/Chat/hooks'
import EmptyComponent from '../../../EmptyComponent'
import CreateChannel from '../../../CreateChannel'
import Paginator from '../Paginator'
import ChannelPreview from '../ChannelPreview/ChannelPreview'
import stylesModule from '../../ChatSidebar.module.scss'
import * as Styles from './ChannelCollapsible.styles'

const options = { state: true, presence: true, limit: 30 }
const sort = { last_message_at: -1 as AscDesc }

const ChannelPreviewWrapper = (previewProps) => {
  const { hideArchiveSidebar, hideSearchView } = useChatSidebarContext()

  return (
    <ChannelPreview
      {...previewProps}
      hideSearchView={() => {
        hideSearchView()
        hideArchiveSidebar()
      }}
    />
  )
}

interface ChannelCollapsibleProps {
  intlId: string
  createChannelIntlId?: string
  filters: ChannelFilters
  channelTypes: ChannelType[]
  showCreateChatModal: () => void
}

const ChannelCollapsible = ({
  intlId,
  filters,
  createChannelIntlId,
  channelTypes,
  showCreateChatModal,
}: ChannelCollapsibleProps) => {
  const { channelId } = useParams<{ channelId?: string }>()
  const { isChannelArchived } = useArchiveChannels()
  const { client } = useChatContext<StreamChatType>()
  const user = useAppSelector(getUser)

  const onCreateChat = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      showCreateChatModal()
    },
    [showCreateChatModal]
  )

  const renderChannels = useCallback(
    (cTypes: ChannelType[]) =>
      (
        channels: Channel<StreamChatType>[],
        channelPreview: (item: Channel<StreamChatType>) => React.ReactNode
      ) => {
        const alreadyAdded: Record<string, boolean> = {}
        const filteredChannels = channels.reduce<Channel<StreamChatType>[]>(
          (result, channel) => {
            if (isChannelArchived(channel.id)) {
              return result
            }

            if (!cTypes.includes(channel.type as ChannelType)) {
              return result
            }

            if (channel.state.members[user.id]) {
              if (isTopicChannel(channel.data)) {
                if (alreadyAdded[channel.data.entityId]) {
                  return result
                }

                alreadyAdded[channel.data.entityId] = true
              }

              return [...result, channel]
            }

            return result
          },
          []
        )

        return sortChannelsByPinned(
          filteredChannels,
          (client.user?.pinnedChannels || []).concat(
            client.user?.pinnedTopics || []
          )
        ).map((channel) => channelPreview(channel))
      },
    [
      client.user?.pinnedChannels,
      client.user?.pinnedTopics,
      isChannelArchived,
      user.id,
    ]
  )

  return (
    <Styles.Collapsible
      bodyPadding="none"
      id={intlId}
      initiallyExpanded
      preventReRender
      className={stylesModule.collapsible}
      headerActionButton={
        !createChannelIntlId ? (
          <Styles.IconContainer>
            <Styles.IconButton
              iconFamily="fal"
              icon="plus"
              buttonType="square"
              buttonSize="3.2rem"
              border={false}
              onClick={onCreateChat}
              className={stylesModule.createChat}
            />
          </Styles.IconContainer>
        ) : undefined
      }
    >
      <ChannelList<StreamChatType>
        filters={filters}
        sort={sort}
        options={options}
        EmptyStateIndicator={EmptyComponent}
        LoadingIndicator={EmptyComponent}
        renderChannels={renderChannels(channelTypes)}
        setActiveChannelOnMount={false}
        customActiveChannel={channelId}
        Preview={ChannelPreviewWrapper}
        Paginator={Paginator}
      />

      <CreateChannel
        createChannelIntlId={createChannelIntlId}
        onClick={showCreateChatModal}
      />
    </Styles.Collapsible>
  )
}

export default ChannelCollapsible
