import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const NewMessageIndicator = styled.div`
  width: 0.9rem;
  height: 0.9rem;
  background: ${color('green')};
  border-radius: 1rem;
  margin-left: 0.1rem;
`

export const LabelContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
