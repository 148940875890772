import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledTitle = styled.h4<{
  hoverState?: boolean
  grayTitle?: boolean
  maxWidth?: string
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-weight: 400;
  font-size: 1.6rem;
  max-width: ${(props) => props.maxWidth || '100%'};

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  color: ${(props) =>
    props.grayTitle ? color('secondaryGray')(props) : color('black')(props)};
  > svg {
    font-size: 1.8rem;
    margin-right: 0.7rem;
  }

  ${(props) =>
    props.hoverState &&
    css`
      color: ${color('primaryBlue')};
    `}
`
