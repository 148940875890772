import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import UpdateService from 'api/UpdateService'
import {
  castLoggingUpdateUser,
  castLoggingUpdateGroup,
  CUSTOM,
  getRemovedLoggingUpdateEntityIds,
  ReshareTokenSet,
} from 'utils/functions/updates'
import { Update } from 'utils/types/update'
import { useSetUpdatePermissions } from '../SetUpdatePermissions/useSetUpdatePermissions'
import { UpdateVisibility } from '../../../utils/constants/updates'

export const useReshareUpdate = (
  update?: Update,
  reshareTokenSet?: ReshareTokenSet
) => {
  const [reshareMessage, setReshareMessage] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { pathname } = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (reshareTokenSet) {
      setReshareMessage(reshareTokenSet.message ?? '')
    }
  }, [reshareTokenSet])

  const { items, sharedGroups, setSharedGroups, sharedUsers, setSharedUsers } =
    useSetUpdatePermissions({
      reshareTokenSet,
      initialVisibility: CUSTOM,
    })

  const reshareUpdate = async () => {
    setIsSubmitting(true)

    await UpdateService.reshareUpdate(
      update!.id,
      sharedUsers,
      sharedGroups,
      reshareMessage
    )

    const urlToRedirect = pathname.split('/').slice(0, -1).join('/')
    history.push(urlToRedirect)
    setIsSubmitting(false)
  }

  const editReshare = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsSubmitting(true)

    const removedGroups = getRemovedLoggingUpdateEntityIds(
      reshareTokenSet!.loggingUpdateEntities.filter(castLoggingUpdateGroup),
      sharedGroups,
      UpdateVisibility.CUSTOM
    )

    const removedUsers = getRemovedLoggingUpdateEntityIds(
      reshareTokenSet!.loggingUpdateEntities.filter(castLoggingUpdateUser),
      sharedUsers,
      UpdateVisibility.CUSTOM
    )
    await UpdateService.editReshare(
      reshareTokenSet!.token,
      reshareMessage,
      sharedGroups,
      sharedUsers,
      removedGroups,
      removedUsers
    )

    const urlToRedirect = pathname.split('/').slice(0, -1).join('/')
    history.push(urlToRedirect)
    setIsSubmitting(false)
  }

  const onChangeReshareMessage = (msg: string) => setReshareMessage(msg)

  return {
    reshareMessage,
    onChangeReshareMessage,
    reshareUpdate,
    editReshare,
    isSubmitting,
    permissions: {
      items,
      sharedGroups,
      setSharedGroups,
      sharedUsers,
      setSharedUsers,
    },
  }
}
