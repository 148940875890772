import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'

import {
  CUSTOM_GROUP,
  CUSTOM,
  ONLY_ME,
  PUBLIC,
  getPermissionItems,
} from 'utils/functions/updates'
import { PermissionItem } from 'utils/types/common'
import Title from 'components/Title'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import CheckboxTextContent from 'containers/Updates/SetUpdatePermissions/components/CheckboxTextContent'
import UpdateCardBox from 'containers/Updates/UpdateCardBox'
import { Update } from 'utils/types/update'

import {
  VisibilityContainer,
  TextContainer,
} from './ShowUpdatePermissions.styles'

interface ShowUpdatePermissionsProps {
  update?: Update
  showResharedPermissions?: boolean
}

const ShowUpdatePermissions: React.FC<ShowUpdatePermissionsProps> = ({
  update,
  showResharedPermissions = false,
}) => {
  const intl = useIntl()

  const [items, setItems] = useState<PermissionItem[]>([])

  useEffect(() => {
    if (update) {
      setItems(getPermissionItems(update, showResharedPermissions))
    }
  }, [update, showResharedPermissions])

  const visibility = update?.visibility ?? ''

  const getVisibilityText = () => {
    switch (visibility) {
      case PUBLIC:
        return (
          <TextContainer>
            <CheckboxTextContent
              type={PUBLIC}
              name={PUBLIC}
              icon={['far', 'users']}
            />
          </TextContainer>
        )
      case CUSTOM:
        return (
          <TextContainer>
            <CheckboxTextContent
              type={CUSTOM_GROUP}
              name={intl.formatMessage({
                id: 'updates.specificGroupsAndUsers',
              })}
              hideDescription
              icon={['far', 'user-lock']}
            />
          </TextContainer>
        )
      default:
        return (
          <TextContainer>
            <CheckboxTextContent
              type={ONLY_ME}
              name={ONLY_ME}
              icon={['far', 'user']}
            />
          </TextContainer>
        )
    }
  }

  return (
    <UpdateCardBox>
      <Title
        title={intl.formatMessage({ id: 'updates.permissions' })}
        icon={['far', 'lock-alt']}
      />
      {getVisibilityText()}
      {(visibility === CUSTOM || showResharedPermissions) && (
        <VisibilityContainer>
          <BoxGroupsAndUsersChips
            items={items}
            canDelete={false}
            loading={!update}
          />
        </VisibilityContainer>
      )}
    </UpdateCardBox>
  )
}

ShowUpdatePermissions.defaultProps = {
  update: undefined,
  showResharedPermissions: false,
}

export default ShowUpdatePermissions
