export const DefaultPdf = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="file-pdf 1" clipPath="url(#clip0_7525_168780)">
      <path
        id="Vector"
        d="M10.6695 3.04175L8.70312 1.07534C8.49219 0.864404 8.20625 0.744873 7.90859 0.744873H3.125C2.50391 0.747217 2 1.25112 2 1.87222V11.6222C2 12.2433 2.50391 12.7472 3.125 12.7472H9.875C10.4961 12.7472 11 12.2433 11 11.6222V3.83862C11 3.54097 10.8805 3.25269 10.6695 3.04175ZM9.78359 3.74722H8V1.96362L9.78359 3.74722ZM3.125 11.6222V1.87222H6.875V4.30972C6.875 4.62144 7.12578 4.87222 7.4375 4.87222H9.875V11.6222H3.125ZM8.98906 8.25425C8.70313 7.973 7.8875 8.05034 7.47969 8.1019C7.07656 7.85581 6.80703 7.51597 6.61719 7.01675C6.70859 6.6394 6.85391 6.06519 6.74375 5.70425C6.64531 5.09019 5.85781 5.15112 5.74531 5.56597C5.64219 5.94331 5.73594 6.46831 5.90938 7.13862C5.675 7.69878 5.32578 8.45112 5.07969 8.88237C4.61094 9.12378 3.97813 9.49644 3.88438 9.96519C3.80703 10.3355 4.49375 11.2589 5.66797 9.23394C6.19297 9.0605 6.76484 8.84722 7.27109 8.76284C7.71406 9.0019 8.23203 9.16128 8.57891 9.16128C9.17656 9.16128 9.23516 8.50034 8.98906 8.25425ZM4.34609 10.0777C4.46563 9.75659 4.92031 9.38628 5.05859 9.25737C4.61328 9.96753 4.34609 10.0941 4.34609 10.0777ZM6.25859 5.6105C6.43203 5.6105 6.41563 6.36284 6.30078 6.56675C6.19766 6.24097 6.2 5.6105 6.25859 5.6105ZM5.68672 8.81206C5.91406 8.41597 6.10859 7.94487 6.26562 7.53003C6.46016 7.88394 6.70859 8.16753 6.97109 8.36206C6.48359 8.46284 6.05938 8.66909 5.68672 8.81206ZM8.77109 8.69487C8.77109 8.69487 8.65391 8.8355 7.89688 8.51206C8.71953 8.45112 8.85547 8.63862 8.77109 8.69487Z"
        fill="#313748"
      />
    </g>
    <defs>
      <clipPath id="clip0_7525_168780">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.74707)"
        />
      </clipPath>
    </defs>
  </svg>
)
