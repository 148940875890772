import React from 'react'
import { useIntl } from 'react-intl'

import Button from 'ui/Button'

interface DiscardOrSaveProps {
  onSave?: (event: React.FormEvent<HTMLFormElement>) => void
  onCancel?: (event: React.MouseEvent<HTMLFormElement>) => void
  onDelete?: (event: React.MouseEvent<HTMLFormElement>) => void
  deleteEnabled?: boolean
  isSubmitting?: boolean
  confirmDisabled?: boolean
  confirmLabel?: string
  cancelLabel?: string
}

const DiscardOrSave: React.FC<DiscardOrSaveProps> = ({
  onCancel,
  onSave,
  onDelete,
  confirmDisabled,
  deleteEnabled,
  isSubmitting,
  confirmLabel,
  cancelLabel,
}) => {
  const intl = useIntl()

  return (
    <>
      {deleteEnabled && (
        <Button danger onClick={onDelete} disabled={isSubmitting}>
          {intl.messages['updates.delete']}
        </Button>
      )}
      <Button onClick={onCancel} disabled={isSubmitting}>
        {cancelLabel || intl.messages['updates.discard']}
      </Button>
      <Button
        primary
        onClick={onSave}
        disabled={isSubmitting || confirmDisabled}
      >
        {confirmLabel || intl.messages['updates.save']}
      </Button>
    </>
  )
}

DiscardOrSave.defaultProps = {
  deleteEnabled: false,
  confirmDisabled: false,
  onDelete: () => {},
  isSubmitting: false,
  confirmLabel: '',
  cancelLabel: '',
}

export default DiscardOrSave
