import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'

import Toast from 'components/Toast'
import EmailService from 'api/EmailService'

const useDuplicateEmail = (emailId) => {
  const history = useHistory()
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState(false)

  const duplicateEmail = async () => {
    setIsLoading(true)
    try {
      const iue = await EmailService.duplicateEmail(emailId)
      if (iue) {
        history.push(`/emails/${iue.loggingUpdate.id}/draft`)
      }
    } catch (error) {
      Toast.display(intl.messages['showEmail.duplicateEmailError'], 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return {
    duplicateEmail,
    isLoading,
  }
}

export default useDuplicateEmail
