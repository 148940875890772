import React, { useCallback } from 'react'
import { useField } from 'components/Form/hooks'
import RichTextArea from 'components/RichTextAreaV2'

interface TextAreaProps {
  id: string
  name: string
  label: string
  title: string
  placeholder?: string
  rows?: number
  disabled?: boolean
  [x: string]: any
}

const TextArea: React.FC<TextAreaProps> = ({ name, ...rest }) => {
  const [field, meta, helpers] = useField(name)
  const onChange = useCallback(
    (value: string) => {
      helpers.setValue(value)
    },
    [helpers]
  )

  return (
    <RichTextArea
      initialValue={field.value}
      onChange={onChange}
      error={meta.error}
      {...rest}
    />
  )
}

export default TextArea
