import { isActingAsClient } from 'selectors/auth'
import { getCurrentCompany } from 'selectors/company'
import { store } from 'store'
import history from 'utils/history'

export const checkUpdatePermissionError = (error) => {
  if (error.status === 403 || error.status === 404) {
    const appState = store.getState()
    const currentCompany = getCurrentCompany(appState)
    if (isActingAsClient(appState) && currentCompany?.id) {
      history.push(`/companies/${currentCompany.id}`)
    } else {
      history.push('/')
    }
    return true
  }

  return false
}

export const checkPortfolioPermissionError = (error) => {
  if (error.status === 403) {
    const appState = store.getState()
    if (isActingAsClient(appState)) {
      history.push('/portfolios')
    } else {
      history.push('/')
    }
    return true
  }

  return false
}

export const checkMetricPermissionError = (error) => {
  if (error.status === 403) {
    history.push('/metrics')
    return true
  }

  return false
}
