import { useEffect, useState } from 'react'

import { useChannelStateContext, useChatContext } from 'stream-chat-react'
import type { Channel } from 'stream-chat'

import { StreamChatType } from 'containers/Chat/types'

export const useIsChannelMuted = (channel?: Channel<StreamChatType>) => {
  const { client } = useChatContext<StreamChatType>()
  const { channel: contextChannel } = useChannelStateContext<StreamChatType>()
  const channelToUse = channel || contextChannel
  const [muted, setMuted] = useState(channelToUse.muteStatus())

  useEffect(() => {
    const handleEvent = () => {
      setMuted(channelToUse.muteStatus())
    }
    client.on('notification.channel_mutes_updated', handleEvent)

    return () => client.off('notification.channel_mutes_updated', handleEvent)
  }, [channelToUse, client])

  return muted
}
