import { FormattedMessage } from 'react-intl'
import * as Styles from './NoFundsAdded.styles'

const NoFundsAdded = () => {
  return (
    <Styles.NoFundsAdded>
      <FormattedMessage id="investorManagement.list.noFundsYet" />
    </Styles.NoFundsAdded>
  )
}

export default NoFundsAdded
