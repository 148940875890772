import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Item } from 'containers/InvestorManagement/components'
import dayjs from 'dayjs'
import { removeHttp } from 'utils/functions/strings'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupCompaniesIds } from 'selectors/auth'
import {
  AdditionInformation,
  AdditionInformationWrapper,
  EditCompanyButton,
} from './HeaderAdditionalInformation.styles'

interface Props {
  companyInfo: any
}

const HeaderAdditionalInformation = ({ companyInfo }: Props) => {
  const intl = useIntl()
  const history = useHistory()
  const location = useLocation()

  const groupCompanies = useAppSelector(getCurrentGroupCompaniesIds)
  const employeesCount =
    companyInfo.employeesNumber || companyInfo.employees.length

  const canEdit = useMemo(
    () => groupCompanies.some((companyId) => companyId === companyInfo.id),
    [companyInfo.id, groupCompanies]
  )

  const redirectToEditCompanyProfile = () => {
    const currentPath = location.pathname
    const relativePath = 'edit'

    if (currentPath.includes(relativePath)) return

    const newPath = `${currentPath}/${relativePath}`

    history.push(newPath, { companyId: companyInfo.id })
  }

  return (
    <AdditionInformationWrapper>
      <AdditionInformation>
        {companyInfo.website && (
          <Item
            isDark
            icon={['far', 'link']}
            value={removeHttp(companyInfo.website)}
            type="darkLink"
            href={companyInfo.website}
            maxLines={1}
            id="company_website"
            topOffset={40}
          />
        )}

        {companyInfo.primaryLocation?.formattedAddress && (
          <Item
            icon={['far', 'map-marker-alt']}
            value={companyInfo.primaryLocation.formattedAddress}
            type="text"
          />
        )}

        {companyInfo.founded && (
          <Item
            icon={['far', 'birthday-cake']}
            value={`Founded ${dayjs(companyInfo.founded).format('MMMM YYYY')}`}
            type="text"
          />
        )}

        {employeesCount > 0 && (
          <Item
            icon={['far', 'users']}
            value={
              employeesCount > 1
                ? intl.formatMessage(
                    { id: 'profiles.employees' },
                    { employeesCount }
                  )
                : intl.formatMessage(
                    { id: 'profiles.employee' },
                    { employeesCount }
                  )
            }
            type="text"
          />
        )}

        {companyInfo.legalStructure?.name && (
          <Item
            icon={['far', 'list-ul']}
            value={companyInfo.legalStructure.name}
            type="text"
          />
        )}

        {!!companyInfo.industries?.length && (
          <Item
            isDark
            icon={['far', 'industry-alt']}
            value={companyInfo.industries.map((ind) => ind.name).join(', ')}
            type="tooltip"
            maxLines={3}
          />
        )}
      </AdditionInformation>
      {canEdit && (
        <EditCompanyButton secondary onClick={redirectToEditCompanyProfile}>
          {intl.formatMessage({ id: 'companyProfile.editProfile' })}
        </EditCompanyButton>
      )}
    </AdditionInformationWrapper>
  )
}

export default HeaderAdditionalInformation
