import styled from 'styled-components'

export const SkeletonUpdateCardWrapper = styled.div`
  border-radius: 0.8rem;
  box-shadow: 0 0 1.5rem rgba(16, 21, 39, 0.1);
  display: flex;
  flex-direction: column;
  padding: 2.2rem;
  width: 100%;
`
