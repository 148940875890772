import React from 'react'
import { useChatContext } from 'stream-chat-react'

import Avatar from 'components/Avatar'
import TooltipContent from 'containers/Chat/components/TooltipContent'
import { getInitials } from 'utils/functions/user'
import { AvatarInfo } from 'components/StackedAvatarList/StackedAvatarList'
import { AvatarRow } from 'components/StackedAvatarList/StackedAvatarList.styles'
import { StreamChatType } from 'containers/Chat/types'
import YouLabel from '../YouLabel'
import { TooltipUserName } from './ChatHeader.styles'

interface ChatHeaderMembersTooltipProps {
  avatars: AvatarInfo[]
}

const ChatHeaderMembersTooltip: React.FC<ChatHeaderMembersTooltipProps> = ({
  avatars,
}) => {
  const { client } = useChatContext<StreamChatType>()

  return (
    <TooltipContent>
      {avatars.map((avatar) => (
        <AvatarRow>
          <Avatar
            key={avatar.id}
            image={avatar.image}
            initials={getInitials(avatar.name)}
            avatarStyle="avatarCircleXS"
          />
          <TooltipUserName>
            {avatar.name} {client.userID === avatar.id && <YouLabel />}
          </TooltipUserName>
        </AvatarRow>
      ))}
    </TooltipContent>
  )
}

export default ChatHeaderMembersTooltip
