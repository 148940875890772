import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CardContainer = styled.div`
  width: 10rem;
  background-color: ${color('white')};
  border-radius: 0.8rem;
  display: flex;
  height: fit-content;
  justify-content: center;
  padding: 2rem;
  padding: 3rem 2.5rem 3rem 2.5rem;

  > svg {
    cursor: pointer;
    font-size: 2rem;
    margin-right: 2rem;
  }
`
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    font-size: 2rem;
  }

  > svg:not(:last-child) {
    margin-bottom: 3rem;
  }
`
