interface Props {
  className?: string
}

export const MinimizeIcon = ({ className }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M6.35775 14.0002H3.02441C2.49398 14.0002 1.98527 13.7894 1.6102 13.4144C1.23513 13.0393 1.02441 12.5306 1.02441 12.0002V3.3335C1.02441 2.80306 1.23513 2.29436 1.6102 1.91928C1.98527 1.54421 2.49398 1.3335 3.02441 1.3335H13.6911C14.2215 1.3335 14.7302 1.54421 15.1053 1.91928C15.4804 2.29436 15.6911 2.80306 15.6911 3.3335V8.00016C15.6911 8.17697 15.6208 8.34654 15.4958 8.47157C15.3708 8.59659 15.2012 8.66683 15.0244 8.66683C14.8476 8.66683 14.678 8.59659 14.553 8.47157C14.428 8.34654 14.3577 8.17697 14.3577 8.00016V3.3335C14.3577 3.15669 14.2875 2.98712 14.1625 2.86209C14.0375 2.73707 13.8679 2.66683 13.6911 2.66683H3.02441C2.8476 2.66683 2.67803 2.73707 2.55301 2.86209C2.42799 2.98712 2.35775 3.15669 2.35775 3.3335V12.0002C2.35775 12.177 2.42799 12.3465 2.55301 12.4716C2.67803 12.5966 2.8476 12.6668 3.02441 12.6668H6.35775C6.53456 12.6668 6.70413 12.7371 6.82915 12.8621C6.95418 12.9871 7.02441 13.1567 7.02441 13.3335C7.02441 13.5103 6.95418 13.6799 6.82915 13.8049C6.70413 13.9299 6.53456 14.0002 6.35775 14.0002Z"
      fill="black"
    />
    <path
      d="M15.0247 14.6668H8.35807C8.18126 14.6668 8.01169 14.5966 7.88667 14.4716C7.76164 14.3465 7.69141 14.177 7.69141 14.0002V10.0002C7.69141 9.82335 7.76164 9.65378 7.88667 9.52876C8.01169 9.40373 8.18126 9.3335 8.35807 9.3335H15.0247C15.2016 9.3335 15.3711 9.40373 15.4961 9.52876C15.6212 9.65378 15.6914 9.82335 15.6914 10.0002V14.0002C15.6914 14.177 15.6212 14.3465 15.4961 14.4716C15.3711 14.5966 15.2016 14.6668 15.0247 14.6668ZM9.02474 13.3335H14.3581V10.6668H9.02474V13.3335Z"
      fill="black"
    />
    <path
      d="M6.8287 4.58627C6.65189 4.58627 6.48232 4.65651 6.3573 4.78153C6.23227 4.90656 6.16203 5.07613 6.16203 5.25294V5.52894L4.16203 3.52894C4.0363 3.4075 3.8679 3.3403 3.6931 3.34182C3.5183 3.34334 3.35109 3.41345 3.22749 3.53706C3.10388 3.66066 3.03377 3.82787 3.03225 4.00267C3.03073 4.17747 3.09793 4.34587 3.21937 4.4716L5.21937 6.4716H4.94337C4.76656 6.4716 4.59699 6.54184 4.47196 6.66687C4.34694 6.79189 4.2767 6.96146 4.2767 7.13827C4.2767 7.31508 4.34694 7.48465 4.47196 7.60968C4.59699 7.7347 4.76656 7.80494 4.94337 7.80494H6.8287C7.00551 7.80494 7.17508 7.7347 7.30011 7.60968C7.42513 7.48465 7.49537 7.31508 7.49537 7.13827V5.25294C7.49537 5.07613 7.42513 4.90656 7.30011 4.78153C7.17508 4.65651 7.00551 4.58627 6.8287 4.58627Z"
      fill="black"
    />
  </svg>
)
