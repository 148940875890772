import { FormattedMessage } from 'react-intl'
import InputLabel from 'ui/InputLabel'
import { instrumentFieldIsDefined } from 'utils/functions/transactions'
import UpdateText from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import { Content, Row } from '../ShowTransactionContent.styles'

const BasicTransactionContent = ({ text }: { text?: string }) => {
  if (!instrumentFieldIsDefined(text)) {
    return null
  }
  return (
    <Row gridColumn="1 / 3">
      <InputLabel marginLeft="1.4rem">
        <FormattedMessage id="updates.description" />
      </InputLabel>
      <Content>
        <UpdateText text={text} />
      </Content>
    </Row>
  )
}

export default BasicTransactionContent
