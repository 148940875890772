/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'

const useEffectSkipFirstRender = (effect, dependencies, cleanup) => {
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      effect()
    }
  }, dependencies)

  return cleanup?.()
}

export default useEffectSkipFirstRender
