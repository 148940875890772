import { Filters } from 'api/InvestorManagementService'

export const QUERIES = {
  INVESTORS: 'investors',
  INVESTOR: 'investor',
} as const

export const investorsKeys = {
  all: () => [QUERIES.INVESTORS] as const,
  allWithFilters: (filters: Filters) => [QUERIES.INVESTORS, filters] as const,
  byId: (id: string) => [QUERIES.INVESTOR, id] as const,
}
