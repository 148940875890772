import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import ButtonToStyle from 'ui/Button'

export const Container = styled.div<{
  padding: string
  centerVertically?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 0.8rem;
  padding: ${({ padding }) => padding};

  ${({ centerVertically }) =>
    centerVertically &&
    css`
      height: 100%;
      justify-content: center;
    `}
`

export const Title = styled.div`
  font-size: 1.8rem;
  font-style: normal;
  line-height: normal;
  color: ${color('gray')};
  display: flex;
  gap: 0.6rem;
  align-items: center;
`

export const Subtitle = styled.div`
  font-size: 1.6rem;
  font-style: normal;
  line-height: normal;
  color: ${color('lightGray')};
  max-width: 62.7rem;
  text-align: center;
`

export const Button = styled(ButtonToStyle)`
  margin-top: 0.8rem;
`
