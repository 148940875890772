import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const Container = styled.button`
  display: flex;
  width: 100%;
  gap: 1.3rem;
  justify-content: flex-start;
  padding: 1rem 1rem 1rem 7.2rem;
  :focus {
    outline: none;
  }

  :hover {
    background-color: ${color('chatMessageHover')};
  }

  @media ${maxSize.xsm} {
    padding-left: 2rem;
  }
`
