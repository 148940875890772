import React, { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { useChatContext, useMessageContext } from 'stream-chat-react'
import Modal from 'components/Modal'
import { StreamChatType } from 'containers/Chat/types'
import Button from 'ui/Button'
import * as Styles from './DeleteMessageModal.styles'

enum DeleteFor {
  EVERYONE = 'everyone',
  YOU = 'you',
}

interface DeleteMessageModalProps {
  show: boolean
  onHide: () => void
}

const DeleteMessageModal: React.FC<DeleteMessageModalProps> = ({
  show,
  onHide,
}) => {
  const intl = useIntl()
  const { client } = useChatContext()
  const { message } = useMessageContext<StreamChatType>()
  const [deleteFor, setDeleteFor] = useState<DeleteFor>(DeleteFor.EVERYONE)

  const onDelete = useCallback(async () => {
    if (deleteFor === DeleteFor.EVERYONE) {
      await client.deleteMessage(message.id)
    } else {
      await client.partialUpdateMessage(message.id, {
        set: {
          selfDeletedIds: [...(message.selfDeletedIds || []), client.userID],
        },
      })
    }
  }, [client, deleteFor, message.id, message.selfDeletedIds])

  return (
    <Styles.StyledModal show={show} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <Modal.H2Title>
          {intl.formatMessage({ id: 'chat.deleteMessageModal.removeMessage' })}
        </Modal.H2Title>
      </Modal.Header>
      <Modal.Body>
        <Styles.Title>
          {intl.formatMessage({ id: 'chat.deleteMessageModal.removeFor' })}
        </Styles.Title>
        <Styles.Checkbox
          id={DeleteFor.EVERYONE}
          name={DeleteFor.EVERYONE}
          checked={deleteFor === DeleteFor.EVERYONE}
          value={deleteFor}
          mode="custom"
          type="radio"
          onChange={() => setDeleteFor(DeleteFor.EVERYONE)}
        >
          <Styles.Label>
            {intl.formatMessage({
              id: 'chat.deleteMessageModal.removeForEveryone',
            })}
          </Styles.Label>
          <Styles.Description>
            {intl.formatMessage({
              id: 'chat.deleteMessageModal.removeForEveryoneDescription',
            })}
          </Styles.Description>
        </Styles.Checkbox>
        <Styles.Checkbox
          id={DeleteFor.YOU}
          name={DeleteFor.YOU}
          checked={deleteFor === DeleteFor.YOU}
          value={deleteFor}
          mode="custom"
          type="radio"
          onChange={() => setDeleteFor(DeleteFor.YOU)}
        >
          <Styles.Label>
            {intl.formatMessage({
              id: 'chat.deleteMessageModal.removeForYou',
            })}
          </Styles.Label>
          <Styles.Description>
            {intl.formatMessage({
              id: 'chat.deleteMessageModal.removeForYouDescription',
            })}
          </Styles.Description>
        </Styles.Checkbox>
      </Modal.Body>
      <Modal.RowFooter>
        <Button secondary onClick={onHide}>
          {intl.formatMessage({ id: 'general.cancel' })}
        </Button>
        <Button onClick={onDelete} danger>
          {intl.formatMessage({ id: 'common.remove' })}
        </Button>
      </Modal.RowFooter>
    </Styles.StyledModal>
  )
}

export default DeleteMessageModal
