import React, { useId, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Channel } from 'stream-chat'

import Toast from 'components/Toast'
import { ReactComponent as Maximize } from 'assets/images/maximize.svg'
import { StreamChatType } from 'containers/Chat/types'
import IconButton from 'ui/IconButton'
import OptionsDropdown from 'ui/OptionsDropdown'
import { CHAT_POPUP_HEADER_HEIGHT } from 'utils/constants/chat'
import AddParticipantsModal from '../AddParticipantsModal'
import * as Styles from '../ChatHeader/ChatHeader.styles'
import ChatHeaderMembersTooltip from '../ChatHeader/ChatHeaderMembersTooltip'
import { useChatHeader } from '../ChatHeader/useChatHeader'
import {
  Tooltip,
  TooltipContent,
} from '../ChatSidebar/components/ChannelPreview/ChannelPreview.styles'
import CountBadge from '../CountBadge/CountBadge'
import LeaveModal from '../LeaveChannelModal'
import MuteChatModal from '../MuteChatModal'
import { UndoArchive } from '../UndoArchive'
import YouLabel from '../YouLabel/YouLabel'
import * as PopupStyles from './ChatPopupHeader.styles'
import ChatPopupHeaderIcon from './ChatPopupHeaderIcon'
import { useChatPopupHeader } from './useChatPopupHeader'

const ChatPopupHeader: React.FC<{
  channel: Channel<StreamChatType>
  isMaximized?: boolean
}> = ({ channel, isMaximized = true }) => {
  const intl = useIntl()

  const {
    members,
    isChannelModerator,
    chatTitle,
    isUserPersonalChat,
    isDirectMessage,
    addParticipantsModal,
    membersForAvatars,
    muteModal,
    leaveModal,
    isChannelMuted,
    isATopicChannel,
    handleMute,
    isArchived,
    toggleArchiveChannel,
    unarchiveChannel,
  } = useChatHeader(channel)

  const {
    onMaximizeChannel,
    onCloseChannel,
    onMinimizeChannel,
    onOpenChat,
    unreadCount,
    maximizedChatId,
  } = useChatPopupHeader(channel)
  const id = useId()

  const tooltipKey = `channel.id}_${id}${
    isMaximized ? 'maximized' : 'minimized'
  }_${maximizedChatId || 'none-maximized'}`

  const tooltipProps = useMemo(
    () => ({
      place: 'bottom',
      topOffset: 45,
      leftOffset: 90,
      backgroundColor: 'transparent',
      delayShow: 500,
      inline: true,
    }),
    []
  )

  const onUndoArchive = () => {
    unarchiveChannel(channel.id!)
    onMaximizeChannel()
  }

  const onToggleArchiveChannel = async () => {
    toggleArchiveChannel(channel.id!)
    onCloseChannel()

    if (!isArchived) {
      Toast.displayIntl(
        'chat.conversationArchived',
        undefined,
        5000,
        UndoArchive(onUndoArchive)
      )
    }
  }

  return (
    <>
      <Styles.Container
        height={`${CHAT_POPUP_HEADER_HEIGHT}px`}
        as="button"
        onClick={(event) => {
          event.stopPropagation()
          if (isMaximized) {
            onMinimizeChannel()
          } else {
            onMaximizeChannel()
          }
        }}
        hasNewMessages={!isMaximized && unreadCount > 0}
        isMinimized={!isMaximized}
        preventResponsive
      >
        <Styles.LeftContainer gap=".8rem">
          <PopupStyles.IconContainer>
            <ChatPopupHeaderIcon
              tooltipKey={tooltipKey}
              channel={channel}
              members={members}
            />
          </PopupStyles.IconContainer>

          <PopupStyles.ChatTitleContainer>
            <PopupStyles.Names isATopicChannel={isATopicChannel}>
              <Tooltip
                key={`chat_popup_title_${tooltipKey}`}
                id={`chat_popup_title_${tooltipKey}`}
                text={
                  <TooltipContent>
                    {chatTitle}
                    {isUserPersonalChat && <YouLabel />}
                  </TooltipContent>
                }
                ellipsis
                {...tooltipProps}
                leftOffset={50}
              >
                {chatTitle}
                {isUserPersonalChat && <YouLabel />}
              </Tooltip>
            </PopupStyles.Names>
            {!isDirectMessage && (
              <Tooltip
                key={`chat_popup_members_${tooltipKey}`}
                id={`chat_popup_members_${tooltipKey}`}
                text={<ChatHeaderMembersTooltip avatars={membersForAvatars} />}
                {...tooltipProps}
                topOffset={isMaximized ? 45 : -20}
                leftOffset={isMaximized ? 140 : 100}
              >
                <PopupStyles.CountMembers>
                  <FormattedMessage
                    id="chat.countMembers"
                    values={{ count: members.length + 1 }}
                  />
                </PopupStyles.CountMembers>
              </Tooltip>
            )}
          </PopupStyles.ChatTitleContainer>
        </Styles.LeftContainer>
        <Styles.RightContainer gap="0.5rem">
          {isMaximized && (
            <IconButton
              onClick={onOpenChat}
              customIcon={<Maximize />}
              buttonType="square"
              buttonSize="3.2rem"
              border={false}
              borderOnFocus={false}
              primary
            />
          )}

          {isMaximized && (
            <OptionsDropdown
              buttonType="square"
              buttonSize="3.2rem"
              icon={['far', 'ellipsis-v-alt']}
            >
              {!isArchived && isChannelModerator && (
                <OptionsDropdown.Item
                  label={intl.formatMessage({ id: 'chat.addParticipants' })}
                  icon={['far', 'user-plus']}
                  onSelectOption={addParticipantsModal.onShow}
                />
              )}
              {!isArchived &&
                (isChannelMuted ? (
                  <OptionsDropdown.Item
                    label={intl.formatMessage({ id: 'chat.unmute' })}
                    icon={['far', 'bell']}
                    onSelectOption={handleMute}
                  />
                ) : (
                  <OptionsDropdown.Item
                    label={intl.formatMessage({ id: 'chat.mute' })}
                    icon={['far', 'bell-slash']}
                    onSelectOption={handleMute}
                  />
                ))}
              {isATopicChannel && (
                <OptionsDropdown.Item
                  label={intl.formatMessage({
                    id: isArchived ? 'chat.unarchive' : 'chat.archive',
                  })}
                  icon={['far', isArchived ? 'box-open' : 'archive']}
                  onSelectOption={onToggleArchiveChannel}
                />
              )}

              {isATopicChannel && (
                <OptionsDropdown.Item
                  label={intl.formatMessage({ id: 'chat.leave' })}
                  icon={['far', 'arrow-from-left']}
                  onSelectOption={leaveModal.onShow}
                />
              )}
            </OptionsDropdown>
          )}

          {!isMaximized && <CountBadge unreadCount={unreadCount} />}

          <PopupStyles.ClosePopupIconButton
            onClick={(event) => {
              event.stopPropagation()
              onCloseChannel()
            }}
            icon="times"
            buttonSize="3.2rem"
            border={false}
            borderOnFocus={false}
            primary
          />
        </Styles.RightContainer>
      </Styles.Container>

      {isMaximized && (
        <>
          <AddParticipantsModal
            show={addParticipantsModal.show}
            onHide={addParticipantsModal.onHide}
            currentMemberIds={members.map(({ user }) => user!.id)}
          />

          <MuteChatModal show={muteModal.show} onHide={muteModal.onHide} />

          <LeaveModal show={leaveModal.show} onHide={leaveModal.onHide} />
        </>
      )}
    </>
  )
}

export default ChatPopupHeader
