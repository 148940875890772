import IconButton from 'ui/IconButton'
import { Nullable } from 'utils/types/common'
import { CHART_MODE } from '../../types'

import * as Styles from './ChartTypeSelector.styles'

interface ChartTypeSelectorProps {
  mode: Nullable<CHART_MODE>
  onChange: (value: CHART_MODE) => void
}

const ChartTypeSelector: React.FC<ChartTypeSelectorProps> = ({
  mode,
  onChange,
}) => {
  return (
    <Styles.ChartTypeSelectorContainer>
      <IconButton
        data-testid="chart-type-line"
        selected={mode === CHART_MODE.LINE}
        onClick={() => onChange(CHART_MODE.LINE)}
        icon="chart-line-up"
        iconFamily="fal"
        border={false}
        roundSquare
        buttonSize="3.6rem"
        fontSize="1.6rem"
      />
      <IconButton
        data-testid="chart-type-bar"
        selected={mode === CHART_MODE.BAR}
        onClick={() => onChange(CHART_MODE.BAR)}
        icon="chart-column"
        iconFamily="fal"
        border={false}
        roundSquare
        buttonSize="3.6rem"
        fontSize="1.6rem"
      />
    </Styles.ChartTypeSelectorContainer>
  )
}

export default ChartTypeSelector
