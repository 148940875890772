import { FileViewEnum } from 'components/FileViewSelector'
import styled, { css, keyframes } from 'styled-components'
import { size as mediaSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

const skeletonShine = keyframes`
  to {
    background-position: 50rem 0;
  }
`

export const defaultBackground = ({
  disableAnimation,
}: {
  disableAnimation?: boolean
}) =>
  disableAnimation
    ? css`
        background-color: ${theme.colors.defaultSkeletonShine};
      `
    : css`
        background: linear-gradient(
          to right,
          ${theme.colors.defaultSkeletonShine} 0%,
          ${theme.colors.defaultSkeletonShine2} 20%,
          ${theme.colors.defaultSkeletonShine} 40%,
          ${theme.colors.defaultSkeletonShine} 100%
        );

        animation: ${skeletonShine} 4s linear 0s infinite normal forwards;
      `
interface SkeletonTableRowProps {
  columnSizes?: string[]
  marginLeft?: string
}

export const SkeletonTableRow = styled.div<SkeletonTableRowProps>`
  display: grid;
  max-width: 100%;
  overflow-x: hidden;
  grid-template-columns: ${({ columnSizes }) =>
    columnSizes ? columnSizes.join(' ') : '15rem 15rem 10rem 35rem auto'};
  margin-left: ${({ marginLeft }) => marginLeft || '0'};

  @media ${mediaSize.tabletLg} {
    grid-template-columns: ${({ columnSizes }) =>
      columnSizes ? columnSizes.join(' ') : '38.5rem 15rem 10rem 35rem auto'};
  }
`

export const SkeletonItemWrapper = styled.div<{
  height?: string
  borderRight?: boolean
  borderLeft?: boolean
  borderBottom?: boolean
  borderTop?: boolean
  border?: string
  gap?: string
  direction?: 'column' | 'row' | string
  alignItems?: 'center' | 'flex-start' | 'flex-end' | string
  justifyContent?:
    | 'center'
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | string
  width?: string
  marginLeft?: string
}>`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  gap: ${(props) => props.gap || '0.6rem'};
  height: ${(props) => props.height || ''};

  border-left: ${(props) =>
    props.borderLeft ? `0.3rem solid ${theme.colors.veryLightGray}` : '0'};
  border-right: ${(props) =>
    props.borderRight ? `0.3rem solid ${theme.colors.veryLightGray}` : '0'};
  border-bottom: ${(props) =>
    props.borderBottom ? `0.1rem solid ${theme.colors.veryLightGray}` : '0'};
  border-top: ${(props) =>
    props.borderTop ? `0.1rem solid ${theme.colors.veryLightGray}` : '0'};

  margin-left: ${(props) => props.marginLeft || '0'};

  ${(props) => props.width && `width: ${props.width}`};

  border-radius: ${(props) => props.border};
`

const getSkeletonAvatarMarginLeft = (marginLeft?: boolean | string): string => {
  if (marginLeft !== undefined) {
    return typeof marginLeft === 'string' ? marginLeft : '2.5rem'
  }
  return '0'
}

export const SkeletonAvatar = styled.div<{
  marginLeft?: boolean | string
  height?: string
  width?: string
  borderWidth?: string
  borderRadius?: string
  backgroundColor?: string
  skeletonShine?: string
  skeletonShine2?: string
  marginRight?: string
}>`
  margin-left: ${(props) => getSkeletonAvatarMarginLeft(props.marginLeft)};
  margin-right: ${(props) => props.marginRight || '0'};
  height: ${(props) => props.height || '2.8rem'};
  width: ${(props) => props.width || '2.8rem'};
  border-radius: ${(props) => props.borderRadius || '50%'};
  background-color: ${(props) => props.backgroundColor || `${color('white')}`};
  border: ${({ borderWidth }) => borderWidth || '0.2rem'} solid
    ${(props) => props.theme.colors.defaultSkeletonShine};
`

export const SkeletonAvatarWithAnimation = styled(SkeletonAvatar)`
  background: linear-gradient(
    to right,
    ${(props) => props.skeletonShine || theme.colors.defaultSkeletonShine} 0%,
    ${(props) => props.skeletonShine2 || theme.colors.defaultSkeletonShine2} 20%,
    ${(props) => props.skeletonShine || theme.colors.defaultSkeletonShine} 40%,
    ${(props) => props.skeletonShine || theme.colors.defaultSkeletonShine} 100%
  );

  animation: ${skeletonShine} 4s linear 0s infinite normal forwards;
`

export const SkeletonItem = styled.div<{
  width: string
  height: string
  marginLeft?: boolean
  skeletonShine?: string
  skeletonShine2?: string
  margin?: string
  minWidth?: string
  borderRadius?: string
  disableAnimation?: boolean
}>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  margin-left: ${(props) => (props.marginLeft ? '2.5rem' : '0')};
  border-radius: ${(props) => props.borderRadius || '0.9rem'};
  margin: ${(props) => props.margin || ''};

  ${(props) => defaultBackground({ disableAnimation: props.disableAnimation })}
`

export const SkeletonThumbnailContainer = styled.div<{ isSelected?: boolean }>`
  transition: box-shadow 0.2s ease-in-out, transform 0.2s;
  box-shadow: 0 0 0 0.1rem ${theme.colors.veryLightGray};
  border-radius: 0.8rem;
`

export const SkeletonThumbnailContentWrapper = styled.div<{
  size: FileViewEnum
}>`
  display: flex;
  background-color: white;
  align-items: center;
  gap: 3rem;
  border-radius: 0.8rem;
  flex-direction: column;
  height: 13.2rem;
  justify-content: center;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 13.2rem;
  box-shadow: 0rem 0rem 1.5rem rgba(16, 21, 39, 0.1);

  ${({ size }) =>
    size === FileViewEnum.LARGE &&
    css`
      height: 21rem;
      width: 21rem;
    `}
`

export const SkeletonNameContainer = styled.div`
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: ${color('darkGray')};
  text-align: center;
  padding: 0.8rem 0.8rem;
  width: 100%;
  grid-template-columns: 1fr auto;
  display: grid;
  align-items: center;
  padding: 0.8rem 0.8rem 1.2rem 0.8rem;
`

export const ImageSkeleton = styled.div`
  border-radius: 0.8rem;
  margin-bottom: 1.6rem;
  height: 21rem;
  ${defaultBackground({})}
`

export const SkeletonIcon = styled.div<{ width?: string; height?: string }>`
  width: ${(props) => props.width || '4rem'};
  height: ${(props) => props.height || '4rem'};
  border-radius: 50%;
  ${defaultBackground({})}
`

export const SkeletonVerticalSeparator = styled.div<{
  width?: string
  height?: string
}>`
  width: ${(props) => props.width || '0.1rem'};
  height: ${(props) => props.height || '100%'};
  background-color: ${color('veryLightGray')};
`

export const SkeletonHorizontalSeparator = styled.div<{
  width?: string
  height?: string
}>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '0.1rem'};
  background-color: ${color('veryLightGray')};
`
