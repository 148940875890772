import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory, useParams } from 'react-router-dom'

import { getCurrentCompany } from 'selectors/company'
import {
  getCurrentGroupId,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import { getCompanyInfo as getCompanyInfoUtil } from 'utils/functions/companies'
import { resetCurrentCompany, setCurrentCompany } from 'actions/companies'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import ResponseNormalizer from 'utils/functions/responseNormalizer'

const CompanyIdRoutes = ({ children }) => {
  const { companyId, fundId } = useParams()
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const dispatch = useAppDispatch()
  const history = useHistory()
  const currentCompany = useAppSelector(getCurrentCompany)
  const groupCompany = useGroupCompany()
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isFounder = useAppSelector(isActingAsFounder)

  const getCompanyInfo = useCallback(
    () => getCompanyInfoUtil(dispatch, companyId, history),
    [companyId, dispatch, history]
  )

  useEffect(() => {
    // If current company changed or switched groups
    // we need to refetch to bring tags in company profile as they are grouped - scoped

    const entityId = companyId || fundId
    const isDifferentCompany = currentCompany?.id !== entityId

    if (isDifferentCompany) {
      if (companyId) {
        getCompanyInfo(companyId)
      } else if (isFundManager || isFounder) {
        const companyHolding = ResponseNormalizer.normalizeHolding(groupCompany)
        dispatch(setCurrentCompany(companyHolding))
      }
    }

    return () => {
      if (groupCompany) {
        dispatch(setCurrentCompany(groupCompany))
      } else {
        dispatch(resetCurrentCompany())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, currentGroupId, groupCompany?.id])

  return children
}

CompanyIdRoutes.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default CompanyIdRoutes
