/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import InvestorManagementService, {
  Filters,
} from 'api/InvestorManagementService'

import Toast from 'components/Toast'

import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import { Investor, InvestorPayload } from 'utils/types/investors'
import { FundSummary } from 'utils/types/funds'
import { portfolioKeys } from 'utils/queries/portfolios'
import {
  investorsKeys,
  QUERIES as investorQueries,
} from 'utils/queries/investors'
import { CustomUseQueryOptions } from 'utils/types/common'

export type InvestorFilters = Omit<Filters, 'page'>

export const useInvestorsInfiniteQuery = (
  filters: InvestorFilters,
  queryOptions: UseInfiniteQueryOptions<{
    investors: Investor[]
    page: number
  }> = {}
) => {
  const {
    ref: infiniteScrollRef,
    onScreen,
    setInfiniteScrollEnabled,
  } = useInfiniteScroll()

  const [total, setTotal] = useState<number>()
  const query = useInfiniteQuery(
    investorsKeys.allWithFilters(filters),
    async ({ pageParam = 1 }) => {
      try {
        const { results: investors, total: totalInvestors } =
          await InvestorManagementService.fetchInvestors({
            ...filters,
            page: pageParam as number,
          })
        if (investors.length === 0) {
          setInfiniteScrollEnabled(false)
        }
        setTotal(totalInvestors)
        return {
          investors,
          page: pageParam as number,
        }
      } catch (err) {
        Toast.displayIntl('investorManagement.errors.fetchInvestors', 'error')
        return {
          investors: [],
          page: 1,
        }
      }
    },
    {
      getNextPageParam: (lastPage): number => {
        return lastPage.page + 1
      },
      ...queryOptions,
    }
  )

  useEffect(() => {
    if (onScreen) {
      query.fetchNextPage()
    }
  }, [onScreen])

  return {
    ...query,
    total,
    infiniteScrollRef,
  }
}

export const useInvestorQuery = (
  investorId: string,
  queryOptions: CustomUseQueryOptions<Investor> = {}
) => {
  return useQuery(
    investorsKeys.byId(investorId),
    async () => {
      try {
        return await InvestorManagementService.fetchInvestor(investorId)
      } catch (err) {
        Toast.displayIntl('investorManagement.errors.fetchInvestor', 'error')
        return null
      }
    },
    { ...queryOptions, staleTime: 500 }
  )
}

export const useInvestorFundPortfoliosSummaryQuery = (
  investorId: string,
  investorOnlyNumbers: boolean = false,
  queryOptions: CustomUseQueryOptions<FundSummary> = {}
) => {
  const queryClient = useQueryClient()

  const fetchFundSummary = () => {
    try {
      return InvestorManagementService.fetchFundPortfoliosSummary(
        investorOnlyNumbers
      )
    } catch (err) {
      Toast.displayIntl('investorManagement.errors.fetchInvestor', 'error')
      return null
    }
  }

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: portfolioKeys.fundPortfolioSummaryByInvestor(
        investorId,
        !investorOnlyNumbers
      ),
      queryFn: fetchFundSummary,
    })
  }, [])

  return useQuery(
    portfolioKeys.fundPortfolioSummaryByInvestor(
      investorId,
      investorOnlyNumbers
    ),
    fetchFundSummary,
    { ...queryOptions, staleTime: 500 }
  )
}

export const useCreateInvestorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (investorData: InvestorPayload) => {
      return InvestorManagementService.createInvestor(investorData)
    },
    {
      onSuccess: (newInvestor) => {
        queryClient.invalidateQueries([investorQueries.INVESTORS])
        queryClient.setQueryData(
          investorsKeys.byId(newInvestor.id),
          newInvestor
        )
      },
    }
  )
}

export const useEditInvestorMutation = (
  investorId: string,
  currentGroupId?: string
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (investorData: Partial<InvestorPayload>) => {
      return InvestorManagementService.editInvestor(
        investorId,
        investorData,
        currentGroupId
      )
    },
    {
      onSuccess: (newInvestor) => {
        queryClient.refetchQueries([investorQueries.INVESTORS])
        queryClient.setQueryData(investorsKeys.byId(investorId), newInvestor)
      },
    }
  )
}

export const useDeleteInvestorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(InvestorManagementService.deleteInvestor, {
    onSuccess: () => queryClient.invalidateQueries([investorQueries.INVESTORS]),
  })
}
