export interface Inbound {
  id: string
  email?: string
  inboundEmail: string
  groupManaged: boolean
  connectionStatus: InboundStatus
  lastConnectionCheck?: string
  lastConnection?: string
  firstConnection?: string
  isDefaultInbound: boolean
  userId?: string
}

export enum CreateInboundSteps {
  ACCOUNT_TYPE = 'account-type',
  EMAIL_ADDRESS = 'email-address',
  CONNECT = 'connect',
}

export enum AccountTypes {
  GMAIL = 'gmail',
  GOOGLE_GROUP = 'google-group',
}

export enum InboundStatus {
  ACTIVE = 'active',
  NOT_CONNECTED = 'not_connected',
  AWAITING_CONNECTION = 'awaiting',
  TESTING = 'testing',
}

export interface InboundForm {
  accountType: AccountTypes
  email: string
  allowMyGroupToReceiveSuggestedUpdates: boolean
}
