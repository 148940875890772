import { useIntl } from 'react-intl'
import AsyncSelect from 'react-select/async'

import { DropdownIndicator } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/components/DropdownIndicator'
import { Input } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/components/Input'
import { Loader } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/components/Loader'
import { NoOptionsMessage } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/components/NoOptionsMessage'
import { Option } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/components/Option'
import { SharedWithPill } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/components/SharedWithPill'
import { REACT_SELECT_STYLES } from 'containers/UpdatesV2/components/ShareSettingsModal/components/ShareWithDropdown/useShareWithDropdown'
import { Group } from './components/Group'
import { GroupHeading } from './components/GroupHeading'
import { ChannelMember, useChatMembersPicker } from './useChatMembersPicker'

type Props = {
  members: ChannelMember[]
  onMembersSelected: (members: ChannelMember[]) => void
  hiddenMemberIds?: string[]
}

const ChatMembersPicker = ({
  members,
  onMembersSelected,
  hiddenMemberIds = [],
}: Props) => {
  const intl = useIntl()

  const { loadOptions } = useChatMembersPicker(hiddenMemberIds)

  return (
    <AsyncSelect
      placeholder={intl.formatMessage({ id: 'chat.addUsers' })}
      closeMenuOnSelect={false}
      isMulti
      loadOptions={loadOptions}
      defaultOptions
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      components={{
        Input,
        NoOptionsMessage,
        DropdownIndicator,
        Option: Option(members),
        MultiValue: SharedWithPill,
        LoadingMessage: Loader,
        IndicatorSeparator: null,
        LoadingIndicator: null,
        GroupHeading,
        Group,
      }}
      hideSelectedOptions={false}
      onChange={onMembersSelected}
      value={members}
      styles={REACT_SELECT_STYLES}
      classNamePrefix="react-select"
      isClearable={false}
      maxMenuHeight={405}
    />
  )
}

export default ChatMembersPicker
