import Title from 'components/Title'
import dayjs from 'dayjs'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import InputLabel from 'ui/InputLabel'
import Separator from 'ui/Separator'
import {
  instrumentFieldIsDefined,
  instrumentHasData,
} from 'utils/functions/transactions'
import theme from 'utils/theme'
import { CreateTransactionFormValues } from 'utils/types/updateForm'
import UpdateText from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'
import {
  Content,
  Row,
  TransactionContent,
} from '../ShowTransactionContent.styles'

const WarrantsInstrumentContent = ({
  transaction,
}: {
  transaction: CreateTransactionFormValues
}) => {
  if (
    !instrumentFieldIsDefined(transaction.description) &&
    !instrumentHasData(transaction.instrument!, transaction)
  ) {
    return null
  }

  return (
    <TransactionContent>
      <Row gridColumn="1 / 3">
        <Title
          title={<FormattedMessage id="transactions.additionalInformation" />}
          color={theme.colors.lightGray}
        />
        <Separator />
      </Row>

      {instrumentFieldIsDefined(transaction.description) && (
        <Row gridColumn="1 / 3">
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="updates.description" />
          </InputLabel>
          <Content>
            <UpdateText text={transaction.description} />
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.vestingCommencementDate) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.vestingCommencementDate" />
          </InputLabel>
          <Content capitalize>
            {dayjs(transaction!.vestingCommencementDate).format('DD MMM, YYYY')}
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.expirationDate) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.expirationDate" />
          </InputLabel>
          <Content capitalize>
            {dayjs(transaction!.expirationDate).format('DD MMM, YYYY')}
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.strikePrice) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.strikePrice" />
          </InputLabel>
          <Content>
            $
            <FormattedNumber
              value={transaction!.strikePrice!}
              maximumFractionDigits={2}
            />
          </Content>
        </Row>
      )}

      {instrumentFieldIsDefined(transaction?.numberOfShares) && (
        <Row>
          <InputLabel marginLeft="1.4rem">
            <FormattedMessage id="transactions.numberOfShares" />
          </InputLabel>
          <Content>{transaction!.numberOfShares}</Content>
        </Row>
      )}
    </TransactionContent>
  )
}

export default WarrantsInstrumentContent
