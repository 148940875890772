import { FileContent } from 'utils/types/files'

import { BackgroundImage } from './ImagePreview.styles'
import { useImagePreview } from './useImagePreview'
import Loading from '../Loading'
import PreviewError from '../PreviewError'

interface ImagePreviewProps {
  file: FileContent
  errorComponent?: React.ReactElement
}

const ImagePreview = ({ file, errorComponent }: ImagePreviewProps) => {
  const { isLoading, hasError, onLoad, onError } = useImagePreview(file)

  return (
    <>
      {isLoading && <Loading />}
      {hasError ? (
        errorComponent || <PreviewError />
      ) : (
        <BackgroundImage src={file.url} onLoad={onLoad} onError={onError} />
      )}
    </>
  )
}

export default ImagePreview
