import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 0.4rem;
`

export const Item = styled.li<{ isOwnReaction?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.4rem 0.8rem;
  background: ${({ isOwnReaction }) =>
    isOwnReaction ? color('primaryBlue') : color('veryLightBlue', 0.1)};
  border-radius: 1.6rem;
  gap: 0.4rem;
  height: 2.4rem;
  cursor: pointer;
`

export const Count = styled.span<{
  isOwnReaction: boolean
}>`
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  text-align: center;
  color: ${({ isOwnReaction }) =>
    isOwnReaction ? 'white' : color('darkGray')};
`

export const ReactIcon = styled(Item)<{
  selected?: boolean
}>`
  font-size: 1.6rem;
  color: black;

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: inset 0 0 0 0.1rem ${color('primaryBlue')};
      color: ${color('primaryBlue')};
    `}
`

export const Emoji = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  line-height: 1;
`
