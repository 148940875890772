import { useIntl } from 'react-intl'

import Tooltip from 'components/Tooltip'
import { useField } from 'components/Form/hooks'
import { size } from 'utils/constants/breakpoint'
import theme from 'utils/theme'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

import { ConfidentialUpdate as StyledConfidentialUpdate } from './ConfidentialUpdate.styles'

const ConfidentialUpdate = () => {
  const intl = useIntl()
  const { matches: isVisible } = useMediaQuery(size.md)
  const [field] = useField<boolean>('permissions.confidentialUpdate')

  if (!isVisible || !field?.value) {
    return null
  }

  return (
    <Tooltip
      place="bottom"
      id="confidential-update"
      text={intl.formatMessage({ id: 'updates.header.confidentialUpdate' })}
      backgroundColor={theme.colors.white}
      color={theme.colors.gray}
      boxShadow={`0rem 0rem 1.5rem 0rem ${theme.colors.veryLightGray}`}
      border={`0.1rem solid ${theme.colors.veryLightGray}`}
      parentTooltipStyles={{ marginLeft: '-5rem' }}
    >
      <StyledConfidentialUpdate icon={['far', 'user-shield']} />
    </Tooltip>
  )
}

export default ConfidentialUpdate
