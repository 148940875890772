import styled from 'styled-components'

export const ViewSelector = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`

export const GridViewSelector = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

  button:last-child {
    position: absolute;
    top: 3rem;
  }
`

export const SelectorList = styled.div``
