import React, { PropsWithChildren } from 'react'

import { ReactComponent as ClockWorkLogo } from 'assets/images/signup-logo.svg'

import {
  ClockworkLogoStyles,
  CloseIcon,
  Container,
  Header,
  HeaderWrapper,
} from './AccountSetupLayout.styles'

interface AccountSetupLayoutProps {
  children: React.ReactNode
}

type AccountSetupLayoutType = React.FC<AccountSetupLayoutProps> & {
  Header: React.FC<HeaderProps>
  StickyHeader: React.FC<PropsWithChildren>
}

const AccountSetupLayout: AccountSetupLayoutType = ({
  children,
}: AccountSetupLayoutProps) => {
  return <Container>{children}</Container>
}

interface HeaderProps {
  onClose?: () => void
}

AccountSetupLayout.Header = ({ onClose }: HeaderProps) => {
  return (
    <Header justify={onClose ? 'space-between' : 'center'}>
      {onClose && <div />}
      <ClockworkLogoStyles>
        <ClockWorkLogo />
      </ClockworkLogoStyles>
      {onClose && (
        <CloseIcon
          icon="times"
          onClick={onClose}
          buttonSize="3.2rem"
          border={false}
        />
      )}
    </Header>
  )
}

AccountSetupLayout.StickyHeader = ({ children }: PropsWithChildren) => {
  return <HeaderWrapper>{children}</HeaderWrapper>
}

export default AccountSetupLayout
