import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Details = styled.div`
  background-color: ${color('white')};
  border-radius: 0 0 0.8rem 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  border-top: 0;
  display: flex;
  gap: 2.4rem;
  padding: 1.8rem 3.2rem;
`
