import React, { useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Editor } from '@tiptap/react'
import {
  StyledBubbleMenu,
  BubbleIcon,
  LinkInputContainer,
  LinkInput,
} from './BubbleMenu.styles'

interface BubbleMenuProps {
  editor: Editor
}

const BubbleMenu: React.FC<BubbleMenuProps> = ({ editor }) => {
  const intl = useIntl()
  const linkInputRef = useRef<HTMLInputElement>(null)
  const [showLinkInput, setShowLinkInput] = useState(false)

  return (
    <div>
      <StyledBubbleMenu
        editor={editor}
        tippyOptions={{
          duration: 100,
          onHide: () => setShowLinkInput(false),
        }}
      >
        <BubbleIcon
          icon={['far', 'bold']}
          onClick={() => editor.chain().focus().toggleBold().run()}
          active={editor.isActive('bold')}
        />
        <BubbleIcon
          icon={['far', 'italic']}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          active={editor.isActive('italic')}
        />
        <BubbleIcon
          icon={['far', 'underline']}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          active={editor.isActive('underline')}
        />
        <BubbleIcon
          icon={['far', 'list-ul']}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          active={editor.isActive('bulletList')}
        />
        <BubbleIcon
          icon={['far', 'list-ol']}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          active={editor.isActive('orderedList')}
        />
        <BubbleIcon
          icon={['far', 'link']}
          onClick={() => setShowLinkInput(true)}
          active={editor.isActive('link')}
        />
        {showLinkInput && (
          <LinkInputContainer>
            <LinkInput
              ref={linkInputRef}
              defaultValue={editor.getAttributes('link').href}
              placeholder={intl.formatMessage({
                id: 'updates.exampleUrl',
              })}
              autoFocus
            />
            <BubbleIcon
              icon={['far', 'check']}
              onClick={() => {
                editor
                  .chain()
                  .setLink({ href: linkInputRef.current?.value || '' })
                  .run()
                setShowLinkInput(false)
              }}
            />
          </LinkInputContainer>
        )}
        <BubbleIcon
          icon={['far', 'unlink']}
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive('link')}
        />
      </StyledBubbleMenu>
    </div>
  )
}

export default BubbleMenu
