import React from 'react'
import { ReactChildren } from 'utils/types/common'

import * as Styles from './NavigationBar.styles'
import NavigationActions from '../NavigationActions'
import NavigationList from '../NavigationList'

interface NavigationComposition {
  List: React.FC<any>
  Actions: React.FC<any>
}

interface NavigationBarProps {
  isExpanded?: boolean
  children?: ReactChildren
}

const NavigationBar: React.FC<NavigationBarProps> & NavigationComposition = ({
  isExpanded = false,
  children,
}) => {
  return (
    <Styles.NavigationBar isExpanded={isExpanded}>
      {children}
    </Styles.NavigationBar>
  )
}

NavigationBar.List = NavigationList
NavigationBar.Actions = NavigationActions

export default NavigationBar
