import { useMemo, useState } from 'react'
import { FieldArray } from 'formik'
import type { ArrayHelpers } from 'formik'
import { useFieldName, useFormikContext } from 'components/Form/hooks'
import List from 'components/List'
import Title from 'components/Title'
import { useIntl } from 'react-intl'
import LocationInput, { LocationOption } from 'ui/LocationInput'
import Separator from 'ui/Separator'
import { AddressesFormValues } from 'utils/types/investors'
// import InputLabel from 'ui/InputLabel'
import GoogleApiService from 'api/GoogleApiService'
import InputError from 'ui/InputError'
import { ErrorType } from 'utils/types/common'

import LocationRow from './LocationRow'
import { EmptyState } from './locationList.styles'

const LocationsList = () => {
  const { values, setFieldValue } = useFormikContext<AddressesFormValues>()
  const intl = useIntl()
  const [locationError, setLocationError] = useState('')
  const locationFieldName = useFieldName('locations')
  const isSomeAddressPopulated = useMemo(
    () => values.locations.some((location) => location.isPopulated),
    [values.locations]
  )

  const onAddLocation = async (
    arrayHelpers: ArrayHelpers,
    locationOption: LocationOption
  ) => {
    if (
      values.locations.some(
        (loc) => loc.googlePlaceId === locationOption.value.place_id
      )
    ) {
      setLocationError(
        intl.formatMessage(
          { id: 'errors.locationAlreadyAdded' },
          { location: locationOption.value.description }
        )
      )
      return
    }
    setLocationError('')

    const geocodedPlace = await GoogleApiService.getPlaceData(
      locationOption.value.place_id
    )

    arrayHelpers.push({
      id: locationOption.value.place_id,
      main: values.locations.length === 0,
      googlePlaceId: geocodedPlace.place_id,
      formattedAddress: geocodedPlace.formatted_address,
      geocodedPlace,
    })
  }

  const onRemoveLocation = (arrayHelpers: ArrayHelpers, index: number) => {
    const locationToRemove = values.locations[index]

    if (locationToRemove.main) {
      const newMainIndex = values.locations.findIndex(
        (location) => location.id !== locationToRemove.id
      )

      if (newMainIndex >= 0) {
        setFieldValue(`locations.${newMainIndex}.main`, true)
      }
    }
    arrayHelpers.remove(index)
  }

  const onSetAsMain = (index: number) => {
    const previousMainIndex = values.locations.findIndex((addr) => addr.main)

    const newLocations = values.locations
      .map((location, locationIndex) => {
        if (locationIndex === index) {
          return {
            ...location,
            main: true,
          }
        }

        if (locationIndex === previousMainIndex) {
          return {
            ...location,
            main: false,
          }
        }

        return location
      })
      .sort((location) => (location.main ? -1 : 1))

    setFieldValue('locations', newLocations)
  }

  return (
    <>
      <Title
        title={intl.formatMessage({
          id: 'investorManagement.address',
        })}
      />
      <Separator />

      <FieldArray
        name={locationFieldName}
        render={(arrayHelpers) => (
          <>
            <LocationInput
              hideDropdownIndicator
              placeholder={intl.formatMessage({
                id: 'investorManagement.addAddress',
              })}
              loadingMessage={intl.formatMessage({
                id: 'investorManagement.loadingAddresses',
              })}
              onChange={(location) => onAddLocation(arrayHelpers, location)}
              types={['route']}
            />
            {locationError && (
              <InputError type={ErrorType.ERROR}>{locationError}</InputError>
            )}
            {isSomeAddressPopulated && (
              <>
                <Separator space="0.8rem" />
                <InputError type={ErrorType.WARNING}>
                  {intl.formatMessage({
                    id: 'investorManagement.addressWarning',
                  })}
                </InputError>
              </>
            )}
            <Separator space="1.6rem" />
            {values.locations.length > 0 ? (
              <List
                overflow="visible"
                removeMaxHeight
                rowWidthAuto
                rowHeightAuto
                keyExtractor={(index) => values.locations[index].id}
                elements={values.locations.map((location, index) => {
                  return (
                    <LocationRow
                      formattedAddress={location.formattedAddress}
                      main={location.main}
                      setAsMain={() => onSetAsMain(index)}
                      onDelete={() => onRemoveLocation(arrayHelpers, index)}
                      canDelete
                      isPopulated={location.isPopulated}
                    />
                  )
                })}
                striped={false}
              />
            ) : (
              <EmptyState>
                {intl.formatMessage({
                  id: 'investorManagement.noAddressYet',
                })}
              </EmptyState>
            )}
          </>
        )}
      />
    </>
  )
}

export default LocationsList
