import {
  ModalBody,
  ModalBodyPrimaryText,
  ModalBodySecondaryText,
} from './DeleteModal.styles'

interface Props {
  text: string | React.ReactElement
  secondaryText?: string
  maxWidth?: string
  children?: React.ReactNode
}

const DeleteModalBody = ({
  text,
  secondaryText,
  maxWidth,
  children,
}: Props) => (
  <ModalBody maxWidth={maxWidth}>
    <ModalBodyPrimaryText>{text}</ModalBodyPrimaryText>
    <ModalBodySecondaryText>{secondaryText}</ModalBodySecondaryText>
    {children}
  </ModalBody>
)

export default DeleteModalBody
