import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { FormattedMessage, useIntl } from 'react-intl'

import Analytics from 'containers/Analytics/Analytics'
import { AnalyticAccessType } from 'utils/constants/analytics'
import { AnalyticRow } from './AnalyticExpandedRow.styles'

const AnalyticExpandedRow = ({
  rowData: analytic,
  rowIndex,
  expandableDataToShow,
  isLastPage,
}) => {
  const intl = useIntl()
  const analyticTextRef = useRef(null)
  const isLast =
    rowIndex === (expandableDataToShow?.length ?? 0) - 1 && isLastPage

  const formatGroups = () => {
    const reshared = [
      ...analytic.resharedGroups.map((rsh) => rsh.name),
      ...analytic.resharedUsers.map((rsh) => rsh.name ?? rsh.email),
    ]

    if (reshared.length === 1) {
      return reshared[0] ?? reshared[0]
    }

    const andSeparator = ` ${intl.formatMessage({ id: 'updates.and' })} `

    if (reshared.length === 2) {
      return reshared.join(andSeparator)
    }

    const firstReshare = reshared.pop()
    return reshared.join(', ').concat(andSeparator).concat(firstReshare)
  }

  const getActivityLabel = () => {
    if (analytic.access === null) {
      return {
        label: (
          <FormattedMessage
            id="analytics.resharedUpdateWith"
            values={{
              groups: <b>{formatGroups()}</b>,
            }}
          />
        ),
        icon: ['far', 'retweet'],
      }
    }

    return {
      label: <FormattedMessage id="analytics.viewedUpdate" />,
      icon: ['far', 'eye'],
    }
  }

  const { label, icon } = getActivityLabel()

  const lineHeight =
    (analyticTextRef.current?.getBoundingClientRect?.().height ?? 0) + 14

  return (
    <AnalyticRow>
      <AnalyticRow.IconContainer>
        <AnalyticRow.Icon isReshare={analytic.access === null}>
          <FontAwesomeIcon icon={icon} />
        </AnalyticRow.Icon>
        {!isLast && <AnalyticRow.Line height={lineHeight} />}
      </AnalyticRow.IconContainer>
      <AnalyticRow.Content>
        <AnalyticRow.Date>
          {dayjs(analytic.createdAt).format('DD MMM. YYYY - hh:mm A')}
        </AnalyticRow.Date>
        <AnalyticRow.Text ref={analyticTextRef}>{label}</AnalyticRow.Text>
      </AnalyticRow.Content>
    </AnalyticRow>
  )
}

AnalyticExpandedRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  expandableDataToShow: PropTypes.array.isRequired,
  isLastPage: PropTypes.bool.isRequired,
}

export default AnalyticExpandedRow
