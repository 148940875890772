import { HoldingPortfolio } from 'utils/types/company'
import type { FlattenInterpolation, ThemeProps } from 'styled-components'

import PortfolioService from 'api/PortfolioService'
import { useCallback, useState } from 'react'

import usePortfoliosByHoldingQuery from 'utils/hooks/usePortfoliosByHoldingQuery'
import Tooltip from 'components/Tooltip'
import { PortfolioCompany } from 'utils/types/portfolios'
import { FormattedMessage } from 'react-intl'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import { randomId } from 'utils/functions/number'
import {
  BtnAddToPortfolio,
  TooltipPortfolioItem,
  TooltipContainer,
  TooltipPortfolioItemIcon,
  TooltipPortfolioItemName,
} from './AddToPortfolioButton.styles'
import AddToPortfolioModal from './AddToPortfolioModal'

interface Props {
  holdingName: string
  holdingId: string
  holdingPortfolios?: HoldingPortfolio[]
  forceFetch?: boolean
  isFundHolding?: boolean
  btnCustomStyles?: FlattenInterpolation<ThemeProps<any>>
}

const emptyInitialData = {
  trackPortfolios: [],
  investPortfolios: [],
  fundPortfolios: [],
}

const AddToPortfolioButton = ({
  holdingName,
  holdingId,
  holdingPortfolios = [],
  forceFetch,
  isFundHolding,
  btnCustomStyles,
}: Props) => {
  const tooltipId = randomId()
  const [addToPortfolioModalOpen, setAddToPortfolioModalOpen] = useState(false)

  const { data: companyPortfolios, refetch } = usePortfoliosByHoldingQuery({
    holdingId,
    enabled: forceFetch || addToPortfolioModalOpen,
  })
  const loadPortfolios = useCallback(
    async ({
      inputSearch,
      companyPortfolios: currentCompanyPortfolios,
      type,
    }) => {
      return PortfolioService.fetchPortfolios({
        portfolioType: type,
        name: inputSearch,
        idNotIn: currentCompanyPortfolios.map(({ id }) => id),
        perPage: 500,
      })
    },
    []
  )

  const onChangeCompanyPortfolios = useCallback(async () => {
    refetch()
    setAddToPortfolioModalOpen(false)
  }, [refetch])

  const handleAddToPortfolio = (e) => {
    e.stopPropagation()
    setAddToPortfolioModalOpen(true)
  }

  const handleHideModal = () => setAddToPortfolioModalOpen(false)

  const thereIsCompanyPortfolios =
    companyPortfolios &&
    (companyPortfolios.trackPortfolios.length > 0 ||
      companyPortfolios.investPortfolios.length > 0 ||
      companyPortfolios.fundPortfolios.length > 0)

  const thereIsHoldingPortfolios =
    holdingPortfolios && holdingPortfolios.length > 0

  const hasPortfolios = companyPortfolios
    ? thereIsCompanyPortfolios
    : thereIsHoldingPortfolios

  const getTooltipContent = () => {
    if (!hasPortfolios) {
      return (
        <FormattedMessage
          id="companyList.thisHoldingIsInNoPortfolio"
          values={{ br: <br /> }}
        />
      )
    }

    const getTooltipItem = (
      portfolios: PortfolioCompany[] | HoldingPortfolio[]
    ) =>
      portfolios.map((portfolio) => (
        <TooltipPortfolioItem key={portfolio.id}>
          <TooltipPortfolioItemIcon>
            {getPortfolioIconByType(portfolio.type)}
          </TooltipPortfolioItemIcon>
          <TooltipPortfolioItemName>{portfolio.name}</TooltipPortfolioItemName>
        </TooltipPortfolioItem>
      ))

    if (thereIsCompanyPortfolios)
      return (
        <TooltipContainer>
          {getTooltipItem(companyPortfolios.investPortfolios)}
          {getTooltipItem(companyPortfolios.trackPortfolios)}
          {getTooltipItem(companyPortfolios.fundPortfolios)}
        </TooltipContainer>
      )

    if (thereIsHoldingPortfolios)
      return (
        <TooltipContainer>{getTooltipItem(holdingPortfolios)}</TooltipContainer>
      )

    return null
  }

  return (
    <>
      <Tooltip
        id={`AddToPortfolioButton_${tooltipId}`}
        text={getTooltipContent()}
        place="bottom"
        widthAuto
        delayShow={500}
      >
        <BtnAddToPortfolio
          onClick={handleAddToPortfolio}
          hasPortfolios={hasPortfolios}
          btnCustomStyles={btnCustomStyles}
          color="white"
          icon="briefcase"
        />
      </Tooltip>
      <AddToPortfolioModal
        isOpen={addToPortfolioModalOpen}
        onHide={handleHideModal}
        companyPortfolios={companyPortfolios || emptyInitialData}
        onChangeCompanyPortfolios={onChangeCompanyPortfolios}
        companyName={holdingName}
        companyId={holdingId}
        loadPortfolios={loadPortfolios}
        isFundHolding={isFundHolding}
      />
    </>
  )
}

export default AddToPortfolioButton
