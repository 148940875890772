import { randomId } from 'utils/functions/number'
import UpdateService from 'api/UpdateService'
import Toast from 'components/Toast'
import { ALPHANUMERICAL_REGEX } from 'utils/functions/regex'

const alphanumericalRegexp = new RegExp(ALPHANUMERICAL_REGEX)

export const getGroupTagWithSameName = (groupTags, tagName) =>
  groupTags.find((tag) => tag.name === tagName)

export const getTagsFromTaggings = (taggings) =>
  taggings.map((tagging) => {
    return {
      ...tagging.tag,
      taggingId: tagging.id,
    }
  })

export const removeTagFromList = (tag, tagsList) => {
  let newTagList = []
  if (tag.taggingId) {
    newTagList = tagsList.map((currTag) => {
      if (currTag.id === tag.id) {
        return {
          ...currTag,
          destroy: true,
        }
      }
      return currTag
    })
  } else {
    newTagList = tagsList.filter((currTag) => currTag.id !== tag.id)
  }
  return newTagList
}

export const addTagToList = (tagName, tagsList, groupTags) => {
  const groupTagWithSameName = getGroupTagWithSameName(groupTags, tagName)
  const hasTagToAddBeenPreviouslyRemoved = tagsList.find(
    (currTag) => currTag.id === groupTagWithSameName?.id
  )

  let newTagList = []
  if (hasTagToAddBeenPreviouslyRemoved) {
    newTagList = tagsList.map((currTag) => {
      if (currTag.id === groupTagWithSameName?.id) {
        const newTag = {
          ...currTag,
        }
        delete newTag.destroy
        return newTag
      }
      return currTag
    })
  } else {
    newTagList = [
      ...tagsList,
      {
        name: tagName,
        id: groupTagWithSameName?.id || randomId(),
        alreadyExists: !!groupTagWithSameName,
      },
    ]
  }
  return newTagList
}

export const onAddTagFromTagInput = async ({
  tagName,
  tagsList,
  groupTags,
  setTagsList,
  updateId,
  intl,
}) => {
  const newTagsList = addTagToList(tagName, tagsList, groupTags)
  setTagsList(newTagsList)

  const tag = newTagsList.filter((currTag) => tagName === currTag.name)[0]

  try {
    const {
      data: { entities },
    } = await UpdateService.updateTagsByUpdateId(updateId, tag)
    const tagsResponse = getTagsFromTaggings(Object.values(entities.taggings))
    const tagResponse = tagsResponse.find(
      (currTag) => currTag.name === tag.name
    )

    setTagsList(
      newTagsList.map((currTag) => {
        if (!currTag.taggingId && currTag.name === tagName) {
          return {
            ...currTag,
            taggingId: tagResponse.taggingId,
          }
        }
        return currTag
      })
    )
  } catch (error) {
    Toast.display(intl.messages['tags.errorAddingTag'], 'error')
  }
}

export const onRemoveTagFromTagInput = async ({
  tag,
  tagsList,
  setTagsList,
  updateId,
  intl,
}) => {
  const newTagsList = removeTagFromList(tag, tagsList)
  setTagsList(newTagsList.filter((currTag) => !currTag.destroy))

  const tagToRemove = newTagsList.filter(
    (currTag) => tag.name === currTag.name
  )[0]

  await UpdateService.updateTagsByUpdateId(updateId, tagToRemove).catch(() => {
    Toast.display(intl.messages['tags.errorRemovingTag'], 'error')
  })
}

export const isValidTag = (tagName, intl) => {
  const isValid = alphanumericalRegexp.test(tagName)
  if (tagName.length >= 2 && isValid) {
    return true
  }
  Toast.display(intl.messages['tags.errorValidatingTag'], 'error')
  return false
}
