import { useScrollToTop } from 'utils/hooks/useScrollToTop'
import CompanyProfile from 'containers/Profiles/CompanyProfile'
import { useParams } from 'react-router-dom'

const CompanyProfileRouteWrapper = ({ prefix }: { prefix?: string }) => {
  const { companyId } = useParams<{ companyId: string }>()

  useScrollToTop()
  return <CompanyProfile companyId={companyId} prefix={prefix} />
}

export default CompanyProfileRouteWrapper
