import React from 'react'
import { Helmet } from 'components/Helmet'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'

import { getInvestmentVehicleSchema } from 'utils/schemas/investorManagement'
import {
  InvestmentVehicleFormValues,
  InvestorManagementSidebarProps,
} from 'utils/types/investors'
import Button from 'ui/Button'
import Drawer from 'components/Drawer'
import FadeIn from 'components/FadeIn/FadeIn'

import useCreateInvestmentVehicle from './useCreateInvestmentVehicle'
import InvestmentVehicleForm from '../InvestorVehicleForm/InvestmentVehicleForm'

const CreateInvestmentVehicle: React.FC<InvestorManagementSidebarProps> = ({
  onCloseDrawer,
  isDrawerOpen,
}) => {
  const intl = useIntl()

  const {
    createInvestmentVehicle,
    onCancel,
    onCreateNewInvestor,
    initialData,
    customError,
  } = useCreateInvestmentVehicle(onCloseDrawer, intl)

  return (
    <Drawer
      isOpen={isDrawerOpen}
      onCloseDrawer={onCloseDrawer}
      headerTitle={intl.formatMessage({
        id: 'investorManagement.addInvestmentVehicle',
      })}
    >
      <Helmet>
        <title>
          {`${intl.formatMessage({
            id: 'metaTitles.createInvestmentVehicle',
          })}`}
        </title>
      </Helmet>

      <FadeIn>
        <Formik<InvestmentVehicleFormValues>
          initialValues={initialData}
          onSubmit={createInvestmentVehicle}
          validationSchema={getInvestmentVehicleSchema(intl)}
        >
          {(formik) => (
            <>
              <Drawer.Content contentPadding="2rem 2.4rem 8.5rem 2.4rem">
                <InvestmentVehicleForm
                  customError={customError}
                  onCreateNewInvestor={onCreateNewInvestor}
                />
              </Drawer.Content>

              <Drawer.Footer>
                <Button onClick={onCancel}>
                  {intl.formatMessage({ id: 'general.cancel' })}
                </Button>
                <Button
                  type="submit"
                  primary
                  onClick={formik.handleSubmit}
                  isDisabled={
                    !formik.values.name ||
                    !!formik.errors.name ||
                    !formik.values.investor ||
                    !!formik.errors.investor ||
                    formik.isSubmitting
                  }
                >
                  {intl.formatMessage({ id: 'general.save' })}
                </Button>
              </Drawer.Footer>
            </>
          )}
        </Formik>
      </FadeIn>
    </Drawer>
  )
}

export default CreateInvestmentVehicle
