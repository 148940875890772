import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { Helmet } from 'components/Helmet'
import { useIntl } from 'react-intl'
import { useCompanyQuery } from 'utils/hooks/queries/useCompanyQuery'

import { isCurrentCompanyFundProfile } from 'selectors/company'
import { isActingAsInvestorGroup } from 'selectors/auth'

import Celeste from 'components/Celeste'
import { UpdatesFiltersProvider } from 'containers/UpdatesView/UpdatesFiltersContext'
import { MetricsContextProvider } from 'containers/Metrics/MetricsContext'
import CWLoader from 'components/CWLoader'
import Layout from 'containers/Profiles/components/Layout'
import { ProfileType } from 'containers/Profiles/types'
import { COMPANY_UPDATES_PREFIX } from 'routes/constant/companiesRoutes'
import { GROUP_PROFILE_ALL_UPDATES } from 'routes/constant/fundManagerRoutes'
import CompanyInformation from 'containers/Profiles/components/SideInformation/CompanyInformation'
import CompanyMainContent from 'containers/Profiles/components/MainContent/CompanyMainContent'
import HeaderContent from 'containers/Profiles/components/HeaderContent/HeaderContent'
import { ProfileProvider } from 'containers/Profiles/ProfileContext'

import * as Styles from './CompanyProfile.styles'

interface CompanyProfileRouteWrapperProps {
  companyId: string
  shouldRenderHelmet?: boolean
  prefix?: string
}

const CompanyProfile = ({
  companyId,
  shouldRenderHelmet = true,
  prefix,
}: CompanyProfileRouteWrapperProps) => {
  const isFundProfileCompany = useAppSelector(isCurrentCompanyFundProfile)
  const { data: companyInfo, isLoading } = useCompanyQuery(companyId)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const intl = useIntl()

  const holdingType = companyInfo?.fund
    ? ProfileType.FUND_COMPANY
    : ProfileType.COMPANY

  return isLoading ? (
    <Styles.LoaderWrapper>
      <CWLoader />
    </Styles.LoaderWrapper>
  ) : (
    <>
      {shouldRenderHelmet && (
        <Helmet>
          <title>
            {intl.formatMessage(
              {
                id: 'metaTitles.profile',
              },
              { profileName: companyInfo?.name }
            )}
          </title>
        </Helmet>
      )}
      <ProfileProvider>
        <UpdatesFiltersProvider>
          <MetricsContextProvider>
            <Layout>
              <Layout.Header type={holdingType}>
                <HeaderContent companyInfo={companyInfo} />
              </Layout.Header>
              <Layout.SideContent>
                <CompanyInformation companyInfo={companyInfo!} />
              </Layout.SideContent>
              <Layout.MainContent>
                <CompanyMainContent
                  companyId={companyId}
                  companyInfo={companyInfo}
                  prefix={prefix}
                />
              </Layout.MainContent>

              {!isInvestorGroup && (
                <Celeste
                  prefixUrl={
                    isFundProfileCompany
                      ? GROUP_PROFILE_ALL_UPDATES
                      : COMPANY_UPDATES_PREFIX.replace(':companyId', companyId)
                  }
                />
              )}
            </Layout>
          </MetricsContextProvider>
        </UpdatesFiltersProvider>
      </ProfileProvider>
    </>
  )
}

export default CompanyProfile
