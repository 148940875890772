import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 700;
  height: 3.6rem;
  border-radius: 0.8rem;
  padding: 0 1.2rem;
  gap: 0.8rem;

  ${({ selected }) =>
    selected &&
    css`
      color: ${color('primaryBlue')};
      background-color: ${color('veryLightBlue', 0.2)};
    `}
`
