import { Nullable } from 'utils/types/common'
import { ChangeSetItem, EventType, LogTextBuilder } from '../types'

export class PortfolioLogTextBuilder extends LogTextBuilder {
  private portfolioLongType: string

  private portfolioShortType: string

  constructor(portfolioLongType: string, portfolioShortType: string) {
    super()
    this.portfolioLongType = portfolioLongType
    this.portfolioShortType = portfolioShortType
  }

  private getCreatedLog(): React.ReactNode {
    return this.formatMessage('logs.portfolios.created', {
      type: this.portfolioLongType,
    })
  }

  private getRenamedLog([from, to]: ChangeSetItem): React.ReactNode {
    return this.formatMessage('logs.portfolios.renamed', {
      type: this.portfolioShortType,
      from,
      to,
    })
  }

  private getAddedCompanyLog(company: string): React.ReactNode {
    return this.formatMessage('logs.portfolios.addedCompany', {
      company,
    })
  }

  private getRemovedCompanyLog(company: string): React.ReactNode {
    return this.formatMessage('logs.portfolios.removedCompany', {
      company,
    })
  }

  private getAddedTransactionLog(transaction: string): React.ReactNode {
    return this.formatMessage('logs.portfolios.addedTransaction', {
      transaction,
    })
  }

  private getRemovedTransactionLog(transaction: string): React.ReactNode {
    return this.formatMessage('logs.portfolios.removedTransaction', {
      transaction,
    })
  }

  private getAddedFMVLog(company: string, value: number): React.ReactNode {
    return this.formatMessage('logs.portfolios.addedFMV', {
      company,
      value,
    })
  }

  private getEditedFMVLog(
    company: string,
    [from, to]: ChangeSetItem
  ): React.ReactNode {
    return this.formatMessage('logs.portfolios.editedFMV', {
      company,
      from,
      to,
    })
  }

  private getRemovedFMVLog(company: string, value: number): React.ReactNode {
    return this.formatMessage('logs.portfolios.removedFMV', {
      company,
      value,
    })
  }

  private getAddedInvestorLog(investor: string): React.ReactNode {
    return this.formatMessage('logs.portfolios.addedInvestor', {
      investor,
    })
  }

  private getRemovedInvestorLog(investor: string): React.ReactNode {
    return this.formatMessage('logs.portfolios.removedInvestor', {
      investor,
    })
  }

  public getLogText(
    event: EventType,
    fieldName: string,
    changeSetItem: ChangeSetItem,
    text: Nullable<string>
  ): React.ReactNode {
    switch (event) {
      case EventType.CREATE:
        return this.getCreatedLog()
      case EventType.UPDATE:
        if (fieldName === 'name') {
          return this.getRenamedLog(changeSetItem)
        }
        return null
      case EventType.ADD_COMPANY:
        return this.getAddedCompanyLog(text!)
      case EventType.REMOVE_COMPANY:
        return this.getRemovedCompanyLog(text!)
      case EventType.ADD_TRANSACTION:
        return this.getAddedTransactionLog(text!)
      case EventType.REMOVE_TRANSACTION:
        return this.getRemovedTransactionLog(text!)
      case EventType.ADD_FMV:
        return this.getAddedFMVLog(text!, changeSetItem[1] as number)
      case EventType.UPDATE_FMV:
        return this.getEditedFMVLog(text!, changeSetItem)
      case EventType.REMOVE_FMV:
        return this.getRemovedFMVLog(text!, changeSetItem[0] as number)
      case EventType.ADD_INVESTOR:
        return this.getAddedInvestorLog(text!)
      case EventType.REMOVE_INVESTOR:
        return this.getRemovedInvestorLog(text!)
      default:
        return null
    }
  }
}
