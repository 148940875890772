import { useShowUpdate } from 'containers/Updates/ShowUpdate/useShowUpdate'
import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { handleDownloadClick } from 'utils/functions/files'
import {
  UpdateWithItem,
  getUpdateFormValues,
  getUpdateItemText,
  getUpdateItemTitle,
} from 'utils/functions/updates'
import { useIsPreviewingDraftUpdate } from 'utils/hooks/useIsPreviewingDraftUpdate'
import { UpdateType } from 'utils/types/common'
import { Update } from 'utils/types/update'

interface Props {
  update: Update
  updateType: UpdateType
  updateTitle?: string
}

const useShowUpdateContent = ({ update, updateType, updateTitle }: Props) => {
  const intl = useIntl()

  const { date = new Date(), contents } = update
  const isPreviewingDraft = useIsPreviewingDraftUpdate()
  const createdAtDate = new Date(update.createdAt)

  const updateValues = update?.item
    ? getUpdateFormValues({
        update: update as UpdateWithItem,
        getPublishedValues: !isPreviewingDraft,
      })
    : {
        date,
        title: updateTitle || getUpdateItemTitle(update),
        body: getUpdateItemText(update),
        files: update.contents,
        updateId: update.id,
        updateType,
        tags: update.tags,
        investor: update.item.investor,
        portfolio: update.portfolio,
      }

  const { attachmentZipUrl } = useShowUpdate()

  const formatEntitiesList = useCallback(
    (names: string[]) => {
      if (names.length === 1) {
        return `${intl.formatMessage({ id: 'updates.reshareBy' })} ${names[0]}`
      }

      if (names.length <= 4) {
        const lastEntity = names.pop()
        return `${intl.formatMessage({
          id: 'updates.reshareBy',
        })} ${names.join(', ')} ${intl.formatMessage({
          id: 'updates.and',
        })} ${lastEntity}`
      }

      const [entity1, entity2, entity3, ...rest] = names
      return `${intl.formatMessage({
        id: 'updates.reshareBy',
      })} ${entity1}, ${entity2}, ${entity3}, ${intl.formatMessage({
        id: 'updates.and',
      })} ${rest.length} more`
    },
    [intl]
  )

  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById('download-button')
      if (elem) {
        elem.style.display = 'block'
      }
    }, 1000)
  }, [])

  useEffect(() => {
    if (attachmentZipUrl) {
      handleDownloadClick({
        name: 'attachments',
        src: attachmentZipUrl,
      })
    }
  }, [attachmentZipUrl])

  return {
    updateValues,
    createdAtDate,
    contents,
    formatEntitiesList,
  }
}

export default useShowUpdateContent
