import ReactDOM from 'react-dom'
import { useIntl } from 'react-intl'
import Input from 'ui/Input'
import useCompanyActions from './useProfilesActions'

const MOBILE_OR_FOCUS_INPUT_STYLES = {
  width: '0rem',
  backgroundColor: 'white',
  border: 'none',
}

const ProfilesActions = () => {
  const intl = useIntl()

  const {
    inputRef,
    mobileStyles,
    companyActions,
    search,
    matchesMobileQuery,
    onInputChange,
    handleOnClickIcon,
  } = useCompanyActions()

  if (!companyActions) return null

  return (
    <>
      {ReactDOM.createPortal(
        <Input
          placeholder={intl.formatMessage({
            id: 'general.searchEllipsis',
          })}
          ref={inputRef}
          value={search}
          onChange={onInputChange}
          icon={['far', 'search']}
          iconFontSize="1.5rem"
          iconColor="darkBlue"
          placeholderColor="darkGray"
          placeholderOpacity={0.5}
          style={mobileStyles ? MOBILE_OR_FOCUS_INPUT_STYLES : {}}
          onClickIcon={handleOnClickIcon}
          width={matchesMobileQuery ? '100%' : '27rem'}
        />,
        companyActions
      )}
    </>
  )
}

export default ProfilesActions
