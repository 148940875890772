import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import { dispatchEvent } from 'utils/hooks/useEventListener'
import Checkbox from 'components/Checkbox'
import { randomId } from 'utils/functions/number'
import Cell from '../Cell/Cell'
import { RowContainer } from './Row.styles'

const Row = ({
  columns,
  data,
  index,
  parentData,
  parentRowIndex,
  mainRow,
  wrapText,
  isExpandable,
  expandIcon,
  showExpandableColumn,
  keyPath,
  isExpanded,
  getExpandedRowHeight,
  height,
  nestedRow,
  onClickRow,
  selectableRows,
  onSelectRow,
  isLastPage,
  expandableDataToShow,
}) => {
  return (
    <RowContainer
      data-testid={mainRow ? 'clickable-expanded-row' : 'expanded-row'}
      onMouseLeave={() => {
        dispatchEvent('close_dropdown')
      }}
      mainRow={mainRow}
      wrapText={wrapText}
      height={height}
      getExpandedRowHeight={getExpandedRowHeight}
      isExpanded={isExpanded}
      nestedRow={nestedRow}
      id={`expandableRow_${parentRowIndex}`}
      onClick={() =>
        onClickRow?.({
          rowData: data,
          rowIndex: index,
          parentRowIndex,
          parentRowData: parentData,
        })
      }
      clickable={!!onClickRow || isExpandable}
    >
      {showExpandableColumn && isExpandable && (
        <Cell
          key={randomId()}
          component={expandIcon}
          flex={0.1}
          onClick={(event) => {
            event.stopPropagation()
            onClickRow()
          }}
        />
      )}
      {columns.map((col, colIndex) => {
        return (
          !col.hideColumnDataCells && (
            <Fragment
              key={get(data, keyPath) + colIndex.toString() || randomId()}
            >
              {selectableRows && colIndex === 0 && (
                <Checkbox
                  onChange={(event) => {
                    onSelectRow(data, event?.target?.checked)
                  }}
                  checked={data.isSelected}
                  type="checkbox"
                />
              )}
              <Cell
                component={
                  col?.cellRenderer?.({
                    rowData: data,
                    rowIndex: index,
                    parentRowData: parentData,
                    parentRowIndex,
                    isLastPage,
                    expandableDataToShow,
                  }) || null
                }
                flex={col.flex}
                align={col.align}
                padding={col.padding}
                wrapText={wrapText}
              />
            </Fragment>
          )
        )
      })}
    </RowContainer>
  )
}

Row.propTypes = {
  columns: PropTypes.array.isRequired,
  expandableDataToShow: PropTypes.array,
  data: PropTypes.object.isRequired,
  parentData: PropTypes.object,
  wrapText: PropTypes.bool,
  index: PropTypes.number.isRequired,
  parentRowIndex: PropTypes.number,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  showExpandableColumn: PropTypes.bool.isRequired,
  expandIcon: PropTypes.node,
  onClickRow: PropTypes.func,
  keyPath: PropTypes.string.isRequired,
  mainRow: PropTypes.bool,
  nestedRow: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  selectableRows: PropTypes.bool,
  onSelectRow: PropTypes.func,
  getExpandedRowHeight: PropTypes.func,
  isLastPage: PropTypes.bool,
}
Row.defaultProps = {
  wrapText: true,
  onClickRow: null,
  expandIcon: null,
  isExpandable: false,
  nestedRow: false,
  isExpanded: false,
  mainRow: false,
  parentData: null,
  parentRowIndex: null,
  getExpandedRowHeight: null,
  selectableRows: false,
  onSelectRow: () => {},
  isLastPage: false,
  expandableDataToShow: [],
}

export default React.memo(Row)
