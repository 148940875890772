import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { areEqual } from 'react-window'

import { randomId } from 'utils/functions/number'
import CWLoader from 'components/CWLoader'
import Row from '../Row/Row'
import {
  Expandable,
  ExpandableRowContainer,
  ZeroStateContainer,
} from './ExpandableRow.styles'
import ColHeader from '../ColHeader/ColHeader'
import Paginator from '../Paginator/Paginator'
import { TableWrapper, TableHeader } from '../Table/Table.styles'
import { useExpandableRow } from '../hooks/useExpandableRow'

const ExpandableRow = ({ data: tableData, index: rowIndex, style }) => {
  const {
    props: {
      keyPath,
      columns,
      selectableRows,
      onSelectRow,
      expandedRowsColumns,
      rowHeight,
      expandedRowHeight,
      wrapText,
      showExpandableColumn,
      showExpandableHeaderColumns,
      expandRowIcon,
      closeRowIcon,
      isRowExpandable,
      showExpandableRowZeroState,
      renderExpandableRowZeroState,
      onClickExpandedRow,
      paginateExpandedRows,
      paginationLabel,
      pageSize,
      asyncExpandedPagination,
      expandedLoadingIndicatorText,
      getExpandedRowHeight,
    },
    state: {
      sortBy,
      sortDirection,
      expandableData,
      isLoadingExpandableRow,
      isExpanded,
      customRow,
      showOriginalRow,
      expandableDataToShow,
      rowData,
    },
    callbacks: { onChangeSortBy, onLoadMoreRows, handleToggleRow },
    rowRef,
  } = useExpandableRow(rowIndex, tableData)

  const isLastPageAsyncPagination =
    (expandableDataToShow?.length ?? 0) % pageSize !== 0

  const isLastPageMemoryPagination =
    expandableDataToShow.length < expandableData?.length

  const isLastPage = asyncExpandedPagination
    ? isLastPageAsyncPagination
    : isLastPageMemoryPagination

  const showPagination =
    !isLoadingExpandableRow && paginateExpandedRows && !isLastPage

  const showZeroState = !isLoadingExpandableRow && showExpandableRowZeroState

  return (
    <div style={style} data-testid="expandable-row">
      <div ref={rowRef}>
        {(customRow?.showCustomRow && customRow?.component) || null}
        {showOriginalRow && (
          <ExpandableRowContainer rowIndex={rowIndex} expanded={isExpanded}>
            <div>
              <Row
                selectableRows={selectableRows}
                onSelectRow={onSelectRow}
                keyPath={keyPath}
                data={rowData}
                columns={columns}
                height={rowHeight}
                index={rowIndex}
                wrapText={wrapText}
                showExpandableColumn={showExpandableColumn}
                expandIcon={isExpanded ? closeRowIcon : expandRowIcon}
                isExpandable={isRowExpandable(rowData)}
                isExpanded={isExpanded}
                onClickRow={handleToggleRow}
                errors={rowData.errors}
                mainRow
              />
            </div>
            {!!isRowExpandable(rowData) && (
              <Expandable data-testid="expandable-container">
                {isExpanded && (
                  <div data-testid="expanded-container">
                    {isLoadingExpandableRow ||
                    expandableDataToShow.length > 0 ? (
                      <TableWrapper embbeddedTable>
                        {isLoadingExpandableRow ? (
                          <CWLoader
                            logoSize="4rem"
                            text={expandedLoadingIndicatorText}
                          />
                        ) : (
                          <>
                            {showExpandableHeaderColumns && (
                              <TableHeader embeddedTable>
                                {expandedRowsColumns.map((column) => (
                                  <ColHeader
                                    key={randomId()}
                                    column={column}
                                    sortBy={sortBy}
                                    sortDirection={sortDirection}
                                    onSort={() =>
                                      onChangeSortBy(column.sortKey)
                                    }
                                  />
                                ))}
                              </TableHeader>
                            )}
                            {expandableDataToShow.map((expandedData, index) => (
                              <Row
                                key={get(expandedData, keyPath) || randomId()}
                                keyPath={keyPath}
                                parentData={rowData}
                                parentRowIndex={rowIndex}
                                index={index}
                                data={expandedData}
                                columns={expandedRowsColumns}
                                getExpandedRowHeight={
                                  getExpandedRowHeight
                                    ? () =>
                                        getExpandedRowHeight({
                                          rowData: expandedData,
                                          rowIndex: index,
                                          parentRowIndex: rowIndex,
                                          parentRowData: rowData,
                                        })
                                    : undefined
                                }
                                height={expandedRowHeight || rowHeight}
                                wrapText={wrapText}
                                showExpandableColumn
                                isExpanded
                                nestedRow
                                onClickRow={onClickExpandedRow}
                                isLastPage={isLastPage}
                                expandableDataToShow={expandableDataToShow}
                              />
                            ))}
                          </>
                        )}

                        {showPagination && (
                          <Paginator
                            embeddedPaginator
                            onLoadMoreRows={onLoadMoreRows}
                            label={paginationLabel}
                          />
                        )}
                      </TableWrapper>
                    ) : (
                      showZeroState && (
                        <ZeroStateContainer>
                          {renderExpandableRowZeroState?.({
                            rowIndex,
                            rowData,
                          }) || null}
                        </ZeroStateContainer>
                      )
                    )}
                  </div>
                )}
              </Expandable>
            )}
          </ExpandableRowContainer>
        )}
      </div>
    </div>
  )
}

export default React.memo(ExpandableRow, areEqual)

ExpandableRow.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
}

ExpandableRow.defaultProps = {
  data: [],
}
