import { ReactNode, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import type {
  FlattenInterpolation,
  FlattenSimpleInterpolation,
  ThemeProps,
} from 'styled-components'
import {
  Wrapper,
  HeaderContainer,
  BodyContainer,
  TitleContainer,
  IconWrapper,
} from './Collapsable.styles'

interface Props {
  id: string
  children: React.ReactNode
  wrapperPadding?: string
  bodyPadding?: string
  bodyCustomStyles?: FlattenSimpleInterpolation
  titleCustomStyles?: FlattenInterpolation<ThemeProps<any>>
  initiallyExpanded?: boolean
  isDisabled?: boolean
  className?: string
  preventReRender?: boolean
  headerActionButton?: ReactNode
  onOpenCallback?: () => void
}

const Collapsable = ({
  id,
  bodyPadding,
  wrapperPadding,
  children,
  bodyCustomStyles,
  titleCustomStyles,
  className,
  headerActionButton,
  initiallyExpanded = false,
  isDisabled = false,
  preventReRender = false,
  onOpenCallback,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded)

  return (
    <Wrapper wrapperPadding={wrapperPadding} className={className}>
      <HeaderContainer
        type="button"
        disabled={isDisabled}
        onClick={() => {
          setIsExpanded((prev) => !prev)
          onOpenCallback?.()
        }}
        isOpen={isExpanded}
      >
        <IconWrapper>
          <FontAwesomeIcon
            icon={['far', !isExpanded ? 'chevron-right' : 'chevron-down']}
          />
        </IconWrapper>
        <TitleContainer titleCustomStyles={titleCustomStyles}>
          <FormattedMessage id={id} />
        </TitleContainer>
        {headerActionButton}
      </HeaderContainer>
      {(isExpanded || preventReRender) && (
        <BodyContainer
          bodyPadding={bodyPadding}
          isOpen={isExpanded}
          customStyles={bodyCustomStyles}
          preventReRender={preventReRender}
        >
          {children}
        </BodyContainer>
      )}
    </Wrapper>
  )
}

export default Collapsable
