import { useQuery } from '@tanstack/react-query'

import UserService from 'api/UserService'
import { User } from 'utils/types/user'
import { userKeys } from 'utils/queries/users'

export const useUserQuery = (userEmail: string) => {
  return useQuery<User>(userKeys.byEmail(userEmail), async () => {
    return UserService.getUserByEmail(userEmail)
  })
}

export const useUserByIdQuery = (userId?: string) => {
  return useQuery<User>(
    userKeys.byId(userId!),
    async () => {
      return UserService.getUserById(userId!)
    },
    {
      enabled: !!userId,
    }
  )
}
