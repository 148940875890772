import { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTestConnection } from 'containers/Inbounds/hooks/useTestConnection'
import { Inbound } from 'utils/types/inbounds'
import Avatar from 'components/AvatarSVG'
import Separator from 'ui/Separator'

import {
  Description,
  IconContainer,
  Label,
  StepContainer,
  SuccessMessageContainer,
} from './SetupYourInboundStep.styles'

interface Props {
  inbound: Inbound
  isStepCompleted: boolean
  completeStep: () => void
}

const TestInboundConnection = ({
  inbound,
  isStepCompleted,
  completeStep,
}: Props) => {
  const { inboundStatusPolling, pollingTimeout } = useTestConnection(inbound.id)

  useEffect(() => {
    inboundStatusPolling(completeStep)
    return () => {
      clearTimeout(pollingTimeout!)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isStepCompleted) {
    return (
      <StepContainer>
        <SuccessMessageContainer>
          <Avatar minSize="3.8rem" size="3.8rem" borderWidth={0}>
            <IconContainer>
              <FontAwesomeIcon icon={['far', 'check']} />
            </IconContainer>
          </Avatar>
          <div>
            <Label>
              <FormattedMessage id="onboardingModal.inbound.receivedEmailSuccessfully" />
            </Label>
            <Separator height="0.4rem" />
            <Description>
              <FormattedMessage id="onboardingModal.inbound.nextStepDescription" />
            </Description>
          </div>
        </SuccessMessageContainer>
      </StepContainer>
    )
  }

  return null
}

export default TestInboundConnection
