import { getCurrentGroupId } from 'selectors/auth'
import { TransactionFundType } from 'utils/constants/transactionTypes'
import { UpdateTypes } from 'utils/constants/updates'
import { ONLY_ME } from 'utils/functions/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { Update } from 'utils/types/update'
import { CreateUpdateGlobalProps } from 'utils/types/updateForm'
import useVisibilityHelpers from './useVisibilityHelpers'

interface UseShareSettingsActionProps {
  hasEditPermissions: boolean
  isCreateMode: boolean
  values: CreateUpdateGlobalProps
  isEditMode: boolean
  isShowMode: boolean
  update: Update
}

const useShareSettingsAction = ({
  update,
  hasEditPermissions,
  isCreateMode,
  values,
  isEditMode,
  isShowMode,
}: UseShareSettingsActionProps) => {
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const wasUpdateCreatedInCurrentGroup = update?.groupId === currentGroupId
  const isUserAbleToEdit =
    update?.visibility === ONLY_ME ? wasUpdateCreatedInCurrentGroup : true

  const isShareSettingsEditable =
    isUserAbleToEdit && (hasEditPermissions || isCreateMode)

  const isTransactionType = values.updateType === UpdateTypes.TRANSACTION

  const isInvestorTransactionFundType =
    values.transactionFundType === TransactionFundType.INVESTOR

  const investor = values?.investor

  const disableSettingsOnTransaction =
    isTransactionType && isInvestorTransactionFundType && !investor

  const { defaultGroupsPermissions, groupsAndUsersPermissions } =
    useVisibilityHelpers({
      values,
      isEditMode,
      isShowMode,
      update,
    })

  return {
    isShareSettingsEditable,
    disableSettingsOnTransaction,
    defaultGroupsPermissions,
    groupsAndUsersPermissions,
  }
}

export default useShareSettingsAction
