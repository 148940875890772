import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './LoginField.module.scss'

const LoginField = React.forwardRef(
  ({ error, name, className, ...rest }, ref) => (
    <div className={classNames(styles.loginFieldWrapper, className)}>
      <input
        ref={ref}
        name={name}
        className={`${styles.loginField} ${
          error?.message && styles.fieldWithError
        }`}
        {...rest}
      />
      <span className={styles.fieldErrorMessage}>{error?.message}</span>
    </div>
  )
)

LoginField.defaultProps = {
  error: null,
  className: '',
}

LoginField.propTypes = {
  error: PropTypes.object,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default LoginField
