import React from 'react'
import { Helmet } from 'components/Helmet'
import Drawer from 'components/Drawer'
import { useIntl } from 'react-intl'
import Separator from 'ui/Separator'
import Button from 'ui/Button'
import {
  InvestmentVehicleFormValues,
  InvestorManagementSidebarProps,
} from 'utils/types/investors'
import { Formik } from 'formik'
import { getInvestmentVehicleSchema } from 'utils/schemas/investorManagement'
import { getEditInvestmentVehicleInitialValues } from 'utils/functions/investorManagement'
import InvestmentVehicleForm from '../InvestorVehicleForm/InvestmentVehicleForm'
import { useEditInvestmentVehicle } from './useEditInvestmentVehicle'

const EditInvestor: React.FC<InvestorManagementSidebarProps> = ({
  onCloseDrawer,
  isDrawerOpen,
}) => {
  const intl = useIntl()

  const {
    investmentVehicle,
    isLoading,
    isInvestorGroup,
    editInvestmentVehicle,
    onCreateNewInvestor,
  } = useEditInvestmentVehicle(onCloseDrawer)

  if (!investmentVehicle) return null

  return (
    <Drawer
      isOpen={isDrawerOpen}
      isLoading={isLoading}
      onCloseDrawer={onCloseDrawer}
      headerTitle={intl.formatMessage({
        id: 'investorManagement.editInvestmentVehicle',
      })}
    >
      <Helmet>
        <title>
          {intl.formatMessage({
            id: 'metaTitles.editInvestor',
          })}
        </title>
      </Helmet>

      <Drawer.Content contentPadding="2.4rem">
        <Formik<InvestmentVehicleFormValues>
          initialValues={getEditInvestmentVehicleInitialValues(
            investmentVehicle!
          )}
          onSubmit={editInvestmentVehicle}
          validationSchema={getInvestmentVehicleSchema(intl)}
        >
          {(formik) => {
            return (
              <>
                <InvestmentVehicleForm
                  isInvestorGroupsSelectorVisible={!isInvestorGroup}
                  onCreateNewInvestor={onCreateNewInvestor}
                />

                <Separator />

                <Drawer.Footer>
                  <Button onClick={onCloseDrawer}>
                    {intl.formatMessage({ id: 'general.cancel' })}
                  </Button>
                  <Button
                    type="submit"
                    primary
                    onClick={formik.handleSubmit}
                    isDisabled={
                      !formik.values.name ||
                      !!formik.errors.name ||
                      !formik.values.investor ||
                      !!formik.errors.investor ||
                      formik.isSubmitting
                    }
                  >
                    {intl.formatMessage({ id: 'general.save' })}
                  </Button>
                </Drawer.Footer>
              </>
            )
          }}
        </Formik>
      </Drawer.Content>
    </Drawer>
  )
}

export default EditInvestor
