import { lazy } from 'react'
import { Redirect, useRouteMatch, Route, Switch } from 'react-router-dom'
import useRoute from 'routes/hooks/useRoutes'
import { getCurrentCompany } from 'selectors/company'
import { isActingAsFounder, getCurrentGroupCompaniesIds } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import UpdateAllMetricsModal from 'containers/Metrics/UpdateAllMetricsModal/UpdateAllMetricsModal'
import CompanyProfileEditDrawer from 'containers/Profiles/CompanyProfileEditDrawer'
import CompanyIdRoutes from './CompanyIdRoutes'
import AccountingRoutes from './AccountingRoutes'
import SidebarMetricsRoutes from './SidebarMetricsRoutes'
import {
  COMPANY_PROFILE_ACCOUNTING_UPDATES,
  COMPANY_PROFILE_ANNOUNCEMENTS_UPDATES,
  COMPANY_PROFILE_DOCUMENTS_UPDATES,
  COMPANY_PROFILE_EMAILS_UPDATES,
  COMPANY_PROFILE_NOTES_UPDATES,
  COMPANY_PROFILE_TRANSACTIONS_UPDATES,
  EMAIL_CREATION,
  COMPANY_PROFILE_ROUTES,
  COMPANIES_METRICS_ID,
  COMPANIES_METRICS,
  COMPANIES_METRICS_UPDATE_ALL,
  COMPANIES_ALL_TABS_EDIT,
  CREATE_NOTE_FROM_SUGGESTED,
  CREATE_DOCUMENT_FROM_SUGGESTED,
  CREATE_TRANSACTION_FROM_SUGGESTED,
} from './constant/companiesRoutes'
import { COMPANIES } from './constant/fundManagerRoutes'

const NotesRoutes = lazy(() => import('./NotesRoutes'))
const DocumentsRoutes = lazy(() => import('./DocumentsRoutes'))
const AnnouncementsRoutes = lazy(() => import('./AnnouncementsRoutes'))
const TransactionsRoutes = lazy(() => import('./TransactionsRoutes'))

const CreateEmail = lazy(() => import('containers/CreateEmail'))
const EmailsRoutes = lazy(() => import('./EmailsRoutes'))
const CompanyProfileRouteWrapper = lazy(() =>
  import('containers/Profiles/CompanyProfile/CompanyProfileRouteWrapper')
)
const HoldingIndex = lazy(() => import('containers/HoldingIndex'))

const getPaths = (paths) => paths.map(({ path }) => path).flat()

const CompaniesRoutes = () => {
  const { path: subPath } = useRouteMatch()
  const { id: companyId } = useAppSelector(getCurrentCompany)
  const userIsActingAsFounder = useAppSelector(isActingAsFounder)
  const currentGroupCompaniesId = useAppSelector(getCurrentGroupCompaniesIds)
  const planFeatures = usePlanFeatures()
  const { renderPaths } = useRoute()

  const paddedRoutes = [
    {
      path: COMPANY_PROFILE_ANNOUNCEMENTS_UPDATES.replace(
        '/companies',
        subPath
      ),
      key: 'announcements',
      component: <AnnouncementsRoutes />,
    },
    {
      path: [
        COMPANY_PROFILE_NOTES_UPDATES.replace('/companies', subPath),
        CREATE_NOTE_FROM_SUGGESTED,
      ],
      key: 'notes',
      component: <NotesRoutes />,
    },
    {
      path: [
        COMPANY_PROFILE_DOCUMENTS_UPDATES.replace('/companies', subPath),
        CREATE_DOCUMENT_FROM_SUGGESTED,
      ],
      key: 'documents',
      component: <DocumentsRoutes />,
    },
    {
      path: [
        COMPANY_PROFILE_TRANSACTIONS_UPDATES.replace('/companies', subPath),
        CREATE_TRANSACTION_FROM_SUGGESTED,
      ],
      key: 'transactions',
      component: <TransactionsRoutes />,
    },
    {
      path: COMPANY_PROFILE_EMAILS_UPDATES.replace('/companies', subPath),
      key: 'emails',
      component: <EmailsRoutes />,
    },
    {
      path: COMPANY_PROFILE_ACCOUNTING_UPDATES.replace('/companies', subPath),
      key: 'accounting',
      component: <AccountingRoutes />,
    },
    {
      path: COMPANIES.replace('/companies', subPath),
      key: 'companies',
      hidden: userIsActingAsFounder,
      component: <HoldingIndex />,
      exact: true,
    },
  ].filter(({ hidden }) => !hidden)

  const nonPaddedRoutes = [
    {
      path: EMAIL_CREATION.replace('/companies', subPath),
      key: 'emailId',
      component: <CreateEmail />,
      exact: true,
    },
    {
      path: COMPANY_PROFILE_ROUTES.map((route) =>
        route.replace('/companies', subPath)
      ),
      key: 'companyId',
      component: <CompanyProfileRouteWrapper />,
      exact: true,
    },
    {
      path: subPath,
      key: 'companies',
      hidden: !userIsActingAsFounder,
      component: <Redirect to={`${subPath}/${currentGroupCompaniesId[0]}`} />,
      exact: true,
    },
  ].filter(({ hidden }) => !hidden)

  return (
    <Route path={[...getPaths(nonPaddedRoutes), ...getPaths(paddedRoutes)]}>
      <CompanyIdRoutes>
        <Route path={getPaths(nonPaddedRoutes)}>
          {renderPaths({ routes: nonPaddedRoutes })}
        </Route>

        {planFeatures.areMetricsEnabled && (
          <Switch path={subPath}>
            <Route
              exact
              path={COMPANIES_METRICS_UPDATE_ALL.replace(
                ':companyId',
                companyId
              )}
            >
              <UpdateAllMetricsModal />
            </Route>

            <Route path={COMPANIES_METRICS_ID.replace('/companies', subPath)}>
              <SidebarMetricsRoutes
                urlPrefix={COMPANIES_METRICS.replace(':companyId', companyId)}
              />
            </Route>
          </Switch>
        )}

        <Route path={getPaths(paddedRoutes)}>
          {renderPaths({ routes: paddedRoutes, addPaddedLayout: true })}
        </Route>

        <Route path={COMPANIES_ALL_TABS_EDIT.replace('/companies', subPath)}>
          <CompanyProfileEditDrawer />
        </Route>
      </CompanyIdRoutes>
    </Route>
  )
}
export default CompaniesRoutes
