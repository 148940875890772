import { useQuery } from '@tanstack/react-query'
import { StreamChatType } from 'containers/Chat/types'
import { groupBy, uniqBy } from 'lodash'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser } from 'selectors/auth'
import type {
  ArrayOneOrMore,
  ArrayTwoOrMore,
  Channel,
  ChannelFilters,
  MessageResponse,
  QueryFilters,
  UserFilters,
  UserResponse,
} from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import { ArrayMap } from 'utils/functions/array'
import { chatKeys } from 'utils/queries/chat'

export type MessageSearchResult = ArrayMap<
  Channel<StreamChatType>,
  MessageResponse<StreamChatType>
>

type ChannelQuery = UserFilters<StreamChatType> | ChannelFilters<StreamChatType>

export const useSearchMessages = (text: string) => {
  const { client } = useChatContext<StreamChatType>()
  const user = useAppSelector(getUser)

  return useQuery<MessageSearchResult>(
    chatKeys.searchMessages(text, user.id),
    async () => {
      if (text) {
        const searchResults = await client.search(
          { members: { $in: [user.id] } },
          { text: { $autocomplete: text } },
          {
            limit: 100,
            offset: 0,
          }
        )

        const messagesByChannel = groupBy(
          searchResults.results.map((msg) => msg.message),
          (message) => message.channel?.id
        )

        return Promise.all(
          Object.entries(messagesByChannel).map(
            async ([channelId, messagesForChannel]) => {
              const [channel] = await client.queryChannels({
                id: { $eq: channelId },
              })

              return {
                key: channel,
                values: messagesForChannel,
              }
            }
          )
        )
      }
      return []
    },
    { enabled: !!text }
  )
}

export const useSearchChannels = (text: string) => {
  const { client } = useChatContext<StreamChatType>()
  const user = useAppSelector(getUser)

  return useQuery<Channel<StreamChatType>[]>(
    chatKeys.searchChannels(text, user.id),
    async () => {
      if (text) {
        return client.queryChannels({
          members: { $in: [user.id] },
          name: {
            $autocomplete: text,
          },
        })
      }
      return Promise.resolve([])
    },
    { enabled: !!text }
  )
}

export const useSearchParticipants = (searchTexts: string[]) => {
  const { client } = useChatContext<StreamChatType>()
  const currentUser = useAppSelector(getUser)

  return useQuery<Channel<StreamChatType>[]>(
    chatKeys.searchParticipants(searchTexts, currentUser.id),
    async () => {
      const nonEmptySearchTexts = searchTexts.filter(Boolean)
      if (nonEmptySearchTexts.length) {
        const searchs = nonEmptySearchTexts.map<ChannelQuery>((txt) => ({
          name: { $autocomplete: txt },
        }))

        const membersResult = await client.queryUsers(
          searchs.length >= 2
            ? {
                $or: searchs as ArrayTwoOrMore<ChannelQuery>,
              }
            : searchs[0],
          { created_at: -1 },
          {
            limit: 100,
            offset: 0,
          }
        )
        if (membersResult.users?.length > 0) {
          const andFilters: ArrayOneOrMore<QueryFilters<ChannelQuery>> = [
            {
              members: {
                $in: [currentUser.id],
              },
            },
          ]

          const usersPerSearchText = nonEmptySearchTexts.reduce<
            UserResponse<StreamChatType>[][]
          >((res, txt) => {
            res.push(
              membersResult.users.filter((user) =>
                user.name?.toLowerCase().includes(txt.toLowerCase())
              )
            )
            return res
          }, [])

          usersPerSearchText.forEach((usersForSearchText) => {
            if (usersForSearchText.length > 0) {
              andFilters.push({
                members: {
                  $in: usersForSearchText.map((user) => user.id),
                },
              })
            }
          })

          const searchResults = await client.queryChannels(
            {
              $and: andFilters as ArrayOneOrMore<QueryFilters>,
            },
            { created_at: -1 },
            {
              limit: 100,
              offset: 0,
            }
          )
          return uniqBy(searchResults, (channel) => channel.id)
        }
      }
      return Promise.resolve([])
    },
    { enabled: !!searchTexts?.length }
  )
}

export const useQueryChannels = (
  channelIds: string[],
  onSuccess?: (channels: Channel<StreamChatType>[]) => void
) => {
  const { client } = useChatContext<StreamChatType>()
  const user = useAppSelector(getUser)

  return useQuery<Channel<StreamChatType>[]>(
    chatKeys.getChannelsByIds(channelIds),
    async () => {
      if (channelIds.length) {
        return client.queryChannels({
          id: { $in: channelIds },
          members: { $in: [user.id] },
        })
      }
      return Promise.resolve([])
    },
    { onSuccess }
  )
}
