import { BaseSyntheticEvent, useCallback, useState } from 'react'
import { useMessageContext } from 'stream-chat-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from 'utils/theme'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { OPEN_THREAD_EVENT } from 'containers/Chat/hooks/useIsThreadOpen'

import DeleteMessageModal from '../DeleteMessageModal/DeleteMessageModal'
import ReactionSelector from '../ReactionSelector'
import * as Styles from './MessageActions.styles'

const getPosition = (
  isMyMessage: boolean,
  isMobile: boolean,
  isAThreadMessage: boolean
) => {
  const bottom = '1.8rem'

  if (isMobile) {
    if (isAThreadMessage) {
      if (isMyMessage) {
        return { right: '-8.9rem', bottom }
      }

      return { right: '-2.5rem', bottom }
    }

    if (isMyMessage) {
      return { right: '-12.1rem', bottom }
    }

    return { right: '-5.7rem', bottom }
  }

  if (isAThreadMessage && isMyMessage) {
    return { right: '-8.9rem', bottom }
  }

  return { right: '1.1rem', bottom: '-2rem' }
}

const MessageActions = () => {
  const {
    threadList,
    showDetailedReactions,
    reactionSelectorRef,
    onReactionListClick,
    handleOpenThread,
    setEditingState,
    isMyMessage,
  } = useMessageContext()
  const { matches: isMobile } = useMediaQuery(maxSize.xsm)

  const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false)

  const openDeleteMessageModal = useCallback(() => {
    setShowDeleteMessageModal(true)
  }, [])

  const closeDeleteMessageModal = useCallback(() => {
    setShowDeleteMessageModal(false)
  }, [])

  const onOpenThread = useCallback(
    (event: BaseSyntheticEvent) => {
      dispatchEvent(OPEN_THREAD_EVENT)
      handleOpenThread(event)
    },
    [handleOpenThread]
  )

  return (
    <>
      <Styles.MessageActions>
        <Styles.MessageAction onClick={onReactionListClick}>
          {showDetailedReactions && (
            <ReactionSelector
              position={getPosition(isMyMessage(), isMobile, !!threadList)}
              ref={reactionSelectorRef}
            />
          )}
          <FontAwesomeIcon icon={['far', 'smile']} />
        </Styles.MessageAction>
        {!threadList && (
          <Styles.MessageAction onClick={onOpenThread}>
            <FontAwesomeIcon icon={['far', 'comment']} />
          </Styles.MessageAction>
        )}

        {isMyMessage() && (
          <Styles.MessageAction onClick={setEditingState}>
            <FontAwesomeIcon icon={['far', 'pen']} />
          </Styles.MessageAction>
        )}

        {isMyMessage() && (
          <Styles.MessageAction onClick={openDeleteMessageModal} isDelete>
            <FontAwesomeIcon
              icon={['far', 'trash-alt']}
              color={theme.colors.red}
            />
          </Styles.MessageAction>
        )}
      </Styles.MessageActions>
      <DeleteMessageModal
        show={showDeleteMessageModal}
        onHide={closeDeleteMessageModal}
      />
    </>
  )
}

export default MessageActions
