import ProtectedRoute from 'components/ProtectedRoute'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import UpdatesRoutes from '../UpdatesRoutes'
import EmailsRoutes from '../EmailsRoutes'
import MetricsRoutes from '../MetricsRoutes'
import {
  EMAILS,
  METRICS_TAB,
  PORTFOLIOS,
  UPDATES,
} from '../constant/commonRoutes'
import PortfolioRoutes from '../PortfolioRoutes'
import InvestorCommonRoutes from './InvestorCommonRoutes'

const CommonRoutes = () => {
  const planFeatures = usePlanFeatures()

  return (
    <>
      <ProtectedRoute path={UPDATES}>
        <UpdatesRoutes />
      </ProtectedRoute>

      <ProtectedRoute path={EMAILS}>
        <EmailsRoutes />
      </ProtectedRoute>

      {planFeatures.areMetricsEnabled && (
        <ProtectedRoute path={METRICS_TAB}>
          <MetricsRoutes />
        </ProtectedRoute>
      )}

      <ProtectedRoute path={PORTFOLIOS}>
        <PortfolioRoutes />
      </ProtectedRoute>

      <InvestorCommonRoutes />
    </>
  )
}

export default CommonRoutes
