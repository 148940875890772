import TagService from 'api/TagService'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import {
  getUserId,
  isActingAsClient,
  isActingAsFounder,
  isActingAsInvestorGroup,
} from 'selectors/auth'
import { getUpdateTypesFilterOptions } from 'utils/functions/renderers/renderPortfolioHelper'
import {
  UpdatesTagsFilterOptions,
  UpdatesTypesFilterOptions,
} from 'utils/types/portfolios'
import { Tag } from 'utils/types/update'
import { useAppSelector } from './reduxToolkit'

export enum StatusFilters {
  ALL = 'all',
  PUBLISHED = 'published',
  DRAFT = 'draft',
  SUGGESTED = 'suggested',
}

export interface FiltersDataSet {
  types?: UpdatesTypesFilterOptions[]
  typesNotIncluded?: string[]
  tags?: UpdatesTagsFilterOptions[]
  userWithDraftUpdates?: string
  searchText: string
  status?: StatusFilters
  [StatusFilters.PUBLISHED]?: boolean
  [StatusFilters.SUGGESTED]?: boolean
  [StatusFilters.DRAFT]?: boolean
}

interface Props {
  isSearchByTagAllowed: boolean
  filters: FiltersDataSet
  withTransactionOptions?: boolean
  setFilters: (filters: FiltersDataSet) => void
}

// TODO: (Updates v2) rename to useUpdatesFilters, and rename the useUpdatesFilters to useUpdatesPortfoliosFilters (since I think it would only be used in portfolios)
export const useUpdatesProfilesFilter = ({
  isSearchByTagAllowed,
  filters,
  withTransactionOptions,
  setFilters,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()
  const isAClient = useAppSelector(isActingAsClient)
  const isFounder = useAppSelector(isActingAsFounder)
  const isInvestor = useAppSelector(isActingAsInvestorGroup)
  const isComingFromUpdatesView = history.location.pathname === '/updates'
  const isComingFromDashboard = history.location.pathname === '/dashboard'
  const isLookingAtInvestorProfile = history.location.pathname.includes(
    '/investor-management/investors'
  )
  const isLookingAtFundProfile = history.location.pathname.includes('/funds/')

  const updateTypesOptions = getUpdateTypesFilterOptions({
    intl,
    isActingAsClient: isAClient,
    withTransactionOptions,
    isActingAsFounder: isFounder,
    isComingFromUpdatesView,
    isComingFromDashboard,
    isInvestor,
    isLookingAtInvestorProfile,
    isLookingAtFundProfile,
  })
  const currentUserId = useAppSelector(getUserId)
  const [filterDataSet, setFilterDataSet] = useState<FiltersDataSet>({
    types: updateTypesOptions,
    tags: [],
    searchText: '',
    userWithDraftUpdates: '',
  })

  const getTags = useCallback(async () => {
    const {
      data: {
        entities: { tags = {} },
      },
    } = await TagService.getTags()

    const tagOptions: Tag[] = Object.values(tags)

    if (tagOptions?.length > 0) {
      setFilterDataSet((prevDataSet) => ({
        types: prevDataSet.types,
        tags: tagOptions.map((tag) => ({
          id: tag.id,
          name: tag.name,
          label: tag.name,
        })),
        searchText: prevDataSet.searchText,
      }))
    }
  }, [])

  const onChangeTypeFilters = (typeFilter: UpdatesTypesFilterOptions) => {
    const typeWasSelected = filters.types?.find(
      (type) => type.id === typeFilter.id
    )
    let newTypes: UpdatesTypesFilterOptions[] | undefined

    if (typeWasSelected) {
      newTypes = filters.types?.filter((type) => type.id !== typeFilter.id)
    } else {
      newTypes = (filters.types || [])?.concat(typeFilter)
    }

    setFilters({
      ...filters,
      types: newTypes,
    })
  }

  const onToggleDraftOnly = () => {
    const userWithDraftUpdates = filters.userWithDraftUpdates
      ? ''
      : currentUserId
    setFilters({
      ...filters,
      userWithDraftUpdates,
    })
  }

  const onChangePublishedFilter = (publishedFilter: boolean) => {
    setFilters({
      ...filters,
      [StatusFilters.PUBLISHED]: publishedFilter || undefined,
    })
  }

  const onChangeDraftFilter = (draftFilter: boolean) => {
    setFilters({
      ...filters,
      [StatusFilters.DRAFT]: draftFilter || undefined,
    })
  }

  const onChangeSuggestedFilter = (suggestedFilter: boolean) => {
    setFilters({
      ...filters,
      [StatusFilters.SUGGESTED]: suggestedFilter || undefined,
    })
  }

  const onChangeTagFilters = (tagFilter: UpdatesTagsFilterOptions) => {
    const tagWasSelected = filters.tags?.find((tag) => tag.id === tagFilter.id)
    let newTags: UpdatesTagsFilterOptions[] | undefined

    if (tagWasSelected) {
      newTags = filters.tags?.filter((tag) => tag.id !== tagFilter.id)
    } else {
      newTags = (filters.tags || [])?.concat(tagFilter)
    }

    setFilters({
      ...filters,
      tags: newTags,
    })
  }

  const onClearFilters = () => {
    setFilters({
      searchText: '',
      [StatusFilters.PUBLISHED]: true,
      [StatusFilters.SUGGESTED]: true,
      [StatusFilters.DRAFT]: true,
    })
  }

  useEffect(() => {
    if (isSearchByTagAllowed) {
      getTags()
    }
  }, [getTags, isSearchByTagAllowed])

  return {
    filterDataSet,
    filters,
    onChangePublishedFilter,
    onChangeDraftFilter,
    onChangeSuggestedFilter,
    onChangeTypeFilters,
    onChangeTagFilters,
    onClearFilters,
    onToggleDraftOnly,
  }
}
