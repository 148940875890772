import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.3rem;
`

export const Separator = styled.div`
  flex: 1;
  border-top: 1px solid ${color('veryLightGray')};
`

export const Text = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('lightGray')};
  margin: 0 2.4rem;
`
