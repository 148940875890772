import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { CUSTOM_GROUP } from 'utils/functions/updates'

import styles from './CheckboxTextContent.module.scss'

const CheckboxTextContent = ({
  type,
  name,
  icon,
  hideDescription,
  customDescription,
  checked,
}) => {
  const intl = useIntl()

  return (
    <>
      <div className={styles.checkboxContentContainer}>
        <p className={classNames({ [styles.checkedOption]: checked })}>
          {type === CUSTOM_GROUP ? name : intl.messages[`updates.${type}Text`]}
        </p>
        {icon && (
          <FontAwesomeIcon
            className={classNames({ [styles.checkedOption]: checked })}
            icon={icon}
          />
        )}
      </div>
      <p className={styles.description}>
        {customDescription}
        {!hideDescription &&
          (type === CUSTOM_GROUP
            ? !customDescription &&
              intl.formatMessage({ id: `updates.${type}Description` }, { name })
            : intl.messages[`updates.${type}Description`])}
      </p>
    </>
  )
}

CheckboxTextContent.propTypes = {
  type: PropTypes.string.isRequired,
  icon: PropTypes.array,
  name: PropTypes.string,
  customDescription: PropTypes.string,
  hideDescription: PropTypes.bool,
  checked: PropTypes.bool,
}

CheckboxTextContent.defaultProps = {
  name: '',
  icon: null,
  customDescription: '',
  hideDescription: false,
  checked: false,
}

export default CheckboxTextContent
