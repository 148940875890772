import { useIntl } from 'react-intl'
import { useFormikContext } from 'components/Form/hooks'

import { TeamMembersFormValues } from 'utils/types/investors'

import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'

import Input from 'ui/Input'
import Separator from 'ui/Separator'
import TeamMembersList from '../components/TeamMembersList'

import { SelectorWrapper } from './InvestorForm.styles'

const TeamMembersForm: React.FC = () => {
  const intl = useIntl()

  const { values, errors, touched, handleChange, handleBlur, getFieldName } =
    useFormikContext<TeamMembersFormValues>()

  return (
    <div>
      <SelectorWrapper maxWidth="24.6rem">
        <Input
          id={getFieldName('teamSize')}
          name={getFieldName('teamSize')}
          type="number"
          label={intl.formatMessage({
            id: 'investorManagement.teamSize',
          })}
          placeholder={intl.formatMessage({
            id: 'investorManagement.addTotalEmployees',
          })}
          value={values.teamSize}
          onChange={handleChange}
          error={buildFormError(
            errors?.teamSize,
            ErrorType.ERROR,
            touched?.teamSize
          )}
          onBlur={handleBlur}
          inputCustomStyles={{
            fontWeight: 400,
            fontSize: '1.6rem',
            marginLeft: 0,
          }}
        />
      </SelectorWrapper>

      <Separator height="0.8rem" />

      <TeamMembersList />
    </div>
  )
}

export default TeamMembersForm
