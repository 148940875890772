/* eslint-disable react-hooks/exhaustive-deps */
import { setCustomLoginData } from 'features/authSlice'
import GroupService from 'api/GroupService'
import { useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { getCurrentGroupData, getCustomLoginData } from 'selectors/auth'
import { GroupTypes } from 'utils/constants/groups'
import { useAppDispatch, useAppSelector } from './reduxToolkit'
import { usePreference } from './usePreference'

export const useCustomLogin = (shouldFetch = true) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const currentGroup = useAppSelector(getCurrentGroupData)
  const customLoginGroupData = useAppSelector(getCustomLoginData)
  const customLoginRouteMatch = useRouteMatch({
    path: '/sign-in/:handle',
    exact: true,
  })
  const [, setCustomLogin] = usePreference('customLogin')

  const isCustomLogin = customLoginRouteMatch?.isExact
  const handle = customLoginRouteMatch?.params?.handle
  const isPreviewMode = location.state?.previewMode
  const customLoginData = isPreviewMode ? currentGroup : customLoginGroupData
  const isCustomLoginEnabled = customLoginData?.type === GroupTypes.FUND_MANAGER
  const isLoadingCustomLogin = isCustomLogin && !customLoginData

  useEffect(() => {
    ;(async () => {
      if (
        shouldFetch &&
        isCustomLogin &&
        handle &&
        !isPreviewMode &&
        !customLoginGroupData
      ) {
        try {
          const response = await GroupService.getCustomLoginData(
            customLoginRouteMatch.params.handle
          )
          const group = response?.data?.entities?.groups[response?.data?.result]
          await dispatch(setCustomLoginData(group))
        } catch (err) {
          if (err.status === 404) {
            history.replace('/sign-in')
          }
        }
      }
    })()
  }, [isCustomLogin])

  const { color, loginImage, loginLogo } = customLoginData ?? {}

  const onLoginByCustomLogin = () => {
    setCustomLogin({
      hasSignedInOnCustomLogin: true,
      customLoginHandle: handle,
    })
  }
  return {
    customLoginGroupId: customLoginData?.id,
    isCustomLogin,
    isCustomLoginEnabled,
    isCustomLoginPreviewMode: isPreviewMode,
    isLoadingCustomLogin,
    handle,
    color,
    loginImage,
    loginLogo,
    onLoginByCustomLogin,
  }
}
