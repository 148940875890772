import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import {
  FUND_HOLDINGS_COMPANIES_INVESTMENTS,
  FUND_HOLDINGS_COMPANIES_UPDATES,
  FUND_HOLDINGS_FUNDS_INVESTMENTS,
  FUND_HOLDINGS_FUNDS_UPDATES,
} from 'routes/constant/InvestorGroupRoutes'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import { INVESTMENTS } from 'routes/constant/investmentsRoutes'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import EntityLink from 'components/Breadcrumb/Components/EntityLink'
import { Update } from 'utils/types/update'
import { CompanyHoldingData, FundProfile } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'
import useInvestmentsBreadcrumb from '../useInvestmentsBreadcrumb'

interface Props {
  update?: Update
  entityToShow?: FundProfile | CompanyHoldingData
  url: URL
  isShowingCompany: boolean
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
  investmentOptionSelected: any
  isManipulatingUpdate: boolean
  getAvatar: () => Nullable<JSX.Element>
  showInitialLink: boolean
}

const InvestorDashboardBreadcrumb = ({
  entityToShow,
  getAvatar,
  isCreatingNewUpdate,
  isShowingCompany,
  initialLinkRedirect,
  update,
  url,
  showInitialLink,
  investmentOptionSelected,
  isManipulatingUpdate,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  const { displayNavigationOptionBreadcrumbItem } = useInvestmentsBreadcrumb({
    initialLinkRedirect,
    url,
    investmentOptionSelected,
  })

  const companiesActiveTabs = {
    accessCompanyFromInvestmentsHoldingsTable: [
      FUND_HOLDINGS_COMPANIES_UPDATES.replace(':companyId', entityToShow?.id!),
      FUND_HOLDINGS_COMPANIES_INVESTMENTS.replace(
        ':companyId',
        entityToShow?.id!
      ),
    ],

    accessFundFromInvestmentsHoldingsTable: [
      FUND_HOLDINGS_FUNDS_UPDATES.replace(':fundId', entityToShow?.id!),
      FUND_HOLDINGS_FUNDS_INVESTMENTS.replace(':fundId', entityToShow?.id!),
    ],
  }

  const isActiveEntity = () => {
    const activeTabs = isShowingCompany
      ? companiesActiveTabs.accessCompanyFromInvestmentsHoldingsTable
      : companiesActiveTabs.accessFundFromInvestmentsHoldingsTable

    return activeTabs.includes(url.current)
  }

  const isSelectingUpdateOnFirstHolding =
    isManipulatingUpdate && url.current.includes('/investments')

  const isSelectingUpdateOnSecondHolding =
    isManipulatingUpdate && url.current.includes('/updates')

  const showNavigationOption =
    !isSelectingUpdateOnFirstHolding || isSelectingUpdateOnSecondHolding

  return (
    <NavigationWrapper>
      {showInitialLink && (
        <>
          <InitialLink
            isActive={false}
            label={intl.formatMessage({ id: 'breadcrumb.investments' })}
            onClickHandler={() => history.push(INVESTMENTS)}
          />

          <ArrowWrapper>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </ArrowWrapper>
        </>
      )}

      {showNavigationOption && displayNavigationOptionBreadcrumbItem()}

      {entityToShow && (
        <>
          <EntityLink
            isActive={isActiveEntity()}
            label={entityToShow.name}
            entityLogo={getAvatar()}
            onClickHandler={() => {
              const entityUrl = isShowingCompany
                ? FUND_HOLDINGS_COMPANIES_UPDATES.replace(
                    ':companyId',
                    entityToShow?.id
                  )
                : FUND_HOLDINGS_FUNDS_UPDATES.replace(
                    ':fundId',
                    entityToShow?.id
                  )

              if (url.current !== entityUrl) {
                if (isSelectingUpdateOnSecondHolding) {
                  history.push(entityUrl)
                  return
                }
                history.push(initialLinkRedirect)
              }
            }}
          />

          {update && (
            <ArrowWrapper>
              <FontAwesomeIcon icon={['fas', 'chevron-right']} />
            </ArrowWrapper>
          )}
        </>
      )}

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default InvestorDashboardBreadcrumb
