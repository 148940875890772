import { useHistory, useLocation } from 'react-router-dom'
import React from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { itemAnimation, listAnimation } from 'utils/animations/list'
import { ReshareTokenSet } from 'utils/functions/updates'
import { getCurrentGroupId, getUserId } from 'selectors/auth'

import ReshareHistoryRow from './ReshareHistoryRow'
import {
  ReshareHistoryList,
  ReshareHistoryListItem,
} from './ReshareHistory.styles'

interface ReshareHistoryProps {
  resharesByToken: ReshareTokenSet[]
}

const ReshareHistory: React.FC<ReshareHistoryProps> = ({ resharesByToken }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const currentUserId = useAppSelector(getUserId)
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const redirectToEditReshareView = (tokenSet: ReshareTokenSet) => {
    const locationState = {
      reshareTokenSet: tokenSet,
    }

    history.push(`${pathname}/edit-reshare`, {
      ...locationState,
    })
  }

  return (
    <ReshareHistoryList
      initial="hidden"
      animate="visible"
      variants={listAnimation}
    >
      {resharesByToken.map((reshareTokenSet) => (
        <ReshareHistoryListItem
          key={reshareTokenSet.token}
          variants={itemAnimation}
        >
          <ReshareHistoryRow
            reshareTokenSet={reshareTokenSet}
            isEditable={
              reshareTokenSet.resharerGroupId === currentGroupId &&
              reshareTokenSet.resharerUserId === currentUserId
            }
            onEditReshare={redirectToEditReshareView}
          />
        </ReshareHistoryListItem>
      ))}
    </ReshareHistoryList>
  )
}

export default ReshareHistory
