import styled from 'styled-components'
import { color } from 'utils/functions/colors'

const TooltipContent = styled.div`
  background-color: white;
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
  color: ${color('gray')};
  padding: 0.4rem 0.8rem;
`

export default TooltipContent
