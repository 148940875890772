import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledNav,
  StyledAside,
  StyledAsideItem,
  StyledNavContent,
} from './NavMenu.styles'

const NavMenu = ({ children }) => {
  return <StyledNav>{children}</StyledNav>
}

NavMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const AsideMenu = ({ children }) => {
  return (
    <StyledAside>
      <ul>{children}</ul>
    </StyledAside>
  )
}

AsideMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

const AsideItem = ({ children, onClick, active }) => {
  return (
    <StyledAsideItem active={active}>
      <button type="button" onClick={onClick}>
        {' '}
        {children}
      </button>
    </StyledAsideItem>
  )
}

AsideItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

AsideItem.defaultProps = {
  active: false,
}

const NavContent = ({ children, className }) => {
  return <StyledNavContent className={className}>{children}</StyledNavContent>
}

NavContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
}

NavContent.defaultProps = {
  className: '',
}

NavMenu.AsideMenu = AsideMenu
NavMenu.AsideItem = AsideItem
NavMenu.Content = NavContent

export default NavMenu
