import { useEffect, useState } from 'react'

interface ChartScrollProps<T> {
  elements: T[]
  visibleElements: number
  limit?: number
}

interface ChartScrollResponse<T> {
  hasNext: boolean
  hasPrevious: boolean
  results: T[]
  onNextPage: () => void
  onPreviousPage: () => void
}

const useChartScroll = <T>({
  elements,
  visibleElements,
  limit = 1,
}: ChartScrollProps<T>): ChartScrollResponse<T> => {
  const [currentRange, setCurrentRange] = useState({
    start: 0,
    end: 0,
  })
  const [currentElements, setCurrentElements] = useState<T[]>([])

  const hasNext = currentRange.end < elements.length
  const hasPrevious = currentRange.start > 0

  const onNextPage = () => {
    if (currentRange.end < elements.length) {
      setCurrentRange((range) => ({
        start: range.start + limit,
        end: range.end + limit,
      }))
    }
  }

  const onPreviousPage = () => {
    if (currentRange.start >= limit) {
      setCurrentRange((range) => ({
        start: range.start - limit,
        end: range.end - limit,
      }))
    } else {
      setCurrentRange({
        start: 0,
        end: limit,
      })
    }
  }

  useEffect(() => {
    setCurrentElements(elements.slice(currentRange.start, currentRange.end))
  }, [elements, visibleElements, currentRange.start, currentRange.end])

  useEffect(() => {
    setCurrentRange({
      start:
        elements.length - visibleElements > 0
          ? elements.length - visibleElements
          : 0,
      end: elements.length,
    })
  }, [elements, visibleElements])

  return {
    hasNext,
    hasPrevious,
    results: currentElements,
    onNextPage,
    onPreviousPage,
  }
}

export default useChartScroll
