import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Heading.module.scss'

const Heading = ({ level, className, ...rest }) => {
  return React.createElement(level, {
    className: classNames(styles.heading, styles[level], className, {
      subheader: level === 'h2',
    }),
    ...rest,
  })
}

Heading.displayName = 'Heading'

Heading.defaultProps = {
  level: 'h1',
  className: '',
}

Heading.propTypes = {
  level: PropTypes.string,
  className: PropTypes.string,
}

export default React.memo(Heading)
