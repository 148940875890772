import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './GrowthIndicator.module.scss'

const GrowthValue = ({ growth, value, suffix, bold, padding }) => {
  return (
    <span
      className={classNames(styles.growthValue, {
        [styles.positiveGrowth]: growth,
        [styles.negativeGrowth]: !growth,
        [styles.bold]: bold,
      })}
      style={{ padding }}
    >
      {value}
      {suffix}
    </span>
  )
}

GrowthValue.propTypes = {
  growth: PropTypes.bool.isRequired,
  suffix: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  bold: PropTypes.bool,
  padding: PropTypes.string,
}

GrowthValue.defaultProps = {
  suffix: '',
  padding: '',
  bold: false,
}

export default GrowthValue
