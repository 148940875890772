import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2.4rem;
  border-top: 1px solid ${color('veryLightGray')};
  gap: 0.8rem;
  margin: 0 -2rem;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 8.5rem;
`
export const FormContent = styled.form``
