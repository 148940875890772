import { useQuery } from '@tanstack/react-query'
import type { UseQueryOptions } from '@tanstack/react-query'
import TagService from 'api/TagService'
import Toast from 'components/Toast'
import { GroupTag } from 'containers/Investments/InvestmentsFilters/types'
import { tagsKeys } from 'utils/queries/tags'
import { Tag } from 'utils/types/update'

type QueryOptions = Omit<
  UseQueryOptions,
  'queryKey' | 'queryFn' | 'initialData'
>

const useTagsQuery = (queryOptions: QueryOptions = {}) => {
  return useQuery({
    queryKey: tagsKeys.getAllTags(),
    queryFn: async () => {
      const {
        data: {
          entities: { tags = {} },
        },
      } = await TagService.getTags()

      const tagOptions: Tag[] = Object.values(tags)

      if (tagOptions?.length > 0) {
        return tagOptions.map(
          (tag): GroupTag => ({
            id: tag.id,
            isSelected: false,
            label: tag.name,
          })
        )
      }

      return []
    },
    onError: () => {
      Toast.displayIntl('errors.fetchTagsError', 'error')
    },
    ...queryOptions,
  })
}

export default useTagsQuery
