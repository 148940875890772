import SelectDatePicker from 'components/TimeSelect'
import {
  IconPosition,
  SelectDatePickerProps,
} from 'components/TimeSelect/types'
import React from 'react'
import useDatePicker from '../../AnnouncementForm/useDatePicker'

const DatePicker: React.FC<SelectDatePickerProps> = ({
  name,
  iconPosition = IconPosition.RIGHT,
  ...rest
}) => {
  const {
    field,
    isNewTimeSelected,
    canScheduleUpdate,
    onChangeDate,
    isEditMode,
  } = useDatePicker(name)

  const scheduleUpdateProps = canScheduleUpdate
    ? {
        showTimeSelect: true,
        maxDate: null,
      }
    : {}

  return (
    <SelectDatePicker
      name={name}
      error={field.buildError()}
      initialValue={field.value}
      value={field.value}
      handleChange={onChangeDate}
      iconPosition={iconPosition}
      onlyMonthYearPicker={false}
      dateFormat={
        isEditMode || isNewTimeSelected ? 'MMM dd yyyy - h:mm a' : 'MMM dd yyyy'
      }
      {...scheduleUpdateProps}
      {...rest}
    />
  )
}

export default DatePicker
