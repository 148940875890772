import { useQuery } from '@tanstack/react-query'
import type { Channel, StreamChat } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'

import {
  ChannelType,
  StreamChatType,
  TopicChannel,
} from 'containers/Chat/types'
import { useSetActiveChannel } from 'containers/Chat/hooks'

export const archivedChanelsKey = ['ARCHIVED_CHANNELS']

const sortByArchivedDateAndLastMessage = (
  channels: Channel<StreamChatType<TopicChannel>>[],
  client: StreamChat<StreamChatType<TopicChannel>>
) => {
  channels.sort((a, b) => {
    const now = new Date()
    const pinnedAtA = new Date(
      client.user?.pinnedChannels?.find(
        (pinnedChannel) => pinnedChannel.id === a.id
      )?.pinnedAt || now
    )
    const pinnedAtB = new Date(
      client.user?.pinnedChannels?.find(
        (pinnedChannel) => pinnedChannel.id === b.id
      )?.pinnedAt || now
    )
    const lastMessageA = a.state?.last_message_at || now
    const lastMessageB = b.state?.last_message_at || now
    const aDate = lastMessageA < pinnedAtA ? pinnedAtA : lastMessageA
    const bDate = lastMessageB < pinnedAtB ? pinnedAtB : lastMessageB

    if (aDate && bDate) {
      return bDate.getTime() - aDate.getTime()
    }

    return 0
  })
}

export const useArchivedChannelsQuery = (
  preventSelectArchivedChannelOnQuery: boolean
) => {
  const { client } = useChatContext<StreamChatType<TopicChannel>>()
  const setActiveChannel = useSetActiveChannel<TopicChannel>()

  return useQuery<Channel<StreamChatType<TopicChannel>>[]>(
    archivedChanelsKey,
    async () => {
      const pageSize = 30
      const pagesCount = Math.ceil(
        (client.user?.archivedChannels?.length ?? 0) / pageSize
      )
      let channels = [] as Channel<StreamChatType<TopicChannel>>[]
      let filters = {
        id: {
          $in: [] as string[],
        },
        type: {
          $in: [ChannelType.HOLDING, ChannelType.PORTFOLIO, ChannelType.UPDATE],
        },
        members: { $in: [client.userID!] },
      }

      for (let i = 0; i < pagesCount; i++) {
        const currentPage = client.user?.archivedChannels?.slice(
          i * pageSize,
          i * pageSize + pageSize
        )
        filters = {
          ...filters,
          id: {
            $in:
              currentPage?.map((archivedChannel) => archivedChannel.id) ?? [],
          },
        }

        // eslint-disable-next-line no-await-in-loop
        const newChannels = await client.queryChannels(filters, undefined, {
          limit: pageSize,
        })

        channels = channels.concat(newChannels)
      }

      sortByArchivedDateAndLastMessage(channels, client)
      if (!preventSelectArchivedChannelOnQuery) {
        setActiveChannel(channels[0])
      }

      return channels
    }
  )
}
