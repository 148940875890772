import React, { useMemo } from 'react'
import { useTipTapShowMoreLess } from 'components/ShowMoreLess'
import { useUpdatesFeedContext } from 'components/UpdatesFeedV2/UpdateFeedContext'
import { getUpdateItemText } from 'utils/functions/updates'
import { DraftHashEditor, IndexUpdate } from 'utils/types/update'

export const useDefaultUpdateCard = (update: IndexUpdate) => {
  const updateText = useMemo(() => getUpdateItemText(update), [update])
  const showMoreLess = useTipTapShowMoreLess({
    updateText,
  })
  const { onShowUpdate, onEditUpdate, onDeleteUpdate, onReshareUpdate } =
    useUpdatesFeedContext()

  const onShow = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement
    const isClickingTheReshareAnchorLink = target instanceof HTMLAnchorElement
    if (isClickingTheReshareAnchorLink) return

    event.stopPropagation()
    onShowUpdate(update)
  }

  const onEdit = (event: React.MouseEvent) => {
    event.stopPropagation()
    onEditUpdate!(update)
  }

  const onDelete = (event?: React.MouseEvent) => {
    event?.stopPropagation()
    onDeleteUpdate!(update)
  }

  const onReshare = (event?: React.MouseEvent) => {
    event?.stopPropagation()
    onReshareUpdate!(update)
  }

  return {
    onShow,
    onEdit,
    onDelete,
    onReshare,
    isEditBlocked: update.isEditBlocked,
    userEditor: (update.draftHash as DraftHashEditor)?.userEditing?.name,
    updateText,
    showMoreLess,
  }
}
