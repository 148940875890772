import { useEffect } from 'react'

export const useWindowResize = (cb) => {
  useEffect(() => {
    window.addEventListener('resize', cb)
    return () => {
      window.removeEventListener('resize', cb)
    }
  })
}
