import { createContext, useContext } from 'react'
import { MixedUpdate } from 'utils/types/update'
import { SelectedCardsState } from './useSelectUpdates/useSelectUpdatesReducer'

export type SelectUpdatesContextType = {
  selectedCards: SelectedCardsState
  selectAllUpdates: (updates: MixedUpdate[]) => void
  unSelectAllUpdates: () => void
  currentHoverCardId?: string
  handleToggleCard: (update: MixedUpdate) => void
  handleSelectCard: (update: MixedUpdate) => void
  handleUnSelectCard: (update: MixedUpdate) => void
  handleHoverCard: (update: MixedUpdate) => void
  handleUnHoverCard: () => void
  isCardSelected: (update: MixedUpdate) => boolean
  isCardHover: (update: MixedUpdate) => boolean
  enabled: boolean
  enabledForSuggestedUpdates: boolean
  isSelectingSuggestedUpdates: boolean
}

export const SelectUpdatesContext = createContext<SelectUpdatesContextType>({
  selectedCards: {},
  selectAllUpdates: () => {},
  unSelectAllUpdates: () => {},
  handleToggleCard: () => {},
  handleSelectCard: () => {},
  handleUnSelectCard: () => {},
  handleHoverCard: () => {},
  handleUnHoverCard: () => {},
  isCardSelected: () => false,
  isCardHover: () => false,
  enabled: false,
  enabledForSuggestedUpdates: false,
  isSelectingSuggestedUpdates: false,
})

export const useSelectUpdatesContext = () => {
  return useContext(SelectUpdatesContext)
}
