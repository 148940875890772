import { useState } from 'react'
import theme from 'utils/theme'
import { useToggleInvestorCalculations } from './useToggleInvestorCalculation'

import * as Styles from './ToggleInvestorCalculation.styles'

interface Props {
  portfolioId: string
  status?: boolean
  isDealPortfolio?: boolean
  onStatusChange?: (status: boolean) => void
}

const ToggleInvestorCalculation = ({
  portfolioId,
  status,
  isDealPortfolio,
  onStatusChange,
}: Props) => {
  const { isInvestorCalculationDisabled, toggleCalculations } =
    useToggleInvestorCalculations(portfolioId)

  const toggleStatus =
    status !== undefined ? status : isInvestorCalculationDisabled

  const [isIndividualSelected, setIsIndividualSelected] = useState(toggleStatus)

  return (
    <Styles.ToggleContainer>
      <Styles.StyledIcon
        disabled={isIndividualSelected}
        icon={['far', 'user-tie']}
      />
      <Styles.StyledToggle
        color={theme.colors.primaryBlue}
        status={isIndividualSelected}
        id="toggle-calculation"
        name="toggle-calculation"
        onChangeStatus={(isEnabled) => {
          if (onStatusChange) {
            onStatusChange(isEnabled)
          } else {
            toggleCalculations(isEnabled)
          }
          setIsIndividualSelected(isEnabled)
        }}
        disableAnimationOnMount
      />
      <Styles.StyledIcon
        disabled={!isIndividualSelected}
        icon={['far', isDealPortfolio ? 'badge-dollar' : 'sack-dollar']}
      />
    </Styles.ToggleContainer>
  )
}

export default ToggleInvestorCalculation
