import styled from 'styled-components'
import IconButton from 'ui/IconButton'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 1.6rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const Text = styled.p`
  font-size: 1.4rem;
  color: ${color('black')};
  max-width: 24rem;
  text-transform: capitalize;
`

export const MainTag = styled.div`
  background-color: ${color('primaryBlue')};
  border-radius: 10rem;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  height: fit-content;
  padding: 0.3rem 0.8rem;
  text-transform: uppercase;
  width: fit-content;
`

export const SetAsMainButton = styled.a`
  color: ${color('primaryBlue')};
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 700;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
`

export const Remove = styled(IconButton)`
  &:hover {
    background-color: transparent;
    background: transparent;

    > svg {
      color: var(--color-red-default);
    }
  }
`
