import {
  GroupContact,
  UserContact,
} from 'containers/ListManagement/components/ListForm/types'
import { ListGroup, ListUser } from 'utils/types/list'
import axiosClient from './httpClient'

export const LISTS_PAGE_SIZE = 5000

type EditListParams = {
  name: string
  addedUsers: UserContact[]
  removedUsers: ListUser[]
  addedGroups: GroupContact[]
  removedGroups: ListGroup[]
}
class ListService {
  static getLists = (params) => {
    return axiosClient().get(`/lists`, {
      params: { ...params, per_page: LISTS_PAGE_SIZE }, // TODO: fix the share settings search pagination [sc-13917]
    })
  }

  static getListById = (listId) => {
    return axiosClient().get(`/lists/${listId}`)
  }

  static editList = (
    listId: number,
    {
      name,
      addedUsers = [],
      removedUsers = [],
      addedGroups = [],
      removedGroups = [],
    }: EditListParams
  ) => {
    return axiosClient().patch(`/lists/${listId}`, {
      list: {
        name,
        listUsersAttributes: [
          ...addedUsers.map((user) => ({ userId: user.id })),
          ...removedUsers.map((user) => ({
            id: user.entityId,
            _destroy: true,
          })),
        ],
        listGroupsAttributes: [
          ...addedGroups.map((group) => ({ groupableId: group.id })),
          ...removedGroups.map((group) => ({
            id: group.entityId,
            _destroy: true,
          })),
        ],
      },
    })
  }

  static setListRecentlyUsed = (listId: number, lastUsedOnUpdate: Date) => {
    return axiosClient().patch(`/lists/${listId}`, {
      list: {
        lastUsedOnUpdate,
      },
    })
  }

  static removeList = (listId) => {
    return axiosClient().delete(`/lists/${listId}`)
  }

  static createList = (name: string, userIds: string[], groupIds: string[]) => {
    return axiosClient().post(`/lists`, {
      list: {
        name,
        listUsersAttributes: userIds.map((userId) => ({ userId })),
        listGroupsAttributes: groupIds.map((groupableId) => ({ groupableId })),
      },
    })
  }
}

export default ListService
