export const QUERIES = {
  USER: 'user',
  USER_PORTFOLIO: 'user_portfolio',
} as const

export const userKeys = {
  byEmail: (email: string) => [QUERIES.USER, email] as const,
  byId: (id: string) => [QUERIES.USER, id] as const,
  byUserPortfolioId: (userPortfolioId: string) =>
    [QUERIES.USER_PORTFOLIO, userPortfolioId] as const,
}
