import GroupService from 'api/GroupService'
import { GroupTypes } from 'utils/constants/groups'
import { NO_LIMIT } from 'utils/constants/tier'
import { AuthHeaders } from 'utils/types/signUp'
import { OnboardingSteps, Tier, TierRequestStatus } from 'utils/types/tier'
import { GroupType } from 'utils/types/user'

import { OnboardingStep } from 'components/OnboardingModal/useOnboardingModal'
import { assertUnreachable } from './utils'

// By default we are disabling all the features
const getInitialTier = (): Tier => {
  return {
    groupId: '',
    id: '',
    maxInvestPortfolios: 0,
    maxStorageInMb: 0,
    maxTrackPortfolios: 0,
    maxTransactions: 0,
    maxUsers: 0,
    plan: undefined,
    investPortfolios: 0,
    storageInMb: 0,
    trackPortfolios: 0,
    transactions: 0,
    users: 0,
    onboarding: {
      welcome: false,
      track: false,
      files: false,
      inbound: false,
    },
  }
}

const getDefaultTierWithAllFeaturesEnabled = (): Tier => {
  return {
    groupId: '',
    id: '',
    maxInvestPortfolios: NO_LIMIT,
    maxStorageInMb: NO_LIMIT,
    maxTrackPortfolios: NO_LIMIT,
    maxTransactions: NO_LIMIT,
    maxUsers: NO_LIMIT,
    plan: undefined,
    investPortfolios: 0,
    storageInMb: 0,
    trackPortfolios: 0,
    transactions: 0,
    users: 0,
    onboarding: {
      welcome: true,
      track: true,
      files: true,
      inbound: true,
    },
  }
}

export const getTier = (
  requestTierStatus: TierRequestStatus,
  tier?: Tier,
  groupType?: GroupType
) => {
  switch (requestTierStatus) {
    case TierRequestStatus.IDLE: {
      if (groupType === GroupTypes.CLIENT) {
        return getInitialTier()
      }
      return getDefaultTierWithAllFeaturesEnabled()
    }
    case TierRequestStatus.ERROR:
      return getInitialTier()
    // The group doesn't have a tier so we are enabling all the features
    case TierRequestStatus.NOT_FOUND:
      return getDefaultTierWithAllFeaturesEnabled()
    case TierRequestStatus.SUCCESS:
      // Tier should be always defined here, but in the sign out flow we are setting the tier to undefined
      return tier || getInitialTier()
    default:
      return assertUnreachable(requestTierStatus)
  }
}

export const fetchTier = async (headers?: AuthHeaders) => {
  try {
    const tier = await GroupService.getGroupTier(headers)
    return {
      tier,
      status: TierRequestStatus.SUCCESS,
    }
  } catch (e) {
    const tierNotFound = e.status === 404
    if (tierNotFound) {
      return { tier: undefined, status: TierRequestStatus.NOT_FOUND }
    }

    return { tier: undefined, status: TierRequestStatus.ERROR }
  }
}

export const getFirstUncompletedOnboardingStep = (
  steps: OnboardingSteps
): OnboardingStep | undefined => {
  const firstUncompletedStep = Object.entries(steps).find(
    ([_, value]) => !value
  )?.[0]

  switch (firstUncompletedStep) {
    case 'track':
      return OnboardingStep.SETUP_YOUR_PIPELINE
    case 'files':
      return OnboardingStep.ADD_YOUR_FILES
    case 'inbound':
      return OnboardingStep.SETUP_YOUR_INBOUND
    default:
      return undefined
  }
}
