import React from 'react'
import { FormattedMessage } from 'react-intl'

import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import { ChatHoldingTopic } from './HoldingAttachment'
import * as Styles from './HoldingAttachment.styles'

interface HoldingAttachmentBoxProps {
  holding: ChatHoldingTopic
}

const HoldingAttachmentBox: React.FC<HoldingAttachmentBoxProps> = ({
  holding,
}) => {
  return (
    <Styles.Container href={holding.link} target="_blank">
      {!!holding && (
        <>
          <Avatar
            image={holding.image}
            initials={getInitials(holding.name)}
            avatarStyle="avatarCircleHoldingLogo"
          />
          <div>
            <Styles.AttachmentTitle>{holding.name}</Styles.AttachmentTitle>
            <div>
              <FormattedMessage id={`chat.holdingType.${holding.type}`} />
            </div>
          </div>
        </>
      )}
    </Styles.Container>
  )
}

export default HoldingAttachmentBox
