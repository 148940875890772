import styled from 'styled-components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const ChipContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  flex-flow: wrap;
`
