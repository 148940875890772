import { useEffect, useState, useMemo } from 'react'
import { sortBy } from 'lodash'
import { HoldingsFilters } from 'api/HoldingsService'
import useInitialData from 'utils/hooks/useInitialData'
import { Industry } from 'utils/types/company'

interface UseHoldingFiltersProps {
  currentFilters: HoldingsFilters
  onChangeFilters: (activeFilters: HoldingsFilters) => void
  onToggleFiltersDrawer: () => void
}

const useHoldingFilters = ({
  currentFilters,
  onChangeFilters,
  onToggleFiltersDrawer,
}: UseHoldingFiltersProps) => {
  const { initialData, loading } = useInitialData()
  const [currentSectorId, setCurrentSectorId] = useState('')
  const [highlightedSectorsIds, setHighlightedSectorsIds] = useState<{
    [sectorId: string]: boolean
  }>(
    () =>
      currentFilters.sectorId?.reduce((res, sector) => {
        res[sector] = true
        return res
      }, {}) || {}
  )
  const [visibleIndustries, setVisibleIndustries] = useState<Industry[]>([])

  const sectorIndustries = useMemo(
    () =>
      sortBy(
        initialData?.industries.filter(
          (industry) => industry.sectorId === currentSectorId
        ) || [],
        ['name']
      ),
    [initialData?.industries, currentSectorId]
  )

  const getSectorsFromIndustries = (industries) => {
    if (!initialData || !currentFilters.industryId) return []

    return initialData.industries
      .filter((industry) => industries.indexOf(industry.id) !== -1)
      .map((industry) => industry.sectorId) as string[]
  }

  useEffect(() => {
    const selectedSectors = getSectorsFromIndustries(currentFilters.industryId)
    const newSectors = [
      ...selectedSectors,
      ...(currentFilters.sectorId as string[]),
    ].reduce((res, sector) => {
      res[sector] = true
      return res
    }, {})

    setHighlightedSectorsIds(() => ({ ...newSectors }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters.industryId, currentFilters.sectorId])

  useEffect(() => {
    setVisibleIndustries(sectorIndustries)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSectorId])

  const onSelectedSectorHandler = (sectorId) => {
    setCurrentSectorId(sectorId)
  }

  const onSelectedIndustryHandler = (industryId) => {
    const industryIndex = currentFilters.industryId?.findIndex(
      (industry) => industry === industryId
    )

    const industries =
      industryIndex === -1
        ? [...(currentFilters.industryId || []), industryId]
        : currentFilters.industryId?.filter((id) => id !== industryId)

    const industrySectorId = getSectorsFromIndustries([industryId])[0]

    if (industryIndex === -1) {
      setHighlightedSectorsIds((prev) => ({
        ...prev,
        [industrySectorId]: true,
      }))
    } else {
      const otherSectorIndustries = initialData?.industries.filter(
        (industry) =>
          industry.sectorId === currentSectorId && industry.id !== industryId
      )
      if (
        otherSectorIndustries?.every(
          (industry) => currentFilters.industryId?.indexOf(industry.id) === -1
        )
      ) {
        setHighlightedSectorsIds((prev) => ({
          ...prev,
          [currentSectorId]: false,
        }))
      }
    }

    onChangeFilters({
      ...currentFilters,
      industryId: industries,
      sectorId: currentFilters.sectorId?.filter(
        (sectorId) => sectorId !== industrySectorId
      ),
    })
  }

  const onSelectAllSectorsHandler = () => {
    setCurrentSectorId('')
    setHighlightedSectorsIds({})
    onChangeFilters({
      ...currentFilters,
      sectorId: [],
      industryId: [],
    })
  }

  const onSelectAllSectorHandler = () => {
    let activeSectors: string[] = []
    const currentSectorIndustries = initialData?.industries.filter(
      (industry) => industry.sectorId === currentSectorId
    )

    if (currentFilters.sectorId?.indexOf(currentSectorId) === -1) {
      activeSectors = [
        ...(currentFilters.sectorId as string[]),
        currentSectorId,
      ]
    } else {
      activeSectors =
        currentFilters.sectorId?.filter(
          (sectorId) => sectorId !== currentSectorId
        ) || []
    }

    setHighlightedSectorsIds((prev) => ({
      ...prev,
      [currentSectorId]: true,
    }))

    onChangeFilters({
      ...currentFilters,
      sectorId: activeSectors,
      industryId: [...(currentFilters.industryId as string[])].filter(
        (industryId) =>
          currentSectorIndustries?.findIndex(
            (industry) => industry.id === industryId
          ) === -1
      ),
    })
  }

  const onOpenDrawerFiltersHandler = () => {
    onToggleFiltersDrawer()
  }

  const isFilterApplied =
    !!currentFilters?.tags?.length ||
    !!currentFilters?.sectorId?.length ||
    !!currentFilters?.industryId?.length ||
    !!currentFilters?.countryId?.length ||
    !!currentFilters?.legalStructureId?.length ||
    !!currentFilters?.legalProvinceId?.length ||
    !!currentFilters?.sectorId?.length ||
    !!currentFilters?.onlyPortfolio ||
    !!currentFilters?.typeIn?.length ||
    !!currentFilters.locationPlaceAddress

  const allSectors = sortBy(
    initialData?.sectors.filter((sector) => sector.icon !== '') || [],
    ['name']
  )

  return {
    allSectors,
    currentSectorId,
    highlightedSectorsIds,
    isFilterApplied,
    loading,
    selectedIndustriesId: currentFilters?.industryId,
    visibleIndustries,
    onOpenDrawerFiltersHandler,
    onSelectAllSectorHandler,
    onSelectAllSectorsHandler,
    onSelectedIndustryHandler,
    onSelectedSectorHandler,
  }
}

export default useHoldingFilters
