import React, { useEffect } from 'react'
import { ReactChildren } from 'utils/types/common'

import * as Styles from './DrawerFooter.styles'

export interface DrawerFooterProps {
  children?: ReactChildren
  className?: string
}

const DrawerFooter: React.FC<DrawerFooterProps> = ({ children, className }) => {
  useEffect(() => {
    const drawerContent = document.getElementById('drawer-content')
    if (drawerContent) {
      // TODO: Find a better way to calculate the height of the drawer content
      // Try to set the height rather than the padding bottom

      // Custom Content paddingBottom (if any) + HeaderHeight + FooterHeight + extra margin
      // to support displaying the dropdown list properly when it's displayed at the end of the content drawer
      drawerContent.style.paddingBottom = `${
        parseInt(drawerContent.style.paddingBottom, 10) || 0 + 35
      }rem`
    }
  }, [])

  return (
    <Styles.DrawerFooter className={className}>{children}</Styles.DrawerFooter>
  )
}

export default DrawerFooter
