import styled from 'styled-components'

import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

import { HorizontalContent as HorizontalContentToStyle } from '../../PreviewContents.styles'

const HeaderSizeCalc = 'calc(3.2rem * 2 + 4rem)'

export const Container = styled.div`
  position: absolute;
  top: 3.2rem;
  right: 15rem;
  display: flex;
  z-index: 2;
  box-sizing: border-box;
  gap: 3.2rem;
  min-width: 39.8rem;
  width: fit-content;

  @media ${maxSize.md} {
    top: ${HeaderSizeCalc};
    gap: 2.4rem;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const Page = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.9rem;
  letter-spacing: 0px;
  color: ${color('white')};
`

export const Separator = styled.div`
  width: 0.1rem;
  height: 4.2rem;
  background-color: ${color('white', 0.3)};
`

export const HorizontalContent = styled(HorizontalContentToStyle)`
  gap: 0.8rem;
`

export const Percentage = styled.div`
  height: 4rem;
  padding: 0 0.8rem;
  background-color: ${color('white')};
  border-radius: 0.8rem;
  border: 1px solid ${color('veryLightGray')};
  color: ${color('gray')};
  font-size: 1.6rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  user-select: none;
`
