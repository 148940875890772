import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { Color } from 'utils/theme'

export const Icon = styled.div<{
  color: Color
  clickable?: boolean
  hoverState?: boolean
  selectedState?: boolean
  showIconOnlyOnHover?: boolean
}>`
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 100%;
  background-color: ${(props) => color(props.color)(props)};
  font-size: 1.6rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  
  &:focus {
    outline: none;
  }
  transition: border 0.1s, background 0.2s, color 0.2s;

  svg {
    visibility: ${({ showIconOnlyOnHover }) =>
      showIconOnlyOnHover ? 'hidden' : 'visible'};};
  }

  ${({ hoverState, clickable }) =>
    hoverState &&
    clickable &&
    css`
      border: 2px solid ${color('veryLightBlue')};
      background: white;
      svg {
        color: ${color('veryLightBlue')};
      }
    `}
  
  ${({ showIconOnlyOnHover, hoverState }) =>
    hoverState &&
    showIconOnlyOnHover &&
    css`
      svg {
        visibility: visible;
      }
    `}
  
      
  ${(props) =>
    props.selectedState &&
    css`
      border: 2px solid
        ${props.hoverState
          ? color('veryLightBlue')(props)
          : color('veryLightGray')(props)};
      background: white;
      svg {
        color: ${color('primaryBlue')};
        visibility: visible;
      }
    `}
`

export const IconWrapper = styled.button<{ logo?: string }>`
  position: relative;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 100%;
  border: 0.1rem solid ${color('veryLightGray')};
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.logo &&
    css`
      background-image: url(${props.logo});
      background-size: contain;
      background-position: center;
    `}
`

export const FloatingIcon = styled(Icon)`
  position: absolute;
  right: -0.8rem;
  bottom: -0.8rem;
  width: 2.1rem;
  height: 2.1rem;
  font-size: 1.1rem;
`
