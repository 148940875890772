import uniq from 'lodash/uniq'
import { SortDirection } from 'utils/constants/sortDirection'
import ResponseNormalizer from 'utils/functions/responseNormalizer'
import { ContentAnalytic, TotalAnalytics } from 'utils/types/analytics'
import { SortDirectionType } from 'utils/types/common'
import axiosClient from './httpClient'

export default class AnalyticsService {
  static _normalizeTrack(analyticId, entities) {
    const tracker = entities.updateUserTrackers[analyticId]
    return {
      ...tracker,
      resharedGroups: tracker.resharedGroups.map(
        (groupId) => entities.resharedGroups[groupId]
      ),
      resharedUsers: tracker.resharedUsers.map(
        (groupId) => entities.resharedUsers[groupId]
      ),
    }
  }

  static _normalizeAttachmentTrack(analyticId, entities) {
    const tracker = entities.attachmentUsers[analyticId]
    return {
      ...tracker,
      user: entities.users[tracker.user],
      accessedGroups: tracker.accessedGroups.map(
        (groupId) => entities.accessedGroups[groupId]
      ),
    }
  }

  static _normalizeUserAnalytic(userId: string, entities: any) {
    const user = entities.users[userId]
    return {
      ...user,
      groups: uniq(user.groups).map(
        (groupId: string) => entities.groups[groupId]
      ),
    }
  }

  static async fetchAnalytics({
    updateId,
    sortBy,
    sortDirection = SortDirection.ASC,
  }: {
    updateId: string
    sortBy?: string
    sortDirection?: SortDirectionType
  }) {
    const params: any = {
      update_id: updateId,
    }
    if (sortBy) {
      params.order_by = sortBy
      params.direction = sortDirection
    }
    const {
      data: { result, entities },
    } = await axiosClient().get('/update_user_trackers/users_for_update', {
      params,
    })
    return result.map((userId) => this._normalizeUserAnalytic(userId, entities))
  }

  static async fetchAnalyticsByUser({ updateId, userId, page, pageSize }) {
    const {
      data: { result, entities },
    } = await axiosClient().get('/update_user_trackers/trackers_by_user', {
      params: {
        update_id: updateId,
        user_id: userId,
        page,
        per_page: pageSize,
        order_by: 'created_at',
        direction: 'desc',
      },
    })
    return result.map((trackId) => this._normalizeTrack(trackId, entities))
  }

  static async getTotalAnalyticsForUpdate({
    updateId,
    groupBy,
    startDate,
    endDate,
  }): Promise<TotalAnalytics> {
    const { data } = await axiosClient().get(
      '/update_user_trackers/trackers_group_by',
      {
        params: {
          update_id: updateId,
          group_by: groupBy,
          start_date: startDate,
          end_date: endDate,
        },
      }
    )
    return data
  }

  static async sendAttachmentDownloadAnalytic(
    contentId: string
  ): Promise<ContentAnalytic[]> {
    return axiosClient().post('/content_user_metrics', { contentId })
  }

  static async fetchAttachmentAnalytics(
    contentId: string
  ): Promise<ContentAnalytic[]> {
    const {
      data: { result, entities },
    } = await axiosClient().get(`/s3_contents/${contentId}/analytics`)

    return result.map((analyticId) =>
      ResponseNormalizer.normalizeContentAnalytic(analyticId, entities)
    )
  }
}
