/* eslint-disable react/style-prop-object */
import classNames from 'classnames'
import { sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl'
import { useLocation, useParams } from 'react-router-dom'

import { getIsFundSelectedByDefault } from 'features/investorManagementSlice'
import { isEnterKeyCode } from 'utils/functions/keyboardEvents'
import {
  getInvestPortfolioChartData,
  isFieldSettingShared,
  isPieChartDataShared,
} from 'utils/functions/portfolios'
import { getHoldingImage } from 'utils/types/company'

import Card from 'components/Card'
import InvestPortfolioPieChart from 'components/InvestPortfolioPieChart'
import ToggleInvestorCalculation from 'components/PortfolioHoldings/PortfolioHoldingsToggleCalculation'
import { AggregateFundSummary } from 'utils/hooks/investments/useFetchInvestmentsSummary'
import { SharePortfolioSettings } from 'utils/types/funds'
import {
  ChartColors,
  PortfolioCompanyWithSharedSettingsApplied,
  PortfolioWithSharedSettingsApplied,
} from 'utils/types/portfolios'
import { DealSummaryChartData } from 'api/PortfolioService'
import GridSkeleton from 'components/Skeletons/components/GridSkeleton'
import { isActingAsFundManager, isActingAsInvestorGroup } from 'selectors/auth'
import { maxSize } from 'utils/constants/breakpoint'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { PortfolioTypes } from 'utils/constants/portfolio'
import DealPortfolioBarChart from 'components/DealPortfolioBarChart/DealPortfolioBarChart'
import {
  ChartContainer,
  DealChartContainer,
  GridAndChartWrapper,
} from 'containers/PortfolioDetail/charts.styles'
import Grid from '../Grid'
import InvestPortfolioChartResume from './InvestPortfolioChartResume'
import PortfolioRowHeader from './PortfolioRowHeader'

import styles from './PortfolioRow.module.scss'
import PortfoliosRowHeader from './PortfoliosRowHeader'
import {
  LastUpdateDateValue,
  LastUpdatedDateContainer,
} from './PortfoliosRowHeader.styles'

const DUMMY_DATE = '0000-01-01'

interface InvestPortfolioRowProps {
  className?: string
  portfolioCompanies?: PortfolioCompanyWithSharedSettingsApplied[]
  portfolios?: any[]
  portfoliosSummary?: AggregateFundSummary
  name?: string
  tabIndex: number
  onClickPortfolio?: () => void
  showDetail?: boolean
  isClickable?: boolean
  shadow?: boolean
  showHeader?: boolean
  fromList?: boolean
  portfolio?: PortfolioWithSharedSettingsApplied
  colorsForCompanies?: ChartColors
  isExpandable?: boolean
  lastPortfolioUpdate?: string
  titleId?: string
  sharePortfolioSetting?: SharePortfolioSettings
  columnsQuantity?: number
  isLoading?: boolean
  showChart?: boolean
  showDealChart?: boolean
}

const InvestPortfolioRow: React.FC<InvestPortfolioRowProps> = ({
  className,
  portfolioCompanies = [],
  portfolios = [],
  portfoliosSummary,
  name,
  showDetail = false,
  tabIndex,
  onClickPortfolio,
  isClickable = false,
  shadow = true,
  showHeader = true,
  showChart = true,
  fromList = false,
  showDealChart = true,
  portfolio,
  colorsForCompanies = {},
  isExpandable = false,
  lastPortfolioUpdate,
  titleId,
  sharePortfolioSetting,
  isLoading,
}) => {
  const intl = useIntl()
  const location = useLocation()
  const { id: portfolioId } = useParams<{ id: string }>()
  const { matches: isMiniMobile } = useMediaQuery(maxSize.xss) // 380
  const { matches: isMediumMobile } = useMediaQuery(maxSize.xsm) // 468
  const { matches: isMobile } = useMediaQuery(maxSize.sm) // 640
  const { matches: isMobileAndSmallDesktop } = useMediaQuery(maxSize.xl) // 1280

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const isFundManagerGroup = useAppSelector(isActingAsFundManager)
  const isFundSelectedByDefault = useAppSelector(getIsFundSelectedByDefault)
  const isInvestorPreview = location.pathname.includes('/preview')
  const shouldShowInvestorCalculation =
    isInvestorGroup || (isFundManagerGroup && isInvestorPreview)
  const isInvestmentsRoute = location.pathname.includes('/investments')
  const isClickingOnDeal = location.pathname.includes('/deal')

  const isIndividualSelectedByDefault =
    (isInvestorGroup && !isFundSelectedByDefault) ||
    (isFundManagerGroup && isInvestorPreview && !isFundSelectedByDefault)
  const [showInvestorCalculation, setShowInvestorCalculation] = useState(
    isIndividualSelectedByDefault
  )

  let rowContainerProps = {}

  const holdingsSummary = showInvestorCalculation
    ? portfolio?.investorSummary
    : portfolio

  const isDealPortfolio = useMemo(
    () => portfolio?.type === PortfolioTypes.DEAL,
    [portfolio?.type]
  )

  const normalizedCompaniesData = useMemo(() => {
    return (
      portfolioCompanies?.map((portfolioCompany) => {
        const holding = portfolioCompany?.holding || portfolioCompany

        return {
          id: holding?.id || holding.holdingId,
          name: holding?.name,
          image: getHoldingImage(holding),
          totalAmountInvested: portfolioCompany?.totalAmountInvested,
          totalValue: portfolioCompany?.totalValue,
        }
      }) ?? []
    )
  }, [portfolioCompanies])

  const groupedChartData = useMemo(() => {
    if (portfolioCompanies.length > 0) {
      const sortedCompanies = sortBy(portfolioCompanies, ['totalValue', 'name'])

      return getInvestPortfolioChartData(
        sortedCompanies,
        intl.formatMessage({ id: 'portfolios.otherCompanies' })
      )
    }

    if (portfolios.length > 0) {
      const sortedPortfolios = sortBy(portfolios, ['totalValue', 'name'])

      return getInvestPortfolioChartData(
        sortedPortfolios,
        intl.formatMessage({ id: 'portfolios.otherPortfolios' })
      )
    }

    return getInvestPortfolioChartData(
      [],
      intl.formatMessage({ id: 'portfolios.otherCompanies' })
    )
  }, [portfolioCompanies, portfolios, intl])

  const dealPortfolioChartData = useMemo(() => {
    if (!portfolio || portfolio?.type !== PortfolioTypes.DEAL)
      return [] as DealSummaryChartData

    return [
      {
        name: 'Total Invested',
        totalInvested: portfolio.totalInvested || 0,
        totalValue: 0,
      },
      {
        name: 'Total Value',
        totalInvested: 0,
        totalValue: portfolio.totalValue || 0,
      },
    ] as DealSummaryChartData
  }, [portfolio])

  const renderMultiplier = (value): string => {
    if (!value) {
      return '-'
    }

    return `${Number(value).toFixed(2)}x`
  }

  const countPieChartFieldsShared = [
    'investmentHoldingTotalValue',
    'investmentHoldingAmountInvested',
  ].filter((field) => isFieldSettingShared(field, sharePortfolioSetting)).length

  const countGridFieldsShared = [
    'portfolioTotalInvested',
    'portfolioTotalFairMarketValue',
    'portfolioTotalDistributed',
    'portfolioTotalValue',
    'portfolioTotalUnfundedCommitment',
    'portfolioTotalHolding',
    'portfolioTotalInvestments',
    'portfolioAvgInvestmentPerHolding',
    'portfolioFirstInvestment',
    'portfolioDistributionToPaidIn',
    'portfolioMultipleOnInvestedCapital',
    'portfolioIrr',
  ].filter((field) => isFieldSettingShared(field, sharePortfolioSetting)).length

  if (!countPieChartFieldsShared && !countGridFieldsShared && !fromList)
    return null

  const { hasTotalValueShared, hasTotalAmountInvestedShared } =
    isPieChartDataShared(groupedChartData)

  const zeroDate = () => {
    if (portfolio) {
      return portfolio.firstInvestmentDate &&
        portfolio.firstInvestmentDate !== DUMMY_DATE ? (
        <FormattedDate year="numeric" value={portfolio.firstInvestmentDate} />
      ) : (
        '-'
      )
    }

    if (portfoliosSummary) {
      return portfoliosSummary.firstInvestmentDate &&
        portfoliosSummary.firstInvestmentDate !== DUMMY_DATE ? (
        <FormattedDate
          year="numeric"
          value={portfoliosSummary.firstInvestmentDate}
        />
      ) : (
        '-'
      )
    }

    return '-'
  }

  const onInvestorCalculationChangeHandler = () => {
    setShowInvestorCalculation(() => !showInvestorCalculation)
  }

  const onClickPortfolioHandler = (e) => {
    if (
      e.target.classList.contains('toggle-element') ||
      e.target.id === 'toggle-calculation'
    ) {
      e.stopPropagation()
      e.preventDefault()
      return
    }
    onClickPortfolio?.()
  }

  if (isClickable) {
    rowContainerProps = {
      tabIndex,
      onClick: onClickPortfolioHandler,
      role: isClickable ? 'button' : 'div',
      onKeyUp: (event) => {
        if (isEnterKeyCode(event)) {
          onClickPortfolio?.()
        }
      },
    }
  }

  const irrDisplayValue = () => {
    const irr = holdingsSummary?.irr ?? portfoliosSummary?.irr

    if (typeof irr === 'string') {
      return '-'
    }

    const irrValue = irr ? irr / 100 : 0

    return (
      <FormattedNumber
        value={irrValue}
        style="percent"
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    )
  }

  const getColumnsQuantity = () => {
    if (isMiniMobile) return 1
    if (isMobile) return 2
    if (isMobileAndSmallDesktop) return 3
    return isDealPortfolio && !isInvestmentsRoute ? 3 : 4
  }

  const showLastUpdated = !isLoading && !fromList && lastPortfolioUpdate

  const showTotalHoldings =
    (!isDealPortfolio &&
      isFieldSettingShared('portfolioTotalHolding', sharePortfolioSetting)) ||
    (isFieldSettingShared('portfolioTotalInvestments', sharePortfolioSetting) &&
      isInvestmentsRoute)

  const showPieChart =
    showChart &&
    !isClickingOnDeal &&
    (hasTotalValueShared || hasTotalAmountInvestedShared)

  return (
    <div
      className={classNames(
        styles.rowContainer,
        styles.investRowContainer,
        {
          [styles.clickableStyles]: isClickable,
          [styles.marginBottom]: fromList,
        },
        className
      )}
      {...rowContainerProps}
    >
      <Card
        isExpandable={isExpandable}
        shadow={shadow}
        padding={isMediumMobile ? '2.4rem 1.6rem' : '1.4rem 2.4rem'}
      >
        {showHeader && portfolio && (
          <Card.Header>
            <PortfolioRowHeader
              type={portfolio.type}
              name={name!}
              countCompanies={portfolio.companiesCount}
              showDetail={!showDetail}
              fromList={fromList}
              titleId={titleId!}
            />

            {showLastUpdated && (
              <div className={styles.lastUpdateHeader}>
                <FormattedMessage id="portfolioDetail.lastUpdated" />
                <span>{lastPortfolioUpdate}</span>
              </div>
            )}

            {shouldShowInvestorCalculation && (
              <ToggleInvestorCalculation
                isDealPortfolio={portfolio.type === PortfolioTypes.DEAL}
                portfolioId={portfolio.id}
                onStatusChange={onInvestorCalculationChangeHandler}
                status={!showInvestorCalculation}
              />
            )}
          </Card.Header>
        )}

        {showHeader && portfolios.length > 0 && (
          <Card.Header>
            <PortfoliosRowHeader titleId={titleId!} />
            {!isLoading && !fromList && lastPortfolioUpdate && (
              <LastUpdatedDateContainer>
                <FormattedMessage id="portfolioDetail.lastUpdated" />
                <LastUpdateDateValue>{lastPortfolioUpdate}</LastUpdateDateValue>
              </LastUpdatedDateContainer>
            )}
          </Card.Header>
        )}

        <Card.Body bodyMargin={portfolioId ? '1.6rem' : '0rem'}>
          <GridAndChartWrapper>
            {isLoading ? (
              <GridSkeleton
                columnsQuantity={getColumnsQuantity()}
                cellsQuantity={12}
                labelWidth="10rem"
                valueWidth="7rem"
              />
            ) : (
              countGridFieldsShared > 0 && (
                <Grid
                  gridId="investPortfolioGrid"
                  columnsQuantity={getColumnsQuantity()}
                >
                  {isFieldSettingShared(
                    'portfolioTotalInvested',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.totalInvested',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.totalInvested',
                      })}
                      value={
                        holdingsSummary?.totalInvested ||
                        portfoliosSummary?.totalInvested ? (
                          <FormattedNumber
                            value={
                              (holdingsSummary?.totalInvested ||
                                portfoliosSummary?.totalInvested)!
                            }
                            style="currency"
                            currency="USD"
                            currencyDisplay="narrowSymbol"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioTotalFairMarketValue',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.totalFairMarketValue',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.totalFairMarketValue',
                      })}
                      value={
                        holdingsSummary?.totalFairMarketValue ||
                        portfoliosSummary?.totalFairMarketValue ? (
                          <FormattedNumber
                            value={
                              (holdingsSummary?.totalFairMarketValue ||
                                portfoliosSummary?.totalFairMarketValue)!
                            }
                            style="currency"
                            currency="USD"
                            currencyDisplay="narrowSymbol"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioTotalDistributed',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.totalDistributed',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.totalDistributed',
                      })}
                      value={
                        holdingsSummary?.totalDistributed ||
                        portfoliosSummary?.totalDistributed ? (
                          <FormattedNumber
                            value={
                              (holdingsSummary?.totalDistributed ||
                                portfoliosSummary?.totalDistributed)!
                            }
                            style="currency"
                            currency="USD"
                            currencyDisplay="narrowSymbol"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioTotalValue',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.totalValue',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.totalValue',
                      })}
                      value={
                        holdingsSummary?.totalValue ||
                        portfoliosSummary?.totalValue ? (
                          <FormattedNumber
                            value={
                              (holdingsSummary?.totalValue ||
                                portfoliosSummary?.totalValue)!
                            }
                            style="currency"
                            currency="USD"
                            currencyDisplay="narrowSymbol"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioTotalUnfundedCommitment',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: isInvestorGroup
                          ? 'portfolios.uncalledCommitment'
                          : 'portfolios.totalUnfundedCommitment',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.totalUnfundedCommitment',
                      })}
                      value={
                        holdingsSummary?.portfolioTotalUnfundedCommitment ||
                        portfoliosSummary?.portfolioTotalUnfundedCommitment ? (
                          <FormattedNumber
                            value={
                              (holdingsSummary?.portfolioTotalUnfundedCommitment ||
                                portfoliosSummary?.portfolioTotalUnfundedCommitment)!
                            }
                            style="currency"
                            currency="USD"
                            currencyDisplay="narrowSymbol"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {showTotalHoldings && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.totalHoldings',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.totalHoldings',
                      })}
                      value={
                        holdingsSummary?.totalHoldings?.toString() ||
                        portfoliosSummary?.totalHoldings?.toString() ||
                        '-'
                      }
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioTotalInvestments',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.totalInvestments',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.totalInvestments',
                      })}
                      value={
                        portfolio?.totalTransactions ||
                        portfoliosSummary?.totalInvestments ||
                        '-'
                      }
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioAvgInvestmentPerHolding',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: isInvestorGroup
                          ? 'portfolios.averageInvestmentPerHolding'
                          : 'portfolios.averageHoldingSize',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.averageHoldingSize',
                      })}
                      value={
                        holdingsSummary?.averageInvestmentPerCompany ||
                        portfoliosSummary?.averageInvestmentPerCompany ? (
                          <FormattedNumber
                            value={
                              (holdingsSummary?.averageInvestmentPerCompany ||
                                portfoliosSummary?.averageInvestmentPerCompany)!
                            }
                            style="currency"
                            currency="USD"
                            currencyDisplay="narrowSymbol"
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                        ) : (
                          '-'
                        )
                      }
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioFirstInvestment',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.firstInvestment',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.firstInvestment',
                      })}
                      value={zeroDate()}
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioDistributionToPaidIn',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.distributionToPaidIn',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.distributionToPaidIn',
                      })}
                      value={renderMultiplier(
                        holdingsSummary?.distributionToPaidIn ||
                          portfoliosSummary?.distributionToPaidIn
                      )}
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioMultipleOnInvestedCapital',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({
                        id: 'portfolios.multipleOnInvestedCapital',
                      })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.multipleOnInvestedCapital',
                      })}
                      value={renderMultiplier(
                        holdingsSummary?.multipleOnInvestedCapital ||
                          portfoliosSummary?.multipleOnInvestedCapital
                      )}
                    />
                  )}
                  {isFieldSettingShared(
                    'portfolioIrr',
                    sharePortfolioSetting
                  ) && (
                    <Grid.Cell
                      padding="1.8rem"
                      label={intl.formatMessage({ id: 'portfolios.irr' })}
                      tooltip={intl.formatMessage({
                        id: 'portfolioDetail.tooltips.irr',
                      })}
                      value={irrDisplayValue()}
                    />
                  )}
                </Grid>
              )
            )}

            {showPieChart ? (
              <ChartContainer isPieChart={showPieChart}>
                <InvestPortfolioPieChart
                  chartData={groupedChartData}
                  colorsForCompanies={colorsForCompanies}
                  sharePortfolioSetting={sharePortfolioSetting}
                  showChartLogoImage={portfolios.length === 0}
                  isLoading={!!isLoading}
                />
                {showDetail && (
                  <InvestPortfolioChartResume
                    chartData={groupedChartData}
                    companies={normalizedCompaniesData}
                    colorsForCompanies={colorsForCompanies}
                    sharePortfolioSetting={sharePortfolioSetting}
                    isLoading={!!isLoading}
                  />
                )}
              </ChartContainer>
            ) : (
              <DealChartContainer>
                <DealPortfolioBarChart
                  isLoading={!!isLoading}
                  dealPortfolioChartData={dealPortfolioChartData}
                  showDealChart={showDealChart}
                />
              </DealChartContainer>
            )}
          </GridAndChartWrapper>
          {showLastUpdated && (
            <div className={styles.lastUpdatedDate}>
              <FormattedMessage id="portfolioDetail.lastUpdated" />
              <span>{lastPortfolioUpdate}</span>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  )
}

export default InvestPortfolioRow
