import { Formik } from 'formik'
import { useIntl } from 'react-intl'
import ReshareHistoryWrapper from 'containers/UpdatesV2/ReshareHistoryV2/ReshareHistoryWrapper'
import useReshareUpdateModal from 'containers/UpdatesV2/components/ReshareUpdateModal/useReshareUpdateModal'
import ReshareUpdateModal from 'containers/UpdatesV2/components/ReshareUpdateModal/ReshareUpdateModal'
import ShowUpdateContent from 'containers/UpdatesV2/ShowUpdateContent'
import ShowWrapper from 'containers/UpdatesV2/components/commons/ShowWrapper/ShowWrapper'
import { getUniqueUserNames } from 'containers/UpdatesV2/utils'
import { UpdateTypes } from 'utils/constants/updates'
import { getUpdateItemTitle } from 'utils/functions/updates'
import { getEditAccountingSchema } from 'utils/schemas/updates'
import { AccountingFormValues } from 'utils/types/updateForm'
import { UpdateForm } from '../../components'
import { useShowAccounting } from './useShowAccounting'
import ShowAccountingContent from './ShowAccountingContent'

const ShowAccounting = () => {
  const intl = useIntl()

  const {
    isReshareModalOpen,
    selectedReshareTokenSet,
    toggleReshareModal,
    handleSetSelectedReshareTokenSet,
    handleCloseReshareModal,
  } = useReshareUpdateModal()

  const {
    isLoading,
    update,
    updateId,
    resharesByToken,
    entityName,
    eventLogType,
    accountingValues,
    rawData,
    editMutation,
  } = useShowAccounting()

  return (
    <ShowWrapper
      title={intl.formatMessage(
        { id: 'metaTitles.updateTitle' },
        {
          title: getUpdateItemTitle(update),
          entityName,
        }
      )}
      isLoading={isLoading}
    >
      <Formik<AccountingFormValues>
        initialValues={accountingValues!}
        validationSchema={getEditAccountingSchema(intl)}
        onSubmit={(values) => editMutation({ values, update: update! })}
        enableReinitialize
        validateOnMount
      >
        <UpdateForm
          updateType={UpdateTypes.ACCOUNTING}
          logType={eventLogType}
          isShowMode
          updateId={updateId}
          update={update}
          accountingRawData={rawData}
          toggleReshareModal={toggleReshareModal}
        >
          <ReshareHistoryWrapper
            handleSetSelectedReshareTokenSet={handleSetSelectedReshareTokenSet}
            resharesByToken={resharesByToken}
          >
            <ShowUpdateContent
              uniqueUserNames={getUniqueUserNames(resharesByToken)}
              update={update!}
              updateType={UpdateTypes.ACCOUNTING}
            >
              <ShowAccountingContent update={update!} />
            </ShowUpdateContent>
          </ReshareHistoryWrapper>

          {isReshareModalOpen && update && (
            <ReshareUpdateModal
              update={update}
              reshareTokenSet={selectedReshareTokenSet}
              isModalOpen={isReshareModalOpen}
              closeModal={handleCloseReshareModal}
              updateTitle={update?.item.title}
            />
          )}
        </UpdateForm>
      </Formik>
    </ShowWrapper>
  )
}

export default ShowAccounting
