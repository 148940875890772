import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { getCurrentGroupType } from 'selectors/auth'
import Toast from 'components/Toast'
import IntegrationService from 'api/IntegrationService'
import { IntegrationStatus } from 'utils/constants/integrations'
import { GroupTypes } from 'utils/constants/groups'

export const QUERIES = {
  CONNECTIONS: 'connections',
} as const

export const accountingKeys = {
  connections: () => [QUERIES.CONNECTIONS] as const,
}

const useAccountingIntegrationStatus = () => {
  const groupType = useAppSelector(getCurrentGroupType)
  const accountingUpdatesEnabled =
    groupType === GroupTypes.FOUNDER || groupType === GroupTypes.FUND_MANAGER
  const { data: status, isFetching } = useQuery(
    accountingKeys.connections(),
    () => IntegrationService.getIntegrationsConnectionStatuses(),
    {
      enabled: accountingUpdatesEnabled,
      initialData: {},
      onError: () => {
        Toast.displayIntl('integrations.statusError', 'error')
      },
      staleTime: 1000,
    }
  )

  const isConnectedToAccounting = useMemo(
    () =>
      Object.values(status).some(
        (connection) => connection.status === IntegrationStatus.CONNECTED
      ),
    [status]
  )

  return {
    loading: isFetching,
    status,
    isConnected: isConnectedToAccounting,
  }
}

export default useAccountingIntegrationStatus
