import Checkbox from 'components/Checkbox'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div<{ direction: 'row' | 'column' }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: 0.8rem;

  div[class*='checkboxLegendWrapper'] {
    display: flex;
    align-items: center;
  }

  span[class*='checkmarkCustom'] {
    margin-right: 0.8rem;
  }
`

export const CheckRadio = styled(Checkbox)<{
  unchecked?: boolean
  uncheckedWithOpacity?: boolean
  marginLeft?: string
  fullWidth?: boolean
}>`
  align-items: center;
  margin-bottom: 0;
  margin-left: ${(props) => props.marginLeft || ''};
  padding: 0.8rem 1.6rem;
  border-radius: 0.4rem;

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
  ${(props) =>
    props.unchecked &&
    props.uncheckedWithOpacity &&
    css`
      opacity: 0.6;
    `}

  &:hover {
    background-color: ${color('veryLightBlue', 0.2)};
    input[type='radio']:not(:checked) ~ [class^='Checkbox_checkmarkCustom'] {
      border: 0.3rem solid ${color('veryLightBlue')};
    }
  }
`

export const Text = styled.div`
  font-size: 1.4rem;
  color: var(--color-gray-700);
`
