import styled from 'styled-components'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { Form as FormToStyle, ContinueButton } from '../../../Signup.styles'

export const Row = styled.div`
  display: flex;
  gap: 2.4rem;
  flex-direction: column;

  @media ${size.sm} {
    flex-direction: row;
    gap: 3.2rem;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;

  @media ${size.sm} {
    width: 50%;
  }
`

export const Form = styled(FormToStyle)`
  max-width: 55.7rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;

  @media ${maxSize.sm} {
    flex-direction: column;
  }
`

export const CustomError = styled.div`
  font-size: 1.4rem;
  color: ${color('red')};
  padding-left: 1.4rem;
  padding-top: 0.8rem;

  a {
    color: ${color('red')};
    text-decoration: none;
    border-bottom: 1px solid ${color('red')};
  }
`

export const StartTrialButton = styled(ContinueButton)`
  padding-left: 4rem;
  padding-right: 4rem;
`
