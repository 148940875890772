/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

const initialState = {
  updateAllMetricsDismissed: false,
}

const setUpdateAllMetricsDismissed = (draftState, value) => {
  draftState.updateAllMetricsDismissed = value
  return draftState
}

const handlers = {
  [Types.SET_UPDATE_ALL_METRICS_DISMISSED]: setUpdateAllMetricsDismissed,
}

export default createReducer(initialState, handlers)
