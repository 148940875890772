import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect } from 'react'

import AuthService from 'api/AuthService'
import { fetchTier, getTier } from 'utils/functions/tier'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { groupKeys } from 'utils/queries/groups'
import { Nullable } from 'utils/types/common'
import { setRedirectUrl } from 'features/redirectSlice'
import {
  setAuth0IdToken,
  signInCreateSession,
  setAuth0AccessToken,
} from 'features/authSlice'

export const ENDPOINT_NOT_FOUND_ERROR = `The page you were looking for doesn't exist`

export const useSignIn = (redirectUrl?: Nullable<string>) => {
  const dispatch = useAppDispatch()
  const clientQuery = useQueryClient()

  useEffect(() => {
    if (redirectUrl) dispatch(setRedirectUrl(redirectUrl))
  }, [dispatch, redirectUrl])

  const loadUserTier = useCallback(
    async (groupId?: string) => {
      const headers = groupId ? { 'group-id': groupId } : undefined
      const { tier, status } = await fetchTier(headers)

      const currentTier = getTier(status, tier)
      clientQuery.setQueryData(groupKeys.tier(), {
        tier: currentTier,
        status,
      })
    },
    [clientQuery]
  )

  const signInAuth0 = useCallback(
    async (authData: { idToken: string; accessToken: string }) => {
      dispatch(setAuth0IdToken(authData.idToken))
      dispatch(setAuth0AccessToken(authData.accessToken))
      const { user, groups, investorGroups } = await AuthService.getMyData()
      const groupId = Object.keys(groups)?.[0]
      if (groupId) await loadUserTier(groupId)
      await dispatch(
        signInCreateSession({
          user,
          groups,
          investorGroups,
          idToken: authData.idToken,
          accessToken: authData.accessToken,
        })
      )
      const hasNoName = user.firstName === null && user.lastName === null
      const hasGroups = Object.keys({ ...groups, ...investorGroups }).length > 0

      const shouldContinueSignup = !hasGroups || hasNoName

      return {
        shouldContinueSignup,
      }
    },
    [dispatch, loadUserTier]
  )

  return {
    signInAuth0,
    loadUserTier,
  }
}
