import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'

export const FinancialInfoBody = styled.div<{
  isDetailsScreen?: boolean
}>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 4.8rem;

  ${({ isDetailsScreen }) =>
    isDetailsScreen &&
    css`
      width: 100%;
    `}

  @media ${maxSize.lg} {
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
  }

  @media ${maxSize.md} {
    gap: 2rem;
    grid-template-columns: 1fr;
  }
`
