import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const YouLabelStyles = styled.span`
  color: ${color('lightGray')};
  margin-left: 0.4rem;
  text-transform: capitalize;
`

const YouLabel = () => {
  return (
    <YouLabelStyles>
      (<FormattedMessage id="general.you" />)
    </YouLabelStyles>
  )
}

export default YouLabel
