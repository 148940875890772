import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  Window,
  MessageList,
  MessageInput as StreamMessageInput,
  Thread,
  useChannelActionContext,
} from 'stream-chat-react'

import ChatHeader from './components/ChatHeader'
import MessageInput from './components/MessageInput'
import { MESSAGE_ID_QUERY_PARAM } from './hooks/useSetActiveChannel'
import * as Styles from './Chat.styles'

const ChatChannelContent: React.FC = () => {
  const { jumpToMessage } = useChannelActionContext()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.has(MESSAGE_ID_QUERY_PARAM)) {
      const messageId = queryParams.get(MESSAGE_ID_QUERY_PARAM) as string
      queryParams.delete(MESSAGE_ID_QUERY_PARAM)
      history.replace({
        search: queryParams.toString(),
      })
      jumpToMessage?.(messageId)
    }

    // jumpToMessage is a new function every render, but the first one we get works fine
    // there's likely a missing useCallback in the library
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search, history])

  return (
    <>
      <Window>
        <ChatHeader />
        <Styles.ChatListContainer>
          <MessageList />
          <StreamMessageInput grow />
        </Styles.ChatListContainer>
      </Window>
      <Thread Input={MessageInput} />
    </>
  )
}

export default ChatChannelContent
