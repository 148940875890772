import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { getFileExtension } from 'utils/functions/files'
import { FileExtension } from 'utils/types/files'
import CWLoader from 'components/CWLoader'
import { EmailContentAvatarIcon } from 'ui/Icons/EmailAvatarIcon'

import {
  IconWrapper,
  PDFIconWrapper,
  UnknownIconWrapper,
  WavIconWrapper,
  DocIconWrapper,
  VideoIconWrapper,
  ZIPIconWrapper,
  ImageIconWrapper,
  BackgroundImage,
  Image,
  LoaderWrapper,
  EmailContentIconWrapper,
} from './FileIcon.styles'

interface Props {
  type?: FileExtension
  src?: string
  large?: boolean
  small?: boolean
  xSmall?: boolean
  useBackgroundImage?: boolean
  showImagePreview?: boolean
  className?: string
  hasBorder?: boolean
  isEmailContent?: boolean
}

const FileIcon = ({
  type,
  src,
  large,
  small,
  xSmall,
  useBackgroundImage,
  className,
  showImagePreview = true,
  hasBorder = false,
  isEmailContent = false,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true)

  let fileType = getFileExtension(type)

  if (fileType?.includes('document')) {
    fileType = FileExtension.DOC
  }

  const iconSize = (() => {
    if (xSmall) return 'lg'
    if (small) return '3x'
    return '4x'
  })()

  if (isEmailContent) {
    return (
      <IconWrapper
        className={className}
        xSmall={xSmall}
        small={small}
        large={large}
      >
        <EmailContentIconWrapper
          xSmall={xSmall}
          small={small}
          large={large}
          hasBorder={hasBorder}
        >
          <EmailContentAvatarIcon small={small} />
        </EmailContentIconWrapper>
      </IconWrapper>
    )
  }

  switch (fileType) {
    case FileExtension.IMAGE: {
      if (src && showImagePreview) {
        if (useBackgroundImage) {
          return <BackgroundImage src={src} />
        }

        return (
          <>
            {isLoading && (
              <LoaderWrapper>
                <CWLoader />
              </LoaderWrapper>
            )}
            <Image
              src={src}
              loading="lazy"
              onLoad={() => setIsLoading(false)}
              onError={() => setIsLoading(false)}
              isLoading={isLoading}
              isLarge={large}
            />
          </>
        )
      }

      return (
        <IconWrapper className={className}>
          <ImageIconWrapper
            small={small}
            xSmall={xSmall}
            large={large}
            hasBorder={hasBorder}
          >
            <FontAwesomeIcon size={iconSize} icon={['far', 'image']} />
          </ImageIconWrapper>
        </IconWrapper>
      )
    }
    case FileExtension.PDF:
      return (
        <IconWrapper className={className}>
          <PDFIconWrapper
            small={small}
            xSmall={xSmall}
            large={large}
            hasBorder={hasBorder}
          >
            <FontAwesomeIcon size={iconSize} icon={['far', 'file-pdf']} />
          </PDFIconWrapper>
        </IconWrapper>
      )
    case FileExtension.ZIP:
      return (
        <IconWrapper className={className}>
          <ZIPIconWrapper
            small={small}
            xSmall={xSmall}
            large={large}
            hasBorder={hasBorder}
          >
            <FontAwesomeIcon size={iconSize} icon={['far', 'file-archive']} />
          </ZIPIconWrapper>
        </IconWrapper>
      )
    case FileExtension.WAV:
    case FileExtension.MP3:
      return (
        <IconWrapper className={className}>
          <WavIconWrapper
            small={small}
            xSmall={xSmall}
            large={large}
            hasBorder={hasBorder}
          >
            <FontAwesomeIcon size={iconSize} icon={['far', 'file-audio']} />
          </WavIconWrapper>
        </IconWrapper>
      )
    case FileExtension.MOV:
    case FileExtension.MP4:
      return (
        <IconWrapper className={className}>
          <VideoIconWrapper
            small={small}
            xSmall={xSmall}
            large={large}
            hasBorder={hasBorder}
          >
            <FontAwesomeIcon size={iconSize} icon={['far', 'file-video']} />
          </VideoIconWrapper>
        </IconWrapper>
      )
    case FileExtension.DOC:
    case FileExtension.DOCX:
    case FileExtension.MSWORD:
      return (
        <IconWrapper className={className}>
          <DocIconWrapper
            small={small}
            xSmall={xSmall}
            large={large}
            hasBorder={hasBorder}
          >
            <FontAwesomeIcon size={iconSize} icon={['far', 'file-alt']} />
          </DocIconWrapper>
        </IconWrapper>
      )
    default:
      return (
        <IconWrapper className={className}>
          <UnknownIconWrapper
            small={small}
            xSmall={xSmall}
            large={large}
            hasBorder={hasBorder}
          >
            <FontAwesomeIcon size={iconSize} icon={['far', 'file']} />
          </UnknownIconWrapper>
        </IconWrapper>
      )
  }
}

export default FileIcon
