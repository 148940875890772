import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import FileIconToStyle from 'components/FileIcon'

export const IconWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

export const CloseIconWrapper = styled.div`
  svg {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 50%;
  }
`
export const GridFile = styled.div<{ isLoading?: boolean }>`
  width: 13rem;
  height: 13rem;
  position: relative;
  border-radius: 0.8rem;
  background-color: color('white');
  border: 0.05rem solid var(--color-gray-200);
  box-shadow: 0px 0px 15px 0px #1015271a;

  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.isLoading &&
    css`
      background-color: ${color('lightWhite')};
      cursor: default;
    `}
`

const previewTitleStyles = css`
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-gray-600);
`

export const PreviewItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.8rem 0.8rem 0.8rem;
  text-align: center;
  height: 100%;
  justify-content: space-between;

  span {
    ${previewTitleStyles}
  }
`

export const PreviewImage = styled.div`
  border-bottom-left-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;

  div:nth-child(2) {
    height: 2.7rem;
    display: flex;
    justify-content: center;
    text-align: center;

    span {
      margin: auto 0;
      width: 8rem;
      ${previewTitleStyles}
    }
  }
`

export const ImageContainer = styled.div`
  height: 10rem;
  background-size: cover;
  width: 12.78rem;
  background-position: center;
  border-top-right-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
`

export const FileIcon = styled(FileIconToStyle)`
  height: initial;
`
