import {
  PreferenceKeys,
  PreferenceStateValues,
  setPreference,
} from 'features/preferenceSlice'
import { useAppSelector, useAppDispatch } from './reduxToolkit'

export function usePreference<T extends PreferenceStateValues>(
  key: PreferenceKeys
): [T, (v: T) => void] {
  const dispatch = useAppDispatch()
  const value = useAppSelector((state) => state.preferences[key]) as T

  const setValue = async (newValue: T): Promise<void> => {
    await dispatch(setPreference({ key, value: newValue }))
  }

  return [value, setValue]
}
