import { useCallback, useEffect, useMemo, useState } from 'react'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { Nullable, ValueOf } from 'utils/types/common'

import { getInitialMetricsQuantity } from './utils/data'
import { getDateRange } from './utils'

import {
  ChartCriteriaType,
  ChartPeriods,
  ChartMetric,
  MetricData,
} from './types'
import useDateRangeHelpers from './useDateRangeHelpers'

export interface BrushRange {
  startIndex: number
  endIndex: number
}

const useMultiViewChart = (chartMetrics: ChartMetric[]) => {
  const [groupingDataCriteria, setGroupingDataCriteria] =
    useState<ChartCriteriaType>(ChartPeriods.QUARTERLY)

  const [brushRangeIndexes, setBrushRangeIndexes] =
    useState<Nullable<BrushRange>>()
  const [dateRange, setDateRange] = useState({
    start: '',
    end: '',
  })

  const { matches: isSmallMobile } = useMediaQuery(maxSize.xss)
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const { matches: isSmallScreen } = useMediaQuery(maxSize.xl)
  const { matches: isSmallDesktop } = useMediaQuery(maxSize.xxl)

  const parsedMetrics = (chartMetric: ChartMetric) => {
    const nameValueMap: Record<string, number> = {}

    chartMetric.metrics.forEach((obj: MetricData) => {
      nameValueMap[obj.metricId as string] = obj.value
    })

    return {
      date: chartMetric.date,
      quarter: chartMetric.quarter,
      year: chartMetric.year,
      ...nameValueMap,
    }
  }

  const metrics = useMemo(() => {
    if (groupingDataCriteria === ChartPeriods.QUARTERLY) {
      return chartMetrics
        .filter((chartMetric) => chartMetric.quarter !== '')
        .map(parsedMetrics)
    }

    if (groupingDataCriteria === ChartPeriods.YEARLY) {
      return chartMetrics
        .filter(
          (chartMetric) => chartMetric.quarter === '' && chartMetric.year !== ''
        )
        .map(parsedMetrics)
    }

    return chartMetrics
      .filter(
        (chartMetric) => chartMetric.quarter === '' && chartMetric.year === ''
      )
      .map(parsedMetrics)
  }, [chartMetrics, groupingDataCriteria])

  const initialIndexes = useMemo(() => {
    const initialMetricsQuantity = getInitialMetricsQuantity(
      isMobile,
      groupingDataCriteria
    )

    return {
      startIndex:
        metrics.length - initialMetricsQuantity < 0
          ? 0
          : metrics.length - initialMetricsQuantity,
      endIndex: metrics.length - 1,
    }
  }, [groupingDataCriteria, isMobile, metrics.length])

  const updateDateRange = useCallback(
    (start: Date, end: Date, groupingCriteria: ValueOf<ChartPeriods>) => {
      const visibleRange = getDateRange(start, end, groupingCriteria)
      setDateRange(visibleRange)
    },
    []
  )

  const onChangePeriod = useCallback(
    (brushRange: BrushRange) => {
      const { startIndex, endIndex } = brushRange
      const start = metrics[startIndex].date!
      const end = metrics[endIndex].date!

      setBrushRangeIndexes(brushRange)
      updateDateRange(start, end, groupingDataCriteria)
    },
    [groupingDataCriteria, metrics, updateDateRange]
  )

  const {
    refreshChartKey,
    getArrayOfQuartersBasedOnDate,
    getArrayOfYearsBasedOnDate,
    updateRangeOnQuarterlyDayChange,
    updateRangeOnYearlyDayChange,
  } = useDateRangeHelpers({
    dateRange,
    metrics,
    brushRangeIndexes,
    onChangePeriod,
  })

  const onChangeGroupingDataCriteria = useCallback(
    (criteria: ChartCriteriaType) => {
      setBrushRangeIndexes(null)
      setGroupingDataCriteria(criteria)
    },
    []
  )

  const cardPadding = useMemo(() => {
    if (isSmallMobile) {
      return '1.6rem'
    }

    if (isMobile) {
      return '2.4rem'
    }

    return '1.6rem 2.4rem 2.4rem'
  }, [isMobile, isSmallMobile])

  const xDateAxisInterval = useMemo(() => {
    const visiblePoints = brushRangeIndexes
      ? brushRangeIndexes.endIndex - brushRangeIndexes.startIndex
      : initialIndexes.endIndex - initialIndexes.startIndex

    const SMALL_MOBILE_MAX_VISIBLE_POINTS = 2
    const MOBILE_MAX_VISIBLE_POINTS = 4
    const TABLET_MAX_VISIBLE_POINTS = 8
    const SMALL_DESKTOP_MAX_VISIBLE_POINTS = 20
    const DESKTOP_MAX_VISIBLE_POINTS = 30

    if (isSmallMobile) {
      return visiblePoints - SMALL_MOBILE_MAX_VISIBLE_POINTS <= 0
        ? 0
        : Math.floor(visiblePoints / SMALL_MOBILE_MAX_VISIBLE_POINTS)
    }

    if (isMobile) {
      return visiblePoints - MOBILE_MAX_VISIBLE_POINTS <= 0
        ? 0
        : Math.floor(visiblePoints / MOBILE_MAX_VISIBLE_POINTS)
    }

    if (isSmallScreen) {
      return visiblePoints - TABLET_MAX_VISIBLE_POINTS <= 0
        ? 0
        : Math.floor(visiblePoints / TABLET_MAX_VISIBLE_POINTS)
    }

    if (isSmallDesktop) {
      return visiblePoints - SMALL_DESKTOP_MAX_VISIBLE_POINTS <= 0
        ? 0
        : Math.floor(visiblePoints / SMALL_DESKTOP_MAX_VISIBLE_POINTS)
    }

    return visiblePoints - DESKTOP_MAX_VISIBLE_POINTS <= 0
      ? 0
      : Math.floor(visiblePoints / DESKTOP_MAX_VISIBLE_POINTS)
  }, [
    brushRangeIndexes,
    initialIndexes.endIndex,
    initialIndexes.startIndex,
    isMobile,
    isSmallDesktop,
    isSmallMobile,
    isSmallScreen,
  ])

  useEffect(() => {
    if (metrics.length === 0) return

    updateDateRange(
      metrics[initialIndexes.startIndex]?.date!,
      metrics[initialIndexes.endIndex]?.date!,
      groupingDataCriteria
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    metrics,
    refreshChartKey,
    groupingDataCriteria,
    dateRange,
    initialIndexes,
    cardPadding,
    brushRangeIndexes,
    xDateAxisInterval,
    onChangeGroupingDataCriteria,
    onChangePeriod,
    updateRangeOnYearlyDayChange,
    updateRangeOnQuarterlyDayChange,
    getArrayOfQuartersBasedOnDate,
    getArrayOfYearsBasedOnDate,
  }
}

export default useMultiViewChart
