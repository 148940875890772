import React, { useMemo } from 'react'
import Tooltip from 'components/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactChildren } from 'utils/types/common'
import { escapeSpecialChars } from 'utils/functions/utils'

import * as Styles from './DrawerHeader.styles'

export interface DrawerHeaderProps {
  children?: ReactChildren
  title?: string | React.ReactNode
  truncTitle?: boolean
  onCloseDrawer: () => void
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({
  children,
  title,
  truncTitle = true,
  onCloseDrawer,
}) => {
  const escapedTitle = useMemo(() => {
    if (typeof title !== 'string') {
      return title
    }

    return escapeSpecialChars(title)
  }, [title])

  return (
    <Styles.DrawerHeader>
      <Styles.IconTitleWrapper>
        {title && (
          <Tooltip
            id={`drawer_header_${escapedTitle}`}
            text={title}
            place="bottom"
            delayShow={1000}
          >
            <Styles.Title trunc={truncTitle}>{title}</Styles.Title>
          </Tooltip>
        )}
      </Styles.IconTitleWrapper>
      {children}
      <Styles.BtnClose
        onClick={onCloseDrawer}
        type="button"
        aria-label="Close drawer"
      >
        <FontAwesomeIcon icon={['fal', 'times']} size="lg" />
      </Styles.BtnClose>
    </Styles.DrawerHeader>
  )
}

export default DrawerHeader
