import { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import InputError from 'ui/InputError'
import { ErrorIcon } from 'ui/Input/Input.styles'
import {
  DatePickerWrapper,
  InputLabel,
  OptionalField,
  DatePickerContainer,
  IconContainer,
  Label,
} from './SelectdatePicker.styles'

import 'react-datepicker/dist/react-datepicker.css'
import { IconPosition, SelectDatePickerProps } from './types'

const SelectDatePicker = ({
  dateFormat = 'MM/yyyy',
  disabled,
  error,
  filled,
  handleChange,
  iconPosition = IconPosition.RIGHT,
  id,
  initialValue = new Date(),
  label,
  maxDate = new Date(),
  maxTime,
  minDate,
  minTime,
  name,
  onBlur,
  onlyMonthYearPicker = true,
  optional,
  placeholder,
  readOnlyMode,
  register,
  setValue,
  shouldCloseOnSelect = true,
  showErrorMessage = true,
  showTimeSelect,
  value,
  ...props
}: SelectDatePickerProps) => {
  const getDate = (date) => {
    if (!dayjs(date).isValid()) {
      return null
    }
    return typeof date === 'string' ? new Date(date) : date
  }

  const intl = useIntl()
  const [startDate, setStartDate] = useState(getDate(initialValue))
  const [isFocused, setIsFocused] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (value !== undefined) {
      setStartDate(getDate(value))
    }
  }, [value])

  useEffect(() => {
    if (register && name) {
      register({ name })
    }
  }, [name, register])

  const onChange = (date) => {
    setIsOpen(false)
    setStartDate(date)
    handleChange?.(date)
    if (setValue) {
      setValue(name, date, { shouldValidate: true })
    }
  }

  const getErrorMessage = () => {
    if (error?.message) return error?.message
    return ''
  }

  const handleOpen = () => setIsOpen((lastValue) => !lastValue)

  const errorMessage = getErrorMessage()

  return (
    <DatePickerWrapper disabled={disabled}>
      {label && (
        <InputLabel filled={filled}>
          {label}
          {optional && (
            <OptionalField>
              {intl.formatMessage({ id: 'general.optional' })}
            </OptionalField>
          )}
        </InputLabel>
      )}
      {!readOnlyMode ? (
        <DatePickerContainer
          disabled={disabled}
          iconLeft={iconPosition === IconPosition.LEFT}
          filled={filled}
          withError={!!errorMessage}
          isFocused={isFocused}
          id={id}
          onClick={() => {
            if (!isOpen) {
              setIsOpen(true)
            }
          }}
        >
          <DatePicker
            name={name}
            selected={startDate}
            shouldCloseOnSelect={shouldCloseOnSelect}
            onChange={onChange}
            dateFormat={dateFormat}
            showMonthYearPicker={onlyMonthYearPicker}
            maxDate={maxDate}
            minDate={minDate}
            minTime={minTime}
            maxTime={maxTime}
            disabled={disabled}
            placeholderText={placeholder}
            showTimeSelect={showTimeSelect}
            onFocus={() => setIsFocused(true)}
            onBlur={(event) => {
              setIsFocused(false)
              onBlur?.(event)
            }}
            open={isOpen}
            onClickOutside={handleOpen}
            autoComplete="off"
            {...props}
          />
          <IconContainer>
            <ErrorIcon error={!!errorMessage} icon={['fal', 'calendar-day']} />
          </IconContainer>
        </DatePickerContainer>
      ) : (
        <Label>{dayjs(value).format('DD MMM YYYY')}</Label>
      )}
      {errorMessage && showErrorMessage && (
        <InputError type={error?.type}>{errorMessage}</InputError>
      )}
    </DatePickerWrapper>
  )
}

export default SelectDatePicker
