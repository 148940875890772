import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const NavBarContainer = styled.nav`
  height: 7.2rem;
  width: 100%;
  background-color: ${color('darkBlue')};
  border-bottom: 0.1rem solid ${color('softDarkBlue')};
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 2.4rem 1.6rem 2.4rem;

  @media ${maxSize.mobile} {
    width: 100%;
    position: fixed;
  }
`

export const NavBarLogo = styled.image``

export const LogoContainer = styled.div`
  flex: 1;
`

export const Brand = styled.a`
  height: 4rem;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
  font-weight: normal;
  width: 100%;
  fill: ${color('white')};

  svg {
    height: 100%;
  }
`

export const SideBarButton = styled.button`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 100%;
  border: 0.1rem solid ${color('darkBlue')};
  transition: background-color 0.2s;
  background-color: ${(props) =>
    props.isSidebarOpen ? color('darkBlue')(props) : 'transparent'};
  display: none;

  &:focus {
    outline: none;
  }
  svg {
    font-size: 1.5rem;
    color: white;
  }

  @media ${maxSize.mobile} {
    display: block;
  }
`
