import React from 'react'
import { FormattedMessage } from 'react-intl'
import HiddenValue from 'ui/HiddenValue/HiddenValue'
import { FinancialInfo, Title } from './FinancialInfoItem.styles'

interface FinancialInfoItemProps {
  isReadOnly?: boolean
  titleId: string
  value?: string
}

const FinancialInfoItem: React.FC<FinancialInfoItemProps> = ({
  isReadOnly,
  titleId,
  value,
}) => {
  return value ? (
    <FinancialInfo>
      <Title>
        <FormattedMessage id={titleId} />
      </Title>
      {isReadOnly && <HiddenValue value={value} />}
      {!isReadOnly && <span className="fs-exclude">{value}</span>}
    </FinancialInfo>
  ) : null
}

export default FinancialInfoItem
