import orderBy from 'lodash/orderBy'
import get from 'lodash/get'

export const removeItemAtIndex = (index) => (items) => {
  const newItems = [...items]
  newItems.splice(index, 1)
  return newItems
}

export const pushItem = (newItem) => (items) => [...items, newItem]

export function orderArrayByObjectPropertyAndDirection<T>(
  arr: T[],
  property: string,
  direction?: 'asc' | 'desc',
  sortBy?: string
): T[] {
  const normalizedDirection = direction?.toLowerCase?.() as 'asc' | 'desc'

  if (!property) {
    return orderBy(arr, ['createdAt'], [normalizedDirection])
  }

  if (sortBy) {
    const sorted = orderBy(arr, sortBy, [normalizedDirection])
    return sorted
  }

  return orderBy(
    arr,
    [
      (obj) => {
        const propertyValue = get(obj, property)
        if (typeof propertyValue === 'undefined') {
          return normalizedDirection === 'asc' ? -1 : 1
        }
        return typeof propertyValue === 'string'
          ? propertyValue.toLowerCase()
          : propertyValue
      },
    ],
    [normalizedDirection]
  )
}

export const getLastTwoValues = (array) => {
  const lastTwoValues = array.slice(-2)

  const lastValue =
    lastTwoValues.length === 1 ? lastTwoValues[0] : lastTwoValues[1]

  const preLastValue = lastTwoValues.length === 1 ? null : lastTwoValues[0]

  return [lastValue, preLastValue]
}

export function removeNulls<T>(array: T[]): T[] {
  return array.filter((item) => item !== null)
}

export function mapToAndIgnoreNulls<U>(
  array: any[],
  callbackfn: (value: any) => U
) {
  // Reduce array to get no null values
  const reducedArray = array.reduce<U[]>((result, value) => {
    if (value !== null) {
      const newValue = callbackfn(value)
      result.push(newValue)
    }
    return result
  }, [])
  return reducedArray
}

export type MapType<K, T> = {
  key: K
  values: T[]
}
export type ArrayMap<K, T> = MapType<K, T>[]

export function getArrayMap<K, T>(
  array: T[],
  iterator: (value: T) => K
): ArrayMap<K, T> {
  const map: ArrayMap<K, T> = []

  array.forEach((value) => {
    const key = iterator(value)
    const mapItemIndex = map.findIndex((item) => item.key === key)
    if (mapItemIndex === -1) {
      map.push({ key, values: [value] })
    } else {
      map[mapItemIndex].values.push(value)
    }
  })

  return map
}
