import { useCallback, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { ChannelFilters } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'

import { ChannelType, StreamChatType } from 'containers/Chat/types'
import {
  useChatSidebarContext,
  useSetActiveChannel,
} from 'containers/Chat/hooks'
import { getUser } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import ChannelCollapsible from './components/ChannelCollapsible'
import CreateChatModal from '../CreateChat/CreateChatModal'
import * as Styles from './ChatSidebar.styles'
import { ChatForm } from '../CreateChat/useCreateChat'
import { useChatMobileContext } from '../ChatMobile'

const DM_TYPE = [ChannelType.DIRECT_MESSAGE, ChannelType.CHATBOT]
const TOPIC_TYPES = [
  ChannelType.HOLDING,
  ChannelType.PORTFOLIO,
  ChannelType.UPDATE,
]

interface Props {
  isMobile?: boolean
}

export const ChatSidebarContent = ({ isMobile = false }: Props) => {
  const user = useAppSelector(getUser)
  const { client } = useChatContext<StreamChatType>()
  const [showCreateChatModal, setShowCreateChatModal] = useState(false)
  const { hideSearchView, showArchiveSidebar, isArchiveSidebarActive } =
    useChatSidebarContext()
  const setActiveChannel = useSetActiveChannel()
  const [initialValues, setInitialValues] = useState<ChatForm | undefined>({
    members: [],
  })
  const { onArchiveButtonClick } = useChatMobileContext()

  const dmFilters: ChannelFilters = useMemo(
    () => ({
      type: { $in: DM_TYPE },
      members: { $in: [user.id] },
    }),
    [user.id]
  )
  const topicFilters: ChannelFilters = useMemo(
    () => ({
      type: { $in: TOPIC_TYPES },
      members: { $in: [user.id] },
    }),
    [user.id]
  )

  const onArchivedButtonClick = useCallback(() => {
    if (!isArchiveSidebarActive) {
      setActiveChannel()
      showArchiveSidebar()
      hideSearchView()
      onArchiveButtonClick()
    }
  }, [
    isArchiveSidebarActive,
    setActiveChannel,
    showArchiveSidebar,
    hideSearchView,
    onArchiveButtonClick,
  ])

  const onCreateDirectMessage = useCallback(() => {
    setShowCreateChatModal(true)
    setInitialValues({ members: [] })
  }, [])

  const onCreateTopic = useCallback(() => {
    setShowCreateChatModal(true)
    setInitialValues(undefined)
  }, [])

  return (
    <>
      {!!client.user?.archivedChannels?.length && (
        <Styles.ArchivedButtonContainer
          onClick={onArchivedButtonClick}
          archiveSidebarActive={isArchiveSidebarActive}
        >
          <Styles.ArchivedIcon>
            <FontAwesomeIcon icon={['far', 'archive']} />
          </Styles.ArchivedIcon>
          <Styles.ArchivedLabel>
            <FormattedMessage id="chat.archived" />
          </Styles.ArchivedLabel>
        </Styles.ArchivedButtonContainer>
      )}

      <Styles.Content>
        <ChannelCollapsible
          intlId="chat.directMessages"
          createChannelIntlId={isMobile ? 'chat.startNewMessage' : undefined}
          filters={dmFilters}
          channelTypes={DM_TYPE}
          showCreateChatModal={onCreateDirectMessage}
        />
        <ChannelCollapsible
          intlId="chat.topics"
          createChannelIntlId={isMobile ? 'chat.addTopic' : undefined}
          filters={topicFilters}
          channelTypes={TOPIC_TYPES}
          showCreateChatModal={onCreateTopic}
        />
      </Styles.Content>

      <CreateChatModal
        show={showCreateChatModal}
        onHide={() => setShowCreateChatModal(false)}
        onChatCreated={hideSearchView}
        initialValues={initialValues}
      />
    </>
  )
}
