import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  min-height: 48.5rem;
`

export const FormTitle = styled.div`
  font-size: 1.8rem;
  color: ${color('darkBlue')};
  padding-bottom: 2.4rem;
`

export const SwitchContainer = styled.div`
  margin-bottom: 2.4rem;

  button {
    font-size: 1.4rem;
  }
`
