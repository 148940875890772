import { useCallback, useEffect, useState } from 'react'
import { Channel } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import type { Event } from 'stream-chat'

import { ChannelData, isTopicChannel, StreamChatType } from '../types'

export const useTypingListener = <ChannelType extends ChannelData>(
  channel: Channel<StreamChatType<ChannelType>>,
  listenTopic?: boolean
) => {
  const { client } = useChatContext<StreamChatType<ChannelType>>()
  const [typing, setTyping] = useState<
    Record<string, Event<StreamChatType<ChannelType>>> | undefined
  >(undefined)

  const areSameChannelsOrTopic = useCallback(
    (event: Event<StreamChatType<ChannelType>>) => {
      const areSameChannels = channel.id === event.channel_id
      const channelEvent = client.channel(
        event.channel_type!,
        event.channel_id!
      )

      const isSameTopic =
        listenTopic &&
        isTopicChannel(channel.data) &&
        isTopicChannel(channelEvent.data)
          ? channel?.data.entityId === channelEvent?.data?.entityId
          : false

      return event.user?.id && (areSameChannels || isSameTopic)
    },
    [channel.data, channel.id, client, listenTopic]
  )

  useEffect(() => {
    const handleTypingStart = (event: Event<StreamChatType<ChannelType>>) => {
      if (areSameChannelsOrTopic(event)) {
        setTyping((prevTyping) => ({
          ...prevTyping,
          [event.user!.id]: event,
        }))
      }
    }

    const handleTypingStop = (event: Event<StreamChatType<ChannelType>>) => {
      if (areSameChannelsOrTopic(event)) {
        setTyping((prevTyping) => {
          const newTyping = { ...prevTyping }
          delete newTyping[event.user!.id]
          return newTyping
        })
      }
    }

    client.on('typing.start', handleTypingStart)
    client.on('typing.stop', handleTypingStop)

    return () => {
      client.off('typing.start', handleTypingStart)
      client.off('typing.stop', handleTypingStop)
    }
  }, [areSameChannelsOrTopic, client])

  return {
    typing,
  }
}
