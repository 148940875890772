import {
  ContinueButton,
  Heading,
  Legend,
} from 'containers/Signup/Signup.styles'
import { SignupContext } from 'containers/Signup/context/SignupContext'
import { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { getUser } from 'selectors/auth'
import Input from 'ui/Input'
import Separator from 'ui/Separator'
import { buildFormError } from 'utils/functions/forms'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { ErrorType } from 'utils/types/common'
import InputError from 'ui/InputError'
import { ButtonsContainer } from '../SetupAccount/SetupAccount.styles'
import { CreateGroupForm } from './CreateGroup.styles'

const CreateGroup = () => {
  const intl = useIntl()
  const {
    investorSignup: { onCreateGroup },
  } = useContext(SignupContext)
  const [name, setName] = useState('')
  const user = useAppSelector(getUser)

  let error
  if (name.length > 80) {
    error = buildFormError(
      intl.formatMessage(
        { id: 'general.youHaveExceededCharacterLimit' },
        { limit: 80 }
      ),
      ErrorType.ERROR,
      true
    )
  }

  return (
    <div>
      <Heading level="h1">
        <FormattedMessage id="createNewGroup.createYourGroup" />
        <Legend>
          <FormattedMessage id="createNewGroup.legend" />
        </Legend>
      </Heading>

      <CreateGroupForm
        onSubmit={(event) => {
          event.preventDefault()
          onCreateGroup({ groupName: name })
        }}
      >
        <Input
          label={intl.formatMessage({ id: 'signup.groupName' })}
          placeholder={intl.formatMessage({ id: 'signup.addYourGroupName' })}
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={error}
        />

        {!error && (
          <InputError type={ErrorType.MESSAGE}>
            {intl.formatMessage(
              { id: 'signup.groupNameSample' },
              { userLastName: user.lastName }
            )}
          </InputError>
        )}

        <Separator />

        <ButtonsContainer>
          <ContinueButton type="submit" primary disabled={!name || !!error}>
            <FormattedMessage id="general.saveAndGoNext" />
          </ContinueButton>
        </ButtonsContainer>
      </CreateGroupForm>
    </div>
  )
}

export default CreateGroup
