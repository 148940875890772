import ReactDOM from 'react-dom'
import { createContext, useContext, useMemo } from 'react'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'

interface BreadcrumbActionPortalProps {
  children: React.ReactNode
}

interface BreadcrumbActionContextProps {
  showActionsBelow: boolean
}

const BreadcrumbActionContext = createContext(
  {} as BreadcrumbActionContextProps
)

export const useBreadcrumbActionContext = () =>
  useContext(BreadcrumbActionContext)

const BreadcrumbActionProvider = ({ children }) => {
  const { matches: showActionsBelow } = useMediaQuery(Media.MAX_LG)

  const memoizedValue = useMemo(
    () => ({
      showActionsBelow,
    }),
    [showActionsBelow]
  )

  return (
    <BreadcrumbActionContext.Provider value={memoizedValue}>
      {children}
    </BreadcrumbActionContext.Provider>
  )
}

export { BreadcrumbActionContext, BreadcrumbActionProvider }

const BreadcrumbActionPortal = ({ children }: BreadcrumbActionPortalProps) => {
  const { matches: showActionsBelow } = useMediaQuery(Media.MAX_LG)

  const actions = document.getElementById(
    showActionsBelow
      ? 'breadcrumb-actions-below-portal'
      : 'breadcrumb-actions-portal'
  )

  if (!actions) return null

  return (
    <BreadcrumbActionProvider>
      {ReactDOM.createPortal(children, actions)}
    </BreadcrumbActionProvider>
  )
}

export default BreadcrumbActionPortal
