import styled from 'styled-components'
import { breakpoint } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const Container = styled.button<{
  isMetricsOption?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.2rem;
  padding: 0 1.2rem;
  width: 100%;
  box-shadow: 0px 0px 15px ${color('darkBlue', 0.1)};
  border-radius: 8px;
  background-color: white;
  font-size: 1.4rem;
  transition: 0.2s;

  @media (max-width: ${breakpoint.sm}) {
    width: 97%;
  }

  &:focus {
    outline: none;
  }

  &:hover(:not(:disabled)) {
    transform: scale(1.02);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  :not(:first-child) {
    margin-top: ${(props) => (props.isMetricsOption ? '2.4rem' : '0')};
  }
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonTitle = styled.span`
  padding-left: 1.2rem;
`

export const StyledUpdateIconFactory = styled.div`
  div,
  button {
    position: relative;
    margin: initial;
    left: initial;
    top: initial;
    width: 3.6rem;
    height: 3.6rem;
  }
`
