import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'
import { Container } from './PreviewError.styles'

const PreviewError = () => {
  return (
    <Container>
      <FontAwesomeIcon
        color={color('red')({ theme })}
        icon={['far', 'exclamation-triangle']}
      />
      <FormattedMessage id="files.errorToLoad" />
    </Container>
  )
}

export default PreviewError
