import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'

import { download } from 'utils/functions/files'

import styles from './DownloadLink.module.scss'

const DownloadLink = ({ url, children, name, className, onFileDownloaded }) => {
  return (
    <button
      onClick={() => {
        onFileDownloaded()
        download(name, url)
      }}
      className={classNames(styles.downloadLink, className)}
      type="button"
    >
      <FontAwesomeIcon icon={['fas', 'download']} />
      {children}
    </button>
  )
}

DownloadLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onFileDownloaded: PropTypes.func,
}

DownloadLink.defaultProps = {
  className: '',
  onFileDownloaded: () => {},
}

export default DownloadLink
