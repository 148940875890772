import { MixedUpdate } from 'utils/types/update'

export type SelectedCardsState<T extends MixedUpdate = MixedUpdate> = {
  [id: string]: T
}

const selectCard = (
  prev: SelectedCardsState,
  update: MixedUpdate
): SelectedCardsState => {
  return { ...prev, [update.id]: update }
}

const unSelectCard = (
  prev: SelectedCardsState,
  update: MixedUpdate
): SelectedCardsState => {
  const newState = { ...prev }
  delete newState[update.id]
  return newState
}

export enum Action {
  SELECT_ALL = 'select_all',
  UNSELECT_ALL = 'unselect_all',
  SELECT_CARD = 'select_card',
  UNSELECT_CARD = 'unselect_card',
  TOGGLE_CARD = 'toggle_card',
}

type PayloadAction =
  | {
      type: Action.SELECT_ALL
      payload: MixedUpdate[]
    }
  | {
      type: Action.UNSELECT_ALL
    }
  | {
      type: Action.SELECT_CARD | Action.UNSELECT_CARD | Action.TOGGLE_CARD
      payload: MixedUpdate
    }

export const selectableCardsReducer = (
  state: SelectedCardsState,
  action: PayloadAction
) => {
  switch (action.type) {
    case Action.SELECT_ALL:
      return action.payload.reduce((acc, update) => {
        return selectCard(acc, update)
      }, {})

    case Action.UNSELECT_ALL:
      return {}

    case Action.SELECT_CARD:
      return selectCard(state, action.payload)

    case Action.UNSELECT_CARD:
      return unSelectCard(state, action.payload)

    case Action.TOGGLE_CARD:
      if (state[action.payload.id]) {
        return unSelectCard(state, action.payload)
      }
      return selectCard(state, action.payload)

    default:
      return state
  }
}
