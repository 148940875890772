import { IFormContext } from 'components/Form/hooks'
import { Holding } from 'utils/types/company'

export interface AddHoldingForm extends IFormContext {
  type: HoldingType
  company?: CompanyForm
  funds?: FundsForm
}

export enum HoldingType {
  COMPANY = 'COMPANY',
  FUND = 'FUND',
  DEAL = 'DEAL',
}

export interface CompanyForm {
  legalEntityName: string
  name: string
  website?: string
  pointOfContact?: string
  logo?: LogoImage
}

export interface FundsForm {
  funds: string[]
  includeFundManager: boolean
  fundManager?: FundManager
}

export interface FundManager {
  name: string
  website?: string
  pointOfContact?: string
  logo?: LogoImage
}

export interface LogoImage {
  file: Blob
  url: string
}

export type AddHoldingFormId = AddHoldingForm & { id: string }

export type AddHoldingBulkImportForm = { holdings: AddHoldingFormId[] }

export interface AddHoldingFormErrors {
  company: {
    legalEntityName?: string
    name?: string
    website?: string
    pointOfContact?: string
  }
  funds: {
    funds: (string | undefined)[] | string
    fundManager?: {
      name?: string
      website?: string
      pointOfContact?: string
    }
  }
}

export interface AddHoldingBulkImportFormErrors {
  holdings: AddHoldingFormErrors[]
}

export interface ProfileData {
  id?: string
  name: string
  url?: string
  holding?: Holding
}

export interface SuggestionErrors {
  fundsErrors?: ProfileData[][]
  fundManagerErrors?: {
    website: ProfileData
  }
  companyErrors?: {
    isHidden?: boolean
    website?: ProfileData
  }
}

export interface BulkImportSuggestionErrors {
  holdings: SuggestionErrors[]
}

export interface CompanyServerErrors {
  website?: string[]
  companyId?: string[]
  companyName?: string[]
}

export type DraftValidationResponse =
  | CompanyDraftValidationResponse
  | FundDraftValidationResponse

export interface CompanyDraftValidationResponse {
  index: number
  company: {
    errors: CompanyServerErrors
  }
}

export interface FundDraftValidationResponse {
  index: number
  fundManager: {
    errors: {
      website?: string[]
      companyId?: string[]
      companyName?: string[]
    }
  }
  funds: {
    errors: {
      name?: string[]
    }
  }[]
}
