import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { lazy } from 'react'
import FileDetails from 'containers/Files/FileDetails'
import EmailContentDetails from 'containers/Files/EmailContentDetails'

const Files = lazy(() => import('containers/Files'))

const FilesRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <>
      <Files />
      <Switch>
        <Route exact path={`${path}/:id/file-details`}>
          <FileDetails />
        </Route>
        <Route exact path={`${path}/:id/email-details`}>
          <EmailContentDetails />
        </Route>
      </Switch>
    </>
  )
}

export default FilesRoutes
