import sortBy from 'lodash/sortBy'
import compact from 'lodash/compact'
import { EmailRecipientStatus } from 'utils/constants/emailRecipientStatus'
import { ImageSize } from 'utils/constants/iue'
import { parseUpdateType } from 'utils/constants/updates'
import { getContentsPayload } from 'utils/functions/api/updates'
import { getEntryStylesForMJML } from 'utils/functions/mjml'
import { getOrderForStatus } from 'utils/functions/updates'
import { EmailIndexUpdate } from 'utils/types/update'
import { EntityType } from 'utils/constants/groups'
import axiosClient from './httpClient'
import { FileContent } from '../utils/types/files'

class EmailService {
  static getEmails = async (
    companyId: string,
    getDraftEmails: boolean,
    page: number,
    pageSize: number
  ): Promise<{ total: number; results: EmailIndexUpdate[] }> => {
    const {
      data: { result, entities },
      headers: { total },
    } = await axiosClient().get(`/companies/${companyId}/iues`, {
      params: {
        draft: getDraftEmails,
        page,
        per_page: pageSize,
        direction: 'desc',
        order_by: getDraftEmails ? 'created_at' : 'sent_at',
      },
    })

    return {
      total: Number(total),
      results: result.map((emailId) => {
        const iue = entities.iues[emailId]
        const update = entities.loggingUpdates[iue.loggingUpdate]

        return {
          ...update,
          draft: !iue.sentAt,
          updateType: parseUpdateType(update.type),
          item: {
            ...iue,
            reportRecipients: iue.reportRecipients.map(
              (recipientId) => entities.reportRecipients[recipientId]
            ),
          },
          contents: update.contents ?? [],
          loggingUpdateGroups: update.loggingUpdateGroups ?? [],
          loggingUpdateUsers: update.loggingUpdateUsers ?? [],
        }
      }),
    }
  }

  static createReportRecipient = async (iueId, body) => {
    const response = await axiosClient().post(
      `/iues/${iueId}/report_recipients`,
      body
    )

    const {
      data: {
        result,
        entities: { reportRecipients },
      },
    } = response

    return reportRecipients[result]
  }

  static bulkCreateReportRecipient = async (iueId, pageSize, body) => {
    const response = await axiosClient().post(
      `/iues/${iueId}/report_recipients/bulk_create?&per_page=${pageSize}`,
      body
    )

    const {
      data: {
        result,
        entities: { reportRecipients, users, groups },
      },
    } = response

    return result.map((recipientId) => ({
      ...reportRecipients[recipientId],
      user: users?.[reportRecipients[recipientId]?.user],
      group: groups?.[reportRecipients[recipientId]?.group],
    }))
  }

  static bulkDeleteReportRecipient = async (iueId, recipientIds) => {
    return axiosClient().delete(
      `/iues/${iueId}/report_recipients/bulk_destroy`,
      {
        data: {
          reportRecipients: recipientIds.map((id) => ({
            id,
          })),
          scheduled: false,
        },
      }
    )
  }

  static getReportRecipients = (iueId, pageSize) => {
    return axiosClient().get(
      `/iues/${iueId}/report_recipients?&per_page=${pageSize}`
    )
  }

  static removeReportRecipient = (id) => {
    return axiosClient().delete(`/report_recipients/${id}`)
  }

  static editReportSection = (entryId, section) => {
    const body = {
      reportEntry: section,
      scheduled: false,
    }
    return axiosClient().patch(`/report_entries/${entryId}`, body)
  }

  static createReportEntry = (iueId, section) => {
    const body = {
      reportEntry: section,
      scheduled: false,
    }
    return axiosClient().post(`/iues/${iueId}/report_entries`, body)
  }

  static createDefaultHeader = (iueId, subject, logoUr) => {
    return this.createReportEntry(iueId, {
      text: `
            <mj-image ${getEntryStylesForMJML({
              type: 'image',
              imageSize: ImageSize.SMALL,
              textAlign: '',
              sectionColor: '',
            })} src="${logoUr}" />
            <mj-text ${getEntryStylesForMJML({
              type: 'text',
              imageSize: ImageSize.SMALL,
              textAlign: 'center',
              sectionColor: '',
            })}>
                <p>${subject}</p>
            </mj-text>
          `,
      position: 1,
      title: 'HEADER',
      showTitle: false,
    })
  }

  static deleteReportSection = (entryId) => {
    return axiosClient().delete(`/report_entries/${entryId}`)
  }

  static changeEntryPosition = ({ reportEntryId, position }) => {
    const body = {
      reportEntry: {
        position,
      },
      scheduled: false,
    }
    return axiosClient().patch(`report_entries/${reportEntryId}`, body)
  }

  static getReportEntries = (iueId) => {
    return axiosClient().get(`iues/${iueId}/report_entries`)
  }

  static getDefaultSectionsByGroupId = () => {
    return axiosClient().get(`/group`)
  }

  static createAssetToEmail = (iueId, data) => {
    return axiosClient(true).post(`/iues/${iueId}/report_assets`, data)
  }

  static addEmailAttachments = (
    updateId: string,
    iueId: string,
    fileContents: FileContent[]
  ) => {
    const payload = {
      report: {
        loggingUpdateAttributes: {
          id: updateId,
          ...getContentsPayload({ addedContents: fileContents }),
          scheduled: false,
        },
      },
    }

    return axiosClient().patch(`/iues/${iueId}`, payload)
  }

  static removeEmailAttachments = (
    updateId: string,
    iueId: string,
    fileContents: FileContent[]
  ) => {
    const payload = {
      report: {
        loggingUpdateAttributes: {
          id: updateId,
          ...getContentsPayload({ removedFiles: fileContents }),
          scheduled: false,
        },
      },
    }

    return axiosClient().patch(`/iues/${iueId}`, payload)
  }

  static editCustomSections = (iueSections) => {
    return axiosClient().patch('/group', { group: { iueSections } })
  }

  static createEmail = (companyId, teamId) => {
    return axiosClient().post(`/companies/${companyId}/iues`, teamId)
  }

  static editEmail = (id, body) => {
    return axiosClient().patch(`/iues/${id}`, body)
  }

  static sendEmail = (id) => {
    return axiosClient().post(`/iues/${id}/deliver`)
  }

  static getEmail = (id) => {
    return axiosClient().get(`/updates/${id}`)
  }

  static getEmailPreview = (id) => {
    return axiosClient().get(`/iues/${id}/preview`)
  }

  static sendTestEmail = (id, body) => {
    return axiosClient().post(`/iues/${id}/test_email`, body)
  }

  static duplicateEmail = async (emailId) => {
    const response = await axiosClient().post(`/iues/${emailId}/duplicate`)
    const iue = response.data.entities.iues[response.data.result]
    return {
      ...iue,
      loggingUpdate: response.data.entities.loggingUpdates[iue.loggingUpdate],
    }
  }

  static resendEmail = (emailId, body) => {
    return axiosClient().post(`/iues/${emailId}/redeliver`, body)
  }

  static deleteIUEDRaft = (emailId) => {
    return axiosClient().delete(`/iues/${emailId}`)
  }

  static updateIUE = (emailId, data) => {
    return axiosClient().patch(`/iues/${emailId}`, data)
  }

  static deleteDrafts = (companyId, drafts) => {
    const iueIds = drafts.map((draftId) => {
      return `iue_ids[]=${encodeURIComponent(draftId)}`
    })
    const queryParams = iueIds.join('&')
    return axiosClient().delete(
      `/companies/${companyId}/iues/bulk_destroy?${queryParams}`
    )
  }

  static getEmailRecipientStatus = async (emailId, pageSize) => {
    const response = await axiosClient().get(
      `/iues/${emailId}/report_recipients?&per_page=${pageSize}`
    )
    const {
      result,
      entities: { reportRecipients, users, recipientEmailStatuses },
    } = response.data

    const openStatuses: any[] = []

    const data = result
      .map((reportRecipientId) => {
        const recipient = reportRecipients?.[reportRecipientId]
        const user = users?.[recipient?.user]

        const recipientStatuses = sortBy(
          recipient.recipientEmailStatuses?.map?.(
            (recipientStatusId) => recipientEmailStatuses?.[recipientStatusId]
          ) ?? [],
          (state) => -getOrderForStatus(state.status),
          (state) => -new Date(state.receivedAt)
        )

        const [currentStatus, ...otherRecipientEmailStatuses] =
          recipientStatuses || []

        if (currentStatus?.status === EmailRecipientStatus.OPENED) {
          openStatuses.push(currentStatus)
        }
        return {
          ...reportRecipients[reportRecipientId],
          user,
          currentStatus,
          recipientEmailStatuses: compact([
            currentStatus,
            ...otherRecipientEmailStatuses,
          ]),
        }
      })
      .filter((recipient) => recipient.recipientType === EntityType.USER)

    const countDelivered = Object.values(recipientEmailStatuses || {}).filter(
      (recipientEmailStatus: any) =>
        recipientEmailStatus.status === EmailRecipientStatus.DELIVERED
    ).length

    const countOpened = openStatuses.length
    const totalEmails = data.length
    const round = (num) => Math.round(num * 100) / 100

    return {
      data,
      countDelivered,
      countOpened,
      openRate: round((countOpened * 100) / (totalEmails || 1)),
    }
  }
}

export default EmailService
