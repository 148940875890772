import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const BtnWrapper = styled.div`
  input {
    display: none;
  }
  label {
    display: inline-block;
    cursor: pointer;
    padding: 1rem 2.6rem;
    border-radius: 0.8rem;
    background-color: ${color('primaryBlue')};
    margin-top: 2.2rem;
    height: 3.7rem;
    color: var(--color-white);
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: normal;
    margin-bottom: 3.6rem;

    /* Secondary */
    ${(props) =>
      props.secondary &&
      css`
        background-color: ${color('white')};
        color: ${color('darkBlue')};
        border: 0.1rem solid ${color('veryLightBlue')};
        font-weight: 700;

        &:hover:not(:disabled) {
          background-color: ${color('veryLightBlue', 0.4)};
        }
      `}
  }
`
