/* eslint-disable no-param-reassign */
import type { IntlShape } from 'react-intl'

import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'

import { PortfolioTypes } from 'utils/constants/portfolio'
import { seededRandomColor } from 'utils/functions/colors'
import { PortfoliosData } from 'utils/hooks/investments/useFetchInvestmentsSummary'

import {
  FundPortfolioInvestor,
  SharePortfolioSettings,
} from 'utils/types/funds'

import { Nullable } from 'utils/types/common'
import {
  ChartColors,
  FundPortfolio,
  Portfolio,
  PortfolioCompany,
  PortfolioCompanyWithSharedSettingsApplied,
  PortfolioType,
} from 'utils/types/portfolios'
import {
  FileCounts,
  UpdateCounts,
  UpdateSummaryChartData,
} from 'api/PortfolioService'
import dayjs from 'dayjs'
import theme from 'utils/theme'
import _ from 'lodash'
import {
  FundManagerRoutes,
  SetRouteConfigurationPayload,
} from 'reducers/breadcrumbSlice'

export const MAX_COMPANIES_ON_PIE_CHART = 8
export const MAX_INVESTORS_ON_PIE_CHART = 8

export const CHART_COLORS = [
  '#3F51B5',
  '#2196F3',
  '#00BCD4',
  '#38B8A5',
  '#56A55A',
  '#8BC34A',
  '#CDDC39',

  '#FDBE00',
  '#FF9800',
  '#EA6C44',
  '#E85464',
  '#CA4270',
  '#C047BB',
  '#8E67CE',
  '#6771CE',
]
const OTHER_COMPANIES_COLOR = '#E7D752'

export const getChartColor = (index: number): string => {
  if (index <= CHART_COLORS.length - 1) {
    return CHART_COLORS[index]
  }
  return seededRandomColor(index)
}

export enum FileTypes {
  TOTAL_PDF = 'totalPdfs',
  TOTAL_IMAGE = 'totalImages',
  TOTAL_DOCUMENT = 'totalDocuments',
  TOTAL_SPREADSHEET = 'totalSpreadsheets',
  TOTAL_PRESENTATION = 'totalPresentations',
  TOTAL_AUDIO = 'totalAudios',
  TOTAL_EMAIL_CONTENT = 'totalEmailContents',
  TOTAL_VIDEO = 'totalVideos',
  TOTAL_COMPRESSED_FILE = 'totalCompressedFiles',
  TOTAL_OTHER = 'totalOtherTypes',
}

export enum DealTotals {
  TOTAL_INVESTED = 'totalInvested',
  TOTAL_VALUE = 'totalValue',
}

export enum UpdateTypes {
  TOTAL_UPDATES = 'totalUpdates',
  TOTAL_NOTES = 'totalNotes',
  TOTAL_DOCUMENTS = 'totalDocuments',
  TOTAL_ANNOUNCEMENTS = 'totalAnnouncements',
  TOTAL_EMAILS = 'totalEmails',
  TOTAL_SUGGESTED_UPDATES = 'totalSuggestedUpdates',
  TOTAL_DRAFT_UPDATES = 'totalDraftUpdates',
  TOTAL_CREATED_UPDATES = 'totalCreatedUpdates',
}

export const getFilesChartColor = (fileType: FileTypes | string): string => {
  switch (fileType) {
    case FileTypes.TOTAL_PDF:
      return theme.colors.orange
    case FileTypes.TOTAL_IMAGE:
      return theme.colors.yellow
    case FileTypes.TOTAL_DOCUMENT:
      return theme.colors.brightBlue
    case FileTypes.TOTAL_SPREADSHEET:
      return theme.colors.darkGreen
    case FileTypes.TOTAL_PRESENTATION:
      return theme.colors.darkOrange
    case FileTypes.TOTAL_AUDIO:
      return theme.colors.skyLightBlue
    case FileTypes.TOTAL_EMAIL_CONTENT:
      return theme.colors.green
    case FileTypes.TOTAL_VIDEO:
      return theme.colors.darkPurple
    case FileTypes.TOTAL_COMPRESSED_FILE:
      return theme.colors.pink
    case FileTypes.TOTAL_OTHER:
      return theme.colors.lightGray
    default:
      return ''
  }
}

export const getDealChartColor = (fileType: DealTotals | string): string => {
  switch (fileType) {
    case DealTotals.TOTAL_INVESTED:
      return theme.colors.brightBlue
    case DealTotals.TOTAL_VALUE:
      return theme.colors.softLightBlue2
    default:
      return ''
  }
}

export const getUpdatesChartColor = (
  fileType: UpdateTypes | string
): string => {
  switch (fileType) {
    case UpdateTypes.TOTAL_NOTES:
      return theme.colors.yellow
    case UpdateTypes.TOTAL_ANNOUNCEMENTS:
      return theme.colors.red
    case UpdateTypes.TOTAL_EMAILS:
      return theme.colors.darkGreen
    case UpdateTypes.TOTAL_DOCUMENTS:
      return theme.colors.brightBlue
    case UpdateTypes.TOTAL_DRAFT_UPDATES:
      return theme.colors.darkOrange
    case UpdateTypes.TOTAL_SUGGESTED_UPDATES:
      return theme.colors.skyLightBlue
    case UpdateTypes.TOTAL_UPDATES:
      return theme.colors.green
    case UpdateTypes.TOTAL_CREATED_UPDATES:
      return theme.colors.darkPurple
    default:
      return ''
  }
}

export type GroupedPortfolios = {
  trackPortfolios: Portfolio[]
  investPortfolios: Portfolio[]
  fundPortfolios: Portfolio[]
}

export type GroupedPortfolioCompanies = {
  trackPortfolios: PortfolioCompany[]
  investPortfolios: PortfolioCompany[]
  fundPortfolios: PortfolioCompany[]
}

export const getFilteredPortfolios = (
  portfolios: Portfolio[]
): GroupedPortfolios => {
  const trackPortfolios: Portfolio[] = []
  const investPortfolios: Portfolio[] = []
  const fundPortfolios: Portfolio[] = []

  portfolios.forEach((portfolio) => {
    switch (portfolio.type) {
      case PortfolioTypes.TRACK:
        trackPortfolios.push(portfolio)
        break
      case PortfolioTypes.INVEST:
        investPortfolios.push(portfolio)
        break
      case PortfolioTypes.FUND:
        fundPortfolios.push(portfolio)
        break
      default:
        break
    }
  })
  return { trackPortfolios, investPortfolios, fundPortfolios }
}

export const groupPortfolioCompaniesByType = (
  portfolioCompanies: PortfolioCompany[]
): GroupedPortfolioCompanies =>
  portfolioCompanies.reduce<GroupedPortfolioCompanies>(
    (result, portfolio) => {
      if (portfolio.type === PortfolioTypes.TRACK) {
        result.trackPortfolios.push(portfolio)
      }
      if (portfolio.type === PortfolioTypes.INVEST) {
        result.investPortfolios.push(portfolio)
      }
      if (portfolio.type === PortfolioTypes.FUND) {
        result.fundPortfolios.push(portfolio)
      }
      return result
    },
    {
      trackPortfolios: [],
      investPortfolios: [],
      fundPortfolios: [],
    }
  )

export const removeCompanyAtIndex = (index) => (companies) => {
  const newCompanies = [...companies]
  newCompanies.splice(index, 1)
  return newCompanies
}

export const pushCompany = (newCompany) => (companies) =>
  [...companies, newCompany]

const calculateTotal = (
  data: InvestPortfolioChartData[] | FundPortfolioInvestor[] | FundPortfolio[],
  key:
    | 'totalAmountInvested'
    | 'totalValue'
    | 'investorCapitalCalled'
    | 'investorCommittedCapital'
    | 'totalCommitted'
    | 'totalCommittedCapital'
    | 'totalCapitalCalled'
    | 'capitalCalled'
): number =>
  Object.values(data).reduce((total, currCompanyData) => {
    if (typeof currCompanyData[key] === 'number') {
      total += currCompanyData[key] ?? 0
    }
    return total
  }, 0)

interface Group {
  id: 'other'
  name: string
}
export interface GroupedCompanies extends Group {
  companiesIds: string[]
  totalAmountInvested: number
  totalValue: number
}

export interface GroupedFunds extends Group {
  fundsIds: string[]
  totalCapitalCalled: number
  totalCommittedCapital: number
}

export interface GroupedFundInvestors extends Group {
  investorCapitalCalled: number
  investorsIds: string[]
}

const getOtherCompaniesGroupedByPercentage = (
  companies: PortfolioCompanyWithSharedSettingsApplied[],
  maxCompaniesToShow: number
): GroupedCompanies => {
  const companiesToGroup = [...companies].splice(maxCompaniesToShow)
  const otherTotalValue = companiesToGroup.reduce(
    (total, company) => total + (company.totalValue ?? 0),
    0
  )
  const otherTotalInvested = companiesToGroup.reduce(
    (total, company) => total + (company.totalAmountInvested ?? 0),
    0
  )

  return {
    id: 'other',
    name: '',
    totalAmountInvested: otherTotalInvested,
    totalValue: otherTotalValue,
    companiesIds: companiesToGroup.map((comp) => comp.id),
  }
}

type FundPortfolioWithTotalInvestment = FundPortfolio & {
  totalInvestment: number
}

const getSortedFundsByInvestment = (
  data: FundPortfolio[]
): FundPortfolioWithTotalInvestment[] => {
  const funds: FundPortfolioWithTotalInvestment[] = data.map((fund) => ({
    ...fund,
    totalInvestment:
      (fund.totalCommittedCapital || 0) + (fund.totalCapitalCalled || 0),
  }))

  const sortedByTotalInvestment = orderBy(funds, ['totalInvestment'], ['desc'])

  return sortedByTotalInvestment
}

const getOtherFundsGroupedByPercentage = (
  funds: FundPortfolio[],
  maxFundsToShow: number,
  intl: IntlShape
): GroupedFunds => {
  const sortedFunds = getSortedFundsByInvestment(funds)
  const fundsToGroup = [...sortedFunds].splice(maxFundsToShow)
  const otherCapitalCalled = fundsToGroup.reduce(
    (total, fund) => total + (fund.totalCapitalCalled || 0),
    0
  )
  const otherTotalCommitted = fundsToGroup.reduce(
    (total, fund) => total + (fund.totalCommittedCapital || 0),
    0
  )

  return {
    id: 'other',
    name: intl.formatMessage({
      id: 'portfolioDetail.investorSummary.chart.others',
    }),
    totalCommittedCapital: otherTotalCommitted,
    totalCapitalCalled: otherCapitalCalled,
    fundsIds: fundsToGroup.map((fund) => fund.id),
  }
}

const getOtherInvestorsGroupedByPercentage = (
  investors: FundPortfolioInvestor[],
  maxInvestorsToShow: number
): GroupedFundInvestors => {
  const investorsToGroup = [...investors].splice(maxInvestorsToShow)
  const otherTotalCapitalCalled = investorsToGroup.reduce(
    (total, investor) => total + investor.investorCapitalCalled,
    0
  )

  return {
    id: 'other',
    name: 'other',
    investorCapitalCalled: otherTotalCapitalCalled,
    investorsIds: investorsToGroup.map((investor) => investor.id),
  }
}

export type InvestPortfolioChartData =
  | PortfolioCompanyWithSharedSettingsApplied
  | GroupedCompanies

export type InvestPortfolioChartDataWithPercentages =
  InvestPortfolioChartData & {
    totalValuePercentage?: number
    totalAmountInvestedPercentage?: number
    holdingId?: string
  }
export type AllFundsChartDataWithPercentages = FundPortfolio & {
  totalCommittedCapitalPercentage: number
  totalCapitalCalledPercentage: number
}
export type FundPortfolioChartDataWithPercentages = FundPortfolioInvestor & {
  name?: string
  totalCapitalCalledPercentage: number
}

export const isFieldShared = (
  value?: Nullable<number | Date | string | Array<any>>
) => value !== undefined && value !== null

export const isFieldSettingShared = (
  field: string,
  sharePortfolioSettings?: SharePortfolioSettings
) => {
  if (!sharePortfolioSettings) return true
  return !!sharePortfolioSettings.settings.visibleFields[field]
}

export const isPieChartDataShared = (
  chartData: InvestPortfolioChartDataWithPercentages[],
  sharePortfolioSetting?: SharePortfolioSettings
) => {
  if (!chartData.length) {
    return {
      hasTotalValueShared: true,
      hasTotalAmountInvestedShared: true,
    }
  }

  const hasTotalValueShared =
    !sharePortfolioSetting ||
    sharePortfolioSetting.settings.visibleFields.investmentHoldingTotalValue

  const hasTotalAmountInvestedShared =
    !sharePortfolioSetting ||
    sharePortfolioSetting.settings.visibleFields.investmentHoldingAmountInvested

  return {
    hasTotalValueShared,
    hasTotalAmountInvestedShared,
  }
}

const getInvestPortfolioChartDataWithPercentage = (
  data: InvestPortfolioChartData[]
): InvestPortfolioChartDataWithPercentages[] => {
  const percentagesData: InvestPortfolioChartDataWithPercentages[] = []
  const totalPortfolioValue = calculateTotal(data, 'totalValue')
  const totalPortfolioAmountInvested = calculateTotal(
    data,
    'totalAmountInvested'
  )
  data.forEach((rowData) => {
    const { totalValue, totalAmountInvested } = rowData
    const totalValuePercentage = isFieldShared(totalValue)
      ? Math.round(((totalValue! * 100) / totalPortfolioValue) * 10) / 10.0
      : undefined

    const totalAmountInvestedPercentage = isFieldShared(totalAmountInvested)
      ? Math.round(
          ((totalAmountInvested! * 100) / totalPortfolioAmountInvested) * 10
        ) / 10.0
      : undefined

    percentagesData.push({
      ...rowData,
      totalValuePercentage,
      totalAmountInvestedPercentage,
    })
  })

  return percentagesData
}

const getAllFundsChartDataWithPercentage = (
  data: FundPortfolio[]
): AllFundsChartDataWithPercentages[] => {
  const percentagesData: AllFundsChartDataWithPercentages[] = []
  const totalPortfolioValue = calculateTotal(data, 'totalCommittedCapital')
  const totalPortfolioAmountInvested = calculateTotal(
    data,
    'totalCapitalCalled'
  )
  data.forEach((rowData) => {
    const { totalCommittedCapital, totalCapitalCalled } = rowData
    const totalCommittedCapitalPercentage = totalPortfolioValue
      ? Math.round(((totalCommittedCapital * 100) / totalPortfolioValue) * 10) /
        10.0
      : 0

    const totalCapitalCalledPercentage = totalPortfolioAmountInvested
      ? Math.round(
          ((totalCapitalCalled * 100) / totalPortfolioAmountInvested) * 10
        ) / 10.0
      : 0

    percentagesData.push({
      ...rowData,
      totalCommittedCapitalPercentage,
      totalCapitalCalledPercentage,
    })
  })

  return percentagesData
}

const getFundInvestorsChartDataWithPercentage = (
  data: FundPortfolioInvestor[]
): FundPortfolioChartDataWithPercentages[] => {
  const percentagesData: FundPortfolioChartDataWithPercentages[] = []
  const totalInvestorsCapitalCalled = calculateTotal(
    data,
    'investorCapitalCalled'
  )

  data.forEach((rowData) => {
    const { investorCapitalCalled } = rowData
    const totalCapitalCalledPercentage =
      Math.round(
        ((investorCapitalCalled * 100) / totalInvestorsCapitalCalled) * 10
      ) / 10.0

    percentagesData.push({
      ...rowData,
      totalCapitalCalledPercentage,
    })
  })

  return percentagesData
}

const getAllFundsPortfolioChartDataWithPercentage = (
  data: FundPortfolio[],
  intl: IntlShape
) => {
  let sortedDataByTotalInvested = getSortedFundsByInvestment(data)
  let groupedCompaniesBelowPercentage: GroupedFunds = {
    id: 'other',
    name: intl.formatMessage({
      id: 'portfolioDetail.investorSummary.chart.others',
    }),
    fundsIds: [],
    totalCapitalCalled: 0,
    totalCommittedCapital: 0,
  }

  let dataToReturn
  if (data.length > MAX_COMPANIES_ON_PIE_CHART) {
    groupedCompaniesBelowPercentage = getOtherFundsGroupedByPercentage(
      sortedDataByTotalInvested,
      MAX_COMPANIES_ON_PIE_CHART,
      intl
    )
    sortedDataByTotalInvested = sortedDataByTotalInvested.slice(
      0,
      MAX_COMPANIES_ON_PIE_CHART
    )
    dataToReturn = [
      ...sortedDataByTotalInvested,
      groupedCompaniesBelowPercentage,
    ]
  } else {
    dataToReturn = sortedDataByTotalInvested
  }

  return getAllFundsChartDataWithPercentage(dataToReturn)
}

interface IndividualFundPortfolio extends FundPortfolio {
  fundPortfolioInvestors: FundPortfolioInvestor[]
}

export const getAllFundsPortfolioIndividualChartData = (
  data: FundPortfolio[],
  intl: IntlShape
): AllFundsChartDataWithPercentages[] => {
  const fundsPortfolioIndividualData = data.map(
    ({ fundPortfolioInvestors, ...rest }: IndividualFundPortfolio) => {
      const investor = fundPortfolioInvestors?.[0]
      return {
        ...investor,
        ...rest,
        totalCommittedCapital: investor?.investorCommittedCapital,
        totalCapitalCalled: investor?.investorCapitalCalled,
      }
    }
  )

  return getAllFundsPortfolioChartDataWithPercentage(
    fundsPortfolioIndividualData,
    intl
  )
}

export const getAllFundsPortfolioChartData = (
  data: FundPortfolio[],
  intl: IntlShape
): AllFundsChartDataWithPercentages[] => {
  return getAllFundsPortfolioChartDataWithPercentage(data, intl)
}

export const getInvestPortfolioChartData = (
  data: PortfolioCompanyWithSharedSettingsApplied[],
  groupedName: string
): InvestPortfolioChartDataWithPercentages[] => {
  let sortedDataByTotalValue = orderBy(data, ['totalValue', 'name'], ['desc'])
  let groupedCompaniesBelowPercentage: GroupedCompanies = {
    id: 'other',
    companiesIds: [],
    name: groupedName,
    totalAmountInvested: 0,
    totalValue: 0,
  }

  let dataToReturn
  if (data.length > MAX_COMPANIES_ON_PIE_CHART) {
    groupedCompaniesBelowPercentage = getOtherCompaniesGroupedByPercentage(
      sortedDataByTotalValue,
      MAX_COMPANIES_ON_PIE_CHART
    )
    groupedCompaniesBelowPercentage.name = groupedName
    sortedDataByTotalValue = sortedDataByTotalValue.slice(
      0,
      MAX_COMPANIES_ON_PIE_CHART - 1
    )
    dataToReturn = [...sortedDataByTotalValue, groupedCompaniesBelowPercentage]
  } else {
    dataToReturn = sortedDataByTotalValue
  }

  return getInvestPortfolioChartDataWithPercentage(dataToReturn)
}

export const getFollowedHoldingsChartData = (
  pieChart: [string, number][],
  totalPercentage: number,
  intl: IntlShape
) => {
  const transformedChartData = pieChart
    .map(([name, quantity]) => ({
      name: String(name),
      percentage: (Number(quantity) / totalPercentage) * 100,
      totalHoldings: quantity,
    }))
    .sort((a, b) => b.percentage - a.percentage)

  if (transformedChartData.length > 8) {
    const top8 = transformedChartData.slice(0, 8)
    const rest = transformedChartData.slice(8)

    const otherPercentage = rest.reduce((acc, item) => acc + item.percentage, 0)
    const others = {
      name: intl.formatMessage({ id: 'portfolios.others' }),
      percentage: otherPercentage,
      totalHoldings: rest.reduce((acc, item) => acc + item.totalHoldings, 0),
    }

    return [...top8, others]
  }

  return transformedChartData
}

export const getFundInvestorsChartData = (
  data: FundPortfolioInvestor[],
  groupedName: string
): FundPortfolioChartDataWithPercentages[] => {
  let sortedDataByInvestorCapitalCalled = orderBy(
    data,
    ['investorCapitalCalled', 'fundPortfolioName'],
    ['desc']
  )
  let othersInvestors: GroupedFundInvestors = {
    id: 'other',
    investorsIds: [],
    name: groupedName,
    investorCapitalCalled: 0,
  }

  let dataToReturn
  if (data.length > MAX_INVESTORS_ON_PIE_CHART) {
    othersInvestors = getOtherInvestorsGroupedByPercentage(
      sortedDataByInvestorCapitalCalled,
      MAX_INVESTORS_ON_PIE_CHART
    )
    othersInvestors.name = groupedName
    sortedDataByInvestorCapitalCalled = sortedDataByInvestorCapitalCalled.slice(
      0,
      MAX_INVESTORS_ON_PIE_CHART - 1
    )
    dataToReturn = [...sortedDataByInvestorCapitalCalled, othersInvestors]
  } else {
    dataToReturn = sortedDataByInvestorCapitalCalled
  }

  return getFundInvestorsChartDataWithPercentage(dataToReturn)
}

export const getOldestInvestmentDateFromPortfolioCompanies = (
  companiesData: PortfolioCompany[] = []
): Nullable<string> => {
  const companiesSortedByDate = companiesData
    .filter((company) => !!company.firstInvestmentDate)
    .map((company) => company.firstInvestmentDate)
    .sort(
      (firstDate, secondDate) => Date.parse(firstDate) - Date.parse(secondDate)
    )

  return companiesSortedByDate?.[0] ?? null
}

export const getColorsForFunds = (funds: FundPortfolio[]): ChartColors => {
  const companiesToShow = orderBy(funds, ['totalCommitted', 'name'], ['desc'])

  const result: ChartColors = companiesToShow.reduce((res, fund, index) => {
    res[fund?.id] = getChartColor(index)
    return res
  }, {})

  result.other = OTHER_COMPANIES_COLOR
  return result
}

export const getColorsForCompanies = (
  companies: PortfolioCompany[]
): ChartColors => {
  const companiesToShow = orderBy(companies, ['totalValue', 'name'], ['desc'])

  const result: ChartColors = companiesToShow.reduce((res, company, index) => {
    res[company?.holding?.id || company?.id || company?.holdingId || ''] =
      getChartColor(index)
    return res
  }, {})

  result.other = OTHER_COMPANIES_COLOR
  return result
}

export const getColorsForPortfolios = (
  portfolios: PortfoliosData[]
): ChartColors => {
  const portfoliosToShow = orderBy(portfolios, ['totalValue', 'name'], ['desc'])

  const result: ChartColors = portfoliosToShow.reduce(
    (res, portfolio, index) => {
      res[portfolio?.id ?? portfolio?.id] = getChartColor(index)
      return res
    },
    {}
  )

  result.other = OTHER_COMPANIES_COLOR
  return result
}

export const getColorsForInvestors = (
  investors: FundPortfolioInvestor[]
): ChartColors => {
  const investorsToShow = orderBy(
    investors,
    ['investorCapitalCalled'],
    ['desc']
  )

  const result: ChartColors = investorsToShow.reduce((res, investor, index) => {
    res[investor?.id] = getChartColor(index)
    return res
  }, {})

  result.other = OTHER_COMPANIES_COLOR
  return result
}

export const groupPortfoliosByGroupId = (portfolios) =>
  groupBy(portfolios, (port) => port.group.id)

export const refetchPortfolio = ({ portfolioId, queryClient }) => {
  return queryClient.refetchQueries(['portfolio', portfolioId], { exact: true })
}

export const getPortfolioRoute = (
  handleDispatchUserRoutes?: ({
    route,
    initialLinkRedirect,
  }: SetRouteConfigurationPayload) => void,
  type?: PortfolioType,
  id?: string
) => {
  if (!type || !id) {
    return ''
  }

  const lowerType = type.toLowerCase()

  handleDispatchUserRoutes?.({
    route: FundManagerRoutes.INVESTMENTS,
    initialLinkRedirect: `/investments/${lowerType}/${id}/portfolio`,
  })

  return `/portfolios/${lowerType}/${id}/portfolio`
}

export const getPortfolioTypeFromUrlType = (
  portfolioUrlType: string
): PortfolioType => {
  switch (portfolioUrlType) {
    case 'track':
      return PortfolioTypes.TRACK
    case 'invest':
      return PortfolioTypes.INVEST
    case 'fund':
      return PortfolioTypes.FUND
    case 'deal':
      return PortfolioTypes.DEAL
    default:
      return PortfolioTypes.TRACK
  }
}

export const getFilesSummaryChartData = (
  chart: [string, FileCounts][]
): Array<{ name: string } & FileCounts> => {
  const transformedChartData = chart.map((chartData) => {
    const [key, values] = chartData
    const date = dayjs(key, { format: 'YYYY-MM' }).format('MMM YYYY')
    return {
      name: date,
      ...values,
    }
  })

  const getEmptyChartData = (month: string) => ({
    name: month,
    totalPdfs: 0,
    totalImages: 0,
    totalDocuments: 0,
    totalSpreadsheets: 0,
    totalPresentations: 0,
    totalAudios: 0,
    totalEmailContents: 0,
    totalVideos: 0,
    totalCompressedFiles: 0,
    totalOtherTypes: 0,
    totalFiles: 0,
  })

  const currentDate = dayjs()
  const lastSixMonths = Array.from({ length: 6 }, (__, index) =>
    currentDate.subtract(index, 'month').startOf('month').format('MMM YYYY')
  )
  const lastSixMonthsChartData = lastSixMonths.map((monthAndYear) => {
    const existingEntry = transformedChartData.find(
      (entry) => entry.name === monthAndYear
    )

    return existingEntry || getEmptyChartData(monthAndYear)
  })

  const sortedChartData = _.orderBy(
    lastSixMonthsChartData,
    [(data) => dayjs(data.name, 'MMM YYYY').valueOf()],
    ['asc']
  )

  return sortedChartData
}

export const getUpdatesSummaryChartData = (
  chart: [string, UpdateCounts][]
): UpdateSummaryChartData => {
  const transformedChartData = chart.map((chartData) => {
    const [key, values] = chartData
    const { totalUpdates, ...newValues } = values

    const date = dayjs(key, { format: 'YYYY-MM' }).format('MMM YYYY')
    return {
      name: date,
      ...newValues,
    }
  })

  const getEmptyChartData = (month: string) => ({
    name: month,
    totalAnnouncements: 0,
    totalCreatedUpdates: 0,
    totalDocuments: 0,
    totalDraftUpdates: 0,
    totalEmails: 0,
    totalNotes: 0,
    totalSuggestedUpdates: 0,
    totalUpdates: 0,
  })

  const currentDate = dayjs()
  const lastSixMonths = Array.from({ length: 6 }, (__, index) =>
    currentDate.subtract(index, 'month').startOf('month').format('MMM YYYY')
  )
  const lastSixMonthsChartData = lastSixMonths.map((monthAndYear) => {
    const existingEntry = transformedChartData.find(
      (entry) => entry.name === monthAndYear
    )
    return existingEntry || getEmptyChartData(monthAndYear)
  })

  const sortedChartData = _.orderBy(
    lastSixMonthsChartData,
    [(data) => dayjs(data.name, 'MMM YYYY').valueOf()],
    ['asc']
  )

  return sortedChartData
}
