export const PROFILE = '/profile'
export const PROFILE_EDIT = '/profile/edit'

export const FUND_HOLDINGS_FUNDS_UPDATES =
  '/fund-holdings/funds/:fundId/updates'

export const INVESTMENT_VEHICLES = '/investment-vehicles/:investmentVehicleId'

export const INVESTMENT_VEHICLES_EDIT =
  '/investment-vehicles/:investmentVehicleId/edit'

export const FUND_HOLDINGS_FUNDS_INVESTMENTS =
  '/fund-holdings/funds/:fundId/investments'

export const FUND_HOLDINGS_COMPANIES_UPDATES =
  '/fund-holdings/companies/:companyId/updates'

export const FUND_HOLDINGS_COMPANIES_INVESTMENTS =
  '/fund-holdings/companies/:companyId/investments'

export const PREFIX_FUND_HOLDINGS_COMPANIES = '/fund-holdings/companies'
export const PREFIX_FUND_HOLDINGS_FUNDS = '/fund-holdings/funds'

const INVESTOR_GROUPS_ROUTES = [
  PROFILE,
  PROFILE_EDIT,
  FUND_HOLDINGS_FUNDS_UPDATES,
  FUND_HOLDINGS_COMPANIES_UPDATES,
  FUND_HOLDINGS_FUNDS_INVESTMENTS,
  FUND_HOLDINGS_COMPANIES_INVESTMENTS,
  INVESTMENT_VEHICLES,
  INVESTMENT_VEHICLES_EDIT,
]

export default INVESTOR_GROUPS_ROUTES
