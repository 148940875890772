import styled from 'styled-components'
import CardToStyle from 'components/Card'
import ButtonToStyle from 'ui/Button'
import { color } from 'utils/functions/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { maxSize } from 'utils/constants/breakpoint'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
  align-items: center;
  min-height: 4rem;

  @media ${maxSize.xl} {
    gap: 1.4rem;
  }
`

export const UpdateTitleContainer = styled.div`
  flex: 1;
  color: ${color('darkBlue')};
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
`

export const NavigationArrow = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  margin-right: 0.8rem;
  cursor: pointer;
`

export const VerticalSeparator = styled.div`
  background-color: ${color('veryLightGray')};
  height: 4.5rem;
  width: 0.1rem;
`

export const Button = styled(ButtonToStyle)`
  display: flex;
  height: fit-content;
  align-items: center;
  line-height: 1;
  padding: 0.8rem 1.2rem;
  border: 0.1rem solid transparent;
  svg {
    font-size: 1.4rem;
  }
`

export const Card = styled(CardToStyle)`
  padding: 1.8rem 2.8rem;

  @media ${maxSize.xl} {
    padding: 1.8rem 2.4rem;
  }
`

export const GlobeContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${color('veryLightBlue')};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Globe = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  color: ${color('white')};
  height: 1.65rem;
  width: 1.45rem;
`

export const CheckBoxContainer = styled.div`
  margin-top: 1.6rem;
`

export const OfflineMask = styled.div`
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  background-color: ${color('white')};
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
`
