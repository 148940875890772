export const TERMS_OF_USE_LINK = 'https://www.clockwork.app/terms-of-use/'

export const FOUNDER_UPDATES =
  'https://support.cwuniverse.app/en/articles/5647999-using-custom-layouts-with-xero-reports'

export const PRIVACY_POLICY_LINK = 'https://www.clockwork.app/privacy-policy/'

export const HOME_PAGE_LINK = 'https://www.clockwork.app/'

export const HELP_PAGE_LINK = 'https://support.cwuniverse.app/en/'

export const STATUS_PAGE_LINK = 'https://status.clockwork.app/'

export const INVESTORS_PAGE_LINK =
  'https://www.clockwork.app/for-individual-investors'

export const PRICING_LINK = 'https://www.clockwork.app/pricing'

export const GLOSSARY_LINK =
  'https://support.cwuniverse.app/en/articles/8337163-universe-glossary'

export const CHAT_LINK =
  'https://support.cwuniverse.app/en/articles/8448651-introduction-to-the-clockwork-universe-chat'

export const FILES_PAGE_LINK =
  'https://support.cwuniverse.app/en/articles/8466631-how-to-uploading-files-to-your-universe-portal'

export const UPDATES_HELP_LINK =
  'https://support.cwuniverse.app/en/collections/2568773-updates'
