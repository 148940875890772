import styled from 'styled-components/macro'
import { css } from 'styled-components'
import Tooltip from 'components/Tooltip/Tooltip'
import { color, hexToRGB } from 'utils/functions/colors'

export const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  && input {
    text-align: center;
    height: auto;
  }
`

const NumberCell = styled.div<{ centered?: boolean }>`
  padding-left: 0.4rem;
  width: 100%;

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
      padding-left: 0;
    `}
`

export const NumberCellPlaceholder = styled(NumberCell)`
  color: ${color('lightGray', 0.5)};
`

export const NumberCellValue = styled(NumberCell)<{ hasError?: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${color('darkGray')};

  ${({ centered }) =>
    centered &&
    css`
      justify-content: center;
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${color('red')};
    `}
`

export const NumberCellInputContainer = styled.div<{ centered?: boolean }>`
  width: 100%;
  padding-left: 0.4rem;

  input {
    text-align: left;

    ${({ centered }) =>
      centered &&
      css`
        text-align: center;
      `}
  }
`

export const WarningIconTooltip = styled(Tooltip)`
  position: absolute;
  right: 1rem;
  font-size: 1.3rem;
`

export const DisabledCell = styled(CellContainer)<{ centered?: boolean }>`
  color: ${color('veryLightGray')};
  cursor: not-allowed;

  ${({ centered }) =>
    !centered &&
    css`
      justify-content: flex-start;
      padding-left: 0.8rem;
    `}
`

export const RowNumberCellContainer = styled(CellContainer)<{
  color?: string
}>`
  height: 100%;
  background-color: ${color('veryLightGray')};
  color: ${color('lightGray')};
  justify-content: start;
  padding-left: 1rem;

  ${(props) =>
    props.color &&
    css`
      padding: 0;
      background-color: ${hexToRGB(props.color, 0.1)};
    `}
`
export const FullCellTooltip = styled(Tooltip)`
  width: 100%;
  height: 100%;
`

export const TextCellValue = styled.div<{
  hasError?: boolean
  autoFilled?: boolean
  isPlaceholder: boolean
  isHeaderCell?: boolean
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${({ isHeaderCell }) => (isHeaderCell ? '0 0.8rem' : '0 1rem')};
  color: ${color('darkGray')};

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${color('red')};
    `}

  ${({ autoFilled }) =>
    autoFilled &&
    css`
      background-color: ${color('lightGray', 0.1)};
      color: ${color('lightGray')};
      border-left: 1px solid ${color('white')};
    `}

    ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${color('lightGray', 0.5)};
    `}
`
