import { FormattedMessage } from 'react-intl'
import { Channel } from 'stream-chat-react'

import CWLoader from 'components/CWLoader'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { ChatBreakpointSize } from 'utils/constants/chat'
import { useIsThreadOpen } from 'containers/Chat/hooks'

import {
  Container as ChatHeaderContainer,
  LeftContainer as ChatHeaderLeftContainer,
} from '../ChatHeader/ChatHeader.styles'
import ChannelDropdown from '../ChannelDropdown'
import { ChannelList, Container } from '../TopicSidebar/TopicSidebar.styles'
import { useArchivedChannelsSidebar } from './useArchivedChannelsSidebar'
import ArchivedChannel from './components/ArchivedChannel/ArchivedChannel'
import { MobileNavigationArrow } from '../ChatMobile/ChatMobile.styles'
import { useChatMobileContext } from '../ChatMobile'

interface Props {
  preventSelectArchivedChannelOnQuery?: boolean
}

const ArchivedChannels = ({ preventSelectArchivedChannelOnQuery }: Props) => {
  const { isLoading, channels } = useArchivedChannelsSidebar(
    preventSelectArchivedChannelOnQuery
  )
  const { matches: showChannelDropdown } = useMediaQuery(
    ChatBreakpointSize.tablet
  )
  const { onGoToSidebar } = useChatMobileContext()
  const isThreadOpen = useIsThreadOpen()

  const renderChannels = () => {
    if (showChannelDropdown) {
      return <ChannelDropdown channels={channels} Channel={ArchivedChannel} />
    }

    return channels.map((channel) => {
      return (
        <Channel key={channel.id} channel={channel}>
          <ArchivedChannel channel={channel} />
        </Channel>
      )
    })
  }

  return (
    <Container>
      <ChatHeaderContainer preventResponsive isThreadOpen={isThreadOpen}>
        <ChatHeaderLeftContainer>
          <MobileNavigationArrow
            onClick={onGoToSidebar}
            icon={['fal', 'chevron-down']}
          />
          <FormattedMessage id="chat.archived" />
        </ChatHeaderLeftContainer>
      </ChatHeaderContainer>
      <ChannelList>{isLoading ? <CWLoader /> : renderChannels()}</ChannelList>
    </Container>
  )
}

export default ArchivedChannels
