import { useIntl } from 'react-intl'

import Separator from 'ui/Separator'
import { buildFormError } from 'utils/functions/forms'
import Title from 'components/Title'
import URLInput from 'ui/URLInput'
import { AddInvestorLinksFormValues } from 'utils/types/investors'
import crunchbaseLogo from 'assets/images/crunchbaselogo.svg'
import xTwitterLogo from 'assets/images/x-twitter.svg'
import { useFormikContext } from 'components/Form/hooks'
import { ErrorType } from 'utils/types/common'

import { SelectorWrapper, Image, Icon } from './InvestorForm.styles'

const AddInvestorLinksForm = () => {
  const { values, errors, touched, handleChange, handleBlur, getFieldName } =
    useFormikContext<AddInvestorLinksFormValues>()

  const intl = useIntl()

  return (
    <div>
      <Title
        title={intl.formatMessage({
          id: 'investorManagement.directories',
        })}
      />

      <Separator />

      <SelectorWrapper>
        <URLInput
          id="crunchbaseUrl"
          name={getFieldName('crunchbaseUrl')}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.crunchbaseUrl}
          label={intl.formatMessage({ id: 'editCompany.crunchbase' })}
          placeholder={intl.formatMessage({
            id: 'editCompany.companyCrunchbasePlaceholder',
          })}
          error={buildFormError(
            errors.crunchbaseUrl,
            ErrorType.ERROR,
            touched.crunchbaseUrl
          )}
          prefix={<Image src={crunchbaseLogo} alt="crunchbase" />}
        />
      </SelectorWrapper>

      <Separator />

      <Title
        title={intl.formatMessage({
          id: 'investorManagement.socialMedia',
        })}
      />

      <Separator />

      <SelectorWrapper>
        <URLInput
          id="linkedinUrl"
          name={getFieldName('linkedinUrl')}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.linkedinUrl}
          label={intl.formatMessage({ id: 'editCompany.linkedin' })}
          placeholder={intl.formatMessage({
            id: 'editCompany.companyLinkedinPlaceholder',
          })}
          error={buildFormError(
            errors.linkedinUrl,
            ErrorType.ERROR,
            touched.linkedinUrl
          )}
          prefix={<Icon icon={['fab', 'linkedin-in']} />}
        />
      </SelectorWrapper>

      <Separator />

      <SelectorWrapper>
        <URLInput
          id="twitterUrl"
          name={getFieldName('twitterUrl')}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.twitterUrl}
          label={intl.formatMessage({
            id: 'investorManagement.twitterProfile',
          })}
          placeholder={intl.formatMessage({
            id: 'investorManagement.twitterProfilePlaceholder',
          })}
          error={buildFormError(
            errors.twitterUrl,
            ErrorType.ERROR,
            touched.twitterUrl
          )}
          prefix={
            <Image
              src={xTwitterLogo}
              alt="x twitter"
              style={{
                width: '1.6rem',
                height: '1.6rem',
                marginTop: '3px',
              }}
            />
          }
        />
      </SelectorWrapper>
    </div>
  )
}

export default AddInvestorLinksForm
