import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonToStyle from 'ui/Button'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { Legend } from '../../Signup.styles'

export const Button = styled(ButtonToStyle)`
  padding: 1.6rem 3.6rem;
  width: fit-content;
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const AlmostThereContainer = styled(StepContainer)`
  max-width: 76.6rem;
  margin: 0 auto;

  h2 {
    padding: 0 2rem;
    white-space: pre-wrap;
    text-align: center;
  }
`

export const SetupAccountContainer = styled(StepContainer)<{
  withTopPadding?: boolean
}>`
  padding: ${({ withTopPadding }) => (withTopPadding ? '4.5rem' : 0)} 2.4rem
    6rem 2.4rem;

  @media ${size.sm} {
    padding: ${({ withTopPadding }) => (withTopPadding ? '4.5rem' : 0)} 0 6rem 0;
    margin: 0 auto;
    width: fit-content;
  }
`

export const PaymentContainer = styled(StepContainer)`
  padding: 0 2.4rem 6rem 2.4rem;
  width: fit-content;
  margin: 0 auto;
`

export const BillingAccountContainer = styled(StepContainer)`
  padding: 0 0 6rem 0;
  max-width: 55.7rem;
  margin: 0 auto;

  @media ${maxSize.sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
`

export const AlmostThereLegendContainer = styled.div`
  display: flex;
  gap: 0.6rem;
  color: ${color('lightGray')};

  @media ${maxSize.sm} {
    padding: 0 2rem;
  }
`

export const InfoIcon = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
`

export const ButtonLink = styled.button`
  background: transparent;
  color: ${color('primaryBlue')};
  font-weight: 700;
  text-decoration: underline;
`

export const AlmostThereLegend = styled(Legend)`
  font-size: 1.2rem;
  line-height: normal;
  color: ${color('lightGray')};
`
