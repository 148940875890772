/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import styles from './Toggle.module.scss'

interface ToggleProps {
  onChangeStatus?: (status: boolean, stepId: string) => void
  id: string
  name: string
  blue?: boolean
  legend?: string
  status: boolean
  danger?: boolean
  disabled?: boolean
  disableAnimationOnMount?: boolean
  color?: string
  inactiveColor?: string
  className?: string
  customLegendStyles?: React.CSSProperties
}

const Toggle = React.forwardRef<HTMLInputElement, ToggleProps>(
  (
    {
      name,
      onChangeStatus,
      blue,
      legend,
      status: statusProp,
      id,
      danger,
      disabled,
      disableAnimationOnMount,
      color,
      inactiveColor,
      className,
      customLegendStyles,
    },
    ref
  ) => {
    const [status, setStatus] = useState(false)

    const handleToggleClick = () => {
      onChangeStatus?.(!status, id)
      setStatus(!status)
    }

    useEffect(() => {
      setStatus(statusProp)
    }, [statusProp])

    const [animationEnabled, setAnimationEnabled] = useState(
      !disableAnimationOnMount
    )

    useEffect(() => {
      if (disableAnimationOnMount) {
        setTimeout(() => {
          setAnimationEnabled(true)
        }, 300)
      }
    }, [disableAnimationOnMount])

    const getToggleStyles = () => {
      if (status) {
        if (color) {
          return { backgroundColor: color }
        }
      } else if (inactiveColor) {
        return { backgroundColor: inactiveColor }
      }
      return {}
    }

    return (
      <div
        className={classNames(
          styles.toggleContainer,
          {
            [styles.disabled]: disabled,
            [styles.animationDisabled]: !animationEnabled,
          },
          className
        )}
        data-testid={`toggle-container-${id}`}
      >
        <label className={styles.toggleLabel} htmlFor={id}>
          <input
            id={id}
            data-testid={id}
            ref={ref}
            name={name}
            checked={status}
            className={classNames(styles.toggleInput, {
              [styles.danger]: danger,
              [styles.blue]: blue,
            })}
            type="checkbox"
            readOnly
          />
          <div
            data-testid={`toggle-${id}`}
            aria-hidden
            className={`${styles.toggleWrapper} toggle-element`}
            onClick={handleToggleClick}
            style={getToggleStyles()}
          >
            <div className={`${styles.toggleCircle} toggle-element`} />
          </div>
        </label>

        {legend && (
          <p
            onClick={handleToggleClick}
            className={styles.legend}
            style={customLegendStyles}
          >
            {legend}
          </p>
        )}
      </div>
    )
  }
)

Toggle.defaultProps = {
  disableAnimationOnMount: false,
  disabled: false,
  danger: false,
  blue: false,
  legend: '',
  color: '',
  className: '',
}

export default Toggle
