import styled from 'styled-components'
import { SIDE_BAR_HEIGHT } from 'layouts/SideBarComponents/SideBar'
import { maxSize } from 'utils/constants/breakpoint'
import { MAC_DESKTOP_HEADER_HEIGHT } from 'utils/constants/electron'

export const LayoutContainer = styled.div`
  width: 100%;
  display: flex;
  position: fixed;
`

export const MainLayoutContainer = styled.div<{
  position?: string
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-gray-200);
  height: calc(100vh - ${MAC_DESKTOP_HEADER_HEIGHT});
  overflow: auto;
  overflow: overlay;
  position: ${(props) => props.position || 'relative'};

  @media ${maxSize.mobile} {
    margin-top: 7.2rem;
    height: calc(
      var(--viewHeight, 100vh) - ${SIDE_BAR_HEIGHT} -
        ${MAC_DESKTOP_HEADER_HEIGHT}
    );
  }
`

export const FooterContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${maxSize.mobile} {
    padding-top: 1rem;
  }
`
