import React from 'react'
import { Formik } from 'formik'
import type { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import Drawer from 'components/Drawer'
import FadeIn from 'components/FadeIn/FadeIn'
import { getInvestorAddOtherInfoSchema } from 'utils/schemas/investorManagement'
import {
  AddInvestorOtherInfoFormValues,
  InvestorType,
} from 'utils/types/investors'
import AddInvestorOtherInfoForm from 'containers/InvestorManagement/InvestorForm/AddInvestorOtherInfoForm'
import AddInvestorLinksForm from 'containers/InvestorManagement/InvestorForm/AddInvestorLinksForm'
import TeamMembersForm from 'containers/InvestorManagement/InvestorForm/CompanyTeamForm'

interface AddOtherInfoProps {
  initialValues: AddInvestorOtherInfoFormValues
  onSubmit: (values: AddInvestorOtherInfoFormValues) => void
  onCancel: () => void
  investorType?: InvestorType
}

const AddOtherInfo: React.ForwardRefRenderFunction<
  FormikProps<AddInvestorOtherInfoFormValues>,
  AddOtherInfoProps
> = ({ initialValues, onSubmit, onCancel, investorType }, ref) => {
  const intl = useIntl()

  return (
    <FadeIn>
      <Formik<AddInvestorOtherInfoFormValues>
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={getInvestorAddOtherInfoSchema(intl)}
      >
        {(formik) => (
          <>
            <AddInvestorLinksForm />
            <Separator />
            <TeamMembersForm />
            <Separator />
            <AddInvestorOtherInfoForm investorType={investorType} />
            <Drawer.Footer>
              <Button onClick={onCancel}>
                {intl.formatMessage({ id: 'general.cancel' })}
              </Button>
              <Button
                type="submit"
                primary
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}
              >
                {intl.formatMessage({ id: 'general.next' })}
              </Button>
            </Drawer.Footer>
          </>
        )}
      </Formik>
    </FadeIn>
  )
}

export default React.forwardRef(AddOtherInfo)
