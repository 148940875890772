import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { UpdateFilterOption } from './CompanyUpdates/UpdatesFilterOptions'

export const useGetFilterOptions = () => {
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const filterOptionsToShow = isInvestorGroup
    ? [UpdateFilterOption.TYPE]
    : undefined

  return filterOptionsToShow
}
