import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import ButtonToStyle from 'ui/Button'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

export const Launching = styled.h1`
  font-size: 3.2rem;
  font-weight: 700;
  margin: 2.4rem 0 1.4rem 0;
  color: ${color('darkBlue')};
`

export const Line = styled.p`
  font-size: 2.4rem;
  color: ${color('darkGray')};
`
export const Line2 = styled(Line)`
  font-size: 1.6rem;
  color: ${color('darkGray')};
  margin-top: 0.7rem;
`

export const Link = styled(ButtonToStyle)`
  padding: 0 0 0.4rem 0.1rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: ${color('darkGray')};

  && {
    text-decoration: underline;
    border: none;
  }

  &&:focus,
  &&:active,
  &&:hover {
    outline: none;
    background: transparent;
    box-shadow: initial;
  }

  &&:focus:not(:active) {
    border-color: transparent;
  }
`
