import React from 'react'

import { Cell, HoverColumn } from './Row.styles'
import { Column, DataType, RowContextMenuProps } from '../types'

type MemoizedRowProps<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType
> = {
  cells: Column<RowDataType>[]
  rowData: RowDataType
  embeddedTable: boolean
  parentRowData: ExpandedRowDataType
  parentRowIndex: number
  rowIndex: number
  rowContextMenuOpen: boolean
  rowContextMenuPosition?: { x: number; y: number }
  onCloseRowContextMenu: () => void
  onOpenRowContextMenu: () => void
  rowContextMenu?: (params: RowContextMenuProps<RowDataType>) => React.ReactNode
  isFixedList: boolean
}

function MemoizedRow<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType
>({
  cells,
  rowData,
  embeddedTable,
  parentRowData,
  parentRowIndex,
  rowIndex,
  rowContextMenuOpen,
  rowContextMenuPosition,
  rowContextMenu,
  onCloseRowContextMenu,
  onOpenRowContextMenu,
  isFixedList,
}: MemoizedRowProps<RowDataType, ExpandedRowDataType>) {
  return (
    <>
      {cells.map((col) => (
        <Cell key={col.id} column={col} style={col.style}>
          {col.cellRenderer({
            rowData,
            embeddedTable: !!embeddedTable,
            parentRowData,
            parentRowIndex,
            rowIndex,
          })}
        </Cell>
      ))}
      {rowContextMenu && (
        <Cell
          key={`hoverColumn_${rowData.id}`}
          style={{
            position: 'sticky',
            right: '0',
            display: isFixedList ? 'none' : undefined,
          }}
          column={{
            minWidth: 45,
            flex: 0,
          }}
        >
          <HoverColumn id={`hoverColumn_${rowData.id}`}>
            {rowContextMenu({
              rowData,
              rowIndex,
              isOpen: rowContextMenuOpen,
              position: rowContextMenuPosition,
              onClose: onCloseRowContextMenu,
              onOpen: onOpenRowContextMenu,
            })}
          </HoverColumn>
        </Cell>
      )}
    </>
  )
}

export default React.memo(MemoizedRow)
