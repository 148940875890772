import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as LoaderSVG } from 'assets/images/barsLoader.svg'

import styles from './Loader.module.scss'

const Loader = ({ text, legend, loader }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {loader || <LoaderSVG width="4rem" height="4rem" />}
          <p className={styles.text}>{text}</p>

          {legend && <p className={styles.legend}>{legend}</p>}
        </div>
      </div>
    </div>
  )
}

Loader.defaultProps = {
  text: '',
  legend: '',
  loader: null,
}

Loader.propTypes = {
  text: PropTypes.string,
  legend: PropTypes.string,
  loader: PropTypes.node,
}

export default Loader
