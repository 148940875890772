import React from 'react'
import { FormattedMessage } from 'react-intl'

import { AccessType } from 'utils/types/updateForm'
import Tooltip from 'components/Tooltip'
import { UpdateType } from 'utils/types/common'
import { capitalizedUpdateType } from 'utils/functions/updates'

import AccessDropdown from './AccessDropdown'
import {
  getImageComponent,
  IconsTypes,
} from '../ShareWithDropdown/components/Icons'
import * as Styles from './ShareWithEntities.styles'

interface YourGroupProps {
  name: string
  imageUrl?: string
  access: AccessType
  onAccessChange: (access: AccessType) => void
  isGroupOwned: boolean
  disableEditGroupPermissions: boolean
  updateType?: UpdateType
}

const YourGroup: React.FC<YourGroupProps> = ({
  name,
  imageUrl,
  access,
  onAccessChange,
  isGroupOwned,
  disableEditGroupPermissions,
  updateType,
}) => {
  const renderDropdown = () => (
    <AccessDropdown
      access={isGroupOwned ? AccessType.CAN_EDIT : access}
      onAccessChange={onAccessChange}
      disabled={isGroupOwned || disableEditGroupPermissions}
    />
  )

  return (
    <Styles.Container data-testid="your-group">
      <Styles.NameContainer>
        <div>{getImageComponent(IconsTypes.avatar, name, imageUrl)}</div>
        <div>
          {name}
          <Styles.UserDetailText>
            (<FormattedMessage id="updates.yourGroup" />)
          </Styles.UserDetailText>
        </div>
      </Styles.NameContainer>

      {isGroupOwned ? (
        <Tooltip
          id="group-owned-transaction"
          text={
            <FormattedMessage
              id="updates.groupOwnedUpdate"
              values={{
                updateType: `${capitalizedUpdateType({ updateType })}s`,
              }}
            />
          }
          place="left"
          tooltipStyle={{
            width: '22rem',
            fontWeight: 'bold',
          }}
          delayShow={500}
          arrowColor="transparent"
        >
          {renderDropdown()}
        </Tooltip>
      ) : (
        renderDropdown()
      )}
    </Styles.Container>
  )
}

export default YourGroup
