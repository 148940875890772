import styled from 'styled-components/macro'
import { css } from 'styled-components'
import Button from 'ui/Button'
import NavMenu from 'components/NavMenu'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem 2.4rem;
  border-top: 0.1rem solid ${color('borderGray')};
  align-items: center;

  button:first-child {
    margin-right: 0.8rem;
  }
`

export const IconsContainer = styled.div`
  display: flex;

  svg + svg {
    margin-left: 0.8rem;
  }
`

export const NameContainer = styled.div<{
  italic?: boolean
  isActive?: boolean
}>`
  word-break: break-word;

  ${({ italic, isActive }) =>
    !!italic &&
    css`
      font-style: italic;
      color: ${isActive ? 'white' : color('lightGray')};
    `}
`

export const DeleteButton = styled(Button)`
  background: white;
  color: ${color('red')};
  border: 0.1rem solid ${color('veryLightBlue')};

  &:hover:not(:disabled) {
    border: 0.1rem solid ${color('veryLightBlue')};
    background-color: ${color('softRed', 0.9)};
  }
`

export const NavMenuContent = styled(NavMenu.Content)`
  max-height: 70vh;
  overflow-y: auto;
  padding: 2.4rem;
`

export const ErrorMessage = styled.div`
  display: flex;
  border-radius: 0.6rem;
  border: solid 1px #fcbe2c;
  background-color: rgba(252, 190, 44, 0.05);
  padding: 1.2rem;
  margin-bottom: 2rem;

  svg[data-icon='exclamation-triangle'] {
    font-size: 3em;
    color: #b57f00;
    margin-right: 1rem;
  }
`

export const ErrorTitle = styled.div`
  color: #b57f00;
  font-size: 1.4rem;
  font-weight: 600;
`

export const ErrorSubTitle = styled.p`
  color: #b57f00;
  font-weight: 400;
  padding-top: 0.4rem;
`

export const InfoMessage = styled.div`
  font-size: 1.2rem;
  color: ${color('lightGray')};
  display: flex;
  white-space: break-spaces;

  > svg {
    margin-right: 0.625rem;
    font-size: 1.5rem;
  }
`

export const InfoMessageText = styled.span`
  height: fit-content;
`
