import { getFileExtension, isPreviewAvailable } from 'utils/functions/files'
import { FileContent, FileExtension } from 'utils/types/files'

import ImagePreview from '../ImagePreview'
import NoPreviewAvailable from '../NoPreviewAvailable'
import PDFPreview from '../PDFPreview'

interface PreviewFileProps {
  file: FileContent
  onDownloadFile: (file: FileContent) => void
  noPreviewAvailable?: React.ReactElement
  errorComponent?: React.ReactElement
  displayPDFControls?: boolean
}

const PreviewFile = ({
  file,
  onDownloadFile,
  noPreviewAvailable,
  errorComponent,
  displayPDFControls,
}: PreviewFileProps) => {
  const type = getFileExtension(file.fileFormat)?.toLowerCase()

  const renderNoPreviewAvailable = () => {
    return (
      noPreviewAvailable || (
        <NoPreviewAvailable file={file} onDownloadFile={onDownloadFile} />
      )
    )
  }

  if (!isPreviewAvailable(file)) {
    return renderNoPreviewAvailable()
  }

  switch (type) {
    case FileExtension.IMAGE:
      return <ImagePreview file={file} errorComponent={errorComponent} />
    case FileExtension.PDF:
      return (
        <PDFPreview
          file={file}
          displayControls={displayPDFControls}
          errorComponent={errorComponent}
        />
      )
    default:
      return renderNoPreviewAvailable()
  }
}

export default PreviewFile
