import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import { useUpdatesFeedContext } from 'components/UpdatesFeedV2/UpdateFeedContext'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'
import { IndexUpdate } from 'utils/types/update'
import { emails } from 'utils/constants/emails'
import { LinkButton } from '../UpdateFactory.styles'
import { UpgradeAccountTooltipText } from './ReshareIcons.styles'

interface ReshareIconsProps {
  update: IndexUpdate
  onClick?: () => void
}

const ReshareIcons: React.FC<ReshareIconsProps> = ({ update, onClick }) => {
  const intl = useIntl()
  const updatePermissions = useUpdatePermissions(update)
  const { reshareDisabled } = useUpdatesFeedContext()

  const getReshareMessage = () => {
    return (
      <UpgradeAccountTooltipText>
        {intl.formatMessage(
          { id: 'readOnlyMode.reshare' },
          {
            upgradeYourPlan: (
              <a
                href={emails.MAILTO_UPGRADE}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'readOnlyMode.upgradeYourPlan',
                })}
              </a>
            ),
            here: (
              <a
                href="https://clockwork.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'readOnlyMode.here',
                })}
              </a>
            ),
          }
        )}
      </UpgradeAccountTooltipText>
    )
  }

  return !reshareDisabled && updatePermissions.canReshare ? (
    <ReadOnlyTooltip
      id={`read_only_tooltip_${update.id}`}
      place="right"
      text={getReshareMessage()}
      disabled={undefined}
      delayHide={300}
      clickable
    >
      {({ disabled }) => (
        <LinkButton onClick={onClick} disabled={disabled}>
          <FormattedMessage id="updates.reshareUpdate" />
        </LinkButton>
      )}
    </ReadOnlyTooltip>
  ) : null
}

export default ReshareIcons
