export const DefaultCompressed = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="file-archive 1" clipPath="url(#clip0_7690_533780)">
      <path
        id="Vector"
        d="M5.00703 4.71572V5.46572H5.75703V4.71572H5.00703ZM6.50703 2.46572H5.75703V3.21572H6.50703V2.46572ZM5.00703 3.21572V3.96572H5.75703V3.21572H5.00703ZM6.50703 3.96572H5.75703V4.71572H6.50703V3.96572ZM10.6695 3.26025L8.70312 1.29385C8.49219 1.08291 8.20625 0.963379 7.90859 0.963379H3.125C2.50391 0.965723 2 1.46963 2 2.09072V11.8407C2 12.4618 2.50391 12.9657 3.125 12.9657H9.875C10.4961 12.9657 11 12.4618 11 11.8407V4.05713C11 3.75947 10.8805 3.47119 10.6695 3.26025ZM8 2.18213L9.78359 3.96572H8V2.18213ZM9.875 11.8407H3.125V2.09072H4.99297V2.46572H5.74297V2.09072H6.875V4.52822C6.875 4.83994 7.12578 5.09072 7.4375 5.09072H9.875V11.8407ZM6.55156 7.19307C6.52578 7.06182 6.41094 6.96572 6.275 6.96572H5.75703V6.21572H5.00703V6.96572L4.54531 9.2415C4.39062 10.0032 4.97187 10.7157 5.75 10.7157C6.52578 10.7157 7.10703 10.0079 6.95703 9.24854L6.55156 7.19307ZM5.75703 10.1087C5.3375 10.1087 4.99766 9.8251 4.99766 9.47588C4.99766 9.12666 5.3375 8.84307 5.75703 8.84307C6.17656 8.84307 6.51641 9.12666 6.51641 9.47588C6.51641 9.8251 6.17656 10.1087 5.75703 10.1087ZM6.50703 5.46572H5.75703V6.21572H6.50703V5.46572Z"
        fill="#313748"
      />
    </g>
    <defs>
      <clipPath id="clip0_7690_533780">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.965576)"
        />
      </clipPath>
    </defs>
  </svg>
)
