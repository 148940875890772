import { useState } from 'react'
import Drawer from 'components/Drawer/Drawer'
import List from 'components/List'
import { FormattedMessage, useIntl } from 'react-intl'
import Separator from 'ui/Separator'
import { useChatMembers } from 'utils/hooks/useChatMembers'
import ChatMemberRow from '../CreateChat/components/ChatMemberRow'
import AddParticipantsModal from '../AddParticipantsModal'
import { Button, ListStyles } from './ViewParticipants.styles'

type Props = {
  isOpen: boolean
  onClose: () => void
  members: { id: string; name: string; image?: string }[]
}

const ViewParticipants = ({ members, isOpen, onClose }: Props) => {
  const { isChannelModerator } = useChatMembers()
  const intl = useIntl()
  const [showAddParticipantsModal, setShowAddParticipantsModal] =
    useState(false)

  return (
    <Drawer
      isOpen={isOpen}
      onCloseDrawer={onClose}
      headerTitle={intl.formatMessage({ id: 'chat.chatDetails' })}
    >
      <Drawer.Content>
        <ListStyles />
        <Separator />

        {isChannelModerator && (
          <>
            <Button
              link
              icon={['far', 'plus']}
              onClick={() => setShowAddParticipantsModal(true)}
            >
              <FormattedMessage id="chat.addParticipants" />
            </Button>

            <Separator />
          </>
        )}

        <List
          elements={members.map((memberData) => (
            <ChatMemberRow
              key={memberData.id}
              image={memberData.image}
              name={memberData.name}
            />
          ))}
          keyExtractor={(member) => member.id}
          striped={false}
        />

        <AddParticipantsModal
          show={showAddParticipantsModal}
          onHide={() => setShowAddParticipantsModal(false)}
          currentMemberIds={members.map((user) => user.id)}
        />
      </Drawer.Content>
    </Drawer>
  )
}

export default ViewParticipants
