import React, { forwardRef } from 'react'
import UIInput from 'ui/Input'
import { useField } from 'components/Form/hooks'

interface InputProps {
  id: string
  name: string
  label: string
  title: string
  placeholder: string
  handleChange?: (value: any, shouldValidate?: boolean | undefined) => void
  [x: string]: any
}

const Input: React.FC<InputProps> = forwardRef(
  ({ name, handleChange, ...rest }: InputProps, ref) => {
    const [field] = useField(name)

    return (
      <UIInput
        ref={ref as React.Ref<HTMLInputElement>}
        value={field.value}
        name={field.name}
        error={field.buildError()}
        onChange={handleChange ?? field.onChange}
        onBlur={field.onBlur}
        {...rest}
      />
    )
  }
)

export default Input
