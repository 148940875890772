import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'

import Heading from 'ui/Heading'
import PhoneInput from 'ui/PhoneInput'
import CountryInput from 'ui/CountryInput'
import Separator from 'ui/Separator'
import { FormikInput } from 'components/Form'
import { getBillingAccountSchema } from 'utils/schemas/signup'
import { CountryCode } from 'utils/types/common'
import { useSignupContext } from 'containers/Signup/context/SignupContext'
import { ContinueButton } from 'containers/Signup/Signup.styles'

import {
  BillingInfo,
  FormWrapper,
  GridContainer,
  PostalAddressLink,
  PhoneAndInputContainer,
  LinkContainer,
} from './BillingAccount.styles'
import { BillingAccountContainer } from '../GetStarted.styles'

export interface BillingAccountFormValues {
  name: string
  email: string
  phone: string
  country: CountryCode
  hasPostalAddress: boolean
  trial: boolean
  address?: {
    street: string
    city: string
    state: string
    zip: string
  }
}

interface Props {
  onSubmit: (values: BillingAccountFormValues) => void
}

const BillingAccount = ({ onSubmit }: Props) => {
  const intl = useIntl()
  const {
    investorSignup: { name, email },
  } = useSignupContext()

  return (
    <Formik<Partial<BillingAccountFormValues>>
      initialValues={{
        country: 'US',
        hasPostalAddress: false,
        name,
        email,
        trial: true,
      }}
      validationSchema={getBillingAccountSchema(intl)}
      onSubmit={onSubmit}
      validateOnBlur
      validateOnMount
    >
      {({ values, setFieldValue, handleSubmit, isValid, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <BillingAccountContainer>
            <Heading level="h1">
              <FormattedMessage id="signup.billingAccount.title" />
            </Heading>

            <BillingInfo>
              <FormattedMessage id="signup.billingAccount.billingInfo" />
            </BillingInfo>

            <FormWrapper>
              <FormikInput
                name="name"
                intlLabel="signup.billingAccount.nameLabel"
                intlPlaceholder="signup.billingAccount.namePlaceholder"
              />
              <FormikInput
                name="email"
                intlLabel="signup.billingAccount.emailLabel"
                intlPlaceholder="signup.billingAccount.emailPlaceholder"
              />
              <PhoneAndInputContainer>
                <PhoneInput
                  name="phone"
                  intlLabel="signup.billingAccount.phone"
                  intlPlaceholder="signup.billingAccount.phonePlaceholder"
                />

                <CountryInput
                  name="country"
                  intlLabel="signup.billingAccount.country"
                />
              </PhoneAndInputContainer>
            </FormWrapper>

            <LinkContainer>
              <PostalAddressLink
                onClick={() =>
                  setFieldValue('hasPostalAddress', !values.hasPostalAddress)
                }
              >
                {values.hasPostalAddress ? (
                  <FormattedMessage id="signup.billingAccount.removePostalAddress" />
                ) : (
                  <FormattedMessage id="signup.billingAccount.addPostalAddress" />
                )}
              </PostalAddressLink>
            </LinkContainer>

            {values.hasPostalAddress && (
              <FormWrapper>
                <Separator height="0.8rem" />
                <GridContainer>
                  <FormikInput
                    name="address.street"
                    intlLabel="signup.billingAccount.street"
                    intlPlaceholder="signup.billingAccount.streetPlaceholder"
                  />
                </GridContainer>
                <GridContainer columns={2}>
                  <FormikInput
                    name="address.city"
                    intlLabel="signup.billingAccount.city"
                    intlPlaceholder="signup.billingAccount.cityPlaceholder"
                  />
                  <FormikInput
                    name="address.state"
                    intlLabel="signup.billingAccount.state"
                    intlPlaceholder="signup.billingAccount.statePlaceholder"
                  />
                </GridContainer>
                <GridContainer columns={2}>
                  <FormikInput
                    name="address.zip"
                    intlLabel="signup.billingAccount.zip"
                    intlPlaceholder="signup.billingAccount.zipPlaceholder"
                  />
                </GridContainer>
              </FormWrapper>
            )}

            <Separator height="3.2rem" />

            <ContinueButton
              id="signup-billing-account-continue-button"
              type="submit"
              primary
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
            >
              <FormattedMessage id="general.saveAndGoNext" />
            </ContinueButton>
          </BillingAccountContainer>
        </form>
      )}
    </Formik>
  )
}

export default BillingAccount
