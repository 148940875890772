import { useCallback, useMemo } from 'react'
import type { FormikErrors, FormikTouched } from 'formik'
import { useFormikContext as useFormik } from 'formik'
import { get, set } from 'lodash'

import { useForm } from './useForm'

export const useFormikContext = <T, S = any>() => {
  const { prefix } = useForm()
  const { setFieldValue, setFieldError, handleChange, ...formik } =
    useFormik<T>()
  const namePrefix = useMemo(() => prefix ?? '', [prefix])
  const namePrefixModifier = useMemo(
    () => (namePrefix ? namePrefix.concat('.') : ''),
    [namePrefix]
  )

  const values = useMemo<T>(
    () =>
      namePrefix ? (get(formik.values, namePrefix, {}) as T) : formik.values,
    [namePrefix, formik.values]
  )

  const errors = useMemo<FormikErrors<T>>(
    () => (namePrefix ? get(formik.errors, namePrefix, {}) : formik.errors),
    [namePrefix, formik.errors]
  )
  const touched = useMemo<FormikTouched<T>>(
    () => (namePrefix ? get(formik.touched, namePrefix, {}) : formik.touched),
    [namePrefix, formik.touched]
  )

  const status = useMemo<S | undefined>(
    () => (namePrefix ? get(formik.status, namePrefix, {}) : formik.status),
    [namePrefix, formik.status]
  )

  const setStatus = useCallback(
    (newStatusContext: S | undefined) => {
      const newStatus = { ...formik.status }
      set(newStatus, namePrefix, newStatusContext)
      formik.setStatus(newStatus)
    },
    [namePrefix, formik]
  )

  const setValue = useCallback(
    (field: string, value: any, shouldValidate?: boolean) => {
      const fieldModifier = field.includes(namePrefixModifier)
        ? field
        : `${namePrefixModifier}${field}`
      setFieldValue(fieldModifier, value, shouldValidate)
    },
    [setFieldValue, namePrefixModifier]
  )

  const setError = useCallback(
    (field: string, message: string | undefined) => {
      const fieldModifier = field.includes(namePrefixModifier)
        ? field
        : `${namePrefixModifier}${field}`
      setFieldError(fieldModifier, message)
    },
    [setFieldError, namePrefixModifier]
  )

  const onChange = useCallback(
    (e: React.ChangeEvent<any>) => {
      const name = e.target.name.includes(namePrefixModifier)
        ? e.target.name
        : `${namePrefixModifier}${e.target.name}`

      if (e.target.type === 'checkbox') {
        e.target.name = name
        handleChange(e)
      } else {
        handleChange({ ...e, target: { ...e.target, name } })
      }
    },
    [handleChange, namePrefixModifier]
  )

  const getFieldName = useCallback(
    (field: string) => `${namePrefixModifier}${field}`,
    [namePrefixModifier]
  )

  return {
    ...formik,
    setFieldValue: setValue,
    setFieldError: setError,
    handleChange: onChange,
    getFieldName,
    values,
    errors,
    touched,
    status,
    setStatus,
  }
}
