import { Optional } from 'utils/types/common'
import {
  CreateDraftUpdatePayload,
  EditDraftUpdateItemPayload,
  EditUpdateItemPayload,
} from 'utils/types/updatePayloads'
import { EditDraftUpdatePayloadParams, EditUpdatePayloadParams } from './types'
import { getEditDraftPayload, getEditUpdateItemPayload } from './updates'

export const getCreateNotePayload = (): CreateDraftUpdatePayload<'note'> => {
  return {
    note: {
      loggingUpdateAttributes: {
        draftHash: {},
      },
    },
  }
}

export const getEditNotePayload = (
  params: Optional<EditUpdatePayloadParams, 'body'>
): EditUpdateItemPayload => {
  return getEditUpdateItemPayload(params)
}

export const getEditDraftNotePayload = (
  params: EditDraftUpdatePayloadParams,
  suggestedUpdateId?: string
): EditDraftUpdateItemPayload => {
  return getEditDraftPayload(params, suggestedUpdateId)
}
