import React from 'react'
import { FormattedMessage } from 'react-intl'
import { GroupTypes } from 'utils/constants/groups'
import history from 'utils/history'
import { round } from './number'

export const UniversityActionType = {
  USER: 'USER',
  GROUP: 'GROUP',
}

export const IntercomProductTours = {
  ADD_PORTFOLIO: {
    INTERCOM_TOUR_ID: 262013,
    GUIDE_URL:
      'https://support.cwuniverse.app/en/articles/4979707-create-and-manage-track-portfolios',
  },
  FIND_COMPANIES: {
    INTERCOM_TOUR_ID: 262014,
    GUIDE_URL:
      'https://support.cwuniverse.app/en/articles/4753843-exploring-the-company-index',
  },
  CREATE_UPDATE: { INTERCOM_TOUR_ID: 262022 },
  MONITOR_INVESTMENTS: { INTERCOM_TOUR_ID: 262026 },
  TRACK_COMPANY_METRICS: { INTERCOM_TOUR_ID: 262029 },
  EXPLORE_SETTINGS: { INTERCOM_TOUR_ID: 262030 },
  NEED_HELP: { INTERCOM_TOUR_ID: 262033 },
}

export const UniversityAction = {
  ADD_PORTFOLIO: {
    id: 'create_portfolio',
    groupTypes: [GroupTypes.CLIENT],
    actionType: UniversityActionType.GROUP,
    guideUrl: IntercomProductTours.ADD_PORTFOLIO.GUIDE_URL,
    onStart: () => {
      history.push('/portfolios')
      window.Intercom(
        'startTour',
        IntercomProductTours.ADD_PORTFOLIO.INTERCOM_TOUR_ID
      )
    },
  },
  FIND_COMPANIES: {
    id: 'company_index_page',
    groupTypes: [GroupTypes.CLIENT],
    actionType: UniversityActionType.GROUP,
    enabledInReadOnly: true,
    onStart: () => {
      history.push('/companies')
      window.Intercom(
        'startTour',
        IntercomProductTours.FIND_COMPANIES.INTERCOM_TOUR_ID
      )
    },
  },
  CREATE_UPDATE: {
    id: 'create_update',
    groupTypes: [GroupTypes.CLIENT],
    actionType: UniversityActionType.GROUP,
    onStart: () => {
      history.push('/companies')
      window.Intercom(
        'startTour',
        IntercomProductTours.CREATE_UPDATE.INTERCOM_TOUR_ID
      )
    },
  },
  MONITOR_INVESTMENTS: {
    id: 'create_transaction_in_portfolio',
    groupTypes: [GroupTypes.CLIENT],
    actionType: UniversityActionType.GROUP,
    isDisabled: true,
    disabledText: (
      <FormattedMessage id="university.investorPortfolioRequired" />
    ),
    onStart: () => {
      history.push('/portfolios')
      window.Intercom(
        'startTour',
        IntercomProductTours.MONITOR_INVESTMENTS.INTERCOM_TOUR_ID
      )
    },
  },
  TRACK_COMPANY_METRICS: {
    id: 'create_metric',
    groupTypes: [GroupTypes.CLIENT],
    actionType: UniversityActionType.GROUP,
    onStart: () => {
      history.push('/metrics')
      window.Intercom(
        'startTour',
        IntercomProductTours.TRACK_COMPANY_METRICS.INTERCOM_TOUR_ID
      )
    },
  },
  EXPLORE_SETTINGS: {
    id: 'user_settings_page',
    groupTypes: [GroupTypes.CLIENT],
    actionType: UniversityActionType.GROUP,
    enabledInReadOnly: true,
    isOptional: true,
    onStart: () => {
      history.push('/settings/account/notifications')
      window.Intercom(
        'startTour',
        IntercomProductTours.EXPLORE_SETTINGS.INTERCOM_TOUR_ID
      )
    },
  },
  NEED_HELP: {
    id: 'need_help',
    groupTypes: [GroupTypes.CLIENT],
    actionType: UniversityActionType.GROUP,
    isOptional: true,
    enabledInReadOnly: true,
    onStart: () => {
      window.Intercom(
        'startTour',
        IntercomProductTours.NEED_HELP.INTERCOM_TOUR_ID
      )
    },
  },
}

export const getUniversityProgress = ({ actions, groupType }) => {
  const totalActions = actions.filter((action) =>
    action.groupTypes.includes(groupType)
  ).length
  const totalCompletedActions = actions.filter(
    (action) => action.completed || action.skip
  ).length

  return round((100 * totalCompletedActions) / totalActions, 0)
}

export const isRunningIntercomProductTour = () => {
  const intercomContainer = document.getElementById('intercom-positioner-tree')
  return !!intercomContainer
}

export const isUniversityActionEnabled = (action, actions, isReadOnlyMode) => {
  if (isReadOnlyMode && !action.enabledInReadOnly) {
    return false
  }

  if (action.isDisabled) {
    return false
  }

  if (!action?.dependsOn?.length) {
    return true
  }

  return action.dependsOn.every(
    (dependencyActionId) =>
      actions.find(({ id }) => id === dependencyActionId)?.completed
  )
}
