import { useCallback } from 'react'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { DefaultRoutes, setRouteConfiguration } from 'reducers/breadcrumbSlice'
import { useHistory } from 'react-router-dom'
import { Channel } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'

import { StreamChatType, ChannelData } from '../types'

export const MESSAGE_ID_QUERY_PARAM = 'messageId'

export const useSetActiveChannel = <T extends ChannelData>(
  preventNavigation?: boolean
) => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { setActiveChannel: setActiveChannelContext } =
    useChatContext<StreamChatType<T>>()

  const setActiveChannel = useCallback(
    (channel?: Channel<StreamChatType<T>>, messageId?: string) => {
      if (!preventNavigation) {
        dispatch(
          setRouteConfiguration({
            route: DefaultRoutes.CHAT,
            initialLinkRedirect: `/chat`,
          })
        )

        history.replace(
          `/chat/${channel?.id || ''}${
            messageId ? `?${MESSAGE_ID_QUERY_PARAM}=${messageId}` : ''
          }`
        )
      }
      setActiveChannelContext(channel)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, preventNavigation, setActiveChannelContext]
  )

  return setActiveChannel
}
