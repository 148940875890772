import React from 'react'
import PropTypes from 'prop-types'

import Card from 'components/Card'
import styles from './UpdateLayout.module.scss'

const UpdateButtons = ({ children }) => (
  <Card className={`${styles.card} ${styles.override}`} shadow>
    <div className={styles.buttonsContainer}>{children}</div>
  </Card>
)

UpdateButtons.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
}

export default UpdateButtons
