import { displayThousands } from 'utils/functions/number'
import { DATE_FORMATS, formattedDate } from 'utils/functions/date'
import { TooltipDate, TooltipValue, TooltipWrapper } from './MetricChart.styles'

interface CustomTooltipProps {
  payload?: {
    payload: {
      value: number
      date: string
    }[]
  }
}

const CustomTooltip = ({ payload }: CustomTooltipProps) => {
  const hasData = payload && payload[0]
  return hasData ? (
    <TooltipWrapper>
      <TooltipValue>{displayThousands(payload[0].payload.value)}</TooltipValue>
      <TooltipDate>
        {formattedDate(payload[0].payload.date, DATE_FORMATS.DDMM__YYYY)}
      </TooltipDate>
    </TooltipWrapper>
  ) : null
}

export default CustomTooltip
