import { PropsWithChildren, useState } from 'react'
import Separator from 'ui/Separator'
import useScrollStopListener from 'utils/hooks/useScrollStopListener'
import PageDotIndicator from '../PageDotIndicator'
import SliderArrow from '../SliderArrow'
import {
  PlanSliderContainer,
  SliderContainer,
  SliderScrollContainer,
} from './PlanSlider.styles'

type Props = {
  slidesToShow: number
  countPlans: number
}

const itemWidth = 280
const itemGap = 30

const PlanSlider = ({
  children,
  slidesToShow,
  countPlans,
}: PropsWithChildren<Props>) => {
  const [currentPage, setCurrentPage] = useState(0)

  const scrollRef = useScrollStopListener(() => {
    const scrollLeft = scrollRef.current?.scrollLeft!
    const offset = scrollLeft % (itemWidth + itemGap)

    scrollRef.current?.scrollBy({
      left: offset > itemWidth / 2 ? itemWidth + itemGap - offset : -offset,
      behavior: 'smooth',
    })
  }, 50)

  const onClickPage = (pageIndex: number) => {
    setCurrentPage(pageIndex)
    scrollRef.current?.scrollTo({
      left: pageIndex * (itemWidth + itemGap),
      behavior: 'smooth',
    })
  }

  const onScroll = () => {
    const scrollLeft = scrollRef.current?.scrollLeft!
    const page = Math.round(scrollLeft / itemWidth)
    setCurrentPage(page)
  }

  const handleScrollLeft = () => {
    setCurrentPage((page) => page - 1)
    scrollRef.current?.scrollTo({
      left: scrollRef.current.scrollLeft - itemWidth - itemGap,
      behavior: 'smooth',
    })
  }

  const handleScrollRight = () => {
    setCurrentPage((page) => page + 1)
    scrollRef.current?.scrollTo({
      left: scrollRef.current.scrollLeft + itemWidth + itemGap,
      behavior: 'smooth',
    })
  }

  const getScrollContainerWidth = () => {
    if (slidesToShow === 1) return `${itemWidth}px`

    return `${slidesToShow * itemWidth + (slidesToShow - 1) * itemGap}px`
  }

  const countPages = countPlans - slidesToShow + 1

  return (
    <PlanSliderContainer>
      <SliderContainer>
        <SliderArrow
          direction="left"
          onClick={() => handleScrollLeft()}
          visible={currentPage > 0}
        />
        <SliderScrollContainer
          ref={scrollRef}
          id="scroll-element"
          width={getScrollContainerWidth()}
          onScroll={onScroll}
          countPlans={countPlans}
        >
          {children}
        </SliderScrollContainer>
        <SliderArrow
          direction="right"
          onClick={() => handleScrollRight()}
          visible={currentPage + 1 < countPages}
        />
      </SliderContainer>

      <Separator space="1.6rem" />

      {countPages > 1 && (
        <PageDotIndicator
          currentPage={currentPage}
          countPages={countPages}
          onClickPage={onClickPage}
        />
      )}
      <Separator space="2.4rem" />
    </PlanSliderContainer>
  )
}

export default PlanSlider
