import { useRef } from 'react'
import { useIntl } from 'react-intl'
import type { FormikProps } from 'formik'
import {
  useInvestorQuery,
  useEditInvestorMutation,
} from 'utils/hooks/investorManagement/useInvestorsQuery'
import { InvestorFormValues, InvestorGroupUser } from 'utils/types/investors'
import {
  getAddInvestorGroupUsersPayload,
  getDeleteInvestorGroupUsersPayload,
  getInvestorPayload,
} from 'utils/functions/investorManagement'
import InvestorManagementService from 'api/InvestorManagementService'
import Toast from 'components/Toast'
import { User } from 'utils/types/user'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'
import { isDuplicatedFieldError } from 'utils/functions/errors'
import useInvestorGroup from '../hooks/useInvestorGroup'

export const useEditInvestor = (onCloseDrawer: () => void) => {
  const intl = useIntl()
  const { id: investorId } = useInvestorGroup()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const formRef = useRef<FormikProps<InvestorFormValues>>(null)
  const { data: investor, isLoading } = useInvestorQuery(investorId)

  const editInvestorMutation = useEditInvestorMutation(
    investorId,
    currentGroupId
  )

  const editInvestor = (values: InvestorFormValues) => {
    const payload = getInvestorPayload({
      investorData: values,
      oldInvestor: investor!,
    })

    editInvestorMutation.mutate(payload, {
      onSuccess: (newInvestor) => {
        onCloseDrawer()
        Toast.displayIntl([
          'investorManagement.success.editInvestor',
          { investorName: newInvestor.name },
        ])
      },
      onError: (error: Error) => {
        if (isDuplicatedFieldError('name', error)) {
          formRef.current?.setFieldError(
            'name',
            intl.formatMessage({
              id: 'investorManagement.investorNameTakenError',
            })
          )
        } else {
          Toast.displayIntl('investorManagement.errors.editInvestor', 'error')
        }
      },
    })
  }

  const addInvestorGroupUsers = async (users: User[], message?: string) => {
    const payload = getAddInvestorGroupUsersPayload(users, message)

    editInvestorMutation.mutate(payload, {
      onSuccess: () =>
        Toast.displayIntl([
          'investorManagement.success.invitesSent',
          { countUsers: users.length, investorName: investor!.name },
        ]),
      onError: (_error) =>
        Toast.displayIntl('investorManagement.errors.invitesSent', 'error'),
    })
  }

  const deleteInvestorGroupUser = async (
    investorUserGroup: InvestorGroupUser,
    undoDeletionAction: () => void
  ) => {
    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'investorManagement.success.userRemoved' },
        {
          userName: investorUserGroup.user.name || investorUserGroup.user.email,
          investorName: investor!.name,
        }
      ),
      action: undoDeletionAction,
      afterClose: () => {
        const payload = getDeleteInvestorGroupUsersPayload([investorUserGroup])
        editInvestorMutation.mutate(payload, {
          onError: (_error) =>
            Toast.displayIntl('investorManagement.errors.userRemoved', 'error'),
        })
      },
      type: 'info',
      label: intl.formatMessage({
        id: 'investorManagement.errors.undoRemoved',
      }),
    })
  }

  const resendInvite = async (investorUser: InvestorGroupUser) => {
    try {
      await InvestorManagementService.sendInvestorInvites(investor!.id, [
        investorUser.user.id,
      ])
      Toast.displayIntl([
        'investorManagement.success.resendInvite',
        { email: investorUser.user.email },
      ])
    } catch (err) {
      Toast.displayIntl(
        [
          'investorManagement.errors.resendInvite',
          { email: investorUser.user.email },
        ],
        'error'
      )
    }
  }

  return {
    investor,
    isLoading,
    editInvestor,
    resendInvite,
    addInvestorGroupUsers,
    deleteInvestorGroupUser,
    formRef,
  }
}
