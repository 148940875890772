import { ScheduleType } from 'utils/types/update'
import {
  AccountingReportParamsPayload,
  CreateUpdatePayload,
  EditUpdatePayload,
} from 'utils/types/updatePayloads'
import {
  AccountingReportType,
  BalanceSheetReportParams,
  ProfitAndLossReportParams,
  ReportParams,
} from '../../constants/accounting'
import { assertUnreachable } from '../utils'
import {
  getDateFromTimeFrame,
  getTimeFrameFromBalanceDate,
  getTimeFrameFromReportDatePeriod,
} from '../xeroReports'
import { CreateUpdatePayloadParams, EditUpdatePayloadParams } from './types'
import {
  getCreateUpdateItemPayload,
  getEditUpdateItemPayload,
  getScheduleUpdatePayload,
} from './updates'

export type AccountingReportTypeKey = 'xero_report' | 'quickbooks_report'
export type AccountingReportTypeValue = 'BalanceSheet' | 'ProfitAndLoss'

export type CreateAccountingPayloadParams<T extends AccountingReportTypeKey> =
  Omit<
    CreateUpdatePayloadParams,
    'body' | 'investor' | 'portfolio' | 'associationType'
  > & {
    accountingReportType: T
    reportParams: ReportParams
    reportType: AccountingReportType
  }

export type EditAccountingPayloadParams<T extends AccountingReportTypeKey> =
  Omit<
    EditUpdatePayloadParams,
    'body' | 'investor' | 'portfolio' | 'associationType'
  > & {
    accountingReportType: T
    reportParams: ReportParams
    reportType: AccountingReportType
  }

type CreateAccountingUpdatePayload<T extends AccountingReportTypeKey> =
  CreateUpdatePayload<T, AccountingReportParamsPayload>

export const getAccountingReportTypeKey = (
  reportType: AccountingReportType
): AccountingReportTypeKey => {
  switch (reportType) {
    case AccountingReportType.XERO_BALANCE_SHEET:
    case AccountingReportType.XERO_PROFIT_AND_LOSS:
      return 'xero_report'
    case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
    case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
      return 'quickbooks_report'
    default:
      return assertUnreachable(reportType)
  }
}

export const getReportValue = (
  reportType: AccountingReportType
): AccountingReportTypeValue => {
  switch (reportType) {
    case AccountingReportType.XERO_BALANCE_SHEET:
    case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
      return 'BalanceSheet'
    case AccountingReportType.XERO_PROFIT_AND_LOSS:
    case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
      return 'ProfitAndLoss'
    default:
      return assertUnreachable(reportType)
  }
}

export const getReportParamsPayload = (
  reportType: AccountingReportType,
  reportParams: ReportParams,
  date: Date
): AccountingReportParamsPayload => {
  switch (reportType) {
    case AccountingReportType.XERO_BALANCE_SHEET:
      return {
        reportType: getReportValue(reportType),
        reportData: {
          periods: Number(reportParams.periods),
          paymentsOnly: reportParams.paymentsOnly,
          date: getDateFromTimeFrame(
            (reportParams as BalanceSheetReportParams).balanceDate,
            date
          ),
          timeFrame: (reportParams as BalanceSheetReportParams).balanceDate,
          standardLayout: reportParams.standardLayout,
        },
      }
    case AccountingReportType.QUICKBOOKS_BALANCE_SHEET:
      return {
        reportType: getReportValue(reportType),
        reportData: {
          periods: Number(reportParams.periods),
          paymentsOnly: reportParams.paymentsOnly,
          date: getDateFromTimeFrame(
            (reportParams as BalanceSheetReportParams).balanceDate,
            date
          ),
          timeFrame: getTimeFrameFromBalanceDate(
            (reportParams as BalanceSheetReportParams).balanceDate
          ),
        },
      }
    case AccountingReportType.XERO_PROFIT_AND_LOSS:
      return {
        reportType: getReportValue(reportType),
        reportData: {
          periods: Number(reportParams.periods),
          paymentsOnly: reportParams.paymentsOnly,
          timeFrame: getTimeFrameFromReportDatePeriod(
            (reportParams as ProfitAndLossReportParams).reportDatePeriod
          ),
          standardLayout: reportParams.standardLayout,
          reportDatePeriod: (reportParams as ProfitAndLossReportParams)
            .reportDatePeriod,
        },
      }
    case AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS:
      return {
        reportType: getReportValue(reportType),
        reportData: {
          periods: Number(reportParams.periods),
          paymentsOnly: reportParams.paymentsOnly,
          timeFrame: getTimeFrameFromReportDatePeriod(
            (reportParams as ProfitAndLossReportParams).reportDatePeriod
          ),
          reportDatePeriod: (reportParams as ProfitAndLossReportParams)
            .reportDatePeriod,
        },
      }
    default:
      return assertUnreachable(reportType)
  }
}

export const getCreateAccountingPayload = <T extends AccountingReportTypeKey>({
  accountingReportType,
  reportParams,
  schedule = { scheduleType: ScheduleType.SEND_NOW },
  date,
  reportType,
  ...params
}: CreateAccountingPayloadParams<T>): CreateAccountingUpdatePayload<T> => {
  const basicPayload = getCreateUpdateItemPayload({
    ...params,
    schedule,
    date,
  })
  return {
    [accountingReportType]: {
      ...basicPayload,
      ...getReportParamsPayload(reportType, reportParams, basicPayload.date!),
    },
    ...getScheduleUpdatePayload({
      loggingUpdateScheduleId: schedule?.loggingUpdateScheduleId,
      scheduleType: schedule?.scheduleType,
      repetition: schedule?.repetition,
      date,
    }),
  } as CreateAccountingUpdatePayload<T>
}

export const getEditAccountingPayload = <T extends AccountingReportTypeKey>({
  accountingReportType,
  schedule = { scheduleType: ScheduleType.SEND_NOW },
  date,
  ...params
}: EditAccountingPayloadParams<T>): EditUpdatePayload<T> => {
  return {
    [accountingReportType]: getEditUpdateItemPayload({
      ...params,
      schedule,
      date,
    }),

    ...getScheduleUpdatePayload({
      loggingUpdateScheduleId: schedule?.loggingUpdateScheduleId,
      scheduleType: schedule?.scheduleType,
      repetition: schedule?.repetition,
      date,
    }),
  } as EditUpdatePayload<T>
}
