import styled, { css } from 'styled-components'
import { FileViewEnum } from 'components/FileViewSelector'
import { color } from 'utils/functions/colors'

export const AttachmentWrapper = styled.div<{
  size: FileViewEnum
  position?: string
}>`
  display: flex;
  position: ${({ position = 'absolute' }) => position};
  color: ${color('darkGray', 0.5)};
  font-size: 1.6rem;

  ${({ size }) => {
    if (size === FileViewEnum.SMALL) {
      return css`
        left: 0.8rem;
        top: 0.85rem;
      `
    }
    return css`
      left: 1.2rem;
      top: 1.37rem;
    `
  }}
`
