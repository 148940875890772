import FundCompanyProfileFunds from 'components/FundCompanyProfileFunds/FundCompanyProfileFunds'
import ProfileMetrics from 'components/ProfileMetrics/ProfileMetrics'
import ProfileChatWidget from 'containers/Chat/components/ChatWidgets'
import { isActingAsFounder, isActingAsInvestorGroup } from 'selectors/auth'
import { getHasChatFeature } from 'selectors/chat'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import { buildHoldingTopicEntityFromCompany } from 'utils/types/chatTopicEntity'
import { CompanyHoldingData } from 'utils/types/company'
import Tabs from 'components/Tabs'
import { useIntl } from 'react-intl'
import { GROUP_PROFILE_PREFIX } from 'containers/Profiles/FundManagerProfile/FundManagerProfile'
import { MainContentCardWrapper } from './CompanyMainContent.styles'
import CompanyUpdates from './CompanyUpdates/CompanyUpdates'

interface Props {
  companyId: string
  companyInfo?: CompanyHoldingData
  prefix?: string
}

const CompanyMainContent = ({
  companyId,
  companyInfo,
  prefix = 'companies',
}: Props) => {
  const isFounder = useAppSelector(isActingAsFounder)
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const hasChatFeature = useAppSelector(getHasChatFeature)
  const planFeatures = usePlanFeatures()
  const intl = useIntl()

  const showMetrics =
    planFeatures.areMetricsEnabled &&
    (isFounder || (!isInvestorGroup && !companyInfo?.isPrivateProfile))

  const showFund = !isInvestorGroup && companyInfo?.fund

  const isFromGroupProfile = prefix === GROUP_PROFILE_PREFIX

  const ROUTES = {
    UPDATES: {
      url:
        isFromGroupProfile || isFounder
          ? `/${prefix}/updates`
          : `/${prefix}/${companyId}/updates`,
      urlPath:
        isFromGroupProfile || isFounder
          ? `/${prefix}/updates`
          : `/${prefix}/:companyId/updates`,
    },
    INVESTMENTS: {
      url: isFromGroupProfile
        ? `/${prefix}/investments`
        : `/${prefix}/${companyId}/investments`,
      urlPath: isFromGroupProfile
        ? `/${prefix}/investments`
        : `/${prefix}/:companyId/investments`,
    },
    METRICS: {
      url:
        isFromGroupProfile || isFounder
          ? `/${prefix}/metrics`
          : `/${prefix}/${companyId}/metrics`,
      urlPath:
        isFromGroupProfile || isFounder
          ? `/${prefix}/metrics`
          : `/${prefix}/:companyId/metrics`,
    },
    CHAT: {
      url: isFromGroupProfile
        ? `/${prefix}/chat`
        : `/${prefix}/${companyId}/chat`,
      urlPath: isFromGroupProfile
        ? `/${prefix}/chat`
        : `/${prefix}/:companyId/chat`,
    },
  }

  return (
    <MainContentCardWrapper>
      <Tabs
        initialIndex={null}
        tabOffset={24}
        items={[
          {
            id: 'UPDATES',
            title: intl.formatMessage({
              id: 'portfolioDetail.updates',
            }),
            ...ROUTES.UPDATES,
          },
          showFund && {
            id: 'INVESTMENTS',
            title: intl.formatMessage({
              id: 'portfolioDetail.investments',
            }),
            ...ROUTES.INVESTMENTS,
          },
          showMetrics && {
            id: 'METRICS',
            title: intl.formatMessage({
              id: 'metrics.metrics',
            }),
            ...ROUTES.METRICS,
          },
          hasChatFeature && {
            id: 'CHAT',
            title: intl.formatMessage({
              id: 'chat.chat',
            }),
            ...ROUTES.CHAT,
          },
        ].filter(Boolean)}
        withBorder
      >
        <Tabs.RouteContent path={ROUTES.UPDATES.urlPath}>
          <CompanyUpdates companyId={companyId} />
        </Tabs.RouteContent>

        {showFund && (
          <Tabs.RouteContent path={ROUTES.INVESTMENTS.urlPath}>
            <FundCompanyProfileFunds fundCompany={companyInfo} />
          </Tabs.RouteContent>
        )}

        {showMetrics && (
          <Tabs.RouteContent path={ROUTES.METRICS.urlPath}>
            <ProfileMetrics companyInfo={companyInfo} />
          </Tabs.RouteContent>
        )}

        {hasChatFeature && (
          <Tabs.RouteContent path={ROUTES.CHAT.urlPath}>
            <ProfileChatWidget
              topic={
                companyInfo
                  ? buildHoldingTopicEntityFromCompany(companyInfo)
                  : undefined
              }
            />
          </Tabs.RouteContent>
        )}
      </Tabs>
    </MainContentCardWrapper>
  )
}

export default CompanyMainContent
