import { FormattedMessage } from 'react-intl'
import theme from 'utils/theme'

import {
  SideBarItemContainer,
  SidebarItemIcon,
  SidebarItemTitle,
  ItemWrapper,
} from '../SideBarItem/SideBarItem.styles'

interface Props {
  handleOpenModal: () => void
}

const UpgradeYourAccountButton = ({ handleOpenModal }: Props) => {
  return (
    <SideBarItemContainer
      id="upgradeYourAccountButton"
      as="button"
      onClick={handleOpenModal}
      backgroundColor={theme.colors.gray}
    >
      <ItemWrapper justify="flex-start">
        <SidebarItemIcon icon={['far', 'gem']} />
        <SidebarItemTitle>
          <FormattedMessage id="sidebar.upgradeYourAccount" />
        </SidebarItemTitle>
      </ItemWrapper>
    </SideBarItemContainer>
  )
}

export default UpgradeYourAccountButton
