import { useCallback } from 'react'
import { StreamChat } from 'stream-chat'

import ChatService from 'api/ChatService'
import { getUser } from 'selectors/auth'
import { useAppSelector } from './reduxToolkit'

export const useChatSetup = () => {
  const currentUser = useAppSelector(getUser)

  const connect = useCallback(
    async (client: StreamChat) => {
      const userToken = await ChatService.getUserToken()
      await client.connectUser(
        {
          id: currentUser.id,
          name: currentUser.name,
          image: currentUser.image.mediumLogo.url,
        },
        userToken
      )
    },
    [currentUser]
  )

  const disconnect = useCallback(async (client: StreamChat) => {
    await client.disconnectUser()
  }, [])

  return {
    connect,
    disconnect,
  }
}
