import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const activeMenuButtonStyles = () => css`
  border: 0.1rem solid ${color('primaryBlue')};
  background-color: ${color('lightBlue', 0.2)};
`

export const ActionsWrapper = styled.div`
  display: flex;

  svg {
    font-size: 1.5rem;
  }
`

export const PortfolioNavigationActions = styled.div`
  display: flex;
  gap: 1.2rem;
  border-left: 0.1rem solid ${color('lightGray', 0.2)};
  width: 13rem;
  padding: 1.2rem 0 0 0;
`

export const PortfolioBigNavigationActions = styled(PortfolioNavigationActions)`
  display: flex;
  justify-content: center;
  align-self: flex-start;
  border: none;
  grid-column: 2 / span 1;
`

export const ActionsIconWrapper = styled.div<{ hasFiltersApplied?: boolean }>`
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 5rem;
  min-width: 3rem;
`

export const HideAndShowIconWrapper = styled.div`
  position: relative;
`

export const ClearFiltersBadge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.8rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${color('white')};
  border: 0.1rem solid ${color('primaryBlue')};
  cursor: pointer;
  z-index: 4;
`

export const FiltersCountBadge = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color('lightGray')};
  border: 0.1rem solid ${color('veryLightGray')};
  color: ${color('white')};
  padding: 0.3rem, 0.8rem;
  border-radius: 50%;
  bottom: -0.5rem;
  right: -0.8rem;
  z-index: 4;
  width: 2.1rem;
  height: 2.1rem;
  font-size: 1.05rem;
  font-weight: 700;
`
