import styled from 'styled-components'

import Collapsable from 'components/Collapsable'
import * as CollapsableStyles from 'components/Collapsable/Collapsable.styles'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'
import IconButtonToStyle from 'ui/IconButton'

export const IconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  display: none;
`

export const IconButton = styled(IconButtonToStyle)`
  min-width: 3.2rem;
  min-height: 3.2rem;
`

export const Collapsible = styled(Collapsable)`
  container-type: inline-size;

  ${CollapsableStyles.HeaderContainer} {
    gap: 0;
    padding: 0 1.2rem 0 1.6rem;
    margin-bottom: 0.5rem;
    align-items: center;
    height: 3.2rem;

    ${CollapsableStyles.IconWrapper} {
      min-width: 1.6rem;
      font-size: 1.6rem;
    }

    &:hover:not(${IconButton}) {
      ${CollapsableStyles.TitleContainer}, ${CollapsableStyles.IconWrapper} {
        color: ${color('primaryBlue')};
      }

      ${IconContainer} {
        display: flex;
      }
    }

    @media ${maxSize.mobile} {
      height: 3.8rem;
      padding: 0 2.4rem 0 3rem;
      margin-bottom: 0px;
    }
  }

  ${CollapsableStyles.TitleContainer} {
    margin-left: 1.2rem;
    font-weight: 700;
    font-size: 1.4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    width: fit-content;
    padding: 0.6rem 0;
  }

  ${CollapsableStyles.BodyContainer} {
    background: ${color('cloud')};
  }

  .str-chat__channel-list {
    padding-top: 0.1rem;
    border-right-width: 0;
    overflow: initial;
  }

  .str-chat__channel-list-react .str-chat__channel-list-messenger-react {
    overflow: initial;
    padding: initial;
    height: fit-content;

    .str-chat__channel-list-messenger-react__main {
      overflow: initial;
      height: fit-content;
    }
  }

  @media ${maxSize.mobile} {
    container-type: unset;
  }
`
