import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { maxSize } from 'utils/constants/breakpoint'
import {
  VisibleFundHoldingsColumns,
  getHoldingColumns,
} from 'utils/functions/renderers/renderFundHoldingsHelper'
import { updateUserGroupPayload } from 'utils/hooks/queries/useUserGroupQuery'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'

export interface HoldingColumnSelectorHandler {
  columns: any
  columnsOption: { id: string; status: boolean; label: string }[]
  toggleColumn: (index: number, columnId?: string) => void
}

export interface HoldingColumnSelectorProps {
  visibleColumnsByDefault: VisibleFundHoldingsColumns
  updateUserGroup: (data: updateUserGroupPayload) => void
}

export const useHoldingsColumnSelector = ({
  visibleColumnsByDefault,
  updateUserGroup,
}: HoldingColumnSelectorProps): HoldingColumnSelectorHandler => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const { handleOnHoldingClick } = useOnClickRedirect()

  const [columnsOption, setColumnsOption] = useState<
    { id: string; status: boolean; label: string }[]
  >([])

  const tableColumnsOptions = useMemo(
    () =>
      getHoldingColumns({
        intl,
        isMobile,
        visibleColumns: visibleColumnsByDefault,
        config: {
          fmvWithTooltip: true,
        },
        handleOnHoldingClick,
      }),
    [handleOnHoldingClick, intl, isMobile, visibleColumnsByDefault]
  )

  // this is to prevent the function from being recreated on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedHandleOnHoldingClick = useCallback(handleOnHoldingClick, [])

  const selectorColumnsDropdownOptions = useMemo(
    () =>
      getHoldingColumns({
        intl,
        isMobile,
        visibleColumns: visibleColumnsByDefault,
        config: {
          fmvWithTooltip: false,
        },
        handleOnHoldingClick: memoizedHandleOnHoldingClick,
      }),
    [intl, isMobile, memoizedHandleOnHoldingClick, visibleColumnsByDefault]
  )

  useEffect(() => {
    const newColumnsOption = selectorColumnsDropdownOptions
      .map((col) => ({
        id: col.id,
        status: !col.hidden,
        label: col.label as string,
      }))
      .filter((col) => col.id !== 'expand' && col.id !== 'name')
    setColumnsOption(newColumnsOption)
  }, [selectorColumnsDropdownOptions])

  const toggleColumn = (_: number, columnId: string) => {
    const newVisibleColumnsByDefault = {
      ...visibleColumnsByDefault,
      [columnId]: !visibleColumnsByDefault[columnId],
    }
    updateUserGroup({ setting: { visibleFields: newVisibleColumnsByDefault } })
  }

  return {
    columns: tableColumnsOptions,
    columnsOption,
    toggleColumn,
  }
}
