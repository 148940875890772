import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Button from 'components/Button'

import './style.css'

const AddSectionEntry = ({
  onAddText,
  onAddImage,
  showRemove,
  onRemoveSection,
}) => {
  const openFilePicker = () => {
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = () => {
      onAddImage(Object.values(input.files)[0])
    }
  }

  return (
    <div className="mjml-generator-container mjml-entity">
      <div className="btn-wrapper">
        <Button blue onClick={onAddText}>
          <FormattedMessage id="createEmail.textBlock" />
        </Button>
        <Button blue onClick={openFilePicker}>
          <FormattedMessage id="createEmail.singleImage" />
        </Button>
      </div>
      {showRemove && (
        <Button link onClick={onRemoveSection}>
          <FormattedMessage id="createEmail.removeSection" />
        </Button>
      )}
    </div>
  )
}

AddSectionEntry.propTypes = {
  onAddText: PropTypes.func.isRequired,
  onAddImage: PropTypes.func.isRequired,
  onRemoveSection: PropTypes.func.isRequired,
  showRemove: PropTypes.bool,
}

AddSectionEntry.defaultProps = {
  showRemove: false,
}

export default AddSectionEntry
