import { useIntl } from 'react-intl'
import Input from 'ui/Input'
import Separator from 'ui/Separator'
import { useFormikContext } from 'components/Form/hooks'
import {
  FinancialInformationFormValues,
  InvestorType,
} from 'utils/types/investors'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'
import { InvestorTypes } from 'utils/constants/investorManagement'
import { DisplayWrapper, SelectorWrapper } from './InvestorForm.styles'

interface FinancialInformationFormProps {
  investorType?: InvestorType
}

const FinancialInformationForm: React.FC<FinancialInformationFormProps> = ({
  investorType,
}) => {
  const intl = useIntl()
  const { values, errors, touched, handleChange, handleBlur, getFieldName } =
    useFormikContext<FinancialInformationFormValues>()

  return (
    <DisplayWrapper showInGrid={false} columnsNumber={1}>
      <SelectorWrapper>
        <Input
          name={getFieldName('einTaxId')}
          type="password"
          autoFill="none"
          label={
            investorType === InvestorTypes.ORGANIZATION
              ? intl.formatMessage({ id: 'investorManagement.einTaxId' })
              : intl.formatMessage({
                  id: 'investorManagement.socialSecurityNumber',
                })
          }
          placeholder={
            investorType === InvestorTypes.ORGANIZATION
              ? intl.formatMessage({
                  id: 'investorManagement.addTaxId',
                })
              : intl.formatMessage({ id: 'investorManagement.addSSN' })
          }
          value={values.einTaxId}
          onChange={handleChange}
          error={buildFormError(
            errors?.einTaxId,
            ErrorType.ERROR,
            touched?.einTaxId
          )}
          onBlur={handleBlur}
          sensitive
          autoComplete="new-password"
        />
        <Separator />
      </SelectorWrapper>

      <SelectorWrapper>
        <Input
          name={getFieldName('bankName')}
          type="password"
          autoFill="none"
          label={intl.formatMessage({ id: 'investorManagement.bankName' })}
          placeholder={intl.formatMessage({
            id: 'investorManagement.addBankName',
          })}
          value={values.bankName}
          onChange={handleChange}
          error={buildFormError(
            errors?.bankName,
            ErrorType.ERROR,
            touched?.bankName
          )}
          onBlur={handleBlur}
        />
        <Separator />
      </SelectorWrapper>

      <SelectorWrapper>
        <Input
          name={getFieldName('routingNumber')}
          type="password"
          autoFill="none"
          label={intl.formatMessage({
            id: 'investorManagement.routingNumber',
          })}
          placeholder={intl.formatMessage({
            id: 'investorManagement.addRoutingNumber',
          })}
          value={values.routingNumber}
          onChange={handleChange}
          error={buildFormError(
            errors?.routingNumber,
            ErrorType.ERROR,
            touched?.routingNumber
          )}
          onBlur={handleBlur}
          sensitive
        />

        <Separator />
      </SelectorWrapper>

      <SelectorWrapper>
        <Input
          name={getFieldName('bankAccountNumber')}
          type="password"
          autoFill="none"
          label={intl.formatMessage({
            id: 'investorManagement.bankAccountNumber',
          })}
          placeholder={intl.formatMessage({
            id: 'investorManagement.addBankAccountNumber',
          })}
          value={values.bankAccountNumber}
          onChange={handleChange}
          error={buildFormError(
            errors?.bankAccountNumber,
            ErrorType.ERROR,
            touched?.bankAccountNumber
          )}
          onBlur={handleBlur}
          sensitive
        />
        <Separator />
      </SelectorWrapper>
    </DisplayWrapper>
  )
}

export default FinancialInformationForm
