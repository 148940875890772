/* eslint-disable consistent-return,  no-lonely-if */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'

import {
  showTermsOfUse,
  updateUserSession,
  setIsSigningUp,
} from 'features/authSlice'
import { getUser } from 'selectors/auth'
import { termsOfUseContent } from 'utils/constants/termsOfUse'
import { TERMS_OF_USE_LINK, PRIVACY_POLICY_LINK } from 'utils/constants/links'
import Heading from 'components/Heading'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import AuthService from 'api/AuthService'
import Toast from 'components/Toast'
import { useSignIn } from 'containers/Auth/SignIn/useSignIn'
import { getUserSession } from 'utils/functions/user'

import styles from './TermsOfUse.module.scss'

const TermsOfUse = ({ userCredentials }) => {
  const [hasUserAccepted, setHasUserAccepted] = useState(false)
  const currentUser = useAppSelector(getUser)
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const history = useHistory()
  const { signInAuth0, loadUserTier } = useSignIn()

  const onChangeTermsOfUseCheckbox = (event) => {
    setHasUserAccepted(event.target.checked)
  }

  const setupUserByToken = async () => {
    const response = await AuthService.getUserAccountByToken(
      userCredentials.token
    )
    const {
      data: {
        entities: { groups, users, investorGroups },
      },
      headers,
    } = response

    const userId = Object.keys(users)[0]
    const user = users[userId]
    const userSession = {
      'access-token': headers['access-token'],
      uid: headers.uid,
      client: headers.client,
    }

    const session = getUserSession({
      user,
      groups,
      userSession,
      investorGroups,
    })

    await dispatch(updateUserSession(session))
  }

  const redirectToDashboardAfterGroupManagement = () => {
    history.replace('/dashboard/updates')
    return dispatch(showTermsOfUse({ show: false }))
  }

  const redirectToGroupSetup = async () => {
    history.replace('/group-setup')
    return dispatch(showTermsOfUse({ show: false }))
  }

  const redirectAfterReAcceptingTermsOfUse = async () => {
    await signInAuth0()
    history.replace('/')
    return dispatch(showTermsOfUse({ show: false }))
  }

  const onAgreeAndContinue = async () => {
    try {
      await AuthService.updateTermsOfUse({
        isGoogleSignIn: false,
        userCredential: userCredentials,
      })

      if (userCredentials.token) {
        await setupUserByToken()
      }
      // Terms of use have changed, user mas accept them again
      if (
        // userCredentials.provider === 'google' || // is a google user that is accepting TOS after they changed
        userCredentials.encryptedPassword || // is an email-password user that is accepting TOS after they changed
        userCredentials.password // is an email-password user that is logging in for the first time
        // userCredentials.password) && // is an email-password user that is logging in for the first time
        // !userCredentials.fromGoogleSignup
      ) {
        return redirectAfterReAcceptingTermsOfUse()
      }

      // User has been added from group management
      return redirectToDashboardAfterGroupManagement()
    } catch (error) {
      return Toast.display(
        intl.messages['termsOfUse.errorAcceptingTermsOfUse'],
        'error'
      )
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Heading className={styles.title}>
          <FormattedMessage id="termsOfUse.title" />
        </Heading>
        <p className={styles.content}>{termsOfUseContent}</p>
        <hr className={styles.separator} />
        <Checkbox
          id="termsOfUse"
          name="termsOfUse"
          className={styles.termsOfUse}
          onChange={onChangeTermsOfUseCheckbox}
          label={
            <FormattedMessage
              id="termsOfUse.checkboxLegend"
              values={{
                termsOfUseLink: (
                  <a
                    href={TERMS_OF_USE_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    {intl.messages['termsOfUse.legend']}
                  </a>
                ),
                privacyPolicyLink: (
                  <a
                    href={PRIVACY_POLICY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                  >
                    {intl.messages['privacyPolicy.legend']}
                  </a>
                ),
              }}
            />
          }
        />
        <Button
          className={styles.btnAcceptTerms}
          disabled={!hasUserAccepted}
          large
          primary
          rounded
          strong
          onClick={onAgreeAndContinue}
        >
          <FormattedMessage id="termsOfUse.agreeAndContinue" />
        </Button>
      </div>
    </div>
  )
}

TermsOfUse.propTypes = {
  userCredentials: PropTypes.object.isRequired,
}

export default TermsOfUse
