export default {
  funds: 'Funds',
  fundsDeals: 'Funds and Deal',
  addFund: 'Add Fund',
  loadingFunds: 'Loading funds...',
  noFunds: 'No funds.',
  privateFundTooltip:
    'This fund is only visible to you\nand your group members.',
  employee: '{employeesCount} Employee',
  employees: '{employeesCount} Employees',
}
