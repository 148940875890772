import { Formik } from 'formik'
import { Helmet } from 'components/Helmet'
import { useIntl } from 'react-intl'
import { UpdateTypes } from 'utils/constants/updates'
import { getAccountingSchema } from 'utils/schemas/updates'
import { AccountingFormValues } from 'utils/types/updateForm'
import { UpdateForm } from '../../components'
import AccountingForm from '../../components/AccountingForm'
import useCreateAccounting from './useCreateAccounting'

const CreateAccounting = () => {
  const { defaultValues, createAccountingUpdate } = useCreateAccounting()
  const intl = useIntl()

  return (
    <>
      <Helmet>
        <title>{`${intl.formatMessage({
          id: 'metaTitles.createAccounting',
        })}`}</title>
      </Helmet>
      <Formik<AccountingFormValues>
        initialValues={defaultValues}
        validationSchema={getAccountingSchema(intl)}
        onSubmit={(value) => createAccountingUpdate(value)}
        enableReinitialize
        validateOnMount
      >
        <UpdateForm
          updateType={UpdateTypes.ACCOUNTING}
          isPublishing={false}
          setIsPublishing={() => {}}
        >
          <AccountingForm />
        </UpdateForm>
      </Formik>
    </>
  )
}

export default CreateAccounting
