import React from 'react'
import ContentLoader from 'react-content-loader'

const EmailSectionsLoader = () => (
  <ContentLoader
    speed={0.5}
    height="auto"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width="100%"
  >
    <rect ry="10" rx="10" x="10" y="0" width="95%" height="40" />
    <rect ry="10" rx="10" x="10" y="50" width="95%" height="40" />
    <rect ry="10" rx="10" x="10" y="100" width="95%" height="40" />
    <rect ry="10" rx="10" x="10" y="150" width="95%" height="40" />
  </ContentLoader>
)

export default EmailSectionsLoader
