import Heading from 'components/Heading'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const TimelineList = styled.ul`
  list-style: none;
  margin-left: 2rem;
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`

export const TimelineTitle = styled(Heading)`
  color: ${color('lightGray')};
  font-weight: bold;
  font-size: 1.4rem;
  letter-spacing: 0.025em;
  margin-bottom: 2.3rem;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  text-transform: uppercase;
`
