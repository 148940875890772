import { useEffect, useState } from 'react'
import { useUpdateUserGroup } from 'utils/hooks/queries/useUserGroupQuery'
import { useFetchInvestmentsFilteredSummary } from 'utils/hooks/investments/useFetchInvestmentsFilteredSummary'
import { useHoldingsColumnSelector } from '../InvestmentsSummary/HoldingsSummaryTable/HoldingsColumnSelector/useHoldingsColumnSelector'

interface Props {
  portfolioHoldingsIds: string[]
}

const useCustomFilterView = ({ portfolioHoldingsIds }: Props) => {
  const [userGroupId, setUserGroupId] = useState<string>('')
  const { mutateAsync: updateUserGroup } = useUpdateUserGroup(userGroupId)
  const {
    data,
    isLoading,
    availableColumns,
    userGroupId: fetchedUserGroupId,
  } = useFetchInvestmentsFilteredSummary({
    portfolioHoldingsIds,
  })

  useEffect(() => {
    if (fetchedUserGroupId) {
      setUserGroupId(fetchedUserGroupId)
    }
  }, [fetchedUserGroupId])

  const { columns, columnsOption, toggleColumn } = useHoldingsColumnSelector({
    visibleColumnsByDefault: availableColumns,
    updateUserGroup,
  })

  return {
    data,
    holdings: data?.aggregateInvestmentHoldings,
    isLoading,
    columns,
    columnsOption,
    toggleColumn,
  }
}

export default useCustomFilterView
