import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import Checkbox from 'components/Checkbox'
import OptionsDropdownToStyle from 'ui/OptionsDropdown'
import {
  METRIC_CHART_HEIGHT,
  METRIC_VALUE_HEIGHT,
} from '../MetricDataPoints/MetricDataPoints.styles'

export const CustomCheckbox = styled(Checkbox)<{
  isMetricInSandboxState: boolean
}>`
  margin-top: 1rem;
  padding-left: 3rem;
  opacity: ${({ isMetricInSandboxState }) => (isMetricInSandboxState ? 1 : 0)};
`

export const MetricContainer = styled.div<{
  hasDataPoints: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.4rem 2.4rem;
  transition: height 0.3s ease;

  & + & {
    border-top: 0.1rem solid ${color('veryLightGray')};
  }

  :hover {
    background-color: ${color('veryLightBlue', 0.2)};
    ${CustomCheckbox} {
      opacity: 1;
    }
  }

  @media ${maxSize.sm} {
    padding: 1.4rem 1.6rem 1.4rem 2.4rem;
    gap: 0.5rem;

    ${({ hasDataPoints }) =>
      hasDataPoints &&
      css`
        min-height: 12rem;
        justify-content: flex-start;
      `}
  }
`

export const Metric = styled.div`
  display: grid;
  grid-template-columns: 18rem 1fr auto auto;

  @media ${size.tabletLg} {
    grid-template-columns: 25rem 1fr auto auto;
  }

  @media ${maxSize.sm} {
    grid-template-columns: 1fr auto auto;
  }
`

export const MetricMetadata = styled.div`
  display: flex;
  align-self: start;

  @media ${maxSize.sm} {
    height: 5rem;
    justify-content: flex-start;
  }
`

export const MetricNameContainer = styled.div<{ showCheckbox: boolean }>`
  max-width: 100%;
  min-width: 0;
  margin-left: ${({ showCheckbox }) => (showCheckbox ? '3.4rem' : '0')};
`

export const MetricName = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  max-width: 30rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media ${maxSize.sm} {
    max-width: 33rem;
    -webkit-line-clamp: 1;
  }

  @media ${maxSize.xsm} {
    max-width: 100%;
  }
`

export const ReceivingDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  gap: 0.7rem;
  padding-top: 0.4rem;
  font-size: 12px;
  color: ${color('darkGray')};
`

export const GroupsContainer = styled.div`
  height: 3rem;
  width: 7rem;
  display: flex;
  align-items: center;
`

export const DataPointsContainer = styled.div`
  height: ${METRIC_VALUE_HEIGHT}px;
  width: 100%;
`

export const ChartContainer = styled.div`
  display: flex;
  height: ${METRIC_CHART_HEIGHT}px;
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: 1.2rem;
`

export const CalculatedMetricContainer = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: center;
  padding-top: 0.4rem;
`

export const LockIcon = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  color: ${color('purple')};
`

export const Subtitle = styled.p`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const ToggleOption = styled.button<{
  active: boolean
  disabled: boolean
}>`
  border-radius: 0.8rem;
  font-size: 1.5rem;
  margin-right: 0.4rem;
  min-width: 3.2rem;
  height: 3.2rem;
  width: 3.2rem;
  align-self: flex-start;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
          cursor: not-allowed;

          &:hover {
            text-decoration: none;
          }
        `
      : css`
          &:hover {
            background-color: ${color('veryLightBlue', 0.2)};
            color: ${color('primaryBlue')};
          }
        `}

  ${(props) =>
    props.active &&
    css`
      border: 0.1rem solid ${color('primaryBlue')};
      background-color: ${color('veryLightBlue', 0.4)};
      color: ${color('primaryBlue')};
    `}
`

export const OptionsDropdown = styled(OptionsDropdownToStyle)`
  svg {
    font-size: 1.6rem;
  }
`
