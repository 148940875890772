/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useLayoutEffect, useRef, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { maxSize, size, BreakPointName } from 'utils/constants/breakpoint'

import { useResizeObserver } from './useResizeObserver'

export const Media = {
  // MAX SIZE
  MAX_XSS: maxSize.xss,
  MAX_XSM: maxSize.xsm,
  MAX_SM: maxSize.sm,
  MAX_MD: maxSize.md,
  MAX_LG: maxSize.lg,
  MAX_XL: maxSize.xl,
  MAX_XXL: maxSize.xxl,
  MAX_XXXL: maxSize.xxxl,
  // MIN SIZE
  MIN_XSS: size.xss,
  MIN_XSM: size.xsm,
  MIN_SM: size.sm,
  MIN_MD: size.md,
  MIN_LG: size.lg,
  MIN_XL: size.xl,
  MIN_XXL: size.xxl,

  maxWidth: (width) => `(max-width: ${width}px)`,

  /**
   * @deprecated Use MAX_XXL instead
   */
  BELOW_HiDPI: '(max-width: 1439px)',
  /**
   * @deprecated Use MAX_LG instead
   */
  TABLET: 'screen and (max-width: 1024px)',
  /**
   * @deprecated Use MAX_XL instead
   */
  SMALL_DESKTOP: 'screen and (max-width: 1280px)',
  /**
   * @deprecated Use MAX_MD instead
   */
  MOBILE: 'screen and (max-width: 768px)',
}

export function useMediaQuery(mediaQuery, initialMatch = false) {
  const [matches, setMatches] = useState({ matches: initialMatch })
  const firstRender = useRef(true)

  const resizeObserver = useResizeObserver()

  const setDebounced = debounce((queryValue) => {
    setMatches(queryValue)
  }, 200)

  useLayoutEffect(() => {
    const query = window.matchMedia(mediaQuery)

    if (firstRender.current) {
      setMatches(query)
      firstRender.current = false
    } else {
      setDebounced(query)
    }
  }, [resizeObserver])

  return matches
}

export const useScreenSizeBetween = (minQuery, maxQuery) => {
  const [matches, setMatches] = useState({ matches: false })
  const firstRender = useRef(true)

  const resizeObserver = useResizeObserver()

  const setDebounced = debounce((queryValue) => {
    setMatches(queryValue)
  }, 200)

  useLayoutEffect(() => {
    const mediaQuery = window.matchMedia(`${minQuery} and ${maxQuery}`)

    if (firstRender.current) {
      setMatches(mediaQuery)
      firstRender.current = false
    } else {
      setDebounced(mediaQuery)
    }
  }, [resizeObserver, minQuery, maxQuery])

  return matches
}

export const useCurrentBreakpoint = () => {
  const windowWidth = window.innerWidth

  const sortedQueries = Object.entries(size).sort((a, b) =>
    a[1].value > b[1].value ? 1 : -1
  )

  const breakpoint = useMemo(() => {
    for (let i = 0; i < sortedQueries.length; i++) {
      const matchMediaQuery = window.matchMedia(sortedQueries[i][1])

      if (matchMediaQuery.matches) {
        return sortedQueries[i][0]
      }
    }

    return 'xxs'
  }, [window.innerWidth])

  return breakpoint
}
