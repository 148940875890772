import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import AuthService from 'api/AuthService'
import { ReactComponent as CWLogo } from 'assets/images/logo-cw.svg'

import Copyright from 'components/Copyright'
import Form from 'components/SignIn/components/Form'
import Heading from 'components/Heading'
import Toast from 'components/Toast'

import authStyles from '../Auth.module.scss'
import styles from './EmailConfirmation.module.scss'

const EmailConfirmation = () => {
  const intl = useIntl()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (event) => {
    event.preventDefault()
    try {
      setIsLoading(true)
      await AuthService.sendSignUpConfirmationEmail(location.state.email)
      Toast.display(intl.messages['emailConfirmation.successMessage'])
    } catch (error) {
      Toast.display(intl.messages['emailConfirmation.error'], 'error')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={authStyles.container}>
      <CWLogo className={authStyles.logo} />
      <Form className={authStyles.form} onSubmit={onSubmit}>
        <Heading level="h1" className={authStyles.title}>
          <FormattedMessage id="emailConfirmation.title" />
        </Heading>
        <p className={authStyles.legend}>
          <b>
            <FormattedMessage id="emailConfirmation.legend" />
          </b>
        </p>
        <p className={authStyles.legend}>
          <FormattedMessage id="emailConfirmation.resendConfirmationLegend" />
        </p>
        <Form.Field
          name="email"
          className={styles.emailInput}
          value={location.state.email}
          disabled
        />
        <Form.Button
          full
          large
          primary
          rounded
          strong
          type="submit"
          disabled={isLoading}
        >
          <FormattedMessage id="emailConfirmation.sendEmail" />
        </Form.Button>
        <Link to="/sign-in" className={authStyles.backNavigation}>
          <FormattedMessage id="emailConfirmation.back" />
        </Link>
      </Form>
      <Copyright />
    </div>
  )
}

export default EmailConfirmation
