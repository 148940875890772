import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ReactFCWithChildren } from 'utils/types/common'
import { StyledTitle } from './FeedUpdateTitle.styles'

export interface FeedUpdateTitleProps {
  draft?: boolean
  hoverState?: boolean
  showStar?: boolean
  titleMaxWidth?: string
}

const FeedUpdateTitle: ReactFCWithChildren<FeedUpdateTitleProps> = ({
  children,
  showStar,
  draft,
  hoverState,
  titleMaxWidth,
}) => {
  return (
    <StyledTitle
      className="update-name fs-exclude"
      grayTitle={draft}
      hoverState={hoverState}
      maxWidth={titleMaxWidth}
    >
      {showStar && <FontAwesomeIcon icon={['fal', 'star']} />}
      {children}
    </StyledTitle>
  )
}

export default FeedUpdateTitle
