import { useQuery, useQueryClient } from '@tanstack/react-query'
import SettingsService from 'api/SettingsService'
import Toast from 'components/Toast'
import { Settings } from 'utils/types/settings'
import { useAuthentication } from './auth'
import { useDebouncedMutation } from './useDebounceMutation'

interface UseSettings {
  onUpdateSuccess?: () => void
  onUpdateError?: () => void
}

export const SETTINGS_KEY = 'settings'

const useSettings = ({ onUpdateSuccess, onUpdateError }: UseSettings = {}) => {
  const queryClient = useQueryClient()
  const { isAuthenticated } = useAuthentication()
  const { data: settings, isLoading } = useQuery<Settings>(
    [SETTINGS_KEY],
    SettingsService.getSettings,
    {
      onError: () => {
        Toast.displayIntl('userSettings.errorTryingToGetSettings', 'error')
      },
      enabled: isAuthenticated,
    }
  )

  const mutation = useDebouncedMutation(
    [SETTINGS_KEY],
    SettingsService.updateSettings,
    {
      onMutate: async (updatedSettings: Partial<Settings>) => {
        await queryClient.cancelQueries([SETTINGS_KEY])

        const previousSettings = queryClient.getQueryData<Settings>([
          SETTINGS_KEY,
        ])
        const newSettings = { ...previousSettings, ...updatedSettings }
        queryClient.setQueryData([SETTINGS_KEY], newSettings)

        return updatedSettings
      },
      onSuccess: (result: Settings) => {
        queryClient.setQueryData([SETTINGS_KEY], result)
        onUpdateSuccess?.()
      },
      onError: () => {
        queryClient.setQueryData([SETTINGS_KEY], settings)
        onUpdateError?.()
      },
    }
  )

  return {
    settings,
    isLoading,
    mutation,
  }
}

export default useSettings
