import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const ComposedChart = styled.div`
  font-size: 1.4rem;
`

export const Traveller = styled.div`
  position: absolute;
  bottom: -3rem;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.7rem;
  border: 0.2px solid ${color('veryLightBlue')};
  background: ${color('cloud')};
  width: 5.3rem;
  height: 1.1rem;
  left: 50%;
  transform: translateX(-50%);
  cursor: row-resize;
  user-select: none;

  @media ${maxSize.md} {
    display: none;
  }
`
