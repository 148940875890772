import type { IntlShape } from 'react-intl'
import { SortDirectionType } from 'utils/types/common'

import { HoldingsFilters, OrderByProps } from 'api/HoldingsService'

type QueryStatus = 'loading' | 'idle' | 'success' | 'error'

interface ShowNoResults {
  allCompanies: any
  hasActiveFilters: boolean
  allCompaniesStatus: QueryStatus
}

export const getShowNoResults = ({
  allCompanies,
  hasActiveFilters,
  allCompaniesStatus,
}: ShowNoResults): boolean => {
  return (
    !allCompanies?.pages?.[0]?.holdings?.length &&
    hasActiveFilters &&
    allCompaniesStatus !== 'loading'
  )
}

export const getLabelForSortCriteria = (
  intl: IntlShape,
  orderBy: OrderByProps,
  direction: SortDirectionType
): string => {
  if (direction === 'asc') {
    return {
      name: intl.formatMessage({
        id: 'companyList.nameSortAsc',
      }),
      last_update_at: intl.formatMessage({
        id: 'companyList.lastUpdateSort',
      }),

      portfolio_type: intl.formatMessage({
        id: 'companyList.portfolioTypeSortAsc',
      }),
    }[orderBy]
  }

  return {
    name: intl.formatMessage({
      id: 'companyList.nameSortDesc',
    }),

    last_update_at: intl.formatMessage({
      id: 'companyList.lastUpdateSort',
    }),

    portfolio_type: intl.formatMessage({
      id: 'companyList.portfolioTypeSortDesc',
    }),
  }[orderBy]
}

export const getParameters = (activeFilters) => {
  const filters: HoldingsFilters = {}
  const tagsName: string[] = []

  activeFilters.forEach((activeFilter) => {
    if (activeFilter?.dataSetName === 'name') {
      filters.name = activeFilter.value
    }

    if (activeFilter?.dataSetName === 'industry') {
      if (filters.industryId) {
        filters.industryId?.push(activeFilter.value)
      } else {
        filters.industryId = [activeFilter.value]
      }
    }

    if (activeFilter?.dataSetName === 'sector') {
      if (filters.sectorId) {
        filters.sectorId?.push(activeFilter.value)
      } else {
        filters.sectorId = [activeFilter.value]
      }
    }

    if (activeFilter?.dataSetName === 'country') {
      if (filters.countryId) {
        filters.countryId.push(activeFilter.value)
      } else {
        filters.countryId = [activeFilter.value]
      }
    }

    if (activeFilter?.dataSetName === 'province') {
      if (filters.legalProvinceId) {
        filters.legalProvinceId.push(activeFilter.value)
      } else {
        filters.legalProvinceId = [activeFilter.value]
      }
    }

    if (activeFilter?.dataSetName === 'legalStructure') {
      if (filters.legalStructureId) {
        filters.legalStructureId.push(activeFilter.value)
      } else {
        filters.legalStructureId = [activeFilter.value]
      }
    }

    if (activeFilter?.dataSetName === 'portfolio') {
      filters.holdingName = activeFilter.value
    }

    if (activeFilter.dataSetName === 'keyword') {
      filters.text = activeFilter.value
    }

    if (activeFilter.dataSetName === 'tag') {
      tagsName.push(activeFilter.value)
    }
  })

  return { filters, tagsName }
}
