import { InvestmentsFiltersObject } from 'containers/Investments/InvestmentsFilters/types'
import {
  ApiFilterEntity,
  Filter,
  InvestmentFilterNormalizer,
} from 'utils/functions/normalizers/investmentFilterNormalizer'
import axiosClient from './httpClient'
import { ApiIndexResponse, ApiShowResponse } from './types'

interface CreateNewFilterProps {
  name: string
  filtersObject: InvestmentsFiltersObject
}

interface EditFilterProps {
  id: string
  name: string
  filtersObject?: InvestmentsFiltersObject
}

interface InvestmentFilterPram {
  name: string
  search_params?: InvestmentsFiltersObject
}

export const InvestmentFiltersMapperForQuerying = {
  sectors: 'q[holding_sectors_id_in]',
  industries: 'q[holding_industries_id_in]',
  portfolios: 'q[portfoliable_id_in]',
  holdings: 'q[holding_id_in]',
  groupTags: 'q[holding_tags_id_in]',
} as const

export const InvestmentFiltersMapperForCreatingOrEditing = {
  sectors: 'holding_sectors_id_in',
  industries: 'holding_industries_id_in',
  portfolios: 'portfoliable_id_in',
  holdings: 'holding_id_in',
  groupTags: 'holding_tags_id_in',
} as const

export const getNewFilterParams = (filters: InvestmentsFiltersObject) => {
  const queryParams = {}

  Object.entries(filters).forEach(([key, values]) => {
    if (values && InvestmentFiltersMapperForCreatingOrEditing[key]) {
      queryParams[InvestmentFiltersMapperForCreatingOrEditing[key]] = values
    }
  })

  return queryParams
}

class InvestmentsFiltersService {
  static async getFilters() {
    const { data } = await axiosClient().get<ApiIndexResponse<ApiFilterEntity>>(
      '/filters'
    )
    const { entities, result } = data

    const investmentFilterData: Filter[] = result.map((id) =>
      InvestmentFilterNormalizer.populateFilterData(
        entities,
        entities.investmentFilters[id]
      )
    )

    return investmentFilterData
  }

  static async getFilterById(id: string) {
    const { data } = await axiosClient().get<ApiShowResponse<ApiFilterEntity>>(
      `/filters/${id}`
    )

    const { entities, result } = data

    const filter: Filter = InvestmentFilterNormalizer.populateFilterData(
      entities,
      entities.investmentFilters[result]
    )

    return filter
  }

  static async createNewFilter({ name, filtersObject }: CreateNewFilterProps) {
    const params = getNewFilterParams(filtersObject)

    const { data } = await axiosClient().post<ApiShowResponse<ApiFilterEntity>>(
      '/filters',
      {
        investment_filter: {
          name,
          search_params: params,
        },
      }
    )

    const { entities, result } = data

    const newFilter: Filter = InvestmentFilterNormalizer.populateFilterData(
      entities,
      entities.investmentFilters[result]
    )

    return newFilter
  }

  static editFilterById({ id, name, filtersObject }: EditFilterProps) {
    let params = {}
    const investment_filter: InvestmentFilterPram = {
      name,
    }

    if (filtersObject) {
      params = getNewFilterParams(filtersObject)
      investment_filter.search_params = params
    }

    return axiosClient().patch(`/filters/${id}`, {
      investment_filter,
    })
  }

  static async deleteFilterById(id: string) {
    return axiosClient().delete(`/filters/${id}`)
  }
}

export default InvestmentsFiltersService
