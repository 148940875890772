import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
`

export const SkeletonInvitationsMetricsCardWrapper = styled.div`
  display: flex;
  padding: 0 1.8rem;
`
