import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const ItemWrapper = styled.div<{
  noMarginBottom?: boolean
}>`
  align-items: center;
  display: flex;
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? 0 : '1.6rem')};
  padding: 0 3.2rem;
`

export const CheckIcon = styled(FontAwesomeIcon)`
  background-color: ${color('green')};
  border-radius: 100%;
  border: 0.2rem solid ${color('green')};
  color: ${color('white')};
  margin-right: 1.2rem;
  padding: 0.4rem;
  font-size: 2rem;
`
