/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip'
import CompanyStatus from 'components/CompanyStatus'
import Avatar from 'components/Avatar'
import AddToPortfolioButton from 'components/AddToPortfolioButton'

import { isEnterCode } from 'utils/functions/keyboardEvents'
import { getInitials } from 'utils/functions/user'
import { getFilteredPortfolios } from 'utils/functions/portfolios'

import companyLogoPlaceholder from 'assets/images/missing.png'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { isFundHolding, getHoldingImage } from 'utils/types/company'
import styles from './HoldingRowCard.module.scss'

const cardVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 50, velocity: -100 },
    },
  },
  hidden: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 50 },
    },
  },
}

const CompanyRowCard = ({
  holding,
  portfolios,
  onCardClick,
  hideAddToPortfolioButton,
  isFundPortfolio,
}) => {
  const intl = useIntl()

  const hasFundManagerInfo = !!holding.group

  const handleKeyUp = (event) => {
    if (isEnterCode(event)) {
      onCardClick()
    }
  }

  const companyIndustries = holding?.industries
    ?.map((industry) => industry.name)
    ?.join(', ')

  const companyLocation =
    holding?.primaryLocation?.formattedAddress ||
    intl.messages['companyProfile.noLocation']

  return (
    <motion.li key={holding.name} variants={cardVariants}>
      <div
        className={styles.companyWrapperRow}
        onClick={onCardClick}
        onKeyUp={handleKeyUp}
        type="button"
        role="button"
      >
        {!hideAddToPortfolioButton && (
          <div className={styles.btnAddToPortfolio}>
            <AddToPortfolioButton
              holding={holding}
              holdingName={holding.name}
              holdingId={holding.id}
              holdingPortfolios={[
                ...(holding.holdingPortfolios ?? []),
                ...(holding.holdingFundPortfolios ?? []),
              ]}
              isFundHolding={isFundHolding(holding)}
            />
          </div>
        )}
        <div className={styles.logoWrapper}>
          <Avatar
            image={getHoldingImage(holding)}
            initials={getInitials(holding.name)}
            grayBorder={false}
            avatarStyle="avatarCircleFundLogo"
          />
        </div>

        <div className={styles.companyInfo}>
          <div className={`${styles.companyRowName} holding-name`}>
            {holding.name}
          </div>

          <div>
            {companyLocation && !isFundPortfolio && (
              <p className={classNames(styles.companyLocation, styles.label)}>
                <FontAwesomeIcon icon={['far', 'map-marker-alt']} />
                {companyLocation}
              </p>
            )}

            {isFundPortfolio && hasFundManagerInfo && (
              <div className={classNames(styles.fundManagerName, styles.label)}>
                <FontAwesomeIcon icon={['far', 'user-tie']} />
                {holding.group.name}
              </div>
            )}
            <div className={styles.industries} title={companyIndustries}>
              <span className={classNames(styles.industriesText, styles.label)}>
                {companyIndustries}
              </span>
            </div>
            <CompanyStatus
              company={holding}
              className={
                holding.qualityAssured && holding.verified
                  ? styles.lastUpdate
                  : styles.companyLegend
              }
              displayLastUpdate
              isFundPortfolio={isFundPortfolio}
            />
          </div>
        </div>
      </div>
    </motion.li>
  )
}

CompanyRowCard.defaultProps = {
  onCardClick: () => {},
  portfolios: [],
  isFundPortfolio: false,
  hideAddToPortfolioButton: false,
}

CompanyRowCard.propTypes = {
  holding: PropTypes.object.isRequired,
  portfolios: PropTypes.array,
  onCardClick: PropTypes.func,
  isFundPortfolio: PropTypes.bool,
  hideAddToPortfolioButton: PropTypes.bool,
}

export default CompanyRowCard
