import { FormattedMessage } from 'react-intl'
import type { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from 'utils/theme'
import {
  ArrowDownOptionsWrapper,
  Button,
  ButtonLabelWrapper,
  OptionsContainer,
  OptionsWrapper,
  Option,
} from './CloseButton.styles'
import useCloseButton from './useCloseButton'

type MenuOption = {
  icon: IconName
  label: string
  color: string
  onClick: () => void
}

interface Props {
  goBack: () => void
  toggleDiscardDraftConfirmationModal: () => void
  toggleDeleteConfirmationModal: () => void
  label?: string
  disabled: boolean
  isSubmitting: boolean
  hasDraftMode: boolean
}

const CloseButton = ({
  disabled,
  label,
  isSubmitting,
  hasDraftMode,
  goBack,
  toggleDeleteConfirmationModal,
  toggleDiscardDraftConfirmationModal,
}: Props) => {
  const { setShowOptions, optionsRef, showOptions } = useCloseButton()

  const discardDraftOption: MenuOption = {
    icon: 'times-circle',
    label: 'Discard Draft',
    color: theme.colors.darkBlue,
    onClick: () => toggleDiscardDraftConfirmationModal(),
  }

  const MENU_OPTIONS: MenuOption[] = [
    {
      icon: 'trash-alt',
      label: 'Delete Update',
      color: theme.colors.red,
      onClick: () => toggleDeleteConfirmationModal(),
    },
  ]

  if (hasDraftMode) {
    MENU_OPTIONS.unshift(discardDraftOption)
  }

  return (
    <OptionsWrapper>
      <Button
        transparent
        secondary
        onClick={goBack}
        disabled={disabled}
        data-testid="close-button"
        isLoading={isSubmitting}
      >
        <ButtonLabelWrapper>
          {label ?? <FormattedMessage id="buttons.close" />}

          <ArrowDownOptionsWrapper
            onClick={(e) => {
              setShowOptions(!showOptions)
              e.stopPropagation()
            }}
          >
            <FontAwesomeIcon icon={['far', 'chevron-down']} />
          </ArrowDownOptionsWrapper>
        </ButtonLabelWrapper>
      </Button>

      <OptionsContainer ref={optionsRef} showOptions={showOptions}>
        {MENU_OPTIONS.map((option) => (
          <Option
            key={option.label}
            fontColor={option.color}
            onClick={option.onClick}
          >
            <FontAwesomeIcon icon={['fal', option.icon]} />
            {option.label}
          </Option>
        ))}
      </OptionsContainer>
    </OptionsWrapper>
  )
}

export default CloseButton
