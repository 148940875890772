import { useLocation } from 'react-router-dom'
import { getCurrentGroupId } from 'selectors/auth'
import { useInvestorQuery } from 'utils/hooks/investorManagement/useInvestorsQuery'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import useParseCurrentUrl from '../useParseCurrentUrl'

interface InvestorInformationProps {
  investorIdFromUrlState?: string
  fetchInvestor?: boolean
}

const useInvestorInformation = ({
  investorIdFromUrlState,
  fetchInvestor,
}: InvestorInformationProps) => {
  const location = useLocation()
  const investorGroupId = useAppSelector(getCurrentGroupId)
  const { investorId } = useParseCurrentUrl()

  const getInvestorFromUrl = location.pathname.includes('investors')

  const useInvestorIdOrInvestorGroupId = getInvestorFromUrl
    ? investorId
    : investorGroupId

  const investorIdToUse =
    investorIdFromUrlState ?? useInvestorIdOrInvestorGroupId

  const { data: investor } = useInvestorQuery(investorIdToUse, {
    enabled: !!(investorIdToUse && fetchInvestor),
  })

  const getInvestorAvatar = () => {
    if (!investor?.logo) return null

    if (investor.logo?.url.indexOf('missing.png') >= 0) {
      return (
        <Avatar
          initials={getInitials(investor?.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }

    return <Avatar image={investor.logo.url} avatarStyle="avatarCircleXS" />
  }

  return {
    investor,
    getInvestorAvatar,
  }
}

export default useInvestorInformation
