import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import theme from 'utils/theme'

const data = [
  {
    name: 'Skeleton',
    valueOne: 200,
  },
  {
    name: 'Skeleton',
    valueTwo: 400,
  },
]

const CustomizedAxisTick = ({ x, y }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={10} />
      <text x={0} y={10} dy={15} />
    </g>
  )
}

const SkeletonBarChart = () => {
  return (
    <ResponsiveContainer minHeight={200} width="100%">
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          axisLine={false}
          tickLine={false}
          tick={CustomizedAxisTick}
          height={40}
          fill={theme.colors.defaultSkeletonShine2}
          display="none"
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          allowDecimals={false}
          fill={theme.colors.defaultSkeletonShine2}
          width={35}
          fontSize={12}
          tickCount={5}
          tickFormatter={() => ''}
        />

        <Bar
          key="valueOne"
          dataKey="valueOne"
          stackId="unique"
          fill={theme.colors.defaultSkeletonShine2}
          barSize={10}
        />

        <Bar
          key="valueTwo"
          dataKey="valueTwo"
          stackId="unique"
          fill={theme.colors.defaultSkeletonShine2}
          barSize={10}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default SkeletonBarChart
