export const DASHBOARD = '/dashboard'

export const DASHBOARD_ALL_UPDATES = `${DASHBOARD}/all-updates`

export const DASHBOARD_UPDATES = `${DASHBOARD}/updates`
export const DASHBOARD_METRICS = `${DASHBOARD}/metrics`
export const DASHBOARD_UPDATES_EDIT = `${DASHBOARD_UPDATES}/edit`
export const DASHBOARD_METRICS_EDIT = `${DASHBOARD_METRICS}/edit`

export const DASHBOARD_SOME_TAB_EDIT = '/dashboard/:someTab/edit'

export const DASHBOARD_METRICS_ID = `${DASHBOARD_METRICS}/:metricId`
export const DASHBOARD_METRICS_ID_ADD_VALUE = `${DASHBOARD_METRICS_ID}/add-value`
export const DASHBOARD_METRICS_ID_SET_MILESTONE = `${DASHBOARD_METRICS_ID}/set-milestone`
export const DASHBOARD_METRICS_ID_EDIT = `${DASHBOARD_METRICS_ID}/edit`
export const DASHBOARD_METRICS_UPDATE_ALL = `${DASHBOARD_METRICS}/update-all`

export const COMPANIES_ALL_UPDATES = '/companies/:id/all-updates'

export const CREATE_GROUP = '/create-group'

export const DASHBOARD_ROUTES = [
  DASHBOARD,
  DASHBOARD_UPDATES,
  DASHBOARD_METRICS,
  DASHBOARD_UPDATES_EDIT,
  DASHBOARD_METRICS_EDIT,
  DASHBOARD_METRICS_ID,
  DASHBOARD_METRICS_ID_ADD_VALUE,
  DASHBOARD_METRICS_ID_SET_MILESTONE,
  DASHBOARD_METRICS_ID_EDIT,
]

export const INDIVIDUAL_ROUTES = [
  DASHBOARD_ALL_UPDATES,
  DASHBOARD_SOME_TAB_EDIT,
  DASHBOARD_METRICS_UPDATE_ALL,
  COMPANIES_ALL_UPDATES,
]

const founderRoutes = [...DASHBOARD_ROUTES, ...INDIVIDUAL_ROUTES]

export default founderRoutes
