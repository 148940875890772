import { Scroll } from 'App.styles'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DEFAULT_DRAWER_WIDTH } from 'components/Drawer/Drawer'

export const MetricTitle = styled.h2<{ trunc?: boolean }>`
  color: #101527;
  font-size: 1.8rem;
  display: flex;
  align-items: flex-start;

  ${(props) =>
    props.trunc &&
    css`
      max-width: 20rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  > svg {
    font-size: 1.4rem;
    margin-right: 0.8rem;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;
  }
`

export const Frequency = styled.span`
  text-transform: capitalize;
  color: ${color('lightGray')};
  font-size: 1.8rem;
  padding-left: 0.5rem;

  &::before {
    content: '(';
  }
  &::after {
    content: ')';
  }
`

export const CompanyChip = styled.div`
  display: flex;
  align-items: center;
`

export const CompanyImage = styled.img`
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 100%;
  border: 1px solid #e9e9e9;
`

export const CompanyName = styled.p`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 1rem;
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  width: 80%;
`

export const DateRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const DateLabel = styled.div`
  display: flex;
  justify-content: space-between;
  color: #101527;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
`

export const DateValue = styled.div`
  display: flex;
  justify-content: space-between;
  color: #787e8c;
  font-size: 1.4rem;
`

export const TableWrapper = styled.div`
  border-radius: 1rem;
  border: solid 0.1rem #e9e9e9;

  div[class^='Table_tableWrapper'] {
    box-shadow: none;
    border-radius: 1rem;
    border: 1px solid #e9e9e9;
  }

  div[class^='Head_headCell'] {
    color: #787e8c;
    font-weight: 800;
    > button {
      text-transform: uppercase;
    }
  }

  div[class^='Head_headWrapper'],
  div[class^='Row_rowWrapper'] {
    padding: 1.8rem 2.2rem;
  }

  span[class^='GrowthIndicator'] {
    font-size: 1.2rem;
  }
`
export const MetricSectionHeader = styled.header`
  height: 4.7rem;
  padding: 3.6rem 0 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const MetricSectionTitle = styled.div`
  color: ${color('primaryDarkBlue')};
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
`

export const LoaderWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MilestoneIndicatorWrapper = styled.div`
  margin-top: 1.3rem;
  width: 100%;
`

export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  #founder-data {
    width: 20rem;
  }
`

export const CTAWrapper = styled.div`
  display: flex;
  gap: 0.8rem;

  > div {
    border: 0.1rem solid #e9e9e9;
    border-radius: 10rem;
  }
`

export const TabsContainer = styled.div<{ isArchivedValuesExpanded?: boolean }>`
  h1 {
    color: ${color('lightGray')};
  }

  #data-points-wrapper {
    margin-top: -2rem;
    padding-top: 2.5rem;

    ${(props) =>
      props.isArchivedValuesExpanded
        ? css`
            padding-bottom: 300px;
          `
        : css`
            padding-bottom: 50px;
          `}
  }
`

export const SubheaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 9999;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  top: -3.1rem;
`

export const CalculatedMetricContainer = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
`
export const CalculatedMetricText = styled.span`
  font-size: 1.1rem;
  color: ${color('lightGray')};
`
export const LockIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${color('lightGray')};
`

export const Subtitle = styled.p<{ mode?: string }>`
  font-size: 1.4rem;
  font-weight: 700;
  color: ${(props) =>
    props.mode === 'light'
      ? color('lightGray')(props)
      : color('darkBlue')(props)};
  text-transform: uppercase;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const CollapseHeader = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  position: fixed;
  bottom: 0;
  flex-direction: column;
  border-top: 1px solid ${color('veryLightGray')};
  width: ${DEFAULT_DRAWER_WIDTH};
  background: ${color('white')};
  margin-left: -2.4rem;
  padding: 0 2.4rem;

  ${(props) =>
    props.isExpanded
      ? css`
          height: 300px;
          overflow: overlay;
        `
      : css`
          height: 64px;
          overflow: hidden;

          ${TableWrapper} {
            margin-top: 1.6rem;
          }
        `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 9999;
  padding: 1.4rem 0rem;

  > div {
    height: 4rem;
  }
`

export const ArchivedValuesTitle = styled.div`
  color: ${color('lightGray')};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.4rem;

  > svg {
    font-size: 1.4rem;
    margin-right: 0.8rem;
  }
`

export const TitleWrapper = styled.div<{ isExpanded?: boolean }>`
  display: flex;
  align-items: center;

  > svg {
    font-size: 2rem;
    margin-right: 2rem;
    margin-left: 0.5rem;
    color: ${color('darkBlue')};

    ${(props) =>
      props.isExpanded
        ? css`
            transform: rotate(0deg);
          `
        : css`
            transform: rotate(-90deg);
          `}
  }
`

export const ReceiverGroupsWrapper = styled.div`
  width: 60%;
`

export const SendRequestContainer = styled.div`
  display: flex;
  align-items: center;

  > svg {
    font-size: 1.5rem;
    color: ${color('lightGray')};
    margin-right: 0.5rem;
  }

  button {
    font-size: 1.2rem;
    padding: 0;
    margin-left: 0.8rem;
    margin-top: 0.2rem;
  }
`
export const FounderDataText = styled.div`
  color: ${color('darkGray')};
  font-size: 1.2rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const RequestSent = styled.p`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  font-weight: 700;
`

export const WaitingForResponse = styled.p`
  display: flex;
  flex-direction: column;
  color: ${color('lightGray')};
  font-size: 1.2rem;
`

export const WaitingResponseContainer = styled.div`
  display: flex;
  align-items: flex-start;

  > svg {
    font-size: 1.5rem;
    color: ${color('lightGray')};
    margin-right: 0.5rem;
  }
`

export const TooltipContainer = styled.div<{ width?: string }>`
  div[data-id='tooltip'] {
    ${(props) =>
      props.width
        ? css`
            width: ${props.width} !important;
          `
        : ''}
  }
`

export const LogsContainer = styled.div``

export const CompanyChipWrapper = styled.div`
  margin-top: 0.8rem;
  margin-bottom: 1rem;
`

export const ProcessingTitle = styled.div`
  color: ${color('purple')};
  display: flex;
  gap: 1rem;
  font-size: 1.3rem;
`
export const GeneratingMetricsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  border-radius: 1rem;
  border: solid 0.1px ${color('veryLightGray')};
  margin: 2rem;
`

export const SubtitleGeneratingMetrics = styled.p`
  color: ${color('lightGray')};
  font-size: 1.1rem;
`

export const AreaChartWrapper = styled.div`
  margin-right: 0.3rem;
`

export const MetricValue = styled.div<{
  fontWeight: number
  showInOneColumn: boolean
  color?: string
}>`
  display: flex;

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}

  ${(props) =>
    props.showInOneColumn
      ? css`
          justify-content: center;
          flex-direction: column;
        `
      : css`
          align-items: center;
          flex-wrap: wrap;
        `}
    

  font-weight: ${(props) => props.fontWeight}
`

export const GrowthIndicatorWrapper = styled.div`
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
`

export const Scroller = styled.div<{ isFounder: boolean }>`
  ${Scroll}

  height: ${(props) =>
    props.isFounder ? 'calc(100vh - 26.4rem)' : 'calc(100vh - 27.4rem)'};
`

export const Padding = styled.div<{ padding: string }>`
  padding: ${(props) => (props.padding ? props.padding : '0')};
`
export const PortfolioIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color('primaryBlue')};
  border-radius: 2.4rem;
  color: ${color('white')};
  font-size: 1.1rem;
  height: 2rem;
  width: 2rem;
`
