/* eslint-disable react-hooks/exhaustive-deps, react/no-array-index-key, consistent-return */
import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQueryClient } from '@tanstack/react-query'
import Button from 'ui/Button'
import { GridType } from 'utils/constants/gridType'

import { Helmet } from 'components/Helmet'
import AddHoldingModal from 'components/AddHoldingModal/AddHoldingModal'
import GridSelector from 'components/GridSelector'
import HoldingsList from 'components/HoldingsList'
import InfiniteScrollRef from 'components/InfiniteScrollRef'
import SortBy from 'components/SortBy'
import Tooltip from 'components/Tooltip'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import ZeroStateLinks from 'utils/constants/zerostatelinks'

import CWLoader from 'components/CWLoader'
import { HoldingCard, HoldingRowCard } from 'components/HoldingCard'
import useHoldingFilters from 'components/HoldingFilters/useHoldingFilters'
import { SortCriteria } from 'components/SortBy/SortBy'
import { InputContainer, Title } from 'components/ViewHeader/ViewHeader.styles'
import IconButton from 'ui/IconButton'
import Input from 'ui/Input'
import { SortDirection } from 'utils/constants/sortDirection'
import layout from 'utils/constants/zerostatelayout'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import { Holding, HoldingType } from 'utils/types/company'
import { AssistanceCardLarge } from 'components/OnboardingModal/components/Assistance'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { AssistanceCardType } from 'utils/types/onboardingModal'
import { holdingsKeys } from 'utils/queries/holdings'
import GroupService from 'api/GroupService'
import { setUserGroups } from 'features/authSlice'
import FiltersDrawer from './FiltersDrawer'

import { getLabelForSortCriteria } from './helpers'
import { SortByOptions, useCompanyIndex } from './useCompanyIndex'

import * as Styles from './Holdings.styles'

const HoldingIndex = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()

  const {
    endOfPageRef,
    intl,
    companies,
    isFetchingNextPage,
    currentFilters,
    gridType,
    isLoading,
    isModalOpen,
    showZeroState,
    showNoResults,
    showCompanyList,
    onChangeGridType,
    onDismissMessage,
    search,
    debouncedSearch,
    onChangeSearch,
    setCurrentFilters,
    setIsModalOpen,
    onChangeSortBy,
    isHoldingFilterDrawerOpen,
    onToggleFiltersDrawer,
    onClearFilters,
    isMobile,
  } = useCompanyIndex()

  const { isFilterApplied, onOpenDrawerFiltersHandler } = useHoldingFilters({
    currentFilters,
    onChangeFilters: setCurrentFilters,
    onToggleFiltersDrawer,
  })

  const isInvestor = useAppSelector(isActingAsInvestorGroup)

  const { handleOnHoldingClick } = useOnClickRedirect()

  const sortCriteria: SortCriteria[] = [
    {
      id: `${SortByOptions.NAME}_${SortDirection.ASC}`,
      field: 'name',
      direction: SortDirection.ASC,
      label: getLabelForSortCriteria(intl, 'name', SortDirection.ASC),
    },
    {
      id: `${SortByOptions.NAME}_${SortDirection.DESC}`,
      field: 'name',
      direction: SortDirection.DESC,
      label: getLabelForSortCriteria(intl, 'name', SortDirection.DESC),
    },
  ]

  const displayHoldingCard = (holding: Holding) => {
    const isFundPortfolio = holding.holdingType === HoldingType.FUND

    return gridType === GridType.GRID ? (
      // TODO: Change to CompanyList.GridItem once CompanyCard and CompanyRowCard are migrated to TS
      <HoldingCard
        isFundPortfolio={isFundPortfolio}
        key={holding.id}
        holding={holding}
        onCardClick={() => handleOnHoldingClick(holding)}
        hideAddToPortfolioButton={isInvestor}
      />
    ) : (
      // TODO: Change to CompanyList.RowItem once CompanyCard and CompanyRowCard are migrated to TS
      <HoldingRowCard
        isFundPortfolio={isFundPortfolio}
        key={holding.id}
        holding={holding}
        onCardClick={() => handleOnHoldingClick(holding)}
        hideAddToPortfolioButton={isInvestor}
      />
    )
  }

  const metaTitle = () =>
    intl.formatMessage({
      id: isModalOpen ? 'metaTitles.addNew' : 'metaTitles.discover',
    })

  const refreshGroupHoldings = useCallback(async () => {
    // refreshing the group holdings so the private companies are displayed as Private
    const userGroups = await GroupService.fetchUserGroups()

    if (userGroups.length > 0) {
      dispatch(setUserGroups(userGroups))
    }

    queryClient.invalidateQueries(holdingsKeys.all(currentFilters, search))
  }, [])

  return (
    <>
      <Helmet>
        <title>{metaTitle()}</title>
      </Helmet>

      {!isInvestor && (
        <Styles.HeaderContainer>
          <Tooltip
            id="btn-add-company"
            text={
              <>
                <p>
                  {intl.formatMessage({ id: 'holdings.addHoldingTooltip' })}
                </p>
                <p>{intl.formatMessage({ id: 'holdings.addHoldingToCW' })}</p>
              </>
            }
            place="right"
            topOffset={-10}
            delayShow={500}
          >
            <Button
              id="btn-add-company"
              uppercase
              primary
              add
              onClick={() => {
                setIsModalOpen(true)
              }}
            >
              {intl.formatMessage({
                id: isMobile ? 'holdings.addHolding' : 'holdings.addNew',
              })}
            </Button>
          </Tooltip>

          <Styles.SearchContainer>
            <InputContainer>
              <Input
                placeholder={intl.formatMessage({
                  id: 'general.searchEllipsis',
                })}
                value={search}
                onChange={onChangeSearch}
                icon={['far', 'search']}
                iconFontSize="1.5rem"
                isCleanable
              />
            </InputContainer>
          </Styles.SearchContainer>
        </Styles.HeaderContainer>
      )}

      <Styles.TitleContainer paddingTop={isInvestor ? '0rem' : '2rem'}>
        <Title>
          {intl.formatMessage({
            id: isInvestor
              ? 'holdings.holdings'
              : 'holdings.exploreOpportunities',
          })}
        </Title>

        <Styles.Row>
          {!isInvestor && (
            <>
              <Styles.MobileFilterContainer>
                <IconButton
                  onClick={onOpenDrawerFiltersHandler}
                  isActive={isFilterApplied}
                  buttonType="square"
                  icon="filter"
                  border={isFilterApplied}
                  iconFontSize="1.4rem"
                />
              </Styles.MobileFilterContainer>
              <div id="filter-button-portal" />
            </>
          )}
          <SortBy
            sortCriteria={sortCriteria}
            onChange={onChangeSortBy}
            initialSortBy={{
              id: `${currentFilters.orderBy}_${currentFilters.direction}`,
              field: currentFilters.orderBy!,
              direction: currentFilters.direction!,
              label: getLabelForSortCriteria(
                intl,
                currentFilters.orderBy!,
                currentFilters.direction!
              ),
            }}
            showSortDirectionToggle={false}
            growOptionItems
            containerPadding="0rem"
            alignDropdownContent="right"
            mobileEnabled
          />

          {isInvestor ? (
            <Styles.SearchContainer>
              <InputContainer>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'general.searchEllipsis',
                  })}
                  value={search}
                  onChange={onChangeSearch}
                  icon={['far', 'search']}
                  iconFontSize="1.5rem"
                  isCleanable
                />
              </InputContainer>
            </Styles.SearchContainer>
          ) : (
            <Styles.DesktopFilterContainer>
              <Button
                onClick={onOpenDrawerFiltersHandler}
                link
                icon={['fal', 'filter']}
                className="filter-button"
                active={isFilterApplied}
              >
                <FormattedMessage id="companyList.filters" />
              </Button>
            </Styles.DesktopFilterContainer>
          )}

          <Styles.GridSelectorContainer>
            <GridSelector
              initialGridType={gridType}
              onChange={onChangeGridType}
            />
          </Styles.GridSelectorContainer>
        </Styles.Row>
      </Styles.TitleContainer>

      <Styles.SearchContainerMobile>
        <Input
          placeholder={intl.formatMessage({ id: 'general.searchEllipsis' })}
          value={search}
          onChange={onChangeSearch}
          icon={['far', 'search']}
          iconFontSize="1.5rem"
        />
      </Styles.SearchContainerMobile>

      {!isInvestor && (
        <FiltersDrawer
          isOpen={isHoldingFilterDrawerOpen}
          onClose={onToggleFiltersDrawer}
          currentFilters={currentFilters}
          onChangeFilters={setCurrentFilters}
        />
      )}

      {isLoading ? (
        <Styles.InitialLoaderWrapper>
          <CWLoader
            text={intl.formatMessage({
              id: 'holdings.loadingHoldings',
            })}
          />
        </Styles.InitialLoaderWrapper>
      ) : (
        <>
          {!!showZeroState && !isInvestor && (
            <Styles.ZeroStateContainer>
              <ZeroState
                className="holdings-zero-state"
                padding="2.5rem"
                type={ZeroStateType.HOLDINGS}
                isChatWithSupportEnabled
                linkToGuide={ZeroStateLinks.ADD_FIRST_COMPANY}
                onDismissMessage={onDismissMessage}
                layout={layout.HORIZONTAL}
                isNeedHelpEnabled={false}
              />
            </Styles.ZeroStateContainer>
          )}

          {!!showCompanyList && !showNoResults && (
            <HoldingsList
              key={
                companies?.pages?.length
                  ? `notEmpty_${gridType}`
                  : `empty_${gridType}`
              }
              gridType={gridType}
            >
              {companies?.pages
                ?.map((page) => {
                  return page?.holdings
                })
                .flat()
                .map(displayHoldingCard)}
            </HoldingsList>
          )}

          {isFetchingNextPage && (
            <Styles.LoaderWrapper>
              <CWLoader
                text={intl.formatMessage({
                  id: 'holdings.loadingMoreHoldings',
                })}
                logoSize="50px"
              />
            </Styles.LoaderWrapper>
          )}

          {!!showNoResults && (
            <Styles.NoResults>
              {isFilterApplied ? (
                <ZeroState
                  padding="5rem 0 0 0"
                  type={ZeroStateType.NO_HOLDINGS_FOUND}
                  maxWidth="50rem"
                  zeroStateLink
                  callToAction={onClearFilters}
                  callToActionText={intl.formatMessage({
                    id: 'zeroState.no_holdings_found.clear_filters',
                  })}
                />
              ) : (
                <ZeroState
                  padding="5rem 0 0 0"
                  type={ZeroStateType.NO_HOLDINGS_RESULTS}
                  maxWidth="50rem"
                  intlValues={{ search: debouncedSearch }}
                />
              )}
            </Styles.NoResults>
          )}
        </>
      )}

      <InfiniteScrollRef ref={endOfPageRef} />

      <AddHoldingModal
        isModalOpen={isModalOpen}
        onHideModal={() => setIsModalOpen(false)}
        onCreateNewHoldings={refreshGroupHoldings}
      />
      <AssistanceCardLarge type={AssistanceCardType.HOLDING} />
    </>
  )
}

export default HoldingIndex
