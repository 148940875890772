import React from 'react'
import { StyledListRow } from './ListRow.styles'

interface ListRowProps {
  children: React.ReactNode
  isOpen: boolean
  className?: string
  onClick?: (event: React.MouseEvent) => void
}

const ListRow: React.FC<ListRowProps> = ({
  children,
  isOpen,
  className,
  onClick,
}) => {
  return (
    <StyledListRow className={className} isOpen={isOpen} onClick={onClick}>
      {children}
    </StyledListRow>
  )
}

export default ListRow
