import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

interface CommonProps {
  xSmall?: boolean
  small?: boolean
  large?: boolean
  hasBorder?: boolean
}

const commonStyles = (props) => css`
  border-radius: 100%;
  width: 4.8rem;
  height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props.hasBorder &&
  css`
    border: 0.2rem solid ${color('darkGray', 0.5)};
  `}

  ${props.large &&
  css`
    width: 6.8rem;
    height: 6.8rem;
  `}

  ${props.small &&
  css`
    width: 3.2rem;
    height: 3.2rem;
  `}
  
  ${props.xSmall &&
  css`
    width: 2.4rem;
    height: 2.4rem;
  `}
`

export const IconWrapper = styled.div<CommonProps>`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84%;
`

export const ImageIconWrapper = styled.div<
  CommonProps & {
    usePrimaryColor?: boolean
  }
>`
  ${commonStyles}
  background: ${({ usePrimaryColor }) =>
    usePrimaryColor ? color('primaryBlue') : color('red')};

  svg {
    color: ${color('white')};
  }
`

export const PDFIconWrapper = styled.div<CommonProps>`
  ${commonStyles}

  svg {
    color: ${color('red')};
  }
`
export const UnknownIconWrapper = styled.div<CommonProps>`
  ${commonStyles}

  svg {
    color: ${color('lightGray')};
  }
`
export const ZIPIconWrapper = styled.div<CommonProps>`
  ${commonStyles}

  svg {
    color: ${color('lightGray')};
  }
`
export const DocIconWrapper = styled.div<CommonProps>`
  ${commonStyles}

  svg {
    color: ${color('primaryBlue')};
  }
`

export const EmailContentIconWrapper = styled.div<CommonProps>`
  ${commonStyles}

  svg {
    color: ${color('primaryBlue')};
  }
`

export const WavIconWrapper = styled.div<CommonProps>`
  ${commonStyles}

  svg {
    color: ${color('yellow')};
  }
`

export const VideoIconWrapper = styled.div<CommonProps>`
  ${commonStyles}

  svg {
    color: ${color('yellow')};
  }
`

const ImageStyles = css`
  width: 100%;
  height: 10.2rem;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  background-size: cover;
  object-fit: cover;
`

export const BackgroundImage = styled.div<{
  src: string
}>`
  ${ImageStyles}
  background-image: url(${(props) => props.src});
`

export const Image = styled.img<{
  isLoading: boolean
  isLarge?: boolean
}>`
  ${ImageStyles}

  opacity: ${(props) => (props.isLoading ? 0 : 1)};

  ${({ isLarge }) => isLarge && 'height: 17.4rem;'}
`

export const LoaderWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 10.2rem;
`
