import { assertUnreachable } from 'utils/functions/utils'
import { InvestorLogChangeSetFields } from '../logs-builders/InvestorLogsBuilder'
import { ChangeSetFieldsType } from '../logs-builders/LogsBuilder'
import { BackendEventsMapper } from './BackendEventsMapper'
import { InvestorEventsMapper } from './InvestorEventsMapper'
import { PortfolioEventsMapper } from './PortfolioEventsMapper'
import { LogType } from '../types'
import { InvestmentVehicleEventsMapper } from './InvestmentVehicleEventsMapper'
import { AccountingUpdateEventsMapper } from './AccountingUpdateEventMapper'
import { UpdateEventsMapper } from './UpdateEventMapper'

export class BackendEventsMapperFactory {
  static createBackendEventsMapper<T extends ChangeSetFieldsType>(
    logType: LogType,
    factoryParams: any
  ): BackendEventsMapper<T> {
    switch (logType) {
      case LogType.INVESTORS:
        return new InvestorEventsMapper<InvestorLogChangeSetFields>(
          'name',
          factoryParams
        ) as unknown as BackendEventsMapper<T>
      case LogType.VEHICLE:
        return new InvestmentVehicleEventsMapper(
          'name'
        ) as unknown as BackendEventsMapper<T>
      case LogType.PIPELINE_PORTFOLIOS:
      case LogType.INVEST_PORTFOLIOS:
      case LogType.FUND_PORTFOLIOS:
      case LogType.DEAL_PORTFOLIOS:
        return new PortfolioEventsMapper(
          'name'
        ) as unknown as BackendEventsMapper<T>
      case LogType.NOTES:
      case LogType.DOCUMENTS:
      case LogType.ANNOUNCEMENTS:
      case LogType.TRANSACTIONS:
        return new UpdateEventsMapper(
          'title'
        ) as unknown as BackendEventsMapper<T>
      case LogType.XERO_REPORTS:
      case LogType.QUICKBOOKS_REPORTS:
        return new AccountingUpdateEventsMapper(
          'title'
        ) as unknown as BackendEventsMapper<T>

      default:
        return assertUnreachable(logType)
    }
  }
}
