import _ from 'lodash'
import { useFileContentQuery } from 'utils/hooks/contents/useContentQuery'
import { useDownloadFile } from 'utils/hooks/files/useDownloadFile'
import { SubjectMatterInfo } from 'utils/types/files'

const useFileDetails = (id: string) => {
  const { isLoading, data } = useFileContentQuery(id)
  const { onDownloadFile } = useDownloadFile()
  const aboutEntities = _.uniqBy(
    data?.loggingUpdates.map(
      (loggingUpdate) => loggingUpdate.subjectMatterInfo as SubjectMatterInfo
    ),
    'id'
  )

  return {
    isLoading,
    contentData: data,
    handlers: {
      onDownloadFile,
    },
    aboutEntities,
  }
}

export default useFileDetails
