import sortBy from 'lodash/sortBy'
import memoize from 'lodash/memoize'

import {
  getUserHasGroupMemberRole,
  getCurrentGroupType,
  getUser,
  getIsReadOnlyMode,
} from './auth'

export const getUniversityActions = memoize((state) => {
  const currentGroupType = getCurrentGroupType(state)
  const userHasGroupMemberRole = getUserHasGroupMemberRole(state)

  return state.university.actions.filter(
    (action) =>
      action.groupTypes.includes(currentGroupType) &&
      (!userHasGroupMemberRole || !action.hideOnMemberRoles)
  )
})

export const getPendingActions = (state) => {
  const actions = getUniversityActions(state)
  const pendingActions = actions.filter((action) => !action.completed)
  const isReadOnlyMode = getIsReadOnlyMode(state)
  if (isReadOnlyMode) {
    return sortBy(pendingActions, (action) => action.enabledInReadOnly)
  }
  return pendingActions
}

export const getCompletedByMeActions = (state) => {
  const currentUser = getUser(state)
  const actions = getUniversityActions(state)
  return actions.filter((action) => {
    if (action.completedByMe) {
      return true
    }

    return (
      action.completed &&
      (action.isUserAction || action.userId === currentUser.id)
    )
  })
}

export const getCompletedByGroupActions = (state) => {
  const currentUser = getUser(state)
  const actions = getUniversityActions(state)

  return actions.filter((action) => {
    if (action.completedByMe) {
      return false
    }

    if (action.isUserAction) {
      return false
    }

    return action.completed && action.userId !== currentUser.id
  })
}

export const getUniversityIsLoading = (state) => state.university.loading

export const getUniversityInitialLoading = (state) =>
  state.university.initialLoading

export const getUniversityHasError = (state) => state.university.error

export const getUniversityIsActive = (state) => {
  return state.university.isActive
}

export const getUniversityIsEnabled = (state) => {
  return state.university.isEnabled
}

export const getUniversityProgress = (state) => state.university.progress

export const getIsUniversityCompleted = (state) => state.university.isCompleted

export const isUniversityActionCompleted = (actionId) => (state) => {
  const actions = getUniversityActions(state)
  const action = actions.find(({ id }) => id === actionId)
  if (action?.completed === undefined) {
    return true
  }
  return action?.completed
}
