import styled, { css } from 'styled-components'

export const PieChartContainer = styled.div`
  height: 23rem;
  width: 23rem;
`

export const PieChartHoverContainer = styled.div<{ hover?: boolean }>`
  .recharts-sector {
    transition: opacity 0.2s;
    &:hover {
      opacity: 1;
    }
  }

  ${(props) =>
    props.hover &&
    css`
      .recharts-sector {
        opacity: 0.2;
      }
    `}
`
