import styled from 'styled-components'
import CWAvatar from 'components/Avatar'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

const DEFAULT_SIZE = '2.8rem'

export const Avatar = styled(CWAvatar)<{ size?: string }>`
  width: ${({ size }) => size || DEFAULT_SIZE};
  min-width: ${({ size }) => size || DEFAULT_SIZE};
  height: ${({ size }) => size || DEFAULT_SIZE};
  min-height: ${({ size }) => size || DEFAULT_SIZE};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const PortfolioIconWrapper = styled.div<{
  size?: string
  hasPortfolioIconBorder?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size || DEFAULT_SIZE};
  height: ${({ size }) => size || DEFAULT_SIZE};
  min-width: ${({ size }) => size || DEFAULT_SIZE};
  min-height: ${({ size }) => size || DEFAULT_SIZE};
  padding: 0.5rem;
  border-radius: 50%;
  background-color: ${color('primaryBlue')};
  color: ${color('white')};
  border: ${({ hasPortfolioIconBorder }) =>
    hasPortfolioIconBorder
      ? `1px solid ${color('veryLightGray')({ theme })}`
      : 'initial'};

  svg {
    height: 1.2rem;
  }
`
