import styled from 'styled-components/macro'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import SearchInputToStyle from 'components/SearchInput'
import SortByToStyle from 'components/SortBy'

export const Container = styled.div`
  padding: 2rem 3.2rem;

  & .metrics-tabs:first-child {
    background-color: ${color('backgroundGray')};
    position: sticky;
    top: 0rem;
    z-index: 1;
  }

  & .metrics-tabs:nth-child(1) {
    @media ${maxSize.sm} {
      padding-top: 4rem !important;
      height: auto;
    }
  }

  @media ${maxSize.lg} {
    padding: 3.2rem 2.4rem;
  }

  @media ${maxSize.xss} {
    padding: 3.2rem 1.8rem;
  }
`

export const Header = styled.div`
  font-size: 2.4rem;
  padding-bottom: 2.5rem;
`

export const Search = styled.div`
  margin-left: 38rem; // tabs width
  position: sticky;
  top: 1rem;
  z-index: 2;

  @media ${maxSize.sm} {
    margin: 0;
  }
`

export const SearchInput = styled(SearchInputToStyle)`
  max-width: 25rem;

  left: 100%;
  margin-bottom: 3.1rem;
  margin-top: -6.3rem;
  position: sticky;
  top: 8px;
  z-index: 2;

  input {
    padding: 0.8rem 3.2rem 0.8rem 1.6rem;
    height: 3.2rem;
  }

  @media ${maxSize.lg} {
    margin-top: -5.5rem;
    margin-bottom: 2.3rem;
    max-width: 18rem;
  }

  @media ${maxSize.sm} {
    margin-bottom: 5.8rem;
    margin-top: -9rem;
    max-width: 100%;
    width: 100%;
  }
`

export const SortBy = styled(SortByToStyle)`
  .dropdown-button {
    height: 3.2rem;
    padding-right: 0.8rem;
    padding-left: 1rem;

    div {
      font-weight: 400;
    }

    svg {
      margin-left: 0;

      &[class*='angle'] {
        font-size: 1.8rem;
      }
    }

    :focus {
      background-color: white;
    }
  }

  #dropdown-content li span span {
    font-weight: 400;
  }
`

export const SortDirectionLabel = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  margin-left: 0.5rem;
`
