import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import { getUser } from 'selectors/auth'
import CreateChatModal from '../../CreateChat/CreateChatModal'

import { Container, Title, Text } from '../ZeroStates.styles'
import { Button } from './EmptyPlaceholder.styles'

const EmptyPlaceholder = () => {
  const [showCreateChatModal, setShowCreateChatModal] = useState(false)
  const user = useAppSelector(getUser)

  return (
    <>
      <Container>
        <ZeroState type={ZeroStateType.NO_CHATS} hideText hideTitle />
        <Title>
          <FormattedMessage
            id="zeroState.no_chats.title"
            values={{ userName: user.firstName }}
          />
        </Title>
        <Text>
          <FormattedMessage id="zeroState.no_chats.text" />
        </Text>
        <Button primary onClick={() => setShowCreateChatModal(true)}>
          <FormattedMessage id="chat.startNewChat" />
        </Button>
      </Container>
      <CreateChatModal
        show={showCreateChatModal}
        onHide={() => setShowCreateChatModal(false)}
      />
    </>
  )
}

export default EmptyPlaceholder
