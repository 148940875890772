import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const Container = styled.div`
  display: flex;
  border-radius: 0.8rem;
  overflow: hidden;
`

export const StepContainer = styled.div<{ selected?: boolean }>`
  padding: 1.5rem 1.6rem 1.5rem 2.4rem;
  display: flex;
  align-items: center;
  gap: 1.4rem;
  width: 33.3%;
  background-color: ${({ selected }) => (selected ? 'white' : 'transparent')};
  border-top: 0.1rem solid ${color('white', 0.4)};
  border-bottom: 0.1rem solid ${color('white', 0.4)};
  border-left: 0.1rem solid ${color('white', 0.4)};

  &:first-child {
    border-radius: 0.8rem 0 0 0.8rem;
  }

  &:last-child {
    border-radius: 0 0.8rem 0.8rem 0;
    border-right: 0.1rem solid ${color('white', 0.4)};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ selected }) =>
      selected ? 'white' : color('white', 0.1)};
  }

  @media ${maxSize.md} {
    flex-direction: column;
    text-align: center;
  }
`

export const IconContainer = styled.div<{
  selected?: boolean
  completed?: boolean
}>`
  background-color: ${({ selected }) =>
    selected ? color('darkBlue') : 'transparent'};
  color: white;
  height: 100%;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ completed }) =>
    completed &&
    css`
      background-color: ${color('green')};
    `}
`

export const StepTitle = styled.div<{
  selected?: boolean
  completed?: boolean
}>`
  color: ${({ selected }) => (selected ? color('darkBlue') : 'white')};
  font-size: 1.4rem;
  font-weight: 700;
  opacity: ${({ completed, selected }) => (completed && !selected ? 0.7 : 1)};
`
