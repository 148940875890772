import React from 'react'
import PropTypes from 'prop-types'
import { isEnterKeyCode } from 'utils/functions/keyboardEvents'
import { SortDirection } from 'utils/constants/sortDirection'
import Tooltip from 'components/Tooltip'
import ArrowButton from '../ArrowButton/ArrowButton'
import {
  ColHeaderContainer,
  SortIcon,
  LabelContainer,
  Label,
  TooltipContainer,
} from './ColHeader.styles'

const ColHeader = ({ column, sortBy, sortDirection, onSort, arrow }) => {
  const activeSort = sortBy && sortBy === column.sortKey

  let props = {}
  if (!column.sortDisabled) {
    props = {
      role: 'button',
      tabIndex: 0,
      onKeyDown: (event) => {
        if (isEnterKeyCode(event)) onSort()
      },
      onClick: onSort,
    }
  }
  return (
    <ColHeaderContainer
      data-testid={`table-column-${column.sortKey}`}
      {...props}
      flex={column.flex}
      padding={column.padding}
      align={column.align}
      sortDisabled={column.sortDisabled}
    >
      {arrow && (
        <ArrowButton direction={arrow.direction} onClick={arrow.onClick} />
      )}
      <LabelContainer>
        <Label activeSort={activeSort}>
          {column.getLabel?.() ?? column.label}
        </Label>
        {column.tooltip && (
          <TooltipContainer activeSort={activeSort}>
            <Tooltip
              id={column.sortKey || column.label}
              text={column.tooltip}
              place="top"
            />
          </TooltipContainer>
        )}
      </LabelContainer>
      {!column.sortDisabled && activeSort && (
        <SortIcon
          icon={[
            'fas',
            sortDirection === SortDirection.ASC ? 'caret-up' : 'caret-down',
          ]}
        />
      )}
    </ColHeaderContainer>
  )
}

ColHeader.propTypes = {
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string,
  onSort: PropTypes.func,
  arrow: PropTypes.object,
  column: PropTypes.object,
}

ColHeader.defaultProps = {
  sortBy: '',
  sortDirection: '',
  onSort: () => {},
  arrow: null,
  column: {},
}

export default ColHeader
