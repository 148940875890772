export const removeHttp = (url: string): string =>
  url.replace(/^https?:\/\//, '')

/**
 * Handy JavaScript to measure the size taken to render the supplied text;
 * https://stackoverflow.com/a/4032497
 */
export const measureText = (text: string, fontSize: string = '1.4rem') => {
  const div = document.createElement('div')
  document.body.appendChild(div)

  div.style.fontSize = fontSize
  div.style.position = 'absolute'
  div.style.display = 'inline-flex'
  div.style.maxWidth = 'max-content'
  div.style.width = 'max-content'

  div.textContent = text

  const result = {
    width: div.clientWidth,
    height: div.clientHeight,
  }

  document.body.removeChild(div)

  return result
}
