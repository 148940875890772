import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import type { FormatMessageResponse } from 'stream-chat'

import {
  isJoinedChannelSystemMessage,
  isLeftChannelSystemMessage,
  StreamChatType,
  SystemMessage,
  TopicChannel,
} from 'containers/Chat/types'
import { useSystemMessage } from 'containers/Chat/components/SystemMessage'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser } from 'selectors/auth'
import { useMessageText } from 'containers/Chat/hooks/useMessageText'
import {
  GrayEditedLabel,
  WhiteEditedLabel,
} from 'containers/Chat/components/Message/Message.styles'

interface TopicChannelMessageProps {
  message?: FormatMessageResponse<StreamChatType<TopicChannel>>
  isChannelSelected?: boolean
}

const TopicChannelMessage = ({
  message,
  isChannelSelected,
}: TopicChannelMessageProps) => {
  const intl = useIntl()
  const user = useAppSelector(getUser)
  const messageText = useMessageText(message)
  const { renderJoinedChatMessage, renderLeftChatMessage } = useSystemMessage()

  const softDeleted = useMemo(
    () => message?.selfDeletedIds?.some((id) => id === user?.id),
    [message, user]
  )

  const messageDeleted = softDeleted || message?.type === 'deleted'

  const editedLabel = useMemo(() => {
    if (!message?.edited || messageDeleted) return null

    if (isChannelSelected)
      return (
        <WhiteEditedLabel>
          <FormattedMessage id="chat.edited" />
        </WhiteEditedLabel>
      )

    return (
      <GrayEditedLabel>
        <FormattedMessage id="chat.edited" />
      </GrayEditedLabel>
    )
  }, [isChannelSelected, message?.edited, messageDeleted])

  if (!message) {
    return null
  }

  if (message.type === 'system') {
    const systemMessage: SystemMessage = JSON.parse(message.text!)
    const plainText = true

    if (isJoinedChannelSystemMessage(systemMessage)) {
      return <>{renderJoinedChatMessage(systemMessage, plainText)}</>
    }

    if (isLeftChannelSystemMessage(systemMessage)) {
      return <>{renderLeftChatMessage(systemMessage, plainText)}</>
    }
  }

  const messageUser =
    message?.user?.id === user?.id
      ? intl.formatMessage({ id: 'chat.you' })
      : message?.user?.name

  return (
    <>
      {messageUser}: {messageText} {editedLabel}
    </>
  )
}

export default TopicChannelMessage
