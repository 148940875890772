/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

import beamerInitialize from './beamerInitialize'
import { useBeamer } from './useBeamer'

const BEAMER_ID = process.env.REACT_APP_BEAMER_PRODUCT_ID

const BeamerHelmet = ({ children }) => {
  const { isBeamerEnabled, args } = useBeamer()

  useEffect(() => {
    if (isBeamerEnabled) {
      setTimeout(() => {
        beamerInitialize(BEAMER_ID, undefined, args)
      }, 2000)
    }
  }, [isBeamerEnabled])

  return children
}

export default BeamerHelmet
