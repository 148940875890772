import styled from 'styled-components'

export const Undo = styled.span`
  text-decoration: underline;
  font-weight: 800;
  cursor: pointer;
`

export const UndoContainer = styled.div`
  margin-left: 2.4rem;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;

  svg {
    cursor: pointer;
    font-size: 2rem;
  }
`
