import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BarContainer } from './OnboardingBar.styles'

type Props = {
  onClick: () => void
}

const OnboardingBar: React.FC<Props> = ({ onClick }) => {
  return (
    <BarContainer>
      <button type="button" onClick={onClick}>
        <FormattedMessage id="onboardingModal.onboardingBarText" />
      </button>
    </BarContainer>
  )
}

export default OnboardingBar
