import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Toggle from 'components/Toggle'
import theme from 'utils/theme'
import { color } from 'utils/functions/colors'

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.6rem;
`

export const StyledToggle = styled(Toggle)`
  margin: 0 0.9rem;

  div[class*='Toggle_toggleWrapper'] {
    background: ${color('primaryBlue')};
  }
`

export const StyledIcon = styled(FontAwesomeIcon)<{ disabled: boolean }>`
  color: ${({ disabled }) =>
    disabled ? theme.colors.lightGray : theme.colors.primaryBlue};
`
