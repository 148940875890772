import { useForm, useFormikContext, useField } from 'components/Form/hooks'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import { TransactionFundType } from 'utils/constants/transactionTypes'
import { UpdateTypes } from 'utils/constants/updates'
import useSettings from 'utils/hooks/useSettings'
import {
  AccessType,
  CreateUpdateFormikStatus,
  CreateUpdateGlobalProps,
  UpdateFormContext,
} from 'utils/types/updateForm'

interface Props {
  handleSubmit: () => void
}

const useManageModals = ({ handleSubmit }: Props) => {
  const { settings } = useSettings()
  const { values, setFieldValue } = useFormikContext<
    CreateUpdateGlobalProps,
    CreateUpdateFormikStatus
  >()

  const [showShareSettingsModal, setShowShareSettingsModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [discardDraftModal, setDiscardDraftModal] = useState(false)

  const toggleDeleteConfirmationModal = () => setDeleteModal((open) => !open)

  const toggleDiscardDraftConfirmationModal = () =>
    setDiscardDraftModal((open) => !open)
  const [notShowShareWithInvestorModal] = useField<boolean>(
    'notShowShareWithInvestorModal'
  )

  const [
    showShareWithInvestorRecommendationModal,
    setShowShareWithInvestorRecommendationModal,
  ] = useState(false)

  const switchShowMessageAgain = useCallback(() => {
    setFieldValue(
      'notShowShareWithInvestorModal',
      !notShowShareWithInvestorModal.value
    )
  }, [notShowShareWithInvestorModal.value, setFieldValue])

  const [showChangePublishUpdateDate, setShowChangePublishUpdateDate] =
    useState(false)

  const { hasDraftMode, showInvestorAndFundSelector } =
    useForm<UpdateFormContext>()

  const handleOpenShareSettingsModal = useCallback(() => {
    setShowShareSettingsModal(true)
  }, [])

  const toggleShareSettingsModal = useCallback(() => {
    setShowShareSettingsModal(false)
  }, [])

  const toggleSubmitConfirmationModal = useCallback(() => {
    setShowShareWithInvestorRecommendationModal((open) => !open)
  }, [])

  const togglePublishUpdateDateModal = useCallback(() => {
    setShowChangePublishUpdateDate((open) => !open)
  }, [])

  const isTransactionType = values.updateType === UpdateTypes.TRANSACTION
  const isNoteType = values.updateType === UpdateTypes.NOTE
  const isDocumentType = values.updateType === UpdateTypes.DOCUMENT

  const shouldHaveConfirmationModal =
    isTransactionType || isNoteType || isDocumentType

  const isInvestorTransactionFundType =
    values.transactionFundType === TransactionFundType.INVESTOR

  const shouldShowShareWithInvestorRecommendation =
    shouldHaveConfirmationModal &&
    (isInvestorTransactionFundType || showInvestorAndFundSelector) &&
    values.permissions.investor === AccessType.NO_ACCESS &&
    settings?.notShowAgainModal?.shareWithInvestor

  const publishUpdate = () => {
    if (shouldShowShareWithInvestorRecommendation) {
      setShowShareWithInvestorRecommendationModal(true)
      return
    }

    if (hasDraftMode) {
      const currentDate = dayjs()

      if (currentDate.isAfter(values.date, 'day')) {
        setShowChangePublishUpdateDate(true)
        return
      }
    }

    handleSubmit()
  }

  const onShareWith = () => {
    toggleSubmitConfirmationModal()
    setShowShareSettingsModal(true)
  }

  return {
    showShareSettingsModal,
    showShareWithInvestorRecommendationModal,
    shouldShowShareWithInvestorRecommendation,
    notShowShareWithInvestorModal,
    showChangePublishUpdateDate,
    deleteModal,
    discardDraftModal,
    isInvestorTransactionFundType,
    shouldHaveConfirmationModal,
    switchShowMessageAgain,
    toggleShareSettingsModal,
    handleOpenShareSettingsModal,
    publishUpdate,
    onShareWith,
    toggleSubmitConfirmationModal,
    togglePublishUpdateDateModal,
    toggleDeleteConfirmationModal,
    toggleDiscardDraftConfirmationModal,
  }
}

export default useManageModals
