import Tooltip from 'components/Tooltip'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { DATE_FORMATS, formattedDate } from 'utils/functions/date'
import { displayFileSize } from 'utils/functions/files'
import { FileContentDetails, SubjectMatterInfo } from 'utils/types/files'

import * as Styles from './Details.styles'
import useDetails from './useDetails'

interface IProps {
  file: FileContentDetails
  aboutEntities?: SubjectMatterInfo[]
}

interface IRowProps {
  label: React.ReactNode
  value: React.ReactNode
}

interface IAboutRowProps {
  label: React.ReactNode
  aboutEntities?: SubjectMatterInfo[]
}

const Row = ({ label, value }: IRowProps) => (
  <Styles.Row>
    <Styles.Label>{label}</Styles.Label>
    <Styles.Value>{value}</Styles.Value>
  </Styles.Row>
)

const AboutRow = ({ label, aboutEntities }: IAboutRowProps) => {
  const { getEntityUrl, renderLogo } = useDetails()

  return (
    <Styles.Row>
      <Styles.Label>{label}</Styles.Label>
      <Styles.ValueColumnWrapper>
        {aboutEntities?.map((entity) => (
          <Styles.PortfolioWrapper>
            <Styles.PortfolioIconWrapper>
              {renderLogo(entity)}
            </Styles.PortfolioIconWrapper>
            <Tooltip
              id={entity.name}
              text={entity.name}
              place="bottom"
              delayShow={1000}
            >
              <Styles.AvatarName
                href={getEntityUrl(entity)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {entity.name}
              </Styles.AvatarName>
            </Tooltip>
          </Styles.PortfolioWrapper>
        ))}
      </Styles.ValueColumnWrapper>
    </Styles.Row>
  )
}

const getName = (user, group) => {
  return user ? `${user?.firstName} ${user?.lastName}` : `${group?.name}`
}

const Details: React.FC<IProps> = ({ file, aboutEntities }) => {
  const { fileSize, createdAt, fileFormat, unreconciled } = file
  const showAboutTag = !!aboutEntities?.length
  return (
    <Styles.Details>
      <Styles.Title>
        <FormattedMessage id="files.details.title" />
      </Styles.Title>
      <Styles.Table>
        <Row
          label={<FormattedMessage id="files.details.size" />}
          value={displayFileSize(fileSize)}
        />
        <Row
          label={<FormattedMessage id="files.details.date" />}
          value={formattedDate(createdAt, DATE_FORMATS.DDMM_YYYY)}
        />
        <Row
          label={<FormattedMessage id="files.details.fileFormat" />}
          value={fileFormat}
        />
        <Row
          label={
            unreconciled ? (
              <FormattedMessage id="files.details.addedBy" />
            ) : (
              <FormattedMessage id="files.details.sharedBy" />
            )
          }
          value={getName(file.user, file.group)}
        />
        {showAboutTag && (
          <AboutRow
            label={<FormattedMessage id="files.details.about" />}
            aboutEntities={aboutEntities}
          />
        )}
      </Styles.Table>
    </Styles.Details>
  )
}

export default Details
