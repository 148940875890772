import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;
`

export const UnreadContainer = styled.div`
  padding: 0 0.8rem;
  height: 1.8rem;
  background: ${color('green')};
  border-radius: 10px;
  font-weight: 700;
  font-size: 1rem;
  color: ${color('white')};
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BetaTag = styled(UnreadContainer)`
  background: transparent;
  border: 1px solid ${color('white')};
  text-transform: uppercase;
`
