import React from 'react'
import { useIntl } from 'react-intl'

import IconButton from 'ui/IconButton'
import PreviewFile from 'components/PreviewContents/components/PreviewFile'
import PreviewContents from 'components/PreviewContents'
import { getFileExtension, isPreviewAvailable } from 'utils/functions/files'
import { usePreviewContents } from 'utils/hooks/files/usePreviewContents'
import {
  FileContentDetails,
  FileExtension,
  UserContentStatus,
} from 'utils/types/files'

import Separator, { Direction } from 'ui/Separator'
import NoPreviewAvailable from './components/NoPreviewAvailable'
import PreviewError from './components/PreviewError'
import * as Styles from './Preview.styles'
import UnreconciledPill from '../../components/UnreconciledPill'

interface PreviewProps {
  file: FileContentDetails
  onDownloadFile: (file: FileContentDetails) => void
}

const Preview: React.FC<PreviewProps> = ({ file, onDownloadFile }) => {
  const {
    onClosePreview,
    onPreviewContent,
    selectedContent,
    onGroupManagedStatusChangeHandler,
  } = usePreviewContents()

  const fileExtension = getFileExtension(file.fileFormat)
  const isAnImage = fileExtension === FileExtension.IMAGE
  const withBackground =
    (fileExtension === FileExtension.PDF || isAnImage) &&
    isPreviewAvailable(file)
  const isUnreconciledUserContent =
    file.userContentStatus === UserContentStatus.USER_UNRECONCILED

  const intl = useIntl()

  return (
    <>
      <Styles.Preview withBackground={withBackground} isAnImage={isAnImage}>
        <PreviewFile
          file={file}
          onDownloadFile={() => {}}
          noPreviewAvailable={<NoPreviewAvailable file={file} />}
          displayPDFControls={false}
          errorComponent={<PreviewError />}
        />
        {file.unreconciled && <UnreconciledPill />}
      </Styles.Preview>
      <Styles.Actions>
        <IconButton
          onClick={() => onPreviewContent(file)}
          iconFamily="far"
          icon="eye"
        />
        <IconButton
          onClick={() => onDownloadFile(file)}
          iconFamily="far"
          icon="download"
        />
        <Separator
          space="2.4rem"
          border
          direction={Direction.Vertical}
          height="4rem"
          separation="1rem"
        />
        {isUnreconciledUserContent && (
          <Styles.GroupManagedToggle
            status={file?.groupManaged ?? false}
            id="group-managed"
            name="group-managed"
            onChangeStatus={(status) =>
              onGroupManagedStatusChangeHandler(file?.id, status)
            }
            blue
            legend={intl.formatMessage({
              id: 'files.groupManaged',
            })}
          />
        )}
      </Styles.Actions>
      {!!selectedContent && (
        <PreviewContents
          contents={[selectedContent]}
          selectedContent={selectedContent}
          isOpen={!!selectedContent}
          onClosePreview={onClosePreview}
        />
      )}
    </>
  )
}

export default Preview
