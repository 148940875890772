import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { GridType } from 'utils/constants/gridType'

import { ReactChildren } from 'utils/types/common'
import { gridAnimation } from 'utils/animations/grid'
import styles from './HoldingsList.module.scss'

interface HoldingsListProps {
  gridType: GridType
  children: ReactChildren
}

// TODO: Add composition once CompanyCard and CompanyRowCard are migrated to TS
// interface OptionsDropdownComposition {
//   GridItem: React.FC<CompanyCardProps>
//   RowItem: React.FC<CompanyRowCardProps>
// }

const HoldingsList: React.FC<HoldingsListProps> = ({
  children,
  gridType = GridType.GRID,
}) => {
  return (
    <motion.ul
      initial="hidden"
      animate="visible"
      variants={gridAnimation}
      className={classNames(styles.flexRow, {
        [styles.listContainerRows]: gridType === GridType.ROWS,
        [styles.listContainerGrid]: gridType === GridType.GRID,
        [styles.listFundPortfolioContainerGrid]:
          gridType === GridType.GRID_FOR_FUND_PORTFOLIOS,
        [styles.flexRow]: gridType === GridType.GRID,
      })}
    >
      {children}
    </motion.ul>
  )
}

// TODO: Add composition once CompanyCard and CompanyRowCard are migrated to TS
// HoldingsList.GridItem = CompanyCard
// HoldingsList.RowItem = CompanyRowCard

export default HoldingsList
