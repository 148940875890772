import React from 'react'
import PropTypes from 'prop-types'

import Heading from 'components/Heading'

import styles from './NoResultsFound.module.scss'

const NoResultsFound = ({ children, ...rest }) => {
  return (
    <div className={styles.noResultsFoundContainer} {...rest}>
      {children}
    </div>
  )
}

NoResultsFound.Title = ({ children }) => (
  <Heading className={styles.noResultsFoundTitle} level="h1">
    {children}
  </Heading>
)
NoResultsFound.Legend = ({ children }) => (
  <p className={styles.noResultsFoundLegend}>{children}</p>
)

NoResultsFound.Icon = ({ children }) => (
  <div className={styles.noResultsFoundIcon}>{children}</div>
)

NoResultsFound.propTypes = {
  children: PropTypes.node.isRequired,
}

NoResultsFound.Title.propTypes = {
  children: PropTypes.node.isRequired,
}

NoResultsFound.Legend.propTypes = {
  children: PropTypes.node.isRequired,
}

NoResultsFound.Icon.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NoResultsFound
