import { RefObject } from 'react'
import { useIntl } from 'react-intl'
import type { FormikProps } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import UpdateService from 'api/UpdateService'
import Toast from 'components/Toast'
import { getCurrentGroupName } from 'selectors/auth'
import { DefaultRoutes, setRouteConfiguration } from 'reducers/breadcrumbSlice'
import { checkUpdatePermissionError } from 'utils/functions/permissions'
import { getScheduleType } from 'utils/functions/updates'
import { emailKeys } from 'utils/queries/emails'
import { EmailUpdate, ScheduleType } from 'utils/types/update'
import { CreateEmailFormValues } from 'utils/types/emailForm'
import EmailService from 'api/EmailService'
import { useAppDispatch, useAppSelector } from '../reduxToolkit'

export const useEmailsQuery = (emailId: string) => {
  const currentGroupName = useAppSelector(getCurrentGroupName)

  const query = useQuery({
    queryKey: emailKeys.byId(emailId),
    queryFn: async () => {
      const email = await UpdateService.fetchUpdate<EmailUpdate>(emailId)
      return email
    },

    onError: (error) => {
      if (checkUpdatePermissionError(error)) {
        Toast.displayIntl(
          [
            'errors.groupNoAccessShow',
            { name: 'email', groupName: currentGroupName },
          ],
          'error'
        )
      } else {
        Toast.displayIntl('updates.fetchErrors.report', 'error')
      }
    },
  })

  return query
}

interface CreateEmailMutationProps {
  updateId: string
  loggingUpdateId: string
  formikRef: RefObject<FormikProps<CreateEmailFormValues>>
}

export const useCreateEmailMutation = ({
  updateId,
  loggingUpdateId,
  formikRef,
}: CreateEmailMutationProps) => {
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { state: locationState } = useLocation<{ redirectTo: string }>()

  const handleOnSuccess = (values: CreateEmailFormValues) => {
    const scheduleType = getScheduleType(values.date)

    Toast.displayIntl(
      scheduleType === ScheduleType.SCHEDULE
        ? ['createEmail.scheduleSuccessful']
        : ['createEmail.sendSuccessful'],
      'success'
    )

    dispatch(
      setRouteConfiguration({
        route: DefaultRoutes.UPDATES,
        initialLinkRedirect: `/updates`,
      })
    )

    if (locationState?.redirectTo) {
      return history.push(`/${locationState.redirectTo}`)
    }

    return history.push('/updates')
  }

  return useMutation({
    mutationKey: emailKeys.createEmail(),
    mutationFn: async (values: CreateEmailFormValues) => {
      const { date, scheduleType } = values

      if (
        scheduleType === ScheduleType.SCHEDULE &&
        date.setSeconds(0) < new Date().setSeconds(0)
      ) {
        return formikRef.current?.setFieldError(
          'date',
          intl.formatMessage({ id: 'general.minDate' })
        )
      }

      if (scheduleType === ScheduleType.SEND_NOW) {
        return EmailService.sendEmail(updateId)
      }

      return EmailService.editEmail(updateId, {
        loggingUpdateSchedule: {
          scheduleDate: date.toISOString(),
        },
        loggingUpdateAttributes: {
          id: loggingUpdateId,
          scheduled: true,
        },
      })
    },
    onSuccess: (_, values) => handleOnSuccess(values),
    onError: () => {
      Toast.displayIntl('createEmail.sendError', 'error')
    },
  })
}
