import styled, { css } from 'styled-components'
import { DropdownOptionsList } from 'ui/Dropdown/Dropdown.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { hexToRGB } from 'utils/functions/colors'
import theme from 'utils/theme'

export const GooglePlacesAutocompleteWrapper = styled.div<{
  disabled?: boolean
}>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
      pointer-events: none;
    `}
`
export const AddLocationIcon = styled(FontAwesomeIcon)<{
  iconFontSize?: string
}>`
  margin-right: 0.6rem;
  margin-top: 0.1rem;

  font-size: ${(props) => props.iconFontSize || '1.8rem'};
`

export const ClearButton = styled(FontAwesomeIcon)`
  font-size: 2rem;
`

export const LocationDropdownOptionsList = styled(DropdownOptionsList)`
  margin-top: 0;
`

export const inputStyles = () => ({
  padding: 0,
})

export const indicatorSeparatorStyles = () => ({
  // display: 'none',
})

export const loadingIndicatorStyles = () => ({
  display: 'none',
})

export const placeholderStyles = (provided) => ({
  ...provided,
  color: hexToRGB(theme.colors.darkGray, 0.3),
})

export const menuStyles = (provided, state) => {
  return {
    ...provided,
    boxShadow:
      state.options.length === 0 && !state.isLoading
        ? 'none'
        : provided.boxShadow,
  }
}

export const controlStyles = (provided, styles) => ({
  ...provided,
  width: '100%',
  border: '0.1rem solid transparent',
  borderRadius: '0.8rem',
  fontSize: '1.4rem',
  outline: 'none',
  background: hexToRGB(theme.colors.lightGray, 0.1),
  color: '#4A4A68',
  padding: '0 .8rem',
  height: ' 3.8rem',
  boxShadow: 'none',
  cursor: 'text',
  ':hover': {
    borderColor: theme.colors.veryLightBlue,
  },
  ':focus': {
    border: ' 0.1rem solid transparent',
  },
  ...styles,
})
