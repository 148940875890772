import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import AccountingIcon from 'components/AccountingIcon/AccountingIcon'
import FeedUpdate from 'components/FeedUpdate'
import IconLegend from 'components/IconLegend'
import { PlainUpdateTypes } from 'utils/constants/updates'
import { useVisibility } from 'utils/hooks/useVisibility'
import { getReportName } from 'utils/functions/xeroReports'
import { IndexUpdate } from 'utils/types/update'
import {
  AccountingReport,
  AccountingUpdateBody,
  LinkButton,
  VisibilityContainer,
} from '../UpdateFactory.styles'
import { useDefaultUpdateCard } from './useDefaultUpdateCard'
import Header from '../components/Header'
import ReshareIcons from '../components/ReshareIcons'

interface AccountingCardProps {
  update: IndexUpdate
  canEdit?: boolean
}

const AccountingCard: React.FC<AccountingCardProps> = ({ update, canEdit }) => {
  const intl = useIntl()
  const { getUpdateVisibility } = useVisibility()
  const { onShow, onEdit, onReshare } = useDefaultUpdateCard(update)

  return (
    <FeedUpdate onClick={onShow}>
      <Header update={update} />

      <AccountingUpdateBody>
        <AccountingReport>
          <AccountingIcon
            type={
              update.type === PlainUpdateTypes.XERO_REPORT
                ? 'xero'
                : 'quickbooks'
            }
          />
          <span>{getReportName(update)}</span>
        </AccountingReport>
      </AccountingUpdateBody>

      <FeedUpdate.Footer>
        {!!update.contents?.length && (
          <IconLegend
            icon={['fal', 'paperclip']}
            legend={update.contents?.length?.toString()}
            title={intl.formatMessage({ id: 'updates.attachmentsNumber' })}
          />
        )}

        <VisibilityContainer>
          <IconLegend
            icon={['fal', 'lock-alt']}
            legend={getUpdateVisibility(update)}
            title={intl.formatMessage({ id: 'updates.visibility' })}
          />
          {update.confidential && (
            <IconLegend
              icon={['fal', 'user-shield']}
              title={intl.formatMessage({ id: 'updates.confidential' })}
              hasTooltip
            />
          )}
        </VisibilityContainer>

        {canEdit && (
          <LinkButton onClick={onEdit}>
            <LinkButton onClick={onEdit}>
              <FormattedMessage id="general.edit" />
            </LinkButton>
          </LinkButton>
        )}

        <ReshareIcons update={update} onClick={onReshare} />
      </FeedUpdate.Footer>
    </FeedUpdate>
  )
}

export default AccountingCard
