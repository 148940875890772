import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

type DropContainerProps = {
  isDraggingFile?: boolean
  showDropper?: boolean
  defaultZIndex?: number
}
export const DropContainer = styled.div.attrs<DropContainerProps>((props) => ({
  style: {
    zIndex: props.showDropper ? 1 : props.defaultZIndex ?? -1,
    backgroundColor: props.isDraggingFile
      ? `${color('primaryBlue', 0.1)(props)}`
      : '',
    boxShadow: props.isDraggingFile
      ? `0 0 0 2px ${color('primaryBlue')(props)}`
      : '',
  },
}))<DropContainerProps>`
  position: fixed;
  width: calc(var(--mainLayoutWidth) - 4.4rem);
  height: calc(100% - 4.8rem);
  transition: all 0.2s ease-in-out;
  transform: translate(-1rem, -1rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 1.6rem;
  border-radius: 8px;
  transition-delay: 0.1s;
`

export const DropFileMessage = styled.div<{
  isDraggingFile?: boolean
  showDropper?: boolean
}>`
  background: ${color('primaryBlue')};
  border-radius: 8px;
  color: ${color('white')};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.6rem;
  padding: 1.6rem;

  transition: all 0.2s ease-in-out;
  transition-delay: 0.1s;
  z-index: ${(props) => (props.showDropper ? 1 : 0)};
  opacity: 1;

  ${(props) =>
    !props.isDraggingFile &&
    css`
      opacity: 0;
    `};
`
