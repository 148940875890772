import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from 'components/Card'
import Button from 'ui/Button'
import { maxSize } from 'utils/constants/breakpoint'

export const InboxNavigationContainer = styled(Card)`
  height: fit-content;
  width: 28.5%;
  padding: 1.2rem;
  position: sticky;
  top: 1.7rem;

  @media ${maxSize.tabletLg} {
    position: relative;
    width: 100%;
    top: 0;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
`

export const OptionButton = styled(Button)`
  border-radius: 0;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  font-size: 1.4rem;
  width: 100%;
  padding: 1.3rem 0.8rem 1.3rem 1.6rem;
  &:active {
    box-shadow: none;
  }
`

export const Icon = styled(FontAwesomeIcon)<{ active?: boolean }>`
  margin-right: 0.9rem;
  min-width: 1.5rem;
  ${(props) =>
    props.active &&
    css`
      color: white;
    `}
`

export const CheckboxLegend = styled.span`
  text-transform: capitalize;
`
