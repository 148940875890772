import { BYTES_IN_MB } from 'utils/constants/groups'
import { useGroupStorage } from 'utils/hooks/queries/useGroupQuery'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'

export const useStorageUsage = () => {
  const planFeatures = usePlanFeatures()
  const { data } = useGroupStorage()
  const { group, users } = data ?? {}
  const hasPlan = !!planFeatures.groupPlan

  const maxStorage = hasPlan
    ? planFeatures.maxStorageInMb ?? 0
    : (group?.storageLimit ?? 0) / BYTES_IN_MB
  const totalUsedInMb = hasPlan
    ? planFeatures.totalUsedInMb ?? 0
    : (group?.totalFileSize ?? 0) / BYTES_IN_MB

  const hasExceededStorage = totalUsedInMb > maxStorage

  const reconciledFilesSize = group?.reconciledFilesSize ?? 0
  const unreconciledFilesSize = group?.unreconciledFilesSize ?? 0

  const totalUsedInPercent = (totalUsedInMb / maxStorage) * 100

  return {
    hasExceededStorage,
    storageLimitInMb: maxStorage,
    totalUsedInMb: hasExceededStorage ? maxStorage : totalUsedInMb,
    totalUsedInPercent: hasExceededStorage ? 100 : totalUsedInPercent,
    reconciledFilesSize,
    unreconciledFilesSize,
    users,
  }
}
