import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const UpgradeAccountTooltipText = styled.p`
  max-width: 24rem;
  font-size: 1.4rem;
  position: relative;
  z-index: 3;

  > a {
    border-bottom: 0.1rem solid ${color('white')};
  }
`
