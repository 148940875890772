import React from 'react'
import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const StyledListRow = styled.div<{
  isOpen: boolean
  onClick?: (event: React.MouseEvent) => void
}>`
  align-items: center;
  background: ${color('white')};
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};

  padding: 1.7rem 3.2rem;

  :hover {
    box-shadow: 0 0 0 0.2rem ${color('veryLightGray')};
  }

  ${(props) =>
    props.isOpen &&
    css`
      border-radius: 8px;
    `}

  ${(props) =>
    props.onClick &&
    !props.isOpen &&
    css`
      cursor: pointer;
    `}

  @media ${maxSize.mobile} {
    padding: 1.7rem 3.2rem 6rem 3.2rem;

    .show-less {
      bottom: 2rem;
      left: 3rem;
      position: absolute;
    }
  }
`
