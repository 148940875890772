import { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import ReadOnlyTooltip from 'components/ReadOnlyTooltip'
import { PRICING_LINK } from 'utils/constants/links'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'

const DisableCreateTransactionTooltip: React.FC<
  PropsWithChildren & {
    id: string
    leftOffset?: number
    topOffset?: number
    offset?: number
    place?: string
  }
> = ({ children, id, offset, leftOffset, topOffset, place = 'top' }) => {
  const intl = useIntl()
  const planFeatures = usePlanFeatures()

  return (
    <ReadOnlyTooltip
      id={id}
      disabled={!planFeatures.hasTransactionsAvailable}
      text={intl.formatMessage(
        { id: 'subscriptions.limitations.transactionsLimit' },
        {
          SubscriptionsLink: (txt) => (
            <Link to="/settings/group_management/subscriptions">{txt}</Link>
          ),
          MoreInfoLink: (txt) => (
            <a href={PRICING_LINK} target="blank" rel="noreferrer">
              {txt}
            </a>
          ),
        }
      )}
      maxWidth="47rem"
      place={place}
      clickable
      topOffset={topOffset}
      leftOffset={leftOffset}
      offset={offset}
      forceOverridePosition={
        topOffset !== undefined || leftOffset !== undefined
      }
    >
      {children}
    </ReadOnlyTooltip>
  )
}

export default DisableCreateTransactionTooltip
