/* eslint-disable no-param-reassign, no-plusplus, no-underscore-dangle */
import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import RichTextEditor, {
  getTextAlignClassName,
  getTextAlignStyles,
} from 'react-rte'
import styled, { css } from 'styled-components'
import { TextAlign } from 'utils/constants/iue'

import './styles.css'

function isOrContains(node, container) {
  let returnValue = false

  while (node && !returnValue) {
    returnValue = node === container
    node = node.parentNode
  }

  return returnValue
}

function elementContainsSelection(el) {
  const sel = window.getSelection()
  let returnValue = sel.rangeCount > 0 && sel.anchorOffset !== sel.focusOffset

  for (let i = 0; i < sel.rangeCount && returnValue; ++i) {
    returnValue = isOrContains(sel.getRangeAt(i).commonAncestorContainer, el)
  }

  return returnValue
}

const toolbarConfig = {
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_ALIGNMENT_BUTTONS',
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
}

const RTEColorWrapper = styled.div`
  span {
    color: ${(props) => props.color};
  }

  .DraftEditor-editorContainer {
    transition: background-color 0.1s;
  }

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor} !important;

      .DraftEditor-editorContainer {
        background-color: ${props.backgroundColor};
      }
    `}
`

const Editor = ({
  onChange,
  text,
  id,
  index,
  textColor,
  backgroundColor,
  onChangeTextAlign,
  showAlignmentToolbar,
  textAlign,
  ...rest
}) => {
  const mousePositionRef = useRef({})
  const selectionRef = useRef(null)
  const intl = useIntl()

  const [toolbarSetup, setToolbarSetup] = useState({
    top: 0,
    left: 0,
    display: 'none',
  })
  const [value, setValue] = useState(
    text
      ? RichTextEditor.createValueFromString(text, 'html')
      : RichTextEditor.createEmptyValue()
  )
  const [initialValue, setInitialValue] = useState(
    RichTextEditor.createEmptyValue()
  )

  useEffect(() => {
    if (text) {
      setInitialValue(RichTextEditor.createValueFromString(text, 'html'))
    } else {
      setInitialValue(RichTextEditor.createEmptyValue())
    }
  }, [text])

  const getTextAlignPropertyForIndex = (buttonIndex) => {
    switch (buttonIndex) {
      case 0:
        return TextAlign.LEFT
      case 1:
        return TextAlign.CENTER
      case 2:
        return TextAlign.RIGHT
      case 3:
        return TextAlign.JUSTIFY
      default:
        return TextAlign.LEFT
    }
  }

  useEffect(() => {
    const textAlignButtons = document.querySelectorAll(
      `div[id='rich-text-editor-${id}-${index}'] button[title*='Align ']`
    )
    textAlignButtons.forEach((button, btnIndex) => {
      button.addEventListener('click', (event) => {
        event.stopPropagation()
        onChangeTextAlign(getTextAlignPropertyForIndex(btnIndex))
      })
    })
  }, [id, index, onChangeTextAlign])

  const getAlignClassName = (textAlignValue) => {
    switch (textAlignValue) {
      case TextAlign.CENTER:
        return 'text-align--center'
      case TextAlign.LEFT:
        return 'text-align--left'
      case TextAlign.RIGHT:
        return 'text-align--right'
      case TextAlign.JUSTIFY:
        return 'text-align--justify'
      default:
        return ''
    }
  }

  useEffect(() => {
    const elements = document.querySelectorAll(
      `div[id='rich-text-editor-${id}-${index}'] div[data-block]`
    )
    elements.forEach((element) => {
      if (element && textAlign) {
        element.classList = [getAlignClassName(textAlign)]
      }
    })
  }, [id, index, textAlign])

  const handleChange = (html) => {
    const htmlStr = html.toString('html', { blockStyleFn: getTextAlignStyles })
    if (htmlStr !== value.toString('html')) {
      onChange(htmlStr)
    }
    setValue(html)
  }

  const handleSelectionChange = useCallback(() => {
    const element = document.getElementById(`rich-text-editor-${id}-${index}`)
    if (
      elementContainsSelection(element) &&
      window.getSelection().toString() !== selectionRef.current
    ) {
      const { top, left } = document
        .querySelector(`#rich-text-editor-${id}-${index}`)
        .getBoundingClientRect()

      setToolbarSetup({
        top: mousePositionRef.current.pageY - top - 60,
        left: mousePositionRef.current.pageX - left,
        display: 'block',
      })

      selectionRef.current = window.getSelection().toString()
    } else {
      // setTimeout is needed to wait for the add link container class to
      // appear in DOM to know if this item was selected
      // and avoid closing the toolbar in this case.
      setTimeout(() => {
        const actualLink = element.getElementsByClassName(
          'InputPopover__root___3Hpj9'
        )?.[0] // add link richtexteditor class
        const currSel = window.getSelection().toString()

        if (!(actualLink || currSel)) {
          setToolbarSetup((state) => ({ ...state, display: 'none' }))
        }

        selectionRef.current = currSel
      }, 200)
    }
  }, [id, index])

  const handleMouseMove = useCallback(({ clientX, clientY }) => {
    mousePositionRef.current = { pageX: clientX, pageY: clientY }
  }, [])

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange)
    document.addEventListener('mousemove', handleMouseMove)
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleSelectionChange, handleMouseMove])

  const hasValue = Object.keys(value._cache).length > 0

  if (showAlignmentToolbar) {
    toolbarConfig.BLOCK_ALIGNMENT_BUTTONS = [
      { label: 'Align Left', style: 'ALIGN_LEFT' },
      { label: 'Align Center', style: 'ALIGN_CENTER' },
      { label: 'Align Right', style: 'ALIGN_RIGHT' },
      { label: 'Align Justify', style: 'ALIGN_JUSTIFY' },
    ]
  }
  return (
    <RTEColorWrapper
      id={`rich-text-editor-${id}-${index}`}
      color={textColor}
      backgroundColor={backgroundColor}
    >
      <RichTextEditor
        toolbarConfig={toolbarConfig}
        value={hasValue ? value : initialValue}
        onChange={handleChange}
        placeholder={
          rest.placeholder || intl.messages['richTextEditor.placeholder']
        }
        toolbarStyle={toolbarSetup}
        blockStyleFn={getTextAlignClassName}
        {...rest}
      />
    </RTEColorWrapper>
  )
}

Editor.propTypes = {
  text: PropTypes.string,
  onChange: PropTypes.func,
  onChangeTextAlign: PropTypes.func,
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  showAlignmentToolbar: PropTypes.bool,
  textAlign: PropTypes.string,
}

Editor.defaultProps = {
  text: '',
  onChange: () => {},
  onChangeTextAlign: () => {},
  index: 0,
  textColor: null,
  backgroundColor: null,
  showAlignmentToolbar: false,
  textAlign: TextAlign.LEFT,
}

export default Editor
