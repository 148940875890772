/* eslint-disable jsx-a11y/anchor-is-valid */
import { FormattedMessage } from 'react-intl'
import { Legend, Link } from './SignUpLink.styles'

interface Props {
  onClick: () => void
}

const SignUpLink = ({ onClick }: Props) => (
  <Legend>
    <FormattedMessage id="login.newToUniverse" />
    <Link link type="button" onClick={onClick}>
      <FormattedMessage id="login.signup" />
    </Link>
  </Legend>
)

export default SignUpLink
