import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import List from 'components/List'
import Title from 'components/Title'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import FadeIn from 'components/FadeIn'
import { randomId } from 'utils/functions/number'
import { User } from 'utils/types/user'
import Button from 'ui/Button'
import {
  AddInvestorSendInvitesFormValues,
  Investor,
  InvestorGroupUser,
} from 'utils/types/investors'
import theme from 'utils/theme'
import InvestorUserRow from '../components/Emails/InvestorUserRow'
import { AddMemberHeader } from './EditInvestor.styles'
import InviteInvestorGroupUserForm from '../components/InviteInvestorGroupUserForm'
import { SelectorWrapper } from '../InvestorForm/InvestorForm.styles'

type InvestorGroupUserWithMessage = InvestorGroupUser & {
  message?: string
  isNew?: boolean
}

interface EditInvestorUsersProps {
  investor: Investor
  handleAddUsers: (user: User[], message?: string) => void
  handleDeleteUser: (
    investorGroupUser: InvestorGroupUser,
    undoDeletion: () => void
  ) => void
  handleResendInvite: (investorGroupUser: InvestorGroupUser) => void
}

const EditInvestorUsers: React.FC<EditInvestorUsersProps> = ({
  investor,
  handleAddUsers,
  handleDeleteUser,
  handleResendInvite,
}) => {
  const intl = useIntl()
  const [showInviteUserForm, setShowInviteUserForm] = useState(false)

  const [investorGroupUsers, setInvestorGroupUsers] = useState<
    InvestorGroupUserWithMessage[]
  >(investor.investorGroupUsers)

  const onAddUsers = (values: AddInvestorSendInvitesFormValues) => {
    setShowInviteUserForm(false)
    handleAddUsers(values.users, values.message)

    const newInvestorGroupUsers = values.users.map((user) => ({
      id: randomId(),
      message: values.message,
      isNew: true,
      user,
    }))
    setInvestorGroupUsers((prev) => [...prev, ...newInvestorGroupUsers])
  }

  const undoInvestorGroupUserDeletion =
    (originalIndex: number, investorGroupUser: InvestorGroupUser) => () => {
      setInvestorGroupUsers((prev) => {
        const prevCopy = [...prev]
        prevCopy.splice(originalIndex, 0, investorGroupUser)
        return prevCopy
      })
    }

  const onDeleteUser = (investorGroupUser: InvestorGroupUser) => {
    const originalIndex = investorGroupUsers.findIndex(
      (invUser) => invUser.id === investorGroupUser.id
    )
    setInvestorGroupUsers((prev) =>
      prev.filter((user) => user.id !== investorGroupUser.id)
    )

    handleDeleteUser(
      investorGroupUser,
      undoInvestorGroupUserDeletion(originalIndex, investorGroupUser)
    )
  }

  const onResendInvite = (investorGroupUser: InvestorGroupUser) => {
    handleResendInvite(investorGroupUser)
  }

  return (
    <SelectorWrapper maxWidth="32.7rem">
      <AddMemberHeader>
        <Title
          title={intl.formatMessage({
            id: 'investorManagement.editInvestor.groupMembers',
          })}
          color={theme.colors.darkBlue}
        />
        <Button
          type="button"
          link
          add
          onClick={() => setShowInviteUserForm(true)}
          disabled={showInviteUserForm}
        >
          {intl.formatMessage({
            id: 'investorManagement.editInvestor.addMember',
          })}
        </Button>
      </AddMemberHeader>

      {showInviteUserForm ? (
        <InviteInvestorGroupUserForm
          onAddUsers={onAddUsers}
          onCancel={() => setShowInviteUserForm(false)}
          currentUsers={investorGroupUsers}
        />
      ) : (
        investorGroupUsers.length === 0 && (
          <FadeIn>
            <ZeroState
              type={ZeroStateType.INVESTOR_MEMBERS}
              maxWidth="50rem"
              padding="0"
              infoMarginTop="0"
              textFontSize="1.6rem"
            />
          </FadeIn>
        )
      )}

      <List
        overflow="visible"
        removeMaxHeight
        rowWidthAuto
        rowHeightAuto
        striped={false}
        keyExtractor={(index) => investorGroupUsers[index].id}
        elements={investorGroupUsers.map((investorUser) => (
          <InvestorUserRow
            investorGroupUser={investorUser}
            onDelete={onDeleteUser}
            onResendInvite={onResendInvite}
          />
        ))}
      />
    </SelectorWrapper>
  )
}

export default EditInvestorUsers
