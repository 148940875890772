import { getUser, setBillingPeriod, setPlanId } from 'features/signUpSlice'
import { useEffect, useMemo } from 'react'
import { getUserGroups } from 'selectors/auth'
import { isBillingPeriod, isPlanId } from 'utils/functions/subscriptions'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import useQueryString from 'utils/hooks/useQueryString'
import { SubscriptionBillingPeriod } from 'utils/types/subscriptions'
import { useSignupContext } from '../context/SignupContext'

export const useInvestorAccountSetup = () => {
  const {
    urlPrefix,
    isUpdatingUserInfo,
    onCancel,
    onSetupAccount,
    investorSignup: {
      billingAccount,
      clientSecret,
      paymentError,
      onPlanSelected,
      onClickContinueToPayment,
      onPaymentSuccess,
      goBackToPayment,
      onContinueForUserAddedFromGroupManagement,
    },
  } = useSignupContext()

  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)
  const userGroups = useAppSelector(getUserGroups)
  const userAddedFromGroupManagement = useMemo(() => {
    const hasNoName = !user?.firstName && !user?.lastName
    const hasGroups = Object.keys(userGroups || {}).length > 0
    return hasGroups && hasNoName
  }, [user, userGroups])
  const initialPlan = useQueryString('plan')
  const initialBillingPeriod = useQueryString('billingPeriod')

  useEffect(() => {
    if (isPlanId(initialPlan)) {
      dispatch(setPlanId(initialPlan))
      if (isBillingPeriod(initialBillingPeriod)) {
        dispatch(setBillingPeriod(initialBillingPeriod))
      } else {
        dispatch(setBillingPeriod(SubscriptionBillingPeriod.ANNUALLY))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPlan, initialBillingPeriod])

  return {
    urlPrefix,
    clientSecret,
    billingAccount,
    paymentError,
    isUpdatingUserInfo,
    userAddedFromGroupManagement,
    onPlanSelected,
    onSetupAccount,
    onClickContinueToPayment,
    onPaymentSuccess,
    goBackToPayment,
    onContinueForUserAddedFromGroupManagement,
    onCancel,
  }
}
