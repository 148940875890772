import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
} from 'react'
import { isEmpty } from 'lodash'
import {
  IndexPortfolio,
  Portfolio,
  PortfolioCompany,
  PortfolioCompanyWithFmv,
} from 'utils/types/portfolios'
import { Data } from 'utils/hooks/useInitialData'
import { Industry, Sector } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import { FilteredSummaryData } from 'utils/hooks/investments/useFetchInvestmentsFilteredSummary'
import { Filter } from 'utils/functions/normalizers/investmentFilterNormalizer'
import { Column } from 'ui/Table/types'
import useInvestments from './useInvestments'
import useHandleModals, { FormStep } from './useHandleModals'
import {
  NormalizedPortfolio,
  GroupTag,
  Holding,
  InvestmentsFilters,
  InvestmentsFiltersObject,
} from './InvestmentsFilters/types'
import useEditInvestmentsFilter from './InvestmentsFilters/EditInvestmentsFilterDrawer/useEditInvestmentsFilter'
import useCreateInvestmentsFilter from './InvestmentsFilters/CreateInvestmentsFilterDrawer/useCreateInvestmentsFilter'
import { BaseOption, EntityId, InvestmentsNavigationOption } from './types'

interface InvestmentContextProps {
  // FOR FILTERS IN PROCESS
  filtersForFilterInProcess: InvestmentsFilters
  portfolioHoldingsIdsForFilterInProcess: string[]
  filterInProcessCount: number
  filteredDataForFilterInProcess: {
    data: Nullable<FilteredSummaryData>
    columns: Column<PortfolioCompany>[]
    holdings: PortfolioCompany[]
    isLoading: boolean
    columnsOption: { id: string; status: boolean; label: string }[]
    toggleColumn: (index: number, columnId?: string) => void
  }
  hasFiltersAppliedOnInProcessFilter: boolean
  hasPortfolioFiltersAppliedOnInProcessFilter: boolean
  hasTagsFiltersAppliedOnInProcessFilter: boolean
  hasHoldingsFiltersAppliedOnInProcessFilter: boolean
  hasSectorFiltersAppliedOnInProcessFilter: boolean
  hasIndustryFiltersAppliedOnInProcessFilter: boolean
  industriesToShowForFilterInProcess: Industry[]
  filtersObjectForFilterInProcess: InvestmentsFiltersObject

  onSelectSectorForFilterInProcess: (sector: Sector) => void
  onSelectIndustryForFilterInProcess: (industry: {
    industry: Industry
    sector: Sector
  }) => void
  onAddPortfolioForFilterInProcess: (portfolioId: NormalizedPortfolio) => void
  onRemovePortfolioForFilterInProcess: (
    portfolioId: NormalizedPortfolio
  ) => void
  onRemoveTagForFilterInProcess: (tagId: GroupTag) => void
  onToggleTagForFilterInProcess: (tagId: GroupTag) => void
  onAddHoldingForFilterInProcess: (holding: Holding) => void
  onRemoveHoldingForFilterInProcess: (holding: Holding) => void
  onClearAllForFilterInProcess: ({
    shouldRedirect,
  }: {
    shouldRedirect?: boolean
  }) => void
  isCreateFilterDrawerOpen: boolean
  onOpenCreateFilterDrawer: () => void
  onCloseCreateFilterDrawer: () => void

  // FOR FILTERS ALREADY CREATED
  filtersForFilterAlreadyCreated: InvestmentsFilters
  portfolioHoldingsIdsForCreatedFilter: string[]
  isOriginalFilterChanged: boolean
  isShowingFilterAlreadyCreated: boolean
  hasFiltersAppliedOnAlreadyCreatedFilter: boolean
  hasPortfolioFiltersAppliedOnCreatedFilter: boolean
  hasTagsFiltersAppliedOnCreatedFilter: boolean
  hasHoldingsFiltersAppliedOnCreatedFilter: boolean
  hasSectorFiltersAppliedOnCreatedFilter: boolean
  hasIndustryFiltersAppliedOnCreatedFilter: boolean
  initialData?: Data
  filtersObjectForAlreadyCreatedFilter: InvestmentsFiltersObject
  industriesToShowForAlreadyCreatedFilter: Industry[]
  filteredDataForAlreadyCreatedFilter: {
    data: Nullable<FilteredSummaryData>
    columns: Column<PortfolioCompany>[]
    holdings: PortfolioCompany[]
    isLoading: boolean
    columnsOption: { id: string; status: boolean; label: string }[]
    toggleColumn: (index: number, columnId?: string) => void
  }
  getIndustries: (currentSectorId: string) => Industry[]
  onSelectSectorForAlreadyCreatedFilter: (sector: Sector) => void
  onSelectIndustryForAlreadyCreatedFilter: (industry: {
    industry: Industry
    sector: Sector
  }) => void
  onAddPortfolioForAlreadyCreatedFilter: (
    portfolioId: NormalizedPortfolio
  ) => void
  onRemovePortfolioForAlreadyCreatedFilter: (
    portfolioId: NormalizedPortfolio
  ) => void
  onRemoveTagForAlreadyCreatedFilter: (tagId: GroupTag) => void
  onToggleTagForAlreadyCreatedFilter: (tagId: GroupTag) => void
  onAddHoldingForAlreadyCreatedFilter: (holding: Holding) => void
  onRemoveHoldingForAlreadyCreatedFilter: (holding: Holding) => void
  onClearAllForAlreadyCreatedFilter: ({
    shouldRedirect,
  }: {
    shouldRedirect?: boolean
  }) => void

  setDisplayBigPortfolioNavigation: Dispatch<SetStateAction<boolean>>
  setCreatePortfolioModalOpen: Dispatch<SetStateAction<boolean>>
  onPortfolioCreated: (portfolio: IndexPortfolio) => Promise<void>
  onInvestmentFilterCreated: (filter: Filter) => Promise<void>
  onPortfolioDeleted: (portfolio: Portfolio<PortfolioCompanyWithFmv>) => void
  onFilterDeleted: () => void

  isEditFilterDrawerOpen: boolean
  onOpenEditFilterDrawer: () => void
  onCloseEditFilterDrawer: () => void

  isLoadingPortfolios: boolean
  isLoadingFilters: boolean
  createdFilters?: Filter[]
  isRefetchingFilters: boolean
  normalizedNavigationOptions: Nullable<InvestmentsNavigationOption[]>
  isAllInvestmentOptionInNavigation: boolean
  displayBigPortfolioNavigation: boolean
  createPortfolioModalOpen: boolean
  selectedPortfolioIndex: Nullable<number>
  isMakingPinnedReq: boolean
  portfolioTypeFilterOptions: BaseOption[]
  showPortfolioTypeFilterButton: boolean
  hasPortfolioTypesFiltersApplied: boolean
  editFilterFormStep: FormStep
  fetchedPortfolios?: IndexPortfolio<PortfolioCompany>[]
  showDeleteFilterModal: boolean
  showSaveAsNewPortfolioModal: boolean
  isCreatingFilter: boolean
  showRenameFilterModal: boolean
  hasOneTrackPortfolio: boolean
  handleShowRenameFilterModal: (state: boolean) => void
  handleShowDeleteFilterModal: (state: boolean) => void
  handleShowSaveAsNewPortfolioModal: (state: boolean) => void
  handleSetEditFormStep: (step: FormStep) => void
  resetPortfolioTypeFilter: () => void
  toggleEntityTypeFilter: (id: EntityId) => void
  selectOption: (option: InvestmentsNavigationOption) => void
  changePortfolioName: ({
    portfolio,
    value,
  }: {
    portfolio: IndexPortfolio
    value: string
  }) => Promise<void>
  togglePinPortfolio: ({
    option,
    value,
  }: {
    option: InvestmentsNavigationOption
    value: boolean
  }) => void
}

export const InvestmentContext = createContext<InvestmentContextProps>(
  {} as InvestmentContextProps
)

interface Props {
  children: React.ReactNode
}

export const InvestmentProvider = ({ children }: Props) => {
  const {
    editFilterFormStep,
    showDeleteFilterModal,
    showRenameFilterModal,
    showSaveAsNewPortfolioModal,
    createPortfolioModalOpen,
    setCreatePortfolioModalOpen,
    handleShowRenameFilterModal,
    handleShowDeleteFilterModal,
    handleSetEditFormStep,
    handleShowSaveAsNewPortfolioModal,
  } = useHandleModals()

  const {
    isLoadingPortfolios,
    isLoadingFilters,
    isRefetchingFilters,
    normalizedNavigationOptions,
    fetchedPortfolios,
    isAllInvestmentOptionInNavigation,
    displayBigPortfolioNavigation,
    selectedPortfolioIndex,
    isMakingPinnedReq,
    portfolioTypeFilterOptions,
    showPortfolioTypeFilterButton,
    hasPortfolioTypesFiltersApplied,
    createdFilters,
    isCreatingFilter,
    hasOneTrackPortfolio,
    resetPortfolioTypeFilter,
    toggleEntityTypeFilter,
    selectOption,
    changePortfolioName,
    togglePinPortfolio,
    setDisplayBigPortfolioNavigation,
    onPortfolioCreated,
    onInvestmentFilterCreated,
    onPortfolioDeleted,
    onFilterDeleted,
  } = useInvestments({
    setCreatePortfolioModalOpen,
  })

  const {
    filtersForFilterAlreadyCreated,
    portfolioHoldingsIdsForCreatedFilter,
    isOriginalFilterChanged,
    isShowingFilterAlreadyCreated,
    filteredDataForAlreadyCreatedFilter,
    hasFiltersAppliedOnAlreadyCreatedFilter,
    hasPortfolioFiltersAppliedOnCreatedFilter,
    hasTagsFiltersAppliedOnCreatedFilter,
    hasHoldingsFiltersAppliedOnCreatedFilter,
    hasSectorFiltersAppliedOnCreatedFilter,
    hasIndustryFiltersAppliedOnCreatedFilter,
    initialData,
    industriesToShowForAlreadyCreatedFilter,
    filtersObjectForAlreadyCreatedFilter,
    isEditFilterDrawerOpen,
    getIndustries,
    onSelectSectorForAlreadyCreatedFilter,
    onSelectIndustryForAlreadyCreatedFilter,
    onAddPortfolioForAlreadyCreatedFilter,
    onRemovePortfolioForAlreadyCreatedFilter,
    onRemoveTagForAlreadyCreatedFilter,
    onToggleTagForAlreadyCreatedFilter,
    onAddHoldingForAlreadyCreatedFilter,
    onRemoveHoldingForAlreadyCreatedFilter,
    onClearAllForAlreadyCreatedFilter,
    onOpenEditFilterDrawer,
    onCloseEditFilterDrawer,
  } = useEditInvestmentsFilter({
    portfolios: fetchedPortfolios,
    createdFilters: isEmpty(createdFilters) ? [] : (createdFilters as Filter[]),
  })

  const {
    filtersForFilterInProcess,
    filterInProcessCount,
    filteredDataForFilterInProcess,
    hasFiltersAppliedOnInProcessFilter,
    hasPortfolioFiltersAppliedOnInProcessFilter,
    hasTagsFiltersAppliedOnInProcessFilter,
    hasHoldingsFiltersAppliedOnInProcessFilter,
    hasSectorFiltersAppliedOnInProcessFilter,
    hasIndustryFiltersAppliedOnInProcessFilter,
    industriesToShowForFilterInProcess,
    filtersObjectForFilterInProcess,
    isCreateFilterDrawerOpen,
    portfolioHoldingsIdsForFilterInProcess,
    onSelectSectorForFilterInProcess,
    onSelectIndustryForFilterInProcess,
    onAddPortfolioForFilterInProcess,
    onRemovePortfolioForFilterInProcess,
    onRemoveTagForFilterInProcess,
    onToggleTagForFilterInProcess,
    onAddHoldingForFilterInProcess,
    onRemoveHoldingForFilterInProcess,
    onClearAllForFilterInProcess,
    onOpenCreateFilterDrawer,
    onCloseCreateFilterDrawer,
  } = useCreateInvestmentsFilter({
    portfolios: fetchedPortfolios,
    isRefetchingFilters,
  })

  const value = useMemo(
    () => ({
      isShowingFilterAlreadyCreated,
      portfolioHoldingsIdsForFilterInProcess,
      portfolioHoldingsIdsForCreatedFilter,
      filterInProcessCount,
      isOriginalFilterChanged,
      initialData,
      filtersForFilterAlreadyCreated,
      filteredDataForAlreadyCreatedFilter,
      hasFiltersAppliedOnAlreadyCreatedFilter,
      hasPortfolioFiltersAppliedOnCreatedFilter,
      hasTagsFiltersAppliedOnCreatedFilter,
      hasHoldingsFiltersAppliedOnCreatedFilter,
      hasSectorFiltersAppliedOnCreatedFilter,
      hasIndustryFiltersAppliedOnCreatedFilter,
      industriesToShowForAlreadyCreatedFilter,
      filtersObjectForAlreadyCreatedFilter,
      isLoadingPortfolios,
      isLoadingFilters,
      normalizedNavigationOptions,
      isAllInvestmentOptionInNavigation,
      displayBigPortfolioNavigation,
      createPortfolioModalOpen,
      selectedPortfolioIndex,
      isMakingPinnedReq,
      portfolioTypeFilterOptions,
      showPortfolioTypeFilterButton,
      hasPortfolioTypesFiltersApplied,
      editFilterFormStep,
      showDeleteFilterModal,
      showSaveAsNewPortfolioModal,
      isCreatingFilter,
      fetchedPortfolios,
      showRenameFilterModal,
      isEditFilterDrawerOpen,
      filtersForFilterInProcess,
      filteredDataForFilterInProcess,
      hasFiltersAppliedOnInProcessFilter,
      hasPortfolioFiltersAppliedOnInProcessFilter,
      hasTagsFiltersAppliedOnInProcessFilter,
      hasHoldingsFiltersAppliedOnInProcessFilter,
      hasSectorFiltersAppliedOnInProcessFilter,
      hasIndustryFiltersAppliedOnInProcessFilter,
      industriesToShowForFilterInProcess,
      filtersObjectForFilterInProcess,
      isCreateFilterDrawerOpen,
      createdFilters,
      isRefetchingFilters,
      hasOneTrackPortfolio,
      onSelectSectorForFilterInProcess,
      onSelectIndustryForFilterInProcess,
      onAddPortfolioForFilterInProcess,
      onRemovePortfolioForFilterInProcess,
      onRemoveTagForFilterInProcess,
      onToggleTagForFilterInProcess,
      onAddHoldingForFilterInProcess,
      onRemoveHoldingForFilterInProcess,
      onClearAllForFilterInProcess,
      onOpenCreateFilterDrawer,
      onCloseCreateFilterDrawer,
      getIndustries,
      onOpenEditFilterDrawer,
      onCloseEditFilterDrawer,
      onSelectSectorForAlreadyCreatedFilter,
      onSelectIndustryForAlreadyCreatedFilter,
      onAddPortfolioForAlreadyCreatedFilter,
      onRemovePortfolioForAlreadyCreatedFilter,
      onRemoveTagForAlreadyCreatedFilter,
      onToggleTagForAlreadyCreatedFilter,
      onAddHoldingForAlreadyCreatedFilter,
      onRemoveHoldingForAlreadyCreatedFilter,
      onClearAllForAlreadyCreatedFilter,
      handleShowRenameFilterModal,
      resetPortfolioTypeFilter,
      toggleEntityTypeFilter,
      togglePinPortfolio,
      selectOption,
      changePortfolioName,
      setDisplayBigPortfolioNavigation,
      setCreatePortfolioModalOpen,
      onPortfolioCreated,
      onInvestmentFilterCreated,
      onPortfolioDeleted,
      onFilterDeleted,
      handleShowDeleteFilterModal,
      handleSetEditFormStep,
      handleShowSaveAsNewPortfolioModal,
    }),
    [
      isShowingFilterAlreadyCreated,
      portfolioHoldingsIdsForFilterInProcess,
      portfolioHoldingsIdsForCreatedFilter,
      filterInProcessCount,
      isOriginalFilterChanged,
      initialData,
      filtersForFilterAlreadyCreated,
      filteredDataForAlreadyCreatedFilter,
      hasFiltersAppliedOnAlreadyCreatedFilter,
      hasPortfolioFiltersAppliedOnCreatedFilter,
      hasTagsFiltersAppliedOnCreatedFilter,
      hasHoldingsFiltersAppliedOnCreatedFilter,
      hasSectorFiltersAppliedOnCreatedFilter,
      hasIndustryFiltersAppliedOnCreatedFilter,
      industriesToShowForAlreadyCreatedFilter,
      filtersObjectForAlreadyCreatedFilter,
      isLoadingPortfolios,
      isLoadingFilters,
      normalizedNavigationOptions,
      isAllInvestmentOptionInNavigation,
      displayBigPortfolioNavigation,
      createPortfolioModalOpen,
      selectedPortfolioIndex,
      isMakingPinnedReq,
      portfolioTypeFilterOptions,
      showPortfolioTypeFilterButton,
      hasPortfolioTypesFiltersApplied,
      editFilterFormStep,
      showDeleteFilterModal,
      showSaveAsNewPortfolioModal,
      isCreatingFilter,
      fetchedPortfolios,
      showRenameFilterModal,
      isEditFilterDrawerOpen,
      filtersForFilterInProcess,
      filteredDataForFilterInProcess,
      hasFiltersAppliedOnInProcessFilter,
      hasPortfolioFiltersAppliedOnInProcessFilter,
      hasTagsFiltersAppliedOnInProcessFilter,
      hasHoldingsFiltersAppliedOnInProcessFilter,
      hasSectorFiltersAppliedOnInProcessFilter,
      hasIndustryFiltersAppliedOnInProcessFilter,
      industriesToShowForFilterInProcess,
      filtersObjectForFilterInProcess,
      isCreateFilterDrawerOpen,
      createdFilters,
      isRefetchingFilters,
      hasOneTrackPortfolio,
      onSelectSectorForFilterInProcess,
      onSelectIndustryForFilterInProcess,
      onAddPortfolioForFilterInProcess,
      onRemovePortfolioForFilterInProcess,
      onRemoveTagForFilterInProcess,
      onToggleTagForFilterInProcess,
      onAddHoldingForFilterInProcess,
      onRemoveHoldingForFilterInProcess,
      onClearAllForFilterInProcess,
      onOpenCreateFilterDrawer,
      onCloseCreateFilterDrawer,
      getIndustries,
      onOpenEditFilterDrawer,
      onCloseEditFilterDrawer,
      onSelectSectorForAlreadyCreatedFilter,
      onSelectIndustryForAlreadyCreatedFilter,
      onAddPortfolioForAlreadyCreatedFilter,
      onRemovePortfolioForAlreadyCreatedFilter,
      onRemoveTagForAlreadyCreatedFilter,
      onToggleTagForAlreadyCreatedFilter,
      onAddHoldingForAlreadyCreatedFilter,
      onRemoveHoldingForAlreadyCreatedFilter,
      onClearAllForAlreadyCreatedFilter,
      handleShowRenameFilterModal,
      resetPortfolioTypeFilter,
      toggleEntityTypeFilter,
      togglePinPortfolio,
      selectOption,
      changePortfolioName,
      setDisplayBigPortfolioNavigation,
      setCreatePortfolioModalOpen,
      onPortfolioCreated,
      onInvestmentFilterCreated,
      onPortfolioDeleted,
      onFilterDeleted,
      handleShowDeleteFilterModal,
      handleSetEditFormStep,
      handleShowSaveAsNewPortfolioModal,
    ]
  )

  return (
    <InvestmentContext.Provider value={value}>
      {children}
    </InvestmentContext.Provider>
  )
}

export const useInvestmentsFiltersContext = () => useContext(InvestmentContext)
