import { useMemo } from 'react'
import { PortfolioTypes } from 'utils/constants/portfolio'
import usePortfoliosQuery from 'utils/hooks/usePortfolioQuery'

export const useFirstPipeline = () => {
  const { data: portfolios, isLoading: isLoadingFirstPipeline } =
    usePortfoliosQuery({
      portfolioType: PortfolioTypes.TRACK,
      page: 1,
      perPage: 1,
    })
  const firstPipeline = useMemo(
    () => portfolios?.pages[0]?.portfolios[0],
    [portfolios]
  )

  return {
    firstPipeline,
    isLoadingFirstPipeline,
  }
}
