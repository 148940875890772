import { useIntl } from 'react-intl'
import theme from 'utils/theme'
import { Button, ButtonsWrapper } from './EmailActions.styles'
import { useBreadcrumbActionContext } from '../BreadcrumbActionPortal'
import { Divider, MainWrapper } from './UpdateActions.styles'
import MoreRecipientsAction from './MoreRecipientsAction/MoreRecipientsAction'

interface EmailActionsProps {
  isSubmitting: boolean
  toggleAdditionalRecipientsModal: () => void
  handleSubmit: () => void
  onCancel: () => void
}

const EditEmailActions = ({
  isSubmitting,
  handleSubmit,
  onCancel,
  toggleAdditionalRecipientsModal,
}: EmailActionsProps) => {
  const intl = useIntl()
  const { showActionsBelow } = useBreadcrumbActionContext()

  return (
    <MainWrapper showActionsBelowBreadcrumb={showActionsBelow}>
      <>
        <MoreRecipientsAction
          handleAddMoreRecipients={toggleAdditionalRecipientsModal}
        />

        <Divider
          color={
            showActionsBelow
              ? theme.colors.veryLightBlue
              : theme.colors.veryLightGray
          }
        />
      </>

      <ButtonsWrapper showEmailActionsBelow={showActionsBelow}>
        <Button border onClick={onCancel} disabled={isSubmitting}>
          {intl.formatMessage({ id: 'buttons.cancel' })}
        </Button>

        <Button primary onClick={handleSubmit} disabled={isSubmitting}>
          {intl.formatMessage({ id: 'buttons.publish' })}
        </Button>
      </ButtonsWrapper>
    </MainWrapper>
  )
}

export default EditEmailActions
