import React, { useState } from 'react'
import { useDebouncedValue } from 'utils/hooks/useDebouncedValue'

export function useDebouncedState<T>(
  initialValue: T,
  delay: number = 300
): [T, T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialValue)

  const debouncedState = useDebouncedValue(state, delay)

  return [state, debouncedState, setState]
}
