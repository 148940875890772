import styled from 'styled-components'
import IconButton from 'ui/IconButton'
import { maxSize } from 'utils/constants/breakpoint'

export const ListFile = styled.div`
  width: 100%;
  background-color: var(--color-gray-200);
  padding: 1rem;
  border-radius: 20rem;
  height: 5rem;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;

  .icon {
    width: 3rem;
    height: 3rem;
  }
`
export const FileInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 85%;
  height: 100%;
  gap: 1rem;

  @media ${maxSize.md} {
    max-width: 100%;
  }

  @media ${maxSize.sm} {
    max-width: 25rem;
  }

  .file-icon {
    height: unset;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto 1rem;
    border-radius: 50%;
  }

  span {
    font-size: 1.6rem;
    margin: auto 0;
    color: var(--color-gray-600);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    font-size: 1.6rem;
    margin: auto 2rem;
    color: var(--color-gray-600);
    font-weight: 700;
  }
`

export const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 42rem;
`

export const DeleteButton = styled(IconButton)`
  height: 3rem;
  width: 3rem;
  margin-right: 0.3rem;
`
