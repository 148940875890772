import { FormattedMessage } from 'react-intl'
import { NoChatsContainer, NoChatsText } from './NoChats.styles'

const NoChats = () => {
  return (
    <NoChatsContainer>
      <NoChatsText>
        <FormattedMessage id="chat.noChats" />
      </NoChatsText>
    </NoChatsContainer>
  )
}

export default NoChats
