import { useCallback, useState } from 'react'
import dayjs from 'dayjs'

import Toast from 'components/Toast'
import { InboundForm } from 'utils/types/inbounds'
import { useUserByIdQuery } from 'utils/hooks/queries/useUserQuery'
import { useTestConnection } from 'containers/Inbounds/hooks/useTestConnection'

import {
  useEditInboundMutation,
  useInboundByIdQuery,
  useDeleteInbound,
} from '../../hooks'

interface Props {
  inboundId: string
  onClose: () => void
}

export const useInboundSettings = ({ inboundId, onClose }: Props) => {
  const { data: inbound, isLoading } = useInboundByIdQuery(inboundId)
  const { testConnection, isTestingConnection } = useTestConnection(
    inboundId,
    inbound?.lastConnection ?? undefined
  )
  const [hasTestedConnection, setHasTestedConnection] = useState(false)
  const { mutateAsync: editAsync, isLoading: isSaving } =
    useEditInboundMutation(inboundId)

  const { onDelete, isLoading: isDeleting } = useDeleteInbound({
    inbound,
    onDeleteSuccess: onClose,
  })
  const { data: inboundOwner } = useUserByIdQuery(inbound?.userId)

  const handleEditInbound = useCallback(
    async (values: Partial<InboundForm>) => {
      try {
        await editAsync({
          groupManaged: values.allowMyGroupToReceiveSuggestedUpdates,
        })
        onClose()
      } catch {
        Toast.displayIntl('inbounds.editInbound.error', 'error')
      }
    },
    [editAsync, onClose]
  )

  const onCheckStatusClick = () => {
    setHasTestedConnection(true)
    testConnection()
  }

  const displayDate = (date: string) => {
    return dayjs(date).format('MMM D, YYYY - HH:mm')
  }

  return {
    inbound,
    isLoading,
    isDeleting,
    isSaving,
    handleDeleteInbound: onDelete,
    handleEditInbound,
    displayDate,
    isTestingConnection,
    hasTestedConnection,
    onCheckStatusClick,
    inboundOwner,
  }
}
