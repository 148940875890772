import styled from 'styled-components/macro'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const PercentageRow = styled.div`
  color: ${color('darkGray')};
  font-size: 1.2rem;
  font-weight: normal;
  padding-bottom: 0.4rem;
  text-align: left;
  width: max-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 25rem;

  @media ${size.xsm} {
    max-width: 14rem;
  }

  @media ${maxSize.md} {
    font-size: 1.5rem;
  }
`

export const LegendIndicator = styled.span<{ backgroundColor: string }>`
  border-radius: 100%;
  background-color: ${(props) => props.backgroundColor};
  display: inline-block;
  height: 0.9rem;
  margin-right: 0.8rem;
  width: 0.9rem;
`
export const ChartWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  margin-bottom: 2rem;
  margin-left: 2rem;

  @media ${maxSize.xl} {
    margin-left: none;
    margin-bottom: 2rem;
    grid-row: 1;
    flex-direction: column;
  }
`

export const ResultContainer = styled.div`
  align-self: center;
  margin-right: 1rem;
  padding: 1rem 0;
  width: 15rem;

  @media ${maxSize.lg} {
    margin: 0;
  }

  @media ${maxSize.md} {
    padding: 0.5rem 0 1rem;
  }
`
