export type Color = keyof typeof theme.colors
export type DataVisualizationColor = keyof typeof theme.dataVisualizationColors

const theme = {
  fonts: ['Lato', 'Raleway'],
  fontWeight: {
    regular: 'regular',
    semiBold: 'semi-bold',
    bold: 'bold',
  },
  fontSizes: {
    small: '1.2rem',
    body: '1.4rem',
    subtitle: '1.8rem',
    header2: '2.4rem',
    header1: '3.2rem',
  },
  dataVisualizationColors: {
    blue: '#4975E7',
    brightBlue: '#2196F3',
    skyBlue: '#00BCD4',
    aquaGreen: '#38B8A5',
    darkGreen: '#56A55A',
    green: '#8BC34A',
    yellowGreen: '#CDDC39',
    yellow: '#E7D752',
    darkYellow: '#FDBE00',
    orange: '#FF9800',
    darkOrange: '#EA6C44',
    red: '#E85464',
    darkRed: '#CA4270',
    darkPink: '#C047BB',
    purple: '#8E67CE',
    bluePurple: '#6771CE',
    veryLightBlue: '#C9CFE4',
  },
  colors: {
    primaryBlue: '#194590',
    primaryDarkBlue: '#112F63',
    mainBlue: '#214B7D',
    darkBlue: '#101527',
    softDarkBlue: '#202537',
    softBlue: '#F2F4F6',
    brightBlue: '#4E74DA',
    lightBlue: '#8ca5e7',
    veryLightBlue: '#C9CFE4',
    veryLightBlue40: '#e9ecf4',
    veryLightBlue40WithOpacity: '#C9CFE466',
    softLightBlue: '#4975E7',
    softLightBlue2: '#2196F3',

    red: '#E75767',
    lightRed: '#f1949d',
    softRed: '#FAECED',
    darkRed: '#C14956',

    pink: '#D778D4',

    yellow: '#FCBE2C',
    darkYellow: '#D9A427',
    lightYellow: '#fed480',

    green: '#44C2AF',
    darkGreen: '#389E8F',
    diffGreen: '#6fcf97',
    softGreen: '#00BCD4',
    superSoftGreen: '#ECFAF8',

    gray: '#313748',
    secondaryGray: '#444444',
    darkGray: '#4A4A68',
    veryDarkGray: '#535659',
    lightGray: '#787E8C',
    borderGray: '#DFE6ED',
    veryLightGray: '#E9E9E9',
    draftGray: '#97A4BA',
    softGray: '#F6F6F6',
    lightDraftGray: '#BBC4D2',
    suggestedGray: '#d7d8dc',
    labelGray: '#97A4BA',
    softLightGray: '#B0B8D2',
    gray700: '#333333',
    backgroundGray: '#EEEEEE',

    veryLightWhite: '#dbe3fa',
    whiteSmoke: '#F4F5F7',
    lightWhite: '#F0F2F3',
    cloud: '#FAFCFE',
    white: '#FFFFFF',
    black: '#111111',
    strongBlack: '#000',
    orange: '#FF8F38',
    lightOrange: '#ffb783',
    darkOrange: '#F09A38',
    purple: '#886FB1',
    lightPurple: '#b2a1cc',
    darkPurple: '#9173E8',
    skyBlue: '#4DCEE3',
    skyLightBlue: '#6FC2F1',

    bigStone: '#141D37',
    blueWhale: '#04273A',
    portGore: '#211E3E',
    revolver: '#2C1E3E',

    chatMessage: '#E6EAF3',
    chatMessageHover: '#F0F3F9',
    chatBorder: '#E0E3E7',
    celesteLight: '#00AEFF',
    celesteDark: '#3A59BA',

    defaultSkeletonShine: '#F0F2F5',
    defaultSkeletonShine2: '#E9ECF2',
    filesGridShine: '#DEDEDE',

    blackShadow: '#1015271A',
  },
}

export default theme

export type Theme = typeof theme
