import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Section = css`
  gap: 1.6rem;
`

export const TitleStyles = css`
  color: ${color('darkBlue')};
`

export const Wrapper = styled.div`
  .imageStyle {
    min-width: 1.8rem;
    height: 1.8rem;
    color: var(--color-blue-strong);
  }
`
