import { FC } from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'components/Tooltip'

import { IconLegendWrapper } from './IconLegend.styles'

interface Props {
  icon?: IconProp
  legend?: string | number
  title: string
  bold?: boolean
  link?: boolean
  disabled?: boolean
  hasTooltip?: boolean
}

const IconLegend: FC<Props> = ({
  icon,
  legend,
  title,
  bold = false,
  link = false,
  disabled = false,
  hasTooltip = false,
}) => {
  const renderIconLegend = () => {
    return (
      <IconLegendWrapper
        title={!hasTooltip ? title : undefined}
        bold={bold}
        link={link}
        disabled={disabled}
      >
        {icon && <FontAwesomeIcon icon={icon} />}
        {legend && <span>{legend}</span>}
      </IconLegendWrapper>
    )
  }

  if (hasTooltip) {
    return (
      <Tooltip text={title} place="bottom">
        {renderIconLegend()}
      </Tooltip>
    )
  }

  return renderIconLegend()
}

export default IconLegend
