import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Details = styled.div`
  font-family: 'Lato';
  font-size: 1.4rem;
  font-style: normal;
  margin-bottom: 4rem;
  user-select: none;
`

export const Title = styled.h2`
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${color('gray')};
  margin-bottom: 0.4rem;
`

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
`

export const Label = styled.span`
  color: ${color('lightGray')};
  font-weight: 400;
`

export const Value = styled.span`
  color: ${color('darkBlue')};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const AvatarName = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${color('gray')};
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 13rem;
`

export const ValueColumnWrapper = styled.div`
  color: ${color('darkBlue')};
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`
export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const PortfolioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const PortfolioIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${color('veryLightGray')};
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: ${color('primaryBlue')};
  color: ${color('white')};
`
