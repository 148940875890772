import { useIntl } from 'react-intl'

import Input from 'ui/Input'
import type { InputProps } from 'ui/Input'
import { useField } from '../hooks'

interface FormikInputProps extends InputProps {
  name: string
  intlLabel?: string
  intlPlaceholder?: string
}

const FormikInput = ({
  name,
  intlLabel,
  intlPlaceholder,
  label,
  placeholder,
  ...props
}: FormikInputProps) => {
  const intl = useIntl()
  const [field] = useField(name)

  return (
    <Input
      id={name}
      name={name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      placeholder={
        intlPlaceholder
          ? intl.formatMessage({ id: intlPlaceholder })
          : placeholder
      }
      label={intlLabel ? intl.formatMessage({ id: intlLabel }) : label}
      error={field.buildError()}
      {...props}
    />
  )
}

export default FormikInput
