export const DefaultAudio = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="file-audio 1" clipPath="url(#clip0_7690_533768)">
      <path
        id="Vector"
        d="M10.6705 3.26107L8.70451 1.29508C8.49353 1.08411 8.20738 0.965578 7.90902 0.965576H3.125C2.50367 0.965576 2 1.46925 2 2.09058V11.8406C2 12.4619 2.50367 12.9656 3.125 12.9656H9.875C10.4963 12.9656 11 12.4619 11 11.8406V4.05656C11 3.75819 10.8815 3.47205 10.6705 3.26107ZM9.78402 3.96558H8V2.18156L9.78402 3.96558ZM3.125 11.8406V2.09058H6.875V4.52808C6.875 4.83874 7.12684 5.09058 7.4375 5.09058H9.875V11.8406H3.125ZM6.5 10.0588C6.5 10.3093 6.19705 10.4348 6.01988 10.2576L5.1875 9.41447H4.53125C4.37593 9.41447 4.25 9.28854 4.25 9.13322V7.82072C4.25 7.6654 4.37593 7.53947 4.53125 7.53947H5.1875L6.01988 6.67352C6.19707 6.49633 6.5 6.62182 6.5 6.87239V10.0588ZM7.46565 8.95415C7.67778 8.73626 7.67799 8.38854 7.46567 8.17043C6.94655 7.63718 7.75243 6.85216 8.2718 7.38572C8.90926 8.04056 8.90959 9.08362 8.27183 9.73886C7.76105 10.2635 6.93713 9.49711 7.46565 8.95415Z"
        fill="#313748"
      />
    </g>
    <defs>
      <clipPath id="clip0_7690_533768">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.965576)"
        />
      </clipPath>
    </defs>
  </svg>
)
