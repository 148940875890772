import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import TextArea from 'ui/TextArea'
import RadioButton from 'ui/RadioButton'

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2.4rem;
  padding-bottom: 2.4rem;
  margin: 0 auto;
  max-width: 102.4rem;

  @media (max-width: 500px) {
    max-width: 100%;
    margin-top: 0;
  }
`

export const Title = styled.div`
  font-size: 3.2rem;
  color: ${color('strongBlack')};
  margin-top: 3rem;
  margin-bottom: 1.6rem;

  @media (max-width: 500px) {
    margin-top: 0;
  }
`

export const Description = styled.div`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  margin-bottom: 2.3rem;
`

export const Question = styled.div`
  font-size: 2.4rem;
  color: ${color('darkGray')};
  margin-bottom: 1.6rem;
`

export const StyledTextArea = styled(TextArea)`
  /* width: 100%; */
`

export const RadioButtons = styled.div`
  margin-top: -1rem;
  margin-bottom: 2.4rem;
`
export const StyledRadioButton = styled(RadioButton)`
  gap: 0;
`

export const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.2rem;
`
