import { components } from 'react-select'
import { FormattedMessage } from 'react-intl'
import * as Styles from '../ShareWithDropdown.styles'

export const Menu = ({ children, ...props }) => {
  return (
    <components.Menu {...props}>
      {!props.isLoading && !props.selectProps.inputValue && (
        <Styles.OptionsTitle>
          <FormattedMessage id="updates.recentlyUsedGroupsListsAndUsers" />
        </Styles.OptionsTitle>
      )}
      {children}
    </components.Menu>
  )
}
