import { Integrations, IntegrationStatus } from 'utils/constants/integrations'

import axiosClient from './httpClient'

export type Connection =
  | {
      connectionId: string
      status: IntegrationStatus
      error?: string
    }
  | {
      status: IntegrationStatus.DISCONNECTED
    }

export type Connections = {
  xero?: Connection
  quickbooks?: Connection
}

class IntegrationService {
  static getIntegrationTypeUrl = (integrationType) => {
    switch (integrationType) {
      case Integrations.XERO:
        return 'xero_connection'
      case Integrations.QUICKBOOKS:
        return 'quickbooks_connection'

      default:
        return ''
    }
  }

  static setupConnection = async (integrationType) => {
    return axiosClient().post(
      `/celeste/${this.getIntegrationTypeUrl(integrationType)}`
    )
  }

  static connectAccount = async ({
    integrationType,
    connectionId,
    authorizationCode,
    redirectUri,
    state,
  }) => {
    return axiosClient().post(
      `/celeste/${this.getIntegrationTypeUrl(
        integrationType
      )}/${connectionId}/connect`,
      {
        authorizationCode,
        redirectUri,
        state,
      }
    )
  }

  static setConnectionError = async ({
    integrationType,
    connectionId,
    error,
    state,
  }) => {
    return axiosClient().post(
      `/celeste/${this.getIntegrationTypeUrl(
        integrationType
      )}/${connectionId}/error`,
      {
        error,
        state,
      }
    )
  }

  static removeConnection = (integrationType, connectionId) => {
    return axiosClient().delete(
      `/celeste/${this.getIntegrationTypeUrl(integrationType)}/${connectionId}`
    )
  }

  static processConnections = (connections): Connection => {
    if (connections.count > 0) {
      const successConnection = connections.data.find(
        (connection) => connection.status === IntegrationStatus.CONNECTED
      )
      if (successConnection) {
        return {
          status: successConnection.status,
          connectionId: successConnection.id,
        }
      }
      const errorConnection = connections.data.find(
        (connection) => connection.status === IntegrationStatus.ERROR
      )
      if (errorConnection) {
        return {
          status: errorConnection.status,
          connectionId: errorConnection.id,
          error: errorConnection.error,
        }
      }
    }
    return { status: IntegrationStatus.DISCONNECTED }
  }

  static getConnectionStatus = async (integrationType) => {
    const response = await axiosClient().get(
      `/celeste/${this.getIntegrationTypeUrl(integrationType)}`
    )
    return this.processConnections(response.data)
  }

  static getIntegrationsConnectionStatuses = async (): Promise<Connections> => {
    const { data } = await axiosClient().get('/celeste/connections')
    return {
      xero: this.processConnections(data.xero),
      quickbooks: this.processConnections(data.quickbooks),
    }
  }
}

export default IntegrationService
