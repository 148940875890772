import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const TimelineListItem = styled.li`
  position: relative;
  padding-left: 2.2rem;

  ${({ hideDottedLine }) =>
    !hideDottedLine &&
    css`
      &:last-child > span:first-child {
        display: none;
      }
    `}
`

export const TimelineItemLine = styled.span`
  min-height: 8rem;
  position: absolute;
  top: 0.5rem;
  width: 0;
  border-right: 0.1rem dashed ${color('veryLightBlue', 0.75)};
  height: ${(props) => props.height};
  left: ${(props) => props.left || '0.4rem'};
`

export const TimelineItemDot = styled.span`
  left: 0;
  position: absolute;
  text-align: center;
  top: 0.5rem;

  ${(props) =>
    props.customIcon &&
    css`
      top: 0;
    `}
`

export const TimelineItemContent = styled.div`
  margin-left: 0.8rem;
  padding: 0 2.7rem;
  ${(props) =>
    props.padding
      ? css`
          padding: ${props.padding};
        `
      : ''}

  @media (max-width: 639px) {
    padding: 0 1rem;
  }
`
