import { ReactNode } from 'react'
import { Nullable } from 'utils/types/common'
import { LogsBuilder } from './LogsBuilder'
import { AddressLogTextBuilder } from '../text-builders/AddressLogTextBuilder'
import { DefaultLogTextBuilder } from '../text-builders/DefaultLogTextBuilder'
import { FinancialLogTextBuilder } from '../text-builders/FinancialLogTextBuilder'
import { EventType, LogTextBuilder } from '../types'
import { InvestmentVehicleLogTextBuilder } from '../text-builders/InvestmentVehicleLogTextBuilder'

export type InvestmentVehicleLogChangeSetFields = {
  name: string
  routingNumber: string
  bankAccount: string
  bankName: string
  taxId: string
  geographyPlacesId: string
  phone: string
  position: string
}

export class InvestmentVehicleLogsBuilder extends LogsBuilder<InvestmentVehicleLogChangeSetFields> {
  private defaultLogTextBuilder: LogTextBuilder = new DefaultLogTextBuilder(
    LogsBuilder.intl.formatMessage({ id: 'logs.vehicle' })
  )

  private investmentVehicleLogTextBuilder: LogTextBuilder =
    new InvestmentVehicleLogTextBuilder(
      LogsBuilder.intl.formatMessage({ id: 'logs.investor' })
    )

  private financialLogTextBuilder: LogTextBuilder =
    new FinancialLogTextBuilder()

  private addressLogTextBuilder: LogTextBuilder = new AddressLogTextBuilder()

  protected getTextBuilderForField(): Nullable<LogTextBuilder> {
    if (
      ['routingNumber', 'bankAccount', 'bankName', 'taxId'].includes(
        this.log.fieldName
      )
    ) {
      return this.financialLogTextBuilder
    }

    if (['geographyPlacesId', 'position'].includes(this.log.fieldName)) {
      return this.addressLogTextBuilder
    }

    if (['phone'].includes(this.log.fieldName)) {
      return this.defaultLogTextBuilder
    }

    if (
      this.log.event === EventType.CREATE ||
      ['name'].includes(this.log.fieldName)
    ) {
      return this.investmentVehicleLogTextBuilder
    }

    return null
  }

  public getOperationIcon(): ReactNode {
    switch (this.log.event) {
      case EventType.ADD_LOCATION:
        if (this.log.fieldName === 'position' && this.log.change[1] === 1)
          return LogsBuilder.getUpdateIcon()

        return LogsBuilder.getCreateIcon()
      case EventType.UPDATE_LOCATION:
        return LogsBuilder.getUpdateIcon()
      case EventType.REMOVE_LOCATION:
        return LogsBuilder.getDestroyIcon()
      default:
        return super.getOperationIcon()
    }
  }
}
