import React from 'react'
import Button from 'ui/Button'
import FileIcon from 'components/FileIcon'
import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { gridItemAnimation } from 'utils/animations/grid'

import { displayFileSize } from 'utils/functions/files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OptionsDropdown from 'ui/OptionsDropdown'
import { useDownloadFile } from 'utils/hooks/files/useDownloadFile'
import { FileContent, isFileFormatImage } from 'utils/types/files'

import {
  CardWrapper,
  CardContainer,
  FileIconWrapper,
  Content,
  FileName,
  FileLabel,
  Footer,
  DownloadsIndicator,
  DropdownContainer,
  CardImagePreview,
} from '../AttachmentCard.styles'
import { useAttachmentAnalytics } from '../../AttachmentSidebar/useAttachmentAnalytics'

interface AttachmentGridCardProps {
  attachment: FileContent
  onSelect: () => void
  isSelected: boolean
}
const AttachmentGridCard: React.FC<AttachmentGridCardProps> = ({
  attachment,
  onSelect,
  isSelected,
}) => {
  const intl = useIntl()
  const { onDownloadFile } = useDownloadFile()
  const { totalDownloads } = useAttachmentAnalytics(attachment)

  const { url, fileName, fileFormat } = attachment

  return (
    <CardWrapper
      variants={gridItemAnimation}
      data-testid="attachments-grid-card"
      role="button"
      onClick={onSelect}
      isSelected={isSelected}
    >
      <CardContainer>
        <FileIconWrapper>
          {isFileFormatImage(fileFormat) ? (
            <CardImagePreview src={url} />
          ) : (
            <FileIcon type={fileFormat} src={url} useBackgroundImage small />
          )}
        </FileIconWrapper>
        <Content>
          <DropdownContainer>
            <OptionsDropdown
              buttonType="square"
              buttonSize="3.2rem"
              topOffset={-5}
            >
              <OptionsDropdown.Item
                label={intl.formatMessage({ id: 'analytics.viewDetails' })}
                icon={['fal', 'clipboard-list-check']}
                onSelectOption={onSelect}
              />
              <OptionsDropdown.Item
                label={intl.formatMessage({ id: 'analytics.downloadFile' })}
                icon={['fal', 'download']}
                onSelectOption={() => onDownloadFile(attachment)}
              />
            </OptionsDropdown>
          </DropdownContainer>
          <div>
            <FileName title={fileName} maxWidth="10rem">
              {fileName}
            </FileName>
          </div>
          <FileLabel>
            <>
              {intl.formatMessage({ id: 'analytics.added' })}{' '}
              {dayjs(attachment.createdAt).format('DD MMM YYYY')}
            </>
          </FileLabel>
          <FileLabel>{displayFileSize(attachment.fileSize)}</FileLabel>
        </Content>
      </CardContainer>
      <Footer>
        <Button type="button" link onClick={onSelect}>
          {intl.formatMessage({ id: 'analytics.viewDetails' })}
        </Button>
        <DownloadsIndicator>
          <FontAwesomeIcon
            icon={['fal', 'download']}
            title={`${totalDownloads} downloads`}
          />
          <span>{totalDownloads}</span>
        </DownloadsIndicator>
      </Footer>
    </CardWrapper>
  )
}

export default AttachmentGridCard
