import React from 'react'

import UpdateFactory from 'components/UpdateFactory'
import { useSelectUpdatesContext } from 'components/UpdatesFeedV2/contexts/SelectUpdates/SelectableCardsContext'
import { IndexUpdate } from 'utils/types/update'

import FeedIcon from '../FeedIcon'
import { Container } from './FeedCard.styles'

type Props = {
  indexUpdate: IndexUpdate
}

const IndexUpdateFeedCard: React.FC<Props> = ({ indexUpdate }) => {
  const { isSelectingSuggestedUpdates } = useSelectUpdatesContext()

  return (
    <>
      <FeedIcon indexUpdate={indexUpdate} />
      <Container isSelectingSuggestedUpdates={isSelectingSuggestedUpdates}>
        <UpdateFactory update={indexUpdate} />
      </Container>
    </>
  )
}

export default IndexUpdateFeedCard
