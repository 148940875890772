import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { EditorContent as EditorContentToStyle } from '@tiptap/react'
import { color } from 'utils/functions/colors'

export const EditorContent = styled(EditorContentToStyle)<{
  isThin?: boolean
  disabled?: boolean
  hasError?: boolean
  openLinksOnClick?: boolean
  maxLines?: number
}>`
  font-family: 'Lato', sans-serif;
  font-size: 1.4rem;
  line-height: normal;
  width: 100%;

  .tiptap {
    border-radius: 0.8rem;
    border: 0.1rem solid transparent;
    padding: 1rem 1.6rem;
    min-height: ${({ isThin }) => (isThin ? '7.6rem' : '20rem')};
    color: ${color('darkGray')};
    outline: none;
    background: ${color('lightGray', 0.1)};
    transition: 0.2s background-color, 0.1s border;

    ${({ hasError, theme }) =>
      hasError &&
      css`
        background-color: ${color('red', 0.15)({ theme })};
      `}

    // ::Placeholder
    p.is-editor-empty:first-child::before {
      color: ${color('darkGray', 0.5)};
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none;
    }

    &:hover {
      border: 0.1rem solid ${color('veryLightBlue')};
    }

    &:focus {
      background-color: ${color('white')};
      border: ${({ hasError, theme }) =>
        `0.1rem solid ${
          hasError ? color('red')({ theme }) : color('primaryBlue')({ theme })
        }`};
    }

    ${({ readOnly, maxLines }) =>
      readOnly &&
      css`
        min-height: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        background: transparent;

        &:hover {
          border: none;
        }

        ${!!maxLines &&
        css`
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: ${maxLines};
          overflow: hidden;
        `}
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--color-gray-500);
      background-color: var(--color-gray-100);
      cursor: not-allowed;
    `}

  * + *:not(li) {
    margin-top: 0.75em;
  }

  a {
    color: ${color('primaryBlue')};
    text-decoration: underline;

    ${({ openLinksOnClick }) =>
      openLinksOnClick &&
      css`
        :hover {
          cursor: pointer;
        }
      `}
  }

  ul,
  ol {
    padding: 0 2rem;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`
