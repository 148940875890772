import { useIntl } from 'react-intl'
import { getUpdateItemTitle } from 'utils/functions/updates'
import { Update } from 'utils/types/update'
import { LinkWrapper, NavigationItem } from '../Breadcrumb.styles'

interface UpdateLinkProps {
  update?: Update
  isCreatingNewUpdate: boolean
}

const UpdateLink = ({ update, isCreatingNewUpdate }: UpdateLinkProps) => {
  const intl = useIntl()

  if (!update && isCreatingNewUpdate) {
    return (
      <NavigationItem>
        <LinkWrapper isActive>
          {intl.formatMessage({ id: 'breadcrumb.createUpdate' })}
        </LinkWrapper>
      </NavigationItem>
    )
  }

  if (!update) return null

  const updateTitle = getUpdateItemTitle(update)

  return (
    <NavigationItem>
      <NavigationItem>
        <LinkWrapper isActive={!!update}>
          {updateTitle?.length === 0
            ? intl.formatMessage(
                {
                  id: 'updates.drafts.untitled',
                },
                {
                  updateType: intl.formatMessage({
                    id: `updates.updateType.${update.updateType}`,
                  }),
                }
              )
            : updateTitle}
        </LinkWrapper>
      </NavigationItem>
    </NavigationItem>
  )
}

export default UpdateLink
