import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from 'utils/theme'
import Modal from 'components/Modal'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import Checkbox from 'components/Checkbox'
import Title from 'components/Title'
import ToastMessage from 'components/ToastMessage'
import { IconPosition, ToastType } from 'components/ToastMessage/ToastMessage'

import List from './components/ShareWithEntities/List'
import ShareWithEntity from './components/ShareWithEntities/ShareWithEntity'
import ClockworkCommunity from './components/ShareWithEntities/ClockworkCommunity'
import YourGroup from './components/ShareWithEntities/YourGroup'
import Email from './components/ShareWithEntities/Email'
import ShareWithDropdown from './components/ShareWithDropdown/ShareWithDropdown'
import { ShareWithOptionType } from './components/ShareWithDropdown/useShareWithDropdown'
import {
  ShareSettingsModalProps,
  useShareSettingsModal,
} from './useShareSettingsModal'
import * as Styles from './ShareSettingsModal.styles'
import CurrentInvestor from './components/ShareWithEntities/CurrentInvestor'

const ShareSettingsModal: React.FC<ShareSettingsModalProps> = ({
  isModalOpen,
  closeModal,
}) => {
  const intl = useIntl()
  const {
    entitiesSelected,
    showDropdown,
    founderUnverified,
    onCloseDropdown,
    loadOptions,
    currentUser,
    updateOwner,
    currentUserIsOwner,
    isEditMode,
    isShowMode,
    currentGroup,
    yourGroupAccess,
    onYourGroupAccessChange,
    isGroupOwned,
    disableEditGroupPermissions,
    showPublicOption,
    communityAccess,
    onCommunityAccessChange,
    setEntitiesSelected,
    onRecipientRemove,
    getOtherEntitiesSelectedIds,
    showReshareSettings,
    toggleIsConfidential,
    isConfidential,
    saveAndClose,
    cancel,
    investor,
    investorAccess,
    onInvestorAccessChange,
    isOwnGroupSharingEnabled,
    isPrivatePortfolioDisclaimerVisible,
    updateType,
  } = useShareSettingsModal({
    isModalOpen,
    closeModal,
  })

  return (
    <Modal show={isModalOpen} onHide={cancel} hideOnOutsideClick={false}>
      <Modal.Header onHide={cancel}>
        <Modal.Title>
          <FormattedMessage id="updates.shareSettings" />
        </Modal.Title>
      </Modal.Header>
      <Styles.ModalBody>
        {showDropdown && (
          <ShareWithDropdown
            selectedUsers={entitiesSelected}
            loadOptions={loadOptions}
            onClose={onCloseDropdown}
            isDisabled={founderUnverified}
          />
        )}

        {isPrivatePortfolioDisclaimerVisible && (
          <>
            <ToastMessage
              type={ToastType.ALERT}
              icon={['far', 'briefcase']}
              width="fit-content"
              iconPosition={IconPosition.TOP}
            >
              <FormattedMessage
                id={
                  currentGroup && isOwnGroupSharingEnabled
                    ? 'updates.privatePortfolioGroupDisclaimer'
                    : 'updates.privatePortfolioDisclaimer'
                }
                values={{ bold: (msg) => <b>{msg}</b> }}
              />
            </ToastMessage>
            <Separator height="2.4rem" />
          </>
        )}

        <Styles.GroupsContainer
          hasTopMargin={!!showDropdown}
          data-testid="groups-container"
        >
          {isEditMode || isShowMode ? (
            <ShareWithEntity
              name={updateOwner?.name!}
              imageUrl={updateOwner?.image.smallLogo.url}
              isOwner
              showYouLabel={currentUserIsOwner}
            />
          ) : (
            <ShareWithEntity
              name={currentUser.name}
              imageUrl={currentUser.image.smallLogo.url}
              isOwner
              showYouLabel
            />
          )}
          {currentGroup && isOwnGroupSharingEnabled && (
            <YourGroup
              name={currentGroup.name}
              imageUrl={currentGroup.logo?.smallLogo.url}
              access={yourGroupAccess!}
              onAccessChange={onYourGroupAccessChange}
              updateType={updateType}
              isGroupOwned={isGroupOwned}
              disableEditGroupPermissions={disableEditGroupPermissions}
            />
          )}
          {showPublicOption && (
            <ClockworkCommunity
              access={communityAccess!}
              onAccessChange={onCommunityAccessChange}
              disabled={founderUnverified}
            />
          )}
          {investor && (
            <CurrentInvestor
              name={investor.name}
              access={investorAccess!}
              onAccessChange={onInvestorAccessChange}
            />
          )}
          {entitiesSelected.map((option, index) => {
            const onRemove = () => {
              setEntitiesSelected((currGroupsSelected) =>
                currGroupsSelected.filter((group) => group.id !== option.id)
              )
            }

            if (option.type === ShareWithOptionType.List) {
              return (
                <List
                  key={option.id}
                  name={option.name}
                  recipients={option.recipients!}
                  onRemove={onRemove}
                  onRecipientRemove={onRecipientRemove(option.id)}
                  selectedIds={getOtherEntitiesSelectedIds(option, index)}
                />
              )
            }

            if (option.type === ShareWithOptionType.Email) {
              return (
                <Email
                  key={option.id}
                  name={option.name}
                  onRemove={onRemove}
                  failedCreating={option.failedCreating}
                  unreachable={option.unreachableEmail}
                />
              )
            }

            return (
              <ShareWithEntity
                key={option.id}
                name={option.name}
                imageUrl={option.imageUrl}
                onRemove={onRemove}
                unreachable={option.unreachableEmail}
              />
            )
          })}
        </Styles.GroupsContainer>
        {showReshareSettings && (
          <>
            <Styles.TitleContainer>
              <Title
                title={intl.formatMessage({ id: 'updates.reshareSettings' })}
              />
            </Styles.TitleContainer>

            <Styles.CheckboxContainer>
              <Checkbox
                id="isConfidential"
                name="isConfidential"
                label={intl.formatMessage({ id: 'updates.confidentialUpdate' })}
                onChange={toggleIsConfidential}
                checked={isConfidential}
                data-testid="confidential-checkbox"
              />
              <FontAwesomeIcon
                icon={['far', 'user-shield']}
                color={theme.colors.darkGray}
              />
            </Styles.CheckboxContainer>

            <Styles.ReshareSettingsDescription>
              <FormattedMessage id="updates.confidentialUpdateDesc" />
            </Styles.ReshareSettingsDescription>
          </>
        )}
      </Styles.ModalBody>
      <Modal.RowFooter>
        <Button onClick={cancel} secondary data-testid="cancel-share-settings">
          <FormattedMessage id="common.cancel" />
        </Button>

        <Button
          primary
          blue
          onClick={saveAndClose}
          data-testid="save-share-settings"
        >
          <FormattedMessage id="general.ok" />
        </Button>
      </Modal.RowFooter>
    </Modal>
  )
}

export default ShareSettingsModal
