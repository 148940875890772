import { DEFAULT_CELL_HEIGHT } from 'components/Spreadsheet/utils'
import { CELL_WIDTH, CELL_PADDING, ARROW_WIDTH } from '../useUpdateMetricsGrid'

/**
 * Average lowercase character [a-z] width of Lato font at 14px size.
 */
const AVG_LOWERCASE_CHAR_WIDTH = 8

/**
 * Average uppercase character [A-Z] width of Lato font at 14px size.
 */
const AVG_UPPERCASE_CHAR_WIDTH = 13

/**
 * Average number character [0-9] width of Lato font at 14px size.
 */
const AVG_NUMBER_CHAR_WIDTH = 8

/**
 * Average symbol character width of Lato font at 14px size.
 */
const AVG_SYMBOL_CHAR_WIDTH = 5

/**
 * Height of an HTML container with text with Lato font at 14px size.
 */
const FONT_HEIGHT = 19

/**
 * Width of the green (new) badge that's displayed when a metric will be created.
 */
const NEW_BADGE_WIDTH = 38

/**
 * Calculates approximate length in pixels of a text with Lato font at 14px size.
 *
 * @param {string} line String of text to calcualte its length.
 * @returns {number} Approximate length in pixels.
 */
const getLineLength = (line: string): number => {
  return (
    (line.match(/[A-Z]/g) || []).length * AVG_UPPERCASE_CHAR_WIDTH +
    (line.match(/[a-z]/g) || []).length * AVG_LOWERCASE_CHAR_WIDTH +
    (line.match(/[0-9]/g) || []).length * AVG_NUMBER_CHAR_WIDTH +
    (line.match(/[^A-Za-z0-9]/g) || []).length * AVG_SYMBOL_CHAR_WIDTH
  )
}

/**
 * Calculates approximate amount of lines the metric's name will occupy in its DOM container.
 *
 * @param {string?} metricName Name of the metric of this row.
 * @returns {number} Height of the cell/row in pixels.
 */
export const getCellHeightFromAmountOfLines = (
  metricName?: string,
  toCreate?: boolean
): number => {
  let cellHeight = DEFAULT_CELL_HEIGHT

  if (metricName) {
    const METRIC_NAME_CONTAINER_WIDTH =
      CELL_WIDTH - CELL_PADDING - ARROW_WIDTH - (toCreate ? NEW_BADGE_WIDTH : 0)

    const lines = metricName.split(' ').reduce(
      (acc, word) => {
        let currentLine = acc[acc.length - 1]
        currentLine = currentLine ? `${currentLine} ${word}` : word
        const lineLength = getLineLength(currentLine)

        if (lineLength > METRIC_NAME_CONTAINER_WIDTH) {
          if (acc[acc.length - 1]) {
            acc.push(word)
          } else {
            acc[acc.length - 1] = word
          }
        } else {
          acc[acc.length - 1] = currentLine
        }

        return acc
      },
      ['']
    )

    if (lines.length > 1) {
      cellHeight = FONT_HEIGHT * lines.length + CELL_PADDING
    }
  }

  return cellHeight
}
