import { useId, useLayoutEffect, useRef } from 'react'

import { useIntl } from 'react-intl'
import {
  useChatContext,
  useMessageContext,
  EmojiPicker,
  useMessageInputContext,
  ChatAutoComplete,
  MessageInput,
} from 'stream-chat-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'ui/Button'
import {
  EditInputWrapper,
  EditMessageActionsContainer,
  EditMessageContainer,
  EmojiPickerContainer,
} from './EditMessageInput.styles'
import { EmojiButton } from '../MessageInput/MessageInput.styles'

const EditMessageForm = () => {
  const id = useId()
  const intl = useIntl()
  const {
    openEmojiPicker,
    closeEmojiPicker,
    text,
    mentioned_users,
    emojiPickerIsOpen,
    clearEditingState,
  } = useMessageInputContext()

  const { client } = useChatContext()
  const { message, threadList } = useMessageContext()
  const originalText = useRef(text)

  useLayoutEffect(() => {
    const emojiPickerContainer = document.getElementById(`emoji-picker_${id}`)

    if (emojiPickerIsOpen && emojiPickerContainer) {
      const clientRects = emojiPickerContainer.getBoundingClientRect()

      if (clientRects.top < 0) {
        emojiPickerContainer.style.top = `107px`
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emojiPickerIsOpen])

  const onEditMessage = async () => {
    if (originalText.current !== text) {
      client.partialUpdateMessage(message.id, {
        set: {
          text,
          mentioned_users: mentioned_users.map((user) => user.id),
          edited: true,
        },
      })
    }

    clearEditingState?.()
  }

  return (
    <EditMessageContainer>
      <EditInputWrapper>
        <ChatAutoComplete handleSubmit={onEditMessage} />
      </EditInputWrapper>
      <EditMessageActionsContainer>
        <EmojiButton
          onClick={emojiPickerIsOpen ? closeEmojiPicker : openEmojiPicker}
        >
          <FontAwesomeIcon id="emojiButton" icon={['far', 'smile']} />
        </EmojiButton>
        <Button secondary onClick={clearEditingState}>
          {intl.formatMessage({ id: 'common.cancel' })}
        </Button>
        <Button primary onClick={onEditMessage} disabled={!text}>
          {intl.formatMessage({ id: 'common.save' })}
        </Button>
      </EditMessageActionsContainer>
      <EmojiPickerContainer id={`emoji-picker_${id}`} threadList={threadList}>
        <EmojiPicker />
      </EmojiPickerContainer>
    </EditMessageContainer>
  )
}

const EditMessageInput = () => {
  const { message, clearEditingState } = useMessageContext()

  return (
    <MessageInput
      clearEditingState={clearEditingState}
      Input={EditMessageForm}
      message={message}
    />
  )
}

export default EditMessageInput
