import type { AxiosProgressEvent } from 'axios'
import { throttle } from 'lodash'
import { FileContentNormalizer } from 'utils/functions/normalizers/fileContentNormalizer'
import {
  FileContentApi,
  FileContentDetailsApi,
  IndexEmailContentApi,
  IndexFileContentApi,
  LoggingUpdateApi,
} from 'utils/types/api/files'
import { GroupApi } from 'utils/types/api/groups'
import { UserApi } from 'utils/types/api/user'
import { PlainUpdateType } from 'utils/types/common'
import { Holding, Sector } from 'utils/types/company'
import { Tag } from 'utils/types/update'
import { ALL_FORMAT_TYPES } from 'utils/hooks/contents/filters/constants'
import {
  FileContent,
  FileContentDetails,
  MixedContent,
} from 'utils/types/files'
import axiosClient from './httpClient'
import {
  ApiEntity,
  ApiPopulateUpdateTypeResponse,
  ApiShowResponse,
} from './types'

export type ContentFilters = {
  name?: string
  onlyUnreconciled: boolean
  onlyFileContents?: boolean
  showPublicFiles: boolean
  showEmailContent: boolean
  uploadModal?: boolean
  orderBy: string
  direction: string
  page?: number
  pageSize?: Number
  entityId?: string
  userIds: string[]
  groupIds: string[]
  loggingUpdateType: PlainUpdateType[]
  fileFormat: string[]
}

export const SharedOption = {
  ADDED_BY: 'added_by',
  SHARED_BY: 'shared_by',
} as const

export enum ContentStorageType {
  EMAIL = 'Contents::EmailContent',
  S3 = 'Contents::S3Content',
}

class ContentService {
  static async getFileContentById(id: string): Promise<FileContentDetails> {
    const { data } = await axiosClient().get<
      ApiShowResponse<{
        s3Contents: ApiEntity<FileContentDetailsApi>
        users: ApiEntity<UserApi>
        groups: ApiEntity<GroupApi>
        loggingUpdates: ApiEntity<LoggingUpdateApi>
      }>
    >(`s3_contents/${id}`)
    const content = FileContentNormalizer.normalizeFileContentDetails(
      data.result,
      data.entities
    )
    return content
  }

  static async fetchContents(
    filters: Partial<ContentFilters & { page: number }>
  ): Promise<MixedContent[]> {
    const params: Record<string, any> = {
      page: filters?.page,
      order_by: filters?.orderBy,
      direction: filters.direction,
      'q[name_cont]': filters?.name,
      per_page: filters.pageSize,
      update_types_in: filters?.loggingUpdateType,
      'q[file_format_in]': filters?.fileFormat,
    }

    if (filters?.onlyUnreconciled) {
      params.only_unreconciled = filters?.onlyUnreconciled
    }

    if (filters?.showPublicFiles) {
      params.include_public = filters?.showPublicFiles
    }

    if (filters?.uploadModal) {
      params.upload_modal = filters?.uploadModal
    }

    if (filters?.onlyFileContents) {
      params['q[type_eq]'] = ContentStorageType.S3

      params['q[file_format_not_in]'] = ALL_FORMAT_TYPES.filter(
        (format) => !filters?.fileFormat?.includes(format)
      )

      params['q[file_format_in]'] = null
    }

    if (filters?.userIds && filters?.userIds.length > 0) {
      // this is a hack to make the API work with one id and make it fail with multiple user ids.
      // Otherwise it always set the param to the last id in the array and it brings the wrong results.
      // this will replace to send an array of ids when the API supports multiple users.
      if (filters?.userIds.length === 1) {
        const userId = filters.userIds[0]
        params['q[user_id_eq]'] = userId
      } else {
        // Once the API supports multiple users, this will be the correct way to send the params.
        params['q[user_id_eq]'] = filters.userIds
      }
    }

    if (filters?.groupIds && filters?.groupIds.length > 0) {
      // same hack as above
      if (filters?.groupIds.length === 1) {
        const groupId = filters.groupIds[0]
        params['q[group_id_eq]'] = groupId
      } else {
        params['q[group_id_in]'] = filters.groupIds
      }
    }

    if (filters?.entityId) {
      params.entity_id = filters?.entityId
    }

    const { data } = await axiosClient().get<{
      contents: {
        s3Content: IndexFileContentApi
        user?: UserApi
        group?: GroupApi
        emailContent: IndexEmailContentApi
        holdings?: { holding: Holding }[]
        sectors?: Sector[]
        tags?: Tag[]
      }[]
    }>('/contents', { params })

    return data.contents.map(FileContentNormalizer.normalizeMixedFileContent)
  }

  static async createContent(
    file: File,
    abortController: AbortController,
    onUploadProgress: (progress: AxiosProgressEvent) => void
  ): Promise<FileContent> {
    const formData = new FormData()
    formData.append('s3_content[file]', file)

    const { data } = await axiosClient(true).post<
      ApiShowResponse<{
        s3Contents: ApiEntity<FileContentApi>
      }>
    >('/s3_contents', formData, {
      signal: abortController.signal,
      onUploadProgress: throttle(onUploadProgress, 1000),
    })

    return FileContentNormalizer.normalizeFileContent(
      data.result,
      data.entities
    )
  }

  static async deleteContent(fileId: string): Promise<string> {
    await axiosClient().delete(`/s3_contents/${fileId}`)
    return fileId
  }

  static async editFileContent(id: string, fileContent: Partial<FileContent>) {
    const { data } = await axiosClient().patch(`/s3_contents/${id}`, {
      s3Content: {
        ...fileContent,
      },
    })

    return FileContentNormalizer.normalizeFileContent(
      data.result,
      data.entities
    )
  }

  static async updateTypesFilterOptions() {
    const { data } = await axiosClient().get<ApiPopulateUpdateTypeResponse>(
      '/s3_contents/content_update_types'
    )

    return FileContentNormalizer.normalizeUpdateTypesOptions(
      data.updatableTypes
    )
  }
}

export default ContentService
