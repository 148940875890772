import styled from 'styled-components/macro'
import Modal from 'components/Modal'

export const StyledModal = styled(Modal)`
  max-width: min(56.3rem, calc(100% - 2rem));
  width: 100%;
`

export const Body = styled(Modal.Body)`
  padding: 2.4rem;

  div[direction='column'] {
    padding-top: initial;
  }
`
