/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import IntegrationService from 'api/IntegrationService'
import useQueryString from 'utils/hooks/useQueryString'
import { useParams } from 'react-router-dom'

const XeroIntegrationRedirect = () => {
  const error = useQueryString('error')
  const code = useQueryString('code')
  const state = useQueryString('state')
  const { integrationType } = useParams()

  const connectAccount = async () => {
    await IntegrationService.connectAccount({
      integrationType,
      connectionId: state,
      authorizationCode: code,
      redirectUri: window.location.href,
      state,
    })
    window.close()
  }

  const setConnectionError = async () => {
    await IntegrationService.setConnectionError({
      integrationType,
      connectionId: state,
      error,
      state,
    })
    window.close()
  }

  useEffect(() => {
    if (code && state && !error) {
      connectAccount()
    }
  }, [code, state])

  useEffect(() => {
    if (error) {
      setConnectionError()
    }
  }, [error])

  return null
}

XeroIntegrationRedirect.propTypes = {}
XeroIntegrationRedirect.defaultProps = {}

export default XeroIntegrationRedirect
