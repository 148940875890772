import { ReactNode } from 'react'
import { color } from 'utils/functions/colors'
import { Nullable } from 'utils/types/common'
import { Color } from 'utils/theme'
import LogIcon from 'containers/Logs/LogIcon'
import { PortfolioLogTextBuilder } from '../text-builders/PortfolioLogTextBuilder'
import { EventType, Log, LogTextBuilder, LogType } from '../types'
import { LogsBuilder } from './LogsBuilder'

export type PortfolioLogChangeSetFields = {
  name: string
  [EventType.ADD_COMPANY]: string
  [EventType.REMOVE_COMPANY]: string
  [EventType.ADD_TRANSACTION]: string
  [EventType.REMOVE_TRANSACTION]: string
  [EventType.ADD_INVESTOR]: string
  [EventType.REMOVE_INVESTOR]: string
}

const PortfolioNames = {
  [LogType.PIPELINE_PORTFOLIOS]: {
    Long: 'pipelinePortfolio',
    Short: 'pipeline',
  },
  [LogType.INVEST_PORTFOLIOS]: {
    Long: 'investPortfolio',
    Short: 'investPortfolio',
  },
  [LogType.FUND_PORTFOLIOS]: {
    Long: 'fundPortfolio',
    Short: 'fundPortfolio',
  },
  [LogType.DEAL_PORTFOLIOS]: {
    Long: 'dealPortfolio',
    Short: 'dealPortfolio',
  },
}

export class PortfolioLogsBuilder extends LogsBuilder<PortfolioLogChangeSetFields> {
  private portfolioLogTextBuilder: LogTextBuilder

  constructor(log: Log<PortfolioLogChangeSetFields>, logType: LogType) {
    super(log)

    this.portfolioLogTextBuilder = new PortfolioLogTextBuilder(
      PortfolioLogsBuilder.intl.formatMessage({
        id: `logs.portfolios.names.${PortfolioNames[logType].Long}`,
      }),
      PortfolioLogsBuilder.intl.formatMessage({
        id: `logs.portfolios.names.${PortfolioNames[logType].Short}`,
      })
    )
  }

  protected getTextBuilderForField(): Nullable<LogTextBuilder> {
    return this.portfolioLogTextBuilder
  }

  public static getCompanyIcon(colorName: Color): React.ReactNode {
    return (
      <LogIcon
        small
        color={color(colorName)}
        icon={['far', 'treasure-chest']}
      />
    )
  }

  public static getTransactionIcon(colorName: Color): React.ReactNode {
    return (
      <LogIcon
        small
        color={color(colorName)}
        icon={['far', 'money-check-alt']}
      />
    )
  }

  public static getFMVIcon(colorName: Color): React.ReactNode {
    return <LogIcon color={color(colorName)} icon={['far', 'dollar-sign']} />
  }

  public static getInvestorIcon(colorName: Color): React.ReactNode {
    return <LogIcon color={color(colorName)} icon={['far', 'user-tie']} />
  }

  public getOperationIcon(): ReactNode {
    switch (this.log.event) {
      case EventType.ADD_COMPANY:
        return PortfolioLogsBuilder.getCompanyIcon('green')
      case EventType.REMOVE_COMPANY:
        return PortfolioLogsBuilder.getCompanyIcon('red')
      case EventType.ADD_TRANSACTION:
        return PortfolioLogsBuilder.getTransactionIcon('green')
      case EventType.REMOVE_TRANSACTION:
        return PortfolioLogsBuilder.getTransactionIcon('red')
      case EventType.ADD_FMV:
        return PortfolioLogsBuilder.getFMVIcon('green')
      case EventType.UPDATE_FMV:
        return PortfolioLogsBuilder.getFMVIcon('brightBlue')
      case EventType.REMOVE_FMV:
        return PortfolioLogsBuilder.getFMVIcon('red')
      case EventType.ADD_INVESTOR:
        return PortfolioLogsBuilder.getInvestorIcon('green')
      case EventType.REMOVE_INVESTOR:
        return PortfolioLogsBuilder.getInvestorIcon('red')
      default:
        return super.getOperationIcon()
    }
  }
}
