import { useHistory } from 'react-router-dom'

import { UpdateEntityType } from 'components/UpdatesFeedV2'
import { useUpdatesFiltersContext } from 'containers/UpdatesView/UpdatesFiltersContext'
import { useMixedUpdatesQuery } from 'utils/hooks/queries/useMixedUpdatesQuery'
import { getUpdateReshareUrl } from 'utils/functions/updates'
import { useUpdatesProfilesFilter } from 'utils/hooks/useUpdatesProfilesFilter'
import { IndexUpdate } from 'utils/types/update'
import { EntityName } from 'utils/hooks/useEntityFromUrl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'

const useCompanyUpdates = (companyId: string) => {
  const history = useHistory()
  const { filters, setFilters } = useUpdatesFiltersContext()
  const isLP = useAppSelector(isActingAsInvestorGroup)
  const {
    filterDataSet,
    onChangeTypeFilters,
    onChangeTagFilters,
    onToggleDraftOnly,
  } = useUpdatesProfilesFilter({
    isSearchByTagAllowed: !isLP,
    filters,
    setFilters,
  })

  const reshareUpdate = (update: IndexUpdate) => {
    history.push(
      getUpdateReshareUrl(update, false, {
        id: companyId,
        name: EntityName.COMPANIES,
      })
    )
  }

  // TODO: This is requesting updates and only using it for hasMixedUpdates
  const { data } = useMixedUpdatesQuery({
    entityType: UpdateEntityType.COMPANY,
    entityId: companyId,
    filters,
  })

  const hasMixedUpdates = !!data?.pages?.some((page) => page.data.length > 0)

  return {
    hasMixedUpdates,
    filters,
    filterDataSet,
    onChangeTypeFilters,
    onChangeTagFilters,
    onToggleDraftOnly,
    reshareUpdate,
  }
}

export default useCompanyUpdates
