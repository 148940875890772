import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'

import useCreatePortfolio, {
  CreatePortfolioFormValues,
} from 'components/CreatePortfolio/useCreatePortfolio'
import Modal from 'components/Modal'
import Button from 'ui/Button'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { Portfolio } from 'utils/types/portfolios'
import CreatePortfolioBody from 'components/CreatePortfolioBody'

import { Body, Footer } from './CreateFundPortfolioModal.styles'

const initialValues: CreatePortfolioFormValues = {
  name: '',
  holdings: [],
  type: PortfolioTypes.FUND,
}

interface CreateFundModalPortfolioProps {
  isOpen: boolean
  onHide: () => void
  onFundPortfolioCreated: (fundPortfolio: Portfolio) => void
}

const CreateFundPortfolioModal = ({
  isOpen,
  onHide,
  onFundPortfolioCreated,
}: CreateFundModalPortfolioProps) => {
  const intl = useIntl()
  const {
    formikRef,
    onCancel,
    createPortfolio,
    loadHoldings,
    checkIfPortfolioNameExists,
    validationSchema,
    loading: isLoading,
  } = useCreatePortfolio(onHide, onFundPortfolioCreated)

  return (
    <Formik<CreatePortfolioFormValues>
      initialValues={initialValues}
      onSubmit={createPortfolio}
      innerRef={formikRef}
      validationSchema={validationSchema}
      validateOnMount
    >
      {(formik) => (
        <Modal show={isOpen} onHide={onCancel}>
          <Modal.Header onHide={onCancel}>
            <Modal.Title>
              <FormattedMessage id="createFundPortfolioModal.title" />
            </Modal.Title>
          </Modal.Header>

          <Body>
            <CreatePortfolioBody
              isFundManager
              loadHoldings={loadHoldings}
              checkIfPortfolioNameExists={checkIfPortfolioNameExists}
            />
          </Body>

          <Footer>
            <Button onClick={onCancel}>
              {intl.formatMessage({ id: 'common.cancel' })}
            </Button>

            <Button
              primary
              onClick={formik.handleSubmit}
              isLoading={isLoading}
              disabled={isLoading || !formik.isValid || !formik.values.type}
            >
              {isLoading
                ? intl.formatMessage({ id: 'common.creating' })
                : intl.formatMessage({ id: 'common.create' })}
            </Button>
          </Footer>
        </Modal>
      )}
    </Formik>
  )
}

export default CreateFundPortfolioModal
