import { useField, useForm } from 'components/Form/hooks'
import { useIntl } from 'react-intl'
import { UpdateFormContext } from 'utils/types/updateForm'
import theme from 'utils/theme'
import { MAX_TITLE_COUNTER_CHARACTERS } from 'utils/constants/updates'
import { DatePicker, Input, TextArea } from '../commons'

import MediaUploader from '../MediaUploader'
import { Content, Row } from './AnnouncementForm.styles'

const AnnouncementForm: React.FC = () => {
  const intl = useIntl()
  const { disabled } = useForm<UpdateFormContext>()
  const [titleField] = useField('title')

  return (
    <Content>
      <Row>
        <Input
          id="title"
          label={intl.formatMessage({
            id: 'updates.announcement.title.label',
          })}
          name="title"
          placeholder={intl.formatMessage({
            id: 'updates.announcement.title.placeholder',
          })}
          title={intl.formatMessage({
            id: 'updates.announcement.title.label',
          })}
          withCounter
          customMaxCounterCharacters={MAX_TITLE_COUNTER_CHARACTERS}
          counterSubtractNumber={titleField.value.length}
          simulateIconSpace
          counterStyle={{
            right: '2rem',
            top: '1.2rem',
            color: theme.colors.darkGray,
            opacity: 0.5,
          }}
          extraPaddingRight={1.5}
        />
        <DatePicker
          id="date"
          name="date"
          label={intl.formatMessage({ id: 'updates.common.publishDate' })}
        />
      </Row>

      <TextArea
        id="body"
        label={intl.formatMessage({
          id: 'updates.announcement.body.label',
        })}
        isOptional
        name="body"
        title={intl.formatMessage({
          id: 'updates.announcement.body.label',
        })}
        placeholder={intl.formatMessage({
          id: 'updates.announcement.body.placeholder',
        })}
        rows={5}
      />

      <MediaUploader allowMultipleContents name="files" disabled={disabled} />
    </Content>
  )
}

export default AnnouncementForm
