import { useEffect } from 'react'
import { Channel } from 'stream-chat'
import type { Event } from 'stream-chat'
import { useChatContext } from 'stream-chat-react'
import type { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'

export const useMessageRemovedListener = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  setChannels: React.Dispatch<
    React.SetStateAction<Array<Channel<StreamChatGenerics>>>
  >
) => {
  const { client } = useChatContext<StreamChatGenerics>(
    'useMessageRemovedListener'
  )

  useEffect(() => {
    const handleEvent = (event: Event<StreamChatGenerics>) => {
      setChannels((channels) => {
        const channel = client.channel(event.channel_type!, event.channel_id!)
        return channels.map((ch) => {
          if (ch.id === event.channel_id) {
            return channel
          }
          return ch
        })
      })
    }

    client.on('message.deleted', handleEvent)

    return () => {
      client.off('message.deleted', handleEvent)
    }
  }, [client, setChannels])
}
