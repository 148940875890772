import FileIcon from 'components/FileIcon'
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg'
import { isFileFormatImage } from 'utils/types/files'
import { isIndexFileContent } from 'utils/functions/files'
import { useMixedContent } from 'utils/hooks/contents/useMixedContent'

import {
  CloseIconWrapper,
  GridFile,
  PreviewImage,
  ImageContainer,
  PreviewItem,
  ItemName,
} from './FileGridListPreview.styles'
import { FilePreviewProps } from '../FileGridPreview/FileGridPreview'

const FileGridListPreview: React.FC<FilePreviewProps> = ({
  content,
  canDelete,
  handleDelete,
  handleOnClick,
}) => {
  const isFileContent = isIndexFileContent(content)
  const { isEmailContent, contentName, fileFormat } = useMixedContent(content)

  return (
    <GridFile onClick={handleOnClick} role="button">
      {isFileContent && isFileFormatImage(content.fileFormat) ? (
        <PreviewImage>
          <ImageContainer style={{ backgroundImage: `url(${content.url})` }} />
          {canDelete && <ItemName>{content.fileName}</ItemName>}
        </PreviewImage>
      ) : (
        <PreviewItem>
          <FileIcon xSmall type={fileFormat} isEmailContent={isEmailContent} />
          <ItemName>{contentName}</ItemName>
        </PreviewItem>
      )}

      {canDelete && (
        <CloseIconWrapper>
          <CloseIcon
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleDelete()
            }}
          />
        </CloseIconWrapper>
      )}
    </GridFile>
  )
}

export default FileGridListPreview
