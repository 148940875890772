import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

interface Props {
  className?: string
}

export const ListAvatarIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="20"
      height="20"
      rx="10"
      fill={color('primaryBlue')({ theme })}
    />
    <g clipPath="url(#clip0_2020_91344)">
      <path
        d="M15.0547 8.4375C15.1965 8.4375 15.3125 8.32148 15.3125 8.17969V7.32031C15.3125 7.17852 15.1965 7.0625 15.0547 7.0625H14.625V6.03125C14.625 5.46191 14.1631 5 13.5938 5H6.71875C6.14941 5 5.6875 5.46191 5.6875 6.03125V14.9688C5.6875 15.5381 6.14941 16 6.71875 16H13.5938C14.1631 16 14.625 15.5381 14.625 14.9688V13.9375H15.0547C15.1965 13.9375 15.3125 13.8215 15.3125 13.6797V12.8203C15.3125 12.6785 15.1965 12.5625 15.0547 12.5625H14.625V11.1875H15.0547C15.1965 11.1875 15.3125 11.0715 15.3125 10.9297V10.0703C15.3125 9.92852 15.1965 9.8125 15.0547 9.8125H14.625V8.4375H15.0547ZM13.5938 14.9688H6.71875V6.03125H13.5938V14.9688ZM10.1562 10.5C10.9146 10.5 11.5312 9.8834 11.5312 9.125C11.5312 8.3666 10.9146 7.75 10.1562 7.75C9.39785 7.75 8.78125 8.3666 8.78125 9.125C8.78125 9.8834 9.39785 10.5 10.1562 10.5ZM8.23125 13.25H12.0813C12.3477 13.25 12.5625 13.0652 12.5625 12.8375V12.425C12.5625 11.7418 11.9158 11.1875 11.1188 11.1875C10.8867 11.1875 10.717 11.3594 10.1562 11.3594C9.57832 11.3594 9.43867 11.1875 9.19375 11.1875C8.39668 11.1875 7.75 11.7418 7.75 12.425V12.8375C7.75 13.0652 7.96484 13.25 8.23125 13.25Z"
        fill="white"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="20"
      height="20"
      rx="10"
      stroke={color('veryLightGray')({ theme })}
    />
    <defs>
      <clipPath id="clip0_2020_91344">
        <rect width="11" height="11" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
)
