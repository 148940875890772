/* eslint-disable no-nested-ternary */
import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'

import Drawer from 'components/Drawer'
import FadeIn from 'components/FadeIn'
import CWLoader from 'components/CWLoader'
import Button from 'ui/Button'
import CircleTimer from 'components/CircleTimer/CircleTimer'
import { InboundStatus } from 'utils/types/inbounds'
import theme from 'utils/theme'
import { TEST_CONNECTION_TIMEOUT_SECONDS } from 'containers/Inbounds/hooks/useTestConnection'

import {
  ButtonsContainer,
  CheckButton,
  ConnectedGoogleGroup,
  Content,
  DrawerContent,
  DrawerFooter,
  StatusContainer,
  StatusLabel,
} from './InboundSettings.styles'
import GroupManagedToggle from '../GroupManagedToggle'
import { useInboundSettings } from './useInboundSettings'
import {
  Description,
  ErrorMessage,
  StatusIndicator,
  StatusWithTimerContainer,
} from '../CreateInbound/CreateInbound.styles'
import GeneratedInboundEmail from '../GeneratedInboundEmail/GeneratedInboundEmail'

interface InboundSettingsProps {
  inboundId: string
  isOpen: boolean
  onClose: () => void
}

const InboundSettings = ({
  inboundId,
  isOpen,
  onClose,
}: InboundSettingsProps) => {
  const intl = useIntl()
  const {
    inbound,
    isLoading,
    isDeleting,
    isSaving,
    handleDeleteInbound,
    handleEditInbound,
    displayDate,
    isTestingConnection,
    hasTestedConnection,
    inboundOwner,
    onCheckStatusClick,
  } = useInboundSettings({
    inboundId,
    onClose,
  })
  const areActionsDisabled =
    isLoading || isDeleting || isSaving || isTestingConnection

  return (
    <Formik
      initialValues={{
        allowMyGroupToReceiveSuggestedUpdates: !!inbound?.groupManaged,
      }}
      enableReinitialize
      onSubmit={handleEditInbound}
    >
      {(formik) => (
        <Drawer
          isOpen={isOpen}
          onCloseDrawer={onClose}
          headerTitle={intl.formatMessage({
            id: 'inbounds.settings.title',
          })}
        >
          <DrawerContent>
            <Content>
              {isLoading ? (
                <CWLoader />
              ) : inbound?.firstConnection ? (
                <>
                  <ConnectedGoogleGroup>
                    <FormattedMessage id="inbounds.settings.googleGroup" />
                    <div>{inbound?.email}</div>
                  </ConnectedGoogleGroup>
                  <GeneratedInboundEmail email={inbound.inboundEmail} />
                  <GroupManagedToggle inboundOwner={inboundOwner} />
                </>
              ) : (
                <>
                  <Description>
                    <FormattedMessage id="inbounds.connectStepCopyDescription" />
                  </Description>
                  <GeneratedInboundEmail email={inbound?.inboundEmail!} />
                  <GroupManagedToggle inboundOwner={inboundOwner} />
                  <Description>
                    <FormattedMessage id="inbounds.connectStepAfterAdding" />
                  </Description>
                </>
              )}
            </Content>
            {inbound && (
              <StatusContainer>
                <StatusLabel>
                  <FormattedMessage id="inbounds.settings.status" />
                  <StatusWithTimerContainer>
                    <StatusIndicator
                      status={
                        isTestingConnection
                          ? InboundStatus.TESTING
                          : inbound.connectionStatus
                      }
                    />
                    {isTestingConnection && (
                      <CircleTimer
                        time={TEST_CONNECTION_TIMEOUT_SECONDS}
                        color={theme.colors.mainBlue}
                      />
                    )}
                  </StatusWithTimerContainer>
                  {inbound.connectionStatus === InboundStatus.NOT_CONNECTED &&
                    hasTestedConnection &&
                    !isTestingConnection && (
                      <ErrorMessage>
                        <FormattedMessage id="inbounds.checkStatusFailed" />
                      </ErrorMessage>
                    )}
                </StatusLabel>

                {inbound.lastConnectionCheck && (
                  <StatusLabel>
                    <FormattedMessage id="inbounds.settings.lastCheckUpPerformed" />
                    <span>{displayDate(inbound.lastConnectionCheck)}</span>
                  </StatusLabel>
                )}

                {inbound.lastConnection && (
                  <StatusLabel>
                    <FormattedMessage id="inbounds.settings.lastSuccessfulConnection" />
                    <span>{displayDate(inbound.lastConnection)}</span>
                  </StatusLabel>
                )}

                {inbound.firstConnection && (
                  <StatusLabel>
                    <FormattedMessage id="inbounds.settings.firstConnection" />
                    <span>{displayDate(inbound.firstConnection)}</span>
                  </StatusLabel>
                )}

                {inboundOwner && (
                  <StatusLabel>
                    <FormattedMessage id="inbounds.settings.createdBy" />
                    <span>{inboundOwner.name}</span>
                  </StatusLabel>
                )}

                {!isTestingConnection && (
                  <CheckButton
                    icon={['far', 'wifi']}
                    onClick={onCheckStatusClick}
                  >
                    <FormattedMessage id="inbounds.checkStatus" />
                  </CheckButton>
                )}
              </StatusContainer>
            )}
          </DrawerContent>

          <FadeIn>
            <DrawerFooter>
              <Button
                onClick={handleDeleteInbound}
                danger
                disabled={areActionsDisabled}
                isLoading={isDeleting}
              >
                {intl.formatMessage({ id: 'general.delete' })}
              </Button>

              <ButtonsContainer>
                <Button onClick={onClose}>
                  {intl.formatMessage({ id: 'general.cancel' })}
                </Button>
                <Button
                  primary
                  disabled={areActionsDisabled}
                  onClick={formik.submitForm}
                  isLoading={isSaving || isTestingConnection}
                  data-testid="inbound-settings-save-button"
                >
                  {intl.formatMessage({ id: 'general.done' })}
                </Button>
              </ButtonsContainer>
            </DrawerFooter>
          </FadeIn>
        </Drawer>
      )}
    </Formik>
  )
}

export default InboundSettings
