import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

import Button from 'components/Button'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TextArea from 'components/TextArea'

export const FairMarketValueHistoryContainer = styled.div`
  h1[class*='noResultsFoundTitle'] {
    margin: 2rem;
  }
`

export const AddButton = styled(Button)`
  display: block;
  margin-left: auto;
  color: #2a2a2f;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0;
  text-transform: capitalize;
  padding-right: 1.6rem;

  &:hover {
    box-shadow: none;
  }

  svg {
    margin-right: 0.9rem;
  }
`

export const ZeroStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;

  > button {
    margin-top: 1.6rem;
  }
`

export const FMVCell = styled.div`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  font-weight: 400;
`

export const FMVDescriptionIcon = styled.div`
  position: absolute;
  right: 8rem;
  bottom: 2rem;
  font-size: 1.7rem;
  color: ${color('darkBlue')};
`

export const FMVValue = styled.div<{ margin?: string }>`
  display: flex;
  align-items: center;
  gap: 0 0.8rem;
  flex-wrap: wrap;
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  span[class*='GrowthIndicator_growthValue'] {
    font-size: 1.2rem;
  }
  span[class*='GrowthIndicator_up'] {
    margin-bottom: 0.3rem;
  }

  span[class*='GrowthIndicator_down'] {
    margin-bottom: -0.1rem;
  }

  ${(props) =>
    props.margin
      ? css`
          margin: ${props.margin};
        `
      : ''}
`
export const GrowthIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
`

export const TableContainer = styled.div`
  border-radius: 1rem;
  border: solid 0.1rem #e9e9e9;
  width: 100%;

  div[data-type='delete'] {
    color: ${color('red')};
  }
`

export const AddFMVContainer = styled.div`
  background-color: rgba(201, 207, 228, 0.2);
  padding: 13px 17px 13px 18px;
`
export const AddFMVRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1.6rem;
  gap: 0.8rem;

  div[class^='TextArea_textAreaWrapper'] {
    width: 100%;
    margin-top: -1.3rem;
  }
  div[class^='Input_inputWrapper'] {
    height: 3.8rem;
    width: 100%;
    margin-top: 0;
    margin-bottom: 1.2rem;
  }

  div[class*='Input_inputContainer']:focus-within svg {
    color: unset;
  }
`

export const DateContainer = styled.div`
  flex: 2;

  label {
    padding: 1rem;
    background: rgba(120, 126, 140, 0.1);
    border: 1px solid transparent;
  }
  .react-datepicker__input-container input {
    font-size: 1.4rem;
  }
`

export const ValueContainer = styled.div`
  flex: 2;
`

export const FMVDatePicker = styled(SelectDatePicker)`
  border-radius: 0.4rem;
  border: solid 0.1rem #e9e9e9;
  background-color: white;
  font-size: 1.4rem;
  font-weight: 600;
  color: #787e8c;

  input {
    font-size: 1.4rem;
  }
`

export const FMVTextArea = styled(TextArea)`
  border-radius: 0.4rem;
  border: solid 0.1rem #e9e9e9;
  line-height: 1.68rem;
  background-color: rgba(120, 126, 140, 0.1);
  border-bottom: none;
  margin-left: 0.2rem;
  font-size: 1.4rem;
  font-weight: 400;
  height: 3.2rem;
  color: rgba(74, 74, 104, 1);
  margin-top: 0.2rem;
  height: 17.3rem;
  margin-bottom: 1.1rem;
  width: 100%;
  padding: 1rem 1.6rem 1rem 3.8rem;
  border-radius: 0.8rem;
  resize: none;

  &:focus {
    outline: none;
  }

  &:hover {
    border: 0.1rem solid #c9cfe4;
  }

  &::placeholder {
    color: rgba(74, 74, 104, 0.5);
  }

  input {
    background-color: #e8e9ef;
  }
  svg + input {
    text-indent: 1rem;
  }

  div[class*='Input_inputContainer'] {
    border-bottom: none;
    height: 100%;
    align-items: center;
    padding: 0 1.6rem;
    input {
      font-size: 1.4rem;
    }
  }
  div[class*='Input_prefixContainer'] {
    margin-left: 0.4rem;
    color: #787e8c;
  }
  span[class*='Input_fieldErrorMessage'] {
    display: none;
  }
`

export const ButtonsContainer = styled.div`
  justify-content: flex-end;
  display: flex;

  button {
    font-size: 1.4rem;
  }

  button:first-child {
    margin-right: 0.7rem;
    background-color: white;
  }
`

export const PrefixCommentIcon = styled(FontAwesomeIcon)`
  font-size: 1.7rem;
  color: ${color('darkBlue')};
  position: absolute;
  top: 1.3rem;
  left: 1.6rem;

  > input {
    text-indent: 1rem;
  }
`
