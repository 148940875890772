import React, { ReactElement, useEffect, useState } from 'react'
import { Nullable } from 'utils/types/common'
import styles from './TextArea.module.scss'

interface Props {
  id?: string
  name?: string
  type?: string
  initialValue: Nullable<string>
  placeholder: string
  error?: any
  disabled?: boolean
  prefixIcon?: Nullable<ReactElement>
  rows?: number
  handleChange?: (args: any) => void
  onChangeFormik?: () => void
}

const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      id,
      name = 'textarea',
      placeholder = '',
      initialValue = null,
      disabled = false,
      error,
      prefixIcon = null,
      handleChange,
      onChangeFormik,
      ...rest
    },
    ref
  ) => {
    const [value, setValue] = useState(initialValue || '')

    useEffect(() => {
      setValue(initialValue || '')
    }, [initialValue])

    const onChange = ({ target: { value: dataValue } }) => {
      setValue(dataValue)
      handleChange?.({ key: id, value: dataValue })
    }

    return (
      <div className={styles.textAreaWrapper}>
        {prefixIcon && prefixIcon}
        <textarea
          className={
            error?.message ? styles.textAreaError : styles.textAreaContainer
          }
          {...rest}
          name={name}
          placeholder={placeholder}
          onChange={onChangeFormik || onChange}
          ref={ref}
          value={value}
          disabled={disabled}
        />
        <span className={styles.fieldErrorMessage}>{error?.message}</span>
      </div>
    )
  }
)

export default TextArea
