import { Channel } from 'stream-chat'
import CWLoader from 'components/CWLoader'
import { StreamChatType } from 'containers/Chat/types'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import ChannelRow from './ChannelRow'
import {
  ChannelRowsContainer,
  ZeroStateContainer,
} from './SearchResults.styles'

type Props = {
  isLoading: boolean
  channels: Channel<StreamChatType>[]
  onSelectChannel: (channel: Channel<StreamChatType>) => void
  searchTexts: string[]
  plainSearch: string
}

const SearchChannelsResults = ({
  channels,
  isLoading,
  onSelectChannel,
  searchTexts,
  plainSearch,
}: Props) => {
  useHighlightText(
    {
      elementClass: '.highlight-channel-name',
      text: searchTexts,
    },
    [searchTexts, isLoading, channels]
  )

  if (isLoading) {
    return <CWLoader />
  }

  if (channels.length === 0) {
    return (
      <ZeroStateContainer>
        <ZeroState
          textFontSize="1.6rem"
          titleFontSize="1.8rem"
          infoMarginTop="-0.7rem"
          type={ZeroStateType.CHAT_SEARCH_RESULTS}
          intlValues={{ search: plainSearch }}
        />
      </ZeroStateContainer>
    )
  }

  return (
    <ChannelRowsContainer>
      {channels.map((channel, index) => (
        <ChannelRow
          key={channel.id}
          channel={channel}
          onSelectChannel={onSelectChannel}
          isLast={index === channels.length - 1}
        />
      ))}
    </ChannelRowsContainer>
  )
}

export default SearchChannelsResults
