import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Channel } from 'stream-chat'
import { useChannelStateContext } from 'stream-chat-react'
import dayjs from 'dayjs'

import { StreamChatType } from 'containers/Chat/types'
import { useIsChannelMuted } from 'utils/hooks/useIsChannelMuted'

export const useMutedIndicator = (channelProps?: Channel<StreamChatType>) => {
  const intl = useIntl()
  const { channel: activeChannel } = useChannelStateContext<StreamChatType>()
  const channel = channelProps || activeChannel
  const { muted, expiresAt } = useIsChannelMuted(channel)

  const muteExpirationText = useMemo(() => {
    if (muted) {
      if (expiresAt) {
        return intl.formatMessage(
          { id: 'chat.mutedUntil' },
          { date: dayjs(expiresAt).format('MMM D, YYYY - hh:mma') }
        )
      }

      return intl.formatMessage({ id: 'chat.mutedAlways' })
    }

    return ''
  }, [expiresAt, intl, muted])

  return {
    isChannelMuted: muted,
    muteExpirationText,
  }
}
