import { FileContent } from 'utils/types/files'

export const FIFTY_MB = 50 * 1024 * 1024
export const TEN_MB = 9.5 * 1024 * 1024

export const fileSizesMoreThanXMb = (
  arr: FileContent[],
  maxSize: number
): boolean => {
  const totalSize = arr.reduce((acc, element) => {
    return acc + element.fileSize
  }, 0)

  return totalSize > maxSize
}

export const filterFilesBySize = (
  files: FileContent[],
  maxSize: number
): FileContent[] => {
  let totalSize = 0
  const filteredFiles: FileContent[] = []
  for (let i = 0; i < files.length; i++) {
    totalSize += files[i].fileSize
    if (totalSize <= maxSize) {
      filteredFiles.push(files[i])
    } else {
      break
    }
  }
  return filteredFiles
}
