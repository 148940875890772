import { useQuery } from '@tanstack/react-query'
import CompanyService from 'api/CompanyService'
import { groupPortfolioCompaniesByType } from 'utils/functions/portfolios'

interface Props {
  holdingId: string
  enabled?: boolean
}

const usePortfoliosByHoldingQuery = ({ holdingId, enabled = true }: Props) => {
  const { data, refetch, isLoading } = useQuery(
    ['holding', holdingId],
    async () => {
      const fetchedPortfolios = await CompanyService.getCompanyPortfolios(
        holdingId
      )
      const groupedPortfolios = groupPortfolioCompaniesByType(fetchedPortfolios)
      return groupedPortfolios
    },
    {
      enabled,
    }
  )

  return {
    data,
    refetch,
    isLoading,
  }
}

export default usePortfoliosByHoldingQuery
