import { useMemo, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InfiniteScrollRef from 'components/InfiniteScrollRef'
import NoResultsFound from 'components/NoResultsFound'
import PortfolioLogsSkeleton from 'components/Skeletons/Portfolio/Logs/PortfolioLogsSkeleton'
import Timeline from 'components/Timeline'
import { FormattedMessage } from 'react-intl'
import useInfiniteLogsQuery from '../../utils/hooks/queries/useInfiniteLogsQuery'

import {
  LogsContainer,
  LogZeroStateContainer,
} from '../../components/Logs/Logs.styles'
import LogRow from './LogRow'
import { BackendEventsMapperFactory } from './models/backend-events-mapper/BackendEventsMapperFactory'
import { LogType } from './models/types'
import * as Styles from './Logs.styles'

interface LogsProps {
  entityId: string
  logType: LogType
  factoryParams?: any
}

const Logs = <TChangeSet,>({ entityId, logType, factoryParams }: LogsProps) => {
  const backendEventMapper = useRef(
    BackendEventsMapperFactory.createBackendEventsMapper(logType, factoryParams)
  )
  const { data, isFetching, isLoading, infiniteScrollRef } =
    useInfiniteLogsQuery<TChangeSet>(entityId, logType)
  const logs = useMemo(
    () =>
      backendEventMapper.current?.getIndividualLogs(
        data?.pages.flatMap((page) => page.logs) ?? []
      ),
    [data?.pages]
  )

  const areThereLogs = !!logs.length

  if (isLoading || isFetching) {
    return <PortfolioLogsSkeleton />
  }

  if (!areThereLogs && !isFetching) {
    return (
      <LogZeroStateContainer>
        <NoResultsFound>
          <NoResultsFound.Icon>
            <FontAwesomeIcon icon={['far', 'file-alt']} />
          </NoResultsFound.Icon>
          <NoResultsFound.Title>
            <FormattedMessage id="logs.noLogsFound" />
          </NoResultsFound.Title>
          <NoResultsFound.Legend>
            <FormattedMessage id="logs.noLogsFoundLegend" />
          </NoResultsFound.Legend>
        </NoResultsFound>
        <InfiniteScrollRef ref={infiniteScrollRef} />
      </LogZeroStateContainer>
    )
  }

  return (
    <LogsContainer>
      {areThereLogs && (
        <Timeline renderChildrenDuration={0.1} listMargin="0 0.7rem 0 0.8rem">
          {logs.map((logsByDate) => {
            return (
              <>
                <Styles.Date>{logsByDate.date}</Styles.Date>
                {logsByDate.logs.map((log) => {
                  return (
                    <LogRow
                      key={log.id}
                      log={log}
                      logType={logType}
                      factoryParams={factoryParams}
                    />
                  )
                })}
              </>
            )
          })}
        </Timeline>
      )}
      <InfiniteScrollRef ref={infiniteScrollRef} />
    </LogsContainer>
  )
}

export default Logs
