import { useCallback, useEffect, useState } from 'react'
import TagService from 'api/TagService'

const baseDataSet = {
  'type: ': [
    { name: 'type: note', value: 'note', dataSetName: 'type' },
    { name: 'type: document', value: 'document', dataSetName: 'type' },
    { name: 'type: announcement', value: 'announcement', dataSetName: 'type' },
    { name: 'type: email', value: 'report', dataSetName: 'type' },
    {
      name: 'type: accounting',
      value: ['xero_report', 'quickbooks_report'],
      dataSetName: 'type',
    },
    { name: 'type: transaction', value: 'transaction', dataSetName: 'type' },
  ],
}

export const useUpdatesFilter = (isSearchByTagAllowed = true) => {
  const [filterDataSet, setFilterDataSet] = useState(baseDataSet)
  const [filters, setFilters] = useState({
    types: [],
    tags: [],
    text: '',
  })

  const getTags = useCallback(async () => {
    const {
      data: {
        entities: { tags = {} },
      },
    } = await TagService.getTags()

    const tagOptions = Object.values(tags)
    if (tagOptions?.length > 0) {
      setFilterDataSet((prevDataSet) => ({
        ...prevDataSet,
        'tag: ': tagOptions.map((tag) => ({
          name: `tag: ${tag.name}`,
          value: tag.name,
          dataSetName: 'tag',
        })),
      }))
    }
  }, [])

  const onChangeFilters = useCallback((activeFilters, resetData) => {
    const currentFilters = {
      types: activeFilters
        ?.filter((filter) => filter.dataSetName === 'type')
        .map((filter) => filter.value)
        .flat(),
      tags: activeFilters
        ?.filter((filter) => filter.dataSetName === 'tag')
        .map((filter) => filter.value),
      text: activeFilters
        ?.filter((filter) => filter.dataSetName === 'keyword')
        .map((filter) => filter.value)?.[0],
      resetData,
    }
    setFilters(currentFilters)
    return currentFilters
  }, [])

  useEffect(() => {
    if (isSearchByTagAllowed) {
      getTags()
    }
  }, [getTags, isSearchByTagAllowed])

  return {
    filterDataSet,
    filters,
    onChangeFilters,
  }
}
