import FileUploader from 'components/FileUploader'
import { useForm } from 'components/Form/hooks'
import Toast from 'components/Toast'
import AttachmentsPreview from 'components/UpdateAttachmentsPreview'
import { MediaContainer } from 'components/UpdateContent/UpdateContent.styles'
import UploadsMenu from 'components/UploadsMenu'
import { useField } from 'formik'
import React, { useEffect, useRef } from 'react'
import { buildFormError } from 'utils/functions/forms'
import { useRandomId } from 'utils/hooks/useRandomId'
import {
  useFileUploadedEventListener,
  useUploadFile,
} from 'utils/hooks/useUploadFile'
import { ErrorType } from 'utils/types/common'
import { MixedContent } from 'utils/types/files'
import { UpdateAttachment } from 'utils/types/transactions'
import { UpdateFormContext } from 'utils/types/updateForm'

export enum ContentsGridType {
  GRID = 'grid',
  LIST = 'list',
}

interface MediaUploaderProps {
  allowMultipleContents?: boolean
  disabled?: boolean
  fileGridType?: ContentsGridType
  name: string
}

function getShouldBeDisabledValue(field, allowMultipleContents, disabled) {
  // If multiple files are not allowed,
  // and the current field has a single value that has not been deleted
  if (
    !allowMultipleContents &&
    field.value?.length === 1 &&
    !field.value[0].destroy
  ) {
    return true
  }

  // If multiple files are not allowed,
  // and the current field has more than one value,
  // and the second value is a new upload
  if (!allowMultipleContents && field.value[1]?.isNewUpload) {
    return true
  }

  // If the field has been globally disabled
  return !!disabled
}

const MediaUploader: React.FC<MediaUploaderProps> = ({
  allowMultipleContents,
  disabled,
  fileGridType = ContentsGridType.GRID,
  name,
}) => {
  const [field, meta, helpers] = useField<UpdateAttachment[]>(name)
  const { submitDraft, setIsUploadingFiles } = useForm<UpdateFormContext>()
  const { uploadFiles, hasUploadsInProgress } = useUploadFile()
  const fileUploadContextId = useRandomId()
  const shouldBeDisabled = getShouldBeDisabledValue(
    field,
    allowMultipleContents,
    disabled
  )
  const isUploadingContents = hasUploadsInProgress(fileUploadContextId)
  const sourceOfTrust = useRef<UpdateAttachment[]>(field.value || [])

  const onAddContents = (result: UpdateAttachment[]) => {
    const newContents = result.filter((content) => {
      const existingFile = field.value.find(
        (stateFile) => stateFile.id === content.id
      )
      if (existingFile) {
        if (existingFile.destroy) {
          const { destroy, ...fileWithoutDestroy } = existingFile
          return helpers.setValue([
            ...field.value.filter((f) => f.id !== content.id),
            fileWithoutDestroy,
          ])
        }
        return false
      }
      return true
    })

    helpers.setValue([...field.value, ...newContents], true)
    submitDraft?.()
  }

  const handleAddUpdateAttachment = (content: MixedContent) => {
    const newFile = {
      ...content,
      isNewUpload: true,
    }
    sourceOfTrust.current = [...sourceOfTrust.current, newFile]
    onAddContents(sourceOfTrust.current)
  }

  const onSelectExistentContents = (fileContents: MixedContent[]) => {
    onAddContents(
      fileContents.map((content) => ({
        ...content,
        isNewUpload: true,
      }))
    )
  }

  const handleFileUploads = async ({ acceptedFiles, rejectedFiles }) => {
    if (rejectedFiles.length) {
      Toast.displayIntl('errors.fileUpload', 'error')
    } else {
      uploadFiles(acceptedFiles, fileUploadContextId)
    }
  }

  const handleRemoveUpdateAttachment = (content: UpdateAttachment) => {
    let newContents: UpdateAttachment[] = []
    if (content.isNewUpload) {
      newContents = field.value.filter(
        (stateFile) => stateFile.id !== content.id
      )
    } else {
      newContents = field.value.map((stateFile) => {
        if (stateFile.id === content.id) {
          return {
            ...stateFile,
            destroy: true,
          }
        }
        return stateFile
      })
    }
    helpers.setValue(newContents)
    sourceOfTrust.current = newContents
    submitDraft?.()
  }

  useFileUploadedEventListener(
    fileUploadContextId,
    (fileContent: MixedContent) => {
      handleAddUpdateAttachment(fileContent)
    }
  )

  useEffect(() => {
    setIsUploadingFiles(isUploadingContents)
  }, [setIsUploadingFiles, isUploadingContents])

  return (
    <MediaContainer>
      {!shouldBeDisabled && !disabled && (
        <FileUploader
          fileHandler={handleFileUploads}
          onSelectExistentFiles={onSelectExistentContents}
          allowMultipleFiles={!!allowMultipleContents}
          disabled={shouldBeDisabled}
          fileError={
            buildFormError(meta.error, ErrorType.ERROR, meta.touched).message
          }
        />
      )}
      <AttachmentsPreview
        contents={field.value}
        handleRemoveFile={handleRemoveUpdateAttachment}
        mode={fileGridType}
      />

      <UploadsMenu />
    </MediaContainer>
  )
}

export default MediaUploader
