import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  height: 7.1rem;
  border: 0.1rem solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 0.86rem;
  svg {
    color: ${color('darkBlue')};
    font-size: 1.5rem;
  }
`

export const Value = styled.span`
  color: ${color('darkBlue')};
  font-size: 1.8rem;
`

export const Label = styled.span`
  color: ${color('lightGray')};
  font-weight: 400;
  font-size: 1.4rem;
`
