import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const CompanyTransactionsZeroStateContainer = styled.div`
  height: 11.8rem;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    color: ${color('darkBlue')};
  }
`

export const CompanyTransactionsZeroStateTitle = styled.div`
  color: ${color('darkBlue')};
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  margin-bottom: 1.3rem;
`
