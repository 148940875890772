import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'store'
import { GridType } from 'utils/constants/gridType'

export interface CustomLogin {
  hasSignedInOnCustomLogin: boolean
  customLoginHandle: string
}
export enum ApiEnv {
  DEVELOP = 'develop',
  STAGING = 'staging',
  TESTING = 'testing',
  PRODUCTION = 'production',
}

export enum DevEnvironment {
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  DEVELOP = 'develop',
  DEVELOP_0 = 'develop-0',
  TESTING = 'testing',
  TESTING_0 = 'testing-0',
  TESTING_1 = 'testing-1',
}

export type PreferenceState = {
  companyListGridType: GridType
  customLogin?: CustomLogin
  devEnvironment?: DevEnvironment
  customApiEnv?: ApiEnv
}
export type PreferenceKeys = keyof PreferenceState

export type PreferenceStateValues = PreferenceState[keyof PreferenceState]

const initialState: PreferenceState = {
  companyListGridType: GridType.GRID,
}

export const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    setPreference: (
      state,
      action: PayloadAction<{
        key: PreferenceKeys
        value: PreferenceStateValues
      }>
    ) => {
      state[action.payload.key as string] = action.payload.value
    },
  },
})

export const { setPreference } = preferenceSlice.actions

export const getInitialPreferencesState = (state: PreferenceState) => {
  return {
    ...initialState,
    devEnvironment: state.devEnvironment,
    customApiEnv: state.customApiEnv,
  }
}

export const getCompanyListGridType = (state: RootState) =>
  state.preferences.companyListGridType

export const getDevEnvironment = (state: RootState) =>
  state.preferences.devEnvironment

export const getCustomApiEnv = (state: RootState) =>
  state.preferences.customApiEnv

export default preferenceSlice.reducer
