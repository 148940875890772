import { useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import { setInvestmentNavigationOptionSelected } from 'reducers/breadcrumbSlice'
import { Nullable } from 'utils/types/common'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { useInvestmentsFiltersContext } from '../InvestmentContext'
import { NavigationBar } from './components'
import { INVESTMENT_FILTER_TYPE, UNSAVED_FILTER_TYPE } from '../types'

const InvestmentsPortfolioNavigation = () => {
  const dispatch = useAppDispatch()

  const [hoveredPortfolioId, setHoveredPortfolioId] =
    useState<Nullable<string>>(null)

  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)

  const handleMouseEnter = (portfolioId: string) => {
    setHoveredPortfolioId(portfolioId)
  }

  const handleMouseLeave = () => {
    setHoveredPortfolioId(null)
  }

  const [hasScroll, setHasScroll] = useState(false)
  const navigationRef = useRef<Nullable<HTMLDivElement>>(null)

  const {
    normalizedNavigationOptions,
    displayBigPortfolioNavigation,
    selectedPortfolioIndex,
    portfolioTypeFilterOptions,
    selectOption,
    setDisplayBigPortfolioNavigation,
    setCreatePortfolioModalOpen,
    onOpenCreateFilterDrawer,
  } = useInvestmentsFiltersContext()

  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const debounceSetHasScrollFalse = () => {
    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current)
    }
    scrollTimeoutRef.current = setTimeout(() => {
      setHasScroll(false)
    }, 200)
  }

  const handleScroll = () => {
    setHasScroll(true)
    debounceSetHasScrollFalse()
  }

  useEffect(() => {
    const scrollToSelectedItem = () => {
      if (navigationRef.current === null) return
      if (selectedPortfolioIndex === null) return

      const selectedItemRef =
        navigationRef.current.children[selectedPortfolioIndex]

      if (!selectedItemRef) return

      selectedItemRef.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      })
    }

    setTimeout(scrollToSelectedItem, 1000)
  }, [selectedPortfolioIndex])

  const visiblePortfolios = useMemo(() => {
    if (normalizedNavigationOptions === null) return []

    return normalizedNavigationOptions?.filter(
      (portfolio) =>
        portfolio.showOption &&
        portfolio.type !== INVESTMENT_FILTER_TYPE &&
        portfolio.type !== UNSAVED_FILTER_TYPE
    )
  }, [normalizedNavigationOptions])

  const visibleFilters = useMemo(() => {
    if (normalizedNavigationOptions === null) return []

    return normalizedNavigationOptions?.filter(
      (option) => option.showOption && option.type === INVESTMENT_FILTER_TYPE
    )
  }, [normalizedNavigationOptions])

  const visibleUnsavedFilters = useMemo(() => {
    if (normalizedNavigationOptions === null) return []

    return normalizedNavigationOptions?.filter(
      (option) => option.showOption && option.type === UNSAVED_FILTER_TYPE
    )
  }, [normalizedNavigationOptions])

  const visiblePortfolioTypeFilterOptions = portfolioTypeFilterOptions?.filter(
    (option) => option.showOption
  )

  const isNavigationEmpty = useMemo(
    () => isEmpty(normalizedNavigationOptions),
    [normalizedNavigationOptions]
  )

  useEffect(() => {
    const allOptions = [
      ...visiblePortfolios,
      ...visibleFilters,
      ...visibleUnsavedFilters,
    ]

    const selectedOption = allOptions.find((option) => option.isSelected)

    if (selectedOption) {
      dispatch(setInvestmentNavigationOptionSelected(selectedOption))
    }
  }, [dispatch, visibleFilters, visiblePortfolios, visibleUnsavedFilters])

  if (isNavigationEmpty) return <NavigationBar />

  return (
    <NavigationBar isExpanded={displayBigPortfolioNavigation}>
      <NavigationBar.List
        options={[
          ...visiblePortfolios,
          ...visibleFilters,
          ...visibleUnsavedFilters,
        ]}
        ref={navigationRef}
        hasAllHoldings={false}
        hasScroll={hasScroll}
        isBig={displayBigPortfolioNavigation}
        hasCreatePortfolio={!isInvestorGroup}
        hoveredPortfolioId={hoveredPortfolioId}
        onMouseEnter={(id) => handleMouseEnter(id)}
        onMouseLeave={handleMouseLeave}
        onItemClick={(option) => {
          setDisplayBigPortfolioNavigation(false)
          selectOption(option)
        }}
        onScroll={handleScroll}
        onAddButtonClick={() => setCreatePortfolioModalOpen(true)}
      />

      <NavigationBar.Actions
        visiblePortfolioTypeFilterOptions={visiblePortfolioTypeFilterOptions}
        openFilterDrawer={onOpenCreateFilterDrawer}
      />
    </NavigationBar>
  )
}

export default InvestmentsPortfolioNavigation
