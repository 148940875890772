import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import TooltipContentToStyle from 'containers/Chat/components/TooltipContent'
import { Dots } from 'containers/Chat/components/TypingIndicator'
import TooltipToStyle from 'components/Tooltip'
import AvatarComp from 'components/Avatar'
import GroupLogoToStyle from 'components/GroupLogo'
import PortfolioIconToStyle from 'components/PortfolioIcon'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const Members = styled.div<{
  selected?: boolean
}>`
  font-size: 1.4rem;
  padding-left: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  max-width: calc(100% - 2rem);

  ${({ selected }) =>
    selected &&
    css`
      color: ${color('white')};
    `}
`

export const HideIcon = styled(FontAwesomeIcon)`
  color: ${color('darkBlue')};
  font-size: 1.9rem;
  display: none;
  margin-left: 0.8rem;

  &:hover {
    color: ${color('primaryBlue')};
  }
`

export const Row = styled.div`
  display: flex;
  align-items: center;
`

export const Container = styled.div<{
  selected?: boolean
}>`
  width: 100%;
  max-width: 100%;
  padding: 0.7rem 1.2rem 0.7rem 1rem;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  container-type: inline-size;

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${color('primaryBlue', 0.9)};
    `}

  &:hover {
    ${({ selected }) =>
      !selected
        ? css`
            background-color: ${color('veryLightBlue', 0.4)};
          `
        : css`
            ${HideIcon} {
              color: ${color('white')};

              &:hover {
                color: ${color('white', 0.5)};
              }
            }
          `}

    ${HideIcon} {
      display: block;
    }

    @media ${maxSize.xxs} {
      ${HideIcon} {
        display: none;
      }
    }
  }

  @media ${maxSize.mobile} {
    padding: 0.7rem 2.4rem;
    position: relative;
  }
`

export const Avatar = styled(AvatarComp)`
  border: 0.1rem solid ${color('veryLightGray')};
`

export const MembersCountBadge = styled.div`
  position: absolute;
  margin-left: 1.4rem;
  margin-top: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.4rem;
  height: 1.4rem;
  background-color: ${color('darkGray')};
  color: ${color('white')};
  font-weight: 700;
  font-size: 1rem;
  border-radius: 2.5rem;
  padding: 0 0.4rem;
`

export const TooltipContent = styled(TooltipContentToStyle)`
  margin-left: 0.5rem;
  display: flex;
  align-items:: center;
  `

export const PinContainer = styled(MembersCountBadge)`
  width: 1.4rem;
  margin-left: -0.4rem;
  margin-top: -1rem;
  background-color: ${color('veryLightGray')};
  color: ${color('darkGray')};
  font-size: 0.8rem;
`

export const MuteIconContainer = styled(PinContainer)`
  margin-left: -0.4rem;
  margin-top: -2.6rem;
`

export const NewMessagesCountBadge = styled(MembersCountBadge)`
  min-width: 1.8rem;
  height: 1.8rem;
  margin: 0;
  background-color: ${color('green')};
  color: ${color('white')};
  position: relative;
`

export const DotsContainer = styled.div<{
  isATopicChannel?: boolean
}>`
  width: 2.2rem;
  height: 2.2rem;
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0.2rem;
  background: ${color('white')};

  ${({ isATopicChannel }) =>
    isATopicChannel &&
    css`
      width: 2.2rem;
      height: 2.2rem;
    `}
`

export const AvatarContainer = styled.div<{
  selected?: boolean
  areTypingInATopicChannel?: boolean
}>`
  position: relative;
  padding-left: 0.3rem;
  margin-bottom: ${({ areTypingInATopicChannel }) =>
    areTypingInATopicChannel ? '0.23rem' : '0'};

  div:first-child:not(${DotsContainer}):not(${Dots}) {
    min-width: 2.2rem;
    min-height: 2.2rem;
    border: none;
  }
`

export const GroupLogo = styled(GroupLogoToStyle)`
  border: initial;
  width: 2.2rem;
  height: 2.2rem;
`

export const Tooltip = styled(TooltipToStyle)`
  min-width: 0;
  display: flex;
  width: 100%;

  > a {
    width: 100%;
  }
`

export const PortfolioIcon = styled(PortfolioIconToStyle)`
  font-size: 1.2rem;
`
