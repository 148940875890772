import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const AnalyticsChartContainer = styled.div``
export const ChartNavigatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const ChartLegend = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  font-weight: 700;
`

export const DummyButton = styled.div`
  width: 4rem;
`
