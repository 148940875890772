import React from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser } from 'selectors/auth'
import Briefing from '../Briefing/Briefing'
import { UserSettingsContainer } from './UserSettings.styles'

const UserSettings = () => {
  const user = useAppSelector(getUser)

  if (!user) return null

  return (
    <UserSettingsContainer>
      <Briefing
        id="user-settings"
        type="user"
        name={user.name}
        logoUrl={user.image.mediumLogo.url}
        handle={user.handle}
        isMainBriefing
        clickable={false}
      />
    </UserSettingsContainer>
  )
}

export default UserSettings
