import styled from 'styled-components'
import IconButton from 'ui/IconButton'
import { breakpoint } from 'utils/constants/breakpoint'

export const NavigationArrowWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  position: relative;
`

export const BtnNavigation = styled(IconButton)`
  @media (max-width: ${breakpoint.lg}) {
    font-size: 1.4rem;
  }
`
