import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const Container = styled.a`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  border-radius: 0.8rem;
  background-color: ${color('white')};
  border: 0.1rem solid ${color('veryLightGray')};
  margin-top: 0.8rem;
  color: ${color('black')};
  width: 100%;
  max-width: 30rem;
  min-height: 5.6rem;
  padding: 1rem 1.4rem;
`

export const AttachmentTitle = styled.div`
  font-weight: 700;
`

export const Date = styled.div`
  font-size: 12px;
  color: ${color('lightGray')};
`
