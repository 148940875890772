import React from 'react'
import type { ColorFormat } from 'react-countdown-circle-timer'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import * as Styles from './CircleTimer.styles'

interface CircleTimerProps {
  time: number
  color: string
}

const CircleTimer: React.FC<CircleTimerProps> = ({ time, color }) => {
  const renderTime = ({ remainingTime }) => (
    <Styles.Number color={color}>{remainingTime}</Styles.Number>
  )

  return (
    <CountdownCircleTimer
      isPlaying
      size={30}
      strokeWidth={2}
      duration={time}
      colors={color as ColorFormat}
    >
      {renderTime}
    </CountdownCircleTimer>
  )
}

export default CircleTimer
