import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

const selectedStyles = css`
  border: 0.1rem solid ${color('primaryBlue')};
  background-color: #e9ecf4;
  color: ${color('primaryBlue')};
  svg {
    color: ${color('primaryBlue')};
  }
`

export const iconButtonHoverStyles = css`
  ${(props) =>
    !props.isActive &&
    !props.disabled &&
    css`
      background: ${color('veryLightBlue', props.hoverOpacity || 0.4)};
      svg {
        color: ${color('primaryBlue')};
      }
    `}
`
export const Button = styled.button`
  background-color: ${color('white')};
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${(props) =>
    props.buttonType === 'circle' ? '100%' : '.8rem'};

  border: ${(props) =>
    props.border &&
    css`
      0.1rem solid ${color('lightGray', 0.5)};
      `};

  ${(props) =>
    props.isActive
      ? props.activeStyles ||
        css`
          outline: none;
          background-color: #e9ecf4;
          border: 1px solid #194590;
          > svg {
            color: #194590;
          }
        `
      : css`
          background: transparent;
        `}

  border-radius: ${(props) =>
    props.buttonType === 'circle' ? '100%' : '.8rem'};

  border: ${(props) =>
    props.border &&
    css`
      0.1rem solid ${color(
        props.borderColor || 'lightGray',
        props.borderOpacity || 0.5
      )};
    `};

  ${(props) =>
    props.color
      ? css`
          color: ${props.color};
        `
      : css`
          color: ${color('darkBlue')};
        `};
  font-size: ${(props) => props.iconFontSize || '1.8rem'};
  height: ${(props) => props.buttonSize || '4rem'};
  width: ${(props) => props.buttonSize || '4rem'};
  min-width: ${(props) => props.minSize || 'initial'};
  min-height: ${(props) => props.minSize || 'initial'};
  position: relative;
  transition: background 0.2s ease-in-out, box-shadow 0.1s, color 0.2s;

  svg {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:focus {
    outline: none;
    color: #194590;
    ${(props) =>
      props.borderOnFocus &&
      css`
        border: solid 0.1rem #194590;
      `}

    ${(props) => props.isActive && props.activeStyles}
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    ${iconButtonHoverStyles}
  }

  &:active {
    box-shadow: inset 0.4rem 0.4rem 0.4rem rgba(120, 126, 140, 0.25);
  }

  &:selected {
    ${selectedStyles}
  }
  ${(props) => props.selected && selectedStyles}
  ${(props) =>
    props.roundSquare &&
    css`
      border-radius: 0.8rem;
    `}

  ${(props) =>
    props.transparent &&
    css`
      background-color: transparent;
      border: none;
    `}
  ${(props) =>
    props.danger &&
    css`
      color: ${color('red')};

      &:hover {
        background-color: ${color('softRed', 0.9)};
        svg {
          color: ${color('red')};
        }
      }

      &:focus {
        border: 0.1rem solid ${color('red')};
      }
    `}

    ${(props) =>
    props.background &&
    css`
      background-color: ${color(props.background)};
    `}

    ${(props) =>
    props.direction === 'vertical' &&
    css`
      transform: rotate(90deg);
    `}

    ${(props) =>
    !props.roundedButtonHover &&
    css`
      &:focus {
        border: none;
        background-color: transparent;
      }

      &:active {
        box-shadow: none;
      }

      &:hover {
        background-color: transparent;
        background: transparent;
      }
    `}
`
