import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const MoicDot = styled.div`
  background-color: ${color('black')};
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
`

export const CustomLegend = styled.span`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 700;
  margin-right: 0.6rem;
`

export const CardTitle = styled.h2`
  text-transform: capitalize;
  color: ${color('darkBlue')};
  font-size: 1.6rem;
  font-weight: 400;
`

export const Header = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  left: 6rem;

  @media ${maxSize.sm} {
    align-items: baseline;
    flex-direction: column;
    gap: 1.6rem;
  }
`
export const ClassSelectorWrapper = styled.div`
  .recharts-default-legend {
    display: flex !important;
  }

  .recharts-legend-item {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    &:nth-child(4) {
      .recharts-surface {
        width: 1.5rem !important;
        height: 1.5rem !important;
      }
    }
  }

  .recharts-brush-texts {
    display: none;
  }

  .recharts-brush-slide {
    fill: ${color('veryLightBlue')};
  }

  .recharts-surface {
    overflow: visible;
  }

  .brush {
    width: 100%;
    opacity: 1;
    overflow: visible;

    & svg {
      filter: drop-shadow(0rem 0.2rem 0.2rem rgba(159, 150, 150, 0.25));
    }

    .recharts-brush-traveller {
      opacity: 1;
    }

    > rect:first-child {
      rx: 0.8rem;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;

  @media ${maxSize.sm} {
    flex-direction: column-reverse;
  }
`

export const ChartWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`

export const Period = styled.div`
  align-items: center;
  display: flex;
  gap: 0.8rem;

  @media ${maxSize.sm} {
    width: 100%;
    justify-content: space-between;
  }

  @media ${maxSize.xxs} {
    align-items: baseline;
    flex-direction: column;
  }
`
