import { UpdateTypes } from 'utils/constants/updates'
import { useFundPortfolioInvestmentVehicleQuery } from 'utils/hooks/investorManagement/useFundPortfolioInvestmentVehicleQuery'
import { useInvestmentVehicleQuery } from 'utils/hooks/investorManagement/useInvestmentVehiclesQuery'
import { useShowUpdateRoute } from 'utils/hooks/useShowUpdateRoutes/useShowUpdateRoute'
import { TransactionItem } from 'utils/types/update'
import { useSetCurrentInvestmentVehicle } from 'utils/hooks/useCurrentInvestorManagementEntities'
import { UpdateUrlSuffix } from 'utils/hooks/useShowUpdateRoutes/types'

const useShowInvestmentVehicle = (vehicleId: string, investorId?: string) => {
  const showUpdateRoute = useShowUpdateRoute()
  const { data: investmentVehicle, isLoading } =
    useInvestmentVehicleQuery(vehicleId)

  useSetCurrentInvestmentVehicle(investmentVehicle)

  const { data: fundsPortfolioInvestor, isLoading: isLoadingFunds } =
    useFundPortfolioInvestmentVehicleQuery(vehicleId)

  const onShowTransaction = (transaction: TransactionItem) => {
    showUpdateRoute.fromInvestmentVehicle({
      updateId: transaction.loggingUpdateId,
      updateType: UpdateTypes.TRANSACTION,
      investmentVehicle: investmentVehicle!,
      investorId,
    })
  }

  const onEditTransaction = (transaction: TransactionItem) => {
    showUpdateRoute.fromInvestmentVehicle({
      updateId: transaction.loggingUpdateId,
      updateType: UpdateTypes.TRANSACTION,
      investmentVehicle: investmentVehicle!,
      suffix: UpdateUrlSuffix.EDIT,
      investorId,
    })
  }

  return {
    investmentVehicle,
    fundsPortfolioInvestor: fundsPortfolioInvestor || [],
    isLoading,
    isLoadingFunds,
    onShowTransaction,
    onEditTransaction,
  }
}

export default useShowInvestmentVehicle
