import { useLocation } from 'react-router-dom'

import { Session } from 'utils/types/user'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getGroupType } from 'features/signUpSlice'
import { useSignupContext } from 'containers/Signup/context/SignupContext'

const useGetStarted = () => {
  const location = useLocation()
  const { isUpdatingUserInfo, onClickFounderSetup } = useSignupContext()
  const groupType = useAppSelector(getGroupType)

  const session = (location.state as { session?: Session })?.session
  const currentUser = session?.user

  return {
    onClickFounderSetup,
    currentUser,
    groupType,
    isUpdatingUserInfo,
  }
}

export default useGetStarted
