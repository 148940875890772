/* eslint-disable react/no-unused-prop-types, react-hooks/exhaustive-deps, react/destructuring-assignment */
import React, { useMemo } from 'react'
import { get } from 'lodash'
import {
  useTableSorting,
  UseTableSortingState,
} from 'components/ExpandableTable/hooks/useTableSorting'
import { useResizeObserver } from 'utils/hooks/useResizeObserver'

import { TableLayoutHelpers } from 'components/ExpandableTable/hooks/useTableLayout'
import { SortDirection } from 'utils/constants/sortDirection'
import { DataType, TableProps } from './types'
import { HoverIndexProps } from './useHoverIndex'

export type TableContextType<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType
> = Required<
  Omit<
    TableProps<RowDataType, ExpandedRowDataType>,
    'onClickExpandedRow' | 'rowContextMenu' | 'expandedRowContextMenu'
  >
> &
  Pick<
    TableProps<RowDataType, ExpandedRowDataType>,
    'onClickExpandedRow' | 'rowContextMenu' | 'expandedRowContextMenu'
  > &
  HoverIndexProps & {
    sortHelpers: UseTableSortingState
    tableLayoutHelpers: TableLayoutHelpers
    expandedRowId: string
    isRowExpanded: (rowId: string) => boolean
    expandRow: (rowId: string) => boolean
    collapseRow: (rowId: string) => boolean
    toggleRow: (rowId: string, rowData: RowDataType) => void
    getRowId: (rowData: RowDataType) => string
    expandedRowIds: string[]
    fixedList: boolean
    tableWidth: 'auto' | number
  }

export const TableContext = React.createContext({})

export function useTableContext<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType
>() {
  const ctx = React.useContext<
    TableContextType<RowDataType, ExpandedRowDataType>
  >(TableContext as any)

  return ctx
}

export function TableContextProvider<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType
>(
  props: TableProps<RowDataType, ExpandedRowDataType> &
    HoverIndexProps & {
      tableLayoutHelpers: TableLayoutHelpers
      children: React.ReactNode
      expandedRowIds: string[]
      toggleRow: (rowId: string, rowData: RowDataType) => void
      isRowExpanded: (rowId: string) => boolean
      fixedList: boolean
      tableContainerId: string
    }
) {
  const sortHelpers = useTableSorting({
    initialSortId: props.initialSortId ?? props.columns[0]?.sortKey ?? '',
    initialSortDirection: props.initialSortDirection || SortDirection.ASC,
    onSort: props.onChangeSortBy || (() => {}),
  })

  const { width: tableWidth } = useResizeObserver(props.tableContainerId)

  const getRowId = (rowData: RowDataType): string => get(rowData, props.keyPath)

  const memoizedContext = useMemo(
    () => ({
      ...props,
      sortHelpers,
      getRowId,
      tableWidth,
    }),
    [props, sortHelpers]
  )

  return (
    <TableContext.Provider value={memoizedContext}>
      {props.children}
    </TableContext.Provider>
  )
}
