import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { GridType } from 'utils/constants/gridType'
import IconButton from 'ui/IconButton'

import styles from './GridSelector.module.scss'

const GridSelector = ({ initialGridType, onChange, buttonSize }) => {
  const [gridType, setGridType] = useState(initialGridType)
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    } else {
      onChange?.(gridType)
    }
  }, [onChange, gridType])

  return (
    <div className={classNames('grid-selector', styles.gridSelectorContainer)}>
      <IconButton
        data-testid="grid-type-button"
        className={classNames(styles.icon)}
        selected={gridType === GridType.GRID}
        onClick={() => setGridType(GridType.GRID)}
        icon="grip-horizontal"
        border={false}
        roundSquare
        buttonSize={buttonSize}
      />
      <IconButton
        data-testid="row-type-button"
        className={classNames(styles.icon)}
        selected={gridType === GridType.ROWS}
        onClick={() => setGridType(GridType.ROWS)}
        icon="bars"
        border={false}
        roundSquare
        buttonSize={buttonSize}
      />
    </div>
  )
}

GridSelector.propTypes = {
  initialGridType: PropTypes.oneOf(Object.values(GridType)),
  onChange: PropTypes.func.isRequired,
  buttonSize: PropTypes.string,
}

GridSelector.defaultProps = {
  initialGridType: GridType.GRID,
  buttonSize: '',
}

export default GridSelector
