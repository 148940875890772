import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import TabsToStyle from 'components/Tabs'
import { maxSize } from 'utils/constants/breakpoint'

export const ChatSearchContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${color('cloud')};

  @media ${maxSize.mobile} {
    background: ${color('white')};
    padding: 0 2.4rem;
    height: calc(100% - 2rem);
  }
`

export const SearchContent = styled.div`
  padding: 5.8rem 3.2rem 3.2rem 3.2rem;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${maxSize.mobile} {
    padding: initial;
  }
`

export const SearchTitle = styled.h1`
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: ${color('gray')};
`

export const ZeroStateContainer = styled.div`
  display: flex;
  height: 100%;
`

export const Tabs = styled(TabsToStyle)`
  padding-top: 2.2rem;
  height: calc(100% - 2.2rem);
  display: flex;
  flex-direction: column;

  > div:nth-of-type(1) {
    padding: initial;
  }
`

export const TabContent = styled(Tabs.TabContent)`
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
`
