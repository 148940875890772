import { useCallback } from 'react'

import AuthService from 'api/AuthService'
import Toast from 'components/Toast'
import { useAuth0 } from '@auth0/auth0-react'

const useAlmostThereForm = () => {
  const { user } = useAuth0()

  const sendEmail = useCallback(async (showToast: boolean) => {
    try {
      await AuthService.sendSignUpConfirmationEmail()

      if (showToast) {
        Toast.displayIntl('emailConfirmation.successMessage', 'success')
      }
    } catch (error) {
      Toast.displayIntl('emailConfirmation.error', 'error')
    }
  }, [])

  return {
    onResendEmail: () => sendEmail(true),
    userEmail: user?.email,
  }
}

export default useAlmostThereForm
