import styled from 'styled-components/macro'
import { css } from 'styled-components'

export const IconWrapper = styled.div<{ isSelectingSuggestedUpdates: boolean }>`
  margin-top: 0.7rem;
  position: relative;

  ${({ isSelectingSuggestedUpdates }) =>
    isSelectingSuggestedUpdates &&
    css`
      pointer-events: none;
    `};
`
