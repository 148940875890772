import { fadeIn } from 'components/FadeIn/FadeIn'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const CardWrapper = styled.div<{
  largePadding?: boolean
  padding?: string
  noPadding?: boolean
  shadow?: boolean
  fitContent?: boolean
  fullHeight?: boolean
}>`
  background: var(--color-white);
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  padding: 2.2rem;
  position: relative;

  .DraftEditor-editorContainer {
    a {
      color: -webkit-link;
      cursor: pointer;
      text-decoration: underline;

      span {
        color: -webkit-link;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  ${(props) =>
    props.largePadding
      ? css`
          padding: 6rem;

          @media (max-width: 767px) {
            padding: 2.5rem;
          }
        `
      : ''}

  ${(props) =>
    props.padding
      ? css`
          padding: ${props.padding};
        `
      : ''}

  ${(props) =>
    props.noPadding
      ? css`
          padding: 0;
        `
      : ''}
  
  ${(props) =>
    props.shadow
      ? css`
          box-shadow: 0 0 1.5rem rgba(16, 21, 39, 0.1);
        `
      : ''}
  
  ${(props) =>
    props.fitContent
      ? css`
          height: fit-content;
        `
      : ''}
  
  ${(props) =>
    props.fullHeight
      ? css`
          min-height: calc(100vh - 5rem);
        `
      : ''}
`

export const CardHeader = styled.div`
  color: var(--color-gray-900);
  font-size: 1.6rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 2rem;
`

export const CardBody = styled.div<{
  bodyMargin?: string
  isExpandable?: boolean
  isExpanded?: boolean
}>`
  margin-top: 0.4rem;
  ${(props) =>
    props.bodyMargin
      ? css`
          margin-top: ${props.bodyMargin};
        `
      : ''}
  ${(props) =>
    !props.isExpandable || props.isExpanded
      ? css`
          height: fit-content;
          animation: 0.3s ${fadeIn} ease-out;
        `
      : css`
          height: 0px;
          overflow: hidden;
          margin: 0;
        `}
`

export const HeaderWrapper = styled.div<{ padding?: string; margin?: string }>`
  display: flex;
  align-items: center;
  height: 3.3rem;
  gap: 0.6rem;

  ${(props) =>
    props.padding
      ? css`
          padding: ${props.padding};
        `
      : ''}

  ${(props) =>
    props.margin
      ? css`
          margin: ${props.margin};
        `
      : ''}

  > svg {
    font-size: 1.5rem;
    cursor: pointer;
  }
`

export const IconWrapper = styled.div<{ isExpanded: boolean }>`
  transform: ${(props) =>
    props.isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'};
  font-size: 1.6rem;
  cursor: pointer;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  gap: 0.6rem;
  color: ${color('darkBlue')};
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.7rem;
  width: 100%;
  height: 2rem;
`
