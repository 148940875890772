/* eslint-disable react-hooks/exhaustive-deps */
import { useInfiniteQuery } from '@tanstack/react-query'
import { get } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { SortDirection } from 'utils/constants/sortDirection'
import { SortDirectionType } from 'utils/types/common'
import { useTableContext } from '../TableContextProvider'
import { DataType } from '../types'

export default function useRow<
  RowDataType extends DataType,
  ExpandedRowDataType extends DataType
>(data: RowDataType[], index: number) {
  const tableContext = useTableContext()

  const rowRef = useRef<HTMLDivElement>(null)

  const [expandedRowLoading, setExpandedRowLoading] = useState<boolean>(false)

  const [currentSort, setCurrentSort] = useState<{
    sortId: string
    sortDirection: SortDirectionType
  }>({
    sortId:
      tableContext.internalTableInitialSortId ??
      tableContext.columns[0]?.sortKey ??
      '',
    sortDirection:
      tableContext.internalTableInitialSortDirection ?? SortDirection.ASC,
  })
  const rowData = data[index]
  const rowId = get(rowData, tableContext.keyPath)
  const rowExpanded = tableContext.isRowExpanded(rowId)
  const cells = useMemo(
    () => tableContext.columns.filter((col) => !col.fixed && !col.hidden),
    [tableContext.columns]
  )
  const [countElementsLoaded, setCountElementsLoaded] = useState<number>(
    tableContext.embeddedPageSize
  )

  const [hasMoreExternalPages, setHasMoreExternalPages] =
    useState<boolean>(true)

  const {
    data: paginatedExpandedDataToShow,
    fetchNextPage,
    isFetching: expandedRowPageLoading,
    refetch,
  } = useInfiniteQuery(
    ['embedded-table-data-query', rowId, currentSort],
    async ({ pageParam = 1 }) => {
      const pageSize =
        pageParam === 1 ? countElementsLoaded : tableContext.embeddedPageSize

      const pageData = await tableContext.getExpandableData(
        rowData,
        pageParam,
        pageSize,
        currentSort.sortId,
        currentSort.sortDirection
      )

      setCountElementsLoaded(
        pageParam === 1 ? pageData.length : countElementsLoaded + pageSize
      )

      if (pageData.length < tableContext.embeddedPageSize)
        setHasMoreExternalPages(false)

      return {
        data: pageData,
        page: pageParam,
        countPagesLoaded: pageSize / tableContext.embeddedPageSize,
      }
    },
    {
      getNextPageParam: (pageParams) => {
        return pageParams.page + pageParams.countPagesLoaded
      },
      enabled: rowExpanded,
    }
  )

  const expandedDataToShow = useMemo(
    () =>
      (paginatedExpandedDataToShow?.pages.flatMap((p) => p.data) ??
        []) as ExpandedRowDataType[],
    [paginatedExpandedDataToShow]
  )

  const onLoadMoreRows = () => {
    fetchNextPage()
  }

  const customRow = tableContext.customRows?.find(
    (cr) => cr.index === index && cr.showCustomRow
  )

  useEffect(() => {
    if (tableContext.fixedList) {
      const height =
        rowRef.current?.getBoundingClientRect?.().height ??
        tableContext.rowHeight
      tableContext.tableLayoutHelpers.setSize(index, height)
    }
  }, [
    expandedDataToShow,
    rowExpanded,
    expandedRowLoading,
    expandedRowPageLoading,
    customRow,
  ])

  const showZeroState =
    rowExpanded &&
    !expandedRowLoading &&
    !expandedRowPageLoading &&
    expandedDataToShow.length === 0

  useEffect(() => {
    setExpandedRowLoading(expandedRowPageLoading)
  }, [expandedRowPageLoading])

  const onClickRow = () => {
    if (tableContext.embeddedTable) {
      tableContext.onClickExpandedRow?.(rowData, tableContext.parentRowData)
    } else if (tableContext.expandOnRowClick) {
      setExpandedRowLoading(true)
      tableContext.toggleRow(rowId, rowData)
    } else if (tableContext.onClickRow) {
      tableContext.onClickRow(rowData, index)
    }
  }

  const onDoubleClickRow = () => {
    if (tableContext.onDoubleClickRow) {
      tableContext.onDoubleClickRow(rowData)
    }

    if (tableContext.embeddedTable && tableContext.onDoubleClickExpandedRow) {
      tableContext.onDoubleClickExpandedRow(rowData)
    }
  }

  const onChangeSortBy = async ({ sortId, sortDirection }) => {
    setCurrentSort({ sortId, sortDirection })
    setTimeout(async () => {
      await refetch()
    }, 0)
  }

  return {
    tableContext,
    rowData,
    showZeroState,
    expandedDataToShow,
    currentSort,
    expandedRowLoading,
    expandedRowPageLoading,
    hasMorePages: hasMoreExternalPages,
    onLoadMoreRows,
    customRow,
    rowRef,
    rowExpanded,
    onClickRow,
    onDoubleClickRow,
    cells,
    onChangeSortBy,
  }
}
