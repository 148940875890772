import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

import { getCurrentGroupId } from 'selectors/auth'
import Tooltip from 'components/Tooltip/Tooltip'
import Title from 'components/Title'
import UpdateCardBox from '../UpdateCardBox'
import {
  CopyContainer,
  CopyButton,
  CopyTooltipContainer,
  UpdateLink,
} from './ShareUpdate.styles'

const ShareUpdate = () => {
  const intl = useIntl()
  const [copied, setCopied] = useState(false)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const link = `${window.location.href}?group_id=${currentGroupId}`

  const handleCopyLink = (event) => {
    event.preventDefault()
    navigator.clipboard.writeText(link)
    setCopied(true)
  }

  const getTooltipPosition = () => {
    const copyButton = document.getElementById('copyButton')
    const rect = copyButton?.getBoundingClientRect()

    return {
      x: rect?.x ?? 0 - 60,
      y: rect?.y ?? 0 - 45,
    }
  }

  return (
    <UpdateCardBox>
      <UpdateCardBox.Header>
        <Title
          title={intl.messages['shareUpdate.title']}
          icon={['far', 'share-square']}
        />
      </UpdateCardBox.Header>
      <UpdateCardBox.Body>
        <Tooltip
          id="copy-tooltip"
          text={
            <CopyTooltipContainer
              style={{ display: copied ? 'block' : 'none' }}
            >
              {intl.messages['shareUpdate.linkCopied']}
            </CopyTooltipContainer>
          }
          place="top"
          float
          backgroundColor="transparent"
          afterHide={() => setCopied(false)}
          position={getTooltipPosition()}
        >
          <CopyContainer id="copy-text" onClick={handleCopyLink}>
            <UpdateLink title={link}>{link}</UpdateLink>
            <CopyButton>
              <FontAwesomeIcon id="copyButton" icon={['far', 'clone']} />
            </CopyButton>
          </CopyContainer>
        </Tooltip>
      </UpdateCardBox.Body>
    </UpdateCardBox>
  )
}

ShareUpdate.propTypes = {}

export default ShareUpdate
