import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export default styled.div`
  background-color: ${color('gray')};
  z-index: 0;
  width: 100vw;
  height: 2.8rem;
  -webkit-app-region: drag;
`
