import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { motion } from 'framer-motion'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 3.4rem 5fr 2fr 1fr 2fr 1fr;
  gap: 1rem;
  padding: 0 1.8rem;
  height: 5.7rem;
  align-items: center;
`

export const CardWrapper = styled(motion.div)<{ isSelected: boolean }>`
  box-shadow: 0 0 0rem 0.1rem #e9e9e9;
  border-radius: 0.8rem;
  width: 19.2rem;
  height: 11.9rem;
  padding-top: 1.6rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      box-shadow: 0 0 0rem 0.2rem ${color('primaryBlue')};
    `}
  ${(props) =>
    !props.isSelected &&
    css`
      &:hover {
        box-shadow: 0 0 0rem 0.2rem #e9e9e9;
      }
    `}

  @media (max-width: 1439px) {
    min-width: 19.2rem;
    width: 100%;
  }
`

export const CardContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  padding: 0 1.2rem 0 1.6rem;
`

export const FileIconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  svg {
    font-size: 1.5rem;
  }
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`

export const FileName = styled.span<{ maxWidth?: string }>`
  color: ${color('darkGray')};
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.68rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
  max-width: ${(props) => props.maxWidth};
`

export const FileLabel = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  line-height: 1.44rem;
`

export const Footer = styled.div`
  padding: 1rem 1.2rem 1rem 1.6rem;
  border-top: 0.1rem solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  button {
    padding: 0;
    color: ${color('darkGray')};
  }
`

export const DownloadsIndicator = styled.div`
  color: ${color('lightGray')};
  font-size: 1.4rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
`
export const DropdownContainer = styled.div`
  position: absolute;
  right: 0.367rem;
  top: 0.4rem;
`

export const CardImagePreview = styled.div<{
  src: string
}>`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 0.6rem;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`
