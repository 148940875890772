import { Helmet } from 'components/Helmet'
import { useIntl } from 'react-intl'
import { Nullable } from 'utils/types/common'
import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { MetricsMode } from 'utils/types/metrics'

import CreateInvestorMetric from './CreateInvestorMetric'
import CreateFounderMetric from './CreateFounderMetric'

interface CreateMetricProps {
  closeDrawer: () => void
  isOpen: boolean
}

const CreateMetric = ({ closeDrawer, isOpen }: CreateMetricProps) => {
  const intl = useIntl()
  const metricsMode = useMetricsMode()

  let createMetricComponent: Nullable<React.ReactNode> = null

  if (metricsMode === MetricsMode.FOUNDER) {
    createMetricComponent = (
      <CreateFounderMetric isOpen={isOpen} onCancel={closeDrawer} />
    )
  } else if (metricsMode === MetricsMode.CLIENT) {
    createMetricComponent = (
      <CreateInvestorMetric isOpen={isOpen} closeDrawer={closeDrawer} />
    )
  }

  return (
    <>
      <Helmet>
        <title>{`${intl.messages['metaTitles.createMetric']}`}</title>
      </Helmet>

      {createMetricComponent}
    </>
  )
}

export default CreateMetric
