import styled from 'styled-components'
import Card from 'components/Card'
import { color } from 'utils/functions/colors'

export const Title = styled.h2`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.7rem;
  margin-bottom: 1.6rem;
  text-transform: uppercase;
`

export const Updates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  font-size: 1.4rem;
  height: 100%;
  user-select: none;
`

export const UpdateCard = styled(Card)`
  padding: 1.6rem;
`

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const UpdateLogo = styled.div`
  div {
    margin: 0;
    position: relative;
  }
`

export const UpdateTitle = styled.h2`
  color: ${color('black')};
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  user-select: none;
  white-space: nowrap;
  width: 25rem;
`

export const UpdateDate = styled.span`
  color: ${color('lightGray')};
  font-weight: 400;
  line-height: 1.7rem;
`

export const ContentsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  .chevron {
    color: ${color('lightGray')};
  }

  .update-link {
    text-align: center;
    width: 2.5rem;
  }
`

export const Contents = styled.div`
  width: 27.8rem;
`
