/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ListService from 'api/ListService'
import Checkbox from 'components/Checkbox'
import Toast from 'components/Toast'
import {
  belongsToAnotherCheckedList,
  setGroupsUsersToListAssociated,
  CHECKBOX_INDETERMINATE,
} from 'utils/functions/lists'

import styles from './ListsSelectionBox.module.scss'

const ListsSelectionBox = ({
  items,
  handleAddGroup,
  handleAddUser,
  handleDelete,
  handleEditItemList,
  columns,
  rowGap,
  showLabel,
  onlyGroups,
  customLabel,
  onlyUsers,
  customLinkMessage,
  onSelectList,
  onUncheckList,
}) => {
  const intl = useIntl()

  const [lists, setLists] = useState({})
  const [users, setUsers] = useState({})
  const [groups, setGroups] = useState({})
  const [loading, setLoading] = useState(true)

  const getLists = useCallback(async () => {
    try {
      setLoading(true)
      const {
        data: {
          entities: {
            lists: listsResponse = {},
            listUsers = {},
            listGroups = {},
          },
        },
      } = await ListService.getLists({ include_users: true })

      setLists(listsResponse)
      setUsers(listUsers)
      setGroups(listGroups)
    } catch (err) {
      Toast.display(intl.messages['lists.getErrorList'], 'error')
    } finally {
      setLoading(false)
    }
  }, [intl])

  useEffect(() => {
    getLists()
  }, [getLists])

  const handleListCheck = ({ listUsers, listGroups }) => {
    const usersToAdd = []
    if (!onlyGroups) {
      listUsers.forEach((user) => {
        if (!items.find(({ id }) => id === user.id)) {
          usersToAdd.push(user)
          handleAddUser(user)
        }
      })
    }

    const groupsToAdd = []
    if (!onlyUsers) {
      listGroups.forEach((group) => {
        groupsToAdd.push(group)
        handleAddGroup(group)
      })
    }

    onSelectList({ users: usersToAdd, groups: groupsToAdd })
  }

  const handleListUncheck = (removeIdList) => {
    const itemsToRemove = []

    items.forEach(({ listId, id }) => {
      const { belongsToAnotherList, newListId } = belongsToAnotherCheckedList(
        id,
        Object.values(lists).filter((item) => item.id !== removeIdList),
        groups,
        users
      )

      if (listId === removeIdList) {
        if (belongsToAnotherList) {
          handleEditItemList({
            id,
            listId: newListId,
            listName: lists[newListId].name,
          })
        } else {
          handleDelete(id)
          itemsToRemove.push(id)
        }
      }
    })

    onUncheckList(itemsToRemove)
  }

  const onChangeSelection = (id) => {
    const checked =
      lists[id].checked === CHECKBOX_INDETERMINATE || !lists[id].checked
    setLists((state) => ({
      ...state,
      [id]: { ...state[id], checked },
    }))

    if (checked) {
      handleListCheck(setGroupsUsersToListAssociated(users, groups, lists[id]))
    } else {
      handleListUncheck(id)
    }
  }

  useEffect(() => {
    Object.values(lists).forEach(({ id, checked, listUsers, listGroups }) => {
      const itemsIds = items.map(
        ({ entityId, id: itemId }) => entityId || itemId
      )

      let allGroupsAreSelected = true
      if (!onlyUsers) {
        allGroupsAreSelected = listGroups?.every((group) =>
          itemsIds.includes(groups[group]?.groupable?.id)
        )
      }

      let allUsersAreSelected = true
      if (!onlyGroups) {
        allUsersAreSelected = listUsers?.every((user) =>
          itemsIds.includes(users[user]?.user?.id)
        )
      }

      if (
        checked &&
        checked !== CHECKBOX_INDETERMINATE &&
        !(allUsersAreSelected && allGroupsAreSelected)
      ) {
        setLists((state) => ({
          ...state,
          [id]: { ...state[id], checked: CHECKBOX_INDETERMINATE },
        }))
      } else if (
        checked === CHECKBOX_INDETERMINATE &&
        allUsersAreSelected &&
        allGroupsAreSelected
      ) {
        setLists((state) => ({
          ...state,
          [id]: { ...state[id], checked: true },
        }))
      }
    })
  }, [items, lists, groups, users])

  const listArray = Object.values(lists)

  const renderListManagementLink = () => {
    if (loading) {
      return null
    }

    if (listArray.length) {
      return (
        <div className={styles.goToWrapper}>
          <div className={styles.row}>
            <FontAwesomeIcon
              className={styles.infoIcon}
              icon={['far', 'info-circle']}
            />

            <p>{intl.messages['lists.goToListsMessage']}</p>

            <Link
              to="/settings/group_management/lists"
              className={styles.marginLeft}
            >
              {!customLinkMessage
                ? intl.messages['lists.linkToListsText']
                : customLinkMessage}
            </Link>
          </div>
        </div>
      )
    }

    if (listArray.length === 0) {
      return null
    }
    return (
      <div className={styles.goToWrapper}>
        <div className={styles.row}>
          <FontAwesomeIcon
            className={styles.infoIcon}
            icon={['far', 'info-circle']}
          />
          <p>{intl.messages['lists.goToListsMessageEmpty']}</p>
          <Link
            to="/settings/group_management/lists"
            className={styles.marginTop}
          >
            {intl.messages['lists.manageRecipientsLists']}
          </Link>
        </div>
      </div>
    )
  }

  return (
    <>
      {showLabel && (
        <p className={styles.selectListsTag}>
          {customLabel || intl.messages['lists.selectLists']}
        </p>
      )}
      <div
        className={styles.listsBoxWrapper}
        style={{
          gridTemplateColumns: columns ? `${columns}fr` : undefined,
          rowGap,
        }}
      >
        {!loading && listArray.length === 0 && (
          <div className={styles.column}>
            <h2 className={styles.title}>
              {intl.messages['lists.noRecipientsLists']}
            </h2>
            <p className={styles.legend}>
              {intl.messages['lists.createLists']}
            </p>
            <Link to="/settings/group_management/lists" className={styles.link}>
              {intl.messages['lists.addRecipientList']}
            </Link>
          </div>
        )}

        {listArray.map(({ id, name, checked }) => (
          <Checkbox
            id={id}
            name={name}
            onChange={() => onChangeSelection(id)}
            label={name}
            key={id}
            checked={checked}
            icon={
              checked === CHECKBOX_INDETERMINATE && (
                <FontAwesomeIcon icon="circle" className={styles.circleIcon} />
              )
            }
          />
        ))}
      </div>

      {renderListManagementLink()}
    </>
  )
}

ListsSelectionBox.propTypes = {
  items: PropTypes.array,
  handleAddGroup: PropTypes.func,
  handleAddUser: PropTypes.func,
  handleDelete: PropTypes.func,
  handleEditItemList: PropTypes.func,
  onSelectList: PropTypes.func,
  onUncheckList: PropTypes.func,
  showLabel: PropTypes.bool,
  columns: PropTypes.number,
  rowGap: PropTypes.string,
  onlyGroups: PropTypes.bool,
  customLabel: PropTypes.string,
  onlyUsers: PropTypes.bool,
  customLinkMessage: PropTypes.string,
}

ListsSelectionBox.defaultProps = {
  items: [],
  onSelectList: () => {},
  onUncheckList: () => {},
  handleAddGroup: () => {},
  handleAddUser: () => {},
  handleDelete: () => {},
  handleEditItemList: () => {},
  showLabel: true,
  columns: null,
  rowGap: null,
  onlyGroups: false,
  customLabel: '',
  onlyUsers: false,
  customLinkMessage: '',
}

export default ListsSelectionBox
