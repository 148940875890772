import { useMemo } from 'react'
import { Countries } from 'utils/functions/countries'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'

export const useCountryDropdown = () => {
  const [search, debouncedSearch, handleSearch] = useDebouncedState<string>(
    '',
    300
  )

  const filteredCountries = useMemo(() => {
    if (!debouncedSearch) return Countries

    return Countries.filter((country) =>
      country.name.toLowerCase().includes(debouncedSearch.toLowerCase())
    )
  }, [debouncedSearch])

  return {
    filteredCountries,
    search,
    handleSearch,
  }
}
