import { useLocation } from 'react-router-dom'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import Separator from 'ui/Separator'
import LAYOUT from 'utils/constants/zerostatelayout'
import * as Styles from './EmptyState.styles'

const EmptyState: React.FC = () => {
  const location = useLocation()
  const isComingFromInvestments = location.pathname.includes('/investments')

  const zeroStateType = isComingFromInvestments
    ? ZeroStateType.SANDBOXED_METRICS_FROM_INVESTMENTS
    : ZeroStateType.SANDBOXED_METRICS

  return (
    <>
      <Styles.EmptyStateContainer>
        <ZeroState type={zeroStateType} layout={LAYOUT.HORIZONTAL} />
      </Styles.EmptyStateContainer>
      <Separator space="2.4rem" />
    </>
  )
}

export default EmptyState
