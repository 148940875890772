import Toggle from 'components/Toggle'
import { useIntl } from 'react-intl'

import OptionsDropdown from 'ui/OptionsDropdown'
import Collapsable from 'components/Collapsable'

import theme from 'utils/theme'
import { FiltersDataSet } from 'utils/hooks/useUpdatesProfilesFilter'
import {
  UpdatesTagsFilterOptions,
  UpdatesTypesFilterOptions,
} from 'utils/types/portfolios'
import {
  FilterTitle,
  titleStyles,
  Tag,
  tagsStyles,
  FilterButton,
} from './CompanyUpdates.style'

export enum UpdateFilterOption {
  TAGS = 'tags',
  TYPE = 'type',
  DRAFTS = 'drafts',
}
interface Props {
  isMobile?: boolean
  filters: FiltersDataSet
  filterDataSet: FiltersDataSet
  handleTypeFilters: (typeFilter: UpdatesTypesFilterOptions) => void
  handleTagFilters: (tagFilter: UpdatesTagsFilterOptions) => void
  handleToggleDraftOnly?: () => void
  hasUpdates: boolean
  optionsToShow?: UpdateFilterOption[]
}

const UpdatesFilterOptions = ({
  isMobile,
  filters,
  filterDataSet,
  handleTypeFilters,
  handleTagFilters,
  handleToggleDraftOnly,
  hasUpdates,
  optionsToShow = [
    UpdateFilterOption.DRAFTS,
    UpdateFilterOption.TAGS,
    UpdateFilterOption.TYPE,
  ],
}: Props) => {
  const intl = useIntl()

  const tagsFiltersApplied = !!filters.tags?.length
  const typesFiltersApplied = !!filters.types?.length
  const draftOnlyFilterApplied = !!filters.userWithDraftUpdates?.length
  const hasFilersApplied =
    tagsFiltersApplied || typesFiltersApplied || draftOnlyFilterApplied

  const showFilter =
    optionsToShow.length > 0 &&
    (hasUpdates || (!hasUpdates && hasFilersApplied))

  return showFilter ? (
    <OptionsDropdown
      title={
        !isMobile ? (
          <FilterButton active={hasFilersApplied} link icon={['far', 'filter']}>
            {!isMobile && intl.formatMessage({ id: 'companyList.filters' })}
          </FilterButton>
        ) : null
      }
      icon={isMobile ? ['far', 'filter'] : undefined}
      attachToDocument={false}
      dropdownContentMinWidth="22.5rem"
    >
      <FilterTitle>
        {intl.formatMessage({ id: 'updates.filters.filterBy' })}
      </FilterTitle>

      {optionsToShow.includes(UpdateFilterOption.DRAFTS) && (
        <Collapsable
          wrapperPadding="0 0 0 1.5rem"
          bodyPadding="0"
          id="updates.filters.draft.title"
          titleCustomStyles={titleStyles(draftOnlyFilterApplied)}
        >
          <OptionsDropdown.Item
            key="updates_draft"
            label={intl.formatMessage({
              id: 'updates.filters.draft.draftsOnly',
            })}
            closeOnSelectOption={false}
            component={
              <Toggle
                id="updates_draft"
                blue
                name="updates.filters.draft.draftOnly"
                disableAnimationOnMount
                color={theme.colors.primaryBlue}
                status={draftOnlyFilterApplied}
                onChangeStatus={handleToggleDraftOnly}
              />
            }
          />
        </Collapsable>
      )}

      {optionsToShow.includes(UpdateFilterOption.TAGS) && (
        <Collapsable
          wrapperPadding="0 0 0 1.5rem"
          bodyPadding="0"
          id="updates.filters.tags.title"
          bodyCustomStyles={tagsStyles()}
          titleCustomStyles={titleStyles(tagsFiltersApplied)}
        >
          {filterDataSet.tags?.map((tag) => {
            const isSelected = !!filters.tags?.find(
              (filter) => filter.id === tag.id
            )

            return (
              <Tag
                key={tag.id}
                status={isSelected}
                onClick={() => handleTagFilters(tag)}
              >
                {tag.label}
              </Tag>
            )
          })}
        </Collapsable>
      )}

      {optionsToShow.includes(UpdateFilterOption.TYPE) && (
        <Collapsable
          wrapperPadding="0 0 0 1.5rem"
          bodyPadding="0"
          id="updates.filters.updateTypes.title"
          titleCustomStyles={titleStyles(typesFiltersApplied)}
        >
          {filterDataSet.types?.map((type) => {
            const isSelected = !!filters.types?.find(
              (filter) => filter.id === type.id
            )

            return (
              <OptionsDropdown.Item
                key={type.id}
                label={type.label}
                closeOnSelectOption={false}
                component={
                  <Toggle
                    id={type.id}
                    status={isSelected}
                    blue
                    name={type.id}
                    disableAnimationOnMount
                    color={theme.colors.primaryBlue}
                  />
                }
                onSelectOption={() => handleTypeFilters(type)}
              />
            )
          })}
        </Collapsable>
      )}
    </OptionsDropdown>
  ) : null
}

export default UpdatesFilterOptions
