import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'

import { PortfolioTypes } from 'utils/constants/portfolio'
import PortfolioIcon from 'components/PortfolioIcon'

import styles from './PortfolioRow.module.scss'

const PortfolioRowHeader = ({
  type,
  name,
  countCompanies,
  showDetail,
  fromList,
  titleId,
}) => {
  const intl = useIntl()
  const history = useHistory()
  const intlPrefix = type === PortfolioTypes.FUND ? '.fundPortfolio' : ''
  const isCommingFromInvestmentsPage =
    history.location.pathname.includes('investments')

  const tableTitle = isCommingFromInvestmentsPage
    ? 'portfolioDetail.investmentSummary'
    : 'portfolioDetail.fundSummary'

  return (
    <div className={classNames({ [styles.headerContainer]: showDetail })}>
      <div className={styles.subHeader}>
        <h2 className={styles.portfolioIcon}>
          {fromList ? (
            <PortfolioIcon type={type} />
          ) : (
            <FormattedMessage id={titleId || tableTitle} />
          )}
        </h2>
        {fromList && (
          <div className={classNames(styles.portfolioName, 'fs-exclude')}>
            {name}
          </div>
        )}
      </div>

      {showDetail && (
        <div className={styles.companies}>
          {intl.formatMessage(
            { id: 'portfolios.countCompanies' },
            { countCompanies }
          )}
        </div>
      )}
    </div>
  )
}

PortfolioRowHeader.propTypes = {
  name: PropTypes.string,
  countCompanies: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(PortfolioTypes)).isRequired,
  showDetail: PropTypes.bool,
  fromList: PropTypes.bool,
  titleId: PropTypes.string,
}

PortfolioRowHeader.defaultProps = {
  name: '',
  showDetail: false,
  fromList: false,
  titleId: '',
}

export default PortfolioRowHeader
