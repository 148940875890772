import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Title = styled.div`
  > h1 {
    margin-top: 0;
  }
`

export const SubHeading = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${color('darkBlue')};
  margin-bottom: 1.6rem;
`

export const EmptyState = styled.div`
  color: ${color('darkGray')};
  font-size: 1.6rem;
  font-weight: 400;
  text-align: center;
  padding: 2.4rem;
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
`
