import { useIntl } from 'react-intl'
import { Update } from 'utils/types/update'
import theme from 'utils/theme'
import { UpdateType } from 'utils/types/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonWrapper, LinkedCopiedContainer } from '../UpdateActions.styles'
import useCopyLinkedAction from './useCopyLinkedAction'
import TooltipActionWrapper from '../Components/TooltipActionWrapper/TooltipActionWrapper'

interface Props {
  updateType: UpdateType
  update?: Update
  hasDraftUpdate: boolean
  isShowMode: boolean
}

const CopyLinkedAction = ({
  updateType,
  update,
  hasDraftUpdate,
  isShowMode,
}: Props) => {
  const intl = useIntl()

  const { copied, showCopyLinkButton, handleCopyLink } = useCopyLinkedAction({
    update,
    updateType,
    hasDraftUpdate,
    isShowMode,
  })

  if (!showCopyLinkButton) return null

  const actionContent = () => {
    if (copied) {
      return (
        <ButtonWrapper transparent onClick={handleCopyLink}>
          <FontAwesomeIcon
            color={theme.colors.darkBlue}
            icon={['fal', 'link-simple']}
          />
        </ButtonWrapper>
      )
    }

    return (
      <TooltipActionWrapper
        id="copy-link-update-action"
        text={intl.formatMessage({ id: 'updateActions.copyUpdateLink' })}
      >
        <ButtonWrapper transparent onClick={handleCopyLink}>
          <FontAwesomeIcon
            color={theme.colors.darkBlue}
            icon={['fal', 'link-simple']}
          />
        </ButtonWrapper>
      </TooltipActionWrapper>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <LinkedCopiedContainer copied={copied}>
        {intl.formatMessage({ id: 'shareUpdate.linkCopied' })}
      </LinkedCopiedContainer>

      {actionContent()}
    </div>
  )
}

export default CopyLinkedAction
