import { useCallback, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { FormattedMessage } from 'react-intl'
import { isActingAsClient } from 'selectors/auth'

import { displayAttachments } from 'utils/functions/renderers/renderUpdatesHelper'
import { SuggestedUpdate } from 'utils/types/update'
import { SuggestedUpdateForm as SuggestedUpdateFormType } from 'utils/types/suggestedUpdate'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { EmailContent, MixedContent } from 'utils/types/files'
import PreviewContents from 'components/PreviewContents'

import CardLabel from '../CardLabel'
import SuggestedUpdateForm from './components/SuggestedUpdateForm'
import DiscardFeedCard from './components/DiscardFeedCard'
import SuggestedUpdateIcon from './components/SuggestedUpdateIcon'
import {
  Card,
  DescriptionContainer,
  Title,
  Tooltip,
  TitleTooltipStyles,
  ButtonsContainer,
  Button,
  ButtonWrapper,
  AttachmentsContainer,
} from './SuggestedUpdateFeedCard.styles'

import { useSuggestedUpdateFeedCard } from './useSuggestedUpdateFeedCard'
import { TransactionFundType } from '../../../../utils/constants/transactionTypes'

const mapSuggestedUpdateToEmailContent = (
  suggestedUpdate: SuggestedUpdate
): EmailContent => {
  return {
    ...suggestedUpdate,
    s3Contents: suggestedUpdate.contents,
    discard: false,
    linkedEmailAccount: {} as any,
    groupManaged: false,
  }
}

type Props = {
  suggestedUpdate: SuggestedUpdate
}

const SuggestedUpdateFeedCard: React.FC<Props> = ({ suggestedUpdate }) => {
  const { data: updateData } = suggestedUpdate
  const suggestedUpdateAsEmailContent = useMemo(
    () => mapSuggestedUpdateToEmailContent(suggestedUpdate),
    [suggestedUpdate]
  )
  const isClient = useAppSelector(isActingAsClient)
  const [selectedContent, setSelectedContent] = useState<MixedContent>()

  const onShowAttachmentPreview = useCallback((content: MixedContent) => {
    setSelectedContent(content)
  }, [])

  const onClosePreview = useCallback(() => {
    setSelectedContent(undefined)
  }, [])

  const {
    isDiscardMode,
    onMouseProps,
    suggestedHolding,
    validateSuggestedUpdate,
    enterDiscardMode,
    cancelDiscardMode,
    getCreateTooltipText,
    onSubmit,
  } = useSuggestedUpdateFeedCard(suggestedUpdate)

  return (
    <>
      <SuggestedUpdateIcon suggestedUpdate={suggestedUpdate} />
      <Card
        onMouseEnter={onMouseProps.onMouseEnter}
        onMouseLeave={onMouseProps.onMouseLeave}
        hoverState={onMouseProps.hoverState}
        isDiscardMode={isDiscardMode}
      >
        {!isDiscardMode && (
          <Formik
            initialValues={{
              ...updateData,
              suggestedUpdateId: suggestedUpdate.id,
              updateFrom: suggestedHolding,
              contents: suggestedUpdate.contents,
              transactionFundType: isClient
                ? TransactionFundType.HOLDING
                : undefined,
              investor: undefined,
            }}
            validate={validateSuggestedUpdate}
            validateOnMount
            validateOnChange
            enableReinitialize
            onSubmit={onSubmit}
          >
            {(formik) => (
              <>
                <CardLabel labelType="suggested" />
                <Tooltip
                  class="suggested-update-tooltip"
                  place="top"
                  text={updateData.title}
                >
                  <Title>{updateData.title}</Title>
                </Tooltip>
                <DescriptionContainer>
                  <AttachmentsContainer>
                    {displayAttachments(
                      [
                        suggestedUpdateAsEmailContent,
                        ...suggestedUpdate.contents,
                      ],
                      'small',
                      onShowAttachmentPreview,
                      true
                    )}
                  </AttachmentsContainer>
                </DescriptionContainer>
                <SuggestedUpdateForm suggestedUpdate={suggestedUpdate} />
                <ButtonsContainer>
                  <Tooltip
                    class="suggested-update-tooltip"
                    place="bottom"
                    text={getCreateTooltipText(
                      formik.values as SuggestedUpdateFormType
                    )}
                  >
                    <Button
                      data-testid="create-suggested-update-button"
                      primary
                      isLoading={formik.isSubmitting}
                      disabled={!formik.isValid || formik.isSubmitting}
                      onClick={formik.submitForm}
                    >
                      <FormattedMessage id="general.create" />
                    </Button>
                  </Tooltip>
                  <ButtonWrapper>
                    <Button onClick={enterDiscardMode} link>
                      <FormattedMessage id="general.discard" />
                    </Button>
                  </ButtonWrapper>
                </ButtonsContainer>
              </>
            )}
          </Formik>
        )}
        {isDiscardMode && (
          <DiscardFeedCard
            suggestedUpdate={suggestedUpdate}
            onCancel={cancelDiscardMode}
          />
        )}
      </Card>
      <TitleTooltipStyles />
      <PreviewContents
        contents={[suggestedUpdateAsEmailContent, ...suggestedUpdate.contents]}
        selectedContent={selectedContent}
        isOpen={!!selectedContent}
        onClosePreview={onClosePreview}
      />
    </>
  )
}

export default SuggestedUpdateFeedCard
