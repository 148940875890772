import { useCallback, useState } from 'react'
import { useField, useFormikContext } from 'components/Form/hooks'
import { AddHoldingForm, LogoImage, SuggestionErrors } from '../types'
import { SUGGESTION_ERROR } from '../errors'

export const useAddFundPortfoliosForm = () => {
  const {
    values,
    status: formikStatus,
    setStatus,
    setFieldValue,
    handleBlur,
  } = useFormikContext<AddHoldingForm>()
  const status = formikStatus as SuggestionErrors
  const [file, setFile] = useState<Blob>()
  const [includeFundManagerField] = useField<boolean>(
    'funds.includeFundManager'
  )
  const [fundManagerNameField] = useField<string>('funds.fundManager.name')
  const [fundManagerWebsiteField, fundManagerWebsiteMeta] = useField<string>(
    'funds.fundManager.website'
  )
  const [fundManagerPointOfContactField] = useField<string>(
    'funds.fundManager.pointOfContact'
  )
  const [fundManagerLogoField] = useField<LogoImage>('funds.fundManager.logo')
  const shouldShowWebsiteErrorMessage =
    fundManagerWebsiteMeta.error !== SUGGESTION_ERROR

  const onSelectImage = useCallback((fileParam: Blob) => {
    setFile(fileParam)
  }, [])

  const onSaveImage = useCallback(() => {
    let url

    if (file) {
      url = window.URL.createObjectURL(file)
    }

    setFieldValue('funds.fundManager.logo', { file, url })
  }, [file, setFieldValue])

  const onDeleteImage = useCallback(() => {
    setFile(undefined)
    setFieldValue('funds.fundManager.logo', undefined)
  }, [setFieldValue])

  const onChangeWebsite = useCallback(
    (event) => {
      fundManagerWebsiteField.onChange(event)

      if (status?.fundManagerErrors?.website) {
        setStatus({
          ...status,
          fundManagerErrors: {},
        })
      }
    },
    [fundManagerWebsiteField, status, setStatus]
  )

  const onAddFund = useCallback(() => {
    setFieldValue('funds.funds', [...(values.funds?.funds || []), ''])
  }, [setFieldValue, values.funds?.funds])

  return {
    values,
    status,
    handleBlur,
    includeFundManagerField,
    fundManagerNameField,
    fundManagerWebsiteField,
    fundManagerPointOfContactField,
    fundManagerLogoField,
    shouldShowWebsiteErrorMessage,
    onSelectImage,
    onSaveImage,
    onDeleteImage,
    onChangeWebsite,
    onAddFund,
  }
}
