export enum Integrations {
  XERO = 'xero',
  QUICKBOOKS = 'quickbooks',
}

export enum IntegrationStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}
