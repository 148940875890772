import styled, { css } from 'styled-components'
import { maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { ReactComponent as ZeroState } from './CardSummary/Chart/CardSummaryChartZeroState.svg'

const defaultGridAndChartWrapperStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  /* margin-top: 1.3rem; */
  width: 100%;
`

const defaultChartContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:empty {
    display: none;
  }
`

const filesSummaryGridAndChartStyles = css`
  @media ${size.xxl} {
    flex-direction: row;
  }
`

const investorGridAndChartStyles = css`
  gap: 2rem;

  @media ${size.sm} {
    align-items: flex-start;
    flex-direction: row;

    // If we center this to the middle the design does not match
    // having this margin-top makes the div where the cells are display
    // ALMOST in the middle of the chart (matching the design)
    & > div:first-child {
      margin-top: 2rem;
    }
  }

  @media ${size.xl} {
    & > div:first-child {
      margin-top: 0rem;
    }
  }
`

export const GridAndChartWrapper = styled.div<{
  isFilesSummary?: boolean
  investorSummaryGridAndChartStyles?: boolean
}>`
  ${defaultGridAndChartWrapperStyles}

  ${({ isFilesSummary, investorSummaryGridAndChartStyles }) => {
    if (isFilesSummary) {
      return filesSummaryGridAndChartStyles
    }

    if (investorSummaryGridAndChartStyles) {
      return investorGridAndChartStyles
    }

    return css`
      @media ${size.xl} {
        flex-direction: row;
      }
    `
  }}
`

const breakpointWhenChartShouldBeOnRightSide = (
  isFilesSummary?: boolean,
  isInvestorChart?: boolean
) => {
  // following logic on filesSummaryGridAndChartStyles (parent wrapper) since files summary chart
  // is on the right side on xxl (1536) breakpoint due to a more extensive files variety
  if (isFilesSummary) {
    return css`
      @media ${size.xxl} {
        width: 40%;
      }
    `
  }

  // same criteria but with the investor chart. In this case Figma shows that the chart should be on the right side
  // at this breakpoint since the grid has less amount of cells
  if (isInvestorChart) {
    return css`
      @media ${size.sm} {
        width: 40%;
      }
    `
  }

  return css`
    @media ${size.xl} {
      width: 40%;
    }
  `
}

const pieChartStyles = (isInvestorPieChart?: boolean) => {
  // The same way the grid and chart wrapper has a different style for the investor chart
  // the same is applied to the pie chart since the investor pie chart has a different styles on different breakpoints
  // due to less grid cells
  if (isInvestorPieChart) {
    return css`
      margin-bottom: 2rem;

      @media ${size.sm} {
        margin-bottom: 0;
      }

      @media ${size.xl} {
        flex-direction: row;
        gap: 2.4rem;
      }
    `
  }

  return css`
    margin-bottom: 2rem;

    @media ${size.xsm} {
      flex-direction: row;
      gap: 2.4rem;
    }

    @media ${size.xl} {
      margin-bottom: 0;
    }
  `
}

export const ChartContainer = styled.div<{
  isFilesSummary?: boolean
  isPieChart?: boolean
  isInvestorPieChart?: boolean
}>`
  ${defaultChartContainerStyles}

  ${({ isFilesSummary, isInvestorPieChart }) =>
    breakpointWhenChartShouldBeOnRightSide(isFilesSummary, isInvestorPieChart)}


  ${({ isPieChart, isInvestorPieChart }) =>
    isPieChart
      ? pieChartStyles(isInvestorPieChart)
      : css`
          @media ${size.xl} {
            justify-content: flex-start;
          }
        `}
`

export const DealChartContainer = styled(ChartContainer)`
  .recharts-wrapper {
    padding-top: 0.5rem;
  }

  @media ${maxSize.lg} {
    .recharts-wrapper {
      padding-top: 0;
    }
  }
`

export const RowContainer = styled.div`
  transition: transform 0.2s;

  &:focus {
    outline: none;
    border-radius: 0.8rem;
    border: 0.1rem solid ${color('primaryBlue')};
  }
`
export const StyledZeroState = styled(ZeroState)`
  width: 100%;
`

export const MoreText = styled.div`
  margin-top: 0.4rem;
  font-size: 1.2rem;
`

export const LoaderContainer = styled.div`
  height: 11.8rem;
`
