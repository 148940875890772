import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  margin-bottom: 0.8rem;

  div:last-child {
    flex: 1;
  }
`

export const IconContainer = styled.div<{ hasValue: boolean }>`
  ${({ hasValue }) =>
    !hasValue &&
    css`
      div {
        background-color: ${color('veryLightBlue')};
      }
    `}
`

export const SuggestionErrorContainer = styled.div`
  padding-bottom: 0.8rem;
  padding-left: 5rem;
`
