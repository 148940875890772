export default {
  title: 'Inbounds',
  description:
    'Start receiving suggested updates from your favorite channels by creating inbounds',
  defaultInboundDescription:
    'Start receiving suggested updates by redirecting emails from your personal mailbox to the following email address:',
  create: 'Create New',
  zeroState: 'No inbounds yet!',
  newInbound: 'New Inbound',
  accountType: 'ACCOUNT TYPE',
  emailAddress: 'E-MAIL ADDRESS',
  connect: 'CONNECT',
  typeOfAccount: 'Which type of account would you like to connect?',
  accounts: {
    gmail: {
      title: 'Gmail Account',
      description: 'Standard e-mail account.',
    },
    googleGroup: {
      title: 'Google Group',
      description: 'Shared with a group.',
    },
  },
  emailStepDescription: 'Enter the Google Group you want to add:',
  emailStepInfo: 'To add a Group you must be a Google Admin with Group access.',
  addEmail: 'Add Email',
  allowMyGroupToReceiveSuggestedUpdates:
    'Allow the group to receive suggested updates from this inbound',
  suggestedUpdatesOnlyToYou:
    'This inbound is customized to send suggested updates to you only.',
  suggestedUpdatesOnlyToOwner:
    'This inbound is customized to send suggested updates to {owner} only.',
  connectStepDescription:
    'This is a unique address that no other user can use. Please don’t use it as an e-mail.',
  connectStepCopyDescription:
    'Copy the address below and add it to “Members” in your Google Group:',
  connectStepInfo:
    'Do you need help adding a member in Google Group? <link>Click here</link>',
  connectStepAfterAdding:
    'After you have added your email to the Google Group, click "Check Status" to complete the setup.',
  checkStatusFailed:
    'Check status connection failed. Check that you have included the email address above as a member in your Google Group.',
  doThisLater: 'Do this later',
  checkStatus: 'Check Status',
  linkCopied: 'Link copied!',
  delete: {
    title: 'Delete Inbound',
    areYouSure: 'Are you sure you want to delete inbound “{email}”?',
    description:
      'By doing this action, you will stop receiving suggested updates from this inbound. Be aware that you cannot undo this action.',
    error: 'An error ocurred deleting the inbound, try again later.',
    success: 'You have deleted “{email}” inbound.',
  },
  createInbound: {
    error: 'An error ocurred creating the inbound, try again later.',
    alreadyTakenError:
      'This Google Group is already being used in another inbound.',
    success: 'You have added “{email}” inbound successfully',
  },
  loading: 'Loading inbounds...',
  settings: {
    title: 'Inbound Settings',
    googleGroup: 'Connected Google Group account: ',
    lastCheckUpPerformed: 'Last check-up performed: ',
    lastSuccessfulConnection: 'Successful last connection: ',
    status: 'Status: ',
    firstConnection: 'First connection: ',
    createdBy: 'Created by: ',
  },
  editInbound: {
    error: 'An error ocurred updating the settings, try again later.',
  },
  status: {
    active: 'Active',
    notConnected: 'Not Connected',
    pending: 'Awaiting Connection',
    pendingTooltip:
      'Awaiting for Google Group to connect with Clockwork Universe.',
    testingConnection: 'Testing connection...',
  },
  inboundTooltip:
    "This inbound was added by a member of your group, but you don't have access to its settings. Only the group owner, admins, or the user who added the inbound can edit.",
}
