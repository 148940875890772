import { useCallback } from 'react'
import { useChannelStateContext } from 'stream-chat-react'

import Toast from 'components/Toast'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser } from 'selectors/auth'
import {
  StreamChatType,
  TopicChannel,
  LeftChannelSystemMessage,
  SystemMessageType,
} from 'containers/Chat/types'
import { useArchiveChannels } from 'containers/Chat/hooks/useArchiveChannels'
import { usePinChannels } from 'containers/Chat/hooks/usePinChannels'

export const useLeaveChannelModal = (onHide: () => void) => {
  const user = useAppSelector(getUser)
  const { channel: activeChannel } =
    useChannelStateContext<StreamChatType<TopicChannel>>()
  const { unpinChannel } = usePinChannels()
  const { unarchiveChannel } = useArchiveChannels()
  const getMessage = useCallback(() => {
    const message: LeftChannelSystemMessage = {
      type: SystemMessageType.LEFT_CHAT,
      member: {
        id: user?.id || '',
        name: user?.name || '',
      },
    }

    return JSON.stringify(message)
  }, [user?.id, user?.name])

  const onLeaveChannel = useCallback(async () => {
    try {
      await activeChannel.removeMembers([user?.id], {
        text: getMessage(),
      })
      unpinChannel(activeChannel.id!)
      unarchiveChannel(activeChannel.id!)
      onHide()
    } catch {
      Toast.displayIntl('chat.leaveChatError', 'error')
    }
  }, [
    activeChannel,
    getMessage,
    onHide,
    user?.id,
    unpinChannel,
    unarchiveChannel,
  ])

  return {
    onLeaveChannel,
  }
}
