import React, { useState } from 'react'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'

import { ReactComponent as EmptyPieChart } from 'assets/images/pie-chart-empty.svg'
import SkeletonPieChart from 'components/Skeletons/components/SkeletonPieChart'
import {
  getChartColor,
  InvestPortfolioChartDataWithPercentages,
  isPieChartDataShared,
} from 'utils/functions/portfolios'
import { SharePortfolioSettings } from '../../utils/types/funds'
import { ChartColors, PortfolioCompany } from '../../utils/types/portfolios'
import { PieChartHoverContainer } from './InvestPortfolioPieChart.styles'
import PortfolioChartTooltip from './PortfolioChartTooltip'

interface InvestPortfolioPieChartProps {
  chartData: InvestPortfolioChartDataWithPercentages[]
  colorsForCompanies: ChartColors
  sharePortfolioSetting?: SharePortfolioSettings
  showChartLogoImage: boolean
  isLoading: boolean
}

const InvestPortfolioPieChart: React.FC<InvestPortfolioPieChartProps> = ({
  chartData,
  colorsForCompanies,
  sharePortfolioSetting,
  showChartLogoImage,
  isLoading,
}) => {
  const [hover, setHover] = useState(false)

  const { hasTotalValueShared, hasTotalAmountInvestedShared } =
    isPieChartDataShared(chartData, sharePortfolioSetting)

  if (!hasTotalValueShared && !hasTotalAmountInvestedShared) return null

  const hasChartData = chartData.some(
    (data) => (data.totalValue ?? 0) > 0 || (data.totalAmountInvested ?? 0) > 0
  )

  if (!hasChartData) {
    return <EmptyPieChart />
  }

  const outerPieRadius = {
    innerRadius: 51,
    outerRadius: 81,
  }
  const innerPieRadius = {
    innerRadius: 20,
    outerRadius: 50,
  }

  if (isLoading)
    return (
      <SkeletonPieChart
        outerPieRadius={outerPieRadius}
        innerPieRadius={innerPieRadius}
        width={170}
        height={170}
      />
    )

  return (
    <PieChartHoverContainer hover={hover}>
      <PieChart width={170} height={170}>
        {hasTotalValueShared && (
          <Pie
            id="totalValue"
            data={chartData}
            dataKey="totalValue"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={outerPieRadius.innerRadius}
            outerRadius={outerPieRadius.outerRadius}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {chartData.map((entry, index) => {
              const colorId =
                (entry as PortfolioCompany).holding?.id ||
                entry.holdingId ||
                entry.id

              return (
                <Cell
                  key={entry.id}
                  fill={colorsForCompanies[colorId] || getChartColor(index)}
                />
              )
            })}
          </Pie>
        )}

        {hasTotalAmountInvestedShared && (
          <Pie
            id="totalAmountInvested"
            data={chartData}
            dataKey="totalAmountInvested"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={
              hasTotalValueShared
                ? innerPieRadius.innerRadius
                : outerPieRadius.innerRadius
            }
            outerRadius={
              hasTotalValueShared
                ? innerPieRadius.outerRadius
                : outerPieRadius.outerRadius
            }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {chartData.map((entry, index) => {
              const colorId =
                (entry as PortfolioCompany).holding?.id || entry.id
              return (
                <Cell
                  key={entry.id}
                  fill={colorsForCompanies[colorId] || getChartColor(index)}
                />
              )
            })}
          </Pie>
        )}

        <Tooltip
          allowEscapeViewBox={{ x: true, y: true }}
          offset={-110}
          content={
            <PortfolioChartTooltip
              showChartLogoImage={showChartLogoImage}
              colorsForCompanies={colorsForCompanies}
            />
          }
        />
      </PieChart>
    </PieChartHoverContainer>
  )
}

export default InvestPortfolioPieChart
