import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const PlanContainer = styled.div<{
  isHighlighted?: boolean
}>`
  max-width: 28rem;
  min-width: 28rem;
  height: 37rem;
  border-radius: 1.6rem;
  box-shadow: ${color('veryLightGray')} inset 0 0 0 0.1rem;
  font-size: 1.4rem;
  padding: 3.2rem 2.4rem 2.8rem 2.4rem;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ isHighlighted }) =>
    isHighlighted
      ? css`
          box-shadow: ${color('primaryBlue')} inset 0 0 0 0.2rem;
        `
      : css`
          &:hover {
            box-shadow: ${color('veryLightBlue')} inset 0 0 0 0.2rem;
          }
        `}
`

export const PlanTag = styled.div`
  position: absolute;
  top: 0;
  left: 10rem;
  background: ${color('primaryBlue')};
  border-radius: 0 0 1rem 1rem;
  text-transform: uppercase;
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  padding: 0.2rem 0.6rem;
  width: 11rem;
  text-align: center;
  left: calc(50% - 5.5rem);
  letter-spacing: 0.036rem;
`

export const Price = styled.div<{ isContactAmount?: boolean }>`
  font-size: 2.2rem;
  display: grid;
  grid-template-columns: auto auto 8.9rem;
  height: 3.7rem;
  align-items: center;

  ${({ isContactAmount }) =>
    isContactAmount &&
    css`
      justify-items: center;
      grid-template-columns: auto;
      font-size: 1.6rem;
    `}
`

export const PriceBold = styled.div`
  font-weight: 700;
`

export const PriceSeparator = styled.div`
  width: 0.1rem;
  height: 100%;
  background: ${color('veryLightGray')};
  margin: 0 0.8rem;
`

export const OldPrice = styled.div`
  color: ${color('lightGray')};
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
`

export const PriceSubtext = styled.div`
  font-size: 1.4rem;
  color: ${color('gray')};
`

export const Name = styled.div`
  font-size: 2.4rem;
  margin-bottom: 0.4rem;
`

export const ButtonContainer = styled.div`
  width: 21.6rem;
  margin: 1.2rem auto 0;

  button {
    width: 100%;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.4rem;
`

export const Description = styled.div`
  text-align: center;
  color: ${color('lightGray')};
  font-style: italic;
  line-height: 1.9rem;
  margin-bottom: 1.4rem;
  width: 21.2rem;
`

export const FeatureContainer = styled.div`
  flex: 1;
`

export const Feature = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 1.2rem 0.8rem;
  color: ${color('gray')};
`

export const FeatureTitle = styled.div`
  width: 18rem;
`

export const FeatureCheckContainer = styled.div`
  min-width: 2.4rem;
  min-height: 2.4rem;
  margin-right: 1.2rem;
`

export const SelectedTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WhiteCheckContainer = styled.div`
  min-width: 1.2rem;
  min-height: 1rem;
  margin-right: 0.8rem;

  svg {
    margin: 0;
    margin-bottom: 0.2rem;
  }
`
