import { getIntlConfig } from 'languageProvider'
import type { IntlShape } from 'react-intl'
import { createIntl } from 'react-intl'
import { Nullable } from 'utils/types/common'

import { color } from 'utils/functions/colors'
import LogIcon from '../../LogIcon'
import {
  EventType,
  FieldValue,
  ILogFactory,
  Log,
  LogTextBuilder,
} from '../types'

export type ChangeSetFieldsType = { [fieldName: string]: FieldValue }

export abstract class LogsBuilder<T extends ChangeSetFieldsType>
  implements ILogFactory
{
  protected static intl: IntlShape = createIntl(getIntlConfig())

  public log: Log<T>

  constructor(log: Log<T>) {
    this.log = log
  }

  /**
   * Returns the log avatar url
   */
  public getLogoUrl(): string {
    return this.log.user?.image?.smallLogo?.url ?? this.log.groupLogo ?? ''
  }

  /**
   * Returns the log owner name
   */
  public getOwner(): string {
    return this.log.user?.name ?? this.log.groupName!
  }

  /**
   * Returns the log timestamp
   */
  public getDate(): string {
    return this.log.createdAt
  }

  public static getCreateIcon(): React.ReactNode {
    return <LogIcon color={color('green')} icon={['far', 'plus']} />
  }

  public static getUpdateIcon(): React.ReactNode {
    return <LogIcon color={color('brightBlue')} icon={['far', 'pen']} />
  }

  public static getDestroyIcon(): React.ReactNode {
    return <LogIcon color={color('red')} icon={['far', 'trash-alt']} />
  }

  /**
   * Returns the icon operation type icon
   */
  public getOperationIcon(): React.ReactNode {
    const valueAdded =
      this.log.change[0] === null && this.log.change[1] !== null
    const valueRemoved =
      this.log.change[0] !== null && this.log.change[1] === null

    switch (this.log.event) {
      case EventType.CREATE:
        return LogsBuilder.getCreateIcon()
      case EventType.UPDATE:
        if (valueAdded) return LogsBuilder.getCreateIcon()
        if (valueRemoved) return LogsBuilder.getDestroyIcon()
        return LogsBuilder.getUpdateIcon()
      case EventType.DESTROY:
        return LogsBuilder.getDestroyIcon()
      default:
        return null
    }
  }

  /**
   * Returns the log text content
   */
  public getText(): React.ReactNode {
    const textBuilder = this.getTextBuilderForField()
    if (!textBuilder) return null
    return textBuilder.getLogText(
      this.log.event,
      this.log.fieldName as string,
      this.log.change,
      this.log.text,
      !this.log.user,
      this.log.customValue
    )
  }

  /**
   * Map the field names with the proper log text builder instance
   *
   * @returns An instance of LogTextBuilder
   *
   */
  protected abstract getTextBuilderForField(): Nullable<LogTextBuilder>
}
