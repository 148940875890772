import Tabs from 'components/Tabs'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const TitleStyles = css`
  color: ${color('darkBlue')};
`

export const EditInvestorTabs = styled(Tabs)`
  .tabItem {
    text-align: center;
    width: 50%;
  }
`

export const AddMemberHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
  button {
    color: ${color('darkBlue')};
  }
`

export const InvestorGeneralInformation = styled.div`
  padding-top: 0.8rem;
`
