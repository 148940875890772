import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledTextArea = styled.textarea<{
  readOnlyMode?: boolean
  disabled?: boolean
  minHeight?: string
}>`
  height: 3.8rem;
  width: 100%;
  border: 0.1rem solid transparent;
  border-radius: 0.8rem;
  padding: 1rem 1.6rem;
  font-size: 1.4rem;
  outline: none;
  background: ${color('lightGray', 0.1)};
  color: ${color('darkGray')};
  transition: 0.2s background-color, 0.1s border;
  text-align: left;
  min-height: ${(props) => props.minHeight || '8rem'};

  &::placeholder {
    font-size: 1.4rem;
    color: ${color('darkGray', 0.5)};
  }

  &:hover {
    border: 0.1rem solid ${color('veryLightBlue')};
    border-radius: 0.8rem;
  }

  &:focus {
    background-color: ${color('white')};
    border: 0.1rem solid ${color('primaryBlue')};
  }

  ${(props) =>
    props.disabled &&
    css`
      padding: 0 1rem;
      cursor: not-allowed;
    `}

  ${(props) =>
    props.readOnlyMode &&
    css`
      background: transparent;
      padding-left: 0.8rem;
      padding-top: 0;
      margin-top: 0;
      cursor: not-allowed;
    `}
`

export const Value = styled.p`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  margin-left: 1rem;
`

export const Wrapper = styled.div`
  position: relative;
`
