import Avatar from 'components/Avatar'
import { useHistory } from 'react-router-dom'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { getInitials } from 'utils/functions/user'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { InvestmentVehicle } from 'utils/types/investors'
import {
  BasicInformationWrapper,
  CompanyName,
  ImageWrapper,
} from '../CompanyHeader/HeaderBasicInformation.styles'
import { InvestorGroupName } from './HeaderBasicInformation.styles'

interface Props {
  investmentVehicle: InvestmentVehicle
}

const HeaderBasicInformation = ({ investmentVehicle }: Props) => {
  const history = useHistory()
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const investorGroupProfileUrl = isInvestorGroup
    ? `/profile`
    : `/investor-management/investors/${investmentVehicle.investorGroup.id}/profile`

  return (
    <BasicInformationWrapper>
      <ImageWrapper>
        <Avatar
          avatarStyle="avatarCircleLarge"
          image={investmentVehicle?.logo?.url}
          initials={getInitials(investmentVehicle.name)}
          grayBorder
        />
      </ImageWrapper>
      <CompanyName>{investmentVehicle.name}</CompanyName>
      <InvestorGroupName onClick={() => history.push(investorGroupProfileUrl)}>
        {investmentVehicle.investorGroup.name}
      </InvestorGroupName>
    </BasicInformationWrapper>
  )
}

export default HeaderBasicInformation
