import * as yup from 'yup'
import type { IntlShape } from 'react-intl'

export const getEmailSchema = (intl: IntlShape) =>
  yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(intl.formatMessage({ id: 'login.invalidEmail' }))
      .required(intl.formatMessage({ id: 'login.requiredEmail' })),
  })

export const getUserInformationSchema = (intl: IntlShape) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
    lastName: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' })),
  })
