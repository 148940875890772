import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TopicEntity } from 'utils/types/chatTopicEntity'
import ChatWrapper from 'containers/Chat/components/ChatWrapper'
import { Update } from 'utils/types/update'
import { useHasUnreadMessages } from '../../UpdateChatWidget/useHasUnreadMessages'
import { LabelContainer, NewMessageIndicator } from './ChatLabel.styles'

interface ChatLabelProps {
  topic?: TopicEntity
  update?: Update
}

const ChatLabel: React.FC<ChatLabelProps> = ({ topic, update }) => {
  const hasUnreadMessages = useHasUnreadMessages(topic, update?.id)
  return (
    <LabelContainer>
      <FormattedMessage id="chat.chat" />
      {hasUnreadMessages && <NewMessageIndicator />}
    </LabelContainer>
  )
}

export default ChatWrapper(ChatLabel)
