import { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'
import InvestmentVehicleLink from './InvestmentVehicleLink'
import InvestorLink from './InvestorLink'

interface Props {
  url: URL
  isCreatingNewUpdate: boolean
  hasUpdate: boolean
  vehicleActiveTabs: string[]
}

const InvestorAndVehicleNavigation = ({
  url,
  isCreatingNewUpdate,
  hasUpdate,
  vehicleActiveTabs,
}: Props) => {
  const route = useLocation().pathname
  const [hasLoadedInvestmentVehicle, setHasLoadedInvestmentVehicle] =
    useState(false)

  const [investorId, setInvestorId] = useState<Nullable<string>>(null)

  const handleIsLoadedInvestmentVehicle = useCallback(() => {
    setHasLoadedInvestmentVehicle(true)
  }, [])

  const handleInvestorId = useCallback((id: string) => {
    setInvestorId(id)
  }, [])

  const showRightArrow =
    !!hasUpdate ||
    isCreatingNewUpdate ||
    (!!hasLoadedInvestmentVehicle &&
      route !== '/profile' &&
      route !== '/profile/edit')

  return (
    <>
      <InvestorLink
        url={url}
        showRightArrow={showRightArrow}
        handleInvestorId={handleInvestorId}
      />

      <InvestmentVehicleLink
        url={url}
        investorId={investorId}
        showRightArrow={!!hasUpdate || isCreatingNewUpdate}
        handleIsLoadedInvestmentVehicle={handleIsLoadedInvestmentVehicle}
        activeTabs={vehicleActiveTabs}
      />
    </>
  )
}

export default InvestorAndVehicleNavigation
