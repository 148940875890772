export const DefaultEmail = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon" clipPath="url(#clip0_7690_533786)">
      <path
        id="Vector"
        d="M11.375 2.46558H1.625C1.00367 2.46558 0.5 2.96925 0.5 3.59058V10.3406C0.5 10.9619 1.00367 11.4656 1.625 11.4656H11.375C11.9963 11.4656 12.5 10.9619 12.5 10.3406V3.59058C12.5 2.96925 11.9963 2.46558 11.375 2.46558ZM11.375 3.59058V4.54694C10.8495 4.97489 10.0117 5.64033 8.22062 7.0428C7.82591 7.35328 7.04403 8.09918 6.5 8.09048C5.95606 8.09927 5.17393 7.35316 4.77938 7.0428C2.98859 5.64054 2.15059 4.97496 1.625 4.54694V3.59058H11.375ZM1.625 10.3406V5.99053C2.16205 6.41829 2.92365 7.01855 4.08448 7.92755C4.59676 8.33079 5.49388 9.22097 6.5 9.21555C7.50118 9.22097 8.38693 8.3437 8.91531 7.92773C10.0761 7.01876 10.8379 6.41833 11.375 5.99055V10.3406H1.625Z"
        fill="#313748"
      />
    </g>
    <defs>
      <clipPath id="clip0_7690_533786">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(0.5 0.965576)"
        />
      </clipPath>
    </defs>
  </svg>
)
