import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Helmet } from 'components/Helmet'

import { AlmostThere } from 'containers/Signup/GetStarted'
import * as FullStory from 'utils/integrations/fullStory'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import { setIsSigningUp } from 'features/authSlice'
import ConfirmEmail from 'containers/Signup/ConfirmEmail'
import SignInAuth0 from 'containers/Auth/SignInAuth0'
import {
  SIGN_UP_ALMOST_DONE,
  SIGN_UP,
  SIGN_UP_CONFIRM,
} from './constant/signUp'

const SignupRoutes = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  useEffect(() => {
    FullStory.initialize()
  }, [])

  useEffect(() => {
    dispatch(setIsSigningUp(true))
  }, [dispatch])

  const routes = [
    {
      path: SIGN_UP,
      key: 'sign-up',
      component: <SignInAuth0 isSignup />,
    },
    {
      path: SIGN_UP_ALMOST_DONE,
      key: 'almost-done',
      component: <AlmostThere />,
    },
    {
      path: SIGN_UP_CONFIRM,
      key: 'confirm',
      component: <ConfirmEmail />,
    },
  ]

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'metaTitles.signUp' })}</title>
      </Helmet>
      <Switch>
        {routes.map((route) => (
          <Route key={route.key} exact path={route.path}>
            {route.component}
          </Route>
        ))}
      </Switch>
    </>
  )
}
export default SignupRoutes
