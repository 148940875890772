import labels from 'react-phone-number-input/locale/en.json'
import { getCountries, getCountryCallingCode } from 'react-phone-number-input'
import { CountryCode } from 'utils/types/common'

export const getCountryNameByCode = (countryCode: CountryCode) => {
  return labels[countryCode]
}

export const Countries = getCountries()
  .map((countryCode) => ({
    name: getCountryNameByCode(countryCode),
    code: countryCode,
    callingCode: getCountryCallingCode(countryCode),
  }))
  .sort((a, b) => a.name.localeCompare(b.name))
