import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: ${color('darkGray')};
  font-weight: 700;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  cursor: pointer;

  svg {
    font-size: 1.6rem;
  }

  &:hover {
    background-color: ${color('veryLightGray')};
    color: ${color('primaryBlue')};
  }
`
