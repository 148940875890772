import { useIntl } from 'react-intl'
import { Company } from 'utils/types/company'

import Toggle from 'components/Toggle'
import Tooltip from 'components/Tooltip'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip'
import type { PlacesType } from 'react-tooltip'
import theme from 'utils/theme'

import * as Styles from './ShowInSearch.styles'

interface TooltipStylesType {
  place: PlacesType
  backgroundColor: string
  color: string
  boxShadow: string
  border: string
  maxWidth: string
  margin: string
}

interface ShowInSearchProps {
  company: Company
  isFounderVerified: boolean
  onChange: (company: Company) => void
  onEditField: (field: { key: string; value: any }) => void
}

const ShowInSearch: React.FC<ShowInSearchProps> = ({
  company,
  isFounderVerified,
  onChange,
  onEditField,
}) => {
  const intl = useIntl()

  const tooltipStyle: TooltipStylesType = {
    place: 'bottom',
    backgroundColor: theme.colors.white,
    color: theme.colors.darkGray,
    boxShadow: `0 0 1.5rem 0 ${theme.colors.blackShadow}`,
    border: `0.1rem solid ${theme.colors.veryLightGray}`,
    maxWidth: '24.5rem',
    margin: '-0.5rem 0 0 4rem',
  }

  const content = (
    <Styles.Wrapper>
      <Toggle
        id="showInDiscover"
        name="showInDiscover"
        status={!company?.hidden}
        onChangeStatus={(checked) => {
          const newCompanyData = {
            ...company,
            hidden: !checked,
          }
          onChange(newCompanyData)
          onEditField({ key: 'hidden', value: !checked })
        }}
        legend={intl.formatMessage({
          id: 'editCompany.showInDiscover',
        })}
        className="showInDiscover"
        customLegendStyles={{
          color: theme.colors.darkBlue,
          fontSize: '1.8rem',
        }}
        disabled={!isFounderVerified}
      />
    </Styles.Wrapper>
  )

  if (!isFounderVerified) {
    return (
      <ReadOnlyTooltip
        text={intl.formatMessage({
          id: 'companyProfile.showInCompanySearchUnverifiedTooltip',
        })}
        {...tooltipStyle}
      >
        {content}
      </ReadOnlyTooltip>
    )
  }

  if (!company?.hidden) {
    return (
      <Tooltip
        text={intl.formatMessage({ id: 'editCompany.showInDiscoverTooltip' })}
        {...tooltipStyle}
      >
        {content}
      </Tooltip>
    )
  }

  return content
}

export default ShowInSearch
