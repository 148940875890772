import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

const commonStyles = css`
  background-color: ${color('white')};
  border-bottom: ${color('lightGray', 0.2)} 0.1rem solid;
  display: grid;
  padding: 0.8rem 2.4rem 2.4rem 2.4rem;
  position: sticky;
  top: 0;
  z-index: 3;
  grid-template-columns: auto min-content;
`

const SmallNavigationStyles = css`
  align-items: baseline;
  height: 7.3rem;
`

const LargeNavigationStyles = css`
  height: auto;
`

export const NavigationBar = styled.div<{
  isExpanded: boolean
}>`
  ${commonStyles};
  ${({ isExpanded }) =>
    isExpanded ? LargeNavigationStyles : SmallNavigationStyles};
`
