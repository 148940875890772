import styled from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const PreviewerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const EmailNavigationWrapper = styled.nav`
  background: ${color('white')};
  padding: 0 5.4rem 0 6.4rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 12.9rem;
  align-items: center;

  @media ${maxSize.md} {
    padding: 2rem 1rem;
  }
`

export const NavigationWizardWrapper = styled.div`
  width: 26%;

  @media ${maxSize.mobile} {
    width: 60%;
  }

  @media ${maxSize.mdXl} {
    display: none;
  }

  @media ${maxSize.xss} {
    width: 100%;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${maxSize.md} {
    padding: 1rem;
  }
`

export const ButtonsRightContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const NextLabel = styled.span`
  margin-right: 1rem;
`

export const CreateEmailContainer = styled.div<{
  minHeightNone?: boolean
}>`
  padding: 3.2rem;
  padding-bottom: 1rem;
  display: flex;
  min-height: ${({ minHeightNone }) =>
    minHeightNone ? 'fitContent' : 'calc(100vh - 28rem)'};

  @media ${maxSize.tabletLg} {
    flex-direction: column;
  }

  @media ${maxSize.mobile} {
    min-height: min-content;
  }

  @media ${maxSize.md} {
    padding: 1rem;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
`
