import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import Tooltip from 'components/Tooltip'
import Button from 'ui/Button/Button'

export const CellValue = styled.div<{
  isPlaceholder: boolean
  hasError?: boolean
  hasSuggestion?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 0 0.8rem;
  color: ${color('darkGray')};

  ${({ isPlaceholder }) =>
    isPlaceholder &&
    css`
      color: ${color('lightGray', 0.8)};
    `}

  ${({ hasError }) =>
    hasError &&
    css`
      border: 1px solid ${color('red')};
    `}

  ${({ hasError, hasSuggestion }) =>
    hasSuggestion &&
    !hasError &&
    css`
      border: 1px solid ${color('yellow')};
    `}
`

export const CellWrapper = styled.div`
  width: 100%;

  input {
    padding: 0 0.8rem;
    color: ${color('darkGray')};
  }
`

export const DropdownWrapper = styled.div`
  background-color: ${color('white')};
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
  margin-top: 0.2rem;
  padding: 0.8rem;
  max-height: 18rem;
  overflow-y: auto;
  width: 24rem;
`

export const DropdownOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;
  border-radius: 0.8rem;
  padding: 0.8rem 1.2rem;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    background-color: ${color('veryLightBlue', 0.2)};
    color: ${color('primaryBlue')};
    cursor: pointer;
  }
`

export const NoResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  font-size: 1.2rem;
`

export const TooltipHoldingName = styled.span`
  color: ${color('darkGray')};
`

export const CellErrorContainer = styled.div`
  position: absolute;
  top: 35px;
  z-index: 9999;
  background-color: ${color('white')};
  color: ${color('darkGray')};
  border: 0.1rem solid ${color('veryLightGray')};
  box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.1);
`

export const FullCellTooltip = styled(Tooltip)`
  height: 100%;
  width: 100%;
`

export const ProfileSuggestions = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-top: 0.8rem;
  `,
  Content: styled.div`
    display: flex;
    gap: 0.8rem;
    align-items: center;
  `,
  FundInfo: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,
  ManagerName: styled.div`
    font-weight: 400;
    text-align: left;
    color: ${color('lightGray')};
  `,
  Button: styled(Button)`
    width: 100%;
  `,
}
