import { getShowInvestorInitialLink } from 'reducers/breadcrumbSlice'
import { useAppSelector } from 'utils/hooks/reduxToolkit'

const useShowInvestorInitialLink = () => {
  const showInitialLink = useAppSelector(getShowInvestorInitialLink)

  return {
    showInitialLink,
  }
}

export default useShowInvestorInitialLink
