import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Menu = styled.div<{
  visible: boolean
  top: number
  left: number
}>`
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  position: fixed;
  z-index: 1;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  background: ${color('white')};
  padding: 0.8rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`
