import { useCallback, useId, useState } from 'react'
import { Nullable } from 'utils/types/common'
import { BrushRange } from './useMultiViewChart'

export type Quarter = {
  formattedDate: string
  fullDate: Date
}

export type DateType = 'start' | 'end'

interface Props {
  dateRange: {
    start: string
    end: string
  }
  metrics: {
    date: Nullable<Date>
    quarter: string
    year: string
  }[]
  brushRangeIndexes?: Nullable<BrushRange>
  onChangePeriod: (range: BrushRange) => void
}

const getLastDayOfQuarter = (date, quarter) => {
  const year = date.getFullYear()
  let month
  switch (quarter) {
    case '1':
      month = 2 // March
      break
    case '2':
      month = 5 // June
      break
    case '3':
      month = 8 // September
      break
    case '4':
      month = 11 // December
      break
    default:
      throw new Error(
        'Invalid quarter. Quarter must be a string between 1 and 4.'
      )
  }

  const lastDayOfMonth = new Date(year, month + 1, 0)
  return lastDayOfMonth
}

const useDateRangeHelpers = ({
  dateRange,
  metrics,
  brushRangeIndexes,
  onChangePeriod,
}: Props) => {
  const [refreshChartKey, setRefreshChartKey] = useState('')
  const randomId = useId()

  const updateRangeOnYearlyDayChange = useCallback(
    ({ date, isStart }: { date: Date; isStart: boolean }) => {
      if (isStart) {
        const startIndex = metrics.findIndex(
          (metric) => metric.year === date.getFullYear().toString()
        )

        setRefreshChartKey(`${randomId}_${startIndex}`)

        onChangePeriod({
          startIndex,
          endIndex: brushRangeIndexes?.endIndex ?? metrics.length - 1,
        })

        return
      }

      const endIndex = metrics.findIndex(
        (metric) => metric.year === date.getFullYear().toString()
      )

      setRefreshChartKey(`${randomId}_${endIndex}`)
      onChangePeriod({
        startIndex: brushRangeIndexes?.startIndex ?? 0,
        endIndex,
      })
    },
    [
      brushRangeIndexes?.endIndex,
      brushRangeIndexes?.startIndex,
      metrics,
      onChangePeriod,
      randomId,
    ]
  )

  const updateRangeOnQuarterlyDayChange = useCallback(
    ({ date, isStart }: { date: Date; isStart: boolean }) => {
      if (isStart) {
        const startIndex = metrics.findIndex(
          (metric) =>
            metric.quarter ===
              Math.ceil((date.getMonth() + 1) / 3).toString() &&
            metric.year === date.getFullYear().toString()
        )

        onChangePeriod({
          startIndex,
          endIndex: brushRangeIndexes?.endIndex ?? metrics.length - 1,
        })

        setRefreshChartKey(`${randomId}_${startIndex}`)
        return
      }

      const endIndex = metrics.findIndex(
        (metric) =>
          metric.quarter === Math.ceil((date.getMonth() + 1) / 3).toString() &&
          metric.year === date.getFullYear().toString()
      )

      setRefreshChartKey(`${randomId}_${endIndex}`)
      onChangePeriod({
        startIndex: brushRangeIndexes?.startIndex ?? 0,
        endIndex,
      })
    },
    [
      brushRangeIndexes?.endIndex,
      brushRangeIndexes?.startIndex,
      metrics,
      onChangePeriod,
      randomId,
    ]
  )

  const getArrayOfYearsBasedOnDate = (useStartDate: boolean) => {
    const years: string[] = []
    const currentDate = useStartDate
      ? new Date(dateRange.start) ?? metrics[0].date!
      : new Date(dateRange.end) ?? metrics[metrics.length - 1].date!

    const endDateCondition = useStartDate
      ? (date: Date) =>
          date.getFullYear() <=
          new Date(metrics[metrics.length - 1].date!).getFullYear()
      : (date: Date) =>
          date.getFullYear() >= new Date(metrics[0].date!).getFullYear()

    const stepFunction = useStartDate
      ? (date: Date) => date.setFullYear(date.getFullYear() + 1)
      : (date: Date) => date.setFullYear(date.getFullYear() - 1)

    while (endDateCondition(currentDate)) {
      years.push(currentDate.getFullYear().toString())
      stepFunction(currentDate)
    }

    return years.reverse()
  }

  const getArrayOfQuartersBasedOnDate = (dateType: DateType) => {
    const quarters: Quarter[] = []
    const currentDate =
      dateType === 'start'
        ? new Date(dateRange.start) ?? metrics[0].date!
        : new Date(dateRange.end) ?? metrics[metrics.length - 1].date!

    while (
      dateType === 'start'
        ? currentDate <= metrics[metrics.length - 1].date!
        : currentDate >= metrics[0].date!
    ) {
      const quarter = Math.ceil((currentDate.getMonth() + 1) / 3)
      const year = currentDate.getFullYear()
      const metric = metrics.find(
        (singleMetric) =>
          singleMetric.quarter === quarter.toString() &&
          singleMetric.year === year.toString()
      )

      if (metric) {
        quarters.push({
          formattedDate: `Q${quarter}, ${year}`,
          fullDate:
            dateType === 'start'
              ? getLastDayOfQuarter(currentDate, quarter.toString())
              : metric.date!,
        })
      }

      currentDate.setMonth(
        dateType === 'start'
          ? currentDate.getMonth() + 3
          : currentDate.getMonth() - 3
      )
    }

    return quarters.reverse().slice(0, dateType === 'start' ? -1 : undefined)
  }

  return {
    refreshChartKey,
    updateRangeOnYearlyDayChange,
    updateRangeOnQuarterlyDayChange,
    getArrayOfYearsBasedOnDate,
    getArrayOfQuartersBasedOnDate,
  }
}

export default useDateRangeHelpers
