import {
  StreamChatType,
  isHoldingMessageLink,
  isHoldingUpdateMessageLink,
  isPortfolioMessageLink,
  isPortfolioUpdateMessageLink,
  isUpdateMessageLink,
} from 'containers/Chat/types'
import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import type { StreamMessage } from 'stream-chat-react'
import { Attachment, renderText, useMessageContext } from 'stream-chat-react'
import { getInitials } from 'utils/functions/user'
import CreateNewChatSuggestion from './components/CreateNewChatSuggestion/CreateNewChatSuggestion'
import HoldingAttachment from './components/HoldingAttachment/HoldingAttachment'
import MessageActions from './components/MessageActions/MessageActions'
import ReactionsList from './components/ReactionsList'
import ThreadRepliesInfo from './components/ThreadRepliesInfo/ThreadRepliesInfo'

import EditMessageInput from '../EditMessageInput/EditMessageInput'
import MessageTimestamp from '../MessageTimestamp'
import SentInformation from '../SentInformation'
import {
  Avatar,
  Container,
  DeletedMessageIcon,
  DummyAvatar,
  GrayEditedLabel,
  MessageContainer,
  MyMessage,
  TheirMessage,
  ThreadSeparator,
  WhiteEditedLabel,
  MyMessageContainer,
} from './Message.styles'
import PortfolioAttachment from './components/HoldingAttachment/PortfolioAttachment'
import UpdateAttachmentBox from './components/HoldingAttachment/UpdateAttachmentBox'
import NoUpdateAccess from './components/NoUpdateAccess'
import { useMessage } from './useMessage'

const getMessageText = (
  message: StreamMessage<StreamChatType>,
  isMyMessage: () => boolean,
  isDeleted: boolean,
  isDeletedForYou: boolean
) => {
  if (!isDeleted) {
    return renderText(message.text, message.mentioned_users)
  }

  let textId = 'chat.youDeletedThisMessage'

  if (!isMyMessage()) {
    textId = 'chat.thisMessageWasDeleted'
  } else if (isDeletedForYou) {
    textId = 'chat.youDeletedThisMessageOnlyForYou'
  }

  return (
    <>
      <DeletedMessageIcon icon={['far', 'ban']} />
      <FormattedMessage id={textId} />
    </>
  )
}

const Message = () => {
  const { message, isMyMessage, initialMessage, editing, groupStyles } =
    useMessageContext<StreamChatType>()

  const { user } = message

  const {
    isDeleted,
    isDeletedForYou,
    showMessageActions,
    showThreadInfo,
    showReactionsList,
    showEdited,
    showAttachments,
    parsedMessageLinks,
    attachments,
    isAThreadMessage,
  } = useMessage()

  const messageText = useMemo(
    () => getMessageText(message, isMyMessage, isDeleted, isDeletedForYou),
    [message, isMyMessage, isDeleted, isDeletedForYou]
  )

  const firstGroupStyle = groupStyles ? groupStyles[0] : 'single'
  const showFullMessage =
    firstGroupStyle === 'top' || firstGroupStyle === 'single'

  const renderCreateChatSuggestions = useMemo(() => {
    if (isDeleted || isDeletedForYou) return null

    return parsedMessageLinks?.map((messageLink) => {
      if (isHoldingMessageLink(messageLink)) {
        return (
          <CreateNewChatSuggestion
            key={messageLink.holdingId}
            entityId={messageLink.holdingId}
            type={messageLink.holdingType}
          />
        )
      }

      if (isUpdateMessageLink(messageLink)) {
        if (isPortfolioUpdateMessageLink(messageLink)) {
          return (
            <CreateNewChatSuggestion
              key={messageLink.portfolioId}
              entityId={messageLink.portfolioId}
              updateId={messageLink.updateId}
              type={messageLink.updateType}
            />
          )
        }

        if (isHoldingUpdateMessageLink(messageLink)) {
          return (
            <CreateNewChatSuggestion
              key={messageLink.holdingId}
              entityId={messageLink.holdingId}
              updateId={messageLink.updateId}
              type={messageLink.updateType}
            />
          )
        }

        return (
          <CreateNewChatSuggestion
            key={messageLink.updateId}
            updateId={messageLink.updateId}
            type={messageLink.updateType}
          />
        )
      }

      if (isPortfolioMessageLink(messageLink)) {
        return (
          <CreateNewChatSuggestion
            key={messageLink.portfolioId}
            entityId={messageLink.portfolioId}
            type={messageLink.portfolioType}
          />
        )
      }

      return null
    })
  }, [isDeleted, isDeletedForYou, parsedMessageLinks])

  const renderNoUpdateAccessMessages = useMemo(() => {
    if (isDeleted || isDeletedForYou) return null

    return parsedMessageLinks
      ?.filter(isUpdateMessageLink)
      .map((messageLink) => {
        return (
          <NoUpdateAccess
            key={messageLink.updateId}
            updateId={messageLink.updateId}
            updateType={messageLink.updateType}
          />
        )
      })
  }, [isDeleted, isDeletedForYou, parsedMessageLinks])

  const renderMessageAttachments = useMemo(() => {
    if (isDeleted || isDeletedForYou) return null

    return parsedMessageLinks?.map((messageLink) => {
      if (isHoldingMessageLink(messageLink)) {
        return (
          <HoldingAttachment
            key={messageLink.holdingId}
            holdingId={messageLink.holdingId}
            link={messageLink.link}
            type={messageLink.holdingType}
          />
        )
      }

      if (isPortfolioMessageLink(messageLink)) {
        return (
          <PortfolioAttachment
            key={messageLink.portfolioId}
            portfolioId={messageLink.portfolioId}
            portfolioType={messageLink.portfolioType}
            link={messageLink.link}
          />
        )
      }

      if (isUpdateMessageLink(messageLink)) {
        return (
          <UpdateAttachmentBox
            updateId={messageLink.updateId}
            updateType={messageLink.updateType}
            link={messageLink.link}
            matchedPath={messageLink.matchedPath}
          />
        )
      }

      return null
    })
  }, [isDeleted, isDeletedForYou, parsedMessageLinks])

  return (
    <>
      <Container
        isMyMessage={isMyMessage()}
        initialMessage={initialMessage}
        groupStyle={firstGroupStyle}
        isAThreadMessage={isAThreadMessage}
      >
        {!isMyMessage() && showFullMessage && (
          <Avatar
            image={user?.image}
            initials={getInitials(user?.name)}
            avatarStyle="avatarForUpdateFeed"
          />
        )}
        {!showFullMessage && (
          <DummyAvatar>
            <MessageTimestamp message={message} showOnlyTime />
          </DummyAvatar>
        )}
        {editing ? (
          <EditMessageInput />
        ) : (
          <MessageContainer isMyMessage={isMyMessage()}>
            {showMessageActions && <MessageActions />}
            {showFullMessage && (
              <SentInformation message={message} isMyMessage={isMyMessage()} />
            )}
            {isMyMessage() ? (
              <>
                <MyMessageContainer>
                  <MyMessage deleted={isDeleted}>
                    {messageText}
                    {showEdited && (
                      <WhiteEditedLabel>
                        <FormattedMessage id="chat.edited" />
                      </WhiteEditedLabel>
                    )}
                    {renderMessageAttachments}
                    {showAttachments && (
                      <Attachment attachments={attachments!} />
                    )}
                  </MyMessage>
                </MyMessageContainer>
                {renderCreateChatSuggestions}
              </>
            ) : (
              <>
                <TheirMessage deleted={isDeleted}>
                  {messageText}
                  {showEdited && (
                    <GrayEditedLabel>
                      <FormattedMessage id="chat.edited" />
                    </GrayEditedLabel>
                  )}
                  {renderMessageAttachments}

                  {showAttachments && <Attachment attachments={attachments!} />}
                </TheirMessage>
                {renderNoUpdateAccessMessages}
              </>
            )}
            {showReactionsList && <ReactionsList />}
            {showThreadInfo && <ThreadRepliesInfo />}
          </MessageContainer>
        )}
      </Container>
      {initialMessage && <ThreadSeparator />}
    </>
  )
}

export default Message
