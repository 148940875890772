import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Title = styled.p<{ width?: string }>`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  line-height: 14.4px;
  text-transform: uppercase;

  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`
