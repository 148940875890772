/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'
import { GridType } from 'utils/constants/gridType'

const initialState = {
  currentCompany: {},
  signupCompany: {},
  legalCompany: {},
  currentCompanyProfile: {},
  owner: {},
  loading: true,
  gridType: GridType.GRID,
  currentUpdates: {},
  showCompanyNavigationHeader: true,
}

const setCurrentCompany = (state, action) => {
  state.currentCompany = action
}

const setCurrentCompanyLoading = (state, action) => {
  state.loading = action
}

const resetCurrentCompany = (state) => {
  state.currentCompany = {}
  state.loading = true
}

const setGridType = (state, action) => {
  state.gridType = action
}

const setSignupCompany = (state, action) => {
  state.signupCompany = action
}

const setOwner = (state, owner) => {
  state.owner = owner
}

const setCurrentUpdates = (state, { updates, page, companyDatum }) => {
  state.currentUpdates = {
    updates,
    page,
    companyDatum,
  }
  return state
}

const setCompanyProfile = (state, company) => {
  state.currentCompanyProfile = company
}

const setShowCompanyNavigationHeader = (state, show) => {
  state.showCompanyNavigationHeader = show
}

const handlers = {
  [Types.SET_CURRENT_COMPANY]: setCurrentCompany,
  [Types.SET_CURRENT_COMPANY_LOADING]: setCurrentCompanyLoading,
  [Types.RESET_CURRENT_COMPANY]: resetCurrentCompany,
  [Types.SET_GRID_TYPE]: setGridType,
  [Types.SET_SIGNUP_COMPANY]: setSignupCompany,
  [Types.SET_OWNER]: setOwner,
  [Types.SET_CURRENT_UPDATES]: setCurrentUpdates,
  [Types.SET_COMPANY_PROFILE]: setCompanyProfile,
  [Types.SET_SHOW_COMPANY_NAVIGATION_HEADER]: setShowCompanyNavigationHeader,
}

export default createReducer(initialState, handlers)
