import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useStripe } from '@stripe/react-stripe-js'
import type { PaymentIntent, SetupIntent } from '@stripe/stripe-js'

import CWLoader from 'components/CWLoader/CWLoader'
import { handlePaymentIntentResponse } from 'components/PaymentForm/usePaymentForm'

interface PaymentCheckerProps {
  clientSecret: string
  isPaymentIntent: boolean
  onPaymentSuccess: (paymentIntentId: string) => void
  goBackToPayment: (clientSecret: string, error: string) => void
}

const PaymentChecker: React.FC<PaymentCheckerProps> = ({
  clientSecret,
  isPaymentIntent,
  onPaymentSuccess,
  goBackToPayment,
}) => {
  const stripe = useStripe()
  const intl = useIntl()

  const retrieveIntentCallback = useCallback(
    (intent: PaymentIntent | SetupIntent | undefined) => {
      handlePaymentIntentResponse(
        intent,
        onPaymentSuccess,
        (error: string) => goBackToPayment(clientSecret, error),
        intl
      )
    },
    [clientSecret, goBackToPayment, intl, onPaymentSuccess]
  )

  useEffect(() => {
    if (clientSecret && stripe) {
      if (isPaymentIntent) {
        stripe
          .retrievePaymentIntent(clientSecret)
          .then(({ paymentIntent }) => retrieveIntentCallback(paymentIntent))
      } else {
        stripe
          .retrieveSetupIntent(clientSecret)
          .then(({ setupIntent }) => retrieveIntentCallback(setupIntent))
      }
    }
  }, [stripe, clientSecret, isPaymentIntent, retrieveIntentCallback])

  return <CWLoader />
}

export default PaymentChecker
