import { useForm } from 'components/Form/hooks'
import { IconPosition } from 'components/TimeSelect/types'
import { RepetitionWrapper } from 'components/UpdateContent/UpdateContent.styles'
import { useField } from 'formik'
import React from 'react'
import { useIntl } from 'react-intl'
import theme from 'utils/theme'
import { getScheduleRepetitionDropdownOptions } from 'utils/functions/accounting'
import { getScheduleType } from 'utils/functions/updates'
import { ScheduleType } from 'utils/types/update'
import { UpdateFormContext } from 'utils/types/updateForm'
import { MAX_TITLE_COUNTER_CHARACTERS } from 'utils/constants/updates'
import { FIELD_NAMES } from '../../Accounting/constants/constants'
import { DatePicker, Dropdown, Input } from '../commons'
import ReportSettings from '../ReportSettings'
import { Content, RepetitionContainer, Row } from './AccountingForm.styles'
import ReadOnlyField from '../commons/ReadOnlyField/ReadOnlyField'

const AccountingForm: React.FC = () => {
  const intl = useIntl()
  const [date] = useField<Date>(FIELD_NAMES.date)
  const [titleField] = useField<string>(FIELD_NAMES.title)
  const scheduleType = getScheduleType(date.value)
  const { isEditMode } = useForm<UpdateFormContext>()

  return (
    <Content>
      <Row>
        <Input
          id={FIELD_NAMES.title}
          label={intl.formatMessage({
            id: 'updates.document.title.label',
          })}
          name={FIELD_NAMES.title}
          placeholder={intl.formatMessage({
            id: 'updates.document.title.placeholder',
          })}
          title={intl.formatMessage({
            id: 'updates.document.title.label',
          })}
          withCounter
          customMaxCounterCharacters={MAX_TITLE_COUNTER_CHARACTERS}
          counterSubtractNumber={titleField.value.length}
          simulateIconSpace
          counterStyle={{
            right: '2rem',
            top: '1.2rem',
            color: theme.colors.darkGray,
            opacity: 0.5,
          }}
          extraPaddingRight={1.5}
        />
        {isEditMode ? (
          <ReadOnlyField
            name={FIELD_NAMES.date}
            label={intl.formatMessage({
              id: 'updates.document.date.label',
            })}
            isDate
            marginBottom="1.8rem"
          />
        ) : (
          <DatePicker
            id={FIELD_NAMES.date}
            iconPosition={IconPosition.LEFT}
            label={intl.formatMessage({
              id: 'updates.document.date.label',
            })}
            name={FIELD_NAMES.date}
            onlyMonthYearPicker={false}
          />
        )}
      </Row>
      {scheduleType === ScheduleType.SCHEDULE && (
        <RepetitionWrapper>
          <RepetitionContainer>
            {isEditMode ? (
              <ReadOnlyField
                name={FIELD_NAMES.repetition}
                label={intl.formatMessage({
                  id: 'general.repetition',
                })}
                marginLeft="0"
              />
            ) : (
              <Dropdown
                disabled={isEditMode}
                label={intl.formatMessage({ id: 'general.repetition' })}
                name={FIELD_NAMES.repetition}
                id={FIELD_NAMES.repetition}
                options={getScheduleRepetitionDropdownOptions(date.value, intl)}
                placeholder={intl.formatMessage({
                  id: 'general.selectRepetition',
                })}
              />
            )}
          </RepetitionContainer>
        </RepetitionWrapper>
      )}
      <ReportSettings isEditMode={!!isEditMode} reportParamsDisabled={false} />
    </Content>
  )
}

export default AccountingForm
