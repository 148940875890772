import { FormattedMessage } from 'react-intl'
import dayjs from 'dayjs'
import GroupLogo from 'components/GroupLogo'
import GroupTooltips from 'components/GroupTooltips'
import { ContentAnalytic } from '../../../../utils/types/analytics'

import {
  AttachmentAnalyticRowContainer,
  Label,
  UserWrapper,
  UserLabel,
  TextLabel,
} from './AttachmentSidebar.styles'

interface AttachmentAnalyticRowProps {
  attachmentAnalytic: ContentAnalytic
}

const AttachmentAnalyticRow: React.FC<AttachmentAnalyticRowProps> = ({
  attachmentAnalytic,
}) => {
  const renderDate = () => {
    if (dayjs(attachmentAnalytic.updatedAt).isToday()) {
      return `Today, ${dayjs(attachmentAnalytic.updatedAt).format('HH:mm')}`
    }
    return dayjs(attachmentAnalytic.updatedAt).format('DD MMM, HH:mm')
  }

  return (
    <AttachmentAnalyticRowContainer>
      <Label>
        <FormattedMessage id="analytics.user" />
      </Label>
      <UserWrapper>
        <GroupLogo
          small
          forceInitials
          name={attachmentAnalytic.user.name}
          imageUrl=""
        />
        <UserLabel>{attachmentAnalytic.user.name}</UserLabel>
      </UserWrapper>

      <Label>
        <FormattedMessage id="analytics.userGroups" />
      </Label>
      <GroupTooltips
        small
        groups={attachmentAnalytic.accessedGroups}
        direction="left"
        maxGroupsToDisplay={3}
      />
      <Label>
        <FormattedMessage id="analytics.lastDownloadDate" />
      </Label>
      <TextLabel>{renderDate()}</TextLabel>
      <Label>
        <FormattedMessage id="analytics.downloads" />
      </Label>
      <TextLabel>{attachmentAnalytic.downloadCount}</TextLabel>
    </AttachmentAnalyticRowContainer>
  )
}

export default AttachmentAnalyticRow
