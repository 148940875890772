import styled, { css } from 'styled-components'
import Button from 'ui/Button'
import { breakpoint, maxSize, size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const SideInformationWrapper = styled.div`
  margin-top: -5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  @media ${maxSize.tabletLg} {
    gap: 2.4rem;
  }
`

export const HeaderInfoWrapper = styled.div`
  background-color: white;
  margin: auto;
  padding: 5.7rem 3.2rem 2.4rem 3.2rem;
  border-radius: 0.8rem;
  width: 100%;

  @media ${size.tabletLg} {
    width: 31.9rem;
  }
`

export const EditButton = styled(Button)`
  margin-top: 1.6rem;
  width: 100%;
`

export const SideCardWrapper = styled.div<{ colorOfTitles?: string }>`
  width: auto;
  &:empty {
    display: none;
  }

  ${(props) =>
    props.colorOfTitles &&
    css`
      span > a > div {
        color: ${color('lightGray')};
      }
    `}

  @media (min-width: ${breakpoint.tabletLg}) {
    width: 31.9rem;
  }
`
