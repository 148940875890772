import { useField } from 'formik'
import { FormattedMessage } from 'react-intl'

import FadeIn from 'components/FadeIn'
import { AccountTypes } from 'utils/types/inbounds'

import {
  Description,
  RadioButton,
  RadioLabel,
  RadioLabelContainer,
  TypeOfAccountStepContainer,
} from '../CreateInbound.styles'

const TypeOfAccountStep = () => {
  const [field, , helper] = useField('accountType')

  return (
    <FadeIn>
      <TypeOfAccountStepContainer>
        <Description bold>
          <FormattedMessage id="inbounds.typeOfAccount" />
        </Description>
        <RadioButton
          name="accountType"
          value={field.value}
          label=""
          onChange={helper.setValue}
          options={[
            {
              id: 'googleGroup',
              label: (
                <RadioLabelContainer>
                  <RadioLabel bold>
                    <FormattedMessage id="inbounds.accounts.googleGroup.title" />
                  </RadioLabel>
                  <RadioLabel>
                    <FormattedMessage id="inbounds.accounts.googleGroup.description" />
                  </RadioLabel>
                </RadioLabelContainer>
              ),
              value: AccountTypes.GOOGLE_GROUP,
            },
          ]}
        />
      </TypeOfAccountStepContainer>
    </FadeIn>
  )
}

export default TypeOfAccountStep
