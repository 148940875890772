const breadcrumb = {
  updates: 'Updates',
  investments: 'Investments',
  allFollowedHoldings: 'All Followed Holdings',
  holdings: 'Holdings',
  discover: 'Discover',
  emails: 'Emails',
  investorManagement: 'Investor Management',
  share: 'Share',
  createUpdate: 'Create Update',
}

export default breadcrumb
