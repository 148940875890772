import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

import Table from 'ui/Table'
import { getVehicleColumns } from 'utils/functions/renderers/renderVehiclesHelper'
import { getCompanyTransactionsColumns } from 'utils/functions/renderers/renderTransactionHelper'

import InvestorManagementService, {
  TransactionFilter,
} from 'api/InvestorManagementService'

import Toast from 'components/Toast'
import Card from 'components/Card'

import CompanyTransactionsZeroState from 'components/PortfolioHoldings/CompanyTransactionsZeroState'
import { maxSize } from 'utils/constants/breakpoint'

import { TransactionItem } from 'utils/types/update'
import { InvestmentVehicle } from 'utils/types/investors'
import styles from 'containers/InvestorManagement/ShowInvestor/ShowInvestor.module.scss'
import { SortDirection } from 'utils/constants/sortDirection'
import { SortByCallback } from 'ui/Table/types'
import { orderArrayByObjectPropertyAndDirection } from 'utils/functions/array'
import { SortDirectionType } from 'utils/types/common'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { useUpdatesFiltersContext } from 'containers/UpdatesView/UpdatesFiltersContext'
import { TableMarginWrapper } from '../FundsTable/FundsTable.styles'
import {
  NoVehiclesForInvestorFunds,
  StyledInvestmentVehicles,
} from './InvestmentVehiclesTable.styles'

const PAGE_SIZE = 25

interface InvestmentVehiclesTableProps {
  isLoading: boolean
  vehicles: any
  onShowTransaction: (transaction: TransactionItem) => void
}

const InvestmentVehiclesTable: React.FC<InvestmentVehiclesTableProps> = ({
  isLoading,
  vehicles,
  onShowTransaction,
}) => {
  const intl = useIntl()
  const { filters } = useUpdatesFiltersContext()
  const [sortBy, setSortBy] = useState<{
    sortId: string
    sortDirection: SortDirectionType
  }>({
    sortId: 'investorManagement.investmentVehicle.name',
    sortDirection: SortDirection.ASC,
  })

  useHighlightText(
    {
      elementClass: '.investment-vehicle-name',
      text: filters.searchText,
    },
    [isLoading, filters.searchText]
  )

  const { matches: isMobile } = useMediaQuery(maxSize.sm)

  const getTransactionsByInvestmentVehicle = async (
    rowData: InvestmentVehicle,
    page: number,
    pageSize: number,
    sortId: string,
    sortDirection: SortDirectionType
  ) => {
    try {
      const transactions = await InvestorManagementService.fetchTransactions({
        id: rowData.id,
        filter: TransactionFilter.INVESTMENT_VEHICLE,
        page,
        pageSize,
        sortId,
        sortDirection,
      })

      return transactions
    } catch (error) {
      Toast.displayIntl(
        'investorManagement.investmentVehicle.errorFetchingTransactions',
        'error'
      )
      return []
    }
  }

  const onSortByColumn: SortByCallback = ({ sortId, sortDirection }) => {
    setSortBy({
      sortId,
      sortDirection,
    })
  }

  const sortedVehicles = useMemo<InvestmentVehicle[]>(() => {
    const filteredVehicles = filters.searchText
      ? vehicles.filter((vehicle) =>
          vehicle.name.toLowerCase().includes(filters.searchText.toLowerCase())
        )
      : vehicles

    return orderArrayByObjectPropertyAndDirection(
      filteredVehicles,
      sortBy.sortId,
      sortBy.sortDirection
    )
  }, [filters.searchText, vehicles, sortBy.sortId, sortBy.sortDirection])

  return (
    <StyledInvestmentVehicles>
      <Card isExpandable padding="1.4rem 3.2rem">
        <Card.Header>
          <FormattedMessage id="investorManagement.investmentVehicle.title" />
        </Card.Header>
        <Card.Body>
          {sortedVehicles.length ? (
            <TableMarginWrapper>
              <Table<InvestmentVehicle, TransactionItem, InvestmentVehicle>
                data={sortedVehicles}
                loading={isLoading}
                rowHeight={45}
                keyPath="id"
                columns={getVehicleColumns({ intl, styles, isMobile })}
                isExpandable
                internalTableInitialSortId="date"
                internalTableInitialSortDirection={SortDirection.DESC}
                getExpandableData={getTransactionsByInvestmentVehicle}
                expandedRowHeight={41}
                expandedKeyPath="id"
                expandedColumns={getCompanyTransactionsColumns({
                  intl,
                  styles,
                  onEditTransaction: () => {},
                  isEditable: false,
                })}
                loadingExpandedDataLabel={intl.formatMessage({
                  id: 'investorManagement.investmentVehicle.loadingTransactions',
                })}
                paginationLabel={intl.formatMessage({
                  id: 'investorManagement.investmentVehicle.loadMoreTransactions',
                })}
                externalEmbeddedPagination
                embeddedPageSize={PAGE_SIZE}
                expandOnRowClick
                renderExpandableRowZeroState={() => (
                  <CompanyTransactionsZeroState />
                )}
                onClickExpandedRow={onShowTransaction}
                onChangeSortBy={onSortByColumn}
              />
            </TableMarginWrapper>
          ) : (
            <NoVehiclesForInvestorFunds>
              {intl.formatMessage({
                id: 'investorManagement.investmentVehicle.noVehiclesInInvestorFunds',
              })}
            </NoVehiclesForInvestorFunds>
          )}
        </Card.Body>
      </Card>
    </StyledInvestmentVehicles>
  )
}

export default InvestmentVehiclesTable
