import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import InvestorAndVehicleNavigation from 'components/Breadcrumb/Components/InvestorAndVehicleNavigation'
import { NavigationWrapper } from 'components/Breadcrumb/Breadcrumb.styles'
import { INVESTOR_PROFILE_VEHICLE_PROFILE } from 'routes/constant/investorManagementRoutes'
import {
  INVESTMENT_VEHICLES,
  INVESTMENT_VEHICLES_EDIT,
} from 'routes/constant/InvestorGroupRoutes'
import { Update } from 'utils/types/update'
import { CompanyHoldingData, FundProfile } from 'utils/types/company'
import { Nullable } from 'utils/types/common'
import { URL } from 'reducers/breadcrumbSlice'
import FundHoldingsBreadcrumb from './FundHoldingsBreadcrumb'

interface Props {
  showInitialLink: boolean
  update?: Update
  entityToShow?: FundProfile | CompanyHoldingData
  url: URL
  isShowingCompany: boolean
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
  getAvatar: () => Nullable<JSX.Element>
}

const InvestorProfileBreadcrumb = ({
  isCreatingNewUpdate,
  update,
  url,
  entityToShow,
  getAvatar,
  isShowingCompany,
  initialLinkRedirect,
  showInitialLink,
}: Props) => {
  return !showInitialLink ? (
    <FundHoldingsBreadcrumb
      entityToShow={entityToShow}
      getAvatar={getAvatar}
      isCreatingNewUpdate={isCreatingNewUpdate}
      isShowingCompany={isShowingCompany}
      initialLinkRedirect={initialLinkRedirect}
      update={update}
      url={url}
      showInitialLink={showInitialLink}
    />
  ) : (
    <NavigationWrapper>
      <InvestorAndVehicleNavigation
        url={url}
        isCreatingNewUpdate={isCreatingNewUpdate}
        hasUpdate={!!update}
        vehicleActiveTabs={[
          INVESTOR_PROFILE_VEHICLE_PROFILE,
          INVESTMENT_VEHICLES,
          INVESTMENT_VEHICLES_EDIT,
        ]}
      />

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default InvestorProfileBreadcrumb
