import { HoldingsFilters } from 'api/HoldingsService'

export const QUERIES = {
  HOLDINGS: 'holdings',
  PORTFOLIO_HOLDINGS: 'portfolioHoldings',
  HOLDINGS_SPREADSHEET: 'holdingsSpreadsheet',
} as const

export const holdingsKeys = {
  all: (filters: HoldingsFilters, textSearch?: string) =>
    [QUERIES.HOLDINGS, filters, textSearch] as const,
  portfolioHoldings: (filters: HoldingsFilters) => [
    QUERIES.PORTFOLIO_HOLDINGS,
    filters,
  ],
  holdingsSpreadsheet: (textSearch: string) => [
    QUERIES.HOLDINGS_SPREADSHEET,
    textSearch,
  ],
  portfolioHoldingsByPortfolioCompanyIds: (portfolioCompaniesIds: string[]) =>
    [QUERIES.PORTFOLIO_HOLDINGS, portfolioCompaniesIds] as const,
}
