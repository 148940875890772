import React from 'react'
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styles from './DateRange.styles'
import { ChartCriteriaType, ChartPeriods } from '../../types'
import YearRange from './YearRange'
import QuarterlyRange from './QuarterlyRange'
import { DateType, Quarter } from '../../useDateRangeHelpers'
import useDateSelected from './useDateSelected'

interface DateRangeProps {
  startDate: string
  endDate: string
  updateRangeOnYearlyDayChange: ({
    date,
    isStart,
  }: {
    date: Date
    isStart: boolean
  }) => void
  updateRangeOnQuarterlyDayChange: ({
    date,
    isStart,
  }: {
    date: Date
    isStart: boolean
  }) => void
  getArrayOfYearsBasedOnDate: (useStartDate: boolean) => string[]
  getArrayOfQuartersBasedOnDate: (date: DateType) => Quarter[]
  periodConfig: ChartCriteriaType
}

const DateRange: React.FC<DateRangeProps> = ({
  startDate,
  endDate,
  updateRangeOnYearlyDayChange,
  updateRangeOnQuarterlyDayChange,
  getArrayOfYearsBasedOnDate,
  getArrayOfQuartersBasedOnDate,
  periodConfig,
}) => {
  const isYearly = periodConfig === ChartPeriods.YEARLY
  const isQuarterly = periodConfig === ChartPeriods.QUARTERLY

  const {
    handleQuarterDateActive,
    handleResetDateActive,
    handleYearDateActive,
    quarterDateActive,
    yearDateActive,
    isAnyDateActive,
  } = useDateSelected()

  if (isYearly) {
    return (
      <Styles.DateRange isAnyDateActive={isAnyDateActive}>
        <FontAwesomeIcon icon={['fal', 'calendar-day']} />
        <YearRange
          yearDateActive={yearDateActive}
          handleYearDateActive={handleYearDateActive}
          handleResetDateActive={handleResetDateActive}
          getArrayOfYearsBasedOnDate={getArrayOfYearsBasedOnDate}
          updateRangeOnYearlyDayChange={updateRangeOnYearlyDayChange}
          startDate={startDate}
          endDate={endDate}
        />
      </Styles.DateRange>
    )
  }

  if (isQuarterly) {
    return (
      <Styles.DateRange isAnyDateActive={isAnyDateActive}>
        <FontAwesomeIcon icon={['fal', 'calendar-day']} />
        <QuarterlyRange
          quarterDateActive={quarterDateActive}
          handleQuarterDateActive={handleQuarterDateActive}
          handleResetDateActive={handleResetDateActive}
          getArrayOfQuartersBasedOnDate={getArrayOfQuartersBasedOnDate}
          updateRangeOnQuarterlyDayChange={updateRangeOnQuarterlyDayChange}
          startDate={startDate}
          endDate={endDate}
        />
      </Styles.DateRange>
    )
  }

  const formattedStartDate = format(new Date(startDate), 'MMM dd, yyyy')
  const formattedEndDate = format(new Date(endDate), 'MMM dd, yyyy')

  return (
    <Styles.DateRange>
      <FontAwesomeIcon icon={['fal', 'calendar-day']} />
      {formattedStartDate} - {formattedEndDate}
    </Styles.DateRange>
  )
}

export default DateRange
