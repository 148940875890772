import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import Card from 'components/Card'
import InvestPortfolioRow from 'components/Portfolios/PortfolioRow/InvestPortfolioRow'
import { getCurrentGroupId } from 'selectors/auth'
import {
  getCurrentPortfolio,
  getPortfolioInvestorSettings,
  getPortfolios,
} from 'selectors/portfolio'
import { getColorsForCompanies } from 'utils/functions/portfolios'
import { useInvestorFundPortfoliosSummaryQuery } from 'utils/hooks/investorManagement/useInvestorsQuery'
import { FundSummary as FundSummaryType } from 'utils/types/funds'
import { FundPortfolio } from 'utils/types/portfolios'
import FadeIn from 'components/FadeIn'
import ToggleInvestorCalculation from 'components/PortfolioHoldings/PortfolioHoldingsToggleCalculation'
import { ALL_FUNDS } from '../../constants'
import FundSummaryRow from './FundSummaryRow'
import { HeaderContent } from './FundSummary.styles'

const hasFundSummaryData = (data: FundSummaryType | FundPortfolio) => {
  return [
    data.portfolioCompanies,
    data.averageInvestmentPerCompany,
    data.distributionToPaidIn,
    data.irr,
    data.multipleOnInvestedCapital,
    data.portfolioTotalUnfundedCommitment,
    data.totalDistributed,
    data.totalFairMarketValue,
    data.totalHoldings,
    data.totalInvested,
    (data as FundSummaryType).totalInvestments ||
      (data as FundPortfolio).totalTransactions,
    data.totalValue,
    data.firstInvestmentDate,
  ].some((field) => field !== undefined && field !== null)
}
const SummaryAllFunds = () => {
  const intl = useIntl()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const portfolios = useAppSelector(getPortfolios)
  const portfolioInvestorSettings = useAppSelector(
    getPortfolioInvestorSettings('ALL')
  )
  const { data, isLoading } = useInvestorFundPortfoliosSummaryQuery(
    currentGroupId,
    !portfolioInvestorSettings?.isInvestorCalculationDisabled
  )

  if (!isLoading && (!data || !hasFundSummaryData(data))) return null

  return (
    <FadeIn>
      <Card isExpandable padding="1.4rem 2.4rem">
        <Card.Header>
          <HeaderContent>
            {portfolioInvestorSettings?.isInvestorCalculationDisabled
              ? intl.formatMessage({
                  id: 'fundDashboard.aggregateFundSummary',
                })
              : intl.formatMessage({
                  id: 'fundDashboard.aggregateIndividualSummary',
                })}
            <ToggleInvestorCalculation portfolioId="ALL" status={false} />
          </HeaderContent>
        </Card.Header>
        <Card.Body>
          <FundSummaryRow
            isLoading={isLoading}
            data={data}
            chartData={portfolios}
            investorCalculationEnabled={
              !portfolioInvestorSettings?.isInvestorCalculationDisabled
            }
          />
        </Card.Body>
      </Card>
    </FadeIn>
  )
}

const SummarySpecificPortfolio = ({ id }: { id: string }) => {
  const portfolios = useAppSelector(getPortfolios)

  const data = portfolios?.find((portfolio) => portfolio.id === id)

  if (!data || !hasFundSummaryData(data)) return null

  const { portfolioCompanies } = data

  const colors = getColorsForCompanies(portfolioCompanies)

  return (
    <FadeIn>
      <InvestPortfolioRow
        tabIndex={0}
        portfolioCompanies={portfolioCompanies}
        portfolio={data}
        isExpandable
        showDetail
        colorsForCompanies={colors}
        sharePortfolioSetting={data.sharePortfolioSetting}
      />
    </FadeIn>
  )
}

const FundSummary = () => {
  const { id } = useAppSelector(getCurrentPortfolio)
  const isAllFundsSelected = id === ALL_FUNDS

  return isAllFundsSelected ? (
    <SummaryAllFunds />
  ) : (
    <SummarySpecificPortfolio id={id} />
  )
}

export default FundSummary
