import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import IconButton from 'ui/IconButton'

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 4.2rem;
  padding: 0rem 2.2rem 0rem 2.4rem;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color('veryLightGray')};
  border-top: 1px solid ${color('veryLightGray')};

  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;
  line-height: normal;
`

export const CloseButton = styled(IconButton)`
  font-size: 1.6rem;
  cursor: pointer;
`
