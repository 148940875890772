import React, { useEffect, useState } from 'react'
import { useMessageContext } from 'stream-chat-react'

import { useCompanyQuery } from 'utils/hooks/queries/useCompanyQuery'
import { HoldingType } from 'utils/types/company'
import { ChatHoldingTopic } from './HoldingAttachment'
import HoldingAttachmentBox from './HoldingAttachmentBox'

interface CompanyAttachmentProps {
  companyId: string
  link: string
}

const CompanyAttachment: React.FC<CompanyAttachmentProps> = ({
  companyId,
  link,
}) => {
  const [holding, setHolding] = useState<ChatHoldingTopic>()
  const { message } = useMessageContext()
  const { data: company } = useCompanyQuery(companyId, { staleTime: 500 })

  useEffect(() => {
    if (company) {
      const fetchedHolding: ChatHoldingTopic = {
        id: company.id,
        name: company.name,
        image: company.primaryLogo.mediumLogo.url,
        link,
        type: HoldingType.COMPANY,
      }
      setHolding(fetchedHolding)
    }
  }, [company, link, message.id])

  return holding ? <HoldingAttachmentBox holding={holding} /> : null
}

export default CompanyAttachment
