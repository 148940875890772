import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.6rem;
  font-weight: 700;
  color: ${color('darkGray')};
  margin-left: 1.3rem;
`

export const Card = styled.article`
  box-shadow: 0 0 0 0.1rem ${color('veryLightGray')};
  border-radius: 0.8rem;
  padding: 1.6rem;
  display: flex;
  gap: 0.8rem;
  cursor: pointer;
  margin-bottom: 1.8rem;

  &:hover {
    box-shadow: 0 0 0 0.2rem ${color('veryLightBlue')};

    h2 {
      color: ${color('primaryBlue')};
    }

    > div:first-child {
      background: ${color('primaryBlue')};
    }
  }
`

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex: 1;
`

export const CardIcon = styled.div`
  width: 3.6rem;
  height: 3.6rem;
  background: ${color('lightGray')};
  color: ${color('white')};
  font-size: 2rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CardTitle = styled.h2`
  margin: 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: ${color('darkBlue')};
`

export const CardDescription = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: ${color('lightGray')};
  width: 25.3rem;
`
