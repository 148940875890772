import { useIntl } from 'react-intl'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips/BoxGroupsAndUsersChips'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import DropdownEntity from 'components/GroupsUsersAddInput/DropdownEntity'
import { getFrequencyNames } from 'utils/functions/metrics'
import useUserData from 'utils/hooks/useUserData'
import {
  AutoSuggestPortfolioCompanies,
  OptionalSpan,
} from 'containers/Metrics/CreateMetric/CreateMetric.styles'
import Dropdown from 'ui/Dropdown'
import InputLabel from 'ui/InputLabel/InputLabel'
import Separator from 'ui/Separator'
import { Input } from 'containers/UpdatesV2/components/commons'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'
import useEditFounderMetricForm from './useEditFounderMetricForm'

interface Props {
  isMetricEditable: boolean
  currentGroups: any
  onSelectGroup: (option: any) => void
  handlePressEnter: (inputValue: any, dropdownOptions: any) => Promise<void>
  handleRemoveGroup: (id: string) => void
  loadGroups: (groups: any) => void
  groupSearchText: string
}

const EditFounderMetricForm = ({
  isMetricEditable,
  currentGroups,
  groupSearchText,
  onSelectGroup,
  handlePressEnter,
  handleRemoveGroup,
  loadGroups,
}: Props) => {
  const intl = useIntl()
  const {
    nameField,
    frequencyField,
    shareGroupsField,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
  } = useEditFounderMetricForm({ currentGroups })

  const { isCurrentUserActingAsFounder, isFounderVerified } = useUserData()

  return (
    <>
      <Input
        id={nameField.name}
        name={nameField.name}
        error={buildFormError(errors.name, ErrorType.ERROR, true)}
        onBlur={handleBlur}
        title={intl.formatMessage({ id: 'metrics.name' })}
        label={intl.formatMessage({ id: 'metrics.name' })}
        placeholder={intl.formatMessage({ id: 'metrics.namePlaceholder' })}
        value={nameField.value}
        onChange={handleChange}
        withCounter
        customMaxCounterCharacters={MAX_COUNTER_CHARACTERS}
        counterSubtractNumber={nameField.value?.length}
        simulateIconSpace
        counterStyle={{
          right: '2rem',
          top: '1.2rem',
          opacity: 0.5,
        }}
        extraPaddingRight={1.5}
      />

      <Separator space="1rem" />

      <Dropdown
        id={frequencyField.name}
        name={frequencyField.name}
        label={intl.formatMessage({ id: 'metrics.frequency' })}
        placeholder={intl.formatMessage({ id: 'metrics.frequencyPlaceholder' })}
        onSelectOption={(_, value) => {
          setFieldValue(frequencyField.name, value)
        }}
        showBorder
        options={getFrequencyNames(intl)}
        error={buildFormError(errors.frequency, ErrorType.ERROR, true)}
        value={frequencyField.value}
        disabled={!isMetricEditable}
      />
      <AutoSuggestPortfolioCompanies>
        <ReadOnlyTooltip
          place="left"
          text={intl.formatMessage({ id: 'updates.readOnlyMetrics' })}
          disabled={!isFounderVerified && isCurrentUserActingAsFounder}
          topOffset={-10}
        >
          <>
            <InputLabel>
              {intl.formatMessage({ id: 'metrics.shareWith' })}
              <OptionalSpan>(optional)</OptionalSpan>
            </InputLabel>

            <Dropdown
              id={shareGroupsField.name}
              name={shareGroupsField.name}
              error={shareGroupsField.buildError()}
              onSelectOption={onSelectGroup}
              placeholder={intl.formatMessage({ id: 'metrics.addGroups' })}
              loadOptions={loadGroups}
              onPressEnter={handlePressEnter}
              async
              type="input"
              icon={['far', 'plus']}
              iconFontSize="1.8rem"
              clearOnSelect
              clearOnPressEnter={false}
              optionsTitle={
                !groupSearchText
                  ? intl.formatMessage({ id: 'metrics.recentlyUsedGroups' })
                  : null
              }
              highlightEnabled
              maxListHeight="40rem"
              dropdownOptionPadding="0"
              showLoadingIndicator
              getOption={(entity) => ({
                value: {
                  type: entity.type,
                  name: entity.name,
                  handle: entity.handle,
                  logo: entity.logo?.url,
                },
                name: entity.name,
                id: entity.id,
              })}
              formatSelectedOptionComponent={(option) => (
                <DropdownEntity
                  entity={option.value}
                  isSelected={option.value}
                />
              )}
              highlightSelectedOption={false}
              refetchOptionsOnDropdownOpen
            />
          </>
        </ReadOnlyTooltip>

        <BoxGroupsAndUsersChips
          items={currentGroups?.map((item) => ({
            ...item,
            fromMetric: true,
            canDelete: item.canDelete,
          }))}
          handleDelete={handleRemoveGroup}
        />
      </AutoSuggestPortfolioCompanies>

      <Separator space="3.6rem" />
    </>
  )
}

export default EditFounderMetricForm
