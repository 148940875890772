import { FormattedMessage } from 'react-intl'

import Toast from 'components/Toast'
import FileUploadRow from 'components/UploadsMenu/components/FileUploadRow'
import Button from 'ui/Button'
import { useFilePicker } from 'utils/hooks/files/useFilePicker'
import {
  useFileUploadedEventListener,
  useUploadFile,
} from 'utils/hooks/useUploadFile'
import { FileUploadStatus } from 'utils/types/uploads'

import {
  BrowseYourFiles,
  Container,
  DropFileMessage,
  FileDropper,
  FileUploaderInput,
  UploadingFiles,
  UploadsContainer,
} from './AddYourFilesStep.styles'
import {
  Body,
  Footer,
  FooterButtonsContainer,
} from '../../OnboardingModal.styles'

interface AddYourFilesStepProps {
  goBack: () => void
  completeStep: () => void
  goToNextStep: () => void
  isStepCompleted: boolean
}

const FILE_UPLOAD_CONTEXT_ID = 'onboarding-modal'

const AddYourFilesStep = ({
  goBack,
  completeStep,
  goToNextStep,
  isStepCompleted,
}: AddYourFilesStepProps) => {
  const { uploads, cancelUpload, restartUpload, deleteUpload, uploadFiles } =
    useUploadFile()

  const onUploadFiles = ({
    acceptedFiles,
    rejectedFiles,
  }: {
    acceptedFiles: File[]
    rejectedFiles: File[]
  }) => {
    if (rejectedFiles.length) {
      Toast.displayIntl('errors.fileUpload', 'error')
    } else {
      uploadFiles(acceptedFiles, FILE_UPLOAD_CONTEXT_ID)
    }
  }

  const { fileInputRef, openFilePicker, onSelectFiles } = useFilePicker({
    onFilesSelected: onUploadFiles,
  })

  useFileUploadedEventListener(FILE_UPLOAD_CONTEXT_ID, completeStep)

  return (
    <>
      <Body>
        <Container>
          <FileDropper
            hasUploads={!!uploads.length}
            onDropFiles={onUploadFiles}
            defaultZIndex={1}
          >
            <DropFileMessage>
              <FormattedMessage
                id="onboardingModal.dropYourFilesHere"
                values={{
                  span: ((text: string) => (
                    <BrowseYourFiles onClick={openFilePicker}>
                      {text}
                    </BrowseYourFiles>
                  )) as any,
                }}
              />
            </DropFileMessage>
          </FileDropper>
          <FileUploaderInput
            ref={fileInputRef}
            type="file"
            autoComplete="off"
            onChange={onSelectFiles}
            multiple
          />

          {!!uploads.length && (
            <>
              <UploadingFiles>
                <FormattedMessage id="files.uploadingFiles" />
              </UploadingFiles>

              <UploadsContainer>
                {uploads.map((upload, index) => (
                  <FileUploadRow
                    key={upload.id}
                    fileName={upload.file.name}
                    timeLeft={upload.uploadTimeLeft}
                    uploadState={upload.status}
                    isLast={index === uploads.length - 1}
                    hasActionButtons={
                      upload.status === FileUploadStatus.UPLOADING ||
                      upload.status === FileUploadStatus.CANCELED
                    }
                    onCancelUpload={() => cancelUpload(upload.id)}
                    onRestartUpload={() => restartUpload(upload.id)}
                    onDeleteUpload={() => deleteUpload(upload.id)}
                  />
                ))}
              </UploadsContainer>
            </>
          )}
        </Container>
      </Body>

      <Footer>
        <Button onClick={goBack}>
          <FormattedMessage id="general.backText" />
        </Button>
        <FooterButtonsContainer>
          {!isStepCompleted && (
            <Button onClick={goToNextStep}>
              <FormattedMessage id="general.skipStep" />
            </Button>
          )}
          <Button onClick={goToNextStep} primary disabled={!isStepCompleted}>
            <FormattedMessage id="general.continue" />
          </Button>
        </FooterButtonsContainer>
      </Footer>
    </>
  )
}

export default AddYourFilesStep
