import React from 'react'
import { useField, useFormikContext } from 'components/Form/hooks'
import UIDropdown from 'ui/Dropdown'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType } from 'utils/types/common'

interface DropdownProps {
  name: string
  [x: string]: any
}

const Dropdown: React.FC<DropdownProps> = React.forwardRef(
  ({ name, ...rest }, ref) => {
    const [fields, meta, helpers] = useField(name)
    const formik = useFormikContext()

    return (
      <UIDropdown
        value={fields.value}
        onChange={fields.onChange}
        onSelectOption={(__, value) => {
          helpers.setValue(value)
          setTimeout(() => {
            formik.handleBlur({ target: { name: fields.name } })
          })
        }}
        error={buildFormError(meta.error, ErrorType.ERROR, meta.touched)}
        ref={ref}
        {...rest}
      />
    )
  }
)

export default Dropdown
