import styled from 'styled-components/macro'
import { css } from 'styled-components'

export const Container = styled.div<{ isSelectingSuggestedUpdates: boolean }>`
  width: 100%;
  max-width: 90rem;
  min-width: 0;
  ${({ isSelectingSuggestedUpdates }) =>
    isSelectingSuggestedUpdates &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`
