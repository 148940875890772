import { useCallback, useEffect, useRef, useState } from 'react'

import { useContents } from 'utils/hooks/contents/useContents'
import { MixedContent } from 'utils/types/files'
import { useStorageUsage } from 'components/StorageUsage/useStorageUsage'
import { SortCriteria } from 'components/SortBy/SortBy'
import useContentFilter from 'utils/hooks/contents/filters/useContentFilter'
import useSort from 'utils/hooks/useSort'
import { ContentFilters } from 'api/ContentService'
import { FileViewEnum } from 'components/FileViewSelector'

const useModalBrowseFiles = (
  isOpen: boolean,
  onSelectFiles: (files: MixedContent[]) => void,
  closeModal: () => void,
  filesSortCriteria: SortCriteria[],
  allowMultipleFiles: boolean,
  onlyFileContents: boolean
) => {
  const [selectedFiles, setSelectedFiles] = useState<MixedContent[]>([])
  const scrollRef = useRef<HTMLDivElement>(null)
  const { sortCriteria, initialCriteria, onChangeSortBy, currentSort } =
    useSort(filesSortCriteria)

  const { filters, search, debouncedSearch, handlers } = useContentFilter()
  const [selectedGridSize, setSelectedGridSize] = useState<FileViewEnum>(
    FileViewEnum.SMALL
  )

  const newFilters: ContentFilters = {
    ...filters,
    name: debouncedSearch,
    orderBy: currentSort.orderBy,
    direction: currentSort.direction,
    uploadModal: true,
    onlyFileContents,
  }

  const {
    contents,
    isLoading,
    isFetching,
    infiniteScrollRef,
    setInfiniteScrollEnabled,
  } = useContents({
    filters: newFilters,
  })
  const { hasExceededStorage } = useStorageUsage()

  useEffect(() => {
    if (isOpen) {
      setInfiniteScrollEnabled(true)
    } else {
      setTimeout(
        () => handlers.onClearFilters({ isCleaningFromDrawer: false }),
        300
      )
      setInfiniteScrollEnabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onClickFile = useCallback(
    (file: MixedContent) => {
      const index = selectedFiles.findIndex((f) => f.id === file.id)
      if (index === -1) {
        if (!allowMultipleFiles) {
          setSelectedFiles([file])
        } else {
          setSelectedFiles([...selectedFiles, file])
        }
      } else {
        const newSelectedFiles = [...selectedFiles]
        newSelectedFiles.splice(index, 1)
        setSelectedFiles(newSelectedFiles)
      }
    },
    [allowMultipleFiles, selectedFiles]
  )

  const handleConfirm = () => {
    closeModal()

    onSelectFiles(selectedFiles)
    setTimeout(() => {
      setSelectedFiles([])
    })
  }

  const handleCancel = () => {
    setSelectedFiles([])
    closeModal()
  }

  const handleGridSizeChange = (type: FileViewEnum) => {
    setSelectedGridSize(type)
  }

  return {
    contents,
    isLoading,
    isFetching,
    infiniteScrollRef,
    scrollRef,
    selectedFiles,
    hasExceededStorage,
    search,
    debouncedSearch,
    filters: newFilters,
    sorting: {
      sortCriteria,
      initialCriteria,
      onChangeSortBy,
    },
    selectedGridSize,
    handlers: {
      onClickFile,
      onSelectFiles,
      handleConfirm,
      handleCancel,
      handleGridSizeChange,
      ...handlers,
    },
  }
}

export default useModalBrowseFiles
