import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const HorizontalContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 0.8rem;
  width: 100%;
`

export const Sender = styled.span`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${color('darkGray')};
  text-align: left;
`
