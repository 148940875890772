import styled from 'styled-components'

export const ExpandableRowContainer = styled.div`
  position: relative;
  background-color: #f5f5fa;
  outline: solid 0rem #f0f0f0;
  transition: outline 0.1s linear;

  &:hover {
    .rowMenu {
      display: block;
    }
  }
`

export const Expandable = styled.div`
  height: auto;
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
  background-color: #f5f5fa;
`

export const ZeroStateContainer = styled.div`
  background-color: #f5f5fa;
`
