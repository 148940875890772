import { LinkWrapper, NavigationItem } from '../Breadcrumb.styles'

interface InitialLinkProps {
  isActive: boolean
  label: string
  onClickHandler: () => void
}

const InitialLink = ({ isActive, label, onClickHandler }: InitialLinkProps) => {
  return (
    <NavigationItem>
      <LinkWrapper isActive={isActive} onClick={onClickHandler}>
        {label}
      </LinkWrapper>
    </NavigationItem>
  )
}

export default InitialLink
