/* eslint-disable react/destructuring-assignment */

import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { PaginatorWrapper, PaginatorContainer } from './Paginator.styles'

const Paginator = ({ label, onLoadMoreRows, embeddedPaginator }) => {
  return (
    <PaginatorWrapper>
      <PaginatorContainer
        type="button"
        onClick={onLoadMoreRows}
        embeddedPaginator={embeddedPaginator}
      >
        <FontAwesomeIcon icon={['far', 'arrow-down']} />
        {label}
      </PaginatorContainer>
    </PaginatorWrapper>
  )
}

Paginator.propTypes = {
  label: PropTypes.string.isRequired,
  onLoadMoreRows: PropTypes.func.isRequired,
  embeddedPaginator: PropTypes.bool,
}

Paginator.defaultProps = {
  embeddedPaginator: false,
}

export default Paginator
