import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useIntl } from 'react-intl'

import TagService from 'api/TagService'

import { getCurrentGroupName } from 'selectors/auth'
import { Tag } from 'utils/types/update'
import IconButton from 'ui/IconButton'
import Dropdown from 'ui/Dropdown'

interface Props {
  onAddTag: (tag: Tag, resultsFromSearch: Tag[]) => void
  validationWhenAddingTag: (tag: Tag) => boolean
  tagInputRef?: any
  tagsList: Tag[]
}

const TagsInput = ({
  onAddTag,
  validationWhenAddingTag,
  tagInputRef,
  tagsList,
}: Props) => {
  const groupName = useAppSelector(getCurrentGroupName)
  const intl = useIntl()
  const idNotIn = useRef(tagsList?.map((tag) => tag.id))
  const [resultsFromSearch, setResultsFromSearch] = useState<Tag[]>([])

  useEffect(() => {
    idNotIn.current = tagsList?.map((tag) => tag.id)
  }, [tagsList])

  const onSelectTag = (selectedTag) => {
    document.getElementById('tags-add')?.blur()
    const tagNameValue = selectedTag.name ?? selectedTag

    const doesTagExistInList = tagsList.find(
      (tag) =>
        tag.name?.trim() === selectedTag.name?.trim() ||
        tag.name?.trim() === selectedTag
    )

    if (!doesTagExistInList) {
      if (!validationWhenAddingTag) {
        onAddTag(tagNameValue, resultsFromSearch)
      } else {
        const isValidTag = validationWhenAddingTag(
          tagNameValue.replace(/\s/g, '')
        )

        if (isValidTag) {
          onAddTag(tagNameValue, resultsFromSearch)
        }
      }
    }
  }

  const searchTags = useCallback(
    async (value) => {
      const {
        data: { result, entities },
      } = await TagService.getTags(value, idNotIn.current)
      const tagsFromSearch = result.map((tagId) => entities?.tags?.[tagId])
      setResultsFromSearch(tagsFromSearch)

      return [
        {
          id: 'group-options',
          icon: ['far', 'tag'],
          group: `${groupName} ${intl.formatMessage({
            id: 'tags.tagsTitle',
          })}`,
          subOptions: result.map((tagId) => {
            return entities?.tags?.[tagId]
          }),
        },
      ]
    },
    [groupName, intl, idNotIn]
  )

  return (
    <Dropdown
      ref={tagInputRef}
      id="tags-add"
      name="companies"
      async
      loadOptions={searchTags}
      getOption={(entity) => ({ label: entity.name, id: entity.id })}
      onSelectOption={(_, __, value) => {
        onSelectTag(value)
      }}
      placeholder={intl.formatMessage({ id: 'tags.addTagPlaceholder' })}
      type="select"
      clearOnSelect
      addOptionEnabled
      withGroupOptions
      hideGroupLabelIfEmpty
      resetSearchOnFocus
      onAddOption={onSelectTag}
      capitalizeOptions={false}
      optionTitlePadding="0rem 0.8rem 1.1rem 0.8rem"
      attachToDocument
      showLoadingIndicator
      dropdownListWidth="34rem"
      dropdownContentWidth="20rem"
      topOffset={5}
      alignLeft
      customButton={<IconButton icon="tag" color="white" buttonSize="3.6rem" />}
      maxLength={255}
      withCounter
      counterStyle={{ right: '-11.5rem' }}
      simulateIconSpaceForSearchInput
    />
  )
}

export default TagsInput
