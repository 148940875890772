import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { StreamStylesOverrides } from 'containers/Chat/ChatStreamStylesOverrides'
import { Container as MessageInputContainer } from 'containers/Chat/components/MessageInput/MessageInput.styles'
import {
  Container as MessageContainer,
  ContainerAttributes as MessageContainerAttributes,
  Avatar as MessageAvatar,
  DummyAvatar as MessageDummyAvatar,
  MessageContainer as MessageTextContainer,
} from 'containers/Chat/components/Message/Message.styles'
import { Sender as MessageSender } from 'containers/Chat/components/SentInformation/SentInformation.styles'
import {
  Container as TypingIndicatorContainer,
  Dots,
} from 'containers/Chat/components/TypingIndicator/TypingIndicator.styles'
import { EmojiPickerContainer as EditMessageEmojiPickerContainer } from 'containers/Chat/components/EditMessageInput/EditMessageInput.styles'
import { CHAT_POPUP_HEADER_HEIGHT } from 'utils/constants/chat'
import { maxSize, size } from 'utils/constants/breakpoint'
import { CLOSABLE_THREAD_AREA_WIDTH } from '../ClosableThreadArea'
import { PopupBorderStyles } from '../../ChatPopup.styles'

const ThreadEmojiPickerStyles = css`
  .str-chat__input--emojipicker {
    left: initial;
  }

  ${EditMessageEmojiPickerContainer} {
    bottom: -11.4rem;
    left: -5rem;

    .str-chat__input--emojipicker,
    .emoji-mart.emoji-mart-light {
      width: 31.4rem !important; // To override styles from emoji-mart
    }

    .emoji-mart-scroll {
      max-height: 23rem;
      overflow-x: auto;
    }
  }

  ${MessageContainer}[${MessageContainerAttributes.groupStyleAttr}="top"] {
    ${EditMessageEmojiPickerContainer} {
      left: -2.5rem;
    }
  }

  @media ${size.sm} {
    ${EditMessageEmojiPickerContainer} {
      left: -1.3rem;
    }

    ${MessageContainer}[${MessageContainerAttributes.groupStyleAttr}="top"] {
      ${EditMessageEmojiPickerContainer} {
        left: 1.2rem;
      }
    }
  }
`

const ThreadStyles = css`
  .str-chat__thread-container {
    position: absolute;
    height: calc(100% - ${CHAT_POPUP_HEADER_HEIGHT}px);
    width: calc(100% - ${CLOSABLE_THREAD_AREA_WIDTH});
    left: ${CLOSABLE_THREAD_AREA_WIDTH};
    top: calc(${CHAT_POPUP_HEADER_HEIGHT}px - 1px);
    z-index: 4;

    &.str-chat__thread {
      .str-chat__main-panel-inner,
      .str-chat__list--thread {
        height: 100%;
      }
    }

    .str-chat__list .str-chat__message-list-scroll {
      .str-chat__parent-message-li {
        margin-bottom: 0;
      }

      .str-chat__ul {
        margin-top: 0;
      }

      .str-chat__thread-list {
        overflow: visible;
      }
    }

    ${MessageContainer} {
      padding-left: 2.4rem;
      padding-right: 2.4rem;
      gap: 0.4rem;

      &[${MessageContainerAttributes.initialMessageAttr}='true'] {
        padding-top: 1.4rem;
      }
    }

    ${MessageTextContainer} {
      max-width: 100%;
    }

    ${MessageInputContainer} {
      width: 100%;
    }

    ${ThreadEmojiPickerStyles}
  }
`

export const Container = styled.div`
  ${StreamStylesOverrides}
  ${PopupBorderStyles}

  .str-chat__main-panel {
    width: initial;
    position: relative;
    flex: 1;
  }

  .str-chat__list .str-chat__message-list-scroll .str-chat__ul {
    margin-top: 2.2rem;
    margin-bottom: 1.6rem;
  }

  .str-chat__reaction-selector .str-chat__message-reactions-options {
    gap: 0.4rem;
  }

  height: 47rem;

  ${MessageInputContainer} {
    padding: 1.2rem 1.6rem;
    padding-top: 0.1rem;
  }

  ${MessageContainer} {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    gap: 0.4rem;

    :hover {
      ${MessageDummyAvatar} {
        visibility: hidden;
      }
    }
  }

  ${MessageContainer}[${MessageContainerAttributes.groupStyleAttr}="bottom"],
  ${MessageContainer}[${MessageContainerAttributes.groupStyleAttr}="single"] {
    padding-bottom: 0.6rem;

    @media ${maxSize.sm} {
      .str-chat__message-reaction-selector {
        right: -12.1rem;
        bottom: -5.7rem;
      }
    }
  }

  ${MessageAvatar} {
    width: 2.1rem;
    height: 2.1rem;
    font-size: 1rem;
  }

  ${MessageDummyAvatar} {
    width: 2.1rem;
    height: 2.1rem;
  }

  ${MessageSender} {
    padding-top: 0.2rem;
  }

  ${MessageTextContainer} {
    max-width: 80%;
  }

  ${TypingIndicatorContainer} {
    bottom: 0rem;
    left: 0;
    padding-left: 2rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    background: ${color('cloud')};
    width: 100%;

    ${Dots} {
      margin-left: 1rem;
    }
  }

  ${EditMessageEmojiPickerContainer} {
    bottom: -21.4rem;
  }

  @media ${maxSize.sm} {
    .str-chat__input--emojipicker {
      right: 0;
    }

    ${EditMessageEmojiPickerContainer} {
      left: -4.2rem;
    }

    ${MessageContainer}[${MessageContainerAttributes.groupStyleAttr}="top"] {
      ${EditMessageEmojiPickerContainer} {
        left: -1.7rem;
      }
    }
  }

  @media ${size.sm} {
    .str-chat__input--emojipicker {
      right: 1.6rem;
    }
  }

  ${ThreadStyles}
`
