import { useState, useEffect, useRef, useCallback } from 'react'

export const useContextMenu = () => {
  const [clicked, setClicked] = useState(false)
  const [points, setPoints] = useState({
    x: 0,
    y: 0,
  })
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClick = () => setClicked(false)
    const handleContextMenu = (e: MouseEvent) => {
      if (!contextMenuRef.current?.contains(e.target as Node)) {
        setClicked(false)
      }
    }

    document.addEventListener('click', handleClick)
    document.addEventListener('contextmenu', handleContextMenu)

    return () => {
      document.removeEventListener('click', handleClick)
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])

  const hideContextMenu = useCallback(() => setClicked(false), [])

  return {
    contextMenuRef,
    menuRef,
    clicked,
    points,
    setClicked,
    setPoints,
    hideContextMenu,
  }
}
