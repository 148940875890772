import React, { useRef, useLayoutEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { EmailContent } from 'utils/types/files'
import { Nullable } from 'utils/types/common'

const MODAL_VERTICAL_PADDING = 64
const MODAL_ELEMENTS_GAP = 18

const FONT_TAG = `<link
  href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap"
  rel="stylesheet"
/>`

const GLOBAL_STYLES = `<style>
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #4A4A68;
    margin: 0;
  }

  a {
    color: #194590;
    font-weight: 700;
  }
</style>
`

const replaceContentIdSourcesWithUrls = (
  sanitizedContent: string,
  emailContent: EmailContent
) => {
  let newContentString = sanitizedContent
  const regex = /<img[^>]+src="cid:([^">]+)"[^>]*>/g
  const matches = newContentString.match(regex)

  if (matches) {
    matches.forEach((match) => {
      const cid = match.match(/cid:([^">]+)/)?.[1]
      const src = emailContent.s3Contents.find(
        (attachment) => attachment.metadata?.contentId === cid
      )?.url

      newContentString = newContentString.replace(
        match,
        match.replace(`cid:${cid}`, src || '')
      )
    })
  }

  return newContentString
}

interface Props {
  emailContent: EmailContent
  container: Nullable<HTMLDivElement>
  detailsContainer: Nullable<HTMLDivElement>
}

const EmailFrame: React.FC<Props> = ({
  emailContent,
  container,
  detailsContainer,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [height, setHeight] = useState(0)
  const [maxHeight, setMaxHeight] = useState(0)

  useLayoutEffect(() => {
    if (iframeRef.current && emailContent) {
      let sanitizedContent = replaceContentIdSourcesWithUrls(
        DOMPurify.sanitize(emailContent.data.text),
        emailContent
      )
      sanitizedContent = FONT_TAG + GLOBAL_STYLES + sanitizedContent

      const iframeDocument =
        iframeRef.current.contentDocument ||
        iframeRef.current.contentWindow?.document

      iframeDocument?.open()
      iframeDocument?.write(sanitizedContent)
      iframeDocument?.close()

      setHeight(iframeDocument?.body.scrollHeight || 0)
      setMaxHeight(
        (container?.clientHeight || 0) -
          (detailsContainer?.clientHeight || 0) -
          MODAL_ELEMENTS_GAP -
          MODAL_VERTICAL_PADDING
      )
    }
  }, [emailContent, container, detailsContainer])

  return (
    <iframe
      ref={iframeRef}
      title="Email Content"
      width="100%"
      style={{
        border: 'none',
        height,
        maxHeight,
      }}
    />
  )
}

export default EmailFrame
