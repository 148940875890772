import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { getUser } from 'selectors/auth'
import { Channel } from 'stream-chat'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getChatTitle } from '../helpers'
import { ChannelData, StreamChatType } from '../types'

export const useChatTitle = <ChannelType extends ChannelData>(
  channel?: Channel<StreamChatType<ChannelType>>
) => {
  const user = useAppSelector(getUser)
  const intl = useIntl()

  return useMemo(
    () => getChatTitle(intl, channel, user.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channel?.data?.name, channel?.state.members]
  )
}
