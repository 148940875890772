import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import Input from 'ui/Input'
import { isEnterKeyCode, isEscCode } from 'utils/functions/keyboardEvents'

import styles from './SearchInput.module.scss'

const SearchInput = ({
  handleChange,
  handleFocus,
  handleBlur,
  value,
  placeholder,
  className,
}) => {
  const inputRef = useRef(null)

  const handleInputChange = (event) => {
    handleChange(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (isEnterKeyCode(event)) {
      handleChange(event.target.value)
    }
    if (isEscCode(event)) {
      handleChange('')
      inputRef.current.blur()
    }
  }

  return (
    <div className={`${styles.searchInputWrapper} ${className}`}>
      <Input
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        ref={inputRef}
        value={value}
        icon={['fal', 'search']}
        iconFontSize="1.6rem"
      />
    </div>
  )
}

SearchInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
}
SearchInput.defaultProps = {
  placeholder: '',
  value: '',
  className: '',
  handleFocus: () => {},
  handleBlur: () => {},
}

export default SearchInput
