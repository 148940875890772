/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events , jsx-a11y/interactive-supports-focus */
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import {
  HOME_PAGE_LINK,
  TERMS_OF_USE_LINK,
  PRIVACY_POLICY_LINK,
  STATUS_PAGE_LINK,
  HELP_PAGE_LINK,
} from 'utils/constants/links'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'

import packageJson from '../../../package.json'

import styles from './Footer.module.scss'

const Footer = () => {
  const intl = useIntl()
  const { matches: isBeamerDisabled } = useMediaQuery(Media.MAX_MD)

  return (
    <footer className={styles.footer}>
      <ul className={styles.copyrightList}>
        <li>
          <FormattedMessage
            id="footer.copyright"
            values={{ year: new Date().getFullYear() }}
          />
        </li>
        <li>-</li>
        <li>
          <FormattedMessage
            id="footer.version"
            values={{ version: packageJson.version }}
          />
        </li>
      </ul>
      <ul className={styles.linksList}>
        <li>
          <a rel="noopener noreferrer" href={HOME_PAGE_LINK} target="_blank">
            <FormattedMessage id="footer.website" />
          </a>
        </li>
        <li>|</li>
        <li>
          <a
            rel="noopener noreferrer"
            href={PRIVACY_POLICY_LINK}
            target="_blank"
          >
            <FormattedMessage id="footer.privacy" />
          </a>
        </li>
        <li>|</li>
        <li>
          <a rel="noopener noreferrer" href={TERMS_OF_USE_LINK} target="_blank">
            <FormattedMessage id="footer.terms" />
          </a>
        </li>
        <li>|</li>
        <li>
          <a rel="noopener noreferrer" href={HELP_PAGE_LINK} target="_blank">
            <FormattedMessage id="footer.help" />
          </a>
        </li>
        <li>|</li>
        <li>
          <a rel="noopener noreferrer" href={STATUS_PAGE_LINK} target="_blank">
            <FormattedMessage id="footer.status" />
          </a>
        </li>
        {!isBeamerDisabled && (
          <>
            <li>|</li>
            <li>
              <a
                role="button"
                onClick={() => {
                  window.Beamer?.show()
                }}
              >
                <FormattedMessage id="footer.newFeatures" />
              </a>
            </li>
          </>
        )}
        <li>|</li>
        <li>
          <a
            rel="noopener noreferrer"
            href={`mailto:${intl.messages['footer.supportEmail']}`}
            target="_blank"
          >
            <FormattedMessage id="footer.contact" />
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
