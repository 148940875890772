import { DealSummaryChartData } from 'api/PortfolioService'
import CardSummaryChart from 'containers/PortfolioDetail/CardSummary/Chart/CardSummaryChart'
import SkeletonBarChart from 'components/Skeletons/components/SkeletonBarchart'
import { getDealChartColor } from 'utils/functions/portfolios'
import DealPortfolioBarTooltip from './DealPortfolioBarTooltip'

interface Props {
  isLoading: boolean
  dealPortfolioChartData: DealSummaryChartData
  showDealChart: boolean
}

const DealPortfolioBarChart = ({
  isLoading,
  dealPortfolioChartData,
  showDealChart,
}: Props) => {
  if (isLoading) return <SkeletonBarChart />

  return showDealChart ? (
    <CardSummaryChart<DealSummaryChartData>
      chartData={dealPortfolioChartData}
      getColorFn={getDealChartColor}
      showXYAxis={
        dealPortfolioChartData[0].totalInvested > 0 ||
        dealPortfolioChartData[1].totalValue > 0
      }
      ChartTooltip={DealPortfolioBarTooltip}
      withCustomizedAxisTick={false}
    />
  ) : null
}

export default DealPortfolioBarChart
