import UpdateIconFactory from 'components/UpdateIconFactoryV2'
import { UpdateEntityType } from 'components/UpdatesFeedV2/types'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { getCurrentGroupId, isActingAsFounder } from 'selectors/auth'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { getPortfolioRoute } from 'utils/functions/portfolios'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import { isIndexUpdate } from 'utils/functions/suggestedUpdates'
import { getUpdatePortfolio } from 'utils/functions/updates'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  FundManagerRoutes,
  SetRouteConfigurationPayload,
  setRouteConfiguration,
} from 'reducers/breadcrumbSlice'
import {
  getHoldingImage,
  HoldingType,
  isCompanyHolding,
  isHoldingFromGroup,
} from 'utils/types/company'
import { IndexPortfolio } from 'utils/types/portfolios'
import { EmailIndexUpdate, IndexUpdate } from 'utils/types/update'
import { useSelectUpdatesContext } from '../../contexts/SelectUpdates/SelectableCardsContext'
import { useUpdatesFeedContext } from '../../UpdateFeedContext'
import { IconWrapper } from './FeedIcon.styles'
import { useClickableIcon } from './useClickableIcon'

type Props = {
  indexUpdate: IndexUpdate
}

const FeedIcon: React.FC<Props> = ({ indexUpdate }) => {
  const selectCtx = useSelectUpdatesContext()
  const feedCtx = useUpdatesFeedContext()
  const { isSelectingSuggestedUpdates, enabled: isClickable } = selectCtx
  const useDraftColor = isSelectingSuggestedUpdates
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const history = useHistory()
  const currentPortfolio = useAppSelector(getCurrentPortfolio) as IndexPortfolio
  const isFounder = useAppSelector(isActingAsFounder)
  const dispatch = useAppDispatch()

  const { handleOnHoldingClick } = useOnClickRedirect()

  const handleDispatchUserRoutes = useCallback(
    ({ route, initialLinkRedirect }: SetRouteConfigurationPayload) => {
      dispatch(
        setRouteConfiguration({
          route,
          initialLinkRedirect,
        })
      )
    },
    [dispatch]
  )

  const handleSetDiscoverRoute = () => {
    handleDispatchUserRoutes({
      route: FundManagerRoutes.DISCOVER,
      initialLinkRedirect: '/companies',
    })
  }

  const getEntityIconProps = () => {
    if (!isIndexUpdate(indexUpdate) || !feedCtx.showEntityFeedIcons) return {}

    const isCurrentGroupHolding =
      indexUpdate.holding &&
      isHoldingFromGroup(indexUpdate.holding, currentGroupId)

    const isPrivateCompany =
      !isFounder &&
      isCurrentGroupHolding &&
      isCompanyHolding(indexUpdate.holding!) &&
      !indexUpdate.holding.fund

    const isPortfolioDetailUpdate = feedCtx.entityId === indexUpdate.holding?.id

    if (
      indexUpdate.holding?.holdingType === HoldingType.FUND ||
      indexUpdate.holding?.holdingType === HoldingType.DEAL
    ) {
      const hasHoldingDraft = !!indexUpdate?.holdingDraft

      if (hasHoldingDraft) {
        return {
          logo: getHoldingImage(indexUpdate.holdingDraft!),
          tooltip: indexUpdate.holdingDraft!.name,
          onClickLogo: () => {
            handleSetDiscoverRoute()
            handleOnHoldingClick(indexUpdate.holdingDraft!)
          },
        }
      }

      if (isPortfolioDetailUpdate) {
        return {}
      }

      if (isCurrentGroupHolding) {
        // Fund profile update from my group
        return {
          portfolioType: PortfolioTypes.FUND,
          tooltip: indexUpdate.holding?.name,
          onClickLogo: () =>
            history.push(
              getPortfolioRoute(
                handleDispatchUserRoutes,
                PortfolioTypes.FUND,
                indexUpdate.holding?.id
              )
            ),
        }
      }

      // Public fund profile update from other group
      const propertyObject = {
        tooltip: indexUpdate.holding!.name,
        onClickLogo: () => {
          handleSetDiscoverRoute()
          handleOnHoldingClick(indexUpdate.holding!)
        },
      }

      if (indexUpdate.holding?.holdingType === HoldingType.DEAL) {
        return {
          ...propertyObject,
          portfolioType: PortfolioTypes.DEAL,
        }
      }

      return {
        ...propertyObject,
        logo: getHoldingImage(indexUpdate.holding!),
      }
    }

    if (indexUpdate.holding?.holdingType === HoldingType.COMPANY) {
      if (isCurrentGroupHolding && !isPrivateCompany) {
        // My organization update

        const isMyOrganizationInThePortfolio =
          feedCtx.entityType === UpdateEntityType.PORTFOLIO_COMPANIES &&
          feedCtx.entityId === currentPortfolio?.id

        const hasHoldingDraft = !!indexUpdate?.holdingDraft

        if (isMyOrganizationInThePortfolio) {
          return {
            logo: getHoldingImage(indexUpdate.holding!),
            tooltip: indexUpdate.holding!.name,
            onClickLogo: () => {
              handleSetDiscoverRoute()
              handleOnHoldingClick(indexUpdate.holding!)
            },
          }
        }

        if (hasHoldingDraft) {
          return {
            logo: getHoldingImage(indexUpdate.holdingDraft!),
            tooltip: indexUpdate.holdingDraft!.name,
            onClickLogo: () => {
              handleSetDiscoverRoute()
              handleOnHoldingClick(indexUpdate.holdingDraft!)
            },
          }
        }

        return {}
      }

      // Public company profile update from other group, or client private company update
      return {
        logo: getHoldingImage(indexUpdate.holding!),
        tooltip: indexUpdate.holding!.name,
        onClickLogo: () => {
          handleSetDiscoverRoute()
          handleOnHoldingClick(indexUpdate.holding!)
        },
      }
    }

    const portfolio = getUpdatePortfolio(indexUpdate)

    // Portfolio update
    if (portfolio) {
      if (feedCtx.entityType === UpdateEntityType.PORTFOLIO_COMPANIES) {
        // Portfolio update from portfolio detail
        return {}
      }

      return {
        portfolioType: portfolio.type,
        tooltip: portfolio.name,
        onClickLogo: () =>
          history.push(
            getPortfolioRoute(
              handleDispatchUserRoutes,
              portfolio.type,
              portfolio.id
            )
          ),
      }
    }

    return {}
  }

  const { isHover, isSelected, onMouseOver, onMouseLeave, onClickIcon } =
    useClickableIcon(indexUpdate as IndexUpdate, isClickable)

  return (
    <IconWrapper isSelectingSuggestedUpdates={isSelectingSuggestedUpdates}>
      <UpdateIconFactory
        updateType={indexUpdate.updateType}
        isDraft={
          (indexUpdate as EmailIndexUpdate).draft ||
          indexUpdate.isDraftUpdate ||
          useDraftColor
        }
        clickable={isClickable}
        isSelected={isSelected}
        isHover={isHover}
        onClick={onClickIcon}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        {...getEntityIconProps()}
      />
    </IconWrapper>
  )
}

export default FeedIcon
