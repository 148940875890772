import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const ChartCriteria = styled.div`
  display: flex;

  @media ${maxSize.sm} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
`

export const CriteriaButton = styled.div<{
  isActive: boolean
  borderRadius?: string
}>`
  align-items: center;
  background-color: ${color('lightGray', 0.1)};
  border-radius: ${({ borderRadius }) => borderRadius ?? '0'};
  color: ${({ isActive }) => (isActive ? color('primaryBlue') : color('gray'))};
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  height: 3.7rem;
  padding: 0 1.6rem 0 1.6rem;
  user-select: none;

  ${({ isActive }) =>
    isActive &&
    css`
      border: 0.1rem solid ${color('primaryBlue')};
    `}

  & + & {
    border-left: ${({ isActive }) =>
      isActive
        ? `0.1rem solid ${color('primaryBlue')}`
        : `0.1rem solid ${color('veryLightBlue40')}`};
  }

  @media ${maxSize.sm} {
    justify-content: center;
  }
`
