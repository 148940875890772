import { getAddedAndRemovedEntities } from 'utils/functions/utils'
import { ChangeSetFieldsType } from '../logs-builders/LogsBuilder'
import { ChangeSet, EventType, FieldName, Log } from '../types'
import { BackendEvent, BackendEventsMapper } from './BackendEventsMapper'

interface InvestorEventsMapperParams {
  investorName: string
}

export class InvestorEventsMapper<
  T extends ChangeSetFieldsType
> extends BackendEventsMapper<T> {
  private investorName: string

  constructor(attribute: FieldName<T>, params: InvestorEventsMapperParams) {
    super(attribute)
    this.investorName = params.investorName
  }

  getCustomEventLog(event: BackendEvent<T>, type: EventType): Log<T> {
    return super.buildLog(
      {
        ...event,
        changeset: {
          [type]: [null, null],
        } as unknown as ChangeSet<T>,
      },
      type as FieldName<T>
    )
  }

  getLogsFromEvent(event: BackendEvent<T>): Log<T>[] {
    const emailsField = 'userEmails' as FieldName<T>

    if (event.event === EventType.UPDATE && event.changeset[emailsField]) {
      const emailsChangeset = event.changeset[emailsField]
      const { added, removed } = getAddedAndRemovedEntities<{ id: string }>(
        (emailsChangeset[0] as string[]).map((email) => ({ id: email })),
        (emailsChangeset[1] as string[]).map((email) => ({ id: email }))
      )
      const addedLogs = added.map((email, index) =>
        super.buildLog(
          {
            ...event,
            id: `${event.id}-${emailsField}-added-${index}`,
            changeset: {
              [emailsField]: [null, email.id],
            } as unknown as ChangeSet<T>,
          },
          emailsField
        )
      )
      const removedLogs = removed.map((email, index) =>
        super.buildLog(
          {
            ...event,
            id: `${event.id}-${emailsField}-removed-${index}`,
            changeset: {
              [emailsField]: [email.id, null],
            } as unknown as ChangeSet<T>,
          },
          emailsField
        )
      )

      const newEvent = {
        ...event,
        changeset: {
          ...event.changeset,
        },
      }
      delete newEvent.changeset[emailsField]

      const res = [
        ...addedLogs,
        ...removedLogs,
        ...super.getLogsFromEvent(newEvent),
      ]
      return res
    }

    if (event.event === EventType.ADD_MEMBER) {
      return [
        {
          ...this.getCustomEventLog(event, EventType.ADD_MEMBER),
          user: event.user
            ? {
                ...event.user,
                image: {
                  ...event.user?.image,
                  smallLogo: {
                    url: 'missing.png',
                  },
                }, // TODO: Image from joining user
                name: event.text,
              }
            : undefined,
          text: this.investorName,
        },
      ]
    }

    if (event.event === EventType.INVITE_MEMBERS) {
      return [
        super.buildLog(
          {
            ...event,
            changeset: {
              [EventType.INVITE_MEMBERS]: [null, JSON.parse(event.text)],
            } as unknown as ChangeSet<T>,
          },
          EventType.INVITE_MEMBERS as FieldName<T>
        ),
      ]
    }

    return super.getLogsFromEvent(event)
  }
}
