import { FormattedMessage } from 'react-intl'
import { useChatContext } from 'stream-chat-react'

import Card from 'components/Card'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getHasAnErrorConnectingTheUser } from 'features/chatSlice'

import ChatErrorHandler from '../../ChatErrorHandler'

interface WidgetErrorHandlerProps {
  children: JSX.Element
}

const WidgetErrorHandler = ({ children }: WidgetErrorHandlerProps) => {
  const { client } = useChatContext()
  const hasAnErrorConnectingTheUser = useAppSelector(
    getHasAnErrorConnectingTheUser
  )

  if (hasAnErrorConnectingTheUser) {
    return (
      <Card isExpandable>
        <Card.Header>
          <FormattedMessage id="chat.chat" />
        </Card.Header>
        <Card.Body>
          <ChatErrorHandler padding="2.6rem 0" smallIcon>
            {children}
          </ChatErrorHandler>
        </Card.Body>
      </Card>
    )
  }

  if (!client) {
    return null
  }

  return children
}

export default WidgetErrorHandler
