import { PortfolioTypes } from 'utils/constants/portfolio'
import { UpdateTypes } from 'utils/constants/updates'
import {
  getCurrentCompany,
  isCompanyPrivate,
  isCurrentCompanyPrivate,
} from 'selectors/company'
import {
  getCurrentGroupId,
  isActingAsClient,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import { useLocation } from 'react-router-dom'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { Portfolio } from 'utils/types/portfolios'
import { UpdateType } from 'utils/types/common'
import { DraftHashAttributes, Update } from 'utils/types/update'
import { TransactionFundType } from 'utils/constants/transactionTypes'
import {
  CompanyHolding,
  HoldingType,
  isCompanyHolding,
  isDealHolding,
  isFundHolding,
} from 'utils/types/company'
import { UpdateRouteState } from './useShowUpdateRoutes'
import { useAppSelector } from './reduxToolkit'
import useGroupCompany from './useGroupCompany'

const EMPTY_PORTFOLIO = {}

interface Props {
  updateType: UpdateType
  update?: Update
}

const useUpdateShareSettingsPermissions = ({ updateType, update }: Props) => {
  const location = useLocation<UpdateRouteState>()
  const isInUpdatesView = location.pathname.includes('/updates')
  const locationState = location.state || {}
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const currentCompany = useAppSelector(getCurrentCompany)
  const groupCompany = useGroupCompany()
  const currentCompanyIsPrivate = useAppSelector(isCurrentCompanyPrivate)
  const currentPortfolioFromRedux = useAppSelector(getCurrentPortfolio)
  const currentCompanyIsFundPortfolio = !!currentCompany?.fundCompany
  const currentGroupOwnsFundPortfolio =
    currentCompany?.fundCompany?.groupId === currentGroupId

  // if we are in the investments page we select a portfolio from the redux store which is the one we are currently viewing
  // however, if we are in the updates page we set the current portfolio to an empty object and we check the rules base on the group and not any portfolio in particular
  // Otherwise, when we select a portfolio in the investments and we go the updates page, the rules will be based on the portfolio we selected in the investments page instead of the
  // group permissions
  const currentPortfolio: Portfolio = isInUpdatesView
    ? EMPTY_PORTFOLIO
    : currentPortfolioFromRedux
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isClient = useAppSelector(isActingAsClient)
  const isFounder = useAppSelector(isActingAsFounder)
  const updateCreatedOnPrivateCompany = useAppSelector(
    isCompanyPrivate(update?.holding)
  )

  const updateCompany = currentCompany?.id ? currentCompany : groupCompany

  const currentCompanyIsFund =
    updateCompany?.holdingType === HoldingType.COMPANY && updateCompany.fund

  const currentGroupOwnsFund = [
    updateCompany?.groupId,
    updateCompany?.owner?.id,
    updateCompany?.group?.id,
  ].includes(currentGroupId)

  const isInvestorTransaction = !!locationState.investorTransaction
  const isInvestorUpdate =
    update?.item.transactionFundType === TransactionFundType.INVESTOR ||
    !!update?.item.investor ||
    !!(update?.draftHash as DraftHashAttributes)?.investor ||
    !!locationState.investor ||
    locationState.showInvestorAndFundSelector

  const updateCreatedOnCompanyAndCurrentGroupIsOwner = !!(
    update?.holding &&
    isCompanyHolding(update.holding) &&
    update.holding.groupId === currentGroupId
  )

  const updateCreatedOnFundOrDealPortfolioAndCurrentGroupIsOwner = !!(
    update?.holding &&
    (isFundHolding(update.holding) || isDealHolding(update.holding)) &&
    update.holding.group?.id === currentGroupId
  )

  const isUpdateIncludedInPortfolio = !!update?.portfolios?.length
  const isPortfolioUpdate = update?.isPortfolioUpdate

  const IS_FUND_PORTFOLIO = currentPortfolio.type === PortfolioTypes.FUND
  const IS_DEAL_PORTFOLIO = currentPortfolio.type === PortfolioTypes.DEAL
  const IS_TRANSACTION_UPDATE = updateType === UpdateTypes.TRANSACTION

  const isPrivatePortfolioDisclaimerVisible = () => {
    const portfolio =
      currentPortfolio === EMPTY_PORTFOLIO
        ? update?.portfolio
        : currentPortfolio

    if (
      updateType === UpdateTypes.NOTE ||
      updateType === UpdateTypes.DOCUMENT
    ) {
      return (
        portfolio?.type === PortfolioTypes.INVEST ||
        portfolio?.type === PortfolioTypes.TRACK
      )
    }

    return false
  }

  const isSharableOnCreate = () => {
    if (currentCompanyIsPrivate || isInvestorTransaction || isInvestorUpdate)
      return false

    if (isFounder) {
      return true
    }

    // A Client or a Fund Manager is allowed to share (outside his group) transaction updates from a portfolio
    // and every kind of updates from a fund portfolio
    if ((isClient || isFundManager) && currentPortfolio.type) {
      return IS_TRANSACTION_UPDATE || IS_FUND_PORTFOLIO || IS_DEAL_PORTFOLIO
    }

    // If there is no currentPortfolio type, then the update is being created from a Company
    return !currentPortfolio.type
  }

  const isSharableOnShowAndEdit = () => {
    if (updateCreatedOnPrivateCompany || isInvestorUpdate) return false

    if (isFounder) {
      return true
    }

    // A Client is allowed to share (outside his group) transaction updates from a portfolio
    // and every kind of updates from a fund portfolio
    if (isClient) {
      if (isPortfolioUpdate) {
        return IS_TRANSACTION_UPDATE || !!update.fundPortfolio
      }

      return true
    }

    if (isFundManager) {
      if (isPortfolioUpdate) {
        return IS_TRANSACTION_UPDATE || !!update.fundPortfolio
      }

      return true
    }

    return false
  }

  const showPublicPermissionOnCreateMode = () => {
    if (IS_TRANSACTION_UPDATE) return false

    if (isClient) {
      return false
    }

    if (isFounder) {
      return true
    }

    if (currentCompanyIsPrivate || isInvestorTransaction || isInvestorUpdate)
      return false

    if (isFundManager) {
      if (currentPortfolio.type) {
        return IS_FUND_PORTFOLIO || IS_DEAL_PORTFOLIO
      }

      if (!currentPortfolio.type) {
        if (currentCompanyIsFund && currentGroupOwnsFund) {
          return true
        }

        return currentCompanyIsFundPortfolio && currentGroupOwnsFundPortfolio
      }
    }

    return false
  }

  const showPublicPermissionOnShowAndEditMode = () => {
    if (IS_TRANSACTION_UPDATE) return false

    if (updateCreatedOnPrivateCompany || isInvestorUpdate) return false

    if (isClient) {
      return false
    }

    if (isFounder) {
      return true
    }

    if (isFundManager) {
      if (isPortfolioUpdate && update.fundPortfolio) {
        return updateCreatedOnFundOrDealPortfolioAndCurrentGroupIsOwner
      }

      if (isPortfolioUpdate && !update.fundPortfolio) {
        return false
      }

      if (!isPortfolioUpdate) {
        if ((update?.holding as CompanyHolding).groupId) {
          return updateCreatedOnCompanyAndCurrentGroupIsOwner
        }

        return false
      }
    }

    return false
  }

  const showReshareSettingsOptionOnCreateMode = () => {
    if (currentCompanyIsPrivate || isInvestorTransaction) return false

    if (isFounder) {
      return true
    }

    if (isClient) {
      if (currentPortfolio.type) {
        return IS_TRANSACTION_UPDATE
      }

      return !currentPortfolio.type
    }

    if (isFundManager) {
      if (
        currentPortfolio.type &&
        (IS_FUND_PORTFOLIO || IS_DEAL_PORTFOLIO || IS_TRANSACTION_UPDATE)
      ) {
        return true
      }

      return !currentPortfolio.type
    }

    return false
  }

  const showReshareSettingsOptionOnShowAndEdit = () => {
    if (updateCreatedOnPrivateCompany || isInvestorUpdate) return false

    if (isFounder) {
      return true
    }

    if (isClient) {
      if (isPortfolioUpdate && IS_TRANSACTION_UPDATE) {
        return !isUpdateIncludedInPortfolio
      }

      return !isPortfolioUpdate
    }

    if (isFundManager) {
      if (isPortfolioUpdate && update.fundPortfolio) {
        return true
      }

      if (isPortfolioUpdate && !update.fundPortfolio) {
        if (IS_TRANSACTION_UPDATE) {
          return !isUpdateIncludedInPortfolio
        }

        return false
      }
    }

    return true
  }

  return {
    isSharableOnCreate,
    isSharableOnShowAndEdit,
    isPrivatePortfolioDisclaimerVisible,
    showPublicPermissionOnCreateMode,
    showPublicPermissionOnShowAndEditMode,
    showReshareSettingsOptionOnCreateMode,
    showReshareSettingsOptionOnShowAndEdit,
  }
}

export default useUpdateShareSettingsPermissions
