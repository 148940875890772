import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledFunds = styled.div``

export const TableMarginWrapper = styled.div`
  margin: 0rem -3.2rem;
`

export const NoFunds = styled.p`
  font-weight: 400;
  font-size: 1.8rem;
  color: ${color('lightGray')};
  text-align: center;
  padding: 2rem 0 6rem 0;
`
