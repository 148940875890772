import React from 'react'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import type { FormikProps } from 'formik'
import Button from 'ui/Button'
import Drawer from 'components/Drawer'
import Collapsable from 'components/Collapsable'
import { Investor, InvestorFormValues } from 'utils/types/investors'
import { getEditInvestorInitialValues } from 'utils/functions/investorManagement'
import { getEditInvestorSchema } from 'utils/schemas/investorManagement'
import { InvestorTypes } from 'utils/constants/investorManagement'
import AddInvestorForm from '../InvestorForm/AddInvestorForm'
import AddInvestorOtherInfoForm from '../InvestorForm/AddInvestorOtherInfoForm'
import AddInvestorLinksForm from '../InvestorForm/AddInvestorLinksForm'
import TeamMembersForm from '../InvestorForm/CompanyTeamForm'

import * as Styles from './EditInvestor.styles'

interface EditInvestorInformationProps {
  investor: Investor
  editInvestor: (values: InvestorFormValues) => void
  onCloseDrawer: () => void
  formRef: React.RefObject<FormikProps<InvestorFormValues>>
}

const EditInvestorInformation: React.FC<EditInvestorInformationProps> = ({
  investor,
  onCloseDrawer,
  editInvestor,
  formRef,
}) => {
  const intl = useIntl()

  return (
    <Formik<InvestorFormValues>
      innerRef={formRef}
      initialValues={getEditInvestorInitialValues(investor)}
      onSubmit={editInvestor}
      validationSchema={getEditInvestorSchema(intl)}
      enableReinitialize
    >
      {(formik) => (
        <>
          <Collapsable
            id="investorManagement.editInvestor.generalInformation"
            initiallyExpanded
            titleCustomStyles={Styles.TitleStyles}
          >
            <AddInvestorForm />
          </Collapsable>

          <Collapsable
            id="investorManagement.editInvestor.investorLinks"
            titleCustomStyles={Styles.TitleStyles}
          >
            <AddInvestorLinksForm />
          </Collapsable>

          {formik.values.type === InvestorTypes.ORGANIZATION && (
            <Collapsable
              id="investorManagement.editInvestor.team"
              titleCustomStyles={Styles.TitleStyles}
            >
              <TeamMembersForm />
            </Collapsable>
          )}

          <Collapsable
            id="investorManagement.editInvestor.otherInformation"
            titleCustomStyles={Styles.TitleStyles}
          >
            <AddInvestorOtherInfoForm investorType={formik.values.type} />
          </Collapsable>

          <Drawer.Footer>
            <Button onClick={onCloseDrawer}>
              {intl.formatMessage({ id: 'general.cancel' })}
            </Button>
            <Button
              type="submit"
              primary
              onClick={formik.handleSubmit}
              isDisabled={
                !formik.values.name ||
                !!formik.errors.name ||
                formik.isSubmitting
              }
            >
              {intl.formatMessage({ id: 'general.save' })}
            </Button>
          </Drawer.Footer>
        </>
      )}
    </Formik>
  )
}

export default EditInvestorInformation
