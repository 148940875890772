import { useIntl } from 'react-intl'

import GroupUsersAddInput from 'components/GroupsUsersAddInput'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import ListsSelectionBox from 'components/ListsSelectionBox'
import Title from 'components/Title'
import Separator from 'ui/Separator'
import { AppGroup } from 'utils/types/user'
import { Nullable } from 'utils/types/common'

interface CustomPermissionsProps {
  handleAddGroup: (group: AppGroup) => void
  handleAddUser: (user: any) => void
  handleBulkAddUsers: (users: any) => void
  handleDelete: (itemId: string) => void
  items: any[]
  ownedByGroup: Nullable<string>
  handleEditItemList: ({
    id,
    listId,
    listName,
  }: {
    id: string
    listId: string
    listName: string
  }) => void
}

const CustomPermissions = ({
  handleAddGroup,
  handleAddUser,
  handleBulkAddUsers,
  handleDelete,
  items,
  ownedByGroup,
  handleEditItemList,
}: CustomPermissionsProps) => {
  const intl = useIntl()

  return (
    <>
      <ListsSelectionBox
        items={items}
        handleAddGroup={handleAddGroup}
        handleAddUser={handleAddUser}
        handleDelete={handleDelete}
        handleEditItemList={handleEditItemList}
        customLabel={intl.formatMessage({ id: 'general.byList' })}
      />
      <Separator />

      <GroupUsersAddInput
        id="custom-permissions-group-users"
        items={items}
        handleAddGroup={handleAddGroup}
        handleAddUser={handleAddUser}
        handleBulkAddUsers={handleBulkAddUsers}
        // NOTE: Next props are not used but they are required by the GroupUsersAddInput component
        // To fix this we should work on the component, move component to TS and fix props in future tickets
        handleBulkAddGroups={undefined}
        onlyUsers={undefined}
        showLabel={undefined}
        className={undefined}
        clearOnSelect={undefined}
        allowBulkAddUsers={undefined}
        maxBulkEmails={undefined}
        onAddMoreEmailsThanAllowed={undefined}
        customLabel={undefined}
        showOptionsTitle={undefined}
        customPlaceholder={undefined}
        dataTestId={undefined}
      />

      {items.length > 0 && (
        <>
          <Separator space="3.2rem" />
          <Title
            title={intl.formatMessage({ id: 'general.selectedGroupsUsers' })}
          />
        </>
      )}

      <BoxGroupsAndUsersChips
        items={items}
        handleDelete={handleDelete}
        ownedByGroup={ownedByGroup}
        canDelete
      />
    </>
  )
}

export default CustomPermissions
