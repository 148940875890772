import { FormattedMessage, useIntl } from 'react-intl'
import { useField } from 'components/Form/hooks'
import ConfirmationDateModal from 'containers/UpdatesV2/components/UpdateHeader/components/ConfirmationDateModal/ConfirmationDateModal'
import ConfirmationModal from 'components/ConfirmationModal'
import ShareSettingsModal from 'containers/UpdatesV2/components/ShareSettingsModal/ShareSettingsModal'
import Checkbox from 'components/Checkbox'
import DeleteModalBody from 'containers/DeleteModal/DeleteModalBody'
import { CreateUpdateGlobalProps } from 'utils/types/updateForm'
import { CheckBoxContainer } from './Modals.styles'

interface UpdateModalsProps {
  updateType: string
  discardDraftModal: boolean
  deleteModal: boolean
  shouldShowShareWithInvestorRecommendation?: boolean
  showShareWithInvestorRecommendationModal: boolean
  showShareSettingsModal: boolean
  showChangePublishUpdateDate: boolean
  values?: CreateUpdateGlobalProps
  handleDelete?: () => void
  handleDiscardDraft?: () => void
  toggleDeleteConfirmationModal: () => void
  toggleDiscardDraftConfirmationModal: () => void
  toggleSubmitConfirmationModal: () => void
  togglePublishUpdateDateModal: () => void
  toggleShareSettingsModal: () => void
  handleSubmit: () => void
  onShareWith: () => void
  onChangeDraftToToday: () => Promise<void>
  switchShowMessageAgain: () => void
}

const UpdateModals = ({
  updateType,
  deleteModal,
  discardDraftModal,
  shouldShowShareWithInvestorRecommendation,
  showShareWithInvestorRecommendationModal,
  showShareSettingsModal,
  showChangePublishUpdateDate,
  values,
  handleDelete,
  handleDiscardDraft,
  toggleDeleteConfirmationModal,
  toggleDiscardDraftConfirmationModal,
  toggleSubmitConfirmationModal,
  togglePublishUpdateDateModal,
  toggleShareSettingsModal,
  handleSubmit,
  onShareWith,
  onChangeDraftToToday,
  switchShowMessageAgain,
}: UpdateModalsProps) => {
  const intl = useIntl()

  const [notShowShareWithInvestorModal] = useField<boolean>(
    'notShowShareWithInvestorModal'
  )

  return (
    <>
      <ConfirmationModal
        dangerConfirm
        confirmMessage={intl.formatMessage({ id: 'general.delete' })}
        title={intl.formatMessage({
          id: `updates.deleteTitles.${updateType}`,
        })}
        body={
          <DeleteModalBody
            text={intl.formatMessage({
              id: `updates.deleteMessages.${updateType}`,
            })}
            secondaryText={intl.formatMessage({
              id: `updates.deleteConfirmationModal.secondaryText`,
            })}
          />
        }
        onHide={toggleDeleteConfirmationModal}
        onConfirm={handleDelete}
        isOpen={deleteModal}
      />

      <ConfirmationModal
        dangerConfirm
        confirmMessage={intl.formatMessage({ id: 'general.discard' })}
        title={intl.formatMessage({
          id: 'updates.drafts.discardDraftTitle',
        })}
        body={
          <DeleteModalBody
            text={intl.formatMessage({
              id: 'updates.drafts.discardDraftMessage',
            })}
            secondaryText={intl.formatMessage({
              id: 'updates.drafts.discardDraftDescription',
            })}
          />
        }
        onHide={toggleDiscardDraftConfirmationModal}
        onConfirm={handleDiscardDraft}
        isOpen={discardDraftModal}
      />

      {shouldShowShareWithInvestorRecommendation && (
        <ConfirmationModal
          title={intl.formatMessage(
            {
              id: 'updates.confirmationModal.title',
            },
            {
              updateType: values?.updateType.toUpperCase(),
            }
          )}
          hasIcon={false}
          confirmMessage={intl.formatMessage({
            id: 'updates.confirmationModal.confirmMessage',
          })}
          showCancel={false}
          showSharedWith
          onShareWith={onShareWith}
          body={
            <DeleteModalBody
              text={
                <FormattedMessage
                  id="updates.confirmationModal.description"
                  values={{
                    investorName: values?.investor?.name,
                    updateType: values?.updateType,
                  }}
                />
              }
              secondaryText={intl.formatMessage(
                {
                  id: 'updates.confirmationModal.descriptionTwo',
                },
                {
                  updateType: values?.updateType,
                }
              )}
              maxWidth="40rem"
            >
              <CheckBoxContainer>
                <Checkbox
                  key="show_this_message_again"
                  id="show_this_message_again"
                  name="show_this_message_again"
                  data-testid="show_this_message_again"
                  label={intl.formatMessage({
                    id: 'updates.confirmationModal.showThisMessageAgain',
                  })}
                  onChange={switchShowMessageAgain}
                  checked={notShowShareWithInvestorModal.value}
                />
              </CheckBoxContainer>
            </DeleteModalBody>
          }
          onHide={toggleSubmitConfirmationModal}
          onConfirm={handleSubmit}
          isOpen={showShareWithInvestorRecommendationModal}
        />
      )}

      {showChangePublishUpdateDate && (
        <ConfirmationDateModal
          isOpen={showChangePublishUpdateDate}
          date={values?.date!}
          togglePublishUpdateDateModal={togglePublishUpdateDateModal}
          publishOnSelectedDate={handleSubmit}
          changeToToday={onChangeDraftToToday}
        />
      )}

      <ShareSettingsModal
        isModalOpen={showShareSettingsModal}
        closeModal={toggleShareSettingsModal}
      />
    </>
  )
}

export default UpdateModals
