import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import ImagePicker from 'ui/ImagePicker'
import { color } from 'utils/functions/colors'

export const FormContainer = styled.div``

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1.8rem;
`

export const StyledImagePicker = styled(ImagePicker)`
  background: ${color('lightGray', 0.1)};
`

export const IndustryList = styled.ul`
  padding: 1.6rem 0;
`
export const IndustryItem = styled.li`
  display: inline-block;
  padding: 0 0.8rem 0.8rem 0;
`

export const Title = styled.div`
  > h1 {
    margin-top: 0;
  }
`

export const Heading = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  color: ${color('darkBlue')};
  margin-bottom: 3.2rem;
`

export const SubHeading = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${color('darkGray')};
  margin-bottom: 1.6rem;
`

export const SelectorWrapper = styled.div<{
  padding?: string
  maxWidth?: string
}>`
  padding: ${(props) => props.padding || '0'};
  max-width: ${(props) => props.maxWidth || 'none'};
`

const displayWrapper = (columnNumber: number, marginBottom: string) => css`
  display: grid;
  gap: 2.4rem;
  margin-bottom: ${marginBottom};
  grid-template-columns: repeat(${columnNumber}, 1fr);
`

export const DisplayWrapper = styled.div<{
  showInGrid: boolean
  columnsNumber?: number
  marginBottom?: string
}>`
  ${(props) =>
    props.showInGrid &&
    displayWrapper(props.columnsNumber || 2, props.marginBottom || '2.4rem')}
`

export const ImageAndNameWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`

export const StandardWrapper = styled.div<{ marginTop?: string }>`
  margin-top: ${(props) => props.marginTop || '0rem'};
`

export const Image = styled.img`
  min-width: 1.8rem;
  height: 1.8rem;
  color: ${color('darkBlue')};
`

export const Icon = styled(FontAwesomeIcon)`
  min-width: 1.8rem;
  height: 1.8rem;
  color: ${color('darkBlue')};
`
