import Avatar from 'components/AvatarSVG/Avatar'
import { EmailAvatarIcon, ListAvatarIcon } from 'ui/Icons'
import { getInitials } from 'utils/functions/user'

export enum IconsTypes {
  email = 'email',
  list = 'list',
  avatar = 'avatar',
}

export const getImageComponent = (
  type: string = IconsTypes.avatar,
  name?: string,
  imageUrl?: string
) => {
  if (type === IconsTypes.email) {
    return <EmailAvatarIcon />
  }

  if (type === IconsTypes.list) {
    return <ListAvatarIcon />
  }

  return (
    <Avatar imageUrl={imageUrl} initials={getInitials(name)} size="2.1rem" />
  )
}
