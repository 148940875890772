export const UPDATES = '/updates'
export const EMAILS = '/emails'
export const METRICS_TAB = '/metrics/:tab'
export const FILES = '/files'
export const CHAT = '/chat'
export const CHAT_CHANNEL = '/chat/:channelId'
export const SETTINGS = '/settings'
export const PORTFOLIOS = '/portfolios'
export const CREATE_GROUP = '/create-group'

const commonRoutes = [
  UPDATES,
  EMAILS,
  METRICS_TAB,
  FILES,
  CHAT,
  CHAT_CHANNEL,
  SETTINGS,
  PORTFOLIOS,
  CREATE_GROUP,
]

export default commonRoutes
