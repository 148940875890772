import { useIntl } from 'react-intl'
import { Helmet } from 'components/Helmet'
import { getCompanyFundId } from 'selectors/auth'

import CompanyProfile from 'containers/Profiles/CompanyProfile'

import { useAppSelector } from 'utils/hooks/reduxToolkit'

export const GROUP_PROFILE_PREFIX = 'group-profile'

const FundManagerProfile = () => {
  const companyFundId = useAppSelector(getCompanyFundId)
  const intl = useIntl()

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'metaTitles.organizationProfile' })}
        </title>
      </Helmet>
      <CompanyProfile
        companyId={companyFundId!}
        shouldRenderHelmet={false}
        prefix={GROUP_PROFILE_PREFIX}
      />
    </>
  )
}

export default FundManagerProfile
