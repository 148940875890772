import { InvestmentNavigationOptionType } from 'containers/Investments/types'
import styled, { css } from 'styled-components'
import IconButton from 'ui/IconButton'
import { color } from 'utils/functions/colors'

export const iconWrapperDefault = (
  optionType: InvestmentNavigationOptionType
) => css`
  align-items: center;
  background-color: ${optionType === 'UnsavedFilter'
    ? color('lightGray')
    : color('primaryBlue')};
  border-radius: 50%;
  display: flex;
  height: 2.1rem;
  justify-content: center;
  padding: 0.5rem;
  width: 2.1rem;

  svg {
    color: ${color('white')};
  }
`

export const NavigationItem = styled.div<{
  isItemSelected?: boolean
  disabled?: boolean
  colorizeHoverIcon?: boolean
}>`
  align-items: center;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('lightGray', 0.2)};
  color: ${color('darkGray')};
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  font-weight: 700;
  gap: 0.7rem;
  height: 4.2rem;
  padding: 1rem;
  user-select: none;
  width: 23rem;
  min-width: 23rem;
  max-width: 23rem;

  &:hover {
    background-color: ${color('veryLightBlue', 0.2)};
    color: ${color('primaryBlue')};

    ${({ colorizeHoverIcon }) =>
      colorizeHoverIcon &&
      css`
        svg {
          color: ${color('primaryBlue')};
        }
      `}
  }

  ${({ isItemSelected }) =>
    isItemSelected &&
    css`
      background-color: ${color('veryLightBlue', 0.2)};
      color: ${color('primaryBlue')};
      box-shadow: inset 0.4rem 0.4rem 0.4rem rgba(120, 126, 140, 0.08);
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`

export const ItemName = styled.span<{
  optionType: InvestmentNavigationOptionType
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 16rem;

  ${({ optionType }) =>
    optionType === 'UnsavedFilter' &&
    css`
      font-style: italic;
      font-weight: 400;
    `}
`

export const PinButton = styled(IconButton)<{
  isPinned: boolean
  isHovered: boolean
}>`
  margin-left: auto;
  cursor: pointer;
  color: ${color('lightGray')};
  display: none;
  z-index: 9999;

  ${({ isPinned, isHovered }) =>
    (isPinned || isHovered) &&
    css`
      display: block;

      &:hover {
        color: ${color('primaryBlue')};
      }
    `}
`

export const IconWrapper = styled.div<{
  isItemSelected: boolean
  hasBackground: boolean
  optionType: InvestmentNavigationOptionType
}>`
  ${({ optionType }) => iconWrapperDefault(optionType)};

  ${({ isItemSelected, hasBackground }) =>
    (isItemSelected || !hasBackground) &&
    css`
      background-color: transparent;
    `};

  ${({ hasBackground }) =>
    !hasBackground &&
    css`
      svg {
        color: ${color('lightGray')};
      }
    `}

  ${({ isItemSelected }) =>
    isItemSelected &&
    css`
      svg {
        color: ${color('primaryBlue')};
      }
    `}
`
