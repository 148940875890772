import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Chip from 'components/Chip'
import Input from 'ui/Input/Input'

import styles from './BulkAddInput.module.scss'

const TARGETS_IDS_TO_AVOID_FOCUS = [
  'tagsInput',
  'closeIcon',
  'itemsWrapper',
  'itemsList',
]

const PARENT_IDS_TO_AVOID_FOCUS = ['closeIcon', 'chip']

const BulkAddInput = React.forwardRef(
  (
    {
      onAdd,
      onBulkAdd,
      onRemove: onRemoveProp,
      list: listProp,
      validationWhenAdding,
      className,
      placeholder,
      allowBulkAdd,
    },
    ref
  ) => {
    const [text, setText] = useState('')
    const [list, setList] = useState([])

    useEffect(() => {
      setList(listProp)
    }, [listProp])

    const onPressEnter = (event) => {
      const textValue = event?.target?.value ?? text
      const items = textValue?.split(',').map((item) => item.trim())

      if (allowBulkAdd && items.length > 1) {
        if (validationWhenAdding) {
          const validItems = items.filter((item) => {
            return validationWhenAdding(item.replace(/\s/g, ''), true)
          })
          if (validItems.length) {
            onBulkAdd(validItems)
          }
          const invalidItems = items.filter(
            (item) => !validationWhenAdding(item.replace(/\s/g, ''), false)
          )
          if (invalidItems.length) {
            setText(invalidItems.join(', '))
          } else {
            setText('')
          }
        } else {
          onBulkAdd(items)
        }
      } else if (!validationWhenAdding) {
        setText('')
        onAdd(textValue)
      } else {
        const isValidItem = validationWhenAdding(
          textValue.replace(/\s/g, ''),
          true
        )

        if (isValidItem) {
          setText('')
          onAdd(textValue)
        }
      }

      event?.preventDefault()
    }

    const ontextValueChange = (event) => {
      setText(event.target.value)
    }

    const onRemove = (txt) => {
      onRemoveProp(txt)
    }

    const displayList = list?.map((item) =>
      item.destroy ? null : (
        <li key={item.name}>
          <Chip
            text={item.name}
            handleDelete={() => {
              onRemove(item)
            }}
          />
        </li>
      )
    )

    const onClickOutside = (event) => {
      event.stopPropagation()
      if (
        !TARGETS_IDS_TO_AVOID_FOCUS.includes(event.target?.id) &&
        !PARENT_IDS_TO_AVOID_FOCUS.includes(event.target.parentNode?.id)
      ) {
        setText('')
      }
    }

    useEffect(() => {
      window.addEventListener('click', onClickOutside)
      return () => {
        window.removeEventListener('click', onClickOutside)
      }
    }, [])

    return (
      <div
        id="itemsWrapper"
        className={classNames(styles.listWrapper, className)}
      >
        <Input
          type="text"
          icon={['far', 'plus']}
          iconFontSize="1.8rem"
          value={text}
          onChange={ontextValueChange}
          onPressEnter={onPressEnter}
          placeholder={placeholder}
        />
        <ul ref={ref} id="itemsList" className={styles.itemList}>
          {displayList}
        </ul>
      </div>
    )
  }
)

BulkAddInput.propTypes = {
  list: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onBulkAdd: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
  validationWhenAdding: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  allowBulkAdd: PropTypes.bool,
}

BulkAddInput.defaultProps = {
  validationWhenAdding: null,
  allowBulkAdd: false,
  onBulkAdd: () => {},
  className: '',
  placeholder: '',
}

export default BulkAddInput
