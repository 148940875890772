import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import InboundService, { EditInboundPayload } from 'api/InboundService'
import { useCallback } from 'react'
import { InboundQueries, inboundsKeys } from 'utils/queries/inbounds'
import { Inbound } from 'utils/types/inbounds'

export const useInboundByIdQuery = (id?: string) => {
  return useQuery(
    inboundsKeys.byId(id || ''),
    () => InboundService.getById(id || ''),
    {
      enabled: !!id,
    }
  )
}

export const useInboundsQuery = () => {
  return useQuery(inboundsKeys.all(), InboundService.getAll)
}

export const useCreateInboundMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(InboundService.create, {
    onSuccess: (inbound: Inbound) => {
      queryClient.invalidateQueries([InboundQueries.INBOUNDS])
      queryClient.setQueryData(inboundsKeys.byId(inbound.id), inbound)
    },
  })
}

export const useEditInboundMutation = (inboundId: string) => {
  const queryClient = useQueryClient()

  return useMutation(
    (inboundData: Partial<EditInboundPayload>) =>
      InboundService.edit(inboundId, inboundData),
    {
      onSuccess: (inbound: Inbound) => {
        queryClient.refetchQueries([InboundQueries.INBOUNDS])
        queryClient.setQueryData(inboundsKeys.byId(inbound.id), inbound)
      },
    }
  )
}

export const useDeleteInboundMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(InboundService.destroy, {
    onSuccess: () => {
      queryClient.invalidateQueries([InboundQueries.INBOUNDS])
    },
  })
}

export const useUpdateInboundCache = () => {
  const queryClient = useQueryClient()

  const updateInboundCache = useCallback(
    (inboundId: string, inboundData: Partial<Inbound>) => {
      const inboundCache = queryClient.getQueryData<Inbound>(
        inboundsKeys.byId(inboundId)
      )

      if (inboundCache) {
        queryClient.setQueryData(
          inboundsKeys.byId(inboundId),
          (inbound: Inbound) => ({
            ...inbound,
            ...inboundData,
          })
        )
      }

      queryClient.setQueryData(inboundsKeys.all(), (inbounds: Inbound[]) => {
        const newInbounds = [...inbounds]
        const index = newInbounds.findIndex(
          (inbound) => inbound.id === inboundId
        )

        if (index > -1) {
          newInbounds[index] = {
            ...newInbounds[index],
            ...inboundData,
          }
        }

        return newInbounds
      })
    },
    [queryClient]
  )

  return { updateInboundCache }
}
