import { ReactNode, useId } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import { Holding } from 'utils/types/company'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { MetricsMode } from 'utils/types/metrics'
import { MetricsSpreadsheetMode } from 'components/UpdateMetricsGrid/MetricsSpreadsheetLogic'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFundManager } from 'selectors/auth'

export const useProfileMetricsActions = (
  holding?: Holding | { id: string; name: string },
  requestFounderMetrics?: boolean
) => {
  const id = useId()
  const history = useHistory()
  const intl = useIntl()
  const params = useParams<{
    companyId?: string
  }>()
  const metricsRoute = useMetricsRoute()
  const isFundManager = useAppSelector(isActingAsFundManager)

  const onAddMetric = (prepopulateHolding: boolean = true) =>
    history.push(`${metricsRoute}/new`, {
      companyToAdd: prepopulateHolding ? holding : undefined,
    })

  const onUpdateAllMetrics = (companyId?: string) => {
    const url = companyId
      ? `${metricsRoute}/update-all/${companyId}`
      : `${metricsRoute}/update-all`

    history.push(url, {
      holding,
      companyName: companyId ? holding?.name : '',
      mode: companyId || params.companyId ? undefined : MetricsMode.FOUNDER,
      spreadsheetMode:
        requestFounderMetrics && !isFundManager
          ? MetricsSpreadsheetMode.SINGLE_HOLDING
          : undefined,
    })
  }

  const getUpdateAllMetricsReadOnlyTooltip = (content: ReactNode) => (
    <ReadOnlyTooltip
      id={`update_company_metrics_${id}`}
      text={intl.formatMessage({ id: 'readOnlyMode.updateAllMetrics' })}
      place="top"
      leftOffset={-90}
      maxWidth="27.9rem"
      disabled={undefined}
    >
      {content}
    </ReadOnlyTooltip>
  )

  const getAddMetricReadOnlyTooltip = (content: ReactNode) => (
    <ReadOnlyTooltip
      id={`add_company_metric_${id}`}
      text={intl.formatMessage({ id: 'readOnlyMode.addMetrics' })}
      place="top"
      leftOffset={-90}
      maxWidth="27.9rem"
      disabled={undefined}
    >
      {content}
    </ReadOnlyTooltip>
  )

  return {
    onAddMetric,
    onUpdateAllMetrics,
    getUpdateAllMetricsReadOnlyTooltip,
    getAddMetricReadOnlyTooltip,
  }
}
