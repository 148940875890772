import { DropdownContainer } from 'components/UpdateContent/UpdateContent.styles'
import { Dropdown } from 'containers/UpdatesV2/components/commons'
import ReadOnlyField from 'containers/UpdatesV2/components/commons/ReadOnlyField'
import { FIELD_NAMES } from 'containers/UpdatesV2/Accounting/constants/constants'
import { useField } from 'formik'
import { useIntl } from 'react-intl'
import { getPeriodsToCompareDropdownOptions } from 'utils/functions/renderers/renderUpdatesHelper'

interface PeriodsProps {
  disabled: boolean
  isEditMode?: boolean
}

const Periods = ({ disabled, isEditMode }: PeriodsProps) => {
  const intl = useIntl()
  const [field] = useField(FIELD_NAMES.periods)

  return (
    <DropdownContainer>
      {isEditMode ? (
        <ReadOnlyField
          name={FIELD_NAMES.periods}
          value={
            getPeriodsToCompareDropdownOptions(intl)[field.value]
              ?.label as string
          }
          label={intl.formatMessage({
            id: 'general.comparePeriods',
          })}
        />
      ) : (
        <Dropdown
          name={FIELD_NAMES.periods}
          label={intl.formatMessage({ id: 'general.comparePeriods' })}
          placeholder={intl.formatMessage({
            id: 'general.selectComparePeriods',
          })}
          options={getPeriodsToCompareDropdownOptions(intl)}
          disabled={disabled}
        />
      )}
    </DropdownContainer>
  )
}

export default Periods
