import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames'
import styles from './DashboardItemTitle.module.scss'

const DashboardItemTitle = ({ icon, title, isFundDashboard }) => {
  return (
    <div className={cn({ [styles.fundWrapper]: isFundDashboard })}>
      <div className={styles.subHeader}>
        <div className={styles.icon}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  )
}

DashboardItemTitle.propTypes = {
  icon: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  isFundDashboard: PropTypes.bool,
}
DashboardItemTitle.defaultProps = {}

export default DashboardItemTitle
