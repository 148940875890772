import { useMemo } from 'react'

const useAxis = (values: number[], numTicks: number) => {
  return useMemo(() => {
    const min = Math.min(...values) > 0 ? 0 : Math.min(...values)
    const max = Math.max(...values)

    const step: number = (max - min) / (numTicks - 1)
    const ticks: number[] = []

    for (let i = 0; i < numTicks; i++) {
      ticks.push(parseFloat((min + i * step).toFixed(2)))
    }

    // if there are no values, returns an array of numbers from 0 to numTicks
    // to display the Catertesian grid (yAxis ticks are hidden so it only shows the grid lines)
    if (values.length === 0) {
      return {
        domain: [0, 1],
        ticks: Array.from({ length: numTicks }, (_, i) => i),
      }
    }

    return {
      domain: [ticks[0], ticks[ticks.length - 1]],
      ticks,
    }
  }, [values, numTicks])
}

export default useAxis
