import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  StreamMobileThread,
  StreamStylesOverrides,
} from 'containers/Chat/ChatStreamStylesOverrides'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const MobileLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${color('white')};

  ${StreamStylesOverrides}

  @media ${maxSize.mobile} {
    .str-chat__list {
      .str-chat__reverse-infinite-scroll {
        padding: initial;
      }

      .str-chat__message-list-scroll {
        .str-chat__ul {
          margin-top: 2rem;
        }

        .str-chat__li {
          margin: 0;
        }
      }
    }

    .str-chat__thread-container {
      ${StreamMobileThread}

      .str-chat__main-panel-inner {
        flex: 1;
      }

      .str-chat__parent-message-li {
        margin-bottom: -0.8rem;
      }
    }
  }
`

export const MobileNavigationArrow = styled(FontAwesomeIcon)`
  display: none;

  @media ${maxSize.mobile} {
    transform: rotate(90deg);
    cursor: pointer;
    display: block;
    font-size: 1.6rem;
    margin-right: 0.8rem;
    margin-bottom: 0.1rem;
  }
`

export const VisibleContainer = styled.div<{ isVisible?: boolean }>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
  background: ${color('white')};
`
