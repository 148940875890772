import type { Compatible, EventHandlers, Uncertain } from '@silevis/reactgrid'
import { keyCodes } from '@silevis/reactgrid'
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { scrollDropdownOptionIntoView } from 'utils/functions/dropdown'
import {
  isDownCode,
  isEnterKeyCode,
  isEscCode,
  isUpCode,
} from 'utils/functions/keyboardEvents'
import useOutsideClick from 'utils/hooks/useOutsideClick'
import { Nullable } from 'utils/types/common'
import {
  Option,
  StaticDropdownCell,
} from '../../CustomStaticDropdownCellTemplate'

/*
 * Pessimistic amount of time (ms) it takes for the React Grid event handler
 * to handle the click event on the cell and is free to handle the synthetic
 * 'Enter' keyboard event
 */
const KEYBOARD_EVENT_DELAY = 150

/*
 * Height of the blue borders around the cell when it's being focused + a bit of margin.
 * Used to calculate the dropdown options top position
 */
const OPTIONS_OFFSET = 7

export interface StaticSpreadsheetDropdownProps {
  cell: StaticDropdownCell
  options: Option[]
  isInEditMode: boolean
  onCellChanged: (cell: Compatible<StaticDropdownCell>, commit: boolean) => void
  getCompatibleCell: (
    uncertainCell: Uncertain<StaticDropdownCell>
  ) => Compatible<StaticDropdownCell>
  eventHandler: MutableRefObject<EventHandlers | undefined>
}

export const useStaticSpreadsheetDropdown = ({
  cell,
  options,
  isInEditMode,
  onCellChanged,
  getCompatibleCell,
  eventHandler,
}: StaticSpreadsheetDropdownProps) => {
  const [isOpen, setIsOpen] = useState(isInEditMode && !cell.autoFilled)
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    cell.option
  )
  const [highlightedOption, setHighlightedOption] = useState<
    Option | undefined
  >(cell.option)
  const [inputHeight, setInputHeight] = useState(0)
  const dropdownRef = useRef<Nullable<HTMLDivElement>>(null)
  const optionsRef = useRef(null)
  const placeholderId = useMemo(() => {
    const id = cell.placeholder || 'spreadsheet.selectOption'
    return cell.optional ? 'spreadsheet.optional' : id
  }, [cell.optional, cell.placeholder])

  useLayoutEffect(() => {
    if (dropdownRef.current) {
      setInputHeight(dropdownRef.current.offsetHeight + OPTIONS_OFFSET)
    }
  }, [])

  useOutsideClick(dropdownRef, () => {
    onCellChanged(getCompatibleCell(cell), true)
    setIsOpen(false)
  })

  useEffect(() => {
    setSelectedOption(cell.option)
    setHighlightedOption(cell.option)
  }, [cell.option])

  const stopPropagation = useCallback((e) => e.stopPropagation(), [])

  const onSelectOption = useCallback(
    (option?: Option) => {
      if (option) {
        onCellChanged(
          getCompatibleCell({
            ...cell,
            option,
          }),
          true
        )
      } else {
        onCellChanged(getCompatibleCell(cell), true)
      }

      setIsOpen(false)
    },
    [cell, onCellChanged, getCompatibleCell]
  )

  const handleDownCode = useCallback(
    (currentHighlightedIndex: number) => {
      if (!isOpen && !cell.autoFilled) {
        setIsOpen(true)
        return options[0]
      }

      if (currentHighlightedIndex + 1 < options.length) {
        return options[currentHighlightedIndex + 1]
      }

      return undefined
    },
    [isOpen, options, cell.autoFilled]
  )

  const handleUpCode = useCallback(
    (currentHighlightedIndex: number) => {
      if (currentHighlightedIndex > 0) {
        return options[currentHighlightedIndex - 1]
      }

      return undefined
    },
    [options]
  )

  const getNextSelectedMetric = useCallback(
    (event) => {
      const currentHighlightedIndex = options.findIndex(
        (metric) => metric.id === highlightedOption?.id
      )

      if (isDownCode(event)) {
        return handleDownCode(currentHighlightedIndex)
      }

      if (isUpCode(event)) {
        return handleUpCode(currentHighlightedIndex)
      }

      return undefined
    },
    [options, highlightedOption, handleDownCode, handleUpCode]
  )

  const onKeyDown = useCallback(
    (event) => {
      if (isEscCode(event)) {
        event.preventDefault()
        event.stopPropagation()
        setIsOpen(false)
      } else if (isEnterKeyCode(event)) {
        event.preventDefault()
        event.stopPropagation()

        if (options.length === 0) {
          onSelectOption()
        } else {
          onSelectOption(highlightedOption)
        }
      } else if (isDownCode(event) || isUpCode(event)) {
        event.preventDefault()
        event.stopPropagation()
        const nextSelectedOption = getNextSelectedMetric(event)

        if (nextSelectedOption) {
          scrollDropdownOptionIntoView(nextSelectedOption, optionsRef)
          setHighlightedOption(nextSelectedOption)
        }
      }
    },
    [getNextSelectedMetric, highlightedOption, onSelectOption, options]
  )

  /**
   * Sends a synthetic event to React Grid to simulate the Enter key press
   * so that the cell opens the dropdown
   */
  const onArrowClick = useCallback(() => {
    setTimeout(() => {
      const syntheticEvent = new KeyboardEvent('keydown', {
        keyCode: keyCodes.ENTER,
      })
      eventHandler.current?.keyDownHandler(syntheticEvent as any)
    }, KEYBOARD_EVENT_DELAY)
  }, [eventHandler])

  return {
    selectedOption,
    isOpen,
    dropdownRef,
    inputHeight,
    optionsRef,
    placeholderId,
    stopPropagation,
    onKeyDown,
    highlightedOption,
    onSelectOption,
    onArrowClick,
  }
}
