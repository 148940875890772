import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IndexPortfolio, PortfolioCompany } from 'utils/types/portfolios'
import { useIntl } from 'react-intl'
import Button from 'ui/Button'
import CompanyService from 'api/CompanyService'
import differenceBy from 'lodash/differenceBy'
import Dropdown from 'ui/Dropdown'
import Modal from 'components/Modal'
import Toast from 'components/Toast'
import useArrayState from 'utils/hooks/useArrayState'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { GroupedPortfolioCompanies } from 'utils/functions/portfolios'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import PortfolioService from 'api/PortfolioService'
import * as Styles from './AddToPortfolioModal.styles'

interface Props {
  isOpen: boolean
  companyPortfolios: GroupedPortfolioCompanies
  onHide: () => void
  companyName: string
  companyId: string
  onChangeCompanyPortfolios: () => Promise<void>
  loadPortfolios: ({
    inputSearch,
    companyPortfolios,
    type,
  }: any) => Promise<IndexPortfolio<PortfolioCompany>[]>
  portfolioType?: PortfolioTypes
  isFundHolding?: boolean
}

const AddToPortfolioModal = ({
  companyPortfolios: currentCompanyPortfolios,
  onHide,
  isOpen,
  companyName,
  companyId,
  onChangeCompanyPortfolios,
  loadPortfolios,
  portfolioType,
  isFundHolding,
}: Props) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [
    companyPortfolios,
    {
      set: setCompanyPortfolios,
      push: pushCompanyPortfolio,
      remove: removeCompanyPortfolio,
    },
  ] = useArrayState<PortfolioCompany>([])
  const [
    removedPortfolios,
    { set: setRemovedPortfolios, push: pushRemovedPortfolios },
  ] = useArrayState<PortfolioCompany>([])
  const allPortfolios = useMemo(
    () => [
      ...currentCompanyPortfolios.fundPortfolios,
      ...currentCompanyPortfolios.investPortfolios,
      ...currentCompanyPortfolios.trackPortfolios,
    ],
    [currentCompanyPortfolios]
  )

  useEffect(() => {
    setCompanyPortfolios(allPortfolios)
    setRemovedPortfolios([])
  }, [allPortfolios, setCompanyPortfolios, setRemovedPortfolios])

  const onCancel = useCallback(() => {
    setCompanyPortfolios(allPortfolios)
    onHide()
  }, [setCompanyPortfolios, allPortfolios, onHide])

  const onConfirm = useCallback(async () => {
    try {
      setLoading(true)
      const addedPortfolios = differenceBy(
        companyPortfolios,
        removedPortfolios,
        (portfolio) => portfolio.id
      ).filter((portfolio) => portfolio.added)

      const deletedPortfolios = differenceBy(
        removedPortfolios,
        companyPortfolios,
        (portfolio) => portfolio.id
      ).filter((portfolio: PortfolioCompany) => !!portfolio.id)

      if (isFundHolding) {
        await PortfolioService.updateFundPortfolios(
          companyId,
          addedPortfolios,
          deletedPortfolios
        )
      } else {
        await CompanyService.updateCompanyPortfolios(
          companyId,
          addedPortfolios,
          deletedPortfolios
        )
      }

      onChangeCompanyPortfolios()
      Toast.display(intl.messages['companyProfile.addPortfolioSuccess'])
    } catch (error) {
      Toast.display(intl.messages['companyProfile.addPortfolioError'], 'error')
    } finally {
      setLoading(false)
    }
  }, [
    companyPortfolios,
    removedPortfolios,
    isFundHolding,
    onChangeCompanyPortfolios,
    intl.messages,
    companyId,
  ])

  const loadPortfolioOptions = React.useCallback(
    (inputSearch) => {
      return loadPortfolios({
        inputSearch,
        companyPortfolios,
      })
    },
    [companyPortfolios, loadPortfolios]
  )

  const getPortfolioIcon = (pType: PortfolioTypes) => (
    <Styles.ChipIconAsButton>
      {getPortfolioIconByType(pType)}
    </Styles.ChipIconAsButton>
  )

  return (
    <Styles.AddPortfolioModal show={isOpen} onHide={onCancel}>
      <Modal.Header onHide={onCancel}>
        <Styles.Header>
          <Modal.Title>
            {intl.formatMessage(
              {
                id: 'companyList.addCompanyToPortfolio',
              },
              { company: companyName }
            )}
          </Modal.Title>
        </Styles.Header>
      </Modal.Header>

      <Modal.Body>
        <Styles.AddInputContainer>
          <Dropdown
            id="portfolios"
            name="portfolios"
            async
            loadOptions={loadPortfolioOptions}
            getOption={(entity) => ({
              label: entity.name,
              id: entity.id,
              value: entity,
              icon: getPortfolioIcon(entity.type),
            })}
            onSelectOption={(_, __, selectedValue) => {
              pushCompanyPortfolio({ ...selectedValue, added: true })
            }}
            placeholder={intl.formatMessage({
              id: 'companyProfile.searchPortfolio',
            })}
            label={intl.formatMessage(
              { id: 'companyProfile.selectPortfolio' },
              { type: portfolioType }
            )}
            type="input"
            icon={['far', 'plus']}
            clearOnSelect
            capitalizeOptions={false}
            highlightSelectedOption={false}
            showLoadingIndicator
            iconFontSize="1.8rem"
          />
        </Styles.AddInputContainer>

        <Styles.Portfolios>
          {companyPortfolios.map((portfolio, index) => (
            <Styles.CustomChip
              key={portfolio.id}
              imageComponent={getPortfolioIcon(
                portfolio.type as PortfolioTypes
              )}
              text={portfolio.name}
              handleDelete={() => {
                pushRemovedPortfolios(companyPortfolios[index])
                removeCompanyPortfolio(index)
              }}
            />
          ))}
        </Styles.Portfolios>
      </Modal.Body>

      <Modal.Footer>
        <Styles.Footer>
          <Button onClick={onCancel} secondary>
            {intl.messages['common.cancel']}
          </Button>

          <Button
            autoWidth
            primary
            blue
            onClick={onConfirm}
            disabled={loading || allPortfolios === companyPortfolios}
          >
            {intl.messages['general.save']}
          </Button>
        </Styles.Footer>
      </Modal.Footer>
    </Styles.AddPortfolioModal>
  )
}

export default AddToPortfolioModal
