import React from 'react'
import styled from 'styled-components/macro'

import quickbooks from 'assets/images/quickbooks.svg'
import xero from 'assets/images/xero.svg'
import { color } from 'utils/functions/colors'

const AccountingIconFactoryWrapper = styled.div`
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 50%;
`

interface AccountingIconFactoryProps {
  type: 'xero' | 'quickbooks'
}

const AccountingIconFactory: React.FC<AccountingIconFactoryProps> = ({
  type,
}) => {
  return (
    <AccountingIconFactoryWrapper>
      <img alt={type} src={type === 'xero' ? xero : quickbooks} />
    </AccountingIconFactoryWrapper>
  )
}

export default AccountingIconFactory
