import { components } from 'react-select'

import * as Styles from '../ShareWithDropdown.styles'

export const Input = (props) => {
  const { selectProps } = props

  return (
    <Styles.InputWrapper hasLeftPadding={!selectProps.value?.length}>
      <components.Input {...props} />
    </Styles.InputWrapper>
  )
}
