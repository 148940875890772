import { useField, useFormikContext } from 'components/Form/hooks'
import { EditInvestorMetricFormType } from './useEditInvestorMetric'

const useEditInvestorMetricForm = () => {
  const { setFieldValue, handleBlur, handleChange, errors } =
    useFormikContext<EditInvestorMetricFormType>()
  const [nameField] = useField<string>('name')
  const [frequencyField] = useField<string>('frequency')

  return {
    nameField,
    frequencyField,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
  }
}

export default useEditInvestorMetricForm
