import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import throttle from 'lodash/throttle'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import omit from 'lodash/omit'
import * as actionCreators from '../actions/types'
import rootReducer from '../reducers'

export const middleware = [thunk, promise]

export const composeEnhancers = composeWithDevTools({
  actionCreators: actionCreators as any,
  trace: true,
  traceLimit: 25,
})

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const preventPersistStateKeys = [
  'uploads',
  'university',
  'auth.showTermsOfUse',
  'auth.confirmationToken',
]

export const saveState = (state) => {
  try {
    const stateCopy = omit(state, preventPersistStateKeys)

    const serializedState = JSON.stringify(stateCopy)
    localStorage.setItem('state', serializedState)
  } catch {
    // ignore write errors
  }
}

export const store = createStore(
  rootReducer,
  loadState(),
  composeEnhancers(applyMiddleware(...middleware))
)

store.subscribe(
  throttle(() => {
    saveState(store.getState())
  }, 1000)
)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
