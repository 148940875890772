import { FC } from 'react'
import { buildFormError } from 'utils/functions/forms'
import Dropdown from 'ui/Dropdown'
import { useField } from 'components/Form/hooks'
import { ErrorType } from 'utils/types/common'
import { AccessType } from 'utils/types/updateForm'

import { FormElementProps } from './types'
import { useInvestorSelector } from './useInvestorSelector'
import { OptionContainer, OptionText } from '../ShareContent.styles'

const InvestorSelector: FC<FormElementProps> = ({ disabled }) => {
  const [_, __, investmentVehiclesHelper] = useField('investmentVehicle')

  const {
    field,
    meta,
    helpers,
    investorPermissionsHelper,
    formik,
    messages,
    hasPrePopulatedInvestor,
    loadInvestors,
    getInvestorOption,
  } = useInvestorSelector()

  const onSelectOptionHandler = (___, ____, option) => {
    helpers.setValue(option)
    investorPermissionsHelper.setValue(AccessType.CAN_VIEW)
    investmentVehiclesHelper.setValue(null, false)
    setTimeout(() => {
      formik.handleBlur({ target: { name: field.name } })
    })
  }

  const renderInvestorOption = (option: {
    id: string
    logo: string
    label: string
  }) => {
    return (
      <OptionContainer>
        <OptionText>{option.label}</OptionText>
      </OptionContainer>
    )
  }

  return (
    <Dropdown
      {...field}
      highlightEnabled
      highlightSelectedOption={false}
      placeholder={messages.placeholder}
      searchPlaceholder={messages.searchPlaceholder}
      type="select"
      label={messages?.label}
      onSelectOption={onSelectOptionHandler}
      loadOptions={loadInvestors}
      error={
        field.value?.populated
          ? buildFormError(messages.error, ErrorType.WARNING, true)
          : buildFormError(meta.error, ErrorType.ERROR, meta.touched)
      }
      borderBold
      async
      searchEnabled
      showBorder
      getOption={getInvestorOption}
      addNewOptionEnabled={false}
      arrowNavigationEnabled
      value={field.value}
      disabled={disabled ?? hasPrePopulatedInvestor}
      formatSelectedOptionComponent={renderInvestorOption}
    />
  )
}

export default InvestorSelector
