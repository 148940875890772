import { TopicEntity } from 'utils/types/chatTopicEntity'
import { Update } from 'utils/types/update'
import ChatErrorHandler from '../../ChatErrorHandler'
import { useChatWidget } from '../useChatWidget'
import UpdateChatWidget from './UpdateChatWidget'

type Props = {
  topic?: TopicEntity
  update?: Update
  showCreateChatModal: boolean
  onHideCreateChatModal: () => void
}

const UpdateChannelList = ({
  topic,
  update,
  showCreateChatModal,
  onHideCreateChatModal,
}: Props) => {
  const { filters, renderChannels, openChatAsPopup } = useChatWidget(
    topic,
    update?.id
  )

  return (
    <UpdateChatWidget
      renderChannels={renderChannels}
      filters={filters}
      showCreateChatModal={showCreateChatModal}
      onHideCreateChatModal={onHideCreateChatModal}
      topic={topic}
      update={update}
      openChatAsPopup={openChatAsPopup}
    />
  )
}

export default (props: Props) => (
  <ChatErrorHandler padding="2.8rem 0 1.6rem 0" smallIcon>
    <UpdateChannelList {...props} />
  </ChatErrorHandler>
)
