import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Logo from 'components/Logo'
import Avatar from 'components/AvatarSVG/Avatar'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getUser } from 'selectors/auth'
import { getSubscriptionData } from 'selectors/subscriptions'
import { SubscriptionStatus } from 'utils/types/subscriptions'

import {
  Footer,
  FooterButtonsContainer,
  SecondHeader,
} from '../../OnboardingModal.styles'
import { OnboardingStep } from '../../useOnboardingModal'
import {
  Action,
  ActionsContainer,
  ActionSubtitle,
  ActionTitle,
  ActionTitleContainer,
  Container,
  IconContainer,
  LogoContainer,
  Subtitle,
  Title,
  Label,
  IconButton,
  Body,
} from './WelcomeStep.styles'
import Assistance from '../Assistance'

interface Props {
  onHide: () => void
  jumpToStep: (step: OnboardingStep) => void
}

const WelcomeStep = ({ onHide, jumpToStep }: Props) => {
  const user = useAppSelector(getUser)
  const subscription = useAppSelector(getSubscriptionData)

  return (
    <>
      <SecondHeader>
        <Container>
          <Title>
            <FormattedMessage
              id="onboardingModal.welcome"
              values={{
                userName: user.name,
              }}
            />
          </Title>
          <Subtitle>
            <FormattedMessage id="onboardingModal.welcomeSubtitle" />
          </Subtitle>

          <LogoContainer>
            <Logo withAnimation />
          </LogoContainer>
        </Container>
      </SecondHeader>
      <Body>
        <Label>
          <FormattedMessage
            id={
              subscription?.stripeStatus === SubscriptionStatus.TRIALING
                ? 'onboardingModal.trialMessage'
                : 'onboardingModal.completeTasks'
            }
          />
        </Label>

        <Separator height="1.6rem" />

        <ActionsContainer>
          <Action>
            <Avatar size="3.6rem" borderWidth={0}>
              <IconContainer>
                <FontAwesomeIcon icon={['far', 'briefcase']} />
              </IconContainer>
            </Avatar>
            <ActionTitleContainer>
              <ActionTitle>
                <FormattedMessage id="onboardingModal.setupYourPipeline" />
              </ActionTitle>
              <ActionSubtitle>
                <FormattedMessage id="onboardingModal.setupYourPipelineDescription" />
              </ActionSubtitle>
            </ActionTitleContainer>
            <IconButton
              buttonSize="3.2rem"
              buttonType="square"
              border={false}
              icon="chevron-down"
              onClick={() => jumpToStep(OnboardingStep.SETUP_YOUR_PIPELINE)}
            />
          </Action>
          <Action>
            <Avatar size="3.6rem" borderWidth={0}>
              <IconContainer>
                <FontAwesomeIcon icon={['far', 'folder']} />
              </IconContainer>
            </Avatar>
            <ActionTitleContainer>
              <ActionTitle>
                <FormattedMessage id="onboardingModal.addYourFiles" />
              </ActionTitle>
              <ActionSubtitle>
                <FormattedMessage id="onboardingModal.addYourFilesDescription" />
              </ActionSubtitle>
            </ActionTitleContainer>
            <IconButton
              buttonSize="3.2rem"
              buttonType="square"
              border={false}
              icon="chevron-down"
              onClick={() => jumpToStep(OnboardingStep.ADD_YOUR_FILES)}
            />
          </Action>
          <Action>
            <Avatar size="3.6rem" borderWidth={0}>
              <IconContainer>
                <FontAwesomeIcon icon={['far', 'cog']} />
              </IconContainer>
            </Avatar>
            <ActionTitleContainer>
              <ActionTitle>
                <FormattedMessage id="onboardingModal.setupYourInbound" />
              </ActionTitle>
              <ActionSubtitle>
                <FormattedMessage id="onboardingModal.setupYourInboundDescription" />
              </ActionSubtitle>
            </ActionTitleContainer>
            <IconButton
              buttonSize="3.2rem"
              buttonType="square"
              border={false}
              icon="chevron-down"
              onClick={() => jumpToStep(OnboardingStep.SETUP_YOUR_INBOUND)}
            />
          </Action>
        </ActionsContainer>

        <Separator height="2rem" />

        <Label>
          <FormattedMessage id="onboardingModal.assistanceQuestion" />
        </Label>

        <Separator height="1.6rem" />

        <Assistance />
      </Body>

      <Footer>
        <FooterButtonsContainer>
          <Button onClick={onHide}>
            <FormattedMessage id="general.cancel" />
          </Button>
          <Button
            primary
            onClick={() => jumpToStep(OnboardingStep.SETUP_YOUR_PIPELINE)}
          >
            <FormattedMessage id="onboardingModal.letsStart" />
          </Button>
        </FooterButtonsContainer>
      </Footer>
    </>
  )
}

export default WelcomeStep
