import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'

export const Container = styled.div<{
  isLast?: boolean
  hasActionButtons?: boolean
}>`
  display: grid;
  grid-template-columns: 1.2fr 1fr auto;
  align-items: center;
  gap: 1rem;
  padding: ${({ hasActionButtons }) =>
    hasActionButtons ? '0.4rem 0' : '1.55rem 0'};
  ${(props) =>
    props.isLast
      ? css`
          padding-bottom: 0;
        `
      : css`
          border-bottom: 1px solid ${color('veryLightGray')(props)};
        `}
`

export const FileName = styled.span`
  color: ${color('darkBlue')};
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  overflow: hidden;
  font-size: 1.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Status = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  text-align: right;
  color: ${color('darkGray')};
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

export const Icon = styled(FontAwesomeIcon)`
  font-size: 1.6rem;
  margin-left: 1.4rem;
  margin-right: 1.4rem;
`
