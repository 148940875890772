import React from 'react'
import PropTypes from 'prop-types'

import { isValidHttpUrl } from 'utils/functions/browser'
import missingPNGPath from 'assets/images/missing.png'

const Image = ({ src, ...rest }) => {
  return (
    <object
      data={isValidHttpUrl(src) ? src : 'https://'}
      type="image/png"
      {...rest}
    >
      <img src={missingPNGPath} alt="missing placeholder" />
    </object>
  )
}

Image.propTypes = {
  src: PropTypes.string,
}

Image.defaultProps = {
  src: '',
}

export default Image
