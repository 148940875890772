import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'
import Tooltip from 'components/Tooltip'

const ellipsisWithBreakLines = (maxLines: number) => css`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`

export const StyledItem = styled.div<{ maxLines?: number; isDark?: boolean }>`
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  gap: 17px;
  font-weight: 400;
  line-height: 1.7rem;

  a {
    color: ${color('primaryBlue')};
    font-weight: 700;
    line-height: 17px;
    text-decoration-line: underline;
  }

  svg {
    color: ${color('darkBlue')};
    font-size: 1.6rem;
  }

  .mailItem {
    text-decoration: underline;
    ${(props) =>
      props.maxLines !== undefined && ellipsisWithBreakLines(props.maxLines)}
  }

  .darkLink {
    ${(props) =>
      props.isDark &&
      css`
        color: ${color('darkBlue')};
        text-decoration: none;
      `}

    ${(props) =>
      props.maxLines !== undefined && ellipsisWithBreakLines(props.maxLines)}
  }

  .tooltip {
    color: ${color('darkGray')};
    font-weight: normal;
    ${(props) =>
      props.maxLines !== undefined && ellipsisWithBreakLines(props.maxLines)}

    ${(props) =>
      props.isDark &&
      css`
        color: ${color('darkBlue')};
        font-weight: 700;
      `}

    @media ${maxSize.xl} {
      min-width: fit-content;
    }
  }
`

export const StyledToolTip = styled(Tooltip)`
  a {
    text-decoration: none;
  }
`
