/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

const initialState = {
  emailSubject: '',
  selectedDrafts: [],
}

const setEmailSubject = (draftState, emailSubject) => {
  draftState.emailSubject = emailSubject
  return draftState
}

const setCurrentSelectedDrafts = (draftState, selectedDrafts) => {
  draftState.selectedDrafts = selectedDrafts
  return draftState
}

const handlers = {
  [Types.SET_EMAIL_SUBJECT]: setEmailSubject,
  [Types.SET_CURRENT_SELECTED_DRAFTS]: setCurrentSelectedDrafts,
}

export default createReducer(initialState, handlers)
