import Tooltip from 'components/Tooltip'
import React from 'react'
import theme from 'utils/theme'

interface Props {
  children: React.ReactNode
  text: string
  id: string
}

const TooltipActionWrapper = ({ children, text, id }: Props) => {
  return (
    <Tooltip
      id={id}
      place="bottom"
      text={text}
      delayShow={500}
      backgroundColor={theme.colors.white}
      color={theme.colors.gray}
      border={`1px solid ${theme.colors.veryLightGray}`}
      boxShadow="0px 0px 15px 0px rgba(16, 21, 39, 0.10)"
      margin="0.5rem 0 0 0"
    >
      {children}
    </Tooltip>
  )
}

export default TooltipActionWrapper
