import { FormattedMessage } from 'react-intl'

import { FileContent } from 'utils/types/files'
import Button from 'ui/Button'

import { Container } from './NoPreviewAvailable.styles'

interface NoPreviewAvailableProps {
  file: FileContent
  onDownloadFile: (file: FileContent) => void
}

const NoPreviewAvailable = ({
  file,
  onDownloadFile,
}: NoPreviewAvailableProps) => {
  return (
    <Container>
      <FormattedMessage
        id="previewFiles.noPreviewAvailable"
        values={{
          fileName: file.fileName,
        }}
      />
      <Button
        primary
        onClick={() => onDownloadFile(file)}
        icon={['far', 'download']}
      >
        <FormattedMessage id="general.download" />
      </Button>
    </Container>
  )
}

export default NoPreviewAvailable
