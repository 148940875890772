import { useEffect } from 'react'
import RawReport from 'components/AccountingReports/RawReport'
import { useForm } from 'components/Form/hooks'
import Tabs from 'components/Tabs'
import Analytics from 'containers/Analytics'
import Logs from 'containers/Logs/Logs'
import { LogType } from 'containers/Logs/models/types'
import { isIndexFileContent } from 'utils/functions/files'
import { useGetUpdateTabs } from 'utils/functions/renderers/renderUpdatesHelper'
import { ReactChildren, UpdateType } from 'utils/types/common'
import { Update } from 'utils/types/update'
import { UpdateFormContext } from 'utils/types/updateForm'
import { getUserId } from 'selectors/auth'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import {
  PREVIEW_DRAFT_PATH,
  useIsPreviewingDraftUpdate,
} from 'utils/hooks/useIsPreviewingDraftUpdate'
import { UpdateTabId } from 'utils/constants/updates'
import UpdateChannelList from 'containers/Chat/components/ChatWidgets/UpdateChatWidget/UpdateChannelList'
import { getHasChatFeature } from 'selectors/chat'
import { buildHoldingTopicEntity } from 'utils/types/chatTopicEntity'
import PreviewUpdateButton from '../PreviewUpdateButton'
import { StyledCard } from './UpdateFormWrapper.styles'

interface UpdateFormWrapperProps {
  updateType: UpdateType
  children: ReactChildren
  logType?: LogType
  update?: Update
  hasEditPermissions: boolean
  showCreateChatModal: boolean
  handleShowCreateChatModal: (value: boolean) => void
  accountingRawData?: any
}

const UpdateFormWrapper: React.FC<UpdateFormWrapperProps> = ({
  updateType,
  children,
  logType,
  update,
  hasEditPermissions,
  accountingRawData,
  showCreateChatModal,
  handleShowCreateChatModal,
}) => {
  const { isEditMode, isShowMode, hasDraftMode, hasDraftUpdate } =
    useForm<UpdateFormContext>()
  const history = useHistory()
  const hasPublishedUpdate = !!update?.item.title
  const currentUserId = useAppSelector(getUserId)
  const isPreviewingDraft = useIsPreviewingDraftUpdate()
  const hasChatFeature = useAppSelector(getHasChatFeature)
  const showAnalytics =
    update && (update.userId === currentUserId || update.ownedByGroup)
  const showPreviewUpdateButton =
    isShowMode && hasDraftMode && hasPublishedUpdate && hasDraftUpdate

  const topic = update?.holding
    ? buildHoldingTopicEntity(update.holding)
    : undefined

  const shouldPreviewDraftRoute =
    (showPreviewUpdateButton && !isPreviewingDraft) ||
    (!hasPublishedUpdate && hasDraftUpdate)

  const tabs = useGetUpdateTabs({
    updateType,
    isEditMode,
    isShowMode,
    hasEditPermissions,
    hasDraftUpdate,
    hasPublishedUpdate,
    hasChatFeature,
    showIcons: false,
    topic,
    update,
  })

  useEffect(() => {
    if (!isEditMode && shouldPreviewDraftRoute && !isPreviewingDraft) {
      const isOnPreviewingDraftRoute =
        history.location.pathname.includes(PREVIEW_DRAFT_PATH)
      if (!isOnPreviewingDraftRoute) {
        history.replace({
          pathname: `${history.location.pathname}${PREVIEW_DRAFT_PATH}`,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, showPreviewUpdateButton])

  return (
    <StyledCard padding="0.5rem 3.2rem 3.2rem 3.2rem">
      {showPreviewUpdateButton && <PreviewUpdateButton />}

      <Tabs initialIndex={0} withBorder tabOffset={32} items={tabs}>
        <Tabs.TabContent id={UpdateTabId.GENERAL}>{children}</Tabs.TabContent>

        {hasChatFeature && isShowMode && (
          <Tabs.TabContent id={UpdateTabId.CHAT}>
            <UpdateChannelList
              topic={topic}
              update={update}
              showCreateChatModal={showCreateChatModal}
              onHideCreateChatModal={() => handleShowCreateChatModal(false)}
            />
          </Tabs.TabContent>
        )}

        <Tabs.TabContent id={UpdateTabId.RAW_DATA}>
          <RawReport
            data={accountingRawData}
            reportName={accountingRawData?.reportTitles?.join?.('_')}
          />
        </Tabs.TabContent>
        {showAnalytics && (
          <Tabs.TabContent id={UpdateTabId.ANALYTICS}>
            <Analytics
              updateId={update.id}
              updateCreationDate={update.createdAt}
              totalViews={update.totalViews}
              uniqueViews={update.uniqueViews}
              attachments={update.contents.filter(isIndexFileContent)} // TODO review this filter
            />
          </Tabs.TabContent>
        )}

        {update?.id && logType && (
          <Tabs.TabContent id="logs">
            <Logs entityId={update?.item.id!} logType={logType} />
          </Tabs.TabContent>
        )}
      </Tabs>
    </StyledCard>
  )
}

export default UpdateFormWrapper
