import ProtectedRoute from 'components/ProtectedRoute'
import { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import CompanyProfileEditDrawer from 'containers/Profiles/CompanyProfileEditDrawer'
import UpdateAllMetricsModal from 'containers/Metrics/UpdateAllMetricsModal/UpdateAllMetricsModal'
import useFounderBreadcrumb from 'components/Breadcrumb/BreadcrumbNavigation/Founder/hooks/useFounderBreadcrumb'
import { getCurrentBaseRoute } from 'reducers/breadcrumbSlice'
import { founderPaths } from 'components/Breadcrumb/BreadcrumbNavigation/constants/routes'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import Breadcrumb from 'components/Breadcrumb/Breadcrumb'
import useShowBreadcrumb from 'components/Breadcrumb/BreadcrumbNavigation/hooks/useShowBreadcrumb'
import { CreateNewGroupProvider } from 'containers/Signup/context/CreateNewGroupContextProvider'
import CreateGroupWizard from 'containers/Signup/SignupWizard/CreateGroupWizard'
import SidebarMetricsRoutes from './SidebarMetricsRoutes'
import UpdatesRoutes from './UpdatesRoutes'
import CommonRoutes from './commonRoutes/CommonRoutes'
import {
  CREATE_GROUP,
  DASHBOARD,
  DASHBOARD_ALL_UPDATES,
  DASHBOARD_SOME_TAB_EDIT,
  DASHBOARD_METRICS_UPDATE_ALL,
  DASHBOARD_METRICS_ID,
  COMPANIES_ALL_UPDATES,
  DASHBOARD_ROUTES,
} from './constant/founderRoutes'
import { COMPANY_PROFILE } from './constant/companiesRoutes'

const Dashboard = lazy(() => import('containers/Dashboard/Dashboard'))

const FounderGroupRoutes = () => {
  const baseRoute = useAppSelector(getCurrentBaseRoute)
  const planFeatures = usePlanFeatures()

  const { showBreadcrumb } = useShowBreadcrumb({
    paths: founderPaths,
  })
  const { navigationComponentFunc } = useFounderBreadcrumb({
    baseRoute,
  })

  return (
    <Breadcrumb
      isFounder
      showBreadcrumb={showBreadcrumb}
      baseRoute={baseRoute}
      navigationComponentFunc={navigationComponentFunc}
    >
      <Route path={CREATE_GROUP}>
        <CreateNewGroupProvider>
          <CreateGroupWizard />
        </CreateNewGroupProvider>
      </Route>

      <ProtectedRoute path={DASHBOARD}>
        <Switch>
          <Route exact path={DASHBOARD_ROUTES}>
            <Dashboard />
          </Route>
        </Switch>

        <Switch>
          <Route path={[DASHBOARD_ALL_UPDATES]}>
            <UpdatesRoutes />
          </Route>
        </Switch>

        <Switch>
          <Route exact path={DASHBOARD_SOME_TAB_EDIT}>
            <CompanyProfileEditDrawer />
          </Route>
        </Switch>

        {planFeatures.areMetricsEnabled && (
          <Switch>
            <Route exact path={DASHBOARD_METRICS_UPDATE_ALL}>
              <UpdateAllMetricsModal />
            </Route>
            <Route path={DASHBOARD_METRICS_ID}>
              <SidebarMetricsRoutes urlPrefix="/dashboard/metrics" />
            </Route>
          </Switch>
        )}
      </ProtectedRoute>

      <Switch>
        <Route path={[COMPANIES_ALL_UPDATES, COMPANY_PROFILE]}>
          <UpdatesRoutes />
        </Route>
      </Switch>

      <CommonRoutes />
    </Breadcrumb>
  )
}

export default FounderGroupRoutes
