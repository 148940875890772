import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FileContent } from 'utils/types/files'

import * as Styles from './NoPreviewAvailable.styles'

interface NoPreviewAvailableProps {
  file: FileContent
}

const NoPreviewAvailable: React.FC<NoPreviewAvailableProps> = ({ file }) => {
  return (
    <Styles.NoPreviewAvailable>
      <FormattedMessage
        id="files.details.noPreviewAvailable"
        values={{ name: file.fileName }}
      />
    </Styles.NoPreviewAvailable>
  )
}

export default NoPreviewAvailable
