import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { ErrorType } from '../../utils/types/common'

export const InputError = styled.span<{
  type?: ErrorType
  large?: boolean
  hasError?: boolean
  displayOnError?: boolean
}>`
  display: inline-block;
  margin-top: 0.8rem;
  margin-right: 0.8rem;
  margin-bottom: 0;
  margin-left: ${(props) => (props.large ? '1.6rem' : '0.8rem')};
  display: ${(props) => props.displayOnError && !props.hasError && 'none'};

  ${(props) =>
    props.displayOnError &&
    !props.hasError &&
    css`
      display: none;
    `};

  color: ${(props) => {
    switch (props.type) {
      case ErrorType.ERROR:
      case ErrorType.REQUIRED:
        return color('red')
      case ErrorType.WARNING:
        return color('darkYellow')
      case ErrorType.MESSAGE:
        return color('lightGray')
      default:
        return color('red')
    }
  }};
  font-size: ${(props) => (props.large ? '1.4rem' : '1.2rem')};

  &:first-letter {
    text-transform: capitalize;
  }
`
