import { useIsPreviewingDraftUpdate } from 'utils/hooks/useIsPreviewingDraftUpdate'
import EditUpdateButton from 'containers/UpdatesV2/components/UpdateHeader/components/EditUpdateButton'
import { DraftHashEditor, Update } from 'utils/types/update'
import useEditUpdateAction from './useEditUpdateAction'

interface Props {
  hasEditPermissions: boolean
  update?: Update
  isEditMode: boolean
  isEditingDraft: boolean
  isSubmitting: boolean
}

const EditUpdateAction = ({
  isSubmitting,
  update,
  isEditMode,
  hasEditPermissions,
  isEditingDraft,
}: Props) => {
  const isPreviewingDraft = useIsPreviewingDraftUpdate()

  const { isEditable, redirectToEditView } = useEditUpdateAction({
    update,
    isEditMode,
    hasEditPermissions,
  })

  const isDraftUpdate = update?.isDraftUpdate

  if (!isEditable) return null

  return (
    <EditUpdateButton
      disabled={
        isSubmitting || (isDraftUpdate && !isPreviewingDraft && !isEditingDraft)
      }
      editionLocked={update?.draftHash as DraftHashEditor}
      redirectToEditView={redirectToEditView}
    />
  )
}

export default EditUpdateAction
