import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const DateRange = styled.div<{ isAnyDateActive?: boolean }>`
  color: ${color('darkGray')};
  display: flex;
  font-size: 1.4rem;
  gap: 1rem;
  height: 3.6rem;
  align-items: center;
  padding: 1rem 1.4rem;
  border-radius: 0.8rem;

  svg {
    font-size: 1.6rem;
  }

  ${(props) =>
    props.isAnyDateActive
      ? css`
          border: 1px solid ${color('primaryBlue')};
        `
      : css`
          border: 1px solid transparent;
        `}
`

export const DropdownsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`

export const DateWrapper = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) =>
    isActive ? color('primaryBlue') : color('darkGray')};

  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  text-decoration: ${({ isActive }) => (isActive ? 'underline' : 'none')};

  &:hover {
    color: ${color('primaryBlue')};
    text-decoration: underline;
  }
`
