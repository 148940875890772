import { useEffect, useState } from 'react'
import { getCurrentGroupId } from 'selectors/auth'
import { UpdateTypes } from 'utils/constants/updates'
import { getUpdateUrlType } from 'utils/functions/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { UpdateType } from 'utils/types/common'
import { IUEReportItem, Update } from 'utils/types/update'

interface Props {
  update?: Update
  updateType: UpdateType
  hasDraftUpdate: boolean
  isShowMode: boolean
}

const useCopyLinkedAction = ({
  update,
  updateType,
  hasDraftUpdate,
  isShowMode,
}: Props) => {
  const [copied, setCopied] = useState(false)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const link = `${window.origin}/updates/${getUpdateUrlType(updateType)}/${
    update?.id
  }?group_id=${currentGroupId}&shared=true`

  const hasPublishedUpdate =
    (!!update?.item.title && updateType !== UpdateTypes.IUE) ||
    !!(update?.item as IUEReportItem)?.subject

  const showCopyLinkButton = hasPublishedUpdate && !hasDraftUpdate && isShowMode

  const handleCopyLink = (event) => {
    event.preventDefault()
    navigator.clipboard.writeText(link)
    setCopied(true)
  }

  useEffect(() => {
    const timer = setTimeout(() => setCopied(false), 1000)
    return () => clearTimeout(timer)
  }, [copied])

  return {
    copied,
    showCopyLinkButton,
    handleCopyLink,
  }
}

export default useCopyLinkedAction
