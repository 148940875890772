import { useFormikContext } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'

import theme from 'utils/theme'
import { User } from 'utils/types/user'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import ToastMessage from 'components/ToastMessage'
import { getUser } from 'selectors/auth'

import { Toggle, Container } from './GroupManagedToggle.styles'

interface Props {
  inboundOwner?: User
}

const GroupManagedToggle = ({ inboundOwner }: Props) => {
  const intl = useIntl()
  const currentUser = useAppSelector(getUser)
  const isMyInbound = !inboundOwner || currentUser?.id === inboundOwner?.id

  const formik = useFormikContext<{
    allowMyGroupToReceiveSuggestedUpdates: boolean
  }>()
  const { isIndividualWithOnlyOneMaxUser } = usePlanFeatures()

  if (!isIndividualWithOnlyOneMaxUser) {
    return (
      <Container>
        <Toggle
          id="allowMyGroupToReceiveSuggestedUpdates"
          name="allowMyGroupToReceiveSuggestedUpdates"
          status={formik.values.allowMyGroupToReceiveSuggestedUpdates}
          disableAnimationOnMount
          legend={intl.formatMessage({
            id: 'inbounds.allowMyGroupToReceiveSuggestedUpdates',
          })}
          color={theme.colors.primaryBlue}
          onChangeStatus={(value: boolean) => {
            formik.setFieldValue('allowMyGroupToReceiveSuggestedUpdates', value)
          }}
        />
        {!formik.values.allowMyGroupToReceiveSuggestedUpdates && (
          <ToastMessage icon={['far', 'info-circle']}>
            {isMyInbound ? (
              <FormattedMessage id="inbounds.suggestedUpdatesOnlyToYou" />
            ) : (
              <FormattedMessage
                id="inbounds.suggestedUpdatesOnlyToOwner"
                values={{ owner: inboundOwner.name }}
              />
            )}
          </ToastMessage>
        )}
      </Container>
    )
  }

  return null
}

export default GroupManagedToggle
