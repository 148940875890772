import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GroupLogo from 'components/GroupLogo'

import {
  EntityRowContainer,
  EntityWrapper,
  EmailLogo,
  EntityName,
  EntityType,
  EntityHandle,
  PortfolioEntityWrapper,
  PortfolioEntityName,
} from './GroupsUsersAddInput.styles'

const DropdownEntity = ({ entity, isSelected, onClickHandler }) => {
  if (entity?.type === 'Portfolio' || entity?.type === 'Invest') {
    return (
      <PortfolioEntityWrapper padding="1rem 1.2rem">
        <FontAwesomeIcon icon={['far', 'dollar-sign']} />
        <PortfolioEntityName>{entity?.name}</PortfolioEntityName>
      </PortfolioEntityWrapper>
    )
  }

  if (entity?.type === 'Fund') {
    return (
      <PortfolioEntityWrapper padding="1rem 1.2rem">
        <FontAwesomeIcon icon={['far', 'sack-dollar']} />
        <PortfolioEntityName>{entity?.name}</PortfolioEntityName>
      </PortfolioEntityWrapper>
    )
  }

  if (entity.type === 'User') {
    if (!entity.name) {
      return (
        <EntityRowContainer padding="0.75rem 1.2rem">
          <EntityWrapper>
            <EmailLogo className="ignore-highlight">
              <FontAwesomeIcon icon={['far', 'envelope']} />
            </EmailLogo>
            <EntityName>{entity?.email}</EntityName>
          </EntityWrapper>
        </EntityRowContainer>
      )
    }

    return (
      <EntityRowContainer padding="0.67rem 1.2rem">
        <EntityWrapper>
          <GroupLogo forceInitials name={entity.name} small />
          <EntityName>
            {entity.name}
            <EntityType className="ignore-highlight" isSelected={isSelected}>
              {' '}
              · {entity.type}
            </EntityType>
          </EntityName>
        </EntityWrapper>
        <EntityHandle isSelected={isSelected}>{entity.email}</EntityHandle>
      </EntityRowContainer>
    )
  }

  if (entity.type === 'Company') {
    return (
      <EntityRowContainer onClick={onClickHandler} padding="0.67rem 1.2rem">
        <EntityWrapper>
          <GroupLogo imageUrl={entity?.logo} name={entity?.name} small />
          <EntityName>{entity?.name}</EntityName>
        </EntityWrapper>
        <EntityHandle isSelected={isSelected}>
          {entity?.handle ? `@${entity.handle}` : ''}
        </EntityHandle>
      </EntityRowContainer>
    )
  }

  return (
    <EntityRowContainer padding="0.67rem 1.2rem">
      <EntityWrapper>
        <GroupLogo
          imageUrl={entity?.logo}
          name={entity?.name ?? entity?.email}
          small
        />
        <EntityName>
          {entity?.name}

          {entity.type && (
            <EntityType className="ignore-highlight" isSelected={isSelected}>
              {' '}
              · {entity.type}
            </EntityType>
          )}
        </EntityName>
      </EntityWrapper>
      <EntityHandle isSelected={isSelected}>
        {entity?.handle ? `@${entity.handle}` : ''}
      </EntityHandle>
    </EntityRowContainer>
  )
}

DropdownEntity.propTypes = {
  entity: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    logo: PropTypes.string,
    handle: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func,
}
export default DropdownEntity
