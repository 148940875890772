import { useFetchUpdateQuery } from 'utils/hooks/queries/useUpdateQuery'
import { UpdateType } from 'utils/types/common'

import { getChannelTypeFromTopicEntityType } from 'containers/Chat/helpers'
import { useQuerySimilarChannel } from 'containers/Chat/hooks/useQuerySimilarChannel'
import { StreamChatType, isTopicChannel } from 'containers/Chat/types'
import { useMemo } from 'react'
import { useChannelStateContext } from 'stream-chat-react'
import { useChatMembers } from 'utils/hooks/useChatMembers'
import {
  buildHoldingTopicEntity,
  buildPortfolioTopicEntity,
} from 'utils/types/chatTopicEntity'
import CreateNewChatSuggestionBox from './CreateNewChatSuggestionBox'
import ExistentChatSuggestion from './ExistentChatSuggestion'

type Props = {
  updateId: string
  updateType: UpdateType
}

const CreateNewUpdateChatSuggestion = ({ updateId, updateType }: Props) => {
  const { channel } = useChannelStateContext<StreamChatType>()

  const { data: update } = useFetchUpdateQuery({
    updateId,
    updateType,
    preventRedirectOnPermissionsError: true,
    retry: false,
  })

  const topicEntity = useMemo(() => {
    if (!update) return undefined

    return update.holding
      ? buildHoldingTopicEntity(update.holding)
      : buildPortfolioTopicEntity(update.portfolio!)
  }, [update])

  const { members } = useChatMembers()

  const { data: similarChannel, isLoading: isLoadingSimilarChannel } =
    useQuerySimilarChannel({
      entityId: topicEntity?.id!,
      entityType: topicEntity?.entityType!,
      channelType: getChannelTypeFromTopicEntityType(topicEntity?.entityType!),
      updateId,
      memberIds: members.map((member) => member.user_id!),
      enabled: !!topicEntity,
    })

  if (
    !topicEntity ||
    (isTopicChannel(channel.data) &&
      channel.data.entityId === topicEntity.id &&
      channel.data.updateId === updateId)
  ) {
    return null
  }

  if (isLoadingSimilarChannel) {
    return null
  }

  if (similarChannel) {
    return (
      <ExistentChatSuggestion
        topicEntity={topicEntity}
        existentChannel={similarChannel!}
        textIntlKey="chat.alreadyExistentChatUpdate"
        update={update}
      />
    )
  }

  return (
    <CreateNewChatSuggestionBox topicEntity={topicEntity} update={update} />
  )
}

export default CreateNewUpdateChatSuggestion
