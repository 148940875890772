import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { CountryDropdown as CountryDropdownToStyle } from 'ui/CountryInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const FlagContainer = styled.div<{ large?: boolean }>`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;

  position: absolute;
  z-index: 1;
  top: 3.1rem;
  left: 1.6rem;

  ${(props) =>
    props.large &&
    css`
      top: 4.2rem;
      left: 2.4rem;
    `}

  &:hover {
    color: ${color('primaryBlue')};
  }
`

const CircleStyles = css<{ large?: boolean }>`
  width: ${(props) => (props.large ? '2.8rem' : '2rem')};
  height: ${(props) => (props.large ? '2.8rem' : '2rem')};
  border-radius: 50%;
`

export const Flag = styled.img<{ large?: boolean }>`
  ${CircleStyles}
  object-fit: cover;
`

export const EmptyCircle = styled.div`
  ${CircleStyles}
  border: 1px solid ${color('veryLightGray')};
`

export const ChevronDown = styled(FontAwesomeIcon)<{ large?: boolean }>`
  font-size: ${(props) => (props.large ? '1.6rem' : '1.4rem')};
  margin-bottom: ${(props) => (props.large ? '0.2rem' : '0')};
`

export const CountryDropdown = styled(CountryDropdownToStyle)<{
  hasError?: boolean
}>`
  ${({ hasError }) =>
    hasError &&
    css`
      top: calc(100% - 1.9rem);
    `}
`
