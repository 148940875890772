import { isIndexEmailContent, isIndexFileContent } from 'utils/functions/files'
import { MixedContent } from 'utils/types/files'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

export const useMixedContent = (content: MixedContent) => {
  const isFileContent = isIndexFileContent(content)
  const isEmailContent = isIndexEmailContent(content)
  const fileFormat = isFileContent ? content.fileFormat : undefined
  const url = isFileContent ? content.url : undefined
  let contentName = isFileContent ? content.fileName : ''
  // TAS - 13671: added ? after content.data to prevent crash
  contentName = isEmailContent ? content.data?.title : contentName

  // TAS - 13671: Capture missing email content data
  useEffect(() => {
    if (isEmailContent && !content.data) {
      Sentry.captureMessage('Email content data is missing', {
        extra: { content },
      })
    }
  }, [content, isEmailContent])

  return {
    isEmailContent,
    contentName,
    fileFormat,
    url,
  }
}
