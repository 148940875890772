import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const StyledPortfolioIcon = styled.div`
  min-height: 3.8rem;
  min-width: 3.8rem;
  border-radius: 10rem;
  border: none;
  position: relative;
  font-size: 1.6rem;
  color: ${color('darkBlue')};
  border: 0.1rem solid var(--color-gray-300);

  > svg {
    fill: var(--color-gray-500);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 77%;
  }

  ${(props) =>
    props.isActive &&
    css`
      color: ${color('white')};
      background: ${color('primaryBlue')};
      border: none;

      > svg {
        fill: var(--color-white);
      }
    `}

  ${(props) =>
    props.noBackground &&
    css`
      background: transparent;
    `}

  ${(props) =>
    props.backgroundColor &&
    css`
      background: ${props.backgroundColor};
    `}
`
