import styled from 'styled-components'
import Dropdown from 'ui/Dropdown'

export const StyledDropdown = styled(Dropdown)`
  min-width: 20rem;
`

export const TagsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0.2rem;
  gap: 1.3rem;
  flex-grow: 1;
  min-width: 0;
`
