import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'ui/Button'
import { color } from 'utils/functions/colors'

export const Container = styled.div`
  position: relative;
  background-color: ${color('white')};
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
  font-size: 1.4rem;
  padding: 1.2rem 1.6rem;
  font-weight: 700;
  color: ${color('darkGray')};
`

export const Visibility = styled.div`
  display: flex;
  gap: 0.4rem;
  color: ${color('lightGray')};
  font-weight: 400;
  padding-bottom: 0.3rem;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  padding-top: 0.8rem;
`

export const ThinButton = styled(Button)`
  padding: 0.6rem 1.2rem;
  color: ${({ primary }) => (primary ? color('white') : color('darkBlue'))};
`

export const CloseIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  cursor: pointer;
  color: ${color('black')};
`
