export function round(value, decimals) {
  const integerPart = Math.round(`${value}e${decimals}`)
  return Number(`${integerPart}e-${decimals}`)
}

export const randomId = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15)

export const getRandomInt = (minRange, maxRange) => {
  const minimum = Math.ceil(minRange)
  const maximum = Math.floor(maxRange)
  return Math.floor(Math.random() * (maximum - minimum + 1) + minimum)
}

const truncateToOneDecimal = (value) => {
  const dotIndex = value.indexOf('.')
  const newValue = dotIndex !== -1 ? value.slice(0, dotIndex + 2) : value
  return newValue.replace(/\.0$/, '')
}

export const hasDecimals = (value) => value % 1 !== 0

export const withDecimals = (value, precision = 2) => {
  const numberValue = Number(value)
  if (hasDecimals(numberValue)) {
    return numberValue.toFixed(precision)
  }
  return numberValue
}

export const displayThousands = (value) => {
  let returnValue = ''
  if (Math.abs(value) >= 1000000000) {
    returnValue = truncateToOneDecimal(
      `${(parseInt(value, 10) / 1000000000).toString()}`
    )
    return `${returnValue}B`
  }

  if (Math.abs(value) >= 1000000) {
    returnValue = truncateToOneDecimal(
      `${(parseInt(value, 10) / 1000000).toString()}`
    )
    return `${returnValue}MM`
  }

  if (Math.abs(value) >= 1000) {
    returnValue = truncateToOneDecimal(
      `${(parseInt(value, 10) / 1000).toString()}`
    )
    return `${returnValue}k`
  }

  return value
}

export const roundMillions = (value) => {
  if (Math.abs(value) >= 1000000000) {
    return Math.ceil(value / 1000000) * 1000000
  }

  if (Math.abs(value) >= 1000000) {
    return Math.ceil(value / 1000000) * 1000000
  }

  if (Math.abs(value) >= 1000) {
    return Math.ceil(value / 1000) * 1000
  }

  return value
}

export const formatPercentage = (value) => {
  const cleanValue = value ? String(value).replace('%', '') : undefined
  return value ? `${cleanValue}%` : ''
}

export const parsePercentage = (val) => {
  if (!val || val === '%') {
    return ''
  }
  return String(parseFloat(val.replace('%', '')))
}

export const displayCurrency = (
  intl,
  value,
  defaultValue = '-',
  config = {}
) => {
  if (!value) return defaultValue

  return intl.formatNumber(value, {
    currency: 'USD',
    style: 'currency',
    maximumFractionDigits: 0,
    ...config,
  })
}

export const displayNumber = (value, defaultValue = '-') => {
  if (value === 0) return 0
  if (!value) return defaultValue
  return value
}
/*
 * Locale aware number parsing based on https://stackoverflow.com/a/72845810
 * and improved to support locales with thousands separators
 */
const DECIMAL_SEPARATOR = 'decimal'

export const parseNumber = (value) => {
  if (value === '') {
    return NaN
  }

  const locale = window.navigator.language
  const decimalExample = Intl.NumberFormat(locale).format(1.1)
  let normalized = value.replace(decimalExample.charAt(1), DECIMAL_SEPARATOR)
  normalized = normalized.replace(/\s/g, '')
  normalized = normalized.replace('$', '')
  normalized = normalized.replace(/,|\./g, '')
  normalized = normalized.replace(DECIMAL_SEPARATOR, '.')
  return Number(normalized)
}

export const parsePercentageNumber = (value) => {
  if (value === '') {
    return NaN
  }

  let valueWithoutPercentage = value.replace('%', '')
  valueWithoutPercentage = valueWithoutPercentage.replace(/\s/g, '')
  return parseNumber(valueWithoutPercentage)
}

export const renderCurrency = (intl, value) => {
  return intl
    .formatNumber(value, {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })
    .replace(/\s+/g, '')
}
