import PaddedLayout from 'layouts/PaddedLayout'
import { Route, Switch } from 'react-router-dom'
import { RouteType } from 'routes/types/common'

const useRoute = () => {
  const getPaths = (paths) => paths.map(({ path }) => path)

  const renderPaths = ({
    routes,
    addPaddedLayout,
  }: {
    routes: RouteType[]
    addPaddedLayout: boolean
  }) => (
    <Switch>
      {routes.map(({ path, component, exact, key }) => (
        <Route key={key} exact={exact} path={path}>
          {addPaddedLayout ? (
            <PaddedLayout>{component}</PaddedLayout>
          ) : (
            component
          )}
        </Route>
      ))}
    </Switch>
  )

  return { getPaths, renderPaths }
}

export default useRoute
