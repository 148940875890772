/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react'
import { Formik } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { Helmet } from 'components/Helmet'
import { useHistory, useLocation } from 'react-router-dom'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import CollapsedPermissionsTagsCard from 'components/CollapsedPermissionsTagsCard'
import EditUpdateContentLoader from 'components/loaders/editUpdateContentLoader'
import type { TabItem } from 'components/Tabs'
import UpdateLayout from 'components/UpdateLayout'
import UpdateNavigationHeader from 'components/UpdateNavigationHeader'
import Logs from 'containers/Logs/Logs'
import SetUpdatePermissions from 'containers/Updates/SetUpdatePermissions'
import ShowUpdateContent from 'containers/UpdatesV2/ShowUpdateContent'

import { getCurrentCompany } from 'selectors/company'
import Separator from 'ui/Separator'
import TextArea from 'ui/TextArea'

import {
  getUpdateItemTitle,
  getUpdateTypeFromUrl,
  ReshareTokenSet,
} from 'utils/functions/updates'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'

import {
  ExpandArrowIconButton,
  OptionsContainer,
} from '../ShowUpdate/ShowUpdate.styles'
import { useShowUpdate } from '../ShowUpdate/useShowUpdate'
import UpdateTags from '../UpdateTags/UpdateTags'
import { useReshareUpdate } from './useReshareUpdate'

const ReshareUpdate: React.FC<{
  loader?: React.ReactNode
  children?: React.ReactNode
  editMode?: boolean
}> = ({ loader, children, editMode }) => {
  const { update, isLoading } = useShowUpdate()
  const location = useLocation<{
    companyName?: string
    reshareTokenSet?: ReshareTokenSet
  }>()
  const reshareTokenSet = location.state?.reshareTokenSet

  const {
    reshareMessage,
    onChangeReshareMessage,
    reshareUpdate,
    editReshare,
    isSubmitting,
    permissions: {
      items,
      sharedGroups,
      setSharedGroups,
      sharedUsers,
      setSharedUsers,
    },
  } = useReshareUpdate(update, reshareTokenSet)

  const updatePermissions = useUpdatePermissions(update)
  const intl = useIntl()

  const history = useHistory()

  const currentCompany = useAppSelector(getCurrentCompany)
  const currentPortfolio = useAppSelector(getCurrentPortfolio)
  const [isCardExpanded, setIsCardExpanded] = useState(false)
  const onCardExpanded = () => {
    setIsCardExpanded(!isCardExpanded)
  }
  const updateType = getUpdateTypeFromUrl(location.pathname)
  const tabsToShow = useMemo<TabItem[]>(() => {
    const tabsToReturn = [
      {
        id: 'general',
        title: (
          <FormattedMessage
            id={
              editMode
                ? 'updates.editReshare'
                : `updates.reshareTitles.${updateType}`
            }
          />
        ),
        icon: ['far', 'retweet'],
      },
    ]

    if (update?.eventLogType) {
      tabsToReturn.push({
        id: 'logs',
        title: <FormattedMessage id="logs.title" />,
        icon: ['far', 'clipboard-list-check'],
      })
    }
    return tabsToReturn
  }, [update])

  const renderChildren = () => {
    if (isLoading || !update) {
      return <div>{loader}</div>
    }

    const optionsDropdown = (
      <OptionsContainer key="dropdown">
        {!isCardExpanded && (
          <ExpandArrowIconButton
            onClick={onCardExpanded}
            icon="arrows-h"
            border={false}
          />
        )}
      </OptionsContainer>
    )

    const reshareMessageBox = (
      <>
        <Separator space="1rem" />
        <TextArea
          label={intl.formatMessage({ id: 'updates.reshareMessage' })}
          value={reshareMessage}
          onChange={(event) => onChangeReshareMessage(event.target.value)}
          isOptional
          rows={2}
        />
        <Separator border space="3.4rem" />
        <Separator space="1rem" />
      </>
    )

    if (children) {
      return [optionsDropdown, reshareMessageBox, children]
    }

    return [
      optionsDropdown,
      <UpdateLayout.Tab id="general" key="general">
        {reshareMessageBox}
        <Formik
          onSubmit={() => {}}
          initialValues={{
            isPreviewingDraft: false,
          }}
        >
          <ShowUpdateContent update={update} updateType={update.updateType} />
        </Formik>
      </UpdateLayout.Tab>,
      update.eventLogType ? (
        <UpdateLayout.Tab id="logs" key="logs">
          <Logs logType={update.eventLogType!} entityId={update.item?.id} />
        </UpdateLayout.Tab>
      ) : null,
    ]
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            { id: 'metaTitles.reshareUpdateTitle' },
            {
              title: getUpdateItemTitle(update),
              entityName:
                location.state?.companyName ||
                currentCompany.name ||
                currentPortfolio?.name ||
                '',
            }
          )}
        </title>
      </Helmet>
      <UpdateLayout.Container>
        <form noValidate>
          <UpdateLayout.Body>
            <UpdateNavigationHeader
              onSave={editMode ? editReshare : reshareUpdate}
              onCancel={history.goBack}
              editMode
              deleteEnabled={false}
              confirmDisabled={
                !editMode && !sharedGroups.length && !sharedUsers.length
              }
              isSubmitting={isSubmitting}
              confirmLabel={
                editMode
                  ? undefined
                  : intl.formatMessage({
                      id: 'updates.reshare',
                    })
              }
              cancelLabel={intl.formatMessage({ id: 'general.cancel' })}
            />

            <UpdateLayout.TabContent
              fullContent={false}
              isCardExpanded={isCardExpanded}
              tabs={tabsToShow}
            >
              {renderChildren()}
            </UpdateLayout.TabContent>

            {isCardExpanded ? (
              <CollapsedPermissionsTagsCard onCardExpanded={onCardExpanded} />
            ) : (
              <UpdateLayout.CollapsableCard>
                <UpdateLayout.Permissions>
                  <SetUpdatePermissions
                    baseVisibility={update?.visibility}
                    sharedUsers={sharedUsers}
                    setSharedGroups={setSharedGroups}
                    setSharedUsers={setSharedUsers}
                    initialItems={items}
                    loading={isLoading}
                    groupCreatedBy={update?.group?.id ?? ''}
                    onCardExpanded={onCardExpanded}
                    onlyCustomPermissions
                    onlyCustomPermissionsText={intl.formatMessage({
                      id: 'updates.chooseLists',
                    })}
                    updatePermissions={updatePermissions}
                    isResharingUpdate
                    updateType={updateType}
                  />
                </UpdateLayout.Permissions>

                <UpdateLayout.Tags>
                  <UpdateTags
                    updateId={update?.id}
                    initialTags={update?.tags ?? []}
                  />
                </UpdateLayout.Tags>
              </UpdateLayout.CollapsableCard>
            )}
          </UpdateLayout.Body>
        </form>
      </UpdateLayout.Container>
    </>
  )
}

ReshareUpdate.defaultProps = {
  loader: <EditUpdateContentLoader />,
  editMode: false,
}

export default ReshareUpdate
