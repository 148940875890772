import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from 'components/Modal'
import Button from 'ui/Button'

import styles from './ConfirmationModal.module.scss'
import { ConfirmationHeader } from './ConfirmationModa.styles'

const ConfirmationModal = ({
  isOpen,
  onHide,
  onConfirm,
  confirmMessage,
  title,
  body,
  showCancel,
  confirmDisabled,
  renderFooter,
  dangerConfirm,
  footerClassName,
  hasIcon,
  showSharedWith,
  onShareWith,
  preventHideOnConfirm = false,
  ModalBody,
  isLoading,
}) => {
  const intl = useIntl()

  const handleOnConfirm = async () => {
    await onConfirm()
    onHide()
  }

  return (
    <Modal show={isOpen} onHide={onHide}>
      <Modal.Header onHide={onHide}>
        <ConfirmationHeader>
          {hasIcon && (
            <FontAwesomeIcon icon={['far', 'exclamation-triangle']} />
          )}
          {title}
        </ConfirmationHeader>
      </Modal.Header>
      {ModalBody || (
        <Modal.Body>
          <div className={styles.confirmationBody}>{body}</div>
        </Modal.Body>
      )}
      <Modal.Footer className={footerClassName}>
        {!renderFooter && (
          <div className={styles.confirmationFooter}>
            {showCancel && (
              <Button onClick={onHide}>{intl.messages['common.cancel']}</Button>
            )}

            {showSharedWith && (
              <Button onClick={onShareWith}>
                {intl.formatMessage({
                  id: 'updates.confirmationModal.shareWith',
                })}
              </Button>
            )}
            <Button
              primary
              danger={dangerConfirm}
              disabled={confirmDisabled}
              isLoading={isLoading}
              onClick={() => {
                onConfirm()
                if (!preventHideOnConfirm) {
                  onHide()
                }
              }}
            >
              {confirmMessage || intl.messages['common.yes']}
            </Button>
          </div>
        )}

        {renderFooter && renderFooter()}
      </Modal.Footer>
    </Modal>
  )
}

ConfirmationModal.propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]),
  confirmMessage: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onShareWith: PropTypes.func,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  showCancel: PropTypes.bool,
  showSharedWith: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  dangerConfirm: PropTypes.bool,
  renderFooter: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  footerClassName: PropTypes.string,
  hasIcon: PropTypes.bool,
  preventHideOnConfirm: PropTypes.bool,
  ModalBody: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isLoading: PropTypes.bool,
}

ConfirmationModal.defaultProps = {
  showCancel: true,
  confirmDisabled: false,
  dangerConfirm: false,
  confirmMessage: '',
  renderFooter: null,
  footerClassName: '',
  onConfirm: null,
  hasIcon: true,
  ModalBody: null,
}

export default ConfirmationModal
