import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getPortfolios } from 'selectors/portfolio'

import Card from 'components/Card'
import Dashboard from 'components/Dashboard'
import { usePortfolioUpdates } from 'components/Dashboard/PortfolioUpdates/usePortfolioUpdates'
import SearchBy from 'components/SearchBy'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import UpdatesFeed, { UpdateEntityType } from 'components/UpdatesFeedV2'
import { Portfolio } from 'utils/types/portfolios'
import { IndexUpdate } from 'utils/types/update'

const Updates: React.FC = () => {
  const allPortfolios: Portfolio[] = useAppSelector(getPortfolios)

  const portfolioIds = allPortfolios?.map((portfolio) => portfolio.id) || []

  const { filterDataSet, handleFiltersChange, intl } =
    usePortfolioUpdates(false)

  const { handleOnUpdateClick } = useOnClickRedirect()

  const onShowUpdate = (update: IndexUpdate) => {
    return handleOnUpdateClick({ update }).redirectFunction()
  }

  return (
    <Dashboard.Row>
      <Dashboard.Item>
        <Card fitContent isExpandable padding="1.4rem 2.4rem">
          <Card.Header>
            <FormattedMessage id="updates.updatesFeed" />
          </Card.Header>
          <Card.Body>
            <UpdatesFeed
              entityType={UpdateEntityType.ALL_PORTFOLIOS}
              entityId={portfolioIds}
              onShowUpdate={onShowUpdate}
              showEntityFeedIcons
            />
          </Card.Body>
        </Card>
      </Dashboard.Item>

      <Dashboard.Item small>
        <Card fitContent>
          <SearchBy
            dataSet={filterDataSet}
            onFiltersChange={handleFiltersChange}
            title={intl.formatMessage({
              id: 'investorManagement.updates.searchBy',
            })}
            placeholder={intl.formatMessage({
              id: 'investorManagement.updates.search',
            })}
            nameSearchEnabled={false}
          />
        </Card>
      </Dashboard.Item>
    </Dashboard.Row>
  )
}

export default Updates
