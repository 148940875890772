import { SortDirection } from 'utils/constants'
import { MetricSortBy } from 'utils/constants/metrics'

export const QUERIES = {
  COMPANY: 'company',
  COMPANIES: 'companies',
  PORTFOLIO_COMPANIES: 'portfolio-companies',
  COMPANY_METRICS: 'company-metrics',
  COMPANY_TRANSACTIONS: 'company-transactions',
} as const

export type CompanyMetricsFilters = {
  companyId: string
  metricName?: string
  sortBy?: MetricSortBy
  sortDirection?: SortDirection
}

export const companyKeys = {
  all: () => [QUERIES.COMPANIES] as const,
  byId: (id: string) => [QUERIES.COMPANY, id] as const,
  allWithFilters: (params: any, sort: any) =>
    [QUERIES.COMPANIES, params, sort] as const, // TODO: TYPE THE PARAMS AND SORT ONCE WE MIGRATE TO THE NEW V2 COMPANY INDEX --> CONSIDER FORMAT THE REQUEST TO ACCEPT "FILTERS"
  portfolioCompaniesWithFilters: (params: any, sort: any) =>
    [QUERIES.PORTFOLIO_COMPANIES, params, sort] as const, // TODO: TYPE THE PARAMS AND SORT ONCE WE MIGRATE TO THE NEW V2 COMPANY INDEX --> CONSIDER FORMAT THE REQUEST TO ACCEPT "FILTERS"
  groupCompanyByIdAndGroupId: (companyId: string, groupId: string) =>
    [QUERIES.COMPANY, companyId, groupId] as const,
  companyMetrics: (id: string) => [QUERIES.COMPANY_METRICS, id] as const,
  companyMetricsWithFilters: (filters: CompanyMetricsFilters) =>
    [QUERIES.COMPANY_METRICS, filters.companyId, filters] as const,
  updatesByMonthAndCompany: (companyId: string, month: string) =>
    [QUERIES.COMPANY, companyId, 'updates', month] as const,
  companyTransactions: (id: string) =>
    [QUERIES.COMPANY_TRANSACTIONS, id] as const,
}
