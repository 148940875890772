import { useMemo, useRef } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import type { InfiniteData } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'

import {
  useEmailUpdatesInfiniteQuery,
  useRemoveEmailDraftsMutation,
} from 'utils/hooks/queries/useEmailUpdatesQuery'
import { EmailIndexUpdate } from 'utils/types/update'
import { emailUpdateKeys } from 'utils/queries/emailUpdates'
import { MailboxType } from './useEmailList'

type DraftEmailIndexUpdate = EmailIndexUpdate & {
  deleted?: boolean
}

type EmailUpdatesCacheData = InfiniteData<{
  data: DraftEmailIndexUpdate[]
  totalResults: number
  page: number
}>

export type ScrollEnabled = {
  emails: boolean
  drafts: boolean
  scheduled: boolean
}

export const useEmailsQuery = ({
  companyId,
  mailboxType,
  scrollEnabled,
  setScrollEnabled,
}: {
  companyId: string
  mailboxType: MailboxType
  scrollEnabled: ScrollEnabled
  setScrollEnabled: React.Dispatch<React.SetStateAction<ScrollEnabled>>
}) => {
  const {
    emails,
    totalResults: totalEmails,
    isLoading: isLoadingSentEmails,
    isFetching,
    fetchNextPage: fetchNextEmailsPage,
    refetch: refetchEmails,
  } = useEmailUpdatesInfiniteQuery({
    companyId,
    draftEmails: false,
    setScrollEnabled: (value) =>
      setScrollEnabled((prev) => ({ ...prev, emails: value })),
  })

  const {
    emails: emailDrafts,
    isLoading: isLoadingDrafts,
    isFetching: isFetchingDrafts,
    fetchNextPage: fetchNextDraftPage,
    refetch: refetchDrafts,
  } = useEmailUpdatesInfiniteQuery({
    companyId,
    draftEmails: true,
    setScrollEnabled: (value) =>
      setScrollEnabled((prev) => ({ ...prev, drafts: value })),
  })

  const drafts = emailDrafts as DraftEmailIndexUpdate[]

  const scheduledDrafts = useMemo(() => {
    return drafts.filter(
      (email) => email.loggingUpdateSchedule && email.scheduled
    )
  }, [drafts])

  const nonScheduledDrafts = useMemo(() => {
    return drafts.filter(
      (email) =>
        !email.loggingUpdateSchedule ||
        (email.loggingUpdateSchedule && !email.scheduled)
    )
  }, [drafts])

  const feedProps = useMemo(() => {
    if (mailboxType === MailboxType.SENT) {
      return {
        isLoading: isLoadingSentEmails,
        isFetching,
        showZeroState: !isLoadingSentEmails && emails.length === 0,
        updates: emails,
        scrollEnabled: scrollEnabled.emails,
        fetchNextPage: fetchNextEmailsPage,
        refetch: refetchEmails,
      }
    }

    if (mailboxType === MailboxType.DRAFT) {
      return {
        isLoading: isLoadingDrafts,
        isFetching: isFetchingDrafts,
        showZeroState: !isLoadingDrafts && nonScheduledDrafts.length === 0,
        updates: nonScheduledDrafts,
        scrollEnabled: scrollEnabled.drafts,
        fetchNextPage: fetchNextDraftPage,
        refetch: refetchDrafts,
      }
    }

    return {
      isLoading: isLoadingDrafts,
      isFetching: isFetchingDrafts,
      showZeroState: !isLoadingDrafts && scheduledDrafts.length === 0,
      updates: scheduledDrafts,
      scrollEnabled: scrollEnabled.scheduled,
      fetchNextPage: fetchNextEmailsPage,
      refetch: refetchDrafts,
    }
  }, [
    mailboxType,
    isLoadingDrafts,
    isFetchingDrafts,
    scheduledDrafts,
    scrollEnabled.scheduled,
    scrollEnabled.emails,
    scrollEnabled.drafts,
    fetchNextEmailsPage,
    refetchDrafts,
    isLoadingSentEmails,
    isFetching,
    emails,
    refetchEmails,
    nonScheduledDrafts,
    fetchNextDraftPage,
  ])

  return {
    feedProps,
    totalEmails,
    totalDrafts: nonScheduledDrafts.length,
    totalSchedules: scheduledDrafts.length,
    initialLoading: isLoadingSentEmails || isLoadingDrafts,
  }
}

export const useRemoveEmailDrafts = (groupCompanyId: string) => {
  const queryClient = useQueryClient()
  const lastUpdatesRemovedRef = useRef<DraftEmailIndexUpdate[]>([])

  const isDraft = true
  const draftKey = emailUpdateKeys.all(isDraft)
  const { mutate, isLoading } = useRemoveEmailDraftsMutation(groupCompanyId)

  const updateDeletedOnCache = (
    updates: DraftEmailIndexUpdate[],
    isDeleted: boolean
  ) => {
    const selectedDraftsIds = updates.map((email) => email.item.id)

    queryClient.setQueryData(draftKey, (oldDrafts: EmailUpdatesCacheData) => {
      const newDrafts = cloneDeep(oldDrafts)

      newDrafts.pages.forEach((page) => {
        page.data.forEach((update) => {
          // eslint-disable-next-line no-param-reassign
          update.deleted = selectedDraftsIds.includes(update.item.id)
            ? isDeleted
            : update.deleted
        })
      })

      return newDrafts
    })
  }

  const removeDraftsFromCache = (updates: DraftEmailIndexUpdate[]) => {
    lastUpdatesRemovedRef.current = updates
    updateDeletedOnCache(updates, true)
  }

  const restoreDraftsToCache = () => {
    updateDeletedOnCache(lastUpdatesRemovedRef.current, false)
  }

  return {
    removeDraftsFromCache,
    restoreDraftsToCache,
    onDeleteDrafts: mutate,
    isDeletingDrafts: isLoading,
  }
}
