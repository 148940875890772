import type { IntlShape } from 'react-intl'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { PortfolioTypes } from 'utils/constants/portfolio'
import * as yup from 'yup'

export const getCreatePortfolioSchema = (intl: IntlShape) =>
  yup.object().shape({
    type: yup.string().oneOf(Object.values(PortfolioTypes)).required(),
    name: yup
      .string()
      .required(intl.formatMessage({ id: 'general.requiredField' }))
      .max(
        MAX_COUNTER_CHARACTERS,
        intl.formatMessage(
          { id: 'general.youHaveExceededCharacterLimit' },
          { limit: MAX_COUNTER_CHARACTERS }
        )
      ),
    holdings: yup.array().when('type', {
      is: PortfolioTypes.DEAL,
      then: yup
        .array()
        .max(
          1,
          intl.formatMessage({
            id: 'createPortfolio.createDealMaxHoldingExceededError',
          })
        )
        .of(
          yup.object().shape({
            id: yup.string(),
          })
        ),
      otherwise: yup.array().of(
        yup.object().shape({
          id: yup.string(),
        })
      ),
    }),
  })

export const getAddPortfoliosToCompanySchema = () =>
  yup.array().of(
    yup.object().shape({
      id: yup.string(),
    })
  )
