import ReportPreviewModal from 'components/ReportPreviewModal'
import {
  QuickbooksIcon,
  XeroIcon,
} from 'components/UpdateContent/UpdateContent.styles'
import UpdateIconFactory from 'components/UpdateIconFactory'
import { FIELD_NAMES } from 'containers/UpdatesV2/Accounting/constants/constants'
import dayjs from 'dayjs'
import { useField } from 'formik'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router-dom'
import { AccountingReportType } from 'utils/constants/accounting'
import { getAccountingReportDropdownOptions } from 'utils/functions/renderers/renderUpdatesHelper'
import useAccountingIntegrationStatus from 'utils/hooks/useAccountingIntegrationStatus'
import { ScheduleType } from 'utils/types/update'
import { Dropdown } from '../commons'
import ReadOnlyField from '../commons/ReadOnlyField'
import ReportFilters from '../ReportFilters'
import {
  DropdownContainer,
  PreviewReportButton,
  ReportSettingsRow,
  ReportSettingsTitle,
} from './ReportSettings.styles'
import { getReportTypeLabel } from './utils'

interface ReportSettingsProps {
  isEditMode: boolean
  reportParamsDisabled?: boolean
}

const ReportSettings: React.FC<ReportSettingsProps> = ({
  isEditMode,
  reportParamsDisabled,
}) => {
  const intl = useIntl()
  const [date] = useField<Date>(FIELD_NAMES.date)
  const [reportTypeField] = useField(FIELD_NAMES.reportType)
  const [balanceDate] = useField(FIELD_NAMES.balanceDate)
  const [reportDatePeriodField] = useField(FIELD_NAMES.reportDatePeriod)
  const [periods] = useField(FIELD_NAMES.periods)
  const scheduleType = dayjs(date.value).isAfter(new Date())
    ? ScheduleType.SCHEDULE
    : ScheduleType.SEND_NOW

  const [previewOpen, setPreviewOpen] = useState(false)

  const getIcon = (value: string) => {
    if (
      value === AccountingReportType.XERO_BALANCE_SHEET ||
      value === AccountingReportType.XERO_PROFIT_AND_LOSS
    )
      return (
        <XeroIcon>
          <UpdateIconFactory updateType="xero" />
        </XeroIcon>
      )

    if (
      value === AccountingReportType.QUICKBOOKS_BALANCE_SHEET ||
      value === AccountingReportType.QUICKBOOKS_PROFIT_AND_LOSS
    )
      return (
        <QuickbooksIcon>
          <UpdateIconFactory updateType="quickbooks" />
        </QuickbooksIcon>
      )
    return null
  }

  const {
    isConnected: isAccountingIntegrationConnected,
    loading: isAccountingIntegrationConnectedLoading,
    status,
  } = useAccountingIntegrationStatus()

  const togglePreview = () => {
    setPreviewOpen((open) => !open)
  }

  if (
    !isAccountingIntegrationConnectedLoading &&
    !isAccountingIntegrationConnected
  ) {
    return <Redirect to="/" />
  }
  return (
    <div>
      <ReportSettingsTitle>
        {intl.formatMessage({ id: 'general.reportSettings' })}
      </ReportSettingsTitle>
      <ReportSettingsRow>
        <DropdownContainer>
          {isEditMode ? (
            <ReadOnlyField
              name={FIELD_NAMES.reportType}
              label={intl.formatMessage({
                id: 'general.selectAccountingReport',
              })}
              marginBottom="0"
              marginLeft="0"
              icon={getIcon(reportTypeField.value)}
              value={getReportTypeLabel(reportTypeField.value)}
            />
          ) : (
            <Dropdown
              name={FIELD_NAMES.reportType}
              label={intl.formatMessage({ id: 'general.accountingReport' })}
              placeholder={intl.formatMessage({
                id: 'general.selectAccountingReport',
              })}
              options={getAccountingReportDropdownOptions(status)}
              disabled={reportParamsDisabled || isEditMode}
            />
          )}
        </DropdownContainer>
        {!isEditMode && !reportParamsDisabled && (
          <PreviewReportButton
            secondary
            onClick={togglePreview}
            disabled={!reportTypeField.value}
          >
            {intl.formatMessage({ id: 'general.previewReport' })}
          </PreviewReportButton>
        )}
      </ReportSettingsRow>
      <ReportFilters
        reportParamsDisabled={reportParamsDisabled}
        scheduleType={scheduleType}
        isEditMode={isEditMode}
      />
      <ReportPreviewModal
        isOpen={previewOpen}
        setIsOpen={setPreviewOpen}
        date={date.value}
        scheduleType={scheduleType}
        reportType={reportTypeField.value}
        periods={periods.value}
        reportParams={{
          balanceDate: balanceDate.value,
          reportDatePeriod: reportDatePeriodField.value,
        }}
      />
    </div>
  )
}

export default ReportSettings
