import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const HintContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HintIcon = styled(FontAwesomeIcon).attrs({
  icon: ['far', 'info-circle'],
})`
  color: #787e8c;
  font-size: 1.55rem;
`

export const HintText = styled.span`
  color: #787e8c;
  font-size: 1.2rem;
  margin-left: 0.6rem;
`
