import { useCallback } from 'react'

import AnalyticsService from 'api/AnalyticsService'
import Toast from 'components/Toast'
import { download } from 'utils/functions/files'
import { FileContent } from 'utils/types/files'
import { useQueryClient } from '@tanstack/react-query'
import { analyticKeys } from 'utils/queries/analytics'

export const useDownloadFile = () => {
  const queryClient = useQueryClient()

  const onDownloadFile = useCallback(
    async (file: FileContent) => {
      try {
        await AnalyticsService.sendAttachmentDownloadAnalytic(file.id)
        download(file.fileName, file.url)
        queryClient.invalidateQueries(analyticKeys.contentAnalytics(file.id))
      } catch (e) {
        Toast.displayIntl('files.errorToDownload', 'error')
      }
    },
    [queryClient]
  )

  return {
    onDownloadFile,
  }
}
