import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'
import UpdateIconFactoryV2 from 'components/UpdateIconFactoryV2'
import PortfolioIconToStyle from 'components/PortfolioIcon'
import { UpdateType } from 'utils/types/common'
import { UpdateTypes } from 'utils/constants/updates'

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 0.8rem;
  align-items: center;

  .dropdown-list {
    margin: initial;
  }

  #dropdown-content {
    z-index: 3;
  }

  @media ${maxSize.xsm} {
    flex-direction: column;
  }
`

export const UpdateTypeDropdownWrapper = styled.div`
  flex: 1;
  max-width: 19rem;

  .dropdown-list {
    max-height: initial;
  }

  @media ${maxSize.xsm} {
    max-width: 100%;
  }
`

export const UpdateFromDropdownWrapper = styled.div`
  flex: 1;
  max-width: 30rem;

  li button {
    background-color: ${color('white')};

    &:hover {
      background-color: ${color('veryLightBlue', 0.2)};

      div,
      span {
        color: ${color('primaryBlue')};
      }
    }
  }

  @media ${maxSize.xsm} {
    max-width: 100%;
  }
`

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  max-width: 100%;
`

export const OptionIcon = styled(UpdateIconFactoryV2)<{
  updateType: UpdateType
}>`
  width: 2.1rem;
  height: 2.1rem;
  border: 1px solid ${color('veryLightGray')};
  font-size: ${({ updateType }) => {
    switch (updateType) {
      case UpdateTypes.ANNOUNCEMENT:
        return `1rem`
      case UpdateTypes.DOCUMENT:
        return `1.1rem`
      case UpdateTypes.NOTE:
        return `1.26rem`
      default:
        return `1.2rem`
    }
  }};

  path {
    color: white !important;
  }
`

export const OptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  max-width: calc(100% - 2.1rem);
`

export const OptionText = styled.div`
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('darkBlue')};
`

export const OptionDescription = styled.span`
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: ${color('lightGray')};
`

export const PortfolioIcon = styled(PortfolioIconToStyle)`
  min-width: 2.1rem;
  min-height: 2.1rem;
  border: initial;
`
