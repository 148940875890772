import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InvestorAndVehicleNavigation from 'components/Breadcrumb/Components/InvestorAndVehicleNavigation'
import { INVESTOR_PROFILE_VEHICLE_PROFILE } from 'routes/constant/investorManagementRoutes'
import {
  ArrowWrapper,
  NavigationWrapper,
} from 'components/Breadcrumb/Breadcrumb.styles'
import UpdateLink from 'components/Breadcrumb/Components/UpdateLink'
import InitialLink from 'components/Breadcrumb/Components/InitialLink'
import { Update } from 'utils/types/update'
import { URL } from 'reducers/breadcrumbSlice'

interface Props {
  update?: Update
  url: URL
  initialLinkRedirect: string
  isCreatingNewUpdate: boolean
}

const InvestorManagementBreadcrumb = ({
  update,
  url,
  initialLinkRedirect,
  isCreatingNewUpdate,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  return (
    <NavigationWrapper>
      <>
        <InitialLink
          isActive={false}
          label={intl.formatMessage({ id: 'breadcrumb.investorManagement' })}
          onClickHandler={() => history.push(initialLinkRedirect)}
        />

        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      </>

      <InvestorAndVehicleNavigation
        url={url}
        isCreatingNewUpdate={isCreatingNewUpdate}
        hasUpdate={!!update}
        vehicleActiveTabs={[INVESTOR_PROFILE_VEHICLE_PROFILE]}
      />

      <UpdateLink update={update} isCreatingNewUpdate={isCreatingNewUpdate} />
    </NavigationWrapper>
  )
}

export default InvestorManagementBreadcrumb
