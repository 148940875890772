import { useRef } from 'react'

interface PaginatorProps {
  isLoading: boolean
  hasNextPage: boolean
  children: React.ReactElement
  loadNextPage: () => void
}

const Paginator = ({
  isLoading,
  children,
  hasNextPage,
  loadNextPage,
}: PaginatorProps) => {
  const totalChildrenLength = useRef(0)

  if (isLoading) {
    return children
  }

  if (hasNextPage && Array.isArray(children)) {
    const isNextPageAlreadyLoaded =
      totalChildrenLength.current === children.length

    if (!isNextPageAlreadyLoaded) {
      totalChildrenLength.current += children.length
      loadNextPage()
    }
  }

  return children
}

export default Paginator
