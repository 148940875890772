export const DefaultOthers = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 9 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame" clipPath="url(#clip0_7690_533792)">
      <path
        id="Vector"
        d="M0.000315367 6.96351C0.000315367 5.37091 -0.00165567 3.77831 0.00425745 2.18374C0.00425745 2.02606 0.0239679 1.85852 0.0752149 1.71069C0.232898 1.25341 0.642875 0.969579 1.14155 0.967608C2.72429 0.963666 4.30704 0.965637 5.89176 0.967608C6.21501 0.967608 6.49292 1.08587 6.72156 1.31648C7.36412 1.96101 8.00865 2.6016 8.65318 3.24613C8.88774 3.48068 8.99812 3.76451 8.99812 4.09762C8.99615 6.66589 8.99812 9.23415 8.99615 11.8044C8.99615 12.3247 8.73991 12.713 8.26883 12.8885C8.12889 12.9417 7.96726 12.9614 7.81549 12.9614C5.60398 12.9653 3.39445 12.9634 1.18294 12.9653C0.7296 12.9653 0.376784 12.784 0.144201 12.3898C0.029881 12.1947 0.000315367 11.9818 0.000315367 11.761C0.000315367 10.1625 0.000315367 8.56203 0.000315367 6.96351ZM1.12578 2.09307C1.12578 5.34726 1.12578 8.59356 1.12578 11.8379C3.37868 11.8379 5.62567 11.8379 7.87068 11.8379C7.87068 9.58303 7.87068 7.33604 7.87068 5.08314C7.82338 5.08314 7.78396 5.08314 7.74453 5.08314C6.98963 5.08314 6.23275 5.08314 5.47784 5.08314C5.32804 5.08314 5.19401 5.05358 5.07575 4.95305C4.92792 4.82296 4.87667 4.66134 4.87667 4.47015C4.87864 3.71721 4.87667 2.96624 4.87667 2.21331C4.87667 2.17388 4.87667 2.13644 4.87667 2.09307C3.62112 2.09307 2.37739 2.09307 1.12578 2.09307ZM6.00411 3.96162C6.59542 3.96162 7.1749 3.96162 7.72285 3.96162C7.15322 3.39199 6.57177 2.81053 6.00411 2.24287C6.00411 2.79476 6.00411 3.37425 6.00411 3.96162Z"
        fill="#313748"
      />
    </g>
    <defs>
      <clipPath id="clip0_7690_533792">
        <rect
          width="8.99754"
          height="12"
          fill="white"
          transform="translate(0 0.965576)"
        />
      </clipPath>
    </defs>
  </svg>
)
