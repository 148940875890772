import React from 'react'
import { PortfolioTypes } from 'utils/constants/portfolio'
import PortfolioIcon from 'components/PortfolioIcon'
import type { InputProps } from 'ui/Input'
import Input from 'ui/Input'
import * as Styles from './FundPortfolioInput.styles'
import ProfileSuggestion from '../../../ProfileSuggestion/ProfileSuggestion'
import {
  useFundPortfolioInput,
  FundPortfolioInputProps,
} from './useFundPortfolioInput'

const FundPortfolioInput: React.FC<FundPortfolioInputProps & InputProps> = ({
  index,
  ...props
}) => {
  const {
    intl,
    handleBlur,
    status,
    fundField,
    onChange,
    existentHoldingContext,
    errorMessage,
    shouldHideError,
    shouldShowErrorMessage,
  } = useFundPortfolioInput(index)

  return (
    <>
      <Styles.InputWrapper>
        <Styles.IconContainer hasValue={!!fundField.value}>
          <PortfolioIcon type={PortfolioTypes.FUND} />
        </Styles.IconContainer>
        <Input
          id={fundField.name}
          name={fundField.name}
          value={fundField.value}
          onChange={onChange}
          onBlur={handleBlur}
          placeholder={intl.formatMessage({
            id: 'addHolding.addFundName',
          })}
          error={shouldHideError ? undefined : errorMessage}
          showErrorMessage={shouldShowErrorMessage}
          {...props}
        />
      </Styles.InputWrapper>
      {!!status?.fundsErrors?.[index]?.length && (
        <Styles.SuggestionErrorContainer>
          <ProfileSuggestion
            type="fund"
            profiles={status.fundsErrors[index]}
            addHoldingsToPortfolio={
              existentHoldingContext?.addHoldingsToPortfolio
            }
          />
        </Styles.SuggestionErrorContainer>
      )}
    </>
  )
}

export default FundPortfolioInput
