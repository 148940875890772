import { useTotalAnalyticsQuery } from 'containers/Analytics/useAnalyticsQuery'
import { useMemo, useState } from 'react'
import {
  AnalyticsChartParams,
  AnalyticsDisplayBy,
} from 'utils/constants/analytics'
import {
  getAnalyticsChartData,
  getChartDateRanges,
  getGroupByFromDisplayType,
  isChartNextPageDisabled,
  isChartPreviousPageDisabled,
} from 'utils/functions/analytics'

export const useAnalyticsChart = (
  updateId: string,
  updateCreationDate: Date
) => {
  const [chartParams, setChartParams] = useState<AnalyticsChartParams>(() => {
    const page = 0
    const displayType = AnalyticsDisplayBy.DAY
    const { startDate, endDate } = getChartDateRanges(displayType, page)

    return {
      displayType,
      startDate,
      endDate,
      page,
    }
  })

  const { data: totalAnalytics = [], maxValue } = useTotalAnalyticsQuery({
    updateId,
    groupBy: getGroupByFromDisplayType(chartParams.displayType),
    startDate: chartParams.startDate,
    endDate: chartParams.endDate,
  })

  const onSelectDisplayType = (displayType: AnalyticsDisplayBy) => {
    const newPage = 0
    const { startDate, endDate } = getChartDateRanges(displayType, newPage)
    setChartParams({
      displayType,
      startDate,
      endDate,
      page: newPage,
    })
  }

  const onChangePage = (incrementor: number) => () => {
    const dateRanges = getChartDateRanges(
      chartParams.displayType,
      chartParams.page + incrementor
    )
    setChartParams((prev) => ({
      ...prev,
      ...dateRanges,
      page: prev.page + incrementor,
    }))
  }

  const isNextPageDisabled = isChartNextPageDisabled(chartParams)
  const isPreviousPageDisabled = isChartPreviousPageDisabled(
    chartParams,
    updateCreationDate
  )

  const chartData = useMemo(() => {
    return getAnalyticsChartData(
      totalAnalytics,
      updateCreationDate,
      chartParams
    )
  }, [chartParams, totalAnalytics, updateCreationDate])

  return {
    maxValue,
    chartData,
    chartParams,
    onSelectDisplayType,
    onChangePage,
    isNextPageDisabled,
    isPreviousPageDisabled,
  }
}
