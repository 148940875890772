import Tooltip from 'components/Tooltip'
import styled, { createGlobalStyle } from 'styled-components'

export const StyledTooltip = styled(Tooltip)`
  width: ${(props) => props.width};
`

export const TooltipGlobalStyles = createGlobalStyle<{ maxWidth?: string }>`
  div[class^='__react_component_tooltip'] {
    z-index: 9999;
    max-width: ${(props) => props.maxWidth || '38.5rem'};
  }
`
