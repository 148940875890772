import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import { ReactComponent as ChartLineReference } from 'assets/images/chart-line-reference.svg'

export const Tooltip = styled.div`
  background-color: ${color('white')};
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  min-width: 20.9rem;
  outline: none;
  padding: 1.2rem 1.6rem;
  box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.1);
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
`

export const DotReference = styled.div<{ fill: string }>`
  background-color: ${({ fill }) => fill};
  border-radius: 50%;
  height: 1.2rem;
  width: 1.2rem;
`

export const LineReference = styled(ChartLineReference)``

export const Value = styled.span`
  color: ${color('darkGray')};
  font-size: 1.2rem;
  font-weight: 700;
`

export const Label = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
`

export const Date = styled.span`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.036rem;
  line-height: normal;
`

export const Metric = styled.div`
  display: flex;
  gap: 0.8rem;
`
