import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

const EmojiAutocompleteContainerStyles = css`
  div.rta__autocomplete.str-chat__emojisearch {
    border: 0.1rem solid ${color('veryLightGray')};
    border-radius: 0.8rem;
    box-shadow: none;
    padding: 0.8rem;
    width: auto;
    min-width: 17rem;
    margin-bottom: 1.6rem;
    z-index: 2;
  }
`

const EmojiPickerStyles = css`
  .str-chat__input--emojipicker {
    z-index: 5;
    position: absolute;
    bottom: 6.1rem;
    right: 7.6rem;
  }

  .emoji-mart {
    border-radius: 0.8rem;
  }

  .emoji-mart,
  .emoji-mart-bar {
    border-color: ${color('veryLightGray')};
  }

  .emoji-mart-anchors {
    padding: 0;
  }

  .emoji-mart-anchor {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .emoji-mart-anchor-selected {
    color: ${color('primaryBlue')}!important;
  }

  .emoji-mart-anchor-bar {
    background-color: ${color('primaryBlue')}!important;
  }

  .emoji-mart-search {
    margin: 1.2rem 1.4rem;
    padding: 0;
  }

  .emoji-mart-search input {
    background-color: ${color('lightGray', 0.1)};
    border: none;
    border-radius: 0.8rem;
    font-size: 1.4rem;
    padding: 1rem 2.5rem 1rem 1.6rem;

    &::placeholder {
      color: ${color('darkGray', 0.5)};
    }
  }

  .emoji-mart-search-icon {
    top: 0.9rem;

    :disabled {
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  .emoji-mart-category {
    padding-bottom: 1rem;
  }

  .emoji-mart-category-label span {
    color: ${color('darkGray')};
    font-size: 1.4rem;
    font-weight: 700;
    padding-bottom: 0;
  }

  .emoji-mart-emoji {
    outline: none;
  }
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 2.4rem 3.8rem;
  background: inherit;
  position: relative;

  .str-chat__textarea {
    flex: 1;
    display: flex;

    .str-chat__textarea__textarea {
      width: 100%;
      height: 100%;
      background: ${color('lightGray', 0.1)};
      border-radius: 0.8rem 0 0 0.8rem;
      font-size: 1.4rem;
      line-height: 1.7rem;
      padding: 1rem 7.2rem 1rem 1.6rem;
      resize: none;
      outline: none;
      font-size: 14px;
      line-height: 17px;
      color: ${color('gray')};

      &::placeholder {
        color: ${color('darkGray', 0.5)};
      }
    }
  }

  ${EmojiAutocompleteContainerStyles}

  ${EmojiPickerStyles}

  @media ${maxSize.mobile} {
    padding: 2.4rem;
  }

  @media ${maxSize.xsm} {
    .str-chat__input--emojipicker {
      right: 2.4rem;
    }
  }
`

export const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  width: 3.8rem;
  height: 100%;
  background-color: ${color('primaryBlue')};
  border-radius: 0rem 0.3rem 0.3rem 0rem;
  color: white;

  :disabled {
    opacity: 0.5;
  }
`

export const EmojiButton = styled.div`
  color: black;
  font-size: 1.7rem;
  cursor: pointer;
  position: relative;
`

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.2rem;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  height: 100%;
`

export const AutoCompleteWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 100%;
  line-height: 1;
`
