import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const FileDataWrapper = styled.div`
  display: flex;
  gap: 0.8rem;
`
export const FileGeneralDataWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
`

export const FileNameContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
`

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`

export const AttachmentAnalyticRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: center;
  padding: 1.8rem 1.2rem;
  gap: 0.9rem;
`
export const Label = styled.span`
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
`

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.4rem;
`

export const UserLabel = styled.span`
  color: ${color('darkGray')};
  font-weight: 700;
  font-size: 1.4rem;
`

export const TextLabel = styled.span`
  color: ${color('darkGray')};
  font-size: 1.4rem;
`
