/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Helmet } from 'components/Helmet'

import { getIconByUpdateType } from 'utils/functions/updates'
import { useLocation } from 'react-router-dom'
import { getCurrentPortfolio } from 'selectors/portfolio'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import CollapsedPermissionsTagsCard from 'components/CollapsedPermissionsTagsCard'
import DeleteModalBody from 'containers/DeleteModal'
import UpdateNavigationHeader from 'components/UpdateNavigationHeader'
import UpdateContent from 'components/UpdateContent'
import SetUpdatePermissions from 'containers/Updates/SetUpdatePermissions'
import ConfirmationModal from 'components/ConfirmationModal'
import UpdateLayout from 'components/UpdateLayout'
import Logs from 'containers/Logs/Logs'
import EditUpdateContentLoader from 'components/loaders/editUpdateContentLoader'
import { getCurrentCompany } from 'selectors/company'
import OptionsDropdown from 'ui/OptionsDropdown'
import { useUpdatePermissions } from 'utils/hooks/useUpdatePermissions'
import { UpdateTypes } from 'utils/constants/updates'
import { LogType } from 'containers/Logs/models/types'
import ShowUpdateContent from 'containers/UpdatesV2/ShowUpdateContent'
import BreadcrumbActionPortal from 'components/Breadcrumb/BreadcrumbActionPortal'
import EditEmailActions from 'components/Breadcrumb/UpdateActions/EditEmailActions'

import UpdateTags from '../UpdateTags/UpdateTags'
import ShareUpdate from '../ShareUpdate/ShareUpdate'
import ReshareSettings from '../ReshareSettings'
import ShowAccountingContent from '../ShowAccounting/ShowAccountingContent'
import {
  ExpandArrowIconButton,
  OptionsContainer,
  UpdateOptionsDropdownWrapper,
} from '../ShowUpdate/ShowUpdate.styles'

const EditUpdate = ({
  updateId,
  handleSubmit,
  redirectToShow,
  isCardExpanded,
  register,
  errors,
  onFilesChange,
  originalFiles,
  onCardExpanded,
  getValues,
  watch,
  setValue,
  update,
  visibility,
  sharedGroups,
  sharedUsers,
  setVisibility,
  setSharedGroups,
  setSharedUsers,
  items,
  tagsList,
  onDelete,
  updateType,
  attachmentsMode,
  allowMultipleFiles,
  tabs,
  dropdownOptions,
  children,
  updateLoading,
  loader,
  eventLogType,
  forceShowDatePicker,
  showTimeSelect,
  maxDate,
  dateRequired,
  pastDatesAllowed,
  reportParamsDisabled,
  integrationStatus,
  fileError,
  onClickPreviewReport,
  showRepetition,
  isSubmitting,
  itemToAdd,
  onlyMeHidden,
  reshareSettings,
  onChangeReshareSettings,
  showReshareSettings,
  ownByGroup,
  setOwnByGroup,
  isPortfolioUpdate,
  isFundPortfolioUpdate,
}) => {
  const intl = useIntl()
  const [modal, setModal] = useState(false)
  const location = useLocation()
  const currentCompany = useAppSelector(getCurrentCompany)
  const currentPortfolio = useAppSelector(getCurrentPortfolio)
  const [isUploadingFiles, setIsUploadingFiles] = useState(false)

  const updatePermissions = useUpdatePermissions(update)
  const toggleDeleteConfirmationModal = useCallback(() => {
    setModal((open) => !open)
  }, [])

  const tabsToShow = useMemo(() => {
    if (tabs) {
      return tabs
    }

    const tabsToReturn = [
      {
        id: 'general',
        title: <FormattedMessage id={`updates.editTitles.${updateType}`} />,
        icon: ['far', getIconByUpdateType(updateType)],
      },
    ]

    if (eventLogType) {
      tabsToReturn.push({
        id: 'logs',
        title: <FormattedMessage id="logs.title" />,
        icon: ['far', 'clipboard-list-check'],
      })
    }
    return tabsToReturn
  }, [eventLogType])

  const renderChildren = () => {
    if (updateLoading) {
      return <div>{loader}</div>
    }

    const optionsDropdown = (
      <OptionsContainer key="dropdown">
        {!isCardExpanded && updateType !== UpdateTypes.IUE && (
          <ExpandArrowIconButton
            onClick={onCardExpanded}
            icon="arrows-h"
            border={false}
          />
        )}

        {updatePermissions.hasEditPermissions &&
          dropdownOptions?.length > 0 && (
            <UpdateOptionsDropdownWrapper>
              <OptionsDropdown>
                {dropdownOptions?.map?.((option) => (
                  <OptionsDropdown.Item
                    key={option.id}
                    label={option.label}
                    icon={option.icon}
                    onSelectOption={option.onSelectOption}
                  />
                ))}
              </OptionsDropdown>
            </UpdateOptionsDropdownWrapper>
          )}
      </OptionsContainer>
    )

    if (children) {
      return [optionsDropdown, children]
    }

    return [
      optionsDropdown,
      <UpdateLayout.Tab id="general" key="general">
        {updatePermissions.isContentEditable ? (
          <UpdateContent
            ref={register}
            update={update}
            type={updateType}
            errors={errors}
            onFilesChange={onFilesChange}
            mode={attachmentsMode}
            originalFiles={originalFiles}
            disabled={!updatePermissions.isEditable}
            onCardExpanded={onCardExpanded}
            isCardExpanded={isCardExpanded}
            setValue={setValue}
            values={getValues()}
            watch={watch}
            isDocument={updateType === 'document'}
            allowMultipleFiles={allowMultipleFiles}
            forceShowDatePicker={forceShowDatePicker}
            showTimeSelect={showTimeSelect}
            maxDate={maxDate}
            dateRequired={dateRequired}
            pastDatesAllowed={pastDatesAllowed}
            reportParamsDisabled={reportParamsDisabled}
            integrationStatus={integrationStatus}
            fileError={fileError}
            onClickPreviewReport={onClickPreviewReport}
            showRepetition={showRepetition}
            canEdit={updatePermissions.hasEditPermissions}
            updateCreationDate={update?.createdAt}
            baseVisibility={update?.visibility}
            currentVisibility={visibility}
            ownedByGroup={update?.ownedByGroup}
            setIsUploadingFiles={setIsUploadingFiles}
          />
        ) : (
          <ShowUpdateContent
            update={update}
            updateType={update.updateType}
            currentVisibility={visibility}
            editMode
          >
            {update.updateType === UpdateTypes.ACCOUNTING && (
              <ShowAccountingContent update={update} />
            )}
          </ShowUpdateContent>
        )}
      </UpdateLayout.Tab>,
      eventLogType ? (
        <UpdateLayout.Tab id="logs" key="logs">
          <Logs entityId={update?.item?.id} logType={eventLogType} />
        </UpdateLayout.Tab>
      ) : null,
    ]
  }

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            { id: 'metaTitles.editUpdateTitle' },
            {
              title: update?.item?.title || update?.iueReport?.subject,
              entityName:
                location.state?.companyName ||
                currentCompany.name ||
                currentPortfolio?.name ||
                '',
            }
          )}
        </title>
      </Helmet>
      <UpdateLayout.Container>
        <form noValidate>
          <UpdateLayout.Body>
            {updateType !== UpdateTypes.IUE && (
              <UpdateNavigationHeader
                onSave={handleSubmit}
                onCancel={() => {
                  const discardChanges = true
                  redirectToShow(discardChanges)
                }}
                onDelete={toggleDeleteConfirmationModal}
                editMode
                deleteEnabled={onDelete && updatePermissions.canDeleteUpdate}
                isSubmitting={isSubmitting}
                confirmDisabled={isUploadingFiles}
              />
            )}

            <UpdateLayout.TabContent
              fullContent={false}
              isCardExpanded={isCardExpanded}
              tabs={tabsToShow}
            >
              {renderChildren()}
            </UpdateLayout.TabContent>
            {isCardExpanded ? (
              <CollapsedPermissionsTagsCard
                onCardExpanded={onCardExpanded}
                showLockIcon={updatePermissions.hasEditPermissions}
              />
            ) : (
              <UpdateLayout.CollapsableCard>
                <UpdateLayout.Permissions>
                  <SetUpdatePermissions
                    ref={register}
                    update={update}
                    groupCreatedBy={update?.group?.id}
                    baseVisibility={update?.visibility}
                    sharedGroups={sharedGroups}
                    setSharedGroups={setSharedGroups}
                    sharedUsers={sharedUsers}
                    setSharedUsers={setSharedUsers}
                    setValue={setValue}
                    visibility={visibility}
                    setVisibility={setVisibility}
                    items={items}
                    initialItems={items}
                    loading={updateLoading}
                    onCardExpanded={onCardExpanded}
                    itemToAdd={itemToAdd}
                    onlyMeHidden={onlyMeHidden}
                    ownByGroup={ownByGroup}
                    setOwnByGroup={setOwnByGroup}
                    updatePermissions={updatePermissions}
                    updateType={updateType}
                    isPortfolioUpdate={isPortfolioUpdate}
                    isFundPortfolioUpdate={isFundPortfolioUpdate}
                  />
                </UpdateLayout.Permissions>

                {update && showReshareSettings && (
                  <UpdateLayout.Reshare>
                    <ReshareSettings
                      reshareSettings={reshareSettings}
                      onChangeReshareSettings={onChangeReshareSettings}
                    />
                  </UpdateLayout.Reshare>
                )}

                <UpdateLayout.Tags>
                  <UpdateTags
                    updateId={update?.iueReport ? update?.id : updateId}
                    initialTags={tagsList}
                    canEdit={updatePermissions.hasEditPermissions}
                  />
                </UpdateLayout.Tags>

                {updateType !== UpdateTypes.IUE && (
                  <UpdateLayout.ShareUpdate>
                    <ShareUpdate />
                  </UpdateLayout.ShareUpdate>
                )}
              </UpdateLayout.CollapsableCard>
            )}

            {onDelete && (
              <ConfirmationModal
                title={intl.messages[`updates.deleteTitles.${updateType}`]}
                body={
                  <DeleteModalBody
                    text={intl.messages[`updates.deleteMessages.${updateType}`]}
                  />
                }
                onHide={toggleDeleteConfirmationModal}
                onConfirm={onDelete}
                isOpen={modal}
              />
            )}
          </UpdateLayout.Body>
        </form>
      </UpdateLayout.Container>
    </>
  )
}

EditUpdate.propTypes = {
  update: PropTypes.object,
  onCardExpanded: PropTypes.func.isRequired,
  isCardExpanded: PropTypes.bool.isRequired,
  tagsList: PropTypes.array,
  updateId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  allowMultipleFiles: PropTypes.bool,
  loader: PropTypes.node,

  handleSubmit: PropTypes.func.isRequired,
  redirectToShow: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  onFilesChange: PropTypes.func,
  originalFiles: PropTypes.array,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  onClickPreviewReport: PropTypes.func,
  visibility: PropTypes.string.isRequired,
  sharedGroups: PropTypes.array.isRequired,
  sharedUsers: PropTypes.array.isRequired,
  setVisibility: PropTypes.func.isRequired,
  setSharedGroups: PropTypes.func.isRequired,
  setSharedUsers: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  updateType: PropTypes.string.isRequired,
  attachmentsMode: PropTypes.string,
  tabs: PropTypes.array,
  dropdownOptions: PropTypes.array,
  updateLoading: PropTypes.bool,
  eventLogType: PropTypes.string,
  forceShowDatePicker: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  maxDate: PropTypes.object,
  dateRequired: PropTypes.bool,
  pastDatesAllowed: PropTypes.bool,
  reportParamsDisabled: PropTypes.bool,
  onlyMeHidden: PropTypes.bool,
  showRepetition: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  showReshareSettings: PropTypes.bool,
  integrationStatus: PropTypes.object,
  fileError: PropTypes.string,
  itemToAdd: PropTypes.object,
  reshareSettings: PropTypes.shape({
    isConfidential: PropTypes.bool,
    isBlocked: PropTypes.bool,
  }).isRequired,
  onChangeReshareSettings: PropTypes.func.isRequired,
  ownByGroup: PropTypes.bool,
  isPortfolioUpdate: PropTypes.bool,
  isFundPortfolioUpdate: PropTypes.bool,
  setOwnByGroup: PropTypes.func,
}

EditUpdate.defaultProps = {
  update: undefined,
  children: null,
  tagsList: [],
  allowMultipleFiles: true,
  loader: <EditUpdateContentLoader />,
  errors: {},
  onFilesChange: () => {},
  onClickPreviewReport: () => {},
  originalFiles: [],
  getValues: null,
  watch: null,
  setValue: null,
  onDelete: null,
  attachmentsMode: 'grid',
  tabs: null,
  dropdownOptions: null,
  updateLoading: false,
  eventLogType: null,
  forceShowDatePicker: false,
  showTimeSelect: false,
  maxDate: null,
  dateRequired: false,
  pastDatesAllowed: false,
  reportParamsDisabled: false,
  showRepetition: false,
  isSubmitting: false,
  integrationStatus: {},
  fileError: '',
  itemToAdd: {},
  onlyMeHidden: false,
  showReshareSettings: true,
  isPortfolioUpdate: false,
}

export default EditUpdate
