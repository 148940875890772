import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import {
  Container as DefaultContainer,
  Name,
} from 'components/UpdateMetricsGrid/components/MetricsSpreadsheetDropdown/MetricsSpreadsheetDropdown.styles'

export const Container = styled(DefaultContainer)<{ isAutoFilled?: boolean }>`
  ${({ isAutoFilled }) =>
    isAutoFilled &&
    css`
      background-color: ${color('lightGray', 0.1)};
      color: ${color('lightGray')};

      ${Name} {
        color: ${color('lightGray')};
      }
    `}
`
