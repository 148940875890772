import styled from 'styled-components'
import { FileViewEnum } from 'components/FileViewSelector'
import { color } from 'utils/functions/colors'

export const GridRow = styled.ul<{ gridSize: FileViewEnum }>`
  display: grid;
  gap: 2.4rem 1.7rem;
  margin-top: 2.5rem;
  grid-template-columns: ${({ gridSize }) =>
    gridSize === FileViewEnum.SMALL
      ? 'repeat(auto-fill, 13.2rem)'
      : 'repeat(auto-fill, 21rem)'};
`

export const RowDate = styled.span`
  color: ${color('gray')};
  font-size: 1.4rem;
  margin-bottom: 2rem;
`
