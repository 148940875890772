import {
  ALL_FOLLOWED_HOLDINGS_OPTION_ID,
  ALL_INVESTMENT_OPTION_ID,
  InvestmentsNavigationOption,
} from 'containers/Investments/types'
import { URL } from 'reducers/breadcrumbSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from 'utils/theme'
import { useIntl } from 'react-intl'
import { Nullable } from 'utils/types/common'
import { useHistory } from 'react-router-dom'
import InitialLink from '../Components/InitialLink'
import { ArrowWrapper, IconWrapper } from '../Breadcrumb.styles'
import EntityLink from '../Components/EntityLink'

interface Props {
  initialLinkRedirect: string
  url: URL
  investmentOptionSelected: Nullable<InvestmentsNavigationOption>
}

const useInvestmentsBreadcrumb = ({
  initialLinkRedirect,
  investmentOptionSelected,
  url,
}: Props) => {
  const intl = useIntl()
  const history = useHistory()

  const fromAllInvestmentsOption =
    investmentOptionSelected?.id === ALL_INVESTMENT_OPTION_ID ||
    investmentOptionSelected?.id === null

  const fromAllFollowedHoldingsOption =
    investmentOptionSelected?.id === ALL_FOLLOWED_HOLDINGS_OPTION_ID

  const displayNavigationOptionBreadcrumbItem = () => {
    if (!investmentOptionSelected || fromAllInvestmentsOption) return null

    if (fromAllFollowedHoldingsOption) {
      return (
        <>
          <InitialLink
            isActive={false}
            label={intl.formatMessage({ id: 'breadcrumb.allFollowedHoldings' })}
            onClickHandler={() => history.push(initialLinkRedirect)}
          />

          <ArrowWrapper>
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </ArrowWrapper>
        </>
      )
    }

    return (
      <>
        <EntityLink
          isActive={false}
          label={investmentOptionSelected.label as string}
          entityLogo={
            <IconWrapper optionType={investmentOptionSelected.type}>
              <FontAwesomeIcon
                color={theme.colors.primaryBlue}
                size="sm"
                icon={investmentOptionSelected.nonSelectedIcon}
              />
            </IconWrapper>
          }
          onClickHandler={() => {
            const entityUrl = investmentOptionSelected.url

            if (url.current !== entityUrl) {
              history.push(investmentOptionSelected.url)
            }
          }}
        />

        <ArrowWrapper>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ArrowWrapper>
      </>
    )
  }

  return {
    displayNavigationOptionBreadcrumbItem,
  }
}

export default useInvestmentsBreadcrumb
