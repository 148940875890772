import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'

import PreviewContents from 'components/PreviewContents'
import { usePreviewContents } from 'utils/hooks/files/usePreviewContents'
import { MixedContent } from 'utils/types/files'

import ContentItem from './ContentItem'
import * as Styles from './Contents.styles'

const MAX_VISIBLE_CONTENTS = 3

interface ContentsProps {
  contents: MixedContent[]
}

const Contents = ({ contents }: ContentsProps) => {
  const params = useParams<{
    id?: string
  }>()
  const [visibleContent, setVisibleContent] = useState(
    contents.slice(0, MAX_VISIBLE_CONTENTS)
  )
  const { onClosePreview, onPreviewContent, selectedContent } =
    usePreviewContents()

  const hiddenContent = contents.slice(MAX_VISIBLE_CONTENTS)

  const onShowMoreHandler = () => {
    setVisibleContent([...visibleContent, ...hiddenContent])
  }

  const onShowLessHandler = () => {
    setVisibleContent(contents.slice(0, MAX_VISIBLE_CONTENTS))
  }

  return (
    <Styles.Contents>
      {visibleContent.map((content) => (
        <ContentItem
          content={content}
          isHighlighted={params.id === content.id}
          onClick={onPreviewContent}
        />
      ))}
      {contents.length > visibleContent.length && (
        <Styles.ShowMore onClick={onShowMoreHandler}>
          <FormattedMessage
            id="files.details.showMore"
            values={{ count: hiddenContent.length }}
          />
        </Styles.ShowMore>
      )}
      {contents.length === visibleContent.length &&
        visibleContent.length > MAX_VISIBLE_CONTENTS && (
          <Styles.ShowMore onClick={onShowLessHandler}>
            <FormattedMessage id="files.details.showLess" />
          </Styles.ShowMore>
        )}
      {selectedContent && (
        <PreviewContents
          contents={contents}
          selectedContent={selectedContent}
          isOpen={!!selectedContent}
          onClosePreview={onClosePreview}
        />
      )}
    </Styles.Contents>
  )
}

export default Contents
