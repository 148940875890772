import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Button from 'ui/Button'
import { usePlanFeatures } from 'utils/hooks/usePlanFeatures'
import CreateInbound from '../CreateInbound'
import {
  Container,
  Separator,
  SubTitle,
  Title,
  TitleContainer,
} from './Header.styles'

const Header = () => {
  const [isCreateInboundOpen, setIsCreateInbound] = useState(false)
  const { hasOnlyDefaultInbound } = usePlanFeatures()

  const openCreateInbound = () => {
    setIsCreateInbound(true)
  }

  const closeCreateInbound = () => {
    setIsCreateInbound(false)
  }

  return (
    <>
      <Container>
        <TitleContainer>
          <Title>
            <FormattedMessage id="inbounds.title" />
          </Title>
          <SubTitle>
            <FormattedMessage
              id={
                hasOnlyDefaultInbound
                  ? 'inbounds.defaultInboundDescription'
                  : 'inbounds.description'
              }
            />
          </SubTitle>
        </TitleContainer>
        {!hasOnlyDefaultInbound && (
          <Button onClick={openCreateInbound} primary>
            <FormattedMessage id="inbounds.create" />
          </Button>
        )}
      </Container>
      <Separator />
      <CreateInbound
        isOpen={isCreateInboundOpen}
        onClose={closeCreateInbound}
      />
    </>
  )
}

export default Header
