import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactChildren } from 'utils/types/common'
import FadeIn from 'components/FadeIn'
import theme from 'utils/theme'

import * as Styles from './Pagination.styles'

interface PaginationProps {
  children?: ReactChildren
  isVisible: boolean
  isPreviousDisabled: boolean
  isNextDisabled: boolean
  hideButtonsInsteadOfDisabling?: boolean
  isButtonRounded?: boolean
  onPreviousClick: () => void
  onNextClick: () => void
}

const Pagination: React.FC<PaginationProps> = ({
  children,
  isVisible,
  isPreviousDisabled,
  isNextDisabled,
  isButtonRounded = true,
  hideButtonsInsteadOfDisabling,
  onPreviousClick,
  onNextClick,
  ...rest
}) => {
  if (!isVisible) return null

  return (
    <FadeIn {...rest}>
      <Styles.Pagination className="actions">
        {!hideButtonsInsteadOfDisabling || !isPreviousDisabled ? (
          <Styles.PaginationButton
            onClick={onPreviousClick}
            type="button"
            disabled={isPreviousDisabled}
            isRounded={isButtonRounded}
          >
            <FontAwesomeIcon icon={['fal', 'chevron-left']} />
          </Styles.PaginationButton>
        ) : (
          <div />
        )}
        {children}
        {!hideButtonsInsteadOfDisabling || !isNextDisabled ? (
          <Styles.PaginationButton
            onClick={onNextClick}
            type="button"
            disabled={isNextDisabled}
            isRounded={isButtonRounded}
          >
            <FontAwesomeIcon
              icon={['fal', 'chevron-right']}
              color={theme.colors.darkBlue}
            />
          </Styles.PaginationButton>
        ) : (
          <div />
        )}
      </Styles.Pagination>
    </FadeIn>
  )
}

export default Pagination
