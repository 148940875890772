import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import {
  DEFAULT_ROUTES_ARRAY,
  DefaultRoutes,
  getHideNavigation,
  RoutesType,
} from 'reducers/breadcrumbSlice'
import { BreadcrumbWrapper } from './Breadcrumb.styles'
import useDisplayDefaultBreadcrumbNavigation from './BreadcrumbNavigation/hooks/useDisplayDefaultBreadcrumbNavigation'

interface BreadcrumbProps {
  navigationComponentFunc: () => JSX.Element | null
  baseRoute: RoutesType
  showBreadcrumb: boolean
  isFounder?: boolean
  children: React.ReactNode
}

const Breadcrumb = ({
  children,
  baseRoute,
  isFounder = false,
  navigationComponentFunc,
  showBreadcrumb,
}: BreadcrumbProps) => {
  const [showDefaultBreadcrumb, setShowDefaultBreadcrumb] = useState(false)
  const hideNavigation = useAppSelector(getHideNavigation)

  const { defaultNavigationComponentFunc } =
    useDisplayDefaultBreadcrumbNavigation({
      baseRoute,
      isFounder,
    })

  useEffect(() => {
    if (DEFAULT_ROUTES_ARRAY.includes(baseRoute as DefaultRoutes)) {
      setShowDefaultBreadcrumb(true)
      return
    }

    setShowDefaultBreadcrumb(false)
  }, [baseRoute])

  return (
    <>
      {showBreadcrumb && (
        <>
          <BreadcrumbWrapper hideNavigation={hideNavigation}>
            {showDefaultBreadcrumb
              ? defaultNavigationComponentFunc()
              : navigationComponentFunc()}
            <div
              id="breadcrumb-actions-portal"
              style={{
                width: hideNavigation ? 'inherit' : 'auto',
              }}
            />
          </BreadcrumbWrapper>
          <div id="breadcrumb-actions-below-portal" />
        </>
      )}

      {children}
    </>
  )
}

export default Breadcrumb
