import dayjs from 'dayjs'
import {
  ScheduleUpdatePayload,
  PermissionsPayload,
  TagsPayload,
  ContentsPayload,
  EditUpdateItemPayload,
  CreateUpdateItemPayload,
  EditDraftUpdateItemPayload,
} from 'utils/types/updatePayloads'
import { Nullable, Optional } from 'utils/types/common'
import { MixedContent } from 'utils/types/files'
import {
  ScheduleType,
  Tag,
  Update,
  DraftHashAttributes,
} from 'utils/types/update'
import {
  AccessType,
  CreateTransactionFormValues,
  ShareWithEntity,
  UpdatePermissions,
} from 'utils/types/updateForm'
import { UpdateVisibility } from 'utils/constants/updates'
import { AppGroup } from 'utils/types/user'
import { TransactionFundTypes } from 'utils/types/transactions'
import { TransactionFundType } from 'utils/constants/transactionTypes'
import { IndexInvestor } from 'utils/types/investors'
import { AssociationType } from 'utils/constants/investorManagement'
import {
  CUSTOM,
  ONLY_ME,
  getRemovedLoggingUpdateEntityIds,
  PUBLIC,
} from '../updates'
import {
  CreateUpdatePayloadParams,
  EditUpdatePayloadParams,
  ScheduleUpdatePayloadParams,
  PermissionsPayloadParams,
  ScheduleUpdateParams,
  EditDraftUpdatePayloadParams,
} from './types'

export const getScheduleUpdatePayload = ({
  loggingUpdateScheduleId,
  scheduleType,
  date,
  repetition,
}: ScheduleUpdatePayloadParams): ScheduleUpdatePayload => {
  if (loggingUpdateScheduleId) {
    // Update was originally scheduled
    if (scheduleType === ScheduleType.SEND_NOW) {
      // User wants to send now
      return {
        loggingUpdateSchedule: {
          id: loggingUpdateScheduleId!,
          _destroy: true,
        },
      }
    }
    // User wants to reschedule
    return {
      loggingUpdateSchedule: {
        id: loggingUpdateScheduleId!,
        scheduleDate: date,
        repetition,
      },
    }
  }

  // Update wasn't scheduled
  if (scheduleType === ScheduleType.SCHEDULE) {
    // User wants to schedule
    return {
      loggingUpdateSchedule: {
        scheduleDate: date,
        repetition,
      },
    }
  }

  // User wants to send now
  return { loggingUpdateSchedule: undefined }
}

export const getPermissionsPayload = ({
  visibility,
  sharedGroups,
  sharedUsers,
  removedGroups,
  removedUsers,
  ownByGroup,
}: PermissionsPayloadParams): PermissionsPayload => {
  const payload = {
    visibility,
  } as PermissionsPayload

  if (visibility === CUSTOM || visibility === ONLY_ME) {
    const sharedGroupsPayload = sharedGroups.map((groupId) => ({
      groupableId: groupId,
      original: true,
    }))
    const removedGroupsPayload = removedGroups.map((groupId) => ({
      id: groupId,
      _destroy: true,
    }))
    const sharedUsersPayload = sharedUsers.map((userId) => ({
      userId,
      original: true,
    }))

    const removedUsersPayload = removedUsers.map((userId) => ({
      id: userId,
      _destroy: true,
    }))

    payload.loggingUpdateGroupsAttributes = [
      ...sharedGroupsPayload,
      ...removedGroupsPayload,
    ]
    payload.loggingUpdateUsersAttributes = [
      ...sharedUsersPayload,
      ...removedUsersPayload,
    ]
  }

  if (visibility === CUSTOM || visibility === PUBLIC) {
    payload.ownedByGroup = ownByGroup
  } else {
    payload.ownedByGroup = false
  }

  return payload
}

interface GetCreatePermissionsParams {
  permissions: UpdatePermissions
  currentGroup: Nullable<AppGroup>
  allowEditInvestorPermissionsOnShow?: boolean
  transactionFundType?: Nullable<TransactionFundTypes>
  investor?: Nullable<IndexInvestor>
}

export const getCreatePermissions = ({
  permissions,
  currentGroup,
  transactionFundType,
  investor,
  allowEditInvestorPermissionsOnShow,
}: GetCreatePermissionsParams) => {
  const isOnlyMe =
    permissions.yourGroup === AccessType.NO_ACCESS &&
    permissions.community === AccessType.NO_ACCESS &&
    permissions.sharedWith.length === 0
  const isPublic = permissions.community === AccessType.CAN_VIEW
  const defaultVisibility = isPublic
    ? UpdateVisibility.PUBLIC
    : UpdateVisibility.CUSTOM
  const visibility = isOnlyMe ? UpdateVisibility.ONLY_ME : defaultVisibility
  const currentGroupPermission =
    permissions.yourGroup !== AccessType.NO_ACCESS && currentGroup
      ? [currentGroup.id]
      : []

  const shareWithInvestor =
    (transactionFundType === TransactionFundType.INVESTOR ||
      allowEditInvestorPermissionsOnShow) &&
    permissions.investor === AccessType.CAN_VIEW

  let shareWithGroupsId: string[] = permissions.sharedWith
    .filter((entity) => entity.type === 'group')
    .map((entity) => entity.id)

  if (shareWithInvestor) {
    shareWithGroupsId = [...shareWithGroupsId, investor?.id!]
  }

  return {
    visibility,
    sharedGroups: isPublic
      ? []
      : [...shareWithGroupsId, ...currentGroupPermission],
    sharedUsers: isPublic
      ? []
      : permissions.sharedWith
          .filter((entity) => entity.type === 'user')
          .map((entity) => entity.id),
    removedGroups: [],
    removedUsers: [],
    ownByGroup: permissions.yourGroup === AccessType.CAN_EDIT,
    confidentialUpdate: permissions.confidentialUpdate,
  }
}

interface GetEditPermissionsParams {
  permissions: UpdatePermissions
  currentGroup: Nullable<AppGroup>
  allowEditInvestorPermissionsOnShow?: boolean
  oldUpdate?: Update
  values?: CreateTransactionFormValues
}

export const getEditPermissions = ({
  permissions,
  currentGroup,
  oldUpdate,
  values,
  allowEditInvestorPermissionsOnShow,
}: GetEditPermissionsParams) => {
  const editPermissions = getCreatePermissions({
    permissions,
    currentGroup,
    transactionFundType:
      values?.transactionFundType || oldUpdate?.item.transactionFundType,
    investor: values?.investor || oldUpdate?.item.investor,
    allowEditInvestorPermissionsOnShow,
  })
  const isPublic = editPermissions.visibility === UpdateVisibility.PUBLIC
  let removedGroups: string[] = []
  let removedUsers: string[] = []

  if (!isPublic) {
    if (oldUpdate) {
      removedGroups = getRemovedLoggingUpdateEntityIds(
        oldUpdate.loggingUpdateGroups,
        editPermissions.sharedGroups,
        editPermissions.visibility
      )

      removedUsers = getRemovedLoggingUpdateEntityIds(
        oldUpdate.loggingUpdateUsers,
        editPermissions.sharedUsers,
        editPermissions.visibility
      )

      // Leave only the newly added groups and users
      editPermissions.sharedGroups = editPermissions.sharedGroups.filter(
        (groupId) =>
          !oldUpdate.loggingUpdateGroups.some(
            (group) => group.groupableId === groupId
          )
      )

      editPermissions.sharedUsers = editPermissions.sharedUsers.filter(
        (userId) =>
          !oldUpdate.loggingUpdateUsers.some((user) => user.userId === userId)
      )
    }
  } else {
    editPermissions.sharedGroups = []
    editPermissions.sharedUsers = []
  }

  return { ...editPermissions, removedGroups, removedUsers }
}

export const getEditDraftPermissions = (
  permissions: UpdatePermissions,
  currentGroup: Nullable<AppGroup>,
  update?: Update,
  // TODO TRANSACTION: Add support to show transaction investor access when draft transactions are supported
  _values?: CreateTransactionFormValues
) => {
  return getEditPermissions({
    permissions,
    currentGroup,
    oldUpdate: update,
  })
}

export const getPermissionsFromDraftHash = (
  draftHash: DraftHashAttributes,
  updateGroupId: string,
  transactionInvestorId?: string
): UpdatePermissions => {
  const confidentialUpdate = draftHash.confidential

  const loggingGroups = draftHash.loggingUpdateGroups?.filter(
    (group) =>
      group.groupableId !== updateGroupId &&
      group.groupableId !== transactionInvestorId
  )

  const groups: ShareWithEntity[] = (loggingGroups || []).map((group) => ({
    id: group.groupableId,
    name: group.groupName,
    type: 'group',
    imageUrl: group.groupLogo.smallLogo.url,
  }))

  const users: ShareWithEntity[] = (draftHash.loggingUpdateUsers || []).map(
    (user) => ({
      id: user.userId,
      name: user.userName || user.userEmail,
      type: 'user',
      imageUrl: user.userImage.smallLogo.url,
    })
  )

  const communityAccess =
    draftHash.visibility === UpdateVisibility.PUBLIC
      ? AccessType.CAN_VIEW
      : AccessType.NO_ACCESS

  let yourGroupAccess = draftHash.loggingUpdateGroups.find(
    (group) => group.groupableId === updateGroupId
  )
    ? AccessType.CAN_VIEW
    : AccessType.NO_ACCESS
  yourGroupAccess = draftHash.ownedByGroup
    ? AccessType.CAN_EDIT
    : yourGroupAccess

  if (
    draftHash.visibility === UpdateVisibility.PUBLIC &&
    yourGroupAccess !== AccessType.CAN_EDIT
  ) {
    yourGroupAccess = AccessType.CAN_VIEW
  }

  const investorGroupAccess = draftHash.loggingUpdateGroups.find(
    (group) => group.groupableId === transactionInvestorId
  )
    ? AccessType.CAN_VIEW
    : AccessType.NO_ACCESS

  return {
    yourGroup: yourGroupAccess,
    community: communityAccess,
    investor: investorGroupAccess,
    sharedWith: [...groups, ...users],
    confidentialUpdate,
  }
}

export const getPermissionsFromServerUpdate = (
  update: Update
): UpdatePermissions => {
  const confidentialUpdate = update.confidential

  const loggingGroups = update.loggingUpdateGroups?.filter(
    (group) =>
      group.groupableId !== update.group?.id &&
      group.groupableId !== update.item.investor?.id
  )
  const groups: ShareWithEntity[] = (loggingGroups || []).map((group) => ({
    id: group.groupableId,
    name: group.groupName,
    type: 'group',
    imageUrl: group.groupLogo.smallLogo.url,
  }))
  const users: ShareWithEntity[] = (update.loggingUpdateUsers || []).map(
    (user) => ({
      id: user.userId,
      name: user.userName || user.userEmail,
      type: 'user',
      imageUrl: user.userImage.smallLogo.url,
    })
  )

  const communityAccess =
    update.visibility === UpdateVisibility.PUBLIC
      ? AccessType.CAN_VIEW
      : AccessType.NO_ACCESS

  let yourGroupAccess = update.loggingUpdateGroups?.find(
    (group) => group.groupableId === update.group?.id
  )
    ? AccessType.CAN_VIEW
    : AccessType.NO_ACCESS
  yourGroupAccess = update.ownedByGroup ? AccessType.CAN_EDIT : yourGroupAccess

  if (
    update.visibility === UpdateVisibility.PUBLIC &&
    yourGroupAccess !== AccessType.CAN_EDIT
  ) {
    yourGroupAccess = AccessType.CAN_VIEW
  }

  const investorGroupAccess = update.loggingUpdateGroups?.find(
    (group) => group.groupableId === update.item.investor?.id
  )
    ? AccessType.CAN_VIEW
    : AccessType.NO_ACCESS

  return {
    yourGroup: yourGroupAccess,
    community: communityAccess,
    investor: investorGroupAccess,
    sharedWith: [...groups, ...users],
    confidentialUpdate,
  }
}

export const getTagsPayload = ({
  addedTags = [],
  removedTags = [],
  newTags = [],
}: {
  addedTags?: Tag[]
  removedTags?: Tag[]
  newTags?: Tag[]
}): TagsPayload => {
  return {
    taggingsAttributes: [
      ...addedTags.map((tag) => ({ tagId: tag.id })),
      ...removedTags.map((tag) => ({ id: tag.taggingId, _destroy: true })),
      ...newTags.map((tag) => ({ tagAttributes: { name: tag.name } })),
    ],
  }
}

export const getContentsPayload = ({
  addedContents = [],
  removedFiles = [],
  suggestedUpdateId,
}: {
  addedContents?: MixedContent[]
  removedFiles?: MixedContent[]
  suggestedUpdateId?: string
}): ContentsPayload => {
  return {
    contentUpdatesAttributes: [
      ...(suggestedUpdateId ? [{ contentId: suggestedUpdateId }] : []),
      ...addedContents.map((fileContent) => ({
        contentId: fileContent.id,
      })),
      ...removedFiles.map((fileContent) => ({
        id: fileContent.id,
        _destroy: true,
      })),
    ],
  }
}

export const getUpdatePayloadDate = (
  date: Date,
  shouldBehaveAsFounder: boolean,
  schedule?: ScheduleUpdateParams,
  isDraft?: boolean
): Nullable<Date> => {
  // DRAFT-TODOS: review this logic. Maybe it's not applies anymore but must be backward compatible
  if (isDraft) return date

  if (schedule?.scheduleType === ScheduleType.SCHEDULE) {
    // If update is scheduled, send null dates
    return null
  }

  if (shouldBehaveAsFounder) {
    // If update is not scheduled and is creating as a founder, set the selected date and time
    return date
  }

  // If creating as a client
  if (dayjs(date).isBefore(dayjs().startOf('day'))) {
    // For past dates, set 00:00hs
    return dayjs(date).hour(12).minute(0).toDate()
  }
  // For today, set current time
  return new Date()
}

export const getCreateUpdateItemPayload = ({
  title,
  body,
  date,
  tags,
  reshareSettings,
  schedule = { scheduleType: ScheduleType.SEND_NOW },
  visibility,
  sharedGroups,
  sharedUsers,
  ownByGroup,
  uploads,
  shouldBehaveAsFounder,
}: Optional<
  CreateUpdatePayloadParams,
  'body' | 'investor' | 'portfolio' | 'associationType'
>): CreateUpdateItemPayload => {
  const payloadDate = getUpdatePayloadDate(
    date,
    shouldBehaveAsFounder,
    schedule
  )

  return {
    title,
    text: body || '',
    date: payloadDate,
    loggingUpdateAttributes: {
      date: payloadDate,
      confidential: reshareSettings.isConfidential,
      blocked: reshareSettings.isBlocked,
      ...getContentsPayload({ addedContents: uploads, removedFiles: [] }),
      ...getTagsPayload({
        addedTags: tags.filter((tag) => tag.alreadyExists),
        newTags: tags.filter((tag) => !tag.alreadyExists && !tag.groupId),
      }),
      ...getPermissionsPayload({
        visibility,
        sharedGroups,
        sharedUsers,
        removedGroups: [],
        removedUsers: [],
        ownByGroup,
      }),
    },
  }
}

// this function is used to edit the permissions in the View Update screen
export const getEditUpdateItemPayload = ({
  updateId,
  title,
  body,
  date,
  reshareSettings,
  schedule = { scheduleType: ScheduleType.SEND_NOW },
  visibility,
  sharedGroups,
  sharedUsers,
  removedGroups,
  removedUsers,
  ownByGroup,
  uploads,
  shouldBehaveAsFounder,
}: Optional<
  EditUpdatePayloadParams,
  'body' | 'date' | 'investor' | 'portfolio' | 'associationType'
>): EditUpdateItemPayload => {
  const payloadDate = date
    ? getUpdatePayloadDate(date, shouldBehaveAsFounder, schedule)
    : null

  return {
    title,
    text: body || '',
    date: payloadDate,
    loggingUpdateAttributes: {
      id: updateId,
      date: payloadDate,
      confidential: reshareSettings.isConfidential,
      blocked: reshareSettings.isBlocked,
      ...getContentsPayload({
        addedContents: uploads?.filter((file) => file.isNewUpload) ?? [],
        removedFiles: uploads?.filter((file) => file.destroy) ?? [],
      }),
      ...getPermissionsPayload({
        visibility,
        sharedGroups,
        sharedUsers,
        removedGroups,
        removedUsers,
        ownByGroup,
      }),
    },
  }
}

export const getEditDraftPayload = (
  {
    title,
    body,
    date,
    reshareSettings,
    addedTags,
    removedTags,
    schedule = { scheduleType: ScheduleType.SEND_NOW },
    visibility,
    sharedGroups,
    sharedUsers,
    removedGroups,
    removedUsers,
    ownByGroup,
    uploads,
    shouldBehaveAsFounder,
    portfolio,
    investor,
    associationType,
  }: EditDraftUpdatePayloadParams,
  suggestedUpdateId?: string
): EditDraftUpdateItemPayload => {
  const payloadDate = date
    ? getUpdatePayloadDate(date, shouldBehaveAsFounder, schedule, true)
    : undefined

  const schedulePayload = date
    ? getScheduleUpdatePayload({
        loggingUpdateScheduleId: schedule?.loggingUpdateScheduleId,
        scheduleType: schedule?.scheduleType,
        date,
        repetition: schedule?.repetition,
      })
    : { loggingUpdateSchedule: undefined }

  let payload: EditDraftUpdateItemPayload = {
    date: payloadDate,
    confidential: reshareSettings?.isConfidential,
    blocked: reshareSettings?.isBlocked,
    ...getContentsPayload({
      addedContents: uploads?.filter((file) => !file.destroy) ?? [],
      removedFiles: uploads?.filter((file) => file.destroy) ?? [],
      suggestedUpdateId,
    }),
    ...getPermissionsPayload({
      visibility,
      sharedGroups,
      sharedUsers,
      removedGroups,
      removedUsers,
      ownByGroup,
    }),

    ...getTagsPayload({
      addedTags,
      removedTags,
    }),

    ...schedulePayload,
    updatableAttributes: {
      title,
      text: body || '',
      date: payloadDate,
      associationType,
    },
  }

  if (associationType === AssociationType.INVESTOR) {
    payload = {
      ...payload,
      updatableAttributes: {
        ...payload.updatableAttributes,
        fundPortfolioInvestorAttributes: {
          fundPortfolioId: portfolio?.id,
          investorGroupId: investor?.id,
        },
      },
    }
  }

  return payload
}
