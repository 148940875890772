import useShowInvestor from 'containers/InvestorManagement/ShowInvestor/useShowInvestor'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { isActingAsInvestorGroup } from 'selectors/auth'
import { UpdateTypes } from 'utils/constants/updates'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import { useShowUpdateRoute } from 'utils/hooks/useShowUpdateRoutes'
import { UpdateUrlSuffix } from 'utils/hooks/useShowUpdateRoutes/types'
import { IndexUpdate, TransactionItem } from 'utils/types/update'

interface InvestorProfileProps {
  investorGroupId: string
}

const useInvestorProfile = ({ investorGroupId }: InvestorProfileProps) => {
  const history = useHistory()
  const {
    fundsPortfolioInvestor,
    isLoadingInvestor,
    isLoadingFunds,
    vehicles,
    onShowTransaction: onShowTransactionActingAsGP,
    onEditTransaction,
  } = useShowInvestor(investorGroupId)
  const showUpdateRoute = useShowUpdateRoute()
  const isInvestorGroup = useAppSelector(isActingAsInvestorGroup)
  const { handleOnUpdateClick } = useOnClickRedirect()

  const matchesProfile = useRouteMatch('/profile')

  const onShowFundTransaction = (transaction: TransactionItem) => {
    if (isInvestorGroup) {
      if (matchesProfile) {
        showUpdateRoute.fromInvestorProfile({
          updateId: transaction.loggingUpdateId,
          updateType: UpdateTypes.TRANSACTION,
        })
      } else {
        showUpdateRoute.fromInvestorGroupFund({
          updateId: transaction.loggingUpdateId,
          updateType: UpdateTypes.TRANSACTION,
          portfolioId: transaction.fundPortfolioId,
        })
      }
    } else {
      onShowTransactionActingAsGP(transaction)
    }
  }

  const onShowInvestmentVehicleTransaction = (transaction: TransactionItem) => {
    if (isInvestorGroup) {
      history.push(`/profile/transactions/${transaction.loggingUpdateId}`)
    } else {
      onShowTransactionActingAsGP(transaction)
    }
  }

  const onEditUpdate = (update: IndexUpdate) => {
    return handleOnUpdateClick({
      update,
      updateSuffix: UpdateUrlSuffix.EDIT,
    }).redirectFunction()
  }

  const onShowUpdate = (update: IndexUpdate) => {
    return handleOnUpdateClick({ update }).redirectFunction()
  }

  return {
    fundsPortfolioInvestor,
    isLoadingInvestor,
    isLoadingFunds,
    vehicles,
    onClickUpdate: onShowUpdate,
    onEditUpdate,
    onShowFundTransaction,
    onEditTransaction,
    onShowInvestmentVehicleTransaction,
  }
}

export default useInvestorProfile
