import { ReactNode, useEffect, useId, useState } from 'react'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'components/Tooltip'
import { Nullable } from 'utils/types/common'
import { getInitials } from 'utils/functions/user'
import Avatar from 'components/Avatar'

import theme from 'utils/theme'
import {
  StyledSpan,
  ChipContainer,
  IconWrapper,
  Title,
  Image,
  BackgroundImage,
  ContextMenu,
} from './Chip.styles'

interface Props {
  text: string | ReactNode
  dataTestId?: string
  backgroundImageMode?: boolean
  canDelete?: boolean
  deleteButtonColor?: string
  className?: string
  image?: Nullable<string>
  imageComponent?: Nullable<ReactNode>
  isSmall?: boolean
  maxWidth?: string
  onHoverShow?: Nullable<string>
  withBorder?: Nullable<boolean>
  withCursorPointer?: boolean
  isUnreachableEmail?: boolean
  hasError?: boolean
  titleWithTooltip?: boolean
  titleColor?: string
  isCapitalizedText?: boolean
  onClick?: () => void
  handleDelete?: () => void
  width?: string
  breakpoints?: { [key: string]: string }
  backgroundColor?: string
  contextualOptions?: JSX.Element[]
}

const Chip = ({
  dataTestId,
  backgroundImageMode = false,
  canDelete = true,
  deleteButtonColor = theme.colors.lightGray,
  className = '',
  handleDelete,
  image = null,
  imageComponent = null,
  isSmall = false,
  maxWidth = '',
  onClick,
  onHoverShow = null,
  text,
  withBorder = null,
  withCursorPointer = false,
  isUnreachableEmail = false,
  hasError = false,
  titleWithTooltip = false,
  isCapitalizedText = false,
  titleColor = '',
  width,
  breakpoints,
  backgroundColor,
  contextualOptions,
}: Props) => {
  const [showHover, setShowHover] = useState(false)
  const intl = useIntl()
  const id = useId()

  useEffect(() => {
    const mouseoverHandler = () => {
      setShowHover(true)
    }

    const mouseleaveHandler = () => {
      setShowHover(false)
    }

    if (onHoverShow) {
      document
        .getElementById(`${id}`)
        ?.addEventListener('mouseover', mouseoverHandler)

      document
        .getElementById(`${id}`)
        ?.addEventListener('mouseleave', mouseleaveHandler)
    }

    return () => {
      document
        .getElementById(`${id}`)
        ?.removeEventListener('mouseover', mouseoverHandler)

      document
        .getElementById(`${id}`)
        ?.removeEventListener('mouseleave', mouseleaveHandler)
    }
  }, [id, onHoverShow])

  const renderImage = () => {
    if (imageComponent) {
      return imageComponent
    }

    if (!image) {
      return null
    }

    if (image.includes('missing') && typeof text === 'string') {
      return (
        <Avatar
          image={undefined}
          initials={getInitials(text)}
          avatarStyle="avatarCircleXS"
        />
      )
    }

    if (backgroundImageMode) {
      return <BackgroundImage src={image} />
    }

    return <Image alt="logo" src={image} />
  }

  const chipWithErrors = isUnreachableEmail || hasError

  const renderErrorWrapper = () => {
    if (isUnreachableEmail) {
      return (
        <Tooltip
          id={id}
          place="top"
          leftOffset={-80}
          backgroundColor={theme.colors.red}
          text={intl.formatMessage({
            id: 'createEmail.unreachableEmailAddress',
          })}
        >
          <FontAwesomeIcon
            icon={['far', 'exclamation-triangle']}
            color={theme.colors.red}
          />
        </Tooltip>
      )
    }

    if (hasError) {
      return (
        <FontAwesomeIcon
          icon={['far', 'exclamation-triangle']}
          color={theme.colors.red}
        />
      )
    }

    return null
  }

  return (
    <ContextMenu items={contextualOptions} enabled={!!contextualOptions}>
      <ChipContainer
        id={id}
        data-testid={dataTestId || id}
        className={className}
        onClick={onClick}
        withBorder={withBorder}
        canDelete={canDelete}
        isSmall={isSmall}
        withCursorPointer={withCursorPointer}
        width={width}
        backgroundColor={backgroundColor}
        hasError={chipWithErrors}
      >
        {renderImage()}

        {titleWithTooltip ? (
          <Title
            showHoverText={showHover && onHoverShow !== null}
            canDelete={canDelete}
            maxWidth={maxWidth}
            color={titleColor}
            breakpoints={breakpoints}
          >
            <Tooltip
              id={id}
              text={text}
              place="left"
              leftOffset={10}
              topOffset={-20}
              widthAuto
              delayShow={500}
            >
              {text}
            </Tooltip>
          </Title>
        ) : (
          <Title
            showHoverText={showHover && onHoverShow !== null}
            canDelete={canDelete}
            maxWidth={maxWidth}
            color={titleColor}
            breakpoints={breakpoints}
            isCapitalizedText={isCapitalizedText}
          >
            {text}
          </Title>
        )}

        <StyledSpan>
          {showHover && onHoverShow !== null ? onHoverShow : ''}
        </StyledSpan>
        {canDelete && (
          <IconWrapper hasError={chipWithErrors}>
            {renderErrorWrapper()}
            <FontAwesomeIcon
              id="closeIcon"
              data-testid={`delete-button-${dataTestId || id}`}
              icon={['far', 'times']}
              color={deleteButtonColor}
              onClick={handleDelete}
            />
          </IconWrapper>
        )}
      </ChipContainer>
    </ContextMenu>
  )
}

export default Chip
