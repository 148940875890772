import styled from 'styled-components/macro'
import Modal from 'components/Modal'

import { color } from 'utils/functions/colors'

export const StyledModal = styled(Modal)`
  width: 46rem;
`

export const Question = styled.div`
  color: ${color('darkBlue')};
  font-size: 1.8rem;
  line-height: 2.16rem;
  font-weight: 400;
  margin-bottom: 1.6rem;
`

export const Description = styled.span`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  line-height: 1.68rem;
`
