import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from 'utils/theme'
import Tooltip from 'components/Tooltip'

import { UnreachableEmailTooltip } from './ShareWithEntity'
import * as Styles from './ShareWithEntities.styles'
import {
  getImageComponent,
  IconsTypes,
} from '../ShareWithDropdown/components/Icons'

interface EmailProps {
  name: string
  onRemove: () => void
  failedCreating?: boolean
  unreachable?: boolean
}

const Email: React.FC<EmailProps> = ({
  name,
  onRemove,
  failedCreating,
  unreachable,
}) => {
  return (
    <Styles.Container data-testid="shared-with-email">
      <Styles.NameContainer>
        {getImageComponent(IconsTypes.email)}
        <div>{name}</div>
      </Styles.NameContainer>
      <Styles.RightSideContainer>
        {!!failedCreating && (
          <Tooltip
            id={`errorAdding_${name}`}
            text={<FormattedMessage id="updates.errorAddingUser" />}
            arrowColor="transparent"
            place="right"
          >
            <FontAwesomeIcon
              icon={['far', 'exclamation-triangle']}
              color={theme.colors.yellow}
            />
          </Tooltip>
        )}
        {unreachable && <UnreachableEmailTooltip name={name} />}
        <Styles.SecondaryText>
          <FormattedMessage id="updates.canView" />
        </Styles.SecondaryText>
        <Styles.RemoveIconContainer>
          <FontAwesomeIcon
            icon={['far', 'times']}
            color={theme.colors.darkBlue}
            onClick={onRemove}
          />
        </Styles.RemoveIconContainer>
      </Styles.RightSideContainer>
    </Styles.Container>
  )
}

export default Email
