import { emails } from 'utils/constants/emails'
import { TRIAL_DAYS } from 'utils/constants/subscriptions'

export default {
  title: 'Sign up',
  setupGroup: 'Setup your Group',
  groupName: 'Group Name',
  addYourGroupName: 'Add your group name',
  groupNameSample: 'Example: The {userLastName} Group',
  quote1:
    '“Clockwork has helped us put portfolio monitoring and operations on auto-pilot so we can focus on what matters most, while company updates and consolidated LP reporting are handled seamlessly.”',
  quote2:
    '“Working with Clockwork provides our team with easy, centralized access to key data and updates from our portfolio companies, and features like alerts and the weekly digest help us better stay on top of our private investments.”',
  founderQuote1:
    "“I love that Clockwork Universe helps manage investor communications in one place. I've noticed that investors are more engaged with our updates, which has led to more productive relationships overall.”",
  founderQuote2:
    '“My investor communications have become more impactful with Clockwork Universe. This has helped foster more collaborative investor relationships as everyone is clearer on what my needs are and how they can best support what we’re building.”',
  getStarted: 'Get started with Clockwork Universe',
  modalLegend: 'Please select the type of account you would like to create. ',
  back: '< Back',
  legend:
    'Enter your email and we’ll send you a link to begin the account setup process.',
  emailPlaceholder: 'Email',
  sendEmail: 'Send email',
  emailConfirmation:
    'An email has been sent to you to complete the signup process',
  emailHasAlreadyBeentaken: 'The email is already taken, try another.',
  confirmationEmailTitle: 'Confirmation email sent',
  confirmationEmailLegend:
    'An email has been sent to you with a confirmation link. Clicking that link will confirm your email and allow you to proceed with the account setup process.',
  contactSupport: 'If you feel this is an error, contact {supportEmail}',
  supportEmail: `${emails.SUPPORT}`,
  error: 'An error occurred when trying to signup, please try again later',
  haveAccount: 'Have an account? {signinLink}',
  signin: 'Sign in',
  nextStep: 'Next',
  backStep: 'Back',
  substep1: 'Claim Company',
  substep2: 'Basic Info.',
  substep3: 'Additional Company Info (optional)',
  aboutCompany: 'About Company',
  claimCompany: 'Claim Company',
  additionalInformation: 'Additional Information',
  welcomeAboard: 'Welcome aboard!',
  welcomeAboardCite:
    'Clockwork Universe powers the actionable source of truth for investors and founders.',
  saveTime: 'Save time and build trust with better investor communications.',
  getStartedWithCW: 'Get started with Clockwork Universe',
  selectGroupTypeLegend: 'Select the type of group you would like to create',
  createNewGroup: 'Create a New Group',
  createNewGroupLegend: 'Create a group and invite all the members you want',
  joinGroup: 'Join a Group',
  joinGroupLegend:
    'To join a group you need to have received an email invitation',
  createGroup: 'Create a new Group',
  selectGroupType: 'Select the type of group you would like to create',
  noPendingInvitation: "You don't have any pending invitation",
  noPendingInvitationLegend1:
    'Verify you have provided the administrator or manager of the group with the correct email.',
  noPendingInvitationLegend2:
    'For further assistance, contact the {supportLink}.',
  noPendingInvitationLegend3:
    'Meanwhile, you can go to clockwork.app and explore all our features built for better Founders and Investors communications.',
  founder: 'Founder',
  areYouFounder: 'Are you a founder?',
  founderDescription:
    'You represent a company looking to manage communications with your investor and stakeholders',
  founderDescriptionAlt:
    "Unlock efficient communication for your company's investors and stakeholders.",
  getStartedNow: 'Get started now',
  investor: 'Investor',
  investorDescription:
    'You represent a group or individual looking to organize your private investment activities & manage communications about your investments',
  organization: 'Organization',
  organizationDescription:
    'You are an intermediary advisor or other group or individual involved in a private investment or investment portfolio',
  payment: {
    title: 'Payment details',
    billTo: 'Bill to:',
    confirmPurchase: 'Confirm purchase',
    confirmTrial: `Confirm ${TRIAL_DAYS}-day Free Trial`,
    poweredBy: 'Powered by {link}',
    stripe: 'Stripe',
    monthlyBilling: `Your 14-day free trial starts immediately. You'll be billed on {date} and each month after. Your subscription auto-renews monthly until the plan is canceled from the group settings Subscriptions and Billing. Please {help} if you need help.`,
    annualBilling: `Your 14-day free trial starts immediately. You'll be billed on {date} and each year after. Your subscription auto-renews annually until the plan is canceled from the group settings Subscriptions and Billing. Please {help} if you need help.`,
    immediateBilling:
      "Your plan starts immediately. You'll be billed on {date} and each month after. Your subscription auto-renews monthly until the plan is canceled from the group settings Subscriptions and Billing. There is a 1-month notice period required for your cancellation. Please {help} if you need help.",
    help: 'get in touch with Support',
    paymentFailed: 'Payment failed. Please try another payment method.',
    somethingWentWrong: 'Something went wrong.',
    createPaymentIntentError:
      'Error creating payment intent, please try again later.',
  },
  setup: {
    title: 'Tell us about yourself',
    subtitle: "Let's personalize your experience.",
    workEmailAddress: 'Work email address',
    firstName: 'First name',
    lastName: 'Last name',
    password: 'Password',
    repeatPassword: 'Repeat password',
    agreeToTerms:
      "By signing up, I agree to Clockwork's {termsAndConditions} and {privacyPolicy}",
    termsAndConditions: 'Terms and Conditions',
    privacyPolicy: 'Privacy Policy',
  },
  steps: {
    welcome: 'Welcome',
    addUserInfo: 'Add user info',
    setupYourGroup: 'Setup your group',
    addBillingInfo: 'Add billing info',
    addPaymentDetails: 'Add payment details',
  },
  billingAccount: {
    title: 'Billing account',
    billingInfo:
      'A billing account is used to store your payment information for your Clockwork Universe group subscription.',
    nameLabel: 'Billing Account Name',
    namePlaceholder: 'Add a name',
    emailLabel: 'Email',
    emailPlaceholder: 'Add email',
    phone: 'Phone',
    phonePlaceholder: '+1 (201) 555-5555',
    country: 'Country',
    addPostalAddress: 'Add postal address to appear on the bill',
    removePostalAddress: 'Remove postal address',
    street: 'Street Address / PO Box',
    streetPlaceholder: 'Add street address or PO Box',
    city: 'Town / City',
    cityPlaceholder: 'Add town or city',
    state: 'State / Region',
    statePlaceholder: 'Add state or region',
    zip: 'Postal / Zip code',
    zipPlaceholder: 'Add a postal or zip code',
    continue: 'Continue',
  },
  plans: {
    startTrial: 'Start 14-day free trial',
    pricingAndPlans: 'Pricing and Plans',
    monthly: 'Monthly',
    annually: 'Annually',
    mostPopular: 'Most popular',
    contactUs: 'Contact us',
    customPricing: 'Custom pricing',
    choosePlan: 'Choose the plan that suits you best. <a>Compare plans</a>.',
    pricesInUSD: 'Prices in USD',
    changePlanNote:
      'If you later decide to change to a less expensive plan you can do so 1 month after upgrading.',
    selectPlan: 'Select Plan',
    getInTouch: 'Get in Touch',
    perMonth: 'per month *',
    priceBilledAnnually: 'Monthly price billed annually',
    upFrontPrice: '{value} up front',
    additionalStorage: 'Additional storage upgrades are available',
    additionalSeats: 'Additional seats are available',
    selected: 'Selected',
    addPromoCode: 'Add promo code',
    addPromoCodeCaps: 'Add Promo Code',
    features: {
      oneInvestPortfolio: '1 Investment Portfolio & 5 Pipelines',
      upTo25Transactions: 'Up to 25 Transactions',
      csvExports: 'CSV Exports',
      unlimitedPortfolios: 'Unlimited Portfolios & Pipelines',
      unlimitedTransactions: 'Unlimited Transactions',
      trackMetrics: 'Track Metrics',
      sharedInbox: 'Shared inbox for Data Imports',
      addSeats: 'Add Seats for Your Team',
      reportStakeholders: 'Report to Stakeholders',
      customReporting: 'Custom Reporting',
      teamSupport: 'Clockwork Team Support',
      suitYourNeeds: 'Bespoke to Suit Your Needs',
    },
    lite: {
      name: 'Lite',
      description: 'Start with tracking and basic operations',
      amount: {
        monthly: '$50',
        annually: '$40',
      },
    },
    basic: {
      name: 'Individual',
      description: 'Level up with elevated investment management',
      amount: {
        monthly: '$165',
        annually: '$150',
      },
    },
    pro: {
      name: 'Pro',
      description: 'Professional tools for groups and offices',
      amount: {
        monthly: '$585',
        annually: '$550',
      },
    },
    organization: {
      name: 'Organization',
      description: 'Custom reporting, tools, and services',
    },
  },
  quotes: {
    quote1: {
      quote:
        '“Working with Clockwork provides our team with easy, centralized access to key data and updates from our portfolio companies, and features like alerts and the weekly digest help us better stay on top of our private investments.”',
      author: 'Moses Gate Holdings',
      authorTitle: 'Founder at Moses Gate Holdings',
    },
    quote2: {
      quote:
        '“Clockwork has helped us put portfolio monitoring and operations on auto-post so we can focus on what matters most, while company updates and consolidates LP reporting are handled seamlessly.”',
      author: 'Magic Hour',
      authorTitle: 'General Partner at Magic Hour',
    },
    investorQuote:
      'Clockwork Universe is a better way to monitor your private investment portfolio.\n\nEasily organize and engage with transparent portfolio company updates and investment data.',
    founderQuote:
      'Save time and build trust with better investor communications.',
  },
}
