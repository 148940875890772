import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Channel } from 'stream-chat'

import { ChannelType, StreamChatType } from 'containers/Chat/types'
import { OPEN_CHAT_POPUP_EVENT } from 'utils/constants/events'
import { useEventListener } from 'utils/hooks/useEventListener'
import { ReactFCWithChildren } from 'utils/types/common'
import { useChatSidebarContext, useSetActiveChannel } from '../../hooks'

export enum CHAT_MOBILE_STEPS {
  SIDEBAR = 'sidebar',
  ARCHIVED = 'archived',
  DIRECT_MESSAGE = 'channel',
  TOPIC_SIDEBAR = 'topicSidebar',
  TOPIC_CHANNEL = 'topicChannel',
}

type ChatMobileContextType = {
  step: CHAT_MOBILE_STEPS
  onArchiveButtonClick: () => void
  onGoToSidebar: () => void
  onTopicChannelClick: () => void
  onSelectChannelFromSidebar: (channel: Channel<StreamChatType>) => void
  openChannel: (channel: Channel<StreamChatType>) => void
  onChannelGoBack: (channel: Channel<StreamChatType>) => void
}

const ChatMobileContext = createContext<ChatMobileContextType | undefined>(
  {} as ChatMobileContextType
)

export const ChatMobileContextProvider: ReactFCWithChildren = ({
  children,
}) => {
  const { isArchiveSidebarActive, hideArchiveSidebar } = useChatSidebarContext()
  const [step, setStep] = useState<CHAT_MOBILE_STEPS>(CHAT_MOBILE_STEPS.SIDEBAR)
  const setActiveChannel = useSetActiveChannel()

  const onArchiveButtonClick = useCallback(() => {
    setStep(CHAT_MOBILE_STEPS.ARCHIVED)
  }, [])

  const onChannelGoBack = useCallback(
    (channel: Channel<StreamChatType>) => {
      if (isArchiveSidebarActive) {
        setActiveChannel(undefined)
        setStep(CHAT_MOBILE_STEPS.ARCHIVED)
      } else if (channel.type === ChannelType.DIRECT_MESSAGE) {
        setStep(CHAT_MOBILE_STEPS.SIDEBAR)
      } else {
        setStep(CHAT_MOBILE_STEPS.TOPIC_SIDEBAR)
      }
    },
    [isArchiveSidebarActive, setActiveChannel]
  )

  const onGoToSidebar = useCallback(() => {
    setStep(CHAT_MOBILE_STEPS.SIDEBAR)
    hideArchiveSidebar()
    setActiveChannel(undefined)
  }, [hideArchiveSidebar, setActiveChannel])

  const onSelectChannelFromSidebar = useCallback(
    (channel: Channel<StreamChatType>) => {
      if (channel.type === ChannelType.DIRECT_MESSAGE) {
        setStep(CHAT_MOBILE_STEPS.DIRECT_MESSAGE)
      } else {
        setStep(CHAT_MOBILE_STEPS.TOPIC_SIDEBAR)
      }
    },
    []
  )

  const onTopicChannelClick = useCallback(() => {
    setStep(CHAT_MOBILE_STEPS.TOPIC_CHANNEL)
  }, [])

  const openChannel = useCallback((channel: Channel<StreamChatType>) => {
    if (channel.type === ChannelType.DIRECT_MESSAGE) {
      setStep(CHAT_MOBILE_STEPS.DIRECT_MESSAGE)
    } else {
      setStep(CHAT_MOBILE_STEPS.TOPIC_CHANNEL)
    }
  }, [])

  const value = useMemo(
    () => ({
      step,
      onArchiveButtonClick,
      onGoToSidebar,
      onTopicChannelClick,
      onChannelGoBack,
      onSelectChannelFromSidebar,
      openChannel,
    }),
    [
      step,
      onArchiveButtonClick,
      onGoToSidebar,
      onTopicChannelClick,
      onChannelGoBack,
      onSelectChannelFromSidebar,
      openChannel,
    ]
  )

  useEffect(() => {
    if (isArchiveSidebarActive) {
      setStep(CHAT_MOBILE_STEPS.ARCHIVED)
    }
  }, [isArchiveSidebarActive])

  useEventListener(OPEN_CHAT_POPUP_EVENT, openChannel)

  return (
    <ChatMobileContext.Provider value={value}>
      {children}
    </ChatMobileContext.Provider>
  )
}

export const useChatMobileContext = () => {
  const context = useContext(ChatMobileContext)

  if (!context) {
    throw new Error(
      'useChatMobileContext must be used within ChatMobileContext'
    )
  }

  return context
}
