import styled, { css } from 'styled-components'
import FileIconToStyle from 'components/FileIcon'
import { color } from 'utils/functions/colors'

export const Content = styled.li<{ isHighlighted: boolean; isImage: boolean }>`
  align-items: center;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightGray')};
  color: ${color('darkGray')};
  cursor: pointer;
  display: flex;
  gap: 1rem;
  height: 4rem;
  overflow: hidden;
  padding: 0.8rem;

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      border: 0.1rem solid ${color('primaryBlue')};
      color: ${color('primaryBlue')};

      a[id*='update-file'] {
        display: flex;
        margin-bottom: 0.2rem;
      }
    `}

  ${({ isImage }) =>
    isImage &&
    css`
      padding: 0;
    `}

  &:hover {
    background-color: ${color('veryLightGray', 0.2)};
    color: ${color('primaryBlue')};
  }
`

export const Image = styled.img`
  height: 3.8rem;
  object-fit: cover;
  width: 3.8rem;
`

export const FileName = styled.span<{
  isEmailContent: boolean
}>`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 21rem;
  white-space: nowrap;
  vertical-align: ${({ isEmailContent }) =>
    isEmailContent ? 'top' : 'middle'}};
`

export const FileIcon = styled(FileIconToStyle)<{
  isEmailContent: boolean
}>`
   {
    ${({ isEmailContent }) =>
      isEmailContent &&
      css`
        div {
          width: 2.4rem;
        }

        svg {
          width: 2.2rem;
          margin-left: 0.5rem;
        }
      `}
  }
`
