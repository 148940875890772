import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const PositionContainer = styled.div`
  position: fixed;
  left: 30.3rem;
  bottom: 2.2rem;
  z-index: 99999;

  @media ${maxSize.lg} {
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
`

export const Container = styled.div`
  width: 44.3rem;
  padding: 2rem 1.2rem 2rem 2.4rem;
  background: white;
  border: 2px solid ${color('veryLightBlue')};
  border-radius: 8px;

  @media ${maxSize.lg} {
    max-width: 100%;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin-right: 1.2rem;
  }
`
export const Title = styled.h1`
  font-weight: 400;
  font-size: 18px;
  line-height: 2.2rem;
  color: ${color('gray')};
  margin: 0;
`

export const Expandable = styled.div<{ isExpanded: boolean }>`
  height: auto;
  transition: max-height 0.3s, opacity 0.3s;
  overflow: hidden;
  ${(props) =>
    props.isExpanded
      ? css`
          max-height: 40rem;
          opacity: 1;
        `
      : css`
          max-height: 0;
          opacity: 0;
        `}
`

export const Content = styled.div`
  max-height: 40rem;
  overflow-y: auto;
  padding-right: 1.2rem;
`
