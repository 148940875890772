import { MAX_PREVIEW_SIZE_ALLOWED } from 'utils/constants/files'
import {
  FileExtension,
  UserContentStatus,
  isFileFormatImage,
  FileContent,
  MixedContent,
  IndexFileContent,
  IndexEmailContent,
  AttachedEmailFileContent,
} from 'utils/types/files'
import { round } from './number'

export const hasProperSize = (file, maxSize) => file.size <= maxSize

export const hasProperType = (file, type) =>
  type ? file.type.split('/')[0] === type : true

export const parseFilesUrl = (url) => {
  const parseAWSUrl = url.split('?')[0].split('.')
  const { length } = parseAWSUrl
  const type = parseAWSUrl[length - 1]
  const fullPAthName = parseAWSUrl[length - 2]
  const name = fullPAthName?.split('/')?.pop()

  return { urlName: name, urlType: type }
}

export const getFileType = (type) => type?.split('/')[1]

export const prettifySize = (size) => {
  let result = size / 1000

  if (result > 1000) {
    result /= 1000
    return `${result.toPrecision(3)} MB`
  }
  return `${Math.round(result)} KB`
}

export const handleDownloadClick = ({ name, src }) => {
  const link = document.createElement('a')
  link.download = name
  link.href = src
  link.rel = 'noopener noreferrer'
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const download = (filename, src) => {
  const element = document.createElement('a')
  element.target = 'blank'
  element.setAttribute('href', src)
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

export const downloadBlob = (fileName: string, blob: Blob) => {
  const url = window.URL.createObjectURL(blob)
  download(fileName, url)
}

export const getFileNameFromUrl = (url) =>
  url?.split('?')?.[0].split('/')?.pop()

export const downloadJson = (object, fileName) => {
  const dataStr = `data:text/json;charset=utf-8,${encodeURIComponent(
    JSON.stringify(object, null, 2)
  )}`
  const downloadElement = document.createElement('a')
  downloadElement.setAttribute('href', dataStr)
  downloadElement.setAttribute('download', fileName)
  downloadElement.click()
}

export const dataUrlToFile = async (dataUrl, fileName, fileProperty) => {
  const res = await fetch(dataUrl)
  const blob = await res.blob()
  return new File([blob], fileName, fileProperty || { type: 'image/png' })
}

export const isBase64 = (data) => {
  return data?.includes(';base64,')
}

export const base64ToFile = (dataurl, filename) => {
  if (dataurl) {
    const arr = dataurl?.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }
  return null
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const displayFileSize = (bytes) => {
  if (bytes > 1000000) {
    return `${round(bytes / 1000000, 1)}mb`
  }
  if (bytes > 1000) {
    return `${round(bytes / 1000, 1)}kb`
  }
  return `${bytes}b`
}

export const getFileExtension = (type?: FileExtension) => {
  return type && isFileFormatImage(type) ? FileExtension.IMAGE : type
}

export const isFileAnImage = (fileType) =>
  getFileExtension(fileType) === FileExtension.IMAGE

export const isUnreconciledFile = (userContentStatus: UserContentStatus) =>
  userContentStatus === UserContentStatus.GROUP_UNRECONCILED ||
  userContentStatus === UserContentStatus.USER_UNRECONCILED

export const isPreviewAvailable = (fileContent: FileContent) => {
  return fileContent.fileSize <= MAX_PREVIEW_SIZE_ALLOWED
}

export function isAttachedEmailFileContent(
  content?: MixedContent
): content is AttachedEmailFileContent {
  return (content as AttachedEmailFileContent).unreconciled !== undefined
}

export function isIndexFileContent(
  content?: MixedContent
): content is IndexFileContent {
  // TODO check this because fileFormat is in FileContent not in IndexFileContent
  return !!(content as IndexFileContent)?.fileFormat
}

export function isIndexEmailContent(
  content?: MixedContent
): content is IndexEmailContent {
  return !!content && !isIndexFileContent(content)
}
