import Types from './types'

export const setCurrentCompany = (company) => ({
  type: Types.SET_CURRENT_COMPANY,
  payload: company,
})

export const resetCurrentCompany = () => ({
  type: Types.RESET_CURRENT_COMPANY,
})

export const setCurrentCompanyLoading = (isLoading) => ({
  type: Types.SET_CURRENT_COMPANY_LOADING,
  payload: isLoading,
})

export const setSignupCompany = (company) => ({
  type: Types.SET_SIGNUP_COMPANY,
  payload: company,
})

export const setOwner = (owner) => ({
  type: Types.SET_OWNER,
  payload: owner,
})

export const setCurrentUpdates = (currentUpdates) => ({
  type: Types.SET_CURRENT_UPDATES,
  payload: currentUpdates,
})

export const setCompanyProfile = (company) => ({
  type: Types.SET_COMPANY_PROFILE,
  payload: company,
})

export const setShowCompanyNavigationHeader = (show) => ({
  type: Types.SET_SHOW_COMPANY_NAVIGATION_HEADER,
  payload: show,
})
