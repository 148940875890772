import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const NoFundsAdded = styled.p`
  color: ${color('lightGray')};
  font-size: 1.4rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.7rem;
`
