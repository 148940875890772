import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { isEnterKeyCode } from 'utils/functions/keyboardEvents'

import rightArrow from './right-arrow-key.svg'

const RightArrowButton = ({ className, onClick }) => {
  return (
    <span
      className={className}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(event) => {
        if (isEnterKeyCode(event)) onClick(event)
      }}
    >
      <img src={rightArrow} alt="" />
    </span>
  )
}

RightArrowButton.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default styled(RightArrowButton)`
  cursor: pointer;
  pointer-events: ${(props) => props.pointerEvents};

  img {
    opacity: ${(props) => (props.pointerEvents === 'none' ? 0.5 : 1)};
  }

  ${(props) =>
    props.direction === 'left' &&
    css`
      img {
        transform: rotate(180deg);
      }
    `};
`
