import { createContext, useContext, useMemo, useState } from 'react'
import { ReactFCWithChildren } from 'utils/types/common'
import { GridType } from './useAddHoldingsSpreadsheet'

interface HoldingsSpreadsheetContextProps {
  grid?: GridType
  setGrid: (grid?: GridType) => void
}

export const HoldingsSpreadsheetContext =
  createContext<HoldingsSpreadsheetContextProps>({
    setGrid: () => {},
  })

export const HoldingsSpreadsheetContextProvider: ReactFCWithChildren = ({
  children,
}) => {
  const [grid, setGrid] = useState<GridType>()

  const value = useMemo(
    () => ({
      grid,
      setGrid,
    }),
    [grid]
  )

  return (
    <HoldingsSpreadsheetContext.Provider value={value}>
      {children}
    </HoldingsSpreadsheetContext.Provider>
  )
}

export const useHoldingsSpreadsheetContext = () =>
  useContext(HoldingsSpreadsheetContext)
