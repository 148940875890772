export default {
  discard: {
    multiplesSuggested: {
      question: 'Why are you discarding these suggested updates?',
      deleteAnyContent:
        'Delete <tooltip>all related content or files</tooltip> to these suggested updates',
      description:
        "Once done, they will be deleted for the entire group, and there's no way to undo this action.",
      error:
        'Something went wrong while discarding the suggested updates, try again later.',
      success: 'Suggested updates discarded successfully.',
    },
    singleSuggested: {
      question: 'Why are you discarding this suggested update?',
      deleteAnyContent:
        'Delete <tooltip>all related content or files</tooltip> to this Suggested Update',
      description:
        "Once done, it will be deleted for the entire group, and there's no way to undo this action.",
      error:
        'Something went wrong while discarding the suggested update, try again later.',
      success: 'Suggested update discarded successfully.',
    },
  },
  discardSelected: 'Discard Selected',
  discardModal: {
    title: 'Discard Suggested Update',
    reasons: {
      notRelevant: "It's not relevant",
      notInterested: "I don't want this information in Universe",
      repetitive: "It's duplicate content",
      dontTrust: "I don't trust the source of the information",
      other: 'Other reasons',
    },
  },
  selectUpdateType: {
    placeholder: 'Select update type',
    options: {
      accounting: 'Accounting Report',
      announcement: 'Announcement',
      document: 'Document',
      note: 'Note',
      transaction: 'Transaction',
      report: 'Email',
    },
  },
  selectUpdateFromPlaceholder: 'Select from...',
  selectHoldingPlaceholder: 'Select holding',
  selectInvestorPlaceholder: 'Select investor',
  portfolioTypes: {
    track: 'Pipeline',
    invest: 'Invest Portfolio',
    fund: 'Fund Portfolio',
  },
  identifyUpdateTypeTooltip:
    'You should identify the update type before creating the update.',
  selectUpdateSourceTooltip:
    'You should select the update source before creating the update.',
  transactionTypeTooltip: {
    holding: 'Holding',
    investor: 'Investor',
  },
}
