import { PortfolioLogChangeSetFields } from '../logs-builders/PortfolioLogsBuilder'
import { EventType, FieldName, Log } from '../types'
import { BackendEvent, BackendEventsMapper } from './BackendEventsMapper'

export class PortfolioEventsMapper extends BackendEventsMapper<PortfolioLogChangeSetFields> {
  getCustomEventLog(
    event: BackendEvent<PortfolioLogChangeSetFields>,
    type: FieldName<PortfolioLogChangeSetFields>
  ): Log<PortfolioLogChangeSetFields> {
    return super.buildLog(event, type)
  }

  getLogsFromEvent(event: BackendEvent<PortfolioLogChangeSetFields>) {
    const customEvents = new Set<FieldName<PortfolioLogChangeSetFields>>([
      EventType.ADD_COMPANY,
      EventType.REMOVE_COMPANY,
      EventType.ADD_TRANSACTION,
      EventType.REMOVE_TRANSACTION,
      EventType.ADD_INVESTOR,
      EventType.REMOVE_INVESTOR,
    ])
    const eventType = event.event as FieldName<PortfolioLogChangeSetFields>

    if (customEvents.has(eventType)) {
      return [this.getCustomEventLog(event, eventType)]
    }

    return super.getLogsFromEvent(event)
  }
}
