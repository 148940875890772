import styled from 'styled-components/macro'
import NoResultsFound from 'components/NoResultsFound'
import { maxSize, size } from 'utils/constants/breakpoint'

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.6rem;
`

export const TitleContainer = styled(Row).attrs((props) => ({
  ...props,
}))<{ paddingTop?: string }>`
  padding-top: ${(props) => props.paddingTop};

  h1 {
    margin: 1.5rem 0 1rem;
  }

  @media ${maxSize.md} {
    padding: 0 2.1rem 0.5rem 2.1rem;
  }
`

export const HeaderContainer = styled(Row)`
  @media ${maxSize.md} {
    background-color: white;
    margin: -1rem -1rem 1rem -1rem;
    padding: 2rem 3.2rem;
  }
`

export const ZeroStateContainer = styled.div`
  margin: 0 6.5rem;
  .holdings-zero-state {
    grid-template-columns: 20rem auto;
    padding: 0 0 4.35rem;

    .zero-state-help-wrapper {
      display: flex;

      .delimeter {
        display: none;
      }
    }
  }
`

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem;
`

export const InitialLoaderWrapper = styled.div`
  margin-top: 6rem;
`

export const NoResults = styled(NoResultsFound)`
  position: relative;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.2rem;
`

export const SearchContainer = styled.div`
  @media ${maxSize.md} {
    display: none;
  }
`
export const SearchContainerMobile = styled.div`
  padding: 0 2rem 1.8rem 2rem;
  @media ${size.md} {
    display: none;
  }
`

export const GridSelectorContainer = styled.div`
  .grid-selector {
    padding: 0;
  }

  @media ${maxSize.md} {
    display: none;
  }
`

export const DesktopFilterContainer = styled.div`
  @media ${maxSize.md} {
    display: none;
  }
`
export const MobileFilterContainer = styled.div`
  display: none;

  @media ${maxSize.md} {
    display: unset;
  }
`
