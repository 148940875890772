import styled from 'styled-components'

import { color } from 'utils/functions/colors'

export const Container = styled.div`
  display: flex;
  padding: 2.8rem 3.2rem;
  gap: 0.8rem;
  background-color: ${color('white')};
  border: 1px solid ${color('veryLightGray')};
  box-shadow: 0px 0px 15px ${color('darkBlue', 0.1)};
  border-radius: 0.8rem;
  max-width: calc(100% - 3.2rem - 3.2rem);

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0px;
`
