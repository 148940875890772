import { store } from 'store'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { parseUpdateType } from 'utils/constants/updates'
import { DraftHashEditor, IndexUpdate, MixedUpdate } from 'utils/types/update'
import { SubjectMatterType } from 'api/UpdateService'
import ResponseNormalizer from './responseNormalizer'
import { getCurrentGroupId } from '../../selectors/auth'
import { FileContentNormalizer } from './normalizers/fileContentNormalizer'

export class SuggestedUpdatesNormalizer {
  static normalizeMixedIndexUpdate(update: any): IndexUpdate {
    const currentGroupId = getCurrentGroupId(store.getState())
    let holdingData

    //  update.fundPortfolio is defined when the update is a fund portfolio update (fetched from the fund owner) or a public fund profile update fetched from other group)
    const fundPortfolio = update.fundPortfolio
      ? {
          ...update.fundPortfolio,
          type:
            update.fundPortfolio.classType === SubjectMatterType.DEAL_PORTFOLIO
              ? PortfolioTypes.DEAL
              : PortfolioTypes.FUND,
        }
      : undefined

    if (update.companyDatum || fundPortfolio) {
      holdingData = update.companyDatum ? update.companyDatum : fundPortfolio
    }

    // update.portfolio should be defined when is a portfolio update of a portfolio owned by our group.
    const updatePortfolio =
      update.portfolio?.groupId === currentGroupId ||
      update.fundPortfolio?.group?.id === currentGroupId ||
      update.portfolio?.type === PortfolioTypes.TRACK ||
      update.portfolio?.type === PortfolioTypes.INVEST
        ? {
            ...update.portfolio,
            type:
              update.portfolio?.type ||
              fundPortfolio?.type ||
              PortfolioTypes.INVEST,
          }
        : undefined

    const isDraftUpdate = Object.keys(update.draftHash || {}).length > 0
    const isEditBlocked = !!(update.draftHash as DraftHashEditor)?.userEditing

    return {
      ...update,
      isDraftUpdate: isDraftUpdate && !isEditBlocked,
      isEditBlocked,
      updateType: parseUpdateType(update.type),
      isPortfolioUpdate: !!updatePortfolio,
      item: ResponseNormalizer.getIndexUpdateItem(update),

      holding: holdingData
        ? ResponseNormalizer.normalizeHolding(holdingData)
        : undefined,
      contents: [
        ...update.s3Contents.map(FileContentNormalizer.populateFileContent),
        ...update.emailContents.map(FileContentNormalizer.populateEmailContent),
      ],
      fundPortfolio,
      portfolio: updatePortfolio,
      draftHash: {
        ...update.draftHash,
        contents:
          update.contentsDraft?.map((content) => {
            const isEmailContent = !!content.emailContent

            return {
              ...content.s3Content,
              ...(isEmailContent && {
                ...content.emailContent,
                s3Contents: [],
              }),
            }
          }) ?? [],
      },
    }
  }

  static normalizeMixedUpdates(mixedUpdates: any): MixedUpdate[] {
    return mixedUpdates.map((mixedUpdate) => {
      if (mixedUpdate.entity.data) {
        return {
          ...mixedUpdate.entity,
          contents: mixedUpdate.entity.s3Contents ?? [],
          date: mixedUpdate.entity.createdAt,
          data: {
            ...mixedUpdate.entity.data,
            type: mixedUpdate.entity.data.type.toLowerCase(),
          },
        }
      }

      return this.normalizeMixedIndexUpdate(mixedUpdate.entity)
    })
  }
}
