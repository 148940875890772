import React from 'react'
import Tooltip from 'components/Tooltip'
import { isFileFormatImage, MixedContent } from 'utils/types/files'
import { isIndexFileContent } from 'utils/functions/files'
import { useMixedContent } from 'utils/hooks/contents/useMixedContent'

import * as Styles from './ContentItem.styles'

interface ContentProps {
  content: MixedContent
  isHighlighted: boolean
  onClick: (content: MixedContent) => void
}

const ContentItem: React.FC<ContentProps> = ({
  content,
  isHighlighted,
  onClick,
}) => {
  const { contentName, fileFormat, isEmailContent, url } =
    useMixedContent(content)
  const isFileContent = isIndexFileContent(content)
  const isImage = isFileContent && isFileFormatImage(content.fileFormat)

  return (
    <Styles.Content
      isHighlighted={isHighlighted}
      isImage={isImage}
      onClick={() => onClick(content)}
    >
      {isImage ? (
        <Styles.Image src={content.url} alt="file-thumbnail" />
      ) : (
        <Styles.FileIcon
          type={fileFormat}
          xSmall
          useBackgroundImage
          src={url}
          isEmailContent={isEmailContent}
        />
      )}
      <Tooltip
        id={`update-file-${contentName}`}
        text={contentName}
        place="bottom"
        delayShow={1000}
        leftOffset={80}
      >
        <Styles.FileName isEmailContent={isEmailContent}>
          {contentName}
        </Styles.FileName>
      </Tooltip>
    </Styles.Content>
  )
}

export default ContentItem
