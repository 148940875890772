import Celeste from 'components/Celeste'
import { useCelesteMetrics } from './useCelesteMetrics'

const CelesteMetrics = () => {
  const { celesteGroupItems } = useCelesteMetrics()

  return (
    <Celeste
      groupsItems={celesteGroupItems}
      readOnlyIntlId="readOnlyMode.addMetrics"
    />
  )
}

export default CelesteMetrics
