import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import { GeneratedInboundEmail as GeneratedInboundEmailToStyle } from 'containers/Inbounds'
import { maxSize } from 'utils/constants/breakpoint'

export const StepContainer = styled.div`
  display: flex;
  gap: 2.4rem;
  padding: 0 0 1.8rem 0.8rem;
  color: ${color('darkGray')};

  & + & {
    border-top: 0.1rem solid ${color('veryLightGray')};
  }

  &:not(:first-child) {
    padding-top: 1.8rem;
  }

  @media ${maxSize.md} {
    padding-right: 0.8rem;
  }
`

export const StepNumber = styled.div`
  min-width: 3.8rem;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 4rem;
  border: 0.1rem solid ${color('veryLightGray')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
`

export const InputWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.2rem;
  min-width: 0;
`

export const Description = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
`

export const Label = styled(Description)`
  font-weight: 700;
`

export const GeneratedInboundEmail = styled(GeneratedInboundEmailToStyle)`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  flex-wrap: wrap;

  div:first-child {
    max-width: 30rem;
    margin-bottom: 0;
  }

  div:last-child {
    padding: 0;
    max-width: 39.5rem;
  }
`

export const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.6rem;
  gap: 1.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('green')};
  background: ${color('green', 0.1)};
  width: 100%;
`

export const IconContainer = styled.div`
  height: 100%;
  border-radius: 4rem;
  background-color: ${color('green')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  color: white;
`
