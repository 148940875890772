import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const Legend = styled.p<{ statusMargin?: string }>`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  white-space: pre;
  margin: ${(props) => props.statusMargin || '0'};
`

export const TooltipWrapper = styled.div`
  align-items: center;
  display: flex;
  height: fit-content;

  @media ${maxSize.md} {
    justify-content: center;
  }

  .__react_component_tooltip {
    font-style: normal !important;
    text-align: left;
    width: 24.6rem !important;
  }
`

const Badge = styled.span`
  border-radius: 10rem;
  color: ${color('white')};
  display: inline-block;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  padding: 0.3rem 0.8rem;
  text-align: center;
  text-transform: uppercase;
  width: 12rem;
`

export const PendingValidationBadge = styled(Badge)`
  background: ${color('lightGray')};
  margin-top: 1rem;
`

export const PrivateCompanyBadge = styled(Badge)`
  background: ${color('darkBlue')};
  margin-top: 1rem;
`
