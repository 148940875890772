import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Toast, ToastLabel } from './ToastMessage.styles'

export const ToastType = {
  ERROR: 'ERROR',
  ALERT: 'ALERT',
}

export const IconPosition = {
  TOP: 'flex-start',
  CENTER: 'center',
}

const ToastMessage = ({ children, icon, type, width, iconPosition }) => {
  return (
    <Toast
      type={type}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      width={width}
    >
      <ToastLabel type={type} iconPosition={iconPosition}>
        <FontAwesomeIcon icon={icon || ['fal', 'info-circle']} />
        <p>{children}</p>
      </ToastLabel>
    </Toast>
  )
}

ToastMessage.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  type: PropTypes.oneOf(Object.values(ToastType)),
  icon: PropTypes.array,
  iconPosition: PropTypes.oneOf(Object.values(IconPosition)),
}

ToastMessage.defaultProps = {
  type: ToastType.ALERT,
  iconPosition: IconPosition.CENTER,
  icon: null,
}

export default ToastMessage
