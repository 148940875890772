import styled from 'styled-components/macro'

import { color } from 'utils/functions/colors'
import Drawer from 'components/Drawer'
import Button from 'ui/Button'

export const DrawerContent = styled(Drawer.Content)`
  padding: initial;
`

export const DrawerFooter = styled(Drawer.Footer)`
  justify-content: space-between;
`

export const Content = styled.div`
  padding: 3.2rem 2.4rem;
  display: flex;
  gap: 2.4rem;
  border-bottom: 1px solid ${color('borderGray')};
  flex-direction: column;
`

export const ConnectedGoogleGroup = styled.div`
  font-size: 1.4rem;
  line-height: 1.68rem;
  font-weight: 400;
  color: ${color('darkGray')};

  div {
    font-weight: 700;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.8rem;
`

export const StatusContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-direction: column;
  padding: 2.9rem 2.8rem;
`

export const StatusLabel = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 700;
  color: ${color('darkGray')};

  span {
    font-weight: 400;
    display: block;
  }
`

export const CheckButton = styled(Button)`
  margin-top: 0.8rem;
  width: fit-content;

  svg {
    rotate: 90deg;
  }
`
