import { Emoji } from 'containers/Chat/types'

export const reactions: Array<Emoji> = [
  {
    id: 'white_check_mark',
    native: '✅',
    name: 'Check Mark Button',
  },
  {
    id: 'eyes',
    native: '👀',
    name: 'Eyes',
  },
  {
    id: 'ok_hand',
    native: '👌',
    name: 'Ok Hand',
  },
  {
    id: 'heart',
    native: '❤️',
    name: 'Red Heart',
  },
  {
    id: 'fire',
    native: '🔥',
    name: 'Fire',
  },
  {
    id: 'pensive',
    native: '😔',
    name: 'Pensive Face',
  },
]
