import styled from 'styled-components'

export const TooltipContainer = styled.div`
  height: 5em;
  background-color: white;
  font-size: 1.2rem;
  border-radius: 0.8rem;
  padding: 1.1rem 1.3rem 1.1rem 1.5rem;
  min-width: 20rem;
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.15);
`

export const Title = styled.p`
  color: #757575;
  margin-bottom: 0.2rem;
`

export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`

export const Percentage = styled.span`
  font-size: 1.6rem;
  font-weight: 700;
  margin-right: 1rem;
  color: ${(props) => props.color};
`

export const Company = styled.div`
  display: flex;
  align-items: center;
`

export const Name = styled.p`
  color: #afb1b7;
  font-size: 1.3rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
