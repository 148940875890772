import { useInvestmentVehicleQuery } from 'utils/hooks/investorManagement/useInvestmentVehiclesQuery'
import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import useParseCurrentUrl from '../useParseCurrentUrl'

interface VehicleInformationProps {
  fetchInvestmentVehicle?: boolean
}

const useVehicleInformation = ({
  fetchInvestmentVehicle,
}: VehicleInformationProps) => {
  const { investmentVehicleId } = useParseCurrentUrl()

  const { data: investmentVehicle } = useInvestmentVehicleQuery(
    investmentVehicleId,
    {
      enabled: !!(investmentVehicleId && fetchInvestmentVehicle),
    }
  )

  const getVehicleAvatar = () => {
    if (!investmentVehicle?.logo) {
      return (
        <Avatar
          initials={getInitials(investmentVehicle?.name)}
          avatarStyle="avatarCircleXS"
        />
      )
    }

    return (
      <Avatar image={investmentVehicle.logo.url} avatarStyle="avatarCircleXS" />
    )
  }

  return {
    investmentVehicle,
    getVehicleAvatar,
  }
}

export default useVehicleInformation
