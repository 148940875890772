import InputLabel from 'ui/InputLabel'
import { StyledTextArea, Value } from './CustomTextArea.styles'

interface Props {
  name: string
  id: string
  value: string | undefined
  placeholder: string
  label?: string
  isOptional?: boolean
  optionalLabel?: string
  readOnlyMode?: boolean
  readOnlyValue?: string
  disabled?: boolean
  minHeight?: string
  onChange: (e: React.ChangeEvent<any>) => void
}

const CustomTextArea = ({
  name,
  id,
  value,
  placeholder,
  label,
  isOptional,
  optionalLabel,
  disabled,
  readOnlyMode,
  readOnlyValue,
  minHeight,
  onChange,
}: Props) => {
  return (
    <>
      {label && (
        <InputLabel isOptional={isOptional} optionalLabel={optionalLabel}>
          {label}
        </InputLabel>
      )}

      {!readOnlyMode ? (
        <StyledTextArea
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnlyMode={readOnlyMode}
          minHeight={minHeight}
        />
      ) : (
        <Value>{readOnlyValue}</Value>
      )}
    </>
  )
}

export default CustomTextArea
