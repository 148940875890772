const MAILTO_SUPPORT = 'mailto:support@clockwork.app'
const SUPPORT = 'support@clockwork.app'
const MAILTO_UPGRADE = 'mailto:upgrade@clockwork.com'
const UPGRADE = 'upgrade@clockwork.app'
const LEGAL = 'legal@clockwork.app'
const USER_EMAIL_EXAMPLE = 'example@clockwork.app'
const EXAMPLE_EMAIL = 'example@clockwork.app'
const INFO = 'info@clockwork.app'

interface Emails {
  MAILTO_SUPPORT: typeof MAILTO_SUPPORT
  MAILTO_UPGRADE: typeof MAILTO_UPGRADE
  SUPPORT: typeof SUPPORT
  UPGRADE: typeof UPGRADE
  LEGAL: typeof LEGAL
  USER_EMAIL_EXAMPLE: typeof USER_EMAIL_EXAMPLE
  EXAMPLE_EMAIL: typeof EXAMPLE_EMAIL
  INFO: typeof INFO
}

export const emails: Emails = {
  MAILTO_SUPPORT: 'mailto:support@clockwork.app',
  MAILTO_UPGRADE: 'mailto:upgrade@clockwork.com',
  SUPPORT: 'support@clockwork.app',
  UPGRADE: 'upgrade@clockwork.app',
  LEGAL: 'legal@clockwork.app',
  USER_EMAIL_EXAMPLE: 'example@clockwork.app',
  EXAMPLE_EMAIL: 'example@clockwork.app',
  INFO: 'info@clockwork.app',
}
