import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const GoogleButton = styled.button`
  align-items: center;
  border-radius: 0.8rem;
  border: 0.1rem solid ${color('veryLightBlue')};
  color: ${color('darkBlue')};
  cursor: pointer;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  letter-spacing: 0.04em;
  min-width: 100%;
  padding: 1rem 1.6rem;
  background-color: white;
  transition: background-color 0.2s ease-in-out;
  height: 5rem;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  img {
    width: 2.8rem;
  }

  span {
    font-size: 1.6rem;
    margin-left: 1.6rem;
  }

  &:hover {
    background-color: #f2f5f6;
  }
`
