import Modal from 'components/Modal'
import Toggle from 'components/Toggle'
import styled from 'styled-components'
import Button from 'ui/Button'
import Input from 'ui/Input'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'

export const ModalBody = styled(Modal.Body)`
  padding: 0 1.2rem;
  max-width: 70rem;
`

export const BrowseButton = styled(Button)`
  width: auto;
`

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.8rem;
`

export const FilterContainer = styled.div`
  border-bottom: 0.1rem solid ${color('borderGray')};
  display: flex;
  gap: 1.6rem;
  padding: 1.6rem 2.4rem;

  @media ${maxSize.sm} {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2.4rem;
  }
`

export const SearchFilesInput = styled(Input)`
  width: 100%;
`

export const UnreconciledOrderWrapper = styled.div`
  display: flex;
  gap: 1.6rem;

  @media ${maxSize.sm} {
    justify-content: space-between;
  }
`

export const FileFilterToggle = styled(Toggle).attrs({
  inactiveColor: theme.colors.lightGray,
})`
  width: fit-content;

  p {
    width: max-content;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: ${color('darkGray')};
  }
`

export const SortDirectionLabel = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  margin-left: 0.5rem;
`

export const FormButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.8rem;
`

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
`

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  height: 55vh;
`

export const ContentWrapper = styled.div`
  height: 55vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
