import React, { useContext, useRef } from 'react'
import { SortDirection } from 'utils/constants/sortDirection'
import useEffectSkipFirstRender from 'utils/hooks/useEffectSkipFirstRender'
import { useExpandableRowState } from './useExpandableRowState'

export const ExpandableRowContext = React.createContext()

export const useExpandableRow = (rowIndex, tableData) => {
  const rowRef = useRef(null)
  const expandableRowProps = useContext(ExpandableRowContext)
  const rowData = tableData[rowIndex]

  const {
    sortBy,
    sortDirection,
    page,
    expandableData,
    isLoadingExpandableRow,
    isExpanded,
    dispatch,
  } = useExpandableRowState(rowIndex, {
    sortBy: expandableRowProps.columns[0].id,
    sortDirection: SortDirection.ASC,
    page: 1,
    expandableData: [],
    isLoadingExpandableRow: false,
    isExpanded: false,
  })

  const customRow = expandableRowProps.getCustomRow(rowIndex)
  const showOriginalRow = !customRow || !customRow?.hideOriginalRow

  useEffectSkipFirstRender(() => {
    const height = rowRef.current?.getBoundingClientRect?.().height
    expandableRowProps.setSize(rowIndex, height)
  }, [
    isExpanded,
    isLoadingExpandableRow,
    expandableData,
    customRow?.showCustomRow,
    page,
  ])

  let expandableDataToShow = []
  if (expandableRowProps.asyncExpandedPagination) {
    expandableDataToShow = expandableData ?? []
  } else if (expandableRowProps.paginateExpandedRows) {
    expandableDataToShow =
      expandableData?.slice(0, page * expandableRowProps.pageSize) ?? []
  } else {
    expandableDataToShow = expandableData ?? []
  }

  const onChangeSortBy = async (sortId) => {
    let newSortDirection
    if (sortId === sortBy) {
      newSortDirection =
        sortDirection === SortDirection.ASC
          ? SortDirection.DESC
          : SortDirection.ASC
    } else {
      newSortDirection = SortDirection.ASC
    }

    dispatch({
      type: 'setSortBy',
      payload: sortId,
    })
    dispatch({
      type: 'setSortDirection',
      payload: newSortDirection,
    })

    const sortedExpandableData =
      await expandableRowProps.onChangeExpandedRowSortBy({
        rowIndex,
        sortId,
        sortDirection: newSortDirection,
        rowData: tableData[rowIndex],
      })

    dispatch({
      type: 'setExpandableData',
      payload: sortedExpandableData,
    })
  }

  const fetchNextPage = async (nextPage) => {
    const currentExpandableData = await expandableRowProps.getExpandableData(
      rowData,
      nextPage
    )
    dispatch({
      type: 'pushExpandableData',
      payload: currentExpandableData,
    })
  }

  const onLoadMoreRows = () => {
    dispatch({
      type: 'setPage',
      payload: page + 1,
    })

    if (expandableRowProps.asyncExpandedPagination) {
      fetchNextPage(page + 1)
    }
  }

  const fetchData = async () => {
    const currentExpandableData = await expandableRowProps.getExpandableData(
      rowData,
      1
    )
    dispatch({
      type: 'setExpandableData',
      payload: currentExpandableData,
    })
  }

  const toggleRow = () => {
    if (expandableRowProps.isRowExpandable(rowData)) {
      if (isExpanded) {
        expandableRowProps.onCollapseRow({ rowIndex, rowData })
      } else {
        expandableRowProps.onExpandRow({ rowIndex, rowData })
      }

      dispatch({
        type: 'setIsExpanded',
        payload: !isExpanded,
      })
      dispatch({
        type: 'setPage',
        payload: 1,
      })
    }
  }

  const handleToggleRow = async (...params) => {
    if (expandableRowProps.showLoadingIndicatorOnExpandableRows) {
      dispatch({
        type: 'setIsLoadingExpandableRow',
        payload: true,
      })
    }

    if (expandableRowProps.expandOnRowClick) {
      toggleRow({ rowData, rowIndex })
    } else {
      expandableRowProps.onClickRow?.(...params)
    }

    if (!isExpanded) {
      await fetchData()
    }
    if (expandableRowProps.showLoadingIndicatorOnExpandableRows) {
      dispatch({
        type: 'setIsLoadingExpandableRow',
        payload: false,
      })
    }
  }

  return {
    props: expandableRowProps,
    state: {
      sortBy,
      sortDirection,
      expandableData,
      isLoadingExpandableRow,
      isExpanded,
      customRow,
      showOriginalRow,
      expandableDataToShow,
      rowData,
    },
    callbacks: {
      onChangeSortBy,
      onLoadMoreRows,
      handleToggleRow,
    },
    rowRef,
  }
}
