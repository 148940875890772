import { PortfolioTypes } from 'utils/constants/portfolio'
import { SuggestedUpdateType } from './common'
import { TransactionFundTypes, UpdateAttachment } from './transactions'
import { Investor } from './investors'
import { TopicEntity } from './chatTopicEntity'

export enum SuggestedUpdateFields {
  type = 'type',
  updateFrom = 'updateFrom',
  transactionFundType = 'transactionFundType',
  investor = 'investor',
}

export interface SuggestedUpdateForm {
  [SuggestedUpdateFields.type]: SuggestedUpdateType
  [SuggestedUpdateFields.updateFrom]: TopicEntity | undefined
  [SuggestedUpdateFields.transactionFundType]: TransactionFundTypes | undefined
  [SuggestedUpdateFields.investor]: Investor | undefined
  suggestedUpdateId: string
  text: string
  title: string
  contents: UpdateAttachment[]
}

export interface CreateUpdateFromRules {
  isOwnOrganizationVisible?: boolean
  isTransactionTypeVisible?: boolean
  selectablePortfolios?: PortfolioTypes[]
  areHoldingsVisible?: boolean
}

export enum DiscardedReasonCodes {
  NotRelevant = 'NotRelevant',
  NotInterested = 'NotInterested',
  Repeated = 'Repeated',
  UntrustedSource = 'UntrustedSource',
  Other = 'Other',
}

export enum DiscardSuggestedUpdateFields {
  SuggestedUpdateIds = 'suggestedUpdateIds',
  Reason = 'reason',
  DiscardContents = 'discardContents',
}

export interface DiscardSuggestedUpdateForm {
  [DiscardSuggestedUpdateFields.SuggestedUpdateIds]: string[]
  [DiscardSuggestedUpdateFields.Reason]?: DiscardedReasonCodes
  [DiscardSuggestedUpdateFields.DiscardContents]: boolean
}

export interface DiscardSuggestedUpdatePayload {
  id: string
  emailContent: {
    discardReasonCode: DiscardedReasonCodes
  }
  deleteEmailContent: boolean
}
