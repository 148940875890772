import styled from 'styled-components/macro'
import { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const Container = styled.label<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `}
`

export const HiddenCheckboxInput = styled.input`
  display: none;
`

export const Checkbox = styled.span<{ checked?: boolean; isMobile?: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.6rem;
  min-width: 1.6rem;
  height: 1.6rem;
  min-height: 1.6rem;
  margin-right: 0.8rem;
  border-radius: 0.3rem;
  border: 0.1rem solid #bbbec5;
  box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.1);

  svg {
    width: 1rem;
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${color('primaryBlue')};
      border-color: ${color('veryLightGray')};
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      min-height: 2.4rem;
      margin-right: 1.2rem;
    `}
`
