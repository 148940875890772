import React from 'react'

import Avatar from 'components/Avatar'
import { getInitials } from 'utils/functions/user'
import { AvatarInfo } from '../StackedAvatarList'
import { AvatarRow } from '../StackedAvatarList.styles'

interface StackedAvatarListTooltipContentProps {
  avatars: AvatarInfo[]
}

const StackedAvatarListTooltipContent: React.FC<
  StackedAvatarListTooltipContentProps
> = ({ avatars }) => {
  return (
    <div>
      {avatars.map((avatar) => (
        <AvatarRow>
          <Avatar
            key={avatar.id}
            image={avatar.image}
            initials={getInitials(avatar.name)}
            avatarStyle="avatarCircleXS"
          />
          <div>{avatar.name}</div>
        </AvatarRow>
      ))}
    </div>
  )
}

export default StackedAvatarListTooltipContent
