import React, { useCallback } from 'react'

import Briefing from '../../Briefing/Briefing'
import {
  GroupBriefingContainer,
  SideBarGroupSelectorContainer,
} from '../GroupSelector.styles'

import { useGroupSelector } from '../useGroupSelector'

interface SidebarGroupSelectorProps {
  onShowSideBarGroupSelector: (groupId: string) => void
}
const SideBarGroupSelector: React.FC<SidebarGroupSelectorProps> = ({
  onShowSideBarGroupSelector,
}) => {
  const { currentGroup } = useGroupSelector()

  const onClickOpenGroupSelector = useCallback(() => {
    onShowSideBarGroupSelector(currentGroup!.id)
  }, [currentGroup, onShowSideBarGroupSelector])

  return (
    <SideBarGroupSelectorContainer>
      <GroupBriefingContainer>
        <Briefing
          type="group"
          name={currentGroup!.name}
          logoUrl={currentGroup!.logo?.mediumLogo.url}
          handle={currentGroup!.handle}
          isMainBriefing
          onClick={onClickOpenGroupSelector}
          isButtonEnabled
          clickable
        />
      </GroupBriefingContainer>
    </SideBarGroupSelectorContainer>
  )
}

export default React.memo(SideBarGroupSelector)
