import { useCallback } from 'react'

interface UseUpdateActionsProps {
  handleSubmit: () => void
  setIsPublishing: (value: boolean) => void
}

const useUpdateActions = ({
  handleSubmit,
  setIsPublishing,
}: UseUpdateActionsProps) => {
  const onHandleSubmit = useCallback(() => {
    setIsPublishing(true)
    setTimeout(handleSubmit, 0)
  }, [handleSubmit, setIsPublishing])

  return {
    onHandleSubmit,
  }
}

export default useUpdateActions
