import styled from 'styled-components'
import { maxSize, size } from 'utils/constants/breakpoint'
import { MAC_DESKTOP_HEADER_HEIGHT } from 'utils/constants/electron'
import { color } from 'utils/functions/colors'
import {
  SideBarTitle,
  SIDE_BAR_HEIGHT,
  SIDE_BAR_WIDTH,
} from '../SideBar/SideBar.styles'

export const SecondarySidebarContainer = styled.aside<{ open: boolean }>`
  height: 100%;
  overflow: hidden;
  background-color: ${color('gray')};
  transition: width 0.3s;
  width: ${(props) => (props.open ? '23rem' : 0)};
  z-index: 1;
  box-shadow: inset 0 0.3rem 0.6rem 0 rgb(16 21 39 / 60%);
  overflow-y: auto;
  border-right: ${(props) =>
    props.open ? `0.1rem solid ${color('gray')}` : 'none'};

  @media ${maxSize.mobile} {
    background-color: ${color('darkBlue')};
    position: absolute;
    transition: transform 0.3s;
    transform: ${(props) =>
      props.open ? 'translateX(0)' : 'translateX(-100%)'};
    width: ${SIDE_BAR_WIDTH};
    z-index: 4;
  }

  @media ${maxSize.md} {
    height: calc(
      var(--viewHeight, 100vh) - ${SIDE_BAR_HEIGHT} -
        ${MAC_DESKTOP_HEADER_HEIGHT}
    );
  }

  @media ${maxSize.xsm} {
    width: 100%;
  }
`

export const SecondarySidebarTitle = styled.div`
  font-size: 2rem;
  color: ${color('white')};

  @media ${size.lg} {
    display: none;
  }
`

export const BackIcon = styled.button`
  height: 4.9rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  justify-content: space-between;

  color: ${color('lightGray')};
  font-size: 1.2rem;
  svg {
    margin-right: 0.9rem;
  }

  &:focus {
    outline: none;
  }

  @media ${size.lg} {
    display: none;
  }
`
export const SecondarySideBarContent = styled.div<{ open: boolean }>`
  transition: opacity 0.3s;
  transition-delay: 0.3s;
  opacity: ${(props) => (props.open ? 1 : 0)};
`

export const SideBarTitleContainer = styled.div`
  height: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 0;

  @media ${maxSize.mobile} {
    padding: 1.8rem 2.4rem 1.8rem 2.3rem;
    ${SideBarTitle} {
      display: none;
    }
  }
`
