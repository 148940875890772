import Card from 'components/Card'
import CWLoader from 'components/CWLoader'
import { StyledInvestorDetails } from 'containers/InvestorManagement/InvestorDetails/InvestorDetails.styles'
import useInvestorDetails from 'containers/InvestorManagement/InvestorDetails/useInvestorDetails'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import { isActingAsFundManager } from 'selectors/auth'
import CompanyDescription from './CompanyDescription/CompanyDescription'
import {
  HeaderInfoWrapper,
  SideCardWrapper,
  SideInformationWrapper,
  EditButton,
} from './CompanyInformation.styles'
import CompanyKeyPeople from './CompanyKeyMembers/CompanyKeyMembers'
import FinancialInfo from './FinancialInformation/FinancialInfo'
import InvestmentVehicles from './InvestmentVehicles/InvestmentVehicles'
import HeaderAdditionalInformation from './InvestorHeader/HeaderAdditionalInformation'
import HeaderBasicInformation from './InvestorHeader/HeaderBasicInformation'

interface Props {
  investorId: string
}

const InvestorInformation = ({ investorId }: Props) => {
  const history = useHistory()
  const intl = useIntl()
  const { investor, isLoading } = useInvestorDetails(investorId)
  const isFundManager = useAppSelector(isActingAsFundManager)

  const onEditProfileClickHandler = () => {
    if (isFundManager) {
      history.push(
        `/investor-management/investors/${investor?.id}/profile/edit`,
        {
          investor,
        }
      )
      return
    }

    history.push('/profile/edit')
  }

  if (isLoading || !investor) {
    return (
      <StyledInvestorDetails>
        <CWLoader />
      </StyledInvestorDetails>
    )
  }

  return (
    <SideInformationWrapper>
      <HeaderInfoWrapper>
        <HeaderBasicInformation investor={investor} />
        <HeaderAdditionalInformation investor={investor} />
        <EditButton secondary onClick={onEditProfileClickHandler}>
          <FormattedMessage id="investorManagement.profiles.edit" />
        </EditButton>
      </HeaderInfoWrapper>

      {investor.description && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'companyProfile.description' })}
            </Card.Header>
            <Card.Body>
              <CompanyDescription content={investor.description} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}

      {!!investor.investmentVehicles.length && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage(
                {
                  id: 'investorManagement.list.extended.investmentVehicles',
                },
                { count: investor.investmentVehicles.length }
              )}
            </Card.Header>
            <Card.Body>
              <InvestmentVehicles
                investorId={investor.id}
                investmentVehicles={investor.investmentVehicles}
              />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}

      {investor.financialInformation && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({
                id: 'investorManagement.list.extended.financialInformation',
              })}
            </Card.Header>
            <Card.Body>
              <FinancialInfo
                financialInformation={investor.financialInformation}
              />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}

      {!!investor.employees.length && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({ id: 'investorManagement.keyPeople' })}
            </Card.Header>
            <Card.Body>
              <CompanyKeyPeople content={investor.employees} />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}
    </SideInformationWrapper>
  )
}

export default InvestorInformation
