import { Nullable } from 'utils/types/common'
import { SubscriptionData } from 'utils/types/user'
import { getCurrentGroupData, getIsReadOnlyMode } from './auth'

export const getSubscriptionData = (state): Nullable<SubscriptionData> => {
  const currentGroupData = getCurrentGroupData(state)

  if (!currentGroupData) return null

  return {
    stripeSubscriptionId: currentGroupData.stripeSubscriptionId,
    stripeCustomerId: currentGroupData.stripeCustomerId,
    stripePlan: currentGroupData.stripePlan,
    stripeStatus: currentGroupData.stripeStatus,
    stripeNextBillingDate: currentGroupData.stripeNextBillingDate,
  }
}

export const getHasActiveSubscription = (state): boolean => {
  const isReadOnly = getIsReadOnlyMode(state)
  const subscriptionData = getSubscriptionData(state)
  return !!subscriptionData?.stripeSubscriptionId && !isReadOnly
}
