import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Input from 'ui/Input'
import { isEnterKeyCode, isEscCode } from 'utils/functions/keyboardEvents'

import { IconButton, InputIconContainer } from './InputIcon.styles'

const InputIcon = ({
  handleChange,
  onEnter,
  onEscape,
  value,
  placeholder,
  icon,
  tabIndex,
  secondary,
  onClickIcon,
  removeIconWhenTyping,
  forceShowIcon,
  className,
  simulateIconSpace,
  error,
  ...rest
}) => {
  const inputRef = useRef(null)

  const handleKeyDown = (event) => {
    if (isEnterKeyCode(event)) {
      onEnter(event)
    }
    if (isEscCode(event)) {
      inputRef.current.blur()
      onEscape(event)
    }
  }

  return (
    <InputIconContainer className={className}>
      <Input
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        ref={inputRef}
        value={value}
        tabIndex={tabIndex}
        secondary={secondary}
        simulateIconSpace={simulateIconSpace}
        error={error}
        {...rest}
      />
      {(forceShowIcon || (removeIconWhenTyping && !value)) && (
        <IconButton
          id={`icon-${rest.id}`}
          onClick={onClickIcon}
          type="button"
          tabIndex="-1"
        >
          <FontAwesomeIcon icon={icon} />
        </IconButton>
      )}
    </InputIconContainer>
  )
}

InputIcon.propTypes = {
  ...Input.propTypes,
  handleChange: PropTypes.func,
  onEnter: PropTypes.func,
  onEscape: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.array,
  tabIndex: PropTypes.number,
  secondary: PropTypes.bool,
  onClickIcon: PropTypes.func,
  removeIconWhenTyping: PropTypes.bool,
  forceShowIcon: PropTypes.bool,
  simulateIconSpace: PropTypes.bool,
}

InputIcon.defaultProps = {
  handleChange: () => {},
  onEnter: () => {},
  onEscape: () => {},
  placeholder: '',
  value: '',
  className: '',
  icon: ['far', 'search'],
  tabIndex: null,
  secondary: false,
  onClickIcon: () => {},
  removeIconWhenTyping: false,
  forceShowIcon: false,
  simulateIconSpace: false,
}

export default InputIcon
