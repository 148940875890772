/* eslint-disable  jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { CHECKBOX_INDETERMINATE } from 'utils/functions/lists'

import styles from './Checkbox.module.scss'

const Checkbox = React.forwardRef(
  (
    {
      id,
      label,
      subLabel,
      name,
      mode,
      onChange,
      children,
      checked,
      disabled,
      icon,
      className,
      type,
      value,
      register,
      onKeyDown,
      color,
    },
    ref
  ) => {
    return mode === 'default' ? (
      <label
        tabIndex="0"
        htmlFor={id}
        className={classNames(styles.checkboxContainer, className)}
        onKeyDown={onKeyDown}
      >
        <span
          className={classNames('checkbox-legend', {
            [styles.labelDisabled]: disabled,
          })}
        >
          {label}
          {!!subLabel && (
            <span
              className={classNames(styles.subLabelStyles, {
                [styles.labelDisabled]: disabled,
              })}
            >
              {subLabel}
            </span>
          )}
        </span>
        <input
          id={id}
          data-testid={`checkbox-${id}`}
          name={name}
          type={type}
          className={styles.checkboxInput}
          value={value}
          onChange={onChange}
          ref={ref || register}
          disabled={disabled}
          checked={checked}
        />
        <span
          className={
            checked === CHECKBOX_INDETERMINATE
              ? styles.checkmarkIndeterminate
              : styles.checkmarkDefault
          }
          style={{
            backgroundColor: checked ? color : '',
            borderColor: checked ? color : '',
          }}
        >
          {icon}
        </span>
      </label>
    ) : (
      <label
        htmlFor={id}
        className={classNames(styles.checkboxContainerCustom, className, {
          [styles.customDisabled]: disabled,
        })}
      >
        <input
          id={id}
          data-testid={`checkbox-${id}`}
          name={name}
          type={type}
          className={styles.checkboxInput}
          value={value}
          onChange={onChange}
          ref={ref || register}
          checked={checked}
        />
        <span className={styles.checkmarkCustom}>
          <div className={styles.customCheckCenter} />
        </span>
        <div className={styles.checkboxLegendWrapper}>{children}</div>
      </label>
    )
  }
)

Checkbox.displayName = 'Checkbox'

Checkbox.defaultProps = {
  checked: undefined,
  children: null,
  className: '',
  disabled: false,
  icon: null,
  label: '',
  subLabel: '',
  mode: 'default',
  onChange: () => {},
  type: 'checkbox',
  value: '',
  register: () => {},
  onKeyDown: () => {},
  color: '',
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mode: PropTypes.oneOf(['default', 'custom']),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  value: PropTypes.string,
  register: PropTypes.func,
  onKeyDown: PropTypes.func,
  color: PropTypes.string,
}

export default Checkbox
