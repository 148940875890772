import { UpdateTypes } from 'utils/constants/updates'
import { UpdateType } from 'utils/types/common'
import {
  CreateDraftUpdatePayload,
  UpdateTypeKey,
} from 'utils/types/updatePayloads'
import { assertUnreachable } from 'utils/functions/utils'

import { getEditDraftAnnouncementPayload } from './announcements'
import { getEditDraftDocumentPayload } from './documents'
import { getEditDraftNotePayload } from './notes'
import { EditDraftUpdatePayloadParams } from './types'
import {
  EditTransactionPayloadParams,
  getEditDraftTransactionPayload,
} from './transactions'

export const getCreateSuggestedUpdatePayload = (
  updateType: UpdateType,
  params: EditDraftUpdatePayloadParams | EditTransactionPayloadParams,
  suggestedUpdateId?: string
): CreateDraftUpdatePayload<UpdateTypeKey> => {
  switch (updateType) {
    case UpdateTypes.NOTE:
      return {
        note: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftNotePayload(params, suggestedUpdateId),
            suggestedUpdateId,
          },
        },
      }
    case UpdateTypes.ANNOUNCEMENT:
      return {
        announcement: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftAnnouncementPayload(
              params,
              suggestedUpdateId
            ),
            suggestedUpdateId,
          },
        },
      }
    case UpdateTypes.DOCUMENT:
      return {
        document: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftDocumentPayload(params),
            suggestedUpdateId,
          },
        },
      }
    case UpdateTypes.TRANSACTION:
      return {
        transaction: {
          loggingUpdateAttributes: {
            draftHash: getEditDraftTransactionPayload(
              params as EditTransactionPayloadParams,
              suggestedUpdateId
            ),
            suggestedUpdateId,
          },
        },
      }
    case UpdateTypes.ACCOUNTING:
    case UpdateTypes.IUE:
      throw new Error('Not implemented')
    default:
      return assertUnreachable(updateType)
  }
}
