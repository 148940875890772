import styled, { css } from 'styled-components'
import { maxSize } from 'utils/constants/breakpoint'

import { color } from 'utils/functions/colors'
import { FileViewEnum } from 'components/FileViewSelector'

const HorizontalFlex = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const Container = styled.div<{ isLoading: boolean }>`
  padding: 3.2rem;
  display: flex;
  gap: 3.2rem;
  flex-direction: column;
  ${({ isLoading }) =>
    isLoading &&
    css`
      height: inherit;
    `}

  @media ${maxSize.md} {
    gap: 2.4rem;
    padding: 1.6rem 2.4rem;
  }
`

export const HeaderContainer = styled.div`
  ${HorizontalFlex}
  flex-wrap: wrap;
  gap: 1.6rem;
`

export const Header = styled.h1`
  font-size: 3.2rem;
  font-weight: 400;
  line-height: 3.8rem;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: ${color('darkBlue')};
`

export const Filters = styled.div`
  ${HorizontalFlex}

  @media ${maxSize.xl} {
    flex-direction: column;
    gap: 1.6rem;
  }
`

export const FilterActionsContainer = styled.div`
  ${HorizontalFlex}
  gap: 1.5rem;
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
`

export const FilterToggleContainer = styled(FilterActionsContainer)`
  @media ${maxSize.xl} {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`

export const Divider = styled.div`
  border-right: 1px solid ${color('lightGray', 0.2)};
  width: 1px;
  height: 3.6rem;
  margin: 0 0.4rem 0 0.5rem;
`

export const SortDirectionLabel = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  margin-left: 0.5rem;
`

export const Content = styled.div<{ gridSize: FileViewEnum }>`
  display: grid;
  gap: 2.4rem 1.7rem;
  grid-template-columns: repeat(
    auto-fill,
    ${({ gridSize }) => (gridSize === FileViewEnum.SMALL ? '13.2rem' : '21rem')}
  );
  @media ${maxSize.lg} {
    justify-content: center;
  }
`

export const InitialLoadingContainer = styled.div`
  height: 100vh;
  position: absolute;
`
export const LoaderContainer = styled.div`
  width: 100%;
  height: 5rem;

  > div {
    min-height: 5rem;
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.8rem;
`

export const IconWrapper = styled.button<{
  isActive: boolean
  activeColor: any
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid ${color('lightGray', 0.2)};
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${({ isActive, activeColor }) =>
    isActive ? activeColor : 'transparent'};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    border: 0.1rem solid activeColor;
    outline: none;
  }

  &:active {
    border: 0.1rem solid activeColor;
  }
`

export const titleStyles = (hasActiveFilters?: boolean) => css`
  color: ${hasActiveFilters ? color('primaryBlue') : color('darkBlue')};
`

export const CircleAddMoreFiltersButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: ${color('darkBlue')};
  padding: 0.6rem;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: 0.1rem solid ${color('darkBlue', 0.2)};

  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    background-color: ${color('veryLightBlue', 0.2)};
  }
`

export const CelesteButtonPosition = styled.div`
  position: fixed;
  bottom: 2.4rem;
  right: 4.2rem;
  width: 5rem;
  height: 5rem;
  z-index: 99;
`
