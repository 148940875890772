import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import { Optional } from 'ui/InputLabel/InputLabel.styles'
import { Container, Icon, Text } from './Title.styles'

interface TitleProps {
  title: string | React.ReactNode
  icon?: IconProp
  image?: React.ReactNode
  iconSize?: string
  uppercase?: boolean
  fontSize?: string
  color?: string
  subLabel?: string
}

const Title = ({
  title,
  uppercase = false,
  image = null,
  icon,
  iconSize = '',
  fontSize = '',
  color = '',
  subLabel = '',
}: TitleProps) => (
  <Container iconSize={iconSize}>
    {image || (icon && <Icon icon={icon} />) || null}
    <Text uppercase={uppercase} fontSize={fontSize} color={color}>
      {title}
      {subLabel && <Optional>{subLabel}</Optional>}
    </Text>
  </Container>
)

export default Title
