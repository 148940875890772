import styled from 'styled-components'
import { color } from 'utils/functions/colors'
import ButtonToStyle from 'ui/Button'
import { ReactComponent as CWLogo } from 'assets/images/logo-cw.svg'
import { isMacApp } from 'utils/functions/electron'
import GoogleLoginButton from './components/GoogleLoginButton'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${() => `calc(100vh - ${isMacApp() ? '2.8rem' : '0px'})`};
`

export const Logo = styled(CWLogo)`
  width: auto;
  max-width: 20rem;
  margin-bottom: 3.2rem;
  fill: white;

  @media (min-width: 76rem) {
    fill: initial;
  }
`

export const Form = styled.form`
  background-color: white;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  padding: 2.4rem 1.6rem;
  width: 30rem;
  border: 0.1rem solid ${color('veryLightGray')};
  box-sizing: border-box;
  box-shadow: 0 0 1.5rem rgba(16, 21, 39, 0.1);
  gap: 2.4rem;
`

export const FormWrapper = styled.div`
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`

export const Title = styled.h1`
  color: black;
  font-size: 2.4rem;
  margin: 0;
  text-align: center;
  font-weight: 400;
`

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`

export const Button = styled(ButtonToStyle)`
  font-size: 1.4rem;
`

export const GoogleButton = styled(GoogleLoginButton)`
  min-width: 0;
  width: 23.9rem;
  align-self: center;
`
