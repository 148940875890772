import { useEffect, useState } from 'react'
import { HoldingsFilters, HoldingTypeFilter } from 'api/HoldingsService'
import useInitialData from 'utils/hooks/useInitialData'
import useTags from 'utils/hooks/useTags'
import { Tag } from 'utils/types/update'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'
import { DEFAULT_FILTER, PAGE_SIZE } from '../useCompanyIndex'
import { LegalStructure } from '../../../utils/types/company'
import { useAllCompanies } from '../useAllCompanies'

interface Props {
  currentFilters: HoldingsFilters
  onChangeFilters: (activeFilters: HoldingsFilters) => void
  onClose: () => void
}

export const useFiltersDrawer = ({
  currentFilters,
  onChangeFilters,
  onClose,
}: Props) => {
  const { initialData } = useInitialData()
  const { tags } = useTags()
  const [filtersToApply, setFiltersToApply] = useState(currentFilters)
  const [firstFiltered, setFirstFiltered] = useState(false)
  const [
    locationPlaceAddressSearchTerm,
    debouncedLocationPlaceAddressSearchTerm,
    setSearchLocationPlaceAddressSearchTerm,
  ] = useDebouncedState(currentFilters.locationPlaceAddress)
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const allSectors = initialData?.sectors ?? []

  useEffect(() => {
    setFiltersToApply(currentFilters)
  }, [currentFilters])

  const getIndustries = (currentSectorId: string) =>
    initialData?.industries.filter(
      (industry) => industry.sectorId === currentSectorId
    ) || []

  const { data: holdingsData, isLoading } = useAllCompanies({
    currentFilters: filtersToApply,
    companiesPerPage: PAGE_SIZE,
  })

  const loadLegalStructureOptions =
    (array?: LegalStructure[]) => (search: string) =>
      array?.filter((item) =>
        item.name.toLocaleLowerCase().includes(search.toLowerCase())
      )

  const getLegalStructureOption = (item: LegalStructure) => ({
    id: item.id,
    label: item.name,
  })

  const filtersApplied =
    !!filtersToApply.industryId?.length ||
    !!filtersToApply.legalStructureId?.length ||
    !!filtersToApply.countryId?.length ||
    !!filtersToApply.legalProvinceId?.length ||
    !!filtersToApply.onlyPortfolio ||
    !!filtersToApply.onlyPrivateCompany ||
    !!filtersToApply.typeIn?.length ||
    !!filtersToApply.tags?.length ||
    !!filtersToApply.locationPlaceAddress

  const totalHoldings = filtersApplied ? holdingsData?.pages[0]?.total : `1000+`

  const onClearFilters = () => {
    onChangeFilters(DEFAULT_FILTER)
    setFiltersToApply(DEFAULT_FILTER)
    setFirstFiltered(false)
    setSearchLocationPlaceAddressSearchTerm('')
    onClose()
  }

  const onApplyFilters = () => {
    if (!filtersApplied) {
      setFirstFiltered(false)
    } else {
      setFirstFiltered(true)
    }
    onChangeFilters(filtersToApply)
    onClose()
  }

  const onOnlyPortfolioChange = () => {
    setFiltersToApply((state) => ({
      ...state,
      onlyPortfolio: !filtersToApply.onlyPortfolio,
    }))
  }

  const onOnlyPrivateCompanyChange = (isActive) => {
    setFiltersToApply((state) => ({
      ...state,
      onlyPrivateCompany: isActive,
      groupId: isActive ? currentGroupId : '',
      typeIn: isActive ? [] : state.typeIn,
    }))
  }

  const onTagChange = (selectedTag: Tag) => {
    const valueIsAlreadySelected = filtersToApply.tags?.find(
      (tag) => tag.id === selectedTag.id
    )
    const listOfTags = valueIsAlreadySelected
      ? filtersToApply.tags?.filter((tag) => tag.id !== selectedTag.id)
      : [...(filtersToApply.tags || []), selectedTag]

    setFiltersToApply((state) => ({
      ...state,
      tags: listOfTags ?? [],
    }))
  }

  const onLegalStructureChange = (id: string) => {
    setFiltersToApply((state) => ({
      ...state,
      legalStructureId: [id],
    }))
  }

  const onLocationPlaceAddressChange = (e) =>
    setSearchLocationPlaceAddressSearchTerm(e.target.value)

  useEffect(() => {
    setFiltersToApply((state) => ({
      ...state,
      locationPlaceAddress: debouncedLocationPlaceAddressSearchTerm,
    }))
  }, [debouncedLocationPlaceAddressSearchTerm])

  const onSelectSector = (id: string) => {
    const valueIsAlreadySelected = filtersToApply.sectorId?.find(
      (sectorId) => sectorId === id
    )
    const listOfSectors = valueIsAlreadySelected
      ? filtersToApply.sectorId?.filter((sectorId) => sectorId !== id)
      : [...(filtersToApply.sectorId || []), id]

    const industriesOfSelectedSector = getIndustries(id).map(
      (industry) => industry.id
    )

    const listOfIndustries = valueIsAlreadySelected
      ? filtersToApply.industryId?.filter(
          (industryId) => !industriesOfSelectedSector.includes(industryId)
        )
      : [
          ...(filtersToApply.industryId || []),
          ...getIndustries(id).map((industry) => industry.id),
        ]

    setFiltersToApply((state) => ({
      ...state,
      sectorId: listOfSectors,
      industryId: listOfIndustries,
    }))
  }

  const onSelectType = (type: HoldingTypeFilter) => {
    const valueIsAlreadySelected = filtersToApply.typeIn?.find(
      (selectedType) => selectedType === type
    )
    const listOfTypes = valueIsAlreadySelected
      ? filtersToApply.typeIn?.filter((selectedType) => selectedType !== type)
      : [...(filtersToApply.typeIn || []), type]

    setFiltersToApply((state) => ({
      ...state,
      typeIn: listOfTypes,
    }))
  }

  const getAllIndustriesForSectorSelected = (
    sectorId: string,
    industryIds: string[]
  ): boolean => {
    const industriesByCurrentSector = getIndustries(sectorId).map(
      (industry) => industry.id
    )

    return industriesByCurrentSector.every((id) => industryIds?.includes(id))
  }

  const onSelectIndustry = ({
    industryId,
    sectorId,
  }: {
    industryId: string
    sectorId: string
  }) => {
    const valueIsAlreadySelected = filtersToApply.industryId?.find(
      (id) => id === industryId
    )
    const newListOfIndustries = valueIsAlreadySelected
      ? filtersToApply.industryId?.filter((id) => id !== industryId)!
      : [...(filtersToApply.industryId || []), industryId]

    const allIndustriesOfSectorAreSelected = getAllIndustriesForSectorSelected(
      sectorId,
      newListOfIndustries
    )

    const newListOfSectors = allIndustriesOfSectorAreSelected
      ? [...(filtersToApply.sectorId || []), sectorId]
      : filtersToApply.sectorId?.filter((id) => id !== sectorId)

    setFiltersToApply((state) => ({
      ...state,
      sectorId: newListOfSectors,
      industryId: newListOfIndustries,
    }))
  }

  const onCloseHandler = () => {
    onClose()
  }

  return {
    initialData,
    tags,
    filtersToApply,
    totalHoldings,
    allSectors,
    isLoading,
    filtersApplied,
    firstFiltered,
    locationPlaceAddressSearchTerm,
    getIndustries,
    onTagChange,
    onLegalStructureChange,
    onClearFilters,
    onOnlyPortfolioChange,
    onOnlyPrivateCompanyChange,
    onApplyFilters,
    onCloseHandler,
    onSelectSector,
    onSelectIndustry,
    onSelectType,
    loadLegalStructureOptions,
    getLegalStructureOption,
    onLocationPlaceAddressChange,
  }
}
