import TagService from 'api/TagService'
import UpdateService from 'api/UpdateService'
import { useField, useForm } from 'components/Form/hooks'
import TagsInput from 'components/TagsInput'
import Title from 'components/Title'
import UpdateCardBox from 'containers/Updates/UpdateCardBox'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'
import {
  addTagToList,
  getGroupTagWithSameName,
  isValidTag,
  removeTagFromList,
} from 'utils/functions/tags'
import useTagsByGroup from 'utils/hooks/useTagsByGroup'
import { Tag } from 'utils/types/update'
import { UpdateFormContext } from 'utils/types/updateForm'

const Tags = () => {
  const [field, _, helpers] = useField<Tag[]>('tags')
  const { value } = field
  const { setValue } = helpers
  const intl = useIntl()
  const groupTags = useTagsByGroup()
  const { updateId, isEditingDraft, submitDraft } = useForm<UpdateFormContext>()
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const onAddTag = async (tagName: string) => {
    if (isEditingDraft) {
      let tag = getGroupTagWithSameName(groupTags, tagName)
      if (!tag) {
        tag = await TagService.createTag(tagName, currentGroupId)
      }
      setValue([...value, tag])
      submitDraft?.()
    } else {
      // TODO: Remove this and use draft logic when all updates support drafts
      const newTagList: Tag[] = addTagToList(tagName, value, groupTags)
      setValue(newTagList)
      const addedTag = newTagList.find((tag) => tag.name === tagName)
      const newTag = await UpdateService.addTagToUpdate(updateId!, addedTag!)

      setValue(
        newTagList.map((currTag) => {
          if (currTag.name === tagName) {
            return newTag
          }
          return currTag
        })
      )
    }
  }

  const onRemoveTag = (tag: Tag) => {
    if (isEditingDraft) {
      setValue(value.filter((currTag) => currTag.id !== tag.id))
      submitDraft?.()
    } else {
      // TODO: Remove this and use draft logic when all updates support drafts
      const newTagList = removeTagFromList(tag, value)
      setValue(newTagList)

      UpdateService.removeTagFromUpdate(updateId!, tag.taggingId)
    }
  }

  return (
    <UpdateCardBox>
      <UpdateCardBox.Header>
        <Title
          title={intl.formatMessage({
            id: 'tags.tagsTitle',
          })}
          icon={['far', 'tag']}
        />
      </UpdateCardBox.Header>
      <UpdateCardBox.Body>
        <TagsInput
          tagsList={value}
          onAddTag={onAddTag}
          onRemoveTag={onRemoveTag}
          validationWhenAddingTag={(tagName: string) =>
            isValidTag(tagName, intl)
          }
          maxTagWidth="34rem"
        />
      </UpdateCardBox.Body>
    </UpdateCardBox>
  )
}

export default Tags
