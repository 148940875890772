import { Mark, mergeAttributes } from '@tiptap/core'

export interface InsOptions {
  HTMLAttributes: Record<string, any>
}

export const Ins = Mark.create<InsOptions>({
  name: 'ins',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'ins',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ins',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },
})
