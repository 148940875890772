import React, { useMemo } from 'react'
import { FormContext } from './hooks'
import { IFormContext } from './hooks/useForm'

interface FormProps {
  prefix?: string
  children: React.ReactNode
}

type Props<T = {}> = T & FormProps

const Form = <T extends IFormContext = IFormContext>({
  children,
  prefix,
  ...props
}: Props<T>) => {
  const value = useMemo(() => ({ prefix, ...props }), [prefix, props])

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export default Form
