import { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { type AuthorizationParams } from '@auth0/auth0-react'

import AuthLayout from 'layouts/AuthLayout'
import Input from 'ui/Input'

import FormSeparator from './components/FormSeparator'
import * as Styles from './DesktopSignIn.styles'
import SignUpLink from './components/SignUpLink'

interface Props {
  handleLogin: (authorizationParams?: AuthorizationParams) => void
}

const DesktopSignIn = ({ handleLogin }: Props) => {
  const intl = useIntl()
  const emailInputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)

  const preventSignInTemporally = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 4000)
  }

  const onSignIn = () => {
    preventSignInTemporally()
    handleLogin({
      login_hint: emailInputRef.current?.value,
    })
  }

  const onSignUpClick = () => {
    preventSignInTemporally()
    handleLogin({
      screen_hint: 'signup',
      redirect_uri: undefined,
    })
  }

  const onSignInWithGoogle = () => {
    preventSignInTemporally()
    handleLogin({
      connection: 'google-oauth2',
    })
  }

  return (
    <AuthLayout>
      <Styles.Container>
        <Styles.FormWrapper>
          <Styles.Logo />
          <Styles.Form>
            <Styles.Title>
              <FormattedMessage id="login.title" />
            </Styles.Title>
            <Styles.GoogleButton
              isLoading={isLoading}
              onClick={onSignInWithGoogle}
            />
            <FormSeparator>
              <FormattedMessage id="login.separatorMessage" />
            </FormSeparator>
            <Styles.InputsContainer>
              <Input
                ref={emailInputRef}
                id="email"
                name="email"
                placeholder={intl.formatMessage({ id: 'login.email' })}
                className="email-input"
                disabled={isLoading}
                onPressEnter={onSignIn}
              />
            </Styles.InputsContainer>
            <Styles.Button
              onClick={onSignIn}
              primary
              uppercase
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              <FormattedMessage id="login.signIn" />
            </Styles.Button>

            <SignUpLink onClick={onSignUpClick} />
          </Styles.Form>
        </Styles.FormWrapper>
      </Styles.Container>
    </AuthLayout>
  )
}

export default DesktopSignIn
