import { Holding, getHoldingImage } from 'utils/types/company'
import companyLogoPlaceholder from 'assets/images/missing.png'
import useOnClickRedirect from 'utils/hooks/useOnClickRedirect'
import Tooltip from 'components/Tooltip'
import {
  Cell,
  Company,
  CompanyAvatar,
  CompanyName,
} from 'utils/functions/renderers/renderFundHoldingsHelper'

interface HoldingNameProps {
  holding: Holding
}

const HoldingName: React.FC<HoldingNameProps> = ({ holding }) => {
  const companyImage = getHoldingImage(holding)
  const image = !companyImage ? companyLogoPlaceholder : companyImage
  const { handleOnHoldingClick } = useOnClickRedirect()

  return (
    <Cell>
      <Company onClick={() => handleOnHoldingClick(holding)}>
        <CompanyAvatar src={image} title={holding?.name} />
        <Tooltip
          id={`company-name-${holding?.name}`}
          place="bottom"
          text={holding?.name}
          delayShow={500}
        >
          <CompanyName>{holding?.name}</CompanyName>
        </Tooltip>
      </Company>
    </Cell>
  )
}

export default HoldingName
