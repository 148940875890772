export default {
  you: 'You',
  today: 'Today ',
  justNow: 'Just now',
  inputPlaceholder: 'Send a message',
  new: '{count} NEW',
  messageReplies: '{count, plural, one {1 reply} other {# replies}}',
  thread: 'Thread',
  addUsers: 'Add group members and users',
  newChat: 'New Chat',
  searchChat: 'Search',
  newChatOn: 'New Chat on {topic}',
  participants: 'Participants',
  archive: 'Archive',
  unarchive: 'Unarchive',
  archived: 'Archived',
  archiveChat: 'Archive Chat',
  conversationArchived: 'Your conversation has been archived.',
  yesArchive: 'Yes, archive',
  noParticipantsZeroState: 'It looks like you have no participants added yet.',
  noParticipantsZeroStateDescription:
    "Don't worry, it's easy to get started! Just click on the box above and add your group members or users. You'll be chatting away in no time!",
  errorCreatingChat:
    'An error occurred when trying to create the chat, try again later',
  groupMembers: 'Your group Members',
  recentUsers: 'Recently used users',
  newMessages: 'New',
  addParticipants: 'Add Participants',
  viewParticipants: 'Participants',
  dontIncludeChatHistory: 'Don’t include chat history',
  includeChatHistory: 'Include all chat history',
  addParticipantsError:
    'An error occurred when trying to add the participants to the chat, try again later',
  joinedTheChannel: '{name} joined the channel',
  deleteMessageModal: {
    removeMessage: 'Remove message',
    removeFor: 'Who do you want to remove this message for?',
    removeForEveryone: 'Remove for everyone',
    removeForEveryoneDescription:
      'You willl permanently remove this message for all chat participants. They can see you removed a message.',
    removeForYou: 'Remove for you',
    removeForYouDescription:
      'This message will be removed for you. Other chat participants will still be able to see it.',
  },
  youDeletedThisMessage: 'You deleted this message',
  youDeletedThisMessageOnlyForYou: 'You deleted this message only for you',
  thisMessageWasDeleted: 'This message was deleted',
  startNewChat: 'Start New Chat',
  hasJoinedTheChat: 'has joined the chat.',
  haveJoinedTheChat: 'have joined the chat.',
  noMessagesYet: 'No messages yet. Type your first message.',
  chatWith: 'Chat with {names}',
  isTyping: '{names} is typing...',
  areTyping: '{names} are typing...',
  severalPeopleAreTyping: 'Several people are typing...',
  edited: ' (edited)',
  mute: 'Mute',
  unmute: 'Unmute',
  muteNotifications: 'Mute notifications',
  muteChannelError:
    'An error occurred when trying to mute the chat, try again later',
  mutedAlways: 'Muted always',
  mutedUntil: 'Muted until {date}',
  topicPlaceholder: 'Select group or portfolio',
  topic: 'Topic',
  titlePlaceholder: 'Give your chat a representative title',
  messageOnlyVisibleToYou: 'Message only visible to you',
  startNewDiscussion:
    'Do you want to create a new discussion with this message and assign it to {topic} profile?',
  startNewDiscussionPortfolio:
    'Do you want to create a new discussion with this message and assign it to {topic} portfolio?',
  alreadyExistentChatProfile:
    'It seems you already have a chat regarding this profile. Would you like to go to that conversation or create a new one?',
  alreadyExistentChatPortfolio:
    'It seems you already have a chat regarding this portfolio. Would you like to go to that conversation or create a new one?',
  alreadyExistentChatUpdate:
    'It seems you already have a chat regarding this update. Would you like to go to that conversation or create a new one?',
  goToExisting: 'Go to existing',
  createNewChat: 'Create new chat',
  leaveItHere: 'No, leave it here',
  createNewDiscussion: 'Create new discussion',
  holdingType: {
    COMPANY: 'Company',
    FUND: 'Fund',
  },
  portfolios: {
    track: 'Track Portfolio',
    invest: 'Invest Portfolio',
    fund: 'Fund Portfolio',
  },
  leave: 'Leave',
  leaveChat: 'Leave chat',
  areYouSureYouWantToLeave: 'Are you sure you want to leave this chat?',
  leaveConfirmation: 'Yes, Leave',
  leaveChatError:
    'An error occurred when trying to leave the chat, try again later',
  hasLeftTheChat: 'has left the chat.',
  search: {
    searchResults: 'Search results for "{search}"',
    messages: 'Messages ({count})',
    chats: 'Chats ({count})',
    participants: 'Participants ({count})',
  },
  chat: 'Chat',
  noChats: 'No chats.',
  noUpdateAccess:
    '<bold>Update Access Denied</bold> - <light>Sorry, you do not have the necessary permissions to view this update. Please contact the user who shared it with you to request access.</light>',
  chatConnectionError: 'Chat Connection Error',
  chatConnectionErrorDescription:
    "Sorry, we can't load your messages right now. Please reload the page and try again. If the problem persists, contact our support team for further assistance.",
  countMembers: '{count, plural, one {1 member} other { # members}}',
  directMessages: 'Direct messages',
  topics: 'Topics',
  addTopic: 'Add topic',
  startNewMessage: 'New message',
  addConversation: 'Add conversation',
  popupChatWindow: 'Popup Chat Window',
  pinChat: 'Pin Chat',
  unpinChat: 'Unpin Chat',
  chatDetails: 'Chat Details',
}
