import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback, useState } from 'react'

import HoldingsService, { HoldingTypeFilter } from 'api/HoldingsService'
import PortfolioService from 'api/PortfolioService'

import { PortfolioTypes } from 'utils/constants/portfolio'
import { holdingsAndPortfolios } from 'utils/queries/holdingsAndPortfolios'
import {
  TopicEntity,
  TopicEntityType,
  buildHoldingTopicEntity,
  buildHoldingTopicEntityFromCompany,
  buildPortfolioTopicEntity,
} from 'utils/types/chatTopicEntity'
import { Nullable } from 'utils/types/common'
import useGroupCompany from '../useGroupCompany'

interface Props {
  isRequestEnabled?: boolean
  queryPortfolios?: boolean
  queryHoldings?: boolean
  showOwnOrganizations?: boolean
  singleEntityId?: string
  portfoliosQuery?: PortfolioTypes[]
}

export const getPortfolioTypeFromTopic = (
  topicEntityType: TopicEntityType
): Nullable<PortfolioTypes> => {
  switch (topicEntityType) {
    case TopicEntityType.FUND_PORTFOLIO:
      return PortfolioTypes.FUND
    case TopicEntityType.DEAL_PORTFOLIO:
      return PortfolioTypes.DEAL
    case TopicEntityType.INVEST_PORTFOLIO:
      return PortfolioTypes.INVEST
    case TopicEntityType.TRACK_PORTFOLIO:
      return PortfolioTypes.TRACK
    default:
      return null
  }
}

export const usePortfoliosAndHoldingsQuery = ({
  isRequestEnabled = true,
  queryPortfolios = true,
  queryHoldings = true,
  showOwnOrganizations = true,
  singleEntityId = '',
  portfoliosQuery,
}: Props = {}) => {
  const [searchValue, refetchData] = useState('')
  const queryKey = singleEntityId
    ? holdingsAndPortfolios.byId(singleEntityId)
    : holdingsAndPortfolios.all(queryHoldings, queryPortfolios, searchValue)
  const queryClient = useQueryClient()
  const groupCompany = useGroupCompany()

  const loadPortfolios = useCallback(async () => {
    if (queryPortfolios) {
      const portfolios = await PortfolioService.fetchPortfolios({
        portfolioType: portfoliosQuery || [
          PortfolioTypes.FUND,
          PortfolioTypes.INVEST,
          PortfolioTypes.TRACK,
        ],
        name: searchValue,
        withCompanies: true,
        perPage: singleEntityId ? 1 : undefined,
        idIn: singleEntityId ? [singleEntityId] : undefined,
      })

      return portfolios.map<TopicEntity>(buildPortfolioTopicEntity)
    }

    return []
  }, [portfoliosQuery, queryPortfolios, searchValue, singleEntityId])

  const loadHoldings = useCallback(async () => {
    if (queryHoldings) {
      const { holdings } = await HoldingsService.getHoldings({
        page: 1,
        filters: {
          name: searchValue,
          idIn: singleEntityId ? [singleEntityId] : undefined,
          typeIn: queryPortfolios
            ? [
                HoldingTypeFilter.COMPANY,
                HoldingTypeFilter.FUND,
                HoldingTypeFilter.DEAL,
              ]
            : undefined,
        },
        companiesPerPage: singleEntityId ? 1 : 50,
      })

      return holdings.map<TopicEntity>(buildHoldingTopicEntity)
    }

    return []
  }, [queryHoldings, queryPortfolios, searchValue, singleEntityId])

  const loadOrganization = (): Nullable<TopicEntity> => {
    if (!showOwnOrganizations || !groupCompany) return null
    if (groupCompany.name.toLowerCase().includes(searchValue.toLowerCase())) {
      return buildHoldingTopicEntityFromCompany(groupCompany)
    }
    return null
  }

  const removeFetchedData = useCallback(() => {
    queryClient.setQueryData(queryKey, [])
  }, [queryClient, queryKey])

  return {
    ...useQuery(
      queryKey,
      async (): Promise<TopicEntity[]> => {
        try {
          const [portfolios, holdings] = await Promise.all([
            loadPortfolios(),
            loadHoldings(),
          ])
          const organization = loadOrganization()
          const results: TopicEntity[] = []

          if (organization) results.push(organization)

          results.push(
            ...[
              ...portfolios,
              ...holdings.filter((holding) => organization?.id !== holding.id),
            ].sort((a, b) => a.label.localeCompare(b.label))
          )

          return results
        } catch {
          return []
        }
      },
      {
        enabled: isRequestEnabled,
        keepPreviousData: true,
      }
    ),
    refetchData,
    removeFetchedData,
  }
}
