/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import EmailService from 'api/EmailService'
import { getCurrentCompanyOwner } from 'selectors/company'
import GroupUsersAddInput from 'components/GroupsUsersAddInput'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import Toast from 'components/Toast'
import ListsSelectionBox from 'components/ListsSelectionBox'
import Card from 'components/Card'
import Title from 'components/Title'
import styles from './AddEmailRecipients.module.scss'
import { EntityType } from '../../utils/constants/groups'

const PAGE_SIZE = 500

const AddEmailRecipients = ({ emailUpdate }) => {
  const intl = useIntl()
  const [recipients, setRecipients] = useState([])
  const [loading, setLoading] = useState(true)
  const companyOwner = useAppSelector(getCurrentCompanyOwner)

  const getRecipients = async () => {
    if (!emailUpdate.item.id) return

    try {
      const {
        data: {
          entities: {
            reportRecipients,
            users = {},
            groups = {},
            investorGroups,
          },
        },
      } = await EmailService.getReportRecipients(emailUpdate.item.id, PAGE_SIZE)

      const results = []
      const groupResults = []
      if (reportRecipients) {
        Object.values(reportRecipients).forEach((recipient) => {
          if (recipient.user) {
            const { email, firstName, lastName, unreachableEmailAddress } =
              users[recipient.user]
            results.push({
              ...recipient,
              email,
              firstName,
              lastName,
              unreachableEmailAddress,
            })
          } else if (recipient.investorGroup) {
            const { id: groupId, ...restGroup } =
              investorGroups[recipient.investorGroup]
            groupResults.push({ ...recipient, ...restGroup, groupId })
            results.push({ ...recipient, ...restGroup, groupId })
          } else {
            const { id: groupId, ...restGroup } = groups[recipient.group]
            groupResults.push({ ...recipient, ...restGroup, groupId })
            results.push({ ...recipient, ...restGroup, groupId })
          }
        })
      }
      setRecipients(results)

      setLoading(false)
    } catch (err) {
      Toast.display(intl.messages['createEmail.errorGetRecipients'], 'error')
    }
  }

  useEffect(() => {
    getRecipients()
  }, [])

  const createRecipient = async (user) => {
    const recipient = await EmailService.createReportRecipient(
      emailUpdate.item.id,
      {
        reportRecipient: { recipientId: user.id, recipientType: 'User' },
        scheduled: false,
      }
    )
    const { email, firstName, lastName, listId } = user
    return { ...recipient, email, firstName, lastName, listId }
  }

  const handleBulkAddUsers = async (newUsers) => {
    try {
      const newRecipients = await EmailService.bulkCreateReportRecipient(
        emailUpdate.item.id,
        PAGE_SIZE,
        {
          reportRecipients: newUsers.map((user) => ({
            recipientType: 'User',
            recipient_id: user.id,
          })),
          scheduled: false,
        }
      )

      setRecipients((state) => [
        ...state,
        ...newRecipients.map((recipient) => {
          const user = newUsers.find((usr) => recipient.user.id === usr.id)
          const { email, firstName, lastName, listId, id: entityId } = user
          return {
            ...recipient,
            email,
            firstName,
            lastName,
            listId,
            entityId,
          }
        }),
      ])
    } catch (err) {
      Toast.display(
        intl.messages['createEmail.errorCreatingRecipient'],
        'error'
      )
    }
  }

  const handleBulkAddGroups = async (newGroups) => {
    try {
      const newRecipients = await EmailService.bulkCreateReportRecipient(
        emailUpdate.item.id,
        PAGE_SIZE,
        {
          reportRecipients: newGroups.map((group) => ({
            recipientType:
              group.type === EntityType.INVESTOR_GROUP
                ? EntityType.INVESTOR_GROUP
                : EntityType.GROUP,
            recipient_id: group.id,
          })),
          scheduled: false,
        }
      )
      setRecipients((state) => [
        ...state,
        ...newRecipients.map((recipient) => {
          const group = newGroups.find(
            (grp) => grp.id === recipient.recipientId
          )

          return { ...group, ...recipient, entityId: group.id }
        }),
      ])
    } catch (err) {
      Toast.display(
        intl.messages['createEmail.errorCreatingRecipient'],
        'error'
      )
    }
  }

  const handleDeleteRecipient = async (recipientIdToRemove) => {
    const recipientId = recipients.find(
      (recipient) => recipient.id === recipientIdToRemove
    )?.id

    if (recipientId) {
      try {
        await EmailService.removeReportRecipient(recipientId)

        setRecipients((state) =>
          state.filter(({ id: value }) => value !== recipientId)
        )
      } catch (err) {
        Toast.display(
          intl.messages['createEmail.errorRemovingRecipient'],
          'error'
        )
      }
    }
  }

  const handleAddList = async ({ users, groups }) => {
    const promises = []
    if (users?.length) {
      promises.push(handleBulkAddUsers(users))
    }
    if (groups?.length) {
      promises.push(handleBulkAddGroups(groups))
    }
    return Promise.all(promises)
  }

  const handleRemoveList = async (recipientIdsToRemove) => {
    await EmailService.bulkDeleteReportRecipient(
      emailUpdate.item.id,
      recipientIdsToRemove
    )

    setRecipients((state) =>
      state.filter((rcp) => !recipientIdsToRemove.includes(rcp.id))
    )
  }

  const handleEditItemList = ({ id: userId, listId, listName }) => {
    setRecipients((state) =>
      state.map((item) =>
        item.id === userId ? { ...item, listId, listName } : item
      )
    )
  }

  const userRecipients = useMemo(
    () =>
      recipients
        .filter((item) => item.recipientType === EntityType.USER)
        .map((item) => ({ ...item, canDelete: true })),
    [recipients]
  )

  const groupRecipients = useMemo(
    () =>
      recipients
        .filter(
          (item) =>
            item.recipientType === EntityType.GROUP ||
            item.recipientType === EntityType.INVESTOR_GROUP
        )
        .map((item) => ({ ...item, canDelete: true })),
    [recipients]
  )

  return (
    <div className={styles.addEmailRecipientsContainer}>
      <div className={styles.boxContainerAdd}>
        <div className={styles.addGroupUsers}>
          <div className={styles.titleContainer}>
            <Title
              icon={['far', 'address-book']}
              title={intl.messages['createEmail.addRecipients']}
              iconSize="1.6rem"
            />
          </div>
          <GroupUsersAddInput
            id="email-recipients-group-users"
            handleBulkAddUsers={handleBulkAddUsers}
            handleAddUser={(user) => handleBulkAddUsers([user])}
            handleAddGroup={(group) => handleBulkAddGroups([group])}
            companyOwner={companyOwner}
            clearOnSelect
            showLabel={false}
          />
        </div>
        <Title title={intl.messages['createEmail.selectLists']} />
        <ListsSelectionBox
          showLabel={false}
          items={recipients}
          handleEditItemList={handleEditItemList}
          columns={1}
          rowGap="0.8rem"
          onSelectList={handleAddList}
          onUncheckList={handleRemoveList}
        />
      </div>

      <div className={styles.recipientsContainer}>
        <Card>
          <Title
            icon={['far', 'users']}
            iconSize="1.6rem"
            title={
              userRecipients?.length
                ? intl.formatMessage(
                    { id: 'createEmail.emailRecipients' },
                    { count: userRecipients.length }
                  )
                : intl.messages['createEmail.emailRecipientsEmpty']
            }
          />
          {!userRecipients.length && !loading && (
            <p className={styles.noRecipients}>
              {intl.messages['createEmail.noRecipients']}
            </p>
          )}
          <div className={styles.usersBox}>
            <BoxGroupsAndUsersChips
              items={userRecipients}
              handleDelete={handleDeleteRecipient}
              loading={loading}
            />
          </div>

          <div className={styles.separator} />

          <Title
            icon={['far', 'share']}
            iconSize="1.6rem"
            title={intl.messages['createEmail.sharedInClockwork']}
          />
          <p className={styles.text}>
            {intl.messages['createEmail.inAppGroupsText']}{' '}
            <b>{intl.messages['createEmail.inAppGroupsText2']}</b>
          </p>
          {!groupRecipients.length && !loading && (
            <p className={styles.noRecipients}>
              {intl.messages['createEmail.noGroups']}
            </p>
          )}
          <div className={styles.usersBox}>
            <BoxGroupsAndUsersChips
              items={groupRecipients}
              handleDelete={handleDeleteRecipient}
              loading={loading}
              canDelete
            />
          </div>
        </Card>
      </div>
    </div>
  )
}

AddEmailRecipients.propTypes = {
  emailUpdate: PropTypes.shape({
    id: PropTypes.string,
    attachments: PropTypes.array,
    item: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
}

export default AddEmailRecipients
