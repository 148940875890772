import React from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import TimelineItem from './components/TimelineItem'

import { TimelineList, TimelineTitle } from './Timeline.styles'

const Timeline = ({ children, title, renderChildrenDuration, listMargin }) => {
  const list = {
    visible: {
      transition: {
        staggerChildren: renderChildrenDuration,
        delayChildren: 0.2,
      },
    },
    hidden: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  }
  return (
    <div>
      <TimelineTitle level="h4">{title}</TimelineTitle>
      <TimelineList
        as={motion.ul}
        initial="hidden"
        animate="visible"
        variants={list}
        margin={listMargin}
      >
        {children}
      </TimelineList>
    </div>
  )
}

Timeline.Item = TimelineItem

Timeline.defaultProps = {
  title: '',
  renderChildrenDuration: 0.2,
  listMargin: '',
}

Timeline.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  renderChildrenDuration: PropTypes.number,
  listMargin: PropTypes.string,
}

export default Timeline
