import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export interface Position {
  top?: string
  left?: string
  right?: string
  bottom?: string
}

export const ReactionSelectorContainer = styled.div<{
  position: Position
  isRelative?: boolean
}>`
  ${({ isRelative }) => isRelative && 'position: relative;'}

  .str-chat__message-reaction-selector {
    ${({ position }) => {
      const { top, left, right, bottom } = position
      return css`
        top: ${top};
        left: ${left};
        right: ${right};
        bottom: ${bottom};
      `
    }}
  }

  .str-chat__reaction-selector .str-chat__message-reactions-options {
    background: white;
    border: 0.1rem solid ${color('veryLightGray')};
    border-radius: 0.8rem;
    box-shadow: initial;
    gap: 1.8rem;
    padding: 0.3rem 0.9rem;
    z-index: 1;

    .str-chat__message-reactions-list-item {
      width: fit-content;
      height: fit-content;
      padding: 0.5rem;
      outline-color: none;

      &:hover {
        background: none;
      }
    }

    .str-chat__message-reactions-option.str-chat__message-reactions-option-selected {
      background: none;
    }
  }
`
