import styled, { css } from 'styled-components'
import { size } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

export const BriefingContainer = styled.div<{ isMainBriefing?: boolean }>`
  align-items: center;
  align-self: flex-end;
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: ${(props) => (props.isMainBriefing ? '8.4rem' : '7.1rem')};
`
export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.4rem;

  @media ${size.xsm} {
    max-width: 12rem;
  }
`

const Text = styled.p`
  color: ${color('white')};
  font-weight: normal;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Name = styled(Text)`
  font-size: 1.4rem;
  text-align: left;
`

export const Handle = styled(Text)`
  font-size: 1.2rem;
  text-align: left;
  color: ${color('veryLightBlue')};
`

export const GroupManager = styled.div`
  max-width: 12rem;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: fit-content;
  word-break: break-all;
  background: ${color('primaryBlue')};
  border-radius: 1rem;
  color: ${color('white')};
  font-size: 1rem;
  font-weight: 700;
  height: 1.8rem;
  padding: 0.3rem 0.8rem;
`

export const IconButton = styled.button<{ active?: boolean }>`
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: #194590;
    `}

  svg {
    color: var(--color-white);
    cursor: pointer;
    margin: 1.25rem;
    text-align: center;
    font-size: 1.7rem;
  }
`

export const Expandable = styled.div<{ isExpanded?: boolean }>`
  height: 1.7rem;
  width: 1.7rem;
  background-color: ${(props) =>
    props.isExpanded ? color('primaryBlue')(props) : color('darkBlue')(props)};
  border-radius: 50%;
  border: 0.1rem solid;
  font-size: 1.2rem;
  font-weight: 600;
  left: 5.3rem;
  top: 4.5rem;

  position: absolute;
  transform: rotate(0deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 0.1rem solid #fafcfe;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isExpanded &&
    css`
      transform: rotate(180deg);
    `}

  svg {
    color: var(--color-white);
    font-size: 1.2rem;
    height: 1.5rem;
    margin: 0.4rem;
    min-width: 1.5rem;
  }

  @media (max-width: 720px) {
    left: 6.5rem;
    top: 6rem;
  }
`

export const ButtonBriefing = styled.button<{
  isMainBriefing?: boolean
  isActive?: boolean
}>`
  align-items: center;
  display: flex;
  outline: none;
  position: relative;
  width: 100%;
  height: ${(props) => (props.isMainBriefing ? '8.4rem' : '7.1rem')};

  @media (max-width: 768px) {
    height: ${(props) => (props.isMainBriefing ? '10.9rem' : '7.1rem')};
  }

  &:active,
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.isMainBriefing
      ? css`
          padding: 2rem 0.3rem 2rem 2rem;

          @media (max-width: 720px) {
            padding: 2rem 0.3rem 2rem 3.2rem;
          }
        `
      : css`
          padding: 1.4rem 0.7rem 1.4rem 2.7rem;
          height: 7.2rem;

          &:hover {
            background-color: #1b2341;
          }

          &:focus {
            background-color: ${color('primaryBlue')};
          }
        `};

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${color('primaryBlue')(props)};
    `}

  div[class*='Avatar_avatarCircle'] {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1.2rem 0 0;
    width: 4.4rem;
    height: 4.4rem;
    box-sizing: content-box;
    ${(props) =>
      !props.isMainBriefing &&
      css`
        width: 3.45rem;
        height: 3.45rem;
      `}
  }
`
