/* eslint-disable class-methods-use-this */
import dayjs from 'dayjs'
import { getIntlConfig } from 'languageProvider'
import { createIntl } from 'react-intl'
import type { IntlShape } from 'react-intl'

import { ChangeSetFieldsType } from '../logs-builders/LogsBuilder'
import {
  ChangeSet,
  ChangeSetItem,
  EventType,
  FieldName,
  Log,
  LogsByDate,
  LogUser,
} from '../types'

export interface BackendEvent<T> {
  id: string
  event: EventType
  createdAt: string
  text: string
  user?: LogUser
  groupName?: string
  groupLogo?: string
  changeset: ChangeSet<T>
}

export class BackendEventsMapper<T extends ChangeSetFieldsType> {
  static DateFormat: string = 'D MMM YYYY'

  intl: IntlShape = createIntl(getIntlConfig())

  /*
   * Attribute that is ONLY on the main entity and is ALWAYS created (required)
   */
  mainAttribute: FieldName<T>

  constructor(attribute: FieldName<T>) {
    this.mainAttribute = attribute
  }

  getKeys(changeSet: ChangeSet<T>): FieldName<T>[] {
    return Object.keys(changeSet) as FieldName<T>[]
  }

  buildLog(event: BackendEvent<T>, key: FieldName<T>): Log<T> {
    return {
      id: `${event.id}-${key}`,
      event: event.event,
      createdAt: event.createdAt,
      text: event.text,
      user: event.user,
      groupName: event.groupName,
      groupLogo: event.groupLogo,
      fieldName: key,
      change: event.changeset[key],
    }
  }

  buildLegacyLog(
    event: BackendEvent<T>,
    key: FieldName<T>,
    changeset: ChangeSetItem
  ): Log<T> {
    return { ...this.buildLog(event, key), change: changeset }
  }

  getLogsFromEvent(event: BackendEvent<T>): Log<T>[] {
    const logs: Log<T>[] = []

    if (Object.keys(event.changeset).length === 0) {
      // Legacy migrated logs has no changeset data

      if (
        event.event === EventType.ADD_ATTACHMENT ||
        event.event === EventType.REMOVE_ATTACHMENT
      ) {
        logs.push(
          this.buildLegacyLog(event, this.mainAttribute, [null, event.text])
        )
      } else {
        logs.push(this.buildLegacyLog(event, this.mainAttribute, [null, null]))
      }
    } else if (event.event === EventType.CREATE) {
      if (event.changeset[this.mainAttribute]) {
        logs.push({
          ...this.buildLog(event, this.mainAttribute),
          change: [null, null],
        })
      } else {
        this.getKeys(event.changeset).forEach((key) => {
          logs.push({
            ...this.buildLog(event, key),
            event: EventType.UPDATE,
          })
        })
      }
    } else if (event.event !== EventType.UPDATE_COMPANY) {
      this.getKeys(event.changeset).forEach((key) => {
        logs.push(this.buildLog(event, key))
      })
    }

    return logs
  }

  getIndividualLogs(backendEvents: BackendEvent<T>[]): LogsByDate<T>[] {
    const logsByDateMap = new Map<string, Log<T>[]>()

    backendEvents.forEach((event) => {
      const newLogs = this.getLogsFromEvent(event)

      const date = dayjs(event.createdAt)
      let dateString = date.format(BackendEventsMapper.DateFormat)

      if (date.isToday()) {
        dateString = this.intl.formatMessage({ id: 'general.today' })
      }

      if (logsByDateMap.has(dateString)) {
        logsByDateMap.set(
          dateString,
          logsByDateMap.get(dateString)!.concat(newLogs)
        )
      } else {
        logsByDateMap.set(dateString, newLogs)
      }
    })

    return Array.from(logsByDateMap.entries())
      .filter(([_, logs]) => logs.length)
      .map(([date, logs]) => ({
        date,
        logs,
      }))
  }
}
