/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import EmailService from 'api/EmailService'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { getIsFounderVerified } from 'selectors/company'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { emailKeys } from 'utils/queries/emails'
import { ScheduleType } from 'utils/types/update'
import { randomId } from 'utils/functions/number'

import Toast from 'components/Toast'
import { useField, useForm, useFormikContext } from 'components/Form/hooks'
import {
  CreateEmailFormValues,
  UpdateEmailContext,
} from 'utils/types/emailForm'

const PAGE_SIZE = 500

const useCreateEmailForm = () => {
  const intl = useIntl()
  const history = useHistory()
  const { search, state: locationState } = useLocation<{ redirectTo: string }>()
  const {
    location: { pathname },
  } = history

  const recipientsStep = intl.formatMessage({ id: 'createEmail.recipients' })
  const sendStep = intl.formatMessage({ id: 'createEmail.send' })
  const buildStep = intl.formatMessage({ id: 'createEmail.build' })
  const query = new URLSearchParams(search)
  const step = query.get('step') || buildStep

  const [recipients, setRecipients] = useState(0)
  const [emailIframeKey, setEmailIframeKey] = useState('')

  const { emailUpdate } = useForm<UpdateEmailContext>()
  const { submitForm, errors } = useFormikContext<CreateEmailFormValues>()
  const [scheduleTypeField] = useField('scheduleType')

  const isFounderVerified = useAppSelector(getIsFounderVerified)

  const queryClient = useQueryClient()
  const invalidateEmailQuery = () => {
    if (!emailUpdate) return
    queryClient.invalidateQueries(emailKeys.byId(emailUpdate.id))
  }

  const getReportRecipients = async () => {
    try {
      const {
        data: {
          entities: { reportRecipients },
        },
      } = await EmailService.getReportRecipients(
        emailUpdate?.item.id,
        PAGE_SIZE
      )

      setRecipients(
        reportRecipients ? Object.values(reportRecipients).length : 0
      )
    } catch (err) {
      Toast.display(intl.messages['createEmail.errorGetRecipients'], 'error')
    }
  }

  const displayBtnText = () => {
    if (!isFounderVerified) {
      return intl.formatMessage({ id: 'createEmail.saveAsDraft' })
    }
    if (scheduleTypeField.value === ScheduleType.SCHEDULE) {
      return intl.formatMessage({ id: 'createEmail.schedule' })
    }
    return intl.formatMessage({ id: 'createEmail.sendNow' })
  }

  const handleClickNext = () => {
    if (step === buildStep) {
      invalidateEmailQuery()
      history.push(`${pathname}?step=${recipientsStep}`, {
        ...locationState,
      })
    } else if (step === recipientsStep) {
      history.push(`${pathname}?step=${sendStep}`, {
        ...locationState,
      })
    } else if (!isFounderVerified) {
      history.push('/emails', {
        showDrafts: true,
      })
    } else {
      submitForm()
    }
  }

  const handleClickPrevious = () => {
    if (step === recipientsStep) {
      history.push(`${pathname}?step=${buildStep}`, {
        ...locationState,
      })
    } else if (step === sendStep) {
      history.push(`${pathname}?step=${recipientsStep}`, {
        ...locationState,
      })
    }
  }

  const refreshEmailIframe = () => {
    setEmailIframeKey(randomId())
  }

  useEffect(() => {
    if (step === sendStep && emailUpdate) getReportRecipients()
  }, [emailUpdate, sendStep, step])

  return {
    emailUpdate,
    recipients,
    isFounderVerified,
    emailIframeKey,
    step,
    buildStep,
    recipientsStep,
    sendStep,
    errors,
    displayBtnText,
    handleClickNext,
    handleClickPrevious,
    refreshEmailIframe,
    invalidateEmailQuery,
  }
}

export default useCreateEmailForm
