import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEmojiContext, useMessageContext } from 'stream-chat-react'

import Tooltip from 'components/Tooltip'
import TooltipContent from 'containers/Chat/components/TooltipContent'
import { formatStringList } from 'utils/functions/utils'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'

import { Count, Emoji, Item, List, ReactIcon } from './ReactionsList.styles'
import { useProcessReactions } from './useProcessReactions'
import ReactionSelector from '../ReactionSelector'

const MAX_TOOLTIP_DISPLAY = 3

const getPosition = (
  isMyMessage: boolean,
  isInATreadList: boolean,
  isMobile: boolean
) => {
  if (isMobile && !isMyMessage) {
    return { left: '1.4rem', bottom: '-1.4rem' }
  }

  if (isMyMessage) {
    return { right: '0rem', bottom: '-4.4rem' }
  }

  if (isInATreadList) {
    return { left: '4.4rem', bottom: '-1.4rem' }
  }

  return { left: '0.2rem', bottom: '-0.6rem' }
}

const ReactionsList = () => {
  const intl = useIntl()
  const { emojiConfig } = useEmojiContext('SimpleReactionsList')
  const { isMyMessage, threadList, message } = useMessageContext()
  const { matches: isMobile } = useMediaQuery(maxSize.xsm)
  const {
    latestReactionTypes,
    reactionCounts,
    showDetailedReactions,
    reactionSelectorRef,
    getUsersPerReactionType,
    getEmojiByReactionType,
    iHaveReactedWithReaction,
    openDetailedReactions,
    handleReaction,
  } = useProcessReactions({ emojiConfig })

  const position = getPosition(isMyMessage(), !!threadList, isMobile)

  if (!isMyMessage()) {
    latestReactionTypes.reverse()
  }

  const isRelativePosition = () => {
    if (isMobile) {
      return isMyMessage()
    }

    return isMyMessage() || !threadList
  }

  return (
    <div>
      <List>
        {latestReactionTypes.map((reactionType, i) => {
          const emojiObject = getEmojiByReactionType(reactionType)
          const isOwnReaction = !!iHaveReactedWithReaction(reactionType)
          const tooltipContent = formatStringList(
            getUsersPerReactionType(reactionType),
            MAX_TOOLTIP_DISPLAY,
            intl
          )
          const tooltipText =
            tooltipContent.charAt(0).toUpperCase() + tooltipContent.slice(1)

          const key = `reaction-${reactionType}-${i}-${message.id}`
          const reactionCount = reactionCounts[reactionType]
          const showCount = reactionCount > 1

          return (
            emojiObject && (
              <Tooltip
                key={key}
                id={key}
                text={<TooltipContent>{tooltipText}</TooltipContent>}
                backgroundColor="transparent"
                place="top"
                offset={-10}
                delayShow={200}
              >
                <Item
                  key={key}
                  onClick={(event) => handleReaction(reactionType, event)}
                  onKeyUp={(event) => handleReaction(reactionType, event)}
                  isOwnReaction={!!isOwnReaction}
                >
                  <Emoji>{emojiObject.native}</Emoji>
                  {showCount && (
                    <Count isOwnReaction={!!isOwnReaction}>
                      {reactionCount}
                    </Count>
                  )}
                </Item>
              </Tooltip>
            )
          )
        })}
        <ReactIcon
          selected={showDetailedReactions}
          onClick={openDetailedReactions}
        >
          <FontAwesomeIcon icon={['far', 'smile']} />
        </ReactIcon>

        <ReactionSelector
          isVisible={showDetailedReactions}
          handleReaction={handleReaction}
          position={position}
          ref={reactionSelectorRef}
          isRelative={isRelativePosition()}
        />
      </List>
    </div>
  )
}

export default ReactionsList
