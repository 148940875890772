import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import type { Color } from './LogIcon'

export const IconBase = styled.div<{ iconColor: Color; small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ small }) => (small ? '1' : '1.2')}rem;
  border-radius: 50%;
  border-width: 1px;
  border-color: ${color('veryLightGray')};
  background-color: ${({ iconColor }) => iconColor};
  height: 2rem;
  width: 2rem;
  position: absolute;
  right: -0.8rem;
  bottom: -0.6rem;
`
