import { assertUnreachable } from 'utils/functions/utils'
import {
  InvestmentVehicleLogsBuilder,
  InvestmentVehicleLogChangeSetFields,
} from './InvestmentVehicleLogsBuilder'
import {
  InvestorLogChangeSetFields,
  InvestorLogsBuilder,
} from './InvestorLogsBuilder'
import { ChangeSetFieldsType } from './LogsBuilder'
import { Log, LogType } from '../types'
import {
  DocumentLogsBuilder,
  AnnouncementLogsBuilder,
  AccountingLogsBuilder,
  NoteLogsBuilder,
  TransactionLogsBuilder,
} from './CustomUpdateLogsBuilders'

import {
  PortfolioLogChangeSetFields,
  PortfolioLogsBuilder,
} from './PortfolioLogsBuilder'
import {
  UpdateLogChangeSetFields,
  UpdateLogsBuilder,
} from './UpdateLogsBuilder'

type LogFactoryType =
  | InvestorLogsBuilder
  | InvestmentVehicleLogsBuilder
  | PortfolioLogsBuilder
  | UpdateLogsBuilder

export class LogsBuilderFactory {
  static createLogsBuilder<T extends ChangeSetFieldsType>(
    log: Log<T>,
    logType: LogType,
    factoryParams: any
  ): LogFactoryType {
    switch (logType) {
      case LogType.INVESTORS:
        return new InvestorLogsBuilder(
          log as unknown as Log<InvestorLogChangeSetFields>
        )
      case LogType.VEHICLE:
        return new InvestmentVehicleLogsBuilder(
          log as unknown as Log<InvestmentVehicleLogChangeSetFields>
        )
      case LogType.PIPELINE_PORTFOLIOS:
      case LogType.INVEST_PORTFOLIOS:
      case LogType.FUND_PORTFOLIOS:
      case LogType.DEAL_PORTFOLIOS:
        return new PortfolioLogsBuilder(
          log as unknown as Log<PortfolioLogChangeSetFields>,
          logType
        )
      case LogType.NOTES:
        return new NoteLogsBuilder(
          log as unknown as Log<UpdateLogChangeSetFields>,
          factoryParams
        )
      case LogType.DOCUMENTS:
        return new DocumentLogsBuilder(
          log as unknown as Log<UpdateLogChangeSetFields>,
          factoryParams
        )
      case LogType.ANNOUNCEMENTS:
        return new AnnouncementLogsBuilder(
          log as unknown as Log<UpdateLogChangeSetFields>,
          factoryParams
        )
      case LogType.TRANSACTIONS:
        return new TransactionLogsBuilder(
          log as unknown as Log<UpdateLogChangeSetFields>,
          factoryParams
        )
      case LogType.QUICKBOOKS_REPORTS:
      case LogType.XERO_REPORTS:
        return new AccountingLogsBuilder(
          log as unknown as Log<UpdateLogChangeSetFields>
        )
      default:
        return assertUnreachable(logType)
    }
  }
}
