/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import { ReactComponent as PoweredByLogo } from 'assets/images/powered-by-logo.svg'
import { PRIVACY_POLICY_LINK } from 'utils/constants/links'

import styles from './Copyright.module.scss'

import packageJson from '../../../package.json'

const Copyright = ({ isCustomLogin }) => {
  const intl = useIntl()
  return (
    <span
      className={classNames(styles.copyright, {
        [styles.copyrightCustomLogin]: isCustomLogin,
      })}
    >
      {isCustomLogin && (
        <>
          <a href="https://www.clockwork.app/">
            <PoweredByLogo />
          </a>
          <div className={styles.links}>
            <a
              href={PRIVACY_POLICY_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.messages['privacyPolicy.legend']}
            </a>
            <span>|</span>
            <a onClick={() => window.Intercom('show')}>
              {intl.messages['sidebar.needHelp']}
            </a>
          </div>
        </>
      )}
      <FormattedMessage
        id="login.copyright"
        values={{
          version: packageJson.version,
          year: new Date().getFullYear(),
        }}
      />
    </span>
  )
}

Copyright.propTypes = {
  isCustomLogin: PropTypes.bool,
}

Copyright.defaultProps = {
  isCustomLogin: false,
}
export default Copyright
