const shareFundSettings = {
  title: 'Share Settings',
  inAppSearch: 'IN- APP SEARCH',
  showInHoldingsSearch: 'Show in holdings search',
  showInHoldingDescription: 'Toggle OFF to hide the fund from in-app search.',
  shareWithInvestors: 'Share with investors',
  shareWithAll: 'Share the same data with all',
  customizeByInvestor: 'Customize by investor',
  seeAllData: 'See All Data',
  custom: 'Custom',
  customizationSettings: 'Customization Settings',
  selectDataToShare:
    'Select the data you want to share with {investorName} about this fund:',
  saveSetting: 'Save Setting',
  fetchFundSettingsError:
    'An error occurred when trying to fetch the share settings, try again later.',
  fetchFundSettingError:
    'An error occurred when trying to fetch the share setting, try again later.',
  capitalStatement: 'Capital Statement Section',
  portfolio: 'Portfolio summary section',
  investmentHolding: 'Investment Holdings section',
  addName: 'Add name',
  saveItToUseLater: 'Save it to use it for another Investor',
  saveCustomizationSetting: 'Save Customization Setting',

  capitalStatementTotalCommitment: 'Total Commitment',
  capitalStatementTotalCommitmentDescription:
    'The total capital that investors have committed to the fund.',
  capitalStatementInvestedCapital: 'Invested Capital',
  capitalStatementInvestedCapitalDescription:
    'The total capital invested into the fund by the investors.',
  capitalStatementUnfundedCommitment: 'Unfunded Commitment',
  capitalStatementUnfundedCommitmentDescription:
    'The remaining capital committed but not yet called from the investors.',
  capitalStatementDistributions: 'Distributions',
  capitalStatementDistributionsDescription:
    'The total capital returned from a fund to the investors.',
  capitalStatementProRataHoldingValues: 'Pro-Rata Holding Value',
  capitalStatementProRataHoldingValuesDescription:
    'The proportionate holding value of the fund to each investor, defined as the aggregate holding value of the fund multiplied by the percentage of total capital contributed by an individual investor. Note: This value represents an estimate and could exclude performance fees, applicable taxes, and other adjustments by the fund manager.',

  portfolioTotalInvested: 'Total Invested',
  portfolioTotalInvestedDescription:
    'The aggregate cost of all investments made to date.',
  portfolioTotalFairMarketValue: 'Total Carrying Value',
  portfolioTotalFairMarketValueDescription:
    'The aggregate carrying (unrealized) value of actively held investments.',
  portfolioTotalDistributed: 'Total Distributed',
  portfolioTotalDistributedDescription:
    'The total capital returned (realized) to the fund by investments to date.',
  portfolioTotalValue: 'Total Value',
  portfolioTotalValueDescription:
    'The aggregate value generated to the fund by all investments made to date. The Total Value is equal to Total FMV + Total Distributed.',
  portfolioTotalUnfundedCommitment: 'Total Unfunded Commitment',
  portfolioTotalUnfundedCommitmentDescription:
    'The aggregate value of any outstanding prior commitments made by the fund to portfolio holdings.',
  portfolioTotalHolding: 'Total Holdings',
  portfolioTotalHoldingDescription:
    'The aggregate number of investments made into unique companies, funds, or other holdings.',
  portfolioTotalInvestments: 'Total Investments',
  portfolioTotalInvestmentsDescription:
    'The aggregate number of unique investments made into companies, funds, or other holdings.',
  portfolioAvgInvestmentPerHolding: 'Avg. Investment per Holding',
  portfolioAvgInvestmentPerHoldingDescription:
    'The average invested capital into each unique company, fund, or other holding.',
  portfolioFirstInvestment: 'First Investment',
  portfolioFirstInvestmentDescription:
    'The year in which the portfolio’s first investment was made. Typically referred to as the “Vintage Year” of the portfolio.',
  portfolioDistributionToPaidIn: 'DPI',
  portfolioDistributionToPaidInDescription:
    'Distributions to Paid-In Capital (DPI) represents the ratio of capital returned to capital invested. It is calculated by dividing Total Distributions by Total Invested.',
  portfolioMultipleOnInvestedCapital: 'MOIC',
  portfolioMultipleOnInvestedCapitalDescription:
    'Multiple on Invested Capital (MOIC) represents the ratio of value to capital invested. It is calculated by dividing Total Value by Total Invested.',
  portfolioIrr: 'IRR',
  portfolioIrrDescription:
    'Internal Rate of Return (IRR) is an annualized time-weighted return measure used to estimate the profitability of investment activity. The IRR calculation uses both realized and unrealized gains, with the assumption that unrealized gains are returned as of the day of calculation.',
  portfolioHoldingPieChart: 'Holdings Pie Chart',

  investmentHoldingFirstInvestmentDate: 'First Investment Date',
  investmentHoldingFirstInvestmentDateDescription:
    'The month and year in which the first investment was made.',
  investmentHoldingTotalInvestments: 'Total Investments',
  investmentHoldingTotalInvestmentsDescription:
    'The total number of unique investments made into the holding.',
  investmentHoldingUnfundedCommitment: 'Unfunded Commitment',
  investmentHoldingUnfundedCommitmentDescription:
    'The aggregate value of any outstanding prior commitments made by the fund to the holding.',
  investmentHoldingAmountInvested: 'Amount Invested',
  investmentHoldingAmountInvestedDescription:
    'The aggregate cost of all investments made to date.',
  investmentHoldingFmv: 'Carrying Value',
  investmentHoldingFmvDescription:
    'The aggregate holding (unrealized) value of actively held investments.',
  investmentHoldingLastCarryingValueUpdate: 'Last Carrying Value Update',
  investmentHoldingLastCarryingValueUpdateDescription:
    'Indicates the date when the Carrying Value was last updated.',
  investmentHoldingAmountDistributed: 'Amount Distributed',
  investmentHoldingAmountDistributedDescription:
    'The total capital returned (realized) by investments to date.',
  investmentHoldingTotalValue: 'Total Value',
  investmentHoldingTotalValueDescription:
    'The aggregate value generated by all investments made to date. The Total Value is equal to Carrying Value + Amount Distributed.',
  investmentHoldingMultipleOnInvestedCapital: 'MOIC',
  investmentHoldingMultipleOnInvestedCapitalDescription:
    'Multiple on Invested Capital (MOIC) represents the ratio of value to capital invested. It is calculated by dividing the sum of Amount Distributed and Carrying Value by Amount Invested.',
  investmentHoldingIrr: 'IRR',
  investmentHoldingIrrDescription:
    'The internal rate of return (IRR) is an annualized time-weighted return metric used to show the profitability of potential investments.',
  investmentHoldingPercentageOfInvested: '% of Invested',
  investmentHoldingPercentageOfInvestedDescription:
    'The ratio of Amount Invested into each entity relative to the Total Invested.',
}

export default shareFundSettings
