import React from 'react'
import { useField } from 'components/Form/hooks'
import UICheckbox from 'components/Checkbox'

interface CheckboxProps {
  id: string
  name: string
  label: string
  [x: string]: any
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  ...rest
}: CheckboxProps) => {
  const [fields] = useField(name)

  return (
    <UICheckbox
      name={name}
      onChange={fields.onChange}
      checked={fields.value}
      {...rest}
    />
  )
}

export default Checkbox
