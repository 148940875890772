import theme from 'utils/theme'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ButtonWrapper } from '../UpdateActions.styles'
import TooltipActionWrapper from '../Components/TooltipActionWrapper/TooltipActionWrapper'

interface Props {
  handleAddMoreRecipients: (value: boolean) => void
}

const MoreRecipientsAction = ({ handleAddMoreRecipients }: Props) => {
  const intl = useIntl()

  return (
    <TooltipActionWrapper
      id="more-recipients-email-update-action"
      text={intl.formatMessage({ id: 'updateActions.addMoreRecipients' })}
    >
      <ButtonWrapper transparent onClick={handleAddMoreRecipients}>
        <FontAwesomeIcon
          color={theme.colors.darkBlue}
          icon={['fal', 'user-plus']}
        />
      </ButtonWrapper>
    </TooltipActionWrapper>
  )
}

export default MoreRecipientsAction
