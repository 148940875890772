import styled from 'styled-components'
import Modal from 'components/Modal'

export const Body = styled(Modal.Body)`
  width: 50rem;
  padding: 2.4rem 3.2rem;
`

export const Footer = styled(Modal.Footer)`
  justify-content: flex-end;
  gap: 0.8rem;
  padding: 1.2rem 3.2rem 3.2rem 3.2rem;
  border: initial;
`
