import type {
  CellTemplate,
  Compatible,
  EventHandlers,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid'
import {
  getCharFromKeyCode,
  isAlphaNumericKey,
  keyCodes,
} from '@silevis/reactgrid'
import { ReactNode, MutableRefObject } from 'react'
import StaticSpreadsheetDropdown from './components/StaticSpreadsheetDropdown/StaticSpreadsheetDropdown'
import { CellErrorTooltipColor } from './components/CellErrorTooltip/CellErrorTooltip'
import { CustomCell } from '../types'
import { DisabledCell } from './Template.styles'

export const INVALID_OPTION_ID = 'INVALID_OPTION_ID'

export type Option<T = any> = {
  id: string
  label: string
  value: T
}

export interface StaticDropdownCell<T = any> extends CustomCell {
  type: 'staticDropdown'
  options: Option<T>[]
  option?: Option<T>
  initialChar?: string
  placeholder?: string
  overrideWidth?: string
  overrideTooltipBackground?: CellErrorTooltipColor
}

export class CustomStaticDropdownCellTemplate
  implements CellTemplate<StaticDropdownCell>
{
  eventHandler: MutableRefObject<EventHandlers | undefined>

  constructor(eventHandler: MutableRefObject<EventHandlers | undefined>) {
    this.eventHandler = eventHandler
  }

  // eslint-disable-next-line class-methods-use-this
  getCompatibleCell(
    uncertainCell: Uncertain<StaticDropdownCell>
  ): Compatible<StaticDropdownCell> {
    const { option, options = [] } = uncertainCell

    const text = option?.label || ''
    return {
      ...uncertainCell,
      option,
      options,
      text,
      value: NaN,
      rowIndex: uncertainCell.rowIndex || 0,
    }
  }

  update(
    cell: Compatible<StaticDropdownCell>,
    cellToMerge: UncertainCompatible<StaticDropdownCell>
  ): Compatible<StaticDropdownCell> {
    let { option } = cellToMerge
    const { text: optionName } = cellToMerge

    if (optionName) {
      option = cell.options.find(
        (opt) => opt.label.toLowerCase() === optionName.toLowerCase()
      )

      if (!option) {
        return this.getCompatibleCell({
          ...cell,
          error: cellToMerge.disabled
            ? ''
            : 'spreadsheet.transactions.invalidOption',
          option: {
            id: INVALID_OPTION_ID,
            label: optionName,
            value: INVALID_OPTION_ID,
          },
        })
      }
    }

    return this.getCompatibleCell({
      ...cell,
      error: '',
      option,
    })
  }

  // eslint-disable-next-line class-methods-use-this
  handleKeyDown(
    cell: Compatible<StaticDropdownCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ): { cell: Compatible<StaticDropdownCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift)
    if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    ) {
      return {
        cell: {
          ...cell,
          initialChar: char,
        },
        enableEditMode: true,
      }
    }
    const isOpen = keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER

    return {
      cell,
      enableEditMode: isOpen,
    }
  }

  render(
    cell: Compatible<StaticDropdownCell>,
    isInEditMode: boolean,
    onCellChanged: (
      cell: Compatible<StaticDropdownCell>,
      commit: boolean
    ) => void
  ): ReactNode {
    if (cell.disabled) {
      return <DisabledCell>-</DisabledCell>
    }

    return (
      <StaticSpreadsheetDropdown
        cell={cell}
        options={cell.options}
        onCellChanged={onCellChanged}
        isInEditMode={isInEditMode}
        getCompatibleCell={this.getCompatibleCell}
        eventHandler={this.eventHandler}
      />
    )
  }
}
