/* eslint-disable no-param-reassign */
import Types from 'actions/types'
import createReducer from 'reducers/createReducer'

export interface PortfolioSettings {
  isInvestorCalculationDisabled: boolean
}

export interface PortfolioState {
  currentPortfolio: {}
  portfolios: []
  settings: {
    [portfolioId: string]: PortfolioSettings
  }
}

const initialState = {
  currentPortfolio: {},
  portfolios: [],
  settings: {},
}

const setCurrentPortfolio = (draftState, action) => {
  draftState.currentPortfolio = action
  return draftState
}

const setPortfolios = (draftState, action) => {
  draftState.portfolios = action
  return draftState
}

const setInvestorCalculation = (
  draftState: PortfolioState,
  payload: { portfolioId: string; isEnabled: boolean }
) => {
  const { portfolioId } = payload

  if (!draftState.settings) {
    draftState.settings = {}
  }

  draftState.settings[portfolioId] = {
    isInvestorCalculationDisabled: !payload.isEnabled,
  }
}

const handlers = {
  [Types.SET_CURRENT_PORTFOLIO]: setCurrentPortfolio,
  [Types.SET_PORTFOLIOS]: setPortfolios,
  [Types.SET_INVESTOR_CALCULATION]: setInvestorCalculation,
}

export default createReducer(initialState, handlers)
