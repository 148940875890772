import { isEnterKeyCode } from 'utils/functions/keyboardEvents'
import { SortDirection } from 'utils/constants/sortDirection'
import Tooltip from 'components/Tooltip'
import {
  ColHeaderContainer,
  SortIcon,
  LabelContainer,
  Label,
  TooltipContainer,
} from './ColHeader.styles'
import { Column, DataType } from '../types'
import { useTableContext } from '../TableContextProvider'

function ColHeader<RowDataType extends DataType>({
  column,
}: {
  column: Column<RowDataType>
}) {
  const { sortHelpers } = useTableContext()
  const activeSort = !!column.sortKey && column.sortKey === sortHelpers.sortBy

  let props = {}
  if (!column.sortDisabled) {
    props = {
      role: 'button',
      tabIndex: 0,
      onKeyDown: (event) => {
        if (isEnterKeyCode(event)) {
          sortHelpers.onChangeSortBy(column.sortKey, null)
        }
      },
      onClick: () => sortHelpers.onChangeSortBy(column.sortKey, null),
    }
  }

  const label = (
    <>
      <Label activeSort={activeSort} className="table-header-label">
        {column.label}
      </Label>
      {!column.sortDisabled && activeSort && (
        <SortIcon
          icon={[
            'fas',
            sortHelpers.sortDirection === SortDirection.ASC
              ? 'caret-up'
              : 'caret-down',
          ]}
        />
      )}
    </>
  )

  return (
    <ColHeaderContainer
      data-testid={`table-column-${column.sortKey}`}
      {...props}
      column={column}
    >
      <LabelContainer className="table-header-label-container">
        {column.tooltip ? (
          <TooltipContainer activeSort={activeSort}>
            <Tooltip
              id={column.id}
              text={column.tooltip}
              place="top"
              delayShow={500}
            >
              <div>{label}</div>
            </Tooltip>
          </TooltipContainer>
        ) : (
          label
        )}
      </LabelContainer>
    </ColHeaderContainer>
  )
}

export default ColHeader
