import Logo from 'components/Logo'
import { ProfileType } from '../types'
import {
  Header,
  MainContent,
  SideContent,
  StyledLayout,
  LogoWrapper,
} from './Layout.styles'

const Layout = ({ children }) => {
  return <StyledLayout>{children}</StyledLayout>
}

export interface HeaderProps {
  type: ProfileType
  children?: React.ReactNode
}
Layout.Header = ({ children, type }: HeaderProps) => (
  <Header type={type}>
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
    {children}
  </Header>
)

Layout.SideContent = ({ children }) => <SideContent>{children}</SideContent>
Layout.MainContent = ({ children }) => <MainContent>{children}</MainContent>

export default Layout
