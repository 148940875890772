import { FileContent } from './files'

export enum FileUploadStatus {
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  UPLOADING = 'UPLOADING',
  ERROR = 'ERROR',
}

export interface Upload {
  id: string
  file: File
  status: FileUploadStatus
  uploadStartDate: Date
  uploadProgress?: number
  uploadTotal?: number
  uploadTimeLeft?: number
  abortController: AbortController
  fileUploadContextId: string
  error?: string
}

export interface FinishedUpload extends Upload {
  fileContent: FileContent
}

export const isUploadFinished = (upload: Upload): upload is FinishedUpload => {
  return upload.status === FileUploadStatus.FINISHED
}
