import { useMemo } from 'react'
import { Helmet } from 'components/Helmet'
import { FormattedMessage, useIntl } from 'react-intl'

import ConfirmationModal from 'components/ConfirmationModal'
import FeedList from 'components/UpdatesFeedV2/components/FeedList'
import {
  UpdatesFeedContext,
  UpdatesFeedContextType,
} from 'components/UpdatesFeedV2/UpdateFeedContext'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import Button from 'ui/Button'
import ZeroStateLinks from 'utils/constants/zerostatelinks'
import { UpdateEntityType } from 'components/UpdatesFeedV2'
import CWLoader from 'components/CWLoader/CWLoader'

import InboxNavigation from './components/InboxNavigation/InboxNavigation'
import SelectionButtons from './components/SelectionButtons'
import {
  ColumnsLayout,
  ComposeEmailWrapper,
  EmailListContainer,
  EmailTimeline,
  LoaderWrapper,
} from './EmailList.styles'
import { MailboxType, useEmailList } from './useEmailList'

const EmailList = () => {
  const intl = useIntl()
  const {
    feedProps,
    totalEmails,
    totalDrafts,
    totalSchedules,
    infiniteScrollRef,

    createEmailLoading,
    onCreateEmail,
    handleMailboxTypeChange,
    openConfirmationModal,
    openDeleteMultipleDraftsModal,
    isDeletingDrafts,
    onShowEmail,
    onEditEmail,
    currentMailboxType,
    setModal,
    modal,
    showZeroState,
    initialLoading,
    scrollRef,
  } = useEmailList()

  const getZeroStateType = () => {
    if (currentMailboxType === MailboxType.SENT) {
      return ZeroStateType.EMAILS
    }

    if (currentMailboxType === MailboxType.DRAFT) {
      return ZeroStateType.DRAFT_EMAILS
    }

    return ZeroStateType.SCHEDULED_EMAILS
  }

  const contextValue = useMemo<UpdatesFeedContextType>(
    () => ({
      onShowUpdate: onShowEmail,
      onEditUpdate: onEditEmail,
      onDeleteUpdate: openConfirmationModal,
      reshareDisabled: true,
      entityType: UpdateEntityType.EMAILS,
    }),
    [onEditEmail, onShowEmail, openConfirmationModal]
  )

  if (initialLoading) {
    return (
      <LoaderWrapper>
        <CWLoader text={intl.formatMessage({ id: 'general.loadingEmails' })} />
      </LoaderWrapper>
    )
  }
  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: 'metaTitles.emails' })}</title>
      </Helmet>
      <EmailListContainer>
        <FeedList.SelectUpdatesProvider
          enabled={currentMailboxType !== MailboxType.SENT}
        >
          <ComposeEmailWrapper>
            <Button
              primary
              onClick={onCreateEmail}
              disabled={createEmailLoading}
              uppercase
              add
            >
              <FormattedMessage id="emailList.composeNewEmail" />
            </Button>
            <SelectionButtons
              updates={feedProps.updates}
              currentMailboxType={currentMailboxType}
              onOpenDeleteDraftsConfirmationModal={
                openDeleteMultipleDraftsModal
              }
              isDeletingDrafts={isDeletingDrafts}
            />
          </ComposeEmailWrapper>

          {showZeroState ? (
            <ZeroState
              type={ZeroStateType.EMAILS}
              isChatWithSupportEnabled
              linkToGuide={ZeroStateLinks.EMAILS}
              maxWidth="48rem"
            />
          ) : (
            <ColumnsLayout>
              <InboxNavigation
                currentMailboxType={currentMailboxType}
                handleMailboxTypeChange={handleMailboxTypeChange}
                totalEmails={totalEmails}
                totalDrafts={totalDrafts}
                totalSchedules={totalSchedules}
              />

              <EmailTimeline
                transparent={feedProps.showZeroState}
                isLoading={feedProps.isLoading}
              >
                <UpdatesFeedContext.Provider value={contextValue}>
                  <FeedList
                    {...feedProps}
                    scrollRef={scrollRef}
                    infiniteScrollRef={infiniteScrollRef}
                    loadingText={intl.formatMessage({
                      id: 'general.loadingEmails',
                    })}
                    customZeroState={
                      <ZeroState
                        type={getZeroStateType()}
                        isChatWithSupportEnabled
                        linkToGuide={ZeroStateLinks.EMAILS}
                        maxWidth="48rem"
                      />
                    }
                  />
                </UpdatesFeedContext.Provider>
              </EmailTimeline>
            </ColumnsLayout>
          )}
        </FeedList.SelectUpdatesProvider>
        <ConfirmationModal
          title={modal.title}
          body={modal.body}
          onHide={() => setModal({ ...modal, isOpen: false })}
          onConfirm={modal.onConfirmCallback}
          isOpen={modal.isOpen}
          dangerConfirm
        />
      </EmailListContainer>
    </>
  )
}

export default EmailList
