import { SubjectMatterType } from 'api/UpdateService'
import { PortfolioType } from 'utils/types/portfolios'

export enum PortfolioTypes {
  TRACK = 'Track',
  INVEST = 'Invest',
  FUND = 'Fund',
  DEAL = 'Deal',
}

export type MixedPortfoliosType = 'mixed_portfolios'
export const MIXED_PORTFOLIOS = 'mixed_portfolios'

export enum PortfolioCompanyTypes {
  TRACK = 'Portfolios::TrackPortfolioCompany',
  INVEST = 'Portfolios::InvestPortfolioCompany',
  FUND = 'Portfolios::FundPortfolioCompany',
}

export const PORTFOLIO_ICONS = {
  [PortfolioTypes.TRACK]: ['fal', 'binoculars'],
  [PortfolioTypes.INVEST]: ['fal', 'dollar-sign'],
  [PortfolioTypes.FUND]: ['fal', 'sack-dollar'],
  [PortfolioTypes.DEAL]: ['fal', 'badge-dollar'],
}

const PortfolioApiUrl = {
  [PortfolioTypes.TRACK.toLocaleLowerCase()]: 'track_portfolios',
  [PortfolioTypes.INVEST.toLocaleLowerCase()]: 'invest_portfolios',
  [PortfolioTypes.FUND.toLocaleLowerCase()]: 'fund_portfolios',
  [PortfolioTypes.DEAL.toLocaleLowerCase()]: 'fund_portfolios',
  [MIXED_PORTFOLIOS]: MIXED_PORTFOLIOS,
} as const

export const getPortfolioApiUrl = (type) => {
  return PortfolioApiUrl[type.toLowerCase()]
}

export const getPortfolioParams = (type: PortfolioType, params) => {
  if (type === PortfolioTypes.FUND) {
    return { fundPortfolio: params }
  }

  if (type === PortfolioTypes.DEAL) {
    return { fundPortfolio: { ...params, type: 'Portfolios::DealPortfolio' } }
  }

  return { portfolio: params }
}

export const getPortfolioTypeFromSubjectMatterType = (
  type: SubjectMatterType
) => {
  switch (type) {
    case SubjectMatterType.TRACK_PORTFOLIO:
      return PortfolioTypes.TRACK
    case SubjectMatterType.INVEST_PORTFOLIO:
      return PortfolioTypes.INVEST
    case SubjectMatterType.FUND_PORTFOLIO:
      return PortfolioTypes.FUND
    default:
      return null
  }
}
