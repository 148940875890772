import { ChangeSetItem, EventType } from '../types'
import { InvestorCustomTextBuilder } from './InvestorCustomTextBuilder'

export class InvestmentVehicleLogTextBuilder extends InvestorCustomTextBuilder {
  private getCreatedText(name: string): React.ReactNode {
    return this.formatMessage('logs.hasCreatedInvestmentVehicle', { name })
  }

  public getLogText(
    event: EventType,
    fieldName: string,
    changeSetItem: ChangeSetItem,
    text: string
  ): React.ReactNode {
    if (event === EventType.CREATE) {
      return this.getCreatedText(text)
    }

    return super.getLogText(event, fieldName, changeSetItem, text)
  }
}
