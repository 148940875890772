import { FormattedMessage } from 'react-intl'

import { SuggestedUpdate } from 'utils/types/update'

import { useDiscardForm } from '../DiscardForm'
import { Button } from '../../SuggestedUpdateFeedCard.styles'
import {
  ActionsContainer,
  Container,
  DiscardForm,
} from './DiscardFeedCard.styles'

interface DiscardFeedCardProps {
  suggestedUpdate: SuggestedUpdate
  onCancel: () => void
}

const DiscardFeedCard = ({
  suggestedUpdate,
  onCancel,
}: DiscardFeedCardProps) => {
  const discardFormController = useDiscardForm({})
  const { isFormValid, isLoading, onSubmit } = discardFormController

  return (
    <Container>
      <DiscardForm
        suggestedUpdates={[suggestedUpdate]}
        discardFormController={discardFormController}
      />
      <ActionsContainer>
        <Button
          danger
          isLoading={isLoading}
          disabled={!isFormValid || isLoading}
          onClick={onSubmit}
        >
          <FormattedMessage id="general.discard" />
        </Button>
        <Button onClick={onCancel} link>
          <FormattedMessage id="general.cancel" />
        </Button>
      </ActionsContainer>
    </Container>
  )
}

export default DiscardFeedCard
