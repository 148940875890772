import { useEffect } from 'react'

import { getChatPopupIds, setChatLayoutContainer } from 'features/chatSlice'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { useResizeObserver } from 'utils/hooks/useResizeObserver'

export const useChatPopupLayout = () => {
  const channelIds = useAppSelector(getChatPopupIds)
  const screenSize = useResizeObserver('mainLayout')

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(setChatLayoutContainer(screenSize))
  }, [dispatch, screenSize])

  return {
    channelIds,
  }
}
