export default {
  description: 'Add holdings manually or paste them from a spreadsheet',
  downloadTemplate: 'Download Template',
  pipelineName: 'Pipeline Name',
  pipelinePlaceholder: 'Add pipeline name',
  noResults: 'No results',
  existingHoldingSuggestion:
    'We already have a holding with this name. Would you like to add this profile?',
  existingHoldingsSuggestion:
    'We already have holdings with this name. Would you like to add any of these profiles?',
  columns: {
    website: 'Website',
    websitePlaceholder: 'Add website',
    type: 'Type',
    typePlaceholder: 'Select type',
    name: 'Name',
    namePlaceholder: 'Add name',
    legalEntityName: 'Legal Entity Name',
    legalEntityNamePlaceholder: 'Add legal entity name',
    pointOfContact: 'Point of Contact',
    pointOfContactPlaceholder: 'Add point of contact',
    fundManagerName: 'Fund Manager Name',
    fundManagerNamePlaceholder: 'Add fund manager name',
  },
  errors: {
    mandatoryField: 'This is a mandatory field.',
    fundNameInUse:
      'This name is already being used. Would you like to add this profile?',
    fundNameInUsePlural:
      'This name is already being used. Would you like to add any of these profiles?',
    fundNameInUseHidden: 'This name is already being used. Please try another.',
    websiteInUse:
      'The website address is already being used. Would you like to add this profile?',
    websiteInUseFundManager:
      'The website address is already being used. Would you like to add the info from this fund organization?',
    websiteInUseHidden: 'The website address is already being used.',
    genericError:
      'An error occurred when importing the holdings, please review your spreadsheet and try again later.',
  },
}
