import React from 'react'
import ContentLoader from 'react-content-loader'

const ChipLoader = () => (
  <ContentLoader
    speed={1}
    height={123}
    viewBox="0 0 430 123"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    width={340}
  >
    <rect x="0" y="0" rx="15" ry="15" width="185" height="30" />
    <rect x="200" y="0" rx="15" ry="15" width="185" height="30" />
    <rect x="0" y="40" rx="15" ry="15" width="185" height="30" />
    <rect x="200" y="40" rx="15" ry="15" width="185" height="30" />
    <rect x="0" y="80" rx="15" ry="15" width="185" height="30" />
  </ContentLoader>
)

export default ChipLoader
