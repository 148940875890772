import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const UsedNameText = styled.div`
  font-size: 1.4rem;
  color: ${color('gray')};
`

export const PortfolioNameLink = styled.a`
  font-weight: 700;
  color: ${color('primaryBlue')};
  text-decoration: underline;
  cursor: pointer;
`
