import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import SubscriptionService from 'api/SubscriptionService'
import { getSubscriptionData } from 'selectors/subscriptions'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { subscriptionsKeys } from 'utils/queries/subscriptions'
import {
  Subscription,
  SubscriptionBillingPeriod,
  SubscriptionPlan,
} from 'utils/types/subscriptions'
import { CustomUseQueryOptions } from 'utils/types/common'
import { setStripeSubscription } from 'features/authSlice'
import { AuthHeaders } from '../../../utils/types/signUp'

export const useSubscriptionQuery = <T extends Subscription = Subscription>({
  subscriptionId,
  queryOptions = {},
  authHeaders,
}: {
  subscriptionId?: string
  authHeaders?: AuthHeaders
  queryOptions?: CustomUseQueryOptions<T>
} = {}) => {
  const subscription = useAppSelector(getSubscriptionData)

  const subscriptionIdToFetch =
    subscriptionId || subscription?.stripeSubscriptionId!

  return useQuery<T>(
    subscriptionsKeys.getSubscriptionData(subscriptionIdToFetch),
    () => {
      return SubscriptionService.getSubscriptionData<T>(
        subscriptionIdToFetch,
        authHeaders
      )
    },
    { ...queryOptions, enabled: !!subscriptionIdToFetch }
  )
}

interface Props {
  subscription: Subscription
  onError: (error) => void
  onSuccess: () => void
}

export const useUpdateSubscriptionPlanMutation = ({
  subscription,
  onError,
  onSuccess,
}: Props) => {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      plan,
      billingPeriod,
      subscriptionProrationDate,
    }: {
      plan: SubscriptionPlan
      billingPeriod: SubscriptionBillingPeriod
      subscriptionProrationDate: number
    }) => {
      return SubscriptionService.updateSubscriptionPlan(
        subscription.id,
        plan,
        billingPeriod,
        subscriptionProrationDate
      )
    },
    {
      onSuccess: () => {
        onSuccess()
        queryClient.invalidateQueries(
          subscriptionsKeys.getSubscriptionData(subscription.id)
        )
      },
      onError,
    }
  )
}

interface ReActivateSubscriptionProps {
  subscriptionId: string
  onError: (error) => void
  onSuccess: () => void
}

export const useReactivateSubscriptionMutation = ({
  subscriptionId,
  onError,
  onSuccess,
}: ReActivateSubscriptionProps) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  return useMutation(
    async (params: {
      plan: SubscriptionPlan
      billingPeriod: SubscriptionBillingPeriod
      prorationDate?: number
    }) => {
      const { stripeNextBillingDate, stripeStatus, stripeSubscriptionId } =
        await SubscriptionService.reactivateSubscription(subscriptionId, params)
      dispatch(
        setStripeSubscription({
          stripeSubscriptionId,
          stripeStatus,
          stripeNextBillingDate,
          stripePlan: params?.plan,
        })
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          subscriptionsKeys.getSubscriptionData(subscriptionId)
        )
        onSuccess()
      },
      onError,
    }
  )
}

export type CreateSubscriptionProps = {
  customerId: string
  onError: (error) => void
  onSuccess: () => void
}

export const useCreateSubscriptionMutation = ({
  customerId,
  onError,
  onSuccess,
}: CreateSubscriptionProps) => {
  const dispatch = useAppDispatch()
  return useMutation(
    async ({
      plan,
      billingPeriod,
      coupon,
    }: {
      plan: SubscriptionPlan
      billingPeriod: SubscriptionBillingPeriod
      coupon?: string
    }) => {
      const { stripeNextBillingDate, stripeStatus, stripeSubscriptionId } =
        await SubscriptionService.createCustomerSubscription(customerId, {
          plan,
          billingPeriod,
          coupon,
          trial: false,
        })
      dispatch(
        setStripeSubscription({
          stripeSubscriptionId,
          stripeStatus,
          stripeNextBillingDate,
          stripePlan: plan,
        })
      )
    },
    {
      onSuccess: () => {
        onSuccess()
      },
      onError,
    }
  )
}
