import { components } from 'react-select'
import { color } from 'utils/functions/colors'
import styled from 'styled-components'

export const OptionsTitle = styled.p`
  .react-select__group-heading {
    color: ${color('lightGray')};
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.2rem;
    padding: 1.1rem 0.8rem;
  }
`

export const GroupHeading = (props) => (
  <OptionsTitle>
    <components.GroupHeading {...props} />
  </OptionsTitle>
)
