interface CheckboxCheckMarkProps {
  width?: number
  height?: number
}

export const CheckboxCheckMark = ({
  width = 8,
  height = 6,
}: CheckboxCheckMarkProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.14672 0.0598897L2.54437 4.54236L0.853296 2.89534C0.771308 2.81548 0.638369 2.81548 0.556363 2.89534L0.0614915 3.37732C-0.0204972 3.45717 -0.0204972 3.58665 0.0614915 3.66652L2.39589 5.94011C2.47788 6.01996 2.61082 6.01996 2.69283 5.94011L7.93851 0.831069C8.0205 0.751216 8.0205 0.621741 7.93851 0.541871L7.44364 0.0598897C7.36165 -0.0199632 7.22871 -0.0199632 7.14672 0.0598897Z"
        fill="white"
      />
    </svg>
  )
}
