import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import type { IntlShape } from 'react-intl'
import type {
  StripePaymentElementChangeEvent,
  PaymentIntent,
  SetupIntent,
} from '@stripe/stripe-js'
import { useStripe, useElements } from '@stripe/react-stripe-js'

import { useSignupContext } from 'containers/Signup/context/SignupContext'

export const handlePaymentIntentResponse = (
  paymentIntent: PaymentIntent | SetupIntent | undefined,
  onSuccess: (paymentIntentId: string) => void,
  onError: (msg: string) => void,
  intl: IntlShape
) => {
  switch (paymentIntent?.status) {
    case 'succeeded':
    case 'processing':
      onSuccess(paymentIntent.id)
      break

    case 'requires_payment_method':
      onError(intl.formatMessage({ id: 'signup.payment.paymentFailed' }))
      break

    default:
      onError(intl.formatMessage({ id: 'signup.payment.somethingWentWrong' }))
      break
  }
}

export const usePaymentForm = (
  onPaymentSuccess: (paymentIntentId: string) => void,
  paymentErrorProp?: string
) => {
  const stripe = useStripe()
  const elements = useElements()
  const intl = useIntl()
  const [paymentElementLoading, setPaymentElementLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [formIsComplete, setFormIsComplete] = useState(false)
  const [paymentError, setPaymentError] = useState(paymentErrorProp)
  const {
    urlPrefix,
    investorSignup: { isTrial },
  } = useSignupContext()

  const handleSubmit = useCallback(
    async (event: MouseEvent) => {
      event.preventDefault()
      setIsLoading(true)

      let intent
      let error

      if (stripe && elements) {
        const returnUrl = `${process.env.REACT_APP_WEB_APP_URL}${urlPrefix}/payment-check`

        if (isTrial) {
          ;({ error, setupIntent: intent } = await stripe.confirmSetup({
            elements,
            confirmParams: {
              return_url: returnUrl,
            },
            redirect: 'if_required',
          }))
        } else {
          ;({ error, paymentIntent: intent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
              return_url: returnUrl,
            },
            redirect: 'if_required',
          }))
        }

        if (!error) {
          handlePaymentIntentResponse(
            intent,
            onPaymentSuccess,
            setPaymentError,
            intl
          )
        } else {
          setPaymentError(error.message)
        }
      }

      setIsLoading(false)
    },
    [stripe, elements, isTrial, onPaymentSuccess, intl, urlPrefix]
  )

  const onPaymentElementChange = useCallback(
    (event: StripePaymentElementChangeEvent) => {
      setFormIsComplete(event.complete)
    },
    []
  )

  const onPaymentElementReady = useCallback(() => {
    setPaymentElementLoading(false)
  }, [])

  return {
    handleSubmit,
    onPaymentElementReady,
    onPaymentElementChange,
    paymentElementLoading,
    formIsComplete,
    isLoading,
    paymentError,
  }
}
