import React from 'react'
import { Formik } from 'formik'
import type { FormikProps } from 'formik'
import { useIntl } from 'react-intl'
import Button from 'ui/Button'
import Drawer from 'components/Drawer'
import FadeIn from 'components/FadeIn/FadeIn'
import { getAddInvestorStepSchema } from 'utils/schemas/investorManagement'
import { AddInvestorStepFormValues } from 'utils/types/investors'
import AddInvestorForm from '../../InvestorForm/AddInvestorForm'

interface AddInvestorStepProps {
  initialValues: AddInvestorStepFormValues
  onSubmit: (values: AddInvestorStepFormValues) => void
  onCancel: () => void
}

const AddInvestorStep: React.ForwardRefRenderFunction<
  FormikProps<AddInvestorStepFormValues>,
  AddInvestorStepProps
> = ({ initialValues, onSubmit, onCancel }, ref) => {
  const intl = useIntl()

  return (
    <FadeIn>
      <Formik<AddInvestorStepFormValues>
        innerRef={ref}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={getAddInvestorStepSchema(intl)}
      >
        {(formik) => (
          <>
            <AddInvestorForm />
            <Drawer.Footer>
              <Button onClick={onCancel}>
                {intl.formatMessage({ id: 'general.cancel' })}
              </Button>
              <Button
                type="submit"
                primary
                onClick={formik.handleSubmit}
                isDisabled={!formik.values.name || !!formik.errors.name}
              >
                {intl.formatMessage({ id: 'general.next' })}
              </Button>
            </Drawer.Footer>
          </>
        )}
      </Formik>
    </FadeIn>
  )
}

export default React.forwardRef(AddInvestorStep)
