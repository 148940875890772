import styled from 'styled-components/macro'
import { UpdateTextContainer } from 'containers/UpdatesV2/ShowUpdateContent/UpdateText'

export const Header = styled.div`
  font-size: 6.25px;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 60vw;
  font-size: 1.4rem;

  ${UpdateTextContainer} {
    margin-top: 1.4rem;
    max-height: 60vh;
    overflow: auto;
  }
`

export const AvatarContainer = styled.div`
  position: absolute;
`

export const TitleAndMessage = styled.div`
  padding-left: 5rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  line-height: 17px;
  font-size: 1.4rem;
`

export const Title = styled.div`
  text-transform: uppercase;
  font-weight: 700;
  max-width: 56vw;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex: 1;
`
