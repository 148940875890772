import React, { useRef } from 'react'
import { useIntl } from 'react-intl'
import { FieldArray } from 'formik'
import type { ArrayHelpers } from 'formik'
import List from 'components/List'
import Input from 'ui/Input'
import Separator from 'ui/Separator'
import { buildFormError } from 'utils/functions/forms'
import { ErrorType, Nullable } from 'utils/types/common'
import EmailRow from './EmailRow'

interface EmailListProps<T> {
  name: string
  label?: Nullable<string>
  placeholder?: string
  handleAddEmail: (arrayHelpers: ArrayHelpers, newEmail: string) => void
  getRowLabel: (item: T) => string
  items: T[]
  error?: string
  mainAndSetAsMainActive?: boolean
  onDelete?: (item: T) => void
}

function EmailsList<T = string>({
  name,
  label,
  placeholder,
  handleAddEmail,
  getRowLabel,
  items,
  error,
  mainAndSetAsMainActive,
  onDelete,
}: EmailListProps<T>) {
  const intl = useIntl()
  const inputEmailRef = useRef<HTMLInputElement>(null)

  const onAddEmail =
    (arrayHelpers: ArrayHelpers) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail = event.target.value || inputEmailRef.current?.value

      if (!newEmail) return
      handleAddEmail(arrayHelpers, newEmail.toLowerCase())
      inputEmailRef.current!.value = ''
    }

  const onSetAsMain = (index: number, arrayHelpers: ArrayHelpers) => {
    arrayHelpers.swap(0, index)
  }

  const handleRemoveEmail = (index: number, arrayHelpers: ArrayHelpers) => {
    arrayHelpers.remove(index)
    onDelete?.(items[index])
  }

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          <Input
            ref={inputEmailRef}
            name="emails"
            onPressEnter={onAddEmail(arrayHelpers)}
            icon={['fal', 'plus']}
            iconFontSize="1.8rem"
            label={
              label ?? label !== null
                ? intl.formatMessage({
                    id: 'investorManagement.email',
                  })
                : undefined
            }
            placeholder={
              placeholder ??
              intl.formatMessage({
                id: 'investorManagement.addEmail',
              })
            }
            error={buildFormError(error, ErrorType.ERROR, true)}
            isOptional
          />
          {items.length > 0 && <Separator />}
          <List
            overflow="visible"
            removeMaxHeight
            rowWidthAuto
            rowHeightAuto
            striped={false}
            keyExtractor={(index) => items[index]}
            elements={items.map((item, index) => (
              <EmailRow
                email={getRowLabel(item)}
                mainAndSetAsMainActive={mainAndSetAsMainActive}
                main={index === 0}
                setAsMain={() => onSetAsMain(index, arrayHelpers)}
                onDelete={() => handleRemoveEmail(index, arrayHelpers)}
              />
            ))}
          />
        </>
      )}
    />
  )
}

export default EmailsList
