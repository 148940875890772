import React from 'react'
import PropTypes from 'prop-types'

import { BtnWrapper } from './BtnUploadFile.styles'

const BtnUploadFile = ({
  children,
  acceptFiles,
  onUploadFile,
  secondary,
  className,
}) => {
  return (
    <BtnWrapper className={className} secondary={secondary}>
      <input
        data-testid="upload-file-input"
        type="file"
        id="actual-btn"
        accept={acceptFiles.join(', ')}
        onChange={(e) => {
          onUploadFile(e)
          e.target.value = null
        }}
      />
      <label htmlFor="actual-btn">{children}</label>
    </BtnWrapper>
  )
}

BtnUploadFile.propTypes = {
  acceptFiles: PropTypes.array,
  onUploadFile: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  secondary: PropTypes.bool,
  className: PropTypes.string,
}

BtnUploadFile.defaultProps = {
  acceptFiles: [],
  className: '',
  secondary: false,
}

export default BtnUploadFile
