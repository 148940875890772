import { PlainSubjectMatters } from 'utils/constants/updates'
import { DraggableOption } from 'components/DragAndDropMenu/DragAndDropMenu'
import type { IntlShape } from 'react-intl'
import { Group, User } from './user'
import { ValueOf, UpdateType, Image } from './common'
import { SuggestedUpdateData, Tag } from './update'
import { Inbound } from './inbounds'
import { Holding, Sector } from './company'

export enum UserContentStatus {
  /** Contents published on updates within the group that have group_owned feature. */
  SHARED_EDITABLE = 'shared_editable',

  /** Contents shared from outside the group. This includes public update contents. */
  SHARED_UPDATE = 'shared_update',

  /** Contents not on updates published by users on the group that have the content_group_owned setting one. */
  GROUP_UNRECONCILED = 'group_unreconciled',
  /** Contents not on updates published by users on the group that have the content_group_owned setting on */
  USER_UPDATE = 'user_update',

  /** User’s unreconciled contents. */
  USER_UNRECONCILED = 'user_unreconciled',
}

export const OrderFilesCoTableColumns = {
  FILES_SIZE: 'filesSize',
  FILES_DATE: 'filesDate',
  FILES_TAGS: 'filesTags',
  FILES_ABOUT: 'filesAbout',
  FILES_SECTOR: 'filesSector',
  FILES_SHARED_BY: 'filesSharedBy',
} as const

export const OrderFilesIndexName = {
  FILES_SIZE_ORDER: 'filesSizeOrder',
  FILES__DATE_ORDER: 'filesDateOrder',
  FILES_TAGS_ORDER: 'filesTagsOrder',
  FILES_ABOUT_ORDER: 'filesAboutOrder',
  FILES_SECTOR_ORDER: 'filesSectorOrder',
  FILES_SHARED_BY_ORDER: 'filesSharedByOrder',
} as const

export interface FilesVisibleColumns {
  filesSize: boolean
  filesSizeOrder: number

  filesDate: boolean
  filesDateOrder: number

  filesTags: boolean
  filesTagsOrder: number

  filesAbout: boolean
  filesAboutOrder: number

  filesSector: boolean
  filesSectorOrder: number

  filesSharedBy: boolean
  filesSharedByOrder: number
}

export type TableIdNameType =
  typeof OrderFilesCoTableColumns[keyof typeof OrderFilesCoTableColumns]

export type OrderFileName =
  typeof OrderFilesIndexName[keyof typeof OrderFilesIndexName]

// TODO: internationalize the labels
export const INITIAL_COLUMNS_ORDER_STATE: DraggableOption[] = [
  {
    id: OrderFilesCoTableColumns.FILES_DATE,
    label: 'Date',
    status: true,
    order: {
      index: 1,
      name: OrderFilesIndexName.FILES__DATE_ORDER,
    },
  },
  {
    id: OrderFilesCoTableColumns.FILES_SIZE,
    label: 'Size',
    status: true,
    order: {
      index: 2,
      name: OrderFilesIndexName.FILES_SIZE_ORDER,
    },
  },
  {
    id: OrderFilesCoTableColumns.FILES_TAGS,
    label: 'Tags',
    status: true,
    order: {
      index: 3,
      name: OrderFilesIndexName.FILES_TAGS_ORDER,
    },
  },
  {
    id: OrderFilesCoTableColumns.FILES_ABOUT,
    label: 'About',
    status: true,
    order: {
      index: 4,
      name: OrderFilesIndexName.FILES_ABOUT_ORDER,
    },
  },
  {
    id: OrderFilesCoTableColumns.FILES_SECTOR,
    label: 'Sector',
    status: true,
    order: {
      index: 5,
      name: OrderFilesIndexName.FILES_SECTOR_ORDER,
    },
  },
  {
    id: OrderFilesCoTableColumns.FILES_SHARED_BY,
    label: 'Added/Shared By',
    status: true,
    order: {
      index: 6,
      name: OrderFilesIndexName.FILES_SHARED_BY_ORDER,
    },
  },
]

export const initialFilesTableColumnsOrder = ({
  intl,
}: {
  intl: IntlShape
}) => [
  {
    id: 'totalFiles',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfFiles',
    }),
    status: true,
    order: {
      index: 1,
      name: 'totalFilesOrder',
    },
  },
  {
    id: 'totalPdfs',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfPDFs',
    }),
    status: true,
    order: {
      index: 2,
      name: 'totalPdfsOrder',
    },
  },
  {
    id: 'totalImages',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfImages',
    }),
    status: true,
    order: {
      index: 3,
      name: 'totalImagesOrder',
    },
  },
  {
    id: 'totalDocuments',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfDocuments',
    }),
    status: true,
    order: {
      index: 4,
      name: 'totalDocumentsOrder',
    },
  },
  {
    id: 'totalSpreadsheets',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfSpreadsheets',
    }),
    status: true,
    order: {
      index: 5,
      name: 'totalSpreadsheetsOrder',
    },
  },
  {
    id: 'totalPresentations',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfPresentations',
    }),
    status: true,
    order: {
      index: 6,
      name: 'totalPresentationsOrder',
    },
  },
  {
    id: 'totalAudios',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfAudios',
    }),
    status: true,
    order: {
      index: 7,
      name: 'totalAudiosOrder',
    },
  },
  {
    id: 'totalVideos',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfVideos',
    }),
    status: true,
    order: {
      index: 8,
      name: 'totalVideosOrder',
    },
  },
  {
    id: 'totalCompressedFiles',
    label: intl.formatMessage({
      id: 'files.tableColumns.totalNumberOfCompressedFiles',
    }),
    status: true,
    order: {
      index: 9,
      name: 'totalCompressedFilesOrder',
    },
  },
]

export const getSortedFilesTableColumns = (
  isInvestor = false
): DraggableOption[] => {
  if (isInvestor) {
    return [
      ...INITIAL_COLUMNS_ORDER_STATE.filter(
        (column) =>
          column.id !== OrderFilesCoTableColumns.FILES_SHARED_BY &&
          column.id !== OrderFilesCoTableColumns.FILES_TAGS
      ),
      {
        id: OrderFilesCoTableColumns.FILES_SHARED_BY,
        label: 'Shared By',
        status: true,
        order: {
          index: 6,
          name: OrderFilesIndexName.FILES_SHARED_BY_ORDER,
        },
      },
    ]
  }

  return INITIAL_COLUMNS_ORDER_STATE
}

export const getContentAnalyticsFilesTableColumns = (): DraggableOption[] => {
  const tableColumns: string[] = [
    OrderFilesCoTableColumns.FILES_DATE,
    OrderFilesCoTableColumns.FILES_SIZE,
    OrderFilesCoTableColumns.FILES_TAGS,
    OrderFilesCoTableColumns.FILES_SHARED_BY,
  ]

  return getSortedFilesTableColumns().filter(
    (column) => tableColumns.indexOf(column.id) !== -1
  )
}

export enum FileExtension {
  PDF = 'pdf',
  IMAGE = 'image',
  PNG = 'png',
  JPG = 'jpg',
  GIF = 'gif',
  JPEG = 'jpeg',
  ZIP = 'zip',
  WAV = 'wav',
  MP3 = 'mp3',
  MOV = 'mov',
  MP4 = 'mp4',
  DOC = 'doc',
  DOCX = 'docx',
  MSWORD = 'msword',
  TXT = 'txt',
  UNKNOWN = 'unknown',
}

export interface MixedContent {
  id: string
  groupManaged: boolean
  createdAt: Date
  updatedAt: Date
}

export interface FileContent extends MixedContent {
  fileName: string
  url: string
  previewUrl?: string // this is the URL without Content-Disposition header
  thumbUrl?: string
  fileSize: number
  fileFormat: FileExtension
  metadata?: Record<string, any>
}

export interface EmailContent extends MixedContent {
  data: SuggestedUpdateData
  s3Contents: AttachedEmailFileContent[]
  discard: boolean
  linkedEmailAccount: Inbound
}

export interface AttachedEmailFileContent extends FileContent {
  unreconciled: boolean
}

export interface IndexEmailContent extends EmailContent {
  userContentStatus: UserContentStatus
  user?: ContentUserType
  group?: ContentGroupType
  groupManaged: boolean
}

export interface EmailContentDetails extends IndexEmailContent {
  unreconciled: boolean
  loggingUpdates: LoggingUpdateContent[]
}

export type ContentUserType = Pick<
  User,
  'id' | 'name' | 'image' | 'firstName' | 'lastName' | 'email'
>
export type ContentGroupType = Pick<Group, 'id' | 'name' | 'logo'>
export interface IndexFileContent extends FileContent {
  userContentStatus: UserContentStatus
  user?: ContentUserType
  group?: ContentGroupType
  holdings?: Holding[]
  sectors?: Sector[]
  tags?: Tag[]
}

export interface SubjectMatterInfo {
  id: string
  type: ValueOf<typeof PlainSubjectMatters>
  name: string
  logo: Image
}

export interface LoggingUpdateContent {
  id: string
  createdAt: Date
  updatedAt: Date
  title: string
  groupId: string
  type: UpdateType
  date: Date
  contents: MixedContent[]
  subjectMatterId: string
  subjectMatterType: ValueOf<typeof PlainSubjectMatters>
  subjectMatterInfo?: SubjectMatterInfo
}

export interface FileContentDetails extends IndexFileContent {
  loggingUpdates: LoggingUpdateContent[]
  unreconciled: boolean
}

export const OrderByProps = {
  NAME: 'name',
  SIZE: 'file_size',
  ADDED_DATE: 'created_at',
} as const

export type OrderByPropsType = ValueOf<typeof OrderByProps>

export const isFileFormatImage = (fileFormat: FileExtension): boolean => {
  return (
    fileFormat === FileExtension.JPEG ||
    fileFormat === FileExtension.PNG ||
    fileFormat === FileExtension.IMAGE ||
    fileFormat === FileExtension.JPG ||
    fileFormat === FileExtension.GIF
  )
}
