/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo } from 'react'
import InvestorManagementService from 'api/InvestorManagementService'
import { useIntl } from 'react-intl'
import { useField, useFormikContext } from 'components/Form/hooks'
import { useLocation } from 'react-router-dom'
import { UpdateRouteState } from 'utils/hooks/useShowUpdateRoutes'

const INVESTORS_PER_PAGE = 50000

export const useInvestorSelector = () => {
  const [{ onChange, ...field }, meta, helpers] = useField('investor')
  const [_, __, investorPermissionsHelper] = useField('permissions.investor')

  const formik = useFormikContext()
  const intl = useIntl()

  const location = useLocation<UpdateRouteState>()
  const locationState = location.state || {}
  const hasPrePopulatedInvestor = !!locationState.investorId

  const messages = useMemo(
    () => ({
      label: intl.formatMessage({
        id: 'investorManagement.createTransaction.fundManager.investor',
      }),
      placeholder: intl.formatMessage({
        id: 'investorManagement.createTransaction.fundManager.selectInvestor',
      }),
      searchPlaceholder: intl.formatMessage({ id: 'general.searchByName' }),
      error: intl.formatMessage({ id: 'portfolioDetail.companyWarning' }), // this should be different
    }),
    []
  )

  const loadInvestors = useCallback(async (inputSearch) => {
    const { results } = await InvestorManagementService.fetchInvestors({
      page: 1,
      name: inputSearch,
      orderBy: 'name',
      pageSize: INVESTORS_PER_PAGE,
    })

    return results
  }, [])

  const getInvestorOption = useCallback((investorData) => {
    return {
      id: investorData.id,
      label: investorData.name,
    }
  }, [])

  return {
    field,
    meta,
    helpers,
    investorPermissionsHelper,
    formik,
    messages,
    hasPrePopulatedInvestor,
    loadInvestors,
    getInvestorOption,
  }
}
