import { LogType } from 'containers/Logs/models/types'
import useShowUpdate from 'containers/UpdatesV2/ShowUpdateContent/useShowUpdate'
import { useMemo } from 'react'
import { BalanceDateType, PeriodType } from 'utils/constants/accounting'
import { Integrations } from 'utils/constants/integrations'
import { getPermissionsFromServerUpdate } from 'utils/functions/api/updates'
import { getBalanceDateFromTimeFrame } from 'utils/functions/xeroReports'
import { useUpdateAccountingMutation } from 'utils/hooks/queries/useUpdateQuery'
import { Nullable } from 'utils/types/common'
import { AccountingItem, AccountingUpdate } from 'utils/types/update'
import { AccountingFormValues } from 'utils/types/updateForm'
import { FIELD_NAMES } from '../constants/constants'

export const useShowAccounting = () => {
  const {
    updateType,
    isLoading,
    update,
    updateId,
    updatePermissions,
    resharesByToken,
    resharesWithMeOrMyGroup,
    entityName,
  } = useShowUpdate<AccountingUpdate>()

  const { mutate: editMutation } = useUpdateAccountingMutation({
    updateId,
  })

  const accounting = update?.item as AccountingItem
  const normalizedReportData = JSON.parse(accounting?.rawContent || '{}')

  const rawData =
    accounting?.integrationType === Integrations.XERO
      ? normalizedReportData?.reports?.[0]
      : normalizedReportData

  const eventLogType =
    accounting?.integrationType === Integrations.XERO
      ? LogType.XERO_REPORTS
      : LogType.QUICKBOOKS_REPORTS

  const accountingValues = useMemo((): Nullable<AccountingFormValues> => {
    if (isLoading || !update) return null

    const { title, reportType, reportData, repetition } = update.item

    const {
      paymentsOnly,
      periods,
      standardLayout,
      reportDatePeriod,
      timeFrame,
    } = reportData
    const files = update.contents
    const tags = update.tags.map((tag) => ({
      ...tag,
      alreadyExists: true,
    }))

    return {
      [FIELD_NAMES.title]: title,
      [FIELD_NAMES.date]: update.date,
      [FIELD_NAMES.periods]: String(periods) as PeriodType,
      [FIELD_NAMES.reportType]: reportType,
      [FIELD_NAMES.repetition]: repetition,
      [FIELD_NAMES.balanceDate]: getBalanceDateFromTimeFrame(
        timeFrame as BalanceDateType
      ),
      [FIELD_NAMES.reportDatePeriod]: reportDatePeriod,
      [FIELD_NAMES.standardLayout]: standardLayout,
      [FIELD_NAMES.paymentsOnly]: paymentsOnly,
      files,
      permissions: getPermissionsFromServerUpdate(update),
      updateType,
      tags,
      updateId: update.id,
      owner: update.user,
    }
  }, [isLoading, update, updateType])

  return {
    updateType,
    isLoading,
    update,
    updateId,
    updatePermissions,
    resharesByToken,
    resharesWithMeOrMyGroup,
    entityName,
    eventLogType,
    accountingValues,
    rawData,
    editMutation,
  }
}
