import { useCallback, useState } from 'react'
import { ReshareTokenSet } from 'utils/functions/updates'

const useReshareUpdateModal = () => {
  const [isReshareModalOpen, setReshareIsModalOpen] = useState(false)
  const [selectedReshareTokenSet, setSelectedReshareTokenSet] =
    useState<ReshareTokenSet>()

  const toggleReshareModal = useCallback(() => {
    setReshareIsModalOpen((open) => !open)
  }, [])

  const handleSetSelectedReshareTokenSet = useCallback(
    (tokenSet: ReshareTokenSet) => {
      setSelectedReshareTokenSet(tokenSet)
      toggleReshareModal()
    },
    [toggleReshareModal]
  )

  const handleCloseReshareModal = useCallback(() => {
    setSelectedReshareTokenSet(undefined)
    toggleReshareModal()
  }, [toggleReshareModal])

  return {
    selectedReshareTokenSet,
    isReshareModalOpen,
    toggleReshareModal,
    handleSetSelectedReshareTokenSet,
    handleCloseReshareModal,
  }
}

export default useReshareUpdateModal
