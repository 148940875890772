import { EventType } from '../types'
import { BackendEvent, BackendEventsMapper } from './BackendEventsMapper'
import { UpdateLogChangeSetFields } from '../logs-builders/UpdateLogsBuilder'

export class UpdateEventsMapper extends BackendEventsMapper<UpdateLogChangeSetFields> {
  getLogsFromEvent(event: BackendEvent<UpdateLogChangeSetFields>) {
    // BE is logging unique_views, which we don't want to show. They mostly come alone in the changeset,
    // and as an UPDATE event
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    delete event.changeset.uniqueViews

    if (
      event.event === EventType.UPDATE &&
      !Object.keys(event.changeset).length &&
      !event.text?.includes('legacy')
    ) {
      return []
    }

    return super.getLogsFromEvent(event)
  }
}
