import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'
import { Column } from '../types'
import { Cell } from '../Row/Row.styles'

export const ColHeaderContainer = styled(Cell)<{ column: Column }>`
  justify-content: ${(props) => props.column.justify || 'flex-start'};
  outline: none;

  ${(props) =>
    !props.column.sortDisabled &&
    css`
      cursor: pointer;
    `};
`

export const SortIcon = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  margin-left: 0.5rem;
  color: ${color('darkBlue')};
  svg {
    height: 1rem;
  }
`

export const LabelContainer = styled.span``
export const TooltipContainer = styled.span<{ activeSort?: boolean }>`
  svg {
    color: ${(props) =>
      color(props.activeSort ? 'darkBlue' : 'lightGray')(props)};
  }
`

export const Label = styled.span<{ activeSort?: boolean }>`
  color: ${(props) =>
    color(props.activeSort ? 'darkBlue' : 'lightGray')(props)};
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: capitalize;
`
