import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'
import PortfolioIconToStyle from 'components/PortfolioIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  max-width: 100%;
`

export const OptionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  max-width: calc(100% - 2.1rem);
`

export const OptionText = styled.div`
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: ${color('darkBlue')};
`

export const OptionDescription = styled.span`
  font-size: 1.2rem;
  line-height: 1.4rem;
  font-weight: 400;
  color: ${color('lightGray')};
`

export const PortfolioIcon = styled(PortfolioIconToStyle)`
  min-width: 2.1rem;
  min-height: 2.1rem;
  border: initial;
`

export const ClearSelectionIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
  pointer-events: initial;
`
