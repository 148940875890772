import React from 'react'

import { ReactComponent as CWLogo } from 'assets/images/logo-cw.svg'

import styles from './Logo.module.scss'

const Logo = () => (
  <div className={styles.logo}>
    <a href="/" className={styles.brand}>
      <CWLogo className={styles.logo} />
    </a>
  </div>
)

export default Logo
