import React from 'react'
import ReactDom from 'react-dom'
import PropTypes from 'prop-types'
import { useAppDispatch, useAppSelector } from 'utils/hooks/reduxToolkit'
import { FormattedMessage } from 'react-intl'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { activateServiceWorker } from 'actions/serviceWorker'

import { updateServiceWorker } from 'utils/serviceWorker'
import * as ToastStyles from 'components/Toast/Toast.styles'
import styles from './ServiceWorkerWrapper.module.scss'
import { getServiceWorker } from '../../selectors/serviceWorker'

const variants = {
  visible: {
    top: '2rem',
    transition: { duration: 0.5 },
  },
  hidden: {
    top: '-7rem',
    transition: { duration: 0.5 },
  },
}

const ServiceWorkerWrapper = ({ children }) => {
  const serviceWorker = useAppSelector(getServiceWorker)
  const dispatch = useAppDispatch()
  const toastPortal = document.getElementById('toast')

  return (
    <>
      {serviceWorker.updated &&
        ReactDom.createPortal(
          <AnimatePresence>
            <ToastStyles.Wrapper
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
              type="info"
            >
              <ToastStyles.Content>
                <FontAwesomeIcon
                  icon={['fal', 'info-circle']}
                  className={styles.toastIcon}
                />
                <div className={styles.toastAlign}>
                  <p>
                    <FormattedMessage id="serviceWorker.newVersionAvailable" />
                  </p>
                  <p>
                    <FormattedMessage
                      id="serviceWorker.saveAndReload"
                      values={{
                        link: (
                          <button
                            type="button"
                            className={styles.alertButton}
                            onClick={async () => {
                              await dispatch(activateServiceWorker())
                              updateServiceWorker(serviceWorker)
                            }}
                          >
                            <FormattedMessage id="serviceWorker.reload" />
                          </button>
                        ),
                      }}
                    />
                  </p>
                </div>
              </ToastStyles.Content>
            </ToastStyles.Wrapper>
          </AnimatePresence>,
          toastPortal
        )}
      {children}
    </>
  )
}

ServiceWorkerWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default ServiceWorkerWrapper
