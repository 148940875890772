import React from 'react'
import CWLoader from 'components/CWLoader'
import { LoadingWrapper } from './Loading.styles'

const Loading: React.FC = () => {
  return (
    <LoadingWrapper>
      <CWLoader logoSize="50rem" />
    </LoadingWrapper>
  )
}

export default Loading
