import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { GridMode } from './AttachmentsPreview'

export const AttachmentsGrid = styled.div<{
  mode: GridMode
}>`
  width: 100%;
  margin-top: 1.8rem;
  margin-bottom: 1rem;
  &:empty {
    display: none;
  }
  ${(props) =>
    props.mode === 'grid' &&
    css`
      display: grid;
      grid-template-columns: repeat(auto-fill, 12.8rem);
      gap: 1.6rem;
    `}

  ${(props) =>
    props.mode === 'grid-list' &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    `}
  ${(props) =>
    props.mode === 'list' &&
    css`
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
    `}
`

export const TitleWrapper = styled.div<{ isTransactionType: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => (props.isTransactionType ? '0' : '2.4rem')};

  > svg {
    font-size: 1.45rem;
  }
`

export const Title = styled.h3`
  font-size: 1.6rem;
  font-weight: 400;
  color: ${color('gray')};
`
