import React from 'react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from './Input.module.scss'

const Input = React.forwardRef(
  (
    {
      error,
      name,
      id,
      label,
      onChange,
      onBlur,
      customStyle,
      icon,
      image,
      hideCaret,
      pointer,
      prefix,
      className,
      prefixIcon,
      onIconClick,
      disabled,
      optional,
      ...rest
    },
    ref
  ) => {
    const intl = useIntl()
    const handleChange = (event) => {
      const {
        target: { value: dataValue },
      } = event
      onChange({ key: id, value: dataValue, event })
    }

    return (
      <div
        className={classNames(customStyle, styles.inputWrapper, className, {
          [styles.disabledStyles]: disabled,
        })}
      >
        {label && (
          <p className={styles.inputLabel}>
            {label}
            {optional && (
              <span className={styles.optionalField}>
                {intl.messages['general.optional']}
              </span>
            )}
          </p>
        )}{' '}
        <div
          className={`${error && styles.fieldWithError} ${
            styles.inputContainer
          }`}
        >
          {prefixIcon && prefixIcon}
          {prefix && (
            <div className={styles.prefixContainer}>
              <span>{prefix}</span>
            </div>
          )}
          <input
            ref={ref}
            name={name}
            className={classNames(styles.inputField, {
              [styles.hideCaretStyles]: hideCaret,
              [styles.pointerStyles]: pointer,
            })}
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
            {...rest}
          />
          {icon && (
            <FontAwesomeIcon
              onClick={onIconClick}
              className={styles.iconEnd}
              icon={icon}
            />
          )}
          {image}
        </div>
        <span className={styles.fieldErrorMessage}>{error?.message}</span>
      </div>
    )
  }
)

Input.displayName = 'Input'

Input.defaultProps = {
  error: null,
  label: '',
  onChange: () => {},
  onBlur: () => {},
  customStyle: '',
  icon: '',
  image: '',
  hideCaret: false,
  pointer: false,
  disabled: false,
  prefix: '',
  className: '',
  prefixIcon: null,
  onIconClick: () => {},
  id: '',
  name: '',
  optional: false,
}

Input.propTypes = {
  error: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  customStyle: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  image: PropTypes.node,
  hideCaret: PropTypes.bool,
  disabled: PropTypes.bool,
  pointer: PropTypes.bool,
  prefix: PropTypes.string,
  className: PropTypes.string,
  prefixIcon: PropTypes.node,
  onIconClick: PropTypes.func,
  optional: PropTypes.bool,
}

export default Input
