import { useQuery } from '@tanstack/react-query'
import PortfolioService from 'api/PortfolioService'
import { portfolioKeys } from 'utils/queries/portfolios'
import { PortfolioMetricsStatus } from 'utils/types/portfolios'

export const useGeneratingHoldingsMetricsQuery = (
  assetIds: string[],
  queryOptions = {}
) => {
  const getPortfolioMetricsStatus = async (): Promise<{
    [portfolioId: string]: PortfolioMetricsStatus
  }> => {
    return PortfolioService.getHoldingMetricsStatus()
  }

  return useQuery(
    portfolioKeys.holdingsMetricsStatus(assetIds),
    getPortfolioMetricsStatus,
    queryOptions
  )
}
