import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'

export const InputContainer = styled.div<{
  disabled?: boolean
}>`
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
    `}
`

const getInputPaddingRight = ({
  icon,
  error,
  simulateIconSpace,
  extraPaddingRight,
}) => {
  let padding = 1.6
  if (error?.message) {
    padding += 2.2
  }
  if (icon || simulateIconSpace) {
    padding += 2.2
  }

  return `${extraPaddingRight ? padding + extraPaddingRight : padding}rem}`
}

export const StyledInput = styled.input`
  display: block;
  height: ${(props) => props.height ?? '3.8rem'};
  width: ${(props) => props.width ?? '100%'};
  border: 0.1rem solid transparent;
  border-radius: 0.8rem;
  padding: 1rem 1.6rem;
  padding-right: ${getInputPaddingRight};
  padding-left: ${(props) => props.prefix && '3.6rem'};
  font-size: 1.4rem;
  box-sizing: border-box;
  outline: none;
  color: ${(props) =>
    props.transparent ? color('white')(props) : color('gray')(props)};
  transition: 0.2s background-color, 0.1s border;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props) =>
    props.backgroundColor
      ? css`
          background: ${color(props.backgroundColor, 0.4)(props)};
        `
      : css`
          background: ${props.transparent
            ? 'transparent'
            : color('lightGray', 0.1)(props)};
        `}

  &::placeholder {
    font-size: 1.4rem;
    color: ${color('white', 0.4)};

    ${(props) =>
      !props.transparent &&
      css`
        color: ${color('darkGray', 0.5)};
        opacity: 1;
      `}

    ${(props) =>
      props.placeholderColor &&
      css`
        color: ${color(props.placeholderColor)};
      `}

    ${(props) =>
      props.placeholderOpacity &&
      css`
        opacity: ${props.placeholderOpacity};
      `}
  }

  &:hover {
    ${(props) =>
      !props.transparent &&
      css`
        border: 0.1rem solid ${color('veryLightBlue')};
        border-radius: 0.8rem;
      `}
  }

  &:focus {
    ${(props) =>
      !props.transparent &&
      css`
        background-color: ${color('white')};
        border: 0.1rem solid ${color('primaryBlue')};
      `}
  }

  ${(props) =>
    props.isFocused &&
    !props.transparent &&
    css`
      background-color: ${color('white')};
      border: 0.1rem solid ${color('primaryBlue')};
    `}

  ${(props) =>
    props.hasError &&
    css`
      // Warning state
      ${props.error?.type === 'warning' &&
      css`
        background: ${color('darkYellow', 0.15)};
        &:focus {
          background: ${color('white')};
          border: 0.1rem solid ${color('darkYellow')};
        }

        ${props.isFocused &&
        css`
          background: ${color('white')};
          border: 0.1rem solid ${color('darkYellow')};
        `}
      `};

      //Error state
      ${(props.error?.type === 'error' || props.error?.type === 'required') &&
      css`
        background: ${color('red', 0.15)};
        &:focus {
          background: ${color('white')};
          border: 0.1rem solid ${color('red')};
        }

        ${props.isFocused &&
        css`
          background: ${color('white')};
          border: 0.1rem solid ${color('red')};
        `}
      `};
    `}

  // Large state
  ${(props) =>
    props.large &&
    css`
      height: 6.4rem;
      font-size: 2.4rem;
      padding: 1rem 2.4rem;
      padding-bottom: 1.6rem;
      padding-right: ${props.hasError ? '6.3rem' : '2.4rem'};

      &::placeholder {
        font-size: 2.4rem;
      }
    `}

    // Disabled State
    ${(props) =>
    props.disabled &&
    css`
      padding: 0 1rem;
      cursor: not-allowed;
    `}

    ${(props) =>
    props.readOnlyMode &&
    css`
      background: transparent;
      padding-left: 0.8rem;
      padding-top: 0;
      margin-top: 0;
      cursor: not-allowed;}
    `}
`

export const ErrorIcon = styled(FontAwesomeIcon)<{
  error: boolean
  hasCounter?: boolean
  errorIconCustomPaddingRight?: string
}>`
  font-size: 1.4rem;
  ${(props) =>
    props.error &&
    css`
      color: ${color('red')};
    `}

  ${(props) => props.hasCounter && 'margin-right: 3.5rem'}

  ${(props) =>
    props.errorIconCustomPaddingRight &&
    css`
      padding-right: ${props.errorIconCustomPaddingRight};
    `}
`

export const InputIcon = styled(FontAwesomeIcon)<{
  disabled?: boolean
  color?: string
  fontSize?: string
}>`
  margin-left: 0.5rem;
  font-size: ${(props) => props?.fontSize || '2rem'};
  cursor: pointer;
  color: ${(props) => props.color || color('darkBlue')(props)};
  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`

export const IconsContainer = styled.div<{
  mouseEventDisabled?: boolean
  large?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 1.5rem;
  top: 0.1rem;
  height: 100%;
  ${(props) =>
    props.mouseEventDisabled &&
    css`
      pointer-events: none;
    `};

  ${(props) =>
    props.large &&
    css`
      right: 2.4rem;
      top: 0;
    `}
`

export const InputWrapper = styled.div<{
  type: string
  large?: boolean
}>`
  position: relative;

  ${ErrorIcon} {
    color: ${(props) =>
      props.type === 'error' || props.type === 'required'
        ? color('red')
        : color('darkYellow')};

    // Large state
    ${(props) =>
      props.large &&
      css`
        top: 2.5rem;
        right: 1.5rem;
        font-size: 1.6rem;
      `}
  }
`
export const Prefix = styled.span`
  position: absolute;
  left: 1.2rem;
  font-size: 1.4rem;
  color: ${color('darkBlue')};
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`

export const Value = styled.p`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  margin-left: 1rem;
`

export const SubLabel = styled.div`
  color: ${color('lightGray')};
  font-weight: 400;
  font-size: 1.2rem;
`

export const LabelContainer = styled.div`
  display: flex;
`
