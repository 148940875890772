import { FormattedMessage, useIntl } from 'react-intl'
import { useFormikContext } from 'formik'

import { InboundForm } from 'utils/types/inbounds'
import Input from 'ui/Input'

import {
  Description,
  EmailStepContainer,
  InfoContainer,
  InputContainer,
} from '../CreateInbound.styles'
import GroupManagedToggle from '../../GroupManagedToggle'

interface Props {
  goToNextStep: () => void
}

const EmailAddressStep = ({ goToNextStep }: Props) => {
  const intl = useIntl()
  const formik = useFormikContext<InboundForm>()

  return (
    <EmailStepContainer>
      <Description bold>
        <FormattedMessage id="inbounds.emailStepDescription" />
      </Description>
      <InputContainer>
        <Input
          id="inbound-email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={intl.formatMessage({
            id: 'inbounds.addEmail',
          })}
          onPressEnter={goToNextStep}
        />
        <InfoContainer>
          <FormattedMessage id="inbounds.emailStepInfo" />
        </InfoContainer>
      </InputContainer>
      <GroupManagedToggle />
    </EmailStepContainer>
  )
}

export default EmailAddressStep
