import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components/macro'
import { maxSize } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 0.8rem;
  background-color: ${color('softBlue', 0.9)};
  font-size: 1.4rem;
  font-weight: 400;
  color: ${color('lightGray')};

  @media ${maxSize.xsm} {
    max-width: 19rem;
  }
`

interface MoreFilesProps {
  amount: number
}

const MoreFiles: React.FC<MoreFilesProps> = ({ amount }) => {
  return (
    <Wrapper>
      <FormattedMessage
        id="files.details.showMore"
        values={{ count: amount }}
      />
    </Wrapper>
  )
}

export default MoreFiles
