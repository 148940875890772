import React, {
  useState,
  useEffect,
  ReactNode,
  Children,
  cloneElement,
} from 'react'
import ReactDom from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Color } from 'utils/theme'

import styles from './Modal.module.scss'
import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  RowFooter,
} from './Modal.styles'

type ModalProps = {
  show: boolean
  onHide?: () => void
  children: ReactNode
  className?: string
  blurOverlay?: boolean
  hideOnOutsideClick?: boolean
}

type ModalHeaderProps = {
  children: ReactNode
  onHide?: () => void
  isSecondary?: boolean
  backgroundColor?: Color
  className?: string
}

type ModalTitleProps = {
  children: ReactNode
  isSecondary?: boolean
}

type ModalH2TitleProps = {
  children: ReactNode
}

type ModalBodyProps = {
  children: ReactNode
  className?: string
  padding?: string
  widthOverflow?: boolean
}

type ModalFooterProps = {
  children: ReactNode
  className?: string
}

type ModalRowFooterProps = {
  id?: string
  children?: ReactNode
  className?: string
}

export const MODAL_FADE_OUT_DURATION_IN_MS = 500
const MODAL_FADE_OUT_DURATION_IN_S = MODAL_FADE_OUT_DURATION_IN_MS / 1000

const Modal = ({
  show,
  onHide,
  children,
  className = '',
  blurOverlay = false,
  hideOnOutsideClick = true,
}: ModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const modalContainer = document.getElementById('modal-container')

  useEffect(() => {
    setIsModalVisible(show)
  }, [show])

  const displayChildren = () =>
    Children.map(children, (child) =>
      child ? cloneElement(child as React.ReactElement<any>, { onHide }) : null
    )

  const animation = {
    y: '-50%',
    x: '-50%',
    opacity: 1,
    transition: { duration: MODAL_FADE_OUT_DURATION_IN_S },
    transitionEnd: {
      x: 0,
      y: 0,
      top: 'auto',
      left: 'auto',
    },
  }

  const exitAnimation = {
    y: '-100%',
    opacity: 0,
    transition: { duration: MODAL_FADE_OUT_DURATION_IN_S },
  }

  if (!modalContainer) {
    return null
  }

  return ReactDom.createPortal(
    <AnimatePresence>
      {isModalVisible && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classNames(styles.modalOverlay, {
            [styles.blurOverlay]: blurOverlay,
          })}
          onClick={(event) => {
            event.stopPropagation()
            if (hideOnOutsideClick) {
              onHide?.()
            }
          }}
        >
          <motion.div
            initial={{ y: '-100%', x: '-50%', opacity: 1 }}
            animate={animation}
            exit={exitAnimation}
            className={classNames(styles.modalWrapper, className)}
            data-testid="modal-wrapper"
            onClick={(event) => {
              event.stopPropagation()
            }}
          >
            {displayChildren()}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    modalContainer
  )
}

Modal.displayName = 'Modal'
Modal.Header = ({
  children,
  onHide,
  isSecondary,
  backgroundColor,
  className,
}: ModalHeaderProps) => (
  <ModalHeader
    className={className}
    isSecondary={isSecondary}
    backgroundColor={backgroundColor}
  >
    <span>{children}</span>
    {onHide && (
      <CloseButton
        onClick={onHide}
        role="presentation"
        isSecondary={isSecondary}
      >
        <FontAwesomeIcon size="4x" icon={['fal', 'times']} />
      </CloseButton>
    )}
  </ModalHeader>
)
Modal.Title = ({ children, isSecondary }: ModalTitleProps) => (
  <ModalTitle isSecondary={isSecondary}>{children}</ModalTitle>
)

Modal.H2Title = ({ children }: ModalH2TitleProps) => (
  <ModalTitle>{children}</ModalTitle>
)

Modal.Body = ({
  children,
  className,
  padding,
  widthOverflow,
}: ModalBodyProps) => (
  <ModalBody
    padding={padding}
    withOverflow={widthOverflow}
    className={classNames(className)}
  >
    {children}
  </ModalBody>
)
Modal.Footer = ({ children, className }: ModalFooterProps) => (
  <ModalFooter className={classNames(styles.modalFooter, className)}>
    {children}
  </ModalFooter>
)
Modal.RowFooter = ({ children, className, id }: ModalRowFooterProps) => (
  <RowFooter id={id} className={classNames(className)}>
    {children}
  </RowFooter>
)

export default Modal
