import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Styles from '../ShareWithDropdown.styles'

export const DropdownIndicator = () => {
  return (
    <Styles.PlusSign>
      <FontAwesomeIcon icon={['far', 'plus']} />
    </Styles.PlusSign>
  )
}
