/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import crunchbaseLogo from 'assets/images/crunchbaselogo.svg'
import xTwitterLogo from 'assets/images/x-twitter.svg'

import Title from 'components/Title'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import Input from 'ui/Input'

import { getUrlFieldsValidationsSchema } from 'utils/schemas/teamMembers'

import { LinksContainer } from './CompanyUrls.styles'

const CompanyUrls = ({
  isFundCompany,
  onEditField,
  companyData,
  iconStyle,
}) => {
  const intl = useIntl()

  const schema = getUrlFieldsValidationsSchema(intl.messages)

  const { errors, register, setValue, getValues } = useForm({
    validationSchema: schema,
    mode: 'onChange',
  })

  useEffect(() => {
    if (companyData) {
      const { website, linkedinUrl, x, crunchbaseUrl, angellistUrl } =
        companyData
      setValue([
        { website },
        { linkedinUrl },
        { x },
        { crunchbaseUrl },
        { angellistUrl },
      ])
    }
  }, [companyData])

  const onBlur = (key) => {
    const transformedValues = schema.cast(getValues())
    onEditField({ key, value: transformedValues[key] })
  }

  return (
    <LinksContainer>
      <Title title={intl.formatMessage({ id: 'editCompany.directories' })} />

      <Input
        id="crunchbaseUrl"
        onBlur={() => !errors.crunchbaseUrl && onBlur('crunchbaseUrl')}
        name="crunchbaseUrl"
        label={intl.formatMessage({ id: 'editCompany.crunchbase' })}
        placeholder={intl.formatMessage({
          id: 'editCompany.companyCrunchbasePlaceholder',
        })}
        error={errors.crunchbaseUrl}
        ref={register}
        prefix={
          <img src={crunchbaseLogo} alt="crunchbase" className={iconStyle} />
        }
      />

      <Title title={intl.formatMessage({ id: 'editCompany.socialMedia' })} />

      <Input
        id="linkedinUrl"
        onBlur={() => !errors.linkedinUrl && onBlur('linkedinUrl')}
        name="linkedinUrl"
        label={intl.formatMessage({ id: 'editCompany.companyLinkedin' })}
        placeholder={intl.formatMessage({
          id: 'editCompany.companyLinkedinPlaceholder',
        })}
        error={errors.linkedinUrl}
        ref={register}
        prefix={
          <FontAwesomeIcon
            icon={['fab', 'linkedin-in']}
            className={iconStyle}
          />
        }
      />

      <Input
        id="xUrl"
        onBlur={() => !errors.x && onBlur('x')}
        name="x"
        label={intl.formatMessage({ id: 'editCompany.companyX' })}
        placeholder={intl.formatMessage({
          id: 'editCompany.companyXPlaceholder',
        })}
        error={errors.x}
        ref={register}
        prefix={
          <img
            src={xTwitterLogo}
            alt="x twitter"
            style={{
              width: '1.6rem',
              height: '1.6rem',
              marginTop: '3px',
            }}
          />
        }
      />
    </LinksContainer>
  )
}

CompanyUrls.propTypes = {
  onEditField: PropTypes.func.isRequired,
  companyData: PropTypes.shape({
    crunchbaseUrl: PropTypes.string,
    linkedinUrl: PropTypes.string,
    x: PropTypes.string,
    angellistUrl: PropTypes.string,
    website: PropTypes.string,
  }),
  iconStyle: PropTypes.string.isRequired,
  isFundCompany: PropTypes.bool,
}

CompanyUrls.defaultProps = {
  companyData: null,
  isFundCompany: false,
}

export default CompanyUrls
