import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { color } from 'utils/functions/colors'

export const ColHeaderContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  display: flex;
  text-align: ${(props) => props.align || 'center'};
  outline: none;
  flex: ${(props) => (props.flex !== undefined ? props.flex : 1)};
  padding: ${(props) => (props.flex === 0 ? 0 : props.padding || '0 .6rem')};

  ${(props) =>
    !props.sortDisabled &&
    css`
      cursor: pointer;
    `};
`

export const SortIcon = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  margin-left: 0.5rem;
  color: ${color('darkBlue')};
  svg {
    height: 1rem;
  }
`

export const LabelContainer = styled.span`
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
`
export const TooltipContainer = styled.span`
  margin-left: 0.5rem;
  svg {
    color: ${(props) =>
      color(props.activeSort ? 'darkBlue' : 'lightGray')(props)};
  }
`

export const Label = styled.span`
  color: ${(props) =>
    color(props.activeSort ? 'darkBlue' : 'lightGray')(props)};
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
`
