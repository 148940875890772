import styled from 'styled-components'
import InputToStyle from 'ui/Input'
import InputError from 'ui/InputError'
import { color } from 'utils/functions/colors'

export const BillContainer = styled.div`
  border-radius: 0.8rem;
  background: rgba(242, 244, 246, 0.62);
  padding: 2.4rem;
  width: 100%;
  margin-top: 2.4rem;
  color: ${color('darkGray')};
`

export const PlanName = styled.div`
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
`
export const Trial = styled.span`
  font-size: 1.4rem;
  color: ${color('lightGray')};
`

export const PlanRow = styled.div`
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const PlanRowDescription = styled.div`
  color: ${color('darkGray')};
  font-size: 1.4rem;
  font-weight: 700;
  margin-top: 0.3rem;
`

export const PlanPriceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`

export const SwitchPeriodButton = styled.button`
  background: none;
  border: none;
  color: ${color('primaryBlue')};
  font-weight: 700;
  font-weight: 700;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }
`

export const PlanPrice = styled.span`
  font-size: 3.2rem;
  color: ${color('darkBlue')};
`
export const PlanPriceLabel = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.2rem;
  width: 4rem;
`

export const Input = styled(InputToStyle)`
  width: 20rem;

  ${InputError} {
    margin-left: 0;
    width: max-content;
  }
`

export const CouponContainer = styled.form`
  display: flex;
  align-items: baseline;
  gap: 0.6rem;

  button {
    padding: 0.9rem 1.6rem;
  }
`
