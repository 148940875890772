import styled, { css } from 'styled-components'
import { color } from 'utils/functions/colors'
import { OptionLegend } from './Dropdown.styles'

export const NoStyledButton = styled.button`
  all: unset;
`

export const DropdownOptionButton = styled.button<{
  capitalizeOptions?: boolean
  padding?: string
  withIcon?: boolean
  selected?: boolean
  disabled?: boolean
  backgroundColorForSelected?: string
}>`
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  word-break: break-word;
  ${(props) =>
    props.capitalizeOptions &&
    css`
      text-transform: capitalize;
    `};
  color: ${color('darkBlue')};
  font-weight: 700;
  font-size: 1.4rem;
  padding: ${(props) => props.padding || '0.9rem'};
  width: 100%;
  text-align: left;
  border-radius: 0.8rem;

  ${(props) =>
    props.withIcon
      ? css`
          display: flex;
        `
      : ''}

  ${OptionLegend} {
    margin-top: 0.5rem;
  }

  ${(props) =>
    props.selected
      ? css`
          background-color: ${props.backgroundColorForSelected ||
          color('primaryBlue')};
          color: white;

          ${OptionLegend} {
            color: ${color('white')};
          }
        `
      : css`
          &:hover {
            background: ${color('veryLightBlue', 0.2)};
            color: ${color('primaryBlue')};

            > div * {
              color: ${color('primaryBlue')};
            }

            ${OptionLegend} {
              color: ${color('primaryBlue')};
              font-weight: 400;
            }
          }

          ${props.disabled &&
          css`
            cursor: not-allowed;
          `}
        `}
`

export const PortfolioName = styled.span<{ selected: boolean }>`
  font-size: 1.4rem;
  font-weight: 700;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.selected ? color('white') : color('darkBlue'))};
`

export const PortfolioType = styled.span<{ selected: boolean }>`
  font-size: 1.4rem;
  margin-top: 0.2rem;
  font-weight: 400;
  color: ${(props) =>
    props.selected ? color('veryLightBlue') : color('lightGray')};
`

export const CustomDiv = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
`
