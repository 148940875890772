import { FormattedMessage } from 'react-intl'
import AddYourFilesStep from './components/AddYourFilesStep'

import SetupYourInboundStep from './components/SetupYourInboundStep'
import SetupYourPipelineStep from './components/SetupYourPipelineStep'
import { HoldingsSpreadsheetContextProvider } from './components/SetupYourPipelineStep/components/AddHoldingSpreadsheet/HoldingsSpreadsheetContext'
import StepsSelector from './components/StepsSelector'
import WelcomeStep from './components/WelcomeStep'
import {
  Header,
  SecondHeader,
  StyledModal,
  Title,
} from './OnboardingModal.styles'
import { OnboardingStep, useOnboardingModal } from './useOnboardingModal'
import OnboardingBar from './components/OnboardingBar/OnboardingBar'

const OnboardingModal = () => {
  const {
    show,
    currentStep,
    completedSteps,
    showOnboardingModal,
    goBack,
    jumpToStep,
    onHide,
    showModal,
    completeStep,
    firstPipeline,
  } = useOnboardingModal()

  const renderSteps = () => {
    switch (currentStep) {
      case OnboardingStep.SETUP_YOUR_PIPELINE:
        return (
          <SetupYourPipelineStep
            preFetchedPipeline={firstPipeline}
            completeStep={() =>
              completeStep(OnboardingStep.SETUP_YOUR_PIPELINE)
            }
            goToNextStep={() => jumpToStep(OnboardingStep.ADD_YOUR_FILES)}
          />
        )
      case OnboardingStep.ADD_YOUR_FILES:
        return (
          <AddYourFilesStep
            isStepCompleted={completedSteps[OnboardingStep.ADD_YOUR_FILES]}
            goBack={goBack}
            completeStep={() => completeStep(OnboardingStep.ADD_YOUR_FILES)}
            goToNextStep={() => jumpToStep(OnboardingStep.SETUP_YOUR_INBOUND)}
          />
        )
      case OnboardingStep.SETUP_YOUR_INBOUND:
        return (
          <SetupYourInboundStep
            goBack={goBack}
            hideModal={onHide}
            completedSteps={completedSteps}
            completeStep={() => completeStep(OnboardingStep.SETUP_YOUR_INBOUND)}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {showOnboardingModal && <OnboardingBar onClick={showModal} />}
      <StyledModal show={show} onHide={onHide} hideOnOutsideClick={false}>
        <Header onHide={onHide}>
          <Title>
            <FormattedMessage id="onboardingModal.gettingStarted" />
          </Title>
        </Header>

        {currentStep ? (
          <SecondHeader>
            <StepsSelector
              currentStep={currentStep}
              completedSteps={completedSteps}
              jumpToStep={jumpToStep}
            />
          </SecondHeader>
        ) : (
          <WelcomeStep jumpToStep={jumpToStep} onHide={onHide} />
        )}

        <HoldingsSpreadsheetContextProvider>
          {renderSteps()}
        </HoldingsSpreadsheetContextProvider>
      </StyledModal>
    </>
  )
}

export default OnboardingModal
