import React, { ReactElement, ReactNode, cloneElement, useState } from 'react'
import { Nullable } from 'utils/types/common'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getIsReadOnlyMode } from 'selectors/auth'
import { StyledTooltip, TooltipGlobalStyles } from './ReadOnlyTooltip.styles'

const ReadOnlyTooltip = ({
  children,
  disabled: disabledProp = null,
  ...props
}: {
  children: ReactNode | (({ disabled }: { disabled: boolean }) => ReactElement)
  disabled?: Nullable<boolean>
  maxWidth?: string
  [key: string]: any
}): ReactElement => {
  const isReadOnly = useAppSelector(getIsReadOnlyMode)
  const [disabled, setDisabled] = useState(false)

  React.useEffect(() => {
    if (typeof disabledProp !== 'object') {
      setDisabled(!!disabledProp)
    } else {
      setDisabled(!!isReadOnly)
    }
  }, [isReadOnly, disabledProp])

  const renderChildren = (): ReactElement => {
    if (typeof children === 'function') {
      return children({
        disabled,
      })
    }
    return (
      <>
        {React.Children.map(children, (child: ReactElement) => {
          if (child) {
            return cloneElement(child, {
              ...child.props,
              ...props,
              disabled,
            })
          }
          return null
        })}
      </>
    )
  }

  if (disabled) {
    return (
      <>
        <TooltipGlobalStyles maxWidth={props.maxWidth} />
        <StyledTooltip {...props}>{renderChildren()}</StyledTooltip>
      </>
    )
  }

  return renderChildren()
}

export default ReadOnlyTooltip
