import { FormattedMessage } from 'react-intl'

import { CLOSE_THREAD_EVENT } from 'containers/Chat/hooks/useIsThreadOpen'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { CloseButton, Container } from './ThreadHeader.styles'

interface ThreadHeaderProps {
  closeThread: () => void
}

const ThreadHeader = ({ closeThread }: ThreadHeaderProps) => {
  const onCloseDrawer = () => {
    dispatchEvent(CLOSE_THREAD_EVENT)
    closeThread()
  }

  return (
    <Container>
      <FormattedMessage id="chat.thread" />
      <CloseButton
        onClick={onCloseDrawer}
        buttonType="square"
        buttonSize="3.2rem"
        icon="times"
        border={false}
        borderOnFocus={false}
        primary
      />
    </Container>
  )
}

export default ThreadHeader
